import { gql } from "@apollo/client";

export const AddLibBookPublisher = gql`
  mutation AddLibBookPublisher(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateLibBookPublisherInput!
  ) {
    AddLibBookPublisher(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

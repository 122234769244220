import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { TableHeaderProps } from "../../../Types/Tables";
import DownArrow from "../../../images/DownArrow.svg";
import { Title } from "../../../stories/Title/Title";
import useMasterTableJson from "../../../json/useMasterTableJson";

const tableData = {
  Customers: [
    {
      name: "Adichunchunagiri",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Adi",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "giri",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
  ],
};
const AssociatedList = () => {
  const [collapseFlag, setCollapseFlag] = useState<number[]>([]);
  const [enableI, setEnableI] = useState(false);
  const { Masters_Table } = useMasterTableJson();

  return (
    <div className="view-eduate-associated-list">
      <Title variant="subtitle1">
        <b className="channeldescription__channelname">User Name</b> Associated
        Institutions
      </Title>
      <TableContainer className="eduate-user-association__data--tableblock--table--modal">
        <Table stickyHeader>
          <TableHead>
            {Masters_Table.Eduate.Association.Table_Headers.map(
              (th: TableHeaderProps, index: React.Key) => {
                return (
                  <TableCell key={index} className={th.className}>
                    {th.labelName}
                  </TableCell>
                );
              }
            )}
          </TableHead>
          <TableBody>
            {tableData.Customers.map((data, index) => {
              return (
                <>
                  <TableRow key={index}>
                    <TableCell className="eduate-user-association__data--tableblock--table--slno">
                      <img
                        src={DownArrow}
                        alt="/"
                        onClick={() => {
                          setEnableI(!enableI);

                          if (!collapseFlag.includes(index)) {
                            setCollapseFlag((p) => [...p, index]);
                          } else {
                            collapseFlag.splice(collapseFlag.indexOf(index), 1);
                          }
                        }}
                      />
                      &nbsp;
                      {index + 1}
                    </TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell className="eduate-user-association__data--tableblock--table--place">
                      {data.place}
                    </TableCell>
                    <TableCell className="eduate-user-association__data--tableblock--table--name">
                      {data.contactperson}
                    </TableCell>
                    <TableCell className="eduate-user-association__data--tableblock--table--mobile">
                      {data.Mobile}
                    </TableCell>
                    <TableCell className="eduate-user-association__data--tableblock--table--total">
                      {data.total}
                    </TableCell>
                  </TableRow>

                  {collapseFlag.includes(index) && (
                    <>
                      {data.InstitutionUnderCustomer.map((subdata, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{subdata.name}</TableCell>
                            <TableCell>{subdata.place}</TableCell>
                            <TableCell>{subdata.contactperson}</TableCell>
                            <TableCell>{subdata.Mobile}</TableCell>
                            <TableCell>{subdata.total}</TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AssociatedList;

import { gql } from "@apollo/client";

export const GetLibBookVendorsByInstId = gql`
  query GetLibBookVendorsByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookVendorOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookVendorsByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ vendorNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          vendor_name
          vendor_address
          vendor_city
          vendor_state
          vendor_country
          vendor_phone
          vendor_contact_person
          vendor_contact_person_phone
          vendor_pan
          vendor_gst
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetAcdYrsByInstId } from "../../../queries/academicyear/list/byid";
import {
  AcademicYearListData,
  AcademicYearListVars,
} from "../../../Types/Student";
import { responseType } from "../../../utils/Form.types";

const useAcademicYears = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [GetAcdYears, { data, loading, error }] = useLazyQuery<
    AcademicYearListData,
    AcademicYearListVars
  >(GetAcdYrsByInstId, {
    variables: { inst_id: InstId!, token },
  });
  useEffect(() => {
    if (token && InstId) {
      GetAcdYears().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetAcdYrsByInstId.map((acdYear) => ({
              label: acdYear.acd_yr,
              value: acdYear.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [token, InstId, GetAcdYears]);
  return { academicYearResponse: { data, loading, error, responseType } };
};

export default useAcademicYears;

import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetChannelStudentIndividual } from "../../../queries/chatapplication/list";
interface GetChannelStudentIndividualData {
  GetChannelStudentIndividual: {
    id: number;
  };
}

interface GetChannelStudentIndividualVars {
  token: string;
  inst_id: string;
  student_id: number;
}

const useIndividualStudentId = (student_id: number) => {
  const { token } = useToken();

  const { InstId } = useParams();
  const [GetIndividualChannelId, { data }] = useLazyQuery<
    GetChannelStudentIndividualData,
    GetChannelStudentIndividualVars
  >(GetChannelStudentIndividual, {
    variables: {
      inst_id: InstId!,
      student_id,
      token,
    },
  });
  const channelId: number = data ? data.GetChannelStudentIndividual.id : 0;
  useEffect(() => {
    if (token && student_id) {
      GetIndividualChannelId();
    }
  }, [token, GetIndividualChannelId, student_id]);
  return {
    data,
    channelId,
  };
};

export default useIndividualStudentId;
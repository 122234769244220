import { gql } from "@apollo/client";

export const AddAcdSubjectComboAndDetails = gql`
  mutation AddAcdSubjectComboAndDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $subj_combo_name: String!
    $user_details: SubscribedUser!
    $input: [CreateAcdSubjectComboDetailsInput!]!
  ) {
    AddAcdSubjectComboAndDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      subj_combo_name: $subj_combo_name
      user_details: $user_details
      input: $input
    )
  }
`;

export const DeleteAcdSubjectCombo = gql`
  mutation DeleteAcdSubjectCombo(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdSubjectCombo(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const AddAcdStudentComboDetails = gql`
  mutation AddAcdStudentComboDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $alloted_level: String!
    $allotted_id: ID!
    $acd_subj_combo_id: ID!
    $student_ids: [ID!]!
    $user_details: SubscribedUser!
  ) {
    AddAcdStudentComboDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      alloted_level: $alloted_level
      allotted_id: $allotted_id
      acd_subj_combo_id: $acd_subj_combo_id
      student_ids: $student_ids
      user_details: $user_details
    )
  }
`;
export const DeleteAcdStudentComboDetails = gql`
  mutation DeleteAcdStudentComboDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $alloted_level: String!
    $allotted_id: ID!
    $acd_subj_combo_id: ID!
    $student_ids: [ID!]!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdStudentComboDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      alloted_level: $alloted_level
      allotted_id: $allotted_id
      acd_subj_combo_id: $acd_subj_combo_id
      student_ids: $student_ids
      user_details: $user_details
    )
  }
`;

export const UpdateAcdSubjectComboSubjOrder = gql`
  mutation UpdateAcdSubjectComboSubjOrder(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_subj_combo_id: ID!
    $user_details: SubscribedUser!
    $input: [SubjOrderData!]!
  ) {
    UpdateAcdSubjectComboSubjOrder(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_subj_combo_id: $acd_subj_combo_id
      user_details: $user_details
      input: $input
    )
  }
`;

import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GetAttDashBoardGraphData } from "../../../../../queries/common";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../../../utils/constants";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";

const useAttendanceDashboardGraphData = () => {
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const { InstId } = useParams();
  const [GetDashboardGraphData, { data: DashBoardGraphData }] = useLazyQuery(
    GetAttDashBoardGraphData
  );
  const { token } = useToken();

  useEffect(() => {
    if (
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetDashboardGraphData({
        variables: {
          token,
          inst_id: InstId,
          acd_yr_id: activeAcdId,
          date_of_attendance: toIsoDate(TODAY_DATE),
        },
      });
    }
  }, [
    token,
    GetDashboardGraphData,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
  ]);

  return { DashBoardGraphData };
};

export default useAttendanceDashboardGraphData;

import { useMutation } from "@apollo/client";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import EditProfile from "../../../../images/EditProfile.svg";
import DeleteModal from "../../../../pages/DeleteModal";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { HRTitleProps } from "../../../../Types/Titles";
import { EMPTY_STRING } from "../../../../utils/constants";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import { msgType } from "../../../../utils/Form.types";
import { reOrderProcess } from "../../../../utils/UtilFunctions";
import useEmpSalaryLedger from "../../hooks/useEmpSalaryLedger";
import { GetPayRollSalaryLdgrsByInstId } from "../../queries/salary/list";
import {
  DeletePayRollSalaryLdgrByIds,
  ReOrderPayRollSalaryLdgr,
} from "../../queries/salary/mutation";
import Delete from "../../../../images/Delete.svg";
import {
  SalaryLedgerType,
  applyHeadsOn,
  cellEditType,
} from "../../enums/Enum.types";
import { SalaryledgerTypeOptions } from "../../constants";
import { ledgers_type } from "../SalaryProcess/SalaryStructDetailsModal";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");

interface PayRollSalaryListType {
  id: number;
  sal_ldgr_desc: string;
  sal_ldgr_short_desc: string;
  sal_ldgr_idx: number;
  sal_ldgr_earn_deduct: string;
  sal_ldgr_type: string;
  sal_ldgr_is_active: boolean;
  sal_ldgr_round_to_next_int: boolean;
  sal_ldgr_is_system_generated: boolean;
  sal_ldgr_remove_decimal: boolean;
  sal_ldgr_is_basic: boolean;
}

interface Props {
  pageType: PageFor;
  setFormDataForUpdate: (id: number) => void;
  setSelectedLedgers?: React.Dispatch<React.SetStateAction<ledgers_type[]>>;
  idSet?: Map<number, boolean>;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const SalaryLedgerList = ({
  pageType,
  setFormDataForUpdate,
  setSelectedLedgers,
  idSet,
  setModal,
}: Props) => {
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const [localItems, setLocalItems] = useState<PayRollSalaryListType[]>([]);
  const [reOrder, setReOrder] = useState(false);
  const [salaryLedgerId, setSalaryLedgerId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { InstId } = useParams();
  const {
    salaryLedger: { data, error, loading },
  } = useEmpSalaryLedger();

  const { USE_PAYROLL } = useUserRightsByEmpId();

  const [DeleteSalaryLedger] = useMutation(DeletePayRollSalaryLdgrByIds, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [ReOrderSalaryLedger, { loading: reOrderSalaryLedgerLoading }] =
    useMutation(ReOrderPayRollSalaryLdgr, {
      onError: (e) => {
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
        setLocalItems(
          data?.GetPayRollSalaryLdgrsByInstId?.map((data) => ({
            id: data.id,
            sal_ldgr_desc: data.sal_ldgr_desc,
            sal_ldgr_short_desc: data.sal_ldgr_short_desc,
            sal_ldgr_idx: data.sal_ldgr_idx,
            sal_ldgr_earn_deduct: data.sal_ldgr_earn_deduct,
            sal_ldgr_type: data.sal_ldgr_type,
            sal_ldgr_is_active: data.sal_ldgr_is_active,
            sal_ldgr_round_to_next_int: data.sal_ldgr_round_to_next_int,
            sal_ldgr_remove_decimal: data.sal_ldgr_remove_decimal,
            sal_ldgr_is_basic: data.sal_ldgr_is_basic,
            sal_ldgr_is_system_generated: data.sal_ldgr_is_system_generated,
          })) ?? []
        );
      },
    });
  const handleSelect = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    response: PayRollSalaryListType
  ) => {
    const { checked } = e.target as HTMLInputElement;
    if (checked) {
      setSelectedLedgers?.((prevValues) => [
        ...prevValues,
        {
          id: response.id,
          ledger_type: response.sal_ldgr_earn_deduct as SalaryLedgerType,
          earn_type:
            response.sal_ldgr_earn_deduct === SalaryLedgerType.EARNING
              ? cellEditType.UDM
              : EMPTY_STRING,
          ledger_desc: response.sal_ldgr_desc,
          ledger_applied_to: applyHeadsOn.BASIC_SALARY,
          ledger_rule: EMPTY_STRING,
          ledger_udvMonthly: true,
          ledger_applied_on_heads: false,
          sal_ldgr_remove_decimal: response.sal_ldgr_remove_decimal,
          sal_ldgr_round_to_next_int: response.sal_ldgr_round_to_next_int,
          earnings: 0,
          remaining_balance: false,
          deductions: 0,
          earnedValueFixedBelowBasic: 0,
          earnedValueRestrictedTo: 0,
          callToMaxValue: 0,
          flat_rate: 0,
          udv: 0,
          emp_sal_str_sl: 0,
          emp_sal_str_ldgr_sl: 0,
          sal_ldgr_is_basic: response.sal_ldgr_is_basic,
          from_sal_process: false,
          fixed_amount: 0,
          fixed_gross: 0,
        },
      ]);
    } else {
      setSelectedLedgers?.((prevValues) =>
        prevValues.filter(({ id }) => id !== response.id)
      );
    }
  };
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      return temp;
    });
  };
  const handleSaveOrder = () => {
    // eslint-disable-next-line
    ReOrderSalaryLedger({
      variables: {
        token,

        inst_id: InstId,
        user_details,
        input: localItems?.map((res, index) => ({
          id: res.id,
          sal_ldgr_idx: index + 1,
        })),
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryLdgrsByInstId,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          flag: true,
          message: "Order saved successfully",
          operation: Operation.CREATE,
        });
    });
    setReOrder(!reOrder);
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteSalaryLedger({
      variables: {
        token,
        ids: id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryLdgrsByInstId,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          operation: Operation.DELETE,
          flag: true,
          message: "SalaryLedger Deleted Successfully",
        });
      }
    });
  };
  useEffect(() => {
    if (data && !loading)
      setLocalItems(
        data.GetPayRollSalaryLdgrsByInstId.map((data) => ({
          id: data.id,
          sal_ldgr_desc: data.sal_ldgr_desc,
          sal_ldgr_short_desc: data.sal_ldgr_short_desc,
          sal_ldgr_idx: data.sal_ldgr_idx,
          sal_ldgr_earn_deduct: data.sal_ldgr_earn_deduct,
          sal_ldgr_type: data.sal_ldgr_type,
          sal_ldgr_is_active: data.sal_ldgr_is_active,
          sal_ldgr_round_to_next_int: data.sal_ldgr_round_to_next_int,
          sal_ldgr_remove_decimal: data.sal_ldgr_remove_decimal,
          sal_ldgr_is_basic: data.sal_ldgr_is_basic,
          sal_ldgr_is_system_generated: data.sal_ldgr_is_system_generated,
        }))
      );
    // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <div className="row g-0 salary-ledger__block--title">
        <div className="col-3"></div>
        <div className="col">
          <Title variant="subtitle1">
            {HRTitles.SalaryLedger.Titles.map(
              (title: HRTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.List}</React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        {pageType === PageFor.MODAL && <div className="col-3"></div>}
      </div>
      <div className="salary-ledger__block--data">
        {error ? (
          <b className="nodata">{error.message}</b>
        ) : data?.GetPayRollSalaryLdgrsByInstId.length! > 0 ? (
          <TableContainer className="salary-ledger__block--data--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {reOrder || pageType === PageFor.MODAL ? <TableCell /> : null}

                  {HR_Table.SalaryLedger.Table_Headers.filter(
                    (th: TableHeaderProps) =>
                      (th.labelName !== "Actions" &&
                        pageType === PageFor.MODAL) ||
                      pageType === PageFor.GENERAL
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {localItems?.map((response, index: number) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={!reOrder}
                        >
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <TableRow
                                key={index}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                {reOrder && pageType === PageFor.GENERAL ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="salary-ledger__block--data--table--slno"
                                  >
                                    =
                                  </TableCell>
                                ) : pageType === PageFor.MODAL ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="salary-ledger__block--data--table--slno"
                                  >
                                    <Checkbox
                                      checked={idSet?.has(response.id)}
                                      disabled={idSet?.get(response.id)}
                                      onClick={(e) => {
                                        handleSelect(e, response);
                                      }}
                                    />
                                  </TableCell>
                                ) : null}
                                <TableCell
                                  id="td-center"
                                  {...draggableProvided.dragHandleProps}
                                  className="salary-ledger__block--data--table--slno"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                >
                                  {response.sal_ldgr_desc}
                                </TableCell>
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  className="salary-ledger__block--data--table--shortdesc"
                                >
                                  {response.sal_ldgr_short_desc}
                                </TableCell>
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  className="salary-ledger__block--data--table--earning"
                                >
                                  {
                                    SalaryledgerTypeOptions.find(
                                      ({ value }) =>
                                        value === response.sal_ldgr_earn_deduct
                                    )?.label
                                  }
                                </TableCell>
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  className="salary-ledger__block--data--table--type"
                                >
                                  {response.sal_ldgr_type}
                                </TableCell>
                                {pageType === PageFor.GENERAL && (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    className="salary-ledger__block--data--table--actions"
                                    id="td-center"
                                  >
                                    {!response.sal_ldgr_is_system_generated && (
                                      <>
                                        {USE_PAYROLL.details?.edit ? (
                                          <img
                                            src={EditProfile}
                                            alt="/"
                                            onClick={() =>
                                              setFormDataForUpdate(response.id)
                                            }
                                          />
                                        ) : null}
                                      </>
                                    )}
                                    {!response.sal_ldgr_is_basic && (
                                      <>
                                        {USE_PAYROLL.details?.delete ? (
                                          <img
                                            src={Delete}
                                            alt="/"
                                            onClick={() => {
                                              setSalaryLedgerId(response.id);
                                              setDeleteModal(!deleteModal);
                                            }}
                                          />
                                        ) : null}
                                      </>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        ) : (
          <b className="nodata">No data Please try adding...</b>
        )}
        {localItems?.length > 1 && pageType === PageFor.GENERAL ? (
          reOrder ? (
            <Button mode="save-order" type="button" onClick={handleSaveOrder} />
          ) : (
            <Button
              mode="reorder"
              type="button"
              onClick={() => {
                setReOrder(!reOrder);
                setLocalItems(
                  data?.GetPayRollSalaryLdgrsByInstId?.map((data) => ({
                    id: data.id,
                    sal_ldgr_desc: data.sal_ldgr_desc,
                    sal_ldgr_short_desc: data.sal_ldgr_short_desc,
                    sal_ldgr_idx: data.sal_ldgr_idx,
                    sal_ldgr_earn_deduct: data.sal_ldgr_earn_deduct,
                    sal_ldgr_type: data.sal_ldgr_type,
                    sal_ldgr_is_active: data.sal_ldgr_is_active,
                    sal_ldgr_round_to_next_int: data.sal_ldgr_round_to_next_int,
                    sal_ldgr_remove_decimal: data.sal_ldgr_remove_decimal,
                    sal_ldgr_is_basic: data.sal_ldgr_is_basic,
                    sal_ldgr_is_system_generated:
                      data.sal_ldgr_is_system_generated,
                  })) ?? []
                );
              }}
            />
          )
        ) : null}

        {pageType === PageFor.MODAL && (
          <Button
            mode="cancel"
            onClick={() => {
              setModal?.(false);
            }}
          />
        )}
      </div>
      <LoadingModal flag={reOrderSalaryLedgerLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        handleDelete={handleDelete}
        id={salaryLedgerId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
      />
    </>
  );
};

export default SalaryLedgerList;

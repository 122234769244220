import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DebitOrCredit,
  PageNumbers,
  PageFor,
  StudentListFor,
  StudentAcctReportType,
  Operation,
} from "../../../utils/Enum.types";
import ConfigurationSettings from "../../Master/configurations/general/Index";

import Modal from "react-modal";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { AppContext } from "../../../context/context";

import FeeLedger from "../FeeLedger/Index";

import { formatter, isOptionEqualToValue } from "../../../utils/UtilFunctions";
import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { AddOrUpdateOrDeleteAcctStdDepositsOB } from "../queries/receipts/mutation/Index";

import { TableHeaderProps } from "../../../Types/Tables";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Avatar from "../../../images/Avatar.svg";
import Home from "../Home/Index";

import LoadingModal from "../../../pages/LoadingModal";
import Settings from "../../../images/Settings.svg";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import Enter from "../../../images/Enter.svg";
import Edit from "../../../images/Edit.svg";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import Close from "../../../images/Close.svg";

import { msgType, responseType } from "../../../utils/Form.types";

import useAcctLedgerData from "../hooks/useAcctLedgerData";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import MessageModal from "../../../pages/MessageModal";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import { payloadTypes } from "../../../context/reducer";
import StudentList from "../common/StudentList";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  AcctLedgerQueryType,
  DepositLedgerQueryType,
} from "../common/QueryTypes";
import { accLedgerDetails } from "../../Transport/paginationTypes";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import useStudentDepositDetails from "../hooks/useStudentDepositDetails";
import DeleteModal from "../../../pages/DeleteModal";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";

interface itemList {
  acctLdgr: accLedgerDetails;
  ob: number;
  sl_no: number;
  isAdded: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  refund: number;
  adjusted: number;
}
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const OpeningBalance = ({ pageType, setModalFlag }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { InstDetails } = useInstDetails(1);
  const { studentId } = useParams();

  //use States for modal
  const [studentModal, setStudentModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [imageString, setImageString] = useState("");

  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const { InstId } = useParams();
  const { token } = useToken();
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE
  );
  const [admNo, setAdmNo] = useState("");
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.GENERAL
  );

  //usestate for input tag
  const [id, setId] = useState(0);
  const [ob, setOB] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [account_ledger_id, SetAccountLedgerId] = useState<responseType | null>(
    null
  );

  const [searchData, setsearchData] = useState("");

  const obRef = useRef<HTMLInputElement>(null);
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = feeDescRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const buttonRef = useRef<HTMLButtonElement>(null);

  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const [deleteId, setDeleteId] = useState(0);

  //use state for arrays

  const [items, setItems] = useState<itemList[]>([]);

  let totalAmount = 0;

  //use queries
  const { user_details } = useLoggedInUserDetails();
  const { id: user_id, user_type } = user_details;
  const { studentData, studentFormData } = useStudentDatabyId();

  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE
  );

  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const { StudentDepositData } = useStudentDepositDetails(
    DepositLedgerQueryType.DEPOSIT_OB_GT_0_BY_STD_ID
  );

  //use mutation
  const [
    AddUpdateDeleteAcctDepositOB,
    { loading: GenerateStudentNonDemandReceiptsLoading },
  ] = useMutation(AddOrUpdateOrDeleteAcctStdDepositsOB, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const existingLedgerSet = new Set(
    items
      .filter(({ isDeleted }) => isDeleted === false)
      .map(({ acctLdgr }) => acctLdgr.id)
  );
  const HandleItems = (e: React.FormEvent) => {
    const itemAlreadyExists = items.filter(
      (d) => account_ledger_id && d.acctLdgr.id === account_ledger_id.value
    );
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      setOB(0);
      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }
    const filterdRes = acctLedgers.responseType.find(
      (d) => account_ledger_id && d.value === account_ledger_id.value
    )!;

    if (filterdRes && ob) {
      setItems((items) => [
        ...items,
        {
          sl_no: items.length + 1,
          ob,
          cr_db: DebitOrCredit.CREDIT,
          acctLdgr: {
            id: filterdRes.value,
            ldgr_desc: filterdRes.label,
          },
          isAdded: true,
          isUpdated: false,
          isDeleted: false,
          adjusted: 0,
          refund: 0,
        },
      ]);
      SetAccountLedgerId(null);
      setOB(0);
    }

    feeDescInputRef?.focus();
  };

  const handleItemEdit = (ob: number, acct_ldgr_id: number) => {
    const res = acctLedgers.responseType.find(
      (ledger) => ledger.value === acct_ldgr_id
    );
    SetAccountLedgerId(res ? res : null);
    setOB(ob);
  };
  const HandleEditItem = (sl_number: number) => {
    const updatedData = items.map((item) => {
      if (item.sl_no === sl_number && account_ledger_id) {
        const newData = {
          ...item,
          isUpdated: item.isAdded === false,
          ob,
          account_ledger_id: account_ledger_id.value,
        };
        return newData;
      }
      return item;
    });
    feeDescInputRef?.focus();

    setEdit(!edit);
    setItems(updatedData);
    setOB(0);
    SetAccountLedgerId(null);
  };

  // eslint-disable-next-line
  items.map((item) => {
    totalAmount += item.ob;
  });

  const handleSubmit = () => {
    if (user_type === EMPTY_STRING || user_id === 0) {
      setMessage({
        flag: true,
        message: "Invalid User details",
        operation: Operation.NONE,
      });
      return;
    }

    AddUpdateDeleteAcctDepositOB({
      variables: {
        token,
        inst_id: InstId,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        student_id: state.studentId,
        user_details,
        input: {
          update_deposit_ob: items
            .filter(({ isAdded, isUpdated }) => isAdded === false && isUpdated)
            .map(({ acctLdgr, ob }) => ({
              acct_ldgr_id: acctLdgr.id,
              opening_bal: ob,
            })),
          add_deposit_ob: items
            .filter(({ isAdded, isDeleted }) => isAdded && isDeleted === false)
            .map(({ acctLdgr, ob }) => ({
              acct_ldgr_id: acctLdgr.id,
              opening_bal: ob,
            })),

          delete_deposit_ob: items
            .filter(({ isAdded, isDeleted }) => isAdded === false && isDeleted)
            .map(({ acctLdgr, ob }) => ({
              acct_ldgr_id: acctLdgr.id,
              opening_bal: ob,
            })),
        },
      },
    }).then(({ data }) => {
      if (data && data.AddOrUpdateOrDeleteAcctStdDepositsOB) {
        setMessage({
          flag: true,
          message: "Deposit Opening Balance Set Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const HandleSelectFeeType = (id: number) => {
    const filterdData = acctLedgers.responseType?.filter(
      ({ value }) => value === id
    );

    if (filterdData) SetAccountLedgerId(filterdData[0]);
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleDelete = (id: number, isAdded: boolean) => {
    setDeleteModal(!deleteModal);
    var updatedData = [];
    if (isAdded) {
      updatedData = items.filter(({ acctLdgr }) => acctLdgr.id !== id);
    } else {
      updatedData = items.map((item) => {
        if (item.acctLdgr.id === id) {
          const newData = {
            ...item,
            isDeleted: item.isAdded === false,
          };
          return newData;
        }
        return item;
      });
    }

    setItems(updatedData);
  };

  const handleClear = () => {
    setAdmNo("");
    setItems([]);
    SetAccountLedgerId(null);
    setOB(0);
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });
    setImageString(EMPTY_STRING);
  };

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (StudentDepositData.data && !StudentDepositData.loading) {
      setItems(
        StudentDepositData.data.GetAcctStdDeposits.edges.map(
          ({ node }, index) => ({
            acctLdgr: {
              id: node.acct_ldgr_details.id,
              ldgr_desc: node.acct_ldgr_details.ldgr_desc,
            },
            sl_no: index + 1,
            ob: node.deposit_ob,
            isAdded: false,
            isUpdated: false,
            isDeleted: false,
            adjusted: node.deposit_adjusted,
            refund: node.deposit_refunded,
          })
        )
      );
    }
  }, [StudentDepositData.data, StudentDepositData.loading]);
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>Deposit Opening Balance</Title>
        </div>
        <div className="configuration-settings">
          <>
            {USE_CONFIG_KEY && (
              <img
                src={Settings}
                alt="/"
                id="settings-icon"
                onClick={() => setConfigurationModal(!configurationModal)}
              />
            )}
          </>
        </div>
      </div>
      <div className="row g-0 non-demandfee-payable">
        <div className="col-4">
          <div className="non-demandfee-payable__grid">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={studentAddmissionNumber}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (admNo || state.studentId) {
                    feeDescInputRef?.focus();
                  }
                }
              }}
              value={
                state.studentId
                  ? studentAddmissionNumber?.find(
                      ({ value }) => value === state.studentId
                    ) ?? null
                  : null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: {
                      studentId: (newValue as responseType)?.value,
                    },
                  });
                } else {
                  handleClear();
                }
              }}
              openOnFocus
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => setAdmNo(e.target.value)}
                  label="Admission No."
                  fullWidth
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  autoFocus
                  className={labelClasses.formControlRoot}
                />
              )}
            />

            <img
              className="data-fetch-icon"
              src={Edit}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>

          <TextField
            label="Register Number"
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.reg_number}
            disabled
          />
          {USE_CATEGORY_KEY && (
            <TextField
               label={categoryLabel}
              className="demand-fee__textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.category}
              disabled
            />
          )}
        </div>

        <div className="col">
          <TextField
            label="Name"
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.std_name}
            disabled
          />
          <TextField
            label={branchLabel}
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.branch}
            disabled
          />
          <TextField
            label={classLabel}
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.class}
            disabled
          />
        </div>
        <div className="col-3">
          <div className="non-demandfee-payable__label-gridbtn">
            <Button
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
              mode="ledger"
            />
          </div>
        </div>
        <div className="col-1 non-demandfee-payable__image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div className="row g-0 non-demandfee-payable__tableblock--deposit-ob">
        <TableContainer className="non-demandfee-payable__table g-0">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.Receipts.OB.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="non-demandfee-payable__table--select-row">
                <TableCell
                  id="td-center"
                  className="non-demandfee-payable__table--slno"
                >
                  {items.length + 1}
                </TableCell>
                <TableCell className="non-demandfee-payable__table--desc">
                  {edit ? (
                    account_ledger_id?.label
                  ) : (
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={acctLedgers.responseType.filter(
                        ({ value }) => !existingLedgerSet.has(value)
                      )}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          account_ledger_id
                        )
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (obRef.current && account_ledger_id) {
                            obRef.current?.focus();
                            obRef.current?.select();
                          }
                          if (items.length >= 1 && account_ledger_id === null) {
                            modeOfTransactionInputRef?.focus();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          SetAccountLedgerId(null);
                        }
                      }}
                      autoHighlight
                      openOnFocus
                      value={account_ledger_id}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          SetAccountLedgerId(newValue as responseType);
                        } else {
                          SetAccountLedgerId(null);
                        }
                      }}
                      ref={feeDescRef}
                      disabled={!state.studentId}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setsearchData(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell
                  id="td-right"
                  className="non-demandfee-payable__table--balance"
                >
                  <input
                    ref={obRef}
                    value={ob}
                    id="td-right"
                    disabled={!account_ledger_id}
                    type="number"
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (ob !== 0) {
                          buttonRef.current?.focus();
                        }
                      }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (Number(e.target.value) >= 0) {
                        setOB(Number(e.target.value));
                      }
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className="non-demandfee-payable__table--balance"
                >
                  <button
                    onClick={(e: React.FormEvent) => {
                      if (ob <= 0) {
                        setMessage({
                          flag: true,
                          message: "Invalid Opening Balance",
                          operation: Operation.NONE,
                        });
                        return;
                      }
                      edit ? HandleEditItem(id) : HandleItems(e);
                    }}
                    ref={buttonRef}
                  >
                    {edit ? "Save" : <img src={Enter} alt="/" />}
                  </button>
                </TableCell>
              </TableRow>
              {items
                .filter(({ isDeleted }) => isDeleted === false)
                .map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell id="td-center">{index + 1}</TableCell>
                      <TableCell>{item.acctLdgr.ldgr_desc}</TableCell>
                      <TableCell
                        id="td-right"
                        className="non-demandfee-payable__table--balance"
                      >
                        {format(item.ob)}
                      </TableCell>
                      <TableCell id="td-center">
                        {(item.adjusted > 0 || item.refund > 0) === false ? (
                          <>
                            <img
                              src={EditProfile}
                              alt="/"
                              onClick={() => {
                                if (edit === false) {
                                  handleItemEdit(item.ob, item.acctLdgr.id);
                                  HandleSelectFeeType(item.acctLdgr.id);
                                  setId(item.sl_no);
                                  obRef.current?.focus();
                                } else {
                                  setOB(0);
                                  SetAccountLedgerId(null);
                                }
                                setEdit(!edit);
                              }}
                            />

                            {items.length ? (
                              <img
                                id="delete-profile"
                                src={Delete}
                                alt="/"
                                onClick={() => {
                                  handleDelete(item.acctLdgr.id, item.isAdded);
                                }}
                              />
                            ) : null}
                          </>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} className="total">
                  Total :
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalAmount)}
                </TableCell>
                <TableCell id="tfoot-td"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>

      <div className="non-demandfee-payable__buttons">
        <Button
          mode="save"
          disabled={
            !state.studentId ||
            items.length === 0 ||
            GenerateStudentNonDemandReceiptsLoading ||
            message.flag ||
            items.filter(({ ob }) => ob === 0).length > 0
          }
          onClick={handleSubmit}
          buttonref={saveButtonRef}
        />

        <Button
          mode="clear"
          disabled={!state.studentId}
          onClick={handleClear}
        />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </div>
      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.CURR}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* fee ledger Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setFeeledgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
            />
          </div>
        </div>
      </Modal>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.ADVANCE_RECEIPT}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentNonDemandReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default OpeningBalance;

import Home from "../../Home/Index";
import {
  DocumentAcknowledgement,
  Operation,
} from "../../../../utils/Enum.types";
import { Step, StepLabel, Stepper } from "@mui/material";
import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";
import { Title } from "../../../../stories/Title/Title";
import { GetMstInstUploadDocTypesList } from "../MandatoryDocs/StudentDocs";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import Settings from "../../../../images/Settings.svg";

import { Button } from "../../../../stories/Button/Button";
import { studentRegStepHeader } from "./NxtBasicDetails";
import DocsUploadComponent from "../Registration/DocsUploadComponent";
import {
  PrintModalStyles,
  WaiveOffModalStyles,
} from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import { useEffect, useState } from "react";
import PrintDocuments from "../../../Print/Documents/Print";
import { useLazyQuery } from "@apollo/client";
import {
  GetInstDocumentsData,
  GetInstDocumentVars,
} from "../../../Print/Documents/Types";
import { GetInstDocuments } from "../../../Print/Documents/queries";
import useToken from "../../../../customhooks/useToken";
import {
  GetStudentDocsByStudentIdData,
  GetStudentDocsByStudentIdVars,
} from "../Documents/Index";
import { GetStudentDocsByStudentId } from "../../../../queries/students/list/byId";

interface props {
  type: Operation;
  step: number;
}
export interface DocsType extends GetMstInstUploadDocTypesList {
  preview: File | null;
  fromDb: boolean;
  fromDbUrl: string;
  docId: number;
  index?: number;
  collected?: boolean;
  isOriginal?: boolean;
  isUpdatedFlag?: boolean;
  dateCollected?: string;
}
const NxtDocsUpload = ({ type, step }: props) => {
  const { token } = useToken();
  const { studentId } = useParams();
  const [configuration, setConfiguration] = useState(false);
  const stepsHeader = studentRegStepHeader();
  const [addDocTypeModal, setAddDocTypeModal] = useState(false);
  const [printAcknowledgement, setPrintAcknowledgement] = useState(false);

  const navigate = useNavigate();
  const { InstId } = useParams();
  const [GetInstDocumentData, { data: DocumentsList }] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments, {
    variables: {
      token,
      inst_id: InstId!,
      custom_doc_name: DocumentAcknowledgement.STD_DOC_ACK,
    },
  });
  const [GetDocuments, { data: documentsData }] = useLazyQuery<
    GetStudentDocsByStudentIdData,
    GetStudentDocsByStudentIdVars
  >(GetStudentDocsByStudentId, {
    variables: {
      student_id: Number(studentId!),
      token,
    },
  });

  useEffect(() => {
    if (token && InstId && studentId) {
      GetDocuments();
    }
  }, [token, InstId, GetDocuments, documentsData, studentId]);
  useEffect(() => {
    if (token && InstId!) {
      GetInstDocumentData();
    }
    // eslint-disable-next-line
  }, [token, GetInstDocumentData]);
  const customDocID =
    DocumentsList && DocumentsList.GetInstDocuments.map((res) => res.id);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="student_docs">
        {type === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        <Title>Student Documents Upload</Title>
        <StudentAcademicDetails />
        <div
          className={`student_docs__table${
            type === Operation.CREATE ? "--create" : "--update"
          }`}
        >
          <DocsUploadComponent type={type} />
        </div>
        <Button
          mode={`${type === Operation.CREATE ? "finish" : "back"}`}
          onClick={() => {
            if (type === Operation.CREATE) {
              setAddDocTypeModal(!addDocTypeModal);
            } else {
              navigate(-1);
            }
          }}
        />
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addDocTypeModal}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <b>
              Student data saved Sucessfully <br></br>Would you like to download
              aknowledgement?
            </b>
            <Button
              mode="yes"
              onClick={() => setPrintAcknowledgement(!printAcknowledgement)}
            ></Button>
            <Button
              mode="no"
              onClick={() =>
                navigate(`/${InstId}/admissions/students/reservations`)
              }
            ></Button>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setAddDocTypeModal(!addDocTypeModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printAcknowledgement}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PrintDocuments
              setModal={setPrintAcknowledgement}
              selectedId={Number(customDocID)}
              selectedStudents={[Number(studentId)!]}
              pageType={DocumentAcknowledgement.STD_DOC_ACK}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Settings}
              alt=""
              onClick={() => setConfiguration(!configuration)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NxtDocsUpload;

import NavBar from "../../../components/common/Navbar/Index";
import { LicenseTypes } from "../../../utils/Enum.types";
interface DashBoardProps {
  DashBoardRequired: boolean;
  NavType: LicenseTypes.EDUATE_CONFIGURATION | LicenseTypes.EDUATE_CUSTOMER;
}
const Index = ({ DashBoardRequired, NavType }: DashBoardProps) => {
  return (
    <>
      <NavBar NavType={NavType} />
      {DashBoardRequired && <p>Home Page</p>}
    </>
  );
};

export default Index;

import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { IdCardPrintTypes } from "../../Enum.types";
import { GlobalPageConfigData } from "../../../../Types/configtypes";

const useIDCardSwConfig = () => {
  const { configData } = useSwConfigData([
    IdCardPrintTypes.ID_PRINT_CUSTOMER_NAME,
    IdCardPrintTypes.ID_PRINT_INST_NAME,
    IdCardPrintTypes.ID_PRINT_INST_LOGO,
    IdCardPrintTypes.ID_PRINT_FATHER_NAME,
    IdCardPrintTypes.ID_PRINT_ADMISSION_NUMBER,
    IdCardPrintTypes.ID_PRINT_CLASS_NAME,
    IdCardPrintTypes.ID_PRINT_SECTION_NAME,
    IdCardPrintTypes.ID_PRINT_INST_ADDRESS,
    IdCardPrintTypes.ID_PRINT_STD_ADDRESS,
    IdCardPrintTypes.ID_LEFT_MARGIN,
    IdCardPrintTypes.ID_RIGHT_MARGIN,
    IdCardPrintTypes.ID_BOTTOM_MARGIN,
    IdCardPrintTypes.ID_TOP_MARGIN,
    IdCardPrintTypes.ID_PRINT_STD_MOBILE_NO,
    IdCardPrintTypes.ID_FOOTER_TEXT,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let customerName = false;
    let instName = false;
    let instLogo = false;
    let fatherName = false;
    let admNo = false;
    let className = false;
    let mobileNo = false;
    let sectionName = false;
    let regNo = false;
    let instAddress = false;
    let studentAddress = false;
    let EnableFooterText = false;
    let footerText = "";
    let marginLeft;
    let marginRight;
    let marginTop;
    let marginBottom;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case IdCardPrintTypes.ID_PRINT_CUSTOMER_NAME:
            customerName = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_INST_NAME:
            instName = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_INST_LOGO:
            instLogo = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_FATHER_NAME:
            fatherName = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_ADMISSION_NUMBER:
            admNo = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_CLASS_NAME:
            className = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_SECTION_NAME:
            sectionName = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_REGISTER_NUMBER:
            regNo = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_INST_ADDRESS:
            instAddress = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_STD_MOBILE_NO:
            mobileNo = item.config_boolean_value;
            break;
          case IdCardPrintTypes.ID_PRINT_STD_ADDRESS:
            studentAddress = item.config_boolean_value;
            break;

          case IdCardPrintTypes.ID_LEFT_MARGIN:
            marginLeft = item.config_integer_value;
            break;
          case IdCardPrintTypes.ID_RIGHT_MARGIN:
            marginRight = item.config_integer_value;
            break;
          case IdCardPrintTypes.ID_TOP_MARGIN:
            marginTop = item.config_integer_value;
            break;
          case IdCardPrintTypes.ID_BOTTOM_MARGIN:
            marginBottom = item.config_integer_value;
            break;
          case IdCardPrintTypes.ID_FOOTER_TEXT:
            footerText = item.config_string_value;
            EnableFooterText = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      customerName,
      instName,
      instLogo,
      fatherName,
      admNo,
      className,
      sectionName,
      regNo,
      instAddress,
      studentAddress,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      mobileNo,
      footerText,
      EnableFooterText,
    };
  };

  return filterDataByConfigKey(configData.data?.GetSwConfigVariables!);
};

export default useIDCardSwConfig;

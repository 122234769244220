import { gql } from "@apollo/client";

export const AddLibBookAuthor = gql`
  mutation AddLibBookAuthor(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateLibBookAuthorInput!
  ) {
    AddLibBookAuthor(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

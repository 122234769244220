import React, { useEffect, useMemo, useState } from "react";
import List from "./List";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Avatar from "../../../../images/Avatar.svg";

import { EMPTY_STRING } from "../../../../utils/constants";
import { TreeType } from "../../DailyActivities/Home";
import { responseType } from "../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../../utils/Enum.types";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import useAcdTeacherTree from "../../../../customhooks/general/useAcdTeacherTress";
import useEmpDetailsById from "../../../HR/hooks/useEmpDetailsById";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const ViewAlloted = () => {

  const { employeeFormData } = useEmpDetailsById();
  const [imageString, setImageString] = useState("");

  const navigate = useNavigate();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  let idsSet = new Set();

  const { data } = useAcdTeacherTree(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0
  );

  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }
    if (USE_SECTION_KEY) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);

  const modifiedArrayForTable: TreeType[] = data
    ? data.GetAcdTeacherTree.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.flatMap((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          filterThis: false,
                          sub_desc: level5.subject_details.map(
                            (res) => res.subj_desc
                          ),
                          sub_code: level5.subject_details.map(
                            (res) => res.subj_code
                          ),
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count,
                        level5_name: EMPTY_STRING,
                        level1_id: level1.acd_level_1_id,
                        level2_id: level2.acd_level_2_id,
                        level3_id: level3.acd_level_3_id,
                        level4_id: level4.acd_level_4_id,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),
                        sub_desc: level4.subject_details.map(
                          (res) => res.subj_desc
                        ),
                        sub_code: level4.subject_details.map(
                          (res) => res.subj_code
                        ),
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level5_name: EMPTY_STRING,
                    level1_id: level1.acd_level_1_id,
                    level2_id: level2.acd_level_2_id,
                    level3_id: level3.acd_level_3_id,
                    level4_id: 0,
                    level5_id: 0,

                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                    sub_desc: level3.subject_details.map(
                      (res) => res.subj_desc
                    ),
                    sub_code: level3.subject_details.map(
                      (res) => res.subj_code
                    ),
                  };

                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level5_name: EMPTY_STRING,
                level1_id: level1.acd_level_1_id,
                level2_id: level2.acd_level_2_id,
                level3_id: 0,
                level4_id: 0,
                level5_id: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                sub_desc: level2.subject_details.map((res) => res.subj_desc),
                sub_code: level2.subject_details.map((res) => res.subj_code),
              };

              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level5_name: EMPTY_STRING,
            level1_id: level1.acd_level_1_id,
            level2_id: 0,
            level3_id: 0,
            level4_id: 0,
            level5_id: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
            sub_desc: level1.subject_details.map((res) => res.subj_desc),
            sub_code: level1.subject_details.map((res) => res.subj_code),
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];

  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  useEffect(() => {
    if (employeeFormData.emp_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        employeeFormData.emp_profile_filename,
        false,
        setImageString
      );
    }
  }, [employeeFormData.emp_profile_filename]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Teacher Allocated List</Title>
      <div className="alloted-teacher-report">
        <div className="alloted-teacher-report__left">
          <List />
        </div>
        <div className="alloted-teacher-report__right">
          <div className="alloted-teacher-report__view">
            <div className="alloted-teacher-report__view--details row g-0">
              <div className="col">
                <TextField
                  disabled
                  className="alloted-teacher-report__view--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Employee ID"
                  value={employeeFormData && employeeFormData.emp_id}
                />
                <TextField
                  disabled
                  className="alloted-teacher-report__view--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Mobile No."
                  value={employeeFormData && employeeFormData.emp_mobile}
                />

                <TextField
                  disabled
                  className="alloted-teacher-report__view--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Job Type"
                  value={employeeFormData && employeeFormData.job_type}
                />
              </div>
              <div className="col">
                <TextField
                  disabled
                  className="alloted-teacher-report__view--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Name"
                  value={
                    employeeFormData &&
                    employeeFormData.emp_name
                  }
                />
                <TextField
                  disabled
                  className="alloted-teacher-report__view--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Designation"
                  value={
                    employeeFormData && employeeFormData.designation
                  }
                />

                <TextField
                  disabled
                  className="alloted-teacher-report__view--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Work Experience"
                  value={employeeFormData && employeeFormData.emp_experience}
                />
              </div>
              <div className="col">
                <TextField
                  disabled
                  className="alloted-teacher-report__view--date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Joined Date"
                  value={employeeFormData && toInputStandardDate(employeeFormData.emp_doj)}
                />
                <TextField
                  label={"Department"}
                  className="alloted-teacher-report__view--textfield"
                  value={employeeFormData && employeeFormData.department}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />

                <TextField
                  disabled
                  className="alloted-teacher-report__view--textfield"
                  label={"Gender"}
                  value={employeeFormData && employeeFormData.emp_sex}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              </div>
              <div className="col-1 alloted-teacher-report__view--image">
              {imageString ? (
                <img src={imageString} alt="" />
              ) : (
                <img src={Avatar} alt="" />
              )}
              </div>
            </div>
            <div className="alloted-teacher-report__view--select row g-0">
              {USE_DEPARTMENT_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={departmentDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, departmentSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (departmentSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setDepartmentSelected(null);
                      }
                    }}
                    openOnFocus
                    value={departmentSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setDepartmentSelected(newValue as responseType);
                      } else {
                        setDepartmentSelected(null);
                      }
                      setBranchSelected(null);
                      setClassSelected(null);
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={departmentLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        id="outlined Departments"
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_BRANCH_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={branchDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, branchSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (branchSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setBranchSelected(null);
                      }
                    }}
                    openOnFocus
                    value={branchSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setBranchSelected(newValue as responseType);
                      } else {
                        setBranchSelected(null);
                      }
                      setClassSelected(null);
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={branchLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_CLASS_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={classDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, classSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (classSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setClassSelected(null);
                      }
                    }}
                    openOnFocus
                    value={classSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setClassSelected(newValue as responseType);
                      } else {
                        setClassSelected(null);
                      }
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={classLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                        fullWidth
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_SEMESTER_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={semesterDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semesterSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (semesterSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setSemesterSelected(null);
                      }
                    }}
                    openOnFocus
                    value={semesterSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSemesterSelected(newValue as responseType);
                      } else {
                        setSemesterSelected(null);
                      }
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={semesterLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_SECTION_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, sectionSelected)
                    }
                    className={labelClasses.inputRoot}
                    options={sectionDropDown}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (sectionSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setSectionSelected(null);
                      }
                    }}
                    openOnFocus
                    value={sectionSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSectionSelected(newValue as responseType);
                      } else {
                        setSectionSelected(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={sectionLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
            <div className="alloted-teacher-report__view--tableblock">
              <TableContainer className="teacher-report__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {USE_DEPARTMENT_KEY ? (
                        <TableCell>{departmentLabel}</TableCell>
                      ) : null}
                      {USE_BRANCH_KEY ? (
                        <TableCell>{branchLabel}</TableCell>
                      ) : null}
                      {USE_CLASS_KEY ? (
                        <TableCell>{classLabel}</TableCell>
                      ) : null}
                      {USE_SEMESTER_KEY ? (
                        <TableCell>{semesterLabel}</TableCell>
                      ) : null}
                      {USE_SECTION_KEY ? (
                        <TableCell>{sectionLabel}</TableCell>
                      ) : null}
                      {/* <TableCell>Total Students</TableCell>
                    <TableCell>Filled</TableCell> */}

                      <TableCell>Allocated Subjects</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                      if (data) {
                        return (
                          <TableRow key={i}>
                            {data.level1_name !== EMPTY_STRING ? (
                              <TableCell rowSpan={data.level1_rowspan}>
                                {data.level1_name}
                              </TableCell>
                            ) : null}
                            {data &&
                            (data.level2_name ||
                              data.level2_name !== EMPTY_STRING) ? (
                              <TableCell rowSpan={data.level2_rowspan}>
                                {data.level2_name}
                              </TableCell>
                            ) : null}
                            {data && data.level3_name !== EMPTY_STRING ? (
                              <TableCell rowSpan={data.level3_span}>
                                {data.level3_name}
                              </TableCell>
                            ) : null}
                            {data && data.level4_name !== EMPTY_STRING ? (
                              <TableCell rowSpan={data.level4_span}>
                                {data.level4_name}
                              </TableCell>
                            ) : null}
                            {data.level5_name !== EMPTY_STRING ? (
                              <TableCell>{data.level5_name}</TableCell>
                            ) : null}

                            <TableCell className="teacher-report__table--subjects">
                              <ul>
                                {data.sub_desc &&
                                  data.sub_desc.map(
                                    (subject: string, index: number) => (
                                      <li key={index}>
                                        <span>
                                          {index + 1}. {subject}
                                        </span>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Button mode="export" />
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAlloted;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetStudentEnquires } from "../../../queries/students/list/newApi";
import { Direction, SortBy, StdEnqQueryType } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import { toIsoDate } from "../../../utils/UtilFunctions";
import useActiveAcademicYear from "../../Academics/hooks/useActiveAcademicYear";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import {
  GetStudentsEnquiryData,
  GetStudentsEnquiryVars,
  YesNoOptionsType,
} from "../StudentEnquiry";
import { AppContext } from "../../../context/context";
import { EMPTY_STRING } from "../../../utils/constants";

const useStudentEnquiries = (
  rowsPerPage: number | null,
  queryType: StdEnqQueryType,
  searchData: string,
  followUpStatus: string,
  boughtApplication: YesNoOptionsType | null,
  admitted: YesNoOptionsType | null,
  sortBy: SortBy,
  endDate: string,
  startDate: string,
  branchId?: number,
  classId?: number,
  empId?: number
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();

  const [studentEnqDropDown, setStudentEnqDropDown] = useState<responseType[]>(
    []
  );
  const [GetStudentEnquiry, StudentEnquiryData] = useLazyQuery<
    GetStudentsEnquiryData,
    GetStudentsEnquiryVars
  >(GetStudentEnquires, {
    variables: {
      name: searchData,
      lastFollowUpStatus: followUpStatus ? followUpStatus : null,
      boughtApplication: boughtApplication ? boughtApplication.value : null,
      admitted: admitted ? admitted.value : null,
      branchID: branchId ? branchId : null,
      classID: classId ? classId : null,
      first: rowsPerPage ? rowsPerPage : null,
      after: null,
      sortBy,
      direction: Direction.ASC,
      token,
      enq_start_date: startDate ? toIsoDate(startDate) : EMPTY_STRING,
      enq_end_date: endDate ? toIsoDate(endDate) : EMPTY_STRING,
      pr_emp_id: empId ? empId : state.employeeId,
      enq_query_type: queryType,
      today_date: toIsoDate(serverDate),
      inst_id: InstId!,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      !activeAcademicYearData.loading &&
      activeAcademicYearData.data &&
      state.ActiveFinYr &&
      serverDate
    ) {
      GetStudentEnquiry().then(({ data }) => {
        if (data) {
          setStudentEnqDropDown(
            data.GetStudentEnquires.edges.map((edge) => ({
              label: `${edge.node.first_name} ${edge.node.middle_name} ${edge.node.last_name}`,
              value: edge.node.id,
            }))
          );
        }
      });
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveFinYr,
    GetStudentEnquiry,
    serverDate,
    searchData,
  ]);
  return {
    EnquiryData: {
      StudentEnquiryData,
      studentEnqDropDown,
    },
  };
};

export default useStudentEnquiries;

import { useLazyQuery, useMutation } from "@apollo/client";
import React, { ChangeEvent, useEffect, useState } from "react";
import { GetDocumentConfig, GetInstDocuments } from "./queries";
import {
  GetDocumentConfigData,
  GetDocumentConfigKeys,
  GetDocumentConfigVars,
  GetInstDocumentVars,
  GetInstDocumentsData,
} from "./Types";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { Label } from "../../../stories/Label/Label";
import { FormControlLabel, FormGroup } from "@mui/material";
import { AntSwitch } from "../../../pages/Switch";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { UpdateDocumentConfig } from "./mutation";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { DocumentAcknowledgement, Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import { EMPTY_STRING } from "../../../utils/constants";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";

interface Props {
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: DocumentAcknowledgement;
}

const Configurations = ({ setModal, pageType }: Props) => {
  const { token } = useToken();
  const { InstId, documentId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [customId, setCustomId] = useState(0);
  const [GetInstDocumentData] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments);

  // eslint-disable-next-line
  const [GetDocumentConfigDetails, { data: ConfigReferenceData }] =
    useLazyQuery<GetDocumentConfigData, GetDocumentConfigVars>(
      GetDocumentConfig
    );

  const [pageSwDetails, setPageSwConfigList] = useState<
    GetDocumentConfigKeys[]
  >([]);

  useEffect(() => {
    if (
      token &&
      documentId &&
      pageType !== DocumentAcknowledgement.STD_DOC_ACK
    ) {
      GetDocumentConfigDetails({
        variables: {
          token,
          inst_id: InstId!,
          document_main_id: Number(documentId),
        },
      }).then(({ data }) => {
        if (data) {
          setPageSwConfigList(data.GetDocumentConfig);
        }
      });
    }
  }, [token, InstId, GetDocumentConfigDetails, pageType, documentId]);

  const handleChangeValue = (
    key: string,
    type:
      | "BOOL"
      | "INT"
      | "primary_list_key"
      | "secondary_list_key"
      | "VARCHAR",
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    if (type === "BOOL") {
      const newState =
        pageSwDetails &&
        // eslint-disable-next-line
        pageSwDetails.map((obj) =>
          obj.config_key === key
            ? { ...obj, config_boolean_value: switchValue! }
            : obj
        );
      setPageSwConfigList(newState);
    }

    if (type === "INT") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_integer_value: Number(selectedValue)! }
          : obj
      );
      setPageSwConfigList(newState);
    }
    if (type === "primary_list_key") {
      const config_key = key + "_" + selectedValue!;
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? {
              ...obj,
              config_secondary_list_key: config_key,
              config_string_value: selectedValue!,
            }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "secondary_list_key") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }
  };

  const [UpdateDocumentConfigDetails, { loading: updationLoading }] =
    useMutation(UpdateDocumentConfig, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const handleUpdateSwConfig = () => {
    if (InstId)
      UpdateDocumentConfigDetails({
        variables: {
          token,
          inst_id: InstId,
          input: pageSwDetails.map((pageSwDetail) => ({
            id: pageSwDetail.id,
            config_key: pageSwDetail.config_key,
            config_boolean_value: pageSwDetail.config_boolean_value,
            config_integer_value: pageSwDetail.config_integer_value,
            config_double_value: pageSwDetail.config_double_value,
            config_string_value: pageSwDetail.config_string_value,
            config_string_value_2: pageSwDetail.config_string_value_2,
          })),
          user_details,
        },
        refetchQueries: [
          {
            query: GetDocumentConfig,
            variables: {
              token,
              inst_id: InstId!,
              document_main_id:
                pageType === DocumentAcknowledgement.STD_DOC_ACK
                  ? Number(customId)
                  : Number(documentId),
              input: {
                str_value: EMPTY_STRING,
                int_value: 0,
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Global Configuration Saved Successfully",
            operation: Operation.CREATE,
          });
        }
        //   handleClear();
      });
  };
  useEffect(() => {
    if (token && InstId! && pageType === DocumentAcknowledgement.STD_DOC_ACK) {
      GetInstDocumentData({
        variables: {
          token,
          inst_id: InstId!,
          custom_doc_name: DocumentAcknowledgement.STD_DOC_ACK,
        },
      }).then(({ data }) => {
        if (data) {
          setCustomId(data.GetInstDocuments[0].id);
          GetDocumentConfigDetails({
            variables: {
              token,
              inst_id: InstId!,
              document_main_id: data.GetInstDocuments[0].id,
            },
          }).then(({ data }) => {
            if (data) {
              setPageSwConfigList(data.GetDocumentConfig);
            }
          });
        }
      });
    }
  }, [token, GetInstDocumentData, GetDocumentConfigDetails, pageType]);
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <div className="document-config">
        <Title>Configurations</Title>
        <div className="document-config__parameters">
          {pageSwDetails.map((list, index) => (
            <div className="document-config__parameters--list" key={index}>
              <Label>
                {index + 1} &nbsp;
                {list.config_form_label_caption}
              </Label>

              {list.config_key_data_storage_type === "BOOLEAN" ? (
                <FormGroup>
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                      <AntSwitch
                        checked={list.config_boolean_value}
                        onClick={() => {
                          const newConfigValue = !list.config_boolean_value;
                          handleChangeValue(
                            list.config_key,
                            "BOOL",
                            newConfigValue,
                            null
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : null}
              {list.config_key_data_storage_type === "BOOLEAN+VARCHAR" ? (
                <FormGroup>
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                      <AntSwitch
                        checked={list.config_boolean_value}
                        onClick={() => {
                          const newConfigValue = !list.config_boolean_value;
                          handleChangeValue(
                            list.config_key,
                            "BOOL",
                            newConfigValue,
                            null
                          );
                        }}
                      />
                    }
                  />
                  {list.config_boolean_value && (
                    <Input
                      placeholder=""
                      value={list.config_string_value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValue(
                          list.config_key,
                          "VARCHAR",
                          null,
                          e.target.value
                        )
                      }
                    />
                  )}
                </FormGroup>
              ) : null}
              {list.config_depends_on_primary_list_key === false &&
              list.config_key_data_storage_type === "VARCHAR" ? (
                <Input
                  value={list.config_string_value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValue(
                      list.config_key,
                      "VARCHAR",
                      null,
                      e.target.value
                    )
                  }
                />
              ) : null}
              {list.config_key_data_storage_type === "INT" ? (
                <Input
                  value={list.config_integer_value}
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValue(
                      list.config_key,
                      "INT",
                      null,
                      e.target.value
                    )
                  }
                />
              ) : null}
              {list.config_key_data_storage_type === "VARCHAR" ? (
                <Input
                  value={list.config_string_value}
                  type="color"
                  onChange={(
                    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
                  ) =>
                    handleChangeValue(
                      list.config_key,
                      "VARCHAR",
                      null,
                      e.target.value
                    )
                  }
                />
              ) : null}
            </div>
          ))}
        </div>
        <Button mode="save" onClick={handleUpdateSwConfig} />
        {pageType === DocumentAcknowledgement.GENERAL ? null : (
          <Button mode="cancel" onClick={() => setModal?.(false)} />
        )}
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={updationLoading} />
    </>
  );
};

export default Configurations;

import { gql } from "@apollo/client";

export const GetPayRollJobTypesByInstId = gql`
  query GetPayRollJobTypesByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: PayRollJobTypeOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
  ) {
    GetPayRollJobTypesByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ jobTypeDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          job_type_desc
          job_type_idx
        }
      }
    }
  }
`;

export const GetPayRollJobTypeIdsByNames = gql`
  query GetPayRollJobTypeIdsByNames(
    $token: String!
    $inst_id: ID!
    $job_type_desc: [String!]
  ) {
    GetPayRollJobTypeIdsByNames(
      token: $token
      inst_id: $inst_id
      job_type_desc: $job_type_desc
    ) {
      id
      job_type_desc
    }
  }
`;

export const GetPayRollJobTypeByNode = gql`
  query ($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollJobType {
        job_type_desc
      }
    }
  }
`;

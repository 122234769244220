import { gql } from "@apollo/client";

export const GetAcdSectionsByAcdSemesterIds = gql`
  query GetAcdSectionsByAcdSemesterIds(
    $token: String!
    $acd_semester_ids: [ID!]!
  ) {
    GetAcdSectionsByAcdSemesterIds(
      token: $token
      acd_semester_ids: $acd_semester_ids
    ) {
      section_desc
      section_idx
      id
      acd_semester_id
      section_id
    }
  }
`;
export const GetAcdSectionsByInstId = gql`
  query GetAcdSectionsByInstId($token: String!, $inst_id: ID!) {
    GetAcdSectionsByInstId(token: $token, inst_id: $inst_id) {
      section_desc
      section_idx
      id
      acd_semester_id
      section_id
    }
  }
`;

export const GetAcdSubjectMainSectionUnmapped = gql`
  query GetAcdSubjectMainSectionUnmapped(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_semester_id: ID!
  ) {
    GetAcdSubjectMainSectionUnmapped(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_semester_id: $acd_semester_id
    ) {
      acd_section_id
      section_desc
    }
  }
`;

export const GetAcdSubjectMainSectionMapped = gql`
  query GetAcdSubjectMainSectionMapped(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_semester_id: ID!
  ) {
    GetAcdSubjectMainSectionMapped(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_semester_id: $acd_semester_id
    ) {
      acd_section_id
      section_desc
    }
  }
`;

export const GetAcdInstDetailsByNodeId = gql`
  query GetAcdInstDetailsByNodeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on AcdInstSection {
        section_desc
      }
      ... on AcdInstClass {
        class_desc
      }
      ... on AcdInstSemester {
        sem_desc
      }
    }
  }
`;
export const GetAcdInstDetailsByIdForteacherDashBoard = gql`
  query GetAcdInstDetailsByIdForteacherDashBoard(
    $ids: [ID!]!
    $token: String!
  ) {
    nodes(ids: $ids, token: $token) {
      id
      ... on AcdInstSection {
        section_desc
        id
        acd_semester_details {
          sem_desc
          acd_class_details {
            class_desc
            acd_branch_details {
              branch_desc
              acd_dept_details {
                dept_desc
              }
            }
          }
        }
        __typename
      }
      ... on AcdInstClass {
        class_desc
        id
        acd_branch_details {
          branch_desc
          acd_dept_details {
            dept_desc
          }
        }
      }
      ... on AcdInstSemester {
        sem_desc
        id
        acd_class_details {
          class_desc
          acd_branch_details {
            branch_desc
            acd_dept_details {
              dept_desc
            }
          }
        }
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const UpdatePayRollAcdYr = gql`
  mutation UpdatePayRollAcdYr(
    $id: ID!
    $input: UpdatePayRollAcdYrInput!
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdatePayRollAcdYr(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import { gql } from "@apollo/client";

export const GetAcdSubjectCategories = gql`
  query GetAcdSubjectCategories(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $acd_yr_id: ID!
    $orderBy: AcdSubjectCategoryOrder
    $name: String!
  ) {
    GetAcdSubjectCategories(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      acd_yr_id: $acd_yr_id
      orderBy: $orderBy
      where: { or: [{ subjCatNameContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          subj_cat_name
          subj_cat_details {
            id
            subject_details {
              id
              subj_code
              subj_desc
              subj_board_code
            }
          }
        }
      }
    }
  }
`;

export const GetSubjectGroupByNodeId = gql`
  query GetSubjectGroupByNodeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on AcdSubjectCategory {
        id
        subj_cat_name
        subj_cat_details {
          subject_details {
            id
            subj_code
            subj_desc
          }
        }
      }
    }
  }
`;

import React, { useState } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  EduateModule,
  LedgersForType,
  LicenseTypes,
  ModuleName,
  Operation,
  PageFor,
  PayRollReportsTypes,
  ProvisionalSalaryDetailsType,
} from "../utils/Enum.types";
import HRCalender from "../Modules/HR/calendar/HolidayEntry/Index";
import EmpBasicDetails from "../Modules/HR/Employee/Registration/BasicDetails";
import EmpPersonalDetails from "../Modules/HR/Employee/Registration/PersonalDetails";
import EmpOtherDetails from "../Modules/HR/Employee/Registration/OtherDetails";
import EmpPreviewPersonalData from "../Modules/HR/Employee/EmployeePreview/PersonalDetails";
import EmpPreviewBasicData from "../Modules/HR/Employee/EmployeePreview/BasicData";
import EmpPreviewOtherDetailsData from "../Modules/HR/Employee/EmployeePreview/OtherDetails";
import EmployeeList, {
  ComponentForEmployee,
} from "../Modules/HR/Employee/List/Index";
import HRHome from "../Modules/HR/Home/Index";
import PayRollJobType from "../Modules/HR/Payroll/JobType/Index";
import PayRollCategory from "../Modules/HR/Payroll/Category/Index";
import PayRollDepartment from "../Modules/HR/Payroll/Department/Index";
import PayRollDesignation from "../Modules/HR/Payroll/Designation/Index";
import PayRollGrade from "../Modules/HR/Payroll/Grade/Index";
import PayRollLeaveLedger from "../Modules/HR/Payroll/LeaveLedger/Index";
import PayRollAttendance from "../Modules/HR/Attendance/Index";
import PayRollMonthlyList from "../Modules/HR/Attendance/MonthlyList";
import SalaryStructure from "../Modules/HR/Payroll/SalaryStructure/Index";
import AssignSalaryStructure from "../Modules/HR/Payroll/SalaryStructure/AssignSalaryStructure";
import SelectMonth from "../Modules/HR/Payroll/SalaryProcess/SelectMonth";
import ProvisionalSalaryDetails from "../Modules/HR/Payroll/SalaryProcess/ProvisionalSalaryDetails";
import CloseSalaryProcess from "../Modules/HR/Payroll/SalaryProcess/CloseSalaryProcess";
import SelectEmpForSalary from "../Modules/HR/Payroll/SalaryProcess/SelectEmpForSalary";
import EmpAttendance from "../Modules/HR/Payroll/SalaryProcess/EmpAttendance";
import ConfirmEmployeeSalary from "../Modules/HR/Payroll/SalaryProcess/ConfirmEmployeeSalary";
import PayRoleAcademicYear from "../Modules/HR/Payroll/PayRollAcdYear/Index";
import PayRollReports from "../Modules/HR/Reports/PayRoll/Index";
import AssignLeaveLedger from "../Modules/HR/Employee/LeaveLedger/AssignLeaveLedger";
import DefaultLeaveLedger from "../Modules/HR/Payroll/LeaveLedger/DefaultLeaveLedger";
import SalaryLedger from "../Modules/HR/Payroll/SalaryLedeger/Index";
import EmployeeLogin from "../Modules/HR/Employee/EnableLogin/Index";
import HRConfiguration from "../Modules/Configurations/PerModuleConfiguration";
import {
  ComponentFor,
  SwConfigQueryType,
} from "../Modules/HR/enums/Enum.types";
import useEduateModuleConfig from "../customhooks/useModulesConfiguartion";
import Page404 from "../pages/Page404";
import useUserRightsByEmpId from "../Modules/UserRights/hooks/useUserRightsByEmpId";
import OrganizationalChart from "../Modules/HR/OrganizationalCharts/Index";
// import OrganizationalChart from "../Modules/HR/OrganizationalCharts/Index";
import EmployeeTree from "../Modules/HR/EmployeeTree/Index";
import EmpResetPassword from "../Modules/HR/Employee/ResetPassword/Index";
import AccountingUsers from "../Modules/Accounts/Users/Index";
import TeacherLogin from "../Modules/Academics/Users/Index";
import AcademicContributions from "../Modules/HR/Employee/Registration/AcademicContributions";
import EmpDocumentsUpload from "../Modules/HR/Employee/Registration/EmpDocumentsUpload";
import EmpAcdContribution from "../Modules/HR/Employee/EmployeePreview/EmpAcdContribution";
import EmpDocsPreview from "../Modules/HR/Employee/EmployeePreview/EmpDocumentsPreview";
const PayrollRoutes = () => {
  const { error } = useEduateModuleConfig(EduateModule.PAYROLL);
  const { USE_PAYROLL } = useUserRightsByEmpId();

  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);
  return (
    <Routes>
      {error || !USE_PAYROLL.flag ? (
        <>
          <Route path="*" element={<Page404 />} />
        </>
      ) : (
        <>
          <Route
            path="/payroll/category"
            element={
              <PayRollCategory
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />
          <Route
            path="/payroll/jobtype"
            element={
              <PayRollJobType
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />

          <Route
            path="/payroll/grade"
            element={
              <PayRollGrade
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />
          <Route
            path="/payroll/leaveledger"
            element={
              <PayRollLeaveLedger
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />
          <Route
            path="/payroll/salaryledger"
            element={<SalaryLedger pageType={LedgersForType.HR} />}
          />
          <Route path="/payroll/acdyr" element={<PayRoleAcademicYear />} />
          <Route path="/calendar/events" element={<HRCalender />} />
          <Route
            path="/payroll/salarystructure"
            element={<SalaryStructure />}
          />
          <Route
            path="/payroll/assignsalarystructure"
            element={<AssignSalaryStructure />}
          />
          <Route
            path="/payroll/salaryprocess/selectmonth"
            element={<SelectMonth />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/selectemployee"
            element={<SelectEmpForSalary />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/employeeattendance"
            element={<EmpAttendance />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/confirmemployeesalary"
            element={<ConfirmEmployeeSalary />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/provisionalsalarydetails/detailed"
            element={
              <ProvisionalSalaryDetails
                pageType={ProvisionalSalaryDetailsType.DETAILED}
              />
            }
          />
          <Route
            path="/employee/1/:empId/registration"
            element={<EmpBasicDetails step={1} operation={Operation.CREATE} />}
          />
          <Route
            path="/employee/1/:empId/update/basicdata"
            element={<EmpBasicDetails step={1} operation={Operation.UPDATE} />}
          />
          <Route
            path="/employee/2/:empId/personaldetails"
            element={<EmpPersonalDetails step={2} type={Operation.CREATE} />}
          />
          <Route
            path="/employee/2/:empId/update/personaldetails"
            element={<EmpPersonalDetails step={2} type={Operation.UPDATE} />}
          />
          <Route
            path="/employee/4/:empId/otherdetails"
            element={<EmpOtherDetails step={4} operation={Operation.CREATE} />}
          />
          <Route
            path="/employee/4/:empId/update/otherdetails"
            element={<EmpOtherDetails step={4} operation={Operation.UPDATE} />}
          />
          <Route
            path="/employee/3/:empId/academicContribution"
            element={<AcademicContributions step={3} type={Operation.CREATE} />}
          />
          <Route
            path="/employee/5/:empId/empUploadDocument"
            element={<EmpDocumentsUpload step={5} type={Operation.CREATE} />}
          />
          <Route
            path="/employee/5/:empId/update/empUploadDocument"
            element={<EmpDocumentsUpload step={5} type={Operation.UPDATE} />}
          />
          <Route
            path="/employee/3/:empId/update/academicContribution"
            element={<AcademicContributions step={3} type={Operation.UPDATE} />}
          />
          <Route
            path="/employee/:empId/preview/basicdetails"
            element={<EmpPreviewBasicData />}
          />
          <Route
            path="/employee/:empId/preview/personaldetails"
            element={<EmpPreviewPersonalData />}
          />
          <Route
            path="/employee/:empId/preview/academicContribution"
            element={<EmpAcdContribution />}
          />
          <Route
            path="/employee/:empId/preview/otherdetails"
            element={<EmpPreviewOtherDetailsData />}
          />
          <Route
            path="/employee/:empId/preview/empDocuments"
            element={<EmpDocsPreview />}
          />
          <Route
            path="/employee/list"
            element={
              <EmployeeList
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
                type={ComponentForEmployee.GENERAL}
              />
            }
          />
          <Route
            path="/employee/assignleaveledger"
            element={<AssignLeaveLedger />}
          />
          <Route
            path="/payroll/category"
            element={
              <PayRollCategory
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />
          <Route
            path="/payroll/jobtype"
            element={
              <PayRollJobType
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />
          <Route
            path="/payroll/department"
            element={
              <PayRollDepartment
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
                type={ComponentFor.MASTERS}
                LicenseType={LicenseTypes.PAYROLL}
              />
            }
          />
          <Route
            path="/payroll/designation"
            element={
              <PayRollDesignation
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
                type={ComponentFor.MASTERS}
                LicenseType={LicenseTypes.PAYROLL}
              />
            }
          />
          <Route
            path="/payroll/grade"
            element={
              <PayRollGrade
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />
          <Route
            path="/payroll/defaultleaveledger"
            element={<DefaultLeaveLedger />}
          />
          <Route
            path="/payroll/salaryledger"
            element={<SalaryLedger pageType={LedgersForType.HR} />}
          />
          <Route path="/payroll/acdyr" element={<PayRoleAcademicYear />} />
          {/* <Route
                    path="/calendar/events"
                    element={<HRCalendarEvents />}
                  /> */}
          <Route
            path="/payroll/salarystructure"
            element={<SalaryStructure />}
          />
          <Route
            path="/payroll/assignsalarystructure"
            element={<AssignSalaryStructure />}
          />
          <Route
            path="/payroll/salaryprocess/selectmonth"
            element={<SelectMonth />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/selectemployee"
            element={<SelectEmpForSalary />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/employeeattendance"
            element={<EmpAttendance />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/confirmemployeesalary"
            element={<ConfirmEmployeeSalary />}
          />
          <Route
            path="/payroll/salaryprocess/:monthId/provisionalsalarydetails/detailed"
            element={
              <ProvisionalSalaryDetails
                pageType={ProvisionalSalaryDetailsType.DETAILED}
              />
            }
          />
          <Route
            path="/payroll/salaryprocess/:monthId/provisionalsalarydetails/bankscroll"
            element={
              <ProvisionalSalaryDetails
                pageType={ProvisionalSalaryDetailsType.BANK}
              />
            }
          />
          <Route
            path="/payroll/salaryprocess/:monthId/closesalaryprocess"
            element={<CloseSalaryProcess />}
          />
          <Route
            path="/payroll/reports/payslip"
            element={<PayRollReports type={PayRollReportsTypes.PAYSLIP} />}
          />
          <Route
            path="/payroll/reports/salarystatement"
            element={
              <PayRollReports type={PayRollReportsTypes.SALARY_STATEMENT} />
            }
          />
          <Route
            path="/payroll/reports/bankscroll"
            element={<PayRollReports type={PayRollReportsTypes.BANK_SCROLL} />}
          />
          <Route
            path="/payroll/reports/leaves"
            element={<PayRollReports type={PayRollReportsTypes.LEAVES} />}
          />
          <Route path="/payroll/attendance" element={<PayRollAttendance />} />
          <Route
            path="/payroll/attendance/monthly"
            element={<PayRollMonthlyList />}
          />
          <Route path="/payroll/enablelogin" element={<EmployeeLogin />} />
          <Route path="/payroll/resetPassword" element={<EmpResetPassword />} />

          <Route path="/" element={<HRHome DashBoardRequired={true} />} />
          <Route
            path="/payroll/organizationalchart"
            element={<OrganizationalChart LicenseType={LicenseTypes.PAYROLL} />}
          />
          <Route path="/payroll/employeetree" element={<EmployeeTree />} />
          <Route
            path="/payroll/accountingusers"
            element={<AccountingUsers />}
          />
          <Route path="/payroll/academicusers" element={<TeacherLogin />} />
          <Route
            path="/payroll/configuration"
            element={
              <HRConfiguration
                config_query_type={SwConfigQueryType.INST_BY_MODULE}
                str_value={ModuleName.PAYROLL}
                int_value={0}
                setModalFlag={setModalFlag}
                pageType={PageFor.GENERAL}
              />
            }
          />
        </>
      )}
    </Routes>
  );
};

export default PayrollRoutes;

import { gql } from "@apollo/client";
export const GetAcdReportCards = gql`
  query GetAcdReportCards(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $orderBy: AcdReportCardOrder
    $where: AcdReportCardWhereInput
  ) {
    GetAcdReportCards(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      after: $after
      first: $first
      before: $before
      last: $last
      orderBy: $orderBy
      where: $where
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          id
          report_name
          inst_id
          acd_yr_id
        }
      }
    }
  }
`;
export const GetAcdReportTests = gql`
  query GetAcdReportTests(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_report_card_id: ID!
  ) {
    GetAcdReportTests(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_report_card_id: $acd_report_card_id
    ) {
      id
      idx
      test_name_id
      acd_report_card_id
      inst_id
      test_name_details {
        id
        test_name
        test_type_details {
          test_type_name
        }
      }
    }
  }
`;

export const GetAcdReportCardConfig = gql`
  query GetAcdReportCardConfig(
    $token: String!
    $inst_id: ID!
    $acd_report_card_id: ID!
  ) {
    GetAcdReportCardConfig(
      token: $token
      inst_id: $inst_id
      acd_report_card_id: $acd_report_card_id
    ) {
      id
      config_key
      config_form_label_caption
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_secondary_list_caption
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      inst_id
      acd_report_card_id
    }
  }
`;

export const GetAcdTestReportCardConfig = gql`
  query GetAcdTestReportCardConfig(
    $token: String!
    $inst_id: ID!
    $acd_test_class_id: ID!
  ) {
    GetAcdTestReportCardConfig(
      token: $token
      inst_id: $inst_id
      acd_test_class_id: $acd_test_class_id
    ) {
      id
      config_key
      config_form_label_caption
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_secondary_list_caption
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      inst_id
      acd_test_class_id
    }
  }
`;
export const GetAcdStudentsMarksStatisticsForReportCard = gql`
  query GetAcdStudentsMarksStatisticsForReportCard(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_ids: [ID!]!
    $input: AcdReportCardData!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdStudentsMarksStatisticsForReportCard(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_ids: $student_ids
      input: $input
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      student_id
      att_percentage
      cal_days_count
      att_marked_days_count
      std_present_count
      student_details {
        id
        std_reg_no
        std_adm_no
        first_name
        middle_name
        last_name
        branch_desc
        branch_id
        class_desc
        class_id
        dept_desc
        dept_id
        sem_desc
        sem_id
        section_desc
        section_id
        cat_desc
        category_id
        std_father_name
        std_profile_filename
      }
      acd_test_marks_details {
        acd_test_class_id
        test_start_date
        test_end_date
        test_type_name
        acd_test_name
        test_total_max_marks
        test_total_min_marks
        test_top_percentage
        test_avg_percentage
        test_std_total_percentage
        test_std_total_marks_scored
        is_non_academic
        alloted_level_rank
        entry_level_rank
        overall_grade
        test_remarks
        marks_details {
          subj_master_id
          subj_desc
          subj_code
          std_marks_scored
          marks_percentage
          subj_max_marks_scored
          subj_avg_marks_scored
          subj_total_max_marks
          subj_total_min_marks
          teacher_comments
          scored_grade
          is_std_present
        }
      }
    }
  }
`;

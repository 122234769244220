//React
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

//Apollo Client
import { useMutation } from "@apollo/client";

//Material Ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

//HTML Hocs
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";

//Styles
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../styles/ModalStyles";

//Images
import closeImg from "../../../images/Close.svg";
import Settings from "../../../images/Settings.svg";

//Custom Hooks
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import useBookEntryOptions from "../customHooks/useBookEntryOptions";
import useToken from "../../../customhooks/useToken";

//Components
import Home from "../Home/Index";

//Modals
import LoadingModal from "../../../pages/LoadingModal";

//Enums and Types and Constants
import { Keys } from "../../../utils/Enum.keys";
import { LibraryTitles } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  CURRENT_PAGE,
  DEBOUNCE_TIME_INTERVAL,
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../utils/constants";
import {
  PageNumbers,
  BooksReportType,
  UserType,
  Operation,
  Direction,
  SortBy,
  LibBookMasterQueryType,
  LibraryConfigKey,
} from "../../../utils/Enum.types";

//Util Functions
import {
  debounce,
  defaultLabelDisplayedRows,
  handleFormEvent,
} from "../../../utils/UtilFunctions";
import MessageModal from "../../../pages/MessageModal";
import { UpdateLibBooksAccessionNumber } from "../../../queries/Library/MasterData/AccessNo/mutations/update";
import { msgType } from "../../../utils/Form.types";
import { GetLibBookMaster } from "../../../queries/Library/BookEntry/list/byInstId";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../json/useLibraryTableJson";

//Jsons
const { Library } = require("../../../json/title.json");

//Interface Types

interface ItemsType {
  id: number;
  book_access_no: string;
  book_title: string;
  publication: string;
  authors: string;
  subjects: string;
  visited: boolean;
}

const EditAccessionNo = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { Library_Table } = useLibraryTableJson();
  const { user_details } = useLoggedInUserDetails();
  const [searchBook, setSearchBook] = useState("");
  const [AccessionNumber, setAccessionNumber] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const [saveModal, setSaveModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [items, setItems] = useState<ItemsType[]>([]);
  const [EditMode, setEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const saveRef = useRef<HTMLButtonElement>(null);
  const { configData: accessionNoEdit } = useSwConfigData(
    LibraryConfigKey.LIB_BOOKS_ACCESSNO_EDITABLE
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [UpdateAccessionNo, { loading: updationLoading }] = useMutation(
    UpdateLibBooksAccessionNumber,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.ALLOT_STUDENT_REGISTER_NO
  );

  const {
    bookEntries: {
      queryData: { data: Books, loading: BooksLoading, fetchMore },
    },
  } = useBookEntryOptions(
    searchBook,
    ROWS_PER_PAGE,
    BooksReportType.GENERAL,
    UserType.USER
  );
  const totalCount = Books?.GetLibBookMaster?.totalCount;
  const endCursor = Books?.GetLibBookMaster?.pageInfo?.endCursor;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;

  const clear = () => {
    setSearchBook("");
  };

  const onEdit = (item: ItemsType) => {
    setEditMode({
      status: true,
      rowKey: item.id,
    });
    if (item.book_access_no) {
      setAccessionNumber(item.book_access_no);
    }
  };

  useEffect(() => {
    if (Books && !BooksLoading) {
      const data = Books?.GetLibBookMaster?.edges
        ?.map(({ node }) => {
          return {
            id: node.id,
            book_access_no: node.book_access_no,
            book_title: node.book_title,
            publication: node.classification.publisher.publisher_name,
            authors: node.classification.author.author_name,
            subjects: node.classification.subject.subject_desc,
            visited: false,
          };
        })
        .slice(indexOfFirstrepo, indexOfLastrepo);
      setItems(data);
    }
  }, [Books, BooksLoading, indexOfFirstrepo, indexOfLastrepo]);

  const handleChangePage = (newPage: number) => {
    setTimeout(() => {
      setPage(newPage);
      updateDebounceText();
    }, DEBOUNCE_TIME_INTERVAL);
  };

  const updateDebounceText = debounce(() => {
    handleMore();
  });
  const handleMore = () => {
    if (!BooksLoading && Books) {
      fetchMore({
        variables: {
          after: endCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          fetchMoreResult.GetLibBookMaster.edges = [
            ...prevResult.GetLibBookMaster.edges,
            ...fetchMoreResult.GetLibBookMaster.edges,
          ];
          return fetchMoreResult;
        },
      });
    }
  };
  const HandleSubmit = (type: string) => {
    if (accessionNoEdit.data?.GetSwConfigVariables[0].config_boolean_value) {
      const accession_data = items?.map((node) => ({
        lib_book_master_id: node.id,
        book_access_no: node.book_access_no,
      }));
      UpdateAccessionNo({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          accession_data,
        },
        refetchQueries: [
          {
            query: GetLibBookMaster,
            variables: {
              token,
              input: {
                book_query_type: LibBookMasterQueryType.BOOK_BY_INST_ID,
                ids: [Number(InstId)],
              },
              first: rowsPerPage,
              sortBy: SortBy.ACCESS_NO,
              direction: Direction.ASC,
              access_no: searchBook,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (type === "nextPage") {
            handleChangePage(newPage);
            setSaveModal(!saveModal);
          } else
            setMessage({
              message: "Sucessfully Updated AccessionNumber",
              flag: true,
              operation: Operation.UPDATE,
            });
        }
      });
    } else {
      setMessage({
        flag: true,
        message: "Please Enable Configuration Before Editing Accession Number",
        operation: Operation.NONE,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ItemsToBeDisplayed = () => {
    if (
      rowsPerPage > 0 &&
      items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length >
        0
    ) {
      return items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    } else {
      return items;
    }
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>
            {Library.Titles.AccessionNumber.map(
              (title: LibraryTitles, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.EditAccessionNumber}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <form>
        <div className="row g-0">
          <div className="col-2 edit-accno__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchBook(e.target.value);
                setPage(0);
              }}
              value={searchBook}
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col"></div>

          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className="container__list--addnew"
            />
          </div>
        </div>
      </form>

      <div className="edit-accno__tableblock">
        {!ItemsToBeDisplayed()?.length ? (
          <b className="nodata">Sorry, No Books Found</b>
        ) : (
          <TableContainer className="edit-accno__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Library_Table.AccesionNumberEdit.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {ItemsToBeDisplayed()?.map((node, index: number) => (
                  <TableRow key={index}>
                    <TableCell
                      className="edit-accno__table--slno"
                      id="td-center"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>{node.book_title}</TableCell>
                    <TableCell className="edit-accno__table--desc">
                      {node.publication}
                    </TableCell>
                    <TableCell className="edit-accno__table--desc">
                      {node.authors}
                    </TableCell>
                    <TableCell className="edit-accno__table--desc">
                      {node.subjects}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onEdit(node);
                      }}
                      className="edit-accno__table--input editCell"
                    >
                      {(EditMode.status && EditMode.rowKey === node.id) ||
                      (index === 0 && EditMode.status === false) ? (
                        <input
                          type="text"
                          value={AccessionNumber}
                          autoFocus={
                            (EditMode.status && EditMode.rowKey === node.id) ||
                            index === 0
                          }
                          onFocus={() => {
                            if (index === 0) {
                              onEdit(node);
                            }
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            items[page * rowsPerPage + index].book_access_no =
                              e.target.value;
                            items[page * rowsPerPage + index].visited = true;
                            setItems(items);
                            setAccessionNumber(e.target.value);
                          }}
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER) {
                              items[page * rowsPerPage + index].book_access_no =
                                AccessionNumber;
                              items[page * rowsPerPage + index].visited = true;
                              setItems(items);

                              if (
                                page * rowsPerPage + index !==
                                items.length - 1
                              ) {
                                onEdit(items[page * rowsPerPage + index + 1]);
                              }
                              if (
                                ItemsToBeDisplayed().length - 1 ===
                                page * rowsPerPage + index
                              ) {
                                saveRef?.current?.focus();
                              }
                            }
                          }}
                        />
                      ) : (
                        <>{node.book_access_no}</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={PAGINATION_ARRAY}
                    count={totalCount ? totalCount : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => {
                      if (items?.filter((item) => item.visited).length > 0) {
                        setNewPage(newPage);
                        setSaveModal(!saveModal);
                      } else {
                        handleChangePage(newPage);
                      }
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    labelRowsPerPage={TABLE_DATA_PER_PAGE}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </div>

      <div className="button-left">
        {ItemsToBeDisplayed().length > 0 && (
          <>
            <Button mode="save" buttonref={saveRef!} onClick={HandleSubmit} />
            <Button mode="clear" />
          </>
        )}
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={updationLoading} />
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={saveModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <Label>
          Navigating to next page will save the current Register Numbers
        </Label>
        <div>
          <Button mode="yes" onClick={() => HandleSubmit("nextPage")} />
          <Button mode="cancel" onClick={() => setSaveModal(false)} />
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {/* <ConfigurationSettings
              pageNumber={PageNumbers.ALLOT_STUDENT_REGISTER_NO}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={closeImg}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default EditAccessionNo;

import React, { Fragment, Key } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Title } from "../../../../stories/Title/Title";
import { StudentsTitlesType } from "../../../../Types/Student/json";
import { PageFor } from "../../../../utils/Enum.types";
const { Students } = require("../../../../json/title.json");
interface Props {
  pageType: PageFor;
}
const PreviewTabs = ({ pageType }: Props) => {
  const { InstId, studentId } = useParams();
  return (
    <>
      <Title>
        {Students.Titles.map((title: StudentsTitlesType, index: React.Key) => {
          return <React.Fragment key={index}>{title.Details}</React.Fragment>;
        })}
      </Title>
      <div className="css3-tabstrip">
        <ul>
          <li>
            <NavLink
              to={
                pageType === PageFor.MODAL
                  ? `/${InstId}/basicdetails`
                  : `/${InstId}/admissions/students/${studentId}/basicdata`
              }
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-0"
              />

              <label htmlFor="css3-tabstrip-0-0">
                {Students.Tabs.Titles.map(
                  (label: StudentsTitlesType, index: Key) => {
                    return (
                      <Fragment key={index}>{label.Basic_Details}</Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                pageType === PageFor.MODAL
                  ? `/${InstId}/personaldetails`
                  : `/${InstId}/admissions/students/${studentId}/personaldata`
              }
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-1"
              />
              <label htmlFor="css3-tabstrip-0-1">
                {Students.Tabs.Titles.map(
                  (label: StudentsTitlesType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {label.Personal_Details}
                      </React.Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={
                pageType === PageFor.MODAL
                  ? `/${InstId}/prevInstDetails`
                  : `/${InstId}/admissions/students/${studentId}/previousschooldata`
              }
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-2"
              />
              <label htmlFor="css3-tabstrip-0-2">
                {Students.Tabs.Titles.map(
                  (label: StudentsTitlesType, index: Key) => {
                    return (
                      <Fragment key={index}>{label.Academic_Details}</Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                pageType === PageFor.MODAL
                  ? `/${InstId}/docs_preview`
                  : `/${InstId}/admissions/students/${studentId}/docs_upload_preview`
              }
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-2"
              />
              <label htmlFor="css3-tabstrip-0-2">
                {Students.Tabs.Titles.map(
                  (label: StudentsTitlesType, index: Key) => {
                    return (
                      <Fragment key={index}>
                        {label.Student_Docs_Upload}
                      </Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                pageType === PageFor.MODAL
                  ? `/${InstId}/parentdetails`
                  : `/${InstId}/admissions/students/${studentId}/stuparentassoc`
              }
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-2"
              />
              <label htmlFor="css3-tabstrip-0-2">
                {Students.Tabs.Titles.map(
                  (label: StudentsTitlesType, index: Key) => {
                    return (
                      <Fragment key={index}>
                        {label.Student_Parent_Association}
                      </Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PreviewTabs;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetLibFineByInstId } from "../../../queries/Library/FineSlab/list/byInstId";
import {
  GetLibFineByInstIdData,
  GetLibFineByInstIdVars,
} from "../../../Types/Library/FineSlab";
import { ReturnType } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";

const useFineSlabOptions = (type: ReturnType) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [fines, setFines] = useState<responseType[]>([]);
  const [GetLibFine, { data, loading, error }] = useLazyQuery<
    GetLibFineByInstIdData,
    GetLibFineByInstIdVars
  >(GetLibFineByInstId, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });

  useEffect(() => {
    GetLibFine().then(({ data }) => {
      if (
        data &&
        data.GetLibFineByInstId &&
        (type === ReturnType.RESPONSE_TYPE || type === ReturnType.BOTH)
      ) {
        setFines(
          data.GetLibFineByInstId?.map(({ id, lib_fine_desc }) => ({
            label: lib_fine_desc,
            value: id,
          }))
        );
      } else {
        setFines([]);
      }
    });
  }, [data, loading, type]);
  return {
    fines: {
      finesDropdown: fines,
      queryData: {
        data,
        error,
        loading,
      },
    },
  };
};

export default useFineSlabOptions;

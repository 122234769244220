import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetPayRollSalaryLdgrsByInstIdData,
  InstIdVars,
} from "../Types/masterDataTypes";
import { responseType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import { GetPayRollSalaryLdgrsByInstId } from "../queries/salary/list";

const useEmpSalaryLedger = () => {
  //Token and others
  const { token } = useToken();
  const { InstId } = useParams();
  //Queries
  const [GetEmpSalaryLedger, { data, error, loading }] = useLazyQuery<
    GetPayRollSalaryLdgrsByInstIdData,
    InstIdVars
  >(GetPayRollSalaryLdgrsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  //useStates
  const [responseType, setResponseType] = useState<responseType[]>([]);

  useEffect(() => {
    if (token) {
      GetEmpSalaryLedger().then(({ data }) => {
        if (data) {
          setResponseType(
            data?.GetPayRollSalaryLdgrsByInstId?.map(
              ({ id, sal_ldgr_desc }) => ({
                label: sal_ldgr_desc,
                value: id,
              })
            )
          );
        }
      });
    }
  }, [data, loading, token, GetEmpSalaryLedger]);

  return {
    salaryLedger: {
      data,
      error,
      loading,
      responseType,
    },
  };
};

export default useEmpSalaryLedger;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { GetAcdTestName } from "../queries/test/query";
import useActiveAcademicYear from "./useActiveAcademicYear";
import { InstitutionConfigurationTypes } from "../../../utils/Enum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { AppContext } from "../../../context/context";
export interface TestTypeDetails {
  test_type_name: string;
  ext_max_marks: number;
  ext_min_marks: number;
  int_max_marks: number;
  int_min_marks: number;
}
export interface GetAcdTestNameDetails {
  id: number;
  test_name: string;
  show_marks_in_grade: boolean;
  add_test_remarks:boolean;
  test_type_id: number;
  is_marks_derived: boolean;
  is_non_academic:boolean;
  show_marks_in_std_login: boolean;
  test_type_details: TestTypeDetails;
  newAdded?:boolean
}

export interface GetAcdTestTypeDetails {
  test_type_details: TestTypeDetails;
}
interface GetAcdTestNameData {
  GetAcdTestName: GetAcdTestNameDetails[];
}
interface GetAcdTestNameVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  for_planning: boolean;
  alloted_level: string;
  allotted_id: number;
  test_type_id: number;
}

const useTests = (for_planning: boolean, test_type_id: number) => {
  const { token } = useToken();
  const { InstId, allotedID } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { state } = useContext(AppContext);
  const [tests, setTests] = useState<responseType[]>([]);
  const [GetAcdTests, { data, loading, error }] = useLazyQuery<
    GetAcdTestNameData,
    GetAcdTestNameVars
  >(GetAcdTestName, {
    variables: {
      inst_id: InstId!,
      token,
      for_planning,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      alloted_level: for_planning
        ? configData.data?.GetSwConfigVariables[0].config_string_value!
        : "",
      allotted_id: for_planning ? Number(allotedID!) : 0,
      test_type_id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      InstId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      configData.data &&
      state.ActiveAcdYr
    ) {
      GetAcdTests().then(({ data, error }) => {
        if (data) {
          setTests(
            data.GetAcdTestName.filter((edge) => edge !== null).map((edge) => ({
              label: edge.test_name,
              value: edge.id,
              isChecked: false,
            }))
          );
        }
        if (error) {
          setTests([]);
        }
      });
    }
  }, [
    InstId,
    GetAcdTests,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    configData.data,
    allotedID,
    state.ActiveAcdYr,
  ]);
  return { acdTests: { data, loading, error, tests } };
};

export default useTests;

import React, { useEffect, useState } from "react";
import List from "./List";
import InstDetails from "../../DailyActivities/InstDetails";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import MonthlyOverview from "../../DailyActivities/Attendance/MonthlyOverview";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { PageFor } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
const MarkedView = () => {
    const navigate=useNavigate();
  const [date, setDate] = useState(new Date());
  const { serverDate } = useServerDateandTime();
  const [monthlyOverView, setMonthlyOverView] = useState(false);

  useEffect(() => {
    if (serverDate) {
      setDate(new Date(serverDate));
    }
  }, [serverDate]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="att-report-marked">
        <div className="att-report-marked__left">
          <List />
        </div>
        <div className="att-report-marked__right">
          <InstDetails />
          <div className="att-report-marked__cal row g-0">
            <div className="col-6 h-100">
            <MonthlyOverview
              selectedDate={date}
              setSelectedDate={setDate}
              setDrawerFlag={setMonthlyOverView}
              pageType={PageFor.REPORT}
            />
            </div>
        
          </div>
          <Button mode="back" onClick={()=>navigate(-1)}/>
        </div>
      </div>
    </>
  );
};

export default MarkedView;

import { gql } from "@apollo/client";

export const AddAcctFineSlab = gql`
  mutation AddAcctFineSlab(
    $token: String!
    $inst_id: ID!
    $input: AcctFineInputData!
    $user_details: SubscribedUser!
  ) {
    AddAcctFineSlab(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

export const UpdateAcctFineSlab = gql`
  mutation UpdateAcctFineSlab(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: AcctFineInputData!
  ) {
    UpdateAcctFineSlab(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const DeleteAcctFineSlabById = gql`
  mutation DeleteAcctFineSlabById(
    $token: String!
    $user_details: SubscribedUser!
    $id: ID!
    $inst_id: ID!
  ) {
    DeleteAcctFineSlabById(
      token: $token
      inst_id: $inst_id
      id: $id
      user_details: $user_details
    )
  }
`;

import { gql } from "@apollo/client";

export const ByNode = gql`
  query LibDetailsByNode($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on LibBookMedia {
        id
        media_desc
        media_abbrevation
        media_is_active
        media_sl
        inst_id
      }
      ... on AcctMiscActivities {
        id
        activity_name
        inst_id
      }
      ... on LibBookDept {
        id
        dept_name
        inst_id
      }
      ... on LibBookCategory {
        id
        category_desc
        category_abbrevation
        category_is_active
        category_sl
        inst_id
      }
      ... on LibBookSubject {
        id
        subject_desc
        subject_classification
        subject_is_active
        subject_sl
        inst_id
      }
      ... on LibBookAttribute {
        id
        book_attribute
        inst_id
      }

      ... on LibBookAuthor {
        id
        author_name
        author_address
        author_city
        author_phone
        author_email_id
        inst_id
      }
      ... on LibBookPublisher {
        id
        publisher_name
        publisher_address
        publisher_city
        publisher_state
        publisher_country
        publisher_phone
        publisher_contact_person
        publisher_contact_person_tel
        inst_id
      }
      ... on LibBookVendor {
        id
        vendor_name
        vendor_address
        vendor_city
        vendor_state
        vendor_country
        vendor_phone
        vendor_contact_person
        vendor_contact_person_phone
        vendor_pan
        vendor_gst
        inst_id
      }
      ... on LibDonor {
        id
        donor_name
        donor_address
        donor_tel
        donor_type
        inst_id
      }

      ... on LibBookMaster {
        id
        book_access_no
        book_status
        book_title
        book_entry_date
        book_no_of_times_issued
        book_last_issued_to
        book_last_issued_date
        inst_id
        lib_book_classification_id
        lib_purchase_master_id
        lib_book_rack_id
        issue_details {
          id
          inst_id
          issue_for_student_or_staff
          issue_date
          issue_return_date
        }
        classification {
          classification_no
          classification_desc
          classification_date
          classification_copies_in
          classification_copies_damaged
          classification_copies_avialable
          classification_copies_issued
          classification_copies_on_shelf
          book_published_year
          book_pages
          book_edition
          book_volume
          book_place
          inst_id
          lib_book_media_id
          last_purchased_date
          last_purchased_from
          last_purchased_rate
          total_purchased_value
          total_purchased_qty
          avg_book_rate
          media {
            id
            media_desc
            media_abbrevation
            media_is_active
            media_sl
          }
          category {
            id
            category_desc
            category_abbrevation
            category_is_active
            category_sl
          }
          subject {
            id
            subject_desc
            subject_classification
            subject_is_active
            subject_sl
          }
          language {
            id
            language
            language_short_desc
          }
          attribute {
            id
            book_attribute
          }
          author {
            id
            author_name
            author_address
            author_city
            author_phone
            author_email_id
          }
          publisher {
            publisher_name
            publisher_address
            publisher_city
            publisher_state
            publisher_country
            publisher_phone
            publisher_contact_person
            publisher_contact_person_tel
          }
        }
      }
      ... on LibBookClassification {
        id
        classification_no
        classification_desc
        classification_date
        classification_copies_in
        classification_copies_damaged
        classification_copies_avialable
        classification_copies_issued
        classification_copies_on_shelf
        book_published_year
        book_pages
        book_edition
        book_volume
        book_place
        lib_book_media_id
        lib_book_category_id
        lib_book_subject_id
        lib_book_publisher_id
        lib_book_language_id
        lib_book_attribute_id
        lib_book_author_id
        inst_id
        media {
          media_desc
          media_abbrevation
        }
        category {
          category_desc
        }
        subject {
          subject_desc
        }
        publisher {
          publisher_name
        }
        language {
          id
          language
          language_short_desc
          inst_id
        }
        attribute {
          id
          book_attribute
          inst_id
        }
        author {
          id
          author_name
          author_address
          author_city
          author_phone
          author_email_id
          inst_id
        }
      }
      ... on LibBookRack {
        id
        lib_rack_no
        lib_rack_desc
        inst_id
      }
      ... on LibKeywords {
        id
        keyword
        inst_id
      }
      ... on LibBookMasterKeyword {
        id
        lib_book_classification_id
        lib_keyword_id
        inst_id
      }
      ... on LibFine {
        id
        lib_fine_desc
        lib_fine_for_student
        lib_fine_for_staff
        lib_slab1_from
        lib_slab1_to
        lib_slab1_fine
        lib_slab2_from
        lib_slab2_to
        lib_slab2_fine
        lib_slab3_from
        lib_slab3_to
        lib_slab3_fine
        lib_slab4_from
        lib_slab4_to
        lib_slab4_fine
        lib_slab5_from
        lib_slab5_to
        lib_slab5_fine
        inst_id
      }
      __typename
    }
  }
`;

export const LanguageByNode = gql`
  query LanguageByNode($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on LibBookLanguage {
        id
        language
        language_short_desc
        inst_id
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const GetLibBookSubjectsByInstId = gql`
  query GetLibBookSubjectsByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookSubjectOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookSubjectsByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ subjectDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          subject_desc
          subject_classification
          subject_is_active
          subject_sl
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

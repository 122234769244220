import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";

import Modal from "react-modal";
import {
  PrintQRCodeModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import StudentEnquiry from "../StudentEnquiry";
import Close from "../../../images/Close.svg";
import Application from "../../../images/Application.svg";
import QRCode from "qrcode.react";
import QRCodeGenerator from "../../../utils/QRCodeGenerator";
import More from "../../../images/More.svg";
import EnquiredStudentsImage from "../../../images/EnquiredStudents.svg";
import AdmittedStudents from "../../../images/AdmittedStudents.svg";
import ToFollowUp from "../../../images/ToFollowUp.svg";
import CalledToday from "../../../images/CalledToday.svg";
import Conversion from "../../../images/Conversion.svg";
import Interested from "../../../images/Interested.svg";
import NotInterested from "../../../images/NotInterested.svg";
import NotDecided from "../../../images/NotDecided.svg";
import TodayToFollowUp from "../../../images/TodaytoFollowUp.svg";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { formatterDecimal, toIsoDate } from "../../../utils/UtilFunctions";
import { AppContext } from "../../../context/context";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import {
  AccessType,
  PageFor,
  StdEnqQueryType,
} from "../../../utils/Enum.types";
import { useLazyQuery } from "@apollo/client";
import { DashboardDetailsEmpVars } from "./Index";
import { GetStudentEnquiryDashboardDetails } from "../../../queries/students/list/newApi";
import { EMPTY_STRING } from "../../../utils/constants";
import VacantSeats from "../../../images/VacancySeats.svg";
import { Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TabPanel, a11yProps } from "../../../styles/Tabs";
import ReferenceThrough from "./ReferenceThrough";
import MonthlyCount from "./MonthlyCount";
import EnquiredStudents from "./EnquiredStudents";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";

interface GetStudentEnquiryDashboardDetailsList {
  enq_student_count: number;
  admitted_student_count: number;
  to_follow_up_count: number;
  called_today_count: number;
  interested_count: number;
  not_interested_count: number;
  not_decided_count: number;
  today_follow_up_count: number;
  bought_app_count: number;
  total_vacant_seats: number;
  newspaper_ads_count: number;
  flyers_count: number;
  digital_count: number;
  parents_count: number;
  staff_count: number;
  walkin_count: number;
  others_count: number;
}
export interface GetStudentEnquiryDashboardDetailsData {
  GetStudentEnquiryDashboardDetails: GetStudentEnquiryDashboardDetailsList;
}
interface Props {
  endDate: string;
  startDate: string;
}
const Details = ({ endDate, startDate }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);
  const { format } = formatterDecimal;
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [studentEnquiryModal, setStudentEnquiryModal] = useState(false);
  const [queryType, setQueryType] = useState(StdEnqQueryType.STD_ENQ_ALL);
  const pollIntervalTime = usePollIntervalTime();

  const currentYear = currentDate.getFullYear();
  const [GetEnqStudentData, { data }] = useLazyQuery<
    GetStudentEnquiryDashboardDetailsData,
    DashboardDetailsEmpVars
  >(GetStudentEnquiryDashboardDetails, {
    variables: {
      token,
      inst_id: InstId!,
      pr_emp_id: 0,
      today_date: toIsoDate(serverDate),
      enq_start_date: startDate ? toIsoDate(startDate) : EMPTY_STRING,
      enq_end_date: endDate ? toIsoDate(endDate) : EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    pollInterval: pollIntervalTime,
  });

  const {
    enq_student_count,
    admitted_student_count,
    to_follow_up_count,
    called_today_count,
    interested_count,
    not_interested_count,
    not_decided_count,
    today_follow_up_count,
    bought_app_count,
    total_vacant_seats,
  } = data?.GetStudentEnquiryDashboardDetails || {};

  useEffect(() => {
    if (state.ActiveFinYr && serverDate !== EMPTY_STRING) {
      GetEnqStudentData();
    }
  }, [state.ActiveFinYr, GetEnqStudentData, serverDate, token]);

  useEffect(() => {
    if (serverDate) {
      setCurrentDate(new Date(serverDate));
    }
  }, [serverDate]);
  const accessType = process.env.React_App_access_type;

  return (
    <>
      <div className="row g-0 enquiry-dashboard__details">
        <div className="col enquiry-dashboard__details--block">
          <div className="enquiry-dashboard__details--block--title">
            <Title variant="subtitle1">Enquired Students</Title>
            <img src={More} alt="/" />
          </div>
          <ul>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_ALL);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={EnquiredStudentsImage} alt="/" />
              <Label>Enquired Students</Label>
              <span className="enquiry-dashboard__details--block--enquired-student">
                {enq_student_count ?? 0}
              </span>
            </li>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_BOUGHT_APP);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={Application} alt="/" />
              <Label>Applications Sold </Label>
              <span className="enquiry-dashboard__details--block--application">
                {bought_app_count ?? 0}
              </span>
            </li>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_ADMITTED);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={AdmittedStudents} alt="/" />
              <Label>Admitted Students</Label>
              <span className="enquiry-dashboard__details--block--admitted-students">
                {admitted_student_count ?? 0}
              </span>
            </li>
          </ul>
        </div>
        <div className="col enquiry-dashboard__details--block">
          <div className="enquiry-dashboard__details--block--title">
            <Title variant="subtitle1">Follow Up Status</Title>
            <img src={More} alt="/" />
          </div>
          <ul>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_INTERESTED);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={Interested} alt="/" />
              <Label>Interested</Label>
              <span className="enquiry-dashboard__details--block--interested">
                {interested_count ?? 0}
              </span>
            </li>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_NOT_INTERESTED);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={NotInterested} alt="/" />
              <Label>Not Interested</Label>
              <span className="enquiry-dashboard__details--block--not-interested">
                {not_interested_count ?? 0}
              </span>
            </li>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_NOT_DECIDED);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={NotDecided} alt="/" />
              <Label>Not Decided</Label>
              <span className="enquiry-dashboard__details--block--not-decided">
                {not_decided_count ?? 0}
              </span>
            </li>
          </ul>
        </div>
        <div className="col enquiry-dashboard__details--block">
          <div className="enquiry-dashboard__details--block--title">
            <Title variant="subtitle1">Follow Up Details</Title>
            <img src={More} alt="/" />
          </div>
          <ul>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_TO_FOLLOW_UP);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={ToFollowUp} alt="/" />
              <Label>To Follow Up</Label>
              <span className="enquiry-dashboard__details--block--to-follow-up">
                {to_follow_up_count ?? 0}
              </span>
            </li>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_TODAY_FOLLOW_UP);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={TodayToFollowUp} alt="/" />
              <Label>Today's Follow Up</Label>
              <span className="enquiry-dashboard__details--block--today-follow-up">
                {today_follow_up_count ?? 0}
              </span>
            </li>
            <li
              className="enquiry-dashboard__details--block--li"
              onClick={() => {
                setQueryType(StdEnqQueryType.STD_ENQ_CALLED_TODAY);
                setStudentEnquiryModal(!studentEnquiryModal);
              }}
            >
              <img src={CalledToday} alt="/" />
              <Label>Called Today</Label>

              <span className="enquiry-dashboard__details--block--called-today">
                {called_today_count ?? 0}
              </span>
            </li>
          </ul>
        </div>
        <div className="col h-100">
          <div className="enquiry-dashboard__details--conversion">
            <div>
              <Title variant="subtitle1">Conversion</Title>
              <ul>
                <li className="enquiry-dashboard__details--block--conversion--li">
                  <img src={Conversion} alt="/" />
                  <span className="enquiry-dashboard__details--block--conversion">
                    {(admitted_student_count! / enq_student_count!) * 100
                      ? `${format(
                          (admitted_student_count! / enq_student_count!) * 100
                        )}%`
                      : 0}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <Title variant="subtitle1">Vacant Seats</Title>
              <ul>
                <li className="enquiry-dashboard__details--block--conversion--li">
                  <img src={VacantSeats} alt="/" />
                  <span className="enquiry-dashboard__details--block--conversion font-purple">
                    {total_vacant_seats ?? 0}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="enquiry-dashboard__details--qrcode"
            onClick={() => setQrCodeModal(!qrCodeModal)}
          >
            {
              <QRCode
                value={`https://dev.myeduate.com/${InstId}/studentenquiry/basicdetails`}
                className="enquiry-dashboard__details--qrcode--url"
              />
            }
            <div className="enquiry-dashboard__details--qrcode--text">
              <span>Scan to fill details</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 enquiry-dashboard__data">
        {/* <div className="col h-100 enquiry-dashboard__details--block">
          <Title variant="subtitle1">
            Total No of Enquiries (
            {currentDate.toLocaleString("default", { month: "long" })}{" "}
            {currentYear})
          </Title>
          <ReactEcharts
            option={options}
            className="enquiry-dashboard__charts"
          />
        </div> */}
        <div className="col h-100 enquiry-dashboard__details--block">
          <div className="enquiry-dashboard__details--block--tabs">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Reference Through" value={0} {...a11yProps(0)} />

              <Tab label="Enquired Student Count" value={1} {...a11yProps(1)} />
            </Tabs>
          </div>

          <div className="enquiry-dashboard__details--block--tabpanel">
            <TabPanel value={value} index={0} dir={theme.direction}>
              <ReferenceThrough startDate={startDate} endDate={endDate} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <MonthlyCount startDate={startDate} endDate={endDate} />
            </TabPanel>
          </div>
        </div>
        <div className="col h-100 enquiry-dashboard__details--block">
          <EnquiredStudents startDate={startDate} endDate={endDate} />
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentEnquiryModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentEnquiry
              pageType={PageFor.MODAL}
              setModalFlag={setStudentEnquiryModal}
              queryType={queryType}
              shouldDispatchId={false}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentEnquiryModal(!studentEnquiryModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={qrCodeModal}
        ariaHideApp={false}
        style={PrintQRCodeModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <QRCodeGenerator
              url={
                accessType === AccessType.DEV
                  ? `https://dev.myeduate.com/${InstId}/studentenquiry/basicdetails`
                  : AccessType.PROD
                  ? `https://edu.myeduate.com/${InstId}/studentenquiry/basicdetails`
                  : ""
              }
              setModalFlag={setQrCodeModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setQrCodeModal(!qrCodeModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Details;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../stories/Input/Input";
import { NavLink } from "react-router-dom";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../../utils/Enum.types";

const List = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  const List = [
    {
      value: 1,
      label: "Student's Absent List",
      url: `/${InstId}/academics/reports/attendance/absenties`,
    },
    {
      value: 2,
      label: "Shortage of Attendance",
      url: `/${InstId}/academics/reports/attendance/soa`,
    },
    ...(!enablePerSessionAtt
      ? [
          {
            value: 3,
            label: "Daily Attendance Report",
            url: `/${InstId}/academics/reports/attendance/dailyattendance`,
          },
        ]
      : []),
    {
      value: 4,
      label: "OverAll Attendance Report",
      url: `/${InstId}/academics/reports/attendance/overall`,
    },
    ...(!enablePerSessionAtt
      ? [
          {
            value: 5,
            label: "Monthly Wise All Student Report",
            url: `/${InstId}/academics/reports/attendance/monthly`,
          },
        ]
      : []),
    {
      value: 6,
      label: "Individual Student Report",
      url: `/${InstId}/academics/reports/attendance/individualstudent`,
    },
    ...(!enablePerSessionAtt
      ? [
          {
            value: 7,
            label: "Attendance Marked & Unmarked Report",
            url: `/${InstId}/academics/reports/attendance/marked`,
          },
        ]
      : []),
    ...(enablePerSessionAtt
      ? [
          {
            value: 8,
            label: "Teacher / Session Marked and Unmarked Status ",
            url: `/${InstId}/academics/reports/attendance/teacher/session`,
          },
        ]
      : []),
  ];

  return (
    <>
      <TableContainer className="fb-form-report__left--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Attendance Reports </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <Input id="search" placeholder="Search..." />
            </TableRow>
            {List.map((label) => {
              return (
                <TableRow key={label.value}>
                  <TableCell
                    onClick={() => {
                      navigate(label.url);
                    }}
                  >
                    <NavLink to={label.url}>{label.label}</NavLink>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;

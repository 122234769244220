import React, { useContext, useEffect } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import Chat from "../../../../../images/Chat.svg";
import { Button } from "../../../../../stories/Button/Button";
import { useLazyQuery } from "@apollo/client";
import { GetAcdFeedbackStdRemarks } from "../../../queries/test/query";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { AppContext } from "../../../../../context/context";

interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  subjectId: number;
  ratingId: number;
  ratingQuestion: string;
  entry_id?: number;
}
const Remarks = ({
  setModalFlag,
  subjectId,
  ratingId,
  ratingQuestion,
  entry_id,
}: props) => {
  const { token } = useToken();
  const { entryId, InstId, formId, empId } = useParams();
  const { state } = useContext(AppContext);

  const { entry_level } = useInstitutionConfiguration();
  const [GetOverAllRemarks, { data: OverAllRemarks }] = useLazyQuery(
    GetAcdFeedbackStdRemarks
  );

  useEffect(() => {
    if (token && ratingId && formId && entry_level) {
      GetOverAllRemarks({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          entry_level,
          entry_id: entryId ? Number(entryId) : entry_id,
          pr_emp_id: empId ? Number(empId) : 0,
          subj_master_id: subjectId ? subjectId : 0,
          form_name_id: Number(formId),
          fb_form_detail_id: ratingId,
        },
      });
    }
  }, [
    token,
    GetOverAllRemarks,
    ratingId,
    entryId,
    empId,
    formId,
    subjectId,
    entry_level,
    ratingId,
  ]);

  return (
    <>
      <div className="fb-form-view__questionwise--remarks">
        <div className="fb-form-view__questionwise--remarks--title">
          <Title>Per Question Remarks</Title>
          <img
            src={Close}
            alt=""
            className="modal-close-icon"
            onClick={() => setModalFlag(false)}
          />
        </div>
        <div className="fb-form-view__questionwise--remarks--data">
          <b className="fb-form-view__questionwise--remarks--ques font-blue">
            {ratingQuestion}
          </b>
          <div className="fb-form-view__questionwise--remarks--block">
            <div className="fb-form-view__questionwise--remarks--total">
              <div className="fb-form-view__questionwise--remarks--total--text">
                <img src={Chat} alt="" />
                <b>Total Remarks</b>
              </div>
              <b className="fb-form-view__questionwise--remarks--total--number">
                {OverAllRemarks &&
                  OverAllRemarks.GetAcdFeedbackStdRemarks.length}
              </b>
            </div>
            <ul className="fb-form-view__questionwise--remarks--list">
              {OverAllRemarks &&
                OverAllRemarks.GetAcdFeedbackStdRemarks.map(
                  (res: string, index: number) => {
                    return (
                      <li key={index}>
                        {index + 1}: &nbsp;
                        <label key={index}>{res}</label>
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default Remarks;

import React from "react";
import Home from "../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import Input from "../../../../../stories/Input/Input";
import CampusActivity from "../CampusActivity";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate } from "react-router";
import { PageFor } from "../../../../../utils/Enum.types";

const Index = () => {
  const navigate = useNavigate();
  return (
    <>
      <Home />
      <Title>My Campus</Title>
      <div className="std-login-campus">
        <div className=" row g-0 std-login-campus__container justify-content-center">
          <div className="col-4 std-login-campus__container--column">
            <div className="std-login-campus__container--column--search">
              <Input id="search" placeholder="Search..." />
            </div>
            <div className="std-login-campus__container--activities">
              <CampusActivity pageType={PageFor.GENERAL} />
            </div>
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

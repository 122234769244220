import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { Direction } from "../../../utils/Enum.types";
import { GetStdTestMarks } from "../queries/test/query";
import { StudentAcdNode } from "./useAcdStudentsData";
import useActiveAcademicYear from "./useActiveAcademicYear";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { responseType } from "../../../utils/Form.types";
import { pageInfo } from "../../../Types/pagination";
import useCheckAllocationType from "./useCheckAllocationType";
export enum AcdStdMarksQueryType {
  TEST_MARKS_BY_STUDENT_ID = "TEST_MARKS_BY_STUDENT_ID",
  TEST_MARKS = "TEST_MARKS",
  TEST_MARKS_AT_ENTRY_LEVEL = "TEST_MARKS_AT_ENTRY_LEVEL",
  TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID = "TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID",
}
type StdMarksQuery = {
  marks_query_type: AcdStdMarksQueryType;
  ids: number[];
  entry_id: number;
  entry_level: string;
};
export type TestMarksNode = {
  id: number;
  subj_marks_scored_total_1: number;
  subj_marks_scored_total_2: number;
  subj_marks_scored_total_3: number;
  subj_marks_scored_total_4: number;
  subj_marks_scored_total_5: number;
  subj_marks_scored_total_6: number;
  subj_marks_scored_total_7: number;
  subj_marks_scored_total_8: number;
  subj_marks_scored_total_9: number;
  subj_marks_scored_total_10: number;
  subj_marks_scored_total_11: number;
  subj_marks_scored_total_12: number;
  subj_marks_scored_total_13: number;
  subj_marks_scored_total_14: number;
  subj_marks_scored_total_15: number;
  subj_marks_scored_total_16: number;
  subj_marks_scored_total_17: number;
  subj_marks_scored_total_18: number;
  subj_marks_scored_total_19: number;
  subj_marks_scored_total_20: number;
  subj_marks_scored_total_21: number;
  subj_marks_scored_total_22: number;
  subj_marks_scored_total_23: number;
  subj_marks_scored_total_24: number;
  subj_marks_scored_total_25: number;
  elective_subj_id_1: number;
  elective_subj_id_2: number;
  elective_subj_id_3: number;
  elective_subj_id_4: number;
  elective_subj_id_5: number;
  elective_subj_id_6: number;
  elective_subj_id_7: number;
  elective_subj_id_8: number;
  elective_subj_id_9: number;
  elective_subj_id_10: number;
  elective_subj_id_11: number;
  elective_subj_id_12: number;
  elective_subj_id_13: number;
  elective_subj_id_14: number;
  elective_subj_id_15: number;
  elective_subj_id_16: number;
  elective_subj_id_17: number;
  elective_subj_id_18: number;
  elective_subj_id_19: number;
  elective_subj_id_20: number;
  elective_subj_id_21: number;
  elective_subj_id_22: number;
  elective_subj_id_23: number;
  elective_subj_id_24: number;
  elective_subj_id_25: number;
  subj_percentage_1: number;
  subj_percentage_2: number;
  subj_percentage_3: number;
  subj_percentage_4: number;
  subj_percentage_5: number;
  subj_percentage_6: number;
  subj_percentage_7: number;
  subj_percentage_8: number;
  subj_percentage_9: number;
  subj_percentage_10: number;
  subj_percentage_11: number;
  subj_percentage_12: number;
  subj_percentage_13: number;
  subj_percentage_14: number;
  subj_percentage_15: number;
  subj_percentage_16: number;
  subj_percentage_17: number;
  subj_percentage_18: number;
  subj_percentage_19: number;
  subj_percentage_20: number;
  subj_percentage_21: number;
  subj_percentage_22: number;
  subj_percentage_23: number;
  subj_percentage_24: number;
  subj_percentage_25: number;
  scored_grade_1: string;
  scored_grade_2: string;
  scored_grade_3: string;
  scored_grade_4: string;
  scored_grade_5: string;
  scored_grade_6: string;
  scored_grade_7: string;
  scored_grade_8: string;
  scored_grade_9: string;
  scored_grade_10: string;
  scored_grade_11: string;
  scored_grade_12: string;
  scored_grade_13: string;
  scored_grade_14: string;
  scored_grade_15: string;
  scored_grade_16: string;
  scored_grade_17: string;
  scored_grade_18: string;
  scored_grade_19: string;
  scored_grade_20: string;
  scored_grade_21: string;
  scored_grade_22: string;
  scored_grade_23: string;
  scored_grade_24: string;
  scored_grade_25: string;
  teacher_comments_1: string;
  teacher_comments_2: string;
  teacher_comments_3: string;
  teacher_comments_4: string;
  teacher_comments_5: string;
  teacher_comments_6: string;
  teacher_comments_7: string;
  teacher_comments_8: string;
  teacher_comments_9: string;
  teacher_comments_10: string;
  teacher_comments_11: string;
  teacher_comments_12: string;
  teacher_comments_13: string;
  teacher_comments_14: string;
  teacher_comments_15: string;
  teacher_comments_16: string;
  teacher_comments_17: string;
  teacher_comments_18: string;
  teacher_comments_19: string;
  teacher_comments_20: string;
  teacher_comments_21: string;
  teacher_comments_22: string;
  teacher_comments_23: string;
  teacher_comments_24: string;
  teacher_comments_25: string;
  total_max_marks: number;
  overall_grade: string;
  total_scored_marks: number;
  total_percent: number;
  pass_fail: string;
  test_remarks: string;
  student_details: StudentAcdNode;
};

export type TestMarksEdge = {
  node: TestMarksNode;
};

export type GetStdTestMarksData = {
  GetStdTestMarks: {
    totalCount: number;
    edges: TestMarksEdge[];
    pageInfo: pageInfo;
  };
};
type AcdTestMarksConsoliateOrderField = "TEST_NAME";

type AcdTestMarksConsoliateOrder = {
  direction: Direction;
  field?: string;
};
type GetStdTestMarksVariables = {
  token: string;
  acd_yr_id: number;
  input: StdMarksQuery;
  after: string | null;
  first: number | null;
  admNo: string;
  per_std_subj_allocation: boolean | undefined;
  orderBy?: AcdTestMarksConsoliateOrder;
};
const useStudentTestMarks = (
  marks_query_type: AcdStdMarksQueryType,
  first: number | null,
  admNo: string
) => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId, testId, comboId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [ids, setIds] = useState([0]);
  const { entryId } = useParams();
  const { entry_level } = useInstitutionConfiguration();
  const [studentOptions, setStudentOptions] = useState<responseType[]>([]);
  const { flag, data: flagData } = useCheckAllocationType();

  useEffect(() => {
    if (!marks_query_type) return;
    switch (marks_query_type) {
      case AcdStdMarksQueryType.TEST_MARKS:
        setIds([
          Number(InstId!),
          state.testConductId ? state.testConductId : Number(testId),
        ]);
        break;
      case AcdStdMarksQueryType.TEST_MARKS_BY_STUDENT_ID:
        setIds([
          Number(InstId!),
          state.testConductId ? state.testConductId : Number(testId),
          state.studentId,
        ]);
        break;
      case AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL:
        setIds([
          Number(InstId!),
          state.testConductId ? state.testConductId : Number(testId),
        ]);
        break;
      case AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID:
        if (state.acdCombinationId || comboId)
          setIds([
            Number(InstId!),
            state.testConductId ? state.testConductId : Number(testId),
            state.acdCombinationId ? state.acdCombinationId : Number(comboId),
          ]);
        break;
      default:
        break;
    }
  }, [
    marks_query_type,
    InstId,
    testId,
    state.studentId,
    state.testConductId,
    state.acdCombinationId,
    comboId,
  ]);

  const [GetMarks, { data, loading, error, fetchMore }] = useLazyQuery<
    GetStdTestMarksData,
    GetStdTestMarksVariables
  >(GetStdTestMarks);

  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      entry_level &&
      state.ActiveAcdYr &&
      flag !== undefined &&
      token &&
      ((marks_query_type ===
        AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID &&
        (comboId || state.acdCombinationId) &&
        ids.length === 3) ||
        marks_query_type !==
          AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID)
    ) {
      GetMarks({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          after: null,
          first,
          admNo,
          input: {
            ids,
            marks_query_type,
            entry_id: entryId ? Number(entryId)! : 0,
            entry_level,
          },
          token,
          orderBy: { direction: Direction.ASC, field: "STD_ROLL_NO" },
          per_std_subj_allocation: flag,
        },
      }).then(({ data }) => {
        if (data) {
          if (data.GetStdTestMarks.edges)
            setStudentOptions(
              data.GetStdTestMarks.edges.map(({ node }, index) => ({
                label: node.student_details.std_adm_no,
                value: node.student_details.id,
              }))
            );
        }
      });
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetMarks,
    entry_level,
    data,
    state.ActiveAcdYr,
    flagData,
    flag,
    state.acdCombinationId,
    comboId,
    entryId,
    marks_query_type,
    data,
    testId,
    state.studentId,
    first,
    ids,
    token,

    // admNo,
  ]);

  return { testMarks: { data, loading, error, fetchMore, studentOptions } };
};

export default useStudentTestMarks;

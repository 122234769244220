import { useEffect, useState } from "react";
import useToken from "./useToken";
import { useLazyQuery } from "@apollo/client";
import { GetSwAcctGroupLdgrs } from "../queries/Eduate/list";
export interface GetSwAcctGroupLdgrsData {
  GetSwAcctGroupLdgrs: string[];
}
interface GetSwAcctGroupLdgrsVars {
  token: string;
}
const useSwGroupLedgers = () => {
  const { token } = useToken();
  const [groupLedgerOptions, setGroupLedgerOptions] = useState<string[]>([]);
  const [GetSwGroupLedgers, { data, loading }] = useLazyQuery<
    GetSwAcctGroupLdgrsData,
    GetSwAcctGroupLdgrsVars
  >(GetSwAcctGroupLdgrs, {
    variables: {
      token,
    },
  });
  useEffect(() => {
    if (token) {
      GetSwGroupLedgers().then(({ data }) => {
        if (data) {
          setGroupLedgerOptions(data.GetSwAcctGroupLdgrs);
        }
      });
    }
  }, [token, GetSwGroupLedgers, data]);
  return {
    data,
    loading,
    groupLedgerOptions,
  };
};

export default useSwGroupLedgers;

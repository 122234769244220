import React, { forwardRef, useRef, useState } from "react";
import CompleteTransactionModal from "../../../Accounts/Reports/AccountLedgerBook/Modals/CompleteTransactionModal";
import { FileUploadParams, PageFor } from "../../../../utils/Enum.types";
import { LedgersOrRegisters } from "../../../../utils/types";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../stories/Button/Button";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";

interface Props {
  type: LedgersOrRegisters;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Template1 = ({ type, setModalFlag }: Props) => {
  const [feeCollectionModal, setFeeCollectionModal] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});
  const { InstFormData } = useInstDetails(1);

  const ComponentToPrint = forwardRef<HTMLDivElement>(() => {
    return (
      <>
        <div className="h-100">
          <div className="ledger-print--inst-data">
            <div className="ledger-print--inst-data--details">
              <div className="ledger-print--inst-data--logo">
                <img src={LogoOrSign.defaultLogo} alt="/" />
              </div>

              <div className="ledger-print--inst-data--details-block">
                <b className="ledger-print--inst-data--inst-name">
                  {InstFormData.inst_name}
                </b>

                <span className="ledger-print--inst-data--inst-address">
                  {[
                    InstFormData.inst_address,
                    InstFormData.inst_place,
                    InstFormData.inst_pin,
                  ]
                    .filter(Boolean)
                    .join(", ")
                    .trim()}
                </span>
              </div>
            </div>
          </div>
          <CompleteTransactionModal
            setCompleteDetailsModal={setFeeCollectionModal}
            CompleteDetailsModal={feeCollectionModal}
            pageType={PageFor.PRINT}
            type={type}
          />
        </div>
      </>
    );
  });

  return (
    <>
      <div className="ledger-print" ref={componentRef}>
        <style type="text/css" media="print" scoped>
          {`
            @page {
              size: A4 Portarit;
              margin: 1cm;
            }
     
        `}
        </style>

        <ComponentToPrint />
      </div>
      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
        documentTitle={InstFormData.inst_name}
      />
      <Button mode="cancel" onClick={() => setModalFlag(false)} />
    </>
  );
};

export default Template1;

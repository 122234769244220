import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import {
  GetPayRollEmpLeaveMasterDetailsData,
  GetPayRollEmpLeaveMasterDetailsVars,
} from "../Types/masterDataTypes";
import { AppContext } from "../../../context/context";

import { GetPayRollEmpLeaveMasterDetails } from "../queries/leaveledger/list";

const useEmpLeaveMaster = (pr_acd_yr_id: number) => {
  const { empId, InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [employeeId, setEmpId] = useState(0);
  const [GetEmpLeaveMaster, EmpLeaveData] = useLazyQuery<
    GetPayRollEmpLeaveMasterDetailsData,
    GetPayRollEmpLeaveMasterDetailsVars
  >(GetPayRollEmpLeaveMasterDetails, {
    variables: {
      token,
      inst_id: InstId!,
      pr_acd_yr_id,
      pr_emp_id: employeeId,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (state.employeeId) {
      setEmpId(state.employeeId);
    }
    if (empId) {
      setEmpId(Number(empId));
    }
  }, [empId, state.employeeId]);
  useEffect(() => {
    if (employeeId && pr_acd_yr_id) {
      GetEmpLeaveMaster();
    }
  }, [employeeId, pr_acd_yr_id, GetEmpLeaveMaster]);
  return { EmpLeaveData };
};

export default useEmpLeaveMaster;

import useInstLabels from "../customhooks/general/useInstLabels";
import useInstitutionConfiguration from "../customhooks/useInstitutionConfiguration";
import {
  AMOUNT_TEXT_ALIGN,
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../styles/DataGridTableStyles";
import { ColumnVisibilityFor } from "../utils/Enum.types";
interface props {
  jsonFor: ColumnVisibilityFor;
}

const useStudentTableJson = ({ jsonFor }: props) => {
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const tableJson = {
    Student: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        ...(jsonFor !== ColumnVisibilityFor.FOR_ALUMINI
          ? [
              {
                headerName: "Adm No.",
                cellClassName: "td-sl-no",
                field: "std_adm_no",
                headerAlign: HEADER_TEXT_ALIGN,
                align: SLNO_TEXT_ALIGN,
              },
              {
                headerName: "Reg No.",
                cellClassName: "td-sl-no",
                field: "std_reg_no",
                headerAlign: HEADER_TEXT_ALIGN,
                align: SLNO_TEXT_ALIGN,
              },
            ]
          : []),

        {
          headerName: "Name",
          cellClassName: "td-name td-onClick",
          field: "std_name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        ...(jsonFor !== ColumnVisibilityFor.FOR_ALUMINI
          ? [
              {
                headerName: "Father Name",
                cellClassName: "td-name",
                field: "std_father_name",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
                disableColumnSelector: true,
                disableColumnMenu: true,
              },
              {
                headerName: "Mother Name",
                cellClassName: "td-name",
                field: "std_mother_name",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
                hide: true,
              },

              {
                headerName: "Gender",
                cellClassName: "td-gender",
                field: "std_sex",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
              {
                headerName: "DOA",
                cellClassName: "td-date",
                field: "std_doa",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
                disableColumnSelector: true,
                disableColumnMenu: true,
              },
              {
                headerName: "DOB",
                cellClassName: "td-date",
                field: "std_dob",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),

        {
          headerName: "Email",
          cellClassName: "td-email",
          field: "std_email",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "std_mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },

        ...(jsonFor !== ColumnVisibilityFor.FOR_ALUMINI && jsonFor !== ColumnVisibilityFor.FOR_PRINT
          ? [
              {
                headerName: "Roll No.",
                cellClassName: "td-adm-no",
                field: "std_roll_no",
                headerAlign: HEADER_TEXT_ALIGN,
                align: SLNO_TEXT_ALIGN,
              },
            ]
          : []),

        ...(USE_DEPARTMENT_KEY && jsonFor !== ColumnVisibilityFor.FOR_ALUMINI
          ? [
              {
                headerName: `${departmentLabel}`,
                cellClassName: "td-desc",
                field: "dept",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_BRANCH_KEY
          ? [
              {
                headerName: `${branchLabel}`,
                cellClassName: "td-desc",
                field: "branch",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_CLASS_KEY
          ? [
              {
                headerName: `${classLabel}`,
                cellClassName: "td-desc",
                field: "class",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_SEMESTER_KEY && jsonFor !== ColumnVisibilityFor.FOR_ALUMINI
          ? [
              {
                headerName: `${semesterLabel}`,
                cellClassName: "td-desc",
                field: "sem",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_SECTION_KEY &&
        jsonFor !== ColumnVisibilityFor.FOR_ALUMINI &&
        jsonFor !== ColumnVisibilityFor.RESERVATION
          ? [
              {
                headerName: `${sectionLabel}`,
                cellClassName: "td-desc",
                field: "sec",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_CATEGORY_KEY && jsonFor !== ColumnVisibilityFor.FOR_ALUMINI
          ? [
              {
                headerName: `${categoryLabel}`,
                cellClassName: "td-desc",
                field: "category",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(jsonFor === ColumnVisibilityFor.FOR_ACCOUNTS
          ? [
              {
                headerName: "Total",
                cellClassName: "td-amount-total",
                field: "total",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Collected",
                cellClassName: "td-amount-collected",
                field: "std_demand_received",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Balance",
                cellClassName: "td-amount-balance",
                field: "std_demand_bal",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },

              {
                headerName: "Fresher/Existing",
                cellClassName: "td-desc",
                field: "std_type",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "O.B",
                cellClassName: "td-amount",
                field: "std_demand_ob",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Demand Amount",
                cellClassName: "td-amount",
                field: "std_demand_amt",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Concession",
                cellClassName: "td-amount",
                field: "std_demand_concession",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Refunds",
                cellClassName: "td-amount",
                field: "std_demand_refunds",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Deposit O.B",
                cellClassName: "td-amount",
                field: "std_deposit_ob",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Deposit Amount",
                cellClassName: "td-amount",
                field: "std_deposit_amt",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Deposit Total",
                cellClassName: "td-amount",
                field: "std_deposit_total",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
              {
                headerName: "Deposit Adjusted",
                cellClassName: "td-amount",
                field: "std_deposit_adjusted",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
            ]
          : []),
        ...(jsonFor === ColumnVisibilityFor.FOR_ALUMINI
          ? [
              {
                headerName: "Pass out Year",
                cellClassName: "td-desc",
                field: "pass_out_year",
                headerAlign: HEADER_TEXT_ALIGN,
                align: AMOUNT_TEXT_ALIGN,
              },
            ]
          : []),
      ],
    },
  };
  return tableJson;
};

export default useStudentTableJson;

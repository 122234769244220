// eslint-disable-next-line
import { Route, Routes } from "react-router-dom";
import {
  EduateModule,
  LicenseTypes,
  ModuleName,
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
} from "../../utils/Enum.types";
import useEduateModuleConfig from "../../customhooks/useModulesConfiguartion";
import useUserRightsByEmpId from "../../Modules/UserRights/hooks/useUserRightsByEmpId";
import AdmissionHome from "../../Modules/Master/Home/Index";
import Configurations from "../../Modules/Configurations/PerModuleConfiguration";

// routes dept
import CreateDepartment from "../../Modules/Master/instdetails/department/Index";
import CreateBranch from "../../Modules/Master/instdetails/branch/Index";
import CreateClass from "../../Modules/Master/instdetails/classes/Index";
import CreateSemester from "../../Modules/Master/instdetails/semester/Index";
import CreateSection from "../../Modules/Master/instdetails/section/Index";
import ClassWiseCount from "../../Modules/Master/ClassWiseCount/Index";
import CreateCategory from "../../Modules/Master/instdetails/categories/Index";
import Page404 from "../../pages/Page404";
import StudentsRoutes from "./StudentsRoutes";
import ParentsRoutes from "./ParentsRoutes";
import { SwConfigQueryType } from "../../Modules/HR/enums/Enum.types";
import ViewActiveYears from "../../Modules/Master/instdetails/ViewActiveYears";
import { useContext, useState } from "react";
import { AppContext } from "../../context/context";
import ChangeOfDepartment from "../../Modules/Master/Student/ChangeOfDepartment/Index";
import Template from "../../Modules/Templates/Index";
import Tags from "../../Modules/Eduate/Tags/Tags";
import StudentListForDocuments from "../../Modules/Master/Reports/Documents/Index";
import SemAcdConfig from "../../Modules/Master/instdetails/SemConfig/SemAcdConfig";
import StudentDocs from "../../Modules/Master/Student/MandatoryDocs/StudentDocs";
import InstitutionLevel from "../../Modules/Master/Reports/InstitutionLevel/Index";
import { ExcelAdmQueryType } from "../../Modules/Master/Reports/InstitutionLevel/useExportMutation";
const AdmissonRoutes = () => {
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);
  const { state } = useContext(AppContext);
  const { error } = useEduateModuleConfig(EduateModule.ADMISSION);
  const { USE_ADMISSIONS } = useUserRightsByEmpId();
  return (
    <>
      <Routes>
        {error || (!USE_ADMISSIONS?.flag && !state.claims?.OWNER) ? (
          <Route path="*" element={<Page404 />} />
        ) : (
          <>
            {state.claims?.OWNER ? (
              <Route path="/" element={<AdmissionHome DashBoardRequired />} />
            ) : (
              <>
                <Route path="/department" element={<CreateDepartment />} />
                <Route
                  path="/department/:deptId/branch"
                  element={<CreateBranch />}
                />
                <Route
                  path="/department/:deptId/branch/:branchId/class"
                  element={<CreateClass />}
                />
                <Route
                  path="/department/:deptId/branch/:branchId/class/:classId/semester"
                  element={<CreateSemester />}
                />
                <Route
                  path="/department/:deptId/branch/:branchId/class/:classId/semester/:semesterId/section"
                  element={<CreateSection />}
                />
                <Route path="/categories" element={<CreateCategory />} />
                <Route path="/semacdconfig" element={<SemAcdConfig />} />

                <Route
                  path="/configurations"
                  element={
                    <Configurations
                      config_query_type={SwConfigQueryType.INST_BY_MODULE}
                      str_value={ModuleName.ADMISSION}
                      int_value={0}
                      setModalFlag={setModalFlag}
                      pageType={PageFor.GENERAL}
                    />
                  }
                />
                <Route
                  path="/changeofdepartment"
                  element={
                    <ChangeOfDepartment pageFor={LicenseTypes.ADMISSION} />
                  }
                />
                <Route path="/viewactiveyears" element={<ViewActiveYears />} />
                <Route path="/students/*" element={<StudentsRoutes />} />
                <Route path="/parents/*" element={<ParentsRoutes />} />
                <Route path="/classWiseCount" element={<ClassWiseCount />} />
                <Route path="/templates" element={<Template />} />
                <Route path="/tags" element={<Tags />} />
                <Route
                  path="/reports/instlevel/student/adm_list"
                  element={
                    <InstitutionLevel
                      reportType={ExcelAdmQueryType.ADM_STUDENT_LIST}
                    />
                  }
                />
                <Route
                  path="/reports/instlevel/student/religion"
                  element={
                    <InstitutionLevel
                      reportType={ExcelAdmQueryType.RELIGION_STUDENT_LIST}
                    />
                  }
                />
                <Route
                  path="/reports/instlevel/student/age"
                  element={
                    <InstitutionLevel
                      reportType={ExcelAdmQueryType.AGE_STUDENT_LIST}
                    />
                  }
                />
                <Route
                  path="/reports/instlevel/student/caste"
                  element={
                    <InstitutionLevel
                      reportType={ExcelAdmQueryType.CASTE_STUDENT_LIST}
                    />
                  }
                />
                <Route
                  path="/reports/instlevel/student/sc_st"
                  element={
                    <InstitutionLevel
                      reportType={ExcelAdmQueryType.SC_ST_STUDENT_LIST}
                    />
                  }
                />

                <Route
                  path="/documents/studentlist"
                  element={
                    <StudentListForDocuments
                      pageType={PageFor.GENERAL}
                      studentListFor={StudentListFor.GENERAL}
                      queryType={StudentReportType.GENERAL}
                      setStudentModal={setModalFlag}
                      studentModal={modalFlag}
                      label={PageLabel.TRUE}
                    />
                  }
                />

                <Route
                  path="/documents/default_std_docs"
                  element={<StudentDocs />}
                />

                <Route path="/" element={<AdmissionHome DashBoardRequired />} />
              </>
            )}
          </>
        )}
      </Routes>
    </>
  );
};

export default AdmissonRoutes;

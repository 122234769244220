import { gql } from "@apollo/client";

export const GetVoucherNumber = gql`
  query GetVoucherNumber(
    $token: String!
    $vo_book_key: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetVoucherNumber(
      token: $token
      vo_book_key: $vo_book_key
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      vo_number
      vo_book_key
      voucher_sub_type
    }
  }
`;

export const GetAcctTransferVoucherDetailsByInstId = gql`
  query GetAcctTransferVoucherDetailsByInstId(
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
  ) {
    GetAcctTransferVoucherDetailsByInstId(
      token: $token
      fin_yr_id: $fin_yr_id
      inst_id: $inst_id
    ) {
      voucher_master_details {
        v_no
        v_total_cr_amt
        v_transcation_narration
        v_date
      }
      from_student_details {
        std_reg_no
        std_adm_no
        first_name
        middle_name
        last_name
        branch_desc
        branch_id
        class_desc
        class_id
        dept_desc
        dept_id
        sem_desc
        sem_id
        section_desc
        section_id
        cat_desc
        category_id
        std_father_name
      }
      to_student_details {
        std_reg_no
        std_adm_no
        first_name
        middle_name
        last_name
        branch_desc
        branch_id
        class_desc
        class_id
        dept_desc
        dept_id
        sem_desc
        sem_id
        section_desc
        section_id
        cat_desc
        category_id
        std_father_name
      }
    }
  }
`;
export const GetAcctVoucherAnonymous = gql`
  query GetAcctVoucherAnonymous(
    $first: Int
    $after: Cursor
    $sortBy: AcctVoucherAnonymousOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $start_date: Time!
    $end_date: Time!
    $anonymous_std_type: String!
    $vTranscationNo: String
  ) {
    GetAcctVoucherAnonymous(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      inst_id: $inst_id
      start_date: $start_date
      end_date: $end_date
      anonymous_std_type: $anonymous_std_type
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            hasVoucherMasterWith: [
              { vTranscationNoContainsFold: $vTranscationNo }
            ]
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          voucher_master_id
          v_transcation_no
          anonymous_type
          received_amt
          adjusted_amt
          bal_amt
          adjustment_details {
            id
          }
          voucher_master_details {
            id
            v_type
            v_date
            v_no
            v_std_passout_receipt
            v_total_db_amt
            v_std_amt_total
            v_total_cr_amt
            v_book_type
            v_std_enquiry
            v_std_refund
            enquiry_student_id
            v_std_deposit_adjusted
            v_std_deposit
            v_std_demand_receipt
            v_std_scholarship_deposit

            v_std_receipt
            party_bill_no
            v_transcation_type
            student_id
            v_transcation_cash_or_bank
            v_transcation_no
            party_name
            v_transcation_narration
            acct_voucher_details {
              id
              vo_date
              vo_cr_db
              vo_sl_no
              vo_cr
              vo_db
              vo_cr_total
              vo_db_total
              acct_ldgr_id
              voucher_master_id
              acct_ldgr {
                ldgr_desc
                id
                ldgr_cash_bank_other
              }
            }
          }
        }
      }
    }
  }
`;
export const GetAcctVouchers = gql`
  query GetAcctVouchers(
    $first: Int
    $after: Cursor
    $sortBy: AcctVoucherMasterOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $input: VoucherQueryData!
    $v_no: String
    $deposit: Boolean
    $vTranscationCashOrBank: String
    $partyName: String
    $vTranscationNo: String
    $vTranscationNarration: String
    $ldgrDesc: String
    $amount: Float
  ) {
    GetAcctVouchers(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            vNoContainsFold: $v_no
            vStdDepositAdjusted: $deposit
            vTranscationCashOrBankEqualFold: $vTranscationCashOrBank
            partyNameContainsFold: $partyName
            vTranscationNoContainsFold: $vTranscationNo
            vTranscationNarrationContainsFold: $vTranscationNarration
            vTotalDbAmt: $amount
            vTotalCrAmt: $amount
            hasMasterVoucherDetailsWith: [
              { hasAcctLdgrWith: { ldgrDescContainsFold: $ldgrDesc } }
            ]
          }
        ]
      }

      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          v_type
          v_date
          v_no
          created_at
          updated_at
          v_std_passout_receipt
          v_total_db_amt
          v_std_amt_total
          v_total_cr_amt
          v_book_type
          v_std_enquiry
          v_std_refund
          v_std_refund_deposit
          mkck_voucher_id
          enquiry_student_id
          v_std_deposit_adjusted
          v_std_deposit
          v_std_demand_receipt
          v_std_scholarship_deposit
          v_std_anonymous_deposit_adjusted
          v_std_receipt
          party_bill_no
          v_transcation_type
          passout_student_id
          student_id
          v_transcation_cash_or_bank
          v_transcation_no
          party_name
          class_id
          v_transcation_narration
          update_count
          class_details {
            class_desc
            id
          }
          branch_details {
            branch_desc
          }
          acct_voucher_details {
            id
            vo_date
            vo_cr_db
            vo_sl_no
            vo_cr
            vo_db
            vo_cr_total
            vo_db_total
            acct_ldgr_id
            voucher_master_id
            acct_ldgr {
              ldgr_desc
              id
              ldgr_cash_bank_other
            }
          }
          cr_ldgr_details {
            ldgr_desc
          }
          db_ldgr_details {
            ldgr_desc
          }
        }
      }
    }
  }
`;
export const GetAcctChallans = gql`
  query GetAcctChallans(
    $first: Int
    $after: Cursor
    $sortBy: AcctChallanMasterOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $input: ChallanQueryData!
    $inst_id: ID!
    $v_no: String
    $vTranscationNo: String
    $amount: Float
    $ldgrDesc: String
  ) {
    GetAcctChallans(
      token: $token
      fin_yr_id: $fin_yr_id
      inst_id: $inst_id
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            vNoContainsFold: $v_no
            vTranscationNoContainsFold: $vTranscationNo
            vTotalDbAmt: $amount
            vTotalCrAmt: $amount
            hasMasterChallanDetailsWith: [
              { hasAcctLdgrWith: { ldgrDescContainsFold: $ldgrDesc } }
            ]
          }
        ]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          v_type
          v_date
          v_no
          created_at
          updated_at
          v_std_passout_receipt
          v_total_db_amt
          v_std_amt_total
          v_total_cr_amt
          v_book_type
          v_std_enquiry
          v_std_refund
          v_reconciled
          v_reconciled_date
          v_std_refund_deposit
          enquiry_student_id
          v_std_deposit_adjusted
          v_std_deposit
          v_std_demand_receipt
          v_std_scholarship_deposit
          v_std_anonymous_deposit_adjusted
          v_std_receipt
          party_bill_no
          v_transcation_type
          passout_student_id
          student_id
          vo_voucher_master_id
          v_transcation_cash_or_bank
          v_transcation_no
          party_name
          v_transcation_narration

          acct_challan_details {
            id
            vo_date
            vo_cr_db
            vo_sl_no
            vo_cr
            vo_db
            vo_cr_total
            vo_db_total
            acct_ldgr_id
            challan_master_id
            acct_ldgr_details {
              ldgr_desc
            }
          }
          acct_ldgr_db_details {
            ldgr_desc
          }
        }
      }
    }
  }
`;

export const GetAcctVouchersForLog = gql`
  query GetAcctVouchers(
    $first: Int
    $after: Cursor
    $sortBy: AcctVoucherMasterOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $input: VoucherQueryData!
    $v_no: String
    $deposit: Boolean
    $vTranscationCashOrBank: String
    $partyName: String
    $vTranscationNo: String
    $vTranscationNarration: String
    $ldgrDesc: String
    $amount: Float
  ) {
    GetAcctVouchers(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            vNoContainsFold: $v_no
            vStdDepositAdjusted: $deposit
            vTranscationCashOrBankEqualFold: $vTranscationCashOrBank
            partyNameContainsFold: $partyName
            vTranscationNoContainsFold: $vTranscationNo
            vTranscationNarrationContainsFold: $vTranscationNarration
            vTotalDbAmt: $amount
            vTotalCrAmt: $amount
            hasMasterVoucherDetailsWith: [
              { hasAcctLdgrWith: { ldgrDescContainsFold: $ldgrDesc } }
            ]
          }
        ]
      }

      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          v_type
          v_date
          v_no
          created_at
          updated_at
          v_std_passout_receipt
          v_total_db_amt
          v_std_amt_total
          v_total_cr_amt
          v_book_type
          v_std_enquiry
          v_std_refund
          v_std_refund_deposit
          mkck_voucher_id
          enquiry_student_id
          v_std_deposit_adjusted
          v_std_deposit
          v_std_demand_receipt
          v_std_scholarship_deposit
          v_std_anonymous_deposit_adjusted
          v_std_receipt
          party_bill_no
          v_transcation_type
          passout_student_id
          student_id
          v_transcation_cash_or_bank
          v_transcation_no
          party_name
          v_transcation_narration
          update_count
          acct_voucher_details {
            id
            vo_date
            vo_cr_db
            vo_sl_no
            vo_cr
            vo_db
            vo_cr_total
            vo_db_total
            acct_ldgr_id
            voucher_master_id
            acct_ldgr {
              ldgr_desc
              id
              ldgr_cash_bank_other
            }
          }
          mst_student {
            first_name
            middle_name
            last_name
            std_reg_no
            std_adm_no
            branch {
              branch_desc
            }
            class {
              class_desc
            }
          }
        }
      }
    }
  }
`;

export const GetAcctStdConcessionVouchers = gql`
  query GetAcctStdConcessionVouchers(
    $first: Int
    $after: Cursor
    $sortBy: AcctStdConcessionOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $name: String
    $startDate: Time
    $endDate: Time
  ) {
    GetAcctStdConcessionVouchers(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      inst_id: $inst_id
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          { concessionVouchNoContainsFold: $name }
          {
            concessionVouchDateGTE: $startDate
            concessionVouchDateLTE: $endDate
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          concession_vouch_no
          concession_vouch_date
          concession_total
          concession_naration
          concession_auth_by
          concession_details {
            id
            concession_amt
            acct_ldgr_details {
              id
              ldgr_desc
            }
          }
          mst_student {
            id
            first_name
            middle_name
            last_name
            std_adm_no
            category {
              id
              cat_desc
            }
            branch {
              id
              branch_desc
            }
            class {
              id
              class_desc
            }
            dept {
              id
              dept_desc
            }
            semester {
              id
              sem_desc
            }
            section {
              id
              section_desc
            }
          }
        }
      }
    }
  }
`;
export const GetAcctVoucherSummation = gql`
  query GetAcctVoucherSummation(
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: VoucherQueryData!
  ) {
    GetAcctVoucherSummation(
      token: $token
      fin_yr_id: $fin_yr_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    ) {
      v_std_amt_receipt
      v_std_amt_deposit
      v_std_amt_fine
      v_std_amt_total
      v_std_amt_refunded
      v_std_amt_adjusted
    }
  }
`;
export const GetAcctVouchersMkCkSummation = gql`
  query GetAcctVouchersMkCkSummation(
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: MkckQueryData!
  ) {
    GetAcctVouchersMkCkSummation(
      token: $token
      fin_yr_id: $fin_yr_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    ) {
      inst_id
      mkck_total_cr_amt
      mkck_total_db_amt
    }
  }
`;
export const GetAcctStdConcessionVoucherSummation = gql`
  query GetAcctStdConcessionVoucherSummation(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    GetAcctStdConcessionVoucherSummation(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
    )
  }
`;
export const GetStdVoucherBooks = gql`
  query GetStdVoucherBooks($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetStdVoucherBooks(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      id
      vo_book_key
      vo_book_desc
      vo_book_start_no
      vo_book_current_no
      is_prefixed
      prefix_str
      suffix_str
      separator
      prefill_with_zero
      prefill_width
      is_book_system_based
      voucher_type
      hide_this_book
      voucher_sub_type
      ldgr_details {
        id
        ldgr_desc
      }
    }
  }
`;
export const CheckVouchersTransferStatus = gql`
  query CheckVouchersTransferStatus(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $student_id: ID!
    $change_of_branch_id: ID!
  ) {
    CheckVouchersTransferStatus(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      student_id: $student_id
      change_of_branch_id: $change_of_branch_id
    ) {
      voucher_master_details {
        id
        v_type
        v_date
        v_no
        v_std_passout_receipt
        v_total_db_amt
        v_std_amt_total
        v_total_cr_amt
        v_std_enquiry
        v_std_refund
        enquiry_student_id
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_std_scholarship_deposit

        v_std_receipt
        party_bill_no
        v_transcation_type
        student_id
        v_transcation_cash_or_bank
        v_transcation_no
        party_name
        v_transcation_narration

        acct_voucher_details {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          voucher_master_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
        }
      }
      status
    }
  }
`;

export const GetAcctVouchersMkCk = gql`
  query GetAcctVouchersMkCk(
    $token: String!
    $finYrId: ID!
    $instId: ID!
    $input: MkckQueryData!
    $after: Cursor
    $first: Int
    $orderBy: AcctVoucherMasterMkCkOrder
    $status: String
  ) {
    GetAcctVouchersMkCk(
      token: $token
      fin_yr_id: $finYrId
      inst_id: $instId
      input: $input
      after: $after
      first: $first
      orderBy: $orderBy
      where: { or: [{ mkckStatusContainsFold: $status }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          created_at
          fin_yr
          mkck_type
          mkck_book_type
          mkck_no
          mkck_date
          mkck_total_cr_amt
          mkck_total_db_amt
          mkck_completed
          mkck_status
          party_name
          party_bill_no
          party_bill_date
          transcation_type
          transcation_no
          payment_invoice_filename
          remarks
          last_assigned_details {
            remarks
          }
          acct_voucher_details_mkck {
            id
            vo_date
            vo_cr_db
            vo_sl_no
            vo_cr
            vo_db
            vo_cr_total
            vo_db_total

            acct_ldgr {
              id
              ldgr_desc
              ldgr_cash_bank_other
            }
          }
          created_emp_details {
            id
            emp_middle_name
            emp_last_name
            emp_first_name
            pr_designation_details {
              id
              designation_desc
            }
          }
        }
      }
    }
  }
`;
export const GetAcctMkCkAssigned = gql`
  query GetAcctMkCkAssigned(
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $mkck_voucher_master_id: ID!
  ) {
    GetAcctMkCkAssigned(
      token: $token
      fin_yr_id: $fin_yr_id
      inst_id: $inst_id
      mkck_voucher_master_id: $mkck_voucher_master_id
    ) {
      id
      sl_no
      remarks
      status
      created_emp_details {
        emp_first_name
        emp_last_name
        emp_middle_name
        id
        pr_designation_details {
          designation_desc
          id
        }
      }
      assigned_emp_details {
        emp_first_name
        emp_last_name
        emp_middle_name
        id
        pr_designation_details {
          designation_desc
          id
        }
      }
      mkck_voucher_master {
        id
        created_at
        fin_yr
        mkck_type
        mkck_book_type
        mkck_no
        mkck_date
        mkck_total_cr_amt
        mkck_total_db_amt
        mkck_completed
        mkck_status
        party_name
        party_bill_no
        party_bill_date
        transcation_no
      }
    }
  }
`;
export const GetAcctUserLogsCount = gql`
  query GetAcctUserLogsCount(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
    $input: AcctUserLogData!
  ) {
    GetAcctUserLogsCount(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
      input: $input
    ) {
      add_std_receipt_count
      edit_std_receipt_count
      delete_std_receipt_count
      add_std_concession_count
      edit_std_concession_count
      add_std_refund_count
      edit_std_refund_count
      delete_std_refund_count
      add_payment_count
      edit_payment_count
      delete_payment_count
      add_receipt_count
      edit_receipt_count
      delete_receipt_count
      add_contra_count
      edit_contra_count
      delete_contra_count
      add_journal_count
      edit_journal_count
      delete_journal_count
    }
  }
`;
export const GetAcctStdConcessions = gql`
  query GetAcctStdConcessions(
    $first: Int
    $after: Cursor
    $sortBy: AcctStdConcessionOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $name: String
    $input: AcctStdConcessionQueryData!
  ) {
    GetAcctStdConcessions(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      inst_id: $inst_id
      after: $after
      input: $input
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          { concessionVouchNoContainsFold: $name }
          {
            hasMstStudentWith: {
              or: [
                { firstNameContainsFold: $name }
                { middleNameContainsFold: $name }
                { lastNameContainsFold: $name }
              ]
            }
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          concession_vouch_no
          concession_vouch_date
          concession_total
          created_at
          updated_at
          concession_naration
          concession_auth_by
          concession_details {
            id
            concession_amt
            acct_ldgr_details {
              id
              ldgr_desc
            }
          }
          branch_details {
            branch_desc
          }
          class_details {
            class_desc
          }
          mst_student {
            id
            first_name
            middle_name
            last_name
            std_adm_no
            category {
              id
              cat_desc
            }
            branch {
              id
              branch_desc
            }
            class {
              id
              class_desc
            }
            dept {
              id
              dept_desc
            }
            semester {
              id
              sem_desc
            }
            section {
              id
              section_desc
            }
          }
        }
      }
    }
  }
`;
export const GetDeletedAcctVouchers = gql`
  query GetDeletedAcctVouchers(
    $first: Int
    $after: Cursor
    $sortBy: AcctVoucherMasterDeletedOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $name: String
    $input: DeletedVoucherQueryData!
  ) {
    GetDeletedAcctVouchers(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      inst_id: $inst_id
      after: $after
      input: $input
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ vNoContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          v_type
          v_date
          v_no
          created_at
          v_std_passout_receipt
          v_total_db_amt
          v_std_amt_total
          v_total_cr_amt
          v_book_type
          v_std_enquiry
          v_std_refund
          v_std_refund_deposit
          mkck_voucher_id
          enquiry_student_id
          v_std_deposit_adjusted
          v_std_deposit
          v_std_demand_receipt
          v_std_scholarship_deposit
          v_std_anonymous_deposit_adjusted
          v_std_receipt
          party_bill_no
          v_transcation_type
          passout_student_id
          student_id
          v_transcation_cash_or_bank
          v_transcation_no
          party_name
          v_transcation_narration
          update_count
          acct_voucher_details {
            id
            vo_date
            vo_cr_db
            vo_sl_no
            vo_cr
            vo_db
            vo_cr_total
            vo_db_total
            acct_ldgr_id
            acct_ldgr {
              ldgr_desc
              id
              ldgr_cash_bank_other
            }
          }
        }
      }
    }
  }
`;
export const GetDeletedAcctVouchersForStudents = gql`
  query GetDeletedAcctVouchers(
    $first: Int
    $after: Cursor
    $sortBy: AcctVoucherMasterDeletedOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $inst_id: ID!
    $name: String
    $input: DeletedVoucherQueryData!
  ) {
    GetDeletedAcctVouchers(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      inst_id: $inst_id
      after: $after
      input: $input
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ vNoContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          v_type
          v_date
          v_no
          created_at
          v_std_passout_receipt
          v_total_db_amt
          v_std_amt_total
          v_total_cr_amt
          v_book_type
          v_std_enquiry
          v_std_refund
          v_std_refund_deposit
          mkck_voucher_id
          enquiry_student_id
          v_std_deposit_adjusted
          v_std_deposit
          v_std_demand_receipt
          v_std_scholarship_deposit
          v_std_anonymous_deposit_adjusted
          v_std_receipt
          party_bill_no
          v_transcation_type
          passout_student_id
          student_id
          v_transcation_cash_or_bank
          v_transcation_no
          party_name
          v_transcation_narration
          update_count
          acct_voucher_details {
            id
            vo_date
            vo_cr_db
            vo_sl_no
            vo_cr
            vo_db
            vo_cr_total
            vo_db_total
            acct_ldgr_id
            acct_ldgr {
              ldgr_desc
              id
              ldgr_cash_bank_other
            }
          }
          mst_student {
            first_name
            middle_name
            last_name
            std_reg_no
            std_adm_no
            branch {
              branch_desc
            }
            class {
              class_desc
            }
          }
        }
      }
    }
  }
`;

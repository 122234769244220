import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import More from "../../../images/More.svg";
import FeeDemand from "../../../images/FeeBalance.svg";
import Received from "../../../images/ReceivedInDashboard.svg";
import Receivable from "../../../images/Receivable.svg";
import StudentFeeDemand from "../../../images/FinancialYear.svg";
import OutstandingBalance from "../../../images/Financial.svg";
import Refund from "../../../images/LogRefundFee.svg";
import Concession from "../../../images/LogConcessionFee.svg";
import {
  AcctDashBoardDetails,
  GetAcctDashBoardDetailsData,
  GetAcctDashBoardDetailsVars,
  GetOwnersAcctDashBoardDetailsData,
  GetOwnersAcctDashBoardDetailsVars,
} from "./types";
import {
  GetAcctDashBoardDetails,
  GetOwnersAcctDashBoardDetails,
} from "../queries/DashBoard";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../context/context";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import {
  formatter,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import { AcctDashboard } from "../common/Enum.types";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
interface props {
  query_type: AcctDashboard;
}
const DemandDetails = ({ query_type }: props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const pollIntervalTime = usePollIntervalTime();
  const { format } = formatter;

  const { state } = useContext(AppContext);
  const [dashBoardDetails, setDahbordDetails] = useState<AcctDashBoardDetails>({
    total_fee_collection: 0,
    total_receipts_count: 0,
    fee_collection_bank: 0,
    fee_collection_cash: 0,
    cash_receipts_count: 0,
    bank_receipts_count: 0,
    total_payments: 0,
    total_payments_count: 0,
    payments_bank: 0,
    payments_cash: 0,
    cash_payments_count: 0,
    bank_payments_count: 0,
    total_balance: 0,
    bank_balance: 0,
    cash_balance: 0,
    total_demand: 0,
    total_received: 0,
    total_demand_balance: 0,
    upi_receipts_count: 0,
    cheque_receipts_count: 0,
    dd_receipts_count: 0,
    neft_receipts_count: 0,
    imps_receipts_count: 0,
    cardswipe_receipts_count: 0,
    total_std_demand: 0,
    total_std_ob: 0,
    total_std_concession: 0,
    total_std_refund: 0,
  });
  const [GetEmpDashBoardDetails, { data: EmpDashBoardData, loading }] =
    useLazyQuery<GetAcctDashBoardDetailsData, GetAcctDashBoardDetailsVars>(
      GetAcctDashBoardDetails,
      {
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId!,
          token,
          today_date: toIsoDate(serverDate),
          query_type,
        },
        pollInterval: pollIntervalTime,
      }
    );

  useEffect(() => {
    if (token && InstId && state.ActiveFinYr && query_type && serverDate) {
      GetEmpDashBoardDetails();

      if (EmpDashBoardData) {
        setDahbordDetails(EmpDashBoardData.GetAcctDashBoardDetails);
      }
    }
  }, [
    token,
    InstId,
    state.ActiveFinYr,
    GetEmpDashBoardDetails,
    query_type,
    state.claims,
    EmpDashBoardData,
    serverDate,
  ]);

  const [
    GetOwnerDashBoardDetails,
    { loading: ownerDashboardLoading, data: OwnerDashBoardData },
  ] = useLazyQuery<
    GetOwnersAcctDashBoardDetailsData,
    GetOwnersAcctDashBoardDetailsVars
  >(GetOwnersAcctDashBoardDetails, {
    variables: {
      token,
      today_date: toIsoDate(serverDate),
      cust_id: state.custId,
      query_type,
    },
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (
      token &&
      state.custId &&
      state.claims &&
      state.claims.OWNER &&
      !InstId &&
      serverDate &&
      query_type
    ) {
      GetOwnerDashBoardDetails();
      if (OwnerDashBoardData) {
        const { cust_total_stats } =
          OwnerDashBoardData.GetOwnersAcctDashBoardDetails;
        setDahbordDetails(cust_total_stats);
      }
    }
  }, [
    token,
    state.custId,
    state.claims,
    GetOwnerDashBoardDetails,
    OwnerDashBoardData,
    InstId,
    serverDate,
    query_type,
  ]);
  const demandData = {
    Collection: [
      {
        label: "Total",
        value: format(dashBoardDetails.total_demand),
        image: `${FeeDemand}`,
        className: "font-red",
      },

      {
        label: "Received",
        value: format(dashBoardDetails.total_received),
        image: `${Received}`,
        className: "font-purple",
      },
      {
        label: "Balance",
        value: format(dashBoardDetails.total_demand_balance),
        image: `${Receivable}`,
        className: "font-green",
      },
    ],
    ThisYear: [
      {
        label: "Student Fee Demand",
        value: format(dashBoardDetails.total_std_demand),
        image: `${StudentFeeDemand}`,
        className: "font-green",
      },

      {
        label: "Outstanding Balance",
        value: format(dashBoardDetails.total_std_ob),
        image: `${OutstandingBalance}`,
        className: "var(text-color)",
      },
      {
        label: "Refund",
        value: format(dashBoardDetails.total_std_refund),
        className: `font-purple`,
        image: `${Refund}`,
      },
      {
        label: "Concession",
        value: format(dashBoardDetails.total_std_concession),
        image: `${Concession}`,
        className: "font-green",
      },
    ],
  };
  return (
    <div className="accounts-dashboard__demand-details">
      <div className="accounts-dashboard__demand-details--data">
        <div className="accounts-dashboard__details--block--title">
          <Title variant="subtitle1">{`Demand Details (As on ${toStandardDate(
            serverDate
          )}`}</Title>

          <img src={More} alt="/" />
        </div>
        <ul className="accounts-dashboard__demand-details--ul">
          {demandData.Collection.map((res) => {
            return (
              <li className="accounts-dashboard__demand-details--flex">
                <div className="accounts-dashboard__demand-details--image">
                  <img src={res.image} alt="" />
                  <span>{res.label}</span>
                </div>
                <b className={res.className}>{res.value}</b>
              </li>
            );
          })}
          <li className="accounts-dashboard__demand-details--subtitle">
            This Year
          </li>
          {demandData.ThisYear.map((res) => {
            return (
              <li className="accounts-dashboard__demand-details--flex">
                <div className="accounts-dashboard__demand-details--image">
                  <img src={res.image} alt="" />
                  <span>{res.label}</span>
                </div>
                <b className={res.className}>{res.value}</b>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DemandDetails;

import React from "react";
import { Title } from "../../../stories/Title/Title";
import FinYrComponent from "./FinYrComponent";
import WarningsComponent from "./WarningsComponent";
import Home from "../../Master/configurations/Home/Index";
const Index = () => {
  return (
    <>
      <Home DashBoardRequired={false} />

      <Title>Financial Year Posting</Title>
      <div className="row g-0 justify-content-center finyr-post">
        <div className="col-4 finyr-post__data">
          <FinYrComponent />
          <WarningsComponent />
        </div>
      </div>
    </>
  );
};

export default Index;

import React, { useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { months, toIsoDate } from "../../../../../utils/UtilFunctions";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import usePayRollActiveAcademicYear from "../../../../HR/hooks/usePayRollActiveAcademicYear";
import { GetAttDashBoardTestVars } from "../DailyDairy";
import { useLazyQuery } from "@apollo/client";
import { TODAY_DATE } from "../../../../../utils/constants";
import { GetDiaryDashBoardGraphData } from "../../../../../queries/common";

const DailyDiaryGraph = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const datesArray = Array.from({ length: daysInMonth }, (_, i) => {
    const date = i + 1;
    return `${date}/${months[currentMonth].slice(0, 3)}`;
  });

  const { token } = useToken();
  const { InstId } = useParams();
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const activeAcdId = payRollActiveAcademicYear.data
    ? payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id
    : 0;

  const [GetDashboardGraphData, { data: DashBoardGraphData }] = useLazyQuery(
    GetDiaryDashBoardGraphData
  );

  useEffect(() => {
    if (
      token &&
      payRollActiveAcademicYear.data &&
      !payRollActiveAcademicYear.loading
    ) {
      GetDashboardGraphData({
        variables: {
          token,
          inst_id: InstId,
          pr_acd_yr_id: activeAcdId,
          date_of_attendance: toIsoDate(TODAY_DATE),
        },
      });
    }
  }, [
    token,
    GetDashboardGraphData,
    payRollActiveAcademicYear.data,
    payRollActiveAcademicYear.loading,
  ]);
  const GraphData =
    DashBoardGraphData && DashBoardGraphData.GetDiaryDashBoardGraphData;
  const seriesData = datesArray.map((date, index) => {
    const key = `percent_day_${index + 1}`;
    return GraphData ? GraphData[key] : null;
  });
  const option = {
    xAxis: {
      type: "category",
      data: datesArray,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      interval: 10,
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    series: [
      {
        data: seriesData,
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: "#38bdf8",
          width: 1,
        },
        areaStyle: {
          color: "#e0f2fe",
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
      },
    ],
  };

  return (
    <>
      <ReactEcharts option={option} />
    </>
  );
};

export default DailyDiaryGraph;

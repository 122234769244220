import { TEXT_FONT_FAMILY } from "./DataGridTableStyles";
import { Autocomplete, styled } from "@mui/material";


//mui autocomplete in forms
const PREFIX = "FormMyAutocomplete";
export const formClasses = {
  root: `${PREFIX}-root`,
  inputRoot: `${PREFIX}-inputRoot`,
  option: `${PREFIX}-option`,
  formControlRoot: `${PREFIX}-formControlRoot`,
  inputLabelRoot: `${PREFIX}-formControlRoot`,
};

// Use the `styled` API to create the root component with custom styles
export const FormAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${formClasses.inputRoot}`]: {
    color: "var(--text-color)",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: 14,
    padding: "0px !important",

    "&.Mui-focused .MuiAutocomplete-root": {
      border: "1px solid var(--level-5)",
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      background: "var(--disabled-color) !important",
      cursor: "not-allowed",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--border-color) !important",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--level-3) !important",
    },

    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      margin: -1,
      fontSize: 12,
    },
    "& .MuiSvgIcon-root ": {
      height: 18,
      width: 18,
    },

    "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
      fontSize: "12px !important",
      fontFamily: TEXT_FONT_FAMILY,
    },
 
    "& .MuiAutocomplete-root ": {
      padding: "0px !important",

    },

    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
  },
  [`& .${formClasses.formControlRoot}`]: {
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color)",
    borderRadius: "6px",
    position: "relative",
    marginTop: "10px",

    "&.Mui-focused .MuiInputBase-formControl": {
      border: "1px solid var(--level-5)",
    },
    " &.hover": {
      border: "1px solid var(--level-5)",
    },

    "& label.Mui-focused": {
      color: "var(--text-color)",
    },
    "& .MuiInputBase-input": {
      height: "15px !important",
      fontSize: 14,
      fontFamily: TEXT_FONT_FAMILY,
    },
    "& .MuiAutocomplete-root ": {
      padding: "0px !important",
    },

    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
  
  },
}));

//requiredField in Form

export const RequiredAutoComplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${formClasses.inputRoot}`]: {
    color: "var(--text-color)",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: 14,
    boxShadow: " 3px 0px 0px red",
    width: "calc(100% - 3px) !important",
    padding: "0px !important",
    marginTop: "10px",
    borderRadius: "6px",


    "&.Mui-focused .MuiAutocomplete-root": {
      border: "1px solid var(--level-5)",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--border-color) !important",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--level-3) !important",
    },

    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      fontSize: 12,
    },
    "& .MuiSvgIcon-root ": {
      height: 18,
      width: 18,
    },

    "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
      fontSize: 12,
      fontFamily: TEXT_FONT_FAMILY,
    },
  },
  [`& .${formClasses.formControlRoot}`]: {
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color)",
    position: "relative",

    "&.Mui-focused .MuiInputBase-formControl": {
      border: "1px solid var(--level-5)",
    },
    " &.hover": {
      border: "1px solid var(--level-5)",
    },

    "& label.Mui-focused": {
      color: "var(--text-color)",
    },
    "& .MuiInputBase-input": {
      height: "15px !important",
      fontSize: 14,
      fontFamily: TEXT_FONT_FAMILY,
    },

    "& .MuiAutocomplete-root ": {
      padding: "0px !important",
    },

    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
  },
}));

//for InstDetails in Header Component
export const InstAutoCompleteStyles = styled(Autocomplete)(
  ({theme}) => ({
    [`&.${formClasses.inputRoot}`]: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 16,
      padding: "3px !important",

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "0px solid var(--level-5)",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid var(--border-color) !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "0px solid var(--level-3) !important",
      },
      "& .MuiOutlinedInput-root": {
        padding: 6,
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        margin: -1,
        fontSize: 14,
      },
      "& .MuiSvgIcon-root ": {
        height: 18,
        width: 18,
      },

      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: "12px !important",
        fontFamily: TEXT_FONT_FAMILY,
      },
      "& .MuiAutocomplete-root ": {
        padding: "7px !important",
      },
    },
    [`& .${formClasses.formControlRoot}`]:{
      fontFamily: TEXT_FONT_FAMILY,
      color: "var(--text-color)",
      borderRadius: "6px",
      position: "relative",

      "&.Mui-focused .MuiInputBase-formControl": {
        border: "1px solid var(--level-5)",
      },
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)",
      },

      "& label.Mui-focused": {
        color: "var(--text-color)",
      },
      "& .MuiInputBase-input": {
        fontSize: 16,
        fontFamily: TEXT_FONT_FAMILY,
        padding: " 2px !important",
        fontWeight: "700 !important",
      },
    },
    
  })
);



//Selected

export const SelectAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${formClasses.inputRoot}`]: {
    color: "var(--level-6)",
    fontFamily: TEXT_FONT_FAMILY,
    border: "1px solid var(--border-color) !important",
    fontSize: 14,
    padding: "7.5px 2px 7.5px 3px",
    background: "var(--disabled-color)",

    "&:Mui-hover .MuiAutocomplete-root": {
      border: "1px solid var(--level-5) !important",
    },

    "&.Mui-focused .MuiAutocomplete-root": {
      border: "1px solid var(--level-5)",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--level-3) !important",
    },
    "& .MuiOutlinedInput-root": {
      padding: 6,
    },
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      margin: -1,
      fontSize: 12,
    },
    "& .MuiSvgIcon-root ": {
      height: 18,
      width: 18,
    },
    "& .MuiAutocomplete-endAdornment": {
      background: "var(--disabled-color) !important",
    },
    "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
      fontSize: 12,
      fontFamily: TEXT_FONT_FAMILY,
    },
  },
  [`& .${formClasses.formControlRoot}`]: {
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color)",
    borderRadius: "6px",
    position: "relative",

    "&.Mui-focused .MuiInputBase-formControl": {
      border: "1px solid var(--level-5)",
    },
    " &.hover": {
      border: "1px solid var(--level-5)",
    },

    "& label.Mui-focused": {
      color: "var(--text-color)",
    },
    "& .MuiInputBase-input": {
      height: "30px !important",
      fontSize: 14,
      fontFamily: TEXT_FONT_FAMILY,
      padding: "0px 5px !important",
    },
  },
}));


import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { LabelNameProps } from "../../../../Types/Labels";
import { PageFor, ParentType } from "../../../../utils/Enum.types";
import { studentParentAssocType } from "../../../../utils/Form.types";
import { AppContext } from "../../../../context/context";
import PreviewTabs from "./PreviewTabs";
import StudentAcademicDetails from "./StudentAcademicDetails";
import { TextField } from "@mui/material";
import Home from "../../Home/Index";
import Information from "../../../../images/Information.svg";
import { ParentModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import Avatar from "../../../../images/Avatar.svg";
import Link from "../../../../images/Link.svg";
import { Title } from "../../../../stories/Title/Title";
import useStudentDatabyId, {
  parentData,
} from "../../../../customhooks/useStudentDatabyId";
import { EMPTY_STRING } from "../../../../utils/constants";
const { StudentPreviewDetails } = require("../../../../json/config.json");
const { Students } = require("../../../../json/title.json");
interface Props {
  pageType: PageFor;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const StudentParentAssoc = ({ pageType, setModal }: Props) => {
  const { InstId, studentId } = useParams();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [fatherDetails, setFatherDetails] = useState(false);
  const [motherDetails, setMotherDetails] = useState(false);
  const [fatherData, setFatherData] = useState<parentData>();
  const [motherData, setMotherData] = useState<parentData>();
  const [formData, setFormData] = useState<studentParentAssocType>({
    student_name: "",
    father_name: "",
    mother_name: "",
    guardian_name: "",
  });

  const { studentData } = useStudentDatabyId();

  useEffect(() => {
    if (!studentData.loading && studentData.data) {
      const { first_name, last_name, middle_name } = studentData.data.nodes[0];
      const parents = studentData.data.nodes[0].parents;
      const studentName = `${first_name} ${middle_name} ${last_name}`;
      let motherName = "";
      let fatherName = "";
      let guardianName = "";

      if (parents.length === 1) {
        const { parent, relationship } = parents[0];
        if (relationship === ParentType.MOTHER) {
          motherName = `${parent.first_name} ${parent.middle_name} ${parent.last_name}`;
        } else if (relationship === ParentType.FATHER) {
          fatherName = `${parent.first_name} ${parent.middle_name} ${parent.last_name}`;
        } else {
          guardianName = `${parent.first_name} ${parent.middle_name} ${parent.last_name}`;
        }
      } else {
        const { parent: mother } =
          parents.find(
            (parent) => parent?.relationship === ParentType.MOTHER
          ) || {};
        const { parent: father } =
          parents.find(
            (parent) => parent?.relationship === ParentType.FATHER
          ) || {};
        motherName = mother
          ? `${mother.first_name} ${mother.middle_name} ${mother.last_name}`
          : EMPTY_STRING;
        fatherName = father
          ? `${father.first_name} ${father.middle_name} ${father.last_name}`
          : EMPTY_STRING;
      }

      setFormData({
        ...formData,
        student_name: studentName,
        mother_name: motherName,
        father_name: fatherName,
        guardian_name: guardianName,
      });
    } // eslint-disable-next-line
  }, [studentData]);

  useEffect(() => {
    if (studentData.data && !studentData.loading) {
      setFatherData(
        studentData.data?.nodes[0].parents.find(
          ({ relationship }) => relationship === ParentType.FATHER
        )?.parent
      );
    }
    setMotherData(
      studentData.data?.nodes[0].parents.find(
        ({ relationship }) => relationship === ParentType.MOTHER
      )?.parent
    );
  }, [studentData.data, studentData.loading]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      {pageType === PageFor.GENERAL ? (
        <PreviewTabs pageType={PageFor.GENERAL} />
      ) : null}
      <div
        className={pageType === PageFor.GENERAL ? "preview" : "preview--modal"}
      >
        <StudentAcademicDetails />
        <div
          className={
            pageType === PageFor.GENERAL
              ? "preview__frame"
              : "preview__frame--modal"
          }
        >
          <div className="preview__frame--data row">
            <div className="col"></div>
            <div className="col-4">
              <div className="preview__imagegrid">
                <TextField
                  className="preview__textfield"
                  label="Student Name"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  name={formData.student_name}
                  value={formData.student_name}
                  disabled
                />

                <img src={Link} alt="/" />
              </div>
            </div>
            <div className="col-4">
              {!formData.guardian_name
                ? StudentPreviewDetails.StudentParentAssociation.FatherName.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="preview__frame--parent">
                            <TextField
                              className="preview__textfield"
                              label={label.LabelName}
                                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                              value={formData[label.inputName]}
                              name={label.inputName}
                              disabled
                            />
                            <img
                              className="data-fetch-icon"
                              src={Information}
                              alt="/"
                              onClick={() => {
                                formData.father_name
                                  ? setFatherDetails(!fatherDetails)
                                  : setFatherDetails(false);
                              }}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                  )
                : null}
              {!formData.guardian_name
                ? StudentPreviewDetails.StudentParentAssociation.MotherName.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="preview__frame--parent">
                            <TextField
                              className="preview__textfield"
                              label={label.LabelName}
                                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                              value={formData[label.inputName]}
                              name={label.inputName}
                              disabled
                            />
                            <img
                              className="data-fetch-icon"
                              src={Information}
                              alt="/"
                              onClick={() => {
                                formData.mother_name
                                  ? setMotherDetails(!motherDetails)
                                  : setMotherDetails(false);
                              }}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                  )
                : null}
            </div>
            <div className="col"></div>
          </div>
          {!state.claims!.STUDENT && (
            <>
              {pageType === PageFor.GENERAL ? (
                <Button
                  mode="edit"
                  autoFocus
                  onClick={() =>
                    navigate(
                      `/${InstId}/admissions/students/${studentId}/studentParentAssociationUpdate`
                    )
                  }
                />
              ) : null}

              {pageType === PageFor.GENERAL ? (
                <Button
                  mode="back"
                  onClick={() => navigate(`/${InstId}/admissions/students`)}
                />
              ) : null}
            </>
          )}
          {pageType === PageFor.MODAL && (
            <Button mode="cancel" onClick={() => setModal?.(false)} />
          )}
        </div>
      </div>

      {/* father details */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={fatherDetails}
        style={ParentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>{Students.ParentInformation.Father}</Title>
            <div className="row g-0">
              <div className="col preview__frame--parent-info">
                {fatherData &&
                  StudentPreviewDetails.ParentDetails.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TextField
                            className="preview__textfield"
                            label={label.LabelName}
                                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                            value={fatherData![label.inputName] ?? [""]}
                            name={label.inputName}
                            disabled
                          />
                        </React.Fragment>
                      );
                    }
                  )}
              </div>
              <div className="col-1 preview__frame--parent-info--image">
                <img src={Avatar} alt="/" />
              </div>
            </div>
            <Button
              mode="cancel"
              onClick={() => setFatherDetails(!fatherDetails)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFatherDetails(!fatherDetails)}
            />
          </div>
        </div>
      </Modal>
      {/* Mother details */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={motherDetails}
        style={ParentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>{Students.ParentInformation.Mother}</Title>
            <div className="row g-0">
              <div className="col preview__frame--parent-info">
                {motherData &&
                  StudentPreviewDetails.ParentDetails.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TextField
                            className="preview__textfield"
                            label={label.LabelName}
                                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                            value={motherData![label.inputName] ?? [""]}
                            name={label.inputName}
                            disabled
                          />
                        </React.Fragment>
                      );
                    }
                  )}
              </div>
              <div className="col-1 preview__frame--parent-info--image">
                <img src={Avatar} alt="/" />
              </div>
            </div>
            <Button
              mode="cancel"
              onClick={() => setMotherDetails(!motherDetails)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setMotherDetails(!motherDetails)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentParentAssoc;

import { useContext, useEffect } from "react";
import { TEXT_FONT_FAMILY } from "../../../styles/DataGridTableStyles";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctDashBoardFinYrGraphData,
  GetAcctRegisterMonthlyDataByInstIdVars,
} from "../../../Types/Accounting";
import { GetAcctDashBoardFinYrGraph } from "../queries/Accountingledgers/List/ById";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import { DashBoardFor, ReceiptTypes } from "../../../utils/Enum.types";
import { convertToK, getDynamicIntervals } from "../../../utils/UtilFunctions";
import ReactEcharts from "echarts-for-react";
interface props {
  selectedYear?: number;
  pageType: DashBoardFor;
}

const GraphDetails = ({ selectedYear, pageType }: props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const pollIntervalTime = usePollIntervalTime();
  const { state } = useContext(AppContext);

  const [GetMonthlyRegistersPaymentData, { data: paymentgraphData }] =
    useLazyQuery<
      GetAcctDashBoardFinYrGraphData,
      GetAcctRegisterMonthlyDataByInstIdVars
    >(GetAcctDashBoardFinYrGraph, {
      variables: {
        fin_yr_id: selectedYear
          ? selectedYear
          : state.ActiveFinYr
          ? state.ActiveFinYr.id
          : 0,
        inst_id: Number(InstId!),
        register_type: ReceiptTypes.PYMT,
        token,
      },
      pollInterval: pollIntervalTime,
    });

  const [GetMonthlyRegistersDataForGraph, { data: graphData }] = useLazyQuery<
    GetAcctDashBoardFinYrGraphData,
    GetAcctRegisterMonthlyDataByInstIdVars
  >(GetAcctDashBoardFinYrGraph, {
    variables: {
      fin_yr_id: selectedYear
        ? selectedYear
        : state.ActiveFinYr
        ? state.ActiveFinYr.id
        : 0,
      inst_id: Number(InstId!),
      register_type: ReceiptTypes.STUDENT_RECEIPT,
      token,
    },
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (token && InstId && state.ActiveFinYr) {
      GetMonthlyRegistersPaymentData();
      GetMonthlyRegistersDataForGraph();
    }
  }, [
    token,
    InstId,
    state.ActiveFinYr,
    GetMonthlyRegistersPaymentData,
    GetMonthlyRegistersDataForGraph,
  ]);
  const registerMonthlyData = graphData?.GetAcctDashBoardFinYrGraph || [];
  const registerMonths = registerMonthlyData.flatMap((f) =>
    f.monthly_register_data.map((res) => res.register_month_db)
  );
  const maxRegisterReceiptsMonth = Math.max(...registerMonths);

  const paymentregisterMonthlyData =
    paymentgraphData?.GetAcctDashBoardFinYrGraph || [];
  const paymentregisterMonths = paymentregisterMonthlyData.flatMap((f) =>
    f.monthly_register_data.map((res) => res.register_month_db)
  );
  const paymentmaxRegisterReceiptsMonth = Math.max(...paymentregisterMonths);

  const MaximumAmount = Math.max(
    maxRegisterReceiptsMonth,
    paymentmaxRegisterReceiptsMonth
  );

  const maxNumber = Math.ceil(MaximumAmount / 100) * 100;
  const intervals = getDynamicIntervals(maxNumber);
  const maxAmountFromIntervals = Math.max(...intervals);
  const previousYearGraph =
    graphData &&
    graphData.GetAcctDashBoardFinYrGraph.filter(
      (res) => res.fin_is_curr_yr === false
    ).flatMap((data) =>
      data.monthly_register_data.map((d) => d.register_month_db)
    );
  const currentYearGraph =
    graphData &&
    graphData.GetAcctDashBoardFinYrGraph.filter(
      (res) => res.fin_is_curr_yr === true
    ).flatMap((data) =>
      data.monthly_register_data.map((d) => d.register_month_db)
    );

  const previousYearPayment =
    paymentgraphData &&
    paymentgraphData.GetAcctDashBoardFinYrGraph.filter(
      (res) => res.fin_is_curr_yr === false
    ).flatMap((data) =>
      data.monthly_register_data.map((d) => d.register_month_db)
    );
  const currentYearGraphPayment =
    paymentgraphData &&
    paymentgraphData.GetAcctDashBoardFinYrGraph.filter(
      (res) => res.fin_is_curr_yr === true
    ).flatMap((data) =>
      data.monthly_register_data.map((d) => d.register_month_db)
    );

  const option2 = {
    legend: {
      textStyle: {
        color: "black",
        fontSize: 12,
        fontFamily: TEXT_FONT_FAMILY,
      },
      icon: "rect",
      bottom: 10,
      width: "90%",
      type: "scroll",
      data: [
        "Previous Year Receipt",

        "Current Year Receipt",
        "Previous Year Payment",

        "Current Year Payment",
      ],
    },
    tooltip: {},

    xAxis: [
      {
        textStyle: {
          color: "black",
          fontSize: 14,
          fontFamily: TEXT_FONT_FAMILY,
        },
        type: "category",
        data:
          graphData &&
          graphData.GetAcctDashBoardFinYrGraph.filter(
            (data) => data.fin_is_curr_yr === true
          ).flatMap((res) =>
            res.monthly_register_data.map((d) =>
              new Date(d.register_month_date).toLocaleString("en-us", {
                month: "short",
              })
            )
          ),
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        saveAsImage: { show: true },
      },
    },
    yAxis: [
      {
        textStyle: {
          color: "black",
          fontSize: 10,
          fontFamily: TEXT_FONT_FAMILY,
        },
        type: "value",
        min: 0,
        max: maxAmountFromIntervals,
        interval: intervals[1] - intervals[0],
        axisLabel: {
          formatter: (value: number) => "₹" + convertToK(value),
        },
      },
      {
        textStyle: {
          color: "black",
          fontSize: 10,
          fontFamily: TEXT_FONT_FAMILY,
        },
        type: "value",
        min: 0,
        max: maxAmountFromIntervals,
        interval: intervals[1] - intervals[0],
        axisLabel: {
          formatter: (value: number) => "₹" + convertToK(value),
        },
      },
    ],
    series: [
      ...(pageType === DashBoardFor.PROPRIETOR
        ? []
        : [
            {
              name: "Previous Year Receipt",
              type: "bar",
              data: previousYearGraph,
              itemStyle: { color: "#38bdf8" },
            },
          ]),

      {
        name: "Current Year Receipt",
        type: "bar",
        data: currentYearGraph,
        itemStyle: { color: "#86efac" },
      },
      ...(pageType === DashBoardFor.PROPRIETOR
        ? []
        : [
            {
              name: "Previous Year Payment",
              type: "line",
              data: previousYearPayment,
              itemStyle: { color: "#f472b6" },
            },
          ]),

      {
        name: "Current Year Payment",
        type: "line",
        data: currentYearGraphPayment,
        itemStyle: { color: "#fdba74" },
      },
    ],
  };
  return (
    <>
      <ReactEcharts
        option={option2}
        className="accounts-dashboard__datablock--chart"
      />
    </>
  );
};

export default GraphDetails;

import React, { useEffect } from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import useAcctTableJson from "../json/useAcctTableJson";
import { tableJson } from "./Index";
import Compare from "../../../images/Compare.svg";
import { AcctOnlinePaymentsNode } from "./TransactionTable";
import { formatter } from "../../../utils/UtilFunctions";
import axios from "axios";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  transactionData: AcctOnlinePaymentsNode | null;
  handleViewVoucher: (v_id: number) => void;
}
const LogData = ({ setModal, transactionData, handleViewVoucher }: Props) => {
  const { Accounts_Table } = useAcctTableJson();
  const { format } = formatter;
  const url = `https://eazypay.icicibank.com/EazyPGVerify?
ezpaytranid=&amount=${transactionData?.total_amount}&paymentmode=NET_BANKING_ICICI&merchantid=${transactionData?.merchant_eazypay_id}&trandate=&pgreferenceno=${transactionData?.unique_ref_number}`;
  useEffect(() => {
    if (transactionData) {
      const url = `https://eazypay.icicibank.com/EazyPGVerify?
ezpaytranid=&amount=${transactionData.total_amount}&paymentmode=NET_BANKING_ICICI&merchantid=${transactionData.merchant_eazypay_id}&trandate=&pgreferenceno=${transactionData.unique_ref_number}`;
      axios.post(url).then(({ data }) => console.log(data));
    }
  }, [transactionData]);
  return (
    <>
      <Title>Bank Log Data</Title>
      <div className="online-transaction__logdata">
        <Title variant="subtitle1">System Data</Title>
        <div className="online-transaction__logdata--tableblock">
          <TableContainer className="online-transaction__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.OnlineTransactionDetailsForLogs.Table_Headers.filter(
                    (th: TableHeaderProps) => {
                      return th.labelName !== "Action";
                    }
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData ? (
                  <TableRow>
                    <TableCell className="online-transaction__table--date">
                      {`${transactionData.transaction_date.split(" ")[0]} ${
                        transactionData.transaction_date.split(" ")[1]
                      }`}
                    </TableCell>
                    <TableCell className="online-transaction__table--number">
                      {transactionData.unique_ref_number}
                    </TableCell>
                    <TableCell className="online-transaction__table--number">
                      {transactionData.reference_no}
                    </TableCell>
                    <TableCell className="online-transaction__table--number">
                      {transactionData.merchant_eazypay_id}
                    </TableCell>
                    <TableCell className="online-transaction__table--number">
                      {transactionData.payment_mode}
                    </TableCell>
                    <TableCell>
                      {format(Number(transactionData.total_amount))}
                    </TableCell>
                    <TableCell
                      className={
                        transactionData.response_code === "E000"
                          ? "font-green"
                          : "font-red"
                      }
                      id="td-right"
                    >
                      {transactionData.response_code}
                    </TableCell>
                    <TableCell className="online-transaction__table--message">
                      {transactionData.response_code_str}
                    </TableCell>

                    <TableCell
                      className="online-transaction__table--vno"
                      id="td-center"
                      onClick={() => {
                        if (transactionData.response_code === "E000") {
                          handleViewVoucher(transactionData.voucher_master_id);
                        }
                      }}
                    >
                      {transactionData.voucher_no}
                    </TableCell>
                    <TableCell
                      className={
                        transactionData.response_code === "E000"
                          ? "online-transaction__table--success"
                          : "online-transaction__table--failure"
                      }
                      id="td-center"
                    >
                      {transactionData.response_code === "E000"
                        ? "Success"
                        : "Failed"}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="online-transaction__logdata--image">
          <img src={Compare} alt="" />
        </div>
        <Title variant="subtitle1">Bank Data</Title>
        <div className="online-transaction__logdata--tableblock">
          <TableContainer className="online-transaction__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.OnlineTransactionDetailsForLogs.Table_Headers.filter(
                    (th: TableHeaderProps) => {
                      return th.labelName !== "Action";
                    }
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableJson.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="online-transaction__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="online-transaction__table--date">
                        {data.t_date}
                      </TableCell>
                      <TableCell className="online-transaction__table--number">
                        {data.uref_no}
                      </TableCell>
                      <TableCell className="online-transaction__table--number">
                        {data.ref_no}
                      </TableCell>
                      <TableCell className="online-transaction__table--number">
                        {data.mid}
                      </TableCell>
                      <TableCell className="online-transaction__table--number">
                        {data.s_mid}
                      </TableCell>
                      <TableCell>{data.p_mode}</TableCell>
                      <TableCell
                        className={
                          data.status === "Success" ? "font-green" : "font-red"
                        }
                        id="td-right"
                      >
                        {data.amount}
                      </TableCell>
                      <TableCell>{data.code}</TableCell>
                      <TableCell className="online-transaction__table--message">
                        {data.message}
                      </TableCell>
                      <TableCell
                        className="online-transaction__table--vno"
                        id="td-center"
                      >
                        {data.v_no}
                      </TableCell>
                      <TableCell
                        className={
                          data.status === "Success"
                            ? "online-transaction__table--success"
                            : "online-transaction__table--failure"
                        }
                        id="td-center"
                      >
                        {data.status}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Button mode="update" onClick={() => window.open(url)} />
      <Button mode="cancel" onClick={() => setModal(false)} />
    </>
  );
};

export default LogData;

//React
import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

//ApolloClient
import { useLazyQuery, useMutation } from "@apollo/client";

//Material UI
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

//HOCS
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { AntSwitch } from "../../../../pages/Switch";

//Styles
import { ChooseLedgerModalStyles } from "../../../../styles/ModalStyles";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";

//Images
import LedgersList from "../../../../images/LedgersList.svg";
import Close from "../../../../images/Close.svg";

//Components
import Home from "../../Home/Index";
import OverAllStructure from "./OverAllStructure";
import CalculationModal from "./CalculationModal";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import DeleteModal from "../../../../pages/DeleteModal";

//CustomHooks
import useToken from "../../../../customhooks/useToken";
import useEmpSalaryLedger from "../../hooks/useEmpSalaryLedger";
import useEmpMasterData from "../../hooks/useEmpMasterData";
import usePayRollSalaryStructure from "../../hooks/usePayRollSalaryStucture";
import { PayRollActiveAcademicYearByInstIdData } from "../../hooks/usePayRollActiveAcademicYear";
import { PayRollAcademicYearListVars } from "../../hooks/usePayRollAcademicYears";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";

// Enums
import {
  Direction,
  Operation,
  ReturnType,
  SalaryStructreEarnType,
  SortBy,
} from "../../../../utils/Enum.types";
import { Keys } from "../../../../utils/Enum.keys";
import {
  applyHeadsOn,
  calculationType,
  cellEditType,
  cellName,
  OverAllSalaryLedgerPageType,
  PayRollConfigKeys,
  SalaryCalculationConfig,
  SalaryLedgerType,
} from "../../enums/Enum.types";

// Types
import { msgType, responseType } from "../../../../utils/Form.types";
import { HRTitleProps } from "../../../../Types/Titles";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  applyHeadsOnOptions,
  calculationMethodModal,
  chooseLedgersModal,
  EditStatus,
  IncomeCalculatorType,
} from "../../Types/dataTypes";

//Queries
import {
  GetPayRollSalaryStructMastersAndDetails,
  GetPayRollSalaryStructMastersByInstId,
} from "../../queries/salary/list";
import {
  AddPayRollSalaryMasterAndDetails,
  DeletePayRollSalaryStructMasterById,
  UpdatePayRollSalaryStructMaster,
} from "../../queries/salary/mutation";
import { GetPayRollAcdYrActiveByInstId } from "../../queries/academicyear/query";

//Constants
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";

//Other Imports
import {
  GetPayRollSalaryStructDetailsByMasterIdData,
  GetPayRollSalaryStructDetailsByMasterIdVars,
  LedgersListType,
  PayRollSalaryStructDetails,
} from "./AssignSalaryStructure";
import {
  applyOnHeadsLookUp,
  applyOnHeadsOptions,
  checkForFlags,
  checkForRoundOff,
  chooseAmtBasedOnType,
  findApplyHeadsBasedOnGroupIds,
  findEarningsForExpressionString,
  getAppliedHeads,
  getExpressionStringtoArray,
  getGroupIds,
  getIndex,
  grossChange,
  handleEarning,
  incomeArrayToRule,
} from "../../utils/Functions";
import { ChooseLedgersTableHead } from "./TableHeaders";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  isOptionEqualToValue,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

//Json Imports
const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");

const Index = () => {
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { token } = useToken();
  //useRefs
  const salaryStructureName = "salaryStructureName";
  const salaryStructureRef = document.getElementsByName(
    salaryStructureName
  )[0] as HTMLInputElement;
  const salaryNameRef = useRef<HTMLInputElement>(null);
  const salaryNameInputRef = salaryNameRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;

  const [chooseLedger, setChooseLedger] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [salaryTemplateName, setSalaryTemplateName] = useState("");
  // eslint-disable-next-line
  const [searchData, setsearchData] = useState("");
  const [searchSalaryStructure, setSearchSalaryStructure] = useState("");
  const [salaryStructureId, setSalaryStructureId] =
    useState<responseType | null>(null);

  // const { format } = formatterDecimal;

  const [salStrAssignedToAll, setSalStrAssignedToAll] = useState(false);
  //Modals
  const [chooseEarningLedger, setChooseEarningLedger] =
    useState<chooseLedgersModal>();
  const [ledgerList, setLedgerList] = useState(false);
  const [calculationMethod, setCalculationMethod] =
    useState<calculationMethodModal>();
  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });

  const [flatRatePercentage, setFlatRatePercentage] = useState(0);
  const [userDefinedfixedValue, setUserDefinedFixedValue] = useState(0);

  const [earnedValueRestrictedTo, setEarnedValueRestrictedto] = useState(0);
  const [earnedBasicValue, setEarnedBasicValue] = useState(0);

  const [basicSalary, setBasicSalary] = useState(0);
  const [grossSalary, setGrossSalary] = useState(0);
  const [callToMaxValue, setCallToMaxValue] = useState(0);
  const [category, setCategory] = useState<responseType | null>(null);
  const [designation, setDesignation] = useState<responseType | null>(null);
  const [department, setDepartment] = useState<responseType | null>(null);
  const [operation, setOperation] = useState<Operation>(Operation.CREATE);

  const [incomeCalculatorItems, setIncomeCalculatorItems] = useState<
    IncomeCalculatorType[]
  >([
    {
      calculation_type: calculationType.Percentage,
      from_amt: 0,
      id: 1,
      income_value_basis: 0,
      upto_amt: 0,
    },
  ]);

  //Refs
  const basicSalaryRef = useRef<HTMLInputElement>(null);
  //Choose Earnings Table
  const [inEditMode, setInEditMode] = useState<EditStatus>({
    status: false,
    rowKey: 0,
    cellEditKey: cellEditType.NONE,
  });
  const { configData: salConfig } = useSwConfigData(
    PayRollConfigKeys.SALARY_CALCULATIONS_BASED_ON
  );
  const { configData: salRoundOffDecimal } = useSwConfigData(
    PayRollConfigKeys.ROUND_TO_DECIMAL
  );

  const salaryConfigKey = salConfig.data
    ? salConfig.data?.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;

  const salRoundOffKey = salRoundOffDecimal.data
    ? salRoundOffDecimal.data?.GetSwConfigVariables[0].config_boolean_value
    : false;

  const [GetSalaryStructureDetails] = useLazyQuery<
    GetPayRollSalaryStructDetailsByMasterIdData,
    GetPayRollSalaryStructDetailsByMasterIdVars
  >(GetPayRollSalaryStructMastersAndDetails);
  const { USE_HR_CATEGORY, USE_HR_DEPARTMENT, USE_HR_DESIGNATION } =
    usePayRollMastersConfig();
  //Mutations
  const [AddSalaryStructure, { loading: creationLoading }] = useMutation(
    AddPayRollSalaryMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateSalaryStructure, { loading: updationLoading }] = useMutation(
    UpdatePayRollSalaryStructMaster,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteSalaryStructure, { loading: deletionLoading }] = useMutation(
    DeletePayRollSalaryStructMasterById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  //CustomHooks
  const {
    salaryLedger: { data, loading },
  } = useEmpSalaryLedger();
  const {
    salaryStructMasters: { dropDown: salaryStructureDropdown },
  } = usePayRollSalaryStructure(
    ReturnType.RESPONSE_TYPE,
    searchSalaryStructure
  );

  const [overAllSalaryLedger, setOverAllSalaryLedger] = useState<
    LedgersListType[]
  >([]);
  const { departmentDropDown, designationDropDown, categoryDropDown } =
    useEmpMasterData();
  //Queries
  const [GetPayRoll, { data: payRollActiveAcdYear }] = useLazyQuery<
    PayRollActiveAcademicYearByInstIdData,
    PayRollAcademicYearListVars
  >(GetPayRollAcdYrActiveByInstId, {
    variables: { token, inst_id: InstId! },
  });

  const total = useMemo(() => {
    const total = overAllSalaryLedger
      .filter(
        ({ isChecked, earnings, deductions }) =>
          isChecked && (earnings > 0 || deductions > 0)
      )
      .reduce(
        (acc, initialValue) => {
          acc.totalEarnings += initialValue.earnings;
          acc.totalDeductions += initialValue.deductions;
          return acc;
        },
        {
          totalEarnings: 0,
          totalDeductions: 0,
        }
      );
    return total;
  }, [overAllSalaryLedger]);

  const handleOverAllLedgers = (
    event: React.MouseEvent<unknown>,
    id: number
  ) => {
    const { checked } = event.target as HTMLInputElement;
    const res = overAllSalaryLedger.map((subject) => {
      if (subject.id === id) {
        const newData = {
          ...subject,
          isChecked: checked,
        };
        return newData;
      }
      return subject;
    });
    setOverAllSalaryLedger(res as LedgersListType[]);
  };

  //Choose Deduction Table

  const onEdit = (id: number, value: number, Key: cellEditType) => {
    setInEditMode({
      cellEditKey: Key,
      rowKey: id,
      status: true,
    });

    switch (Key) {
      case cellEditType.FLR:
        setFlatRatePercentage(Number(value));
        break;
      case cellEditType.UDV_E:
        setUserDefinedFixedValue(value);
        break;
      case cellEditType.EARNED_VALUE_RESTRICTED:
        setEarnedValueRestrictedto(value);
        break;
      case cellEditType.EARNED_BASIC_VALUE:
        setEarnedBasicValue(value);
        break;
      default:
        break;
    }
  };

  const handleEdit = (id: number) => {
    const index = getIndex(id, overAllSalaryLedger);

    if (
      overAllSalaryLedger[index].ledger_type === SalaryLedgerType.EARNING &&
      (total.totalEarnings + flatRatePercentage < grossSalary ||
        grossSalary === 0)
    ) {
      if (inEditMode.cellEditKey === cellEditType.FLR) {
        if (overAllSalaryLedger[index].flat_rate !== flatRatePercentage) {
          overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
          overAllSalaryLedger[index].earnedValueFixedBelowBasic = 0;
        }
        overAllSalaryLedger[index].flat_rate = Number(flatRatePercentage);
      }
      if (inEditMode.cellEditKey === cellEditType.EARNED_BASIC_VALUE)
        overAllSalaryLedger[index].earnedValueFixedBelowBasic =
          Number(earnedBasicValue);

      if (inEditMode.cellEditKey === cellEditType.EARNED_VALUE_RESTRICTED)
        overAllSalaryLedger[index].earnedValueRestrictedTo = Number(
          earnedValueRestrictedTo
        );
      if (inEditMode.cellEditKey === cellEditType.UDV_E) {
        overAllSalaryLedger[index].udv = Number(userDefinedfixedValue);
      }
    }
    if (overAllSalaryLedger[index].ledger_type === SalaryLedgerType.DEDUCTION) {
      if (inEditMode.cellEditKey === cellEditType.CALL_TO_MAX_VALUE) {
        overAllSalaryLedger[index].callToMaxValue = Number(callToMaxValue);
      }
    }

    setInEditMode({
      cellEditKey: cellEditType.NONE,
      rowKey: 0,
      status: false,
    });
  };

  const handleSalaryStructureCheckBox = (
    event: React.MouseEvent<unknown>,
    id: number
  ) => {
    const { checked, name } = event.target as HTMLInputElement;

    const index = overAllSalaryLedger?.indexOf(
      overAllSalaryLedger?.find(({ id: value }) => value === id)!
    );
    if (chooseEarningLedger?.flag === false) {
      overAllSalaryLedger[index].earnings = 0;
      overAllSalaryLedger[index].udv = 0;
      overAllSalaryLedger[index].ledger_udvMonthly = false;
      overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
      overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
    }
    overAllSalaryLedger[index].ledger_rule = EMPTY_STRING;

    if (
      overAllSalaryLedger[index].ledger_type === SalaryLedgerType.EARNING &&
      checked === false
    ) {
      overAllSalaryLedger[index].selectedForDeductions = false;
      editEarningLedgersFromDeductions();
    }
    if (
      name === cellName.UDV &&
      checked &&
      overAllSalaryLedger[index].ledger_type === SalaryLedgerType.EARNING
    ) {
      overAllSalaryLedger[index].flat_rate = 0;
      overAllSalaryLedger[index].udv = 0;
      overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
      overAllSalaryLedger[index].earnedValueFixedBelowBasic = 0;
      overAllSalaryLedger[index].ledger_rule = EMPTY_STRING;
      overAllSalaryLedger[index].rule_applied = false;
      overAllSalaryLedger[index].earnings = 0;

      // if (netSalary > 0)
      //   setBasicSalary(netSalary + overAllSalaryLedger[index].earnings);
    }
    if (
      name === cellName.UDV &&
      checked &&
      overAllSalaryLedger[index].ledger_type === SalaryLedgerType.DEDUCTION
    ) {
      overAllSalaryLedger[index].callToMaxValue = 0;
      overAllSalaryLedger[index].ledger_udvMonthly = true;
      overAllSalaryLedger[index].deductions = 0;
      onEdit(
        overAllSalaryLedger[index].id,
        overAllSalaryLedger[index].flat_rate,
        cellEditType.UDV_D
      );
    }
    if (
      name === cellName.REMAINING_BALANCE &&
      overAllSalaryLedger[index].ledger_type === SalaryLedgerType.EARNING &&
      salaryConfigKey === SalaryCalculationConfig.GROSS
    ) {
      if (checked) {
        overAllSalaryLedger[index].remaining_balance = true;
        overAllSalaryLedger[index].earnings = grossSalary - total.totalEarnings;
      } else {
        overAllSalaryLedger[index].remaining_balance = false;
        overAllSalaryLedger[index].udv = 0;
        overAllSalaryLedger[index].earnings = 0;
      }
    }

    if (name === cellName.RULE_ON_BASIC_SALARY) {
      if (checked) {
        overAllSalaryLedger[index].flat_rate = 0;
        overAllSalaryLedger[index].udv = 0;
        overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
        overAllSalaryLedger[index].earnedValueFixedBelowBasic = 0;
        overAllSalaryLedger[index].rule_applied = true;

        setCalculationMethod({
          flag: true,
          id,
          applyOnHeads: overAllSalaryLedger[index].ledger_applied_to,
        });
      } else {
        overAllSalaryLedger[index].earnings = 0;
      }
    }
    const res = overAllSalaryLedger.map((subject) => {
      if (subject.id === id) {
        if (
          subject.ledger_type === SalaryLedgerType.EARNING &&
          chooseEarningLedger?.flag === false
        )
          subject.ledger_rule = EMPTY_STRING;

        if (name) {
          const newData: LedgersListType = {
            ...subject,
            [name]: checked ? true : false,
          };
          return newData;
        }
      }
      return subject;
    });
    setInEditMode({
      status: false,
      rowKey: 0,
      cellEditKey: cellEditType.NONE,
    });
    setOverAllSalaryLedger(res);
  };

  //Edit Earning Ledger Calculation from dedcutions for Group of Ledgers
  const editEarningLedgersFromDeductions = () => {
    let totalEarnings = 0;

    const final = overAllSalaryLedger.map((data) => {
      data.groupLedgers?.forEach((sodata) => {
        totalEarnings +=
          overAllSalaryLedger[getIndex(sodata, overAllSalaryLedger)]
            ?.earnings || 0;
      });

      if (totalEarnings > 0) {
        data.deductions = findEarningsForExpressionString(
          data.ledger_rule,
          totalEarnings,
          salRoundOffKey
        );
      }

      return data;
    });
    setOverAllSalaryLedger(final);
  };

  const setBasic = (amount: number, isBasicLedger: boolean) => {
    if (isBasicLedger && grossSalary) {
      setBasicSalary(amount);
      basicSalaryChange(amount);
    }
  };

  const errorForGrossSalaryExceed = (id: number) => {
    setInEditMode({
      status: id ? true : false,
      rowKey: id,
      cellEditKey: inEditMode.cellEditKey,
    });
    setMessage({
      message: "Invalid earning",
      operation: Operation.NONE,
      flag: true,
    });
    return false;
  };

  const handleFinalSalaryStructure = (id: number) => {
    const index = getIndex(id, overAllSalaryLedger);
    const isBasicLedger = overAllSalaryLedger[index].sal_ldgr_is_basic;

    let newEarning = 0;
    let newBasicSalary =
      isBasicLedger && grossSalary ? grossSalary : basicSalary;

    switch (inEditMode.cellEditKey) {
      case cellEditType.UDV_E:
        newEarning = handleUDVEarnings(index, isBasicLedger, id);
        break;
      case cellEditType.FLR:
      case cellEditType.EARNED_VALUE_RESTRICTED:
      case cellEditType.EARNED_BASIC_VALUE:
        newEarning = handleEarnedValues(
          isBasicLedger,
          newBasicSalary,
          index,
          id
        );
        break;

      default:
        break;
    }

    if (newEarning) {
      setBasic(newEarning, isBasicLedger);
    }

    return true;
  };

  const handleUDVEarnings = (
    index: number,
    isBasicLedger: boolean,
    id: number
  ) => {
    const remaining_balance_ledger = overAllSalaryLedger.find(
      ({ remaining_balance }) => remaining_balance
    );
    const totalEarning = overAllSalaryLedger.reduce((acc, ledger) => {
      if (
        ledger.ledger_type === SalaryLedgerType.EARNING &&
        !ledger.remaining_balance &&
        ledger.id !== id
      ) {
        acc += ledger.earnings;
      }
      return acc;
    }, 0);

    if (
      grossSalary === 0 ||
      total.totalEarnings + userDefinedfixedValue < grossSalary ||
      (remaining_balance_ledger &&
        grossSalary - (totalEarning + userDefinedfixedValue) > 0)
    ) {
      overAllSalaryLedger[index].earnings = checkForFlags(
        overAllSalaryLedger[index],
        salRoundOffKey,
        userDefinedfixedValue
      );
      overAllSalaryLedger[index].udv = checkForFlags(
        overAllSalaryLedger[index],
        salRoundOffKey,
        userDefinedfixedValue
      );
      setBasic(userDefinedfixedValue, isBasicLedger);
      if (remaining_balance_ledger) {
        const balance_ledger_index = getIndex(
          remaining_balance_ledger.id,
          overAllSalaryLedger
        );
        overAllSalaryLedger[balance_ledger_index].earnings = checkForFlags(
          overAllSalaryLedger[balance_ledger_index],
          salRoundOffKey,
          grossSalary - (totalEarning + userDefinedfixedValue)
        );
      }
      return checkForFlags(
        overAllSalaryLedger[index],
        salRoundOffKey,
        userDefinedfixedValue
      );
    } else {
      errorForGrossSalaryExceed(id);
      return checkForFlags(
        overAllSalaryLedger[index],
        salRoundOffKey,
        userDefinedfixedValue
      );
    }
  };

  const handleEarnedValues = (
    isBasicLedger: boolean,
    newBasicSalary: number,
    index: number,
    id: number
  ) => {
    let newEarning = 0;

    const earned_basic_value =
      inEditMode.cellEditKey !== cellEditType.EARNED_BASIC_VALUE
        ? overAllSalaryLedger[index].earnedValueFixedBelowBasic
        : earnedBasicValue;
    if (earned_basic_value > 0 && newBasicSalary > earned_basic_value) {
      newBasicSalary = earned_basic_value;
    }

    const remaining_balance_ledger = overAllSalaryLedger.find(
      ({ remaining_balance }) => remaining_balance
    );

    const flat_rate =
      inEditMode.cellEditKey !== cellEditType.FLR
        ? overAllSalaryLedger[index].flat_rate
        : flatRatePercentage;
    const earned_value_restricted =
      inEditMode.cellEditKey !== cellEditType.EARNED_VALUE_RESTRICTED
        ? overAllSalaryLedger[index].earnedValueRestrictedTo
        : earnedValueRestrictedTo;
    const totalEarning = overAllSalaryLedger.reduce((acc, ledger) => {
      if (
        ledger.ledger_type === SalaryLedgerType.EARNING &&
        !ledger.remaining_balance &&
        ledger.id !== id
      ) {
        acc += ledger.earnings;
      }
      return acc;
    }, 0);

    if (flat_rate >= 0) {
      newEarning = (flat_rate / 100) * newBasicSalary;

      if (earned_value_restricted > 0 && newEarning > earned_value_restricted) {
        newEarning = earned_value_restricted;
      }
      const newEarningAfterFlags = checkForFlags(
        overAllSalaryLedger[index],
        salRoundOffKey,
        newEarning
      );
      if (
        grossSalary === 0 ||
        total.totalEarnings -
          overAllSalaryLedger[index].earnings +
          checkForFlags(
            overAllSalaryLedger[index],
            salRoundOffKey,
            newEarningAfterFlags
          ) <
          grossSalary ||
        (isBasicLedger &&
          total.totalEarnings -
            overAllSalaryLedger[index].earnings +
            checkForFlags(
              overAllSalaryLedger[index],
              salRoundOffKey,
              newEarningAfterFlags
            ) <
            grossSalary) ||
        (remaining_balance_ledger &&
          grossSalary -
            (totalEarning +
              checkForFlags(
                overAllSalaryLedger[index],
                salRoundOffKey,
                newEarningAfterFlags
              )) >
            0)
      ) {
        if (flat_rate) {
          overAllSalaryLedger[index].earnedValueFixedBelowBasic =
            earned_basic_value;
          overAllSalaryLedger[index].earnedValueRestrictedTo =
            earned_value_restricted;

          overAllSalaryLedger[index].earnings = checkForFlags(
            overAllSalaryLedger[index],
            salRoundOffKey,
            newEarningAfterFlags
          );
        } else {
          overAllSalaryLedger[index].earnedValueFixedBelowBasic = 0;
          overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
          overAllSalaryLedger[index].earnings = 0;
        }
        overAllSalaryLedger[index].flat_rate = flat_rate;
        if (remaining_balance_ledger) {
          const balance_ledger_index = getIndex(
            remaining_balance_ledger.id,
            overAllSalaryLedger
          );

          overAllSalaryLedger[balance_ledger_index].earnings =
            grossSalary - (totalEarning + newEarningAfterFlags);
        }
        if (isBasicLedger) {
          basicSalaryChange(newEarningAfterFlags);
        }
        clearStates();
        return newEarningAfterFlags;
      } else {
        errorForGrossSalaryExceed(id);
        return newEarningAfterFlags;
      }
    }
    return newEarning;
  };

  const calculationMethodCancel = () => {
    setIncomeCalculatorItems([
      {
        calculation_type: calculationType.Percentage,
        from_amt: 0,
        id: 1,
        income_value_basis: 0,
        upto_amt: 0,
      },
    ]);
    const index = overAllSalaryLedger?.indexOf(
      overAllSalaryLedger?.find(
        ({ id: value }) => value === calculationMethod?.id
      )!
    );
    if (overAllSalaryLedger[index].ledger_rule === EMPTY_STRING) {
      overAllSalaryLedger[index].rule_applied = false;
    }

    setCalculationMethod({
      flag: !calculationMethod?.flag,
      id: 0,
      applyOnHeads: applyHeadsOn.BASIC_SALARY,
    });
  };

  //Save Button for Rule on Basic Salary
  const setRuleonBasicSalary = () => {
    const index = getIndex(calculationMethod?.id!, overAllSalaryLedger);

    overAllSalaryLedger[index].flat_rate = 0;
    overAllSalaryLedger[index].udv = 0;
    overAllSalaryLedger[index].ledger_udvMonthly = false;
    overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
    overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
    overAllSalaryLedger[index].ledger_rule = incomeArrayToRule(
      incomeCalculatorItems
    );
    const completed = chooseDeductionsBasedOnAppliedHeads(
      calculationMethod?.applyOnHeads!,
      calculationMethod?.id!
    );

    if (completed) {
      setInEditMode({
        status: false,
        rowKey: 0,
        cellEditKey: cellEditType.NONE,
      });
      setCalculationMethod({
        flag: !calculationMethod?.flag,
        id: 0,
        applyOnHeads: applyHeadsOn.BASIC_SALARY,
      });
      setIncomeCalculatorItems([
        {
          calculation_type: calculationType.Percentage,
          from_amt: 0,
          id: 1,
          income_value_basis: 0,
          upto_amt: 0,
        },
      ]);
    }
  };

  //Enabling field when clicked by making everything else 0 and false
  const enableField = (id: number, type: cellEditType) => {
    const index = getIndex(id, overAllSalaryLedger);

    switch (type) {
      case cellEditType.FLR:
        overAllSalaryLedger[index].udv = 0;
        overAllSalaryLedger[index].ledger_udvMonthly = false;
        overAllSalaryLedger[index].rule_applied = false;
        overAllSalaryLedger[index].ledger_rule = EMPTY_STRING;

        onEdit(
          overAllSalaryLedger[index].id,
          overAllSalaryLedger[index].flat_rate,
          cellEditType.FLR
        );
        break;
      case cellEditType.UDV_E:
        overAllSalaryLedger[index].flat_rate = 0;
        overAllSalaryLedger[index].ledger_udvMonthly = false;
        overAllSalaryLedger[index].earnedValueRestrictedTo = 0;
        overAllSalaryLedger[index].earnedValueFixedBelowBasic = 0;
        overAllSalaryLedger[index].rule_applied = false;
        overAllSalaryLedger[index].ledger_rule = EMPTY_STRING;

        onEdit(
          overAllSalaryLedger[index].id,
          overAllSalaryLedger[index].udv,
          cellEditType.UDV_E
        );
        break;

      case cellEditType.APPLY_ON_HEADS:
        overAllSalaryLedger[index].callToMaxValue = 0;
        overAllSalaryLedger[index].ledger_udvMonthly = false;

        onEdit(
          overAllSalaryLedger[index].id,
          overAllSalaryLedger[index].flat_rate,
          cellEditType.APPLY_ON_HEADS
        );
        break;

      default:
        break;
    }
  };

  //Finding which row to choose for calculation from calculation method

  //Open Modal to edit the expression string
  const editExpressionString = (item: LedgersListType) => {
    const index = overAllSalaryLedger?.indexOf(item);

    const incomeCalItems = getExpressionStringtoArray(
      overAllSalaryLedger[index].ledger_rule
    );

    setCalculationMethod({
      flag: true,
      id: overAllSalaryLedger[index].id,
      applyOnHeads: overAllSalaryLedger[index].ledger_applied_to,
    });
    setIncomeCalculatorItems(incomeCalItems);
  };

  const saveChooseLedgers = () => {
    if (
      overAllSalaryLedger?.filter(
        ({ selectedForDeductions }) => selectedForDeductions
      ).length > 0
    ) {
      overAllSalaryLedger[
        getIndex(chooseEarningLedger?.id!, overAllSalaryLedger)
      ].groupLedgers = overAllSalaryLedger
        ?.filter(({ selectedForDeductions }) => selectedForDeductions)
        ?.map(({ id: value }) => value);

      setCalculationMethod({
        flag: true,
        id: chooseEarningLedger?.id!,
        applyOnHeads: applyHeadsOn.ON_GROUP_OF_LEDGERS,
      });
      setChooseEarningLedger({
        flag: false,
        id: 0,
      });
    } else {
      setMessage({
        message: "Select Earning Ledgers",
        flag: true,
        operation: Operation.NONE,
      });
    }
  };

  //Handle BasicChange
  const basicSalaryChange = (basicAmt: number) => {
    const totalEarning = overAllSalaryLedger.reduce((result, ledger) => {
      const configAmount = chooseAmtBasedOnType(
        ledger.sal_ldgr_is_basic,
        overAllSalaryLedger,
        salaryConfigKey,
        salRoundOffKey,
        basicAmt
      );

      if (ledger.sal_ldgr_is_basic) {
        if (salaryConfigKey === SalaryCalculationConfig.GROSS) {
          result += handleEarning(ledger, salRoundOffKey, grossSalary);
        } else {
          result += basicSalary;
        }
      } else {
        result += handleEarning(ledger, salRoundOffKey, configAmount);
      }
      return result;
    }, 0);

    const response = overAllSalaryLedger?.map((data, index) => {
      const isBasicLedger = overAllSalaryLedger[index].sal_ldgr_is_basic;
      const salary = chooseAmtBasedOnType(
        true,
        overAllSalaryLedger,
        salaryConfigKey,
        salRoundOffKey,
        basicAmt
      );

      if (isBasicLedger) {
        if (salaryConfigKey === SalaryCalculationConfig.BASIC) {
          overAllSalaryLedger[index].earnings = salary;
        } else {
          overAllSalaryLedger[index].earnings = handleEarning(
            data,
            salRoundOffKey,
            grossSalary
          );
        }
      } else if (data.remaining_balance) {
        overAllSalaryLedger[index].earnings = grossSalary - totalEarning;
        overAllSalaryLedger[index].remaining_balance = true;
      } else {
        overAllSalaryLedger[index].earnings = handleEarning(
          data,
          salRoundOffKey,
          salary
        );
      }

      if (data.ledger_rule && data.ledger_type === SalaryLedgerType.EARNING)
        overAllSalaryLedger[index].earnings = findEarningsForExpressionString(
          data.ledger_rule,
          salary,
          salRoundOffKey
        )!;

      if (data.ledger_rule && data.ledger_type === SalaryLedgerType.DEDUCTION) {
        const configAmount = chooseAmtBasedOnType(
          true,
          overAllSalaryLedger,
          salaryConfigKey,
          salRoundOffKey,
          basicAmt
        );

        switch (data?.ledger_applied_to) {
          case applyHeadsOn.BASIC_SALARY:
            overAllSalaryLedger[index].deductions =
              findEarningsForExpressionString(
                data.ledger_rule,
                salary,
                salRoundOffKey
              )!;

            break;
          case applyHeadsOn.ON_GROUP_OF_LEDGERS:
            const toGroupLedgersEarning =
              totalEarningsForDeductionsOnGroupOfLedgers(data.id, configAmount);

            overAllSalaryLedger[index].deductions =
              findEarningsForExpressionString(
                data.ledger_rule,
                toGroupLedgersEarning,
                salRoundOffKey
              )!;

            break;
          case applyHeadsOn.GROSS_SALARY:
            overAllSalaryLedger[index].deductions =
              findEarningsForExpressionString(
                data.ledger_rule,
                salaryConfigKey === SalaryCalculationConfig.BASIC
                  ? totalEarning
                  : grossSalary,
                salRoundOffKey
              )!;

            break;
          default:
            break;
        }
      }
      return data;
    });

    setOverAllSalaryLedger(response);
  };

  const totalEarningsForDeductionsOnGroupOfLedgers = (
    id: number,
    amount: number
  ) => {
    const index = getIndex(id, overAllSalaryLedger);
    const groupLedgersSet = new Set(overAllSalaryLedger[index].groupLedgers);
    let presentTotalEarnings = 0;

    const groupLedgersTotal = overAllSalaryLedger.reduce((acc, item) => {
      if (operation === Operation.CREATE) {
        if (item.sal_ldgr_is_basic) {
          presentTotalEarnings += amount;
          if (groupLedgersSet.has(item.id)) {
            acc += amount;
          }
        } else {
          if (item.flat_rate > 0) {
            let newEarning = 0;
            let newBasicSalary = 0;
            newBasicSalary = amount;
            if (
              item.earnedValueFixedBelowBasic > 0 &&
              amount > item.earnedValueFixedBelowBasic
            )
              newBasicSalary = item.earnedValueFixedBelowBasic;

            if (item.flat_rate >= 0) {
              newEarning = (item.flat_rate / 100) * newBasicSalary;
              if (
                item.earnedValueRestrictedTo > 0 &&
                newEarning > item.earnedValueRestrictedTo
              ) {
                newEarning = item.earnedValueRestrictedTo;
              }
            }
            presentTotalEarnings += newEarning;
            if (groupLedgersSet.has(item.id)) {
              acc += newEarning;
            }
          }
          if (item.udv > 0) {
            presentTotalEarnings += item.udv;
            if (groupLedgersSet.has(item.id)) {
              acc += item.udv;
            }
          }

          if (item.rule_applied) {
            presentTotalEarnings += findEarningsForExpressionString(
              item.ledger_rule,
              amount,
              salRoundOffKey
            )!;
            if (groupLedgersSet.has(item.id)) {
              acc += findEarningsForExpressionString(
                item.ledger_rule,
                amount,
                salRoundOffKey
              )!;
            }
          }
        }
      } else {
        acc += item.earnings;
      }

      return acc;
    }, 0);

    return groupLedgersTotal + (grossSalary - presentTotalEarnings);
  };
  const clearStates = () => {
    switch (inEditMode.cellEditKey) {
      case cellEditType.FLR:
        setFlatRatePercentage(0);
        break;
      case cellEditType.EARNED_BASIC_VALUE:
        setEarnedBasicValue(0);
        break;
      case cellEditType.EARNED_VALUE_RESTRICTED:
        setEarnedValueRestrictedto(0);
        break;
      case cellEditType.UDV_E:
      case cellEditType.UDV_D:
        setUserDefinedFixedValue(0);
        break;
      case cellEditType.CALL_TO_MAX_VALUE:
        setCallToMaxValue(0);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    const detailsDataArray = overAllSalaryLedger?.filter(
      ({ isChecked }) => isChecked
    );

    const details_data = detailsDataArray?.map((data, index) => {
      return {
        sal_str_sl: index + 1,
        sal_str_earn_ded: data.ledger_type,
        sal_str_earn_type:
          data.flat_rate > 0
            ? SalaryStructreEarnType.FLAT_RATE
            : data.ledger_udvMonthly &&
              data.ledger_type === SalaryLedgerType.EARNING
            ? SalaryStructreEarnType.USER_DEFINED_MONTHLY
            : data.udv > 0
            ? SalaryStructreEarnType.USER_DEFINED_VALUE
            : EMPTY_STRING,
        sal_str_earn_flat_rate: data.flat_rate,
        sal_str_earn_is_UDM: data.ledger_udvMonthly,
        sal_str_earn_UDM_val: data.udv,
        sal_str_earn_restrict_to: data.earnedValueRestrictedTo,
        sal_str_earn_basic_max_to: data.earnedValueFixedBelowBasic,
        sal_str_earn_rule_apply: data.rule_applied,
        sal_str_earn_rule:
          data.ledger_type === SalaryLedgerType.EARNING
            ? data.ledger_rule
            : EMPTY_STRING,
        sal_remaining_amt_rule: data.remaining_balance,
        sal_str_ded_is_UDM: data.ledger_udvMonthly,
        sal_str_ded_UDM_val: 0,
        sal_str_ded_is_applied_on_heads: data.ledger_applied_to ? true : false,
        sal_str_ded_heads: data.ledger_udvMonthly
          ? EMPTY_STRING
          : getAppliedHeads(data, overAllSalaryLedger).heads,
        sal_str_ded_rule:
          data.ledger_type === SalaryLedgerType.DEDUCTION
            ? data.ledger_rule
            : EMPTY_STRING,
        sal_str_ded_to_max_value: data.callToMaxValue,
        sal_str_group_id_heads: getAppliedHeads(data, overAllSalaryLedger)
          .headIds,
        pr_sal_ldgr_id: data.id,
      };
    });

    if (operation === Operation.CREATE && salaryTemplateName) {
      if (
        (total.totalEarnings === grossSalary &&
          salaryConfigKey === SalaryCalculationConfig.GROSS) ||
        salaryConfigKey === SalaryCalculationConfig.BASIC
      ) {
        AddSalaryStructure({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            pr_acd_yr_id:
              payRollActiveAcdYear?.GetPayRollAcdYrActiveByInstId?.id,
            input: {
              master_data: {
                sal_str_desc: salaryTemplateName,
                sal_str_assigned_to_all: salStrAssignedToAll,
                sal_str_in_use: false,
                pr_category_id: category?.value,
                pr_dept_id: department?.value,
                pr_designation_id: designation?.value,
              },
              details_data,
            },
          },
          refetchQueries: [
            {
              query: GetPayRollSalaryStructMastersByInstId,
              variables: {
                token,
                inst_id: InstId!,
                after: null,
                first: ROWS_PER_PAGE,
                direction: Direction.ASC,
                sortBy: SortBy.SAL_STR_DESC,
                name: searchData,
              },
            },
          ],
        }).then(({ data }) => {
          if (data)
            if (data) {
              setMessage({
                flag: true,
                message: "Salary Structure created",
                operation: Operation.CREATE,
              });
              handleClear();
            }
        });
      } else {
        setMessage({
          message: "Entered Gross Salary and Total Earnings doesn't match",
          flag: true,
          operation: Operation.NONE,
        });
      }
    }
    if (operation === Operation.UPDATE && salaryStructureId?.value) {
      UpdateSalaryStructure({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          pr_sal_str_master_id: salaryStructureId?.value,
          input: {
            master_data: {
              sal_str_desc: salaryTemplateName,
              sal_str_assigned_to_all: salStrAssignedToAll,
              sal_str_in_use: false,
              pr_category_id: category?.value,
              pr_dept_id: department?.value,
              pr_designation_id: designation?.value,
            },
            details_data,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollSalaryStructMastersByInstId,
            variables: {
              token,
              inst_id: InstId!,
              after: null,
              first: ROWS_PER_PAGE,
              direction: Direction.ASC,
              sortBy: SortBy.SAL_STR_DESC,
              name: searchData,
            },
          },
          {
            query: GetPayRollSalaryStructMastersAndDetails,
            variables: {
              pr_sal_str_master_id: salaryStructureId.value,
              inst_id: InstId!,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data)
          if (data) {
            setMessage({
              flag: true,
              message: "Salary Structure created",
              operation: Operation.UPDATE,
            });
            handleClear();
          }
      });
    }
  };

  const handleDelete = (id: number) => {
    DeleteSalaryStructure({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryStructMastersByInstId,
          variables: {
            token,
            inst_id: InstId!,
            after: null,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            sortBy: SortBy.SAL_STR_DESC,
            name: searchData,
          },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          flag: true,
          message: "Deleted SuccessFully",
          operation: Operation.DELETE,
        });
      setDeleteModal(!deleteModal);
      setSalaryStructureId(null);
      salaryNameInputRef?.focus();
    });
  };

  const chooseDeductionsBasedOnAppliedHeads = (
    appliedHeads: applyHeadsOn,
    id: number
  ) => {
    const index = getIndex(id, overAllSalaryLedger);
    const totalGross =
      checkForRoundOff(
        overAllSalaryLedger?.reduce(
          (total, { earnings }) => total + (earnings > 0 ? earnings : 0),
          0
        ),
        salRoundOffKey!
      ) ?? 0;
    const isBasicLedger = overAllSalaryLedger[index].sal_ldgr_is_basic;
    const salary = isBasicLedger && grossSalary ? grossSalary : basicSalary;

    switch (appliedHeads) {
      case applyHeadsOn.BASIC_SALARY:
        if (
          overAllSalaryLedger[index].ledger_type === SalaryLedgerType.DEDUCTION
        ) {
          overAllSalaryLedger[index].deductions =
            findEarningsForExpressionString(
              overAllSalaryLedger[index].ledger_rule,
              salary,
              salRoundOffKey
            );
          overAllSalaryLedger[index].ledger_applied_to =
            applyHeadsOn.BASIC_SALARY;
          return true;
        }
        if (
          overAllSalaryLedger[index].ledger_type === SalaryLedgerType.EARNING
        ) {
          const remaining_balance_ledger = overAllSalaryLedger.find(
            ({ remaining_balance }) => remaining_balance
          );
          const remaining_index = getIndex(
            remaining_balance_ledger?.id!,
            overAllSalaryLedger
          );

          const totalEarning = overAllSalaryLedger.reduce((acc, ledger) => {
            if (
              ledger.ledger_type === SalaryLedgerType.EARNING &&
              !ledger.remaining_balance &&
              ledger.id !== id
            ) {
              acc += ledger.earnings;
            }
            return acc;
          }, 0);

          const earning = findEarningsForExpressionString(
            overAllSalaryLedger[index].ledger_rule,
            salary,
            salRoundOffKey
          );

          if (
            grossSalary === 0 ||
            total.totalEarnings + salary < grossSalary ||
            (remaining_balance_ledger &&
              grossSalary - (totalEarning + earning) > 0)
          ) {
            overAllSalaryLedger[index].earnings = earning;
            if (grossSalary > 0)
              overAllSalaryLedger[remaining_index!].earnings =
                grossSalary - (totalEarning + earning);
            setBasic(basicSalary, isBasicLedger);
            if (remaining_balance_ledger) return true;
          } else {
            overAllSalaryLedger[index].ledger_rule = EMPTY_STRING;
            overAllSalaryLedger[index].rule_applied = false;
            errorForGrossSalaryExceed(id);
          }
        }
        overAllSalaryLedger[index].ledger_applied_to =
          applyHeadsOn.BASIC_SALARY;
        break;
      // fall-through

      case applyHeadsOn.ON_GROUP_OF_LEDGERS:
        const totalEarnings =
          overAllSalaryLedger
            ?.filter(
              ({ selectedForDeductions, earnings, isChecked }) =>
                selectedForDeductions && earnings > 0 && isChecked
            )
            ?.reduce((total, { earnings }) => total + earnings, 0) ?? 0;
        overAllSalaryLedger[index].deductions = findEarningsForExpressionString(
          overAllSalaryLedger[index].ledger_rule,
          totalEarnings,
          salRoundOffKey
        );
        overAllSalaryLedger[index].ledger_applied_to =
          applyHeadsOn.ON_GROUP_OF_LEDGERS;
        const response = overAllSalaryLedger.map((data) => ({
          ...data,
          selectedForDeductions: false,
        }));
        setOverAllSalaryLedger(response);
        return true;
      case applyHeadsOn.GROSS_SALARY:
        overAllSalaryLedger[index].deductions = findEarningsForExpressionString(
          overAllSalaryLedger[index].ledger_rule,
          totalGross,
          salRoundOffKey
        );
        overAllSalaryLedger[index].ledger_applied_to =
          applyHeadsOn.GROSS_SALARY;
        return true;
      default:
        break;
    }
  };

  const applyHeadsFromString = (data: string) => {
    const applyHeads =
      applyOnHeadsLookUp.find(({ value }) => value === Number(data))?.label ??
      applyHeadsOn.ON_GROUP_OF_LEDGERS;
    var ledgersGroup = [0];
    if (applyHeads === applyHeadsOn.ON_GROUP_OF_LEDGERS) {
      ledgersGroup = data.split(";")?.map((data) => Number(data));
    }
    return {
      applyHeads,
      ledgersGroup: ledgersGroup.filter((data) => data !== 0),
    };
  };
  useEffect(() => {
    if (token && InstId) {
      GetPayRoll();
    }
  }, [token, InstId, GetPayRoll]);
  useEffect(() => {
    if (data && !loading) {
      setOverAllSalaryLedger([
        ...data.GetPayRollSalaryLdgrsByInstId?.map(
          ({
            id,
            sal_ldgr_desc,
            sal_ldgr_earn_deduct,
            sal_ldgr_remove_decimal,
            sal_ldgr_round_to_next_int,
            sal_ldgr_is_basic,
          }) => ({
            id,
            ledger_type: sal_ldgr_earn_deduct,
            earn_type: EMPTY_STRING,
            ledger_desc: sal_ldgr_desc,
            ledger_applied_to: applyHeadsOn.BASIC_SALARY,
            ledger_rule: EMPTY_STRING,
            ledger_udvMonthly: false,
            ledger_applied_on_heads: false,
            earnings: 0,
            deductions: 0,
            earnedValueFixedBelowBasic: 0,
            earnedValueRestrictedTo: 0,
            callToMaxValue: 0,
            flat_rate: 0,
            udv: 0,
            sal_ldgr_remove_decimal: sal_ldgr_remove_decimal,
            sal_ldgr_round_to_next_int: sal_ldgr_round_to_next_int,
            emp_sal_str_sl: 0,
            remaining_balance: false,
            sal_ldgr_is_basic: sal_ldgr_is_basic,
            emp_sal_str_ldgr_sl: 0,
            isChecked: sal_ldgr_is_basic,
            selectedForDeductions: false,
            groupLedgers: null,
            rule_applied: false,
          })
        ),
      ]);
    }
  }, [data, loading, operation]);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const checkDisable = (id: number) => {
    const earningLedgerWithEarningsCount = overAllSalaryLedger.filter(
      ({ earnings, ledger_udvMonthly, ledger_type }) =>
        earnings === 0 &&
        ledger_udvMonthly === false &&
        ledger_type === SalaryLedgerType.EARNING
    ).length;

    const index = getIndex(id, overAllSalaryLedger);

    if (
      overAllSalaryLedger[index].earnings > 0 ||
      overAllSalaryLedger[index].ledger_udvMonthly
    ) {
      if (overAllSalaryLedger[index].remaining_balance) {
        return false;
      }
      return true;
    } else if (earningLedgerWithEarningsCount === 1) {
      return false;
    }
    return true;
  };

  const prefillSalaryStructure = (data: PayRollSalaryStructDetails[]) => {
    setOverAllSalaryLedger(
      overAllSalaryLedger?.map((d) => {
        const foundata = data?.filter(
          ({ pr_sal_ldgr_id }) => pr_sal_ldgr_id === d.id
        );

        if (foundata.length > 0) {
          return {
            id: foundata[0].pr_sal_ldgr_id,
            ledger_type: foundata[0].sal_str_earn_ded,
            earn_type: EMPTY_STRING,
            ledger_desc: foundata[0].sal_ldgr_details.sal_ldgr_desc,
            ledger_applied_to: findApplyHeadsBasedOnGroupIds(
              getGroupIds(foundata[0].sal_str_group_id_heads)
            ),
            ledger_rule:
              foundata[0].sal_str_earn_ded === SalaryLedgerType.EARNING
                ? foundata[0].sal_str_earn_rule
                : foundata[0].sal_str_ded_rule,
            ledger_udvMonthly:
              foundata[0].sal_str_earn_ded === SalaryLedgerType.EARNING
                ? foundata[0].sal_str_earn_is_UDM
                : foundata[0].sal_str_ded_is_UDM,
            ledger_applied_on_heads: false,
            earnings: 0,
            remaining_balance: foundata[0].sal_remaining_amt_rule,
            deductions: 0,
            earnedValueFixedBelowBasic: foundata[0].sal_str_earn_basic_max_to,
            earnedValueRestrictedTo: foundata[0].sal_str_earn_restrict_to,

            callToMaxValue: foundata[0].sal_str_ded_to_max_value,
            sal_ldgr_remove_decimal:
              foundata[0].sal_ldgr_details.sal_ldgr_remove_decimal,
            sal_ldgr_round_to_next_int:
              foundata[0].sal_ldgr_details.sal_ldgr_round_to_next_int,
            flat_rate: foundata[0].sal_str_earn_flat_rate,
            udv:
              foundata[0].sal_str_earn_ded === SalaryLedgerType.EARNING
                ? foundata[0].sal_str_earn_UDM_val
                : foundata[0].sal_str_ded_UDM_val,
            emp_sal_str_sl: 0,
            sal_ldgr_is_basic: foundata[0].sal_ldgr_details.sal_ldgr_is_basic,
            emp_sal_str_ldgr_sl: 0,
            isChecked: true,
            selectedForDeductions: false,
            groupLedgers: applyHeadsFromString(
              foundata[0].sal_str_group_id_heads
            )?.ledgersGroup,
            rule_applied: foundata[0].sal_str_earn_rule_apply,
          };
        } else {
          return {
            ...d,
            isChecked: false,
          };
        }
      })
    );
  };

  const handleClear = () => {
    setOverAllSalaryLedger(
      overAllSalaryLedger?.map((data) => ({
        ...data,
        isChecked: data.sal_ldgr_is_basic,
        selectedForDeductions: false,
        groupLedgers: [0],
        flat_rate: 0,
        ledger_applied_to: applyHeadsOn.BASIC_SALARY,
        callToMaxValue: 0,
        deductions: 0,
        earnings: 0,
        earnedValueFixedBelowBasic: 0,
        earnedValueRestrictedTo: 0,
        rule_applied: false,
        ledger_rule: EMPTY_STRING,
        udv: 0,
        ledger_udvMonthly: false,
      }))
    );
    setBasicSalary(0);
    setGrossSalary(0);
    setDepartment(null);
    setDesignation(null);
    setCategory(null);
    setSalStrAssignedToAll(false);
    setSalaryTemplateName(EMPTY_STRING);
    if (operation !== Operation.CREATE) {
      salaryNameInputRef?.focus();
    } else {
      salaryStructureRef.focus();
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryStructure.Titles.map(
          (title: HRTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Title}</React.Fragment>;
          }
        )}
      </Title>

      <div className="salary-structure">
        <div className="row g-0 salary-structure__frame">
          <div className="col-4 h-100 salary-structure__frame--leftblock">
            <div className="salary-structure__frame--leftblock--datablock">
              <div className="salary-structure__frame--leftblock--datablock--label-grid">
                <>
                  {operation !== Operation.CREATE && (
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={salaryStructureDropdown!}
                      openOnFocus
                      ref={salaryNameRef!}
                      value={salaryStructureId}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as responseType, salaryStructureId)
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSalaryStructureId(newValue as responseType);
                          //Choose Ledger should always stay true since selecting ledger can misbehave
                          setChooseLedger(true);

                          GetSalaryStructureDetails({
                            variables: {
                              token,
                              pr_sal_str_master_id: (newValue as responseType)?.value!,
                              inst_id: InstId!,
                            },
                          }).then(({ data, variables }) => {
                            // setSalaryTemplateName(newValue)

                            if (data) {
                              setSalaryTemplateName(
                                data.GetPayRollSalaryStructMastersAndDetails
                                  .master_data.sal_str_desc
                              );
                              setSalStrAssignedToAll(
                                data.GetPayRollSalaryStructMastersAndDetails
                                  .master_data.sal_str_assigned_to_all
                              );
                              setCategory(
                                categoryDropDown?.find(
                                  ({ value }) =>
                                    value ===
                                    data.GetPayRollSalaryStructMastersAndDetails
                                      ?.master_data?.pr_category_id
                                )!
                              );
                              setDepartment(
                                departmentDropDown?.find(
                                  ({ value }) =>
                                    value ===
                                    data.GetPayRollSalaryStructMastersAndDetails
                                      ?.master_data?.pr_dept_id
                                )!
                              );
                              setDesignation(
                                designationDropDown?.find(
                                  ({ value }) =>
                                    value ===
                                    data.GetPayRollSalaryStructMastersAndDetails
                                      ?.master_data?.pr_designation_id
                                )!
                              );

                              prefillSalaryStructure(
                                data?.GetPayRollSalaryStructMastersAndDetails
                                  ?.details_data!
                              );
                            }
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Salary Template Name"
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchSalaryStructure(e.target.value)
                          }
                          autoFocus
                          fullWidth
                          className={labelClasses.formControlRoot}
                        />
                      )}
                    />
                  )}
                  {operation === Operation.CREATE && (
                    <Label>Salary Template Name</Label>
                  )}
                  {operation !== Operation.VIEW && (
                    <Input
                      required
                      name="salaryStructureName"
                      onChange={(e) => setSalaryTemplateName(e.target.value)}
                      value={salaryTemplateName}
                      autoFocus
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (
                          e.key === Keys.ENTER &&
                          salaryTemplateName !== EMPTY_STRING &&
                          !chooseLedger &&
                          operation === Operation.CREATE
                        ) {
                          setLedgerList(!ledgerList);
                        }
                      }}
                    />
                  )}
                </>
                <img
                  src={LedgersList}
                  onClick={() => setLedgerList(!ledgerList)}
                  alt="/"
                  className="salary-structure__frame--leftblock--datablock--image"
                />
              </div>
            </div>

            <div className="salary-structure__frame--leftblock--tableblock">
              <Title variant="subtitle1">
                {HRTitles.SalaryStructure.Titles.map(
                  (title: HRTitleProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {title.Over_All}
                      </React.Fragment>
                    );
                  }
                )}
              </Title>
              <div className="salary-structure__frame--leftblock--tableblock--textfield">
                <TextField
                  className="salary-structure__frame--leftblock--amount"
                  label={toStandardCase(salaryConfigKey)}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  onFocus={(e) => e.target.select()}
                  type="number"
                  ref={basicSalaryRef!}
                  value={
                    salaryConfigKey === SalaryCalculationConfig.BASIC
                      ? basicSalary
                      : grossSalary
                  }
                  onChange={(e) => {
                    if (salaryConfigKey === SalaryCalculationConfig.BASIC) {
                      setBasicSalary(Number(e.target.value));
                    } else {
                      setGrossSalary(Number(e.target.value));
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      basicSalaryChange(
                        salaryConfigKey === SalaryCalculationConfig.BASIC
                          ? basicSalary
                          : grossSalary
                      );
                    }
                  }}
                />
              </div>

              <OverAllStructure
                selectedOverAllLedger={overAllSalaryLedger?.filter(
                  ({ isChecked }) => isChecked
                )}
                basicSalary={basicSalary}
                pageFor={OverAllSalaryLedgerPageType.SALARY_STRUCTURE}
              />
            </div>
            <div className="salary-structure__frame--leftblock--selectblock">
              <div className="row g-0 salary-structure__frame--leftblock--selectblock--autocomplete">
                {USE_HR_CATEGORY ? (
                  <div className="col">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={categoryDropDown}
                      value={category}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as responseType, category)
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setCategory(newValue as responseType);
                        } else {
                          setCategory(null);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.BACKSPACE) setCategory(null);
                      }}
                      openOnFocus
                      freeSolo
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category"
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          id="outlined Category"
                          className={labelClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                ) : null}
                {USE_HR_DESIGNATION ? (
                  <div className="col">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={designationDropDown}
                      value={designation}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as responseType, designation)
                      }
                      onChange={(e, newValue) => {
                        if (newValue) setDesignation(newValue as responseType);
                        else setDesignation(null);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.BACKSPACE) setDesignation(null);
                      }}
                      openOnFocus
                      freeSolo
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Designation"
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          id="outlined Job Type"
                          className={labelClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                ) : null}
                {USE_HR_DEPARTMENT ? (
                  <div className="col">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={departmentDropDown}
                      value={department}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as responseType, department)
                      }
                      onChange={(e, newValue) => {
                        if (newValue) setDepartment(newValue as responseType);
                        else setDepartment(null);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.BACKSPACE) {
                          setDepartment(null);
                        }
                      }}
                      openOnFocus
                      freeSolo
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Department"
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          id="outlined Department"
                          className={labelClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                ) : null}
              </div>
              <FormGroup className="salary-structure__frame--leftblock--form-labels">
                <FormControlLabel
                  label="Would You Like to Associate Structure"
                  control={
                    <AntSwitch
                      checked={salStrAssignedToAll}
                      onClick={(e: React.MouseEvent) =>
                        setSalStrAssignedToAll(
                          (e.target as HTMLInputElement).checked
                        )
                      }
                    />
                  }
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
          </div>
          {overAllSalaryLedger.filter(({ isChecked }) => isChecked).length >
            0 && (
            <div className="col h-100 salary-structure__frame--rightblock">
              <div className="salary-structure__frame--rightblock--title">
                <Title variant="subtitle1">
                  {HRTitles.SalaryStructure.Titles.map(
                    (title: HRTitleProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          {title.List}
                        </React.Fragment>
                      );
                    }
                  )}
                </Title>
              </div>
              <div className="salary-structure__frame--rightblock--tableblock">
                <Title variant="subtitle1">
                  {HRTitles.SalaryStructure.Titles.map(
                    (title: HRTitleProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          {title.Earning_Details}
                        </React.Fragment>
                      );
                    }
                  )}
                </Title>
                <TableContainer className="salary-structure__frame--rightblock--tableblock--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {HR_Table.SalaryStructure.Earnings.Table_Headers.filter(
                          (table_head: TableHeaderProps) =>
                            (table_head.labelName !== "Remaining Salary" &&
                              salaryConfigKey !==
                                SalaryCalculationConfig.GROSS) ||
                            salaryConfigKey === SalaryCalculationConfig.GROSS
                        ).map((th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index}>{th.labelName}</TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {overAllSalaryLedger
                        .filter(
                          (f) =>
                            (f.isChecked &&
                              f.ledger_type === SalaryLedgerType.EARNING &&
                              f.sal_ldgr_is_basic === false) ||
                            (f.sal_ldgr_is_basic &&
                              salaryConfigKey === SalaryCalculationConfig.GROSS)
                        )
                        .map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--slno"
                                id="td-center"
                              >
                                {item.sal_ldgr_is_basic ? null : (
                                  <Checkbox
                                    checked={item.isChecked ?? false}
                                    onClick={(event) => {
                                      if (
                                        operation === Operation.CREATE ||
                                        operation === Operation.UPDATE
                                      )
                                        handleOverAllLedgers(event, item.id);
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell>{item.ledger_desc}</TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--input"
                                id="td-right"
                                onClick={() => {
                                  if (
                                    operation === Operation.CREATE ||
                                    operation === Operation.UPDATE
                                  ) {
                                    enableField(item.id, cellEditType.FLR);
                                  }
                                }}
                              >
                                {inEditMode.rowKey === item.id &&
                                inEditMode.status &&
                                inEditMode.cellEditKey === cellEditType.FLR &&
                                ((item.sal_ldgr_is_basic && grossSalary) ||
                                  item.sal_ldgr_is_basic === false) ? (
                                  <input
                                    autoFocus
                                    disabled={item.udv !== 0}
                                    type="number"
                                    value={flatRatePercentage}
                                    onChange={(e) => {
                                      setFlatRatePercentage(
                                        Number(e.target.value)
                                      );
                                    }}
                                    onFocus={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      e.target?.select();
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent) => {
                                      if (e.key === Keys.ENTER) {
                                        // handleEdit(item.id);
                                        const completed =
                                          handleFinalSalaryStructure(item.id);

                                        if (completed) {
                                          editEarningLedgersFromDeductions();
                                          grossChange(
                                            overAllSalaryLedger,
                                            setOverAllSalaryLedger,
                                            salRoundOffKey
                                          );
                                          if (flatRatePercentage > 0) {
                                            setInEditMode({
                                              status: true,
                                              rowKey: item.id,
                                              cellEditKey:
                                                cellEditType.EARNED_VALUE_RESTRICTED,
                                            });
                                          } else {
                                            setInEditMode({
                                              status: true,
                                              rowKey: item.id,
                                              cellEditKey: cellEditType.UDV_E,
                                            });
                                          }
                                        }
                                      }
                                    }}
                                  />
                                ) : (
                                  item.flat_rate
                                )}
                              </TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--input"
                                id="td-right"
                                onClick={() => {
                                  if (
                                    operation === Operation.CREATE ||
                                    operation === Operation.UPDATE
                                  ) {
                                    enableField(item.id, cellEditType.UDV_E);
                                  }
                                }}
                              >
                                {inEditMode.rowKey === item.id &&
                                inEditMode.status &&
                                inEditMode.cellEditKey ===
                                  cellEditType.UDV_E ? (
                                  <input
                                    autoFocus
                                    type="number"
                                    value={userDefinedfixedValue}
                                    onChange={(e) =>
                                      setUserDefinedFixedValue(
                                        Number(e.target.value)
                                      )
                                    }
                                    onFocus={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      e.target?.select();
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent) => {
                                      if (e.key === Keys.ENTER) {
                                        // handleEdit(item.id);
                                        const completed =
                                          handleFinalSalaryStructure(item.id);
                                        if (completed) {
                                          editEarningLedgersFromDeductions();
                                          if (userDefinedfixedValue) {
                                            setInEditMode({
                                              status: false,
                                              rowKey: 0,
                                              cellEditKey: cellEditType.NONE,
                                            });
                                          }
                                        }
                                        // if (basicSalary > 0 && netSalary > 0) {
                                        //   changeBasicSalaryThroughCTC();
                                        // }
                                      }
                                    }}
                                  />
                                ) : (
                                  item.udv
                                )}
                              </TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--actions"
                                id="td-center"
                              >
                                <Checkbox
                                  checked={item.ledger_udvMonthly}
                                  name={cellName.UDV}
                                  onClick={(event) => {
                                    if (
                                      operation === Operation.CREATE ||
                                      operation === Operation.UPDATE
                                    )
                                      handleSalaryStructureCheckBox(
                                        event,
                                        item.id
                                      );
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--input"
                                id="td-right"
                                onClick={() => {
                                  if (
                                    item.flat_rate !== 0 &&
                                    (operation === Operation.CREATE ||
                                      operation === Operation.UPDATE)
                                  )
                                    onEdit(
                                      item.id,
                                      item.earnedValueRestrictedTo,
                                      cellEditType.EARNED_VALUE_RESTRICTED
                                    );
                                }}
                              >
                                {inEditMode.rowKey === item.id &&
                                inEditMode.status &&
                                inEditMode.cellEditKey ===
                                  cellEditType.EARNED_VALUE_RESTRICTED ? (
                                  <input
                                    autoFocus
                                    type="number"
                                    value={earnedValueRestrictedTo}
                                    onChange={(e) =>
                                      setEarnedValueRestrictedto(
                                        Number(e.target.value)
                                      )
                                    }
                                    onFocus={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      if (item.earnedValueRestrictedTo) {
                                        setEarnedValueRestrictedto(
                                          item.earnedValueRestrictedTo
                                        );
                                      }
                                      e.target?.select();
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent) => {
                                      if (e.key === Keys.ENTER) {
                                        // handleEdit(item.id);
                                        const completed =
                                          handleFinalSalaryStructure(item.id);
                                        if (completed) {
                                          editEarningLedgersFromDeductions();
                                          // if (basicSalary > 0 && netSalary > 0) {
                                          //   changeBasicSalaryThroughCTC();
                                          // }
                                          setInEditMode({
                                            rowKey: item.id,
                                            status: true,
                                            cellEditKey:
                                              cellEditType.EARNED_BASIC_VALUE,
                                          });
                                        }
                                      }
                                    }}
                                  />
                                ) : (
                                  item.earnedValueRestrictedTo
                                )}
                              </TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--input"
                                id="td-right"
                                onClick={() => {
                                  if (
                                    item.flat_rate !== 0 &&
                                    (operation === Operation.CREATE ||
                                      operation === Operation.UPDATE)
                                  )
                                    onEdit(
                                      item.id,
                                      item.earnedValueFixedBelowBasic,
                                      cellEditType.EARNED_BASIC_VALUE
                                    );
                                }}
                              >
                                {inEditMode.rowKey === item.id &&
                                inEditMode.status &&
                                inEditMode.cellEditKey ===
                                  cellEditType.EARNED_BASIC_VALUE ? (
                                  <input
                                    autoFocus
                                    type="number"
                                    value={earnedBasicValue}
                                    onChange={(e) =>
                                      setEarnedBasicValue(
                                        Number(e.target.value)
                                      )
                                    }
                                    onFocus={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      if (
                                        item.flat_rate !== 0 &&
                                        (operation === Operation.CREATE ||
                                          operation === Operation.UPDATE)
                                      )
                                        onEdit(
                                          item.id,
                                          item.earnedValueFixedBelowBasic,
                                          cellEditType.EARNED_BASIC_VALUE
                                        );
                                      e.target?.select();
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent) => {
                                      if (e.key === Keys.ENTER) {
                                        handleEdit(item.id);
                                        const completed =
                                          handleFinalSalaryStructure(item.id);
                                        if (completed) {
                                          editEarningLedgersFromDeductions();
                                        }
                                        // if (basicSalary > 0 && netSalary > 0) {
                                        //   changeBasicSalaryThroughCTC();
                                        // }
                                      }
                                    }}
                                  />
                                ) : (
                                  item.earnedValueFixedBelowBasic
                                )}
                              </TableCell>
                              {salaryConfigKey ===
                                SalaryCalculationConfig.GROSS && (
                                <TableCell
                                  className="salary-structure__frame--rightblock--tableblock--table--actions"
                                  id="td-center"
                                >
                                  <Checkbox
                                    checked={item.remaining_balance}
                                    name={cellName.REMAINING_BALANCE}
                                    disabled={checkDisable(item.id)}
                                    onClick={(event) => {
                                      if (
                                        operation === Operation.CREATE ||
                                        operation === Operation.UPDATE
                                      )
                                        handleSalaryStructureCheckBox(
                                          event,
                                          item.id
                                        );
                                    }}
                                  />
                                </TableCell>
                              )}
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--actions"
                                id="td-center"
                              >
                                <>
                                  <Checkbox
                                    checked={item.rule_applied}
                                    name={cellName.RULE_ON_BASIC_SALARY}
                                    onClick={(event) => {
                                      if (
                                        operation === Operation.CREATE ||
                                        operation === Operation.UPDATE
                                      )
                                        handleSalaryStructureCheckBox(
                                          event,
                                          item.id
                                        );
                                    }}
                                  />

                                  <CustomTooltip
                                    title={
                                      item.ledger_rule.length > 0
                                        ? item.ledger_rule
                                            .split(",")
                                            ?.map((data, index) => {
                                              return (
                                                <React.Fragment key={index}>
                                                  <span key={index}>
                                                    {data}
                                                  </span>
                                                  <br />
                                                </React.Fragment>
                                              );
                                            })
                                        : EMPTY_STRING
                                    }
                                    arrow
                                    placement="right"
                                    slotProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: TOOLTIP_COLORS.VARIABLE,
                                          "& .MuiTooltip-arrow": {
                                            color: TOOLTIP_COLORS.VARIABLE,
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    <span
                                      onClick={() => {
                                        if (
                                          operation === Operation.CREATE ||
                                          operation === Operation.UPDATE
                                        )
                                          editExpressionString(item);
                                      }}
                                    >
                                      {item.ledger_rule}
                                    </span>
                                  </CustomTooltip>
                                </>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="salary-structure__frame--rightblock--tableblock">
                <Title variant="subtitle1">
                  {HRTitles.SalaryStructure.Titles.map(
                    (title: HRTitleProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          {title.Deduction_Details}
                        </React.Fragment>
                      );
                    }
                  )}
                </Title>
                <TableContainer className="salary-structure__frame--rightblock--tableblock--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {HR_Table.SalaryStructure.Deduction.Table_Headers.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index} className={th.className}>
                                {th.labelName}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {overAllSalaryLedger
                        .filter(
                          ({ isChecked, ledger_type }) =>
                            isChecked &&
                            ledger_type === SalaryLedgerType.DEDUCTION
                        )
                        .map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--slno"
                                id="td-center"
                              >
                                <Checkbox
                                  checked={item.isChecked ?? false}
                                  onClick={(event) => {
                                    if (
                                      operation === Operation.CREATE ||
                                      operation === Operation.UPDATE
                                    ) {
                                      handleOverAllLedgers(event, item.id);
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell>{item.ledger_desc}</TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--actions"
                                id="td-center"
                              >
                                <Checkbox
                                  checked={item.ledger_udvMonthly}
                                  name={cellName.UDV}
                                  onClick={(event) => {
                                    if (
                                      operation === Operation.CREATE ||
                                      operation === Operation.UPDATE
                                    ) {
                                      handleSalaryStructureCheckBox(
                                        event,
                                        item.id
                                      );
                                    }
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--select"
                                onClick={() => {
                                  if (
                                    operation === Operation.CREATE ||
                                    operation === Operation.UPDATE
                                  )
                                    enableField(
                                      item.id,
                                      cellEditType.APPLY_ON_HEADS
                                    );
                                }}
                              >
                                {inEditMode.rowKey === item.id &&
                                inEditMode.status &&
                                inEditMode.cellEditKey ===
                                  cellEditType.APPLY_ON_HEADS ? (
                                  <LabeledAutocomplete
                                    className={labelClasses.inputRoot}
                                    options={applyOnHeadsOptions}
                                    openOnFocus
                                    freeSolo
                                    value={applyOnHeadsOptions?.find(
                                      ({ value }) =>
                                        value === item.ledger_applied_to
                                    )}
                                    autoHighlight
                                    renderOption={(props, option) => {
                                      return (
                                        <li
                                          {...props}
                                          key={(option as applyHeadsOnOptions).value}
                                          onClick={() => {
                                            if ((option as applyHeadsOnOptions).value !== null) {
                                              if (
                                                (option as applyHeadsOnOptions).value ===
                                                applyHeadsOn.ON_GROUP_OF_LEDGERS
                                              ) {
                                                if (item?.ledger_rule) {
                                                  const index = getIndex(
                                                    item.id,
                                                    overAllSalaryLedger
                                                  );

                                                  overAllSalaryLedger[
                                                    index
                                                  ]?.groupLedgers!?.map(
                                                    (data) =>
                                                      (overAllSalaryLedger[
                                                        getIndex(
                                                          data,
                                                          overAllSalaryLedger
                                                        )
                                                      ].selectedForDeductions =
                                                        true)
                                                  );
                                                }
                                                item.ledger_applied_to =
                                                  (option as applyHeadsOnOptions).value;
                                                setChooseEarningLedger({
                                                  flag: true,
                                                  id: item.id,
                                                });
                                              } else if (
                                                item.ledger_rule &&
                                                item.ledger_applied_to ===
                                                  (option as applyHeadsOnOptions).value
                                              ) {
                                                item.ledger_applied_to =
                                                  (option as applyHeadsOnOptions).value;
                                                editExpressionString(item);
                                              } else {
                                                setCalculationMethod({
                                                  flag: true,
                                                  id: item.id,
                                                  applyOnHeads: (option as applyHeadsOnOptions).value,
                                                });
                                              }
                                            }
                                          }}
                                        >
                                          {(option as applyHeadsOnOptions).label}
                                        </li>
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className={labelClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                ) : item.ledger_rule ? (
                                  applyOnHeadsOptions?.find(
                                    ({ value }) =>
                                      value === item.ledger_applied_to
                                  )?.label!
                                ) : (
                                  EMPTY_STRING
                                )}
                              </TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--input"
                                id="td-right"
                                onClick={() => {
                                  if (
                                    item.ledger_applied_to !== null &&
                                    item.ledger_rule &&
                                    (operation === Operation.CREATE ||
                                      operation === Operation.UPDATE)
                                  ) {
                                    setInEditMode({
                                      rowKey: item.id,
                                      status: true,
                                      cellEditKey:
                                        cellEditType.CALL_TO_MAX_VALUE,
                                    });
                                  }
                                }}
                              >
                                {item.ledger_udvMonthly === false &&
                                inEditMode.rowKey === item.id &&
                                inEditMode.status &&
                                inEditMode.cellEditKey ===
                                  cellEditType.CALL_TO_MAX_VALUE ? (
                                  <Input
                                    value={callToMaxValue}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      setCallToMaxValue(Number(e.target.value))
                                    }
                                    autoFocus
                                    onFocus={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      e.target?.select();
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent) => {
                                      if (e.key === Keys.ENTER) {
                                        const indexFormOverAllSalary = getIndex(
                                          item.id,
                                          overAllSalaryLedger
                                        );
                                        handleEdit(item.id);
                                        if (callToMaxValue > 0)
                                          overAllSalaryLedger[
                                            indexFormOverAllSalary
                                          ].deductions =
                                            findEarningsForExpressionString(
                                              item.ledger_rule,
                                              callToMaxValue,
                                              salRoundOffKey
                                            );

                                        if (callToMaxValue === 0)
                                          chooseDeductionsBasedOnAppliedHeads(
                                            item.ledger_applied_to,
                                            item.id
                                          );

                                        setOverAllSalaryLedger(
                                          overAllSalaryLedger
                                        );
                                        // if (basicSalary > 0 && netSalary > 0) {
                                        //   changeBasicSalaryThroughCTC();
                                        // }
                                      }
                                    }}
                                  />
                                ) : (
                                  item.callToMaxValue
                                )}
                              </TableCell>
                              <TableCell
                                className="salary-structure__frame--rightblock--tableblock--table--deduction"
                                id="td-center"
                                onClick={() => {
                                  if (
                                    item.ledger_rule &&
                                    (operation === Operation.CREATE ||
                                      operation === Operation.UPDATE)
                                  )
                                    editExpressionString(item);
                                }}
                              >
                                {item.ledger_udvMonthly === false
                                  ? item.ledger_rule
                                  : null}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
        </div>
        {operation === Operation.CREATE ? (
          <>
            <Button
              mode="save"
              onClick={handleSubmit}
              disabled={
                overAllSalaryLedger?.filter(
                  ({ isChecked, earnings, deductions, ledger_udvMonthly }) =>
                    isChecked &&
                    (earnings > 0 || deductions > 0 || ledger_udvMonthly)
                ).length === 0 && salaryTemplateName === EMPTY_STRING
              }
            />
            <Button
              mode="update"
              onClick={() => {
                handleClear();
                setOperation(Operation.UPDATE);
              }}
            />
            <Button mode="view" onClick={() => setOperation(Operation.VIEW)} />
            <Button
              mode="delete"
              onClick={() => {
                handleClear();
                setOperation(Operation.DELETE);
              }}
            />
            <Button mode="clear" onClick={handleClear} />
          </>
        ) : operation === Operation.DELETE ? (
          <>
            <Button
              mode="delete"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </>
        ) : operation === Operation.UPDATE ? (
          <Button mode="save" onClick={handleSubmit} />
        ) : null}
        {operation !== Operation.CREATE ? (
          <Button
            mode="back"
            onClick={() => {
              handleClear();
              setOperation(Operation.CREATE);
              salaryStructureRef.focus();
            }}
          />
        ) : null}
      </div>
      {/* Ledger-List */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={ledgerList}
        style={ChooseLedgerModalStyles}
        ariaHideApp={false}
        onRequestClose={() => setLedgerList(!ledgerList)}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Choose Ledgers</Title>
            <div className="row g-0 salary-structure__choose-ledger">
              <div className="col h-100">
                <TableContainer className="salary-structure__choose-ledger--table">
                  <Table stickyHeader>
                    <ChooseLedgersTableHead
                      ledgerSelected={
                        overAllSalaryLedger.filter(
                          (d) =>
                            d.isChecked &&
                            d.sal_ldgr_is_basic === false &&
                            d.ledger_type === SalaryLedgerType.EARNING
                        ).length
                      }
                      type={SalaryLedgerType.EARNING}
                      ledgerRowCount={
                        overAllSalaryLedger.filter(
                          (d) =>
                            d.ledger_type === SalaryLedgerType.EARNING &&
                            d.sal_ldgr_is_basic === false
                        ).length
                      }
                      overAllSalaryLedgers={overAllSalaryLedger}
                      setOverAllSalaryLedgers={setOverAllSalaryLedger}
                    />
                    <TableBody>
                      {overAllSalaryLedger
                        .filter(
                          (d) =>
                            d.ledger_type === SalaryLedgerType.EARNING &&
                            d.sal_ldgr_is_basic === false
                        )
                        .map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell padding="checkbox" id="td-center">
                                <Checkbox
                                  checked={data.isChecked ?? false}
                                  autoFocus={index === 0}
                                  onClick={(event) =>
                                    handleOverAllLedgers(event, data.id)
                                  }
                                />
                              </TableCell>
                              <TableCell> {data.ledger_desc}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="col h-100">
                <TableContainer className="salary-structure__choose-ledger--table">
                  <Table stickyHeader>
                    <ChooseLedgersTableHead
                      ledgerSelected={
                        overAllSalaryLedger.filter(
                          (d) =>
                            d.isChecked &&
                            d.ledger_type === SalaryLedgerType.DEDUCTION
                        ).length
                      }
                      type={SalaryLedgerType.DEDUCTION}
                      ledgerRowCount={
                        overAllSalaryLedger.filter(
                          (d) => d.ledger_type === SalaryLedgerType.DEDUCTION
                        ).length
                      }
                      overAllSalaryLedgers={overAllSalaryLedger}
                      setOverAllSalaryLedgers={setOverAllSalaryLedger}
                    />
                    <TableBody>
                      {overAllSalaryLedger
                        .filter(
                          (d) => d.ledger_type === SalaryLedgerType.DEDUCTION
                        )
                        .map((deductiondata, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell padding="checkbox" id="td-center">
                                <Checkbox
                                  checked={deductiondata.isChecked ?? false}
                                  onClick={(event) =>
                                    handleOverAllLedgers(
                                      event,
                                      deductiondata.id
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>{deductiondata.ledger_desc}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <Button mode="save" />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setLedgerList(!ledgerList);
              }}
            />
          </div>
        </div>
      </Modal>

      {/* Calculation-Method */}

      <CalculationModal
        calculationMethodModal={calculationMethod!}
        incomeCalculatorItems={incomeCalculatorItems}
        setIncomeCalculatorItems={setIncomeCalculatorItems}
        closeMethod={calculationMethodCancel}
        saveMethod={setRuleonBasicSalary}
        ledgerDesc={
          overAllSalaryLedger?.find(
            ({ id: value }) => value === calculationMethod?.id
          )?.ledger_desc!
        }
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={chooseEarningLedger?.flag!}
        style={ChooseLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Choose Earning Ledgers</Title>

            <TableContainer className="salary-structure__frame--rightblock--tableblock__choose_ledger">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {HR_Table.SalaryStructure.ChooseLedger.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {overAllSalaryLedger
                    .filter(
                      (f) =>
                        f.isChecked &&
                        f.ledger_type === SalaryLedgerType.EARNING
                    )
                    .map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              checked={item.selectedForDeductions ?? false}
                              name="selectedForDeductions"
                              onClick={(event) =>
                                handleSalaryStructureCheckBox(event, item.id)
                              }
                            />
                          </TableCell>
                          <TableCell>{item.ledger_desc}</TableCell>
                          <TableCell>{item.earnings}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button mode="save" onClick={saveChooseLedgers} />
            <Button
              mode="cancel"
              onClick={() => {
                setChooseEarningLedger({
                  flag: false,
                  id: 0,
                });
              }}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setChooseEarningLedger({
                  flag: false,
                  id: 0,
                });
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={creationLoading ?? deletionLoading ?? updationLoading}
      />

      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={salaryStructureId?.value!}
      />
    </>
  );
};

export default Index;

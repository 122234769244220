import {
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import Edit from "../../../../images/EditProfile.svg";
import InputHoc from "../../../../components/common/Input/Index";
import Delete from "../../../../images/Delete.svg";
import {
  CURRENT_PAGE,
  DEBOUNCE_TIME_INTERVAL,
  EMPTY_STRING,
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../../utils/constants";
import { TablePaginationActions } from "../../../../pages/CustomTablePagination";
import {
  debounce,
  defaultLabelDisplayedRows,
  handleFormEvent,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { TransportTitleProps } from "../../../../Types/Titles";
import { LabelNameProps } from "../../../../Types/Labels";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddTransportRouteMaster,
  DeleteTransportRouteMasterById,
  UpdateTransportRouteMaster,
} from "../../queries/mutations";
import { Form, Formik } from "formik";
import { RouteType } from "../../FormTypes";
import { RouteValidation } from "../../../../utils/validationRules";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import { GetTransportRouteMasters, RouteNode } from "../../queries/list";

import DeleteModal from "../../../../pages/DeleteModal";
import LoadingModal from "../../../../pages/LoadingModal";
import useTransportRoutesData from "../../../../customhooks/general/useTransportRoutesData";

import { AntSwitch } from "../../../../pages/Switch";
import { RouteByIdData, RouteByIdVars } from "../../paginationTypes";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useTransportTableJson from "../../json/useTransportTableJson";

const { Transport_Title } = require("../../json/title.json");
const { Transport_FormLabels } = require("../../json/form.json");
interface Props {
  pageType: PageFor;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
const Index = ({ pageType, modalFlag, setModalFlag }: Props) => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const { token } = useToken();
  const { Transport_Table } = useTransportTableJson();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const [sortBy] = useState(SortBy.ROUTE_DESC);
  const [direction] = useState(Direction.ASC);
  const [searchRoute, setSearchRoute] = useState("");
  const [routeId, setRouteId] = useState(0);
  const [operation, setOperation] = useState(Operation.CREATE);

  const { routesData } = useTransportRoutesData(searchRoute, false);

  const routeDescRef = document.getElementsByName(
    "route_desc"
  )[0] as HTMLInputElement;

  const [AddRoute, { loading: creationLoading }] = useMutation(
    AddTransportRouteMaster,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
      fetchPolicy: "network-only",
    }
  );
  const [UpdateRoute, { loading: updationLoading }] = useMutation(
    UpdateTransportRouteMaster,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
      fetchPolicy: "network-only",
    }
  );
  const [DeleteRoute] = useMutation(DeleteTransportRouteMasterById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [GetRoute, { data: RoutesData, loading: RoutesLoading }] = useLazyQuery<
    RouteByIdData,
    RouteByIdVars
  >(RouteNode);
  const [formData, setFormData] = useState<RouteType>({
    route_desc: "",
    route_st_date: "",
    route_is_active: false,
  });
  const totalCount = routesData?.data?.GetTransportRouteMasters?.totalCount;
  const endCursor =
    routesData.data?.GetTransportRouteMasters?.pageInfo?.endCursor;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = routesData.data
    ? routesData.data.GetTransportRouteMasters.edges.slice(
        indexOfFirstrepo,
        indexOfLastrepo
      )
    : [];
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTimeout(() => {
      setPage(newPage);
      updateDebounceText();
    }, DEBOUNCE_TIME_INTERVAL);
  };
  const updateDebounceText = debounce(() => {
    handleMore();
  });
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleMore = () => {
    routesData.fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.GetTransportRouteMasters.edges = [
          ...prevResult.GetTransportRouteMasters.edges,
          ...fetchMoreResult.GetTransportRouteMasters.edges,
        ];

        return fetchMoreResult;
      },
    });
  };
  const HandleSubmit = () => {
    const input = {
      route_desc: removeMoreSpace(formData.route_desc),
      route_st_date: toIsoDate(formData.route_st_date),
      route_is_active: formData.route_is_active,
    };
    if (operation === Operation.CREATE) {
      AddRoute({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input: {
            route_desc: removeMoreSpace(formData.route_desc),
            route_st_date: toIsoDate(formData.route_st_date),
          },
        },
        refetchQueries: [
          {
            query: GetTransportRouteMasters,
            variables: {
              token,
              inst_id: InstId!,
              first: rowsPerPage,
              sortBy,
              direction,
              name: EMPTY_STRING,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (pageType === PageFor.MODAL) {
            setModalFlag?.(!modalFlag);
          }
          setMessage({
            flag: !message.flag,
            message: "Route Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      UpdateRoute({
        variables: {
          token,
          id: routeId,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetTransportRouteMasters,
            variables: {
              token,
              inst_id: InstId!,
              first: rowsPerPage,
              sortBy,
              direction,
              name: EMPTY_STRING,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Route Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: number) => {
    DeleteRoute({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetTransportRouteMasters,
          variables: {
            token,
            inst_id: InstId!,
            first: rowsPerPage,
            sortBy,
            direction,
            name: EMPTY_STRING,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Route Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleClear = () => {
    setFormData({
      route_desc: "",
      route_st_date: "",
      route_is_active: false,
    });
    routeDescRef?.focus();
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (RoutesData && !RoutesLoading && operation === Operation.UPDATE) {
      const { route_desc, route_st_date, route_is_active } =
        RoutesData.nodes[0];
      setFormData({
        route_desc: route_desc,
        route_st_date: toInputStandardDate(route_st_date),
        route_is_active: route_is_active,
      });
    }
  }, [RoutesData, RoutesLoading, operation]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>
        {Transport_Title.MasterData.Route.map(
          (title: TransportTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Title}</React.Fragment>;
          }
        )}
      </Title>

      <Formik
        initialValues={formData}
        validationSchema={RouteValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <>
              <Form className="transport-route">
                <div className="row g-0 transport-route__details">
                  <div className="col booktype-left">
                    <div className="transport-route__details--add">
                      <Title variant="subtitle1">
                        {Transport_Title.MasterData.Route.map(
                          (title: TransportTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {operation === Operation.UPDATE
                                  ? title.Update
                                  : title.Add}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                      {Transport_FormLabels.MasterData.Route.map(
                        (label: LabelNameProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              <InputHoc
                                name={label.inputName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                values={formData[label.inputName]}
                                type={label.dataType}
                                required={label.required}
                                LabelName={label.LabelName}
                                autoFocus={label.autoFocus}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                      {operation === Operation.UPDATE && (
                        <FormGroup>
                          <FormControlLabel
                            label="Status"
                            onKeyDown={handleFormEvent}
                            control={
                              <AntSwitch
                                checked={
                                  formData.route_is_active ? true : false
                                }
                                onClick={(e: React.MouseEvent) => {
                                  setFormData({
                                    ...formData,
                                    route_is_active: (
                                      e.target as HTMLInputElement
                                    ).checked,
                                  });
                                }}
                              />
                            }
                            labelPlacement="start"
                          />
                        </FormGroup>
                      )}
                    </div>
                  </div>
                  <div className="col booktype-right">
                    <div className="transport-route__details--list">
                      <div className="row g-0 transport-route__details--title">
                        <div className="col-3"></div>
                        <div className="col">
                          <Title variant="subtitle1">
                            {Transport_Title.MasterData.Route.map(
                              (
                                title: TransportTitleProps,
                                index: React.Key
                              ) => {
                                return (
                                  <React.Fragment key={index}>
                                    {title.List}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </Title>
                        </div>

                        <div className="col-3">
                          <Input
                            id="search"
                            placeholder="Search..."
                            onChange={(e) => setSearchRoute(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="transport-route__details--tableblock">
                        <TableContainer className="transport-route__details--table">
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                {Transport_Table.MasterData.Route.map(
                                  (th: TableHeaderProps, index: React.Key) => {
                                    return (
                                      <TableCell
                                        key={index}
                                        className={th.className}
                                      >
                                        {th.labelName}
                                      </TableCell>
                                    );
                                  }
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {currentRepo &&
                                currentRepo &&
                                (rowsPerPage > 0 &&
                                currentRepo.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                ).length > 0
                                  ? currentRepo.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : currentRepo
                                ).map((response, index: number) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell
                                        id="td-center"
                                        className="transport-route__details--table--slno"
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell>
                                        {response.node.route_desc}
                                      </TableCell>
                                      <TableCell className="transport-route__details--table--date">
                                        {toStandardDate(
                                          response.node.route_st_date
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={
                                          response.node.route_is_active
                                            ? "transport-route__details--table--status font-green"
                                            : "transport-route__details--table--status font-red"
                                        }
                                        id="td-center"
                                      >
                                        {response.node.route_is_active
                                          ? "Active"
                                          : "Inactive"}
                                      </TableCell>
                                      <TableCell
                                        id="td-center"
                                        className="transport-route__details--table--actions"
                                      >
                                        <img
                                          src={Edit}
                                          alt="/"
                                          onClick={() => {
                                            GetRoute({
                                              variables: {
                                                token,
                                                ids: response.node.id,
                                              },
                                            }).then(({ data }) => {
                                              if (data) {
                                                setRouteId(response.node.id);
                                                setOperation(Operation.UPDATE);
                                                routeDescRef?.focus();
                                              }
                                            });
                                          }}
                                        />
                                        <img
                                          src={Delete}
                                          alt="/"
                                          onClick={() => {
                                            setRouteId(response.node.id);
                                            setOperation(Operation.DELETE);
                                            setDeleteModal(!deleteModal);
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TablePagination
                                  rowsPerPageOptions={PAGINATION_ARRAY}
                                  count={totalCount ? totalCount : 0}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                  ActionsComponent={TablePaginationActions}
                                  labelDisplayedRows={defaultLabelDisplayedRows}
                                  labelRowsPerPage={TABLE_DATA_PER_PAGE}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </div>
                <Button mode="save" />
                <Button mode="clear" type="button" onClick={handleClear} />
                {pageType === PageFor.GENERAL ? (
                  <Button mode="back" onClick={() => navigate(-1)} />
                ) : null}
              </Form>
            </>
          );
        }}
      </Formik>
      <LoadingModal flag={creationLoading || updationLoading} />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={routeId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const PayRollEmpMasterNode = gql`
  query PayRollEmpMaster($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollEmpMaster {
        emp_id
        emp_title
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_dob
        emp_sex
        emp_mobile
        emp_email
        emp_qualification
        emp_experience
        emp_status
        emp_on_roll
        emp_doj
        emp_dor
        emp_idx
        emp_dept_idx
        emp_type
        emp_profile_filename
        pr_grade_id
        pr_dept_id
        pr_job_type_id
        pr_category_id
        pr_designation_id
        parent_designation_id
        reporting_to_emp_id
        other_details {
          id
          emp_aadhar
          emp_pan
          emp_bank_acct_no
          emp_bank_name
          emp_bank_ifsc
          emp_epf_no
          emp_esi_no
          emp_esi_no
          emp_biometric_id
          emp_esi_dispen
          emp_uan_no
          emp_passport
          emp_drv_lic
          emp_voter_id
          emp_biometric_machine_id
          pr_emp_id
        }
        academics_prof_details {
          id
          emp_teaching_experience
          consultany_service
          certification_no
          research_papers_published
          patents_no
          books_Authored_no
          workshops_no
          projects_no
          awards_no
          resource_person
          remarks
        }
        personal_details {
          id
          emp_father_name
          emp_mother_name
          emp_spouse_name
          emp_blood_grp
          emp_religion
          emp_caste
          emp_tel
          emp_corr_address
          emp_corr_city
          emp_corr_pin
          emp_corr_district
          emp_corr_state
          emp_tel
          emp_perm_address
          emp_perm_city
          emp_perm_pin
          emp_perm_district
          emp_perm_state
          emp_nationality
          emp_martial
        }
        pr_dept_details {
          id
          dept_desc
          dept_idx
          inst_id
        }
        pr_grade_details {
          id
          grade_desc
        }
        pr_designation_details {
          id
          designation_desc
          designation_idx
          inst_id
        }
        pr_category_details {
          id
          category_desc
          category_idx
          inst_id
        }
        pr_job_type_details {
          id
          job_type_desc
          job_type_idx
          inst_id
        }
      }
    }
  }
`;
export const LoginEmpMasterNode = gql`
  query LoginEmpMasterNode($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollEmpMaster {
        emp_id
        emp_title
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_dob
        emp_sex
        emp_mobile
        emp_email
        emp_qualification
        emp_experience
        emp_status
        emp_on_roll
        emp_doj
        emp_type
        emp_dor
        emp_idx
        emp_dept_idx
        emp_profile_filename
        pr_grade_id
        pr_dept_id
        pr_job_type_id
        pr_category_id
        pr_designation_id
        parent_designation_id
        reporting_to_emp_id
        pr_dept_details {
          id
          dept_desc
          dept_idx
          inst_id
        }
        pr_grade_details {
          id
          grade_desc
        }
        pr_designation_details {
          id
          designation_desc
          designation_idx
          inst_id
        }
        pr_category_details {
          id
          category_desc
          category_idx
          inst_id
        }
        pr_job_type_details {
          id
          job_type_desc
          job_type_idx
          inst_id
        }
        reporting_manager_details {
          id
          emp_last_name
          emp_first_name
          emp_middle_name
        }
      }
    }
  }
`;
export const PayRollEmpMasterNodeForLogin = gql`
  query PayRollEmpMaster($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollEmpMaster {
        id
        emp_first_name
        emp_middle_name
        emp_last_name
      }
    }
  }
`;
export const GetPayRollEmp = gql`
  query GetPayRollEmp(
    $token: String!
    $first: Int
    $after: Cursor
    $sortBy: PayRollEmpMasterOrderField
    $direction: OrderDirection!
    $input: PrEmpQueryTypeData!
    $name: String
    $per_std_subj_allocation: Boolean!
    $departmentId: ID
    $designationId: ID
    $categoryId: ID
    $gradeId: ID
    $jobTypeId: ID
    $employeeId: ID
  ) {
    GetPayRollEmp(
      token: $token
      input: $input
      first: $first
      after: $after
      per_std_subj_allocation: $per_std_subj_allocation
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            empFirstNameContainsFold: $name
            prDeptID: $departmentId
            prDesignationID: $designationId
            prCategoryID: $categoryId
            prGradeID: $gradeId
            prJobTypeID: $jobTypeId
            id: $employeeId
          }
          {
            empMiddleNameContainsFold: $name
            prDeptID: $departmentId
            prDesignationID: $designationId
            prCategoryID: $categoryId
            prGradeID: $gradeId
            prJobTypeID: $jobTypeId
            id: $employeeId
          }
          {
            empLastNameContainsFold: $name
            prDeptID: $departmentId
            prDesignationID: $designationId
            prCategoryID: $categoryId
            prGradeID: $gradeId
            prJobTypeID: $jobTypeId
            id: $employeeId
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          emp_id
          emp_title
          emp_first_name
          emp_middle_name
          emp_last_name
          emp_sex
          emp_dob
          emp_mobile
          emp_email
          emp_qualification
          emp_experience
          emp_status
          emp_on_roll
          is_login_created
          emp_doj
          emp_dor
          emp_type
          # emp_lsd
          emp_idx
          emp_dept_idx
          other_details {
            id
            emp_aadhar
            emp_pan
            emp_bank_acct_no
            emp_bank_name
            emp_bank_ifsc
            emp_epf_no
            emp_esi_no
            emp_biometric_id
            emp_esi_dispen
            emp_uan_no
            emp_passport
            emp_drv_lic
            emp_voter_id
            emp_biometric_machine_id
          }
          personal_details {
            id
            emp_father_name
            emp_mother_name
            emp_spouse_name
            emp_blood_grp
            emp_religion
            emp_caste
            emp_tel
            emp_corr_address
            emp_corr_city
            emp_corr_pin
            emp_corr_district
            emp_corr_state
            emp_perm_address
            emp_perm_city
            emp_perm_pin
            emp_perm_district
            emp_perm_state
            emp_nationality
            emp_martial
          }
          pr_category_details {
            id
            category_desc
            category_idx
          }
          pr_dept_details {
            id
            dept_desc
            dept_idx
          }
          pr_designation_details {
            id
            designation_desc
            designation_idx
          }
          pr_job_type_details {
            id
            job_type_desc
            job_type_idx
            inst_id
          }
          pr_grade_details {
            id
            grade_desc
            inst_id
          }
          reporting_manager_details {
            id
            emp_last_name
            emp_first_name
            emp_middle_name
          }
          # leave_master_details {
          #   leave_details {
          #     leave_ldgr_details {
          #       id
          #       leave_desc
          #     }
          #   }
          # }
        }
      }
    }
  }
`;

export const GetPayRollEmpSalaryStruture = gql`
  query GetPayRollEmpSalaryStruture(
    $token: String!
    $inst_id: ID!
    $pr_emp_id: ID!
  ) {
    GetPayRollEmpSalaryStruture(
      token: $token
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
    ) {
      emp_sal_master_details {
        id
        emp_sal_str_basic
        emp_sal_str_total_earnings
        emp_sal_str_total_deductions
        emp_sal_str_net
        emp_sal_str_net_is_roundoff
        pr_emp_id
        pr_sal_str_master_id
      }
      emp_sal_details {
        id
        emp_sal_sl
        emp_sal_ldgr_value
        emp_sal_ldgr_sl
        emp_sal_earn_ded
        emp_sal_earn_type
        emp_sal_earn_flat_rate
        emp_sal_earn_is_UDM
        emp_sal_earn_UDM_val
        emp_sal_earn_restrict_to
        emp_sal_earn_to_min_basic
        emp_sal_earn_rule_apply
        emp_sal_earn_rule
        emp_sal_ded_is_UDM
        emp_sal_ded_UDM_value
        emp_sal_group_id_heads
        emp_sal_ded_is_applied_on_heads
        emp_sal_ded_on_heads
        emp_sal_ded_to_max_value
        emp_sal_ded_rule
        emp_sal_remaining_amt_rule
        pr_emp_sal_str_master_id
        pr_sal_ldgr_id
        sal_ldgr_details {
          id
          sal_ldgr_desc
          sal_ldgr_short_desc
          sal_ldgr_idx
          sal_ldgr_earn_deduct
          sal_ldgr_type
          sal_ldgr_is_system_generated
          sal_ldgr_is_active
          sal_ldgr_round_to_next_int
          sal_ldgr_is_basic
          sal_ldgr_remove_decimal
          inst_id
        }
      }
    }
  }
`;

export const GetPayRollEmpId = gql`
  query GetPayRollEmpId($token: String!, $inst_id: ID!) {
    GetPayRollEmpId(token: $token, inst_id: $inst_id)
  }
`;

export const ValidateEmpDataForImport = gql`
  query ValidateEmpDataForImport(
    $token: String!
    $inst_id: ID!
    $input: ValidateEmpData!
  ) {
    ValidateEmpDataForImport(token: $token, inst_id: $inst_id, input: $input)
  }
`;

export const GetPayRollEmpForOrgChart = gql`
  query GetPayRollEmpForOrgChart(
    $token: String!
    $inst_id: ID!
    $pr_dept_id: ID!
    $pr_designation_ids: [ID!]!
    $pr_emp_id: ID!
  ) {
    GetPayRollEmpForOrgChart(
      token: $token
      inst_id: $inst_id
      pr_dept_id: $pr_dept_id
      pr_designation_ids: $pr_designation_ids
      pr_emp_id: $pr_emp_id
    ) {
      emp_first_name
      emp_middle_name
      emp_last_name
      id
      emp_id
      pr_designation_id
      pr_dept_id
      emp_profile_filename
      subordinate_count
      pr_designation_details {
        designation_desc
      }
    }
  }
`;

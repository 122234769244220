import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";
import { BookTransactionTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import Modal from "react-modal";

import Add from "../../../../images/Add.svg";
import Close from "../../../../images/Close.svg";
import Delete from "../../../../images/Delete.svg";
import EditProfile from "../../../../images/EditProfile.svg";

import { StudentModalStyles } from "../../../../styles/ModalStyles";
import StudentList from "../../../Master/Student/List";
import {
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import { TableHeaderProps } from "../../../../Types/Tables";
import ClassificationList from "./ClassificationList";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import { FormAutocomplete, formClasses } from "../../../../styles/AutocompleteStyles";
const { Library } = require("../../../../json/title.json");

const options = [
  { label: "DB001", id: 1 },
  { label: "DB002", id: 2 },
];

const Index = () => {
  const [classificationModal, setClassificationModal] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const { Library_Table } = useLibraryTableJson();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Library.BookTransaction.Titles.map(
          (title: BookTransactionTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.BookReservation}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="row g-0 book-reservation__date">
        <div className="col"></div>
        <div className="col-3">
          <div className="book-reservation__date--filter">
            <Label>Filter List Through Date :</Label>
            <Input type="checkbox" />
          </div>
        </div>
        <div className="col-3">
          <div className="book-reservation__date--textfield">
            <TextField
              type="date"
              className="book-reservation__textfield"
              label="From"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              defaultValue="2022-07-02"
            />
            <TextField
              type="date"
              className="book-reservation__textfield"
              label="To"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              defaultValue="2022-07-02"
            />
          </div>
        </div>
      </div>
      <div className="row g-0 book-reservation__frame">
        <div className="col booktype-left book-reservation__frame--data">
          <div className="book-reservation__frame--image-grid">
            <Label>Classification No.</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={options}
              value=""
              freeSolo
              forcePopupIcon
              // popupIcon={<img src={DownArrow} alt="/" />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => {}}
                  fullWidth
                  className={formClasses.formControlRoot}
                />
              )}
            />
            <CustomTooltip
              title="Choose From List"
              arrow
              placement="top"
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor: TOOLTIP_COLORS.VARIABLE,
                    "& .MuiTooltip-arrow": {
                      color: TOOLTIP_COLORS.VARIABLE,
                    },
                  },
                },
              }}
              className="tooltip-hide"
            >
              <img
                src={Add}
                alt="/"
                onClick={() => setClassificationModal(!classificationModal)}
                className="data-fetch-icon"
              />
            </CustomTooltip>
          </div>
          <div className="book-reservation__label-grid">
            <Label>Title</Label>
            <Input disabled />
            <Label>Publisher</Label>
            <Input disabled />
            <Label>Book Category</Label>
            <Input disabled />
          </div>

          <div className="row g-0">
            <div className="col book-reservation__frame--label-grid">
              <Label>Total No. of Copy</Label>
              <Input disabled />
            </div>
            <div className="col book-reservation__frame--label-grid">
              <Label>Circulation of Books</Label>
              <Input disabled />
            </div>
          </div>
          <div className="row g-0">
            <div className="col book-reservation__frame--label-grid">
              <Label>Damaged</Label>
              <Input disabled />
            </div>
            <div className="col book-reservation__frame--label-grid">
              <Label>Lost</Label>
              <Input disabled />
            </div>
          </div>
          <div className="row g-0">
            <div className="col-6 book-reservation__frame--label-grid">
              <Label>On Shelf</Label>
              <Input disabled />
            </div>
          </div>
          <hr className="dashed" />
          <div className="book-reservation__frame--image-grid">
            <Label>Student / Staff ID</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={options}
              value=""
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => {}}
                  fullWidth
                  className={formClasses.formControlRoot}
                />
              )}
            />
            <CustomTooltip
              title="Choose From List"
              arrow
              placement="top"
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor: TOOLTIP_COLORS.VARIABLE,
                    "& .MuiTooltip-arrow": {
                      color: TOOLTIP_COLORS.VARIABLE,
                    },
                  },
                },
              }}
              className="tooltip-hide"
            >
              <img
                src={Add}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
                className="data-fetch-icon"
              />
            </CustomTooltip>
          </div>
          <div className="book-reservation__label-grid">
            <Label>Name</Label>
            <Input disabled />
            <Label>Reservation Date</Label>
            <Input disabled type="date" />
          </div>
        </div>
        <div className="col booktype-right">
          <div className="row g-0">
            <div className="col-6">
              <Title>
                {Library.BookTransaction.Titles.map(
                  (title: BookTransactionTitleProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {title.ReservationList}
                      </React.Fragment>
                    );
                  }
                )}
              </Title>
            </div>
            <div className="col-6 book-reservation__frame--list-header">
              <Input
                id="search"
                type="text"
                placeholder="Enter Classification / Std ID"
              />
            </div>
          </div>
          <div className="book-reservation__tableblock">
            <TableContainer className="book-reservation__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Library_Table.BookTransaction.BookReservation.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      id="td-center"
                      className="book-reservation__table--slno"
                    >
                      1
                    </TableCell>
                    <TableCell className="book-reservation__table--clsno">
                      2012hg323
                    </TableCell>
                    <TableCell>computer science</TableCell>
                    <TableCell className="book-reservation__table--rsvid">
                      2012hg323
                    </TableCell>
                    <TableCell className="book-reservation__table--number">
                      56
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="book-reservation__table--actions"
                    >
                      <img src={EditProfile} alt="/" />

                      <img src={Delete} alt="/" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button mode="reserve-book" />
        <Button mode="clear" />
        <Button mode="back" />
      </div>

      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.LIBRARY}
              queryType={StudentReportType.GENERAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      {/* claasification modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={classificationModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ClassificationList />
            <Button
              mode="back"
              onClick={() => setClassificationModal(!classificationModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setClassificationModal(!classificationModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

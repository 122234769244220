import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";

import { responseType } from "../../../utils/Form.types";
import { FinyearByInstId } from "../../../queries/institution/financialyear/list/byid";
import { ListFinancialYearVars } from "../../Accounts/hooks/useActiveFinancialYear";
import { ListFinancialYearData } from "../../../Types/Accounting";
import { AppContext } from "../../../context/context";

const useFinancialYears = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const { state } = useContext(AppContext);
  const [GetFinYear, { data, loading, error }] = useLazyQuery<
    ListFinancialYearData,
    ListFinancialYearVars
  >(FinyearByInstId, {
    variables: { inst_id: InstId ?? (state.InstId as string), token },
  });
  useEffect(() => {
    if (token) {
      GetFinYear().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetFinYrsByInstId.map((finYear) => ({
              label: finYear.fin_yr,
              value: finYear.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [token, data, GetFinYear]);
  return { financialYearResponse: { data, loading, error, responseType } };
};

export default useFinancialYears;

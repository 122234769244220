import { gql } from "@apollo/client";

export const AddPayRollJobType = gql`
  mutation AddPayRollJobType(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollJobTypeInput!
  ) {
    AddPayRollJobType(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const DeletePayRollJobTypeById = gql`
  mutation DeletePayRollJobTypeById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollJobTypeById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdatePayRollJobType = gql`
  mutation UpdatePayRollJobType(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollJobTypeInput!
  ) {
    UpdatePayRollJobType(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

import { TextField } from "@mui/material";
import Avatar from "../../../../../images/Avatar.svg";
import usePayRollMastersConfig from "../../../hooks/usePayRollMastersConfig";

const EmployeeDetails = () => {
  const { USE_HR_DEPARTMENT, USE_HR_DESIGNATION, USE_HR_CATEGORY } =
    usePayRollMastersConfig();
  return (
    <>
      <div className="payroll-reports__payslip--details row g-0">
        <div className="col">
          <TextField
            label="Employee ID"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="EMP01"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
          {USE_HR_DESIGNATION ? (
            <TextField
              label="Designation"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value="lecturer"
              disabled
              className="payroll-reports__payslip--details--textfield"
            />
          ) : null}
          <TextField
            label="Permanent"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="2022-11"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
        </div>
        <div className="col">
          <TextField
            label="Name"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="Employee"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
          {USE_HR_DEPARTMENT ? (
            <TextField
              label="Department"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value="CS"
              disabled
              className="payroll-reports__payslip--details--textfield"
            />
          ) : null}
          <TextField
            label="Qualification"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="M-Tech"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
        </div>
        <div className="col">
          {USE_HR_CATEGORY ? (
            <TextField
              label="Category"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value="Teaching"
              disabled
              className="payroll-reports__payslip--details--textfield"
            />
          ) : null}
          <TextField
            label="Date of Joining"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="12-12-2022"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
          <TextField
            label="Year of Experience"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="1"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
        </div>
        <div className="col-1 payroll-reports__payslip--details--image">
          <img src={Avatar} alt="/" />
        </div>
      </div>
      <div className="row g-0 payroll-reports__payslip--details--options">
        <div className="col"></div>
        <div className="col">
          <TextField
            type="month"
            label="Salary Process Month"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="2022-11"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
        </div>
        <div className="col">
          <TextField
            label="Month Days"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="30"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
        </div>
        <div className="col">
          <TextField
            className="payroll-reports__payslip--details--textfield"
            label="Sunday's"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="4"
            disabled
          />
        </div>
        <div className="col">
          <TextField
            label="General Holiday's"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="0"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
        </div>
        <div className="col">
          <TextField
            label="Working Day's"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="26"
            disabled
            className="payroll-reports__payslip--details--textfield font-green"
          />
        </div>
        <div className="col">
          <TextField
            label="Worked Day's"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value="24"
            disabled
            className="payroll-reports__payslip--details--textfield"
          />
        </div>
        <div className="col"></div>
      </div>
    </>
  );
};

export default EmployeeDetails;

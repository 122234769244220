import React, { useContext, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";

import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { PageFor } from "../../../../utils/Enum.types";
import { StudentBookCount } from "../../../../queries/Library/BookIssue/list/byIds";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import {
  BookCountNodeData,
  BookCountNodeVars,
} from "../../../../Types/Library/BookIssue/paginationTypes";
import BooksHolding from "../../../../images/TotalBooks.svg";
import BooksOverDue from "../../../../images/BookOverDue.svg";
import Fine from "../../../../images/FineCollected.svg";
import BookDetails from "./Library/Index";
const LibraryDetails = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);

  const [bookDetails, setBookDetails] = useState(false);

  return (
    <>
      <div className="student-dashboard__frame--grids--title">
        <Title variant="subtitle1">Library Details</Title>
        <img src={More} alt="/" />
      </div>
      <div
        className="student-dashboard__library"
        onClick={() => setBookDetails(!bookDetails)}
      >
        <div className="student-dashboard__frame--flex">
          <div className="student-dashboard__frame--flex--title">
            <img src={BooksHolding} alt="" />
            Books Holding
          </div>
          <span className="student-dashboard__frame--flex--number">0</span>
        </div>
        <div className="student-dashboard__frame--flex">
          <div className="student-dashboard__frame--flex--title">
            <img src={BooksOverDue} alt="" />
            Books OverDue
          </div>
          <span className="student-dashboard__frame--flex--number">0</span>
        </div>
        <div className="student-dashboard__frame--flex">
          <div className="student-dashboard__frame--flex--title">
            <img src={Fine} alt="" />
            Books OverDue
          </div>
          <span className="student-dashboard__frame--flex--number font-red">
            ₹ 0
          </span>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookDetails}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {/* <BookReturn type={PageFor.MODAL} BorrowerType={UserType.STUDENT} /> */}
            <BookDetails
              pageType={PageFor.MODAL}
              setModalFlag={setBookDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setBookDetails(!bookDetails)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LibraryDetails;

import { styled } from "@mui/material/styles";
import { TEXT_FONT_FAMILY } from "./DataGridTableStyles";
import { Autocomplete } from "@mui/material";

const PREFIX = "MyAutocomplete";
export const labelClasses = {
  root: `${PREFIX}-root`,
  inputRoot: `${PREFIX}-inputRoot`,
  option: `${PREFIX}-option`,
  formControlRoot: `${PREFIX}-formControlRoot`,
  inputLabelRoot: `${PREFIX}-formControlRoot`,
};

// Use the `styled` API to create the root component with custom styles
export const LabeledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${labelClasses.inputRoot}`]: {
    color: "var(--text-color)",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: 14,

    "&:Mui-hover .MuiAutocomplete-root": {
      border: "1px solid var(--level-5)",
    },

   "& .MuiFormLabel-root":{
    zIndex:"8 !important"
   },

    "&.Mui-focused .MuiAutocomplete-root": {
      border: "1px solid var(--level-5)",
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      background: "var(--disabled-color)",
      cursor: "not-allowed",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--border-color) !important",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--level-3) !important",
    },

    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      fontSize: 12,
    },

    "& .MuiSvgIcon-root ": {
      height: 16,
      width: 16,
    },

    "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
      fontSize: "12px !important",
      fontFamily: TEXT_FONT_FAMILY,
    },
  },
  [`& .${labelClasses.formControlRoot}`]: {
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color)",
    borderRadius: "6px",
    fontSize: 12,
    "&.Mui-focused .MuiInputBase-formControl": {
      border: "1px solid var(--level-5)",
    },
    " &.hover": {
      border: "1px solid var(--level-5) !important",
    },

    "& label.Mui-focused": {
      color: "var(--text-color)",
    },
    "& .MuiInputBase-input": {
      height: "15px !important",
    },

    "& .MuiAutocomplete-root ": {
      padding: "0px !important",
    },

    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
  },
  [`& .${labelClasses.option}`]: {
    fontSize: 14,
    padding: theme.spacing(1),
  },
}));


export const requiredLabeledAutoComplete = styled(Autocomplete)(({theme}) => ({
  [`&.${labelClasses.inputRoot}`]: {
    color: "var(--text-color)",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: 14,
    padding: "6px !important",

    "&:Mui-hover .MuiAutocomplete-root": {
      border: "1px solid var(--level-5)",
    },

    "&.Mui-focused .MuiAutocomplete-root": {
      border: "1px solid var(--level-5)",
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      background: "var(--disabled-color)",
      cursor: "not-allowed",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--border-color) !important",
      borderRight: "3px solid red !important",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--level-3) !important",
    },

    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      fontSize: 12,
    },

    "& .MuiSvgIcon-root ": {
      height: 16,
      width: 16,
    },
    " & .MuiAutocomplete-endAdornment ": {
      backgroundColor: "var(--bg-color)",
    },
    "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
      fontSize: "12px !important",
      fontFamily: TEXT_FONT_FAMILY,
    },
  },
}));

import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";

import {
  ConfigurationsModalStyles,
  ImageModalStyles,
  ViewBookDetailsModalStyles,
} from "../../../styles/ModalStyles";

import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";

//Images

import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import Book from "../../../images/Book.svg";
import Settings from "../../../images/Settings.svg";

import useToken from "../../../customhooks/useToken";
import useBookEntryOptions from "../customHooks/useBookEntryOptions";

import { DeleteLibBookMasterById } from "../../../queries/Library/BookEntry/mutation/delete";
import { GetLibBookMaster } from "../../../queries/Library/BookEntry/list/byInstId";

//Modals
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import ConfigurationSettings from "../../../Modules/Master/configurations/general/Index";
import DamagedBooks from "../../../Modules/Library/BooksAllocation/RepairedBooks";
import Home from "../Home/Index";

import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import { BookStatus, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import {
  BookListType,
  BooksBy,
  BooksReportType,
  Direction,
  Operation,
  PageFor,
  PageNumbers,
  SortBy,
  TableHeaders,
  UserType,
  YesNo,
} from "../../../utils/Enum.types";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Close from "../../../images/Close.svg";
import { msgType } from "../../../utils/Form.types";
import { AppContext } from "../../../context/context";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../json/useLibraryTableJson";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { BookMasterEdge } from "../../../Types/Library/BookEntry/paginationTypes";
import { TableHeaderProps } from "../../../utils/types";
import { payloadTypes } from "../../../context/reducer";

interface Props {
  pageFor: PageFor;
  bookListType: BookListType;
  setBookListModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setClassBookModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({
  pageFor,
  bookListType,
  setClassBookModal,
  setBookListModal,
}: Props) => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { Library_Table } = useLibraryTableJson();
  const { dispatch } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [queryType, setQueryType] = useState(BooksReportType.GENERAL);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  // eslint-disable-next-line
  const [sortBy] = useState(SortBy.BOOK_TITLE);
  const [direction] = useState(Direction.ASC);
  const { token } = useToken();
  const [imageModal, setImageModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState(false);
  const [damagedBookModal, setDamagedBookModal] = useState(false);
  const [bookEntry, setBookEntry] = useState<BookMasterEdge[]>([]);
  const [searchData, setSearchData] = useState("");
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [BookId, setBookId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [deleteModal, setDeleteModal] = useState(false);

  //Mutations
  const [DeleteBookMaster] = useMutation(DeleteLibBookMasterById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  //customHooks

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_ENTRY_LIST_PAGE
  );
  const { bookEntries } = useBookEntryOptions(
    searchData,
    ROWS_PER_PAGE,
    queryType,
    pageFor === PageFor.GENERAL ? UserType.EMPLOYEE : BooksBy.ACCESS_NO
  );

  //Pagination Methods

  const HandleDelete = (id: number) => {
    DeleteBookMaster({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibBookMaster,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            sortBy,
            direction,
            name: "",
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Book entry deleted SuccessFully",
          flag: true,
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (pageFor === PageFor.GENERAL && bookListType === BookListType.DAMAGED) {
      setQueryType(BooksReportType.BOOKS_THAT_ARE_DAMAGED);
    }
    if (pageFor === PageFor.MODAL && bookListType === BookListType.ON_SHELF) {
      setQueryType(BooksReportType.BOOKS_ON_SHELF);
    }
    if (
      pageFor === PageFor.MODAL &&
      bookListType === BookListType.ISSUED_BY_CLASSIFICATION
    ) {
      setQueryType(BooksReportType.BOOKS_ISSUED_BY_CLASSIFICATION);
    }
    if (bookListType === BookListType.BY_CLASSIFICATION) {
      setQueryType(BooksReportType.BOOKS_BY_CLASSIFICATION);
    }
  }, [bookListType, pageFor]);

  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Books_Entry.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,

    ...(pageFor === PageFor.GENERAL &&
    bookListType === BookListType.GENERAL_LIST
      ? [
          {
            field: "actions",
            cellClassName: "td-status ",
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
            headerName: TableHeaders.ACTION,
            renderCell: (params: GridRenderCellParams) => {
              const bookId = params.row.bbok_id;

              return (
                <>
                  <img
                    src={EditProfile}
                    alt="/"
                    onClick={() => {
                      navigate(`/${InstId}/library/${bookId}/updatebookentry`);
                    }}
                  />
                  &nbsp;
                  <img
                    src={Delete}
                    alt="/"
                    onClick={() => {
                      setBookId(bookId);
                      setDeleteModal(!deleteModal);
                    }}
                  />
                </>
              );
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (bookEntries.queryData.data && !bookEntries.queryData.loading) {
      const newData = bookEntries.queryData.data.GetLibBookMaster.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newBook) => {
          const filteredStudent = bookEntry.find(
            (book) => book.node.id === newBook.node.id
          );
          if (filteredStudent) {
            return {
              ...newBook,
              node: {
                ...newBook.node,
              },
            };
          }
          return newBook;
        });
        setBookEntry(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            accession_no: node.book_access_no,
            title: node.classification.classification_desc,
            book_status: BookStatus.find(
              ({ value }) => value === node.book_status
            )?.label,
            publication: node.classification.publisher.publisher_name,
            authors: node.classification.author.author_name,
            subjects: node.classification.subject.subject_desc,
            reference: node.reference === true ? YesNo.YES : YesNo.NO,
            book_id: node.id,
          }))
        );
      } else {
        setBookEntry(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            accession_no: node.book_access_no,
            title: node.classification.classification_desc,
            book_status: BookStatus.find(
              ({ value }) => value === node.book_status
            )?.label,
            publication: node.classification.publisher.publisher_name,
            authors: node.classification.author.author_name,
            subjects: node.classification.subject.subject_desc,
            reference: node.reference === true ? YesNo.YES : YesNo.NO,
            book_id: node.id,
          }))
        );
      }
      setEndCursor(
        bookEntries.queryData.data.GetLibBookMaster.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [bookEntries.queryData.data, bookEntries.queryData.loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !bookEntries.queryData.loading) {
          bookEntries.queryData.fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetLibBookMaster.edges;
              const pageInfo = fetchMoreResult.GetLibBookMaster.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetLibBookMaster.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetLibBookMaster: {
                  edges: [...bookEntry, ...newEdges],
                  pageInfo,
                  totalCount: bookEntries.queryData.data
                    ? bookEntries.queryData.data.GetLibBookMaster.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      {pageFor === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {bookListType === BookListType.DAMAGED
              ? " List of Damaged Books"
              : "Book Entry"}
          </Title>
        </div>
        {USE_CONFIG_KEY &&
        pageFor === PageFor.GENERAL &&
        bookListType === BookListType.GENERAL_LIST ? (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        ) : null}
      </div>

      <div
        className={
          pageFor === PageFor.GENERAL
            ? "row g-0 book-entry"
            : "row g-0 book-entry--modal"
        }
      >
        <div className="col"></div>

        <div className="col-2 ">
          <Input
            id="search"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchData(e.target.value)
            }
            placeholder="Search ..."
            autoFocus
          />
        </div>

        {pageFor === PageFor.GENERAL &&
        bookListType === BookListType.GENERAL_LIST ? (
          <div className="col-1">
            <Button
              mode="addnew"
              autoFocus
              className="container__list--addnew"
              onClick={() => navigate(`/${InstId}/library/newbookentry`)}
            />
          </div>
        ) : null}
      </div>
      <div
        className={
          pageFor === PageFor.GENERAL
            ? "book-entry__tableblock"
            : "book-entry__tableblock--modal"
        }
      >
        {!rows?.length ? (
          <b className="nodata">Sorry, no results.</b>
        ) : (
          <div className={`h100 `}>
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              hideFooter
              onCellClick={(params) => {
                if (params.field === "title") {
                  dispatch({
                    type: payloadTypes.SET_BOOK_ID,
                    payload: {
                      bookId: params.row.book_id,
                    },
                  });
                  setClassBookModal?.(false);
                  setClassBookModal?.(false);

                  if (bookListType === BookListType.DAMAGED) {
                    setBookId(params.row.id);
                    setDamagedBookModal?.(!damagedBookModal);
                  }
                }
              }}
 
            />
          </div>
        )}
      </div>
      {pageFor === PageFor.GENERAL ? (
        <div className="button-left">
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      ) : (
        <Button mode="cancel" onClick={() => setBookListModal?.(false)} />
      )}

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_ENTRY_LIST_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>

      {/* DamagedBookModal  */}

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={damagedBookModal}
        style={ViewBookDetailsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DamagedBooks
              id={BookId}
              setAllocateToShelf={setDamagedBookModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDamagedBookModal(!damagedBookModal)}
            />
          </div>
        </div>
      </Modal>

      {/* image modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={imageModal}
        style={ImageModalStyles}
        onRequestClose={() => setImageModal(!imageModal)}
      >
        <img src={Book} alt="/" style={{ width: "100%", height: "100%" }} />
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={BookId}
      />
    </>
  );
};

export default Index;

import useInstLabels from "../../../customhooks/general/useInstLabels";

const useLibraryformJson = () => {
  const { departmentLabel, branchLabel, classLabel, semesterLabel } =
    useInstLabels();
  const tableJson = {
    LibraryFormLabels: {
      ModalBookDetails: [
        {
          LabelName: "Accesion No",
          inputName: "access_no",
        },
        {
          LabelName: "Publisher",
          inputName: "publisher",
        },
        {
          LabelName: "Volume",
          inputName: "volume",
        },
        {
          LabelName: "Subject",
          inputName: "subject",
        },
        {
          LabelName: "Status",
          inputName: "status",
        },
        {
          LabelName: "Title",
          inputName: "title",
        },
        {
          LabelName: "Book Category",
          inputName: "book_category",
        },
        {
          LabelName: "Author",
          inputName: "author",
        },
        {
          LabelName: "Edition",
          inputName: "edition",
        },
        {
          LabelName: "Previous Shelf",
          inputName: "last_shelf",
        },
        {
          LabelName: "Shelf",
          inputName: "shelf",
        },
        {
          LabelName: "Classification No.",
          inputName: "classification_no",
        },
      ],
      PreviousTransactionStudentDetails: [
        {
          LabelName: "Name",
          inputName: "name",
        },
        {
          LabelName: "Register No.",
          inputName: "reg_no",
        },
        {
          LabelName: "Admission No.",
          inputName: "adm_no",
        },
        {
          LabelName: `${departmentLabel}`,
          inputName: "department",
        },
        {
          LabelName: `${branchLabel}`,
          inputName: "branch",
        },
        {
          LabelName: `${classLabel}`,
          inputName: "class",
        },
        {
          LabelName: `${semesterLabel}`,
          inputName: "semester",
        },
        {
          LabelName: "Category",
          inputName: "category",
        },
      ],
      Media: {
        InputType: [
          {
            LabelName: "Media Desc",
            inputName: "media_desc",
            autoFocus: true,
            required: true,
          },
          {
            LabelName: "Abbreviation",
            inputName: "media_abbrevation",
            required: true,
          },
        ],
        CheckBoxType: [{ LabelName: "Status", inputName: "media_is_active" }],
      },
      MiscData: {
        InputType: [
          {
            LabelName: "Description",
            inputName: "activity_name",
            autoFocus: true,
            required: true,
          },
        ],
      },
      Department: {
        InputType: [
          {
            LabelName: "Department Desc",
            inputName: "dept_name",
            autoFocus: true,
            required: true,
          },
        ],
      },

      Subjects: {
        InputType: [
          {
            LabelName: "Subject Name",
            inputName: "subject_desc",
            autoFocus: true,
            required: true,
          },
          {
            LabelName: "Subject Short Name",
            inputName: "subject_classification",
            required: true,
          },
        ],
        CheckBoxType: [{ LabelName: "Status", inputName: "subject_is_active" }],
      },
      Publisher: {
        InputType: [
          {
            LabelName: "Publisher Name",
            inputName: "publisher_name",
            autoFocus: true,
            required: true,
          },
          {
            LabelName: "Phone No.",
            inputName: "publisher_phone",
          },
          {
            LabelName: "Publisher Contact Person",
            inputName: "publisher_contact_person",
          },
          {
            LabelName: "Publisher Contact Person Telno.",
            inputName: "publisher_contact_person_tel",
          },
        ],
        MultiLine: [{ LabelName: "Address", inputName: "publisher_address" }],
      },
      Languages: [
        {
          LabelName: "Language",
          inputName: "language",
          autoFocus: true,
          required: true,
        },
        {
          LabelName: "Short Description",
          inputName: "language_short_desc",
          required: true,
        },
      ],
      Attribute: [
        {
          LabelName: "Attribute",
          inputName: "book_attribute",
          autoFocus: true,
          required: true,
        },
      ],
      Author: {
        Input: [
          {
            LabelName: "Author",
            inputName: "author_name",
            autoFocus: true,
            required: true,
          },
          {
            LabelName: "Phone",
            inputName: "author_phone",
          },
          {
            LabelName: "Email",
            inputName: "author_email_id",
            dataType: "email",
          },
        ],
        MultiLine: [
          {
            LabelName: "Address",
            inputName: "author_address",
          },
        ],
      },
      NewBookEntry: [
        {
          LabelName: "Media",
          inputName: "media",
        },
        {
          LabelName: "Accession No.",
          inputName: "book_access_no",
        },
        {
          LabelName: "Attribute",
          inputName: "attribute",
        },
        {
          LabelName: "Subject",
          inputName: "subject",
        },
        {
          LabelName: "Publisher",
          inputName: "publisher",
        },
        {
          LabelName: "Author",
          inputName: "author",
        },
        {
          LabelName: "Book Category",
          inputName: "book_category",
        },
        {
          LabelName: "Language",
          inputName: "language",
        },
        {
          LabelName: "Year",
          inputName: "book_published_year",
        },
        {
          LabelName: "Number of Pages",
          inputName: "book_pages",
        },
        {
          LabelName: "Edition",
          inputName: "book_edition",
        },
        {
          LabelName: "Volume",
          inputName: "book_volume",
        },
      ],
      VendorDetails: {
        InputType: [
          {
            LabelName: "Vendor Name",
            inputName: "vendor_name",
            autoFocus: true,
            required: true,
          },
          { LabelName: "Contact Person", inputName: "vendor_contact_person" },
          { LabelName: "Phone No.", inputName: "vendor_phone" },
          {
            LabelName: "Contact Person Phone.",
            inputName: "vendor_contact_person_phone",
          },
          { LabelName: "Pan No.", inputName: "vendor_pan" },
          { LabelName: "GST", inputName: "vendor_gst" },
        ],
        MultiLine: [{ LabelName: "Address", inputName: "vendor_address" }],
      },
      Book_Category: [
        {
          LabelName: "Book Category",
          inputName: "category_desc",
          autoFocus: true,
          required: true,
        },
        {
          LabelName: "Abbreviation",
          inputName: "category_abbrevation",
          required: true,
        },
      ],

      Book_Transaction: {
        Book_Issue_Return: [
          { LabelName: "Name", inputName: "std_name" },
          { LabelName: "Admission Number", inputName: "adm_no" },
          { LabelName: `${classLabel}`, inputName: "class" },
        ],
        BookDetails: [
          { LabelName: "Accession No." },
          { LabelName: "ISBN" },
          { LabelName: "Publisher" },
          { LabelName: "Volume" },
          {
            LabelName: "Subject",
          },
          { LabelName: "Status" },
          { LabelName: "Title" },
          { LabelName: "Book Category" },
          { LabelName: "Author" },
          { LabelName: "Edition" },
          { LabelName: "Shelf" },
          { LabelName: "Classification No." },
        ],
      },
      Donor: [
        {
          LabelName: "Donor Name",
          inputName: "donor_name",
          autoFocus: true,
          required: true,
        },
        {
          LabelName: " Address",
          inputName: "donor_address",
        },
        {
          LabelName: " Contact No",
          inputName: "donor_tel",
        },
      ],
      Classification: [
        {
          LabelName: "Classification Desc",
          inputName: "classification_desc",
          autoFocus: true,
          required: true,
        },
        {
          LabelName: "Classification No.",
          inputName: "classification_no",
          required: true,
        },
        {
          LabelName: "Classification Date",
          inputName: "classification_date",
          dataType: "date",
        },
      ],
      Shelf: [
        {
          LabelName: "Shelf Desc",
          inputName: "lib_rack_desc",
          autoFocus: true,
          required: true,
        },
        {
          LabelName: "Shelf No.",
          inputName: "lib_rack_no",
          required: true,
        },
      ],
      Keyword: [
        {
          LabelName: "Keyword.",
          inputName: "keyword",
          required: true,
        },
      ],
    },
  };
  return tableJson;
};

export default useLibraryformJson;

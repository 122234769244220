import { gql } from "@apollo/client";

export const GetLibBookAttributesByInstId = gql`
  query GetLibBookAttributesByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookAttributeOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookAttributesByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ bookAttributeContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          book_attribute
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

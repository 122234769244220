import React, { useState } from "react";
import { HRS_MINS } from "../../../utils/Enum.types";
import { INPUT } from "../../../utils/constants";
import { Keys } from "../../../utils/Enum.keys";

interface Props {
  onChangeHrs: React.Dispatch<React.SetStateAction<string>>;
  onChangeMins: React.Dispatch<React.SetStateAction<string>>;
  hrs: string;
  mins: string;
}

const Time = ({ onChangeHrs, onChangeMins, hrs, mins }: Props) => {
  const [shouldHandleChange, setShouldHandleChange] = useState(true);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    callbackState: React.Dispatch<React.SetStateAction<string>>,
    hrsOrMins: HRS_MINS
  ) => {
    const value = e.target.value;

    if (shouldHandleChange)
      if (
        (Number(value) === 0 || Number(value)) &&
        ((hrsOrMins === HRS_MINS.HRS && Number(value) <= 23) ||
          (hrsOrMins === HRS_MINS.MINS && Number(value) <= 59))
      ) {
        if (value.length === 1) {
          callbackState(`0${value}`);
        } else {
          if (value[0] === "0") callbackState(`${value[1]}${value[2]}`);
        }
      }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent,
    index: number,
    callbackState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const divElement = document.getElementsByClassName("time-input")[0];
    const elements = divElement.getElementsByTagName(INPUT);

    if (e.key === Keys.ENTER) {
      if (elements[index + 1]) {
        (elements[index + 1] as HTMLInputElement).focus();
      }
    }
    if (e.key === Keys.BACKSPACE) {
      const value = (e.target as HTMLInputElement).value;

      if (Number(value[0]) > 0) {
        callbackState(`${"0"}${value[0]}`);
        setShouldHandleChange(false);
      } else {
        setShouldHandleChange(true);
        callbackState("00");
      }
    } else {
      setShouldHandleChange(true);
    }
  };

  return (
    <div className="time-input">
      <input
        value={hrs}
        onFocus={(e) => {
          if (e.target) {
            e.target.select();
          }
        }}
        onKeyDown={(e: React.KeyboardEvent) => handleKeyDown(e, 0, onChangeHrs)}
        onChange={(e) => {
          handleOnChange(e, onChangeHrs, HRS_MINS.HRS);
        }}
      />{" "}
      <span>:</span>
      <input
        value={mins}
        onKeyDown={(e: React.KeyboardEvent) =>
          handleKeyDown(e, 1, onChangeMins)
        }
        onFocus={(e) => {
          if (e.target) {
            e.target.select();
          }
        }}
        onChange={(e) => {
          handleOnChange(e, onChangeMins, HRS_MINS.MINS);
        }}
      />
    </div>
  );
};

export default Time;

import { gql } from "@apollo/client";

export const DeletePredefinedDataById = gql`
  mutation DeletePredefinedDataById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePredefinedDataById(
      token: $token
      id: $id
      user_details: $user_details
    )
  }
`;

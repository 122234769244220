import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import usePageDetailsByPageNumber from "../../../../customhooks/usePageDetailsByPageNumber";
import { AntSwitch } from "../../../../pages/Switch";
import { UpdateSwConfigVariables } from "../../../../queries/institution/configuration/mutation";
import {
  GetSwConfigReferenceDataByKey,
  GetSwConfigVariablesPageAssociByPageNumber,
} from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Select } from "../../../../stories/Select/Select";
import { Title } from "../../../../stories/Title/Title";
import {
  SoftwareDetails,
  SoftwareConfigList,
  SoftwareVars,
} from "../../../../Types/configtypes";
import Modal from "react-modal";
import { ConfigurationTitleProps } from "../../../../Types/Titles";
import {
  InstConfigKey,
  Operation,
  PageFor,
} from "../../../../utils/Enum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import { FormControlLabel, FormGroup } from "@mui/material";
import useToken from "../../../../customhooks/useToken";
import Input from "../../../../stories/Input/Input";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import { ConfigurationsModalStyles } from "../../../../styles/ModalStyles";
import Reorder from "../institutionconfiguration/ReorderPageConfig";
import Close from "../../../../images/Close.svg";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const { Configuration } = require("../../../../json/title.json");

interface OptionList {
  label: string;
  value: string;
}
interface Props {
  pageNumber: number;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
const Index = ({ pageNumber, setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams<{ InstId: string }>();

  const [institutionWorkingtypeData, setInstitutionWorkingTypeData] = useState<
    OptionList[]
  >([]);
  const [pageSwDetails, setPageSwConfigList] = useState<SoftwareDetails[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [reorderModal, setReorderModal] = useState(false);
  const { data, GetSwConfigVariablesPageAssociByPageNumberLoading } =
    usePageDetailsByPageNumber(pageNumber);
  const [
    GetInstWorkModalData,
    { data: InstitutionWorkModalData, loading: pageNumberLoading },
  ] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey,
    {
      variables: { config_key: InstConfigKey.INSTITUTION_WORK_MODEL, token },
    }
  );
  const [
    GetSwReferenceKey,
    { data: InstitutionAdmissionModalData, loading: modalDataLoading },
  ] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey,
    {
      variables: {
        config_key: InstConfigKey.INSTITUTION_ADMISSION_NUMBER_MODEL,
        token,
      },
    }
  );
  const { user_details } = useLoggedInUserDetails();
  const instititionAccountingType =
    // eslint-disable-next-line
    InstitutionWorkModalData?.GetSwConfigReferenceDataByKey.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }));
  const instititionAddmissionType =
    // eslint-disable-next-line
    InstitutionAdmissionModalData?.GetSwConfigReferenceDataByKey.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }));
  const [GetInstitutionTypeData, { data: InstitutionTypeData }] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey, {
    variables: { config_key: InstConfigKey.INSTITUTION_TYPE, token },
  });

  const instititionType =
    InstitutionTypeData?.GetSwConfigReferenceDataByKey.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }));
  const [GetSubjectAllocationData, { data: SubjectAllocationLevel }] =
    useLazyQuery<SoftwareConfigList, SoftwareVars>(
      GetSwConfigReferenceDataByKey,
      {
        variables: {
          config_key: InstConfigKey.SUBJECT_ALLOCATION_LEVEL,
          token,
        },
      }
    );

  const subjectAllocattionDetails =
    SubjectAllocationLevel?.GetSwConfigReferenceDataByKey.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }));

  //lazy queries
  const [GetInstitutionWorkingType] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey);

  //mutations
  const [UpdateInstConfig, { loading: updationLoading }] = useMutation(
    UpdateSwConfigVariables,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleChangeValue = (
    key: string,
    type: string,
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    let newState;
    switch (type) {
      case "BOOL":
        newState = pageSwDetails.map((obj) =>
          obj.config_details.config_key === key
            ? {
                ...obj,
                config_details: {
                  ...obj.config_details,
                  config_boolean_value: switchValue!,
                },
              }
            : obj
        );
        setPageSwConfigList(newState);
        break;
      case "WORK_MODAL":
        newState = pageSwDetails.map((obj) =>
          obj.config_details.config_key === key
            ? {
                ...obj,
                config_details: {
                  ...obj.config_details,
                  config_string_value: selectedValue!,
                },
              }
            : obj
        );
        setPageSwConfigList(newState);
        break;
      case "INT":
        newState = pageSwDetails.map((obj) =>
          obj.config_details.config_key === key
            ? {
                ...obj,
                config_details: {
                  ...obj.config_details,
                  config_integer_value: Number(selectedValue)!,
                },
              }
            : obj
        );
        setPageSwConfigList(newState);
        break;
      case "primary_list_key":
        const config_key = `${key}_${selectedValue!}`;
        newState = pageSwDetails.map((obj) =>
          obj.config_details.config_key === key
            ? {
                ...obj,
                config_details: {
                  ...obj.config_details,
                  config_secondary_list_key: config_key,
                  config_string_value: selectedValue!,
                },
              }
            : obj
        );
        setPageSwConfigList(newState);
        GetInstitutionWorkingType({
          variables: { config_key, token },
        }).then(({ data }) => {
          if (data?.GetSwConfigReferenceDataByKey) {
            setInstitutionWorkingTypeData(
              data.GetSwConfigReferenceDataByKey.list_item_list
                .split(";")
                ?.map((d) => ({
                  label: d?.split(":")[0],
                  value: d?.split(":")[1],
                }))
            );
          }
        });
        break;
      case "secondary_list_key":
        newState = pageSwDetails.map((obj) =>
          obj.config_details.config_key === key
            ? {
                ...obj,
                config_details: {
                  ...obj.config_details,
                  config_string_value_2: selectedValue!,
                },
              }
            : obj
        );
        setPageSwConfigList(newState);
        break;
      default:
        break;
    }
  };

  const handleUpdateSwConfig = () => {
    UpdateInstConfig({
      variables: {
        token,
        input: pageSwDetails.map((pageSwDetail) => ({
          id: pageSwDetail.config_details.id,
          config_key: pageSwDetail.config_details.config_key,
          config_boolean_value:
            pageSwDetail.config_details.config_boolean_value,
          config_integer_value:
            pageSwDetail.config_details.config_integer_value,
          config_double_value: pageSwDetail.config_details.config_double_value,
          config_string_value: pageSwDetail.config_details.config_string_value,
          config_string_value_2:
            pageSwDetail.config_details.config_string_value_2,
          inst_id: InstId,
        })),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwConfigVariablesPageAssociByPageNumber,
          variables: {
            token,
            page_number: pageNumber,
            inst_id: InstId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Configuration saved successfully",
          flag: true,
          operation: Operation.CREATE,
        });
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (!GetSwConfigVariablesPageAssociByPageNumberLoading && data)
      setPageSwConfigList(data!);
    // eslint-disable-next-line
  }, [data, GetSwConfigVariablesPageAssociByPageNumberLoading]);
  useEffect(() => {
    if (token) {
      GetInstitutionTypeData();
    }
  }, [token, GetInstitutionTypeData]);
  useEffect(() => {
    if (token) {
      GetSubjectAllocationData();
    }
  }, [token, GetSubjectAllocationData]);
  useEffect(() => {
    if (token) {
      GetInstWorkModalData();
    }
  }, [token, GetInstWorkModalData]);
  useEffect(() => {
    if (token) {
      GetSwReferenceKey();
    }
  }, [token, GetSwReferenceKey]);
  return (
    <>
      <div className="row g-0">
        <div className="col">
          <Title>
            {Configuration.Titles.map(
              (title: ConfigurationTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.Receipt}</React.Fragment>
                );
              }
            )}
          </Title>
        </div>
      </div>
      <div className="accountsconfig">
        <div className="accountsconfig__data">
          {pageSwDetails?.map((pageSwDetail, index) => {
            return (
              <React.Fragment key={index}>
                {pageSwDetail.config_details?.config_key ===
                InstConfigKey.INSTITUTION_WORK_MODEL ? (
                  <div className="accountsconfig__label-grid">
                    <Label>
                      {pageSwDetail.config_details.config_form_label_caption}
                    </Label>
                    <Select
                      value={pageSwDetail.config_details.config_string_value}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        handleChangeValue(
                          pageSwDetail.config_details.config_key,
                          "WORK_MODAL",
                          null,
                          e.target.value
                        );
                      }}
                    >
                      <option>select</option>
                      {instititionAccountingType?.map((data) => {
                        return (
                          <React.Fragment key={data.value}>
                            <option value={data.value}>{data.label}</option>
                          </React.Fragment>
                        );
                      })}
                    </Select>
                  </div>
                ) : pageSwDetail.config_details.config_key ===
                  InstConfigKey.INSTITUTION_ADMISSION_NUMBER_MODEL ? (
                  <div className="accountsconfig__select-option">
                    <div className="accountsconfig__select-option--label-grid">
                      <Label>
                        {pageSwDetail.config_details.config_form_label_caption}
                      </Label>
                      <Select
                        value={pageSwDetail.config_details.config_string_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeValue(
                            pageSwDetail.config_details.config_key,
                            "primary_list_key",
                            null,
                            e.target.value
                          );
                        }}
                      >
                        <option>Select</option>
                        {instititionAddmissionType?.map(({ label, value }) => {
                          return (
                            <React.Fragment key={value}>
                              <option value={value}>{label}</option>
                            </React.Fragment>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                ) : pageSwDetail.config_details.config_key ===
                  InstConfigKey.SUBJECT_ALLOCATION_LEVEL ? (
                  <div className="accountsconfig__select-option">
                    <div className="accountsconfig__select-option--label-grid">
                      <Label>
                        {pageSwDetail.config_details.config_form_label_caption}
                      </Label>
                      <Select
                        value={pageSwDetail.config_details.config_string_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeValue(
                            pageSwDetail.config_details.config_key,
                            "primary_list_key",
                            null,
                            e.target.value
                          );
                        }}
                      >
                        <option>Select</option>
                        {subjectAllocattionDetails?.map(({ label, value }) => {
                          return (
                            <React.Fragment key={value}>
                              <option value={value}>{label}</option>
                            </React.Fragment>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                ) : pageSwDetail.config_details.config_key ===
                  InstConfigKey.INSTITUTION_TYPE ? (
                  <div className="accountsconfig__select-option">
                    <div className="accountsconfig__select-option--label-grid">
                      <Label>
                        {pageSwDetail.config_details.config_form_label_caption}
                      </Label>
                      <Select
                        value={pageSwDetail.config_details.config_string_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeValue(
                            pageSwDetail.config_details.config_key,
                            "primary_list_key",
                            null,
                            e.target.value
                          );
                        }}
                      >
                        <option>Select</option>
                        {instititionType?.map(({ label, value }) => {
                          return (
                            <React.Fragment key={value}>
                              <option value={value}>{label}</option>
                            </React.Fragment>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="accountsconfig__select-option--label-grid">
                      <Label>Institution Working Type</Label>
                      <Select
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeValue(
                            pageSwDetail.config_details.config_key,
                            "secondary_list_key",
                            null,
                            e.target.value
                          );
                        }}
                      >
                        <option>Select</option>
                        {institutionWorkingtypeData.map(({ label, value }) => {
                          return (
                            <React.Fragment key={value}>
                              <option value={value}>{label}</option>
                            </React.Fragment>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                ) : pageSwDetail.config_details.config_key_data_storage_type ===
                  "BOOLEAN" ? (
                  <div className="accountsconfig__switches">
                    <FormGroup>
                      <FormControlLabel
                        label={
                          pageSwDetail.config_details.config_form_label_caption
                        }
                        labelPlacement="start"
                        control={
                          <AntSwitch
                            checked={
                              pageSwDetail.config_details.config_boolean_value
                            }
                            onClick={() => {
                              const newConfigValue = pageSwDetail.config_details
                                .config_boolean_value
                                ? false
                                : true;
                              handleChangeValue(
                                pageSwDetail.config_details.config_key,
                                "BOOL",
                                newConfigValue,
                                null
                              );
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                ) : pageSwDetail.config_details.config_key_data_storage_type ===
                  "INT" ? (
                  <div className="accountsconfig__label-grid">
                    <Input
                      value={pageSwDetail.config_details.config_integer_value}
                      type="number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValue(
                          pageSwDetail.config_details.config_key,
                          "INT",
                          null,
                          e.target.value
                        )
                      }
                    />
                  </div>
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <>
        <Button mode="save" onClick={handleUpdateSwConfig} />
        <Button mode="reorder" onClick={() => setReorderModal(!reorderModal)} />
        <Button
          type="button"
          mode="cancel"
          onClick={() => setModalFlag?.(false)}
        />
      </>
      <LoadingModal
        flag={
          updationLoading ||
          pageNumberLoading ||
          modalDataLoading ||
          GetSwConfigVariablesPageAssociByPageNumberLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={reorderModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data">
            <Reorder pageType={PageFor.MODAL} modalpageNumber={pageNumber} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setReorderModal(!reorderModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

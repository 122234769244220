import React, { useContext, useEffect, useRef, useState } from "react";
import DownArrow from "../../images/DownArrow.svg";
import Close from "../../images/Close.svg";
// import Word from "../../images/WordDocument.svg";
import PngFormat from "../../images/PngFormat.svg";
import {
  Chip,
  Divider,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../stories/Button/Button";
import { Label } from "../../stories/Label/Label";
import Modal from "react-modal";
import FileAttach from "../../images/AttachFile.svg";
import Send from "../../images/Send.svg";
import MessageTemplate from "../../images/TemplateMessages.svg";
import Avatar from "../../images/Avatar.svg";
import {
  AboutChannelModalStyles,
  AddLedgerModalStyles,
} from "../../styles/ModalStyles";
import { Title } from "../../stories/Title/Title";
import { AppContext } from "../../context/context";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetChannelMessages,
  GetChannelStudentIndividual,
  GetMessageChannelById,
  GetMessageDataById,
  StudentsCountByChannelId,
} from "../../queries/chatapplication/list";
import useToken from "../../customhooks/useToken";
import {
  ChannelDataByIdData,
  ChannelDataByIdVars,
  GetChannelMessagesByData,
  GetChannelMessagesByVars,
  GetMessageData,
  GetMessageDataVariables,
} from "./Types";
import AddChannel from "./NewChannel";
import MessageModal from "../../pages/MessageModal";
import { msgType } from "../../utils/Form.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  formatOptions,
  formattingToolbarOptions,
} from "../../utils/constants";
import {
  ChannelType,
  Direction,
  InstitutionConfigurationTypes,
  MsgChannelType,
  Operation,
  PageFor,
  SortBy,
} from "../../utils/Enum.types";
import { AddChannelmessage } from "../../queries/chatapplication/mutations";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { payloadTypes } from "../../context/reducer";
import useInstDetails from "../../customhooks/general/useInstDetails";
import { handleUploadAndDownloadFile } from "../../utils/Upload";
import axios from "axios";
import { MessageEdge } from "./Types";
import {
  getFileName,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../utils/UtilFunctions";
import useSwConfigData from "../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../Types/configtypes";
import { MessagePayload } from "firebase/messaging/sw";
import DownloadPdfImg from "../../images/DownloadPdf.svg";
import ExcelImg from "../../images/Excel.svg";

import localizedFormat from "dayjs/plugin/localizedFormat";
import useStudentDatabyId from "../../customhooks/useStudentDatabyId";
import { GetMsgInstTemplates } from "../Templates/queries/list";
import {
  GetMsgInstTemplatesData,
  GetMsgInstTemplatesVars,
  templateEdges,
} from "../Templates/utils/types";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../customhooks/general/useInstLabels";
import { getDownloadUrl as getProfilePic } from "../../utils/DownloadFile";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { TemplateViewDrawer } from "../../styles/DrawerStyles";
dayjs.extend(localizedFormat);

export interface MessageData {
  url: string;
  mediaContent: string;
  id: number;
  createdTime: string;
  downloadKey: string;
  sent_user_id: number;
}
interface Props {
  pageType: PageFor;
  setMessageModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const MessageList = ({ pageType, setMessageModal }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { dispatch, state } = useContext(AppContext);
  const messageListRef = useRef<HTMLUListElement>(null);
  const [mediaContent, setMediaContent] = useState("");
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const [templateId, setTemplateId] = useState(-898);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [aboutChannel, setAboutChannel] = useState(false);
  const [channelMessage, setChannelMessage] = useState("");

  const { InstDetails } = useInstDetails(1);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [addChannel, setAddChannel] = useState(false);
  const [templates, setTemplates] = useState<templateEdges[]>([]);

  const [file, setFile] = useState<File | File[]>();
  const { studentFormData, studentData } = useStudentDatabyId();
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);
  // Handle the error as needed
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [endCursor, setEndCursor] = useState("");

  const [imageString, setImageString] = useState("");

  const [userList, setUserList] = useState<Map<number, string>>(new Map());

  const { InstFormData } = useInstDetails(1);

  const { configData: sizeLimits } = useSwConfigData([
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE,
  ]);

  const [
    GetTemplates,
    {
      data: templateData,
      loading: templateLoading,
      fetchMore: fetchMoreTemplates,
    },
  ] = useLazyQuery<GetMsgInstTemplatesData, GetMsgInstTemplatesVars>(
    GetMsgInstTemplates,
    {
      variables: {
        token,
        inst_id: InstId!,
        after: null,
        first: ROWS_PER_PAGE,
        name: EMPTY_STRING,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.MSG_TPL_DESC,
        },
      },
    }
  );

  const [GetChannelData, { data: ChannelDetails }] = useLazyQuery<
    ChannelDataByIdData,
    ChannelDataByIdVars
  >(GetMessageChannelById, {
    variables: {
      token,
      id: state.channelId,
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const [GetMessages, { data: MessagesData, fetchMore, loading }] =
    useLazyQuery<GetChannelMessagesByData, GetChannelMessagesByVars>(
      GetChannelMessages
    );

  const [GetMessageUser, { data: SenderData }] = useLazyQuery<
    GetMessageData,
    GetMessageDataVariables
  >(GetMessageDataById, {
    variables: {
      token,
      ids: allMessages.map((res) => res.node.sent_user_id),
    },
  });
  const [GetChannelStudent, { data: channelStudentData }] = useLazyQuery(
    GetChannelStudentIndividual
  );
  let filename = Array.isArray(file)
    ? file
        .map(
          (f) =>
            `${InstFormData.inst_name}/channels/${state.channelId}/${f.name}`
        )
        .join(",")
    : `${InstFormData.inst_name}/channels/${state.channelId}/${file?.name}`;

  const [AddChannelMsg] = useMutation(AddChannelmessage, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [GetStudentCount, { data: StudentCount }] = useLazyQuery(
    StudentsCountByChannelId,
    {
      variables: {
        token,
        msg_channel_id: state.channelId,
      },
    }
  );

  const handleScrollForTemplates = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMoreTemplates({
          variables: {
            first: ROWS_PER_PAGE,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetMsgInstTemplates?.edges;
            const pageInfo = fetchMoreResult.GetMsgInstTemplates.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetMsgInstTemplates.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck?.length > 0) return prevResult;

            return {
              GetMsgInstTemplates: {
                edges: [...templates, ...newEdges],
                pageInfo,
                totalCount: templateData
                  ? templateData.GetMsgInstTemplates.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };

  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let imageSize = 0;
    let videoSize = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE:
            imageSize = item.config_integer_value;
            break;
          case InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE:
            videoSize = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      imageSize,
      videoSize,
    };
  };
  const { imageSize, videoSize } = filterDataByConfigKey(
    sizeLimits.data?.GetSwConfigVariables!
  );
  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      files && setFile(files.length > 1 ? files : files[0]);
    }
  };
  const handleSubmit = () => {
    let flag = true;

    if (Array.isArray(file)) {
      // Handle multiple files
      for (const f of file) {
        if (f.type.includes("image/") && f.size > imageSize! * 1024 * 1024) {
          alert("Image file size exceeds the maximum limit.");
          flag = false;
          break;
        }

        if (f.type.includes("video/") && f.size > videoSize! * 1024 * 1024) {
          alert("Video file size exceeds the maximum limit.");
          flag = false;
          break;
        }

        if (f.type === "application/pdf" && f.size > imageSize! * 1024 * 1024) {
          alert("PDF file size exceeds the maximum limit.");
          flag = false;
          break;
        }

        if (
          (f.type === "application/vnd.ms-excel" ||
            f.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") &&
          f.size > imageSize! * 1024 * 1024
        ) {
          alert("Excel file size exceeds the maximum limit.");
          flag = false;
          break;
        }
      }
    } else {
      // Handle single file
      if (
        file &&
        file.type.includes("image/") &&
        file.size > imageSize! * 1024 * 1024
      ) {
        alert("Image file size exceeds the maximum limit.");
        flag = false;
      }

      if (
        file &&
        file.type.includes("video/") &&
        file.size > videoSize! * 1024 * 1024
      ) {
        alert("Video file size exceeds the maximum limit.");
        flag = false;
      }

      if (
        file &&
        file.type === "application/pdf" &&
        file.size > imageSize! * 1024 * 1024
      ) {
        alert("PDF file size exceeds the maximum limit.");
        flag = false;
      }

      if (
        file &&
        (file.type === "application/vnd.ms-excel" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") &&
        file.size > imageSize! * 1024 * 1024
      ) {
        alert("Excel file size exceeds the maximum limit.");
        flag = false;
      }
    }

    if (flag) {
      const sendMsgPromise = AddChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: file ? filename : "",
            msg_channel_id:
              pageType === PageFor.GENERAL
                ? state.channelId
                : channelStudentData?.GetChannelStudentIndividual?.id,
            inst_id: InstId!,
            msg_header: EMPTY_STRING,
          },
          user_details,
        },

        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              token,
              inst_id: InstId ? InstId : state.InstId.toString(),
              input: {
                query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
                channel_id: state.channelId,
              },
              direction: Direction.ASC,
              last: 10,
              after: null,
              sortBy: SortBy.CREATED_AT,
              mediaContent,
            },
          },
        ],
      });
      sendMsgPromise.then(({ data }) => {
        if (data) {
          const uploadFilePromise = file
            ? Array.isArray(file)
              ? Promise.all(
                  file.map((f, index) =>
                    handleUploadAndDownloadFile(
                      f,
                      `${InstFormData.inst_name}/channels/${state.channelId}/${
                        data.AddChannelMessage.id
                      }_${index}.${
                        f.name.split(".")[f.name.split(".").length - 1]
                      }`,
                      setProgress,
                      false
                    )
                  )
                )
              : handleUploadAndDownloadFile(
                  file,
                  `${InstFormData.inst_name}/channels/${state.channelId}/${
                    data.AddChannelMessage.id
                  }.${file.name.split(".")[file.name.split(".").length - 1]}`,
                  setProgress,
                  false
                )
            : false;

          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              if (sendMsgData) {
                setChannelMessage(EMPTY_STRING);
                setFile(undefined);
                if (pageType === PageFor.MODAL)
                  setMessage({
                    flag: true,
                    message: "Message Sent Successfully",
                    operation: Operation.CREATE,
                  });
              }
            })

            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setChannelMessage(EMPTY_STRING);
      dispatch({
        type: payloadTypes.SET_CHANNEL_ID,
        payload: {
          channelId: 0,
        },
      });
      setMessageModal?.(false);
    }
    setMessage({
      flag: !message.flag,
      message: "",
      operation: Operation.NONE,
    });
  };
  const byDate: { [key: string]: MessageEdge[] } | undefined =
    allMessages &&
    allMessages?.reduce(
      (obj: { [key: string]: MessageEdge[] }, item: MessageEdge) => {
        const date = new Date(item?.node?.created_at);
        const dateString = dayjs(date).format("DD/MM/YY");
        if (obj[dateString]) {
          obj[dateString].push(item);
          return obj;
        }

        obj[dateString] = [{ ...item }];
        return obj;
      },
      {}
    );

  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        axios.get(response.data, { responseType: "blob" }).then(() => {
          setDownloadUrl((prevDownloadUrl) =>
            prevDownloadUrl.map((messageData) => {
              if (getFileName(mediaContent) === messageData.downloadKey) {
                return { ...messageData, url: response.data };
              } else return messageData;
            })
          );
        });
      })
      .catch((err) => console.log(err));
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;

    if (scrollTop === 0 && !loading && hasPreviousPage) {
      fetchMore({
        variables: {
          last: FETCH_MORE_DATA,
          before: startCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.GetChannelMessages.edges;
          const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
          setStartCursor(pageInfo.startCursor);
          setHasPreviousPage(pageInfo.hasPreviousPage);

          const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
            ({ node: { id } }) =>
              newEdges.findIndex(({ node: { id: newId } }) => newId === id) !==
              -1
          );

          if (duplicateCheck.length > 0) return prevResult;

          return {
            GetChannelMessages: {
              edges: [...newEdges, ...allMessages],
              pageInfo,
              totalCount: MessagesData?.GetChannelMessages.totalCount!,
            },
          };
        },
      });
    }
  };

  const handleFileDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }
  };

  useEffect(() => {
    if (state.channelId) {
      GetChannelData();
      GetStudentCount();
    }
  }, [state.channelId, GetChannelData, GetStudentCount]);

  useEffect(() => {
    if (state.channelId && token) {
      GetMessageUser().then(({ data }) => {
        if (data) {
          setUserList(
            new Map(
              data.nodes.map((res) => {
                if (res.first_name)
                  return [
                    res.id,
                    `${
                      res.first_name +
                      " " +
                      res.middle_name +
                      " " +
                      res.last_name
                    }`,
                  ];
                else
                  return [
                    res.id,
                    `${
                      res.emp_first_name +
                      " " +
                      res.emp_middle_name +
                      " " +
                      res.emp_last_name
                    }`,
                  ];
              })
            )
          );
        }
      });
    }
  }, [state.channelId, token, SenderData, GetMessageUser]);

  useEffect(() => {
    const messageDataArray: MessageData[] = allMessages
      .filter((mess) => mess.node.msg_media_content !== "")
      .flatMap((message) => {
        const mediaContentArray = message.node.msg_media_content.split(",");

        if (mediaContentArray.length > 1) {
          return mediaContentArray.map((content, index) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}_${index}.${
              content.split(".")[content.split(".").length - 1]
            }`;
            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,
              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        } else {
          return mediaContentArray.map((content) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              state.channelId
            }/${message.node.id}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,
              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        }
      });
    setDownloadUrl(messageDataArray);
    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        const mediaContent = message.mediaContent;
        getDownloadUrl(message.id, mediaContent);
      });
    };
    fetchDownloadUrls();
  }, [allMessages, InstFormData.inst_name]);

  useEffect(() => {
    if (state.channelId && token) {
      GetMessages({
        variables: {
          token,
          inst_id: InstId ? InstId : state.InstId.toString(),
          input: {
            query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
            channel_id: state.channelId,
          },
          direction: Direction.ASC,
          last: 10,
          after: null,
          sortBy: SortBy.CREATED_AT,
          mediaContent,
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
        }
      });
    } // eslint-disable-next-line
  }, [state.channelId, token, mediaContent, MessagesData, GetMessages]);

  useEffect(() => {
    if (MessagesData && !loading) {
      const newMessages = MessagesData.GetChannelMessages.edges;

      if (startCursor) {
        const updatedNewMessages = newMessages.map((newMessage) => {
          const filteredMessage = allMessages.find(
            (message) => message.node.id === newMessage.node.id
          );
          if (filteredMessage) {
            return {
              ...newMessage,
              node: {
                ...newMessage.node,
              },
            };
          }
          return newMessage;
        });
        setAllMessages(updatedNewMessages);
      } else {
        setAllMessages(newMessages);
      }

      const pageInfo = MessagesData.GetChannelMessages.pageInfo;
      setStartCursor(pageInfo.startCursor);
      setHasPreviousPage(pageInfo.hasPreviousPage);
    } // eslint-disable-next-line
  }, [MessagesData, loading, startCursor]);

  useEffect(() => {
    // Scroll to the bottom after rendering new messages
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight;
    }
  }, [byDate]);
  useEffect(() => {
    if (state.studentId && token && InstId) {
      GetChannelStudent({
        variables: {
          token,
          inst_id: InstId!,
          student_id: state.studentId,
        },
      });
    }
  }, [state.studentId, token, InstId, GetChannelStudent]);

  useEffect(() => {
    if (token && InstId) {
      GetTemplates();
    }
  }, [GetTemplates, token, InstId]);
  const stdData = studentData.data && studentData.data?.nodes[0];

  const replaceNames = (inputStr: string) => {
    const replacements: { [key: string]: string } = {
      "<ADMNO>": stdData ? stdData.std_adm_no : EMPTY_STRING,
      "<EMAILID>": stdData ? stdData.std_email : EMPTY_STRING,
      "<MOBILENO>": stdData ? stdData.std_mobile : EMPTY_STRING,
      "<DATEOFADM>": stdData ? toStandardDate(stdData.std_doa) : EMPTY_STRING,
      "<STUDENTNAME>":
        stdData?.first_name +
        "" +
        stdData?.middle_name +
        " " +
        stdData?.last_name,
      "<REGNO>": stdData ? stdData.std_reg_no : EMPTY_STRING,
      "<MOTHERNAME>": stdData ? stdData.std_mother_name : EMPTY_STRING,
      "<FATHERNAME>": stdData ? stdData.std_father_name : EMPTY_STRING,
      "<ADDRESS>": stdData ? stdData.std_details.std_address : EMPTY_STRING,
      "<CATEGORY>": stdData ? stdData.category.cat_desc : EMPTY_STRING,
      "<SECTION>": stdData ? stdData.section.section_desc : EMPTY_STRING,
      "<SEMESTER>": stdData ? stdData.semester.sem_desc : EMPTY_STRING,
      "<DEPARTMENT>": stdData ? stdData.dept.dept_desc : EMPTY_STRING,
      "<BRANCH>": stdData ? stdData.branch.branch_desc : EMPTY_STRING,
      "<CLASS>": stdData ? stdData.class.class_desc : EMPTY_STRING,
      "<FEEBALANCE>": stdData
        ? stdData.acct_std_demand.std_demand_bal.toString()
        : EMPTY_STRING,
    };

    const regex = new RegExp(Object.keys(replacements).join("|"), "g");

    return inputStr.replace(regex, (match) => replacements[match]);
  };

  useEffect(() => {
    if (templateId > 0) {
      const res = templates.find(({ node: { id } }) => id === templateId);
      if (res) {
        setChannelMessage(replaceNames(res.node.msg_tpl_desc));
      }
    } // eslint-disable-next-line
  }, [templateId, templates]);
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();
  const handleQuillChange = (value: string) => {
    setChannelMessage(value);
  };

  useEffect(() => {
    if (templateData && !templateLoading) {
      const newData = templateData.GetMsgInstTemplates?.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = templates.find(
            (row) => row.node.id && row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setTemplates(updatedNewData);
      } else {
        setTemplates(newData);
      }
      setEndCursor(templateData.GetMsgInstTemplates?.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [templateData, templateLoading]);

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getProfilePic(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  return (
    <>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "channel__message-block--messages-list"
            : "channel__message-block--messages-list--modal"
        }
      >
        {pageType === PageFor.GENERAL && (
          <div
            className="channel__message-block--channel-name"
            onClick={() => {
              pageType === PageFor.GENERAL && setAboutChannel(!aboutChannel);
            }}
          >
            <div>
              <span>
                {pageType === PageFor.GENERAL
                  ? ChannelDetails?.node.channel_type === ChannelType.INDIVIDUAL
                    ? "ME"
                    : ChannelDetails?.node.channel_name
                  : studentFormData?.first_name +
                    " " +
                    studentFormData?.middle_name +
                    " " +
                    studentFormData?.last_name}
              </span>
              &nbsp;
              {pageType === PageFor.GENERAL && <img src={DownArrow} alt="/" />}
            </div>
            {pageType === PageFor.GENERAL && (
              <div className="channel__message-block--channel-name--flex">
                <div className="channel__message-block--levels">
                  {/* dept/branch/class/semester/section */}
                </div>
                <div>
                  <span>No of Students :</span>
                  <span className="channel__message-block--channel-name--count">
                    {StudentCount?.GetStudentsCountByChannelId}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        {pageType === PageFor.MODAL && (
          <>
            <Title>Send Message</Title>
            <div className="row g-0 channel__student-info">
              <div className="col">
                <TextField
                  className="channel__student-info--textfield"
                  label="Admission No."
                  value={studentFormData.adm_no}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
                <TextField
                  className="channel__student-info--textfield"
                  label="Reg No."
                  value={studentFormData.reg_number}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
                <TextField
                  className="channel__student-info--textfield"
                  label={branchLabel}
                  value={studentFormData.branch}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
              </div>
              <div className="col">
                <TextField
                  className="channel__student-info--textfield"
                  label="Name"
                  value={
                    studentFormData?.first_name +
                    " " +
                    studentFormData?.middle_name +
                    " " +
                    studentFormData?.last_name
                  }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
                <TextField
                  className="channel__student-info--textfield"
                  label="Father Name"
                  value={studentFormData.father_name}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
                <TextField
                  className="channel__student-info--textfield"
                  label="Academic Year"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                  value={studentFormData.acd_yr}
                />
              </div>
              <div className="col">
                <TextField
                  className="channel__student-info--textfield--date"
                  label="Date of Birth"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                  value={toStandardDate(studentFormData.std_dob)}
                />
                <TextField
                  className="channel__student-info--textfield"
                  label={classLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                  value={studentFormData.class}
                />
                <TextField
                  className="channel__student-info--textfield"
                   label={categoryLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                  value={studentFormData.category}
                />
              </div>
              <div className="col-1 channel__student-info--image h-100">
                <img src={imageString ? imageString : Avatar} alt="" />
              </div>
            </div>
            <div className="channel__student-info--data"></div>
          </>
        )}

        {pageType === PageFor.GENERAL && (
          <div
            className="channel__message-block--messages-list--messages"
            onScroll={handleScroll}
          >
            <ul ref={messageListRef}>
              <div />
              {pageType === PageFor.GENERAL && (
                <div
                  className="channel__message-block--messages-list--messages"
                  onScroll={handleScroll}
                >
                  <ul ref={messageListRef}>
                    {Object.entries(byDate)?.map(([date, messages]) => (
                      <React.Fragment key={date}>
                        <Divider>
                          <Chip label={date} />
                        </Divider>
                        {messages.map((message, index) => {
                          const parsedContent = parseMessageContent(
                            message.node.msg_content
                          );

                          const isImage =
                            message.node.msg_media_content.includes(".png") ||
                            message.node.msg_media_content.includes(".jpeg") ||
                            message.node.msg_media_content.includes(".jpg");
                          const isPdf =
                            message.node.msg_media_content.includes(".pdf");
                          const isExcel =
                            message.node.msg_media_content.includes(".xls") ||
                            message.node.msg_media_content.includes(".xlsx");
                          const isVideo =
                            message.node.msg_media_content.includes(".mp4");
                          const id = state.claims?.EMPLOYEE
                            ? state.empLoginId
                            : state.studentId;
                          const isSentUser = id === message.node.sent_user_id;

                          const combinedById = downLoadUrl.reduce(
                            (result, item) => {
                              if (!result[item.id]) {
                                result[item.id] = [item];
                              } else {
                                result[item.id].push(item);
                              }
                              return result;
                            },
                            {} as { [id: number]: MessageData[] }
                          );

                          return (
                            <li
                              key={index}
                              className={`channel__message-block--messages-list--messages--list${
                                isSentUser ? "--sender" : ""
                              }--li`}
                            >
                              <div
                                className={`channel__message-block--messages-list--messages--list${
                                  isSentUser ? "--sender" : ""
                                }`}
                              >
                                {!isSentUser && (
                                  <img
                                    src={Avatar}
                                    alt="/"
                                    className="channel__message-block--messages-list--image"
                                  />
                                )}
                                <div>
                                  {!isSentUser && (
                                    <Label variant="LabelBold">
                                      {userList.get(message.node.sent_user_id)}
                                    </Label>
                                  )}
                                  <span className="channel__message-block--messages-list--time">
                                    {dayjs(message.node.created_at).format(
                                      "LT"
                                    )}
                                  </span>
                                  {message.node.msg_content && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: parsedContent,
                                      }}
                                    />
                                  )}
                                  {combinedById[message.node.id]?.map(
                                    ({ mediaContent, url }) => {
                                      if (isImage) {
                                        return (
                                          <a key={url} href={url}>
                                            <img
                                              src={url}
                                              alt="Loading...."
                                              className="channel__message-block--messages-list--messages--file-image"
                                            />
                                          </a>
                                        );
                                      } else if (isPdf || isExcel) {
                                        return (
                                          <a
                                            key={url}
                                            href={url}
                                            onClick={() =>
                                              handleFileDownload(url)
                                            }
                                          >
                                            {getFileName(mediaContent)}
                                          </a>
                                        );
                                      } else if (isVideo) {
                                        return (
                                          <video
                                            key={url}
                                            width="320"
                                            height="240"
                                            controls
                                            src={url}
                                          >
                                            {mediaContent}
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              )}
            </ul>
          </div>
        )}
        {state.claims && state.claims.STUDENT ? null : (
          <div className="channel__message-block--text-area">
            <div className="channel__message-block--text-area--flex">
              <div className="channel__message-block--text-area--flex--text-field">
                {/* <TextField
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setChannelMessage(e.target.value)}
                value={channelMessage}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && channelMessage !== "") {
                    handleSubmit();
                  }
                }}
                placeholder={`Message...${
                  file && Array.isArray(file)
                    ? file[0].name && `${file[0].name}`
                    : ""
                }`}
                fullWidth
                multiline
                minRows={1}
                maxRows={2}
                InputProps={{
                  endAdornment: (
                    <label className="inst-logo__browse--fileattach--label">
                      <img src={FileAttach} alt="/" className="browse-icon" />
                      <input
                        type="file"
                        multiple
                        id="inst-logo__browse--fileattach--inputtag"
                        className="inst-logo__browse--fileattach--input"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleImagePreview(e)
                        }
                        accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/*"
                      />
                    </label>
                  ),
                }}
              /> */}
                <ReactQuill
                  value={channelMessage}
                  onChange={handleQuillChange}
                  modules={formattingToolbarOptions}
                  formats={formatOptions}
                  placeholder={`Message...`}
                />
              </div>
              <div>
                <label className="inst-logo__browse--fileattach--label">
                  <img src={FileAttach} alt="/" className="browse-icon" />
                  <input
                    type="file"
                    multiple
                    id="inst-logo__browse--fileattach--inputtag"
                    className="inst-logo__browse--fileattach--input"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleImagePreview(e)
                    }
                    accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/*"
                  />
                </label>
              </div>

              {pageType === PageFor.MODAL && (
                <button onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                  <img src={MessageTemplate} alt="/" />
                </button>
              )}
              <button
                className="channel__message-block--text-area--flex--send"
                disabled={channelMessage.length === 0}
                onClick={handleSubmit}
              >
                <img src={Send} alt="/" />
              </button>
            </div>
          </div>
        )}
        {pageType === PageFor.MODAL && (
          <Button mode="cancel" onClick={() => setMessageModal?.(false)} />
        )}
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={aboutChannel}
        style={AboutChannelModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Channel Details</Title>
            <div className="channel__details">
              <div className="channel__details--channel-name">
                <div className="channel__details--channel-name--list">
                  <Label variant="LabelBold">Channel Name</Label>
                  <Label>
                    {ChannelDetails?.node.channel_type ===
                    ChannelType.INDIVIDUAL
                      ? "ME"
                      : ChannelDetails?.node.channel_name}
                  </Label>
                  <Label variant="LabelBold">Description</Label>
                  <Label>{ChannelDetails?.node.channel_desc}</Label>
                </div>
                {ChannelDetails?.node.channel_is_student ? null : (
                  <Label
                    variant="LabelPrimary"
                    onClick={() => setAddChannel(!addChannel)}
                  >
                    Edit
                  </Label>
                )}
              </div>
              <div className="channel__details--created-by">
                <Label variant="LabelBold">Created By</Label>
                <Label>{ChannelDetails?.node.created_user_type}</Label>
              </div>
              <div className="channel__details--files">
                <div className="channel__details--files--first-row">
                  <Title variant="subtitle1">Files</Title>
                </div>
                <Input
                  color="info"
                  placeholder="search files"
                  onChange={(e) => setMediaContent(e.target.value)}
                />
                <div className="channel__details--files--documents">
                  {downLoadUrl.map((d) => {
                    const isPdf = d.mediaContent.includes(".pdf");

                    const isExcel =
                      d.mediaContent.includes(".xls") ||
                      d.mediaContent.includes(".xlsx");
                    return (
                      <>
                        <img
                          src={
                            isExcel
                              ? ExcelImg
                              : isPdf
                              ? DownloadPdfImg
                              : PngFormat
                          }
                          alt="/"
                        />
                        <a
                          href={d.url}
                          onClick={() => handleFileDownload(d.url)}
                        >
                          {getFileName(d.mediaContent)}
                        </a>

                        <div className="channel__details--files--documents--created-by">
                          <Label>{userList.get(d.sent_user_id)}</Label>
                          <div>
                            <Label>{dayjs(d.createdTime).format("LLL")}</Label>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <Button
              mode="cancel"
              onClick={() => setAboutChannel(!aboutChannel)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAboutChannel(!aboutChannel)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addChannel}
        style={AddLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AddChannel
              setAddChannel={setAddChannel}
              operation={Operation.UPDATE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddChannel(!addChannel)}
            />
          </div>
        </div>
      </Modal>
      <TemplateViewDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
   
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Choose Message Template</Title>
            <div className="choose-message-template">
              <div className="choose-message-template__tableblock">
                <TableContainer
                  className="choose-message-template__table"
                  onScroll={handleScrollForTemplates}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl</TableCell>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {templates.map((template, index) => {
                        return (
                          <TableRow
                            key={template.node.id}
                            onClick={() => {
                              setTemplateId(template.node.id);
                              setIsDrawerOpen(!isDrawerOpen);
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell> {template.node.msg_tpl_desc}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Button mode="okay" />
              <Button
                mode="cancel"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
            </div>
          </div>
        </div>
      </TemplateViewDrawer>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default MessageList;

import { gql } from "@apollo/client";

export const GetAcdDeptsByInstId = gql`
  query GetAcdDeptsByInstId($token: String!, $inst_id: ID!) {
    GetAcdDeptsByInstId(token: $token, inst_id: $inst_id) {
      dept_desc
      dept_idx
      id
      dept_id
    }
  }
`;

import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import {
  Direction,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import useChannelDetails from "../../../../customhooks/useChannelDetails";
import { ChannelQueryType } from "../../../Accounts/common/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetChannelMessages,
  GetMessageDataById,
} from "../../../../queries/chatapplication/list";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import {
  GetChannelMessagesByData,
  GetChannelMessagesByVars,
  GetMessageData,
  GetMessageDataVariables,
  MessageEdge,
} from "../../../Channel/Types";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../utils/constants";
import { Chip, Divider } from "@mui/material";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { getFileName } from "../../../../utils/UtilFunctions";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { Label } from "../../../../stories/Label/Label";
import Avatar from "../../../../images/Avatar.svg";
import axios from "axios";
import StudentList from "./StudentList";
import { MessageData } from "../../../Channel/MessageList";
import SendMessage from "./SendMessage";
import {
  AccountingLedgerModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import useIndividualStudentId from "../../hooks/useIndividualStudentId";
import { msgType } from "../../../../utils/Form.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { DeleteChannelMessage } from "../../../../queries/chatapplication/mutations";
import DeleteModal from "../../../../pages/DeleteModal";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import Delete from "../../../../images/Delete.svg";
import Edit from "../../../../images/EditProfile.svg";
import More from "../../../../images/More.svg";
import Input from "../../../../stories/Input/Input";
import MessageImport from "./MessageImport";
import { TooltipForMultipleOption } from "../../../../styles/TooltipStyles";
dayjs.extend(localizedFormat);
interface Props {
  channel: ChannelQueryType;
}
export interface UpdateMessageType {
  message: string;
  id: number;
}
const MessageList = ({ channel }: Props) => {
  const messageListRef = useRef<HTMLUListElement>(null);

  const { token } = useToken();

  const { InstId } = useParams();
  const { InstFormData } = useInstDetails(1);

  const { channelDetails } = useChannelDetails([
    ChannelQueryType.ANNOUNCEMENTS,
    ChannelQueryType.ENTRYLEVEL,
    ChannelQueryType.INDIVIDUAL,
  ]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [messageId, setMessageId] = useState(0);
  const [updateMessage, setUpdateMessage] = useState<UpdateMessageType>({
    message: "",
    id: 0,
  });
  const [importModal, setImportModal] = useState(false);

  // eslint-disable-next-line
  const [mediaContent, setMediaContent] = useState("");
  const [searchMessage, setSearchMessage] = useState("");
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);
  const { state } = useContext(AppContext);
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const [userList, setUserList] = useState<Map<number, string>>(new Map());
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(true);
  const [sendMessage, setSendMessage] = useState(false);
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const { channelId: individualChannelId } = useIndividualStudentId(
    state.studentId
  );
  const navigate = useNavigate();
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const { user_details } = useLoggedInUserDetails();
  const [DeleteMessage, { loading: DeleteLoading }] = useMutation(
    DeleteChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetMessageUser, { data: SenderData }] = useLazyQuery<
    GetMessageData,
    GetMessageDataVariables
  >(GetMessageDataById, {
    variables: {
      token,
      ids: allMessages.map((res) => res.node.sent_user_id),
    },
  });
  const [GetMessages, { data: MessagesData, fetchMore, loading }] =
    useLazyQuery<GetChannelMessagesByData, GetChannelMessagesByVars>(
      GetChannelMessages
    );

  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        axios.get(response.data, { responseType: "blob" }).then(() => {
          setDownloadUrl((prevDownloadUrl) =>
            prevDownloadUrl.map((messageData) => {
              if (getFileName(mediaContent) === messageData.downloadKey) {
                return { ...messageData, url: response.data };
              } else return messageData;
            })
          );
        });
      })
      .catch((err) => console.log(err));
  };

  const channelId =
    channel === ChannelQueryType.INDIVIDUAL
      ? individualChannelId
      : channelDetails.data && channelDetails.data.GetChannelSubscribed.length
      ? channelDetails.data.GetChannelSubscribed[0].id
      : 0;

  useEffect(() => {
    if (channelId && token) {
      GetMessageUser().then(({ data }) => {
        if (data) {
          setUserList(
            new Map(
              data.nodes.map((res) => {
                if (res) {
                  if (res.first_name)
                    return [
                      res.id,
                      `${
                        res.first_name +
                        " " +
                        res.middle_name +
                        " " +
                        res.last_name
                      }`,
                    ];
                  else if (
                    res.emp_first_name +
                    res.emp_middle_name +
                    res.emp_last_name
                  )
                    return [
                      res.id,
                      `${
                        res.emp_first_name +
                        res.emp_middle_name +
                        res.emp_last_name
                      }`,
                    ];
                  else
                    return [
                      res.id,
                      `${res.first_name + res.middle_name + res.last_name}`,
                    ];
                } else return [0, ""];
              })
            )
          );
        }
      });
    }
  }, [channelId, token, SenderData, GetMessageUser]);
  useEffect(() => {
    if (channel) {
      setAllMessages([]);
      setDownloadUrl([]);
    }
  }, [channel]);

  useEffect(() => {
    const messageDataArray: MessageData[] = allMessages
      .filter((mess) => mess.node.msg_media_content !== "")
      .flatMap((message) => {
        const mediaContentArray = message.node.msg_media_content.split(",");

        if (mediaContentArray.length > 1) {
          return mediaContentArray.map((content, index) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}_${index}.${
              content.split(".")[content.split(".").length - 1]
            }`;
            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,
              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        } else {
          return mediaContentArray.map((content) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,
              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        }
      });
    setDownloadUrl(messageDataArray);
    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        const mediaContent = message.mediaContent;
        getDownloadUrl(message.id, mediaContent);
      });
    };
    // if (messageDataArray.some((message) => message.mediaContent !== ""))
    fetchDownloadUrls();
  }, [allMessages, channel, InstFormData.inst_name]);

  useEffect(() => {
    if (channelDetails.data && token && InstId) {
      setAllMessages([]);
      setDownloadUrl([]);

      GetMessages({
        variables: {
          token,
          inst_id: InstId,
          input: {
            query_type:
              channel === ChannelQueryType.INDIVIDUAL ||
              channel === ChannelQueryType.ENTRYLEVEL
                ? MsgChannelType.MSGS_BY_CHANNEL_ID
                : MsgChannelType.MY_CAMPUS_FEEDS,
            channel_id: channelId,
          },
          direction: Direction.ASC,
          last: 5,
          after: null,
          sortBy: SortBy.CREATED_AT,
          mediaContent: searchMessage,
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
        }
      });
    } // eslint-disable-next-line
  }, [
    token,
    mediaContent,
    MessagesData,
    GetMessages,
    channelDetails.data,
    channelId,
    individualChannelId,
    channel,
    searchMessage,
  ]);

  useEffect(() => {
    if (MessagesData && !loading) {
      const newMessages = MessagesData.GetChannelMessages.edges;

      if (startCursor) {
        const updatedNewMessages = newMessages.map((newMessage) => {
          const filteredMessage = allMessages.find(
            (message) => message.node.id === newMessage.node.id
          );
          if (filteredMessage) {
            return {
              ...newMessage,
              node: {
                ...newMessage.node,
              },
            };
          }
          return newMessage;
        });
        setAllMessages(updatedNewMessages);
      } else {
        setAllMessages(newMessages);
      }

      const pageInfo = MessagesData.GetChannelMessages.pageInfo;
      setStartCursor(pageInfo.startCursor);
      setHasPreviousPage(pageInfo.hasPreviousPage);
    } // eslint-disable-next-line
  }, [MessagesData, loading, startCursor]);

  const byDate: { [key: string]: MessageEdge[] } | undefined =
    allMessages &&
    allMessages?.reduce(
      (obj: { [key: string]: MessageEdge[] }, item: MessageEdge) => {
        const date = new Date(item?.node?.created_at);
        const dateString = dayjs(date).format("DD/MM/YY");
        if (obj[dateString]) {
          obj[dateString].push(item);
          return obj;
        }

        obj[dateString] = [{ ...item }];
        return obj;
      },
      {}
    );
  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  const handleFileDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;

    if (scrollTop === 0 && !loading && hasPreviousPage) {
      fetchMore({
        variables: {
          last: ROWS_PER_PAGE,
          before: startCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.GetChannelMessages.edges;
          const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
          setStartCursor(pageInfo.startCursor);
          setHasPreviousPage(pageInfo.hasPreviousPage);

          const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
            ({ node: { id } }) =>
              newEdges.findIndex(({ node: { id: newId } }) => newId === id) !==
              -1
          );

          if (duplicateCheck.length > 0) return prevResult;

          return {
            GetChannelMessages: {
              edges: [...newEdges, ...allMessages],
              pageInfo,
              totalCount: MessagesData
                ? MessagesData.GetChannelMessages.totalCount
                : 0,
            },
          };
        },
      });
    }
  };

  const HandleDelete = (id: Number) => {
    DeleteMessage({
      variables: {
        token,
        msg_id: id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetChannelMessages,
          variables: {
            token,
            inst_id: InstId,
            input: {
              query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
              channel_id: channelId,
            },
            direction: Direction.ASC,
            last: 5,
            after: null,
            sortBy: SortBy.CREATED_AT,
            mediaContent: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Message Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
    setDeleteModal(!deleteModal);
  };
  useEffect(() => {
    // Scroll to the bottom after rendering new messages
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight;
    }
  }, [byDate]);

  return (
    <>
      <div className="academics__channel">
        <div className="academics__channel--datablock">
          <div className="academics__channel--datablock--left">
            {channel === ChannelQueryType.INDIVIDUAL ? null : (
              <div className="academics__channel--datablock--left--header">
                <span>
                  {" "}
                  #
                  {channelDetails
                    ? channelDetails.data?.GetChannelSubscribed.map(
                        (res) => res.channel_name
                      )
                    : EMPTY_STRING}
                </span>
                <Input
                  id="search"
                  placeholder="Search ..."
                  onChange={(e) => setSearchMessage(e.target.value)}
                />
              </div>
            )}

            <div
              className="academics__channel--datablock--left--messages"
              onScroll={handleScroll}
            >
              <ul ref={messageListRef}>
                {Object.entries(byDate)?.map(([date, messages]) => (
                  <React.Fragment key={date}>
                    <Divider>
                      <Chip label={date} />
                    </Divider>
                    {messages.map((message, index) => {
                      const parsedContent = parseMessageContent(
                        message.node.msg_content
                      );
                      const isImage =
                        message.node.msg_media_content.includes(".png") ||
                        message.node.msg_media_content.includes(".jpeg");
                      const isPdf =
                        message.node.msg_media_content.includes(".pdf");
                      const isExcel =
                        message.node.msg_media_content.includes(".xls") ||
                        message.node.msg_media_content.includes(".xlsx");
                      const isVideo =
                        message.node.msg_media_content.includes(".mp4");
                      const id = state.claims?.EMPLOYEE
                        ? state.empLoginId
                        : state.studentId;
                      const isSentUser = id === message.node.sent_user_id;

                      const combinedById = downLoadUrl.reduce(
                        (result, item) => {
                          if (!result[item.id]) {
                            result[item.id] = [item];
                          } else {
                            result[item.id].push(item);
                          }
                          return result;
                        },
                        {} as { [id: number]: MessageData[] }
                      );

                      return (
                        <li
                          key={index}
                          className={`channel__message-block--messages-list--messages--list${
                            isSentUser ? "--sender" : ""
                          }--li`}
                        >
                          <div
                            className={`channel__message-block--messages-list--messages--list${
                              isSentUser ? "--sender" : ""
                            }`}
                          >
                            {!isSentUser && (
                              <img
                                src={Avatar}
                                alt="/"
                                className="channel__message-block--messages-list--image"
                              />
                            )}
                            <div>
                              {!isSentUser && (
                                <Label variant="LabelBold">
                                  {userList.get(message.node.sent_user_id)}
                                </Label>
                              )}

                              {state.claims && state.claims.EMPLOYEE ? (
                                <TooltipForMultipleOption
                                  onClick={() =>
                                    setEnableToolTipModal(!enableToolTipModal)
                                  }
                                  
                                  placement="right"
                                  title={
                                    enableToolTipModal && (
                                      <>
                                        <ul className="feeds__messages--list--tooltip--ul">
                                          <li
                                            onClick={() => {
                                              setEditModal(!editModal);
                                              setUpdateMessage({
                                                message:
                                                  message.node.msg_content,
                                                id: message.node.id,
                                              });
                                            }}
                                            className="studentlist__table--more--fee"
                                          >
                                            <img src={Edit} alt="/" />
                                            <span> Edit Message</span>
                                          </li>
                                          <li
                                            onClick={() => {
                                              setDeleteModal(!deleteModal);
                                              setMessageId(message.node.id);
                                            }}
                                            className="studentlist__table--more--fee"
                                          >
                                            <img src={Delete} alt="/" />
                                            <span> Delete Message</span>
                                          </li>
                                        </ul>
                                      </>
                                    )
                                  }
                                >
                                  <div className="feeds__image">
                                    <span className="feeds__messages--list--time">
                                      {dayjs(message.node.created_at).format(
                                        "LT"
                                      )}
                                    </span>
                                    <img src={More} alt="/" />
                                  </div>
                                </TooltipForMultipleOption>
                              ) : (
                                <span className="feeds__messages--list--time">
                                  {dayjs(message.node.created_at).format("LT")}
                                </span>
                              )}
                              {message.node.msg_content && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: parsedContent,
                                  }}
                                />
                              )}
                              {combinedById[message.node.id]?.map(
                                ({ mediaContent, url }) => {
                                  if (isImage) {
                                    return (
                                      <a key={url} href={url}>
                                        <img
                                          src={url}
                                          alt="Loading...."
                                          className="channel__message-block--messages-list--messages--file-image"
                                        />
                                      </a>
                                    );
                                  } else if (isPdf || isExcel) {
                                    return (
                                      <a
                                        key={url}
                                        href={url}
                                        onClick={() => handleFileDownload(url)}
                                      >
                                        {getFileName(mediaContent)}
                                      </a>
                                    );
                                  } else if (isVideo) {
                                    return (
                                      <video
                                        key={url}
                                        width="320"
                                        height="240"
                                        controls
                                        src={url}
                                      >
                                        {mediaContent}
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
          {channel === ChannelQueryType.INDIVIDUAL ? null : (
            <>
              <div className="academics__channel--datablock--right">
                <StudentList />
              </div>
            </>
          )}
        </div>

        {channel === ChannelQueryType.INDIVIDUAL ? null : (
          <>
            <Button mode="send" onClick={() => setSendMessage(!sendMessage)} />
            <Button
              mode="excel"
              type="button"
              onClick={() => setImportModal(!importModal)}
            >
              Import Messages
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} />
          </>
        )}
      </div>
      <Modal
        isOpen={sendMessage}
        ariaHideApp={false}
        style={AccountingLedgerModalStyles}
      >
        <SendMessage
          setModalFlag={setSendMessage}
          Channel={ChannelQueryType.ENTRYLEVEL}
          setMessage={setMessage}
          operation={Operation.CREATE}
        />
      </Modal>
      <Modal
        isOpen={editModal}
        ariaHideApp={false}
        style={AccountingLedgerModalStyles}
      >
        <SendMessage
          setModalFlag={setEditModal}
          Channel={ChannelQueryType.ENTRYLEVEL}
          setMessage={setMessage}
          operation={Operation.UPDATE}
          updateMessage={updateMessage}
        />
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageImport setModalFlag={setImportModal} />
          </div>
        </div>
      </Modal>
      <DeleteModal
        id={messageId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <MessageModal
        handleClose={() =>
          setMessage({
            flag: false,
            message: "",
            operation: Operation.CREATE,
          })
        }
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={DeleteLoading} />
    </>
  );
};

export default MessageList;

import { useEffect, useState } from "react";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

const useEntryIdByStudent = () => {
  const [entry_id, set_entry_id] = useState(0);
  const { studentData } = useStudentDatabyId();
  const { USE_CLASS_KEY, USE_SEMESTER_KEY, USE_SECTION_KEY } =
    useInstitutionConfiguration();
  useEffect(() => {
    if (studentData.data && !studentData.loading) {
      if (USE_SECTION_KEY) {
        set_entry_id(studentData.data.nodes[0].acd_section.id);
      } else if (USE_SEMESTER_KEY) {
        set_entry_id(studentData.data.nodes[0].acd_semester.id);
      } else if (USE_CLASS_KEY) {
        set_entry_id(studentData.data.nodes[0].acd_class.id);
      }
    }
  }, [
    studentData.data,
    studentData.loading,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);

  return { entry_id };
};

export default useEntryIdByStudent;

import { gql } from "@apollo/client";

export const AddPayRollAcdYr = gql`
  mutation AddPayRollAcdYr(
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollAcdYrInput!
    $token: String!
  ) {
    AddPayRollAcdYr(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  BookListType,
  BooksReportType,
  EduateModule,
  ModuleName,
  PageFor,
  StudentReportType,
  UserType,
} from "../utils/Enum.types";

import Media from "../Modules/Library/MasterDataForBookEntry/Media/Index";
import Subjects from "../Modules/Library/MasterDataForBookEntry/Subjects/Index";
import Publisher from "../Modules/Library/MasterDataForBookEntry/Publishers/Index";
import Attribute from "../Modules/Library/MasterDataForBookEntry/Attribute/Index";
import Author from "../Modules/Library/MasterDataForBookEntry/Author/Index";
import Shelf from "../Modules/Library/MasterDataForBookEntry/Shelf/Index";
import Vendor from "../Modules/Library/MasterDataForBookEntry/VendorDetails/Index";
import Donor from "../Modules/Library/MasterDataForBookEntry/Donar/Index";

import Classification from "../Modules/Library/MasterDataForBookEntry/Classfication/Index";
import BookMasterKeyword from "../Modules/Library/MasterDataForBookEntry/BookMasterkeyword/Index";
import Keyword from "../Modules/Library/MasterDataForBookEntry/Keyword/Index";
import Languages from "../Modules/Library/MasterDataForBookEntry/Languages/Index";
import BookCategory from "../Modules/Library/MasterDataForBookEntry/BookCategory/Index";
import BookEntry from "../Modules/Library/BookEntry/Index";
import NewBook from "../Modules/Library/BookEntry/NewBook";
import FineSlab from "../Modules/Library/FineSlab/Index";
import FineAssociation from "../Modules/Library/FineSlab/Association";
import BookIssue from "../Modules/Library/BooksTransaction/BookIssue/Index";
import BookReturn from "../Modules/Library/BooksTransaction/BookReturn/Index";
import BookReservation from "../Modules/Library/BooksTransaction/BookReservation/Index";
import BooksSearch from "../Modules/Library/BooksSearch/Index";
import LibraryReports from "../Modules/Library/Reports/List/BooksReport";
import BookClearanceReport from "../Modules/Library/Reports/List/BookClearanceList";
import LibraryHome from "../Modules/Library/Home/Index";
import BooksAllocation from "../Modules/Library/BooksAllocation/Index";
import BooksNotAllocation from "../Modules/Library/BooksAllocation/BooksNotAllocation";
import AccessNoEdition from "../Modules/Library/AccessionNo/EditAccessionNo";
import LibStudentList from "../Modules/Library/students/List";
import { SwConfigQueryType } from "../Modules/HR/enums/Enum.types";
import Configurations from "../Modules/Configurations/PerModuleConfiguration";
import useEduateModuleConfig from "../customhooks/useModulesConfiguartion";
import Page404 from "../pages/Page404";
import useUserRightsByEmpId from "../Modules/UserRights/hooks/useUserRightsByEmpId";
import { useState } from "react";
import Import from "../Modules/Library/BookImport/Import";
import Department from "../Modules/Library/MasterDataForBookEntry/Department/Index";
import BooksDeptAllocation from "../Modules/Library/DepartmentAllocation/BooksDeptAllocation";
import BooksDeptNotAllocation from "../Modules/Library/DepartmentAllocation/BooksDeptNotAllocated";

const LibraryRoutes = () => {
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);
  const { error } = useEduateModuleConfig(EduateModule.LIBRARY);
  const { USE_LIBRARY } = useUserRightsByEmpId();

  return (
    <Routes>
      {error || !USE_LIBRARY.flag ? (
        <>
          <Route path="*" element={<Page404 />} />
        </>
      ) : (
        <>
          <Route
            path="/studentlist"
            element={
              <LibStudentList
                pageType={PageFor.GENERAL}
                queryType={StudentReportType.GENERAL}
                setModal={setModalFlag}
              />
            }
          />
          <Route path="/media" element={<Media type={PageFor.GENERAL} />} />
          <Route
            path="/department"
            element={<Department type={PageFor.GENERAL} />}
          />
          <Route
            path="/bookcategory"
            element={<BookCategory type={PageFor.GENERAL} />}
          />
          <Route
            path="/subjects"
            element={<Subjects type={PageFor.GENERAL} />}
          />
          <Route
            path="/publisher"
            element={<Publisher type={PageFor.GENERAL} />}
          />
          <Route
            path="/languages"
            element={<Languages type={PageFor.GENERAL} />}
          />
          <Route
            path="/attribute"
            element={<Attribute type={PageFor.GENERAL} />}
          />
          <Route path="/author" element={<Author type={PageFor.GENERAL} />} />
          <Route path="/shelf" element={<Shelf type={PageFor.GENERAL} />} />
          <Route
            path="/vendordetails"
            element={<Vendor type={PageFor.GENERAL} />}
          />
          <Route path="/donor" element={<Donor type={PageFor.GENERAL} />} />
          <Route
            path="/classification"
            element={<Classification type={PageFor.GENERAL} />}
          />
          <Route path="/keyword" element={<Keyword />} />
          <Route path="/bookmasterkeyword" element={<BookMasterKeyword />} />
          <Route
            path="/damaged/shelfallocation"
            element={
              <BookEntry
                pageFor={PageFor.GENERAL}
                bookListType={BookListType.DAMAGED}
              />
            }
          />
          <Route path="/newbookentry" element={<NewBook />} />
          <Route path="/fineslab" element={<FineSlab />} />
          <Route path="/fineassociation" element={<FineAssociation />} />
          <Route
            path="/bookissue/student"
            element={<BookIssue BorrowerType={UserType.STUDENT} />}
          />
          <Route
            path="/bookissue/staff"
            element={<BookIssue BorrowerType={UserType.EMPLOYEE} />}
          />
          <Route
            path="/bookreturn/student"
            element={
              <BookReturn
                type={PageFor.GENERAL}
                BorrowerType={UserType.STUDENT}
              />
            }
          />
          <Route
            path="/bookreturn/staff"
            element={
              <BookReturn
                type={PageFor.GENERAL}
                BorrowerType={UserType.EMPLOYEE}
              />
            }
          />
          <Route path="/bookreservation" element={<BookReservation />} />
          <Route
            path="/booksearch"
            element={
              <BooksSearch
                pageType={PageFor.GENERAL}
                bookListType={BookListType.BY_CLASSIFICATION}
              />
            }
          />
          <Route
            path="/reports/currentlyissuedbooks"
            element={<LibraryReports type={BooksReportType.BOOKS_ISSUED} />}
          />
          <Route
            path="/reports/currentlyrecievedbooks"
            element={<LibraryReports type={BooksReportType.BOOKS_RETURNED} />}
          />
          <Route
            path="/reports/bothissuedandreturned"
            element={
              <LibraryReports
                type={BooksReportType.BOOKS_ISSUED_AND_RETURNED}
              />
            }
          />
          <Route
            path="/reports/booksoverdue"
            element={<LibraryReports type={BooksReportType.BOOKS_OVER_DUE} />}
          />
          <Route
            path="/reports/finecollection"
            element={
              <LibraryReports type={BooksReportType.BOOKS_FINE_COLLECTION} />
            }
          />
          <Route
            path="/reports/booksclearancelist"
            element={<BookClearanceReport />}
          />
          <Route
            path="/booksallocation/allocatebooks"
            element={<BooksAllocation />}
          />
          <Route
            path="/booksallocation/notallocatebooks"
            element={<BooksNotAllocation />}
          />
          <Route
            path="/booksallocation/booksdeptallocation"
            element={<BooksDeptAllocation />}
          />
          <Route
            path="/booksallocation/booksdeptNotallocation"
            element={<BooksDeptNotAllocation />}
          />

          <Route
            path="/accessionno/editaccessionno"
            element={<AccessNoEdition />}
          />
          <Route
            path="/configurations"
            element={
              <Configurations
                config_query_type={SwConfigQueryType.INST_BY_MODULE}
                str_value={ModuleName.LIBRARY}
                int_value={0}
                setModalFlag={setModalFlag}
                pageType={PageFor.GENERAL}
              />
            }
          />
          <Route path="/import" element={<Import />} />
          <Route path="/" element={<LibraryHome DashBoardRequired={true} />} />
        </>
      )}
    </Routes>
  );
};

export default LibraryRoutes;

import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

import useToken from "../../../customhooks/useToken";
import { Button } from "../../../stories/Button/Button";
import Home from "../Home/Index";
import TotalStudents from "../../../images/TotalMale.svg";
import ReadyToImport from "../../../images/Present.svg";
import ContainsError from "../../../images/Absent.svg";
import Info from "../../../images/Information.svg";
import DownArrow from "../../../images/DownArrow.svg";
import FileAttach from "../../../images/BrowseFiles.svg";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Title } from "../../../stories/Title/Title";
import {
  getHeaderRowStyle,
  toStandardDate,
  handleClear,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import { _genderRegex, _isPhone } from "../../../utils/validationRules";
import Eduate from "../../../images/Eduate_Logo_image.png";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  Operation,
  ParentQueryType,
  SortBy,
  TableHeaders,
} from "../../../utils/Enum.types";

import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import { HEADER_ALIGNMENT_CENTER } from "../../Library/Constants";
import LoadingModal from "../../../pages/LoadingModal";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import { AddParentUser } from "../../../queries/parent/mutations/new";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import { GetParents } from "../../../queries/parent/list";
import { CustomTooltip } from "../../../styles/TooltipStyles";

const ParentImport = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();

  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });

  const [expanded, setExpanded] = useState(false);
  const [schema, setSchema] = useState<any>();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [records, setRecords] = useState<any[]>([]);

  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);

  const [AddAuthParent, { loading: addLoading }] = useMutation(AddParentUser, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { InstDetails } = useInstDetails(1);

  const {
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();

  const instConfig = [
    {
      Headers: "Name",
      key: "first_name",
      width: 25,
    },
    {
      Headers: "Email",
      key: "parent_email",
      width: 25,
    },
    {
      Headers: "Mobile",
      key: "parent_mobile",
      width: 25,
    },
    {
      Headers: "Parents Type",
      key: "parent_type",
      width: 25,
    },
  ];
  const studentData = [
    {
      Headers: "Name",
      key: "first_name",
      width: 25,
      use_in_sheet: true,
    },
    {
      Headers: "Email",
      key: "parent_email",
      width: 25,
      use_in_sheet: true,
    },
    {
      Headers: "Mobile",
      key: "parent_mobile",
      width: 25,
      use_in_sheet: true,
    },
    {
      Headers: "ParentsType",
      key: "parent_type",
      width: 25,
      use_in_sheet: true,
    },
  ];
  const student_schema = {
    Name: {
      name: "first_name",
      required: true,
    },

    Email: {
      name: "parent_email",
      required: false,
    },
    Mobile: {
      name: "parent_mobile",
      required: true,
    },
    ParentsType: {
      name: "parent_type",
      required: true,
    },
  };

  const schemaKeys = Object.keys(student_schema);

  const filteredKeys = schemaKeys.filter((key) => {
    // @ts-ignore
    const fieldSchema = student_schema[key];
    if (fieldSchema.required || fieldSchema.required === undefined) {
      const hasData = records.some(
        (record) => record[key] !== undefined && record[key] !== ""
      );
      return hasData;
    }
    return false;
  });

  const InstName = InstDetails.data?.nodes[0]?.inst_name!;

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Reference Data");
    const worksheet1 = workbook.addWorksheet("ParentInfo");
    const dataRows = [
      "Parent Name",
      "surajkumar@gmail.com",
      "9148223692",
      "Father/Mother/Gaurdian",
    ];
    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });
                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);
                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");
                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;
                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = instConfig.map((f) => f.Headers);

                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  const filteredStudentExcelData = studentData.filter(
                    (column) => column.use_in_sheet
                  );

                  let studentInfoHeader = filteredStudentExcelData.map(
                    (f) => f.Headers
                  );
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      schema &&
        // eslint-disable-next-line
        Object.keys(schema).map((key) => {
          // @ts-ignore
          let keySchema = student_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }

          if (key === "ContactNumber" && !_isPhone.mask.test(keyValue)) {
            let errorObj = {
              column: key,
              error: "Invalid Phone",
            };
            errors.push(errorObj);
          }

          if (
            key === "DateOfBirth" &&
            !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          ) {
            let errorObj = {
              column: key,
              error:
                "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
            };
            errors.push(errorObj);
          }
          if (
            key === "DateOfAdmission" &&
            !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          ) {
            let errorObj = {
              column: key,
              error:
                "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
            };
            errors.push(errorObj);
          }

          if (key === "Gender" && !_genderRegex.mask.test(keyValue)) {
            let errorObj = {
              column: key,
              error: "Invalid Gender, the format should be male or female",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleAddStudent = async () => {
    for (const record of records.filter(
      (record) => !record.isValidatedAndError
    )) {
      try {
        await AddAuthParent({
          variables: {
            token,
            input: {
              inst_id: InstId,
              first_name: removeMoreSpace(record.Name),
              parent_address: EMPTY_STRING,
              parent_email: record.Email
                ? removeMoreSpace(record.Email.toLowerCase())
                : EMPTY_STRING,
              parent_mobile: record.Mobile
                ? `+91${record.Mobile}`
                : EMPTY_STRING,
              parent_place: EMPTY_STRING,
              parent_state: EMPTY_STRING,
              parent_pin: EMPTY_STRING,
              parent_occup: EMPTY_STRING,
              parent_qualification: EMPTY_STRING,
              parent_aadhar: EMPTY_STRING,
              parent_type: record.ParentsType,
              parent_profile_filename: EMPTY_STRING,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetParents,
              variables: {
                after: null,
                direction: Direction.ASC,
                first: ROWS_PER_PAGE,
                inst_id: InstId!,
                queryType: ParentQueryType.PARENTS_BY_INST_ID,
                token,
                name: EMPTY_STRING,
                sortBy: SortBy.FIRST_NAME,
              },
            },
          ],
          onError: (err) => {
            record.errors = record.errors ? record.errors : [];
            record.errors.push(err.message);
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);
          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
                isValidated: false,
                isValidatedAndError: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });

  useEffect(() => {
    if (
      USE_CLASS_KEY ||
      USE_DEPARTMENT_KEY ||
      USE_SEMESTER_KEY ||
      USE_CATEGORY_KEY ||
      USE_BRANCH_KEY
    ) {
      setSchema(student_schema);
    } // eslint-disable-next-line
  }, [
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_BRANCH_KEY,
  ]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Import Parent Data</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Parents
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record?.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 && schema ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>

                          {filteredKeys.map((key, index) => {
                            return <TableCell key={index}>{key}</TableCell>;
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => !record?.errors)
                          .map((record, index) => (
                            <TableRow key={index}>
                              <TableCell id="td-center">{index + 1}</TableCell>
                              {/* @ts-ignore */}
                              {/* eslint-disable-next-line */}
                              {filteredKeys.map((key, colIndex) => {
                                // @ts-ignore
                                const fieldSchema = student_schema[key];

                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                ) {
                                  return (
                                    <TableCell key={colIndex}>
                                      {" "}
                                      {record[key]}
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          {/* eslint-disable-next-line */}
                          {filteredKeys.map((key, index) => {
                            // @ts-ignore
                            const fieldSchema = student_schema[key];
                            if (
                              fieldSchema.required ||
                              fieldSchema.required === undefined
                            ) {
                              return <TableCell key={index}>{key}</TableCell>;
                            }
                          })}
                          <TableCell id="td-center">Error Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => {
                            const filteredHeaders: string[] = [];
                            return (
                              <TableRow key={index}>
                                {/* <TableCell
                                  className={classes.stickyColumnCheckbox}
                                  id="td-center"
                                ></TableCell> */}

                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                {Object.keys(schema).map((key, colIndex) => {
                                  // @ts-ignore
                                  const fieldSchema = student_schema[key];
                                  if (
                                    fieldSchema.required ||
                                    fieldSchema.required === undefined
                                  ) {
                                    filteredHeaders.push(key);

                                    return (
                                      <TableCell key={colIndex}>
                                        
                                        {record[key]}
                                      </TableCell>
                                    );
                                  }
                                  return null;
                                })}

                                <TableCell className="import-excel-data__datablock--error">
                                  <CustomTooltip
                                    title={
                                      <ul>
                                        {record?.errors
                                          ? record?.errors?.map(
                                              (error: any, index: number) => {
                                                return (
                                                  <li key={index}>
                                                    {index + 1})&nbsp;
                                                    {typeof error === "string"
                                                      ? error
                                                      : "Error message = " +
                                                        error.error +
                                                        ", column = " +
                                                        error.column}
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <div className="import-excel-data__datablock--tableblock--error-message">
                                      <span>Errors</span>
                                      <img src={Info} alt="/" />
                                    </div>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button onClick={handleAddStudent} mode="excel">
              Import Parents
            </Button>

            <Button onClick={handleClear} mode="clear" />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={downloadExcel}>
              Generate Template XLS File
            </Button>
          </div>
        </div>
      </div>

      <LoadingModal flag={addLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ParentImport;

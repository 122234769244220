import React, { useContext, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import UPIReceipts from "../../../images/UPIReceipts.svg";
import ChequeReceipts from "../../../images/ChequeReceipts.svg";
import DDReceipts from "../../../images/DDReceipts.svg";
import NeftReceipts from "../../../images/NEFT.svg";
import ImpsReceipts from "../../../images/IMPS.svg";
import CardSwipe from "../../../images/CardSwipable.svg";

import More from "../../../images/More.svg";

import {
  DashBoardFor,
  InstitutionConfigurationTypes,
} from "../../../utils/Enum.types";

import { AcctDashBoardDetails } from "./types";
import { toStandardDate } from "../../../utils/UtilFunctions";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import ApprovedRequest from "../Voucher/MakerAndChecker/Requests";
import ApprovedBy from "../../../images/ApprovedBy.svg";
import ViewStatus from "../../../images/ViewStatus.svg";

import {
  ApprovedStatusModal,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import Status from "../Voucher/MakerAndChecker/Status";
import View from "../Voucher/MakerAndChecker/View";
import Modal from "react-modal";
import Close from "../../../images/Close.svg";
import { PendingStatusFor } from "../Voucher/MakerAndChecker/View";
import {
  InstitutionAcctConfigurationTypes,
  MkCkQueryType,
} from "../common/Enum.types";
import useMkckVoucherData from "../hooks/useMkckVoucherData";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import { EMPTY_STRING } from "../../../utils/constants";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import GraphDetails from "./GraphDetails";
interface Props {
  dashBoardDetails: AcctDashBoardDetails;
}
const EmpBottomDetails = ({ dashBoardDetails }: Props) => {
  const { serverDate } = useServerDateandTime();
  const { state, dispatch } = useContext(AppContext);
  const [viewModal, setViewModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [completePendingRequests, setCompletePendingRequests] = useState(false);

  const {
    configData: { data },
  } = useSwConfigData([
    InstitutionAcctConfigurationTypes.ENABLE_MAKER_CHECKER,
    InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT,
    InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableMacherChecker = false;
    let enableChallanReceipt = false;
    let enableFeeReceipt = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.ENABLE_MAKER_CHECKER:
            enableMacherChecker = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT:
            enableChallanReceipt = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT:
            enableFeeReceipt = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enableMacherChecker,
      enableChallanReceipt,
      enableFeeReceipt,
    };
  };

  const { enableChallanReceipt, enableMacherChecker, enableFeeReceipt } =
    filterDataByConfigKey(data ? data.GetSwConfigVariables : []);

  const { mkckVouchers } = useMkckVoucherData(
    MkCkQueryType.MK_CK_PENDING_BY_USER_ID,
    EMPTY_STRING
  );

  return (
    <>
      <div className="accounts-dashboard__datablock">
        <div className="accounts-dashboard__details--block h-100">
          <div className="accounts-dashboard__details--block--title">
            <Title variant="subtitle1">{`OverAll graph (As On ${toStandardDate(
              serverDate
            )})`}</Title>

            <img src={More} alt="/" />
          </div>
          <div className="accounts-dashboard__datablock--graph">
            <GraphDetails pageType={DashBoardFor.GENERAL} />
          </div>
        </div>
        {enableFeeReceipt ? (
          <div className="accounts-dashboard__datablock--frame h-100">
            <div className="accounts-dashboard__details--block--receipts-block">
              <div className="accounts-dashboard__details--block--title">
                <Title variant="subtitle1">Receipts Report</Title>
                <img src={More} alt="/" />
              </div>
              <ul className="accounts-dashboard__details--block--receipts-block--data">
                {/* <li className="accounts-dashboard__details--block--g-count--flex">
                <img src={CashReceipts} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    Cash Receipts
                  </span>
                  <span
                    className={
                      dashBoardDetails.cash_receipts_count > 9999
                        ? "accounts-dashboard__details--block--g-count--block--total font-10"
                        : "accounts-dashboard__details--block--g-count--block--total"
                    }
                  >
                    ({dashBoardDetails.cash_receipts_count})
                  </span>
                </div>
              </li>
              <li className="accounts-dashboard__details--block--g-count--flex">
                <img src={BankReceipts} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    Bank Receipts
                  </span>
                  <span className="accounts-dashboard__details--block--g-count--block--total">
                    ({dashBoardDetails.bank_receipts_count})
                  </span>
                </div>
              </li> */}
                <li className="accounts-dashboard__details--block--g-count--flex">
                  <img src={UPIReceipts} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--g-count--block--text">
                      UPI
                    </span>
                    <span className="accounts-dashboard__details--block--g-count--block--total">
                      ({dashBoardDetails.upi_receipts_count})
                    </span>
                  </div>
                </li>
                <li className="accounts-dashboard__details--block--g-count--flex">
                  <img src={ChequeReceipts} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--g-count--block--text">
                      Cheque
                    </span>
                    <span className="accounts-dashboard__details--block--g-count--block--total">
                      ({dashBoardDetails.cheque_receipts_count})
                    </span>
                  </div>
                </li>
                <li className="accounts-dashboard__details--block--g-count--flex">
                  <img src={DDReceipts} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--g-count--block--text">
                      DD
                    </span>
                    <span className="accounts-dashboard__details--block--g-count--block--total">
                      ({dashBoardDetails.dd_receipts_count})
                    </span>
                  </div>
                </li>
                <li className="accounts-dashboard__details--block--g-count--flex">
                  <img src={NeftReceipts} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--g-count--block--text">
                      Neft
                    </span>
                    <span className="accounts-dashboard__details--block--g-count--block--total">
                      ({dashBoardDetails.neft_receipts_count})
                    </span>
                  </div>
                </li>
                <li className="accounts-dashboard__details--block--g-count--flex">
                  <img src={ImpsReceipts} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--g-count--block--text">
                      IMPS
                    </span>
                    <span className="accounts-dashboard__details--block--g-count--block--total">
                      ({dashBoardDetails.imps_receipts_count})
                    </span>
                  </div>
                </li>
                <li className="accounts-dashboard__details--block--g-count--flex">
                  <img src={CardSwipe} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--g-count--block--text">
                      CardSwipe
                    </span>
                    <span className="accounts-dashboard__details--block--g-count--block--total">
                      ({dashBoardDetails.cardswipe_receipts_count})
                    </span>
                  </div>
                </li>
                {enableChallanReceipt ? (
                  <li className="accounts-dashboard__details--block--g-count--flex">
                    <img src={UPIReceipts} alt="/" />
                    <div className="accounts-dashboard__details--block--g-count--block">
                      <span className="accounts-dashboard__details--block--g-count--block--text">
                        Challans
                      </span>
                      <span className="accounts-dashboard__details--block--g-count--block--total">
                        ({dashBoardDetails.upi_receipts_count})
                      </span>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
            {enableMacherChecker && (
              <div className="accounts-dashboard__voucher-checker">
                <div className="accounts-dashboard__details--block--title ">
                  <Title variant="subtitle1">
                    Pending Requests
                    <span className="LabelPrimary">
                      {mkckVouchers.data?.GetAcctVouchersMkCk.totalCount}
                    </span>
                  </Title>

                  <img src={More} alt="/" />
                </div>
                <div className="accounts-dashboard__voucher-checker--li">
                  <ul>
                    {mkckVouchers.data?.GetAcctVouchersMkCk.edges.map(
                      (edge, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li>
                              <div
                                className="vouchers-approved-request__datablock--li"
                                onClick={() => {
                                  if (
                                    edge.node.created_emp_details.id ===
                                    state.empLoginId
                                  )
                                    return;
                                  else {
                                    setViewModal(!viewModal);
                                    dispatch({
                                      type: payloadTypes.SET_MK_CK_ID,
                                      payload: { mkckId: edge.node.id },
                                    });
                                  }
                                }}
                              >
                                <div>
                                  <div className="vouchers-approved-request__datablock--v-details">
                                    <b>
                                      {index + 1}. {"Payment Voucher"}
                                    </b>
                                    <span>
                                      {" "}
                                      (Voucher No : {edge.node.mkck_no})
                                    </span>
                                  </div>
                                  <span className="vouchers-approved-request__datablock--approvedby">
                                    Pending
                                  </span>
                                  <div className="vouchers-approved-request__datablock--approved-details">
                                    <span>
                                      {
                                        edge.node.created_emp_details
                                          .pr_designation_details
                                          .designation_desc
                                      }
                                    </span>
                                    <img src={ApprovedBy} alt="/" />
                                    <span>
                                      {edge.node.created_emp_details
                                        .emp_first_name +
                                        edge.node.created_emp_details
                                          .emp_middle_name +
                                        edge.node.created_emp_details
                                          .emp_last_name}
                                    </span>
                                  </div>
                                </div>
                                <div className="vouchers-approved-request__datablock--date">
                                  {toStandardDate(edge.node.mkck_date)}
                                </div>
                              </div>
                              <div className="vouchers-approved-request__datablock--approved-status">
                                <div
                                  className="vouchers-approved-request__datablock--image"
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_MK_CK_ID,
                                      payload: { mkckId: edge.node.id },
                                    });
                                    setStatusModal(!statusModal);
                                  }}
                                >
                                  <img src={ViewStatus} alt="" />
                                  <span>View Status</span>
                                </div>
                              </div>
                            </li>
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className="accounts-dashboard__voucher-checker--view">
                  <span
                    onClick={() =>
                      setCompletePendingRequests(!completePendingRequests)
                    }
                  >
                    View More
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View
              setModalFlag={setViewModal}
              pageType={MkCkQueryType.MK_CK_PENDING}
              type={PendingStatusFor.CHECKER}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setViewModal(!viewModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={statusModal}
        style={ApprovedStatusModal}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Status />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStatusModal(!statusModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={completePendingRequests}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ApprovedRequest
              setModalFlag={setCompletePendingRequests}
              pageType={MkCkQueryType.MK_CK_PENDING}
              type={PendingStatusFor.CHECKER}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setCompletePendingRequests(!completePendingRequests)
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EmpBottomDetails;

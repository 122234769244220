import React, { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  TableHeaders,
} from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcdStdCompleteTestReportForDerivedTestData,
  GetAcdStdCompleteTestReportForDerivedTestVars,
} from "../../types/derivatives";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { GetAcdStdCompleteTestReportForDerivedTest } from "../../queries/derivatives";
import InstDetailsForFilter from "../InstDetails";
import Finalize from "../../../../images/Finalize_Marks.svg";

import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import { GetTestConducteDetailsByNode } from "../../queries/test/query";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import { FinalizeTestMarks } from "../../queries/test/mutation";
import { msgType } from "../../../../utils/Form.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import MessageModal from "../../../../pages/MessageModal";
import { GetAcdTestSubjectsByTestConductIdData } from "../../hooks/useTestClassSubjects";
import LoadingModal from "../../../../pages/LoadingModal";
import { toIsoDate, toStandardDate } from "../../../../utils/UtilFunctions";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import jsPDF from "jspdf";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import autoTable from "jspdf-autotable";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../hooks/useTestStatus";
import { singleNodeVars } from "../../../../Types/Accounting";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";

const Derivatives = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId, entryId, testId } = useParams();

  const { state } = useContext(AppContext);
  const { entry_level } = useInstitutionConfiguration();
  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { serverDate } = useServerDateandTime();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [GetTestReport, { data: derivativesData }] = useLazyQuery<
    GetAcdStdCompleteTestReportForDerivedTestData,
    GetAcdStdCompleteTestReportForDerivedTestVars
  >(GetAcdStdCompleteTestReportForDerivedTest);

  const testNames = derivativesData
    ? derivativesData.GetAcdStdCompleteTestReportForDerivedTest[0]
    : null;
  const rowSpan = testNames
    ? testNames.student_test_marks[0].std_marks_details.length
    : null;

  const subjectDetails =
    (derivativesData &&
      derivativesData.GetAcdStdCompleteTestReportForDerivedTest.flatMap((res) =>
        res.student_test_marks[0].std_marks_details.map(
          (data) => data.subj_desc
        )
      )) ||
    [];

  const subjectCode =
    (derivativesData &&
      derivativesData.GetAcdStdCompleteTestReportForDerivedTest.flatMap((res) =>
        res.student_test_marks[0].std_marks_details.map(
          (data) => data.subj_code
        )
      )) ||
    [];

  const [GetAcdSubjects, { data: TestDetails, loading: TestLoading }] =
    useLazyQuery<GetAcdTestSubjectsByTestConductIdData, singleNodeVars>(
      GetTestConducteDetailsByNode,
      {
        variables: {
          token,
          id: state.testConductId || Number(testId),
        },
      }
    );
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.BY_TEST_CLASS_ID,
    Number(entryId)!
  );

  const { flag } = useCheckAllocationType();

  useEffect(() => {
    if (token && state.ActiveAcdYr && entry_level) {
      GetTestReport({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          student_ids: [],
          input: {
            acd_test_class_id: testId ? Number(testId) : 0,
            entry_level: entry_level,
            entry_id: Number(entryId),
          },
        },
      });
    }
  }, [
    token,
    state.ActiveAcdYr,
    entry_level,
    GetTestReport,
    testId,
    entryId,
    InstId,
  ]);

  useEffect(() => {
    if ((state.testConductId || testId) && token) {
      GetAcdSubjects();
    }
  }, [state.testConductId, GetAcdSubjects, testId, token]);

  const [FinalizeMarks, { loading }] = useMutation(FinalizeTestMarks, {
    onError: (e) =>
      setMessage({
        message: e.message,
        flag: true,
        operation: Operation.NONE,
      }),
  });
  const handleFinalize = () => {
    FinalizeMarks({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        date_of_finalize: toIsoDate(TODAY_DATE),
        input: {
          inst_id: InstId,
          acd_test_class_id: testId,
          entry_id: entryId,
          entry_level,
          test_type_id: 0,
        },
        per_std_subj_allocation: flag,
      },

      refetchQueries: [
        {
          query: GetAcdStdCompleteTestReportForDerivedTest,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            student_ids: [],
            input: {
              acd_test_class_id: testId,
              entry_level: entry_level,
              entry_id: Number(entryId),
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.FinalizeTestMarks) {
        setMessage({
          message: `Test: ${
            TestDetails
              ? TestDetails.node.test_name_details.test_name
              : EMPTY_STRING
          }  finalized`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  const testLabels = testNames
    ? testNames.student_test_marks.map((th) => th.acd_test_name)
    : 0 || [];
  const HeadersForPdf = [
    {
      title: "SlNo",
      field: "Slno",
    },
    { title: "Admission No", field: "AdmissionNo" },
    { title: "Student Name", field: "Name" },
    ...testLabels.map((label: string) => ({ title: label, field: label })),
  ];
  // eslint-disable-next-line
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Academics Test/Exam Reports`, 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: HeadersForPdf.map((col) => ({
                    ...col,
                    dataKey: col.field,
                    styles: { fontSize: 18 },
                  })),

                  body: derivativesData
                    ? derivativesData.GetAcdStdCompleteTestReportForDerivedTest.map(
                        (data, index) => {
                          return {
                            Slno: index + 1,
                            AdmissionNo: data.student_details.std_adm_no,
                            Name: `${data.student_details.first_name} ${data.student_details.middle_name} ${data.student_details.last_name}`,
                            Subject_Description: subjectDetails
                              ? subjectDetails.map((res) => res)
                              : [],
                          };
                        }
                      )
                    : [],

                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Derivatives</Title>
      <div className="derivatives-list">
        <InstDetailsForFilter />
        <div className="derivatives-list__title">
          {TestDetails
            ? TestDetails.node.test_name_details.test_name
            : EMPTY_STRING}
          <span className="derivatives-list__title--derivative">
            Derivative
          </span>
          ( {TestDetails ? TestDetails?.node.derived_method : EMPTY_STRING}){" "}
        </div>
        <div className="derivatives-list__tableblock">
          <TableContainer className="derivatives-list__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow className="derivatives-list__table--groupheader">
                  <TableCell rowSpan={2}>{TableHeaders.SLNO}</TableCell>
                  <TableCell rowSpan={2}>
                    {TableHeaders.ADMISSION_NUMBER}
                  </TableCell>
                  <TableCell rowSpan={2}>{TableHeaders.NAME}</TableCell>
                  <TableCell rowSpan={2}>
                    {TableHeaders.SUBJECT_DESCRIPTION}
                  </TableCell>
                  {testNames
                    ? testNames.student_test_marks.map((th) => {
                        return (
                          <TableCell key={th.acd_test_class_id}>
                            {th.acd_test_name}
                          </TableCell>
                        );
                      })
                    : null}
                </TableRow>
                <TableRow className="derivatives-list__table--subheader">
                  {testNames
                    ? testNames?.student_test_marks.map((th) => {
                        return (
                          <TableCell key={th.acd_test_class_id}>
                            {th.test_total_max_marks !== 0 ? (
                              <>
                                {th.test_total_max_marks} /{" "}
                                {th.test_total_min_marks}
                              </>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        );
                      })
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {derivativesData
                  ? derivativesData.GetAcdStdCompleteTestReportForDerivedTest.map(
                      (res, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell
                                rowSpan={Number(rowSpan) + 2}
                                id="td-center"
                                className="derivatives-list__table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                rowSpan={Number(rowSpan) + 2}
                                className="derivatives-list__table--number"
                              >
                                {res.student_details.std_adm_no}
                              </TableCell>
                              <TableCell
                                rowSpan={Number(rowSpan) + 2}
                                className="derivatives-list__table--number"
                              >
                                {res.student_details.first_name +
                                  " " +
                                  res.student_details.middle_name +
                                  " " +
                                  res.student_details.last_name}
                              </TableCell>
                            </TableRow>
                            {[...Array(rowSpan)].map((sub, subIndex) => {
                              // @ts-ignore
                              const dataIndex = index * rowSpan + subIndex;

                              return (
                                <TableRow key={subIndex}>
                                  <TableCell className="derivatives-list__table--sub">
                                    {subjectDetails?.[dataIndex]}
                                    <span>({subjectCode?.[dataIndex]})</span>
                                  </TableCell>
                                  {[
                                    ...Array(
                                      testNames
                                        ? testNames.student_test_marks.length
                                        : 0
                                    ),
                                  ].map((_, testIndex) => {
                                    return (
                                      <TableCell
                                        id="td-center"
                                        className="derivatives-list__table--marks"
                                      >
                                        {
                                          res.student_test_marks[testIndex]
                                            .std_marks_details[subIndex]
                                            .std_marks_scored
                                        }
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                            <TableRow>
                              <TableCell className="total-count">
                                Total
                              </TableCell>

                              {[
                                ...Array(
                                  testNames
                                    ? testNames.student_test_marks.length
                                    : 0
                                ),
                              ].map((_, testTotalIndex) => {
                                return (
                                  <TableCell
                                    id="td-center"
                                    className="derivatives-list__table--t-marks"
                                  >
                                    {
                                      res.student_test_marks[testTotalIndex]
                                        .test_std_total_marks_scored
                                    }
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </React.Fragment>
                        );
                      }
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {statusOfSubject
          ? statusOfSubject.data?.GetAcdTestMarksStatus.map((edge, index) => {
              return (
                <>
                  {!edge.is_report_generated && (
                    <Button onClick={() => handleFinalize()}>
                      <img src={Finalize} alt="/" />
                      Finalize and View
                    </Button>
                  )}
                </>
              );
            })
          : null}

        {/* <Button
          mode="pdf"
          onClick={() => {
            downloadPdf();
          }}
        /> */}
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={TestLoading || loading} />

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Derivatives;

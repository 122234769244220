import React, { useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import Interchange from "../../../images/PostingIcon.svg";
import { CustomModal } from "./Index";
import ReorderTable from "./ReorderTable";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  branchId: number;
}

const data = [
  {
    branch: "Kannada Medium",
    fromClass: {
      class: "1st Standard",
      sections: ["Section 1", "Section2", "Section3"],
    },
    toClass: {
      class: "2nd Standard",
      sections: ["Section 1", "Section2", ""],
    },
  },
];

const ViewSection = ({ setModal, branchId }: Props) => {
  const [reorder, setReorder] = useState(false);
  return (
    <>
      <Title>Selected Branch</Title>
      <div className="acd-posting__view-section">
        <div className="acd-posting__view-section--data">
          <ul>
            {data.map((data, index) => {
              return (
                <li>
                  <div key={index} className="acd-posting__data--right--title">
                    <b>{data.fromClass.class}</b>
                    <b>{data.toClass.class}</b>
                  </div>

                  <li className="acd-posting__data--right--sublist">
                    <span style={{ display: "block" }}>
                      {data.fromClass.sections.map((section, secIndex) => (
                        <React.Fragment key={secIndex}>
                          {section}
                          <br />
                        </React.Fragment>
                      ))}
                    </span>
                    <img src={Interchange} alt="/" />
                    <div className="acd-posting__data--right--moving-sec">
                      {data.toClass.sections === null ? (
                        <b>Passout</b>
                      ) : (
                        <>
                          {data.toClass.sections.map((section, secIndex) => (
                            <span key={secIndex}>
                              {section}
                              <br />
                            </span>
                          ))}
                        </>
                      )}
                    </div>
                  </li>
                </li>
              );
            })}
          </ul>
        </div>
        <Button mode="reorder" onClick={() => setReorder(!reorder)} />
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <CustomModal
        isOpen={reorder}
        onRequestClose={() => setReorder(!reorder)}
        contentComponent={
          <ReorderTable setModal={setReorder} branchId={branchId} />
        }
      />
    </>
  );
};

export default ViewSection;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { FeeDemandTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import DepartmentImage from "../../../../images/department.svg";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import { formatter } from "../../../../utils/UtilFunctions";

import useDemandTree from "./useDemandTree";
import { PageFor, StudentStatus } from "../../../../utils/Enum.types";
import useAcctTableJson from "../../json/useAcctTableJson";

import { EMPTY_STRING } from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

interface tableType {
  level: string;
  level_name: string;
  description: string;
  cur_std_no: number;
  tc_std_no: number;
  ne_std_no: number;
  std_demand_ob: number;
  std_demand_amt: number;
  std_demand_concession: number;
  tc_std_demand_amt: number;
  ne_std_demand_amt: number;
  total_demand: number;
  id: number;
}
const { AccountsTitles } = require("../../json/title.json");

interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const TableView = ({ pageType, setModalFlag }: Props) => {
  const { Accounts_Table } = useAcctTableJson();
  const navigate = useNavigate();
  const [table, setTable] = useState<tableType[]>([]);
  const { format } = formatter;

  const [status, setStatus] = useState("");

  const {
    demandTreeData: { data, loading },
  } = useDemandTree(status);

  const [level_ids, setLevelIds] = useState({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });
  const [parent, setParent] = useState(0);
  // eslint-disable-next-line

  const total = table.reduce(
    (acc, item) => {
      acc.studentCount += item.cur_std_no;
      acc.neStudentCount += item.ne_std_no;
      acc.tcStudentCount += item.tc_std_no;
      acc.totalAmountCollected += item.total_demand;
      acc.std_demand_ob += item.std_demand_ob;
      acc.std_demand_amt += item.std_demand_amt;
      acc.std_demand_concession += item.std_demand_concession;

      return acc;
    },
    {
      studentCount: 0,
      neStudentCount: 0,
      tcStudentCount: 0,
      totalAmountCollected: 0,
      std_demand_ob: 0,
      std_demand_amt: 0,
      std_demand_concession: 0,
    }
  );

  useEffect(() => {
    if (data && !loading) {
      setTable(
        data.GetStdDemandSummaryTree.map((tree_data) => ({
          level: tree_data.level_1,
          level_name: tree_data.level_1_name,
          description: tree_data.level_1_desc,
          cur_std_no: tree_data.cur_std_no,
          tc_std_no: tree_data.tc_std_no,
          ne_std_no: tree_data.ne_std_no,
          std_demand_ob: tree_data.std_demand_ob,
          std_demand_amt: tree_data.std_demand_amt,
          std_demand_concession: tree_data.std_demand_concession,
          tc_std_demand_amt: tree_data.tc_std_demand_amt,
          ne_std_demand_amt: tree_data.ne_std_demand_amt,
          total_demand: tree_data.total_demand,
          id: tree_data.level_1_id,
        }))
      );
    }
  }, [data, loading]);
  const setNextLevel = (id: number, step: number) => {
    if (data) {
      switch (step) {
        case 0:
          setTable(
            data.GetStdDemandSummaryTree.map((tree_data) => ({
              level: tree_data.level_1,
              level_name: tree_data.level_1_name,
              description: tree_data.level_1_desc,
              cur_std_no: tree_data.cur_std_no,
              tc_std_no: tree_data.tc_std_no,
              ne_std_no: tree_data.ne_std_no,
              std_demand_ob: tree_data.std_demand_ob,
              std_demand_amt: tree_data.std_demand_amt,
              std_demand_concession: tree_data.std_demand_concession,
              tc_std_demand_amt: tree_data.tc_std_demand_amt,
              ne_std_demand_amt: tree_data.ne_std_demand_amt,
              total_demand: tree_data.total_demand,
              id: tree_data.level_1_id,
            }))
          );
          setLevelIds({
            level1: 0,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 1:
          setTable(
            data.GetStdDemandSummaryTree.filter(
              ({ level_1_id }) => level_1_id === id
            ).flatMap((level1_data) => {
              const { level_2_details } = level1_data;
              return level_2_details.flatMap((level_2) => {
                return {
                  level: level_2.level_2,
                  level_name: level_2.level_2_name,
                  description: level_2.level_2_desc,
                  cur_std_no: level_2.cur_std_no,
                  tc_std_no: level_2.tc_std_no,
                  ne_std_no: level_2.ne_std_no,
                  std_demand_ob: level_2.std_demand_ob,
                  std_demand_amt: level_2.std_demand_amt,
                  std_demand_concession: level_2.std_demand_concession,
                  tc_std_demand_amt: level_2.tc_std_demand_amt,
                  ne_std_demand_amt: level_2.ne_std_demand_amt,
                  total_demand: level_2.total_demand,
                  id: level_2.level_2_id,
                };
              });
            })
          );
          setLevelIds({
            level1: id,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 2:
          const level_2_tree = data.GetStdDemandSummaryTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0].level_2_details.filter(({ level_2_id }) => level_2_id === id);
          if (level_2_tree[0].level_3_details) {
            setTable(
              level_2_tree.flatMap((level2_data) => {
                const { level_3_details } = level2_data;
                return level_3_details.flatMap((level_3) => {
                  return {
                    level: level_3.level_3,
                    level_name: level_3.level_3_name,
                    description: level_3.level_3_desc,
                    cur_std_no: level_3.cur_std_no,
                    tc_std_no: level_3.tc_std_no,
                    ne_std_no: level_3.ne_std_no,
                    std_demand_ob: level_3.std_demand_ob,
                    std_demand_amt: level_3.std_demand_amt,
                    std_demand_concession: level_3.std_demand_concession,
                    tc_std_demand_amt: level_3.tc_std_demand_amt,
                    ne_std_demand_amt: level_3.ne_std_demand_amt,
                    total_demand: level_3.total_demand,
                    id: level_3.level_3_id,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: id,
              level3: 0,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 3:
          const level_3_tree = data.GetStdDemandSummaryTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(({ level_3_id }) => level_3_id === id);

          if (level_3_tree[0].level_4_details) {
            setTable(
              level_3_tree.flatMap((level3_data) => {
                const { level_4_details } = level3_data;
                return level_4_details.flatMap((level_4) => {
                  return {
                    level: level_4.level_4,
                    level_name: level_4.level_4_name,
                    description: level_4.level_4_desc,
                    cur_std_no: level_4.cur_std_no,
                    tc_std_no: level_4.tc_std_no,
                    ne_std_no: level_4.ne_std_no,
                    std_demand_ob: level_4.std_demand_ob,
                    std_demand_amt: level_4.std_demand_amt,
                    std_demand_concession: level_4.std_demand_concession,
                    tc_std_demand_amt: level_4.tc_std_demand_amt,
                    ne_std_demand_amt: level_4.ne_std_demand_amt,
                    total_demand: level_4.total_demand,
                    id: level_4.level_4_id,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: id,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 4:
          const level_4_tree = data.GetStdDemandSummaryTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(
              ({ level_3_id }) => level_3_id === level_ids.level3
            )[0]
            .level_4_details.filter(({ level_4_id }) => level_4_id === id);

          if (level_4_tree[0]?.level_5_details) {
            setTable(
              level_4_tree.flatMap((level_4_data) => {
                const { level_5_details } = level_4_data;
                return level_5_details.flatMap((level_5) => {
                  return {
                    level: level_5.level_5,
                    level_name: level_5.level_5_name,
                    description: level_5.level_5_desc,
                    cur_std_no: level_5.cur_std_no,
                    tc_std_no: level_5.tc_std_no,
                    ne_std_no: level_5.ne_std_no,
                    std_demand_ob: level_5.std_demand_ob,
                    std_demand_amt: level_5.std_demand_amt,
                    std_demand_concession: level_5.std_demand_concession,
                    tc_std_demand_amt: level_5.tc_std_demand_amt,
                    ne_std_demand_amt: level_5.ne_std_demand_amt,
                    total_demand: level_5.total_demand,
                    id: level_5.level_5_id,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: level_ids.level3,
              level4: id,
            });
            setParent(step);
          }

          break;
        default:
          break;
      }
    }
  };
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {AccountsTitles.FeeDemand.DemandSummary.Titles.map(
          (title: FeeDemandTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.DemandSummary}</React.Fragment>
            );
          }
        )}
      </Title>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "demand-summary"
            : "demand-summary__modal"
        }
      >
        <div className="row g-0">
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={Object.values(StudentStatus)}
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatus(newValue as StudentStatus);
                } else {
                  setStatus(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatus(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  className={labelClasses.formControlRoot}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              )}
            />
          </div>
        </div>
        <div className="inst-breadcrumbs"></div>
        <div className="demand-summary__tableblock">
          <TableContainer className="masters-dashboard__datablock--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <img
                      src={DepartmentImage}
                      alt="/"
                      className="academics-table-logo"
                    />
                  </TableCell>

                  <TableCell>
                    {table.length && table[0].level_name} Name
                  </TableCell>

                  {Accounts_Table.FeeDemand.DemandSummary.Table_Headers.filter(
                    (th) =>
                      (th.labelName !== "Not Eligible" &&
                        status !== EMPTY_STRING &&
                        th.labelName !== "TC" &&
                        status !== EMPTY_STRING) ||
                      status === EMPTY_STRING
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {table.map((data, index) => {
                  return (
                    <TableRow
                      onClick={() => setNextLevel(data.id, parent + 1)}
                      key={index}
                    >
                      <TableCell
                        className="demand-summary__tableblock--table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="masters-dashboard__datablock--table--name">
                        {data.description}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="demand-summary__tableblock--table--number"
                      >
                        {data.cur_std_no}
                      </TableCell>
                      {status === EMPTY_STRING ? (
                        <>
                          <TableCell
                            className="demand-summary__tableblock--table--number"
                            id="td-right"
                          >
                            {data.ne_std_no}
                          </TableCell>
                          <TableCell
                            className="demand-summary__tableblock--table--amount"
                            id="td-right"
                          >
                            {data.tc_std_no}
                          </TableCell>
                        </>
                      ) : null}
                      <TableCell
                        className="demand-summary__tableblock--table--amount"
                        id="td-right"
                      >
                        {format(data.std_demand_ob)}
                      </TableCell>
                      <TableCell
                        className="demand-summary__tableblock--table--amount"
                        id="td-right"
                      >
                        {format(data.std_demand_amt)}
                      </TableCell>
                      <TableCell
                        className="demand-summary__tableblock--table--amount"
                        id="td-right"
                      >
                        {format(data.std_demand_concession)}
                      </TableCell>

                      <TableCell
                        className="demand-summary__tableblock--table--amount"
                        id="td-right"
                      >
                        {format(data.total_demand)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Summation of All Students:
                  </TableCell>
                  <TableCell className="totalcount">
                    {total.studentCount}
                  </TableCell>

                  {status === EMPTY_STRING ? (
                    <>
                      <TableCell className="totalcount">
                        {total.neStudentCount}
                      </TableCell>
                      <TableCell className="totalcount">
                        {total.tcStudentCount}
                      </TableCell>
                    </>
                  ) : null}

                  <TableCell className="totalcount">
                    {format(total.std_demand_ob)}
                  </TableCell>

                  <TableCell className="totalcount">
                    {format(total.std_demand_amt)}
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(total.std_demand_concession)}
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(total.totalAmountCollected)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>

        <Button
          mode="previous"
          onClick={() => {
            const position = parent - 1;
            const key = `level${position}`;
            // @ts-ignore
            setNextLevel(level_ids[key], parent - 1);
          }}
        />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </div>
    </>
  );
};

export default TableView;

import React from "react";
import ReactEcharts from "echarts-for-react";
import { TEXT_FONT_FAMILY } from "../../../../../styles/DataGridTableStyles";
import { GetAcdFeedbackFormOverAllRatingData } from "../../../types/subject/Subject";

interface QuestionWiseGraphProps {
  data?: GetAcdFeedbackFormOverAllRatingData;
}

const QuestionWiseGraph: React.FC<QuestionWiseGraphProps> = ({ data }) => {
  if (!data?.GetAcdFeedbackFormOverAllRating) {
    return <div>No data available</div>;
  }

  const { num_questions, ...ratings } = data.GetAcdFeedbackFormOverAllRating;

  // Collect only the ratings that correspond to the number of questions
  const ratingKeys = Array.from(
    { length: num_questions },
    (_, i) => `rating_${i + 1}`
  );
  //@ts-ignore
  const seriesData = ratingKeys.map((key) => ratings[key] || 0);

  // Generate yAxis labels correctly
  const yAxisLabels = Array.from(
    { length: num_questions },
    (_, i) => `Q${i + 1}`
  );

  const option = {
    title: {
      text: "Question wise Average",
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
        crossStyle: {
          color: "#999",
        },
      },
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: "category",
      data: yAxisLabels.reverse(),
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          color: "#fde68a",
        },
        data: seriesData.reverse(),
      },
    ],
  };

  return (
    <div className="fb-form-view__questionwise--graph">
      <ReactEcharts option={option} />
    </div>
  );
};

export default QuestionWiseGraph;

import { gql } from "@apollo/client";
export const UpdateInst = gql`
  mutation UpdateInst(
    $id: ID!
    $token: String!
    $input: UpdateMstInstInput!
    $user_details: SubscribedUser!
  ) {
    UpdateInst(
      id: $id
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;

export const updateInstBranch = gql`
  mutation UpdateInstBranch(
    $branch_desc: String!
    $branch_short_desc: String
    $branch_id: ID!
    $branch_idx: Int
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstBranch(
      token: $token
      id: $branch_id
      inst_id: $inst_id
      input: {
        branch_desc: $branch_desc
        branch_short_desc: $branch_short_desc
        branch_idx: $branch_idx
      }
      user_details: $user_details
    )
  }
`;
export const updateInstClass = gql`
  mutation updateInstClass(
    $id: ID!
    $input: UpdateMstInstClassInput!
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstClass(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateInstSemester = gql`
  mutation UpdateInstSemester(
    $sem_desc: String!
    $sem_short_desc: String
    $id: ID!
    $sem_idx: Int
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstSemester(
      token: $token
      id: $id
      input: {
        sem_desc: $sem_desc
        sem_short_desc: $sem_short_desc
        sem_idx: $sem_idx
      }
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdateInstDept = gql`
  mutation UpdateInstDept(
    $dept_desc: String!
    $dept_short_desc: String
    $id: ID!
    $token: String!
    $dept_idx: Int
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstDept(
      token: $token
      id: $id
      input: {
        dept_desc: $dept_desc
        dept_idx: $dept_idx
        dept_short_desc: $dept_short_desc
      }
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ReOrderInstDept = gql`
  mutation ReOrderInstDept(
    $token: String!
    $input: [ReOrderMstInstDeptInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstDept(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ReOrderBranch = gql`
  mutation ReOrderBranch(
    $token: String!
    $input: [ReOrderMstInstBranchInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderBranch(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ReOrderInstClass = gql`
  mutation ReOrderInstClass(
    $token: String!
    $input: [ReOrderMstInstClassInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstClass(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ReOrderInstSemester = gql`
  mutation ReOrderInstSemester(
    $token: String!
    $input: [ReOrderMstInstSemesterInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstSemester(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ReOrderInstSection = gql`
  mutation ReOrderInstSection(
    $token: String!
    $input: [ReOrderMstInstSectionInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstSection(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ReOrderInstStdCategory = gql`
  mutation ReOrderInstStdCategory(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [ReOrderMstInstCategoryInput!]!
  ) {
    ReOrderInstStdCategory(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const AddAcdSemesterYr = gql`
  mutation AddAcdSemesterYr(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $acd_yr_id: ID!
    $semester_ids: [ID!]!
    $input: CreateAcdSemesterYrInput!
  ) {
    AddAcdSemesterYr(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      semester_ids: $semester_ids
      input: $input
    )
  }
`;
export const UpdateInstCategory = gql`
  mutation UpdateInstStdCategory(
    $cat_desc: String!
    $id: ID!
    $cat_idx: Int
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstStdCategory(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: { cat_desc: $cat_desc, cat_idx: $cat_idx }
    )
  }
`;

export const UpdateInstSection = gql`
  mutation UpdateInstSection(
    $section_desc: String!
    $section_short_desc: String
    $token: String!
    $id: ID!
    $section_idx: Int
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstSection(
      token: $token
      id: $id
      input: {
        section_idx: $section_idx
        section_desc: $section_desc
        section_short_desc: $section_short_desc
      }
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import { useState, useEffect } from "react";
import Modal from "react-modal";
import { ErrorModalStyles } from "../styles/ModalStyles";
import { Button } from "../stories/Button/Button";
interface Props {
  value: string;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ErrorModal = ({ value, setModal }: Props) => {
  const [errorModal, setErrorModal] = useState(false);
  useEffect(() => {
    if (value) {
      setErrorModal(true);
    }
  }, [value]);
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={errorModal}
      style={ErrorModalStyles}
      ariaHideApp={false}
    >
      <div className="loading-time">
        <h2 className="error-heading">Error</h2>
        <span className="error_body"> {value}</span>
      </div>
      <div className="loading-time__btn">
        <Button
          onClick={() => {
            setErrorModal(!errorModal);
            if (setModal) {
              setModal(false);
            }
          }}
          autoFocus
          mode="cancel"
        />
      </div>
    </Modal>
  );
};

export default ErrorModal;

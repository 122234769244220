import { gql } from "@apollo/client";

export const AddStudent = gql`
  mutation AddStudent(
    $token: String!
    $flags: StdFlags!
    $input: CreateMstStudentInput!
    $enquiry_std_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    AddStudent(
      token: $token
      input: $input
      flags: $flags
      enquiry_std_id: $enquiry_std_id
      inst_id: $inst_id
      user_details: $user_details
    ) {
      id
      first_name
      middle_name
      last_name
    }
  }
`;
export const AddAnonymousStudent = gql`
  mutation AddAnonymousStudent(
    $token: String!
    $inst_id: ID!
    $name: String!
    $student_type: String!
    $user_details: SubscribedUser!
  ) {
    AddAnonymousStudent(
      token: $token
      inst_id: $inst_id
      name: $name
      student_type: $student_type
      user_details: $user_details
    )
  }
`;
export const UpdateAnonymousStudent = gql`
  mutation UpdateAnonymousStudent(
    $token: String!
    $student_id: ID!
    $inst_id: ID!
    $name: String!
    $student_type: String!
    $user_details: SubscribedUser!
  ) {
    UpdateAnonymousStudent(
      token: $token
      student_id: $student_id
      inst_id: $inst_id
      name: $name
      student_type: $student_type
      user_details: $user_details
    )
  }
`;
export const DeleteAnonymousStudent = gql`
  mutation DeleteAnonymousStudent(
    $token: String!
    $student_id: ID!
    $inst_id: ID!
  ) {
    DeleteAnonymousStudent(
      token: $token
      student_id: $student_id
      inst_id: $inst_id
    )
  }
`;

export const AddStudentParentAssociation = gql`
  mutation AddStudentParentAssociation(
    $token: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [StudentParentAssoci!]!
  ) {
    AddStudentParentAssoci(
      token: $token
      student_id: $student_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const AddAcctStudentDemandDetailsByDemandMainId = gql`
  mutation AddAcctStudentDemandDetailsByDemandMainId(
    $token: String!
    $student_id: ID!
    $acct_demand_main_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $inst_id: ID!
  ) {
    AddAcctStudentDemandDetailsByDemandMainId(
      token: $token
      student_id: $student_id
      acct_demand_main_id: $acct_demand_main_id
      user_details: $user_details
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    )
  }
`;

export const AddStudentPassout = gql`
  mutation AddStudentPassout(
    $token: String!
    $input: CreateMstStudentPassoutInput!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    AddStudentPassout(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddStudentDocs = gql`
  mutation AddStudentDocs(
    $token: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [CreateMstStudentDocsInput!]!
  ) {
    AddStudentDocs(
      token: $token
      student_id: $student_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddEmpDocs = gql`
  mutation AddEmpDocs(
    $token: String!
    $pr_emp_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [CreatePayRollEmpMasterDocsInput!]!
  ) {
    AddEmpDocs(
      token: $token
      pr_emp_id: $pr_emp_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateEmpDoc = gql`
  mutation UpdateEmpDoc(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $document_id: ID!
    $input: UpdatePayRollEmpMasterDocsInput!
  ) {
    UpdateEmpDoc(
      token: $token
      input: $input
      inst_id: $inst_id
      document_id: $document_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentDoc = gql`
  mutation UpdateStudentDoc(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [UpdateMstStudentDocs!]!
  ) {
    UpdateStudentDoc(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddStudentEnquiry = gql`
  mutation AddStudentEnquiry(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateMstStudentEnquiryInput!
  ) {
    AddStudentEnquiry(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    ) {
      id
    }
  }
`;

export const AddStudentEnquiryByScan = gql`
  mutation AddStudentEnquiryByScan(
    $inst_id: ID!
    $input: CreateMstStudentEnquiryInput!
  ) {
    AddStudentEnquiryByScan(inst_id: $inst_id, input: $input)
  }
`;

export const AddStudentEnquiryFollowUp = gql`
  mutation AddStudentEnquiryFollowUp(
    $token: String!
    $inst_id: ID!
    $enq_student_id: ID!
    $user_details: SubscribedUser!
    $input: CreateMstStudentEnquiryFollowUpInput!
  ) {
    AddStudentEnquiryFollowUp(
      token: $token
      inst_id: $inst_id

      enq_student_id: $enq_student_id
      input: $input
      user_details: $user_details
    )
  }
`;

export const UpdateStudentEnquiryFollowUp = gql`
  mutation UpdateStudentEnquiryFollowUp(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateMstStudentEnquiryFollowUpInput!
  ) {
    UpdateStudentEnquiryFollowUp(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteStudentEnquiryFollowUp = gql`
  mutation DeleteStudentEnquiryFollowUp(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteStudentEnquiryFollowUp(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteStudentDoc = gql`
  mutation DeleteStudentDoc(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $document_id: ID!
  ) {
    DeleteStudentDoc(
      token: $token
      user_details: $user_details
      inst_id: $inst_id
      document_id: $document_id
    )
  }
`;
export const DeleteEmpDoc = gql`
  mutation DeleteEmpDoc(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $document_id: ID!
  ) {
    DeleteEmpDoc(
      token: $token
      user_details: $user_details
      inst_id: $inst_id
      document_id: $document_id
    )
  }
`;
export const ResetStudentPassword = gql`
  mutation ResetStudentPassword(
    $token: String!
    $student_id: ID!
    $password: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    ResetStudentPassword(
      token: $token
      student_id: $student_id
      password: $password
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateStudentLoginStatus = gql`
  mutation UpdateStudentLoginStatus(
    $token: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $login_status: Boolean!
  ) {
    UpdateStudentLoginStatus(
      token: $token
      student_id: $student_id
      login_status: $login_status
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateEmpLoginStatus = gql`
  mutation UpdateEmpLoginStatus(
    $token: String!
    $pr_emp_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $login_status: Boolean!
  ) {
    UpdateEmpLoginStatus(
      token: $token
      pr_emp_id: $pr_emp_id
      login_status: $login_status
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const EnableStudentLoginStatusWithNewEmail = gql`
  mutation EnableStudentLoginStatusWithNewEmail(
    $token: String!
    $student_id: ID!
    $login_data: StdLoginData!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    EnableStudentLoginStatusWithNewEmail(
      token: $token
      student_id: $student_id
      login_data: $login_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const EnableEmpLoginStatusWithNewEmail = gql`
  mutation EnableEmpLoginStatusWithNewEmail(
    $token: String!
    $pr_emp_id: ID!
    $login_data: EmpLoginData!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    EnableEmpLoginStatusWithNewEmail(
      token: $token
      pr_emp_id: $pr_emp_id
      login_data: $login_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddEduateModule = gql`
  mutation AddEduateModule(
    $token: String!
    $input: CreateEduateModuleInput!
    $user_details: SubscribedUser!
  ) {
    AddEduateModule(token: $token, input: $input, user_details: $user_details)
  }
`;
export const UpdateEduateModule = gql`
  mutation UpdateEduateModule(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateEduateModuleInput!
  ) {
    UpdateEduateModule(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;
export const DeleteEduateModule = gql`
  mutation DeleteEduateModule(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteEduateModule(token: $token, id: $id, user_details: $user_details)
  }
`;

export const UpdateMstInstModuleDetails = gql`
  mutation UpdateMstInstModuleDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateInstModuleDetails!
  ) {
    UpdateMstInstModuleDetails(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const DeleteMstInstModule = gql`
  mutation DeleteMstInstModule($token: String!, $id: ID!) {
    DeleteMstInstModule(token: $token, id: $id)
  }
`;

export const UpdateMstInstModuleDetailsForMobile = gql`
  mutation UpdateMstInstModuleDetailsForMobile(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [UpdateModuleForMobile!]!
  ) {
    UpdateMstInstModuleDetailsForMobile(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const AddInstUploadDocTypes = gql`
  mutation AddInstUploadDocTypes(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [CreateMstInstUploadDocTypesInput!]!
  ) {
    AddInstUploadDocTypes(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const ReOrderInstUploadDocTypes = gql`
  mutation ReOrderInstUploadDocTypes(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [ReOrderMstInstUploadDocTypesInput!]!
  ) {
    ReOrderInstUploadDocTypes(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const DeleteInstUploadDocTypes = gql`
  mutation DeleteInstUploadDocTypes(
    $token: String!
    $doc_type_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstUploadDocTypes(
      token: $token
      doc_type_id: $doc_type_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

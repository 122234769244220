import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
} from "../utils/Enum.types";

import { useContext, useEffect, useState } from "react";
import useToken from "./useToken";
import { GetSwConfigVariables } from "../queries/institution/configuration/query/SoftwreConfig";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
} from "../Types/configtypes";
import { SwConfigQueryType } from "../Modules/HR/enums/Enum.types";
import { AppContext } from "../context/context";

const useInstitutionConfiguration = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [departmentData, setDepartmentData] = useState(false);
  const [branchData, setBranchData] = useState(false);
  const [classData, setClassData] = useState(false);
  const [semesterData, setSemesterData] = useState(false);
  const [sectionData, setSectionData] = useState(false);
  const [categoryData, setCategoryData] = useState(false);
  const [entry_level, setEntryLevel] = useState("");

  const [GetConfigdata, { data, loading }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId ? InstId : state.InstId,
      input: {
        config_query_type: SwConfigQueryType.EDUATE_BY_MODULE,
        str_value: "EDUATE",
        int_value: 1,
      },
    },
  });
  useEffect(() => {
    if (token && (InstId || state.InstId)) {
      GetConfigdata();
    }
  }, [token, GetConfigdata, InstId]);

  useEffect(() => {
    if (!loading && data) {
      // eslint-disable-next-line
      data.GetSwConfigVariables.forEach((config) => {
        const { config_key, config_boolean_value } = config;

        switch (config_key) {
          case InstitutionConfigurationTypes.INSTITUTION_USE_DEPARTMENT:
            setDepartmentData(config_boolean_value);
            break;

          case InstitutionConfigurationTypes.INSTITUTION_USE_BRANCH:
            setBranchData(config_boolean_value);
            break;

          case InstitutionConfigurationTypes.INSTITUTION_USE_CLASS:
            setClassData(config_boolean_value);

            break;

          case InstitutionConfigurationTypes.INSTITUTION_USE_SEMESTER:
            setSemesterData(config_boolean_value);

            break;

          case InstitutionConfigurationTypes.INSTITUTION_USE_SECTION:
            setSectionData(config_boolean_value);
            break;

          case InstitutionConfigurationTypes.INSTITUTION_USE_CATEGORY:
            setCategoryData(config_boolean_value);
            break;

          default:
            break;
        }
      });
    }
  }, [loading, data, InstId, token]);
  useEffect(() => {
    if (sectionData) {
      setEntryLevel(AcdSubjectAllocationData.SECTION);
    } else if (semesterData) {
      setEntryLevel(AcdSubjectAllocationData.SEMESTER);
    } else if (classData) {
      setEntryLevel(AcdSubjectAllocationData.CLASS);
    } else if (branchData) {
      setEntryLevel(AcdSubjectAllocationData.BRANCH);
    } else if (departmentData) {
      setEntryLevel(AcdSubjectAllocationData.DEPT);
    }
  }, [sectionData, semesterData, classData, branchData, departmentData]);
  return {
    USE_DEPARTMENT_KEY: departmentData,
    USE_BRANCH_KEY: branchData,
    USE_CLASS_KEY: classData,
    USE_SEMESTER_KEY: semesterData,
    USE_SECTION_KEY: sectionData,
    USE_CATEGORY_KEY: categoryData,
    entry_level,
  };
};

export default useInstitutionConfiguration;

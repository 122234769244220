import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";

import { AddInstStdCategory } from "../../../../queries/institution/mutations/new";
import {
  ReOrderInstStdCategory,
  UpdateInstCategory,
} from "../../../../queries/institution/mutations/update";
import React, { useEffect, useState } from "react";
import { categoryType, msgType } from "../../../../utils/Form.types";
import { Button } from "../../../../stories/Button/Button";

import Input from "../../../../components/common/Input/Index";
import InstitutionTabs from "../../routes/InstitutionTabs";

import { category_validation } from "../../../../utils/validationRules";
import { Form, Formik } from "formik";
import { Operation, PageNumbers } from "../../../../utils/Enum.types";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ListCategoryByInstId,
  CategoryDetailsByNode,
} from "../../../../queries/institution/categories/byId";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import {
  removeMoreSpace,
  reOrderProcess,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import Modal from "react-modal";

import { InstitutionDetailsFormLabelsType } from "../../../../Types/Institution/json";
import { InstitutionDetailsTitlesType } from "../../../../Types/Titles/index";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../../styles/ModalStyles";
import LoadingModal from "../../../../pages/LoadingModal";
import Edit from "../../../../images/EditProfile.svg";
import DeleteImg from "../../../../images/Delete.svg";
import { DeleteInstStdCategoryById } from "../../../../queries/institution/mutations/delete";
import DeleteModal from "../../../../pages/DeleteModal";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import Settings from "../../../../images/Settings.svg";
import ConfigurationSettings from "../../configurations/general/Index";
import Close from "../../../../images/Close.svg";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import {
  CategoryList,
  CategoryListDataByInstId,
  DepartmentListVarsByInstId,
} from "../../../../Types/Student";
import { EMPTY_STRING } from "../../../../utils/constants";
import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import CategoryImage from "../../../../images/Category.svg";
import Collapse from "../../../../images/Collapse.svg";
import UnCollapse from "../../../../images/UnCollapse.svg";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
const { CategoriesFormLabels } = require("../../../../json/config.json");

const Category = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const { USE_ADMISSIONS } = useUserRightsByEmpId();
  const [operation, setOperation] = useState(Operation.CREATE);
  const { Masters_Table } = useMasterTableJson();

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [localItems, setLocalItems] = useState<CategoryList[]>([]);
  // eslint-disable-next-line
  const [item, setitems] = useState<CategoryList[]>([]);
  //flags for sucess model
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [configurationModal, setConfigurationModal] = useState(false);

  const [reOrder, setReOrder] = useState(false);
  const [categoryId, setCategoryid] = useState(0);
  const nameRef = document.getElementsByName("cat_desc")[0] as HTMLInputElement;
  const [formData, setFormData] = useState<categoryType>({
    cat_desc: "",
  });
  const [NewCategory, { loading: creationLoading }] = useMutation(
    AddInstStdCategory,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateCategories, { loading: updationLoading }] = useMutation(
    UpdateInstCategory,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [reorderCategories, { loading: reorderLoading }] = useMutation(
    ReOrderInstStdCategory,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [deleteCategories, { loading: deleteLoading }] = useMutation(
    DeleteInstStdCategoryById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetCategoryData, { data: categoryDropDown }] = useLazyQuery<
    CategoryListDataByInstId,
    DepartmentListVarsByInstId
  >(ListCategoryByInstId, { variables: { inst_id: InstId!, token } });

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.CATEGORY_PAGE);
  const [categoryDataById, { data, loading }] =
    useLazyQuery(CategoryDetailsByNode, {
      variables: { token, category_id: categoryId },
    }) || {};

  const handleClose = () => {
    //enableAdd and enableEdit should stay false when you close the success modal
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(false);
    }
    setMessage({
      message: " ",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };

  useEffect(
    () => {
      setLocalItems(
        categoryDropDown?.GetCategoriesByInstId.filter((cat) => cat !== null)!
      );
    }, // eslint-disable-next-line
    [categoryDropDown]
  );
  useEffect(() => {
    if (!loading && data && operation === Operation.UPDATE) {
      const { cat_desc } = data.node || EMPTY_STRING;
      setFormData({
        cat_desc: cat_desc,
      });
    }
  }, [loading, data, operation]);
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const HandleNewCategory = () => {
    NewCategory({
      variables: {
        token,
        input: {
          inst_id: InstId,
          cat_desc: removeMoreSpace(formData.cat_desc),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: ListCategoryByInstId,
          variables: { token, inst_id: InstId! },
        },
        {
          query: GetInstMasterData,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Category Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setFormData({
      cat_desc: "",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const HandleEdit = () => {
    updateCategories({
      variables: {
        token,
        id: categoryId,
        inst_id: InstId,
        cat_desc: removeMoreSpace(formData.cat_desc),
        user_details,
      },
      refetchQueries: [
        {
          query: ListCategoryByInstId,
          variables: { token, inst_id: InstId! },
        },
        {
          query: CategoryDetailsByNode,
          variables: { token, category_id: categoryId! },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Category Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setFormData({
      cat_desc: "",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };

  const HandleDelete = (cat_id: number) => {
    deleteCategories({
      variables: {
        token,
        cat_id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: ListCategoryByInstId,
          variables: { token, inst_id: InstId! },
        },
        {
          query: CategoryDetailsByNode,
          variables: { token, category_id: categoryId! },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDeleteModal(!deleteModal);
        setMessage({
          message: "Category Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setOperation(Operation.CREATE);
    handleClear();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };
  const HandleReorder = () => {
    // eslint-disable-next-line
    reorderCategories({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: localItems?.map((res, index) => ({
          id: res.id,
          cat_idx: index + 1,
        })),
      },
      refetchQueries: [
        {
          query: ListCategoryByInstId,
          variables: {
            token,
            inst_id: InstId,
          },
        },
      ],
    });
    setReOrder(!reOrder);
  };
  const handleClear = () => {
    setFormData({
      cat_desc: "",
    });
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };
  useEffect(() => {
    if (token) {
      GetCategoryData();
    }
  }, [token, GetCategoryData]);

  const [collapseFlag, setCollapseFlag] = useState(false);
  const {categoryLabel}=useInstLabels();
  return (
    <>
      <InstitutionTabs />
      <div className="row g-0">
        <div className="col">
          <Title>
      {categoryLabel}
          </Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>

      <div className="masterconfig">
        <div className="row g-0 masterconfig__details frame-space">
          <div className="col h-100 booktype-left">
            <Title variant="subtitle1">
       List of {categoryLabel}
            </Title>
            <TableContainer className="masterconfig__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {reOrder ? <TableCell></TableCell> : null}

                    {Masters_Table.InstitutionDetails.Categories.Table_Headers.filter(
                      ({ labelName }: TableHeaderProps) =>
                        !(labelName === "Actions" && reOrder)
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TableCell className={th.className}>
                            {th.labelName}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {localItems?.length > 0
                          ? localItems?.map((response, index: number) => (
                              <Draggable
                                key={index}
                                draggableId={index.toString()}
                                index={index}
                                isDragDisabled={!reOrder}
                              >
                                {(
                                  draggableProvided: DraggableProvided,
                                  snapshot: DraggableStateSnapshot
                                ) => {
                                  return (
                                    <TableRow
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      key={index}
                                    >
                                      {reOrder ? (
                                        <TableCell
                                          {...draggableProvided.dragHandleProps}
                                          align="center"
                                          className="institution__table--slno"
                                        >
                                          =
                                        </TableCell>
                                      ) : null}
                                      <TableCell
                                        id="td-center"
                                        {...draggableProvided.dragHandleProps}
                                        className="institution__table--slno"
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        {response?.cat_desc}
                                      </TableCell>
                                      {reOrder === false ? (
                                        <TableCell
                                          id="td-center"
                                          {...draggableProvided.dragHandleProps}
                                          className="institution__table--action"
                                        >
                                          <>
                                            {USE_ADMISSIONS.details?.edit && (
                                              <img
                                                src={Edit}
                                                alt="/"
                                                onClick={() => {
                                                  setOperation(
                                                    Operation.UPDATE
                                                  );
                                                  setCategoryid(response.id);
                                                  setAddModal(!addModal);
                                                  categoryDataById();
                                                }}
                                              />
                                            )}
                                            {USE_ADMISSIONS.details?.delete && (
                                              <img
                                                src={DeleteImg}
                                                alt="/"
                                                onClick={() => {
                                                  setCategoryid(response.id);
                                                  setDeleteModal(!deleteModal);
                                                }}
                                              />
                                            )}
                                          </>
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  );
                                }}
                              </Draggable>
                            ))
                          : null}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </div>
          <div className="col h-100 booktype-right">
            <Title variant="subtitle1">Tree Structure</Title>
            <div className="masterconfig__tree-view">
              <ol>
                <li className="masterconfig__tree-view--li">
                  {!collapseFlag ? (
                    <img
                      src={Collapse}
                      alt=""
                      onClick={() => setCollapseFlag(!collapseFlag)}
                    />
                  ) : (
                    <img
                      src={UnCollapse}
                      alt=""
                      onClick={() => setCollapseFlag(!collapseFlag)}
                    />
                  )}
                  <b>{categoryLabel}</b>
                </li>
                {!collapseFlag && (
                  <ol className="masterconfig__tree-view--ol">
                    {localItems?.map((data) => {
                      return (
                        <li
                          key={data.id}
                          className="masterconfig__tree-view--li"
                        >
                          <img src={CategoryImage} alt="" /> {data.cat_desc}
                        </li>
                      );
                    })}
                  </ol>
                )}
              </ol>
            </div>
          </div>
        </div>
        <div className="masterconfig__buttons">
          <Button mode="addnew" onClick={() => setAddModal(!addModal)} />
          {localItems?.length > 1 ? (
            reOrder ? (
              <>
                <Button
                  mode="save-order"
                  type="button"
                  onClick={HandleReorder}
                />
                <Button
                  mode="clear"
                  type="button"
                  onClick={() => {
                    setReOrder(!reOrder);
                    setLocalItems(
                      categoryDropDown?.GetCategoriesByInstId.filter(
                        (cat) => cat !== null
                      )!
                    );
                  }}
                />
              </>
            ) : (
              <Button
                mode="reorder"
                type="button"
                onClick={() => setReOrder(!reOrder)}
              />
            )
          ) : null}

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={categoryId}
      />
      <LoadingModal
        flag={
          creationLoading || updationLoading || deleteLoading || reorderLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.CATEGORY_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Formik
              initialValues={formData}
              validationSchema={category_validation}
              onSubmit={
                operation === Operation.UPDATE ? HandleEdit : HandleNewCategory
              }
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    <Title variant="subtitle1">
                      {operation === Operation.UPDATE
                        ? `Update ${categoryLabel}`
                        : `Add ${categoryLabel}`}
                    </Title>
                    {CategoriesFormLabels.map(
                      (
                        label: InstitutionDetailsFormLabelsType,
                        index: React.Key
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              name={label.inputName}
                              type={label.dataType}
                              values={formData[label.inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}

                    <div className="masterconfig__buttons">
                      <Button mode="save" type="submit" />

                      <Button
                        mode="clear"
                        type="button"
                        onClick={handleClear}
                      />
                      <Button
                        mode="cancel"
                        onClick={() => {
                          setAddModal(!addModal);
                          handleClear();
                        }}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setAddModal(!addModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Category;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { msgType, responseType } from "../../utils/Form.types";
import {
  InstitutionConfigurationTypes,
  LicenseTypes,
  Operation,
} from "../../utils/Enum.types";
import { nodevars } from "../../Types/Accounting";

import Home from "../Eduate/Home/Index";
import Avatar from "../../images/Avatar.svg";
import useToken from "../../customhooks/useToken";
import useSwConfigData from "../../customhooks/useSwConfigData";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Title } from "../../stories/Title/Title";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Keys } from "../../utils/Enum.keys";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { Button } from "../../stories/Button/Button";
import LoadingModal from "../../pages/LoadingModal";
import MessageModal from "../../pages/MessageModal";

import {
  GetOwnerData,
  GetOwnerNode,
  GetOwnersVars,
  OwnerType,
} from "./utils/types";
import { CustOwnerDetialsByNode, GetOwners } from "./queries/List";
import { ResetOwnerPassword } from "./queries/mutation";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../styles/AutocompleteListStyles";

const ResetChairmanPassword = () => {
  const { token } = useToken();
  const { custId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();

  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const [chairmanSelected, setChairmanSelected] = useState<responseType | null>(
    null
  );

  const [formData, setFormData] = useState<OwnerType>({
    owner_name: "",
    owner_mobile: "",
    owner_email: "",
  });
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetCustOwner] = useLazyQuery<GetOwnerNode, nodevars>(
    CustOwnerDetialsByNode,
    {
      variables: {
        ids: [chairmanSelected?.value ? chairmanSelected?.value : 0],
        token,
      },
    }
  );
  const [GetOwner, { data, loading }] = useLazyQuery<
    GetOwnerData,
    GetOwnersVars
  >(GetOwners, { variables: { cust_id: custId!, token } });
  const chairmanDropDown: responseType[] = data
    ? data.GetOwners.map((res) => ({
        label: res.owner_name,
        value: res.id,
      }))
    : [];

  const [ResetPassword, { loading: UpdateLoading }] = useMutation(
    ResetOwnerPassword,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const HandleUpdate = () => {
    ResetPassword({
      variables: {
        token,
        owner_id: chairmanSelected?.value,
        user_details,
        password: resetPassword,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Password Reset Successfull",
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.DEFAULT_PASSWORD
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      //   handleClear(clearType.CLEAR_ALL);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (configData.data && !configData.loading) {
      setResetPassword(
        configData.data?.GetSwConfigVariables[0].config_string_value
      );
    }
  }, [configData.data, configData.loading]);
  useEffect(() => {
    if (token && custId) {
      GetOwner();
    }
  }, [token, custId, GetOwner]);
  useEffect(() => {
    if (chairmanSelected?.value && token) {
      GetCustOwner().then(({ data }) => {
        if (data) {
          const { owner_name, owner_mobile, owner_email } = data.nodes[0];

          setFormData({
            owner_name,
            owner_mobile,
            owner_email,
          });
        }
      });
    }
  }, [chairmanSelected?.value, data, GetCustOwner, token]);

  return (
    <>
      <Home DashBoardRequired={false} NavType={LicenseTypes.EDUATE_CUSTOMER} />
      <Title>Reset Chairman Password</Title>
      <div className="student-reset-password">
        <div className="row g-0 student-reset-password__details">
          <div className="col">
            <div className="student-reset-password__details--imagefield">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={chairmanDropDown || []}
                openOnFocus
                value={chairmanSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setChairmanSelected(newValue as responseType);
                  } else {
                    setChairmanSelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setChairmanSelected(null);
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Chairman Name"
                    autoFocus
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    //   setSearchEmployee(e.target.value)
                    // }
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              {/* <img
              className="data-fetch-icon"
              src={DataFetch}
              alt="/"
              onClick={() => setEmpModal(!empModal)}
            /> */}
            </div>

            <TextField
              label="Mobile"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={formData?.owner_mobile}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={formData.owner_name}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            <TextField
              className="emp-assign-leave-ledger__textfield"
              label="Email"
              value={formData.owner_email}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col-1 h-100 emp-assign-leave-ledger__profile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="student-reset-password__form">
          <div className="student-reset-password__form--details row g-0">
            <div className="col-4">
              <TextField
                className="student-reset-password__form--details--pwd"
                label="Password"
                value={resetPassword}
                onChange={(e) => setResetPassword(e.target.value)}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                type={showPassword ? "text" : "password"}
              />
            </div>
          </div>
          <Button mode="save" onClick={HandleUpdate} />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <LoadingModal flag={UpdateLoading || loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ResetChairmanPassword;

import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import Modal from "react-modal";
import { AccountingLedgerModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import New from "./New";
import { Operation } from "../../../../utils/Enum.types";
const { VMS_Table } = require("../../../json/table.json");

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  contentComponent: React.ReactNode;
}
const Index = () => {
  const navigate = useNavigate();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    onRequestClose,
    contentComponent,
  }) => {
    return (
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        style={AccountingLedgerModalStyles}
        ariaHideApp={false}
        onRequestClose={onRequestClose}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">{contentComponent}</div>
          <div className="modal-flex__image">
            <img src={Close} alt="Close" onClick={onRequestClose} />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Supplier Details</Title>
      <div className="vms-supplier">
        <div className="vms-supplier__select row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="addnew"
              className="vms-supplier__addnew"
              onClick={() => setAddModal(!addModal)}
            />
          </div>
        </div>
        <div className="vms-supplier__tableblock">
          <TableContainer className="vms-supplier__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {VMS_Table.Supplier_Details.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="vms-supplier__table--slno"
                    id="td-center"
                  >
                    1
                  </TableCell>
                  <TableCell
                    onClick={() => setViewModal(!viewModal)}
                    className="vms-supplier__table--name"
                  >
                    Karan
                  </TableCell>
                  <TableCell className="vms-supplier__table--number">
                    9663254785
                  </TableCell>
                  <TableCell className="vms-supplier__table--number">
                    22AAAAA0000A1Z5
                  </TableCell>
                  <TableCell className="vms-supplier__table--number">
                    HSZ78965
                  </TableCell>
                  <TableCell>Arjun</TableCell>
                  <TableCell>Hp</TableCell>
                  <TableCell>Hennur</TableCell>
                  <TableCell
                    className="vms-supplier__table--actions"
                    id="td-center"
                  >
                    <img
                      src={Edit}
                      alt=""
                      onClick={() => setEditModal(!editModal)}
                    />
                    <img src={Delete} alt="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <CustomModal
        isOpen={addModal}
        onRequestClose={() => setAddModal(!addModal)}
        contentComponent={
          <New setModalFlag={setAddModal} operation={Operation.CREATE} />
        }
      />
      <CustomModal
        isOpen={editModal}
        onRequestClose={() => setEditModal(!editModal)}
        contentComponent={
          <New setModalFlag={setEditModal} operation={Operation.UPDATE} />
        }
      />
      <CustomModal
        isOpen={viewModal}
        onRequestClose={() => setViewModal(!viewModal)}
        contentComponent={
          <New setModalFlag={setViewModal} operation={Operation.VIEW} />
        }
      />
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const AddPayRollJustLoginAcct = gql`
  mutation AddPayRollJustLoginAcct(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: JustLoginAcctInput!
  ) {
    AddPayRollJustLoginAcct(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

export const UpdatePayRollJustLoginAcct = gql`
  mutation UpdatePayRollJustLoginAcct(
    $token: String!
    $pr_emp_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: JustLoginAcctInput!
  ) {
    UpdatePayRollJustLoginAcct(
      token: $token
      pr_emp_id: $pr_emp_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { Direction, Operation } from "../../../../utils/Enum.types";
import { AddAcdReportCardDataTypes } from "../../types/Progresscard";
import { msgType } from "../../../../utils/Form.types";
import { useMutation } from "@apollo/client";
import {
  AddAcdReportCard,
  UpdateAcdReportCard,
} from "../../queries/reportcard/mutation";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import { GetAcdReportCards } from "../../queries/reportcard";

import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Form, Formik } from "formik";
import {
  document_validation,
  report_card_validation,
} from "../../../../utils/validationRules";
import Input from "../../../../components/common/Input/Index";
import List from "./List";
import { Button } from "../../../../stories/Button/Button";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { AppContext } from "../../../../context/context";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
const ReportCards = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);

  const [reportCardId, setReportCardId] = useState(0);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [formData, setFormData] = useState<AddAcdReportCardDataTypes>({
    report_name: "",
  });

  const nameRef = document.getElementsByName(
    "report_name"
  )[0] as HTMLInputElement;

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [createReportCard, { loading: creationLoading }] = useMutation(
    AddAcdReportCard,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateReportCard, { loading: updationLoading }] = useMutation(
    UpdateAcdReportCard,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const fetchEditDetails = (id: number, report_name: string) => {
    setReportCardId(id);
    setFormData({ report_name });
    setOperation(Operation.UPDATE);
  };
  const handleRegister = () => {
    if (InstId)
      createReportCard({
        variables: {
          token,
          inst_id: InstId,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          user_details,
          input: {
            report_name: removeMoreSpace(formData.report_name),
          },
        },

        refetchQueries: [
          {
            query: GetAcdReportCards,
            variables: {
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              after: null,
              first: ROWS_PER_PAGE,
              inst_id: Number(InstId),
              orderBy: {
                direction: Direction.ASC,
              },
              token,
             
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Report Card Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    setFormData({
      report_name: "",
    });
    setOperation(Operation.CREATE);
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleClose = () => {
    //enableAdd and enableEdit should stay false when you close the success modal
    //   if (message.operation !== Operation.NONE && message.flag) {
    //     setDeleteModal(false);
    //   }
    setMessage({
      message: " ",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };
  const handleClear = () => {
    setFormData({
      report_name: "",
    });
    nameRef?.focus();
  };

  const handleEdit = () => {
    if (InstId)
      updateReportCard({
        variables: {
          token,
          inst_id: InstId,
          id: reportCardId,
          input: {
            report_name: removeMoreSpace(formData.report_name),
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcdReportCards,
            variables: {
              token,
              inst_id: Number(InstId),
              id: reportCardId,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              first: ROWS_PER_PAGE,
              after: null,
              orderBy: {
                direction: Direction.ASC,
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Report Card Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    setFormData({
      report_name: "",
    });
    setOperation(Operation.UPDATE);
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Report Cards</Title>
      <Formik
        initialValues={formData}
        validationSchema={report_card_validation}
        onSubmit={operation === Operation.UPDATE ? handleEdit : handleRegister}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="report-card-create">
              <div className="report-card-create__frame row g-0">
                <div className="report-card-create__frame--left col ">
                  <Title variant="subtitle1">
                    {operation === Operation.CREATE
                      ? "Add New Report Card"
                      : "Update Report Card"}
                  </Title>
                  <Input
                    required
                    type="text"
                    LabelName="Report Card Name"
                    values={[formData.report_name]}
                    name="report_name"
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      meta.handleChange(e);
                      handleValueChange(e);
                    }}
                  />
                </div>
                <div className="report-card-create__frame--right col ">
                  <Title variant="subtitle1">List of Report Cards</Title>

                  <List fetchEditDetails={fetchEditDetails} />
                </div>
              </div>

              <Button mode="save" type="submit" />
              <Button mode="clear" onClick={handleClear} />
              <Button mode="back" onClick={() => navigate(-1)} />
            </Form>
          );
        }}
      </Formik>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ReportCards;

import { useNavigate, useParams } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase";
import { AppContext } from "../../../context/context";

import { useContext, useState } from "react";
import Modal from "react-modal";
import {
  LogOutModalStyles,
  WaiveOffModalStyles,
} from "../../../styles/ModalStyles";

import Enlarge from "../../../images/Enlarge.svg";
import Username from "../../../images/Username.svg";
import Inbox from "../../../images/Inbox.svg";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { toggleFullSceen } from "../../../utils/UtilFunctions";

import { Label } from "../../../stories/Label/Label";

import Captcha from "../../../Modules/Master/Captcha";
import Close from "../../../images/Close.svg";

import { useMutation } from "@apollo/client";
import { LogoutUser } from "../../../queries/DetailsBytoken";
import useToken from "../../../customhooks/useToken";

import { CustomTooltip, TOOLTIP_COLORS } from "../../../styles/TooltipStyles";
import Skins from "../../../components/common/Header/Skins";
import { EMPTY_STRING } from "../../../utils/constants";
export const handleSignOut = () => {
  signOut(auth)
    .then(() => (window.location.href = "/"))
    .catch((error) => {
      alert(error);
    });
};
const Index = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();

  const [userLogout, setUserLogout] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const [DeleteDeviceId] = useMutation(LogoutUser);

  const handleDeleteUserDeviceId = () => {
    if (state.claims?.EDUATE || state.claims?.OWNER) {
      handleSignOut();
    } else
      DeleteDeviceId({
        variables: {
          token,
          inst_id: InstId,
          device_token: EMPTY_STRING,
          input: {
            id: state?.claims!.EMPLOYEE
              ? state.empLoginId
              : state?.claims!.PARENT
              ? state.parentId
              : state.studentId,
            user_type: state.claims!.EMPLOYEE
              ? "EMPLOYEE"
              : state.claims!.PARENT
              ? "PARENT"
              : "STUDENT",
          },
        },
      }).then(({ data }) => {
        if (data) {
          handleSignOut();
        }
      });
  };


  return (
    <>
      <div className="header">
        <div className="row g-0 h-100">
          <div className="col h-100"></div>
          <div className="col-5 h-100">
            <ul className="header__icon">
              <li>
                <CustomTooltip
                  title="Full Screen"
                  arrow
                  placement="top"
                  slotProps={{
                    tooltip: {
                      sx: {
                        bgcolor: TOOLTIP_COLORS.VARIABLE,
                        "& .MuiTooltip-arrow": {
                          color: TOOLTIP_COLORS.VARIABLE,
                        },
                      },
                    },
                  }}
                >
                  <img
                    src={Enlarge}
                    alt="/"
                    onClick={() => toggleFullSceen()}
                  />
                </CustomTooltip>
              </li>

              <li>
                <Skins />
              </li>

              {state.claims?.EDUATE ? (
                <li>
                  <CustomTooltip
                    title="Token"
                    arrow
                    placement="top"
                    className="tooltip-hide"
                  >
                    <img
                      src={Inbox}
                      alt="/"
                      onClick={() => navigate("/token")}
                    />
                  </CustomTooltip>
                </li>
              ) : null}

              <li>
                <CustomTooltip
                  title="SignOut"
                  arrow
                  placement="top"
                  className="tooltip-hide"
                >
                  <img
                    src={Username}
                    alt="/"
                    onClick={() => setUserLogout(!userLogout)}
                  />
                </CustomTooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={userLogout}
        ariaHideApp={false}
        style={LogOutModalStyles}
        onRequestClose={() => setUserLogout(!userLogout)}
      >
        <div className="logout-modal">
          <img src={Username} alt="/" />
          <Title variant="subtitle1">UserName</Title>
          <Title variant="subtitle2">{auth.currentUser?.email}</Title>
          <Label
            variant="LabelPrimary"
            onClick={() => {
              setPasswordModal(!passwordModal);
              setUserLogout(!userLogout);
            }}
          >
            Change Password
          </Label>
          <Button
            onClick={() => {
              // handleSignOut();
              handleDeleteUserDeviceId();
              // window.location.href = "/";
              setUserLogout(!userLogout);
            }}
            mode="sign-out"
          />
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={passwordModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="col">
              <Captcha setPasswordModal={setPasswordModal} />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPasswordModal(!passwordModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

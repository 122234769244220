import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UseSelect from "../../../../customhooks/UseSelect";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import { EMPTY_STRING } from "../../../../utils/constants";
import { PayRollReportsTypes } from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import Pointer from "../../../../images/Pointer.svg";
import BankScroll from "./BankScroll";
import Leaves from "./Leaves";
import PaySlip from "./PaySlip";
interface Props {
  type: PayRollReportsTypes;
}

const Index = ({ type }: Props) => {
  const { InstId } = useParams();
  const navigate = useNavigate();

  const [selected, setSelected] = useState("");
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");

  const ReportTypes = [
    {
      label: "PaySlip",
      url: `/${InstId}/hr/payroll/reports/payslip`,
      value: 1,
    },
    {
      label: "Salary Statement",
      url: `/${InstId}/hr/payroll/reports/salarystatement`,
      value: 2,
    },
    {
      label: "Bank Scroll",
      url: `/${InstId}/hr/payroll/reports/bankscroll`,
      value: 3,
    },
    {
      label: "Leaves",
      url: `/${InstId}/hr/payroll/reports/leaves`,
      value: 4,
    },
  ];

  const filterReportTypes = UseSelect(ReportTypes, searchText);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Payroll Reports</Title>
      <div className="payroll-reports">
        <div className="payroll-reports__leftblock">
          <TableContainer className="payroll-reports__leftblock--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Report Types</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="payroll-reports__leftblock--table--input">
                    <Input
                      type="text"
                      placeholder="Search..."
                      id="search"
                      size="small"
                    />
                  </TableCell>
                </TableRow>
                {filterReportTypes.map((data) => {
                  return (
                    <TableRow
                      key={data.label}
                      className={
                        selected === data.label ? "selected-row" : EMPTY_STRING
                      }
                    >
                      <TableCell
                        onClick={() => {
                          navigate(data.url!);
                          setSelected(data.label!);
                        }}
                      >
                        {selected === data.label ? (
                          <img src={Pointer} alt="/" className="pointer-icon" />
                        ) : (
                          EMPTY_STRING
                        )}
                        {data.label}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="payroll-reports__rightblock">
          {type === PayRollReportsTypes.BANK_SCROLL ? (
            <BankScroll />
          ) : type === PayRollReportsTypes.LEAVES ? (
            <Leaves />
          ) : type === PayRollReportsTypes.SALARY_STATEMENT ? (
            <PaySlip type={type} />
          ) : (
            <PaySlip type={type} />
          )}
          <div className="row g-0 payroll-reports__rightblock--details">
            <div className="col"></div>
            <div className="col"></div>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button mode="export" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

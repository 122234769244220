import { gql } from "@apollo/client";

export const ParentByNodeId = gql`
  query ParentByNodeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on AuthParent {
        first_name
        middle_name
        last_name
        parent_type
        parent_address
        parent_place
        parent_state
        parent_pin
        parent_email
        parent_mobile
        parent_occup
        parent_qualification
        parent_aadhar
        parent_profile_filename
        parent_rights
      }
    }
  }
`;
export const GetParents = gql`
  query GetParents(
    $name: String
    $first: Int
    $token: String!
    $queryType: ParentQueryTypes!
    $inst_id: ID!
    $after: Cursor
    $sortBy: AuthParentOrderField
    $direction: OrderDirection!
  ) {
    GetParents(
      token: $token
      first: $first
      query_type: $queryType
      inst_id: $inst_id
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ firstNameContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          first_name
          middle_name
          last_name
          parent_address
          parent_place
          parent_state
          parent_pin
          parent_email
          parent_mobile
          is_login_created
          parent_occup
          parent_qualification
          parent_aadhar
          parent_type

          inst_id
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { GetDemoEnquires } from "./Queries";
import { useLazyQuery } from "@apollo/client";
import { Direction, SortBy } from "../utils/Enum.types";
import useToken from "../customhooks/useToken";
import Home from "../Modules/Master/Home/Index";
import useMasterTableJson from "../json/useMasterTableJson";
import {
  GridColDef,
  GridValidRowModel,
  GridAlignment,
} from "@mui/x-data-grid-pro";
import { Title } from "../stories/Title/Title";
import Input from "../stories/Input/Input";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../styles/DataGridTableStyles";
import { DemoEdges, GetDemoData, GetDemoVars, TableHeaderProps } from "./Types";
import { Button } from "../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import {
  getModifiedScrollHeight,
  toStandardDate,
} from "../utils/UtilFunctions";
import { FETCH_MORE_DATA } from "../utils/constants";

const DemoList = () => {
  const { token } = useToken();
  const [direction] = useState(Direction.ASC);
  const { Masters_Table } = useMasterTableJson();
  const [sortBy] = useState(SortBy.CREATED_AT);
  const [searchData, setSearchData] = useState("");
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [demos, setDemos] = useState<DemoEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const navigate = useNavigate();

  const [GetDemoList, { data, loading, fetchMore }] = useLazyQuery<
    GetDemoData,
    GetDemoVars
  >(GetDemoEnquires, {
    variables: {
      first: 25,
      after: null,
      sortBy,
      direction,
      token,
      name: searchData,
    },
  });
  useEffect(() => {
    if (token) {
      GetDemoList();
    }
  }, [token, GetDemoList]);

  const dynamicHeaders: TableHeaderProps[] =
    Masters_Table.DemoList.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.className,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetDemoEnquires?.edges;
                const pageInfo = fetchMoreResult.GetDemoEnquires.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetDemoEnquires.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetDemoEnquires: {
                    edges: [...demos, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetDemoEnquires.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetDemoEnquires?.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = demos.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setDemos(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: index + 1,
            created_at: toStandardDate(node.created_at),
            demo_enq_name: node.demo_enq_name,
            demo_enq_designation: node.demo_enq_designation,
            demo_enq_mobile_no: node.demo_enq_mobile_no,
            demo_enq_email: node.demo_enq_email,
            demo_enq_inst_name: node.demo_enq_inst_name,
            demo_enq_comments: node.demo_enq_comments,
          }))
        );
      } else {
        setDemos(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: index + 1,
            created_at: toStandardDate(node.created_at),
            demo_enq_name: node.demo_enq_name,
            demo_enq_designation: node.demo_enq_designation,
            demo_enq_mobile_no: node.demo_enq_mobile_no,
            demo_enq_email: node.demo_enq_email,
            demo_enq_inst_name: node.demo_enq_inst_name,
            demo_enq_comments: node.demo_enq_comments,
          }))
        );
      }
      setEndCursor(data.GetDemoEnquires?.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title> Demo List</Title>
      <div className="demo-list">
        <div className="demo-list__select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={`demo-list__tableblock `}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            
          />
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default DemoList;

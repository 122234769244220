import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetAcctLdgrs } from "../queries/FeeLedgers/query/Byid";
import { Direction, SortBy } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import { AppContext } from "../../../context/context";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";

export interface acct_group_ldgr_details {
  gr_ldgr_desc: string;
  id: number;
  gr_ldgr_rp: string;
  gr_ldgr_db_cr: string;
}
export interface ldgr_summary {
  id: number;
  ldgr_rp_ob: number;
  ldgr_rp_cr: number;
  ldgr_rp_db: number;
  ldgr_rp_cb: number;
  ldgr_rp_rcpt_show: number;
  ldgr_rp_pymt_show: number;
  ldgr_ie_cb: number;
  ldgr_ie_ob: number;
  ldgr_ie_cr: number;
  ldgr_ie_db: number;
  ldgr_bs_ob: number;
  ldgr_bs_cr: number;
  ldgr_bs_db: number;
  ldgr_bs_cb: number;
}

interface ldgr_yearly_data {
  fee_ldgr_ob: number;
  fee_ldgr_demand: number;
  fee_ldgr_concession: number;
  fee_ldgr_receivable: number;
  fee_ldgr_received: number;
  fee_ldgr_refunds: number;
  fee_ldgr_bal: number;
  fee_ldgr_dropout_demand: number;
}
export interface FeeLedgerListNode {
  ldgr_desc: string;
  id: number;
  ldgr_cb: number;
  ldgr_std_fee: number;
  ldgr_cash_bank_other: string;
  ldgr_sundry_address: string;
  ldgr_sundry_gst_no: string;
  ldgr_sundry_contact_person_mobile: string;
  acct_group_ldgr_details: acct_group_ldgr_details;
  ldgr_summary: ldgr_summary;
  ldgr_yearly_data: ldgr_yearly_data[];
  voucher_book_details: {
    id: number;
    vo_book_key: string;
    voucher_type: string;
    vo_book_desc: string;
  };
}

export interface FeeLedgerEdge {
  node: FeeLedgerListNode;
}
export interface FeeLedgerPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}
export interface FeeLedgerDetails {
  edges: FeeLedgerEdge[];
  pageInfo: FeeLedgerPageInfo;
  totalCount: number;
}
export interface FeeLedgerData {
  GetAcctLdgrs: FeeLedgerDetails;
}

interface input {
  ids: (string | number)[];
  acct_ldgr_query_type: string;
}
export interface FeeLedgerVars {
  name: string;
  after: string | null;
  orderBy: { field?: string; direction: string }[];
  first: number | null;
  token: string;
  id: number | null;
  input: input;
}

const useAcctLedgerData = (
  name: string,
  type: AcctLedgerQueryType | string,
  first: number | null,
  feeLedgerModal?: boolean,
  orderby?: SortBy | string,
  groupLedgerId?: number,
  id?: number
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [scrolledResponseType, setScrolledResponseType] = useState<
    FeeLedgerEdge[]
  >([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const { state } = useContext(AppContext);

  const [acct_ldgr_query_type, setacct_ldgr_query_type] = useState("");
  const [ids, setIds] = useState<number[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [GetAccountLedgers, { data, loading, error, fetchMore }] = useLazyQuery<
    FeeLedgerData,
    FeeLedgerVars
  >(GetAcctLdgrs);

  const { id: finyrId } = state.ActiveFinYr || {};
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: first,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctLdgrs.edges;
            const pageInfo = fetchMoreResult.GetAcctLdgrs.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctLdgrs.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctLdgrs: {
                edges: [...scrolledResponseType, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctLdgrs.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!(InstId || state.InstId) || !type || !finyrId) return;

    switch (type) {
      case AcctLedgerQueryType.ACCT_LDGR_BY_GROUP_LDGR_ID:
        setacct_ldgr_query_type(type);
        setIds([groupLedgerId!]);
        break;
      case AcctLedgerQueryType.BILL_BOOK_ASSIGNED_STD_FEE_ACCT_LDGRS:
        setacct_ldgr_query_type(type);
        setIds([Number(InstId ? InstId : state.InstId)!, finyrId]);
        break;
      default:
        setacct_ldgr_query_type(type);
        setIds([Number(InstId ? InstId : state.InstId)]);
        break;
    }
  }, [InstId, type, groupLedgerId, finyrId, state.ActiveFinYr]);

  useEffect(() => {
    if (acct_ldgr_query_type) {
      GetAccountLedgers({
        variables: {
          token,
          id: id ? id : null,
          after: null,
          input: { acct_ldgr_query_type, ids },

          orderBy: [
            { direction: Direction.ASC, field: orderby ? orderby : "INDEX" },
            { direction: Direction.DESC, field: SortBy.LDGR_DESC },
          ],
          first,
          name,
        },
      }).then(({ data, error }) => {
        if (data && data.GetAcctLdgrs) {
          const responseType = data.GetAcctLdgrs.edges.map((edge) => ({
            label: edge.node.ldgr_desc,
            value: edge.node.id,
            isChecked: false,
          }));
          setResponseType(responseType);
        }
        if (error) {
          return error.clientErrors;
        }
      });
    }
  }, [
    GetAccountLedgers,
    name,
    id,
    ids,
    acct_ldgr_query_type,
    type,
    token,
    orderby,
    state.InstId,
    first,
    feeLedgerModal,
    data,
  ]);
  useEffect(() => {
    if (data && !loading) {
      setHasNextPage(true);
      const newData = data.GetAcctLdgrs.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = scrolledResponseType.find(
            (transaction) => transaction.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setScrolledResponseType(updatedNewData);
      } else {
        setScrolledResponseType(newData);
      }
      setEndCursor(data.GetAcctLdgrs.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return {
    acctLedgers: {
      data,
      loading,
      error,
      responseType,
      scrolledResponseType,
      fetchMore,
      handleScroll,
    },
  };
};

export default useAcctLedgerData;

import { gql } from "@apollo/client";

export const AddPayRollGeneralHolidays = gql`
  mutation AddPayRollGeneralHolidays(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $input: [CreatePayRollGeneralHolidaysInput!]!
  ) {
    AddPayRollGeneralHolidays(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      input: $input
    )
  }
`;
export const AddPayRollGeneralHolidayConfigAndDetails = gql`
  mutation AddPayRollGeneralHolidayConfigAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $input: PrHolidayConfigAndDetails!
  ) {
    AddPayRollGeneralHolidayConfigAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      input: $input
    )
  }
`;

export const DeletePayRollMonthlyHolidayDetails = gql`
  mutation DeletePayRollMonthlyHolidayDetails(
    $token: String!
    $pr_monthly_holidays_id: ID!
    $holiday_details_id: ID!
  ) {
    DeletePayRollMonthlyHolidayDetails(
      token: $token
      pr_monthly_holidays_id: $pr_monthly_holidays_id
      holiday_details_id: $holiday_details_id
    )
  }
`;

export const DeletePayRollGeneralHolidaysById = gql`
  mutation DeletePayRollGeneralHolidaysById($token: String!, $id: ID!) {
    DeletePayRollGeneralHolidaysById(token: $token, id: $id)
  }
`;

export const UpdatePayRollGeneralHolidayById = gql`
  mutation UpdatePayRollGeneralHolidayById(
    $token: String!
    $id: ID!
    $input: UpdatePayRollGeneralHolidaysInput!
  ) {
    UpdatePayRollGeneralHolidayById(token: $token, id: $id, input: $input)
  }
`;

export const AddPayRollMonthlyHolidayAndDetails = gql`
  mutation UpdatePayRollGeneralHolidayById(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $input: PrMonthlyHolidaysAndDetailsInput!
  ) {
    AddPayRollMonthlyHolidayAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      input: $input
    )
  }
`;

export const AddPayRollMonthlyHolidayDetails = gql`
  mutation AddPayRollMonthlyHolidayDetails(
    $token: String!
    $pr_monthly_holidays_id: ID!
    $input: [CreatePayRollMonthlyHolidayDetailsInput!]!
  ) {
    AddPayRollMonthlyHolidayDetails(
      token: $token
      pr_monthly_holidays_id: $pr_monthly_holidays_id
      input: $input
    )
  }
`;
export const DeletePayRollGeneralHolidaysAndConfig = gql`
  mutation DeletePayRollGeneralHolidaysAndConfig(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $holiday_ids: [ID!]!
    $input: DeleteAcdInstHolidayConfig!
  ) {
    DeletePayRollGeneralHolidaysAndConfig(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      holiday_ids: $holiday_ids
      input: $input
    )
  }
`;

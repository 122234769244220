import React from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../components/common/Input/Index";
import { Label } from "../../../../stories/Label/Label";
import { Select } from "../../../../stories/Select/Select";
import { Title } from "../../../../stories/Title/Title";
import { UserType, YesNo } from "../../../../utils/Enum.types";
import { LabelNameProps } from "../../../../Types/Labels";

const { Userdetails } = require("../../../../json/config.json");

const UserDetails = () => {
  return (
    <>
      <Title>User Details</Title>
      <div className="main-frame">
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="details">
              <h4>User log</h4>
            </div>
            {Userdetails?.Labels.map(
              (label: LabelNameProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    <Label>{label.LabelName}</Label>
                    <Input name={label.inputName} required={label.required} />
                  </React.Fragment>
                );
              }
            )}
            <Label>User Type</Label>
            <Select name="Select">
              <option value="">Select</option>
              {Object.values(UserType).map((value) => (
                <option aria-selected="true" key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
            <Label>Is Active</Label>
            <Select name="Select">
              <option value="">Select</option>
              {Object.values(YesNo).map((value) => (
                <option aria-selected="true" key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <Button mode="addnew" type="submit" />

            <Button mode="cancel" type="button" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;

import { gql } from "@apollo/client";

export const SectionIdByName = gql`
  query GetSectionIdsByNames(
    $names: [String!]
    $token: String!
    $inst_id: ID!
  ) {
    GetSectionIdsByNames(
      token: $token
      section_names: $names
      inst_id: $inst_id
    ) {
      id
      section_desc
    }
  }
`;

export const SectionIdBySectionName = gql`
  query SectionIdByName(
    $token: String!
    $section_name: String!
    $semester_id: ID!
    $inst_id: ID!
  ) {
    GetSectionIdByName(
      token: $token
      section_name: $section_name
      semester_id: $semester_id
      inst_id: $inst_id
    )
  }
`;

export const SectionNotAllocatedId = gql`
  query GetSectionNotAllocateId($token: String!, $inst_id: ID!) {
    GetSectionNotAllocateId(token: $token, inst_id: $inst_id)
  }
`;

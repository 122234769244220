import { gql } from "@apollo/client";

export const AddMstInstReferenceData = gql`
  mutation AddMstInstReferenceData(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateMstInstReferenceDataInput!
  ) {
    AddMstInstReferenceData(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddMstInstReferenceDataTypeOnly = gql`
  mutation AddMstInstReferenceDataTypeOnly(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $data_type: String!
  ) {
    AddMstInstReferenceDataTypeOnly(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      data_type: $data_type
    )
  }
`;

export enum EarnOrDeduction {
  earnings = "earnings",
  deductions = "deductions",
}

export enum PayRollMasterConfigKey {
  PR_USE_EMP_CATEGORY = "PR_USE_EMP_CATEGORY",
  PR_USE_EMP_JOB_TYPE = "PR_USE_EMP_JOB_TYPE",
  PR_USE_EMP_DEPT = "PR_USE_EMP_DEPT",
  PR_USE_EMP_GRADE = "PR_USE_EMP_GRADE",
  PR_USE_EMP_DESIGNATION = "PR_USE_EMP_DESIGNATION",
}

export enum SalaryLedgerType {
  DEDUCTION = "D",
  EARNING = "E",
}

export enum PayRollConfigKeys {
  PR_ENABLE_GRP_INSURANCE_SAL_TYPE = "PR_ENABLE_GRP_INSURANCE_SAL_TYPE",
  PR_ENABLE_INSURANCE_SAL_TYPE = "PR_ENABLE_INSURANCE_SAL_TYPE",
  PR_ENABLE_LOAN_SAL_TYPE = "PR_ENABLE_LOAN_SAL_TYPE",
  SALARY_CALCULATIONS_BASED_ON = "SALARY_CALCULATIONS_BASED_ON",
  ROUND_TO_DECIMAL = "ROUND_TO_DECIMAL",
  EARNED_SAL_CAL = "EARNED_SAL_CAL",
  ATT_ENTRY_METHOD = "ATT_ENTRY_METHOD",
  PR_EDIT_EMP_ID = "PR_EDIT_EMP_ID",
  PR_ENABLE_LAST_NAME = "PR_ENABLE_LAST_NAME",
  PR_ENABLE_MIDDLE_NAME = "PR_ENABLE_MIDDLE_NAME",
  ENABLE_PAYROLL_PROCESS = "ENABLE_PAYROLL_PROCESS",
}

export enum DeductionOptions {
  LOAN = "L",
  INSURANCE = "I",
  GROUP_INSURANCE = "GI",
}

export enum EarningOptions {
  GENERAL = "G",
  ALLOWANCES = "AL",
  AREARS = "AR",
}

export enum calculationType {
  Percentage = "P",
  Value = "V",
}

export enum PER_VALUE {
  PER = "PER",
  VALUE = "VALUE",
}

export enum cellEditType {
  FLR = "FLR",
  UDV_E = "UDV_E",
  UDV_D = "UDV_D",
  UDM = "UDM",
  EARNED_VALUE_RESTRICTED = "EVR",
  EARNED_BASIC_VALUE = "EBV",
  NONE = "NONE",
  RULE_ON_BASIC_SALARY = "RBS",
  APPLY_ON_HEADS = "AOH",
  CALL_TO_MAX_VALUE = "CTMV",
}

export enum cellName {
  UDV = "ledger_udvMonthly",
  RULE_ON_BASIC_SALARY = "rule_applied",
  REMAINING_BALANCE = "remaining_balance",
}

export enum applyHeadsOn {
  BASIC_SALARY = "BS",
  GROSS_SALARY = "GS",
  ON_GROUP_OF_LEDGERS = "GL",
}

export enum OverAllSalaryLedgerPageType {
  ASSIGN_SALARY = "ASSIGN_SALARY",
  SALARY_STRUCTURE = "SALARY_STRUCTURE",
}

export enum PayRollSortBy {
  DESIGNATION_DESC = "DESIGNATION_DESC",
}

export enum PayRollMasters {
  DEPARTMENT = "DEPARTMENT",
  DESIGNATION = "DESIGNATION",
  JOB_TYPE = "JOB_TYPE",
  CATEGORY = "CATEGORY",
  GRADE = "GRADE",
}

//AssignLeave Ledger
export enum clearType {
  CLEAR_FIELDS = "CLEAR_FIELDS",
  CLEAR_ALL = "CLEAR_ALL",
}

//Salary Calculation Based on Config
export enum SalaryCalculationConfig {
  BASIC = "BASIC",
  GROSS = "GROSS",
}

export enum EarnedSalaryMethod {
  MONTH_DAYS = "MD",
  D30 = "30-D",
  NOWD = "NO-WD",
}

export enum AttEntryMethodEnum {
  DAILY = "DAILY",
  ONCE_IN_A_MONTH = "ONCE",
  WITHOUT_LEAVE_MANAGEMENT = "WLM",
}

export enum SwConfigQueryType {
  BY_CONFIG_KEY = "BY_CONFIG_KEY",
  PAYROLL_CONFIG_FLAGS = "PAYROLL_CONFIG_FLAGS",
  INST_COMPLETE_CONFIG = "INST_COMPLETE_CONFIG",
  EDUATE_COMPLETE_CONFIG = "EDUATE_COMPLETE_CONFIG",
  INST_BY_MODULE = "INST_BY_MODULE",
  EDUATE_BY_MODULE = "EDUATE_BY_MODULE",
}

export enum PrEmpAttQueryType {
  ALL_EMPS_ATT = "ALL_EMPS_ATT",
  EMPS_ATT_ADDED_FOR_SAL_MK_MONTH = "EMPS_ATT_ADDED_FOR_SAL_MK_MONTH",
  EMPS_ATT_NOT_ADDED_FOR_SAL_MK_MONTH = "EMPS_ATT_NOT_ADDED_FOR_SAL_MK_MONTH",
  ALL_EMP_ABSENTEES = "ALL_EMP_ABSENTEES",
}

export enum SubmitType {
  CANCEL = "CANCEL",
  SAVE = "SAVE",
}

export const enum ComponentFor {
  MASTERS = "Masters",
  OAGANIZATIONAL = "Oaganizational",
}

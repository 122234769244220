import { TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import DataFetch from "../../../../images/Edit.svg";
import Avatar from "../../../../images/Avatar.svg";
import Transport from "../../../../images/Transport.svg";
import Hostel from "../../../../images/Hostel.svg";
import Modal from "react-modal";
import { AntSwitch } from "../../../../pages/Switch";
import Close from "../../../../images/Close.svg";
import Warning from "../../../../images/Warning.svg";
import {
  AssignRouteModalStyles,
  ErrorModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import StudentList from "../../../Master/Student/List";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
  StudentListFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import useAcdStudentsAdmNumber from "../../../Academics/hooks/useAcdStudentsAdmNumber";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import {
  msgType,
  responseType,
  transportResponse,
} from "../../../../utils/Form.types";
import { payloadTypes } from "../../../../context/reducer";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { toIsoDate, toStandardDate } from "../../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UpdateStudentAvailedServices } from "../../../../queries/students/mutations/update";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import useActiveInstModules from "../../../UserRights/hooks/useActiveInstModules";
import { Label } from "../../../../stories/Label/Label";
import Delete from "../../../../images/Delete.svg";
import Edit from "../../../../images/EditProfile.svg";
import Route from "../../../../images/Route.svg";
import AssignRoute from "./AssignRoute";
import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
  RouteDetailsByData,
  RouteDetailsByVars,
} from "../../../Transport/paginationTypes";
import {
  GetTransportFeeDetails,
  GetTransportRouteDetailsByMasterId,
  GetTransportStudent,
} from "../../../Transport/queries/list";
import { Dates } from "../../../Transport/MasterData/AssignRoute/ToStudent";
import {
  AddTransportStudent,
  DeleteTransportStudent,
  UpdateTransportStudent,
} from "../../../Transport/queries/mutations";
import { AddOrUpdateOrDeleteStudentDemandDetails } from "../../../Accounts/queries/demands/mutation";
import { GetStudents } from "../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { GetAcdStudentsForAdmissions } from "../../../Academics/queries/students/Index";
import useAcdStudentsForAdmission from "../../../Academics/hooks/useAcdStudentsForAdmission";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
export interface ServicesType {
  availed_transport: boolean;
  availed_hostel: boolean;
}

const Index = () => {
  const { state, dispatch } = useContext(AppContext);
  const { branchLabel, classLabel } = useInstLabels();
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [imageString, setImageString] = useState("");
  const { InstDetails } = useInstDetails(1);

  const [studentModal, setStudentModal] = useState(false);
  const [searchAdmNo, setSearchAdmNo] = useState("");
  const [formData, setFormData] = useState<ServicesType>({
    availed_transport: false,
    availed_hostel: false,
  });
  const [routeId, setRouteId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [modalFlag, setModalFlag] = useState(false);
  const [assignRoute, setAssignRoute] = useState(false);

  const [operation, setOperation] = useState(Operation.CREATE);

  const [stageSelected, setStageSelected] = useState<transportResponse | null>(
    null
  );

  const [date, setDate] = useState<Dates>({
    start_date: "",
    end_date: "",
    due_date: "",
  });
  //refs
  const admNoRef = useRef<HTMLSelectElement>(null);
  const admNoClearRef = admNoRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const { studentData, studentFormData } = useStudentDatabyId();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    searchAdmNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );
  const [GetTransportStudentData, { data: StudentRouteData }] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: state.studentId,
    },
  });

  const [GetRouteDetailsById, { data }] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id:
        operation === Operation.CREATE
          ? routeId.value!
          : StudentRouteData?.GetTransportStudent.route_details
              .route_master_id!,
    },
  });

  const { USE_TRANSPORT } = useActiveInstModules();

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    0,
    0,
    0,
    0,
    0,
    0,
    ROWS_PER_PAGE,
    0,
    EMPTY_STRING,
    operation === Operation.CREATE
      ? StudentReportType.STDS_NOT_AVAILED_TRANSPORT
      : StudentReportType.STDS_AVAILED_TRANSPORT,
    EMPTY_STRING,
    []
  );
  const [AssignStdRoute, { loading: creationLoading }] = useMutation(
    AddTransportStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateRoute, { loading: RouteupdationLoading }] = useMutation(
    UpdateTransportStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const studentsDropDown = AcademicsStudentData.data
    ? AcademicsStudentData.data.GetAcdStudents.edges.map((res) => ({
        label: res.node.std_adm_no,
        value: res.node.id,
      }))
    : [];

  const [UpdateStudentServices, { loading: servicesLoading }] = useMutation(
    UpdateStudentAvailedServices,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteStudentRoute] = useMutation(DeleteTransportStudent, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [GetRouteFeeData, { data: FeeData }] = useLazyQuery(
    GetTransportFeeDetails
  );

  useEffect(() => {
    if (stageSelected) {
      GetRouteFeeData({
        variables: {
          token,
          inst_id: state.InstId,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

          acct_ldgr_id: stageSelected.acct_ldgr_id,
        },
      });
    }
  }, [stageSelected, GetRouteFeeData, state.ActiveFinYr, token, state.InstId]);
  const [
    UpdateStudentDemandDetails,
    { loading: UpdateStudentDemandDetailsLoading },
  ] = useMutation(AddOrUpdateOrDeleteStudentDemandDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleSubmit = () => {
    UpdateStudentServices({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        input: {
          student_id: state.studentId,
          availed_transport: formData.availed_transport,
          availed_hostel: formData.availed_hostel,
        },
      },
    });
    UpdateStudentDemandDetails({
      variables: {
        token,
        student_id: [state.studentId],
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: {
          add_std_demand_details: [
            {
              fee_demand: FeeData?.GetTransportFeeDetails,
              acct_ldgr_id: stageSelected?.acct_ldgr_id,
              fee_due_date: toIsoDate(date.due_date),
            },
          ],
        },
        user_details,
        inst_id: InstId,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Demand Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });

    if (operation === Operation.CREATE) {
      AssignStdRoute({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          route_detail_id: stageSelected?.value!,
          student_ids: state.studentId,
        },
        refetchQueries: [
          {
            query: GetTransportStudent,
            variables: {
              token,
              student_id: state.studentId,
            },
          },
          {
            query: GetStudents,
            variables: {
              admNo: EMPTY_STRING,
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                std_query_type:
                  studentQueryTypes.NOT_AVAILED_TRANSPORT_BY_INST_ID,
                ids: [Number(InstId)],
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Assigned Route to Student",
            operation: Operation.CREATE,
          });
        }
      });
    } else if (
      operation === Operation.UPDATE &&
      formData.availed_transport === false
    ) {
      DeleteStudentRoute({
        variables: {
          token,
          student_id: state.studentId,
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcdStudentsForAdmissions,
            variables: {
              after: null,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              status: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              orderBy: [
                {
                  direction: Direction.ASC,
                  field: SortBy.STD_ROLL_NO,
                },
              ],
              input: {
                ids: [],
                acd_std_query_type:
                  StudentReportType.STDS_NOT_AVAILED_TRANSPORT,
                str_data: [],
                int_data: [],
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Deleted Student Associated Route",
            operation: Operation.DELETE,
          });
        }
      });
    } else {
      UpdateRoute({
        variables: {
          token,
          id: StudentRouteData?.GetTransportStudent.id,
          inst_id: InstId,
          user_details,
          input: {
            student_id: state.studentId,
            route_detail_id: stageSelected?.value!,
          },
        },
        refetchQueries: [
          {
            query: GetTransportStudent,
            variables: {
              token,
              student_id: state.studentId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            operation: Operation.UPDATE,
            message: "Successfully Updated Student Route",
          });
        }
      });
    }
  };
  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setDate({
      end_date: "",
      start_date: "",
      due_date: "",
    });
    setStageSelected(null);
    setRouteId(EMPTY_RESPONSETYPE_OBJECT);
    admNoClearRef?.click();
    setFormData({
      availed_hostel: false,
      availed_transport: false,
    });
  };

  const handleStageClear = () => {
    setDate({
      end_date: "",
      start_date: "",
      due_date: "",
    });
    setFormData({
      availed_hostel: false,
      availed_transport: false,
    });
    setStageSelected(null);
    setRouteId(EMPTY_RESPONSETYPE_OBJECT);
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setModalFlag(false);
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (
      operation === Operation.UPDATE &&
      AcademicsStudentData.data &&
      !AcademicsStudentData.loading
    ) {
      setFormData({
        availed_transport:
          AcademicsStudentData.data.GetAcdStudents.edges[0]?.node.misc_data
            .availed_transport!,
        availed_hostel:
          AcademicsStudentData.data.GetAcdStudents.edges[0]?.node.misc_data
            .availed_hostel,
      });
      setRouteId({
        label:
          data?.GetTransportRouteDetailsByMasterId[0].route_master_details
            .route_desc!,
        value:
          data?.GetTransportRouteDetailsByMasterId[0].route_master_details.id!,
      });
      setStageSelected({
        label: data?.GetTransportRouteDetailsByMasterId[0].route_stage_desc!,
        value: data?.GetTransportRouteDetailsByMasterId[0].id!,
        ldgr_desc:
          data?.GetTransportRouteDetailsByMasterId[0].acct_ldgr_details
            ?.ldgr_desc!,
        acct_ldgr_id:
          data?.GetTransportRouteDetailsByMasterId[0].acct_ldgr_details?.id!,
      });
      // setDate({
      //       start_date:data?.GetTransportRouteDetailsByMasterId[0].
      // })
    }
  }, [
    AcademicsStudentData.data,
    AcademicsStudentData.loading,
    operation,
    data,
  ]);
  useEffect(() => {
    if (operation === Operation.UPDATE) {
      GetTransportStudentData();
    }
  }, [GetTransportStudentData, operation, state.studentId, token]);
  useEffect(() => {
    if (
      routeId.value ||
      StudentRouteData?.GetTransportStudent.route_detail_id!
    ) {
      GetRouteDetailsById();
    }
  }, [GetRouteDetailsById, InstId, routeId.value, token, StudentRouteData]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {operation === Operation.CREATE
          ? "Student Services"
          : "Update Student Services"}
      </Title>
      <div className="student-services">
        <div className="row g-0 student-services__details">
          <div className="col-3">
            <div className="student-services__details--image-flex">
              <>
                {operation === Operation.CREATE ? (
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={
                      studentAddmissionNumber ?? EMPTY_RESPONSETYPE_OBJECT
                    }
                    isOptionEqualToValue={(option) =>
                      (option as responseType).value === state.studentId
                    }
                    ref={admNoRef}
                    openOnFocus
                    autoHighlight
                    forcePopupIcon
                    onChange={(e, newValue) => {
                      if (newValue) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: (newValue as responseType)?.value,
                          },
                        });
                      } else {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Admission Number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSearchAdmNo(e.target.value)
                        }
                        autoFocus
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                ) : (
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={studentsDropDown ?? EMPTY_RESPONSETYPE}
                    isOptionEqualToValue={(option) =>
                      (option as responseType).value === state.studentId
                    }
                    ref={admNoRef}
                    openOnFocus
                    autoHighlight
                    forcePopupIcon
                    onChange={(e, newValue) => {
                      if (newValue) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: (newValue as responseType)?.value,
                          },
                        });
                      } else {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Admission Number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSearchAdmNo(e.target.value)
                        }
                        autoFocus
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                )}
              </>
              <img
                src={DataFetch}
                alt="/"
                className="data-fetch-icon"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              label="Register Number"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="student-services__details--textfield"
              value={studentFormData.reg_number}
              disabled
            />
            <TextField
              label={branchLabel}
              className="student-services__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.branch}
              disabled
            />
          </div>
          <div className="col student-services__details--colspan">
            <TextField
              label="Name"
              className="student-services__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                studentFormData.first_name +
                " " +
                studentFormData.middle_name +
                " " +
                studentFormData.last_name
              }
              disabled
            />
            <TextField
              label="Father Name"
              className="student-services__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.father_name}
              disabled
            />

            <TextField
              label={classLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="student-services__details--textfield"
              value={studentFormData.class}
              disabled
            />
            <TextField
              label="Date of Admission"
              className="student-services__details--textfield--date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                studentFormData.std_doa
                  ? toStandardDate(studentFormData.std_doa)
                  : EMPTY_STRING
              }
              disabled
            />
            <TextField
              label="Academic Year"
              className="student-services__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.acd_yr}
              disabled
            />
            <TextField
              label="Mobile No."
              className="student-services__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.std_mobile}
              disabled
            />
          </div>
          <div className="col-1 student-services__details--student-profile">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <div className="row g-0 student-services__data">
          <div className="col-3 student-services__data--frame ">
            <Title variant="subtitle1">
              {operation === Operation.CREATE
                ? "Available Services"
                : "Update Available Services"}
            </Title>
            <ul className="student-services__data--li">
              {USE_TRANSPORT ? (
                <li>
                  <div>
                    <img src={Transport} alt="/" />
                    <Label variant="LabelBold">Transport</Label>
                  </div>

                  <div className="student-services__data--li--images">
                    {routeId.value && stageSelected?.value ? (
                      <>
                        <img
                          src={Edit}
                          alt="/"
                          onClick={() => setAssignRoute(!assignRoute)}
                        />
                        {operation === Operation.CREATE && (
                          <img
                            src={Delete}
                            alt="/"
                            onClick={handleStageClear}
                          />
                        )}
                      </>
                    ) : null}
                    <AntSwitch
                      checked={formData.availed_transport}
                      onClick={(e: React.MouseEvent) => {
                        setFormData({
                          ...formData,
                          availed_transport: (e.target as HTMLInputElement)
                            .checked,
                        });
                        if ((e.target as HTMLInputElement).checked) {
                          setAssignRoute(!assignRoute);
                        }
                      }}
                    />
                  </div>
                </li>
              ) : null}
              <li>
                <div>
                  <img src={Hostel} alt="/" />
                  <Label variant="LabelBold">Hostel</Label>
                </div>
                <AntSwitch
                  checked={formData.availed_hostel}
                  onClick={(e: React.MouseEvent) => {
                    setFormData({
                      ...formData,
                      availed_hostel: (e.target as HTMLInputElement).checked,
                    });
                  }}
                />
              </li>
            </ul>
          </div>

          {formData.availed_transport && (
            <div className="col-3 student-services__transport">
              <Title variant="subtitle1">
                <img src={Transport} alt="/" />
                &nbsp; Transport
              </Title>
              <div className="student-services__transport--view">
                <TextField
                  label="Bus Route"
                  className="student-services__transport--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={routeId?.label}
                  disabled
                />
                <TextField
                  label="Route Stage"
                  className="student-services__transport--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={stageSelected?.label}
                  disabled
                />

                <TextField
                  label="Ledger"
                  className="student-services__transport--textfield"
                  value={stageSelected?.ldgr_desc}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
                <TextField
                  label="Route Fee"
                  className="student-services__transport--textfield"
                  value={FeeData?.GetTransportFeeDetails}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
                <TextField
                  label="Fee Due Date"
                  type="date"
                  value={date.due_date}
                  onChange={(e) =>
                    setDate({
                      ...date,
                      due_date: e.target.value,
                    })
                  }
                  className="student-services__transport--textfield--date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
                <TextField
                  label="Start Date"
                  value={date.start_date}
                  onChange={(e) =>
                    setDate({
                      ...date,
                      start_date: e.target.value,
                    })
                  }
                  className="student-services__transport--textfield--date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  type="date"
                />
                <TextField
                  label="End Date"
                  value={date.end_date}
                  onChange={(e) =>
                    setDate({
                      ...date,
                      end_date: e.target.value,
                    })
                  }
                  className="student-services__transport--textfield--date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  type="date"
                />
              </div>
              <div className="student-services__transport--choosed-route">
                <img src={Route} alt="" />
                Choosed Route
              </div>
            </div>
          )}
          <div className="col"></div>
        </div>
        <Button
          mode="save"
          onClick={handleSubmit}
          disabled={
            !formData.availed_transport && operation === Operation.CREATE
          }
        />
        <Button mode="clear" onClick={handleClear} />
        <Button mode="edit" onClick={() => setOperation(Operation.UPDATE)} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      {/* studentmodal */}

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.GENERAL}
              queryType={
                operation === Operation.CREATE
                  ? StudentReportType.TRANSPORT_NOT_ENABLED
                  : StudentReportType.TRANSPORT_ENABLED
              }
              setStudentModal={setStudentModal}
              studentModal={studentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={assignRoute}
        style={AssignRouteModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AssignRoute
              setModal={setAssignRoute}
              setRouteId={setRouteId}
              routeId={routeId}
              date={date}
              setDate={setDate}
              setStageSelected={setStageSelected}
              stageSelected={stageSelected}
              setFormData={setFormData}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setAssignRoute(!assignRoute)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={modalFlag}
        style={ErrorModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <h3 className="delete-modal__title">
              <img src={Warning} alt="/" /> Are you sure?
            </h3>
            <div className="delete-modal__body">
              <span>
                This will remove all the routes assigned to this particular
                student?
              </span>
            </div>
            <div>
              <Button autoFocus onClick={handleSubmit} mode="save" />
              <Button onClick={() => setModalFlag(!modalFlag)} mode="cancel" />
            </div>
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setModalFlag(!modalFlag)} />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={
          creationLoading ||
          RouteupdationLoading ||
          servicesLoading ||
          UpdateStudentDemandDetailsLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

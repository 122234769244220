// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomerIndex from "../Modules/Eduate/customer/Index";
import Configurations from "../Modules/Eduate/customer/Configuration";
import Home from "../Modules/Eduate/Home/Index";
import InstitutionList from "../Modules/Eduate/Institution/InstitutionList";
import { LicenseTypes } from "../utils/Enum.types";
import PropritetorList from "../Modules/Proprietor/List/Index";
import AccountsDashboard from "../Modules/Accounts/Home/Index";
import AdmissionsDashboard from "../Modules/Master/Home/Index";
import CampusList from "../Modules/Eduate/Campus/List";
import ResetChairmanPassword from "../Modules/Proprietor/ResetChairmanPassword";

const CustomerRoutes = () => {
  return (
    <Routes>
      <Route path="/list" element={<CustomerIndex />} />
      <Route path="/:custId/configurations" element={<Configurations />} />
      <Route path="/:custId/institutionlist" element={<InstitutionList />} />
      <Route path="/:custId/proprietor/list" element={<PropritetorList />} />
      <Route
        path="/:custId/proprietor/resetPassword"
        element={<ResetChairmanPassword />}
      />

      <Route path="/:custId/campus/list" element={<CampusList />} />

      <Route
        path="/:custId/proprietor/accounts"
        element={<AccountsDashboard DashBoardRequired={true} />}
      />
      <Route
        path="/:custId/proprietor/admissions"
        element={<AdmissionsDashboard DashBoardRequired />}
      />
      <Route
        path="/"
        element={
          <Home
            NavType={LicenseTypes.EDUATE_CUSTOMER}
            DashBoardRequired={false}
          />
        }
      />
      {/* <Route path="/:custId" element={<Home />} /> */}
    </Routes>
  );
};

export default CustomerRoutes;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { Direction, SortBy } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import {
  CommonPaginationVars,
  GetPayRollLeaveLdgrsByInstIdData,
} from "../Types/paginationTypes";
import { GetPayRollLeaveLdgrsByInstId } from "../queries/leaveledger/list";

const useLeaveLedgersByInstId = (
  searchLeave: string,
  rowsPerPage: number | null,
  filterFemaleLeaves?: boolean
) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [GetLeaves, { data, error, loading, fetchMore }] = useLazyQuery<
    GetPayRollLeaveLdgrsByInstIdData,
    CommonPaginationVars
  >(GetPayRollLeaveLdgrsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: rowsPerPage,
      after: null,
      direction: Direction.ASC,
      sortBy: SortBy.LEAVE_DESC,
      name: searchLeave,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token) {
      GetLeaves().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetPayRollLeaveLdgrsByInstId.edges
              .filter(
                ({ node }) =>
                  (filterFemaleLeaves && !node.leave_only_for_women) ||
                  !filterFemaleLeaves
              )
              .map((leave) => ({
                label: leave.node.leave_desc,
                value: leave.node.id,
              }))
          );
        }
      });
    }
  }, [GetLeaves, InstId, token, rowsPerPage, searchLeave, filterFemaleLeaves]);
  return {
    leaveLedgers: {
      data,
      error,
      loading,
      fetchMore,
      responseType,
    },
  };
};

export default useLeaveLedgersByInstId;

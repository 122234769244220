import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { DailyDiaryQuestions } from "../../../../utils/Enum.types";
import { GlobalPageConfigData } from "../../../../Types/configtypes";

const useQuestionsConfig = () => {
  const { configData } = useSwConfigData([
    DailyDiaryQuestions.QUESTION_1,
    DailyDiaryQuestions.QUESTION_2,
    DailyDiaryQuestions.QUESTION_3,
    DailyDiaryQuestions.QUESTION_4,
    DailyDiaryQuestions.QUESTION_5,
    DailyDiaryQuestions.QUESTION_6,
    DailyDiaryQuestions.QUESTION_7,
    DailyDiaryQuestions.QUESTION_8,
    DailyDiaryQuestions.QUESTION_9,
    DailyDiaryQuestions.QUESTION_10,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let question1Boolean = false;
    let question1String = "";
    let question2Boolean = false;
    let question2String = "";
    let question3Boolean = false;
    let question3String = "";
    let question4Boolean = false;
    let question4String = "";
    let question5Boolean = false;
    let question5String = "";
    let question6Boolean = false;
    let question6String = "";
    let question7Boolean = false;
    let question7String = "";
    let question8Boolean = false;
    let question8String = "";
    let question9Boolean = false;
    let question9String = "";
    let question10Boolean = false;
    let question10String = "";
    if (data) {
      // Check if data is not undefined
      data.forEach((item) => {
        switch (item.config_key) {
          case DailyDiaryQuestions.QUESTION_1:
            question1Boolean = item.config_boolean_value;
            question1String = item.config_string_value;
            break;

          case DailyDiaryQuestions.QUESTION_2:
            question2Boolean = item.config_boolean_value;
            question2String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_3:
            question3Boolean = item.config_boolean_value;
            question3String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_4:
            question4Boolean = item.config_boolean_value;
            question4String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_5:
            question5Boolean = item.config_boolean_value;
            question5String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_6:
            question6Boolean = item.config_boolean_value;
            question6String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_7:
            question7Boolean = item.config_boolean_value;
            question7String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_8:
            question8Boolean = item.config_boolean_value;
            question8String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_9:
            question9Boolean = item.config_boolean_value;
            question9String = item.config_string_value;
            break;
          case DailyDiaryQuestions.QUESTION_10:
            question10Boolean = item.config_boolean_value;
            question10String = item.config_string_value;
            break;

          default:
            break;
        }
      });
    }

    return {
      question1Boolean,
      question1String,
      question2Boolean,
      question2String,
      question3Boolean,
      question3String,
      question4Boolean,
      question4String,
      question5Boolean,
      question5String,
      question6Boolean,
      question6String,
      question7Boolean,
      question7String,
      question8Boolean,
      question8String,
      question9Boolean,
      question9String,
      question10Boolean,
      question10String,
    };
  };

  return filterDataByConfigKey(configData.data?.GetSwConfigVariables!);
};

export default useQuestionsConfig;

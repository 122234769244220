import { useState, useEffect, useCallback } from "react";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const useRefreshToken = () => {
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdTokenResult(user).then((IdTokenResult) => {
          setToken(IdTokenResult.token);
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const refreshToken = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const IdTokenResult = await getIdTokenResult(user, true);
      setToken(IdTokenResult.token);
      return IdTokenResult.token;
    }
    throw new Error("No user is authenticated");
  }, []);

  return { token, refreshToken };
};

export default useRefreshToken;

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import {
  GetAcctGroupLdgrsData,
  GetAcctGroupLdgrsVars,
  GroupLedgerEdge,
} from "../hooks/useGroupLedgerData";
import useToken from "../../../customhooks/useToken";

import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { LedgerTitles } from "../../../Types/Titles";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";

import { reOrderProcess } from "../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { GetAcctGroupLdgrs } from "../queries/FeeLedgers/query/Byid";
import { EMPTY_STRING } from "../../../utils/constants";
import {
  ReOrderAcctGroupLdgrPymtIndex,
  ReOrderAcctGroupLdgrRcptIndex,
} from "../queries/GroupLedgers/mutation/Index";
import Home from "../Home/Index";
import { AcctGroupLdgrQueryType } from "../common/QueryTypes";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
const { AccountsTitles } = require("../json/title.json");

interface Props {
  sortBy:
    | SortBy.GR_LDGR_RCPT_INDEX_POSITION
    | SortBy.GR_LDGR_PYMT_INDEX_POSITION;
}
const Reorder = ({ sortBy }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [localItems, setLocalItems] = useState<GroupLedgerEdge[]>([]);
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [GetAcctGrpLdgrs, { data, loading }] = useLazyQuery<
    GetAcctGroupLdgrsData,
    GetAcctGroupLdgrsVars
  >(GetAcctGroupLdgrs, {
    variables: {
      token,
      name: EMPTY_STRING,
      direction: Direction.ASC,
      first: null,
      sortBy,
      id: null,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      input: {
        ids: [Number(InstId)],
        acct_group_ldgr_query_type:
          AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
      },
    },
    fetchPolicy: "network-only",
  });

  const [updateacctGroupOrderReceipt, { loading: updationLoading }] =
    useMutation(ReOrderAcctGroupLdgrRcptIndex, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const [updateacctGroupOrderPayment, { loading: updationPayLoading }] =
    useMutation(ReOrderAcctGroupLdgrPymtIndex, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  useEffect(() => {
    if (data) {
      setLocalItems(data?.GetAcctGroupLdgrs?.edges!);
    }
  }, [data, loading]);
  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: GroupLedgerEdge[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      return temp;
    });
  };
  const HandleSaveDragAndDropReceipt = () => {
    updateacctGroupOrderReceipt({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.node.id,
          gr_ldgr_rcpt_index_position: index + 1,
        })),
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctGroupLdgrs,
          variables: {
            token,
            name: EMPTY_STRING,
            direction: Direction.ASC,
            first: null,
            sortBy: SortBy.GR_LDGR_RCPT_INDEX_POSITION,
            id: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input: {
              ids: [Number(InstId)],
              acct_group_ldgr_query_type:
                AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Group Ledgers Receipt Re-Ordered Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const HandleSaveDragAndDropPayment = () => {
    updateacctGroupOrderPayment({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.node.id,
          gr_ldgr_pymt_index_position: index + 1,
        })),
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctGroupLdgrs,
          variables: {
            token,
            name: EMPTY_STRING,
            direction: Direction.ASC,
            first: null,
            sortBy: SortBy.GR_LDGR_PYMT_INDEX_POSITION,
            id: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input: {
              ids: [InstId!],
              acct_group_ldgr_query_type:
                AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Group Ledgers Payment Re-Ordered Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token && state.ActiveFinYr) {
      GetAcctGrpLdgrs();
    }
  }, [token, GetAcctGrpLdgrs, state.ActiveFinYr, InstId]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.GroupLedger.map(
          (title: LedgerTitles, index: React.Key) => {
            return <React.Fragment key={index}>{title.REORDER}</React.Fragment>;
          }
        )}
      </Title>
      <div className="reorder-groupledger__tableblock">
        <TableContainer className="reorder-groupledger__tableblock--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {localItems?.length > 1 && <TableCell></TableCell>}

                {Accounts_Table.GroupLedger.Reorder.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}> {th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {localItems?.map((response, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => {
                          return (
                            <TableRow
                              key={index}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                            >
                              {localItems?.length > 1 && (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  id="td-center"
                                  className="reorder-groupledger__tableblock--table--slno"
                                >
                                  =
                                </TableCell>
                              )}

                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                id="td-center"
                                className="reorder-groupledger__tableblock--table--slno"
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                key={index}
                              >
                                {response.node.gr_ldgr_desc}
                              </TableCell>
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>
      <div className="row g-0">
        <div className="col">
          <div className="button-left">
            {localItems?.length > 1 ? (
              <>
                <Button
                  mode="save-order"
                  onClick={() => {
                    sortBy === SortBy.GR_LDGR_RCPT_INDEX_POSITION
                      ? HandleSaveDragAndDropReceipt()
                      : HandleSaveDragAndDropPayment();
                  }}
                />
                <Button mode="clear" />
              </>
            ) : null}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
        <div className="col-2 reorder-groupledger__total">
          <div className="student-total-count">
            Total Ledgers :&nbsp;<b>{data?.GetAcctGroupLdgrs.totalCount!}</b>
          </div>
        </div>
      </div>

      <LoadingModal flag={updationLoading ?? updationPayLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default Reorder;

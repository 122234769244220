import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../context/context";

import useToken from "../../../customhooks/useToken";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import { GetAcctLdgrsByTypeDetails } from "../../../Types/Accounting";

import { DepositLedgerQueryType } from "../common/QueryTypes";
import { Direction } from "../../../utils/Enum.types";
import { useParams } from "react-router-dom";
import { EMPTY_STRING } from "../../../utils/constants";
import { StudentAcdNode } from "../../Academics/hooks/useAcdStudentsForAdmission";
export interface DepositDetailNode {
  id: number;
  deposit_ob: number;
  deposit_amt: number;
  deposit_total: number;
  deposit_adjusted: number;
  // back end key for previously refunded amount
  deposit_refunded: number;
  is_scholarship: boolean;

  deposit_bal: number;
  acct_ldgr_id: number;
  acct_ldgr_details: GetAcctLdgrsByTypeDetails;
  // front end key for calucalating user defined deposit amount
  deposit_refund: number;
  student_details: StudentAcdNode;
  branch:{
    branch_desc: string;
  }
  class:{
    class_desc: string;
  }
}
export interface DepositEdge {
  node: DepositDetailNode;
}
interface DepositPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}
interface DepositDetails {
  edges: DepositEdge[];
  pageInfo: DepositPageInfo;
  totalCount: number;
}

export interface GetAcctStdDepositsData {
  GetAcctStdDeposits: DepositDetails;
}

export interface GetAcctStdDepositsVars {
  token: string;
  inst_id: string;
  fin_yr_id: number;
  after: string | null;
  input: {
    query_type: string;
    student_id: number;
  };
  orderBy: { direction: Direction; field: string };
  deptID: number | null;
  branchID: number | null;
  classID: number | null;
  semesterID: number | null;
  sectionID: number | null;
  categoryID: number | null;
  name: string;
  ldgrDesc: string;
}

const useStudentDepositDetails = (
  type: DepositLedgerQueryType,
  name?: string,
  ldgrDesc?: string,
  deptID?: number,
  branchID?: number,
  classID?: number,
  semesterID?: number,
  sectionID?: number,
  categoryID?: number
) => {
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { token } = useToken();
  const [GetStudentDepositLedgers, { data, loading, error, fetchMore }] =
    useLazyQuery<GetAcctStdDepositsData, GetAcctStdDepositsVars>(
      GetAcctStdDeposits,
      { fetchPolicy: "network-only" }
    );

  useEffect(() => {
    if (type && state.ActiveFinYr) {
      GetStudentDepositLedgers({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          token,
          after: null,
          ldgrDesc: ldgrDesc ? ldgrDesc : EMPTY_STRING,
          input: { query_type: type, student_id: state.studentId },
          inst_id: InstId! ? InstId : state.InstId.toString(),
          orderBy: { direction: Direction.ASC, field: "STUDENT_ID" },
          deptID: deptID ? deptID : null,
          branchID: branchID ? branchID : null,
          classID: classID ? classID : null,
          semesterID: semesterID ? semesterID : null,
          sectionID: sectionID ? sectionID : null,
          categoryID: categoryID ? categoryID : null,
          name: name ? name : EMPTY_STRING,
        },
      });
    }
  }, [
    state.ActiveFinYr,
    token,
    state.studentId,
    type,
    InstId,
    GetStudentDepositLedgers,
    deptID,
    branchID,
    classID,
    semesterID,
    sectionID,
    name,
    ldgrDesc,
    categoryID,
    state.InstId,
  ]);
  return { StudentDepositData: { data, loading, error, fetchMore } };
};

export default useStudentDepositDetails;

import React, { useEffect } from "react";
import { GetSwConfigStudentDataEntryByInstId } from "../../../queries/institution/configuration/query";
import {
  GetSwConfigStudentDataEntryByInstIdData,
  GetSwConfigStudentDataEntryByInstIdVars,
} from "../../../Types/Student/Configuration";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { Title } from "../../../stories/Title/Title";
import { ConfigurationTitleProps } from "../../../Types/Titles";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Button } from "../../../stories/Button/Button";
import useInstLabels from "../../../customhooks/general/useInstLabels";

const { Configuration } = require("../../../json/title.json");
export interface Fields {
  fieldName: string;
  fieldLabel: string;
  enabled: boolean;
}

interface Props {
  studentPersonalSwConfigData: Fields[];
  setStudentPersonalSwConfigData: React.Dispatch<
    React.SetStateAction<Fields[]>
  >;
  downloadExcel: () => void;
}
const StudentFieldsForExport = ({
  studentPersonalSwConfigData,
  setStudentPersonalSwConfigData,
  downloadExcel,
}: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const {
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {departmentLabel,branchLabel,classLabel,semesterLabel,sectionLabel,categoryLabel}=useInstLabels();
  const basicDetails: (Fields | null)[] = [
    {
      enabled: true,
      fieldLabel: "Admission Number",
      fieldName: "std_adm_no",
    },
    {
      enabled: true,
      fieldLabel: "Register Number",
      fieldName: "std_reg_no",
    },
    {
      enabled: true,
      fieldLabel: "Name",
      fieldName: "std_name",
    },
    {
      enabled: true,
      fieldLabel: "Father Name",
      fieldName: "std_father_name",
    },
    {
      enabled: true,
      fieldLabel: "Father Mobile",
      fieldName: "std_father_mobile",
    },
    {
      enabled: true,
      fieldLabel: "Mother Name",
      fieldName: "std_mother_name",
    },
    {
      enabled: true,
      fieldLabel: "Mother Mobile",
      fieldName: "std_mother_mobile",
    },
    {
      enabled: true,
      fieldLabel: "Mobile",
      fieldName: "std_mobile",
    },
    {
      enabled: true,
      fieldLabel: "Email",
      fieldName: "std_email",
    },
    {
      enabled: true,
      fieldLabel: "D.O.B",
      fieldName: "std_dob",
    },
    {
      enabled: true,
      fieldLabel: "Gender",
      fieldName: "std_sex",
    },
    {
      enabled: true,
      fieldLabel: "Fresher",
      fieldName: "std_fresher",
    },
    USE_DEPARTMENT_KEY
      ? {
          enabled: true,
          fieldLabel: `${departmentLabel}`,
          fieldName: "dept",
        }
      : null,
    USE_BRANCH_KEY
      ? {
          enabled: true,
          fieldLabel: `${branchLabel}`,
          fieldName: "branch",
        }
      : null,
    USE_CLASS_KEY
      ? {
          enabled: true,
          fieldLabel: `${classLabel}`,
          fieldName: "class",
        }
      : null,
    USE_SEMESTER_KEY
      ? {
          enabled: true,
          fieldLabel: `${semesterLabel}`,
          fieldName: "sem",
        }
      : null,
    USE_SECTION_KEY
      ? {
          enabled: true,
          fieldLabel: `${sectionLabel}`,
          fieldName: "section",
        }
      : null,
    USE_CATEGORY_KEY
      ? {
          enabled: true,
          fieldLabel:`${categoryLabel}`,
          fieldName: "category",
        }
      : null,
    {
      enabled: true,
      fieldLabel: "D.O.A",
      fieldName: "std_doa",
    },
  ];

  const [GetStudentSwConfig] = useLazyQuery<
    GetSwConfigStudentDataEntryByInstIdData,
    GetSwConfigStudentDataEntryByInstIdVars
  >(GetSwConfigStudentDataEntryByInstId);
  useEffect(() => {
    GetStudentSwConfig({
      variables: {
        inst_id: InstId!,
        token,
      },
    }).then(({ data }) => {
      if (data && data.GetSwConfigStudentDataEntryByInstId) {
        setStudentPersonalSwConfigData([
          ...(basicDetails.filter((ele) => ele !== null) as Fields[]),
          ...data.GetSwConfigStudentDataEntryByInstId.filter(
            (ele) => ele.data_field_enable
          ).map((node) => ({
            enabled: node.data_field_enable,
            fieldLabel: node.data_field_label,
            fieldName: node.data_field_name,
          })),
        ]);
      }
    }); // eslint-disable-next-line
  }, [
    InstId,
    token,
    GetStudentSwConfig,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  ]);

  const handleChangeState = (field_name: string) => {
    const modifiedArray = studentPersonalSwConfigData.map((ele) => {
      if (ele.fieldName === field_name) {
        return {
          ...ele,
          enabled: !ele.enabled,
        };
      } else {
        return ele;
      }
    });

    setStudentPersonalSwConfigData(modifiedArray);
  };

  return (
    <div>
      <Title>
        {Configuration.Titles.map(
          (title: ConfigurationTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Student}</React.Fragment>;
          }
        )}
      </Title>{" "}
      <div className="select-all">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="allSelect"
                className="select-all--checkbox"
                checked={
                  studentPersonalSwConfigData.filter(({ enabled }) => enabled)
                    .length === studentPersonalSwConfigData.length
                }
                onChange={() =>
                  setStudentPersonalSwConfigData(
                    studentPersonalSwConfigData.map((ele) => {
                      if (ele.fieldName === "std_name") {
                        return ele;
                      } else {
                        return {
                          ...ele,
                          enabled: !ele.enabled,
                        };
                      }
                    })
                  )
                }
              />
            }
            label="All Select"
          />
        </FormGroup>
      </div>
      <div className="student-software-config">
        <div className="student-software-config__data">
          {studentPersonalSwConfigData
            ?.filter(
              ({ fieldLabel }) =>
                fieldLabel !== "STS No." && fieldLabel !== "Name"
            )
            ?.map((label, index) => {
              return (
                <FormGroup className="select-all__checkboxes" key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={label.fieldLabel}
                        checked={label.enabled}
                        onChange={() => handleChangeState(label.fieldName)}
                      />
                    }
                    label={
                      <>
                        &nbsp; {index + 1}) &nbsp;{label.fieldLabel}
                      </>
                    }
                  />
                </FormGroup>
              );
            })}
        </div>
      </div>
      <Button
        mode="export"
        onClick={downloadExcel}
        disabled={
          studentPersonalSwConfigData.filter(({ enabled }) => enabled)
            .length === 0
        }
      />
    </div>
  );
};

export default StudentFieldsForExport;

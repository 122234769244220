import React from "react";

import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { TextField } from "@mui/material";
import EduateLogo from "../../../../images/EduateLogo.svg";
import ModulesImg from "../../../../images/Modules.svg";
import FinancialYear from "../../../../images/FinancialYear.svg";
import AcademicYear from "../../../../images/AcademicYear.svg";
import LicenseStatus from "../../../../images/LicenceStatus.svg";
import Compare from "../../../../images/Compare.svg";
import Active from "../../../../images/ActiveRoute.svg";
import Inactive from "../../../../images/InActiveRoute.svg";
import { LabelNameProps } from "../../../../Types/Labels";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { InstitutionNode } from "../../../../customhooks/useInstitutionsByCustId";
import { EMPTY_STRING } from "../../../../utils/constants";
import {
  timeDifference,
  toInputStandardDate,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
const { Institutionformlabels } = require("../../../../json/config.json");
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewInstDetails = ({ setModalFlag }: Props) => {
  const { InstDetails } = useInstDetails(1);
  const combinedArray = Object.values(Institutionformlabels.Labels).flatMap(
    (arr: any) => arr
  );
  const { acd_yr_details, fin_yr_details, module_master_details } =
    InstDetails.data?.nodes[0] || {};
  const activeFInYr =
    (fin_yr_details &&
      fin_yr_details?.find(({ fin_is_curr_yr }) => fin_is_curr_yr)?.fin_yr) ||
    EMPTY_STRING;

  const activeAcdYr =
    (fin_yr_details &&
      acd_yr_details?.find(({ acd_is_curr_yr }) => acd_is_curr_yr)?.acd_yr) ||
    EMPTY_STRING;
  return (
    <>
      <Title>View Institution Details</Title>
      <div className="view-inst-details">
        <div className="row g-0 view-inst-details__datablock">
          <div className="col-7 view-inst-details__datablock--left">
            <Title variant="subtitle1">Institution Basic Details</Title>
            <div className="view-inst-details__datablock--left--inst-logo">
              <img src={EduateLogo} alt="EduateLogo" />
            </div>
            <div className="view-inst-details__datablock--left--details">
              {combinedArray.map((label: LabelNameProps, Index: React.Key) => {
                const value =
                  InstDetails.data?.nodes[0][
                    label.inputName as keyof InstitutionNode
                  ] || "";
                return (
                  <React.Fragment key={Index}>
                    <TextField
                      value={value}
                      name={label.inputName}
                      label={label.LabelName}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      disabled
                      className="view-inst-details__datablock--textfield"
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="col view-inst-details__datablock--block">
            <div className="view-inst-details__datablock--block--finyr">
              <Title variant="subtitle1">Financial Year</Title>
              <div className="view-inst-details__datablock--block--image">
                <img src={FinancialYear} alt="" />
              </div>
              <TextField
                disabled
                value={activeFInYr}
                className="view-inst-details__datablock--textfield"
              />
            </div>
            <div className="view-inst-details__datablock--block--acdyr">
              <Title variant="subtitle1">Academic Year</Title>
              <div className="view-inst-details__datablock--block--image">
                <img src={AcademicYear} alt="" />
              </div>
              <TextField
                value={activeAcdYr}
                disabled
                className="view-inst-details__datablock--textfield"
              />
            </div>

            <div className="view-inst-details__datablock--block--license">
              <Title variant="subtitle1">License Status</Title>
              <div className="view-inst-details__datablock--block--image">
                <img src={LicenseStatus} alt="" />
              </div>
              <div className="view-inst-details__datablock--block--license--dates">
                <TextField
                  type="date"
                  label="Start Date"
                  value={toInputStandardDate(
                    module_master_details
                      ? module_master_details.lic_start_date
                      : ""
                  )}
                  disabled
                  className="view-inst-details__datablock--textfield--start-date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
                <div className="view-inst-details__datablock--block--compare-image">
                  <img src={Compare} alt="" />
                </div>
                <TextField
                  type="date"
                  label="End Date"
                  value={toInputStandardDate(
                    module_master_details
                      ? module_master_details.lic_end_date
                      : ""
                  )}
                  disabled
                  className="view-inst-details__datablock--textfield--end-date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              </div>
              <div className="view-inst-details__datablock--block--license--days">
                <span>
                  {timeDifference(
                    module_master_details?.lic_start_date!,
                    module_master_details?.lic_end_date!
                  )}
                </span>
                Days Left
              </div>
              <div className="view-inst-details__datablock--block--license--active">
                {module_master_details?.lic_active ? (
                  <>
                    <img src={Active} alt="/" />
                    License Active
                  </>
                ) : (
                  <>
                    <img src={Inactive} alt="/" />
                    License Inactive
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col view-inst-details__datablock--block">
            <Title variant="subtitle1">Available Modules</Title>
            <div className="view-inst-details__datablock--block--image">
              <img src={ModulesImg} alt="" />
            </div>

            <ul className="view-inst-details__datablock--block--list">
              {module_master_details?.inst_module_details
                .filter((module) => module.is_module_enabled)
                .map((name, index) => {
                  return (
                    <li key={index}>
                      {index + 1}&nbsp;
                      {toStandardCase(name.eduate_module_details.Name)}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default ViewInstDetails;

import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import useToken from "../../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";

import { UpdateMstInstModuleDetails } from "../../../../../queries/students/mutations/new";
import MessageModal from "../../../../../pages/MessageModal";
import {
  timeDifference,
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
} from "../../../../../utils/UtilFunctions";
import { TableHeaderProps } from "../../../../../Types/Tables";

import LoadingModal from "../../../../../pages/LoadingModal";

import { IObjectKeys, msgType } from "../../../../../utils/Form.types";

import { AntSwitch } from "../../../../../pages/Switch";
import {
  GetMstInstModules,
  InstModuleById,
} from "../../../../../queries/customerModules/query";
import useInstModules, {
  InstModuleDetails,
} from "../../../../UserRights/hooks/useInstModules";
import Steps from "../Steps";
import {
  LicenseTypes,
  Operation,
  PageFor,
} from "../../../../../utils/Enum.types";
import { useMutation } from "@apollo/client";
import { Button } from "../../../../../stories/Button/Button";
import { AppContext } from "../../../../../context/context";
import Compare from "../../../../../images/Compare.svg";
import Active from "../../../../../images/ActiveRoute.svg";
import Inactive from "../../../../../images/InActiveRoute.svg";
import { Label } from "../../../../../stories/Label/Label";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
const { EduateModules } = require("../../../../../json/table.json");

interface ModuleType extends IObjectKeys {
  id: number;
  lic_type: string;
  lic_active: boolean;
  lic_exp_grace_period: number;
  lic_enabled_date: string;
  lic_start_date: string;
  lic_end_date: string;
}
interface Props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageType, setModal }: Props) => {
  const { token } = useToken();
  const { InstId, custId } = useParams();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [formData, setFormData] = useState<ModuleType>({
    lic_type: "",
    lic_active: false,
    lic_exp_grace_period: 0,
    lic_enabled_date: "",
    lic_start_date: "",
    lic_end_date: "",
    id: 0,
  });
  const [inst_module_details, setInstModuleDetails] = useState<
    InstModuleDetails[]
  >([]);
  const { InstModuleData } = useInstModules();

  //Mutations

  const [UpdateModule, { loading: updationLoading }] = useMutation(
    UpdateMstInstModuleDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //Methods
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };

  const handleSwitchToggle = (index: number) => {
    setInstModuleDetails((prevState) => {
      const updatedModuleDetails = prevState.map((module, idx) => {
        if (idx === index) {
          return {
            ...module,
            is_module_enabled: !module.is_module_enabled,
          };
        }
        return module;
      });
      return updatedModuleDetails;
    });
  };
  const HandleSubmit = () => {
    UpdateModule({
      variables: {
        token,
        inst_id: InstId || state.InstId,
        user_details,
        input: {
          module_master_id: formData.id,
          update_module_master: {
            lic_type: formData.lic_type,
            lic_active: formData.lic_active,
            lic_exp_grace_period: formData.lic_exp_grace_period,
            lic_enabled_date: toIsoDate(formData.lic_enabled_date),
            lic_start_date: toIsoDate(formData.lic_start_date),
            lic_end_date: toIsoDate(formData.lic_end_date),
          },
          module_details: inst_module_details.map((module) => ({
            is_module_enabled: module.is_module_enabled,
            eduate_module_id: module.eduate_module_details.id,
          })),
        },
      },
      refetchQueries: [
        {
          query: GetMstInstModules,
          variables: {
            token,
            inst_id: InstId || state.InstId,
          },
        },
        {
          query: InstModuleById,
          variables: {
            token,
            id: formData.id,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Module Updated Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleClear = () => {
    setFormData({
      lic_type: "",
      lic_active: false,
      lic_exp_grace_period: 0,
      lic_enabled_date: "",
      lic_start_date: "",
      lic_end_date: "",
      is_module_enabled: false,
      id: 0,
    });
  };

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (InstModuleData.data && !InstModuleData.loading) {
      const {
        inst_module_details,
        id,
        lic_active,
        lic_enabled_date,
        lic_end_date,
        lic_exp_grace_period,
        lic_start_date,
        lic_type,
      } = InstModuleData.data.GetMstInstModules;
      setFormData({
        is_module_enabled: false,
        lic_active,
        lic_enabled_date: toInputStandardDate(lic_enabled_date),
        lic_end_date: toInputStandardDate(lic_end_date),
        lic_exp_grace_period,
        lic_start_date: toInputStandardDate(lic_start_date),
        lic_type,
        id,
      });
      setInstModuleDetails(inst_module_details);
    }
  }, [InstModuleData.data, InstModuleData.loading]);

  return (
    <>
      {pageType === PageFor.GENERAL && (
        <>
          <Home
            DashBoardRequired={false}
            NavType={LicenseTypes.EDUATE_CUSTOMER}
          />
          <Steps step={5} />
        </>
      )}
      <div
        className={
          pageType === PageFor.MODAL ? "eduate-module__modal" : "eduate-module"
        }
      >
        <Title>Eduate Modules Configuration</Title>
        <div className="eduate-module__details">
          <div className="row g-0 eduate-module__details--add">
            <div className="col"></div>
            <div className="col eduate-module__details--add--block">
              <div className="eduate-module__details--add--dates">
                <TextField
                  type="date"
                  label="Start Date"
                  className="eduate-module__details--textfield--sdate"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={formData.lic_start_date}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleValueChange(e)
                  }
                  name="lic_start_date"
                />
                <img src={Compare} alt="/" />
                <TextField
                  type="date"
                  label="End Date"
                  className="eduate-module__details--textfield--edate"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={formData.lic_end_date}
                  name="lic_end_date"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleValueChange(e)
                  }
                />
              </div>
              <div className="eduate-module__details--add--gperiod">
                <TextField
                  type="number"
                  label="Grace Period"
                  value={formData.lic_exp_grace_period}
                  name="lic_exp_grace_period"
                  className="eduate-module__details--textfield--gperiod"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleValueChange(e)
                  }
                />
              </div>
              {formData.lic_start_date && formData.lic_end_date && (
                <div className="eduate-module__details--add--total">
                  <span>
                    {timeDifference(
                      formData.lic_start_date,
                      formData.lic_end_date
                    )}
                  </span>{" "}
                  days Left to Expire
                </div>
              )}
            </div>

            <div className="col eduate-module__details--add--license">
              {formData.lic_active ? (
                <img src={Active} alt="/" />
              ) : (
                <img src={Inactive} alt="/" />
              )}

              <div>
                <Label> License Active </Label>
                <AntSwitch
                  checked={formData.lic_active}
                  onClick={(e: React.MouseEvent) => {
                    setFormData({
                      ...formData,
                      lic_active: (e.target as HTMLInputElement).checked,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="eduate-module__tableblock">
            <TableContainer className="eduate-module__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {EduateModules.EduateModuleConfig.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inst_module_details?.map((response, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="eduate-module__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {toStandardCase(response.eduate_module_details?.Name)}
                        </TableCell>
                        <TableCell className="eduate-module__table--actions">
                          {formData.lic_type}{" "}
                        </TableCell>

                        <TableCell
                          id="td-center"
                          className="eduate-module__table--actions"
                        >
                          <AntSwitch
                            disabled={!formData.lic_active}
                            checked={response.is_module_enabled}
                            onClick={() => handleSwitchToggle(index)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <Button mode="save" onClick={HandleSubmit} />
        {pageType === PageFor.GENERAL && (
          <Button
            mode="move"
            onClick={() => navigate(`/eduate/${custId}/${InstId}/basiconfig`)}
          >
            &nbsp;Next
          </Button>
        )}
        {pageType === PageFor.GENERAL ? (
          <Button
            mode="back"
            onClick={() =>
              navigate(`/eduate/${custId}/${InstId}/payrollacdyear`)
            }
          />
        ) : (
          <Button mode="cancel" onClick={() => setModal(false)} />
        )}
      </div>

      <LoadingModal flag={updationLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

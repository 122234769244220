import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Form, Formik } from "formik";
import { EduateModuleValidation } from "../../../utils/validationRules";
import { LabelNameProps } from "../../../Types/Labels";
import Input from "../../../stories/Input/Input";
import InputHoc from "../../../components/common/Input/Index";
import { Button } from "../../../stories/Button/Button";
import { EMPTY_STRING } from "../../../utils/constants";
import useToken from "../../../customhooks/useToken";
import { useNavigate } from "react-router-dom";
import { IObjectKeys, msgType } from "../../../utils/Form.types";
import { LicenseTypes, Operation } from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddEduateModule,
  DeleteEduateModule,
  UpdateEduateModule,
} from "../../../queries/students/mutations/new";
import MessageModal from "../../../pages/MessageModal";
import { toInputStandardDate, toIsoDate } from "../../../utils/UtilFunctions";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  EduateModuleById,
  GetEduateModules,
} from "../../../queries/students/list/newApi";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";

import {
  GetEduateModulesData,
  GetEduateModulesVars,
} from "../../HR/Types/dataTypes";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
const { EmployeePreview } = require("../../HR/json/form.json");
const { EduateModules } = require("../../../json/table.json");

interface ModuleType extends IObjectKeys {
  Name: string;
  Desc: string;
  Version: string;
  LaunchDate: string;
  LastUpdatedDate: string;
}
const Index = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [deleteModal, setDeleteModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [moduleId, setModuleId] = useState(0);
  const [formData, setFormData] = useState<ModuleType>({
    Name: "",
    Desc: "",
    Version: "",
    LaunchDate: "",
    LastUpdatedDate: "",
  });
  //Queries
  const [GetModuleData, { data }] = useLazyQuery<
    GetEduateModulesData,
    GetEduateModulesVars
  >(GetEduateModules, {
    variables: {
      token,
    },
  });
  const [GetModuleById, { data: ModuleData, loading }] =
    useLazyQuery(EduateModuleById);

  //Mutations
  const [AddModule, { loading: creationLoading }] = useMutation(
    AddEduateModule,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateModule, { loading: updationLoading }] = useMutation(
    UpdateEduateModule,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteModule, { loading: deleteLoading }] = useMutation(
    DeleteEduateModule,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //Methods
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };
  const HandleSubmit = () => {
    if (operation === Operation.CREATE) {
      AddModule({
        variables: {
          token,
          input: {
            Name: formData.Name,
            Desc: formData.Desc,
            Version: formData.Version,
            LaunchDate: toIsoDate(formData.LaunchDate),
            LastUpdatedDate: toIsoDate(formData.LastUpdatedDate),
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetEduateModules,
            variables: {
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Module Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateModule({
        variables: {
          token,
          id: moduleId,
          input: {
            Name: formData.Name,
            Desc: formData.Desc,
            Version: formData.Version,
            LaunchDate: toIsoDate(formData.LaunchDate),
            LastUpdatedDate: toIsoDate(formData.LastUpdatedDate),
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetEduateModules,
            variables: {
              token,
            },
          },
          {
            query: EduateModuleById,
            variables: {
              token,
              id: moduleId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Module Updated Successfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };

  const HandleDelete = (id: number) => {
    DeleteModule({
      variables: {
        token,
        id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetEduateModules,
          variables: {
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Module Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClear = () => {
    setFormData({
      Name: "",
      Desc: "",
      Version: "",
      LaunchDate: "",
      LastUpdatedDate: "",
    });
    setOperation(Operation.CREATE);
    // nameRef?.focus();
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (ModuleData && !loading) {
      setFormData({
        Name: ModuleData.node.Name!,
        Desc: ModuleData.node.Desc!,
        Version: ModuleData.node.Version!,
        LaunchDate: toInputStandardDate(ModuleData.node.LaunchDate!),
        LastUpdatedDate: toInputStandardDate(ModuleData.node.LastUpdatedDate!),
      });
    }
  }, [ModuleData, loading]);
  useEffect(() => {
    if (token) {
      GetModuleData();
    }
  }, [token, GetModuleData]);

  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>Eduate Modules</Title>

      <Formik
        initialValues={formData}
        validationSchema={EduateModuleValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className={"library__frame"}>
              <div className="row g-0 library__frame--row">
                <div className="col h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">Add Eduate Module</Title>
                  </div>
                  <div className="library__frame--block">
                    {EmployeePreview.EduateModules?.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={
                                formData[label.inputName] === 0
                                  ? EMPTY_STRING
                                  : formData[label.inputName]
                              }
                              type={label.dataType}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="col h-100 booktype-right">
                  <div className="row g-0 library__frame--title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">List of Modules</Title>
                    </div>
                    <div className="col-3">
                      <Input id="search" placeholder="Search..." />
                    </div>
                  </div>
                  <div className="library__frame--block">
                    <TableContainer className="library__table">
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {EduateModules.EduateModuleList.Table_Headers.map(
                              (th: TableHeaderProps, index: React.Key) => {
                                return (
                                  <TableCell
                                    key={index}
                                    className={th.className}
                                  >
                                    {th.labelName}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.GetEduateModules.map(
                            (response, index: number) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    className="library__table--slno"
                                    id="td-center"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell>{response.Name}</TableCell>
                                  <TableCell>{response.Desc}</TableCell>
                                  <TableCell className="library__table--status">
                                    {response.Version}
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    className="library__table--actions"
                                  >
                                    <img
                                      src={EditProfile}
                                      alt="/"
                                      onClick={() => {
                                        GetModuleById({
                                          variables: {
                                            id: response.id,
                                            token,
                                          },
                                        });
                                        setModuleId(response.id);
                                        setOperation(Operation.UPDATE);
                                      }}
                                    />
                                    &nbsp;
                                    <img
                                      src={Delete}
                                      alt="/"
                                      onClick={() => {
                                        setModuleId(response.id);
                                        setOperation(Operation.DELETE);
                                        setDeleteModal(!deleteModal);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                <Button mode="save" type="submit" />
                <Button mode="clear" type="button" onClick={handleClear} />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => navigate(-1)}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deleteLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={moduleId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

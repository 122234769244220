import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";

import { Button } from "../../../../../stories/Button/Button";
import {
  InstitutionConfigurationTypes,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import ReactEcharts from "echarts-for-react";
import useAcdStudentDetails from "../../../hooks/useAcdStudentDetails";
import { AppContext } from "../../../../../context/context";

import { responseType } from "../../../../../utils/Form.types";
import {
  EMPTY_STRING,
  MULTI_SELECT_LIMIT_TAGS,
} from "../../../../../utils/constants";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../../hooks/useTestStatus";

import { useParams } from "react-router-dom";
import useEntryIdByStudent from "../../../hooks/useEntryIdByStudent";
import { useLazyQuery } from "@apollo/client";

import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useToken from "../../../../../customhooks/useToken";
import { GetAcdStudentMarksStatistics } from "../../../queries/test/query";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useSubjectAllotedForClass from "../../../hooks/useSubjectAllotedForClass";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  GetAcdStudentMarksStatisticsData,
  GetAcdStudentMarksStatisticsVars,
} from "../../../hooks/useAcdStdMaksStats";
import SingleTestGraph from "./SingleTestGraph";

import useStudentOverAllPerformance from "../../../hooks/useStudentOverAllPerformance";
import { TEXT_FONT_FAMILY } from "../../../../../styles/DataGridTableStyles";
import { colorsInGraph } from "../../../../../styles/Constants";
import InstDetails from "./StudentDetailsComponent";
import TableView from "../../../../../images/TableView.svg";
import GraphView from "../../../../../images/GraphView.svg";
import {
  CustomTooltip,
  TOOLTIP_COLORS,
} from "../../../../../styles/TooltipStyles";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import useUserRightsByEmpId from "../../../../UserRights/hooks/useUserRightsByEmpId";
import { GetAcdTeacherClassAssociations } from "../../../queries/subjects/List.tsx/Index";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsData,
  GetAcdTeacherClassAssociationsVars,
} from "../../../types/subject/Subject";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../../styles/AutocompleteListStyles";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  testConductId: responseType | null;
}
interface markDetails {
  std_marks_scored: number;
  subj_total_max_marks: number;
  marks_percentage: number;
  subj_total_min_marks: number;
  is_std_present: boolean;
}
export interface subjectMarks {
  [testId: string]: markDetails;
}
export interface testTypes {
  test_desc: string;
  subj_total_max_marks: number;
  subj_total_min_marks: number;
  std_marks_scored: number;
  marks_percentage: number;
  test_top_percentage: number;
  test_avg_percentage: number;
}

const ParticularStudentResults = ({ setModalFlag, testConductId }: Props) => {
  const { InstId } = useParams();
  const [tableViewActive, setTableViewActive] = useState(true);
  const { entry_id } = useEntryIdByStudent();
  const { token } = useToken();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [selectedTests, setSelectedTests] = useState<responseType[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<responseType[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<responseType[]>([]);
  const [tableData, setTableData] = useState<Map<string, subjectMarks>>(
    new Map()
  );
  const [tests, setTests] = useState<testTypes[]>([]);
  const { state } = useContext(AppContext);
  const [dataOptions, setDataOptions] = useState({});
  const [singleTestOptions, setSingleTestOptions] = useState({});
  const [overallPerformance, setOverAllPerformance] = useState(false);
  const { entryIdFromStudent } = useAcdStudentDetails();
  const { studentScores } = useStudentOverAllPerformance();
  const { entry_level } = useInstitutionConfiguration();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { InstConfigDetails } = useInstConfigByEntryId(entryIdFromStudent);
  const { acd_class_id, acd_semester_id, acd_section_id } = InstConfigDetails
    .data?.GetAcdInstConfigNames ?? {
    acd_class_id: 0,
    acd_semester_id: 0,
    acd_section_id: 0,
  };
  const { studentEntryId } = useStudentDatabyId();

  const { flag } = useCheckAllocationType(studentEntryId);

  const allotedLevel =
    configData.data?.GetSwConfigVariables[0].config_string_value;

  const allotedId =
    allotedLevel === "SEC"
      ? acd_section_id
      : allotedLevel === "SEM"
      ? acd_semester_id
      : acd_class_id;

  const { subjectsForClass } = useSubjectAllotedForClass(allotedId);

  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.SHOW_MARKS_IN_STD_LOGIN,
    entry_id
  );
  const [GetStats] = useLazyQuery<
    GetAcdStudentMarksStatisticsData,
    GetAcdStudentMarksStatisticsVars
  >(GetAcdStudentMarksStatistics, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_ids: selectedTests.map(({ value }) => value),
      subj_master_ids: selectedSubjects.map(({ value }) => value),
      student_id: state.studentId,
      per_std_subj_allocation: flag!,
    },
  });

  useEffect(() => {
    if (subjectsForClass.data && !subjectsForClass.loading) {
      setSubjectOptions(
        subjectsForClass.data.GetAcdSubjectAllotedForClass.flatMap((subj) =>
          subj.subj_is_elective
            ? subj.elective_details.map((elective) => ({
                label: `${elective.subject_details.subj_desc} (${elective.subject_details.subj_code})`,
                value: elective.subj_master_id,
              }))
            : [
                {
                  label: `${subj.subject_details.subj_desc} (${subj.subject_details.subj_code})`,
                  value: subj.subj_master_id,
                },
              ]
        ) ?? []
      );
    }
  }, [subjectsForClass.data, subjectsForClass.loading]);

  const tempTests: testTypes[] = [];
  useEffect(() => {
    if (testConductId?.value) {
      setSelectedTests([testConductId]);
    }
  }, [testConductId]);
  useEffect(() => {
    if (
      token &&
      state.studentId &&
      activeAcademicYearData.data &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetStats().then(({ data }) => {
        if (
          data &&
          data.GetAcdStudentMarksStatistics &&
          data.GetAcdStudentMarksStatistics.length
        ) {
          const tempTableData: Map<string, subjectMarks> = new Map();
          const tempSubjects: Set<string> = new Set();
          const tempMap = new Map();

          data.GetAcdStudentMarksStatistics.filter((test) => test).forEach(
            (test) => {
              tempTests.push({
                test_desc: test.acd_test_name,
                subj_total_max_marks: test.test_total_max_marks,
                subj_total_min_marks: test.test_total_min_marks,
                std_marks_scored: test.test_std_total_marks_scored,
                test_top_percentage: test.test_top_percentage,
                test_avg_percentage: test.test_avg_percentage,
                marks_percentage: test.test_std_total_percentage,
              });
              for (let sub = 0; sub <= test.marks_details.length - 1; sub++) {
                if (
                  !tempSubjects.has(
                    `${test.marks_details[sub].subj_desc} (${test.marks_details[sub].subj_code})`
                  )
                ) {
                  tempSubjects.add(
                    `${test.marks_details[sub].subj_desc} (${test.marks_details[sub].subj_code})`
                  );
                }
                let prev = tempMap.get(
                  test.marks_details[sub].subj_master_id
                )?.[0];

                let tablePrev = tempTableData.get(
                  `${test.marks_details[sub].subj_desc} (${test.marks_details[sub].subj_code})`
                );

                delete prev?.subjects;
                if (
                  prev &&
                  tempMap.has(test.marks_details[sub].subj_master_id)
                ) {
                  tempMap.set(test.marks_details[sub].subj_master_id, [
                    {
                      subjects: `${test.marks_details[sub].subj_desc} (${test.marks_details[sub].subj_code})`,
                      [test.acd_test_name.toString()]:
                        test.marks_details[sub].marks_percentage,
                      ...prev,
                    },
                  ]);
                  tempTableData.set(
                    `${test.marks_details[sub].subj_desc} (${test.marks_details[sub].subj_code})`,
                    {
                      ...tablePrev,
                      [test.acd_test_name.toString()]: {
                        std_marks_scored:
                          test.marks_details[sub].std_marks_scored,
                        subj_total_max_marks:
                          test.marks_details[sub].subj_total_max_marks,
                        subj_total_min_marks:
                          test.marks_details[sub].subj_total_min_marks,
                        marks_percentage:
                          test.marks_details[sub].marks_percentage,
                        is_std_present: test.marks_details[sub].is_std_present,
                      },
                    }
                  );
                } else {
                  tempMap.set(test.marks_details[sub].subj_master_id, [
                    {
                      subjects: `${test.marks_details[sub].subj_desc} (${test.marks_details[sub].subj_code})`,
                      [test.acd_test_name.toString()]:
                        test.marks_details[sub].marks_percentage,
                    },
                  ]);
                  tempTableData.set(
                    `${test.marks_details[sub].subj_desc} (${test.marks_details[sub].subj_code})`,
                    {
                      [test.acd_test_name.toString()]: {
                        std_marks_scored:
                          test.marks_details[sub].std_marks_scored,
                        subj_total_max_marks:
                          test.marks_details[sub].subj_total_max_marks,
                        subj_total_min_marks:
                          test.marks_details[sub].subj_total_min_marks,
                        marks_percentage:
                          test.marks_details[sub].marks_percentage,
                        is_std_present: test.marks_details[sub].is_std_present,
                      },
                    }
                  );
                }
              }
            }
          );
          setTableData(tempTableData);
          const tempTestsMap = new Map(
            tempTests.map((tempTest) => [
              tempTest.test_desc,
              {
                test_top_percentage: tempTest.test_top_percentage,
                test_avg_percentage: tempTest.test_avg_percentage,
              },
            ])
          );

          const result = Array.from(tempMap, ([testId, subj_marks]) => {
            const marksObj = subj_marks;

            return [...marksObj];
          });

          const sub_marks = result.map(
            (res) => res[0][selectedTests[0]?.label]
          );

          const tavg =
            selectedTests[0] && tempTestsMap.get(selectedTests[0].label)
              ? tempTestsMap.get(selectedTests[0].label)?.test_avg_percentage
              : 0;
          const tmax =
            selectedTests[0] && tempTestsMap.get(selectedTests[0].label)
              ? tempTestsMap.get(selectedTests[0].label)?.test_top_percentage
              : 0;
          const SingleTestOptions = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
                crossStyle: {
                  color: "#999",
                },
              },
              textStyle: {
                fontSize: 14,
                fontFamily: TEXT_FONT_FAMILY,
                color: "#1e293b",
              },
            },
            toolbox: {
              feature: {
                dataView: {
                  show: false,
                  readOnly: false,
                },
                magicType: { show: true, type: ["line", "bar", "piechart"] },
                saveAsImage: { show: true },
              },
            },
            legend: {
              show: true,
              data: Array.from(tempSubjects).map((label) => label),
              textStyle: {
                color: "var(--text-color)",
                fontSize: 12,
                fontFamily: TEXT_FONT_FAMILY,
              },
              icon: "rect",
              bottom: 10,
              width: "90%",
              type: "scroll",
            },
            xAxis: [
              {
                type: "category",
                data: Array.from(tempSubjects).map((label) => label),
                axisPointer: {
                  type: "shadow",
                },
                textStyle: {
                  color: "var(--text-color) !important",
                  fontSize: 12,
                  fontFamily: TEXT_FONT_FAMILY,
                },
                axisLabel: {
                  rotate: -25,
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: "Percentage",
                min: 0,
                max: 100,
                interval: 20,
                axisLabel: {
                  formatter: "{value}%",
                },
                textStyle: {
                  color: "var(--text-color) !important",
                  fontSize: 12,
                  fontFamily: TEXT_FONT_FAMILY,
                },
              },
            ],
            dataZoom: [
              {
                type: "inside",
                start: 0,
                end: 100,
              },
            ],
            series: [
              {
                data: sub_marks.map((value, index) => ({
                  value: value,
                  itemStyle: { color: colorsInGraph[index] },
                })),
                markLine: {
                  data: [
                    {
                      type: "average",
                      name: "Avg",
                      yAxis: tavg,
                    },
                    {
                      type: "max",
                      name: "Max",
                      yAxis: tmax,
                    },
                  ],
                },
                type: "bar",
                barWidth: 20,
              },
            ],
          };

          const JsonOptions = {
            legend: {
              show: true,
              data: result.length
                ? Object.keys(result.map((res) => res[0])[0]).filter(
                    (sub) => sub !== "subjects"
                  )
                : [],
              textStyle: {
                color: "black",
                fontSize: 12,
                fontFamily: TEXT_FONT_FAMILY,
              },
              icon: "rect",
              bottom: 10,
              width: "90%",
              type: "scroll",
            },
            tooltip: {},
            toolbox: {
              feature: {
                dataView: {
                  show: false,
                  readOnly: false,
                },
                magicType: { show: true, type: ["line", "bar", "piechart"] },
                saveAsImage: { show: true },
              },
            },
            dataset: {
              dimensions: result.length
                ? [
                    "subjects",

                    ...Object.keys(result.map((res) => res[0])[0]).filter(
                      (sub) => sub !== "subjects"
                    ),
                  ]
                : [],
              source: result.map((res) => res[0]),
            },
            xAxis: {
              type: "category",
              axisLabel: {
                rotate: -25,
              },
            },
            yAxis: {},
            dataZoom: [
              {
                type: "inside",
                start: 0,
                end: 100,
              },
            ],
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: Object.keys(result.map((res) => res[0])[0])
              .filter((sub) => sub !== "subjects")
              .map((data, index) => {
                return {
                  type: "bar",
                  barWidth: 20,
                  color: colorsInGraph[index % colorsInGraph.length],
                  markLine: {
                    data: [
                      {
                        type: "average",
                        name: "Avg",
                        yAxis: tempTestsMap.get(data)?.test_avg_percentage,
                      },
                      {
                        type: "max",
                        name: "Max",
                        yAxis: tempTestsMap.get(data)?.test_top_percentage,
                      },
                    ],
                  },
                };
              }),
          };

          setTests(tempTests);
          setDataOptions(JsonOptions);
          setSingleTestOptions(SingleTestOptions);
        }
      });
    }
    //eslint-disable-next-line
  }, [
    token,
    selectedSubjects,
    selectedTests,
    state.studentId,
    statusOfSubject.classTests,
    GetStats,
    state.ActiveAcdYr,
    flag,
  ]);

  return (
    <>
      <Title>Student Details</Title>
      <div className="particular-student-results">
        <InstDetails />

        {overallPerformance ? null : (
          <div className="particular-student-results__swipable--select row g-0">
            <div className="col-2">
              <b>Table View</b>
            </div>
            <div className="col"></div>

            <div className="col-2 ">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                limitTags={MULTI_SELECT_LIMIT_TAGS}
                multiple
                options={statusOfSubject.classTests}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={
                        selectedTests?.find(
                          ({ value }) =>
                            value === (option as responseType).value
                        )
                          ? true
                          : false
                      }
                    />
                    {(option as responseType).label}
                  </li>
                )}
                openOnFocus
                value={selectedTests ?? []}
                onChange={(e, newValue) => {
                  if (newValue) {
                    if (
                      (newValue as responseType[])
                        .map(({ value }) => value)
                        .includes(-52)
                    ) {
                      setSelectedTests(statusOfSubject.classTests);
                    } else {
                      setSelectedTests((newValue as responseType[]) ?? []);
                    }
                  } else {
                    setSelectedTests([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tests"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                limitTags={MULTI_SELECT_LIMIT_TAGS}
                multiple
                options={subjectOptions}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={
                        selectedSubjects?.find(
                          ({ value }) =>
                            value === (option as responseType).value
                        )
                          ? true
                          : false
                      }
                    />
                    {(option as responseType).label}
                  </li>
                )}
                openOnFocus
                value={selectedSubjects ?? []}
                onChange={(e, newValue) => {
                  if (newValue) {
                    if (
                      (newValue as responseType[])
                        .map(({ value }) => value)
                        .includes(-52)
                    ) {
                      setSelectedSubjects(subjectOptions);
                    } else {
                      setSelectedSubjects((newValue as responseType[]) ?? []);
                    }
                  } else {
                    setSelectedSubjects([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Subjects"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="col"></div>

            <div className="col-2 particular-student-results__swipable--select--images">
              <CustomTooltip
                title="Table View"
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={TableView}
                  alt=""
                  className={!tableViewActive ? "active-image" : ""}
                  onClick={() => setTableViewActive(!tableViewActive)}
                />
              </CustomTooltip>
              <CustomTooltip
                title="Graph View"
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={GraphView}
                  alt=""
                  className={tableViewActive ? "active-image" : ""}
                  onClick={() => setTableViewActive(!tableViewActive)}
                />
              </CustomTooltip>
            </div>
          </div>
        )}
        <div className="row g-0 particular-student-results__swipable justify-content-center">
          {!tableViewActive ? (
            <div className="particular-student-results__swipable--tableblock">
              <TableContainer className="particular-student-results__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell rowSpan={2} id="td-center">
                        {TableHeaders.SLNO}
                      </TableCell>
                      <TableCell rowSpan={2} id="td-center">
                        Subject
                      </TableCell>

                      {!overallPerformance &&
                        tests.map((data, index) => {
                          return (
                            <TableCell key={index} colSpan={3} id="td-center">
                              {data.test_desc}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                    <TableRow>
                      {overallPerformance ? (
                        <React.Fragment>
                          <TableCell id="td-center" className="particular-student-results__table--sticky">Marks Percentage</TableCell>
                        </React.Fragment>
                      ) : (
                        tests.map((_, index) => {
                          return (
                            <React.Fragment key={index}>
                              <TableCell id="td-center" className="particular-student-results__table--sticky">Max(Min)</TableCell>
                              <TableCell id="td-center" className="particular-student-results__table--sticky">Scored Marks</TableCell>
                              <TableCell id="td-center" className="particular-student-results__table--sticky">
                                Scored Percentage
                              </TableCell>
                            </React.Fragment>
                          );
                        })
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {overallPerformance
                      ? studentScores.map((score, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                id="td-center"
                                className="particular-student-results__table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell>{score.name}</TableCell>
                              <TableCell className="particular-student-results__table--marks">
                                {score.marks}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : Array.from(tableData).map(
                          ([key, value], index: number) => {
                            return (
                              <TableRow key={index}>
                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                <TableCell>{key}</TableCell>

                                {tests.map((test, i: number) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <TableCell id="td-center">
                                        {value[tests[i].test_desc]
                                          ? `${
                                              value[tests[i].test_desc]
                                                .subj_total_max_marks
                                            }(${
                                              value[tests[i].test_desc]
                                                .subj_total_min_marks
                                            })`
                                          : ""}
                                      </TableCell>
                                      <TableCell id="td-center">
                                        {value[tests[i].test_desc]
                                          ? value[tests[i].test_desc]
                                              .is_std_present
                                            ? value[tests[i].test_desc]
                                                .std_marks_scored
                                            : "A"
                                          : ""}
                                      </TableCell>
                                      <TableCell id="td-center">
                                        {value[tests[i].test_desc]
                                          ? value[tests[i].test_desc]
                                              .is_std_present
                                            ? value[tests[i].test_desc]
                                                .marks_percentage
                                            : "A"
                                          : ""}
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                })}
                              </TableRow>
                            );
                          }
                        )}
                  </TableBody>
                  {overallPerformance ? null : (
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          {selectedSubjects.length > 0 ? "" : "Total :"}
                        </TableCell>
                        {selectedSubjects.length > 0
                          ? null
                          : tests.map((test, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <TableCell id="td-center">
                                    {`${test.subj_total_max_marks}(${test.subj_total_min_marks})`}
                                  </TableCell>
                                  <TableCell id="td-center">
                                    {test.std_marks_scored}
                                  </TableCell>
                                  <TableCell id="td-center">
                                    {test.marks_percentage}
                                  </TableCell>
                                </React.Fragment>
                              );
                            })}
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="col-6 h100 ">
              {
                // overallPerformance ? (
                //   <OverAllPerformanceGraph />
                // ) :

                selectedTests.length === 1 ? (
                  <SingleTestGraph jsonOptions={singleTestOptions} />
                ) : (
                  <div className="particular-student-results__swipable--charts">
                    <ReactEcharts
                      option={dataOptions}
                      key={JSON.stringify(dataOptions)}
                    />
                  </div>
                )
              }
            </div>
          )}
        </div>
        {/* {!overallPerformance && (
          <Button onClick={() => setOverAllPerformance(!overallPerformance)}>
            <img src={viewAll} alt="" /> OverAllPerformance
          </Button>
        )} */}

        <Button
          mode="cancel"
          onClick={() => {
            if (overallPerformance) {
              setOverAllPerformance(!overallPerformance);
            } else {
              setModalFlag(false);
            }
          }}
        />
      </div>
    </>
  );
};

export default ParticularStudentResults;

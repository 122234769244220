import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GetStudentDocsByStudentIdData,
  GetStudentDocsByStudentIdVars,
} from "../../Master/Student/Documents/Index";
import { GetStudentDocsByStudentId } from "../../../queries/students/list/byId";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";

import { GetMstInstUploadDocTypes } from "../../../queries/students/list/newApi";
import {
  GetMstInstUploadDocTypesData,
  Vars,
} from "../../Master/Student/MandatoryDocs/StudentDocs";
import { toStandardDate } from "../../../utils/UtilFunctions";

const Acknowledgment = () => {
  const { studentId, InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();

  const [GetDocumentsByStdId, { data: documentsData }] = useLazyQuery<
    GetStudentDocsByStudentIdData,
    GetStudentDocsByStudentIdVars
  >(GetStudentDocsByStudentId, {
    variables: {
      student_id: studentId ? Number(studentId) : state.studentId,
      token,
    },
  });

  useEffect(() => {
    if (token && (state.studentId || studentId)) {
      GetDocumentsByStdId();
    }
  }, [token, GetDocumentsByStdId, documentsData, state.studentId, studentId]);

  const [GetDocuments, { data: documentsList }] = useLazyQuery<
    GetMstInstUploadDocTypesData,
    Vars
  >(GetMstInstUploadDocTypes, {
    variables: {
      inst_id: InstId!,
      token,
    },
  });

  useEffect(() => {
    if (token && InstId) {
      GetDocuments();
    }
  }, [token, GetDocuments, documentsList, InstId]);

  return (
    <>
      <TableContainer className="doc-ack-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Documents Description</TableCell>
              <TableCell>Collected</TableCell>
              <TableCell>Original / Photo Copy</TableCell>
              <TableCell>Date Of Collection</TableCell>
              <TableCell>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentsList &&
              documentsList.GetMstInstUploadDocTypes.map((res, index) => {
                const matchingDoc =
                  documentsData &&
                  documentsData.GetStudentDocsByStudentId.find(
                    (data) => data.std_doc_type === res.document_name
                  );

                return (
                  <TableRow key={index}>
                    <TableCell>{res.document_name}</TableCell>
                    <TableCell>
                      {matchingDoc
                        ? matchingDoc.std_doc_collected
                          ? "Yes"
                          : "Not Collected"
                        : "No"}
                    </TableCell>
                    <TableCell>
                      {matchingDoc
                        ? matchingDoc.std_doc_original
                          ? "Original"
                          : "Photo Copy"
                        : "-"}
                    </TableCell>
                    <TableCell id="td-center">
                      {matchingDoc && matchingDoc.date_of_collection
                        ? toStandardDate(matchingDoc.date_of_collection)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {matchingDoc && matchingDoc.std_doc_remarks
                        ? matchingDoc.std_doc_remarks
                        : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Acknowledgment;

import { gql } from "@apollo/client";

export const SearchUserByPhoneOrEmail = gql`
  query SearchUserByPhoneOrEmail(
    $token: String!
    $inst_id: ID!
    $phone_number: String
    $email_id: String
  ) {
    SearchUserByPhoneOrEmail(
      token: $token
      inst_id: $inst_id
      phone_number: $phone_number
      email_id: $email_id
    ) {
      student_details {
        id
        first_name
        middle_name
        last_name
        std_type
        std_studying
        std_reg_no
        std_adm_no
        std_dob
        std_email
        std_mobile
        std_father_name
        std_mother_name
        std_father_mobile
        std_mother_mobile
        std_profile_filename
        inst_details {
          inst_name
          inst_logo_filename
        }
      }
      parent_details {
        id
        first_name
        middle_name
        last_name
        parent_address
        parent_place
        parent_state
        parent_pin
        parent_email
        parent_mobile
        parent_occup
        parent_qualification
        parent_aadhar
        parent_rights
        parent_type
        parent_profile_filename
        parent_last_accessed
        firebase_id
        is_login_created
      }
      eduate_details {
        id
        first_name
        middle_name
        last_name
        eduate_address
        eduate_place
        eduate_state
        eduate_pin
        eduate_email
        eduate_mobile
        eduate_rights
        eduate_last_accessed
        eduate_user_type

        is_login_created
      }
      emp_details {
        id
        emp_id
        emp_title
        emp_type
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_sex
        emp_dob
        emp_mobile
        emp_email
        emp_qualification
      }
      owner_details {
        id
        owner_name
        owner_mobile
        owner_email
        mst_customer_id
        auth_id
        customer_details {
          cust_code
          cust_name
          cust_address
          cust_place
          cust_state
          cust_pin
          cust_contact_person
          cust_phone
          cust_email
          cust_mobile
        }
      }
    }
  }
`;

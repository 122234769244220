import { useState } from "react";
import { Button } from "../../../stories/Button/Button";
import Close from "../../../images/Close.svg";
import Modal from "react-modal";
import { ModalCustomStyles } from "../../../styles/ModalStyles";
import InstBasicData from "./InstCreation/InstBasicData";
import { Operation, PageFor } from "../../../utils/Enum.types";
import ModuleConfig from "./InstCreation/ModuleConfiguration/Configuration";
import EduateGlobalConfig from "./InstCreation/EduateGlobalConfig";
import { Title } from "../../../stories/Title/Title";
import FinancialYearList from "./InstCreation/financialyear/FinancialYearList";
import AcademicYearList from "./InstCreation/academicyear/AcademicYearList";
import PrAcdYearList from "./InstCreation/PayRollAcdYear/PrAcdYearList";
const EditIndex = () => {
  const [instBasicDetails, setInstBasicDetails] = useState(false);
  const [editFinYr, setEditFinYr] = useState(false);
  const [editAcdYr, setEditAcdYr] = useState(false);
  const [editPayRollAcdYr, setEditPayRollAcdYr] = useState(false);
  const [editModuleConfig, setEditModuleConfig] = useState(false);
  const [editBasicConfig, setEditBasicConfig] = useState(false);
  return (
    <>
      <Title>What Do You like to Edit?</Title>

      <div style={{ display: "flex", margin: "10px" }}>
        <Button onClick={() => setInstBasicDetails(!instBasicDetails)}>
          Basic Details
        </Button>
        <Button onClick={() => setEditFinYr(!editFinYr)}>Financial Year</Button>
        <Button onClick={() => setEditAcdYr(!editAcdYr)}>Academic Year</Button>
        <Button onClick={() => setEditPayRollAcdYr(!editPayRollAcdYr)}>
          PayRoll Academic Year
        </Button>
        <Button onClick={() => setEditModuleConfig(!editModuleConfig)}>
          Module Configuration
        </Button>
        <Button onClick={() => setEditBasicConfig(!editBasicConfig)}>
          Basic Configuration
        </Button>
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={instBasicDetails}
        style={ModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <InstBasicData
              operation={Operation.UPDATE}
              setModal={setInstBasicDetails}
            />
            {/* <Button
              mode="cancel"
              onClick={() => setInstBasicDetails(!instBasicDetails)}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setInstBasicDetails(!instBasicDetails)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editFinYr}
        style={ModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FinancialYearList onlyTable={true} />
            {/* <Button
              mode="cancel"
              onClick={() => setEditFinYr(!instBasicDetails)}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditFinYr(!editFinYr)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editAcdYr}
        style={ModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {/* <AcdYr pageType={PageFor.MODAL} setModal={setEditAcdYr} /> */}
            <AcademicYearList onlyTable={true} />
            {/* <Button
              mode="cancel"
              onClick={() => setEditAcdYr(!instBasicDetails)}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditAcdYr(!editAcdYr)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editPayRollAcdYr}
        style={ModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {/* <AcdYr pageType={PageFor.MODAL} setModal={setEditAcdYr} /> */}
            <PrAcdYearList onlyTable={true} />
            {/* <Button
              mode="cancel"
              onClick={() => setEditAcdYr(!instBasicDetails)}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditPayRollAcdYr(!editPayRollAcdYr)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editModuleConfig}
        style={ModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ModuleConfig
              pageType={PageFor.MODAL}
              setModal={setEditModuleConfig}
            />
            {/* <Button
              mode="cancel"
              onClick={() => setEditModuleConfig(!instBasicDetails)}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditModuleConfig(!editModuleConfig)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editBasicConfig}
        style={ModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EduateGlobalConfig
              pageType={PageFor.MODAL}
              setModal={setEditBasicConfig}
            />
            {/* <Button
              mode="cancel"
              onClick={() => setEditBasicConfig(!instBasicDetails)}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditBasicConfig(!editBasicConfig)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditIndex;

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import {
  labelClasses,
} from "../../../styles/AutocompleteListStyles";
import { useNavigate, useParams } from "react-router-dom";
import {
  InstitutionDetails,
  Operation,
  SortBy,
  StdEnqQueryType,
  YesNo,
} from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { msgType, responseType } from "../../../utils/Form.types";
import { useContext, useEffect, useRef, useState } from "react";

import useDropdownData from "../../../customhooks/useDropdownData";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import Input from "../../../stories/Input/Input";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Keys } from "../../../utils/Enum.keys";
import { Button } from "../../../stories/Button/Button";
import MessageModal from "../../../pages/MessageModal";
import useStudentEnquiries from "../hooks/useStudentEnquires";
import { GetStudentsEnquiryEdges, YesNoOptionsType } from "../StudentEnquiry";
import useEmployee, { empQueryTypes } from "../../HR/hooks/useEmployee";
import { TableHeaderProps } from "../../../Types/Tables";
import useInstitutionDetailsDataByInstId from "../../../customhooks/useInstitutionDetailsDataByInstId";
import { useMutation } from "@apollo/client";
import { AddStudentEnquiryEmpAssoci } from "../../../queries/students/mutations/update";
import useActiveAcademicYear from "../../Academics/hooks/useActiveAcademicYear";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useMasterTableJson from "../../../json/useMasterTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import ViewAllocated from "./View";
import { LabeledAutocomplete } from "../../../styles/AutocompleteListStyles";
import { SectionAllocationDrawer } from "../../../styles/DrawerStyles";

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}
interface Props {
  queryType: StdEnqQueryType;
  startDate: string;
  endDate: string;
}
const EnquiryAllocation = ({ queryType, startDate, endDate }: Props) => {

  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { Masters_Table } = useMasterTableJson();
  const { branchLabel, classLabel } = useInstLabels();
  const [viewModal, setViewModal] = useState(false);

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {Masters_Table.StudentsEnquiry.Table_Headers.filter(
            (data: TableHeaderProps) => data.labelName !== "Actions"
          ).map((label: TableHeaderProps, index: React.Key) => {
            return <TableCell key={index}>{label.labelName}</TableCell>;
          })}
        </TableRow>
      </TableHead>
    );
  };
  const EnhancedTableHeadForDrawer = () => {
    return (
      <TableHead>
        <TableRow>
          {Masters_Table.StudentsEnquiry.Table_Headers.filter(
            (data: TableHeaderProps) => data.labelName !== "Actions"
          ).map((label: TableHeaderProps, index: React.Key) => {
            return <TableCell key={index}>{label.labelName}</TableCell>;
          })}
        </TableRow>
      </TableHead>
    );
  };
  //useStates
  const { InstId } = useParams();

  const { token } = useToken();
  const [allocateEmp, setAllocateEmp] = useState(false);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);

  const [empSelected, setEmpSelected] = useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  // const [allocatedHasNextPage, setAllocatedHasNextPage] =
  //   useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [allocateEndCursor, setAllocateEndCursor] = useState<string | null>(
    null
  );
  const [rowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [applicationBoughtSelected] = useState<YesNoOptionsType | null>(null);
  const [admittedSelected] = useState<YesNoOptionsType | null>(null);
  const [searchData, setSearchData] = useState("");
  const [students, setStudents] = useState<GetStudentsEnquiryEdges[]>([]);
  const [allocatedStudentsList, setAllocatedStudentsList] = useState<
    GetStudentsEnquiryEdges[]
  >([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //useRefs
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;

  //useMutations
  const { user_details } = useLoggedInUserDetails();
  const { branchDropDown } = useInstitutionDetailsDataByInstId(
    InstitutionDetails.BRANCH
  );

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { classDropDown } = useDropdownData(
    0,
    branchSelected ? branchSelected?.value : 0,
    classSelected ? classSelected.value : 0,
    0
  );
  const {
    empDetails: { data },
  } = useEmployee(
    100,
    empQueryTypes.JUST_LOGIN_ACCTS,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0
  );
  const employeeDropDown: responseType[] = data
    ? data.GetPayRollEmp.edges.map((res) => ({
        label:
          res.node.emp_first_name +
          " " +
          res.node.emp_middle_name +
          " " +
          res.node.emp_last_name,
        value: res.node.id,
      }))
    : [];
  //left table
  const {
    EnquiryData: { StudentEnquiryData },
  } = useStudentEnquiries(
    rowsPerPage,
    queryType,
    searchData,
    EMPTY_STRING,
    applicationBoughtSelected,
    admittedSelected,
    SortBy.ENQ_DATE,
    endDate,
    startDate,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    0
  );
  const [AllocateStudents] = useMutation(AddStudentEnquiryEmpAssoci);
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAllocateEmp(!allocateEmp);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    departmentInputRef?.focus();
  };

  const HandleClear = () => {
    setBranchSelected(null);
    setClassSelected(null);
    setEmpSelected(null);
    setSearchData("");
    // setStudents([]);
    departmentInputRef?.focus();
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = students?.map((edge) => ({
      ...edge,
      node: {
        ...edge.node,
        isChecked: event.target.checked,
      },
    }));
    setStudents(newSelecteds);
  };
  const handleClick = (
    data: GetStudentsEnquiryEdges,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const mappedDataLeft = students?.map((row) => {
      if (row.node.id === data.node.id) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: (e.target as HTMLInputElement).checked ? true : false,
          },
        };
      } else {
        return row;
      }
    });

    setStudents(mappedDataLeft);
  };

  const handleSubmit = () => {
    AllocateStudents({
      variables: {
        token,
        user_details,
        enq_student_ids: students
          .filter((student) => student.node.isChecked)
          ?.map(({ node }) => node.id),
        inst_id: InstId!,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        acd_yr_id: activeAcademicYearData?.data?.GetAcdYrActiveByInstId.id,
        pr_emp_id: empSelected?.value,
      },
      //   refetchQueries: [
      //     {
      //       query: GetStudents,
      //       variables: {
      //         admNo: EMPTY_STRING,
      //         after: null,
      //         fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id!,
      //         first: ROWS_PER_PAGE,
      //         name: EMPTY_STRING,
      //         token,
      //         direction: Direction.ASC,
      //         sortBy: SortBy.FIRST_NAME,
      //         input: {
      //           ids: [semesterSelected ? semesterSelected.value : 0],
      //           std_query_type:
      //             studentQueryTypes.SECTION_NOT_ALLOC_BY_SEMESTER_ID,
      //         },
      //       },
      //     },
      //     {
      //       query: GetStudents,
      //       variables: {
      //         admNo: EMPTY_STRING,
      //         after: null,
      //         fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id!,
      //         first: ROWS_PER_PAGE,
      //         name: EMPTY_STRING,
      //         token,
      //         direction: Direction.ASC,
      //         sortBy: SortBy.FIRST_NAME,
      //         input: {
      //           ids: [InstId],
      //           std_query_type: studentQueryTypes.SECTION_NOT_ALLOC_BY_INST_ID,
      //         },
      //       },
      //     },
      //   ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Students Allocated to Employee Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        HandleClear();
      }
    });
  };
  const handleClear = () => {
    setBranchSelected(null);
    setClassSelected(null);
    // setStudents([]);
    setSearchData("");
  };

  const clearRestInstDetails = (type: InstitutionDetails) => {
    switch (type) {
      case InstitutionDetails.DEPARTMENT:
        setBranchSelected(null);
        setClassSelected(null);
        break;
      case InstitutionDetails.BRANCH:
        setClassSelected(null);
        break;
      case InstitutionDetails.CLASS:
        break;
    }
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !StudentEnquiryData.loading) {
        StudentEnquiryData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudentEnquires.edges;
            const pageInfo = fetchMoreResult.GetStudentEnquires.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudentEnquires.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudentEnquires: {
                edges: [...students!, ...newEdges],
                pageInfo,
                totalCount:
                  StudentEnquiryData.data?.GetStudentEnquires.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  

  useEffect(() => {
    if (StudentEnquiryData.data && !StudentEnquiryData.loading && token) {
      const newData = StudentEnquiryData.data.GetStudentEnquires.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: false, // set default value of isChecked to true
          },
        })
      );

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(
        StudentEnquiryData.data.GetStudentEnquires.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [StudentEnquiryData.data, StudentEnquiryData.loading, token]);

  useEffect(() => {
    if (StudentEnquiryData.data && !StudentEnquiryData.loading && token) {
      const newData = StudentEnquiryData.data.GetStudentEnquires.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: edge.node.isChecked ? true : false, // set default value of isChecked to true
          },
        })
      );

      if (allocateEndCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = allocatedStudentsList.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: true,
              },
            };
          }
          return newStudent;
        });
        setAllocatedStudentsList(updatedNewData);
      } else {
        setAllocatedStudentsList(newData);
      }
      setAllocateEndCursor(
        StudentEnquiryData.data.GetStudentEnquires.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [StudentEnquiryData.data, StudentEnquiryData.loading, token]);

  

  return (
    <>
      <div className="enquiry-allocation">
        <form className="row g-0 enquiry-allocation__filters">
          <div className="col-1 ">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && branchSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.BRANCH);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={branchLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                } else {
                  setClassSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.CLASS);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={classLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        </form>
        <div className="enquiry-allocation__tableblock">
          {!students?.length ? (
            <b className="nodata">Sorry, No Students Found</b>
          ) : (
            <>
              <TableContainer
                className="enquiry-allocation__table"
                onScroll={handleScroll}
              >
                <Table stickyHeader>
                  <EnhancedTableHead
                    numSelected={
                      students?.filter((data) => data.node.isChecked).length
                    }
                    onSelectAllClick={(event) => handleSelectAllClick(event)}
                    rowCount={students?.length}
                  />
                  <TableBody>
                    {students?.map((response, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={students[index]?.node.isChecked}
                          tabIndex={-1}
                          key={response.node.id}
                          selected={students[index]?.node.isChecked}
                        >
                          <TableCell padding="checkbox" align="center">
                            <Checkbox
                              checked={students[index]?.node.isChecked ?? false}
                              onClick={(event) => handleClick(response, event)}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="student-enquiry__frame--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="enquiry-allocation__move-to--table--inst">
                            {toStandardDate(response.node.enq_date)}
                          </TableCell>
                          <TableCell>
                            {`${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`}
                          </TableCell>

                          <TableCell className="student-enquiry__frame--table--inst">
                            {response.node.std_previous_school}
                          </TableCell>

                          <TableCell className="student-enquiry__frame--table--desc">
                            {response.node.branch_details.branch_desc
                              ? response.node.branch_details.branch_desc
                              : "-"}
                          </TableCell>
                          <TableCell className="student-enquiry__frame--table--desc">
                            {response.node.class_details.class_desc
                              ? response.node.class_details.class_desc
                              : "-"}
                          </TableCell>

                          <TableCell className="student-enquiry__frame--table--mobile">
                            {response.node.std_mobile}
                          </TableCell>
                          <TableCell className="student-enquiry__frame--table--email">
                            {response.node.std_email}
                          </TableCell>
                          <TableCell
                            className="student-enquiry__frame--table--status"
                            id="td-center"
                          >
                            {response.node.admitted ? YesNo.YES : YesNo.NO}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="allocate"
              onClick={() => setAllocateEmp(!allocateEmp)}
              disabled={
                !(students?.filter((data) => data.node.isChecked).length > 0)
              }
            />
            <Button mode="clear" onClick={handleClear} />
            <Button mode="view" onClick={() => setViewModal(!viewModal)}>
              &nbsp;Allocated
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 enquiry-allocation__total">
            <StudentTotalCount totalCount={students.length!} />
          </div>
        </div>
      </div>

      <SectionAllocationDrawer
    
        anchor="right"
        open={allocateEmp}
        onClose={() => setAllocateEmp(!allocateEmp)}
      >
        <div className="enquiry-allocation__move-to">
          <div className="row g-0 ">
            <div className="col-3 ">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={employeeDropDown}
                value={empSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setEmpSelected(newValue as responseType);
                  } else {
                    setEmpSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    label="Select Employee"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          </div>

          <div className="enquiry-allocation__move-to--tableblock">
            <TableContainer className="enquiry-allocation__move-to--table">
              <Table stickyHeader>
                <EnhancedTableHeadForDrawer />
                <TableBody>
                  {students
                    .filter((student) => student.node.isChecked)
                    .map((response, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={students[index]?.node.isChecked}
                          tabIndex={-1}
                          key={response.node.id}
                          selected={students[index]?.node.isChecked}
                        >
                          <TableCell
                            id="td-center"
                            className="enquiry-allocation__move-to--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            {`${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`}
                          </TableCell>
                          <TableCell className="enquiry-allocation__move-to--table--inst">
                            {toStandardDate(response.node.enq_date)}
                          </TableCell>
                          <TableCell className="enquiry-allocation__move-to--table--inst">
                            {response.node.std_previous_school}
                          </TableCell>

                          <TableCell className="enquiry-allocation__move-to--table--desc">
                            {response.node.branch_details.branch_desc
                              ? response.node.branch_details.branch_desc
                              : "-"}
                          </TableCell>
                          <TableCell className="enquiry-allocation__move-to--table--desc">
                            {response.node.class_details.class_desc
                              ? response.node.class_details.class_desc
                              : "-"}
                          </TableCell>

                          <TableCell className="enquiry-allocation__move-to--table--mobile">
                            {response.node.std_mobile}
                          </TableCell>
                          <TableCell className="enquiry-allocation__move-to--table--email">
                            {response.node.std_email}
                          </TableCell>
                          <TableCell
                            className="enquiry-allocation__move-to--table--status"
                            id="td-center"
                          >
                            {response.node.admitted ? YesNo.YES : YesNo.NO}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="row g-0">
            <div className="col">
              <Button mode="save" onClick={handleSubmit} />
              <Button
                mode="cancel"
                onClick={() => {
                  HandleClear();
                  setAllocateEmp(!allocateEmp);
                }}
              />
            </div>

            <div className="student-total-count col">
              {`Total Selected Students : ${
                students?.filter((student) => student.node.isChecked).length
              }`}
            </div>
          </div>

          <MessageModal
            modalFlag={message.flag!}
            value={message.message!}
            handleClose={handleClose}
            operation={message.operation!}
          />
          {/* <LoadingModal flag={updationLoading} /> */}
        </div>
      </SectionAllocationDrawer>
      <Modal isOpen={viewModal} ariaHideApp={false} style={StudentModalStyles}>
        <ViewAllocated
          setModalFlag={setViewModal}
          startDate={startDate}
          endDate={endDate}
        />
      </Modal>
    </>
  );
};

export default EnquiryAllocation;

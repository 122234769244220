import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Home from "../Home/Index";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { Form, Formik } from "formik";
import {
  getModifiedScrollHeight,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import Input from "../../../stories/Input/Input";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { msgType } from "../../../utils/Form.types";
import LoadingModal from "../../../pages/LoadingModal";
import DeleteModal from "../../../pages/DeleteModal";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import {
  ActivityEdge,
  ActivityNode,
  GetAcctMiscActivitiesData,
  GetAcctMiscActivitiesVars,
} from "../../../Types/Library/MasterData/Media/paginationTypes";
import { ByNode as MediaById } from "../../../queries/Library/Node";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../utils/Enum.types";
import InputHoc from "../../../components/common/Input/Index";

import MessageModal from "../../../pages/MessageModal";
//Queries and Mutations
import { AddAcctMiscActivities } from "../../../queries/Library/MasterData/Media/mutations/new";
import { UpdateAcctMiscActivities } from "../../../queries/Library/MasterData/Media/mutations/update";
import { DeleteAcctMiscActivities } from "../../../queries/Library/MasterData/Media/mutations/delete";
import { MiscActivitiesValidation } from "../../../utils/validationRules";
import { GetAcctMiscActivities } from "../../../queries/Library/MasterData/Media/list/byInstId";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryformJson from "../../Library/json/useLibraryformJson";
import { LibraryLabelNameProps } from "../../../Types/Labels";

interface Props {
  type: string;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
export interface MediaByIdData {
  nodes: ActivityNode[];
}

interface MiscType {
  activity_name: string;
  [key: string]: string;
}
export interface MediaByIdVars {
  token: string;
  ids: number[] | number;
}
const MiscActivities = ({ type, modalFlag, setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy] = useState(SortBy.ACTIVITY_NAME);
  const [direction] = useState(Direction.ASC);
  const [searchData, setSearchData] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const { LibraryFormLabels } = useLibraryformJson();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [mediaId, setMediaId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [activityList, setActivityList] = useState<ActivityEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [formData, setFormData] = useState<MiscType>({
    activity_name: "",
  });
  //Queries
  const [GetMiscData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcctMiscActivitiesData,
    GetAcctMiscActivitiesVars
  >(GetAcctMiscActivities, {
    variables: {
      token,
      inst_id: InstId!,
      first: null,
      sortBy,
      direction,
      activityName: searchData,
      after: null,
    },
  });

  const [GetMiscDataById, { data: MediaByIdData, loading: MediaByIdLoading }] =
    useLazyQuery<MediaByIdData, MediaByIdVars>(MediaById);
  //Mutations
  const [AddMiscData, { loading: creationLoading }] = useMutation(
    AddAcctMiscActivities,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateActivity, { loading: updationLoading }] = useMutation(
    UpdateAcctMiscActivities,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteActivity, { loading: deleteLoading }] = useMutation(
    DeleteAcctMiscActivities,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctMiscActivities.edges;
            const pageInfo = fetchMoreResult.GetAcctMiscActivities.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetAcctMiscActivities.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctMiscActivities: {
                edges: [...activityList, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctMiscActivities.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  //Methods
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      activity_name: e.target.value.toString(),
    }));
  };
  const HandleSubmit = () => {
    const input = {
      activity_name: removeMoreSpace(formData.activity_name),
    };
    if (operation === Operation.CREATE) {
      AddMiscData({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetAcctMiscActivities,
            variables: {
              token,
              inst_id: InstId!,
              first: null,
              sortBy,
              direction,
              activityName: searchData,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (type === PageFor.MODAL) {
            setModalFlag?.(!modalFlag);
          }
          setMessage({
            flag: !message.flag,
            message: "Activity Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateActivity({
        variables: {
          token,
          id: mediaId,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetAcctMiscActivities,
            variables: {
              token,
              inst_id: InstId!,
              first: null,
              sortBy,
              direction,
              activityName: searchData,
              after: null,
            },
          },
          {
            query: MediaById,
            variables: {
              token,
              ids: mediaId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Activity Updated Successfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: number) => {
    DeleteActivity({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctMiscActivities,
          variables: {
            token,
            inst_id: InstId!,
            first: null,
            sortBy,
            direction,
            activityName: searchData,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Activity Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClear = () => {
    setFormData({
      activity_name: "",
    });
    setOperation(Operation.CREATE);
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (MediaByIdData && !MediaByIdLoading && operation === Operation.UPDATE) {
      const { activity_name } = MediaByIdData.nodes[0];
      setFormData({
        activity_name: activity_name,
      });
    }
  }, [MediaByIdData, MediaByIdLoading, operation]);
  useEffect(() => {
    if (token) {
      GetMiscData();
    }
  }, [token, GetMiscData]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.GetAcctMiscActivities.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = activityList.find(
            (media) => media.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setActivityList(updatedNewData);
      } else {
        setActivityList(newData);
      }
      setEndCursor(data?.GetAcctMiscActivities.pageInfo.endCursor!);
    } // eslint-disable-next-line
  }, [data, loading]);
  if (error) return <>{error.message}</>;
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Miscellaneous Activities</Title>

      <Formik
        initialValues={formData}
        validationSchema={MiscActivitiesValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                type === PageFor.MODAL
                  ? "library__frame--modal"
                  : "library__frame"
              }
            >
              <div className="row g-0 library__frame--row">
                <div className="col h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">Add Activity</Title>
                  </div>
                  <div className="library__frame--block">
                    {LibraryFormLabels.MiscData.InputType.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData[label.inputName]}
                              type={label.dataType}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="col h-100 booktype-right">
                  <div className="row g-0 library__frame--title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">List of Misc Activities</Title>
                    </div>
                    <div className="col-3">
                      <Input
                        id="search"
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchData(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="library__frame--block">
                    {!activityList?.length ? (
                      <b className="nodata">Sorry, no results.</b>
                    ) : (
                      <TableContainer
                        className="library__table"
                        onScroll={handleScroll}
                      >
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sl</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {activityList &&
                              activityList.map((response, index: number) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      className="library__table--slno"
                                      id="td-center"
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell>
                                      {response.node.activity_name}
                                    </TableCell>

                                    <TableCell
                                      id="td-center"
                                      className="library__table--actions"
                                    >
                                      <img
                                        src={EditProfile}
                                        alt="/"
                                        onClick={() => {
                                          GetMiscDataById({
                                            variables: {
                                              token,
                                              ids: response.node.id,
                                            },
                                          }).then(({ data }) => {
                                            //enableSave should always stay true if edit is clicked
                                            if (data) {
                                              setMediaId(response.node.id);
                                              setOperation(Operation.UPDATE);
                                            }
                                          });
                                        }}
                                      />
                                      &nbsp;
                                      <img
                                        src={Delete}
                                        alt="/"
                                        onClick={() => {
                                          setMediaId(response.node.id);
                                          setOperation(Operation.DELETE);
                                          setDeleteModal(!deleteModal);
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                <Button mode="save" type="submit" />
                <Button mode="clear" type="button" onClick={handleClear} />
                {type === PageFor.GENERAL ? (
                  operation === Operation.CREATE ? (
                    <>
                      <Button
                        mode="back"
                        type="button"
                        onClick={() => navigate(-1)}
                      />
                    </>
                  ) : (
                    <Button
                      mode="back"
                      type="button"
                      onClick={() => setOperation(Operation.CREATE)}
                    />
                  )
                ) : (
                  <Button
                    mode="cancel"
                    type="button"
                    onClick={() => setModalFlag?.(false)}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>

      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deleteLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={mediaId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default MiscActivities;

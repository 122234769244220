import React, { useState, Key, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import InputHoc from "../../../components/common/Input/Index";

import { useParams } from "react-router-dom";
import Close from "../../../images/Close.svg";

import useToken from "../../../customhooks/useToken";
import { LoginUser } from "../../HR/Types/formTypes";
import {
  AddPayRollJustLoginAcct,
  UpdatePayRollJustLoginAcct,
} from "../../HR/queries/loginuser/mutation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InstitutionCustomStyles } from "../../../styles/ModalStyles";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { loginUserValidation } from "../../HR/utils/validationRules";
import { LabelNameProps } from "../../../Types/Labels";
import LoadingModal from "../../../pages/LoadingModal";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { msgType, responseType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import {
  GetPayRollEmp,
  LoginEmpMasterNode,
} from "../../HR/queries/employee/query";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import useEmployee, {
  EmpData,
  empQueryTypes,
  PrEmpQueryType,
} from "../../HR/hooks/useEmployee";
import { ModalType } from "./Index";
import { CommonNodeVars } from "../../HR/Types/masterDataTypes";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../utils/UtilFunctions";
import { TextField } from "@mui/material";
import { Label } from "../../../stories/Label/Label";
import useEmpMasterData from "../../HR/hooks/useEmpMasterData";
import { Keys } from "../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useCheckAllocationType from "../../Academics/hooks/useCheckAllocationType";
import { AccountentType } from "../../Accounts/common/Enum.types";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";

const { HRFormLabel } = require("../../HR/json/form.json");
interface Props {
  openModal: ModalType;
  setOpenModal: React.Dispatch<React.SetStateAction<ModalType>>;
}

const AddLoginUser = ({ openModal, setOpenModal }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const nameRef = document.getElementsByName(
    "emp_first_name"
  )[0] as HTMLInputElement;
  const deptRef = useRef<HTMLSelectElement>(null);
  const deptInputRef = deptRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const { user_details } = useLoggedInUserDetails();
  const { departmentDropDown, designationDropDown } = useEmpMasterData();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [pr_dept, setpr_dept] = useState<responseType | null>(null);

  const [pr_designation, setpr_designation] = useState<responseType | null>(
    null
  );
  const [reporting_manager_id, set_reporting_manager_id] =
    useState<responseType | null>(null);
  const [formData, setFormData] = useState<LoginUser>({
    emp_first_name: "",
    emp_mobile: "",
    emp_email: "",
    emp_type: "",
  });

  const { flag } = useCheckAllocationType();
  const { empDetails } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.REPORTING_MANAGER,
    "",
    0,
    pr_dept ? pr_dept.value : 0,
    pr_designation ? pr_designation.value : 0
  );
  const [GetLoginEmp, { data }] = useLazyQuery<EmpData, CommonNodeVars>(
    LoginEmpMasterNode,
    {
      variables: {
        id: openModal.id,
        token,
      },
    }
  );
  const userTypeOptions = Object.values(AccountentType);

  const [AddLoginUser, { loading: creationLoading }] = useMutation(
    AddPayRollJustLoginAcct,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const [UpdateLoginUser, { loading: updationLoading }] = useMutation(
    UpdatePayRollJustLoginAcct,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues: LoginUser) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      emp_mobile: mobileNo,
    }));
  };

  const HandleUserCreation = () => {
    if (openModal.operation === Operation.CREATE) {
      AddLoginUser({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input: {
            emp_first_name: formData.emp_first_name,
            emp_mobile: `+${formData.emp_mobile}`,
            emp_email: formData.emp_email ? formData.emp_email : EMPTY_STRING,
            pr_dept_id: pr_dept ? pr_dept.value : 0,
            pr_designation_id: pr_designation ? pr_designation.value : 0,
            reporting_to_emp_id: reporting_manager_id
              ? reporting_manager_id.value
              : 0,
            parent_designation_id: 0,
            emp_type: formData.emp_type,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.JUST_LOGIN_ACCTS,
                emp_type: EMPTY_STRING,
                entry_level: null,
                entry_id: null,
              },
              name: EMPTY_STRING,
              per_std_subj_allocation: flag,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Saved",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateLoginUser({
        variables: {
          token,
          pr_emp_id: openModal.id,
          inst_id: InstId,
          user_details,

          input: {
            ...formData,
            emp_mobile: `+${formData.emp_mobile}`,
            pr_dept_id: pr_dept ? pr_dept.value : 0,
            pr_designation_id: pr_designation ? pr_designation.value : 0,
            reporting_to_emp_id: reporting_manager_id
              ? reporting_manager_id.value
              : 0,
            parent_designation_id: 0,
            emp_type: formData.emp_type,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              per_std_subj_allocation: flag,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.JUST_LOGIN_ACCTS,
              },
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
            fetchPolicy: "network-only",
          },
          {
            query: LoginEmpMasterNode,
            variables: {
              id: openModal.id,
              token,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Saved",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClear = () => {
    setFormData({
      emp_first_name: "",
      emp_mobile: "",
      emp_email: "",
    });
    nameRef?.focus();
    setpr_dept(null);
    setpr_designation(null);
    set_reporting_manager_id(null);
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
      setOpenModal({
        flag: false,
        id: 0,
        operation: Operation.NONE,
      });
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (openModal.id && openModal.operation === Operation.UPDATE && token) {
      GetLoginEmp().then(({ data }) => {
        if (data) {
          const { dept_desc, id: deptId } = data.node.pr_dept_details;
          const { designation_desc, id: desgId } =
            data.node.pr_designation_details;
          const {
            emp_first_name,
            emp_last_name,
            emp_middle_name,
            id: empId,
          } = data.node.reporting_manager_details;
          const empMobileWithoutPlus = data.node.emp_mobile.replaceAll("+", "");
          setFormData({
            emp_email: data.node.emp_email,
            emp_first_name: data.node.emp_first_name,
            emp_mobile: empMobileWithoutPlus,
            emp_type: data.node.emp_type,
          });
          setpr_dept({ label: dept_desc, value: deptId });
          setpr_designation({ label: designation_desc, value: desgId });
          set_reporting_manager_id({
            label: emp_first_name + emp_middle_name + emp_last_name,
            value: empId,
          });
        }
      });
    }
  }, [openModal, data, GetLoginEmp, token]);

  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={openModal.flag}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {openModal.operation === Operation.CREATE
                ? "Add Login User"
                : "Update Login User"}
            </Title>
            <Formik
              initialValues={formData}
              validationSchema={loginUserValidation}
              onSubmit={HandleUserCreation}
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    <div className="row">
                      {HRFormLabel.AddLoginUsers?.map(
                        (label: LabelNameProps, index: Key) => {
                          return (
                            <React.Fragment key={index}>
                              <InputHoc
                                name={label.inputName}
                                LabelName={label.LabelName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                autoFocus={label.autoFocus}
                                values={formData[label.inputName]}
                                type={label.dataType}
                                required={label.required}
                                maxLength={label.maxLength}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                      <div className="label-grid">
                        <Label>Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.emp_mobile}
                          onChange={handleNumberChange}
                          inputProps={{
                            required: true,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              deptInputRef?.focus();
                            }
                          }}
                        />
                      </div>
                      <div className="employee-details__block--frame--select-grid">
                        <Label>User Type</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          // ref={accountantRef}
                          options={userTypeOptions}
                          value={formData.emp_type}
                          onChange={(e, value) => {
                            if (value) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  emp_type: value as AccountentType,
                                };
                              });
                            } else {
                              setFormData((prevValues) => ({
                                ...prevValues,
                                emp_type: "",
                              }));
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setFormData((prevValues) => ({
                                ...prevValues,
                                emp_type: "",
                              }));
                            }
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (formData.emp_type) {
                                handleMUISelectEvent(e);
                              }
                            }
                          }}
                          openOnFocus
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      </div>
                      <div className="employee-details__block--frame--select-grid">
                        <Label>Department</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          ref={deptRef}
                          options={departmentDropDown}
                          value={pr_dept}
                          isOptionEqualToValue={(option) =>
                            isOptionEqualToValue(
                              option as responseType,
                              pr_dept
                            )
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setpr_dept(newValue as responseType);
                            } else {
                              setpr_dept(null);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setpr_dept(null);
                            }
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (pr_dept) {
                                handleMUISelectEvent(e);
                              }
                            }
                          }}
                          openOnFocus
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      </div>
                      <div className="employee-details__block--frame--select-grid">
                        <Label>Designation</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={designationDropDown}
                          value={pr_designation}
                          isOptionEqualToValue={(option) =>
                            isOptionEqualToValue(
                              option as responseType,
                              pr_designation
                            )
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setpr_designation(newValue as responseType);
                            } else setpr_designation(null);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setpr_designation(null);
                            }
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();

                              if (pr_designation) {
                                handleMUISelectEvent(e);
                              }
                            }
                          }}
                          openOnFocus
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      </div>
                      <div className="employee-details__block--frame--select-grid">
                        <Label>Reporting Manager</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={empDetails.responseType}
                          value={reporting_manager_id}
                          isOptionEqualToValue={(option) =>
                            isOptionEqualToValue(
                              option as responseType,
                              reporting_manager_id
                            )
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              set_reporting_manager_id(
                                newValue as responseType
                              );
                            } else set_reporting_manager_id(null);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              set_reporting_manager_id(null);
                            }
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();

                              if (reporting_manager_id) {
                                handleMUISelectEvent(e);
                              }
                            }
                          }}
                          openOnFocus
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <Button mode="save" type="submit" />
                    <Button mode="clear" type="button" onClick={handleClear} />
                    <Button
                      mode="cancel"
                      type="button"
                      onClick={() => {
                        handleClear();
                        setOpenModal({
                          operation: Operation.NONE,
                          flag: false,
                          id: 0,
                        });
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setOpenModal({
                  operation: Operation.NONE,
                  flag: false,
                  id: 0,
                });
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default AddLoginUser;

import { gql } from "@apollo/client";

export const AddCampusDetails = gql`
  mutation AddCampus(
    $token: String!
    $input: CreateMstCampusInput!
    $inst_ids: [ID!]!
    $user_details: SubscribedUser!
  ) {
    AddCampus(
      token: $token
      input: $input
      inst_ids: $inst_ids
      user_details: $user_details
    ) {
      id
      campus_name
      assoicated_insts {
        inst_id
        inst_details {
          inst_name
        }
      }
    }
  }
`;
export const UpdateCampusDetails = gql`
  mutation UpdateCampus(
    $token: String!
    $campus_id: ID!
    $campus_name: String!
    $inst_ids: [ID!]!
    $user_details: SubscribedUser!
  ) {
    UpdateCampus(
      token: $token
      campus_id: $campus_id
      campus_name: $campus_name
      inst_ids: $inst_ids
      user_details: $user_details
    )
  }
`;
export const DeleteCampusDetails = gql`
  mutation DeleteCampus(
    $token: String!
    $campus_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteCampus(
      token: $token
      campus_id: $campus_id
      user_details: $user_details
    )
  }
`;
export const GetCampus = gql`
  query GetCampus($token: String!, $customer_id: ID!) {
    GetCampus(token: $token, customer_id: $customer_id) {
      id
      campus_name
      customer_id
      customer_details {
        cust_name
      }
      assoicated_insts {
        is_primary_inst
        inst_details {
          inst_name
          inst_short_name
          inst_code
          inst_address
          inst_place
          inst_state
          inst_pin
          inst_contact_person
        }
      }
    }
  }
`;
export const UpdateCampusPrimaryInst = gql`
  mutation UpdateCampusPrimaryInst(
    $token: String!
    $campus_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateCampusPrimaryInst(
      token: $token
      campus_id: $campus_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const GetCampusById = gql`
  query nodequery($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstCampus {
        id
        campus_name
        customer_id
        customer_details {
          cust_name
        }
        assoicated_insts {
          inst_id
          is_primary_inst
          inst_details {
            inst_name
            inst_short_name
          }
        }
      }
    }
  }
`;
export const GetCampusPrimaryInst = gql`
  query GetCampusPrimaryInst($token: String!, $inst_id: ID!) {
    GetCampusPrimaryInst(token: $token, inst_id: $inst_id) {
      campus_id
      primary_inst_id
    }
  }
`;

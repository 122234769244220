import { gql } from "@apollo/client";
export const GetInstDocuments = gql`
  query GetInstDocuments($token: String!, $inst_id: ID! ,$custom_doc_name: String!) {
    GetInstDocuments(token: $token, inst_id: $inst_id,custom_doc_name:$custom_doc_name) {
      id
      document_name
      document_content
     custom_doc_name
      inst_id
    }
  }
`;
export const GetDocumentConfig = gql`
  query GetDocumentConfig(
    $token: String!
    $inst_id: ID!
    $document_main_id: ID!
  ) {
    GetDocumentConfig(
      token: $token
      inst_id: $inst_id
      document_main_id: $document_main_id
    ) {
      id
      config_key
      config_form_label_caption
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_secondary_list_caption
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      inst_id
      document_main_id
    }
  }
`;
export const GetInstDocumentContent = gql`
  query GetInstDocumentContent(
    $token: String!
    $inst_id: ID!
    $document_id: ID!
  ) {
    GetInstDocumentContent(
      token: $token
      inst_id: $inst_id
      document_id: $document_id
    )
  }
`;

export const MstInstDocumentMainByNodeId = gql`
  query MstInstDocumentMainByNodeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstInstDocumentMain {
        id
        document_name
      }
    }
  }
`;

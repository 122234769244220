import * as Yup from "yup";
import {
  Adhar_Card_Regex,
  AlphaNumeric_Regex,
  DrivingLicense_Regex,
  IfscRegex,
  Number_Regex,
  OnlyAlphabets,
  PAN_Regex,
  Passport_Regex,
  TelNos_Regex,
  VoterId_Regex,
} from "./validationRules";

export const payroleCategorySchema = Yup.object({
  category_desc: Yup.string().required("Category Description is  required"),
});
export const payroleJobTypeSchema = Yup.object({
  job_type_desc: Yup.string().required("JobType Description is  required"),
});
export const payroleDeptSchema = Yup.object({
  dept_desc: Yup.string().required("Department Description is  required"),
});
export const payroleDesignationSchema = Yup.object({
  designation_desc: Yup.string().required(
    "Designation Description is  required"
  ),
});
export const payroleGradeSchema = Yup.object({
  grade_desc: Yup.string().required("Grade Description is required"),
});

export const EmpBasicDetailsSchema = Yup.object({
  emp_id: Yup.string().required("Employee Id is required"),
  emp_first_name: Yup.string().required("Employee Name is required"),
  emp_middle_name: Yup.string().matches(OnlyAlphabets),
  emp_last_name: Yup.string().matches(OnlyAlphabets),
  emp_dob: Yup.string().required("Employee Dob is required"),

  // pr_grade_id: Yup.string().required("Grade Description is required"),
  // pr_dept_id: Yup.string().required("Grade Description is required"),
  // pr_category_id: Yup.string().required("Grade Description is required"),
  // pr_designation_id: Yup.string().required("Grade Description is required"),
});
export const EmpOtherDetailsSchema = Yup.object({
  emp_aadhar: Yup.string().matches(Adhar_Card_Regex, "Invalid Adhaar"),
  emp_bank_ifsc: Yup.string().matches(IfscRegex, "Invalid IFSC"),
  emp_voter_id: Yup.string()
    .max(10, "Invalid VoterId")
    .matches(VoterId_Regex, "Invalid Voter Id"),
  emp_pan: Yup.string()
    .max(11, "Invalid Pan")
    .matches(PAN_Regex, "Pan No is Invalid"),
  emp_drv_lic: Yup.string()
    .max(15, "Invalid Driving License")
    .matches(DrivingLicense_Regex, "Invalid Driving License"),
  emp_passport: Yup.string()
    .max(9, "Invalid Passport")
    .matches(Passport_Regex, "Passport number is Invalid"),
  emp_epf_no: Yup.string()
    .max(14, "Invalid PF No")
    .matches(AlphaNumeric_Regex, "Invalid PFNo Regex"),
  emp_uan_no: Yup.string()
    .max(12, "Invalid UAN")
    .matches(Number_Regex, "Invalid UAN"),
  emp_bank_acct_no: Yup.string().max(21, "Invalid Bank Account Number"),
  emp_esi_no: Yup.string().max(11, "Invalid ESI No").matches(Number_Regex),
});

export const holidayFormValidation = Yup.object({
  holiday_desc: Yup.string().required("Holiday Description required"),
  holiday_type: Yup.string().required("Holiday type is required"),
  fullDayHalfDay: Yup.string().required("FullDay or HalfDay required"),
});
export const eduateHolidayValidation = Yup.object({
  holiday_desc: Yup.string().required("Holiday Description required"),
});

export const EmpPersonalDetailsSchema = Yup.object({
  emp_father_name: Yup.string(),
  emp_tel: Yup.string().matches(TelNos_Regex),
  emp_corr_pin: Yup.string().max(6, "Invalid regex").matches(Number_Regex),
  emp_perm_pin: Yup.string().max(6, "Invalid regex").matches(Number_Regex),
  emp_esi_dispen: Yup.string().matches(OnlyAlphabets, "Invalid Dispencery"),
});

import { gql } from "@apollo/client";
export const AddAcctGroupLdgr = gql`
  mutation AddAcctGroupLdgr(
    $token: String!
    $input: CreateAcctGroupLdgrInput!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    AddAcctGroupLdgr(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

export const UpdateAcctGroupLdgrById = gql`
  mutation UpdateAcctGroupLdgrById(
    $token: String!
    $input: UpdateAcctGroupLdgrInput!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateAcctGroupLdgrById(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
      inst_id: $inst_id
    )
  }
`;
export const DeleteAcctGroupLdgrById = gql`
  mutation DeleteAcctGroupLdgrById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteAcctGroupLdgrById(
      token: $token
      id: $id
      user_details: $user_details
      inst_id: $inst_id
    )
  }
`;

export const ReOrderAcctGroupLdgrRcptIndex = gql`
  mutation ReOrderAcctGroupLdgrRcptIndex(
    $token: String!
    $input: [ReOrderRcptIndexInput!]!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    ReOrderAcctGroupLdgrRcptIndex(
      token: $token
      input: $input
      user_details: $user_details
      inst_id: $inst_id
    )
  }
`;

export const ReOrderAcctGroupLdgrPymtIndex = gql`
  mutation ReOrderAcctGroupLdgrPymtIndex(
    $token: String!
    $input: [ReOrderPymtIndexInput!]!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    ReOrderAcctGroupLdgrPymtIndex(
      token: $token
      input: $input
      user_details: $user_details
      inst_id: $inst_id
    )
  }
`;

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";

import { useParams } from "react-router-dom";
import useTransportRoutesData from "../../../../customhooks/general/useTransportRoutesData";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";

import { TableHeaderProps } from "../../../../Types/Tables";
import { AssignRouteToStudentTitleProps } from "../../../../Types/Titles";
import { EMPTY_RESPONSETYPE_OBJECT } from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import { Operation } from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import { reOrderProcess } from "../../../../utils/UtilFunctions";
import { RouteDetailsNodes } from "../../paginationTypes";
import { RouteDetailsByData, RouteDetailsByVars } from "../../paginationTypes";
import { GetTransportRouteDetailsByMasterId } from "../../queries/list";
import { UpdateTransportRouteDetails } from "../../queries/mutations";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useTransportTableJson from "../../json/useTransportTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { Transport_Title } = require("../../json/title.json");

const Reorder = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { Transport_Table } = useTransportTableJson();
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [searchRoutes, setSearchRoutes] = useState("");
  const [localItems, setLocalItems] = useState<RouteDetailsNodes[]>([]);
  const [item, setitems] = useState<RouteDetailsNodes[]>([]);
  const [route_master_id, setRoute_master_id] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const { routesData } = useTransportRoutesData(searchRoutes, false);

  const [GetRouteDetailsById, { data }] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id: route_master_id.value,
    },
    fetchPolicy: "network-only",
  });
  const [UpdateRouteDetails, { loading: updationLoading }] = useMutation(
    UpdateTransportRouteDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: RouteDetailsNodes[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };

  const HandleSaveDragAndDrop = () => {
    // eslint-disable-next-line
    item?.map((element, index: number) => {
      if (localItems[index].route_stage_desc === element.route_stage_desc) {
        UpdateRouteDetails({
          variables: {
            token,
            id: item[index].id,
            inst_id: InstId,
            user_details,
            input: {
              route_stage_sl: index + 1,
            },
          },
          refetchQueries: [
            {
              query: GetTransportRouteDetailsByMasterId,
              variables: {
                inst_id: InstId!,
                route_master_id: route_master_id.value,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Route Stages Re-Ordered Successfully",
              flag: true,
              operation: Operation.UPDATE,
            });
          }
        });
      }
    });
  };
  useEffect(() => {
    if (route_master_id.value) {
      GetRouteDetailsById({
        variables: {
          token,
          inst_id: InstId!,
          route_master_id: route_master_id.value,
        },
      });
    }
  }, [GetRouteDetailsById, InstId, route_master_id.value, token]);

  useEffect(() => {
    if (data && data.GetTransportRouteDetailsByMasterId) {
      setLocalItems(data.GetTransportRouteDetailsByMasterId);
    }
  }, [data?.GetTransportRouteDetailsByMasterId, data]);

  const handleClose = () => {
    setRoute_master_id(EMPTY_RESPONSETYPE_OBJECT);
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Title>
        {Transport_Title.MasterData.ReOrder.map(
          (title: AssignRouteToStudentTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Title}</React.Fragment>;
          }
        )}
      </Title>
      <div className="reorder-route-stages">
        <div className="row g-0">
          <div className="col-3">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={routesData.routesDropDown}
              openOnFocus
              value={route_master_id}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.BACKSPACE) {
                  setRoute_master_id(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setRoute_master_id(newValue as responseType);
                } else {
                  setRoute_master_id(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => setSearchRoutes(e.target.value)}
                  fullWidth
                  autoFocus
                  label="Select Bus Route"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className="reorder-route-stages__tableblock">
          {route_master_id.value ? (
            <TableContainer className="reorder-route-stages__tableblock--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {localItems?.length > 1 && <TableCell></TableCell>}

                    {Transport_Table.MasterData.ReOrder.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragAndDrop}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {localItems?.map((response, index: number) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <TableRow
                                  key={index}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  {localItems?.length > 1 && (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      id="td-center"
                                      className="reorder-route-stages__tableblock--table--slno"
                                    >
                                      =
                                    </TableCell>
                                  )}

                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="reorder-route-stages__tableblock--table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>

                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    key={index}
                                  >
                                    {response.route_stage_desc}
                                  </TableCell>
                                </TableRow>
                              );
                            }}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          ) : null}
        </div>
        {localItems?.length > 1 ? (
          <>
            <Button
              mode="save-order"
              onClick={() => {
                HandleSaveDragAndDrop();
              }}
            />

            <Button mode="clear" />
          </>
        ) : null}
      </div>

      <LoadingModal flag={updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default Reorder;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetMstInstReferenceDataByType } from "../queries/institution/list/byId";
import { EMPTY_OPTIONSTYPE_OBJECT, EMPTY_STRING } from "../utils/constants";
import { optionsType } from "../utils/Form.types";
import useToken from "./useToken";
export interface GetMstInstReferenceDataByTypeData {
  value1: string;
  value2: string;
  id: number;
  type: string;
}
export interface GetMstInstReferenceDataByTypeDetails {
  GetMstInstReferenceDataByType: GetMstInstReferenceDataByTypeData[];
}
export interface GetMstInstReferenceDataByTypeVars {
  token: string;
  inst_id: string;
  type: string;
}

export enum InstReferenceDataByType {
  AUTHORIZER = "AUTHORIZER",
  NAME_TITLE = "NAME_TITLE",
}

const useInstReferenceDataByType = (type: InstReferenceDataByType) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [optionsType, setOptionsType] = useState<optionsType[]>([
    EMPTY_OPTIONSTYPE_OBJECT,
  ]);
  const [GetData, { data, loading, error }] = useLazyQuery<
    GetMstInstReferenceDataByTypeDetails,
    GetMstInstReferenceDataByTypeVars
  >(GetMstInstReferenceDataByType);
  useEffect(() => {
    if (token !== EMPTY_STRING && type) {
      GetData({ variables: { inst_id: InstId!, token, type } }).then(
        ({ data }) => {
          if (data) {
            setOptionsType(
              data.GetMstInstReferenceDataByType.map((f) => ({
                label: f.value1,
                value: f.value2,
              }))
            );
          }
        }
      );
    }
  }, [token, type, GetData, InstId]);
  return { InstreferenceData: { data, loading, error, optionsType } };
};

export default useInstReferenceDataByType;

import React from "react";
import { LabelNameProps } from "../../../../Types/Labels";
import { TextField } from "@mui/material";
const { VMS_Form } = require("../../../json/form.json");

const Common = () => {
  return (
    <>
      <div className="vms-v-details-new__other-details row g-0">
        <div className="col vms-v-details-new__other-details--common">
          {VMS_Form.Vehicle_Details.Common.map(
            (label: LabelNameProps, index: React.Key) => {
              return (
                <TextField
                  disabled
                  label={label.LabelName}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={label.inputName}
                  className="vms-v-details-new__other-details--textfield"
                />
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default Common;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import useToken from "../../../customhooks/useToken";
import { GetLibBookIssued } from "../../../queries/Library/BookIssue/list/byIds";
import {
  GetLibBookIssuedData,
  GetLibBookIssuedVars,
} from "../../../Types/Library/BookIssue/paginationTypes";
import {
  BooksReportType,
  Direction,
  LibBookIssueQueryType,
  SortBy,
} from "../../../utils/Enum.types";
import { toIsoDate } from "../../../utils/UtilFunctions";

const useIssuedBook = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  rowsPerPage: number,
  queryType: BooksReportType,
  book_issue_from_date: string,
  book_issue_to_date: string
) => {
  //token and params and Global states
  const { token } = useToken();

  const [ids, setIds] = useState<number[]>([]);
  const [book_issue_query_type, setbook_issue_query_type] = useState(
    LibBookIssueQueryType.NOT_SELECTED
  );
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [GetIssueBook, { data, loading, error, fetchMore }] = useLazyQuery<
    GetLibBookIssuedData,
    GetLibBookIssuedVars
  >(GetLibBookIssued, {
    variables: {
      first: rowsPerPage,
      after: null,
      input: {
        book_issue_query_type,
        book_issue_from_date: toIsoDate(book_issue_from_date),
        book_issue_to_date: toIsoDate(book_issue_to_date),
        ids,
      },
      sortBy: SortBy.ISSUE_DATE,
      direction: Direction.ASC,
      token,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (InstId) {
      switch (queryType) {
        case BooksReportType.BOOKS_ISSUED:
          if (sectionId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_BY_SECTION_ID
            );
            setIds([Number(InstId), sectionId]);
          } else if (semesterId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_BY_SEMESTER_ID
            );
            setIds([Number(InstId), semesterId]);
          } else if (classId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_BY_CLASS_ID
            );
            setIds([Number(InstId), classId]);
          } else if (branchId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_BY_BRANCH_ID
            );
            setIds([Number(InstId), branchId]);
          } else if (departmentId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_RETURNED_BY_DEPT_ID
            );
            setIds([Number(InstId), departmentId]);
          } else {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_BY_INST_ID
            );
            setIds([Number(InstId)]);
          }
          break;
        case BooksReportType.BOOKS_RETURNED:
          if (sectionId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_RETURNED_BY_SECTION_ID
            );
            setIds([Number(InstId), sectionId]);
          } else if (semesterId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_RETURNED_BY_SEMESTER_ID
            );
            setIds([Number(InstId), semesterId]);
          } else if (classId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_RETURNED_BY_CLASS_ID
            );
            setIds([Number(InstId), classId]);
          } else if (branchId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_BY_BRANCH_ID
            );
            setIds([Number(InstId), branchId]);
          } else if (departmentId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_RETURNED_BY_DEPT_ID
            );
            setIds([Number(InstId), departmentId]);
          } else if (state.studentId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_RETURNED_BY_STD_ID
            );
            setIds([Number(InstId), state.studentId]);
          } else {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_RETURNED_BY_INST_ID
            );
            setIds([Number(InstId)]);
          }
          break;
        case BooksReportType.BOOKS_OVER_DUE:
          if (sectionId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_OVER_DUE_BY_SECTION_ID
            );
            setIds([Number(InstId), sectionId]);
          } else if (semesterId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_OVER_DUE_BY_SEMESTER_ID
            );
            setIds([Number(InstId), semesterId]);
          } else if (classId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_OVER_DUE_BY_CLASS_ID
            );
            setIds([Number(InstId), classId]);
          } else if (branchId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_OVER_DUE_BY_BRANCH_ID
            );
            setIds([Number(InstId), branchId]);
          } else if (departmentId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_OVER_DUE_BY_DEPT_ID
            );
            setIds([Number(InstId), departmentId]);
          } else {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_OVER_DUE_BY_INST_ID
            );
            setIds([Number(InstId)]);
          }
          break;
        case BooksReportType.BOOKS_FINE_COLLECTION:
          if (sectionId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_FINE_BY_SECTION_ID
            );
            setIds([Number(InstId), sectionId]);
          } else if (semesterId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_FINE_BY_SEMESTER_ID
            );
            setIds([Number(InstId), semesterId]);
          } else if (classId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_FINE_BY_CLASS_ID
            );
            setIds([Number(InstId), classId]);
          } else if (branchId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_FINE_BY_BRANCH_ID
            );
            setIds([Number(InstId), branchId]);
          } else if (departmentId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_FINE_BY_DEPT_ID
            );
            setIds([Number(InstId), departmentId]);
          } else {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_FINE_BY_INST_ID
            );
            setIds([Number(InstId)]);
          }
          break;
        case BooksReportType.BOOKS_ISSUED_AND_RETURNED:
          if (sectionId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_AND_RETURNED_BY_SECTION_ID
            );
            setIds([Number(InstId), sectionId]);
          } else if (semesterId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_AND_RETURNED_BY_SEMESTER_ID
            );
            setIds([Number(InstId), semesterId]);
          } else if (classId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_AND_RETURNED_BY_CLASS_ID
            );
            setIds([Number(InstId), classId]);
          } else if (branchId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_AND_RETURNED_BY_BRANCH_ID
            );
            setIds([Number(InstId), branchId]);
          } else if (departmentId) {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_AND_RETURNED_BY_DEPT_ID
            );
            setIds([Number(InstId), departmentId]);
          } else {
            setbook_issue_query_type(
              LibBookIssueQueryType.BOOKS_ISSUED_AND_RETURNED_BY_INST_ID
            );
            setIds([Number(InstId)]);
          }
          break;
        default:
          break;
      }
    }
  }, [
    queryType,
    departmentId,
    branchId,
    classId,
    semesterId,
    sectionId,
    InstId,
    state.studentId,
  ]);
  useEffect(() => {
    if (
      ids[0] > 0 &&
      book_issue_query_type !== LibBookIssueQueryType.NOT_SELECTED
    ) {
      GetIssueBook();
    }
  }, [GetIssueBook, ids, book_issue_query_type, rowsPerPage]);
  return {
    IssuedBooks: {
      data,
      loading,
      error,
      fetchMore,
    },
  };
};

export default useIssuedBook;

import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import EventsList from "../../../../../images/EventsList.svg";
import Close from "../../../../../images/Close.svg";
import Input from "../../../../../stories/Input/Input";
import { Label } from "../../../../../stories/Label/Label";
import { Button } from "../../../../../stories/Button/Button";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../../hooks/useAcdGeneralHolidays";
import { AcdYrType, FlagType } from "../Index";
import {
  MonthName,
  getMonthDateAndDay,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import { GetAcdInstGeneralHolidaysNode } from "../../../types/attendance";

interface Props {
  acdYr: AcdYrType;
  setDrawerFlag: React.Dispatch<React.SetStateAction<FlagType>>;
}

const Events = ({ acdYr, setDrawerFlag }: Props) => {
  const [searchText, setSearchText] = useState("");
  const [holidaysMap, setHolidaysMap] = useState<
    Map<string, Map<string, GetAcdInstGeneralHolidaysNode[]>>
  >(new Map());

  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    acdYr ? acdYr.value : 0,
    acdYr.end_date.toString(),
    acdYr.start_date.toString(),
    searchText,
    holiday_query_type.EVENTS
  );
  useEffect(() => {
    if (InstGeneralHolidays.data && !InstGeneralHolidays.loading) {
      let convertToMap = new Map();
      InstGeneralHolidays.data.GetAcdInstGeneralHolidays.edges.forEach(
        ({ node }) => {
          const alreadyExistingHolidays = convertToMap.get(
            MonthName(node.holiday_date)
          );
          if (alreadyExistingHolidays) {
            const alreadyExistingDate = alreadyExistingHolidays.get(
              node.holiday_date
            );

            const tempMap: Map<string, GetAcdInstGeneralHolidaysNode[]> =
              alreadyExistingHolidays;
            if (alreadyExistingDate) {
              tempMap.set(node.holiday_date, [...alreadyExistingDate, node]);
            } else {
              tempMap.set(node.holiday_date, [node]);
            }
            convertToMap.set(MonthName(node.holiday_date), tempMap);
          } else {
            const tempMap: Map<string, GetAcdInstGeneralHolidaysNode[]> =
              new Map();
            tempMap.set(node.holiday_date, [node]);
            convertToMap.set(MonthName(node.holiday_date), tempMap);
          }
        }
      );
      setHolidaysMap(convertToMap);
    }
  }, [InstGeneralHolidays.data, InstGeneralHolidays.loading]);

  return (
    <>
      <div className="calendar-holiday-list">
        <div className="calendar-holiday-list--title">
          <Title variant="subtitle1">
            <img src={EventsList} alt="" /> &nbsp; All Events List
          </Title>
          <img
            src={Close}
            alt=""
            className="modal-close-icon"
            onClick={() =>
              setDrawerFlag((prev) => ({
                ...prev,
                flag: false,
              }))
            }
          />
        </div>
        <div className="row g-0 justify-content-center">
          <div className="col-5">
            <Input
              id="search"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="calendar-holiday-list--data">
          <ul className="calendar-holiday-list--data--events-list">
            {Array.from(holidaysMap).map(([key, value], index) => {
              return (
                <React.Fragment key={index}>
                  <li className="calendar-holiday-list--data--month">{key}</li>
                  {Array.from(value).map(([k, value], index) => {
                    return (
                      <li key={index}>
                        <Label>{index + 1}</Label>
                        <div className="calendar-holiday-list--data--events-list--events">
                          <div>
                            <b>{toStandardDate(k)}</b>
                            <br />
                            {value.map((event, index) => (
                              <span key={index}>{event.holiday_desc}</span>
                            ))}
                          </div>
                          <div className="calendar-holiday-list--data--desc">
                            {getMonthDateAndDay(k).weekString}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
        <Button
          mode="cancel"
          onClick={() =>
            setDrawerFlag((prev) => ({
              ...prev,
              flag: false,
            }))
          }
        />
      </div>
    </>
  );
};

export default Events;

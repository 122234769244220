import React, { useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { ConfigurationTitleProps } from "../../../../Types/Titles";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddSwConfigPageInfo,
  DeleteSwConfigPageInfo,
} from "../../../../queries/institution/configuration/mutation";
import useToken from "../../../../customhooks/useToken";
import { GetSwConfigPageInfo } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import {
  PageSoftwareDetailsVars,
  PageSwData,
} from "../../../../Types/configtypes";
import ArrowRed from "../../../../images/ArrowRed.svg";
import ArrowGreen from "../../../../images/ArrowGreen.svg";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import {
  LicenseTypes,
  MoveDirection,
  Operation,
} from "../../../../utils/Enum.types";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const config = require("../../../../json/pageconfig.json");
const { Configuration } = require("../../../../json/title.json");
interface pageConfigDetails {
  id: number;
  page_number: number;
  page_name: string;
  page_config_avialable: boolean;
  isChecked: boolean;
}
const Index = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();

  const [checked, setChecked] = useState<pageConfigDetails[]>([]);
  const [unChecked, setunChecked] = useState<pageConfigDetails[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [AddPageConfiguration, { loading: creationLoading }] = useMutation(
    AddSwConfigPageInfo,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeletePageConfiguration, { loading: updationLoading }] = useMutation(
    DeleteSwConfigPageInfo,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetPageConfigInfo, { data }] = useLazyQuery<
    PageSwData,
    PageSoftwareDetailsVars
  >(GetSwConfigPageInfo, { variables: { token } });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    GetPageConfigInfo().then(({ data }) => {
      if (data) {
        let b: pageConfigDetails = config;
        const pageNames = Object?.values(b).map(
          (u: pageConfigDetails[]) => u[0]
        );

        const unChecked = pageNames.filter((info) => {
          return !data.GetSwConfigPageInfo.find((pageName) => {
            return (
              info.page_config_avialable === false &&
              info.page_number === pageName.page_number
            );
          });
        });
        setunChecked(data.GetSwConfigPageInfo.length ? unChecked : pageNames);

        const checked = data.GetSwConfigPageInfo.map((v) => ({
          id: v.id,
          page_number: v.page_number,
          page_name: v.page_name,
          page_config_avialable: v.page_config_avialable,
          isChecked: false,
        }));
        setChecked(checked);
      }
    });
  }, [GetPageConfigInfo, data]);

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: pageConfigDetails,
    direction: string
  ) => {
    const { checked: check } = event.target as HTMLInputElement;
    switch (direction) {
      case MoveDirection.LEFT:
        const mappedDataLeft = unChecked?.map((row) => {
          if (row.page_number === data.page_number) {
            return {
              ...row,
              isChecked: check ? true : false,
            };
          }
          return row;
        });
        setunChecked(mappedDataLeft);
        break;
      case MoveDirection.RIGHT:
        const mappedDataRight = checked?.map((row) => {
          if (row.page_number === data.page_number) {
            return {
              ...row,
              isChecked: check ? true : false,
            };
          }
          return row;
        });
        setChecked(mappedDataRight);
        break;
      default:
        break;
    }
  };
  const movePageConfig = (direction: MoveDirection) => {
    switch (direction) {
      case MoveDirection.RIGHT:
        AddPageConfiguration({
          variables: {
            token,
            input: unChecked
              ?.filter((data) => data.isChecked)
              ?.map((pageData) => ({
                page_number: pageData.page_number,
                page_name: pageData.page_name,
                page_config_avialable: !pageData.page_config_avialable,
              })),
            user_details,
          },
          refetchQueries: [
            {
              query: GetSwConfigPageInfo,
              variables: {
                token,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Page Configured Sucessfully",
              operation: Operation.CREATE,
            });
          }
        });
        break;
      case MoveDirection.LEFT:
        DeletePageConfiguration({
          variables: {
            token,
            ids: checked
              .filter((d) => d.isChecked)
              .map((pageData) => pageData.id),
            user_details,
          },
          refetchQueries: [
            {
              query: GetSwConfigPageInfo,
              variables: {
                token,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Page Configured Deleted Sucessfully",
              operation: Operation.DELETE,
            });
          }
        });
        break;
      default:
        break;
    }
  };

  const handleSelectAllClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    direction: MoveDirection
  ) => {
    const { checked: check } = e.target as HTMLInputElement;
    if (direction === MoveDirection.LEFT) {
      const newSelecteds = unChecked?.map((data) => ({
        ...data,
        isChecked: check,
      }));
      setunChecked([...newSelecteds]);
    } else {
      const newSelecteds = checked?.map((data) => ({
        ...data,
        isChecked: check,
      }));
      setChecked([...newSelecteds]);
    }
  };
  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>
        {Configuration.Titles.map(
          (title: ConfigurationTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Page}</React.Fragment>;
          }
        )}
      </Title>
      <div className="row g-0 eduate-pageconfig">
        <div className="col booktype-left eduate-pageconfig__details">
          <Title variant="subtitle1">Page Config Not Available</Title>
          <div className="eduate-pageconfig__details--list">
            <FormGroup className="select-all">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      unChecked.length > 0 &&
                      unChecked?.filter((data) => data.isChecked).length ===
                        unChecked.length
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleSelectAllClick(e, MoveDirection.LEFT);
                    }}
                    disabled={
                      checked?.filter((data) => data.isChecked).length > 0
                    }
                  />
                }
                label="Select All"
              />
            </FormGroup>
            <div className="eduate-pageconfig__details--list--flex">
              {unChecked?.map((data, index) => {
                return (
                  <FormGroup
                    role="checkbox"
                    aria-checked={data.isChecked}
                    tabIndex={-1}
                    key={index}
                    className="eduate-pageconfig__details--list--flex--data"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data?.isChecked ?? false}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleClick(event, data, MoveDirection.LEFT);
                          }}
                          disabled={
                            checked?.filter((data) => data.isChecked).length > 0
                          }
                        />
                      }
                      label={
                        <>
                          &nbsp; {index + 1})&nbsp;{data.page_name}
                        </>
                      }
                    />
                  </FormGroup>
                );
              })}
            </div>
          </div>
        </div>

        <div className="books-allocation__tableblock--swap-image">
          <div>
            <img
              src={ArrowGreen}
              alt="/"
              onClick={() => {
                movePageConfig(MoveDirection.RIGHT);
              }}
              className={
                unChecked?.filter((data) => data.isChecked).length > 0
                  ? ""
                  : "opacity"
              }
            />
          </div>
          <br />
          <div>
            <img
              src={ArrowRed}
              alt="/"
              onClick={() => {
                movePageConfig(MoveDirection.LEFT);
              }}
              className={
                checked?.filter((data) => data.isChecked).length > 0
                  ? ""
                  : "opacity"
              }
            />
          </div>
        </div>
        <div className="col booktype-right eduate-pageconfig__details">
          <Title variant="subtitle1">Page Config Available</Title>
          <div className="eduate-pageconfig__details--list">
            <FormGroup className="select-all">
              <FormControlLabel
                control={
                  <Checkbox
                    className="select-all--checkbox"
                    checked={
                      checked.length > 0 &&
                      checked?.filter((data) => data.isChecked).length ===
                        checked.length
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleSelectAllClick(e, MoveDirection.RIGHT);
                    }}
                    disabled={
                      unChecked?.filter((data) => data.isChecked).length > 0
                    }
                  />
                }
                label="All Select"
              />
            </FormGroup>
            <div className="eduate-pageconfig__details--list--flex">
              {checked?.map((data, index) => {
                return (
                  <FormGroup key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="select-all--checkbox"
                          checked={data?.isChecked ?? false}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleClick(event, data, MoveDirection.RIGHT)}
                          disabled={
                            unChecked?.filter((data) => data.isChecked).length >
                            0
                          }
                        />
                      }
                      label={
                        <>
                          &nbsp;{index + 1}) &nbsp;{data.page_name}
                        </>
                      }
                      className="eduate-pageconfig__details--list--flex--data"
                    />
                  </FormGroup>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      &nbsp;&nbsp;&nbsp;
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const UpdateLibBookRack = gql`
  mutation UpdateLibBookRack(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateLibBookRackInput!
  ) {
    UpdateLibBookRack(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateLibBooksRackDetails = gql`
  mutation UpdateLibBooksRackDetails(
    $token: String!
    $book_ids: [ID!]!
    $rack_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateLibBooksRackDetails(
      token: $token
      book_ids: $book_ids
      rack_id: $rack_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateLibBooksDeptDetails = gql`
  mutation UpdateLibBooksDeptDetails(
    $token: String!
    $book_ids: [ID!]!
    $dept_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateLibBooksDeptDetails(
      token: $token
      book_ids: $book_ids
      inst_id: $inst_id
      user_details: $user_details
      dept_id: $dept_id
    )
  }
`;

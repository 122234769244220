import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import { Direction } from "../../../utils/Enum.types";
import { EmpDetails } from "../../HR/hooks/useEmployeeAttsByMonth";
import { MkCkQueryType } from "../common/Enum.types";
import { GetAcctVouchersMkCk } from "../queries/Vouchers/query";
interface AcctVoucherDetailsMkCk {
  id: number;
  vo_date: string;
  vo_cr_db: string;
  vo_sl_no: number;
  vo_cr: number;
  vo_db: number;
  vo_cr_total: number;
  vo_db_total: number;
  acct_ldgr: {
    id: number;
    ldgr_desc: string;
    ldgr_cash_bank_other: string;
  };
}
export interface AssignedDetails {
  remarks: string;
}

export interface AcctVoucherMasterMkCk {
  id: number;
  created_at: string;
  fin_yr: string;
  mkck_type: string;
  mkck_book_type: string;
  mkck_no: string;
  mkck_date: string;
  mkck_total_cr_amt: number;
  mkck_total_db_amt: number;
  mkck_completed: boolean;
  mkck_status: string;
  party_name: string;
  party_bill_no: string;
  party_bill_date: string;
  payment_invoice_filename: string;
  transcation_no: string;
  remarks: string;
  transcation_type: string;
  acct_voucher_details_mkck: AcctVoucherDetailsMkCk[];
  created_emp_details: EmpDetails;
  last_assigned_details: AssignedDetails;
}
export interface MkckNodeData {
  node: AcctVoucherMasterMkCk;
}
interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  endCursor: string;
  startCursor: string;
}

interface AcctVoucherMkCkResponse {
  totalCount: number;
  pageInfo: PageInfo;
  edges: {
    node: AcctVoucherMasterMkCk;
  }[];
}

interface GetAcctVouchersMkCkVariables {
  token: string;
  finYrId: number;
  instId: string;
  input: {
    query_type: MkCkQueryType;
    pr_emp_id: number;
  };
  after: string | null;
  first: number;
  orderBy: { direction: Direction; field?: string };
  status: string;
}

interface GetAcctVouchersMkCkResult {
  GetAcctVouchersMkCk: AcctVoucherMkCkResponse;
}

interface GetAcctMkCkAssignedDetails {
  id: number;
  sl_no: number;
  remarks: string;
  status: string;
  transcation_type: string;
  created_emp_details: EmpDetails;
  assigned_emp_details: EmpDetails;
  mkck_voucher_master: {
    id: number;
    created_at: string;
    fin_yr: string;
    mkck_type: string;
    mkck_book_type: string;
    mkck_no: string;
    mkck_date: string;
    mkck_total_cr_amt: number;
    mkck_total_db_amt: number;
    mkck_completed: boolean;
    mkck_status: string;
    party_name: string;
    party_bill_no: string;
    party_bill_date: string;
    transcation_no: string;
    last_assigned_details: AssignedDetails;
  };
}
export interface GetAcctMkCkAssignedData {
  GetAcctMkCkAssigned: GetAcctMkCkAssignedDetails[];
}
export interface GetAcctMkCkAssignedDetailsVars {
  token: string;
  fin_yr_id: number;
  inst_id: string;
  mkck_voucher_master_id: number;
}
const useMkckVoucherData = (query_type: MkCkQueryType, status: string) => {
  const { state } = useContext(AppContext);
  const { token } = useToken();

  const { InstId } = useParams();

  const [GetMkckVouchers, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcctVouchersMkCkResult,
    GetAcctVouchersMkCkVariables
  >(GetAcctVouchersMkCk);
  useEffect(() => {
    if (state.ActiveFinYr && token) {
      GetMkckVouchers({
        variables: {
          finYrId: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          first: ROWS_PER_PAGE,
          input: { pr_emp_id: state.empLoginId, query_type },
          instId: InstId!,
          orderBy: { direction: Direction.ASC },
          token,
          after: null,
          status,
        },
      });
    }
  }, [
    state.ActiveFinYr,
    GetMkckVouchers,
    token,
    InstId,
    query_type,
    state.empLoginId,
    status,
  ]);
  return { mkckVouchers: { data, loading, error, fetchMore } };
};

export default useMkckVoucherData;

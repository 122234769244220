import { gql } from "@apollo/client";

export const GetAccessionNumber = gql`
  query GetAccessionNumber(
    $token: String!
    $inst_id: ID!
    $lib_classfication_id: ID!
  ) {
    GetAccessionNumber(
      token: $token
      inst_id: $inst_id
      lib_classfication_id: $lib_classfication_id
    )
  }
`;

import { gql } from "@apollo/client";

export const AddAcdSubjectGroupAndDetails = gql`
  mutation AddAcdSubjectGroupAndDetails(
    $token: String!
    $inst_id: ID!
    $group_name: String!
    $user_details: SubscribedUser!
    $input: [CreateAcdSubjectGroupDetailsInput!]!
  ) {
    AddAcdSubjectGroupAndDetails(
      token: $token
      inst_id: $inst_id
      group_name: $group_name
      user_details: $user_details
      input: $input
    )
  }
`;
export const UpdateAcdSubjectGroup = gql`
  mutation UpdateAcdSubjectGroup(
    $token: String!
    $inst_id: ID!
    $subj_group_id: ID!
    $subj_group_name: String!
    $user_details: SubscribedUser!
  ) {
    UpdateAcdSubjectGroup(
      token: $token
      inst_id: $inst_id
      subj_group_name: $subj_group_name
      user_details: $user_details
      subj_group_id: $subj_group_id
    )
  }
`;
export const DeleteAcdSubjectGroup = gql`
  mutation DeleteAcdSubjectGroup(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdSubjectGroup(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetStudents } from "../queries/students/list/newApi";
import {
  AcademicYear,
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../Types/Student";
import {
  AcdSubjectAllocationData,
  Direction,
  SortBy,
  StudentReportType,
} from "../utils/Enum.types";
import { studentQueryTypes } from "../utils/studentqueryEnum.types";
import useToken from "./useToken";
import {
  optionsType,
  studentPersonalData,
  studentPreviousSchoolData,
} from "../utils/Form.types";
import { EMPTY_STRING } from "../utils/constants";
import { AppContext } from "../context/context";

export interface MstStudentMiscData {
  id: number;
  lib_book_count: number;
  student_id: number;
  availed_transport: boolean;
  availed_hostel: boolean;
  mobile_app_count: number;
}
export interface StudentNode {
  first_name: string;
  id: number;
  last_name: string;
  middle_name: string;
  std_adm_no: string;
  std_email: string;
  std_father_name: string;
  std_father_mobile: string;
  std_mother_mobile: string;
  std_fresher: boolean;
  std_mobile: string;
  std_mother_name: string;
  std_profile_filename: string;
  std_reg_no: string;
  std_status: string;
  std_doa: string;
  std_dob: string;
  std_sex: string;
  std_roll_no: number;
  category: CategoryList;
  dept: DepartmentList;
  branch: BranchesList;
  acd_yr: AcademicYear;
  class: ClassList;
  semester: SemesterList;
  section: SectionList;
  std_details: studentPersonalData;
  std_other_details: studentPreviousSchoolData;
  misc_data: MstStudentMiscData;
  fin_yr?: {
    fin_yr: string;
  };
  isChecked?: boolean;
  amount: number;
  inc_no: string;
  visited: boolean;
  std_type: string;
  edit_adm_no?: string;
  url: string;
}

export interface StudentPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}
export interface StudentEdges {
  node: StudentNode;
}
export interface GetStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
export interface input {
  std_query_type: string;
  ids: number[];
  str_data?: string[];
  int_data?: number[];
  float_data?: number[];
}
export interface MstStudentOrder {
  field: string;
  direction: Direction;
}
export interface vars {
  name: string;
  status: string;
  after: string | null;
  orderBy: MstStudentOrder[];
  first: number | null;
  token: string;
  fin_yr_id: number;
  input: input;
}
export interface GetStudentsData {
  GetStudents: GetStudentsList;
}

const useStudentsbyNewApi = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  status: string,
  name: string,
  rowsPerPage: number | null,
  queryType: StudentReportType,
  acctLdgrId?: number,
  channelId?: number,
  orderBy?: MstStudentOrder[],
  religionSelected?: optionsType | null,
  ageValues?: number[]
) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const [std_query_type, setstd_query_type] = useState("");
  const [strData, setStrData] = useState<string[]>([]);
  const [ids, setId] = useState<number[]>([]);
  const setGeneralQueryType = useCallback(() => {
    if (sectionId && categoryId) {
      setId([sectionId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_SECTION_AND_CATEGORY_ID);
    } else if (semesterId && categoryId) {
      setId([semesterId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_SEMESTER_AND_CATEGORY_ID);
    } else if (classId && categoryId) {
      setId([classId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_CLASS_AND_CATEGORY_ID);
    } else if (branchId && categoryId) {
      setId([branchId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_BRANCH_AND_CATEGORY_ID);
    } else if (departmentId && categoryId) {
      setId([departmentId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_DEPT_AND_CATEGORY_ID);
    } else if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.BY_DEPT_ID);
    } else if (categoryId) {
      setId([categoryId]);
      setstd_query_type(studentQueryTypes.BY_CATEGORY_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.BY_INST_ID);
    }
  }, [
    sectionId,
    categoryId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
  ]);

  const setSectionAllocationQueryType = useCallback(() => {
    if (categoryId) {
      setId([categoryId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_CATEGORY_ID);
    }
    // else if (sectionId) {
    //   setId([sectionId]);
    //   setstd_query_type(studentQueryTypes.section_NOT);
    // }
    else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_INST_ID);
    }
  }, [categoryId, semesterId, classId, branchId, departmentId, InstId]);

  const setNotRegisteredQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.NOT_REGISTERED_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.NOT_REGISTERED_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.NOT_REGISTERED_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.NOT_REGISTERED_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.NOT_REGISTERED_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.NOT_REGISTERED_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);

  const setEligibleQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_CUR_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_CUR_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_CUR_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_CUR_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_CUR_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_CUR_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);

  const setLibBookClearanceQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.LIB_BOOK_CLEARANCE_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.LIB_BOOK_CLEARANCE_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.LIB_BOOK_CLEARANCE_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.LIB_BOOK_CLEARANCE_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.LIB_BOOK_CLEARANCE_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.LIB_BOOK_CLEARANCE_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);

  const setStatusNxtInstId = useCallback(() => {
    if (sectionId && categoryId) {
      setId([sectionId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_EQ_NXT_BY_SECTION_AND_CATEGORY_ID
      );
    } else if (semesterId && categoryId) {
      setId([semesterId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_EQ_NXT_BY_SEMESTER_AND_CATEGORY_ID
      );
    } else if (classId && categoryId) {
      setId([classId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_EQ_NXT_BY_CLASS_AND_CATEGORY_ID
      );
    } else if (branchId && categoryId) {
      setId([branchId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_EQ_NXT_BY_BRANCH_AND_CATEGORY_ID
      );
    } else if (departmentId && categoryId) {
      setId([departmentId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_EQ_NXT_BY_DEPT_AND_CATEGORY_ID
      );
    } else if (categoryId) {
      setId([categoryId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_NXT_BY_CATEGORY_ID);
    } else if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_NXT_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_NXT_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_NXT_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_NXT_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_NXT_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_NXT_BY_INST_ID);
    }
  }, [
    sectionId,
    categoryId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
  ]);

  const setNonEligibleQueryType = useCallback(() => {
    if (sectionId && categoryId) {
      setId([sectionId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_NEQ_CUR_BY_SECTION_AND_CATEGORY_ID
      );
    } else if (semesterId && categoryId) {
      setId([semesterId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_NEQ_CUR_BY_SEMESTER_AND_CATEGORY_ID
      );
    } else if (classId && categoryId) {
      setId([classId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_NEQ_CUR_BY_CLASS_AND_CATEGORY_ID
      );
    } else if (branchId && categoryId) {
      setId([branchId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_NEQ_CUR_BY_BRANCH_AND_CATEGORY_ID
      );
    } else if (departmentId && categoryId) {
      setId([departmentId, categoryId]);
      setstd_query_type(
        studentQueryTypes.STATUS_NEQ_CUR_BY_DEPT_AND_CATEGORY_ID
      );
    } else if (categoryId) {
      setId([categoryId]);
      setstd_query_type(studentQueryTypes.STATUS_NEQ_CUR_BY_CATEGORY_ID);
    } else if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.STATUS_NEQ_CUR_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.STATUS_NEQ_CUR_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.STATUS_NEQ_CUR_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.STATUS_NEQ_CUR_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.STATUS_NEQ_CUR_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.STATUS_NEQ_CUR_BY_INST_ID);
    }
  }, [
    sectionId,
    categoryId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
  ]);

  const setNewAdmissionQueryType = useCallback(() => {
    if (sectionId && categoryId) {
      setId([sectionId, categoryId]);
      setstd_query_type(
        studentQueryTypes.NEW_ADMISSIONS_BY_SECTION_AND_CATEGORY_ID
      );
    } else if (semesterId && categoryId) {
      setId([semesterId, categoryId]);
      setstd_query_type(
        studentQueryTypes.NEW_ADMISSIONS_BY_SEMESTER_AND_CATEGORY_ID
      );
    } else if (classId && categoryId) {
      setId([classId, categoryId]);
      setstd_query_type(
        studentQueryTypes.NEW_ADMISSIONS_BY_CLASS_AND_CATEGORY_ID
      );
    } else if (branchId && categoryId) {
      setId([branchId, categoryId]);
      setstd_query_type(
        studentQueryTypes.NEW_ADMISSIONS_BY_BRANCH_AND_CATEGORY_ID
      );
    } else if (departmentId && categoryId) {
      setId([departmentId, categoryId]);
      setstd_query_type(
        studentQueryTypes.NEW_ADMISSIONS_BY_DEPT_AND_CATEGORY_ID
      );
    } else if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.NEW_ADMISSIONS_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.NEW_ADMISSIONS_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.NEW_ADMISSIONS_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.NEW_ADMISSIONS_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.NEW_ADMISSIONS_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.NEW_ADMISSIONS_BY_INST_ID);
    }
  }, [
    sectionId,
    categoryId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
  ]);

  const setByReligionQueryType = useCallback(() => {
    if (sectionId && categoryId) {
      setId([sectionId, categoryId]);
      setstd_query_type(
        studentQueryTypes.BY_RELIGION_AND_SECTION_AND_CATEGORY_ID
      );
    } else if (semesterId && categoryId) {
      setId([semesterId, categoryId]);
      setstd_query_type(
        studentQueryTypes.BY_RELIGION_AND_SEMESTER_AND_CATEGORY_ID
      );
    } else if (classId && categoryId) {
      setId([classId, categoryId]);
      setstd_query_type(
        studentQueryTypes.BY_RELIGION_AND_CLASS_AND_CATEGORY_ID
      );
    } else if (branchId && categoryId) {
      setId([branchId, categoryId]);
      setstd_query_type(
        studentQueryTypes.BY_RELIGION_AND_BRANCH_AND_CATEGORY_ID
      );
    } else if (departmentId && categoryId) {
      setId([departmentId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_RELIGION_AND_DEPT_AND_CATEGORY_ID);
    } else if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.BY_RELIGION_AND_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.BY_RELIGION_AND_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.BY_RELIGION_AND_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.BY_RELIGION_AND_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.BY_RELIGION_AND_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.BY_RELIGION_AND_INST_ID);
    }
  }, [
    sectionId,
    categoryId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
  ]);

  const setByAgeQueryType = useCallback(() => {
    if (sectionId && categoryId) {
      setId([sectionId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_SECTION_AND_CATEGORY_ID);
    } else if (semesterId && categoryId) {
      setId([semesterId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_SEMESTER_AND_CATEGORY_ID);
    } else if (classId && categoryId) {
      setId([classId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_CLASS_AND_CATEGORY_ID);
    } else if (branchId && categoryId) {
      setId([branchId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_BRANCH_AND_CATEGORY_ID);
    } else if (departmentId && categoryId) {
      setId([departmentId, categoryId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_DEPT_AND_CATEGORY_ID);
    } else if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.BY_AGE_AND_INST_ID);
    }
  }, [
    sectionId,
    categoryId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
  ]);

  const setTransportEnabledQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.AVAILED_TRANSPORT_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.AVAILED_TRANSPORT_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.AVAILED_TRANSPORT_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.AVAILED_TRANSPORT_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.AVAILED_TRANSPORT_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.AVAILED_TRANSPORT_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);

  const setTransportNotEnabledQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.NOT_AVAILED_TRANSPORT_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.NOT_AVAILED_TRANSPORT_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.NOT_AVAILED_TRANSPORT_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.NOT_AVAILED_TRANSPORT_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.NOT_AVAILED_TRANSPORT_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.NOT_AVAILED_TRANSPORT_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);

  const setTransportNotAssignedQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.ROUTE_NOT_ASSIGNED_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.ROUTE_NOT_ASSIGNED_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.ROUTE_NOT_ASSIGNED_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.ROUTE_NOT_ASSIGNED_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.ROUTE_NOT_ASSIGNED_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);
  const setMobileDeviceQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.MOBILE_APP_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.MOBILE_APP_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.MOBILE_APP_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.MOBILE_APP_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.MOBILE_APP_BY_DEPT_ID);
    } else {
      setId([Number(InstId)]);
      setstd_query_type(studentQueryTypes.MOBILE_APP_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);
  const setStudentDeviceInstalledQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setStrData([AcdSubjectAllocationData.SECTION]);

      setstd_query_type(studentQueryTypes.MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL);
    } else if (semesterId) {
      setId([semesterId]);
      setStrData([AcdSubjectAllocationData.SEMESTER]);

      setstd_query_type(studentQueryTypes.MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL);
    } else if (classId) {
      setId([classId]);
      setStrData([AcdSubjectAllocationData.CLASS]);

      setstd_query_type(studentQueryTypes.MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL);
    } else if (branchId) {
      setId([branchId]);
      setStrData([AcdSubjectAllocationData.BRANCH]);

      setstd_query_type(studentQueryTypes.MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL);
    } else if (departmentId) {
      setId([departmentId]);
      setStrData([AcdSubjectAllocationData.DEPT]);

      setstd_query_type(studentQueryTypes.MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL);
    } else {
      setId([Number(InstId)]);
      setStrData([AcdSubjectAllocationData.INST]);

      setstd_query_type(studentQueryTypes.MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);
  const setStudentDeviceNotInstalledQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setStrData([AcdSubjectAllocationData.SECTION]);
      setstd_query_type(
        studentQueryTypes.MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL
      );
    } else if (semesterId) {
      setId([semesterId]);
      setStrData([AcdSubjectAllocationData.SEMESTER]);
      setstd_query_type(
        studentQueryTypes.MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL
      );
    } else if (classId) {
      setId([classId]);
      setStrData([AcdSubjectAllocationData.CLASS]);
      setstd_query_type(
        studentQueryTypes.MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL
      );
    } else if (branchId) {
      setId([branchId]);
      setStrData([AcdSubjectAllocationData.BRANCH]);
      setstd_query_type(
        studentQueryTypes.MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL
      );
    } else if (departmentId) {
      setId([departmentId]);
      setStrData([AcdSubjectAllocationData.DEPT]);
      setstd_query_type(
        studentQueryTypes.MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL
      );
    } else {
      setId([Number(InstId)]);
      setStrData([AcdSubjectAllocationData.INST]);
      setstd_query_type(
        studentQueryTypes.MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL
      );
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, InstId]);
  //Global States
  useEffect(() => {
    switch (queryType) {
      case StudentReportType.GENERAL:
        setGeneralQueryType();
        break;
      case StudentReportType.SECTION_ALLOCATION:
        setSectionAllocationQueryType();
        break;
      case StudentReportType.NOT_REGISTERED:
        setNotRegisteredQueryType();
        break;
      case StudentReportType.ELIGIBLE:
        setEligibleQueryType();
        break;

      case StudentReportType.LIB_BOOK_CLEARANCE:
        setLibBookClearanceQueryType();
        break;
      case StudentReportType.STUDENTS_BY_CHANNEL:
        if (channelId && channelId !== 0) {
          setstd_query_type(studentQueryTypes.BY_MSG_CHANNEL_ID);
          setId([channelId!]);
        }
        break;
      case StudentReportType.STATUS_NXT_INST_ID:
        setStatusNxtInstId();
        break;
      case StudentReportType.NON_ELIGIBLE:
        setNonEligibleQueryType();
        break;
      case StudentReportType.NEW_ADMISSION:
        setNewAdmissionQueryType();
        break;
      case StudentReportType.BY_RELIGION:
        setByReligionQueryType();
        break;
      case StudentReportType.BY_AGE:
        setByAgeQueryType();
        break;
      case StudentReportType.TRANSPORT_ENABLED:
        setTransportEnabledQueryType();
        break;
      case StudentReportType.TRANSPORT_NOT_ENABLED:
        setTransportNotEnabledQueryType();
        break;
      case StudentReportType.TRANSPORT_NOT_ASSIGNED:
        setTransportNotAssignedQueryType();
        break;
      case StudentReportType.MOBILE_DEVICE:
        setMobileDeviceQueryType();
        break;
      case StudentReportType.DEVICE_INSTALLED:
        setStudentDeviceInstalledQueryType();
        break;
      case StudentReportType.DEVICE_NOT_INSTALLED:
        setStudentDeviceNotInstalledQueryType();
        break;
      default:
        break;
    }
  }, [
    InstId,
    departmentId,
    branchId,
    classId,
    semesterId,
    sectionId,
    categoryId,
    queryType,
    acctLdgrId,
    channelId,
    religionSelected?.value,
    ageValues,
    setGeneralQueryType,
    setSectionAllocationQueryType,
    setNotRegisteredQueryType,
    setEligibleQueryType,
    setLibBookClearanceQueryType,
    setStatusNxtInstId,
    setNonEligibleQueryType,
    setNewAdmissionQueryType,
    setByReligionQueryType,
    setByAgeQueryType,
    setTransportEnabledQueryType,
    setTransportNotEnabledQueryType,
    setTransportNotAssignedQueryType,
    setMobileDeviceQueryType,
    setStudentDeviceInstalledQueryType,
    setStudentDeviceNotInstalledQueryType,
  ]);

  const [GetStudentsData, { data, loading, error, fetchMore, variables }] =
    useLazyQuery<GetStudentsData, vars>(GetStudents, {
      variables: {
        after: null,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        first: rowsPerPage === null ? null : rowsPerPage,
        name,
        status,
        token,
        orderBy: orderBy
          ? orderBy
          : [
              {
                direction: Direction.ASC,
                field: SortBy.FIRST_NAME,
              },
            ],
        input: {
          ids,
          std_query_type,
          str_data: strData ? strData : [religionSelected?.value!],
          int_data: ageValues,
        },
      },
      fetchPolicy: "network-only",
    });
  useEffect(() => {
    if (state.ActiveFinYr && ids[0] > 0 && std_query_type !== EMPTY_STRING) {
      GetStudentsData();
    }
  }, [
    GetStudentsData,
    state.ActiveFinYr,
    ids,
    std_query_type,
    status,
    // rowsPerPage,
    // adm_numbers,
  ]);
  return { StudentsData: { data, loading, error, fetchMore, variables } };
};

export default useStudentsbyNewApi;

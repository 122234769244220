import { gql } from "@apollo/client";

export const studentInfo = gql`
  query studentInfo($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on MstStudent {
        first_name
        inst_id
        dept_id
        branch_id
        section_id
        semester_id
        class_id
        auth_id
      }
    }
  }
`;

export const GetChannelsByInstId = gql`
  query GetChannelsByInstId($token: String!, $inst_id: ID!) {
    GetChannelsByInstId(token: $token, inst_id: $inst_id) {
      channel_desc
      id
      channel_active
      channel_type
      channel_is_student
      channel_name
    }
  }
`;
export const GetChannelMessages = gql`
  query GetChannelMessages(
    $token: String!
    $inst_id: ID!
    $input: ChannelMsgQueryData!
    $before: Cursor
    $after: Cursor
    $first: Int
    $last: Int
    $direction: OrderDirection!
    $sortBy: MsgChannelMessageOrderField
    $mediaContent: String
  ) {
    GetChannelMessages(
      token: $token
      inst_id: $inst_id
      input: $input
      before: $before
      after: $after

      first: $first
      last: $last
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ msgContentContainsFold: $mediaContent }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          msg_date
          msg_is_expiry
          msg_expiry_date
          msg_is_text
          msg_content
          msg_header
          msg_media_type
          msg_media_content
          created_at
          sent_user_type
          sent_user_id
          msg_channel_id
        }
        cursor
      }
    }
  }
`;

export const GetStudentDetailsByAuthId = gql`
  query GetStudentDetailsByAuthId($token: String!, $auth_id: ID!) {
    GetStudentDetailsByAuthId(token: $token, auth_id: $auth_id) {
      id
      first_name
      fin_yr_id
      inst_id
    }
  }
`;

export const GetMessageChannel = gql`
  query GetMessageChannel(
    $token: String!
    $inst_id: ID!
    $input: ChannelMsgQueryData!
  ) {
    GetChannelsByInstId(token: $token, inst_id: $inst_id, input: $input) {
      id
      channel_name
      channel_desc
      channel_topic
      channel_is_student
      channel_chat_enabled
      channel_type
      channel_active
      channel_icon_id
      inst_id
    }
  }
`;
export const GetMessageChannelById = gql`
  query GetMessageChannelById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgChannelMaster {
        id
        channel_name
        channel_desc
        channel_topic
        channel_is_student
        channel_chat_enabled
        channel_type
        channel_active
        channel_icon_id
        inst_id
      }
    }
  }
`;
export const GetMessageById = gql`
  query GetMessageById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgChannelMessage {
        id
        msg_date
        msg_is_expiry
        msg_expiry_date
        msg_is_text
        msg_header
        msg_content
        msg_media_type
        msg_media_content
        created_at
        sent_user_type
        sent_user_id
        msg_channel_id
      }
    }
  }
`;

export const GetEduateDetailsByAuthId = gql`
  query GetEduateDetailsByAuthId($token: String!, $auth_id: ID!) {
    GetEduateDetailsByAuthId(token: $token, auth_id: $auth_id) {
      id
      first_name
      middle_name
      last_name
    }
  }
`;

export const GetParentDetailsByAuthId = gql`
  query GetParentDetailsByAuthId($token: String!, $auth_id: ID!) {
    GetParentDetailsByAuthId(token: $token, auth_id: $auth_id) {
      id
      inst_id
    }
  }
`;
export const GetEmployeeByAuthId = gql`
  query GetEmployeeByAuthId($token: String!, $auth_id: ID!) {
    GetEmployeeByAuthId(token: $token, auth_id: $auth_id) {
      id
      emp_first_name
      inst_id
      just_login_acct
      emp_type
    }
  }
`;

export const StudentsCountByChannelId = gql`
  query GetStudentsCountByChannelId($token: String!, $msg_channel_id: ID!) {
    GetStudentsCountByChannelId(token: $token, msg_channel_id: $msg_channel_id)
  }
`;
export const GetChannelSubscribed = gql`
  query GetChannelSubscribed(
    $token: String!
    $device_token: String!
    $inst_id: ID!
    $input: SubscribedUser!
    $channel_type: [String!]!
    $entry_level: String!
    $entry_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetChannelSubscribed(
      token: $token
      device_token: $device_token
      inst_id: $inst_id
      input: $input
      channel_type: $channel_type
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      id
      channel_name
      channel_desc
      channel_topic
      channel_is_student
      channel_chat_enabled
      channel_type
      channel_active
      channel_icon_id
      inst_id
    }
  }
`;

export const GetChannelSubscriptionCount = gql`
  query GetChannelSubscriptionCount($token: String!, $msg_channel_id: ID!) {
    GetChannelSubscriptionCount(token: $token, msg_channel_id: $msg_channel_id)
  }
`;
export const GetMessageDataById = gql`
  query GetMessageDataById($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on AuthEduate {
        first_name
        middle_name
        last_name
      }
      ... on PayRollEmpMaster {
        emp_first_name
        emp_middle_name
        emp_last_name
      }
      ... on MstStudent {
        first_name
        middle_name
        last_name
      }
    }
  }
`;
export const GetChannelStudentIndividual = gql`
  query GetChannelStudentIndividual(
    $token: String!
    $inst_id: ID!
    $student_id: ID!
  ) {
    GetChannelStudentIndividual(
      token: $token
      inst_id: $inst_id
      student_id: $student_id
    ) {
      id
    }
  }
`;

export const GetAssignmentChannelMessages = gql`
  query GetAssignmentChannelMessages(
    $token: String!
    $inst_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $subj_master_id: ID!
    $before: Cursor
    $first: Int
    $last: Int
    $orderBy: MsgChannelMessageOrder
    $mediaContent: String
    $per_std_subj_allocation: Boolean!
  ) {
    GetAssignmentChannelMessages(
      token: $token
      inst_id: $inst_id
      entry_level: $entry_level
      entry_id: $entry_id
      subj_master_id: $subj_master_id
      before: $before
      first: $first
      last: $last
      orderBy: $orderBy
      per_std_subj_allocation: $per_std_subj_allocation
      where: { or: [{ msgMediaContentContainsFold: $mediaContent }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          msg_date
          msg_is_expiry
          msg_expiry_date
          msg_is_text
          msg_content
          msg_header
          msg_media_type
          msg_media_content
          created_at
          sent_user_type
          sent_user_id
          msg_channel_id
        }
        cursor
      }
    }
  }
`;

export const GetAssignmentChannel = gql`
  query GetAssignmentChannel(
    $token: String!
    $inst_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $subj_master_id: ID!
  ) {
    GetAssignmentChannel(
      token: $token
      inst_id: $inst_id
      entry_level: $entry_level
      entry_id: $entry_id
      subj_master_id: $subj_master_id
    ) {
      id
    }
  }
`;

import { TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../stories/Title/Title";
import { LabelNameProps } from "../../Types/Labels";
import {
  Direction,
  Operation,
  SortBy,
  StdEnqQueryType,
} from "../../utils/Enum.types";
import DownArrow from "../../images/DownArrow.svg";
import {
  msgType,
  optionsType,
  responseType,
  StudentEnquiryForScan,
} from "../../utils/Form.types";
import { StudentEnquiryValidationForQrcode } from "../../utils/validationRules";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddStudentEnquiryByScan } from "../../queries/students/mutations/new";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../utils/constants";
import useToken from "../../customhooks/useToken";
import {
  GetStudentEnquires,
  GetStudentEnquiryDashboardMonthCount,
} from "../../queries/students/list/newApi";
import { Keys } from "../../utils/Enum.keys";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
} from "../../utils/UtilFunctions";
import { Button } from "../../stories/Button/Button";
import MessageModal from "../../pages/MessageModal";
import { useNavigate, useParams } from "react-router-dom";
import useActiveAcademicYear from "../Academics/hooks/useActiveAcademicYear";
import dayjs from "dayjs";
import useServerDateandTimeForScan from "../../customhooks/useServerDateForQrScanner";
import { GetBranchesForScanByInstId } from "../../queries/institution/branch/list/byid";
import {
  BranchesListDataByInstIdForScan,
  BranchListVarsByInstIdForScan,
  ClassListDataByBranchIdForScan,
  ClassListVarsBranchIdForScan,
} from "../../Types/Student";
import { GetClassesForScanByBranchIds } from "../../queries/institution/classes/list/byid";
import { GetInstByID } from "../../queries/institution/list/byId";
import { InstitutionNode } from "../../customhooks/useInstitutionsByCustId";
import MobileEnquiry from "../../images/MobileEnquiry.svg";
import EduateImage from "../../images/EduateLogo.svg";
import UpdatedRefresh from "../../images/UpdatedRefresh.svg";
import { GetSwConfigThankYouMsg } from "../../queries/institution/configuration/query/SoftwreConfig";
import { getDownloadUrl } from "../../utils/DownloadFile";
import { Label } from "../../stories/Label/Label";
import { AppContext } from "../../context/context";
import PhoneInput from "react-phone-input-2";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../styles/AutocompleteListStyles";
const { StudentEnquiryFormForScan } = require("../../json/config.json");
const { StudentEnquiryForm } = require("../../json/config.json");

// eslint-disable-next-line
export interface InstDetails {
  GetInstByID: InstitutionNode;
}

const EnquiryDetails = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [captchaText, setCaptchaText] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const [imageString, setImageString] = useState("");

  const [formData, setFormData] = useState<StudentEnquiryForScan>({
    first_name: "",
    middle_name: "",
    last_name: "",
    std_father_name: "",
    std_mother_name: "",
    std_previous_school: "",
    std_previous_place: "",
    std_email: "",
    std_mobile: "+",
    std_sex: "",
    enq_date: "",
  });

  const { state } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [GetConfigData, { data: configData }] = useLazyQuery(
    GetSwConfigThankYouMsg,
    {
      variables: {
        inst_id: InstId!,
      },
    }
  );

  const [updateBranchSelected, setUpdateBranchSelected] =
    useState<responseType | null>(null);
  const [updateclassSelected, setUpdateClassSelected] =
    useState<responseType | null>(null);

  const [gender, setGender] = useState<optionsType | null>(null);
  const [referenceBy, setReferenceBy] = useState("");
  const [otherReference, setOtherReference] = useState("");
  const [nationality, setnationality] = useState("");

  const [AddEnquiry] = useMutation(AddStudentEnquiryByScan, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [branchList, setBranchList] = useState<responseType[]>([]);
  const [classList, setClassList] = useState<responseType[]>([]);

  const [GetBranchByInstId] = useLazyQuery<
    BranchesListDataByInstIdForScan,
    BranchListVarsByInstIdForScan
  >(GetBranchesForScanByInstId, {
    variables: {
      inst_id: InstId!,
    },
  });
  const [GetClassesByBranch] = useLazyQuery<
    ClassListDataByBranchIdForScan,
    ClassListVarsBranchIdForScan
  >(GetClassesForScanByBranchIds, {
    variables: {
      branch_ids: [updateBranchSelected?.value!],
    },
  });
  const [GetInstDetails, { data, loading }] = useLazyQuery<InstDetails>(
    GetInstByID,
    {
      variables: {
        id: InstId,
      },
    }
  );
  useEffect(() => {
    if (data && !loading) {
      const filename = data.GetInstByID;
      if (filename) {
        // eslint-disable-next-line
        const ers = getDownloadUrl(
          data?.GetInstByID.inst_logo_filename,
          false,
          setImageString
        );
      }
    }
  }, [data, loading]);

  const { activeAcademicYearData } = useActiveAcademicYear();

  const ReferenceBy = [
    "Newspaper ads",
    "Flyers",
    "Digital",
    "Parents",
    "Staff",
    "walkin",
    "others",
  ];
  const genderOptions = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
  ];
  const { serverDate } = useServerDateandTimeForScan();

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };
  const handleSubmit = () => {
    if (captchaText !== userInput) {
      setMessage({
        flag: true,
        message: "Captcha do not match , Please Try Again",
        operation: Operation.NONE,
      });
      return;
    }
    if (gender) {
      AddEnquiry({
        variables: {
          token,
          inst_id: InstId,
          input: {
            ...formData,
            first_name: removeMoreSpace(formData.first_name),
            middle_name: removeMoreSpace(formData.middle_name),
            last_name: removeMoreSpace(formData.last_name),
            std_father_name: removeMoreSpace(formData.std_father_name),
            std_mother_name: removeMoreSpace(formData.std_mother_name),
            branch_id: updateBranchSelected?.value,
            class_id: updateclassSelected?.value,
            enq_date: dayjs(formData.enq_date).format(),
            std_sex: gender.value,
            reference_through: referenceBy,
            std_nationality: nationality,
            counselled_by: EMPTY_STRING,
            enq_remarks: EMPTY_STRING,
          },
        },
        refetchQueries: [
          {
            query: GetStudentEnquires,
            variables: {
              name: EMPTY_STRING,
              lastFollowUpStatus: null,
              boughtApplication: null,
              admitted: null,
              deptID: null,
              branchID: null,
              classID: null,
              semesterID: null,
              categoryID: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.FIRST_NAME,
              direction: Direction.ASC,
              today_date: toIsoDate(serverDate),
              token,
              enq_query_type: StdEnqQueryType.STD_ENQ_ALL,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetStudentEnquiryDashboardMonthCount,
            variables: {
              token,
              inst_id: InstId!,
              today_date: toIsoDate(serverDate),
            },
            fetchPolicy: "network-only",
          },
        ],
      })
        .then(({ data }) => {
          if (data) {
            setMessage({
              message: "Student Enquiry Added successfully",
              flag: true,
              operation: Operation.CREATE,
            });
            // handleClear();
            navigate(`/${InstId}/studentenquiry/basicdetails/exit`);
          }
        })
        .catch((error) => {
          // Handle error if necessary
        });
    } else {
      setMessage({
        message: "Please fill the required fields",
        flag: true,
        operation: Operation.NONE,
      });
    }
  };

  const handleClear = () => {
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
      std_father_name: "",
      std_mother_name: "",
      std_previous_school: "",
      std_previous_place: "",
      std_email: "",
      std_mobile: "",
      std_sex: "",
      enq_date: "",
      counselled_by: "",
      enq_remarks: "",
    });
    setUpdateBranchSelected(null);
    setUpdateClassSelected(null);
    setGender(null);
    setReferenceBy(EMPTY_STRING);
    setCaptchaText("");
    setUserInput("");
    setnationality(EMPTY_STRING);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (serverDate) {
      setFormData((prevValues) => ({
        ...prevValues,
        enq_date: toInputStandardDate(serverDate),
      }));
    }
  }, [serverDate]);
  useEffect(() => {
    if (InstId) {
      GetBranchByInstId({
        variables: {
          inst_id: InstId!,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredBranchList = (data.GetBranchesForScanByInstId || [])
            .filter((branch) => branch !== null)
            .map((branch) => ({
              label: branch.branch_desc,
              value: branch.id,
              isChecked: false,
            }));
          setBranchList(filteredBranchList);
        }
      });
    }
  }, [InstId, GetBranchByInstId]);
  useEffect(() => {
    if (InstId && updateBranchSelected?.value) {
      GetClassesByBranch({
        variables: {
          branch_ids: [updateBranchSelected?.value!],
        },
      }).then(({ data }) => {
        if (data) {
          const filteredClassList = (data.GetClassesForScanByBranchIds || [])
            .filter((res) => res !== null)
            .map((res) => ({
              label: res.class_desc,
              value: res.id,
              isChecked: false,
            }));
          setClassList(filteredClassList);
        }
      });
    }
    // eslint-disable-next-line
  }, [InstId, GetClassesByBranch, updateBranchSelected?.value]);
  useEffect(() => {
    if (InstId) {
      GetInstDetails();
    }
  }, [InstId, GetInstDetails]);
  useEffect(() => {
    if (InstId) GetConfigData();
  }, [InstId, GetConfigData]);
  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptchaText(captcha);
    setUserInput("");
  };
  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };
  useEffect(() => {
    generateCaptcha();
  }, []);

  useEffect(() => {
    if (data && !loading) {
      const filename = data.GetInstByID;
      if (filename) {
        // eslint-disable-next-line
        const ers = getDownloadUrl(
          data?.GetInstByID.inst_logo_filename,
          false,
          setImageString
        );
      }
    }
  }, [data, loading]);
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      std_mobile: mobileNo,
    }));
  };
  const dobRef = document.getElementsByName("gender")[0];

  return (
    <div className="std-enquiry-for-all">
      <div className="std-enquiry-for-all__inst-details">
        <div className="std-enquiry-for-all__inst-details--logo">
          <img src={imageString} alt="/" />
        </div>
        <div className="std-enquiry-for-all__inst-details--address">
          <span className="std-enquiry-for-all__inst-details--c-name">
            {data?.GetInstByID.customer_details.cust_name}
          </span>
          <b>{data?.GetInstByID.inst_name}</b>
          <span>{data?.GetInstByID.inst_address}</span>
        </div>
      </div>

      <Formik
        initialValues={formData}
        validationSchema={StudentEnquiryValidationForQrcode}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="std-enquiry-for-all__form">
              <div className="row g-0 std-enquiry-for-all__title">
                <img src={MobileEnquiry} alt="/" />
                <Title>Enquiry Form </Title>
              </div>
              <div className="row g-0 std-enquiry-for-all__form--data">
                <div className="col h-100">
                  {StudentEnquiryFormForScan.Labels.Name.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TextField
                            className={
                              label.LabelName === "First Name" ||
                              label.LabelName === "Father Name"
                                ? "std-enquiry-for-all__form--required"
                                : "std-enquiry-for-all__form--textfield"
                            }
                            label={label.LabelName}
                            name={label.inputName}
                            type={label.dataType}
                            value={formData[label.inputName]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            required={label.required}
                      
                            autoFocus={label.autoFocus}
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                              },
                              input: {
                                inputProps: {
                                  max: label.maxLength,
                                },
                              },
                            }}
                          />
                        </React.Fragment>
                      );
                    }
                  )}

                  <PhoneInput
                    country={"in"}
                    value={formData.std_mobile}
                    onChange={handleNumberChange}
                    inputProps={{
                      required: true,
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        dobRef.focus();
                      }
                    }}
                  />
                  <div className="frame-space"></div>

                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={genderOptions!}
                    value={gender}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setGender(newValue as optionsType);
                      } else {
                        setGender(null);
                      }
                    }}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, gender)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (gender) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setGender(null);
                      }
                    }}
                    openOnFocus
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        className={labelClasses.formControlRoot}
                        label="Gender"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    )}
                  />
                  {StudentEnquiryForm.Labels.OtherDetails.filter(
                    (label: LabelNameProps) => {
                      return label.LabelName !== "Counselled By";
                    }
                  ).map((label: LabelNameProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TextField
                          label={label.LabelName}
                          className="std-enquiry-for-all__form--required"
                          name={label.inputName}
                          type={label.dataType}
                          value={formData[label.inputName]}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          required={label.required}
                          inputProps={{
                            maxLength: label.maxLength,
                          }}
                          autoFocus={label.autoFocus}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          placeholder={label.placeholder}
                        />
                      </React.Fragment>
                    );
                  })}

                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    popupIcon={
                      <img
                        src={DownArrow}
                        alt="/"
                        className="down-arrow-autocomplete"
                      />
                    }
                    options={branchList!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(
                        option as responseType,
                        updateBranchSelected
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (updateBranchSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setUpdateBranchSelected(null);
                        setUpdateClassSelected(null);
                      }
                    }}
                    openOnFocus
                    value={updateBranchSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setUpdateBranchSelected(newValue as responseType);
                      } else {
                        setUpdateBranchSelected(null);
                        setUpdateClassSelected(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required={updateBranchSelected ? false : true}
                        {...params}
                        fullWidth
                        className={labelClasses.formControlRoot}
                        label={
                          configData?.GetSwConfigThankYouMsg?.branch_label!
                        }
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    )}
                  />

                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    popupIcon={
                      <img
                        src={DownArrow}
                        alt="/"
                        className="down-arrow-autocomplete"
                      />
                    }
                    options={classList!}
                    openOnFocus
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(
                        option as responseType,
                        updateclassSelected
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (updateclassSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setUpdateClassSelected(null);
                      }
                    }}
                    value={updateclassSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setUpdateClassSelected(newValue as responseType);
                      } else {
                        setUpdateClassSelected(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={configData?.GetSwConfigThankYouMsg?.class_label!}
                        required={updateclassSelected ? false : true}
                        {...params}
                        className={labelClasses.formControlRoot}
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        fullWidth
                      />
                    )}
                  />

                  <TextField
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label="Nationality"
                    className="std-enquiry-for-all__form--required"
                    type="text"
                    value={nationality}
                    required
                    onChange={(e) => {
                      setnationality(e.target.value);
                    }}
                  />
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={ReferenceBy!}
                    value={referenceBy}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setReferenceBy(newValue as string);
                      } else {
                        setReferenceBy(EMPTY_STRING);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (referenceBy) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setReferenceBy(EMPTY_STRING);
                      }
                    }}
                    openOnFocus
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        label="Reference Through"
                        {...params}
                        fullWidth
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        className={labelClasses.formControlRoot}
                        required
                      />
                    )}
                  />
                  {referenceBy === "others" && (
                    <>
                      <TextField
                        type="text"
                        className="std-enquiry-for-all__form--textfield"
                        fullWidth
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER && !e.shiftKey) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        onChange={(e) => {
                          setOtherReference(e.target.value);
                        }}
                        value={otherReference}
                        label="Other Reference"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    </>
                  )}

                  <Label>Captcha code</Label>
                  <div className="change-password__textfield--captcha">
                    <div className="change-password__textfield--captcha--text">
                      <span>{captchaText}</span>
                    </div>

                    <img
                      src={UpdatedRefresh}
                      alt="/"
                      onClick={generateCaptcha}
                    />
                  </div>

                  <TextField
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label="Enter Captcha Code"
                    className="std-enquiry-for-all__form--required"
                    type="text"
                    value={userInput}
                    required
                    onChange={handleUserInput}
                  />
                </div>
              </div>
              <div className="std-enquiry-for-all__form--buttons ">
                <Button mode="save" type="submit" />
                <Button mode="clear" type="button" onClick={handleClear} />
              </div>
              <div className="std-enquiry-for-all__footer">
                <img src={EduateImage} alt="" />
                Simplified With My-Eduate
              </div>
            </Form>
          );
        }}
      </Formik>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </div>
  );
};
export default EnquiryDetails;

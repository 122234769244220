import { gql } from "@apollo/client";

export const GetPayRollDeptsByInstId = gql`
  query GetPayRollDeptsByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: PayRollDeptOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
  ) {
    GetPayRollDeptsByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ deptDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          dept_desc
          dept_idx
        }
      }
    }
  }
`;

export const GetPayRollDeptIdsByNames = gql`
  query GetPayRollDeptIdsByNames(
    $token: String!
    $inst_id: ID!
    $dept_desc: [String!]
  ) {
    GetPayRollDeptIdsByNames(
      token: $token
      inst_id: $inst_id
      dept_desc: $dept_desc
    ) {
      id
      dept_desc
      dept_idx
    }
  }
`;

export const PayRolldepartmentNode = gql`
  query ($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollDept {
        dept_desc
      }
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { Direction } from "../../../utils/Enum.types";
import { GetUserRights } from "../queries/List";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export interface GetUserRightsedges {
  node: {
    id: number;
    is_inst_sysadmin: boolean;
    pr_emp_details: {
      emp_first_name: string;
      emp_middle_name: string;
      emp_last_name: string;
      emp_email: string;
      emp_mobile: string;
      emp_type: string;
      emp_id: string;
      id: number;
      pr_designation_details: {
        designation_desc: string;
      };
    };
    user_rights_details: {
      inst_module_details: {
        eduate_module_details: {
          Name: string;
        };
      };
    }[];
  };
}
interface GetUserRightsData {
  GetUserRights: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; endCursor: string; startCursor: string };
    edges: GetUserRightsedges[];
  };
}
interface GetUserRightsVars {
  token: string;
  inst_id: String;
  after: string | null;
  first: number;
  orderBy: { direction: Direction };
}
const useUserRights = (first: number) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [GetUsers, { data, loading, error, fetchMore }] = useLazyQuery<
    GetUserRightsData,
    GetUserRightsVars
  >(GetUserRights, {
    variables: {
      after: null,
      first,
      inst_id: InstId!,
      orderBy: { direction: Direction.ASC },
      token,
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (token && InstId) {
      GetUsers();
    }
  }, [token, InstId, GetUsers]);
  return { users: { data, loading, error, fetchMore } };
};

export default useUserRights;

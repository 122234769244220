// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginUser from "../Modules/UserRights/LoginUser/Index";
import ListUsersRightsAssigned from "../Modules/UserRights/AssignRights/List";
import AssignRights from "../Modules/UserRights/AssignRights/Index";
import ResetUserPassword from "../Modules/UserRights/ResetUserPassword";
import PayRollDepartment from "../Modules/HR/Payroll/Department/Index";
import PayRollDesignation from "../Modules/HR/Payroll/Designation/Index";
import OrganizationalChart from "../Modules/HR/OrganizationalCharts/Index";

import Home from "../Modules/UserRights/Home/Index";
import { ComponentFor } from "../Modules/HR/enums/Enum.types";
import { LicenseTypes, PageFor } from "../utils/Enum.types";
import { useState } from "react";
import EnableUserLogin from "../Modules/UserRights/EnableUserLogin";
import EmpImport from "../Modules/UserRights/EmpImport";

const UserRightsRoutes = () => {
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);
  return (
    <Routes>
      <Route path="/loginusers" element={<LoginUser />} />
      <Route path="/assignedlist" element={<ListUsersRightsAssigned />} />
      <Route path="/assignrights" element={<AssignRights />} />
      <Route path="/ResetUserPassword" element={<ResetUserPassword />} />
      <Route path="/enableUserLogin" element={<EnableUserLogin />} />
      <Route path="/empImport" element={<EmpImport />} />

      <Route
        path="/department"
        element={
          <PayRollDepartment
            pageType={PageFor.GENERAL}
            setModalFlag={setModalFlag}
            type={ComponentFor.MASTERS}
            LicenseType={LicenseTypes.USER_RIGHTS}
          />
        }
      />
      <Route
        path="/designation"
        element={
          <PayRollDesignation
            pageType={PageFor.GENERAL}
            setModalFlag={setModalFlag}
            type={ComponentFor.MASTERS}
            LicenseType={LicenseTypes.USER_RIGHTS}
          />
        }
      />
      <Route
        path="/organizationalchart"
        element={<OrganizationalChart LicenseType={LicenseTypes.USER_RIGHTS} />}
      />
      <Route path="/" element={<Home DashBoardRequired />} />
    </Routes>
  );
};

export default UserRightsRoutes;

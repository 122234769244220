import { gql } from "@apollo/client";

export const GetPayRollInstOrgChart = gql`
  query GetPayRollInstOrgChart(
    $token: String!
    $inst_id: ID!
    $parent_id: ID!
  ) {
    GetPayRollInstOrgChart(
      token: $token
      inst_id: $inst_id
      parent_id: $parent_id
    ) {
      org_chart_details {
        id
        pr_dept_id
        pr_designation_id
        parent_id
        name_desc
        child_count
      }
      option_dept
      option_designation
    }
  }
`;

import React, { useContext, useEffect, useState } from "react";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import { TableHeaderProps } from "../../../../../utils/types";
import useAcademicTableJson from "../../../json/useAcademicTable";
import {
  HEADER_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import FormFilled from "../../../../../images/MarksEntryCompleted.svg";

import { TextField } from "@mui/material";
import Avatar from "../../../../../images/Avatar.svg";
import { Title } from "../../../../../stories/Title/Title";
import Compare from "../../../../../images/Compare.svg";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Total from "../../../../../images/TotalStudentsinAdmin.svg";
import { GetAcdFeedbackDetailsReport } from "../../../queries/test/query";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdFeedbackDetailsReportData,
  GetAcdFeedbackDetailsReportVars,
} from "../../../types/subject/Subject";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import { TableHeaders } from "../../../../../utils/Enum.types";
import Eye from "../../../../../images/EyeWhite.svg";
import Remarks from "./Remarks";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { EmpData } from "../../../../HR/hooks/useEmployee";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import { PayRollEmpMasterNodeForLogin } from "../../../../HR/queries/employee/query";
import { GetFormDataByFormId } from "../../../queries/general";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import { toInputStandardDate } from "../../../../../utils/UtilFunctions";
import useAcdSubjectDetails from "../../../hooks/useAcdSubjectDetails";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";
import { GetAcdFeedbackStdRatingCount } from "../../../../../queries/common";
import Close from "../../../../../images/Close.svg";
import { Button } from "../../../../../stories/Button/Button";
import { AttendanceDrawer } from "../../../../../styles/DrawerStyles";
interface Props {
  setViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  subjectId: number;
  entry_id?: number;
}

const DetailedFormModal = ({ setViewModal, subjectId, entry_id }: Props) => {
  const { token } = useToken();
  const { entryId, InstId, formId, empId } = useParams();
  const { state } = useContext(AppContext);
  const [remarks, setRemarks] = useState(false);
  const [ratingId, setRatingId] = useState(0);
  const [ratingQuestion, setRatingQuestion] = useState("");
  const { flag } = useCheckAllocationType(entryId ? Number(entryId) : entry_id);

  const { FeedbackQuestionWise } = useAcademicTableJson();
  const [GetOverAllGraph, { data: OverAllData }] = useLazyQuery<
    GetAcdFeedbackDetailsReportData,
    GetAcdFeedbackDetailsReportVars
  >(GetAcdFeedbackDetailsReport);
  const [GetRatingCount, { data: RatingCountData }] = useLazyQuery(
    GetAcdFeedbackStdRatingCount
  );

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    entry_level,
  } = useInstitutionConfiguration();
  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(entryId ? Number(entryId) : entry_id!);
  const { data: subjectData } = useAcdSubjectDetails(Number(subjectId));
  useEffect(() => {
    if (token && formId && entry_level && flag !== undefined) {
      GetRatingCount({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          entry_level,
          entry_id: entryId ? Number(entryId) : entry_id,
          form_name_id: Number(formId),
          pr_emp_id: empId ? Number(empId) : 0,
          subj_master_id: subjectId ? subjectId : 0,
          perStdSubjAllocation: flag!,
        },
      });
    }
  }, [token, GetRatingCount, empId, formId, subjectId, entry_level, flag]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const dynamicHeaders: TableHeaderProps[] =
    FeedbackQuestionWise.TableHeader.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "action",
      headerName: TableHeaders.ACTION,
      headerAlign: HEADER_TEXT_ALIGN,
      align: "center",
      renderCell: (params) => {
        return (
          <button
            className="combination-report-view__tableblock--view"
            onClick={() => {
              setRemarks(!remarks);
              setRatingId(params.row.rating_id);
              setRatingQuestion(params.row.rating_factor);
            }}
          >
            Remarks <img src={Eye} alt="" />
          </button>
        );
      },
    },
  ];
  const rows: GridValidRowModel[] =
    (OverAllData &&
      OverAllData.GetAcdFeedbackDetailsReport.map((response, index) => {
        return {
          id: index + 1,
          rating_factor: response.fb_details.rating_factor,
          excellent: response.excellent_count,
          good: response.good_count,
          average: response.average_count,
          fair: response.fair_count,
          poor: response.poor_count,
          rating_id: response.fb_form_detail_id,
        };
      })) ||
    [];
  useEffect(() => {
    if (token && formId && entry_level) {
      GetOverAllGraph({
        variables: {
          token,
          inst_id: Number(InstId)!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          pr_emp_id: empId ? Number(empId) : 0,
          form_name_id: Number(formId),
          entry_level,
          entry_id: entryId ? Number(entryId) : entry_id!,
          subj_master_id: subjectId ? subjectId : 0,
        },
      });
    }
  }, [token, GetOverAllGraph, empId, formId, entryId, entry_level, subjectId]);
  const [GetEmpDetailsForLogin, { data: teacherData }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNodeForLogin, {
    variables: {
      id: Number(empId),
      token,
    },
    fetchPolicy: "network-only",
  });

  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);
  useEffect(() => {
    if (empId && token) {
      GetEmpDetailsForLogin();
    }
  }, [empId, GetEmpDetailsForLogin, token]);

  return (
    <>
      <div className="fb-form-view__questionwise--detailed">
        <div className="fb-form-view__questionwise--detailed--title">
          <Title>Detailed View</Title>
          <img src={Close} alt="" onClick={() => setViewModal(false)} />
        </div>

        <div className="row g-0 fb-form-view__detailed--select">
          {USE_DEPARTMENT_KEY && (
            <div className="col-2">
              <TextField
                label={departmentLabel}
                value={
                  configdata
                    ? configdata.GetAcdInstConfigNames.acd_dept_desc
                    : EMPTY_STRING
                }
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                className="daily-activities__textfield"
              />
            </div>
          )}
          {USE_BRANCH_KEY && (
            <div className="col-2">
              <TextField
                label={branchLabel}
                value={
                  configdata
                    ? configdata.GetAcdInstConfigNames.acd_branch_desc
                    : EMPTY_STRING
                }
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                className="daily-activities__textfield"
              />
            </div>
          )}
          {USE_CLASS_KEY &&
            configdata?.GetAcdInstConfigNames.acd_class_desc && (
              <div className="col-2">
                <TextField
                  label={classLabel}
                  value={configdata.GetAcdInstConfigNames.acd_class_desc}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  className="daily-activities__textfield"
                />
              </div>
            )}
          {USE_SEMESTER_KEY &&
            configdata?.GetAcdInstConfigNames.acd_semester_desc && (
              <div className="col-2">
                <TextField
                  label={semesterLabel}
                  value={configdata.GetAcdInstConfigNames.acd_semester_desc}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  className="daily-activities__textfield"
                />
              </div>
            )}
          {USE_SECTION_KEY &&
            configdata?.GetAcdInstConfigNames.acd_section_desc && (
              <div className="col-2">
                <TextField
                  label={sectionLabel}
                  value={configdata.GetAcdInstConfigNames.acd_section_desc}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  className="daily-activities__textfield"
                />
              </div>
            )}
        </div>
        <div className="fb-form-view__title row g-0">
          <div
            className={
              formData && formData.node.is_academic
                ? "col-5 fb-form-view__title--left"
                : "fb-form-view__title--non-acd"
            }
          >
            <b>{formData && formData.node.form_name}</b>
            <span className="fb-form-view__title--acd">
              {formData && formData.node.is_academic
                ? "Academic"
                : "Non-Academic"}
            </span>
          </div>
          {empId && (
            <div className="col fb-form-view__questionwise--emp">
              <div className="fb-form-view__questionwise--emp--flex">
                <img src={Avatar} alt="" />
                <div className="fb-form-view__questionwise--emp--block">
                  <b>
                    {teacherData &&
                      teacherData.node.emp_first_name +
                        " " +
                        teacherData.node.emp_middle_name +
                        " " +
                        teacherData.node.emp_last_name}
                  </b>
                  <span> {teacherData && teacherData.node.emp_id}</span>
                </div>
              </div>
            </div>
          )}
          <div className="col-4">
            <div className="fb-form-view__title--flex">
              <TextField
                className="fb-form-report__datablock--grid--textfield"
                label="Start Date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={
                  formData && toInputStandardDate(formData.node.form_st_date)
                }
              />
              <img src={Compare} alt="" />
              <TextField
                className="fb-form-report__datablock--grid--textfield"
                label="End Date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={
                  formData && toInputStandardDate(formData.node.form_end_date)
                }
              />
            </div>
          </div>
        </div>
        <div className="fb-form-view__subtitle">
          <b className="font-blue">
            {subjectData && subjectData.node.subj_desc}
          </b>
          <span className="font-grey">
            {subjectData && subjectData.node.subj_code}
          </span>
          <Title variant="subtitle1">Questionwise List</Title>
        </div>
        <div className="fb-form-view__cards">
          <div className="fb-form-view__card">
            <img src={Total} alt="" />
            <span>Total Students</span>
            <b className="font-blue">
              {RatingCountData &&
                RatingCountData.GetAcdFeedbackStdRatingCount.total_std_count}
            </b>
          </div>
          <div className="fb-form-view__card">
            <img src={FormFilled} alt="" />
            <span>Students Form Filled</span>
            <b className="font-green">
              {RatingCountData &&
                RatingCountData.GetAcdFeedbackStdRatingCount.form_filled_count}
            </b>
          </div>
        </div>
        <div className={`fb-form-view__questionwise--detailed--table`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
          />
        </div>
        <Button mode="cancel" onClick={() => setViewModal(false)} />
        {/* <Button mode="view" onClick={() => setView(!view)}>
              Not Filled Students
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} /> */}
      </div>

      <AttendanceDrawer
        anchor="right"
        open={remarks}
        onClose={() => setRemarks(!remarks)}
      >
        <Remarks
          setModalFlag={setRemarks}
          subjectId={subjectId}
          ratingId={ratingId}
          ratingQuestion={ratingQuestion}
          entry_id={entry_id}
        />
      </AttendanceDrawer>
    </>
  );
};

export default DetailedFormModal;

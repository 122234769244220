import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { optionsType } from "../../../utils/Form.types";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { PredefinedDataTypes } from "../../../utils/Enum.types";
import { isOptionEqualToValue } from "../../../utils/UtilFunctions";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
interface Props {
  type: PredefinedDataTypes;
  value: string;
  onChange: (newValue: optionsType | null, type: PredefinedDataTypes) => void;
  label: string;
  disabled?: boolean;
}
const PredefinedAutocomplete = ({
  type,
  value,
  onChange,
  label,
  disabled,
}: Props) => {
  const [searchData, setSearchData] = useState(value);

  const [autoValue, setAutoValue] = useState<optionsType | null>(null);

  const {
    PredefinedData: { dropDown, data, handleScroll },
  } = usePredefinedDataByType(type, searchData);

  useEffect(() => {
    if (searchData && value) {
      const foundValue = dropDown.find(
        ({ value: optionsValue }) => optionsValue === value
      );

      setAutoValue(foundValue ? foundValue : null);
    }
  }, [searchData, value, data, dropDown]);

  const commonAutocompleteProps = {
    className: label ? labelClasses.inputRoot : formClasses.inputRoot,
    options: dropDown,
    openOnFocus: true,
    ListboxProps: {
      onScroll: handleScroll,
    },
    disabled: disabled,
    onChange: (e: React.SyntheticEvent<Element, Event>, value: unknown) =>
      onChange(value as optionsType, type),
  };

  const commonTextFieldProps = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchData(e.target.value),
    label: label || "",
    fullWidth: true,
    InputLabelProps: { shrink: label.length > 0 },
    className: label
      ? labelClasses.formControlRoot
      : formClasses.formControlRoot,
  };

  const Autocomplete =
    label.length > 0 ? LabeledAutocomplete : FormAutocomplete;
  return (
    <Autocomplete
      {...commonAutocompleteProps}
      {...(value ? { value: autoValue } : {})} // Conditional value
      isOptionEqualToValue={(option) =>
        isOptionEqualToValue(option as optionsType, autoValue)
      }
      renderInput={(params) => (
        <TextField {...params} {...commonTextFieldProps} />
      )}
    />
  );
};

export default PredefinedAutocomplete;

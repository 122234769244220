import { gql } from "@apollo/client";

export const GetMstInstModules = gql`
  query GetMstInstModules(
    $token: String!
    $inst_id: ID!
    $query_type: InstModuleQueryType!
  ) {
    GetMstInstModules(
      token: $token
      inst_id: $inst_id
      query_type: $query_type
    ) {
      id
      lic_type
      lic_active
      lic_exp_grace_period
      lic_enabled_date
      lic_start_date
      lic_end_date
      inst_module_details {
        id
        is_module_enabled
        is_module_enabled_for_mobile
        eduate_module_details {
          id
          Name
        }
      }
    }
  }
`;
export const InstModuleById = gql`
  query InstModuleById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstInstModuleMaster {
        id
        lic_type
        lic_active
        lic_exp_grace_period
        lic_enabled_date
        lic_start_date
        lic_end_date
        inst_module_details {
          id
          is_module_enabled
          eduate_module_details {
            Name
          }
        }
      }
    }
  }
`;
export const GetMstInstActiveModules = gql`
  query GetMstInstActiveModules($token: String!, $inst_id: ID!) {
    GetMstInstActiveModules(token: $token, inst_id: $inst_id) {
      id
      is_module_enabled
      is_module_enabled_for_mobile
      inst_module_master_id
      eduate_module_details {
        Name
        id
      }
    }
  }
`;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import {
  FormControlLabel,
  TextField,
} from "@mui/material";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../../utils/constants";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import Avatar from "../../../../../images/Avatar.svg";
import { Button } from "../../../../../stories/Button/Button";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import Posting from "../../../../../images/Pending_Student_Posting.svg";
import Compare from "../../../../../images/Compare.svg";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import { Keys } from "../../../../../utils/Enum.keys";
import { AppContext } from "../../../../../context/context";
import useActiveFinancialYear from "../../../../Accounts/hooks/useActiveFinancialYear";
import { Label } from "../../../../../stories/Label/Label";
import {
  Direction,
  Operation,
  SortBy,
  StdStatusQueryType,
  StudentStatus,
} from "../../../../../utils/Enum.types";
import { msgType, optionsType, responseType } from "../../../../../utils/Form.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ChangeStudentStatusToNE,
  StudentNEStatusCancel,
} from "../../../../../queries/students/mutations/update";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import {
  GetStudentNEActiveByStudentId,
  GetStudentsNeByInstId,
} from "../../../../../queries/students/list/byId";
import {
  GetStudents,
  GetStudentsAdmno,
} from "../../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../../utils/studentqueryEnum.types";
import TextArea from "../../../../../stories/TextArea/TextArea";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { studentNeData, studentNeVars } from "../../../../../Types/Student";
import MessageModal from "../../../../../pages/MessageModal";
import { AcctFinYrPostingForStudent } from "../../../../Accounts/queries/FinYearPost/mutation";

import LoadingModal from "../../../../../pages/LoadingModal";
import { GetFinYrsFuture } from "../../../../../queries/institution/financialyear/list/byid";
import { GetStudentDetails } from "../../../../../queries/common";
import { studentStatusDropDown } from "../../../../Accounts/Reports/BalanceReports/Reports";
import { AntSwitch } from "../../../../../pages/Switch";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { labelClasses, LabeledAutocomplete } from "../../../../../styles/AutocompleteListStyles";
import { RequiredAutoComplete } from "../../../../../styles/AutocompleteStyles";
import { AttendanceDrawer } from "../../../../../styles/DrawerStyles";
export interface FinYrType {
  label: string;
  value: number;
}
interface GetFinYrsFutureList {
  id: number;
  fin_yr: string;
  fin_st_date: string;
  fin_end_date: string;
  fin_is_curr_yr: boolean;
  inst_id: number | string;
}
interface GetFinYrsFutureData {
  GetFinYrsFuture: GetFinYrsFutureList[];
}
interface GetFinYrsFutureVars {
  token: string;
  inst_id: number | string;
  fin_yr_id: number;
}
interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudentInfo = ({ setModalFlag }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { studentFormData, studentData } = useStudentDatabyId();
  const [imageString, setImageString] = useState("");
  const { classLabel, branchLabel } = useInstLabels();
  const { state } = useContext(AppContext);
  const { InstDetails } = useInstDetails(1);
  const [selectedAcdYr, setSelectedAcdYr] = useState<FinYrType | null>(null);
  const acdYearRef = useRef<HTMLSelectElement>(null);
  const [status, setStatus] = useState<optionsType | null>(null);
  const [finYearFlag, setFinYearFlag] = useState(true);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [remarks, setRemarks] = useState("");

  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const [statusStdModal, setStatusStdModal] = useState(false);
  const { serverDate } = useServerDateandTime();

  const statusRef = useRef<HTMLSelectElement>(null);
  // const statusInputRef = statusRef?.current?.childNodes[0]?.childNodes[0]
  //   ?.childNodes[0] as HTMLInputElement;

  const [GetFutureFinYears, { data: FinYears }] = useLazyQuery<
    GetFinYrsFutureData,
    GetFinYrsFutureVars
  >(GetFinYrsFuture);

  const [GetStudentNeData, { data }] = useLazyQuery<
    studentNeData,
    studentNeVars
  >(GetStudentNEActiveByStudentId, {
    variables: { token, studentId: state.studentId },
  });
  const finYearDropDown =
    FinYears &&
    FinYears.GetFinYrsFuture.map((res) => ({
      label: res.fin_yr,
      value: res.id,
    }));
  const [UpdateStudentStatus, { loading: updationloading }] = useMutation(
    ChangeStudentStatusToNE,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [CancelNEstudentStatus, { loading }] = useMutation(
    StudentNEStatusCancel,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [FinYearPost, { loading: FinYearLoading }] = useMutation(
    AcctFinYrPostingForStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handlePostingForStudent = () => {
    FinYearPost({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        from_fin_yr_id: state.ActiveFinYr?.id,
        to_fin_yr_id: selectedAcdYr ? selectedAcdYr.value : 0,
        student_id: state.studentId,
        post_prev_fin: finYearFlag,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,

            input: {
              ids: [Number(InstId)],
              std_query_type: studentQueryTypes.BY_INST_ID,
              str_data: [null],
            },
            name: "",
            orderBy: [
              {
                direction: Direction.ASC,
                field: SortBy.FIRST_NAME,
              },
            ],
            status: "",
            token,
          },
        },
      ],
    }).then(({ data }) => {
      // handleClear();
      if (data)
        setMessage({
          message: "Financial Year Posting Successfull",
          flag: true,
          operation: Operation.CREATE,
        });
    });
  };
  const handleUpdate = () => {
    if (status && status.label !== "Eligible") {
      UpdateStudentStatus({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          input: {
            std_still_ne: false,
            std_ne_type: status.value,
            std_ne_reason: remarks,
            std_ne_date: toIsoDate(TODAY_DATE),
            std_ne_acd_year:
              studentData.data && studentData.data.nodes[0].acd_yr.acd_yr,
            std_ne_made_by_staff_id: state.empLoginId,
            student_id: studentData.data?.nodes[0].id,
            inst_id: InstId,
          },
        },
        refetchQueries: [
          {
            query: GetStudentsNeByInstId,
            variables: {
              token,
              inst_id: InstId!,
              query_type: StdStatusQueryType.NE_STATUS_FALSE,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              after: null,
              orderBy: SortBy.STD_NE_DATE,
              direction: Direction.ASC,
            },
          },
          {
            query: GetStudentDetails,
            variables: { token, ids: [state.studentId] },
          },
          {
            query: GetStudentsAdmno,
            variables: {
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)!],
                std_query_type: studentQueryTypes.STATUS_EQ_CUR_BY_INST_ID,
              },
            },
          },
          {
            query: GetStudentsAdmno,
            variables: {
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)!],
                std_query_type: studentQueryTypes.STATUS_NEQ_CUR_BY_INST_ID,
              },
            },
          },
        ],
      }).then(({ data }) => {
        // handleClear();
        if (data)
          setMessage({
            message: "Successfully made Student Not Eligible",
            flag: true,
            operation: Operation.CREATE,
          });
      });

      // handleClear();
    } else {
      CancelNEstudentStatus({
        variables: {
          token,
          id: data ? data.GetStudentNEActiveByStudentId.id : 0,
          inst_id: InstId!,
          user_details,
          input: {
            std_still_ne: true,
            std_ne_type: StudentStatus.CUR,
            std_ne_cancel_reason: remarks,
            std_ne_cancel_date: toIsoDate(serverDate),
            std_ne_cancel_acd_year:
              studentData.data && studentData.data.nodes[0].acd_yr.acd_yr,
            std_ne_cancel_made_by_staff_id: state.empLoginId,
          },
        },
        refetchQueries: [
          {
            query: GetStudentsNeByInstId,
            variables: {
              token,
              inst_id: InstId!,
              query_type: StdStatusQueryType.NE_STATUS_TRUE,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              after: null,
              orderBy: SortBy.STD_NE_DATE,
              direction: Direction.ASC,
            },
          },
          {
            query: GetStudentDetails,
            variables: { token, ids: [state.studentId] },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Successfully made Student Eligible",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
      // handleClear();
    }
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      statusStdModal ? setStatusStdModal(!statusStdModal) : setModalFlag(false);
      setStatus(null);
      setRemarks(EMPTY_STRING);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (state.studentId && token) {
      GetStudentNeData();
    }
  }, [GetStudentNeData, token, state.studentId]);
  useEffect(() => {
    if (token && InstId && state.ActiveFinYr) {
      GetFutureFinYears({
        variables: {
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      });
    }
  }, [GetFutureFinYears, InstId, state.ActiveFinYr, token]);
  const renderTextField = (label: string, value: string) => (
    <TextField
      label={label}
      value={value}
      className="pending-data-std-posting__std-info--textfield"
      disabled
             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
    />
  );

  return (
    <>
      <div className="pending-data-std-posting__std-info">
        <div className="pending-data-std-posting__std-info--title">
          <Title>Student Info for Posting</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="pending-data-std-posting__std-info--select row g-0">
          <div className="col pending-data-std-posting__std-info--row-span">
            {renderTextField("Adm no.", studentFormData.adm_no)}
            {renderTextField("Reg No.", studentFormData.reg_number)}
            {renderTextField(`${branchLabel}`, studentFormData.branch)}
            {renderTextField(
              "Name",
              `${studentFormData.first_name} ${studentFormData.middle_name} ${studentFormData.last_name}`
            )}
            {renderTextField("Father Name", studentFormData.father_name)}
            {renderTextField(`${classLabel}`, studentFormData.class)}
          </div>
          <div className="col-3 student-profile">
            {imageString ? (
              <img src={imageString} alt="/" className="" />
            ) : (
              <img src={Avatar} alt="/" />
            )}
          </div>
        </div>

        <h6 className="pending-data-std-posting__std-info--sub-title">
          Postings Permissible Only in the Subsequent Years
        </h6>
        <div className="pending-data-std-posting__std-info--data">
          <h4>Finacial Year Posting</h4>
          <div className="row g-0 justify-content-center">
            <TextField
              label="Current Financial Year"
              className="pending-data-std-posting__std-info--year"
              disabled
              value={
                ActiveFinancicalYear.data &&
                ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_yr
              }
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              type="year"
            />
          </div>
          <div className="pending-data-std-posting__std-info--flex">
            <Label>Carry Fee Outstanding Balances</Label>
            <FormControlLabel
              label=""
              labelPlacement="start"
              control={
                <AntSwitch
                  checked={finYearFlag}
                  onClick={() => setFinYearFlag(!finYearFlag)}
                />
              }
            />
          </div>
          <div className="pending-data-std-posting__std-info--flex">
            <TextField
              label="Posting from"
              className="pending-data-std-posting__std-info--year"
              disabled
              value={state.ActiveFinYr ? state.ActiveFinYr.fin_yr : 0}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            />
            <img src={Compare} alt="" />
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              ref={acdYearRef!}
              options={finYearDropDown ? finYearDropDown : []}
              value={selectedAcdYr}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedAcdYr(newValue as responseType);
                } else {
                  setSelectedAcdYr(null);
                }
              }}
              // isOptionEqualToValue={(option) =>
              //   selectedAcdYr &&
              //   option.value.startDate === selectedAcdYr.value.startDate &&
              //   option.value.endDate === selectedAcdYr.value.endDate
              //     ? true
              //     : false
              // }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (selectedAcdYr) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSelectedAcdYr(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=" To Financial Year"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>

          {/* <div className="pending-data-std-posting__std-info--class-info">
            <div className="pending-data-std-posting__std-info--class-info--from">
              <b>Posting from {classLabel}</b>
              <span>TE First Year</span>
            </div>
            <img src={Transfer} alt="" />
            <div className="pending-data-std-posting__std-info--class-info--to">
              <b>Posting from {classLabel}</b>
              <span>TE First Year</span>
            </div>
          </div> */}
          {/* <div className="pending-data-std-posting__std-info--balance">
            <div>
              <b>Balance</b>
              <span>&nbsp;(Balance will Be Carried Forward as OB)</span>
            </div>
            <b className="font-red">2000</b>
          </div> */}
          <div className="pending-data-std-posting__std-info--status">
            <b>Status</b>
            <div>
              <Button>
                {state.studentId && studentData.data
                  ? studentData.data.nodes[0].std_status
                  : EMPTY_STRING}
              </Button>
              <label onClick={() => setStatusStdModal(!statusStdModal)}>
                Change Status to Next Financial Year
              </label>
            </div>
          </div>
          <div className="pending-data-std-posting__std-info--button">
            <Button onClick={handlePostingForStudent}>
              <img src={Posting} alt="" />
              Post Financial Year & Save
            </Button>
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>

      <AttendanceDrawer
        anchor="right"
        open={statusStdModal}
        onClose={() => setStatusStdModal(!statusStdModal)}
      >
        <Title>Change Student Status</Title>
        <div>
          <div className="label-grid">
            <Label> Student Status</Label>
            <RequiredAutoComplete
              className={labelClasses.inputRoot}
              options={studentStatusDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as optionsType, status)
              }
              ref={statusRef!}
              value={status}
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatus(newValue as optionsType);
                } else {
                  setStatus(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  // remarksRef?.current?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setStatus(null);
                }
              }}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  className={labelClasses.formControlRoot}
                  {...params}
                  fullWidth
                  required
                />
              )}
            />
          </div>
          <div className="label-grid">
            <Label>Remarks</Label>
            <TextArea
              // textAreaRef={remarksRef!}
              value={remarks}
              // onKeyDown={(e: React.KeyboardEvent) => {
              //   if (e.key === Keys.ENTER && !e.shiftKey) {
              //     e.preventDefault();
              //     eligiblePagetype === StudentReportType.ELIGIBLE
              //       ? eligibleRef?.current?.focus()
              //       : saveRef?.current?.focus();
              //   }
              // }}
              required
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
              rows={3}
            />
          </div>
          <Button mode="save" onClick={handleUpdate} />
          <Button
            mode="cancel"
            onClick={() => setStatusStdModal(!statusStdModal)}
          />
        </div>
      </AttendanceDrawer>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={FinYearLoading || loading || updationloading} />
    </>
  );
};

export default StudentInfo;

import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";

import DepartmentImage from "../../../images/department.svg";

import CloseImg from "../../../images/Close.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";

import TreeView from "./TreeView";
import { Button } from "../../../stories/Button/Button";
import useMstInstTree from "../../../customhooks/useMstInstTree";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { PageFor } from "../../../utils/Enum.types";
import { useNavigate } from "react-router-dom";
import Home from "../Home/Index";
interface tableType2 {
  level_name: string;
  level: string;
  description: string;
  fresher_count: number;
  existing_count: number;
  total_count: number;
  boys_count: number;
  girls_count: number;
  id: number;
}

interface keyPairType {
  [key: string]: number;
}
interface level_ids extends keyPairType {
  level1: number;
  level2: number;
  level3: number;
  level4: number;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: PageFor;
}
const StdSummary = ({ setModalFlag, pageType }: Props) => {
  const navigate = useNavigate();
  const [treeViewModal, setTreeViewModal] = useState(false);
  const [levelId, setLevelId] = useState(0);

  const { data, loading } = useMstInstTree();
  const { entry_level } = useInstitutionConfiguration();
  const [table, setTable] = useState<tableType2[]>([]);
  const [parent, setParent] = useState(0);
  const [level_ids, setLevelIds] = useState<level_ids>({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });

  //Students Total Count
  // eslint-disable-next-line
  let totalFreshersCount = 0;
  // eslint-disable-next-line
  let totalExistingCount = 0;
  // eslint-disable-next-line
  let totalStudentCount = 0;

  let totalBoysCount = 0;
  let totalGirlsCount = 0;
  // eslint-disable-next-line
  table.map((data) => {
    totalFreshersCount += data.fresher_count;
    totalExistingCount += data.existing_count;
    totalStudentCount += data.total_count;
    totalBoysCount += data.boys_count;
    totalGirlsCount += data.girls_count;
  });

  const setNextLevel = (id: number, step: number) => {
    if (data) {
      switch (step) {
        case 0:
          setTable(
            data.GetMstInstTree.map((tree_data) => ({
              level_name: tree_data.level_1_name,
              level: tree_data.level_1,
              description: tree_data.level_1_desc,
              fresher_count: tree_data.fresher_count,
              existing_count: tree_data.existing_count,
              id: tree_data.level_1_id,
              total_count: tree_data.total_count,
              girls_count: tree_data.girls_count,
              boys_count: tree_data.boys_count,
            }))
          );
          setLevelIds({
            level1: 0,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 1:
          setTable(
            data.GetMstInstTree.filter(
              ({ level_1_id }) => level_1_id === id
            ).flatMap((level1_data) => {
              const { level_2_details } = level1_data;
              return level_2_details.flatMap((level_2) => {
                return {
                  level_name: level_2.level_2_name,
                  level: level_2.level_2,
                  description: level_2.level_2_desc,
                  fresher_count: level_2.fresher_count,
                  existing_count: level_2.existing_count,
                  boys_count: level_2.boys_count,
                  girls_count: level_2.girls_count,
                  total_count: level_2.total_count,
                  id: level_2.level_2_id,
                };
              });
            })
          );
          setLevelIds({
            level1: id,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 2:
          const level_2_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0].level_2_details.filter(({ level_2_id }) => level_2_id === id);
          if (level_2_tree[0].level_3_details) {
            setTable(
              level_2_tree.flatMap((level2_data) => {
                const { level_3_details } = level2_data;
                return level_3_details.flatMap((level_3) => {
                  return {
                    level_name: level_3.level_3_name,
                    level: level_3.level_3,
                    description: level_3.level_3_desc,
                    fresher_count: level_3.fresher_count,
                    existing_count: level_3.existing_count,
                    boys_count: level_3.boys_count,
                    girls_count: level_3.girls_count,
                    total_count: level_3.total_count,
                    id: level_3.level_3_id,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: id,
              level3: 0,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 3:
          const level_3_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(({ level_3_id }) => level_3_id === id);

          if (level_3_tree[0].level_4_details) {
            setTable(
              level_3_tree.flatMap((level3_data) => {
                const { level_4_details } = level3_data;
                return level_4_details.flatMap((level_4) => {
                  return {
                    level_name: level_4.level_4_name,
                    level: level_4.level_4,
                    description: level_4.level_4_desc,
                    fresher_count: level_4.fresher_count,
                    existing_count: level_4.existing_count,
                    boys_count: level_4.boys_count,
                    girls_count: level_4.girls_count,
                    total_count: level_4.total_count,
                    id: level_4.level_4_id,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: id,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 4:
          const level_4_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(
              ({ level_3_id }) => level_3_id === level_ids.level3
            )[0]
            .level_4_details.filter(({ level_4_id }) => level_4_id === id);

          if (level_4_tree[0]?.level_5_details) {
            setTable(
              level_4_tree.flatMap((level_4_data) => {
                const { level_5_details } = level_4_data;
                return level_5_details.flatMap((level_5) => {
                  return {
                    level_name: level_5.level_5_name,
                    level: level_5.level_5,

                    description: level_5.level_5_desc,
                    fresher_count: level_5.fresher_count,
                    existing_count: level_5.existing_count,
                    boys_count: level_5.boys_count,
                    girls_count: level_5.girls_count,
                    total_count: level_5.total_count,
                    id: level_5.level_5_id,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: level_ids.level3,
              level4: id,
            });
            setParent(step);
          }

          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (data && !loading) {
      setTable(
        data.GetMstInstTree.map((tree_data) => ({
          level_name: tree_data.level_1_name,
          level: tree_data.level_1,
          description: tree_data.level_1_desc,
          fresher_count: tree_data.fresher_count,
          existing_count: tree_data.existing_count,
          id: tree_data.level_1_id,
          total_count: tree_data.total_count,
          boys_count: tree_data.boys_count,
          girls_count: tree_data.girls_count,
        }))
      );
    }
  }, [data, loading]);

  return (
    <>
      {pageType === PageFor.GENERAL ? (
        <>
          <Home DashBoardRequired={false} />
          <Title>Student Count</Title>
        </>
      ) : (
        <Title>Student Summary (Fresher/ Existing)</Title>
      )}
      <div
        className={
          pageType === PageFor.MODAL
            ? "masters-dashboard__demand-summary__modal"
            : "masters-dashboard__demand-summary"
        }
      >
        <div className="masters-dashboard__demand-summary__breadcrumbs"></div>
        <div className="masters-dashboard__demand-summary__tableblock">
          <TableContainer className="masters-dashboard__demand-summary--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <img
                      src={DepartmentImage}
                      alt="/"
                      className="academics-table-logo"
                    />
                  </TableCell>

                  <TableCell>
                    {table.length && table[0].level_name} Name
                  </TableCell>
                  <TableCell>Boys</TableCell>
                  <TableCell>Girls</TableCell>
                  <TableCell>Fresher</TableCell>
                  <TableCell>Existing</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table?.map((data, index: number) => {
                  return (
                    <TableRow
                      onClick={() => {
                        if (data.level === entry_level) {
                          setTreeViewModal(!treeViewModal);
                          setLevelId(data.id);
                        } else {
                          setNextLevel(data.id, parent + 1);
                        }
                      }}
                    >
                      <TableCell
                        id="td-center"
                        className="masters-dashboard__demand-summary--table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="masters-dashboard__demand-summary--table--desc">
                        {data?.description}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="masters-dashboard__demand-summary--table--count"
                      >
                        {data.boys_count}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="masters-dashboard__demand-summary--table--count"
                      >
                        {data.girls_count}
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className="masters-dashboard__demand-summary--table--count"
                      >
                        {data.fresher_count}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="masters-dashboard__demand-summary--table--count"
                      >
                        {data.existing_count}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="masters-dashboard__demand-summary--table--count"
                      >
                        {data.total_count}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell className="total" colSpan={2}>
                    Total:
                  </TableCell>
                  <TableCell className="totalcount">{totalBoysCount}</TableCell>
                  <TableCell className="totalcount">
                    {totalGirlsCount}
                  </TableCell>
                  <TableCell className="totalcount">
                    {totalFreshersCount}
                  </TableCell>
                  <TableCell className="totalcount">
                    {totalExistingCount}
                  </TableCell>
                  <TableCell className="totalcount">
                    {totalStudentCount}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="previous"
              onClick={() => {
                const position = parent - 1;
                const key = `level${position}`;
                setNextLevel(level_ids[key], parent - 1);
              }}
            />
            {pageType === PageFor.GENERAL ? (
              <Button mode="back" onClick={() => navigate(-1)} />
            ) : (
              <Button mode="cancel" onClick={() => setModalFlag(false)} />
            )}
          </div>
        </div>
      </div>

      {/* TreeView-modal */}
      <Modal
        isOpen={treeViewModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TreeView
              levelId={levelId}
              setTreeViewModal={setTreeViewModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setTreeViewModal(!treeViewModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StdSummary;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { GetAcdStdOverallPerformance } from "../queries/students/Index";
import useActiveAcademicYear from "./useActiveAcademicYear";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { colorsInGraph } from "../../../styles/Constants";
import { TEXT_FONT_FAMILY } from "../../../styles/DataGridTableStyles";
import useCheckAllocationType from "./useCheckAllocationType";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
interface GetAcdStdOverallPerformanceVars {
  token: string;
  inst_id: string | number;
  acd_yr_id: number;
  student_id: number;
  per_std_subj_allocation: boolean;
}
interface GetAcdStdOverallPerformanceData {
  GetAcdStdOverallPerformance: {
    subj_name_1: string;
    subj_name_2: string;
    subj_name_3: string;
    subj_name_4: string;
    subj_name_5: string;
    subj_name_6: string;
    subj_name_7: string;
    subj_name_8: string;
    subj_name_9: string;
    subj_name_10: string;
    subj_name_11: string;
    subj_name_12: string;
    subj_name_13: string;
    subj_name_14: string;
    subj_name_15: string;
    subj_percentage_1: number;
    subj_percentage_2: number;
    subj_percentage_3: number;
    subj_percentage_4: number;
    subj_percentage_5: number;
    subj_percentage_6: number;
    subj_percentage_7: number;
    subj_percentage_8: number;
    subj_percentage_9: number;
    subj_percentage_10: number;
    subj_percentage_11: number;
    subj_percentage_12: number;
    subj_percentage_13: number;
    subj_percentage_14: number;
    subj_percentage_15: number;
    test_top_percentage: number;
    test_avg_percentage: number;
    [key: string]: number | string;
  };
}
const useStudentOverAllPerformance = () => {
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { studentEntryId } = useStudentDatabyId();
  const { flag } = useCheckAllocationType(studentEntryId);
  const [GetOverAllPerformance, { data, loading, error }] = useLazyQuery<
    GetAcdStdOverallPerformanceData,
    GetAcdStdOverallPerformanceVars
  >(GetAcdStdOverallPerformance, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId ?? state.InstId,
      student_id: state.studentId,
      token,
      per_std_subj_allocation: flag ? flag : false,
    },
  });

  const scores: {
    name: string;
    marks: number;
  }[] = new Array(15).fill(0).reduce((acc, _, index) => {
    const { GetAcdStdOverallPerformance } = data || {};
    if (GetAcdStdOverallPerformance?.[`subj_name_${index}`]) {
      acc.push({
        name: GetAcdStdOverallPerformance?.[`subj_name_${index}`],
        marks: GetAcdStdOverallPerformance?.[`subj_percentage_${index}`],
      });
    }
    return acc;
  }, []);

  const graphData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        crossStyle: {
          color: "#999",
        },
      },
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },
    toolbox: {
      feature: {
        dataView: {
          show: false,
          readOnly: false,
        },
        magicType: { show: true, type: ["line", "bar", "piechart"] },
        saveAsImage: { show: true },
      },
    },
    legend: {
      show: true,
      data: scores.map((value) => value.name),
      textStyle: {
        color: "black",
        fontSize: 12,
        fontFamily: TEXT_FONT_FAMILY,
      },
      icon: "rect",
      bottom: 10,
      width: "90%",
      type: "scroll",
    },
    xAxis: [
      {
        type: "category",
        data: scores.map((value) => value.name),
        axisPointer: {
          type: "shadow",
        },
        textStyle: {
          color: "black",
          fontSize: 14,
          fontFamily: TEXT_FONT_FAMILY,
        },
        axisLabel: {
          rotate: -25,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "Percentage",
        min: 0,
        max: 100,
        interval: 25,
        axisLabel: {
          formatter: "{value}%",
        },
        textStyle: {
          color: "black",
          fontSize: 12,
          fontFamily: TEXT_FONT_FAMILY,
        },
      },
    ],
    series: [
      {
        data: scores.map((value, index) => ({
          value: value.marks,
          itemStyle: { color: colorsInGraph[index] },
        })),
        markLine: {
          data: [
            {
              type: "average",
              name: "Avg",
              yAxis: data?.GetAcdStdOverallPerformance.test_avg_percentage,
            },
            {
              type: "max",
              name: "Max",
              yAxis: data?.GetAcdStdOverallPerformance.test_top_percentage,
            },
          ],
        },
        type: "bar",
        barWidth: 20,
      },
    ],
  };
  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      token &&
      state.studentId &&
      state.ActiveAcdYr
    ) {
      GetOverAllPerformance();
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.studentId,
    state.InstId,
    token,
    GetOverAllPerformance,
    state.ActiveAcdYr,
    flag,
  ]);

  return { data, loading, error, graphData, studentScores: scores };
};

export default useStudentOverAllPerformance;

import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LicenseTypes, Operation } from "../../../utils/Enum.types";

import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { TableHeaderProps } from "../../../Types/Tables";
import EditProfile from "../../../images/EditProfile.svg";

import Delete from "../../../images/Delete.svg";
import useToken from "../../../customhooks/useToken";

import AddCampus from "../Campus/Index";
import {
  DeleteCampusDetails,
  GetCampus,
} from "../../../queries/Campus/mutations/new";
import { GetCampusData, GetCampusVars } from "./Types";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import { msgType } from "../../../utils/Form.types";
import LoadingModal from "../../../pages/LoadingModal";
import Active from "../../../images/ActiveRoute.svg";
import { EMPTY_STRING } from "../../../utils/constants";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../json/useMasterTableJson";
import { CampusEntryDrawer } from "../../../styles/DrawerStyles";

const InstitutionList = () => {
  const navigate = useNavigate();
  const { custId } = useParams<{ custId: string }>();
  const { token } = useToken();
  const { Masters_Table } = useMasterTableJson();

  const { dispatch } = useContext(AppContext);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [campusModal, setCampusModal] = useState(false);
  const [campusupdateModal, setCampusUpdateModal] = useState(false);

  const [GetCampusDetails, { data, loading, error }] = useLazyQuery<
    GetCampusData,
    GetCampusVars
  >(GetCampus, {
    variables: {
      token,
      customer_id: Number(custId),
    },
    fetchPolicy: "network-only",
  });

  const [DeleteCampus] = useMutation(DeleteCampusDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleDelete = (id: Number) => {
    DeleteCampus({
      variables: {
        token,
        campus_id: id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetCampus,
          variables: {
            token,
            customer_id: Number(custId),
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Campus Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(!deleteModal);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token) GetCampusDetails();
  }, [token, GetCampusDetails]);
  if (error) return <>{error.message}</>;

  return (
    <>
      <Home DashBoardRequired={false} NavType={LicenseTypes.EDUATE_CUSTOMER} />
      <Title>Campus List</Title>
      <div className="campus-list">
        <div className="row g-0 justify-content-end">
          <div className="col-2">
            <Button
              autoFocus
              mode="addnew"
              className="campus-list__button"
              onClick={() => setCampusModal(!campusModal)}
            />
          </div>
        </div>
        <div className="campus-list__tableblock">
          {data && data.GetCampus.length === 0 ? (
            <b className="nodata">Sorry, no results.</b>
          ) : (
            <TableContainer className="campus-list__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Masters_Table.InstitutionsUnderCampus.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <TableCell className={th.className}>
                              {th.labelName}
                            </TableCell>
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.GetCampus.map((response, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="campus-list__table--slno">
                          {index + 1}
                        </TableCell>
                        <TableCell>{response.campus_name}</TableCell>
                        <TableCell className="campus-list__table--primary">
                          <img src={Active} alt="/" />
                          {response.assoicated_insts.find(
                            ({ is_primary_inst }) => is_primary_inst
                          )
                            ? response.assoicated_insts.find(
                                ({ is_primary_inst }) => is_primary_inst
                              )?.inst_details.inst_name
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell className="campus-list__table--place">
                          <ul>
                            {response.assoicated_insts.map((res, resIndex) => (
                              <li>
                                {resIndex + 1}.&nbsp;
                                {res.inst_details.inst_name}
                              </li>
                            ))}
                          </ul>
                        </TableCell>

                        <TableCell
                          id="td-center"
                          className="campus-list__table--action">
                          <img
                            src={EditProfile}
                            alt="/"
                            onClick={() => {
                              setCampusUpdateModal(!campusupdateModal);
                              dispatch({
                                type: payloadTypes.SET_CAMPUS_ID,
                                payload: {
                                  campusId: response.id,
                                },
                              });
                            }}
                          />
                          <img
                            src={Delete}
                            onClick={() => {
                              setDeleteId(response.id);
                              setDeleteModal(!deleteModal);
                            }}
                            alt="\"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <CampusEntryDrawer
     
        anchor="right"
        open={campusModal}
        onClose={() => setCampusModal(!campusModal)}>
        <AddCampus
          operation={Operation.CREATE}
          setCampusModal={setCampusModal}
        />
      </CampusEntryDrawer>
      <CampusEntryDrawer
       
        anchor="right"
        open={campusupdateModal}
        onClose={() => setCampusUpdateModal(!campusupdateModal)}>
        <AddCampus
          operation={Operation.UPDATE}
          setCampusUpdateModal={setCampusUpdateModal}
        />
      </CampusEntryDrawer>

      <LoadingModal flag={loading} />
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default InstitutionList;

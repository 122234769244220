// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Index from "../Modules/Channel/FeedsAnnouncementsTabs";

const ChatRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      {/* <Route path="/:channelId/channel" element={<MessageList />} /> */}
    </Routes>
  );
};

export default ChatRoutes;

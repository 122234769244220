import React from "react";
import Close from "../../../../../../images/Close.svg";
import Topper from "../../../../../../images/MakeAsGlobalUser.svg";
import SecondTopper from "../../../../../../images/SecondTopper.svg";
import ThirdTopper from "../../../../../../images/ThirdTopper.svg";
import { Button } from "../../../../../../stories/Button/Button";
import { TextField } from "@mui/material";
import useInstLabels from "../../../../../../customhooks/general/useInstLabels";
import { ToppersType } from "../../../../../../utils/Enum.types";
import { responseType } from "../../../../../../utils/Form.types";
import { useParams } from "react-router-dom";
import LoadingModal from "../../../../../../pages/LoadingModal";
import { Title } from "../../../../../../stories/Title/Title";
import useAcdTestPerformance from "../../../../hooks/useAcdTestPerformance";
import { EMPTY_STRING } from "../../../../../../utils/constants";
interface props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: ToppersType;
  departmentSelected: responseType | null;
  branchSelected: responseType | null;
  classSelected: responseType | null;
  semesterSelected: responseType | null;
  sectionSelected: responseType | null;
}
const MultipleToppersList = ({
  setModal,
  pageType,
  departmentSelected,
  branchSelected,
  classSelected,
  semesterSelected,
  sectionSelected,
}: props) => {
  const { branchLabel, classLabel } = useInstLabels();
  const { testId } = useParams();

  
  const { data: TreeData, loading } = useAcdTestPerformance(
    departmentSelected ? Number(departmentSelected.value) : 0,
    branchSelected ? Number(branchSelected.value) : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    testId ? Number(testId) : 0
  );
  const firstRankInfo = TreeData
  ? TreeData.GetAcdTestPerformance.first_rank_info.map((res) => res)
  : EMPTY_STRING;
const secondRankInfo = TreeData
  ? TreeData.GetAcdTestPerformance.second_rank_info.map((res) => res)
  : EMPTY_STRING;
const thirdRankInfo = TreeData
  ? TreeData.GetAcdTestPerformance.third_rank_info.map((res) => res)
  : EMPTY_STRING;

  return (
    <>
      <div className="test-wise-view__multiple">
        <div className="test-wise-view__multiple--title">
          <Title>Multiple Toppers List</Title>
          <img src={Close} alt="" onClick={()=>setModal(false)}/>
        </div>
        <div className="test-wise-view__multiple--select row g-0">
          <div className="col-4">
            <TextField
              disabled
              className="test-wise-view__multiple--select--textfield"
              label={branchLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={branchSelected?.label}
            />
          </div>
          <div className="col-4">
            <TextField
              disabled
              className="test-wise-view__multiple--select--textfield"
              label={classLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={classSelected?.label}
            />
          </div>
        </div>
        <div className="test-wise-view__multiple--grade">
          {pageType === ToppersType.FIRST_RANK ? (
            <img src={Topper} alt="" />
          ) : pageType === ToppersType.SECOND_RANK ? (
            <img src={SecondTopper} alt="" />
          ) : (
            <img src={ThirdTopper} alt="" />
          )}

          <span>
            {pageType === ToppersType.FIRST_RANK
              ? "1st Place"
              : pageType === ToppersType.SECOND_RANK
              ? "2nd Place"
              : "3rd Place"}
          </span>
          <b>   {pageType === ToppersType.FIRST_RANK
              ?<>
              {firstRankInfo && firstRankInfo[0].percent_marks_scored}%</>
              : pageType === ToppersType.SECOND_RANK
              ? <>
              {secondRankInfo && secondRankInfo[0].percent_marks_scored}%</>
              : <>
              {thirdRankInfo && thirdRankInfo[0].percent_marks_scored}%</>}</b>
        </div>
        <ul className="test-wise-view__multiple--ul">
          {pageType === ToppersType.FIRST_RANK ? (
            <>
              {firstRankInfo
                ? firstRankInfo.map((std, outerIndex) =>
                     {

                      return (
                        <li key={outerIndex}>
                          <span>{outerIndex + 1}</span>
                          <img src={Topper} alt="" />
                          <div className="test-wise-view__multiple--ul--std-details">
                            <b>
                              {std.first_name +
                                " " +
                                std.middle_name +
                                " " +
                                std.last_name}
                            </b>
                            <span>{std.std_adm_no}</span>
                            <span>{std.entry_level_name}</span>
                          </div>
                        </li>
                      );
                    }
                  )
                : null}
            </>
          ) : pageType === ToppersType.SECOND_RANK ? (
            <>
                {secondRankInfo
                ? secondRankInfo.map((std, index) =>
                     {
                    

                      return (
                        <li key={ index}>
                          <span>{index + 1}</span>
                          <img src={Topper} alt="" />
                          <div className="test-wise-view__multiple--ul--std-details">
                            <b>
                              {std.first_name +
                                " " +
                                std.middle_name +
                                " " +
                                std.last_name}
                            </b>
                            <span>{std.std_adm_no}</span>
                            <span>{std.entry_level_name}</span>
                          </div>
                        </li>
                      );
                    }
                  )
                : null}
            </>
          ) : (
            <>
                {thirdRankInfo
                ? thirdRankInfo.map((std, outerIndex) =>
                    {

                      return (
                        <li key={outerIndex }>
                          <span>{outerIndex + 1}</span>
                          <img src={Topper} alt="" />
                          <div className="test-wise-view__multiple--ul--std-details">
                            <b>
                              {std.first_name +
                                " " +
                                std.middle_name +
                                " " +
                                std.last_name}
                            </b>
                            <span>{std.std_adm_no}</span>
                            <span>{std.entry_level_name}</span>
                          </div>
                        </li>
                      );
                    })
                  
                : null}
            </>
          )}
        </ul>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <LoadingModal flag={loading} />

    </>
  );
};

export default MultipleToppersList;

import React, { useState } from "react";
import Topper from "../../../../../../images/MakeAsGlobalUser.svg";
import SecondTopper from "../../../../../../images/SecondTopper.svg";
import ThirdTopper from "../../../../../../images/ThirdTopper.svg";
import Avatar from "../../../../../../images/Avatar.svg";
import Ranks from "../../../../../../images/Ranks.svg";
import useAcdTestPerformance from "../../../../hooks/useAcdTestPerformance";
import { responseType } from "../../../../../../utils/Form.types";
import { useParams } from "react-router-dom";
import { EMPTY_STRING } from "../../../../../../utils/constants";
import { CustomModal } from "../../View";
import { ToppersType } from "../../../../../../utils/Enum.types";
import LoadingModal from "../../../../../../pages/LoadingModal";
import MultipleToppersList from "./MultipleToppersList";

interface Props {
  departmentSelected: responseType | null;
  branchSelected: responseType | null;
  classSelected: responseType | null;
  semesterSelected: responseType | null;
  sectionSelected: responseType | null;
}
const ToppersListClassWise = ({
  departmentSelected,
  branchSelected,
  classSelected,
  semesterSelected,
  sectionSelected,
}: Props) => {
  const { testId } = useParams();

  const [firstRankToppers, setFirstRankToppers] = useState(false);
  const [secondRankToppers, setSecondRankToppers] = useState(false);
  const [thirdRankToppers, setThirdRankToppers] = useState(false);

  const { data, loading } = useAcdTestPerformance(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    testId ? Number(testId) : 0
  );

  const firstRankInfo = data
    ? data.GetAcdTestPerformance.first_rank_info.map((res) => res)
    : EMPTY_STRING;
  const secondRankInfo = data
    ? data.GetAcdTestPerformance.second_rank_info.map((res) => res)
    : EMPTY_STRING;
  const thirdRankInfo = data
    ? data.GetAcdTestPerformance.third_rank_info.map((res) => res)
    : EMPTY_STRING;

  return (
    <>
      <div className="class-wise-rank__toppers--view">
        <h6>Toppers</h6>
        <img
          className="class-wise-rank__toppers--view--topper"
          src={Ranks}
          alt=""
        />
        <ul className="class-wise-rank__toppers--view--ul">
          <li>
            <div className="class-wise-rank__toppers--view--l">
              <span>1</span>
              <img src={Topper} alt="" />
              <img src={Avatar} alt="" />
              <div className="class-wise-rank__toppers--view--l--name">
                <b>
                  {firstRankInfo && firstRankInfo.length > 0
                    ? firstRankInfo
                        .slice(0, 3)
                        .map(
                          (res) =>
                            `${res.first_name} ${res.middle_name} ${res.last_name}`
                        )
                        .join(", ")
                    : EMPTY_STRING}
                </b>
                <span>
                  {firstRankInfo && firstRankInfo.length > 0
                    ? firstRankInfo
                        .slice(0, 3)
                        .map((res) => `${res.std_adm_no}`)
                        .join(", ")
                    : EMPTY_STRING}
                </span>
                {firstRankInfo.length > 3 ? (
                  <span
                    className="font-blue"
                    onClick={() => setFirstRankToppers(!firstRankToppers)}
                  >
                    + {firstRankInfo.length - 3} More
                  </span>
                ) : null}
              </div>
            </div>

            <b className="class-wise-rank__toppers--view--perc">
              {firstRankInfo &&
                firstRankInfo.length &&
                firstRankInfo[0] &&
                firstRankInfo[0].percent_marks_scored !== undefined &&
                `${firstRankInfo[0].percent_marks_scored}%`}
            </b>
          </li>
          <li>
            <div className="class-wise-rank__toppers--view--l">
              <span>2</span>
              <img src={SecondTopper} alt="" />
              <img src={Avatar} alt="" />
              <div className="class-wise-rank__toppers--view--l--name">
                <b>
                  {secondRankInfo && secondRankInfo.length > 0
                    ? secondRankInfo
                        .slice(0, 3)
                        .map(
                          (res) =>
                            `${res.first_name} ${res.middle_name} ${res.last_name}`
                        )
                        .join(", ")
                    : EMPTY_STRING}
                </b>
                <span>
                  {secondRankInfo && secondRankInfo.length > 0
                    ? secondRankInfo
                        .slice(0, 3)
                        .map((res) => `${res.std_adm_no}`)
                        .join(", ")
                    : EMPTY_STRING}
                </span>
                {secondRankInfo.length > 2 ? (
                  <span
                    className="font-blue"
                    onClick={() => setSecondRankToppers(!secondRankToppers)}
                  >
                    + {secondRankInfo.length - 3} More
                  </span>
                ) : null}
              </div>
            </div>

            <b className="class-wise-rank__toppers--view--perc">
              {secondRankInfo &&
                secondRankInfo.length &&
                secondRankInfo[0] &&
                secondRankInfo[0].percent_marks_scored !== undefined &&
                `${secondRankInfo[0].percent_marks_scored}%`}
            </b>
          </li>
          <li>
            <div className="class-wise-rank__toppers--view--l">
              <span>3</span>
              <img src={ThirdTopper} alt="" />
              <img src={Avatar} alt="" />
              <div className="class-wise-rank__toppers--view--l--name">
                <b>
                  {thirdRankInfo && thirdRankInfo.length > 0
                    ? thirdRankInfo
                        .slice(0, 3)
                        .map(
                          (res) =>
                            `${res.first_name} ${res.middle_name} ${res.last_name}`
                        )
                        .join(", ")
                    : EMPTY_STRING}
                </b>
                <span>
                  {thirdRankInfo && thirdRankInfo.length > 0
                    ? thirdRankInfo
                        .slice(0, 3)
                        .map((res) => `${res.std_adm_no}`)
                        .join(", ")
                    : EMPTY_STRING}
                </span>
                {thirdRankInfo.length > 3 ? (
                  <span
                    className="font-blue"
                    onClick={() => setThirdRankToppers(!thirdRankToppers)}
                  >
                    + {thirdRankInfo.length - 3} More
                  </span>
                ) : null}
              </div>
            </div>

            <b className="class-wise-rank__toppers--view--perc">
              {thirdRankInfo &&
                thirdRankInfo.length &&
                thirdRankInfo[0] &&
                thirdRankInfo[0].percent_marks_scored !== undefined &&
                `${thirdRankInfo[0].percent_marks_scored}%`}
            </b>
          </li>
        </ul>
      </div>
      <CustomModal
        isOpen={firstRankToppers}
        onRequestClose={() => setFirstRankToppers(!firstRankToppers)}
        contentComponent={
          <MultipleToppersList
            setModal={setFirstRankToppers}
            pageType={ToppersType.FIRST_RANK}
            departmentSelected={departmentSelected}
            branchSelected={branchSelected}
            classSelected={classSelected}
            semesterSelected={semesterSelected}
            sectionSelected={sectionSelected}
          />
        }
      />
      <CustomModal
        isOpen={secondRankToppers}
        onRequestClose={() => setSecondRankToppers(!secondRankToppers)}
        contentComponent={
          <MultipleToppersList
            setModal={setSecondRankToppers}
            pageType={ToppersType.SECOND_RANK}
            departmentSelected={departmentSelected}
            branchSelected={branchSelected}
            classSelected={classSelected}
            semesterSelected={semesterSelected}
            sectionSelected={sectionSelected}
          />
        }
      />
      <CustomModal
        isOpen={thirdRankToppers}
        onRequestClose={() => setThirdRankToppers(!thirdRankToppers)}
        contentComponent={
          <MultipleToppersList
            setModal={setThirdRankToppers}
            pageType={ToppersType.THIRD_RANK}
            departmentSelected={departmentSelected}
            branchSelected={branchSelected}
            classSelected={classSelected}
            semesterSelected={semesterSelected}
            sectionSelected={sectionSelected}
          />
        }
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default ToppersListClassWise;

import React from "react";
import { Title } from "../../../stories/Title/Title";

const Index = () => {
  return (
    <>
      <Title>VMS Dashboard</Title>
    </>
  );
};

export default Index;

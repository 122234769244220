import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import {  TextField } from "@mui/material";
import Input from ".././../../../stories/Input/Input";
import { DeviceType } from "../../../../utils/Enum.types";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import Home from "../../Home/Index";
import { Keys } from "../../../../utils/Enum.keys";

import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import useEmployee, {
  empQueryTypes,
  PayRollEmpEdges,
} from "../../../HR/hooks/useEmployee";
import Device from "../../../../images/Installed_Devices.svg";

import { AccountentType } from "../../../Accounts/common/Enum.types";
import useHRtable from "../../../HR/json/useHRtable";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const FacultyDevice = () => {
  const navigate = useNavigate();
  const { HR_Table } = useHRtable();

  const [statusSelected, setStatusSelected] = useState<string>("");


  const [searchData, setSearchData] = useState("");
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.EMP_BY_TYPE,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0,
    [AccountentType.TEACHER],
    EMPTY_STRING
  );

  const clear = () => {
    setSearchData("");
  };

  const dynamicHeaders: TableHeaderProps[] = HR_Table.LoginUser?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "no_of_devices",
      headerName: "No Of Devices Installed",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <>
            <img
              src={Device}
              alt="/"
              className="faculty-device-details__tableblock--image"
            />
            {params.row.no_of_devices}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (employee) => employee.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            no_of_devices: 0,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            no_of_devices: 0,
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetPayRollEmp.edges;
              const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetPayRollEmp: {
                  edges: [...employees, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Faculty Device Details</Title>
      <div className="faculty-device-details">
        <form className="faculty-device-details__select row g-0">
          <div className="col-2">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={Object.keys(DeviceType)}
              openOnFocus
              value={statusSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatusSelected(newValue as string);
                } else {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-1 flex-end">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
            />
          </div>
        </form>

        <div
          className={`faculty-device-details__tableblock `}
        >
          {!loading && !data?.GetPayRollEmp.edges.length ? (
            <b className="nodata">Sorry, no results.</b>
          ) : (
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              hideFooter
          
              // onCellClick={(params:GridCellParams)=> {if(params.field === "std_name"){

              // }}}
            />
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 flex-end">
            <div className="student-total-count">
              Total Students: &nbsp;<b>{data?.GetPayRollEmp.totalCount!}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacultyDevice;

import React from "react";

import { months } from "../../../../../utils/UtilFunctions";
import ReactEcharts from "echarts-for-react";

import useAttendanceDashboardGraphData from "../hooks/useAttendanceDashboardGraphData";
const MarkedAtt = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const datesArray = Array.from({ length: daysInMonth }, (_, i) => {
    const date = i + 1;
    return `${date}/${months[currentMonth].slice(0, 3)}`;
  });
  const { DashBoardGraphData } = useAttendanceDashboardGraphData();

  const GraphData =
    DashBoardGraphData && DashBoardGraphData.GetAttDashBoardGraphData;

  const seriesData = datesArray.map((date, index) => {
    const key = `marked_percent_day_${index + 1}`;
    return GraphData ? GraphData[key] : null;
  });

  const option = {
    xAxis: {
      type: "category",
      data: datesArray,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      interval: 30,
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    series: [
      {
        data: seriesData,
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: "#38bdf8",
          width: 1,
        },
        areaStyle: {
          color: "#e0f2fe",
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
      },
    ],
  };

  return (
    <>
      <ReactEcharts option={option} />
    </>
  );
};

export default MarkedAtt;

import { useLazyQuery, useMutation } from "@apollo/client";

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateSwConfigStudentDataFieldEnable } from "../../../../queries/institution/configuration/mutation";
import { GetSwConfigStudentDataEntryByInstId } from "../../../../queries/institution/configuration/query";
import { Button } from "../../../../stories/Button/Button";

import { Title } from "../../../../stories/Title/Title";
import {
  GetSwConfigStudentDataEntryByInstIdData,
  GetSwConfigStudentDataEntryByInstIdVars,
} from "../../../../Types/Student/Configuration";
import { ConfigurationTitleProps } from "../../../../Types/Titles";
import Home from "../../../Master/configurations/Home/Index";

import LoadingModal from "../../../../pages/LoadingModal";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const { Configuration } = require("../../../../json/title.json");
export interface StudentSwConfigType {
  data_field_default_used: string;
  data_field_type: string;
  data_field_enable: boolean;
  data_field_index_order: number;
  data_field_is_list_data: boolean;
  data_field_label: string;
  data_field_list_data_key: string;
  data_field_name: string;
  data_used_in_table: string;
  id: number;
  inst_id: number;
}

interface Props {
  pageType: PageFor;
  setStudentSwDataForModal?: React.Dispatch<
    React.SetStateAction<StudentSwConfigType[]>
  >;
  studentSwDataForModal?: StudentSwConfigType[];
}
const Index = ({
  pageType,
  setStudentSwDataForModal,
  studentSwDataForModal,
}: Props) => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [studentPersonalSwConfigData, setStudentSwPersonalConfigData] =
    useState<StudentSwConfigType[]>([]);
  const [GetStudentSwConfig] = useLazyQuery<
    GetSwConfigStudentDataEntryByInstIdData,
    GetSwConfigStudentDataEntryByInstIdVars
  >(GetSwConfigStudentDataEntryByInstId);
  const [UpdateStudentPageConfig, { loading: updationLoading }] = useMutation(
    UpdateSwConfigStudentDataFieldEnable,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleConfigValueSelected = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempClass = (
        pageType === PageFor.GENERAL
          ? studentPersonalSwConfigData
          : studentSwDataForModal
      )?.map((user) => {
        return { ...user, data_field_enable: checked };
      })!;
      if (pageType === PageFor.GENERAL) {
        setStudentSwPersonalConfigData(tempClass);
      } else {
        setStudentSwDataForModal?.(tempClass);
      }
    } else {
      let tempClass = (
        pageType === PageFor.GENERAL
          ? studentPersonalSwConfigData
          : studentSwDataForModal
      )?.map((user) =>
        user.data_field_label === name
          ? { ...user, data_field_enable: checked }
          : user
      )!;
      if (pageType === PageFor.GENERAL) {
        setStudentSwPersonalConfigData(tempClass);
      } else {
        setStudentSwDataForModal?.(tempClass);
      }
    }
  };

  const handleStudentSwConfig = () => {
    UpdateStudentPageConfig({
      variables: {
        token,
        input: studentPersonalSwConfigData.map(
          ({ data_field_name, data_field_enable }) => ({
            data_field_name,
            data_field_enable,
            inst_id: InstId!,
          })
        ),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwConfigStudentDataEntryByInstId,
          variables: {
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Student Configuration updated successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    GetStudentSwConfig({
      variables: {
        inst_id: InstId!,
        token,
      },
    }).then(({ data }) => {
      if (data) {
        if (pageType === PageFor.GENERAL) {
          setStudentSwPersonalConfigData(
            data?.GetSwConfigStudentDataEntryByInstId!
          );
        } else {
          setStudentSwDataForModal?.(
            data?.GetSwConfigStudentDataEntryByInstId?.filter(
              (res) => res.data_field_enable
            )
          );
        }
      }
    }); // eslint-disable-next-line
  }, [InstId, token, GetStudentSwConfig]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {Configuration.Titles.map(
          (title: ConfigurationTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Student}</React.Fragment>;
          }
        )}
      </Title>
      <div className="select-all">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="allSelect"
                className="select-all--checkbox"
                checked={
                  pageType === PageFor.GENERAL
                    ? !studentPersonalSwConfigData
                    : !studentSwDataForModal?.some(
                        (user) => user?.data_field_enable !== true
                      )
                }
                onChange={handleConfigValueSelected}
              />
            }
            label="All Select"
          />
        </FormGroup>
      </div>
      <div
        className={
          pageType === PageFor.MODAL
            ? "student-software-config__modal"
            : "student-software-config"
        }
      >
        <div className="student-software-config__data">
          {(pageType === PageFor.GENERAL
            ? studentPersonalSwConfigData
            : studentSwDataForModal
          )
            ?.filter(({ data_field_label }) => data_field_label !== "STS No.")
            ?.map((label, index) => {
              return (
                <FormGroup className="select-all__checkboxes" key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={label.data_field_label}
                        checked={label.data_field_enable}
                        onChange={handleConfigValueSelected}
                      />
                    }
                    label={
                      <>
                        &nbsp; {index + 1}) &nbsp;{label.data_field_label}
                      </>
                    }
                  />
                </FormGroup>
              );
            })}
        </div>
      </div>
      <div className="student-software-config__buttons">
        {pageType === PageFor.GENERAL && (
          <>
            <Button mode="save" onClick={handleStudentSwConfig} />
            <Button mode="back" onClick={() => navigate(-1)} />
          </>
        )}
      </div>
      <LoadingModal flag={updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

//React Imports
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
//apollo
import { useLazyQuery } from "@apollo/client";
//Material ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

//HTML Hocs
import { Title } from "../../../../stories/Title/Title";

//Queries
import { GetLibBookIssued } from "../../../../queries/Library/BookIssue/list/byIds";
//custom Hooks
import useToken from "../../../../customhooks/useToken";

import {
  DEFAULT_TIME,
  LIBRARY_ROWS_PER_PAGE,
} from "../../../../utils/constants";

import {
  getDueDate,
  GetNoOfDaysDelayed,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

//Types
import {
  Direction,
  LibBookIssueQueryType,
  LibraryConfigKey,
  SortBy,
  UserType,
} from "../../../../utils/Enum.types";
import { BookTransactionTitleProps } from "../../../../Types/Titles";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  GetLibBookIssuedData,
  GetLibBookIssuedVars,
} from "../../../../Types/Library/BookIssue/paginationTypes";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useLibraryTableJson from "../../json/useLibraryTableJson";

//Jsons
const { Library } = require("../../../../json/title.json");
interface props {
  id: number;
  userType: UserType;
}
const BookIssueList = ({ id, userType }: props) => {
  //Params
  const { InstId } = useParams();
  //cutom Hooks
  const { token } = useToken();
  const { Library_Table } = useLibraryTableJson();

  const [GetBookIssuedData, { data, error }] = useLazyQuery<
    GetLibBookIssuedData,
    GetLibBookIssuedVars
  >(GetLibBookIssued, {
    variables: {
      first: LIBRARY_ROWS_PER_PAGE,
      after: null,
      input: {
        book_issue_query_type:
          userType === UserType.EMPLOYEE
            ? LibBookIssueQueryType.BOOKS_ISSUED_BY_STAFF_ID
            : LibBookIssueQueryType.BOOKS_ISSUED_BY_STD_ID,
        book_issue_from_date: toIsoDate(DEFAULT_TIME),
        book_issue_to_date: toIsoDate(DEFAULT_TIME),
        ids: [Number(InstId), id],
      },
      sortBy: SortBy.ISSUE_DATE,
      direction: Direction.ASC,
      token,
    },
  });
  //custom Hooks

  const { configData } = useSwConfigData(
    LibraryConfigKey.LIB_BOOK_NO_OF_BORROWING_DAYS
  );
  const { config_integer_value: DELAY_DAYS_LIMIT } =
    configData.data?.GetSwConfigVariables[0] || {};
  useEffect(() => {
    if (token) {
      GetBookIssuedData();
    }
  }, [token, GetBookIssuedData]);
  return (
    <>
      <Title>
        {Library.BookTransaction.BookIssue.Titles.map(
          (title: BookTransactionTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.IssuedBook}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="library-report__issued-books--tableblock">
        {error ? (
          <>{error?.message}</>
        ) : (
          <TableContainer className="library-report__issued-books--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Library_Table.BookTransaction.IssuedBooks.Table_Headers.map(
                    (label: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={label.className}>
                          {label.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.GetLibBookIssued.edges.map(({ node }, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="book-issue__tableblock--books-table--accno">
                          {node.book_master.book_access_no}
                        </TableCell>
                        <TableCell>
                          {node.book_master.classification.classification_desc}
                        </TableCell>
                        <TableCell className="book-issue__tableblock--books-table--publication">
                          {
                            node.book_master.classification.publisher
                              .publisher_name
                          }
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--date"
                        >
                          {toStandardDate(node.issue_date)}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--date"
                        >
                          {toStandardDate(node.issue_return_date)}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--date"
                        >
                          {GetNoOfDaysDelayed(
                            getDueDate(node.issue_date, DELAY_DAYS_LIMIT!)
                          )}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className={
                            node?.issue_return_fine_amt === "0.00"
                              ? "font-green book-issue__tableblock--books-table--amount"
                              : "font-red book-issue__tableblock--books-table--amount"
                          }
                        >
                          {node?.issue_return_fine_amt}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default BookIssueList;

import { gql } from "@apollo/client";

export const UpdateMstInstReferenceDataById = gql`
  mutation UpdateMstInstReferenceDataById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMstInstReferenceDataInput!
  ) {
    UpdateMstInstReferenceDataById(
      token: $token
      input: $input
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import Modal from "react-modal";
import { useLazyQuery, useMutation } from "@apollo/client";

import Home from "../Home/Index";
import Close from "../../../../images/Close.svg";
import { toStandardCase } from "../../../../utils/UtilFunctions";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
  GlobalPageConfigData,
  PageSoftwareDetailsVars,
  PageSwData,
} from "../../../../Types/configtypes";
import { PageConfigurationTitleProps } from "../../../../Types/Titles";
import { AddSwConfigVariablesPageAssoci } from "../../../../queries/institution/configuration/mutation";
import {
  GetSwConfigPageInfo,
  GetSwConfigVariables,
  GetSwConfigVariablesPageAssociByPageNumber,
} from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { Select } from "../../../../stories/Select/Select";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import usePageDetailsByPageNumber from "../../../../customhooks/usePageDetailsByPageNumber";
import Reorder from "./ReorderPageConfig";
import { ConfigurationsModalStyles } from "../../../../styles/ModalStyles";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const { Page_Configuration } = require("../../../../json/title.json");

const PageConfiguration = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [GetConfigData, { data, loading }] = useLazyQuery<
    PageSwData,
    PageSoftwareDetailsVars
  >(GetSwConfigPageInfo, {
    variables: { token },
  });

  const [pageNumber, setPageNumber] = useState("");
  const [options, setOptions] = useState<GlobalPageConfigData[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [configurationModal, setConfigurationModal] = useState(false);

  const [GetSwDetails] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables);

  const pageNumberInfo = data?.GetSwConfigPageInfo?.find(
    ({ id }) => id === Number(pageNumber)
  );

  const {
    data: pageNumberData,
    GetSwConfigVariablesPageAssociByPageNumberLoading,
  } = usePageDetailsByPageNumber(pageNumberInfo?.page_number!);

  useEffect(() => {
    if (InstId && token) {
      GetSwDetails({
        variables: {
          token,
          inst_id: InstId,
          input: {
            config_query_type: SwConfigQueryType.INST_COMPLETE_CONFIG,
            str_value: EMPTY_STRING,
            int_value: 0,
          },
        },
      }).then(({ data }) => {
        if (data) {
          setOptions(data?.GetSwConfigVariables!);
        }
      });
    }
  }, [InstId, GetSwDetails, token]);

  useEffect(
    () => {
      if (
        pageNumberData &&
        !GetSwConfigVariablesPageAssociByPageNumberLoading &&
        options
      ) {
        const updatedItems = options.map((option) => {
          if (
            pageNumberData?.find(
              (data) => data.config_details.config_key === option?.config_key
            )
          ) {
            const newData = {
              ...option,
              isChecked: true,
            };
            return newData;
          } else {
            const newData = {
              ...option,
              isChecked: false,
            };
            return newData;
          }
        });
        setOptions(updatedItems);
      }
    },
    // eslint-disable-next-line
    [
      pageNumberData,
      GetSwConfigVariablesPageAssociByPageNumberLoading,
      pageNumber,
      pageNumberInfo,
    ]
  );

  //mutations
  const [AddPageDisplayConfigDetails, { loading: creationLoading }] =
    useMutation(AddSwConfigVariablesPageAssoci, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const handleConfigValueSelected = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempClass = options.map((option) => {
        return { ...option, isChecked: checked };
      });
      setOptions(tempClass);
    } else {
      let tempClass = options.map((option) =>
        option.config_form_label_caption === name
          ? { ...option, isChecked: checked }
          : option
      );
      setOptions(tempClass);
    }
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleClear = () => {
    setOptions(
      options?.map((data) => ({
        ...data,
        isChecked: false,
      }))
    );
    setPageNumber("");
  };
  const handlePageConfigAssociation = () => {
    AddPageDisplayConfigDetails({
      variables: {
        token,
        input: options
          .filter((option) => option.isChecked)
          .map((item, index) => ({
            inst_id: InstId,
            sw_config_variable_id: item.id,
            sw_config_page_info_id: pageNumber,
            idx: 0,
          })),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwConfigVariablesPageAssociByPageNumber,
          variables: {
            token,
            page_number: pageNumberInfo?.page_number,
            inst_id: InstId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Page configuration  Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
        handleClear();
      }
    });
  };

  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Page_Configuration.Titles.map(
          (title: PageConfigurationTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.Configuration}</React.Fragment>
            );
          }
        )}
      </Title>
      {/* <ErrorModal value={errorMessage} /> */}
      <div className="row g-0 ">
        <div className="col-4 page-config__label-grid">
          <Label>Page Name</Label>
          <Select
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPageNumber(e.target.value)
            }
            value={pageNumber}
          >
            <option>Select</option>
            {data?.GetSwConfigPageInfo?.map((option, index) => (
              <option key={index} value={option.id}>
                {toStandardCase(option.page_name)}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div className="select-all">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="allSelect"
                className="select-all--checkbox"
                checked={!options?.some((user) => user?.isChecked !== true)}
                onChange={handleConfigValueSelected}
              />
            }
            label="All Select"
          />
        </FormGroup>
      </div>
      <div className="page-config">
        <div className="page-config__data">
          {options?.map((user, index) => (
            <FormGroup className="select-all__checkboxes" key={index}>
              <CustomTooltip
                title={
                  user.config_key_data_storage_type === "VARCHAR"
                    ? user.config_string_value
                    : user.config_boolean_value
                    ? "true"
                    : "false"
                }
                arrow
                placement="right"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
                className="tooltip-hide"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name={user.config_form_label_caption}
                      checked={user?.isChecked || false}
                      onChange={handleConfigValueSelected}
                    />
                  }
                  label={
                    <>
                      &nbsp; {index + 1}) &nbsp;
                      {user.config_form_label_caption}
                    </>
                  }
                />
              </CustomTooltip>
            </FormGroup>
          ))}
        </div>
      </div>
      <div className="button-left">
        <Button mode="save" onClick={handlePageConfigAssociation} />
        <Button
          mode="reorder"
          onClick={() => setConfigurationModal(!configurationModal)}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Reorder
              pageType={PageFor.GENERAL}
              setModal={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setConfigurationModal(!configurationModal);
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default PageConfiguration;

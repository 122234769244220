import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { GetDeptsByInstId } from "../queries/institution/department/list/byid";
import {
  BranchesListDataByInstId,
  BranchListVarsByInstId,
  CategoryListDataByInstId,
  ClassListDataByInstId,
  DepartmentListDataByInstId,
  DepartmentListVarsByInstId,
  SectionListDataByInstId,
  SemesterListDataByInstId,
} from "../Types/Student";
import { ByInstId as BranchesByInstId } from "../queries/institution/branch/list/byid";
import { ClassByInstId } from "../queries/institution/classes/list/byid";
import { SemByInstId } from "../queries/institution/sem/list/byid";
import { SectionByInstId } from "../queries/sections/list/byId";
import { ListCategoryByInstId } from "../queries/institution/categories/byId";
import { InstitutionDetails } from "../utils/Enum.types";
import { useEffect, useState } from "react";
import useToken from "./useToken";
import { responseType } from "../utils/Form.types";

const useInstitutionDetailsDataByInstId = (type: InstitutionDetails) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [departmentDropDown, setDepartmentDropDown] = useState<responseType[]>(
    []
  );
  const [branchDropDown, setBranchDropDown] = useState<responseType[]>([]);
  const [classDropDown, setClassDropDown] = useState<responseType[]>([]);
  const [semesterDropDown, setSemesterDropDown] = useState<responseType[]>([]);
  const [sectionDropDown, setsectionDropDown] = useState<responseType[]>([]);
  const [categoryDropDown, setCategoryDropDown] = useState<responseType[]>([]);

  const [GetDepartmentsByInstId] = useLazyQuery<
    DepartmentListDataByInstId,
    DepartmentListVarsByInstId
  >(GetDeptsByInstId);

  const [GetBranchesByInstId] = useLazyQuery<
    BranchesListDataByInstId,
    BranchListVarsByInstId
  >(BranchesByInstId);

  const [GetClassesByInstId] = useLazyQuery<
    ClassListDataByInstId,
    DepartmentListVarsByInstId
  >(ClassByInstId);

  const [GetSemestersByInstId] = useLazyQuery<
    SemesterListDataByInstId,
    DepartmentListVarsByInstId
  >(SemByInstId);

  const [GetSectionsByInstId] = useLazyQuery<
    SectionListDataByInstId,
    DepartmentListVarsByInstId
  >(SectionByInstId);

  const [GetCategoriesByInstId] = useLazyQuery<
    CategoryListDataByInstId,
    DepartmentListVarsByInstId
  >(ListCategoryByInstId);

  useEffect(() => {
    if (type === InstitutionDetails.DEPARTMENT) {
      GetDepartmentsByInstId({
        variables: {
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredDeptList = data.GetDeptsByInstId.filter(
            (dept) => dept !== null
          ).map((dept) => ({
            label: dept.dept_desc,
            value: dept.id,
            isChecked: false,
          }));
          setDepartmentDropDown(filteredDeptList);
        }
      });
    }
    if (type === InstitutionDetails.BRANCH) {
      GetBranchesByInstId({
        variables: {
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredBranchList = (data.GetBranchesByInstId || [])
            .filter((branch) => branch !== null)
            .map((branch) => ({
              label: branch.branch_desc,
              value: branch.id,
              isChecked: false,
            }));
          setBranchDropDown(filteredBranchList);
        }
      });
    }

    if (type === InstitutionDetails.CLASS) {
      GetClassesByInstId({
        variables: {
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data && data.GetClassesByInstId) {
          const filteredClassList = data.GetClassesByInstId.filter(
            (data) => data !== null
          ).map((data) => ({
            label: data.class_desc,
            value: data.id,
            isChecked: false,
          }));
          setClassDropDown(filteredClassList);
        }
      });
    }

    if (type === InstitutionDetails.SEMESTER) {
      GetSemestersByInstId({
        variables: {
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredSemesterList = data.GetSemestersByInstId.filter(
            (data) => data !== null
          ).map((data) => ({
            label: data.sem_desc,
            value: data.id,
            isChecked: false,
          }));
          setSemesterDropDown(filteredSemesterList);
        }
      });
    }
    if (type === InstitutionDetails.SECTION) {
      GetSectionsByInstId({
        variables: {
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredSemesterList = data.GetSectionsByInstId.filter(
            (data) => data !== null
          ).map((data) => ({
            label: data.section_desc,
            value: data.id,
            isChecked: false,
          }));
          setsectionDropDown(filteredSemesterList);
        }
      });
    }
    if (type === InstitutionDetails.CATEGORY) {
      GetCategoriesByInstId({
        variables: {
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data && data.GetCategoriesByInstId) {
          const filteredCategoryList = data.GetCategoriesByInstId.filter(
            (data) => data !== null
          ).map((data) => ({
            label: data.cat_desc,
            value: data.id,
            isChecked: false,
          }));
          setCategoryDropDown(filteredCategoryList);
        }
      });
    }
  }, [
    type,
    InstId,
    token,
    GetDepartmentsByInstId,
    GetBranchesByInstId,
    GetClassesByInstId,
    GetSemestersByInstId,
    GetSectionsByInstId,
    GetCategoriesByInstId,
  ]);

  return {
    departmentDropDown,
    branchDropDown,
    classDropDown,
    semesterDropDown,
    sectionDropDown,
    categoryDropDown,
  };
};

export default useInstitutionDetailsDataByInstId;

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router";
import { useMutation, useLazyQuery } from "@apollo/client";

import { Title } from "../../../../stories/Title/Title";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import { msgType, sectionTypes } from "../../../../utils/Form.types";
import InstitutionTabs from "../../routes/InstitutionTabs";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../stories/Button/Button";
import {
  ReOrderInstSection,
  UpdateInstSection,
} from "../../../../queries/institution/mutations/update";
import { AddInstSection } from "../../../../queries/institution/mutations/new";
import Input from "../../../../components/common/Input/Index";
import {
  SectionDetailsByNode,
  SectionsBySemIds,
} from "../../../../queries/sections/list/byId";
import Edit from "../../../../images/EditProfile.svg";
import DeleteImg from "../../../../images/Delete.svg";

import { Form, Formik } from "formik";
import { sectionValidation } from "../../../../utils/validationRules";
import { Operation, PageNumbers } from "../../../../utils/Enum.types";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import {
  removeMoreSpace,
  reOrderProcess,
} from "../../../../utils/UtilFunctions";
import { ByNode as DeptByDeptId } from "../../../../queries/institution/department/list/byid";
import { BranchDetailsByNode } from "../../../../queries/institution/branch/list/byid";
import { ByNode as ClassBySemId } from "../../../../queries/institution/classes/list/byid";
import { SemesterDetailsByNode } from "../../../../queries/institution/sem/list/byid";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

import { InstitutionDetailsFormLabelsType } from "../../../../Types/Institution/json";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../../styles/ModalStyles";

import { Breadcrumbs } from "@mui/material";
import LoadingModal from "../../../../pages/LoadingModal";
import { DeleteInstSectionById } from "../../../../queries/institution/mutations/delete";

import { NavLink } from "react-router-dom";
import DeleteModal from "../../../../pages/DeleteModal";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import Settings from "../../../../images/Settings.svg";
import ConfigurationSettings from "../../configurations/general/Index";
import Close from "../../../../images/Close.svg";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import {
  SectionList,
  SectionListDataBySemesterId,
  SectionListVarsBySemesterId,
} from "../../../../Types/Student";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import TreeView from "../TreeView";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
const { SectionFormLabels } = require("../../../../json/config.json");

const Section = () => {
  //useParam, GlobalStates and useNavigate
  const { InstId, deptId, branchId, classId, semesterId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();

  const { USE_ADMISSIONS } = useUserRightsByEmpId();
  //useStates for Data
  const [formData, setFormData] = useState<sectionTypes>({
    section_desc: "",
    indexnumber: "",
    shortdescription: "",
  });
  const [sectionId, setSectionId] = useState(0);
  const [localItems, setLocalItems] = useState<SectionList[]>([]);
  // eslint-disable-next-line
  const [item, setitems] = useState<SectionList[]>([]);
  const nameRef = document.getElementsByName(
    "section_desc"
  )[0] as HTMLInputElement;

  //useStates for Flags
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reOrder, setReOrder] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const { user_details } = useLoggedInUserDetails();
  //flags for sucess model
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [configurationModal, setConfigurationModal] = useState(false);

  //Queries
  const [GetDepartments, { data: DeptByNodeId, loading: DeptByNodeIdLoading }] =
    useLazyQuery(DeptByDeptId, {
      variables: { dept_id: deptId, token },
    });
  const [
    GetBranches,
    { data: BranchByNodeId, loading: BranchByNodeIdLoading },
  ] = useLazyQuery(BranchDetailsByNode, {
    variables: { branch_id: branchId, token },
  });
  const [GetClasses, { data: ClassByNodeId, loading: ClassByNodeIdLoading }] =
    useLazyQuery(ClassBySemId, {
      variables: { class_id: classId, token },
    });
  const [GetSemesters, { data: SemByNodeId, loading: SemByNodeIdLoading }] =
    useLazyQuery(SemesterDetailsByNode, {
      variables: { semester_id: semesterId, token },
    });

  //Lazy Queries
  const [SectionDataById, { data, loading }] = useLazyQuery(
    SectionDetailsByNode,
    {
      variables: { token, section_id: sectionId },
    }
  );

  //Mutations
  const [NewSection, { loading: creationLoading }] = useMutation(
    AddInstSection,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateSection, { loading: updationLoading }] = useMutation(
    UpdateInstSection,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [reorderSection, { loading: reorderLoading }] = useMutation(
    ReOrderInstSection,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [deleteSection, { loading: deleteLoading }] = useMutation(
    DeleteInstSectionById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //cutomHooks
  const [GetSections, { data: sectionData, loading: sectionLoading }] =
    useLazyQuery<SectionListDataBySemesterId, SectionListVarsBySemesterId>(
      SectionsBySemIds,
      {
        variables: {
          sem_ids: [Number(semesterId)],
          token,
        },
      }
    );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.SECTION_PAGE);

  //Methods
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };
  const HandleSubmit = async () => {
    // backend api call
    await NewSection({
      variables: {
        token,
        input: {
          inst_id: InstId,
          semester_id: semesterId,
          section_desc: removeMoreSpace(formData.section_desc),
          section_short_desc: removeMoreSpace(formData.shortdescription),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: SectionsBySemIds,
          variables: {
            token,
            sem_ids: [Number(semesterId)!],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Section Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setFormData({
      section_desc: "",
      indexnumber: "0",
      shortdescription: "",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const HandleEdit = async () => {
    // backend api call
    await updateSection({
      variables: {
        token,
        id: sectionId,
        section_desc: removeMoreSpace(formData.section_desc),
        section_idx: Number(formData.indexnumber),
        section_short_desc: removeMoreSpace(formData.shortdescription),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: SectionsBySemIds,
          variables: {
            token,
            sem_ids: [semesterId!],
          },
        },
        {
          query: SemesterDetailsByNode,
          variables: {
            token,
            section_id: sectionId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Section Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setFormData({
      section_desc: "",
      indexnumber: "0",
      shortdescription: "",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const HandleDelete = async (sec_id: number) => {
    await deleteSection({
      variables: {
        token,
        sec_id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: SectionsBySemIds,
          variables: {
            token,
            sem_ids: [Number(semesterId)!],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDeleteModal(!deleteModal);
        setMessage({
          message: "Section Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setOperation(Operation.CREATE);
    handleClear();
  };
  const handleSaveOrder = () => {
    // eslint-disable-next-line
    reorderSection({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.id,
          section_idx: index + 1,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: SectionsBySemIds,
          variables: {
            token,
            sem_ids: [Number(semesterId)!],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re=Order Successful",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setReOrder(!reOrder);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };
  //useEffects

  useEffect(() => {
    if (!loading && data && operation === Operation.UPDATE && token) {
      const { section_desc, section_idx, section_short_desc } = data.node;
      setFormData({
        section_desc: section_desc,
        indexnumber: section_idx,
        shortdescription: section_short_desc,
      });
    }
  }, [loading, data, operation, token]);
  useEffect(() => {
    if (sectionData && !sectionLoading && token)
      setLocalItems(
        sectionData?.GetSectionsBySemesterIds?.filter((f) => f !== null)
      );
  }, [sectionData, sectionLoading, token]);
  const handleClear = () => {
    setFormData({
      section_desc: "",
      indexnumber: "",
      shortdescription: "",
    });
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };
  useEffect(() => {
    if (token) {
      GetDepartments();
      GetBranches();
      GetClasses();
      GetSemesters();
      GetSections();
    }
  }, [
    token,
    GetDepartments,
    GetBranches,
    GetClasses,
    GetSemesters,
    GetSections,
  ]);
  const { sectionLabel } = useInstLabels();
  return (
    <>
      <InstitutionTabs />

      <div className="row g-0">
        <div className="col">
          <Title>{sectionLabel}</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>

      <div className="row g-0">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb" className="inst-breadcrumbs">
            <NavLink to={`/${InstId}/admissions/department`}>
              {USE_DEPARTMENT_KEY &&
                !DeptByNodeIdLoading &&
                DeptByNodeId &&
                DeptByNodeId.node.dept_desc}
            </NavLink>
            <NavLink
              to={`/${InstId}/admissions/department/${DeptByNodeId?.node.id}/branch`}
            >
              {USE_BRANCH_KEY &&
                !BranchByNodeIdLoading &&
                BranchByNodeId &&
                BranchByNodeId.node.branch_desc}
            </NavLink>
            <NavLink
              to={`/${InstId}/admissions/department/${DeptByNodeId?.node.id}/branch/${BranchByNodeId?.node.id}/class`}
            >
              {USE_CLASS_KEY &&
                !ClassByNodeIdLoading &&
                ClassByNodeId &&
                ClassByNodeId.node.class_desc}
            </NavLink>

            <NavLink
              to={`/${InstId}/admissions/department/${DeptByNodeId?.node.id}/branch/${BranchByNodeId?.node.id}/class/${ClassByNodeId?.node.id}/semester`}
            >
              {USE_SEMESTER_KEY &&
                !SemByNodeIdLoading &&
                SemByNodeId &&
                SemByNodeId.node.sem_desc}
            </NavLink>
          </Breadcrumbs>
        </div>
      </div>

      <div className="masterconfig">
        <div className="row g-0 masterconfig__details">
          <div className="col booktype-left h-100">
            <Title variant="subtitle1">List of {sectionLabel}</Title>

            <TableContainer className={"masterconfig__table"}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {reOrder ? <TableCell /> : null}

                    {Masters_Table.InstitutionDetails.Sections.Table_Headers.filter(
                      ({ labelName }: TableHeaderProps) =>
                        !(labelName === "Actions" && reOrder)
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TableCell className={th.className}>
                            {th.labelName}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {localItems?.map((response, index: number) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!reOrder}
                          >
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <TableRow
                                    key={index}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                  >
                                    {reOrder ? (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                        id="td-center"
                                        className="institution__table--slno"
                                      >
                                        =
                                      </TableCell>
                                    ) : null}
                                    <TableCell
                                      id="td-center"
                                      {...draggableProvided.dragHandleProps}
                                      className="institution__table--slno"
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                    >
                                      {response.section_desc}
                                    </TableCell>
                                    {reOrder === false ? (
                                      <TableCell
                                        id="td-center"
                                        {...draggableProvided.dragHandleProps}
                                        className="institution__table--action"
                                      >
                                        {USE_ADMISSIONS.details?.edit && (
                                          <img
                                            src={Edit}
                                            alt="/"
                                            onClick={() => {
                                              setSectionId(response.id);
                                              setOperation(Operation.UPDATE);
                                              setAddModal(!addModal);
                                              SectionDataById({
                                                variables: {
                                                  token,
                                                  section_id: response.id,
                                                },
                                              });
                                            }}
                                          />
                                        )}
                                        {USE_ADMISSIONS.details?.delete && (
                                          <img
                                            src={DeleteImg}
                                            alt="/"
                                            onClick={() => {
                                              setSectionId(response.id);
                                              setDeleteModal(!deleteModal);
                                            }}
                                          />
                                        )}
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                </React.Fragment>
                              );
                            }}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </div>

          <div className="col booktype-right h-100">
            <TreeView />
          </div>
        </div>
        <div className="masterconfig__buttons">
          <Button mode="addnew" onClick={() => setAddModal(!addModal)} />
          {localItems?.length > 1 ? (
            reOrder ? (
              <>
                <Button
                  mode="save-order"
                  type="button"
                  onClick={handleSaveOrder}
                />

                <Button
                  mode="clear"
                  type="button"
                  onClick={() => {
                    setReOrder(!reOrder);
                  }}
                />
              </>
            ) : (
              <Button
                mode="reorder"
                type="button"
                onClick={() => setReOrder(!reOrder)}
              />
            )
          ) : null}

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={sectionId}
      />
      <LoadingModal
        flag={
          creationLoading || updationLoading || deleteLoading || reorderLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.SECTION_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Formik
              initialValues={formData}
              validationSchema={sectionValidation}
              onSubmit={
                operation === Operation.UPDATE ? HandleEdit : HandleSubmit
              }
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    <Title variant="subtitle1">
                      {operation === Operation.UPDATE
                        ? `Update ${sectionLabel}`
                        : `Add ${sectionLabel}`}
                    </Title>
                    {SectionFormLabels.map(
                      (
                        section: InstitutionDetailsFormLabelsType,
                        index: React.Key
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              name={section.inputName}
                              values={formData[section.inputName]}
                              required={section.required}
                              autoFocus={section.autoFocus}
                              LabelName={section.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}

                    <div className="masterconfig__buttons">
                      <Button mode="save" type="submit" />

                      <Button
                        mode="clear"
                        type="button"
                        onClick={handleClear}
                      />

                      <Button
                        mode="cancel"
                        onClick={() => {
                          setAddModal(!addModal);
                          handleClear();
                        }}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image ">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setAddModal(!addModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Section;

import { gql } from "@apollo/client";

export const AddPayRollDept = gql`
  mutation AddPayRollDept(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollDeptInput!
  ) {
    AddPayRollDept(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const DeletePayRollDeptById = gql`
  mutation DeletePayRollDeptById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollDeptById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdatePayRollDept = gql`
  mutation UpdatePayRollDept(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollDeptInput!
  ) {
    UpdatePayRollDept(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import {  TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";

import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import { Operation, StudentReportType } from "../../../../utils/Enum.types";
import { responseType } from "../../../../utils/Form.types";
import useAcdStudentsAdmNumber from "../../hooks/useAcdStudentsAdmNumber";
import Edit from "../../../../images/Edit.svg";
import Avatar from "../../../../images/Avatar.svg";
import {
  NameOfTheDay,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import Home from "../../Home/Index";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { Label } from "../../../../stories/Label/Label";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Title } from "../../../../stories/Title/Title";
import useAcademicYears from "../../hooks/useAcademicYears";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStudentAttendanceByStdId } from "../../queries/holidays/list";
import {
  GetAcdAttendanceMonthlyByStdIdData,
  GetAcdAttendanceMonthlyByStdIdVars,
} from "../../types/holidays";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { Button } from "../../../../stories/Button/Button";
import { GetAcdAttendanceMonthlyNode } from "../../hooks/useAcdStudentAttendance";
import CustomizedCalendar from "../../calendar/HolidayEntry/CustomizedCalendar";
import { AcdYrType, ModalType } from "../../calendar/HolidayEntry/Index";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

export interface HolidayDate {
  date: Date;
  duration: string;
}
const ByStudentId = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { InstDetails } = useInstDetails(1);
  const { dispatch, state } = useContext(AppContext);
  const [studentModal, setStudentModal] = useState(false);
  const [admNo, setAdmNo] = useState("");
  const [acdYr, setAcdYr] = useState<AcdYrType | null>(null);
  const [imageString, setImageString] = useState("");
  const [holidays, setHolidays] = useState<GetAcdAttendanceMonthlyNode>();
  const [acdYearOptions, setAcdYearOptions] = useState<AcdYrType[]>([]);
  const [addModal, setAddModal] = useState<ModalType>({
    date: new Date(),
    flag: false,
    operation: Operation.NONE,
  });
  const apiData: Record<string, string> = {};

  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );

  const { academicYearResponse } = useAcademicYears();

  const { studentData, studentFormData } = useStudentDatabyId();

  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { serverDate } = useServerDateandTime();

  const [GetStudentHolidayDetails] = useLazyQuery<
    GetAcdAttendanceMonthlyByStdIdData,
    GetAcdAttendanceMonthlyByStdIdVars
  >(GetAcdStudentAttendanceByStdId, {
    variables: {
      acd_yr_id: acdYr?.value!,
      cal_month: addModal.date.toString(),
      inst_id: InstId!,
      student_id: state.studentId,
      token,
    },
  });

  if (holidays) {
    const {
      day_2,
      day_3,
      day_4,
      day_1,
      day_5,
      day_6,
      day_7,
      day_8,
      day_9,
      day_10,
      day_11,
      day_12,
      day_13,
      day_14,
      day_15,
      day_16,
      day_17,
      day_18,
      day_19,
      day_20,
      day_21,
      day_22,
      day_23,
      day_24,
      day_25,
      day_26,
      day_27,
      day_28,
      day_29,
      day_30,
      day_31,
    } = holidays;
    apiData.day_1 = day_1 || EMPTY_STRING;
    apiData.day_2 = day_2 || EMPTY_STRING;
    apiData.day_3 = day_3 || EMPTY_STRING;
    apiData.day_4 = day_4 || EMPTY_STRING;
    apiData.day_5 = day_5 || EMPTY_STRING;
    apiData.day_6 = day_6 || EMPTY_STRING;
    apiData.day_7 = day_7 || EMPTY_STRING;
    apiData.day_8 = day_8 || EMPTY_STRING;
    apiData.day_9 = day_9 || EMPTY_STRING;
    apiData.day_10 = day_10 || EMPTY_STRING;
    apiData.day_11 = day_11 || EMPTY_STRING;
    apiData.day_12 = day_12 || EMPTY_STRING;
    apiData.day_13 = day_13 || EMPTY_STRING;
    apiData.day_14 = day_14 || EMPTY_STRING;
    apiData.day_15 = day_15 || EMPTY_STRING;
    apiData.day_16 = day_16 || EMPTY_STRING;
    apiData.day_17 = day_17 || EMPTY_STRING;
    apiData.day_18 = day_18 || EMPTY_STRING;
    apiData.day_19 = day_19 || EMPTY_STRING;
    apiData.day_20 = day_20 || EMPTY_STRING;
    apiData.day_21 = day_21 || EMPTY_STRING;
    apiData.day_22 = day_22 || EMPTY_STRING;
    apiData.day_23 = day_23 || EMPTY_STRING;
    apiData.day_24 = day_24 || EMPTY_STRING;
    apiData.day_25 = day_25 || EMPTY_STRING;
    apiData.day_26 = day_26 || EMPTY_STRING;
    apiData.day_27 = day_27 || EMPTY_STRING;
    apiData.day_28 = day_28 || EMPTY_STRING;
    apiData.day_29 = day_29 || EMPTY_STRING;
    apiData.day_30 = day_30 || EMPTY_STRING;
    apiData.day_31 = day_31 || EMPTY_STRING;
  }
  // const holidayDates: HolidayDate[] = Array.from(
  //   { length: numDaysInMonth },
  //   (_, i) => {
  //     const dayNum = i + 1;
  //     const dayKey = `day_${dayNum}`;
  //     const duration =
  //       apiData[dayKey] === HolidayType.WEEKEND
  //         ? "full day"
  //         : apiData[dayKey] === HolidayDuration.HALFDAY
  //         ? "half day"
  //         : EMPTY_STRING;
  //     const date = new Date(year, month, dayNum);
  //     return { date, duration };
  //   }
  // );

  useEffect(() => {
    if (acdYr?.value && state.studentId && addModal) {
      GetStudentHolidayDetails().then(({ data }) => {
        if (data) {
          setHolidays(
            data.GetAcdStudentAttendanceByStdId as GetAcdAttendanceMonthlyNode
          );
        } else {
          setHolidays(undefined);
        }
      });
    } // eslint-disable-next-line
  }, [acdYr?.value, state.studentId, addModal]);
  useEffect(() => {
    if (studentData.data) {
      if (
        studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
        InstDetails.data
      ) {
        const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

        getDownloadUrl(studentProfiePicUrl, false, setImageString);
      }
    }
  }, [studentData.data, state.studentId, InstDetails.data]);

  useEffect(() => {
    if (academicYearResponse.data && !academicYearResponse.loading) {
      setAcdYearOptions(
        academicYearResponse.data.GetAcdYrsByInstId.map((edge) => ({
          start_date: new Date(edge.acd_st_date),
          end_date: new Date(edge.acd_end_date),
          label: edge.acd_yr,
          value: edge.id,
        }))
      );
    }
  }, [academicYearResponse.data, academicYearResponse.loading]);
  const { branchLabel, classLabel ,categoryLabel} = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Per Student Attendence Report</Title>
      <div className="per-student-attendance-report">
        <div className="row g-0 per-student-attendance-report__details">
          <div className="col">
            <div className="per-student-attendance-report__details--imagefield">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                value={
                  studentAddmissionNumber?.find(
                    ({ label }) =>
                      studentData.data &&
                      label === studentData.data?.nodes[0].std_adm_no
                  )! ?? admNo
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setAdmNo("");
                  }
                }}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission Number"
                    autoFocus
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />

              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => {
                  if (!state.studentId) {
                    setStudentModal(!studentModal);
                  }
                }}
              />
            </div>

            <TextField
              label="Reg No."
              className="per-student-attendance-report__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.reg_number}
              disabled
            />
            <TextField
              label={branchLabel}
              className="per-student-attendance-report__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.branch}
              disabled
            />
          </div>

          <div className="col">
            <TextField
              label="Name"
              className="per-student-attendance-report__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.std_name}
              disabled
            />
            <TextField
              label="Father Name"
              className="per-student-attendance-report__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.father_name}
              disabled
            />

            <TextField
              label={classLabel}
              className="per-student-attendance-report__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.class}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              type="date"
              label="Date"
              className="per-student-attendance-report__details--date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={toInputStandardDate(serverDate)}
              disabled
            />
            <Label variant="present-day">{NameOfTheDay(serverDate)}</Label>

            {USE_CATEGORY_KEY && (
              <TextField
                label={categoryLabel}
                className="per-student-attendance-report__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData?.category}
                disabled
              />
            )}
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              value={acdYr}
              options={acdYearOptions}
              onChange={(e, newValue) => {
                if (newValue) {
                  setAcdYr(newValue as AcdYrType );
                } else {
                  setAcdYr(null);
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Academic Year"
                  fullWidth
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-1 per-student-attendance-report__details--image h-100">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <div className="row g-0 per-student-attendance-report__calendar">
          <div className="col-5">
            {/* <Calendar
              defaultView="year"
              maxDate={new Date(acd_end_date!)}
              minDate={new Date(acd_st_date!)}
              onClickMonth={(e: Date) =>
                setCalMonth(
                  toIsoDate(
                    new Date(e.getFullYear(), e.getMonth(), 1).toString()
                  )
                )
              }
              tileClassName={({ date }) => getTileClassName(date, holidayDates)}
            /> */}
            <CustomizedCalendar
              acdYr={acdYr!}
              date={addModal.date}
              setAddmodal={setAddModal}
            />
          </div>
        </div>
        <div className="row g-0 ">
          <div className="col-1">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col per-student-attendance-report__legends">
            <span className="per-student-attendance-report__legends--fullday"></span>
            &rarr;
            <Label>Full Day</Label>
            <span className="per-student-attendance-report__legends--halfday"></span>
            &rarr;
            <Label>Half Day</Label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ByStudentId;

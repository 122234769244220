import React, { useContext, useMemo, useState } from "react";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import { AssignComponentProp, TreeType } from "../../DailyActivities/Home";

import { useNavigate, useParams } from "react-router-dom";
import { responseType } from "../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  InstitutionConfigurationTypes,
  TableHeaders,
} from "../../../../utils/Enum.types";
import useMstInstTreeByQueryType from "../../../../customhooks/general/useMstInstTreeByQueryType";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import View from "../../../../images/EyeWhite.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import { Keys } from "../../../../utils/Enum.keys";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { CheckPerStdSubjAllocAtEntryLevel } from "../../../../queries/customallocation/combination/queries";
import {
  CheckPerStdSubjAllocAtEntryLevelData,
  CheckPerStdSubjAllocAtEntryLevelVars,
} from "../../../../Types/Combinations/queries";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
const Index = () => {

  const navigate = useNavigate();
  const { InstId, entryId } = useParams();

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const { state } = useContext(AppContext);
  const [CheckPerStdConfig] = useLazyQuery<
    CheckPerStdSubjAllocAtEntryLevelData,
    CheckPerStdSubjAllocAtEntryLevelVars
  >(CheckPerStdSubjAllocAtEntryLevel, {
    onError: (e) => console.log(e.message),
  });
  const { token } = useToken();

  const { entry_level } = useInstitutionConfiguration();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );

  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  let idsSet = new Set();

  const { data } = useMstInstTreeByQueryType(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    toIsoDate(TODAY_DATE),
    0
  );

  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }
    if (USE_SECTION_KEY) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);

  const AssignComponent = ({ id }: AssignComponentProp) => (
    <button
      className="acd-reports__table--view-button"
      onClick={() =>
        navigate(`/${InstId}/academics/reports/completeReports/${id}/view`)
      }
    >
      View <img src={View} alt="" />
    </button>
  );
  const modifiedArrayForTable: TreeType[] = data
    ? data.GetAcdInstTree.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.flatMap((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          filterThis: false,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count,
                        level5_name: EMPTY_STRING,
                        level1_id: level1.acd_level_1_id,
                        level2_id: level2.acd_level_2_id,
                        level3_id: level3.acd_level_3_id,
                        level4_id: level4.acd_level_4_id,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level5_name: EMPTY_STRING,
                    level1_id: level1.acd_level_1_id,
                    level2_id: level2.acd_level_2_id,
                    level3_id: level3.acd_level_3_id,
                    level4_id: 0,
                    level5_id: 0,

                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                  };

                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level5_name: EMPTY_STRING,
                level1_id: level1.acd_level_1_id,
                level2_id: level2.acd_level_2_id,
                level3_id: 0,
                level4_id: 0,
                level5_id: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
              };

              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level5_name: EMPTY_STRING,
            level1_id: level1.acd_level_1_id,
            level2_id: 0,
            level3_id: 0,
            level4_id: 0,
            level5_id: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];

  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Academic Reports</Title>
      <div className="acd-reports">
        <div className="acd-reports__select row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="acd-reports__tableblock">
          <TableContainer className="acd-reports__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {USE_DEPARTMENT_KEY ? (
                    <TableCell>{departmentLabel}</TableCell>
                  ) : null}
                  {USE_BRANCH_KEY ? <TableCell>{branchLabel}</TableCell> : null}
                  {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
                  {USE_SEMESTER_KEY ? (
                    <TableCell>{semesterLabel}</TableCell>
                  ) : null}
                  {USE_SECTION_KEY ? (
                    <TableCell>{sectionLabel}</TableCell>
                  ) : null}
                  <TableCell>{TableHeaders.ACTION}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                  if (data) {
                    return (
                      <TableRow key={i}>
                        {data.level1_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level1_rowspan}>
                            {data.level1_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        (data.level2_name ||
                          data.level2_name !== EMPTY_STRING) ? (
                          <TableCell rowSpan={data.level2_rowspan}>
                            {data.level2_name}
                          </TableCell>
                        ) : null}
                        {data && data.level3_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level3_span}>
                            {data.level3_name}
                          </TableCell>
                        ) : null}
                        {data && data.level4_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level4_span}>
                            {data.level4_name}
                          </TableCell>
                        ) : null}
                        {data.level5_name !== EMPTY_STRING ? (
                          <TableCell>{data.level5_name}</TableCell>
                        ) : null}
                        <TableCell
                          id="td-center"
                          className="acd-reports__table--actions"
                        >
                          {last_level - 1 === 1 && data.level1_name && (
                            <AssignComponent id={data.level1_id} />
                          )}
                          {last_level - 1 === 2 && data.level2_name && (
                            <AssignComponent id={data.level2_id} />
                          )}
                          {last_level - 1 === 3 && data.level3_name && (
                            <AssignComponent id={data.level3_id} />
                          )}
                          {last_level - 1 === 4 && data.level4_name && (
                            <AssignComponent id={data.level4_id} />
                          )}
                          {last_level - 1 === 5 && data.level5_name && (
                            <AssignComponent id={data.level5_id} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

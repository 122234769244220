import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { InstIdVars } from "../Types/masterDataTypes";

import { GetPayRollAcdYrActiveByInstId } from "../queries/academicyear/query";
import { PayRollAcademicYearList } from "./usePayRollAcademicYears";
import { useEffect } from "react";
export interface PayRollActiveAcademicYearByInstIdData {
  GetPayRollAcdYrActiveByInstId: PayRollAcademicYearList;
}

const usePayRollActiveAcademicYear = () => {
  const { InstId } = useParams();
  const { token } = useToken();

  const [GetPayRollAcdYr, { data, loading, error }] = useLazyQuery<
    PayRollActiveAcademicYearByInstIdData,
    InstIdVars
  >(GetPayRollAcdYrActiveByInstId, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  useEffect(() => {
    if (token) {
      GetPayRollAcdYr();
    }
  }, [token, GetPayRollAcdYr, InstId]);
  return { payRollActiveAcademicYear: { data, loading, error } };
};

export default usePayRollActiveAcademicYear;

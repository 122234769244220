import React, { useContext, useEffect } from "react";
import FeeCollector from "../../../../images/FeeCollector.svg";
import PendingRequest from "../../../../images/PendingRequest.svg";
import ApprovedRequest from "../../../../images/ApprovedRequest.svg";
import Forwarded from "../../../../images/ForwardRequest.svg";
import Rejected from "../../../../images/RejectedStatus.svg";
import { Title } from "../../../../stories/Title/Title";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { useLazyQuery } from "@apollo/client";
import { GetAcctMkCkAssigned } from "../../queries/Vouchers/query";
import {
  GetAcctMkCkAssignedData,
  GetAcctMkCkAssignedDetailsVars,
} from "../../hooks/useMkckVoucherData";
import { VoucherApprovalStatus } from "../../common/Enum.types";
import { toStandardDate } from "../../../../utils/UtilFunctions";
export const returnStatus = (status: string) => {
  switch (status) {
    case VoucherApprovalStatus.Pending:
      return "Pending";

    case VoucherApprovalStatus.Approved:
      return "Approved";
    case VoucherApprovalStatus.Rejected:
      return "Rejected";
    case VoucherApprovalStatus.Forwarded:
      return "Forwarded";
    default:
      break;
  }
};
const Status = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [GetStatus, { data }] = useLazyQuery<
    GetAcctMkCkAssignedData,
    GetAcctMkCkAssignedDetailsVars
  >(GetAcctMkCkAssigned, { fetchPolicy: "network-only" });
  useEffect(() => {
    if (state.ActiveFinYr) {
      GetStatus({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId!,
          mkck_voucher_master_id: state.mkckId,
          token,
        },
      });
    }
  }, [state.ActiveFinYr, GetStatus, InstId, state, token]);

  return (
    <>
      <Title>View Status</Title>
      <div className="vouchers-approved-status">
        <div className="vouchers-approved-status__datablock">
          <ul>
            {data?.GetAcctMkCkAssigned.map((item, index) => {
              return (
                <>
                  <li>
                    <div className="vouchers-approved-status__datablock--li">
                      <div>
                        <div className="vouchers-approved-status__datablock--approved-details">
                          <div>
                            {item.status === VoucherApprovalStatus.Created ? (
                              <img src={FeeCollector} alt="" />
                            ) : item.status ===
                              VoucherApprovalStatus.Forwarded ? (
                              <img src={Forwarded} alt="" />
                            ) : item.status ===
                              VoucherApprovalStatus.Pending ? (
                              <img src={PendingRequest} alt="" />
                            ) : item.status ===
                              VoucherApprovalStatus.Rejected ? (
                              <img src={Rejected} alt="" />
                            ) : (
                              <img src={ApprovedRequest} alt="" />
                            )}
                          </div>
                          <div>
                            <div className="vouchers-approved-status__datablock--approved-by">
                              <span className="vouchers-approved-status__datablock--approved-by--desn">
                                {item.status === VoucherApprovalStatus.Created
                                  ? item.created_emp_details
                                      .pr_designation_details.designation_desc
                                  : item.assigned_emp_details
                                      .pr_designation_details.designation_desc}
                              </span>
                              <span>
                                ({" "}
                                {item.status === VoucherApprovalStatus.Created
                                  ? item.created_emp_details.emp_first_name +
                                    item.created_emp_details.emp_middle_name +
                                    item.created_emp_details.emp_last_name
                                  : item.assigned_emp_details.emp_first_name +
                                    item.created_emp_details.emp_middle_name +
                                    item.created_emp_details.emp_last_name}{" "}
                                )
                              </span>
                            </div>
                            <div className="vouchers-approved-status__datablock--approved">
                              {item.status ===
                              VoucherApprovalStatus.Approved ? (
                                <div className="vouchers-approved-status__datablock--approved--without-line"></div>
                              ) : (
                                <div className="vouchers-approved-status__datablock--approved--line"></div>
                              )}
                              <div className="vouchers-approved-status__datablock--approved--details">
                                <div className="vouchers-approved-status__datablock--mode">
                                  {returnStatus(item.status)}
                                </div>
                                <div className="vouchers-approved-status__datablock--remarks">
                                  {/* {
                                    item.mkck_voucher_master
                                      .last_assigned_details.remarks
                                  } */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vouchers-approved-status__datablock--date">
                        <span>
                          {toStandardDate(item.mkck_voucher_master.created_at)}
                        </span>
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Status;

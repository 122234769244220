import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/context";
import { useLazyQuery } from "@apollo/client";
import { InstDetailsByNodeId } from "../../queries/institution/list/byId";
import useToken from "../useToken";

import { nodevars } from "../../Types/Accounting";
import { InstitutionNode } from "../useInstitutionsByCustId";
interface InstFormData {
  inst_name: string;
  inst_short_name: string;
  inst_address: string;
  inst_pin: string;
  inst_place: string;
  inst_phone: string;
  inst_state: string;
  inst_email: string;
  inst_mobile: string;
  cust_name: string;
  inst_name_to_print: string;
}
interface InstNodeData {
  nodes: InstitutionNode[];
}

const useInstDetails = (length: number) => {
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const { token } = useToken();

  const [InstFormData, setInstFormData] = useState<InstFormData>({
    inst_name: "",
    inst_short_name: "",
    inst_address: "",
    inst_pin: "",
    inst_place: "",
    inst_phone: "",
    inst_state: "",
    inst_email: "",
    inst_mobile: "",
    cust_name: "",
    inst_name_to_print: "",
  });

  const [GetInstDetails, { data, loading, error }] = useLazyQuery<
    InstNodeData,
    nodevars
  >(InstDetailsByNodeId, {
    variables: {
      ids: [Number(InstId ? InstId : state.InstId)],
      token,
    },
  });
  useEffect(() => {
    if (token && (InstId || state.InstId)) {
      GetInstDetails().then(({ data }) => {
        if (data && length === 1) {
          const { nodes } = data;
          setInstFormData({
            inst_name: nodes[0].inst_name,
            inst_short_name: nodes[0].inst_short_name,
            inst_address: nodes[0].inst_address,
            inst_pin: nodes[0].inst_pin,
            inst_place: nodes[0].inst_place,
            inst_phone: nodes[0].inst_phone,
            inst_state: nodes[0].inst_state,
            inst_email: nodes[0].inst_email,
            inst_mobile: nodes[0].inst_mobile,
            cust_name: nodes[0].customer_details.cust_name,
            inst_name_to_print: nodes[0].inst_name_to_print,
          });
        }
      });
    }
  }, [token, InstId, state.InstId, length, GetInstDetails]);

  return {
    InstDetails: { data, error, loading },
    InstFormData,
  };
};

export default useInstDetails;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { Label } from "../../../../../stories/Label/Label";
import EmployeeDetails from "./EmployeeDetails";

const earningData = [
  {
    name: "Basic",
    amt: "0",
  },
  {
    name: "Additional Payments",
    amt: "0",
  },
  {
    name: "Special Allowances",
    amt: "0",
  },
  {
    name: "HRA",
    amt: "0",
  },
  {
    name: "CCA",
    amt: "0",
  },
  {
    name: "Fixed PP",
    amt: "0",
  },
];
const deductionData = [
  { name: "PF", amt: "0" },
  { name: "ESI", amt: "0" },
  { name: "TDS", amt: "0" },
  { name: "LIC", amt: "0" },
  { name: "Additional Deductions", amt: "0" },
  { name: "SWF", amt: "0" },
  { name: "Interest", amt: "0" },
];

const PaySlipDetails = () => {
  return (
    <>
      <Title>PaySlip Details</Title>
      <div className="payroll-reports__payslip">
        <EmployeeDetails />
        <div className="payroll-reports__payslip--tableblock">
          <div className="row g-0 payroll-reports__payslip--tableblock--tables">
            <div className="col h-100">
              <TableContainer className="payroll-reports__payslip--tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="payroll-reports__payslip--tableblock--table--groupheader">
                      <TableCell colSpan={2}>Earning's</TableCell>
                    </TableRow>
                    <TableRow className="payroll-reports__payslip--tableblock--table--subheader">
                      <TableCell>Earning Heads</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {earningData.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{data.name}</TableCell>
                          <TableCell
                            id="td-right"
                            className="payroll-reports__payslip--tableblock--table--amount"
                          >
                            {data.amt}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="col h-100">
              <TableContainer className="payroll-reports__payslip--tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="payroll-reports__payslip--tableblock--table--groupheader">
                      <TableCell colSpan={2}>Deduction's</TableCell>
                    </TableRow>
                    <TableRow className="payroll-reports__payslip--tableblock--table--subheader">
                      <TableCell>Deduction Heads</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deductionData.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{data.name}</TableCell>
                          <TableCell
                            id="td-right"
                            className="payroll-reports__payslip--tableblock--table--amount"
                          >
                            {data.amt}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="row g-0 payroll-reports__payslip--tableblock--total">
            <div className="col payroll-reports__payslip--tableblock--total--label">
              <Label className="total">Total : </Label>
              <span className="font-green">0000</span>
            </div>
            <div className="col  payroll-reports__payslip--tableblock--total--label">
              <Label className="total">Total : </Label>
              <span className="font-red">0000</span>
            </div>
          </div>
          <div className="payroll-reports__payslip--tableblock--grandtotal">
            <Label className="total">Net Pay : </Label>
            <span>0000</span>
          </div>
        </div>
      </div>
      <Button mode="print" />
    </>
  );
};

export default PaySlipDetails;

import { useContext, useState } from "react";
import { PageFor } from "../../../utils/Enum.types";
import Template1, {
  IdTemplates,
  IdTemplatesSides,
} from "./Templates/Portrait/ThemeBased/Template1";
import Template2 from "./Templates/Portrait/FullyCustomizable/Template2";
import Template3 from "./Templates/Portrait/LightCustomizable/Template3";
import Template4 from "./Templates/Portrait/DarkCustomizable/Template4";
import { PortraitViewProps } from "./LandscapeView";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import useIdcardConfig from "./hooks/useIdcardConfig";
const PortraitView = ({
  onSelectTemplate,
  selectedTemplateId,
  setModal,
  templateName,
  selectedStudents,
  setStudentIdSet,
}: PortraitViewProps) => {
  const { dispatch } = useContext(AppContext);
  const { data, loading } = useIdcardConfig();

  const [templateId, setTemplateId] = useState<number | null>(null);
  const handleTemplateClick = (templateId: number, templateName: string) => {
    setTemplateId(templateId);
    dispatch({
      type: payloadTypes.SET_TEMPLATE_ID,
      payload: {
        templateId: data ? data.GetIdCardTemplate.id : 0,
      },
    });
    // Update templateId state with the selected template ID
    onSelectTemplate(templateId, templateName);
  };

  const TemplateList = [
    {
      id: 1,
      desc: "Theme Based",
      name: (
        <Template1
          templateFor={IdTemplates.LIST}
          setModal={setModal}
          selectedSideForPrint={IdTemplatesSides.FRONT}
          pageType={PageFor.GENERAL}
          templateId={templateId}
          templateName={templateName}
          selectedStudents={selectedStudents}
          setStudentIdSet={setStudentIdSet}
        />
      ),
    },
    {
      id: 2,
      desc: "Fully Customizable",
      name: (
        <Template2
          templateFor={IdTemplates.LIST}
          setModal={setModal}
          selectedSideForPrint={IdTemplatesSides.FRONT}
          pageType={PageFor.GENERAL}
          templateId={templateId}
          templateName={templateName}
          selectedStudents={selectedStudents}
          setStudentIdSet={setStudentIdSet}
        />
      ),
    },
    {
      id: 3,
      desc: "Light Color Customizable",
      name: (
        <Template3
          templateFor={IdTemplates.LIST}
          setModal={setModal}
          selectedSideForPrint={IdTemplatesSides.FRONT}
          pageType={PageFor.GENERAL}
          templateId={templateId}
          templateName={templateName}
          selectedStudents={selectedStudents}
          setStudentIdSet={setStudentIdSet}
        />
      ),
    },
    {
      id: 4,
      desc: "Dark Color Customizable",
      name: (
        <Template4
          templateFor={IdTemplates.LIST}
          setModal={setModal}
          selectedSideForPrint={IdTemplatesSides.FRONT}
          pageType={PageFor.GENERAL}
          templateId={templateId}
          templateName={templateName}
          selectedStudents={selectedStudents}
          setStudentIdSet={setStudentIdSet}
        />
      ),
    },
  ];
  return (
    <ul>
      {TemplateList.map((template) => (
        <li
          key={template.id}
          onClick={() => handleTemplateClick(template.id, template.desc)}
        >
          <div
            className={
              selectedTemplateId === template.id ? "selected-id-template" : ""
            }
          >
            {template.name}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PortraitView;

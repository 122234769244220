import { useLazyQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTransportRoutesData from "../../../../customhooks/general/useTransportRoutesData";
import useToken from "../../../../customhooks/useToken";

import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { RoutesDetailsTitleProps } from "../../../../Types/Titles";
import { EMPTY_RESPONSETYPE_OBJECT } from "../../../../utils/constants";
import { responseType } from "../../../../utils/Form.types";
import { RouteDetailsByData, RouteDetailsByVars } from "../../paginationTypes";
import { GetTransportRouteDetailsByMasterId } from "../../queries/list";
import useTransportTableJson from "../../json/useTransportTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { Transport_Title } = require("../../json/title.json");

interface Props {
  modalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const View = ({ modalFlag }: Props) => {
  const { Transport_Table } = useTransportTableJson();
  const { token } = useToken();
  const { InstId } = useParams();
  const [routeId, setRouteId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [searchRoutes, setSearchData] = useState("");
  const { routesData } = useTransportRoutesData(searchRoutes, false);

  const [GetRouteDetailsById, { data }] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id: routeId.value,
    },
  });
  useEffect(() => {
    if (routeId.value) {
      GetRouteDetailsById({
        variables: {
          token,
          inst_id: InstId!,
          route_master_id: routeId.value,
        },
      });
    }
  }, [GetRouteDetailsById, InstId, routeId.value, token]);

  return (
    <>
      <Title>
        {Transport_Title.MasterData.RoutesDetails.map(
          (title: RoutesDetailsTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.View}</React.Fragment>;
          }
        )}
      </Title>
      <div className="transport-route-details__view">
        <div className="transport-route-details__view--block">
          <div className="row g-0">
            <div className="col-3">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={routesData.routesDropDown}
                openOnFocus
                value={routeId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setRouteId(newValue as responseType);
                  } else {
                    setRouteId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Route"
                    onChange={(e) => setSearchData(e.target.value)}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          </div>

          <Title variant="subtitle1">
            {Transport_Title.MasterData.RoutesDetails.map(
              (title: RoutesDetailsTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.Title}</React.Fragment>
                );
              }
            )}
          </Title>
          <div className="transport-route-details__view--block--tableblock">
            {routeId.value > 0 ? (
              <TableContainer className="transport-route-details__view--block--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Transport_Table.MasterData.RouteDetails.filter(
                        (th: TableHeaderProps) =>
                          th.labelName !== "Actions" &&
                          th.labelName !== "Amount"
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.GetTransportRouteDetailsByMasterId.map(
                      (data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              id="td-center"
                              className="transport-route-details__table--slno"
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell>{data.route_stage_desc}</TableCell>
                            <TableCell className="transport-route-details__table--ledger">
                              {data.acct_ldgr_details.ldgr_desc}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </div>
        </div>
        <Button mode="cancel" onClick={() => modalFlag(false)} />
      </div>
    </>
  );
};

export default View;

import { gql } from "@apollo/client";

export const AddAcctLdgr = gql`
  mutation AddAcctLdgr(
    $token: String!
    $input: CreateAcctLdgrInput!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    AddAcctLdgr(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteAcctLdgrById = gql`
  mutation DeleteAcctLdgrById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteAcctLdgrById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateAcctLdgrById = gql`
  mutation UpdateAcctLdgrById(
    $id: ID!
    $token: String!
    $input: UpdateAcctLdgrInput!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateAcctLdgrById(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    ) {
      id
      ldgr_desc
    }
  }
`;
export const UpdateAcctLdgrIndex = gql`
  mutation UpdateAcctLdgrIndex(
    $token: String!
    $input: [UpdateAcctLdgrIndex!]!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateAcctLdgrIndex(
      token: $token
      input: $input
      user_details: $user_details
      inst_id: $inst_id
    )
  }
`;

export const AddAcctLdgrFromList = gql`
  mutation AddAcctLdgrFromList(
    $token: String!
    $inst_id: ID!
    $input: [AddAcctLdgrFromDefaultList!]!
    $user_details: SubscribedUser!
  ) {
    AddAcctLdgrFromList(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../Modules/Master/Home/Index";
import { Title } from "../stories/Title/Title";
import Input from "../stories/Input/Input";
import useMasterTableJson from "../json/useMasterTableJson";

import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { SearchUserByPhoneOrEmail } from "./Queries";
import useToken from "../customhooks/useToken";
import { EMPTY_STRING } from "../utils/constants";
import PhoneInput from "react-phone-input-2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../Types/Tables";
import Nodata from "../images/No_data_to_display.svg";
import { getDownloadUrl } from "../utils/DownloadFile";
import { PageFor } from "../utils/Enum.types";
import { Button } from "../stories/Button/Button";
import HomeForAdmin from "../Modules/UserRights/Home/Index";
import { AppContext } from "../context/context";
import Search from "../images/Regular.svg";
import LoadingModal from "../pages/LoadingModal";
import useInstDetails from "../customhooks/general/useInstDetails";
interface InstDetails {
  inst_name: string;
  inst_logo_filename: string;
}
interface StudentDetails {
  std_adm_no: string;
  std_reg_no: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  std_father_name: string;
  class: string;
  std_mobile: string;
  email: string;
  std_status: string;
  inst_details: InstDetails;
}
interface parentDetails {
  first_name: string;
  last_name: string;
  middle_name: string;
  parent_mobile: string;
  parent_email: string;
}
interface eduateDetails {
  first_name: string;
  last_name: string;
  middle_name: string;
  eduate_mobile: string;
  eduate_email: string;
}
interface employeeDetails {
  emp_first_name: string;
  emp_last_name: string;
  emp_middle_name: string;
  emp_mobile: string;
  emp_email: string;
}
interface ownerDetails {
  owner: string;
  owner_mobile: string;
  owner_email: string;
}
interface Details {
  student_details: StudentDetails[];
  parent_details: parentDetails[];
  eduate_details: eduateDetails[];
  emp_details: employeeDetails[];
  owner_details: ownerDetails[];
}
interface SearchUserByPhoneOrEmailData {
  SearchUserByPhoneOrEmail: Details;
}
interface SearchUserByPhoneOrEmailVars {
  token: string;
  inst_id: Number;
  phone_number: string;
  email_id: string;
}
type AuthCred = {
  email: string;
  mobileNo: string;
};

enum inputTypeEnum {
  email = "email",
  phone = "phone",
}
interface Props {
  pageType: PageFor;
}
const Index = ({ pageType }: Props) => {
  const { Masters_Table } = useMasterTableJson();
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { InstDetails } = useInstDetails(1);

  const { state } = useContext(AppContext);
  const [searchPhoneNo, setSearchPhoneNo] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const mobileRef = useRef<HTMLButtonElement>(null);
  const [inputType, setInputType] = useState<
    inputTypeEnum.email | inputTypeEnum.phone
  >(inputTypeEnum.email);
  const [authCred, setAuthCred] = useState<AuthCred>({
    email: "",
    mobileNo: "",
  });
  const [showResults, setShowResults] = useState(false);
  const [searchUser, { data, loading }] = useLazyQuery<
    SearchUserByPhoneOrEmailData,
    SearchUserByPhoneOrEmailVars
  >(SearchUserByPhoneOrEmail);

  const handleSearch = () => {
    let isValid = true;

    // Validate based on input type
    if (inputType === inputTypeEnum.email) {
      const emailPattern = /^\S+@\S+\.\S+$/;
      if (!emailPattern.test(searchEmail)) {
        alert("Please enter a valid email address.");
        isValid = false;
      }
    } else if (inputType === inputTypeEnum.phone) {
      if (searchPhoneNo.length < 10) {
        alert("Please enter a valid phone number.");
        isValid = false;
      }
    }

    // If valid, perform the search
    if (isValid) {
      searchUser({
        variables: {
          token,
          inst_id: InstId ? Number(InstId) : Number(state.InstId),
          phone_number:
            inputType === inputTypeEnum.phone
              ? `+${searchPhoneNo}`
              : EMPTY_STRING,
          email_id:
            inputType === inputTypeEnum.email ? searchEmail : EMPTY_STRING,
        },
      }).then(() => {
        setShowResults(true);
      });
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedAuthCred = { ...authCred };

    if (/^\S+@\S+\.\S+$/.test(value)) {
      updatedAuthCred.email = value;
      setInputType(inputTypeEnum.email);
    } else if (/^\d+$/.test(value)) {
      updatedAuthCred.mobileNo = value;
      setInputType(inputTypeEnum.phone);
      setSearchPhoneNo(value);
    } else {
      updatedAuthCred[name as keyof AuthCred] = value;
    }

    setAuthCred(updatedAuthCred);
    setSearchEmail(value);
  };

  useEffect(() => {
    if (inputType === inputTypeEnum.phone) {
      mobileRef.current?.focus();
    }
  }, [inputType]);

  const handleClear = () => {
    setInputType(inputTypeEnum.email);
    setAuthCred({
      email: "",
      mobileNo: "",
    });
    setSearchEmail("");
    setSearchPhoneNo("");
    setShowResults(false);
  };

  const [imageString, setImageString] = useState("");

  useEffect(() => {
    const logoFilename =
      InstDetails.data && InstDetails.data.nodes.length
        ? InstDetails.data.nodes[0].inst_logo_filename
        : EMPTY_STRING;

    if (logoFilename && logoFilename !== "") {
      getDownloadUrl(logoFilename, false, setImageString);
    }
  }, [InstDetails.data]);

  return (
    <>
      {pageType === PageFor.GENERAL ? (
        <Home DashBoardRequired={false} />
      ) : (
        <HomeForAdmin DashBoardRequired={false} />
      )}

      <Title>Global Search</Title>
      <div className="global-search">
        <div className="global-search__flex row g-0">
          <div className="col-4">
            {inputType === inputTypeEnum.phone ? (
              <PhoneInput
                country={"in"}
                value={searchPhoneNo}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    mobileRef.current?.focus();
                  }
                }}
                onChange={(e) => setSearchPhoneNo(e)}
                inputProps={{
                  required: true,
                  ref: mobileRef,
                }}
              />
            ) : (
              <Input
                type="text"
                required
                id="email"
                name="email"
                value={searchEmail}
                className="loginfield"
                autoFocus
                placeholder="Search"
                onChange={handleChange}
              />
            )}
          </div>
          <div className="col-3">
            <Button onClick={handleSearch}>
              <img src={Search} alt="" className="image-white" />
              Search
            </Button>
            <Button mode="clear" onClick={handleClear} />
          </div>
        </div>

        <div className="global-search__tableblock">
          {showResults && data ? (
            <TableContainer className="global-search__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Masters_Table.GlobalSearch.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return <TableCell>{th.labelName}</TableCell>;
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                  data.SearchUserByPhoneOrEmail &&
                  data.SearchUserByPhoneOrEmail.student_details
                    ? data.SearchUserByPhoneOrEmail.student_details.map(
                        (std, stdIndex) => (
                          <TableRow key={stdIndex}>
                            <TableCell id="td-center">{stdIndex + 1}</TableCell>
                            <TableCell>{std.std_adm_no}</TableCell>
                            <TableCell>{std.std_reg_no}</TableCell>

                            <TableCell>{`${std.first_name} ${std.middle_name} ${std.last_name}`}</TableCell>
                            <TableCell>{std.std_father_name}</TableCell>
                            <TableCell>{std.class}</TableCell>
                            <TableCell>{std.std_mobile}</TableCell>
                            <TableCell>{std.email}</TableCell>
                            <TableCell>{std.std_status}</TableCell>
                            <TableCell>STUDENT</TableCell>
                            <TableCell>
                              {imageString ? (
                                <img src={imageString} alt="" />
                              ) : null}

                              {std.inst_details.inst_name}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    : null}
                  {data &&
                  data.SearchUserByPhoneOrEmail &&
                  data.SearchUserByPhoneOrEmail.parent_details
                    ? data.SearchUserByPhoneOrEmail.parent_details.map(
                        (parent, parentIndex) => (
                          <TableRow key={parentIndex}>
                            <TableCell id="td-center">
                              {parentIndex + 1}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>

                            <TableCell>{`${parent.first_name} ${parent.middle_name} ${parent.last_name}`}</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>{parent.parent_mobile}</TableCell>
                            <TableCell>{parent.parent_email}</TableCell>
                            <TableCell id="td-center"></TableCell>
                            <TableCell>PARENT</TableCell>
                            <TableCell id="td-center">
                              {imageString ? (
                                <img src={imageString} alt="" />
                              ) : null}
                              {InstDetails.data?.nodes[0].inst_name}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    : null}
                  {data &&
                  data.SearchUserByPhoneOrEmail &&
                  data.SearchUserByPhoneOrEmail.emp_details
                    ? data.SearchUserByPhoneOrEmail.emp_details.map(
                        (emp, empIndex) => (
                          <TableRow key={empIndex}>
                            <TableCell>{empIndex + 1}</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>
                              {emp.emp_first_name +
                                emp.emp_middle_name +
                                emp.emp_last_name}
                            </TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>{emp.emp_mobile}</TableCell>
                            <TableCell>{emp.emp_email}</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>EMPLOYEE</TableCell>
                            <TableCell id="td-center">
                              {imageString ? (
                                <img src={imageString} alt="" />
                              ) : null}
                              {InstDetails.data?.nodes[0].inst_name}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    : null}
                  {data &&
                  data.SearchUserByPhoneOrEmail &&
                  data.SearchUserByPhoneOrEmail.owner_details
                    ? data.SearchUserByPhoneOrEmail.owner_details.map(
                        (owner, ownerIndex) => (
                          <TableRow key={ownerIndex}>
                            <TableCell id="td-center">
                              {ownerIndex + 1}
                            </TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>{owner.owner}</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>{owner.owner_mobile}</TableCell>
                            <TableCell>{owner.owner_email}</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>OWNER</TableCell>
                            <TableCell id="td-center">
                              {imageString ? (
                                <img src={imageString} alt="" />
                              ) : null}
                              {InstDetails.data?.nodes[0].inst_name}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    : null}
                  {data &&
                  data.SearchUserByPhoneOrEmail &&
                  data.SearchUserByPhoneOrEmail.eduate_details
                    ? data.SearchUserByPhoneOrEmail.eduate_details.map(
                        (eduate, eduateIndex) => (
                          <TableRow key={eduateIndex}>
                            <TableCell id="td-center">
                              {eduateIndex + 1}
                            </TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>
                              {eduate.first_name +
                                eduate.middle_name +
                                eduate.last_name}
                            </TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell id="td-center">-</TableCell>
                            <TableCell>{eduate.eduate_mobile}</TableCell>
                            <TableCell>{eduate.eduate_email}</TableCell>
                          </TableRow>
                        )
                      )
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="global-search__tableblock--image">
              <img src={Nodata} alt="/" className="no-data" />
            </div>
          )}
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={loading} />
    </>
  );
};

export default Index;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { singleNodeVars } from "../../../Types/Accounting";
import { GetTestConducteDetailsByNode } from "../queries/test/query";
import { GetAcdTestClassDetails } from "./useTestClassData";
import { responseType } from "../../../utils/Form.types";

export interface GetAcdTestSubjectsByTestConductIdData {
  node: GetAcdTestClassDetails;
}

const useTestDetailsForAllotedLevel = () => {
  const { testId, testClassId } = useParams();

  const { token } = useToken();
  const [subjectsOptions, setSubjectOptions] = useState<responseType[]>([]);
  const [GetAcdSubjects, { data, loading, error }] = useLazyQuery<
    GetAcdTestSubjectsByTestConductIdData,
    singleNodeVars
  >(GetTestConducteDetailsByNode, {
    variables: {
      token,
      id: Number(testClassId) || Number(testId),
    },
  });

  useEffect(() => {
    if ((Number(testClassId) || testId) && token) {
      GetAcdSubjects().then(({ data }) => {
        if (data) {
          setSubjectOptions(
            data.node.class_subject_details.map((subject) => ({
              label: subject.subject_master_details.subj_desc,
              value: subject.subject_master_details.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [testClassId, GetAcdSubjects, testId, token]);
  return { testConductDetails: { data, loading, error, subjectsOptions } };
};

export default useTestDetailsForAllotedLevel;

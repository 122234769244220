import React from "react";
import icon from "../../../images/Aws-dark.svg";
import { handleSignOut } from "../../../components/common/Header";

const PaymentIssue = () => {
  return (
    <>
      {/* <Home DashBoardRequired={false} NavType={ LicenseTypes.EDUATE_CUSTOMER}/> */}

      <div className="inst-payment-issue">
        <div className="inst-payment-issue__title">
          <img src={icon} alt="" />
          <b>Amazon web Service Suspended</b>
        </div>
        <div className="inst-payment-issue__block">
          <p>
            Your access to our service has been temporarily suspended due to
            non-payment of service fees. Please settle your outstanding balance
            to restore access,have any questions regarding your account status
            please contact your service provider.
          </p>
          <p>
            If you do not pay or provide a payment method to resolve your
            outstanding balance, your account resources may be terminated
          </p>
          <button onClick={handleSignOut}>Go back</button>
        </div>
      </div>
    </>
  );
};

export default PaymentIssue;

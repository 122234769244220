import React from 'react'
import useSwConfigData from '../../../customhooks/useSwConfigData';
import { ChatConfigKeys } from '../../../utils/Enum.types';
import { GlobalPageConfigData } from '../../../Types/configtypes';
import useEmpDetailsById from '../../HR/hooks/useEmpDetailsById';


const useChannelConfigs = () => {

    const { configData } = useSwConfigData([
        ChatConfigKeys.ENABLE_ANNOUNCEMENTS,
        ChatConfigKeys.ENABLE_CLASS_LEVEL_CHANNELS,
        ChatConfigKeys.ENABLE_INDIVIDUAL_CHANNELS,
        ChatConfigKeys.ENABLE_MY_CAMPUS_FEEDS,
      ]);
    
      const { employeeFormData } = useEmpDetailsById(true);
    
      const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
        let enableAnnouncement = false;
        let enableClassLevelChannels = false;
        let enableIndividualChannels = false;
        let enableFeeds = false;
        if (data) {
          data.forEach((item) => {
            switch (item.config_key) {
              case ChatConfigKeys.ENABLE_ANNOUNCEMENTS:
                enableAnnouncement = item.config_boolean_value;
                break;
              case ChatConfigKeys.ENABLE_CLASS_LEVEL_CHANNELS:
                enableClassLevelChannels = item.config_boolean_value;
                break;
              case ChatConfigKeys.ENABLE_INDIVIDUAL_CHANNELS:
                enableIndividualChannels = item.config_boolean_value;
                break;
              case ChatConfigKeys.ENABLE_MY_CAMPUS_FEEDS:
                enableFeeds = item.config_boolean_value;
                break;
              default:
                break;
            }
          });
        }
        return {
          enableAnnouncement,
          enableClassLevelChannels,
          enableIndividualChannels,
          enableFeeds,
        };
      };
    
      const {
        enableAnnouncement,
        enableClassLevelChannels,
        enableFeeds,
        enableIndividualChannels,
      } = filterDataByConfigKey(
        configData.data ? configData.data.GetSwConfigVariables : []
      );
    
  return {
    empAllowedToFeeds: employeeFormData.can_send_feeds,
    empAllowedToAnnounce: employeeFormData.can_send_announcement,
    enableAnnouncement,
    enableClassLevelChannels,
    enableFeeds,
    enableIndividualChannels,
  }
}

export default useChannelConfigs

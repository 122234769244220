import { FormControlLabel, FormGroup } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AntSwitch } from "../../../pages/Switch";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { LabelNameProps } from "../../../Types/Labels";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import Close from "../../../images/Close.svg";
import Input from "../../../components/common/Input/Index";
import { SubjectFormData } from "../types/subject/Subject";
import { Form, Formik } from "formik";
import { addtionOfSubjectValidation } from "../../../utils/academicValidation";
import { useLazyQuery, useMutation } from "@apollo/client";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { msgType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useAcademicYear from "../hooks/useActiveAcademicYear";
import {
  AddAcdSubjectMaster,
  UpdateAcdSubjectMaster,
} from "../queries/subjects/mutation/Index";
import {
  GetAcdSubject,
  GetSubjectById,
} from "../queries/subjects/List.tsx/Index";
import { EMPTY_STRING } from "../../../utils/constants";
import { SubjectsByAcdYear } from "../../../utils/studentAcdEnum.types";
import {
  handleMUISelectEvent,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import { Keys } from "../../../utils/Enum.keys";
import { AppContext } from "../../../context/context";
import { GetSubjectByIdData } from "../hooks/useAcademicSubjects";
import { singleNodeVars } from "../../../Types/Accounting";
import { payloadTypes } from "../../../context/reducer";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
const { AcademicsDetails } = require("../json/formLabels.json");
interface Props {
  operation: Operation;
  setAddSubjectDetails: React.Dispatch<React.SetStateAction<boolean>>;
}
const NewSubject = ({ operation, setAddSubjectDetails }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state, dispatch } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();

  const { activeAcademicYearData } = useAcademicYear();
  const [formData, setFormData] = useState<SubjectFormData>({
    subj_apptitude_avialed: false,
    subj_board_code: "",
    subj_code: "",
    subj_desc: "",
    subj_idx: 0,
    subj_internals_avialed: false,
    subj_is_core_subject: false,
    subj_is_elective: false,
    subj_is_lang: false,
    subj_is_non_academic: false,
    subj_marks_in_grades: false,
    subj_is_lab: false,
    teacher_count: 0,
  });
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [AddNewSubject, { loading: AddSubjectLoading }] = useMutation(
    AddAcdSubjectMaster,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateSubject, { loading: UpdateSubjectLoading }] = useMutation(
    UpdateAcdSubjectMaster,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetSubject, { data }] = useLazyQuery<
    GetSubjectByIdData,
    singleNodeVars
  >(GetSubjectById, { variables: { id: state.subjectId, token } });
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSwitch = (e: React.MouseEvent) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement)
        .checked,
    }));
  };
  const handleNewSubjects = () => {
    AddNewSubject({
      variables: {
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        token,
        inst_id: InstId,
        user_details,
        input: {
          subj_apptitude_avialed: formData.subj_apptitude_avialed,
          subj_board_code: removeMoreSpace(formData.subj_board_code),
          subj_code: removeMoreSpace(formData.subj_code),
          subj_desc: removeMoreSpace(formData.subj_desc),
          subj_idx: 0,
          subj_internals_avialed: formData.subj_internals_avialed,
          subj_is_core_subject: formData.subj_is_core_subject,
          subj_is_elective: formData.subj_is_elective,
          subj_is_lang: formData.subj_is_lang,
          subj_is_non_academic: formData.subj_is_non_academic,
          subj_marks_in_grades: formData.subj_marks_in_grades,
          subj_is_lab: formData.subj_is_lab,
        },
      },
      refetchQueries: [
        {
          query: GetAcdSubject,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            after: null,
            direction: Direction.ASC,
            name: EMPTY_STRING,
            token,
            sortBy: SortBy.SUBJ_CODE,
            input: {
              acd_subj_query_type: SubjectsByAcdYear.ACD_SUBJ_BY_INST_ID,
              ids: [Number(InstId)],
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Subject Created Sucessfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleUpdateSubject = () => {
    UpdateSubject({
      variables: {
        token,
        id: state.subjectId,
        inst_id: InstId,
        user_details,
        input: {
          subj_apptitude_avialed: formData.subj_apptitude_avialed,
          subj_board_code: removeMoreSpace(formData.subj_board_code),
          subj_code: removeMoreSpace(formData.subj_code),
          subj_desc: removeMoreSpace(formData.subj_desc),
          subj_internals_avialed: formData.subj_internals_avialed,
          subj_is_core_subject: formData.subj_is_core_subject,
          subj_is_elective: formData.subj_is_elective,
          subj_is_lang: formData.subj_is_lang,
          subj_is_non_academic: formData.subj_is_non_academic,
          subj_marks_in_grades: formData.subj_marks_in_grades,
          subj_is_lab: formData.subj_is_lab,
        },
      },
      refetchQueries: [
        {
          query: GetAcdSubject,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            after: null,
            direction: Direction.ASC,
            name: EMPTY_STRING,
            token,
            sortBy: SortBy.SUBJ_CODE,
            input: {
              acd_subj_query_type: SubjectsByAcdYear.ACD_SUBJ_BY_INST_ID,
              ids: [Number(InstId)],
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Subject Updated Sucessfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleClear = () => {
    setFormData({
      id: 0,
      subj_apptitude_avialed: false,
      subj_board_code: "",
      subj_code: "",
      subj_desc: "",
      subj_idx: 0,
      subj_internals_avialed: false,
      subj_is_core_subject: false,
      subj_is_elective: false,
      subj_is_lang: false,
      subj_is_non_academic: false,
      subj_marks_in_grades: false,
      subj_is_lab: false,
      teacher_count: 0,
    });
    dispatch({
      type: payloadTypes.SET_SUBJECT_ID,
      payload: { subjectId: 0 },
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setAddSubjectDetails(false);
    }
    setMessage({
      flag: false,
      operation: Operation.NONE,
      message: "",
    });
  };

  useEffect(() => {
    if (operation === Operation.UPDATE && state.subjectId) {
      GetSubject().then(({ data }) => {
        if (data && data.node) {
          const { node } = data;

          setFormData({
            subj_apptitude_avialed: node?.subj_apptitude_avialed,
            subj_board_code: node?.subj_board_code,
            subj_code: node?.subj_code,
            subj_desc: node?.subj_desc,
            subj_idx: 0,
            subj_internals_avialed: node?.subj_internals_avialed,
            subj_is_core_subject: node?.subj_is_core_subject,
            subj_is_elective: node?.subj_is_elective,
            subj_is_lang: node?.subj_is_lang,
            subj_is_non_academic: node?.subj_is_non_academic,
            subj_marks_in_grades: node?.subj_marks_in_grades,
            subj_is_lab: node?.subj_is_lab,
            teacher_count: node.teacher_count,
          });
        }
      });
    }
  }, [operation, GetSubject, state.subjectId, data]);

  return (
    <>
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <Title>
            {operation === Operation.UPDATE
              ? "Update Subject Details"
              : "Add Subject Details"}
          </Title>

          <Formik
            initialValues={formData}
            validationSchema={addtionOfSubjectValidation}
            enableReinitialize
            onSubmit={
              operation === Operation.UPDATE
                ? handleUpdateSubject
                : handleNewSubjects
            }
          >
            {(meta) => {
              return (
                <Form>
                  <>
                    {AcademicsDetails.AcademicsSubjectDetails?.Labels?.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              required={label.required}
                              autoFocus={label.autoFocus}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    {AcademicsDetails.AcademicsSubjectDetails?.AdditionalDetails?.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <FormGroup
                            key={index}
                            className="academic-subject-details__form-labels"
                          >
                            <FormControlLabel
                              label={label.LabelName}
                              name={label.inputName}
                              checked={formData[label.inputName] as boolean}
                              control={
                                <AntSwitch
                                  onClick={(e: React.MouseEvent) =>
                                    handleSwitch(e)
                                  }
                                  onKeyDown={(e: React.KeyboardEvent) => {
                                    if (e.key === Keys.ENTER) {
                                      handleMUISelectEvent(e);
                                    }
                                  }}
                                />
                              }
                              labelPlacement="start"
                            />
                          </FormGroup>
                        );
                      }
                    )}
                  </>
                  <Button mode="save" type="submit" />
                  <Button mode="clear" type="button" onClick={handleClear} />
                  <Button
                    mode="cancel"
                    onClick={() => {
                      setAddSubjectDetails(false);
                      handleClear();
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="modal-flex__image">
          <img
            src={Close}
            alt="/"
            onClick={() => setAddSubjectDetails(false)}
          />
        </div>
      </div>
      <LoadingModal flag={AddSubjectLoading || UpdateSubjectLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default NewSubject;

import { gql } from "@apollo/client";

export const AddAcdSubjectTeacherAssoci = gql`
  mutation AddAcdSubjectTeacherAssoci(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_ids: [ID!]!
    $subj_master_id: ID!
    $acd_yr_id: ID!
  ) {
    AddAcdSubjectTeacherAssoci(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      pr_emp_ids: $pr_emp_ids
      subj_master_id: $subj_master_id
    )
  }
`;
export const ImportAcdTeacherClassAssoci = gql`
  mutation ImportAcdTeacherClassAssoci(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: ClassTeacherSubjectAssoicData!
  ) {
    ImportAcdTeacherClassAssoci(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;

export const AddOrDeleteAcdTeacherClassAssoci = gql`
  mutation AddOrDeleteAcdTeacherClassAssoci(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $entry_id: ID!
    $entry_level: String!
    $per_std_subj_allocation: Boolean!
    $input: TeacherClassAssociData!
  ) {
    AddOrDeleteAcdTeacherClassAssoci(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      entry_id: $entry_id
      entry_level: $entry_level
      per_std_subj_allocation: $per_std_subj_allocation
      input: $input
    )
  }
`;
export const UpdateAcdTeacherClassAssoci = gql`
  mutation UpdateAcdTeacherClassAssoci(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $entry_id: ID!
    $entry_level: String!
    $pr_emp_id: ID!
    $is_class_teacher: Boolean!
    $per_std_subj_allocation: Boolean!
  ) {
    UpdateAcdTeacherClassAssoci(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      entry_id: $entry_id
      entry_level: $entry_level
      pr_emp_id: $pr_emp_id
      is_class_teacher: $is_class_teacher
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;
export const AddAcdFeedbackFromDetails = gql`
  mutation AddAcdFeedbackFromDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $allotted_ids: [ID!]!
    $allotted_level: String!
    $input: FeedbackFrom!
  ) {
    AddAcdFeedbackFromDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      allotted_ids: $allotted_ids
      allotted_level: $allotted_level
      input: $input
    )
  }
`;
export const UpdateAcdFeedbackFromDetails = gql`
  mutation UpdateAcdFeedbackFromDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: FeedbackFrom!
  ) {
    UpdateAcdFeedbackFromDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;
export const DeleteAcdFeedbackFromDetails = gql`
  mutation DeleteAcdFeedbackFromDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $form_name: String!
  ) {
    DeleteAcdFeedbackFromDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      form_name: $form_name
    )
  }
`;

import { gql } from "@apollo/client";

export const GetAcdNotAllocatedIds = gql`
  query GetAcdNotAllocatedIds(
    $token: String!
    $inst_id: ID!
    $table: TableType!
    $table_id: ID!
  ) {
    GetAcdNotAllocatedIds(
      token: $token
      inst_id: $inst_id
      table: $table
      table_id: $table_id
    ) {
      acd_dept_id
      acd_branch_id
      acd_class_id
      acd_semester_id
      acd_section_id
    }
  }
`;
export const GetAcdInstConfigNames = gql`
  query GetAcdInstConfigNames(
    $token: String!
    $entry_level: String!
    $entry_id: ID!
  ) {
    GetAcdInstConfigNames(
      token: $token
      entry_level: $entry_level
      entry_id: $entry_id
    ) {
      acd_dept_desc
      acd_dept_id
      acd_branch_desc
      acd_branch_id
      acd_class_desc
      acd_class_id
      acd_semester_desc
      acd_semester_id
      acd_section_desc
      acd_section_id
    }
  }
`;

export const GetAcdStudentAttendanceCount = gql`
  query GetAcdStudentAttendanceCount(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $entry_level: String!
    $entry_id: ID!
  ) {
    GetAcdStudentAttendanceCount(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      entry_level: $entry_level
      entry_id: $entry_id
    ) {
      present_count
      absent_count
      total_std_count
      att_percentage
    }
  }
`;
export const GetAcdFeedbackFormDetails = gql`
  query GetAcdFeedbackFormDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $form_name: String!
  ) {
    GetAcdFeedbackFormDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      form_name: $form_name
    ) {
      id
      rating_factor
      idx
      inst_id
      acd_yr_id
      form_name_id

      from_name_details {
        id
        form_name
        form_st_date
        form_end_date
        is_academic
        inst_id
        acd_yr_id
      }
    }
  }
`;
export const GetAcdFeedbackForms = gql`
  query GetAcdFeedbackForms(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $pr_emp_id: ID!
    $allotted_level: String!
    $allotted_id: ID!
    $today_date: Time!
    $query_type: AcdFeedbackFormQueryType!
  ) {
    GetAcdFeedbackForms(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      allotted_level: $allotted_level
      allotted_id: $allotted_id
      today_date: $today_date
      query_type: $query_type
    ) {
      id
      form_name
      form_st_date
      form_end_date
      is_academic
      inst_id
      acd_yr_id
      que_count
      is_report_generated
    }
  }
`;

export const GetAcdFeedbackStdRating = gql`
  query GetAcdFeedbackStdRating(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $allotted_level: String!
    $allotted_id: ID!
    $query_type: StdRatingQueryData!
    $perStdSubjAllocation: Boolean!
  ) {
    GetAcdFeedbackStdRating(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      allotted_level: $allotted_level
      allotted_id: $allotted_id
      query_type: $query_type
      perStdSubjAllocation: $perStdSubjAllocation
    ) {
      id
      rating
      idx
      remarks
      acd_class_id
      acd_semester_id
      acd_section_id
      fb_form_detail_id
      pr_emp_id
      student_id
      subj_master_id
      form_name_id
      inst_id
      acd_yr_id
      fb_form_details {
        rating_factor
      }
    }
  }
`;
export const GetFormDataByFormId = gql`
  query AcdFeedbackForm($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcdFeedbackForm {
        id
        form_name
        form_st_date
        form_end_date
        is_academic
        inst_id
        acd_yr_id
      }
    }
  }
`;

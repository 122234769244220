import {
  
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { LabelNameProps } from "../../../../Types/Labels";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import TextArea from "../../../../stories/TextArea/TextArea";
import Common from "./Common";
import { Operation } from "../../../../utils/Enum.types";
import { FormAutocomplete } from "../../../../styles/AutocompleteStyles";
import { labelClasses } from "../../../../styles/AutocompleteListStyles";
const { VMS_Form } = require("../../../json/form.json");
interface Props {
  step: number;
  operation: Operation;
}

const stepHeader = () => {
  return [
    "Vehicle Information",
    "Vehicle Purchase and Other Details",
    "Vehicle Current Details",
  ];
};
const VehiclePurchase = ({ step, operation }: Props) => {
  const stepsHeader = stepHeader();
  const [boughtNewFlag, selectBoughtNewFlag] = useState(false);
  const [CCTVenabled, setCCTVenabled] = useState(false);
  const [GPSenabled, setGPSenabled] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="vms-v-details-new">
        {operation === Operation.CREATE && (
          <div className="vms-v-details-new__stepper">
            <Stepper alternativeLabel activeStep={step - 1}>
              {stepsHeader.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        )}

        <Title>
          {operation === Operation.UPDATE
            ? "Update Vehicle Purchase and Other Details"
            : "Vehicle Purchase and Other Details"}
        </Title>
        <Common />
        <div
          className={
            operation === Operation.UPDATE
              ? "vms-v-details-new__other-details--data--update row g-0"
              : "vms-v-details-new__other-details--data row g-0"
          }
        >
          <div className="col">
            {VMS_Form.Vehicle_Details.OtherDetails.map(
              (label: LabelNameProps, index: React.Key) => {
                return (
                  <div className="label-grid" key={index}>
                    <Label>{label.LabelName}</Label>
                    <Input />
                  </div>
                );
              }
            )}
            <div className="vms-v-details-new__other-details--data--grid">
              <Label>Brought New</Label>
              <FormAutocomplete
                className={labelClasses.inputRoot}
                options={["Yes", "No"]}
                forcePopupIcon
                onChange={(event, newValue) =>
                  selectBoughtNewFlag(newValue === "No")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />

              {boughtNewFlag && (
                <>
                  <Label>Bought KM Reading</Label>
                  <Input type="date" />
                </>
              )}
            </div>

            <div className="label-grid">
              <Label>Target KMPL</Label>
              <Input />
            </div>
            <div className="vms-v-details-new__other-details--data--grid">
              <Label>CCTV enabled</Label>
              <FormAutocomplete
                className={labelClasses.inputRoot}
                options={["Yes", "No"]}
                forcePopupIcon
                onChange={(event, newValue) =>
                  setCCTVenabled(newValue === "Yes")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />

              {CCTVenabled && (
                <>
                  <Label>CCTV Enabled Date</Label>
                  <Input type="date" />
                </>
              )}
            </div>
            {CCTVenabled && (
              <div className="label-grid">
                <Label>CCTV Enabled Remarks</Label>
                <Input />
              </div>
            )}

            <div className="vms-v-details-new__other-details--data--grid">
              <Label>GPS Enabled</Label>
              <FormAutocomplete
                className={labelClasses.inputRoot}
                options={["Yes", "No"]}
                forcePopupIcon
                onChange={(event, newValue) =>
                  setGPSenabled(newValue === "Yes")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />

              {GPSenabled && (
                <>
                  <Label>GPS Enabled Date</Label>
                  <Input type="date" />
                </>
              )}
            </div>
            {GPSenabled && (
              <>
                <div className="label-grid">
                  <Label>GPS Enabled Remarks</Label>
                  <Input />
                </div>
              </>
            )}
          </div>
          <div className="col">
            {VMS_Form.Vehicle_Details.PurchaseDetails.map(
              (label: LabelNameProps, index: React.Key) => {
                return (
                  <div className="label-grid" key={index}>
                    <Label>{label.LabelName}</Label>
                    <Input type={label.dataType} />
                  </div>
                );
              }
            )}
            <div className="label-grid">
              <Label>Remarks</Label>
              <TextArea rows={2} />
            </div>
          </div>
        </div>
        {operation === Operation.UPDATE ? (
          <Button mode="save" />
        ) : (
          <Button
            mode="save-continue"
            onClick={() => navigate(`/vms/vehicledetails/new/currentdetails`)}
          />
        )}

        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default VehiclePurchase;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetAcdInstConfigNames } from "../queries/general";
import useToken from "../../../customhooks/useToken";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
} from "../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../utils/constants";
import useAcdLevel from "../../Accounts/hooks/useAcdLevel";

interface GetAcdInstConfigNamesData {
  GetAcdInstConfigNames: {
    acd_dept_desc: string;
    acd_dept_id: number;
    acd_branch_desc: string;
    acd_branch_id: number;
    acd_class_desc: string;
    acd_class_id: number;
    acd_semester_desc: string;
    acd_semester_id: number;
    acd_section_desc: string;
    acd_section_id: number;
  };
}
interface GetAcdInstConfigNamesVars {
  token: string;
  entry_level: string;
  entry_id: string | number;
}

interface AllotedType {
  id: number;
  level: string;
}
const useInstConfigByEntryId = (entry_id: string | number) => {
  const { token } = useToken();
  const [alloted, setAlloted] = useState<AllotedType>({
    id: 0,
    level: EMPTY_STRING,
  });

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const { acdLevel } = useAcdLevel(entry_id.toString());

  const alloted_level = configData.data
    ? configData.data?.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;

  const [GetInstDetails, { data, loading, error }] = useLazyQuery<
    GetAcdInstConfigNamesData,
    GetAcdInstConfigNamesVars
  >(GetAcdInstConfigNames, {
    variables: {
      token,
      entry_id,
      entry_level: acdLevel,
    },
  });
  useEffect(() => {
    if (token && acdLevel && entry_id) {
      GetInstDetails().then(({ data, error }) => {
        if (data && data.GetAcdInstConfigNames && configData.data) {
          const alloted_level =
            configData.data?.GetSwConfigVariables[0].config_string_value;
          const { acd_class_id, acd_semester_id, acd_section_id } =
            data.GetAcdInstConfigNames;

          if (alloted_level === AcdSubjectAllocationData.SECTION) {
            setAlloted({
              id: acd_section_id,
              level: alloted_level,
            });
          } else if (alloted_level === AcdSubjectAllocationData.SEMESTER) {
            setAlloted({
              id: acd_semester_id,
              level: alloted_level,
            });
          } else if (alloted_level === AcdSubjectAllocationData.CLASS) {
            setAlloted({
              id: acd_class_id,
              level: alloted_level,
            });
          }
        }
      });
    }
  }, [token, acdLevel, GetInstDetails, configData.data, entry_id, data]);

  return {
    InstConfigDetails: { data, loading, error, alloted, alloted_level },
  };
};

export default useInstConfigByEntryId;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";

import { Button } from "../../../../../stories/Button/Button";
import {
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import { AppContext } from "../../../../../context/context";

import { msgType, responseType } from "../../../../../utils/Form.types";
import { EMPTY_STRING } from "../../../../../utils/constants";

import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useToken from "../../../../../customhooks/useToken";
import {
  GetStdTestMarks,
  GetStdTestMarksByStudentId,
  GetTestConducteDetailsByNode,
} from "../../../queries/test/query";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";

import {
  AcdTestMarksIndividual,
  sub_marks,
} from "../../../DailyActivities/Exam/UpdateMarks";
import { PageInfo } from "../../../../../Types/Student/paginationTypes";
import MessageModal from "../../../../../pages/MessageModal";
import { UpdateAcdTestMarksByStudentID } from "../../../queries/test/mutation";
import { AntSwitch } from "../../../../../pages/Switch";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import StudentDetails from "./StudentDetailsComponent";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";
import { Keys } from "../../../../../utils/Enum.keys";
import useUserRightsByEmpId from "../../../../UserRights/hooks/useUserRightsByEmpId";
import { GetAcdTeacherClassAssociations } from "../../../queries/subjects/List.tsx/Index";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsData,
  GetAcdTeacherClassAssociationsVars,
} from "../../../types/subject/Subject";
import { GlobalPageConfigData } from "../../../../../Types/configtypes";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  GetAcdGradeScalesByInstIdData,
  GetAcdGradeScalesByInstIdVars,
} from "../../../types/grade";
import { GetAcdGradeScalesByInstId } from "../../../queries/grade";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../../styles/AutocompleteStyles";
interface Props {
  modalFlag: boolean;
  testDetails: responseType | null;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
interface GetStdTestMarksByStudentIdVars {
  token: string;
  acd_yr_id: number;
  inst_id: string;
  student_id: number;
  acd_test_class_id: number;
  entry_level: string;
  entry_id: string;
  after: string | null;
  first: number | null;
  per_std_subj_allocation: boolean;
  orderBy: {
    direction: string;
    field: string;
  };
}
interface GetStdTestMarksByStudentIdEdges {
  node: AcdTestMarksIndividual;
  cursor: string;
}
interface GetStdTestMarksByStudentIdDetails {
  totalCount: number;
  pageInfo: PageInfo;
  edges: GetStdTestMarksByStudentIdEdges[];
}
interface GetStdTestMarksByStudentIdData {
  GetStdTestMarksByStudentId: GetStdTestMarksByStudentIdDetails;
}

const ParticularStudentResultsDailyAct = ({
  modalFlag,
  testDetails,
  setModalFlag,
}: Props) => {
  const { entryId, InstId, testId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const { flag } = useCheckAllocationType();
  const [ext_marks, setext_marks] = useState(0);
  const [gradeScored, setgradeScored] = useState("");
  const [remarks, setRemarks] = useState("");

  const [updatedStudentMarks, setUpdatedStudentMarks] = useState<
    Map<number, sub_marks>
  >(new Map());
  const [attChangeState, setAttMarksChangeState] = useState(false);
  const { state } = useContext(AppContext);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const saveRef = useRef<HTMLButtonElement>(null);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [edit, setEdit] = useState(false);
  const { userRights } = useUserRightsByEmpId();

  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_TEACHER_TO_EDIT_TEST_MARKS,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableTeacherMarksEdit = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ENABLE_TEACHER_TO_EDIT_TEST_MARKS:
            enableTeacherMarksEdit = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enableTeacherMarksEdit,
    };
  };

  const { enableTeacherMarksEdit } = filterDataByConfigKey(
    configData.data ? configData.data.GetSwConfigVariables : []
  );
  const [GetSubjectsTeachersAssociated, { data: TeacherData }] = useLazyQuery<
    GetAcdTeacherClassAssociationsData,
    GetAcdTeacherClassAssociationsVars
  >(GetAcdTeacherClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
      per_std_subj_allocation: flag,
      input: {
        query_type: AcdTeacherClassQueryType.All_TEACHERS_BY_ENTRY_ID,
        entry_id: Number(entryId)!,
        entry_level: entry_level,
        pr_emp_id: 0,
        subj_master_id: 0,
      },
    },
  });

  const findClassTecaher = TeacherData
    ? TeacherData.GetAcdTeacherClassAssociations.find(
        ({ pr_emp_id }) => pr_emp_id === state.empLoginId
      )
    : null;

  const enableEdit =
    userRights.data && userRights.data.GetUserRightsByEmpId.is_inst_sysadmin
      ? true
      : findClassTecaher
      ? findClassTecaher.is_class_teacher && enableTeacherMarksEdit
      : false;

  const [GetMarks, { data, error }] = useLazyQuery<
    GetStdTestMarksByStudentIdData,
    GetStdTestMarksByStudentIdVars
  >(GetStdTestMarksByStudentId, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_id: testDetails?.value!,
      after: null,
      entry_id: entryId!,
      student_id: state.studentId,
      entry_level: entry_level,
      first: null,
      inst_id: InstId!,
      per_std_subj_allocation: flag!,
      orderBy: {
        direction: Direction.ASC,
        field: "SUBJ_SL",
      },
    },
    fetchPolicy: "network-only",
  });
  const isCoCurricular =
    data &&
    data.GetStdTestMarksByStudentId.edges.find(
      (res) => res.node.subject_details.subj_is_non_academic
    );

  const isRemarksEnabled =
    data &&
    data.GetStdTestMarksByStudentId.edges.find(
      (res) => res.node.acd_test_class_details.enable_teachers_comments
    );

  const [GetAcdGradeScaleDetails, { data: gradeDetails }] = useLazyQuery<
    GetAcdGradeScalesByInstIdData,
    GetAcdGradeScalesByInstIdVars
  >(GetAcdGradeScalesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    },
  });
  useEffect(() => {
    if (token && InstId) {
      GetAcdGradeScaleDetails();
    }
  }, [token, InstId, GetAcdGradeScaleDetails]);
  const gradeDropDown = gradeDetails
    ? gradeDetails.GetAcdGradeScalesByInstId.map((list) => list.grade)
    : [];
  const [UpdateMarks] = useMutation(UpdateAcdTestMarksByStudentID, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const isInternalSubjectExists =
    data &&
    data.GetStdTestMarksByStudentId.edges.filter(
      ({ node }) => node.subject_details.subj_internals_avialed === true
    ).length > 0;

  useEffect(() => {
    if (
      entryId &&
      entry_level &&
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetSubjectsTeachersAssociated();
    }
  }, [
    entryId,
    GetSubjectsTeachersAssociated,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    entry_level,
    state.ActiveAcdYr,
  ]);

  useEffect(() => {
    if (
      token &&
      testDetails &&
      state.studentId &&
      activeAcademicYearData.data &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetMarks().then(({ data }) => {
        if (data && data.GetStdTestMarksByStudentId) {
          const tempUpdatedMarks: Map<number, sub_marks> = new Map();

          data.GetStdTestMarksByStudentId.edges.forEach((subj) => {
            tempUpdatedMarks.set(subj.node.subj_master_id, {
              ext_marks:
                subj.node.marks_scored_tot >= 0
                  ? subj.node.marks_scored_tot
                  : 0,
              is_std_present: updatedStudentMarks?.get(subj.node.subj_master_id)
                ? updatedStudentMarks?.get(subj.node.subj_master_id)
                    ?.is_std_present
                : subj.node.is_std_present,
            });
          });

          setUpdatedStudentMarks(tempUpdatedMarks);
        }
      });
    }
    //eslint-disable-next-line
  }, [
    token,
    state.studentId,
    modalFlag,
    testDetails,
    GetMarks,
    entryId,
    entry_level,
    activeAcademicYearData.data,
    attChangeState,
    state.ActiveAcdYr,
    flag,
  ]);
  const onEdit = (rowKey: number) => {
    setInEditMode({
      status: true,
      rowKey,
    });
    setext_marks(updatedStudentMarks.get(rowKey)?.ext_marks ?? 0);
    setgradeScored(
      updatedStudentMarks.get(rowKey)?.scored_grade ?? EMPTY_STRING
    );
    setRemarks(
      updatedStudentMarks.get(rowKey)?.teacher_comments ?? EMPTY_STRING
    );
  };

  const handleMarksChange = (
    id: number,
    e: React.KeyboardEvent<HTMLInputElement>,
    marks: number
  ) => {
    const marksScored = ext_marks;
    const maxMarks = marks;

    if (
      marksScored !== undefined &&
      maxMarks !== undefined &&
      marksScored <= maxMarks
    ) {
      const newStudentMarks: Map<number, sub_marks> = new Map(
        updatedStudentMarks
      );
      const existingMarks = newStudentMarks.get(id);

      const updatedMarks: sub_marks = {
        ext_marks: Number((e.target as HTMLInputElement).value),
        is_std_present: existingMarks?.is_std_present ?? false,
      };

      newStudentMarks.set(id, updatedMarks);
      setUpdatedStudentMarks(newStudentMarks);
    } else {
      setMessage({
        flag: true,
        message: "Entered Marks is More than Maximum Marks",
        operation: Operation.NONE,
      });
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage({
      operation: Operation.NONE,
      flag: false,
      message: "",
    });
  };

  const handleClear = () => {
    setEdit(!edit);
    setUpdatedStudentMarks(new Map());
    setModalFlag(false);
  };
  const handleUpdateMarks = () => {
    if (flag === undefined) {
      alert("Per Subject allocation not found");
      return;
    }

    UpdateMarks({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id:
          activeAcademicYearData &&
          activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        acd_test_class_id: testDetails?.value,
        student_id: state.studentId,
        marks_details: Array.from(updatedStudentMarks).map(([k, v]) => ({
          subj_master_id: k,
          marks_ext_scored: v.ext_marks,
          is_std_present: v.is_std_present,
          scored_grade: v.scored_grade,
          teacher_comments: v.teacher_comments,
        })),
        entry_level,
        entry_id: entryId,
        per_std_subj_allocation: flag,
      },
      refetchQueries: [
        {
          query: GetTestConducteDetailsByNode,
          variables: {
            token,
            id: state.testConductId,
          },
        },
        {
          query: GetStdTestMarksByStudentId,
          variables: {
            token,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            acd_test_class_id: testDetails?.value!,
            after: null,
            entry_id: entryId!,
            student_id: state.studentId,
            entry_level: entry_level,
            first: null,
            inst_id: InstId!,
            per_std_subj_allocation: flag!,
            orderBy: {
              direction: Direction.ASC,
              field: "SUBJ_SL",
            },
          },
        },
        {
          query: GetStdTestMarks,
          variables: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            admNo: EMPTY_STRING,

            after: null,
            first: null,
            input: {
              entry_id: entryId ? Number(entryId)! : 0,
              entry_level,

              ids: [
                Number(InstId!),
                state.testConductId ? state.testConductId : Number(testId),
              ],
              marks_query_type: "TEST_MARKS_AT_ENTRY_LEVEL",
            },
            orderBy: { direction: Direction.ASC, field: "STD_ROLL_NO" },
            per_std_subj_allocation: flag,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Updated Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const totalMarks = data?.GetStdTestMarksByStudentId.edges.reduce(
    (acc, item) => {
      acc.totalExtMax += item.node.marks_max_total;
      acc.totalExtMin += item.node.marks_min_total;
      acc.totalExtOld +=
        item.node.marks_scored_tot < 0 ? 0 : item.node.marks_scored_tot;

      acc.totalExtNew +=
        updatedStudentMarks.get(item.node.subj_master_id)?.ext_marks! < 0
          ? 0
          : updatedStudentMarks.get(item.node.subj_master_id)?.ext_marks!;

      return acc;
    },
    {
      totalExtMax: 0,
      totalIntMax: 0,
      totalExtMin: 0,
      totalIntMin: 0,
      totalExtOld: 0,
      totalIntOld: 0,
      totalExtNew: 0,
      totalIntNew: 0,
    }
  );
  const changeAttendance = (
    id: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newStudentMarks: Map<number, sub_marks> = updatedStudentMarks;

    newStudentMarks.set(id, {
      ext_marks: 0,
      is_std_present: (event.target as HTMLInputElement).checked ? true : false,
    });

    setUpdatedStudentMarks(newStudentMarks);
    setInEditMode({
      rowKey: 0,
      status: false,
    });
    setAttMarksChangeState(!attChangeState);
  };

  return (
    <>
      <Title>Student Details</Title>
      <div className="particular-student-results">
        <StudentDetails />

        <div className="row g-0">
          <div className="col-2">
            <TextField
              className="particular-student-results__select--textfield"
              label="Test"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={testDetails ? testDetails.label : EMPTY_STRING}
            />
          </div>
        </div>

        <div className="particular-student-results__tableblock">
          {error ? (
            <b className="nodata">{error.message}</b>
          ) : data && data.GetStdTestMarksByStudentId ? (
            <TableContainer className="particular-student-results__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={edit ? 3 : 2} id="td-center">
                      {TableHeaders.SLNO}
                    </TableCell>
                    <TableCell rowSpan={edit ? 3 : 2} id="td-center">
                      Subject
                    </TableCell>
                    {edit ? (
                      <TableCell
                        colSpan={isInternalSubjectExists ? 4 : 2}
                        id="td-center"
                      >
                        {isCoCurricular ? "Old Grades" : "Old Marks"}
                      </TableCell>
                    ) : (
                      <TableCell colSpan={2} id="td-center">
                        {isCoCurricular ? " Grades" : " Marks"}
                      </TableCell>
                    )}
                    {edit ? (
                      <TableCell colSpan={3} id="td-center">
                        {isCoCurricular ? "New Grades" : "New Marks"}
                      </TableCell>
                    ) : (
                      isInternalSubjectExists && (
                        <TableCell colSpan={2} id="td-center">
                          Internal
                        </TableCell>
                      )
                    )}
                  </TableRow>
                  {edit ? (
                    <TableRow>
                      <TableCell colSpan={2} id="td-center">
                        {isCoCurricular ? " Grades" : " Marks"}
                      </TableCell>
                      {isInternalSubjectExists && (
                        <TableCell colSpan={2} id="td-center">
                          Internal
                        </TableCell>
                      )}
                      <TableCell rowSpan={2} id="td-center">
                        {isCoCurricular ? " Grades" : " Marks"}
                      </TableCell>
                      {isCoCurricular && isRemarksEnabled && (
                        <TableCell rowSpan={2} id="td-center">
                          Remarks
                        </TableCell>
                      )}
                      {isInternalSubjectExists && (
                        <TableCell rowSpan={2} id="td-center">
                          Internal
                        </TableCell>
                      )}
                      <TableCell rowSpan={2} id="td-center">
                        Attendance
                      </TableCell>
                    </TableRow>
                  ) : null}
                  <TableRow>
                    {isCoCurricular ? (
                      <>
                        {edit ? (
                          <TableCell id="td-center" rowSpan={2}>
                            Scored Grade
                          </TableCell>
                        ) : (
                          <TableCell id="td-center" rowSpan={2}>
                            Scored Grade
                          </TableCell>
                        )}
                      </>
                    ) : (
                      <>
                        {edit ? (
                          <TableCell id="td-center" className="particular-student-results__table--sticky">
                            Max(Min)
                          </TableCell>
                        ) : (
                          <TableCell id="td-center" className="particular-student-results__table--sticky">
                            Max(Min)
                          </TableCell>
                        )}
                      </>
                    )}

                    {isCoCurricular && isRemarksEnabled ? (
                      <>
                        {edit ? (
                          <TableCell id="td-center" rowSpan={2}>
                            Remarks
                          </TableCell>
                        ) : (
                          <TableCell id="td-center" rowSpan={2}>
                            Remarks
                          </TableCell>
                        )}
                      </>
                    ) : (
                      <>
                        {edit ? (
                          <TableCell id="td-center" className="particular-student-results__table--sticky">
                            Scored
                          </TableCell>
                        ) : (
                          <TableCell id="td-center" className="particular-student-results__table--sticky">Scored</TableCell>
                        )}
                      </>
                    )}
                    {isInternalSubjectExists && (
                      <>
                        {edit ? (
                          <TableCell id="td-center" className="particular-student-results__table--sticky">
                            Max(Min)
                          </TableCell>
                        ) : (
                          <TableCell id="td-center" className="particular-student-results__table--sticky">
                            Max(Min)
                          </TableCell>
                        )}

                        {edit ? (
                          <TableCell id="td-center" className="particular-student-results__table--sticky">
                            Scored
                          </TableCell>
                        ) : (
                          <TableCell id="td-center"className="particular-student-results__table--sticky">
                            Scored
                          </TableCell>
                        )}
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.GetStdTestMarksByStudentId.edges.map(
                    ({ node }, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell id="td-center">
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            {`${node.subject_details.subj_desc} (${node.subject_details.subj_code})`}
                          </TableCell>

                          {isCoCurricular ? (
                            <TableCell id="td-center">
                              {node.scored_grade}
                            </TableCell>
                          ) : (
                            <TableCell id="td-center">
                              {`${node.marks_max_total}(${node.marks_min_total})`}
                            </TableCell>
                          )}
                          {isCoCurricular ? (
                            <TableCell id="td-center">
                              {node.teacher_comments}
                            </TableCell>
                          ) : (
                            <TableCell id="td-center">
                              {node.is_std_present
                                ? node.marks_scored_tot
                                : "Absent"}
                            </TableCell>
                          )}
                          {isInternalSubjectExists ? (
                            <>
                              <TableCell id="td-center">
                                {node.marks_max_total
                                  ? `${node.marks_max_total}(${node.marks_min_total})`
                                  : ""}
                              </TableCell>
                              <TableCell id="td-center">
                                {node.marks_scored_tot
                                  ? node.is_std_present &&
                                    node.marks_scored_tot >= 0
                                    ? node.marks_scored_tot
                                    : "Absent"
                                  : ""}
                              </TableCell>
                            </>
                          ) : null}

                          {edit ? (
                            <>
                              {isCoCurricular ? (
                                <TableCell
                                  id="td-center"
                                  onClick={() => {
                                    if (
                                      updatedStudentMarks.get(
                                        node.subj_master_id
                                      )?.is_std_present
                                    ) {
                                      onEdit(node.subj_master_id);
                                    }
                                  }}
                                >
                                  {inEditMode.status &&
                                  inEditMode.rowKey === node.subj_master_id ? (
                                    <FormAutocomplete
                                      className={formClasses.inputRoot}
                                      options={gradeDropDown!}
                                      value={gradeScored}
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          setgradeScored(newValue as string);
                                        }
                                      }}
                                      onKeyDown={(event) => {
                                        if (event.key === Keys.ENTER) {
                                          handleMarksChange(
                                            node.subj_master_id,
                                            event as React.KeyboardEvent<HTMLInputElement>,
                                            node.marks_max_total
                                          );

                                          const nextNode =
                                            data.GetStdTestMarksByStudentId
                                              .edges[index + 1]?.node;
                                          if (nextNode) {
                                            onEdit(nextNode.subj_master_id);
                                          } else {
                                            setInEditMode({
                                              rowKey: 0,
                                              status: false,
                                            });
                                            saveRef.current?.focus();
                                          }
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className={
                                            formClasses.formControlRoot
                                          }
                                          fullWidth
                                        />
                                      )}
                                    />
                                  ) : updatedStudentMarks.get(
                                      node.subj_master_id
                                    )?.is_std_present ? (
                                    updatedStudentMarks.get(node.subj_master_id)
                                      ?.scored_grade
                                  ) : (
                                    "Absent"
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell
                                  id="td-center"
                                  onClick={() => {
                                    if (
                                      updatedStudentMarks.get(
                                        node.subj_master_id
                                      )?.is_std_present
                                    ) {
                                      onEdit(node.subj_master_id);
                                    }
                                  }}
                                >
                                  {inEditMode.status &&
                                  inEditMode.rowKey === node.subj_master_id ? (
                                    <input
                                      autoFocus
                                      name="marks_ext_scored"
                                      type="number"
                                      onFocus={(e) => {
                                        (e.target as HTMLInputElement).select();
                                      }}
                                      onBlur={() =>
                                        setInEditMode({
                                          rowKey: 0,
                                          status: false,
                                        })
                                      }
                                      value={ext_marks}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (
                                          Number(e.target.value) <=
                                          node.marks_max_total
                                        ) {
                                          setext_marks(Number(e.target.value));
                                        } else {
                                          setMessage({
                                            flag: true,
                                            message: "Exceeds Max Marks",
                                            operation: Operation.NONE,
                                          });
                                        }
                                      }}
                                      onKeyDown={(event) => {
                                        if (event.key === Keys.ENTER) {
                                          handleMarksChange(
                                            node.subj_master_id,
                                            event,
                                            node.marks_max_total
                                          );

                                          if (
                                            data.GetStdTestMarksByStudentId
                                              .edges[index + 1]
                                          ) {
                                            onEdit(
                                              data.GetStdTestMarksByStudentId
                                                .edges[index + 1].node
                                                .subj_master_id
                                            );
                                          } else {
                                            setInEditMode({
                                              rowKey: 0,
                                              status: false,
                                            });
                                            saveRef.current?.focus();
                                          }
                                        }
                                      }}
                                    />
                                  ) : updatedStudentMarks.get(
                                      node.subj_master_id
                                    )?.is_std_present ? (
                                    updatedStudentMarks.get(node.subj_master_id)
                                      ?.ext_marks
                                  ) : (
                                    "Absent"
                                  )}
                                </TableCell>
                              )}
                              {isCoCurricular && isRemarksEnabled && (
                                <TableCell
                                  id="td-center"
                                  onClick={() => {
                                    const studentMark = updatedStudentMarks.get(
                                      node.subj_master_id
                                    );
                                    if (studentMark?.is_std_present) {
                                      onEdit(node.subj_master_id);
                                    }
                                  }}
                                >
                                  <input
                                    autoFocus
                                    name="teacher_comments"
                                    type="string"
                                    onFocus={(e) => {
                                      (e.target as HTMLInputElement).select();
                                    }}
                                    onBlur={() => {
                                      setInEditMode({
                                        rowKey: 0,
                                        status: false,
                                      });
                                    }}
                                    value={remarks}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setRemarks(e.target.value);
                                    }}
                                    onKeyDown={(event) => {
                                      if (event.key === Keys.ENTER) {
                                        handleMarksChange(
                                          node.subj_master_id,
                                          event,
                                          node.marks_max_total
                                        );

                                        const nextNode =
                                          data.GetStdTestMarksByStudentId.edges[
                                            index + 1
                                          ]?.node;
                                        if (nextNode) {
                                          onEdit(nextNode.subj_master_id);
                                        } else {
                                          setInEditMode({
                                            rowKey: 0,
                                            status: false,
                                          });
                                          saveRef.current?.focus();
                                        }
                                      }
                                    }}
                                  />
                                </TableCell>
                              )}

                              <TableCell>
                                <AntSwitch
                                  checked={
                                    updatedStudentMarks?.get(
                                      node.subj_master_id
                                    )?.is_std_present
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    changeAttendance(node.subj_master_id, e)
                                  }
                                  name="is_present"
                                />
                                {updatedStudentMarks.get(node.subj_master_id)
                                  ?.is_std_present
                                  ? "P"
                                  : "A"}
                              </TableCell>
                            </>
                          ) : null}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} align="right" className="total">
                      Total :
                    </TableCell>

                    <TableCell id="td-center" className="totalcount">
                      {`${totalMarks?.totalExtMax}(${totalMarks?.totalExtMin})`}
                    </TableCell>
                    <TableCell id="td-center" className="totalcount">
                      {totalMarks?.totalExtOld}
                    </TableCell>

                    {isInternalSubjectExists && (
                      <>
                        <TableCell id="td-center" className="totalcount">
                          {`${totalMarks?.totalIntMax}(${totalMarks?.totalIntMin})`}
                        </TableCell>
                        <TableCell id="td-center" className="totalcount">
                          {totalMarks?.totalIntOld}
                        </TableCell>
                      </>
                    )}

                    {edit ? (
                      <>
                        <TableCell id="td-center" className="totalcount">
                          {totalMarks?.totalExtNew}
                        </TableCell>
                        {isInternalSubjectExists && (
                          <TableCell id="td-center" className="totalcount">
                            {totalMarks?.totalIntNew}
                          </TableCell>
                        )}
                      </>
                    ) : null}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <b className="nodata">No data </b>
          )}
        </div>

        {enableEdit ? (
          edit ? (
            <Button
              mode="save"
              onClick={handleUpdateMarks}
              buttonref={saveRef}
            ></Button>
          ) : (
            <Button
              mode="update"
              onClick={() => {
                setEdit(!edit);
              }}
            >
              &nbsp;Marks
            </Button>
          )
        ) : null}
        {edit && (
          <Button
            mode="cancel"
            onClick={() => {
              setEdit(!edit);
            }}
          />
        )}
        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />
      </div>
    </>
  );
};

export default ParticularStudentResultsDailyAct;

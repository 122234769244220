import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useToken from "../../../customhooks/useToken";
import { InstitutionConfigurationTypes } from "../../../utils/Enum.types";
import { GetAcdSubjectAllotedForClass } from "../queries/subjects/List.tsx/Index";
import { SubjectFormData } from "../types/subject/Subject";
import useActiveAcademicYear from "./useActiveAcademicYear";
import { AppContext } from "../../../context/context";
import useCheckAllocationType from "./useCheckAllocationType";
export interface GetAcdSubjectAllotedForClassData {
  GetAcdSubjectAllotedForClass: GetAcdSubjectAllotedForClassDetails[];
}
export interface ElectiveDetailsData {
  id: number;
  elective_sl: number;
  subj_alloted_details_id: number;
  subj_master_id: number;
  subject_details: SubjectFormData;
}
export interface GetAcdSubjectAllotedForClassDetails {
  subject_details: SubjectFormData;
  elective_details: ElectiveDetailsData[];
  id: number;
  subj_sl: number;
  core_sl_no: number;
  subj_is_lang: boolean;
  subj_is_elective: boolean;
  subj_is_grade: boolean;
  subj_internals_avialed: boolean;
  subj_alloted_main_id: number;
  subj_master_id: number;
  isChecked: boolean;
  main_elective_subj_desc: string;
  elective_sl_no: number;
  elective_subj_master_id: number;
}
export interface GetAcdSubjectAllotedForClassVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  alloted_id: number;
  alloted_level: string;
  per_std_subj_allocation: boolean | undefined;
}

const useSubjectAllotedForClass = (alloted_id: number) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { state } = useContext(AppContext);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { flag } = useCheckAllocationType(alloted_id);
  const [GetSubjectsAssociated, { data, variables, loading, error }] =
    useLazyQuery<
      GetAcdSubjectAllotedForClassData,
      GetAcdSubjectAllotedForClassVars
    >(GetAcdSubjectAllotedForClass, {
      variables: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        alloted_id,
        alloted_level:
          configData.data?.GetSwConfigVariables[0].config_string_value!,
        inst_id: InstId!,
        token,
        per_std_subj_allocation: flag,
      },
    });

  useEffect(() => {
    if (
      alloted_id &&
      configData.data &&
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetSubjectsAssociated();
    }
  }, [
    alloted_id,
    GetSubjectsAssociated,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    configData.data,
    state.ActiveAcdYr,
    flag,
  ]);

  return { subjectsForClass: { data, loading, variables, error } };
};

export default useSubjectAllotedForClass;

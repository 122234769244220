import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { InstIdVars } from "../Types/masterDataTypes";
import { GetPayRollNotAllocatedIds } from "../queries/general/list";
import { useEffect } from "react";

interface GetPayRollNotAllocatedIdsDetails {
  job_type_id: number;
  dept_id: number;
  designation_id: number;
  grade_id: number;
  category_id: number;
}
interface GetPayRollNotAllocatedIdsData {
  GetPayRollNotAllocatedIds: GetPayRollNotAllocatedIdsDetails;
}

const usePayRollMasterNotAllocatedIds = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [GetPayRollNotAllocId, { data }] = useLazyQuery<
    GetPayRollNotAllocatedIdsData,
    InstIdVars
  >(GetPayRollNotAllocatedIds, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  useEffect(() => {
    if (token) {
      GetPayRollNotAllocId();
    }
  }, [token, GetPayRollNotAllocId, InstId]);
  return {
    NotAllocatedCategoryId: data && data.GetPayRollNotAllocatedIds.category_id,
    NotAllocatedJobTypeId: data && data.GetPayRollNotAllocatedIds.job_type_id,
    NotAllocatedDepartmentId: data && data.GetPayRollNotAllocatedIds.dept_id,
    NotAllocatedGradeId: data && data.GetPayRollNotAllocatedIds.grade_id,
    NotAllocatedDesignationId:
      data && data.GetPayRollNotAllocatedIds.designation_id,
  };
};

export default usePayRollMasterNotAllocatedIds;

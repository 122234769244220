import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { reportCardConfigTypes } from "../../../../../Print/Enum.types";
import {
  GetAcdReportCardConfigData,
  GetAcdReportCardConfigVars,
} from "../../../../types/Progresscard";
import { GetAcdReportCardConfig } from "../../../../queries/reportcard";
import { PageFor } from "../../../../../../utils/Enum.types";
import useToken from "../../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";

interface Props {
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const useReportCardConfig = ({ selectedStudents, setStudentIdSet }: Props) => {
  const { token } = useToken();
  const { InstId, reportId } = useParams();
  const [GetReportCartConfigDetails, { data }] = useLazyQuery<
    GetAcdReportCardConfigData,
    GetAcdReportCardConfigVars
  >(GetAcdReportCardConfig, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_report_card_id: Number(reportId),
    },
  });

  useEffect(() => {
    if (token && InstId && reportId) {
      GetReportCartConfigDetails();
    }
  }, [token, InstId, reportId, GetReportCartConfigDetails]);
  const custName =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_CUSTOMER_NAME
    );
  const instLogo =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_LOGO
    );
  const stdImage =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STUDENT_PHOTO
    );
  const header =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_HEADER
    );
  const title =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_TITLE
    );
  const affiliatedTo =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_AFFILIATED_CAPTION
    );
  const photo1Config =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PHOTO_1
    );

  const photo2Config =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PHOTO_2
    );

  const instName =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_NAME
    );

  const instAddress =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_ADDRESS
    );
  const instEmail =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_EMAIL
    );

  const instWebsite =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_WEBSITE
    );
  const gradeScale =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_GRADE_SCALE
    );
  const stdName =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_NAME
    );
  const section =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_CLASS_SECTION
    );
  const rollNo =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ROLL_NUMBER
    );
  const dob =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_DOB
    );
  const motherName =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_MOTHER_NAME
    );
  const fatherName =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_FATHER_NAME
    );

  const stdAddress =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ADDRESS
    );

  const stdMobile =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_MOBILE
    );
  const stdEmail =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_EMAIL
    );
  const parentSign =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PARENT_SIGN
    );
  const classTeacherSign =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_CLASS_TEACHER_SIGN
    );
  const attendanceStats =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ATTENDANCE_STATS
    );
  const remarksPerTest =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_REMARKS_PER_TEST
    );
  const gradeForEachSub =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) =>
        res.config_key === reportCardConfigTypes.RP_GRADE_FOR_EACH_SUBJECT
    );

  const heightWeight =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_HEIGHT_WEIGHT
    );

  const overAllPercentage =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_OVERALL_PERCENTAGE
    );
  const annualResults =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_ANNUAL_RESULTS
    );

  const overAllGrade =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_OVERALL_GRADE
    );
  const overAllRank =
    data &&
    data.GetAcdReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_OVERALL_RANK
    );

  return {
    instAddress,
    instLogo,
    instName,
    instWebsite,
    gradeScale,
    instEmail,
    stdEmail,
    stdMobile,
    stdAddress,
    stdName,
    section,
    dob,
    motherName,
    fatherName,
    rollNo,
    custName,
    parentSign,
    attendanceStats,
    heightWeight,
    gradeForEachSub,
    remarksPerTest,
    classTeacherSign,
    overAllPercentage,
    annualResults,
    overAllGrade,
    overAllRank,
    stdImage,
    header,
    affiliatedTo,
    photo1Config,
    photo2Config,
    title,
  };
};
export default useReportCardConfig;

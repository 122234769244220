import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Home from "../../Home/Index";
import { Step, StepLabel, Stepper } from "@mui/material";
import { Title } from "../../../../stories/Title/Title";
import Common from "./Common";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import InsuranceDetails from "../../../../images/InsuranceDetails.svg";
import FitnessDetails from "../../../../images/FitnessDetails.svg";
import RoadTaxDetails from "../../../../images/RoadTaxDetails.svg";
import EmissionTestDetails from "../../../../images/EmisisontestDetails.svg";
import { AntSwitch } from "../../../../pages/Switch";
import { LabelNameProps } from "../../../../Types/Labels";
import { Button } from "../../../../stories/Button/Button";
import { Operation } from "../../../../utils/Enum.types";
const { VMS_Form } = require("../../../json/form.json");
interface Props {
  step: number;
  operation: Operation;
}

const stepHeader = () => {
  return [
    "Vehicle Information",
    "Vehicle Purchase and Other Details",
    "Vehicle Current Details",
  ];
};
const CurrentDetails = ({ step, operation }: Props) => {
  const stepsHeader = stepHeader();
  const navigate = useNavigate();
  const [roadTaxEnabled, setRoadTaxEnabled] = useState(false);
  const [emissionTestEnabled, setEmissionTestEnabled] = useState(false);
  const [fitnessDetails, setFitnessDetails] = useState(false);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="vms-v-details-new">
        {operation === Operation.CREATE && (
          <div className="vms-v-details-new__stepper">
            <Stepper alternativeLabel activeStep={step - 1}>
              {stepsHeader.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        )}

        <Title>
          {operation === Operation.UPDATE
            ? "Update Vehicle Current Details"
            : "Vehicle Current Details"}
        </Title>
        <Common />
        <div
          className={
            operation === Operation.UPDATE
              ? "vms-v-details-new__current-details--update"
              : "vms-v-details-new__current-details"
          }
        >
          <div className="row g-0 vms-v-details-new__current-details--insurance">
            <div className="col">
              <div className="vms-v-details-new__current-details--title">
                <img src={InsuranceDetails} alt="" />
                Insurance Details
              </div>
              <div className="label-grid">
                <Label>Insurance Provider</Label>
                <Input />
                <Label>InSurance Policy No.</Label>
                <Input />
                <Label>Insurance Done Date</Label>
                <Input type="date" className="font-blue" />
              </div>
              <div className="vms-v-details-new__other-details--data--grid">
                <Label>Insurance Start Date</Label>
                <Input type="date" className="font-green" />
                <Label>Insurance Expiry Date</Label>
                <Input type="date" className="font-red" />
              </div>
            </div>
            <div className="col">
              <div className="vms-v-details-new__current-details--title">
                <img src={RoadTaxDetails} alt="" />
                Road Tax
              </div>
              <div className="vms-v-details-new__other-details--data--grid">
                <Label>Road Tax Enabled</Label>
                <AntSwitch
                  onChange={() => setRoadTaxEnabled(!roadTaxEnabled)}
                />
                {roadTaxEnabled && (
                  <>
                    <Label>Road Tax Paid Date</Label>
                    <Input type="date" className="font-blue" />
                  </>
                )}
              </div>
              {roadTaxEnabled && (
                <>
                  <div className="vms-v-details-new__other-details--data--grid">
                    <Label>Road Tax Start Date</Label>
                    <Input type="date" className="font-green" />
                    <Label>Road Tax Expiry Date</Label>
                    <Input type="date" className="font-red" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row vms-v-details-new__current-details--insurance g-0">
            <div className="col">
              <div className="vms-v-details-new__current-details--title">
                <img src={FitnessDetails} alt="" />
                Fitness Details
              </div>

              <div className="vms-v-details-new__other-details--data--grid">
                <Label>Fitness Test Enabled</Label>
                <AntSwitch
                  onChange={() => setFitnessDetails(!fitnessDetails)}
                />
                {fitnessDetails && (
                  <>
                    <Label>Fitness Test Done Date</Label>
                    <Input type="date" className="font-blue" />
                  </>
                )}
              </div>
              {fitnessDetails && (
                <>
                  <div className="vms-v-details-new__other-details--data--grid">
                    <Label>Fitness Test Start Date</Label>
                    <Input type="date" className="font-green" />
                    <Label>Fitness Test Expiry Date</Label>
                    <Input type="date" className="font-red" />
                  </div>
                </>
              )}
              {fitnessDetails && (
                <>
                  <div className="label-grid">
                    <Label>Fitness Test Reference Number</Label>
                    <Input />
                  </div>
                </>
              )}
            </div>
            <div className="col">
              <div className="vms-v-details-new__current-details--title ">
                <img src={EmissionTestDetails} alt="" />
                Emission Test
              </div>
              <div className="vms-v-details-new__other-details--data--grid">
                <Label>Emission Test Enabled</Label>
                <AntSwitch
                  onChange={() => setEmissionTestEnabled(!emissionTestEnabled)}
                />
                {emissionTestEnabled && (
                  <>
                    <Label>Emission Test Done Date</Label>
                    <Input type="date" className="font-blue" />
                  </>
                )}
              </div>
              {emissionTestEnabled && (
                <>
                  <div className="vms-v-details-new__other-details--data--grid">
                    <Label>Emission Test Start Date</Label>
                    <Input type="date" className="font-green" />
                    <Label>Emission Test Expiry Date</Label>
                    <Input type="date" className="font-red" />
                  </div>
                </>
              )}
              {emissionTestEnabled && (
                <>
                  <div className="label-grid">
                    <Label>Emission Test Reference Number</Label>
                    <Input />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row g-0 vms-v-details-new__current-details--tyre-details">
            <div className="col">
              {VMS_Form.Vehicle_Details.CurrentDetails.map(
                (label: LabelNameProps, index: React.Key) => {
                  return (
                    <div className="label-grid" key={index}>
                      <Label>{label.LabelName}</Label>
                      <Input type={label.dataType} />
                    </div>
                  );
                }
              )}
            </div>
            <div className="col"></div>
          </div>
        </div>
        {operation === Operation.UPDATE ? (
          <Button mode="save" />
        ) : (
          <Button mode="finish" />
        )}

        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default CurrentDetails;

import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdStdAttOverAllData,
  GetAcdStdSubjAttOverAllData,
  subjectData,
} from "../../../hooks/useAcdStudentAttendance";
import {
  GetAcdStdAttOverAllVars,
  GetAcdStdSubjAttOverAllVars,
} from "../../../types/holidays";
import {
  GetAcdStdAttOverAll,
  GetAcdStdSubjAttOverAll,
} from "../../../queries/holidays/list";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { toIsoDate, toStandardDate } from "../../../../../utils/UtilFunctions";
import {
  Direction,
  InstitutionConfigurationTypes,
  SortBy,
} from "../../../../../utils/Enum.types";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";

import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import OverAllAttendance from "../../../../Master/Student/Dashboard/Academics/OverAllAttendance";
import Sessionwise from "../../../../Master/Student/Dashboard/Academics/Sessionwise";
import GraphData from "../../Attendance/SessionDetails/GraphData";

const AttendanceGraph = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const [GetOverAllAttendance, { data: OverAllData, loading }] = useLazyQuery<
    GetAcdStdAttOverAllData,
    GetAcdStdAttOverAllVars
  >(GetAcdStdAttOverAll, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: 0,
        entry_level: EMPTY_STRING,
        inst_id: state.InstId!,
        cal_month: toIsoDate(serverDate),
        subj_master_id: 0,
        session_id: 0,
        student_id: state.studentId,
        att_query_type: "OVER_ALL_ATT_PER_STD",
        date_of_attendance: toIsoDate(serverDate),
      },
      token,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      after: null,
      first: null,
      name: EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (state.studentId && token && state.ActiveAcdYr) {
      GetOverAllAttendance();
    }
  }, [GetOverAllAttendance, state.studentId, token, state.ActiveAcdYr]);
  return (
    <>
      {enablePerSessionAtt === false ? (
        <div className="col student-dashboard__attendance--full-view--datablock--frame">
          <OverAllAttendance />
        </div>
      ) : null}
      {enablePerSessionAtt ? (
        <>
          <div className="col student-dashboard__attendance--full-view--datablock--frame">
            <Title variant="subtitle1">OverAll Attendance Details</Title>
            <div className="student-dashboard__attendance--full-view--datablock--frame--cards row g-0">
              <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                  Total Sessions
                </span>
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--attendance">
                  {OverAllData && OverAllData.GetAcdStdAttOverAll.edges.length
                    ? OverAllData.GetAcdStdAttOverAll.edges[0].node
                        .total_session
                    : 0}
                </span>
              </div>
              <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                  Total Present
                </span>
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--present">
                  {OverAllData && OverAllData.GetAcdStdAttOverAll.edges.length
                    ? OverAllData.GetAcdStdAttOverAll.edges[0].node
                        .total_session_present
                    : 0}
                </span>
              </div>
              <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                  Total Absent
                </span>
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--absent">
                  {OverAllData && OverAllData.GetAcdStdAttOverAll.edges.length
                    ? OverAllData.GetAcdStdAttOverAll.edges[0].node
                        .total_session_absent
                    : 0}
                </span>
              </div>
              <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                  Total Percentage
                </span>
                <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--percentage">
                  {OverAllData && OverAllData.GetAcdStdAttOverAll.edges.length
                    ? `${OverAllData.GetAcdStdAttOverAll.edges[0].node.over_all_per}% `
                    : 0}
                </span>
              </div>
            </div>
            <div className="student-dashboard__attendance--full-view--graph">
              <GraphData />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AttendanceGraph;

import React, { useContext, useEffect } from "react";
import Input from "../../../../../stories/Input/Input";
import {
  GetAcdReportCardsData,
  GetAcdReportCardsVars,
} from "../../../types/Progresscard";
import { useLazyQuery } from "@apollo/client";
import { GetAcdReportCards } from "../../../queries/reportcard";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import { useNavigate, useParams } from "react-router-dom";
import { ROWS_PER_PAGE } from "../../../../../utils/constants";
import { Direction } from "../../../../../utils/Enum.types";

import View from "../../../../../images/EyeWhite.svg";
import LoadingModal from "../../../../../pages/LoadingModal";
const Index = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const navigate = useNavigate();
  const [GetReportCardData, { data, loading }] = useLazyQuery<
    GetAcdReportCardsData,
    GetAcdReportCardsVars
  >(GetAcdReportCards, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      first: ROWS_PER_PAGE,
      after: null,
      orderBy: {
        direction: Direction.ASC,
      },
    },
  });
  useEffect(() => {
    if (token) {
      GetReportCardData();
    }
  }, [token, GetReportCardData]);
  return (
    <>
      <div className="report-cards">
        <div className="report-cards__search row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
        </div>
        <div className="report-cards__block">
          <div className="report-cards__grids">
            {data
              ? data.GetAcdReportCards.edges.map((res, index) => {
                  return (
                    <div className="report-cards__grid">
                      <b>{res.node.report_name}</b>
                      <div className="report-cards__grid--button">
                        <button
                          className="report-cards__grid--view"
                          onClick={() =>
                            navigate(
                              `/${InstId}/academics/reports/completeReports/${entryId}/view/${res.node.id}/testdetails`
                            )
                          }
                        >
                          View <img src={View} alt="" />
                        </button>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <LoadingModal flag={loading} />
    </>
  );
};

export default Index;

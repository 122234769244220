// import { datesFormType } from "../../../utils/Form.types";
import { SalaryLedgerType } from "../enums/Enum.types";
import { EmpDetails } from "../hooks/useEmployee";

export enum InstTreeQueryType {
  TREE_BY_INST_ID = "TREE_BY_INST_ID",
  TREE_BY_DEPT_ID = "TREE_BY_DEPT_ID",
  TREE_BY_BRANCH_ID = "TREE_BY_BRANCH_ID",
  TREE_BY_CLASS_ID = "TREE_BY_CLASS_ID",
  TREE_BY_SEMESTER_ID = "TREE_BY_SEMESTER_ID",
  TREE_BY_SECTION_ID = "TREE_BY_SECTION_ID",
}

//JobType
export interface PayRollJobTypeNode {
  id: number;
  job_type_desc: string;
  job_type_idx: number;
  isChecked?: boolean;
}

//Categories
export interface PayRollCategoryNode {
  id: number;
  category_desc: string;
  category_idx: number;
  isChecked?: boolean;
}

export interface PayRollCategoryDetails {
  category_desc: string;
}

// Dept
export interface PayRollDeptNode {
  id: number;
  dept_desc: string;
  dept_idx: number;
  isChecked?: boolean;
}
export interface PayRollDepartmentDetails {
  dept_desc: string;
}
export interface PayRollNodeDepartmentData {
  node: PayRollDepartmentDetails;
}
// designation
export interface PayRollDesignationNode {
  id: number;
  designation_desc: string;
  designation_idx: number;
  isChecked?: boolean;
}

export interface PayRollDesignationDetails {
  designation_desc: string;
}
export interface PayRollNodeDesignationData {
  node: PayRollDesignationDetails;
}

//Grade
export interface PayRollGradeNode {
  id: number;
  grade_desc: string;
  grade_idx: number;
  isChecked?: boolean;
}
export interface PayRollGradeDetails {
  grade_desc: string;
}
export interface PayRollNodeGradeData {
  node: PayRollGradeDetails;
}

//Salary Ledger Types
export interface PayRollLeaveLedgerNode {
  id: number;
  leave_desc: string;
  leave_short_code: string;
  leave_idx: number;
  leave_type: string;
  leave_is_system_defined: boolean;
  leave_is_ldgr_active: boolean;
  leave_only_for_women: boolean;
  leave_is_credited: boolean;
  leave_carry_forwarded: boolean;
  leave_for_salary_not_used: boolean;
  leave_is_earned: boolean;
  is_lwp_ldgr: boolean;
  isChecked?: boolean;
}

export interface GetPayRollSalaryLdgrsByInstIdList {
  id: number;
  sal_ldgr_desc: string;
  sal_ldgr_short_desc: string;
  sal_ldgr_idx: number;
  sal_ldgr_earn_deduct: SalaryLedgerType;
  sal_ldgr_type: string;
  sal_ldgr_is_system_generated: boolean;
  sal_ldgr_is_active: boolean;
  sal_ldgr_is_basic: boolean;
  sal_ldgr_round_to_next_int: boolean;
  sal_ldgr_remove_decimal: boolean;
  sal_ldgr_is_loan_ac: boolean;
  inst_id: number;
}
export interface GetPayRollSalaryLdgrsByInstIdData {
  GetPayRollSalaryLdgrsByInstId: GetPayRollSalaryLdgrsByInstIdList[];
}

export interface PayRollGeneralHolidays {
  id: number;
  holiday_date: string;
  holiday_desc: string;
  holiday_duration: string;
  holiday_type: string;
  inst_id: number;
  pr_acd_yr_id: number;
}

//InstId and Token Vars
export interface InstIdVars {
  inst_id: string | number;
  token: string;
}
export interface InstTreeVars {
  inst_id: string | number;
  token: string;
  id: string | number;
  query_type: InstTreeQueryType;
}

//Holiday Count
export interface GetPayRollGeneralHolidayTypeCountList {
  general_holiday_count: number;
  unexpected_holiday_count: number;
  weekend_holiday_count: number;
}
export interface GetPayRollGeneralHolidayTypeCountData {
  GetPayRollGeneralHolidayTypeCount: GetPayRollGeneralHolidayTypeCountList;
}

export interface GetPayRollGeneralHolidayTypeCountVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
  start_date: string;
  end_date: string;
}
export interface GetPayRollEmpAttendanceByEmpIdList {
  id: number;
  cal_month: string;
  att_total_days_present: number;
  att_total_days_absent_full: number;
  att_total_days_absent_half: number;
  att_total_days_absent: number;
  day_2: string;
  day_3: string;
  day_4: string;
  day_1: string;
  day_5: string;
  day_6: string;
  day_7: string;
  day_8: string;
  day_9: string;
  day_10: string;
  day_11: string;
  day_12: string;
  day_13: string;
  day_14: string;
  day_15: string;
  day_16: string;
  day_17: string;
  day_18: string;
  day_19: string;
  day_20: string;
  day_21: string;
  day_22: string;
  day_23: string;
  day_24: string;
  day_25: string;
  day_26: string;
  day_27: string;
  day_28: string;
  day_29: string;
  day_30: string;
  day_31: string;
  pr_emp_id: number;
  pr_acd_yr_id: number;
  pr_cal_month_id: number;
  emp_details: EmpDetails;
}
export interface GetPayRollEmpAttendanceByEmpIdData {
  GetPayRollEmpAttendanceByEmpId:
  | GetPayRollEmpAttendanceByEmpIdList
  | Record<string, string>;
}
export interface GetPayRollEmpAttendanceByEmpIdVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
  cal_month: string;
  pr_emp_id: number;
}

export interface PayRollEmpLeaveDetails {
  leaves_availed: number;
  leaves_used: number;
  leave_ldgr_details: PayRollLeaveLedgerNode;
}
export interface GetPayRollEmpLeaveMasterLeaveDetails
  extends PayRollEmpLeaveDetails {
  id: number;
  leaves_balance: number;
  pr_leave_ldgr_id: number;
}
export interface GetPayRollEmpLeaveMasterList {
  pr_emp_id: number;
  total_leaves_availed: number;
  total_leaves_used: number;
  leave_details: GetPayRollEmpLeaveMasterLeaveDetails[];
}
//GetPayRollEmpId
export interface GetPayRollEmpIdData {
  GetPayRollEmpId: string;
}

// GetPayRollGeneralHolidaysInBetween
export interface GetPayRollGeneralHolidaysInBetweenData {
  GetPayRollGeneralHolidaysInBetween: PayRollGeneralHolidays[];
}

// GetPayRollEmpLeaveMasterDetails
export interface GetPayRollEmpLeaveMasterDetailsData {
  GetPayRollEmpLeaveMasterDetails: GetPayRollEmpLeaveMasterList;
}
export interface GetPayRollEmpLeaveMasterDetailsVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
  pr_emp_id: number;
}

// GetRollGeneralGeneralHolidayConfiguredWeekends
export interface GetPayRollGeneralHolidayConfiguredWeekendsList {
  id: number;
  holiday_date: string;
  holiday_desc: string;
  holiday_duration: string;
  holiday_type: string;
  day_idx: number;
  week_idx: number;
  inst_id: number;
  pr_acd_yr_id: number;
}
export interface GetPayRollGeneralHolidayConfiguredWeekendsData {
  GetPayRollGeneralHolidayConfiguredWeekends: GetPayRollGeneralHolidayConfiguredWeekendsList[];
}
export interface GetPayRollGeneralHolidayConfiguredWeekendsVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
  day_idx: number;
  week_idx: number;
}

export interface PayRollHolidayConfig {
  id: number;
  week_day: string;
  day_idx: number;
  week_idx: number;
  week_day_status: boolean;
  inst_id: number;
  pr_acd_yr_id: number;
}

//GetPayROllHolidayConfig
export interface GetPayRollHolidayConfigData {
  GetPayRollHolidayConfig: PayRollHolidayConfig[];
}
export interface GetPayRollHolidayConfigVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
}

//GetPayRollMonthlyCalendar
export interface GetPayRollMonthlyCalendarList {
  id: number;
  cal_month: string;
  month_start_date: string;
  month_end_date: string;
  no_of_days: number;
  no_of_weekend_days: number;
  no_of_general: number;
  no_of_unexpected: number;
  total_no_of_holidays: number;
  total_no_of_workdays: number;
  no_of_vacation: number;
  calendar_entered: number;
}
export interface GetPayRollMonthlyCalendarData {
  GetPayRollMonthlyCalendar: GetPayRollMonthlyCalendarList[];
}

export interface GetPayRollMonthlyCalendarVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
}

export interface GetPayRollEmpAttDailyStatusDetails {
  id: number;
  cal_month: string;
  day_1: boolean;
  day_2: boolean;
  day_3: boolean;
  day_4: boolean;
  day_5: boolean;
  day_6: boolean;
  day_7: boolean;
  day_8: boolean;
  day_9: boolean;
  day_10: boolean;
  day_11: boolean;
  day_12: boolean;
  day_13: boolean;
  day_14: boolean;
  day_15: boolean;
  day_16: boolean;
  day_17: boolean;
  day_18: boolean;
  day_19: boolean;
  day_20: boolean;
  day_21: boolean;
  day_22: boolean;
  day_23: boolean;
  day_24: boolean;
  day_25: boolean;
  day_26: boolean;
  day_27: boolean;
  day_28: boolean;
  day_29: boolean;
  day_30: boolean;
  day_31: boolean;
  pr_acd_yr_id: number;
  inst_id: number;
  pr_cal_month_id: number;
}
export interface GetPayRollEmpAttDailyStatusData {
  GetPayRollEmpAttDailyStatus:
  | GetPayRollEmpAttDailyStatusDetails
  | Record<string, boolean>;
}

export interface EmpAttStatusQuery {
  cal_month: string;
  inst_id: string;
  pr_acd_yr_id: number;
}
export interface GetPayRollEmpAttDailyStatusVars {
  token: string;
  input: EmpAttStatusQuery;
}

export interface GetPayRollEmpAttTodaysStatusData {
  GetPayRollEmpAttTodaysStatus: boolean;
}
export interface GetPayRollEmpAttTodaysStatusVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
  date_of_attendance: string;
}

//Common NodeVars
export interface CommonNodeVars {
  token: string;
  id: number;
}
//Common InstId Vars
export interface InsIdVars {
  token: string;
  inst_id: string;
}

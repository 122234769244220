import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { TableHeaderProps } from "../../../../Types/Tables";
import { formatter } from "../../../../utils/UtilFunctions";
import {
  OverAllSalaryLedgerPageType,
  SalaryLedgerType,
} from "../../enums/Enum.types";
import { LedgersListType } from "./AssignSalaryStructure";
const { HR_Table } = require("../../json/table.json");
interface Props {
  selectedOverAllLedger: LedgersListType[];
  basicSalary: number;
  pageFor: OverAllSalaryLedgerPageType;
}
const OverAllStructure = ({
  selectedOverAllLedger,
  basicSalary,
  pageFor,
}: Props) => {
  const { format } = formatter;

  const totalValue = () => {
    let totalEarningsValue = 0;

    let totalDeductionsValue = 0;
    // eslint-disable-next-line
    selectedOverAllLedger?.map(({ earnings, deductions }) => {
      totalEarningsValue = totalEarningsValue + earnings;
      totalDeductionsValue = totalDeductionsValue + deductions;
    });

    return {
      totalEarnings: totalEarningsValue,
      grossTotalDeductionValue: totalDeductionsValue,
      totalDeductions: totalDeductionsValue,
    };
  };

  return (
    <>
      <TableContainer className="salary-structure__frame--leftblock--tableblock--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {HR_Table.SalaryStructure.OverAll.Table_Headers.map(
                (th: TableHeaderProps, index: React.Key) => {
                  return (
                    <TableCell key={index} className={th.className}>
                      {th.labelName}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedOverAllLedger
              .filter(({ sal_ldgr_is_basic }) => sal_ldgr_is_basic)
              .map((item, index: React.Key) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      className={
                        item.ledger_type === SalaryLedgerType.EARNING
                          ? "td-green"
                          : "td-red"
                      }
                    >
                      {item.ledger_desc}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="salary-structure__frame--leftblock--tableblock--table--amount"
                    >
                      {item.ledger_type === SalaryLedgerType.EARNING
                        ? format(Number(item.earnings.toFixed(2)))
                        : "-"}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="salary-structure__frame--leftblock--tableblock--table--amount"
                    ></TableCell>
                  </TableRow>
                );
              })}
            {selectedOverAllLedger
              .filter(({ sal_ldgr_is_basic }) => sal_ldgr_is_basic === false)
              .map((item, index: React.Key) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      className={
                        item.ledger_type === SalaryLedgerType.EARNING
                          ? "td-green"
                          : "td-red"
                      }
                    >
                      {item.ledger_desc}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="salary-structure__frame--leftblock--tableblock--table--amount"
                    >
                      {item.ledger_type === SalaryLedgerType.EARNING
                        ? format(Number(item.earnings.toFixed(2)))
                        : "-"}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="salary-structure__frame--leftblock--tableblock--table--amount"
                    >
                      {item.ledger_type === SalaryLedgerType.DEDUCTION
                        ? format(Number(item.deductions.toFixed(2)))
                        : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className="total"> Total</TableCell>
              <TableCell id="td-right" className="totalcount">
                {format(Number(totalValue()?.totalEarnings.toFixed(2)))}
              </TableCell>
              <TableCell id="td-right" className="balance-count">
                {format(
                  Number(totalValue()?.grossTotalDeductionValue.toFixed(2))
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="total" colSpan={2}>
                Net Salary :
              </TableCell>
              <TableCell id="td-right" className="totalcount">
                {format(
                  Number(
                    (
                      totalValue()?.totalEarnings -
                      totalValue()?.totalDeductions
                    ).toFixed(2)
                  )
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default OverAllStructure;

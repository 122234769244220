import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GetAcdInstSessions } from "../queries/sessions/list";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { responseType } from "../../../utils/Form.types";
import { InstIdVars } from "../../HR/Types/masterDataTypes";

export interface GetAcdInstSessionsList {
  id: number;
  session_desc: string;
  start_time: string;
  end_time: string;
  session_idx: number;
}

export interface GetAcdInstSessionsData {
  GetAcdInstSessions: GetAcdInstSessionsList[];
}
export interface GetAcdInstInactiveSessionsData {
  GetAcdInstInactiveSessions: GetAcdInstSessionsList[];
}
const useAcdInstSessions = () => {
  const { token } = useToken();
  const { InstId } = useParams();

  const [sessionsOptions, setSessionOptions] = useState<responseType[]>([]);
  const [GetSessions, { data, loading, fetchMore, error }] = useLazyQuery<
    GetAcdInstSessionsData,
    InstIdVars
  >(GetAcdInstSessions, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });

  useEffect(() => {
    if (token) {
      GetSessions().then(({ data }) => {
        if (data && data.GetAcdInstSessions) {
          setSessionOptions(
            data.GetAcdInstSessions.map((node) => ({
              label: node.session_desc,
              value: node.id,
            }))
          );
        }
      });
    }
  }, [token, GetSessions, data]);

  return {
    SessionsQueryResult: { data, loading, fetchMore, error },
    sessionsOptions,
  };
};

export default useAcdInstSessions;

import { useMutation } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getPredefinedDataByTypes } from "../../../../queries/preDefinedData/byType";
import { AddPrefinedTypesFromJson } from "../../../../queries/preDefinedData/new";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { SettingsTitleProps } from "../../../../Types/Titles";

import LoadingModal from "../../../../pages/LoadingModal";
import Home from "../../Home/Index";
import useToken from "../../../../customhooks/useToken";
import { LicenseTypes } from "../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { Settings } = require("../../../../json/title.json");

const Index = () => {
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();

  const { token } = useToken();
  const [AddPredefinedTypes, { loading: creationLoading }] = useMutation(
    AddPrefinedTypesFromJson
  );
  const handleAddPredefinedDataFromJson = async () => {
    await AddPredefinedTypes({
      variables: {
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: getPredefinedDataByTypes,
          variables: {
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        navigate("/eduate/predefinedlist");
      }
    });
  };
  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>
        {Settings.Titles.map((title: SettingsTitleProps, index: React.Key) => {
          return <React.Fragment key={index}>{title.Settings}</React.Fragment>;
        })}
      </Title>
      <div className="button-left">
        <Button
          mode="pre-defined"
          onClick={handleAddPredefinedDataFromJson}
          autoFocus
        />
      </div>

      <LoadingModal flag={creationLoading} />
    </>
  );
};

export default Index;

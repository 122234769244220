import React from "react";
import Navbar from "../common/Navbar";
import Dashboard from "../Dashboard/Index";
interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  return (
    <>
      <Navbar />
      {DashBoardRequired && <Dashboard />}
    </>
  );
};

export default Index;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import DataFetchIcon from "../../../../images/Add.svg";
import Edit from "../../../../images/EditProfile.svg";
import Close from "../../../../images/Close.svg";
import DeleteIcon from "../../../../images/Delete.svg";
import Enter from "../../../../images/EnterKeyWhite.svg";
import RouteStagesRepresentation from "../../../../images/RouteStagesRepresentation.svg";
import { TableHeaderProps } from "../../../../Types/Tables";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import {
  DeleteLedgerModalStyles,
  InstitutionCustomStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import { RoutesDetailsTitleProps } from "../../../../Types/Titles";
import View from "./View";
import AddRoute from "../Route/Index";
import DeleteRoute from "./Delete";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";
import useTransportRoutesData from "../../../../customhooks/general/useTransportRoutesData";

import { msgType, responseType } from "../../../../utils/Form.types";
import { AcctLedgerQueryType } from "../../../Accounts/common/QueryTypes";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useAcctLedgerData from "../../../Accounts/hooks/useAcctLedgerData";
import FeeLedger from "../../../Accounts/FeeLedger/Index";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddOrUpdateOrDeleteTransportRouteDetails } from "../../queries/mutations";
import ReOrder from "./ReOrder";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { RouteDetailsByData, RouteDetailsByVars } from "../../paginationTypes";
import { GetTransportRouteDetailsByMasterId } from "../../queries/list";
import LoadingModal from "../../../../pages/LoadingModal";
import { Keys } from "../../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useTransportTableJson from "../../json/useTransportTableJson";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";

const { Transport_Title } = require("../../json/title.json");
interface Items {
  id: number;
  route_stage_desc: string;
  route_master_id: responseType;
  acct_ldgr_id: responseType;
  newAdded: boolean;
  updated: boolean;
}
const Index = () => {
  const { token } = useToken();
  const { Transport_Table } = useTransportTableJson();
  const { user_details } = useLoggedInUserDetails();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [searchRoutes, setSearchRoutes] = useState("");
  const [enableEdit, setEnableEdit] = useState(false);

  const { routesData } = useTransportRoutesData(searchRoutes, true);
  const { routesData: routesDataUpdate } = useTransportRoutesData(
    searchRoutes,
    false
  );

  const [ledgerModal, setLedgerModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [reorderModal, setReorderModal] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [items, setItems] = useState<Items[]>([]);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [localId, setLocalId] = useState(0);
  const [route_master_id, SetRoute_master_id] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [acct_ldgr_id, setAcctLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [searchAcctLedger, setSearchAcctldgrs] = useState("");

  const [route_stage_desc, setroute_stage_desc] = useState("");
  const { acctLedgers } = useAcctLedgerData(
    searchAcctLedger,
    AcctLedgerQueryType.TRANSPORT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  //UserRefs
  const routeRef = useRef<HTMLSelectElement>(null);
  const routeInputRef = routeRef?.current?.childNodes[0]?.childNodes[0]
    .childNodes[0] as HTMLInputElement;

  const stageRef = useRef<HTMLInputElement>(null);
  const ledgerRef = useRef<HTMLSelectElement>(null);
  const ledgerInputRef = ledgerRef?.current?.childNodes[0]?.childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const insertRef = useRef<HTMLButtonElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedItems, setDeletedItems] = useState<Items[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetRouteDetailsById] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id: route_master_id.value,
    },
  });

  const [UpdateRouteDetails, { loading: updationLoading }] = useMutation(
    AddOrUpdateOrDeleteTransportRouteDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [AddRouteDetails, { loading: creationLoading }] = useMutation(
    AddOrUpdateOrDeleteTransportRouteDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const HandleSubmit = () => {
    if (operation === Operation.CREATE) {
      AddRouteDetails({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          add_route_details: items
            .filter(({ newAdded }) => newAdded)
            .map((item) => ({
              route_stage_desc: item.route_stage_desc,
              route_master_id: item.route_master_id.value,
              acct_ldgr_id: item.acct_ldgr_id.value,
            })),
        },
        refetchQueries: [
          {
            query: GetTransportRouteDetailsByMasterId,
            variables: {
              token,
              inst_id: InstId!,
              route_master_id: route_master_id.value,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Route Details Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (enableEdit) {
      UpdateRouteDetails({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          update_route_details: items
            .filter(({ updated }) => updated)
            .map((item) => ({
              route_detail_id: item.id,
              route_stage_desc: item.route_stage_desc,
              acct_ldgr_id: item.acct_ldgr_id.value,
            })),
          add_route_details: items
            .filter(({ newAdded }) => newAdded)
            .map((item) => ({
              route_stage_desc: item.route_stage_desc,
              route_master_id: item.route_master_id.value,
              acct_ldgr_id: item.acct_ldgr_id.value,
            })),
          delete_route_details: deletedItems
            .filter(({ updated }) => updated)
            .map((item) => ({
              route_detail_id: item.id,
            })),
        },
        refetchQueries: [
          {
            query: GetTransportRouteDetailsByMasterId,
            variables: {
              token,
              inst_id: InstId!,
              route_master_id: route_master_id.value,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Route Details Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleItems = () => {
    if (acct_ldgr_id.value && route_master_id.value && route_stage_desc) {
      setItems((item) => [
        ...item,
        {
          id: Math.random(),
          acct_ldgr_id,
          route_master_id,
          route_stage_desc,
          newAdded: true,
          updated: false,
        },
      ]);
      setAcctLedgerId(EMPTY_RESPONSETYPE_OBJECT);
      setroute_stage_desc("");
    }
  };

  const filterItemByReference = (id: number) => {
    const res = items.find((item) => item.id === id);
    if (res) {
      const { acct_ldgr_id, route_master_id, route_stage_desc } = res;
      SetRoute_master_id(route_master_id);
      setroute_stage_desc(route_stage_desc);
      setAcctLedgerId(acct_ldgr_id);
    }
  };
  const handleItemUpdate = () => {
    const updatedData = items.map((item) => {
      if (item.id === localId) {
        return {
          ...item,

          acct_ldgr_id: acct_ldgr_id,
          id: localId,
          route_master_id,
          route_stage_desc,
        };
      }
      return item;
    });
    setItems(updatedData);
    setLocalId(0);
    setAcctLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setroute_stage_desc("");
    setOperation(Operation.CREATE);
  };
  const deleteItem = (id: number) => {
    setItems(items.filter((data) => data.id !== id));

    if (enableEdit) {
      const deletedItem = items.find((data) => data.id === id);
      setDeletedItems((prevDeletedItems) =>
        deletedItem ? [...prevDeletedItems, deletedItem] : prevDeletedItems
      );
    }
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    SetRoute_master_id(EMPTY_RESPONSETYPE_OBJECT);
    setroute_stage_desc(EMPTY_STRING);
    setAcctLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setItems([]);
    setSearchAcctldgrs(EMPTY_STRING);
    routeInputRef?.focus();
  };
  useEffect(() => {
    if (route_master_id.value) {
      GetRouteDetailsById({
        variables: {
          token,
          inst_id: InstId!,
          route_master_id: route_master_id.value,
        },
      }).then(({ data }) => {
        if (data && enableEdit) {
          setItems(
            data.GetTransportRouteDetailsByMasterId.map((data) => ({
              id: data.id,
              route_stage_desc: data.route_stage_desc,
              route_master_id: {
                label: data.route_master_details.route_desc,
                value: data.route_master_id,
              },
              acct_ldgr_id: {
                label: data.acct_ldgr_details.ldgr_desc,
                value: data.acct_ldgr_details.id,
              },
              newAdded: false,
              updated: true,
            }))
          );
        }
      });
    }
  }, [GetRouteDetailsById, InstId, route_master_id.value, token, enableEdit]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Transport_Title.MasterData.RoutesDetails.map(
          (title: RoutesDetailsTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {enableEdit || operation === Operation.UPDATE
                  ? title.UpdateRoutes
                  : title.Title}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="transport-route-details">
        <div className="row g-0 transport-route-details__data">
          <div className="col">
            <div className="transport-route-details__data--label-grid">
              <Label>Bus Route</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={
                  enableEdit
                    ? routesDataUpdate.routesDropDown
                    : routesData.routesDropDown
                }
                openOnFocus
                ref={routeRef}
                value={route_master_id}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (route_master_id.value) {
                      stageRef.current?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    SetRoute_master_id(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    SetRoute_master_id(newValue as responseType);
                  } else {
                    SetRoute_master_id(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setSearchRoutes(e.target.value)}
                    fullWidth
                    autoFocus
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add Route"
                arrow
                placement="top"
                slotProps={{
                  tooltip: {
                    sx:{
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
                className="tooltip-hide"
              >
                <img
                  src={DataFetchIcon}
                  alt="/"
                  className="data-fetch-icon"
                  onClick={() => setModalFlag(!modalFlag)}
                />
              </CustomTooltip>
            </div>
          </div>
          <div className="col-1">
            <img
              src={RouteStagesRepresentation}
              alt="/"
              className="transport-route-details__data--image"
            />
          </div>
          <div className="col">
            <div className="transport-route-details__data--label-grid">
              <Label>Route Stage Place</Label>
              <Input
                inputRef={stageRef}
                value={route_stage_desc}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (route_stage_desc) {
                      ledgerInputRef?.focus();
                    }
                  }
                }}
                onChange={(e) => setroute_stage_desc(e.target.value)}
              />
            </div>
            <div className="transport-route-details__data--label-grid">
              <Label>Ledger</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={acctLedgers.responseType || []}
                openOnFocus
                ref={ledgerRef}
                value={acct_ldgr_id}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (acct_ldgr_id.value) {
                      insertRef?.current?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setAcctLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setAcctLedgerId(newValue as responseType);
                  } else setAcctLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setSearchAcctldgrs(e.target.value)}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add Ledger"
                arrow
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
                className="tooltip-hide"
              >
                <img
                  src={DataFetchIcon}
                  alt="/"
                  className="data-fetch-icon"
                  onClick={() => setLedgerModal(!ledgerModal)}
                />
              </CustomTooltip>
            </div>

            <Button
              buttonref={insertRef}
              onClick={() => {
                if (!acct_ldgr_id.value || !route_stage_desc) {
                  return;
                }
                if (operation === Operation.UPDATE) {
                  handleItemUpdate();
                } else {
                  handleItems();
                }
              }}
            >
              <img src={Enter} alt="/" />
              {operation === Operation.CREATE
                ? `Insert Into List`
                : `Insert Updated Item Into List`}
            </Button>
          </div>
        </div>
        <div className="transport-route-details__tableblock">
          <Title variant="subtitle1">
            {Transport_Title.MasterData.RoutesDetails.map(
              (title: RoutesDetailsTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.Title}</React.Fragment>
                );
              }
            )}
          </Title>
          <TableContainer className="transport-route-details__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Transport_Table.MasterData.RouteDetails.filter(
                    (th: TableHeaderProps) => th.labelName !== "Amount"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell className={th.className} key={index}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="transport-route-details__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.route_stage_desc}</TableCell>
                      <TableCell className="transport-route-details__table--ledger">
                        {item.acct_ldgr_id.label}
                      </TableCell>
                      {/* <TableCell className="transport-route-details__table--ledger">
                        {FeeData?.GetTransportFeeDetails}
                      </TableCell> */}
                      <TableCell id="td-center">
                        <img
                          src={Edit}
                          alt="/"
                          onClick={() => {
                            filterItemByReference(item.id);
                            setLocalId(item.id);
                            setOperation(Operation.UPDATE);
                          }}
                        />
                        <img
                          src={DeleteIcon}
                          alt="/"
                          onClick={() => deleteItem(item.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode="save"
          disabled={items.length === 0}
          buttonref={saveRef}
          onClick={HandleSubmit}
        />
        <Button
          mode="edit"
          onClick={() => {
            setEnableEdit(true);
            handleClear();
          }}
        />
        <Button mode="clear" onClick={handleClear} />
        <Button mode="delete" onClick={() => setDeleteModal(!deleteModal)} />
        <Button mode="view" onClick={() => setViewModal(!viewModal)} />
        <Button mode="reorder" onClick={() => setReorderModal(!reorderModal)}>
          Stages
        </Button>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* ledger-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={ledgerModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setLedgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setLedgerModal(!ledgerModal)}
            />
          </div>
        </div>
      </Modal>
      {/* view-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View modalFlag={setViewModal} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
      {/* delete-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DeleteRoute modalFlag={setDeleteModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      {/* reOrder-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={reorderModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ReOrder />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setReorderModal(!reorderModal)}
            />
          </div>
        </div>
      </Modal>
      {/* Add-Route Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={modalFlag}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AddRoute
              pageType={PageFor.MODAL}
              setModalFlag={setModalFlag}
              modalFlag={modalFlag}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setModalFlag(!modalFlag)} />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../../../images/Avatar.svg";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import { PageFor, StudentPreviewFor } from "../../../../utils/Enum.types";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { EMPTY_STRING } from "../../../../utils/constants";
import Close from "../../../../images/Close.svg";
import BasicData from "../StudentPreview/BasicData";
import PreviewTabs from "../StudentPreview/PreviewTabs";
import { AppContext } from "../../../../context/context";
import StudentPersonalData from "../StudentPreview/StudentPersonalData";
import PreviousInstDetails from "../StudentPreview/PreviousInstDetails";
import StudentParentAssoc from "../StudentPreview/StudentParentAssoc";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import View from "../../../../images/EyeWhite.svg";
import Replace from "../../../../images/Replace.svg";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  parentDashboardByParentIdData,
  parentStudentAssociationByParentIdVars,
} from "../../../../Types/Student";
import { ListParentStudentAssocByParentId } from "../../../../queries/parent/list/byId";
import useToken from "../../../../customhooks/useToken";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import DocsPreview from "../StudentPreview/DocsPreview";
import { TooltipForMultipleOption } from "../../../../styles/TooltipStyles";

interface Props {
  previewType: StudentPreviewFor;
}

const StudentDetails = ({ previewType }: Props) => {
  const [studentDetails, setStudentDetails] = useState(false);
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const navigate = useNavigate();
  const [imageString, setImageString] = useState("");
  const { studentFormData } = useStudentDatabyId();
  const { InstDetails } = useInstDetails(1);
  const { USE_BRANCH_KEY, USE_CLASS_KEY } = useInstitutionConfiguration();
  const { branchLabel, classLabel } = useInstLabels();

  const [GetParentStudentData, { data: ParentStudentAssociationData }] =
    useLazyQuery<
      parentDashboardByParentIdData,
      parentStudentAssociationByParentIdVars
    >(ListParentStudentAssocByParentId);
  useEffect(() => {
    if (token && state.parentId) {
      GetParentStudentData({
        variables: {
          token,
          parent_id: state.parentId,
        },
      });
    }
  }, [token, GetParentStudentData, state.parentId]);

  const studentData =
    ParentStudentAssociationData &&
    ParentStudentAssociationData.GetParentStudentAssociByParentId.map(
      (response) => response.student
    );
  useEffect(() => {
    if (
      studentFormData.std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentFormData.std_profile_filename, InstDetails.data, state.studentId]);
  return (
    <>
      <div className="student-dashboard__frame--grids--title">
        <Title variant="subtitle1">
          {state.claims!.PARENT ? (
            <>
              {studentFormData.first_name +
                "" +
                studentFormData.middle_name +
                "" +
                studentFormData.last_name}
              Details
            </>
          ) : (
            "My Details"
          )}
        </Title>
        <img src={More} alt="/" />
      </div>
      <div className="student-dashboard__frame--std-details">
        {imageString ? (
          <img src={imageString} alt="/" className="student-profile" />
        ) : (
          <img src={Avatar} alt="/" className="student-profile" />
        )}
        <div className="student-dashboard__frame--std-data">
          <div className="student-dashboard__frame--std-data--flex">
            <span>Name : </span>
            <b>
              {studentFormData.first_name +
                "" +
                studentFormData.middle_name +
                "" +
                studentFormData.last_name}
            </b>
          </div>
          <div className="student-dashboard__frame--std-data--flex">
            <span>Adm No. : </span>
            <b>{studentFormData.adm_no} </b>
          </div>

          {USE_BRANCH_KEY && (
            <div className="student-dashboard__frame--std-data--flex">
              <span>{branchLabel} : </span>
              <b>{studentFormData.branch} </b>
            </div>
          )}
          {USE_CLASS_KEY && (
            <div className="student-dashboard__frame--std-data--flex">
              <span>{classLabel} : </span>
              <b>{studentFormData.class} </b>
            </div>
          )}
        </div>
      </div>
      <div className="student-dashboard__frame--std-details--button">
        <button
          className="student-dashboard__frame--std-details--button--view"
          onClick={() => {
            setStudentDetails(!studentDetails);
            navigate(`/${state.InstId}/basicdetails`);
          }}
        >
          View Details
          <img
            src={View}
            alt=""
            onClick={() => setStudentDetails(!studentDetails)}
          />
        </button>

        {state.claims!.PARENT ? (
          <TooltipForMultipleOption
         
            placement="bottom"
            title={
              <>
                <Title variant="subtitle2">Select Children</Title>
                <ul className="student-dashboard__frame--multiple-std-details">
                  {studentData?.map((data, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          navigate(`/${data.id}/parentdashboard/student`);
                        }}
                      >
                        {data.std_profile_filename ? (
                          <img src={data.std_profile_filename} alt="" />
                        ) : (
                          <img src={Avatar} alt="/" />
                        )}
                        <span>
                          {data.first_name + data.middle_name + data.last_name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </>
            }
          >
            <button className="student-dashboard__frame--std-details--button--swap">
              Swap <img src={Replace} alt="" />
            </button>
          </TooltipForMultipleOption>
        ) : null}
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentDetails}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PreviewTabs pageType={PageFor.MODAL} />
            {previewType === StudentPreviewFor.BASIC_DETAILS ? (
              <BasicData
                pageType={PageFor.MODAL}
                setModal={setStudentDetails}
              />
            ) : previewType === StudentPreviewFor.PERSONAL_DETAILS ? (
              <StudentPersonalData
                pageType={PageFor.MODAL}
                setModal={setStudentDetails}
              />
            ) : previewType === StudentPreviewFor.PREV_INST_DETAILS ? (
              <PreviousInstDetails
                pageType={PageFor.MODAL}
                setModal={setStudentDetails}
              />
            ) : previewType === StudentPreviewFor.STUDENT_DOCS ? (
              <DocsPreview
                pageType={PageFor.MODAL}
                setModal={setStudentDetails}
              />
            ) : previewType === StudentPreviewFor.PARENT_DETAILS ? (
              <StudentParentAssoc
                pageType={PageFor.MODAL}
                setModal={setStudentDetails}
              />
            ) : null}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentDetails(!studentDetails)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentDetails;

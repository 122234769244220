import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS_1,
  FROZEN_CELLS_INST,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import { ExcelAlignment, ExcelPageHeader, FileUploadParams } from "../../../utils/Enum.types";
import { PayRollMasters } from "../enums/Enum.types";
import { getMasterDetails } from "./utils/GetMasterDetails";
import {
  getHeaderRowStyle,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import Eduate from "../../../images/Eduate_Logo_image.png";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import usePayRollMasters from "../hooks/usePayRollMasters";
import { EMPTY_STRING } from "../../../utils/constants";
import { AppContext } from "../../../context/context";
import { useContext } from "react";

const useMastersExport = (MasterType: PayRollMasters) => {
  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});
  const { InstFormData } = useInstDetails(1);
  const { serverDate } = useServerDateandTime();
  const { state } = useContext(AppContext);
  const { dropDown } = usePayRollMasters(MasterType, EMPTY_STRING, null);
  const downloadExcel = () => {
    const { header, sheetname } = getMasterDetails(MasterType) || {};
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet(sheetname);
    worksheet.views = FROZEN_CELLS_INST;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    worksheet1.views = FROZEN_CELLS_1;

    worksheet1.getColumn(1).width = 30;
    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstFormData.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value = InstFormData.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstFormData.inst_place + "-" + InstFormData.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                  const rowData: Cell = worksheet1.getCell(Char + 1);
                  rowData.value = header?.Headers;
                  rowData.fill = TABLE_HEADER_CSS;
                  rowData.font = ACC_HEADER_FONT;
                  rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  let excel_name = InstFormData.inst_name;

                  // if (forError) {
                  //   errorData?.forEach((errData, index) => {
                  //     const cell_number = startCell + index;
                  //     const rowErrorData: Cell = worksheet1.getCell(
                  //       `A${cell_number}`
                  //     );
                  //     rowErrorData.value = errData?.desc;
                  //   });
                  //   excel_name = `${InstName}errors`;
                  // }
                  if (dropDown.length) {
                    for (let i = 0; i <= dropDown.length; i++) {
                      const rowData: Cell = worksheet1.getCell(`A${i + 2}`);
                      rowData.value = dropDown[i]
                        ? dropDown[i].label
                        : EMPTY_STRING;

                      rowData.alignment = {
                        horizontal: ExcelAlignment.CENTER,
                      };
                    }
                  }
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, excel_name);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  return downloadExcel;
};

export default useMastersExport;

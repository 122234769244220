import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../../Home/Index";

import {  TextField } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Enter from "../../../../images/Enter.svg";
import Delete from "../../../../images/Delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import Close from "../../../../images/Close.svg";
import useCategories, { categoryType } from "../customHooks/useCategories";
import {
  AutoCloseButton,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../utils/constants";
import { RefsByTagName } from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import { AddAcdSubjectComboAndDetails } from "../../../../queries/customallocation/combination/mutations";
import { useMutation } from "@apollo/client";
import {
  AddAcdSubjectComboAndDetailsData,
  AddAcdSubjectComboAndDetailsVars,
} from "../../../../Types/Combinations/mutations";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import MessageModal from "../../../../pages/MessageModal";
import { GetAcdSubjectCombos } from "../../../../queries/customallocation/combination/queries";
import { AppContext } from "../../../../context/context";
import useCombinationByNodeId from "../customHooks/useCombinationByNodeId";
import { payloadTypes } from "../../../../context/reducer";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
import { FormAutocomplete, formClasses } from "../../../../styles/AutocompleteStyles";
const { Academics_Table } = require("../../json/table.json");

interface props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Add = ({ pageType, setModal }: props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const [searchCatName, setCatName] = useState("");
  const [subj_combo_name, setCombinationName] = useState("");

  const [message, setMessage] = useState(emptyMessageType);

  const catRef = useRef<HTMLSelectElement>(null);
  const catInputRef = RefsByTagName(catRef);
  const subRef = useRef<HTMLSelectElement>(null);
  const combinationRef = useRef<HTMLInputElement>(null);

  const autoClearBtn = subRef.current
    ? (subRef.current.getElementsByClassName(
        AutoCloseButton
      )[0] as HTMLButtonElement)
    : null;
  const [selectedCategory, setSelectedCategory] = useState<categoryType | null>(
    null
  );

  const { state, dispatch } = useContext(AppContext);
  const { data } = useCombinationByNodeId();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { user_details } = useLoggedInUserDetails();
  const { categories } = useCategories(ROWS_PER_PAGE, searchCatName);
  const [items, setItems] = useState<categoryType[]>([]);
  const [AddCombo] = useMutation<
    AddAcdSubjectComboAndDetailsData,
    AddAcdSubjectComboAndDetailsVars
  >(AddAcdSubjectComboAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleItems = () => {
    const foundItem = items.find(
      (item) => selectedCategory && item.value === selectedCategory.value
    );
    if (selectedCategory)
      if (foundItem) {
        alert("Subject already exists");
      } else {
        setItems((prevItems) => {
          return [...prevItems, selectedCategory];
        });
      }
    setSelectedCategory(null);
    if (autoClearBtn) autoClearBtn.click();
  };

  const handleDeleteItem = (id: number) => {
    setItems(items.filter((item) => item.value !== id));
  };
  const handleSubmit = () => {
    AddCombo({
      variables: {
        token,
        subj_combo_name,
        inst_id: InstId!,
        user_details,
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        input: items.map((item, index) => ({
          idx: index + 1,
          acd_subj_cat_id: item.value,
        })),
      },
      refetchQueries: [
        {
          query: GetAcdSubjectCombos,
          variables: {
            token,
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: EMPTY_STRING,
            query_type: AcdSubjComboQueryType.ALL_COMBOS,
            entry_id: 0,
            entry_level: EMPTY_STRING,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.SUBJ_COMBO_NAME,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.AddAcdSubjectComboAndDetails) {
        setMessage({
          flag: true,
          message: `${subj_combo_name} ${
            pageType === PageFor.MODAL ? "updtaed" : "created"
          } successfully`,
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_ACD_COMBO_ID,
      payload: { acdCombinationId: 0 },
    });
    setCatName("");
    setSelectedCategory(null);
    setItems([]);
    setCombinationName("");
    combinationRef.current && combinationRef.current.focus();
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage(emptyMessageType);
  };

  useEffect(() => {
    if (state.acdCombinationId && pageType === PageFor.MODAL && data) {
      setCombinationName(data.node.subj_combo_name);
      setItems(
        data.node.subj_combo_details.map((item) => ({
          label: item.category_details.subj_cat_name,
          value: item.category_details.id,
          subj_cat_details: item.category_details.subj_cat_details,
        }))
      );
    }
  }, [state.acdCombinationId, pageType, data]);
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <div className="add-combinations__title">
        <Title>
          {pageType === PageFor.GENERAL
            ? "Associate Category to Combination"
            : "Edit Associated Category to Combination"}
        </Title>
        {pageType === PageFor.MODAL && (
          <img src={Close} alt="" onClick={() => setModal(false)} />
        )}
      </div>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "add-combinations"
            : "add-combinations__modal"
        }
      >
        <div className="add-combinations__row row g-0">
          <div className="col-6 add-combinations__block">
            <div className="label-grid">
              <Label>Enter Combination Description</Label>
              {pageType === PageFor.GENERAL ? (
                <Input
                  value={subj_combo_name}
                  onChange={(e) => setCombinationName(e.target.value)}
                  required
                  autoFocus
                  inputRef={combinationRef}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      catInputRef.focus();
                    }
                  }}
                  error={subj_combo_name ? "" : "Combination is required"}
                />
              ) : (
                <Input disabled value={subj_combo_name} />
              )}
            </div>
          </div>
        </div>
        <div className="add-combinations__tableblock">
          <TableContainer className="add-combinations__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.CustomAllocation.AssociateCombination.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="add-combinations__table--row">
                  <TableCell
                    className="add-combinations__table--slno"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="add-combinations__table--select">
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={categories}
                      openOnFocus
                      ref={catRef}
                      value={selectedCategory}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSelectedCategory(newValue as categoryType);
                        } else {
                          setSelectedCategory(null);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          handleItems();
                        }
                      }}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    id="td-center"
                    className="add-combinations__table--actions"
                  >
                    <img src={Enter} alt="" onClick={handleItems} />
                  </TableCell>
                </TableRow>
                {items.map((res, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow className="add-combinations__table--group">
                        <TableCell colSpan={3}>
                          {index + 1}. {res.label}
                        </TableCell>
                        <TableCell id="td-center">
                          <img
                            src={Delete}
                            alt=""
                            onClick={() => {
                              handleDeleteItem(res.value);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      {res.subj_cat_details.map((response, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell></TableCell>
                            <TableCell>
                              {response.subject_details.subj_desc}
                            </TableCell>
                            <TableCell>
                              {response.subject_details.subj_code}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button mode="clear" onClick={handleClear} />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button
            mode="cancel"
            onClick={() => {
              dispatch({
                payload: { acdCombinationId: 0 },
                type: payloadTypes.SET_ACD_COMBO_ID,
              });
              setModal(false);
            }}
          />
        )}
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Add;

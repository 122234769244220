import React, { useContext, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { TEXT_FONT_FAMILY } from "../../../styles/DataGridTableStyles";
import { useLazyQuery } from "@apollo/client";
import { GetStudentEnquiryDashboardDetailsData } from "./Details";
import { DashboardDetailsVars } from "./Index";
import { GetStudentEnquiryDashboardDetails } from "../../../queries/students/list/newApi";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import {
  convertToK,
  getDynamicIntervals,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../utils/constants";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import { MonthlyCountProps } from "./MonthlyCount";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";

const ReferenceThrough = ({ startDate, endDate }: MonthlyCountProps) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const pollIntervalTime = usePollIntervalTime();

  const [GetEnqStudentData, { data }] = useLazyQuery<
    GetStudentEnquiryDashboardDetailsData,
    DashboardDetailsVars
  >(GetStudentEnquiryDashboardDetails, {
    variables: {
      token,
      inst_id: InstId!,
      pr_emp_id: 0,
      today_date: toIsoDate(serverDate),
      enq_start_date: startDate ? toIsoDate(startDate) : EMPTY_STRING,
      enq_end_date: endDate ? toIsoDate(endDate) : EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (state.ActiveFinYr && serverDate !== EMPTY_STRING) {
      GetEnqStudentData();
    }
  }, [state.ActiveFinYr, GetEnqStudentData, serverDate, token]);

  const newsPaperAds =
    data && data.GetStudentEnquiryDashboardDetails.newspaper_ads_count;

  const flyersCount =
    data && data.GetStudentEnquiryDashboardDetails.flyers_count;

  const digitalCount =
    data && data.GetStudentEnquiryDashboardDetails.digital_count;
  const parentsCount =
    data && data.GetStudentEnquiryDashboardDetails.parents_count;
  const staffCount = data && data.GetStudentEnquiryDashboardDetails.staff_count;
  const walkinCount =
    data && data.GetStudentEnquiryDashboardDetails.walkin_count;
  const othersCount =
    data && data.GetStudentEnquiryDashboardDetails.others_count;

  const MaxCount = Math.max(
    newsPaperAds!,
    flyersCount!,
    parentsCount!,
    staffCount!,
    digitalCount!,
    walkinCount!,
    othersCount!
  );
  const maxNumber = Math.ceil(MaxCount / 100) * 100;
  const intervals = getDynamicIntervals(maxNumber);
  const maxAmountFromIntervals = Math.max(...intervals);

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        saveAsImage: { show: true },
      },
    },
    legend: {
      textStyle: {
        color: "black",
        fontSize: 12,
        fontFamily: TEXT_FONT_FAMILY,
      },
      icon: "rect",
      bottom: 10,
      width: "90%",
      type: "scroll",
      show: true,

      data: [
        "NewsPaperads",
        "Flyers",
        "Digital",
        "Parents",
        "Staff",
        "Walkin",
        "Others",
      ],
    },
    xAxis: [
      {
        textStyle: {
          color: "black",
          fontSize: 8,
          fontFamily: TEXT_FONT_FAMILY,
        },
        type: "category",
        data: [
          "NewsPaperads",
          "Flyers",
          "Digital",
          "Parents",
          "Staff",
          "Walkin",
          "Others",
        ],
        axisPointer: {
          type: "shadow",
        },
        axisLabel: {
          rotate: -25,
        },
      },
    ],
    yAxis: [
      {
        textStyle: {
          color: "black",
          fontSize: 10,
          fontFamily: TEXT_FONT_FAMILY,
        },
        type: "value",
        name: "Reference",
        min: 0,
        max: maxAmountFromIntervals,
        interval: intervals[1] - intervals[0],
        axisLabel: {
          formatter: (value: number) => convertToK(value),
        },
      },
    ],
    series: [
      {
        type: "bar",
        barWidth: 20,
        tooltip: {
          valueFormatter: function (value: number) {
            return value + " ";
          },
        },
        data: [
          { value: newsPaperAds, itemStyle: { color: "#67e8f9" } },
          { value: flyersCount, itemStyle: { color: "#86efac" } },
          { value: digitalCount, itemStyle: { color: "#a5b4fc" } },
          { value: parentsCount, itemStyle: { color: "#bef264" } },
          { value: staffCount, itemStyle: { color: "#fdba74" } },
          { value: walkinCount, itemStyle: { color: "#f9a8d4" } },
          { value: othersCount, itemStyle: { color: "#d8b4fe" } },
        ],
      },
    ],
  };
  return (
    <>
      <ReactEcharts option={options} className="enquiry-dashboard__charts" />
    </>
  );
};

export default ReferenceThrough;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetDeptsByInstId } from "../queries/institution/department/list/byid";
import {
  BranchesListDataByDeptId,
  BranchListVarsByDeptId,
  ClassListDataByBranchId,
  ClassListVarsBranchId,
  DepartmentListDataByInstId,
  DepartmentListVarsByInstId,
  SectionListDataBySemesterId,
  SectionListVarsBySemesterId,
  SemesterListDataByClassId,
  SemesterListVarsByClassId,
} from "../Types/Student";
import { ByDeptIDs as BranchesByDeptIds } from "../queries/institution/branch/list/byid";

import { InstitutionDetails } from "../utils/Enum.types";
import { ClassesByBranchIds } from "../queries/institution/classes/list/byid";

import { GetNotAllocatedIds } from "../queries/common";
import { SemestersByClassIds } from "../queries/institution/sem/list/byid";
import { SectionsBySemIds } from "../queries/sections/list/byId";
import { responseType } from "../utils/Form.types";
import useInstitutionConfiguration from "./useInstitutionConfiguration";
import useToken from "./useToken";
export interface NotAllocatedIdsDetails {
  dept_id: number;
  branch_id: number;
  class_id: number;
  semester_id: number;
  section_id: number;
}
export interface NotAllocatedIdsData {
  GetNotAllocatedIds: NotAllocatedIdsDetails;
}
export interface GetNotAllocatedIdsVars {
  token: string;
  inst_id: string;
  table: string;
  table_id: number;
}
const useDropdownData = (
  selectedDeptId: number,
  selectedbranchId: number,
  selectedClassId: number,
  selectedSemester: number,
  selectedSection?: number
) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [departmentId, setDeptId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [semesterId, setSemesterId] = useState(0);
  const [sectionId, setSectionId] = useState(0);
  const [departmentDropDown, setDepartmentDropDown] = useState<responseType[]>(
    []
  );
  const [branchDropDown, setBranchDropDown] = useState<responseType[]>([]);
  const [classDropDown, setClassDropDown] = useState<responseType[]>([]);
  const [semesterDropDown, setSemesterDropDown] = useState<responseType[]>([]);
  const [sectionDropDown, setSectionDropDown] = useState<responseType[]>([]);
  const [DepartmentsByInstId] = useLazyQuery<
    DepartmentListDataByInstId,
    DepartmentListVarsByInstId
  >(GetDeptsByInstId, { variables: { inst_id: InstId!, token } });
  const [GetBranchesByDept] = useLazyQuery<
    BranchesListDataByDeptId,
    BranchListVarsByDeptId
  >(BranchesByDeptIds);
  const [GetClassesByBranch] = useLazyQuery<
    ClassListDataByBranchId,
    ClassListVarsBranchId
  >(ClassesByBranchIds);

  const [GetSemesterByClass] = useLazyQuery<
    SemesterListDataByClassId,
    SemesterListVarsByClassId
  >(SemestersByClassIds);
  const [GetSectionBySemester] = useLazyQuery<
    SectionListDataBySemesterId,
    SectionListVarsBySemesterId
  >(SectionsBySemIds);
  const [GetNotallocted, { data: notAllocatedIdData }] = useLazyQuery<
    NotAllocatedIdsData,
    GetNotAllocatedIdsVars
  >(GetNotAllocatedIds);
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();

  useEffect(() => {
    if (selectedDeptId >= 0) {
      setDeptId(selectedDeptId);
    }
    if (selectedbranchId >= 0) {
      setBranchId(selectedbranchId);
    }
    if (selectedClassId >= 0) {
      setClassId(selectedClassId);
    }
    if (selectedSemester >= 0) {
      setSemesterId(selectedSemester);
    }
  }, [selectedDeptId, selectedbranchId, selectedClassId, selectedSemester]);

  const GetBranches = (dept_id: number) => {
    if (selectedDeptId === 0 && USE_DEPARTMENT_KEY) {
      setBranchDropDown([]);
      setClassDropDown([]);
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (dept_id) {
      GetBranchesByDept({
        variables: {
          //sometimes the departmentId remains 0 because the useEffect does not re-run so we will always have the value
          dept_ids: [dept_id],
          token,
        },
      }).then(({ data }) => {
        if (data && data.GetBranchesByDeptIds) {
          const filteredBranch = data?.GetBranchesByDeptIds.filter(
            (id) => id !== null
          ).map((branch) => ({
            label: branch?.branch_desc,
            value: Number(branch.id),
          }));
          setBranchDropDown(filteredBranch);
        } else {
          setBranchDropDown([]);
        }
      });
    }
  };
  const GetClasses = (branch_id: number) => {
    if (selectedbranchId === 0 && USE_BRANCH_KEY) {
      setClassDropDown([]);
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (branch_id) {
      GetClassesByBranch({
        variables: {
          branch_ids: [branch_id],
          token,
        },
      }).then(({ data }) => {
        if (data && data.GetClassesByBranchIds) {
          const filteredClass = data.GetClassesByBranchIds.filter(
            (id) => id !== null
          ).map((branch) => ({ label: branch.class_desc, value: branch.id }));

          setClassDropDown(filteredClass);
        } else {
          setClassDropDown([]);
        }
      });
    }
  };
  const GetSemester = (class_id: number) => {
    if (selectedClassId === 0 && USE_CLASS_KEY) {
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (class_id) {
      GetSemesterByClass({
        variables: {
          class_ids: [class_id]!,
          token,
        },
      }).then(({ data }) => {
        if (data && data.GetSemestersByClassIds) {
          const filteredSemester = data.GetSemestersByClassIds.filter(
            (id) => id !== null
          ).map((sem) => ({ label: sem.sem_desc, value: sem.id }));
          setSemesterDropDown(filteredSemester);
        } else {
          setSemesterDropDown([]);
        }
      });
    }
  };
  const GetSections = (sem_id: number) => {
    if (selectedSemester === 0 && USE_SEMESTER_KEY) {
      setSectionDropDown([]);
    } else if (sem_id) {
      GetSectionBySemester({
        variables: {
          sem_ids: [sem_id],
          token,
        },
      }).then(({ data }) => {
        if (data && data.GetSectionsBySemesterIds) {
          const filteredSection = data.GetSectionsBySemesterIds.filter(
            (id) => id !== null
          ).map((section) => ({
            label: section.section_desc,
            value: section.id,
          }));
          setSectionDropDown(filteredSection);
        } else {
          setSectionDropDown([]);
        }
      });
    } else {
      setSectionDropDown([]);
    }
  };

  useEffect(() => {
    // dept false
    if (token) {
      if (departmentId === 0) {
        if (USE_DEPARTMENT_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.TABLE_NONE,
              table_id: 0,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              // setting notallocated deptid
              if (data.GetNotAllocatedIds.dept_id) {
                setDeptId(data.GetNotAllocatedIds.dept_id);
                GetBranches(data.GetNotAllocatedIds.dept_id);
              }
            }
          });
        } else {
          DepartmentsByInstId().then(({ data }) => {
            if (data) {
              const filteredDepartment = data?.GetDeptsByInstId?.filter(
                (id) => id !== null
              ).map((dept) => ({ label: dept.dept_desc, value: dept.id }));
              setDepartmentDropDown(filteredDepartment);
            }
          });
        }
      }

      // branch false
      if (branchId === 0 && departmentId !== 0) {
        if (USE_BRANCH_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.DEPARTMENT,
              table_id: departmentId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetNotAllocatedIds.branch_id) {
                setBranchId(data.GetNotAllocatedIds.branch_id);
                GetClasses(data.GetNotAllocatedIds.branch_id);
              }
            }
          });
        } else {
          GetBranches(departmentId);
        }
      } else {
        GetBranches(departmentId);
      }

      // class false
      if (classId === 0 && branchId !== 0) {
        if (USE_CLASS_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.BRANCH,
              table_id: branchId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetNotAllocatedIds.class_id) {
                setClassId(data.GetNotAllocatedIds.class_id);
                GetSemester(data.GetNotAllocatedIds.class_id);
              }
            }
          });
        }
        // true class
        else {
          GetClasses(branchId);
          setSemesterDropDown([]);
        }
      } else {
        GetClasses(branchId);
      }
      if (semesterId === 0 && classId !== 0) {
        // //semseter false
        if (USE_SEMESTER_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.CLASS,
              table_id: classId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetNotAllocatedIds.semester_id) {
                setSemesterId(data.GetNotAllocatedIds.semester_id);
                GetSections(data.GetNotAllocatedIds.semester_id);
              }
            }
          });
        }
        // true semester
        else {
          GetSemester(classId);
          setSectionDropDown([]);
        }
      } else {
        GetSemester(classId);
      }
      //section
      if (
        semesterId !== 0 &&
        departmentId !== 0 &&
        branchId !== 0 &&
        classId !== 0
      ) {
        if (USE_SECTION_KEY === false || selectedSection === undefined) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.SEMESTER,
              table_id: semesterId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              setSectionId(data.GetNotAllocatedIds.section_id);
            }
          });
        }

        // true section
        else {
          setSectionId(selectedSection!);
        }
        GetSections(semesterId);
      }
    }
    // eslint-disable-next-line
  }, [
    InstId,
    token,
    selectedDeptId,
    selectedbranchId,
    selectedSemester,
    selectedClassId,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    DepartmentsByInstId,
    GetBranchesByDept,
    GetClassesByBranch,
    GetNotallocted,
    GetSectionBySemester,
    GetSemesterByClass,
    branchId,
    classId,
    departmentId,
    semesterId,
    selectedSection,
    sectionId,
    notAllocatedIdData,
  ]);

  return {
    departmentDropDown,
    branchDropDown,
    classDropDown,
    semesterDropDown,
    sectionDropDown,
    // we are not sendng the depatmentId because, it's already notallocated depending on instId
    // this will return not allocated ids if the flg of any master configuration is disabled, else it will return the same id which we are passing thorugh the props.
    masterIds: { branchId, classId, semesterId, sectionId },
  };
};

export default useDropdownData;

import { gql } from "@apollo/client";

export const AddPayRollLeaveLdgr = gql`
  mutation AddPayRollLeaveLdgr(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollLeaveLdgrInput!
  ) {
    AddPayRollLeaveLdgr(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const AddOrDeletePayRollPredefinedLeaveLdgr = gql`
  mutation AddOrDeletePayRollPredefinedLeaveLdgr(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: AddOrDeleteLeaveLdgrs!
  ) {
    AddOrDeletePayRollPredefinedLeaveLdgr(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const AddSwLeaveLdgr = gql`
  mutation AddSwLeaveLdgr(
    $token: String!
    $input: CreateSwLeaveLdgrInput!
    $user_details: SubscribedUser!
  ) {
    AddSwLeaveLdgr(token: $token, input: $input, user_details: $user_details)
  }
`;

export const UpdatePayRollLeaveLdgr = gql`
  mutation UpdatePayRollLeaveLdgr(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollLeaveLdgrInput!
  ) {
    UpdatePayRollLeaveLdgr(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdateSwLeaveLdgr = gql`
  mutation UpdateSwLeaveLdgr(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwLeaveLdgrInput!
  ) {
    UpdateSwLeaveLdgr(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;

export const DeletePayRollLeaveLdgrById = gql`
  mutation DeletePayRollLeaveLdgrById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollLeaveLdgrById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteSwLeaveLdgrById = gql`
  mutation DeleteSwLeaveLdgrById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteSwLeaveLdgrById(token: $token, id: $id, user_details: $user_details)
  }
`;

export const AddPayRollEmpLeavesMasterAndDetails = gql`
  mutation AddPayRollEmpLeavesMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $pr_emp_id: ID!
    $input: [AddEmpLeaveDetails!]!
  ) {
    AddPayRollEmpLeavesMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      pr_emp_id: $pr_emp_id
      input: $input
    )
  }
`;

export const UpdatePayRollEmpLeavesMasterAndDetails = gql`
  mutation UpdatePayRollEmpLeavesMasterAndDetails(
    $token: String!
    $input: PrUpdateEmpLeaveMasterAndDetails!
  ) {
    UpdatePayRollEmpLeavesMasterAndDetails(token: $token, input: $input)
  }
`;

export const DeletePayRollEmpLeavesMasterAndDetails = gql`
  mutation DeletePayRollEmpLeavesMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $pr_emp_id: ID!
  ) {
    DeletePayRollEmpLeavesMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      pr_emp_id: $pr_emp_id
    )
  }
`;

export const UpdatePayRollEmpLeaveDetails = gql`
  mutation UpdatePayRollEmpLeaveDetails(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $pr_emp_id: ID!
    $emp_sal_month_att_id: ID!
    $input: [UpdateLeaveDetails!]!
  ) {
    UpdatePayRollEmpLeaveDetails(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      pr_emp_id: $pr_emp_id
      emp_sal_month_att_id: $emp_sal_month_att_id
      input: $input
    )
  }
`;

export const AddOrUpdateOrDeletePayRollEmpLeaveLdgrs = gql`
  mutation AddOrUpdateOrDeletePayRollEmpLeaveLdgrs(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
    $pr_acd_yr_id: ID!
    $input: AddOrUpdateOrDeleteEmpLeaveDetails!
  ) {
    AddOrUpdateOrDeletePayRollEmpLeaveLdgrs(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
      pr_acd_yr_id: $pr_acd_yr_id
      input: $input
    )
  }
`;

export const UpdatePayRollEmpDefaultLeaveLdgr = gql`
  mutation UpdatePayRollEmpDefaultLeaveLdgr(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: DefaultEmpLdgrs!
  ) {
    UpdatePayRollEmpDefaultLeaveLdgr(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const AddPayRollEmpDefaultLeaveLdgr = gql`
  mutation AddPayRollEmpDefaultLeaveLdgr(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [DefaultEmpLdgrs!]!
  ) {
    AddPayRollEmpDefaultLeaveLdgr(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const DeletePayRollEmpDefaultLeaveLdgr = gql`
  mutation DeletePayRollEmpDefaultLeaveLdgr(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollEmpDefaultLeaveLdgr(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

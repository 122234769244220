import { useState } from "react";

import AssignmentsList from "./AssignmentsList";
import AssignmentsChatView from "./AssignmentsChatView";

const StudentAssignments = () => {
  const [subjectId, setSubjectId] = useState(0);
  return (
    <div className="stu-assignments">
      <AssignmentsList setSubjectId={setSubjectId} />

      <div className="stu-assignments__chat-view">
        {subjectId ? (
          <AssignmentsChatView channelId={subjectId} />
        ) : (
          <b className="nodata">Please Select Subject</b>
        )}
      </div>
    </div>
  );
};

export default StudentAssignments;

import { useLazyQuery } from "@apollo/client";
import {
  GetAcdTestReportCardConfigData,
  GetAcdTestReportCardConfigVars,
} from "../../../Academics/types/Progresscard";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { GetAcdTestReportCardConfig } from "../../../Academics/queries/reportcard";
import { useEffect } from "react";
import { reportCardConfigTypes } from "../../Enum.types";

const usePerTestReportConfig = () => {
  const { token } = useToken();
  const { InstId, testId } = useParams();
  const [GetReportCartConfigDetails, { data }] = useLazyQuery<
    GetAcdTestReportCardConfigData,
    GetAcdTestReportCardConfigVars
  >(GetAcdTestReportCardConfig, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_test_class_id: Number(testId),
    },
  });

  useEffect(() => {
    if (token && InstId && testId) {
      GetReportCartConfigDetails();
    }
  }, [token, InstId, testId, GetReportCartConfigDetails]);

  const custName =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_CUSTOMER_NAME
    );
  const instLogo =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_LOGO
    );
  const stdImage =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STUDENT_PHOTO
    );
  const header =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_HEADER
    );
  const affiliatedTo =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_AFFILIATED_CAPTION
    );
  const photo1Config =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PHOTO_1
    );
  const photo2Config =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PHOTO_2
    );
  const instName =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_NAME
    );

  const instAddress =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_ADDRESS
    );
  const instEmail =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_EMAIL
    );

  const instWebsite =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_INST_WEBSITE
    );
  const gradeScale =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_GRADE_SCALE
    );
  const stdName =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_NAME
    );
  const section =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_CLASS_SECTION
    );
  const rollNo =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ROLL_NUMBER
    );
  const dob =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_DOB
    );
  const motherName =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_MOTHER_NAME
    );
  const fatherName =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_FATHER_NAME
    );

  const stdAddress =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ADDRESS
    );

  const stdMobile =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_MOBILE
    );
  const stdEmail =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_EMAIL
    );
  const parentSign =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_PARENT_SIGN
    );
  const classTeacherSign =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_CLASS_TEACHER_SIGN
    );
  const attendanceStats =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_ATTENDANCE_STATS
    );
  const remarksPerTest =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_REMARKS_PER_TEST
    );
  const gradeForEachSub =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) =>
        res.config_key === reportCardConfigTypes.RP_GRADE_FOR_EACH_SUBJECT
    );

  const heightWeight =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_HEIGHT_WEIGHT
    );

  const overAllPercentage =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_OVERALL_PERCENTAGE
    );
  const annualResults =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_ANNUAL_RESULTS
    );

  const overAllGrade =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_OVERALL_GRADE
    );
  const overAllRank =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_STD_OVERALL_RANK
    );
  const title =
    data &&
    data.GetAcdTestReportCardConfig.find(
      (res) => res.config_key === reportCardConfigTypes.RP_TITLE
    );
  return {
    instAddress,
    instLogo,
    instName,
    instWebsite,
    gradeScale,
    instEmail,
    stdEmail,
    stdMobile,
    stdAddress,
    stdName,
    section,
    dob,
    motherName,
    fatherName,
    rollNo,
    custName,
    parentSign,
    attendanceStats,
    heightWeight,
    gradeForEachSub,
    remarksPerTest,
    classTeacherSign,
    overAllPercentage,
    annualResults,
    overAllGrade,
    overAllRank,
    stdImage,
    header,
    affiliatedTo,
    photo1Config,
    photo2Config,
    title,
  };
};
export default usePerTestReportConfig;

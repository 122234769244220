import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAcdNotAllocatedIds } from "../queries/general";

import {
  GetAcdBranchesByAcdDeptData,
  GetAcdBranchesByAcdDeptVars,
  GetAcdClassesByAcdBranchIdsData,
  GetAcdClassesByAcdBranchIdsVars,
  GetAcdDeptsByInstIdData,
  GetAcdDeptsByInstIdVars,
  GetAcdSectionsByAcdSemesterIdsData,
  GetAcdSectionsByAcdSemesterIdsVars,
  GetAcdSemestersByAcdClassIdsData,
  GetAcdSemestersByAcdClassIdsVars,
} from "../types/genreal";

import { InstitutionDetails } from "../../../utils/Enum.types";

import { responseType } from "../../../utils/Form.types";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useToken from "../../../customhooks/useToken";
import {
  GetNotAllocatedAcdIdsVars,
  NotAllocatedAcdIdsData,
} from "./useAcdNotAllocatedIds";
import { GetAcdDeptsByInstId } from "../queries/instdetails/department/byId";
import { GetAcdBranchesByAcdDeptIds } from "../queries/instdetails/branch/byId";
import { GetAcdClassesByAcdBranchIds } from "../queries/instdetails/class/byId";
import { GetAcdSemestersByAcdClassIds } from "../queries/instdetails/semester/byId";
import { GetAcdSectionsByAcdSemesterIds } from "../queries/instdetails/section/byId";

const useAcdDropDownData = (
  selectedDeptId: number,
  selectedbranchId: number,
  selectedClassId: number,
  selectedSemester: number,
  selectedSection?: number
) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [departmentId, setDeptId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [semesterId, setSemesterId] = useState(0);
  const [sectionId, setSectionId] = useState(0);
  const [departmentDropDown, setDepartmentDropDown] = useState<responseType[]>(
    []
  );

  const [branchDropDown, setBranchDropDown] = useState<responseType[]>([]);
  const [classDropDown, setClassDropDown] = useState<responseType[]>([]);
  const [semesterDropDown, setSemesterDropDown] = useState<responseType[]>([]);
  const [sectionDropDown, setSectionDropDown] = useState<responseType[]>([]);

  const [DepartmentsByInstId] = useLazyQuery<
    GetAcdDeptsByInstIdData,
    GetAcdDeptsByInstIdVars
  >(GetAcdDeptsByInstId, { variables: { inst_id: InstId!, token } });
  const [GetBranchesByDept] = useLazyQuery<
    GetAcdBranchesByAcdDeptData,
    GetAcdBranchesByAcdDeptVars
  >(GetAcdBranchesByAcdDeptIds);
  const [GetClassesByBranch] = useLazyQuery<
    GetAcdClassesByAcdBranchIdsData,
    GetAcdClassesByAcdBranchIdsVars
  >(GetAcdClassesByAcdBranchIds);
  const [GetSemesterByClass] = useLazyQuery<
    GetAcdSemestersByAcdClassIdsData,
    GetAcdSemestersByAcdClassIdsVars
  >(GetAcdSemestersByAcdClassIds);
  const [GetSectionBySemester] = useLazyQuery<
    GetAcdSectionsByAcdSemesterIdsData,
    GetAcdSectionsByAcdSemesterIdsVars
  >(GetAcdSectionsByAcdSemesterIds);
  const [GetNotallocted, { data: notAllocatedIdData }] = useLazyQuery<
    NotAllocatedAcdIdsData,
    GetNotAllocatedAcdIdsVars
  >(GetAcdNotAllocatedIds);
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  useEffect(() => {
    if (selectedDeptId >= 0) {
      setDeptId(selectedDeptId);
    }
    if (selectedbranchId >= 0) {
      setBranchId(selectedbranchId);
    }
    if (selectedClassId >= 0) {
      setClassId(selectedClassId);
    }
    if (selectedSemester >= 0) {
      setSemesterId(selectedSemester);
    }
  }, [selectedDeptId, selectedbranchId, selectedClassId, selectedSemester]);
  const GetBranches = (dept_id: number) => {
    if (selectedDeptId === 0 && USE_DEPARTMENT_KEY) {
      setBranchDropDown([]);
      setClassDropDown([]);
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (dept_id) {
      GetBranchesByDept({
        variables: {
          //sometimes the departmentId remains 0 because the useEffect does not re-run so we will always have the value
          acd_dept_ids: [dept_id],
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredBranch = data?.GetAcdBranchesByAcdDeptIds?.filter(
            (id) => id !== null
          ).map((branch) => ({
            label: branch?.branch_desc,
            value: Number(branch.id),
          }));
          setBranchDropDown(filteredBranch);
        }
      });
    }
  };

  const GetClasses = (branch_id: number) => {
    if (selectedbranchId === 0 && USE_BRANCH_KEY) {
      setClassDropDown([]);
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (branch_id) {
      GetClassesByBranch({
        variables: {
          acd_branch_ids: [branch_id],
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredClass = data?.GetAcdClassesByAcdBranchIds?.filter(
            (id) => id !== null
          ).map((branch) => ({
            label: branch.class_desc,
            value: branch.id,
          }));
          setClassDropDown(filteredClass);
        }
      });
    }
  };
  const GetSemester = (class_id: number) => {
    if (selectedClassId === 0 && USE_CLASS_KEY) {
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (class_id) {
      GetSemesterByClass({
        variables: {
          acd_class_ids: [class_id]!,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredSemester = data?.GetAcdSemestersByAcdClassIds?.filter(
            (id) => id !== null
          ).map((sem) => ({ label: sem.sem_desc, value: sem.id }));
          setSemesterDropDown(filteredSemester);
        }
      });
    }
  };
  const GetSections = (sem_id: number) => {
    if (selectedSemester === 0 && USE_SEMESTER_KEY) {
      setSectionDropDown([]);
    } else if (sem_id) {
      GetSectionBySemester({
        variables: {
          acd_semester_ids: [sem_id],
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const filteredSection = data?.GetAcdSectionsByAcdSemesterIds?.filter(
            (id) => id !== null
          ).map((section) => ({
            label: section.section_desc,
            value: section.id,
          }));
          setSectionDropDown(filteredSection);
        }
      });
    }
  };

  useEffect(() => {
    // dept false
    if (token) {
      if (departmentId === 0) {
        if (USE_DEPARTMENT_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.TABLE_NONE,
              table_id: 0,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              // setting notallocated deptid
              if (data.GetAcdNotAllocatedIds.acd_dept_id) {
                setDeptId(data.GetAcdNotAllocatedIds.acd_dept_id);
                GetBranches(data.GetAcdNotAllocatedIds.acd_dept_id);
              }
            }
          });
        } else {
          DepartmentsByInstId().then(({ data }) => {
            if (data) {
              const filteredDepartment = data?.GetAcdDeptsByInstId?.filter(
                (id) => id !== null
              ).map((dept) => ({ label: dept.dept_desc, value: dept.id }));
              setDepartmentDropDown(filteredDepartment);
            }
          });
        }
      }

      // branch false
      if (branchId === 0 && departmentId !== 0) {
        if (USE_BRANCH_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.DEPARTMENT,
              table_id: departmentId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetAcdNotAllocatedIds.acd_branch_id) {
                setBranchId(data.GetAcdNotAllocatedIds.acd_branch_id);
                GetClasses(data.GetAcdNotAllocatedIds.acd_branch_id);
              }
            }
          });
        } else {
          GetBranches(departmentId);
        }
      } else {
        GetBranches(departmentId);
      }

      // class false
      if (classId === 0 && branchId !== 0) {
        if (USE_CLASS_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.BRANCH,
              table_id: branchId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetAcdNotAllocatedIds.acd_class_id) {
                setClassId(data.GetAcdNotAllocatedIds.acd_class_id);
                GetSemester(data.GetAcdNotAllocatedIds.acd_class_id);
              }
            }
          });
        }
        // true class
        else {
          GetClasses(branchId);
          setSemesterDropDown([]);
        }
      } else {
        GetClasses(branchId);
      }
      if (semesterId === 0 && classId !== 0) {
        // //semseter false
        if (USE_SEMESTER_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.CLASS,
              table_id: classId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetAcdNotAllocatedIds.acd_semester_id) {
                setSemesterId(data.GetAcdNotAllocatedIds.acd_semester_id);
                GetSections(data.GetAcdNotAllocatedIds.acd_semester_id);
              }
            }
          });
        }
        // true semester
        else {
          GetSemester(classId);
          setSectionDropDown([]);
        }
      } else {
        GetSemester(classId);
      }
      //section
      if (
        semesterId !== 0 &&
        departmentId !== 0 &&
        branchId !== 0 &&
        classId !== 0
      ) {
        if (USE_SECTION_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: InstId!,
              table: InstitutionDetails.SEMESTER,
              table_id: semesterId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              setSectionId(data.GetAcdNotAllocatedIds.acd_section_id);
            }
          });
        }

        // true section
        else {
          setSectionId(selectedSection!);
          GetSections(semesterId);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    InstId,
    token,
    selectedDeptId,
    selectedbranchId,
    selectedSemester,
    selectedClassId,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    DepartmentsByInstId,
    GetBranchesByDept,
    GetClassesByBranch,
    GetNotallocted,
    GetSectionBySemester,
    GetSemesterByClass,
    branchId,
    classId,
    departmentId,
    semesterId,
    selectedSection,
    sectionId,
    notAllocatedIdData,
  ]);
  return {
    departmentDropDown,
    branchDropDown,
    classDropDown,
    semesterDropDown,
    sectionDropDown,
    // we are not sendng the depatmentId because, it's already notallocated depending on instId
    // this will return not allocated ids if the flg of any master configuration is disabled, else it will return the same id which we are passing thorugh the props.
    masterIds: { branchId, classId, semesterId, sectionId },
  };
};

export default useAcdDropDownData;

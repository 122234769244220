import { gql } from "@apollo/client";

export const GetAcdSubjectGroups = gql`
  query GetAcdSubjectGroups(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: AcdSubjectGroupOrder
    $name: String!
  ) {
    GetAcdSubjectGroups(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
      where: { or: [{ groupNameContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          group_name
          subj_group_details {
            id
            subject_details {
              id
              subj_code
              subj_desc
              subj_board_code
            }
          }
        }
      }
    }
  }
`;

export const GetSubjectGroupByNodeId = gql`
  query GetSubjectGroupByNodeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on AcdSubjectGroup {
        id
        group_name
        subj_group_details {
          subject_details {
            id
            subj_code
            subj_desc
          }
        }
      }
    }
  }
`;

import * as Yup from "yup";
import { Number_Regex, OnlyAlphabets } from "../../../utils/validationRules";

export const createOwnerValidation = Yup.object({
  owner_name: Yup.string()
    .required("Firstname is required")
    .matches(OnlyAlphabets),
  owner_mobile: Yup.string()
    .matches(Number_Regex)
    .required("Mobile No is required"),
  owner_email: Yup.string().email().required("Email is required"),
});

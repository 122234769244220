import { gql } from "@apollo/client";

export const AddAcdSubjectCategoryAndDetails = gql`
  mutation AddAcdSubjectCategoryAndDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $subj_cat_name: String!
    $user_details: SubscribedUser!
    $input: [CreateAcdSubjectCategoryDetailsInput!]!
  ) {
    AddAcdSubjectCategoryAndDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      subj_cat_name: $subj_cat_name
      user_details: $user_details
      input: $input
    )
  }
`;

export const DeleteAcdSubjectCategory = gql`
  mutation DeleteAcdSubjectCategory(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdSubjectCategory(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

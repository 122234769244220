import React, { useContext, useEffect, useState } from "react";
import More from "../../../../images/More.svg";
import {
  DateRange,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../../utils/constants";
import { Button } from "../../../../stories/Button/Button";
import diaryNotCompleted from "../../../../images/DiaryNotCompleted.svg";
import Filled from "../../../../images/FilledDiaryStatus.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Diary from "../../DailyDiary/Index";
import View from "../../../../images/EyeWhite.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GetPayRollEmpDailyDiaryTodayStatus } from "../../DailyDiary/queries";
import usePayRollActiveAcademicYear from "../../../HR/hooks/usePayRollActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import Review, { PageFor } from "../../DailyDiary/Review";
const DailyDiary = () => {
  const { token } = useToken();
  const [fillDiary, setFillDiary] = useState(false);
  const navigate = useNavigate();
  const { InstId } = useParams();

  const { state } = useContext(AppContext);
  const { firstDay } = DateRange(TODAY_DATE) || {};
  const [review, setReview] = useState(false);

  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const pr_acd_yr_id = payRollActiveAcademicYear.data
    ? payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id
    : 0;
  const [GetDairyStatus, { data: DairyStatusData }] = useLazyQuery(
    GetPayRollEmpDailyDiaryTodayStatus
  );
  useEffect(() => {
    if (token && pr_acd_yr_id) {
      GetDairyStatus({
        variables: {
          token,
          date_of_diary: toIsoDate(TODAY_DATE),
          input: {
            cal_month: toIsoDate(firstDay!),
            inst_id: InstId!,
            pr_acd_yr_id,
            pr_emp_id: state.empLoginId,
          },
        },
      });
    } // eslint-disable-next-line
  }, [token, GetDairyStatus, pr_acd_yr_id]);
  const dairyStatus =
    DairyStatusData && DairyStatusData.GetPayRollEmpDailyDiaryTodayStatus;

  return (
    <>
      <div className="teacher-dashboard__daily-dairy--flex">
        <b className="teacher-dashboard__daily-dairy--title">Daily Diary</b>
        <div className="teacher-dashboard__grids--cards--flex--image">
          <button
            onClick={() => navigate(`/${InstId}/academics/dailydiary/view`)}
            className="teacher-dashboard__daily-dairy--view"
          >
            View <img src={View} alt="" />
          </button>
          <img src={More} alt="" />
        </div>
      </div>
      <div className="teacher-dashboard__daily-dairy">
        <div className="teacher-dashboard__daily-dairy--date">
          {toStandardDate(TODAY_DATE)}
        </div>
        <div className="teacher-dashboard__daily-dairy--image">
          {!dairyStatus && <img src={diaryNotCompleted} alt="" />}
          {dairyStatus && <img src={Filled} alt="" />}
        </div>
        {!dairyStatus && (
          <Button mode="fill-diary" onClick={() => setFillDiary(!fillDiary)} />
        )}
        {dairyStatus && (
          <Button mode="update" onClick={() => setReview(!review)}>
            Diary
          </Button>
        )}
      </div>
      <Modal isOpen={fillDiary} style={StudentModalStyles} ariaHideApp={false}>
        <Diary setFillDiary={setFillDiary} />
      </Modal>
      <Modal isOpen={review} style={StudentModalStyles} ariaHideApp={false}>
        <Review
          setModal={setReview}
          setFillDiary={setFillDiary}
          pageType={PageFor.SUBMIT}
        />
      </Modal>
    </>
  );
};

export default DailyDiary;

import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { LicenseTypes, Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import Home from "../../Eduate/Home/Index";
import {
  AddSwMsgTag,
  DeleteSwMsgTag,
  UpdateSwMsgTag,
} from "../../Templates/queries/mutation";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { TableHeaderProps } from "../../../Types/Tables";
import { GetSwMsgTags, GetSwMsgTagsNode } from "../../Templates/queries/list";
import { GetSwMsgTagsData, GetSwMsgTagsVars } from "./paginationTypes";
import { useNavigate } from "react-router";
import LoadingModal from "../../../pages/LoadingModal";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import DeleteModal from "../../../pages/DeleteModal";
const { EduateModules } = require("../../../json/table.json");

export interface TagsNode {
  id: number;
  tag_name: string;
  tag_details: string;
}
export interface TagsByIdData {
  node: TagsNode;
}
export interface TagsByIdVars {
  token: string;
  id: number;
}
const Tags = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [tagName, setTagName] = useState("");
  const [description, setDescription] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [tagId, setTagId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [createTags, { loading: creationLoading }] = useMutation(AddSwMsgTag, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [updateTags, { loading: updationLoading }] = useMutation(
    UpdateSwMsgTag,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteTags, { loading: deletionLoading }] = useMutation(
    DeleteSwMsgTag,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetTags, { data }] = useLazyQuery<GetSwMsgTagsData, GetSwMsgTagsVars>(
    GetSwMsgTags
  );
  const [GetTagsNode, { data: TagsByIdData, loading: TagsByIdLoading }] =
    useLazyQuery<TagsByIdData, TagsByIdVars>(GetSwMsgTagsNode);

  const createUppercaseHTMLTag = (tagName: string) => {
    // Remove any spaces from the tagName
    const cleanedTagName = tagName.replace(/\s/g, "");

    // Create the uppercase HTML tag
    const htmlTag = `<${cleanedTagName.toUpperCase()}>`;

    return htmlTag;
  };

  const handleCreateTags = (e: React.FormEvent) => {
    e.preventDefault();
    if (operation === Operation.UPDATE) {
      updateTags({
        variables: {
          token,
          id: tagId,
          input: {
            tag_name: tagName,
            tag_details: description,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetSwMsgTags,
            variables: {
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Tag Updated Successfully",
            operation: Operation.UPDATE,
          });
        }
      });
    } else {
      createTags({
        variables: {
          token,
          input: {
            tag_name: createUppercaseHTMLTag(tagName),
            tag_details: description,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetSwMsgTags,
            variables: {
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Tag Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: number) => {
    DeleteTags({
      variables: {
        token,
        id: tagId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwMsgTags,
          variables: {
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Tag Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setTagName("");
      setDescription("");
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
    setMessage({ flag: false, message: "", operation: Operation.NONE });
  };
  useEffect(() => {
    if (token) {
      GetTags({
        variables: {
          token,
        },
      });
    }
  }, [token, GetTags]);

  useEffect(() => {
    if (TagsByIdData && !TagsByIdLoading && operation === Operation.UPDATE) {
      setTagName(TagsByIdData?.node.tag_name);
      setDescription(TagsByIdData?.node.tag_details);
    }
  }, [TagsByIdData, TagsByIdLoading, operation]);

  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title> Message Tags for Templates</Title>
      <form className="create-tags" onSubmit={handleCreateTags}>
        <div className="row g-0 create-tags__block">
          <div className="col h-100 booktype-left">
            <Title variant="subtitle1">Add New Tag </Title>

            <div className="label-grid">
              <Label>Tag Name</Label>
              <Input
                onChange={(e) => setTagName(e.target.value)}
                required
                value={tagName}
              />
              <Label> Description</Label>
              <Input
                onChange={(e) => setDescription(e.target.value)}
                required
                value={description}
              />
            </div>
          </div>
          <div className="col h-100 booktype-right">
            <Title variant="subtitle1">List of Tags </Title>
            <div className="create-tags__tableblock">
              <TableContainer className="create-tags__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {EduateModules.EduateTags.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index}>{th.labelName}</TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data?.GetSwMsgTags.map((res, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            className="create-tags__table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="create-tags__table--desc">
                            {res.tag_name}
                          </TableCell>
                          <TableCell>{res.tag_details}</TableCell>
                          <TableCell
                            id="td-center"
                            className="library__table--actions"
                          >
                            <img
                              src={EditProfile}
                              alt="/"
                              onClick={() => {
                                GetTagsNode({
                                  variables: {
                                    token,
                                    id: res.id,
                                  },
                                }).then(({ data }) => {
                                  if (data) {
                                    setTagId(res.id);
                                    setOperation(Operation.UPDATE);
                                  }
                                });
                              }}
                            />
                            &nbsp;
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => {
                                setTagId(res.id);
                                setOperation(Operation.DELETE);
                                setDeleteModal(!deleteModal);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Button type="submit" mode="save" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </form>
      <LoadingModal
        flag={creationLoading || updationLoading || deletionLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={tagId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Tags;

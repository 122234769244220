import { useContext, useEffect, useState } from "react";
import { EduateModuleDetails } from "./useInstModules";
import { useLazyQuery } from "@apollo/client";
import { GetMstInstActiveModules } from "../../../queries/customerModules/query";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { EduateModule } from "../../../utils/Enum.types";
import { AppContext } from "../../../context/context";
export interface GetMstInstActiveModulesData {
  id: number;
  is_module_enabled: boolean;
  inst_module_master_id: number;
  is_module_enabled_for_mobile: boolean;
  eduate_module_details: EduateModuleDetails;
}
export interface GetMstInstActiveModulesDetails {
  GetMstInstActiveModules: GetMstInstActiveModulesData[];
}
export interface GetMstInstActiveModulesVars {
  token: string;
  inst_id: string;
}
const useActiveInstModules = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [accounts, setAccounts] = useState(false);
  const [payroll, setPayroll] = useState(false);
  const [library, setLibrary] = useState(false);
  const [academics, setAcademics] = useState(false);
  const [transport, setTransport] = useState(false);
  const [enquiry, setEnquiry] = useState(false);
  const [messageApp, setMessageApp] = useState(false);
  const [admissions, setAdmission] = useState(false);
  const [stdAccounts, setStdAccounts] = useState(false);
  const [stdAcademics, setStdAcademics] = useState(false);
  const [stdLibrary, setStdLibrary] = useState(false);
  const [stdTransport, setStdTransport] = useState(false);
  const [stdChatApp, setChatApp] = useState(false);
  const [GetActiveModules, { data, loading, error }] = useLazyQuery<
    GetMstInstActiveModulesDetails,
    GetMstInstActiveModulesVars
  >(GetMstInstActiveModules, {
    variables: {
      inst_id: InstId! || state.InstId.toString(),
      token,
    },
  });
  useEffect(() => {
    if (token && (InstId || state.InstId)) {
      GetActiveModules().then(({ data }) => {
        if (data) {
          const { GetMstInstActiveModules } = data;
          const accounts = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.ACCOUNTS
          );
          const payroll = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.PAYROLL
          );
          const lib = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.LIBRARY
          );
          const academics = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.ACADEMICS
          );
          const transport = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.TRANSPORT
          );
          const enquiry = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.ENQUIRY
          );
          const messageApp = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.MESSAGEAPP
          );
          const admission = GetMstInstActiveModules.find(
            ({ eduate_module_details: { Name } }) =>
              Name === EduateModule.ADMISSION
          );

          const stdModuleEnabledFilter = data.GetMstInstActiveModules.filter(
            (mst_module) => mst_module.is_module_enabled_for_mobile
          );

          const studentAcademics = stdModuleEnabledFilter.find(
            (mod) => mod.eduate_module_details.Name === EduateModule.ACADEMICS
          );
          const studentAccounts = stdModuleEnabledFilter.find(
            (mod) => mod.eduate_module_details.Name === EduateModule.ACCOUNTS
          );
          const studentLibrary = stdModuleEnabledFilter.find(
            (mod) => mod.eduate_module_details.Name === EduateModule.LIBRARY
          );
          const studentTransport = stdModuleEnabledFilter.find(
            (mod) => mod.eduate_module_details.Name === EduateModule.TRANSPORT
          );
          const studentChat = stdModuleEnabledFilter.find(
            (mod) => mod.eduate_module_details.Name === EduateModule.MESSAGEAPP
          );
          accounts && setAccounts(true);
          payroll && setPayroll(true);
          lib && setLibrary(true);
          academics && setAcademics(true);
          transport && setTransport(true);
          enquiry && setEnquiry(true);
          messageApp && setMessageApp(true);
          admission && setAdmission(true);

          studentAcademics && setStdAcademics(true);
          studentAccounts && setStdAccounts(true);
          studentLibrary && setStdLibrary(true);
          studentTransport && setStdTransport(true);
          studentChat && setChatApp(true);
        }
      });
    }
  }, [GetActiveModules, InstId, state.InstId, token]);

  return {
    ActiveModules: { data, loading, error },
    USE_ACCOUNTS: accounts,
    USE_PAYROLL: payroll,
    USE_LIBRARY: library,
    USE_TRANSPORT: transport,
    USE_ACADEMICS: academics,
    USE_ENQUIRY: enquiry,
    USE_MESSAGE_APP: messageApp,
    USE_ADMISSION: admissions,
    STD_USE_ACADEMICS: stdAcademics && academics,
    STD_USE_ACCOUNTS: stdAccounts && accounts,
    STD_USE_LIBRARY: stdLibrary && library,
    STD_USE_TRANSPORT: stdTransport && transport,
    STD_USE_CHAT: stdChatApp && messageApp,
  };
};

export default useActiveInstModules;

import React, { useContext, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { StudentsTitlesType } from "../../../../Types/Student/json";
import Home from "../../Home/Index";
import { Tab, Tabs } from "@mui/material";
import { StudentReportType } from "../../../../utils/Enum.types";

import SwipeableViews from "react-swipeable-views";
import StudentStatusPage from "./StudentStatus";
import { useTheme } from "@mui/material/styles";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
const { Students } = require("../../../../json/title.json");

const Index = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { dispatch } = useContext(AppContext);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    dispatch({
      payload: { studentId: 0 },
      type: payloadTypes.SET_STUDENT_ID,
    });
    if (index === 2) {
      setValue(1);
    } else {
      setValue(index);
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Students.Titles.map((title: StudentsTitlesType, index: React.Key) => {
          return (
            <React.Fragment key={index}>
              {value === 0 ? title.Not_Eligible : title.Make_Eligible}
            </React.Fragment>
          );
        })}
      </Title>
      <div className="student-status">
        <div className="student-status__tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="not Eligible" value={0} {...a11yProps(0)} />
            <Tab label="Make Eligible" value={1} {...a11yProps(1)} />
          </Tabs>
        </div>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          className="bulk-data-alter__swipable"
        >
          {/* values for Attendance */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <StudentStatusPage eligiblePagetype={StudentReportType.ELIGIBLE} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <StudentStatusPage
              eligiblePagetype={StudentReportType.NON_ELIGIBLE}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
    </>
  );
};

export default Index;

import { InstDetails } from "../../utils/Enum.types";
import useSwConfigData from "../useSwConfigData";
import { GlobalPageConfigData } from "../../Types/configtypes";

const useInstLabels = () => {
  const { configData } = useSwConfigData([
    InstDetails.DEFAULT_DEPT_LABEL,
    InstDetails.DEFAULT_BRANCH_LABEL,
    InstDetails.DEFAULT_CLASS_LABEL,
    InstDetails.DEFAULT_SEMESTER_LABEL,
    InstDetails.DEFAULT_SECTION_LABEL,
    InstDetails.DEFAULT_CATEGORY_LABEL
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let departmentLabel = "";
    let branchLabel = "";
    let classLabel = "";
    let semesterLabel = "";
    let sectionLabel = "";
    let categoryLabel=""

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstDetails.DEFAULT_DEPT_LABEL:
            departmentLabel = item.config_string_value;
            break;
          case InstDetails.DEFAULT_BRANCH_LABEL:
            branchLabel = item.config_string_value;
            break;
          case InstDetails.DEFAULT_CLASS_LABEL:
            classLabel = item.config_string_value;
            break;
          case InstDetails.DEFAULT_SEMESTER_LABEL:
            semesterLabel = item.config_string_value;
            break;
          case InstDetails.DEFAULT_SECTION_LABEL:
            sectionLabel = item.config_string_value;
            break;
            case InstDetails.DEFAULT_CATEGORY_LABEL:
              categoryLabel = item.config_string_value;
              break;

          default:
            break;
        }
      });
    }
    return {
      departmentLabel,
      branchLabel,
      classLabel,
      semesterLabel,
      sectionLabel,
      categoryLabel
    };
  };
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel
  } = filterDataByConfigKey(configData.data?.GetSwConfigVariables!);
  return {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
    configData,
  };
};

export default useInstLabels;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";

import Avatar from "../../../images/Avatar.svg";
import ListAll from "../../../images/ListAll.svg";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

import { PageFor, StudentReportType } from "../../../utils/Enum.types";
import { StudentTitles } from "../../../Types/Titles";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { Button } from "../../../stories/Button/Button";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useMasterTableJson from "../../../json/useMasterTableJson";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";

const { Students } = require("../../../json/title.json");

interface Props {
  levelId: number;
  setTreeViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: PageFor;
}
const Index = ({ levelId, setTreeViewModal, pageType }: Props) => {
  //useStates For Paginataion

  const { entry_level } = useInstitutionConfiguration();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const { Masters_Table } = useMasterTableJson();

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    entry_level === "DEPT" ? levelId : 0,
    entry_level === "BRANCH" ? levelId : 0,
    entry_level === "CLASS" ? levelId : 0,
    entry_level === "SEM" ? levelId : 0,
    entry_level === "SEC" ? levelId : 0,
    0,
    ROWS_PER_PAGE,
    0,
    EMPTY_STRING,
    StudentReportType.CURR_STUDENTS,
    EMPTY_STRING,
    []
  );

  const { data, fetchMore, loading } = AcademicsStudentData;

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcdStudents.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      <Title>
        {Students.Titles.map((title: StudentTitles, index: React.Key) => {
          return (
            <React.Fragment key={index}>
              {title.Students_List_For_All}
            </React.Fragment>
          );
        })}
      </Title>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "masters-dashboard__demand-summary"
            : "masters-dashboard__demand-summary__modal"
        }
      >
        <div className="masters-dashboard__demand-summary__tableblock--allstudents">
          <TableContainer
            className="academics-all-studentlist__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="academics-all-studentlist__table--slno">
                    <img
                      className="academics-table-logo"
                      src={ListAll}
                      alt="/"
                    />
                  </TableCell>
                  {Masters_Table.Students.Academics.CompleteStudentList.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TableCell className={th.className}>
                            {th.labelName}
                          </TableCell>
                        </React.Fragment>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((response, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="academics-all-studentlist__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="academics-all-studentlist__table--admno">
                        <img src={Avatar} alt="" />

                        {response.node.std_adm_no}
                      </TableCell>
                      <TableCell>
                        {response.node.first_name +
                          " " +
                          response.node.middle_name +
                          " " +
                          response.node.last_name}
                      </TableCell>
                      <TableCell className="academics-all-studentlist__table--regno">
                        {response.node.std_reg_no}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="academics-all-studentlist__table--date"
                      >
                        {dayjs(response.node.std_doa).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell className="academics-all-studentlist__table--dropout" />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setTreeViewModal(false)} />
      </div>
    </>
  );
};

export default Index;

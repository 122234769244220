import { gql } from "@apollo/client";

export const SemestersByClassIds = gql`
  query GetSemestersByClassIds($class_ids: [ID!]!, $token: String!) {
    GetSemestersByClassIds(token: $token, class_ids: $class_ids) {
      id
      sem_desc
      sem_idx
    }
  }
`;
export const GetSemestersForScanByClassIds = gql`
  query GetSemestersForScanByClassIds($class_ids: [ID!]!) {
    GetSemestersForScanByClassIds(class_ids: $class_ids) {
      id
      sem_desc
      sem_idx
    }
  }
`;
export const ByClassIds = gql`
  query GetSemesterNamesByClassIds($class_id: [ID!]!, $token: String!) {
    GetSemesterNamesByClassIds(token: $token, class_id: $class_id) {
      id
      sem_desc
      class_id
    }
  }
`;

export const SemesterDetailsByNode = gql`
  query SemesterDetailsByNode($token: String!, $semester_id: ID!) {
    node(id: $semester_id, token: $token) {
      ... on MstInstSemester {
        id
        inst_id
        class_id
        sem_desc
        sem_short_desc
        sem_idx
      }
    }
  }
`;

export const SemByInstId = gql`
  query SemByInstId($token: String!, $inst_id: ID!) {
    GetSemestersByInstId(token: $token, inst_id: $inst_id) {
      sem_desc
      id
    }
  }
`;

export const SemesterCountByClassId = gql`
  query GetSemesterCountByClassId($token: String!, $class_id: ID!) {
    GetSemesterCountByClassId(token: $token, class_id: $class_id)
  }
`;

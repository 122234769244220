import { gql } from "@apollo/client";

export const GetLibBookIssued = gql`
  query GetLibBookIssued(
    $first: Int
    $after: Cursor
    $sortBy: LibBookIssueOrderField
    $direction: OrderDirection!
    $token: String!
    $input: BookIssueQueryData!
  ) {
    GetLibBookIssued(
      token: $token
      first: $first
      after: $after
      input: $input
      orderBy: { direction: $direction, field: $sortBy }
    ) {
      edges {
        node {
          id
          issue_for_student_or_staff
          issue_date
          issue_return_date
          issue_fine_amt
          inst_id
          book_master {
            id
            book_access_no
            book_title
            classification {
              classification_desc
              publisher {
                publisher_name
              }
            }
          }
          librarian_staff_id
          student_id
          staff_id
          student_details {
            id
            first_name
            middle_name
            last_name
            std_adm_no
            class {
              class_desc
            }
            branch {
              branch_desc
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const StudentBookCount = gql`
  query StudentBookCount($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstStudent {
        misc_data {
          id
          lib_book_count
          student_id
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrintReceipts from "../../../../images/PrintProgressCard.svg";
import { useParams } from "react-router";

import {  Checkbox, TextField } from "@mui/material";

import Print from "../../../../images/Print.svg";
import Modal from "react-modal";
import {
  StudentListFields,
  PageFor,
  TableHeaders,
  StudentReportType,
  StudentListFor,
  PageLabel,
  PredefinedDataTypes,
  ColumnVisibilityFor,
  DocumentAcknowledgement,
} from "../../../../utils/Enum.types";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import Home from "../../Home/Index";

import {
  PrintConfigModalStyles,
  PrintModalStyles,
} from "../../../../styles/ModalStyles";

import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import { Keys } from "../../../../utils/Enum.keys";

import { optionsType, responseType } from "../../../../utils/Form.types";
import {
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import StudentTotalCount from "../../Student/Components/StudentTotalCount";
import LegendsInStudentList from "../../Student/Components/LegendsInStudentList";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import {
  
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT} from "../../../../styles/DataGridTableStyles";
import PrintDocuments from "../../../Print/Documents/Print";
import { useLazyQuery } from "@apollo/client";
import {
  GetInstDocumentVars,
  GetInstDocumentsData,
} from "../../../Print/Documents/Types";
import { GetInstDocuments } from "../../../Print/Documents/queries";
import useToken from "../../../../customhooks/useToken";
import { FetchStudentStatusClassName } from "../../Student/List";
import { TableHeaderProps } from "../../../../utils/types";
import useStudentTableJson from "../../../../json/useStudentTableJson";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import Configurations from "../../../Print/Documents/Configurations";
import Settings from "../../../../images/Settings.svg";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
 
interface Props {
  pageType: PageFor;
  studentListFor: StudentListFor;
  queryType: StudentReportType;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  label?: PageLabel;
}
export interface columnsType {
  label: string;
  value: StudentListFields;
  visible: boolean;
}

const StudentListForDocuments = ({
  pageType,
  studentListFor,
  queryType,
  setStudentModal,
  studentModal,
  label,
}: Props) => {

  const navigate = useNavigate();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { token } = useToken();
  const { InstId } = useParams();
  const [previewModal, setPreviewModal] = useState(false);
  const [configuration, setConfiguration] = useState(false);
  // eslint-disable-next-line
  const [showAllStudent, setShowAllStudent] = useState(false);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_PRINT,
  });
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );
  const [documentSelected, setDocumentSelected] = useState<responseType | null>(
    null
  );

  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [studentIdSet, setStudentIdSet] = useState<Set<number>>(new Set());
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel
  } = useInstLabels();

  const {
    PredefinedData: { dropDown: STUDENT_STATUS },
  } = usePredefinedDataByType(PredefinedDataTypes.STUDENT_STATUS, EMPTY_STRING);

  //Queries

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.ACD_STD_GENERAL,
    EMPTY_STRING,
    []
  );

  const { data, fetchMore, loading } = AcademicsStudentData;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { categoryDropDown } = useInstMasterDataByInstId();

  //Categories

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
    setStatusSelected(null);
  };
  const [GetInstDocumentData, { data: DocumentsList }] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments, {
    variables: {
      token,
      inst_id: InstId!,
      custom_doc_name: EMPTY_STRING,
    },
  });
  useEffect(() => {
    if (token && InstId!) {
      GetInstDocumentData();
    }
    // eslint-disable-next-line
  }, [token, GetInstDocumentData]);
  const DocumentDropDown = DocumentsList
    ? DocumentsList?.GetInstDocuments.map((list) => ({
        label: list.document_name,
        value: list.id,
      }))
    : [];

  useEffect(() => {
    if (data && !loading && selectedStudents) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father_name: node.std_father_name,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_doa),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_mobile: node.std_mobile,
            std_email: node.std_email,
            status: node.std_status,
            std_id: node.id,
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father_name: node.std_father_name,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_doa),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, selectedStudents]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [rows]);
  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },

    {
      field: "print",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      renderHeader: (params) => {
        return (
          <>
            <Checkbox checked={selectAll} onChange={() => handleSelectAll()} />
            Print Reports
            <img src={Print} alt="" />
          </>
        );
      },
      renderCell: (params) => {
        const studentId = params.row.std_id || "";

        return (
          <>
            {studentIdSet.has(studentId)}
            <Checkbox
              checked={selectAll || studentIdSet.has(Number(studentId))}
              onChange={(e) => hanldeStudentClick(params, e)}
            />
            <img src={PrintReceipts} alt="" />
          </>
        );
      },
    },
  ];
  const hanldeStudentClick = (
    params: GridCellParams,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (params.field === "print") {
      const selectedRow = rows.find((row) => row.id === params.id);
      const tempSet: Set<number> = new Set(studentIdSet);
      const checked = e.target.checked;
      if (selectedRow && selectedRow.std_id) {
        if (checked) {
          tempSet.add(selectedRow.std_id);
        } else {
          tempSet.delete(selectedRow.std_id);
        }
      }

      setStudentIdSet(tempSet);
      setSelectedStudents(Array.from(tempSet));
      // Update selected students
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allStudentIds = rows
        .map((student) => student.std_id)
        .filter((id) => Number.isInteger(id)) as number[];

      setStudentIdSet(new Set(allStudentIds));
      setSelectedStudents(allStudentIds);
      setSelectAll(true);
    } else {
      setStudentIdSet(new Set());
      setSelectedStudents([]);
      setSelectAll(false);
    }
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: false,
      std_mobile: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
    });

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_ACADEMICS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_ACADEMICS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Student List</Title>
      <div className="std-list-for-doc">
        <form>
          <div className="row g-0 std-list-for-doc__options">
            {/* <div className="col-1 studentlist__select-flex studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div> */}

            {USE_DEPARTMENT_KEY ? (
              <div className="col-1">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={departmentDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, departmentSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(null);
                    }
                  }}
                  openOnFocus
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setDepartmentSelected(null);
                    }
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col-1">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={branchDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, branchSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && branchSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(null);
                    }
                  }}
                  openOnFocus
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue as responseType);
                    } else {
                      setBranchSelected(null);
                    }
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col-1">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={classDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, classSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && classSelected) {
                      handleMUISelectEvent(e);
                    }

                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(null);
                    }
                  }}
                  openOnFocus
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue as responseType);
                    } else {
                      setClassSelected(null);
                    }
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={classLabel}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                      fullWidth
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col-1">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={semesterDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, semesterSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterSelected(null);
                    }
                  }}
                  openOnFocus
                  value={semesterSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterSelected(newValue as responseType);
                    } else {
                      setSemesterSelected(null);
                    }
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col-1">
                <LabeledAutocomplete
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, sectionSelected)
                  }
                  className={labelClasses.inputRoot}
                  options={sectionDropDown}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSectionSelected(null);
                    }
                  }}
                  openOnFocus
                  value={sectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionSelected(newValue as responseType);
                    } else {
                      setSectionSelected(null);
                    }
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CATEGORY_KEY ? (
              <div className="col-1">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={categoryDropDown}
                  openOnFocus
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, categorySelected)
                  }
                  value={categorySelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategorySelected(newValue as responseType);
                    } else {
                      setCategorySelected(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setCategorySelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={categoryLabel}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                      fullWidth
                    />
                  )}
                />
              </div>
            ) : null}
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={STUDENT_STATUS}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as optionsType, statusSelected)
                }
                value={statusSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStatusSelected(newValue as optionsType);
                  } else {
                    setStatusSelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setStatusSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={DocumentDropDown!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, documentSelected)
                }
                value={documentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDocumentSelected(newValue as responseType);
                  } else {
                    setDocumentSelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDocumentSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Documents List"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="col"></div>
            {pageType === PageFor.MODAL ? null : (
              <div className="col-2 flex-end">
                <>
                  <Button
                    mode="clear"
                    onClick={(e: React.FormEvent) => {
                      e.preventDefault();
                      clear();
                    }}
                  />
                </>
              </div>
            )}
          </div>

          <div className="row g-0">
            <div className="col-5 ">
              <StudentTotalCount
                totalCount={data ? data.GetAcdStudents.totalCount! : 0}
              />
            </div>
          </div>
        </form>

        <div className={`std-list-for-doc__tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            // onCellClick={(params: GridCellParams, studentId) => {
            //   handleCellClick(params);
            // }}
            onColumnHeaderClick={() => handleSelectAll()}
            disableRowSelectionOnClick
            
            hideFooter
          />
        </div>
        <div className="studentlist__buttons">
          <Button
            mode="print"
            disabled={selectedStudents.length === 0}
            onClick={() => {
              setPreviewModal(!previewModal);
            }}
          />
          <Button mode="back" onClick={() => navigate(-1)} />
          {pageType === PageFor.GENERAL && label === "FALSE" ? null : (
            <LegendsInStudentList />
          )}
        </div>

        {/* <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      /> */}
        {/* preview-modal */}
        <Modal
          ariaHideApp={false}
          style={PrintModalStyles}
          isOpen={previewModal}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <PrintDocuments
                setModal={setPreviewModal}
                selectedId={documentSelected?.value!}
                selectedStudents={selectedStudents}
                setStudentIdSet={setStudentIdSet}
                pageType={DocumentAcknowledgement.GENERAL}
              />
            </div>
            {/* <div className="modal-flex__image">
              <img src={Settings} alt=""  onClick={()=>setConfiguration(!configuration)}/>
            </div> */}
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          style={PrintConfigModalStyles}
          isOpen={configuration}
        >
          <Configurations
            setModal={setConfiguration}
            pageType={DocumentAcknowledgement.MODAL}
          />
        </Modal>
      </div>
    </>
  );
};

export default StudentListForDocuments;

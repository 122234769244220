import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../stories/Button/Button";

import { Title } from "../../../stories/Title/Title";

import { LicenseTypes, Operation } from "../../../utils/Enum.types";
import Home from "../../Eduate/Home/Index";
import { ModalType } from "../../UserRights/LoginUser/Index";
import Edit from "../../../images/EditProfile.svg";
import CreateProprietor from "./CreateProprietor";
import { useLazyQuery } from "@apollo/client";
import { GetOwners } from "../queries/List";
import useToken from "../../../customhooks/useToken";
import { GetOwnerData, GetOwnersVars } from "../utils/types";

const Index = () => {
  const navigate = useNavigate();
  const { custId } = useParams();
  const { token } = useToken();

  const [openModal, setOpenModal] = useState<ModalType>({
    operation: Operation.NONE,
    flag: false,
    id: 0,
  });
  const [GetOwner, { data, loading }] = useLazyQuery<
    GetOwnerData,
    GetOwnersVars
  >(GetOwners, { variables: { cust_id: custId!, token } });
  useEffect(() => {
    if (token && custId) {
      GetOwner();
    }
  }, [token, custId, GetOwner]);

  return (
    <>
      <Home DashBoardRequired={false} NavType={LicenseTypes.EDUATE_CUSTOMER} />
      <Title>Owner's List</Title>
      <div className="emp-login-user">
        <div className="row g-0 ">
          <div className="col-2 "></div>
          <div className="col"></div>

          <div className="col-1">
            <Button
              mode="addnew"
              className="emp-login-user__button"
              autoFocus
              onClick={() =>
                setOpenModal({
                  operation: Operation.CREATE,
                  flag: true,
                  id: 0,
                })
              }
            />
          </div>
        </div>

        <div className="emp-login-user__tableblock">
          {!loading && !data?.GetOwners.length ? (
            <b className="nodata">Sorry, no results.</b>
          ) : (
            <TableContainer className="emp-login-user__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.GetOwners.map((response, index: number) => (
                    <TableRow key={response.id}>
                      <TableCell
                        id="td-center"
                        className="emp-login-user__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{response.owner_name}</TableCell>
                      <TableCell
                        className="emp-login-user__table--mobile"
                        id="td-center"
                      >
                        {response.owner_mobile}
                      </TableCell>
                      <TableCell className="emp-login-user__table--email">
                        {response.owner_email}
                      </TableCell>
                      <TableCell
                        className="emp-login-user__table--actions"
                        id="td-center"
                      >
                        <img
                          src={Edit}
                          alt="/"
                          onClick={() =>
                            setOpenModal({
                              operation: Operation.UPDATE,
                              flag: true,
                              id: response.id,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <CreateProprietor openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};

export default Index;

import React from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import FormsReport, { ReportType } from "./Formwise/FormsReport";

import List from "./List";
import TeacherWise from "./Teacherwise/TeacherWise";
interface props {
  pageType: ReportType;
}
const Index = ({ pageType }: props) => {
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Forms Report</Title>
      <div className="fb-form-report">
        <div className="fb-form-report__left">
          <List />
        </div>
        <div className="fb-form-report__right">
          {pageType === ReportType.Formwise ? (
            <>
              <FormsReport pageType={ReportType.Formwise} />
            </>
          ) : pageType === ReportType.Subwise ? (
            <>
              <FormsReport pageType={ReportType.Subwise} />
            </>
          ) : (
            <>
              <TeacherWise />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Title } from "../../../stories/Title/Title";

import { EnquiryLabelNameProps, LabelNameProps } from "../../../Types/Labels";

import { Button } from "../../../stories/Button/Button";
import { Label } from "../../../stories/Label/Label";

import { TableHeaderProps } from "../../../Types/Tables";
import useEnquiryStudentDetails from "../../../customhooks/useEnquiryStudentDetails";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  follow_up_date,
  next_follow_up_date,
  SeekingCategory,
} from "../utils/constants";
import { Form, Formik } from "formik";
import { IObjectKeys, optionsType } from "../../../utils/Form.types";
import { followUpValidationSchema } from "../utils/validationRules";
import { useMutation } from "@apollo/client";
import {
  AddStudentEnquiryFollowUp,
  DeleteStudentEnquiryFollowUp,
  UpdateStudentEnquiryFollowUp,
} from "../../../queries/students/mutations/new";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useActiveAcademicYear from "../../Academics/hooks/useActiveAcademicYear";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import {
  Direction,
  Operation,
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
} from "../../../utils/Enum.types";
import { Keys } from "../../../utils/Enum.keys";
import Input from "../../../components/common/Input/Index";
import MessageModal from "../../../pages/MessageModal";
import {
  GetStudentEnquires,
  GetStudentEnquiresByEnquiryId,
} from "../../../queries/students/list/newApi";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import DeleteModal from "../../../pages/DeleteModal";
import TextArea from "../../../stories/TextArea/TextArea";
import useEnquiryFormJson from "../json/useEnquiryFormJson";
import useEmployee, { empQueryTypes } from "../../HR/hooks/useEmployee";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { FormAutocomplete, formClasses, RequiredAutoComplete } from "../../../styles/AutocompleteStyles";
const { Enquiry_Table } = require("../json/table.json");

interface formData extends IObjectKeys {
  follow_up_remarks: string;
  follow_up_person: string;
  next_follow_up_date: string;
  follow_up_date: string;
}
interface Props {
  setStudentDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ setStudentDetailsModal }: Props) => {
  const { EnquiryFormLabels } = useEnquiryFormJson();
  const { InstId } = useParams();
  const [searchData, setSearchData] = useState("");
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [updateId, setUpdateId] = useState(0);
  const [followUpId, setFollowUpId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [formData, setFormData] = useState<formData>({
    next_follow_up_date: "",
    follow_up_date: "",
    follow_up_remarks: "",
    follow_up_person: "",
  });
  const [follow_up_status, set_follow_up_status] = useState<optionsType | null>(
    null
  );
  const {
    empDetails: { data },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.JUST_LOGIN_ACCTS,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0
  );

  const { serverDate } = useServerDateandTime();
  const { departmentLabel, branchLabel, classLabel, semesterLabel } =
    useInstLabels();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const employeeDropDown = data
    ? data.GetPayRollEmp.edges.map(
        (res) =>
          res.node.emp_first_name +
          " " +
          res.node.emp_middle_name +
          " " +
          res.node.emp_last_name
      )
    : [];
  const { user_details } = useLoggedInUserDetails();
  const [AddEnquiryFollowUp] = useMutation(AddStudentEnquiryFollowUp, {
    onError: (e) =>
      setMessage({
        message: e.message,
        operation: Operation.NONE,
        flag: true,
      }),
  });

  const [UpdateEnquiryFollowUp] = useMutation(UpdateStudentEnquiryFollowUp, {
    onError: (e) =>
      setMessage({
        message: e.message,
        operation: Operation.NONE,
        flag: true,
      }),
  });
  const [DeleteEnquiryFollowUp] = useMutation(DeleteStudentEnquiryFollowUp, {
    onError: (e) =>
      setMessage({
        message: e.message,
        operation: Operation.NONE,
        flag: true,
      }),
  });
  const {
    enquiryStudentData: { enquiryFormData, followItems },
  } = useEnquiryStudentDetails();

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    PredefinedData: { dropDown: ENQ_STATUS_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_FOLLOW_UP_STATUS,
    EMPTY_STRING
  );

  const InstConfigForFilter = (data: LabelNameProps) => {
    switch (data.LabelName) {
      case `Seeking ${departmentLabel}`:
        return USE_DEPARTMENT_KEY;
      case `Seeking ${branchLabel}`:
        return USE_BRANCH_KEY;
      case `Seeking ${classLabel}`:
        return USE_CLASS_KEY;
      case `Seeking ${semesterLabel}`:
        return USE_SEMESTER_KEY;
      case SeekingCategory:
        return USE_CATEGORY_KEY;
      default:
        return true;
    }
  };
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteEnquiryFollowUp({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudentEnquires,
          variables: {
            name: EMPTY_STRING,
            lastFollowUpStatus: null,
            boughtApplication: null,
            admitted: null,
            status: null,
            deptID: null,
            branchID: null,
            classID: null,
            semesterID: null,
            categoryID: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            first: ROWS_PER_PAGE,
            after: null,
            today_date: toIsoDate(serverDate),
            sortBy: SortBy.FIRST_NAME,
            direction: Direction.ASC,
            token,
            enq_query_type: StdEnqQueryType.STD_ENQ_ALL,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Deleted SuccessFully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };

  const handleSubmit = () => {
    if (operation === Operation.CREATE) {
      AddEnquiryFollowUp({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
          enq_student_id: state.studentEnquiryId,
          input: {
            follow_up_remarks: removeMoreSpace(formData.follow_up_remarks),
            follow_up_person: formData.follow_up_person,
            follow_up_date: toIsoDate(serverDate),
            follow_up_status: follow_up_status?.value,
            next_follow_up_date: toIsoDate(formData.next_follow_up_date!),
          },
        },
        refetchQueries: [
          {
            query: GetStudentEnquiresByEnquiryId,
            variables: { id: state.studentEnquiryId, token },
            fetchPolicy: "network-only",
          },
          {
            query: GetStudentEnquires,
            variables: {
              name: EMPTY_STRING,
              lastFollowUpStatus: null,
              boughtApplication: null,
              admitted: null,
              deptID: null,
              branchID: null,
              classID: null,
              semesterID: null,
              categoryID: null,
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.FIRST_NAME,
              direction: Direction.ASC,
              token,
              today_date: toIsoDate(serverDate),
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              enq_query_type: StdEnqQueryType.STD_ENQ_NOT_ADMITTED,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Follow up added successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateEnquiryFollowUp({
        variables: {
          token,
          id: updateId,
          inst_id: InstId!,
          user_details,
          input: {
            follow_up_remarks: removeMoreSpace(formData.follow_up_remarks),
            follow_up_person: formData.follow_up_person,
            follow_up_date: toIsoDate(formData.follow_up_date),
            follow_up_status: follow_up_status?.value,
            next_follow_up_date: toIsoDate(formData.next_follow_up_date!),
          },
        },
        refetchQueries: [
          {
            query: GetStudentEnquiresByEnquiryId,
            variables: { id: state.studentEnquiryId, token },
            fetchPolicy: "network-only",
          },
          {
            query: GetStudentEnquires,
            variables: {
              name: EMPTY_STRING,
              lastFollowUpStatus: null,
              boughtApplication: null,
              admitted: null,
              deptID: null,
              branchID: null,
              classID: null,
              semesterID: null,
              categoryID: null,
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.FIRST_NAME,
              direction: Direction.ASC,
              token,
              today_date: toIsoDate(serverDate),
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              enq_query_type: StdEnqQueryType.STD_ENQ_NOT_ADMITTED,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Follow up updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
          setOperation(Operation.CREATE);
        }
      });
    }
  };
  const handleClear = () => {
    setFormData({
      next_follow_up_date: "",
      follow_up_date: "",
      follow_up_status: "",
      follow_up_remarks: "",
      follow_up_person: "",
    });
    set_follow_up_status(null);
    setSearchData("");
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
      setStudentDetailsModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const setFollowItemForUpdate = (follow_id: number) => {
    const follow_up_data = followItems.find(({ id }) => id === follow_id);
    setUpdateId(follow_up_data?.id ?? 0);

    if (follow_up_data) {
      setFormData({
        next_follow_up_date: toInputStandardDate(
          follow_up_data.next_follow_up_date
        ),
        follow_up_date: toInputStandardDate(follow_up_data.follow_up_date)!,
        follow_up_person: follow_up_data.follow_up_person!,
        follow_up_remarks: follow_up_data.follow_up_remarks!,
      });
      set_follow_up_status(
        ENQ_STATUS_OPTIONS.find(
          ({ value }) => value === follow_up_data.follow_up_status
        ) ?? null
      );
    }
  };
  const findEnquiry = (data: string) => {
    return (
      ENQ_STATUS_OPTIONS.find(({ value }) => value === data)?.label ??
      EMPTY_STRING
    );
  };

  return (
    <>
      <Title>Student Details</Title>
      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={followUpValidationSchema}
        onSubmit={handleSubmit}
      >
        {(meta) => {
          return (
            <Form className="std-enquiry-followup__view">
              <div className="row g-0 std-enquiry-followup__view--details">
                <div className="col std-enquiry-followup__view--details--span-4">
                  {EnquiryFormLabels.FollowUp.Details.filter(
                    (data: EnquiryLabelNameProps) => InstConfigForFilter(data)
                  ).map((label: EnquiryLabelNameProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TextField
                          className="std-enquiry-followup__details--textfield"
                          label={label.LabelName}
                          value={enquiryFormData[label.inputName]}
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          disabled
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div className="std-enquiry-followup__view--tableblock">
                <Title variant="subtitle1">Follow Up History</Title>
                <TableContainer className="std-enquiry-followup__view--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {Enquiry_Table.ViewFollowUp.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {followItems.map((data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className="std-enquiry-followup__table--slno"
                              id="td-center"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell>{data.follow_up_person}</TableCell>
                            <TableCell className="std-enquiry-followup__table--status">
                              {findEnquiry(data.follow_up_status)}
                            </TableCell>
                            <TableCell className="std-enquiry-followup__table--date">
                              {toStandardDate(data.follow_up_date)}
                            </TableCell>
                            <TableCell className="std-enquiry-followup__table--date">
                              {toStandardDate(data.next_follow_up_date)}
                            </TableCell>
                            <TableCell>{data.follow_up_remarks}</TableCell>
                            <TableCell id="td-center">
                              <img
                                src={Edit}
                                alt="/"
                                onClick={() => {
                                  setOperation(Operation.UPDATE);
                                  setFollowItemForUpdate(data.id);
                                }}
                              />
                              <img
                                src={Delete}
                                alt="/"
                                onClick={() => {
                                  setFollowUpId(data.id);
                                  setDeleteModal(!deleteModal);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="row g-0 std-enquiry-followup__view--additional">
                <div className="col std-enquiry-followup__view--additional--frame">
                  <Input
                    LabelName="Follow Up Date"
                    values={
                      formData[follow_up_date]
                        ? formData[follow_up_date]
                        : toInputStandardDate(serverDate)
                    }
                    name={follow_up_date}
                    disabled
                    type="date"
                  />
                  <Input
                    LabelName="Next Follow Up Date"
                    values={formData[next_follow_up_date]}
                    name={next_follow_up_date}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleValueChange(e);
                      meta.handleChange(e);
                    }}
                    autoFocus
                    min={toInputStandardDate(serverDate)}
                    type="date"
                  />
                  <div className="label-grid">
                    <Label>Follow Up Person</Label>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={employeeDropDown}
                      value={formData.follow_up_person}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              follow_up_person: newValue as string,
                            };
                          });
                        } else {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              follow_up_person: EMPTY_STRING,
                            };
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus
                          required
                          onChange={(e) => setSearchData(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col std-enquiry-followup__view--additional--frame">
                  <div className="label-grid">
                    <Label>Follow Up Status</Label>
                    <RequiredAutoComplete
                      className={formClasses.inputRoot}
                      options={ENQ_STATUS_OPTIONS}
                      value={follow_up_status}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as optionsType, follow_up_status)
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (follow_up_status) {
                            handleMUISelectEvent(e);
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          set_follow_up_status(null);
                        }
                      }}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          set_follow_up_status(newValue as optionsType);
                        } else {
                          set_follow_up_status(null);
                        }
                      }}
                      openOnFocus
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={follow_up_status ? false : true}
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                  <div className="label-grid">
                    <Label>Remarks</Label>
                    <TextArea
                      rows={3}
                      name="follow_up_remarks"
                      onChange={(e) => {
                        handleValueChange(e);
                        meta.handleChange(e);
                      }}
                      value={formData.follow_up_remarks}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER && e.shiftKey === false) {
                          e.preventDefault();
                          handleMUISelectEvent(e);
                        }
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <Button mode="save" type="submit" />
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="cancel"
                type="button"
                onClick={() => setStudentDetailsModal(false)}
              />
            </Form>
          );
        }}
      </Formik>
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        handleDelete={handleDelete}
        id={followUpId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
      />
    </>
  );
};

export default Index;

import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Title } from "../../../../stories/Title/Title";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import {
  MonthName,
  formatter,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import Avatar from "../../../../images/Avatar.svg";
import Close from "../../../../images/Close.svg";

import { GetPayRollSalaryMkEmpMasterEdges } from "../../Types/paginationTypes";
import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  Direction,
  Operation,
  PageFor,
  PrMkSalQueryType,
  SalaryDetailsModalType,
  SortBy,
} from "../../../../utils/Enum.types";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import {
  getEmpRule,
  LedgersListType,
  PayRollEmpSalaryStructDetails,
  udvMonthlyType,
} from "../SalaryStructure/AssignSalaryStructure";
import { PayRollSalaryMkWork } from "./SelectMonth";
import { idWithModalType } from "./EmpAttendance";
import { UpdatePayRollSalaryMkEmpMaster } from "../../queries/salary/mutation";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import {
  EarnOrDeduction,
  EarnedSalaryMethod,
  PayRollConfigKeys,
  SalaryLedgerType,
  applyHeadsOn,
  cellEditType,
} from "../../enums/Enum.types";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import {
  calculateDeduction,
  calculateEarning,
  calculateMonthlyBasicGrossGroupLedgers,
  checkForFlags,
  checkForRoundOff,
  convertGroupIdsToString,
  getGroupIds,
  getSalCalDaysMethod,
} from "../../utils/Functions";
import SalaryLedgerList from "../SalaryLedeger/SalaryLedgerList";
import { PickSubjectModalStyles } from "../../../../styles/ModalStyles";
import {
  GetPayRollSalaryMkEmpMaster,
  GetPayRollSalaryProcessCount,
} from "../../queries/salary/list";
import { useParams } from "react-router-dom";
import { PayRollAcademicYearList } from "../../hooks/usePayRollAcademicYears";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { fixed_ledger_details } from "./ConfirmEmployeeSalary";
import { HYPHEN } from "../../constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HR_Table } = require("../../json/table.json");

interface monthDaysType {
  total_no_of_workdays: number;
  no_of_days: number;
  no_of_weekend_days: number;
  no_of_general: number;
}

export interface ledgers_type extends LedgersListType, fixed_ledger_details {}
interface Props {
  employeeDetails: GetPayRollSalaryMkEmpMasterEdges;
  monthlyData: PayRollSalaryMkWork;
  setSalaryDetailsModal: React.Dispatch<React.SetStateAction<idWithModalType>>;
  salaryDetailsModalType: SalaryDetailsModalType;
  monthDays: monthDaysType;
  pr_acd_yr?: PayRollAcademicYearList;
}
const SalaryStructDetailsModal = ({
  employeeDetails,
  monthlyData,
  setSalaryDetailsModal,
  salaryDetailsModalType,
  monthDays,
  pr_acd_yr,
}: Props) => {
  const { token } = useToken();
  const { InstId, monthId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const ESI = "ESI";
  const [udvMonthly, setUdvMonthly] = useState<udvMonthlyType>();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [ledgers, setLedgers] = useState<ledgers_type[]>([]);

  const [addLedgers, setAddLedgers] = useState(false);
  const { configData: salRoundOffDecimal } = useSwConfigData(
    PayRollConfigKeys.ROUND_TO_DECIMAL
  );
  const salRoundOffKey =
    salRoundOffDecimal.data && !salRoundOffDecimal.loading
      ? salRoundOffDecimal.data?.GetSwConfigVariables[0].config_boolean_value
      : false;

  const { configData: salNoOfDaysConfig } = useSwConfigData(
    PayRollConfigKeys.EARNED_SAL_CAL
  );
  const salNoOfDaysType = salNoOfDaysConfig.data
    ? salNoOfDaysConfig.data?.GetSwConfigVariables[0].config_string_value
    : EarnedSalaryMethod.MONTH_DAYS;

  const { no_of_days, no_of_weekend_days } = monthDays;
  const { format } = formatter;
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const [ConfirmSalary] = useMutation(UpdatePayRollSalaryMkEmpMaster, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });

  const fixedTotal = ledgers.reduce(
    (acc, item) => {
      if (item.ledger_type === SalaryLedgerType.DEDUCTION)
        acc.totalDeductions += checkForRoundOff(
          item.fixed_amount,
          salRoundOffKey
        );
      if (item.ledger_type === SalaryLedgerType.EARNING)
        acc.totalEarning += checkForRoundOff(item.fixed_amount, salRoundOffKey);
      return acc;
    },
    {
      totalEarning: 0,
      totalDeductions: 0,
    }
  );
  const earnedTotal = ledgers.reduce(
    (acc, item) => {
      acc.totalDeductions += checkForRoundOff(item.deductions, salRoundOffKey);
      acc.totalEarning += checkForRoundOff(item.earnings, salRoundOffKey);
      return acc;
    },
    {
      totalEarning: 0,
      totalDeductions: 0,
    }
  );

  const setUdv = (index: number) => {
    ledgers[index].udv =
      index === udvMonthly?.index
        ? Number(udvMonthly.value)
        : ledgers[index].udv;

    ledgers[index][
      ledgers[index].ledger_type === SalaryLedgerType.EARNING
        ? EarnOrDeduction.earnings
        : EarnOrDeduction.deductions
    ] =
      index === udvMonthly?.index
        ? Number(udvMonthly.value)
        : ledgers[index][
            ledgers[index].ledger_type === SalaryLedgerType.EARNING
              ? EarnOrDeduction.earnings
              : EarnOrDeduction.deductions
          ];
    setUdvMonthly({
      index: 0,
      value: "",
    });
  };
  const calculateEarn = (
    monthlyBasic: number,
    salRoundOffKey: boolean,
    ledger: PayRollEmpSalaryStructDetails,
    remaining_balance_ledger: number
  ) => {
    if (ledger.emp_sal_remaining_amt_rule) {
      const days = getSalCalDaysMethod(
        salNoOfDaysType as EarnedSalaryMethod,
        no_of_days!,
        no_of_weekend_days!
      )!;
      const perDayEarningLedger = remaining_balance_ledger / days;

      return checkForFlags(
        ledger.sal_ldgr_details,
        salRoundOffKey,
        perDayEarningLedger * (days - employeeDetails.node.emp_sal_mk_lwp)
      );
    } else {
      return calculateEarning(monthlyBasic, salRoundOffKey, ledger);
    }
  };

  const setLedgersTable = () => {
    setLedgers(
      employeeDetails?.node.confirmed_salary_details.map((ledger) => {
        const numOfDaysBasedOnCOnfiguration = getSalCalDaysMethod(
          salNoOfDaysType as EarnedSalaryMethod,
          employeeDetails.node.emp_sal_mk_earned_days!,
          no_of_weekend_days!
        );
        const fixed_ledger_details =
          employeeDetails.node.fixed_salary_details.salary_str_details.find(
            (data) => data.sal_ldgr_details.id === ledger.sal_ldgr_details.id
          );

        const { monthlyBasic, monthlyGross, monthlyGroupSalary } =
          calculateMonthlyBasicGrossGroupLedgers(
            employeeDetails?.node.emp_sal_mk_basic,
            employeeDetails?.node.emp_sal_mk_fixed_gross,
            employeeDetails.node.emp_sal_mk_earned_days -
              employeeDetails.node.emp_sal_mk_lwp,
            employeeDetails.node.confirmed_salary_details,
            numOfDaysBasedOnCOnfiguration!,
            salRoundOffKey,
            employeeDetails.node.emp_sal_mk_remaining_ldgr_bal,
            new Set(getGroupIds(ledger.emp_sal_group_id_heads))
          );

        return {
          id: ledger.sal_ldgr_details.id,
          ledger_type: ledger.emp_sal_earn_ded as SalaryLedgerType,
          earn_type: ledger.emp_sal_earn_type,
          ledger_desc: ledger.sal_ldgr_details.sal_ldgr_desc,
          ledger_applied_to: ledger.emp_sal_ded_on_heads as applyHeadsOn,
          ledger_rule: getEmpRule(ledger),
          ledger_udvMonthly:
            ledger.emp_sal_earn_ded === SalaryLedgerType.EARNING
              ? ledger.emp_sal_earn_is_UDM
              : ledger.emp_sal_ded_is_UDM,
          ledger_applied_on_heads: ledger.emp_sal_ded_is_applied_on_heads,
          sal_ldgr_remove_decimal:
            ledger.sal_ldgr_details.sal_ldgr_remove_decimal,
          sal_ldgr_round_to_next_int:
            ledger.sal_ldgr_details.sal_ldgr_round_to_next_int,
          earnings:
            ledger.emp_sal_earn_ded === SalaryLedgerType.EARNING
              ? employeeDetails?.node.emp_sal_mk_lwp > 0
                ? calculateEarn(
                    monthlyBasic,
                    salRoundOffKey,
                    ledger,
                    employeeDetails.node.emp_sal_mk_remaining_ldgr_bal
                  )
                : ledger.emp_sal_ldgr_value
              : 0,
          remaining_balance: ledger.emp_sal_remaining_amt_rule,
          deductions:
            ledger.emp_sal_earn_ded === SalaryLedgerType.DEDUCTION
              ? employeeDetails?.node.emp_sal_mk_lwp > 0
                ? ledger.emp_sal_ded_is_UDM
                  ? ledger.emp_sal_ded_UDM_value
                  : checkForFlags(
                      ledger.sal_ldgr_details,
                      salRoundOffKey,
                      calculateDeduction(
                        getGroupIds(ledger.emp_sal_group_id_heads),
                        ledger.emp_sal_ded_rule,
                        monthlyBasic,
                        monthlyGross,
                        monthlyGroupSalary,
                        salRoundOffKey,
                        ledger.sal_ldgr_details.sal_ldgr_type === ESI &&
                          employeeDetails?.node.emp_sal_mk_fixed_gross > 21000
                      )
                    )
                : ledger.emp_sal_ldgr_value
              : 0,

          earnedValueFixedBelowBasic: ledger.emp_sal_earn_to_min_basic,
          earnedValueRestrictedTo: ledger.emp_sal_earn_restrict_to,
          callToMaxValue: ledger.emp_sal_ded_to_max_value,
          flat_rate: ledger.emp_sal_earn_flat_rate,
          udv:
            ledger.emp_sal_earn_ded === SalaryLedgerType.EARNING
              ? ledger.emp_sal_earn_UDM_val
              : ledger.emp_sal_ded_UDM_value,
          emp_sal_str_sl: ledger.emp_sal_sl,
          emp_sal_str_ldgr_sl: ledger.sal_ldgr_details.sal_ldgr_idx,
          sal_ldgr_is_basic: ledger.sal_ldgr_details.sal_ldgr_is_basic,
          from_sal_process: true,
          groupLedgers: getGroupIds(ledger.emp_sal_group_id_heads),
          fixed_amount: fixed_ledger_details
            ? fixed_ledger_details.emp_sal_ldgr_value!
            : 0,
          fixed_gross: employeeDetails.node.fixed_salary_details
            .emp_sal_str_total_earnings
            ? employeeDetails.node.fixed_salary_details
                .emp_sal_str_total_earnings
            : 0,
        };
      })
    );
  };
  const approveSalary = () => {
    const numOfDaysBasedOnCOnfiguration = getSalCalDaysMethod(
      salNoOfDaysType as EarnedSalaryMethod,
      employeeDetails.node.emp_sal_mk_earned_days!,
      no_of_weekend_days!
    );
    const { monthlyBasic } = calculateMonthlyBasicGrossGroupLedgers(
      employeeDetails?.node.emp_sal_mk_basic,
      employeeDetails?.node.emp_sal_mk_other_earn,
      employeeDetails.node.emp_sal_mk_earned_days -
        employeeDetails.node.emp_sal_mk_lwp,
      employeeDetails.node.confirmed_salary_details,
      numOfDaysBasedOnCOnfiguration!,
      salRoundOffKey,
      employeeDetails.node.emp_sal_mk_remaining_ldgr_bal
    );

    const singleEmployee = [
      {
        id: employeeDetails?.node.id,
        pr_emp_id: employeeDetails.node.emp_details.id,
        emp_sal_mk_earned_days: employeeDetails?.node.emp_sal_mk_earned_days,
        emp_sal_mk_basic: employeeDetails?.node.emp_sal_mk_basic,
        emp_sal_mk_earned_basic: monthlyBasic,
        emp_sal_mk_other_earn: earnedTotal.totalEarning,
        emp_sal_mk_gross: earnedTotal.totalEarning,
        emp_sal_mk_others_ded: earnedTotal.totalDeductions,
        emp_sal_mk_net: earnedTotal.totalEarning - earnedTotal.totalDeductions,
        sal_mk_emp_details: ledgers.map((ledger, index) => {
          return {
            emp_sal_sl: index,
            emp_sal_ldgr_value:
              ledger.ledger_type === SalaryLedgerType.EARNING
                ? ledger.earnings
                : ledger.deductions,
            emp_sal_ldgr_sl: ledger.emp_sal_str_ldgr_sl,
            emp_sal_earn_is_UDM: ledger.earn_type === cellEditType.UDM,
            emp_sal_group_id_heads: ledger.groupLedgers
              ? convertGroupIdsToString(ledger.groupLedgers!)
              : "1;",
            emp_sal_earn_ded: ledger.ledger_type,
            emp_sal_earn_type: ledger.earn_type,
            emp_sal_earn_flat_rate: ledger.flat_rate,
            emp_sal_earn_UDM_val:
              ledger.ledger_type === SalaryLedgerType.EARNING ? ledger.udv : 0,
            emp_sal_earn_restrict_to: ledger.earnedValueRestrictedTo,
            emp_sal_earn_to_min_basic: ledger.earnedValueFixedBelowBasic,
            emp_sal_earn_rule_apply: ledger.ledger_rule.length > 0,
            emp_sal_earn_rule:
              ledger.ledger_type === SalaryLedgerType.EARNING
                ? ledger.ledger_rule
                : EMPTY_STRING,
            emp_sal_ded_is_UDM:
              ledger.ledger_type === SalaryLedgerType.DEDUCTION
                ? ledger.ledger_udvMonthly
                : false,
            emp_sal_ded_UDM_value:
              ledger.ledger_type === SalaryLedgerType.DEDUCTION
                ? ledger.udv
                : 0,
            emp_sal_ded_is_applied_on_heads:
              ledger.ledger_type === SalaryLedgerType.DEDUCTION
                ? ledger.ledger_applied_on_heads
                : false,
            emp_sal_ded_on_heads:
              ledger.ledger_type === SalaryLedgerType.DEDUCTION
                ? ledger.ledger_applied_to
                : EMPTY_STRING,
            emp_sal_ded_to_max_value: ledger.callToMaxValue,
            emp_sal_ded_rule:
              ledger.ledger_type === SalaryLedgerType.DEDUCTION
                ? ledger.ledger_rule
                : EMPTY_STRING,
            emp_sal_cur_month_amt:
              earnedTotal.totalEarning + employeeDetails?.node.emp_sal_mk_basic,
            emp_sal_remaining_amt_rule: ledger.remaining_balance,
            emp_sal_cur_month_earned_amt:
              earnedTotal.totalEarning +
              employeeDetails?.node.emp_sal_mk_basic -
              earnedTotal.totalDeductions,
            pr_sal_ldgr_id: ledger.id,
          };
        }),
      },
    ];

    ConfirmSalary({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: singleEmployee,
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkEmpMaster,
          variables: {
            token,
            inst_id: InstId!,
            pr_acd_yr_id: pr_acd_yr?.id,
            input: {
              pr_mk_sal_query: PrMkSalQueryType.FOR_MK_SAL_PROCESS,
              ids: [Number(monthId)],
            },
            first: ROWS_PER_PAGE,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.EMP_SAL_MK_MONTH,
            department: null,
            designation: null,
            jobtype: null,
            grade: null,
            category: null,
            name: EMPTY_STRING,
          },
        },
        {
          query: GetPayRollSalaryProcessCount,
          variables: {
            inst_id: InstId!,
            pr_acd_yr_id: pr_acd_yr?.id!,
            token,
            pr_sal_mk_month_id: monthId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setSalaryDetailsModal({
          flag: false,
          id: 0,
        });
        setMessage({
          operation: Operation.UPDATE,
          message: "Salary Approved Successfully",
          flag: true,
        });
      }
    });
  };
  const hanldeClose = () =>
    setMessage({
      message: "",
      operation: Operation.NONE,
      flag: false,
    });

  useEffect(() => {
    setLedgersTable();
    // eslint-disable-next-line
  }, [employeeDetails, salRoundOffKey]);

  return (
    <div className="modal-flex h-100">
      <div className="modal-flex__data h-100">
        <Title>Employee Salary Details</Title>
        <div className="salary-process__confirm-salary--emp-details">
          <div className="row g-0 salary-process__emp-attendance--leave-ledger--details">
            <div className="col">
              <TextField
                label="Emp ID"
                disabled
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  employeeDetails
                    ? employeeDetails.node.emp_details.emp_id
                    : EMPTY_STRING
                }
                className="salary-process__emp-attendance--leave-ledger--details--textfield"
              />
              {USE_HR_DESIGNATION ? (
                <TextField
                  label="Designation"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={
                    employeeDetails
                      ? employeeDetails.node.emp_details.pr_designation_details
                          .designation_desc
                      : EMPTY_STRING
                  }
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                />
              ) : null}
              {USE_HR_JOBTYPE ? (
                <TextField
                  label="Job Type"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={
                    employeeDetails
                      ? employeeDetails.node.emp_details.pr_job_type_details
                          .job_type_desc
                      : EMPTY_STRING
                  }
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                />
              ) : null}
            </div>
            <div className="col">
              <TextField
                label="Name"
                disabled
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  employeeDetails
                    ? `${employeeDetails.node.emp_details.emp_first_name} ${employeeDetails.node.emp_details.emp_middle_name} ${employeeDetails.node.emp_details.emp_last_name}`
                    : EMPTY_STRING
                }
                className="salary-process__emp-attendance--leave-ledger--details--textfield"
              />
              {USE_HR_DEPARTMENT ? (
                <TextField
                  label="Department"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={
                    employeeDetails
                      ? employeeDetails.node.emp_details.pr_dept_details
                          .dept_desc
                      : EMPTY_STRING
                  }
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                />
              ) : null}
              <TextField
                label="Qualification"
                disabled
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  employeeDetails
                    ? employeeDetails.node.emp_details.emp_qualification
                    : EMPTY_STRING
                }
                className="salary-process__emp-attendance--leave-ledger--details--textfield"
              />
            </div>
            <div className="col">
              {USE_HR_CATEGORY ? (
                <TextField
                  label="Category"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={
                    employeeDetails
                      ? employeeDetails.node.emp_details.pr_category_details
                          .category_desc
                      : EMPTY_STRING
                  }
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                />
              ) : null}
              <TextField
                label="Date of Joining"
                disabled
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  employeeDetails
                    ? toStandardDate(employeeDetails.node.emp_details.emp_doj)
                    : EMPTY_STRING
                }
                className="salary-process__emp-attendance--leave-ledger--details--textfield"
              />
              <TextField
                label="Year of Experience"
                disabled
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  employeeDetails
                    ? employeeDetails.node.emp_details.emp_experience
                    : EMPTY_STRING
                }
                className="salary-process__emp-attendance--leave-ledger--details--textfield"
              />
            </div>
            <div className="col-1 salary-process__emp-attendance--leave-ledger--details--image">
              <img src={Avatar} alt="/" />
            </div>
          </div>
          {salaryDetailsModalType ===
            SalaryDetailsModalType.CONFIRM_EMP_SALARY && employeeDetails ? (
            <div className="row salary-process__emp-attendance--leave-ledger--dates">
              <div className="col">
                <TextField
                  label="Salary Process Month"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={
                    monthlyData
                      ? MonthName(monthlyData.sal_wrk_for_month!).toString()
                      : EMPTY_STRING
                  }
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                />
              </div>
              <div className="col">
                <TextField
                  label="Month Days"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={no_of_days ?? EMPTY_STRING}
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                />
              </div>
              <div className="col">
                <TextField
                  label="Lwp Days"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={employeeDetails.node.emp_sal_mk_lwp ?? 0}
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                />
              </div>
              <div className="col">
                <TextField
                  className="salary-process__emp-attendance--leave-ledger--details--textfield"
                  label="Worked Days"
                  disabled
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={
                    employeeDetails.node.emp_sal_mk_earned_days! -
                    employeeDetails.node.emp_sal_mk_lwp!
                  }
                />
              </div>
              <div className="col-1"></div>
            </div>
          ) : null}
          <div className="row g-0 salary-process__confirm-salary--emp-details--tableblock">
            <div className="col h-100">
              <Title variant="subtitle1">Fixed Salary</Title>
              <TableContainer className="salary-process__confirm-salary--emp-details--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {HR_Table.SalaryProcess.ConfirmEmpSalary.SalaryDetails.filter(
                        (th: TableHeaderProps) =>
                          th.labelName !== "Applied to" &&
                          th.labelName !== "Rule" &&
                          th.labelName !== "Sl"
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ledgers.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="assign-salary-structure__table--desc">
                            {data.ledger_desc}
                          </TableCell>
                          <TableCell className="assign-salary-structure__table--amount">
                            {data.ledger_type === SalaryLedgerType.EARNING
                              ? data.fixed_amount
                                ? format(data.fixed_amount)
                                : HYPHEN
                              : EMPTY_STRING}
                          </TableCell>
                          <TableCell
                            className="assign-salary-structure__table--amount"
                            id="td-center"
                          >
                            {data.ledger_type === SalaryLedgerType.DEDUCTION
                              ? data.fixed_amount
                                ? format(data.fixed_amount)
                                : HYPHEN
                              : EMPTY_STRING}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={1} className="total">
                        Total :
                      </TableCell>
                      <TableCell
                        className="salary-process__emp-attendance--leave-ledger--table--slno totalcountcell"
                        id="td-right"
                      >
                        {format(fixedTotal.totalEarning)}
                      </TableCell>
                      <TableCell
                        className="salary-process__emp-attendance--leave-ledger--table--slno balance-count"
                        id="td-right"
                      >
                        {format(fixedTotal.totalDeductions)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} className="total">
                        Net :
                      </TableCell>
                      <TableCell className="totalcount">
                        {format(
                          fixedTotal.totalEarning - fixedTotal.totalDeductions
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>

            <div className="col h-100">
              <Title variant="subtitle1">Earning Salary</Title>
              <TableContainer className="salary-process__confirm-salary--emp-details--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {HR_Table.SalaryProcess.ConfirmEmpSalary.SalaryDetails.filter(
                        (th: TableHeaderProps) =>
                          th.labelName !== "Applied to" &&
                          th.labelName !== "Rule" &&
                          th.labelName !== "Sl"
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ledgers.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="assign-salary-structure__table--desc">
                            {data.ledger_desc}
                          </TableCell>
                          <TableCell className="assign-salary-structure__table--amount">
                            {data.ledger_udvMonthly &&
                            data.ledger_type === SalaryLedgerType.EARNING &&
                            salaryDetailsModalType ===
                              SalaryDetailsModalType.CONFIRM_EMP_SALARY ? (
                              <Input
                                size="small"
                                value={
                                  index === udvMonthly?.index && !data.udv
                                    ? udvMonthly?.value
                                    : data.udv
                                }
                                onFocus={(e) => {
                                  e.target.select();
                                  setUdvMonthly({
                                    index,
                                    value: e.target.value,
                                  });
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setUdvMonthly({
                                    index: index,
                                    value: e.target.value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    setUdv(index);
                                  }
                                }}
                              />
                            ) : data.ledger_type ===
                              SalaryLedgerType.EARNING ? (
                              data.earnings ? (
                                format(data.earnings)
                              ) : (
                                HYPHEN
                              )
                            ) : (
                              EMPTY_STRING
                            )}
                          </TableCell>
                          <TableCell
                            className="assign-salary-structure__table--amount"
                            id="td-center"
                          >
                            {data.ledger_udvMonthly &&
                            data.ledger_type === SalaryLedgerType.DEDUCTION &&
                            salaryDetailsModalType ===
                              SalaryDetailsModalType.CONFIRM_EMP_SALARY ? (
                              <Input
                                size="small"
                                value={
                                  index === udvMonthly?.index
                                    ? udvMonthly?.value
                                    : data.udv
                                }
                                onFocus={(e) => {
                                  e.target.select();
                                  setUdvMonthly({
                                    index,
                                    value: e.target.value,
                                  });
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setUdvMonthly({
                                    index: index,
                                    value: e.target.value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    setUdv(index);
                                  }
                                }}
                              />
                            ) : data.ledger_type ===
                              SalaryLedgerType.DEDUCTION ? (
                              data.deductions ? (
                                format(data.deductions)
                              ) : (
                                HYPHEN
                              )
                            ) : (
                              EMPTY_STRING
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell className="total">Total :</TableCell>
                      <TableCell
                        className="salary-process__emp-attendance--leave-ledger--table--slno totalcountcell"
                        id="td-right"
                      >
                        {format(earnedTotal.totalEarning)}
                      </TableCell>
                      <TableCell
                        className="salary-process__emp-attendance--leave-ledger--table--slno balance-count"
                        id="td-right"
                      >
                        {format(earnedTotal.totalDeductions)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} className="total">
                        Net :
                      </TableCell>
                      <TableCell className="totalcount">
                        {format(
                          earnedTotal.totalEarning - earnedTotal.totalDeductions
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Button mode="approve" type="submit" onClick={approveSalary}>
          Approve Salary
        </Button>
        <Button
          type="submit"
          onClick={() => setAddLedgers(!addLedgers)}
          mode="addnew"
        >
          &nbsp;Ledgers
        </Button>

        <Button mode="clear" />
        <Button
          mode="cancel"
          onClick={() =>
            setSalaryDetailsModal({
              id: 0,
              flag: false,
            })
          }
        />
      </div>
      <div className="modal-flex__image">
        <img
          src={Close}
          alt="/"
          onClick={() =>
            setSalaryDetailsModal({
              id: 0,
              flag: false,
            })
          }
        />
      </div>

      <MessageModal
        modalFlag={message.flag}
        handleClose={hanldeClose}
        operation={message.operation}
        value={message.message}
      />
      <Modal
        isOpen={addLedgers}
        style={PickSubjectModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <SalaryLedgerList
              pageType={PageFor.MODAL}
              setFormDataForUpdate={() => {}}
              setSelectedLedgers={setLedgers}
              idSet={
                new Map(
                  ledgers.map(({ id, from_sal_process }) => [
                    id,
                    from_sal_process!,
                  ])
                )
              }
              setModal={setAddLedgers}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddLedgers(!addLedgers)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SalaryStructDetailsModal;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { Button } from "../../../../../stories/Button/Button";
import Input from "../../../../../stories/Input/Input";
import { Label } from "../../../../../stories/Label/Label";
import { Title } from "../../../../../stories/Title/Title";
import {
  DebitOrCredit,
  PageNumbers,
  ReceiptTypes,
  PageFor,
  VoucherBookKey,
  StudentListFor,
  StudentAcctReportType,
  BankOrCash,
  YesNo,
  Direction,
  SortBy,
  Operation,
} from "../../../../../utils/Enum.types";

import { studentRecepit } from "../../../../../Types/Accounting/index";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  DateRange,
  formatter,
  GetNoOfDaysDelayed,
  NameOfTheDay,
  toggleFullSceen,
  toInputStandardDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import { AddAcctVoucherMasterAndDetails } from "../../../queries/receipts/mutation/Index";
import dayjs from "dayjs";

import { AccountsTableHeaderProps } from "../../../../../Types/Tables";
import ConfigurationSettings from "../../../../Master/configurations/general/Index";

import useDisplayConfigIcon from "../../../../../customhooks/useDisplayConfigIcon";
import { AppContext } from "../../../../../context/context";
import Home from "../../../Home/Index";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
  StudentModalStyles,
  PrintModalStyles,
} from "../../../../../styles/ModalStyles";
import StudentList from "../../../common/StudentList";
import FeeLedger from "../../../FeeLedger/Index";
import LoadingModal from "../../../../../pages/LoadingModal";
import Settings from "../../../../../images/Settings.svg";
import Enlarge from "../../../../../images/Enlarge.svg";
import Edit from "../../../../../images/Edit.svg";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";

import Avatar from "../../../../../images/Avatar.svg";

import { msgType, responseType } from "../../../../../utils/Form.types";
import { payloadTypes } from "../../../../../context/reducer";
import useAcctLedgerData from "../../../hooks/useAcctLedgerData";
import useToken from "../../../../../customhooks/useToken";
import { Keys } from "../../../../../utils/Enum.keys";
import Close from "../../../../../images/Close.svg";
import useStudentDemandDetails, {
  studentDemandDetailsData,
} from "../../../hooks/useStudentDemandDetails";
import MessageModal from "../../../../../pages/MessageModal";
import { GetAcctVouchers } from "../../../queries/Vouchers/query/index";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import useVoucherNumber from "../../../hooks/useVoucherNumber";
import useFineSlab from "../../../hooks/useFineSlab";
import useAcctStdAdmissionNumber from "../../../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import {
  AcctLedgerQueryType,
  DemandDetailsQueryType,
  DepositLedgerQueryType,
  LedgerType,
} from "../../../common/QueryTypes";
import { GetAcctStdDeposits } from "../../../queries/FeeLedgers/query/Byid";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../../queries/students/list/byId";
import Print from "../../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import useBillbooks from "../../../hooks/useBillbooks";

import { Data } from "../../../../Print/Accounts/FeeReceipts/Standard/Index";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import TextArea from "../../../../../stories/TextArea/TextArea";
import WaiveOfModal from "../../../CustomComponents/Modal/WaiveOffModal";
import useAcctTableJson from "../../../json/useAcctTableJson";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../../../Types/Accounting/mutations";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../../styles/AutocompleteListStyles";
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollectCaptationFee = ({ pageType, setModalFlag }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  let DefaultDate = new Date();
  const { InstId } = useParams<{ InstId: string }>();
  const { dispatch, state } = useContext(AppContext);

  const navigate = useNavigate();
  const { InstDetails } = useInstDetails(1);

  const dates = DateRange(DefaultDate.toString());

  const [admNo, setAdmNo] = useState("");
  const [totalFineAmt, setTotalFineAmt] = useState(0);

  //use states for modal screens
  const [studentModal, setStudentModal] = useState(false);
  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [admissionNumberDisabled, setAdmissionNumberDiabled] = useState(false);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [configurationModal, setConfigurationModal] = useState(false);
  //use states for input tags
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [UpdatedReceviedAmount, setUpdatedReceviedAmount] = useState(0);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");

  const [printModal, SetPrintModal] = useState(false);
  const [transaction_no, set_transaction_no] = useState("");

  const [waiveOffAmt, setWaiveOffAmt] = useState(0);
  const [enableWaiveOff, setEnableWaiveOff] = useState(false);

  //use states for table items
  const [items, setItems] = useState<studentRecepit[]>([]);
  const [narration, setNarration] = useState("");

  const [imageString, setImageString] = useState("");

  const receivedAmountRef = useRef<HTMLInputElement>(null);

  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const { user_details } = useLoggedInUserDetails();

  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { studentData, studentFormData } = useStudentDatabyId();

  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.FEE_RECEIPT
  );
  const { token } = useToken();

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.STUDENT_RECEIPT_PAGE
  );

  const { finesData } = useFineSlab();

  const { serverDate } = useServerDateandTime();
  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.HIDDEN_CASH_ACCOUNT,
    ROWS_PER_PAGE
  );

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
    true,
    EMPTY_STRING
  );

  const { voucherBooks } = useBillbooks();
  const { GetStdVoucherBooks } = voucherBooks.data || {};
  const vKey = GetStdVoucherBooks?.find((book) => book.hide_this_book)
    ?.vo_book_key as VoucherBookKey;

  const { voucherNumber } = useVoucherNumber(vKey);

  //mutations
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const totals = items.reduce(
    (acc, item) => {
      acc.totalOutStandingBalance += item.fee_ob;
      acc.totalDemandAmount += item.fee_demand;
      acc.totalconcession += item.fee_concession;
      acc.totalbalance += item.fee_bal;
      acc.totalPayable += item.fee_receivable;
      acc.totalpaidAmount += item.fee_received;
      acc.totalacademicAmount += item.rcpt_amt!;

      return acc;
    },
    {
      totalOutStandingBalance: 0,
      totalDemandAmount: 0,
      totalconcession: 0,
      totalbalance: 0,
      totalPayable: 0,
      totalpaidAmount: 0,
      totalacademicAmount: 0,
    }
  );

  const handleStudentReceipts = () => {
    if (CashLedgers.responseType[0].value) {
      GenerateStudentReceipts({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: [
            {
              acct_voucher_master: {
                fin_yr: state.ActiveFinYr
                  ? state.ActiveFinYr.fin_yr
                  : EMPTY_STRING,
                v_type: ReceiptTypes.STUDENT_RECEIPT,
                v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
                v_date: dayjs(serverDate).format(),
                v_total_cr_amt: totals.totalacademicAmount + totalFineAmt,
                v_total_db_amt: totals.totalacademicAmount + totalFineAmt,
                v_reconciled: false,
                v_reconciled_date: dayjs(DEFAULT_TIME).format(),
                v_std_receipt: true,
                v_std_non_demand_receipt: false,
                v_std_refund: false,
                v_std_receipt_ob: false,
                v_std_anonymous_deposit_adjusted: false,
                v_std_refund_deposit: false,
                v_std_receipt_anonymous: false,
                v_std_enquiry: false,
                enquiry_student_id: 0,
                v_std_deposit_adjusted: false,
                v_book_type: vKey,
                v_std_deposit: false,
                v_std_passout_receipt: false,
                v_std_demand_receipt: true,
                v_std_scholarship_deposit: false,

                student_id: state.studentId,
                class_id: studentData.data?.nodes[0].class.id,
                semester_id: studentData.data?.nodes[0].semester.id,
                v_std_amt_receipt: totals.totalacademicAmount,
                v_std_amt_deposit: 0,
                v_std_amt_fine: totalFineAmt,
                v_std_amt_total: totals.totalacademicAmount + totalFineAmt,
                v_std_amt_refunded: 0,
                v_std_amt_adjusted: 0,
                v_transcation_type: "Cash Book",
                v_transcation_cash_or_bank: BankOrCash.CASH,
                v_transcation_no: "",
                v_transcation_date: dayjs(DEFAULT_TIME).format(),
                v_transcation_narration: narration,
                // paid_party_id: "",
                // party_bill_no: "",
                party_bill_date: dayjs(DEFAULT_TIME).format(),
                // party_name: "",
                annx_yesno: false,
                // annx_id: Math.random() * 1000,
                is_vouch_multi_entry: false,
                acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
                  .acct_ldgr_details.id,
                acct_ldgr_id_db: CashLedgers.responseType[0].value,
              },
              acct_voucher_db: CashLedgers.responseType.map(
                (receipt, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db: totals.totalacademicAmount + totalFineAmt,
                  vo_cr_total: 0,
                  vo_db_total: totals.totalacademicAmount + totalFineAmt,
                  acct_ldgr_id: receipt.value,
                })
              ),
              acct_voucher_cr: items
                .filter((item) => item.rcpt_amt > 0)
                .map((item, index) => ({
                  vo_sl_no: index + 1,
                  vo_cr_db: DebitOrCredit.CREDIT,
                  vo_cr: item.rcpt_amt,
                  vo_db: 0,
                  vo_cr_total: totals.totalacademicAmount + totalFineAmt,
                  vo_db_total: 0,
                  acct_ldgr_id: item.acct_ldgr_id,
                })),
            },
          ],
        },
        refetchQueries: [
          {
            query: GetAcctVouchers,
            variables: {
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              sortBy: SortBy.V_DATE,
              token,
              name: EMPTY_STRING,
              deposit: null,
              vTranscationCashOrBank: null,
              partyName: EMPTY_STRING,
              vTranscationNo: EMPTY_STRING,
              vTranscationNarration: EMPTY_STRING,
              ldgrDesc: EMPTY_STRING,
              amount: null,
              input: {
                inst_id: InstId!,
                voucher_query_type: VoucherQueryTypes.STUDENT_ALL_RECEIPTS,
                vo_end_date: dayjs(dates?.lastDay).format(),
                acct_ldgr_id: state.accountLedgerId,
                vo_start_date: dayjs(dates?.firstDay).format(),
                vo_type: EMPTY_STRING,
              },
            },
          },
          {
            query: GetAcctStdDeposits,
            variables: {
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
              student_id: state.studentId,
              token,
            },
          },
          {
            query: GetStdCompleteFeeDetailsByStudentID,
            variables: {
              token,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              inst_id: InstId!,
              student_id: state.studentId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          const { v_no, v_date } = data.AddAcctVoucherMasterAndDetails[0];
          setTableData(
            items
              .filter((item) => item.rcpt_amt > 0)
              .map((item) => ({
                particular: item.acct_ldgr_details.ldgr_desc,
                amount: item.rcpt_amt,
              }))
          );
          set_v_Date(v_date);
          set_vo_no(v_no);
          set_transaction_no(transaction_no);

          !printModal ? SetPrintModal(!printModal) : handleClear();
          setMessage({
            message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const updateItem = (
    dataArray: studentRecepit[],
    id: number,
    updatedAmount: number
  ) => {
    if (
      updatedAmount > dataArray.find((item) => item.id === id)?.fee_receivable!
    ) {
      alert("Please check you're receipt amount");
      return dataArray;
    }
    return dataArray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          rcpt_amt: updatedAmount,
          fee_bal: item.fee_receivable - updatedAmount - item.fee_received,
        };
      }
      return item;
    });
  };
  const HandleEditItem = (sl_number: number) => {
    const updatedData = updateItem(items, sl_number, UpdatedReceviedAmount);
    if (updatedData) {
      setItems(updatedData);
    } else alert("Invalid ledger type");
  };

  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUpdatedReceviedAmount(updatedRcptAmount);
    setAdmissionNumberDiabled(true);
  };

  const handleSplittingAmount = () => {
    let initalAmount = receivedAmount;
    const rcvd_amt: studentRecepit[] = items.reduce(
      (result: studentRecepit[], element: studentRecepit) => {
        const rcpt_amt = Math.min(
          initalAmount,
          element.fee_receivable - element.fee_received
        );
        initalAmount -= rcpt_amt;
        result.push({
          ...element,
          rcpt_amt,
          fee_bal: element.fee_receivable - element.fee_received - rcpt_amt,
        });
        return result;
      },
      []
    );

    setItems(rcvd_amt);
    if (items[0].rcpt_amt > 0) {
      onEdit(items[0].id!, items[0].rcpt_amt);
    }
  };

  const handleClear = () => {
    setItems([]);
    setAdmissionNumberDiabled(false);
    setReceivedAmount(0);
    setUpdatedReceviedAmount(0);
    setAdmNo("");
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setTotalFineAmt(0);
    setNarration("");
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      setAdmNo("");
      setReceivedAmount(0);
      setUpdatedReceviedAmount(0);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const getFine = (noOfDaysDelayed: number) => {
    const { GetAcctFineSlabByInstId } = finesData.data || {};
    if (!GetAcctFineSlabByInstId) return 0;

    const fineSlabs = [
      {
        from: GetAcctFineSlabByInstId.acct_slab1_from,
        to: GetAcctFineSlabByInstId.acct_slab1_to,
        fine: GetAcctFineSlabByInstId.acct_slab1_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab2_from,
        to: GetAcctFineSlabByInstId.acct_slab2_to,
        fine: GetAcctFineSlabByInstId.acct_slab2_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab3_from,
        to: GetAcctFineSlabByInstId.acct_slab3_to,
        fine: GetAcctFineSlabByInstId.acct_slab3_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab4_from,
        to: GetAcctFineSlabByInstId.acct_slab4_to,
        fine: GetAcctFineSlabByInstId.acct_slab4_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab5_from,
        to: Infinity,
        fine: GetAcctFineSlabByInstId.acct_slab5_fine,
      },
    ];

    for (let i = 0; i < fineSlabs.length; i++) {
      if (
        noOfDaysDelayed >= fineSlabs[i].from &&
        noOfDaysDelayed < fineSlabs[i].to
      ) {
        return fineSlabs[i].fine;
      }
    }
    return 0;
  };

  const mapStudentDemandDetails = (
    data: studentDemandDetailsData
  ): { studentFeeData: studentRecepit[] } => {
    const studentFeeData: studentRecepit[] =
      data.GetAcctStdDemandDetails.filter((item) => item !== null).map(
        (item) => ({
          fee_ob: item.fee_ob,
          fee_demand: item.fee_demand,
          fee_concession: item.fee_concession,
          fee_receivable: item.fee_receivable,
          fee_received: item.fee_received,
          fee_refunds: item.fee_refunds,
          fee_bal: item.fee_bal,
          v_no: "",
          fee_due_date: item.fee_due_date,
          acct_ldgr_id: item.acct_ldgr_id,
          id: item.id,
          cr_db: DebitOrCredit.CREDIT,
          recevied_amount: 0,
          newItem: YesNo.NO,
          legederType: LedgerType.GENERAL,
          rcpt_amt: 0,
          acct_ldgr_details: item?.acct_ldgr_details,
          initialBalance: item.fee_bal,
          student_id: item.student_id,
          // fine_amt:
          //   toStandardDate(
          //     studentData.data?.nodes[0].acct_std_demand?.new_due_date!
          //   ) !== DEFAULT_TIME &&
          //   new Date(items[items.length - 1]?.fee_due_date!).getTime() <
          //     new Date(serverDate).getTime()!
          //     ? getFine(GetNoOfDaysDelayed(new Date(item.fee_due_date!)))! *
          //       GetNoOfDaysDelayed(new Date(item.fee_due_date!))
          //     : 0,
          fine_amt:
            new Date(item?.fee_due_date!).getTime() <
            new Date(serverDate).getTime()!
              ? getFine(GetNoOfDaysDelayed(new Date(item.fee_due_date!)))! *
                GetNoOfDaysDelayed(new Date(item.fee_due_date!))
              : 0,
        })
      );

    return { studentFeeData };
  };

  // memos
  const studentDemandDetailsmemoizedData = useMemo(() => {
    if (StudentDemandDetails.data && !StudentDemandDetails.loading) {
      const { studentFeeData } = mapStudentDemandDetails(
        StudentDemandDetails.data
      );
      return studentFeeData;
    }
    return items;
    // eslint-disable-next-line
  }, [
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
    studentData.data,
  ]);
  const waiveOffAmtMemoized = useMemo(() => {
    if (items.length > 0) {
      let res = 0;
      // eslint-disable-next-line
      items.map((item) => {
        res += item.fine_amt;
      });
      return res;
    }
    return 0;
  }, [items]);
  const handleCloseWaiveOffModal = () => {
    setWaiveOffAmt(0);
    setEnableWaiveOff(!enableWaiveOff);
  };
  useEffect(() => {
    setItems(studentDemandDetailsmemoizedData);

    if (studentData.data) {
      if (
        studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
        InstDetails.data
      ) {
        const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
        // eslint-disable-next-line
        const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
      }
    }
  }, [
    studentDemandDetailsmemoizedData,
    studentData.data,
    InstDetails.data,
    state.studentId,
    voucherNumber.data,
  ]);

  useEffect(() => {
    if (items.length > 0 && state.studentId) {
      setTotalFineAmt(waiveOffAmtMemoized);
      setWaiveOffAmt(waiveOffAmtMemoized);
    }
  }, [waiveOffAmtMemoized, items, state.studentId]);
  const { branchLabel, classLabel ,categoryLabel} = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}

      <div className="row g-0">
        <div className="col">
          <Title>Management Student Fee Receipt</Title>
        </div>

        <div className="configuration-settings">
          {pageType === PageFor.GENERAL ? (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          ) : (
            <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
          )}
        </div>
      </div>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "student-fee-receipt"
            : "student-fee-receipt--modal"
        }
      >
        <div className="student-fee-receipt__details">
          <div className="row g-0 student-fee-receipt__details--select-options">
            <div className="col student-fee-receipt__frame">
              <div className="student-fee-receipt__frame--imagefield">
                {pageType === PageFor.GENERAL ? (
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={studentAddmissionNumber}
                    value={
                      studentAddmissionNumber?.find(
                        ({ label }) => label === studentFormData.adm_no
                      )! ?? EMPTY_RESPONSETYPE_OBJECT
                    }
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (state.studentId) {
                          receivedAmountRef.current?.select();
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                        setAdmNo("");
                      }
                    }}
                    disabled={admissionNumberDisabled}
                    openOnFocus
                    onChange={(e, newValue) => {
                      if (newValue) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: (newValue as responseType)?.value,
                          },
                        });
                      } else {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                        handleClear();
                      }
                    }}
                    freeSolo
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => setAdmNo(e.target.value)}
                        label="Admission Number"
                        autoFocus
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    label=" Admission Number"
                    className="student-fee-receipt__frame--textfield"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    value={
                      studentData.data?.nodes[0].std_adm_no ?? EMPTY_STRING
                    }
                    disabled
                  />
                )}
                {pageType === PageFor.GENERAL ? (
                  <img
                    className="data-fetch-icon"
                    src={Edit}
                    alt="/"
                    onClick={() => {
                      if (!state.studentId) {
                        setStudentModal(!studentModal);
                      }
                    }}
                  />
                ) : null}
              </div>

              <TextField
                label="Reg No."
                className="student-fee-receipt__frame--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData.reg_number}
                disabled
              />
              <TextField
                label={branchLabel}
                className="student-fee-receipt__frame--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData.branch}
                disabled
              />
            </div>

            <div className="col student-fee-receipt__frame g-0">
              <TextField
                label="Name"
                className="student-fee-receipt__frame--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData.std_name}
                disabled
              />
              <TextField
                label="Father Name"
                className="student-fee-receipt__frame--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData.father_name}
                disabled
              />

              <TextField
                label={classLabel}
                className="student-fee-receipt__frame--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData.class}
                disabled
              />
            </div>
            <div className="col student-fee-receipt__frame g-0">
              <TextField
                type="date"
                label="Date"
                className="student-fee-receipt__frame--textfield--date"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={toInputStandardDate(serverDate)}
                disabled
              />
              <Label variant="present-day">{NameOfTheDay(serverDate)}</Label>

              {USE_CATEGORY_KEY && (
                <TextField
                   label={categoryLabel}
                  className="student-fee-receipt__frame--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={studentFormData.category}
                  disabled
                />
              )}
              <div className="student-fee-receipt__label-gridtrio">
                <Input
                  id="receivedamount"
                  placeholder="Amount"
                  type="number"
                  disabled={admissionNumberDisabled}
                  value={receivedAmount}
                  inputRef={receivedAmountRef}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setReceivedAmount(Number(e.target.value));
                    setUpdatedReceviedAmount(Number(e.target.value));
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === Keys.ENTER && items.length) {
                      handleSplittingAmount();
                    }
                  }}
                />
                <Button
                  mode="received"
                  disabled={admissionNumberDisabled}
                  onClick={() => {
                    items.length && handleSplittingAmount();
                  }}
                />
              </div>
            </div>
            <div className="col-1 student-fee-receipt__frame--image h-100">
              {imageString === EMPTY_STRING ? (
                <img src={Avatar} alt="/" />
              ) : (
                <img src={imageString} alt="/" />
              )}
            </div>
          </div>

          <div
            className={
              pageType === PageFor.GENERAL
                ? "row g-0 student-fee-receipt__tableblock"
                : "row g-0 student-fee-receipt__tableblock--modal"
            }
          >
            <TableContainer className="student-fee-receipt__table g-0">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.Receipts.StudentFeeReceipt.Table_Headers.map(
                      (th: AccountsTableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          className={
                            item.legederType === LedgerType.DEPOSIT
                              ? "deposit"
                              : ""
                          }
                        >
                          <TableCell
                            className="student-fee-receipt__table--slNo"
                            align="center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="student-fee-receipt__table--desc">
                            {item.acct_ldgr_details?.ldgr_desc}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="student-fee-receipt__table--amount"
                          >
                            {toStandardDate(item.fee_due_date!)}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="student-fee-receipt__table--amount"
                          >
                            {format(item.fee_ob)}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="student-fee-receipt__table--amount"
                          >
                            {format(item.fee_demand)}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="student-fee-receipt__table--amount"
                          >
                            {format(item.fee_concession)}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="student-fee-receipt__table--amount"
                          >
                            {format(item.fee_receivable)}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="student-fee-receipt__table--amount"
                          >
                            {format(item.fee_received)}
                          </TableCell>
                          <TableCell
                            className="student-fee-receipt__table--amount editCell"
                            onClick={() => {
                              if (receivedAmount > 0) {
                                if (item.rcpt_amt > 0) {
                                  onEdit(item.id!, item.rcpt_amt);
                                }
                                if (item.rcpt_amt === 0) {
                                  onEdit(item.id!, 0);
                                }
                              }
                            }}
                            id="td-right"
                          >
                            {inEditMode.status &&
                            inEditMode.rowKey === item.id ? (
                              <input
                                autoFocus
                                type="number"
                                value={UpdatedReceviedAmount}
                                onFocus={(e) => e.target.select()}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  setUpdatedReceviedAmount(
                                    Number(e.target.value)!
                                  )
                                }
                                onKeyDown={(event: React.KeyboardEvent) => {
                                  if (event.key === Keys.ENTER) {
                                    if (
                                      items[index + 1] &&
                                      items[index + 1].id
                                    ) {
                                      setUpdatedReceviedAmount(
                                        items[index + 1].rcpt_amt
                                      );
                                      onEdit(
                                        items[index + 1].id!,
                                        items[index + 1].rcpt_amt
                                      );
                                      HandleEditItem(item.id!);
                                    } else {
                                      onEdit(item.id!, item.rcpt_amt);
                                      HandleEditItem(item.id!);

                                      setInEditMode({
                                        rowKey: -805,
                                        status: false,
                                      });
                                      remarksRef.current?.focus();
                                    }
                                  }
                                }}
                              />
                            ) : (
                              format(item.rcpt_amt)
                            )}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="balance-amount student-fee-receipt__table--amount"
                          >
                            {format(item.fee_bal)}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={8} className="total">
                      Academic Fee Total :
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="totalcount student-fee-receipt__table__borderbottom"
                    >
                      {format(totals.totalacademicAmount)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={8} className="total">
                      Fine :
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="balance-count student-fee-receipt__table__borderbottom"
                    >
                      {format(totalFineAmt)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} className="total">
                      Total :
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalOutStandingBalance)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalDemandAmount)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalconcession)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalPayable)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalpaidAmount)}
                    </TableCell>

                    <TableCell id="td-right" className="totalcount">
                      {format(totals.totalacademicAmount + totalFineAmt)}
                    </TableCell>
                    <TableCell id="td-right" className="balance-count">
                      {format(totals.totalbalance)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <form
            className={
              pageType === PageFor.GENERAL
                ? "row g-0 student-fee-receipt__receiptblock"
                : "row g-0 student-fee-receipt__receiptblock--modal"
            }
          >
            <div className="col student-fee-receipt__receiptblock--frame">
              <div className="label-grid">
                <Label>Receipt No.</Label>
                <Input
                  disabled
                  value={voucherNumber.data?.GetVoucherNumber.vo_number}
                />
                <Label> Cash Ledgers</Label>
                <Input
                  disabled
                  value={
                    (CashLedgers?.responseType?.length &&
                      CashLedgers?.responseType[0]?.label) ||
                    EMPTY_STRING
                  }
                />
              </div>
            </div>

            <div className="col student-fee-receipt__receiptblock--frame label-grid">
              <Label>Remarks :</Label>
              <TextArea
                textAreaRef={remarksRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    saveButtonRef.current?.focus();
                  }
                }}
                onChange={(e) => setNarration(e.target.value)}
                rows={3}
              />
            </div>
          </form>
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row student-fee-receipt__buttons"
            : "row student-fee-receipt__buttons--modal"
        }
      >
        <div className="col">
          <Button
            mode="save"
            buttonref={saveButtonRef}
            disabled={
              totals.totalacademicAmount + totalFineAmt > 0 ? false : true
            }
            onClick={handleStudentReceipts}
          />

          <Button
            mode="waive-off"
            onClick={() => setEnableWaiveOff(!enableWaiveOff)}
            disabled={
              !state.studentId ||
              totalFineAmt === 0 ||
              totalFineAmt < waiveOffAmtMemoized
            }
          />

          <Button
            mode="clear"
            onClick={handleClear}
            disabled={!state.studentId}
          />

          {pageType === PageFor.GENERAL ? (
            <Button mode="back" onClick={() => navigate(-1)} />
          ) : (
            <Button
              mode="cancel"
              type="button"
              onClick={() => setModalFlag(false)}
            />
          )}
        </div>
      </div>

      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.FEE_RECEIPT}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>

      {/* fee-ledger */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setFeeledgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
            />
          </div>
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.STUDENT_RECEIPT_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>

      {/* waiveoff-modal */}

      <WaiveOfModal
        due_date={
          toStandardDate(
            studentData.data?.nodes[0].acct_std_demand.new_due_date!
          ) === toStandardDate(DEFAULT_TIME)
            ? "-"
            : toStandardDate(
                studentData.data?.nodes[0].acct_std_demand.new_due_date!
              )
        }
        enableWaiveOff={enableWaiveOff}
        handleCloseModal={handleCloseWaiveOffModal}
        items={items}
        setTotalFineAmt={setTotalFineAmt}
        waiveOffAmtMemoized={waiveOffAmtMemoized}
        setWaiveOffAmt={setWaiveOffAmt}
        totalFineAmt={totalFineAmt}
        waiveOffAmt={waiveOffAmt}
      />

      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={
                totalFineAmt - waiveOffAmt > 0
                  ? [
                      ...tableData,
                      {
                        amount: totalFineAmt - waiveOffAmt,
                        particular: "Fee Fine",
                      },
                    ]
                  : [...tableData]
              }
              v_date={v_date}
              v_no={v_no}
              transaction_no={transaction_no}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setWaiveOffAmt(0);
                SetPrintModal(!printModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CollectCaptationFee;

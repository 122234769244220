import React, { useEffect, useRef, useState } from "react";

import More from "../../../../images/More.svg";
import Close from "../../../../images/Close.svg";
import { Title } from "../../../../stories/Title/Title";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";

import {
  DateRange,
  getAllDaysInMonth,
  getMonthDateAndDay,
} from "../../../../utils/UtilFunctions";
import { Tab, Tabs } from "@mui/material";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../../Academics/hooks/useAcdGeneralHolidays";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { monthDaysType } from "../../../Academics/DailyActivities/Attendance/MonthlyOverview";
import {
  HolidayType,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
} from "../../../../utils/Enum.types";

import { msgType } from "../../../../utils/Form.types";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { useTheme } from "@mui/material/styles";
import CalendarWithListOfHolidays from "../../../Academics/calendar/HolidayEntry/Index";
import CustomizedCalendar from "../../../Academics/calendar/HolidayEntry/CustomizedCalendar";
import {
  AcdYrType,
  ModalType,
} from "../../../Academics/calendar/HolidayEntry/Index";
import { TODAY_DATE } from "../../../../utils/constants";
import Attendance from "./Attendance";
import useSwConfigData from "../../../../customhooks/useSwConfigData";

const Calendar = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  const [modal, setModal] = useState(false);
  const [date, setDate] = useState(new Date());

  const { serverDate } = useServerDateandTime();
  const { firstDay, lastDay } = DateRange(date.toString()) || {};
  const { activeAcademicYearData } = useActiveAcademicYear();
  var generalHolidaysMap = useRef(new Map());
  const [monthDays, setMonthDays] = useState<monthDaysType[]>([]);
  const { month, year } = getMonthDateAndDay(date.toString());
  // eslint-disable-next-line
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const dates = getAllDaysInMonth(month, year);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    holiday_query_type.ALL_HOLIDAYS
  );

  const chooseTypeAndColor = (day: number) => {
    if (generalHolidaysMap.current.has(day)) {
      const holiday_details = generalHolidaysMap.current.get(day)!;

      // switch (holiday_details.holiday_type) {
      //   case HolidayType.GENERAL || HolidayType.UNEXPECTED:
      //     return {
      //       type: holiday_details.holiday_type,
      //       description: holiday_details.holiday_description,
      //       class_name: "border-left-for-holidays",
      //     };
      //   case HolidayType.WEEKEND:
      //     return {
      //       type: holiday_details.holiday_type,
      //       description: holiday_details.holiday_description,
      //       class_name: "weekend-dates",
      //     };
      //   default:
      //     break;
      // }
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
      };
    }
  };
  const emptyDaysByWeek = (week: string) => {
    let numberOfEmpty = -1;
    let numberOfEmptyToLast = -1;
    switch (week) {
      case "Sun":
        numberOfEmpty = 6;
        numberOfEmptyToLast = 0;
        break;
      case "Mon":
        numberOfEmpty = 0;
        numberOfEmptyToLast = 6;
        break;
      case "Tue":
        numberOfEmpty = 1;
        numberOfEmptyToLast = 5;

        break;
      case "Wed":
        numberOfEmpty = 2;
        numberOfEmptyToLast = 4;
        break;
      case "Thu":
        numberOfEmpty = 3;
        numberOfEmptyToLast = 3;
        break;
      case "Fri":
        numberOfEmpty = 4;
        numberOfEmptyToLast = 2;
        break;
      case "Sat":
        numberOfEmpty = 5;
        numberOfEmptyToLast = 1;
        break;
      default:
        break;
    }
    return { numberOfEmpty, numberOfEmptyToLast };
  };
  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          class_name: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          class_name: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };
  useEffect(() => {
    if (serverDate) {
      setDate(new Date(serverDate));
    }
  }, [serverDate]);
  useEffect(() => {
    if (InstGeneralHolidays.data && !InstGeneralHolidays.loading) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      setMonthDays(
        dates.map(({ day, day_num, date }) => {
          const holiday_details = chooseTypeAndColor(day_num);
          return {
            day: day_num,
            status: holiday_details?.type!,
            description: holiday_details?.description!,
            class_name: holiday_details?.class_name!,
            week: day,
            date: new Date(date),
            holiday_types: [],
            events_length: 0,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [InstGeneralHolidays.data, InstGeneralHolidays.loading, date]);

  const [acdYr, setAcdYr] = useState<AcdYrType | null>(null);
  const [addModal, setAddModal] = useState<ModalType>({
    date: new Date(TODAY_DATE),
    flag: false,
    operation: Operation.NONE,
  });
  useEffect(() => {
    if (activeAcademicYearData.data && !activeAcademicYearData.loading) {
      setAcdYr({
        end_date: new Date(
          activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_end_date
        ),
        label: activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_yr,
        start_date: new Date(
          activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_st_date
        ),
        value: 0,
      });
    }
  }, [activeAcademicYearData.data, activeAcademicYearData.loading]);
  return (
    <>
      <div className="student-dashboard__frame--flex">
        <Title variant="subtitle1">Calendar</Title>

        <img src={More} alt="/" />
      </div>
      <div className="student-dashboard__frame--tabs">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Attendance" value={0} {...a11yProps(0)} />

          <Tab label="Calendar" value={1} {...a11yProps(1)} />
        </Tabs>
      </div>
      <div
        className="student-dashboard__frame--tab-panel"
        // onClick={() => {
        //   setModal(!modal);
        // }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Attendance pageType={PageFor.GENERAL}/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <CustomizedCalendar
            acdYr={acdYr!}
            date={addModal.date}
            setAddmodal={setAddModal}
          />
        </TabPanel>
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={modal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CalendarWithListOfHolidays
              setModalFlag={setModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setModal(!modal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Calendar;

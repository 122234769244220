import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import * as XLSX from "xlsx";

// import Home from "../../Home/Index";
// import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../../images/TotalMale.svg";
import ReadyToImport from "../../../images/Present.svg";
import ContainsError from "../../../images/Absent.svg";
import DownArrow from "../../../images/DownArrow.svg";
import FileAttach from "../../../images/BrowseFiles.svg";

import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Close from "../../../images/Close.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { ValidateStudentDataForImport } from "../../../../queries/xls";

// import { ImportStudentDataFromXls } from "../../../../queries/xls/mutation";
import Validate from "../../../images/Generate.svg";

import {
  getHeaderRowStyle,
  handleClear,
  removeMoreSpace,
  toStandardDate,
} from "../../../utils/UtilFunctions";

import Modal from "react-modal";

import {
  ACC_HEADER_FONT,
  BLOB_TYPE,
  DOWNLOAD,
  ELEMENT,
  FIRST_INDEX,
  TABLE_HEADER_CSS,
  FILENAME,
  EDUATE_IMG_FORMAT,
  FIRST_CELL,
  HEADER_CSS,
  HEADER_ALIGNMENT,
  A2_CELL,
  A3_CELL,
  ADDRESS_FONT,
  ADDRESS_ALIGNMENT,
  C4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  HEADER_ALIGNMENT_CENTER,
  E4_CELL,
  FIN_YEAR_FONT,
  A4_CELL,
  HEADER_ALIGNMENT_LEFT,
  BORDER_DATA,
  FROZEN_CELLS,
} from "../../Library/Constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  Operation,
} from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { PayRollMasters } from "../../HR/enums/Enum.types";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import { getMasterDetails } from "../../HR/Imports/utils/GetMasterDetails";
import Eduate from "../../../images/Eduate_Logo_image.png";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import usePayRollMastersConfig from "../../HR/hooks/usePayRollMastersConfig";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import { EMPTY_STRING } from "../../../utils/constants";
import { LoadingStyles } from "../../../styles/ModalStyles";
import ExcelLoading from "../../../pages/ExcelLoading";
import { AccountentType } from "../../Accounts/common/Enum.types";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useMstInstTree from "../../../customhooks/useMstInstTree";
import {
  GetInstMasterDataDetails,
  GetInstMasterDataVars,
} from "../../../customhooks/useInstMasterDataByInstId";
import { GetInstMasterData } from "../../../queries/institution/masterdata";
import { ImportAcdTeacherClassAssoci } from "../queries/teacher_association/mutations";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";

interface dr {
  Class: string;
  Department: string;
  Branch: string;
  Semester: string;
  Section: string;
}
const TeachersImport = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { state } = useContext(AppContext);


  const [expanded, setExpanded] = useState(false);
  const [records, setRecords] = useState<any[]>([]);

  const [importModal, setImportModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });

  const { InstDetails } = useInstDetails(1);

  const [AddEmployees, { loading }] = useMutation(ImportAcdTeacherClassAssoci);

  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { serverDate } = useServerDateandTime();
  const [schema, setSchema] = useState<any>();

  const InstName = InstDetails.data?.nodes[0]?.inst_name;
  const {
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const { data: treeData } = useMstInstTree();
  const [GetMasterDetails, { data }] = useLazyQuery<
    GetInstMasterDataDetails,
    GetInstMasterDataVars
  >(GetInstMasterData, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  useEffect(() => {
    if (token) {
      GetMasterDetails();
    }
  }, [token, GetMasterDetails]);
  const { sheetname } =
    getMasterDetails(PayRollMasters.JOB_TYPE, records) || {};
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const classDetails = data?.GetInstMasterData.class_details || [];
  const deptDetails = data?.GetInstMasterData.dept_details || [];
  const branchDetails = data?.GetInstMasterData.branch_details || [];
  const sectionDetails = data?.GetInstMasterData.section_details || [];
  const semesterDetails = data?.GetInstMasterData.semester_details || [];
  const maxLength = Math.max(
    classDetails.length,
    deptDetails.length,
    branchDetails.length,
    sectionDetails.length,
    semesterDetails.length
  );
  let arrayTreeMapedData: dr[] = [];
  for (let index = 0; index < maxLength; index++) {
    const classe = classDetails[index]?.class_desc;
    const dept = deptDetails[index]?.dept_desc;
    const branche = branchDetails[index]?.branch_desc;
    const sectione = sectionDetails[index]?.section_desc;
    const semestere = semesterDetails[index]?.sem_desc;

    arrayTreeMapedData.push({
      Class: classe || "",
      Department: dept || "",
      Branch: branche || "",
      Semester: semestere || "",
      Section: sectione || "",
    });
  }
  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;

  const {
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const employeeData = [
    {
      Headers: "Department",
      width: 50,
      required: USE_DEPARTMENT_KEY,
      type: String,
      key: "Department",
      use_in_sheet: USE_DEPARTMENT_KEY,
    },
    {
      Headers: "Branch",
      width: 50,
      required: USE_BRANCH_KEY,
      type: String,
      key: "Branch",
      use_in_sheet: USE_BRANCH_KEY,
    },
    {
      Headers: "Class",
      width: 50,
      required: USE_CLASS_KEY,
      type: String,
      key: "Class",
      use_in_sheet: USE_CLASS_KEY,
    },
    {
      Headers: "Semester",
      width: 50,
      required: USE_SEMESTER_KEY,
      type: String,
      key: "Semester",
      use_in_sheet: USE_SEMESTER_KEY,
    },
    {
      Headers: "Section",
      width: 50,
      required: USE_SECTION_KEY,
      type: String,
      key: "Section",
      use_in_sheet: USE_SECTION_KEY,
    },

    {
      Headers: "SubjectName",
      key: "SubjectName",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "TeacherName",
      key: "TeacherName",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "IsClassTeacher",
      key: "IsClassTeacher",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
  ];
  const employee_schema = {
    Department: {
      name: "Department",
      required: USE_DEPARTMENT_KEY,
    },
    Branch: {
      name: "Branch",
      required: USE_BRANCH_KEY,
    },

    Class: {
      name: "Class",
      required: USE_CLASS_KEY,
    },
    Semester: {
      name: "Semester",
      required: USE_SEMESTER_KEY,
    },
    Section: { name: "Section", required: USE_SECTION_KEY },

    SubjectName: {
      name: "SubjectName",
      required: true,
    },
    TeacherName: {
      name: "TeacherName",
      required: false,
    },
    IsClassTeacher: {
      name: "IsClassTeacher",
      required: false,
    },
  };

  const instConfig = [
    {
      Headers: "Department",
      bool: USE_DEPARTMENT_KEY,
      key: "Department",
      width: 25,
    },
    {
      Headers: "Branch",
      bool: USE_BRANCH_KEY,
      key: "Branch",
      width: 25,
    },

    {
      Headers: "Class",
      bool: USE_CLASS_KEY,
      key: "Class",
      width: 25,
    },
    {
      Headers: "Semester",
      bool: USE_SEMESTER_KEY,
      key: "Semester",
      width: 25,
    },
    {
      Headers: "Section",
      bool: USE_SECTION_KEY,
      key: "Section",
      width: 25,
    },
  ];
  const schemaKeys = Object.keys(employee_schema);

  useEffect(() => {
    if (
      USE_CLASS_KEY ||
      USE_DEPARTMENT_KEY ||
      USE_SEMESTER_KEY ||
      USE_BRANCH_KEY
    ) {
      setSchema(schemaKeys);
    } // eslint-disable-next-line
  }, [USE_CLASS_KEY, USE_DEPARTMENT_KEY, USE_SEMESTER_KEY, USE_BRANCH_KEY]);
  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      emp_id: f.EmployeeId,
      emp_title: f.EmployeeTitle,
      emp_first_name: f.FirstName,
      emp_middle_name: f.MiddleName,
      emp_last_name: f.LastName,
      emp_sex: f.Gender,
      emp_dob: f.DOB,
      emp_mobile: f.Mobile,
      emp_email: f.Email,
      emp_qualification: f.Qualification,
      emp_experience: f.Experience,
      emp_status: f.Status,
      emp_on_roll: f.OnRole,
      emp_doj: f.DOJ,
      emp_dor: f.DOR,
      ParentDesignation: f.ParentDesignation,
      ...(USE_HR_DEPARTMENT ? { Department: f.Department } : {}),
      ...(USE_HR_DESIGNATION ? { Designation: f.Designation } : {}),
      ...(USE_HR_GRADE ? { Grade: f.Grade } : {}),
      ...(USE_HR_JOBTYPE ? { JobType: f.JobType } : {}),
    }));
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = instConfig
                    .filter((d) => d.bool)
                    .map((f) => f.Headers);

                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);
                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  const filteredStudentExcelData = employeeData.filter(
                    (column) => column.use_in_sheet
                  );

                  let studentInfoHeader = filteredStudentExcelData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  let dataRow = 6;
                  treeData?.GetMstInstTree.forEach((level1_data) => {
                    let level1_nxt_index = 0;
                    if (
                      level1_data.level_2_details &&
                      level1_data.level_2_details.length > 0
                    ) {
                      let level2_dataRow = dataRow;
                      level1_data.level_2_details.forEach((level2_data) => {
                        let level2_nxt_index = 0;
                        if (
                          level2_data.level_3_details &&
                          level2_data.level_3_details.length > 0
                        ) {
                          let level3_dataRow = level2_dataRow;
                          level2_data.level_3_details.forEach((level3_data) => {
                            let level3_nxt_index = 0;
                            if (
                              level3_data.level_4_details &&
                              level3_data.level_4_details.length > 0
                            ) {
                              let level4_dataRow = level3_dataRow;
                              level3_data.level_4_details.forEach(
                                (level4_data) => {
                                  let level4_nxt_index = 0;
                                  if (
                                    level4_data.level_5_details &&
                                    level4_data.level_5_details.length > 0
                                  ) {
                                    level4_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level3_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level1_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level2_nxt_index +=
                                      level4_data.level_5_details.length;
                                    let level5_dataRow = level4_dataRow;
                                    level4_data.level_5_details.forEach(
                                      (level5_data, index) => {
                                        const level5_cell = worksheet.getCell(
                                          `E${level5_dataRow}`
                                        );
                                        level5_cell.value =
                                          level5_data.level_5_desc;

                                        level5_cell.style = {
                                          alignment: HEADER_ALIGNMENT_CENTER,
                                        };
                                        level5_dataRow += index + 1;
                                      }
                                    );
                                  } else {
                                    level4_nxt_index = 1;
                                    level3_nxt_index += 1;
                                    level2_nxt_index += 1;
                                    level1_nxt_index += 1;
                                  }
                                  const level4_cell = worksheet.getCell(
                                    `D${level4_dataRow}`
                                  );
                                  level4_cell.value = level4_data.level_4_desc;
                                  worksheet.mergeCells(
                                    `D${level4_dataRow}:D${
                                      level4_dataRow + level4_nxt_index - 1
                                    }`
                                  );
                                  level4_cell.style = {
                                    alignment: HEADER_ALIGNMENT_CENTER,
                                  };
                                  level4_dataRow += level4_nxt_index;
                                }
                              );
                            } else {
                              level3_nxt_index = 1;
                              level2_nxt_index += 1;
                              level1_nxt_index += 1;
                            }
                            const level3_cell = worksheet.getCell(
                              `C${level3_dataRow}`
                            );
                            level3_cell.value = level3_data.level_3_desc;
                            worksheet.mergeCells(
                              `C${level3_dataRow}:C${
                                level3_dataRow + level3_nxt_index - 1
                              }`
                            );
                            level3_cell.style = {
                              alignment: HEADER_ALIGNMENT_CENTER,
                            };
                            level3_dataRow += level3_nxt_index;
                          });
                        } else {
                          level2_nxt_index = 1;
                          level1_nxt_index += 1;
                        }

                        const level2_cell = worksheet.getCell(
                          `B${level2_dataRow}`
                        );
                        level2_cell.value = level2_data.level_2_desc;
                        worksheet.mergeCells(
                          `B${level2_dataRow}:B${
                            level2_dataRow + level2_nxt_index - 1
                          }`
                        );
                        level2_cell.style = {
                          alignment: HEADER_ALIGNMENT_CENTER,
                        };
                        level2_dataRow += level2_nxt_index;
                      });
                    } else {
                      level1_nxt_index = 1;
                    }

                    const level1_cell = worksheet.getCell(`A${dataRow}`);
                    level1_cell.value = level1_data.level_1_desc;
                    worksheet.mergeCells(
                      `A${dataRow}:A${dataRow + level1_nxt_index - 1}`
                    );
                    level1_cell.style = {
                      alignment: HEADER_ALIGNMENT_CENTER,
                    };
                    dataRow += level1_nxt_index;
                  });
                  let count = 1;
                  if (USE_DEPARTMENT_KEY) {
                    count++;
                  }
                  if (USE_BRANCH_KEY) {
                    count++;
                  }
                  if (USE_CLASS_KEY) {
                    count++;
                  }
                  if (USE_SEMESTER_KEY) {
                    count++;
                  }
                  if (USE_SECTION_KEY) {
                    count++;
                  }

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName!);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];
    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      schema &&
        // eslint-disable-next-line
        Object.keys(schema).map((key) => {
          // @ts-ignore
          let keySchema = employee_schema[key];
          let keyValue = record[key];

          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleClick = (id: number) => {
    const filteredData = records.find((record) => record.id === id);

    if (filteredData) {
      const validationErrors: string[] = [];

      // Perform your manual validation logic here
      if (!filteredData.Name) {
        validationErrors.push("Name is required.");
      }
      if (!filteredData.EmpType) {
        validationErrors.push("Employee Type is required.");
      }
      if (!filteredData.EmpType) {
        validationErrors.push("Employee Type is required.");
      } else if (
        !Object.values(AccountentType).includes(
          filteredData.EmpType.toUpperCase()
        )
      ) {
        validationErrors.push("Invalid employee type.");
      }
      if (!filteredData.Mobile && !filteredData.Email) {
        validationErrors.push("Either mobile number or email is required.");
      } else {
        if (filteredData.Email && !validateEmail(filteredData.Email)) {
          validationErrors.push("Invalid email format.");
        }
        if (filteredData.Mobile && !validatePhoneNumber(filteredData.Mobile)) {
          validationErrors.push("Invalid phone number.");
        }
      }

      // Example date conversion function

      if (validationErrors.length === 0) {
        const newData = records.map((record) => {
          if (id === record.id) {
            return {
              ...record,
              isChecked: !record.isChecked,
              isValidated: true,
              isValidatedAndError: false,
            };
          }
          return record;
        });
        setRecords(newData);
      } else {
        const newData = records.map((record) => {
          if (record.id === id) {
            return {
              ...record,
              isChecked: !record.isChecked,
              isValidated: true,
              isValidatedAndError: true,
              errors: validationErrors,
            };
          }
          return record;
        });
        setRecords(newData);
      }
    }
  };
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;

  // Example validation helper functions
  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phone: string): boolean => {
    const phonePattern = /^[0-9]{10}$/; // Example phone number format
    return phonePattern.test(phone);
  };
  const handleSubmit = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);
    for (const record of records.filter(
      (record) => !record.isValidatedAndError
      //   && record.isChecked
    )) {
      try {
        await AddEmployees({
          variables: {
            token,
            inst_id: InstId,
            acd_yr_id: activeAcdId,

            user_details,
            input: {
              pr_emp_name: removeMoreSpace(record.TeacherName),
              subj_desc: record.SubjectName,
              is_class_teacher:
                record.IsClassTeacher === "YES"
                  ? true
                  : record.IsClassTeacher === "Yes"
                  ? true
                  : record.IsClassTeacher === "yes"
                  ? true
                  : record.IsClassTeacher === "NO"
                  ? false
                  : record.IsClassTeacher === "No"
                  ? false
                  : false,
              acd_dept_name: record.Department
                ? record.Department
                : EMPTY_STRING,
              acd_branch_name: record.Branch ? record.Branch : EMPTY_STRING,
              acd_class_name: record.Class ? record.Class : EMPTY_STRING,
              acd_semester_name: record.Semester
                ? record.Semester
                : EMPTY_STRING,
              acd_section_name: record.Section ? record.Section : EMPTY_STRING,
            },
          },

          onError: (err) => {
            record.errors = record.errors ? record.errors : [];
            record.errors.push(err.message);
            setMessage({
              flag: true,
              message: err.message,
              operation: Operation.NONE,
            });
            setErrorCount((prev) => prev + 1);
          },
        }).then(({ data }) => {
          if (data) {
            setSuccessCount((prev) => prev + 1);
          }
        });
      } catch (error) {
        console.error(error);
        setErrorCount((prev) => prev + 1);
      }
    }
    // setImportModal(false);
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(
    () => {
      const input = document.getElementById("input") as HTMLInputElement;
      if (input) {
        input.addEventListener("change", () => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const res = processExcel(reader.result);
            setRecords(
              res.map((f: any, index: number) => {
                return {
                  ...f,
                  id: index + 1,
                  isChecked: false,
                };
              })
            );
          };
          reader.readAsBinaryString(input.files![0]);
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Title>Import Teacher Data</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Employees
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 && schema ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell
                            className={classes.stickyHeaderCheckbox}
                            id="td-center"
                          >
                            <Checkbox />
                          </TableCell> */}
                          <TableCell
                            
                            id="td-center"
                          >
                            Sl
                          </TableCell>
                          {schemaKeys && // eslint-disable-next-line
                            schemaKeys.map((key, index) => {
                              // @ts-ignore

                              return (
                                <TableCell
                                  key={index}
                                 
                                  id="td-center"
                                >
                                  {key}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => !record?.errors)
                          .map((record, index) => {
                            return (
                              <TableRow key={index}>
                                {/* <TableCell
                                className={classes.stickyColumnCheckbox}
                                id="td-center"
                              >
                                <Checkbox
                                  onClick={(e) => handleClick(record.id)}
                                  checked={record?.isChecked}
                                />
                              </TableCell> */}

                                <TableCell
                                 
                                  id="td-center"
                                >
                                  {index + 1}
                                </TableCell>

                                {schemaKeys && // eslint-disable-next-line
                                  schemaKeys.map(
                                    // eslint-disable-next-line
                                    (key, index: React.Key) => {
                                      // @ts-ignore
                                      const fieldSchema = employee_schema[key];

                                      if (fieldSchema)
                                        return (
                                          <TableCell
                                            key={index}
                                           
                                          >
                                            {record[key]}
                                          </TableCell>
                                        );
                                    }
                                  )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
            
            </>
          ) : null}
        </div>
        <div className="row g-0 ">
          <div className="col">
            <Button
              onClick={() => {
                setImportModal(true);
                handleSubmit();
              }}
              mode="save"
              disabled={!records.length ? true : false}
            />
            <Button onClick={handleClick}>
              <img src={Validate} alt="" />
              Validate
            </Button>
            <Button onClick={handleClear} mode="clear" />

            {/* <Button onClick={() => downloadExcel(true)} mode="export">
              Export error Data
            </Button> */}
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={downloadExcel}>
              Generate Template
            </Button>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </Modal>
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default TeachersImport;

import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { msgType } from "../../../../utils/Form.types";
import { Direction, Operation } from "../../../../utils/Enum.types";
import useToken from "../../../../customhooks/useToken";
import { GetAcdReportCards } from "../../queries/reportcard";
import { AppContext } from "../../../../context/context";
import {
  GetAcdReportCardsData,
  GetAcdReportCardsEdges,
  GetAcdReportCardsVars,
} from "../../types/Progresscard";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import { DeleteAcdReportCard } from "../../queries/reportcard/mutation";
import LoadingModal from "../../../../pages/LoadingModal";
import DeleteModal from "../../../../pages/DeleteModal";

export interface UpdateDocumentTyps {
  id: number;
  name: string;
}
interface props {
  fetchEditDetails: (id: number, report_name: string) => void;
}
const List = ({ fetchEditDetails }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();

  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [reportCards, setReportCards] = useState<GetAcdReportCardsEdges[]>([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [reportCardId, setReportCardId] = useState(0);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetReportCardData, { data, fetchMore, loading }] = useLazyQuery<
    GetAcdReportCardsData,
    GetAcdReportCardsVars
  >(GetAcdReportCards, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      first: ROWS_PER_PAGE,
      after: null,
      orderBy: {
        direction: Direction.ASC,
      },
    },
  });
  useEffect(() => {
    if (token) {
      GetReportCardData();
    }
  }, [token, GetReportCardData]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdReportCards.edges;
                const pageInfo = fetchMoreResult.GetAcdReportCards.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetAcdReportCards.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcdReportCards: {
                    edges: [...reportCards, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdReportCards.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdReportCards.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = reportCards.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setReportCards(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            report_name: node.report_name,
            report_id: node.id,
          }))
        );
      } else {
        setReportCards(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            report_name: node.report_name,
            report_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetAcdReportCards.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl No",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,
    },
    {
      field: "report_name",
      headerName: "Report Name",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,
      flex: 1,
    },
    {
      field: "rep_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <img
              src={Edit}
              alt=""
              onClick={() => {
                fetchEditDetails(params.row.report_id, params.row.report_name);
              }}
            />
            <img
              src={Delete}
              alt=""
              onClick={() => {
                setReportCardId(params.row.report_id);
                setDeleteModal(!deleteModal);
              }}
            />
          </>
        );
      },
    },
  ];

  const [DeleteReportCard, { loading: deleteLoading }] = useMutation(
    DeleteAcdReportCard,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const HandleDelete = (id: number) => {
    if (InstId)
      DeleteReportCard({
        variables: {
          token,
          id: reportCardId,
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcdReportCards,
            variables: {
              token,
              inst_id: Number(InstId),
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              first: ROWS_PER_PAGE,
              after: null,
              orderBy: {
                direction: Direction.ASC,
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Document Deleted Successfully",
            operation: Operation.DELETE,
          });
          setDeleteModal(!deleteModal);
        }
      });
  };
  return (
    <>
      <div className={`document-create__table`}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          // experimentalFeatures={{ columnGrouping: true }}
          // columnGroupingModel={columnGroupingModel}
          //   onSortModelChange={}
        />
      </div>
      <LoadingModal flag={deleteLoading} />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={reportCardId}
      />
    </>
  );
};

export default List;

import { gql } from "@apollo/client";
export const AcctRaiseStudentDemandDetails = gql`
  mutation AcctRaiseStudentDemandDetails(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
    $class_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    AcctRaiseStudentDemandDetails(
      token: $token
      branch_id: $branch_id
      class_id: $class_id
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
    )
  }
`;

export const AddOrUpdateOrDeleteStudentDemandDetails = gql`
  mutation AddOrUpdateOrDeleteStudentDemandDetails(
    $token: String!
    $student_id: [ID!]!
    $input: AddOrUpdateOrDeleteDemandDetails
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $inst_id: ID!
  ) {
    AddOrUpdateOrDeleteStudentDemandDetails(
      token: $token
      user_details: $user_details
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      input: $input
      student_id: $student_id
    )
  }
`;
export const DeleteAcctStdDemand = gql`
  mutation DeleteAcctStdDemand(
    $token: String!
    $student_ids: [ID!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
  ) {
    DeleteAcctStdDemand(
      token: $token
      student_ids: $student_ids
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
    )
  }
`;
export const DeleteParticularFeeFromAcctStdDemandDetails = gql`
  mutation DeleteParticularFeeFromAcctStdDemandDetails(
    $token: String!
    $acct_ldgr_id: ID!
    $student_ids: [ID!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
  ) {
    DeleteParticularFeeFromAcctStdDemandDetails(
      token: $token
      acct_ldgr_id: $acct_ldgr_id
      student_ids: $student_ids
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
    )
  }
`;

import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import useToken from "./useToken";
import { useParams } from "react-router-dom";
import { IsModuleEnabled } from "../queries/institution/list/byId";
import { EduateModule } from "../utils/Enum.types";

const useEduateModuleConfig = (moduleName: EduateModule) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [GetIsModuleEnabled, { error, loading }] =
    useLazyQuery(IsModuleEnabled);

  useEffect(() => {
    if (token) {
      GetIsModuleEnabled({
        variables: {
          token,
          inst_id: InstId!,
          module_name: moduleName,
        },
      });
    }
  }, [token, GetIsModuleEnabled, moduleName, InstId]);

  return { error, loading };
};
export default useEduateModuleConfig;

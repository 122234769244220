import { gql } from "@apollo/client";

export const AddAcdTestType = gql`
  mutation AddAcdTestType(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateAcdTestTypeInput!
  ) {
    AddAcdTestType(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const AddAcdTestName = gql`
  mutation AddAcdTestName(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: CreateAcdTestNameInput!
  ) {
    AddAcdTestName(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;
export const UpdateAcdTestType = gql`
  mutation UpdateAcdTestType(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateAcdTestTypeInput!
  ) {
    UpdateAcdTestType(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const FinalizeAcdFeedbackForm = gql`
  mutation FinalizeAcdFeedbackForm(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
    $form_name_id: ID!
  ) {
    FinalizeAcdFeedbackForm(
      token: $token
      form_name_id: $form_name_id
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
    )
  }
`;
export const AddAcdTestClassAndSubjects = gql`
  mutation AddAcdTestClassAndSubjects(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $allotted_level: String!
    $allotted_id: ID!
    $test_name_id: ID!
    $test_class_marks: TestClassMaxMinMarks!
    $assoicated_subj_data: [AcdAssoicatedSubjData!]!
    $enable_teachers_comments: Boolean!
    $per_std_subj_allocation: Boolean!
  ) {
    AddAcdTestClassAndSubjects(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: {
        test_name_id: $test_name_id
        test_class_marks: $test_class_marks
        allotted_level: $allotted_level
        allotted_id: $allotted_id
        assoicated_subj_data: $assoicated_subj_data
        enable_teachers_comments: $enable_teachers_comments
        per_std_subj_allocation: $per_std_subj_allocation
      }
    )
  }
`;

export const AddAcdTestMarksForStds = gql`
  mutation AddAcdTestMarksForStds(
    $token: String!
    $acd_yr_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: StdIndivualMarksData!
    $todays_date: Time!
    $per_std_subj_allocation: Boolean!
  ) {
    AddAcdTestMarksForStds(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      todays_date: $todays_date
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;
export const ImportAcdTestMarksForStdsFromXlx = gql`
  mutation ImportAcdTestMarksForStdsFromXlx(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: StdMarksFromXlx!
    $todays_date: Time!
    $per_std_subj_allocation: Boolean!
  ) {
    ImportAcdTestMarksForStdsFromXlx(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      todays_date: $todays_date
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const UpdateAcdTestName = gql`
  mutation UpdateAcdTestName(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateAcdTestNameInput!
  ) {
    UpdateAcdTestName(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const FinalizeTestMarks = gql`
  mutation FinalizeTestMarks(
    $token: String!
    $acd_yr_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: AcdReferanceIds!
    $date_of_finalize: Time!
    $per_std_subj_allocation: Boolean!
  ) {
    FinalizeTestMarks(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      date_of_finalize: $date_of_finalize
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const UpdateAcdTestMarksBySubjectID = gql`
  mutation UpdateAcdTestMarksBySubjectID(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $acd_test_class_id: ID!
    $subj_master_id: ID!
    $std_marks_details: [StdMarksDetails!]!
    $entry_level: String!
    $entry_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    UpdateAcdTestMarksBySubjectID(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      acd_test_class_id: $acd_test_class_id
      subj_master_id: $subj_master_id
      std_marks_details: $std_marks_details
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const UpdateAcdTestMarksByStudentID = gql`
  mutation UpdateAcdTestMarksByStudentID(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $acd_test_class_id: ID!
    $student_id: ID!
    $marks_details: [SubjectWiseMarks!]!
    $entry_level: String!
    $entry_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    UpdateAcdTestMarksByStudentID(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      acd_test_class_id: $acd_test_class_id
      student_id: $student_id
      marks_details: $marks_details
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const UpdateAcdTestClassesAndSubjects = gql`
  mutation UpdateAcdTestClassesAndSubjects(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $test_class_id: ID!
    $input: AcdTestClassesAndSubjData!
  ) {
    UpdateAcdTestClassesAndSubjects(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      test_class_id: $test_class_id
      input: $input
    )
  }
`;

export const DeleteAcdTestClassesAndSubjects = gql`
  mutation DeleteAcdTestClassesAndSubjects(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $test_class_id: ID!
  ) {
    DeleteAcdTestClassesAndSubjects(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      test_class_id: $test_class_id
    )
  }
`;
export const AcdYrPosting = gql`
  mutation AcdYrPosting(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: NewAcdYrData!
    $branch_ids: [ID!]!
    $prev_acd_yr_id: ID!
  ) {
    AcdYrPosting(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      branch_ids: $branch_ids
      prev_acd_yr_id: $prev_acd_yr_id
    )
  }
`;
export const AcdSemesterPosting = gql`
  mutation AcdSemesterPosting(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $branch_ids: [ID!]!
  ) {
    AcdSemesterPosting(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      branch_ids: $branch_ids
    )
  }
`;
export const DeleteAllChannel = gql`
  mutation DeleteAllChannel(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAllChannel(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const ReCreateAllChannels = gql`
  mutation ReCreateAllChannels(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReCreateAllChannels(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddAcdDerivedTestForClass = gql`
  mutation AddAcdDerivedTestForClass(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: AcdDerivedTestData!
    $per_std_subj_allocation: Boolean!
  ) {
    AddAcdDerivedTestForClass(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;
export const UpdateAcdInstSessionActiveStatus = gql`
  mutation UpdateAcdInstSessionActiveStatus(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $session_ids: [ID!]!
    $active_status: Boolean!
  ) {
    UpdateAcdInstSessionActiveStatus(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      session_ids: $session_ids
      active_status: $active_status
    )
  }
`;

export const UpdateAcdDerivedTestForClass = gql`
  mutation UpdateAcdDerivedTestForClass(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $test_class_id: ID!
    $input: AcdDerivedTestData!
  ) {
    UpdateAcdDerivedTestForClass(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      test_class_id: $test_class_id
      input: $input
    )
  }
`;

export const AddAcdTestRemarksForStds = gql`
  mutation AddAcdTestRemarksForStds(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: StdIndivualTestComments!
    $todays_date: Time!
    $per_std_subj_allocation: Boolean!
  ) {
    AddAcdTestRemarksForStds(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
      todays_date: $todays_date
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const UpdateAcdTestRemarksForStd = gql`
  mutation UpdateAcdTestRemarksForStd(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $acd_test_class_id: ID!
    $student_id: ID!
    $test_remarks: String!
    $todays_date: Time!
  ) {
    UpdateAcdTestRemarksForStd(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      acd_test_class_id: $acd_test_class_id
      student_id: $student_id
      test_remarks: $test_remarks
      todays_date: $todays_date
    )
  }
`;

import { useState } from "react";

import Modal from "react-modal";
import MonthlyTotal from "../MonthlyTotal";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import Close from "../../../../../images/Close.svg";
import {
  GetAcctLdgrYearlyDataByInstIdDetails,
  GetAcctRegisterYearlyDataByInstIdDetails,
} from "../../../../../Types/Accounting";
import { LedgersOrRegisters } from "../../../../../utils/types";

interface Props {
  acctldgrYearlyData: GetAcctLdgrYearlyDataByInstIdDetails[];
  registerYearlyData: GetAcctRegisterYearlyDataByInstIdDetails[];
  type: LedgersOrRegisters;
}

const CompleteMonthlyTotalModal = ({
  acctldgrYearlyData,
  registerYearlyData,
  type,
}: Props) => {
  const [completeDetailsModal, setCompleteDetailsModal] = useState(false);

  return (
    <>
      <MonthlyTotal
        type={type}
        acctldgrYearlyData={acctldgrYearlyData}
        registerYearlyData={registerYearlyData}
        completeDetailsModal={completeDetailsModal}
        setCompleteDetailsModal={setCompleteDetailsModal}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={completeDetailsModal}
        style={StudentModalStyles}
      >
        <div className="bank-book__table-section--transaction-details--tableblock">
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <MonthlyTotal
                type={type}
                registerYearlyData={registerYearlyData}
                acctldgrYearlyData={acctldgrYearlyData}
                completeDetailsModal={completeDetailsModal}
                setCompleteDetailsModal={setCompleteDetailsModal}
              />
            </div>
            <div className="modal-flex__image">
              <img
                onClick={() => setCompleteDetailsModal(!completeDetailsModal)}
                src={Close}
                alt="/"
                className="modal-close-icon"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CompleteMonthlyTotalModal;

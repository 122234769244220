import { useState } from "react";
import EyeWhite from "../../../../../../images/EyeWhite.svg";
import { AcdSubjComboQueryType } from "../../../../../Accounts/common/QueryTypes";
import useCombinationDetails from "../../../../CustomAllocation/customHooks/useCombinationDetails";
import { useNavigate, useParams } from "react-router-dom";
const Combination = () => {
  const { data } = useCombinationDetails(
    AcdSubjComboQueryType.COMBO_BY_TEST_CLASS_ID
  );

  const { InstId, entryId, testId, testTypeId } = useParams();

  const navigate = useNavigate();

  return (
    <>
      <div className="combination-report__details">
        <div className="combination-report__details--grids">
          {data
            ? data.GetComboDetails.map((combo) => {
                return (
                  <div className="combination-report__details--grid">
                    <b>{combo.subj_combo_name}</b>
                    <div className="combination-report__details--flex">
                      <div>
                        <span>Total Subjects</span>
                        <b>{combo.subject_count}</b>
                      </div>
                      <div>
                        <span>Total Students</span>
                        <b>{combo.std_count}</b>
                      </div>
                    </div>
                    <button
                      className="combination-report__details--view"
                      onClick={() =>
                        navigate(
                          `/${InstId}/academics/reports/completeReports/${entryId}/view/${testId}/${testTypeId}/pertestdetails/${combo.id}/forcombination`
                        )
                      }
                    >
                      View Details <img src={EyeWhite} alt="" />
                    </button>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};

export default Combination;

import { gql } from "@apollo/client";

export const AddLibBookIssue = gql`
  mutation AddLibBookIssue(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $lib_book_master_ids: [ID!]!
    $input: CreateLibBookIssueInput!
  ) {
    AddLibBookIssue(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      lib_book_master_ids: $lib_book_master_ids
    )
  }
`;

import { gql } from "@apollo/client";

export const GetAcdInstSessions = gql`
  query GetAcdInstSessions($token: String!, $inst_id: ID!) {
    GetAcdInstSessions(token: $token, inst_id: $inst_id) {
      id
      session_desc
      start_time
      end_time
      session_idx
      inst_id
    }
  }
`;
export const GetAcdInstInactiveSessions = gql`
  query GetAcdInstInactiveSessions($token: String!, $inst_id: ID!) {
    GetAcdInstInactiveSessions(token: $token, inst_id: $inst_id) {
      id
      session_desc
      start_time
      end_time
      session_idx
      session_active
      inst_id
    }
  }
`;

export const GetAcdAttSessionsBySubjectForTheDay = gql`
  query GetAcdAttSessionsBySubjectForTheDay(
    $token: String!
    $entry_id: ID!
    $entry_level: String!
    $cal_month: Time!
    $inst_id: ID!
    $acd_yr_id: ID!
    $subj_master_id: ID!
    $date_of_attendance: Time!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdAttSessionsBySubjectForTheDay(
      token: $token
      entry_id: $entry_id
      entry_level: $entry_level
      cal_month: $cal_month
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      subj_master_id: $subj_master_id
      date_of_attendance: $date_of_attendance
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      id
      session_desc
      start_time
      end_time
      session_idx
      inst_id
    }
  }
`;

export const GetAcdSubjAttOverAll = gql`
  query GetAcdSubjAttOverAll(
    $token: String!
    $input: SubjMonthlyAttQuery
    $first: Int
    $after: Cursor
    $orderBy: AcdAttendanceSubjYearlyOrder
  ) {
    GetAcdSubjAttOverAll(
      token: $token
      input: $input
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          std_roll_no
          total_session
          total_session_present
          total_session_absent
          over_all_per
        }
      }
    }
  }
`;

export const GetSubjAttPerSubjectPerSessionCount = gql`
  query GetSubjAttPerSubjectPerSessionCount(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $subj_master_id: ID!
    $session_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetSubjAttPerSubjectPerSessionCount(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      subj_master_id: $subj_master_id
      session_id: $session_id
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      total_students
      total_present
      total_absent
      att_percentage
      completed_session
    }
  }
`;
export const GetAcdUnMarkedSessionsForTheDay = gql`
  query GetAcdUnMarkedSessionsForTheDay(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $entry_level: String!
    $entry_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdUnMarkedSessionsForTheDay(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      id
      session_desc
      start_time
      end_time
      session_idx
      inst_id
    }
  }
`;
export const GetAcdMarkedSessionsForTheDay = gql`
  query GetAcdMarkedSessionsForTheDay(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $entry_level: String!
    $entry_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdMarkedSessionsForTheDay(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      session_id
      session_desc
      start_time
      end_time
      subj_master_id
      subj_desc
    }
  }
`;

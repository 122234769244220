export enum InstitutionAcctConfigurationTypes {
  STD_RECEIPT_NO_MANUAL_ENTRY = "STD_RECEIPT_NO_MANUAL_ENTRY",
  STD_RECEIPT_NO_EDIT = "STD_RECEIPT_NO_EDIT",
  STD_RECEIPT_EDIT_DATE = "STD_RECEIPT_EDIT_DATE",
  MULTIPLE_BILL_BOOKS = "MULTIPLE_BILL_BOOKS",
  HIDE_CAPITATION_FEE = "HIDE_CAPITATION_FEE",
  ALLOW_MUL_BILLS_UNDER_ONE = "ALLOW_MUL_BILLS_UNDER_ONE",
  ENABLE_FEE_FINE = "ENABLE_FEE_FINE",
  SHOW_ONLY_CONSOL_FEE_IN_STD_LOGIN = "SHOW_ONLY_CONSOL_FEE_IN_STD_LOGIN",
  PAYMENT_GATEWAY = "PAYMENT_GATEWAY",
  VOUCHER_EDIT_DATE = "VOUCHER_EDIT_DATE",
  ALLOW_CHANGE_OF_BRANCH = "ALLOW_CHANGE_OF_BRANCH",
  ENABLE_MAKER_CHECKER = "ENABLE_MAKER_CHECKER",
  MKCK_MAX_PAYMENT_AMOUNT = "MKCK_MAX_PAYMENT_AMOUNT",
  ALLOW_PARTIAL_SCHOLARSHIP_ADJUSTMENT = "ALLOW_PARTIAL_SCHOLARSHIP_ADJUSTMENT",
  ENABLE_PARTIAL_STD_RECEIPT_PAYMENT = "ENABLE_PARTIAL_STD_RECEIPT_PAYMENT",
  RESTRICT_STD_FEE_CONSESSION_ONLY_ONCE = "RESTRICT_STD_FEE_CONSESSION_ONLY_ONCE",
  ENABLE_APPLICATION_FEE = "ENABLE_APPLICATION_FEE",
  STRICTLY_COLLECT_STD_FEE_IN_ORDER = "STRICTLY_COLLECT_STD_FEE_IN_ORDER",
  ENABLE_OB_COLLECT_FIRST = "ENABLE_OB_COLLECT_FIRST",
  ENABLE_FEED_BACK = "ENABLE_FEED_BACK",
}
export enum VoucherQueryTypes {
  BY_DATES = "BY_DATES",
  BY_VOUCHER_BOOK_TYPE_AND_BY_DATES = "BY_VOUCHER_BOOK_TYPE_AND_BY_DATES",
  BY_VOUCHER_TYPE_AND_BY_DATES = "BY_VOUCHER_TYPE_AND_BY_DATES",
  STUDENT_ALL_RECEIPTS = "STUDENT_ALL_RECEIPTS",
  STUDENT_DEMAND_RECEIPTS = "STUDENT_DEMAND_RECEIPTS",
  REFUND_VOUCHERS = "REFUND_VOUCHERS",
  CONCESSION_VOUCHERS = "CONCESSION_VOUCHERS",
  STD_NON_DEMAND_RECEIPTS = "STD_NON_DEMAND_RECEIPTS",
  PAYMENT_VOUCHERS = "PAYMENT_VOUCHERS",
  RECEIPTS_VOUCHERS = "RECEIPTS_VOUCHERS",
  CONTRA_VOUCHERS = "CONTRA_VOUCHERS",
  JOURNAL_VOUCHERS = "JOURNAL_VOUCHERS",
  STD_REGISTER = "STD_REGISTER",
  STD_DEMANDS_AND_DEPOSITS = "STD_DEMANDS_AND_DEPOSITS",
  STD_DAILY_RECEIPTS = "STD_DAILY_RECEIPTS",
  ANONYMOUS_STD_RECEIPTS = "ANONYMOUS_STD_RECEIPTS",
  STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS = "STUDENT_ALL_RECEIPTS_WITHOUT_REFUNDS",
  ADD_STD_RECEIPT_USER_LOG = "ADD_STD_RECEIPT_USER_LOG",
  EDIT_STD_RECEIPT_USER_LOG = "EDIT_STD_RECEIPT_USER_LOG",
  ADD_STD_REFUND_USER_LOG = "ADD_STD_REFUND_USER_LOG",
  EDIT_STD_REFUND_USER_LOG = "EDIT_STD_REFUND_USER_LOG",
  ADD_PAYMENT_USER_LOG = "ADD_PAYMENT_USER_LOG",
  EDIT_PAYMENT_USER_LOG = "EDIT_PAYMENT_USER_LOG",
  ADD_RECEIPT_USER_LOG = "ADD_RECEIPT_USER_LOG",
  EDIT_RECEIPT_USER_LOG = "EDIT_RECEIPT_USER_LOG",
  ADD_CONTRA_USER_LOG = "ADD_CONTRA_USER_LOG",
  EDIT_CONTRA_USER_LOG = "EDIT_CONTRA_USER_LOG",
  ADD_JOURNAL_USER_LOG = "ADD_JOURNAL_USER_LOG",
  EDIT_JOURNAL_USER_LOG = "EDIT_JOURNAL_USER_LOG",
  DELETED_STD_RECEIPT_USER_LOG = "DELETED_STD_RECEIPT_USER_LOG",
  DELETED_STD_REFUND_USER_LOG = "DELETED_STD_REFUND_USER_LOG",
  DELETED_PAYMENT_USER_LOG = "DELETED_PAYMENT_USER_LOG",
  DELETED_RECEIPT_USER_LOG = "DELETED_RECEIPT_USER_LOG",
  DELETED_CONTRA_USER_LOG = "DELETED_CONTRA_USER_LOG",
  DELETED_JOURNAL_USER_LOG = "DELETED_JOURNAL_USER_LOG",
  STD_DEPOSIT_COLLECTED_DETAILS = "STD_DEPOSIT_COLLECTED_DETAILS",
  STD_DEPOSIT_ADJUSTED_DETAILS = "STD_DEPOSIT_ADJUSTED_DETAILS",
}
export enum ChallanQueryTypes {
  ALL_CHALLAN = "ALL_CHALLAN",
  CHALLAN_RECONCILED = "CHALLAN_RECONCILED",
  CHALLAN_PENDING = "CHALLAN_PENDING",
  CHALLAN_DISCORDED = "CHALLAN_DISCORDED",
  CHALLAN_ALL_BANK_LDGR = "CHALLAN_ALL_BANK_LDGR",
  CHALLAN_BANK_LDGR_RECONCILED = "CHALLAN_BANK_LDGR_RECONCILED",
  CHALLAN_BANK_LDGR_PENDING = "CHALLAN_BANK_LDGR_PENDING",
  CHALLAN_BANK_LDGR_DISCORDED = "CHALLAN_BANK_LDGR_DISCORDED",
  CHALLAN_ALL_STD = "CHALLAN_ALL_STD",
  CHALLAN_STD_RECONCILED = "CHALLAN_STD_RECONCILED",
  CHALLAN_STD_PENDING = "CHALLAN_STD_PENDING",
  CHALLAN_STD_DISCORDED = "CHALLAN_STD_DISCORDED",
  CHALLAN_ALL_ENQ = "CHALLAN_ALL_ENQ",
  CHALLAN_ENQ_RECONCILED = "CHALLAN_ENQ_RECONCILED",
  CHALLAN_ENQ_PENDING = "CHALLAN_ENQ_PENDING",
  CHALLAN_ENQ_DISCORDED = "CHALLAN_ENQ_DISCORDED",
  CHALLAN_ALL_ALUMINI = "CHALLAN_ALL_ALUMINI",
  CHALLAN_ALUMINI_RECONCILED = "CHALLAN_ALUMINI_RECONCILED",
  CHALLAN_ALUMINI_PENDING = "CHALLAN_ALUMINI_PENDING",
  CHALLAN_ALUMINI_DISCORDED = "CHALLAN_ALUMINI_DISCORDED",
}
export enum AcctStudentType {
  AnonymousRecolied = "AR",
  SocialWelfare = "SW",
  Agent = "AG",
}
export enum AcctDashboard {
  TODAY = "TODAY",
  THIS_MONTH = "THIS_MONTH",
  PREV_MONTH = "PREV_MONTH",
  THIS_YEAR = "THIS_YEAR",
}
export enum VoucherApprovalStatus {
  Pending = "P",
  Rejected = "R",
  Approved = "A",
  Forwarded = "F",
  Created = "C",
}
export enum MkCkQueryType {
  MK_CK_PENDING = "MK_CK_PENDING",
  MK_CK_REJECTED = "MK_CK_REJECTED",
  MK_CK_FORWARDED = "MK_CK_FORWARDED",
  MK_CK_APPROVED = "MK_CK_APPROVED",
  MK_CK_ALL_STILL_OPENED = "MK_CK_ALL_STILL_OPENED",
  MK_CK_ALL_CLOSED = "MK_CK_ALL_CLOSED",
  MK_CK_ALL = "MK_CK_ALL",
  MK_CK_PENDING_BY_USER_ID = "MK_CK_PENDING_BY_USER_ID",
  MK_CK_REJECTED_BY_USER_ID = "MK_CK_REJECTED_BY_USER_ID",
  MK_CK_FORWARDED_BY_USER_ID = "MK_CK_FORWARDED_BY_USER_ID",
  MK_CK_APPROVED_BY_USER_ID = "MK_CK_APPROVED_BY_USER_ID",
}
export enum AccountentType {
  ACCOUNTANT = "ACCOUNTANT",
  CASHIER = "CASHIER",
  CLERK = "CLERK",
  TEACHER = "TEACHER",
  SYS_ADMIN = "SYS_ADMIN",
  LIBRARIAN = "LIBRARIAN",
  ADM_EXECUTIVE = "ADM_EXECUTIVE",
}

export enum ChannelQueryType {
  MYCAMPUSFEED = "MY-CAMPUS-FEED",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  ASSIGNMENTS = "ASSIGNMENTS",
  ENTRYLEVEL = "ENTRY-LEVEL",
  CUSTOM = "CUSTOM",
  INDIVIDUAL = "INDIVIDUAL",
  MULTIPLE = "MULTIPLE",
}
export enum ChannelQueryTypeByInstId {
  MYCAMPUSFEED = "MY_CAMPUS_FEEDS",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  MSGS_BY_CHANNEL_ID = "MSGS_BY_CHANNEL_ID",
  ALL_CHANNELS = "ALL_CHANNELS",
}

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import DeleteIcon from "../../../images/Delete.svg";
import useToken from "../../../customhooks/useToken";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { StudentDetailsByConcessionVoucherId } from "../../../queries/common";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import {
  ConcessionVoucherData,
  ConcessionVoucherDetails,
  GetAcctStdConcessionsData,
  GetAcctStdConcessionsEdge,
  GetAcctStdConcessionsVars,
  nodevars,
} from "../../../Types/Accounting";
import { TableHeaderProps } from "../../../Types/Tables";
import { StudentFeeReceiptTitleProps } from "../../../Types/Titles";
import {
  CURRENT_PAGE,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  SortBy,
  Operation,
  Direction,
  AcctStdConcessionQueryType,
} from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import {
  formatter,
  getModifiedScrollHeight,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { DeleteAcctStdConcessionAndDetails } from "../queries/receipts/mutation/Index";
import { GetAcctStdConcessions } from "../queries/Vouchers/query";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";

const { AccountsTitles } = require("../json/title.json");

const Delete = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { format } = formatter;

  const { Accounts_Table } = useAcctTableJson();
  const [searchData, setSearchData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [acctId, setAcctId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [concessionVouchers, setConcessionVouchers] = useState<
    GetAcctStdConcessionsEdge[]
  >([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const { state } = useContext(AppContext);
  const [studentDetails, setStudentDetails] = useState<
    ConcessionVoucherDetails[]
  >([]);
  const { user_details } = useLoggedInUserDetails();

  const [GetAcctConcession, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcctStdConcessionsData,
    GetAcctStdConcessionsVars
  >(GetAcctStdConcessions, {
    variables: {
      after: null,
      direction: Direction.ASC,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: ROWS_PER_PAGE,
      input: {
        query_type: AcctStdConcessionQueryType.STD_CONCESSIONS,
        vo_start_date: toIsoDate(startDate),
        vo_end_date: toIsoDate(endDate),
        user_details,
      },
      inst_id: InstId!,
      name: searchData,
      sortBy: SortBy.CONCESSION_VOUCH_DATE,
      token,
    },
  });

  const [GetStudentDetails] = useLazyQuery<ConcessionVoucherData, nodevars>(
    StudentDetailsByConcessionVoucherId
  );
  const [DeleteVoucher, { loading: deleteVoucherLoading }] = useMutation(
    DeleteAcctStdConcessionAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const totalCount = data?.GetAcctStdConcessions?.totalCount;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = data?.GetAcctStdConcessions.edges.slice(
    indexOfFirstrepo,
    indexOfLastrepo
  );

  const FetchStudentDetails = (node: ConcessionVoucherDetails) => {
    const student = studentDetails.find((id) => id.id === node.id);

    if (student && student.concession_details.length)
      return (
        student.mst_student.first_name +
        " " +
        student.mst_student.middle_name +
        " " +
        student.mst_student.last_name +
        " " +
        `(${node.branch_details.branch_desc} / ${node.class_details.class_desc} / ${student.concession_details[0].acct_ldgr_details.ldgr_desc} / ${student?.mst_student.std_adm_no})`
      );
    else return EMPTY_STRING;
  };
  const HandleDelete = (id: number) => {
    DeleteVoucher({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctStdConcessions,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              query_type: AcctStdConcessionQueryType.STD_CONCESSIONS,
              vo_start_date: toIsoDate(startDate),
              vo_end_date: toIsoDate(endDate),
              user_details,
            },
            inst_id: InstId!,
            name: searchData,
            sortBy: SortBy.CONCESSION_VOUCH_DATE,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdConcessions.edges;
            const pageInfo = fetchMoreResult.GetAcctStdConcessions.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetAcctStdConcessions.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdConcessions: {
                edges: [...concessionVouchers, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctStdConcessions.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAcctId(0);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (data) {
      GetStudentDetails({
        variables: {
          token,
          ids: currentRepo?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data) {
          setStudentDetails(data.nodes);
        }
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, currentRepo?.length, token]);
  useEffect(() => {
    if (state.ActiveFinYr) {
      setStartDate(state.ActiveFinYr.fin_st_date);
      setEndDate(state.ActiveFinYr.fin_end_date);
    }
  }, [state.ActiveFinYr]);
  useEffect(() => {
    if (token && state.ActiveFinYr && startDate && endDate) {
      GetAcctConcession();
    }
  }, [
    token,
    state.ActiveFinYr,
    GetAcctConcession,
    startDate,
    endDate,
    searchData,
  ]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdConcessions.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = concessionVouchers.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setConcessionVouchers(updatedNewData);
      } else {
        setConcessionVouchers(newData);
      }
      setEndCursor(data.GetAcctStdConcessions.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      <Title>
        {AccountsTitles.StudentFeeReceipt.Titles.map(
          (title: StudentFeeReceiptTitleProps) => {
            return (
              <React.Fragment key={title.Delete_Demand_Fee_Receipt}>
                {title.Delete_Demand_Fee_Receipt}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="delete-demand-fee-receipt">
        <div className="row g-0 delete-demand-fee-receipt__details">
          <div className="col-3">
            <Input
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search Vouchers..."
              id="search"
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <TextField
              type="date"
              label="Start Date."
              value={toInputStandardDate(startDate!)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col-2">
            <TextField
              type="date"
              label="End Date."
              value={toInputStandardDate(endDate!)}
              onChange={(e) => setEndDate(e.target.value)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </div>
        </div>
        <div className="delete-demand-fee-receipt__tableblock">
          {!currentRepo?.length && !loading ? (
            <b className="nodata">Sorry No Results</b>
          ) : (
            <TableContainer
              className="delete-demand-fee-receipt__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.DeleteDemandFeeReceipt.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {concessionVouchers.map((response, index) => (
                    <TableRow key={response.node.id}>
                      <TableCell
                        id="td-center"
                        className="delete-demand-fee-receipt__table--slno"
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell className="delete-demand-fee-receipt__table--vno">
                        {response.node.concession_vouch_no}
                      </TableCell>
                      <TableCell>
                        {FetchStudentDetails(response.node)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="delete-demand-fee-receipt__table--amount"
                      >
                        {format(response.node.concession_total)}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="delete-demand-fee-receipt__table--actions"
                      >
                        <img
                          src={DeleteIcon}
                          onClick={() => {
                            setAcctId(response.node.id);
                            setDeleteModal(!deleteModal);
                          }}
                          alt="/"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
      <LoadingModal flag={loading || deleteVoucherLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={acctId}
      />
    </>
  );
};

export default Delete;

import React, { useContext, useEffect, useState } from "react";
import List from "./List";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import InstDetails from "../../DailyActivities/InstDetails";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { Operation, TableHeaders } from "../../../../utils/Enum.types";
import {
  GetnumberOfDaysInMonth,
  handleDate,
  MonthName,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../../utils/constants";
import Next from "../../../../images/CalenderNextButton.svg";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Input from "../../../../stories/Input/Input";
import { GetAcdStudentsAttendance } from "../../queries/holidays/list";
import { useLazyQuery } from "@apollo/client";
import {
  AttendenceQueryType,
  GetAcdAttendanceMonthlyNode,
} from "../../hooks/useAcdStudentAttendance";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import {
  GetAcdAttendanceMonthlyForStdsData,
  GetAcdStudentsAttendanceVars,
} from "../../DailyActivities/Attendance/AbsenteesList";
import { msgType } from "../../../../utils/Form.types";
import { INCREMENT_DECREMENT } from "../../../Enquiry/Dashboard/Index";
import dayjs from "dayjs";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";

import MessageModal from "../../../../pages/MessageModal";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  GridAlignment,
  GridColDef,
  GridPinnedColumnFields,
} from "@mui/x-data-grid-pro";
const ViewMonthwise = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const [date, setMonthDate] = useState(new Date());
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { serverDate } = useServerDateandTime();

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const numberOfDaysInMonth = GetnumberOfDaysInMonth(date.toString());

  const daysArray = Array.from({ length: numberOfDaysInMonth }, (_, i) => {
    const dayIndex =
      (i + new Date(date.getFullYear(), date.getMonth(), 1).getDay()) % 7;
    return { day: days[dayIndex], date: dayIndex };
  });
  const { USE_SECTION_KEY, USE_CLASS_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();

  const [GetStdDataAttendance, { data: stdAttData }] = useLazyQuery<
    GetAcdAttendanceMonthlyForStdsData,
    GetAcdStudentsAttendanceVars
  >(GetAcdStudentsAttendance);

  useEffect(() => {
    if (token && entryId) {
      GetStdDataAttendance({
        variables: {
          token,
          input: {
            att_query_type: USE_SECTION_KEY
              ? AttendenceQueryType.ATT_STD_BY_SECTION_ID
              : USE_SEMESTER_KEY
              ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
              : USE_CLASS_KEY
              ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
              : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            subj_master_id: 0,
            per_std_subj_allocation: false,
          },
        },
      });
    }
  }, [token, entryId, GetStdDataAttendance, date]);

  const daysColumn = daysArray.map((day, index) => ({
    field: `day_${index + 1}`,
    headerName: `${index + 1} ${day.day}`,
    headerAlign: HEADER_TEXT_ALIGN,
    cellClassName: "td-sl-no",
    align: SLNO_TEXT_ALIGN,
  }));

  const getCellClassName = (params: any) => {
    const value = params.value;
    if (value === "P") return "font-green";
    if (value === "A") return "font-red";
    return "";
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "name",
      headerName: TableHeaders.NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    ...daysArray.map((_, index) => ({
      field: `day_${index + 1}`,
      headerName: `Day ${index + 1}`,
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      cellClassName: getCellClassName,
      renderCell: (params: any) => (params.value ? params.value : "-"),
    })),
  ];
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const getDayValues = (edge: GetAcdAttendanceMonthlyNode) => {
    const daysRow = daysArray.reduce((acc, _, index) => {
      const dayValue = (edge as unknown as Record<string, string>)[
        `day_${index + 1}`
      ];

      acc = {
        ...acc,
        [`day_${index + 1}`]: dayValue,
      };

      return acc;
    }, {});
    return daysRow;
  };

  const rows = stdAttData
    ? stdAttData.GetAcdStudentsAttendance.map((edge, index) => ({
        id: index + 1,
        adm_no: edge.student_details.std_adm_no,
        name: `${edge.student_details.first_name} ${edge.student_details.middle_name}  ${edge.student_details.last_name}`,
        ...getDayValues(edge),
      }))
    : [];

  const [pinnedColumns, setPinnedColumns] =
    React.useState<GridPinnedColumnFields>({
      left: ["id", "adm_no", "name"],
    });
  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumnFields) => {
      setPinnedColumns(updatedPinnedColumns);
    },
    []
  );

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="view-overall-att">
        <div className="view-overall-att__left">
          <List />
        </div>
        <div className="view-overall-att__right">
          <InstDetails />
          <div className="view-overall-att__select row g-0">
            <div className="col-2">
              <Input id="search" placeholder="Search..." />
            </div>
            <div className="col">
              {" "}
              <Title variant="subtitle1">
                <img
                  src={Previous}
                  alt="/"
                  onClick={() =>
                    handleDate(
                      date,
                      setMonthDate,
                      state.ActiveAcdYr
                        ? state.ActiveAcdYr.acd_st_date
                        : EMPTY_STRING,
                      state.ActiveAcdYr
                        ? state.ActiveAcdYr.acd_end_date
                        : EMPTY_STRING,
                      setMessage,
                      INCREMENT_DECREMENT.DECREMENT
                    )
                  }
                />
                {MonthName(date.toString())}

                <img
                  src={Next}
                  alt="/"
                  onClick={() => {
                    const nextDate = new Date(
                      date.getFullYear(),
                      date.getMonth() + 1,
                      date.getDate()
                    );
                    const serverDateDayjs = dayjs(serverDate);
                    const selectedDateDayjs = dayjs(nextDate);
                    if (
                      serverDateDayjs.isAfter(selectedDateDayjs) &&
                      !serverDateDayjs.isSame(selectedDateDayjs)
                    ) {
                      handleDate(
                        date,
                        setMonthDate,
                        state.ActiveAcdYr
                          ? state.ActiveAcdYr.acd_st_date
                          : EMPTY_STRING,
                        state.ActiveAcdYr
                          ? state.ActiveAcdYr.acd_end_date
                          : EMPTY_STRING,
                        setMessage,
                        INCREMENT_DECREMENT.INCREMENT
                      );
                    } else {
                      setMessage({
                        flag: true,
                        operation: Operation.NONE,
                        message: "Cannot Access Future Dates",
                      });
                    }
                  }}
                />
              </Title>
            </div>
            <div className="col-2 flex-end">
              <div className="student-total-count">
                Total Student :{" "}
                <b>{stdAttData?.GetAcdStudentsAttendance.length}</b>
              </div>
            </div>
          </div>

          <div className={`view-overall-att__monthly `}>
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              hideFooter
              pinnedColumns={pinnedColumns}
              onPinnedColumnsChange={handlePinnedColumnsChange}
            />
          </div>
          <Button mode="excel">Export</Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default ViewMonthwise;

import React, { useState } from "react";
import { Button } from "../../../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import InstDetails from "../../../../DailyActivities/InstDetails";
import RightArrow from "../../../../../../images/ArrowRight.svg";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../../../styles/Tabs";
import Input from "../../../../../../stories/Input/Input";
import { useTheme } from "@mui/material/styles";
import Combination from "./Combination";
import Subjectwise from "./Subjectwise";
import Home from "../../../../Home/Index";
import { Title } from "../../../../../../stories/Title/Title";

const Index = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Academics Report </Title>
      <div className="combination-report">
        <InstDetails />
        <div className="combination-report__back">
          <img src={RightArrow} alt="/" />
          Go back to Test / Exam Report
        </div>
        <div className="combination-report__title">Third Internals (Test)</div>
        <div className="combination-report__tabs">
          <Tabs value={value} onChange={handleTabChange}>
            <Tab label="combination wise" value={0} {...a11yProps(0)} />

            <Tab label="Subject wise" value={1} {...a11yProps(1)} />
          </Tabs>
        </div>

        <div className="combination-report__search row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
        </div>
        <div className="combination-report__datablock">
          <div className="combination-report__datablock--tab-panel">
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Combination />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Subjectwise />
            </TabPanel>
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

import React, { useContext, useEffect, useState } from "react";
import Eye from "../../../../images/EyeWhite.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import View from "./View";
import {
  GetComboDetailsData,
  GetComboDetailsList,
  GetComboDetailsVars,
} from "../../../../Types/Combinations/queries";

import { AppContext } from "../../../../context/context";

import { payloadTypes } from "../../../../context/reducer";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
import { useLazyQuery } from "@apollo/client";
import { GetComboDetails } from "../../../../queries/customallocation/combination/queries";
import { useParams } from "react-router-dom";
import useAcdLevel from "../../../Accounts/hooks/useAcdLevel";
import useToken from "../../../../customhooks/useToken";

const Combinationwise = () => {
  const [viewModal, setViewModal] = useState(false);

  const { token } = useToken();
  const { state } = useContext(AppContext);

  const { entryId, InstId } = useParams();
  const { acdLevel } = useAcdLevel(entryId!);
  const [GetCombinations, { data, error }] = useLazyQuery<
    GetComboDetailsData,
    GetComboDetailsVars
  >(GetComboDetails, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: entryId!,
      entry_level: acdLevel,
      inst_id: InstId!,
      acd_test_class_id: 0,
      query_type: AcdSubjComboQueryType.COMBO_STDS_ALLOCATED,
    },
  });

  const { dispatch } = useContext(AppContext);
  const [selectedComboNode, setSelectedComboNode] =
    useState<GetComboDetailsList | null>(null);

  const viewDetails = (node: GetComboDetailsList) => {
    setSelectedComboNode(node);
    dispatch({
      type: payloadTypes.SET_ACD_COMBO_ID,
      payload: { acdCombinationId: node.id },
    });
    setViewModal(!viewModal);
  };
  useEffect(() => {
    if (token && acdLevel && state.ActiveAcdYr) {
      GetCombinations();
    }
  }, [token, GetCombinations, acdLevel, state.ActiveAcdYr, entryId, InstId]);
  return (
    <>
      <div className="combination-sub-list">
        <div className="combination-sub-list__grids">
          {error
            ? error.message
            : data
            ? data.GetComboDetails.map((res, index) => {
                return (
                  <>
                    <div className="combination-sub-list__grid" key={index}>
                      <b>{res.subj_combo_name}</b>
                      <span>Total Subjects</span>
                      <span>{res.subject_count}</span>
                      <button
                        className="combination-sub-list__grid--view"
                        onClick={() => {
                          viewDetails(res);
                        }}
                      >
                        View Details <img src={Eye} alt="" />
                      </button>
                    </div>
                  </>
                );
              })
            : null}
        </div>
      </div>
      <Modal isOpen={viewModal} style={StudentModalStyles} ariaHideApp={false}>
        <View setModal={setViewModal} selectedComboNode={selectedComboNode} />
      </Modal>
    </>
  );
};

export default Combinationwise;

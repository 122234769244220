import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { NOTALLOCATED } from "../../../utils/constants";
import { responseType } from "../../../utils/Form.types";
import {
  PayRollCategoryNode,
  PayRollDeptNode,
  PayRollDesignationNode,
  PayRollGradeNode,
  PayRollJobTypeNode,
} from "../Types/masterDataTypes";
import { GetPayRollMasterData } from "../queries/general/list";

interface GetPayRollMasterDataList {
  pr_dept_details: PayRollDeptNode[];
  pr_designation_details: PayRollDesignationNode[];
  pr_category_details: PayRollCategoryNode[];
  pr_job_type_details: PayRollJobTypeNode[];
  pr_grade_details: PayRollGradeNode[];
}
interface GetPayRollMasterDataDetails {
  GetPayRollMasterData: GetPayRollMasterDataList;
}
interface GetPayRollMasterDataVars {
  token: string;
  inst_id: string;
}
const useEmpMasterData = () => {
  //useParams and Token
  const { token } = useToken();
  const { InstId } = useParams();
  const [departmentDropDown, setDepartmentDropDown] = useState<responseType[]>(
    []
  );
  const [designationDropDown, setDesignationDropDown] = useState<
    responseType[]
  >([]);
  const [jobTypeDropDown, setJobTypeDropDown] = useState<responseType[]>([]);
  const [gradeDropDown, setGradeDropDown] = useState<responseType[]>([]);
  const [categoryDropDown, setCategoryDropdown] = useState<responseType[]>([]);

  const [GetPayRollMasters, { data }] = useLazyQuery<
    GetPayRollMasterDataDetails,
    GetPayRollMasterDataVars
  >(GetPayRollMasterData, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  useEffect(() => {
    if (token && InstId) {
      GetPayRollMasters().then(({ data }) => {
        if (data) {
          setDepartmentDropDown(() => {
            return data.GetPayRollMasterData.pr_dept_details.reduce(
              (acc: responseType[], item: PayRollDeptNode) => {
                if (item.dept_desc !== NOTALLOCATED) {
                  acc.push({
                    label: item.dept_desc,
                    value: item.id,
                  });
                }
                return acc;
              },
              []
            );
          });
          setDesignationDropDown(() => {
            return data.GetPayRollMasterData.pr_designation_details.reduce(
              (acc: responseType[], item: PayRollDesignationNode) => {
                if (item.designation_desc !== NOTALLOCATED) {
                  acc.push({
                    label: item.designation_desc,
                    value: item.id,
                  });
                }
                return acc;
              },
              []
            );
          });
          setJobTypeDropDown(() => {
            return data.GetPayRollMasterData.pr_job_type_details.reduce(
              (acc: responseType[], item: PayRollJobTypeNode) => {
                if (item.job_type_desc !== NOTALLOCATED) {
                  acc.push({
                    label: item.job_type_desc,
                    value: item.id,
                  });
                }
                return acc;
              },
              []
            );
          });
          setGradeDropDown(() => {
            return data.GetPayRollMasterData.pr_grade_details.reduce(
              (acc: responseType[], item: PayRollGradeNode) => {
                if (item.grade_desc !== NOTALLOCATED) {
                  acc.push({
                    label: item.grade_desc,
                    value: item.id,
                  });
                }
                return acc;
              },
              []
            );
          });
          setCategoryDropdown(() => {
            return data.GetPayRollMasterData.pr_category_details.reduce(
              (acc: responseType[], item: PayRollCategoryNode) => {
                if (item.category_desc !== NOTALLOCATED) {
                  acc.push({
                    label: item.category_desc,
                    value: item.id,
                  });
                }
                return acc;
              },
              []
            );
          });
        }
      });
    }
  }, [token, InstId, GetPayRollMasters, data]);

  return {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  };
};

export default useEmpMasterData;

import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import Avatar from "../../../images/Avatar.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import { TableHeaderProps } from "../../../Types/Tables";
import useSubjTeacherAssoci, {
  AcdSubjTeacherQueryType,
} from "../hooks/useSubjTeacherAssoci";
import { AppContext } from "../../../context/context";
import usePayRollMastersConfig from "../../HR/hooks/usePayRollMastersConfig";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import { EMPTY_STRING } from "../../../utils/constants";
import useInstLabels from "../../../customhooks/general/useInstLabels";
const { Academics_Table } = require("../json/table.json");
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompleteDetails = ({ setModal }: Props) => {
  const { state } = useContext(AppContext);
  const [imageString, setImageString] = useState("");
  const { data, error } = useSubjTeacherAssoci(
    AcdSubjTeacherQueryType.ALL_SUBJECTS_BY_TEACHER_ID,
    state.employeeId,
    0
  );

  const { USE_HR_DEPARTMENT, USE_HR_DESIGNATION, USE_HR_JOBTYPE } =
    usePayRollMastersConfig();

  const { pr_emp_details } =
    data && data.GetAcdSubjectTeacherAssociations[0]
      ? data.GetAcdSubjectTeacherAssociations[0]
      : {
          pr_emp_details: {
            emp_id: "",
            emp_first_name: "",
            emp_middle_name: "",
            emp_last_name: "",
            emp_qualification: "",
            pr_designation_details: {
              designation_desc: "",
            },
            pr_job_type_details: {
              job_type_desc: "",
            },
            pr_dept_details: {
              dept_desc: "",
            },
            emp_profile_filename: "",
          },
        };
  useEffect(() => {
    if (pr_emp_details.emp_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        pr_emp_details.emp_profile_filename,
        false,
        setImageString
      );
    }
  }, [pr_emp_details.emp_profile_filename]);
  const { departmentLabel } = useInstLabels();
  return (
    <>
      <div className="allocate-teacher__details">
        <div className="allocate-teacher__details--title">
          <Title>Teacher Subject Details</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="row g-0 allocate-teacher__details--data">
          <div className="col">
            <TextField
              className="allocate-teacher__details--textfield"
              label="Employee ID"
              value={pr_emp_details.emp_id}
              disabled
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            />
            {USE_HR_DESIGNATION ? (
              <TextField
                className="allocate-teacher__details--textfield"
                label="Designation"
                disabled
                value={pr_emp_details.pr_designation_details.designation_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            ) : null}
            {USE_HR_JOBTYPE ? (
              <TextField
                className="allocate-teacher__details--textfield"
                label="Job Type"
                disabled
                value={pr_emp_details.pr_job_type_details.job_type_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            ) : null}
          </div>
          <div className="col">
            <TextField
              className="allocate-teacher__details--textfield"
              label="Name"
              disabled
              value={`${pr_emp_details.emp_first_name} ${pr_emp_details.emp_middle_name} ${pr_emp_details.emp_last_name}`}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            />
            {USE_HR_DEPARTMENT ? (
              <TextField
                className="allocate-teacher__details--textfield"
                label={departmentLabel}
                disabled
                value={pr_emp_details.pr_dept_details.dept_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            ) : null}
            <TextField
              className="allocate-teacher__details--textfield"
              label="Qualification"
              value={pr_emp_details.emp_qualification}
              disabled
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            />
          </div>
          <div className="col-2 h-100">
            <div className="allocate-teacher__details--image">
              {imageString ? (
                <img src={imageString} alt="" />
              ) : (
                <img src={Avatar} alt="" />
              )}
            </div>
          </div>
        </div>
        <Title variant="subtitle1">List of Allocated Subjects</Title>
        <div className="allocate-teacher__details--tableblock">
          {error ? (
            <b className="nodata">{error.message}</b>
          ) : (
            <TableContainer className="allocate-teacher__details--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Academics_Table.AllocateTeachers.AllocatedSubject.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.GetAcdSubjectTeacherAssociations.length ? (
                    data?.GetAcdSubjectTeacherAssociations.map(
                      ({ subject_details }, index) => (
                        <TableRow key={index}>
                          <TableCell
                            className="allocate-teacher__details--table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            className="allocate-teacher__details--table--code"
                            id="td-center"
                          >
                            {subject_details.subj_code}
                          </TableCell>
                          <TableCell
                            className="allocate-teacher__details--table--code"
                            id="td-center"
                          >
                            {subject_details.subj_board_code}
                          </TableCell>
                          <TableCell>{subject_details.subj_desc}</TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <b className="nodata">No Data Found</b>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="cancel" onClick={() => setModal(false)} />
          </div>
          <div className="col">
            <div className="student-total-count">
              Total Subjects :{" "}
              <b>
                {data && data.GetAcdSubjectTeacherAssociations
                  ? data.GetAcdSubjectTeacherAssociations.length
                  : 0}
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteDetails;

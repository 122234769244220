import React, { useContext, useEffect, useMemo, useState } from "react";
import { responseType } from "../../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useAcdDropDownData from "../../../hooks/useAcdDropDownData";
import { TableHeaders } from "../../../../../utils/Enum.types";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { AssignComponentProp, TreeType } from "../../../DailyActivities/Home";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Modal from "react-modal";
import Close from "../../../../../images/Close.svg";

import { Keys } from "../../../../../utils/Enum.keys";
import Eye from "../../../../../images/EyeWhite.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ReportType } from "./FormsReport";
import useMstInstTreeForFeedBack from "../../../../../customhooks/general/useMstInstTreeForFeedBack";
import QuestionWiseGraph from "./QuestionWiseGraph";
import { GetAcdFeedbackFormOverAllRating } from "../../../../../queries/common";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdFeedbackFormOverAllRatingData,
  GetAcdFeedbackFormOverAllRatingVars,
} from "../../../types/subject/Subject";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import DetailedFormModal from "./DetailedFormModal";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import { GetFormDataByFormId } from "../../../queries/general";
import { Button } from "../../../../../stories/Button/Button";
import { labelClasses, LabeledAutocomplete } from "../../../../../styles/AutocompleteListStyles";
interface props {
  pageType: ReportType;
}
const ViewTable = ({ pageType }: props) => {
  const { token } = useToken();

  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { InstId, formId, empId } = useParams();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [viewModal, setViewModal] = useState(false);
  const [entry_id, setEntryId] = useState(0);
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  let idsSet = new Set();

  const { data } = useMstInstTreeForFeedBack(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    Number(formId!)
  );

  const [GetOverAllGraph, { data: OverAllData }] = useLazyQuery<
    GetAcdFeedbackFormOverAllRatingData,
    GetAcdFeedbackFormOverAllRatingVars
  >(GetAcdFeedbackFormOverAllRating);
  useEffect(() => {
    if (token && formId) {
      GetOverAllGraph({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          pr_emp_id: empId ? Number(empId) : 0,
          form_name_id: Number(formId),
        },
      });
    }
  }, [token, GetOverAllGraph, empId, formId]);

  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }
    if (USE_SECTION_KEY) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);
  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);
  const AssignComponent = ({ id }: AssignComponentProp) => (
    <button
      className="acd-reports__table--view-button"
      onClick={() => {
        if (formData && !formData.node.is_academic) {
          setEntryId(id);
          setViewModal(!viewModal);
        } else {
          pageType === ReportType.Formwise
            ? navigate(`/${InstId}/academics/reports/feedback/subwise`)
            : navigate(
                `/${InstId}/academics/reports/feedback/${formId}/${empId}/${id}/questionwise`
              );
        }
      }}
    >
      View Report
      <img src={Eye} alt="" />
    </button>
  );
  const modifiedArrayForTable: TreeType[] = data
    ? data.GetAcdFeedbackFormTree.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.flatMap((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          filterThis: false,
                          form_exists: level5.form_exists,
                          total_std_count: level5.total_std_count,
                          form_filled_count: level5.form_filled_count,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count,
                        level5_name: EMPTY_STRING,
                        level1_id: level1.acd_level_1_id,
                        level2_id: level2.acd_level_2_id,
                        level3_id: level3.acd_level_3_id,
                        level4_id: level4.acd_level_4_id,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),
                        form_exists: level4.form_exists,
                        total_std_count: level4.total_std_count,
                        form_filled_count: level4.form_filled_count,
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level5_name: EMPTY_STRING,
                    level1_id: level1.acd_level_1_id,
                    level2_id: level2.acd_level_2_id,
                    level3_id: level3.acd_level_3_id,
                    level4_id: 0,
                    level5_id: 0,

                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                    form_exists: level3.form_exists,
                    total_std_count: level3.total_std_count,
                    form_filled_count: level3.form_filled_count,
                  };

                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level5_name: EMPTY_STRING,
                level1_id: level1.acd_level_1_id,
                level2_id: level2.acd_level_2_id,
                level3_id: 0,
                level4_id: 0,
                level5_id: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                form_exists: level2.form_exists,
                total_std_count: level2.total_std_count,
                form_filled_count: level2.form_filled_count,
              };

              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level5_name: EMPTY_STRING,
            level1_id: level1.acd_level_1_id,
            level2_id: 0,
            level3_id: 0,
            level4_id: 0,
            level5_id: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
            form_exists: level1.form_exists,
            total_std_count: level1.total_std_count,
            form_filled_count: level1.form_filled_count,
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];

  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  return (
    <>
      <div className="acd-reports__select row g-0">

        {USE_DEPARTMENT_KEY ? (
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                } else {
                  setDepartmentSelected(null);
                }
                setBranchSelected(null);
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(null);
                }
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                } else {
                  setClassSelected(null);
                }
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={semesterDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue as responseType);
                } else {
                  setSemesterSelected(null);
                }
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={semesterLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SECTION_KEY ? (
          <div className="col-2">
            <LabeledAutocomplete
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, sectionSelected)
              }
              className={labelClasses.inputRoot}
              options={sectionDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(null);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue as responseType);
                } else {
                  setSectionSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
      </div>
      <div className="fb-form-view__block--tableblock">
        
        <TableContainer className="fb-form-view__block--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {USE_DEPARTMENT_KEY ? (
                  <TableCell>{departmentLabel}</TableCell>
                ) : null}
                {USE_BRANCH_KEY ? <TableCell>{branchLabel}</TableCell> : null}
                {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
                {USE_SEMESTER_KEY ? (
                  <TableCell>{semesterLabel}</TableCell>
                ) : null}
                {USE_SECTION_KEY ? <TableCell>{sectionLabel}</TableCell> : null}
                <TableCell>Total Students</TableCell>
                <TableCell>Filled</TableCell>

                <TableCell>{TableHeaders.ACTION}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                if (data) {
                  return (
                    <TableRow key={i}>
                      {data.level1_name !== EMPTY_STRING ? (
                        <TableCell rowSpan={data.level1_rowspan}>
                          {data.level1_name}
                        </TableCell>
                      ) : null}
                      {data &&
                      (data.level2_name ||
                        data.level2_name !== EMPTY_STRING) ? (
                        <TableCell rowSpan={data.level2_rowspan}>
                          {data.level2_name}
                        </TableCell>
                      ) : null}
                      {data && data.level3_name !== EMPTY_STRING ? (
                        <TableCell rowSpan={data.level3_span}>
                          {data.level3_name}
                        </TableCell>
                      ) : null}
                      {data && data.level4_name !== EMPTY_STRING ? (
                        <TableCell rowSpan={data.level4_span}>
                          {data.level4_name}
                        </TableCell>
                      ) : null}
                      {data.level5_name !== EMPTY_STRING ? (
                        <TableCell>{data.level5_name}</TableCell>
                      ) : null}
                      <TableCell
                        id="td-center"
                        className="acd-reports__table--number"
                      >
                        <b className="font-blue">
                          {last_level - 1 === 1 &&
                            data.level1_name &&
                            data.form_exists &&
                            data.total_std_count}
                          {last_level - 1 === 2 &&
                            data.level2_name &&
                            data.form_exists &&
                            data.total_std_count}
                          {last_level - 1 === 3 &&
                            data.level3_name &&
                            data.form_exists &&
                            data.total_std_count}
                          {last_level - 1 === 4 &&
                            data.level4_name &&
                            data.form_exists &&
                            data.total_std_count}
                          {last_level - 1 === 5 &&
                            data.level5_name &&
                            data.form_exists &&
                            data.total_std_count}
                        </b>
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="acd-reports__table--number"
                      >
                        <b className="font-green">
                          {last_level - 1 === 1 &&
                            data.level1_name &&
                            data.form_exists &&
                            data.form_filled_count}
                          {last_level - 1 === 2 &&
                            data.level2_name &&
                            data.form_exists &&
                            data.form_filled_count}
                          {last_level - 1 === 3 &&
                            data.level3_name &&
                            data.form_exists &&
                            data.form_filled_count}
                          {last_level - 1 === 4 &&
                            data.level4_name &&
                            data.form_exists &&
                            data.form_filled_count}
                          {last_level - 1 === 5 &&
                            data.level5_name &&
                            data.form_exists &&
                            data.form_filled_count}
                        </b>
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="acd-reports__table--actions"
                      >
                        {last_level - 1 === 1 &&
                          data.level1_name &&
                          data.form_exists && (
                            <AssignComponent id={data.level1_id} />
                          )}
                        {last_level - 1 === 2 &&
                          data.level2_name &&
                          data.form_exists && (
                            <AssignComponent id={data.level2_id} />
                          )}
                        {last_level - 1 === 3 &&
                          data.level3_name &&
                          data.form_exists && (
                            <AssignComponent id={data.level3_id} />
                          )}
                        {last_level - 1 === 4 &&
                          data.level4_name &&
                          data.form_exists && (
                            <AssignComponent id={data.level4_id} />
                          )}
                        {last_level - 1 === 5 &&
                          data.level5_name &&
                          data.form_exists && (
                            <AssignComponent id={data.level5_id} />
                          )}
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="fb-form-view__block--graph">
          <QuestionWiseGraph
            data={{
              GetAcdFeedbackFormOverAllRating:
                OverAllData?.GetAcdFeedbackFormOverAllRating!,
            }}
          />
        </div>
      </div>
      <Button mode="back" onClick={() => navigate(-1)} />

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="col">
              <DetailedFormModal
                setViewModal={setViewModal}
                subjectId={0}
                entry_id={entry_id}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewTable;

import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import { msgType, optionsType } from "../../../../utils/Form.types";
import {
  getDateRangeByMonth,
  isOptionEqualToValue,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import Modal from "react-modal";
import Home from "../../Home/Index";
import Delete from "../../../../images/Delete.svg";
import Add from "../../../../images/Add.svg";
import Close from "../../../../images/Close.svg";
import useAcademicYears from "../../hooks/useAcademicYears";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import New from "./New";

import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../hooks/useAcdGeneralHolidays";

import DeleteHolidayConfig from "./Delete";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import { ActiveAcademicYearData } from "../../../../Types/Accounting";
import { useMutation } from "@apollo/client";
import { DeleteAcdInstGeneralHolidayByIds } from "../../queries/holidays/mutation";
import DeleteModal from "../../../../pages/DeleteModal";
import MessageModal from "../../../../pages/MessageModal";
import useToken from "../../../../customhooks/useToken";
import VacationList from "../../../../images/VacationList.svg";
import WeekendsList from "../../../../images/Weekendslist.svg";
import EventsList from "../../../../images/EventsList.svg";
import HolidayList from "../../../../images/HolidaysList.svg";
import { GetAcdInstGeneralHolidays } from "../../queries/holidays/list";

import CustomizedCalendar from "./CustomizedCalendar";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";
import CalendarLegends, {
  CalendarFor,
} from "../../../../pages/CalendarLegends";
import Holidays from "./List/Holidays";
import Vacations from "./List/Vacations";
import Events from "./List/Events";
import Weekends from "./List/Weekends";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import AddHoliday from "../AddHoliday";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { HolidaysListDrawer } from "../../../../styles/DrawerStyles";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: PageFor;
}
export const enum listFor {
  Holidays = "Holidays",
  Weekends = "Weekends",
  Events = "Events",
  Vacations = "Vacations",
}

export interface FlagType {
  type: listFor;
  flag: boolean;
  date: Date;
}

export const holidayQueryTypes: optionsType[] = [
  { label: "Weekend Holidays", value: holiday_query_type.WEEKEND_HOLIDAYS },
  {
    label: "General and Unexpected Holidays",
    value: holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
  },
  { label: "General Holidays", value: holiday_query_type.GENERAL_HOLIDAYS },
  {
    label: "Unexpected Holidays",
    value: holiday_query_type.UNEXPECTED_HOLIDAYS,
  },
  { label: "Vacation Holidays", value: holiday_query_type.VACATION_HOLIDAYS },
  { label: "All Holidays", value: holiday_query_type.ALL_HOLIDAYS },
  { label: "Holiday by Date", value: holiday_query_type.HOLIDAY_BY_DATE },
];
export interface ModalType {
  date: Date;
  flag: boolean;
  operation: Operation;
}
export interface AcdYrType {
  start_date: Date;
  end_date: Date;
  label: string;
  value: number;
}
const Index = ({ setModalFlag, pageType }: Props) => {
  const { user_details } = useLoggedInUserDetails();

  const [drawerFlag, setDrawerFlag] = useState<FlagType>({
    type: listFor.Events,
    flag: false,
    date: new Date(),
  });

  const { token } = useToken();
  const navigate = useNavigate();
  const { InstId } = useParams();

  const [acdYr, setAcdYr] = useState<AcdYrType | null>(null);
  const [addModal, setAddModal] = useState<ModalType>({
    date: new Date(TODAY_DATE),
    flag: false,
    operation: Operation.NONE,
  });
  const [addWeekendsModal, setAddWeekendsModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [holidayDeleteModal, setHolidayDeleteModal] = useState(false);
  const [acdYearOptions, setAcdYearOptions] = useState<AcdYrType[]>([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const { academicYearResponse } = useAcademicYears();
  const { serverDate } = useServerDateandTime();
  const {
    activeAcademicYearData: { data: activeAcdData, loading: activeAcdLoading },
  } = useActiveAcademicYear();

  const filteredAcdyearDetails: ActiveAcademicYearData =
    academicYearResponse.data?.GetAcdYrsByInstId.find(
      ({ id }) => id === acdYr?.value
    )!;

  const { endDate, startDate } = getDateRangeByMonth(
    new Date(addModal.date).getFullYear(),
    new Date(addModal.date).getMonth()
  );
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    acdYr ? acdYr.value : 0,
    endDate.toString(),
    startDate.toString(),
    EMPTY_STRING,
    holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS
  );

  const [DeleteHoliday] = useMutation(DeleteAcdInstGeneralHolidayByIds, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const HandleDelete = (id: Number) => {
    DeleteHoliday({
      variables: {
        token,
        ids: id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYr?.value,
            input: {
              end_date: toIsoDate(endDate.toString()),
              in_between_flag: true,
              start_date: toIsoDate(startDate.toString()),
              holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
            },
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.HOLIDAY_DATE,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYr?.value,
            input: {
              end_date: toIsoDate(endDate.toString()),
              in_between_flag: true,
              start_date: toIsoDate(startDate.toString()),
              holiday_query_type:
                holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
            },
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.HOLIDAY_DATE,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Holiday Deleted Successfully",
          operation: Operation.DELETE,
        });
        setHolidayDeleteModal(!holidayDeleteModal);
      }
    });
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      // handleClear();
    }
    setMessage({
      flag: !message.flag,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (academicYearResponse.data && !academicYearResponse.loading) {
      setAcdYearOptions(
        academicYearResponse.data.GetAcdYrsByInstId.map((edge) => ({
          start_date: new Date(edge.acd_st_date),
          end_date: new Date(edge.acd_end_date),
          label: edge.acd_yr,
          value: edge.id,
        }))
      );
    }
  }, [academicYearResponse.data, academicYearResponse.loading]);

  useEffect(
    () => {
      if (activeAcdData && !activeAcdLoading) {
        setAcdYr({
          label: activeAcdData.GetAcdYrActiveByInstId.acd_yr,
          value: activeAcdData.GetAcdYrActiveByInstId.id,
          end_date: new Date(activeAcdData.GetAcdYrActiveByInstId.acd_end_date),
          start_date: new Date(
            activeAcdData.GetAcdYrActiveByInstId.acd_st_date
          ),
        });
      }
    },
    // eslint-disable-next-line
    [activeAcdData, activeAcdLoading]
  );
  useEffect(
    () => {
      if (serverDate) {
        setAddModal({
          ...addModal,
          date: new Date(serverDate),
        });
      }
    },
    // eslint-disable-next-line
    [serverDate]
  );

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>Calendar</Title>
      <div
        className={
          pageType === PageFor.MODAL ? "holiday-entry--modal" : "holiday-entry"
        }
      >
        {pageType === PageFor.GENERAL && (
          <div className="row g-0 holiday-entry__details">
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                value={acdYr}
                options={acdYearOptions}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as AcdYrType, acdYr)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setAcdYr(newValue as AcdYrType);
                  } else {
                    setAcdYr({
                      label: activeAcdData?.GetAcdYrActiveByInstId.acd_yr!,
                      value: activeAcdData?.GetAcdYrActiveByInstId.id!,
                      end_date: new Date(
                        activeAcdData?.GetAcdYrActiveByInstId.acd_st_date!
                      ),
                      start_date: new Date(
                        activeAcdData?.GetAcdYrActiveByInstId.acd_end_date!
                      ),
                    });
                  }
                }}
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Academic Year"
                    fullWidth
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="col"></div>
            <div className="col-3">
              <Button mode="copy">Include Default Holidays</Button>
            </div>
          </div>
        )}

        <div
          className={
            pageType === PageFor.MODAL
              ? "row g-0 holiday-entry__data--modal"
              : "row g-0 holiday-entry__data"
          }
        >
          <div className="col holiday-entry__data--left">
            {acdYr ? (
              <CustomizedCalendar
                acdYr={acdYr}
                date={addModal.date}
                setAddmodal={setAddModal}
              />
            ) : (
              <b className="nodata"> Please select academic Year</b>
            )}
          </div>
          <div className="col holiday-entry__data--right">
            <Title variant="subtitle1">List of Holidays</Title>
            <ul className="holiday-entry__data--holiday-list">
              {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
                (edge, index) => {
                  return (
                    <li key={index}>
                      <Label>{index + 1}</Label>
                      <div className="holiday-entry__data--holiday-list--events">
                        <div>
                          <span>{toStandardDate(edge.node.holiday_date)}</span>
                          <br />
                          <b>{edge.node.holiday_desc}</b>
                        </div>
                        <div className="holiday-entry__data--holiday-list--desc">
                          {edge.node.holiday_duration}
                        </div>
                      </div>
                      <img
                        src={Delete}
                        alt="/"
                        onClick={() => {
                          setDeleteId(edge.node.id);
                          setHolidayDeleteModal(!holidayDeleteModal);
                        }}
                      />
                    </li>
                  );
                }
              )}
            </ul>
            <div className="holiday-entry__data--right--icons">
              <CustomTooltip
                title="All Weekend's List"
                placement="bottom"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={WeekendsList}
                  alt=""
                  onClick={() =>
                    setDrawerFlag({
                      type: listFor.Weekends,
                      flag: true,
                      date: addModal.date,
                    })
                  }
                />
              </CustomTooltip>

              <CustomTooltip
                title="All Event's List"
                placement="bottom"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={EventsList}
                  alt=""
                  onClick={() =>
                    setDrawerFlag({
                      type: listFor.Events,
                      flag: true,
                      date: addModal.date,
                    })
                  }
                />
              </CustomTooltip>

              <CustomTooltip
                title="All Vacation's List"
                placement="bottom"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={VacationList}
                  alt=""
                  onClick={() =>
                    setDrawerFlag({
                      type: listFor.Vacations,
                      flag: true,
                      date: addModal.date,
                    })
                  }
                />
              </CustomTooltip>

              <CustomTooltip
                title="All Holiday's List"
                placement="bottom"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={HolidayList}
                  alt=""
                  onClick={() =>
                    setDrawerFlag({
                      type: listFor.Holidays,
                      flag: true,
                      date: addModal.date,
                    })
                  }
                />
              </CustomTooltip>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-5">
            {pageType === PageFor.MODAL ? (
              <Button mode="cancel" onClick={() => setModalFlag(false)} />
            ) : (
              <>
                <Button
                  onClick={() => setAddWeekendsModal(!addWeekendsModal)}
                  disabled={!acdYr}
                >
                  <img src={Add} alt="/" /> Add Weekends
                </Button>
                <Button
                  mode="delete"
                  disabled={!acdYr}
                  onClick={() => setDeleteModal(!deleteModal)}
                >
                  Weekends
                </Button>
                <Button mode="back" onClick={() => navigate(-1)} />
              </>
            )}
          </div>
          <div className="col">
            <CalendarLegends useCalendarIn={CalendarFor.GENERAL} />
          </div>
        </div>
      </div>
      {/* Add-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addWeekendsModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <New
              setModalFlag={setAddWeekendsModal}
              acd_yr={filteredAcdyearDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddWeekendsModal(!addWeekendsModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DeleteHolidayConfig
              setModalFlag={setDeleteModal}
              acd_yr={filteredAcdyearDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal.flag}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DateWiseHolidayEntry
              date={addModal.date}
              setInsertModal={setAddModal}
              moduleType={LicenseTypes.ACADEMIC}
              prAcdYearId={null}
              acdYearId={filteredAcdyearDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setAddModal({
                  ...addModal,
                  flag: false,
                })
              }
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal> */}
      <DeleteModal
        modalFlag={holidayDeleteModal}
        setModalFlag={setHolidayDeleteModal}
        handleDelete={HandleDelete}
        id={deleteId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
      <HolidaysListDrawer
        anchor="right"
        open={drawerFlag.flag}
        onClose={() => {
          setDrawerFlag({
            type: listFor.Holidays,
            flag: false,
            date: addModal.date,
          });
        }}
      >
        {drawerFlag.type === listFor.Events ? (
          <Events acdYr={acdYr!} setDrawerFlag={setDrawerFlag} />
        ) : drawerFlag.type === listFor.Vacations ? (
          <Vacations acdYr={acdYr!} setDrawerFlag={setDrawerFlag} />
        ) : drawerFlag.type === listFor.Weekends ? (
          <Weekends acdYr={acdYr!} setDrawerFlag={setDrawerFlag} />
        ) : (
          <Holidays acdYr={acdYr!} setDrawerFlag={setDrawerFlag} />
        )}
      </HolidaysListDrawer>

      <HolidaysListDrawer
        anchor="right"
        open={addModal.flag}
        // onClose={() => {
        //   setAddModal({
        //     ...addModal,
        //     flag: false,
        //   });
        // }}
      >
        <AddHoliday
          InsertModal={addModal}
          setInsertModal={setAddModal}
          acdYearId={filteredAcdyearDetails}
        />
      </HolidaysListDrawer>
    </>
  );
};

export default Index;

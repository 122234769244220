import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { GetParents } from "../queries/parent/list";
import { Direction, ParentQueryType, SortBy } from "../utils/Enum.types";
import useToken from "./useToken";
export interface ParentNode {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  parent_address: string;
  parent_place: string;
  parent_state: string;
  parent_pin: string;
  parent_email: string;
  parent_mobile: string;
  parent_occup: string;
  parent_qualification: string;
  parent_aadhar: string;
  parent_type: string;
  inst_id: number;
  is_login_created: boolean;
}
export interface ParentListEdge {
  node: ParentNode;
  cursor: string;
}

export interface ParentListPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}

export interface GetParentsDetails {
  totalCount: number;
  pageInfo: ParentListPageInfo;
  edges: ParentListEdge[];
}
export interface GetParentsVars {
  name: string;
  first: number | null;
  token: string;
  queryType: ParentQueryType;
  inst_id: number | string;
  after: string | null;
  direction: string;
  sortBy: string;
}
export interface GetParentsData {
  GetParents: GetParentsDetails;
}
const useParentsData = (
  first: number | null,
  queryType: ParentQueryType,
  name: string
) => {
  const { InstId } = useParams();
  const { token } = useToken();

  const [GetParentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetParentsData,
    GetParentsVars
  >(GetParents);
  useEffect(() => {
    if (token) {
      GetParentsData({
        variables: {
          after: null,
          direction: Direction.ASC,
          first,
          inst_id: InstId!,
          name,
          sortBy: SortBy.FIRST_NAME,
          queryType,
          token,
        },
      });
    }
  }, [GetParentsData, InstId, first, token, name, queryType]);
  return { parentsData: { data, loading, error, fetchMore } };
};

export default useParentsData;

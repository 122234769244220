import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { Title } from "../../../../stories/Title/Title";
import { RoutesDetailsTitleProps } from "../../../../Types/Titles";
import Avatar from "../../../../images/Avatar.svg";
import DataFetch from "../../../../images/Edit.svg";
import ActiveRoute from "../../../../images/ActiveRoute.svg";
import ChoosedRoute from "../../../../images/ChoosedRoute.svg";
import { Label } from "../../../../stories/Label/Label";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Button } from "../../../../stories/Button/Button";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import StudentList from "../../Student/List";
import {
  Operation,
  PageFor,
  StageDetails,
  StudentReportType,
} from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../../utils/constants";
import { payloadTypes } from "../../../../context/reducer";
import { msgType, responseType } from "../../../../utils/Form.types";
import useAcdStudentsAdmNumber from "../../../Academics/hooks/useAcdStudentsAdmNumber";
import { AppContext } from "../../../../context/context";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DeleteTransportStudent } from "../../queries/mutations";
import MessageModal from "../../../../pages/MessageModal";
import useToken from "../../../../customhooks/useToken";
import DeleteModal from "../../../../pages/DeleteModal";
import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
} from "../../paginationTypes";
import { GetTransportStudent } from "../../queries/list";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { toInputStandardDate } from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { useParams } from "react-router-dom";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useTransportTableJson from "../../json/useTransportTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { Transport_Title } = require("../../json/title.json");

interface Props {
  modalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Delete = ({ modalFlag }: Props) => {
  const { token } = useToken();
  const { state, dispatch } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const { InstId } = useParams();
  const { Transport_Table } = useTransportTableJson();

  const [studentModal, setStudentModal] = useState(false);
  const [searchAdmNo, setSearchAdmNo] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { studentFormData } = useStudentDatabyId();
  const { serverDate } = useServerDateandTime();

  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    searchAdmNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );
  const [DeleteStudentRoute] = useMutation(DeleteTransportStudent, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [GetTransportStudentData, { data }] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: state.studentId,
    },
  });
  const ChoosedRouteData = [
    {
      name: StageDetails.ROUTE,
      route:
        data?.GetTransportStudent.route_details.route_master_details.route_desc,
    },
    {
      name: StageDetails.STAGE,
      route: data?.GetTransportStudent.route_details.route_stage_desc,
    },
    {
      name: StageDetails.LEDGER,
      route: data?.GetTransportStudent.acct_ldgr_details.ldgr_desc,
    },
  ];
  const HandleDelete = (id: Number) => {
    DeleteStudentRoute({
      variables: {
        token,
        student_id: id,
        inst_id: InstId,
        user_details,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Deleted Student Associated Route",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleBack = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (state.studentId) {
      GetTransportStudentData({
        variables: {
          token,
          student_id: state.studentId,
        },
      });
    }
  }, [GetTransportStudentData, state.studentId, token]);
  const { branchLabel ,categoryLabel} = useInstLabels();
  return (
    <>
      <Title>
        {Transport_Title.MasterData.RoutesDetails.map(
          (title: RoutesDetailsTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Delete}</React.Fragment>;
          }
        )}
      </Title>
      <div className="transport-assign-route__delete">
        <div className="transport-assign-route__details row g-0">
          <div className="col">
            <div className="transport-assign-route__details--image-flex">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber ?? EMPTY_RESPONSETYPE_OBJECT}
                isOptionEqualToValue={(option) =>
                  (option as responseType)?.value === state.studentId
                }
                openOnFocus
                freeSolo
                autoHighlight
                forcePopupIcon
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Admission Number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchAdmNo(e.target.value)
                    }
                    autoFocus
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => {
                  setStudentModal(!studentModal);
                }}
              />
            </div>
            <TextField
              label="Reg No."
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.reg_number}
              disabled
            />
            <TextField
              label="Academic Year"
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.acd_yr}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                studentFormData.first_name +
                " " +
                studentFormData.middle_name +
                " " +
                studentFormData.last_name
              }
              disabled
            />
            <TextField
              label="Father Name"
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.father_name}
              disabled
            />
            <TextField
              label={branchLabel}
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.branch}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Date"
              className="transport-assign-route__details--date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={toInputStandardDate(serverDate)}
              disabled
              type="date"
            />
            <TextField
               label={categoryLabel}
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.category}
              disabled
            />
            <TextField
              label="Mobile No."
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.std_mobile}
              disabled
            />
          </div>
          <div className="col-1 transport-assign-route__details--studentprofile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="transport-assign-route__datablock">
          <div className="transport-assign-route__datablock--left">
            <div className="row g-0">
              <div className="col">
                <TextField
                  label="Bus Route"
                  className="transport-assign-route__details--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={
                    data?.GetTransportStudent?.route_details
                      ?.route_master_details.route_desc ?? EMPTY_STRING
                  }
                  disabled
                />
              </div>
              <div className="col">
                <Title variant="subtitle2">
                  <img src={ActiveRoute} alt="/" />
                  Active Route
                </Title>
              </div>
            </div>
            <div className="transport-assign-route__tableblock">
              <TableContainer className="transport-route-details__delete--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Transport_Table.MasterData.RouteDetails.filter(
                        (th: TableHeaderProps) =>
                          th.labelName !== "Actions" && th.labelName !== "Sl"
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {
                          data?.GetTransportStudent.route_details
                            .route_stage_desc
                        }
                      </TableCell>
                      <TableCell className="transport-route-details__table--ledger">
                        {data?.GetTransportStudent.acct_ldgr_details.ldgr_desc}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="transport-assign-route__datablock--right">
            <TextField
              label="Student Stage At"
              className="transport-assign-route__details--textfield"
              value={
                data?.GetTransportStudent.route_details.route_stage_desc ??
                EMPTY_STRING
              }
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
            />
            <div className="transport-assign-route__choosed-route">
              <Title variant="subtitle2">
                <img src={ChoosedRoute} alt="/" />
                Choosed Route
              </Title>
              <ul className="transport-assign-route__choosed-route--list">
                {ChoosedRouteData.map((data, index) => {
                  return (
                    <li key={index + 1}>
                      <Label>{data.name}</Label>
                      <Label>:</Label>
                      <Label variant="LabelPrimary">{data.route}</Label>
                    </li>
                  );
                })}
              </ul>
              <div className="transport-assign-route__choosed-route--label-grid">
                <Label>Start Date</Label>
                <TextField
                  label="Year"
                  className="transport-assign-route__details--date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  type="date"
                />
                <Label>End Date</Label>
                <TextField
                  label="Year"
                  className="transport-assign-route__details--date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>
        <Button mode="delete" onClick={() => setDeleteModal(!deleteModal)} />
        <Button mode="clear" />
        <Button mode="cancel" onClick={() => modalFlag(false)} />
      </div>
      {/* student-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              setStudentModal={setStudentModal}
              queryType={StudentReportType.GENERAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={data?.GetTransportStudent?.id!}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Delete;

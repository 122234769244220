import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Delete from "../../../../images/Delete.svg";
import Edit from "../../../../images/EditProfile.svg";
import { Direction, Operation, PageFor } from "../../../../utils/Enum.types";
import useToken from "../../../../customhooks/useToken";
import { msgType } from "../../../../utils/Form.types";
import usePurchasesMasterData from "../hooks/usePurchasesMasterData";
import { useMutation } from "@apollo/client";
import {
  AddAcctStockItemUom,
  DeleteAcctStockItemUomById,
} from "../../queries/purchages/mutation";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import { GetAcctStockItemUoms } from "../../queries/purchages/queries";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import DeleteModal from "../../../../pages/DeleteModal";
import useAcctTableJson from "../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnitOfMeasurement = ({ pageType, setModalFlag }: Props) => {
  const { Accounts_Table } = useAcctTableJson();
  const navigate = useNavigate();
  const [desc, setDesc] = useState("");
  const { token } = useToken();
  const { InstId } = useParams();
  const [operation, setOperation] = useState(Operation.CREATE);
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [umId, setUmId] = useState(0);
  const { user_details } = useLoggedInUserDetails();

  const { UOM } = usePurchasesMasterData();

  const [AddGroup, { loading: creationLoading }] = useMutation(
    AddAcctStockItemUom,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [DeleteCategory, { loading: deletionLoading }] = useMutation(
    DeleteAcctStockItemUomById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setOperation(Operation.CREATE);
      setModalFlag(false);
      setDesc("");
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleCreation = (e: React.FormEvent) => {
    e.preventDefault();
    AddGroup({
      variables: {
        token,
        input: {
          item_uom_desc: removeMoreSpace(desc),
          item_uom_in_use: true,
        },
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctStockItemUoms,
          variables: {
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            orderBy: { direction: Direction.ASC },
            query_type: "ALL_ITEM_UOMS",
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Category Created Sucessfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleDelete = (id: number) => {
    DeleteCategory({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctStockItemUoms,
          variables: {
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            orderBy: { direction: Direction.ASC },
            query_type: "ALL_ITEM_UOMS",
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Category Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>Unit of Measurement</Title>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "purchase-entry-masters"
            : "purchase-entry-masters__modal"
        }
        onSubmit={handleCreation}
      >
        <div className="row g-0 purchase-entry-masters__datablock">
          <div className="col purchase-entry-masters__datablock--frame-left">
            <Title variant="subtitle1">Add New Unit of Measurement</Title>
            <div className="purchase-entry-masters__tableblock">
              <div className="label-grid">
                <Label>Unit of Measurement Description</Label>
                <Input
                  onChange={(e) => setDesc(e.target.value)}
                  required
                  autoFocus
                  value={desc}
                />
              </div>
            </div>
          </div>
          <div className="col purchase-entry-masters__datablock--frame-right">
            <div className="row g-0 purchase-entry-masters__datablock--title">
              <div className="col-3"></div>
              <div className="col">
                <Title variant="subtitle1">List of Unit of Measurements</Title>
              </div>
              <div className="col-3">
                <Input id="search" placeholder="Search..." />
              </div>
            </div>
            <div className="purchase-entry-masters__tableblock">
              <TableContainer className="purchase-entry-masters__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.PurchaseEntry.Masters.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index}>{th.labelName}</TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {UOM.Measuremeants?.GetAcctStockItemUoms.edges.map(
                      (edge, index) => {
                        return (
                          <TableRow key={edge.node.id}>
                            <TableCell
                              className="purchase-entry-masters__table--slno"
                              id="td-center"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell>{edge.node.item_uom_desc}</TableCell>
                            <TableCell
                              className="purchase-entry-masters__table--actions"
                              id="td-center"
                            >
                              <img
                                src={Edit}
                                alt=""
                                onClick={() => {
                                  setUmId(edge.node.id);
                                  setOperation(Operation.UPDATE);
                                }}
                              />
                              <img
                                src={Delete}
                                alt=""
                                onClick={() => {
                                  setUmId(edge.node.id);
                                  setDeleteModal(!deleteModal);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Button mode="save" />

        <Button mode="clear" />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </form>
      <LoadingModal flag={creationLoading || deletionLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={umId}
      />
    </>
  );
};

export default UnitOfMeasurement;

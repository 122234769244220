import React, { useState } from 'react'
import { msgType } from '../../../../utils/Form.types';
import { DEFAULT_TIME, EMPTY_STRING, emptyMessageType } from '../../../../utils/constants';
import useChannelConfigs from '../../hooks/useChannelConfigs';
import { Title } from '../../../../stories/Title/Title';
import StudentDetails from '../../../Master/Student/Dashboard/StudentDetails';
import StudentAcademicDetails from '../../../Master/Student/StudentPreview/StudentAcademicDetails';
import Close from "../../../../images/Close.svg";
import { ChannelQueryType } from '../../../Accounts/common/Enum.types';
import { Button } from '../../../../stories/Button/Button';
import ReactModal from 'react-modal';
import SendMessage from './SendMessage';
import MessageModal from '../../../../pages/MessageModal';
import { Operation } from '../../../../utils/Enum.types';
import { AccountingLedgerModalStyles } from '../../../../styles/ModalStyles';
import MessageList from './Index';
import Send from "../../../../images/Send.svg";
interface props{
    setModal:React.Dispatch<React.SetStateAction<boolean>>
}
const ForIndividual = ({setModal}:props) => {
    const [sendMessage, setSendMessage] = useState(false);

    const { enableIndividualChannels } = useChannelConfigs();
  
    const [message, setMessage] = useState<msgType>(emptyMessageType);
  return (
    <>
       <div className="message-for-individual">
        <div className="message-for-individual__title">
          <Title>Individual Message</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <StudentAcademicDetails/>
        {enableIndividualChannels ? (
          <>
            <div className="message-for-individual__message-list">
              <MessageList channel={ChannelQueryType.INDIVIDUAL} />
            </div>
            <Button onClick={() => setSendMessage(!sendMessage)}>
              <img src={Send} alt="/" />
              Compose Message
            </Button>
          </>
        ) : null}

        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>

      <ReactModal
        isOpen={sendMessage}
        ariaHideApp={false}
        style={AccountingLedgerModalStyles}
      >
        <SendMessage
          setModalFlag={setSendMessage}
          Channel={ChannelQueryType.INDIVIDUAL}
          setMessage={setMessage}
          operation={Operation.CREATE}
      
        />
      </ReactModal>

      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.CREATE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  )
}

export default ForIndividual

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import { useMutation } from "@apollo/client";

import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";

import InstitutionTabs from "../../routes/InstitutionTabs";
import Settings from "../../../../images/Settings.svg";

import ConfigurationSettings from "../../configurations/general/Index";

import {
  ReOrderInstDept,
  UpdateInstDept,
} from "../../../../queries/institution/mutations/update";

import { msgType } from "../../../../utils/Form.types";
import { Operation, PageNumbers } from "../../../../utils/Enum.types";

import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../../styles/ModalStyles";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import LoadingModal from "../../../../pages/LoadingModal";

import { DeleteInstDeptById } from "../../../../queries/institution/mutations/delete";

import DeleteModal from "../../../../pages/DeleteModal";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import {
  DepartmentList,
  DepartmentListDataByInstId,
  DepartmentListVarsByInstId,
} from "../../../../Types/Student";
import {
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Edit from "../../../../images/EditProfile.svg";
import DeleteImg from "../../../../images/Delete.svg";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { ByClassIds } from "../../../../queries/institution/sem/list/byid";
import { ByBranchIds } from "../../../../queries/institution/classes/list/byid";
import { ByDeptIds } from "../../../../queries/institution/branch/list/byid";
import { useLazyQuery } from "@apollo/client";

import {
  GetDeptsByInstId,
  ByNode,
} from "../../../../queries/institution/department/list/byid";
import { reOrderProcess } from "../../../../utils/UtilFunctions";
import { Form, Formik } from "formik";
import { departmentValidation } from "../../../../utils/validationRules";
import { departmentType } from "../../../../utils/Form.types";
import { AddInstDept } from "../../../../queries/institution/mutations/new";
import { InstitutionDetailsFormLabelsType } from "../../../../Types/Institution/json";
import Input from "../../../../components/common/Input/Index";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import TreeView from "../TreeView";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";

const { DepartmentFormLabels } = require("../../../../json/config.json");

const Department = () => {
  //useParam, GlobalStates and useNavigate
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { USE_ADMISSIONS } = useUserRightsByEmpId();
  const { user_details } = useLoggedInUserDetails();
  const [addModal, setAddModal] = useState(false);
  const { Masters_Table } = useMasterTableJson();

  //useStates for Data

  const [localItems, setLocalItems] = useState<DepartmentList[]>([]);
  // eslint-disable-next-line
  const [items, setitems] = useState<DepartmentList[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [departmentId, setDepartmentId] = useState(0);
  const [formData, setFormData] = useState<departmentType>({
    dept_desc: "",
    shortdescription: "",
  });
  //useStates for Flags
  const [reOrder, setReOrder] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  //useRefs
  const nameRef = document.getElementsByName(
    "dept_desc"
  )[0] as HTMLInputElement;

  //flags for sucess model
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [configurationModal, setConfigurationModal] = useState(false);

  //Mutations
  const [NewDepartment, { loading: creationLoading }] = useMutation(
    AddInstDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [departmentdata] = useLazyQuery(ByNode);
  const [updateDepartment, { loading: updationLoading }] = useMutation(
    UpdateInstDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [ReOrderDepartment, { loading: reorderLoading }] = useMutation(
    ReOrderInstDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [DeleteDepartment, { loading: DeleteLoading }] = useMutation(
    DeleteInstDeptById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //customHooks

  const [GetDepartments, { data: departmentDropDown }] = useLazyQuery<
    DepartmentListDataByInstId,
    DepartmentListVarsByInstId
  >(GetDeptsByInstId, {
    variables: {
      inst_id: InstId!,
      token,
    },
  });

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.DEPARTMENT_PAGE);
  const { USE_BRANCH_KEY, USE_CLASS_KEY, USE_SECTION_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();

  //Methods

  const HandleDelete = async (dept_id: number) => {
    setDeleteModal(!deleteModal);
    await DeleteDepartment({
      variables: {
        token,
        dept_id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetDeptsByInstId,
          variables: {
            inst_id: InstId,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Department Deleted successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setOperation(Operation.CREATE);
  };

  const HandleSaveDragAndDrop = () => {
    // eslint-disable-next-line
    ReOrderDepartment({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.id,
          dept_idx: index + 1,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetDeptsByInstId,
          variables: {
            token,
            inst_id: InstId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re-Order Successful",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });

    setReOrder(!reOrder);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };

  //Lazy Queries
  const [BranchData] = useLazyQuery(ByDeptIds);
  const [ClassData] = useLazyQuery(ByBranchIds);
  const [SemesterData] = useLazyQuery(ByClassIds);

  //Lazy Queries for Student counts By DeptId

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) return;

    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: DepartmentList[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };

  useEffect(() => {
    if (departmentDropDown)
      setLocalItems(
        departmentDropDown.GetDeptsByInstId.filter((dept) => dept != null)
      );
    // eslint-disable-next-line
  }, [departmentDropDown]);

  //New Department
  // eslint-disable-next-line
  const HandleSubmit = async () => {
    await NewDepartment({
      variables: {
        token,
        input: {
          inst_id: InstId,
          dept_desc: removeMoreSpace(formData.dept_desc),
          dept_short_desc: removeMoreSpace(formData.shortdescription),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetDeptsByInstId,
          variables: {
            inst_id: InstId,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Department Added successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setFormData({
      dept_desc: "",
      shortdescription: "",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const HandleEdit = async () => {
    await updateDepartment({
      variables: {
        token,
        id: departmentId,
        dept_desc: removeMoreSpace(formData.dept_desc),
        dept_short_desc: removeMoreSpace(formData.shortdescription),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetDeptsByInstId,
          variables: {
            inst_id: InstId,
            token,
          },
        },
        {
          query: ByNode,
          variables: { dept_id: departmentId, token },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Department Updated successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setFormData({
      dept_desc: "",
      shortdescription: "",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const handleClear = () => {
    setFormData({
      dept_desc: "",
      shortdescription: "",
    });
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };

  useEffect(() => {
    if (departmentId && operation === Operation.UPDATE && token) {
      departmentdata({
        variables: {
          dept_id: departmentId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          setFormData({
            dept_desc: data.node.dept_desc,
            shortdescription: data.node.dept_short_desc,
          });
        }
      });
    }
  }, [departmentId, departmentdata, token, operation]);
  useEffect(() => {
    if (token) {
      GetDepartments();
    }
  }, [token, GetDepartments]);
  const { departmentLabel } = useInstLabels();
  return (
    <>
      <InstitutionTabs />

      <div className="row g-0">
        <div className="col">
          <Title>{departmentLabel}</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>
      <div className="masterconfig">
        <div className="row g-0 masterconfig__details frame-space">
          <div className="col booktype-left h-100">
            <Title variant="subtitle1">List of {departmentLabel}</Title>

            <TableContainer className="masterconfig__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {reOrder ? <TableCell></TableCell> : null}
                    {Masters_Table.InstitutionDetails.Departments.Table_Headers.filter(
                      ({ labelName }: TableHeaderProps) =>
                        !(labelName === "Actions" && reOrder)
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TableCell className={th.className}>
                            {th.labelName}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <DragDropContext onDragEnd={handleDragAndDrop}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {localItems?.map((response, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!reOrder}
                          >
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <TableRow
                                  key={index}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  {reOrder ? (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      id="td-center"
                                      className="institution__table--slno"
                                    >
                                      =
                                    </TableCell>
                                  ) : null}
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="institution__table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  {USE_BRANCH_KEY ? (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      onClick={() =>
                                        navigate(`${response.id}/branch`)
                                      }
                                      className="institution__table--name"
                                    >
                                      {response?.dept_desc}
                                    </TableCell>
                                  ) : USE_CLASS_KEY ? (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      onClick={async () => {
                                        const branchResult = await BranchData({
                                          variables: {
                                            dept_id: response.id,
                                            token,
                                          },
                                        });
                                        navigate(
                                          `${response.id}/branch/${branchResult.data.GetBranchNamesByDeptIds[0]?.id}/class`
                                        );
                                      }}
                                    >
                                      {response.dept_desc}
                                    </TableCell>
                                  ) : USE_SEMESTER_KEY ? (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      onClick={async () => {
                                        const branchResult = await BranchData({
                                          variables: {
                                            dept_id: response.id! ?? "",
                                            token,
                                          },
                                        });

                                        const classResult = await ClassData({
                                          variables: {
                                            branch_id: [
                                              branchResult.data
                                                .GetBranchNamesByDeptIds[0]
                                                .id! ?? "",
                                            ],
                                            token,
                                          },
                                        });
                                        navigate(
                                          `${response.id}/branch/${branchResult.data.GetBranchNamesByDeptIds[0].id}/class/${classResult.data.GetClassNamesByBranchIds[0]?.id}/semester`
                                        );
                                      }}
                                    >
                                      {response.dept_desc}
                                    </TableCell>
                                  ) : USE_SECTION_KEY ? (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      onClick={async () => {
                                        const branchResult = await BranchData({
                                          variables: {
                                            dept_id: [response.id!],
                                            token,
                                          },
                                        });

                                        const classResult = await ClassData({
                                          variables: {
                                            branch_id: [
                                              branchResult.data
                                                .GetBranchNamesByDeptIds[0].id!,
                                            ],
                                            token,
                                          },
                                        });

                                        const semesterResult =
                                          await SemesterData({
                                            variables: {
                                              token,
                                              class_id: [
                                                classResult.data
                                                  .GetClassNamesByBranchIds[0]
                                                  ?.id,
                                              ],
                                            },
                                          });

                                        navigate(
                                          `${response.id}/branch/${branchResult.data.GetBranchNamesByDeptIds[0].id}/class/${classResult.data.GetClassNamesByBranchIds[0]?.id}/semester/${semesterResult.data.GetSemesterNamesByClassIds[0].id}/section`
                                        );
                                      }}
                                    >
                                      {response.dept_desc}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                    >
                                      {response.dept_desc}
                                    </TableCell>
                                  )}
                                  {reOrder === false ? (
                                    <TableCell
                                      id="td-center"
                                      {...draggableProvided.dragHandleProps}
                                      className="institution__table--action"
                                    >
                                      <>
                                        {USE_ADMISSIONS.details?.edit && (
                                          <img
                                            src={Edit}
                                            alt="/"
                                            onClick={() => {
                                              setDepartmentId(response.id);
                                              setOperation(Operation.UPDATE);
                                              setAddModal(!addModal);
                                            }}
                                          />
                                        )}
                                        {USE_ADMISSIONS.details?.delete && (
                                          <img
                                            src={DeleteImg}
                                            alt="/"
                                            onClick={() => {
                                              setDepartmentId(response.id);
                                              setDeleteModal(!deleteModal);
                                            }}
                                          />
                                        )}
                                      </>
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              );
                            }}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </div>
          <div className="col booktype-right h-100">
            <TreeView />
          </div>
        </div>
        <div className="masterconfig__buttons">
          <Button mode="addnew" onClick={() => setAddModal(!addModal)} />
          <>
            {localItems?.length > 1 ? (
              reOrder ? (
                <>
                  <Button
                    mode="save-order"
                    type="button"
                    onClick={HandleSaveDragAndDrop}
                  />
                  <Button
                    mode="clear"
                    type="button"
                    onClick={() => {
                      setReOrder(!reOrder);
                      setLocalItems(
                        departmentDropDown?.GetDeptsByInstId.filter(
                          (response) => response !== null
                        )!
                      );
                    }}
                  />
                </>
              ) : (
                <Button
                  mode="reorder"
                  type="button"
                  onClick={() => setReOrder(!reOrder)}
                />
              )
            ) : null}
          </>
          <Button mode="back" type="button" onClick={() => navigate(-1)} />
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1">
              {operation === Operation.UPDATE
                ? `Update ${departmentLabel}`
                : `Add ${departmentLabel}`}
            </Title>

            <Formik
              initialValues={formData}
              validationSchema={departmentValidation}
              onSubmit={
                operation === Operation.UPDATE ? HandleEdit : HandleSubmit
              }
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    {DepartmentFormLabels?.map(
                      (
                        label: InstitutionDetailsFormLabelsType,
                        index: React.Key
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={label.LabelName}
                              name={label.inputName}
                              type={label.dataType}
                              values={formData[label.inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              required={label.required}
                              autoFocus={label.autoFocus}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="masterconfig__buttons">
                      <Button mode="save" type="submit" />

                      <Button
                        mode="clear"
                        type="button"
                        onClick={handleClear}
                      />

                      <Button
                        mode="cancel"
                        type="button"
                        onClick={() => {
                          setAddModal(!addModal);
                          handleClear();
                        }}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setAddModal(!addModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal
        flag={
          updationLoading || creationLoading || reorderLoading || DeleteLoading
        }
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={departmentId}
      />

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.DEPARTMENT_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Department;

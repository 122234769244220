// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import EduateHome from "../Modules/Eduate/Home/Index";
import GlobalConfigFiles from "../Modules/Eduate/configurations/globalconfiguration/Index";
import Settings from "../Modules/Eduate/configurations/PredefinedData/Index";

import Index from "../Modules/Eduate/Institution/Dashboard/Home";
import FcmMessage from "../Modules/Eduate/Institution/FcmMessage";
import SalaryLedger from "../Modules/HR/Payroll/SalaryLedeger/Index";
import AccountingLedger from "../Modules/Eduate/AccountLedger/EduateAccountLedgerList";
import LeaveLedger from "../Modules/Eduate/leaveledger/EduateLeaveLedgerList";
import EduateModules from "../Modules/Eduate/EduateModules/Modules";
import ModulesConfiguration from "../Modules/Eduate/Institution/InstCreation/ModuleConfiguration/Configuration";
import List from "../Modules/Eduate/configurations/PredefinedData/List";
import PageConfig from "../Modules/Eduate/configurations/PageConfiguration/Index";
import Tags from "../Modules/Eduate/Tags/Tags";
import Template from "../Modules/Eduate/Tags/Templates";
import EduateUserAssociation from "../Modules/Eduate/user/Association";

import TransportHome from "../Modules/Transport/Dashboard/Index";
import UserRights from "../Modules/Eduate/Institution/UserRights/Index";
import EnquiryDashboard from "../Modules/Enquiry/Dashboard/Index";
import EduateCalendarEvents from "../Modules/Eduate/calendar/Index";
import Configuration from "../Modules/Eduate/EduateConfiguration/Index";
import {
  LedgersForType,
  LicenseTypes,
  Operation,
  PageFor,
} from "../utils/Enum.types";
import TeacherLogin from "../Modules/Academics/Users/Index";
import InstBasicData from "../Modules/Eduate/Institution/InstCreation/InstBasicData";
import CreateFinancialYear from "../Modules/Eduate/Institution/InstCreation/financialyear/Index";
import CreateAcademicYear from "../Modules/Eduate/Institution/InstCreation/academicyear/Index";
import CreatePayRollAcademicYear from "../Modules/Eduate/Institution/InstCreation/PayRollAcdYear/Index";
import EduateGlobalConfig from "../Modules/Eduate/Institution/InstCreation/EduateGlobalConfig";
import { useState } from "react";
import CustomerRoutes from "./CustomerRoutes";
import EduateConfigurations from "./EduateConfigurations";
const EduateRoutes = () => {
  // eslint-disable-next-line
  const [instBasicDetails, setModal] = useState(false);

  return (
    <Routes>
      <Route path="/customer/*" element={<CustomerRoutes />} />
      <Route path="/configurations/*" element={<EduateConfigurations />} />
      <Route
        path="/:custId/:InstId/addInstData"
        element={
          <InstBasicData operation={Operation.CREATE} setModal={setModal} />
        }
      />

      <Route
        path="/:custId/:InstId/financialyear"
        element={
          <CreateFinancialYear
            operation={Operation.CREATE}
            setModal={setModal}
          />
        }
      />
      <Route
        path="/:custId/:InstId/academicyear"
        element={
          <CreateAcademicYear pageType={PageFor.GENERAL} setModal={setModal} />
        }
      />
      <Route
        path="/:custId/:InstId/payrollacdyear"
        element={
          <CreatePayRollAcademicYear
            pageType={PageFor.GENERAL}
            setModal={setModal}
          />
        }
      />

      <Route
        path="/:custId/:InstId/moduleConfiguration"
        element={
          <ModulesConfiguration
            pageType={PageFor.GENERAL}
            setModal={setModal}
          />
        }
      />
      <Route
        path="/:custId/:InstId/basiconfig"
        element={
          <EduateGlobalConfig pageType={PageFor.GENERAL} setModal={setModal} />
        }
      />
      <Route path="/globalconfig" element={<GlobalConfigFiles />} />
      <Route path="/settingsdashboard" element={<Settings />} />
      <Route path="/tags" element={<Tags />} />
      <Route path="/template" element={<Template />} />
      <Route path="/fcmMessage" element={<FcmMessage />} />

      {/* <Route path="/eduate/assignpagenumber" element={<PageConfig />} /> */}
      <Route path="/predefinedlist" element={<List />} />
      <Route path="/pageconfig" element={<PageConfig />} />
      <Route
        path="/eduateuserassociation"
        element={<EduateUserAssociation />}
      />
      <Route path="/calendarevents" element={<EduateCalendarEvents />} />
      <Route
        path="/salaryledger"
        element={<SalaryLedger pageType={LedgersForType.EDUATE} />}
      />
      <Route
        path="/accountledger"
        element={
          <AccountingLedger pageFor={PageFor.GENERAL} setModalFlag={setModal} />
        }
      />
      <Route path="/leaveledger" element={<LeaveLedger />} />
      <Route path="/modules" element={<EduateModules />} />
      <Route path="/configuration" element={<Configuration />} />

      <Route
        path="/:InstId/instdetails"
        element={<Index type={LicenseTypes.EDUATE_GENERAL} />}
      />
      <Route
        path="/:InstId/instdetails/admissions"
        element={<Index type={LicenseTypes.MASTERS} />}
      />
      <Route
        path="/:InstId/instdetails/accounts"
        element={<Index type={LicenseTypes.ACCOUNTS} />}
      />
      <Route
        path="/:InstId/instdetails/academics"
        element={<Index type={LicenseTypes.ACADEMIC} />}
      />
      <Route
        path="/:InstId/instdetails/library"
        element={<Index type={LicenseTypes.LIBRARY} />}
      />
      <Route
        path="/:InstId/instdetails/users"
        element={<Index type={LicenseTypes.USER_RIGHTS} />}
      />
      <Route
        path="/:InstId/instdetails/configurations"
        element={<Index type={LicenseTypes.INSTITUTION_CONFIG} />}
      />
      <Route
        path="/:InstId/instdetails/hr"
        element={<Index type={LicenseTypes.PAYROLL} />}
      />
      <Route path="/:InstId/payroll/academicusers" element={<TeacherLogin />} />
      <Route
        path="/:InstId/instdetails/enquiry"
        element={<EnquiryDashboard />}
      />
      <Route path="/:InstId/userrights" element={<UserRights />} />
      <Route
        path="/:InstId/instdetails/transport"
        element={<TransportHome />}
      />
      <Route
        path="/"
        element={
          <EduateHome
            DashBoardRequired
            NavType={LicenseTypes.EDUATE_CUSTOMER}
          />
        }
      />
    </Routes>
  );
};

export default EduateRoutes;

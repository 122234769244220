import { gql } from "@apollo/client";

export const AddAcdLessonPlannerMain = gql`
  mutation AddAcdLessonPlannerMain(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
    $input: LessonMainData!
    $per_std_subj_allocation: Boolean!
  ) {
    AddAcdLessonPlannerMain(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      input: $input
      user_details: $user_details
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const DeleteAcdLessonPlannerMainById = gql`
  mutation DeleteAcdLessonPlannerMainById(
    $token: String!
    $lesson_main_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdLessonPlannerMainById(
      token: $token
      inst_id: $inst_id
      lesson_main_id: $lesson_main_id
      user_details: $user_details
    )
  }
`;

export const UpdateAcdLessonPlannerMain = gql`
  mutation UpdateAcdLessonPlannerMain(
    $token: String!
    $lesson_main_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $chapter_name: String!
  ) {
    UpdateAcdLessonPlannerMain(
      token: $token
      lesson_main_id: $lesson_main_id
      inst_id: $inst_id
      user_details: $user_details
      chapter_name: $chapter_name
    )
  }
`;

export const AddAcdLessonPlannerDetails = gql`
  mutation AddAcdLessonPlannerDetails(
    $token: String!
    $inst_id: ID!
    $lesson_main_id: ID!
    $user_details: SubscribedUser!
    $input: LessonDetailsData!
  ) {
    AddAcdLessonPlannerDetails(
      token: $token
      inst_id: $inst_id
      lesson_main_id: $lesson_main_id
      user_details: $user_details
      input: $input
    ) {
      id
    }
  }
`;

export const UpdateAcdLessonPlannerDetails = gql`
  mutation UpdateAcdLessonPlannerDetails(
    $token: String!
    $lesson_details_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateLessonDetails!
  ) {
    UpdateAcdLessonPlannerDetails(
      token: $token
      lesson_details_id: $lesson_details_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    ) {
      id
    }
  }
`;

export const DeleteAcdLessonPlannerDetailsById = gql`
  mutation DeleteAcdLessonPlannerDetailsById(
    $token: String!
    $lesson_details_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdLessonPlannerDetailsById(
      token: $token
      lesson_details_id: $lesson_details_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdateLessonPlannerStatus = gql`
  mutation UpdateLessonPlannerStatus(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
    $entry_level: String!
    $entry_id: ID!
    $input: [LessonStatusInput!]!
    $per_std_subj_allocation: Boolean!
  ) {
    UpdateLessonPlannerStatus(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      user_details: $user_details
      entry_level: $entry_level
      entry_id: $entry_id
      input: $input
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

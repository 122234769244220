import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetServerDateAndTimeForScan } from "../queries/Library/ServerDateTime/byTime";
interface ServerDateTimeData {
  GetServerDateAndTimeForScan: string;
}

const useServerDateandTimeForScan = () => {
  const [serverDate, setServerDate] = useState("");
  const { data, loading } = useQuery<ServerDateTimeData>(
    GetServerDateAndTimeForScan
  );

  useEffect(() => {
    if (data && !loading) {
      setServerDate(data.GetServerDateAndTimeForScan);
    }
  }, [data, loading]);
  return {
    serverDate,
    data,
    loading,
  };
};
export default useServerDateandTimeForScan;

import { gql } from "@apollo/client";

export const GetAcdStudentDetails = gql`
  query nodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MstStudent {
        id
        first_name
        middle_name
        last_name
        std_reg_no
        std_adm_no
        is_login_created
        std_father_name
        std_mother_name
        inst_id
        std_email
        std_mobile
        std_sex
        acd_yr_id
        std_doa
        std_dob
        std_status
        std_profile_filename
        std_details {
          std_corr_address
        }
        acd_class {
          class_desc
          id
        }
        acd_branch {
          branch_desc
          id
        }
        acd_semester {
          sem_desc
          id
        }
        acd_section {
          id
          section_desc
        }
        acd_dept {
          dept_desc
          id
        }
        category {
          cat_desc
          id
        }
        acd_yr {
          acd_yr
          id
        }
      }
      __typename
    }
  }
`;

export const GetAcdStudent = gql`
  query nodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MstStudent {
        id
        first_name
        middle_name
        last_name
      }
      ... on MstStudentEnquiry {
        id
        first_name
        middle_name
        last_name
      }
      ... on MstStudentPassout {
        id
        std_name
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const GetAcdLessonDetailsByNodeId = gql`
  query GetAcdLessonDetailsByNodeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on AcdLessonPlannerDetails {
        id
        sub_topic_name
        learning_objectives
        five_es
        classroom_process
        resources_required
        techniques
        teacher_reflection
        duration_in_mins
        inst_id
        lesson_main_id
      }
    }
  }
`;

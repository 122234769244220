import { gql } from "@apollo/client";

export const GetLibDonorsByInstId = gql`
  query GetLibDonorsByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibDonorOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibDonorsByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ donorNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          donor_name
          donor_address
          donor_tel
          donor_type
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  ApolloLink,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { serverList } from "../utils/apiconnectivityEnum";
import { query } from "../utils/constants";
import { LicenseInfo } from "@mui/x-license-pro";

const eduateBackendServerLink = new HttpLink({
  uri: process.env.React_App_PROD_Backend_End_Point + query,
});

const rickandMortyOpenApi = new HttpLink({
  uri: process.env.React_App_RICK_MORTY,
});

const countriesApi = new HttpLink({
  uri: process.env.React_App_COUNTRIES,
});

const eduateSubscriptionLink = new GraphQLWsLink(
  createClient({
    url: "ws://localhost:8081/subscriptions",
  })
);
LicenseInfo.setLicenseKey(
  "0ec9c4fbef6920cbf42d680afcc17ab9Tz04NjUzMyxFPTE3NDIxMDEyMTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
const eduateServer = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  eduateSubscriptionLink,
  eduateBackendServerLink
  // rickandMortyOpenApi
);

export const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === serverList.RICK_MORTY,
    rickandMortyOpenApi, // <= apollo will send to this if clientName is "link1"
    ApolloLink.split(
      (operation) => operation.getContext().clientName === serverList.COUNTRIES,
      countriesApi,
      eduateServer
      // <= apollo will send to this if clientName is "link2"
      // <= else eduateServer will run
    )
  ),
  cache: new InMemoryCache({}),
  // defaultOptions: { query: { fetchPolicy: "network-only" } },
});

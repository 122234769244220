import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import TextArea from "../../../stories/TextArea/TextArea";
import Close from "../../../images/Close.svg";
import { Button } from "../../../stories/Button/Button";
import { Operation } from "../../../utils/Enum.types";
import { useMutation } from "@apollo/client";
import {
  AddAcdLessonPlannerMain,
  UpdateAcdLessonPlannerMain,
} from "../queries/lesson_planner/mutation";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { SubjectFormData, SubjectType } from "../types/subject/Subject";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { emptyMessageType } from "../../../utils/constants";
import MessageModal from "../../../pages/MessageModal";
import { GetAcdLessonPlannerMain } from "../queries/lesson_planner/list";
import { GetAcdSubjectForEntryLevel } from "../queries/subjects/List.tsx/Index";
import {
  AddAcdLessonPlannerMainData,
  AddAcdLessonPlannerMainVars,
  UpdateAcdLessonPlannerMainData,
  UpdateAcdLessonPlannerMainVars,
} from "../types/lessonplanner/mutations";
import { editDetailsType } from "./List";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
interface Props {
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  selectedSubject: SubjectType | null;
  editDetails: editDetailsType;
}
const New = ({ setDrawer, operation, selectedSubject, editDetails }: Props) => {
  const { flag } = useCheckAllocationType();

  const [AddAcdLesson] = useMutation<
    AddAcdLessonPlannerMainData,
    AddAcdLessonPlannerMainVars
  >(AddAcdLessonPlannerMain);

  const [UpdateAcdLesson] = useMutation<
    UpdateAcdLessonPlannerMainData,
    UpdateAcdLessonPlannerMainVars
  >(UpdateAcdLessonPlannerMain);

  const { token } = useToken();

  const { InstId, entryId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const [chapter_name, setChapterName] = useState("");

  const [message, setMessage] = useState(emptyMessageType);
  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(Number(entryId));
  const { user_details } = useLoggedInUserDetails();

  useEffect(() => {
    if (operation === Operation.UPDATE) {
      setChapterName(editDetails.chapter_name);
    }
  }, [editDetails, operation]);
  const handleSubmit = () => {
    if (operation === Operation.CREATE)
      AddAcdLesson({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: activeAcademicYearData.data
            ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
            : 0,
          user_details,
          input: {
            chapter_name,
            allotted_level: alloted.level,
            allotted_id: alloted.id,
            subj_master_id:
              selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
          },

          per_std_subj_allocation: flag!,
        },
        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject ? selectedSubject.id : 0,
            },
          },
          {
            query: GetAcdSubjectForEntryLevel,
            variables: {
              token,
              inst_id: InstId!,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              per_std_subj_allocation: flag,
            },
          },
          // {
          //   query: GetAcdInstTree,
          //   variables: {
          //     inst_id: InstId,
          //     id: 0,
          //     token,
          //     query_type: InstTreeQueryType.TREE_BY_INST_ID,
          //     date_of_attendance: toIsoDate(TODAY_DATE),
          //     testNameId: 0,
          //   },
          // },
        ],
      }).then(({ data }) => {
        if (data && data.AddAcdLessonPlannerMain) {
          setMessage({
            flag: true,
            message: "Lesson plan created successfully",
            operation: Operation.CREATE,
          });
        }
      });

    if (operation === Operation.UPDATE) {
      if (editDetails.id === 0) {
        setMessage({
          flag: true,
          message: "Lesson not found",
          operation: Operation.NONE,
        });
        return;
      }
      UpdateAcdLesson({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: activeAcademicYearData.data
            ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
            : 0,
          user_details,
          chapter_name,
          lesson_main_id: editDetails.id,
        },

        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject ? selectedSubject.id : 0,
            },
          },
          {
            query: GetAcdSubjectForEntryLevel,
            variables: {
              token,
              inst_id: InstId!,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              per_std_subj_allocation: flag,
            },
          },
          // {
          //   query: GetAcdInstTree,
          //   variables: {
          //     inst_id: InstId,
          //     id: 0,
          //     token,
          //     query_type: InstTreeQueryType.TREE_BY_INST_ID,
          //     date_of_attendance: toIsoDate(TODAY_DATE),
          //     testNameId: 0,
          //   },
          // },
        ],
      }).then(({ data }) => {
        if (data && data.UpdateAcdLessonPlannerMain) {
          setMessage({
            flag: true,
            message: "Lesson plan updated successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDrawer(false);
      setChapterName("");
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="create-main-chapter">
        <div className="create-main-chapter__title">
          <Title>
            {operation === Operation.UPDATE
              ? "Update Main Chapter"
              : "Create Main Chapter"}
          </Title>
          <img src={Close} alt="" onClick={() => setDrawer(false)} />
        </div>

        <div className="create-main-chapter--frame">
          <div className="label-grid">
            <Label>Description</Label>
            <TextArea
              rows={3}
              autoFocus
              value={chapter_name}
              onChange={(e) => {
                setChapterName(e.target.value);
              }}
            />
          </div>
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button mode="cancel" onClick={() => setDrawer(false)} />
      </div>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default New;

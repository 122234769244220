import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import {
  PageFor,
  PageLabel,
  StudentDetailedReportType,
  StudentListFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Button } from "../../../../stories/Button/Button";

import Pointer from "../../../../images/Pointer.svg";
import { useNavigate, useParams } from "react-router-dom";
import StudentBioData from "../DashboardReports/BioData";
import StudentReligion from "../DashboardReports/ReligionWise";
import StudentList from "../../Student/List";
import AgeWise from "../DashboardReports/AgeWise";
import YearWise from "../DashboardReports/YearWise";

interface ArrayDataType {
  label: string;
  id: string;
  url: string;
  value?: string;
}
interface Props {
  type: StudentDetailedReportType;
}

const StudentDetailedReport = ({ type }: Props) => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [studentModal, setStudentModal] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState("");
  const ReportTypes: ArrayDataType[] = [
    {
      id: "1",
      label: "Student List",
      value: StudentDetailedReportType.STUDENT_LIST,
      url: `/${InstId}/admissions/students/reports/student/detailed`,
    },
    {
      id: "2",
      label: "Student Bio Data",
      value: StudentDetailedReportType.STUDENT_BIO_DATA,
      url: `/${InstId}/admissions/students/reports/student/biodata`,
    },
    {
      id: "3",
      label: "Mailing Label",
      value: StudentDetailedReportType.MAILING_LABEL,
      url: `/${InstId}/admissions/students/reports/student/mailinglabel`,
    },
    {
      id: "4",
      label: "Religion Wise Student List",
      value: StudentDetailedReportType.RELIGION_WISE,
      url: `/${InstId}/admissions/students/reports/student/religionwise`,
    },
    {
      id: "5",
      label: "Age Wise Student List",
      value: StudentDetailedReportType.AGE_WISE,
      url: `/${InstId}/admissions/students/reports/student/agewise`,
    },
    {
      id: "6",
      label: "Year Of Joining Student List",
      value: StudentDetailedReportType.YEAR_OF_JOINING,
      url: `/${InstId}/admissions/students/reports/student/yearWise`,
    },
  ];

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Student Detailed Report</Title>
      <div className="std-detailed-report">
        <div className="std-detailed-report__datablock">
          <div className="std-detailed-report__datablock--left">
            <div className="std-detailed-report__tableblock">
              <TableContainer className="std-detailed-report__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Report Types</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ReportTypes.map((data, index) => {
                      return (
                        <TableRow
                          key={data.id}
                          className={
                            selectedReportType === data.id ? "selected-row" : ""
                          }
                        >
                          <TableCell
                            onClick={() => {
                              setSelectedReportType(data.id!);
                              navigate(data.url!);
                            }}
                          >
                            {selectedReportType === data.id ? (
                              <img
                                src={Pointer}
                                alt="/"
                                className="pointer-icon"
                              />
                            ) : (
                              ""
                            )}
                            {data.label}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="std-detailed-report__datablock--right">
            <div className="std-detailed-report__tableblock">
              {type === StudentDetailedReportType.AGE_WISE ? (
                <AgeWise />
              ) : type === StudentDetailedReportType.STUDENT_BIO_DATA ? (
                <StudentBioData
                  type={StudentDetailedReportType.STUDENT_BIO_DATA}
                  pageType={PageFor.GENERAL}
                />
              ) : type === StudentDetailedReportType.MAILING_LABEL ? (
                <StudentBioData
                  type={StudentDetailedReportType.MAILING_LABEL}
                  pageType={PageFor.GENERAL}
                />
              ) : type === StudentDetailedReportType.RELIGION_WISE ? (
                <StudentReligion
                  type={StudentDetailedReportType.RELIGION_WISE}
                />
              ) : type === StudentDetailedReportType.CASTE_WISE ? (
                <StudentReligion type={StudentDetailedReportType.CASTE_WISE} />
              ) : type === StudentDetailedReportType.YEAR_OF_JOINING ? (
                <YearWise />
              ) : (
                <StudentList
                  pageType={PageFor.GENERAL}
                  studentListFor={StudentListFor.DASHBOARD}
                  queryType={StudentReportType.GENERAL}
                  setStudentModal={setStudentModal}
                  studentModal={studentModal}
                  label={PageLabel.FALSE}
                />
              )}
            </div>
          </div>
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default StudentDetailedReport;

import { Fragment, Key, useEffect, useState } from "react";
import Modal from "react-modal";
import {
  Operation,
  PageFor,
  ParentQueryType,
  ParentType,
} from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import ParentsList, { idType } from "../../parent/List";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { AddStudentParentAssociation } from "../../../../queries/students/mutations/new";
import { UpdateStudentParentAssoc } from "../../../../queries/students/mutations/update";

import { StudentsTitlesType } from "../../../../Types/Student/json";
import Home from "../../Home/Index";

import {
  EditModalCustomStyles,
  ModalCustomStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";

import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";
import { Title } from "../../../../stories/Title/Title";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import { studentAcademicYearFormDetails } from "../../../../Types/Accounting";
import LoadingModal from "../../../../pages/LoadingModal";
import Registration from "../../parent/Registration";
import { Step, StepLabel, Stepper } from "@mui/material";
import OpenIcon from "../../../../images/OpenIcon.svg";
import Close from "../../../../images/Close.svg";
import { GetStudentDetails } from "../../../../queries/common";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { EMPTY_STRING } from "../../../../utils/constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { studentRegStepHeader } from "./BasicDetails";

const { Students } = require("../../../../json/title.json");
interface props {
  type: Operation;
  step: number;
}

const StudentParentAssociation = ({ type, step }: props) => {
  const { token } = useToken();
  const { InstId, studentId } = useParams();
  const stepsHeader = studentRegStepHeader();

  const [parentsModal, setParentsModal] = useState(false);
  const [addParentModal, setAddParentModal] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [parentId, setParentId] = useState<idType>();
  const [parentType, setParentType] = useState("");
  const [warningModal, setWarningModal] = useState(false);

  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();
  const { studentData } = useStudentDatabyId();
  const [AddStudentParentAssoci, { loading: parentLoading }] = useMutation(
    AddStudentParentAssociation
  );
  const [updateStudentParentAssoci] = useMutation(UpdateStudentParentAssoc);

  interface parentAssoc {
    name: string;
    relationShip: string;
    id: number;
  }
  const [displayData, setDisplayData] = useState<parentAssoc[]>([
    { id: 0, name: "", relationShip: "Father" },
    { id: 1, name: "", relationShip: "Mother" },
  ]);
  const [studentList, setStudentList] = useState<
    studentAcademicYearFormDetails[]
  >([
    {
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    },
  ]);

  const openParentModal = (type: string) => {
    setParentType(type);
    setParentsModal(true);
  };

  const checkFatherName = () => {
    const filteredData = displayData.find((f) => f.relationShip === "Father");

    if (filteredData && studentData.data?.nodes[0].std_father_name) {
      return (
        filteredData.name.toUpperCase().trim() ===
        studentData.data?.nodes[0].std_father_name.toUpperCase().trim()
      );
    } else {
      return false;
    }
  };
  const checkMotherName = () => {
    const filteredData = displayData.find((f) => f.relationShip === "Mother");
    if (filteredData && studentData.data?.nodes[0].std_mother_name) {
      return (
        filteredData.name.toUpperCase().trim() ===
        studentData.data?.nodes[0].std_mother_name.toUpperCase().trim()
      );
    } else {
      return false;
    }
  };

  const submitForm = () => {
    let matchError = "";
    if (
      checkFatherName() ||
      (studentData.data?.nodes[0].std_father_name.length === 0 &&
        studentData.data?.nodes[0].std_mother_name.length === 0)
    ) {
      matchError = "";
    } else {
      matchError = "Father names ";
    }

    if (
      checkMotherName() ||
      studentData.data?.nodes[0].std_mother_name.length === 0
    ) {
      matchError = "";
    } else if (matchError) {
      matchError = "Father and Mother names ";
    } else {
      matchError = "Mother name ";
    }
    if (matchError !== EMPTY_STRING) {
      setWarningModal(!warningModal);
    } else {
      submitParentForm();
    }
  };

  const submitParentForm = () => {
    if (type === Operation.CREATE) {
      AddStudentParentAssoci({
        variables: {
          token,
          student_id: studentId,
          inst_id: InstId!,
          user_details,
          input: displayData
            .filter((d) => d.id > 1)
            .map((d) => ({
              parent_id: d.id,
              relationship: d.relationShip,
            })),
        },
      }).then(({ data }) => {
        if (data) {
          navigate(`/${InstId}/admissions/students/5/${studentId}/defaultDocs`);
        }
      });
    } else {
      updateStudentParentAssoci({
        variables: {
          token,
          student_id: studentId,
          inst_id: InstId!,
          user_details,
          input: displayData
            .filter((d) => d.id > 1)
            .map((d) => ({
              parent_id: d.id,
              relationship: d.relationShip,
            })),
        },
        refetchQueries: [
          {
            query: GetStudentDetails,
            variables: { token, ids: [studentId] },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          navigate(
            `/${InstId}/admissions/students/${studentId}/stuparentassoc`
          );
        }
      });
    }
  };
  useEffect(() => {
    if (studentData.data && !studentData.loading) {
      const { parents } = studentData.data.nodes[0];
      if (parents.length > 0) {
        setDisplayData(
          displayData.map((res) => {
            const parent = parents.find(
              (p) => p.relationship === res.relationShip
            );
            if (res.relationShip === parent?.relationship) {
              return {
                ...res,
                id: parent.parent.id,
                name: `${parent.parent.first_name} ${parent.parent.middle_name} ${parent.parent.last_name}`,
              };
            } else {
              return res;
            }
          })
        );
      }
      if (parentId) {
        const existingParent = displayData.find(
          (d) => d.relationShip === parentId.relationship
        );

        if (existingParent) {
          if (existingParent.id === parentId.parent_id) {
            alert("cannot choose same parent");
            openParentModal(parentId.relationship);
            return;
          }

          const updatedParents = displayData.map((parent) => {
            if (parent.relationShip === parentId.relationship) {
              return {
                ...parent,
                id: parentId.parent_id,
                name: parentId.name,
              };
            }
            return parent;
          });

          setDisplayData(updatedParents);
        }
      }
    }
    // eslint-disable-next-line
  }, [studentData.data, studentData.loading, parentId?.parent_id]);

  useEffect(() => {
    if (studentId) {
      // eslint-disable-next-line
      studentData?.data?.nodes.map((studentData) => {
        if (
          !studentList.find(
            (response: studentAcademicYearFormDetails) =>
              response.std_id === studentId
          )
        ) {
          setStudentList((prevValues) => [
            {
              std_id: studentId,
              std_adm_no: studentData.std_adm_no,
              first_name: studentData.first_name,
              middle_name: studentData.middle_name,
              last_name: studentData.last_name,
              std_reg_no: studentData.std_reg_no,
              std_father_name: studentData.std_father_name,
              branch_desc: studentData.branch.branch_desc,
              class_desc: studentData.class.class_desc,
              dept_desc: studentData.dept.dept_desc,
              cat_desc: studentData.category.cat_desc,
            },
            ...prevValues,
          ]);
        }
      });
    } // eslint-disable-next-line
  }, [studentId, studentData]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div
        className={
          type === Operation.UPDATE ? "previous-inst-update" : "previous-inst"
        }
      >
        {type === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        {type === Operation.UPDATE && (
          <Title>
            {Students.StudentParentAssociation.Titles.map(
              (title: StudentsTitlesType, index: Key) => {
                return <Fragment key={index}>{title.Update}</Fragment>;
              }
            )}
          </Title>
        )}

        <StudentAcademicDetails />

        <div
          className={type === Operation.UPDATE ? "h-30" : "previous-inst__form"}
        >
          <div className="previous-inst__form--details">
            <div className="details">
              <h4>
                {Students.StudentParentAssociation.Titles.map(
                  (title: StudentsTitlesType, index: Key) => {
                    return <Fragment key={index}>{title.Create}</Fragment>;
                  }
                )}
              </h4>
            </div>
            <div className="row g-0 student-parent-association">
              <div className="col">
                <>
                  <div className="row g-0">
                    <div className="col-11">
                      <div className="label-grid">
                        <Label>Student Name</Label>
                        <Input
                          value={
                            studentData?.data?.nodes[0].first_name +
                            " " +
                            studentData?.data?.nodes[0].middle_name +
                            " " +
                            studentData?.data?.nodes[0].last_name
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
                <div className="row g-0">
                  <p className="parent-student">
                    <span>Hint:</span>
                    <span>
                      Father Name:&nbsp;
                      {studentData.data?.nodes[0].std_father_name}
                    </span>
                    <span>
                      Mother Name:&nbsp;
                      {studentData.data?.nodes[0].std_mother_name}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col">
                {displayData.map((d) => {
                  return (
                    <>
                      <div className="row g-0">
                        <div className="col-11">
                          <div className="label-grid" key={d.id}>
                            <Label>{d.relationShip}</Label>
                            <Input value={d.name} disabled />
                          </div>
                        </div>
                        <div className="col-1">
                          <img
                            className="open-button"
                            alt="Loading"
                            src={OpenIcon}
                            onClick={() => openParentModal(d.relationShip)}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}

                <Button
                  mode="addnew"
                  type="button"
                  autoFocus
                  onClick={() => setAddParentModal(!addParentModal)}
                >
                  Parent
                </Button>
              </div>
            </div>
          </div>
          {parentId?.relationship ? (
            type === Operation.UPDATE ? (
              <Button
                type="button"
                mode="save"
                onClick={() => setWarningModal(!warningModal)}
              />
            ) : (
              <Button type="submit" mode="save-continue" onClick={submitForm} />
            )
          ) : type !== Operation.UPDATE ? (
            <Button
              mode="skip"
              onClick={() =>
                navigate(
                  `/${InstId}/admissions/students/5/${studentId}/defaultDocs`
                )
              }
              type="button"
            />
          ) : (
            <Button type="submit" mode="save" onClick={submitForm} />
          )}
          <Button
            mode="back"
            type="button"
            onClick={() => {
              if (type === Operation.UPDATE) {
                navigate(
                  `/${InstId}/admissions/students/${studentId}/stuparentassoc`
                );
              } else {
                navigate(
                  `/${InstId}/admissions/students/3/${studentId}/prevInstDetails`
                );
              }
            }}
          />
        </div>
      </div>

      {/* warningmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={warningModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div>
          <Label>
            Are you sure? You want to Change Parent Name. <br />
            Change of Data will Be saved
          </Label>
          <div className="row">
            <div className="col">
              <Button
                mode="yes"
                type="button"
                onClick={() => {
                  submitParentForm();
                  setWarningModal(!warningModal);
                }}
              />

              <Button
                mode="no"
                onClick={() => setWarningModal(!warningModal)}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={parentsModal}
        style={StudentModalStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
        <div className="h-100 modal-flex">
          <div className="modal-flex__data h-100">
            <ParentsList
              ParentName={
                parentType === ParentType.FATHER
                  ? studentData.data?.nodes[0]?.std_father_name
                  : studentData.data?.nodes[0]?.std_mother_name
              }
              pageType={PageFor.MODAL}
              queryType={
                parentType === ParentType.FATHER
                  ? ParentQueryType.FATHER
                  : ParentQueryType.MOTHER
              }
              setParentId={setParentId}
              setParentsModal={setParentsModal}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setParentsModal(false)} />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addParentModal}
        style={ModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data">
            <Registration
              operation={Operation.CREATE}
              pageType={PageFor.MODAL}
              setCloseModal={setAddParentModal}
              closeModal={addParentModal}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              step={step}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddParentModal(!addParentModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={parentLoading} />
    </>
  );
};

export default StudentParentAssociation;

import React, { useContext, useEffect, useRef, useState } from "react";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  AttendanceStatus,
  HolidayType,
  InstitutionConfigurationTypes,
  PageFor,
} from "../../../../../utils/Enum.types";
import { Title } from "../../../../../stories/Title/Title";
import { AppContext } from "../../../../../context/context";
import {
  DateRange,
  GetnumberOfDaysInMonth,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import useToken from "../../../../../customhooks/useToken";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import { Button } from "../../../../../stories/Button/Button";
import Modal from "react-modal";
import ViewAllSubjects from "../../../../Master/Student/Dashboard/Academics/ViewAllSubjects";
import { ViewDetails } from "../../../../../styles/ModalStyles";
import Close from "../../../../../images/Close.svg";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../../hooks/useAcdGeneralHolidays";
import {
  GetAcdStdAttSubjMonthlyForCal,
  GetAcdStdAttSubjMonthlyForTheDay,
} from "../../../queries/holidays/list";
import { useLazyQuery } from "@apollo/client";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";
import { monthDaysType } from "../../Attendance/MonthlyOverview";
import {
  GetAcdStdAttSubjMonthlyForTheDayData,
  GetAcdStdAttSubjMonthlyForTheDayVars,
  SessionDetails,
} from "../../../types/holidays";
import { EMPTY_STRING } from "../../../../../utils/constants";
import RightArrow from "../../../../../images/ArrowRight.svg";
const SubjectWise = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [viewSubjects, setViewSubjects] = useState(false);
  var generalHolidaysMap = useRef(new Map());
  const [sessionData, setSessionData] = useState<SessionDetails[]>([]);
  const [subjectData, setSubjectData] = useState({
    subj_code: "",
    subj_desc: "",
  });
  const [subjectWiseAttendance, setSubjectWiseAttendance] = useState(false);

  const [GetStdSessionDetails, { data: SessionDetails }] = useLazyQuery<
    GetAcdStdAttSubjMonthlyForTheDayData,
    GetAcdStdAttSubjMonthlyForTheDayVars
  >(GetAcdStdAttSubjMonthlyForTheDay);

  const [date, setMonthDate] = useState(new Date());
  const { serverDate } = useServerDateandTime();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { flag } = useCheckAllocationType();
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();
  const { firstDay, lastDay } = DateRange(date.toString()) || {};
  const [monthDaysForSession, setMonthDaysForSession] =
    useState<monthDaysType[]>();
  const [customDate, setCustomDate] = useState(new Date());
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    holiday_query_type.ALL_HOLIDAYS
  );
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const [GetData, { data: attData, loading: attLoading }] = useLazyQuery(
    GetAcdStdAttSubjMonthlyForCal
  );
  const chooseTypeAndColorForSession = (statusArray: string[], day: number) => {
    if (generalHolidaysMap.current.has(day)) {
      const holiday_details = generalHolidaysMap.current.get(day)!;
      switch (holiday_details.holiday_type) {
        case HolidayType.GENERAL || HolidayType.UNEXPECTED:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "border-left-for-holidays",
          };
        case HolidayType.WEEKEND || HolidayType.VACATION:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "weekend-dates",
          };
        default:
          break;
      }
    } else {
      const areAllPresent =
        statusArray.length > 0 &&
        statusArray.every((value) => value === AttendanceStatus.P);

      const areAllAbsent =
        statusArray.length > 0 &&
        statusArray.every((value) => value === AttendanceStatus.A);
      const containsBothAP =
        statusArray.includes(AttendanceStatus.A) &&
        statusArray.includes(AttendanceStatus.P);

      if (areAllPresent) {
        return {
          type: AttendanceStatus.P,
          description: "",
          class_name: "cal-bg-green",
        };
      } else if (areAllAbsent) {
        return {
          type: AttendanceStatus.A,
          description: "",
          class_name: "cal-bg-red",
        };
      } else if (containsBothAP) {
        return {
          type: "",
          description: "",
          class_name: "cal-bg-amber",
        };
      } else {
        return {
          type: "",
          description: "",
          class_name: "",
        };
      }
    }
  };
  useEffect(() => {
    if (serverDate) {
      setMonthDate(new Date(serverDate));
    }
  }, [serverDate]);

  useEffect(() => {
    if (
      state.studentId &&
      firstDay &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      enablePerSessionAtt
    ) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetData({
        variables: {
          token,
          inst_id: state.InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(firstDay),
          student_id: state.studentId,
          per_std_subj_allocation: flag ? flag :false,
        },
      }).then(({ data }) => {
        if (data) {
          const arrLength = GetnumberOfDaysInMonth(firstDay);
          const res = Array.from({ length: arrLength }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);

            const statusValue = (
              data.GetAcdStdAttSubjMonthlyForCal as Record<
                string,
                string | string[]
              >
            )[dayKey];

            const statusArray = Array.isArray(statusValue)
              ? statusValue
              : statusValue?.split(";") || [];

            const filteredStatusArray = statusArray.filter(
              (value) => value !== ""
            );

            const holiday_details = chooseTypeAndColorForSession(
              filteredStatusArray,
              dayNum
            );
            return {
              day: dayNum,
              status: holiday_details?.type,
              description: holiday_details?.description,
              week: date.toLocaleString("en-US", { weekday: "short" }),
              class_name: holiday_details?.class_name!,
              date,
              holiday_types: [],
              events_length: 0,
              attendance: statusArray,
            };
          });
          setMonthDaysForSession(res);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.studentId,
    firstDay,
    state.InstId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetData,
    InstGeneralHolidays.data,
    attData,
    date,
    customDate,
    enablePerSessionAtt,
    state.ActiveAcdYr,
    flag,
  ]);
  useEffect(() => {
    if (state.studentId && token && state.ActiveAcdYr) {
      GetStdSessionDetails({
        variables: {
          token,
          inst_id: state.InstId,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(customDate.toString()),
          student_id: state.studentId,
          per_std_subj_allocation: flag ? flag :false,
        },
      });
    }
  }, [
    GetStdSessionDetails,
    token,
    state.studentId,
    state.ActiveAcdYr,
    customDate,
    state.InstId,
    flag,
  ]);
  return (
    <>
        <>
          {/* <Title variant="subtitle1"> */}
          <b>
            {customDate.toLocaleDateString("en-IN", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </b>
          {/* </Title> */}
          <div className="student-dashboard__attendance--full-view--subjects-count">
            <span>Subjects Covered</span>
            <b>
              {SessionDetails &&
                SessionDetails.GetAcdStdAttSubjMonthlyForTheDay.map(
                  (res) => res.subj_desc
                ).length}
            </b>
          </div>
          <ul className="student-dashboard__attendance--full-view--subjects-list">
            {SessionDetails &&
              SessionDetails.GetAcdStdAttSubjMonthlyForTheDay.map(
                (data, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setSubjectWiseAttendance(!subjectWiseAttendance);
                        setSessionData(
                          data.session_details.filter(
                            (data) => data.att_status !== EMPTY_STRING
                          )
                        );
                        setSubjectData({
                          subj_code: data.subj_code,
                          subj_desc: data.subj_desc,
                        });
                      }}
                    >
                      <div className="student-dashboard__attendance--full-view--subjects">
                        <span className="student-dashboard__attendance--full-view--subjects--name">
                          {data.subj_desc}
                        </span>
                        <span className="student-dashboard__attendance--full-view--subjects--code">
                          ({data.subj_code})
                        </span>
                      </div>
                      <div className="student-dashboard__attendance--full-view--sessions">
                        <div className="student-dashboard__attendance--full-view--sessions--flex">
                          <span>{`${
                            data.session_details.filter(
                              (data) => data.att_status !== EMPTY_STRING
                            ).length
                          } Sessions`}</span>

                          <img src={RightArrow} alt="" />
                        </div>
                        <div className="student-dashboard__attendance--full-view--sessions--status">
                          {data.session_details
                            .filter((data) => data.att_status !== EMPTY_STRING)
                            .map((status, index) => (
                              <span
                                key={index}
                                className={`student-dashboard__attendance--full-view--dot ${
                                  status.att_status === AttendanceStatus.P
                                    ? "student-dashboard__attendance--full-view--present"
                                    : "student-dashboard__attendance--full-view--absent"
                                }`}
                              ></span>
                            ))}
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
          </ul>
          <div className="student-dashboard__attendance--full-view--button">
            <Button mode="view" onClick={() => setViewSubjects(!viewSubjects)}>
              &nbsp;Subject Wise Attendance
            </Button>
          </div>
        </>
      

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewSubjects}
        ariaHideApp={false}
        style={ViewDetails}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewAllSubjects
              setModalFlag={setViewSubjects}
              pageType={PageFor.GENERAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setViewSubjects(!viewSubjects)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubjectWise;

import React from "react";
import Common from "../New/Common";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import InsuranceDetails from "../../../../images/InsuranceDetails.svg";
import RoadTaxDetails from "../../../../images/RoadTaxDetails.svg";
import FitnessDetails from "../../../../images/FitnessDetails.svg";
import EmissionTestDetails from "../../../../images/EmisisontestDetails.svg";
import { LabelNameProps } from "../../../../Types/Labels";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";

const { VMS_Form } = require("../../../json/form.json");
const CurrentDetails = () => {
  const navigate = useNavigate();
  return (
    <>
      <Common />
      <div className="view-vehicle-details__current-details">
        <div className="row g-0 vms-v-details-new__current-details--insurance">
          <div className="col">
            <div className="vms-v-details-new__current-details--title">
              <img src={InsuranceDetails} alt="" />
              Insurance Details
            </div>
            <div className="label-grid">
              <Label>Insurance Provider</Label>
              <Input disabled />
              <Label>InSurance Policy No.</Label>
              <Input disabled />
              <Label>Insurance Done Date</Label>
              <Input type="date" className="font-blue" disabled />
            </div>
            <div className="vms-v-details-new__other-details--data--grid">
              <Label>Insurance Start Date</Label>
              <Input type="date" className="font-green" disabled />
              <Label>Insurance Expiry Date</Label>
              <Input type="date" className="font-red" disabled />
            </div>
          </div>
          <div className="col">
            <div className="vms-v-details-new__current-details--title">
              <img src={RoadTaxDetails} alt="" />
              Road Tax
            </div>
            <div className="label-grid">
              <Label>Road Tax Paid Date</Label>
              <Input type="date" className="font-blue" disabled />
            </div>

            <div className="vms-v-details-new__other-details--data--grid">
              <Label>Road Tax Start Date</Label>
              <Input type="date" className="font-green" disabled />
              <Label>Road Tax Expiry Date</Label>
              <Input type="date" className="font-red" disabled />
            </div>
          </div>
        </div>
        <div className="row vms-v-details-new__current-details--insurance g-0">
          <div className="col">
            <div className="vms-v-details-new__current-details--title">
              <img src={FitnessDetails} alt="" />
              Fitness Details
            </div>

            <div className="label-grid">
              <Label>Fitness Test Done Date</Label>
              <Input type="date" className="font-blue" disabled />
            </div>

            <div className="vms-v-details-new__other-details--data--grid">
              <Label>Fitness Test Start Date</Label>
              <Input type="date" className="font-green" disabled />
              <Label>Fitness Test Expiry Date</Label>
              <Input type="date" className="font-red" disabled />
            </div>

            <div className="label-grid">
              <Label>Fitness Test Reference Number</Label>
              <Input disabled />
            </div>
          </div>
          <div className="col">
            <div className="vms-v-details-new__current-details--title ">
              <img src={EmissionTestDetails} alt="" />
              Emission Test
            </div>
            <div className="label-grid">
              <Label>Emission Test Done Date</Label>
              <Input type="date" className="font-blue" disabled />
            </div>

            <div className="vms-v-details-new__other-details--data--grid">
              <Label>Emission Test Start Date</Label>
              <Input type="date" className="font-green" disabled />
              <Label>Emission Test Expiry Date</Label>
              <Input type="date" className="font-red" disabled />
            </div>

            <div className="label-grid">
              <Label>Emission Test Reference Number</Label>
              <Input disabled />
            </div>
          </div>
        </div>
        <div className="row g-0 vms-v-details-new__current-details--tyre-details">
          <div className="col">
            {VMS_Form.Vehicle_Details.Preview.CurrentDetails.map(
              (label: LabelNameProps, index: React.Key) => {
                return (
                  <div className="label-grid" key={index}>
                    <Label>{label.LabelName}</Label>
                    <Input type={label.dataType} disabled />
                  </div>
                );
              }
            )}
          </div>
          <div className="col"></div>
        </div>
      </div>
      <Button
        mode="edit"
        onClick={() => navigate(`/vms/vehicledetails/update/currentdetails`)}
      />
      <Button mode="back" onClick={() => navigate(-1)} />
    </>
  );
};

export default CurrentDetails;

import React, { SetStateAction } from "react";
import { Operation } from "../../../../utils/Enum.types";
import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import TextArea from "../../../../stories/TextArea/TextArea";
import { TextField } from "@mui/material";
import { LabelNameProps } from "../../../../Types/Labels";
import { Button } from "../../../../stories/Button/Button";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
const { VMS_Form } = require("../../../json/form.json");
interface Props {
  setModalFlag: React.Dispatch<SetStateAction<boolean>>;
  operation: Operation;
}

const New = ({ setModalFlag, operation }: Props) => {
  return (
    <>
      <Title>
        {operation === Operation.UPDATE
          ? "Edit Supplier Details"
          : operation === Operation.VIEW
          ? "View Supplier Details"
          : "Add Supplier Details"}
      </Title>
      <div className="vms-supplier__add">
        <div className="label-grid">
          <Label>Supplier Name</Label>
          {operation === Operation.VIEW ? <Input disabled /> : <Input />}

          <Label>Address</Label>
          {operation === Operation.VIEW ? (
            <TextArea rows={3} disabled />
          ) : (
            <TextArea rows={3} />
          )}

          <Label>City</Label>
          {operation === Operation.VIEW ? (
            <Input disabled />
          ) : (
            <FormAutocomplete
              options={["Bangalore", "Mysore"]}
              className={formClasses.inputRoot}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          )}
        </div>
        {VMS_Form.Supplier_Details.BasicDetails.map(
          (label: LabelNameProps, index: React.Key) => {
            return (
              <div className="label-grid" key={index}>
                <Label>{label.LabelName}</Label>
                {operation === Operation.VIEW ? <Input disabled /> : <Input />}
              </div>
            );
          }
        )}
      </div>
      {operation === Operation.CREATE ? (
        <Button mode="save" />
      ) : operation === Operation.UPDATE ? (
        <Button mode="update" />
      ) : null}
      <Button mode="cancel" onClick={() => setModalFlag(false)} />
    </>
  );
};

export default New;

import React, { useState } from "react";
import Home from "../../Master/Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../styles/Tabs";
import PortraitView from "./PortraitView";
import LandscapeView from "./LandscapeView";
import { useTheme } from "@mui/material/styles";
import Template1, {
  IdTemplates,
  IdTemplatesSides,
} from "./Templates/Portrait/ThemeBased/Template1";
import Template2 from "./Templates/Portrait/FullyCustomizable/Template2";
import Template3 from "./Templates/Portrait/LightCustomizable/Template3";
import Template4 from "./Templates/Portrait/DarkCustomizable/Template4";
import LandscapeTemplate1 from "./Templates/Landscape/Template1";
import { PageFor } from "../../../utils/Enum.types";
import { StudentEdges } from "../../Academics/hooks/useAcdStudentsForAdmission";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudents?: StudentEdges[];
  setStudentIdSet?: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const Index = ({ setModal, selectedStudents, setStudentIdSet }: Props) => {
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null
  );
  const [selectedTemplateName, setSelectedTemplateName] =
    useState("Theme Based");
  const selectTemplate = (templateId: number, templateName: string) => {
    setSelectedTemplateId(templateId);
    setSelectedTemplateName(templateName);
  };

  const getSelectedPortraitTemplateComponent = (templateId: number) => {
    switch (templateId) {
      case 1:
        return (
          <Template1
            templateFor={IdTemplates.DEMO}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BOTH}
            pageType={PageFor.GENERAL}
            templateId={selectedTemplateId}
            templateName={selectedTemplateName}
            selectedStudents={selectedStudents!}
            setStudentIdSet={setStudentIdSet!}
          />
        );
      case 2:
        return (
          <Template2
            templateFor={IdTemplates.DEMO}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BOTH}
            pageType={PageFor.GENERAL}
            templateId={selectedTemplateId}
            templateName={selectedTemplateName}
            selectedStudents={selectedStudents!}
            setStudentIdSet={setStudentIdSet!}
          />
        );
      case 3:
        return (
          <Template3
            templateFor={IdTemplates.DEMO}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BOTH}
            pageType={PageFor.GENERAL}
            templateId={selectedTemplateId}
            templateName={selectedTemplateName}
            selectedStudents={selectedStudents!}
            setStudentIdSet={setStudentIdSet!}
          />
        );
      case 4:
        return (
          <Template4
            templateFor={IdTemplates.DEMO}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BOTH}
            pageType={PageFor.GENERAL}
            templateId={selectedTemplateId}
            templateName={selectedTemplateName}
            selectedStudents={selectedStudents!}
            setStudentIdSet={setStudentIdSet!}
          />
        );
      default:
        return (
          <Template1
            templateFor={IdTemplates.DEMO}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BOTH}
            pageType={PageFor.GENERAL}
            templateId={selectedTemplateId}
            templateName={selectedTemplateName}
            selectedStudents={selectedStudents!}
            setStudentIdSet={setStudentIdSet!}
          />
        );
    }
  };
  const getSelectedVerticalTemplateComponent = (templateId: number) => {
    switch (templateId) {
      case 1:
        return (
          <LandscapeTemplate1
            templateFor={IdTemplates.DEMO}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BOTH}
            pageType={PageFor.GENERAL}
            templateId={selectedTemplateId}
            templateName={selectedTemplateName}
            selectedStudents={selectedStudents!}
            setStudentIdSet={setStudentIdSet!}
          />
        );

      default:
        return (
          <LandscapeTemplate1
            templateFor={IdTemplates.DEMO}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BOTH}
            pageType={PageFor.GENERAL}
            templateId={selectedTemplateId}
            templateName={selectedTemplateName}
            selectedStudents={selectedStudents!}
            setStudentIdSet={setStudentIdSet!}
          />
        );
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>ID Card Template</Title>
      <div className="id-card-template">
        <div className="id-card-template__flex">
          <div className="id-card-template__ls">
            <div className="id-card-template__ls--tabs">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Portrait" value={0} {...a11yProps(0)} />
                {/* <Tab label="Landscape" value={1} {...a11yProps(1)} /> */}
              </Tabs>
            </div>

            <div className="id-card-template__ls--tabpanel">
              <TabPanel value={value} index={0} dir={theme.direction}>
                <PortraitView
                  onSelectTemplate={selectTemplate}
                  selectedTemplateId={selectedTemplateId || 1}
                  setModal={setModal}
                  templateName={selectedTemplateName}
                  selectedStudents={selectedStudents!}
                  setStudentIdSet={setStudentIdSet!}
                />
              </TabPanel>
              {/* <TabPanel value={value} index={1} dir={theme.direction}>
                <LandscapeView
                  onSelectTemplate={selectTemplate}
                  selectedTemplateId={selectedTemplateId || 1}
                  setModal={setModal}
                  templateName={selectedTemplateName}
                  selectedStudents={selectedStudents!}
                  setStudentIdSet={setStudentIdSet!}
                />
              </TabPanel> */}
            </div>
          </div>
          {value === 0 ? (
            <>{getSelectedPortraitTemplateComponent(selectedTemplateId || 1)}</>
          ) : (
            <>{getSelectedVerticalTemplateComponent(selectedTemplateId || 1)}</>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const GetAcdSemestersByAcdClassIds = gql`
  query GetAcdSemestersByAcdClassIds($token: String!, $acd_class_ids: [ID!]!) {
    GetAcdSemestersByAcdClassIds(token: $token, acd_class_ids: $acd_class_ids) {
      sem_desc
      id
      sem_idx
      acd_class_id
      semester_id
    }
  }
`;
export const GetAcdSemestersByInstId = gql`
  query GetAcdSemestersByInstId($token: String!, $inst_id: ID!) {
    GetAcdSemestersByInstId(token: $token, inst_id: $inst_id) {
      sem_desc
      id
      sem_idx
      acd_class_id
      semester_id
    }
  }
`;
export const GetAcdSubjectMainSemesterUnmapped = gql`
  query GetAcdSubjectMainSemesterUnmapped(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_class_id: ID!
  ) {
    GetAcdSubjectMainSemesterUnmapped(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_class_id: $acd_class_id
    ) {
      acd_sem_id
      sem_desc
    }
  }
`;
export const GetAcdSubjectMainSemesterMapped = gql`
  query GetAcdSubjectMainSemesterMapped(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_class_id: ID!
  ) {
    GetAcdSubjectMainSemesterMapped(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_class_id: $acd_class_id
    ) {
      acd_sem_id
      sem_desc
    }
  }
`;

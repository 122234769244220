import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { BankBookTitleProps } from "../../../../Types/Titles";
import { useNavigate } from "react-router-dom";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { AccountsTitles } = require("../../json/title.json");

const CashBalanceReport = () => {
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.BankBook.Reports.Titles.map(
          (title: BankBookTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.Day_Wise_Cash_Balance_Report}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="row g-0 cash-balance-report">
        <div className="col">
          <div className="daily-fee-collected">
            <TextField
              type="date"
              className="daily-fee-collected__textfield"
              label="From"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              defaultValue="2022-07-02"
            />
            <TextField
              type="date"
              className="daily-fee-collected__textfield"
              label="To"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              defaultValue="2022-07-30"
            />
          </div>
        </div>
        <div className="col-3">
          <div className="cash-balance-report__finyr">
            <Label>View Previous Financial Year:</Label>
            &nbsp;
            <Input
              type="checkbox"
              // onChange={() => setEnableFilterDate(!enableFilterDate)}
            />
          </div>
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={["1", "2"]}
            openOnFocus
            onChange={(e, newValue) => {}}
            freeSolo
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-uncontrolled"
                label="Financial Year"
                autoFocus
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                fullWidth
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
      </div>
      <div className="cash-balance-report__tableblock">
        <TableContainer className="cash-balance-report__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.Reports.DayWiseCashBalanceReport.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className="cash-balance-report__table--date"
                  id="td-center"
                >
                  02-02-2022
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
                <TableCell
                  className="cash-balance-report__table--amount"
                  id="td-right"
                >
                  20000
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="button-left">
        <Button mode="print" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default CashBalanceReport;

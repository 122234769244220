import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { getPredefinedDataByType } from "../queries/preDefinedData/byType";
import {
  PredefinedDataByTypeData,
  PredefinedDataByTypeEdge,
  PredefinedDataByTypevars,
} from "../Types/Settings";
import { EMPTY_STRING, FETCH_MORE_DATA } from "../utils/constants";
import { PredefinedDataTypes, ReturnType } from "../utils/Enum.types";
import { optionsType } from "../utils/Form.types";
import {
  getModifiedScrollHeight,
  toStandardCase,
} from "../utils/UtilFunctions";
import useToken from "./useToken";

const usePredefinedDataByType = (
  type: PredefinedDataTypes,
  searchData: string,
  returnType?: ReturnType
) => {
  const { token } = useToken();
  const [predefined, setPredefined] = useState<optionsType[]>([]);
  const [options, setOptions] = useState<PredefinedDataByTypeEdge[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [endCursor, setEndCursor] = useState("");

  const default_rows = 10;
  const [GetPredeindedData, { data, loading, fetchMore }] = useLazyQuery<
    PredefinedDataByTypeData,
    PredefinedDataByTypevars
  >(getPredefinedDataByType, {
    variables: {
      first: default_rows,
      token,
      type,
      after: null,
      Lname: searchData ?? EMPTY_STRING,
    },
  });
  useEffect(() => {
    if (token) {
      GetPredeindedData();
    }
  }, [token, GetPredeindedData]);
  useEffect(() => {
    if (!loading && data) {
      if (data.GetPredefinedDataByType.edges[0]?.node.value2 === EMPTY_STRING) {
        setPredefined(
          data.GetPredefinedDataByType.edges.map(({ node }) => ({
            label: toStandardCase(node.value1),
            value: node.value1,
          }))
        );
      } else {
        setPredefined(
          data.GetPredefinedDataByType.edges.map(({ node }) => ({
            label: toStandardCase(node.value1),
            value: node.value2,
          }))
        );
      }

      if (returnType === ReturnType.VALUE_SAME_AS_LABEL) {
        setPredefined(
          data.GetPredefinedDataByType.edges.map(({ node }) => ({
            label: node.value1,
            value: node.value1,
          }))
        );
      }
      if (returnType === ReturnType.WITH_ID) {
        setPredefined(
          data.GetPredefinedDataByType.edges.map(({ node }) => ({
            label: node.value1,
            value: node.id.toString(),
          }))
        );
      }
    } else setPredefined([]);
  }, [loading, data, searchData, returnType]);
  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPredefinedDataByType.edges;
            const pageInfo = fetchMoreResult.GetPredefinedDataByType.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPredefinedDataByType.edges.filter(({ node }) => {
                return (
                  newEdges.findIndex(
                    ({ node: newNode }) => newNode.id === node.id
                  ) !== -1
                );
              });

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPredefinedDataByType: {
                edges: [...options, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetPredefinedDataByType.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPredefinedDataByType.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = options.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });

        setOptions(updatedNewData);
      } else {
        setOptions(newData);
      }
      setEndCursor(data.GetPredefinedDataByType.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return {
    PredefinedData: {
      dropDown: predefined,
      data,
      handleScroll,
    },
  };
};

export default usePredefinedDataByType;

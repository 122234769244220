import { gql } from "@apollo/client";

export const GetAcdSubject = gql`
  query GetAcdSubject(
    $name: String
    $first: Int
    $acd_yr_id: ID!
    $after: Cursor
    $input: AcdSubjQueryData!
    $sortBy: AcdSubjectMasterOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetAcdSubject(
      token: $token
      first: $first
      acd_yr_id: $acd_yr_id
      input: $input
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [{ subjDescContainsFold: $name }, { subjCodeContainsFold: $name }]
      }
    ) {
      edges {
        node {
          id
          subj_code
          subj_desc
          subj_idx
          subj_board_code
          subj_is_lang
          teacher_count
          subj_is_elective
          subj_is_non_academic
          subj_is_core_subject
          subj_internals_avialed
          subj_apptitude_avialed
          subj_marks_in_grades
          subj_is_lab
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const GetAcdSubjectTeacherAssociations = gql`
  query GetAcdSubjectTeacherAssociations(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $input: SubjectTeacherQueryData!
  ) {
    GetAcdSubjectTeacherAssociations(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      input: $input
    ) {
      id
      pr_emp_id
      inst_id
      acd_yr_id
      subj_master_id
      pr_emp_details {
        id
        emp_id
        emp_title
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_experience
        emp_qualification
        emp_profile_filename
        pr_grade_details {
          id
          grade_desc
        }
        pr_designation_details {
          designation_desc
        }
        pr_job_type_details {
          job_type_desc
        }
        pr_dept_details {
          dept_desc
        }
      }
      subject_details {
        id
        subj_code
        subj_desc
        subj_idx
        teacher_count
        subj_board_code
        subj_is_lang
        subj_is_lab
        subj_is_elective
        subj_is_non_academic
        subj_is_core_subject
        subj_internals_avialed
        subj_apptitude_avialed
        subj_marks_in_grades
      }
    }
  }
`;
export const GetAcdSubjectAllotedForClass = gql`
  query GetAcdSubjectAllotedForClass(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $alloted_id: ID!
    $alloted_level: String!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdSubjectAllotedForClass(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      alloted_id: $alloted_id
      alloted_level: $alloted_level
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      id
      subj_sl
      core_sl_no
      subj_is_lang
      subj_is_elective
      subj_is_grade
      subj_internals_avialed
      subj_alloted_main_id
      subj_master_id
      subject_details {
        id
        subj_code
        subj_desc
        subj_idx
        subj_board_code
        subj_is_lang
        subj_is_elective
        subj_is_non_academic
        subj_is_core_subject
        subj_internals_avialed
        subj_apptitude_avialed
        subj_marks_in_grades
        subj_is_lab
      }
      elective_details {
        id
        elective_sl
        subj_alloted_details_id
        subj_master_id
        subject_details {
          id
          subj_code
          subj_desc
          subj_idx
          subj_board_code
          subj_is_lang
          subj_is_elective
          subj_is_non_academic
          subj_is_core_subject
          subj_internals_avialed
          subj_apptitude_avialed
          subj_marks_in_grades
          subj_is_lab
        }
      }
    }
  }
`;

export const GetSubjectById = gql`
  query GetSubjectById($id: ID!, $token: String!) {
    node(token: $token, id: $id) {
      ... on AcdSubjectMaster {
        id
        subj_code
        subj_desc
        subj_idx
        subj_board_code
        subj_is_lang
        subj_is_elective
        subj_is_non_academic
        subj_is_core_subject
        subj_internals_avialed
        subj_apptitude_avialed
        subj_marks_in_grades
        subj_is_lab
      }
    }
  }
`;
export const GetAcdSubjectForEntryLevel = gql`
  query GetAcdSubjectForEntryLevel(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $entry_id: ID!
    $entry_level: String!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdSubjectForEntryLevel(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      entry_id: $entry_id
      entry_level: $entry_level
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      id
      subj_sl
      core_sl_no
      subj_is_lang
      subj_is_elective
      subj_is_grade
      subj_internals_avialed
      subj_alloted_main_id
      is_lessons_planned
      subj_master_id
      subject_details {
        id
        subj_code
        subj_desc
        subj_idx
        subj_board_code
        subj_is_lang
        subj_is_elective
        subj_is_non_academic
        subj_is_core_subject
        subj_internals_avialed
        subj_apptitude_avialed
        subj_marks_in_grades
        subj_is_lab
      }
    }
  }
`;
export const GetAcdTeacherClassAssociations = gql`
  query GetAcdTeacherClassAssociations(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $per_std_subj_allocation: Boolean!
    $input: TeacherClassQueryData!
  ) {
    GetAcdTeacherClassAssociations(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      per_std_subj_allocation: $per_std_subj_allocation
      input: $input
    ) {
      id
      is_class_teacher
      pr_emp_id
      inst_id
      acd_yr_id
      acd_class_id
      subj_master_id
      acd_semester_id
      acd_section_id
      per_std_subj_allocation
      pr_emp_details {
        id
        emp_id
        emp_title
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_experience
        emp_qualification
        emp_profile_filename
        pr_grade_details {
          id
          grade_desc
        }
        pr_designation_details {
          designation_desc
        }
        pr_job_type_details {
          job_type_desc
        }
        pr_dept_details {
          dept_desc
        }
      }
      subject_details {
        id
        subj_code
        subj_desc
        subj_idx
        subj_board_code
        subj_is_lang
        subj_is_elective
        subj_is_non_academic
        subj_is_core_subject
        subj_internals_avialed
        subj_apptitude_avialed
        subj_marks_in_grades
        subj_is_lab
      }
    }
  }
`;

export const GetAcdIsClassTeacher = gql`
  query GetAcdTeacherClassAssociations(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $per_std_subj_allocation: Boolean!
    $input: TeacherClassQueryData!
  ) {
    GetAcdTeacherClassAssociations(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      per_std_subj_allocation: $per_std_subj_allocation
      input: $input
    ) {
      pr_emp_id
      is_class_teacher
    }
  }
`;
export const GetAcdTeacherSubjectClassAssociations = gql`
  query GetAcdTeacherSubjectClassAssociations(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $entry_level: String!
    $per_std_subj_allocation: Boolean!
    $entry_id: ID!
  ) {
    GetAcdTeacherSubjectClassAssociations(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      subj_master_id
      subj_code
      subj_desc
      subj_board_code
      subj_is_elective
      subj_is_core_subject
      elective_subj_master_id
      elective_subj_code
      elective_subj_desc
      subj_is_lang
      subj_is_lab
      subj_is_non_academic
      is_lessons_planned
      Teachers {
        pr_emp_id
        emp_id
        emp_title
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_profile_filename
        is_class_teacher
      }
    }
  }
`;
export const GetAcdTeachersForClass = gql`
  query GetAcdTeachersForClass(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $entry_level: String!
    $entry_id: ID!
  ) {
    GetAcdTeachersForClass(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      entry_level: $entry_level
      entry_id: $entry_id
    ) {
      id
      is_class_teacher
      pr_emp_id
      inst_id
      acd_yr_id
      acd_class_id
      subj_master_id
      acd_semester_id
      acd_section_id
      pr_emp_details {
        emp_id
        emp_first_name
        emp_middle_name
        emp_last_name
      }
    }
  }
`;

export const GetAcdSubjectLessonStatusByStudent = gql`
  query GetAcdSubjectLessonStatusByStudent(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_id: ID!
    $entry_id: ID!
    $entry_level: String!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdSubjectLessonStatusByStudent(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_id: $student_id
      entry_id: $entry_id
      entry_level: $entry_level
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      subj_master_id
      subj_code
      subj_desc
      header_data {
        subj_master_id
        total_chapters
        total_duration
        completed_percent
      }
    }
  }
`;

export const GetAcdSubjectLessonStatusByTeacher = gql`
  query GetAcdSubjectLessonStatusByTeacher(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $pr_emp_id: ID!
    $entry_id: ID!
    $entry_level: String!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdSubjectLessonStatusByTeacher(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      entry_id: $entry_id
      entry_level: $entry_level
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      subj_master_id
      subj_code
      subj_desc
      header_data {
        subj_master_id
        total_chapters
        total_duration
        completed_percent
      }
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetLibBookClassificationsByInstId } from "../../../queries/Library/MasterData/Classification/list/byInstId";
import {
  GetLibBookClassificationByInstIdData,
  GetLibBookClassificationByInstIdVars,
} from "../../../Types/Library/MasterData/Classification/paginationTypes";
import { EMPTY_RESPONSETYPE } from "../../../utils/constants";
import { Direction, ReturnType, SortBy } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";

const useClassification = (
  searchClassification: string,
  rowsPerPage: number,
  returnType: ReturnType,
  searchByAuthId?: number | null,
  searchByPublisherId?: number | null,
  searchBySubjectId?: number | null,
  searchByKeywordId?: number | null,
  searchByMediaId?: number | null
) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [classifications, setClassifications] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);

  const [
    GetLibBookClass,
    {
      data: classificationData,
      loading: classificationLoading,
      error: classificationError,
      fetchMore,
    },
  ] = useLazyQuery<
    GetLibBookClassificationByInstIdData,
    GetLibBookClassificationByInstIdVars
  >(GetLibBookClassificationsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: rowsPerPage,
      sortBy: SortBy.CLASSIFICATION_DESC,
      direction: Direction.ASC,
      name: searchClassification!,
      authorId: searchByAuthId,
      publisherId: searchByPublisherId,
      subjectId: searchBySubjectId,
      mediaId: searchByMediaId,
      keywordId: searchByKeywordId,
      after: null,
    },
  });

  useEffect(() => {
    if (token) {
      GetLibBookClass().then(({ data }) => {
        if (
          data &&
          data.GetLibBookClassificationsByInstId &&
          returnType === ReturnType.RESPONSE_TYPE
        ) {
          setClassifications(
            data.GetLibBookClassificationsByInstId.edges?.map(({ node }) => ({
              label: node.classification_desc,
              value: node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [
    classificationData,
    classificationLoading,
    GetLibBookClass,
    token,
    returnType,
  ]);
  return {
    classificationsList: {
      dropDown: classifications,
      queryData: {
        data: classificationData?.GetLibBookClassificationsByInstId,
        loading: classificationLoading,
        error: classificationError,
        fetchMore,
      },
    },
  };
};

export default useClassification;

import { Title } from "../../../stories/Title/Title";
import Warning from "../../../images/Warning.svg";
const warningsArray = [
  "All Your Current Financial Year Details of Closing Balance Will Be Carried Forward To New Financial Year As Opening Balance (OB)",
  "Once Posting has Roll Out to New Financial Year  Changes Like Alteration OR Deletion can not Be Done in Previous Financial Year",
  "Demand Deletion For Not Eligible T/C Taken Students For Current Year Should Be Done Before posting else cannot be done in Posted Year",
];
const WarningsComponent = () => {
  return (
    <div className="finyr-post__data--warning">
      <Title variant="subtitle1">
        <img className="logo-1" src={Warning} alt="/" />
        Warnings
      </Title>
      <ul>
        {warningsArray.map((war_ele, index) => {
          return (
            <li>
              {index + 1}.&nbsp;{war_ele}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WarningsComponent;

import React, { useState } from "react";
import { Title } from "../../../stories/Title/Title";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import CompleteDetails from "./CompleteDetails";
import Assign from "./Assign";
import { SubjDrawerDetails } from "./Index";
import { PayRollMasters } from "../../HR/enums/Enum.types";
import usePayRollMasters from "../../HR/hooks/usePayRollMasters";
import usePayRollMastersConfig from "../../HR/hooks/usePayRollMastersConfig";
import { ACTION, EMPLOYEEGRADE } from "../../HR/constants";
import { useMutation } from "@apollo/client";
import { DeleteAcdSubjectTeacherAssoci } from "../queries/subjects/mutation/Index";
import { Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useEmployee, { empQueryTypes } from "../../HR/hooks/useEmployee";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import { AccountentType } from "../../Accounts/common/Enum.types";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { AllocateTeachersDrawer } from "../../../styles/DrawerStyles";
const { Academics_Table } = require("../json/table.json");
interface Props {
  subjectDetails: SubjDrawerDetails;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const View = ({ setModal, subjectDetails }: Props) => {
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { InstId } = useParams();
  const [pr_emp_ids, setPrEmpIds] = useState<Set<number>>(new Set());
  const [completeDetails, setCompleteDetails] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [assign, setAssign] = useState(false);
  const [searchGrade, setSearchGrade] = useState("");
  const [searchEmp, setSearchEmp] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const { subj_code, subj_id, subj_name } = subjectDetails;
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const [DeleteSubjTeacherAssoci] = useMutation(DeleteAcdSubjectTeacherAssoci, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { dropDown } = usePayRollMasters(
    PayRollMasters.GRADE,
    searchGrade,
    null
  );
  const {
    empDetails: { data, error },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.TEACHERS_ASSIGNED_FOR_SUBJECT,
    searchEmp,
    0,
    0,
    0,
    0,
    0,
    0,
    [AccountentType.TEACHER]
  );
  const { USE_HR_GRADE } = usePayRollMastersConfig();

  const handleDelete = () => {
    DeleteSubjTeacherAssoci({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        subj_master_id: subj_id,
        pr_emp_ids: Array.from(pr_emp_ids),
      },
    }).then(({ data }) => {
      if (data && data.DeleteAcdSubjectTeacherAssoci) {
        setMessage({
          flag: true,
          message: "Successfully deleted employees",
          operation: Operation.CREATE,
        });
      }
    });
  };
  return (
    <>
      <div className="allocate-teacher__assign">
        <Title>View Details</Title>

        <div className="allocate-teacher__assign--sub-details">
          <div className="allocate-teacher__assign--sub-details--grid">
            <span>Subject Code</span>
            <span>:</span>
            <span className="allocate-teacher__assign--sub-details--code">
              {subj_code}
            </span>
          </div>
          <div className="allocate-teacher__assign--sub-details--grid">
            <span>Subject Name</span>
            <span>:</span>
            <span className="allocate-teacher__assign--sub-details--code">
              {subj_name}
            </span>
          </div>
        </div>
        <Title variant="subtitle1">Assigned Teachers List</Title>
        <div className="allocate-teacher__assign--filters row g-0">
          <div className="col-5">
            <Input
              placeholder="Search..."
              id="search"
              onChange={(e) => {
                setSearchEmp(e.target.value);
              }}
            />
          </div>
          <div className="col"></div>
          {USE_HR_GRADE ? (
            <div className="col-3">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={dropDown}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setSearchGrade(e.target.value)}
                    className={labelClasses.formControlRoot}
                    label="Employee Grade"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="allocate-teacher__assign--tableblock">
          {error ? (
            <b className="nodata">{error.message}</b>
          ) : (
            <TableContainer className="allocate-teacher__assign--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Academics_Table.AllocateTeachers.TeachersList.filter(
                      (th: TableHeaderProps) =>
                        USE_HR_GRADE
                          ? th.labelName !== ACTION
                          : th.labelName !== EMPLOYEEGRADE &&
                            th.labelName !== ACTION
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.GetPayRollEmp.edges.length
                    ? data.GetPayRollEmp.edges.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell
                            className="allocate-teacher__assign--table--slno"
                            id="td-center"
                          >
                            {deleteFlag ? (
                              <Checkbox
                                checked={pr_emp_ids.has(data?.node.id)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const newSet = new Set(pr_emp_ids);
                                  if (checked) {
                                    newSet.add(data?.node.id);
                                    setPrEmpIds(newSet);
                                  } else {
                                    newSet.delete(data?.node.id);
                                    setPrEmpIds(newSet);
                                  }
                                }}
                              />
                            ) : null}
                            {index + 1}
                          </TableCell>
                          <TableCell className="allocate-teacher__assign--table--id">
                            {data.node.emp_id}
                          </TableCell>
                          <TableCell
                            className="allocate-teacher__assign--table--name"
                            // onClick={() => {
                            //   dispatch({
                            //     payload: { employeeId: data.node.id },
                            //     type: payloadTypes.SET_EMPLOYEE_ID,
                            //   });
                            //   setCompleteDetails(!completeDetails);
                            // }}
                          >
                            {`${data.node.emp_first_name} ${data.node.emp_middle_name} ${data.node.emp_last_name}`}
                          </TableCell>
                          <TableCell
                            className="allocate-teacher__assign--table--count"
                            id="td-center"
                          >
                            {data.node.emp_experience
                              ? data.node.emp_experience
                              : "-"}
                          </TableCell>
                          {USE_HR_GRADE ? (
                            <TableCell
                              className="allocate-teacher__assign--table--grade"
                              id="td-center"
                            >
                              {data.node.pr_grade_details.grade_desc}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {!deleteFlag ? (
              <Button mode="assign" onClick={() => setAssign(!assign)}>
                &nbsp;Teachers
              </Button>
            ) : null}
            {(deleteFlag && pr_emp_ids.size > 0) || !deleteFlag ? (
              <Button
                mode="remove"
                onClick={() => {
                  deleteFlag ? handleDelete() : setDeleteFlag(!deleteFlag);
                }}
              />
            ) : null}
            <Button
              mode="cancel"
              onClick={() => {
                deleteFlag ? setDeleteFlag(!deleteFlag) : setModal(false);
              }}
            />
          </div>
          <div className="col-4">
            <div className="student-total-count">
              Total Teachers :{" "}
              <b>
                {data && data.GetPayRollEmp.edges
                  ? data.GetPayRollEmp.totalCount
                  : 0}
              </b>
            </div>
          </div>
        </div>
      </div>
      <AllocateTeachersDrawer
    
        anchor="right"
        open={completeDetails}
        onClose={() => setCompleteDetails(!completeDetails)}
      >
        <CompleteDetails setModal={setCompleteDetails} />
      </AllocateTeachersDrawer>

      <AllocateTeachersDrawer
        anchor="right"
        open={assign}
        onClose={() => setAssign(!assign)}
      >
        <Assign setModal={setAssign} subjectDetails={subjectDetails} />
      </AllocateTeachersDrawer>
      <MessageModal
        handleClose={() =>
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          })
        }
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default View;

import { gql } from "@apollo/client";

export const GetAcctFineSlabByInstId = gql`
  query GetAcctFineSlabByInstId($token: String!, $inst_id: ID!) {
    GetAcctFineSlabByInstId(token: $token, inst_id: $inst_id) {
      id
      acct_fine_desc
      acct_fine_type
      acct_slab1_from
      acct_slab1_to
      acct_slab1_fine
      acct_slab2_from
      acct_slab2_to
      acct_slab2_fine
      acct_slab3_from
      acct_slab3_to
      acct_slab3_fine
      acct_slab4_from
      acct_slab4_to
      acct_slab4_fine
      acct_slab5_from
      acct_slab5_to
      acct_slab5_fine
    }
  }
`;
export const GetAcctFineSlabById = gql`
  query GetAcctFineSlabById($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      id
      ... on AcctFineSlab {
        acct_fine_desc
        acct_slab1_from
        acct_slab1_to
        acct_slab1_fine
        acct_slab2_from
        acct_slab2_to
        acct_slab2_fine
        acct_slab3_from
        acct_slab3_to
        acct_slab3_fine
        acct_slab4_from
        acct_slab4_to
        acct_slab4_fine
        acct_slab5_from
        acct_slab5_to
        acct_slab5_fine
      }
    }
  }
`;

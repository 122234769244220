import { gql } from "@apollo/client";

export const GetAcdGradeScalesByInstId = gql`
  query GetAcdGradeScalesByInstId(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
  ) {
    GetAcdGradeScalesByInstId(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
    ) {
      id
      idx
      grade
      grade_remarks
      marks_max
      marks_min
      inst_id
      acd_yr_id
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { GetSwEduateConfigVariableByConfigKey } from "../queries/institution/configuration/query/SoftwreConfig";
import { GlobalPageConfigData, SoftwareVars } from "../Types/configtypes";
import useToken from "./useToken";
export interface EduateConfigList {
  GetSwEduateConfigVariableByConfigKey: GlobalPageConfigData;
}

export enum EduateConfigTypes {
  EDUATE_BANNER = "EDUATE_BANNER",
  INSTITUTION_HOSTEL="INSTITUTION_HOSTEL"
}

const useEduateConfiguration = (config_key: EduateConfigTypes) => {
  const { token } = useToken();

  const [GetEduateConfigFlag, { data, error, loading }] = useLazyQuery<
    EduateConfigList,
    SoftwareVars
  >(GetSwEduateConfigVariableByConfigKey, {
    variables: {
      token,
      config_key,
    },
  });

  useEffect(() => {
    if (token && config_key) {
      GetEduateConfigFlag();
    }
  }, [token, config_key, GetEduateConfigFlag]);
  return { EduateConfig: { data, loading, error } };
};

export default useEduateConfiguration;

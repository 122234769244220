import React, { useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";

import { useLazyQuery, useMutation } from "@apollo/client";
import { GetSwMsgTags } from "../../Templates/queries/list";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import {
  GetSwMsgTagsData,
  GetSwMsgTagsVars,
} from "../../Eduate/Tags/paginationTypes";
import { Button } from "../../../stories/Button/Button";
import Close from "../../../images/Close.svg";

import { UpdateDocumentContent } from "./mutation";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import { GetInstDocumentContent, GetInstDocuments } from "./queries";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import {
  EMPTY_STRING,
  formatOptions,
  formattingToolbarOptions,
} from "../../../utils/constants";
import ReactQuill from "react-quill";

interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  content: string;
}
const AddContent = ({ setModalFlag, operation, content }: props) => {
  const { token } = useToken();
  const { InstId, documentId } = useParams();
  const [templateMessage, setTemplateMessage] = useState("");
  const messageRef = useRef<HTMLTextAreaElement | null>(null);
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const handleTemplateClick = (template: string) => {
    const renamedTemplate = template
      .replaceAll("<", "&lt;")
      .replaceAll(">", "&gt;");

    const selectedText = window.getSelection()?.toString();
    const updatedMessage =
      selectedText && selectedText.length > 0
        ? templateMessage.replace(selectedText, renamedTemplate)
        : templateMessage.replaceAll("<p>", "").replaceAll("</p>", "") +
          " " +
          renamedTemplate;

    setTemplateMessage(updatedMessage);
  };
  const handleTextAreaChange = (
    value: string,
    delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ): void => {
    setTemplateMessage(value);
  };
  const handleTextAreaSelect = () => {
    const selectedText = window.getSelection()?.toString();
    if (selectedText && selectedText.length > 0) {
      messageRef.current?.focus();
    }
  };

  const [GetTags, { data }] = useLazyQuery<GetSwMsgTagsData, GetSwMsgTagsVars>(
    GetSwMsgTags
  );

  useEffect(() => {
    if (token) {
      GetTags({
        variables: {
          token,
        },
      });
    }
  }, [token, GetTags]);
  const [CreateContent, { loading: creationLoading }] = useMutation(
    UpdateDocumentContent,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const handleAddTemplate = () => {
    const repaceTags = templateMessage
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    if (InstId && documentId)
      CreateContent({
        variables: {
          token,
          inst_id: InstId,
          document_id: Number(documentId),
          document_content: repaceTags,
          user_details,
        },
        refetchQueries: [
          {
            query: GetInstDocuments,
            variables: {
              token,
              inst_id: InstId,
              custom_doc_name:EMPTY_STRING,
              
            },
          },
          {
            query: GetInstDocumentContent,
            variables: {
              token,
              inst_id: InstId,
              document_id: Number(documentId),
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: content
              ? "Template Updated Successfully"
              : "Template Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
  };
  useEffect(() => {
    if (content) {
      const mutatedContent = content
        .replaceAll("<", "&lt;")
        .replaceAll(">", "&gt;");
      setTemplateMessage(`<p>${mutatedContent}</p>`);
    }
  }, [content]);
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setTemplateMessage("");
      setModalFlag(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="documents-content">
        <div className="documents-content__title">
          <Title>Add Content</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="documents-content__data">
          <div className="row g-0">
            <div className="col">
              <div className="label-grid">
                <Label>Description</Label>

                <ReactQuill
                  value={templateMessage}
                  onChange={handleTextAreaChange}
                  modules={formattingToolbarOptions}
                  formats={formatOptions}
                  onChangeSelection={handleTextAreaSelect}
                />
              </div>
            </div>
          </div>
          <div className="message-template__predefined-words">
            {data?.GetSwMsgTags.map((res) => {
              return (
                <button onClick={() => handleTemplateClick(res.tag_name)}>
                  {res.tag_name}
                </button>
              );
            })}
          </div>
        </div>
        <Button
          mode="save"
          onClick={handleAddTemplate}
          disabled={!templateMessage}
        />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default AddContent;

import { useContext } from "react";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PrintReceiptProps } from "../FeeReceipts/Standard/FeeReceiptPrint";
import { AppContext } from "../../../../context/context";
import { formatter, toStandardDate } from "../../../../utils/UtilFunctions";
import { ToWords } from "to-words";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useChallanConfiguration from "./hooks/useChallanConfiguration";
import { EMPTY_STRING } from "../../../../utils/constants";
import useEnquiryStudentDetails from "../../../../customhooks/useEnquiryStudentDetails";
import { FileUploadParams } from "../../../../utils/Enum.types";

const Template1 = ({
  tableData,
  v_date,
  v_no,
  studentDetails,
  bankDetailsForPrint,
}: PrintReceiptProps) => {
  const { state } = useContext(AppContext);
  const { format } = formatter;
  const toWords = new ToWords();
  const { InstFormData } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});
  const { studentFormData, studentPassedOutFormData } = useStudentDatabyId();
  const {
    enquiryStudentData: { enquiryFormData },
  } = useEnquiryStudentDetails();
  const { totalacademicAmount } = tableData.reduce(
    (acc, item) => {
      acc.totalacademicAmount += item.amount;
      return acc;
    },
    {
      totalacademicAmount: 0,
    }
  );
  const {
    USE_CUSTOMER_NAME,
    USE_INST_NAME,
    USE_LOGO,
    USE_FATHER_NAME,
    USE_ADM_NO,
    USE_CLASS_NAME,
    USE_SECTION_NAME,
    USE_INST_ADDRESS,
    USE_BANK_ACCOUNT_NUMBER,
    USE_BANK_NAME,
    USE_HEADER,
    USE_PRINT_HEADER,
    USE_CONSOLIDATED_TITLE,
    USE_PRINT_CONSOLIDATED_TITLE,
    USE_NOTE1,
    USE_NOTE2,
    USE_NOTE3,
  } = useChallanConfiguration();

  return (
    <>
      <div className="challan-pr-t1">
        <div className="challan-pr-t1__inst-details">
          {USE_HEADER ? (
            <span className="challan-pr-t1__inst-details--header">
              {USE_PRINT_HEADER}
            </span>
          ) : null}
          {USE_LOGO ? (
            <div className="challan-pr-t1__inst-details--logo">
              {LogoOrSign.defaultLogo ? (
                <img src={LogoOrSign.defaultLogo} alt="/" />
              ) : null}
            </div>
          ) : null}
          {USE_CUSTOMER_NAME ? (
            <span className="challan-pr-t1__inst-details--cust-name">
              {InstFormData.cust_name}
            </span>
          ) : null}
          {USE_INST_NAME ? (
            <b className="challan-pr-t1__inst-details--inst-name">
              {InstFormData.inst_name}
            </b>
          ) : null}
          {USE_INST_ADDRESS ? (
            <span className="challan-pr-t1__inst-details--inst-address">
              {InstFormData.inst_address}
            </span>
          ) : null}
        </div>

        <div className="challan-pr-t1__receipt">CHALLAN</div>

        <div className="challan-pr-t1__bank-details">
          {USE_BANK_ACCOUNT_NUMBER ? (
            <div className="challan-pr-t1__bank-details--flex">
              <b> A/C. No. :</b>
              <b className="challan-pr-t1__bank-details--flex--number">
                &nbsp;
                {bankDetailsForPrint?.account_no
                  ? bankDetailsForPrint.account_no
                  : 0}
              </b>
            </div>
          ) : null}

          <div className="challan-pr-t1__bank-details--flex-2">
            {USE_BANK_NAME ? (
              <div className="challan-pr-t1__bank-details--bank-name">
                <label>BANK:</label>
                <b>
                  &nbsp;
                  {bankDetailsForPrint?.bank_name
                    ? bankDetailsForPrint.bank_name
                    : EMPTY_STRING}
                </b>
              </div>
            ) : null}
          </div>
        </div>
        <div className="challan-pr-t1__std-details">
          <div className="challan-pr-t1__bank-details--flex-2">
            <div>
              <label>STD NAME : </label>
              <b>
                {studentDetails
                  ? studentDetails?.first_name
                  : state.studentId
                  ? studentFormData.first_name
                  : state.aluminiStudentId
                  ? studentPassedOutFormData.std_name
                  : state.studentEnquiryId
                  ? enquiryFormData.enq_student_name
                  : "-"}
              </b>
            </div>
            {USE_ADM_NO ? (
              <div>
                <label>Adm No.</label> <label>:</label>
                <label>
                  {studentDetails
                    ? studentDetails.std_adm_no
                    : state.studentId
                    ? studentFormData.adm_no
                    : "-"}
                </label>
              </div>
            ) : null}
          </div>
          <div className="challan-pr-t1__bank-details--flex-2">
            {USE_FATHER_NAME ? (
              <div>
                <label>FATHER NAME : </label>
                <b>
                  {studentDetails
                    ? studentDetails?.std_father_name
                    : state.studentId
                    ? studentFormData.father_name
                    : enquiryFormData.std_father_name}
                </b>
              </div>
            ) : null}

            {USE_CLASS_NAME ? (
              <div>
                <label>Class</label> <label>:</label>
                <label>
                  {studentDetails
                    ? studentDetails.acd_class.class_desc
                    : state.studentId
                    ? studentFormData.class
                    : state.aluminiStudentId
                    ? studentPassedOutFormData.class
                    : state.studentEnquiryId
                    ? enquiryFormData.seekingClass
                    : "-"}
                </label>
              </div>
            ) : null}
          </div>
          <div className="challan-pr-t1__bank-details--flex-2">
            {USE_SECTION_NAME ? (
              <div>
                <label>Section</label> <label>:</label>
                <label>
                  {studentDetails
                    ? studentDetails.acd_section.section_desc
                    : state.studentId
                    ? studentFormData.section
                    : "-"}
                </label>
              </div>
            ) : null}
          </div>
        </div>
        <div className="challan-pr-t1__tableblock">
          <TableContainer className="challan-pr-t1__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sl</TableCell>
                  <TableCell align="center">Particulars</TableCell>
                  <TableCell
                    align="center"
                    className="challan-pr-t1__table--amount"
                  >
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {USE_PRINT_CONSOLIDATED_TITLE && state.studentId ? (
                  <TableRow>
                    <TableCell id="td-center">1</TableCell>
                    <TableCell>{USE_CONSOLIDATED_TITLE}</TableCell>
                    <TableCell
                      id="td-right"
                      className="challan-pr-t1__table--amount"
                    >
                      {format(totalacademicAmount)}
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((data, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell id="td-center">{index + 1}</TableCell>
                          <TableCell>{data.particular}</TableCell>
                          <TableCell id="td-right">
                            {format(data.amount)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="challan-pr-t1__table--footer">
          <div className="challan-pr-t1__table--footer--total">
            <span>Total Paid</span>
          </div>
          <div className="challan-pr-t1__table--footer--amount">
            <span>{format(totalacademicAmount)}</span>
          </div>
        </div>
        <div className="challan-pr-t1__footer">
          <span className="challan-pr-t1__footer--amount-in-words">
            ({toWords.convert(totalacademicAmount, { currency: true })})
          </span>
          <div className="challan-pr-t1__footer--date-sign">
            <b>Date:</b>
            <b>Signature of the Remitter</b>
          </div>
          <div className="challan-pr-t1__footer--receipt-no">
            <b className="challan-pr-t1__footer--receipt-no--title">
              For the Use of Bank
            </b>
            <div className="challan-pr-t1__footer--receipt-no--flex">
              <b>Receipt No : {v_no}</b>
              <b>Date:{toStandardDate(v_date)}</b>
            </div>
          </div>
          <div className="challan-pr-t1__footer--date-seal">
            <div>
              <b>Date & Seal</b>
            </div>
            <b>Signature of Receiving Authority</b>
          </div>
          <div className="challan-pr-t1__footer--note">
            <b>NOTE:</b>
            <ul>
              <li>1. {USE_NOTE1}</li>
              <li>2. {USE_NOTE2}</li>
              <li>3. {USE_NOTE3}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template1;

import NavBar from "../../../components/common/Navbar/Index";
import { LicenseTypes } from "../../../utils/Enum.types";
import Dashboard from "../Dashboard/Index";
interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  return (
    <>
      <NavBar NavType={LicenseTypes.LIBRARY} />
      {DashBoardRequired && (
        <>
          <Dashboard />
        </>
      )}
    </>
  );
};

export default Index;

import React, { useState } from "react";
import { Title } from "../../../stories/Title/Title";

// import BankReceipts from "../../../images/BankReceipt.svg";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";

import { DashBoardFor } from "../../../utils/Enum.types";

import { TabPanel, a11yProps } from "../../../styles/Tabs";

import CompleteDetails from "./CompleteDetails";
import { AcctDashboard } from "../common/Enum.types";
import { LedgersOrRegisters } from "../../../utils/types";
import View from "../../../images/Application.svg";
import HistoricData from "./HistoricData";
import { useParams } from "react-router-dom";
import { AllocateTeachersDrawer } from "../../../styles/DrawerStyles";
interface Props {
  type: LedgersOrRegisters;
  pageType: DashBoardFor;
}

const AccountsDashboard = ({ type, pageType }: Props) => {
  const theme = useTheme();
  const { InstId } = useParams();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => setValue(index);
  const [historicData, setHistoricData] = useState(false);
  return (
    <>
      <Title>Accounts Dashboard</Title>
      <div className="accounts-dashboard">
        <div className="accounts-dashboard__tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="fullWidth"
            textColor="secondary"
          >
            <Tab label="Today" value={0} {...a11yProps(0)} />

            <Tab label="This Month" value={1} {...a11yProps(1)} />
            <Tab label="Previous Month" value={2} {...a11yProps(2)} />
            <Tab label="This Year" value={3} {...a11yProps(3)} />
          </Tabs>
          {InstId ? (
            <button
              className="accounts-dashboard__tabs--view"
              onClick={() => setHistoricData(!historicData)}
            >
              <img src={View} alt="" />
              View Historical Data
            </button>
          ) : null}
        </div>
        <div className="accounts-dashboard__swipable">
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            className="accounts-dashboard__swipable--data"
          >
            {/* values for today */}

            <TabPanel value={value} index={0} dir={theme.direction}>
              <CompleteDetails
                type={type}
                pageType={pageType}
                query_type={AcctDashboard.TODAY}
              />
            </TabPanel>
            {/* values for present month */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              <CompleteDetails
                type={type}
                pageType={pageType}
                query_type={AcctDashboard.THIS_MONTH}
              />
            </TabPanel>
            {/* values for previous month */}
            <TabPanel value={value} index={2} dir={theme.direction}>
              <CompleteDetails
                type={type}
                pageType={pageType}
                query_type={AcctDashboard.PREV_MONTH}
              />
            </TabPanel>
            {/* values for present year */}
            <TabPanel value={value} index={3} dir={theme.direction}>
              <CompleteDetails
                type={type}
                pageType={pageType}
                query_type={AcctDashboard.THIS_YEAR}
              />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
      <AllocateTeachersDrawer
        anchor="right"
        open={historicData}
        onClose={() => setHistoricData(!historicData)}
      >
        <HistoricData setModalFlag={setHistoricData} />
      </AllocateTeachersDrawer>
    </>
  );
};

export default AccountsDashboard;

import React, { Key, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";
import { Checkbox, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { Label } from "../../../../stories/Label/Label";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../components/common/Input/Index";
import InputStory from "../../../../stories/Input/Input";
import { GetSwConfigStudentDataEntryByInstId } from "../../../../queries/institution/configuration/query";
import { updateStudentPersonal } from "../../../../queries/students/mutations/update";
import {
  YesNo,
  Operation,
  PredefinedDataTypes,
  StudentRegitrationForms,
  StudentRegistrationFieldNames,
  ReturnType,
} from "../../../../utils/Enum.types";
import { optionsType, studentPersonalData } from "../../../../utils/Form.types";
import {
  GetSwConfigStudentDataEntryByInstIdData,
  GetSwConfigStudentDataEntryByInstIdVars,
} from "../../../../Types/Student/Configuration";
import { StudentsTitlesType } from "../../../../Types/Student/json";
import {
  handleFormEvent,
  handleMUISelectEvent,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { studentOtherDetailsSchema } from "../../../../utils/validationRules";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import LoadingModal from "../../../../pages/LoadingModal";
import Home from "../../Home/Index";
import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";
import DownArrow from "../../../../images/DownArrow.svg";
import { Title } from "../../../../stories/Title/Title";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import { GetStudentDetails } from "../../../../queries/common";
import { Keys } from "../../../../utils/Enum.keys";
import { EMPTY_STRING } from "../../../../utils/constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { studentRegStepHeader } from "./BasicDetails";
import { userDetails } from "../../../../Types/Accounting/other";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { Students } = require("../../../../json/title.json");
interface props {
  step: number;
  operation: Operation;
}

interface UpdateVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  student_id: string;
  input: {
    std_aadhar: string;
    std_address: string;
    std_place: string;
    std_district: string;
    std_state: string;
    std_phone: string;
    std_pincode: string;
    std_corr_address: string;
    std_corr_place: string;
    std_corr_district: string;
    std_corr_state: string;
    std_corr_phone: string;
    std_corr_pincode: string;
    std_blood_group: string;
    std_weight: number;
    std_height: number;
    std_is_phy_challenged: boolean;
    std_disability_name: string;
    std_disability_percentage: number;
    std_religion: string;
    std_caste: string;
    std_sub_caste: string;
    std_caste_group: string;
    std_sc_st: string;
    std_caste_minority: boolean;
    std_rural_urban: string;
    std_mother_tongue: string;
    std_annual_income: number;
    std_martial_status: string;
    std_identity_marks: string;
    std_place_of_birth: string;
    std_nationality: string;
    std_nearest_police_station: string;
    std_nearest_railway_station: string;
    std_km_from_residence: string;
    std_belongs_to_jk: boolean;
    std_belongs_to_jk_brahmin: boolean;
    std_is_sports_person: boolean;
    std_is_ncc: boolean;
    std_is_nss: boolean;
    std_is_gadinadu_horanadu: boolean;
    std_is_defence: boolean;
    std_is_political_sufferer: boolean;
    std_is_cultural: boolean;
    std_is_hiv_devadasi: boolean;
    std_is_child_of_freedom_fighter: boolean;
    std_is_child_of_farmer: boolean;
    std_is_destitude_woman: boolean;
    std_is_nirashritharu: boolean;
    std_is_bpl_card: boolean;
    std_is_non_emp_status: boolean;
    std_is_ksou_emp: boolean;
  };
}
const PersonalData = ({ step, operation }: props) => {
  //Use Navigate useParams and others
  const { token } = useToken();
  const stepsHeader = studentRegStepHeader();

  const { InstId, studentId } = useParams();
  const navigate = useNavigate();

  //flags
  const [checked, setChecked] = useState(false);

  //UseStates For Datas
  const [formData, setFormData] = useState<studentPersonalData>({
    std_aadhar: "",
    std_address: "",
    std_place: "",
    std_district: "",
    std_state: "",
    std_phone: "",
    std_pincode: "",
    std_corr_address: "",
    std_corr_place: "",
    std_corr_district: "",
    std_corr_state: "",
    std_corr_phone: "",
    std_corr_pincode: "",
    std_blood_group: "",
    std_weight: 0,
    std_height: 0,
    std_is_phy_challenged: YesNo.NO,
    std_disability_name: "",
    std_disability_percentage: 0,
    std_religion: "",
    std_caste: "",
    std_sub_caste: "",
    std_caste_group: "",
    std_sc_st: "",
    std_caste_minority: YesNo.NO,
    std_rural_urban: "",
    std_mother_tongue: "",
    std_annual_income: 0,
    std_martial_status: "",
    std_identity_marks: "",
    std_place_of_birth: "",
    std_nationality: "",
    std_nearest_police_station: "",
    std_nearest_railway_station: "",
    std_km_from_residence: "",
    std_belongs_to_jk: YesNo.NO,
    std_belongs_to_jk_brahmin: YesNo.NO,
    std_is_sports_person: YesNo.NO,
    std_is_ncc: YesNo.NO,
    std_is_nss: YesNo.NO,
    std_is_gadinadu_horanadu: YesNo.NO,
    std_is_defence: YesNo.NO,
    std_is_political_sufferer: YesNo.NO,
    std_is_cultural: YesNo.NO,
    std_is_hiv_devadasi: YesNo.NO,
    std_is_child_of_freedom_fighter: YesNo.NO,
    std_is_child_of_farmer: YesNo.NO,
    std_is_destitude_woman: YesNo.NO,
    std_is_nirashritharu: YesNo.NO,
    std_is_bpl_card: YesNo.NO,
    std_is_non_emp_status: YesNo.NO,
    std_is_ksou_emp: YesNo.NO,
  });

  //Queries
  const [GetStudentConfig, { data: GetStudentSwConfig }] = useLazyQuery<
    GetSwConfigStudentDataEntryByInstIdData,
    GetSwConfigStudentDataEntryByInstIdVars
  >(GetSwConfigStudentDataEntryByInstId, {
    variables: { token, inst_id: InstId! },
  });
  const { user_details } = useLoggedInUserDetails();
  const { studentData } = useStudentDatabyId();
  //Mutations
  const [updateStudentPersonalData, { loading: updationLoading }] = useMutation<
    { UpdateStudentDetailsByStudentId: { id: number } },
    UpdateVars
  >(updateStudentPersonal);
  const {
    PredefinedData: { dropDown: City },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.std_place ?? EMPTY_STRING,
    ReturnType.VALUE_SAME_AS_LABEL
  );
  const {
    PredefinedData: { dropDown: PlaceOfBirthOptions },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.std_place_of_birth ?? EMPTY_STRING,
    ReturnType.VALUE_SAME_AS_LABEL
  );
  const {
    PredefinedData: { dropDown: PoliceStationOptions },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.std_nearest_police_station ?? EMPTY_STRING,
    ReturnType.VALUE_SAME_AS_LABEL
  );
  const {
    PredefinedData: { dropDown: RailwayStationOptions },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.std_nearest_railway_station ?? EMPTY_STRING,
    ReturnType.VALUE_SAME_AS_LABEL
  );
  const {
    PredefinedData: { dropDown: CorrespondanceCity },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.std_corr_place ?? EMPTY_STRING,
    ReturnType.VALUE_SAME_AS_LABEL
  );

  const {
    PredefinedData: { dropDown: BloodGroup },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BLOODGROUP,
    formData.std_blood_group ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: District },
  } = usePredefinedDataByType(
    PredefinedDataTypes.DISTRICT,
    formData.std_district ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: States },
  } = usePredefinedDataByType(
    PredefinedDataTypes.STATE,
    formData.std_state ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: CorrespondingStates },
  } = usePredefinedDataByType(
    PredefinedDataTypes.STATE,
    formData.std_corr_state ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: CorrespondanceDistrict },
  } = usePredefinedDataByType(
    PredefinedDataTypes.DISTRICT,
    formData.std_corr_district ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: NATIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.NATIONALITY,
    formData.std_nationality ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: Religion },
  } = usePredefinedDataByType(
    PredefinedDataTypes.RELIGION,
    formData.std_religion ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: Caste },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CASTE,
    formData.std_caste ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: CasteGroup },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CASTE_GROUP,
    formData.std_caste_group ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: SCST },
  } = usePredefinedDataByType(PredefinedDataTypes.SC_ST, EMPTY_STRING);

  const {
    PredefinedData: { dropDown: YESNO },
  } = usePredefinedDataByType(PredefinedDataTypes.YESNO, EMPTY_STRING);
  const {
    PredefinedData: { dropDown: MotherTongue },
  } = usePredefinedDataByType(
    PredefinedDataTypes.MOTHER_TONGUE,
    formData.std_mother_tongue ?? EMPTY_STRING
  );

  //Methods
  const PersonalDataBlock1 = GetStudentSwConfig
    ? GetStudentSwConfig.GetSwConfigStudentDataEntryByInstId.filter(
        ({
          data_used_in_table,
          data_field_block_number,
          data_field_enable,
          data_field_label,
        }) =>
          data_used_in_table === StudentRegitrationForms.PERSONAL_DATA &&
          data_field_block_number === 1 &&
          data_field_enable &&
          data_field_label !== "STS No."
      )
    : [];

  const PersonalDataBlock1AddedProps = PersonalDataBlock1?.map((data) => {
    if (data.data_field_name === StudentRegistrationFieldNames.AADHAR) {
      return { ...data, data_focus: true, maxLength: 12 };
    }
    return data;
  });
  const PersonalDataBlock2 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PERSONAL_DATA &&
        data_field_block_number === 2 &&
        data_field_enable
    );

  const PersonalDataBlock3 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PERSONAL_DATA &&
        data_field_block_number === 3 &&
        data_field_enable
    );

  const PersonalDataBlock4 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PERSONAL_DATA &&
        data_field_block_number === 4 &&
        data_field_enable
    );

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues: studentPersonalData) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const updateForm = async () => {
    const phy_challenged =
      formData.std_is_phy_challenged === YesNo.YES ? true : false;
    const belongs_to_jk =
      formData.std_belongs_to_jk === YesNo.YES ? true : false;
    const belongs_to_jk_brahmin =
      formData.std_belongs_to_jk_brahmin === YesNo.YES ? true : false;
    const caste_minority =
      formData.std_caste_minority === YesNo.YES ? true : false;
    const std_is_bpl_card =
      formData.std_std_is_bpl_card === YesNo.YES ? true : false;
    const std_is_child_of_farmer =
      formData.std_std_is_child_of_farmer === YesNo.YES ? true : false;
    const std_is_child_of_freedom_fighter =
      formData.std_std_is_child_of_freedom_fighter === YesNo.YES ? true : false;
    const std_is_cultural =
      formData.std_std_is_cultural === YesNo.YES ? true : false;
    const std_is_defence =
      formData.std_std_is_defence === YesNo.YES ? true : false;
    const std_is_destitude_woman =
      formData.std_std_is_destitude_woman === YesNo.YES ? true : false;
    const std_is_gadinadu_horanadu =
      formData.std_std_is_gadinadu_horanadu === YesNo.YES ? true : false;
    const std_is_hiv_devadasi =
      formData.std_is_hiv_devadasi === YesNo.YES ? true : false;
    const std_is_ksou_emp =
      formData.std_is_ksou_emp === YesNo.YES ? true : false;
    const std_is_ncc = formData.std_is_ncc === YesNo.YES ? true : false;
    const std_is_nirashritharu =
      formData.std_is_nirashritharu === YesNo.YES ? true : false;
    const std_is_non_emp_status =
      formData.std_is_non_emp_status === YesNo.YES ? true : false;
    const std_is_nss = formData.std_is_nss === YesNo.YES ? true : false;
    const std_is_political_sufferer =
      formData.std_is_political_sufferer === YesNo.YES ? true : false;
    const std_is_sports_person =
      formData.std_is_sports_person === YesNo.YES ? true : false;
    await updateStudentPersonalData({
      variables: {
        token,
        student_id: studentId!,
        inst_id: InstId!,
        user_details,
        input: {
          std_blood_group: formData.std_blood_group,
          std_aadhar: removeMoreSpace(formData.std_aadhar),
          std_address: removeMoreSpace(formData.std_address),
          std_place: removeMoreSpace(formData.std_place),
          std_district: removeMoreSpace(formData.std_district),
          std_state: removeMoreSpace(formData.std_state),
          std_pincode: removeMoreSpace(formData.std_pincode),
          std_phone: removeMoreSpace(formData.std_phone),
          std_corr_address: removeMoreSpace(formData.std_corr_address),
          std_corr_place: removeMoreSpace(formData.std_corr_place),
          std_corr_district: removeMoreSpace(formData.std_corr_district),
          std_corr_state: removeMoreSpace(formData.std_corr_state),
          std_corr_pincode: removeMoreSpace(formData.std_corr_pincode),
          std_corr_phone: removeMoreSpace(formData.std_corr_phone),
          std_religion: removeMoreSpace(formData.std_religion),
          std_caste: removeMoreSpace(formData.std_caste),
          std_sub_caste: removeMoreSpace(formData.std_sub_caste),
          std_caste_group: removeMoreSpace(formData.std_caste_group),
          std_sc_st: formData.std_sc_st,
          std_caste_minority: caste_minority,
          std_rural_urban: formData.std_rural_urban,
          std_mother_tongue: removeMoreSpace(formData.std_mother_tongue),
          std_annual_income: formData.std_annual_income
            ? formData.std_annual_income
            : 0,
          std_martial_status: formData.std_martial_status,
          std_identity_marks: formData.std_identity_marks,
          std_place_of_birth: removeMoreSpace(formData.std_place_of_birth),
          std_nationality: removeMoreSpace(formData.std_nationality),
          std_is_phy_challenged: phy_challenged,
          std_nearest_police_station: removeMoreSpace(
            formData.std_nearest_police_station
          ),
          std_nearest_railway_station: removeMoreSpace(
            formData.std_nearest_railway_station
          ),
          std_km_from_residence: formData.std_km_from_residence,
          std_belongs_to_jk: belongs_to_jk,
          std_belongs_to_jk_brahmin: belongs_to_jk_brahmin,
          std_disability_name: removeMoreSpace(formData.std_disability_name),
          std_disability_percentage: formData.std_disability_percentage,
          std_height: formData.std_height,
          std_is_bpl_card,
          std_is_child_of_farmer,
          std_is_child_of_freedom_fighter,
          std_is_cultural,
          std_is_defence,
          std_is_destitude_woman,
          std_is_gadinadu_horanadu,
          std_is_hiv_devadasi,
          std_is_ksou_emp,
          std_is_ncc,
          std_is_nirashritharu,
          std_is_non_emp_status,
          std_is_nss,
          std_is_political_sufferer,
          std_is_sports_person,
          std_weight: formData.std_weight,
        },
      },

      // change made by akshay need to know what query should we call
      refetchQueries: [
        {
          query: GetStudentDetails,
          variables: {
            token,
            ids: studentId,
          },
        },
      ],
    });
    if (operation === Operation.UPDATE) {
      navigate(`/${InstId}/admissions/students/${studentId}/personaldata`);
    } else {
      navigate(`/${InstId}/admissions/students/3/${studentId}/prevInstDetails`);
    }
  };

  const setCorrespondanceAddress = (event: React.MouseEvent) => {
    if ((event.target as HTMLInputElement).checked) {
      setChecked(!checked);
    }
    if (!(event.target as HTMLInputElement).checked) {
      setChecked(!checked);
      setFormData({
        std_aadhar: formData.std_aadhar,
        std_address: formData.std_address,
        std_place: formData.std_place,
        std_district: formData.std_district,
        std_state: formData.std_state,
        std_pincode: formData.std_pincode,
        std_phone: formData.std_phone,
        std_blood_group: formData.std_blood_group,
        std_corr_address: "",
        std_corr_place: "",
        std_corr_district: "",
        std_corr_state: "",
        std_corr_pincode: "",
        std_corr_phone: "",
        std_religion: formData.std_religion,
        std_caste: formData.std_caste,
        std_sub_caste: formData.std_sub_caste,
        std_caste_group: formData.std_caste_group,
        std_sc_st: formData.std_sc_st,
        std_caste_minority: formData.std_caste_minority,
        std_rural_urban: formData.std_rural_urban,
        std_mother_tongue: formData.std_mother_tongue,
        std_annual_income: formData.std_annual_income,
        std_martial_status: formData.std_martial_status,
        std_identity_marks: formData.std_identity_marks,
        std_place_of_birth: formData.std_place_of_birth,
        std_nationality: formData.std_nationality,
        std_is_phy_challenged: formData.std_is_phy_challenged,
        std_nearest_police_station: formData.std_nearest_police_station,
        std_nearest_railway_station: formData.std_nearest_railway_station,
        std_km_from_residence: formData.std_km_from_residence,
        std_belongs_to_jk: formData.std_belongs_to_jk,
        std_belongs_to_jk_brahmin: formData.std_belongs_to_jk_brahmin,
        std_disability_name: formData.std_disability_name,
        std_disability_percentage: formData.std_disability_percentage,
        std_height: formData.std_height,
        std_is_bpl_card: formData.std_is_bpl_card,
        std_is_child_of_farmer: formData.std_is_child_of_farmer,
        std_is_child_of_freedom_fighter:
          formData.std_is_child_of_freedom_fighter,
        std_is_cultural: formData.std_is_cultural,
        std_is_defence: formData.std_is_defence,
        std_is_destitude_woman: formData.std_is_destitude_woman,
        std_is_gadinadu_horanadu: formData.std_is_gadinadu_horanadu,
        std_is_hiv_devadasi: formData.std_is_hiv_devadasi,
        std_is_ksou_emp: formData.std_is_ksou_emp,
        std_is_ncc: formData.std_is_ncc,
        std_is_nirashritharu: formData.std_is_nirashritharu,
        std_is_non_emp_status: formData.std_is_non_emp_status,
        std_is_nss: formData.std_is_nss,
        std_is_political_sufferer: formData.std_is_political_sufferer,
        std_is_sports_person: formData.std_is_sports_person,
        std_weight: formData.std_weight,
      });
    }
  };
  if (checked) {
    formData.std_corr_address = formData.std_address;
    formData.std_corr_place = formData.std_place;
    formData.std_corr_phone = formData.std_phone;
    formData.std_corr_state = formData.std_state;
    formData.std_corr_pincode = formData.std_pincode;
    formData.std_corr_district = formData.std_district;
  }

  useEffect(() => {
    if (!studentData.loading && studentData.data) {
      const {
        std_blood_group,
        std_aadhar,
        std_address,
        std_place,
        std_district,
        std_state,
        std_pincode,
        std_phone,
        std_corr_address,
        std_corr_place,
        std_corr_district,
        std_corr_state,
        std_corr_phone,
        std_corr_pincode,
        std_religion,
        std_caste,
        std_sub_caste,
        std_caste_group,
        std_sc_st,
        std_caste_minority,
        std_rural_urban,
        std_mother_tongue,
        std_annual_income,
        std_martial_status,
        std_identity_marks,
        std_place_of_birth,
        std_nationality,
        std_is_phy_challenged,
        std_nearest_police_station,
        std_nearest_railway_station,
        std_km_from_residence,
        std_belongs_to_jk,
        std_belongs_to_jk_brahmin,
        std_disability_name,
        std_disability_percentage,
        std_height,
        std_is_bpl_card,
        std_is_child_of_farmer,
        std_is_child_of_freedom_fighter,
        std_is_cultural,
        std_is_defence,
        std_is_destitude_woman,
        std_is_gadinadu_horanadu,
        std_is_hiv_devadasi,
        std_is_ksou_emp,
        std_is_ncc,
        std_is_nirashritharu,
        std_is_non_emp_status,
        std_is_nss,
        std_is_political_sufferer,
        std_is_sports_person,
        std_weight,
      } = studentData.data.nodes[0].std_details;
      setFormData({
        std_aadhar,
        std_address,
        std_place,
        std_district,
        std_state,
        std_pincode,
        std_phone,
        std_blood_group,
        std_corr_address,
        std_corr_place,
        std_corr_district,
        std_corr_state,
        std_corr_phone,
        std_corr_pincode,
        std_religion,
        std_caste,
        std_sub_caste,
        std_caste_group,
        std_sc_st,
        std_caste_minority: std_caste_minority ? YesNo.YES : YesNo.NO,
        std_rural_urban,
        std_mother_tongue,
        std_annual_income,
        std_martial_status: std_martial_status ? YesNo.YES : YesNo.NO,
        std_identity_marks,
        std_place_of_birth,
        std_nationality,
        std_is_phy_challenged: std_is_phy_challenged ? YesNo.YES : YesNo.NO,
        std_nearest_police_station,
        std_nearest_railway_station,
        std_km_from_residence,
        std_belongs_to_jk: std_belongs_to_jk ? YesNo.YES : YesNo.NO,
        std_belongs_to_jk_brahmin: std_belongs_to_jk_brahmin
          ? YesNo.YES
          : YesNo.NO,

        std_disability_name,
        std_disability_percentage,
        std_height,
        std_is_bpl_card: std_is_bpl_card ? YesNo.YES : YesNo.NO,
        std_is_child_of_farmer: std_is_child_of_farmer ? YesNo.YES : YesNo.NO,
        std_is_child_of_freedom_fighter: std_is_child_of_freedom_fighter
          ? YesNo.YES
          : YesNo.NO,
        std_is_cultural: std_is_cultural ? YesNo.YES : YesNo.NO,
        std_is_defence: std_is_defence ? YesNo.YES : YesNo.NO,
        std_is_destitude_woman: std_is_destitude_woman ? YesNo.YES : YesNo.NO,
        std_is_gadinadu_horanadu: std_is_gadinadu_horanadu
          ? YesNo.YES
          : YesNo.NO,
        std_is_hiv_devadasi: std_is_hiv_devadasi ? YesNo.YES : YesNo.NO,
        std_is_ksou_emp: std_is_ksou_emp ? YesNo.YES : YesNo.NO,
        std_is_ncc: std_is_ncc ? YesNo.YES : YesNo.NO,
        std_is_nirashritharu: std_is_nirashritharu ? YesNo.YES : YesNo.NO,
        std_is_non_emp_status: std_is_non_emp_status ? YesNo.YES : YesNo.NO,
        std_is_nss: std_is_nss ? YesNo.YES : YesNo.NO,
        std_is_political_sufferer: std_is_political_sufferer
          ? YesNo.YES
          : YesNo.NO,
        std_is_sports_person: std_is_sports_person ? YesNo.YES : YesNo.NO,
        std_weight,
      });
    }
  }, [studentData.loading, studentData.data]);
  useEffect(() => {
    if (token) {
      GetStudentConfig();
    }
  }, [token, GetStudentConfig]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <div
        className={
          operation === Operation.UPDATE
            ? "previous-inst-update"
            : " previous-inst"
        }
      >
        {operation === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        {operation === Operation.UPDATE && (
          <Title>
            {Students.PersonalDetails.Titles.map(
              (title: StudentsTitlesType, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.Update}</React.Fragment>
                );
              }
            )}
          </Title>
        )}

        <StudentAcademicDetails />
        <Formik
          initialValues={formData}
          validationSchema={studentOtherDetailsSchema}
          onSubmit={updateForm}
          enableReinitialize
        >
          {(meta) => {
            return (
              <Form
                className={
                  operation === Operation.UPDATE
                    ? " h-30"
                    : "previous-inst__form"
                }
              >
                <div className="previous-inst__form--details">
                  <div className="details">
                    <h4>
                      {Students.PersonalDetails.Titles.map(
                        (title: StudentsTitlesType, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Create}
                            </React.Fragment>
                          );
                        }
                      )}
                    </h4>
                  </div>
                  <div className="studentregistration__span-2">
                    {PersonalDataBlock1AddedProps?.map((pd, index) => {
                      if (
                        pd.data_field_list_data_key === PredefinedDataTypes.CITY
                      ) {
                        return (
                          // eslint-disable-next-line
                          <div className="label-grid" key={index}>
                            <Label>{pd.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={City!}
                              value={
                                City?.find(
                                  ({ value }) => value === formData.std_place
                                )! ?? null
                              }
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place: (newValue as optionsType)
                                        ?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place: "",
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_place: e.target.value!,
                                      };
                                    });
                                  }}
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }

                      if (
                        pd.data_field_list_data_key ===
                        PredefinedDataTypes.DISTRICT
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={District!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_district: "",
                                    };
                                  });
                                }
                              }}
                              value={formData.std_district}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_district: (newValue as optionsType)
                                        ?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_district: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_district: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }

                      if (
                        pd.data_field_list_data_key ===
                        PredefinedDataTypes.STATE
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={States!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_state: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                States?.find(
                                  ({ value }) => value === formData.std_state
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_state: (newValue as optionsType)
                                        ?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_state: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_state: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      return (
                        <React.Fragment key={index}>
                          <Input
                            LabelName={pd.data_field_label}
                            name={pd.data_field_name}
                            values={formData[pd.data_field_name]}
                            maxLength={pd.maxLength}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            autoFocus={pd?.data_focus}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="details frame-space">
                    <h4>
                      {Students.PersonalDetails.Titles.map(
                        (title: StudentsTitlesType, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Correspondance}
                            </React.Fragment>
                          );
                        }
                      )}
                    </h4>
                  </div>
                  <div className="studentregistration__span-2">
                    <Label>Same as Permanent Address</Label>
                    <Checkbox
                      onClick={setCorrespondanceAddress}
                      onKeyDown={handleFormEvent}
                    />
                    {PersonalDataBlock2?.map((pd2, index) => {
                      if (
                        pd2.data_field_list_data_key ===
                        PredefinedDataTypes.CITY
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd2.data_field_label}</Label>
                            {checked ? (
                              <InputStory
                                name={pd2.data_field_name}
                                value={formData[pd2.data_field_name]}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                disabled={checked}
                              />
                            ) : (
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={CorrespondanceCity!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_place: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  CorrespondanceCity?.find(
                                    ({ value }) =>
                                      value === formData.std_corr_place
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_place: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_place: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                disabled={checked}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_corr_place: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            )}
                          </div>
                        );
                      }
                      if (
                        pd2.data_field_list_data_key ===
                        PredefinedDataTypes.DISTRICT
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd2.data_field_label}</Label>
                            {checked ? (
                              <InputStory
                                name={pd2.data_field_name}
                                value={formData[pd2.data_field_name]}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                disabled={checked}
                              />
                            ) : (
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={CorrespondanceDistrict!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_district: "",
                                      };
                                    });
                                  }
                                }}
                                value={formData.std_corr_district}
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_district: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_district: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                disabled={checked}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_corr_district: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            )}
                          </div>
                        );
                      }
                      if (
                        pd2.data_field_list_data_key ===
                        PredefinedDataTypes.STATE
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd2.data_field_label}</Label>
                            {checked ? (
                              <InputStory
                                name={pd2.data_field_name}
                                value={formData[pd2.data_field_name]}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                disabled={checked}
                              />
                            ) : (
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={CorrespondingStates!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_state: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  CorrespondingStates?.find(
                                    ({ value }) =>
                                      value === formData.std_corr_state
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_state: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_corr_state: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                disabled={checked}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_corr_state: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            )}
                          </div>
                        );
                      }
                      return (
                        <React.Fragment key={index}>
                          <Input
                            LabelName={pd2.data_field_label}
                            name={pd2.data_field_name}
                            values={formData[pd2.data_field_name]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            disabled={checked}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="details frame-space">
                    <h4>Health Details</h4>
                  </div>
                  <div className="studentregistration__span-2">
                    {PersonalDataBlock3?.map((pdb3, index: Key) => {
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.BLOODGROUP
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pdb3.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={BloodGroup!}
                              value={
                                BloodGroup?.find(
                                  ({ value }) =>
                                    value === formData.std_blood_group
                                )! ?? null
                              }
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_blood_group: "",
                                    };
                                  });
                                }
                              }}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_blood_group: (newValue as optionsType)
                                        ?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_blood_group: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_blood_group: e.target.value!,
                                      };
                                    });
                                    meta.handleChange(e);
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.RELIGION
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={Religion!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  Religion?.find(
                                    ({ value }) =>
                                      value === formData.std_religion
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_religion: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_name ===
                        StudentRegistrationFieldNames.MARRIED
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pdb3.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={YESNO!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_martial_status: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                YESNO?.find(
                                  ({ value }) =>
                                    value === formData.std_martial_status
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_martial_status: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_martial_status: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_martial_status: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pdb3.data_field_name ===
                        StudentRegistrationFieldNames.BELONGS_TO_JK
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pdb3.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={YESNO!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                YESNO?.find(
                                  ({ value }) =>
                                    value === formData.std_belongs_to_jk
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_belongs_to_jk: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pdb3.data_field_name ===
                        StudentRegistrationFieldNames.IS_KASHMIR_BRAHMIN
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pdb3.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={YESNO!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk_brahmin: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                YESNO?.find(
                                  ({ value }) =>
                                    value === formData.std_belongs_to_jk_brahmin
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk_brahmin: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk_brahmin: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_belongs_to_jk_brahmin:
                                          e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.CITY
                      ) {
                        return (
                          // eslint-disable-next-line
                          <div className="label-grid" key={index}>
                            <Label>{pdb3.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={PlaceOfBirthOptions!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place_of_birth: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                PlaceOfBirthOptions?.find(
                                  ({ value }) =>
                                    value === formData.std_place_of_birth
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place_of_birth: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place_of_birth: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_place_of_birth: e.target.value!,
                                      };
                                    });
                                  }}
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.NATIONALITY
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={NATIONS!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nationality: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  NATIONS?.find(
                                    ({ value }) =>
                                      value === formData.std_nationality
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nationality: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nationality: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_nationality: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.CASTE
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={Caste!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  Caste?.find(
                                    ({ value }) => value === formData.std_caste
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_caste: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        StudentRegistrationFieldNames.SUB_CASTE
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={pdb3.data_field_label}
                              name={pdb3.data_field_name}
                              values={formData.std_sub_caste}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                            />
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.SC_ST
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>

                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={SCST!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_sc_st: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  SCST?.find(
                                    ({ value }) => value === formData.std_sc_st
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_sc_st: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_sc_st: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_sc_st: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.CASTE_GROUP
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={CasteGroup!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_group: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  CasteGroup?.find(
                                    ({ value }) =>
                                      value === formData.std_caste_group
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_group: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_group: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_caste_group: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_SPORTS_PERSON
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_sports_person: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_sports_person
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_sports_person: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_sports_person: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_sports_person: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_name ===
                        StudentRegistrationFieldNames.MINORITY
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_minority: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_caste_minority
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_minority: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_minority: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_caste_minority: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        StudentRegistrationFieldNames.AREA
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={pdb3.data_field_label}
                              name={pdb3.data_field_name}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData.std_rural_urban}
                            />
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_list_data_key ===
                        PredefinedDataTypes.MOTHER_TONGUE
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                id="combo-box-demo"
                                options={MotherTongue!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_mother_tongue: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  MotherTongue?.find(
                                    ({ value }) =>
                                      value === formData.std_mother_tongue
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_mother_tongue: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_mother_tongue: "",
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_mother_tongue: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pdb3.data_field_name ===
                        StudentRegistrationFieldNames.PHYSICALLY_CHALLENGED
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pdb3.data_field_label}</Label>

                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_phy_challenged
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_phy_challenged: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_phy_challenged: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_phy_challenged: "",
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_phy_challenged:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      return (
                        <React.Fragment key={index}>
                          <Input
                            LabelName={pdb3.data_field_label}
                            name={pdb3.data_field_name}
                            values={
                              formData[pdb3.data_field_name] === 0
                                ? ""
                                : formData[pdb3.data_field_name]
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="details frame-space">
                    <h4>Other Details</h4>
                  </div>
                  <div className="studentregistration__span-2">
                    {PersonalDataBlock4?.map((pd4, index: Key) => {
                      if (
                        pd4.data_field_list_data_key ===
                        PredefinedDataTypes.RELIGION
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={Religion!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  Religion?.find(
                                    ({ value }) =>
                                      value === formData.std_religion
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_religion: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_list_data_key ===
                        PredefinedDataTypes.RELIGION
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={Religion!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  Religion?.find(
                                    ({ value }) =>
                                      value === formData.std_religion
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_religion: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_religion: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.MARRIED
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd4.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={YESNO!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_martial_status: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                YESNO?.find(
                                  ({ value }) =>
                                    value === formData.std_martial_status
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_martial_status: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_martial_status: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_martial_status: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.BELONGS_TO_JK
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd4.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={YESNO!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                YESNO?.find(
                                  ({ value }) =>
                                    value === formData.std_belongs_to_jk
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_belongs_to_jk: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.IS_KASHMIR_BRAHMIN
                      ) {
                        return (
                          <div className="label-grid" key={index}>
                            <Label>{pd4.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={YESNO!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk_brahmin: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                YESNO?.find(
                                  ({ value }) =>
                                    value === formData.std_belongs_to_jk_brahmin
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk_brahmin: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_belongs_to_jk_brahmin: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_belongs_to_jk_brahmin:
                                          e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (pd4.data_field_name === "std_place_of_birth") {
                        return (
                          // eslint-disable-next-line
                          <div className="label-grid" key={index}>
                            <Label>{pd4.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={PlaceOfBirthOptions!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place_of_birth: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                PlaceOfBirthOptions?.find(
                                  ({ value }) =>
                                    value === formData.std_place_of_birth
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place_of_birth: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_place_of_birth: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_place_of_birth: e.target.value!,
                                      };
                                    });
                                  }}
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pd4.data_field_name === "std_nearest_police_station"
                      ) {
                        return (
                          // eslint-disable-next-line
                          <div className="label-grid" key={index}>
                            <Label>{pd4.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={PoliceStationOptions!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_nearest_police_station: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                PoliceStationOptions?.find(
                                  ({ value }) =>
                                    value ===
                                    formData.std_nearest_police_station
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_nearest_police_station: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_nearest_police_station: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nearest_police_station:
                                          e.target.value!,
                                      };
                                    });
                                  }}
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pd4.data_field_name === "std_nearest_railway_station"
                      ) {
                        return (
                          // eslint-disable-next-line
                          <div className="label-grid" key={index}>
                            <Label>{pd4.data_field_label}</Label>
                            <LabeledAutocomplete
                              className={labelClasses.inputRoot}
                              options={RailwayStationOptions!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_nearest_railway_station: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                RailwayStationOptions?.find(
                                  ({ value }) =>
                                    value ===
                                    formData.std_nearest_railway_station
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_nearest_railway_station: (
                                        newValue as optionsType
                                      )?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_nearest_railway_station: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nearest_railway_station:
                                          e.target.value!,
                                      };
                                    });
                                  }}
                                  className={labelClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      if (
                        pd4.data_field_list_data_key ===
                        PredefinedDataTypes.NATIONALITY
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={NATIONS!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nationality: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  NATIONS?.find(
                                    ({ value }) =>
                                      value === formData.std_nationality
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nationality: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_nationality: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_nationality: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_list_data_key ===
                        PredefinedDataTypes.CASTE
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={Caste!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  Caste?.find(
                                    ({ value }) => value === formData.std_caste
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_caste: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_list_data_key ===
                        StudentRegistrationFieldNames.SUB_CASTE
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={pd4.data_field_label}
                              name={pd4.data_field_name}
                              values={formData.std_sub_caste}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                            />
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_list_data_key ===
                        PredefinedDataTypes.SC_ST
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>

                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={SCST!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_sc_st: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  SCST?.find(
                                    ({ value }) => value === formData.std_sc_st
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_sc_st: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_sc_st: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_sc_st: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_list_data_key ===
                        PredefinedDataTypes.CASTE_GROUP
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={CasteGroup!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_group: "",
                                      };
                                    });
                                  }
                                }}
                                value={
                                  CasteGroup?.find(
                                    ({ value }) =>
                                      value === formData.std_caste_group
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_group: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_group: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_caste_group: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.MINORITY
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_minority: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_caste_minority
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_minority: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_caste_minority: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_caste_minority: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_list_data_key ===
                        StudentRegistrationFieldNames.AREA
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={pd4.data_field_label}
                              name={pd4.data_field_name}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData.std_rural_urban}
                            />
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_list_data_key ===
                        PredefinedDataTypes.MOTHER_TONGUE
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                id="combo-box-demo"
                                options={MotherTongue!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_mother_tongue: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  MotherTongue?.find(
                                    ({ value }) =>
                                      value === formData.std_mother_tongue
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_mother_tongue: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_mother_tongue: "",
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_mother_tongue: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.PHYSICALLY_CHALLENGED
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>

                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_phy_challenged
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_phy_challenged: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_phy_challenged: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_phy_challenged: "",
                                      };
                                    });
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_phy_challenged:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_SPORTS_PERSON
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_sports_person: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_sports_person
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_sports_person: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_sports_person: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_sports_person: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_NCC
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_ncc: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) => value === formData.std_is_ncc
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_ncc: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_ncc: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_ncc: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_NSS
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_nss: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) => value === formData.std_is_nss
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_nss: (newValue as optionsType)
                                          ?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_nss: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_nss: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_GADINADU_HORANADU
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_gadinadu_horanadu: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value ===
                                      formData.std_is_gadinadu_horanadu
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_gadinadu_horanadu: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_gadinadu_horanadu: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_gadinadu_horanadu:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_DEFENCE
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_defence: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_defence
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_defence: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_defence: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_defence: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_POLITICAL_SUFFERER
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_political_sufferer: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value ===
                                      formData.std_is_political_sufferer
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_political_sufferer: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_political_sufferer: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_political_sufferer:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_CULTURAL
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_cultural: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_cultural
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_cultural: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_cultural: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_cultural: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_HIV_DEVADASI
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_hiv_devadasi: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_hiv_devadasi
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_hiv_devadasi: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_hiv_devadasi: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_hiv_devadasi: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_CHILD_OF_FREEDOM_FIGHTER
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_child_of_freedom_fighter:
                                          EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value ===
                                      formData.std_is_child_of_freedom_fighter
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_child_of_freedom_fighter: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_child_of_freedom_fighter:
                                          EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_child_of_freedom_fighter:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_DESTITUTE_WOMAN
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_destitude_woman: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_destitude_woman
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_destitude_woman: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_destitude_woman: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_destitude_woman:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_CHILD_OF_FARMER
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_child_of_farmer: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_child_of_farmer
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_child_of_farmer: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_child_of_farmer: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_child_of_farmer:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_NIRASHRITHARU
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_nirashritharu: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_nirashritharu
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_nirashritharu: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_nirashritharu: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_nirashritharu: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }

                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_BPL_CARD
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_bpl_card: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_bpl_card
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_bpl_card: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_bpl_card: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_bpl_card: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_NON_EMP_STATUS
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_non_emp_status: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_non_emp_status
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_non_emp_status: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_non_emp_status: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_non_emp_status:
                                            e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (
                        pd4.data_field_name ===
                        StudentRegistrationFieldNames.STD_IS_KSOU_EMP
                      ) {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{pd4.data_field_label}</Label>
                              <LabeledAutocomplete
                                className={labelClasses.inputRoot}
                                options={YESNO!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_ksou_emp: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  YESNO?.find(
                                    ({ value }) =>
                                      value === formData.std_is_ksou_emp
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_ksou_emp: (
                                          newValue as optionsType
                                        )?.value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_is_ksou_emp: EMPTY_STRING,
                                      };
                                    });
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                openOnFocus
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_is_ksou_emp: e.target.value!,
                                        };
                                      });
                                    }}
                                    fullWidth
                                    className={labelClasses.formControlRoot}
                                  />
                                )}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment key={index}>
                          <Input
                            LabelName={pd4.data_field_label}
                            name={pd4.data_field_name}
                            values={
                              formData[pd4.data_field_name] === 0
                                ? ""
                                : formData[pd4.data_field_name]
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                {studentData.data && operation === Operation.UPDATE ? (
                  <Button type="submit" mode="save" />
                ) : (
                  <Button mode="save-continue" type="submit" />
                )}
                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    if (operation === Operation.UPDATE) {
                      navigate(
                        `/${InstId}/admissions/students/${studentId}/personaldata`
                      );
                    } else {
                      navigate(
                        `/${InstId}/admissions/students/1/${studentId}/basicDetails`
                      );
                    }
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <LoadingModal flag={updationLoading} />
    </>
  );
};

export default PersonalData;

import { gql } from "@apollo/client";

export const GetAcdStudents = gql`
  query GetAcdStudents(
    $name: String
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $acd_yr_id: ID!
    $input: AcdStdQueryData!
  ) {
    GetAcdStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        or: [
          { firstNameContainsFold: $name }
          { stdAdmNoContainsFold: $name }
          { stdMobileContainsFold: $name }
          { stdRegNoContainsFold: $name }
        ]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          std_adm_no
          first_name
          middle_name
          last_name
          std_father_name
          std_mother_name
          std_reg_no
          std_dob
          category_id
          std_status
          std_sex
          std_roll_no
          std_fresher
          std_email
          std_mobile
          is_login_created
          std_joining_acd_yr
          category {
            id
            cat_desc
          }
          acd_branch {
            id
            branch_desc
          }
          acd_class {
            id
            class_desc
          }
          acd_dept {
            id
            dept_desc
          }
          acd_semester {
            id
            sem_desc
          }
          acd_section {
            id
            section_desc
          }
          misc_data {
            lib_book_count
            availed_transport
            availed_hostel
          }
        }
      }
    }
  }
`;

export const GetAcdStudentsForAdmissions = gql`
  query GetAcdStudents(
    $name: String
    $status: String
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $acd_yr_id: ID!
    $input: AcdStdQueryData!
  ) {
    GetAcdStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        and: [
          {
            or: [
              { firstNameContainsFold: $name }
              { middleNameContainsFold: $name }
              { lastNameContainsFold: $name }
              { stdAdmNoContainsFold: $name }
              { stdRegNoContainsFold: $name }
            ]
          }
          { stdStatusContainsFold: $status }
        ]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          std_adm_no
          std_reg_no
          first_name
          middle_name
          last_name
          std_joining_acd_yr
          std_father_name
          std_mother_name
          std_profile_filename
          std_sex
          std_doa
          std_dob
          std_email
          std_mobile
          std_roll_no
          std_status
          category {
            id
            cat_desc
          }
          acd_branch {
            id
            branch_desc
          }
          acd_class {
            id
            class_desc
          }
          acd_dept {
            id
            dept_desc
          }
          acd_semester {
            id
            sem_desc
          }
          acd_section {
            id
            section_desc
          }
          std_details {
            std_phone
            std_mother_tongue
            std_religion
            std_address
            std_place
            std_district
            std_state
            std_caste
            std_caste_group
          }
          misc_data {
            is_transaction_started
            is_tc_issued
            lib_book_count
            mobile_app_count
            availed_transport
            availed_hostel
            is_route_assgined
            per_std_subj_allocation
          }
        }
      }
    }
  }
`;

export const GetAcdStudentsNameAndId = gql`
  query GetAcdStudents(
    $name: String
    $status: String
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $acd_yr_id: ID!
    $input: AcdStdQueryData!
  ) {
    GetAcdStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        or: [
          { firstNameContainsFold: $name }
          { stdAdmNoContainsFold: $name }
          { stdMobileContainsFold: $name }
          { stdStatusContainsFold: $status }
        ]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
  }
`;

export const GetAcdStudentsByAdmNo = gql`
  query GetAcdStudents(
    $name: String
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $acd_yr_id: ID!
    $input: AcdStdQueryData!
  ) {
    GetAcdStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        or: [{ firstNameContainsFold: $name }, { stdAdmNoContainsFold: $name }]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          std_adm_no
          first_name
          middle_name
          last_name
        }
      }
    }
  }
`;
export const GetAcdStudentsForCount = gql`
  query GetAcdStudents(
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $acd_yr_id: ID!
    $input: AcdStdQueryData!
  ) {
    GetAcdStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      input: $input
    ) {
      totalCount
    }
  }
`;
export const GetAcdStdOverallPerformance = gql`
  query GetAcdStdOverallPerformance(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdStdOverallPerformance(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_id: $student_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      subj_name_1
      subj_name_2
      subj_name_3
      subj_name_4
      subj_name_5
      subj_name_6
      subj_name_7
      subj_name_8
      subj_name_9
      subj_name_10
      subj_name_11
      subj_name_12
      subj_name_13
      subj_name_14
      subj_name_15
      subj_percentage_1
      subj_percentage_2
      subj_percentage_3
      subj_percentage_4
      subj_percentage_5
      subj_percentage_6
      subj_percentage_7
      subj_percentage_8
      subj_percentage_9
      subj_percentage_10
      subj_percentage_11
      subj_percentage_12
      subj_percentage_13
      subj_percentage_14
      subj_percentage_15
      test_top_percentage
      test_avg_percentage
    }
  }
`;

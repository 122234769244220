import { gql } from "@apollo/client";

export const UpdateAgentDetails = gql`
  mutation UpdateAgentDetails(
    $token: String!
    $inst_id: ID!
    $agent_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMstInstAgentDetailsInput!
  ) {
    UpdateAgentDetails(
      token: $token
      inst_id: $inst_id
      agent_id: $agent_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const AssoicateAgentAndStds = gql`
  mutation AssoicateAgentAndStds(
    $token: String!
    $inst_id: ID!
    $agent_id: ID!
    $user_details: SubscribedUser!
    $stdIds: [ID!]!
  ) {
    AssoicateAgentAndStds(
      token: $token
      inst_id: $inst_id
      agent_id: $agent_id
      user_details: $user_details
      stdIds: $stdIds
    )
  }
`;

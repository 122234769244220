import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import { Direction } from "../../../../utils/Enum.types";
import { responseType } from "../../../../utils/Form.types";
import { PageInfo } from "../../../Templates/utils/types";
import { GetAcctStockItems } from "../../queries/purchages/queries";
import {
  GetAcctStockItemCategorysNode,
  GetAcctStockItemGroupsNode,
  GetAcctStockItemUmNode,
} from "./usePurchasesMasterData";

interface GetAcctStockItemsNode {
  id: number;
  item_desc: string;
  item_type: string;
  item_in_use: boolean;
  item_last_purchased_date: string;
  item_last_purchased_rate: number;
  item_last_purchased_tax: number;
  item_group_details: GetAcctStockItemGroupsNode;
  item_category_details: GetAcctStockItemCategorysNode;
  item_uom_details: GetAcctStockItemUmNode;
}
interface GetAcctStockItemsEdges {
  node: GetAcctStockItemsNode;
}
interface GetAcctStockItemCategorysDetails {
  edges: GetAcctStockItemsEdges[];
  totalCount: number;
  pageInfo: PageInfo;
}
interface GetAcctStockItemsDetailsData {
  GetAcctStockItems: GetAcctStockItemCategorysDetails;
}
export enum PuchanseItemQueryType {
  ALL_ITEMS = "ALL_ITEMS",
  ACTIVE_ITEMS = "ACTIVE_ITEMS",
}
interface Vars {
  token: string;
  inst_id: string;
  first: number;
  after: string | null;
  itemName: string;
  orderBy: { direction: Direction; field?: string };
  query_type: PuchanseItemQueryType;
}
const usePurchaseItems = (
  query_type: PuchanseItemQueryType,
  itemName: string
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [purchaseItemResponseType, setPurchaseItemResponseType] = useState<
    responseType[]
  >([]);
  const [GetItems, { data, loading, error }] = useLazyQuery<
    GetAcctStockItemsDetailsData,
    Vars
  >(GetAcctStockItems);
  useEffect(() => {
    if (token && InstId && query_type) {
      GetItems({
        variables: {
          after: null,
          first: ROWS_PER_PAGE,
          inst_id: InstId,
          orderBy: { direction: Direction.ASC },
          query_type,
          token,
          itemName,
        },
      }).then(({ data }) => {
        if (data) {
          setPurchaseItemResponseType(
            data.GetAcctStockItems.edges.map((edge) => ({
              label: edge.node.item_desc,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [token, InstId, itemName, GetItems, query_type]);
  return { Items: { data, loading, error, purchaseItemResponseType } };
};

export default usePurchaseItems;

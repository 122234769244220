import { gql } from "@apollo/client";

export const AddLibFine = gql`
  mutation AddLibFine(
    $token: String!
    $inst_id: ID!
    $input: FineInputData!
    $user_details: SubscribedUser!
  ) {
    AddLibFine(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

export const AssociateLibFine = gql`
  mutation AssociateLibFine(
    $token: String!
    $id: ID!
    $for_student: Boolean!
    $for_staff: Boolean!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    AssociateLibFine(
      token: $token
      id: $id
      for_student: $for_student
      for_staff: $for_staff
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../../../styles/DataGridTableStyles";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { attendanceReportType } from "../../../utils/Enum.types";
import { GridAlignment } from "@mui/x-data-grid-pro";

const useAcademicTableJson = () => {
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const tableJson = {
    TeacherWise: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "EMP ID",
          cellClassName: "td-adm-no",
          field: "emp_id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },

        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Designation",
          cellClassName: "td-status",
          field: "designation",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
      ],
    },
    PerTeacher: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        ...(USE_DEPARTMENT_KEY
          ? [
              {
                headerName: `${departmentLabel}`,
                cellClassName: "td-desc",
                field: "dept",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
                flex: 1,
              },
            ]
          : []),
        ...(USE_BRANCH_KEY
          ? [
              {
                headerName: `${branchLabel}`,
                cellClassName: "td-desc",
                field: "branch",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_CLASS_KEY
          ? [
              {
                headerName: `${classLabel}`,
                cellClassName: "td-desc",
                field: "class",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_SEMESTER_KEY
          ? [
              {
                headerName: `${semesterLabel}`,
                cellClassName: "td-desc",
                field: "sem",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_SECTION_KEY
          ? [
              {
                headerName: `${sectionLabel}`,
                cellClassName: "td-desc",
                field: "sec",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
      ],
    },
    SubjectDetails: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Subject Code",
          cellClassName: "td-sub-code",
          field: "sub_code",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Subject Board Code",
          cellClassName: "td-sub-code",
          field: "board_code",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "Subject Description",
          cellClassName: "td-name",
          field: "sub_desc",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
      ],
    },
    ViewSubjects: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Subject Code",
          cellClassName: "td-sub-code",
          field: "sub_code",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Subject Board Code",
          cellClassName: "td-sub-code",
          field: "board_code",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "Subject Description",
          cellClassName: "td-desc",
          field: "sub_desc",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          headerName: "Subject Type",
          cellClassName: "td-desc",
          field: "sub_type",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
    SubjectAllocationToTeachers: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Subject Code",
          cellClassName: "td-sub-code",
          field: "sub_code",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Subject Board Code",
          cellClassName: "td-sub-code",
          field: "board_code",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "Subject Description",
          cellClassName: "td-desc",
          field: "sub_desc",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          headerName: "Teacher's Count",
          cellClassName: "td-sub-code",
          field: "teachers_count",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
      ],
    },

    FeedbackSubwise: {
      TableHeader: [
        {
          labelName: "Sl",
        },
        {
          labelName: "Subject Code",
        },
        {
          labelName: "Subjects",
        },
        {
          labelName: "Subject Teacher",
        },
        {
          labelName: "Total Students",
        },
        {
          labelName: "Filled",
        },
        {
          labelName: "Action",
        },
      ],
    },
    FeedbackQuestionWise: {
      TableHeader: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Rating Factor",
          cellClassName: "td-name",
          field: "rating_factor",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          headerName: "Excellent",
          cellClassName: "td-number",
          field: "excellent",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Good",
          cellClassName: "td-number",
          field: "good",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Average",
          cellClassName: "td-number",
          field: "average",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Fair",
          cellClassName: "td-number",
          field: "fair",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Poor",
          cellClassName: "td-number",
          field: "poor",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
      ],
    },
    FormNotFilledStudents: {
      TableHeader: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Admission No.",
          cellClassName: "td-adm-no",
          field: "adm_no",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          headerName: "Reg No.",
          cellClassName: "td-reg-no",
          field: "reg_no",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },

        {
          headerName: "Phone No",
          cellClassName: "td-mobile",
          field: "mobile",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
    FeedBackTeacherWise: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "EMP ID",
          cellClassName: "td-adm-no",
          field: "emp_id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },

        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "User Type",
          cellClassName: "td-status",
          field: "user_type",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Designation",
          cellClassName: "td-status",
          field: "designation",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
      ],
    },
    TeachersAllotedList: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "EMP ID",
          cellClassName: "td-adm-no",
          field: "emp_id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },

        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Designation",
          cellClassName: "td-status",
          field: "designation",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
      ],
    },
    AllTeachersList: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "EMP ID",
          cellClassName: "td-adm-no",
          field: "emp_id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },

        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Designation",
          cellClassName: "td-status",
          field: "designation",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Mobile No.",
          cellClassName: "td-marks font-blue",
          field: "assoc_class",
          headerAlign: HEADER_TEXT_ALIGN,
          align: MARKS_TEXT_ALIGN,
        },
      ],
    },
    AbsentiesList: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Adm No.",
          cellClassName: "td-adm-no",
          field: "adm_no",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Reg No.",
          cellClassName: "td-adm-no",
          field: "reg_no",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Student Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        ...(USE_DEPARTMENT_KEY
          ? [
              {
                headerName: `${departmentLabel}`,
                cellClassName: "td-desc",
                field: "dept",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
                flex: 1,
              },
            ]
          : []),
        ...(USE_BRANCH_KEY
          ? [
              {
                headerName: `${branchLabel}`,
                cellClassName: "td-desc",
                field: "branch",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_CLASS_KEY
          ? [
              {
                headerName: `${classLabel}`,
                cellClassName: "td-desc",
                field: "class",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_SEMESTER_KEY
          ? [
              {
                headerName: `${semesterLabel}`,
                cellClassName: "td-desc",
                field: "sem",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
        ...(USE_SECTION_KEY
          ? [
              {
                headerName: `${sectionLabel}`,
                cellClassName: "td-desc",
                field: "sec",
                headerAlign: HEADER_TEXT_ALIGN,
                align: "",
              },
            ]
          : []),
      ],
    },
    OverAttReport: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "Adm No.",
          cellClassName: "td-adm-no",
          field: "adm_no",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Reg No.",
          cellClassName: "td-adm-no",
          field: "reg_no",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Total Attendance Held",
          cellClassName: "td-number font-blue",
          field: "att_held",
          headerAlign: HEADER_TEXT_ALIGN,
          align: MARKS_TEXT_ALIGN,
          className: "font-blue",
        },
        {
          headerName: "Total Present",
          cellClassName: "td-number ",
          field: "total_present",
          headerAlign: HEADER_TEXT_ALIGN,
          align: MARKS_TEXT_ALIGN,
          className: "font-green",
        },
        {
          headerName: "Total Absent",
          cellClassName: "td-number",
          field: "total_absent",
          headerAlign: HEADER_TEXT_ALIGN,
          align: MARKS_TEXT_ALIGN,
          className: "font-red",
        },
        {
          headerName: "Total Percentage",
          cellClassName: "td-number",
          field: "total_perc",
          headerAlign: HEADER_TEXT_ALIGN,
          align: MARKS_TEXT_ALIGN,
          className: "font-purple",
        },
      ],
    },
    MonthWiseAtt: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "Adm No.",
          cellClassName: "td-adm-no",
          field: "adm_no",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Reg No.",
          cellClassName: "td-adm-no",
          field: "reg_no",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
      ],
    },
  };
  return tableJson;
};

export default useAcademicTableJson;

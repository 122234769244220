import { useEffect } from "react";
import useToken from "../../../../../../customhooks/useToken";
import { useLazyQuery } from "@apollo/client";
import { ChannelDetails } from "../../../../../../queries/chatapplication/list/nodequeries";
import { singleNodeVars } from "../../../../../../Types/Accounting";
interface ChannelDetailsData {
  node: {
    channel_name: string;
    channel_desc: string;
  };
}

const useChannelDetailByNodeId = (channel_id: number) => {
  const { token } = useToken();
  const [GetChannelDetails, { data }] = useLazyQuery<
    ChannelDetailsData,
    singleNodeVars
  >(ChannelDetails, {
    variables: {
      id: channel_id,
      token,
    },
  });

  useEffect(() => {
    if (token && channel_id) {
      GetChannelDetails();
    }
  }, [GetChannelDetails, token, channel_id]);

  return { channelData: data };
};

export default useChannelDetailByNodeId;

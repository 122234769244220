import React, { useState } from "react";
import Modal from "react-modal";
import { OrganizationalAddDepartment } from "../../../styles/ModalStyles";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { ModalFlagType } from "./TreeView";
import { PayRollMasters } from "../enums/Enum.types";
import Close from "../../../images/Close.svg";
import { toStandardCase } from "../../../utils/UtilFunctions";
import { useMutation } from "@apollo/client";
import { AddPayRollDept } from "../queries/department/mutation";
import { AddPayRollDesignation } from "../queries/designation/mutation";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router";
import MessageModal from "../../../pages/MessageModal";
import { Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import { GetPayRollMasterData } from "../queries/general/list";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
interface Props {
  modalFlag: ModalFlagType;
  setModalFlag: React.Dispatch<React.SetStateAction<ModalFlagType>>;
}
const AddModal = ({ modalFlag, setModalFlag }: Props) => {
  const [description, setDescription] = useState("");
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [AddDepartment] = useMutation(AddPayRollDept, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [AddDesignation] = useMutation(AddPayRollDesignation, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (modalFlag.type === PayRollMasters.DEPARTMENT) {
      AddDepartment({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input: {
            dept_desc: description,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Department Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      AddDesignation({
        variables: {
          token,
          inst_id: InstId,
          user_details,

          input: {
            designation_desc: description,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Designation Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const hanldeClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
    setModalFlag({
      flag: false,
      type: PayRollMasters.DEPARTMENT,
    });
    setDescription("");
  };

  return (
    <>
      <Modal
        isOpen={modalFlag.flag}
        ariaHideApp={false}
        style={OrganizationalAddDepartment}
      >
        <div className="modal-flex h-100">
          <form className="modal-flex__data h-100" onSubmit={handleSubmit}>
            <Title variant="subtitle1">
              Add New {toStandardCase(modalFlag.type)}
            </Title>
            <div className="label-grid">
              <Label>Description</Label>
              <Input
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                autoFocus
                required
              />
            </div>
            <Button mode="save" type="submit" />
            <Button
              mode="clear"
              type="button"
              onClick={() => {
                setDescription("");
              }}
            />
            <Button
              mode="cancel"
              type="button"
              onClick={() => {
                setModalFlag({
                  type: PayRollMasters.DEPARTMENT,
                  flag: false,
                });
                setDescription("");
              }}
            />
          </form>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setModalFlag({
                  type: PayRollMasters.DEPARTMENT,
                  flag: false,
                });
                setDescription("");
              }}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={hanldeClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default AddModal;

import React, { useState } from "react";
import Modal from "react-modal";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import { studentRecepit } from "../../../../Types/Accounting";
import { formatter, toStandardDate } from "../../../../utils/UtilFunctions";
import { DEFAULT_TIME } from "../../../../utils/constants";

import { ChooseLedgerModalStyles } from "../../../../styles/ModalStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { msgType } from "../../../../utils/Form.types";
import { Operation } from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";
import MessageModal from "../../../../pages/MessageModal";
import useAcctTableJson from "../../json/useAcctTableJson";

export type itemsType = studentRecepit[];
interface Props {
  items: itemsType;
  due_date: string;
  enableWaiveOff: boolean;
  waiveOffAmtMemoized: number;
  waiveOffAmt: number;
  totalFineAmt: number;
  setWaiveOffAmt: React.Dispatch<React.SetStateAction<number>>;
  setTotalFineAmt: React.Dispatch<React.SetStateAction<number>>;
  handleCloseModal: () => void;
  setWaiveOff?: React.Dispatch<React.SetStateAction<boolean>>;
}
const WaiveOfModal = ({
  items,
  enableWaiveOff,
  due_date,
  waiveOffAmt,
  totalFineAmt,
  waiveOffAmtMemoized,
  setWaiveOffAmt,
  setTotalFineAmt,
  handleCloseModal,
  setWaiveOff,
}: Props) => {
  const { format } = formatter;

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { Accounts_Table } = useAcctTableJson();

  return (
    <Modal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={enableWaiveOff}
      style={ChooseLedgerModalStyles}
      onRequestClose={() => {
        handleCloseModal();
      }}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <Title>Waive Off</Title>
          <div className="label-grid">
            <Label>New Due Date:</Label>
            <Label variant="LabelBold">
              {toStandardDate(due_date) === toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(due_date)}
            </Label>
          </div>

          <TableContainer className="student-fee-receipt__waive-off">
            <Table className="student-fee-receipt__waive-off--table">
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.StudentFeeReceipt.WaiveOff.filter(
                    (th) => th.labelName !== "Fine"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="student-fee-receipt__waive-off--table--slno">
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.acct_ldgr_details.ldgr_desc}</TableCell>
                      <TableCell className="student-fee-receipt__waive-off--table--date">
                        {toStandardDate(item.fee_due_date!)}
                      </TableCell>
                      {/* <TableCell className="student-fee-receipt__waive-off--table--amount">
                        {format(item.fine_amt)}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="label-grid">
            <Label>Waive-Off Amount</Label>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (Number(e.target.value) >= 0)
                  setWaiveOffAmt(Number(e.target.value));
              }}
              value={waiveOffAmt}
            />
          </div>

          <Button
            mode="save"
            onClick={() => {
              if (waiveOffAmt <= waiveOffAmtMemoized) {
                setWaiveOff?.(true);
                setTotalFineAmt(waiveOffAmtMemoized - waiveOffAmt);
                handleCloseModal();
              } else {
                setMessage({
                  flag: true,
                  message:
                    "Waive off amount is more than the total fine amount",
                  operation: Operation.NONE,
                });
              }
            }}
          />
          <Button mode="cancel" onClick={() => handleCloseModal()} />
        </div>

        <div className="modal-flex__image">
          <img
            src={Close}
            alt="/"
            className="modal-close-icon"
            onClick={() => {
              handleCloseModal();
            }}
          />
        </div>
        <MessageModal
          value={message.message}
          handleClose={() => {
            if (message.flag && message.operation !== Operation.NONE) {
              handleCloseModal();
            }
            setMessage({
              flag: false,
              message: "",
              operation: Operation.NONE,
            });
          }}
          modalFlag={message.flag}
          operation={message.operation}
        />
      </div>
    </Modal>
  );
};

export default WaiveOfModal;

export enum StudentAcdType {
  BY_ACD_INST_ID = "BY_ACD_INST_ID",
  BY_ACD_DEPT_ID = "BY_ACD_DEPT_ID",
  BY_ACD_BRANCH_ID = "BY_ACD_BRANCH_ID",
  BY_ACD_CLASS_ID = "BY_ACD_CLASS_ID",
  BY_ACD_SEMESTER_ID = "BY_ACD_SEMESTER_ID",
  BY_ACD_SECTION_ID = "BY_ACD_SECTION_ID",
  BY_ALLOTTED_LEVEL = "BY_ALLOTTED_LEVEL",
  ACD_STD_MARKS_BY_SUBJECT = "ACD_STD_MARKS_BY_SUBJECT",
  LIB_BOOK_CLEARANCE_BY_INST_ID = "LIB_BOOK_CLEARANCE_BY_INST_ID",
  LIB_BOOK_CLEARANCE_BY_DEPT_ID = "LIB_BOOK_CLEARANCE_BY_DEPT_ID",
  LIB_BOOK_CLEARANCE_BY_BRANCH_ID = "LIB_BOOK_CLEARANCE_BY_BRANCH_ID",
  LIB_BOOK_CLEARANCE_BY_CLASS_ID = "LIB_BOOK_CLEARANCE_BY_CLASS_ID",
  LIB_BOOK_CLEARANCE_BY_SEMESTER_ID = "LIB_BOOK_CLEARANCE_BY_SEMESTER_ID",
  LIB_BOOK_CLEARANCE_BY_SECTION_ID = "LIB_BOOK_CLEARANCE_BY_SECTION_ID",
  AVAILED_TRANSPORT_BY_ACD_INST_ID = "AVAILED_TRANSPORT_BY_ACD_INST_ID",
  AVAILED_TRANSPORT_BY_ACD_DEPT_ID = "AVAILED_TRANSPORT_BY_ACD_DEPT_ID",
  AVAILED_TRANSPORT_BY_ACD_BRANCH_ID = "AVAILED_TRANSPORT_BY_ACD_BRANCH_ID",
  AVAILED_TRANSPORT_BY_ACD_CLASS_ID = "AVAILED_TRANSPORT_BY_ACD_CLASS_ID",
  AVAILED_TRANSPORT_BY_ACD_SEMESTER_ID = "AVAILED_TRANSPORT_BY_ACD_SEMESTER_ID",
  AVAILED_TRANSPORT_BY_ACD_SECTION_ID = "AVAILED_TRANSPORT_BY_ACD_SECTION_ID",
  ROUTE_NOT_ASSIGNED_BY_INST_ID = "ROUTE_NOT_ASSIGNED_BY_INST_ID",
  ROUTE_NOT_ASSIGNED_BY_DEPT_ID = "ROUTE_NOT_ASSIGNED_BY_DEPT_ID",
  ROUTE_NOT_ASSIGNED_BY_BRANCH_ID = "ROUTE_NOT_ASSIGNED_BY_BRANCH_ID",
  ROUTE_NOT_ASSIGNED_BY_CLASS_ID = "ROUTE_NOT_ASSIGNED_BY_CLASS_ID",
  ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID = "ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID",
  ROUTE_NOT_ASSIGNED_BY_SECTION_ID = "ROUTE_NOT_ASSIGNED_BY_SECTION_ID",
  ROUTE_ASSIGNED_BY_ACD_INST_ID = "ROUTE_ASSIGNED_BY_ACD_INST_ID",
  ROUTE_ASSIGNED_BY_ACD_DEPT_ID = "ROUTE_ASSIGNED_BY_ACD_DEPT_ID",
  ROUTE_ASSIGNED_BY_ACD_BRANCH_ID = "ROUTE_ASSIGNED_BY_ACD_BRANCH_ID",
  ROUTE_ASSIGNED_BY_ACD_CLASS_ID = "ROUTE_ASSIGNED_BY_ACD_CLASS_ID",
  ROUTE_ASSIGNED_BY_ACD_SEMESTER_ID = "ROUTE_ASSIGNED_BY_ACD_SEMESTER_ID",
  ROUTE_ASSIGNED_BY_ACD_SECTION_ID = "ROUTE_ASSIGNED_BY_ACD_SECTION_ID",
  NXT_STUDENTS_BY_ACD_INST_ID = "NXT_STUDENTS_BY_ACD_INST_ID",
  NXT_STUDENTS_BY_ACD_DEPT_ID = "NXT_STUDENTS_BY_ACD_DEPT_ID",
  NXT_STUDENTS_BY_ACD_BRANCH_ID = "NXT_STUDENTS_BY_ACD_BRANCH_ID",
  NXT_STUDENTS_BY_ACD_CLASS_ID = "NXT_STUDENTS_BY_ACD_CLASS_ID",
  NXT_STUDENTS_BY_ACD_SEMESTER_ID = "NXT_STUDENTS_BY_ACD_SEMESTER_ID",
  NXT_STUDENTS_BY_ACD_SECTION_ID = "NXT_STUDENTS_BY_ACD_SECTION_ID",
  NXT_STUDENTS_BY_CATEGORY_ID = "NXT_STUDENTS_BY_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_DEPT_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_DEPT_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_BRANCH_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_BRANCH_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_CLASS_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_CLASS_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_SEMESTER_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_SEMESTER_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_SECTION_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_SECTION_ID_AND_CATEGORY_ID",
  CUR_BY_ACD_INST_ID = "CUR_BY_ACD_INST_ID",
  CUR_BY_ACD_DEPT_ID = "CUR_BY_ACD_DEPT_ID",
  CUR_BY_ACD_BRANCH_ID = "CUR_BY_ACD_BRANCH_ID",
  CUR_BY_ACD_CLASS_ID = "CUR_BY_ACD_CLASS_ID",
  CUR_BY_ACD_SEMESTER_ID = "CUR_BY_ACD_SEMESTER_ID",
  CUR_BY_ACD_SECTION_ID = "CUR_BY_ACD_SECTION_ID",
  CUR_BY_ACD_CATEGORY_ID = "CUR_BY_ACD_CATEGORY_ID",
  CUR_BY_ACD_DEPT_AND_CATEGORY_ID = "CUR_BY_ACD_DEPT_AND_CATEGORY_ID",
  CUR_BY_ACD_BRANCH_AND_CATEGORY_ID = "CUR_BY_ACD_BRANCH_AND_CATEGORY_ID",
  CUR_BY_ACD_CLASS_AND_CATEGORY_ID = "CUR_BY_ACD_CLASS_AND_CATEGORY_ID",
  CUR_BY_ACD_SEMESTER_AND_CATEGORY_ID = "CUR_BY_ACD_SEMESTER_AND_CATEGORY_ID",
  CUR_BY_ACD_SECTION_AND_CATEGORY_ID = "CUR_BY_ACD_SECTION_AND_CATEGORY_ID",
  BY_ENTRY_LEVEL_FOR_MAIN_ELECTIVE_SUBJ = "BY_ENTRY_LEVEL_FOR_MAIN_ELECTIVE_SUBJ",
  BY_ENTRY_LEVEL_AND_BY_SUBJ_ID = "BY_ENTRY_LEVEL_AND_BY_SUBJ_ID",
  ACD_PASSED_STDS_BY_ACD_INST_ID = "ACD_PASSED_STDS_BY_ACD_INST_ID",
  ACD_PASSED_STDS_BY_ACD_DEPT_ID = "ACD_PASSED_STDS_BY_ACD_DEPT_ID",
  ACD_PASSED_STDS_BY_ACD_BRANCH_ID = "ACD_PASSED_STDS_BY_ACD_BRANCH_ID",

  BY_COMBO_ID_AT_ALLOTED_LEVEL = "BY_COMBO_ID_AT_ALLOTED_LEVEL",
  COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL = "COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL",
  PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL = "PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL",
  CUR_STD_HAS_DOB_TODAY = "CUR_STD_HAS_DOB_TODAY",
  CUR_STD_HAS_DOB_IN_WEEK = "CUR_STD_HAS_DOB_IN_WEEK",
  CUR_STD_HAS_DOB_TODAY_AT_ENTRY_LEVEL = "CUR_STD_HAS_DOB_TODAY_AT_ENTRY_LEVEL",
  CUR_STD_HAS_DOB_IN_WEEK_AT_ENTRY_LEVEL = "CUR_STD_HAS_DOB_IN_WEEK_AT_ENTRY_LEVEL",
  ACD_STDS_BY_ENTRY_LEVEL = "ACD_STDS_BY_ENTRY_LEVEL",
  LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL = "LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL",
  LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL = "LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL",
}

export enum SubjectsByAcdYear {
  ACD_SUBJ_BY_INST_ID = "ACD_SUBJ_BY_INST_ID",
  ACD_SUBJ_BY_CLASS_ID = "ACD_SUBJ_BY_CLASS_ID",
  ACD_SUBJ_BY_SEMESTER_ID = "ACD_SUBJ_BY_SEMESTER_ID",
  ACD_SUBJ_BY_SECTION_ID = "ACD_SUBJ_BY_SECTION_ID",
  ACD_SUBJ_ELECTIVE_BY_CLASS_ID = "ACD_SUBJ_ELECTIVE_BY_CLASS_ID",
  ACD_SUBJ_ELECTIVE_BY_SEMESTER_ID = "ACD_SUBJ_ELECTIVE_BY_SEMESTER_ID",
  ACD_SUBJ_ELECTIVE_BY_SECTION_ID = "ACD_SUBJ_ELECTIVE_BY_SECTION_ID",
  NON_ELECTIVE_SUBJECTS = "NON_ELECTIVE_SUBJECTS",
  SUBJ_BY_COMBO_ID = "SUBJ_BY_COMBO_ID",
  SUBJ_AT_ALLOTED_LEVEL = "SUBJ_AT_ALLOTED_LEVEL",
}

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useState } from "react";
import UseSelect from "../../../../customhooks/UseSelect";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import { FeeReceiptBookTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import Pointer from "../../../../images/Pointer.svg";
import List from "./List/Index";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import { EMPTY_STRING } from "../../../../utils/constants";
import { payloadTypes } from "../../../../context/reducer";
import { VoucherQueryTypes } from "../../common/Enum.types";
const { AccountsTitles } = require("../../json/title.json");
interface ArrayDataType {
  label: string;
  url: string;
  value: number;
}
interface props {
  queryType: VoucherQueryTypes;
}
const Index = ({ queryType }: props) => {
  const [searchText, setSearchText] = useState("");
  const { InstId } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const { dispatch } = useContext(AppContext);
  const [selectedReceiptType, setSelectedReceiptType] = useState(0);

  const ReceiptTypes: ArrayDataType[] = [
    {
      value: 0,
      label: "Show All Receipts",
      url: `/${InstId}/accounts/reports/feereceiptbook/showallreceipts`,
    },
    {
      value: 1,
      label: "Demand Receipt",
      url: `/${InstId}/accounts/reports/feereceiptbook/demandreceipt`,
    },

    {
      value: 3,
      label: "Show Non Demand Student Receipts",
      url: `/${InstId}/accounts/reports/feereceiptbook/nondemandreceipts`,
    },
  ];
  const ReceiptTypeList = UseSelect(ReceiptTypes!, searchText);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.FeeReceiptBook.Titles.map(
          (title: FeeReceiptBookTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.FeeReceiptBook}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="fee-receipt-book">
        <div className="fee-receipt-book__typesblock">
          <TableContainer className="fee-receipt-book__typesblock--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Receipt Types</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="fee-receipt-book__typesblock--table--input">
                    <Input
                      size="small"
                      id="search"
                      placeholder="Search..."
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
                {ReceiptTypeList.map((data) => {
                  return (
                    <TableRow
                      key={data.value}
                      className={
                        selectedReceiptType === data.value
                          ? "selected-row"
                          : EMPTY_STRING
                      }
                      selected={selectedReceiptType === data.value}
                    >
                      <TableCell
                        onClick={() => {
                          navigate(data.url!);
                          dispatch({
                            type: payloadTypes.SET_FEE_RECEIPT_BOOK_NAME,
                            payload: {
                              receiptBookName: data.label,
                            },
                          });
                          setSelectedReceiptType(data.value!);
                        }}
                        className="bank-book__table--ledger"
                      >
                        {selectedReceiptType === data.value ? (
                          <img src={Pointer} alt="/" className="pointer-icon" />
                        ) : (
                          EMPTY_STRING
                        )}
                        {data.label}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="fee-receipt-book__tableblock">
          <List queryType={queryType} />
        </div>
      </div>
      <div className="fee-receipt-book__footer">
        <div className="button-left">
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default Index;

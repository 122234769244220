import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetAcctGroupLdgrs } from "../queries/FeeLedgers/query/Byid";

import { Direction, SortBy } from "../../../utils/Enum.types";
import { FeeLedgerPageInfo, ldgr_summary } from "./useAcctLedgerData";
import { responseType } from "../../../utils/Form.types";
import { EMPTY_RESPONSETYPE, ROWS_PER_PAGE } from "../../../utils/constants";
import { AcctGroupLdgrQueryType } from "../common/QueryTypes";
import { AppContext } from "../../../context/context";
interface acct_ldgr {
  id: number;
  ldgr_desc: string;
  ldgr_ob: number;
  ldgr_total_cr: number;
  ldgr_total_db: number;
  ldgr_cb: number;
  ldgr_summary: ldgr_summary[];
}
interface gr_ldgr_summary {
  id: number;
  gr_rp_show_idx: number;
  gr_rp_ob: number;
  gr_rp_cr: number;
  gr_rp_db: number;
  gr_rp_cb: number;
  gr_rp_rcpt_show: number;
  gr_rp_pymt_show: number;
  gr_ie_cb: number;
  gr_bs_ob: number;
  gr_bs_cr: number;
  gr_bs_db: number;
  gr_bs_cb: number;
}
export interface GetAcctGroupLdgrsDetailsNode {
  gr_ldgr_desc: string;
  id: number;
  gr_ldgr_ob: number;
  gr_ldgr_total_cr: number;
  gr_ldgr_total_db: number;
  gr_ldgr_cb: number;
  gr_ldgr_rp: string;
  acct_ldgr: acct_ldgr[];
  gr_ldgr_summary: gr_ldgr_summary[];
  gr_ldgr_aie: string;
}

export interface GroupLedgerEdge {
  node: GetAcctGroupLdgrsDetailsNode;
}
interface GetAcctGroupLdgrsDetails {
  edges: GroupLedgerEdge[];
  pageInfo: FeeLedgerPageInfo;
  totalCount: number;
}
export interface GetAcctGroupLdgrsData {
  GetAcctGroupLdgrs: GetAcctGroupLdgrsDetails;
}
interface input {
  acct_group_ldgr_query_type: string;
  ids: number[];
}
export interface GetAcctGroupLdgrsVars {
  token: string;
  input: input;
  first: number | null;
  direction: string;
  sortBy: string;
  name: string;
  id?: number | null;
  fin_yr_id: number;
}
const useGroupLedgerData = (
  name: string,
  queryType: AcctGroupLdgrQueryType,
  grpId?: number | null
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [acct_group_ldgr_query_type, setacct_group_ldgr_query_type] =
    useState("");
  const [ids, setIds] = useState([0]);
  const [responseType, setResponseType] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);

  const [GetGroupLedgers, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcctGroupLdgrsData,
    GetAcctGroupLdgrsVars
  >(GetAcctGroupLdgrs, {
    variables: {
      token,
      name,
      direction: Direction.ASC,
      first: ROWS_PER_PAGE,
      sortBy: SortBy.GR_LDGR_DESC,
      id: grpId ? grpId : null,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      input: {
        ids,
        acct_group_ldgr_query_type,
      },
    },
  });
  useEffect(() => {
    if (!InstId) return;

    switch (queryType) {
      case AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID:
      case AcctGroupLdgrQueryType.GROUP_STD_LDGR_BY_INST_ID:
      case AcctGroupLdgrQueryType.GROUP_LDGR_FOR_LIABILITIES:
      case AcctGroupLdgrQueryType.GROUP_LDGR_FOR_ASSETS:
        setacct_group_ldgr_query_type(queryType);
        setIds([Number(InstId)]);
        break;
      default:
        break;
    }
  }, [queryType, InstId, grpId, name]);
  useEffect(() => {
    if (state.ActiveFinYr && acct_group_ldgr_query_type !== "") {
      GetGroupLedgers().then(({ data }) => {
        if (data && data.GetAcctGroupLdgrs) {
          setResponseType(
            data.GetAcctGroupLdgrs.edges.map((edge) => ({
              label: edge.node.gr_ldgr_desc,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [
    GetGroupLedgers,
    state.ActiveFinYr,
    acct_group_ldgr_query_type,
    name,
    grpId,
    data,
  ]);
  return { groupLedgers: { data, loading, error, responseType, fetchMore } };
};

export default useGroupLedgerData;

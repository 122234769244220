import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DepartmentImage from "../../../images/department.svg";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import More from "../../../images/More.svg";
import { useLazyQuery } from "@apollo/client";
import { GetStudentEnquiryDashboardTree } from "../../../queries/students/list/newApi";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { toIsoDate } from "../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../utils/constants";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";

interface GetStudentEnquiryDashboardTreeVars {
  token: string;
  inst_id: string | number;
  enq_start_date: string;
  enq_end_date: string;
  today_date: string;
}
interface classList {
  class_name: string;
  class_id: number;
  class_desc: string;
  total_enq_count: number;
  total_vacant_seats: number;
  total_seats: number;
}
interface branchList {
  branch_name: string;
  branch_id: number;
  branch_desc: string;
  total_enq_count: number;
  total_vacant_seats: number;
  total_seats: number;
  class_details: classList[];
}

export interface tableType {
  level_name: string;
  descriptions: string;
  enq_count: number;
  id: number;
  total_seats: number;
  vacant_seats: number;
}
interface GetStudentEnquiryDashboardTreeData {
  GetStudentEnquiryDashboardTree: branchList[];
}
interface props {
  startDate: string;
  endDate: string;
}
interface keyPairType {
  [key: string]: number;
}
export interface level_ids extends keyPairType {
  level1: number;
  level2: number;
}
const EnquiredStudents = ({ startDate, endDate }: props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [table, setTable] = useState<tableType[]>([]);
  const { entry_level } = useInstitutionConfiguration();
  const [treeViewModal, setTreeViewModal] = useState(false);
  const { serverDate } = useServerDateandTime();
  const pollIntervalTime = usePollIntervalTime();

  const [GetStudentEnquiryDashboardTreeDetails, { data, loading }] =
    useLazyQuery<
      GetStudentEnquiryDashboardTreeData,
      GetStudentEnquiryDashboardTreeVars
    >(GetStudentEnquiryDashboardTree, {
      variables: {
        token,
        inst_id: InstId ? InstId : state.InstId,
        enq_start_date: startDate ? toIsoDate(startDate) : EMPTY_STRING,
        enq_end_date: endDate ? toIsoDate(endDate) : EMPTY_STRING,
        today_date: toIsoDate(serverDate),
      },
      fetchPolicy: "network-only",
      pollInterval: pollIntervalTime,
    });

  useEffect(() => {
    if (token && InstId) {
      GetStudentEnquiryDashboardTreeDetails();
    }
  }, [token, state.InstId, GetStudentEnquiryDashboardTreeDetails, InstId]);

  const [parent, setParent] = useState(0);
  const [levelId, setLevelId] = useState(0);

  const [level_ids, setLevelIds] = useState<level_ids>({
    level1: 0,
    level2: 0,
  });

  const totalCounts = table.reduce(
    (acc, item) => {
      acc.total_count += item.enq_count;
      acc.total_seats += item.total_seats;
      acc.total_vacant_seats += item.vacant_seats;
      return acc;
    },
    {
      total_count: 0,
      total_seats: 0,
      total_vacant_seats: 0,
    }
  );

  const setNextLevel = (id: number, step: number) => {
    if (data) {
      switch (step) {
        case 0:
          setTable(
            data.GetStudentEnquiryDashboardTree.map((tree_data) => ({
              level_name: tree_data.branch_name,
              id: tree_data.branch_id,
              descriptions: tree_data.branch_desc,
              enq_count: tree_data.total_enq_count,
              vacant_seats: tree_data.total_vacant_seats,
              total_seats: tree_data.total_seats,
            }))
          );
          setLevelIds({
            level1: 0,
            level2: 0,
          });
          setParent(step);
          break;
        case 1:
          setTable(
            data.GetStudentEnquiryDashboardTree.filter(
              ({ branch_id }) => branch_id === id
            ).flatMap((level1_data) => {
              const { class_details } = level1_data;
              return class_details.flatMap((level_2) => {
                return {
                  level_name: level_2.class_name,
                  id: level_2.class_id,
                  descriptions: level_2.class_desc,
                  enq_count: level_2.total_enq_count,
                  vacant_seats: level_2.total_vacant_seats,
                  total_seats: level_2.total_seats,
                };
              });
            })
          );
          setLevelIds({
            level1: id,
            level2: 0,
          });
          setParent(step);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      setTable(
        data.GetStudentEnquiryDashboardTree.map((tree_data) => ({
          level_name: tree_data.branch_name,
          id: tree_data.branch_id,
          descriptions: tree_data.branch_desc,
          enq_count: tree_data.total_enq_count,
          vacant_seats: tree_data.total_vacant_seats,
          total_seats: tree_data.total_seats,
        }))
      );
    }
  }, [data, loading]);
  return (
    <>
      <div className="enquiry-dashboard__details--block--title">
        <Title variant="subtitle1">Enquired Students</Title>
        <img src={More} alt="" />
      </div>

      <div className="enquiry-dashboard__tableblock">
        <TableContainer className="masters-dashboard__datablock--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <img
                    src={DepartmentImage}
                    alt="/"
                    className="academics-table-logo"
                  />
                </TableCell>

                <TableCell>
                  {table.length && table[0].level_name} Name
                </TableCell>

                <TableCell>Total</TableCell>

                <TableCell>Total Seats</TableCell>
                <TableCell>Vacant Seats</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table &&
                table.map((data, index: number) => {
                  return (
                    <TableRow
                      onClick={() => {
                        if (data.level_name === entry_level) {
                          setTreeViewModal(!treeViewModal);
                          setLevelId(data.id);
                        } else {
                          setNextLevel(data.id, parent + 1);
                        }
                      }}
                      key={index}
                    >
                      <TableCell
                        id="td-center"
                        className="masters-dashboard__datablock--table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="masters-dashboard__datablock--table--name">
                        {data?.descriptions}
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className="masters-dashboard__datablock--table--count"
                      >
                        {data.enq_count}
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className="masters-dashboard__datablock--table--count"
                      >
                        {data.total_seats}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={`masters-dashboard__datablock--table--count ${
                          data.vacant_seats < 0 ? "balance-count" : ""
                        }`}
                      >
                        {data.vacant_seats}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className="total" colSpan={2}>
                  Total:
                </TableCell>

                <TableCell className="totalcount">
                  {totalCounts.total_count}
                </TableCell>

                <TableCell className="totalcount">
                  {totalCounts.total_seats}
                </TableCell>
                <TableCell
                  className={`totalcount ${
                    totalCounts.total_vacant_seats < 0 ? "balance-count" : ""
                  }`}
                >
                  {totalCounts.total_vacant_seats}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>

      <Button
        mode="previous"
        onClick={() => {
          const position = parent - 1;
          const key = `level${position}`;
          setNextLevel(level_ids[key], parent - 1);
        }}
      />
    </>
  );
};

export default EnquiredStudents;

import { optionsType } from "../../../utils/Form.types";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
import { StudentStatus } from "../../../utils/Enum.types";
import { TextField } from "@mui/material";
import { isOptionEqualToValue } from "../../../utils/UtilFunctions";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
interface Props {
  statusValue: string;
  onChange: (newValue: optionsType | null) => void;
  label: string;
}

export const StatusOptions = [
  {
    label: "Current",
    value: StudentStatus.CUR,
  },
  {
    label: "Transfer Certificate (TC)",
    value: StudentStatus.TC,
  },
  {
    label: "Detained",
    value: StudentStatus.DET,
  },
  {
    label: "Not Eligible",
    value: StudentStatus.NE,
  },
  {
    label: "Reservation",
    value: StudentStatus.NXT,
  },
  {
    label: "Shortage of Attendance",
    value: StudentStatus.SOA,
  },
  {
    label: "Cancelled",
    value: StudentStatus.CXLD,
  },
  {
    label: "Passed Out",
    value: StudentStatus.PASS,
  },
];
const StatusAutocomplete = ({ statusValue, onChange, label }: Props) => {
  const foundValue = StatusOptions.find(({ value }) => value === statusValue);
  const autoValue = foundValue ? foundValue : null;
  const Autocomplete =
    label.length > 0 ? LabeledAutocomplete : FormAutocomplete;
  return (
    <Autocomplete
      className={label.length ? labelClasses.inputRoot : formClasses.inputRoot}
      options={StatusOptions}
      openOnFocus={true}
      {...(statusValue ? { value: autoValue } : {})}
      onChange={(e: React.SyntheticEvent<Element, Event>, value) => {
        onChange(value as optionsType | null);
      }}
      isOptionEqualToValue={(option) =>
        isOptionEqualToValue(option as optionsType, autoValue)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ""}
          fullWidth={true}

          slotProps={{
            inputLabel: {
              shrink: label.length > 0,
            },
          }}
          className={
            label.length > 0
              ? labelClasses.formControlRoot
              : formClasses.formControlRoot
          }
        />
      )}
    />
  );
};

export default StatusAutocomplete;

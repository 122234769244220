import { ExcelReportPageType } from "../../../../utils/Enum.types";
import { StatesProvider } from "./GlobalStates/StatesProvider";
import MainPage from "./MainPage";
import { ExcelAcctQueryType, ExcelAdmQueryType } from "./useExportMutation";
export interface ExportProps {
  reportType: ExcelAdmQueryType | ExcelAcctQueryType;
  pageType?: ExcelReportPageType;
  forVouchers?: boolean;
}
const Index = ({ reportType, pageType }: ExportProps) => {
  return (
    <StatesProvider>
      <MainPage reportType={reportType} pageType={pageType} />
    </StatesProvider>
  );
};

export default Index;

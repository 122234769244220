import Modal from "react-modal";
import { LoadingModalStyles } from "../styles/ModalStyles";
import loading from "../images/Loading.gif";
interface Props {
  flag: boolean;
  message?: string;
}
const LoadingModal = ({ flag, message }: Props) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={flag}
      style={LoadingModalStyles}
      ariaHideApp={false}
    >
      <div className="loading-time">
        <span> {message ? message : "Loading..."}</span>
        <br />
        <img src={loading} alt="loading" className="loading-gif" />
      </div>
    </Modal>
  );
};

export default LoadingModal;

import React, { useState, Key, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import InputHoc from "../../../components/common/Input/Index";

import { useParams } from "react-router-dom";
import Close from "../../../images/Close.svg";

import useToken from "../../../customhooks/useToken";

import { InstitutionCustomStyles } from "../../../styles/ModalStyles";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { LabelNameProps } from "../../../Types/Labels";
import LoadingModal from "../../../pages/LoadingModal";
import { Operation } from "../../../utils/Enum.types";
import MessageModal from "../../../pages/MessageModal";

import { ModalType } from "../../UserRights/LoginUser/Index";
import { createOwnerValidation } from "../utils/validationRules";
import { AddOwner, UpdateOwner } from "../queries/mutation";
import { CustOwnerDetialsByNode, GetOwners } from "../queries/List";
import { GetOwnerNode, OwnerType } from "../utils/types";
import { nodevars } from "../../../Types/Accounting";
import { msgType } from "../../../utils/Form.types";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { Label } from "../../../stories/Label/Label";
import PhoneInput from "react-phone-input-2";
import { Keys } from "../../../utils/Enum.keys";

const { Owner } = require("../json/form.json");
interface Props {
  openModal: ModalType;
  setOpenModal: React.Dispatch<React.SetStateAction<ModalType>>;
}

const CreateProprietor = ({ openModal, setOpenModal }: Props) => {
  const { token } = useToken();
  const { custId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const nameRef = document.getElementsByName(
    "emp_first_name"
  )[0] as HTMLInputElement;
  const saveRef = useRef<HTMLButtonElement>(null);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [formData, setFormData] = useState<OwnerType>({
    owner_name: "",
    owner_mobile: "",
    owner_email: "",
  });
  const [GetCustOwner, { data }] = useLazyQuery<GetOwnerNode, nodevars>(
    CustOwnerDetialsByNode,
    {
      variables: {
        ids: [openModal.id],
        token,
      },
    }
  );
  const [CreateOwner, { loading: creationLoading }] = useMutation(AddOwner, {
    onError: (e) =>
      setMessage({
        message: e.message,
        operation: Operation.NONE,
        flag: true,
      }),
  });

  const [UpdateLoginUser, { loading: updationLoading }] = useMutation(
    UpdateOwner,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      owner_mobile: mobileNo,
    }));
  };

  const HandleUserCreation = () => {
    if (openModal.operation === Operation.CREATE) {
      CreateOwner({
        variables: {
          token,
          input: {
            owner_name: formData.owner_name,
            owner_mobile: `+${formData.owner_mobile}`,
            owner_email: formData.owner_email,
            mst_customer_id: custId,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetOwners,
            variables: {
              cust_id: custId,
              token,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setOpenModal({
            id: 0,
            flag: false,
            operation: Operation.NONE,
          });
        }
      });
    } else {
      UpdateLoginUser({
        variables: {
          token,
          id: openModal.id,
          input: {
            ...formData,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetOwners,
            variables: {
              cust_id: custId,
              token,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setOpenModal({
            id: 0,
            flag: false,
            operation: Operation.NONE,
          });
        }
      });
    }
  };

  const handleClear = () => {
    setFormData({
      owner_name: "",
      owner_mobile: "",
      owner_email: "",
    });
    nameRef?.focus();
    setOpenModal({
      operation: Operation.NONE,
      flag: false,
      id: 0,
    });
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setOpenModal({
        operation: Operation.NONE,
        flag: false,
        id: 0,
      });
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (openModal.id && openModal.operation === Operation.UPDATE && token) {
      GetCustOwner().then(({ data }) => {
        if (data) {
          const { owner_name, owner_mobile, owner_email } = data.nodes[0];

          setFormData({
            owner_name,
            owner_mobile,
            owner_email,
          });
        }
      });
    }
  }, [openModal, data, GetCustOwner, token]);

  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={openModal.flag}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {openModal.operation === Operation.CREATE
                ? "Create Owner"
                : "Update Owner Details"}
            </Title>
            <Formik
              initialValues={formData}
              validationSchema={createOwnerValidation}
              onSubmit={HandleUserCreation}
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    <div className="row">
                      {Owner.CreateOwner?.map(
                        (label: LabelNameProps, index: Key) => {
                          return (
                            <React.Fragment key={index}>
                              <InputHoc
                                name={label.inputName}
                                LabelName={label.LabelName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                autoFocus={label.autoFocus}
                                values={formData[label.inputName]}
                                type={label.dataType}
                                required={label.required}
                                maxLength={label.maxLength}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                    <div className="label-grid">
                      <Label>Mobile</Label>
                      <PhoneInput
                        country={"in"}
                        value={formData.owner_mobile}
                        onChange={handleNumberChange}
                        inputProps={{
                          required: true,
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            saveRef?.current?.focus();
                          }
                        }}
                      />
                    </div>
                    <Button mode="save" type="submit" buttonref={saveRef!} />
                    <Button mode="clear" type="button" onClick={handleClear} />
                    <Button
                      mode="cancel"
                      type="button"
                      onClick={() => {
                        handleClear();
                        setOpenModal({
                          operation: Operation.NONE,
                          flag: false,
                          id: 0,
                        });
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setOpenModal({
                  operation: Operation.NONE,
                  flag: false,
                  id: 0,
                });
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default CreateProprietor;

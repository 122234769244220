import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../images/CalenderNextButton.svg";
import {
  DEFAULT_TIME,
  Friday,
  Monday,
  Saturday,
  Sunday,
  Thursday,
  Tuesday,
  Wednesday,
  days,
} from "../../../../utils/constants";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import {
  GetAcdAttStdDailyStatus,
  GetAcdAttStdTodaysStatus,
  GetAcdStdsForAttendance,
  GetAcdStudentsAttendance,
} from "../../queries/holidays/list";

import {
  DateRange,
  GetnumberOfDaysInMonth,
  MonthName,
  getMonthDateAndDay,
  getWeekByDayNum,
  handleDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../hooks/useAcdGeneralHolidays";
import {
  GetAcdAttStdDailyStatusData,
  GetAcdAttStdDailyStatusVars,
} from "../../types/attendance";
import { HolidayType, Operation, PageFor } from "../../../../utils/Enum.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Tooltip } from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import { INCREMENT_DECREMENT } from "../../../Enquiry/Dashboard/Index";
import CalendarLegends, {
  CalendarFor,
} from "../../../../pages/CalendarLegends";
import Close from "../../../../images/Close.svg";
import { AppContext } from "../../../../context/context";
import dayjs from "dayjs";
import AttendanceDeleteModal from "../../../../pages/AttendanceDeleteModal";
import { DeleteAttendanceForStudents } from "../../queries/holidays/mutation";
import LoadingModal from "../../../../pages/LoadingModal";
import { AttendenceQueryType } from "../../hooks/useAcdStudentAttendance";
import { GetAcdStudentAttendanceCount } from "../../queries/general";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const emptyDaysByWeek = (week: string) => {
  let numberOfEmpty = -1;
  let numberOfEmptyToLast = -1;
  switch (week) {
    case Sunday:
      numberOfEmpty = 6;
      numberOfEmptyToLast = 0;
      break;
    case Monday:
      numberOfEmpty = 0;
      numberOfEmptyToLast = 6;
      break;
    case Tuesday:
      numberOfEmpty = 1;
      numberOfEmptyToLast = 5;
      break;
    case Wednesday:
      numberOfEmpty = 2;
      numberOfEmptyToLast = 4;
      break;
    case Thursday:
      numberOfEmpty = 3;
      numberOfEmptyToLast = 3;
      break;
    case Friday:
      numberOfEmpty = 4;
      numberOfEmptyToLast = 2;
      break;
    case Saturday:
      numberOfEmpty = 5;
      numberOfEmptyToLast = 1;
      break;
    default:
      break;
  }
  return { numberOfEmpty, numberOfEmptyToLast };
};
export interface monthDaysType {
  day: number;
  status: string;
  description: string;
  week: string;
  class_name: string;
  date: Date;
  holiday_types: HolidayType[];
  events_length: number;
  attendance?: string[];
}

interface Props {
  pageType: PageFor;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  setDrawerFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const MonthlyOverview = ({
  selectedDate,
  setSelectedDate,
  setDrawerFlag,
  pageType,
}: Props) => {
  const { InstId, entryId } = useParams();
  const { token } = useToken();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [date, setDate] = useState(new Date());
  const [deleteModal, setDeleteModal] = useState(false);
  const { user_details } = useLoggedInUserDetails();

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { state } = useContext(AppContext);

  const numberOfDaysInMonth = GetnumberOfDaysInMonth(date.toString());
  const { entry_level, USE_SECTION_KEY, USE_CLASS_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();
  const { firstDay, lastDay } = DateRange(date.toString()) || {};

  const { serverDate } = useServerDateandTime();
  var generalHolidaysMap = useRef(new Map());
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    holiday_query_type.ALL_HOLIDAYS
  );
  const [monthDays, setMonthDays] = useState<monthDaysType[]>([]);

  const { year, month } = getMonthDateAndDay(date.toString());
  const [DeleteAttendance, { loading: deleteLoading }] = useMutation(
    DeleteAttendanceForStudents,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetMarkedStatus, { data: dailyStatMarks }] = useLazyQuery<
    GetAcdAttStdDailyStatusData,
    GetAcdAttStdDailyStatusVars
  >(GetAcdAttStdDailyStatus, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: entryId!,
        entry_level,
        date_of_attendance: toIsoDate(firstDay!),
        inst_id: InstId!,
      },
      token,
    },
    fetchPolicy: "network-only",
  });

  const getStatusForDate = dailyStatMarks
    ? (dailyStatMarks?.GetAcdAttStdDailyStatus as Record<string, boolean>)[
        `day_${date.getDate()}`
      ]
    : false;

  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;
      const lastWeek = monthDays[monthDays.length - 1]?.week;
      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };

  const chooseTypeAndColor = (status: boolean, day: number) => {
    const toDay = new Date(serverDate).getTime();
    const sel_date = new Date(year, month, day).getTime();
    if (sel_date <= toDay) {
      if (status) {
        return {
          type: "M",
          description: "",
          class_name: "cal-bg-green",
        };
      } else {
        return {
          type: "UM",
          description: "",
          class_name: "cal-bg-red",
        };
      }
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
      };
    }
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleDeleteAttendance = () => {
    DeleteAttendance({
      variables: {
        token,
        acd_yr_id:
          activeAcademicYearData &&
          activeAcademicYearData.data &&
          activeAcademicYearData.data.GetAcdYrActiveByInstId.id,
        entry_level,
        entry_id: entryId,
        date_of_attendance: toIsoDate(date.toString()),
        emp_id: state.claims!.EDUATE ? 0 : state.empLoginId,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdAttStdTodaysStatus,
          variables: {
            token,
            input: {
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              entry_id: entryId!,
              entry_level,
              date_of_attendance: toIsoDate(date.toString()),
              inst_id: InstId!,
            },
          },
        },

        {
          query: GetAcdStdsForAttendance,
          variables: {
            token,
            input: {
              att_query_type: USE_SECTION_KEY
                ? AttendenceQueryType.ATT_STD_BY_SECTION_ID
                : USE_SEMESTER_KEY
                ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                : USE_CLASS_KEY
                ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
              entry_id: entryId!,
              cal_month: toIsoDate(date.toString()),
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(date.toString()),
              subj_master_id: 0,
              per_std_subj_allocation: false,
            },
          },
        },
        {
          query: GetAcdStudentsAttendance,
          variables: {
            token,
            input: {
              att_query_type: AttendenceQueryType.ATT_STD_BY_SECTION_ID,
              entry_id: entryId!,
              cal_month: toIsoDate(date.toString()),
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(date.toString()),
              subj_master_id: 0,
              per_std_subj_allocation: false,
            },
          },
        },
        {
          query: GetAcdStudentAttendanceCount,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            entry_level,
            entry_id: entryId!,
          },
        },
        {
          query: GetAcdAttStdDailyStatus,
          variables: {
            input: {
              acd_yr_id:
                activeAcademicYearData &&
                activeAcademicYearData.data &&
                activeAcademicYearData.data.GetAcdYrActiveByInstId.id,
              entry_id: entryId!,
              entry_level: entry_level,
              date_of_attendance: toIsoDate(date.toString()!),
              inst_id: InstId!,
            },
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDeleteModal(false);
      }
    });
    setMessage({
      flag: true,
      message: `Sucessfully Updated Attendence for the day ${toStandardDate(
        date.toString()
      )}`,
      operation: Operation.CREATE,
    });
  };
  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      entry_level &&
      state.ActiveAcdYr
    ) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetMarkedStatus().then(({ data }) => {
        if (data) {
          setMonthDays(
            Array.from({ length: numberOfDaysInMonth }, (_, i) => {
              const dayNum = i + 1;
              const holiday_details = chooseTypeAndColor(
                (data.GetAcdAttStdDailyStatus as Record<string, boolean>)[
                  `day_${dayNum}`
                ],
                dayNum
              );
              const date = `${year}/${month + 1}/${dayNum}`;
              const { week } = getMonthDateAndDay(date);
              const week_string = getWeekByDayNum(week);
              return {
                day: dayNum,
                status: holiday_details?.type!,
                description: holiday_details?.description!,
                class_name: holiday_details?.class_name!,
                date: new Date(date),
                week: week_string!,
                holiday_types: [],
                events_length: 0,
              };
            })
          );
        }
      });
    } // eslint-disable-next-line
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    date,
    GetMarkedStatus,
    numberOfDaysInMonth,
    InstGeneralHolidays.data,
    entry_level,
    state.ActiveAcdYr,
  ]);
  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
    }
  }, [selectedDate]);

  return (
    <div className="attendance-overview__monthly-overview">
      {pageType === PageFor.REPORT ? null : (
        <>
          <div className="attendance-overview__monthly-overview--title">
            <Title variant="subtitle1">Monthly Attendance Overview</Title>
            <img
              src={Close}
              alt=""
              onClick={() => setDrawerFlag(false)}
              className="modal-close-icon"
            />
          </div>
          <span className="attendance-overview__monthly-overview--status">
            Marked
          </span>
          <div className="attendance-overview__monthly-overview--date">
            <Label>{toStandardDate(date.toString())}</Label>
            <div>
            {getStatusForDate ? (
              <>
                <Button
                  mode="update"
                  onClick={() => {
                    setSelectedDate(date);
                    setDrawerFlag(false);
                  }}
                >
                  Attendance
                </Button>
                <Button
                  mode="delete"
                  onClick={() => {
                    setDeleteModal(!deleteModal);
                  }}
                >
                  Attendance
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  setSelectedDate(date);
                  setDrawerFlag(false);
                }}
              >
                Mark Attendance
              </Button>
            )}
            </div>
       
          </div>
        </>
      )}

      <div className="attendance-overview__monthly-overview--month">
        <img
          src={Previous}
          alt="/"
          onClick={() => {
            handleDate(
              date,
              setDate,
              activeAcademicYearData.data?.GetAcdYrActiveByInstId.acd_st_date!,
              activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                .acd_end_date!,
              setMessage,
              INCREMENT_DECREMENT.DECREMENT
            );
          }}
        />

        <Title variant="subtitle1">{MonthName(date.toString())}</Title>
        <img
          src={Next}
          alt="/"
          onClick={() => {
            const nextDate = new Date(
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate()
            );
            const serverDateDayjs = dayjs(serverDate);
            const selectedDateDayjs = dayjs(nextDate);
            if (
              serverDateDayjs.isAfter(selectedDateDayjs) &&
              !serverDateDayjs.isSame(selectedDateDayjs)
            ) {
              handleDate(
                date,
                setDate,
                activeAcademicYearData.data?.GetAcdYrActiveByInstId
                  .acd_st_date!,
                activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                  .acd_end_date!,
                setMessage,
                INCREMENT_DECREMENT.INCREMENT
              );
            } else {
              setMessage({
                message: "Cannot Access Future dates",
                flag: true,
                operation: Operation.NONE,
              });
            }
          }}
        />
      </div>
      <div className="attendance-overview__monthly-overview--calendar">
        <ul className="attendance-overview__monthly-overview--grid-day">
          {days.map((day, index: React.Key) => {
            return (
              <li
                key={index}
                className="attendance-overview__monthly-overview--grid-container--day"
              >
                {day}
              </li>
            );
          })}
        </ul>
        <ul className="attendance-overview__monthly-overview--grid-container">
          {weekCount().map(
            ({ day, class_name, description, date, status }, index) => {
              return day ? (
                status === HolidayType.GENERAL ||
                status === HolidayType.UNEXPECTED ? (
                  <Tooltip
                    title={<>{description}</>}
                    arrow
                    placement="right"
                    key={index}
                  >
                    <li
                      className={`attendance-overview__monthly-overview--grid-item ${class_name}`}
                    >
                      <span
                        className={`attendance-overview__monthly-overview--grid-item--date `}
                      >
                        {day > 0 ? day : ""}
                      </span>
                    </li>
                  </Tooltip>
                ) : (
                  <li
                    key={index}
                    className={`attendance-overview__monthly-overview--grid-item ${class_name}`}
                    onClick={() => {
                      if (date?.getTime()! < new Date(serverDate).getTime()) {
                        setDate(date!);
                      } else {
                        setMessage({
                          message: "Cannot mark Attendance for Future dates",
                          flag: true,
                          operation: Operation.NONE,
                        });
                      }
                    }}
                  >
                    <span
                      className={`attendance-overview__monthly-overview--grid-item--date `}
                    >
                      {day > 0 ? day : ""}
                    </span>
                  </li>
                )
              ) : (
                <li key={index}></li>
              );
            }
          )}
        </ul>
      </div>

      <CalendarLegends useCalendarIn={CalendarFor.ATTENDANCE} />

      <MessageModal
        value={message.message}
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
      />
      <AttendanceDeleteModal
        id={0}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDeleteAttendance}
        date={date}
      />
      <LoadingModal flag={deleteLoading} />
    </div>
  );
};

export default MonthlyOverview;

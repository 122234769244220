import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";

import {
  GetSwConfigReferenceData,
  GetSwConfigReferenceDataByKey,
  GetSwConfigVariables,
} from "../../queries/institution/configuration/query/SoftwreConfig";
import useToken from "../../customhooks/useToken";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
  GlobalPageConfigData,
  SoftwareConfigList,
  SoftwareConfigTypeList,
  SoftwareConfigTypeVar,
  SoftwareVars,
} from "../../Types/configtypes";
import { ModuleName, Operation, PageFor } from "../../utils/Enum.types";
import AcademicsHome from "../Academics/Home/Index";
import AccountssHome from "../Accounts/Home/Index";
import PayRollHome from "../HR/Home/Index";
import LibraryHome from "../Library/Home/Index";
import MastersHome from "../Master/Home/Index";
import { Label } from "../../stories/Label/Label";
import { Select } from "../../stories/Select/Select";
import { msgType, optionsType } from "../../utils/Form.types";
import { FormControlLabel, FormGroup } from "@mui/material";
import { AntSwitch } from "../../pages/Switch";
import Input from "../../stories/Input/Input";
import { UpdateSwConfigVariables } from "../../queries/institution/configuration/mutation";
import { Button } from "../../stories/Button/Button";
import MessageModal from "../../pages/MessageModal";
import LoadingModal from "../../pages/LoadingModal";
import { SwConfigQueryType } from "../HR/enums/Enum.types";
import { Title } from "../../stories/Title/Title";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";

interface Props {
  config_query_type: SwConfigQueryType;
  str_value: ModuleName;
  int_value: number;
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MenuProps {
  menu: ModuleName;
}
const PerModuleConfiguration = ({
  config_query_type,
  str_value,
  int_value,
  pageType,
  setModalFlag,
}: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [pageSwDetails, setPageSwConfigList] = useState<GlobalPageConfigData[]>(
    []
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [institutionWorkingtypeData, setInstitutionWorkingTypeData] = useState<
    optionsType[]
  >([]);

  const [UpdateInstConfig, { loading: updationLoading }] = useMutation(
    UpdateSwConfigVariables,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetSwConfigReference, { data: ConfigReferenceData }] = useLazyQuery<
    SoftwareConfigTypeList,
    SoftwareConfigTypeVar
  >(GetSwConfigReferenceData, { variables: { token } });

  const [GetConfigVariables, { data, loading }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId!,
      input: {
        config_query_type,
        str_value,
        int_value,
      },
    },
  });

  const [GetInstitutionWorkingType] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey);
  const { user_details } = useLoggedInUserDetails();

  const handleChangeValue = (
    key: string,
    type: string,
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    if (type === "BOOL") {
      const newState =
        pageSwDetails &&
        // eslint-disable-next-line
        pageSwDetails.map((obj) =>
          obj.config_key === key
            ? { ...obj, config_boolean_value: switchValue! }
            : obj
        );
      setPageSwConfigList(newState);
    }

    if (type === "INT") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_integer_value: Number(selectedValue)! }
          : obj
      );
      setPageSwConfigList(newState);
    }
    if (type === "primary_list_key") {
      const config_key = key + "_" + selectedValue!;

      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? {
              ...obj,
              config_secondary_list_key: config_key,
              config_string_value: selectedValue!,
            }
          : obj
      );
      setPageSwConfigList(newState);
      GetInstitutionWorkingType({
        variables: { config_key, token },
      }).then(({ data, error }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          setInstitutionWorkingTypeData(
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              }))
              .filter(({ value }) => value !== undefined)
          );
        }
      });
    }

    if (type === "secondary_list_key") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }
  };
  const instTypeData = (type: string) => {
    const typeData = ConfigReferenceData?.GetSwConfigReferenceData?.find(
      (data) => data.list_item_key === type
    );

    return typeData?.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }))
      .filter(({ value }) => value !== undefined);
  };
  const handleUpdateSwConfig = () => {
    UpdateInstConfig({
      variables: {
        token,
        input: pageSwDetails.map((pageSwDetail) => ({
          id: pageSwDetail.id,
          config_key: pageSwDetail.config_key,
          config_boolean_value: pageSwDetail.config_boolean_value,
          config_integer_value: pageSwDetail.config_integer_value,
          config_double_value: pageSwDetail.config_double_value,
          config_string_value: pageSwDetail.config_string_value,
          config_string_value_2: pageSwDetail.config_string_value_2,
          inst_id: InstId,
        })),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwConfigVariables,
          variables: {
            token,
            inst_id: InstId!,
            input: {
              config_query_type,
              str_value,
              int_value,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Configuration saved successfully",
          flag: true,
          operation: Operation.CREATE,
        });
    });
  };
  const handleClose = () => {
    setModalFlag?.(false);
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (data && !loading) {
      setPageSwConfigList(data.GetSwConfigVariables);
    }
  }, [data, loading]);
  useEffect(() => {
    if (token) {
      GetSwConfigReference();
    }
  }, [token, GetSwConfigReference]);

  useEffect(() => {
    if (token && InstId) {
      GetConfigVariables();
    }
  }, [token, GetConfigVariables, InstId]);

  const Menu = ({ menu }: MenuProps) => {
    switch (menu) {
      case ModuleName.ACADEMICS:
        return (
          <>
            <AcademicsHome DashBoardRequired={false} />
            <Title>Academics Configurations</Title>
          </>
        );
      case ModuleName.ACCOUNTS:
        return (
          <>
            <AccountssHome DashBoardRequired={false} />
            <Title>Accounts Configurations</Title>
          </>
        );
      case ModuleName.LIBRARY:
        return (
          <>
            <LibraryHome DashBoardRequired={false} />
            <Title>Library Configurations</Title>
          </>
        );
      case ModuleName.PAYROLL:
        return (
          <>
            <PayRollHome DashBoardRequired={false} />
            <Title>Staff Management Configurations</Title>
          </>
        );
      case ModuleName.ADMISSION:
        return (
          <>
            <MastersHome DashBoardRequired={false} />
            <Title> Configurations</Title>
          </>
        );
      case ModuleName.RECEIPT_PRINT:
        return (
          <>
            <Title>Receipt Print Configurations</Title>
          </>
        );
      case ModuleName.VOUCHER_PRINT:
        return (
          <>
            <Title>Voucher Print Configurations</Title>
          </>
        );
      case ModuleName.CHALLAN_PRINT:
        return (
          <>
            <Title>Challan Print Configurations</Title>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <>
        <Menu menu={str_value} />
      </>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "module-wise-configuration"
            : "module-wise-configuration__modal"
        }
      >
        <div className="module-wise-configuration__block">
          <div
            className={
              str_value === ModuleName.IDCARD_PRINT
                ? ""
                : "module-wise-configuration__block--details"
            }
          >
            {pageSwDetails.map((list: GlobalPageConfigData, index: number) => {
              return (
                <div
                  className="module-wise-configuration__block--parameters"
                  key={index}
                >
                  <Label>
                    {index + 1} &nbsp;
                    {list.config_form_label_caption}
                  </Label>
                  {list.config_depends_on_primary_list_key &&
                  list.config_depends_on_secondary_list_key === false ? (
                    <div>
                      <Select
                        value={list.config_string_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeValue(
                            list.config_key,
                            "primary_list_key",
                            null,
                            e.target.value
                          );
                        }}
                      >
                        <option>Select</option>
                        {instTypeData(list.config_primary_list_key)?.map(
                          ({ label, value }) => {
                            return (
                              <React.Fragment key={value}>
                                <option value={value}>{label}</option>
                              </React.Fragment>
                            );
                          }
                        )}
                      </Select>
                    </div>
                  ) : null}

                  {list.config_depends_on_primary_list_key &&
                  list.config_depends_on_secondary_list_key ? (
                    <div>
                      <div>
                        <Select
                          value={list.config_string_value}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeValue(
                              list.config_key,
                              "primary_list_key",
                              null,
                              e.target.value
                            );
                          }}
                        >
                          <option>Select</option>
                          {instTypeData(list.config_primary_list_key)?.map(
                            ({ label, value }) => {
                              return (
                                <React.Fragment key={value}>
                                  <option value={value}>{label}</option>
                                </React.Fragment>
                              );
                            }
                          )}
                        </Select>
                      </div>
                      {institutionWorkingtypeData.length ? (
                        <div>
                          <Label>Form to Date</Label>
                          <Select
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeValue(
                                list.config_key,
                                "secondary_list_key",
                                null,
                                e.target.value
                              );
                            }}
                          >
                            <option>Select</option>
                            {institutionWorkingtypeData.map(
                              ({ label, value }) => {
                                return (
                                  <React.Fragment key={value}>
                                    <option value={value}>{label}</option>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </Select>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {list.config_key_data_storage_type === "BOOLEAN" ? (
                    <FormGroup>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <AntSwitch
                            checked={list.config_boolean_value}
                            onClick={() => {
                              const newConfigValue = list.config_boolean_value
                                ? false
                                : true;
                              handleChangeValue(
                                list.config_key,
                                "BOOL",
                                newConfigValue,
                                null
                              );
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  ) : null}
                  {list.config_key_data_storage_type === "BOOLEAN+VARCHAR" ? (
                    <FormGroup>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <AntSwitch
                            checked={list.config_boolean_value}
                            onClick={() => {
                              const newConfigValue = !list.config_boolean_value;
                              handleChangeValue(
                                list.config_key,
                                "BOOL",
                                newConfigValue,
                                null
                              );
                            }}
                          />
                        }
                      />
                      {list.config_boolean_value && (
                        <Input
                          value={list.config_string_value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeValue(
                              list.config_key,
                              "VARCHAR",
                              null,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </FormGroup>
                  ) : null}
                  {list.config_key_data_storage_type === "BOOLEAN+INT" ? (
                    <FormGroup>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <AntSwitch
                            checked={list.config_boolean_value}
                            onClick={() => {
                              const newConfigValue = !list.config_boolean_value;
                              handleChangeValue(
                                list.config_key,
                                "BOOL",
                                newConfigValue,
                                null
                              );
                            }}
                          />
                        }
                      />
                      {list.config_boolean_value && (
                        <Input
                          value={list.config_integer_value}
                          type="number"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeValue(
                              list.config_key,
                              "INT",
                              null,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </FormGroup>
                  ) : null}
                  {list.config_depends_on_primary_list_key === false &&
                  list.config_key_data_storage_type === "VARCHAR" ? (
                    <Input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValue(
                          list.config_key,
                          "VARCHAR",
                          null,
                          e.target.value
                        )
                      }
                      value={list.config_string_value}
                    />
                  ) : null}
                  {list.config_key_data_storage_type === "INT" ? (
                    <Input
                      type="number"
                      value={list.config_integer_value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValue(
                          list.config_key,
                          "INT",
                          null,
                          e.target.value
                        )
                      }
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>

        <Button onClick={handleUpdateSwConfig} mode="save" />
        {pageType === PageFor.GENERAL ? (
          <Button onClick={() => navigate(-1)} mode="back" />
        ) : (
          <Button onClick={() => setModalFlag(false)} mode="cancel" />
        )}
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={updationLoading} />
    </>
  );
};

export default PerModuleConfiguration;

import React, { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { useNavigate, useParams } from "react-router-dom";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import Close from "../../../../images/Close.svg";

import Transfer from "../../../../images/Transfer.svg";

import Modal from "react-modal";
import {
  AccountingLedgerModalStyles,
  StudentModalStyles,
  ViewPickedSubjectModalStyles,
} from "../../../../styles/ModalStyles";

import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import View from "./View";
import EditAssociated from "./Add";
import useCombinations from "../customHooks/useCombinations";
import {
  DELETE,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../utils/constants";
import {
  AcdSubjectComboDetails,
  AcdSubjectComboEdge,
} from "../../../../Types/Combinations/queries";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import { DeleteAcdSubjectCombo } from "../../../../queries/customallocation/combination/mutations";
import { useMutation } from "@apollo/client";
import { msgType } from "../../../../utils/Form.types";
import {
  DeleteAcdSubjectComboData,
  DeleteAcdSubjectComboVars,
} from "../../../../Types/Combinations/mutations";
import { GetAcdSubjectCombos } from "../../../../queries/customallocation/combination/queries";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import MessageModal from "../../../../pages/MessageModal";
import { payloadTypes } from "../../../../context/reducer";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
const { Academics_Table } = require("../../json/table.json");

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: PageFor;
}
const Index = ({ setModal, pageType }: Props) => {
  const navigate = useNavigate();
  const { InstId, allocationId } = useParams();
  const { token } = useToken();
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [endCursor, setEndCursor] = useState("");
  const [combinations, setCombinations] = useState<AcdSubjectComboEdge[]>([]);
  const [searchCombo, setSearchCombo] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [combo_details, setComboDetails] = useState<AcdSubjectComboDetails[]>(
    []
  );

  const { state, dispatch } = useContext(AppContext);

  const { user_details } = useLoggedInUserDetails();
  const { data, error, loading, fetchMore } = useCombinations(
    ROWS_PER_PAGE,
    searchCombo,
    AcdSubjComboQueryType.ALL_COMBOS
  );
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [DeleteCombo] = useMutation<
    DeleteAcdSubjectComboData,
    DeleteAcdSubjectComboVars
  >(DeleteAcdSubjectCombo, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdSubjectCombos.edges;
            const pageInfo = fetchMoreResult.GetAcdSubjectCombos.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdSubjectCombos.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdSubjectCombos: {
                edges: [...combinations, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcdSubjectCombos.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdSubjectCombos.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newCategory) => {
          const filteredStudent = combinations.find(
            (category) => category.node.id === newCategory.node.id
          );
          if (filteredStudent) {
            return {
              ...newCategory,
              node: {
                ...newCategory.node,
              },
            };
          }
          return newCategory;
        });
        setCombinations(updatedNewData);
      } else {
        setCombinations(newData);
      }
      setHasNextPage(data.GetAcdSubjectCombos.pageInfo.hasNextPage);
      setEndCursor(data.GetAcdSubjectCombos.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  const handleDelete = (id: number) => {
    DeleteCombo({
      variables: {
        id,
        inst_id: InstId!,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdSubjectCombos,
          variables: {
            token,
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: EMPTY_STRING,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            query_type: AcdSubjComboQueryType.ALL_COMBOS,
            entry_id: 0,
            entry_level: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.SUBJ_COMBO_NAME,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteAcdSubjectCombo) {
        setMessage({
          flag: true,
          message: `Group deleted successfully`,
          operation: Operation.CREATE,
        });
      }
    });
  };

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      {pageType === PageFor.GENERAL ? (
        <Title>Associate Category to Combination</Title>
      ) : (
        <>
          <div className="combinations__title">
            <Title>List of Combination</Title>
            <img src={Close} alt="" onClick={() => setModal(false)} />
          </div>
        </>
      )}
      <div
        className={
          pageType === PageFor.GENERAL ? "combinations" : "combinations__modal"
        }
      >
        <div className="combinations__filters row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search.."
              onChange={(e) => setSearchCombo(e.target.value)}
            />
          </div>
          <div className="col"></div>
          {pageType === PageFor.GENERAL && (
            <div className="col-2 combinations__filters--button">
              <Button
                mode="addnew"
                autoFocus
                onClick={() =>
                  navigate(
                    `/${InstId}/academics/customallocation/combinations/associate`
                  )
                }
              />
            </div>
          )}
        </div>
        <div className="combinations__tableblock">
          {error ? (
            <b className="nodata"> {error.message} </b>
          ) : (
            <TableContainer
              className="combinations__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Academics_Table.CustomAllocation.Combinations.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {combinations.map((res, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="combinations__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="combinations__table--name"
                          onClick={() => setView(!view)}
                        >
                          {res.node.subj_combo_name}
                        </TableCell>
                        <TableCell>
                          {res.node.subj_combo_details.map((cat) => {
                            return (
                              <ul className="combinations__table--ul">
                                <li>
                                  <b>{cat.category_details.subj_cat_name}</b>
                                </li>
                                {cat.category_details.subj_cat_details.map(
                                  (c, index) => {
                                    return (
                                      <li key={index}>
                                        {index + 1}.&nbsp;
                                        {c.subject_details.subj_desc}&nbsp;(
                                        <span className="font-grey">
                                          {c.subject_details.subj_code}
                                        </span>
                                        )
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            );
                          })}
                        </TableCell>
                        <TableCell
                          className="combinations__table--actions"
                          id="td-center"
                        >
                          {pageType === PageFor.GENERAL ? (
                            <>
                              <img
                                src={Edit}
                                alt=""
                                onClick={() => {
                                  dispatch({
                                    type: payloadTypes.SET_ACD_COMBO_ID,
                                    payload: {
                                      acdCombinationId: res.node.id,
                                    },
                                  });
                                  setEdit(!edit);
                                }}
                              />
                              <img
                                src={Delete}
                                alt=""
                                onClick={() => {
                                  setComboDetails(res.node.subj_combo_details);
                                  setDeleteId(res.node.id);
                                  setDeleteModal(!deleteModal);
                                }}
                              />
                            </>
                          ) : (
                            <button
                              className="combinations__table--assign"
                              onClick={() => {
                                navigate(
                                  `/${InstId}/academics/${allocationId}/subjectallocationdetails/${res.node.id}/combinations/list`
                                );
                                setModal(false);
                              }}
                            >
                              Choose Students <img src={Transfer} alt="" />
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <>
            <Button mode="save" />
            <Button mode="clear" />
            <Button mode="cancel" onClick={() => setModal(false)} />
          </>
        )}
      </div>

      <Modal
        isOpen={view}
        ariaHideApp={false}
        style={ViewPickedSubjectModalStyles}
      >
        <View
          setModal={setView}
          operation={Operation.VIEW}
          data={combo_details}
        />
      </Modal>
      <Modal isOpen={edit} ariaHideApp={false} style={StudentModalStyles}>
        <EditAssociated pageType={PageFor.MODAL} setModal={setEdit} />
      </Modal>
      <Modal
        isOpen={deleteModal}
        ariaHideApp={false}
        style={AccountingLedgerModalStyles}
      >
        <View
          setModal={setDeleteModal}
          operation={Operation.DELETE}
          data={combo_details}
        />
        <span className="view-combinations__delete--span">Do you really want to delete these records?</span>
        {/*Shiva:  br tag to be removed after css */}
        <br />
        <span className="view-combinations__delete--span">
          Please enter <b className="nodata">'DELETE'</b> to confirm the
          deletion.
        </span>
        <Input
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
          }}
        />
        <Button
          autoFocus
          disabled={inputValue !== DELETE}
          onClick={() => {
            handleDelete(deleteId);
            setInputValue("");
          }}
          mode="delete"
        />
        <Button
          onClick={() => {
            setInputValue("");
            setDeleteModal(!deleteModal);
          }}
          mode="cancel"
        />
      </Modal>
      <MessageModal
        handleClose={() => {
          setMessage(emptyMessageType);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;

import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ThemeMenuProps } from "../../../styles/Accordion";
import {
  CustomTooltip,
  TOOLTIP_COLORS} from "../../../styles/TooltipStyles";
import Teal from "../../../images/Teal.svg";

import SkyBlue from "../../../images/SkyBlue.svg";
import Purple from "../../../images/Purple.svg";
import Pink from "../../../images/Pink.svg";
import Lime from "../../../images/Lime.svg";
import Green from "../../../images/Green.svg";
import Fuchsia from "../../../images/Fuchsia.svg";
import Grey from "../../../images/Grey.svg";
import Blue from "../../../images/Blue.svg";
import Amber from "../../../images/Amber.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UpdateUserRightsTheme } from "../../../Modules/UserRights/queries/mutation";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import { GetUserRightsByEmpId } from "../../../Modules/UserRights/queries/List";
import useLoggedInUserDetails from "../../../Modules/Accounts/hooks/useLoggedInUserDetails";

const Skins = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [theme, setThem] = useState("");
  const { user_details } = useLoggedInUserDetails();

  const [GetTheme, { data }] = useLazyQuery(GetUserRightsByEmpId);
  const [UpdateTheme] = useMutation(UpdateUserRightsTheme);

  const setTheme = (theme: string) => {
    UpdateTheme({
      variables: {
        token,
        inst_id: state.InstId,
        user_details,
        pr_emp_id: state.empLoginId,
        theme,
      },
      refetchQueries: [
        {
          query: GetUserRightsByEmpId,
          variables: {
            inst_id: state.InstId,
            pr_emp_id: state.empLoginId,
            token,
          },
        },
      ],
    });
  };

  const [themeOptions, setThemeOptions] = useState(false);
  useEffect(() => {
    if (token && state.InstId && state.empLoginId) {
      GetTheme({
        variables: {
          inst_id: state.InstId,
          pr_emp_id: state.empLoginId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          document.documentElement.className =
            data?.GetUserRightsByEmpId.default_theme!;
          setThem(data?.GetUserRightsByEmpId.default_theme!);
        }
      });
    }
  }, [token, state.InstId, state.empLoginId, GetTheme, data]);
  const colorOption = [


    {
      label: "Teal",
      value: "teal",
      image: `${Teal}`,
      bgColor:TOOLTIP_COLORS.TEAL,
    },

    {
      label: "Sky-Blue",
      value: "sky",
      image: `${SkyBlue}`,
      bgColor:TOOLTIP_COLORS.SKY,
    },

    {
      label: "Purple",
      value: "purple",
      image: `${Purple}`,
      bgcolor:TOOLTIP_COLORS.PURPLE,
    },
    {
      label: "Pink",
      value: "pink",
      image: `${Pink}`,
      bgcolor:TOOLTIP_COLORS.PINK,
    },

    {
      label: "Lime",
      value: "lime",
      image: `${Lime}`,
      bgcolor:TOOLTIP_COLORS.LIME,
    },

    {
      label: "Green",
      value: "Green",
      image: `${Green}`,
      bgColor:TOOLTIP_COLORS.GREEN,
    },
    {
      label: "Fuchsia",
      value: "fuchsia",
      image: `${Fuchsia}`,
      bgcolor:TOOLTIP_COLORS.FUCHSIA,
    },

    {
      label: "Grey",
      value: "grey",
      image: `${Grey}`,
      bgcolor:TOOLTIP_COLORS.GREY,
    },
    {
      label: "Blue",
      value: "blue",
      image: `${Blue}`,
      bgcolor:TOOLTIP_COLORS.BLUE,
    },
    {
      label: "Amber",
      value: "amber",
      image: `${Amber}`,
      bgcolor:TOOLTIP_COLORS.VARIABLE,
    },
  ];

  return (
    <Select
      onChange={(e: SelectChangeEvent) => {
        setTheme(e.target.value);
        setThemeOptions(!themeOptions);
      }}
      className="theme-option"
      value={theme}
      MenuProps={ThemeMenuProps}
    >
      {colorOption.map((option, index: React.Key) => (
        <MenuItem key={index} value={option.value} className="theme-menu">
          <ListItemText>
            <CustomTooltip
              title={option.label}
              arrow
              placement="bottom"
              className="tooltip-hide"
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor:option.bgColor,
                    '& .MuiTooltip-arrow': {
                      color:option.bgColor,
                    },
                  },
                },
              }}              >
              <img src={option.image} alt="/" className="theme-image" />
            </CustomTooltip>
          </ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};

export default Skins;

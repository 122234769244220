import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { Direction, SortBy } from "../../../utils/Enum.types";
import { toIsoDate } from "../../../utils/UtilFunctions";
import { GetAcdStudentsAttendance } from "../queries/holidays/list";
import { GetAcdAttendanceMonthlyForStdsVars } from "../types/holidays";
import { StudentAcdNode, StudentPageInfo } from "./useAcdStudentsData";
import useActiveAcademicYear from "./useActiveAcademicYear";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { AppContext } from "../../../context/context";

export interface GetAcdAttendanceMonthlyNode {
  id: number;
  att_total_days_present: number;
  att_total_days_absent_full: number;
  att_total_days_absent_half: number;
  att_total_days_absent: number;
  cal_month: string;
  day_1: string;
  day_2: string;
  day_3: string;
  day_4: string;
  day_5: string;
  day_6: string;
  day_7: string;
  day_8: string;
  day_9: string;
  day_10: string;
  day_11: string;
  day_12: string;
  day_13: string;
  day_14: string;
  day_15: string;
  day_16: string;
  day_17: string;
  day_18: string;
  day_19: string;
  day_20: string;
  day_21: string;
  day_22: string;
  day_23: string;
  day_24: string;
  day_25: string;
  day_26: string;
  day_27: string;
  day_28: string;
  day_29: string;
  day_30: string;
  day_31: string;
  student_id: number;
  student_details: StudentAcdNode;
  isChecked: boolean;
}

export interface StudentDetails {
  first_name: string;
  middle_name: string;
  last_name: string;
  std_adm_no: string;
  std_reg_no: string;
  id: number;
  dept: {
    dept_desc: string;
  };
  branch: {
    branch_desc: string;
  };
  class: {
    class_desc: string;
  };
  semester: {
    sem_desc: string;
  };
  section: {
    section_desc: string;
  };
}
export interface GetAcdStdAttOverAllNode {
  total_session: number;
  total_session_present: number;
  total_session_absent: number;
  over_all_per: number;
  student_details: StudentDetails;
}
export interface subjectData {
  id: number;
  subj_desc: string;
  subj_code: string;
}
export interface GetAcdStdAttOverAllSubjectNode {
  id: number;
  std_roll_no: number;
  total_session: number;
  total_session_present: number;
  total_session_absent: number;
  over_all_per: number;
  acd_dept_id: number;
  acd_branch_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  student_id: number;
  acd_yr_id: number;
  inst_id: number;
  subj_master_id: number;
  student_details: StudentAcdNode;
  subject_details: subjectData;
}
export interface GetAcdAttendanceMonthlyEdges {
  node: GetAcdAttendanceMonthlyNode | Record<string, string | number>;
}
export interface GetAcdStdAttOverAllEdges {
  node: GetAcdStdAttOverAllNode | Record<string, string | number>;
  cursor: string;
}
export interface GetAcdStdSubjAttOverAllEdges {
  node: GetAcdStdAttOverAllSubjectNode | Record<string, string | number>;
}
interface GetAcdAttendanceMonthlyList {
  edges: GetAcdAttendanceMonthlyEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
export interface GetAcdStdAttOverAllList {
  edges: GetAcdStdAttOverAllEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
interface GetAcdStdSubjAttOverAllList {
  edges: GetAcdStdSubjAttOverAllEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
export interface GetAcdAttendanceMonthlyForStdsData {
  GetAcdStudentsAttendance: GetAcdAttendanceMonthlyList;
}
export interface GetAcdStdAttOverAllData {
  GetAcdStdAttOverAll: GetAcdStdAttOverAllList;
}
export interface GetAcdStdSubjAttOverAllData {
  GetAcdStdSubjAttOverAll: GetAcdStdSubjAttOverAllList;
}
export enum AttendenceQueryType {
  ALL_STUDENTS = "ALL_STUDENTS",
  ONLY_ABSENTEES = "ONLY_ABSENTEES",
  ATT_STD_BY_DEPT_ID = "ATT_STD_BY_DEPT_ID",
  ATT_STD_BY_BRANCH_ID = "ATT_STD_BY_BRANCH_ID",
  ATT_STD_BY_CLASS_ID = "ATT_STD_BY_CLASS_ID",
  ATT_STD_BY_SEMESTER_ID = "ATT_STD_BY_SEMESTER_ID",
  ATT_STD_BY_SECTION_ID = "ATT_STD_BY_SECTION_ID",
  ATT_PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL = "ATT_PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL",
  ATT_BY_ENTRY_LEVEL_AND_BY_SUBJ_ID = "ATT_BY_ENTRY_LEVEL_AND_BY_SUBJ_ID",
}
const useAcdStudentAttendance = (
  firstDay: string,
  searchData: string,
  rowsPerPage: number | null,
  date: string,
  query_type?: AttendenceQueryType
) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { entryId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { state } = useContext(AppContext);
  const [queryType, setQuerytype] = useState("");
  const {
    USE_SECTION_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
  } = useInstitutionConfiguration();
  const [GetStudents, { data, fetchMore, loading, error }] = useLazyQuery<
    GetAcdAttendanceMonthlyForStdsData,
    GetAcdAttendanceMonthlyForStdsVars
  >(GetAcdStudentsAttendance, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: entryId!,
        inst_id: InstId!,
        cal_month: toIsoDate(firstDay!),
        att_query_type: queryType!,
        date_of_attendance: toIsoDate(date),
      },
      token,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      after: null,
      first: rowsPerPage,
      name: searchData,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    let queryType = "";
    if (USE_SECTION_KEY) {
      queryType = AttendenceQueryType.ATT_STD_BY_SECTION_ID;
    } else if (USE_SEMESTER_KEY) {
      queryType = AttendenceQueryType.ATT_STD_BY_SEMESTER_ID;
    } else if (USE_CLASS_KEY) {
      queryType = AttendenceQueryType.ATT_STD_BY_CLASS_ID;
    } else if (USE_BRANCH_KEY) {
      queryType = AttendenceQueryType.ATT_STD_BY_BRANCH_ID;
    } else if (USE_DEPARTMENT_KEY) {
      queryType = AttendenceQueryType.ATT_STD_BY_DEPT_ID;
    }
    if (query_type) {
      queryType = query_type;
    }

    setQuerytype(queryType!);
  }, [
    query_type,
    USE_SECTION_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
  ]);

  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      token &&
      queryType &&
      state.ActiveAcdYr
    ) {
      GetStudents();
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStudents,
    queryType,
    token,
    state.ActiveAcdYr,
  ]);

  return { attendanceDetails: { data, loading, error, fetchMore } };
};

export default useAcdStudentAttendance;

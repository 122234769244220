import { gql } from "@apollo/client";

export const GetAcctStockItemCategories = gql`
  query GetAcctStockItemCategories(
    $token: String!
    $inst_id: ID!
    $first: Int
    $after: Cursor
    $orderBy: AcctStockItemCategoryOrder
    $query_type: AcctStockItemCategoryQueryType!
  ) {
    GetAcctStockItemCategories(
      token: $token
      inst_id: $inst_id
      first: $first
      after: $after
      orderBy: $orderBy
      query_type: $query_type
    ) {
      edges {
        node {
          item_category_desc
          item_category_in_use
          id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const GetAcctStockItemGroups = gql`
  query GetAcctStockItemGroups(
    $token: String!
    $inst_id: ID!
    $first: Int
    $after: Cursor
    $orderBy: AcctStockItemGroupOrder
    $query_type: AcctStockItemGroupQueryType!
  ) {
    GetAcctStockItemGroups(
      token: $token
      inst_id: $inst_id
      first: $first
      after: $after
      orderBy: $orderBy
      query_type: $query_type
    ) {
      edges {
        node {
          item_grp_desc
          item_grp_in_use
          id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GetAcctStockItemUoms = gql`
  query GetAcctStockItemUoms(
    $token: String!
    $inst_id: ID!
    $first: Int
    $after: Cursor
    $orderBy: AcctStockItemUomOrder
    $query_type: AcctStockItemUomQueryType!
  ) {
    GetAcctStockItemUoms(
      token: $token
      inst_id: $inst_id
      first: $first
      after: $after
      orderBy: $orderBy
      query_type: $query_type
    ) {
      edges {
        node {
          item_uom_desc
          item_uom_in_use
          id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GetAcctStockItems = gql`
  query GetAcctStockItems(
    $token: String!
    $inst_id: ID!
    $first: Int
    $after: Cursor
    $orderBy: AcctStockItemOrder
    $query_type: AcctStockItemQueryType!
    $itemName: String
  ) {
    GetAcctStockItems(
      token: $token
      inst_id: $inst_id
      first: $first
      after: $after
      orderBy: $orderBy
      query_type: $query_type
      where: { or: [{ itemDescContainsFold: $itemName }] }
    ) {
      edges {
        node {
          id
          item_desc
          item_type
          item_in_use
          item_group_details {
            item_grp_desc
            item_grp_in_use
            id
          }
          item_category_details {
            item_category_in_use
            item_category_desc
            id
          }
          item_uom_details {
            item_uom_desc
            item_uom_in_use
            id
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

import { gql } from "@apollo/client";

export const GetPayRollMonthlyCalendar = gql`
  query GetPayRollMonthlyCalendar(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
  ) {
    GetPayRollMonthlyCalendar(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
    ) {
      id
      cal_month
      month_start_date
      month_end_date
      no_of_days
      no_of_weekend_days
      no_of_general
      no_of_unexpected
      no_of_vacation
      total_no_of_holidays
      total_no_of_workdays
      calendar_entered
    }
  }
`;

export const GetPayRollEmpAttendanceByEmpId = gql`
  query GetPayRollEmpAttendanceByEmpId(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $cal_month: Time!
    $pr_emp_id: ID!
  ) {
    GetPayRollEmpAttendanceByEmpId(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      cal_month: $cal_month
      pr_emp_id: $pr_emp_id
    ) {
      id
      cal_month
      att_total_days_present
      att_total_days_absent_full
      att_total_days_absent_half
      att_total_days_absent
      day_2
      day_3
      day_4
      day_1
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
      pr_emp_id
      pr_acd_yr_id
      pr_cal_month_id
      emp_details {
        id
        emp_id
        emp_title
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_sex
        emp_dob
        emp_mobile
        emp_email
        emp_qualification
        emp_experience
        emp_status
        emp_on_roll
        emp_doj
        leave_master_details {
          leave_details {
            leave_ldgr_details {
              id
              leave_desc
              leave_short_code
            }
          }
        }
      }
    }
  }
`;

export const GetPayRollEmpAttendance = gql`
  query GetPayRollEmpAttendance(
    $token: String!
    $first: Int
    $after: Cursor
    $sortBy: PayRollEmpAttendanceOrderField
    $pr_acd_yr_id: ID!
    $direction: OrderDirection!
    $input: emp_att_query!
    $name: String
  ) {
    GetPayRollEmpAttendance(
      token: $token
      input: $input
      first: $first
      after: $after
      pr_acd_yr_id: $pr_acd_yr_id
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            hasPrEmpWith: {
              or: [
                { empFirstNameContainsFold: $name }
                { empMiddleNameContainsFold: $name }
                { empLastNameContainsFold: $name }
              ]
            }
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          cal_month
          att_total_days_present
          att_total_days_absent_full
          att_total_days_absent_half
          att_total_days_absent
          day_2
          day_3
          day_4
          day_1
          day_5
          day_6
          day_7
          day_8
          day_9
          day_10
          day_11
          day_12
          day_13
          day_14
          day_15
          day_16
          day_17
          day_18
          day_19
          day_20
          day_21
          day_22
          day_23
          day_24
          day_25
          day_26
          day_27
          day_28
          day_29
          day_30
          day_31
          pr_emp_id
          pr_acd_yr_id
          pr_cal_month_id
          emp_details {
            id
            emp_id
            emp_title
            emp_first_name
            emp_middle_name
            emp_last_name
            emp_sex
            emp_dob
            emp_mobile
            emp_email
            emp_qualification
            emp_experience
            emp_status
            emp_on_roll
            is_login_created
            emp_doj
            emp_dor
            # emp_lsd
            emp_idx
            emp_dept_idx
            other_details {
              id
              emp_aadhar
              emp_pan
              emp_bank_acct_no
              emp_bank_name
              emp_bank_ifsc
              emp_epf_no
              emp_esi_no
              emp_biometric_id
              emp_esi_dispen
              emp_uan_no
              emp_passport
              emp_drv_lic
              emp_voter_id
              emp_biometric_machine_id
            }
            personal_details {
              id
              emp_father_name
              emp_mother_name
              emp_spouse_name
              emp_blood_grp
              emp_religion
              emp_caste
              emp_tel
              emp_corr_address
              emp_corr_city
              emp_corr_pin
              emp_corr_district
              emp_corr_state
              emp_perm_address
              emp_perm_city
              emp_perm_pin
              emp_perm_district
              emp_perm_state
              emp_nationality
              emp_martial
            }
            pr_category_details {
              id
              category_desc
              category_idx
            }
            pr_dept_details {
              id
              dept_desc
              dept_idx
            }
            pr_designation_details {
              id
              designation_desc
              designation_idx
            }
            pr_job_type_details {
              id
              job_type_desc
              job_type_idx
              inst_id
            }
            leave_master_details {
              leave_details {
                leave_ldgr_details {
                  id
                  leave_desc
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GetPayRollEmpAttDailyStatus = gql`
  query GetPayRollEmpAttDailyStatus(
    $token: String!
    $input: EmpAttStatusQuery
  ) {
    GetPayRollEmpAttDailyStatus(token: $token, input: $input) {
      id
      cal_month
      day_1
      day_2
      day_3
      day_4
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
      pr_acd_yr_id
      inst_id
      pr_cal_month_id
    }
  }
`;
export const GetPayRollEmpAttTodaysStatus = gql`
  query GetPayRollEmpAttTodaysStatus(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $date_of_attendance: Time!
  ) {
    GetPayRollEmpAttTodaysStatus(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      date_of_attendance: $date_of_attendance
    )
  }
`;

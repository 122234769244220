import React from "react";
import Input from "../../../stories/Input/Input";
import { handleFormEvent } from "../../../utils/UtilFunctions";

interface Props {
  onChangeHrs: React.Dispatch<React.SetStateAction<string>>;
  onChangeMins: React.Dispatch<React.SetStateAction<string>>;
  hrs: string;
  mins: string;
}

const Time = ({ onChangeHrs, onChangeMins, hrs, mins }: Props) => {
  return (
    <div>
      <Input
        value={hrs}
        onKeyDown={handleFormEvent}
        onChange={(e) => {
          const value = e.target.value;
          if ((Number(value) !== 0 && Number(value)) || value === "") {
            onChangeHrs(value);
          }
        }}
        maxLength={2}
      />
      hrs
      <Input
        value={mins}
        onKeyDown={handleFormEvent}
        onChange={(e) => {
          const value = e.target.value;
          onChangeMins(value);
        }}
        maxLength={2}
      />
      mins
    </div>
  );
};

export default Time;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InstIdVars } from "../../HR/Types/masterDataTypes";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import {
  GetAcdTestPerformance,
} from "../queries/holidays/list";
import { InstTreeQueryType, RankInfo } from "./useAcdInstTreeForTestReport";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../utils/constants";
interface TestPerformanceList {
  id: number;
  num_of_std_test_taken: number;
  num_of_std_passed: number;
  num_of_std_failed: number;
  num_of_std_mt_80_per: number;
  num_of_std_mt_70_per: number;
  num_of_std_mt_60_per: number;
  num_of_std_mt_50_per: number;
  num_of_std_mt_40_per: number;
  num_of_std_lt_40_per: number;
}

interface GetAcdTestPerformanceList {
  test_perfromance: TestPerformanceList;
  first_rank_info: RankInfo[];
  second_rank_info: RankInfo[];
  third_rank_info: RankInfo[];
}
interface GetAcdTestPerformanceData {
  GetAcdTestPerformance: GetAcdTestPerformanceList;
}
interface GetAcdTestPerformanceVars extends InstIdVars {
  test_name_id: number;
  acd_yr_id: number;
  allotted_level: string;
  allotted_id: number;
}

const useAcdTestPerformance = (
  deptId: number,
  branchId: number,
  classId: number,
  semId: number,
  sectionId: number,
  test_name_id: number
) => {
  const { InstId, allotedID } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [id, setId] = useState(0);
  const [query_type, setQueryType] = useState<InstTreeQueryType>(
    InstTreeQueryType.NONE
  );
  useEffect(() => {
    if (sectionId) {
      setId(sectionId);
      setQueryType(InstTreeQueryType.TREE_BY_SECTION_ID);
    } else if (semId) {
      setId(semId);
      setQueryType(InstTreeQueryType.TREE_BY_SEMESTER_ID);
    } else if (classId) {
      setId(classId);
      setQueryType(InstTreeQueryType.TREE_BY_CLASS_ID);
    } else if (branchId) {
      setId(branchId);
      setQueryType(InstTreeQueryType.TREE_BY_BRANCH_ID);
    } else if (deptId) {
      setId(deptId);
      setQueryType(InstTreeQueryType.TREE_BY_DEPT_ID);
    } else {
      setId(Number(InstId));
      setQueryType(InstTreeQueryType.TREE_BY_INST_ID);
    }
  }, [sectionId, semId, classId, branchId, deptId]);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  
  const [GetPerformance, { data, loading }] = useLazyQuery<
    GetAcdTestPerformanceData,
    GetAcdTestPerformanceVars
  >(GetAcdTestPerformance, {
    variables: {
      inst_id: InstId ? InstId : state.InstId,
      token,
      test_name_id,
      allotted_level: configData.data
        ? configData.data.GetSwConfigVariables[0].config_string_value
        : EMPTY_STRING,
      allotted_id: allotedID ? Number(allotedID) : 0,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token && InstId && query_type !== InstTreeQueryType.NONE) {
      GetPerformance();
    }
  }, [token, state.InstId, GetPerformance, InstId, id, query_type]);
  return {
    data,
    loading,
  };
};

export default useAcdTestPerformance;

import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import useToken from "../../../customhooks/useToken";
import usePayRollActiveAcademicYear from "../hooks/usePayRollActiveAcademicYear";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import {
  DateRange,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import { Button } from "../../../stories/Button/Button";
import { GetPayRollEmpAttDailyStatus } from "../queries/attendance/query";
import {
  GetPayRollEmpAttDailyStatusData,
  GetPayRollEmpAttDailyStatusVars,
} from "../Types/masterDataTypes";
import {
  Friday,
  Monday,
  Saturday,
  Sunday,
  Thursday,
  Tuesday,
  Wednesday,
  days,
} from "../../../utils/constants";
import usePayRollGeneralHolidays, {
  HolidayQueryType,
} from "../hooks/usePayRollGeneralHolidays";
import { GetnumberOfDaysInMonth } from "../../../utils/UtilFunctions";
import { getMonthDateAndDay } from "../../../utils/UtilFunctions";
import { getWeekByDayNum } from "../../../utils/UtilFunctions";
import { HolidayType } from "../../../utils/Enum.types";
import { Tooltip } from "@mui/material";

const emptyDaysByWeek = (week: string) => {
  let numberOfEmpty = -1;
  let numberOfEmptyToLast = -1;
  switch (week) {
    case Sunday:
      numberOfEmpty = 6;
      numberOfEmptyToLast = 0;
      break;
    case Monday:
      numberOfEmpty = 0;
      numberOfEmptyToLast = 6;
      break;
    case Tuesday:
      numberOfEmpty = 1;
      numberOfEmptyToLast = 5;

      break;
    case Wednesday:
      numberOfEmpty = 2;
      numberOfEmptyToLast = 4;
      break;
    case Thursday:
      numberOfEmpty = 3;
      numberOfEmptyToLast = 3;
      break;
    case Friday:
      numberOfEmpty = 4;
      numberOfEmptyToLast = 2;
      break;
    case Saturday:
      numberOfEmpty = 5;
      numberOfEmptyToLast = 1;
      break;
    default:
      break;
  }
  return { numberOfEmpty, numberOfEmptyToLast };
};
interface monthDaysType {
  day: number;
  status: string;
  description: string;
  week: string;
  class_name: string;
}
interface LegendsType {
  class_name: string;
  legend_name: string;
}
const MonthlyOverview = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const { serverDate } = useServerDateandTime();
  const numberOfDaysInMonth = GetnumberOfDaysInMonth(serverDate);
  const { firstDay, lastDay } = DateRange(serverDate!) || {};

  var generalHolidaysMap = useRef(new Map());
  const { PayRollGeneralHolidays } = usePayRollGeneralHolidays(
    payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    HolidayQueryType.ALL_HOLIDAYS,
    true
  );

  const [monthDays, setMonthDays] = useState<monthDaysType[]>([]);
  const { year, month } = getMonthDateAndDay(serverDate!);
  const ledgends_json: LegendsType[] = [
    {
      class_name: "border-left-for-holidays",
      legend_name: "General or Unexpected Holidays",
    },
    {
      class_name: "weekend-dates",
      legend_name: "Weekend Days",
    },
    {
      class_name: "cal-bg-green",
      legend_name: "Marked",
    },
    {
      class_name: "cal-bg-red",
      legend_name: "Un Marked",
    },
    {
      class_name: "",
      legend_name: "Future Dates",
    },
  ];
  const [GetMarkedStatus, { data }] = useLazyQuery<
    GetPayRollEmpAttDailyStatusData,
    GetPayRollEmpAttDailyStatusVars
  >(GetPayRollEmpAttDailyStatus, {
    variables: {
      input: {
        pr_acd_yr_id:
          payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!,
        cal_month: toIsoDate(serverDate!),
        inst_id: InstId!,
      },
      token,
    },
  });

  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;
      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          class_name: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          class_name: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };

  const chooseTypeAndColor = (status: boolean, day: number) => {
    if (generalHolidaysMap.current.has(day)) {
      const holiday_details = generalHolidaysMap.current.get(day)!;
      switch (holiday_details.holiday_type) {
        case HolidayType.GENERAL || HolidayType.UNEXPECTED:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "border-left-for-holidays",
          };
        case HolidayType.WEEKEND:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "weekend-dates",
          };
        default:
          break;
      }
    } else {
      const toDay = new Date(serverDate).getDate();
      if (day <= toDay) {
        if (status) {
          return {
            type: "M",
            description: "",
            class_name: "cal-bg-green",
          };
        } else {
          return {
            type: "UM",
            description: "",
            class_name: "cal-bg-red",
          };
        }
      } else {
        return {
          type: "",
          description: "",
          class_name: "",
        };
      }
    }
  };

  useEffect(() => {
    if (payRollActiveAcademicYear.data && !payRollActiveAcademicYear.loading) {
      generalHolidaysMap.current = new Map(
        PayRollGeneralHolidays.data?.GetPayRollGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetMarkedStatus().then(({ data }) => {
        if (data) {
          setMonthDays(
            Array.from({ length: numberOfDaysInMonth }, (_, i) => {
              const dayNum = i + 1;
              const holiday_details = chooseTypeAndColor(
                (data.GetPayRollEmpAttDailyStatus as Record<string, boolean>)[
                  `day_${dayNum}`
                ],
                dayNum
              );
              const date = `${year}/${month + 1}/${dayNum}`;
              const { week } = getMonthDateAndDay(date);
              const week_string = getWeekByDayNum(week);
              return {
                day: dayNum,
                status: holiday_details?.type!,
                description: holiday_details?.description!,
                class_name: holiday_details?.class_name!,
                week: week_string!,
              };
            })
          );
        }
      });
    } // eslint-disable-next-line
  }, [
    payRollActiveAcademicYear.data,
    payRollActiveAcademicYear.loading,
    serverDate,
    GetMarkedStatus,
    numberOfDaysInMonth,
    data,
    PayRollGeneralHolidays.data,
  ]);

  return (
    <div className="emp-attendance-overview__monthly-overview">
      <Title variant="subtitle1">Monthly Attendance Overview</Title>
      <span className="font-green">Marked</span>
      <div className="emp-attendance-overview__monthly-overview--date">
        <Label variant="LabelBold">{toStandardDate(serverDate)}</Label>
        <Button mode="update"> Attendance</Button>
      </div>

      <div className="row g-0 emp-attendance-overview__monthly-overview--calendar">
        <ul className="emp-attendance-overview__monthly-overview--calendar--grid-container">
          {days.map((day, index: React.Key) => {
            return (
              <li
                key={index}
                className="emp-attendance-overview__monthly-overview--calendar--grid-container--day"
              >
                {day}
              </li>
            );
          })}

          {weekCount().map(
            ({ day, class_name, description, status }, index) => {
              return day ? (
                status === HolidayType.GENERAL ||
                status === HolidayType.UNEXPECTED ? (
                  <Tooltip
                    title={<>{description}</>}
                    arrow
                    placement="right"
                    key={index}
                  >
                    <li
                      className={`emp-attendance-overview__monthly-overview--calendar--grid-item ${class_name}`}
                    >
                      <span
                        className={`emp-attendance-overview__monthly-overview--calendar--grid-item--date `}
                      >
                        {day > 0 ? day : ""}
                      </span>
                    </li>
                  </Tooltip>
                ) : (
                  <li
                    key={index}
                    className={`emp-attendance-overview__monthly-overview--calendar--grid-item ${class_name}`}
                  >
                    <span
                      className={`emp-attendance-overview__monthly-overview--calendar--grid-item--date `}
                    >
                      {day > 0 ? day : ""}
                    </span>
                  </li>
                )
              ) : (
                <li key={index}></li>
              );
            }
          )}
        </ul>
      </div>
      <div className="row g-0 emp-attendance-overview__monthly-overview--calendar--legends">
        <ul>
          {ledgends_json.map(({ class_name, legend_name }, index) => {
            return (
              <li
                key={index}
                className="emp-attendance-overview__monthly-overview--calendar--li"
              >
                <div
                  className={`emp-attendance-overview__monthly-overview--calendar--legends--grid-item ${class_name}`}
                >
                  1
                </div>
                &rarr;
                <span>{legend_name}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MonthlyOverview;

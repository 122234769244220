import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { GetAcdStudentsMarksStatisticsAtEntryLevel } from "../../queries/test/query";
import useToken from "../../../../customhooks/useToken";
import { responseType } from "../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import EduateLogo from "../../../../images/EduateLogo.svg";
import { Title } from "../../../../stories/Title/Title";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { EMPTY_STRING } from "../../../../utils/constants";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import Avatar from "../../../../images/Avatar.svg";
import { formatter, toStandardDate } from "../../../../utils/UtilFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import SecuredPercentage from "../../../../images/Conversion.svg";
import AttendancePercentage from "../../../../images/PercentageInMarksSheet.svg";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../stories/Button/Button";
import { AppContext } from "../../../../context/context";
import { FileUploadParams } from "../../../../utils/Enum.types";
import usePerTestReportConfig from "../../../Print/ProgressReport/hooks/usePerTestReportConfig";
interface SubjectMarks {
  subj_master_id: number;
  subj_desc: string;
  subj_code: string;
  std_marks_scored: number;
  marks_percentage: number;
  subj_total_max_marks: number;
  subj_total_min_marks: number;
  subj_max_marks_scored: number;
  subj_avg_marks_scored: number;
  is_std_present: boolean;
}
interface StudentAcdDetails {
  std_adm_no: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  branch_desc: string;
  class_desc: string;
  section_desc: string;
  std_profile_filename: string;
  std_father_name: string;
  std_mother_name: string;
}

interface GetAcdStudentsMarksStatisticsAtEntryLevelDetails {
  student_id: number;
  acd_test_class_id: number;
  test_start_date: string;
  test_end_date: string;
  test_type_name: string;
  acd_test_name: string;
  test_total_max_marks: number;
  test_total_min_marks: number;
  test_top_percentage: number;
  test_avg_percentage: number;
  att_percentage: number;
  test_remarks: string;
  test_std_total_marks_scored: number;
  test_std_total_percentage: number;
  student_details: StudentAcdDetails;
  marks_details: SubjectMarks[];
}
interface GetAcdStudentsMarksStatisticsAtEntryLevelData {
  GetAcdStudentsMarksStatisticsAtEntryLevel: GetAcdStudentsMarksStatisticsAtEntryLevelDetails[];
}
interface StdStatisticsInput {
  inst_id: number | string;
  acd_test_class_id: number;
  entry_level: string;
  entry_id: number;
  test_type_id: number;
}
interface GetAcdStudentsMarksStatisticsAtEntryLevelVars {
  token: string;
  inst_id: string | number;
  acd_yr_id: number;
  student_ids: number[];
  input: StdStatisticsInput;
  acd_subj_combo_id: number | string;
}
interface Props {
  testConductId: responseType | null;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
  forCombination?: boolean;
}

const ProgressReport = ({
  testConductId,
  setModalFlag,
  selectedStudents,
  setStudentIdSet,
  forCombination,
}: Props) => {
  const { token } = useToken();
  const { entry_level } = useInstitutionConfiguration();
  const { format } = formatter;
  const componentRef = useRef<HTMLDivElement>(null);
  const instLogoDetails = {
    LogoOrSign: useInstLogoDetails({ filetype: FileUploadParams.INST_LOGO })
      .LogoOrSign,
    PrincipalSign: useInstLogoDetails({
      filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
    }).LogoOrSign,
    Photo1: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_1 })
      .LogoOrSign,
    Photo2: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_2 })
      .LogoOrSign,
  };
  const { LogoOrSign, PrincipalSign, Photo1, Photo2 } = instLogoDetails;

  const { InstFormData, InstDetails } = useInstDetails(1);
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [GetStats, { data: statsData }] = useLazyQuery<
    GetAcdStudentsMarksStatisticsAtEntryLevelData,
    GetAcdStudentsMarksStatisticsAtEntryLevelVars
  >(GetAcdStudentsMarksStatisticsAtEntryLevel);

  useEffect(() => {
    if (
      token &&
      testConductId &&
      entryId &&
      selectedStudents &&
      entry_level &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetStats({
        variables: {
          token,
          inst_id: InstId!,
          student_ids: selectedStudents,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            inst_id: InstId!,
            acd_test_class_id: testConductId ? testConductId.value : 0,
            entry_level: entry_level,
            entry_id: entryId ? Number(entryId) : 0,
            test_type_id: 0,
          },
          acd_subj_combo_id:
            forCombination && state.acdCombinationId
              ? state.acdCombinationId
              : 0,
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    testConductId,
    entryId,
    selectedStudents,
    entry_level,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStats,
    state.ActiveAcdYr,
  ]);

  const { USE_BRANCH_KEY, USE_CLASS_KEY, USE_SECTION_KEY } =
    useInstitutionConfiguration();
  const { branchLabel, classLabel, sectionLabel } = useInstLabels();
  const [imageString, setImageString] = useState("");
  useEffect(() => {
    if (
      statsData &&
      statsData.GetAcdStudentsMarksStatisticsAtEntryLevel[0].student_details
        .std_profile_filename !== EMPTY_STRING &&
      InstDetails.data &&
      InstDetails.data.nodes.length
    ) {
      const studentProfiePicUrl = `${InstDetails.data.nodes[0]?.inst_name}/students/${statsData?.GetAcdStudentsMarksStatisticsAtEntryLevel[0].student_id}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [statsData, InstDetails.data]);
  const {
    instAddress,
    instLogo,
    instName,
    stdName,
    section,
    motherName,
    fatherName,
    rollNo,
    custName,
    parentSign,
    attendanceStats,
    remarksPerTest,
    classTeacherSign,
    overAllPercentage,
    stdImage,
    photo1Config,
    photo2Config,
    title,
  } = usePerTestReportConfig();

  return (
    <>
      <div ref={componentRef} className="progress-report">
        {statsData?.GetAcdStudentsMarksStatisticsAtEntryLevel.map(
          (data, index) => {
            return (
              <div className="progress-report__print" key={index}>
                <style type="text/css" media="print" scoped>
                  {`
                html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;

    }
            @page {
                size:A4 Portrait;
          width:100%;
          height:100%;

        }
           
  .progress-report__print--form{
    padding:40px !important;
  }
  .progress-report__signature{
    position:fixed;
    bottom:30px;
    width:calc(100% - 80px);
    
  }

          `}
                </style>
                <div className="progress-report__print--form">
                  <div
                    className="progress-report__inst--logos"
                    style={
                      photo1Config?.config_boolean_value &&
                      photo2Config?.config_boolean_value
                        ? { justifyContent: "space-between" }
                        : photo1Config?.config_boolean_value ||
                          photo2Config?.config_boolean_value
                        ? { justifyContent: "space-between" }
                        : { justifyContent: "center" }
                    }
                  >
                    {photo1Config?.config_boolean_value === true &&
                    photo2Config?.config_boolean_value === false ? (
                      <>
                        {instLogo?.config_boolean_value ? (
                          <div className="progress-report__inst--logo">
                            {LogoOrSign.defaultLogo ? (
                              <img src={LogoOrSign.defaultLogo} alt="/" />
                            ) : (
                              <img src={EduateLogo} alt="/" />
                            )}
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {photo1Config?.config_boolean_value && (
                          <div className="progress-report__inst--logo">
                            {Photo1.defaultLogo ? (
                              <img src={Photo1.defaultLogo} alt="/" />
                            ) : (
                              <img src={EduateLogo} alt="/" />
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {photo1Config?.config_boolean_value === false &&
                      photo2Config?.config_boolean_value === true && (
                        <>
                          {instLogo?.config_boolean_value ? (
                            <div className="progress-report__inst--logo">
                              {LogoOrSign.defaultLogo ? (
                                <img src={LogoOrSign.defaultLogo} alt="/" />
                              ) : (
                                <img src={EduateLogo} alt="/" />
                              )}
                            </div>
                          ) : null}
                        </>
                      )}
                    <div>
                      <div className="progress-report__inst--details">
                        {(photo1Config?.config_boolean_value === false &&
                          photo2Config?.config_boolean_value === true) ||
                        (photo1Config?.config_boolean_value === true &&
                          photo2Config?.config_boolean_value === false) ? (
                          <></>
                        ) : (
                          <>
                            {instLogo?.config_boolean_value ? (
                              <div className="progress-report__inst--logo">
                                {LogoOrSign.defaultLogo ? (
                                  <img src={LogoOrSign.defaultLogo} alt="/" />
                                ) : (
                                  <img src={EduateLogo} alt="/" />
                                )}
                              </div>
                            ) : null}
                          </>
                        )}

                        {custName?.config_boolean_value ? (
                          <span className="progress-report__inst--details--cust-name">
                            {InstFormData.cust_name}
                          </span>
                        ) : null}
                        {instName?.config_boolean_value ? (
                          <b className="progress-report__inst--details--inst-name">
                            {InstFormData.inst_name_to_print}
                          </b>
                        ) : null}
                        {instAddress?.config_boolean_value ? (
                          <span className="progress-report__inst--details--inst-address">
                            {[
                              InstFormData.inst_address,
                              InstFormData.inst_place,
                              InstFormData.inst_pin,
                            ]
                              .filter(Boolean)
                              .join(", ")
                              .trim()}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {photo1Config?.config_boolean_value === true &&
                      photo2Config?.config_boolean_value === false && (
                        <>
                          {photo1Config?.config_boolean_value && (
                            <div className="progress-report__inst--logo">
                              {Photo1.defaultLogo ? (
                                <img src={Photo1.defaultLogo} alt="/" />
                              ) : (
                                <img src={EduateLogo} alt="/" />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    {photo2Config?.config_boolean_value ? (
                      <div className="progress-report__inst--logo">
                        {Photo2.defaultLogo ? (
                          <img
                            src={Photo2.defaultLogo}
                            alt="/"
                            className="bgs-pn-pr__inst-details--logo"
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>

                  {title?.config_boolean_value ? (
                    <Title>{title.config_string_value}</Title>
                  ) : null}

                  <div className="progress-report__student">
                    {stdImage?.config_boolean_value ? (
                      <div className="progress-report__student--image">
                        {imageString ? (
                          <img src={imageString} alt="/" />
                        ) : (
                          <img src={Avatar} alt="/" />
                        )}
                      </div>
                    ) : null}

                    <div className="progress-report__student--details">
                      {stdName?.config_boolean_value ? (
                        <>
                          <span>{stdName.config_string_value} </span>

                          <b>
                            &nbsp;
                            {data.student_details.first_name +
                              " " +
                              data.student_details.middle_name +
                              " " +
                              data.student_details.last_name}
                          </b>
                        </>
                      ) : null}

                      {rollNo?.config_boolean_value ? (
                        <>
                          <span>Adm No. : </span>

                          <span>{data.student_details.std_adm_no}</span>
                        </>
                      ) : null}
                      {fatherName?.config_boolean_value ? (
                        <>
                          <span>{fatherName.config_string_value}: </span>
                          <span>{data.student_details.std_father_name}</span>
                        </>
                      ) : null}
                      {motherName?.config_boolean_value ? (
                        <>
                          <span>{motherName.config_string_value} </span>

                          <span>{data.student_details.std_mother_name}</span>
                        </>
                      ) : null}
                    </div>

                    <div className="progress-report__student--details">
                      {USE_BRANCH_KEY && (
                        <>
                          <span>{branchLabel} : </span>

                          <span>{data.student_details.branch_desc}</span>
                        </>
                      )}
                      {USE_CLASS_KEY && (
                        <>
                          <span>{classLabel} :</span>
                          <span>{data.student_details.class_desc}</span>
                        </>
                      )}
                      {section?.config_boolean_value && (
                        <>
                          {USE_SECTION_KEY && (
                            <>
                              <span>{sectionLabel} :</span>
                              <span>{data.student_details.section_desc}</span>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="progress-report__test">
                    <span className="progress-report__test--name">
                      {data.acd_test_name}
                    </span>
                    <span className="progress-report__test--duration">
                      &nbsp; ({toStandardDate(data.test_start_date!)} -{" "}
                      {toStandardDate(data.test_end_date)})
                    </span>
                  </div>
                  {/* <div className="progress-report__group">Part A</div> */}
                  <div className="progress-report__tableblock">
                    <TableContainer className="progress-report__table">
                      <Table>
                        <TableHead>
                          <TableRow className="progress-report__table--group-header">
                            <TableCell rowSpan={2}>Sl</TableCell>
                            <TableCell rowSpan={2}>Subject Name</TableCell>
                            <TableCell colSpan={2}>Total Marks</TableCell>
                            <TableCell rowSpan={2}>Marks Obtained</TableCell>
                          </TableRow>
                          <TableRow className="progress-report__table--sub-header">
                            <TableCell>Max</TableCell>
                            <TableCell>Min</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.marks_details.map((res, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  id="td-center"
                                  className="progress-report__table--slno"
                                >
                                  {index + 1}
                                </TableCell>

                                <TableCell>{res.subj_desc}</TableCell>
                                <TableCell id="td-center">
                                  {res.subj_total_max_marks}
                                </TableCell>
                                <TableCell id="td-center">
                                  {res.subj_total_min_marks}
                                </TableCell>
                                <TableCell id="td-center">
                                  {res.is_std_present
                                    ? res.std_marks_scored
                                    : "A"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>

                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={2} className="total">
                              Total :
                            </TableCell>
                            <TableCell id="td-center">
                              {data.test_total_max_marks}{" "}
                            </TableCell>
                            <TableCell id="td-center">
                              {data.test_total_min_marks}
                            </TableCell>
                            <TableCell align="center">
                              {data.test_std_total_marks_scored}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>

                  {overAllPercentage?.config_boolean_value && (
                    <div className="progress-report__percentage--details">
                      <div>
                        <img src={SecuredPercentage} alt="" />
                        <span> Secured Percentage</span>
                      </div>
                      <b>: {data.test_std_total_percentage}</b>%
                    </div>
                  )}

                  {/* <div className="progress-report__percentage--details">
                    <div>
                      <img src={OverAllGrade} alt="" />
                      <span> OverAll Grade :</span>
                    </div>
                    <b>A</b>
                  </div> */}
                  {attendanceStats?.config_boolean_value && (
                    <div className="progress-report__percentage--details">
                      <div>
                        <img src={AttendancePercentage} alt="" />
                        <span> Attendance Percentage</span>
                      </div>
                      <b>:{format(data.att_percentage)}%</b>
                    </div>
                  )}

                  {/* <div className="progress-report__group">Part B</div>
                  <div className="progress-report__tableblock">
                    <TableContainer className="progress-report__table2">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sl</TableCell>
                            <TableCell>Subject Name</TableCell>
                            <TableCell>Grade</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell id="td-center">1</TableCell>
                            <TableCell>Drawing</TableCell>
                            <TableCell className="font-green">A</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>*/}
                  {remarksPerTest && (
                    <div className="progress-report__remarks">
                      <span>Remarks</span>
                      <b>: {data.test_remarks}</b>
                    </div>
                  )}

                  <div className="progress-report__signature">
                    <div>
                      {PrincipalSign.defaultLogo ? (
                        <img src={PrincipalSign.defaultLogo} alt="/" />
                      ) : null}
                      <div className="progress-report__signature--text">
                        PRINCIPAL SIGN
                      </div>
                    </div>
                    {classTeacherSign?.config_boolean_value && (
                      <div>
                        <div style={{ height: "25px" }}></div>
                        <div className="progress-report__signature--text">
                          {classTeacherSign.config_string_value}
                        </div>
                      </div>
                    )}

                    {parentSign?.config_boolean_value && (
                      <div>
                        <div style={{ height: "25px" }}></div>
                        <div className="progress-report__signature--text">
                          {parentSign.config_string_value}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="page-break" />
              </div>
            );
          }
        )}
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
      <Button
        mode="cancel"
        onClick={() => {
          setModalFlag(false);
          setStudentIdSet(new Set());
        }}
      />
    </>
  );
};

export default ProgressReport;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../context/context";

import useToken from "../../../customhooks/useToken";
import { GetAcctStdDemandDetails } from "../../../queries/students/list/byId";
import { studentDemandDetails } from "../../../Types/Accounting";

import { DemandDetailsQueryType } from "../common/QueryTypes";

export interface studentDemandDetailsVars {
  student_id: number;
  token: string;
  query_type: DemandDetailsQueryType;
  is_capitation_fee: boolean;
  voucher_book_type: string;
  fin_yr_id: number;
}

export interface studentDemandDetailsData {
  GetAcctStdDemandDetails: studentDemandDetails[];
}

const useStudentDemandDetails = (
  query_type: DemandDetailsQueryType,
  is_capitation_fee: boolean,
  voucher_book_type: string
) => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [GetStudentDemandDetails, { data, loading, error }] = useLazyQuery<
    studentDemandDetailsData,
    studentDemandDetailsVars
  >(GetAcctStdDemandDetails, { fetchPolicy: "network-only" });
  useEffect(() => {
    if (query_type && token && state.studentId !== 0 && state.ActiveFinYr) {
      GetStudentDemandDetails({
        variables: {
          query_type: query_type,
          student_id: state.studentId,
          token,
          voucher_book_type,
          is_capitation_fee,
          fin_yr_id: state.ActiveFinYr.id,
        },
      });
    }
  }, [
    query_type,
    token,
    state.studentId,
    GetStudentDemandDetails,
    is_capitation_fee,
    voucher_book_type,
  ]);

  return { StudentDemandDetails: { data, loading, error } };
};

export default useStudentDemandDetails;

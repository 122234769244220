import Home from "../../../Master/configurations/Home/Index";
const Index = () => {
  return (
    <>
      <Home DashBoardRequired={false} />
    </>
  );
};

export default Index;

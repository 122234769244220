//React
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";

//Material Ui
import {  TextField } from "@mui/material";

//Styles

import {
  ConfigurationsModalStyles,
  LibraryRepotsModalStyles,
} from "../../../../styles/ModalStyles";

//Images
import Close from "../../../../images/Close.svg";
import Settings from "../../../../images/Settings.svg";

//Types
import {
  ExcelAlignment,
  ExcelFont,
  ExcelPageHeader,
  ExcelSheetsNames,
  PageNumbers,
  SortBy,
  StudentReportType,
  UserType,
} from "../../../../utils/Enum.types";

//custom Hooks
import useDropdownData from "../../../../customhooks/useDropdownData";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";

//Constants and Functions
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

//HTML Hocs
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";

//Components
import Home from "../../Home/Index";
import List from "./List";

//Modal
import BooksIssued from "../../BooksTransaction/BookIssue/BookIssueList";
import ConfigurationSettings from "../../../Master/configurations/general/Index";
import { responseType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import useAcdStudentsData, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsData";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
  TABLE_HEADER_CSS4,
} from "../../Constants";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import useServerDateandTime from "../../customHooks/useServerDateandTime";

import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import { AppContext } from "../../../../context/context";
import { TableHeaderProps } from "../../../../utils/types";
import {
  
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
 

interface bookIssuedModal {
  flag: boolean;
  id: number;
  userType: UserType | null;
}
const Index = () => {
  const { serverDate } = useServerDateandTime();
  const { Library_Table } = useLibraryTableJson();
  const { state } = useContext(AppContext);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [configurationModal, setConfigurationModal] = useState(false);
  const [bookIssueModal, setBookIssueModal] = useState<bookIssuedModal>();
  //Styles

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_REPORTS_PAGE
  );
  // const {
  //   data: {
  //     data: data,
  //     error: StudentsDataError,
  //     loading: StudentsDataLoading,
  //     fetchMore,
  //   },
  // } = useStudentsbyNewApi(
  //   departmentSelected.value,
  //   branchSelected.value,
  //   classSelected.value,
  //   semesterSelected.value,
  //   sectionSelected.value,
  //   0,
  //   EMPTY_STRING,
  //   EMPTY_STRING,
  //   rowsPerPage,
  //   StudentReportType.LIB_BOOK_CLEARANCE
  // );
  const {
    AcademicsStudentData: { data, loading, error, fetchMore },
  } = useAcdStudentsData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value,
    sectionSelected.value,
    0,
    ROWS_PER_PAGE,
    0,
    EMPTY_STRING,
    StudentReportType.LIB_BOOK_CLEARANCE
  );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  //Pagination Methods

  const currentRepo = data?.GetAcdStudents?.edges;

  const { InstDetails } = useInstDetails(1);

  const studentData = currentRepo?.map((edge, index) => ({
    SlNo: index + 1,
    Name:
      edge.node.first_name +
      " " +
      edge.node.middle_name +
      " " +
      edge.node.last_name,
    Dept: edge.node.acd_dept.dept_desc,
    branch: edge.node.acd_branch.branch_desc,
    books: edge.node?.misc_data?.lib_book_count,
  }));

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  const bookClearanceHeaderData = [
    "SlNo",
    "Student Name",
    departmentLabel,
    branchLabel,
    "Books Not Returned",
  ];

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      ExcelPageHeader.BOOK_CLEARANCE_LIST
    );

    const dataRows = studentData
      ? studentData?.map((item) => [
          item.SlNo,
          item.Name,
          item.Dept,
          item.branch,
          item.books,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "M1:M3");

          worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;

          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:E2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:E3");

          const mergedHeader: Cell = worksheet.getCell(C4_CELL);
          mergedHeader.value = ExcelPageHeader.BOOK_CLEARANCE_LIST;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("C4:D4");
          const mergedDate: Cell = worksheet.getCell(E4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("E4:E4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:B4");

          let Char = FIRST_INDEX;

          for (let i = 0; i < bookClearanceHeaderData.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 5);
            rowData.value = bookClearanceHeaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }

          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.CENTER };
              cell.font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };
              cell.fill = TABLE_HEADER_CSS4;
            });
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.BOOK_CLEARANCE);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
  };
  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Reports.Books_Clearance_List.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,

            std_name: node.first_name + node.middle_name + node.last_name,

            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            books_returned: node.misc_data.lib_book_count,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,

            std_name: node.first_name + node.middle_name + node.last_name,

            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            books_returned: node.misc_data.lib_book_count,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>Transaction List</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>

      <div className="library-report">
        <div className="library-report__transaction-list">
          <div className="library-report__transaction-list--tableblock">
            <List />
          </div>
        </div>
        <div className="library-report__selected-data">
          <div className="row g-0 library-report__selected-data--select-flex">
            {USE_DEPARTMENT_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={departmentDropDown}
                  openOnFocus
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue as responseType);
                      setHasNextPage(true);

                    } else {
                      setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      fullWidth
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={branchDropDown}
                  openOnFocus
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue as responseType);
                    } else {
                      setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      fullWidth
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={classDropDown}
                  openOnFocus
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue as responseType);
                    } else {
                      setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={classLabel}
                      fullWidth
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={semesterDropDown}
                  openOnFocus
                  value={semesterSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterSelected(newValue as responseType);
                    } else {
                      setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      fullWidth
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={sectionDropDown}
                  openOnFocus
                  value={sectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionSelected(newValue as responseType);
                    } else {
                      setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      fullWidth
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <hr className="dashed" />
          <div className="library-report__selected-data--title">
            <Title variant="subtitle1">Books Clearance List</Title>
          </div>
          <div
            className={`library-report__selected-data--tableblock`}
          >
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              hideFooter
           
            />
          </div>
        </div>
      </div>
      {/* book-received modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookIssueModal?.flag!}
        style={LibraryRepotsModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BooksIssued
              id={bookIssueModal?.id!}
              userType={bookIssueModal?.userType!}
            />
            <Button
              mode="cancel"
              onClick={() =>
                setBookIssueModal({
                  flag: false,
                  id: 0,
                  userType: null,
                })
              }
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setBookIssueModal({
                  flag: false,
                  id: 0,
                  userType: null,
                })
              }
            />
          </div>
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_REPORTS_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <div className="button-left">
        <Button mode="print" />
        <Button onClick={() => downloadExcel()} mode="export" />
        <Button mode="back" />
      </div>
    </>
  );
};

export default Index;

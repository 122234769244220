import React, { useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import Avatar from "../../../../../images/Avatar.svg";
import Close from "../../../../../images/Close.svg";
import History from "../../../../../images/Refresh.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Label } from "../../../../../stories/Label/Label";
import Input from "../../../../../stories/Input/Input";
import { TableHeaderProps } from "../../../../../Types/Tables";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import TransactionHistory from "../../../../Library/Modals/BookTransactions/Index";
import useBookEntryOptions from "../../../../Library/customHooks/useBookEntryOptions";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../../utils/constants";
import {
  BookListType,
  BooksReportType,
  LibraryConfigKey,
  PageFor,
  UserType,
} from "../../../../../utils/Enum.types";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import {
  GetNoOfDaysDelayed,
  getDueDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import BookSearch from "../../../../Library/BooksSearch/Index";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import useMasterTableJson from "../../../../../json/useMasterTableJson";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();

  const [bookTransaction, setBookTransaction] = useState(false);
  const [bookSearchModal, setBookSearchModal] = useState(false);
  const { configData: NoOfBookBorrowingDays } = useSwConfigData(
    LibraryConfigKey.LIB_BOOK_NO_OF_BORROWING_DAYS
  );
  const { configData: IssueLimit } = useSwConfigData(
    LibraryConfigKey.LIB_BOOKS_ISSUE_LIMIT
  );
  const BOOKS_ISSUE_LIMIT =
    IssueLimit.data?.GetSwConfigVariables[0].config_integer_value!;
  const DELAY_DAYS_LIMIT =
    NoOfBookBorrowingDays.data?.GetSwConfigVariables[0].config_integer_value!;
  const {
    bookEntries: {
      issuedBooksData: { data: IssueBookData },
    },
  } = useBookEntryOptions(
    EMPTY_STRING,
    ROWS_PER_PAGE,
    BooksReportType.BOOKS_ISSUED,
    UserType.STUDENT
  );

  const { studentData } = useStudentDatabyId();
  const { branchLabel, classLabel } = useInstLabels();

  return (
    <>
      {pageType === PageFor.GENERAL && <Home />}
      <Title>Library</Title>
      <div
        className={
          pageType === PageFor.MODAL ? "std-login-lib__modal" : "std-login-lib"
        }
      >
        <div className="std-login-lib__details row g-0">
          <div className="col">
            <TextField
              className="std-login-lib__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              label="Admission Number"
              disabled
              value={studentData.data && studentData.data.nodes[0].std_adm_no}
            />
            <div className="std-login-lib__details--issued-details">
              <Label>Max. Issue Limit</Label>
              <Input
                id={
                  IssueBookData?.nodes?.length! >= BOOKS_ISSUE_LIMIT
                    ? "bg-red"
                    : "bg-green"
                }
                value={IssueBookData ? IssueBookData.nodes.length : 0}
                disabled
              />
              <span>/</span>
              <Input value={BOOKS_ISSUE_LIMIT ?? 0} disabled id="bg-red" />
            </div>
          </div>
          <div className="col">
            <TextField
              className="std-login-lib__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              label="Name"
              disabled
              value={
                studentData?.data?.nodes[0].first_name +
                " " +
                studentData?.data?.nodes[0].middle_name +
                " " +
                studentData?.data?.nodes[0].last_name
              }
            />

            <TextField
              className="std-login-lib__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              label={branchLabel}
              disabled
              value={studentData?.data?.nodes[0].branch.branch_desc}
            />
          </div>
          <div className="col">
            <TextField
              className="std-login-lib__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              label={classLabel}
              disabled
              value={studentData?.data?.nodes[0].class.class_desc}
            />
          </div>
          <div className="col-1 std-login-lib__details--image">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="std-login-lib__tableblock">
          <Title variant="subtitle1">Books With Me</Title>
          <TableContainer className="std-login-lib__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Masters_Table.Students.Dashboard.BookDetails.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {IssueBookData &&
                  IssueBookData?.nodes?.map((data, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          className="std-login-lib__table--fine--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="std-login-lib__table--fine--accessno">
                          {data.book_access_no}
                        </TableCell>
                        <TableCell>
                          {data.classification.classification_desc}
                        </TableCell>
                        <TableCell>
                          {data.classification.publisher.publisher_name}
                        </TableCell>
                        <TableCell
                          className="std-login-lib__table--fine--date"
                          id="td-center"
                        >
                          {toStandardDate(data.issue_details.issue_date)}
                        </TableCell>
                        <TableCell
                          className="std-login-lib__table--fine--date"
                          id="td-center"
                        >
                          {toStandardDate(data.issue_details.issue_return_date)}
                        </TableCell>
                        <TableCell
                          className="std-login-lib__table--fine--delayed"
                          id="td-right"
                        >
                          {GetNoOfDaysDelayed(
                            getDueDate(
                              data.book_last_issued_date,
                              DELAY_DAYS_LIMIT
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button onClick={() => setBookSearchModal(!bookSearchModal)}>
              Search Book
            </Button>
            {pageType === PageFor.MODAL ? (
              <Button mode="cancel" onClick={() => setModalFlag(false)} />
            ) : (
              <Button mode="back" onClick={() => navigate(-1)} />
            )}
          </div>
          <div
            className="col std-login-lib__history"
            onClick={() => setBookTransaction(!bookTransaction)}
          >
            <Label>My Book Transaction Details</Label>
            <img src={History} alt="/" />
          </div>
        </div>
      </div>

      <Modal
        isOpen={bookTransaction}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TransactionHistory />
            <Button
              mode="cancel"
              onClick={() => setBookTransaction(!bookTransaction)}
            />
          </div>
          <div className="modal-flex__image ">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setBookTransaction(!bookTransaction)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={bookSearchModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BookSearch
              pageType={PageFor.MODAL}
              bookListType={BookListType.BY_CLASSIFICATION}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setBookSearchModal(!bookSearchModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

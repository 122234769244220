import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";
import Modal from "react-modal";
import FeeReceipt, {
  PayerType,
} from "../../../Accounts/StudentDetails/FeeReceipt/Index";
import {
  ConsolidatedPayModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import {
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  TransactionStatusType,
} from "../../../../utils/Enum.types";
import {
  msgType,
  responseType,
  studentPieChartDeatils,
} from "../../../../utils/Form.types";

import CompleteFeeDetails from "./StudentFeeDetails/Index";

import { useLazyQuery } from "@apollo/client";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../queries/students/list/byId";
import {
  GetStdCompleteFeeDetailsByStudentIDData,
  studentDemandVars,
} from "../../../../Types/Accounting";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import useFinancialYears from "../../../Academics/hooks/useFinancialYears";
import { EMPTY_RESPONSETYPE_OBJECT } from "../../../../utils/constants";
import PayOnline from "../../../../images/PayOnline.svg";
import FeeBalance from "../../../../images/FeeBalanceInDashboard.svg";
import TotalFee from "../../../../images/TotalFeeInStdDashboard.svg";
import PaidFee from "../../../../images/PaidFeeInStdDashboard.svg";
import { formatter } from "../../../../utils/UtilFunctions";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { InstitutionAcctConfigurationTypes } from "../../../Accounts/common/Enum.types";
import ConsolidatedAmountPay from "../Modals/ConsolidatedAmountPay";
import MessageModal from "../../../../pages/MessageModal";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import PayByDemand from "./StudentFeeDetails/PayByDemand";
import { CustomModalProps } from "../../../Logs/ModuleWiseDetails/Accounts";
import StudentCompleteFeeReceipt from "../../../Accounts/StudentDetails/CompleteFeeReceipt/Index";

const FeeDetails = () => {
  const { format } = formatter;
  const [payOnline, setPayOnline] = useState(false);
  const [consolidatePayOnline, setConsolidatePayOnline] = useState(false);

  const [completeReceiptModal, setCompleteReceiptModal] = useState(false);

  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [fin_yr, setFinYr] = useState<responseType | null>(null);
  const [payFeeByDemand, setPayFeeByDemand] = useState(false);
  const { financialYearResponse } = useFinancialYears();
  const { configData } = useSwConfigData([
    InstitutionAcctConfigurationTypes.SHOW_ONLY_CONSOL_FEE_IN_STD_LOGIN,
    InstitutionConfigurationTypes.PAYMENT_GATEWAY,
    InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let consolidatedFeeEnabled;
    let enablePaymentGateway;
    let defaultBank = 0;
    let selectedPaymentGateway = "";
    let encryptionKey = "";
    let PartialFee;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.SHOW_ONLY_CONSOL_FEE_IN_STD_LOGIN:
            consolidatedFeeEnabled = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.PAYMENT_GATEWAY:
            enablePaymentGateway = item.config_boolean_value;
            defaultBank = item.config_integer_value;
            selectedPaymentGateway = item.config_string_value;
            encryptionKey = item.config_string_value_2;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT:
            PartialFee = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      consolidatedFeeEnabled,
      enablePaymentGateway,
      defaultBank,
      selectedPaymentGateway,
      encryptionKey,
      PartialFee,
    };
  };
  const {
    defaultBank,
    enablePaymentGateway,
    encryptionKey,
    consolidatedFeeEnabled,
    PartialFee,
  } = filterDataByConfigKey(configData.data?.GetSwConfigVariables!);

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const [GetStdDemand, { loading }] = useLazyQuery<
    GetStdCompleteFeeDetailsByStudentIDData,
    studentDemandVars
  >(GetStdCompleteFeeDetailsByStudentID);

  const [studentBasicFormData, setStudentBasicFormData] =
    useState<studentPieChartDeatils>({
      std_demand_received: 0,
      std_demand_bal: 0,
      std_demand_amt: 0,
      std_demand_concession: 0,
      std_demand_refunds: 0,
      std_deposit_amt: 0,
    });

  useEffect(() => {
    if (token && state.InstId && state.studentId && fin_yr?.value) {
      GetStdDemand({
        variables: {
          token,
          fin_yr_id: fin_yr?.value!,
          inst_id: state.InstId as string,
          student_id: state.studentId,
        },
      }).then(({ data }) => {
        if (data) {
          setStudentBasicFormData({
            std_demand_received:
              data.GetStdCompleteFeeDetailsByStudentID.std_demand_by_fin_yr
                .std_demand_received,
            std_demand_bal:
              data.GetStdCompleteFeeDetailsByStudentID.std_demand_by_fin_yr
                .std_demand_bal,
            std_demand_amt:
              data.GetStdCompleteFeeDetailsByStudentID.std_demand_by_fin_yr
                .std_demand_amt +
              data.GetStdCompleteFeeDetailsByStudentID.std_demand_by_fin_yr
                .std_demand_ob,
            std_demand_concession:
              data.GetStdCompleteFeeDetailsByStudentID.std_demand_by_fin_yr
                .std_demand_concession,
            std_demand_refunds:
              data.GetStdCompleteFeeDetailsByStudentID.std_demand_by_fin_yr
                .std_demand_refunds,
            std_deposit_amt:
              data.GetStdCompleteFeeDetailsByStudentID.std_demand_by_fin_yr
                .std_deposit_amt,
          });
        }
      });
    }
  }, [
    token,
    state.InstId,
    state.studentId,
    fin_yr?.value,
    loading,
    GetStdDemand,
  ]);

  const [feeDetails, setFeeDetails] = useState(false);
  useEffect(() => {
    if (state.ActiveFinYr) {
      const selectedFinYr = financialYearResponse.responseType.find(
        ({ value }) => value === state.ActiveFinYr?.id
      );
      setFinYr(selectedFinYr ? selectedFinYr : null);
    }
  }, [state.ActiveFinYr, financialYearResponse.responseType]);

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    onRequestClose,
    contentComponent,
  }) => {
    return (
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">{contentComponent}</div>
          <div className="modal-flex__image">
            <img src={Close} alt="Close" onClick={onRequestClose} />
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <>
      <div className="student-dashboard__frame--grids--title">
        <Title variant="subtitle1">Fee Balance</Title>
        {enablePaymentGateway ? (
          defaultBank && encryptionKey ? (
            <button
              onClick={() => {
                if (
                  configData.data &&
                  consolidatedFeeEnabled !== undefined &&
                  PartialFee !== undefined
                ) {
                  if (consolidatedFeeEnabled) {
                    setConsolidatePayOnline(!consolidatePayOnline);
                  } else if (PartialFee) {
                    setPayOnline(!payOnline);
                  } else {
                    setPayFeeByDemand(!payFeeByDemand);
                  }
                }
              }}
            >
              Pay Now &nbsp;
              <img src={PayOnline} alt="" />
            </button>
          ) : encryptionKey ? (
            <b className="nodata">Aes Key not set</b>
          ) : (
            <b className="nodata">Default Bank not set</b>
          )
        ) : null}
      </div>
      <div
        className="student-dashboard__frame--grids--details"
        onClick={() => setFeeDetails(!feeDetails)}
      >
        <img src={FeeBalance} alt="/" />
        <div className="student-dashboard__frame--grids--g-count--block">
          <span
            className={
              studentBasicFormData.std_demand_bal > 99999
                ? "font-14 student-dashboard__frame--grids--details--total"
                : "student-dashboard__frame--grids--details--total"
            }
          >
            {format(studentBasicFormData.std_demand_bal ?? 0)}
          </span>
        </div>
      </div>
      <div className="student-dashboard__frame--grids--g-count">
        <div className="student-dashboard__frame--grids--g-count--flex separator">
          <img src={TotalFee} alt="/" />
          <div className="student-dashboard__frame--grids--g-count--block">
            <span className="student-dashboard__frame--grids--g-count--block--text">
              Total
            </span>
            <span
              className={
                studentBasicFormData.std_demand_amt > 9999
                  ? "student-dashboard__frame--grids--g-count--block--total font-10"
                  : "student-dashboard__frame--grids--g-count--block--total"
              }
            >
              {format(studentBasicFormData.std_demand_amt ?? 0)}
            </span>
          </div>
        </div>
        <div className="student-dashboard__frame--grids--g-count--flex">
          <img src={PaidFee} alt="/" />
          <div className="student-dashboard__frame--grids--g-count--block">
            <span className="student-dashboard__frame--grids--g-count--block--text">
              Paid
            </span>
            <span
              className={
                studentBasicFormData.std_demand_received > 9999
                  ? "student-dashboard__frame--grids--g-count--block--total font-10"
                  : "student-dashboard__frame--grids--g-count--block--total"
              }
            >
              {format(studentBasicFormData.std_demand_received ?? 0)}
            </span>
          </div>
        </div>
      </div>

      {/* Complete Fee Modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={feeDetails}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CompleteFeeDetails
              pageType={PageFor.MODAL}
              transactionStatus={TransactionStatusType.SUCCESS}
              setCompleteFeeModal={setCompleteReceiptModal}
              setModal={setFeeDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeDetails(!feeDetails)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={payOnline}
        ariaHideApp={false}
        style={StudentModalStyles}
        onRequestClose={() => setPayOnline(!payOnline)}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeReceipt
              setModalFlag={setPayOnline}
              pageType={PageFor.MODAL}
              payer={PayerType.STUDENT}
              studentSelected={true}
            />
          </div>
          <div className="modal-flex__image ">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setPayOnline(!payOnline)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={consolidatePayOnline}
        ariaHideApp={false}
        style={ConsolidatedPayModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConsolidatedAmountPay
              balance={studentBasicFormData.std_demand_bal}
              setModalFlag={setConsolidatePayOnline}
              setMessage={setMessage}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setConsolidatePayOnline(!consolidatePayOnline)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <CustomModal
        isOpen={payFeeByDemand}
        onRequestClose={() => setPayFeeByDemand(!payFeeByDemand)}
        contentComponent={<PayByDemand setModalFlag={setPayFeeByDemand} />}
      />

      <Modal
        shouldCloseOnOverlayClick={true}
        style={StudentModalStyles}
        ariaHideApp={false}
        isOpen={completeReceiptModal}
        onRequestClose={() => setCompleteReceiptModal(!completeReceiptModal)}
      >
        <StudentCompleteFeeReceipt
          PageType={PageFor.MODAL}
          setModalFlag={setCompleteReceiptModal}
        />
      </Modal>
    </>
  );
};

export default FeeDetails;

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { GetSwConfigPageInfoByPageNumber } from "../queries/institution/configuration/query/SoftwreConfig";
import { PageSwData, SoftwareVars } from "../Types/configtypes";
import useToken from "./useToken";

const useDisplayConfigIcon = (pagenumber: number) => {
  const { token } = useToken();
  const [GetPageConfigData, { data }] = useLazyQuery<PageSwData, SoftwareVars>(
    GetSwConfigPageInfoByPageNumber,
    {
      variables: { token, page_number: pagenumber },
    }
  );
  useEffect(() => {
    if (pagenumber && token !== "") {
      GetPageConfigData();
    }
  }, [GetPageConfigData, pagenumber, token]);
  return {
    USE_CONFIG_KEY: data?.GetSwConfigPageInfoByPageNumber.page_config_avialable,
  };
};

export default useDisplayConfigIcon;

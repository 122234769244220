import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { SendFcmMsg } from "../../Templates/queries/mutation";
import { LicenseTypes, Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import LoadingModal from "../../../pages/LoadingModal";
import { TextField } from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import { EMPTY_STRING } from "../../../utils/constants";
import MessageModal from "../../../pages/MessageModal";
import Home from "../../Eduate/Home/Index";
import { Label } from "../../../stories/Label/Label";

const FcmMessage = () => {
  const { token } = useToken();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [fcmMessage, setFcmMessage] = useState("");
  const [SendFcmMessage, { loading: deleteTemplateLoading }] = useMutation(
    SendFcmMsg,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleClose = () => {
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };
  const HandleSendMessage = () => {
    SendFcmMessage({
      variables: {
        token,
        msg: fcmMessage,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Message Sent Sucessfully",
          operation: Operation.DELETE,
        });
      }
    });
  };

  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Label>Enter Message</Label>
      <TextField
        value={fcmMessage}
        onChange={(e) => setFcmMessage(e.target.value)}
      />
      <Button mode="save" onClick={HandleSendMessage} />
      <LoadingModal flag={deleteTemplateLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default FcmMessage;

import { gql } from "@apollo/client";

export const GetInstsByCustId = gql`
  query GetInstsByCustId(
    $token: String!
    $first: Int!
    $customer_id: ID!
    $after: Cursor
    $name: String
    $orderBy: MstInstOrder
  ) {
    GetInstsByCustId(
      token: $token
      first: $first
      after: $after
      customer_id: $customer_id
      orderBy: $orderBy
      where: { or: [{ instNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          inst_code
          inst_name
          inst_place
          inst_contact_person
          inst_mobile
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const ListInstIdByInstNames = gql`
  query ListInstIdbyNames($names: [String!], $token: String!) {
    GetInstIdsByNames(token: $token, inst_names: $names) {
      id
      Inst_name
    }
  }
`;

export const ListCategoryIdsByNames = gql`
  query GetCategoryIdsByNames(
    $names: [String!]
    $token: String!
    $inst_id: ID!
  ) {
    GetCategoryIdsByNames(token: $token, cat_name: $names, inst_id: $inst_id) {
      id
      cat_desc
    }
  }
`;

export const ListCategoryIdByName = gql`
  query GetCategoryIdByName($names: String!, $token: String!, $inst_id: ID!) {
    GetCategoryIdByName(token: $token, category_name: $names, inst_id: $inst_id)
  }
`;
export const GetMstInstReferenceDataByType = gql`
  query GetMstInstReferenceDataByType(
    $token: String!
    $inst_id: ID!
    $type: String!
  ) {
    GetMstInstReferenceDataByType(
      token: $token
      inst_id: $inst_id
      type: $type
    ) {
      value1
      value2
      id
      type
    }
  }
`;
export const GetMstInstReferenceDataByTypeId = gql`
  query GetMstInstReferenceDataByTypeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on MstInstReferenceData {
        type
        index
        value1
        value2
      }
    }
  }
`;
export const IsModuleEnabled = gql`
  query IsModuleEnabled($token: String!, $inst_id: ID!, $module_name: String!) {
    IsModuleEnabled(token: $token, inst_id: $inst_id, module_name: $module_name)
  }
`;

export const InstDetailsByNodeId = gql`
  query InstDetailsByNodeId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on MstInst {
        id
        inst_code
        inst_name
        inst_short_name
        inst_address
        inst_name_to_print
        inst_place
        inst_state
        inst_pin
        inst_contact_person
        inst_phone
        inst_email
        inst_mobile
        inst_url
        inst_is_active
        inst_status
        inst_logo_filename
        inst_latitude
        inst_longitude
        inst_time_zone
        customer_id

        customer_details {
          id
          cust_name
        }
        module_master_details {
          id
          lic_type
          lic_active
          lic_exp_grace_period
          lic_enabled_date
          lic_start_date
          lic_end_date
          inst_module_details {
            id
            is_module_enabled
            eduate_module_details {
              id
              Name
            }
          }
        }
        fin_yr_details {
          fin_is_curr_yr
          id
          fin_yr
          fin_st_date
          fin_end_date
        }
        acd_yr_details {
          id
          acd_yr
          acd_st_date
          acd_end_date
          acd_is_curr_yr
        }
      }
    }
  }
`;
export const GetInstByID = gql`
  query GetInstByID($id: ID!) {
    GetInstByID(id: $id) {
      id
      inst_code
      inst_name
      inst_short_name
      inst_address
      inst_place
      inst_state
      inst_pin
      inst_contact_person
      inst_phone
      inst_email
      inst_mobile
      inst_url
      inst_is_active
      inst_status
      inst_logo_filename
      inst_latitude
      inst_longitude
      inst_time_zone
      customer_id
      customer_details {
        id
        cust_name
      }
      module_master_details {
        id
        lic_type
        lic_active
        lic_exp_grace_period
        lic_enabled_date
        lic_start_date
        lic_end_date
        inst_module_details {
          id
          is_module_enabled
          eduate_module_details {
            id
            Name
          }
        }
      }
      fin_yr_details {
        fin_is_curr_yr
        id
        fin_yr
        fin_st_date
        fin_end_date
      }
      acd_yr_details {
        id
        acd_yr
        acd_st_date
        acd_end_date
        acd_is_curr_yr
      }
    }
  }
`;
export const GetInstsForCampus = gql`
  query GetInstsForCampus(
    $token: String!
    $first: Int!
    $customer_id: ID!
    $after: Cursor
    $name: String
    $orderBy: MstInstOrder
  ) {
    GetInstsForCampus(
      token: $token
      first: $first
      after: $after
      customer_id: $customer_id
      orderBy: $orderBy
      where: { or: [{ instNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          inst_code
          inst_name
          inst_place
          inst_contact_person
          inst_mobile
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { ChallanPrintTypes } from "../../../Enum.types";

const useChallanConfiguration = () => {
  const [copies, setCopies] = useState(0);
  const {
    configData: { data: CustomerName },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_CUSTOMER_NAME);
  const {
    configData: { data: InstName },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_INST_NAME);

  const {
    configData: { data: NoOfCopies, loading },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_NUMBER_COPIES);
  const {
    configData: { data: Logo },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_INST_LOGO);
  const {
    configData: { data: FatherName },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_FATHER_NAME);
  const {
    configData: { data: AdmissionNumber },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_ADMISSION_NUMBER);

  const {
    configData: { data: ClassDetails },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_CLASS_NAME);

  const {
    configData: { data: SectionName },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_SECTION_NAME);
  const {
    configData: { data: InstAdress },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_ADMISSION_NUMBER);
  const {
    configData: { data: BankAccountNumber },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_BANK_ACCOUNT_NUMBER);
  const {
    configData: { data: BankName },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_BANK_NAME);
  const {
    configData: { data: BranchName },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_BANK_BRANCH_NAME);
  const {
    configData: { data: ConsolidateTitle },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_CONSOLIDATE_TITLE);
  const {
    configData: { data: Header },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_RECEIPT_HEADER);
  const {
    configData: { data: Note1 },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_NOTE_1);
  const {
    configData: { data: Note2 },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_NOTE_2);
  const {
    configData: { data: Note3 },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_NOTE_2);
  const {
    configData: { data: PrintConsolidateTitle },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_CONSOLIDATE_TITLE);
  const {
    configData: { data: PrintHeader },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_RECEIPT_HEADER);
  const {
    configData: { data: PrintLabel },
  } = useSwConfigData(ChallanPrintTypes.CHALLAN_PRINT_INST_STD_BANK_LABEL);

  useEffect(() => {
    if (NoOfCopies && !loading) {
      const { config_string_value } = NoOfCopies.GetSwConfigVariables[0];
      setCopies(Number(config_string_value));
    }
  }, [NoOfCopies, loading]);

  return {
    USE_CUSTOMER_NAME:
      CustomerName?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_INST_NAME: InstName?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_LOGO: Logo?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_FATHER_NAME: FatherName?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_ADM_NO: AdmissionNumber?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_CLASS_NAME: ClassDetails?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_SECTION_NAME:
      SectionName?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_INST_ADDRESS: InstAdress?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_BANK_ACCOUNT_NUMBER:
      BankAccountNumber?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_BANK_NAME: BankName?.GetSwConfigVariables[0].config_boolean_value,
    USE_BANK_BRANCH_NAME:
      BranchName?.GetSwConfigVariables[0].config_boolean_value,
    USE_CONSOLIDATED_TITLE:
      PrintConsolidateTitle?.GetSwConfigVariables[0].config_string_value,
    USE_PRINT_CONSOLIDATED_TITLE:
      ConsolidateTitle?.GetSwConfigVariables[0].config_boolean_value,
    USE_PRINT_HEADER: PrintHeader?.GetSwConfigVariables[0].config_string_value,
    USE_HEADER: Header?.GetSwConfigVariables[0].config_boolean_value,
    USE_LABEL: PrintLabel?.GetSwConfigVariables[0].config_boolean_value,
    USE_NO_COPIES: copies,
    USE_NOTE1: Note1?.GetSwConfigVariables[0]?.config_string_value,
    USE_NOTE2: Note2?.GetSwConfigVariables[0]?.config_string_value,
    USE_NOTE3: Note3?.GetSwConfigVariables[0]?.config_string_value,
  };
};

export default useChallanConfiguration;

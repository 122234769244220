import { gql } from "@apollo/client";

export const GetInstMasterData = gql`
  query GetInstMasterData($token: String!, $inst_id: ID!) {
    GetInstMasterData(token: $token, inst_id: $inst_id) {
      dept_details {
        id
        dept_desc
      }
      branch_details {
        id
        branch_desc
      }
      class_details {
        id
        class_desc
        class_short_desc
      }
      semester_details {
        id
        sem_desc
      }
      section_details {
        id
        section_desc
      }
      category_details {
        id
        cat_desc
      }
    }
  }
`;

import React, { useContext, useEffect } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import { Label } from "../../../../stories/Label/Label";
import { useNavigate } from "react-router-dom";
import {
  parentDashboardByParentIdData,
  parentStudentAssociationByParentIdVars,
} from "../../../../Types/Student";
import { useLazyQuery } from "@apollo/client";
import { ListParentStudentAssocByParentId } from "../../../../queries/parent/list/byId";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";

import Header from "../../../../components/common/Header/index";
import BreadCrumb from "../../../../components/common/BreadCrumb/index";

const { StudentLabelNames } = require("../../../../json/config.json");

const Index = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { dispatch, state } = useContext(AppContext);

  const [GetParentStudentData, { data: ParentStudentAssociationData }] =
    useLazyQuery<
      parentDashboardByParentIdData,
      parentStudentAssociationByParentIdVars
    >(ListParentStudentAssocByParentId, {
      variables: {
        token,
        parent_id: state.parentId ?? "",
      },
    });

  const studentData =
    ParentStudentAssociationData?.GetParentStudentAssociByParentId.map(
      (response) => response.student
    );

  useEffect(() => {
    if (token) {
      GetParentStudentData();
    }
  }, [token, GetParentStudentData]);
  return (
    <>
      <div className="parent-dashboard">
        <div className="row g-0">
          <Header />
        </div>
        <div className="row g-0">
          <BreadCrumb />
        </div>
        <div className="parent-dashboard__nav"></div>
        <div className="parent-dashboard__frame">
          {studentData?.map((value, index) => {
            return (
              <div
                className="parent-dashboard__frame--student-list"
                key={index}
                onClick={() => {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: {
                      studentId: value.id,
                    },
                  });
                  navigate(`/${value.id}/parentdashboard/student`);
                }}
              >
                <div className="parent-dashboard__frame--student-list--more-options">
                  <Title variant="subtitle1">Student Name</Title>
                  <img src={More} alt="/" />
                </div>
                <div className="parent-dashboard__frame--student-list--more-options--student-profile"></div>

                {StudentLabelNames.map((label: any, index: React.Key) => {
                  return (
                    <ul key={index}>
                      <li>
                        <Label variant="LabelBold">{label.name}</Label>
                        <Label>
                          {value.first_name +
                            " " +
                            value.middle_name +
                            " " +
                            value.last_name}
                        </Label>
                      </li>
                      <li>
                        <Label variant="LabelBold">{label.status}</Label>
                        <Label
                          className={
                            value.std_status === "CUR" ? "font-green" : ""
                          }
                        >
                          {value.std_status}
                        </Label>
                      </li>
                      <li>
                        <Label variant="LabelBold">{label.admno}</Label>
                        <Label>{value.std_adm_no}</Label>
                      </li>
                      {value.dept.dept_desc !== "---" ? (
                        <li>
                          <Label variant="LabelBold">{label.department}</Label>
                          <Label>{value.dept.dept_desc}</Label>
                        </li>
                      ) : null}
                      {value.branch.branch_desc && (
                        <li>
                          <Label variant="LabelBold">{label.branch}</Label>
                          <Label>{value.branch.branch_desc}</Label>
                        </li>
                      )}
                      <li>
                        <Label variant="LabelBold">{label.class}</Label>
                        <Label>{value.class.class_desc}</Label>
                      </li>
                      {value.semester.sem_desc !== "---" ? (
                        <li>
                          <Label variant="LabelBold">{label.semester}</Label>
                          <Label>{value.semester.sem_desc}</Label>
                        </li>
                      ) : null}
                      {value.section.section_desc !== "---" ? (
                        <li>
                          <Label variant="LabelBold">{label.section}</Label>
                          <Label>{value.section.section_desc}</Label>
                        </li>
                      ) : null}
                    </ul>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Index;

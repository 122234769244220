export enum studentQueryTypes {
  //general student details
  BY_INST_ID = "BY_INST_ID",
  BY_DEPT_ID = "BY_DEPT_ID",
  BY_BRANCH_ID = "BY_BRANCH_ID",
  BY_CLASS_ID = "BY_CLASS_ID",
  BY_SEMESTER_ID = "BY_SEMESTER_ID",
  BY_SECTION_ID = "BY_SECTION_ID",
  BY_CATEGORY_ID = "BY_CATEGORY_ID",
  BY_DEPT_AND_CATEGORY_ID = "BY_DEPT_AND_CATEGORY_ID",
  BY_BRANCH_AND_CATEGORY_ID = "BY_BRANCH_AND_CATEGORY_ID",
  BY_CLASS_AND_CATEGORY_ID = "BY_CLASS_AND_CATEGORY_ID",
  BY_SEMESTER_AND_CATEGORY_ID = "BY_SEMESTER_AND_CATEGORY_ID",
  BY_SECTION_AND_CATEGORY_ID = "BY_SECTION_AND_CATEGORY_ID",
  //students by not allocated section

  SECTION_NOT_ALLOC_BY_INST_ID = "SECTION_NOT_ALLOC_BY_INST_ID",
  SECTION_NOT_ALLOC_BY_DEPT_ID = "SECTION_NOT_ALLOC_BY_DEPT_ID",
  SECTION_NOT_ALLOC_BY_BRANCH_ID = "SECTION_NOT_ALLOC_BY_BRANCH_ID",
  SECTION_NOT_ALLOC_BY_CLASS_ID = "SECTION_NOT_ALLOC_BY_CLASS_ID",
  SECTION_NOT_ALLOC_BY_SEMESTER_ID = "SECTION_NOT_ALLOC_BY_SEMESTER_ID",
  SECTION_NOT_ALLOC_BY_CATEGORY_ID = "SECTION_NOT_ALLOC_BY_CATEGORY_ID",

  //eligible students
  STATUS_EQ_CUR_BY_INST_ID = "STATUS_EQ_CUR_BY_INST_ID",
  STATUS_EQ_CUR_BY_DEPT_ID = "STATUS_EQ_CUR_BY_DEPT_ID",
  STATUS_EQ_CUR_BY_BRANCH_ID = "STATUS_EQ_CUR_BY_BRANCH_ID",
  STATUS_EQ_CUR_BY_CLASS_ID = "STATUS_EQ_CUR_BY_CLASS_ID",
  STATUS_EQ_CUR_BY_SEMESTER_ID = "STATUS_EQ_CUR_BY_SEMESTER_ID",
  STATUS_EQ_CUR_BY_SECTION_ID = "STATUS_EQ_CUR_BY_SECTION_ID",

  //not eligible
  STATUS_NEQ_CUR_BY_INST_ID = "STATUS_NEQ_CUR_BY_INST_ID",
  STATUS_NEQ_CUR_BY_DEPT_ID = "STATUS_NEQ_CUR_BY_DEPT_ID",
  STATUS_NEQ_CUR_BY_BRANCH_ID = "STATUS_NEQ_CUR_BY_BRANCH_ID",
  STATUS_NEQ_CUR_BY_CLASS_ID = "STATUS_NEQ_CUR_BY_CLASS_ID",
  STATUS_NEQ_CUR_BY_SEMESTER_ID = "STATUS_NEQ_CUR_BY_SEMESTER_ID",
  STATUS_NEQ_CUR_BY_SECTION_ID = "STATUS_NEQ_CUR_BY_SECTION_ID",
  STATUS_NEQ_CUR_BY_CATEGORY_ID = "STATUS_NEQ_CUR_BY_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_DEPT_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_DEPT_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_BRANCH_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_BRANCH_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_CLASS_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_CLASS_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_SEMESTER_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_SEMESTER_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_SECTION_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_SECTION_AND_CATEGORY_ID",
  //Students not Registered
  NOT_REGISTERED_BY_INST_ID = "NOT_REGISTERED_BY_INST_ID",
  NOT_REGISTERED_BY_DEPT_ID = "NOT_REGISTERED_BY_DEPT_ID",
  NOT_REGISTERED_BY_BRANCH_ID = "NOT_REGISTERED_BY_BRANCH_ID",
  NOT_REGISTERED_BY_CLASS_ID = "NOT_REGISTERED_BY_CLASS_ID",
  NOT_REGISTERED_BY_SEMESTER_ID = "NOT_REGISTERED_BY_SEMESTER_ID",
  NOT_REGISTERED_BY_SECTION_ID = "NOT_REGISTERED_BY_SECTION_ID",

  //Library Book Clearance List
  LIB_BOOK_CLEARANCE_BY_INST_ID = "LIB_BOOK_CLEARANCE_BY_INST_ID",
  LIB_BOOK_CLEARANCE_BY_DEPT_ID = "LIB_BOOK_CLEARANCE_BY_DEPT_ID",
  LIB_BOOK_CLEARANCE_BY_BRANCH_ID = "LIB_BOOK_CLEARANCE_BY_BRANCH_ID",
  LIB_BOOK_CLEARANCE_BY_CLASS_ID = "LIB_BOOK_CLEARANCE_BY_CLASS_ID",
  LIB_BOOK_CLEARANCE_BY_SEMESTER_ID = "LIB_BOOK_CLEARANCE_BY_SEMESTER_ID",
  LIB_BOOK_CLEARANCE_BY_SECTION_ID = "LIB_BOOK_CLEARANCE_BY_SECTION_ID",
  //students by channelId
  BY_MSG_CHANNEL_ID = "BY_MSG_CHANNEL_ID",
  //student Reservation
  STATUS_EQ_NXT_BY_INST_ID = "STATUS_EQ_NXT_BY_INST_ID",
  STATUS_EQ_NXT_BY_DEPT_ID = "STATUS_EQ_NXT_BY_DEPT_ID",
  STATUS_EQ_NXT_BY_BRANCH_ID = "STATUS_EQ_NXT_BY_BRANCH_ID",
  STATUS_EQ_NXT_BY_CLASS_ID = "STATUS_EQ_NXT_BY_CLASS_ID",
  STATUS_EQ_NXT_BY_SEMESTER_ID = "STATUS_EQ_NXT_BY_SEMESTER_ID",
  STATUS_EQ_NXT_BY_SECTION_ID = "STATUS_EQ_NXT_BY_SECTION_ID",
  STATUS_EQ_NXT_BY_CATEGORY_ID = "STATUS_EQ_NXT_BY_CATEGORY_ID",
  STATUS_EQ_NXT_BY_DEPT_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_DEPT_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_BRANCH_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_BRANCH_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_CLASS_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_CLASS_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_SEMESTER_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_SEMESTER_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_SECTION_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_SECTION_AND_CATEGORY_ID",

  //new Admission students
  NEW_ADMISSIONS_BY_INST_ID = "NEW_ADMISSIONS_BY_INST_ID",
  NEW_ADMISSIONS_BY_DEPT_ID = "NEW_ADMISSIONS_BY_DEPT_ID",
  NEW_ADMISSIONS_BY_BRANCH_ID = "NEW_ADMISSIONS_BY_BRANCH_ID",
  NEW_ADMISSIONS_BY_CLASS_ID = "NEW_ADMISSIONS_BY_CLASS_ID",
  NEW_ADMISSIONS_BY_SEMESTER_ID = "NEW_ADMISSIONS_BY_SEMESTER_ID",
  NEW_ADMISSIONS_BY_SECTION_ID = "NEW_ADMISSIONS_BY_SECTION_ID",
  NEW_ADMISSIONS_BY_CATEGORY_ID = "NEW_ADMISSIONS_BY_CATEGORY_ID",
  NEW_ADMISSIONS_BY_DEPT_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_DEPT_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_BRANCH_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_BRANCH_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_CLASS_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_CLASS_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_SEMESTER_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_SEMESTER_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_SECTION_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_SECTION_AND_CATEGORY_ID",

  //By Religion
  BY_RELIGION_AND_INST_ID = "BY_RELIGION_AND_INST_ID",
  BY_RELIGION_AND_DEPT_ID = "BY_RELIGION_AND_DEPT_ID",
  BY_RELIGION_AND_BRANCH_ID = "BY_RELIGION_AND_BRANCH_ID",
  BY_RELIGION_AND_CLASS_ID = "BY_RELIGION_AND_CLASS_ID",
  BY_RELIGION_AND_SEMESTER_ID = "BY_RELIGION_AND_SEMESTER_ID",
  BY_RELIGION_AND_SECTION_ID = "BY_RELIGION_AND_SECTION_ID",
  BY_RELIGION_AND_CATEGORY_ID = "BY_RELIGION_AND_CATEGORY_ID",
  BY_RELIGION_AND_DEPT_AND_CATEGORY_ID = "BY_RELIGION_AND_DEPT_AND_CATEGORY_ID",
  BY_RELIGION_AND_BRANCH_AND_CATEGORY_ID = "BY_RELIGION_AND_BRANCH_AND_CATEGORY_ID",
  BY_RELIGION_AND_CLASS_AND_CATEGORY_ID = "BY_RELIGION_AND_CLASS_AND_CATEGORY_ID",
  BY_RELIGION_AND_SEMESTER_AND_CATEGORY_ID = "BY_RELIGION_AND_SEMESTER_AND_CATEGORY_ID",
  BY_RELIGION_AND_SECTION_AND_CATEGORY_ID = "BY_RELIGION_AND_SECTION_AND_CATEGORY_ID",

  ANONYMOUS_STUDENT = "ANONYMOUS_STUDENT",
  SOCIAL_WELFARE_STUDENT = "SOCIAL_WELFARE_STUDENT",
  AGENT_STUDENT = "AGENT_STUDENT",
  ALL_ANONYMOUS_STUDENTS = "ALL_ANONYMOUS_STUDENTS",

  //By Age
  BY_AGE_AND_INST_ID = "BY_AGE_AND_INST_ID",
  BY_AGE_AND_DEPT_ID = "BY_AGE_AND_DEPT_ID",
  BY_AGE_AND_BRANCH_ID = "BY_AGE_AND_BRANCH_ID",
  BY_AGE_AND_CLASS_ID = "BY_AGE_AND_CLASS_ID",
  BY_AGE_AND_SEMESTER_ID = "BY_AGE_AND_SEMESTER_ID",
  BY_AGE_AND_SECTION_ID = "BY_AGE_AND_SECTION_ID",
  BY_AGE_AND_CATEGORY_ID = "BY_AGE_AND_CATEGORY_ID",
  BY_AGE_AND_DEPT_AND_CATEGORY_ID = "BY_AGE_AND_DEPT_AND_CATEGORY_ID",
  BY_AGE_AND_BRANCH_AND_CATEGORY_ID = "BY_AGE_AND_BRANCH_AND_CATEGORY_ID",
  BY_AGE_AND_CLASS_AND_CATEGORY_ID = "BY_AGE_AND_CLASS_AND_CATEGORY_ID",
  BY_AGE_AND_SEMESTER_AND_CATEGORY_ID = "BY_AGE_AND_SEMESTER_AND_CATEGORY_ID",
  BY_AGE_AND_SECTION_AND_CATEGORY_ID = "BY_AGE_AND_SECTION_AND_CATEGORY_ID",
  //Transport Service
  AVAILED_TRANSPORT_BY_INST_ID = "AVAILED_TRANSPORT_BY_INST_ID",
  AVAILED_TRANSPORT_BY_DEPT_ID = "AVAILED_TRANSPORT_BY_DEPT_ID",
  AVAILED_TRANSPORT_BY_BRANCH_ID = "AVAILED_TRANSPORT_BY_BRANCH_ID",
  AVAILED_TRANSPORT_BY_CLASS_ID = "AVAILED_TRANSPORT_BY_CLASS_ID",
  AVAILED_TRANSPORT_BY_SEMESTER_ID = "AVAILED_TRANSPORT_BY_SEMESTER_ID",
  AVAILED_TRANSPORT_BY_SECTION_ID = "AVAILED_TRANSPORT_BY_SECTION_ID",

  NOT_AVAILED_TRANSPORT_BY_INST_ID = "NOT_AVAILED_TRANSPORT_BY_INST_ID",
  NOT_AVAILED_TRANSPORT_BY_DEPT_ID = "NOT_AVAILED_TRANSPORT_BY_DEPT_ID",
  NOT_AVAILED_TRANSPORT_BY_BRANCH_ID = "NOT_AVAILED_TRANSPORT_BY_BRANCH_ID",
  NOT_AVAILED_TRANSPORT_BY_CLASS_ID = "NOT_AVAILED_TRANSPORT_BY_CLASS_ID",
  NOT_AVAILED_TRANSPORT_BY_SEMESTER_ID = "NOT_AVAILED_TRANSPORT_BY_SEMESTER_ID",
  NOT_AVAILED_TRANSPORT_BY_SECTION_ID = "NOT_AVAILED_TRANSPORT_BY_SECTION_ID",

  ROUTE_NOT_ASSIGNED_BY_INST_ID = "ROUTE_NOT_ASSIGNED_BY_INST_ID",
  ROUTE_NOT_ASSIGNED_BY_DEPT_ID = "ROUTE_NOT_ASSIGNED_BY_DEPT_ID",
  ROUTE_NOT_ASSIGNED_BY_BRANCH_ID = "ROUTE_NOT_ASSIGNED_BY_BRANCH_ID",
  ROUTE_NOT_ASSIGNED_BY_CLASS_ID = "ROUTE_NOT_ASSIGNED_BY_CLASS_ID",
  ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID = "ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID",
  ROUTE_NOT_ASSIGNED_BY_SECTION_ID = "ROUTE_NOT_ASSIGNED_BY_SECTION_ID",

  MOBILE_APP_BY_INST_ID = "MOBILE_APP_BY_INST_ID",
  MOBILE_APP_BY_DEPT_ID = "MOBILE_APP_BY_DEPT_ID",
  MOBILE_APP_BY_BRANCH_ID = "MOBILE_APP_BY_BRANCH_ID",
  MOBILE_APP_BY_CLASS_ID = "MOBILE_APP_BY_CLASS_ID",
  MOBILE_APP_BY_SEMESTER_ID = "MOBILE_APP_BY_SEMESTER_ID",
  MOBILE_APP_BY_SECTION_ID = "MOBILE_APP_BY_SECTION_ID",
  STDS_BY_ADMISSION_NUMS = "STDS_BY_ADMISSION_NUMS",

  MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL = "MOBILE_APP_INSTALLED_AT_ENTRY_LEVEL",
  MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL = "MOBILE_APP_NOT_INSTALLED_AT_ENTRY_LEVEL",
}

import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
} from "../../../utils/Enum.types";

const useDisplayAutoCompleteTag = () => {
  let displayClass = false;
  let displaySemester = false;
  let displaySection = false;
  let setallotedIdClassLvl = false;
  let setallotedIdSemLvl = false;
  let setAllotedIdSecLvl = false;
  const { USE_CLASS_KEY, USE_SECTION_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  displayClass =
    (USE_CLASS_KEY &&
      configData.data?.GetSwConfigVariables[0].config_string_value ===
        AcdSubjectAllocationData.CLASS) ||
    configData.data?.GetSwConfigVariables[0].config_string_value ===
      AcdSubjectAllocationData.SEMESTER ||
    configData.data?.GetSwConfigVariables[0].config_string_value ===
      AcdSubjectAllocationData.SECTION;
  displaySemester =
    (USE_SEMESTER_KEY &&
      configData.data?.GetSwConfigVariables[0].config_string_value ===
        AcdSubjectAllocationData.SECTION) ||
    configData.data?.GetSwConfigVariables[0].config_string_value ===
      AcdSubjectAllocationData.SEMESTER;
  displaySection =
    USE_SECTION_KEY &&
    configData.data?.GetSwConfigVariables[0].config_string_value ===
      AcdSubjectAllocationData.SECTION;
  setallotedIdClassLvl =
    configData.data?.GetSwConfigVariables[0].config_string_value ===
    AcdSubjectAllocationData.CLASS;
  setallotedIdSemLvl =
    configData.data?.GetSwConfigVariables[0].config_string_value ===
    AcdSubjectAllocationData.SEMESTER;
  setAllotedIdSecLvl =
    configData.data?.GetSwConfigVariables[0].config_string_value ===
    AcdSubjectAllocationData.SECTION;
  return {
    displayClass,
    displaySection,
    displaySemester,
    setallotedIdClassLvl,
    setallotedIdSemLvl,
    setAllotedIdSecLvl,
  };
};

export default useDisplayAutoCompleteTag;

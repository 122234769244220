import { IObjectKeys } from "../../../../utils/Form.types";
import { EmpDetails } from "../../../HR/hooks/useEmployee";
import { SubjectDetailsNode } from "../../hooks/useAcademicSubjects";

export enum AcdTeacherClassQueryType {
  All_TEACHERS_BY_ENTRY_ID = "All_TEACHERS_BY_ENTRY_ID",
  ALL_ENTRIES_BY_TEACHER_ID = "ALL_ENTRIES_BY_TEACHER_ID",
  ALL_TEACHERS_BY_SUBJECT = "ALL_TEACHERS_BY_SUBJECT",
  ALL_SUBJECTS_BY_TEACHER_ID_AT_ENTRY_LEVEL = "ALL_SUBJECTS_BY_TEACHER_ID_AT_ENTRY_LEVEL",
}
export interface SubjectFormData extends IObjectKeys {
  // To be visited later id should not be optional
  id?: number;
  subj_code: string;
  subj_desc: string;
  subj_idx: number;
  teacher_count: number;
  subj_board_code: string;
  subj_is_lang: boolean;
  subj_is_elective: boolean;
  subj_is_non_academic: boolean;
  subj_is_core_subject: boolean;
  subj_internals_avialed: boolean;
  subj_apptitude_avialed: boolean;
  subj_is_lab: boolean;
  subj_marks_in_grades: boolean;
}

export interface SubjectType extends IObjectKeys {
  // To be visited later id should not be optional
  id: number;
  subj_code: string;
  subj_desc: string;
}
export interface GetAcdSubjectForEntryLevelsData {
  GetAcdSubjectForEntryLevel: GetAcdSubjectForEntryLevelDetails[];
}
export interface GetAcdSubjectForEntryLevelDetails {
  subject_details: SubjectFormData;
  id: number;
  subj_sl: number;
  core_sl_no: number;
  subj_is_lang: boolean;
  is_lessons_planned: boolean;
  subj_is_elective: boolean;
  subj_is_grade: boolean;
  subj_internals_avialed: boolean;
  subj_alloted_main_id: number;
  subj_master_id: number;
  isChecked: boolean;
}
export interface TeacherDetails {
  pr_emp_id: number;
  emp_id: string;
  emp_title: number;
  emp_first_name: number;
  emp_middle_name: number;
  emp_last_name: number;
  emp_profile_filename: number;
  is_class_teacher: boolean;
}
export interface FeedBackForm {
  id: number;
  form_name: string;
  form_st_date: string;
  form_end_date: string;
  is_academic: boolean;
  inst_id: number;
  acd_yr_id: number;
}
export interface GetAcdSubjectTeachersDetails {
  subj_master_id: number;
  subj_code: string;
  subj_desc: string;
  subj_board_code: string;
  subj_is_elective: boolean;
  subj_is_core_subject: boolean;
  subj_is_lang: boolean;
  subj_is_lab: boolean;
  subj_is_non_academic: boolean;
  is_lessons_planned: boolean;
  elective_subj_master_id: number;
  elective_subj_code: string;
  elective_subj_desc: string;
  Teachers: TeacherDetails[];
}
export interface GetAcdFeedbackFromList {
  id: number;
  rating_factor: string;
  idx: number;
  inst_id: number;
  acd_yr_id: number;
  form_name_id: number;
  from_name_details: FeedBackForm;
}
export interface GetAcdFeedbackStdRatingList {
  id: number;
  rating: number;
  idx: number;
  remarks: string;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  fb_form_detail_id: number;
  pr_emp_id: number;
  student_id: number;
  subj_master_id: number;
  form_name_id: number;
  inst_id: number;
  acd_yr_id: number;
  fb_form_details: {
    rating_factor: string;
  };
}
export interface GetAcdFeedbackFormsList {
  id: number;
  form_name: string;
  form_st_date: string;
  form_end_date: string;
  is_academic: boolean;
  inst_id: number;
  acd_yr_id: number;
  que_count: number;
  is_report_generated: boolean;
}
export interface GetAcdSubjectForEntryLevelVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  entry_id: number;
  entry_level: string;
  per_std_subj_allocation: boolean | undefined;
}
export interface GetAcdFeedbackFromDetailsVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  form_name: string;
}
export interface GetAcdFeedbackStdRatingVars {
  token: string;
  inst_id: number | string;
  acd_yr_id: number;
  allotted_level: string;
  allotted_id: number;
  query_type: {
    pr_emp_id: number;
    student_id: number;
    subj_master_id: number;
    idx: number | null;
    form_name_id: number;
  };
  perStdSubjAllocation: boolean;
}
export interface SubjectTeacherQueryData {
  query_type: AcdTeacherClassQueryType;
  entry_level: string;
  entry_id: number;
  pr_emp_id: number;
  subj_master_id: number;
}
export interface GetAcdSubjectTeacherAssociationsList {
  id: number;
  is_class_teacher: boolean;
  pr_emp_id: number;
  inst_id: number;
  acd_yr_id: number;
  subj_master_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  pr_emp_details: EmpDetails;
  per_std_subj_allocation: boolean;
  subject_details: SubjectDetailsNode;
}

export interface GetAcdTeacherClassAssociationsData {
  GetAcdTeacherClassAssociations: GetAcdSubjectTeacherAssociationsList[];
}
export interface GetAcdTeacherClassAssociationsVars {
  token: string;
  inst_id: string | number;
  acd_yr_id: number;
  per_std_subj_allocation: boolean | undefined;
  input: SubjectTeacherQueryData;
}
export interface GetAcdTeacherSubjectClassAssociationsData {
  GetAcdTeacherSubjectClassAssociations: GetAcdSubjectTeachersDetails[];
}
export interface GetAcdFeedbackFromDetailsData {
  GetAcdFeedbackFormDetails: GetAcdFeedbackFromList[];
}
export interface GetAcdFeedbackStdRatingData {
  GetAcdFeedbackStdRating: GetAcdFeedbackStdRatingList[];
}
export interface GetAcdFeedbackFormOverAllRatingList {
  id: number;
  num_questions: number;
  rating_1: number;
  rating_2: number;
  rating_3: number;
  rating_4: number;
  rating_5: number;
  rating_6: number;
  rating_7: number;
  rating_8: number;
  rating_9: number;
  rating_10: number;
  rating_11: number;
  rating_12: number;
  rating_13: number;
  rating_14: number;
  rating_15: number;
  inst_id: number;
  acd_yr_id: number;
  pr_emp_id: number;
  form_name_id: number;
}
export interface GetAcdFeedbackFormEmpClassWiseRatingList {
  id: number;
  num_questions: number;
  rating_1: number;
  rating_2: number;
  rating_3: number;
  rating_4: number;
  rating_5: number;
  rating_6: number;
  rating_7: number;
  rating_8: number;
  rating_9: number;
  rating_10: number;
  rating_11: number;
  rating_12: number;
  rating_13: number;
  rating_14: number;
  rating_15: number;
  inst_id: number;
  acd_yr_id: number;
  pr_emp_id: number;
  form_name_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  subj_master_id: number;
  subject_details: {
    subj_code: string;
    subj_desc: string;
  };
}
export interface GetAcdFeedbackDetailsReportList {
  id: number;
  num_questions: number;
  excellent_count: number;
  good_count: number;
  average_count: number;
  fair_count: number;
  poor_count: number;
  inst_id: number;
  acd_yr_id: number;
  pr_emp_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  subj_master_id: number;
  form_name_id: number;
  fb_form_detail_id: number;
  fb_details: {
    id: number;
    rating_factor: string;
    from_name_details: {
      form_name: string;
      form_st_date: string;
      form_end_date: string;
    };
  };
}
export interface GetAcdFeedbackFormOverAllRatingData {
  GetAcdFeedbackFormOverAllRating: GetAcdFeedbackFormOverAllRatingList;
}
export interface GetAcdFeedbackFormEmpClassWiseRatingData {
  GetAcdFeedbackFormEmpClassWiseRating: GetAcdFeedbackFormEmpClassWiseRatingList[];
}
export interface GetAcdFeedbackDetailsReportData {
  GetAcdFeedbackDetailsReport: GetAcdFeedbackDetailsReportList[];
}
export interface GetAcdFeedbackFormsData {
  GetAcdFeedbackForms: GetAcdFeedbackFormsList[];
}
export interface GetAcdTeachersForClassData {
  GetAcdTeachersForClass: GetAcdSubjectTeacherAssociationsList[];
}
export interface GetAcdTeachersForClassVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  entry_id: number;
  entry_level: string;
}
export interface GetAcdFeedbackFormOverAllRatingVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  pr_emp_id: number;
  form_name_id: number;
}
export interface GetAcdFeedbackFormEmpClassWiseRatingVars {
  token: string;
  inst_id: number;
  acd_yr_id: number;
  pr_emp_id: number;
  form_name_id: number;
  entry_level: string;
  entry_id: number;
}
export interface GetAcdFeedbackDetailsReportVars {
  token: string;
  inst_id: number;
  acd_yr_id: number;
  pr_emp_id: number;
  form_name_id: number;
  entry_level: string;
  entry_id: number;
  subj_master_id: number;
}
export interface GetAcdFeedbackFormsVars {
  token: string;
  inst_id: number | string;
  acd_yr_id: number;
  pr_emp_id: number | string;
  allotted_level: string;
  allotted_id: number;
  today_date: string;
  query_type: string;
}
export interface GetAcdSubjectLessonStatusByStudentAndTeachersList {
  subj_master_id: number;
  subj_code: string;
  subj_desc: string;
  header_data: {
    subj_master_id: number;
    total_chapters: number;
    total_duration: number;
    completed_percent: number;
  };
}
export interface GetAcdSubjectLessonStatusByStudentData {
  GetAcdSubjectLessonStatusByStudent: GetAcdSubjectLessonStatusByStudentAndTeachersList[];
}

export interface GetAcdSubjectLessonStatusByStudentVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  student_id: number;
  entry_id: number;
  entry_level: string;
  per_std_subj_allocation: boolean;
}

export interface GetAcdSubjectLessonStatusByTeacherData {
  GetAcdSubjectLessonStatusByTeacher: GetAcdSubjectLessonStatusByStudentAndTeachersList[];
}

export interface GetAcdSubjectLessonStatusByTeacherVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  pr_emp_id: number;
  entry_id: string;
  entry_level: string;
  per_std_subj_allocation: boolean;
}

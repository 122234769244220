import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { GetAcdSubjectTeacherAssociations } from "../queries/subjects/List.tsx/Index";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "./useActiveAcademicYear";
import useToken from "../../../customhooks/useToken";
import { SubjectDetailsNode } from "./useAcademicSubjects";
import { EmpDetails } from "../../HR/hooks/useEmployee";
import { AppContext } from "../../../context/context";
export enum AcdSubjTeacherQueryType {
  All_TEACHERS_BY_SUBJECT_ID = "All_TEACHERS_BY_SUBJECT_ID",
  ALL_SUBJECTS_BY_TEACHER_ID = "ALL_SUBJECTS_BY_TEACHER_ID",
}

export interface GetAcdSubjectTeacherAssociationsList {
  id: number;
  pr_emp_id: number;
  inst_id: number;
  acd_yr_id: number;
  subj_master_id: number;
  pr_emp_details: EmpDetails;
  subject_details: SubjectDetailsNode;
}

interface SubjectTeacherQueryData {
  query_type: AcdSubjTeacherQueryType;
  pr_emp_id: number;
  subj_master_id: number;
}

interface GetAcdSubjectTeacherAssociationsData {
  GetAcdSubjectTeacherAssociations: GetAcdSubjectTeacherAssociationsList[];
}
interface GetAcdSubjectTeacherAssociationsVars {
  token: string;
  inst_id: string | number;
  acd_yr_id: number;
  input: SubjectTeacherQueryData;
}

const useSubjTeacherAssoci = (
  query_type: AcdSubjTeacherQueryType,
  pr_emp_id: number,
  subj_master_id: number
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { state } = useContext(AppContext);
  const [GetAcdSubjectTeacherAssoci, { data, loading, error }] = useLazyQuery<
    GetAcdSubjectTeacherAssociationsData,
    GetAcdSubjectTeacherAssociationsVars
  >(GetAcdSubjectTeacherAssociations, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      input: {
        query_type,
        pr_emp_id,
        subj_master_id,
      },
    },
  });

  useEffect(() => {
    if (
      token &&
      activeAcademicYearData.data &&
      (pr_emp_id || subj_master_id) &&
      InstId &&
      state.ActiveAcdYr
    )
      GetAcdSubjectTeacherAssoci();
  }, [
    query_type,
    pr_emp_id,
    subj_master_id,
    token,
    GetAcdSubjectTeacherAssoci,
    InstId,
    activeAcademicYearData.data,
    state.ActiveAcdYr,
  ]);

  return {
    data,
    loading,
    error,
  };
};

export default useSubjTeacherAssoci;

import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { TextField } from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import { responseType, transportResponse } from "../../../../utils/Form.types";
import { EMPTY_RESPONSETYPE_OBJECT } from "../../../../utils/constants";
import useTransportRoutesData from "../../../../customhooks/general/useTransportRoutesData";
import {
  RouteDetailsByData,
  RouteDetailsByVars,
} from "../../../Transport/paginationTypes";
import { useLazyQuery } from "@apollo/client";
import {
  GetTransportFeeDetails,
  GetTransportRouteDetailsByMasterId,
} from "../../../Transport/queries/list";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import { Dates } from "../../../Transport/MasterData/AssignRoute/ToStudent";
import { ServicesType } from "./Index";
import { AppContext } from "../../../../context/context";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteId: React.Dispatch<React.SetStateAction<responseType>>;
  routeId: responseType;
  setStageSelected: React.Dispatch<
    React.SetStateAction<transportResponse | null>
  >;
  stageSelected: transportResponse | null;
  setDate: React.Dispatch<React.SetStateAction<Dates>>;
  date: Dates;
  setFormData: React.Dispatch<React.SetStateAction<ServicesType>>;
}

const AssignRoute = ({
  setModal,
  setRouteId,
  routeId,
  setStageSelected,
  stageSelected,
  setDate,
  date,
  setFormData,
}: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [searchData, setSearchData] = useState("");
  const { routesData } = useTransportRoutesData(searchData, false);

  const [GetRouteDetailsById, { data }] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id: routeId.value!,
    },
  });

  const [GetRouteFeeData, { data: FeeData }] = useLazyQuery(
    GetTransportFeeDetails
  );
  useEffect(() => {
    if (stageSelected) {
      GetRouteFeeData({
        variables: {
          token,
          inst_id: state.InstId,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          acct_ldgr_id: stageSelected.acct_ldgr_id,
        },
      });
    }
  }, [
    stageSelected,
    GetRouteFeeData,
    state.ActiveFinYr ? state.ActiveFinYr.id : 0,
    token,
    state.InstId,
  ]);

  const stagesDropDown: transportResponse[] =
    data?.GetTransportRouteDetailsByMasterId.map((res) => ({
      label: res.route_stage_desc,
      value: res.id,
      ldgr_desc: res.acct_ldgr_details?.ldgr_desc,
      acct_ldgr_id: res.acct_ldgr_details.id,
    })) || [];

  const handleClear = () => {
    setRouteId(EMPTY_RESPONSETYPE_OBJECT);
    setStageSelected(null);
    setDate({
      start_date: "",
      end_date: "",
      due_date: "",
    });
  };
  useEffect(() => {
    if (routeId) {
      GetRouteDetailsById();
    }
  }, [GetRouteDetailsById, InstId, routeId, token]);

  return (
    <>
      <Title>Assign Route</Title>
      <div className="student-services__assign-route">
        <div className="student-services__assign-route--data">
          <div className="row g-0">
            <div className="col">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={routesData.routesDropDown}
                openOnFocus
                forcePopupIcon
                value={routeId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setRouteId(newValue as responseType);
                    setStageSelected(null);
                  } else {
                    setRouteId(EMPTY_RESPONSETYPE_OBJECT);
                    setStageSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bus Route"
                    onChange={(e) => setSearchData(e.target.value)}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    autoFocus
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={stagesDropDown}
                openOnFocus
                forcePopupIcon
                value={stageSelected}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as transportResponse,
                    stageSelected
                  )
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStageSelected(newValue as transportResponse);
                  } else {
                    setStageSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Student Stage At"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              {stageSelected && (
                <>
                  <TextField
                    className="student-services__assign-route--textfield"
                    label="Ledger"
                    disabled
                    value={stageSelected?.ldgr_desc}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                  <TextField
                    className="student-services__assign-route--textfield"
                    label="Route Fee"
                    disabled
                    value={FeeData?.GetTransportFeeDetails}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                </>
              )}
              <TextField
                className="student-services__assign-route--date"
                label="Fee Due Date"
                type="date"
                value={date.due_date}
                onChange={(e) =>
                  setDate({
                    ...date,
                    due_date: e.target.value,
                  })
                }
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                required
              />
              <br />
              <TextField
                className="student-services__assign-route--date"
                label="Start Date"
                type="date"
                value={date.start_date}
                onChange={(e) =>
                  setDate({
                    ...date,
                    start_date: e.target.value,
                  })
                }
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
              <br />
              <TextField
                className="student-services__assign-route--date"
                label="End Date"
                type="date"
                value={date.end_date}
                onChange={(e) =>
                  setDate({
                    ...date,
                    end_date: e.target.value,
                  })
                }
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            </div>
          </div>
        </div>
        <Button
          mode="save"
          onClick={() => setModal(false)}
          disabled={!stageSelected?.value || !routeId.value || !date.due_date}
        />
        <Button mode="clear" onClick={handleClear} />
        <Button
          mode="cancel"
          onClick={() => {
            handleClear();
            setModal(false);
            setFormData({
              availed_hostel: false,
              availed_transport: false,
            });
          }}
        />
      </div>
    </>
  );
};

export default AssignRoute;

import React, { useContext, useEffect, useState } from "react";
import InstDetails from "../../DailyActivities/InstDetails";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import List from "./List";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Input from "../../../../stories/Input/Input";
import TotalStudents from "../../../../images/TotalStudentsinAdmin.svg";
import TotalPresent from "../../../../images/Attendance_Marked.svg";
import TotalAbsent from "../../../../images/Attendance_Not_Marked.svg";
import OverallPercentage from "../../../../images/AttendanceOverallPercentage.svg";
import { GetAcdStudentAttendanceCount } from "../../queries/general";
import {
  GetAcdStudentAttendanceCountData,
  GetAcdStudentAttendanceCountVars,
} from "../../DailyActivities/Attendance/Index";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import CalendarForInput, {
  CustomisedInputFor,
} from "../../../../utils/CalendarForInput";
import useHolidaysList from "../../../../customhooks/useHolidaysList";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  GetAcdAttStdAttStatusVars,
  GetAcdAttStdTodaysStatusData,
  GetAcdStdsFromAttData,
  GetAcdStdsFromAttVars,
} from "../../types/attendance";
import {
  GetAcdAttStdTodaysStatus,
  GetAcdStdsForAttendance,
} from "../../queries/holidays/list";
import { StudentListEdges } from "../../hooks/useAcdStudentsData";
import { GetAcdStudentsAttendance } from "../../queries/holidays/mutation";
import { AttendenceQueryType } from "../../hooks/useAcdStudentAttendance";
import Avatar from "../../../../images/Avatar.svg";
import {
  AcdSubjectAllocationData,
  AttendanceStatus,
  attendanceReportType,
} from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

const { Academics_Table } = require("../../json/table.json");
interface props {
  pageType: attendanceReportType;
}

const ViewDailyAttReport = ({ pageType }: props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const [students, setStudents] = useState<StudentListEdges[]>([]);

  const navigate = useNavigate();
  const { entry_level, USE_SECTION_KEY, USE_SEMESTER_KEY, USE_CLASS_KEY } =
    useInstitutionConfiguration();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [date, setDate] = useState(new Date());
  const dates = useHolidaysList(
    date,
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!
  );
  const [GetStudentAcdList, { data: stdData, loading: stdLoading }] =
    useLazyQuery<GetAcdStdsFromAttData, GetAcdStdsFromAttVars>(
      GetAcdStdsForAttendance
    );

  const [GetAttCount, { data: countData }] = useLazyQuery<
    GetAcdStudentAttendanceCountData,
    GetAcdStudentAttendanceCountVars
  >(GetAcdStudentAttendanceCount);

  useEffect(() => {
    if (
      token &&
      date &&
      state.ActiveAcdYr &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetAttCount({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(date.toString()),
          entry_level,
          entry_id: entryId!,
        },
      });
    }
  }, [
    GetAttCount,
    token,
    date,
    state.ActiveAcdYr,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
  ]);
  useEffect(() => {
    if (stdData && !stdLoading && token) {
      const updatedStudents = stdData.GetAcdStdsForAttendance.map(
        (student: StudentListEdges) => ({
          ...student,
          isChecked: true,
        })
      );
      setStudents(updatedStudents);
    } // eslint-disable-next-line
  }, [stdData, stdLoading, token]);
  useEffect(() => {
    if (token && state.ActiveAcdYr) {
      GetStudentAcdList({
        variables: {
          token,
          input: {
            att_query_type:
              entry_level === AcdSubjectAllocationData.CLASS
                ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                : entry_level === AcdSubjectAllocationData.SEMESTER
                ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            subj_master_id: 0,
            per_std_subj_allocation: false,
          },
        },
      });
    }
  }, [
    GetStudentAcdList,
    token,
    state.ActiveAcdYr,
    entry_level,
    entryId,
    InstId,
    date,
  ]);

  const [GetTodaysAttStatus, { data: attStatus }] = useLazyQuery<
    GetAcdAttStdTodaysStatusData,
    GetAcdAttStdAttStatusVars
  >(GetAcdAttStdTodaysStatus, {
    variables: {
      token,
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: entryId!,
        entry_level,
        date_of_attendance: toIsoDate(date.toString()),
        inst_id: InstId!,
      },
    },
  });
  useEffect(() => {
    if (
      token &&
      date &&
      state.ActiveAcdYr &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetTodaysAttStatus();
    }
  }, [
    token,
    GetTodaysAttStatus,
    date,
    state.ActiveAcdYr,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
  ]);
  const [GetStdDataAttendance, { data: stdAttData }] = useLazyQuery(
    GetAcdStudentsAttendance
  );

  useEffect(() => {
    if (token && entryId) {
      GetStdDataAttendance({
        variables: {
          token,
          input: {
            att_query_type:
              entry_level === AcdSubjectAllocationData.CLASS
                ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
                : entry_level === AcdSubjectAllocationData.SEMESTER
                ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
                : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            subj_master_id: 0,
            per_std_subj_allocation: false,
          },
        },
      });
    }
  }, [
    token,
    entryId,
    GetStdDataAttendance,
    entry_level,
    InstId,
    date,
    state.ActiveAcdYr,
  ]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="view-daily-att-report">
        <div className="view-daily-att-report--left">
          <List />
        </div>
        <div className="view-daily-att-report--right">
          <InstDetails />
          <div className="daily-attendance__right--cards">
            <div className="daily-attendance__right--cards--list">
              <div className="daily-attendance__right--cards--list--grid">
                <img src={TotalStudents} alt="" />
                <span>Total Students</span>
                <b className="font-blue">
                  {countData?.GetAcdStudentAttendanceCount.total_std_count}
                </b>
              </div>
              <div className="daily-attendance__right--cards--list--grid">
                <img src={TotalPresent} alt="" />
                <span>Total Students Present</span>
                <b className="font-green">
                  {countData?.GetAcdStudentAttendanceCount.present_count}
                </b>
              </div>
              <div className="daily-attendance__right--cards--list--grid">
                <img src={TotalAbsent} alt="" />
                <span>Total Students Absent</span>
                <b className="font-red">
                  {countData?.GetAcdStudentAttendanceCount.absent_count}
                </b>
              </div>
              <div className="daily-attendance__right--cards--list--grid">
                <img src={OverallPercentage} alt="" />
                <span>Overall Attendance Percentage</span>
                <b className="font-purple">
                  {countData?.GetAcdStudentAttendanceCount.att_percentage !==
                  undefined
                    ? countData?.GetAcdStudentAttendanceCount.att_percentage +
                      "%"
                    : ""}
                </b>
              </div>
            </div>
          </div>
          <div className="row g-0  view-daily-att-report--right--filters">
            <div className="col-2">
              <Input id="search" placeholder="Search..." />
            </div>
            <div className="col-2 view-daily-att-report--right--filter">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={["p", "A", "All"]}
                openOnFocus
                // onChange={(e, newValue) => {
                //   if (newValue) {
                //     setFilterBy(newValue);
                //   } else {
                //     setFilterBy(null);
                //   }
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter By"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="col"></div>
            <div className="col-2 view-daily-att-report--right--filter">
              <CalendarForInput
                date={date}
                setDate={setDate}
                dates={dates}
                allowFutureDatesAccess={false}
                inputFor={CustomisedInputFor.GENERAL}
                label="Date"
              />
            </div>
          </div>
          <div className="view-daily-att-report--right--tableblock">
            <TableContainer className="attendance-overview__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Academics_Table.DailyActivities.Attendance.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attStatus &&
                  attStatus?.GetAcdAttStdTodaysStatus !== undefined &&
                  attStatus.GetAcdAttStdTodaysStatus
                    ? stdAttData &&
                      stdAttData.GetAcdStudentsAttendance !== undefined &&
                      stdAttData.GetAcdStudentsAttendance.map(
                        (edge: any, index: number) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className="attendance-overview__table--slno">
                                {index + 1} &nbsp; <img src={Avatar} alt="/" />
                              </TableCell>
                              <TableCell className="attendance-overview__table--admno">
                                {
                                  (edge?.student_details as StudentListEdges)
                                    .std_adm_no
                                }
                              </TableCell>
                              <TableCell className="attendance-overview__table--regno">
                                {
                                  (edge?.student_details as StudentListEdges)
                                    .std_reg_no
                                }
                              </TableCell>
                              <TableCell
                                className="attendance-overview__table--name"
                                //   onClick={() => {
                                //     if (attStatus?.GetAcdAttStdTodaysStatus) {
                                //       dispatch({
                                //         type: payloadTypes.SET_STUDENT_ID,
                                //         payload: {
                                //           studentId: edge?.student_id as number,
                                //         },
                                //       });
                                //       setPerStudentAttendance(
                                //         !perStudentAttendance
                                //       );
                                //     }
                                //   }}
                              >
                                {`${
                                  (edge?.student_details as StudentListEdges)
                                    .first_name
                                } ${
                                  (edge?.student_details as StudentListEdges)
                                    .middle_name
                                } ${
                                  (edge?.student_details as StudentListEdges)
                                    .last_name
                                }`}
                              </TableCell>

                              <TableCell
                                className={`attendance-overview__table--status font-${
                                  (edge as unknown as Record<string, string>)[
                                    `day_${date.getDate()}`
                                  ] === AttendanceStatus.P
                                    ? "green"
                                    : "red"
                                }`}
                                id="td-center"
                              >
                                {
                                  (edge as unknown as Record<string, string>)[
                                    `day_${date.getDate()}`
                                  ]
                                }
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    : students.map((edge: any, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className="attendance-overview__table--slno">
                              {index + 1} &nbsp; <img src={Avatar} alt="/" />
                            </TableCell>
                            <TableCell className="attendance-overview__table--admno">
                              {edge.std_adm_no}
                            </TableCell>
                            <TableCell className="attendance-overview__table--regno">
                              {edge.std_reg_no}
                            </TableCell>
                            <TableCell className="attendance-overview__table--name">
                              {`${edge.first_name} ${edge.middle_name} ${edge.last_name}`}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="excel">Export</Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default ViewDailyAttReport;

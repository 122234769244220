import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { GetStdDemandSummaryTree } from "../../queries/Accountingledgers/List/ById";
import { AppContext } from "../../../../context/context";
import { EMPTY_STRING } from "../../../../utils/constants";
interface Level5Details {
  level_5: string;
  level_5_name: string;
  level_5_desc: string;
  level_5_id: number;
  cur_std_no: number;
  tc_std_no: number;
  ne_std_no: number;
  std_demand_ob: number;
  std_demand_amt: number;
  std_demand_concession: number;
  tc_std_demand_amt: number;
  ne_std_demand_amt: number;
  total_demand: number;
}

interface Level4Details {
  level_4: string;
  level_4_name: string;
  level_4_desc: string;
  level_4_id: number;
  level_5_details: Level5Details[];
  cur_std_no: number;
  tc_std_no: number;
  ne_std_no: number;
  std_demand_ob: number;
  std_demand_amt: number;
  std_demand_concession: number;
  tc_std_demand_amt: number;
  ne_std_demand_amt: number;
  total_demand: number;
}

interface Level3Details {
  level_3: string;
  level_3_name: string;
  level_3_desc: string;
  level_3_id: number;
  level_4_details: Level4Details[];
  cur_std_no: number;
  tc_std_no: number;
  ne_std_no: number;
  std_demand_ob: number;
  std_demand_amt: number;
  std_demand_concession: number;
  tc_std_demand_amt: number;
  ne_std_demand_amt: number;
  total_demand: number;
}

interface Level2Details {
  level_2: string;
  level_2_name: string;
  level_2_desc: string;
  level_2_id: number;
  level_3_details: Level3Details[];
  cur_std_no: number;
  tc_std_no: number;
  ne_std_no: number;
  std_demand_ob: number;
  std_demand_amt: number;
  std_demand_concession: number;
  tc_std_demand_amt: number;
  ne_std_demand_amt: number;
  total_demand: number;
}
interface GetStdDemandSummaryTreeVars {
  token: string;
  inst_id: string;
  fin_yr_id: number;
  std_status: string;
}
interface GetStdDemandSummaryTreeData {
  GetStdDemandSummaryTree: {
    level_1: string;
    level_1_name: string;
    level_1_desc: string;
    level_1_id: number;
    level_2_details: Level2Details[];
    cur_std_no: number;
    tc_std_no: number;
    ne_std_no: number;
    std_demand_ob: number;
    std_demand_amt: number;
    std_demand_concession: number;
    tc_std_demand_amt: number;
    ne_std_demand_amt: number;
    total_demand: number;
  }[];
}

const useDemandTree = (std_status: string) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [GetTree, { data, loading, error }] = useLazyQuery<
    GetStdDemandSummaryTreeData,
    GetStdDemandSummaryTreeVars
  >(GetStdDemandSummaryTree, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
      std_status,
    },
  });
  useEffect(() => {
    if (state.ActiveFinYr && token) {
      GetTree();
    }
  }, [state.ActiveFinYr, GetTree, token, std_status]);
  return { demandTreeData: { data, loading, error } };
};

export default useDemandTree;

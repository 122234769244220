import React, { useState } from "react";
import Enquiry from "../../images/Enquiry.svg";
import Accounts from "../../images/AccountsLogo.svg";
import Academics from "../../images/AcademicsLogo.svg";
import Admissions from "../../images/Admissions.svg";
import HR from "../../images/HR.svg";
import UserRightsImg from "../../images/User_Rights.svg";
import { ModuleName } from "../../utils/Enum.types";
import AdmissionsLogin from "../../videos/AdmissionsLogin.mp4";
import AcademicsLogin from "../../videos/AcademicsLogin.mp4";
import chairmanLogin from "../../videos/ChairmanLogin.mp4";
import EnquiryLogin from "../../videos/EnquiryLogin.mp4";
import AccountsLogin from "../../videos/Accounts.mp4";
import TeacherLogin from "../../videos/TeacherLogin.mp4"
import MultipleLogin from "../../videos/MultipleLogin.mp4";
import StudentandParent from "../../videos/StudentandParentLogin.mp4";
import Chairman from "../../images/ChairmanLogin.svg";
import Teacher from "../../images/TeacherLogin.svg";
import Previous from "../../images/CalenderPreviousButton.svg";
import Next from "../../images/CalenderNextButton.svg"
const Tabs = () => {
  const List = [
    {
      title: "Enquiry",
      value: ModuleName.ENQUIRY,
      image: `${Enquiry}`,
      gif: `${EnquiryLogin}`,
      text: "MyEduate enables institutions to efficiently capture, track, and respond to enquiries. Key features include automated follow-ups, enquiry categorization, communication history, and seamless integration with admission workflows. This ensures a smooth and streamlined experience for both the administration and prospective students, enhancing engagement and efficiency at every step.",
    },
    {
      title: "Admissions",
      value: ModuleName.ADMISSION,
      image: `${Admissions}`,
      gif: `${AdmissionsLogin}`,
      text: "The Admission Module in MyEduate helps make student enrollment easier. It allows you to assign students to classes, bring in their data, and keep records organized. This makes the whole admission process smooth, from applying to enrolling, making it easier for both staff and students.",
    },
    {
      title: "Accounts",
      value: ModuleName.ACCOUNTS,
      image: `${Accounts}`,
      gif: `${AccountsLogin}`,
      text: " With MyEduate, the Accounts Management system automates the entire fee collection process, tracks all payments, and generates comprehensive financial reports. It also provides efficient control over finances, ensuring complete transparency for your institution",
    },
    {
      title: "Academics",
      value: ModuleName.ACADEMICS,
      image: `${Academics}`,
      gif: `${AcademicsLogin}`,
      text: "The Academics Module in MyEduate helps manage curriculum planning, course scheduling, and attendance tracking. It allows teachers to create and update course materials, track student performance, and communicate easily with students. This module makes academic administration smoother, helping improve learning outcomes and making academic processes more efficient",
    },
    {
      title: "Teacher Login",
      value: ModuleName.LIBRARY,
      image: `${Teacher}`,
      gif: `${TeacherLogin}`,
      text: "The Teacher Login in MyEduate gives teachers access to a personalized dashboard where they can manage their classes, take attendance, upload assignments, and track student progress. They can also communicate with students and parents, check timetables, and see important announcements, all from one easy-to-use platform",
    },

    {
      title: "Chairman Login",
      value: ModuleName.CHAT,
      image: `${Chairman}`,
      gif: `${chairmanLogin}`,
      text: "MyEduate offers a dedicated dashboard for the Chairman, Director, Principal, and Management, allowing them to oversee financials, admissions, and staff information for day-to-day operations. For groups of institutions, they can also view cumulative statistics across all campuses, providing a clear overview of overall performance and key metrics",
    },
    {
      title: "Student and Parent Login",
      value: ModuleName.PAYROLL,
      image: `${HR}`,
      gif: `${StudentandParent}`,
      text: "MyEduate App gives students, parents, and staff convenient access to important features anytime, anywhere. Students can check assignments, grades, and schedules; parents receive notifications, track attendance, and monitor their child's progress; and staff can manage attendance, communicate, and update academic records. This app ensures real-time access and boosts engagement throughout the institution",
    },

    {
      // title: "Multiple Login",
      value: ModuleName.USER_RIGHTS,
      image: `${UserRightsImg}`,
      gif: `${MultipleLogin}`,
      // text: "The ERP System integrates modules like Enquiry, Admission, Accounting, Academics, Transport, Payroll, and Communication to streamline operations, enhance efficiency, and improve management across educational institutions.",
    },
  ];
  const [selectedModule, setSelectedModule] = useState<string>(ModuleName.ENQUIRY);
  const currentIndex = List.findIndex((module) => module.value === selectedModule);

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % List.length; 
    setSelectedModule(List[nextIndex].value);
  };

  const handlePrevious = () => {
    const prevIndex = (currentIndex - 1 + List.length) % List.length; 
    setSelectedModule(List[prevIndex].value);
  };
  return (
    <>
      <div className="login__tabs">
        <div className="login__tabs--left">
          <ul>
            {List.map((res, index) => {
              return (
                <li
                  key={index}
                  onClick={() => setSelectedModule(res.value)} // Set selected module on click
                  className={selectedModule === res.value ? "active" : ""}
                >
                  <img src={res.image} alt="" />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="login__tabs--right">
          {selectedModule && (
            <>
              {List.map((res, index) => {
                if (res.value === selectedModule) {
                  return (
                    <React.Fragment key={index}>
                      <div className="login__tabs--right--video-block">
                        <img src={Previous} alt=""   onClick={handlePrevious}/>
                      <video
                        className="login__tabs--right--video"
                        src={res.gif}
                        autoPlay
                        muted
                        onEnded={handleNext}
                      >
                        Your browser does not support the video tag.
                      </video>
                      <img src={Next} alt=""   onClick={handleNext}/>
                      </div>
               
                      <p>{res.text}</p>
                      <h5>{res.title}</h5>

                    </React.Fragment>
                   
                  );
                }
                return null;
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Tabs;

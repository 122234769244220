import { useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { AccountsTitleProps } from "../../../Types/Titles";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import { GetAcctGroupLdgrs } from "../queries/FeeLedgers/query/Byid";
import DeleteIcon from "../../../images/Delete.svg";
import useGroupLedgerData, {
  GroupLedgerEdge,
} from "../hooks/useGroupLedgerData";
import { DeleteAcctGroupLdgrById } from "../queries/GroupLedgers/mutation/Index";
import { AcctGroupLdgrQueryType } from "../common/QueryTypes";
import { Button } from "../../../stories/Button/Button";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
const { AccountsTitles } = require("../json/title.json");
interface Props {
  setEnableDelete: React.Dispatch<React.SetStateAction<boolean>>;
}
const Delete = ({ setEnableDelete }: Props) => {
  const { InstId } = useParams();
  const { Accounts_Table } = useAcctTableJson();
  const [searchData, setSearchData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [acctId, setAcctId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [groupLedgers, setGroupLedgers] = useState<GroupLedgerEdge[]>([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { user_details } = useLoggedInUserDetails();

  const {
    groupLedgers: { data, loading: groupLedgerDataLoading, fetchMore },
  } = useGroupLedgerData(
    searchData,
    AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID
  );
  const { token } = useToken();

  const [DeleteLedger, { loading }] = useMutation(DeleteAcctGroupLdgrById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleDelete = (id: number) => {
    DeleteLedger({
      variables: {
        token,
        id,
        user_details,
        inst_id: InstId,
      },
      refetchQueries: [
        {
          query: GetAcctGroupLdgrs,
          variables: {
            token,
            after: null,
            input: {
              acct_ldgr_query_type:
                AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
              ids: [Number(InstId)],
            },
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            name: searchData,
            sortBy: SortBy.LDGR_DESC,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Group Ledger Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAcctId(0);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctGroupLdgrs.edges;
      if (endCursor) {
        const updatedNewData = newData.map((newGrpLedger) => {
          const filteredStudent = groupLedgers.find(
            (grpLedger) => grpLedger.node.id === newGrpLedger.node.id
          );
          if (filteredStudent) {
            return {
              ...newGrpLedger,
              node: {
                ...newGrpLedger.node,
              },
            };
          }
          return newGrpLedger;
        });
        setGroupLedgers(updatedNewData);
      } else {
        setGroupLedgers(newData);
      }
      setEndCursor(data.GetAcctGroupLdgrs.pageInfo.endCursor);
    }
  }, [data, loading]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !groupLedgerDataLoading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctGroupLdgrs.edges;
            const pageInfo = fetchMoreResult.GetAcctGroupLdgrs.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctGroupLdgrs.edges.filter(
              ({ node: { id } }) => {
                return (
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
                );
              }
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctGroupLdgrs: {
                edges: [...groupLedgers, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcctGroupLdgrs.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };

  return (
    <>
      <Title>
        {AccountsTitles.GroupLedger.map(
          (title: AccountsTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.DELETE}</React.Fragment>;
          }
        )}
      </Title>
      <div className="delete-account-ledger">
        <div className="row g-0">
          <div className="col-3">
            <Input
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search Group Ledgers..."
              id="search"
              autoFocus
            />
          </div>
        </div>
        <div className="delete-account-ledger__tableblock">
          <TableContainer
            className="delete-account-ledger__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.AccountLedger.Delete.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {groupLedgers.map((response, index: number) => (
                  <TableRow key={response.node.id}>
                    <TableCell
                      id="td-center"
                      className="delete-account-ledger__table--slno"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      {response.node.gr_ldgr_desc +
                        `${
                          response.node.gr_ldgr_aie === "F"
                            ? " (Fixed Asset)"
                            : response.node.gr_ldgr_aie === "A"
                            ? " (Current Asset)"
                            : response.node.gr_ldgr_aie === "E"
                            ? " (Exepnses)"
                            : response.node.gr_ldgr_aie === "I"
                            ? " (Income)"
                            : " (Liabilities)"
                        }`}
                    </TableCell>

                    <TableCell
                      id="td-center"
                      className="delete-account-ledger__table--actions"
                    >
                      <img
                        src={DeleteIcon}
                        onClick={() => {
                          setAcctId(response.node.id);
                          setDeleteModal(!deleteModal);
                        }}
                        alt="/"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="cancel" onClick={() => setEnableDelete(false)} />
          </div>
          <div className="col-3 delete-account-ledger__total">
            <div className="student-total-count">
              Total Ledgers :{" "}
              <b>{data ? data.GetAcctGroupLdgrs.totalCount : 0}</b>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={acctId}
      />
    </>
  );
};

export default Delete;

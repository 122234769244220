import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GetStudentsByAdmNo } from "../../../queries/students/list/newApi";

import {
  Direction,
  SortBy,
  StudentAcctReportType,
} from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import {
  GetAcctStdDemandData,
  StudentDemandEdges,
} from "./useStudentsbyDemandAmount";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  FIRST_FEW_DATA,
} from "../../../utils/constants";
import { AcctStudentQueryType } from "../common/QueryTypes";
import { AppContext } from "../../../context/context";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";

interface StudentNode {
  id: number;
  std_adm_no: string;
}

interface StudentPageInfo {
  endCursor: string;
}
export interface StudentEdges {
  node: StudentNode;
}
interface GetStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
interface input {
  std_demand_query_type: string;
  ids: number[];
  str_data?: string[];
  int_data?: number[];
  float_data?: number[];
}
interface vars {
  name: string;
  admNo: string;
  after: string | null;
  sortBy?: string;
  direction: string;
  first: number | null;
  token: string;
  fin_yr_id: number;
  id: number | null;
  input: input;
}
export interface GetStudentsData {
  GetStudents: GetStudentsList;
}

const useAcctStdAdmissionNumber = (
  admNo: string,
  rowsPerPage: number,
  queryType: StudentAcctReportType
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [endCursor, setEndCursor] = useState("");

  const [students, setStudents] = useState<StudentDemandEdges[]>([]);

  const [studentAddmissionNumber, setStudentList] = useState<responseType[]>(
    []
  );
  const [std_demand_query_type, setstd_query_type] = useState("");

  useEffect(() => {
    if (!InstId) return;

    switch (queryType) {
      case StudentAcctReportType.GENERAL:
        setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_INST_ID);
        break;
      case StudentAcctReportType.FEE_ADVANCE:
        setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_INST_ID);
        break;
      case StudentAcctReportType.PARTIALLY_PAID:
        setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_INST_ID);
        break;
      case StudentAcctReportType.DEMAND_RAISED_NOT_PAID:
        setstd_query_type(
          AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_INST_ID
        );
        break;
      case StudentAcctReportType.DEMAND_RAISED:
        setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_INST_ID);
        break;
      case StudentAcctReportType.PARTIALLY_OR_COMPLETELY_PAID:
        setstd_query_type(
          AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_INST_ID
        );
        break;
      case StudentAcctReportType.FEE_RECEIPT:
        setstd_query_type(
          AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_INST_ID
        );
        break;
      case StudentAcctReportType.SOCIAL_WELFARE_STUDENT:
        setstd_query_type(AcctStudentQueryType.SOCIAL_WELFARE_STUDENT);

        break;
      case StudentAcctReportType.AGENT_STUDENT:
        setstd_query_type(AcctStudentQueryType.AGENT_STUDENT);

        break;
      case StudentAcctReportType.ALL_ANONYMOUS_STUDENTS:
        setstd_query_type(AcctStudentQueryType.ALL_ANONYMOUS_STUDENTS);
        break;
      case StudentAcctReportType.ANONYMOUS_STUDENT:
        setstd_query_type(AcctStudentQueryType.ANONYMOUS_STUDENT);
        break;
      case StudentAcctReportType.DEMAND_NOT_RAISED:
        setstd_query_type(AcctStudentQueryType.DEMAND_NOT_RAISED_BY_INST_ID);
        break;
      default:
        break;
    }
  }, [InstId, queryType]);

  const [GetStudentsData, { error, data, loading, fetchMore }] = useLazyQuery<
    GetAcctStdDemandData,
    vars
  >(GetStudentsByAdmNo, {
    variables: {
      admNo,
      after: null,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: rowsPerPage,
      name: EMPTY_STRING,
      token,
      direction: Direction.ASC,
      sortBy: SortBy.FIRST_NAME,
      input: { ids: [Number(InstId)!], std_demand_query_type },
      id: state.studentId ? state.studentId : null,
    },
  });

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({ node }) => {
                return (
                  newEdges.findIndex(
                    ({ node: newNode }) =>
                      newNode.mst_student.id === node.mst_student.id
                  ) !== -1
                );
              }
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcctStdDemand.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (state.ActiveFinYr && InstId && std_demand_query_type && token) {
      GetStudentsData();
    }
  }, [
    GetStudentsData,
    admNo,
    state.ActiveFinYr,
    InstId,
    queryType,
    error,
    std_demand_query_type,
    state.studentId,
    token,
  ]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        const result = updatedNewData.map((edge) => ({
          label:
            queryType === StudentAcctReportType.AGENT_STUDENT ||
            queryType === StudentAcctReportType.ALL_ANONYMOUS_STUDENTS ||
            queryType === StudentAcctReportType.SOCIAL_WELFARE_STUDENT ||
            queryType === StudentAcctReportType.ANONYMOUS_STUDENT
              ? edge.node.mst_student.first_name +
                " " +
                edge.node.mst_student.middle_name +
                " " +
                edge.node.mst_student.last_name
              : `(${edge.node.mst_student.std_adm_no}) ` +
                edge.node.mst_student.first_name +
                " " +
                edge.node.mst_student.middle_name +
                " " +
                edge.node.mst_student.last_name,
          value: edge.node.mst_student.id,
        }));
        setStudentList(result);

        setStudents(updatedNewData);
      } else {
        const result = newData.map((edge) => ({
          label:
            queryType === StudentAcctReportType.AGENT_STUDENT ||
            queryType === StudentAcctReportType.ALL_ANONYMOUS_STUDENTS ||
            queryType === StudentAcctReportType.SOCIAL_WELFARE_STUDENT ||
            queryType === StudentAcctReportType.ANONYMOUS_STUDENT
              ? edge.node.mst_student.first_name +
                " " +
                edge.node.mst_student.middle_name +
                " " +
                edge.node.mst_student.last_name
              : `(${edge.node.mst_student.std_adm_no}) ` +
                edge.node.mst_student.first_name +
                " " +
                edge.node.mst_student.middle_name +
                " " +
                edge.node.mst_student.last_name,
          value: edge.node.mst_student.id,
        }));

        setStudentList(result);
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return { studentAddmissionNumber, handleScroll };
};

export default useAcctStdAdmissionNumber;

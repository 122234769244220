import { useContext } from "react";
import { Label } from "../../../../../stories/Label/Label";
import {  Checkbox, TextField } from "@mui/material";

import { MULTI_SELECT_LIMIT_TAGS } from "../../../../../utils/constants";
import { ExcelReportPageType, StudentStatus } from "../../../../../utils/Enum.types";
import { payloadType, SortBy, sortbyOptionsType } from "../GlobalStates/types";
import { StatesContext } from "../GlobalStates/StatesProvider";
import { isOptionEqualToValue } from "../../../../../utils/UtilFunctions";
import { labelClasses, LabeledAutocomplete } from "../../../../../styles/AutocompleteListStyles";
import { ExportProps } from "../Index";

const OtherOptions = ({pageType}:ExportProps) => {


  const { state, dispatch } = useContext(StatesContext);

  const sortbyOptions: sortbyOptionsType[] = [
    {
      label: "Student Name",
      value: SortBy.STD_NAME,
    },
    {
      label: "Admission Number",
      value: SortBy.STD_ADM_NUM,
    },
    {
      label: "Student Register Number",
      value: SortBy.STD_REG_NUM,
    },
    {
      label: "Student Roll Number",
      value: SortBy.STD_ROLL_NUM,
    },
  ];

  return (
    <div className="inst-level-report__rt">
      <div className="inst-level-report__rt--header">
        <b>Other Options</b>
      </div>
      <div className="inst-level-report__rt--block">
        {
          pageType=== ExcelReportPageType.STUDENT && <div className="inst-level-report__rt--select">
          <Label>Sort By</Label>
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={sortbyOptions}
            openOnFocus
            value={state.selectedSortBy}
            isOptionEqualToValue={(option) =>
              isOptionEqualToValue(option as sortbyOptionsType,state.selectedSortBy )
            }
            onChange={(e, newValue) => {
              if (newValue) {
                dispatch({
                  payload: {
                    selectedSortBy: newValue as sortbyOptionsType,
                  },
                  type: payloadType.SORT_BY,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                className={labelClasses.formControlRoot}
              />
            )}
          />
          <Label>Student Status</Label>
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            limitTags={MULTI_SELECT_LIMIT_TAGS}
            multiple
            options={Object.values(StudentStatus) as string[]}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={state.selectedStatus.includes(option as StudentStatus)}
                />
                {option as string}
              </li>
            )}
            openOnFocus
            value={state.selectedStatus}
            onChange={(e, newValue) => {
              if (newValue) {
                dispatch({
                  payload: {
                    selectedStatus: newValue as string[],
                  },
                  type: payloadType.STATUS,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
        }
       
      </div>
    </div>
  );
};

export default OtherOptions;

import { useMutation, useLazyQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
// import Pointer from "../../../../images/Pointer.svg";
import Pointer from "../../../../images/Pointer.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import dayjs from "dayjs";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import Edit from "../../../../images/EditProfile.svg";
import { Form, Formik } from "formik";
import { msgType, payRollAcademicYear } from "../../../../utils/Form.types";
import Input from "../../../../components/common/Input/Index";
import { payroll_academic_validation } from "../../../../utils/validationRules";
import { InstitutionDetailsFormLabelsType } from "../../../../Types/Institution/json";
import useToken from "../../../../customhooks/useToken";
import { TextField } from "@mui/material";
import { Label } from "../../../../stories/Label/Label";
import MessageModal from "../../../../pages/MessageModal";
import { Keys } from "../../../../utils/Enum.keys";
import Settings from "../../../../images/Settings.svg";
import { Operation, PageNumbers } from "../../../../utils/Enum.types";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  EMPTY_STRING,
} from "../../../../utils/constants";
import { GetPayRollAcdYrsByInstId } from "../../queries/academicyear/query";
import { PayRollByNodeIds } from "../../queries/general/list";
import { AddPayRollAcdYr } from "../../queries/academicyear/mutations/new";
import { UpdatePayRollAcdYr } from "../../queries/academicyear/mutations/update";
import usePayRollAcademicYear from "../../hooks/usePayRollAcademicYears";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
const { HRFormLabels } = require("../../json/form.json");

const AcademicYear = () => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { Masters_Table } = useMasterTableJson();

  const { user_details } = useLoggedInUserDetails();

  // const [edit, setEdit] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [academicYearId, setAcademicYearId] = useState(0);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [FutureYear, setFutureYear] = useState("");
  const [configurationModal, setConfigurationModal] = useState(false);

  const [formData, setFormData] = useState<payRollAcademicYear>({
    payroll_yr: "",
    payroll_st_date: "",
    payroll_end_date: "",
  });
  const payroll_st_date = "payroll_st_date";
  const startDateRef = document.getElementsByName(
    payroll_st_date
  )[0] as HTMLInputElement;
  const endDateRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const chooseAcdYearRef = useRef<HTMLSelectElement>(null);
  const chooseAcdYearInputRef =
    chooseAcdYearRef?.current?.getElementsByClassName(
      AUTOCOMPLETE_INPUT_CLASSNAME
    )[0] as HTMLInputElement;
  const {
    payRollAcademicYearResponse: {
      data: AcademicYearResponse,
      error: AcademicYearResponseError,
    },
  } = usePayRollAcademicYear();

  const { USE_PAYROLL } = useUserRightsByEmpId();

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.ACADEMIC_YEAR_PAGE
  );

  const AcademicYearList = AcademicYearResponse
    ? AcademicYearResponse.GetPayRollAcdYrsByInstId
    : [];
  let serialno = 1;
  const [GetAcademicYearData, { data }] = useLazyQuery(PayRollByNodeIds, {
    variables: { token, ids: academicYearId },
  });
  const [newAcademicYear, { loading: creationLoading }] = useMutation(
    AddPayRollAcdYr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateAcademicYear, { loading: updationLoading }] = useMutation(
    UpdatePayRollAcdYr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const upcommingAcademicYears = () => {
    var currentYear = new Date().getFullYear();

    return [...new Array(5).keys()].map((i) => {
      const start = dayjs(`4-1-${currentYear}`).add(i, "year").format();
      const end = dayjs(`03-31-${currentYear + 1}`)
        .add(i, "year")
        .format();

      return {
        label: `${dayjs(start).format("YYYY")}-${dayjs(end).format("YYYY")}`,
        value: {
          startDate: start,
          endDate: end,
        },
      };
    });
  };
  const years = [...upcommingAcademicYears()];
  years.sort(
    (a, b) =>
      new Date(a.value.startDate).valueOf() -
      new Date(b.value.startDate).valueOf()
  );

  const HandleRegister = async () => {
    await newAcademicYear({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: {
          payroll_yr: formData.payroll_yr,
          payroll_st_date: toIsoDate(formData.payroll_st_date),
          payroll_end_date: toIsoDate(formData.payroll_end_date),
          payroll_is_curr_yr: false,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollAcdYrsByInstId,
          variables: { inst_id: InstId, token },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Successfully Added  Academic year",
          flag: true,
          operation: Operation.CREATE,
        });
    });
    setOperation(Operation.CREATE);
  };
  const HandleUpdate = async () => {
    const AcdStartYear = new Date(formData.payroll_st_date).toISOString();
    const AcdEndDate = new Date(formData.payroll_end_date).toISOString();
    await updateAcademicYear({
      variables: {
        id: academicYearId,
        token,
        inst_id: InstId,
        user_details,

        input: {
          payroll_yr: formData.payroll_yr,
          payroll_st_date: AcdStartYear,
          payroll_end_date: AcdEndDate,
          payroll_is_curr_yr: false,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollAcdYrsByInstId,
          variables: { inst_id: InstId, token },
        },
        {
          query: PayRollByNodeIds,
          variables: { token, ids: academicYearId },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Successfully Updated Academic year",
          flag: true,
          operation: Operation.UPDATE,
        });
    });
    setOperation(Operation.CREATE);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleclear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleclear = () => {
    setFormData({
      payroll_end_date: "",
      payroll_is_curr_yr: false,
      payroll_st_date: "",
      payroll_yr: "",
    });
    setSelectedAcademicYear("");
    setOperation(Operation.CREATE);
    chooseAcdYearInputRef.focus();
  };
  useEffect(() => {
    //validations
    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let day = new Date().getDate();
    if (formData.payroll_st_date) {
      year = new Date(formData.payroll_st_date).getFullYear();
      month = new Date(formData.payroll_st_date).getMonth() + 1;
      day = new Date(formData.payroll_st_date).getDate();
    }
    let FutureYear = new Date(year + 1, month + 3, day)
      .toISOString()
      .split("T")[0];
    setFutureYear(FutureYear);
  }, [formData.payroll_st_date]);
  useEffect(() => {
    if (!updationLoading && data && operation === Operation.UPDATE) {
      const { payroll_yr, payroll_st_date, payroll_end_date } =
        data.nodes[0] || EMPTY_STRING;
      setFormData({
        payroll_yr: payroll_yr,
        payroll_st_date: payroll_st_date
          ? toInputStandardDate(payroll_st_date)
          : EMPTY_STRING,
        payroll_end_date: payroll_end_date
          ? toInputStandardDate(payroll_end_date)
          : EMPTY_STRING,
      });
    }
  }, [data, updationLoading, operation]);
  useEffect(() => {
    if (selectedAcademicYear) {
      const filteredAcademicYear = years.find(
        ({ label }) => label === selectedAcademicYear
      );
      setFormData({
        payroll_yr: filteredAcademicYear?.label!,
        payroll_st_date: toInputStandardDate(
          filteredAcademicYear?.value.startDate!
        ),
        payroll_end_date: toInputStandardDate(
          filteredAcademicYear?.value.endDate!
        ),
      });
    } // eslint-disable-next-line
  }, [selectedAcademicYear]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>Payroll Academic Year</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>
      <Formik
        initialValues={formData}
        validationSchema={payroll_academic_validation}
        onSubmit={
          operation === Operation.UPDATE ? HandleUpdate : HandleRegister
        }
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="payroll-masterdata">
              <div className="row g-0  payroll-masterdata__frame">
                <div className="col booktype-left h-100">
                  <div className="payroll-masterdata__title">
                    <Title variant="subtitle1">PayRoll Academic Year</Title>
                  </div>
                  <div className="payroll-masterdata__block">
                    {/* {operation === Operation.CREATE ? (  */}

                    <div className="label-grid">
                      <Label>Choose Academic Year</Label>
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        onKeyDown={(e: any) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            startDateRef?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setSelectedAcademicYear("");
                          }
                        }}
                        ref={chooseAcdYearRef}
                        options={years}
                        openOnFocus
                        forcePopupIcon
                        value={
                          years?.find(
                            ({ label }) => label === selectedAcademicYear
                          )! ?? null
                        }
                        disabled={operation === Operation.UPDATE}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setSelectedAcademicYear(
                              (
                                newValue as {
                                  label: string;
                                  value: {
                                    startDate: string;
                                    endDate: string;
                                  };
                                }
                              )?.label!
                            );
                          } else {
                            setSelectedAcademicYear(EMPTY_STRING);
                          }
                          setFormData({
                            payroll_yr: (
                              newValue as {
                                label: string;
                                value: {
                                  startDate: string;
                                  endDate: string;
                                };
                              }
                            )?.label!,
                            payroll_st_date: "",
                            payroll_end_date: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            required={!formData?.AcdYear}
                            {...params}
                            autoFocus
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                    </div>

                    {HRFormLabels.PayRollAcdYear.InputType.map(
                      (
                        label: InstitutionDetailsFormLabelsType,
                        index: React.Key
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              name={label.inputName}
                              LabelName={label.LabelName}
                              type={label.dataType}
                              values={formData[label.inputName]}
                              onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  endDateRef?.current?.focus();
                                }
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              max={label.max && FutureYear}
                              required={label.required}
                              autoFocus={label.autoFocus}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="col booktype-right h-100">
                  <div className="payroll-masterdata__title">
                    <Title variant="subtitle1">List of Academic years</Title>
                  </div>
                  <div className="payroll-masterdata__block--academics">
                    {AcademicYearResponseError ? (
                      <b className="nodata">
                        {AcademicYearResponseError.message}
                      </b>
                    ) : AcademicYearResponse?.GetPayRollAcdYrsByInstId
                        ?.length! > 0 ? (
                      <TableContainer className="payroll-masterdata__block--academics--table">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {Masters_Table.InstitutionDetails.AcademicYear.Table_Headers.map(
                                (th: TableHeaderProps, index: React.Key) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <TableCell className={th.className}>
                                        {th.labelName}
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {AcademicYearList.map((response, index: number) => (
                              <React.Fragment key={index}>
                                <TableRow
                                  className={
                                    response.payroll_is_curr_yr
                                      ? "activefinancialyear"
                                      : ""
                                  }
                                >
                                  <TableCell
                                    id="td-center"
                                    className="payroll-masterdata__block--academics--table--slno"
                                  >
                                    {response.payroll_is_curr_yr && (
                                      <img
                                        className="activefinancialyearIcon"
                                        src={Pointer}
                                        alt="/"
                                      />
                                    )}

                                    {serialno++}
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    className="payroll-masterdata__block--academics--table--date"
                                  >
                                    {response.payroll_yr}
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    className="payroll-masterdata__block--academics--table--date"
                                  >
                                    {toStandardDate(response.payroll_st_date)}
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    className="payroll-masterdata__block--academics--table--date"
                                  >
                                    {toStandardDate(response.payroll_end_date)}
                                  </TableCell>
                                  <TableCell id="td-center">
                                    {USE_PAYROLL.details?.edit ? (
                                      <img
                                        src={Edit}
                                        alt="/"
                                        onClick={() => {
                                          setOperation(Operation.UPDATE);
                                          setSelectedAcademicYear(
                                            response.payroll_yr
                                          );
                                          setAcademicYearId(response.id);
                                          GetAcademicYearData();
                                          startDateRef.focus();
                                        }}
                                      />
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <b className="nodata">
                        No Academic years present. Please try adding
                      </b>
                    )}
                  </div>
                </div>
              </div>
              <>
                <Button type="submit" mode="save" buttonref={saveRef} />{" "}
                <Button mode="clear" type="button" onClick={handleclear} />
                <Button mode="back" onClick={() => navigate(-1)} />
              </>
            </Form>
          );
        }}
      </Formik>

      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <LoadingModal flag={creationLoading} />
    </>
  );
};

export default AcademicYear;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Label } from "../../../stories/Label/Label";
import { Button } from "../../../stories/Button/Button";
import {
  Direction,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddChannelmessage,
  UpdateChannelMessage,
} from "../../../queries/chatapplication/mutations";
import { msgType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import {
  GetChannelMessages,
  GetMessageChannel,
} from "../../../queries/chatapplication/list";
import {
  EMPTY_STRING,
  formatOptions,
  formattingToolbarOptions,
} from "../../../utils/constants";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import MessageModal from "../../../pages/MessageModal";
import { ChannelQueryTypeByInstId } from "../../Accounts/common/Enum.types";
import { ChannelListData, ChannelListVars } from "../Types";
import LoadingModal from "../../../pages/LoadingModal";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  messageId: number;
}
const CreateAnnouncements = ({ setModalFlag, operation, messageId }: Props) => {
  const { token } = useToken();

  const [channelMessage, setChannelMessage] = useState("");
  const { user_details } = useLoggedInUserDetails();
  const { InstId } = useParams();
  const descRef = useRef<ReactQuill>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const { state } = useContext(AppContext);
  const [GetChannelListData, { data: ChannelList }] = useLazyQuery<
    ChannelListData,
    ChannelListVars
  >(GetMessageChannel, {
    variables: {
      token,
      inst_id: InstId!,
      input: {
        query_type: ChannelQueryTypeByInstId.ANNOUNCEMENTS,
        channel_id: 0,
      },
    },
  });
  const channelId =
    ChannelList && ChannelList.GetChannelsByInstId.length
      ? ChannelList?.GetChannelsByInstId[0].id
      : 0;

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [AddChannelMsg, { loading }] = useMutation(AddChannelmessage, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateChannelMsg, { loading: UpdateLoading }] = useMutation(
    UpdateChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleSubmit = () => {
    if (channelMessage.length === 0) {
      return descRef?.current?.focus();
    }
    if (operation === Operation.CREATE) {
      AddChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: EMPTY_STRING,
            msg_channel_id: channelId,
            inst_id: InstId!,
            msg_header: EMPTY_STRING,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              token,
              inst_id: InstId ? InstId : state.InstId.toString(),
              input: {
                query_type: MsgChannelType.ANNOUNCEMENTS,
                channel_id: channelId,
              },
              direction: Direction.ASC,
              last: 10,
              after: null,
              sortBy: SortBy.CREATED_AT,
              mediaContent: EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Announcement Created Successfully",
            operation: Operation.CREATE,
          });
          setIsDisabled(true);
        }
      });
    } else {
      UpdateChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: EMPTY_STRING,
            msg_header: EMPTY_STRING,
          },
          user_details,
          msg_id: messageId,
        },
        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              token,
              inst_id: InstId ? InstId : state.InstId.toString(),
              input: {
                query_type: MsgChannelType.ANNOUNCEMENTS,
                channel_id: channelId,
              },
              direction: Direction.ASC,
              last: 10,
              after: null,
              sortBy: SortBy.CREATED_AT,
              mediaContent: EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Announcement Updated Successfully",
            operation: Operation.CREATE,
          });
          setIsDisabled(true);
        }
      });
    }
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setModalFlag(false);
      setIsDisabled(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token && InstId) {
      GetChannelListData();
    }
  }, [token, InstId, GetChannelListData]);
  return (
    <>
      <div className="assignments__creation">
        <div className="assignments__creation--title">
          <Title>Create Announcements</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="assignments__creation--data">
          <div className="assignments__creation--selected"></div>
          <div className="label-grid">
            <Label>Description</Label>
            <ReactQuill
              value={channelMessage}
              ref={descRef!}
              onChange={setChannelMessage}
              modules={formattingToolbarOptions}
              formats={formatOptions}
            />
          </div>
        </div>
        <Button mode="save" onClick={handleSubmit} disabled={isDisabled} />
        <Button mode="clear" />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default CreateAnnouncements;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/context";
import useToken from "../useToken";
import { InstIdVars } from "../../Modules/HR/Types/masterDataTypes";
import { GetAcdInstTreeForAllocatedLevel } from "../../queries/common";
enum InstTreeQueryType {
  TREE_BY_INST_ID = "TREE_BY_INST_ID",
  TREE_BY_DEPT_ID = "TREE_BY_DEPT_ID",
  TREE_BY_BRANCH_ID = "TREE_BY_BRANCH_ID",
  TREE_BY_CLASS_ID = "TREE_BY_CLASS_ID",
  TREE_BY_SEMESTER_ID = "TREE_BY_SEMESTER_ID",
  TREE_BY_SECTION_ID = "TREE_BY_SECTION_ID",
  NONE = "NONE",
}
interface AcdLevel5Tree {
  acd_level_5: string;
  acd_level_5_name: string;
  acd_level_5_desc: string;
  acd_level_5_id: number;
  per_std_subj_allocation: boolean;
  boys_count: number;
  girls_count: number;
  total_count: number;
  total_att_present: number;
  total_att_absent: number;
  att_percentage: number;
  att_marked: boolean;
  total_subjects: number;
  allocated_level: string;
  total_test_subjects: number;
  test_start_date: string;
  test_end_date: string;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_acd_level_total_count: number;
  show_seats_info: boolean;
}
interface AcdLevel4Tree {
  acd_level_4: string;
  acd_level_4_name: string;
  acd_level_4_desc: string;
  acd_level_4_id: number;
  per_std_subj_allocation: boolean;
  acd_level_5_details: AcdLevel5Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  total_att_present: number;
  total_att_absent: number;
  att_percentage: number;
  att_marked: boolean;
  total_subjects: number;
  allocated_level: string;
  total_test_subjects: number;
  test_start_date: string;
  test_end_date: string;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_acd_level_total_count: number;
  show_seats_info: boolean;
}
interface AcdLevel3Tree {
  acd_level_3: string;
  acd_level_3_name: string;
  acd_level_3_desc: string;
  acd_level_3_id: number;
  per_std_subj_allocation: boolean;
  acd_level_4_details: AcdLevel4Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  total_att_present: number;
  total_att_absent: number;
  att_percentage: number;
  att_marked: boolean;
  total_subjects: number;
  allocated_level: string;
  total_test_subjects: number;
  test_start_date: string;
  test_end_date: string;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_acd_level_total_count: number;
  show_seats_info: boolean;
}
interface AcdLevel2Tree {
  acd_level_2: string;
  acd_level_2_name: string;
  acd_level_2_desc: string;
  acd_level_2_id: number;
  per_std_subj_allocation: boolean;
  acd_level_3_details: AcdLevel3Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  total_att_present: number;
  total_att_absent: number;
  att_percentage: number;
  att_marked: boolean;
  total_subjects: number;
  allocated_level: string;
  total_test_subjects: number;
  test_start_date: string;
  test_end_date: string;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_acd_level_total_count: number;
  show_seats_info: boolean;
}
export interface AcdLevel1Tree {
  acd_level_1: string;
  acd_level_1_name: string;
  acd_level_1_desc: string;
  acd_level_1_id: number;
  total_att_present: number;
  total_att_absent: number;
  att_percentage: number;
  att_marked: boolean;
  total_subjects: number;
  allocated_level: string;
  total_test_subjects: number;
  test_start_date: string;
  test_end_date: string;
  per_std_subj_allocation: boolean;
  acd_level_2_details: AcdLevel2Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_acd_level_total_count: number;
  show_seats_info: boolean;
}
interface GetMstInstTreeData {
  GetAcdInstTreeForAllocatedLevel: AcdLevel1Tree[];
}
interface GetMstInstTreeByQueryTypeVars extends InstIdVars {
  id: number;
  query_type: InstTreeQueryType;
  date_of_attendance: string;
  testNameId: number;
  acd_yr_id: number;
}

const useMstInstTreeByAllocatedLevel = (
  deptId: number,
  branchId: number,
  classId: number,
  semId: number,
  sectionId: number,
  date_of_attendance: string,
  testNameId: number
) => {
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [id, setId] = useState(0);
  const [query_type, setQueryType] = useState<InstTreeQueryType>(
    InstTreeQueryType.NONE
  );
  useEffect(() => {
    if (sectionId) {
      setId(sectionId);
      setQueryType(InstTreeQueryType.TREE_BY_SECTION_ID);
    } else if (semId) {
      setId(semId);
      setQueryType(InstTreeQueryType.TREE_BY_SEMESTER_ID);
    } else if (classId) {
      setId(classId);
      setQueryType(InstTreeQueryType.TREE_BY_CLASS_ID);
    } else if (branchId) {
      setId(branchId);
      setQueryType(InstTreeQueryType.TREE_BY_BRANCH_ID);
    } else if (deptId) {
      setId(deptId);
      setQueryType(InstTreeQueryType.TREE_BY_DEPT_ID);
    } else {
      setId(0);
      setQueryType(InstTreeQueryType.TREE_BY_INST_ID);
    }
  }, [sectionId, semId, classId, branchId, deptId]);

  const [GetInstTree, { data, loading }] = useLazyQuery<
    GetMstInstTreeData,
    GetMstInstTreeByQueryTypeVars
  >(GetAcdInstTreeForAllocatedLevel, {
    variables: {
      inst_id: InstId ? InstId : state.InstId,
      id,
      token,
      query_type,
      date_of_attendance,
      testNameId,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token && InstId && query_type !== InstTreeQueryType.NONE) {
      GetInstTree();
    }
  }, [token, state.InstId, GetInstTree, InstId, id, query_type]);
  return {
    data,
    loading,
  };
};

export default useMstInstTreeByAllocatedLevel;

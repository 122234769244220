import React from "react";
import AddRoute from "../Modules/Transport/MasterData/Route/Index";
import RouteDetails from "../Modules/Transport/MasterData/RouteDetails/Index";
import AssignRouteToStudent from "../Modules/Transport/MasterData/AssignRoute/ToStudent";
import StudentListTransport from "../Modules/Transport/Student/List";
import StudentRouteList from "../Modules/Transport/Student/StudentRoute";
import ViewStudentRoute from "../Modules/Transport/Student/View";
import TransportHome from "../Modules/Transport/Dashboard/Index";
import CreateLedger from "../Modules/Transport/TransportLedger/CreateLedger";
import RouteAssignedStudentList from "../Modules/Transport/MasterData/AssignRoute/List";
// eslint-disable-next-line
import { Route, Routes } from "react-router-dom";

import {
  EduateModule,
  Operation,
  PageFor,
  StudentReportType,
} from "../utils/Enum.types";
import useEduateModuleConfig from "../customhooks/useModulesConfiguartion";
import Page404 from "../pages/Page404";
import useUserRightsByEmpId from "../Modules/UserRights/hooks/useUserRightsByEmpId";

const TransportRoutes = () => {
  const { error } = useEduateModuleConfig(EduateModule.TRANSPORT);
  const { USE_TRANSPORT } = useUserRightsByEmpId();

  return (
    <Routes>
      {error || !USE_TRANSPORT.flag ? (
        <>
          <Route path="*" element={<Page404 />} />
        </>
      ) : (
        <>
          <Route path="/" element={<TransportHome />} />
          <Route
            path="/masterdata/route/add"
            element={<AddRoute pageType={PageFor.GENERAL} />}
          />
          <Route path="/masterdata/route/details" element={<RouteDetails />} />
          <Route
            path="/masterdata/assignroutetostudent"
            element={
              <AssignRouteToStudent
                operation={Operation.CREATE}
                pageType={PageFor.GENERAL}
              />
            }
          />
          <Route
            path="/masterdata/updateroutetostudent"
            element={
              <AssignRouteToStudent
                operation={Operation.UPDATE}
                pageType={PageFor.GENERAL}
              />
            }
          />
          <Route
            path="/masterdata/assignroutetostudent/list"
            element={<RouteAssignedStudentList pageType={PageFor.GENERAL} />}
          />
          <Route path="/student/StudentRoute" element={<StudentRouteList />} />
          <Route path="/student/View" element={<ViewStudentRoute />} />
          <Route
            path="/student/list"
            element={
              <StudentListTransport
                pageType={PageFor.GENERAL}
                queryType={StudentReportType.TRANSPORT_ENABLED}
              />
            }
          />
          <Route
            path="/transportledger/createledger"
            element={<CreateLedger />}
          />
        </>
      )}
    </Routes>
  );
};

export default TransportRoutes;

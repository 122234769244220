import { gql } from "@apollo/client";

export const GetLibBookMaster = gql`
  query GetLibBookMaster(
    $access_no: String
    $first: Int
    $after: Cursor
    $sortBy: LibBookMasterOrderField
    $direction: OrderDirection!
    $token: String!
    $input: BookQueryData!
  ) {
    GetLibBookMaster(
      token: $token
      first: $first
      after: $after
      input: $input
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ bookAccessNoContainsFold: $access_no }] }
    ) {
      edges {
        node {
          id
          book_access_no
          book_title
          book_status
          reference
          book_entry_date
          book_no_of_times_issued
          book_last_issued_to
          inst_id
          lib_book_classification_id
          lib_purchase_master_id

          last_rack_id
          classification {
            id
            classification_no
            classification_desc
            classification_date
            classification_copies_in
            classification_copies_damaged
            classification_copies_avialable
            classification_copies_issued
            classification_copies_on_shelf
            book_published_year
            book_pages
            book_edition
            book_volume
            book_place
            last_purchased_from
            last_purchased_rate
            total_purchased_value
            total_purchased_qty
            avg_book_rate
            inst_id
            lib_book_media_id
            media {
              id
              media_desc
              media_abbrevation
              media_is_active
              media_sl
            }
            category {
              id
              category_desc
              category_abbrevation
              category_is_active
              category_sl
            }
            subject {
              id
              subject_desc
              subject_classification
              subject_is_active
              subject_sl
            }
            language {
              id
              language
              language_short_desc
            }
            attribute {
              id
              book_attribute
            }
            author {
              id
              author_name
              author_address
              author_city
              author_phone
              author_email_id
            }
            publisher {
              publisher_name
              publisher_address
              publisher_city
              publisher_state
              publisher_country
              publisher_phone
              publisher_contact_person
              publisher_contact_person_tel
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GetBookDamageId = gql`
  query GetBookDamageId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on LibBookMaster {
        id
        book_access_no
        book_title
        classification {
          classification_no
          publisher {
            publisher_name
          }
          author {
            author_name
          }
        }
      }
    }
  }
`;
export const UpdateLibBookDamagedStatus = gql`
  mutation UpdateLibBookDamagedStatus(
    $token: String!
    $book_id: ID!
    $rack_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateLibBookDamagedStatus(
      token: $token
      book_id: $book_id
      rack_id: $rack_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const GetLibBookNotAllocatedRack = gql`
  query GetLibBookNotAllocatedRack($token: String!, $inst_id: ID!) {
    GetLibBookNotAllocatedRack(token: $token, inst_id: $inst_id) {
      id
      lib_rack_no
      lib_rack_desc
      inst_id
    }
  }
`;
export const GetAllInstsOfCampus = gql`
  query GetAllInstsOfCampus($token: String!, $inst_id: ID!) {
    GetAllInstsOfCampus(token: $token, inst_id: $inst_id) {
      id
      is_primary_inst
      campus_id
      inst_id
      inst_details {
        id
        inst_name
      }
      campus_details {
        id
        campus_name
      }
    }
  }
`;

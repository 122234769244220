import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
import { TEXT_FONT_FAMILY } from "../../../../../styles/DataGridTableStyles";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import DetailedFormModal from "./DetailedFormModal";
import View from "../../../../../images/EyeWhite.svg"

interface QuestionWiseGraphProps {
  data?: any;
  subject: string;
  subjectId: number;
}

const QuestionWiseGraphClassWise: React.FC<QuestionWiseGraphProps> = ({
  data,
  subject,
  subjectId,
}) => {
  const [viewModal, setViewModal] = useState(false);

  if (!data) {
    return <div>No data available</div>;
  }

  const { num_questions, ...ratings } =
    data.GetAcdFeedbackFormEmpClassWiseRating;

  const ratingKeys = Array.from(
    { length: num_questions },
    (_, i) => `rating_${i + 1}`
  );

  const seriesData = ratingKeys.map(
    (key) => ratings[key as keyof typeof ratings] || 0
  );

  const yAxisLabels = Array.from(
    { length: num_questions },
    (_, i) => `Q${i + 1}`
  );

  const option = {
    title: {
      text: subject,
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
        crossStyle: {
          color: "#999",
        },
      },
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },
    legend: {},

    xAxis: {
      type: "category",
      data: yAxisLabels,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          color: "#fde68a",
          barWidth: 10,
        },
        data: seriesData,
      },
    ],
  };

  return (
    <>
      <div className="h-100">
        <ReactEcharts option={option} />
        <div className="fb-form-view__ques-graph--button">
          <button onClick={() => setViewModal(!viewModal)} >View <img src={View} alt="" /></button>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <DetailedFormModal setViewModal={setViewModal} subjectId={subjectId} />
      </Modal>
    </>
  );
};

export default QuestionWiseGraphClassWise;

import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import { Button } from "../../../stories/Button/Button";
import RightArrow from "../../../images/ArrowRight.svg";
import Modal from "react-modal";
import {
  PrintModalStyles,
} from "../../../styles/ModalStyles";
import { useLazyQuery } from "@apollo/client";
import {
  GetFinYrsByInstIdData,
  GetFinYrsByInstIdVars,
} from "./types";
import { GetFinYrsByInstId } from "../queries/DashBoard";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import ViewHistoricData from "./ViewHistoricData";
import useActiveFinancialYear from "../hooks/useActiveFinancialYear";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const HistoricData = ({ setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [view, setView] = useState(false);
  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const { dispatch } = useContext(AppContext);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [Year, setYear] = useState<string | null>(null);

  const [GetFinYrsByInstIdDetails, { data }] = useLazyQuery<
    GetFinYrsByInstIdData,
    GetFinYrsByInstIdVars
  >(GetFinYrsByInstId, {
    variables: {
      inst_id: Number(InstId),
      token,
    },
  });
  useEffect(() => {
    if (token && InstId) {
      GetFinYrsByInstIdDetails();
    }
  }, [token, InstId, GetFinYrsByInstIdDetails]);

  return (
    <>
      <div className="accounts-dashboard__historic">
        <div className="accounts-dashboard__historic--title">
          <Title>Historic Data</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="accounts-dashboard__historic--data">
          <b className="accounts-dashboard__historic--b">
            Choose Financial Year
          </b>
          <div className="accounts-dashboard__historic--block">
            <b className="accounts-dashboard__historic--block--yr">
              {ActiveFinancicalYear.data &&
                ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_yr}
            </b>

            <span className="accounts-dashboard__historic--block--span">
              Active Year
            </span>
          </div>
          <div className="accounts-dashboard__historic--ul">
            {data &&
              data.GetFinYrsByInstId.map((res, index) => {
                return (
                  <li
                    onClick={() => {
                      dispatch({
                        type: payloadTypes.SET_FIN_ID,
                        payload: {
                          finId: res.id,
                        },
                      });
                      setView(!view);
                      setSelectedYear(res.id!);
                      setYear(res.fin_yr);
                    }}
                    key={index}
                  >
                    <span>{res.fin_yr} </span> <img src={RightArrow} alt="" />
                  </li>
                );
              })}
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={view}
        style={PrintModalStyles}
        ariaHideApp={false}
      >
        <ViewHistoricData
          setModalFlag={setView}
          selectedYear={selectedYear!}
          year={Year!}
        />
      </Modal>
    </>
  );
};

export default HistoricData;

import { useMemo } from "react";
interface responseType {
  value: number;
  label: string;
  url?: string;
}
const UseSelect = (arrayName: responseType[], searchText: string) => {
  const containsText = (text: string) =>
    text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => arrayName && arrayName?.filter(({ label }) => containsText(label)),
    // eslint-disable-next-line
    [searchText, arrayName]
  );
  return displayedOptions;
};

export default UseSelect;

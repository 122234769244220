import { gql } from "@apollo/client";

export const AddLibBookSubject = gql`
  mutation AddLibBookSubject(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateLibBookSubjectInput!
  ) {
    AddLibBookSubject(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  GetPayRollEmpLeaveMasterList,
  PayRollGradeNode,
  PayRollJobTypeNode,
} from "../Types/masterDataTypes";
import { pageInfo } from "../../../Types/pagination";
import { Direction, SortBy } from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import {
  EmpCategoryDetailsFormType,
  EmpDepartmentDetailsFormType,
  EmpDesignationDetailsFormType,
  EmpOtherDetailsFormType,
} from "../Types/formTypes";
import { GetPayRollEmpAttendance } from "../queries/attendance/query";
import { PrEmpAttQueryType } from "../enums/Enum.types";
import { toIsoDate } from "../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../utils/constants";
interface EmpPersonalDetails {
  emp_father_name: string;
  emp_mother_name: string;
  emp_spouse_name: string;
  emp_tel: string;
  emp_corr_address: string;
  emp_corr_pin: string;
  emp_perm_address: string;
  emp_perm_pin: string;
}
interface PersonalDetails extends EmpPersonalDetails {
  emp_blood_grp: string;
  emp_religion: string;
  emp_caste: string;
  emp_corr_city: string;
  emp_corr_district: string;
  emp_corr_state: string;
  emp_perm_city: string;
  emp_perm_district: string;
  emp_perm_state: string;
  emp_nationality: string;
  emp_martial: string;
}
export interface EmpDetails {
  id: number;
  emp_id: string;
  emp_title: string;
  emp_first_name: string;
  emp_middle_name: string;
  emp_last_name: string;
  emp_sex: string;
  emp_dob: string;
  emp_mobile: string;
  emp_email: string;
  emp_qualification: string;
  emp_experience: number;
  emp_status: string;
  emp_on_roll: boolean;
  emp_type: string;
  is_login_created: boolean;
  emp_doj: string;
  emp_dor: string;
  emp_lsd: string;
  emp_idx: number;
  emp_dept_idx: number;
  auth_id: number;
  pr_grade_id: number;
  pr_job_type_id: number;
  pr_dept_id: number;
  pr_category_id: number;
  pr_designation_id: number;
  other_details: EmpOtherDetailsFormType;
  personal_details: PersonalDetails;
  pr_designation_details: EmpDesignationDetailsFormType;
  pr_dept_details: EmpDepartmentDetailsFormType;
  pr_category_details: EmpCategoryDetailsFormType;
  pr_job_type_details: PayRollJobTypeNode;
  pr_grade_details: PayRollGradeNode;
  leave_master_details: GetPayRollEmpLeaveMasterList;
}

export interface EmpData {
  node: EmpDetails;
}
export interface PayRollEmpEdges {
  node: PayRollEmpDetails;
}
export interface PayRoleEmpList {
  edges: PayRollEmpEdges[];
  pageInfo: pageInfo;
  totalCount: number;
}
export interface PayRoleEmpvars {
  name: string;
  after: string | null;
  sortBy: string;
  direction: string;
  first: number | null;
  pr_acd_yr_id: number;
  token: string;
  input: input;
}

interface input {
  att_query_type: string;
  inst_id: string;
  cal_month: string;
  date_of_attendance: string;
}
export enum empQueryTypes {
  GENERAL = "GENERAL",
  SAL_STR_ASSGINED = "EMP_SAL_STR_ASSGINED",
  SAL_STR_NOT_ASSGINED = "EMP_SAL_STR_NOT_ASSGINED",
  EMPS_ADDED_FOR_SAL_MK_MONTH = "EMPS_ADDED_FOR_SAL_MK_MONTH",
  EMPS_NOT_ADDED_FOR_SAL_MK_MONTH = "EMPS_NOT_ADDED_FOR_SAL_MK_MONTH",
  NOT_SELECTED = "NOT_SELECTED",
  EMPS_WITH_UNASSIGNED_LEAVES = "EMPS_WITH_UNASSIGNED_LEAVES",
  EMPS_WITH_ASSIGNED_LEAVES = "EMPS_WITH_ASSIGNED_LEAVES",
  JUST_LOGIN_ACCTS = "JUST_LOGIN_ACCTS",
}
export enum PrEmpQueryType {
  EMP_BY_INST_ID = "EMP_BY_INST_ID",
  EMP_SAL_STR_ASSGINED = "EMP_SAL_STR_ASSGINED",
  EMP_SAL_STR_NOT_ASSGINED = "EMP_SAL_STR_NOT_ASSGINED",
  EMP_BY_DEPT_ID = "EMP_BY_DEPT_ID",
  EMP_BY_DESIGINATION_ID = "EMP_BY_DESIGINATION_ID",
  EMP_BY_CATEGORY_ID = "EMP_BY_CATEGORY_ID",
  EMP_BY_GRADE_ID = "EMP_BY_GRADE_ID",
  NOT_SELECTED = "NOT_SELECTED",
  EMPS_ADDED_FOR_SAL_MK_MONTH = "EMPS_ADDED_FOR_SAL_MK_MONTH",
  EMPS_NOT_ADDED_FOR_SAL_MK_MONTH = "EMPS_NOT_ADDED_FOR_SAL_MK_MONTH",
  EMPS_WITH_ASSIGNED_LEAVES = "EMPS_WITH_ASSIGNED_LEAVES",
  EMPS_WITH_UNASSIGNED_LEAVES = "EMPS_WITH_UNASSIGNED_LEAVES",
  JUST_LOGIN_ACCTS = "JUST_LOGIN_ACCTS",
}
export interface PayRollEmpDetails {
  id: number;
  cal_month: string;
  att_total_days_present: number;
  att_total_days_absent_full: number;
  att_total_days_absent_half: number;
  att_total_days_absent: number;
  day_2: string;
  day_3: string;
  day_4: string;
  day_1: string;
  day_5: string;
  day_6: string;
  day_7: string;
  day_8: string;
  day_9: string;
  day_10: string;
  day_11: string;
  day_12: string;
  day_13: string;
  day_14: string;
  day_15: string;
  day_16: string;
  day_17: string;
  day_18: string;
  day_19: string;
  day_20: string;
  day_21: string;
  day_22: string;
  day_23: string;
  day_24: string;
  day_25: string;
  day_26: string;
  day_27: string;
  day_28: string;
  day_29: string;
  day_30: string;
  day_31: string;
  pr_emp_id: number;
  pr_acd_yr_id: number;
  pr_cal_month_id: number;
  emp_details: EmpDetails;
  isChecked: boolean;
  leave_ledger_id: number;
}
export interface PayRollEmpData {
  GetPayRollEmpAttendance: PayRoleEmpList;
}
export interface PayRollEmpVars {
  name: string;
  departmentId: number | null;
  designationId: number | null;
  categoryId: number | null;
  gradeId: number | null;
  jobTypeId: number | null;
  employeeId: number | null;
  after: string | null;
  sortBy: string;
  direction: string;
  first: number;
  token: string;
  input: input;
}
const useEmployeeAttsByMonth = (
  rowsPerPage: number | null,
  queryType: PrEmpAttQueryType,
  month: string,
  name: string,
  pr_acd_yr_id: number,
  date_of_att?: string
) => {
  const { token } = useToken();
  const { InstId } = useParams();

  const [GetEmployees, { data, loading, error, fetchMore }] = useLazyQuery<
    PayRollEmpData,
    PayRoleEmpvars
  >(GetPayRollEmpAttendance, {
    variables: {
      after: null,
      direction: Direction.ASC,
      first: rowsPerPage,
      pr_acd_yr_id,
      input: {
        inst_id: InstId!,
        att_query_type: queryType,
        cal_month: toIsoDate(month),
        date_of_attendance: date_of_att
          ? toIsoDate(date_of_att)
          : toIsoDate(TODAY_DATE),
      },
      name,
      sortBy: SortBy.TOTAL_DAYS,
      token,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token && pr_acd_yr_id) {
      GetEmployees();
    }
  }, [GetEmployees, token, queryType, InstId, pr_acd_yr_id]);
  return { empDetails: { data, loading, error, fetchMore } };
};

export default useEmployeeAttsByMonth;

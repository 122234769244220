import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { TableHeaderProps } from "../../../Types/Tables";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import { useParams } from "react-router-dom";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  PredefinedDataTypes,
  ReceiptTypes,
  ReturnType,
  SortBy,
  StudentAcctReportType,
  VoucherBookKey,
} from "../../../utils/Enum.types";
import {
  AcctStudentType,
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../common/Enum.types";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  AcctLedgerQueryType,
  DepositLedgerQueryType,
} from "../common/QueryTypes";
import useVoucherNumber from "../hooks/useVoucherNumber";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import {
  DateRange,
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { Keys } from "../../../utils/Enum.keys";
import { AddAcctVoucherMasterAndDetails } from "../queries/receipts/mutation/Index";
import { useMutation } from "@apollo/client";
import { AppContext } from "../../../context/context";
import {
  GetAcctVoucherAnonymous,
  GetAcctVouchers,
  GetVoucherNumber,
} from "../queries/Vouchers/query";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../queries/students/list/byId";
import useToken from "../../../customhooks/useToken";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { payloadTypes } from "../../../context/reducer";
import MessageModal from "../../../pages/MessageModal";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import LoadingModal from "../../../pages/LoadingModal";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import useUploadComponent from "../../../customhooks/useUploadComponent";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import AddReconciledForm from "./AddReconciledForm";
import dayjs from "dayjs";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../Types/Accounting/mutations";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";

interface recepitsw {
  acct_ldgr_id: number;
}
interface Props {
  studentType:
    | StudentAcctReportType.SOCIAL_WELFARE_STUDENT
    | StudentAcctReportType.ANONYMOUS_STUDENT;
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const vouchers: optionsType[] = [
  { label: "Agent", value: StudentAcctReportType.AGENT_STUDENT },
  {
    label: "Social Welfare",
    value: StudentAcctReportType.SOCIAL_WELFARE_STUDENT,
  },
];
const AssignNewReceipt = ({ studentType, pageType, setModalFlag }: Props) => {
  let DefaultDate = new Date();

  const { Accounts_Table } = useAcctTableJson();

  const dates = DateRange(DefaultDate.toString());
  const { token } = useToken();

  const { InstId } = useParams<{ InstId: string }>();
  const { dispatch, state } = useContext(AppContext);

  const [receipts, setReceipts] = useState<recepitsw[]>([]);
  const [acctLedgerId, setAcctLedgerId] = useState<responseType | null>(null);
  const [bankTransactionType, setBankTransactionType] =
    useState<optionsType | null>(null);
  const [narration, setNarration] = useState("");
  const [admNo, setAdmNo] = useState("");

  const [amount, setAmount] = useState(0);
  const [rcptDate, setRcptDate] = useState("");

  const [queryType, setQueryType] = useState(
    StudentAcctReportType.SOCIAL_WELFARE_STUDENT
  );

  const [referenceNumber, setReferenceNumber] = useState("");
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feeDescRef);
  const amountref = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const { user_details } = useLoggedInUserDetails();
  const { InstDetails } = useInstDetails(1);
  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_ADVANCE_RECEIPT_BOOK
  );
  const { component, uploadFile, file } = useUploadComponent(
    `${InstDetails.data?.nodes[0]?.inst_name}/socialwelfare/${
      voucherNumber.data ? voucherNumber.data.GetVoucherNumber.vo_number : ""
    }`,
    Operation.CREATE,
    EMPTY_STRING,
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE
  );

  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();

  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
    };
  };
  const { editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );

  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    studentType === StudentAcctReportType.ANONYMOUS_STUDENT
      ? studentType
      : queryType
  );

  const { studentData } = useStudentDatabyId();

  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING,
    ReturnType.WITH_ID
  );

  const { acctLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    studentType === StudentAcctReportType.SOCIAL_WELFARE_STUDENT
      ? AcctLedgerQueryType.DEPOSIT_FEE_LDGRS
      : AcctLedgerQueryType.SUSPENSE_DEPOSIT,
    ROWS_PER_PAGE
  );

  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    if (pageType === PageFor.MODAL) {
      setModalFlag(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setAmount(0);
    setTransactionLedgerId(null);
    setBankTransactionType(null);
    setReferenceNumber("");
    setNarration("");
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: { studentId: 0 },
    });
  };
  const handleReceipts = () => {
    if (transactionBookLedgerId)
      setReceipts((items) => [
        ...items,
        {
          acct_ldgr_id: transactionBookLedgerId.value,
        },
      ]);
  };

  const handleStudentReceipts = async (e: React.FormEvent) => {
    e.preventDefault();
    if (acctLedgerId === null) {
      alert("Account Ledger Id Required");
      return;
    }
    const res = bankTransactionTypes.find(
      ({ value }) => bankTransactionType && value === bankTransactionType.value
    );
    try {
      const uploadResult = await uploadFile();
      if (
        transactionBookLedgerId &&
        ((uploadResult && file) || file === null)
      ) {
        GenerateStudentReceipts({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input: [
              {
                acct_voucher_master: {
                  fin_yr: state.ActiveFinYr
                    ? state.ActiveFinYr.fin_yr
                    : EMPTY_STRING,
                  v_type: ReceiptTypes.STUDENT_RECEIPT,
                  v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
                  v_date: toIsoDate(rcptDate),
                  v_total_cr_amt: amount,
                  v_total_db_amt: amount,
                  v_reconciled: false,
                  v_reconciled_date: toIsoDate(DEFAULT_TIME),
                  v_std_receipt: true,
                  v_std_non_demand_receipt: true,
                  v_std_refund: false,
                  v_std_anonymous_deposit_adjusted: false,

                  v_std_enquiry: false,
                  v_std_receipt_ob: false,
                  enquiry_student_id: 0,
                  v_std_deposit_adjusted: false,
                  v_book_type: VoucherBookKey.STUDENT_ADVANCE_RECEIPT_BOOK,
                  v_std_deposit: true,
                  v_std_passout_receipt: false,
                  v_std_demand_receipt: false,
                  v_std_scholarship_deposit: false,

                  student_id: state.studentId,
                  v_std_refund_deposit: false,
                  v_std_receipt_anonymous: true,

                  class_id: studentData.data?.nodes[0].class.id,
                  semester_id: studentData.data?.nodes[0].semester.id,
                  v_std_amt_receipt: amount,
                  v_std_amt_deposit: amount,
                  v_std_amt_fine: 0,
                  v_std_amt_total: amount,
                  v_std_amt_refunded: 0,
                  v_std_amt_adjusted: 0,
                  v_transcation_type: res?.label,
                  v_transcation_cash_or_bank: BankOrCash.BANK,
                  v_transcation_no: referenceNumber,
                  v_transcation_date: toIsoDate(DEFAULT_TIME),
                  v_transcation_narration: narration,
                  // paid_party_id: "",
                  // party_bill_no: "",
                  party_bill_date: toIsoDate(DEFAULT_TIME),
                  // party_name: "",
                  annx_yesno: false,
                  // annx_id: Math.random() * 1000,
                  is_vouch_multi_entry: false,
                  acct_ldgr_id_cr: acctLedgerId.value,
                  acct_ldgr_id_db: receipts[0].acct_ldgr_id,
                },
                acct_voucher_db: receipts.map((receipt, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db: amount,
                  vo_cr_total: 0,
                  vo_db_total: amount,
                  acct_ldgr_id: receipt.acct_ldgr_id,
                })),
                acct_voucher_cr: [
                  {
                    vo_sl_no: 1,
                    vo_cr_db: DebitOrCredit.CREDIT,
                    vo_cr: amount,
                    vo_db: 0,
                    vo_cr_total: amount,
                    vo_db_total: 0,
                    acct_ldgr_id: acctLedgerId.value,
                  },
                ],
              },
            ],
          },
          refetchQueries: [
            {
              query: GetAcctVouchers,
              variables: {
                after: null,
                direction: Direction.ASC,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                first: ROWS_PER_PAGE,
                sortBy: SortBy.V_DATE,
                token,
                name: EMPTY_STRING,
                deposit: null,
                vTranscationCashOrBank: null,
                partyName: EMPTY_STRING,
                vTranscationNo: EMPTY_STRING,
                vTranscationNarration: EMPTY_STRING,
                ldgrDesc: EMPTY_STRING,
                amount: null,
                input: {
                  inst_id: InstId!,
                  voucher_query_type: VoucherQueryTypes.STUDENT_ALL_RECEIPTS,
                  vo_end_date: toIsoDate(dates?.lastDay!),
                  acct_ldgr_id: state.accountLedgerId,
                  vo_start_date: toIsoDate(dates?.firstDay!),
                  vo_type: EMPTY_STRING,
                },
              },
            },
            {
              query: GetAcctVoucherAnonymous,
              variables: {
                after: null,
                anonymous_std_type: AcctStudentType.SocialWelfare,
                direction: Direction.DESC,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                first: ROWS_PER_PAGE,
                sortBy: SortBy.CREATED_AT,
                token,
                vTranscationNo: EMPTY_STRING,
                inst_id: InstId!,
                start_date: dates ? dayjs(dates.firstDay).format() : "",
                end_date: dates ? dayjs(dates.lastDay).format() : "",
              },
            },
            {
              query: GetAcctStdDeposits,
              variables: {
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
                student_id: state.studentId,
                token,
              },
            },
            {
              query: GetStdCompleteFeeDetailsByStudentID,
              variables: {
                token,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                inst_id: InstId!,
                student_id: state.studentId,
              },
            },
            {
              query: GetVoucherNumber,
              variables: {
                vo_book_key: VoucherBookKey.STUDENT_ADVANCE_RECEIPT_BOOK,
                token,
                inst_id: InstId!,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: `Successfully generated receipt`,
              flag: true,
              operation: Operation.CREATE,
            });
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (transactionBookLedgerId && transactionBookLedgerId.value > 0) {
      handleReceipts();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);

  useEffect(() => {
    if (
      studentAddmissionNumber.length &&
      studentType === StudentAcctReportType.ANONYMOUS_STUDENT
    ) {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: { studentId: studentAddmissionNumber[0].value },
      });
    }
  }, [studentAddmissionNumber, studentType, dispatch]);
  useEffect(() => {
    if (
      acctLedgers.data &&
      !acctLedgers.loading &&
      studentType === StudentAcctReportType.ANONYMOUS_STUDENT
    ) {
      const { ldgr_desc, id } = acctLedgers.data.GetAcctLdgrs.edges[0].node;
      setAcctLedgerId({ label: ldgr_desc, value: id, isChecked: true });
    }
  }, [acctLedgers.data, acctLedgers.loading, studentType]);
  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Add Unreconciled Fee Receipt</Title>
      <div className="unknown-bank-trans__add">
        <div className="unknown-bank-trans__add--button row g-0">
          {studentType !== StudentAcctReportType.ANONYMOUS_STUDENT && (
            <>
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={vouchers}
                  value={vouchers.find(({ value }) => value === queryType)}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                      setAdmNo("");
                    }
                  }}
                  openOnFocus
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setQueryType(
                        (newValue as optionsType).value as StudentAcctReportType
                      );
                    } else {
                      setQueryType(
                        StudentAcctReportType.SOCIAL_WELFARE_STUDENT
                      );
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                      setAdmNo("");
                    }
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setAdmNo(e.target.value)}
                      id="outlined-uncontrolled"
                      label="Social Welfare / Agent"
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={studentAddmissionNumber}
                  value={
                    studentAddmissionNumber.find(
                      ({ value }) => value === state.studentId
                    ) ?? null
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                      setAdmNo("");
                    }
                  }}
                  openOnFocus
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setAdmNo(e.target.value)}
                      id="outlined-uncontrolled"
                      label={`${
                        vouchers.find(({ value }) => value === queryType)?.label
                      }`}
                      autoFocus
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            </>
          )}
          <div className="col"></div>
          <div className="col-2">
            <TextField
              label="Date of Receipt"
              type="date"
              value={toInputStandardDate(rcptDate)}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              onChange={(e) => setRcptDate(e.target.value)}
              disabled={editDate ? false : true}
              className="unknown-bank-trans__select--textfield"
            />
          </div>
        </div>
        <div
          className={`unknown-bank-trans__add--tableblock${
            pageType === PageFor.MODAL ? "--modal" : ""
          }`}
        >
          <TableContainer className="unknown-bank-trans__add--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.UnknownBankTransaction.Add.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="unknown-bank-trans__add--table--row">
                  <TableCell
                    className="unknown-bank-trans__add--table--slno"
                    id="td-center"
                  >
                    1
                  </TableCell>
                  <TableCell>
                    {studentType ===
                    StudentAcctReportType.SOCIAL_WELFARE_STUDENT ? (
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        value={acctLedgerId}
                        options={acctLedgers.responseType}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            amountref.current?.select();
                            amountref.current?.focus();
                          } else if (e.key === Keys.BACKSPACE) {
                            setAcctLedgerId(null);
                          }
                        }}
                        openOnFocus
                        forcePopupIcon
                        autoHighlight
                        ref={feeDescRef}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setAcctLedgerId(newValue as responseType);
                          } else {
                            setAcctLedgerId(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                    ) : (
                      acctLedgers.responseType[0]?.label
                    )}
                  </TableCell>
                  <TableCell className="unknown-bank-trans__add--table--amount">
                    <Input
                      value={amount}
                      autoFocus
                      inputRef={amountref}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER && amount) {
                          e.preventDefault();
                          feeDescInputRef.select();
                          feeDescInputRef.focus();
                        }
                      }}
                      onChange={(e) => setAmount(Number(e.target.value))}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <AddReconciledForm
          methods={{
            handleClear,
            handleStudentReceipts,
          }}
          pageType={pageType}
          refs={{
            feeDescRef,
            saveButtonRef,
          }}
          setStates={{
            setBankTransactionType,
            setModalFlag,
            setNarration,
            setReceipts,
            setTransactionLedgerId,
          }}
          states={{
            amount,
            bankTransactionType,
            GenerateStudentReceiptsLoading,
            message,
            narration,
            transactionBookLedgerId,
            vo_number: voucherNumber.data
              ? voucherNumber.data.GetVoucherNumber.vo_number
              : EMPTY_STRING,
          }}
          uploadComponent={component}
        />
        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />
        <LoadingModal flag={GenerateStudentReceiptsLoading} />
      </div>
    </>
  );
};

export default AssignNewReceipt;

import React, { useEffect, useState } from "react";
import Home from "../../Master/Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import AddContent from "./AddContent";
import { DocumentAcknowledgement, Operation, PageFor } from "../../../utils/Enum.types";
import Configurations from "./Configurations";
import Document from "./Document";

import { GetInstDocumentContent, MstInstDocumentMainByNodeId } from "./queries";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { GetDocumentDataDetails, GetDocumentNameVars } from "./Types";
import { TemplateViewDrawer } from "../../../styles/DrawerStyles";
interface props {
  pageType: PageFor;
  selectedId?: number;
  selectedStudents?: number[];
  setStudentIdSet?: React.Dispatch<React.SetStateAction<Set<number>>>;
}

const GenerateDocuments = ({
  pageType,
  selectedId,
  selectedStudents,
  setStudentIdSet,
}: props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId, documentId } = useParams();
  const [content, setContent] = useState("");
  const [createContent, setCreateContent] = useState(false);
  const [updateContent, setUpdateContent] = useState(false);

  const [GetInstDocumentContentData, { data: contentData }] = useLazyQuery(
    GetInstDocumentContent,
    {
      variables: {
        token,
        inst_id: InstId!,
        document_id: selectedId ? selectedId : Number(documentId),
      },
    }
  );
  const [DocumentName, { data }] = useLazyQuery<
    GetDocumentDataDetails,
    GetDocumentNameVars
  >(MstInstDocumentMainByNodeId, {
    variables: {
      id: selectedId ? selectedId : Number(documentId),
      token,
    },
  });
  useEffect(() => {
    if (pageType === PageFor.GENERAL) {
      if (token && documentId) {
        GetInstDocumentContentData();
      }
    } else {
      if (token && selectedId) {
        GetInstDocumentContentData();
      }
    }
  }, [pageType, token, GetInstDocumentContentData, documentId, selectedId]);

  useEffect(() => {
    if (pageType === PageFor.GENERAL) {
      if (token && InstId && documentId) {
        DocumentName();
      }
    } else {
      if (token && InstId && selectedId) {
        DocumentName();
      }
    }
  }, [token, InstId, DocumentName, documentId, selectedId]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>{data ? data.node.document_name : ""}</Title>

      <div className="document-layout">
        <div className="document-layout__frame">
          <div className="document-layout__frame--left">
            <Document
              selectedId={Number(selectedId)}
              selectedStudents={selectedStudents ? selectedStudents : []}
              setStudentIdSet={setStudentIdSet!}
            />
          </div>

          <div className="document-layout__frame--right">
            <Configurations pageType={DocumentAcknowledgement.GENERAL} />
          </div>
        </div>

        <>
          {contentData && contentData?.GetInstDocumentContent.length >= 1 ? (
            <>
              <Button
                mode="update"
                onClick={() => {
                  setUpdateContent(!updateContent);
                  setContent(contentData.GetInstDocumentContent);
                }}
              >
                &nbsp;Content
              </Button>
            </>
          ) : (
            <>
              <Button
                mode="create"
                onClick={() => setCreateContent(!createContent)}
              >
                &nbsp;Content
              </Button>
            </>
          )}

          <Button mode="back" onClick={() => navigate(-1)} />
        </>
      </div>
      <TemplateViewDrawer
        anchor="right"
        open={createContent}
        onClose={() => setCreateContent(!createContent)}
  
      >
        <AddContent
          setModalFlag={setCreateContent}
          operation={Operation.CREATE}
          content={content}
        />
      </TemplateViewDrawer>
      <TemplateViewDrawer
        anchor="right"
        open={updateContent}
        onClose={() => setCreateContent(!updateContent)}
  
      >
        <AddContent
          setModalFlag={setUpdateContent}
          operation={Operation.UPDATE}
          content={content}
        />
      </TemplateViewDrawer>
    </>
  );
};

export default GenerateDocuments;

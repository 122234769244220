import React, { useState } from "react";
import More from "../../../../images/More.svg";
import TotalTests from "../../../../images/TotalTestPlanned.svg";
import MarksEntryCompleted from "../../../../images/Attendance_Marked.svg";
import MarksEntryNotCompleted from "../../../../images/Attendance_Not_Marked.svg";
import { Title } from "../../../../stories/Title/Title";
import Modal from "react-modal";
import MarksEntryModal from "./Modal/MarksEntry";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
const MarksEntry = () => {
  const [marksEntryModal, setMarksEntryModal] = useState(false);
  return (
    <>
      <div className="teacher-dashboard__flex">
        <b className="teacher-dashboard__flex--title">Marks Entry Details</b>
        <div className="teacher-dashboard__flex--image">
          <button
            className="teacher-dashboard__flex--mark"
            onClick={() => setMarksEntryModal(!marksEntryModal)}
          >
            Mark
          </button>
          <img src={More} alt="" />
        </div>
      </div>
      <div className="teacher-dashboard__details">
        <div className="teacher-dashboard__details--flex">
          <div className="teacher-dashboard__details--image-flex">
            <img src={TotalTests} alt="" />
            <Title variant="subtitle2">Total Test Conducted</Title>
          </div>
          <b className="font-blue">0</b>
        </div>
        <div className="teacher-dashboard__details--flex">
          <div className="teacher-dashboard__details--image-flex">
            <img src={MarksEntryCompleted} alt="" />
            <Title variant="subtitle2">Marks Entry Completed</Title>
          </div>
          <b className="font-green">0</b>
        </div>
        <div className="teacher-dashboard__details--flex">
          <div className="teacher-dashboard__details--image-flex">
            <img src={MarksEntryNotCompleted} alt="" />
            <Title variant="subtitle2">Marks Entry Not completed</Title>
          </div>
          <b className="font-blue">0</b>
        </div>
      </div>
      <Modal
        isOpen={marksEntryModal}
        onRequestClose={() => setMarksEntryModal(!marksEntryModal)}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <MarksEntryModal setModalFlag={setMarksEntryModal} />
      </Modal>
    </>
  );
};

export default MarksEntry;

import React, { useContext, useEffect, useState } from "react";
import Input from "../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Compare from "../../../images/Compare.svg";
import { Button } from "../../../stories/Button/Button";
import Avatar from "../../../images/Avatar.svg";
import { Direction } from "../../../utils/Enum.types";
import { Title } from "../../../stories/Title/Title";
import useLogsTableJson from "../json/useLogsTableJson";
import { TableHeaderProps } from "../../../Types/Tables";

import { VoucherQueryTypes } from "../../Accounts/common/Enum.types";
import { EMPTY_STRING } from "../../../utils/constants";
import {
  toInputStandardDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Titles } from "../ModuleWiseDetails/Accounts";

import { useLazyQuery } from "@apollo/client";
import { GetDeletedAcctVouchersForStudents } from "../../Accounts/queries/Vouchers/query";
import {
  GetDeletedAcctVouchersData,
  GetDeletedAcctVouchersVars,
} from "../../../Types/Accounting";
import useToken from "../../../customhooks/useToken";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userid: number;
  endDate: string;
  startDate: string;
  empName: string;
  titleName: string;
}
const DeletedVoucherDetailsForStudents = ({
  setModal,
  userid,
  endDate,
  startDate,
  empName,
  titleName,
}: Props) => {
  const { Logs_Table } = useLogsTableJson();
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [name, setname] = useState("");

  let queryType: string;
  switch (titleName) {
    case Titles.Student_Receipt_entries:
      queryType = VoucherQueryTypes.DELETED_STD_RECEIPT_USER_LOG;
      break;
    case Titles.Refund_Fee:
      queryType = VoucherQueryTypes.DELETED_STD_REFUND_USER_LOG;
      break;

    default:
      queryType = VoucherQueryTypes.DELETED_STD_RECEIPT_USER_LOG;
      break;
  }
  const { user_details } = useLoggedInUserDetails();
  const [GetDeletedVouchers, { data: DeletedVouchers }] = useLazyQuery<
    GetDeletedAcctVouchersData,
    GetDeletedAcctVouchersVars
  >(GetDeletedAcctVouchersForStudents);

  useEffect(() => {
    if (userid && token && state.ActiveFinYr) {
      GetDeletedVouchers({
        variables: {
          after: null,
          direction: Direction.ASC,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          first: 200,
          input: {
            query_type: queryType,
            vo_start_date: dayjs(startDate).format(),
            vo_end_date: dayjs(endDate).format(),
            user_details,
          },
          inst_id: InstId!,
          name,
          sortBy: "V_DATE",
          token,
        },
      });
    }
  }, [
    state.ActiveFinYr,
    userid,
    startDate,
    endDate,
    GetDeletedVouchers,
    InstId,
    token,
    name,
    queryType,
  ]);

  return (
    <>
      <Title>{`Deleted ${titleName} Details`}</Title>
      <div className="emp-logs__filtered-data">
        <div className="emp-logs__filtered-data--select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>

          <div className="col-4">
            <div className="emp-logs__filtered-data--select--flex">
              <TextField
                value={toInputStandardDate(startDate)}
                label="Start Date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className="emp-logs__filtered-data--textfield"
              />
              <img src={Compare} alt="" />
              <TextField
                value={endDate}
                label="End Date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className="emp-logs__filtered-data--textfield"
              />
            </div>
          </div>
          <div className="col"></div>
          <div className="col-3">
            <div className="emp-logs__filtered-data--profile">
              <img src={Avatar} alt="" />
              <div className="emp-logs__filtered-data--profile--details">
                <span>{empName}</span>
                {/* <b>Manager</b> */}
              </div>
            </div>
          </div>
        </div>
        <div className="emp-logs__filtered-data--tableblock">
          <TableContainer className="emp-logs__filtered-data--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Logs_Table.FeeReceiptDetails.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {DeletedVouchers &&
                  DeletedVouchers.GetDeletedAcctVouchers.edges.map(
                    (res, index) => {
                      const utcTimestamp = new Date(res.node.created_at);
                      return (
                        <TableRow>
                          <TableCell
                            className="emp-logs__filtered-data--table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--number">
                            {res.node.v_no}
                          </TableCell>
                          <TableCell
                            className="emp-logs__filtered-data--table--date"
                            id="td-center"
                          >
                            {toStandardDate(res.node.v_date)}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--number">
                            {res.node.mst_student.std_adm_no}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--number">
                            {res.node.mst_student.std_reg_no}
                          </TableCell>
                          <TableCell>
                            {res.node.mst_student.first_name +
                              " " +
                              res.node.mst_student.middle_name +
                              " " +
                              res.node.mst_student.last_name}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--desc">
                            {res.node.mst_student.branch.branch_desc}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--desc">
                            {res.node.mst_student.class.class_desc}
                          </TableCell>
                          <TableCell
                            className="emp-logs__filtered-data--table--amount"
                            id="td-right"
                          >
                            {res.node.v_std_amt_total}
                          </TableCell>
                          <TableCell>
                            {res.node.v_transcation_cash_or_bank === "C"
                              ? "CASH"
                              : res.node.v_transcation_cash_or_bank === "B"
                              ? "BANK"
                              : EMPTY_STRING}
                          </TableCell>
                          <TableCell
                            className="emp-logs__filtered-data--table--date"
                            id="td-center"
                          >
                            {utcTimestamp.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default DeletedVoucherDetailsForStudents;

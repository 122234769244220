import React, { useEffect } from "react";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { useLazyQuery } from "@apollo/client";
import {
  CollapseIcon,
  CustomTreeItem,
  EndIcon,
  ExpandIcon,

} from "../../../pages/TreeView";
import General from "../../../images/General.svg";
import Bus from "../../../images/Transport.svg";
import Hostel from "../../../images/Hostel.svg";

import { GetAcctStdFeeLdgrsByInstIdByType } from "../queries/FeeLedgers/query/Byid";
import { useParams } from "react-router";
import useToken from "../../../customhooks/useToken";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useBillbooks from "../hooks/useBillbooks";
import { InstitutionAcctConfigurationTypes } from "../common/Enum.types";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
interface LdgrDesc {
  id: number;
  ldgr_desc: string;
}
interface GetAcctStdFeeLdgrsByInstIdByTypeData {
  GetAcctStdFeeLdgrsByInstIdByType: {
    general: LdgrDesc[];
    transport: LdgrDesc[];
    hostel: LdgrDesc[];
  };
}
const FeeledgerTreeView = () => {
  const { InstId } = useParams();

  const { configData: useSameBill } = useSwConfigData(
    InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS
  );
  const { token } = useToken();
  const [GetFeeledgerTree, { data }] =
    useLazyQuery<GetAcctStdFeeLdgrsByInstIdByTypeData>(
      GetAcctStdFeeLdgrsByInstIdByType,
      {
        variables: { token, inst_id: InstId! },
      }
    );
  const { voucherBooks } = useBillbooks();

  const { general, transport, hostel } =
    data?.GetAcctStdFeeLdgrsByInstIdByType || {};

  useEffect(() => {
    if (token && InstId) {
      GetFeeledgerTree();
    }
  }, [token, InstId, GetFeeledgerTree]);
  return (
    <>
      <div className="tree-view">
        {useSameBill.data?.GetSwConfigVariables[0].config_boolean_value ? (
          <SimpleTreeView
            className="departmenttreeview"
            defaultExpandedItems={["1"]}
            slots={{
              expandIcon: ExpandIcon,
              collapseIcon: CollapseIcon,
              endIcon: EndIcon,
            }}
          >
            {voucherBooks.data?.GetStdVoucherBooks.map((book) => {
              return (
                <CustomTreeItem
                  itemId={book.id.toString()}
                  label={book.vo_book_desc}
                >
                  {book.ldgr_details?.map((detail) => {
                    return (
                      <CustomTreeItem
                        itemId={detail.id.toString()}
                        label={detail.ldgr_desc}
                      />
                    );
                  })}
                </CustomTreeItem>
              );
            })}
          </SimpleTreeView>
        ) : (
          <SimpleTreeView
            className="departmenttreeview"
            defaultExpandedItems={["1"]}
            slots={{
              expandIcon: ExpandIcon,
              collapseIcon: CollapseIcon,
              endIcon: EndIcon,
            }}
          >
            <CustomTreeItem
              itemId="1"
              label={
                <span>
                  <img src={General} alt="/" />
                  General
                </span>
              }
            >
              {general &&
                general.map(({ id, ldgr_desc }) => {
                  return (
                    <React.Fragment key={id}>
                      <CustomTreeItem
                        label={ldgr_desc}
                        itemId={id.toString()}
                      />
                    </React.Fragment>
                  );
                })}
            </CustomTreeItem>
            <CustomTreeItem
              itemId="2"
              label={
                <span>
                  <img src={Bus} alt="/" className="transport-icon" />
                  Transport
                </span>
              }
            >
              {transport &&
                transport.map(({ id, ldgr_desc }) => {
                  return (
                    <React.Fragment key={id}>
                      <CustomTreeItem
                        itemId={id.toString()}
                        label={ldgr_desc}
                      />
                    </React.Fragment>
                  );
                })}
            </CustomTreeItem>
            <CustomTreeItem
              itemId="3"
              label={
                <span>
                  <img src={Hostel} alt="/" />
                  Hostel
                </span>
              }
            >
              {hostel &&
                hostel.map(({ id, ldgr_desc }) => {
                  return (
                    <React.Fragment key={id}>
                      <CustomTreeItem
                        itemId={id.toString()}
                        label={ldgr_desc}
                      />
                    </React.Fragment>
                  );
                })}
            </CustomTreeItem>
          </SimpleTreeView>
        )}
      </div>
    </>
  );
};

export default FeeledgerTreeView;

import { gql } from "@apollo/client";

export const AddAcdSubjectMaster = gql`
  mutation AddAcdSubjectMaster(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateAcdSubjectMasterInput!
    $acd_yr_id: ID!
  ) {
    AddAcdSubjectMaster(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;

export const UpdateAcdSubjectMaster = gql`
  mutation UpdateAcdSubjectMaster(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateAcdSubjectMasterInput!
  ) {
    UpdateAcdSubjectMaster(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const DeleteAcdSubjectMasterById = gql`
  mutation DeleteAcdSubjectMasterById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdSubjectMasterById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const AddOrDeleteAcdSubjectClassAllotedDetails = gql`
  mutation AddOrDeleteAcdSubjectClassAllotedDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: AcdSubjectClassAlloted!
  ) {
    AddOrDeleteAcdSubjectClassAllotedDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;
export const UpdateAcdSubjectClassAllotedDetails = gql`
  mutation UpdateAcdSubjectClassAllotedDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: AcdSubjectClassAlloted!
  ) {
    UpdateAcdSubjectClassAllotedDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;
export const AddAcdSubjectClassAllotedElectives = gql`
  mutation AddAcdSubjectClassAllotedElectives(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: AssociElectiveSubjects!
  ) {
    AddAcdSubjectClassAllotedElectives(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;

export const AddElectiveSubjForStds = gql`
  mutation AddElectiveSubjForStds(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $main_subj_master_id: ID!
    $elective_subj_master_id: ID!
    $student_ids: [ID!]!
    $main_subj_sl: Int!
    $allotted_level: String!
    $allotted_id: ID!
  ) {
    AddElectiveSubjForStds(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      main_subj_master_id: $main_subj_master_id
      elective_subj_master_id: $elective_subj_master_id
      student_ids: $student_ids
      main_subj_sl: $main_subj_sl
      allotted_level: $allotted_level
      allotted_id: $allotted_id
    )
  }
`;
export const DeleteAcdSubjectClassAllotedDetails = gql`
  mutation DeleteAcdSubjectClassAllotedDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $alloted_id: ID!
    $alloted_level: String!
  ) {
    DeleteAcdSubjectClassAllotedDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      alloted_id: $alloted_id
      alloted_level: $alloted_level
    )
  }
`;

export const DeleteAcdSubjectTeacherAssoci = gql`
  mutation DeleteAcdSubjectTeacherAssoci(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $subj_master_id: ID!
    $pr_emp_ids: [ID!]!
  ) {
    DeleteAcdSubjectTeacherAssoci(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      subj_master_id: $subj_master_id
      pr_emp_ids: $pr_emp_ids
    )
  }
`;

import useInstLabels from "../../../customhooks/general/useInstLabels";
import {
  AMOUNT_TEXT_ALIGN,
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../../../styles/DataGridTableStyles";

const useAcctTableJson = () => {
  const { branchLabel, classLabel, departmentLabel,categoryLabel } = useInstLabels();
  const tableJson = {
    Accounts_Table: {
      AccountLedger: {
        Delete: [
          {
            labelName: "Sl",
            className: "delecte-account-ledger__table--th-slno",
          },
          { labelName: "Ledger Description" },
          {
            labelName: "Actions",
            className: "delete-account-ledger__table--actions",
          },
        ],
        Reorder: [
          {
            labelName: "Sl",
          },
          {
            labelName: "Description",
          },
        ],
      },
      GroupLedger: {
        Reorder: [
          {
            labelName: "Sl",
          },
          {
            labelName: "Description",
          },
        ],
      },
      Dashboard: {
        StudentList: [
          { labelName: `${departmentLabel} Name` },
          { labelName: "Boys" },
          { labelName: "Girls" },
          { labelName: "Total" },
        ],
      },
      FeeDemand: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "feedemand__table--th-slno",
          },
          { labelName: "Fee Description" },
          { labelName: "Due Date", className: "feedemand__table--th-amount" },
          {
            labelName: "Fee (All)",
            className: "feedemand__table--th-amount",
          },
          {
            labelName: "Fee (Fresher)",
            className: "feedemand__table--th-amount",
          },
          {
            labelName: "Fee(Existing)",
            className: "feedemand__table--th-amount",
          },
          { labelName: "Actions", className: "feedemand__table--actions" },
        ],
        CopyDemand: {
          Table_Headers: [
            { labelName: "Sl", className: "copy-demand__table--th-slno" },
            { labelName: "Fee Description" },
            {
              labelName: "Due Date",
              className: "copy-demand__table--th-date",
            },
            { labelName: "Fee All", className: "copy-demand__table--th-fee" },
            {
              labelName: "Fee Fresher",
              className: "copy-demand__table--th-fee",
            },
            {
              labelName: "Fee Existing",
              className: "copy-demand__table--th-fee",
            },
          ],
        },

        ViewFeeDemand: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "view-feedemand__table--pink--th-slno",
            },
            {
              labelName: "Fee Description",
            },
            {
              labelName: "Fee",
              className: "view-feedemand__table--pink--th-fee",
            },
            {
              labelName: "Due Date",
              className: "view-feedemand__table--pink--th-fee",
            },
            {
              labelName: "Total Amount",
              className: "view-feedemand__table--pink--th-fee",
            },
          ],
        },

        RaiseStudentDemand: {
          Table_Headers: [
            { labelName: "Sl", className: "student-demand__table--th-slno" },
            {
              labelName: "Adm No.",
              className: "student-demand__table--th-amt",
            },
            {
              labelName: "Student Name",
              className: "student-demand__table--desc",
            },
            {
              labelName: `${classLabel}`,
              className: "student-demand__table--desc",
            },
            {
              labelName: "Fresher",
              className: "student-demand__table--status",
            },
            {
              labelName: `${categoryLabel}`,
              className: "student-demand__table--desc",
            },
            {
              labelName: "Demand Amount to be Raised",
              className: "student-demand__table--th-amt",
            },
          ],
        },
        DemandFeePayable: {
          Table_Headers: [
            { labelName: "Sl", className: "demand-fee__table--th-slno" },
            { labelName: "Fee Description" },
            { labelName: "OB", className: "demand-fee__table--th-amount" },
            {
              labelName: "Demand",
              className: "demand-fee__table--th-amount",
            },
            {
              labelName: "Concession",
              className: "demand-fee__table--th-amount",
            },
            {
              labelName: "Receivable",
              className: "demand-fee__table--th-amount",
            },
            {
              labelName: "Received",
              className: "demand-fee__table--th-amount",
            },
            {
              labelName: "Balance",
              className: "demand-fee__table--th-amount",
            },
            { labelName: "Actions", className: "demand-fee__table--actions" },
          ],
        },

        DeleteFeeDemand: {
          Table_Headers: [
            {
              labelName: "Sl",
            },
            {
              labelName: "Adm No.",
              className: "feedemand__delete--table--th-regno",
            },
            {
              labelName: "Reg. No.",
              className: "feedemand__delete--table--th-regno",
            },
            { labelName: "Student Name" },
            {
              labelName: "Demand Amount",
              className: "feedemand__delete--table--th-amt",
            },
            {
              labelName: "Demand Balance",
              className: "feedemand__delete--table--th-amt",
            },
          ],
        },

        DeleteParticularFeeDemand: {
          Table_Headers: [
            { labelName: "Sl" },
            {
              labelName: "Adm. No.",
              className: "feedemand__delete--table--th-regno",
            },
            {
              labelName: "Reg. No.",
              className: "feedemand__delete--table--th-regno",
            },
            { labelName: "Student Name" },
            {
              labelName: `${categoryLabel}`,
              className: "feedemand__delete--table--desc",
            },
            {
              labelName: "Year",
              className: "feedemand__delete--table--desc",
            },
            {
              labelName: "Fee",
              className: "feedemand__delete--table--th-amt",
            },
          ],
        },

        AdditionalFee: {
          Group: {
            Table_Headers: [
              {
                labelName: "Sl",
                className:
                  "feedemand-additionalfee-group__tableblock--table--th-slno",
              },
              {
                labelName: "Admission No.",
                className:
                  "feedemand-additionalfee-group__tableblock--table--th-admno",
              },
              {
                labelName: "Register No.",
                className:
                  "feedemand-additionalfee-group__tableblock--table--th-regno",
              },
              { labelName: "Name" },
              {
                labelName: "Demand Amount",
                className:
                  "feedemand-additionalfee-group__tableblock--table--year",
              },
              {
                labelName: "Added Demand",
                className:
                  "feedemand-additionalfee-group__tableblock--table--category",
              },

              {
                labelName: "New Demand",
                className:
                  "feedemand-additionalfee-group__tableblock--table--category",
              },
              {
                labelName: "Actions",
                className:
                  "feedemand-additionalfee-group__tableblock--table--year",
              },
            ],
          },
          Individual: {
            Table_Headers: [
              {
                labelName: "Sl",
                className:
                  "feedemand-additionalfee-individual__tableblock--table--th-slno",
              },
              { labelName: "Fee Description" },
              {
                labelName: "OB",
                className:
                  "feedemand-additionalfee-individual__tableblock--table--th-amount",
              },
              {
                labelName: "Demand",
                className:
                  "feedemand-additionalfee-individual__tableblock--table--th-amount",
              },
            ],
          },
        },
        ArrearFee: {
          Table_Headers: [
            {
              labelName: "Sl",
              className:
                "feedemand-arrearfee-individual__tableblock--table--th-slno",
            },
            { labelName: "Fee Description" },
            {
              labelName: "OB",
              className:
                "feedemand-arrearfee-individual__tableblock--table--th-amount",
            },
            {
              labelName: "Demand",
              className:
                "feedemand-arrearfee-individual__tableblock--table--th-amount",
            },
          ],
        },
        DemandSummary: {
          Table_Headers: [
            {
              labelName: "Student's",
              className: "demand-summary__tableblock--table--th-number",
            },
            {
              labelName: "Not Eligible",
              className: "demand-summary__tableblock--table--th-number",
            },
            {
              labelName: "TC",
              className: "demand-summary__tableblock--table--th-number",
            },
            {
              labelName: "OB",
              className: "demand-summary__tableblock--table--th-amount",
            },
            {
              labelName: "Demand",
              className: "demand-summary__tableblock--table--th-amount",
            },
            {
              labelName: "Concession",
              className: "demand-summary__tableblock--table--th-amount",
            },
            {
              labelName: "Total",
              className: "demand-summary__tableblock--table--th-amount",
            },
          ],
          CompleteRecord: {
            Table_Headers: [
              {
                labelName: "Student Name",
                className: "demand-summary__tableblock--table--th-number",
              },
              {
                labelName: "Fresher",
                className: "demand-summary__tableblock--table--th-number",
              },
              {
                labelName: "Status",
                className: "demand-summary__tableblock--table--th-status",
              },
              {
                labelName: "OB",
                className: "demand-summary__tableblock--table--th-amount",
              },
              {
                labelName: "Demand",
                className: "demand-summary__tableblock--table--th-amount",
              },
              {
                labelName: "Concession",
                className: "demand-summary__tableblock--table--th-amount",
              },
              {
                labelName: "Total",
                className: "demand-summary__tableblock--table--th-amount",
              },
            ],
          },
        },
      },
      Receipts: {
        StudentFeeReceipt: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "student-fee-receipt__table--th-slno",
              type: true,
            },
            { labelName: "Particulars", type: true, className: "" },
            {
              labelName: "Due-Date",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },

            {
              labelName: "OB",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Demand",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Concession",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Receivable",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Received",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Receipt Amt.",
              className: "student-fee-receipt__table--th-amount",
              type: true,
            },
            {
              labelName: "Balance",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
          ],
          Table_Headers_Challan: [
            {
              labelName: "Sl",
              className: "student-fee-receipt__table--th-slno",
              type: true,
            },
            { labelName: "Particulars", type: true, className: "" },

            {
              labelName: "Receipt Amt.",
              className: "student-fee-receipt__table--th-amount",
              type: true,
            },
          ],
          WaiveOff: [
            {
              labelName: "Sl",
              className: "student-fee-receipt__waive-off--table--th-slno",
            },
            { labelName: "Particulars" },
            {
              labelName: "Due Date",
              className: "student-fee-receipt__waive-off--table--th-date",
            },
            {
              labelName: "Fine",
              className: "student-fee-receipt__waive-off--table--th-amount",
            },
          ],
        },
        StudentObFeeReceipt: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "student-fee-receipt__table--th-slno",
              type: true,
            },
            { labelName: "Particulars", type: true, className: "" },
            {
              labelName: "OB",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Concession",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Receivable",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Received",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
            {
              labelName: "Receipt Amt.",
              className: "student-fee-receipt__table--th-amount",
              type: true,
            },
            {
              labelName: "Balance",
              className: "student-fee-receipt__table--th-amount",
              type: false,
            },
          ],
          Table_Headers_Challan: [
            {
              labelName: "Sl",
              className: "student-fee-receipt__table--th-slno",
              type: true,
            },
            { labelName: "Particulars", type: true, className: "" },

            {
              labelName: "Receipt Amt.",
              className: "student-fee-receipt__table--th-amount",
              type: true,
            },
          ],
          WaiveOff: [
            {
              labelName: "Sl",
              className: "student-fee-receipt__waive-off--table--th-slno",
            },
            { labelName: "Particulars" },
            {
              labelName: "Due Date",
              className: "student-fee-receipt__waive-off--table--th-date",
            },
            {
              labelName: "Fine",
              className: "student-fee-receipt__waive-off--table--th-amount",
            },
          ],
        },
        ApplicationFee: {
          Table_Headers: [
            { labelName: "Sl" },
            {
              labelName: "Receipt No.",
            },
            { labelName: "Name" },
            {
              labelName: "Year/Course",
            },
            {
              labelName: "Payment",
            },
          ],
        },
        EnquiryFeeReceipt: {
          Table_Headers: [
            { labelName: "Sl" },
            {
              labelName: "Ledger Name",
              className: "applicationfee__table--desc",
            },
            {
              labelName: "Amount",
            },
            {
              labelName: "Actions",
            },
          ],
        },
        InterChangeReceipt: {
          CurrentReceiptDetails: {
            Table_Headers: [
              {
                labelName: "Sl",
                className: "interchange-fee-receipt__table--th-slno",
              },
              { labelName: "Fee Description" },
              {
                labelName: "Paid",
                className: "interchange-fee-receipt__table--th-amount",
              },
            ],
          },
          ChangedReceiptDetails: {
            Table_Headers: [
              {
                labelName: "Sl",
                className: "interchange-fee-receipt__table--th-slno",
              },
              { labelName: "Particulars" },
              {
                labelName: "Demand",
                className: "interchange-fee-receipt__table--th-amount",
              },
              {
                labelName: "Receivable",
                className: "interchange-fee-receipt__table--th-amount",
              },
              {
                labelName: "Received",
                className: "interchange-fee-receipt__table--th-amount",
              },
              {
                labelName: "Rcpt Amt.",
                className: "interchange-fee-receipt__table--th-amount",
              },
              {
                labelName: "Balance",
                className: "interchange-fee-receipt__table--th-amount",
              },
            ],
          },

          StudentDemandReceipts: {
            Table_Headers: [
              {
                labelName: "Sl",
                className: "student-demand-receipt__table--th-slno",
              },
              {
                labelName: "Date",
                className: "student-demand-receipt__table--th-date",
              },
              {
                labelName: "Receipt No.",
                className: "student-demand-receipt__table--th-rcptno",
              },
              { labelName: "Name" },

              {
                labelName: "Adm Number",
                className: "student-demand-receipt__table--th-admno",
              },
              {
                labelName: "Parent Name",
                className: "student-demand-receipt__table--parent",
              },
              {
                labelName: "Reg No.",
                className: "student-demand-receipt__table--th-regno",
              },
              {
                labelName: "Amount",
                className: "student-demand-receipt__table--th-amount",
              },
            ],
          },

          ViewStudentInterChangeReceipt: {
            Table_Headers: [
              {
                labelName: "Sl",
                className: "view-interchange-fee-receipt__table--th-slno",
              },
              {
                labelName: "Receipt Date",
                className: "view-interchange-fee-receipt__table--date",
              },
              {
                labelName: "Receipt No.",
                className: "view-interchange-fee-receipt__table--th-receipt",
              },
              {
                labelName: "Adm No.",
                className: "view-interchange-fee-receipt__table--th-receipt",
              },
              { labelName: "Name" },
              {
                labelName: `${classLabel}`,
                className: "view-interchange-fee-receipt__table--class",
              },
              {
                labelName: "Adm No.",
                className: "view-interchange-fee-receipt__table--th-receipt",
              },
              { labelName: "Name" },
              {
                labelName: `${classLabel}`,
                className: "view-interchange-fee-receipt__table--class",
              },
              {
                labelName: "Receipt Amount",
                className: "view-interchange-fee-receipt__table--th-receipt",
              },
            ],
            ColumnGroup: [
              { labelName: "From Student Details" },
              { labelName: "To Student Details" },
            ],
          },
        },
        OB: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "non-demandfee-payable__table--th-slno",
            },
            {
              labelName: "Fee Description",
              className: "non-demandfee-payable__table--desc",
            },
            {
              labelName: "OB",
              className: "non-demandfee-payable__table--th-balance",
            },
            {
              labelName: "Actions",
              className: "non-demandfee-payable__table--actions",
            },
          ],
        },
        NonDemand: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "non-demandfee-payable__table--th-slno",
            },
            {
              labelName: "Fee Description",
              className: "non-demandfee-payable__table--desc",
            },
            {
              labelName: "Fee",
              className: "non-demandfee-payable__table--th-balance",
            },
            {
              labelName: "Actions",
              className: "non-demandfee-payable__table--actions",
            },
          ],
        },
      },
      RefundAcademicFee: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "refund-academic-fee__table--th-slno",
          },
          {
            labelName: "Particulars",
          },
          {
            labelName: "Demand",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Received",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Refunded",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Refundable",
            className: "refund-academic-fee__table--th-fee",
          },
        ],
      },
      FeeConcession: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "refund-academic-fee__table--th-slno",
          },
          {
            labelName: "Particulars",
          },
          {
            labelName: "OB",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Concession",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Demand",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Receivable",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Received",
            className: "refund-academic-fee__table--th-fee",
          },

          {
            labelName: "Refunded",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Balance",
            className: "refund-academic-fee__table--th-fee",
          },

          {
            labelName: "Concession Amount",
            className: "refund-academic-fee__table--th-fee",
          },
        ],
      },
      StudentCompleteFeeReceipt: {
        DemandFeePayable: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "std-complete-fee-receipt__frame--table--th-slno",
            },
            { labelName: "Particulars" },
            {
              labelName: "OB",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Demand",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Concession",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Receivable",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Received",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Refunds",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Balance",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
          ],
        },
        Receipts: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "std-complete-fee-receipt__frame--table--th-slno",
            },
            {
              labelName: "Rcpt No.",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },

            {
              labelName: "Date",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },

            {
              labelName: "Voucher Type",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Fine",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposits",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Demand",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "NonDemand",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Total",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
          ],
        },
        FeeAdvance: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "std-complete-fee-receipt__frame--table--th-slno",
            },
            { labelName: "Particulars" },
            {
              labelName: "OB",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposits",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Total",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Adjusted",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Refunds",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Balance",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
          ],
        },
        Refund: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "std-complete-fee-receipt__frame--table--th-slno",
            },
            {
              labelName: "Receipt No.",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Date",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Refunded",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
          ],
        },
        Consolidated: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "std-complete-fee-receipt__frame--table--th-slno",
            },
            {
              labelName: "OB",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Demand",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Concession",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Receivable",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Received",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Refunded",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Balance",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit OB",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Amount",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Total",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Adjusted",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Refunded",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Balance",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Non Demand ",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
          ],
        },
        Demand: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "std-complete-fee-receipt__frame--table--th-slno",
            },
            {
              labelName: "OB",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Demand",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Concession",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Receivable",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Received",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Refunded",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Balance",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit OB",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Amount",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Total",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Adjusted",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Refunded",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Deposit Balance",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
            {
              labelName: "Non Demand ",
              className: "std-complete-fee-receipt__frame--table--th-amount",
            },
          ],
        },
      },
      PaymentVoucher: {
        Table_Headers: [
          { labelName: "", className: "payment-voucher__table--select" },
          { labelName: "Particulars" },
          {
            labelName: "Debit",
            className: "payment-voucher__table--th-amount",
          },
          {
            labelName: "Credit",
            className: "payment-voucher__table--th-amount",
          },
          {
            labelName: "Actions",
            className: "payment-voucher__table--th-actions",
          },
        ],
      },
      DepositRefund: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "refund-academic-fee__table--th-slno",
          },

          {
            labelName: "Particulars",
          },
          {
            labelName: "Deposit OB",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: " Deposit Amount",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: " Deposit Received",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: " Deposit Adjusted",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: " Deposit Refunded",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: " Deposit Balance",
            className: "refund-academic-fee__table--th-fee",
          },
          {
            labelName: "Refundable",
            className: "refund-academic-fee__table--th-fee",
          },
        ],
      },
      ViewRefundedReceipts: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            headerClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Receipt No.",
            cellClassName: "td-adm_no",
            headerClassName: "td-adm_no",
            field: "receipt_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Date",
            cellClassName: "td-date",
            headerClassName: "td-date",
            field: "date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Adm No.",
            cellClassName: "td-adm_no",
            headerClassName: "td-adm_no",
            field: "admission_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },

          {
            headerName: "Name",
            cellClassName: "td-onClick td-name",
            headerClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Amount",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "amount",
            headerAlign: HEADER_TEXT_ALIGN,
            align: AMOUNT_TEXT_ALIGN,
          },
        ],
      },
      Concession: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            headerClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Concession No.",
            cellClassName: "td-adm-no",
            headerClassName: "td-adm-no",
            field: "concession_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Date",
            cellClassName: "td-date",
            headerClassName: "td-date",
            field: "date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Adm no.",
            cellClassName: "td-adm-no",
            headerClassName: "td-adm-no",
            field: "adm_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Reg No.",
            cellClassName: "td-reg-no",
            headerClassName: "td-reg-no",
            field: "reg_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },

          {
            headerName: "Name",
            cellClassName: "td-name",
            headerClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },

          {
            headerName: "Concession",
            cellClassName: "td-name",
            headerClassName: "td-name",
            field: "concession",
            headerAlign: HEADER_TEXT_ALIGN,
            align: AMOUNT_TEXT_ALIGN,
          },

          {
            headerName: "Authorized By",
            cellClassName: "td-desc",
            headerClassName: "td-desc",
            field: "authorized_by",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Remarks",
            cellClassName: "td-desc",
            headerClassName: "td-desc",
            field: "remarks",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
        ],
      },
      ConcessionGivenForLedgers: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            headerClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Particulars",
            cellClassName: "td-name",
            headerClassName: "td-name",
            field: "particulars",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },

          {
            headerName: "Amount",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "amount",
            headerAlign: HEADER_TEXT_ALIGN,
            align: AMOUNT_TEXT_ALIGN,
          },
        ],
      },
      AdvanceFeeDepositList: {
        Table_Headers: [
          { labelName: "Sl", className: "advance-fee-list__table--th-slno" },
          {
            labelName: "Adm No.",
            className: "advance-fee-list__table--th-admno",
          },
          {
            labelName: "Reg No.",
            className: "advance-fee-list__table--th-admno",
          },
          { labelName: "Name" },
          {
            labelName: `${branchLabel}`,
            className: "advance-fee-list__table--desc",
          },
          {
            labelName: `${classLabel}`,
            className: "advance-fee-list__table--desc",
          },
          { labelName: "Ledger", className: "advance-fee-list__table--desc" },
          {
            labelName: "Deposit OB",
            className: "advance-fee-list__table--th-amount",
          },
          {
            labelName: "Deposit Amount",
            className: "advance-fee-list__table--th-amount",
          },
          {
            labelName: "Total",
            className: "advance-fee-list__table--th-amount",
          },
          {
            labelName: "Adjusted Fee",
            className: "advance-fee-list__table--th-amount",
          },
          {
            labelName: "Refunds",
            className: "advance-fee-list__table--th-amount",
          },
          {
            labelName: "Balance",
            className: "advance-fee-list__table--th-amount",
          },
        ],
      },
      DeleteDemandFeeReceipt: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "delete-demand-fee-receipt__table--th-slno",
          },
          {
            labelName: "Receipt No.",
            className: "delete-demand-fee-receipt__table--th-vno",
          },
          { labelName: "Student Name" },
          {
            labelName: "Amount",
            className: "delete-demand-fee-receipt__table--th-amount",
          },
          {
            labelName: "Actions",
            className: "delete-demand-fee-receipt__table--actions",
          },
        ],
      },

      Reports: {
        AccountLedgerBooks: {
          YearlyTotal: {
            Title: [{ labelName: "Yearly Total" }],
            Table_Headers: [
              { labelName: "Year" },
              { labelName: "Open Balance" },
              { labelName: "Debit" },
              { labelName: "Credit" },
              { labelName: "Closing Balance" },
            ],
          },
          YearlyTotalRegister: {
            Title: [{ labelName: "Yearly Total" }],
            Table_Headers: [
              { labelName: "Year" },
              { labelName: "No. of Transactions" },
              { labelName: "Debit" },
              { labelName: "Credit" },
            ],
          },
          MonthlyTotal: {
            Title: [{ labelName: "Monthly Total" }],

            Table_Headers: [
              {
                labelName: "Month",
                className:
                  "bank-book__table-section--monthly-total--table--th-month",
              },
              {
                labelName: "Debit",
                className:
                  "bank-book__table-section--monthly-total--table--th-amount",
              },
              {
                labelName: "Credit",
                className:
                  "bank-book__table-section--monthly-total--table--th-amount",
              },
              {
                labelName: "Balance",
                className:
                  "bank-book__table-section--monthly-total--table--th-amount",
              },
            ],
          },
          MonthlyTotalRegister: {
            Title: [{ labelName: "Monthly Total" }],

            Table_Headers: [
              {
                labelName: "Month",
                className:
                  "bank-book__table-section--monthly-total--table--th-month",
              },
              { labelName: "No. of Transactions" },
              {
                labelName: "Debit",
                className:
                  "bank-book__table-section--monthly-total--table--th-amount",
              },
              {
                labelName: "Credit",
                className:
                  "bank-book__table-section--monthly-total--table--th-amount",
              },
            ],
          },
          LedgerTransactionDetails: {
            Title: [{ labelName: "Ledger Transaction Details" }],
            Table_Headers: [
              {
                labelName: "Sl",
              },
              {
                labelName: "Date",
              },
              {
                labelName: "Voucher No.",
              },
              {
                labelName: "Particulars",
              },
              {
                labelName: "Voucher Types",
              },

              {
                labelName: "Debit",
              },
              {
                labelName: "Credit",
              },
            ],
          },
        },
        FeeBalanceReports: {
          Table_Headers: [
            {
              headerName: "Sl",
              cellClassName: "td-sl-no",
              headerClassName: "td-sl-no",
              field: "id",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Adm No.",
              cellClassName: "td-adm-no",
              headerClassName: "td-adm-no",
              field: "adm_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Reg No.",
              cellClassName: "td-adm-no",
              headerClassName: "td-adm-no",
              field: "reg_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Name",
              cellClassName: "td-name",
              headerClassName: "td-name",
              field: "name",
              headerAlign: HEADER_TEXT_ALIGN,
              flex: 1,
            },
            {
              headerName: "Mobile",
              cellClassName: "td-mobile",
              headerClassName: "td-mobile",
              field: "mobile",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Status",
              cellClassName: "td-status",
              headerClassName: "td-status",
              field: "status",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: `${categoryLabel}`,
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "cat",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "OB",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "ob",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },

            {
              headerName: "Demand",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "demand",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },

            {
              headerName: "Concession",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "concession",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
            {
              headerName: "Receivable",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "receivable",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },

            {
              headerName: "Received",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "received",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },

            {
              headerName: "Refunds",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "refunds",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },

            {
              headerName: "Balance",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "balance",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
          ],
        },
        LedgerSummary: {
          Table_Headers: [
            {
              headerName: "Sl",
              cellClassName: "td-sl-no",
              headerClassName: "td-sl-no",
              field: "id",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Ledger Description",
              cellClassName: "td-name",

              field: "ledger_desc",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "",
              flex: 1,
            },
            {
              headerName: "OB",
              cellClassName: "td-amount",
              field: "ob",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "right",
              headerClassName: "td-amount",
            },
            {
              headerName: "Demand",
              cellClassName: "td-amount",
              field: "demand_amount",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "right",
              headerClassName: "td-amount",
            },

            {
              headerName: "Concession",
              cellClassName: "td-amount",
              field: "concession",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "right",
              headerClassName: "td-amount",
            },
            {
              headerName: "Receivable",
              cellClassName: "td-amount",
              field: "receivable",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "right",
              headerClassName: "td-amount",
            },
            {
              headerName: "Received",
              cellClassName: "td-amount",
              field: "received",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "right",
              headerClassName: "td-amount",
            },

            {
              headerName: "Refunds",
              cellClassName: "td-amount",
              field: "refunds",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "right",
              headerClassName: "td-amount",
            },
            {
              headerName: "Balance",
              cellClassName: "td-amount",
              field: "balance",
              headerAlign: HEADER_TEXT_ALIGN,
              align: "right",
              headerClassName: "td-amount",
            },
          ],
        },
        ReceiptAndPaymentAccount: {
          Table_Headers: [
            { labelName: "Description" },
            {
              labelName: "Amount",
              className:
                "receipt-payment-account__tableblock--table--th-amount",
            },
            {
              labelName: "Total",
              className:
                "receipt-payment-account__tableblock--table--th-amount",
            },
          ],
        },
        TrialBalance: {
          Table_Headers: [
            { labelName: "Particulars" },
            {
              labelName: "OB",
              className: "trial-balance__tableblock--table--th-amount",
            },
            {
              labelName: "Debit",
              className: "trial-balance__tableblock--table--th-amount",
            },
            {
              labelName: "Credit",
              className: "trial-balance__tableblock--table--th-amount",
            },
            {
              labelName: "Closing Balance",
              className: "trial-balance__tableblock--table--th-amount",
            },
            {
              labelName: "Debit/Credit",
              className: "trial-balance__tableblock--table--status",
            },
          ],
        },
        FeeReceiptBook: {
          Table_Headers: [
            {
              headerName: "Sl",
              cellClassName: "td-sl-no",
              headerClassName: "td-sl-no",
              field: "id",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Date",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "date",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Receipt No.",
              cellClassName: "td-adm_no",
              headerClassName: "td-adm_no",
              field: "receipt_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Adm No.",
              cellClassName: "td-adm_no",
              headerClassName: "td-adm_no",
              field: "admission_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Reg No.",
              cellClassName: "td-adm_no",
              headerClassName: "td-adm_no",
              field: "reg_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Name",
              cellClassName: "td-onClick td-name",
              headerClassName: "td-name",
              field: "name",
              headerAlign: HEADER_TEXT_ALIGN,
              flex: 1,
            },

            {
              headerName: "Received From",
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "received_from",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Receipt Amt.",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "receipt_amt",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
          ],
        },
        PaymentVoucherRegister: {
          Table_Headers: [
            {
              labelName: "Date",
              className: "payment-general-register__tableblock--table--th-date",
            },
            {
              labelName: "Voucher No.",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            {
              labelName: "Particulars",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            {
              labelName: "Paid to",
              className:
                "payment-general-register__tableblock--table--th-paidto",
            },
            {
              labelName: "Bill No.",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            { labelName: "Narration" },
            {
              labelName: "Amount",
              className:
                "payment-general-register__tableblock--table--th-amount",
            },
            {
              labelName: "Cash/Bank",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            {
              labelName: "Cheque No.",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
          ],
        },
        GeneralReceiptVoucher: {
          Table_Headers: [
            {
              labelName: "Date",
              className: "payment-general-register__tableblock--table--th-date",
            },
            {
              labelName: "Voucher No.",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            {
              labelName: "Particulars",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            {
              labelName: "Received From",
              className:
                "payment-general-register__tableblock--table--th-paidto",
            },
            {
              labelName: "Bill No.",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            { labelName: "Narration" },
            {
              labelName: "Amount",
              className:
                "payment-general-register__tableblock--table--th-amount",
            },
            {
              labelName: "Cash / Bank.",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
            {
              labelName: "Cheque No.",
              className:
                "payment-general-register__tableblock--table--th-number",
            },
          ],
        },
        DayWiseCashBalanceReport: {
          Table_Headers: [
            {
              labelName: "Date",
              className: "cash-balance-report__table--th-date",
            },
            {
              labelName: "Imprest OB.",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "Fee Collected(Cash)",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "0' th Cash Receipt",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "Cash Withdrawal",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "Total Inflow (Cash)",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "Cash Payments",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "Cash Deposits",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "Total Outflow of Cash",
              className: "cash-balance-report__table--th-amount",
            },
            {
              labelName: "Impreset Cash Balance",
              className: "cash-balance-report__table--th-amount",
            },
          ],
        },
        DayClose: {
          Group1: {
            Table_Headers: [{ labelName: "Receipt Cash" }],
          },
          Group2: {
            Table_Headers: [{ labelName: "Imprest Cash" }],
          },
          ReceiptCash: {
            Table_Headers: [
              {
                labelName: "Sl",
                className: "cash-flow-details__table--th-slno",
              },
              { labelName: "Particulars" },
              {
                labelName: "InFlow",
                className: "cash-flow-details__table--th-amount",
              },
              {
                labelName: "Debit",
                className: "cash-flow-details__table--th-amount",
              },
            ],
          },
          ImprestCash: {
            Table_Headers: [
              {
                labelName: "Sl",
                className: "cash-flow-details__table--th-slno",
              },
              { labelName: "Particulars" },
              {
                labelName: "OutFlow",
                className: "cash-flow-details__table--th-amount",
              },
              {
                labelName: "Debit",
                className: "cash-flow-details__table--th-amount",
              },
            ],
          },
        },
        LedgerWiseStudentReport: {
          Table_Headers: [
            {
              labelName: "Sl",
            },
            {
              labelName: "Adm No.",
            },
            {
              labelName: "Reg No.",
            },
          
            {
              labelName: "Student Name",
            },
            {
              labelName: `${classLabel}`,
            },
            {
              labelName: "Father Name",
            },
            {
              labelName: "Mobile",
            },
            {
              labelName: "Ledger Name",
            },
            {
              labelName: "OB",
            },
            {
              labelName: "Demand Amount",
            },
            {
              labelName: "Concession",
            },

            {
              labelName: "Receivable",
            },
            {
              labelName: "Received",
            },
            {
              labelName: "Refunds",
            },
            {
              labelName: "Balance",
            },
          ],
        },
        DayWiseCollection: {
          Table_Headers: [
            {
              headerName: "Sl",
              cellClassName: "td-sl-no",
              headerClassName: "td-sl-no",
              field: "id",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Date",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "date",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Receipt No.",
              cellClassName: "td-adm-no",
              headerClassName: "td-adm-no",
              field: "receipt_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Adm No.",
              cellClassName: "td-adm-no",
              headerClassName: "td-adm-no",
              field: "adm_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Reg No.",
              cellClassName: "td-adm-no",
              headerClassName: "td-adm-no",
              field: "reg_no",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Name",
              cellClassName: "td-onClick td-name",
              headerClassName: "td-name",
              field: "name",
              headerAlign: HEADER_TEXT_ALIGN,
              flex: 1,
            },
            {
              headerName: "Received From",
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "received_from",
              headerAlign: HEADER_TEXT_ALIGN,
            },
          ],
        },
        FeeSummary: {
          FeeStructure: [
            {
              headerName: "Sl",
              cellClassName: "td-sl-no",
              headerClassName: "td-sl-no",
              field: "id",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Particulars",
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "particulars",
              headerAlign: HEADER_TEXT_ALIGN,
              flex: 1,
            },
            {
              headerName: "OB",
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "ob",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
              flex: 1,
            },
            {
              headerName: "Total Demand",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "demand",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
            {
              headerName: "Receivable",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "receivable",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
            {
              headerName: "Received",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "received",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
            {
              headerName: "Balance",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "balance",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
          ],
          Receipts: [
            {
              headerName: "Sl",
              cellClassName: "td-sl-no",
              headerClassName: "td-sl-no",
              field: "id",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Receipt No.",
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "receipt_no",
              headerAlign: HEADER_TEXT_ALIGN,
              flex: 1,
            },
            {
              headerName: "Date",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "date",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Voucher Type",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "v_type",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Fine",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "fine",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Deposits",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "deposits",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Demand",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "demand",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Non Demand",
              cellClassName: "td-date",
              headerClassName: "td-date",
              field: "nondemand",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
            {
              headerName: "Mode of Payment",
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "payment",
              headerAlign: HEADER_TEXT_ALIGN,
            },
            {
              headerName: "Receipt Amount",
              cellClassName: "td-amount",
              headerClassName: "td-amount",
              field: "amount",
              headerAlign: HEADER_TEXT_ALIGN,
              align: AMOUNT_TEXT_ALIGN,
            },
            {
              headerName: "Remarks",
              cellClassName: "td-desc",
              headerClassName: "td-desc",
              field: "remarks",
              headerAlign: HEADER_TEXT_ALIGN,
              align: SLNO_TEXT_ALIGN,
            },
          ],
        },
      },
      Fines: {
        Table_Headers: [
          { labelName: "Sl", className: "fine-slab__frame--table--th-slno" },
          { labelName: "Fine Name" },
          {
            labelName: "Actions",
            className: "fine-slab__frame--table--actions",
          },
        ],
      },

      UnknownBankTransaction: {
        Table_Headers: [
          { labelName: "Sl" },
          { labelName: "Date" },
          { labelName: "Reference No." },
          { labelName: "Receipt No." },
          { labelName: "Bank Account" },
          { labelName: "Transaction Type" },
          { labelName: " Received Amount" },
          { labelName: " Adjusted Amount" },
          { labelName: " Balance Amount" },

          { labelName: "Actions" },
        ],
        Add: [
          { labelName: "Sl" },
          { labelName: "Ledger" },
          { labelName: "Amount" },
        ],
        LinkedReceiptDetails: [
          { labelName: "Sl" },
          { labelName: "Date" },
          { labelName: "Receipt No." },
          { labelName: "Reference No." },
          { labelName: "Transaction Type" },
          { labelName: "Admission No." },
          { labelName: "Name" },
          { labelName: `${classLabel}` },
          { labelName: "Receipt Amt." },
        ],
        ChangedReceiptDetails: [
          {
            labelName: "Sl",
            className: "interchange-fee-receipt__table--th-slno",
          },
          { labelName: "Particulars" },
          {
            labelName: "Payable",
            className: "interchange-fee-receipt__table--th-amount",
          },

          {
            labelName: "Paid",
            className: "interchange-fee-receipt__table--th-amount",
          },
          {
            labelName: "Rcpt Amt.",
            className: "interchange-fee-receipt__table--th-amount",
          },
          {
            labelName: "Balance",
            className: "interchange-fee-receipt__table--th-amount",
          },
        ],
        AdjustMents: [
          { labelName: "Sl" },
          { labelName: "Fee Description" },
          { labelName: "Fee Opening Bal." },
          { labelName: "Demand" },
          {
            labelName: "Concession",
          },
          {
            labelName: "Receivable",
          },
          {
            labelName: "Received",
          },
          { labelName: "Receipt Amt." },
          { labelName: "Balance" },
        ],
      },
      ScholarshipDeposit: {
        Table_Headers: [
          { labelName: "Sl" },
          { labelName: "Date" },
          { labelName: "Receipt No." },
          { labelName: "Bank Account" },
          { labelName: "Transaction Type" },
          { labelName: "Received Amount" },
          { labelName: "Adjusted Amount" },
          { labelName: "Balance" },
          { labelName: "Actions" },
        ],
        AdjustedReceipts: {
          Table_Headers: [
            { labelName: "Sl" },
            { labelName: "Receipt No." },
            { labelName: "Remarks" },
            { labelName: "Date" },
            { labelName: "Receipt No." },
            { labelName: "Admission No." },
            { labelName: "Name" },
            { labelName: `${classLabel}` },
            { labelName: "Adjusted Amount" },
          ],
        },
      },
      ProprietorDashboard: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            headerClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align:SLNO_TEXT_ALIGN
          },
          {
            headerName: "Institution Name",
            cellClassName: "td-name",
            headerClassName: "td-name",
            field: "inst_name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex:1
          },
          {
            headerName: "Bank Balance",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "bank_balance",
            headerAlign: HEADER_TEXT_ALIGN,
            align:AMOUNT_TEXT_ALIGN
          },
          {
            headerName: "Cash Balance",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "cash_balance",
            headerAlign: HEADER_TEXT_ALIGN,
            align:AMOUNT_TEXT_ALIGN
          },
          {
            headerName: "Demand",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "demand",
            headerAlign: HEADER_TEXT_ALIGN,
            align:AMOUNT_TEXT_ALIGN
          },
          {
            headerName: "Received",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "received",
            headerAlign: HEADER_TEXT_ALIGN,
            align:AMOUNT_TEXT_ALIGN
          },
          {
            headerName: "Demand Balance",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "demand_balance",
            headerAlign: HEADER_TEXT_ALIGN,
            align:AMOUNT_TEXT_ALIGN
          },
         
          
        ],
      },
      PurchaseEntry: {
        Masters: [
          { labelName: "Sl" },
          { labelName: "Description" },
          { labelName: "Actions" },
        ],
        Details: [
          { labelName: "Sl" },
          { labelName: "Description" },
          { labelName: "Group" },
          { labelName: "Category" },
          { labelName: "Unit of Measurement" },
          { labelName: "Item Type" },
          { labelName: "Actions" },
        ],
        Invoice: [
          { labelName: "Sl" },
          { labelName: "Item Name" },
          { labelName: "Unit of Measurement" },
          { labelName: "Quantity" },
          { labelName: "Rate" },
          { labelName: "Taxable Amount" },
          { labelName: "GST" },
          { labelName: "Tax" },
          { labelName: "Net" },
          { labelName: "Actions" },
        ],
        View: [
          { labelName: "Sl" },
          { labelName: "Invoice Date" },
          { labelName: "Invoice No" },
          { labelName: "Purchase Order No." },
          { labelName: "Purchase Order Date" },
          { labelName: "Supplier Name" },
          { labelName: "Total Items" },
          { labelName: "Quantity" },
          { labelName: "Tax" },
          { labelName: "Net" },
        ],
        LedgerList: [
          { labelName: "Sl" },
          { labelName: "Supplier Name" },
          { labelName: "Contact Person" },
          { labelName: "Mobile No" },
          { labelName: "Email ID" },
          { labelName: "Address" },
          { labelName: "GST No." },
          { labelName: "Type" },
        ],
        MakerCheckerList: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            headerClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Date",
            cellClassName: "td-date",
            headerClassName: "td-date",
            field: "date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Voucher No.",
            cellClassName: "td-adm-no",
            headerClassName: "td-adm-no",
            field: "voucher_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Amount",
            cellClassName: "td-amount",
            headerClassName: "td-amount",
            field: "amount",
            headerAlign: HEADER_TEXT_ALIGN,
            align: AMOUNT_TEXT_ALIGN,
          },
          {
            headerName: "Status",
            cellClassName: "td-onClick td-status",
            headerClassName: "td-status",
            field: "status",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Transaction Type",
            cellClassName: "td-desc",
            headerClassName: "td-desc",
            field: "transaction_type",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Remarks",
            cellClassName: "td-remarks",
            headerClassName: "td-remarks",
            field: "remarks",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
        ],
      },
      OnlineTransactionDetails: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            headerClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Transaction Date",
            cellClassName: "td-date",
            headerClassName: "td-date",
            field: "t_date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Bank Processed Date",
            cellClassName: "td-date",
            headerClassName: "td-date",
            field: "bank_processed_date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Unique Reference No.",
            cellClassName: "td-number",
            headerClassName: "td-number",
            field: "unique_ref_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "UUID",
            cellClassName: "td-number",
            headerClassName: "td-number",
            field: "uuid",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Reference No.",
            cellClassName: "td-number",
            headerClassName: "td-number",
            field: "ref_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Merchant ID",
            cellClassName: "td-number",
            headerClassName: "td-number",
            field: "merchant_id",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Payment Mode",
            cellClassName: "td-number",
            headerClassName: "td-number",
            field: "payment_mode",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Transaction Amount",
            cellClassName: "td-number",
            headerClassName: "td-number",
            field: "transaction_amount",
            headerAlign: HEADER_TEXT_ALIGN,
            align: AMOUNT_TEXT_ALIGN,
          },
          {
            headerName: "Total Amount (Trans+Chrgs)",
            cellClassName: "td-number",
            headerClassName: "td-number",
            field: "total_amount",
            headerAlign: HEADER_TEXT_ALIGN,
            align: AMOUNT_TEXT_ALIGN,
          },
          {
            headerName: "Status Code",
            cellClassName: "td-status font-green",
            headerClassName: "td-status",
            field: "status_code",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Message",
            cellClassName: "td-desc",
            headerClassName: "td-desc",
            field: "message",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Voucher No.",
            cellClassName: " td-onClick td-desc",
            headerClassName: "td-desc",
            field: "voucher_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      OnlineTransactionDetailsForLogs: {
        Table_Headers: [
          {
            labelName: "Sl",
          },
          { labelName: "Transaction Date" },
          { labelName: "Bank Processed Date" },
          { labelName: "Unique Reference No." },
          { labelName: "UUID" },
          { labelName: "Reference No." },
          { labelName: "Merchant ID" },
          { labelName: "Payment Mode" },
          { labelName: "Transaction Amount" },
          { labelName: "Total Amount   (Trans+Chrgs) " },
          { labelName: "Status Code" },
          { labelName: "Message" },
          { labelName: "Voucher No." },
          { labelName: "Transaction Status" },
          { labelName: "Action" },
        ],
      },
      OnlineFeePayMentInDashboard: [
        { labelName: "Fee Description" },
        { labelName: "Fee Opening Bal" },
        { labelName: "Demand" },
        { labelName: "Concession" },
        { labelName: "Paid" },
        { labelName: "Balance" },
      ],
      ChallanReceipts: [
        { labelName: "Sl" },
        { labelName: "Date" },
        { labelName: "Challan No." },
        { labelName: "Adm No." },
        { labelName: "Name" },
        { labelName: "Bank Account Name" },
        { labelName: "Receipt Type" },
        { labelName: "Challan Amount" },
        { labelName: "Reconciled Date" },
        { labelName: "Action" },
      ],
    },
  };
  return tableJson;
};

export default useAcctTableJson;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Modal from "react-modal";
import Home from "../../Home/Index";
import { FeeModalStyles } from "../../../../styles/ModalStyles";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { BankBookTitleProps } from "../../../../Types/Titles";
import { useNavigate } from "react-router-dom";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { AccountsTitles } = require("../../json/title.json");
const options = ["1", "2"];

const CashFlowDetails = () => {
  const [selectMonth, setSelectMonth] = useState(false);
  const [dayEndModal, setDayEndModal] = useState(false);
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.BankBook.Reports.Titles.map(
          (title: BankBookTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.Day_Close}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="row g-0 cash-balance-report">
        <div className="col">
          <div className="daily-fee-collected">
            <TextField
              type="date"
              className="daily-fee-collected__textfield"
              label="From Date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              defaultValue="2022-07-02"
            />
            <TextField
              type="date"
              className="daily-fee-collected__textfield"
              label="To Date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              defaultValue="2022-07-30"
            />
          </div>
        </div>
        <div className="col-3">
          <div className="cash-balance-report__finyr">
            <Label>Select Month</Label>
            &nbsp;
            <Input
              type="checkbox"
              onChange={() => setSelectMonth(!selectMonth)}
            />
          </div>
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={options}
            openOnFocus
            onChange={(e) => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Month"
                fullWidth
                className={labelClasses.formControlRoot}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            )}
          />
        </div>
      </div>
      <div className="row g-0 cash-flow-details__tableblock">
        <div className="col h-100">
          <TableContainer className="cash-flow-details__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow className="cash-flow-details__table--group-header">
                  {Accounts_Table.Reports.DayClose.Group1.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} align="center" colSpan={4}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
                <TableRow className="cash-flow-details__table--sub-header">
                  {Accounts_Table.Reports.DayClose.ReceiptCash.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="cash-flow-details__table--slno"
                    id="td-center"
                  >
                    1
                  </TableCell>
                  <TableCell>Receipt Cash OB</TableCell>
                  <TableCell
                    className="cash-flow-details__table--amount"
                    id="td-right"
                  >
                    20000
                  </TableCell>
                  <TableCell
                    className="cash-flow-details__table--amount"
                    id="td-right"
                  >
                    20000
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="col h-100">
          <TableContainer className="cash-flow-details__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow className="cash-flow-details__table--group-header">
                  {Accounts_Table.Reports.DayClose.Group2.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} align="center" colSpan={4}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
                <TableRow className="cash-flow-details__table--sub-header">
                  {Accounts_Table.Reports.DayClose.ImprestCash.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="cash-flow-details__table--slno"
                    id="td-center"
                  >
                    1
                  </TableCell>
                  <TableCell>Receipt Cash OB</TableCell>
                  <TableCell
                    className="cash-flow-details__table--amount"
                    id="td-right"
                  >
                    20000
                  </TableCell>
                  <TableCell
                    className="cash-flow-details__table--amount"
                    id="td-right"
                  >
                    20000
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="button-left">
        <Button mode="day-end" onClick={() => setDayEndModal(!dayEndModal)} />
        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      {/* day-end modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={dayEndModal}
        style={FeeModalStyles}
        ariaHideApp={false}
      >
        <Title>Change Date</Title>
        <div className="label-grid">
          <Label>Finacial Year</Label>
          <Input disabled value="2022-2023" />
          <Label>Present Date</Label>
          <Input disabled value="2022-03-10" />
          <Label>Change Date to</Label>
          <Input type="date" value="2022-03-10" />
        </div>
        <Button mode="save" />
        <Button mode="back" onClick={() => setDayEndModal(!dayEndModal)} />
      </Modal>
    </>
  );
};

export default CashFlowDetails;

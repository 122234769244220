import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DetailsNode } from "../../Modules/Transport/paginationTypes";

import { GetTransportRouteMasters } from "../../Modules/Transport/queries/list";
import { PageInfo } from "../../Types/Student/paginationTypes";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../utils/constants";
import { Direction, SortBy } from "../../utils/Enum.types";
import { responseType } from "../../utils/Form.types";
import useToken from "../useToken";
export interface RouteNodes {
  id: number;
  route_desc: string;
  route_no_stages: number;
  route_is_active: boolean;
  route_st_date: string;
  inst_id: number | string;
  route_details: DetailsNode[];
}

interface RouteEdge {
  node: RouteNodes;
  cursor: string;
}
interface GetTransportRouteMaster {
  totalCount: number;
  pageInfo: PageInfo;
  edges: RouteEdge[];
}
export interface GetTransportRouteMastersData {
  GetTransportRouteMasters: GetTransportRouteMaster;
}

export interface GetTransportRouteMastersVars {
  first: number;
  after: string | null;
  sortBy: string;
  direction: string;
  token: string;
  inst_id: number | string;
  name: string;
}

const useTransportRoutesData = (searchData: string, routeFlag: boolean) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [routesDropDown, setRoutesDropDown] = useState<responseType[]>([]);
  const [GetRoutes, { data, loading, error, fetchMore }] = useLazyQuery<
    GetTransportRouteMastersData,
    GetTransportRouteMastersVars
  >(GetTransportRouteMasters, {
    variables: {
      token,
      inst_id: InstId!,
      first: ROWS_PER_PAGE,
      sortBy: SortBy.ROUTE_DESC,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (InstId && token !== EMPTY_STRING) {
      GetRoutes().then(({ data }) => {
        if (data) {
          setRoutesDropDown(
            data.GetTransportRouteMasters.edges
              .filter(
                (route) =>
                  (route.node.route_details.length === 0 && routeFlag) ||
                  (route && routeFlag === false)
              )
              .map((route) => ({
                label: route.node.route_desc,
                value: route.node.id,
              }))
          );
        }
      });
    }
  }, [InstId, token, GetRoutes, routeFlag]);
  return { routesData: { data, loading, error, fetchMore, routesDropDown } };
};

export default useTransportRoutesData;

import React, { useContext, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { TEXT_FONT_FAMILY } from "../../../styles/DataGridTableStyles";
import { useLazyQuery } from "@apollo/client";
import { DashboardDetailsVars } from "./Index";
import { GetStudentEnquiryDashboardGraphMonthlyCounts } from "../../../queries/students/list/newApi";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import {
  convertToK,
  getDynamicIntervals,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../utils/constants";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
import { colorsInGraph } from "../../../styles/Constants";
export interface MonthlyCountProps {
  startDate: string;
  endDate: string;
}
interface GetStudentEnquiryDashboardGraphMonthlyCountsList {
  enq_student_count: number;
  month_name: string;
}
interface GetStudentEnquiryDashboardGraphMonthlyCountsData {
  GetStudentEnquiryDashboardGraphMonthlyCounts: GetStudentEnquiryDashboardGraphMonthlyCountsList[];
}
const MonthlyCount = ({ startDate, endDate }: MonthlyCountProps) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const pollIntervalTime = usePollIntervalTime();

  const [GetEnqStudentMonthlyCount, { data }] = useLazyQuery<
    GetStudentEnquiryDashboardGraphMonthlyCountsData,
    DashboardDetailsVars
  >(GetStudentEnquiryDashboardGraphMonthlyCounts, {
    variables: {
      token,
      inst_id: InstId!,
      today_date: toIsoDate(serverDate),
      enq_start_date: startDate ? toIsoDate(startDate) : EMPTY_STRING,
      enq_end_date: endDate ? toIsoDate(endDate) : EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (state.ActiveFinYr && serverDate !== EMPTY_STRING) {
      GetEnqStudentMonthlyCount();
    }
  }, [state.ActiveFinYr, GetEnqStudentMonthlyCount, serverDate, token]);

  const maxCount = Math.max(
    ...(data?.GetStudentEnquiryDashboardGraphMonthlyCounts ?? []).map(
      (res) => res.enq_student_count ?? 0
    )
  );

  const maxNumber = Math.ceil(maxCount / 100) * 100;
  const intervals = getDynamicIntervals(maxNumber);
  const maxAmountFromIntervals = Math.max(...intervals);
  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        crossStyle: {
          color: "#999",
        },
      },
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },

    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: true, type: "line" },
        saveAsImage: { show: true },
      },
    },

    legend: {
      textStyle: {
        color: "black",
        fontSize: 12,
        fontFamily: TEXT_FONT_FAMILY,
      },
      icon: "rect",
      bottom: 10,
      width: "90%",
      type: "scroll",
      data: data?.GetStudentEnquiryDashboardGraphMonthlyCounts.map(
        (res) => res.month_name
      ),
    },
    xAxis: [
      {
        textStyle: {
          color: "black",
          fontSize: 14,
          fontFamily: TEXT_FONT_FAMILY,
        },
        axisPointer: {
          type: "shadow",
        },
        type: "category",
        data: data?.GetStudentEnquiryDashboardGraphMonthlyCounts.map(
          (res) => res.month_name
        ),
      },
    ],
    yAxis: [
      {
        textStyle: {
          color: "black",
          fontSize: 10,
          fontFamily: TEXT_FONT_FAMILY,
        },
        type: "value",
        min: 0,
        max: maxAmountFromIntervals,
        interval: intervals[1] - intervals[0],
        axisLabel: {
          formatter: (value: number) => convertToK(value),
        },
      },
    ],
    series: [
      {
        data:
        data &&
        data?.GetStudentEnquiryDashboardGraphMonthlyCounts.map(
          (value, index) => ({
            value: value.enq_student_count,
            itemStyle: { color: colorsInGraph[index] },
          })
        ),
        type: "bar",
        showSymbol: false,
        lineStyle: {
          color: "#0891b2",
          width: 1,
        },
        areaStyle: {
          color: "#e0f2fe",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
      },
    ],
  };

  return (
    <>
      <ReactEcharts option={options} className="enquiry-dashboard__charts" />
    </>
  );
};

export default MonthlyCount;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  GetPayRollEmpLeaveMasterList,
  PayRollGradeNode,
  PayRollJobTypeNode,
} from "../Types/masterDataTypes";
import { pageInfo } from "../../../Types/pagination";
import { Direction, SortBy } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import { GetPayRollEmp } from "../queries/employee/query";
import {
  EmpCategoryDetailsFormType,
  EmpDepartmentDetailsFormType,
  EmpDesignationDetailsFormType,
  EmpOtherDetailsFormType,
} from "../Types/formTypes";
import { AppContext } from "../../../context/context";
import useCheckAllocationType from "../../Academics/hooks/useCheckAllocationType";
interface EmpPersonalDetails {
  emp_father_name: string;
  emp_mother_name: string;
  emp_spouse_name: string;
  emp_tel: string;
  emp_corr_address: string;
  emp_corr_pin: string;
  emp_perm_address: string;
  emp_perm_pin: string;
}
interface PersonalDetails extends EmpPersonalDetails {
  emp_blood_grp: string;
  emp_religion: string;
  emp_caste: string;
  emp_corr_city: string;
  emp_corr_district: string;
  emp_corr_state: string;
  emp_perm_city: string;
  emp_perm_district: string;
  emp_perm_state: string;
  emp_nationality: string;
  emp_martial: string;
}
export interface EmpDetails {
  id: number;
  emp_id: string;
  emp_type: string;
  emp_title: string;
  emp_first_name: string;
  emp_middle_name: string;
  emp_last_name: string;
  emp_sex: string;
  emp_dob: string;
  emp_mobile: string;
  emp_email: string;
  emp_qualification: string;
  emp_experience: number;
  emp_status: string;
  emp_on_roll: boolean;
  is_login_created: boolean;
  emp_profile_filename: string;
  emp_doj: string;
  emp_dor: string;
  emp_lsd: string;
  emp_idx: number;
  emp_dept_idx: number;
  auth_id: number;
  pr_grade_id: number;
  pr_job_type_id: number;
  pr_dept_id: number;
  pr_category_id: number;
  pr_designation_id: number;
  parent_designation_id: number;
  other_details: EmpOtherDetailsFormType;
  personal_details: PersonalDetails;
  pr_designation_details: EmpDesignationDetailsFormType;
  pr_dept_details: EmpDepartmentDetailsFormType;
  pr_category_details: EmpCategoryDetailsFormType;
  pr_job_type_details: PayRollJobTypeNode;
  pr_grade_details: PayRollGradeNode;
  reporting_to_emp_id: number;
  leave_master_details: GetPayRollEmpLeaveMasterList;
  isChecked: boolean;
  leave_ledger_id: number;
  academics_prof_details: {
    id: number;
    emp_teaching_experience: number;
    consultany_service: number;
    certification_no: number;
    research_papers_published: number;
    patents_no: number;
    books_Authored_no: number;
    workshops_no: number;
    projects_no: number;
    awards_no: number;
    resource_person: number;
    remarks: string;
  };
  reporting_manager_details: {
    id: number;
    emp_first_name: string;
    emp_middle_name: string;
    emp_last_name: string;
  };
}

export interface EmpData {
  node: EmpDetails;
}
export interface PayRollEmpEdges {
  node: EmpDetails;
}
export interface PayRoleEmpList {
  edges: PayRollEmpEdges[];
  pageInfo: pageInfo;
  totalCount: number;
}
export interface PayRoleEmpvars {
  name: string;
  per_std_subj_allocation: boolean | undefined;
  departmentId: number | null;
  designationId: number | null;
  categoryId: number | null;
  gradeId: number | null;
  jobTypeId: number | null;
  employeeId: number | null;
  after: string | null;
  sortBy: string;
  direction: string;
  first: number;
  token: string;
  input: input;
}
export interface PayRollEmpData {
  GetPayRollEmp: PayRoleEmpList;
}
interface input {
  pr_emp_query_type: PrEmpQueryType;
  ids: number[];
  emp_type: string[];
  entry_level: string;
  entry_id: number;
}
export enum empQueryTypes {
  GENERAL = "GENERAL",
  SAL_STR_ASSGINED = "EMP_SAL_STR_ASSGINED",
  SAL_STR_NOT_ASSGINED = "EMP_SAL_STR_NOT_ASSGINED",
  EMPS_ADDED_FOR_SAL_MK_MONTH = "EMPS_ADDED_FOR_SAL_MK_MONTH",
  EMPS_NOT_ADDED_FOR_SAL_MK_MONTH = "EMPS_NOT_ADDED_FOR_SAL_MK_MONTH",
  NOT_SELECTED = "NOT_SELECTED",
  EMPS_WITH_UNASSIGNED_LEAVES = "EMPS_WITH_UNASSIGNED_LEAVES",
  EMPS_WITH_ASSIGNED_LEAVES = "EMPS_WITH_ASSIGNED_LEAVES",
  JUST_LOGIN_ACCTS = "JUST_LOGIN_ACCTS",
  REPORTING_MANAGER = "REPORTING_MANAGER",
  EMP_BY_TYPE = "EMP_BY_TYPE",
  TEACHERS_UNASSIGNED_FOR_CLASS_SUBJECT = "TEACHERS_UNASSIGNED_FOR_CLASS_SUBJECT",
  TEACHERS_UNASSIGNED_FOR_SUBJECT = "TEACHERS_UNASSIGNED_FOR_SUBJECT",
  TEACHERS_ASSIGNED_FOR_SUBJECT = "TEACHERS_ASSIGNED_FOR_SUBJECT",
  TEACHERS_ASSIGNED_FOR_CLASS = "TEACHERS_ASSIGNED_FOR_CLASS",
  ALL_TEACHERS = "ALL_TEACHERS",
  ALL_TEACHERS_BY_FEEDBACK_FORM_ID = "ALL_TEACHERS_BY_FEEDBACK_FORM_ID",
  ALL_TEACHERS_ASSIGNED_FOR_CLASS = "ALL_TEACHERS_ASSIGNED_FOR_CLASS",
  EMP_BY_DESIGINATION_ID = "EMP_BY_DESIGINATION_ID",
}
export enum PrEmpQueryType {
  EMP_BY_INST_ID = "EMP_BY_INST_ID",
  EMP_SAL_STR_ASSGINED = "EMP_SAL_STR_ASSGINED",
  EMP_SAL_STR_NOT_ASSGINED = "EMP_SAL_STR_NOT_ASSGINED",
  EMP_BY_DEPT_ID = "EMP_BY_DEPT_ID",
  EMP_BY_DESIGINATION_ID = "EMP_BY_DESIGINATION_ID",
  EMP_BY_CATEGORY_ID = "EMP_BY_CATEGORY_ID",
  EMP_BY_GRADE_ID = "EMP_BY_GRADE_ID",
  NOT_SELECTED = "NOT_SELECTED",
  EMPS_ADDED_FOR_SAL_MK_MONTH = "EMPS_ADDED_FOR_SAL_MK_MONTH",
  EMPS_NOT_ADDED_FOR_SAL_MK_MONTH = "EMPS_NOT_ADDED_FOR_SAL_MK_MONTH",
  EMPS_WITH_ASSIGNED_LEAVES = "EMPS_WITH_ASSIGNED_LEAVES",
  EMPS_WITH_UNASSIGNED_LEAVES = "EMPS_WITH_UNASSIGNED_LEAVES",
  JUST_LOGIN_ACCTS = "JUST_LOGIN_ACCTS",
  REPORTING_MANAGER = "REPORTING_MANAGER",
  EMP_BY_TYPE = "EMP_BY_TYPE",
  TEACHERS_UNASSIGNED_FOR_SUBJECT = "TEACHERS_UNASSIGNED_FOR_SUBJECT",
  TEACHERS_UNASSIGNED_FOR_CLASS_SUBJECT = "TEACHERS_UNASSIGNED_FOR_CLASS_SUBJECT",
  TEACHERS_ASSIGNED_FOR_SUBJECT = "TEACHERS_ASSIGNED_FOR_SUBJECT",
  TEACHERS_ASSIGNED_FOR_CLASS = "TEACHERS_ASSIGNED_FOR_CLASS",
  ALL_TEACHERS_BY_FEEDBACK_FORM_ID = "ALL_TEACHERS_BY_FEEDBACK_FORM_ID",
  ALL_TEACHERS_ASSIGNED_FOR_CLASS = "ALL_TEACHERS_ASSIGNED_FOR_CLASS",
}
const useEmployee = (
  rowsPerPage: number,
  queryType: empQueryTypes,
  name: string,
  employeeId?: number,
  departmentId?: number,
  designationId?: number,
  categoryId?: number,
  gradeId?: number,
  jobTypeId?: number,
  emp_type?: string[],
  entry_level?: string,
  entry_id?: number,
  rerender?: boolean
) => {
  const { token } = useToken();
  const { InstId, monthId, formId } = useParams();
  const { state } = useContext(AppContext);

  const [responseType, setResponseType] = useState<responseType[]>([]);

  const [ids, setIds] = useState<number[]>([]);

  const [pr_emp_query_type, set_pr_emp_query_type] = useState(
    PrEmpQueryType.NOT_SELECTED
  );

  const getOptionalValues = (queryType: empQueryTypes) => {
    return queryType === empQueryTypes.REPORTING_MANAGER
      ? {
          departmentId: null,
          designationId: null,
          categoryId: null,
          gradeId: null,
          jobTypeId: null,
          employeeId: null,
        }
      : {
          departmentId: departmentId ? departmentId : null,
          designationId: designationId ? designationId : null,
          categoryId: categoryId ? categoryId : null,
          gradeId: gradeId ? gradeId : null,
          jobTypeId: jobTypeId ? jobTypeId : null,
          employeeId: employeeId ? employeeId : null,
        };
  };
  const optionalValues = getOptionalValues(queryType);
  const { flag } = useCheckAllocationType();
  const [GetEmployees, { data, loading, error, fetchMore }] = useLazyQuery<
    PayRollEmpData,
    PayRoleEmpvars
  >(GetPayRollEmp, {
    variables: {
      after: null,
      direction: Direction.ASC,
      first: rowsPerPage,
      input: {
        ids,
        pr_emp_query_type,
        emp_type: emp_type!,
        entry_level: entry_level!,
        entry_id: entry_id!,
      },
      name,
      per_std_subj_allocation: flag ? flag : false,
      sortBy: SortBy.EMP_FIRST_NAME,
      token,
      ...optionalValues,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let idsToSet = [];
    let queryTypeEnum = null;

    if (InstId) {
      idsToSet.push(Number(InstId));

      switch (queryType) {
        case empQueryTypes.GENERAL:
          queryTypeEnum = PrEmpQueryType.EMP_BY_INST_ID;
          break;
        case empQueryTypes.SAL_STR_ASSGINED:
          queryTypeEnum = PrEmpQueryType.EMP_SAL_STR_ASSGINED;
          break;
        case empQueryTypes.SAL_STR_NOT_ASSGINED:
          queryTypeEnum = PrEmpQueryType.EMP_SAL_STR_NOT_ASSGINED;
          break;
        case empQueryTypes.EMPS_NOT_ADDED_FOR_SAL_MK_MONTH:
          if (monthId) {
            idsToSet.push(Number(monthId));
            queryTypeEnum = PrEmpQueryType.EMPS_NOT_ADDED_FOR_SAL_MK_MONTH;
          }
          break;
        case empQueryTypes.EMPS_ADDED_FOR_SAL_MK_MONTH:
          if (monthId) {
            idsToSet.push(Number(monthId));
            queryTypeEnum = PrEmpQueryType.EMPS_ADDED_FOR_SAL_MK_MONTH;
          }
          break;
        case empQueryTypes.EMPS_WITH_UNASSIGNED_LEAVES:
          queryTypeEnum = PrEmpQueryType.EMPS_WITH_UNASSIGNED_LEAVES;
          break;
        case empQueryTypes.EMPS_WITH_ASSIGNED_LEAVES:
          queryTypeEnum = PrEmpQueryType.EMPS_WITH_ASSIGNED_LEAVES;
          break;
        case empQueryTypes.JUST_LOGIN_ACCTS:
          queryTypeEnum = PrEmpQueryType.JUST_LOGIN_ACCTS;
          break;
        case empQueryTypes.EMP_BY_TYPE:
          queryTypeEnum = PrEmpQueryType.EMP_BY_TYPE;
          break;
        case empQueryTypes.REPORTING_MANAGER:
          if (departmentId && designationId) {
            idsToSet.push(departmentId, designationId);
            queryTypeEnum = PrEmpQueryType.REPORTING_MANAGER;
          }
          break;
        case empQueryTypes.TEACHERS_UNASSIGNED_FOR_CLASS_SUBJECT:
          idsToSet.push(Number(state.subjectId));
          queryTypeEnum = PrEmpQueryType.TEACHERS_UNASSIGNED_FOR_CLASS_SUBJECT;
          break;
        case empQueryTypes.TEACHERS_UNASSIGNED_FOR_SUBJECT:
          idsToSet.push(Number(state.subjectId));
          queryTypeEnum = PrEmpQueryType.TEACHERS_UNASSIGNED_FOR_SUBJECT;
          break;
        case empQueryTypes.TEACHERS_ASSIGNED_FOR_SUBJECT:
          idsToSet.push(Number(state.subjectId));
          queryTypeEnum = PrEmpQueryType.TEACHERS_ASSIGNED_FOR_SUBJECT;
          break;
        case empQueryTypes.TEACHERS_ASSIGNED_FOR_CLASS:
          queryTypeEnum = PrEmpQueryType.TEACHERS_ASSIGNED_FOR_CLASS;
          break;
        case empQueryTypes.ALL_TEACHERS_BY_FEEDBACK_FORM_ID:
          idsToSet.push(Number(formId));

          queryTypeEnum = PrEmpQueryType.ALL_TEACHERS_BY_FEEDBACK_FORM_ID;
          break;
        case empQueryTypes.EMP_BY_DESIGINATION_ID:
          idsToSet.push(Number(designationId));
          queryTypeEnum = PrEmpQueryType.EMP_BY_DESIGINATION_ID;
          break;
        case empQueryTypes.ALL_TEACHERS_ASSIGNED_FOR_CLASS:
          queryTypeEnum = PrEmpQueryType.ALL_TEACHERS_ASSIGNED_FOR_CLASS;
          break;
        default:
          break;
      }

      setIds(idsToSet);
      if (queryTypeEnum !== null) {
        set_pr_emp_query_type(queryTypeEnum);
      }
    }
  }, [
    state.subjectId,
    queryType,
    InstId,
    departmentId,
    categoryId,
    formId,
    designationId,
    gradeId,
    jobTypeId,
    monthId,
  ]);

  useEffect(() => {
    if (
      ids[0] > 0 &&
      token &&
      ((pr_emp_query_type !== PrEmpQueryType.NOT_SELECTED &&
        pr_emp_query_type !== PrEmpQueryType.REPORTING_MANAGER) ||
        (pr_emp_query_type === PrEmpQueryType.REPORTING_MANAGER &&
          departmentId &&
          designationId))
    ) {
      GetEmployees().then(({ data }) => {
        if (data && data.GetPayRollEmp) {
          setResponseType(
            data.GetPayRollEmp.edges.map((edge) => ({
              label:
                edge.node.emp_first_name +
                " " +
                edge.node.emp_middle_name +
                " " +
                edge.node.emp_last_name,
              value: edge.node.id,
            }))
          );
        }
      });
    }
  }, [
    ids,
    GetEmployees,
    pr_emp_query_type,
    departmentId,
    categoryId,
    designationId,
    gradeId,
    jobTypeId,
    name,
    token,
    employeeId,
    rerender,
    flag,
  ]);
  return { empDetails: { data, loading, error, fetchMore, responseType } };
};

export default useEmployee;

import React, { useContext, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import DataFetch from "../../../images/Edit.svg";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import {
  PageFor,
  StudentAcctReportType,
  StudentListFor,
} from "../../../utils/Enum.types";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import StudentList from "../common/StudentList";
import Close from "../../../images/Close.svg";
import { LabeledAutocomplete, labelClasses } from "../../../styles/AutocompleteListStyles";
import { formClasses } from "../../../styles/AutocompleteStyles";
import { responseType } from "../../../utils/Form.types";
interface Props {
  handleClear: () => void;
  searchAdmNo: string;
  setSearchAdmNo: React.Dispatch<React.SetStateAction<string>>;
}
const StudentAutocomplete = ({
  handleClear,
  searchAdmNo,
  setSearchAdmNo,
}: Props) => {
  const [studentListModal, setStudentListModal] = useState(false);
  const { dispatch, state } = useContext(AppContext);
  const { studentAddmissionNumber, handleScroll } = useAcctStdAdmissionNumber(
    searchAdmNo,
    ROWS_PER_PAGE,
    StudentAcctReportType.DEMAND_RAISED
  );

  return (
    <div className="student-autocomplete">
      <LabeledAutocomplete
        className={labelClasses.inputRoot}
        options={studentAddmissionNumber}
        openOnFocus
        forcePopupIcon
        value={
          studentAddmissionNumber.find(
            ({ value }) => value === state.studentId
          ) ?? null
        }
        ListboxProps={{
          onScroll: handleScroll,
        }}
        onChange={(e, newValue) => {
          if (newValue) {
            dispatch({
              type: payloadTypes.SET_STUDENT_ID,
              payload: {
                studentId: (newValue as responseType).value,
              },
            });
          } else {
            handleClear();
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchAdmNo(e.target.value)
            }
            fullWidth
            className={labelClasses.formControlRoot}
            label="Admission No."
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
        )}
      />
      <img
        src={DataFetch}
        alt=""
        className="data-fetch-icon"
        onClick={() => setStudentListModal(!studentListModal)}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentListModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.DEMAND_RAISED}
              setStudentModal={setStudentListModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentListModal(!studentListModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StudentAutocomplete;

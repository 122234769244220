import { gql } from "@apollo/client";

export const AccountGroupLdgrsTypeByInstId = gql`
  query GetAcctGroupLdgrsByInstIdByType($token: String!, $inst_id: ID!) {
    GetAcctGroupLdgrsByInstIdByType(token: $token, inst_id: $inst_id) {
      fixed_asset {
        id
        gr_ldgr_desc
        acct_ldgr {
          id
          ldgr_desc

          ldgr_std_fee_short_desc
        }
      }

      current_asset {
        id
        gr_ldgr_desc
        acct_ldgr {
          id
          ldgr_desc

          ldgr_std_fee_short_desc
        }
      }

      income {
        id
        gr_ldgr_desc
        acct_ldgr {
          id
          ldgr_desc

          ldgr_std_fee_short_desc
        }
      }

      expenses {
        id
        gr_ldgr_desc
        acct_ldgr {
          id
          ldgr_desc

          ldgr_std_fee_short_desc
        }
      }
      liabilities {
        id
        gr_ldgr_desc
        acct_ldgr {
          id
          ldgr_desc

          ldgr_std_fee_short_desc
        }
      }
    }
  }
`;
export const GetGroupLedgerDetailsByNode = gql`
  query GetGroupLedgerDetailsByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on AcctGroupLdgr {
        id
        gr_ldgr_corb
        gr_ldgr_bs
        gr_ldgr_alias
        group_ldgr_type_id
        gr_ldgr_desc
      }
    }
  }
`;

export const AccLedgerByNodeId = gql`
  query AccLedgerByNodeId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on AcctLdgr {
        id
        ldgr_desc
        ldgr_alias
        ldgr_narration
        default_receipt_book_id
        ldgr_cash_bank_other
        ldgr_index_position
        ldgr_ob
        ldgr_total_cr
        ldgr_total_db
        ldgr_cb
        ldgr_imprest_ob
        acct_group_ldgr_id
        ldgr_std_fee_sub_Type
        ldgr_is_std_fee_ldgr
        ldgr_std_fee_short_desc
        ldgr_std_fee_is_monthly
        ldgr_std_fee_is_deposit_ldgr
        ldgr_std_fee_Type
        ldgr_std_fee_merge
        ldgr_std_fee_merge_desc
        ldgr_std_fee
        ldgr_sundry_address
        ldgr_sundry_city
        ldgr_sundry_state
        ldgr_sundry_country
        ldgr_sundry_contact_person
        ldgr_sundry_contact_person_phone
        ldgr_sundry_contact_person_mobile
        ldgr_sundry_contact_person_email
        ldgr_sundry_pan_id
        ldgr_sundry_aadhar_id
        ldgr_sundry_gst_no
        ldgr_bank_holder
        ldgr_bank_ac_no
        ldgr_bank_ifsc
        ldgr_bank_name
        ldgr_bank_city
        ldgr_bank_type
        cr_or_db
      }
    }
  }
`;

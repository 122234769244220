import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Direction,
  SortBy,
  StudentReportType,
  TransportQueryType,
} from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { GetTransportStd } from "../../Transport/queries/list";
import {
  RouteDetailsNodes,
  accLedgerDetails,
} from "../../Transport/paginationTypes";
import useAcademicYear from "./useActiveAcademicYear";
import { StudentAcdNode } from "./useAcdStudentsForAdmission";
interface routeFee {
  route_fee: number;
}
export interface StudentTransNode {
  is_transport_active: boolean;
  id: number;
  student_id: number;
  route_detail_id: number;
  acct_ldgr_id: number;
  route_details: RouteDetailsNodes;
  student_details: StudentAcdNode;
  acct_ldgr_details: accLedgerDetails;
  route_fee_details: routeFee;
}
export interface StudentPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}
export interface StudentEdges {
  node: StudentTransNode;
}
export interface GetTransStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}

export interface GetTransportStdData {
  GetTransportStd: GetTransStudentsList;
}
interface input {
  TransQueryType: string;
  ids: number[];
}
export interface TransStudentsvars {
  name: string;
  after: string | null;
  sortBy?: string;
  direction: string;
  first: number | null;
  token: string;
  acd_yr_id: number;
  input: input;
}
const useTransStudentData = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  rowsPerPage: number | null,
  name: string,
  queryType: StudentReportType | string
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [ids, setIds] = useState<number[]>([]);
  const [TransQueryType, setTransQueryType] = useState("");
  const { activeAcademicYearData } = useAcademicYear();

  const [GetTransStudentsData, { data, loading, error, fetchMore }] =
    useLazyQuery<GetTransportStdData, TransStudentsvars>(GetTransportStd, {
      variables: {
        token,
        name,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,
        first: rowsPerPage,
        sortBy: SortBy.STUDENT_ID,
        direction: Direction.ASC,
        after: null,
        input: { TransQueryType, ids },
      },
      fetchPolicy: "network-only",
    });
  useEffect(() => {
    switch (queryType) {
      case StudentReportType.TRANSPORT_ASSIGNED:
        if (sectionId) {
          setIds([sectionId]);
          setTransQueryType(TransportQueryType.ROUTE_ASSIGNED_BY_SECTION_ID);
        } else if (semesterId) {
          setIds([semesterId]);
          setTransQueryType(TransportQueryType.ROUTE_ASSIGNED_BY_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setTransQueryType(TransportQueryType.ROUTE_ASSIGNED_BY_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setTransQueryType(TransportQueryType.ROUTE_ASSIGNED_BY_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setTransQueryType(TransportQueryType.ROUTE_ASSIGNED_BY_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setTransQueryType(TransportQueryType.ROUTE_ASSIGNED_BY_INST_ID);
        }
        break;
      case StudentReportType.TRANSPORT_NOT_ASSIGNED:
        if (sectionId) {
          setIds([sectionId]);
          setTransQueryType(
            TransportQueryType.ROUTE_NOT_ASSIGNED_BY_SECTION_ID
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setTransQueryType(
            TransportQueryType.ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID
          );
        } else if (classId) {
          setIds([classId]);
          setTransQueryType(TransportQueryType.ROUTE_NOT_ASSIGNED_BY_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setTransQueryType(TransportQueryType.ROUTE_NOT_ASSIGNED_BY_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setTransQueryType(TransportQueryType.ROUTE_NOT_ASSIGNED_BY_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setTransQueryType(TransportQueryType.ROUTE_NOT_ASSIGNED_BY_INST_ID);
        }
        break;
      default:
        break;
    }
  }, [
    sectionId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
    queryType,
  ]);
  useEffect(() => {
    if (InstId) {
      GetTransStudentsData();
    }
  }, [InstId, queryType, GetTransStudentsData]);
  return {
    TransportStudentData: { data, loading, error, fetchMore },
  };
};

export default useTransStudentData;

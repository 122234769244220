import { gql } from "@apollo/client";

export const GetLibBookCategoriesByInstId = gql`
  query GetLibBookCategoriesByInstId(
    $categoryName: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookCategoryOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookCategoriesByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ categoryDescContainsFold: $categoryName }] }
    ) {
      edges {
        node {
          id
          category_desc
          category_abbrevation
          category_is_active
          category_sl
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

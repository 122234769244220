import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GetAcctStdDemandSummation } from "../queries/demands/query";

import { StudentAcctReportType } from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { AcctStudentQueryType } from "../common/QueryTypes";
import { AppContext } from "../../../context/context";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

interface input {
  std_demand_query_type: string;
  ids: number[];
  str_data?: string[];
  int_data?: number[];
  float_data?: number[];
}
interface vars {
  token: string;
  fin_yr_id: number;
  input: input;
  student_status: string;
}

export interface GetAcctStdDemandSummationDetails {
  std_demand_ob: number;
  std_demand_amt: number;
  std_demand_concession: number;
  std_demand_receivable: number;
  std_demand_received: number;
  std_demand_refunds: number;
  std_demand_bal: number;
}
export interface GetSummationData {
  GetAcctStdDemandSummation: GetAcctStdDemandSummationDetails[];
}

const useSummationDatabyNewApi = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  queryType: StudentAcctReportType,
  student_status: string
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [std_demand_query_type, setstd_query_type] = useState("");
  const [entryId, setEntryId] = useState(0);

  const { state } = useContext(AppContext);

  const [ids, setId] = useState<number[]>([]);

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  useEffect(() => {
    switch (queryType) {
      case StudentAcctReportType.GENERAL:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_CATEGORY_ID
          );
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_DEPT_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.COMPLETELY_PAID:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_CATEGORY_ID
          );
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_DEPT_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.PARTIALLY_PAID:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_CATEGORY_ID);
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_DEPT_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_INST_ID);
        }

        break;

      case StudentAcctReportType.DEMAND_RAISED_NOT_PAID:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_CATEGORY_ID
          );
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_DEPT_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_INST_ID
          );
        }

        break;
      case StudentAcctReportType.REFUNDS:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_CATEGORY_ID);
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_DEPT_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.CONCESSION:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_CATEGORY_ID);
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_DEPT_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_INST_ID);
        }

        break;

      case StudentAcctReportType.DEMAND_SUMMATION_CURR:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CATEGORY_ID
          );
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_DEPT_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_INST_ID
          );
        }

        break;

      case StudentAcctReportType.CURR:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_DEPT_ID
          );
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_INST_ID
          );
        }
        break;
      case StudentAcctReportType.DEMAND_RAISED:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_CATEGORY_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_INST_ID);
        }
        break;
      case StudentAcctReportType.BY_AGENT:
        setId([Number(InstId), entryId, categoryId, state.agentId]);
        setstd_query_type(AcctStudentQueryType.BY_AGENT_ID);
        break;

      default:
        break;
    }
  }, [
    departmentId,
    branchId,
    classId,
    semesterId,
    sectionId,
    categoryId,
    queryType,
    state.agentId,
    entryId,
    InstId,
  ]);
  useEffect(() => {
    if (sectionId && USE_SECTION_KEY) {
      setEntryId(sectionId);
    } else if (semesterId && USE_SEMESTER_KEY) {
      setEntryId(semesterId);
    } else if (classId && USE_CLASS_KEY) {
      setEntryId(classId);
    } else if (branchId && USE_BRANCH_KEY) {
      setEntryId(branchId);
    } else if (departmentId && USE_DEPARTMENT_KEY) {
      setEntryId(departmentId);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionId,
    USE_SECTION_KEY,
    semesterId,
    USE_SEMESTER_KEY,
    classId,
    USE_CLASS_KEY,
    branchId,
    USE_BRANCH_KEY,
    departmentId,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);

  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetSummationData,
    vars
  >(GetAcctStdDemandSummation, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      token,
      input: { ids, std_demand_query_type },
      student_status,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (state.ActiveFinYr && token && std_demand_query_type!=="") {
      GetStudentsData();
    }
  }, [GetStudentsData, state.ActiveFinYr, token, std_demand_query_type]);
  return { StudentsSummationData: { data, loading, error, fetchMore } };
};

export default useSummationDatabyNewApi;

import React, { useState } from "react";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../images/CalenderNextButton.svg";
import { Title } from "../../../../stories/Title/Title";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";
import { AcdYrType, ModalType } from "./Index";
import useHolidaysList from "../../../../customhooks/useHolidaysList";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { HolidayType, Operation } from "../../../../utils/Enum.types";
import { MonthName } from "../../../../utils/UtilFunctions";
import { monthDaysType } from "../../DailyActivities/Attendance/MonthlyOverview";
import { INCREMENT_DECREMENT } from "../../../Enquiry/Dashboard/Index";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import dayjs from "dayjs";
import { EMPTY_STRING, days } from "../../../../utils/constants";

interface Props {
  date: Date;
  acdYr: AcdYrType;
  setAddmodal: React.Dispatch<React.SetStateAction<ModalType>>;
}
export const colors: string[] = [
  "#0ea5e9",
  "#ec4899",
  "#34d399",
  "#22d3ee",
  "#a3e635",
  "#e6c057",
  "#e81c34",
  "#1c1ce8",
  "#28942d",
  "#7dbef0",
];
const CustomizedCalendar = ({ setAddmodal, date, acdYr }: Props) => {
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [selectMonth, setSelectMonth] = useState(false);

  const { activeAcademicYearData } = useActiveAcademicYear();
  const monthDays = useHolidaysList(
    date,
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!
  );

  const handleDateClick = (date: monthDaysType) => {
    setAddmodal({
      date: new Date(date.date),
      flag: true,
      operation:
        date.status === EMPTY_STRING ? Operation.CREATE : Operation.UPDATE,
    });
  };

  const changeMonth = (increment_decrement: INCREMENT_DECREMENT) => {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    const academicYearEndDate = new Date(acdYr.end_date!);

    const academicYearStartDate = new Date(acdYr.start_date);
    if (increment_decrement === INCREMENT_DECREMENT.INCREMENT) {
      const nextDate = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );
      if (nextDate.getTime() < academicYearEndDate.getTime()) {
        setAddmodal({
          flag: false,
          date: new Date(year, month + 1, day),
          operation: Operation.NONE,
        });
      } else {
        setMessage({
          message: "Reached the end of Academic Year",
          flag: true,
          operation: Operation.NONE,
        });
      }
    } else {
      const prevDate = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        date.getDate()
      );

      const prev = dayjs(prevDate).startOf("day");
      const acdStartDate = dayjs(academicYearStartDate).startOf("day");
      if (acdStartDate.isBefore(prev) || acdStartDate.isSame(prev)) {
        setAddmodal({
          flag: false,
          date: new Date(year, month - 1, day),
          operation: Operation.NONE,
        });
      } else {
        setMessage({
          message: "Reached the Start of Academic Year",
          flag: true,
          operation: Operation.NONE,
        });
      }
    }
  };

  return (
    <>
      <div className="customized-calendar">
        {selectMonth ? (
          <div className="months-list">
            {activeAcademicYearData.dateOptions.map((month, index) => {
              return (
                <span
                  className="month_name"
                  onClick={() => {
                    setAddmodal((prev) => ({
                      ...prev,
                      date: new Date(month.value),
                    }));
                    setSelectMonth(!selectMonth);
                  }}
                  key={index}
                >
                  {month.label}
                </span>
              );
            })}
          </div>
        ) : (
          <>
            <div className="customized-calendar--month">
              <img
                src={Previous}
                alt="/"
                onClick={() => changeMonth(INCREMENT_DECREMENT.DECREMENT)}
              />
              <Title
                variant="subtitle1"
                onClick={() => {
                  setSelectMonth(!selectMonth);
                }}
              >
                {MonthName(date.toString())}
              </Title>
              <img
                src={Next}
                alt="/"
                onClick={() => changeMonth(INCREMENT_DECREMENT.INCREMENT)}
              />
            </div>
            <ul className="customized-calendar--grid-container">
              {days.map((day, index: React.Key) => {
                return (
                  <li className="customized-calendar--day" key={index}>
                    {day}
                  </li>
                );
              })}
              {monthDays.map((date, index) => {
                // const dotColors = generateDotColors();

                // const className = isWeekend
                //   ? "weekends-in-calendar"
                //   : presentDay.includes(date)
                //   ? "present-date-in-calendar"
                //   : "customized-calendar--date";

                // const liClassName = isHoliday
                //   ? "holidays-in-calendar"
                //   : presentDay.includes(date)
                //   ? "present-day-in-calendar"
                //   : "customized-calendar--grid-item";
                if (date.day > 0) {
                  return (
                    <React.Fragment key={index}>
                      {/* {eventsInCalendar.includes(date) ? (
                  <Tooltip
                    title={
                      <>
                        <ul>
                          <li>1. Cultural Day</li>
                          <li>2. Sports Day</li>
                          <li>3. Sports Day</li>
                        </ul>
                      </>
                    }
                    placement="right"
                    classes={{
                      tooltip: classes.customTooltipGrey,
                      arrow: classes.customArrowGrey,
                    }}
                    arrow
                  >
                    <li className={liClassName} key={date}>
                      {isVacation && (
                        <span className="vacations-in-calendar"></span>
                      )}
                      <span className={className}>{date}</span>
                      {eventsInCalendar.includes(date) ? (
                        <>
                          {dotColors.map((color, dotIndex) => (
                            <>
                              <span
                                className="events-in-calendar"
                                style={{ backgroundColor: color }}
                                key={dotIndex}
                              />
                            </>
                          ))}
                        </>
                      ) : null}
                    </li>
                  </Tooltip>
                ) : ( */}

                      {date.status ? (
                        <CustomTooltip
                          title={
                            <ul>
                              {date.description
                                .trim()
                                .split("\n")
                                .map((desc, index) => (
                                  <li key={index}>{desc}</li>
                                ))}
                            </ul>
                          }
                          placement="right"
                          slotProps={{
                            tooltip: {
                              sx: {
                                bgcolor: TOOLTIP_COLORS.GREY,
                                "& .MuiTooltip-arrow": {
                                  color: TOOLTIP_COLORS.GREY,
                                },
                              },
                            },
                          }}
                          arrow
                          key={index}
                        >
                          <li
                            className={
                              date.holiday_types.includes(
                                HolidayType.GENERAL
                              ) ||
                              date.holiday_types.includes(
                                HolidayType.UNEXPECTED
                              )
                                ? `customized-calendar--grid-item holidays-in-calendar`
                                : "customized-calendar--grid-item"
                            }
                            onClick={() => handleDateClick(date)}
                            key={index}
                          >
                            {date.holiday_types.includes(
                              HolidayType.VACATION
                            ) && (
                              <span className="vacations-in-calendar"></span>
                            )}
                            <span
                              className={
                                date.holiday_types.includes(HolidayType.WEEKEND)
                                  ? `customized-calendar--date weekends-in-calendar`
                                  : "customized-calendar--date"
                              }
                            >
                              {date.day}
                            </span>
                            {date.holiday_types.includes(HolidayType.EVENT) ? (
                              <>
                                {Array.from({ length: date.events_length }).map(
                                  (_, dotIndex) => {
                                    return (
                                      <span
                                        className="events-in-calendar"
                                        style={{
                                          backgroundColor:
                                            dotIndex >= colors.length
                                              ? "#0ea5e9"
                                              : colors[dotIndex],
                                        }}
                                        key={dotIndex}
                                      />
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                          </li>
                        </CustomTooltip>
                      ) : (
                        <li
                          className={
                            date.status === HolidayType.UNEXPECTED ||
                            date.status === HolidayType.GENERAL
                              ? `customized-calendar--grid-item ${date.class_name}`
                              : "customized-calendar--grid-item"
                          }
                          onClick={() => handleDateClick(date)}
                          key={index}
                        >
                          {date.status === HolidayType.VACATION && (
                            <span className="vacations-in-calendar"></span>
                          )}
                          <span
                            className={
                              date.status === HolidayType.WEEKEND
                                ? `customized-calendar--date ${date.class_name}`
                                : "customized-calendar--date"
                            }
                          >
                            {date.day}
                          </span>
                          {/* {eventsInCalendar.includes(date) ? (
                    <>
                      {dotColors.map((color, dotIndex) => (
                        <>
                          <span
                            className="events-in-calendar"
                            style={{ backgroundColor: color }}
                            key={dotIndex}
                          />
                        </>
                      ))}
                    </>
                  ) : null} */}
                        </li>
                      )}

                      {/* )} */}
                    </React.Fragment>
                  );
                } else {
                  return <li key={index}></li>;
                }
              })}
            </ul>
          </>
        )}
      </div>
      <MessageModal
        handleClose={() =>
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          })
        }
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default CustomizedCalendar;

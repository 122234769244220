import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Previous from "../../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../../images/CalenderNextButton.svg";
import { Label } from "../../../../../stories/Label/Label";
import { Button } from "../../../../../stories/Button/Button";
import { Tooltip } from "@mui/material";
import {
  DateRange,
  GetnumberOfDaysInMonth,
  MonthName,
  handleDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import { INCREMENT_DECREMENT } from "../../../../Enquiry/Dashboard/Index";
import useActiveAcademicYear from "../../../../Academics/hooks/useActiveAcademicYear";
import { msgType } from "../../../../../utils/Form.types";
import {
  AttendanceStatus,
  Direction,
  HolidayType,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  SortBy,
} from "../../../../../utils/Enum.types";
import MessageModal from "../../../../../pages/MessageModal";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  days,
} from "../../../../../utils/constants";
import { monthDaysType } from "../../../../Academics/DailyActivities/Attendance/MonthlyOverview";
import { AppContext } from "../../../../../context/context";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdAttendanceMonthlyByStdIdData,
  GetAcdAttendanceMonthlyByStdIdVars,
  GetAcdStdAttOverAllVars,
  GetAcdStdSubjAttOverAllVars,
} from "../../../../Academics/types/holidays";
import {
  GetAcdStdAttOverAll,
  GetAcdStdAttSubjMonthlyForCal,
  GetAcdStdSubjAttOverAll,
  GetAcdStudentAttendanceByStdId,
} from "../../../../Academics/queries/holidays/list";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../../../Academics/hooks/useAcdGeneralHolidays";
import useToken from "../../../../../customhooks/useToken";
import {
  GetAcdStdAttOverAllData,
  GetAcdStdSubjAttOverAllData,
} from "../../../../Academics/hooks/useAcdStudentAttendance";
import dayjs from "dayjs";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import LoadingModal from "../../../../../pages/LoadingModal";
import useCheckAllocationType from "../../../../Academics/hooks/useCheckAllocationType";
import OverAllAttendance from "./OverAllAttendance";
import Sessionwise from "./Sessionwise";
import GraphData from "../../../../Academics/DailyActivities/Attendance/SessionDetails/GraphData";
import StudentAcademicDetails from "../../StudentPreview/StudentAcademicDetails";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: PageFor;
}
const CompleteAttendanceDetails = ({ setModalFlag, pageType }: Props) => {
  const { serverDate } = useServerDateandTime();
  const { state } = useContext(AppContext);

  var generalHolidaysMap = useRef(new Map());
  const { token } = useToken();
  const [customDate, setCustomDate] = useState(new Date());

  const [monthDaysForSession, setMonthDaysForSession] =
    useState<monthDaysType[]>();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [date, setMonthDate] = useState(new Date());
  const [monthDays, setMonthDays] = useState<monthDaysType[]>();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  const { firstDay, lastDay } = DateRange(date.toString()) || {};

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    holiday_query_type.ALL_HOLIDAYS
  );
  const [GetStudentHolidayDetails, { data }] = useLazyQuery<
    GetAcdAttendanceMonthlyByStdIdData,
    GetAcdAttendanceMonthlyByStdIdVars
  >(GetAcdStudentAttendanceByStdId);
  const [
    GetOverAllSubjectAttendance,
    { data: OverSubjectData, loading: SubjectLoading },
  ] = useLazyQuery<GetAcdStdSubjAttOverAllData, GetAcdStdSubjAttOverAllVars>(
    GetAcdStdSubjAttOverAll,
    {
      variables: {
        input: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          entry_id: 0,
          entry_level: EMPTY_STRING,
          inst_id: state.InstId!,
          cal_month: toIsoDate(serverDate),
          subj_master_id: 0,
          session_id: 0,
          student_id: state.studentId,
          att_query_type: "SUBJ_YEARLY_ATT_PER_STD",
          date_of_attendance: toIsoDate(serverDate),
        },
        token,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.STD_ROLL_NO,
        },
        after: null,
        first: null,
        name: EMPTY_STRING,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );
  const [GetOverAllAttendance, { data: OverAllData, loading }] = useLazyQuery<
    GetAcdStdAttOverAllData,
    GetAcdStdAttOverAllVars
  >(GetAcdStdAttOverAll, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: 0,
        entry_level: EMPTY_STRING,
        inst_id: state.InstId!,
        cal_month: toIsoDate(serverDate),
        subj_master_id: 0,
        session_id: 0,
        student_id: state.studentId,
        att_query_type: "OVER_ALL_ATT_PER_STD",
        date_of_attendance: toIsoDate(serverDate),
      },
      token,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      after: null,
      first: null,
      name: EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { flag } = useCheckAllocationType();

  const [GetData, { data: attData, loading: attLoading }] = useLazyQuery(
    GetAcdStdAttSubjMonthlyForCal
  );

  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();

  const weekCountForSession = () => {
    if (monthDaysForSession && monthDaysForSession.length > 0) {
      const firstWeek = monthDaysForSession[0].week;

      const lastWeek =
        monthDaysForSession[monthDaysForSession.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
          attendance: [],
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
          attendance: [],
        }));

      return [...startArray, ...monthDaysForSession, ...endArray];
    } else {
      return [];
    }
  };
  const chooseTypeAndColor = (status: string, day: number) => {
    if (status === AttendanceStatus.P) {
      return {
        type: AttendanceStatus.P,
        description: "",
        class_name: "cal-bg-green",
      };
    } else if (status === AttendanceStatus.A) {
      return {
        type: AttendanceStatus.A,
        description: "",
        class_name: "cal-bg-red",
      };
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
      };
    }
  };
  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };
  const chooseTypeAndColorForSession = (statusArray: string[], day: number) => {
    const areAllPresent =
      statusArray.length > 0 &&
      statusArray.every((value) => value === AttendanceStatus.P);

    const areAllAbsent =
      statusArray.length > 0 &&
      statusArray.every((value) => value === AttendanceStatus.A);
    const containsBothAP =
      statusArray.includes(AttendanceStatus.A) &&
      statusArray.includes(AttendanceStatus.P);

    if (areAllPresent) {
      return {
        type: AttendanceStatus.P,
        description: "",
        class_name: "cal-bg-green",
      };
    } else if (areAllAbsent) {
      return {
        type: AttendanceStatus.A,
        description: "",
        class_name: "cal-bg-red",
      };
    } else if (containsBothAP) {
      return {
        type: "",
        description: "",
        class_name: "cal-bg-amber",
      };
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
      };
    }
  };

  const emptyDaysByWeek = (week: string) => {
    let numberOfEmpty = -1;
    let numberOfEmptyToLast = -1;
    switch (week) {
      case "Sun":
        numberOfEmpty = 6;
        numberOfEmptyToLast = 0;
        break;
      case "Mon":
        numberOfEmpty = 0;
        numberOfEmptyToLast = 6;
        break;
      case "Tue":
        numberOfEmpty = 1;
        numberOfEmptyToLast = 5;

        break;
      case "Wed":
        numberOfEmpty = 2;
        numberOfEmptyToLast = 4;
        break;
      case "Thu":
        numberOfEmpty = 3;
        numberOfEmptyToLast = 3;
        break;
      case "Fri":
        numberOfEmpty = 4;
        numberOfEmptyToLast = 2;
        break;
      case "Sat":
        numberOfEmpty = 5;
        numberOfEmptyToLast = 1;
        break;
      default:
        break;
    }
    return { numberOfEmpty, numberOfEmptyToLast };
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (serverDate) {
      setMonthDate(new Date(serverDate));
    }
  }, [serverDate]);

  useEffect(() => {
    if (
      state.studentId &&
      firstDay &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      enablePerSessionAtt
    ) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetData({
        variables: {
          token,
          inst_id: state.InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(firstDay),
          student_id: state.studentId,
          per_std_subj_allocation: flag ? flag : false,
        },
      }).then(({ data }) => {
        if (data) {
          const arrLength = GetnumberOfDaysInMonth(firstDay);
          const res = Array.from({ length: arrLength }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);

            const statusValue = (
              data.GetAcdStdAttSubjMonthlyForCal as Record<
                string,
                string | string[]
              >
            )[dayKey];

            const statusArray = Array.isArray(statusValue)
              ? statusValue
              : statusValue?.split(";") || [];

            const filteredStatusArray = statusArray.filter(
              (value) => value !== ""
            );

            const holiday_details = chooseTypeAndColorForSession(
              filteredStatusArray,
              dayNum
            );
            return {
              day: dayNum,
              status: holiday_details?.type,
              description: holiday_details?.description,
              week: date.toLocaleString("en-US", { weekday: "short" }),
              class_name: holiday_details?.class_name!,
              date,
              holiday_types: [],
              events_length: 0,
              attendance: statusArray,
            };
          });
          setMonthDaysForSession(res);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.studentId,
    firstDay,
    state.InstId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetData,
    InstGeneralHolidays.data,
    attData,
    date,
    customDate,
    enablePerSessionAtt,
    state.ActiveAcdYr,
    flag,
  ]);

  useEffect(() => {
    if (state.studentId && token && state.ActiveAcdYr && serverDate) {
      GetOverAllAttendance();
      GetOverAllSubjectAttendance();
    }
  }, [
    GetOverAllAttendance,
    GetOverAllSubjectAttendance,
    state.studentId,
    token,
    state.ActiveAcdYr,
    serverDate,
  ]);
  useEffect(() => {
    if (
      state.studentId &&
      firstDay &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      enablePerSessionAtt === false
    ) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetStudentHolidayDetails({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          cal_month: toIsoDate(firstDay!),
          inst_id: String(state.InstId)!,
          student_id: state.studentId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const arrLength = GetnumberOfDaysInMonth(firstDay);
          const res = Array.from({ length: arrLength }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);
            const holiday_details = chooseTypeAndColor(
              (data.GetAcdStudentAttendanceByStdId as Record<string, string>)[
                dayKey
              ],
              dayNum
            );
            return {
              day: dayNum,
              status: holiday_details?.type,
              description: holiday_details?.description,
              week: date.toLocaleString("en-US", { weekday: "short" }),
              class_name: holiday_details?.class_name!,
              date,
              holiday_types: [],
              events_length: 0,
            };
          });
          setMonthDays(res);
        }
      });
    }
    // eslint-disable-next-line
  }, [
    state.studentId,
    firstDay,
    state.InstId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStudentHolidayDetails,
    InstGeneralHolidays.data,
    state.ActiveAcdYr,
    data,
    date,
  ]);
  return (
    <>
      <div className="student-dashboard__attendance--full-view">
        <Title>Attendance Overview</Title>
        {pageType === PageFor.REPORT ? <StudentAcademicDetails /> : null}

        <div
          className={`student-dashboard__attendance--full-view--datablock${
            pageType === PageFor.REPORT ? "--report" : ""
          } row g-0`}
        >
          <div className="col student-dashboard__attendance--full-view--datablock--frame">
            <div className="student-dashboard__attendance--month">
              <img
                src={Previous}
                alt="/"
                onClick={() =>
                  handleDate(
                    date,
                    setMonthDate,
                    activeAcademicYearData.data?.GetAcdYrActiveByInstId
                      .acd_st_date!,
                    activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                      .acd_end_date!,
                    setMessage,
                    INCREMENT_DECREMENT.DECREMENT
                  )
                }
              />
              <Title variant="subtitle1">{MonthName(date.toString())}</Title>
              <img
                src={Next}
                alt="/"
                onClick={() => {
                  const nextDate = new Date(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    date.getDate()
                  );
                  const serverDateDayjs = dayjs(serverDate);
                  const selectedDateDayjs = dayjs(nextDate);
                  if (
                    serverDateDayjs.isAfter(selectedDateDayjs) &&
                    !serverDateDayjs.isSame(selectedDateDayjs)
                  ) {
                    handleDate(
                      date,
                      setMonthDate,
                      activeAcademicYearData.data?.GetAcdYrActiveByInstId
                        .acd_st_date!,
                      activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                        .acd_end_date!,
                      setMessage,
                      INCREMENT_DECREMENT.INCREMENT
                    );
                  } else {
                    setMessage({
                      flag: true,
                      operation: Operation.NONE,
                      message: "Cannot Access Future Dates",
                    });
                  }
                }}
              />
            </div>
            <ul className="student-dashboard__attendance--grid-container">
              {days.map((day, index) => {
                return (
                  <li
                    key={index}
                    className="student-dashboard__attendance--grid-container--day"
                  >
                    {day}
                  </li>
                );
              })}
              {enablePerSessionAtt
                ? weekCountForSession().map(
                    (
                      {
                        day,
                        class_name,
                        description,
                        date: monthDate,
                        status,
                        attendance,
                      },
                      index
                    ) => {
                      return day > 0 ? (
                        status === HolidayType.GENERAL ||
                        status === HolidayType.UNEXPECTED ||
                        status === HolidayType.VACATION ? (
                          <Tooltip
                            title={<>{description}</>}
                            arrow
                            placement="right"
                            key={index}
                          >
                            <li
                              className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                              key={index}
                            >
                              <span>{day > 0 ? day : ""}</span>
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                            onClick={() => {
                              dayjs(monthDate!).isAfter(serverDate)
                                ? setMessage({
                                    flag: true,
                                    message: "Cannot Access Future Dates",
                                    operation: Operation.NONE,
                                  })
                                : setCustomDate(monthDate!);
                            }}
                            key={index}
                          >
                            <span>{day > 0 ? day : ""}</span>
                            <div className="student-dashboard__attendance--full-view--sessions--status">
                              {attendance &&
                                attendance
                                  .filter((data) => data !== EMPTY_STRING)
                                  .map((status: string, index: number) => (
                                    <span
                                      key={index}
                                      className={`student-dashboard__attendance--full-view--dot ${
                                        status === "P"
                                          ? "student-dashboard__attendance--full-view--present"
                                          : "student-dashboard__attendance--full-view--absent"
                                      }`}
                                    ></span>
                                  ))}
                            </div>
                          </li>
                        )
                      ) : (
                        <li key={index}></li>
                      );
                    }
                  )
                : weekCount().map(
                    (
                      { day, class_name, description, date: monthDate, status },
                      index
                    ) => {
                      return day > 0 ? (
                        status === HolidayType.GENERAL ||
                        status === HolidayType.UNEXPECTED ||
                        status === HolidayType.VACATION ? (
                          <Tooltip
                            title={<>{description}</>}
                            arrow
                            placement="right"
                            key={index}
                          >
                            <li
                              className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                              key={index}
                            >
                              <span>{day > 0 ? day : ""}</span>
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                            onClick={() => {
                              dayjs(monthDate!).isAfter(serverDate)
                                ? setMessage({
                                    flag: true,
                                    message: "Cannot Access Future Dates",
                                    operation: Operation.NONE,
                                  })
                                : setCustomDate(monthDate!);
                            }}
                            key={index}
                          >
                            <span>{day > 0 ? day : ""}</span>
                          </li>
                        )
                      ) : (
                        <li key={index}></li>
                      );
                    }
                  )}
            </ul>
            <div className="student-dashboard__attendance--legends">
              <span
                className="student-dashboard__attendance--legends--data"
                id="bg-green"
              ></span>
              &rarr;
              <Label>Present</Label>
              <span
                className="student-dashboard__attendance--legends--data"
                id="bg-red"
              ></span>
              &rarr;
              <Label>Absent</Label>
              {enablePerSessionAtt ? (
                <>
                  <span
                    className="student-dashboard__attendance--legends--data"
                    id="bg-amber"
                  ></span>
                  &rarr;
                  <Label>Absent in any of the Sessions</Label>
                </>
              ) : null}
            </div>
          </div>
          {/* use this div for overall class attendance */}
          {enablePerSessionAtt === false ? (
            <div className="col student-dashboard__attendance--full-view--datablock--frame">
              <OverAllAttendance />
            </div>
          ) : null}
          {enablePerSessionAtt && (
            <>
              <div className="col student-dashboard__attendance--full-view--datablock--frame">
                <Sessionwise />
              </div>
              <div className="col student-dashboard__attendance--full-view--datablock--frame">
                <Title variant="subtitle1">OverAll Attendance Details</Title>
                <div className="student-dashboard__attendance--full-view--datablock--frame--cards row g-0">
                  <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                      Total Sessions
                    </span>
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--attendance">
                      {OverAllData &&
                        OverAllData.GetAcdStdAttOverAll &&
                        OverAllData.GetAcdStdAttOverAll.edges.length &&
                        OverAllData.GetAcdStdAttOverAll.edges[0].node
                          .total_session}
                    </span>
                  </div>
                  <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                      Total Present
                    </span>
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--present">
                      {OverAllData &&
                        OverAllData.GetAcdStdAttOverAll &&
                        OverAllData.GetAcdStdAttOverAll.edges.length &&
                        OverAllData.GetAcdStdAttOverAll.edges[0].node
                          .total_session_present}
                    </span>
                  </div>
                  <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                      Total Absent
                    </span>
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--absent">
                      {OverAllData &&
                        OverAllData.GetAcdStdAttOverAll &&
                        OverAllData.GetAcdStdAttOverAll.edges.length &&
                        OverAllData.GetAcdStdAttOverAll.edges[0].node
                          .total_session_absent}
                    </span>
                  </div>
                  <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
                      Total Percentage
                    </span>
                    <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--percentage">
                      {OverAllData &&
                        OverAllData.GetAcdStdAttOverAll &&
                        OverAllData.GetAcdStdAttOverAll.edges.length &&
                        OverAllData.GetAcdStdAttOverAll.edges[0].node
                          .over_all_per}
                      %
                    </span>
                  </div>
                </div>
                <div className="student-dashboard__attendance--full-view--graph">
                  <GraphData />
                </div>
              </div>
            </>
          )}
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        value={message.message}
        operation={message.operation}
      />
      <LoadingModal flag={loading || SubjectLoading || attLoading} />
    </>
  );
};

export default CompleteAttendanceDetails;

import React, { SetStateAction, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import TableView from "../../../../../images/TableView.svg";
import DataView from "../../../../../images/DataView.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaders } from "../../../../../utils/Enum.types";
import { Button } from "../../../../../stories/Button/Button";
import MarkAttendance from "../../../../../images/Assign.svg";
import Update from "../../../../../images/Update.svg";
import { Label } from "../../../../../stories/Label/Label";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../../styles/TooltipStyles";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";

const listData = [
  {
    branch: "AI First Year",
    semester: "AI First Semester",
    section: "A-Section",
    total: 80,
    present: 20,
    absent: 60,
    Percentage: 35,
    status: "Marked",
  },
  {
    branch: "AI First Year",
    semester: "AI First Semester ",
    section: "A-Section",
    total: 80,
    present: 20,
    absent: 60,
    Percentage: 35,
    status: "Not Marked",
  },
];
interface Props {
  setModalFlag: React.Dispatch<SetStateAction<boolean>>;
}
const Attendance = ({ setModalFlag }: Props) => {
  const [tableViewActive, setTableViewActive] = useState(true);
  const { departmentLabel, branchLabel, classLabel, sectionLabel } =
    useInstLabels();
  return (
    <>
      <Title>Attendance Overview</Title>
      <div className="teacher-dashboard__attendance">
        <div className="row g-0 teacher-dashboard__attendance--filters">
          <div className="col-4 ">
            <TextField
              label="Date"
              className="teacher-dashboard__attendance--filters--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
            />
            <Label className="present-day">Friday</Label>
          </div>
          <div className="col"></div>
          <div className="col-2  teacher-dashboard__attendance--filters--images">
            {!tableViewActive ? (
              <CustomTooltip
                title="Card View"
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={DataView}
                  alt=""
                  onClick={() => setTableViewActive(!tableViewActive)}
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip
                title="Table View"
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <img
                  src={TableView}
                  alt=""
                  onClick={() => setTableViewActive(!tableViewActive)}
                />
              </CustomTooltip>
            )}
          </div>
        </div>
        {!tableViewActive ? (
          <div className="teacher-dashboard__attendance--tableblock">
            <TableContainer className="teacher-dashboard__attendance--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{TableHeaders.SLNO}</TableCell>
                    <TableCell>{departmentLabel}</TableCell>
                    <TableCell>{branchLabel}</TableCell>
                    <TableCell>{classLabel}</TableCell>
                    <TableCell>{sectionLabel}</TableCell>
                    <TableCell>{sectionLabel}</TableCell>
                    <TableCell>{TableHeaders.TOTAL}</TableCell>
                    <TableCell>Present</TableCell>
                    <TableCell>Absent</TableCell>
                    <TableCell>Percentage</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="teacher-dashboard__attendance--table--slno"
                      id="td-center"
                    >
                      1
                    </TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>branch </TableCell>
                    <TableCell>class</TableCell>
                    <TableCell>semester</TableCell>
                    <TableCell>section</TableCell>

                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-grey"
                    >
                      100
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-green"
                    >
                      86
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-red"
                    >
                      14
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-blue"
                    >
                      85%
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--status"
                    >
                      <button className="teacher-dashboard__attendance--table--mark-attendance">
                        Mark attendance <img src={MarkAttendance} alt="" />
                      </button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="teacher-dashboard__attendance--table--slno"
                      id="td-center"
                    >
                      2
                    </TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>branch </TableCell>
                    <TableCell>class</TableCell>
                    <TableCell>semester</TableCell>
                    <TableCell>section</TableCell>

                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-grey"
                    >
                      100
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-green"
                    >
                      86
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-red"
                    >
                      14
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--number font-blue"
                    >
                      85%
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="teacher-dashboard__attendance--table--status"
                    >
                      <button className="teacher-dashboard__attendance--table--update">
                        Update <img src={Update} alt="" />
                      </button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <>
            {/* use only last three levels in cards */}

            <div className="teacher-dashboard__attendance--cardview">
              <div className="teacher-dashboard__attendance--gridlist">
                {listData.map((data, index) => {
                  return (
                    <div
                      className={
                        data.status === "Marked"
                          ? "teacher-dashboard__attendance--grid marked"
                          : "teacher-dashboard__attendance--grid not-marked"
                      }
                      key={index}
                    >
                      <span className="teacher-dashboard__attendance--grid--level-1">
                        {data.branch}
                      </span>
                      <span className="teacher-dashboard__attendance--grid--level-2">
                        {data.semester}
                      </span>
                      <b className="teacher-dashboard__attendance--grid--level-3">
                        {data.section}
                      </b>
                      <div className="teacher-dashboard__attendance--grid--flex">
                        <div>
                          <span className="teacher-dashboard__attendance--grid--text">
                            Total
                          </span>
                          <span className="teacher-dashboard__attendance--grid--number font-grey">
                            {data.total}
                          </span>
                        </div>
                        <div>
                          <span className="teacher-dashboard__attendance--grid--text">
                            Present
                          </span>
                          <span className="teacher-dashboard__attendance--grid--number font-green">
                            {data.present}
                          </span>
                        </div>
                        <div>
                          <span className="teacher-dashboard__attendance--grid--text">
                            Absent
                          </span>
                          <span className="teacher-dashboard__attendance--grid--number font-red">
                            {data.absent}
                          </span>
                        </div>
                      </div>
                      <div className="teacher-dashboard__attendance--grid--flex2">
                        <span className="teacher-dashboard__attendance--grid--text">
                          Percentage
                        </span>
                        <span className="teacher-dashboard__attendance--grid--number font-blue">
                          {data.Percentage}%
                        </span>
                      </div>
                      <div className="teacher-dashboard__attendance--grid--button">
                        {data.status === "Marked" ? (
                          <button className="teacher-dashboard__attendance--grid--update">
                            Update <img src={Update} alt="" />
                          </button>
                        ) : (
                          <button className="teacher-dashboard__attendance--grid--mark">
                            Mark Attendance <img src={MarkAttendance} alt="" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}

        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default Attendance;

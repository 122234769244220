import Home from "../../Home/Index";
import { Operation } from "../../../../utils/Enum.types";
import { Step, StepLabel, Stepper } from "@mui/material";
import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";
import { Title } from "../../../../stories/Title/Title";
import { GetMstInstUploadDocTypesList } from "../MandatoryDocs/StudentDocs";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../../stories/Button/Button";
import { studentRegStepHeader } from "./BasicDetails";
import DocsUploadComponent from "./DocsUploadComponent";

interface props {
  type: Operation;
  step: number;
}

export interface DocsType extends GetMstInstUploadDocTypesList {
  preview: File | null;
  fromDb: boolean;
  fromDbUrl: string;
  docId: number;
  index?: number;
  collected?: boolean;
  isOriginal?: boolean;
  isUpdatedFlag?: boolean;
  dateCollected?: string;
  remarks?: string;
}
const DocsUpload = ({ type, step }: props) => {
  const stepsHeader = studentRegStepHeader();

  const navigate = useNavigate();
  const { InstId, studentId } = useParams();

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="student_docs">
        {type === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        <Title>Student Documents Upload</Title>
        <StudentAcademicDetails />
        <div
          className={`student_docs__table${
            type === Operation.CREATE ? "--create" : "--update"
          }`}
        >
          <DocsUploadComponent type={type} />
        </div>
        <Button
          mode={`${type === Operation.CREATE ? "next" : "back"}`}
          onClick={() => {
            if (type === Operation.CREATE) {
              navigate(
                `/${InstId}/admissions/students/6/${studentId}/studentFeeDemand`
              );
            } else {
              navigate(-1);
            }
          }}
        />
      </div>
    </>
  );
};

export default DocsUpload;

import useEduateConfiguration, {
  EduateConfigTypes,
} from "../../../customhooks/useEduateConfiguration";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../utils/Enum.types";

const Index = () => {
  // const breadcrumbs = useBreadcrumbs(routes, {});
  // const Reg = /\d+/g;
  // let replace_id: string;
  // let route;

  const {
    EduateConfig: { data: EduateBannerDetails },
  } = useEduateConfiguration(EduateConfigTypes.EDUATE_BANNER);
  const {
    configData: { data: InstBanner1Details },
  } = useSwConfigData(InstitutionConfigurationTypes.INST_BANNER_1);
  const {
    configData: { data: InstBanner2Details },
  } = useSwConfigData(InstitutionConfigurationTypes.INST_BANNER_2);

  const handleBannerData = () => {
    let banner = "";
    if (EduateBannerDetails) {
      const { config_boolean_value } =
        EduateBannerDetails.GetSwEduateConfigVariableByConfigKey;
      if (config_boolean_value) {
        banner =
          EduateBannerDetails.GetSwEduateConfigVariableByConfigKey
            .config_string_value;
      }

      if (InstBanner1Details) {
        const { config_boolean_value } =
          InstBanner1Details.GetSwConfigVariables[0];
        if (config_boolean_value) {
          banner +=
            " " +
            InstBanner1Details.GetSwConfigVariables[0].config_string_value;
        }
      }

      if (InstBanner2Details) {
        const { config_boolean_value } =
          InstBanner2Details.GetSwConfigVariables[0];
        if (config_boolean_value) {
          banner +=
            "    " +
            InstBanner2Details.GetSwConfigVariables[0].config_string_value;
        }
      }
    }

    return banner;
  };
  return (
    <>
      <div className="addressbar">
        <div className="addressbar__slide-in">
          <span>{handleBannerData()}</span>
        </div>
      </div>
    </>
  );
};

export default Index;

import Home from "../../Home/Index";
import PreviewTabs from "./PreviewTabs";
import EmployeeDetails from "../Registration/EmployeeDetails";
import React from "react";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import EmpDocsUploadComponent from "../Registration/EmpDocsUploadComponent";
import { Operation } from "../../../../utils/Enum.types";

const EmpDocsPreview = () => {
  const navigate = useNavigate();

  return (
    <>
      <Home DashBoardRequired={false} />
      <PreviewTabs />
      <div className="emp-preview">
        <EmployeeDetails />
        <div className="emp-preview__frame--personal">
          <div className="emp-preview__frame--data row">
            <EmpDocsUploadComponent type={Operation.UPDATE} />
          </div>

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default EmpDocsPreview;

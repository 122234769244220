import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { PayRollMasters } from "../../HR/enums/Enum.types";
import usePayRollMasters from "../../HR/hooks/usePayRollMasters";
import usePayRollMastersConfig from "../../HR/hooks/usePayRollMastersConfig";
import { GetAcdSubjectTeacherAssociationsList } from "../hooks/useSubjTeacherAssoci";
import { ACTION, EMPLOYEEGRADE } from "../../HR/constants";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import { useLazyQuery, useMutation } from "@apollo/client";

import { Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { TeacherDrawerDetails } from "./Index";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsData,
  GetAcdTeacherClassAssociationsVars,
} from "../types/subject/Subject";
import {
  GetAcdTeacherClassAssociations,
  GetAcdTeacherSubjectClassAssociations,
} from "../queries/subjects/List.tsx/Index";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { AddOrDeleteAcdTeacherClassAssoci } from "../queries/teacher_association/mutations";
import TeachersList, { teacherDetails } from "./TeachersList";
import MessageModal from "../../../pages/MessageModal";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { AllocateTeachersDrawer } from "../../../styles/DrawerStyles";
const { Academics_Table } = require("../json/table.json");

interface Props {
  subjectDetails: TeacherDrawerDetails;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const View = ({ setModal, subjectDetails }: Props) => {
  const { user_details } = useLoggedInUserDetails();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const [completeDetails, setCompleteDetails] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [assign, setAssign] = useState(false);
  const [updateOperation, setUpdateOperation] = useState(Operation.CREATE);
  const [searchGrade, setSearchGrade] = useState("");
  const [searchEmp, setSearchEmp] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [items, setItems] = useState<teacherDetails[]>([]);

  const { subj_code, subj_id, subj_name } = subjectDetails;
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;

  const { dropDown } = usePayRollMasters(
    PayRollMasters.GRADE,
    searchGrade,
    null
  );

  const { USE_HR_GRADE } = usePayRollMastersConfig();
  const searchFilter = (emp_details: GetAcdSubjectTeacherAssociationsList) => {
    const { pr_emp_details } = emp_details;
    return searchEmp
      ? `${pr_emp_details.emp_first_name} ${pr_emp_details.emp_middle_name} ${pr_emp_details.emp_last_name}`
          .toLowerCase()
          .includes(searchEmp.toLowerCase()) ||
          pr_emp_details.emp_id.toLowerCase().includes(searchEmp.toLowerCase())
      : true;
  };
  const { entry_level } = useInstitutionConfiguration();
  const [AssociateTeachersWithSubject] = useMutation(
    AddOrDeleteAcdTeacherClassAssoci,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const { flag } = useCheckAllocationType();
  const [GetSubjectsTeachersAssociated, { data, error }] = useLazyQuery<
    GetAcdTeacherClassAssociationsData,
    GetAcdTeacherClassAssociationsVars
  >(GetAcdTeacherClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
      per_std_subj_allocation: flag,
      input: {
        query_type: AcdTeacherClassQueryType.ALL_TEACHERS_BY_SUBJECT,
        entry_id: Number(entryId)!,
        entry_level: entry_level,
        pr_emp_id: 0,
        subj_master_id: subjectDetails.subj_id,
      },
    },
  });

  const handleDelete = () => {
    AssociateTeachersWithSubject({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        entry_level: entry_level,
        entry_id: entryId,
        per_std_subj_allocation: flag,
        input: {
          add_class_teacher: [],
          delete_class_teacher: items?.map((teacher) => ({
            is_class_teacher: teacher.is_class_teacher,
            pr_emp_id: teacher.pr_emp_id,
            subj_master_id: teacher.subj_master_id,
          })),
        },
      },
      refetchQueries: [
        {
          query: GetAcdTeacherSubjectClassAssociations,
          variables: {
            acd_yr_id: activeAcdId,
            entry_id: Number(entryId)!,
            entry_level: entry_level,
            inst_id: InstId!,
            per_std_subj_allocation: flag,

            token,
          },
        },
        {
          query: GetAcdTeacherClassAssociations,
          variables: {
            acd_yr_id: activeAcdId,
            inst_id: InstId!,
            token,
            per_std_subj_allocation: flag,
            input: {
              query_type: AcdTeacherClassQueryType.ALL_TEACHERS_BY_SUBJECT,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              pr_emp_id: 0,
              subj_master_id: subjectDetails.subj_id,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Removed Allocated Teacher with Subject",
          operation: Operation.CREATE,
        });
      }
    });
  };
  useEffect(() => {
    if (
      entryId &&
      entry_level &&
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetSubjectsTeachersAssociated();
    }
  }, [
    entryId,
    GetSubjectsTeachersAssociated,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    entry_level,
    state.ActiveAcdYr,
  ]);
  return (
    <>
      <div className="allocate-teacher__assign">
        <Title>View Details</Title>

        <div className="allocate-teacher__assign--sub-details">
          <div className="allocate-teacher__assign--sub-details--grid">
            <span>Subject Code</span>
            <span>:</span>
            <span className="allocate-teacher__assign--sub-details--code">
              {subj_code}
            </span>
          </div>
          <div className="allocate-teacher__assign--sub-details--grid">
            <span>Subject Name</span>
            <span>:</span>
            <span className="allocate-teacher__assign--sub-details--code">
              {subj_name}
            </span>
          </div>
        </div>
        <Title variant="subtitle1">Assigned Teachers List</Title>
        <div className="allocate-teacher__assign--filters row g-0">
          <div className="col-5">
            <Input
              placeholder="Search..."
              id="search"
              onChange={(e) => {
                setSearchEmp(e.target.value);
              }}
            />
          </div>
          <div className="col"></div>
          {USE_HR_GRADE ? (
            <div className="col-3">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={dropDown}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setSearchGrade(e.target.value)}
                    className={labelClasses.formControlRoot}
                    label="Employee Grade"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="allocate-teacher__assign--tableblock">
          {error ? (
            <b className="nodata">{error.message}</b>
          ) : (
            <TableContainer className="allocate-teacher__assign--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Academics_Table.AllocateTeachers.TeachersList.filter(
                      (th: TableHeaderProps) =>
                        USE_HR_GRADE
                          ? th.labelName !== ACTION
                          : th.labelName !== EMPLOYEEGRADE &&
                            th.labelName !== ACTION &&
                            th.labelName !== "Years of Experience"
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.GetAcdTeacherClassAssociations.length
                    ? data.GetAcdTeacherClassAssociations.filter(
                        searchFilter
                      ).map((teacher, index) => (
                        <TableRow key={index}>
                          <TableCell
                            className="allocate-teacher__assign--table--slno"
                            id="td-center"
                          >
                            {deleteFlag ? (
                              <Checkbox
                                checked={items.some(
                                  (item) => item.pr_emp_id === teacher.pr_emp_id
                                )}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  if (checked) {
                                    setItems((prevItems) => [
                                      ...prevItems,
                                      {
                                        is_class_teacher: false,
                                        pr_emp_id: teacher.pr_emp_id,
                                        subj_master_id: teacher.subj_master_id,
                                      },
                                    ]);
                                  } else {
                                    setItems((prevItems) =>
                                      prevItems.filter(
                                        (item) =>
                                          item.pr_emp_id !== teacher.pr_emp_id
                                      )
                                    );
                                  }
                                }}
                              />
                            ) : null}
                            {index + 1}
                          </TableCell>
                          <TableCell className="allocate-teacher__assign--table--id">
                            {teacher.pr_emp_details.emp_id}
                          </TableCell>
                          <TableCell
                            className="allocate-teacher__assign--table--name"
                            onClick={() => {
                              dispatch({
                                payload: { employeeId: teacher.pr_emp_id },
                                type: payloadTypes.SET_EMPLOYEE_ID,
                              });
                            }}
                          >
                            {`${teacher.pr_emp_details.emp_first_name} ${teacher.pr_emp_details.emp_middle_name} ${teacher.pr_emp_details.emp_last_name}`}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {!deleteFlag ? (
              <Button
                mode="assign"
                onClick={() => {
                  dispatch({
                    type: payloadTypes.SET_SUBJECT_ID,
                    payload: {
                      subjectId: subjectDetails.subj_id,
                    },
                  });
                  setAssign(!assign);
                  setUpdateOperation(Operation.UPDATE);
                }}
              >
                &nbsp;Teachers
              </Button>
            ) : null}
            {(deleteFlag && items.length > 0) || !deleteFlag ? (
              <Button
                mode="remove"
                onClick={() => {
                  deleteFlag ? handleDelete() : setDeleteFlag(!deleteFlag);
                }}
              />
            ) : null}
            <Button
              mode="cancel"
              onClick={() => {
                deleteFlag ? setDeleteFlag(!deleteFlag) : setModal(false);
              }}
            />
          </div>
          <div className="col-4">
            <div className="student-total-count">
              Total Teachers :{" "}
              <b>
                {data && data.GetAcdTeacherClassAssociations
                  ? data.GetAcdTeacherClassAssociations.length
                  : 0}
              </b>
            </div>
          </div>
        </div>
      </div>
      <AllocateTeachersDrawer
      
        anchor="right"
        open={completeDetails}
        onClose={() => setCompleteDetails(!completeDetails)}
      >
        {/* <CompleteDetails setModal={setCompleteDetails} /> */}
      </AllocateTeachersDrawer>

      <AllocateTeachersDrawer
     
        anchor="right"
        open={assign}
        onClose={() => setAssign(!assign)}
      >
        <TeachersList
          subjectId={subj_id}
          updateOperation={updateOperation}
          setPerSubjectTeacherList={setAssign}
        />
      </AllocateTeachersDrawer>
      <MessageModal
        handleClose={() => {
          if (message.operation !== Operation.NONE && message.flag) {
            setModal?.(false);
          }
          setMessage({
            message: "",
            flag: false,
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default View;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import { Direction } from "../../../../utils/Enum.types";
import { responseType } from "../../../../utils/Form.types";
import { PageInfo } from "../../../Templates/utils/types";
import {
  GetAcctStockItemCategories,
  GetAcctStockItemGroups,
  GetAcctStockItemUoms,
} from "../../queries/purchages/queries";
export interface GetAcctStockItemCategorysNode {
  item_category_desc: string;
  item_category_in_use: boolean;
  id: number;
}
interface GetAcctStockItemCategorysEdges {
  node: GetAcctStockItemCategorysNode;
}
interface GetAcctStockItemCategorysDetails {
  edges: GetAcctStockItemCategorysEdges[];
  totalCount: number;
  pageInfo: PageInfo;
}
interface GetAcctStockItemCategorysDetailsData {
  GetAcctStockItemCategories: GetAcctStockItemCategorysDetails;
}

export interface GetAcctStockItemGroupsNode {
  item_grp_desc: string;
  item_grp_in_use: boolean;

  id: number;
}
interface GetAcctStockItemGroupsEdges {
  node: GetAcctStockItemGroupsNode;
}
interface GetAcctStockItemGroupsDetails {
  edges: GetAcctStockItemGroupsEdges[];
  totalCount: number;
  pageInfo: PageInfo;
}
interface GetAcctStockItemGroupsDetailsData {
  GetAcctStockItemGroups: GetAcctStockItemGroupsDetails;
}
interface Vars {
  token: string;
  inst_id: string;
  first: number;
  after: string | null;

  orderBy: { direction: Direction; field?: string };
}

interface GroupVars extends Vars {
  query_type: "ALL_ITEM_GROUPS" | "ACTIVE_ITEM_GROUPS";
}

export interface GetAcctStockItemUmNode {
  item_uom_desc: string;
  item_uom_in_use: boolean;
  id: number;
}
interface GetAcctStockItemUmEdges {
  node: GetAcctStockItemUmNode;
}

interface GetAcctStockItemUmDetails {
  edges: GetAcctStockItemUmEdges[];
  totalCount: number;
  pageInfo: PageInfo;
}
interface GetAcctStockItemUmDetailsData {
  GetAcctStockItemUoms: GetAcctStockItemUmDetails;
}
interface Vars {
  token: string;
  inst_id: string;
  first: number;
  after: string | null;

  orderBy: { direction: Direction; field?: string };
}
interface GroupVars extends Vars {
  query_type: "ALL_ITEM_GROUPS" | "ACTIVE_ITEM_GROUPS";
}

interface UmVars extends Vars {
  query_type: "ALL_ITEM_UOMS" | "ACTIVE_ITEM_UOMS";
}

interface CategoriesVars extends Vars {
  query_type: "ALL_ITEM_CATEGORIES" | "ACTIVE_ITEM_CATEGORIES";
}
const usePurchasesMasterData = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [categoriesResponseType, setCategoriesResposnseType] = useState<
    responseType[]
  >([]);
  const [groupsResponseType, setGroupsResposnseType] = useState<responseType[]>(
    []
  );
  const [umResponseType, setUmResponseType] = useState<responseType[]>([]);
  const [
    GetCategorys,
    { data: Categories, loading: CategoriesLoading, error: CategoriesError },
  ] = useLazyQuery<GetAcctStockItemCategorysDetailsData, CategoriesVars>(
    GetAcctStockItemCategories,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );
  const [
    GetGroups,
    { data: Groups, loading: GroupsLoading, error: GroupsError },
  ] = useLazyQuery<GetAcctStockItemGroupsDetailsData, GroupVars>(
    GetAcctStockItemGroups,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );
  const [
    GetMeasurement,
    {
      data: Measuremeants,
      loading: measureMentssLoading,
      error: measurementsError,
    },
  ] = useLazyQuery<GetAcctStockItemUmDetailsData, UmVars>(
    GetAcctStockItemUoms,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (token && InstId) {
      GetCategorys({
        variables: {
          after: null,
          first: ROWS_PER_PAGE,
          inst_id: InstId!,
          orderBy: { direction: Direction.ASC },
          token,
          query_type: "ALL_ITEM_CATEGORIES",
        },
      }).then(({ data }) => {
        if (data) {
          setCategoriesResposnseType(
            data.GetAcctStockItemCategories.edges.map((edge) => ({
              label: edge.node.item_category_desc,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
      GetGroups({
        variables: {
          after: null,
          first: ROWS_PER_PAGE,
          inst_id: InstId!,
          orderBy: { direction: Direction.ASC },
          token,
          query_type: "ALL_ITEM_GROUPS",
        },
      }).then(({ data }) => {
        if (data) {
          setGroupsResposnseType(
            data.GetAcctStockItemGroups.edges.map((edge) => ({
              label: edge.node.item_grp_desc,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
      GetMeasurement({
        variables: {
          after: null,
          first: ROWS_PER_PAGE,
          inst_id: InstId!,
          orderBy: { direction: Direction.ASC },
          token,
          query_type: "ALL_ITEM_UOMS",
        },
      }).then(({ data }) => {
        if (data) {
          setUmResponseType(
            data.GetAcctStockItemUoms.edges.map((edge) => ({
              label: edge.node.item_uom_desc,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [token, InstId, GetGroups, GetMeasurement, GetCategorys]);
  return {
    Categories: {
      Categories,
      CategoriesLoading,
      CategoriesError,
      categoriesResponseType,
    },
    Groups: {
      Groups,
      groupsResponseType,
      GroupsLoading,
      GroupsError,
    },
    UOM: {
      Measuremeants,
      measureMentssLoading,
      measurementsError,
      umResponseType,
    },
  };
};

export default usePurchasesMasterData;

import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import * as XLSX from "xlsx";

// import Home from "../../Home/Index";
// import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../images/TotalMale.svg";
import ReadyToImport from "../../images/Present.svg";
import ContainsError from "../../images/Absent.svg";
import Info from "../../images/Information.svg";
import DownArrow from "../../images/DownArrow.svg";
import FileAttach from "../../images/BrowseFiles.svg";

import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Home from "./Home/Index";
import Close from "../../images/Close.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { ValidateStudentDataForImport } from "../../../../queries/xls";

// import { ImportStudentDataFromXls } from "../../../../queries/xls/mutation";
import Validate from "../../images/Generate.svg";

import {
  getHeaderRowStyle,
  handleClear,
  removeMoreSpace,
  toStandardDate,
} from "../../utils/UtilFunctions";
// import { _genderRegex, _isPhone } from "../../../../utils/validationRules";
import Modal from "react-modal";

import {
  ACC_HEADER_FONT,
  BLOB_TYPE,
  DOWNLOAD,
  ELEMENT,
  FIRST_INDEX,
  TABLE_HEADER_CSS,
  FROZEN_CELLS_1,
  FILENAME,
  EDUATE_IMG_FORMAT,
  FIRST_CELL,
  HEADER_CSS,
  HEADER_ALIGNMENT,
  A2_CELL,
  A3_CELL,
  ADDRESS_FONT,
  ADDRESS_ALIGNMENT,
  C4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  HEADER_ALIGNMENT_CENTER,
  E4_CELL,
  FIN_YEAR_FONT,
  A4_CELL,
  HEADER_ALIGNMENT_LEFT,
  FROZEN_CELLS_INST,
  BORDER_DATA,
} from "../Library/Constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  Operation,
  TableHeaders,
} from "../../utils/Enum.types";
import useToken from "../../customhooks/useToken";
import { Button } from "../../stories/Button/Button";
import { Title } from "../../stories/Title/Title";
import { PayRollMasters } from "../HR/enums/Enum.types";
import { msgType } from "../../utils/Form.types";
import MessageModal from "../../pages/MessageModal";
import { getMasterDetails } from "../HR/Imports/utils/GetMasterDetails";
import Eduate from "../../images/Eduate_Logo_image.png";
import useInstLogoDetails from "../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../Library/customHooks/useServerDateandTime";
import usePayRollMastersConfig from "../HR/hooks/usePayRollMastersConfig";
import useEmpMasterData from "../HR/hooks/useEmpMasterData";
import { ImportPayRollJustLoginAcct } from "../HR/queries/employee/mutation";
import useInstDetails from "../../customhooks/general/useInstDetails";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../context/context";
import { EMPTY_STRING } from "../../utils/constants";
import { LoadingStyles } from "../../styles/ModalStyles";
import ExcelLoading from "../../pages/ExcelLoading";
import { AccountentType } from "../Accounts/common/Enum.types";
import { CustomTooltip } from "../../styles/TooltipStyles";

const EmpImport = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { state } = useContext(AppContext);

  const [expanded, setExpanded] = useState(false);
  const [records, setRecords] = useState<any[]>([]);

  const [importModal, setImportModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });

  const { InstDetails } = useInstDetails(1);
  const userTypeOptions = Object.values(AccountentType);

  const [AddEmployees, { loading }] = useMutation(ImportPayRollJustLoginAcct);
  const {
    categoryDropDown,
    departmentDropDown,
    designationDropDown,
    gradeDropDown,
    jobTypeDropDown,
  } = useEmpMasterData();

  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { serverDate } = useServerDateandTime();

  const InstName = InstDetails.data?.nodes[0]?.inst_name;
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const { sheetname } =
    getMasterDetails(PayRollMasters.JOB_TYPE, records) || {};
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;
  const masterConfig = [
    {
      Headers: "Department",
      key: "Department",
      width: 25,
    },
    {
      Headers: "Designation",
      key: "Designation",
      width: 25,
    },
    {
      Headers: "EmpType",
      key: "EmpType",
      width: 25,
    },
  ];

  const employeeData = [
    {
      Headers: "Name",
      key: "emp_first_name",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Mobile",
      key: "emp_mobile",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Email",
      key: "emp_email",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "EmpType",
      key: "EmpType",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },

    {
      Headers: "Department",
      width: 50,
      required: false,
      type: String,
      key: "Department",
      use_in_sheet: true,
    },

    {
      Headers: "Designation",
      width: 50,
      required: false,
      type: String,
      key: "Designation",
      use_in_sheet: true,
    },
  ];
  const employee_schema = {
    Name: {
      name: "emp_first_name",
      required: true,
    },
    Mobile: {
      name: "emp_mobile",
      required: false,
    },
    Email: {
      name: "emp_email",
      required: false,
    },
    EmpType: {
      name: "EmpType",
      required: true,
    },

    Department: {
      name: "Department",
      required: false,
    },

    Designation: {
      name: "Designation",
      required: true,
    },
  };
  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      emp_id: f.EmployeeId,
      emp_title: f.EmployeeTitle,
      emp_first_name: f.FirstName,
      emp_middle_name: f.MiddleName,
      emp_last_name: f.LastName,
      emp_sex: f.Gender,
      emp_dob: f.DOB,
      emp_mobile: f.Mobile,
      emp_email: f.Email,
      emp_qualification: f.Qualification,
      emp_experience: f.Experience,
      emp_status: f.Status,
      emp_on_roll: f.OnRole,
      emp_doj: f.DOJ,
      emp_dor: f.DOR,
      ParentDesignation: f.ParentDesignation,
      ...(USE_HR_DEPARTMENT ? { Department: f.Department } : {}),
      ...(USE_HR_CATEGORY ? { Category: f.Category } : {}),
      ...(USE_HR_DESIGNATION ? { Designation: f.Designation } : {}),
      ...(USE_HR_GRADE ? { Grade: f.Grade } : {}),
      ...(USE_HR_JOBTYPE ? { JobType: f.JobType } : {}),
    }));

  const downloadExcel = (forError: boolean) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet(sheetname);
    worksheet.views = FROZEN_CELLS_INST;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    worksheet1.views = FROZEN_CELLS_1;

    worksheet1.getColumn(1).width = 30;
    worksheet1.getColumn(2).width = 30;
    worksheet1.getColumn(3).width = 30;
    worksheet1.getColumn(4).width = 30;
    worksheet1.getColumn(5).width = 30;
    worksheet1.getColumn(6).width = 30;
    worksheet1.getColumn(7).width = 30;
    worksheet1.getColumn(8).width = 30;
    worksheet1.getColumn(9).width = 30;
    worksheet1.getColumn(10).width = 30;
    worksheet1.getColumn(11).width = 30;
    worksheet1.getColumn(12).width = 30;
    worksheet1.getColumn(13).width = 30;
    worksheet1.getColumn(14).width = 30;
    worksheet1.getColumn(15).width = 30;
    worksheet1.getColumn(16).width = 30;
    worksheet1.getColumn(17).width = 30;
    worksheet1.getColumn(18).width = 30;
    worksheet1.getColumn(19).width = 30;
    worksheet1.getColumn(20).width = 30;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = masterConfig.map((f) => f.Headers);
                  let headers2 = employeeData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);

                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);
                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  for (let i = 0; i < headers2.length; i++) {
                    const Char = String.fromCharCode(65 + i);
                    const rowData2: Cell = worksheet1.getCell(Char + 1);
                    rowData2.value = headers2[i];
                    rowData2.fill = TABLE_HEADER_CSS;
                    rowData2.border = BORDER_DATA;
                    rowData2.font = ACC_HEADER_FONT;
                    rowData2.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }
                  let data_char = FIRST_INDEX;
                  for (let i = 0; i < headers.length; i++) {
                    data_char = String.fromCharCode(
                      data_char.charCodeAt(0) + 1
                    );
                    let data_begins = 6;
                    if (headers[i] === "Department") {
                      for (let j = 0; j < departmentDropDown.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData: Cell = worksheet.getCell(
                          data_char + index_pos
                        );

                        rowData.value = departmentDropDown[j].label;
                        rowData.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                    if (headers[i] === "Designation") {
                      for (let j = 0; j < designationDropDown.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData.value = designationDropDown[j].label;
                        rowData.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                    if (headers[i] === "EmpType") {
                      for (let j = 0; j < userTypeOptions.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData.value = userTypeOptions[j];
                        rowData.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                    if (headers[i] === "JobType") {
                      for (let j = 0; j < jobTypeDropDown.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData.value = jobTypeDropDown[j].label;
                        rowData.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                    if (headers[i] === "Grade") {
                      for (let j = 0; j < gradeDropDown.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData.value = gradeDropDown[j].label;
                        rowData.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                    if (headers[i] === "Category") {
                      for (let j = 0; j < categoryDropDown.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData.value = categoryDropDown[j].label;
                        rowData.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                  }
                  if (forError) {
                    errorData.forEach((f) => worksheet1.addRow(f));
                  }
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName!);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];
    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      employee_schema &&
        // eslint-disable-next-line
        Object.keys(employee_schema).map((key) => {
          // @ts-ignore
          let keySchema = employee_schema[key];
          let keyValue = record[key];

          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleClick = (id: number) => {
    const filteredData = records.find((record) => record.id === id);

    if (filteredData) {
      const validationErrors: string[] = [];

      // Perform your manual validation logic here
      if (!filteredData.Name) {
        validationErrors.push("Name is required.");
      }
      if (!filteredData.EmpType) {
        validationErrors.push("Employee Type is required.");
      }
      if (!filteredData.EmpType) {
        validationErrors.push("Employee Type is required.");
      } else if (
        !Object.values(AccountentType).includes(
          filteredData.EmpType.toUpperCase()
        )
      ) {
        validationErrors.push("Invalid employee type.");
      }
      if (!filteredData.Mobile && !filteredData.Email) {
        validationErrors.push("Either mobile number or email is required.");
      } else {
        if (filteredData.Email && !validateEmail(filteredData.Email)) {
          validationErrors.push("Invalid email format.");
        }
        if (filteredData.Mobile && !validatePhoneNumber(filteredData.Mobile)) {
          validationErrors.push("Invalid phone number.");
        }
      }

      // Example date conversion function

      if (validationErrors.length === 0) {
        const newData = records.map((record) => {
          if (id === record.id) {
            return {
              ...record,
              isChecked: !record.isChecked,
              isValidated: true,
              isValidatedAndError: false,
            };
          }
          return record;
        });
        setRecords(newData);
      } else {
        const newData = records.map((record) => {
          if (record.id === id) {
            return {
              ...record,
              isChecked: !record.isChecked,
              isValidated: true,
              isValidatedAndError: true,
              errors: validationErrors,
            };
          }
          return record;
        });
        setRecords(newData);
      }
    }
  };

  // Example validation helper functions
  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phone: string): boolean => {
    const phonePattern = /^[0-9]{10}$/; // Example phone number format
    return phonePattern.test(phone);
  };
  const handleSubmit = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);
    for (const record of records.filter(
      (record) => !record.isValidatedAndError
      //   && record.isChecked
    )) {
      try {
        await AddEmployees({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: {
              emp_first_name: removeMoreSpace(record.Name),
              emp_email: record.Email,
              emp_mobile: record.Mobile ? `+91${record.Mobile}` : EMPTY_STRING,
              emp_type: record.EmpType?.toUpperCase(),
              pr_dept_name: record.Department,
              pr_designation_name: record.Designation,
              reporting_to_emp_name: EMPTY_STRING,
              parent_designation_name: EMPTY_STRING,
            },
          },

          onError: (err) => {
            record.errors = record.errors ? record.errors : [];
            record.errors.push(err.message);
            setMessage({
              flag: true,
              message: err.message,
              operation: Operation.NONE,
            });
            setErrorCount((prev) => prev + 1);
          },
        }).then(({ data }) => {
          if (data) {
            setSuccessCount((prev) => prev + 1);
          }
        });
      } catch (error) {
        console.error(error);
        setErrorCount((prev) => prev + 1);
      }
    }
    // setImportModal(false);
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(
    () => {
      const input = document.getElementById("input") as HTMLInputElement;
      if (input) {
        input.addEventListener("change", () => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const res = processExcel(reader.result);
            setRecords(
              res.map((f: any, index: number) => {
                return {
                  ...f,
                  id: index + 1,
                  isChecked: false,
                };
              })
            );
          };
          reader.readAsBinaryString(input.files![0]);
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Import Employee Data</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Employees
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell
                            className={classes.stickyHeaderCheckbox}
                            id="td-center"
                          >
                            <Checkbox />
                          </TableCell> */}
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          {employee_schema && // eslint-disable-next-line
                            Object.keys(employee_schema).map((key, index) => {
                              // @ts-ignore

                              return (
                                <TableCell key={index} id="td-center">
                                  {key}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => !record?.errors)
                          .map((record, index) => {
                            return (
                              <TableRow key={index}>
                                {/* <TableCell
                                className={classes.stickyColumnCheckbox}
                                id="td-center"
                              >
                                <Checkbox
                                  onClick={(e) => handleClick(record.id)}
                                  checked={record?.isChecked}
                                />
                              </TableCell> */}

                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>

                                {employee_schema && // eslint-disable-next-line
                                  Object.keys(employee_schema).map(
                                    // eslint-disable-next-line
                                    (key, index: React.Key) => {
                                      // @ts-ignore

                                      return (
                                        <TableCell key={index}>
                                          {record[key]}
                                        </TableCell>
                                      );
                                    }
                                  )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && records.filter((record) => record?.errors) && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell
                            className={classes.stickyHeaderCheckbox}
                            id="td-center"
                          ></TableCell> */}
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          <TableCell id="td-center">Category</TableCell>
                          <TableCell id="td-center">Error Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => {
                            const filteredHeaders: string[] = [];
                            return (
                              <TableRow key={index}>
                                {/* <TableCell
                                  className={classes.stickyColumnCheckbox}
                                  id="td-center"
                                >
                                  {!record?.errors ? (
                                    <Checkbox
                                      onClick={() => handleClick(index)}
                                      checked={record?.isChecked}
                                    />
                                  ) : null}
                                </TableCell> */}

                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                {Object.keys(employee_schema!).map(
                                  (key, index) => {
                                    // @ts-ignore
                                    const fieldSchema = employee_schema[key];

                                    filteredHeaders.push(key);
                                    return (
                                      <TableCell key={index}>
                                        {record[key]}
                                      </TableCell>
                                    );
                                  }
                                )}

                                <TableCell className="import-excel-data__datablock--error">
                                  <CustomTooltip
                                    title={
                                      <ul>
                                        {record?.errors
                                          ? record?.errors?.map(
                                              (error: any, index: number) => {
                                                return (
                                                  <li key={index}>
                                                    {index + 1})&nbsp;
                                                    {typeof error === "string"
                                                      ? error
                                                      : "Error message = " +
                                                        error.error +
                                                        ", column = " +
                                                        error.column}
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <div className="import-excel-data__datablock--tableblock--error-message">
                                      <span>Errors</span>
                                      <img src={Info} alt="/" />
                                    </div>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button
              onClick={() => {
                setImportModal(true);
                handleSubmit();
              }}
              mode="save"
              disabled={!records.length ? true : false}
            />
            <Button onClick={handleClick}>
              <img src={Validate} alt="" />
              Validate
            </Button>
            <Button onClick={handleClear} mode="clear" />

            <Button onClick={() => downloadExcel(true)} mode="export">
              Export error Data
            </Button>
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={() => downloadExcel(false)}>
              Generate Template
            </Button>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </Modal>
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default EmpImport;

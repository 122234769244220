import { Title } from "../../../stories/Title/Title";
import useFinancialYears from "../../Academics/hooks/useFinancialYears";
import useAcademicYears from "../../Academics/hooks/useAcademicYears";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InstitutionTabs from "../routes/InstitutionTabs";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../stories/Button/Button";
import {
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { WaiveOffModalStyles } from "../../../styles/ModalStyles";
import Modal from "react-modal";
import { useState } from "react";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { useMutation } from "@apollo/client";
import { UpdateAcdInstReOpeningDate } from "../../../queries/chatapplication/mutations";
import { Operation } from "../../../utils/Enum.types";
import MessageModal from "../../../pages/MessageModal";
import { msgType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import LoadingModal from "../../../pages/LoadingModal";

const ViewActiveYears = () => {
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { financialYearResponse } = useFinancialYears();
  const { academicYearResponse } = useAcademicYears();

  const [acdStartYearModal, setAcdStartYearModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const acdYearId = academicYearResponse
    ? academicYearResponse.data &&
      academicYearResponse.data.GetAcdYrsByInstId.filter(
        (res) => res.acd_is_curr_yr
      )[0].id
    : 0;
  const [UpdateInstOpeningDate, { loading }] = useMutation(
    UpdateAcdInstReOpeningDate,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const Handleupdate = () => {
    if (startDate && acdYearId) {
      UpdateInstOpeningDate({
        variables: {
          token,
          user_details,
          inst_id: InstId!,
          acd_yr_id: acdYearId,
          re_opening_date: toIsoDate(startDate),
        },
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Updated Institution Re-Opening Date",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setAcdStartYearModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <InstitutionTabs />
      <Title>Active Years</Title>
      <div className="active-years">
        <div className="row g-0 active-years__datablock">
          <div className="col booktype-left h-100">
            <Title variant="subtitle1">Financial Year</Title>
            <div className="active-years__tableblock">
              <TableContainer className="active-years__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl</TableCell>
                      <TableCell>Financial Year</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {financialYearResponse.data?.GetFinYrsByInstId.map(
                      (year, index) => {
                        return (
                          <TableRow
                            className={
                              year.fin_is_curr_yr ? "activefinancialyear" : ""
                            }
                          >
                            <TableCell
                              id="td-center"
                              className="active-years__tableblock--table"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell>{year.fin_yr} </TableCell>
                            <TableCell>
                              {toStandardDate(year.fin_st_date)}
                            </TableCell>
                            <TableCell>
                              {toStandardDate(year.fin_end_date)}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="col booktype-right h-100">
            <Title variant="subtitle1">Academic Year</Title>
            <div className="active-years__tableblock">
              <TableContainer className="active-years__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl</TableCell>
                      <TableCell>Academic Year</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Re-Open Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {academicYearResponse &&
                      academicYearResponse.data &&
                      academicYearResponse.data.GetAcdYrsByInstId.map(
                        (year, index) => {
                          return (
                            <TableRow
                              className={
                                year.acd_is_curr_yr ? "activefinancialyear" : ""
                              }
                            >
                              <TableCell
                                id="td-center"
                                className="active-years__tableblock--table"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell>{year.acd_yr}</TableCell>
                              <TableCell>
                                {toStandardDate(year.acd_st_date)}
                              </TableCell>
                              <TableCell>
                                {toStandardDate(year.acd_end_date)}
                              </TableCell>
                              <TableCell>
                                {toStandardDate(year.re_opening_date)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Button onClick={() => setAcdStartYearModal(!acdStartYearModal)}>
              Update Institution Start Date
            </Button>
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={acdStartYearModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Label>Start Date</Label>
            <Input
              type="date"
              value={toInputStandardDate(startDate)}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <br></br>

            <Button mode="save" onClick={Handleupdate} />
            <Button mode="cancel" onClick={() => setAcdStartYearModal(false)} />
          </div>
          <div className="modal-flex__image"></div>
        </div>
      </Modal>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default ViewActiveYears;

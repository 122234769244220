import useInstLabels from "../../../customhooks/general/useInstLabels";

const useTransportTableJson = () => {
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();
  const tableJson = {
    Transport_Table: {
      MasterData: {
        Route: [
          {
            labelName: "Sl",
            className: "transport-route__details--table--th-slno",
          },
          {
            labelName: "Description",
          },
          {
            labelName: "Start Date",
            className: "transport-route__details--table--th-date",
          },
          {
            labelName: "Status",
            className: "transport-route__details--table--status",
          },
          {
            labelName: "Actions",
            className: "transport-route__details--table--actions",
          },
        ],
        RouteDetails: [
          {
            labelName: "Sl",
            className: "transport-route-details__table--th-slno",
          },
          {
            labelName: "Stage Description",
          },

          {
            labelName: "Ledger",
            className: "transport-route-details__table--ledger",
          },
          {
            labelName: "Amount",
            className: "transport-route-details__table--ledger",
          },
          {
            labelName: "Actions",
            className: "transport-route-details__table--actions",
          },
        ],
        StudentDetails: [
          {
            labelName: "Sl",
            className: "transport-route-details__table--th-slno",
          },
          {
            labelName: "Student Name",
          },

          {
            labelName: "Stage",
            className: "transport-route-details__table--actions",
          },

          {
            labelName: "Amount",
            className: "transport-route-details__table--actions",
          },
          {
            labelName: `${branchLabel}`,
            className: "transport-route-details__table--actions",
          },
          {
            labelName: `${classLabel}`,
            className: "transport-route-details__table--actions",
          },
        ],
        ReOrder: [
          {
            labelName: "Sl",
            className: "transport-route-details__table--th-slno",
          },
          {
            labelName: "Stages Description",
          },
        ],
        TransLedger: [
          {
            labelName: "Sl",
            className: "transport-route-details__table--th-slno",
          },
          {
            labelName: "Transport Ledger Description",
          },
          {
            labelName: "Amount",
          },
          {
            labelName: "Actions",
            className: "transport-route-details__table--actions",
          },
        ],
        RoutesAssignedStudentList: [
          { labelName: "Sl" },
          { labelName: "Adm No." },
          { labelName: "Reg No." },
          { labelName: "Student Name" },
          { labelName: `${categoryLabel}` },
          { labelName: "Bus Route" },
          { labelName: "Stage At" },
          { labelName: "Ledger" },
          { labelName: "Amount" },
          { labelName: "Actions" },
        ],
      },
      Dashboard: {
        RoutesNotAssignedStudentList: [
          { labelName: "Sl" },
          { labelName: "Adm No." },
          { labelName: "Reg No." },
          { labelName: "Student Name" },
          { labelName: `${branchLabel}` },
          { labelName: `${classLabel}` },
          { labelName: "Actions" },
        ],
      },
      TransportLedger: [
        { labelName: "Transport Ledger" },
        { labelName: "Amount" },
      ],
    },
  };
  return tableJson;
};

export default useTransportTableJson;

import {  TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { useParams } from "react-router";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import { BooksAllocationTitleProps } from "../../../Types/Titles";
import {
  Direction,
  LibBookMasterQueryType,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetBookDamageId,
  GetLibBookMaster,
  UpdateLibBookDamagedStatus,
} from "../../../queries/Library/BookEntry/list/byInstId";
import useToken from "../../../customhooks/useToken";
import { Label } from "../../../stories/Label/Label";
import useShelfOptions, { shelfType } from "../customHooks/useShelfOptions";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { labelClasses, LabeledAutocomplete } from "../../../styles/AutocompleteListStyles";
const { Library } = require("../../../json/title.json");

interface BookFormDataType {
  book_access_no: string;
  book_title: string;
  author_name: string;
  publisher_name: string;
  classification_no: string;
}
interface classificationData {
  classification_no: string;
  publisher: {
    publisher_name: string;
  };
  author: {
    author_name: string;
  };
}
interface GetDamageBooksDetails {
  id: number;
  book_access_no: string;
  book_title: string;
  classification: classificationData;
}
export interface GetBookDamageData {
  node: GetDamageBooksDetails;
}
interface GetDamageBooksVars {
  id: number;
  token: string;
}

interface Props {
  id: number;
  setAllocateToShelf: React.Dispatch<React.SetStateAction<boolean>>;
}
const RepairedBooks = ({ id, setAllocateToShelf }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  
  const { user_details } = useLoggedInUserDetails();
  
  const [searchToShelf, setToSearchShelf] = useState("");
  const [rackId, setRackId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [formData, setFormData] = useState<BookFormDataType>({
    classification_no: "",
    book_access_no: "",
    book_title: "",
    publisher_name: "",
    author_name: "",
  });

  const [UpdateDamageBookData] = useMutation(UpdateLibBookDamagedStatus, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const { shelves: toShelves } = useShelfOptions(searchToShelf);

  const [GetDamBooks] = useLazyQuery<GetBookDamageData, GetDamageBooksVars>(
    GetBookDamageId
  );

  useEffect(() => {
    if (id > 0) {
      GetDamBooks({
        variables: {
          id,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          setFormData({
            classification_no: data.node.classification.classification_no,
            book_access_no: data.node.book_access_no,
            book_title: data.node.book_title,
            publisher_name: data.node.classification.publisher.publisher_name,
            author_name: data.node.classification.author.author_name,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [GetDamBooks, token, id]);
  const handleClear = () => {
    setFormData({
      classification_no: "",
      book_access_no: "",
      book_title: "",
      publisher_name: "",
      author_name: "",
    });
    setRackId(0);
  };
  const HandleDamageBookSubmit = () => {
    UpdateDamageBookData({
      variables: {
        token,
        book_id: id,
        inst_id: InstId,
        user_details,
        rack_id: rackId,
      },
      refetchQueries: [
        {
          query: GetLibBookMaster,
          variables: {
            token,
            input: {
              book_query_type: LibBookMasterQueryType.BOOKS_THAT_ARE_DAMAGED,
              ids: [Number(InstId)],
            },
            first: ROWS_PER_PAGE,
            sortBy: SortBy.ACCESS_NO,
            direction: Direction.ASC,
            access_no: EMPTY_STRING,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: " DamageBook Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });

    setFormData({
      classification_no: "",
      book_access_no: "",
      book_title: "",
      publisher_name: "",
      author_name: "",
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    setAllocateToShelf(false);
  };

  return (
    <>
      <div className="repaired-books">
        <div className="modal-flex">
          <div className="modal-flex__data h-100">
            <Title>
              {Library.Titles.BooksShelfAllocation.map(
                (title: BooksAllocationTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.AllocateRepairedBooks}
                    </React.Fragment>
                  );
                }
              )}
            </Title>
            <div className="row g-0 repaired-books__allocate">
              <div className="col">
                <TextField
                  id="outlined-uncontrolled"
                  label="Classification No."
                  className="repaired-books__allocate--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={formData.classification_no}
                  disabled
                />
                <TextField
                  id="outlined-uncontrolled"
                  label="Accession No."
                  className="repaired-books__allocate--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={formData.book_access_no}
                  disabled
                />
                <TextField
                  id="outlined-uncontrolled"
                  label="Title"
                  className="repaired-books__allocate--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={formData.book_title}
                  disabled
                />
                <TextField
                  id="outlined-uncontrolled"
                  label="Publisher"
                  className="repaired-books__allocate--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={formData.publisher_name}
                  disabled
                />
                <TextField
                  id="outlined-uncontrolled"
                  label="Author"
                  className="repaired-books__allocate--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={formData.author_name}
                  disabled
                />
              </div>
              <div className="col">
                <Label>Allocate Book to Shelf</Label>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={toShelves}
                  getOptionLabel={(option) =>
                    ((option as shelfType).lib_rack_desc + " " + (option as shelfType).lib_rack_no).toString()
                  }
                  value={toShelves.find((data) => data.id === rackId) ?? null}
                  openOnFocus
                  onChange={(e, newValue) => {
                    setRackId((newValue as shelfType)?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      fullWidth
                      onChange={(e) => setToSearchShelf(e.target.value)}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            </div>
            <Button
              mode="save"
              type="button"
              onClick={() => {
                HandleDamageBookSubmit();
                handleClear();
              }}
            />
            <Button
              mode="cancel"
              onClick={() => {
                setAllocateToShelf(false);
                handleClear();
              }}
            />
          </div>
        </div>
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default RepairedBooks;

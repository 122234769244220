import useInstLabels from "../../../customhooks/general/useInstLabels";

const useLogsTableJson = () => {
  const { branchLabel, classLabel } = useInstLabels();
  const tableJson = {
    Logs_Table: {
      FeeReceiptDetails: [
        { labelName: "Sl" },
        { labelName: "Receipt No." },
        { labelName: "Date" },
        { labelName: "Admission No." },
        { labelName: "Reg No." },
        { labelName: "Student Name" },
        { labelName: branchLabel },
        { labelName: classLabel },
        { labelName: "Receipt Amount" },
        { labelName: "Through A/c" },
        { labelName: "Transaction Date  & Time" },
      ],
      CompleteLogs: [
        { labelName: "Sl" },
        { labelName: "Table Name" },
        { labelName: "Module" },
        { labelName: "Employee ID" },
        { labelName: "Employee Name" },
        { labelName: "Transaction Date And Time" },
      ],
      VoucherDetails: [
        { labelName: "Sl" },
        { labelName: "Date" },
        { labelName: "Voucher No." },
        { labelName: "Through" },
        { labelName: "Paid to." },
        { labelName: "Narration" },
        { labelName: "Amount" },
        { labelName: "Time" },
      ],
    },
  };
  return tableJson;
};

export default useLogsTableJson;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AcdSubjectType, Direction } from "../../../utils/Enum.types";
import { SubjectsByAcdYear } from "../../../utils/studentAcdEnum.types";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { EMPTY_RESPONSETYPE, EMPTY_STRING } from "../../../utils/constants";
import { GetAcdSubject } from "../queries/subjects/List.tsx/Index";
import useActiveAcademicYear from "./useActiveAcademicYear";
import { AppContext } from "../../../context/context";
import { ElectiveDetailsData } from "./useSubjectAllotedForClass";
interface input {
  acd_subj_query_type: SubjectsByAcdYear | string;
  ids: number[];
  str_data?: string[];
}
export interface GetSubjectvars {
  name: string;
  after: string | null;
  sortBy?: string;
  direction: string;
  first: number;
  token: string;
  acd_yr_id: number;
  input: input;
}
export interface SubjectDetailsNode {
  id: number;
  subj_code: string;
  subj_desc: string;
  subj_idx: number;
  teacher_count: number;
  subj_board_code: string;
  subj_is_lang: boolean;
  subj_is_elective: boolean;
  subj_is_non_academic: boolean;
  subj_is_core_subject: boolean;
  subj_internals_avialed: boolean;
  subj_apptitude_avialed: boolean;
  subj_marks_in_grades: boolean;
  subj_is_lab: boolean;
  isChecked?: boolean;
  newAdded?: boolean;
  updated?: boolean;
  elective_details?: ElectiveDetailsData[];
  subject_master_id?: number;
}
export interface SubjectEdges {
  node: SubjectDetailsNode;
}
export interface SubjectPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}
export interface SubjectList {
  edges: SubjectEdges[];
  pageInfo: SubjectPageInfo;
  totalCount: number;
}
export interface GetSubjectData {
  GetAcdSubject: SubjectList;
}

export interface GetSubjectByIdData {
  node: SubjectDetailsNode;
}
const useAcademicSubjects = (
  classId: number,
  semesterId: number,
  sectionId: number,
  name: string,
  rowsPerPage: number,
  reportType: AcdSubjectType
) => {
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, combinationId } = useParams();
  const [ids, setIds] = useState<number[]>([]);
  const [acd_subj_query_type, setacd_subj_query_type] = useState("");
  const [responseType, setSubjects] = useState<responseType[]>([]);

  useEffect(() => {
    switch (reportType) {
      case AcdSubjectType.GENERAL:
        if (sectionId) {
          setIds([Number(InstId), sectionId]);
          setacd_subj_query_type(SubjectsByAcdYear.ACD_SUBJ_BY_SECTION_ID);
        } else if (semesterId) {
          setIds([Number(InstId), semesterId]);
          setacd_subj_query_type(SubjectsByAcdYear.ACD_SUBJ_BY_SEMESTER_ID);
        } else if (classId) {
          setIds([Number(InstId), classId]);
          setacd_subj_query_type(SubjectsByAcdYear.ACD_SUBJ_BY_CLASS_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_subj_query_type(SubjectsByAcdYear.ACD_SUBJ_BY_INST_ID);
        }
        break;
      case AcdSubjectType.ELECTIVE:
        if (sectionId) {
          setIds([Number(InstId), sectionId]);
          setacd_subj_query_type(
            SubjectsByAcdYear.ACD_SUBJ_ELECTIVE_BY_SECTION_ID
          );
        } else if (semesterId) {
          setIds([Number(InstId), semesterId]);
          setacd_subj_query_type(
            SubjectsByAcdYear.ACD_SUBJ_ELECTIVE_BY_SEMESTER_ID
          );
        } else if (classId) {
          setIds([Number(InstId), classId]);
          setacd_subj_query_type(
            SubjectsByAcdYear.ACD_SUBJ_ELECTIVE_BY_CLASS_ID
          );
        }
        break;
      case AcdSubjectType.NON_ELECTIVE_SUBJECTS:
        if (InstId) {
          setIds([Number(InstId)]);
          setacd_subj_query_type(SubjectsByAcdYear.NON_ELECTIVE_SUBJECTS);
        }
        break;
      case AcdSubjectType.SUBJ_BY_COMBO_ID:
        if (InstId) {
          setIds([
            Number(InstId),
            combinationId ? Number(combinationId) : state.acdCombinationId,
          ]);
          setacd_subj_query_type(SubjectsByAcdYear.SUBJ_BY_COMBO_ID);
        }
        break;
      default:
        break;
    }
  }, [
    reportType,
    sectionId,
    semesterId,
    classId,
    InstId,
    state.acdCombinationId,
  ]);
  const [GetSubjects, { data, loading, error, fetchMore }] = useLazyQuery<
    GetSubjectData,
    GetSubjectvars
  >(GetAcdSubject, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      acd_subj_query_type !== EMPTY_STRING &&
      state.ActiveAcdYr
    ) {
      GetSubjects({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          after: null,
          direction: Direction.ASC,
          first: rowsPerPage,

          input: {
            acd_subj_query_type,
            ids,
          },
          name,
          token,
          sortBy: "SUBJ_CODE",
        },
      }).then(({ data }) => {
        if (data && data.GetAcdSubject) {
          const responseType = data.GetAcdSubject.edges.map((edge) => ({
            label: edge.node.subj_desc,
            value: edge.node.id,
          }));
          setSubjects([...EMPTY_RESPONSETYPE, ...responseType]);
        }
      });
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    acd_subj_query_type,
    GetSubjects,
    ids,
    name,
    rowsPerPage,
    token,
    state.ActiveAcdYr,
  ]);
  return {
    subjects: {
      data,
      loading,
      error,
      fetchMore,
      responseType,
    },
  };
};

export default useAcademicSubjects;

import { useNavigate, useParams } from "react-router-dom";
import { Title } from "../../stories/Title/Title";
import { Button } from "../../stories/Button/Button";
import { ChannelUsersTitleProps } from "../../Types/Titles";
import React from "react";

import Home from "../Master/Home/Index";
import useToken from "../../customhooks/useToken";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../Types/Tables";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../utils/constants";
import { Direction, SortBy, StudentReportType } from "../../utils/Enum.types";
import useInstitutionConfiguration from "../../customhooks/useInstitutionConfiguration";
import useMasterTableJson from "../../json/useMasterTableJson";
import useInstLabels from "../../customhooks/general/useInstLabels";
import useAcdStudentsForAdmission from "../Academics/hooks/useAcdStudentsForAdmission";
const { ChannelUsers } = require("../../json/title.json");

const ChannelUserList = () => {
  const { InstId, channelId } = useParams();
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();
  const { branchLabel, classLabel, departmentLabel } = useInstLabels();

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    0,
    0,
    0,
    0,
    0,
    0,
    ROWS_PER_PAGE,
    0,
    EMPTY_STRING,
    StudentReportType.STUDENTS_BY_CHANNEL,
    EMPTY_STRING,
    [],
    [
      {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
    ],
    Number(channelId)
  );

  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY, USE_CLASS_KEY } =
    useInstitutionConfiguration();

  // const [GetChannelListData, { data: ChannelList }] = useLazyQuery<
  //   ChannelListData,
  //   ChannelListVars
  // >(GetMessageChannel, {
  //   variables: { token, inst_id: InstId! },
  // });

  // const filterdName = ChannelList?.GetChannelsByInstId.find(
  //   (channel) => channel.id === Number(channelId)
  // );
  const InstConfig = (data: TableHeaderProps[]) => {
    var tableConfig = data;
    if (USE_DEPARTMENT_KEY === false) {
      tableConfig = tableConfig?.filter(
        (data: TableHeaderProps) => data.labelName !== departmentLabel
      );
    }
    if (USE_BRANCH_KEY === false) {
      tableConfig = tableConfig?.filter(
        (data: TableHeaderProps) => data.labelName !== branchLabel
      );
    }
    if (USE_CLASS_KEY === false) {
      tableConfig = tableConfig?.filter(
        (data: TableHeaderProps) => data.labelName !== classLabel
      );
    }
    return tableConfig;
  };
  // useEffect(() => {
  //   if (token) {
  //     GetChannelListData();
  //   }
  // }, [token, GetChannelListData]);
  return (
    <div className="channeldescription">
      <Home DashBoardRequired={false} />
      <Title>
        List ofsfsdf
        <b className="channeldescription__channelname">
          {/* {filterdName?.channel_name!}&nbsp; */}
        </b>
        Users
      </Title>

      <div className="row g-0" id="channeluser-layout">
        <div className="col channeldescription__frame">
          <div className="channeldescription__frame--header">
            <Title variant="subtitle1">
              {ChannelUsers.Titles.map(
                (title: ChannelUsersTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.List_Of_Teachers}
                    </React.Fragment>
                  );
                }
              )}
            </Title>
            <div className="channeldescription__totalCount"></div>
          </div>
          <hr className="solid" />
          <div className="channeldescription__frame--userlist">
            <ol></ol>
          </div>
        </div>
        <div className="col channeldescription__frame">
          <div className="channeldescription__frame--header">
            <Title variant="subtitle1">
              {ChannelUsers.Titles.map(
                (title: ChannelUsersTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.List_Of_Students}
                    </React.Fragment>
                  );
                }
              )}
            </Title>
            <div className="channeldescription__totalCount"></div>
          </div>

          <hr className="solid" />
          <div className="channeldescription__frame--userlist">
            <TableContainer className="channeldescription__frame--userlist--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {InstConfig(
                      Masters_Table.InstitutionDetails.ChannelTable
                        .Table_Headers
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell className={th.className} key={index}>
                          {th.labelName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {AcademicsStudentData.data &&
                    AcademicsStudentData.data.GetAcdStudents.edges.map(
                      (data, index: number) => (
                        <TableRow key={index}>
                          <TableCell
                            id="td-center"
                            className="institution__table--th-slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            {data.node.first_name +
                              " " +
                              data.node.middle_name +
                              " " +
                              data.node.last_name}
                          </TableCell>
                          {USE_DEPARTMENT_KEY ? (
                            <TableCell>
                              {data.node.acd_dept.dept_desc}
                            </TableCell>
                          ) : null}
                          {USE_BRANCH_KEY ? (
                            <TableCell>
                              {data.node.acd_branch.branch_desc}
                            </TableCell>
                          ) : null}
                          {USE_CLASS_KEY ? (
                            <TableCell>
                              {data.node.acd_class.class_desc}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button
          mode="back"
          onClick={() => navigate(`/${InstId}/masters/chatconfiguration`)}
        />
      </div>
    </div>
  );
};

export default ChannelUserList;

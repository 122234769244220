import { gql } from "@apollo/client";

export const GetAgents = gql`
  query GetAgents(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: MstInstAgentDetailsOrder
    $name: String
  ) {
    GetAgents(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
      where: { and: [{ or: [{ agentNameContainsFold: $name }] }] }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          agent_name
          agent_address
          agent_city
          agent_state
          agent_country
          agent_mobile
          agent_email
          agent_pan_no
          agent_aadhar_no
          agent_gst_no
          agent_bank_ac_no
          agent_bank_ifsc
          agent_bank_name
          agent_bank_address
          agent_bank_city
          agent_bank_type
        }
      }
    }
  }
`;

export const GetAgentsNameAndId = gql`
  query GetAgents(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: MstInstAgentDetailsOrder
    $name: String
  ) {
    GetAgents(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
      where: { and: [{ or: [{ agentNameContainsFold: $name }] }] }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          agent_name
        }
      }
    }
  }
`;

export const GetAgentDetailsByNodeId = gql`
  query node($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstInstAgentDetails {
        agent_name
        agent_address
        agent_city
        agent_state
        agent_country
        agent_mobile
        agent_email
        agent_pan_no
        agent_aadhar_no
        agent_gst_no
        agent_bank_ac_no
        agent_bank_ifsc
        agent_bank_name
        agent_bank_address
        agent_bank_city
      }
      __typename
    }
  }
`;

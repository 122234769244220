import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { GetUserRightsByEmpId } from "../queries/List";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { EduateModule } from "../../../utils/Enum.types";
import { useParams } from "react-router-dom";
interface user_rights_detailsData {
  id: number;
  module_rights_type: string;
  view: boolean;
  add: boolean;
  edit: boolean;
  delete: boolean;
  inst_module_details: {
    eduate_module_details: {
      id: number;
      Name: string;
    };
  };
}
interface Details {
  is_inst_sysadmin: boolean;
  default_theme: string;
  pr_emp_id: number;
  pr_emp_details: {
    emp_type: string;
  };
  user_rights_details: user_rights_detailsData[];
}
export interface GetUserRightsByEmpIdData {
  GetUserRightsByEmpId: Details;
}
export interface GetUserRightsByEmpIdVars {
  token: string;
  inst_id: string;
  pr_emp_id: number;
}
interface returnType {
  flag: boolean;
  details: user_rights_detailsData | null;
}
const useUserRightsByEmpId = (instId?: number) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const [employeeId, setEmpId] = useState(0);
  const [institutionId, setInstitutionId] = useState(0);
  const [accounts, setAccounts] = useState<returnType>({
    flag: false,
    details: null,
  });
  const [admission, setAdmission] = useState<returnType>({
    flag: false,
    details: null,
  });
  const [payroll, setPayroll] = useState<returnType>({
    flag: false,
    details: null,
  });
  const [library, setLibrary] = useState<returnType>({
    flag: false,
    details: null,
  });
  const [academics, setAcademics] = useState<returnType>({
    flag: false,
    details: null,
  });
  const [transport, setTransport] = useState<returnType>({
    flag: false,
    details: null,
  });
  const [enquiry, setEnquiry] = useState<returnType>({
    flag: false,
    details: null,
  });
  const [messageApp, setMessageApp] = useState<returnType>({
    flag: false,
    details: null,
  });
  useEffect(() => {
    if (state.employeeId) {
      setEmpId(state.employeeId);
    } else if (state.userRightsEmpId) {
      setEmpId(state.userRightsEmpId);
    } else if (state.empLoginId) {
      setEmpId(state.empLoginId);
    }
  }, [state.employeeId, state.empLoginId, state.userRightsEmpId]);
  useEffect(() => {
    if (instId) {
      setInstitutionId(Number(instId));
    } else {
      if (InstId) setInstitutionId(Number(InstId));
      if (state.InstId) setInstitutionId(Number(state.InstId));
    }
  }, [InstId, instId, state.InstId]);

  const [GetDetails, { data, loading, error }] = useLazyQuery<
    GetUserRightsByEmpIdData,
    GetUserRightsByEmpIdVars
  >(GetUserRightsByEmpId, {
    variables: {
      inst_id: institutionId.toString(),
      pr_emp_id: employeeId,
      token,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (institutionId && token && employeeId) GetDetails();
  }, [institutionId, GetDetails, employeeId, token]);
  useEffect(() => {
    if (data && !loading) {
      const { user_rights_details } = data.GetUserRightsByEmpId;

      const findModule = (name: EduateModule) =>
        user_rights_details.find(
          ({
            inst_module_details: {
              eduate_module_details: { Name },
            },
          }) => Name === name
        );

      const accounts = findModule(EduateModule.ACCOUNTS);
      const payroll = findModule(EduateModule.PAYROLL);
      const lib = findModule(EduateModule.LIBRARY);
      const academics = findModule(EduateModule.ACADEMICS);
      const transport = findModule(EduateModule.TRANSPORT);
      const enquiry = findModule(EduateModule.ENQUIRY);
      const messageApp = findModule(EduateModule.MESSAGEAPP);
      const admissions = findModule(EduateModule.ADMISSION);
      setAccounts((prevState) => ({
        ...prevState,
        details: accounts!,
        flag: Boolean(accounts),
      }));
      setPayroll((prevState) => ({
        ...prevState,
        details: payroll!,
        flag: Boolean(payroll),
      }));
      setLibrary((prevState) => ({
        ...prevState,
        details: lib!,
        flag: Boolean(lib),
      }));
      setAcademics((prevState) => ({
        ...prevState,
        flag: Boolean(academics),
        details: academics!,
      }));
      setTransport((prevState) => ({
        ...prevState,
        flag: Boolean(transport),
        details: transport!,
      }));
      setEnquiry((prevState) => ({
        ...prevState,
        flag: Boolean(enquiry),
        details: enquiry!,
      }));
      setMessageApp((prevState) => ({
        ...prevState,
        details: messageApp!,
        flag: Boolean(messageApp),
      }));
      setAdmission((prevState) => ({
        ...prevState,
        details: admissions!,
        flag: Boolean(admissions),
      }));
    }
  }, [data, loading]);
  return {
    userRights: { data, loading, error },
    USE_ACCOUNTS: accounts,
    USE_PAYROLL: payroll,
    USE_LIBRARY: library,
    USE_TRANSPORT: transport,
    USE_ACADEMICS: academics,
    USE_ENQUIRY: enquiry,
    USE_MESSAGE_APP: messageApp,
    USE_ADMISSIONS: admission,
  };
};

export default useUserRightsByEmpId;

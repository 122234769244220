import React, { useState } from "react";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { FormControlLabel, TextField } from "@mui/material";

import { msgType } from "../../../utils/Form.types";
import {
  handleMUISelectEvent,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { Keys } from "../../../utils/Enum.keys";
import { Button } from "../../../stories/Button/Button";
import useActiveFinancialYear from "../hooks/useActiveFinancialYear";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { AcctFinYrPosting } from "../queries/FinYearPost/mutation";
import { userDetails } from "../../../Types/Accounting/other";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import ConfirmationModal from "./ConfirmationModal";
import { Operation } from "../../../utils/Enum.types";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import { GetFinYrActiveByInstId } from "../../../queries/institution/financialyear/list/byid";
import { AntSwitch } from "../../../pages/Switch";
import Modal from "react-modal";
import { WaiveOffModalStyles } from "../../../styles/ModalStyles";
import Close from "../../../images/Close.svg";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";

export interface FinYrType {
  label: string;
  value: {
    startDate: string;
    endDate: string;
  };
}
interface AcctFinYrPostingData {
  AcctFinYrPosting: boolean;
}
interface AcctFinYrPostingVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  prev_fin_yr_id: number;
  input: {
    fin_yr: string;
    fin_st_date: string;
    fin_end_date: string;
  };
  post_prev_fin: boolean;
}
const FinYrComponent = () => {
  const { InstId } = useParams();
  const { token } = useToken();

  const [selectedFinYr, setSelectedFinYr] = useState<FinYrType | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [finYearFlag, setFinYearFlag] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [confirModal, setConfirmModal] = useState(false);
  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const { user_details } = useLoggedInUserDetails();
  const [FinYearPost, { loading }] = useMutation<
    AcctFinYrPostingData,
    AcctFinYrPostingVars
  >(AcctFinYrPosting, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const upcommingFinancialYears = () => {
    if (ActiveFinancicalYear.data) {
      var currentYear =
        ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_yr.split("-")[1];

      return [...new Array(1).keys()].map((i) => {
        const start = dayjs(`4-1-${currentYear}`).add(i, "year").format();
        const end = dayjs(`03-31-${Number(currentYear) + 1}`)
          .add(i, "year")
          .format();
        return {
          label: `${dayjs(start).format("YYYY")}-${dayjs(end).format("YYYY")}`,
          value: {
            startDate: start,
            endDate: end,
          },
        };
      });
    } else {
      return [];
    }
  };

  const handleFinYearPost = () => {
    if (selectedFinYr === null) {
      setMessage({
        flag: true,
        message: "Please select financial year",
        operation: Operation.CREATE,
      });
      return;
    }
    if (ActiveFinancicalYear.data === undefined) {
      setMessage({
        flag: true,
        message: "Active financial year not found",
        operation: Operation.CREATE,
      });
      return;
    }

    FinYearPost({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        prev_fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id,
        input: {
          fin_yr: selectedFinYr.label,
          fin_st_date: toIsoDate(selectedFinYr?.value.startDate),
          fin_end_date: toIsoDate(selectedFinYr?.value.endDate),
        },
        post_prev_fin: finYearFlag,
      },
      refetchQueries: [
        {
          query: GetFinYrActiveByInstId,
          variables: {
            token,
            inst_id: InstId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.AcctFinYrPosting) {
        setMessage({
          flag: true,
          message: "Successfully posted",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleClose = () => {
    setConfirmModal(!confirModal);
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <form className="finyr-post__data--component">
        <div className="finyr-post__data--component--label-grid">
          <Label>Current Financial Year</Label>
          <Input
            value={
              ActiveFinancicalYear.data
                ? ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_yr
                : ""
            }
            disabled
          />
        </div>

        <div className="finyr-post__data--component--label-grid">
          <Label>New Financial Year</Label>
          <FormAutocomplete
            className={formClasses.inputRoot}
            options={[...upcommingFinancialYears()]}
            value={selectedFinYr}
            onChange={(e, newValue) => {
              if (newValue) {
                setSelectedFinYr(newValue as FinYrType);
                setStartDate(
                  toInputStandardDate((newValue as FinYrType).value.startDate)
                );
                setEndDate(
                  dayjs((newValue as FinYrType).value.startDate)
                    .add(364, "day")
                    .format("YYYY-MM-DD")
                );
              } else {
                setSelectedFinYr(null);
              }
            }}
            isOptionEqualToValue={(option) =>
              selectedFinYr &&
              (option as FinYrType).value.startDate ===
                selectedFinYr.value.startDate &&
              (option as FinYrType).value.endDate ===
                selectedFinYr.value.endDate
                ? true
                : false
            }
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                e.preventDefault();
                if (selectedFinYr) {
                  handleMUISelectEvent(e);
                }
              }
              if (e.key === Keys.BACKSPACE) {
                setSelectedFinYr(null);
              }
            }}
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                required
                fullWidth
                autoFocus
                className={formClasses.formControlRoot}
              />
            )}
          />
        </div>
        <div className="finyr-post__data--component--label-grid">
          <Label>Financial Start Date</Label>
          <Input type="date" value={startDate} disabled />
        </div>
        <div className="finyr-post__data--component--label-grid">
          <Label>Financial End Date</Label>
          <Input type="date" value={endDate} disabled />
        </div>
        <div className="finyr-post__data--component--label-grid">
          <Label>Carry Fee Outstanding Balances</Label>

          <FormControlLabel
            label=""
            labelPlacement="start"
            control={
              <AntSwitch
                checked={finYearFlag}
                onClick={() => setConfirmationModal(!confirmationModal)}
              />
            }
          />
        </div>

        <Button
          mode="start-posting"
          onClick={(e: React.FormEvent) => {
            e.preventDefault();
            setConfirmModal(!confirModal);
          }}
        />
        <ConfirmationModal
          handleFinYearPost={handleFinYearPost}
          isOpen={confirModal}
          setOpenModal={setConfirmModal}
        />
      </form>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={confirmationModal}
        ariaHideApp={false}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Label>
              Are You Sure, Student Fee Balances will not be Carried, All
              Balances Will Reset to Zero
            </Label>
            <Button
              mode="okay"
              onClick={() => {
                setFinYearFlag(!finYearFlag);
                setConfirmationModal(!confirmationModal);
              }}
            ></Button>
            <Button
              mode="cancel"
              onClick={() => setConfirmationModal(!confirmationModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfirmationModal(!confirmationModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default FinYrComponent;

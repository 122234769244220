import { gql } from "@apollo/client";

export const ListInstClassIdByNames = gql`
  query GetClassIdsByNames($names: [String!], $token: String!, $inst_id: ID!) {
    GetClassIdsByNames(token: $token, class_names: $names, inst_id: $inst_id) {
      class_desc
      id
    }
  }
`;

export const ListClassIdByName = gql`
  query GetClassIdByName(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
    $class_name: String!
  ) {
    GetClassIdByName(
      token: $token
      inst_id: $inst_id
      branch_id: $branch_id
      class_name: $class_name
    )
  }
`;

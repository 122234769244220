import React from "react";
import Common from "../New/Common";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { LabelNameProps } from "../../../../Types/Labels";
import { useNavigate } from "react-router-dom";
import TextArea from "../../../../stories/TextArea/TextArea";
const { VMS_Form } = require("../../../json/form.json");
const PurchaseDetails = () => {
  const navigate = useNavigate();
  return (
    <>
      <Common />
      <div className="view-vehicle-details__purchase-details row g-0">
        <div className="col">
          <div className="details">
            <h4>Vehicle Other Details</h4>
          </div>
          <div className="label-grid">
            <Label>Wheel Base</Label>
            <Input disabled />
            <Label>Unloaded Weight</Label>
            <Input disabled />
            <Label>Loaded Weight</Label>
            <Input disabled />
          </div>
          <div className="vms-v-details-new__other-details--data--grid">
            <Label>Bought New</Label>
            <Input disabled />
            <Label>Bought KM Reading</Label>
            <Input type="date" disabled />
          </div>
          <div className="label-grid">
            <Label>Target KMPL</Label>
            <Input disabled />
          </div>
          <div className="vms-v-details-new__other-details--data--grid">
            <Label>CCTV Enabled</Label>
            <Input disabled />
            <Label>CCTV Enabled Date</Label>
            <Input type="date" disabled />
          </div>
          <div className="label-grid">
            <Label>CCTV Enabled Remarks</Label>
            <Input disabled />
          </div>
          <div className="vms-v-details-new__other-details--data--grid">
            <Label>GPS Enabled</Label>
            <Input disabled />
            <Label>GPS Enabled Date</Label>
            <Input type="date" disabled />
          </div>
          <div className="label-grid">
            <Label>GPS Enabled Remarks</Label>
            <Input disabled />
          </div>
        </div>
        <div className="col">
          <div className="details">
            <h4>Purchase Information</h4>
          </div>
          {VMS_Form.Vehicle_Details.Preview.PurchaseDetails.map(
            (label: LabelNameProps, index: React.Key) => {
              return (
                <>
                  <div className="label-grid" key={index}>
                    <Label>{label.LabelName}</Label>
                    <Input disabled type={label.dataType} />
                  </div>
                </>
              );
            }
          )}
          <div className="label-grid">
            <Label>Remarks</Label>
            <TextArea rows={2} disabled />
          </div>
        </div>
      </div>
      <Button
        mode="edit"
        onClick={() => navigate(`/vms/vehicledetails/update/purchasedetails`)}
      />
      <Button mode="back" onClick={() => navigate(-1)} />
    </>
  );
};

export default PurchaseDetails;

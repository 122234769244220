import { gql } from "@apollo/client";

export const GetTransportRouteMasters = gql`
  query GetTransportRouteMasters(
    $name: String
    $token: String!
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: TransportRouteMasterOrderField
    $direction: OrderDirection!
  ) {
    GetTransportRouteMasters(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ routeDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          route_desc
          route_no_stages
          route_is_active
          route_st_date
          inst_id
          route_details {
            id
            route_stage_desc
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const RouteNode = gql`
  query nodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on TransportRouteMaster {
        id
        route_desc
        route_no_stages
        route_is_active
        route_st_date
        inst_id
      }
    }
  }
`;
export const RouteDetailsNode = gql`
  query nodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on TransportRouteDetails {
        id
        route_stage_desc
        route_stage_sl
        inst_id
        route_master_id
        acct_ldgr_id
        route_master_details {
          id
          route_desc
          route_no_stages
          route_is_active
          route_st_date
          inst_id
        }
        acct_ldgr_details {
          id
          ldgr_desc
        }
      }
    }
  }
`;
export const GetTransportRouteDetailsByMasterId = gql`
  query GetTransportRouteDetailsByMasterId(
    $token: String!
    $inst_id: ID!
    $route_master_id: ID!
  ) {
    GetTransportRouteDetailsByMasterId(
      token: $token
      inst_id: $inst_id
      route_master_id: $route_master_id
    ) {
      id
      route_stage_desc
      route_stage_sl
      inst_id
      route_master_id
      acct_ldgr_id
      route_master_details {
        id
        route_desc
        route_no_stages
        route_is_active
        route_st_date
        inst_id
      }
      acct_ldgr_details {
        id
        ldgr_desc
        ldgr_alias
        ldgr_narration
        ldgr_cash_bank_other
        ldgr_sw_based
        cr_or_db
        ldgr_index_position
        ldgr_ob
        ldgr_total_cr
        ldgr_total_db
        ldgr_cb
        ldgr_imprest_ob
        ldgr_is_std_fee_ldgr
        ldgr_std_fee_short_desc
        ldgr_std_fee_is_monthly
        ldgr_std_fee_is_deposit_ldgr
        ldgr_std_fee_is_fine_ldgr
        ldgr_std_fee_Type
        ldgr_std_fee_sub_Type
      }
    }
  }
`;

export const GetTransportRouteDetails = gql`
  query GetTransportRouteDetails(
    $name: String
    $token: String!
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: TransportRouteDetailsOrderField
    $direction: OrderDirection!
  ) {
    GetTransportRouteDetails(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ routeStageDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          route_stage_desc
          route_stage_sl
          inst_id
          route_master_id
          acct_ldgr_id
          route_master_details {
            id
            route_desc
            route_no_stages
            route_is_active
            route_st_date
            inst_id
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GetTransportStudent = gql`
  query GetTransportStudent($token: String!, $student_id: ID!) {
    GetTransportStudent(token: $token, student_id: $student_id) {
      id
      is_transport_active
      student_id
      route_detail_id
      acct_ldgr_id
      student_details {
        id
        first_name
        middle_name
        last_name
        std_reg_no
        std_adm_no
        std_email
        acd_yr {
          acd_yr
        }
        std_father_name
        branch {
          branch_desc
        }
        class {
          class_desc
        }
        category {
          cat_desc
        }
        std_mobile
      }
      route_details {
        id
        route_stage_desc
        route_master_id
        route_master_details {
          route_desc
          route_is_active
        }
      }
      acct_ldgr_details {
        id
        ldgr_desc
      }
      route_fee_details {
        route_fee
      }
    }
  }
`;
export const GetTransportStd = gql`
  query GetTransportStd(
    $name: String
    $token: String!
    $first: Int
    $after: Cursor
    $input: TransQueryData!
    $acd_yr_id: ID!
    $sortBy: TransportStudentOrderField
    $direction: OrderDirection!
  ) {
    GetTransportStd(
      token: $token
      first: $first
      after: $after
      input: $input
      acd_yr_id: $acd_yr_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { hasMstStudentWith: [{ firstNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          is_transport_active
          student_id
          route_detail_id
          acct_ldgr_id
          student_details {
            first_name
            middle_name
            last_name
            std_mobile
            std_reg_no
            std_adm_no
            dept {
              dept_desc
            }
            branch {
              branch_desc
            }
            class {
              class_desc
            }
            category {
              cat_desc
            }
          }
          route_details {
            route_stage_desc
            route_master_details {
              route_desc
            }
            acct_ldgr_details {
              ldgr_desc
            }
          }
          route_fee_details {
            route_fee
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const GetTransportDashBoardCounts = gql`
  query GetTransportDashBoardCounts(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
  ) {
    GetTransportDashBoardCounts(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
    ) {
      total_student
      not_assigned_total_student
      total_routes
      total_stages
    }
  }
`;
export const GetTransportRouteFee = gql`
  query GetTransportRouteFee(
    $name: String
    $token: String!
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $fin_yr_id: ID!
    $sortBy: TransportRouteFeeOrderField
    $direction: OrderDirection!
  ) {
    GetTransportRouteFee(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ hasAcctLdgrWith: [{ ldgrDescContainsFold: $name }] }] }
    ) {
      edges {
        node {
          id
          route_fee
          acct_ldgr_id
          inst_id
          fin_yr_id
          acct_ldgr_details {
            ldgr_desc
            id
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GetTransportFeeDetails = gql`
  query GetTransportFeeDetails(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $acct_ldgr_id: ID!
  ) {
    GetTransportFeeDetails(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      acct_ldgr_id: $acct_ldgr_id
    )
  }
`;

import React, { useContext, useEffect, useState } from "react";
import Home from "../../../../Master/configurations/Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT} from "../../../../../styles/DataGridTableStyles";
import {

  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StudentReportType,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../../utils/constants";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import Assign from "../../../../../images/Assign.svg";
import AcdStudentInfo from "./AcdStudentInfo";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import { FetchStudentStatusClassName } from "../../../Student/List";
import LegendsInStudentList from "../../../Student/Components/LegendsInStudentList";
import useAcdStudentsData, {
  StudentEdges,
} from "../../../../Academics/hooks/useAcdStudentsData";
import { AttendanceDrawer } from "../../../../../styles/DrawerStyles";

const PendingAcdPosting = () => {
  const navigate = useNavigate();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [plan, setPlan] = useState(false);
  const { dispatch } = useContext(AppContext);
  const { branchLabel, classLabel } = useInstLabels();
  const [searchData, setSearchData] = useState("");

  const {
    AcademicsStudentData: { data, loading, fetchMore },
  } = useAcdStudentsData(
    0,
    0,
    0,
    0,
    0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.GENERAL
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,

      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-sl-no",
    },
    {
      field: "std_adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,

      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,

      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      flex: 1,
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "branch",
      headerName: branchLabel,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub",
    },
    {
      field: "class",
      headerName: classLabel,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub",
    },

    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`pending-data-std-posting__datablock--status ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,
      cellClassName: "td-actions-plan",
      headerClassName: "td-actions-plan",
      renderCell: (params: GridRenderCellParams) => {
        const stdId = params.row.stdId || "";

        return (
          <>
            <button
              className="pending-data-std-posting__datablock--actions "
              onClick={() => {
                setPlan(!plan);
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: stdId!,
                  },
                });
              }}
            >
              Post To Next Year <img src={Assign} alt="" />
            </button>
          </>
        );
      },
    },
  ];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.stdId && row.stdId=== newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name + node.middle_name + node.last_name,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            status: node.std_status,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name + node.middle_name + node.last_name,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            status: node.std_status,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Pending Student List for Academic Year Posting</Title>
      <div className="pending-data-std-posting">
        <div className="pending-data-std-posting__select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
            />
          </div>
        </div>

        <div className={`pending-data-std-posting__datablock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
          
          />
        </div>
        <div className="row g-0">
          <div className="col-2">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col">
            <LegendsInStudentList />
          </div>
        </div>
      </div>

      <AttendanceDrawer
     
        anchor="right"
        open={plan}
        onClose={() => setPlan(!plan)}
      >
        <AcdStudentInfo setModalFlag={setPlan} />{" "}
      </AttendanceDrawer>
    </>
  );
};

export default PendingAcdPosting;

import { gql } from "@apollo/client";

export const AddAcctVoucherMasterAndDetails = gql`
  mutation AddAcctVoucherMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $input: [AcctVoucherMasterAndDetails!]!
  ) {
    AddAcctVoucherMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      input: $input
    ) {
      id
      v_no
      v_date
    }
  }
`;
export const AddAcctChallanMasterAndDetails = gql`
  mutation AddAcctChallanMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $input: AcctChallanMasterAndDetails!
  ) {
    AddAcctChallanMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      input: $input
    ) {
      id
      v_no
      v_date
      acct_ldgr_db_details {
        ldgr_bank_ac_no
        ldgr_bank_name
      }
    }
  }
`;
export const AddAcctVoucherMasterMkCkAndDetails = gql`
  mutation AddAcctVoucherMasterMkCkAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $input: AcctVoucherMasterMkCkAndDetails!
  ) {
    AddAcctVoucherMasterMkCkAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      input: $input
    ) {
      id
      created_at
      fin_yr
      mkck_type
      mkck_book_type
      mkck_no
      mkck_date
      remarks
      mkck_total_cr_amt
      mkck_total_db_amt
      mkck_completed
      mkck_status
      pr_emp_id_created
      pr_emp_id_approved
      inst_id
      fin_yr_id
    }
  }
`;
export const AddAcctPurchaseMasterAndDetails = gql`
  mutation AddAcctPurchaseMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $input: CreateAcctPurchaseDetails!
    $user_details: SubscribedUser!
  ) {
    AddAcctPurchaseMasterAndDetails(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      input: $input
      user_details: $user_details
    ) {
      id
    }
  }
`;
export const AddReconciledStdReceipt = gql`
  mutation AddReconciledStdReceipt(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $voucher_master_id: ID!
    $input: [AcctVoucherMasterAndDetails!]!
    $user_details: SubscribedUser!
  ) {
    AddReconciledStdReceipt(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      voucher_master_id: $voucher_master_id
      input: $input
      user_details: $user_details
    ) {
      id
      v_no
      v_date
    }
  }
`;

export const EditAcctVoucherMasterForStdRecipts = gql`
  mutation EditAcctVoucherMasterForStdRecipts(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $voucher_master_id: ID!
    $input: AcctVoucherMasterStdReciptEdit!
    $user_details: SubscribedUser!
  ) {
    EditAcctVoucherMasterForStdRecipts(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      voucher_master_id: $voucher_master_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const AddAcctStdConcessionAndDetails = gql`
  mutation AddAcctStdConcessionAndDetails(
    $token: String!
    $inst_id: ID!
    $acct_std_concession: CreateAcctStdConcessionInput!
    $concession_details: [CreateAcctStdConcessionDetailsInput!]!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    AddAcctStdConcessionAndDetails(
      token: $token
      inst_id: $inst_id
      input: {
        acct_std_concession: $acct_std_concession
        concession_details: $concession_details
      }
      fin_yr_id: $fin_yr_id
      user_details: $user_details
    )
  }
`;

export const TransferAcctVoucherMasterAndDetails = gql`
  mutation TransferAcctVoucherMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $voucher_master_id: ID!
    $fin_yr_id: ID!
    $acct_voucher_master: CreateAcctVoucherMasterInput!
    $acct_voucher_cr: [CreateAcctVoucherDetailsInput!]!
    $acct_voucher_db: [CreateAcctVoucherDetailsInput!]!
    $user_details: SubscribedUser!
  ) {
    TransferAcctVoucherMasterAndDetails(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      voucher_master_id: $voucher_master_id
      user_details: $user_details
      input: {
        acct_voucher_master: $acct_voucher_master
        acct_voucher_cr: $acct_voucher_cr
        acct_voucher_db: $acct_voucher_db
      }
    )
  }
`;

export const TransferAcctVoucherMasterAndDetailsAfterBranchChange = gql`
  mutation TransferAcctVoucherMasterAndDetailsAfterBranchChange(
    $token: String!
    $inst_id: ID!
    $voucher_master_id: ID!
    $fin_yr_id: ID!
    $acct_voucher_master: CreateAcctVoucherMasterInput!
    $acct_voucher_cr: [CreateAcctVoucherDetailsInput!]!
    $acct_voucher_db: [CreateAcctVoucherDetailsInput!]!
    $user_details: SubscribedUser!
  ) {
    TransferAcctVoucherMasterAndDetailsAfterBranchChange(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      voucher_master_id: $voucher_master_id
      user_details: $user_details
      input: {
        acct_voucher_master: $acct_voucher_master
        acct_voucher_cr: $acct_voucher_cr
        acct_voucher_db: $acct_voucher_db
      }
    )
  }
`;
export const DeleteAcctVoucherMasterAndDetails = gql`
  mutation DeleteAcctVoucherMasterAndDetails(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcctVoucherMasterAndDetails(
      token: $token
      acct_master_voucher_id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteAcctStdConcessionAndDetails = gql`
  mutation DeleteAcctStdConcessionAndDetails(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcctStdConcessionAndDetails(
      token: $token
      acct_std_concession_master_id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const ResetAccountsData = gql`
  mutation ResetAccountsData(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    ResetAccountsData(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
    )
  }
`;
export const ReCalculateAccounts = gql`
  mutation ReCalculateAccounts(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReCalculateAccounts(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
    )
  }
`;
export const EditAcctPaymentVouchers = gql`
  mutation EditAcctPaymentVouchers(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $voucher_master_id: ID!
    $input: AcctPaymentVoucherEdit!
    $user_details: SubscribedUser!
  ) {
    EditAcctPaymentVouchers(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      voucher_master_id: $voucher_master_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdateAcctVoucherMkCkAssignedStatusMutation = gql`
  mutation UpdateAcctVoucherMkCkAssignedStatusMutation(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $mkck_voucher_id: ID!
    $input: UpdateAcctVoucherMkCkAssignedInput!
  ) {
    UpdateAcctVoucherMkCkAssignedStatus(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      mkck_voucher_id: $mkck_voucher_id
      input: $input
    )
  }
`;
export const DeleteAcctVoucherMasterMkCkAndDetails = gql`
  mutation DeleteAcctVoucherMasterMkCkAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $id: ID!
  ) {
    DeleteAcctVoucherMasterMkCkAndDetails(
      token: $token
      acct_mkck_voucher_id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const AddOrUpdateOrDeleteAcctStdDepositsOB = gql`
  mutation AddOrUpdateOrDeleteAcctStdDepositsOB(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $student_id: ID!
    $input: AddOrUpdateOrDeleteDepositOb!
  ) {
    AddOrUpdateOrDeleteAcctStdDepositsOB(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      student_id: $student_id
      input: $input
    )
  }
`;

export const AddAcctVoucherMasterOnlineStdReceipt = gql`
  mutation AddAcctVoucherMasterOnlineStdReceipt(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $user_details: SubscribedUser!
    $online_payment_id: ID!
  ) {
    AddAcctVoucherMasterOnlineStdReceipt(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      user_details: $user_details
      online_payment_id: $online_payment_id
    ) {
      id
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import { GetFinYrActiveByInstId } from "../../../queries/institution/financialyear/list/byid";
import { EMPTY_STRING } from "../../../utils/constants";
export interface ActiveFinancialYearData {
  id: number;
  fin_yr: string;
  fin_st_date: string;
  fin_end_date: string;
  fin_is_curr_yr: Boolean;
  inst_id: string;
}
export interface ListFinancialYearVars {
  token: string;
  inst_id: string;
}
export interface ActiveFinancialYearList {
  GetFinYrActiveByInstId: ActiveFinancialYearData;
}
const useActiveFinancialYear = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();
  const [GetActivefinYear, { data, loading, error }] = useLazyQuery<
    ActiveFinancialYearList,
    ListFinancialYearVars
  >(GetFinYrActiveByInstId, {
    variables: {
      token,
      inst_id: InstId! ?? state.InstId,
    },
  });

  useEffect(() => {
    if ((InstId || state.InstId) && token !== EMPTY_STRING) {
      GetActivefinYear();
    }
  }, [InstId, token, GetActivefinYear, state.InstId]);
  return { ActiveFinancicalYear: { data, loading, error } };
};

export default useActiveFinancialYear;

import { gql } from "@apollo/client";

export const AddLibBookLanguage = gql`
  mutation AddLibBookLanguage(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateLibBookLanguageInput!
  ) {
    AddLibBookLanguage(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

import React from "react";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { VMS_Table } = require("../../../json/table.json");
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const View = ({ setModalFlag }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="vms-fuel-indent__view">
      <Title>Vehicle Fuel Indent</Title>

      <div className="vms-fuel-indent__view--select row g-0">
        <div className="col-2">
          <Input id="search" placeholder="Search Indent" />
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            options={["1", "2"]}
            className={labelClasses.inputRoot}
            renderInput={(params) => (
              <TextField
                {...params}
                className={labelClasses.formControlRoot}
                label="Vehicle Type"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            )}
          />
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            options={["1", "2"]}
            className={labelClasses.inputRoot}
            renderInput={(params) => (
              <TextField
                {...params}
                className={labelClasses.formControlRoot}
                label="Manufacturer"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            )}
          />
        </div>
        <div className="col-2">
          {" "}
          <LabeledAutocomplete
            options={["1", "2"]}
            className={labelClasses.inputRoot}
            renderInput={(params) => (
              <TextField
                {...params}
                className={labelClasses.formControlRoot}
                label="Manufacturer Model"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            )}
          />
        </div>
      </div>
      <div className="vms-fuel-indent__view--tableblock">
        <TableContainer className="vms-fuel-indent__view--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {VMS_Table.Supplier_Details.FuelIndent.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className="vms-fuel-indent__view--table--slno"
                  id="td-center"
                >
                  1
                </TableCell>
                <TableCell className="vms-fuel-indent__view--table--number">
                  20-10-2023
                </TableCell>
                <TableCell className="vms-fuel-indent__view--table--number">
                  KA90784
                </TableCell>
                <TableCell className="vms-fuel-indent__view--table--number">
                  KA90784
                </TableCell>
                <TableCell
                  className="vms-fuel-indent__view--table--number"
                  id="td-center"
                >
                  30
                </TableCell>
                <TableCell className="vms-fuel-indent__view--table--number">
                  1000
                </TableCell>
                <TableCell>Gangadhar Private Limited</TableCell>
                <TableCell>HP</TableCell>
                <TableCell
                  className="vms-fuel-indent__view--table--number"
                  id="td-center"
                >
                  30
                </TableCell>

                <TableCell
                  className="vms-fuel-indent__view--table--actions"
                  id="td-center"
                >
                  <img
                    src={Edit}
                    alt=""
                    onClick={() => {
                      navigate(`/vms/fuelindent/edit`);
                      setModalFlag(false);
                    }}
                  />
                  <img src={Delete} alt="" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Button mode="cancel" onClick={() => setModalFlag(false)} />
    </div>
  );
};

export default View;

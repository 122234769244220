import ReactDOM from "react-dom/client";

import "./scss/main.scss";
import Eduate from "./Eduate";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client/index";
import { AppProvider } from "./context/context";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppProvider>
    <ApolloProvider client={client}>
      <Eduate />
    </ApolloProvider>
  </AppProvider>
);

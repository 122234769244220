import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { EMPTY_STRING, NOTALLOCATED } from "../utils/constants";
import { GetDeptIdByName as DeptIdByName } from "../queries/institution/department/list/bynames";
import { BranchIdByName } from "../queries/institution/branch/list/byNames";
import { ListClassIdByName as ClassIdByName } from "../queries/institution/classes/list/byName";
import { SemIdByName } from "../queries/institution/sem/list/byName";
import { SectionIdBySectionName as SectionIdByName } from "../queries/sections/list/bynames";
import { ListCategoryIdByName } from "../queries/institution/list/byId";
import useToken from "./useToken";
import { useEffect } from "react";

const useNotAlloactedIdFromInst = () => {
  const { InstId } = useParams();
  const { token } = useToken();

  const [GetNotAllocatedDeptId, { data: NotAllocatedDeptId }] = useLazyQuery(
    DeptIdByName,
    {
      variables: {
        dept_name: NOTALLOCATED,
        inst_id: InstId,
        token,
      },
    }
  );
  const [GetNotAllocatedBranchId, { data: NotAllocatedBranchId }] =
    useLazyQuery(BranchIdByName, {
      variables: {
        branch_name: NOTALLOCATED,
        dept_id: NotAllocatedDeptId?.GetDeptIdByName ?? EMPTY_STRING,
        inst_id: InstId,
        token,
      },
    });
  const [GetNotAllocatedClassId, { data: NotAllocatedClassId }] = useLazyQuery(
    ClassIdByName,
    {
      variables: {
        class_name: NOTALLOCATED,
        branch_id: NotAllocatedBranchId?.GetBranchIdByName ?? EMPTY_STRING,
        inst_id: InstId,
        token,
      },
    }
  );

  const [GetNotAllocatedSemesterId, { data: NotAllocatedSemesterId }] =
    useLazyQuery(SemIdByName, {
      variables: {
        semester_name: NOTALLOCATED,
        class_id: NotAllocatedClassId?.GetClassIdByName ?? EMPTY_STRING,
        inst_id: InstId,
        token,
      },
    });
  const [GetNotAllocatedSectionId, { data: NotAllocatedSectionId }] =
    useLazyQuery(SectionIdByName, {
      variables: {
        section_name: NOTALLOCATED,
        semester_id:
          NotAllocatedSemesterId?.GetSemesterIdByName ?? EMPTY_STRING,
        inst_id: InstId,
        token,
      },
    });
  const [GetNotAllocatedCategoryId, { data: NotAlloacatedCategoryId }] =
    useLazyQuery(ListCategoryIdByName, {
      variables: {
        token,
        inst_id: InstId,
        names: NOTALLOCATED,
      },
    });

  useEffect(() => {
    if (token && InstId) {
      GetNotAllocatedDeptId();
    }
  }, [token, InstId, GetNotAllocatedDeptId, NotAllocatedDeptId]);

  useEffect(() => {
    if (token && InstId) {
      GetNotAllocatedBranchId();
    }
  }, [token, InstId, GetNotAllocatedBranchId]);

  useEffect(() => {
    if (token && InstId) {
      GetNotAllocatedClassId();
    }
  }, [
    token,
    InstId,
    GetNotAllocatedClassId,
    NotAllocatedBranchId,
    NOTALLOCATED,
  ]);

  useEffect(() => {
    if (token && InstId) {
      GetNotAllocatedSemesterId();
    }
  }, [
    token,
    InstId,
    GetNotAllocatedSemesterId,
    NotAllocatedClassId,
    NOTALLOCATED,
  ]);

  useEffect(() => {
    if (token && InstId) {
      GetNotAllocatedSectionId();
    }
  }, [
    token,
    InstId,
    GetNotAllocatedSectionId,
    NotAllocatedSemesterId,
    NOTALLOCATED,
  ]);

  useEffect(() => {
    if (token && InstId) {
      GetNotAllocatedCategoryId();
    }
  }, [token, InstId, GetNotAllocatedCategoryId, NOTALLOCATED]);

  return {
    NotAllocatedDeptId:
      NotAllocatedDeptId && NotAllocatedDeptId?.GetDeptIdByName,
    NotAllocatedBranchId:
      NotAllocatedBranchId && NotAllocatedBranchId?.GetBranchIdByName,
    NotAllocatedClassId:
      NotAllocatedClassId && NotAllocatedClassId?.GetClassIdByName,
    NotAllocatedSemesterId:
      NotAllocatedSemesterId && NotAllocatedSemesterId?.GetSemesterIdByName,
    NotAllocatedSectionId:
      NotAllocatedSectionId && NotAllocatedSectionId?.GetSectionIdByName,
    NotAllocatedCategoryId:
      NotAlloacatedCategoryId && NotAlloacatedCategoryId?.GetCategoryIdByName,
  };
};

export default useNotAlloactedIdFromInst;

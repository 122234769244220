export enum AcctStudentQueryType {
  // general
  ACCT_STD_DEMAND_BY_INST_ID = "ACCT_STD_DEMAND_BY_INST_ID",
  ACCT_STD_DEMAND_BY_DEPT_ID = "ACCT_STD_DEMAND_BY_DEPT_ID",
  ACCT_STD_DEMAND_BY_BRANCH_ID = "ACCT_STD_DEMAND_BY_BRANCH_ID",
  ACCT_STD_DEMAND_BY_CLASS_ID = "ACCT_STD_DEMAND_BY_CLASS_ID",
  ACCT_STD_DEMAND_BY_SEMESTER_ID = "ACCT_STD_DEMAND_BY_SEMESTER_ID",
  ACCT_STD_DEMAND_BY_SECTION_ID = "ACCT_STD_DEMAND_BY_SECTION_ID",
  ACCT_STD_DEMAND_BY_CATEGORY_ID = "ACCT_STD_DEMAND_BY_CATEGORY_ID",
  ACCT_STD_DEMAND_BY_INST_AND_CATEGORY_ID = "ACCT_STD_DEMAND_BY_INST_AND_CATEGORY_ID",
  ACCT_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID = "ACCT_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID",
  ACCT_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID = "ACCT_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID",
  ACCT_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID = "ACCT_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID",
  ACCT_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID = "ACCT_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID",
  ACCT_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID = "ACCT_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID",

  // Freshers
  FRESHER_DEMAND_RAISED_BY_INST_ID = "FRESHER_DEMAND_RAISED_BY_INST_ID",
  FRESHER_DEMAND_RAISED_BY_DEPT_ID = "FRESHER_DEMAND_RAISED_BY_DEPT_ID",
  FRESHER_DEMAND_RAISED_BY_BRANCH_ID = "FRESHER_DEMAND_RAISED_BY_BRANCH_ID",
  FRESHER_DEMAND_RAISED_BY_CLASS_ID = "FRESHER_DEMAND_RAISED_BY_CLASS_ID",
  FRESHER_DEMAND_RAISED_BY_SEMESTER_ID = "FRESHER_DEMAND_RAISED_BY_SEMESTER_ID",
  FRESHER_DEMAND_RAISED_BY_SECTION_ID = "FRESHER_DEMAND_RAISED_BY_SECTION_ID",
  FRESHER_DEMAND_RAISED_BY_CATEGORY_ID = "FRESHER_DEMAND_RAISED_BY_CATEGORY_ID",
  FRESHER_DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID = "FRESHER_DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID",
  FRESHER_DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID = "FRESHER_DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID",
  FRESHER_DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID = "FRESHER_DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID",
  FRESHER_DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID = "FRESHER_DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID",
  FRESHER_DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID = "FRESHER_DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID",

  //Existing
  EXISTING_DEMAND_RAISED_BY_INST_ID = "EXISTING_DEMAND_RAISED_BY_INST_ID",
  EXISTING_DEMAND_RAISED_BY_DEPT_ID = "EXISTING_DEMAND_RAISED_BY_DEPT_ID",
  EXISTING_DEMAND_RAISED_BY_BRANCH_ID = "EXISTING_DEMAND_RAISED_BY_BRANCH_ID",
  EXISTING_DEMAND_RAISED_BY_CLASS_ID = "EXISTING_DEMAND_RAISED_BY_CLASS_ID",
  EXISTING_DEMAND_RAISED_BY_SEMESTER_ID = "EXISTING_DEMAND_RAISED_BY_SEMESTER_ID",
  EXISTING_DEMAND_RAISED_BY_SECTION_ID = "EXISTING_DEMAND_RAISED_BY_SECTION_ID",
  EXISTING_DEMAND_RAISED_BY_CATEGORY_ID = "EXISTING_DEMAND_RAISED_BY_CATEGORY_ID",
  EXISTING_DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID = "EXISTING_DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID",
  EXISTING_DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID = "EXISTING_DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID",
  EXISTING_DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID = "EXISTING_DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID",
  EXISTING_DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID = "EXISTING_DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID",
  EXISTING_DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID = "EXISTING_DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID",

  //students by completely paid fee
  COMPLETELY_PAID_BY_INST_ID = "COMPLETELY_PAID_BY_INST_ID",
  COMPLETELY_PAID_BY_DEPT_ID = "COMPLETELY_PAID_BY_DEPT_ID",
  COMPLETELY_PAID_BY_BRANCH_ID = "COMPLETELY_PAID_BY_BRANCH_ID",
  COMPLETELY_PAID_BY_CLASS_ID = "COMPLETELY_PAID_BY_CLASS_ID",
  COMPLETELY_PAID_BY_SEMESTER_ID = "COMPLETELY_PAID_BY_SEMESTER_ID",
  COMPLETELY_PAID_BY_SECTION_ID = "COMPLETELY_PAID_BY_SECTION_ID",
  COMPLETELY_PAID_BY_CATEGORY_ID = "COMPLETELY_PAID_BY_CATEGORY_ID",
  COMPLETELY_PAID_BY_DEPT_AND_CATEGORY_ID = "COMPLETELY_PAID_BY_DEPT_AND_CATEGORY_ID",
  COMPLETELY_PAID_BY_BRANCH_AND_CATEGORY_ID = "COMPLETELY_PAID_BY_BRANCH_AND_CATEGORY_ID",
  COMPLETELY_PAID_BY_CLASS_AND_CATEGORY_ID = "COMPLETELY_PAID_BY_CLASS_AND_CATEGORY_ID",
  COMPLETELY_PAID_BY_SEMESTER_AND_CATEGORY_ID = "COMPLETELY_PAID_BY_SEMESTER_AND_CATEGORY_ID",
  COMPLETELY_PAID_BY_SECTION_AND_CATEGORY_ID = "COMPLETELY_PAID_BY_SECTION_AND_CATEGORY_ID",

  //students by partially paid fee
  PARTIALLY_PAID_BY_INST_ID = "PARTIALLY_PAID_BY_INST_ID",
  PARTIALLY_PAID_BY_DEPT_ID = "PARTIALLY_PAID_BY_DEPT_ID",
  PARTIALLY_PAID_BY_BRANCH_ID = "PARTIALLY_PAID_BY_BRANCH_ID",
  PARTIALLY_PAID_BY_CLASS_ID = "PARTIALLY_PAID_BY_CLASS_ID",
  PARTIALLY_PAID_BY_SEMESTER_ID = "PARTIALLY_PAID_BY_SEMESTER_ID",
  PARTIALLY_PAID_BY_SECTION_ID = "PARTIALLY_PAID_BY_SECTION_ID",
  PARTIALLY_PAID_BY_CATEGORY_ID = "PARTIALLY_PAID_BY_CATEGORY_ID",
  PARTIALLY_PAID_BY_DEPT_AND_CATEGORY_ID = "PARTIALLY_PAID_BY_DEPT_AND_CATEGORY_ID",
  PARTIALLY_PAID_BY_BRANCH_AND_CATEGORY_ID = "PARTIALLY_PAID_BY_BRANCH_AND_CATEGORY_ID",
  PARTIALLY_PAID_BY_CLASS_AND_CATEGORY_ID = "PARTIALLY_PAID_BY_CLASS_AND_CATEGORY_ID",
  PARTIALLY_PAID_BY_SEMESTER_AND_CATEGORY_ID = "PARTIALLY_PAID_BY_SEMESTER_AND_CATEGORY_ID",
  PARTIALLY_PAID_BY_SECTION_AND_CATEGORY_ID = "PARTIALLY_PAID_BY_SECTION_AND_CATEGORY_ID",

  //bwt amount
  IN_BWT_AMT_PAID_BY_INST_ID = "IN_BWT_AMT_PAID_BY_INST_ID",
  IN_BWT_AMT_PAID_BY_DEPT_ID = "IN_BWT_AMT_PAID_BY_DEPT_ID",
  IN_BWT_AMT_PAID_BY_BRANCH_ID = "IN_BWT_AMT_PAID_BY_BRANCH_ID",
  IN_BWT_AMT_PAID_BY_CLASS_ID = "IN_BWT_AMT_PAID_BY_CLASS_ID",
  IN_BWT_AMT_PAID_BY_SEMESTER_ID = "IN_BWT_AMT_PAID_BY_SEMESTER_ID",
  IN_BWT_AMT_PAID_BY_SECTION_ID = "IN_BWT_AMT_PAID_BY_SECTION_ID",
  IN_BWT_AMT_PAID_BY_CATEGORY_ID = "IN_BWT_AMT_PAID_BY_CATEGORY_ID",
  IN_BWT_AMT_PAID_BY_DEPT_AND_CATEGORY_ID = "IN_BWT_AMT_PAID_BY_DEPT_AND_CATEGORY_ID",
  IN_BWT_AMT_PAID_BY_BRANCH_AND_CATEGORY_ID = "IN_BWT_AMT_PAID_BY_BRANCH_AND_CATEGORY_ID",
  IN_BWT_AMT_PAID_BY_CLASS_AND_CATEGORY_ID = "IN_BWT_AMT_PAID_BY_CLASS_AND_CATEGORY_ID",
  IN_BWT_AMT_PAID_BY_SEMESTER_AND_CATEGORY_ID = "IN_BWT_AMT_PAID_BY_SEMESTER_AND_CATEGORY_ID",
  IN_BWT_AMT_PAID_BY_SECTION_AND_CATEGORY_ID = "IN_BWT_AMT_PAID_BY_SECTION_AND_CATEGORY_ID",

  //advance query type
  DEPOSIT_BAL_BY_INST_ID = "DEPOSIT_BAL_BY_INST_ID",
  DEPOSIT_BAL_BY_DEPT_ID = "DEPOSIT_BAL_BY_DEPT_ID",
  DEPOSIT_BAL_BY_BRANCH_ID = "DEPOSIT_BAL_BY_BRANCH_ID",
  DEPOSIT_BAL_BY_CLASS_ID = "DEPOSIT_BAL_BY_CLASS_ID",
  DEPOSIT_BAL_BY_SEMESTER_ID = "DEPOSIT_BAL_BY_SEMESTER_ID",
  DEPOSIT_BAL_BY_SECTION_ID = "DEPOSIT_BAL_BY_SECTION_ID",
  DEPOSIT_BAL_BY_CATEGORY_ID = "DEPOSIT_BAL_BY_CATEGORY_ID",
  DEPOSIT_BAL_BY_DEPT_AND_CATEGORY_ID = "DEPOSIT_BAL_BY_DEPT_AND_CATEGORY_ID",
  DEPOSIT_BAL_BY_BRANCH_AND_CATEGORY_ID = "DEPOSIT_BAL_BY_BRANCH_AND_CATEGORY_ID",
  DEPOSIT_BAL_BY_CLASS_AND_CATEGORY_ID = "DEPOSIT_BAL_BY_CLASS_AND_CATEGORY_ID",
  DEPOSIT_BAL_BY_SEMESTER_AND_CATEGORY_ID = "DEPOSIT_BAL_BY_SEMESTER_AND_CATEGORY_ID",
  DEPOSIT_BAL_BY_SECTION_AND_CATEGORY_ID = "DEPOSIT_BAL_BY_SECTION_AND_CATEGORY_ID",

  //student receipt

  DEMAND_RAISED_NOT_PAID_BY_INST_ID = "DEMAND_RAISED_NOT_PAID_BY_INST_ID",
  DEMAND_RAISED_NOT_PAID_BY_DEPT_ID = "DEMAND_RAISED_NOT_PAID_BY_DEPT_ID",
  DEMAND_RAISED_NOT_PAID_BY_BRANCH_ID = "DEMAND_RAISED_NOT_PAID_BY_BRANCH_ID",
  DEMAND_RAISED_NOT_PAID_BY_CLASS_ID = "DEMAND_RAISED_NOT_PAID_BY_CLASS_ID",
  DEMAND_RAISED_NOT_PAID_BY_SEMESTER_ID = "DEMAND_RAISED_NOT_PAID_BY_SEMESTER_ID",
  DEMAND_RAISED_NOT_PAID_BY_SECTION_ID = "DEMAND_RAISED_NOT_PAID_BY_SECTION_ID",
  DEMAND_RAISED_NOT_PAID_BY_CATEGORY_ID = "DEMAND_RAISED_NOT_PAID_BY_CATEGORY_ID",
  DEMAND_RAISED_NOT_PAID_BY_DEPT_AND_CATEGORY_ID = "DEMAND_RAISED_NOT_PAID_BY_DEPT_AND_CATEGORY_ID",
  DEMAND_RAISED_NOT_PAID_BY_BRANCH_AND_CATEGORY_ID = "DEMAND_RAISED_NOT_PAID_BY_BRANCH_AND_CATEGORY_ID",
  DEMAND_RAISED_NOT_PAID_BY_CLASS_AND_CATEGORY_ID = "DEMAND_RAISED_NOT_PAID_BY_CLASS_AND_CATEGORY_ID",
  DEMAND_RAISED_NOT_PAID_BY_SEMESTER_AND_CATEGORY_ID = "DEMAND_RAISED_NOT_PAID_BY_SEMESTER_AND_CATEGORY_ID",
  DEMAND_RAISED_NOT_PAID_BY_SECTION_AND_CATEGORY_ID = "DEMAND_RAISED_NOT_PAID_BY_SECTION_AND_CATEGORY_ID",

  UNMAPPED_ACCT_LDGR = "UNMAPPED_ACCT_LDGR",
  // NOT_SELECTED = "NOT_SELECTED",
  MAPPED_ACCT_LDGR = "MAPPED_ACCT_LDGR",

  //by acct ledgers
  ACCT_LDGR_WISE_BY_INST_ID = "ACCT_LDGR_WISE_BY_INST_ID",
  ACCT_LDGR_WISE_BY_DEPT_ID = "ACCT_LDGR_WISE_BY_DEPT_ID",
  ACCT_LDGR_WISE_BY_BRANCH_ID = "ACCT_LDGR_WISE_BY_BRANCH_ID",
  ACCT_LDGR_WISE_BY_CLASS_ID = "ACCT_LDGR_WISE_BY_CLASS_ID",
  ACCT_LDGR_WISE_BY_SEMESTER_ID = "ACCT_LDGR_WISE_BY_SEMESTER_ID",
  ACCT_LDGR_WISE_BY_SECTION_ID = "ACCT_LDGR_WISE_BY_SECTION_ID",
  ACCT_LDGR_WISE_BY_CATEGORY_ID = "ACCT_LDGR_WISE_BY_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_DEPT_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_DEPT_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_BRANCH_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_BRANCH_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_CLASS_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_CLASS_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_SEMESTER_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_SEMESTER_AND_CATEGORY_ID",
  ACCT_LDGR_WISE_BY_SECTION_AND_CATEGORY_ID = "ACCT_LDGR_WISE_BY_SECTION_AND_CATEGORY_ID",

  // refunds
  REFUND_BY_INST_ID = "REFUND_BY_INST_ID",
  REFUND_BY_DEPT_ID = "REFUND_BY_DEPT_ID",
  REFUND_BY_BRANCH_ID = "REFUND_BY_BRANCH_ID",
  REFUND_BY_CLASS_ID = "REFUND_BY_CLASS_ID",
  REFUND_BY_SEMESTER_ID = "REFUND_BY_SEMESTER_ID",
  REFUND_BY_SECTION_ID = "REFUND_BY_SECTION_ID",
  REFUND_BY_CATEGORY_ID = "REFUND_BY_CATEGORY_ID",
  REFUND_BY_DEPT_AND_CATEGORY_ID = "REFUND_BY_DEPT_AND_CATEGORY_ID",
  REFUND_BY_BRANCH_AND_CATEGORY_ID = "REFUND_BY_BRANCH_AND_CATEGORY_ID",
  REFUND_BY_CLASS_AND_CATEGORY_ID = "REFUND_BY_CLASS_AND_CATEGORY_ID",
  REFUND_BY_SEMESTER_AND_CATEGORY_ID = "REFUND_BY_SEMESTER_AND_CATEGORY_ID",
  REFUND_BY_SECTION_AND_CATEGORY_ID = "REFUND_BY_SECTION_AND_CATEGORY_ID",
  // COncessions
  CONCESSION_BY_INST_ID = "CONCESSION_BY_INST_ID",
  CONCESSION_BY_DEPT_ID = "CONCESSION_BY_DEPT_ID",
  CONCESSION_BY_BRANCH_ID = "CONCESSION_BY_BRANCH_ID",
  CONCESSION_BY_CLASS_ID = "CONCESSION_BY_CLASS_ID",
  CONCESSION_BY_SEMESTER_ID = "CONCESSION_BY_SEMESTER_ID",
  CONCESSION_BY_SECTION_ID = "CONCESSION_BY_SECTION_ID",
  CONCESSION_BY_CATEGORY_ID = "CONCESSION_BY_CATEGORY_ID",
  CONCESSION_BY_DEPT_AND_CATEGORY_ID = "CONCESSION_BY_DEPT_AND_CATEGORY_ID",
  CONCESSION_BY_BRANCH_AND_CATEGORY_ID = "CONCESSION_BY_BRANCH_AND_CATEGORY_ID",
  CONCESSION_BY_CLASS_AND_CATEGORY_ID = "CONCESSION_BY_CLASS_AND_CATEGORY_ID",
  CONCESSION_BY_SEMESTER_AND_CATEGORY_ID = "CONCESSION_BY_SEMESTER_AND_CATEGORY_ID",
  CONCESSION_BY_SECTION_AND_CATEGORY_ID = "CONCESSION_BY_SECTION_AND_CATEGORY_ID",

  // demand raised
  DEMAND_RAISED_BY_INST_ID = "DEMAND_RAISED_BY_INST_ID",
  DEMAND_RAISED_BY_DEPT_ID = "DEMAND_RAISED_BY_DEPT_ID",
  DEMAND_RAISED_BY_BRANCH_ID = "DEMAND_RAISED_BY_BRANCH_ID",
  DEMAND_RAISED_BY_CLASS_ID = "DEMAND_RAISED_BY_CLASS_ID",
  DEMAND_RAISED_BY_SEMESTER_ID = "DEMAND_RAISED_BY_SEMESTER_ID",
  DEMAND_RAISED_BY_SECTION_ID = "DEMAND_RAISED_BY_SECTION_ID",
  DEMAND_RAISED_BY_CATEGORY_ID = "DEMAND_RAISED_BY_CATEGORY_ID",
  DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID = "DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID",
  DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID = "DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID",
  DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID = "DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID",
  DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID = "DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID",
  DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID = "DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID",

  // PARTIALLY_OR_COMPLETELY_paid
  PARTIALLY_OR_COMPLETELY_PAID_BY_INST_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_INST_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_DEPT_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_DEPT_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_BRANCH_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_BRANCH_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_CLASS_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_CLASS_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_SEMESTER_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_SEMESTER_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_SECTION_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_SECTION_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_CATEGORY_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_CATEGORY_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_DEPT_AND_CATEGORY_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_DEPT_AND_CATEGORY_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_BRANCH_AND_CATEGORY_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_BRANCH_AND_CATEGORY_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_CLASS_AND_CATEGORY_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_CLASS_AND_CATEGORY_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_SEMESTER_AND_CATEGORY_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_SEMESTER_AND_CATEGORY_ID",
  PARTIALLY_OR_COMPLETELY_PAID_BY_SECTION_AND_CATEGORY_ID = "PARTIALLY_OR_COMPLETELY_PAID_BY_SECTION_AND_CATEGORY_ID",

  //  new due date
  NEW_DUE_DATE_BY_INST_ID = "NEW_DUE_DATE_BY_INST_ID",
  NEW_DUE_DATE_BY_DEPT_ID = "NEW_DUE_DATE_BY_DEPT_ID",
  NEW_DUE_DATE_BY_BRANCH_ID = "NEW_DUE_DATE_BY_BRANCH_ID",
  NEW_DUE_DATE_BY_CLASS_ID = "NEW_DUE_DATE_BY_CLASS_ID",
  NEW_DUE_DATE_BY_SEMESTER_ID = "NEW_DUE_DATE_BY_SEMESTER_ID",
  NEW_DUE_DATE_BY_SECTION_ID = "NEW_DUE_DATE_BY_SECTION_ID",
  NEW_DUE_DATE_BY_CATEGORY_ID = "NEW_DUE_DATE_BY_CATEGORY_ID",
  NEW_DUE_DATE_BY_DEPT_AND_CATEGORY_ID = "NEW_DUE_DATE_BY_DEPT_AND_CATEGORY_ID",
  NEW_DUE_DATE_BY_BRANCH_AND_CATEGORY_ID = "NEW_DUE_DATE_BY_BRANCH_AND_CATEGORY_ID",
  NEW_DUE_DATE_BY_CLASS_AND_CATEGORY_ID = "NEW_DUE_DATE_BY_CLASS_AND_CATEGORY_ID",
  NEW_DUE_DATE_BY_SEMESTER_AND_CATEGORY_ID = "NEW_DUE_DATE_BY_SEMESTER_AND_CATEGORY_ID",
  NEW_DUE_DATE_BY_SECTION_AND_CATEGORY_ID = "NEW_DUE_DATE_BY_SECTION_AND_CATEGORY_ID",

  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_INST_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_INST_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_DEPT_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_DEPT_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_BRANCH_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_BRANCH_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CLASS_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CLASS_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SEMESTER_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SEMESTER_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SECTION_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SECTION_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CATEGORY_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CATEGORY_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_DEPT_AND_CATEGORY_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_DEPT_AND_CATEGORY_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_BRANCH_AND_CATEGORY_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_BRANCH_AND_CATEGORY_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CLASS_AND_CATEGORY_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CLASS_AND_CATEGORY_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SEMESTER_AND_CATEGORY_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SEMESTER_AND_CATEGORY_ID",
  STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SECTION_AND_CATEGORY_ID = "STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SECTION_AND_CATEGORY_ID",

  ACCT_CURR_STD_DEMAND_BY_INST_ID = "ACCT_CURR_STD_DEMAND_BY_INST_ID",
  ACCT_CURR_STD_DEMAND_BY_DEPT_ID = "ACCT_CURR_STD_DEMAND_BY_DEPT_ID",
  ACCT_CURR_STD_DEMAND_BY_BRANCH_ID = "ACCT_CURR_STD_DEMAND_BY_BRANCH_ID",
  ACCT_CURR_STD_DEMAND_BY_CLASS_ID = "ACCT_CURR_STD_DEMAND_BY_CLASS_ID",
  ACCT_CURR_STD_DEMAND_BY_SEMESTER_ID = "ACCT_CURR_STD_DEMAND_BY_SEMESTER_ID",
  ACCT_CURR_STD_DEMAND_BY_SECTION_ID = "ACCT_CURR_STD_DEMAND_BY_SECTION_ID",
  ACCT_CURR_STD_DEMAND_BY_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID",
  ACCT_CURR_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID = "ACCT_CURR_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID",

  //Demand not raised
  DEMAND_NOT_RAISED_BY_INST_ID = "DEMAND_NOT_RAISED_BY_INST_ID",
  DEMAND_NOT_RAISED_BY_DEPT_ID = "DEMAND_NOT_RAISED_BY_DEPT_ID",
  DEMAND_NOT_RAISED_BY_BRANCH_ID = "DEMAND_NOT_RAISED_BY_BRANCH_ID",
  DEMAND_NOT_RAISED_BY_CLASS_ID = "DEMAND_NOT_RAISED_BY_CLASS_ID",
  DEMAND_NOT_RAISED_BY_SEMESTER_ID = "DEMAND_NOT_RAISED_BY_SEMESTER_ID",
  DEMAND_NOT_RAISED_BY_SECTION_ID = "DEMAND_NOT_RAISED_BY_SECTION_ID",
  DEMAND_NOT_RAISED_BY_CATEGORY_ID = "DEMAND_NOT_RAISED_BY_CATEGORY_ID",
  DEMAND_NOT_RAISED_BY_DEPT_AND_CATEGORY_ID = "DEMAND_NOT_RAISED_BY_DEPT_AND_CATEGORY_ID",
  DEMAND_NOT_RAISED_BY_BRANCH_AND_CATEGORY_ID = "DEMAND_NOT_RAISED_BY_BRANCH_AND_CATEGORY_ID",
  DEMAND_NOT_RAISED_BY_CLASS_AND_CATEGORY_ID = "DEMAND_NOT_RAISED_BY_CLASS_AND_CATEGORY_ID",
  DEMAND_NOT_RAISED_BY_SEMESTER_AND_CATEGORY_ID = "DEMAND_NOT_RAISED_BY_SEMESTER_AND_CATEGORY_ID",
  DEMAND_NOT_RAISED_BY_SECTION_AND_CATEGORY_ID = "DEMAND_NOT_RAISED_BY_SECTION_AND_CATEGORY_ID",

  ANONYMOUS_STUDENT = "ANONYMOUS_STUDENT",
  SOCIAL_WELFARE_STUDENT = "SOCIAL_WELFARE_STUDENT",
  AGENT_STUDENT = "AGENT_STUDENT",
  ALL_ANONYMOUS_STUDENTS = "ALL_ANONYMOUS_STUDENTS",

  PASSED_STDS_DEMAND_BY_INST_ID = "PASSED_STDS_DEMAND_BY_INST_ID",
  PASSED_STDS_DEMAND_BY_DEPT_ID = "PASSED_STDS_DEMAND_BY_DEPT_ID",
  PASSED_STDS_DEMAND_BY_BRANCH_ID = "PASSED_STDS_DEMAND_BY_BRANCH_ID",

  BY_AGENT_ID = "BY_AGENT_ID",
  ACCT_STD_DEMAND_BY_ENTRY_ID = "ACCT_STD_DEMAND_BY_ENTRY_ID",
  STDS_NOT_ASSOICATED_TO_AGENT = "STDS_NOT_ASSOICATED_TO_AGENT",
  BY_AGENT_STUDENT_ASSOCI_ID = "BY_AGENT_STUDENT_ASSOCI_ID",
}

export enum DemandDetailsQueryType {
  ALL_DEMAND_DETAILS = "ALL_DEMAND_DETAILS",
  DEMAND_DETAILS_GT_0 = "DEMAND_DETAILS_GT_0",
  DEMAND_DETAILS_TO_REFUND = "DEMAND_DETAILS_TO_REFUND",
  DEMAND_DETAILS_TO_EXCHANGE = "DEMAND_DETAILS_TO_EXCHANGE",
}

export enum LedgerType {
  GENERAL = "GENERAL",
  DEPOSIT = "DEPOSIT",
}

export enum DepositLedgerQueryType {
  ALL_DEPOSIT_LDGRS_BY_STD_ID = "ALL_DEPOSIT_LDGRS_BY_STD_ID",
  DEPOSIT_GT_0_BY_STD_ID = "DEPOSIT_GT_0_BY_STD_ID",
  DEPOSIT_OB_GT_0_BY_STD_ID = "DEPOSIT_OB_GT_0_BY_STD_ID",
  ALL_DEPOSIT_LDGRS_FOR_ALL_STDS = "ALL_DEPOSIT_LDGRS_FOR_ALL_STDS",
  // ALL_DEPOSIT_LDGRS = "ALL_DEPOSIT_LDGRS",
  // DEPOSIT_GT_0 = "DEPOSIT_GT_0",
}

export enum AcctGroupLdgrQueryType {
  GROUP_LDGR_BY_INST_ID = "GROUP_LDGR_BY_INST_ID",
  GROUP_STD_LDGR_BY_INST_ID = "GROUP_STD_LDGR_BY_INST_ID",
  GROUP_LDGR_FOR_LIABILITIES = "GROUP_LDGR_FOR_LIABILITIES",
  GROUP_LDGR_FOR_ASSETS = "GROUP_LDGR_FOR_ASSETS",
}
export enum AcctLedgerQueryType {
  ACCT_LDGRS_BY_INST_ID = "ACCT_LDGRS_BY_INST_ID",
  DEPOSIT_FEE_LDGRS = "DEPOSIT_FEE_LDGRS",
  CASH_AND_BANK = "CASH_AND_BANK",
  TRANSPORT_FEE_LDGRS = "TRANSPORT_FEE_LDGRS",
  APPLICATION_FEE_LDGRS = "APPLICATION_FEE_LDGRS",
  EXAM_FEE_LDGRS = "EXAM_FEE_LDGRS",
  EXCEPT_CASH_AND_BANK = "EXCEPT_CASH_AND_BANK",
  APPLICATION_AND_EXAM_FEE_LDGRS = "APPLICATION_AND_EXAM_FEE_LDGRS",
  STD_FEE_ACCT_LDGRS = "STD_FEE_ACCT_LDGRS",
  ACCT_LDGR_BY_GROUP_LDGR_ID = "ACCT_LDGR_BY_GROUP_LDGR_ID",
  HIDDEN_CASH_ACCOUNT = "HIDDEN_CASH_ACCOUNT",
  ACCT_LDGRS_CASH = "ACCT_LDGRS_CASH",
  ACCT_LDGRS_BANK = "ACCT_LDGRS_BANK",
  ACCT_LDGR_USER_DEFINED = "ACCT_LDGR_USER_DEFINED",
  STD_LDGR_USER_DEFINED = "STD_LDGR_USER_DEFINED",
  ONLY_APPLICATION_FEE_LDGR = "ONLY_APPLICATION_FEE_LDGR",
  CAPITATION_FEE_STD_LDGRS = "CAPITATION_FEE_STD_LDGRS",
  NON_CAPITATION_FEE_STD_LDGRS = "NON_CAPITATION_FEE_STD_LDGRS",
  ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND = "ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND",
  BILL_BOOK_ASSIGNED_STD_FEE_ACCT_LDGRS = "BILL_BOOK_ASSIGNED_STD_FEE_ACCT_LDGRS",
  SUSPENSE_DEPOSIT = "SUSPENSE_DEPOSIT",
  STD_FEE_ACCT_LDGRS_FOR_REPORT = "STD_FEE_ACCT_LDGRS_FOR_REPORT",
  SUNDRY_DEBITORS = "SUNDRY_DEBITORS",
  SUNDRY_SUPPLIERS = "SUNDRY_SUPPLIERS",
  STD_FEE_ACCT_LDGRS_WITHOUT_FINE = "STD_FEE_ACCT_LDGRS_WITHOUT_FINE",
}

export enum AcctGroupLedgerFor {
  GENERAL = "GENERAL",
  INCOME = "INCOME",
  EXPENSE = "EXPENSE",
  RECEIPT = "RECEIPT",
  PAYMENT = "PAYMENT",
  PL = "PL",
}
export enum GroupLedgerRPTypes {
  BankAndCash = "BC",
  CurrentAsset = "CA",
  FixedAsset = "FA",
  Expense = "EX",
  Income = "IN",
  Liability = "LI",
  ProfitAndLoss = "PL",
}

export enum AcdSubjComboQueryType {
  ALL_COMBOS = "ALL_COMBOS",
  COMBO_STDS_ALLOCATED = "COMBO_STDS_ALLOCATED",
  COMBO_STDS_NOT_ALLOCATED = "COMBO_STDS_NOT_ALLOCATED",
  COMBO_BY_TEST_CLASS_ID = "COMBO_BY_TEST_CLASS_ID",
}

import React, { useEffect, useRef, useState } from "react";
import {
  DocumentAcknowledgement,
  FileUploadParams,
} from "../../../utils/Enum.types";
import ReactToPrint from "react-to-print";
import { Button } from "../../../stories/Button/Button";
import useDocumentConfig from "./hooks/useDocumentConfig";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import { toStandardDate } from "../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../utils/constants";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { GetInstDocumentContent, GetInstDocuments } from "./queries";
import { useNavigate, useParams } from "react-router-dom";
import {
  AcdStudentDetails,
  StudentAcademicData,
  StudentAcademicVars,
} from "../../Academics/hooks/useAcdStudentDetails";
import { GetAcdStudentDetails } from "../../Academics/queries/student";
import Acknowledgment from "./Acknowledgment";
import { GetInstDocumentVars, GetInstDocumentsData } from "./Types";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import Avatar from "../../../images/Avatar.svg";
import useInstLabels from "../../../customhooks/general/useInstLabels";
interface props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedId: number;
  selectedStudents: number[];
  setStudentIdSet?: React.Dispatch<React.SetStateAction<Set<number>>>;
  pageType: DocumentAcknowledgement;
}

const PrintDocuments = ({
  setModal,
  selectedId,
  selectedStudents,
  setStudentIdSet,
  pageType,
}: props) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { InstFormData } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { token } = useToken();
  const { InstId, documentId, studentId } = useParams();
  const navigate = useNavigate();
  const [imageString, setImageString] = useState("");

  const [GetInstDocumentData, { data: DocumentsList }] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments, {
    variables: {
      token,
      inst_id: InstId!,
      custom_doc_name: DocumentAcknowledgement.STD_DOC_ACK,
    },
  });

  useEffect(() => {
    if (token && InstId!) {
      GetInstDocumentData();
    }
    // eslint-disable-next-line
  }, [token, GetInstDocumentData]);
  const customDocID = DocumentsList ? DocumentsList.GetInstDocuments[0].id : 0;

  const {
    titleName,
    academicYear,
    bottomMargin,
    custNameColor,
    custName,
    footerHeight,
    footerText,
    headerBorderColor,
    headerHeight,
    instAddress,
    instLogo,
    instName,
    instNamecolor,
    leftMargin,
    rightMargin,
    sign1,
    sign2,
    sign3,
    sign4,
    sign5,
    topMargin,
    docDate,
    footerBorderColor,
    instAddressColor,
    minBodyHeight,
    studentPhoto,
    feeCategory,
  } = useDocumentConfig({
    selectedId:
      pageType === DocumentAcknowledgement.STD_DOC_ACK
        ? Number(customDocID)
        : selectedId
        ? Number(selectedId)
        : Number(documentId),
    selectedStudents,
    setStudentIdSet: setStudentIdSet!,
    pageType: DocumentAcknowledgement.GENERAL
      ? DocumentAcknowledgement.GENERAL
      : DocumentAcknowledgement.STD_DOC_ACK,
  });
  const { studentFormData } = useStudentDatabyId();
  const [GetInstDocumentContentData, { data: contentData }] = useLazyQuery(
    GetInstDocumentContent,
    {
      variables: {
        token,
        inst_id: InstId!,
        document_id: selectedId ? selectedId : Number(documentId),
      },
    }
  );

  useEffect(() => {
    if (token && InstId! && documentId) {
      GetInstDocumentContentData();
    } else {
      if (token && InstId! && selectedId) {
        GetInstDocumentContentData();
      }
    }
  }, [token, InstId, documentId, selectedId, GetInstDocumentContentData]);

  const [GetStudentDetailsByIds, { data }] = useLazyQuery<
    StudentAcademicData,
    StudentAcademicVars
  >(GetAcdStudentDetails, {
    variables: { token, ids: selectedStudents },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (token && selectedStudents) {
      GetStudentDetailsByIds();
    }
  }, [token, GetStudentDetailsByIds, selectedStudents]);

  const replaceNames = (inputStr: string, stdData: AcdStudentDetails) => {
    let replacedStr = inputStr;

    const replacements: { [key: string]: string } = {
      "FATHER-NAME": stdData.std_father_name || "",
      "STUDENT-NAME":
        stdData.first_name +
          "" +
          stdData.middle_name +
          " " +
          stdData.last_name || "",

      "MOTHER-NAME": stdData.std_mother_name || "",
      "REGISTRATION-NO": stdData.std_reg_no || "",
      CATEGORY: stdData.category.cat_desc || "",
      DEPARTMENT: stdData.acd_dept.dept_desc || "",
      BRANCH: stdData.acd_branch.branch_desc || "",
      CLASS: stdData.acd_class.class_desc || "",
      SEMESTER: stdData.acd_semester.sem_desc || "",
      SECTION: stdData.acd_section.section_desc || "",
      "FEE-CATEGORY": "",
      "ADMISSION-NO": stdData.std_adm_no || "",
      "MOBILE-NO": stdData.std_mobile || "",
      "EMAIL-ID": stdData.std_email || "",
      ADDRESS: stdData.std_details.std_corr_address || "",
      // "<FEEBALANCE>": 0,
      "DATE-OF-ADMISSION": toStandardDate(stdData.std_doa) || "",
      "DATE-OF-BIRTH": toStandardDate(stdData.std_dob) || "",
    };

    const regex = new RegExp(Object.keys(replacements).join("|"), "g");

    replacedStr = replacedStr.replace(regex, (match) => replacements[match]);

    return replacedStr;
  };
  useEffect(() => {
    if (studentFormData.std_profile_filename !== "") {
      getDownloadUrl(
        studentFormData.std_profile_filename,
        false,
        setImageString
      );
    }
  }, [studentFormData.std_profile_filename]);
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  return (
    <>
      <div className="documents-print" ref={componentRef}>
        {data
          ? data.nodes.map((std, index) => {
              return (
                <div className="documents-print__container" key={index}>
                  <style type="text/css" media="print" scoped>
                    {`
     
      html,body {
   height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;

    }
            @page {
          size:A4 Portrait;
          width:100%;
          height:100%;
            }

           .documents-print__container{
            position:relative;
            padding-top: ${topMargin?.config_integer_value}px;
            padding-right: ${rightMargin?.config_integer_value}px;
            padding-bottom: ${bottomMargin?.config_integer_value}px;
            padding-left: ${leftMargin?.config_integer_value}px;
            height:100% !important;
           }

 .documents-print__container--form,.documents-print{
  height:100% !important;
 }    
 .document-layout__frame--left--body{
   height:${minBodyHeight} !important;  
 }
 .document-layout__frame--left--body--container{
  height:calc(100% - 100px) !important;
 }
.document-layout__frame--left--footer{
 position: fixed;
              bottom: 0;
              width: 100%;
              height: ${footerHeight?.config_integer_value}px;
}
.document-layout__frame--left--body--content{
  height:calc(100% - 30px)!important;
}
.doc-ack-table{
height:100% !important;
}


          `}
                  </style>
                  <div className="documents-print__container--form">
                    <div
                      className="document-layout__frame--left--header"
                      style={{
                        height: `${headerHeight?.config_integer_value}px`,
                        borderBottom: `1px dashed ${headerBorderColor?.config_string_value}`,
                        justifyContent: "center",
                      }}
                    >
                      {instLogo?.config_boolean_value && (
                        <div className="document-layout__frame--left--header--logo">
                          {LogoOrSign.defaultLogo ? (
                            <img src={LogoOrSign.defaultLogo} alt="/" />
                          ) : null}
                        </div>
                      )}

                      <div className="document-layout__frame--left--header--inst-details">
                        {custName?.config_boolean_value && (
                          <div
                            className="document-layout__frame--left--header--cust-name"
                            style={{
                              color: `${custNameColor?.config_string_value}`,
                            }}
                          >
                            {InstFormData.cust_name}
                          </div>
                        )}
                        {instName?.config_boolean_value && (
                          <div
                            className="document-layout__frame--left--header--inst-name"
                            style={{
                              color: `${instNamecolor?.config_string_value}`,
                            }}
                          >
                            {InstFormData.inst_name_to_print}
                          </div>
                        )}
                        {instAddress?.config_boolean_value && (
                          <div
                            className="document-layout__frame--left--header--inst-address"
                            style={{
                              color: `${instAddressColor?.config_string_value}`,
                            }}
                          >
                            {InstFormData.inst_address}
                          </div>
                        )}
                      </div>
                    </div>
                    {titleName?.config_boolean_value && (
                      <div className="document-layout__frame--left--body--sub-title">
                        {titleName?.config_string_value}
                      </div>
                    )}

                    {pageType === DocumentAcknowledgement.STD_DOC_ACK && (
                      <div className="doc-ack-std-details">
                        <div className="doc-ack-std-details--flex">
                          {studentPhoto?.config_boolean_value && (
                            <img
                              src={imageString ? imageString : Avatar}
                              alt="/"
                            />
                          )}

                          <div className="doc-ack-std-details--grid">
                            <>
                              <label>Student Name</label>
                              <span>:</span>
                              <b>
                                {studentFormData &&
                                  studentFormData.first_name +
                                    " " +
                                    studentFormData.middle_name +
                                    " " +
                                    studentFormData.last_name}
                              </b>
                            </>
                            <>
                              <label>Adm No.</label>
                              <span>:</span>
                              <b>{studentFormData.adm_no}</b>
                            </>
                            <>
                              <label>Father Name</label>
                              <span>:</span>
                              <span>{studentFormData.father_name}</span>
                            </>
                          </div>
                        </div>

                        <div className="doc-ack-std-details--grid">
                          <>
                            <label>{branchLabel}</label>
                            <span>:</span>
                            <span>{studentFormData.branch}</span>
                          </>
                          <>
                            <label>{classLabel}</label>
                            <span>:</span>
                            <span>{studentFormData.class}</span>
                          </>
                          <>
                            <label>DOA</label>
                            <span>:</span>
                            <span>
                              {toStandardDate(studentFormData.std_doa)}
                            </span>
                          </>
                          {feeCategory?.config_boolean_value && (
                            <>
                              <label>{categoryLabel}</label>
                              <span>:</span>
                              <span>{studentFormData.category}</span>
                            </>
                          )}
                        </div>
                        {/* <div className="doc-ack-std-details--grid">
        <>
<label></label>
<span></span>
<span></span></>
<>
<label></label>
<span></span>
<span></span></>
<>
<label></label>
<span></span>
<span></span>
</>
        </div> */}
                      </div>
                    )}
                    <div
                      className="document-layout__frame--left--body"
                      style={{ height: `${minBodyHeight}` }}
                    >
                      <div className="document-layout__frame--left--body--container">
                        <div className="document-layout__frame--left--body--number">
                          {academicYear?.config_boolean_value && (
                            <div className="doc-ack-std-details--grid">
                              <span>Academic Year</span>
                              <span>: </span>
                              <b>{academicYear.config_string_value}</b>
                            </div>
                          )}

                          {docDate?.config_boolean_value && (
                            <div className="doc-ack-std-details--grid">
                              {" "}
                              <span>Date</span>
                              <span>:</span>
                              <b>{toStandardDate(TODAY_DATE)}</b>
                            </div>
                          )}
                        </div>

                        <div className="document-layout__frame--left--body--content">
                          {pageType === DocumentAcknowledgement.STD_DOC_ACK ? (
                            <>
                              <Acknowledgment />
                            </>
                          ) : (
                            <>
                              {contentData
                                ? replaceNames(
                                    contentData.GetInstDocumentContent,
                                    std
                                  )
                                : ""}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="document-layout__frame--left--footer"
                      style={{
                        height: `${footerHeight?.config_integer_value}px`,
                      }}
                    >
                      <div className="document-layout__frame--left--body--footer">
                        {sign1?.config_boolean_value && (
                          <b>{sign1.config_string_value}</b>
                        )}
                        {sign2?.config_boolean_value && (
                          <b>{sign2.config_string_value}</b>
                        )}
                        {sign3?.config_boolean_value && (
                          <b>{sign3.config_string_value}</b>
                        )}
                        {sign4?.config_boolean_value && (
                          <b>{sign4.config_string_value}</b>
                        )}
                        {sign5?.config_boolean_value && (
                          <b>{sign5.config_string_value}</b>
                        )}
                      </div>
                      <span
                        style={{
                          borderTop: `1px solid ${footerBorderColor?.config_string_value}`,
                        }}
                      ></span>
                      {footerText?.config_boolean_value && (
                        <> {footerText?.config_string_value}</>
                      )}
                    </div>
                  </div>
                  <div className="page-break" />
                </div>
              );
            })
          : null}
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
      <Button
        mode="cancel"
        onClick={() => {
          if (studentId) {
            setModal(false);
            navigate(`/${InstId}/admissions/students/reservations`);
          } else {
            setModal(false);
          }
        }}
      />
    </>
  );
};

export default PrintDocuments;

import { gql } from "@apollo/client";

export const IdByName = gql`
  query ListSemIdbyNames($names: [String!], $token: String!, $inst_id: ID!) {
    GetSemesterIdsByNames(
      token: $token
      semester_names: $names
      inst_id: $inst_id
    ) {
      id
      sem_desc
    }
  }
`;

export const SemIdByName = gql`
  query GetSemesterIdByName(
    $token: String!
    $inst_id: ID!
    $class_id: ID!
    $semester_name: String!
  ) {
    GetSemesterIdByName(
      token: $token
      inst_id: $inst_id
      class_id: $class_id
      semester_name: $semester_name
    )
  }
`;

import { useState } from "react";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import { AccountBookTypes, PageFor } from "../../../../../utils/Enum.types";
import TransactionList from "../TransactionList";
import Modal from "react-modal";
import { Button } from "../../../../../stories/Button/Button";
import Close from "../../../../../images/Close.svg";
interface Props {
  type: AccountBookTypes;
}

const CompleteTransactionModal = ({ type }: Props) => {
  const [CompleteDetailsModal, setCompleteDetailsModal] = useState(false);

  return (
    <>
      <TransactionList
        type={type}
        pageType={PageFor.GENERAL}
        CompleteDetailsModal={CompleteDetailsModal}
        setCompleteDetailsModal={setCompleteDetailsModal}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={CompleteDetailsModal}
        style={StudentModalStyles}
      >
        <div className="bank-book__table-section--transaction-details--tableblock">
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <TransactionList
                type={type}
                pageType={PageFor.MODAL}
                CompleteDetailsModal={CompleteDetailsModal}
                setCompleteDetailsModal={setCompleteDetailsModal}
              />
            </div>
            <div className="modal-flex__image">
              <img
                onClick={() => setCompleteDetailsModal(!CompleteDetailsModal)}
                src={Close}
                alt="/"
                className="modal-close-icon"
              />
            </div>
          </div>
        </div>
        <Button
          mode="cancel"
          onClick={() => setCompleteDetailsModal(!CompleteDetailsModal)}
        />
      </Modal>
    </>
  );
};

export default CompleteTransactionModal;

import { gql } from "@apollo/client";

export const AddAcctStockItemCategory = gql`
  mutation AddAcctStockItemCategory(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateAcctStockItemCategoryInput!
  ) {
    AddAcctStockItemCategory(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteAcctStockItemCategoryById = gql`
  mutation DeleteAcctStockItemCategoryById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcctStockItemCategoryById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddAcctStockItemGroup = gql`
  mutation AddAcctStockItemGroup(
    $token: String!
    $inst_id: ID!
    $input: CreateAcctStockItemGroupInput!
    $user_details: SubscribedUser!
  ) {
    AddAcctStockItemGroup(
      token: $token
      inst_id: $inst_id
      input: $input

      user_details: $user_details
    )
  }
`;
export const DeleteAcctStockItemGroupById = gql`
  mutation DeleteAcctStockItemGroupById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcctStockItemGroupById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddAcctStockItemUom = gql`
  mutation AddAcctStockItemUom(
    $token: String!
    $input: CreateAcctStockItemUomInput!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    AddAcctStockItemUom(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteAcctStockItemUomById = gql`
  mutation DeleteAcctStockItemUomById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcctStockItemUomById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddAcctStockItem = gql`
  mutation AddAcctStockItem(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $input: CreateAcctStockItemInput!
  ) {
    AddAcctStockItem(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      input: $input
    )
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetLibBookAttributesByInstId } from "../../../queries/Library/MasterData/Attributes/list/byInstId";
import { GetLibBookAuthorsByInstId } from "../../../queries/Library/MasterData/Author/list/byInstId";
import { GetLibBookCategoriesByInstId } from "../../../queries/Library/MasterData/BookCategory/list/byInstId";
import { GetLibBookClassificationsByInstId } from "../../../queries/Library/MasterData/Classification/list/byInstId";
import { GetLibKeywordsByInstId } from "../../../queries/Library/MasterData/Keyword/list/byInstId";
import { GetLibBookLanguagesByInstId } from "../../../queries/Library/MasterData/Languages/list/byInstId";
import { GetLibBookMediaByInstId } from "../../../queries/Library/MasterData/Media/list/byInstId";
import { GetLibBookPublishersByInstId } from "../../../queries/Library/MasterData/Publisher/list/byInstId";
import { GetLibBookSubjectsByInstId } from "../../../queries/Library/MasterData/Subjects/list/byInstid";
import { GetLibBookVendorsByInstId } from "../../../queries/Library/MasterData/Vendor/list/byInstId";
import {
  GetLibBookAttributesByInstIdData,
  GetLibBookAttributesByInstIdVars,
} from "../../../Types/Library/MasterData/Attribute/paginationTypes";
import {
  GetLibBookAuthorsByInstIdData,
  GetLibBookAuthorsByInstIdVars,
} from "../../../Types/Library/MasterData/Author/paginationTypes";
import {
  GetLibBookCategoriesByInstIdData,
  GetLibBookCategoriesByInstIdVars,
} from "../../../Types/Library/MasterData/BookCategory/paginationTypes";
import {
  GetLibBookClassificationByInstIdData,
  GetLibBookClassificationByInstIdVars,
} from "../../../Types/Library/MasterData/Classification/paginationTypes";
import {
  GetLibKeywordsByInstIdData,
  GetLibKeywordsByInstIdVars,
} from "../../../Types/Library/MasterData/Keyword/paginationTypes";
import {
  GetLibBookLanguagesByInstIdData,
  GetLibBookLanguagesByInstIdVars,
} from "../../../Types/Library/MasterData/Language/paginationTypes";
import {
  GetLibBookMediaByInstIdData,
  GetLibBookMediaByInstIdVars,
} from "../../../Types/Library/MasterData/Media/paginationTypes";
import {
  GetLibBookPublishersByInstIdData,
  GetLibBookPublishersByInstIdVars,
} from "../../../Types/Library/MasterData/Publisher/paginationTypes";
import {
  GetLibBookSubjectsByInstIdData,
  GetLibBookSubjectsByInstIdVars,
} from "../../../Types/Library/MasterData/Subjects/paginationTypes";
import {
  GetLibBookVendorsByInstIdData,
  GetLibBookVendorsByInstIdVars,
} from "../../../Types/Library/MasterData/Vendor/paginationTypes";
import {
  EMPTY_RESPONSETYPE,
  LIBRARY_ROWS_PER_PAGE,
} from "../../../utils/constants";
import { Direction, SortBy } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";

export enum LibMasterData {
  MEDIA = "MEDIA",
  ATTRIBUTE = "ATTRIBUTE",
  SUBJECT = "SUBJECT",
  CLASSIFICATION = "CLASSIFICATION",
  PUBLISHERS = "PUBLISHERS",
  AUTHORS = "AUTHORS",
  CATEGORY = "CATEGORY",
  LANGUAGES = "LANGUAGES",
  KEYWORDS = "KEYWORDS",
  VENDORS = "VENDORS",
}

const useLibMasterDataOptions = (type: LibMasterData, searchData: string) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [medias, setMedias] = useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [attributes, setAttributes] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [subjects, setSubjects] = useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [classifications, setClassifications] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [publishers, setPublishers] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [authors, setAuthors] = useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [bookCategories, setBookCategories] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [languages, setLanguages] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [vendors, setVendors] = useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [keywords, setKeywords] = useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [GetLibBookMedia, { data: mediasData }] = useLazyQuery<
    GetLibBookMediaByInstIdData,
    GetLibBookMediaByInstIdVars
  >(GetLibBookMediaByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.MEDIA_DESC,
      direction: Direction.ASC,
      mediaName: searchData,
      after: null,
    },
  });
  const [GetLibBookAttributes, { data: attributesData }] = useLazyQuery<
    GetLibBookAttributesByInstIdData,
    GetLibBookAttributesByInstIdVars
  >(GetLibBookAttributesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.BOOK_ATTRIBUTE,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
  });
  const [GetLibBookSubjects, { data: subjectsData }] = useLazyQuery<
    GetLibBookSubjectsByInstIdData,
    GetLibBookSubjectsByInstIdVars
  >(GetLibBookSubjectsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.SUBJECT_DESC,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
  });

  const [GetLibBookClassifications, { data: classificationsData }] =
    useLazyQuery<
      GetLibBookClassificationByInstIdData,
      GetLibBookClassificationByInstIdVars
    >(GetLibBookClassificationsByInstId, {
      variables: {
        token,
        inst_id: InstId!,
        first: LIBRARY_ROWS_PER_PAGE,
        sortBy: SortBy.CLASSIFICATION_DESC,
        direction: Direction.ASC,
        name: searchData,
        authorId: null,
        publisherId: null,
        subjectId: null,
        mediaId: null,
        keywordId: null,
        after: null,
      },
    });

  const [GetLibBookPublishers, { data: publishersData }] = useLazyQuery<
    GetLibBookPublishersByInstIdData,
    GetLibBookPublishersByInstIdVars
  >(GetLibBookPublishersByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.PUBLISHER_NAME,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
  });

  const [GetLibBookAuthors, { data: authorsData }] = useLazyQuery<
    GetLibBookAuthorsByInstIdData,
    GetLibBookAuthorsByInstIdVars
  >(GetLibBookAuthorsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.AUTHOR_NAME,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
  });

  const [GetLibBookCategories, { data: categoriesData }] = useLazyQuery<
    GetLibBookCategoriesByInstIdData,
    GetLibBookCategoriesByInstIdVars
  >(GetLibBookCategoriesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.CATEGORY_DESC,
      direction: Direction.ASC,
      categoryName: searchData,
      after: null,
    },
  });
  const [GetLibBookLanguages, { data: languagesData }] = useLazyQuery<
    GetLibBookLanguagesByInstIdData,
    GetLibBookLanguagesByInstIdVars
  >(GetLibBookLanguagesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.LANGUAGE,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
  });
  const [GetLibBookVendors, { data: vendersData }] = useLazyQuery<
    GetLibBookVendorsByInstIdData,
    GetLibBookVendorsByInstIdVars
  >(GetLibBookVendorsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.VENDOR_NAME,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
  });
  const [GetLibKeywords, { data: keywordsData }] = useLazyQuery<
    GetLibKeywordsByInstIdData,
    GetLibKeywordsByInstIdVars
  >(GetLibKeywordsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      sortBy: SortBy.KEYWORD,
      direction: Direction.ASC,
      name: searchData,
      after: null,
    },
  });

  useEffect(() => {
    if (token && InstId) {
      switch (type) {
        case LibMasterData.MEDIA:
          GetLibBookMedia().then(({ data }) => {
            if (data) {
              setMedias(
                data?.GetLibBookMediaByInstId.edges?.map(({ node }) => ({
                  label: node.media_desc,
                  value: node.id,
                }))
              );
            } else {
              setMedias(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.ATTRIBUTE:
          GetLibBookAttributes().then(({ data }) => {
            if (data) {
              setAttributes(
                data?.GetLibBookAttributesByInstId.edges?.map(({ node }) => ({
                  label: node.book_attribute,
                  value: node.id,
                }))
              );
            } else {
              setAttributes(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.SUBJECT:
          GetLibBookSubjects().then(({ data }) => {
            if (data) {
              setSubjects(
                data?.GetLibBookSubjectsByInstId.edges?.map(({ node }) => ({
                  label: node.subject_desc,
                  value: node.id,
                }))
              );
            } else {
              setSubjects(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.CLASSIFICATION:
          GetLibBookClassifications().then(({ data }) => {
            if (data) {
              setClassifications(
                data?.GetLibBookClassificationsByInstId?.edges?.map(
                  ({ node }) => ({
                    label: node.classification_desc,
                    value: node.id,
                  })
                )
              );
            } else {
              setClassifications(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.PUBLISHERS:
          GetLibBookPublishers().then(({ data }) => {
            if (data) {
              setPublishers(
                data?.GetLibBookPublishersByInstId.edges?.map(({ node }) => ({
                  label: node.publisher_name,
                  value: node.id,
                }))
              );
            } else {
              setPublishers(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.AUTHORS:
          GetLibBookAuthors().then(({ data }) => {
            if (data) {
              setAuthors(
                data?.GetLibBookAuthorsByInstId.edges?.map(({ node }) => ({
                  label: node.author_name,
                  value: node.id,
                }))
              );
            } else {
              setAuthors(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.CATEGORY:
          GetLibBookCategories().then(({ data }) => {
            if (data) {
              setBookCategories(
                data?.GetLibBookCategoriesByInstId.edges?.map(({ node }) => ({
                  label: node.category_desc,
                  value: node.id,
                }))
              );
            } else {
              setBookCategories(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.LANGUAGES:
          GetLibBookLanguages().then(({ data }) => {
            if (data) {
              setLanguages(
                data?.GetLibBookLanguagesByInstId.edges?.map(({ node }) => ({
                  label: node.language,
                  value: node.id,
                }))
              );
            } else {
              setLanguages(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.VENDORS:
          GetLibBookVendors().then(({ data }) => {
            if (data) {
              setVendors(
                data?.GetLibBookVendorsByInstId.edges?.map(({ node }) => ({
                  label: node.vendor_name,
                  value: node.id,
                }))
              );
            } else {
              setVendors(EMPTY_RESPONSETYPE);
            }
          });
          break;
        case LibMasterData.KEYWORDS:
          GetLibKeywords().then(({ data }) => {
            if (data) {
              setKeywords(
                data?.GetLibKeywordsByInstId.edges?.map(({ node }) => ({
                  label: node.keyword,
                  value: node.id,
                }))
              );
            } else {
              setKeywords(EMPTY_RESPONSETYPE);
            }
          });
          break;

        default:
          break;
      }
    }
  }, [
    type,
    mediasData,
    attributesData,
    subjectsData,
    publishersData,
    authorsData,
    categoriesData,
    languagesData,
    classificationsData,
    vendersData,
    keywordsData,
    GetLibBookMedia,
    GetLibBookAttributes,
    GetLibBookSubjects,
    GetLibBookClassifications,
    GetLibBookPublishers,
    GetLibBookAuthors,
    GetLibBookCategories,
    GetLibBookLanguages,
    GetLibBookVendors,
    GetLibKeywords,
    InstId,
    token,
  ]);
  return {
    medias,
    attributes,
    subjects,
    classifications,
    publishers,
    authors,
    bookCategories,
    languages,
    vendors,
    keywords,
    token,
  };
};

export default useLibMasterDataOptions;

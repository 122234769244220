import { gql } from "@apollo/client";

export const AddPayRollDesignation = gql`
  mutation AddPayRollDesignation(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollDesignationInput!
  ) {
    AddPayRollDesignation(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const DeletePayRollDesignationById = gql`
  mutation DeletePayRollDesignationById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollDesignationById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdatePayRollDesignation = gql`
  mutation UpdatePayRollDesignation(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollDesignationInput!
  ) {
    UpdatePayRollDesignation(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

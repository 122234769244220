import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { HRTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import UserRightsHome from "../../../UserRights/Home/Index";
import EditProfile from "../../../../images/EditProfile.svg";
import InputHoc from "../../../../components/common/Input/Index";

import Close from "../../../../images/Close.svg";

import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";

import HRImport from "../../Imports/MastersImport";
import {
  getModifiedScrollHeight,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { LabelNameProps } from "../../../../Types/Labels";
import {
  Direction,
  LicenseTypes,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import {
  PayRollNodeDepartmentData,
  CommonNodeVars,
} from "../../Types/masterDataTypes";
import { Form, Formik } from "formik";
import Delete from "../../../../images/Delete.svg";

import { payroleDeptSchema } from "../../../../utils/payrole";

import { msgType } from "../../../../utils/Form.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import {
  GetPayRollDeptsByInstId,
  PayRolldepartmentNode,
} from "../../queries/department/query";
import {
  AddPayRollDept,
  DeletePayRollDeptById,
  UpdatePayRollDept,
} from "../../queries/department/mutation";

import { PayRollDeptDetailsFormType } from "../../Types/formTypes";
import DeleteModal from "../../../../pages/DeleteModal";
import { GetPayRollMasterData } from "../../queries/general/list";
import { dept_desc } from "../../constants";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import useDepartmentData from "../../hooks/usePayRollMasters";
import { ComponentFor, PayRollMasters } from "../../enums/Enum.types";
import { PayRollDeptEdges } from "../../Types/paginationTypes";
import useMastersExport from "../../Imports/useMastersExport";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");
const { HRFormLabels } = require("../../json/form.json");

interface Props {
  type: ComponentFor;
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  LicenseType: LicenseTypes;
}

const Index = ({ pageType, setModalFlag, type, LicenseType }: Props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [deleteModal, setDeleteModal] = useState(false);

  const [formData, setFormData] = useState<PayRollDeptDetailsFormType>({
    dept_desc: "",
  });
  const [payrollDeptId, setPayrollDeptId] = useState(0);
  const [operation, setOperation] = useState(Operation.CREATE);

  const [searchData, setSearchData] = useState("");

  const [departments, setDepartments] = useState<PayRollDeptEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [importModal, setImportModal] = useState(false);

  const deptDescRef = document.getElementsByName(dept_desc)[0];

  const [GetDepartmentDetailsById] = useLazyQuery<
    PayRollNodeDepartmentData,
    CommonNodeVars
  >(PayRolldepartmentNode);
  const exportExcel = useMastersExport(PayRollMasters.DEPARTMENT);
  const [AddDept, { loading: creationLoading }] = useMutation(AddPayRollDept, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteDepartment, { loading: deleteLoading }] = useMutation(
    DeletePayRollDeptById,
    {
      onError: (e) => {
        setDeleteModal(!deleteModal);
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
      },
    }
  );
  const [UpdateDepartment, { loading: updationLoading }] = useMutation(
    UpdatePayRollDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    departmentDetails: { data, loading, fetchMore },
  } = useDepartmentData(PayRollMasters.DEPARTMENT, searchData, ROWS_PER_PAGE);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollDeptsByInstId.edges;
            const pageInfo = fetchMoreResult.GetPayRollDeptsByInstId.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollDeptsByInstId.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollDeptsByInstId: {
                edges: [...departments!, ...newEdges],
                pageInfo,
                totalCount: data?.GetPayRollDeptsByInstId.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
      if (pageType === PageFor.MODAL) {
        setModalFlag(false);
      }
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setFormData({
      dept_desc: "",
    });
    if (operation === Operation.UPDATE) {
      setOperation(Operation.CREATE);
    }
    deptDescRef.focus();
  };
  const handleSubmitForm = () => {
    if (operation === Operation.CREATE) {
      AddDept({
        variables: {
          token,
          inst_id: InstId,
          user_details,

          input: {
            dept_desc: removeMoreSpace(formData.dept_desc),
          },
        },
        refetchQueries: [
          {
            query: GetPayRollDeptsByInstId,
            variables: {
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.DEPT_DESC,
              direction: Direction.ASC,
              token,
              name: searchData,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSearchData(EMPTY_STRING);
          setMessage({
            flag: true,
            message: "PayRoll Department Created Sucessfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      UpdateDepartment({
        variables: {
          token,
          id: payrollDeptId,
          inst_id: InstId,
          user_details,
          input: {
            dept_desc: removeMoreSpace(formData.dept_desc),
          },
        },
        refetchQueries: [
          {
            query: PayRolldepartmentNode,
            variables: {
              token,
              id: payrollDeptId,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSearchData(EMPTY_STRING);
          setMessage({
            flag: true,
            message: "PayRoll Department Updated Sucessfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const handleDelete = (id: number) => {
    DeleteDepartment({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollDeptsByInstId,
          variables: {
            first: ROWS_PER_PAGE,
            after: null,
            sortBy: SortBy.DEPT_DESC,
            direction: Direction.ASC,
            token,
            name: searchData,
            inst_id: InstId!,
          },
          fetchPolicy: "network-only",
        },
        {
          query: GetPayRollMasterData,
          variables: {
            token,
            inst_id: InstId!,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setSearchData(EMPTY_STRING);
        setMessage({
          flag: true,
          message: "PayRoll Category Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  useEffect(() => {
    if (operation === Operation.UPDATE) {
      GetDepartmentDetailsById({
        variables: {
          token,
          id: payrollDeptId,
        },
      }).then(({ data }) => {
        if (data) {
          const { dept_desc } = data.node;
          setFormData({
            dept_desc,
          });
        }
      });
    }
  }, [payrollDeptId, operation, token, GetDepartmentDetailsById]);

  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetPayRollDeptsByInstId.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredDepartments = departments.filter(
          (department) => !department.node.isChecked
        );

        const updatedNewData = newData.map((newDepartment) => {
          const filteredDepartment = filteredDepartments.find(
            (department) => department.node.id === newDepartment.node.id
          );
          if (filteredDepartment) {
            return {
              ...newDepartment,
              node: {
                ...newDepartment.node,
                isChecked: true,
              },
            };
          }
          return newDepartment;
        });
        setDepartments(updatedNewData);
      } else {
        setDepartments(newData);
      }
      setEndCursor(data.GetPayRollDeptsByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);

  return (<>
    {pageType === PageFor.GENERAL &&
      type === ComponentFor.MASTERS &&
      (LicenseType === LicenseTypes.PAYROLL ? (
        <Home DashBoardRequired={false} />
      ) : (
        (<UserRightsHome DashBoardRequired={false} />)
        // Render something else for different LicenseType
      ))}
    <Title>
      {HRTitles.MasterData.Department.Titles.map(
        (title: HRTitleProps, index: React.Key) => {
          return <React.Fragment key={index}>{title.Title}</React.Fragment>;
        }
      )}
    </Title>
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={payroleDeptSchema}
      onSubmit={handleSubmitForm}
    >
      {(meta) => {
        return (
          <Form
            className={
              pageType === PageFor.GENERAL && type === ComponentFor.MASTERS
                ? "payroll-masterdata"
                : pageType === PageFor.MODAL
                ? "payroll-masterdata--modal"
                : "payroll-masterdata--organization"
            }
          >
            <div className="row g-0 payroll-masterdata__frame">
              <div className="col booktype-left h-100">
                <div className="payroll-masterdata__title">
                  <Title variant="subtitle1">
                    {HRTitles.MasterData.Department.Titles.map(
                      (title: HRTitleProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            {operation === Operation.UPDATE
                              ? title.Update
                              : title.Add}
                          </React.Fragment>
                        );
                      }
                    )}
                  </Title>
                </div>
                <div className="payroll-masterdata__block">
                  {HRFormLabels.MasterData.Department.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <InputHoc
                            LabelName={label.LabelName}
                            values={formData[label.inputName]}
                            name={label.inputName}
                            autoFocus
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleValueChange(e);
                              meta.handleChange(e);
                            }}
                            required={label.required ? true : false}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="col booktype-right h-100">
                <div className="row g-0 payroll-masterdata__title">
                  <div className="col-3"></div>
                  <div className="col">
                    <Title variant="subtitle1">
                      {HRTitles.MasterData.Department.Titles.map(
                        (title: HRTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.List}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="col-3">
                    <Input
                      type="text"
                      id="search"
                      placeholder="Search..."
                      onChange={(e) => setSearchData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="payroll-masterdata__block">
                  {!departments?.length && !loading ? (
                    <b className="nodata">
                      Sorry, No PayRoll Departments Found
                    </b>
                  ) : (
                    <TableContainer
                      className="payroll-masterdata__block--table"
                      onScroll={handleScroll}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {HR_Table.MasterData.Table_Headers.Department.map(
                              (th: TableHeaderProps, index: React.Key) => {
                                return (
                                  <TableCell
                                    key={index}
                                    className={th.className}
                                  >
                                    {th.labelName}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {departments.map((edge, index) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell
                                  className="payroll-masterdata__block--table--slno"
                                  id="td-center"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell>{edge.node.dept_desc}</TableCell>
                                <TableCell
                                  className="payroll-masterdata__block--table--actions"
                                  id="td-center"
                                >
                                  <>
                                    <img
                                      src={EditProfile}
                                      alt="/"
                                      onClick={() => {
                                        setPayrollDeptId(edge.node.id);
                                        setOperation(Operation.UPDATE);
                                        deptDescRef.focus();
                                      }}
                                    />
                                    &nbsp;
                                    <img
                                      src={Delete}
                                      alt="/"
                                      onClick={() => {
                                        setPayrollDeptId(edge.node.id);
                                        setDeleteModal(!deleteModal);
                                      }}
                                    />
                                  </>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              </div>
            </div>
            <>
              <Button mode="save" type="submit" />

              <Button mode="clear" type="button" onClick={handleClear} />

              {pageType === PageFor.GENERAL ? (
                <>
                  <Button
                    mode="excel"
                    type="button"
                    onClick={() => setImportModal(!importModal)}
                  >
                    Import Departments
                  </Button>
                  <Button type="button" mode="export" onClick={exportExcel} />

                  <Button
                    mode="back"
                    onClick={() => navigate(-1)}
                    type="button"
                  />
                </>
              ) : (
                <Button
                  mode="cancel"
                  onClick={() => setModalFlag(false)}
                  type="button"
                />
              )}
            </>
          </Form>
        );
      }}
    </Formik>
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={importModal}
      style={StudentModalStyles}
      ariaHideApp={false}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <HRImport MasterType={PayRollMasters.DEPARTMENT} />
        </div>
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </div>
    </Modal>
    <LoadingModal
      flag={creationLoading || loading || deleteLoading || updationLoading}
    />
    <MessageModal
      modalFlag={message.flag}
      value={message.message}
      handleClose={handleClose}
      operation={message.operation}
    />
    <DeleteModal
      modalFlag={deleteModal}
      setModalFlag={setDeleteModal}
      handleDelete={handleDelete}
      id={payrollDeptId}
    />
  </>);
};

export default Index;

import React, { useEffect, useState } from "react";
import { Button } from "../../../../../stories/Button/Button";
import Compare from "../../../../../images/Compare.svg";
import { TextField } from "@mui/material";
import { toIsoDate, toStandardDate } from "../../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import Eye from "../../../../../images/EyeWhite.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcdFeedbackFormsData,
  GetAcdFeedbackFormsVars,
} from "../../../types/subject/Subject";
import { GetAcdFeedbackForms } from "../../../queries/general";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useToken from "../../../../../customhooks/useToken";
import { EMPTY_STRING, TODAY_DATE } from "../../../../../utils/constants";
import { FinalizeAcdFeedbackForm } from "../../../queries/test/mutation";
import LoadingModal from "../../../../../pages/LoadingModal";
import MessageModal from "../../../../../pages/MessageModal";
import { Operation } from "../../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import { msgType } from "../../../../../utils/Form.types";
export const enum ReportType {
  Formwise = "Formwise",
  Subwise = "Subwise",
}

const TeacherWise = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetAcdFeedbackFormDropDown, { data: FormDropDown }] = useLazyQuery<
    GetAcdFeedbackFormsData,
    GetAcdFeedbackFormsVars
  >(GetAcdFeedbackForms, {
    variables: {
      acd_yr_id: activeAcdId ? activeAcdId : 0,
      inst_id: InstId!,
      token,
      allotted_level: EMPTY_STRING,
      allotted_id: 0,
      today_date: toIsoDate(TODAY_DATE),
      query_type: "PAST_FORMS",
      pr_emp_id: 0,
    },
  });
  const [FinalizeForm, { loading: finalizeLoading }] = useMutation(
    FinalizeAcdFeedbackForm,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleSubmit = (id: number) => {
    FinalizeForm({
      variables: {
        token,
        inst_id: InstId!,
        acd_yr_id: activeAcdId ? activeAcdId : 0,
        user_details,
        form_name_id: id,
      },
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      // navigate(`/${InstId}/academics/${testId}/examplanner/view`);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token) {
      GetAcdFeedbackFormDropDown();
    }
  }, [token, GetAcdFeedbackFormDropDown]);
  return (
    <>
      <div className={"fb-teacher-wise-report__datablock"}>
        <div className="fb-form-report__datablock--grids">
          {FormDropDown &&
            FormDropDown.GetAcdFeedbackForms.map((data, index) => {
              return (
                <div
                  className={
                    data.is_academic
                      ? "fb-form-report__datablock--grid"
                      : "fb-form-report__datablock--event"
                  }
                >
                  <b>{data.form_name}</b>
                  <span
                    className={
                      data.is_academic
                        ? "fb-form-report__datablock--grid--acd"
                        : "fb-form-report__datablock--event--acd"
                    }
                  >
                    {data.is_academic ? "Academic" : "Non-Academics"}
                  </span>
                  <div className="fb-form-report__datablock--grid--flex">
                    <TextField
                      className="fb-form-report__datablock--grid--textfield"
                      disabled
                      label="Start Date"
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      value={toStandardDate(data.form_st_date)}
                    />
                    <img src={Compare} alt="" />
                    <TextField
                      className="fb-form-report__datablock--grid--textfield"
                      disabled
                      label="End Date"
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      value={toStandardDate(data.form_end_date)}
                    />
                  </div>

                  <div className="fb-form-report__datablock--grid--flex">
                    <div className="fb-form-report__datablock--grid--block">
                      {/* <span>Total Students</span> */}
                      {/* <b className="font-blue">{data.total}</b> */}
                    </div>
                    <div className="fb-form-report__datablock--grid--block">
                      {/* <span>Filled Form</span> */}
                      {/* <b className="font-green">{data.filled}</b> */}
                    </div>
                  </div>

                  {data.is_report_generated ? (
                    <button
                      className="fb-form-report__datablock--grid--button"
                      onClick={() => {
                        data.is_academic
                          ? navigate(
                              `/${InstId}/academics/reports/teacherwise/feedback/${data.id}/List`
                            )
                          : navigate(
                              `/${InstId}/academics/reports/teacherwise/feedback/${data.id}/view`
                            );
                      }}
                    >
                      View Report <img src={Eye} alt="" />
                    </button>
                  ) : (
                    <button
                      className="fb-form-report__datablock--grid--button"
                      onClick={() => {
                        handleSubmit(data.id);
                      }}
                    >
                      Finalize <img src={Eye} alt="" />
                    </button>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <Button mode="back" onClick={() => navigate(-1)} />
      <LoadingModal flag={finalizeLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default TeacherWise;

import { gql } from "@apollo/client";

export const DeleteInstDeptById = gql`
  mutation DeleteInstDeptById(
    $token: String!
    $dept_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstDeptById(
      token: $token
      id: $dept_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstBranchById = gql`
  mutation DeleteInstBranchById(
    $token: String!
    $branch_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstBranchById(
      token: $token
      id: $branch_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstClassById = gql`
  mutation DeleteInstClassById(
    $token: String!
    $class_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstClassById(
      token: $token
      id: $class_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteInstSemesterById = gql`
  mutation DeleteInstSemesterById(
    $token: String!
    $sem_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstSemesterById(
      token: $token
      id: $sem_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstSectionById = gql`
  mutation DeleteInstSectionById(
    $token: String!
    $sec_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstSectionById(
      token: $token
      id: $sec_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstStdCategoryById = gql`
  mutation DeleteInstStdCategoryById(
    $token: String!
    $cat_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstStdCategoryById(
      token: $token
      id: $cat_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstById = gql`
  mutation DeleteInstById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstById(token: $token, id: $id, user_details: $user_details)
  }
`;

import React from "react";
import { useState } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ParentsList from "../../Modules/Master/parent/List";
import { Operation, PageFor, ParentQueryType } from "../../utils/Enum.types";

import ParentRegistartion from "../../Modules/Master/parent/Registration";
import ParentPreview from "../../Modules/Master/parent/parentPreview/parentBasicData";
import ParentAssociation from "../../Modules/Master/parent/ParentStudentAssociation";
import ParentAssociationPreview from "../../Modules/Master/parent//parentPreview/parentAssocPreview";
import ParentImport from "../../Modules/Master/parent/ImportParent";
const ParentsRoutes = () => {
  const [modalFlag, setModalFlag] = useState(false);

  return (
    <Routes>
      <Route
        path="/1/:parentId/addparent"
        element={
          <ParentRegistartion
            operation={Operation.CREATE}
            pageType={PageFor.GENERAL}
            setStudentModal={setModalFlag}
            studentModal={modalFlag}
            step={1}
          />
        }
      />
      <Route
        path="/2/:parentId/parentAssosiation"
        element={<ParentAssociation type={Operation.CREATE} step={2} />}
      />
      <Route
        path="/:parentId/updatebasicdata"
        element={
          <ParentRegistartion
            operation={Operation.UPDATE}
            pageType={PageFor.GENERAL}
            setStudentModal={setModalFlag}
            studentModal={modalFlag}
            step={1}
          />
        }
      />
      <Route
        path="/:parentId/updateparentAssosiation"
        element={<ParentAssociation type={Operation.UPDATE} step={2} />}
      />
      <Route path="/:parentId/ParentData" element={<ParentPreview />} />
      <Route
        path="/:parentId/parentAssosiation"
        element={<ParentAssociationPreview />}
      />
      <Route path="/import" element={<ParentImport />} />
      <Route
        path="/"
        element={
          <ParentsList
            queryType={ParentQueryType.PARENTS_BY_INST_ID}
            pageType={PageFor.GENERAL}
            setParentsModal={setModalFlag}
          />
        }
      />
    </Routes>
  );
};

export default ParentsRoutes;

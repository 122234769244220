import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  SortBy,
} from "../../../../utils/Enum.types";
import { TableHeaderProps } from "../../../../Types/Tables";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { responseType } from "../../../../utils/Form.types";
import Input from "../../../../stories/Input/Input";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import { pageInfo } from "../../../../Types/pagination";
import { GetStudentBranchChange } from "../../../../queries/students/list/newApi";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import {
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../../../../Types/Student";
import { useLazyQuery } from "@apollo/client";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  I4_CELL,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import StudentTotalCount from "../Components/StudentTotalCount";
import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";

export interface GetStudentsChangeBranchVars {
  name: string;
  newDeptID: number | null;
  newBranchID: number | null;
  newClassID: number | null;
  newSemesterID: number | null;
  newSectionID: number | null;
  newCategoryID: number | null;
  first: number | null;
  after: string | null;
  sortBy: SortBy;
  direction: Direction;
  token: string;
  inst_id: string | number;
  fin_yr_id: number;
}
export interface MstStudentData {
  first_name: string;
  middle_name: string;
  last_name: string;
  std_reg_no: string;
  std_adm_no: string;
}
export interface GetStudentsChangeBranchNode {
  id: number;
  comments: string;
  created_at: string;
  mst_student: MstStudentData;
  old_dept_details: DepartmentList;
  old_branch_details: BranchesList;
  old_class_details: ClassList;
  old_semester_details: SemesterList;
  old_section_details: SectionList;
  old_category_details: CategoryList;
  new_dept_details: DepartmentList;
  new_branch_details: BranchesList;
  new_class_details: ClassList;
  new_semester_details: SemesterList;
  new_section_details: SectionList;
  new_category_details: CategoryList;
}
export interface GetStudentsChangeBranchEdges {
  node: GetStudentsChangeBranchNode;
}

export interface GetStudentsChangeBranchList {
  totalCount: number;
  pageInfo: pageInfo;
  edges: GetStudentsChangeBranchEdges[];
}
export interface GetStudentsChangeBranchData {
  GetStudentBranchChange: GetStudentsChangeBranchList;
}
interface Props {
  setTableViewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewChangedStudent = ({ setTableViewModal }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel,
  } = useInstLabels();

  // eslint-disable-next-line
  const { dispatch, state } = useContext(AppContext);
  const { Masters_Table } = useMasterTableJson();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const [searchData, setSearchData] = useState("");
  const { serverDate } = useServerDateandTime();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<GetStudentsChangeBranchEdges[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);

  const PreviousCategory = `Previous ${categoryLabel}`;
  const ChangedCategory = `Changed ${categoryLabel}`;

  //Queries
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;

  const [GetStudents, { data, loading, fetchMore }] = useLazyQuery<
    GetStudentsChangeBranchData,
    GetStudentsChangeBranchVars
  >(GetStudentBranchChange, {
    variables: {
      newDeptID: departmentSelected ? departmentSelected.value : null,
      newBranchID: branchSelected ? branchSelected.value : null,
      newClassID: classSelected ? classSelected.value : null,
      newSemesterID: semesterSelected ? semesterSelected.value : null,
      newSectionID: sectionSelected ? sectionSelected.value : null,
      newCategoryID: categorySelected ? categorySelected.value : null,
      name: searchData,
      first: null,
      after: null,
      sortBy: SortBy.CREATED_AT,
      direction: Direction.DESC,
      token,
      inst_id: InstId!,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
    },
  });

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();
  const ChangeOfBranchHeaders = [
    "SlNo",
    "Admission No",
    "RegNo",
    "Name",
    USE_DEPARTMENT_KEY ? `Changed ${departmentLabel}` : null,
    USE_BRANCH_KEY ? `Changed ${branchLabel}` : null,
    USE_CLASS_KEY ? `Changed ${classLabel}` : null,
    USE_SEMESTER_KEY ? `Changed ${semesterLabel}` : null,
    USE_SECTION_KEY ? `Changed ${sectionLabel}` : null,
    USE_CATEGORY_KEY ? `Changed ${categoryLabel}` : null,
  ];
  const studentCol = [
    { title: "Sl", field: "Slno" },
    { title: "Admission No", field: "AdmNo" },
    { title: "RegNo", field: "RegNo" },
    { title: "Name", field: "Name" },

    USE_DEPARTMENT_KEY
      ? { title: `Changed ${departmentLabel}`, field: "Dept" }
      : null,
    USE_BRANCH_KEY
      ? { title: `Changed ${branchLabel}`, field: "Branch" }
      : null,
    USE_CLASS_KEY ? { title: `Changed ${classLabel}`, field: "Class" } : null,
    USE_SEMESTER_KEY
      ? { title: `Changed ${semesterLabel}`, field: "Sem" }
      : null,
    USE_SECTION_KEY
      ? { title: `changed ${sectionLabel}`, field: "Section" }
      : null,
    USE_CATEGORY_KEY
      ? { title: `Changed ${categoryLabel}`, field: "Category" }
      : null,
  ];
  const stdData = data?.GetStudentBranchChange.edges.map((data, index) => ({
    slNo: index + 1,
    AdmNo: data.node.mst_student.std_adm_no,
    RegNo: data.node.mst_student.std_reg_no,
    Name:
      data.node.mst_student.first_name +
      " " +
      data.node.mst_student.middle_name +
      " " +
      data.node.mst_student.last_name,
    Dept: USE_DEPARTMENT_KEY ? data.node.new_dept_details.dept_desc : null,
    Branch: USE_BRANCH_KEY ? data.node.new_branch_details.branch_desc : null,
    Class: USE_CLASS_KEY ? data.node.new_class_details.class_desc : null,
    Sem: USE_SEMESTER_KEY ? data.node.new_semester_details.sem_desc : null,
    Section: USE_SECTION_KEY
      ? data.node.new_section_details.section_desc
      : null,
    Category: USE_CATEGORY_KEY ? data.node.new_category_details.cat_desc : null,
  }));
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text("Change of Branch/Class/Dept", 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: stdData,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Chnage of Branch");

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.slNo,
          item.AdmNo,
          item.RegNo,
          item.Name,
          item.Dept,
          item.Branch,
          item.Class,
          item.Sem,
          item.Section,
          item.Category,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 25;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "J1:J3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:J2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:J3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = `Change of ${departmentLabel} / ${branchLabel} / ${classLabel} / ${semesterLabel} / ${sectionLabel} / ${categoryLabel} `;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:H4");
                  const mergedDate: Cell = worksheet.getCell(I4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("I4:J4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < ChangeOfBranchHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = ChangeOfBranchHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Change of Branch");
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };
  const TableHeader = () => {
    return (
      <>
        <TableHead>
          <TableRow>
            {Masters_Table.StudentsBranchChange.Table_Headers.filter(
              (data: TableHeaderProps) => {
                if (
                  (data.labelName === ChangedCategory ||
                    data.labelName === PreviousCategory) &&
                  USE_CATEGORY_KEY === false
                ) {
                  return false;
                }
                return true;
              }
            ).map((label: TableHeaderProps, index: React.Key) => {
              return (
                <TableCell key={index} className={label.className}>
                  {label.labelName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      </>
    );
  };

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { categoryDropDown } = useInstMasterDataByInstId();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudentBranchChange.edges;
            const pageInfo = fetchMoreResult.GetStudentBranchChange.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetStudentBranchChange.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudentBranchChange: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetStudentBranchChange.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const handleClear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
  };
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    if (pdfFlag && rowsPerPage === null && !loading) downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading]);
  useEffect(() => {
    if (data && !loading && excelFlag === false && pdfFlag === false) {
      const newData = data?.GetStudentBranchChange.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data?.GetStudentBranchChange.pageInfo.endCursor!);
    } // eslint-disable-next-line
  }, [data, loading, excelFlag, pdfFlag]);
  useEffect(() => {
    if (token) {
      GetStudents();
    }
  }, [token, GetStudents, state.ActiveFinYr]);

  return (
    <>
      <Title>
        Change of
        {USE_DEPARTMENT_KEY && <> {departmentLabel} / </>}
        {USE_BRANCH_KEY && <> {branchLabel} / </>}
        {USE_CLASS_KEY && <> {classLabel} / </>}
        {USE_SEMESTER_KEY && <> {semesterLabel} /</>}
        {USE_SECTION_KEY && <> {sectionLabel} /</>}
        {USE_CATEGORY_KEY && <> {categoryLabel}</>}
      </Title>
      <form className="row g-0 studentlist__options">
        <div className="col-1 studentlist__select-flex studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchData(e.target.value)
            }
            value={searchData}
            onKeyDown={handleFormEvent}
          />
        </div>
        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                } else {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={semesterDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue as responseType);
                } else {
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={semesterLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SECTION_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, sectionSelected)
              }
              className={labelClasses.inputRoot}
              options={sectionDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue as responseType);
                } else {
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CATEGORY_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={categoryDropDown}
              openOnFocus
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, categorySelected)
              }
              value={categorySelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setCategorySelected(newValue as responseType);
                } else {
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={categoryLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        <div className="col"></div>
        <div className="col-1">
          <Button
            mode="clear"
            onClick={(e: React.FormEvent) => {
              e.preventDefault();
              handleClear();
            }}
            className="change-of-dept__view--button"
          />
        </div>
      </form>
      <div className="change-of-dept__view">
        {!students?.length ? (
          <b className="nodata">Sorry, No Students Found</b>
        ) : (
          <TableContainer
            className="change-of-dept__view--table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHeader />
              <TableBody>
                {students?.map((response, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="change-of-dept__view--table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="change-of-dept__view--table--admno"
                      >
                        {toStandardDate(response.node.created_at)}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="change-of-dept__view--table--admno"
                      >
                        {response.node.mst_student.std_adm_no}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="change-of-dept__view--table--regno"
                      >
                        {response.node.mst_student.std_reg_no ?? EMPTY_STRING}
                      </TableCell>
                      <TableCell className="change-of-dept__view--table--name">
                        {response.node.mst_student.first_name +
                          " " +
                          response.node.mst_student.middle_name +
                          " " +
                          response.node.mst_student.last_name}
                      </TableCell>
                      <TableCell className="change-of-dept__view--table--desc">
                        {response.node.old_branch_details.branch_desc}
                      </TableCell>
                      <TableCell className="change-of-dept__view--table--desc">
                        {response.node.old_class_details.class_desc}
                      </TableCell>
                      <TableCell className="change-of-dept__view--table--desc">
                        {response.node.old_category_details.cat_desc}
                      </TableCell>
                      <TableCell className="change-of-dept__view--table--desc">
                        {response.node.new_branch_details.branch_desc}
                      </TableCell>
                      <TableCell className="change-of-dept__view--table--desc">
                        {response.node.new_class_details.class_desc}
                      </TableCell>
                      {USE_CATEGORY_KEY ? (
                        <TableCell className="change-of-dept__view--table--desc">
                          {response.node.new_category_details.cat_desc}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setPdfFlag(true);
            }}
            disabled={classSelected && classSelected.value ? false : true}
            mode="pdf"
          />
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setExcelFlag(true);
            }}
            disabled={classSelected && classSelected.value ? false : true}
            mode="export"
          />
          <Button
            mode="cancel"
            onClick={() => {
              setTableViewModal(false);
              dispatch({
                type: payloadTypes.SET_CHANGE_OF_BRANCH_STUDENT_ID,
                payload: {
                  changeOfBranchStudentId: 0,
                },
              });
            }}
          />
        </div>
        <div className="col-2 change-of-dept__total">
          <StudentTotalCount
            totalCount={data?.GetStudentBranchChange.totalCount!}
          />
        </div>
      </div>
    </>
  );
};

export default ViewChangedStudent;

import { gql } from "@apollo/client";

export const GetInstDashBoardCounters = gql`
  query GetInstDashBoardCounters($token: String!, $inst_id: ID!) {
    GetInstDashBoardCounters(token: $token, inst_id: $inst_id) {
      total_alumini_std_count
      total_reserved_std_count
      total_eligible_std_count
      total_teaching_staff_count
    }
  }
`;
export const GetAdmissionDashBoardDetails = gql`
  query GetAdmissionDashBoardDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
  ) {
    GetAdmissionDashBoardDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
    ) {
      total_cur_std_count
      boys_cur_std_count
      girls_cur_std_count
      total_nxt_std_count
      boys_nxt_std_count
      girls_nxt_std_count
      total_new_admission_count
      total_cancelled_count
      total_re_admission_count
      total_ne_count
      total_detained_count
      total_soa_count
      total_seats_count
      total_vacant_count
    }
  }
`;

export const GetOwnersAdmissionDashBoardDetails = gql`
  query GetOwnersAdmissionDashBoardDetails($token: String!, $cust_id: ID!) {
    GetOwnersAdmissionDashBoardDetails(token: $token, cust_id: $cust_id) {
      cust_adm_total_stats {
        total_cur_std_count
        boys_cur_std_count
        girls_cur_std_count
        total_nxt_std_count
        boys_nxt_std_count
        girls_nxt_std_count
        total_new_admission_count
        total_cancelled_count
        total_re_admission_count
        total_ne_count
        total_detained_count
        total_soa_count
        inst_id
        inst_name
      }
      inst_adm_total_stats {
        total_cur_std_count
        total_cur_std_count
        boys_cur_std_count
        girls_cur_std_count
        total_nxt_std_count
        boys_nxt_std_count
        girls_nxt_std_count
        total_new_admission_count
        total_cancelled_count
        total_re_admission_count
        total_ne_count
        total_detained_count
        total_soa_count
        inst_id
        inst_name
      }
    }
  }
`;

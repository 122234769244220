import { gql } from "@apollo/client";

export const AddPayRollSalaryLdgr = gql`
  mutation AddPayRollSalaryLdgr(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollSalaryLdgrInput!
  ) {
    AddPayRollSalaryLdgr(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const AddOrDeletePayRollPredefinedSalaryLdgr = gql`
  mutation AddOrDeletePayRollPredefinedSalaryLdgr(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: AddOrDeleteSalLdgrs!
  ) {
    AddOrDeletePayRollPredefinedSalaryLdgr(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const AddPayRollSalaryMasterAndDetails = gql`
  mutation AddPayRollSalaryMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $input: SalaryMasterAndDetails
  ) {
    AddPayRollSalaryMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      input: $input
    )
  }
`;

export const UpdatePayRollSalaryLdgr = gql`
  mutation UpdatePayRollSalaryLdgr(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollSalaryLdgrInput!
  ) {
    UpdatePayRollSalaryLdgr(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const ReOrderPayRollSalaryLdgr = gql`
  mutation ReOrderPayRollSalaryLdgr(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [ReOrderPayRollSalaryLdgrInput!]!
  ) {
    ReOrderPayRollSalaryLdgr(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdatePayRollSalaryStructMaster = gql`
  mutation UpdatePayRollSalaryStructMaster(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_str_master_id: ID!
    $input: UpdateSalaryMasterAndDetails!
  ) {
    UpdatePayRollSalaryStructMaster(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_str_master_id: $pr_sal_str_master_id
      input: $input
    )
  }
`;
export const DeletePayRollSalaryLdgrByIds = gql`
  mutation DeletePayRollSalaryLdgrByIds(
    $token: String!
    $ids: [ID!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollSalaryLdgrByIds(
      token: $token
      ids: $ids
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const CreatePayRollEmpSalaryStructMasterAndDetails = gql`
  mutation CreatePayRollEmpSalaryStructMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
    $input: EmpSalaryDetails!
  ) {
    CreatePayRollEmpSalaryStructMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
      input: $input
    )
  }
`;

export const DeletePayRollSalaryStructMasterById = gql`
  mutation DeletePayRollSalaryStructMasterById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollSalaryStructMasterById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

//Salary Process
// export const UpdatePrSalWorkStartedAndMonthlyHolidays = gql`
//   mutation UpdatePrSalWorkStartedAndMonthlyHolidays(
//     $token: String!
//     $pr_sal_mk_month_id: ID!
//     $input: CreatePayRollMonthlyHolidaysInput!
//   ) {
//     UpdatePrSalWorkStartedAndMonthlyHolidays(
//       token: $token
//       pr_sal_mk_month_id: $pr_sal_mk_month_id
//       input: $input
//     )
//   }
// `;

export const AddPrEmpForSalMkMonth = gql`
  mutation AddPrEmpForSalMkMonth(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $pr_emp_ids: [ID!]!
    $month_start_date: Time!
  ) {
    AddPrEmpForSalMkMonth(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      pr_emp_ids: $pr_emp_ids
      month_start_date: $month_start_date
    )
  }
`;

export const UpdatePrEmpForSalMkMonth = gql`
  mutation UpdatePrEmpForSalMkMonth(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $pr_emp_ids: [ID!]!
    $UpdatePrEmpForSalMkMonth: Time!
  ) {
    UpdatePrEmpForSalMkMonth(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      pr_emp_ids: $pr_emp_ids
      UpdatePrEmpForSalMkMonth: $UpdatePrEmpForSalMkMonth
    )
  }
`;

export const DeletePrSalWorkEmpFromMkMonth = gql`
  mutation DeletePrSalWorkEmpFromMkMonth(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $pr_emp_ids: [ID!]!
  ) {
    DeletePrSalWorkEmpFromMkMonth(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      pr_emp_ids: $pr_emp_ids
    )
  }
`;

export const UpdateSalaryMkEmpAtt = gql`
  mutation UpdateSalaryMkEmpAtt(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $cal_month: Time!
    $input: UpdateSalaryMkEmpAttInput!
  ) {
    UpdateSalaryMkEmpAtt(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      cal_month: $cal_month
      input: $input
    )
  }
`;

export const UpdatePrSalWorkAttComplete = gql`
  mutation UpdatePrSalWorkAttComplete(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $month_start_date: Time!
  ) {
    UpdatePrSalWorkAttComplete(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      month_start_date: $month_start_date
    )
  }
`;

export const UpdatePayRollSalaryMkEmpMaster = gql`
  mutation UpdatePayRollSalaryMkEmpMaster(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [UpdateSalaryMkEmpMaster!]!
  ) {
    UpdatePayRollSalaryMkEmpMaster(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdatePrSalWorkSalStructComplete = gql`
  mutation UpdatePrSalWorkSalStructComplete(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $month_start_date: Time!
  ) {
    UpdatePrSalWorkSalStructComplete(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      month_start_date: $month_start_date
    )
  }
`;

export const UpdatePrSalWorkSalMade = gql`
  mutation UpdatePrSalWorkSalMade(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $month_start_date: Time!
  ) {
    UpdatePrSalWorkSalMade(
      token: $token
      inst_id: $inst_id
      user_details: $user_details

      pr_sal_mk_month_id: $pr_sal_mk_month_id
      month_start_date: $month_start_date
    )
  }
`;

export const UpdatePrSalWorkSalCompleted = gql`
  mutation UpdatePrSalWorkSalCompleted(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $month_start_date: Time!
  ) {
    UpdatePrSalWorkSalCompleted(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      month_start_date: $month_start_date
    )
  }
`;

export const StartPrSalaryProcessWork = gql`
  mutation StartPrSalaryProcessWork(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_sal_mk_month_id: ID!
    $month_start_date: Time!
  ) {
    StartPrSalaryProcessWork(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      month_start_date: $month_start_date
    )
  }
`;

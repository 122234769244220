import { forwardRef, useContext, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../../stories/Button/Button";
import useStudentDatabyId, {
  StudentDetails,
} from "../../../../../customhooks/useStudentDatabyId";

import StandardReceiptTemplate from "./Index";
import usePrintConfig from "./hooks/usePrintConfiguration";
import { AppContext } from "../../../../../context/context";
import { BankDetails } from "../../../../Accounts/StudentDetails/Challan/CreateChallan";
import EduateLogo from "../../../../../images/EduateLogo.svg";

interface Data {
  particular: string;
  amount: number;
}
export interface PrintReceiptProps {
  tableData: Data[];
  v_date: string;
  v_no: string;
  transaction_no: string;
  studentDetails?: StudentDetails | null;
  printBoth?: boolean;
  bankDetailsForPrint?: BankDetails;
}
const ComponentToPrint = forwardRef<HTMLDivElement, PrintReceiptProps>(
  ({ tableData, v_date, v_no, transaction_no, studentDetails }, ref) => {
    return (
      <>
        <style type="text/css" media="print" scoped>
          {`
            
            @page {
          width:100%;
          height:100%;
            }
            body {
              padding: 10px 20px 0px 100px;
            }
  

          `}
        </style>
        <div className="h-100" ref={ref}>
          <StandardReceiptTemplate
            tableData={tableData}
            v_date={v_date}
            v_no={v_no}
            studentDetails={studentDetails}
            transaction_no={transaction_no}
          />
        </div>
      </>
    );
  }
);
const FeeReceiptPrint = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
}: PrintReceiptProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { studentFormData } = useStudentDatabyId();
  const { state } = useContext(AppContext);
  // State to store the number of copies to print
  const { USE_NO_COPIES, USE_INST_STD_LABEL } = usePrintConfig();

  return (
    <div className="print-receipts">
      <div ref={componentRef} className="print-receipts__ss">
        {state.claims && state.claims.STUDENT ? (
          <div
            className={
              USE_INST_STD_LABEL
                ? "print-receipts__dd--with-label"
                : "print-receipts__dd"
            }
          >
            <ComponentToPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              studentDetails={studentDetails}
              transaction_no={transaction_no}
            />
            {USE_INST_STD_LABEL && (
              <>
                <b className="print-receipts__standard--footer--copy">
                  Student Copy
                </b>
                <div className="print-receipts__standard--footer--simplify">
                  <img src={EduateLogo} alt="" />{" "}
                  <span>Simplified By My-Eduate</span>
                </div>
              </>
            )}
          </div>
        ) : (
          [...Array(USE_NO_COPIES)].map((_, index) => (
            <div
              className={
                USE_INST_STD_LABEL
                  ? "print-receipts__dd--with-label"
                  : "print-receipts__dd"
              }
              key={index}
            >
              <ComponentToPrint
                tableData={tableData}
                v_date={v_date}
                v_no={v_no}
                transaction_no={transaction_no}
                studentDetails={studentDetails}
              />
              {USE_INST_STD_LABEL && (
                <>
                  {index === 0 ? (
                    <b className="print-receipts__standard--footer--copy">
                      Institution Copy
                    </b>
                  ) : (
                    <b className="print-receipts__standard--footer--copy">
                      Student Copy
                    </b>
                  )}
                  <div className="print-receipts__standard--footer--simplify">
                    <img src={EduateLogo} alt="" />{" "}
                    <span>Simplified By My-Eduate</span>
                  </div>
                </>
              )}
            </div>
          ))
        )}
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
        documentTitle={studentFormData.std_name}
      />
    </div>
  );
};

export default FeeReceiptPrint;

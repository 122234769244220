import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import Compare from "../../../../../images/Compare.svg";

import InputNumber from "../../../../../components/common/Input/InputNumber";
import { Button } from "../../../../../stories/Button/Button";
import Modal from "react-modal";
import Export from "./Export";
import {
  ModalCustomStyles,
  StudentModalStyles,
  TransactionStatusStyles,
} from "../../../../../styles/ModalStyles";
import { optionsType, responseType } from "../../../../../utils/Form.types";
import { StatesContext } from "../GlobalStates/StatesProvider";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstitutionDetailsDataByInstId from "../../../../../customhooks/useInstitutionDetailsDataByInstId";
import {
  ExcelReportPageType,
  ExportModuleType,
  InstitutionDetails,
  Operation,
  PredefinedDataTypes,
  TransactionStatusType,
} from "../../../../../utils/Enum.types";
import { payloadType } from "../GlobalStates/types";
import PredefinedAutocomplete from "../../../../../components/common/PredefinedAutocomplete/PredefinedAutocomplete";
import useExportMutation, {
  ExcelAcctQueryType,
  ExcelAdmQueryType,
} from "../useExportMutation";
import useAcdDropDownData from "../../../../Academics/hooks/useAcdDropDownData";
import { Label } from "../../../../../stories/Label/Label";
import { EMPTY_STRING, emptyMessageType } from "../../../../../utils/constants";
import AgentOptionsAutocomplete from "../../../../Accounts/Agents/Components/AgentOptionsAutocomplete";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../../styles/AutocompleteListStyles";
import useDropdownData from "../../../../../customhooks/useDropdownData";
import useFinancialYears from "../../../../Academics/hooks/useFinancialYears";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../../styles/AutocompleteStyles";
import {
  ListFinancialYears,
  nodevars,
  singleNodeVars,
} from "../../../../../Types/Accounting";
import { ExportProps } from "../Index";
import NoData from "../../../../../images/No_data_to_display.svg";
import { useParams } from "react-router-dom";
import { AccLedgerByNodeId } from "../../../../Accounts/queries/GroupLedgers/query/ById";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import { toInputStandardDate } from "../../../../../utils/UtilFunctions";
import { AppContext } from "../../../../../context/context";
import LoadingModal from "../../../../../pages/LoadingModal";
import MessageModal from "../../../../../pages/MessageModal";
import Success from "../../../../Accounts/Status/Success";
import Failure from "../../../../Accounts/Status/Failure";
enum ChosenType {
  ALL = "ALL",
  PARTICULAR = "PARTICULAR",
}

const GenerateReport = ({ reportType, pageType }: ExportProps) => {
  const { state, dispatch } = useContext(StatesContext);

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(emptyMessageType);

  const { state: globalState } = useContext(AppContext);
  const { token } = useToken();

  const { ledgerId } = useParams();
  const [statesChange, setStatesChange] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(ChosenType.ALL);
  const [selectedBranch, setSelectedBranch] = useState(ChosenType.ALL);
  const [selectedClass, setSelectedClass] = useState(ChosenType.ALL);
  const [selectedSem, setSelectedSem] = useState(ChosenType.ALL);
  const [selectedSection, setSelectedSection] = useState(ChosenType.ALL);
  const [selectedCategory, setSelectedCategory] = useState(ChosenType.ALL);

  const { handleSubmit } = useExportMutation(
    ExcelAcctQueryType.ACCT_VOUCHERS_BY_DATE
  );

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel,
  } = useInstLabels();
  const {
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const { financialYearResponse } = useFinancialYears();

  const [GetAccountLedger, { data }] = useLazyQuery<
    { node: { ldgr_desc: string } },
    singleNodeVars
  >(AccLedgerByNodeId);

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    state.dept ? state.dept.value : 0,
    state.branch ? state.branch.value : 0,
    state.class ? state.class.value : 0,
    state.sem ? state.sem.value : 0
  );
  const {
    branchDropDown: mstBranchDropDown,
    classDropDown: mstClassDropDown,
    departmentDropDown: mstDeptDropDown,
    sectionDropDown: mstSecDropDown,
    semesterDropDown: mstSemDropDown,
  } = useDropdownData(
    state.dept ? state.dept.value : 0,
    state.branch ? state.branch.value : 0,
    state.class ? state.class.value : 0,
    state.sem ? state.sem.value : 0
  );
  const isAdmissionModule = window.location.pathname.includes(
    ExportModuleType.ADMISSIONS
  );
  const { categoryDropDown } = useInstitutionDetailsDataByInstId(
    InstitutionDetails.CATEGORY
  );

  const [customizeDetails, setCustomizeDetails] = useState(false);
  const isVouchers = window.location.pathname.includes("voucher");
  const handleChange = (
    newValue: optionsType | null,
    type: PredefinedDataTypes
  ) => {
    dispatch({
      payload: {
        dept: {
          label: "De",
          value: 0,
        },
      },
      type: InstitutionDetails.DEPARTMENT,
    });
    if (type === PredefinedDataTypes.CASTE) {
      if (newValue) {
        dispatch({
          payload: {
            caste: newValue as optionsType,
          },
          type: payloadType.CASTE,
        });
      }
    } else {
      if (newValue) {
        dispatch({
          payload: {
            religion: newValue as optionsType,
          },
          type: payloadType.RELIGION,
        });
      } else {
        dispatch({
          payload: {
            religion: null,
          },
          type: payloadType.RELIGION,
        });
      }
    }
    setStatesChange(!statesChange);
  };

  useEffect(() => {
    if (token && Number(ledgerId)) {
      GetAccountLedger({
        variables: {
          id: Number(ledgerId),
          token,
        },
      });
    }
  }, [GetAccountLedger, token, ledgerId]);

  useEffect(() => {
    if (globalState.ActiveFinYr) {
      dispatch({
        type: payloadType.START_DATE,
        payload: {
          startDate: globalState.ActiveFinYr.fin_st_date,
        },
      });
      dispatch({
        type: payloadType.END_DATE,
        payload: {
          endDate: globalState.ActiveFinYr.fin_end_date,
        },
      });
    }
  }, [globalState.ActiveFinYr]);

  return (
    <>
      <div className="inst-level-report__md">
        <div className="inst-level-report__md--header">
          <b>Generate Report</b>
        </div>
        {pageType === ExcelReportPageType.VOUCHER ? (
          <div className="inst-level-report__md--block">
            {Number(ledgerId) ? (
              <>
                <h6 className="inst-level-report__md--block--title">
                  {data ? data.node.ldgr_desc : EMPTY_STRING}
                </h6>
                <div className="inst-level-report__md--flex">
                  <TextField
                    className="inst-level-report__md--textfield"
                    label="Start Date"
                    type="date"
                    value={
                      state.startDate
                        ? toInputStandardDate(state.startDate)
                        : EMPTY_STRING
                    }
                    onChange={(e) => {
                      dispatch({
                        type: payloadType.START_DATE,
                        payload: {
                          startDate: e.target.value,
                        },
                      });
                    }}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                  <img
                    src={Compare}
                    alt=""
                    className="inst-level-report__md--block--compare"
                  />
                  <TextField
                    className="inst-level-report__md--textfield"
                    label="End Date"
                    type="date"
                    value={
                      state.endDate
                        ? toInputStandardDate(state.endDate)
                        : EMPTY_STRING
                    }
                    onChange={(e) => {
                      dispatch({
                        type: payloadType.END_DATE,
                        payload: {
                          endDate: e.target.value,
                        },
                      });
                    }}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="inst-level-report__md--nodata">
                <img src={NoData} alt="" />
              </div>
            )}
          </div>
        ) : (
          <div className="inst-level-report__md--block">
            {reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR && (
              <div className="inst-level-report__md--block--flex">
                <Label>Choose Particular Year</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  options={
                    financialYearResponse.data
                      ? financialYearResponse.data.GetFinYrsByInstId
                      : []
                  }
                  getOptionLabel={(option) =>
                    (option as ListFinancialYears).fin_yr
                  }
                  openOnFocus
                  value={state.selectedFinYr}
                  isOptionEqualToValue={(option) => {
                    if (state.selectedFinYr) {
                      return (
                        (option as ListFinancialYears).id ===
                        state.selectedFinYr.id
                      );
                    } else {
                      return false;
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadType.SET_SELECTED_FIN_YR,
                        payload: {
                          selectedFinYr: newValue as ListFinancialYears,
                        },
                      });
                    } else {
                      dispatch({
                        type: payloadType.SET_SELECTED_FIN_YR,
                        payload: {
                          selectedFinYr: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            )}
            {USE_DEPARTMENT_KEY &&
            reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
            state.selectedFinYr ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedDepartment}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.DEPARTMENT,
                          payload: {
                            dept: null,
                          },
                        });
                      }
                      setSelectedDepartment(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${departmentLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${departmentLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={
                    isAdmissionModule ? departmentDropDown : mstDeptDropDown
                  }
                  openOnFocus
                  value={state.dept}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.DEPARTMENT,
                        payload: {
                          dept: newValue as responseType as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.DEPARTMENT,
                        payload: {
                          dept: null,
                        },
                      });
                    }
                  }}
                  disabled={selectedDepartment === ChosenType.ALL}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY &&
            reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
            state.selectedFinYr ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    value={selectedBranch}
                    row
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.BRANCH,
                          payload: {
                            branch: null,
                          },
                        });
                      }
                      setSelectedBranch(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${branchLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${branchLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={
                    isAdmissionModule ? branchDropDown : mstBranchDropDown
                  }
                  openOnFocus
                  value={state.branch}
                  disabled={selectedBranch === ChosenType.ALL}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.BRANCH,
                        payload: {
                          branch: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.BRANCH,
                        payload: {
                          branch: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_CLASS_KEY &&
            reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
            state.selectedFinYr ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedClass}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.CLASS,
                          payload: {
                            class: null,
                          },
                        });
                      }
                      setSelectedClass(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${classLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${classLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={isAdmissionModule ? classDropDown : mstClassDropDown}
                  openOnFocus
                  value={state.class}
                  disabled={selectedClass === ChosenType.ALL}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.CLASS,
                        payload: {
                          class: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.CLASS,
                        payload: {
                          class: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY &&
            reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
            state.selectedFinYr ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedSem}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.SEMESTER,
                          payload: {
                            sem: null,
                          },
                        });
                      }
                      setSelectedSem(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${semesterLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${semesterLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={
                    isAdmissionModule ? semesterDropDown : mstSemDropDown
                  }
                  disabled={selectedSem === ChosenType.ALL}
                  openOnFocus
                  value={state.sem}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.SEMESTER,
                        payload: {
                          sem: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.SEMESTER,
                        payload: {
                          sem: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY &&
            reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
            state.selectedFinYr ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedSection}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.SECTION,
                          payload: {
                            section: null,
                          },
                        });
                      }
                      setSelectedSection(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${sectionLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${sectionLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={isAdmissionModule ? sectionDropDown : mstSecDropDown}
                  disabled={selectedSection === ChosenType.ALL}
                  openOnFocus
                  value={state.section}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.SECTION,
                        payload: {
                          section: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.SECTION,
                        payload: {
                          section: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CATEGORY_KEY &&
            reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
            state.selectedFinYr ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedCategory}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.CATEGORY,
                          payload: {
                            category: null,
                          },
                        });
                      }
                      setSelectedCategory(value as ChosenType);
                    }}
                  >
                    <div className="inst-level-report__md--block--left">
                      <FormControlLabel
                        control={<Radio />}
                        label={`All ${categoryLabel}`}
                        value={ChosenType.ALL}
                      />
                    </div>

                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${categoryLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={categoryDropDown}
                  disabled={selectedCategory === ChosenType.ALL}
                  openOnFocus
                  value={state.category}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.CATEGORY,
                        payload: {
                          category: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.CATEGORY,
                        payload: {
                          category: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_DEPARTMENT_KEY &&
            reportType !== ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedDepartment}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.DEPARTMENT,
                          payload: {
                            dept: null,
                          },
                        });
                      }
                      setSelectedDepartment(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${departmentLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${departmentLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={
                    isAdmissionModule ? departmentDropDown : mstDeptDropDown
                  }
                  openOnFocus
                  value={state.dept}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.DEPARTMENT,
                        payload: {
                          dept: newValue as responseType as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.DEPARTMENT,
                        payload: {
                          dept: null,
                        },
                      });
                    }
                  }}
                  disabled={selectedDepartment === ChosenType.ALL}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY &&
            reportType !== ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    value={selectedBranch}
                    row
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.BRANCH,
                          payload: {
                            branch: null,
                          },
                        });
                      }
                      setSelectedBranch(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${branchLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${branchLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={
                    isAdmissionModule ? branchDropDown : mstBranchDropDown
                  }
                  openOnFocus
                  value={state.branch}
                  disabled={selectedBranch === ChosenType.ALL}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.BRANCH,
                        payload: {
                          branch: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.BRANCH,
                        payload: {
                          branch: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_CLASS_KEY &&
            reportType !== ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedClass}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.CLASS,
                          payload: {
                            class: null,
                          },
                        });
                      }
                      setSelectedClass(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${classLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${classLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={isAdmissionModule ? classDropDown : mstClassDropDown}
                  openOnFocus
                  value={state.class}
                  disabled={selectedClass === ChosenType.ALL}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.CLASS,
                        payload: {
                          class: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.CLASS,
                        payload: {
                          class: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY &&
            reportType !== ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedSem}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.SEMESTER,
                          payload: {
                            sem: null,
                          },
                        });
                      }
                      setSelectedSem(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${semesterLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${semesterLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={
                    isAdmissionModule ? semesterDropDown : mstSemDropDown
                  }
                  disabled={selectedSem === ChosenType.ALL}
                  openOnFocus
                  value={state.sem}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.SEMESTER,
                        payload: {
                          sem: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.SEMESTER,
                        payload: {
                          sem: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY &&
            reportType !== ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedSection}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.SECTION,
                          payload: {
                            section: null,
                          },
                        });
                      }
                      setSelectedSection(value as ChosenType);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${sectionLabel}`}
                      value={ChosenType.ALL}
                    />
                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${sectionLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={isAdmissionModule ? sectionDropDown : mstSecDropDown}
                  disabled={selectedSection === ChosenType.ALL}
                  openOnFocus
                  value={state.section}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.SECTION,
                        payload: {
                          section: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.SECTION,
                        payload: {
                          section: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CATEGORY_KEY &&
            reportType !== ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR ? (
              <div className="inst-level-report__md--block--flex">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedCategory}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === ChosenType.ALL) {
                        dispatch({
                          type: InstitutionDetails.CATEGORY,
                          payload: {
                            category: null,
                          },
                        });
                      }
                      setSelectedCategory(value as ChosenType);
                    }}
                  >
                    <div className="inst-level-report__md--block--left">
                      <FormControlLabel
                        control={<Radio />}
                        label={`All ${categoryLabel}`}
                        value={ChosenType.ALL}
                      />
                    </div>

                    <img
                      src={Compare}
                      alt=""
                      className="inst-level-report__md--block--compare"
                    />
                    <FormControlLabel
                      value={ChosenType.PARTICULAR}
                      control={<Radio />}
                      label={`Particular ${categoryLabel}`}
                    />
                  </RadioGroup>
                </FormControl>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={categoryDropDown}
                  disabled={selectedCategory === ChosenType.ALL}
                  openOnFocus
                  value={state.category}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: InstitutionDetails.CATEGORY,
                        payload: {
                          category: newValue as responseType,
                        },
                      });
                    } else {
                      dispatch({
                        type: InstitutionDetails.CATEGORY,
                        payload: {
                          category: null,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}

            {reportType === ExcelAdmQueryType.RELIGION_STUDENT_LIST && (
              <div className="inst-level-report__md--block--flex">
                <Label>Select Religion</Label>
                <PredefinedAutocomplete
                  label=""
                  type={PredefinedDataTypes.RELIGION}
                  onChange={handleChange}
                  value={state.religion ? state.religion.value : EMPTY_STRING}
                />
              </div>
            )}
            {reportType === ExcelAdmQueryType.AGE_STUDENT_LIST && (
              <div className="inst-level-report__md--block--flex">
                <Label>Enter Age</Label>
                <InputNumber
                  placeholder="Above Age"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: payloadType.ABOVE_AGE,
                      payload: {
                        aboveAge: Number(e.target.value),
                      },
                    });
                  }}
                  className="inst-level-report__md--block--age"
                />
                <InputNumber
                  placeholder="Below Age"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: payloadType.BELOW_AGE,
                      payload: {
                        belowAge: Number(e.target.value),
                      },
                    });
                  }}
                  className="inst-level-report__md--block--age"
                />
              </div>
            )}
            {reportType === ExcelAdmQueryType.CASTE_STUDENT_LIST && (
              <div className="inst-level-report__md--block--flex">
                <Label>Select Caste</Label>
                <PredefinedAutocomplete
                  label=""
                  type={PredefinedDataTypes.CASTE}
                  onChange={handleChange}
                  value={state.caste ? state.caste.value : EMPTY_STRING}
                />
              </div>
            )}
            {reportType === ExcelAcctQueryType.ACCT_STD_FEE_BY_AGENT_REPORT && (
              <div className="inst-level-report__md--block--flex">
                <Label>By Agent</Label>
                <AgentOptionsAutocomplete />
              </div>
            )}
          </div>
        )}

        {reportType === ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
          state.selectedFinYr && (
            <div className="inst-level-report__md--buttons">
              <Button
                mode="excel"
                onClick={() => {
                  setCustomizeDetails(!customizeDetails);
                  dispatch({
                    type: payloadType.FIELDS,
                    payload: {
                      selectedFields: [],
                    },
                  });
                }}
              >
                Initialize Report
              </Button>
            </div>
          )}
        {reportType !== ExcelAcctQueryType.ACCT_STD_BY_JOINING_YR &&
          (isVouchers ? (
            <div className="inst-level-report__md--buttons">
              <Button
                mode="excel"
                disabled={
                  state.startDate === "" ||
                  state.endDate === "" ||
                  state.voucherType === null ||
                  ledgerId === undefined ||
                  ledgerId === "0"
                }
                onClick={async () => {
                  setLoading(true);
                  try {
                    const res = await handleSubmit();

                    if (res.flag) {
                      setLoading(false);
                      setMessage(message);
                    }
                  } catch (e) {
                    setLoading(false);
                    setMessage({
                      flag: true,
                      message: `Failed downloading ${(e as Error).message}`,
                      operation: Operation.NONE,
                    });
                  }
                }}
              >
                Export
              </Button>
            </div>
          ) : (
            <div className="inst-level-report__md--buttons">
              <Button
                mode="excel"
                disabled={
                  (reportType === ExcelAdmQueryType.CASTE_STUDENT_LIST &&
                    state.caste === null) ||
                  (reportType === ExcelAdmQueryType.RELIGION_STUDENT_LIST &&
                    state.religion === null)
                }
                onClick={() => {
                  setCustomizeDetails(!customizeDetails);
                  dispatch({
                    type: payloadType.FIELDS,
                    payload: {
                      selectedFields: [],
                    },
                  });
                }}
              >
                Initialize Report
              </Button>

            </div>
          ))}
      </div>
      <Modal
        isOpen={customizeDetails}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <Export setModal={setCustomizeDetails} reportType={reportType} />
      </Modal>


      <LoadingModal flag={loading} />
      <MessageModal
        handleClose={() => {
          setMessage(emptyMessageType);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default GenerateReport;

import { optionsType } from "../../../utils/Form.types";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
import {
  SortByFilterType,
  StudentOrderFields,
} from "../../../utils/Enum.types";
import { TextField } from "@mui/material";
import { isOptionEqualToValue } from "../../../utils/UtilFunctions";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
interface Props {
  sortByType: SortByFilterType;
  sortByValue: string;
  onChange: (newValue: optionsType | null) => void;
  label: string;
}

export const SortByOptions = [
  {
    type: SortByFilterType.STUDENT,
    options: [
      {
        label: "Name",
        value: StudentOrderFields.FIRST_NAME,
      },
      {
        label: "Admission No",
        value: StudentOrderFields.STD_ADM_NO,
      },
      {
        label: "Register No",
        value: StudentOrderFields.STD_REG_NO,
      },
      {
        label: "Roll No",
        value: StudentOrderFields.STD_ROLL_NO,
      },
    ],
  },
];
const SortByAutocomplete = ({
  sortByType,
  sortByValue,
  onChange,
  label,
}: Props) => {
  const foundOptions = SortByOptions.find(({ type }) => type === sortByType);
  const options = foundOptions ? foundOptions.options : [];

  const foundValue = options.find(({ value }) => value === sortByValue);
  const autoValue = foundValue ? foundValue : null;

  const Autocomplete =
    label.length > 0 ? LabeledAutocomplete : FormAutocomplete;
  return (
    <Autocomplete
      className={label.length ? labelClasses.inputRoot : formClasses.inputRoot}
      options={options}
      openOnFocus={true}
      {...(sortByValue ? { value: autoValue } : {})}
      onChange={(e: React.SyntheticEvent<Element, Event>, value) => {
        onChange(value as optionsType | null);
      }}
      isOptionEqualToValue={(option) =>
        isOptionEqualToValue(option as optionsType, autoValue)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ""}
          fullWidth={true}

          slotProps={{
            inputLabel: {
               shrink: label.length > 0,
            },
          }}
          className={
            label.length > 0
              ? labelClasses.formControlRoot
              : formClasses.formControlRoot
          }
        />
      )}
    />
  );
};

export default SortByAutocomplete;

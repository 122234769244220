import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import {
  DebitOrCredit,
  NonDemand,
  PageNumbers,
  ReceiptTypes,
  PageFor,
  VoucherBookKey,
  StudentListFor,
  StudentAcctReportType,
  BankOrCash,
  PredefinedDataTypes,
  Direction,
  SortBy,
  Operation,
  StudentReportType,
} from "../../../../utils/Enum.types";

import Modal from "react-modal";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
  EditModalCustomStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import {
  ListAccountLedgerNameData,
  ListAccountLedgerNameVars,
} from "../../../../Types/Accounting";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import {
  DateRange,
  formatter,
  handleMUISelectEvent,
  NameOfTheDay,
  toggleFullSceen,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { GetAccountLedgerNameById } from "../../queries/FeeLedgers/query/Byid";
import { AddAcctVoucherMasterAndDetails } from "../../queries/receipts/mutation/Index";
import dayjs from "dayjs";

import { NonDemandFeePayableTitleProps } from "../../../../Types/Titles";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import FeeLedger from "../../FeeLedger/Index";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import ConfigurationSettings from "../../../Master/configurations/general/Index";
import Avatar from "../../../../images/Avatar.svg";
import Enter from "../../../../images/Enter.svg";
import EditProfile from "../../../../images/EditProfile.svg";
import AddPassoutStudentModal from "../../../Master/Student/AddPassOutStudentModal";
import LoadingModal from "../../../../pages/LoadingModal";
import Settings from "../../../../images/Settings.svg";
import Enlarge from "../../../../images/Enlarge.svg";
import Edit from "../../../../images/Edit.svg";
import DeleteImg from "../../../../images/Delete.svg";
import Close from "../../../../images/Close.svg";

import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import DownArrow from "../../../../images/DownArrow.svg";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import { recepitsw } from "../FeeReceipt/Index";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import MessageModal from "../../../../pages/MessageModal";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../context/reducer";
import Delete from "../FeeReceipt/modals/Delete";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import { GetAcctVouchers } from "../../queries/Vouchers/query";
import StudentList from "../../common/StudentList";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import useAcdStudentsAdmNumber from "../../../Academics/hooks/useAcdStudentsAdmNumber";
import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
} from "../../../Transport/paginationTypes";
import { GetTransportStudent } from "../../../Transport/queries/list";
import { VoucherQueryTypes } from "../../common/Enum.types";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../../Types/Accounting/mutations";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
import { FormAutocomplete, formClasses } from "../../../../styles/AutocompleteStyles";

const { AccountsTitles } = require("../../json/title.json");
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
interface itemList {
  account_ledger_id: number;
  feeAmount: number;
  sl_no: number;
  cr_db: string;
}

const ledgerOptions: optionsType[] = [
  { label: "Cash Book", value: BankOrCash.CASH },

  { label: "Bank Book", value: BankOrCash.BANK },
];
const Index = ({ setModalFlag, pageType }: Props) => {
  const { format } = formatter;
  let DefaultDate = new Date();
  const { Accounts_Table } = useAcctTableJson();
  const dates = DateRange(DefaultDate.toString());

  const { InstId } = useParams<{ InstId: string }>();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const [imageString, setImageString] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate();
  //use States for modal
  const [studentModal, setStudentModal] = useState(false);
  const { InstDetails } = useInstDetails(1);

  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");

  const [ledgerType, setLedgerType] = useState(EMPTY_OPTIONSTYPE_OBJECT);
  const [cashAccount, setCashAccount] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);
  const [bankTransactionType, setBankTransactionType] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const { user_details } = useLoggedInUserDetails();
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE
  );
  const [searchData, setsearchData] = useState("");

  const [admNo, setAdmNo] = useState("");
  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = feeDescRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const dueDateRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;

  const transactionRefNumberRef = useRef<HTMLInputElement>(null);
  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType>(EMPTY_RESPONSETYPE_OBJECT);
  const [id, setId] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [feeAmount, setFeeAmount] = useState(0);
  const [account_ledger_id, SetAccountLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //use state for arrays
  const [items, setItems] = useState<itemList[]>([]);
  const [receipts, setreceipts] = useState<recepitsw[]>([]);

  let totalAmount = 0;

  //use queries
  const { studentData, studentFormData } = useStudentDatabyId();
  const [ledgerList, setLedgerList] = useState<responseType[]>([]);
  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
    ROWS_PER_PAGE
  );
  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_CASH,
    ROWS_PER_PAGE
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING
  );
  const { serverDate } = useServerDateandTime();
  const [GetAcctLedger, { data: AccountLedgerNameData }] = useLazyQuery<
    ListAccountLedgerNameData,
    ListAccountLedgerNameVars
  >(GetAccountLedgerNameById, {
    variables: {
      token,
      acct_ldgr_ids: items.map((item) => item.account_ledger_id),
    },
  });

  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  //lazy queries
  const [GetStudentTranspotData] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: state.studentId,
    },
  });

  //use mutation
  const [
    GenerateStudentNonDemandReceipts,
    { loading: GenerateStudentNonDemandReceiptsLoading },
  ] = useMutation<
    AddAcctVoucherMasterAndDetailsData,
    AddAcctVoucherMasterAndDetailsVars
  >(AddAcctVoucherMasterAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleItems = (e: React.FormEvent) => {
    e.preventDefault();
    const itemAlreadyExists = items.filter(
      (d) => d.account_ledger_id === account_ledger_id.value
    );
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
      setFeeAmount(0);

      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }
    const updatedData = ledgerList?.map((id) => {
      if (id.value === account_ledger_id.value) {
        const newData = {
          ...id,
          value: id.value,
          label: id.label,
          isChecked: !id.isChecked,
        };
        return newData;
      }
      return id;
    });
    setLedgerList(updatedData);
    if (account_ledger_id && feeAmount) {
      setItems((items) => [
        ...items,
        {
          sl_no: items.length + 1,
          account_ledger_id: account_ledger_id.value,
          feeAmount,
          cr_db: DebitOrCredit.CREDIT,
        },
      ]);
    }

    SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setFeeAmount(0);
    feeDescInputRef?.select();
    feeDescInputRef?.focus();
  };
  const handleDebitItems = () => {
    setreceipts((items) => [
      ...items,
      {
        acct_ldgr_id: transactionBookLedgerId.value,
      },
    ]);
  };
  const handleItemEdit = (feeAmount: number, acct_ldgr_id: number) => {
    const ledger = ledgerList.find((d) => d.value === acct_ldgr_id);
    SetAccountLedgerId(ledger!);
    setFeeAmount(feeAmount);
  };
  const HandleEditItem = (sl_number: number, e: React.FormEvent) => {
    e.preventDefault();
    const updatedData = items.map((item) => {
      if (item.sl_no === sl_number) {
        const newData = {
          ...item,
          feeAmount,
          account_ledger_id: account_ledger_id.value,
        };
        return newData;
      }
      return item;
    });
    setEdit(!edit);
    setItems(updatedData);
    setFeeAmount(0);
    SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
  };

  const handleItemDeleted = (i: number) => {
    items.splice(i, 1);
    setItems((items) => [...items]);
  };

  // eslint-disable-next-line
  items.map((item) => (totalAmount += item.feeAmount));
  const handleStudentReceipts = (type?: string) => {
    const res = bankTransactionTypes.find(
      ({ value }) => value === bankTransactionType.value
    );
    GenerateStudentNonDemandReceipts({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: [
          {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,
              v_type: ReceiptTypes.STUDENT_RECEIPT,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number,
              v_date: dayjs(serverDate).format(),
              v_total_cr_amt: totalAmount,
              v_total_db_amt: totalAmount,
              v_std_deposit_adjusted: false,
              v_reconciled: false,
              v_reconciled_date: dayjs(DEFAULT_TIME).format(),
              v_std_receipt: true,
              v_std_enquiry: false,
              enquiry_student_id: 0,
              v_std_non_demand_receipt: true,
              v_std_refund: false,
              v_std_anonymous_deposit_adjusted: false,
              v_std_receipt_anonymous: false,
              v_std_receipt_ob: false,
              v_std_refund_deposit: false,
              v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
              v_std_deposit: false,
              v_std_demand_receipt: false,
              v_std_scholarship_deposit: false,

              student_id: state.studentId ? state.studentId : 0,
              passout_student_id: state.aluminiStudentId
                ? state.aluminiStudentId
                : 0,
              v_std_passout_receipt: NonDemand.ROLE ? false : true,
              class_id: studentData.data?.nodes[0].class.id,
              semester_id: studentData.data?.nodes[0].semester.id,
              v_std_amt_receipt: totalAmount,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: totalAmount,
              v_std_amt_refunded: 0,
              v_std_amt_adjusted: 0,
              v_transcation_type: res?.label ?? "Cash Book",
              v_transcation_cash_or_bank: res
                ? BankOrCash.BANK
                : BankOrCash.CASH,

              v_transcation_no: referenceNumber,
              v_transcation_date: dayjs(serverDate).format(),
              v_transcation_narration: remarks,
              // paid_party_id: "",
              // party_bill_no: "",
              party_bill_date: dayjs(DEFAULT_TIME).format(),
              // party_name: "",
              annx_yesno: false,
              // annx_id: Math.random() * 1000,
              is_vouch_multi_entry: false,
              acct_ldgr_id_cr: items[0].account_ledger_id,
              acct_ldgr_id_db: receipts[0].acct_ldgr_id,
            },
            acct_voucher_db: receipts.map((dbData, index) => ({
              vo_cr_db: DebitOrCredit.DEBIT,
              vo_sl_no: index + 1,
              vo_cr: 0,
              vo_db: totalAmount,
              vo_cr_total: 0,
              vo_db_total: totalAmount,
              acct_ldgr_id: dbData.acct_ldgr_id,
            })),
            acct_voucher_cr: items.map((crData, index) => ({
              vo_cr_db: crData.cr_db,
              vo_sl_no: index + 1,
              vo_cr: crData.feeAmount,
              vo_db: 0,
              vo_cr_total: totalAmount,
              vo_db_total: 0,
              acct_ldgr_id: crData.account_ledger_id,
            })),
          },
        ],
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS,
              vo_end_date: dayjs(dates?.lastDay!).format(),
              acct_ldgr_id: 0,
              vo_start_date: dayjs(dates?.firstDay!).format(),
              vo_type: EMPTY_STRING,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        handleClear();
        setMessage({
          message: `sucessfully raised receipt for student ${studentFormData?.std_name}`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const HandleSelectFeeType = (id: number) => {
    const filterdData = ledgerList?.find(({ value }) => value === id);

    if (filterdData) SetAccountLedgerId(filterdData);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    if (message.operation !== Operation.NONE && message.flag) handleClear();

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setItems([]);
    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
    setAdmNo("");
  };

  useEffect(() => {
    if (transactionBookLedgerId.value > 0) {
      handleDebitItems();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);
  useEffect(() => {
    if (token) {
      GetAcctLedger();
    }
  }, [token, GetAcctLedger, items]);
  useEffect(() => {
    if (acctLedgers.data) {
      setLedgerList(acctLedgers.responseType);
    }
  }, [acctLedgers]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (state.studentId && token) {
      GetStudentTranspotData();
    }
  }, [state.studentId, token, GetStudentTranspotData]);
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.NonDemandFeePayable.Titles.map(
              (title: NonDemandFeePayableTitleProps) => {
                return (
                  <React.Fragment key={title.Role}>{title.Role}</React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        <div className="configuration-settings">
          {pageType === PageFor.GENERAL ? (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          ) : (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 non-demandfee-payable"
            : "row g-0 non-demandfee-payable--modal"
        }
      >
        <div className="col-4 ">
          <div className="non-demandfee-payable__grid">
            {pageType === PageFor.GENERAL ? (
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                openOnFocus
                disabled={state.studentId ? true : false}
                value={
                  studentAddmissionNumber.find(
                    ({ label }) => label === studentFormData.adm_no
                  )! ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                freeSolo
                autoHighlight
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (state.studentId) {
                      feeDescInputRef?.select();
                      feeDescInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission No."
                    fullWidth
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            ) : (
              <TextField
                label="Admission No."
                className="demand-fee__textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData?.adm_no ?? EMPTY_STRING}
                disabled
              />
            )}

            {pageType === PageFor.GENERAL ? (
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            ) : null}
          </div>
          <TextField
            label="Register Number"
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.reg_number ?? EMPTY_STRING}
            disabled
          />
          {USE_CATEGORY_KEY && (
            <TextField
               label={categoryLabel}
              className="demand-fee__textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.category ?? EMPTY_STRING}
              disabled
            />
          )}
        </div>

        <div className="col">
          <TextField
            label="Name"
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.std_name}
            disabled
          />

          <TextField
            label={branchLabel}
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.branch}
            disabled
          />
          <TextField
            label={classLabel}
            className="demand-fee__textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.class}
            disabled
          />
        </div>
        <div className="col-3 ">
          <div className="non-demandfee-payable__label-grid">
            <TextField
              type="date"
              label="Date"
              className="demand-fee__textfield--date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={toInputStandardDate(serverDate)}
              disabled
            />
            <Label variant="present-day">{NameOfTheDay(serverDate)}</Label>
          </div>
          <div className="non-demandfee-payable__label-gridbtn">
            <Button
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
              mode="ledger"
            />
          </div>
        </div>
        <div className="col-1 non-demandfee-payable__image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 non-demandfee-payable__tableblock"
            : "row g-0 non-demandfee-payable__tableblock--modal"
        }
      >
        <TableContainer className="non-demandfee-payable__table g-0">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.Receipts.NonDemand.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="non-demandfee-payable__table--select-row">
                <TableCell
                  id="td-center"
                  className="non-demandfee-payable__table--slno"
                >
                  {items.length + 1}
                </TableCell>
                <TableCell className="non-demandfee-payable__table--desc">
                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={ledgerList?.filter(({ isChecked }) => !isChecked)!}
                    openOnFocus
                    freeSolo
                    disabled={!state.studentId}
                    autoHighlight
                    ref={feeDescRef}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (
                          account_ledger_id.value !==
                            EMPTY_RESPONSETYPE[0].value &&
                          dueDateRef.current
                        ) {
                          dueDateRef.current?.select();
                          dueDateRef.current?.focus();
                        }
                        if (
                          account_ledger_id.value ===
                            EMPTY_RESPONSETYPE[0].value &&
                          items.length >= 1
                        ) {
                          modeOfTransactionInputRef?.focus();
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                      }
                    }}
                    value={account_ledger_id}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        const data = newValue as responseType;
                        SetAccountLedgerId({
                          label: data.label,
                          value: data.value,
                          isChecked: true,
                        });
                      } else {
                        SetAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus={pageType === PageFor.MODAL}
                        onChange={(e) => setsearchData(e.target.value)}
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </TableCell>
                <TableCell
                  id="td-right"
                  className="non-demandfee-payable__table--balance"
                >
                  <input
                    value={feeAmount}
                    id="td-right"
                    disabled={!account_ledger_id.value}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFeeAmount(Number(e.target.value))
                    }
                    ref={dueDateRef}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (feeAmount !== 0) {
                          buttonRef.current?.focus();
                        }
                      }
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className="non-demandfee-payable__table--actions"
                >
                  <button
                    ref={buttonRef}
                    onClick={(e: React.FormEvent) =>
                      edit ? HandleEditItem(id, e) : HandleItems(e)
                    }
                  >
                    {edit ? "Save" : <img src={Enter} alt="/" />}
                  </button>
                </TableCell>
              </TableRow>
              {items.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell id="td-center">{index + 1}</TableCell>
                    <TableCell>
                      {AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]
                        ?.id === item.account_ledger_id
                        ? AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]
                            ?.ldgr_desc
                        : EMPTY_STRING}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="non-demandfee-payable__table--balance"
                    >
                      {format(item.feeAmount)}
                    </TableCell>
                    <TableCell id="td-center">
                      <img
                        src={EditProfile}
                        alt="/"
                        onClick={() => {
                          handleItemEdit(
                            item.feeAmount,
                            item.account_ledger_id
                          );
                          setEdit(!edit);
                          HandleSelectFeeType(item.account_ledger_id);
                          setId(item.sl_no);
                        }}
                      />

                      {items.length ? (
                        <img
                          id="delete-profile"
                          src={DeleteImg}
                          alt="/"
                          onClick={() => handleItemDeleted(index)}
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} className="total">
                  Total :
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalAmount)}
                </TableCell>
                <TableCell id="tfoot-td"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 non-demandfee-payable__receiptblock"
            : "row g-0 non-demandfee-payable__receiptblock--modal"
        }
      >
        <div className="col account-frames h-100">
          <div className="label-grid">
            <Label>Receipt No.</Label>
            <Input
              value={voucherNumber.data?.GetVoucherNumber! ?? EMPTY_STRING}
              disabled
            />
          </div>
          <div className="label-grid">
            <Label> Mode of Transaction</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={ledgerOptions}
              openOnFocus
              freeSolo
              ref={modeOfTransactionRef}
              autoHighlight
              value={ledgerType}
              onChange={(e, newValue) => {
                if (newValue) {
                  setLedgerType(newValue as optionsType);
                  if ((newValue as optionsType).value === BankOrCash.BANK) {
                    setBankAccount(true);
                    setCashAccount(false);
                  } else {
                    setCashAccount(true);
                    setBankAccount(false);
                  }
                } else {
                  setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER && ledgerType.value) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        {cashAccount ? (
          <div className="col account-frames label-grid h-100">
            <Label> Cash Ledgers</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={CashLedgers.responseType!}
              openOnFocus
              freeSolo
              autoHighlight
              // ref={cashAccount ? cashLedgerRef : null}
              value={transactionBookLedgerId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTransactionLedgerId(newValue as responseType);
                  setreceipts([]);
                } else {
                  setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  setreceipts([]);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (transactionBookLedgerId.value && e.key === Keys.ENTER) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  onChange={(e) => setsearchData(e.target.value)}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {bankAccount ? (
          <div className="col account-frames h-100">
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label> Bank Transaction Type</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={bankTransactionTypes!}
                openOnFocus
                freeSolo
                autoHighlight
                value={bankTransactionType}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBankTransactionType(newValue as optionsType);
                  } else {
                    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (ledgerType && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label> Bank Ledgers</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={BankLedgers.responseType!}
                openOnFocus
                freeSolo
                autoHighlight
                value={transactionBookLedgerId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setTransactionLedgerId(newValue as responseType);
                    setreceipts([]);
                  } else {
                    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                    setreceipts([]);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (transactionBookLedgerId.value && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setTransactionLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setsearchData(e.target.value)}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label>Transaction Reference Number</Label>
              <Input
                inputRef={transactionRefNumberRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                }}
                type="text"
                value={referenceNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setReferenceNumber(e.target.value)
                }
              />
            </div>
          </div>
        ) : null}
        <div className="col account-frames label-grid h-100">
          <Label>Remarks :</Label>
          <TextArea
            rows={3}
            textAreaRef={remarksRef}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === Keys.ENTER) {
                saveButtonRef.current?.focus();
              }
            }}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </div>
      </form>
      <div
        className={
          pageType === PageFor.GENERAL ? "non-demandfee-payable__buttons" : ""
        }
      >
        <Button
          mode="save"
          disabled={!items.length || !transactionBookLedgerId.value}
          onClick={handleStudentReceipts}
          buttonref={saveButtonRef}
        />
        <Button mode="delete" onClick={() => setDeleteModal(!deleteModal)} />

        <Button mode="previous-receipt" />
        <Button
          mode="clear"
          disabled={!state.studentId}
          onClick={handleClear}
        />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} type="button" />
        ) : (
          <Button
            mode="cancel"
            type="button"
            onClick={() => setModalFlag(false)}
          />
        )}
      </div>
      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.GENERAL}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* fee ledger Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setFeeledgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
            />
          </div>
        </div>
      </Modal>
      {/* Pass out student Modal */}
      <AddPassoutStudentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        operation={Operation.CREATE}
      />
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete v_type={VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS} />
            <Button
              mode="cancel"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentNonDemandReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../stories/Button/Button";
import Template1, {
  IdTemplates,
  IdTemplatesSides,
} from "./Templates/Portrait/ThemeBased/Template1";
import { PageFor } from "../../../utils/Enum.types";
import Template2 from "./Templates/Portrait/FullyCustomizable/Template2";
import Template3 from "./Templates/Portrait/LightCustomizable/Template3";
import Template4 from "./Templates/Portrait/DarkCustomizable/Template4";
import useIdcardConfig from "./hooks/useIdcardConfig";
import { Title } from "../../../stories/Title/Title";
import { printPreviewModalType } from "./StudentList";
import Close from "../../../images/Close.svg";
import { StudentEdges } from "../../Academics/hooks/useAcdStudentsForAdmission";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<printPreviewModalType>>;
  selectedTemplateId?: number | null;
  templateName?: string;
  selectedStudents: StudentEdges[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const PrintIdcard = ({
  setModalFlag,
  selectedTemplateId,
  templateName,
  selectedStudents,
  setStudentIdSet,
}: Props) => {
  const [modal, setModal] = useState(false);
  const { data } = useIdcardConfig();
  const [selectedSide, setSelectedSide] = useState("front");
  const TemplateList = [
    {
      id: 1,
      desc: "Theme Based",
      name: (
        <>
          {selectedSide === "front" ? (
            <>
              <Template1
                templateFor={IdTemplates.PRINT}
                setModal={setModal}
                selectedSideForPrint={IdTemplatesSides.FRONT}
                pageType={PageFor.PRINT}
                templateId={selectedTemplateId!}
                templateName={templateName!}
                selectedStudents={selectedStudents}
                setStudentIdSet={setStudentIdSet}
              />
            </>
          ) : (
            <Template1
              templateFor={IdTemplates.PRINT}
              setModal={setModal}
              selectedSideForPrint={IdTemplatesSides.BACK}
              pageType={PageFor.PRINT}
              templateId={selectedTemplateId!}
              templateName={templateName!}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
            />
          )}
        </>
      ),
    },
    {
      id: 2,
      desc: "Fully Customizable",
      name: (
        <>
          {selectedSide === "front" ? (
            <Template2
              templateFor={IdTemplates.PRINT}
              setModal={setModal}
              selectedSideForPrint={IdTemplatesSides.FRONT}
              pageType={PageFor.PRINT}
              templateId={selectedTemplateId!}
              templateName={templateName!}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
            />
          ) : (
            <Template2
              templateFor={IdTemplates.PRINT}
              setModal={setModal}
              selectedSideForPrint={IdTemplatesSides.BACK}
              pageType={PageFor.PRINT}
              templateId={selectedTemplateId!}
              templateName={templateName!}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
            />
          )}
        </>
      ),
    },
    {
      id: 3,
      desc: "Light Color Customizable",
      name: (
        <>
          {selectedSide === "front" ? (
            <Template3
              templateFor={IdTemplates.PRINT}
              setModal={setModal}
              selectedSideForPrint={IdTemplatesSides.FRONT}
              pageType={PageFor.PRINT}
              templateId={selectedTemplateId!}
              templateName={templateName!}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
            />
          ) : (
            <Template3
              templateFor={IdTemplates.PRINT}
              setModal={setModal}
              selectedSideForPrint={IdTemplatesSides.BACK}
              pageType={PageFor.PRINT}
              templateId={selectedTemplateId!}
              templateName={templateName!}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
            />
          )}
        </>
      ),
    },
    {
      id: 4,
      desc: "Dark Color Customizable",
      name:
        selectedSide === "front" ? (
          <Template4
            templateFor={IdTemplates.PRINT}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.FRONT}
            pageType={PageFor.PRINT}
            templateId={selectedTemplateId!}
            templateName={templateName!}
            selectedStudents={selectedStudents}
            setStudentIdSet={setStudentIdSet}
          />
        ) : (
          <Template4
            templateFor={IdTemplates.PRINT}
            setModal={setModal}
            selectedSideForPrint={IdTemplatesSides.BACK}
            pageType={PageFor.PRINT}
            templateId={selectedTemplateId!}
            templateName={templateName!}
            selectedStudents={selectedStudents}
            setStudentIdSet={setStudentIdSet}
          />
        ),
    },
  ];
  const selectedTemplate = TemplateList.find(
    (list) => data && list.desc === data.GetIdCardTemplate.id_tpl_desc
  );

  const componentRef = useRef<HTMLDivElement>(null);
  if (selectedTemplate) {
    return (
      <>
        <div className="id-cards-print__title">
          <Title>Preview</Title>
          <img
            src={Close}
            alt=""
            onClick={() => setModalFlag({ flag: false, selectedStudents: [] })}
          />
        </div>
        <label>
          Select Side:
          <select
            value={selectedSide}
            onChange={(e) => setSelectedSide(e.target.value)}
          >
            <option value="front">Page 1</option>
            <option value="back">Page 2</option>
          </select>
        </label>

        <div className="id-cards-print row g-0 justify-content-center">
          <style type="text/css" media="print" scoped>
            {`
            @page{
              size:54mm 85.6mm;
              margin:0px;
            }
         
            body {
              height: 100% !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
              margin:0px;
            }
           
        .id-template-1__card{
          height:100% !important;
          width:100% !important;
          page-break-after: always;

        }     .id-template-2__card{
          height:100% !important;
          width:100% !important;
          page-break-after: always;

        }
        .id-cards-print--print{
          height:100% !important;
          width:100% !important;
        }
        .id-template-1__card--bs{
          height:100% !important;
          width:100% !important;
          margin:0px 0px !important;

        }
        .id-template-1__card--bs--id{
          height:100% !important;
          width:100% !important;
        }
         .id-template-1__card--fs--li{
          height:100% !important;
          width:100% !important;
        }
           .id-template-2__card--fs--li{
          height:100% !important;
          width:100% !important;
        }
        .id-template-1__card--fs{
          height:100% !important;
          width:100% !important;
        }
        .id-template-2{
          height:100% !important;
          width:100% !important;
          margin:0px 0px !important;

        }
        .id-template-2__card--bs{
          height:100% !important;
          width:100% !important;
          margin:0px 0px !important;
        }  
         .id-template-2__card--bs--id{
          height:100% !important;
          width:100% !important;
        }
        .h-100{
          height:100% !important;
          width:100% !important;
        }
        .id-template-1{
          margin:0px 0px !important;
          height:100% !important;
          width:100% !important;
        }
        .id-template-1__card--fs--id{
          height:100% !important;
          width:100% !important;
        }
        .id-template-2__card--fs--id{
          height:100% !important;
          width:100% !important;
        }
       `}
          </style>
          <div className="id-cards-print--print" ref={componentRef}>
            {selectedTemplate.name}
          </div>
        </div>
        <ReactToPrint
          trigger={() => <Button mode="print" />}
          content={() => componentRef.current}
        />
        <Button
          mode="cancel"
          onClick={() => setModalFlag({ flag: false, selectedStudents: [] })}
        />
      </>
    );
  }
};

export default PrintIdcard;

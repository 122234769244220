import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";

import {
  DateRange,
  GetnumberOfDaysInMonth,
  MonthName,
  handleDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import Previous from "../../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../../images/CalenderNextButton.svg";
import { Button } from "../../../../../stories/Button/Button";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { SubjectData } from "./ViewAllSubjects";
import { useLazyQuery } from "@apollo/client";
import { GetAcdAttendanceSubjMonthlyForStdVars } from "../../../../Academics/DailyActivities/Attendance/Index";
import { GetAcdAttendanceSubjMonthly } from "../../../../Academics/queries/holidays/list";
import {
  AcdStdAttSubjQueryType,
  GetAcdAttendanceSubjMonthlyData,
  GetAcdAttendanceSubjMonthlyEdges,
} from "../../../../Academics/DailyActivities/Attendance/SessionAttendance";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { INCREMENT_DECREMENT } from "../../../../Enquiry/Dashboard/Index";
import MessageModal from "../../../../../pages/MessageModal";
import { msgType } from "../../../../../utils/Form.types";
import StudentAcademicDetails from "../../StudentPreview/StudentAcademicDetails";
import { HEADER_TEXT_ALIGN, SLNO_TEXT_ALIGN, StyledDatagrid, TABLE_ROW_HEIGHT } from "../../../../../styles/DataGridTableStyles";
import dayjs from "dayjs";
import useCheckAllocationType from "../../../../Academics/hooks/useCheckAllocationType";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { GridAlignment, GridColDef, GridPinnedColumnFields } from "@mui/x-data-grid-pro";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  subjectDetails: SubjectData;
  pageType: PageFor;
}

const SubjectWiseAttendance = ({
  subjectDetails,
  setModalFlag,
  pageType,
}: Props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [date, setMonthDate] = useState(new Date());
  const { firstDay } = DateRange(date.toString()) || {};
  const { serverDate } = useServerDateandTime();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const numberOfDaysInMonth = GetnumberOfDaysInMonth(date.toString());

  const daysArray = Array.from({ length: numberOfDaysInMonth }, (_, i) => {
    const dayIndex =
      (i + new Date(date.getFullYear(), date.getMonth(), 1).getDay()) % 7;
    return { day: days[dayIndex], date: dayIndex };
  });
  const { studentEntryId } = useStudentDatabyId();
  const { entry_level } = useInstitutionConfiguration();
  const { flag } = useCheckAllocationType(studentEntryId);
  const [GetAcdAttSubjMonthly, { data: SessionsData }] = useLazyQuery<
    GetAcdAttendanceSubjMonthlyData,
    GetAcdAttendanceSubjMonthlyForStdVars
  >(GetAcdAttendanceSubjMonthly, {
    variables: {
      token,
      input: {
        att_query_type:
          AcdStdAttSubjQueryType.SUBJ_MONTHLY_ATT_PER_STD_PER_SUBJECT,
        entry_id: studentEntryId,
        entry_level: entry_level,
        cal_month: toIsoDate(firstDay!),
        inst_id: state.InstId!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        subj_master_id: subjectDetails.id,
        date_of_attendance: toIsoDate(serverDate.toString()),
        student_id: state.studentId,
        session_id: 0,
      },
      per_std_subj_allocation: flag!,
      after: null,
      first: 50,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      name:EMPTY_STRING,
    },
  });

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (
      token &&
      state.ActiveAcdYr &&
      subjectDetails.id &&
      firstDay &&
      flag !== undefined
    ) {
      GetAcdAttSubjMonthly();
    }
  }, [
    token,
    state.ActiveAcdYr,
    subjectDetails.id,
    firstDay,
    GetAcdAttSubjMonthly,
    flag,
  ]);
  const getCellClassName = (params: any) => {
    const value = params.value;
    if (value === "P") return "font-green";
    if (value === "A") return "font-red";
    return "";
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "sessions",
      headerName: "Sessions",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
    },
    {
      field: "time",
      headerName:  "Start and End Time",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    ...daysArray.map((_, index) => ({
      field: `day_${index + 1}`,
      headerName: `Day ${index + 1}`,
      headerAlign: "center" as GridAlignment,
      align:"center" as GridAlignment,
      cellClassName: getCellClassName,
      renderCell: (params: any) => (params.value ? params.value : "-"), 
    }))
  ];


  const getDayValues = (edge: GetAcdAttendanceSubjMonthlyEdges) => {
    
    const daysRow = daysArray.reduce((acc, _, index) => {
      const dayValue = (edge.node as unknown as Record<string, string>)[
        `day_${index + 1}`
      ];
      

      acc = {
        ...acc,
        [`day_${index + 1}`]: dayValue,
      };

      return acc;
    }, {});
    return daysRow;
  };

  const rows = SessionsData
    ? SessionsData.GetAcdAttendanceSubjMonthly.edges.map((edge, index) => ({
        id: index + 1,
        sessions: edge.node.session_details.session_desc,
        time: `${edge.node.session_details.start_time} - ${edge.node.session_details.end_time}`,
        ...getDayValues(edge),
      }))
    : [];
    



  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumnFields>({
    left: ["id",'sessions',"time"],

  });
  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumnFields) => {
      setPinnedColumns(updatedPinnedColumns);
    },
    [],
  );
  return (
    <>
      <div
        className={
          pageType === PageFor.REPORT ? "sub-wise-att--report" : "sub-wise-att"
        }
      >
        <Title>
          <b>{subjectDetails.subj_desc} Subject</b>&nbsp;Attendance Details
        </Title>
        {pageType === PageFor.REPORT && <StudentAcademicDetails />}
        <div className="sub-wise-att__select row g-0">
          <div className="col-2"></div>
        </div>
        <div className="sub-wise-att__cards">
          <div className="sub-wise-att__cards--grid">
            <span>Total No. of Sessions</span>
            <b className=" font-grey">{subjectDetails.total_sessions}</b>
          </div>
          <div className="sub-wise-att__cards--grid">
            <span>Total Present</span>
            <b className=" font-green">{subjectDetails.total_present}</b>
          </div>
          <div className="sub-wise-att__cards--grid">
            <span>Total Absent</span>
            <b className=" font-red">{subjectDetails.total_absent}</b>
          </div>
          <div className="sub-wise-att__cards--grid">
            <span>Overall Attendance Percentage</span>
            <b className=" font-blue">{subjectDetails.total_per}%</b>
          </div>
        </div>

        <Title variant="subtitle1">
          <img
            src={Previous}
            alt="/"
            onClick={() =>
              handleDate(
                date,
                setMonthDate,
                state.ActiveAcdYr
                  ? state.ActiveAcdYr.acd_st_date
                  : EMPTY_STRING,
                state.ActiveAcdYr
                  ? state.ActiveAcdYr.acd_end_date
                  : EMPTY_STRING,
                setMessage,
                INCREMENT_DECREMENT.DECREMENT
              )
            }
          />
          {MonthName(date.toString())}

          <img
            src={Next}
            alt="/"
            onClick={() => {
              const nextDate = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate()
              );
              const serverDateDayjs = dayjs(serverDate);
              const selectedDateDayjs = dayjs(nextDate);
              if (
                serverDateDayjs.isAfter(selectedDateDayjs) &&
                !serverDateDayjs.isSame(selectedDateDayjs)
              ) {
                handleDate(
                  date,
                  setMonthDate,
                  state.ActiveAcdYr
                    ? state.ActiveAcdYr.acd_st_date
                    : EMPTY_STRING,
                  state.ActiveAcdYr
                    ? state.ActiveAcdYr.acd_end_date
                    : EMPTY_STRING,
                  setMessage,
                  INCREMENT_DECREMENT.INCREMENT
                );
              } else {
                setMessage({
                  flag: true,
                  operation: Operation.NONE,
                  message: "Cannot Access Future Dates",
                });
              }
            }}
          />
        </Title>

        <div className="sub-wise-att__tableblock">
        
             <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            hideFooter
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={handlePinnedColumnsChange}
          />
        </div>
        <div className="row g-0">
          <div className="col-2">
            <Button mode="cancel" onClick={() => setModalFlag(false)} />
          </div>
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default SubjectWiseAttendance;

import { gql } from "@apollo/client";

export const GetAcctOnlinePayments = gql`
  query GetAcctOnlinePayments(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $student_id: ID!
    $query_type: AcctOnlinePaymentQueryType!
    $orderBy: AcctOnlinePaymentsOrder
    $after: Cursor
    $first: Int
    # $responseCodeNEQ: String
    $transactionDateContainsFold: String
    $searchString: String
  ) {
    GetAcctOnlinePayments(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      student_id: $student_id
      query_type: $query_type
      orderBy: $orderBy
      after: $after
      first: $first
      where: {
        or: [
          # {
          #   responseCode: $responseCode
          #   transactionDateContainsFold: $transactionDateContainsFold
          #   voucherNoContainsFold: $searchString
          # }
          # { responseCodeNEQ: $responseCodeNEQ }
          {
            uniqueRefNumberContainsFold: $searchString
            transactionDateContainsFold: $transactionDateContainsFold
          }
          {
            voucherNoContainsFold: $searchString
            transactionDateContainsFold: $transactionDateContainsFold
          }
        ]
      }
    ) {
      edges {
        node {
          id
          uuid
          response_code
          unique_ref_number
          service_tax_amount
          processing_fee_amount
          total_amount
          transaction_amount
          response_code_str
          transaction_date
          payment_mode
          sub_merchant_id
          reference_no
          merchant_eazypay_id
          mandatory_fields
          optional_fields
          voucher_no
          voucher_master_id
          created_at
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const GetPaymentDetails = gql`
  query GetPaymentDetails($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on AcctOnlinePayments {
        response_code
        voucher_master_id
        response_code_str
        reference_no
        transaction_amount
        transaction_date
      }
    }
  }
`;

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTransportRoutesData from "../../../../customhooks/general/useTransportRoutesData";
import useToken from "../../../../customhooks/useToken";
import Delete from "../../../../images/Delete.svg";
import DeleteModal from "../../../../pages/DeleteModal";

import MessageModal from "../../../../pages/MessageModal";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { RoutesDetailsTitleProps } from "../../../../Types/Titles";
import { EMPTY_RESPONSETYPE_OBJECT } from "../../../../utils/constants";
import { Operation } from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import { RouteDetailsByData, RouteDetailsByVars } from "../../paginationTypes";
import { GetTransportRouteDetailsByMasterId } from "../../queries/list";
import { DeleteTransportRouteDetailsById } from "../../queries/mutations";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useTransportTableJson from "../../json/useTransportTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { Transport_Title } = require("../../json/title.json");

interface Props {
  modalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteRoute = ({ modalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { Transport_Table } = useTransportTableJson();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [routeId, setRouteId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [stageId, setStageId] = useState(0);
  const [searchRoutes, setSearchData] = useState("");

  const [DeleteRouteDetails] = useMutation(DeleteTransportRouteDetailsById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const { routesData } = useTransportRoutesData(searchRoutes, false);

  const [GetRouteDetailsById, { data }] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id: routeId.value,
    },
  });

  const HandleDelete = (id: number) => {
    DeleteRouteDetails({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetTransportRouteDetailsByMasterId,
          variables: {
            token,
            inst_id: InstId!,
            route_master_id: routeId.value,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Route Stage Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleBack = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (routeId.value) {
      GetRouteDetailsById({
        variables: {
          token,
          inst_id: InstId!,
          route_master_id: routeId.value,
        },
      });
    }
  }, [GetRouteDetailsById, InstId, routeId.value, token]);
  return (
    <>
      <Title>
        {Transport_Title.MasterData.RoutesDetails.map(
          (title: RoutesDetailsTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Delete}</React.Fragment>;
          }
        )}
      </Title>
      <div className="transport-route-details__delete">
        <div className="row g-0">
          <div className="col-4">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={routesData.routesDropDown}
              openOnFocus
              value={routeId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setRouteId(newValue as responseType);
                } else {
                  setRouteId(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Route"
                  autoFocus
                  onChange={(e) => setSearchData(e.target.value)}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className="transport-route-details__delete--tableblock">
          {routeId.value > 0 ? (
            <TableContainer className="transport-route-details__delete--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Transport_Table.MasterData.RouteDetails.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.GetTransportRouteDetailsByMasterId.map(
                    (data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            id="td-center"
                            className="transport-route-details__table--slno"
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell className="transport-route-details__table--ledger">
                            {data.route_stage_desc}
                          </TableCell>
                          <TableCell>
                            {data.acct_ldgr_details.ldgr_desc}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="transport-route-details__table--slno"
                          >
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => {
                                setStageId(data.id);
                                setDeleteModal(!deleteModal);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </div>
        <Button mode="cancel" onClick={() => modalFlag(false)} />
      </div>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={stageId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default DeleteRoute;

import {  TextField } from "@mui/material";
import Input from "../../stories/Input/Input";

import { Title } from "../../stories/Title/Title";
import { useParams } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetEduateDetailsByAuthId,
  GetChannelSubscribed,
  GetEmployeeByAuthId,
  GetMessageChannel,
  GetMessageChannelById,
} from "../../queries/chatapplication/list";

import {
  InstitutionDetails,
  Operation,
  UserType,
} from "../../utils/Enum.types";
import { Label } from "../../stories/Label/Label";
import Modal from "react-modal";

import { removeMoreSpace } from "../../utils/UtilFunctions";
import { Button } from "../../stories/Button/Button";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GetAuthDetailsByToken } from "../../queries/DetailsBytoken/index";
import {
  AddMsgChannelandGroup,
  UpdateChannel,
} from "../../queries/chatapplication/mutations";

import { ChannelDataByIdData, ChannelDataByIdVars } from "./Types";

import LoadingModal from "../../pages/LoadingModal";
import Close from "../../images/Close.svg";

import { EMPTY_RESPONSETYPE_OBJECT, EMPTY_STRING } from "../../utils/constants";
import useToken from "../../customhooks/useToken";
import MessageModal from "../../pages/MessageModal";
import { Keys } from "../../utils/Enum.keys";
import { msgType, responseType } from "../../utils/Form.types";
import { AppContext } from "../../context/context";
import { AddLedgerModalStyles } from "../../styles/ModalStyles";
import useInstitutionConfiguration from "../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../customhooks/useDropdownData";
import Next from "../../images/Next.svg";
import TextArea from "../../stories/TextArea/TextArea";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { ChannelQueryType } from "../Accounts/common/Enum.types";
import useInstLabels from "../../customhooks/general/useInstLabels";
import { FormAutocomplete, formClasses } from "../../styles/AutocompleteStyles";
interface Props {
  setAddChannel: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
}
const NewChannel = ({ setAddChannel, operation }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();

  const [channelName, setChannelName] = useState("");
  const [channelDescription, setChannelDescription] = useState("");
  const [levelSelected, setLevelSelected] = useState("");
  const [eduate_id, setEduateId] = useState(0);
  const [emp_id, setEmpId] = useState(0);
  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );

  const departmentRef = useRef<HTMLSelectElement>(null);
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const sectionRef = useRef<HTMLSelectElement>(null);
  const sectionInputRef = sectionRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const [allocationFlag, setAllocationFlag] = useState(false);
  //useRefs
  const channelDescRef = useRef<HTMLTextAreaElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  const [GetChannelData] = useLazyQuery<
    ChannelDataByIdData,
    ChannelDataByIdVars
  >(GetMessageChannelById, {
    variables: {
      token,
      id: state.channelId,
    },
  });

  const [GetUserDetails, { data: userDetails }] = useLazyQuery(
    GetAuthDetailsByToken,
    {
      variables: { token },
    }
  );

  const { id: authId, auth_group_type } =
    userDetails?.GetAuthDetailsByToken ?? "";
  const [eduateDetails] = useLazyQuery(GetEduateDetailsByAuthId, {
    variables: {
      auth_id: authId,
      token,
    },
  });

  const [EmployeeByAuthId] = useLazyQuery(GetEmployeeByAuthId, {
    variables: {
      auth_id: authId,
      token,
    },
  });
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  //channel-configuration
  const createdUserId =
    auth_group_type === UserType.EDUATE ? eduate_id : emp_id;
  const levelsOptions = [
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    "Institution Level",
  ];
  const [NewChannel, { loading: creationLoading }] = useMutation(
    AddMsgChannelandGroup,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
      onCompleted: (e) =>
        setMessage({
          flag: !message.flag,
          message: "Channel Added Successfully",
          operation: Operation.CREATE,
        }),
    }
  );
  const [UpdateChannelData, { loading: updationLoading }] = useMutation(
    UpdateChannel,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
      onCompleted: (e) =>
        setMessage({
          flag: !message.flag,
          message: "Channel Updated Successfully",
          operation: Operation.CREATE,
        }),
    }
  );

  const handleClear = () => {
    setChannelName("");
    setChannelDescription("");
  };

  const getChannelInfo = (levelSelected: string) => {
    switch (levelSelected) {
      case "Institution Level":
        return {
          groupIds: InstId!,
          channelType: InstitutionDetails.INSTITUTION,
        };
      case "Section":
        return {
          groupIds: sectionSelected.value!,
          channelType: InstitutionDetails.SECTION,
        };
      case "Semester":
        return {
          groupIds: semesterSelected.value,
          channelType: InstitutionDetails.SEMESTER,
        };
      case "Class":
        return {
          groupIds: classSelected.value,
          channelType: InstitutionDetails.CLASS,
        };
      case "Branch":
        return {
          groupIds: branchSelected.value,
          channelType: InstitutionDetails.BRANCH,
        };
      default:
        return {
          groupIds: departmentSelected.value,
          channelType: InstitutionDetails.DEPARTMENT,
        };
    }
  };

  const { groupIds, channelType } = getChannelInfo(levelSelected);
  const onSubmit = () => {
    if (operation === Operation.CREATE) {
      NewChannel({
        variables: {
          token,
          user_details,
          input: {
            channel_details: {
              inst_id: InstId,
              channel_name: removeMoreSpace(channelName),
              channel_desc: removeMoreSpace(channelDescription),
              channel_topic: EMPTY_STRING,
              channel_is_student: false,
              channel_chat_enabled: false,
              channel_type: channelType,
              channel_active: false,
              channel_icon_id: 0,
            },
            group_of_ids: groupIds!,
          },
        },
        refetchQueries: [
          {
            query: GetChannelSubscribed,
            variables: {
              token,
              input: {
                user_type: "EMPLOYEE",
                id: createdUserId,
              },
              device_token: EMPTY_STRING,
              inst_id: InstId,
              channel_type: [
                ChannelQueryType.ANNOUNCEMENTS,
                ChannelQueryType.ENTRYLEVEL,
                ChannelQueryType.INDIVIDUAL,
              ],
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Channel Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateChannelData({
        variables: {
          token,
          msg_channel_id: state.channelId,
          inst_id: InstId,
          user_details,
          input: {
            channel_name: removeMoreSpace(channelName),
            channel_desc: removeMoreSpace(channelDescription),
          },
        },
        refetchQueries: [
          {
            query: GetMessageChannel,
            variables: {
              token,
              inst_id: InstId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Channel Updated Successfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAddChannel?.(false);
      handleClear();
    }
    setMessage({
      flag: !message.flag,
      message: "",
      operation: Operation.NONE,
    });
  };
  const clear = () => {
    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
  };

  useEffect(() => {
    if (auth_group_type === UserType.EDUATE) {
      eduateDetails().then(({ data }) => {
        data && setEduateId(data?.GetEduateDetailsByAuthId.id);
      });
    }
    if (auth_group_type === UserType.EMPLOYEE) {
      EmployeeByAuthId().then(({ data }) => {
        data && setEmpId(data?.GetEmployeeByAuthId.id);
      });
    }
  }, [auth_group_type, eduateDetails, EmployeeByAuthId]);
  useEffect(() => {
    if (state.channelId && operation === Operation.UPDATE) {
      GetChannelData().then(({ data }) => {
        if (data) {
          setChannelName(data.node.channel_name);
          setChannelDescription(data.node.channel_desc);
        }
      });
    }
  }, [state.channelId, operation, GetChannelData]);
  useEffect(() => {
    if (token) {
      GetUserDetails();
    }
  }, [token, GetUserDetails]);

  return (
    <>
      <Title>
        {operation === Operation.CREATE ? " Create Channel" : "Update Channel"}
      </Title>
      <div className="channel__add-channel">
        <div className="label-grid">
          <Label>Channel Name</Label>
          <Input
            autoFocus
            required
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                e.preventDefault();
                channelDescRef?.current?.focus();
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setChannelName(e.target.value)
            }
            value={channelName}
            name="channel_name"
          />
        </div>

        <div className="label-grid">
          <Label>Channel Description</Label>

          <TextArea
            rows={5}
            textAreaRef={channelDescRef}
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                e.preventDefault();
                saveRef?.current?.focus();
              }
            }}
            draggable={false}
            resize="none"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setChannelDescription(e.target.value)
            }
            name="channel_desc"
            value={channelDescription}
          />
        </div>
        <div className="label-grid">
          <Label>Allocation Level Upto</Label>
          <FormAutocomplete
            className={formClasses.inputRoot}
            options={levelsOptions}
            openOnFocus
            forcePopupIcon
            value={levelSelected}
            onChange={(e, newValue) => {
              if (newValue) {
                setLevelSelected(newValue as string);
              } else {
                setLevelSelected(EMPTY_STRING);
              }
            }}
            // onKeyDown={(e) => {
            //   if (e.key === Keys.BACKSPACE) {
            //     setVoucherBookData((prev) => ({
            //       ...prev,
            //       separator: "",
            //     }));
            //   }
            // }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                className={formClasses.formControlRoot}
              />
            )}
          />
        </div>
        {levelSelected === "Institution Level" ? (
          <Button
            mode="save"
            type="submit"
            buttonref={saveRef}
            onClick={onSubmit}
          />
        ) : null}
        {levelSelected !== "Institution Level" ? (
          <Button
            buttonref={saveRef}
            onClick={() => setAllocationFlag(!allocationFlag)}
          >
            <img src={Next} alt="" />
            Next
          </Button>
        ) : null}
        {channelName.length ? (
          <Button mode="clear" onClick={handleClear} />
        ) : null}
        <Button mode="cancel" onClick={() => setAddChannel(false)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={allocationFlag}
        style={AddLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Allocation Level</Title>
            <Label>{channelName}</Label>
            {USE_DEPARTMENT_KEY ? (
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={departmentDropDown}
                ref={departmentRef!}
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === departmentSelected.value
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected.value) {
                      branchInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);

                  } else {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            ) : null}
            {USE_BRANCH_KEY && levelSelected !== departmentLabel ? (
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={branchDropDown}
                ref={branchRef!}
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === branchSelected.value
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected.value) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                  } else {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            ) : null}
            {USE_CLASS_KEY && levelSelected !== departmentLabel ? (
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === classSelected.value
                }
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected.value) {
                      semInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                  } else {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={formClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            ) : null}
            {USE_SEMESTER_KEY && levelSelected !== departmentLabel ? (
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={semesterDropDown}
                ref={semRef!}
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === semesterSelected.value
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected.value) {
                      sectionInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                  } else {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            ) : null}
            {USE_SECTION_KEY && levelSelected !== departmentLabel ? (
              <FormAutocomplete
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === sectionSelected.value
                }
                className={formClasses.inputRoot}
                options={sectionDropDown}
                ref={sectionRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      categoryInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                  } else {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            ) : null}
            <Button
              mode="save"
              type="submit"
              buttonref={saveRef}
              onClick={onSubmit}
            />
            <Button mode="clear" onClick={clear} />
            <Button
              mode="cancel"
              onClick={() => setAllocationFlag(!allocationFlag)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAllocationFlag(!allocationFlag)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default NewChannel;

import React, { useEffect, useState } from "react";
import EduateLogo from "../images/EduateLogo.svg";
import Banner from "../images/MyEduateBanner.jpg";
import Banner2 from "../images/ContactusMobile.jpg";
import ContactUs from "../images/ContactUs.jpg";
import Footer from "../images/MyEduateDemoFooter.jpg";
import "./demo.scss";
import { useMutation } from "@apollo/client";
import { AddDemoEnquiry } from "./Queries";
import MessageModal from "../pages/MessageModal";
import { Operation } from "../utils/Enum.types";
import { msgType } from "../utils/Form.types";
import { ACCOUNTANT } from "../utils/constants";
import UpdatedRefresh from "../images/UpdatedRefresh.svg";
interface websiteData {
  demo_enq_name: string;
  demo_enq_designation: string;
  demo_enq_mobile_no: string;
  demo_enq_email: string;
  demo_enq_inst_name: string;
  demo_enq_comments: string;
}
const Index = () => {
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [formData, setFormData] = useState<websiteData>({
    demo_enq_name: "",
    demo_enq_designation: "",
    demo_enq_mobile_no: "",
    demo_enq_email: "",
    demo_enq_inst_name: "",
    demo_enq_comments: "",
  });
  const [captchaText, setCaptchaText] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");

  const [AddWebsiteEnquiry] = useMutation(AddDemoEnquiry, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleSubmit = () => {
    AddWebsiteEnquiry({
      variables: {
        input: {
          demo_enq_name: formData.demo_enq_name,
          demo_enq_designation: ACCOUNTANT,
          demo_enq_mobile_no: formData.demo_enq_mobile_no,
          demo_enq_email: formData.demo_enq_email,
          demo_enq_inst_name: formData.demo_enq_inst_name,
          demo_enq_comments: formData.demo_enq_comments,
        },
      },
    }).then(({ data }) => {
      if (data && data.AddDemoEnquiry) {
        setMessage({
          flag: true,
          message: "Details Added Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setFormData({
        demo_enq_name: "",
        demo_enq_designation: "",
        demo_enq_mobile_no: "",
        demo_enq_email: "",
        demo_enq_inst_name: "",
        demo_enq_comments: "",
      });
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptchaText(captcha);
    setUserInput("");
  };
  useEffect(() => {
    generateCaptcha();
  }, []);
  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };
  return (
    <>
      <div className="demo">
        <div className="nav">
          <input type="checkbox" id="nav-check" />
          <div className="nav-header">
            <div className="nav-title">
              <img src={EduateLogo} alt="" />
              <h2> My-Eduate</h2>
            </div>
          </div>
          <div className="nav-btn">
            <label htmlFor="nav-check">
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>

          <div className="nav-links">
            <a href="https://myeduate.com/">
              <span>Home</span>
            </a>
            <a href="https://myeduate.com/index.php/why-us/">
              <span>Why Us</span>
            </a>
            <a href="https://myeduate.com/index.php/solutions/">
              <span>Solutions</span>
            </a>
            <a href="https://myeduate.com/index.php/our-team/">
              <span> Our Team</span>
            </a>
            <a href="https://edu.myeduate.com/contact-us">
              <span>Contact Us</span>
            </a>
          </div>
          <div className="demo__header--button-block">
            {/* <button className="demo__header--button">Login</button> */}
          </div>
        </div>
        <div className="demo__banner">
          <img src={Banner} alt="" className="demo__banner--image" />
          <img src={Banner2} alt="" className="demo__banner--image--mobile" />
        </div>
        <div className="demo__form">
          <div className="demo__form--image">
            <img src={ContactUs} alt="" />
          </div>
          <div className="demo__form--data">
            <div className="demo__form--data-block">
              <div className="demo__form--title">
                <h4>Book a Demo</h4>
                <div className="demo__grid">
                  <label className="demo__label">Name </label>
                  <input
                    className="demo__input"
                    value={formData.demo_enq_name}
                    onChange={(e) => {
                      setFormData((prevValues) => ({
                        ...prevValues,
                        demo_enq_name: e.target.value,
                      }));
                    }}
                  />

                  <label className="demo__label">Email Id</label>
                  <input
                    className="demo__input"
                    value={formData.demo_enq_email}
                    onChange={(e) => {
                      setFormData((prevValues) => ({
                        ...prevValues,
                        demo_enq_email: e.target.value,
                      }));
                    }}
                  />
                  <label className="demo__label">Contact No</label>
                  <input
                    className="demo__input"
                    value={formData.demo_enq_mobile_no}
                    onChange={(e) => {
                      setFormData((prevValues) => ({
                        ...prevValues,
                        demo_enq_mobile_no: e.target.value,
                      }));
                    }}
                  />
                  <label className="demo__label">Designation</label>
                  <input
                    className="demo__input"
                    onChange={(e) => {
                      setFormData((prevValues) => ({
                        ...prevValues,
                        demo_enq_designation: e.target.value,
                      }));
                    }}
                  />
                  <label className="demo__label">Institution Name</label>
                  <input
                    className="demo__input"
                    value={formData.demo_enq_inst_name}
                    onChange={(e) => {
                      setFormData((prevValues) => ({
                        ...prevValues,
                        demo_enq_inst_name: e.target.value,
                      }));
                    }}
                  />
                  <label className="demo__label">Message</label>
                  <textarea
                    className="demo__textarea"
                    rows={3}
                    value={formData.demo_enq_comments}
                    onChange={(e) => {
                      setFormData((prevValues) => ({
                        ...prevValues,
                        demo_enq_comments: e.target.value,
                      }));
                    }}
                  />
                  <label className="demo__label">Captcha Code</label>
                  <div className="demo__captcha">
                    <div className="demo__captcha--text">
                      <span>{captchaText}</span>
                    </div>

                    <img
                      src={UpdatedRefresh}
                      alt="/"
                      onClick={generateCaptcha}
                    />
                  </div>
                  <label className="demo__label">Enter Captcha Code</label>
                  <input
                    className="demo__input"
                    type="text"
                    value={userInput}
                    onChange={handleUserInput}
                  />
                </div>
              </div>
              <div className="demo__form--button">
                <button className="demo__form--submit" onClick={handleSubmit}>
                  Submit
                </button>
                <button className="demo__form--cancel">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div className="demo__footer">
          <div className="demo__footer--image-block">
            <img src={Footer} alt="" className="demo__banner--image" />
          </div>
          <div className="demo__footer--data-block ">
            <div className=" demo__footer--data-block--items">
              <div className="demo__footer--data-block--flex">
                <img src={EduateLogo} alt="" className="demo__header--image" />
                <b>My-Eduate</b>
              </div>

              <h4>Eduate Pvt. Ltd.</h4>
              <span>Infra house, Hennur-Bagalur</span>
              <span>Main Road,Kalyan Nagar</span>
              <span>Bengaluru 560 043,</span>
              <span>Karnataka,India</span>
            </div>
            <div className="  demo__footer--data-block--items">
              <h3>Explore</h3>
              <a href="/"> Home</a>
              <a href="/">Why Us</a>
              <a href="/">Solutions</a>
              <a href="/">Contact Us</a>
            </div>
            <div className="  demo__footer--data-block--items">
              <h3>Download App</h3>
            </div>
            <div className="">
              <h3>News letter</h3>
            </div>
          </div>
          <div className="demo__footer--copyright">
            © My Eduate 2023 | Privacy Policy
          </div>
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;

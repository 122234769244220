import React, { useState, useRef, useEffect } from "react";
import Home from "../Master/Home/Index";
import { Button } from "../../stories/Button/Button";
import { Title } from "../../stories/Title/Title";
import Input from "../../stories/Input/Input";
import { TextField } from "@mui/material";

import Edit from "../../images/EditProfile.svg";
import Delete from "../../images/Delete.svg";
import { Direction, EduateModule, Operation } from "../../utils/Enum.types";
import { msgType, optionsType } from "../../utils/Form.types";
import { toStandardCase } from "../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddMsgInstTemplate,
  DeleteMsgInstTemplate,
  UpdateMsgInstTemplate,
} from "./queries/mutation";
import LoadingModal from "../../pages/LoadingModal";
import MessageModal from "../../pages/MessageModal";
import useToken from "../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetInstTemplateById,
  GetMsgInstTemplates,
  GetSwMsgTags,
} from "./queries/list";
import {
  GetInstMsgTemplatesVars,
  GetInstTemplateByIdData,
  GetMsgInstTemplatesData,
  GetTemplateByIdVars,
} from "./utils/types";
import DeleteModal from "../../pages/DeleteModal";
import Modal from "react-modal";
import Close from "../../images/Close.svg";
import {
  GetSwMsgTagsData,
  GetSwMsgTagsVars,
} from "../Eduate/Tags/paginationTypes";
import { DeleteLedgerModalStyles } from "../../styles/ModalStyles";
import TagsListImport from "./TagsListImport";
import Import from "../../images/ReadytoImport.svg";
import TextArea from "../../stories/TextArea/TextArea";
import { Label } from "../../stories/Label/Label";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { FormAutocomplete, formClasses } from "../../styles/AutocompleteStyles";
import {
  LabeledAutocomplete,
  labelClasses,
} from "../../styles/AutocompleteListStyles";
import { TemplateViewDrawer } from "../../styles/DrawerStyles";

interface HighlightedTextProps {
  text: string;
}
export const HighlightedText: React.FC<HighlightedTextProps> = ({ text }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".highlight-text");

    elements.forEach((element) => {
      const html = element.innerHTML;
      const updatedHtml = html.replace(
        // /<(\w+)>/g,
        /<([A-Z-]+)>/g,
        '<span class="highlight">&lt; $1 &gt;</span>'
      );
      element.innerHTML = updatedHtml;
    });
  }, []);

  return (
    <span
      className="highlight-text"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

const modules: optionsType[] = Object.keys(EduateModule).map((key) => ({
  label: toStandardCase(key),
  value: EduateModule[key as keyof typeof EduateModule],
}));

const Index = () => {
  const { user_details } = useLoggedInUserDetails();

  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [templateMessage, setTemplateMessage] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [moduleType, setModuleType] = useState<optionsType | null>(null);
  const [search, setSearch] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [acctId, setAcctId] = useState(0);
  const [moduleSelected, setModuleSelected] = useState<optionsType | null>(
    null
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [tagsModal, setTagsModal] = useState(false);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);

  const [GetTemplates, { data: templatesList }] = useLazyQuery<
    GetMsgInstTemplatesData,
    GetInstMsgTemplatesVars
  >(GetMsgInstTemplates);

  const [GetTemplatesByNode] = useLazyQuery<
    GetInstTemplateByIdData,
    GetTemplateByIdVars
  >(GetInstTemplateById);

  const [DeleteTemplate, { loading: deleteTemplateLoading }] = useMutation(
    DeleteMsgInstTemplate,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [CreateTemplate, { loading: creationLoading }] = useMutation(
    AddMsgInstTemplate,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const [UpdateTemplate, { loading: updationLoading }] = useMutation(
    UpdateMsgInstTemplate,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const [GetTags, { data }] = useLazyQuery<GetSwMsgTagsData, GetSwMsgTagsVars>(
    GetSwMsgTags
  );
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModuleSelected(null);
      setTemplateMessage("");
      setIsDrawerOpen(false);
      setDeleteModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const HandleDelete = (id: number) => {
    DeleteTemplate({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMsgInstTemplates,
          variables: {
            name: search,
            token,
            after: null,
            first: null,
            inst_id: InstId!,
            orderBy: {
              direction: Direction.ASC,
              field: "MSG_TPL_DESC",
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleTemplateClick = (template: string) => {
    const selectedText = window.getSelection()?.toString();
    const updatedMessage =
      selectedText && selectedText.length > 0
        ? templateMessage.replace(selectedText, template)
        : templateMessage + " " + template;
    setTemplateMessage(updatedMessage);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplateMessage(e.target.value);
  };

  const handleTextAreaSelect = () => {
    const selectedText = window.getSelection()?.toString();
    if (selectedText && selectedText.length > 0) {
      messageRef.current?.focus();
    }
  };

  const handleAddTemplate = () => {
    if (operation === Operation.CREATE) {
      CreateTemplate({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input: {
            msg_tpl_idx: 0,
            msg_tpl_desc: templateMessage,
            inst_module: moduleType ? moduleType.label : "",
            inst_type: "S",
          },
        },
        refetchQueries: [
          {
            query: GetMsgInstTemplates,
            variables: {
              name: search,
              token,
              after: null,
              first: null,
              inst_id: InstId!,
              orderBy: {
                direction: Direction.ASC,
                field: "MSG_TPL_DESC",
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Template Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateTemplate({
        variables: {
          token,
          inst_id: InstId,
          id: acctId,
          user_details,
          input: {
            msg_tpl_idx: 0,
            msg_tpl_desc: templateMessage,
            inst_module: moduleType ? moduleType.label : "",
            inst_type: "S",
          },
        },
        refetchQueries: [
          {
            query: GetMsgInstTemplates,
            variables: {
              name: search,
              token,
              after: null,
              first: null,
              inst_id: InstId!,
              orderBy: {
                direction: Direction.ASC,
                field: "MSG_TPL_DESC",
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Template Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const filteredTemplates =
    templatesList &&
    templatesList.GetMsgInstTemplates.edges.filter((template) => {
      return (
        !moduleSelected || template.node.inst_module === moduleSelected.label
      );
    });
  useEffect(() => {
    if (token && InstId) {
      GetTemplates({
        variables: {
          name: search,
          token,
          after: null,
          first: null,
          inst_id: InstId!,
          orderBy: {
            direction: Direction.ASC,
            field: "MSG_TPL_DESC",
          },
        },
      });
    }
  }, [GetTemplates, token, search, InstId]);
  useEffect(() => {
    if (token) {
      GetTags({
        variables: {
          token,
        },
      });
    }
  }, [token, GetTags]);
  useEffect(() => {
    if (operation === Operation.UPDATE && acctId && token) {
      GetTemplatesByNode({
        variables: {
          id: acctId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          setTemplateMessage(data.node.msg_tpl_desc);
          setModuleType({
            label: data.node.inst_module,
            value:
              EduateModule[data.node.inst_module as keyof typeof EduateModule],
          });
        }
      });
    }
  }, [token, GetTemplatesByNode, acctId, operation]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Templates for Message</Title>
      <div className="message-template">
        <div className="message-template__filters row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search Message"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={modules}
              openOnFocus
              value={moduleSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setModuleSelected(newValue as optionsType);
                } else {
                  setModuleSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Under Module"
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <Button
              mode="addnew"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              className="message-template__filters--button"
            />
          </div>
        </div>
        <div className="message-template__datablock">
          <ul>
            {filteredTemplates &&
              filteredTemplates.map((template, index) => {
                return (
                  <li
                    className="message-template__datablock--li"
                    key={template.node.id}
                  >
                    <div className="message-template__datablock--li--message">
                      {`${index + 1})`}&nbsp;
                      {template.node.msg_tpl_desc}
                    </div>
                    <div className="message-template__datablock--li--module">
                      {template.node.inst_module}
                    </div>
                    <div className="message-template__datablock--li--image">
                      <img
                        src={Edit}
                        alt=""
                        onClick={() => {
                          setIsDrawerOpen(!isDrawerOpen);
                          setOperation(Operation.UPDATE);
                          setAcctId(template.node.id);
                        }}
                      />
                      <img
                        src={Delete}
                        alt=""
                        onClick={() => {
                          setAcctId(template.node.id);
                          setDeleteModal(!deleteModal);
                        }}
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <Button onClick={() => setTagsModal(!tagsModal)}>
          <img src={Import} alt="" />
          Import Eduate Templates
        </Button>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <TemplateViewDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {operation === Operation.CREATE
                ? " Add New Template"
                : "Update Template"}
            </Title>

            <div className="row g-0">
              <div className="col">
                <div className="label-grid">
                  <Label>Description</Label>
                  <TextArea
                    textAreaRef={messageRef}
                    rows={3}
                    onChange={handleTextAreaChange}
                    onSelect={handleTextAreaSelect}
                    value={templateMessage}
                  />
                  <Label>Under Module</Label>
                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={modules}
                    openOnFocus
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setModuleType(newValue as optionsType);
                      } else {
                        setModuleType(null);
                      }
                    }}
                    value={moduleType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        fullWidth
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="message-template__predefined-words">
              {data &&
                data.GetSwMsgTags.map((res) => {
                  return (
                    <button onClick={() => handleTemplateClick(res.tag_name)}>
                      {res.tag_name}
                    </button>
                  );
                })}
            </div>
            <Button
              mode="save"
              onClick={handleAddTemplate}
              disabled={!moduleType || !templateMessage}
            />
            <Button
              mode="cancel"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            />
          </div>
        </div>
      </TemplateViewDrawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={tagsModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TagsListImport setModalFlag={setTagsModal} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setTagsModal(!tagsModal)} />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={creationLoading || deleteTemplateLoading || updationLoading}
      />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={acctId}
      />
    </>
  );
};

export default Index;

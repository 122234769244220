import React, { useContext, useEffect, useRef, useState } from "react";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import Avatar from "../../../../images/Avatar.svg";
import useAcdStudentDetails from "../../hooks/useAcdStudentDetails";
import {
  DateRange,
  GetnumberOfDaysInMonth,
  MonthName,
  formatterDecimal,
  getMonthDateAndDay,
  getWeekByDayNum,
  handleDate,
  isOptionEqualToValue,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../hooks/useAcdGeneralHolidays";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  Friday,
  Monday,
  Saturday,
  Sunday,
  Thursday,
  Tuesday,
  Wednesday,
  days,
} from "../../../../utils/constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcdAttStdAbsentDetailsForTheDayData,
  GetAcdAttStdAbsentDetailsForTheDayVars,
  GetAcdAttendanceMonthlyByStdIdData,
  GetAcdAttendanceMonthlyByStdIdVars,
  GetAcdStdAttOverAllVars,
} from "../../types/holidays";
import {
  GetAcdAttStdAbsentDetailsForTheDay,
  GetAcdStdAttOverAll,
  GetAcdStudentAttendanceByStdId,
} from "../../queries/holidays/list";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import {
  UpdateAcdAttStdAbsentDetails,
  UpdateAttendanceForStudents,
} from "../../queries/holidays/mutation";
import { msgType } from "../../../../utils/Form.types";
import {
  AttendanceStatus,
  Direction,
  HolidayType,
  InstConfigKey,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  SortBy,
  UserType,
} from "../../../../utils/Enum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { monthDaysType } from "./MonthlyOverview";
import {
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { AntSwitch } from "../../../../pages/Switch";
import { Button } from "../../../../stories/Button/Button";
import { INCREMENT_DECREMENT } from "../../../Enquiry/Dashboard/Index";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../images/CalenderNextButton.svg";
import dayjs from "dayjs";
import Close from "../../../../images/Close.svg";
import CalendarLegends, {
  CalendarFor,
} from "../../../../pages/CalendarLegends";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import ReactEcharts from "echarts-for-react";
import { TEXT_FONT_FAMILY } from "../../../../styles/DataGridTableStyles";
import { GetAcdStdAttOverAllData } from "../../hooks/useAcdStudentAttendance";
import { Keys } from "../../../../utils/Enum.keys";
import { OptionList } from "../../../UserRights/AssignRights/ChatConfiguration";
import TextArea from "../../../../stories/TextArea/TextArea";
import {
  SoftwareConfigList,
  SoftwareVars,
} from "../../../../Types/configtypes";
import { GetSwConfigReferenceDataByKey } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import AbsentDays from "../../../Master/Student/Dashboard/Academics/AbsentDays";
import Edit from "../../../../images/EditProfile.svg";
import Modal from "react-modal";

import {
  ErrorModalStyles,
  WaiveOffModalStyles,
} from "../../../../styles/ModalStyles";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
import { PerStudentAttDrawer } from "../../../../styles/DrawerStyles";
const emptyDaysByWeek = (week: string) => {
  let numberOfEmpty = -1;
  let numberOfEmptyToLast = -1;
  switch (week) {
    case Sunday:
      numberOfEmpty = 6;
      numberOfEmptyToLast = 0;
      break;
    case Monday:
      numberOfEmpty = 0;
      numberOfEmptyToLast = 6;
      break;
    case Tuesday:
      numberOfEmpty = 1;
      numberOfEmptyToLast = 5;

      break;
    case Wednesday:
      numberOfEmpty = 2;
      numberOfEmptyToLast = 4;
      break;
    case Thursday:
      numberOfEmpty = 3;
      numberOfEmptyToLast = 3;
      break;
    case Friday:
      numberOfEmpty = 4;
      numberOfEmptyToLast = 2;
      break;
    case Saturday:
      numberOfEmpty = 5;
      numberOfEmptyToLast = 1;
      break;
    default:
      break;
  }
  return { numberOfEmpty, numberOfEmptyToLast };
};
interface Props {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  setPerStudentAttendance: React.Dispatch<React.SetStateAction<boolean>>;
  perStudentAttendance: boolean;
  pageType: PageFor;
}
const ViewPerStdAttendance = ({
  selectedDate,
  setSelectedDate,
  setPerStudentAttendance,
  perStudentAttendance,
  pageType,
}: Props) => {
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const [absentiesList, setAbsentiesList] = useState(false);
  const [MarkAttendence, setMarkAttendance] = useState(false);

  const { state } = useContext(AppContext);
  const { studentFormData } = useAcdStudentDetails();
  const { user_details } = useLoggedInUserDetails();
  const { serverDate } = useServerDateandTime();
  const [absentModal, setAbsentModal] = useState(false);
  const [absentRemarksModal, setAbsentRemarksModal] = useState(false);
  const [institutionWorkingtypeData, setInstitutionWorkingTypeData] = useState<
    OptionList[]
  >([]);

  const [reasonSelected, setReasonSelected] = useState<OptionList | null>(null);
  const [comments, setComments] = useState("");
  const [approverSelected, setApproverSelected] = useState<OptionList | null>(
    null
  );

  const [imageString, setImageString] = useState("");
  const [approvedDropDown, setApprovedDropDown] = useState<OptionList[]>([]);

  const { entry_level } = useInstitutionConfiguration();
  const { activeAcademicYearData } = useActiveAcademicYear();
  var generalHolidaysMap = useRef(new Map());
  const numberOfDaysInMonth = GetnumberOfDaysInMonth(selectedDate.toString());
  const { firstDay, lastDay } = DateRange(selectedDate.toString()) || {};
  const [attDate, setAttDate] = useState(selectedDate);
  const [attStatus, setAttStatus] = useState(false);
  const { format } = formatterDecimal;
  const [selectedValue, setSelectedValue] = useState("");

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_TEACHER_TO_EDIT_ATTENDANCE
  );

  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    holiday_query_type.ALL_HOLIDAYS
  );
  const [errorAppearedFromApi, setErrorAppearedFromApi] = useState("");
  const [monthDays, setMonthDays] = useState<monthDaysType[]>();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [presentCount, setPresentCount] = useState(0);

  const { userRights } = useUserRightsByEmpId();

  const isTeacher =
    userRights.data &&
    userRights.data.GetUserRightsByEmpId.pr_emp_details.emp_type ===
      UserType.TEACHER
      ? true
      : false;

  const enableEdit =
    userRights.data && userRights.data.GetUserRightsByEmpId.is_inst_sysadmin
      ? true
      : isTeacher &&
        configData.data &&
        configData.data.GetSwConfigVariables.length &&
        configData.data.GetSwConfigVariables[0].config_boolean_value
      ? true
      : false;
  const [GetConfigData] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey
  );
  const [GetApprovedConfigData] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey);

  const [GetStudentHolidayDetails] = useLazyQuery<
    GetAcdAttendanceMonthlyByStdIdData,
    GetAcdAttendanceMonthlyByStdIdVars
  >(GetAcdStudentAttendanceByStdId, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      cal_month: toIsoDate(firstDay!),
      inst_id: InstId!,
      student_id: state.studentId,
      token,
    },
    onError: (e) => {
      setErrorAppearedFromApi(e.message);
    },
  });

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
    setAbsentModal(!absentModal);
  };
  const [GetAcdStdAbseentDetails, { data: AbsentData }] = useLazyQuery<
    GetAcdAttStdAbsentDetailsForTheDayData,
    GetAcdAttStdAbsentDetailsForTheDayVars
  >(GetAcdAttStdAbsentDetailsForTheDay);

  const [UpdateAttendance, { loading }] = useMutation(
    UpdateAttendanceForStudents,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateAbsentDetails, { loading: AbsentLoading }] = useMutation(
    UpdateAcdAttStdAbsentDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const month = selectedDate.getMonth();
  const year = selectedDate.getFullYear();

  const chooseTypeAndColor = (status: string, day: number) => {
    if (status === AttendanceStatus.P) {
      return {
        type: AttendanceStatus.P,
        description: "",
        class_name: "cal-bg-green",
      };
    } else if (status === AttendanceStatus.A) {
      return {
        type: AttendanceStatus.A,
        description: "",
        class_name: "cal-bg-red",
      };
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
      };
    }
  };
  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;
      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };

  const handleAttendence = () => {
    const selectedDate =
      monthDays &&
      monthDays.find(
        ({ date }) =>
          toStandardDate(date.toString()) === toStandardDate(attDate.toString())
      );
    const dateStatus =
      selectedDate && selectedDate.status !== undefined
        ? selectedDate.status
        : EMPTY_STRING;
    if (!attDate) return;
    UpdateAttendance({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        date_of_attendance: toIsoDate(attDate.toString()),
        input: {
          student_id: state.studentId,
          att_status: attStatus ? AttendanceStatus.P : AttendanceStatus.A,
          comments:
            reasonSelected?.label === "Others"
              ? comments
              : reasonSelected?.value,
          approved_by: approverSelected?.value,
        },
        from_status: dateStatus,
        to_status: attStatus ? AttendanceStatus.P : AttendanceStatus.A,
        entry_id: entryId!,
        entry_level,
      },
      refetchQueries: [
        {
          query: GetAcdStudentAttendanceByStdId,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            cal_month: toIsoDate(firstDay!),
            inst_id: InstId!,
            student_id: state.studentId,
            token,
          },
        },
        {
          query: GetAcdAttStdAbsentDetailsForTheDay,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            student_id: state.studentId,
            date_of_attendance: toIsoDate(attDate.toString()),
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Updated Attendance Status",
          operation: Operation.UPDATE,
        });
      }
    });
    setMarkAttendance(false);
    setAbsentModal(false);
  };
  const handleAbsetAttendance = () => {
    UpdateAbsentDetails({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: {
          date_of_attendance: toIsoDate(attDate.toString()),
          att_status: AttendanceStatus.A,
          comments: comments,
          approved_by: "",
        },

        att_absent_details_id:
          AbsentData && AbsentData.GetAcdAttStdAbsentDetailsForTheDay[0].id,
      },
      refetchQueries: [
        {
          query: GetAcdStudentAttendanceByStdId,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            cal_month: toIsoDate(firstDay!),
            inst_id: InstId!,
            student_id: state.studentId,
            token,
          },
        },
        {
          query: GetAcdAttStdAbsentDetailsForTheDay,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            student_id: state.studentId,
            date_of_attendance: toIsoDate(attDate.toString()),
          },
        },
      ],
    });
    // .then(({ data }) => {
    //   if (data) {
    //     setMessage({
    //       flag: true,
    //       message: "Updated Attendance Status",
    //       operation: Operation.UPDATE,
    //     });
    //   }
    // });
    setMarkAttendance(false);
    setAbsentModal(false);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setPerStudentAttendance(!perStudentAttendance);
      setReasonSelected(null);
      setComments(EMPTY_STRING);
      setApproverSelected(null);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const [GetOverAllAttendance, { data: OverAllData }] = useLazyQuery<
    GetAcdStdAttOverAllData,
    GetAcdStdAttOverAllVars
  >(GetAcdStdAttOverAll, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: entryId ? Number(entryId) : 0,
        entry_level,
        inst_id: state.InstId!,
        cal_month: toIsoDate(firstDay!),
        subj_master_id: 0,
        session_id: 0,
        student_id: state.studentId,
        att_query_type: "OVER_ALL_ATT_PER_STD",
        date_of_attendance: toIsoDate(serverDate),
      },
      token,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      after: null,
      first: null,
      name: EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const presentPercentage = Number(
    OverAllData &&
      OverAllData.GetAcdStdAttOverAll.edges.map((response, index) => {
        return response.node.over_all_per;
      })
  );
  useEffect(() => {
    if (token) {
      GetConfigData({
        variables: { config_key: InstConfigKey.STD_LEAVE_OPTIONS, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          setInstitutionWorkingTypeData(
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              }))
          );
        }
      });
      GetApprovedConfigData({
        variables: { config_key: InstConfigKey.LEAVE_APPROVED_BY, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          setApprovedDropDown(
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              }))
          );
        }
      });
    }
  }, [token, GetConfigData, GetApprovedConfigData]);
  useEffect(() => {
    if (
      token &&
      state.studentId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetAcdStdAbseentDetails({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,

          student_id: state.studentId,
          date_of_attendance: toIsoDate(attDate.toString()),
        },
      });
    }
  }, [
    token,
    state.studentId,
    GetAcdStdAbseentDetails,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
    InstId,
    attDate,
  ]);

  const absencePercentage: number = 100 - Number(presentPercentage);

  const option = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
        crossStyle: {
          color: "#999",
        },
      },
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontFamily: TEXT_FONT_FAMILY,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: `${format(presentPercentage)}`,
            name: "Present",
            itemStyle: {
              color: "#22c55e",
            },
          },
          {
            value: `${format(absencePercentage)}`,
            name: "Absent",
            itemStyle: {
              color: "#ef4444",
            },
          },
        ],
      },
    ],
  };

  useEffect(() => {
    if (token) {
      GetConfigData({
        variables: { config_key: InstConfigKey.STD_LEAVE_OPTIONS, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          // setLeaveTypeDropDown(
          //   data.GetSwConfigReferenceDataByKey.list_item_list
          //     .split(";")
          //     ?.map((d) => ({
          //       label: d?.split(":")[0],
          //       value: d?.split(":")[1],
          //     }))
          // );
        }
      });
      GetApprovedConfigData({
        variables: { config_key: InstConfigKey.LEAVE_APPROVED_BY, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          const existingOptions =
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              })) || [];
          const newOption = {
            label: "Others",
            value: "OTHERS",
          };
          const updatedOptions = [...existingOptions, newOption];
          setApprovedDropDown(updatedOptions);
        }
      });
    }
  }, [token, GetConfigData, GetApprovedConfigData]);
  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.studentId &&
      firstDay &&
      state.ActiveAcdYr
    ) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetStudentHolidayDetails().then(({ data }) => {
        if (data) {
          setPresentCount(
            data.GetAcdStudentAttendanceByStdId.att_total_days_present as number
          );
          const res = Array.from({ length: numberOfDaysInMonth }, (_, i) => {
            const dayNum = i + 1;
            const date = new Date(year, month, dayNum);
            const holiday_details = chooseTypeAndColor(
              (data.GetAcdStudentAttendanceByStdId as Record<string, string>)[
                `day_${dayNum}`
              ],
              dayNum
            );
            const { week } = getMonthDateAndDay(date.toString());

            const week_string = getWeekByDayNum(week);
            return {
              day: dayNum,
              status: holiday_details?.type!,
              description: holiday_details?.description!,
              class_name: holiday_details?.class_name!,
              date: new Date(date),
              week: week_string!,
              holiday_types: [],
              events_length: 0,
            };
          });
          setMonthDays(res);
          setErrorAppearedFromApi("");
        }
      });
    } // eslint-disable-next-line
  }, [
    state.studentId,
    firstDay,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    message.flag,
    InstGeneralHolidays.data,
    state.ActiveAcdYr,
  ]);
  useEffect(() => {
    if (selectedDate) {
      setAttDate(selectedDate);
      setAttStatus(
        monthDays?.find(
          ({ date }) => date.toDateString() === attDate.toDateString()
        )?.status === AttendanceStatus.P
          ? true
          : monthDays?.find(
              ({ date }) => date.toDateString() === attDate.toDateString()
            )?.status === AttendanceStatus.A
          ? false
          : false
      );
    }
  }, [attDate, monthDays, selectedDate]);
  useEffect(() => {
    if (
      entry_level &&
      token &&
      entryId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetOverAllAttendance();
    }
  }, [
    GetOverAllAttendance,
    entry_level,
    token,
    entryId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
  ]);

  useEffect(() => {
    if (studentFormData.std_profile_pic !== "") {
      getDownloadUrl(studentFormData.std_profile_pic, false, setImageString);
    }
  }, [studentFormData.std_profile_pic]);

  return (
    <>
      <div className="attendance-overview__std-att">
        <div className="attendance-overview__std-att--title">
          <Title variant="subtitle1">Per Student Attendance Overview</Title>
          <img
            src={Close}
            alt="/"
            onClick={() => setPerStudentAttendance(!perStudentAttendance)}
          />
        </div>
        <div className="attendance-overview__std-att--datablock row g-0">
          <div className="col attendance-overview__std-att--frame">
            <div className="attendance-overview__std-att--details">
              <div className="attendance-overview__std-att--details--image">
                <img src={imageString ? imageString : Avatar} alt="" />
              </div>
              <div className="col attendance-overview__std-att--details--list">
                <Label>Name</Label>
                <Label>:</Label>
                <Label>{studentFormData.std_name}</Label>
                <Label>Adm No.</Label>
                <Label>:</Label>
                <Label>{studentFormData.adm_no}</Label>
                <Label>Reg No.</Label>
                <Label>:</Label>
                <Label>{studentFormData.reg_number}</Label>
              </div>
            </div>

            <div className="attendance-overview__std-att--cards row g-0">
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Attendance
                </span>
                <span className="attendance-overview__std-att--blocks--attendance">
                  {OverAllData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      ?.total_session}
                </span>
              </div>
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Present
                </span>
                <span className="attendance-overview__std-att--blocks--present">
                  {OverAllData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      .total_session_present}
                </span>
              </div>
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Absent
                </span>
                <span className="attendance-overview__std-att--blocks--absent">
                  {OverAllData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      .total_session_absent}
                </span>
              </div>
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Percentage
                </span>
                <span className="attendance-overview__std-att--blocks--percentage">
                  {OverAllData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      .over_all_per}
                  %
                </span>
              </div>
            </div>
            {OverAllData &&
            OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node.over_all_per ===
              100 ? null : (
              <div className="attendance-overview__std-att--button">
                <Button
                  mode="absent"
                  onClick={() => setAbsentiesList(!absentiesList)}
                >
                  View Absent Days
                </Button>
              </div>
            )}
            <div className="attendance-overview__std-att--percentage">
              <div className="attendance-overview__std-att--percentage--text">
                <b>OverAll Attendance Percentage</b>
                <b className="font-green">{format(presentPercentage)}%</b>
              </div>

              <div className="attendance-overview__std-atd--graph">
                <ReactEcharts option={option} />
              </div>
            </div>
          </div>
          <div className="col attendance-overview__std-att--frame">
            <div className="attendance-overview__std-att--leave-details">
              {pageType === PageFor.GENERAL ? (
                <>
                  <div>
                    <Label>{toStandardDate(attDate.toString())}</Label>
                  </div>
                  <br />
                  <div className="attendance-overview__std-att--leave-details--status">
                    <div className="attendance-overview__std-att--leave-details--text">
                      <span>Attendance Status</span>
                      <b
                        className={
                          monthDays?.find(
                            ({ date }) =>
                              date.toDateString() === attDate.toDateString()
                          )?.status === AttendanceStatus.P
                            ? "attendance-overview__std-att--leave-details--pr"
                            : monthDays?.find(
                                ({ date }) =>
                                  date.toDateString() === attDate.toDateString()
                              )?.status === AttendanceStatus.A
                            ? "attendance-overview__std-att--leave-details--ab"
                            : "attendance-overview__std-att--leave-details--pr"
                        }
                      >
                        {monthDays?.find(
                          ({ date }) =>
                            date.toDateString() === attDate.toDateString()
                        )?.status === AttendanceStatus.P
                          ? "Present"
                          : monthDays?.find(
                              ({ date }) =>
                                date.toDateString() === attDate.toDateString()
                            )?.status === AttendanceStatus.A
                          ? "Absent"
                          : "Mark Attendance"}
                      </b>
                      {enableEdit ? (
                        <img
                          src={Edit}
                          alt=""
                          className="attendance-overview__std-att--leave-details--edit"
                          onClick={() => setMarkAttendance(!MarkAttendence)}
                        />
                      ) : (
                        false
                      )}
                    </div>
                    {monthDays?.find(
                      ({ date }) =>
                        date.toDateString() === attDate.toDateString()
                    )?.status === AttendanceStatus.A && (
                      <div className="attendance-overview__std-att--leave-details--text">
                        <span>Leave Details</span>
                        <b className="attendance-overview__std-att--leave-details--comments">
                          {AbsentData &&
                            AbsentData.GetAcdAttStdAbsentDetailsForTheDay.map(
                              (res) => res.comments
                            )}
                        </b>
                        <img
                          src={Edit}
                          alt=""
                          className="attendance-overview__std-att--leave-details--edit"
                          onClick={() => {
                            setAbsentModal(!absentModal);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : null}
            </div>
            <div className="attendance-overview__std-att--month">
              <img
                src={Previous}
                alt="/"
                onClick={() => {
                  handleDate(
                    selectedDate,
                    setSelectedDate,
                    activeAcademicYearData.data?.GetAcdYrActiveByInstId
                      .acd_st_date!,
                    activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                      .acd_end_date!,
                    setMessage,
                    INCREMENT_DECREMENT.DECREMENT
                  );
                }}
              />

              <Title variant="subtitle1">
                {MonthName(selectedDate.toString())}
              </Title>
              <img
                src={Next}
                alt="/"
                onClick={() => {
                  const nextDate = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth() + 1,
                    selectedDate.getDate()
                  );
                  const serverDateDayjs = dayjs(serverDate);
                  const selectedDateDayjs = dayjs(nextDate);
                  if (
                    serverDateDayjs.isAfter(selectedDateDayjs) &&
                    !serverDateDayjs.isSame(selectedDateDayjs)
                  ) {
                    handleDate(
                      selectedDate,
                      setSelectedDate,
                      activeAcademicYearData.data?.GetAcdYrActiveByInstId
                        .acd_st_date!,
                      activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                        .acd_end_date!,
                      setMessage,
                      INCREMENT_DECREMENT.INCREMENT
                    );
                  } else {
                    setMessage({
                      message: "Cannot Access Future dates",
                      flag: true,
                      operation: Operation.NONE,
                    });
                  }
                }}
              />
            </div>
            {errorAppearedFromApi ? (
              <>
                <br />
                <b className="nodata">{errorAppearedFromApi}</b>
              </>
            ) : (
              <>
                <div className="attendance-overview__std-att--calendar">
                  <ul className="attendance-overview__student-attendance--grid-day">
                    {days.map((day, index: React.Key) => {
                      return (
                        <li
                          key={index}
                          className="attendance-overview__student-attendance--grid-container--day"
                        >
                          {day}
                        </li>
                      );
                    })}
                  </ul>
                  <ul className="attendance-overview__student-attendance--grid-container">
                    {weekCount().map(
                      (
                        { day, status, description, date, class_name },
                        index
                      ) => {
                        return day ? (
                          status === HolidayType.GENERAL ||
                          status === HolidayType.UNEXPECTED ? (
                            <Tooltip
                              title={<>{description}</>}
                              arrow
                              placement="right"
                              key={index}
                            >
                              <li
                                className={`attendance-overview__student-attendance--grid-item ${class_name}`}
                              >
                                <span
                                  className={`attendance-overview__student-attendance--grid-item--date `}
                                >
                                  {day > 0 ? day : ""}
                                </span>
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              key={index}
                              className={`attendance-overview__student-attendance--grid-item ${class_name}`}
                              onClick={() => {
                                if (dayjs(date).isBefore(serverDate)) {
                                  if (
                                    status === AttendanceStatus.P ||
                                    status === AttendanceStatus.A
                                  ) {
                                    setAttDate(date!);
                                    setAttStatus(status === AttendanceStatus.P);
                                  }
                                } else {
                                  setMessage({
                                    flag: true,
                                    message: "Cannot Access Future Dates",
                                    operation: Operation.NONE,
                                  });
                                }
                              }}
                            >
                              <span
                                className={`attendance-overview__student-attendance--grid-item--date `}
                              >
                                {day > 0 ? day : ""}
                              </span>
                            </li>
                          )
                        ) : (
                          <li key={index}></li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className="attendance-overview__std-att--legends">
                  <CalendarLegends
                    useCalendarIn={CalendarFor.PER_STUDENT_ATTENDANCE}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Button mode="cancel" onClick={() => setPerStudentAttendance(false)} />
      </div>

      <LoadingModal flag={loading || AbsentLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <PerStudentAttDrawer
    
        anchor="right"
        open={absentiesList}
        onClose={() => setAbsentiesList(!absentiesList)}
      >
        <AbsentDays setModalFlag={setAbsentiesList} />
      </PerStudentAttDrawer>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={MarkAttendence}
        style={ErrorModalStyles}
      >
        <div className="attendance-overview__std-att--title">
          <Title>
            {absentRemarksModal ? "Absent Remarks" : "Change Attendance Status"}
          </Title>
          <img
            src={Close}
            alt=""
            onClick={() => setMarkAttendance(!MarkAttendence)}
          />
        </div>

        <div className="attendance-overview__std-att--block">
          {!absentRemarksModal ? (
            <>
              <b
                className={
                  monthDays?.find(
                    ({ date }) => date.toDateString() === attDate.toDateString()
                  )?.status === AttendanceStatus.P
                    ? "font-green"
                    : monthDays?.find(
                        ({ date }) =>
                          date.toDateString() === attDate.toDateString()
                      )?.status === AttendanceStatus.A
                    ? "nodata"
                    : ""
                }
              >
                {monthDays?.find(
                  ({ date }) => date.toDateString() === attDate.toDateString()
                )?.status === AttendanceStatus.P
                  ? "Marked as Present"
                  : monthDays?.find(
                      ({ date }) =>
                        date.toDateString() === attDate.toDateString()
                    )?.status === AttendanceStatus.A
                  ? "Marked as Absent"
                  : "Mark Attendance"}
              </b>
              <FormControlLabel
                label={attStatus ? "Present" : "Absent"}
                labelPlacement="bottom"
                control={
                  <AntSwitch
                    checked={attStatus}
                    onClick={() => {
                      setAttStatus(!attStatus);
                      attStatus && setAbsentRemarksModal(!absentRemarksModal);
                    }}
                  />
                }
                className="monthly-attendance-list__view--form-labels"
              />
            </>
          ) : (
            absentRemarksModal && (
              <>
                <Label>Reason</Label>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={institutionWorkingtypeData}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as OptionList, reasonSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) e.preventDefault();
                    if (e.key === Keys.BACKSPACE) setReasonSelected(null);
                  }}
                  openOnFocus
                  value={reasonSelected}
                  onChange={(e, newValue) => {
                    setReasonSelected(newValue as OptionList || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      id="outlined Departments"
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
                {reasonSelected?.label === "Others" && (
                  <>
                    <Label>Comments</Label>
                    <TextArea
                      rows={3}
                      draggable={false}
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      resize="none"
                    />
                  </>
                )}
              </>
            )
          )}
        </div>

        <Button mode="save" onClick={handleAttendence} />
        <Button
          mode="cancel"
          onClick={() => setMarkAttendance(!MarkAttendence)}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={absentModal}
        style={ErrorModalStyles}
      >
        <div className="attendance-overview__std-att--title">
          <Title>Update Attendance</Title>
          <img
            src={Close}
            alt=""
            onClick={() => setAbsentModal(!absentModal)}
          />
        </div>

        <Label>Remarks</Label>
        <TextArea
          rows={2}
          draggable={false}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          resize="none"
        />

        <br />
        <Button mode="save" type="submit" onClick={handleAbsetAttendance} />
        <Button mode="cancel" onClick={() => setAbsentModal(!absentModal)} />
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={absentRemarksModal}
        ariaHideApp={false}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Absent Remarks</Title>
            <Label>Reason</Label>
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={institutionWorkingtypeData}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as OptionList, reasonSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                }
                if (e.key === Keys.BACKSPACE) {
                  setReasonSelected(null);
                }
              }}
              openOnFocus
              value={reasonSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setReasonSelected(newValue as OptionList);
                } else {
                  setReasonSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
            {reasonSelected?.label === "Others" && (
              <>
                <Label>Comments</Label>
                <TextArea
                  rows={3}
                  draggable={false}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  // onKeyDown={(e) => {
                  //   if (e.key === Keys.ENTER) {
                  //     e.preventDefault();
                  //     saveButtonRef.current?.focus();
                  //   }
                  // }}
                  // textAreaRef={remarkRef}
                  resize="none"
                />
              </>
            )}

            <br />
            <Button
              mode="save"
              disabled={reasonSelected?.value ? false : true}
              onClick={() => {
                handleAttendence();
              }}
            />
            <Button
              mode="cancel"
              onClick={() => setAbsentRemarksModal(!absentRemarksModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setAbsentRemarksModal(!absentRemarksModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewPerStdAttendance;

import { TextField } from "@mui/material";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import Avatar from "../../../../images/Avatar.svg";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";

const EmployeeDetails = () => {
  const { employeeFormData } = useEmpDetailsById();
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  return (
    <div className="row employee-details__basic-details">
      <div className="col">
        <TextField
          id="outlined-uncontrolled"
          className="preview__details--textfield"
          label="Employee ID."
                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          disabled
          value={employeeFormData.emp_id}
        />
        {USE_HR_DESIGNATION && (
          <TextField
            id="outlined-uncontrolled"
            className="preview__details--textfield"
            label="Designation"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            disabled
            value={employeeFormData.designation}
          />
        )}

        {USE_HR_JOBTYPE && (
          <TextField
            id="outlined-uncontrolled"
            className="preview__details--textfield"
            label="Job Type"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={employeeFormData.job_type}
            disabled
          />
        )}
      </div>
      <div className="col">
        <TextField
          id="outlined-uncontrolled"
          className="preview__details--textfield"
          label="Name"
                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          disabled
          value={employeeFormData.emp_name}
        />
        {USE_HR_DEPARTMENT && (
          <TextField
            id="outlined-uncontrolled"
            className="preview__details--textfield"
            label="Department"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            disabled
            value={employeeFormData.department}
          />
        )}
        <TextField
          id="outlined-uncontrolled"
          className="preview__details--textfield"
          label="Qualification"
                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          disabled
          value={employeeFormData.emp_qualification}
        />
      </div>
      <div className="col">
        {USE_HR_CATEGORY && (
          <TextField
            id="outlined-uncontrolled"
            className="preview__details--textfield"
            label="Category"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            disabled
            value={employeeFormData.category}
          />
        )}
        <TextField
          id="outlined-uncontrolled"
          className="preview__details--textfield"
          label="Date of Joining"
                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          disabled
          value={toStandardDate(employeeFormData.emp_doj!)}
        />
        <TextField
          id="outlined-uncontrolled"
          className="preview__details--textfield"
          label="Year of Experience"
                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          disabled
          value={employeeFormData.emp_experience}
        />
      </div>
      <div className="col-1">
        <div className="employee-details__basic-details--image">
          <img src={Avatar} alt="/" />
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;

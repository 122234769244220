import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { SettingsTitleProps } from "../../../../Types/Titles";
import Home from "../../../Master/configurations/Home/Index";
import Add from "../../../../images/Add.svg";

import Delete from "../../../../images/Delete.svg";
import EditProfile from "../../../../images/EditProfile.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  EMPTY_STRING,
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../../utils/constants";
import { TablePaginationActions } from "../../../../pages/CustomTablePagination";
import { defaultLabelDisplayedRows } from "../../../../utils/UtilFunctions";
import Modal from "react-modal";
import { PredefinedDataStyles } from "../../../../styles/ModalStyles";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import Close from "../../../../images/Close.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddMstInstReferenceData,
  AddMstInstReferenceDataTypeOnly,
} from "../../../../queries/institution/PreDefined/mutations/new";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { GetMstInstReferenceDataTypes } from "../../../../queries/institution/PreDefined/List/byDataType";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, optionsType } from "../../../../utils/Form.types";
import { Operation } from "../../../../utils/Enum.types";
import {
  PredefinedDataTypeData,
  PredefinedDataTypevars,
} from "../../../../Types/Settings";
import {
  GetMstInstReferenceDataByType,
  GetMstInstReferenceDataByTypeId,
} from "../../../../queries/institution/list/byId";
import {
  GetMstInstReferenceDataByTypeDetails,
  GetMstInstReferenceDataByTypeVars,
} from "../../../../customhooks/useInstReferenceDataByType";
import { UpdateMstInstReferenceDataById } from "../../../../queries/institution/PreDefined/mutations/update";
import { DeleteMstInstReferenceDataById } from "../../../../queries/institution/PreDefined/mutations/delete";
import DeleteModal from "../../../../pages/DeleteModal";
import { Keys } from "../../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const { Settings } = require("../../../../json/title.json");
const { Settings_Table } = require("../../../../json/table.json");

const PredefinedData = () => {
  const [addDataTypeModal, setAddDataTypeModal] = useState(false);
  const [addReferenceModal, setAddReferenceModal] = useState(false);
  const [typeSelected, setTypeSelected] = useState("");
  const [dataType, setDataType] = useState("");
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const [operation, setOperation] = useState(Operation.CREATE);
  const navigate = useNavigate();
  interface preDefinedValues {
    value1: string;
    value2: string;
  }
  const [formData, setFormData] = useState<preDefinedValues>({
    value1: "",
    value2: "",
  });
  const [preDefinedId, setPreDefinedId] = useState(0);

  const [GetPredefinedList, { data: PredefinedDataList }] = useLazyQuery<
    PredefinedDataTypeData,
    PredefinedDataTypevars
  >(GetMstInstReferenceDataTypes, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  const [GetPredefinedData, { data: PredefinedData }] = useLazyQuery<
    GetMstInstReferenceDataByTypeDetails,
    GetMstInstReferenceDataByTypeVars
  >(GetMstInstReferenceDataByType, {
    variables: {
      token,
      inst_id: InstId!,
      type: typeSelected,
    },
  });
  const [GetPreDefinedDataByNode] = useLazyQuery(
    GetMstInstReferenceDataByTypeId
  );

  const preDefinedDropDown =
    PredefinedDataList?.GetMstInstReferenceDataTypes.map((data) => data);
  const setFormDataForUpdate = (id: number) => {
    GetPreDefinedDataByNode({
      variables: {
        token,
        id,
      },
    }).then(({ data }) => {
      if (data) {
        setFormData({
          value1: data.node.value1,
          value2: data.node.value2,
        });
      }
    });
  };
  const [AddPredefinedDataType, { loading: updationLoading }] = useMutation(
    AddMstInstReferenceDataTypeOnly,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [AddPredefinedData, { loading: additionLoading }] = useMutation(
    AddMstInstReferenceData,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [EditPredefinedData, { loading: editLoading }] = useMutation(
    UpdateMstInstReferenceDataById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeletePredefinedData, { loading: deletionLoading }] = useMutation(
    DeleteMstInstReferenceDataById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const value1Ref = useRef<HTMLInputElement>(null);
  const value2Ref = useRef<HTMLButtonElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  const HandleSubmit = () => {
    AddPredefinedDataType({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        data_type: dataType,
      },
      refetchQueries: [
        {
          query: GetMstInstReferenceDataTypes,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Pre-Defined DataType Added Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setAddDataTypeModal(false);
  };
  const HandlePreDefinedSubmit = () => {
    if (operation === Operation.CREATE) {
      AddPredefinedData({
        variables: {
          token,
          inst_id: InstId,
          input: {
            type: typeSelected,
            value1: formData.value1,
            value2: formData.value2,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetMstInstReferenceDataByType,
            variables: {
              token,
              inst_id: InstId!,
              type: typeSelected,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Pre-Defined Data Values Added Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
      setAddReferenceModal(false);
      setFormData({
        value1: "",
        value2: "",
      });
    }

    if (operation === Operation.UPDATE) {
      EditPredefinedData({
        variables: {
          token,
          id: preDefinedId,
          input: {
            type: typeSelected,
            value1: formData.value1,
            value2: formData.value2,
          },
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetMstInstReferenceDataByType,
            variables: {
              token,
              inst_id: InstId!,
              type: typeSelected,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Pre-Defined Data Values Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
      setAddReferenceModal(false);
      setFormData({
        value1: "",
        value2: "",
      });
    }
  };

  const HandleDelete = () => {
    setDeleteModal(!deleteModal);
    DeletePredefinedData({
      variables: {
        token,
        id: preDefinedId,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstReferenceDataByType,
          variables: {
            token,
            inst_id: InstId!,
            type: typeSelected,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Pre-Defined Data Values Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setAddReferenceModal(false);
    setFormData({
      value1: "",
      value2: "",
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    setAddDataTypeModal(false);
  };
  const handleClear = () => setTypeSelected("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (token) {
      GetPredefinedList();
    }
  }, [token, GetPredefinedList]);
  useEffect(() => {
    if (token && InstId) {
      GetPredefinedData();
    }
  }, [token, InstId, GetPredefinedData]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Settings.CustomerPredefinedData.map(
          (title: SettingsTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.List}</React.Fragment>;
          }
        )}
      </Title>
      <div className="customer-predefined-data">
        <div className="row g-0">
          <div className="col-5">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={preDefinedDropDown!}
              value={typeSelected!}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTypeSelected(newValue as string);
                } else {
                  setTypeSelected(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setTypeSelected(EMPTY_STRING);
                }
              }}
              openOnFocus
              forcePopupIcon
              freeSolo
              popupIcon={<img src={DownArrow} alt="/" />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={labelClasses.formControlRoot}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Select Reference Type"
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-3 customer-predefined-data__button">
            <Button
              onClick={() => {
                setAddReferenceModal(!addReferenceModal);
                setOperation(Operation.CREATE);
              }}
            >
              <img src={Add} alt="/" />
              Add Reference Data
            </Button>
          </div>
        </div>
        <div className="row g-0 customer-predefined-data__tableblock">
          <div className="col-5 h-100">
            <Title variant="subtitle1">Authorizer</Title>
            <TableContainer className="customer-predefined-data__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Settings_Table.CustomerPredefinedData.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <TableCell>{th.labelName}</TableCell>
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PredefinedData?.GetMstInstReferenceDataByType.filter(
                    (data) => data.value1 !== EMPTY_STRING
                  ).map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell id="td-center">{index + 1}</TableCell>
                        <TableCell>{data.value1}</TableCell>
                        <TableCell>{data.value2}</TableCell>
                        <TableCell id="td-center">
                          <img
                            src={EditProfile}
                            alt="/"
                            onClick={() => {
                              setPreDefinedId(data.id);
                              setAddReferenceModal(!addReferenceModal);
                              setFormDataForUpdate(data.id);
                              setOperation(Operation.UPDATE);
                            }}
                          />
                          &nbsp;
                          <img
                            src={Delete}
                            alt="/"
                            onClick={() => {
                              setPreDefinedId(data.id);
                              setDeleteModal(!deleteModal);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={PAGINATION_ARRAY}
                      count={totalCount ? totalCount : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      labelRowsPerPage={TABLE_DATA_PER_PAGE}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
        <Button onClick={() => setAddDataTypeModal(!addDataTypeModal)}>
          <img src={Add} alt="/" />
          Add New Reference Type
        </Button>

        <Button mode="clear" onClick={handleClear} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal
        flag={
          updationLoading || additionLoading || editLoading || deletionLoading
        }
      />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={preDefinedId}
      />

      {/*add predefined data */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addReferenceModal}
        style={PredefinedDataStyles}
      >
        <div className="modal-flex">
          <div className="modal-flex__data h-100">
            <Title>
              {Settings.CustomerPredefinedData.map(
                (title: SettingsTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {operation === Operation.CREATE
                        ? title.Add
                        : title.Update}
                    </React.Fragment>
                  );
                }
              )}
            </Title>

            <div className="label-grid">
              <Label>Reference Type</Label>
              <Input disabled value={typeSelected} />

              <Label> Value1</Label>
              <Input
                value={formData.value1}
                onChange={(e) =>
                  setFormData((prevValues) => ({
                    ...prevValues,
                    value1: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    value1Ref?.current?.focus();
                  }
                }}
              />
              <Label>Value2</Label>
              <Input
                value={formData.value2}
                inputRef={value1Ref!}
                onChange={(e) =>
                  setFormData((prevValues) => ({
                    ...prevValues,
                    value2: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    value2Ref?.current?.focus();
                  }
                }}
              />
            </div>
            <Button
              mode="save"
              buttonref={value2Ref!}
              onClick={HandlePreDefinedSubmit}
            />

            <Button mode="clear" />
            <Button
              mode="cancel"
              onClick={() => setAddReferenceModal(!addReferenceModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddReferenceModal(!addReferenceModal)}
            />
          </div>
        </div>
      </Modal>
      {/* add new predefined type */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addDataTypeModal}
        style={PredefinedDataStyles}
      >
        <div className="modal-flex">
          <div className="modal-flex__data h-100">
            <Title>
              {Settings.CustomerPredefinedType.map(
                (title: SettingsTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>{title.Add}</React.Fragment>
                  );
                }
              )}
            </Title>

            <div className="label-grid">
              <Label>Description</Label>

              <Input
                value={dataType}
                onChange={(e) => setDataType(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    saveRef?.current?.focus();
                  }
                }}
              />
            </div>
            <Button mode="save" buttonref={saveRef!} onClick={HandleSubmit} />
            <Button mode="clear" />
            <Button
              mode="cancel"
              onClick={() => setAddDataTypeModal(!addDataTypeModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddDataTypeModal(!addDataTypeModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PredefinedData;

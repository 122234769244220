import { CSSProperties } from "react";
import { NUMBER_FONT_FAMILY, TEXT_FONT_FAMILY } from "./DataGridTableStyles";
import { styled } from "@mui/material";

const commonHeaderStyles: CSSProperties = {
  position: "sticky",
  backgroundColor: "var(--level-8)",
  padding: "0px !important",
  color: "white !important",
  textAlign: "center",
  fontFamily: TEXT_FONT_FAMILY,
  borderRight: "1px solid var(--border-color) !important",
  height: "25px",
  fontSize: "14px !important",
  fontStyle: "normal",
  zIndex: 0,
  top: 0,
  fontWeight:400
};

const stickyColumnStyles: CSSProperties = {
  position: "sticky",
  zIndex: 0,
  backgroundColor: "var(--level-1)",
  borderRight: "1px solid var(--border-color) !important",
  borderBottom: "1px solid var(--border-color) !important",
  padding: "5px 3px !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px !important",
};
const commonColumnStyles: CSSProperties = {
  padding: "5px 3px !important",
  zIndex: 0,
  backgroundColor: "var(--level-1)",
  borderRight: "1px solid var(--border-color) !important",
   borderBottom: "1px solid var(--border-color) !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px important",
};
const columnWithNumberFont: CSSProperties = {
  fontFamily: NUMBER_FONT_FAMILY,
};

const stickyFooterTotal: CSSProperties = {
  position: "sticky",
  bottom: 0,
  left: 0,
  padding: "0px 0px !important",
  textAlign: "right",
  fontWeight: "700 !important",
  color: "var(--text-color) !important",
  background: "var(--bg-color) !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px !important",
  zIndex: 2,
  height: 25,
};

const stickyFooterAmount: CSSProperties = {
  ...columnWithNumberFont,
  position: "sticky",
  bottom: 0,
  zIndex: 0,
  padding: "0px 3px !important",
  color: "var(--text-color) !important",
  background: "#4ade80 !important",
  borderRight: "1px solid white",
  fontSize: "14px !important",
  fontWeight: 700,
};

// HR Reports Table




export const TableStyled = styled("table")({
  height: "calc(100% - 10px)",
  tableLayout: "fixed",
  width:"100% !important"
});

export const StickyHeader = styled("th")({
  ...commonHeaderStyles,
  overflow: "hidden",
  minWidth: 100,
  maxWidth: 100,
});

export const StickyHeaderSl = styled("th")({
  ...commonHeaderStyles,
  zIndex: 998,
  minWidth: 60,
  maxWidth: 60,
  left: 0,
});

export const StickyHeaderAdmNo = styled("th") ({
  ...commonHeaderStyles,
  zIndex: 998,
  minWidth: 100,
  maxWidth: 100,
  left: 60,
});

export const StickyHeaderRegNo = styled("th")({
  ...commonHeaderStyles,
  zIndex: 998,
  minWidth: 120,
  maxWidth: 120,
  left: 160,
});

export const StickyHeaderName = styled("th")({
  ...commonHeaderStyles,
  zIndex: 998,
  minWidth: 150,
  maxWidth: 150,
  left: 280,
});

export const StickyHeaderLedgerName=styled("th")({
  ...commonHeaderStyles,
  overflow: "hidden",
  minWidth: 180,
  maxWidth: 180,
  textOverflow: "ellipsis",
});


export const StickyHeaderAmount=styled("th") ({
  ...commonHeaderStyles,
  overflow: "hidden",
  minWidth: 150,
  maxWidth: 150,
});


export const StickyColumnSl=styled("td") ({
  ...stickyColumnStyles,
  ...columnWithNumberFont,
  left: 0,
  minWidth: 60,
  maxWidth: 60,
});

export const  StickyColumnAdmno=styled("td") ({
  ...stickyColumnStyles,
  ...columnWithNumberFont,
  left: 60,
  minWidth: 100,
  maxWidth: 100,
});

export const StickyColumnRegno=styled("td")({
  ...stickyColumnStyles,
  ...columnWithNumberFont,
  left: 160,
  minWidth: 120,
  maxWidth: 120,
});

export const StickyColumnName=styled("td") ({
  ...stickyColumnStyles,
  left: 280,
  minWidth: 150,
  maxWidth: 150,
});

export const   StickyColumnCName=styled("td")({
  ...commonColumnStyles,
  minWidth: 100,
  maxWidth: 100,
  fontSize: 14,
  zIndex: 0,
});

export const   StickyColumnLedgerName=styled("td")({
  ...commonColumnStyles,
  minWidth: 280,
  maxWidth: 280,
  fontSize: 14,
  zIndex: 0,
  textOverflow: "ellipsis",
});

export const StickyColumnAmount=styled("td")( {
  ...commonColumnStyles,
  ...columnWithNumberFont,
  minWidth: 150,
  maxWidth: 150,
  fontSize: 14,
  zIndex: 0,
});

export const StickyColumnFooterTotal=styled("td") ({
  ...stickyFooterTotal,
  minWidth: 410,
  maxWidth: 410,
});
export const StickyColumnFooter=styled("td")({
  ...stickyFooterAmount,
  minWidth: 150,
  maxWidth: 150,
});

export const StickyColumn=styled("td")({
  ...commonColumnStyles,
  minWidth: 120,
  maxWidth: 120,
  fontSize: 14,
  zIndex: 0,
});


export const StickyHeaderTotal=styled("td") ({
  ...commonHeaderStyles,
position: "sticky",
  zIndex: 998,
  right: 200,
  maxWidth: 100,
  minWidth: 100,
});
export const StickyHeaderPercentage=styled("td")({
  ...commonHeaderStyles,
position: "sticky",
  zIndex: 998,
  right: 100,
  maxWidth: 100,
  minWidth: 100,
});

export const StickyHeaderRemarks=styled("td")({
  ...commonHeaderStyles,
position: "sticky",
  zIndex: 998,
  right: 0,
  maxWidth: 100,
  minWidth: 100,
});

export const StickyColumnTotal=styled("td") ({
  ...stickyColumnStyles,
  right: 200,
  zIndex: 0,
  maxWidth: 100,
  minWidth: 100,
  background: "#4ade80 !important",
});
export const StickyColumnPercentage=styled("td")( {
  ...stickyColumnStyles,
  right: 100,
  zIndex: 0,
  maxWidth: 100,
  minWidth: 100,
  background: "#4ade80 !important",
});
export const StickyColumnRemarks=styled("td")( {
  ...stickyColumnStyles,
  right: 0,
  zIndex: 0,
  maxWidth: 100,
  minWidth: 100,
});



export const StickySubHeader=styled("td") ({
  backgroundColor: "var(--bg-color) !important",
  ...commonHeaderStyles,
  position: "sticky",
  top: 42,
  zIndex: 0,
  height: 25,
});
export const StickySubHeaderTotal=styled("td")( {
  backgroundColor: "var(--bg-color) !important",
  ...commonHeaderStyles,
  position: "sticky",
  top: 42,
  zIndex: 998,
  right: 200,
  height: 25,
  maxWidth: 100,
  minWidth: 100,
});
export const StickyColumStatus=styled("td")( {
  ...stickyColumnStyles,
  right: 0,
  zIndex: 0,
  maxWidth: 100,
  minWidth: 100,
});

export const StickyHeaderStatus=styled("td")({
  ...commonHeaderStyles,
position: "sticky",
  zIndex: 998,
  right: 0,
  maxWidth: 100,
  minWidth: 100,
});

export const StickyHeaderNameInExcel = styled("th")({
  ...commonHeaderStyles,
  zIndex: 998,
  minWidth: 150,
  maxWidth: 150,
  left: 160,
});

export const StickyColumnNameInExcel=styled("td") ({
  ...stickyColumnStyles,
  left: 160,
  minWidth: 150,
  maxWidth: 150,
});


export const StickyHeaderSubject = styled("th")({
  ...commonHeaderStyles,
  zIndex: 998,
  minWidth: 150,
  maxWidth: 150,
  left: 60,
});

export const StickyColumnSubject=styled("td") ({
  ...stickyColumnStyles,
  left: 60,
  minWidth: 150,
  maxWidth: 150,
});



export const StickySubHeader1=styled("td") ({
  ...commonHeaderStyles,
  position: "sticky",
  top: 60,
  zIndex: 0,
  height: 30,
});
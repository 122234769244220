import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import usePayRollMastersConfig from "../../../Modules/HR/hooks/usePayRollMastersConfig";
import {
  InstitutionConfigurationTypes,
  LicenseTypes,
  UserType,
} from "../../../utils/Enum.types";
import BreadCrumb from "../BreadCrumb/index";
import Header from "../Header/index";
import {
  AttEntryMethodEnum,
  PayRollConfigKeys,
} from "../../../Modules/HR/enums/Enum.types";
import useUserRightsByEmpId from "../../../Modules/UserRights/hooks/useUserRightsByEmpId";
import { InstitutionAcctConfigurationTypes } from "../../../Modules/Accounts/common/Enum.types";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useActiveInstModules from "../../../Modules/UserRights/hooks/useActiveInstModules";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import StudentDetails from "../../../images/StudentListHeader.svg";
import Active from "../../../images/Active.svg";
import Planning from "../../../images/AllLogs.svg";
import Calendar from "../../../images/Calender_white.svg";
import Master from "../../../images/Masterdata.svg";
import Detailed from "../../../images/DetailedReportHeader.svg";
import Report from "../../../images/GraphView.svg";
import Settings from "../../../images/Configure.svg";
import Agent from "../../../images/Agent.svg";

import Assign from "../../../images/Assign.svg";
import Add from "../../../images/Add.svg";
import Deposit from "../../../images/Deposit.svg";
import FeeReceipt from "../../../images/ReceiptVoucher.svg";
import OtherReceipts from "../../../images/PrintPreviousReceipt.svg";
import NonDemand from "../../../images/NonDemandReceipt.svg";
import Voucher from "../../../images/TransferReceipt.svg";
import Demand from "../../../images/Demand.svg";
import OtherReport from "../../../images/CreateLedger.svg";
import AccountDetails from "../../../images/Final Book.svg";
import Delete from "../../../images/DeleteButton.svg";
import Excel from "../../../images/Upload.svg";
import Identifier from "../../../images/Select.svg";
import Section from "../../../images/Arrear.svg";
import Template from "../../../images/MessageTemplate.svg";
import IdCard from "../../../images/IDCardTemplate.svg";
import Documents from "../../../images/DocumentsHeader.svg";
import Department from "../../../images/DepartmentHeader.svg";
import BookTransaction from "../../../images/BookTransactionHeader.svg";
import Allocation from "../../../images/BooksAllocationHeader.svg";
import NewBook from "../../../images/NewBooksHeader.svg";
import Fine from "../../../images/CashReceipts.svg";
import Attendance from "../../../images/AllLogs.svg";
import User from "../../../images/Avatar.svg";
import Log from "../../../images/LogSheet.svg";
import Posting from "../../../images/PostingIcon.svg";
import Organization from "../../../images/TreeView.svg";
import Chart from "../../../images/ChartHeader.svg";
import CustomerImage from "../../../images/Customer.svg";
import Campus from "../../../images/Institution.svg";

import AcBooks from "../../../images/Miscellaneous_Activity.svg";
import Challans from "../../../images/Challan.svg";
import Export from "../../../images/Excel.svg";
import { clearGlobalStates } from "../../../utils/UtilFunctions";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../../Modules/UserRights/hooks/useAssignedInstbyEmpId";
interface NavProps {
  NavType: LicenseTypes;
  TeacherDashboard?: boolean;
  AdminDashboard?: boolean;
}

interface MenuItemsProps {
  menu?: string | number;
  url?: string;
  title: string;
  submenu: string[];
}

interface SubMenuProps {
  title: string;
  url: string;
}

interface SubHeaderProps {
  title: string;
  image: string;
  className: string;
  subMenu: SubMenuProps[];
}

interface MainHeaderProps {
  title: string;
  subHeader: SubHeaderProps[];
}

interface MenuItemsProps {
  mainHeader: MainHeaderProps[];
}

const Navbar = ({ NavType, TeacherDashboard }: NavProps) => {
  const { InstId, custId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useContext(AppContext);

  const { USE_SECTION_KEY, USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { USE_ACCOUNTS, USE_ADMISSIONS } = useUserRightsByEmpId();

  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_STUDENT_LOGIN,
    InstitutionConfigurationTypes.ENABLE_SCHOLARSHIP,
    InstitutionConfigurationTypes.ENABLE_PARENT_LOGIN,
    InstitutionConfigurationTypes.ENABLE_EMPLOYEE_LOGIN,
    PayRollConfigKeys.ATT_ENTRY_METHOD,
    InstitutionAcctConfigurationTypes.ALLOW_CHANGE_OF_BRANCH,
    InstitutionConfigurationTypes.EDIT_STD_BRANCH_DETAILS,
    InstitutionConfigurationTypes.CREATE_ANONYMOUS_STUDENT,
    InstitutionAcctConfigurationTypes.ENABLE_MAKER_CHECKER,
    InstitutionAcctConfigurationTypes.PAYMENT_GATEWAY,
    InstitutionAcctConfigurationTypes.ENABLE_APPLICATION_FEE,
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE,
    InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT,
    InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT,
    PayRollConfigKeys.ENABLE_PAYROLL_PROCESS,
    InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE,
    InstitutionConfigurationTypes.ENABLE_SUBJECT_GROUPING,
    InstitutionConfigurationTypes.ENABLE_PER_STD_SUBJECT_ALLOCATION,
    InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE,
    InstitutionAcctConfigurationTypes.ENABLE_FEED_BACK,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableStudentLogin = false;
    let enableEmpLogin = false;
    let enableScholarship = false;
    let enableParentLogin = false;
    let enableMkCk = false;
    let enablePaymentGateWay = false;
    let enableEnqAppFee = false;
    let enableSessionAtttendance = false;
    let enableChallanReceipt = false;
    let enableFeeReceipt = false;
    let enablePayRollProcess = false;
    let allowChangeOfBranch = false;
    let allowChangeOfBranchinAdm = false;
    let createAnonymousStudents = false;
    let hideCaptationFee = false;
    let enableFeeFine = false;
    let enableSubjectGrouping = false;
    let enablePerStdSubAllocation = false;
    let enableFeedBackForm = false;

    let attEntryMethod = "";

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ENABLE_STUDENT_LOGIN:
            enableStudentLogin = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_EMPLOYEE_LOGIN:
            enableEmpLogin = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_SCHOLARSHIP:
            enableScholarship = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_PARENT_LOGIN:
            enableParentLogin = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_MAKER_CHECKER:
            enableMkCk = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.PAYMENT_GATEWAY:
            enablePaymentGateWay = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_APPLICATION_FEE:
            enableEnqAppFee = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE:
            enableSessionAtttendance = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT:
            enableChallanReceipt = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT:
            enableFeeReceipt = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ALLOW_CHANGE_OF_BRANCH:
            allowChangeOfBranch = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.EDIT_STD_BRANCH_DETAILS:
            allowChangeOfBranchinAdm = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.CREATE_ANONYMOUS_STUDENT:
            createAnonymousStudents = item.config_boolean_value;
            break;
          case PayRollConfigKeys.ENABLE_PAYROLL_PROCESS:
            enablePayRollProcess = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE:
            hideCaptationFee = item.config_boolean_value;
            break;
          case PayRollConfigKeys.ATT_ENTRY_METHOD:
            attEntryMethod = item.config_string_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE:
            enableFeeFine = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_SUBJECT_GROUPING:
            enableSubjectGrouping = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_PER_STD_SUBJECT_ALLOCATION:
            enablePerStdSubAllocation = item.config_boolean_value;
            break;

          case InstitutionConfigurationTypes.ENABLE_FEED_BACK:
            enableFeedBackForm = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enableStudentLogin,
      enableEmpLogin,
      enableScholarship,
      enableParentLogin,
      enableMkCk,
      enablePaymentGateWay,
      enableEnqAppFee,
      enableSessionAtttendance,
      enableChallanReceipt,
      enablePayRollProcess,
      enableFeeReceipt,
      allowChangeOfBranch,
      allowChangeOfBranchinAdm,
      createAnonymousStudents,
      hideCaptationFee,
      attEntryMethod,
      enableFeeFine,
      enableSubjectGrouping,
      enablePerStdSubAllocation,
      enableFeedBackForm,
    };
  };

  const {
    enableStudentLogin,
    enableEmpLogin,
    enableScholarship,
    enableParentLogin,
    enableMkCk,
    enablePaymentGateWay,
    enableEnqAppFee,
    enableSessionAtttendance,
    enableChallanReceipt,
    enablePayRollProcess,
    enableFeeReceipt,
    allowChangeOfBranch,
    allowChangeOfBranchinAdm,
    createAnonymousStudents,
    hideCaptationFee,
    attEntryMethod,
    enableFeeFine,
    enableSubjectGrouping,
    enablePerStdSubAllocation,
    enableFeedBackForm,
  } = filterDataByConfigKey(
    configData.data ? configData.data.GetSwConfigVariables : []
  );

  const { USE_TRANSPORT } = useActiveInstModules();

  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();

  const { branchLabel, classLabel, categoryLabel, sectionLabel } =
    useInstLabels();
  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const isAdminExecutive =
    MultipleInstitutions &&
    MultipleInstitutions.data &&
    MultipleInstitutions.data.GetInstUserAccess.edges.length &&
    MultipleInstitutions.data.GetInstUserAccess.edges[0].node.pr_emp_details
      .emp_type === UserType.ADM_EXECUTIVE;

  const Admissions = [
    isAdminExecutive
      ? {
          mainHeader: [
            {
              title: "Students",
              subHeader: [
                {
                  title: "Student Details",
                  image: `${StudentDetails}`,
                  className: "image-white",
                  subMenu: [
                    {
                      title: "Reservation",
                      url: `/${InstId}/admissions/students/reservations`,
                    },
                  ],
                },
              ],
            },
          ],
        }
      : {
          mainHeader: [
            {
              title: "Students",
              subHeader: [
                {
                  title: "Student Details",
                  image: `${StudentDetails}`,
                  className: "image-white",
                  subMenu: [
                    {
                      title: "Students List",
                      url: `/${InstId}/admissions/students`,
                    },

                    {
                      title: "Reservation",
                      url: `/${InstId}/admissions/students/reservations`,
                    },
                    {
                      title: "Alumni",
                      url: `/${InstId}/admissions/students/alumni`,
                    },
                    {
                      title: "Upload Bulk Profile Picture",
                      url: `/${InstId}/admissions/students/bulk_profile_pic`,
                    },
                    ...(USE_ADMISSIONS.details?.view &&
                    !USE_ADMISSIONS.details?.add
                      ? []
                      : [
                          {
                            title: "Student Status",
                            url: `/${InstId}/admissions/students/makeeligible`,
                          },

                          ...(enableStudentLogin
                            ? [
                                {
                                  title: "Login Status",
                                  url: `/${InstId}/admissions/students/enableLogin`,
                                },
                              ]
                            : []),
                          {
                            title: "Upload Documents",
                            url: `/${InstId}/admissions/students/documents`,
                          },
                          ...(enableStudentLogin
                            ? [
                                {
                                  title: "Reset Student Login Password",
                                  url: `/${InstId}/admissions/students/resetpassword`,
                                },
                              ]
                            : []),
                          ...(USE_TRANSPORT
                            ? [
                                {
                                  title: "Student Services",
                                  url: `/${InstId}/admissions/students/services`,
                                },
                              ]
                            : []),
                        ].filter(Boolean)),
                  ],
                },

                {
                  title: "Student Enroll",
                  image: `${Identifier}`,
                  subMenu: [
                    ...(USE_ADMISSIONS.details?.view &&
                    !USE_ADMISSIONS.details?.add
                      ? []
                      : [
                          {
                            title: "Registration Number",
                            url: `/${InstId}/admissions/students/allotregno`,
                          },
                          {
                            title: "Admission Number",
                            url: `/${InstId}/admissions/students/editadmissionnumber`,
                          },
                          {
                            title: "Roll Number",
                            url: `/${InstId}/admissions/students/allotrollno`,
                          },
                        ].filter(Boolean)),
                  ],
                },
                {
                  title: `${sectionLabel} & ${categoryLabel}`,
                  image: `${Section}`,
                  className: "image-white",
                  subMenu: [
                    ...(USE_SECTION_KEY &&
                    !(
                      USE_ADMISSIONS.details?.view &&
                      !USE_ADMISSIONS.details?.add
                    )
                      ? [
                          {
                            title: `${sectionLabel}`,
                            url: `/${InstId}/admissions/students/allocatesection`,
                          },
                        ]
                      : []),
                    ...(USE_CATEGORY_KEY &&
                    !(
                      USE_ADMISSIONS.details?.view &&
                      !USE_ADMISSIONS.details?.add
                    )
                      ? [
                          {
                            title: `Interchange ${categoryLabel}`,
                            url: `/${InstId}/admissions/students/interchangeCategory`,
                          },
                        ]
                      : []),
                    ...(allowChangeOfBranchinAdm
                      ? [
                          {
                            title: `Change ${branchLabel}/${classLabel}/${categoryLabel}`,
                            url: `/${InstId}/admissions/changeofdepartment`,
                          },
                        ]
                      : []),
                  ],
                },
                {
                  title: "Import Data",
                  image: `${Excel}`,
                  className: "image-white",
                  subMenu: [
                    {
                      title: "Bulk Data Alter",
                      url: `/${InstId}/admissions/students/studentImport`,
                    },
                    ...(USE_ADMISSIONS.details?.view &&
                    !USE_ADMISSIONS.details?.add
                      ? []
                      : [
                          {
                            title: "Bulk Import",
                            url: `/${InstId}/admissions/students/importdata`,
                          },
                        ].filter(Boolean)),
                  ],
                },
                {
                  title: "Delete",
                  image: `${Delete}`,
                  subMenu: [
                    ...(USE_ADMISSIONS.details?.view &&
                    !USE_ADMISSIONS.details?.add
                      ? []
                      : [
                          {
                            title: "Delete Students",
                            url: `/${InstId}/admissions/students/delete`,
                          },
                        ].filter(Boolean)),

                    // {
                    //   title: "Student Complete Report Export",
                    //   url: `/${InstId}/admissions/students/completeReport`,
                    // },
                    //       // {
                    //       //   title: "Detained Student List",
                    //       //   url: `/${InstId}/admissions/students/detainedlist`,
                    //       // }, // Remove null values
                  ],
                },
              ],
            },
            {
              title: "Parents",
              subHeader: [
                {
                  title: "Parent Details",
                  image: `${FeeReceipt}`,
                  className: "image-white",
                  subMenu: [
                    {
                      title: "Parents List",
                      url: `/${InstId}/admissions/parents`,
                    },
                    ...(enableParentLogin
                      ? [
                          {
                            title: "Enable Parents Login",
                            url: `/${InstId}/masters/parentsLogin`,
                          },
                        ]
                      : []),
                  ],
                },
                {
                  title: "Import Data",
                  image: `${Excel}`,
                  className: "image-white",
                  subMenu: [
                    {
                      title: "Parents Import",
                      url: `/${InstId}/admissions/parents/import`,
                    },
                  ],
                },
              ],
            },
            {
              title: "Templates",

              subHeader: [
                {
                  title: "Documents",
                  image: `${Documents}`,
                  subMenu: [
                    {
                      title: "Print",
                      url: `/${InstId}/admissions/documents/studentlist`,
                    },
                  ],
                },

                {
                  title: "Messages",
                  image: `${Template}`,
                  className: "image-white",
                  subMenu: [
                    // {
                    //   title: "Create Tags",
                    //   url: `/${InstId}/admissions/tags`,
                    // },
                    {
                      title: "Templates",
                      url: `/${InstId}/admissions/templates`,
                    },
                  ],
                },
              ],
            },
            {
              title: "Reports",

              subHeader: [
                {
                  title: "Detailed Reports",
                  image: `${Report}`,
                  className: "image-white",
                  subMenu: [
                    {
                      title: "Students Detailed Reports",
                      url: `/${InstId}/admissions/students/reports/student/detailed`,
                    },
                    {
                      title: "Students Academic list",
                      url: `/${InstId}/admissions/students/studentcount/tableview`,
                    },
                    ...(USE_ADMISSIONS.details?.view &&
                    !USE_ADMISSIONS.details?.add
                      ? []
                      : [
                          // {
                          //   title: "Delete Student (who left institution)",
                          //   url: `/${InstId}/admissions/students/deletestudent`,
                          // },

                          {
                            title: `${classLabel} Wise Admission Count`,
                            url: `/${InstId}/admissions/classWiseCount`,
                          },
                        ].filter(Boolean)),
                  ],
                },
                {
                  title: "Export",
                  image: `${Export}`,
                  subMenu: [
                    {
                      title: "Institution Level Report",
                      url: `/${InstId}/admissions/reports/instlevel/student/adm_list`,
                    },
                  ],
                },
              ],
            },
            {
              title: "Masters",

              subHeader: [
                {
                  title: "Master Data",
                  image: `${Master}`,
                  className: "image-white",
                  subMenu: [
                    {
                      title: "Institution Master Configurations",
                      url: `/${InstId}/admissions/viewactiveyears`,
                    },
                    {
                      title: "Logo Upload",
                      url: `/${InstId}/admissions/logoupload`,
                    },
                    {
                      title: "Students Device Details",
                      url: `/${InstId}/admissions/students/studentcount/deviceList`,
                    },
                  ],
                },
              ],
            },
            {
              title: "Configurations",

              subHeader: [
                {
                  title: "Configurations",
                  image: `${Settings}`,
                  subMenu: [
                    {
                      title: "Configurations",
                      url: `/${InstId}/admissions/configurations`,
                    },
                    {
                      title: "Default Students Documents",
                      url: `/${InstId}/admissions/documents/default_std_docs`,
                    },
                  ],
                },
              ],
            },
          ],
        },
  ];

  const AccountItems = [
    {
      mainHeader: [
        {
          title: "Students",

          subHeader: [
            {
              title: "Student Details",
              image: `${StudentDetails}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Students List",
                  url: `/${InstId}/accounts/studentlist`,
                },
                {
                  title: "Passed Out Students List",
                  url: `/${InstId}/accounts/passedOutList`,
                },

                {
                  title: "Permission Letter for Late Fee",
                  url: `/${InstId}/accounts/latefeepayment/permissionletter`,
                },

                ...(allowChangeOfBranch
                  ? [
                      {
                        title: `Change ${branchLabel}/${classLabel}/${categoryLabel}`,
                        url: `/${InstId}/accounts/changeofdepartment`,
                      },
                    ]
                  : []),
                // USE_ACCOUNTS.details?.view && !USE_ACCOUNTS.details?.add
                //   ? null
                //   : {
                //       title: "Delete Demand For Student (who left institution)",
                //       url: `/${InstId}/accounts/deletedemand`,
                //     },
              ],
            },
          ],
        },
        ...(USE_ACCOUNTS.details?.view && !USE_ACCOUNTS.details?.add
          ? []
          : [
              {
                title: "Receipts",

                subHeader: [
                  {
                    title: "Fee Receipts",
                    image: `${FeeReceipt}`,
                    className: "image-white",
                    subMenu: [
                      ...(enableFeeReceipt
                        ? [
                            {
                              title: "Fee Receipt",
                              url: `/${InstId}/accounts/studentfeereceipt`,
                            },
                          ]
                        : []),
                      ...(enableEnqAppFee && enableFeeReceipt
                        ? [
                            {
                              title: "Application Fee Receipt for Enquiry",
                              url: `/${InstId}/accounts/enquirystudentreceipt`,
                            },
                          ]
                        : []),
                      {
                        title: "Interchange Fee Receipt",
                        url: `/${InstId}/accounts/interchangefeereceipt`,
                      },
                      {
                        title: "Import Fee Receipt",
                        url: `/${InstId}/accounts/importFeeReceipt`,
                      },

                      // {
                      //   title: "Fee Receipt For Student Transport",
                      //   url: `/${InstId}/accounts/transportStudentReceipt`,
                      // },

                      // {
                      //   title: "General Fee Receipt",
                      //   url: `/${InstId}/accounts/applicationfeereceipt`,
                      // },
                    ],
                  },

                  {
                    title: "Deposit Receipts",
                    image: `${Deposit}`,
                    subMenu: [
                      ...(enableFeeReceipt
                        ? [
                            {
                              title: "Deposit Receipts",
                              url: `/${InstId}/accounts/deposit/receipt`,
                            },
                          ]
                        : []),
                      {
                        title: "Deposit  Adjustment",
                        url: `/${InstId}/accounts/deposit/adjustment`,
                      },
                      {
                        title: "Deposit  Refund",
                        url: `/${InstId}/accounts/deposit/refunds`,
                      },
                      {
                        title: "Deposit  Details",
                        url: `/${InstId}/accounts/deposit/details`,
                      },
                      {
                        title: "Deposit  OB",
                        url: `/${InstId}/accounts/deposit/ob`,
                      },
                    ],
                  },

                  ...(enableChallanReceipt
                    ? [
                        {
                          title: "Fee Challans",
                          image: `${Challans}`,
                          className: "image-white",
                          subMenu: [
                            {
                              title: "Student Fee Challan",
                              url: `/${InstId}/accounts/challanReceipt`,
                            },

                            {
                              title: "Non Demand (Existing)",
                              url: `/${InstId}/accounts/nonDemandChallan`,
                            },
                            {
                              title: "Non Demand (Alumini)",
                              url: `/${InstId}/accounts/nonDemandChallanFornonrole`,
                            },
                            ...(enableEnqAppFee && enableChallanReceipt
                              ? [
                                  {
                                    title: "Non Demand (Enquiry)",
                                    url: `/${InstId}/accounts/challanReceiptForEnquiry`,
                                  },
                                ]
                              : []),
                            {
                              title: "Reconcile List",
                              url: `/${InstId}/accounts/challanList`,
                            },
                          ],
                        },
                      ]
                    : [
                        {
                          title: "Non Demand Receipts",
                          image: `${NonDemand}`,
                          className: "image-white",
                          subMenu: [
                            ...(enableFeeReceipt
                              ? [
                                  {
                                    title: "Non Demand (Existing)",
                                    url: `/${InstId}/accounts/nondemandfeepayableacademicalreceipt`,
                                  },
                                  {
                                    title: "Non Demand (Alumini)",
                                    url: `/${InstId}/accounts/nondemandfeepayablenonrole`,
                                  },
                                ]
                              : []),

                            ...(hideCaptationFee
                              ? [
                                  {
                                    title: "Management Fee Receipt",
                                    url: `/${InstId}/accounts/managementfeereceipt`,
                                  },
                                  {
                                    title: "Non Demand Management Fee Receipt",
                                    url: `/${InstId}/accounts/managementnondemandfeereceipt`,
                                  },
                                ]
                              : []),
                          ],
                        },
                      ]),

                  {
                    title: "Other Receipts",
                    image: `${OtherReceipts}`,
                    className: "image-white",
                    subMenu: [
                      {
                        title: "Refund Academic Fee",
                        url: `/${InstId}/accounts/refundacademicfee`,
                      },
                      {
                        title: "Concession In Academic Fee",
                        url: `/${InstId}/accounts/feeconcession`,
                      },
                      ...(enableScholarship
                        ? [
                            {
                              title: "Social Welfare/Scholarship Deposit",
                              url: `/${InstId}/accounts/socialwelfare`,
                            },
                          ]
                        : []),
                      ...(createAnonymousStudents
                        ? [
                            {
                              title: "UnReconciled  Fee Receipts",
                              url: `/${InstId}/accounts/unknownbanktransaction`,
                            },
                          ]
                        : []),
                      // {
                      //   title: "Miscellaneous ",
                      //   url: `/${InstId}/accounts/miscellaneous`,
                      // },
                      // {
                      //   title: "Import Student Receipt",
                      //   url: `/${InstId}/accounts/receiptimport`,
                      // },
                    ],
                  },
                ],
              },
            ]),

        ...(USE_ACCOUNTS.details?.view && !USE_ACCOUNTS.details?.add
          ? []
          : [
              {
                title: "Vouchers",
                subHeader: [
                  {
                    title: "Vouchers",
                    image: `${Voucher}`,
                    subMenu: [
                      {
                        title: "Payment Voucher",
                        url: `/${InstId}/accounts/vouchers/paymentvoucher`,
                      },

                      {
                        title: "Receipt Voucher",
                        url: `/${InstId}/accounts/vouchers/receiptvoucher`,
                      },
                      {
                        title: "Contra Voucher",
                        url: `/${InstId}/accounts/vouchers/contravoucher`,
                      },
                      {
                        title: "Journal Voucher",
                        url: `/${InstId}/accounts/vouchers/journalvoucher`,
                      },
                    ],
                  },
                ],
              },
            ]),

        ...(USE_ACCOUNTS.details?.view && !USE_ACCOUNTS.details?.add
          ? []
          : [
              {
                title: "Demands",

                subHeader: [
                  {
                    title: "Demand",
                    image: `${Demand}`,
                    className: "image-white",
                    subMenu: [
                      {
                        title: "Fee Structure (Demand)",
                        url: `/${InstId}/accounts/feedemand`,
                      },

                      {
                        title: "Edit Fee Demand",
                        url: `/${InstId}/accounts/demandfeepayable`,
                      },
                    ],
                  },
                  {
                    title: "Additional Demand",
                    image: `${Add}`,
                    subMenu: [
                      {
                        title: "For Group of Students",
                        url: `/${InstId}/accounts/feedemand/additionalfee/forgroupofstudents`,
                      },
                      {
                        title: "For Individual Student",
                        url: `/${InstId}/accounts/feedemand/additionalfee/forindividualstudent`,
                      },
                    ],
                  },
                  {
                    title: "Delete",
                    image: `${Delete}`,
                    subMenu: [
                      {
                        title: "Complete Fee Demand",
                        url: `/${InstId}/accounts/feedemand/deletefeedemand`,
                      },
                      {
                        title: "Ledger from Fee Demand",
                        url: `/${InstId}/accounts/feedemand/deleteParticularfee`,
                      },

                      // { title: "Arrear Fee", url: `/${InstId}/accounts/feedemand/arrearfee` },
                    ],
                  },
                ],
              },
            ]),
        {
          title: "A/c Books/Final Accounts",

          subHeader: [
            {
              title: "Accounts Details",
              image: `${AcBooks}`,
              subMenu: [
                // should be removed later
                // {
                //   title: "Bank / Cash / Registers / Group Ledgers",
                //   url: `/${InstId}/accounts/accountledgerbooks/accountingledger`,
                // },
                {
                  title: "Bank",
                  url: `/${InstId}/accounts/accountledgerbooks/accountingledger/bank`,
                },
                {
                  title: "Cash",
                  url: `/${InstId}/accounts/accountledgerbooks/accountingledger/cash`,
                },
                {
                  title: "General Ledgers",
                  url: `/${InstId}/accounts/accountledgerbooks/accountingledger/generalledgers`,
                },
                {
                  title: "Fee Ledgers",
                  url: `/${InstId}/accounts/accountledgerbooks/accountingledger/feeledgers`,
                },
                {
                  title: "Registers",
                  url: `/${InstId}/accounts/accountledgerbooks/accountingledger/registers`,
                },

                // {
                //   title: "Trial Balance",
                //   url: `/${InstId}/accounts/reports/trialbalance`,
                // },
                // {
                //   title: "Bank Reconcilation",
                //   url: `/${InstId}/accounts/reports/bankreconcilation`,
                // },
              ],
            },
            {
              title: "Final Account",
              image: `${AccountDetails}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Receipt & Payment A/C",
                  url: `/${InstId}/accounts/receiptandpaymentaccount`,
                },
                {
                  title: "Income & Expenditure",
                  url: `/${InstId}/accounts/incomeandexpenditureaccount`,
                },
                {
                  title: "Balance Sheet",
                  url: `/${InstId}/accounts/balancesheet`,
                },

                // {
                //   title: "Trial Balance",
                //   url: `/${InstId}/accounts/reports/trialbalance`,
                // },
                // {
                //   title: "Bank Reconcilation",
                //   url: `/${InstId}/accounts/reports/bankreconcilation`,
                // },
              ],
            },
          ],
        },

        {
          title: "Reports",

          subHeader: [
            {
              title: "Student Fee Reports",
              image: `${Report}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Student Fee Summary",
                  url: `/${InstId}/accounts/studentcompletefeereceipt`,
                },
                {
                  title: "Fee Balance Reports",
                  url: `/${InstId}/accounts/feebalancereport/balancereport`,
                },

                {
                  title: "Fee Receipt Book",
                  url: `/${InstId}/accounts/reports/feereceiptbook/showallreceipts`,
                },

                {
                  title: "Ledger Wise Student Report",
                  url: `/${InstId}/accounts/reports/ledgerwisestudentreport`,
                },
                ...(enablePaymentGateWay
                  ? [
                      {
                        title: "Online Receipts",
                        url: `/${InstId}/accounts/onlinetransaction`,
                      },
                    ]
                  : []),
                {
                  title: "All Year Fee Details",
                  url: `/${InstId}/accounts/reports/allyearfeedetails`,
                },
                //{
                //   title: "Fee Collected",
                //   url: `/${InstId}/accounts/reports/feecollected`,
                // },

                {
                  title: "Fee Collected (DayWise)",
                  url: `/${InstId}/accounts/dailyfeecollected/feecollected`,
                },
              ],
            },

            {
              title: "Other Reports",
              image: `${OtherReport}`,
              subMenu: [
                {
                  title: "Concession Reports",
                  url: `/${InstId}/accounts/concessionreports`,
                },
                {
                  title: "Refund Reports",
                  url: `/${InstId}/accounts/refundreports`,
                },

                {
                  title: "Ledger Summary",
                  url: `/${InstId}/accounts/reports/ledgersummary`,
                },
                {
                  title: "Demand Summary",
                  url: `/${InstId}/accounts/demandsummary/table`,
                },
                {
                  title: "Payment & General Voucher Registers",
                  url: `/${InstId}/accounts/reports/paymentvoucherregister`,
                },

                ...(enableMkCk
                  ? [
                      {
                        title: "Maker Checker List",
                        url: `/${InstId}/accounts/makercheckerlist`,
                      },
                    ]
                  : []),
              ],
            },

            {
              title: "Export",
              image: `${Export}`,
              subMenu: [
                {
                  title: "Receipts",
                  url: `/${InstId}/accounts/reports/fee/balance`,
                },
                // {
                //   title: "Cash Balance Reports",
                //   url: `/${InstId}/accounts/cashbalancereports`,
                // },
                // {
                //   title: "Cash Flow Details",
                //   url: `/${InstId}/accounts/cashflowdetails`,
                // },
              ],
            },
          ],
        },
        ...(USE_ACCOUNTS.details?.view && !USE_ACCOUNTS.details?.add
          ? []
          : [
              {
                title: "Master",
                subHeader: [
                  {
                    title: "Create",
                    image: `${Add}`,
                    subMenu: [
                      {
                        title: "Group Ledger",
                        url: `/${InstId}/accounts/groupledger`,
                      },
                      {
                        title: "Accounting Ledger",
                        url: `/${InstId}/accounts/accountingledger`,
                      },

                      {
                        title: "Fee Ledger",
                        url: `/${InstId}/accounts/feeledger`,
                      },
                      // {
                      //   title: "Miscellaneous Activities",
                      //   url: `/${InstId}/accounts/miscActivities`,
                      // },
                      {
                        title: "Anonymous Receipt",
                        url: `/${InstId}/accounts/createstudent`,
                      },
                    ],
                  },

                  {
                    title: "Configurations",
                    image: `${Settings}`,
                    subMenu: [
                      {
                        title: "Bill Book Configuration",
                        url: `/${InstId}/accounts/billbook`,
                      },
                      ...(enableFeeFine
                        ? [
                            {
                              title: "Fine Slab",
                              url: `/${InstId}/accounts/fineslab`,
                            },
                          ]
                        : []),
                      {
                        title: "Receipt Group Ledger Reorder",
                        url: `/${InstId}/accounts/groupledger/receiptreoder`,
                      },
                      {
                        title: "Payment Group Ledger Reorder",
                        url: `/${InstId}/accounts/groupledger/paymentreorder`,
                      },
                    ],
                  },
                  {
                    title: "Agents",
                    image: `${Agent}`,
                    subMenu: [
                      {
                        title: "Agents List",
                        url: `/${InstId}/accounts/agents/list`,
                      },
                      {
                        title: "Update Agent Details",
                        url: `/${InstId}/accounts/agents/update`,
                      },
                      {
                        title: "Agent & Student Associations",
                        url: `/${InstId}/accounts/agents/associate`,
                      },
                    ],
                  },
                ],
              },
            ]),

        ...(!USE_ACCOUNTS.details?.view
          ? []
          : [
              {
                title: "Configurations",
                subHeader: [
                  {
                    title: "Configurations",
                    image: `${Settings}`,
                    subMenu: [
                      {
                        title: "Configuration",
                        url: `/${InstId}/accounts/configurations`,
                      },
                    ],
                  },
                ],
              },
            ]),
      ],
    },

    // {
    //   title: "Purchase Entry",
    //   submenu: [
    //     {
    //       title: "Item Group",
    //       url: `/${InstId}/accounts/purchaseentry/masters/group`,
    //     },
    //     {
    //       title: "Item Category",
    //       url: `/${InstId}/accounts/purchaseentry/masters/category`,
    //     },
    //     {
    //       title: "Unit of Measurement",
    //       url: `/${InstId}/accounts/purchaseentry/masters/unitofmeasurement`,
    //     },
    //     {
    //       title: "Item Type",
    //       url: `/${InstId}/accounts/purchaseentry/masters/type`,
    //     },
    //     {
    //       title: "Item Details",
    //       url: `/${InstId}/accounts/purchaseentry/details`,
    //     },
    //     {
    //       title: "Purchase Entry",
    //       url: `/${InstId}/accounts/purchaseentry/invoice`,
    //     },
    //   ],
    // },
  ];
  const AcademicItems = [
    {
      mainHeader: [
        {
          title: "Students",

          subHeader: [
            {
              title: "Student Details",
              image: `${StudentDetails}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Students List",
                  url: `/${InstId}/academics/student/list`,
                },
                {
                  title: "Passed Out Student List",
                  url: `/${InstId}/academics/student/passedOutList`,
                },
              ],
            },
          ],
        },
        {
          title: "Daily Activities",
          subHeader: [
            {
              title: "Activities",
              image: `${Active}`,
              className: "",
              subMenu: [
                {
                  title: `${classLabel} Wise List`,
                  url: `/${InstId}/academics/dailyactivities/home`,
                },
                {
                  title: `Test Wise List`,
                  url: `/${InstId}/academics/dailyactivities/testwise`,
                },
              ],
            },
          ],
        },

        {
          title: "Planner",
          subHeader: [
            {
              title: "Planning Section",
              image: `${Planning}`,
              className: "",

              subMenu: [
                {
                  title: "Lesson Planner",
                  url: `/${InstId}/academics/lessonplanner`,
                },
                {
                  title: "Exam Planner",
                  url: `/${InstId}/academics/examplanner`,
                },
              ],
            },
          ],
        },

        {
          title: "Master",
          subHeader: [
            {
              title: "Allocations",
              image: `${Assign}`,
              className: "",
              subMenu: [
                {
                  title: "Subjects to Class",
                  url: `/${InstId}/academics/subjectallocationdetails`,
                },
                {
                  title: "Subjects to Teachers",
                  url: `/${InstId}/academics/allocateteachers`,
                },
                {
                  title: "Teachers to Class",
                  url: `/${InstId}/academics/assignclassteacher`,
                },
                {
                  title: "Elective Subjects to Students",
                  url: `/${InstId}/academics/electiveSubjectAllocationToStudents`,
                },
              ],
            },
            ...(enableSubjectGrouping || enablePerStdSubAllocation
              ? [
                  {
                    title: "Custom Allocations",
                    image: `${Assign}`,
                    subMenu: [
                      enableSubjectGrouping
                        ? {
                            title: "Groups",
                            url: `/${InstId}/academics/customallocation/groups`,
                          }
                        : null,
                      enablePerStdSubAllocation
                        ? {
                            title: "Category",
                            url: `/${InstId}/academics/customallocation/category`,
                          }
                        : null,
                      enablePerStdSubAllocation
                        ? {
                            title: "Combinations",
                            url: `/${InstId}/academics/customallocation/combinations`,
                          }
                        : null,
                    ],
                  },
                ]
              : []),
            {
              title: "Create",
              image: `${Master}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Subject",
                  url: `/${InstId}/academics/subjestdetails`,
                },

                {
                  title: "Test Type",
                  url: `/${InstId}/academics/testtype`,
                },
                ...(enableSessionAtttendance
                  ? [
                      {
                        title: "Sessions",
                        url: `/${InstId}/academics/sessions`,
                      },
                    ]
                  : []),

                {
                  title: "Grade",
                  url: `/${InstId}/academics/examplanner/grade`,
                },

                enableFeedBackForm
                  ? {
                      title: "Feedback",
                      url: `/${InstId}/academics/feedback`,
                    }
                  : {},
              ],
            },

            {
              title: "Calendar",
              image: `${Calendar}`,
              className: "",
              subMenu: [
                {
                  title: "Enable Month Attendance",
                  url: `/${InstId}/academics/attendance/monthly`,
                },
                {
                  title: "Holiday Entry",
                  url: `/${InstId}/academics/calendar/holidayentry`,
                },
              ],
            },

            {
              title: "Report Cards",
              image: `${Report}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Add / List",
                  url: `/${InstId}/academics/reportcard`,
                },
                {
                  title: "Allocate Tests",
                  url: `/${InstId}/academics/reportcard/allocatetests`,
                },
                {
                  title: "List",
                  url: `/${InstId}/academics/reportcard/list`,
                },
              ],
            },
          ],
        },
        {
          title: "Reports",
          subHeader: [
            {
              title: "Reports",
              image: `${Report}`,
              className: "image-white",

              subMenu: [
                {
                  title: "Complete Reports",
                  url: `/${InstId}/academics/reports/completeReports`,
                },
                {
                  title: "Teacher Reports",
                  url: `/${InstId}/academics/reports/teacher`,
                },

                // {
                //   title: "Teacher Wise Allocated Reports",
                //   url: `/${InstId}/academics/reports/teacherwise`,
                // },
                {
                  title: "FeedBack Reports",
                  url: `/${InstId}/academics/reports/teacherwise/reports`,
                },
                {
                  title: "Attendance Reports",
                  url: `/${InstId}/academics/reports/attendance/absenties`,
                },
                {
                  title: "Faculty Device Details",
                  url: `/${InstId}/academics/reports/FacultyDeiveDetails`,
                },
              ],
            },

            {
              title: "Monitor",
              image: `${Detailed}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Attendance Tracking",
                  url: `/${InstId}/academics/attendance/foradmin`,
                },
                {
                  title: "Testwise Reports",
                  url: `/${InstId}/academics/reports/testwise`,
                },
              ],
            },
          ],
        },

        {
          title: "Configurations",

          subHeader: [
            {
              title: "Configurations",
              image: `${Settings}`,
              subMenu: [
                {
                  title: "Configuration",
                  url: `/${InstId}/academics/configurations`,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const LibraryItems = [
    {
      mainHeader: [
        {
          title: "Students",

          subHeader: [
            {
              title: "Student Details",
              image: `${StudentDetails}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Students List",
                  url: `/${InstId}/library/studentlist`,
                },
              ],
            },
          ],
        },

        {
          title: "Book Transaction",

          subHeader: [
            {
              title: "Book Details",
              image: `${BookTransaction}`,
              subMenu: [
                {
                  title: "Book Issue",
                  url: `/${InstId}/library/bookissue/student`,
                },
                {
                  title: "Book Return",
                  url: `/${InstId}/library/bookreturn/student`,
                },
                // {
                //   title: "Book Reservation",
                //   url: `/${InstId}/library/bookreservation`,
                // },
              ],
            },
          ],
        },

        {
          title: "Books",

          subHeader: [
            {
              title: "New Book",
              image: `${NewBook}`,
              subMenu: [
                {
                  title: "Classification",
                  url: `/${InstId}/library/classification`,
                },
                {
                  title: "Classification and Keyword Associations",
                  url: `/${InstId}/library/bookmasterkeyword`,
                },
                {
                  title: "New Book Entry",
                  url: `/${InstId}/library/booksearch`,
                },
                {
                  title: "Edit Accession No.",
                  url: `/${InstId}/library/accessionno/editaccessionno`,
                },
              ],
            },
            {
              title: "Allocation",
              image: `${Allocation}`,
              subMenu: [
                {
                  title: "Allocate Repaired Books",
                  url: `/${InstId}/library/damaged/shelfallocation`,
                },
                {
                  title: "Books Shelf Allocation",
                  url: `/${InstId}/library/booksallocation/allocatebooks`,
                },
                {
                  title: "Books Shelf Not Allocated",
                  url: `/${InstId}/library/booksallocation/notallocatebooks`,
                },
              ],
            },
            {
              title: "Department",
              image: `${Department}`,
              subMenu: [
                {
                  title: "Department Allocation",
                  url: `/${InstId}/library/booksallocation/booksdeptallocation`,
                },
                {
                  title: "Department Not Allocated",
                  url: `/${InstId}/library/booksallocation/booksdeptNotallocation`,
                },
              ],
            },

            {
              title: "Import Data",
              image: `${Excel}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Import",
                  url: `/${InstId}/library/import`,
                },
              ],
            },
          ],
        },
        {
          title: "Fine",

          subHeader: [
            {
              title: "Fine",
              image: `${Fine}`,
              subMenu: [
                { title: "Fine Slab", url: `/${InstId}/library/fineslab` },
                {
                  title: "Fine Association",
                  url: `/${InstId}/library/fineassociation`,
                },
              ],
            },
          ],
        },
        {
          title: "Master",

          subHeader: [
            {
              title: "Master",
              image: `${Master}`,
              className: "image-white",
              subMenu: [
                { title: "Media", url: `/${InstId}/library/media` },
                {
                  title: "Book Category",
                  url: `/${InstId}/library/bookcategory`,
                },
                { title: "Subjects", url: `/${InstId}/library/subjects` },
                { title: "Publisher", url: `/${InstId}/library/publisher` },
                { title: "Languages", url: `/${InstId}/library/languages` },
                { title: "Attribute", url: `/${InstId}/library/attribute` },
                { title: "Author", url: `/${InstId}/library/author` },
                { title: "Shelf", url: `/${InstId}/library/shelf` },
                { title: "Vendor", url: `/${InstId}/library/vendordetails` },
                { title: "Department", url: `/${InstId}/library/department` },
                {
                  title: "Keyword",
                  url: `/${InstId}/library/keyword`,
                },
                { title: "Donor", url: `/${InstId}/library/donor` },
                //     { title: "Books Search", url: `/${InstId}/library/booksearch` },
              ],
            },
          ],
        },
        {
          title: "Reports",

          subHeader: [
            {
              title: "Detailed Report",
              image: `${Report}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Reports",
                  url: `/${InstId}/library/reports/currentlyissuedbooks`,
                },
              ],
            },
          ],
        },
        {
          title: "Configurations",

          subHeader: [
            {
              title: "Configurations",
              image: `${Settings}`,
              subMenu: [
                {
                  title: "Configuration",
                  url: `/${InstId}/library/configurations`,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const HRItems = [
    {
      mainHeader: [
        {
          title: "Employee & Users",

          subHeader: [
            {
              title: "Employee Details",
              image: `${StudentDetails}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Employee List",
                  url: `/${InstId}/hr/employee/list`,
                },
                ...(attEntryMethod !==
                AttEntryMethodEnum.WITHOUT_LEAVE_MANAGEMENT
                  ? [
                      {
                        title: "Assign Leave Ledger",
                        url: `/${InstId}/hr/employee/assignleaveledger`,
                      },
                    ]
                  : []),
                {
                  title: "Reset Password for Employee",
                  url: `/${InstId}/hr/payroll/resetPassword`,
                },
              ],
            },
            {
              title: "Users",
              image: `${User}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Accounting Users",
                  url: `/${InstId}/hr/payroll/accountingusers`,
                },
                {
                  title: "Teacher Login",
                  url: `/${InstId}/hr/payroll/academicusers`,
                },
                ...(enableEmpLogin
                  ? [
                      {
                        title: "Enable Login for Employee",
                        url: `/${InstId}/hr/payroll/enablelogin`,
                      },
                    ]
                  : []),
              ],
            },
          ],
        },
        ...(enablePayRollProcess
          ? [
              {
                title: "Salary",

                subHeader: [
                  {
                    title: " Details",
                    image: `${StudentDetails}`,
                    className: "image-white",
                    subMenu: [
                      {
                        title: "Assign Salary Structure",
                        url: `/${InstId}/hr/payroll/assignsalarystructure`,
                      },
                      {
                        title: "Salary Process",
                        url: `/${InstId}/hr/payroll/salaryprocess/selectmonth`,
                      },
                    ],
                  },
                  {
                    title: "Salary Structure",
                    image: `${Allocation}`,
                    className: "image-white",
                    subMenu: [
                      {
                        title: "Salary Structure",
                        url: `/${InstId}/hr/payroll/salarystructure`,
                      },
                    ],
                  },
                ],
              },
            ]
          : []),

        {
          title: "Attendance & Calendar",

          subHeader: [
            {
              title: "Attendance",
              image: `${Attendance}`,
              subMenu: [
                {
                  title: "Attendance Entry",
                  url: `/${InstId}/hr/payroll/attendance`,
                },
                {
                  title: "Monthly Attendance List",
                  url: `/${InstId}/hr/payroll/attendance/monthly`,
                },
              ],
            },
            {
              title: "Calendar",
              image: `${Calendar}`,
              subMenu: [
                {
                  title: "Calendar Events",
                  url: `/${InstId}/hr/calendar/events`,
                },
              ],
            },
          ],
        },

        {
          title: "Organization",

          subHeader: [
            {
              title: "Employee Tree",
              image: `${Organization}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Employee Tree",
                  url: `/${InstId}/hr/payroll/employeetree`,
                },
              ],
            },
            {
              title: "Chart",
              image: `${Chart}`,
              subMenu: [
                {
                  title: "Organizational Chart",
                  url: `/${InstId}/hr/payroll/organizationalchart`,
                },
              ],
            },
          ],
        },
        {
          title: "Master Data",

          subHeader: [
            {
              title: "Master Details",
              image: `${Master}`,
              className: "image-white",
              subMenu: [
                ...(USE_HR_CATEGORY
                  ? [
                      {
                        title: "Category",
                        url: `/${InstId}/hr/payroll/category`,
                      },
                    ]
                  : []),
                ...(USE_HR_JOBTYPE
                  ? [
                      {
                        title: "Job Type",
                        url: `/${InstId}/hr/payroll/jobtype`,
                      },
                    ]
                  : []),
                ...(USE_HR_DEPARTMENT
                  ? [
                      {
                        title: "Department",
                        url: `/${InstId}/hr/payroll/department`,
                      },
                    ]
                  : []),
                ...(USE_HR_DESIGNATION
                  ? [
                      {
                        title: "Designation",
                        url: `/${InstId}/hr/payroll/designation`,
                      },
                    ]
                  : []),
                ...(USE_HR_GRADE
                  ? [{ title: "Grade", url: `/${InstId}/hr/payroll/grade` }]
                  : []),
                ...(attEntryMethod !==
                AttEntryMethodEnum.WITHOUT_LEAVE_MANAGEMENT
                  ? [
                      {
                        title: "Leave Ledger",
                        url: `/${InstId}/hr/payroll/leaveledger`,
                      },
                    ]
                  : []),
                ...(attEntryMethod !==
                AttEntryMethodEnum.WITHOUT_LEAVE_MANAGEMENT
                  ? [
                      {
                        title: "Default Leave Ledger",
                        url: `/${InstId}/hr/payroll/defaultleaveledger`,
                      },
                    ]
                  : []),
                {
                  title: "Salary Ledger",
                  url: `/${InstId}/hr/payroll/salaryledger`,
                },
              ],
            },
          ],
        },

        {
          title: "Reports",

          subHeader: [
            {
              title: "Detailed Report",
              image: `${Report}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Payroll",
                  url: `/${InstId}/hr/payroll/reports/payslip`,
                },
              ],
            },
          ],
        },

        {
          title: "Configurations",

          subHeader: [
            {
              title: "Configurations",
              image: `${Settings}`,
              subMenu: [
                {
                  title: "Configurations",
                  url: `/${InstId}/hr/payroll/configuration`,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const userRightsItems = [
    {
      mainHeader: [
        {
          title: "Users",

          subHeader: [
            {
              title: "Users",
              image: `${User}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Create Login User",
                  url: `/${InstId}/rights/loginusers`,
                },
                {
                  title: "Search Users",
                  url: `/${InstId}/rights/globalsearch`,
                },
                {
                  title: "Users Rights Assigned List",
                  url: `/${InstId}/rights/assignedlist`,
                },
                {
                  title: "Reset User Password",
                  url: `/${InstId}/rights/ResetUserPassword`,
                },
                {
                  title: "Enable User Login",
                  url: `/${InstId}/rights/enableUserLogin`,
                },
                {
                  title: "Import Employee",
                  url: `/${InstId}/rights/empImport`,
                },
              ],
            },
          ],
        },
        {
          title: "Masters",

          subHeader: [
            {
              title: "Masters",
              image: `${Master}`,
              className: "image-white",
              subMenu: [
                ...(!USE_HR_DEPARTMENT
                  ? [
                      {
                        title: "Department",
                        url: `/${InstId}/rights/department`,
                      },
                    ]
                  : []),
                ...(!USE_HR_DESIGNATION
                  ? [
                      {
                        title: "Designation",
                        url: `/${InstId}/rights/designation`,
                      },
                    ]
                  : []),

                {
                  title: "Organizational Chart",
                  url: `/${InstId}/rights/organizationalchart`,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const InstitutionConfiurationItems = [
    {
      mainHeader: [
        {
          title: "Configurations",

          subHeader: [
            {
              title: "Configurations",
              image: `${Settings}`,
              subMenu: [
                ...(state?.claims!.EMPLOYEE
                  ? [
                      {
                        title: "Institution",
                        url: `/${InstId}/institutionconfiguration/configureuserneeds`,
                      },
                      {
                        title: "Institution Complete Configuration",
                        url: `/${InstId}/institutionconfiguration/globalconfiguration`,
                      },
                      {
                        title: "Module Configuration for Students",
                        url: `/${InstId}/institutionconfiguration/instmodules`,
                      },

                      {
                        title: "Reset & Recalculate",
                        url: `/${InstId}/institutionconfiguration/resetandrecalculate`,
                      },
                    ]
                  : [
                      {
                        title: "Page",
                        url: `/${InstId}/institutionconfiguration/pageconfiguration`,
                      },

                      {
                        title: "Predefined Data",
                        url: `/${InstId}/instdetails/configurations/predefineddata`,
                      },
                      {
                        title: "Students Configuration",
                        url: `/${InstId}/institutionconfiguration/studentsconfiguration`,
                      },
                    ].filter(Boolean)),
              ],
            },
          ],
        },

        {
          title: "Logs",

          subHeader: [
            {
              title: "Logs",
              image: `${Log}`,
              subMenu: [
                {
                  title: "Logs",
                  url: `/${InstId}/logs`,
                },
              ],
            },
          ],
        },
        {
          title: "Posting",

          subHeader: [
            {
              title: "Posting",
              image: `${Posting}`,
              subMenu: [
                {
                  title: "Academics Data Posting",
                  url: `/${InstId}/institutionconfiguration/posting/academicyear`,
                },
                {
                  title: "Financial Year Posting",
                  url: `/${InstId}/institutionconfiguration/posting/financialyear`,
                },
                {
                  title: "Pending Financial Year Student Data Posting",
                  url: `/${InstId}/institutionconfiguration/posting/pendingstudentdata`,
                },
                {
                  title: "Pending Academic Year Student  Data Posting",
                  url: `/${InstId}/institutionconfiguration/posting/pendingAcdstudentdata`,
                },
              ],
            },
          ],
        },

        {
          title: "Documents",

          subHeader: [
            {
              title: "Documents",
              image: `${Documents}`,
              subMenu: [
                {
                  title: "Create Documents",
                  url: `/${InstId}/institutionconfiguration/documents/create`,
                },
                // {
                //   title: "Create Tags",
                //   url: `/${InstId}/admissions/tags`,
                // },
              ],
            },

            {
              title: "Id Cards",
              image: `${IdCard}`,
              className: "image-white",
              subMenu: [
                {
                  title: "Id Cards",
                  url: `/${InstId}/institutionconfiguration/idcards`,
                },
                {
                  title: "Print",
                  url: `/${InstId}/institutionconfiguration/idcards/print`,
                },
              ],
            },
          ],
        },
        {
          title: "Import",

          subHeader: [
            {
              title: "Import",
              image: `${Excel}`,
              className: "image-white",
              subMenu: [
                {
                  title: " Old Students Import",
                  url: `/${InstId}/institutionconfiguration/importStdData`,
                },
                // {
                //   title: "Students Import(Non-Demand)",
                //   url: `/${InstId}/institutionconfiguration/importNonDemandData`,
                // },
              ],
            },
          ],
        },
      ],
    },
  ];

  const Customer = [
    {
      mainHeader: [
        {
          title: "Customers",

          subHeader: [
            {
              title: "Customers",
              image: `${CustomerImage}`,
              className: "image-white",
              subMenu: [
                {
                  title: "List",
                  url: `/eduate/customer/list`,
                },
                // {
                //   title: "Customer",
                //   url: `/eduate/customer/configuration`,
                // },
                {
                  title: "Institutions",
                  url: `/eduate/customer/${custId}/institutionlist`,
                },
                {
                  title: "Proprietor",
                  url: `/eduate/customer/${custId}/proprietor/list`,
                },
                {
                  title: "Reset Chairman Password",
                  url: `/eduate/customer/${custId}/proprietor/resetPassword`,
                },
              ],
            },
          ],
        },

        {
          title: "Campus",

          subHeader: [
            {
              title: "Campus",
              image: `${Campus}`,
              className: "image-white",
              subMenu: [
                {
                  title: "List",
                  url: `/eduate/customer/${custId}/campus/list`,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const EduateConfigItems = [
    {
      mainHeader: [
        {
          title: "Configurations",

          subHeader: [
            {
              title: "Configurations",
              image: `${Settings}`,
              subMenu: [
                {
                  title: "Settings Details",
                  url: `/eduate/settingsdashboard`,
                },
                {
                  title: "Page Configuration",
                  url: `/eduate/pageconfig`,
                },
                {
                  title: "Salary Ledger",
                  url: `/eduate/salaryledger`,
                },
                {
                  title: "Accounting Ledger",
                  url: `/eduate/accountledger`,
                },
                {
                  title: "Leave Ledger",
                  url: `/eduate/leaveledger`,
                },
                {
                  title: "Eduate Modules",
                  url: `/eduate/modules`,
                },
                {
                  title: "Eduate Configuration",
                  url: `/eduate/configuration`,
                },
                {
                  title: "Eduate Tags",
                  url: `/eduate/tags`,
                },
                {
                  title: "Eduate Templates",
                  url: `/eduate/template`,
                },
                {
                  title: "Future Message",
                  url: `/eduate/fcmMessage`,
                },
                // {
                //   title: "Eduate User Association",
                //   url: `/eduate/eduateuserassociation`,
                // },
              ],
            },
          ],
        },
        {
          title: "Calendar",

          subHeader: [
            {
              title: "Calendar",
              image: `${Calendar}`,
              subMenu: [
                { title: "Calendar Events", url: `/eduate/calendarevents` },
              ],
            },
          ],
        },
      ],
    },
  ];

  const TransportItems = [
    {
      mainHeader: [
        {
          title: "Masters",

          subHeader: [
            {
              title: "Masters",
              image: `${Master}`,
              subMenu: [
                {
                  title: "Route",
                  url: `/${InstId}/transport/masterdata/route/add`,
                },
                {
                  title: "Route Details",
                  url: `/${InstId}/transport/masterdata/route/details`,
                },
                // {
                //   title: "Assign Route for Multiple Student",
                //   url: `/${InstId}/transport/student/StudentRoute`,
                // },
                {
                  title: "Route Assigned Student List",
                  url: `/${InstId}/transport/masterdata/assignroutetostudent/list`,
                },
              ],
            },
          ],
        },
        {
          title: "Students",

          subHeader: [
            {
              title: "Students",
              image: `${StudentDetails}`,
              subMenu: [
                {
                  title: "List",
                  url: `/${InstId}/transport/student/list`,
                },
                {
                  title: "View Students Route",
                  url: `/${InstId}/transport/student/View`,
                },
              ],
            },
          ],
        },

        {
          title: " Ledger",

          subHeader: [
            {
              title: "Create",
              image: `${Add}`,
              subMenu: [
                {
                  title: "Create Ledger",
                  url: `/${InstId}/transport/transportledger/createledger`,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    if (NavType === LicenseTypes.ACCOUNTS) {
      setItems(state.claims?.EMPLOYEE ? AccountItems : []);
    }
    if (NavType === LicenseTypes.EDUATE_CUSTOMER) {
      setItems(
        window.location.pathname.includes("customer/list") ? [] : Customer
      );
    }
    if (NavType === LicenseTypes.ACADEMIC) {
      setItems(AcademicItems);
    }

    if (NavType === LicenseTypes.PAYROLL) {
      setItems(HRItems);
    }

    if (NavType === LicenseTypes.LIBRARY) {
      setItems(LibraryItems);
    }

    if (NavType === LicenseTypes.TRANSPORT) {
      setItems(TransportItems);
    }
    if (NavType === LicenseTypes.USER_RIGHTS) {
      setItems(userRightsItems);
    }

    if (NavType === LicenseTypes.INSTITUTION_CONFIG) {
      setItems(InstitutionConfiurationItems);
    }
    if (NavType === LicenseTypes.EDUATE_CONFIGURATION) {
      setItems(EduateConfigItems);
    }
    if (NavType === LicenseTypes.MASTERS) {
      setItems(state.claims?.EMPLOYEE ? Admissions : []);
    }
    // eslint-disable-next-line
  }, [
    NavType,
    USE_SECTION_KEY,
    USE_HR_CATEGORY,
    USE_HR_JOBTYPE,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    hideCaptationFee,
    USE_ACCOUNTS.details?.view,
    USE_ADMISSIONS.details?.view,
    enableStudentLogin,
    enableEmpLogin,
    enableScholarship,
    enableParentLogin,
    enableMkCk,
    enablePaymentGateWay,
    enableEnqAppFee,
    enableSessionAtttendance,
    enableChallanReceipt,
    enablePayRollProcess,
    enableFeeReceipt,
    allowChangeOfBranch,
    allowChangeOfBranchinAdm,
    createAnonymousStudents,
    hideCaptationFee,
    attEntryMethod,
    enableFeeFine,
    enablePerStdSubAllocation,
    enableFeedBackForm,
    enableSubjectGrouping,
  ]);

  return (
    <>
      <div className="row g-0">
        <Header />
      </div>
      <div className="row g-0">
        <BreadCrumb />
      </div>

      {TeacherDashboard ? (
        <div className="student-dashboard__nav"></div>
      ) : (
        <>
          <div className="menus">
            <ul>
              {items.map((menuItem: MenuItemsProps, menuIndex: React.Key) =>
                menuItem.mainHeader.map((mainItem, mainIndex) => (
                  <li
                    className="menus__dropdown"
                    key={`${menuIndex}-${mainIndex}`}
                  >
                    <button
                      className={mainItem?.title ? "dropbtn" : ""}
                      aria-haspopup="true"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {mainItem?.title}{" "}
                      {mainItem?.title ? <KeyboardArrowRight /> : null}
                    </button>
                    {isOpen && (
                      <div className="menus__dropdown-content">
                        <div className="menus__dropdown-content--flex">
                          {Array.isArray(mainItem?.subHeader) &&
                            mainItem?.subHeader.map((subItem, subIndex) => (
                              <div
                                key={`${menuIndex}-${mainIndex}-${subIndex}`}
                              >
                                <React.Fragment>
                                  <div className="menus__dropdown-content--title">
                                    <div className="menus__dropdown-content--image">
                                      <img
                                        src={subItem?.image}
                                        alt=""
                                        className={subItem?.className}
                                      />
                                    </div>
                                    <div>
                                      <h6>{subItem?.title}</h6>
                                      <hr />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      subItem?.title.length > 1
                                        ? "border-right"
                                        : ""
                                    }
                                  >
                                    {subItem?.subMenu?.map(
                                      (linkItem, linkIndex) => (
                                        <NavLink
                                          to={linkItem?.url}
                                          onClick={() => {
                                            clearGlobalStates(dispatch, state);
                                          }}
                                          key={`${menuIndex}-${mainIndex}-${subIndex}-${linkIndex}`}
                                          className={({ isActive }) =>
                                            `${
                                              linkItem?.title
                                                ? "menus__dropdown-content--routes"
                                                : ""
                                            } ${isActive ? "active" : ""}`
                                          }
                                        >
                                          {linkItem?.title}
                                        </NavLink>
                                      )
                                    )}
                                  </div>
                                </React.Fragment>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </li>
                ))
              )}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;

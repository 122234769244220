import React, { useContext, useEffect } from "react";
import { Title } from "../../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useMasterTableJson from "../../../../../json/useMasterTableJson";
import { TableHeaderProps } from "../../../../../Types/Tables";
import { GetAcdYrsByInstId } from "../../../../../queries/academicyear/list/byid";
import {
  AcademicYearListData,
  AcademicYearListVars,
} from "../../../../../Types/Student";
import {
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
} from "@apollo/client";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import useToken from "../../../../../customhooks/useToken";
import Pointer from "../../../../../images/Pointer.svg";
import Edit from "../../../../../images/EditProfile.svg";

import { toStandardDate } from "../../../../../utils/UtilFunctions";
import { Operation } from "../../../../../utils/Enum.types";
import { Actions } from "../../../../HR/constants";
interface Props {
  onlyTable: boolean;
  setOperation?: React.Dispatch<React.SetStateAction<Operation>>;
  setAcademicYearId?: React.Dispatch<React.SetStateAction<number>>;
  GetacademicYearData?: LazyQueryExecFunction<any, OperationVariables>;
}
const AcademicYearList = ({
  onlyTable,
  setOperation,
  setAcademicYearId,
  GetacademicYearData,
}: Props) => {
  const { Masters_Table } = useMasterTableJson();
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [GetAcdYears, { data: AcademicYearResponse }] = useLazyQuery<
    AcademicYearListData,
    AcademicYearListVars
  >(GetAcdYrsByInstId, {
    variables: { inst_id: InstId || state.InstId!, token },
  });

  const AcademicYearList = AcademicYearResponse
    ? AcademicYearResponse.GetAcdYrsByInstId
    : [];

  let serialno = 1;
  useEffect(() => {
    if (token) {
      GetAcdYears();
    }
  }, [token, GetAcdYears]);
  return (
    (<div className={`col ${onlyTable === false ? "booktype-right" : ""} h-100`}>
      <Title variant="subtitle1">List of Academic Years</Title>
      <div className="acd-masters-frame__tableblock">
        <TableContainer className="masterconfig__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Masters_Table.InstitutionDetails.AcademicYear.Table_Headers.filter(
                  (th: TableHeaderProps) =>
                    (th.labelName !== Actions && onlyTable) ||
                    onlyTable === false
                ).map((th: TableHeaderProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TableCell className={th.className}>
                        {th.labelName}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {AcademicYearList.map((response, index: number) => (
                <React.Fragment key={index}>
                  <TableRow
                    className={
                      response.acd_is_curr_yr ? "activefinancialyear" : ""
                    }
                  >
                    <TableCell
                      id="td-center"
                      className="institution__table--slno"
                    >
                      {response.acd_is_curr_yr && (
                        <img
                          className="activefinancialyearIcon"
                          src={Pointer}
                          alt="/"
                        />
                      )}
                      {serialno++}
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="institution__table--year"
                    >
                      {response.acd_yr}
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="institution__table--date"
                    >
                      {toStandardDate(response.acd_st_date)}
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="institution__table--date"
                    >
                      {toStandardDate(response.acd_end_date)}
                    </TableCell>
                    {onlyTable === false ? (
                      <TableCell
                        id="td-center"
                        className="institution__table--action"
                      >
                        {!response.acd_is_curr_yr && (
                          // !(
                          //   USE_ADMISSIONS.details?.edit &&
                          //   state.claims?.EDUATE
                          // ) &&

                          (<img
                            src={Edit}
                            alt="/"
                            onClick={() => {
                              setOperation?.(Operation.UPDATE);
                              setAcademicYearId?.(response.id);
                              GetacademicYearData?.();
                            }}
                          />)
                        )}
                      </TableCell>
                    ) : null}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>)
  );
};

export default AcademicYearList;

import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import TextArea from "../../../stories/TextArea/TextArea";
import { Button } from "../../../stories/Button/Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DairyDetailsById, GetPayRollEmpDailyDiary } from "./queries";
import { GetPayRollEmpDailyDiaryList } from "./Index";
import useToken from "../../../customhooks/useToken";
import { AddOrUpdatePayRollEmpDailyDiary } from "./queries/mutations";
import { Operation, PrEmpDailyDiaryQueryType } from "../../../utils/Enum.types";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { msgType } from "../../../utils/Form.types";
import usePayRollActiveAcademicYear from "../../HR/hooks/usePayRollActiveAcademicYear";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { toIsoDate } from "../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../utils/constants";
import { AppContext } from "../../../context/context";
interface props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  dairyId: number;
}
export interface DairyDetailsByIdData {
  node: GetPayRollEmpDailyDiaryList;
}
interface DairyDetailsByIdDataVars {
  token: string;
  id: number;
}

const Update = ({ setModal, dairyId }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const pr_acd_yr_id = payRollActiveAcademicYear.data
    ? payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id
    : 0;
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [AddDailyDairy, { loading }] = useMutation(
    AddOrUpdatePayRollEmpDailyDiary,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetDairyDetailsById, { data }] = useLazyQuery<
    DairyDetailsByIdData,
    DairyDetailsByIdDataVars
  >(DairyDetailsById, {
    variables: {
      id: dairyId,
      token,
    },
  });
  const HandleAddDairyData = () => {
    AddDailyDairy({
      variables: {
        token,
        inst_id: InstId!,
        pr_acd_yr_id,
        user_details,
        input: {
          pr_emp_id: state.empLoginId,
          date_of_diary: toIsoDate(TODAY_DATE),
          question_idx: data?.node.question_idx,
          question,
          answer,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollEmpDailyDiary,
          variables: {
            token,
            inst_id: Number(InstId)!,
            pr_acd_yr_id,
            pr_emp_id: state.empLoginId,
            input: {
              query_type: PrEmpDailyDiaryQueryType.DIARY_NOTES,
              date_of_diary: toIsoDate(TODAY_DATE),
              question_idx: 0,
            },
          },
        },
      ],
    });
    setModal(false);
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token && dairyId) {
      GetDairyDetailsById().then(({ data }) => {
        if (data) {
          setQuestion(data.node.question);
          setAnswer(data.node.answer);
        }
      });
    }
  }, [token, dairyId, GetDairyDetailsById]);
  return (
    <>
      <div className="daily-diary-update">
        <div className="daily-diary-update__title">
          <Title>Update</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="daily-diary-update__datablock">
          <b>{question}</b>
          <TextArea
            rows={8}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>
        <Button mode="save" onClick={HandleAddDairyData} />
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Update;

import { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import { useLazyQuery } from "@apollo/client";
import {
  ChannelListData,
  ChannelListVars,
  GetChannelMessagesByData,
  GetChannelMessagesByVars,
} from "../../../Channel/Types";
import {
  GetChannelMessages,
  GetMessageChannel,
} from "../../../../queries/chatapplication/list";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import {
  Direction,
  MsgChannelType,
  SortBy,
} from "../../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import { ChannelQueryTypeByInstId } from "../../../Accounts/common/Enum.types";

export const colors: string[] = [
  "#fef3c7",
  "#cffafe",
  "#d1fae5",
  "#fae8ff",
  "#ecfccb",
  "#ffedd5",
  "#fce7f3",
];

const Announcements = () => {
  const slideDuration: number = 5000;
  const resumeDelay = 60000;
  const [index, setIndex] = useState<number>(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const [delayedIndex, setDelayedIndex] = useState<number | null>(null);
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();

  const [GetChannelListData, { data: ChannelList }] = useLazyQuery<
    ChannelListData,
    ChannelListVars
  >(GetMessageChannel, {
    variables: {
      token,
      inst_id: InstId!,
      input: {
        query_type: ChannelQueryTypeByInstId.ANNOUNCEMENTS,
        channel_id: 0,
      },
    },
  });
  const channelId =
    ChannelList &&
    ChannelList.GetChannelsByInstId &&
    ChannelList.GetChannelsByInstId[0]
      ? ChannelList.GetChannelsByInstId[0].id
      : 0;

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [GetMessages, { data: MessagesData }] = useLazyQuery<
    GetChannelMessagesByData,
    GetChannelMessagesByVars
  >(GetChannelMessages, {
    variables: {
      token,
      inst_id: InstId ? InstId : state.InstId.toString(),
      input: {
        query_type: MsgChannelType.ANNOUNCEMENTS,
        channel_id: channelId ? channelId : 0,
      },
      direction: Direction.ASC,
      last: 5,
      after: null,
      sortBy: SortBy.CREATED_AT,
      mediaContent: EMPTY_STRING,
    },
  });
  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  useEffect(() => {
    if (token && InstId) {
      GetChannelListData();
    }
  }, [token, InstId, GetChannelListData]);
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          MessagesData &&
          prevIndex === MessagesData?.GetChannelMessages.edges.length - 1
            ? 0
            : prevIndex + 1
        ),
      slideDuration
    );

    return () => {
      resetTimeout();
    };
  }, [index, MessagesData]);
  useEffect(() => {
    if (token && (InstId || state.InstId)) {
      GetMessages();
    }
  }, [token, MessagesData, GetMessages, InstId, state.InstId]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (autoSlide) {
      intervalId = setInterval(() => {
        setIndex((prevIndex) => {
          if (delayedIndex !== null && delayedIndex === prevIndex) {
            return prevIndex; // Stay at the delayed index while autoSlide is true
          }
          return (
            (prevIndex + 1) %
            (MessagesData?.GetChannelMessages.edges.length ?? colors.length)
          );
        });
      }, slideDuration); // Slide transition duration
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [autoSlide, MessagesData, colors, slideDuration]);

  const handleDotClick = (idx: number) => {
    setIndex(idx);
    setAutoSlide(false);
    setDelayedIndex(idx);

    setTimeout(() => {
      setDelayedIndex(null);
      setAutoSlide(true); // Resume auto sliding after the specified delay
    }, resumeDelay);
  };
  return (
    <>
      <div className="student-dashboard__frame--grids--title">
        <Title variant="subtitle1">
          Announcements{" "}
          <svg
            width="40"
            height="25"
            viewBox="0 0 26 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.58746 15.6358H6.63531C6.92047 15.6358 7.14451 15.4067 7.14451 15.1266V6.41106C7.14451 6.13097 6.92049 5.90186 6.63531 5.90186L2.13007 5.90205C1.24942 5.90205 0.526367 6.6249 0.526367 7.51085V14.0324C0.526367 14.913 1.24922 15.6361 2.13007 15.6361H2.42655L4.24785 20.5947C4.52266 21.3378 5.2406 21.8419 6.03475 21.8419C6.25877 21.8419 6.47772 21.8013 6.6914 21.7249L6.78808 21.6894C7.26152 21.5112 7.64333 21.1599 7.85723 20.7017C8.07109 20.2384 8.09141 19.7241 7.91328 19.2456L6.58746 15.6358Z"
              fill="#F6AD55"
            />
            <path
              d="M22.1881 0.388501C21.9692 0.327366 21.735 0.413891 21.618 0.607446C19.2049 4.46135 15.056 6.76245 10.5095 6.76245H8.1626V14.7754H10.5095C15.0505 14.7754 19.1995 17.0715 21.613 20.9304L21.6181 20.9355C21.7148 21.0832 21.8777 21.1697 22.0456 21.1697C22.0966 21.1697 22.1423 21.1646 22.1933 21.1494C22.4071 21.0832 22.5548 20.8847 22.5548 20.6607L22.5546 0.877221C22.5546 0.653196 22.407 0.449436 22.1881 0.388501Z"
              fill="var(--level-6)"
            />
            <path
              d="M23.5728 7.14404V14.3965C24.9866 13.7995 25.9816 12.3991 25.9816 10.7704C25.9816 9.14169 24.9867 7.74109 23.5728 7.14424V7.14404Z"
              fill="var(--level-4)"
            />
          </svg>
        </Title>
        <img src={More} alt="/" />
      </div>
      <div className="student-dashboard__announcements">
        <div className="student-dashboard__announcements--slideshow">
          <div
            className="student-dashboard__announcements--slider"
            style={{
              transform: `translate3d(${-index * 100}%, 0, 0)`,
            }}
          >
            {colors.map((backgroundColor, idx) => {
              const parsedContent =
                MessagesData &&
                MessagesData.GetChannelMessages.edges[idx] &&
                MessagesData.GetChannelMessages.edges[idx].node &&
                MessagesData.GetChannelMessages.edges[idx].node.msg_content
                  ? parseMessageContent(
                      MessagesData.GetChannelMessages.edges[idx].node
                        .msg_content
                    )
                  : null;

              return (
                <div
                  className="student-dashboard__announcements--slide"
                  key={idx}
                  style={{ backgroundColor }}
                >
                  {MessagesData &&
                  MessagesData.GetChannelMessages.edges[idx] ? (
                    <>
                      {MessagesData.GetChannelMessages.edges[idx].node
                        .msg_content && (
                        <b
                          dangerouslySetInnerHTML={{
                            __html: parsedContent || "",
                          }}
                        />
                      )}
                      <span>
                        {toStandardDate(
                          MessagesData.GetChannelMessages.edges[idx].node
                            .msg_date
                        )}
                      </span>
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>

          <div className="student-dashboard__announcements--dots">
            {MessagesData &&
              MessagesData.GetChannelMessages.edges.map((_, idx) => (
                <div
                  key={idx}
                  className={`student-dashboard__announcements--dot${
                    index === idx ? " active " : ""
                  }`}
                  onClick={() => handleDotClick(idx)}
                ></div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcements;

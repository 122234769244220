import { gql } from "@apollo/client";
export const ListInstDeptIdsByNames = gql`
  query GetDeptIdsByNames($names: [String!], $token: String!, $inst_id: ID!) {
    GetDeptIdsByNames(token: $token, dept_names: $names, inst_id: $inst_id) {
      id
      dept_desc
    }
  }
`;

export const GetDeptIdByName = gql`
  query GetDeptIdByName($dept_name: String!, $inst_id: ID!, $token: String!) {
    GetDeptIdByName(dept_name: $dept_name, inst_id: $inst_id, token: $token)
  }
`;

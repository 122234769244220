import React from "react";
import { Title } from "../../../../../stories/Title/Title";
import Previous from "../../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../../images/CalenderNextButton.svg";
import {  TextField } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { Button } from "../../../../../stories/Button/Button";
import { TEXT_FONT_FAMILY } from "../../../../../styles/DataGridTableStyles";
import { FormAutocomplete, formClasses } from "../../../../../styles/AutocompleteStyles";

const options = {
  legend: {
    textStyle: {
      color: "black",
      fontSize: 12,
      fontFamily: TEXT_FONT_FAMILY,
    },
    icon: "rect",
    bottom: 10,
    width: "90%",
    type: "scroll",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
      crossStyle: {
        color: "#999",
      },
    },
    textStyle: {
      fontSize: 14,
      fontFamily: TEXT_FONT_FAMILY,
      color: "#1e293b",
    },
  },
  dataset: {
    dimensions: ["percentage", "Pass", "Fail"],
    source: [
      { percentage: "English", Pass: 43.3, Fail: 85.8 },
      { percentage: "Kannada", Pass: 83.1, Fail: 73.4 },
      { percentage: "Maths", Pass: 86.4, Fail: 65.2 },
      { percentage: "Social", Pass: 72.4, Fail: 53.9 },
      { percentage: "Science", Pass: 72.4, Fail: 53.9 },
      { percentage: "Hindi", Pass: 72.4, Fail: 53.9 },
    ],
  },
  xAxis: [
    {
      type: "category",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        color: "black",
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "Percentage",
      min: 0,
      max: 100,
      interval: 25,
      axisLabel: {
        formatter: "{value}%",
      },
      textStyle: {
        color: "black",
        fontSize: 12,
        fontFamily: TEXT_FONT_FAMILY,
      },
    },
  ],
  series: [
    {
      type: "bar",
      itemStyle: {
        color: "#0891b2",
      },
    },
    {
      type: "bar",
      itemStyle: {
        color: "#a5f3fc",
      },
    },
  ],
};

const ClassWisePerformance = () => {


  return (
    <>
      <Title>Class Wise Performance</Title>
      <div className="class-wise-performance">
        <div className="row g-0 ">
          <div className="col">
            <img alt="/" src={Previous} />
          </div>
          <div className="col">
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={["dept"]}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  defaultValue="Third Internals (Test Name)"
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col class-wise-performance__next">
            <img alt="/" src={Next} />
          </div>
        </div>
        <div className="class-wise-performance__graph">
          <ReactEcharts option={options} />
        </div>
        <Button mode="print" />
        <Button mode="cancel" />
      </div>
    </>
  );
};

export default ClassWisePerformance;

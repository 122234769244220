import { gql } from "@apollo/client";

export const GetSwConfigVariableByInstId = gql`
  query GetSwConfigVariableByInstId($token: String!, $inst_id: ID!) {
    GetSwConfigVariableByInstId(token: $token, inst_id: $inst_id) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
    }
  }
`;
export const GetSwConfigPageInfo = gql`
  query GetSwConfigPageInfo($token: String!) {
    GetSwConfigPageInfo(token: $token) {
      id
      page_number
      page_name
      page_config_avialable
    }
  }
`;
export const GetSwConfigVariableByConfigKey = gql`
  query GetSwConfigVariableByConfigKey(
    $token: String!
    $inst_id: ID!
    $config_key: String!
  ) {
    GetSwConfigVariableByConfigKey(
      token: $token
      inst_id: $inst_id
      config_key: $config_key
    ) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
    }
  }
`;

export const GetSwConfigReferenceDataByKey = gql`
  query GetSwConfigReferenceDataByKey($token: String!, $config_key: String!) {
    GetSwConfigReferenceDataByKey(token: $token, config_key: $config_key) {
      id
      list_item_key
      list_item_no_of_items
      list_item_list
    }
  }
`;
export const GetSwEduateConfigVariableByConfigKey = gql`
  query GetSwEduateConfigVariableByConfigKey(
    $token: String!
    $config_key: String!
  ) {
    GetSwEduateConfigVariableByConfigKey(
      token: $token
      config_key: $config_key
    ) {
      id
      config_key
      config_form_label_caption
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_secondary_list_caption
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
    }
  }
`;
export const GetSwConfigReferenceData = gql`
  query GetSwConfigReferenceData($token: String!) {
    GetSwConfigReferenceData(token: $token) {
      id
      list_item_key
      list_item_no_of_items
      list_item_list
    }
  }
`;

export const GetSwConfigVariablesPageAssociByPageNumber = gql`
  query GetSwConfigVariablesPageAssociByPageNumber(
    $token: String!
    $page_number: Int!
    $inst_id: ID!
  ) {
    GetSwConfigVariablesPageAssociByPageNumber(
      token: $token
      page_number: $page_number
      inst_id: $inst_id
    ) {
      config_details {
        id
        config_key
        config_form_label_caption
        config_key_used_for_module
        config_key_data_storage_type
        config_depends_on_primary_list_key
        config_depends_on_secondary_list_key
        config_primary_list_key
        config_secondary_list_key
        config_boolean_value
        config_integer_value
        config_double_value
        config_string_value
        config_string_value_2
        config_key_show_index
        config_secondary_list_caption
      }
    }
  }
`;
export const GetSwConfigPageInfoByPageNumber = gql`
  query GetSwConfigPageInfoByPageNumber($token: String!, $page_number: Int!) {
    GetSwConfigPageInfoByPageNumber(token: $token, page_number: $page_number) {
      id
      page_number
      page_name
      page_config_avialable
    }
  }
`;

export const GetSwConfigVariableByModuleName = gql`
  query GetSwConfigVariableByModuleName(
    $token: String!
    $inst_id: ID!
    $module_name: String!
  ) {
    GetSwConfigVariableByModuleName(
      token: $token
      inst_id: $inst_id
      module_name: $module_name
    ) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
      config_secondary_list_caption
    }
  }
`;

export const GetSwConfigVariableModuleNames = gql`
  query GetSwConfigVariableModuleNames($token: String!, $inst_id: ID!) {
    GetSwConfigVariableModuleNames(token: $token, inst_id: $inst_id)
  }
`;
export const GetSwConfigVariableByAccessLevel = gql`
  query GetSwConfigVariableByAccessLevel(
    $token: String!
    $inst_id: ID!
    $access_level: Int!
  ) {
    GetSwConfigVariableByAccessLevel(
      token: $token
      inst_id: $inst_id
      access_level: $access_level
    ) {
      id
      config_boolean_value
      config_key
    }
  }
`;

export const GetSwConfigVariables = gql`
  query GetSwConfigVariables(
    $token: String!
    $inst_id: ID!
    $input: ConfigQuery!
  ) {
    GetSwConfigVariables(token: $token, inst_id: $inst_id, input: $input) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
      config_secondary_list_caption
    }
  }
`;
export const GetSwEmpConfigVariables = gql`
  query GetSwEmpConfigVariables(
    $token: String!
    $inst_id: ID!
    $pr_emp_id: ID!
    $input: EmpConfigQuery!
  ) {
    GetSwEmpConfigVariables(
      token: $token
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
      input: $input
    ) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
      config_secondary_list_caption
    }
  }
`;
export const GetSwConfigThankYouMsg = gql`
  query GetSwConfigThankYouMsg($inst_id: ID!) {
    GetSwConfigThankYouMsg(inst_id: $inst_id) {
      branch_label
      class_label
      thank_you_msg
    }
  }
`;

export const GetSwConfigVariablesForScan = gql`
  query GetSwConfigVariablesForScan($inst_id: ID!, $input: ConfigQuery!) {
    GetSwConfigVariablesForScan(inst_id: $inst_id, input: $input) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
      config_secondary_list_caption
    }
  }
`;
export const GetSwEduateConfigVariables = gql`
  query GetSwEduateConfigVariables($token: String!) {
    GetSwEduateConfigVariables(token: $token) {
      id
      config_key
      config_form_label_caption
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_secondary_list_caption
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
    }
  }
`;

export const GetTimeZone = gql`
  query GetTimeZone(
    $token: String!
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $orderBy: SwTimeZoneOrder
    $name: String
  ) {
    GetTimeZone(
      token: $token
      after: $after
      first: $first
      before: $before
      last: $last
      orderBy: $orderBy
      where: { or: [{ timezoneContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          timezone
        }
      }
    }
  }
`;

import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import LoadingModal from "../../../../../pages/LoadingModal";
import InstDetails from "../../../DailyActivities/InstDetails";
import StudentListForReportCards from "./StudentList";
import {
  StudentListFor,
  StudentReportType,
} from "../../../../../utils/Enum.types";
import useAcdReportTestDetails from "./hooks";
const TestDetails = () => {
  const { data: TestDetails, loading } = useAcdReportTestDetails();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Allocated Tests</Title>
      <div className="report-cards__test-details">
        <InstDetails />
        <div className="report-cards__test-details--flex">
          {TestDetails
            ? TestDetails.GetAcdReportTests.map((res, index) => {
                return (
                  <span className="report-cards__test-details--test-name">
                    {res.test_name_details.test_name}
                  </span>
                );
              })
            : null}
        </div>
        <StudentListForReportCards
          studentListFor={StudentListFor.GENERAL}
          queryType={StudentReportType.GENERAL}
        />
      </div>

      <LoadingModal flag={loading} />
    </>
  );
};

export default TestDetails;

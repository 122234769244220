import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";
import InstDetails from "../InstDetails";
import Avatar from "../../../../images/Avatar.svg";
import ClassTeacher from "../../../../images/Class_Teacher.svg";
import Input from "../../../../stories/Input/Input";
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { Direction, SortBy, TableHeaders } from "../../../../utils/Enum.types";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { Button } from "../../../../stories/Button/Button";
import PerStudentDetails from "./PerStudentDetails";
import { useLazyQuery } from "@apollo/client";
import { GetAcdTeacherSubjectClassAssociations } from "../../queries/subjects/List.tsx/Index";
import {
  GetAcdSubjectForEntryLevelVars,
  GetAcdTeacherSubjectClassAssociationsData,
} from "../../types/subject/Subject";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import {
  AcdStudentsvars,
  GetStudentAcdData,
  StudentAcdNode,
  StudentEdges,
} from "../../hooks/useAcdStudentsData";
import { GetAcdStudents } from "../../queries/students/Index";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import { payloadTypes } from "../../../../context/reducer";
import { AttendanceDrawer } from "../../../../styles/DrawerStyles";
 

interface props {
  selectedSubjectId: number;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const StudentList = ({ selectedSubjectId, setModalFlag }: props) => {
  const [drawer, setDrawer] = useState(false);
  const [searchData, setSearchData] = useState("");
  const { entryId, InstId } = useParams();
  const { token } = useToken();
  const { entry_level } = useInstitutionConfiguration();
  const { state, dispatch } = useContext(AppContext);
  const { flag } = useCheckAllocationType();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);

  const [studentRows, setStdRows] = useState<GridValidRowModel[]>([]);

  const [GetSubjectsTeachersAssociated, { data: Tdata }] = useLazyQuery<
    GetAcdTeacherSubjectClassAssociationsData,
    GetAcdSubjectForEntryLevelVars
  >(GetAcdTeacherSubjectClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: Number(entryId)!,
      entry_level: entry_level,
      inst_id: InstId!,
      per_std_subj_allocation: flag,
      token,
    },
  });

  const [GetStudentsData, { data, loading, fetchMore }] = useLazyQuery<
    GetStudentAcdData,
    AcdStudentsvars
  >(GetAcdStudents);

  const subjTeacherLabel =
    Tdata &&
    Tdata.GetAcdTeacherSubjectClassAssociations.filter(
      (data) => data.subj_master_id === selectedSubjectId
    );

  useEffect(() => {
    if (token && entry_level) {
      GetStudentsData({
        variables: {
          after: null,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          first: ROWS_PER_PAGE,
          name: searchData,
          token,
          orderBy: [
            {
              direction: Direction.ASC,
              field: SortBy.STD_ROLL_NO,
            },
          ],
          input: {
            ids: [Number(InstId), Number(entryId), selectedSubjectId],
            acd_std_query_type:
              StudentAcdType.PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL,
            str_data: [entry_level],
          },
        },
      });
    }
  }, [
    token,
    GetStudentsData,
    InstId,
    entryId,
    state.ActiveAcdYr,
    searchData,
    selectedSubjectId,
    entry_level,
  ]);

  useEffect(() => {
    if (
      entryId &&
      entry_level &&
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetSubjectsTeachersAssociated();
    }
  }, [
    entryId,
    GetSubjectsTeachersAssociated,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    entry_level,
    state.ActiveAcdYr,
    flag,
  ]);

  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetAcdStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) =>
              student.node.id === (newStudent.node as StudentAcdNode).id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: true,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData as StudentEdges[]);
        setStdRows(
          (updatedNewData || []).map((response, index) => {
            return {
              id: index + 1,
              adm_no: response.node.std_adm_no,
              reg_no: response.node.std_reg_no,
              std_name: `${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`,
              std_id: response.node.id,
            };
          }) || []
        );
      } else {
        setStdRows(
          (newData || []).map((response, index) => {
            return {
              id: index + 1,
              adm_no: response.node.std_adm_no,
              reg_no: response.node.std_reg_no,
              std_name: `${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`,
              std_id: response.node.id,
            };
          }) || []
        );
        setStudents(newData as StudentEdges[]);
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);

  const stdColumns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-name td-onclick",
      flex: 1,
    },
  ];

  const stdRows: GridValidRowModel[] =
    (students || []).map((response, index) => {
      return {
        id: index + 1,
        adm_no: response.node.std_adm_no,
        reg_no: response.node.std_reg_no,
        std_name: `${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`,
        std_id: response.node.id,
      };
    }) || [];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents?.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && studentRows && studentRows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [studentRows]
  );
  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "std_name") {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: { studentId: params.row.std_id },
      });
      const selectedRow = stdRows.find((row) => row.id === params.id);

      if (selectedRow) {
        const stdId = selectedRow.std_id;

        // setSelectedStudentDetails(selectedRow);
        // dispatch({
        //   type: payloadTypes.SET_STUDENT_ID,
        //   payload: {
        //     studentId: stdId!,
        //   },
        // }
        // );
        setDrawer(!drawer);
      }
    }
  };

  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetAcdStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) =>
              student.node.id === (newStudent.node as StudentAcdNode).id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: true,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData as StudentEdges[]);
      } else {
        setStudents(newData as StudentEdges[]);
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);

  return (
    <>
      <div className="sub-details__std-list">
        <div className="sub-details__std-list--title">
          <Title>Student's List</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <InstDetails />
        {subjTeacherLabel && subjTeacherLabel.length
          ? subjTeacherLabel[0].Teachers.map((teacher) => {
              return (
                <div className="sub-details__std-list--subtitle">
                  <Title variant="subtitle1">
                    <b>{`${teacher.emp_first_name} ${teacher.emp_middle_name} ${teacher.emp_last_name}`}</b>
                  </Title>
                  <span>( {`${teacher.emp_id}`} )</span>
                </div>
              );
            })
          : null}

        {subjTeacherLabel && subjTeacherLabel.length
          ? subjTeacherLabel[0].Teachers.map((teacher) => {
              return (
                <div className="sub-details__std-list--classteacher">
                  <>
                    <div className="sub-details__std-list--classteacher--profile">
                      <img src={ClassTeacher} alt="" />
                      <div className="sub-details__std-list--classteacher--profile--image">
                        <img src={Avatar} alt="" />
                      </div>
                      <div className="sub-details__std-list--classteacher--profile--details">
                        <b>{`${teacher.emp_first_name} ${teacher.emp_middle_name} ${teacher.emp_last_name}`}</b>
                        <span>{teacher.emp_id}</span>
                      </div>
                    </div>
                    <span className="sub-details__std-list--classteacher--desc">
                      Subject Teacher is managing the DailyActivities within the
                      Subject
                    </span>
                  </>
                </div>
              );
            })
          : null}
        <div className="row g-0">
          <div className="col-3">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>
        </div>
        <div className={`sub-details__std-list--tableblock`}>
          <StyledDatagrid
            columns={stdColumns}
            rows={stdRows}
            rowHeight={TABLE_ROW_HEIGHT}
            onCellClick={(params: GridCellParams) => {
              handleCellClick(params);
            }}
            
          />
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <AttendanceDrawer
   
        anchor="right"
        open={drawer}
        onClose={() => setDrawer(!drawer)}
      >
        <PerStudentDetails setModal={setDrawer} />
      </AttendanceDrawer>
    </>
  );
};

export default StudentList;

import { Box, IconButton, useTheme } from "@mui/material";
// import LastPageIcon from "@mui/icons-material/LastPage";
// import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}
export const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  // const handleFirstPageButtonClick = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   onPageChange(event, 0);
  // };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    onPageChange(event, page - 1);

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  // const handleLastPageButtonClick = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  // };

  return (
    (<Box sx={{ flexShrink: 0, ml: 5.5 }}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="firstpage"
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon aria-label="firstpage" />
        ) : (
          <FirstPageIcon aria-label="firstpage" />
        )}
      </IconButton> */}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previouspage"
        size="large">
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight aria-label="previouspage" />
        ) : (
          <KeyboardArrowLeft aria-label="previouspage" />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="nextpage"
        size="large">
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft aria-label="nextpage" />
        ) : (
          <KeyboardArrowRight aria-label="nextpage" />
        )}
      </IconButton>
      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="lastpage"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon aria-label="lastpage" />
        ) : (
          <LastPageIcon aria-label="lastpage" />
        )}
      </IconButton> */}
    </Box>)
  );
};

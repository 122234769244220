import { gql } from "@apollo/client";

export const AddAcdInstSessions = gql`
  mutation AddAcdInstSessions(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateAcdInstSessionsInput!
  ) {
    AddAcdInstSessions(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdateAcdInstSessions = gql`
  mutation UpdateAcdInstSessions(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateAcdInstSessionsInput!
  ) {
    UpdateAcdInstSessions(
      token: $token
      inst_id: $inst_id
      id: $id
      user_details: $user_details
      input: $input
    )
  }
`;

export const DeleteAcdInstSessionsById = gql`
  mutation DeleteAcdInstSessionsById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdInstSessionsById(
      token: $token
      inst_id: $inst_id
      id: $id
      user_details: $user_details
    )
  }
`;

export const ReOrderAcdInstSessions = gql`
  mutation ReOrderAcdInstSessions(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [ReOrderAcdInstSessions!]!
  ) {
    ReOrderAcdInstSessions(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

import { ListFinancialYears } from "../../../../../Types/Accounting";
import { InstitutionDetails } from "../../../../../utils/Enum.types";
import { optionsType, responseType } from "../../../../../utils/Form.types";
import { AcctLedgerQueryType } from "../../../../Accounts/common/QueryTypes";

export enum payloadType {
  PASS_OUT = "PASS_OUT",
  PARAM_OPTION = "PARAM_OPTION",
  STATUS = "STATUS",
  SORT_BY = "SORT_BY",
  CASTE = "CASTE",
  RELIGION = "RELIGION",
  FIELDS = "FIELDS",
  ABOVE_AGE = "ABOVE_AGE",
  BELOW_AGE = "BELOW_AGE",
  SET_SELECTED_FIN_YR = "SET_SELECTED_FIN_YR",
  VOUCHER_TYPE = "VOUCHER_TYPE",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
}
type reducerPayload = {
  [InstitutionDetails.DEPARTMENT]: {
    dept: responseType | null;
  };
  [InstitutionDetails.BRANCH]: {
    branch: responseType | null;
  };
  [InstitutionDetails.CLASS]: {
    class: responseType | null;
  };
  [InstitutionDetails.SEMESTER]: {
    sem: responseType | null;
  };
  [InstitutionDetails.SECTION]: {
    section: responseType | null;
  };
  [InstitutionDetails.CATEGORY]: {
    category: responseType | null;
  };
  [payloadType.STATUS]: {
    selectedStatus: string[];
  };
  [payloadType.SORT_BY]: {
    selectedSortBy: sortbyOptionsType;
  };
  [payloadType.CASTE]: {
    caste: optionsType | null;
  };
  [payloadType.RELIGION]: {
    religion: optionsType | null;
  };
  [payloadType.FIELDS]: {
    selectedFields: optionsType[];
  };
  [payloadType.ABOVE_AGE]: {
    aboveAge: number;
  };
  [payloadType.BELOW_AGE]: {
    belowAge: number;
  };
  [payloadType.BELOW_AGE]: {
    belowAge: number;
  };
  [payloadType.SET_SELECTED_FIN_YR]: {
    selectedFinYr: ListFinancialYears | null;
  };
  [payloadType.VOUCHER_TYPE]: {
    voucherType: VoucherOptionsType | null;
  };
  [payloadType.START_DATE]: {
    startDate: string;
  };
  [payloadType.END_DATE]: {
    endDate: string;
  };
};

export enum SortBy {
  STD_NAME = "STD_NAME",
  STD_ADM_NUM = "STD_ADM_NUM",
  STD_REG_NUM = "STD_REG_NUM",
  STD_ROLL_NUM = "STD_ROLL_NUM",
}
export type sortbyOptionsType = {
  label: string;
  value: SortBy;
};

export enum VoucherType {
  BANK = "Bank",
  CASH = "Cash",
  GENERAL = "General",
  FEELEDGER = "Fee Ledger",
}

export type VoucherOptionsType = {
  label: VoucherType;
  value: AcctLedgerQueryType;
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type Actions =
  ActionMap<reducerPayload>[keyof ActionMap<reducerPayload>];

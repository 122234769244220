import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import { GetAcctLdgrsForNonDemand } from "../queries/FeeLedgers/query/Byid";
import { FeeLedgerListNode } from "./useAcctLedgerData";
interface GetAcctLdgrsForNonDemandDetails {
  GetAcctLdgrsForNonDemand: FeeLedgerListNode[];
}
const useAcctLdgrsForCaptation = (
  acct_ldgr_query_type: AcctLedgerQueryType
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [GetLedgers, { data, loading, error }] =
    useLazyQuery<GetAcctLdgrsForNonDemandDetails>(GetAcctLdgrsForNonDemand);
  useEffect(() => {
    if (token && acct_ldgr_query_type) {
      GetLedgers({
        variables: {
          token,
          input: {
            acct_ldgr_query_type,
            ids: [InstId],
          },
        },
      }).then(({ data, error }) => {
        if (data) {
          setResponseType(
            data.GetAcctLdgrsForNonDemand.filter((item) => item !== null).map(
              (demand) => ({
                label: demand.ldgr_desc,
                value: demand.id,
              })
            )
          );
        }
      });
    }
  }, [token, acct_ldgr_query_type, InstId, GetLedgers, data]);
  return { NonDemandCapLedgers: { data, loading, error, responseType } };
};

export default useAcctLdgrsForCaptation;

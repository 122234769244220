import React from "react";
import Navbar from "../../../components/common/Navbar/Index";
import { LicenseTypes } from "../../../utils/Enum.types";
import StudentEnquiryDashBoard from "../Dashboard/Index";

interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  return (
    <>
      <Navbar NavType={LicenseTypes.ENQUIRY} />
      {DashBoardRequired && (
        <StudentEnquiryDashBoard />
      )}
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const GetPayRollEmpDailyDiary = gql`
  query GetPayRollEmpDailyDiary(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $pr_emp_id: ID!
    $input: EmpDailyDiaryQueryType!
  ) {
    GetPayRollEmpDailyDiary(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      pr_emp_id: $pr_emp_id
      input: $input
    ) {
      id
      date_of_diary
      question_idx
      question
      answer
      inst_id
      pr_acd_yr_id
      pr_emp_id
    }
  }
`;
export const GetPayRollEmpDailyDiaryTodayStatus = gql`
  query GetPayRollEmpDailyDiaryTodayStatus(
    $token: String!
    $date_of_diary: Time!
    $input: EmpDailyDiaryStatusQuery
  ) {
    GetPayRollEmpDailyDiaryTodayStatus(
      token: $token
      date_of_diary: $date_of_diary
      input: $input
    )
  }
`;
export const GetPayRollEmpDailyDiaryStatus = gql`
  query GetPayRollEmpDailyDiaryStatus(
    $token: String!
    $input: EmpDailyDiaryStatusQuery!
  ) {
    GetPayRollEmpDailyDiaryStatus(token: $token, input: $input) {
      id
      day_1
      day_2
      day_3
      day_4
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
      pr_emp_id
      pr_acd_yr_id
      inst_id
      pr_cal_month_id
    }
  }
`;
export const DairyDetailsById = gql`
  query DairyDetailsById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on PayRollEmpDailyDiary {
        id
        date_of_diary
        question_idx
        question
        answer
        inst_id
        pr_acd_yr_id
        pr_emp_id
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import Avatar from "../../../../../../images/Avatar.svg";
import { Title } from "../../../../../../stories/Title/Title";
import Configurations from "../../../../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../../../../HR/enums/Enum.types";
import {
  FileUploadParams,
  ModuleName,
  PageFor,
} from "../../../../../../utils/Enum.types";
import useIDCardSwConfig from "../../../hooks/Index";
import useUpdateIdCardTemplate from "../../../hooks/useUpdateIdCardTemplate";
import MessageModal from "../../../../../../pages/MessageModal";
import useSwIdCardTemplates from "../../../hooks/useSwIdCardTemplates";
import useInstDetails from "../../../../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../../../../customhooks/useInstLogoDetails";
import { EMPTY_STRING } from "../../../../../../utils/constants";

import useToken from "../../../../../../customhooks/useToken";
import { StudentEdges } from "../../../../../Academics/hooks/useAcdStudentsForAdmission";
export interface AcdStudentDetails {
  first_name: string;
  middle_name: string;
  last_name: string;
  std_adm_no: string;
  std_father_name: string;
  std_mobile: string;
  std_dob: string;
  std_addr: string;
  acd_class: string;
  acd_section: string;
  url: string;
}
export enum IdTemplates {
  LIST = "LIST",
  DEMO = "DEMO",
  PRINT = "PRINT",
}
export enum IdTemplatesSides {
  FRONT = "FRONT",
  BACK = "BACK",
  BOTH = "BOTH",
}

export interface IdTemplateProps {
  templateFor: IdTemplates;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSideForPrint: IdTemplatesSides;
  pageType: PageFor;
  templateId: number | null;
  templateName: string;
  selectedStudents: StudentEdges[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const Template1 = ({
  templateFor,
  setModal,
  selectedSideForPrint,
  pageType,
  templateName,
  selectedStudents,
  setStudentIdSet,
}: IdTemplateProps) => {
  const {
    customerName,
    instName,
    instLogo,
    fatherName,
    admNo,
    className,
    sectionName,
    instAddress,
    studentAddress,
    mobileNo,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    footerText,
    EnableFooterText,
  } = useIDCardSwConfig();

  const { message, handleClose, handleUpdate } =
    useUpdateIdCardTemplate(templateName);
  const { swIdCardDetails } = useSwIdCardTemplates();
  const { InstFormData } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { token } = useToken();
  const [studentData, setStudentData] = useState<AcdStudentDetails[]>([]);
  const { LogoOrSign: PrincipalSignature } = useInstLogoDetails({
    filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
  });

  useEffect(() => {
    if (token && selectedStudents) {
      setStudentData(
        selectedStudents.map((std) => ({
          std_adm_no: std.node.std_adm_no,
          first_name: std.node.first_name,
          middle_name: std.node.middle_name,
          last_name: std.node.last_name,
          std_father_name: std.node.std_father_name,
          std_mobile: std.node.std_mobile,
          std_addr: std.node.std_details.std_address,
          std_dob: std.node.std_dob,
          acd_class: std.node.acd_class.class_desc,
          acd_section: std.node.acd_section.section_desc,
          url: std.node.url ? std.node.url : Avatar,
        }))
      );
    } else {
      setStudentData([
        {
          std_adm_no: "XXXXXX",
          first_name: "XXXXXX",
          middle_name: "XXXXXX",
          last_name: "XXXXXX",
          std_father_name: "XXXXXX",
          std_mobile: "XXXXXX",
          std_addr: "XXXXXX",
          std_dob: "XXXXXX",
          acd_class: "XXXXXX",
          acd_section: "XXXXXX",
          url: Avatar,
        },
      ]);
    }
  }, [token, selectedStudents, Avatar]);

  return (
    <>
      <div
        className={
          templateFor === IdTemplates.DEMO ? "id-card-template__md" : "h-100"
        }
      >
        {studentData.map((res) => (
          <div className="id-template-1">
            <div className="id-template-1__card">
              {selectedSideForPrint === IdTemplatesSides.FRONT ||
              selectedSideForPrint === IdTemplatesSides.BOTH ? (
                <div
                  className={`id-template-1__card--fs${
                    templateFor === IdTemplates.DEMO ? "" : "--li"
                  }`}
                >
                  {pageType === PageFor.PRINT ? null : (
                    <Title variant="subtitle1">
                      {templateFor === IdTemplates.LIST
                        ? swIdCardDetails?.GetSwIdCardTemplates[0].id_tpl_desc
                        : "Page 1"}
                    </Title>
                  )}
                  <div className="id-template-1__card--fs--id">
                    <div className="id-template-1__card--fs--id--profile">
                      <div
                        className="id-template-1__card--fs--id--inst-details"
                        style={{
                          paddingTop: `${marginTop}px`,
                          paddingLeft: `${marginLeft}px`,
                          paddingRight: `${marginRight}px`,
                        }}
                      >
                        {instLogo && (
                          <div className="id-template-1__card--fs--id--inst-logo">
                            {LogoOrSign.defaultLogo ? (
                              <img src={LogoOrSign.defaultLogo} alt="/" />
                            ) : null}
                          </div>
                        )}
                        <div className="id-template-1__card--fs--id--inst-details--name">
                          {customerName && (
                            <span className="id-template-1__card--fs--id--c-name">
                              {InstFormData
                                ? InstFormData.cust_name
                                : EMPTY_STRING}
                            </span>
                          )}
                          {instName ? (
                            <b className="id-template-1__card--fs--id--inst-name">
                              {InstFormData
                                ? InstFormData.inst_name
                                : EMPTY_STRING}
                            </b>
                          ) : null}
                        </div>
                      </div>
                      <div className="id-template-1__card--fs--id--std-image">
                        <img src={res.url} alt="" />
                      </div>
                      <div
                        className="id-template-1__card--fs--id--grid"
                        style={{
                          paddingLeft: `${marginLeft}px`,
                          paddingRight: `${marginRight}px`,
                        }}
                      >
                        {admNo ? (
                          <>
                            <label>Adm No</label>
                            <span>:</span>
                            <b>{res.std_adm_no}</b>
                          </>
                        ) : null}

                        <label>Name</label>
                        <span>:</span>
                        <b>
                          {res.first_name + res.middle_name + res.last_name}
                        </b>
                        {className ? (
                          <>
                            <label>Class</label>
                            <span>:</span>
                            <b>{res.acd_class}</b>
                          </>
                        ) : null}

                        {sectionName ? (
                          <>
                            <label>Section</label>
                            <span>:</span>
                            <b>{res.acd_section}</b>
                          </>
                        ) : null}
                        {mobileNo ? (
                          <>
                            <label>Mob No</label>
                            <span>:</span>
                            <b>{res.std_mobile}</b>
                          </>
                        ) : null}

                        {fatherName ? (
                          <>
                            <label>Father</label>
                            <span>:</span>
                            <b>{res.std_father_name}</b>
                          </>
                        ) : null}
                        {studentAddress ? (
                          <>
                            <label>Addr</label>
                            <span>:</span>
                            <span>{res.std_addr}</span>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="id-template-1__card--fs--id--footer"
                      style={{
                        paddingLeft: `${marginLeft}px`,
                        paddingRight: `${marginRight}px`,
                      }}
                    >
                      {/* <span>
                        {InstFormData ? InstFormData.inst_email : EMPTY_STRING}
                      </span> */}
                    </div>
                  </div>
                </div>
              ) : null}

              {(templateFor === IdTemplates.DEMO ||
                selectedSideForPrint === IdTemplatesSides.BACK ||
                selectedSideForPrint === IdTemplatesSides.BOTH) && (
                <div className="id-template-1__card--bs">
                  {pageType === PageFor.PRINT ? null : (
                    <Title variant="subtitle1">Page 2</Title>
                  )}
                  <div className="id-template-1__card--bs--id">
                    <div
                      className="id-template-1__card--fs--id--profile"
                      style={{
                        paddingTop: `${marginTop}px`,
                        paddingLeft: `${marginLeft}px`,
                        paddingRight: `${marginRight}px`,
                        paddingBottom: `${marginBottom}px`,
                      }}
                    >
                      <div className="id-template-1__card--fs--id--inst-details">
                        {instLogo ? (
                          <div className="id-template-1__card--fs--id--inst-logo">
                            {LogoOrSign.defaultLogo ? (
                              <img src={LogoOrSign.defaultLogo} alt="/" />
                            ) : null}
                          </div>
                        ) : null}
                        <div className="id-template-1__card--fs--id--name">
                          {customerName ? (
                            <>
                              <span className="id-template-1__card--fs--id--c-name">
                                {InstFormData
                                  ? InstFormData.cust_name
                                  : EMPTY_STRING}
                              </span>
                            </>
                          ) : null}
                          {instName ? (
                            <b className="id-template-1__card--fs--id--inst-name">
                              {InstFormData
                                ? InstFormData.inst_name
                                : EMPTY_STRING}
                            </b>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={
                          EnableFooterText
                            ? "id-template-1__card--fs--id--inst-address"
                            : "id-template-1__card--fs--id--inst-address--without-footer"
                        }
                      >
                        <div className="id-template-1__card--fs--id--grid">
                          {instAddress ? (
                            <>
                              <label>Addr</label>
                              <span>:</span>
                              <span className="id-template-1__card--fs--id--addr">
                                {InstFormData
                                  ? InstFormData.inst_address
                                  : EMPTY_STRING}
                              </span>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="id-template-1__card--fs--id--signature">
                        {PrincipalSignature.defaultLogo ? (
                          <img src={PrincipalSignature.defaultLogo} alt="/" />
                        ) : null}
                        <span>PRINCIPAL</span>
                      </div>
                      {EnableFooterText ? (
                        <div className="id-template-1__card--fs--id--footer-text">
                          {footerText}
                        </div>
                      ) : null}
                    </div>
                    <div className="id-template-1__card--fs--id--footer">
                      <span>
                        {/* {InstFormData ? InstFormData.inst_email : EMPTY_STRING} */}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {templateFor === IdTemplates.DEMO && (
              <div className="id-template-1__button">
                <button onClick={handleUpdate}>Use This Template</button>
              </div>
            )}
          </div>
        ))}
      </div>
      {templateFor === IdTemplates.DEMO && (
        <div className="id-card-template__rs">
          <Title variant="subtitle1">Configurations</Title>
          <Configurations
            config_query_type={SwConfigQueryType.INST_BY_MODULE}
            str_value={ModuleName.IDCARD_PRINT}
            int_value={0}
            setModalFlag={setModal}
            pageType={PageFor.GENERAL}
          />
        </div>
      )}
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Template1;

import { gql } from "@apollo/client";

export const GetLibBookMasterKeywords = gql`
  query GetLibBookMasterKeywords(
    $token: String!
    $lib_book_classification_id: ID!
  ) {
    GetLibBookMasterKeywords(
      token: $token
      lib_book_classification_id: $lib_book_classification_id
    ) {
      lib_book_classification_id
      lib_keyword_id
      inst_id
      key_words {
        id
        keyword
        inst_id
      }
    }
  }
`;

import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";

import Input from "../../../../components/common/Input/Index";
import { LabelNameProps } from "../../../../Types/Labels";
import { Operation } from "../../../../utils/Enum.types";
import EmployeeDetails from "./EmployeeDetails";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../../Home/Index";
import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import { UpdatePayRollEmpAcdProfDetails } from "../../queries/employee/mutation";
import { EmpAcdDetailsFormType } from "../../Types/formTypes";
import { Form, Formik } from "formik";
import { EMPTY_STRING } from "../../../../utils/constants";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";

import { stepHeader } from "../../constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRFormLabels } = require("../../json/form.json");

interface props {
  step: number;
  nextStep?: () => void;
  prevStep?: () => void;
  type?: Operation;
}

const AcademicContributions = ({ nextStep, prevStep, type, step }: props) => {
  const { token } = useToken();
  const { InstId, empId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const stepsHeader = stepHeader();
  const navigate = useNavigate();
  const [UpdateEmpPersonalData, { loading }] = useMutation(
    UpdatePayRollEmpAcdProfDetails
  );

  //useRefs
  const saveRef = useRef<HTMLButtonElement>(null);

  // eslint-disable-next-line
  const [formData, setFormData] = useState<EmpAcdDetailsFormType>({
    research_papers_published: 0,
    patents_no: 0,
    books_Authored_no: 0,
    certification_no: 0,
    workshops_no: 0,
    consultany_service: 0,
    projects_no: 0,
    awards_no: 0,
    resource_person: 0,
    remarks: EMPTY_STRING,
  });
  const { empolyeeData } = useEmpDetailsById();
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleUpdatePerosnalDetails = () =>
    UpdateEmpPersonalData({
      variables: {
        token,
        pr_emp_id: empId,
        inst_id: InstId,
        user_details,
        input: {
          ...formData,
        },
      },
    }).then(({ data }) => {
      if (data) {
        if (type === Operation.CREATE) {
          navigate(`/${InstId}/hr/employee/4/${empId}/otherdetails`);
        } else {
          navigate(
            `/${InstId}/hr/employee/${empId}/preview/academicContribution`
          );
        }
      }
    });

  const handleClear = () => {
    setFormData({
      research_papers_published: 0,
      patents_no: 0,
      books_Authored_no: 0,
      certification_no: 0,
      workshops_no: 0,
      consultany_service: 0,
      projects_no: 0,
      awards_no: 0,
      resource_person: 0,
      remarks: EMPTY_STRING,
    });
  };

  useEffect(() => {
    if (empolyeeData.data && !empolyeeData.loading) {
      const { academics_prof_details } = empolyeeData.data.node;
      setFormData({
        research_papers_published:
          academics_prof_details.research_papers_published,
        patents_no: academics_prof_details.patents_no,
        books_Authored_no: academics_prof_details.books_Authored_no,
        certification_no: academics_prof_details.certification_no,
        workshops_no: academics_prof_details.workshops_no,
        consultany_service: academics_prof_details.consultany_service,
        projects_no: academics_prof_details.projects_no,
        awards_no: academics_prof_details.awards_no,
        resource_person: academics_prof_details.resource_person,
        remarks: academics_prof_details.remarks,
      });
    }
    // eslint-disable-next-line
  }, [empolyeeData.data, empolyeeData.loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="employee-details">
        <Stepper alternativeLabel activeStep={step - 1}>
          {stepsHeader.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <EmployeeDetails />
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={""}
          onSubmit={handleUpdatePerosnalDetails}
        >
          {(meta) => {
            return (
              <Form className="employee-details__basic-details--frame">
                <div className="employee-details__basic-details--block">
                  <div className="details">
                    <h4>Research and Publications</h4>
                  </div>
                  <div className="employee-details__basic-details--block--frame">
                    {HRFormLabels.Employee.Academic_details.InputType.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              values={formData[label.inputName]}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              type={label.dataType}
                              required={label.required ? true : false}
                              autoFocus={label.autoFocus}
                              maxLength={label.maxLength}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                  <br />
                  <div className="details">
                    <h4>Professional Development</h4>
                  </div>
                  <div className="employee-details__basic-details--block--frame">
                    {HRFormLabels.Employee.Academic_details.InputType1.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              values={formData[label.inputName]}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              type={label.dataType}
                              required={label.required ? true : false}
                              autoFocus={label.autoFocus}
                              maxLength={label.maxLength}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>

                <Button
                  mode={type === Operation.CREATE ? "save-continue" : "update"}
                  type="submit"
                  buttonref={saveRef}
                />
                <Button mode="clear" type="button" onClick={handleClear} />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    if (type === Operation.CREATE) {
                      navigate(
                        `/${InstId}/hr/employee/1/${empId}/registration`
                      );
                    } else {
                      navigate(
                        `/${InstId}/hr/employee/${empId}/preview/academicContribution`
                      );
                    }
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>

      <LoadingModal flag={loading} />
    </>
  );
};

export default AcademicContributions;

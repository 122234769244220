import { styled } from "@mui/material";
import { DataGridPro, GridAlignment } from "@mui/x-data-grid-pro";

export const TEXT_FONT_FAMILY = "Josefin Sans !important";
export const HEADER_TEXT_ALIGN: GridAlignment = "center";
export const SLNO_TEXT_ALIGN: GridAlignment = "center";
export const MARKS_TEXT_ALIGN: GridAlignment = "center";

export const AMOUNT_TEXT_ALIGN: GridAlignment = "right";
export const NUMBER_FONT_FAMILY = "Spline Sans Mono";
export const TABLE_ROW_HEIGHT = 30;
export const TABLE_FONT_SIZE = 14;


export const StyledDatagrid = styled(DataGridPro)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders": {
    minHeight: "35px !important",
    maxHeight: "35px !important",
    lineHeight: "35px !important",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: 16,
    color: "white",
    fontWeight: 700,
  },

  "& .MuiDataGrid-columnHeader": {
    minHeight: "35px !important",
    maxHeight: "35px !important",
    lineHeight: "35px !important",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: 16,
    color: "white",
    fontWeight: 700,
    backgroundColor: "var(--level-7)",
    textAlign: "center !important",
    borderRight: "1px solid white",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },

  // "& .MuiDataGrid-withBorderColor": {
  //   display: "none !important",
  // },

  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid var(--border-color)",
    borderRight: "1px solid var(--border-color)",
    backgroundColor: "var(--level-1)",
    color: "var(--text-color)",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: TABLE_FONT_SIZE,
  },
  "& .MuiDataGrid-row": {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "#f9f9f9",
    // },
    "&:hover  .MuiDataGrid-cell": {
      backgroundColor: "var(--level-2)",
      color: "var(--level-5)",
    },
    "&:hover .td-onClick": {
      textDecoration: "underline !important",
    },

    "& .Mui-selected": {
      backgroundColor: "var(--level-4)",
      color: "var(--level-5)",
    },
  },

  "& .MuiDataGrid-pinnedRows": {
    position: "-webkit-sticky ",
    zIndex: 4,
    backgroundColor: " #fff",
    boxShadow: "0px -3px 4px -2px rgba(0, 0, 0, 0.21)",
    bottom: 0,
    "&:hover  .MuiDataGrid-cell": {
      backgroundColor: "transparent !important",
      color: "inherit",
    },
    "&:hover  .totalcount": {
      backgroundColor: "#4ade80 !important",
      color: "inherit",
    },
    "&:hover  .balance-count": {
      backgroundColor: "#4ade80 !important",
      color: "inherit",
    },
    "& .total":{
      borderRight:"none !important"
    }
 
  },
  "& .MuiDataGrid-footerContainer": {
    minHeight: TABLE_ROW_HEIGHT,
  },
  "& .MuiDataGrid-pagination": {
    backgroundColor: "var(--bg-color)", // Change pagination background color
    fontWeight: 700,
  },
  "& .MuiDataGrid-rowCount": {
    color: "var(--text-color)",
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: TABLE_FONT_SIZE,
  },
  "& .MuiList-root": {
    paddingTop: "5px !important",
  },
  "& .MuiButtonBase-root": {
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: TABLE_FONT_SIZE,
    "&:hover": {
      backgroundColor: "var(--level-2)",
      color: "var(--level-5)",
    },
  },
  "& .MuiDataGrid-selectedRowCount": {
    fontFamily: TEXT_FONT_FAMILY,
    fontSize: TABLE_FONT_SIZE,
  },
  "& button": {
    lineHeight: 0,
  },
}));

import React, { useContext, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Input from "../../../../stories/Input/Input";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Button } from "../../../../stories/Button/Button";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { optionsType } from "../../../../utils/Form.types";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const supplierType = [
  { label: "Sundry Debitor", value: "SUNDRY_DEBITORS" },
  { label: "Sundry Creditor", value: "SUNDRY_SUPPLIERS" },
];
const LedgerList = ({ setModalFlag }: Props) => {
  const [supplierName, setSupplierName] = useState("");
  const [queryType, setQueryType] = useState(supplierType[0]);
  const { Accounts_Table } = useAcctTableJson();
  const { dispatch } = useContext(AppContext);

  const { acctLedgers } = useAcctLedgerData(
    supplierName,
    queryType.value,
    ROWS_PER_PAGE
  );
  return (
    <>
      <Title>Sundry Debtor/Sundry Creditor Details</Title>
      <div className="purchase-entry__ledger-list">
        <div className="purchase-entry__ledger-list--filters row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => setSupplierName(e.target.value)}
            />
          </div>

          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={supplierType}
              value={queryType}
              onChange={(e, newValue) => {
                if (newValue) setQueryType(newValue as optionsType);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier Type"
                  fullWidth
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className="purchase-entry__ledger-list--tableblock">
          <TableContainer className="purchase-entry__ledger-list--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.PurchaseEntry.LedgerList.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {acctLedgers.data?.GetAcctLdgrs.edges.map((edge, index) => {
                  return (
                    <TableRow key={edge.node.id}>
                      <TableCell
                        className="purchase-entry__ledger-list--table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          dispatch({
                            type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                            payload: { accountLedgerId: edge.node.id },
                          });
                          setModalFlag(false);
                        }}
                      >
                        {edge.node.ldgr_desc}
                      </TableCell>
                      <TableCell>
                        {edge.node.ldgr_sundry_contact_person_mobile}
                      </TableCell>
                      <TableCell className="purchase-entry__ledger-list--table--mobile">
                        {edge.node.ldgr_sundry_contact_person_mobile}
                      </TableCell>
                      <TableCell className="purchase-entry__ledger-list--table--email">
                        {/* {edge.node.} */}
                      </TableCell>
                      <TableCell>{edge.node.ldgr_sundry_address}</TableCell>
                      <TableCell className="purchase-entry__ledger-list--table--number">
                        {edge.node.ldgr_sundry_gst_no}
                      </TableCell>
                      <TableCell className="purchase-entry__ledger-list--table--type"></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default LedgerList;

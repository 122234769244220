import { gql } from "@apollo/client";

export const GetIdCardTemplate = gql`
  query GetIdCardTemplate($token: String!, $inst_id: ID!) {
    GetIdCardTemplate(token: $token, inst_id: $inst_id) {
      id
      id_tpl_desc
      bg_color
      header_color
      footer_color
      text_color
      theme
      inst_id
    }
  }
`;
export const GetSwIdCardTemplates = gql`
  query GetSwIdCardTemplates($token: String!) {
    GetSwIdCardTemplates(token: $token) {
      id
      id_tpl_desc
      bg_color
      header_color
      footer_color
      text_color
      theme
    }
  }
`;

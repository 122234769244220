import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import CopyDemand from "../../../../images/CopyDemand.svg";

import { TableHeaderProps } from "../../../../Types/Tables";

import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
} from "../../../../utils/Enum.types";
import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import useSwConfigData from "../../../../customhooks/useSwConfigData";

import { SubjectDetailsNode } from "../../hooks/useAcademicSubjects";
import LoadingModal from "../../../../pages/LoadingModal";
import useSubjectAllotedForClass from "../../hooks/useSubjectAllotedForClass";
import { useParams } from "react-router-dom";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import { responseType } from "../../../../utils/Form.types";

import {
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
const { Academics_Table } = require("../../json/table.json");
export const enum TableHeaderTypes {
  Actions = "Actions",
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setItems: React.Dispatch<React.SetStateAction<SubjectDetailsNode[]>>;
  items: SubjectDetailsNode[];
}

const CopySubjectDetails = ({ setModalFlag, setItems, items }: Props) => {

  const { allotedID } = useParams();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const allotedLevel =
    configData.data?.GetSwConfigVariables[0].config_string_value;
  const { USE_DEPARTMENT_KEY } = useInstitutionConfiguration();
  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [alloted_id, setAllotedID] = useState(0);

  const { subjectsForClass } = useSubjectAllotedForClass(alloted_id);
  const {
    departmentDropDown,
    branchDropDown,
    classDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const copySubjects = () => {
    if (subjectsForClass.data) {
      setItems((prevValues) => [
        ...prevValues,
        ...subjectsForClass.data?.GetAcdSubjectAllotedForClass.map(
          (subject) => ({
            id: subject.subject_details.id!,
            subj_apptitude_avialed:
              subject.subject_details.subj_apptitude_avialed,
            subj_board_code: subject.subject_details.subj_board_code,
            subj_code: subject.subject_details.subj_code,
            subj_desc: subject.subject_details.subj_desc,
            subj_idx: subject.subject_details.subj_idx,
            subj_internals_avialed:
              subject.subject_details.subj_internals_avialed,
            subj_is_core_subject: subject.subject_details.subj_is_core_subject,
            subj_is_elective: subject.subject_details.subj_is_elective,
            subj_is_lang: subject.subject_details.subj_is_lang,
            subj_is_non_academic: subject.subject_details.subj_is_non_academic,
            subj_marks_in_grades:
              subject.subject_details.subj_marks_in_grades,
            isChecked: true,
            subj_is_lab: subject.subject_details.subj_is_lab,
            teacher_count: subject.subject_details.teacher_count,
            newAdded: true,
          })
        )!,
      ]);
      setModalFlag(false);
    }
  };
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  return (
    <>
      <Title>Copy Subject Details</Title>
      <div className="allocatesubjects__copy">
        <div className="row g-0 allocatesubjects__copy--details ">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                // ref={semRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                    // if (semesterSelected) {
                    //   sectionInputRef?.focus();
                    // }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);

                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              // ref={semRef!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                  // if (semesterSelected) {
                  //   sectionInputRef?.focus();
                  // }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(null);
                }
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          {displayClass ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown.filter(
                  ({ value }) => value !== Number(allotedID)
                )}
                // ref={semRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                    // if (semesterSelected) {
                    //   sectionInputRef?.focus();
                    // }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    if (allotedLevel === AcdSubjectAllocationData.CLASS) {
                      setAllotedID((newValue as responseType).value);
                    }
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {displaySemester ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown.filter(
                  ({ value }) => value !== Number(allotedID)
                )}
                // ref={semRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                    // if (semesterSelected) {
                    //   sectionInputRef?.focus();
                    // }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    if (allotedLevel === AcdSubjectAllocationData.SEMESTER) {
                      setAllotedID((newValue as responseType).value);
                    }
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}

          {displaySection ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={sectionDropDown.filter(
                  ({ value }) => value !== Number(allotedID)
                )}
                // ref={semRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                    // if (semesterSelected) {
                    //   sectionInputRef?.focus();
                    // }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    if (allotedLevel === AcdSubjectAllocationData.SECTION) {
                      setAllotedID((newValue as responseType).value);
                    }
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="allocatesubjects__copy--tableblock">
          <TableContainer className="allocatesubjects__copy--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.AllocateSubject.Table_Headers.filter(
                    (th: TableHeaderProps) =>
                      th.labelName !== TableHeaderTypes.Actions
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TableCell className={th.className}>
                          {th.labelName}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {subjectsForClass.data?.GetAcdSubjectAllotedForClass.map(
                  (item, index) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell
                          className="allocatesubjects__table--slno"
                          id="td-center">
                          {index + 1}
                        </TableCell>
                        <TableCell>{`${item.subject_details.subj_desc}(${item.subject_details.subj_code})`}</TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subject_details.subj_board_code}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          onClick={copySubjects}
          disabled={!(subjectsForClass.data && !subjectsForClass.loading)}>
          <img src={CopyDemand} alt="/" />
          Copy Subjects
        </Button>
        <Button mode="clear" />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <LoadingModal flag={subjectsForClass.loading} />
    </>
  );
};

export default CopySubjectDetails;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import useSwConfigData from "../../../customhooks/useSwConfigData";
import useToken from "../../../customhooks/useToken";
import {
  Direction,
  InstitutionConfigurationTypes,
  SortBy,
} from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import { GetAcdTestClass } from "../queries/test/query";
import { AcdSubjectMaster } from "../types/test";
import { PageInfo } from "../../../Types/Student/paginationTypes";
interface test_name_details {
  test_name: string;
  id: number;
  is_marks_derived: boolean;
  test_type_id: number;
  add_test_remarks: string;
  test_type_details: {
    test_type_name: string;
  };
}
interface class_subject_details {
  id: number;
  subj_sl: number;
  elective_subj_sl: number;
  subj_total_max_marks: number;
  subj_total_min_marks: number;
  test_date: string;
  enable_internal_test: boolean;
  subject_master_details: AcdSubjectMaster;
  elective_subject_details: AcdSubjectMaster;
  elective_main_subj_master_id: number;
  subj_master_id: number;
  subj_is_elective: boolean;
}

export interface AcdTestClassesDeriveDetails {
  id: number;

  derive_test_id: number;
}

export interface GetAcdTestClassDetails {
  id: number;
  test_end_date: string;
  test_start_date: string;
  // use this if the common marks else inside  class_subject_details structure
  // subj_lab_max_marks  subj_lab_min_marks subj_theory_max_marks subj_theory_min_marks;
  derived_method: string;
  out_of: number;
  avg_best_of_num: number;
  enable_teachers_comments: boolean;
  subj_total_min_marks: number;
  subj_total_max_marks: number;
  derived_test_count: number;
  test_name_details: test_name_details;
  marks_entered: boolean;
  apply_same_marks_all_subj: boolean;
  class_subject_details: class_subject_details[];
  derive_tests: AcdTestClassesDeriveDetails[];
}
export enum AcdTestConductQueryType {
  ALL_TESTS_BY_ACD_ALLOTTED_LEVEL = "ALL_TESTS_BY_ACD_ALLOTTED_LEVEL",
  BY_ACD_TEST_TYPE = "BY_ACD_TEST_TYPE",
  BY_ACD_TEST_NAME = "BY_ACD_TEST_NAME",
  NONE = "NONE",
}

export interface TestConductData {
  GetAcdTestClass: {
    edges: {
      cursor: string;
      node: GetAcdTestClassDetails;
    }[];
    totalCount: number;
    pageInfo: PageInfo;
  };
}
interface input {
  alloted_level: string;
  ids: number[];
  acd_test_class_query: AcdTestConductQueryType;
}
export interface TestConductVars {
  token: string;
  acd_yr_id: number;
  input: input;
  inst_id: string;
  after: string | null;
  first: number | null;
  orderBy: {
    direction: Direction;
    field: SortBy.TEST_START_DATE;
  };
}
const useTestClassData = (
  test_type_id: number,
  allotedId: number,
  queryType: AcdTestConductQueryType,
  sortBy?: Direction
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const [ids, setIds] = useState<number[]>([]);
  const [responseType, setResponseType] = useState<responseType[]>([]);

  const [GetTestTypesByTestId, { data, loading, error }] = useLazyQuery<
    TestConductData,
    TestConductVars
  >(GetAcdTestClass, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      input: {
        alloted_level:
          configData.data?.GetSwConfigVariables[0].config_string_value!,
        ids,
        acd_test_class_query: queryType,
      },
      token,
      inst_id: InstId!,
      after: null,
      first: null,
      orderBy: {
        direction: sortBy ?? Direction.DESC,
        field: SortBy.TEST_START_DATE,
      },
    },
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (allotedId)
      switch (queryType) {
        case AcdTestConductQueryType.ALL_TESTS_BY_ACD_ALLOTTED_LEVEL:
          setIds([Number(InstId ? InstId : state.InstId), allotedId]);
          break;
        case AcdTestConductQueryType.BY_ACD_TEST_NAME:
          setIds([Number(InstId), test_type_id, allotedId]);
          break;

        default:
          break;
      }
  }, [
    queryType,
    test_type_id,
    allotedId,
    InstId,
    state.studentId,
    state.InstId,
  ]);
  useEffect(() => {
    if (ids[0] > 0 && allotedId && state.ActiveAcdYr && token)
      GetTestTypesByTestId().then(({ data }) => {
        if (data && data.GetAcdTestClass) {
          setResponseType(
            data.GetAcdTestClass.edges.map(({ node }) => ({
              label: node.test_name_details.test_name,
              value: node.id,
            }))
          );
        }
      });
  }, [GetTestTypesByTestId, ids, allotedId, state.ActiveAcdYr, token, data]);

  return {
    testConductDetails: { data, loading, error, responseType },
  };
};

export default useTestClassData;

import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GetStudentsPassoutByInstId } from "../../../queries/students/list/byId";
import Edit from "../../../images/EditProfile.svg";

import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import Delete from "../../../images/Delete.svg";
import { useParams, useNavigate } from "react-router";
import { Title } from "../../../stories/Title/Title";

import { Button } from "../../../stories/Button/Button";
import { AppContext } from "../../../context/context";

import Input from "../../../stories/Input/Input";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../images/Eduate_Logo_image.png";
import {
  ColumnVisibilityFor,
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  GetStudentsPassoutByInstIdData,
  GetStudentsPassoutByInstIdVars,
} from "../../../Types/Student/paginationTypes";
import { StudentsTitlesType } from "../../../Types/Student/json";
import AddPassoutStudentModal from "./AddPassOutStudentModal";
import Home from "../Home/Index";
import useToken from "../../../customhooks/useToken";
import { payloadTypes } from "../../../context/reducer";
import { DeleteStudentPassout } from "../../../queries/students/mutations/update";
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import { msgType, responseType } from "../../../utils/Form.types";
import { GetInstDashBoardCounters } from "../../../queries/institution/masterDashboard/new";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import { AluminiStudentEdges } from "../../../Types/Student/paginationTypes";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import useUserRightsByEmpId from "../../UserRights/hooks/useUserRightsByEmpId";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { TextField } from "@mui/material";
import { Keys } from "../../../utils/Enum.keys";
import useDropdownData from "../../../customhooks/useDropdownData";
import StudentTotalCount from "./Components/StudentTotalCount";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useStudentTableJson from "../../../json/useStudentTableJson";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
const { Students } = require("../../../json/title.json");

interface Props {
  type: PageFor;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AluminiStudentList = ({ type, setStudentModal }: Props) => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const { dispatch, state } = useContext(AppContext);

  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_ALUMINI,
  });

  const { USE_ADMISSIONS } = useUserRightsByEmpId();
  const { InstDetails } = useInstDetails(1);
  const [searchData, setSearchData] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [deleteModal, setDeleteModal] = useState(false);
  const [students, setStudents] = useState<AluminiStudentEdges[]>([]);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const { branchDropDown } = useInstMasterDataByInstId();
  const { classDropDown } = useDropdownData(
    0,
    branchSelected ? branchSelected.value : 0,
    0,
    0
  );
  const { user_details } = useLoggedInUserDetails();

  const [GetStudentsPassout, { data, loading, fetchMore }] = useLazyQuery<
    GetStudentsPassoutByInstIdData,
    GetStudentsPassoutByInstIdVars
  >(GetStudentsPassoutByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      name: searchData,
      first: rowsPerPage,
      after: null,
      branch: branchSelected ? branchSelected.value : null,
      class: classSelected ? classSelected.value : null,
      orderBy: SortBy.STD_NAME,
      direction: Direction.ASC,
    },
  });
  const { USE_BRANCH_KEY, USE_CLASS_KEY } = useInstitutionConfiguration();
  const [DeleteStdPassout] = useMutation(DeleteStudentPassout, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const HandleDelete = (id: number) => {
    DeleteStdPassout({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudentsPassoutByInstId,
          variables: {
            token,
            inst_id: InstId!,
            name: EMPTY_STRING,
            first: ROWS_PER_PAGE,
            after: null,
            branch: null,
            class: null,
            orderBy: SortBy.STD_NAME,
            direction: Direction.ASC,
          },
        },
        {
          query: GetInstDashBoardCounters,
          variables: {
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Alumini Student Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const stdData = data?.GetStudentsPassoutByInstId.edges.map((data, index) => ({
    SlNo: index + 1,
    Name: data.node.std_name,
    Mobile: data.node.std_mobile,
    Email: data.node.std_email,
    Branch: data.node.branch_details.branch_desc,
    Class: data.node.class_details.class_desc,
    PassOutYear: data.node.std_passout_yr,
  }));
  const { branchLabel, classLabel } = useInstLabels();
  const studentCol = [
    { title: "Sl", field: "SlNo" },
    { title: "Name", field: "Name" },
    { title: "Mobile", field: "Mobile" },
    { title: "Email", field: "Email" },
    { title: branchLabel, field: "Branch" },
    { title: classLabel, field: "Class" },
    { title: "PassOutYear", field: "PassOutYear" },
  ];

  const AluminiListHeaders = [
    "SlNo",
    "Name",
    "Mobile",
    "E-Mail",
    branchLabel,
    classLabel,
    "PassOutYear",
  ];

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Alumini Students");

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.SlNo,
          item.Name,
          item.Mobile,
          item.Email,
          item.Branch,
          item.Class,
          item.PassOutYear,
        ])
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "G1:G3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:G2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:G3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Alumini Students";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell(F4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("F4:G4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < AluminiListHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = AluminiListHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
    setDeleteId(0);
  };
  useEffect(() => {
    if (token) {
      GetStudentsPassout();
    }
  }, [token, GetStudentsPassout, data]);

  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const studentId = params.row.std_id || "";

        return (
          <>
            {USE_ADMISSIONS.details?.edit && (
              <img
                src={Edit}
                alt="/"
                onClick={() => {
                  setOpenModal(!openModal);
                  setOperation(Operation.UPDATE);
                  dispatch({
                    type: payloadTypes.SET_ALUMINI_STUDENT_ID,
                    payload: {
                      aluminiStudentId: studentId,
                    },
                  });
                }}
              />
            )}
            {USE_ADMISSIONS.details?.delete && (
              <img
                src={Delete}
                alt="/"
                onClick={() => {
                  setDeleteId(studentId);
                  setDeleteModal(!deleteModal);
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading && excelFlag === false) {
      const newData = data.GetStudentsPassoutByInstId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,

            std_name: node.std_name,
            std_mobile: node.std_mobile,
            std_id: node.id,
            pass_out_year: node.std_passout_yr,
            std_email: node.std_email,
            branch: node.branch_details.branch_desc,
            class: node.class_details.class_desc,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,

            std_name: node.std_name,
            std_mobile: node.std_mobile,
            std_id: node.id,
            pass_out_year: node.std_passout_yr,

            std_email: node.std_email,
            branch: node.branch_details.branch_desc,
            class: node.class_details.class_desc,
          }))
        );
      }
      setEndCursor(data.GetStudentsPassoutByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading, data]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetStudentsPassoutByInstId.edges;
              const pageInfo =
                fetchMoreResult.GetStudentsPassoutByInstId.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck =
                prevResult.GetStudentsPassoutByInstId.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetStudentsPassoutByInstId: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data
                    ? data.GetStudentsPassoutByInstId.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      <div className="studentlist">
        {type === PageFor.MODAL ? null : <Home DashBoardRequired={false} />}
        <Title>
          {Students.Titles.map(
            (title: StudentsTitlesType, index: React.Key) => {
              return (
                <React.Fragment key={index}>{title.AluminiList}</React.Fragment>
              );
            }
          )}
        </Title>
        <form className="row g-0 alumini-student-list__select">
          <div className="col-1 studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) handleMUISelectEvent(e);
                  }

                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                  } else {
                    setClassSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="addnew"
              className="alumini-student-list__btn"
              autoFocus
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setOpenModal(!openModal);
                setOperation(Operation.CREATE);
              }}
            />
          </div>
        </form>

        <div
          id={
            type === PageFor.MODAL
              ? "aluministudentlist__modal--frame"
              : "aluministudentlist__frame"
          }
        >
          <div className={`h100`}>
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              onCellClick={(params) => {
                if (params.field === "std_name") {
                  type === PageFor.MODAL &&
                    dispatch({
                      type: payloadTypes.SET_ALUMINI_STUDENT_ID,
                      payload: {
                        aluminiStudentId: params.row.std_id,
                      },
                    });
                  setStudentModal(false);
                }
              }}
              disableRowSelectionOnClick
              hideFooter
            />
          </div>
        </div>
        <div className="row g-0">
          <div className="col">
            {type === PageFor.GENERAL ? (
              <>
                <div className="button-left">
                  <Button
                    onClick={() => {
                      setRowsPerPage(null);
                      setExcelFlag(true);
                    }}
                    mode="export"
                  />

                  <Button mode="back" onClick={() => navigate(-1)} />
                </div>
              </>
            ) : (
              <Button mode="cancel" onClick={() => setStudentModal?.(false)} />
            )}
          </div>
          <div className="col-2 alumini-student-list__total">
            <StudentTotalCount
              totalCount={data?.GetStudentsPassoutByInstId.totalCount!}
            />
          </div>
        </div>
      </div>

      <AddPassoutStudentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        operation={
          operation === Operation.CREATE ? Operation.CREATE : Operation.UPDATE
        }
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={deleteId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default AluminiStudentList;

import { gql } from "@apollo/client";
export const GetAcdStdCompleteTestReportForDerivedTest = gql`
  query GetAcdStdCompleteTestReportForDerivedTest(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_ids: [ID!]!
    $input: DerivedTestDetails!
  ) {
    GetAcdStdCompleteTestReportForDerivedTest(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_ids: $student_ids
      input: $input
    ) {
      student_id
      att_percentage
      student_details {
        id
        std_reg_no
        std_adm_no
        first_name
        middle_name
        last_name
        branch_desc
        class_desc
        section_desc
        dept_desc
        sem_desc
        cat_desc
        std_father_name
      }
      student_test_marks {
        acd_test_class_id
        test_start_date
        test_end_date
        test_type_name
        acd_test_name
        test_total_max_marks
        test_total_min_marks
        test_std_total_percentage
        test_std_total_marks_scored
        std_marks_details {
          subj_master_id
          subj_desc
          subj_code
          std_marks_scored
          marks_percentage
          subj_max_marks_scored
          subj_avg_marks_scored
          subj_total_max_marks
          subj_total_min_marks
        }
      }
    }
  }
`;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { TableHeaderProps } from "../../../Types/Tables";
import Institution from "../../../images/TotalInstitutions.svg";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { instAdmTotalStats } from "../../Master/Types";
import { useNavigate } from "react-router-dom";
import useMasterTableJson from "../../../json/useMasterTableJson";

interface Props {
  inst_adm_total_stats: instAdmTotalStats[];
}
const Admissions = ({ inst_adm_total_stats }: Props) => {
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();

  const { totalBoys, totalGirls, totalStudents } = inst_adm_total_stats
    .filter((d) => d !== null)
    .reduce(
      (acc, item) => {
        acc.totalBoys += item.boys_cur_std_count;
        acc.totalGirls += item.girls_cur_std_count;
        acc.totalStudents += item.total_cur_std_count;
        return acc;
      },
      {
        totalBoys: 0,
        totalGirls: 0,
        totalStudents: 0,
      }
    );

  return (
    <>
      <div className="proprietor-dashboard">
        <div className="row g-0 proprietor-dashboard__row">
          <div className="col-2">
            <Input id="search" placeholder="Search" />
          </div>
          <div className="col"></div>
          <div className="col-3 proprietor-dashboard__row--flex">
            <div className="proprietor-dashboard__row--total">
              <img src={Institution} alt="/" />
              <Label>Total Institution</Label>
              <Label variant="LabelBold">{inst_adm_total_stats.length}</Label>
            </div>
          </div>
        </div>
        <div className="proprietor-dashboard__tableblock">
          <TableContainer className="proprietor-dashboard__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Masters_Table.ProprietorDashboard.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {inst_adm_total_stats
                  .filter((d) => d !== null)
                  .map((inst, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          className="proprietor-dashboard__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="proprietor-dashboard__table--name"
                          onClick={() =>
                            navigate(`/${inst.inst_id}/admissions`)
                          }
                        >
                          <img src={Institution} alt="/" />
                          {inst.inst_name}
                        </TableCell>
                        <TableCell
                          className="proprietor-dashboard__table--total font-purple"
                          id="td-right"
                        >
                          {inst.boys_cur_std_count}
                        </TableCell>
                        <TableCell
                          className="proprietor-dashboard__table--total font-pink"
                          id="td-right"
                        >
                          {inst.girls_cur_std_count}
                        </TableCell>

                        <TableCell
                          className="proprietor-dashboard__table--total"
                          id="td-right"
                        >
                          {inst.total_cur_std_count}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell className="total" colSpan={2}>
                    Total:
                  </TableCell>
                  <TableCell className="proprietor-dashboard__table--total--boys">
                    {totalBoys}
                  </TableCell>
                  <TableCell className="proprietor-dashboard__table--total--girls">
                    {totalGirls}
                  </TableCell>
                  <TableCell className="proprietor-dashboard__table--total totalcount">
                    {totalStudents}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Admissions;

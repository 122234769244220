import { useState } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ExamPlanner from "../Modules/Academics/TestPlanner/Index";
import MonthlyList from "../Modules/Academics/Attendance/MonthlyList";

import HolidayEntry from "../Modules/Academics/calendar/HolidayEntry/Index";
import AllocateClassTeacher from "../Modules/Academics/AssignClassTeacher/Index";
import StudentTestReport from "../Modules/Academics/Reports/Test/Index";
import PerStudentAttendanceReport from "../Modules/Academics/Reports/Attendance/ByStudentId";
import CreateTestPlan from "../Modules/Academics/TestPlanner/CreateTest";
import AcdStudents from "../Modules/Academics/students/List";
import AcademicSubjectDetails from "../Modules/Academics/SubjectDetails/Index";
import AcademicsHome from "../Modules/Academics/Home/Index";
import EnterMarks from "../Modules/Academics/DailyActivities/Exam/EnterMarks";
import UpdateMarks, {
  updatedRemarks,
} from "../Modules/Academics/DailyActivities/Exam/UpdateMarks";
import AcademicsDailyActivities from "../Modules/Academics/DailyActivities/Index";
import TestType from "../Modules/Academics/Test/TestType/Index";
import ViewTestDetails, {
  ForReportOrDailyActivities,
} from "../Modules/Academics/Reports/Test/View";
import DailyActivitesHome from "../Modules/Academics/DailyActivities/Home";
import {
  AttendanceOverViewPageFor,
  EduateModule,
  MarksFrom,
  ModuleName,
  Operation,
  PageFor,
  StudentReportType,
  attendanceReportType,
} from "../utils/Enum.types";
import { SwConfigQueryType } from "../Modules/HR/enums/Enum.types";
import Configurations from "../Modules/Configurations/PerModuleConfiguration";
import useEduateModuleConfig from "../customhooks/useModulesConfiguartion";
import useUserRightsByEmpId from "../Modules/UserRights/hooks/useUserRightsByEmpId";
import Page404 from "../pages/Page404";
import ForAdmin from "../Modules/Academics/Attendance/ForAdmin";
import AllocateTeachers, {
  TeachersAssociationPageFrom,
} from "../Modules/Academics/AllocateTeachers/Index";
import SubjectAllocationDetails from "../Modules/Academics/SubjectDetails/Allocation/Index";
import TeacherAllocationTree from "../Modules/Academics/AllocateTeachers/TeacherAllocationTree";
import Planner from "../Modules/Academics/TestPlanner/Planner";
import AllocateSubjects from "../Modules/Academics/SubjectDetails/Allocation/AllocateSubjects";
import ViewDetails from "../Modules/Academics/DailyActivities/Assignments/ViewDetails";
import SessionsList from "../Modules/Academics/Sessions/Index";
import PerSessionDetails from "../Modules/Academics/DailyActivities/Attendance/SessionDetails/Index";
import CompleteReports from "../Modules/Academics/Reports/CompleteReports/Index";
import ViewCompleteReports from "../Modules/Academics/Reports/CompleteReports/Test/View";
import ViewParticularTestReport from "../Modules/Academics/Reports/CompleteReports/Test/ViewParticularTestReport";
import SessionAttendance from "../Modules/Academics/DailyActivities/Attendance/SessionAttendance";
import LessonPlanner from "../Modules/Academics/LessonPlanner/Index";
import Assign from "../Modules/Academics/LessonPlanner/Assign";
// import ProgressReport from "../Modules/Print/ProgressReport/Template1/Index";
import ViewDetailed from "../Modules/Academics/DailyDiary/View";
import ElectiveAllocationToStudent from "../Modules/Academics/SubjectDetails/ElectiveSubject/ElectiveSubjectStudentAllocation";
import StudentAllocation from "../Modules/Academics/SubjectDetails/ElectiveSubject/StudentAllocation";
import Derivative from "../Modules/Academics/TestPlanner/Derivative";
import Derivatives from "../Modules/Academics/DailyActivities/Exam/Derivatives";
import EditGrade from "../Modules/Academics/TestPlanner/Grade/Add";
import TestWiseReports from "../Modules/Academics/Reports/TestWise/Index";
import ViewTestWise from "../Modules/Academics/Reports/TestWise/View";
import ViewDetailedTestwiseReport from "../Modules/Academics/Reports/TestWise/DetailedReport/Report";
import NonAcademic from "../Modules/Academics/TestPlanner/NonAcademic";
import TeacherWiseAllocatedReports from "../Modules/Academics/Reports/TeacherWiseAllocated/Index";
import PerTeacherClassDetails from "../Modules/Academics/Reports/TeacherWiseAllocated/PerTeacherClassDetails";
import ReportCards from "../Modules/Academics/Reports/Progress/Index";
import AllocateTest from "../Modules/Academics/Reports/Progress/TestDetails/Index";
import AcdTestReportList from "../Modules/Academics/Reports/Progress/TestDetails/List";
import TestDetails from "../Modules/Academics/Reports/CompleteReports/ReportCards/TestDetails";
import PassedOutStudentAcdList from "../Modules/Academics/students/PassedOutStudentAcdList";
import Groups from "../Modules/Academics/CustomAllocation/Groups/Index";
import AddGroups from "../Modules/Academics/CustomAllocation/Groups/Add";
import Categories from "../Modules/Academics/CustomAllocation/Categories/Index";
import CombinationsForSuballocation from "../Modules/Academics/SubjectDetails/Allocation/Combinations";
import Combinations from "../Modules/Academics/CustomAllocation/Combinations/Index";
import AssociateCombinations from "../Modules/Academics/CustomAllocation/Combinations/Add";
import AssociateSubjects from "../Modules/Academics/CustomAllocation/Categories/Add";
import ChooseStudent from "../Modules/Academics/SubjectDetails/Allocation/ChooseStudent/Index";
import CombinationsForReports from "../Modules/Academics/Reports/CompleteReports/Test/Combinations/Index";
import CombinationWise from "../Modules/Academics/Reports/CompleteReports/Test/Combinations/CombinationWise";
import Individual from "../Modules/Academics/DailyActivities/StudentList/Individual";
import Feeds from "../Modules/Academics/Feeds/Index";
import FormType from "../Modules/Master/Student/Dashboard/FormTypes/Index";
import Form from "../Modules/Master/Student/Dashboard/FormTypes/Form";
import FillForm from "../Modules/Master/Student/Dashboard/FormTypes/FillForm";
import FeedbackPreview from "../Modules/Master/Student/Dashboard/FormTypes/Preview";
import FeedBackReports from "../Modules/Academics/Feeds/Report/Index";
import ViewFeedBackReports from "../Modules/Academics/Feeds/Report/Formwise/Classwise";
import ViewDetailedReports from "../Modules/Academics/Feeds/Report/Formwise/SubjectWise";
import QuestionWise from "../Modules/Academics/Feeds/Report/Formwise/QuestionWise";
import { ReportType } from "../Modules/Academics/Feeds/Report/Formwise/FormsReport";
import ActiveSessions from "../Modules/Academics/Sessions/ActiveSessions";
import TeacherList from "../Modules/Academics/Feeds/Report/Teacherwise/List";
import TeacherReport from "../Modules/Academics/Reports/Teacher/Index";
import Alloted from "../Modules/Academics/Reports/Teacher/Alloted";
import AllTeachers from "../Modules/Academics/Reports/Teacher/AllTeachers";
import ViewAlloted from "../Modules/Academics/Reports/Teacher/ViewAlloted";
import AbsentiesList from "../Modules/Academics/Reports/Attendance/AbsentiesList";
import ViewDailyAttReport from "../Modules/Academics/Reports/Attendance/ViewDailyAttReport";
import DailyAttendance from "../Modules/Academics/Reports/Attendance/DailyAttendance";
import ViewOverallAtt from "../Modules/Academics/Reports/Attendance/ViewOverallAtt";
import ViewMonthwise from "../Modules/Academics/Reports/Attendance/ViewMonthlywise";
import IndividualStudent from "../Modules/Academics/Reports/Attendance/IndividualStudent";
import FormsByEmployee from "../Modules/Academics/Feeds/Report/Formwise/FormsByEmployee";
import MarkedView from "../Modules/Academics/Reports/Attendance/MarkedView";
import TeacherSession from "../Modules/Academics/Reports/Attendance/TeacherSession";
import FacultyDevice from "../Modules/Academics/DailyActivities/DeviceDetails/FacultyDevice";
import SubjectListView from "../Modules/Academics/Reports/Attendance/SubjectListView";
import View from "../Modules/Academics/DailyActivities/TestWise/View";
const AcademicRoutes = () => {
  const { error } = useEduateModuleConfig(EduateModule.ACADEMICS);
  const { USE_ACADEMICS } = useUserRightsByEmpId();
  const [modalFlag, setModalFlag] = useState(false);

  return (
    <Routes>
      {error || !USE_ACADEMICS.flag ? (
        <>
          <Route path="*" element={<Page404 />} />
        </>
      ) : (
        <>
          <Route path="/examplanner" element={<ExamPlanner />} />
          <Route path="/sessions" element={<SessionsList />} />
          <Route path="/activeSessions" element={<ActiveSessions />} />

          <Route
            path="/:testId/examplanner/view"
            element={<Planner isderivative={false} isnonacademic={false} />}
          />
          <Route
            path="/:testId/examplanner/view/derivative"
            element={<Planner isderivative={true} isnonacademic={false} />}
          />
          <Route
            path="/:testId/examplanner/view/nonacademic"
            element={<Planner isderivative={false} isnonacademic={true} />}
          />
          <Route
            path="/examplanner/grade"
            element={
              <EditGrade pageType={PageFor.GENERAL} setModal={setModalFlag} />
            }
          />
          <Route
            path="/customallocation/*"
            element={
              <>
                <Routes>
                  <Route
                    path="/combinations/associate"
                    element={
                      <AssociateCombinations
                        pageType={PageFor.GENERAL}
                        setModal={setModalFlag}
                      />
                    }
                  />

                  <Route
                    path="/combinations"
                    element={
                      <Combinations
                        pageType={PageFor.GENERAL}
                        setModal={setModalFlag}
                      />
                    }
                  />
                  <Route path="/category" element={<Categories />} />
                  <Route
                    path="/category/associatesub"
                    element={
                      <AssociateSubjects
                        pageType={PageFor.GENERAL}
                        setModal={setModalFlag}
                        operation={Operation.NONE}
                      />
                    }
                  />
                  <Route path="/groups" element={<Groups />} />
                  <Route
                    path="/groups/add"
                    element={
                      <AddGroups
                        pageType={PageFor.GENERAL}
                        setModal={setModalFlag}
                      />
                    }
                  />
                </Routes>
              </>
            }
          />

          <Route
            path="/:testId/examplanner/:allotedID/createtest"
            element={
              <CreateTestPlan
                operation={Operation.CREATE}
                studentWiseSubAllocation={false}
              />
            }
          />
          <Route
            path="/:testId/examplanner/:allotedID/createtest/perstudent"
            element={
              <CreateTestPlan
                operation={Operation.CREATE}
                studentWiseSubAllocation={true}
              />
            }
          />
          <Route
            path="/:testId/examplanner/:allotedID/createnonacademictest"
            element={<NonAcademic operation={Operation.CREATE} />}
          />
          <Route
            path="/:testId/examplanner/:allotedID/updatenonacademictest"
            element={<NonAcademic operation={Operation.UPDATE} />}
          />
          <Route
            path="/:testId/examplanner/:allotedID/createderivative"
            element={<Derivative operation={Operation.CREATE} />}
          />
          <Route
            path="/:testId/examplanner/:allotedID/updatederivative"
            element={<Derivative operation={Operation.UPDATE} />}
          />
          <Route
            path="/:testId/examplanner/:allotedID/updatetest"
            element={
              <CreateTestPlan
                operation={Operation.UPDATE}
                studentWiseSubAllocation={false}
              />
            }
          />
          <Route
            path="/:testId/examplanner/:allotedID/updatetest/perstudent"
            element={
              <CreateTestPlan
                operation={Operation.UPDATE}
                studentWiseSubAllocation={true}
              />
            }
          />
          <Route
            path="/calendar/holidayentry"
            element={
              <HolidayEntry
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
              />
            }
          />
          {/* <Route
            path="/calendar/vacationholidayentry"
            element={<VacationHolidayEntry />}
          /> */}
          <Route
            path="/"
            element={<AcademicsHome DashBoardRequired={true} />}
          />
          <Route path="/attendance/Monthly" element={<MonthlyList />} />
          <Route
            path="/attendance/foradmin/:entryId/markAttendance"
            element={
              <PerSessionDetails pageType={AttendanceOverViewPageFor.ADMIN} />
            }
          />
          <Route
            path="/attendance/foradmin/:entryId/:subjectId/persession/attendance"
            element={
              <SessionAttendance
                pageType={AttendanceOverViewPageFor.ADMIN}
                selectedSubject={0}
              />
            }
          />
          <Route path="/dailydiary/view" element={<ViewDetailed />} />
          <Route path="/attendance/foradmin" element={<ForAdmin />} />
          <Route path="/subjestdetails" element={<AcademicSubjectDetails />} />
          <Route
            path="/subjectallocationdetails"
            element={<SubjectAllocationDetails />}
          />
          <Route
            path="/:allocationId/subjectallocationdetails/combinations"
            element={<CombinationsForSuballocation />}
          />
          <Route
            path="/:allocationId/subjectallocationdetails/:combinationId/combinations/list"
            element={<ChooseStudent />}
          />
          <Route
            path="/electiveSubjectAllocationToStudents"
            element={<ElectiveAllocationToStudent />}
          />
          <Route
            path="/electiveSubjectAllocationToStudents/:allotedID/allocate"
            element={<StudentAllocation />}
          />
          <Route
            path="/subjectallocationdetails/:allotedID/allocateSubject"
            element={<AllocateSubjects operation={Operation.CREATE} />}
          />
          <Route
            path="/subjectallocationdetails/:allotedID/updateAllocatedSubject"
            element={<AllocateSubjects operation={Operation.UPDATE} />}
          />
          <Route
            path="/student/list"
            element={
              <AcdStudents
                pageType={PageFor.GENERAL}
                setStudentModal={setModalFlag}
                studentModal={modalFlag}
                queryType={StudentReportType.GENERAL}
              />
            }
          />
          <Route
            path="/reports/FacultyDeiveDetails"
            element={<FacultyDevice />}
          />
          <Route path="/reportcard" element={<ReportCards />} />
          <Route
            path="/reportcard/allocatetests"
            element={<AllocateTest operation={Operation.CREATE} />}
          />
          <Route
            path="/reportcard/:reportId/updatetests"
            element={<AllocateTest operation={Operation.UPDATE} />}
          />
          <Route path="/reportcard/list" element={<AcdTestReportList />} />

          <Route
            path="/student/passedOutList"
            element={
              <PassedOutStudentAcdList
                pageType={PageFor.GENERAL}
                setStudentModal={setModalFlag}
                studentModal={modalFlag}
                queryType={StudentReportType.GENERAL}
              />
            }
          />
          <Route path="/reports/test" element={<StudentTestReport />} />
          <Route path="/reports/teacher" element={<TeacherReport />} />
          <Route path="/reports/teacher/alloted" element={<Alloted />} />
          <Route
            path="/reports/teacher/:empId/alloted/view"
            element={<ViewAlloted />}
          />

          <Route path="/reports/teacher/all" element={<AllTeachers />} />

          <Route
            path="/reports/:testId/test/:entryId/view"
            element={
              <ViewTestDetails page_for={ForReportOrDailyActivities.REPORTS} />
            }
          />
          <Route
            path="/reports/perstudentattendance"
            element={<PerStudentAttendanceReport />}
          />
          <Route
            path="/reports/completeReports"
            element={<CompleteReports />}
          />
          <Route
            path="/reports/attendance/absenties"
            element={
              <AbsentiesList pageType={attendanceReportType.ABSENT_LIST} />
            }
          />
          <Route
            path="/reports/attendance/teacher/session"
            element={<TeacherSession />}
          />
          <Route
            path="/reports/attendance/soa"
            element={
              <AbsentiesList pageType={attendanceReportType.SHORTAGE_OF_ATT} />
            }
          />
          <Route
            path="/reports/attendance/dailyattendance"
            element={
              <DailyAttendance pageType={attendanceReportType.DAILY_ATT} />
            }
          />
          <Route
            path="/reports/attendance/dailyattendance/:entryId/view"
            element={
              <ViewDailyAttReport pageType={attendanceReportType.DAILY_ATT} />
            }
          />
          <Route
            path="/reports/attendance/dailyattendance/:entryId/viewSessionWise"
            element={
              <ViewDailyAttReport pageType={attendanceReportType.DAILY_ATT} />
            }
          />
          <Route
            path="/reports/attendance/overall"
            element={
              <DailyAttendance pageType={attendanceReportType.OVERALL} />
            }
          />
          <Route
            path="/reports/attendance/monthly"
            element={
              <DailyAttendance pageType={attendanceReportType.MONTHLY} />
            }
          />

          <Route
            path="/reports/attendance/marked"
            element={<DailyAttendance pageType={attendanceReportType.MARKED} />}
          />
          <Route
            path="/reports/attendance/monthly/:entryId/view"
            element={<ViewMonthwise />}
          />
          <Route
            path="/reports/attendance/monthly/:entryId/subjectView"
            element={<SubjectListView />}
          />
          <Route
            path="/reports/attendance/marked/:entryId/view"
            element={<MarkedView />}
          />
          <Route
            path="/reports/attendance/individualstudent"
            element={<IndividualStudent />}
          />
          <Route
            path="/reports/attendance/overall/:entryId/view"
            element={<ViewOverallAtt />}
          />
          <Route
            path="/reports/feedback"
            element={<FeedBackReports pageType={ReportType.Formwise} />}
          />

          <Route
            path="/reports/teacherwise/feedback/form"
            element={<FeedBackReports pageType={ReportType.Subwise} />}
          />
          <Route
            path="/reports/teacherwise/reports"
            element={<FeedBackReports pageType={ReportType.TeacherWise} />}
          />

          <Route
            path="/reports/teacherwise/feedback/:formId/List"
            element={<TeacherList />}
          />
          <Route
            path="/reports/feedback/view"
            element={<ViewFeedBackReports pageType={ReportType.Formwise} />}
          />
          <Route
            path="/reports/teacherwise/feedback/:empId/formsByEmp"
            element={<FormsByEmployee />}
          />
          <Route
            path="/reports/teacherwise/feedback/view"
            element={<ViewFeedBackReports pageType={ReportType.Subwise} />}
          />
          <Route
            path="/reports/teacherwise/feedback/:formId/:empId/view"
            element={<ViewFeedBackReports pageType={ReportType.Subwise} />}
          />
          <Route
            path="/reports/teacherwise/feedback/:formId/view"
            element={<ViewFeedBackReports pageType={ReportType.Subwise} />}
          />
          <Route
            path="/reports/feedback/subwise"
            element={<ViewDetailedReports pageType={ReportType.Formwise} />}
          />
          <Route
            path="/reports/teacherwise/feedback/subwise"
            element={<ViewDetailedReports pageType={ReportType.Subwise} />}
          />
          <Route
            path="/reports/feedback/:formId/:empId/:entryId/questionwise"
            element={<QuestionWise />}
          />
          <Route
            path="/reports/completeReports/combination/:entryId/view"
            element={<CombinationWise />}
          />
          <Route
            path="/reports/testwise"
            element={<TestWiseReports pageType={PageFor.REPORT} />}
          />
          <Route
            path="/reports/teacherwise"
            element={
              <TeacherWiseAllocatedReports
                pageType={PageFor.GENERAL}
                setStudentModal={setModalFlag}
                studentModal={modalFlag}
                queryType={StudentReportType.GENERAL}
              />
            }
          />
          <Route
            path="/reports/teacherwise/:teacherId/perTeacherDetails"
            element={
              <PerTeacherClassDetails
                pageType={PageFor.GENERAL}
                setStudentModal={setModalFlag}
                studentModal={modalFlag}
                queryType={StudentReportType.GENERAL}
              />
            }
          />
          <Route
            path="/reports/testwise/:testId/view"
            element={<ViewTestWise />}
          />
          <Route
            path="/reports/testwise/:testId/:allotedID/:testClassId/detailedreport"
            element={<ViewDetailedTestwiseReport />}
          />

          <Route
            path="/reports/completeReports/:entryId/view"
            element={<ViewCompleteReports />}
          />

          <Route
            path="/reports/completeReports/:entryId/view/:reportId/testdetails"
            element={<TestDetails />}
          />
          <Route
            path="/reports/completeReports/:entryId/view/:testId/:testTypeId/pertestdetails/combinations"
            element={<CombinationsForReports />}
          />
          <Route
            path="/reports/completeReports/:entryId/view/:testId/:testTypeId/pertestdetails"
            element={
              <ViewParticularTestReport
                pageType={PageFor.GENERAL}
                setModal={setModalFlag}
              />
            }
          />
          <Route
            path="/reports/completeReports/:entryId/view/:testId/:testTypeId/pertestdetails/:comboId/forcombination"
            element={
              <ViewParticularTestReport
                pageType={PageFor.GENERAL}
                setModal={setModalFlag}
                marksFor={MarksFrom.COMBINATION}
              />
            }
          />
          <Route
            path="/reports/completeReports/:entryId/view/:testId/:testTypeId/pertestdetails/:subjectId/forsubjects"
            element={
              <ViewParticularTestReport
                pageType={PageFor.GENERAL}
                setModal={setModalFlag}
                marksFor={MarksFrom.SUBJECT}
              />
            }
          />
          {/* routes to be tested */}
          <Route
            path="/dailyactivities/*"
            element={
              <>
                <Routes>
                  <Route
                    path="/:entryId/:subjectId/classdetails"
                    element={<AcademicsDailyActivities />}
                  />
                  <Route
                    path="/:entryId/studentlist/view"
                    element={
                      <Individual
                        pageType={PageFor.GENERAL}
                        setModalFlag={setModalFlag}
                      />
                    }
                  />
                  <Route
                    path="/:entryId/:subjectId/attendance"
                    element={<AcademicsDailyActivities />}
                  />
                  <Route
                    path="/:entryId/persessiondetails"
                    element={
                      <PerSessionDetails
                        pageType={AttendanceOverViewPageFor.DAILY_ACTIVITIES}
                      />
                    }
                  />
                  <Route
                    path="/:entryId/:subjectId/channel/:channelId/classdetails/assignments/view"
                    element={<ViewDetails />}
                  />
                  <Route
                    path="/:testId/exams/:entryId/viewmarks"
                    element={
                      <ViewTestDetails
                        page_for={ForReportOrDailyActivities.DAILY_ACT}
                      />
                    }
                  />
                  <Route path="/home" element={<DailyActivitesHome />} />
                  <Route
                    path="/testwise"
                    element={<TestWiseReports pageType={PageFor.GENERAL} />}
                  />
                  <Route path="/testwise/:testId/view" element={<View />} />

                  <Route
                    path="/:testId/:subjectId/exams/:entryId/entermarks"
                    element={<EnterMarks />}
                  />
                  <Route
                    path="/:testId/exams/:entryId/derivatives"
                    element={<Derivatives />}
                  />
                  <Route
                    path="/:testId/exams/:entryId/updatemarks"
                    element={<UpdateMarks operation={Operation.UPDATE} />}
                  />
                  <Route
                    path="/:testId/exams/:entryId/updateremarks"
                    element={<UpdateMarks operation={updatedRemarks} />}
                  />
                  <Route
                    path="/allocateteachers"
                    element={
                      <AllocateTeachers
                        pageFrom={TeachersAssociationPageFrom.DAILY_ACTIVITIES}
                      />
                    }
                  />
                </Routes>
              </>
            }
          />
          <Route
            path="/allocateteachers/*"
            element={
              <Routes>
                {/* <Route path="*" element={<TeacherAllocationTree />} /> */}
                <Route
                  path="/:instMasterId/classandsubject"
                  element={
                    <AllocateTeachers
                      pageFrom={TeachersAssociationPageFrom.INST_LEVEL}
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    <AllocateTeachers
                      pageFrom={TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS}
                    />
                  }
                />
              </Routes>
            }
          />
          <Route path="/testtype" element={<TestType />} />
          <Route
            path="/assignclassteacher/:entryId/assignTeacher"
            element={<AllocateClassTeacher />}
          />
          <Route
            path="/assignclassteacher"
            element={<TeacherAllocationTree />}
          />
          <Route
            path="/configurations"
            element={
              <Configurations
                config_query_type={SwConfigQueryType.INST_BY_MODULE}
                str_value={ModuleName.ACADEMICS}
                int_value={0}
                setModalFlag={setModalFlag}
                pageType={PageFor.GENERAL}
              />
            }
          />
        </>
      )}
      <Route path="/lessonplanner" element={<LessonPlanner />} />
      <Route path="/lessonplanner/:entryId/assign" element={<Assign />} />{" "}
      <Route path="/feedback" element={<Feeds />} />
      <Route path="/feedback/formtype" element={<FormType />} />
      <Route path="/feedback/form" element={<Form />} />
      <Route path="/feedback/fillform" element={<FillForm />} />
      <Route path="/feedback/preview" element={<FeedbackPreview />} />
    </Routes>
  );
};

export default AcademicRoutes;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Title } from "../../stories/Title/Title";
import { Button } from "../../stories/Button/Button";
import { Label } from "../../stories/Label/Label";

import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
} from "../../utils/UtilFunctions";

import { Form, Formik } from "formik";

import { useMutation } from "@apollo/client";
import {
  AddStudentEnquiryFollowUp,
  DeleteStudentEnquiryFollowUp,
  UpdateStudentEnquiryFollowUp,
} from "../../queries/students/mutations/new";
import useServerDateandTime from "../Library/customHooks/useServerDateandTime";
import useToken from "../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/context";
import useActiveAcademicYear from "../Academics/hooks/useActiveAcademicYear";
import usePredefinedDataByType from "../../customhooks/usePredefinedDataByType";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../utils/constants";
import {
  Direction,
  Operation,
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
} from "../../utils/Enum.types";
import { Keys } from "../../utils/Enum.keys";
import Input from "../../components/common/Input/Index";
import MessageModal from "../../pages/MessageModal";
import {
  GetStudentEnquires,
  GetStudentEnquiresByEnquiryId,
} from "../../queries/students/list/newApi";
import DeleteModal from "../../pages/DeleteModal";
import TextArea from "../../stories/TextArea/TextArea";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { IObjectKeys, optionsType } from "../../utils/Form.types";
import { followUpValidationSchema } from "./utils/validationRules";
import { follow_up_date, next_follow_up_date } from "./utils/constants";
import useEmpDetailsById from "../HR/hooks/useEmpDetailsById";
import useStudentEnquiries from "./hooks/useStudentEnquires";
import { formClasses, RequiredAutoComplete } from "../../styles/AutocompleteStyles";

interface formData extends IObjectKeys {
  follow_up_remarks: string;
  follow_up_person: string;
  next_follow_up_date: string;
  follow_up_date: string;
}
interface Props {
  setStudentDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  startDate: string;
  endDate: string;
}
const EmpDetailsModal = ({
  setStudentDetailsModal,
  startDate,
  endDate,
}: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [updateId, setUpdateId] = useState(0);
  const [followUpId, setFollowUpId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const { activeAcademicYearData } = useActiveAcademicYear();

  const [message, setMessage] = useState({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [formData, setFormData] = useState<formData>({
    next_follow_up_date: "",
    follow_up_date: "",
    follow_up_remarks: "",
    follow_up_person: "",
  });
  const [follow_up_status, set_follow_up_status] = useState<optionsType | null>(
    null
  );

  const {
    EnquiryData: { StudentEnquiryData },
  } = useStudentEnquiries(
    null,
    StdEnqQueryType.STD_ENQ_BY_EMP_ID,
    EMPTY_STRING,
    EMPTY_STRING,
    null,
    null,
    SortBy.ENQ_DATE,
    endDate,
    startDate,
    0,
    0,
    state.employeeId ? state.employeeId : 0
  );

  const { empolyeeData } = useEmpDetailsById(false, false);
  const empData = empolyeeData.data && empolyeeData.data.node;
  const { serverDate } = useServerDateandTime();

  const { user_details } = useLoggedInUserDetails();
  const [AddEnquiryFollowUp] = useMutation(AddStudentEnquiryFollowUp, {
    onError: (e) =>
      setMessage({
        message: e.message,
        operation: Operation.NONE,
        flag: true,
      }),
  });

  const [UpdateEnquiryFollowUp] = useMutation(UpdateStudentEnquiryFollowUp, {
    onError: (e) =>
      setMessage({
        message: e.message,
        operation: Operation.NONE,
        flag: true,
      }),
  });
  const [DeleteEnquiryFollowUp] = useMutation(DeleteStudentEnquiryFollowUp, {
    onError: (e) =>
      setMessage({
        message: e.message,
        operation: Operation.NONE,
        flag: true,
      }),
  });

  const {
    PredefinedData: { dropDown: ENQ_STATUS_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_FOLLOW_UP_STATUS,
    EMPTY_STRING
  );

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteEnquiryFollowUp({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudentEnquires,
          variables: {
            name: EMPTY_STRING,
            lastFollowUpStatus: null,
            boughtApplication: null,
            admitted: null,
            status: null,
            deptID: null,
            branchID: null,
            classID: null,
            semesterID: null,
            categoryID: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            first: ROWS_PER_PAGE,
            after: null,
            today_date: toIsoDate(serverDate),
            sortBy: SortBy.FIRST_NAME,
            direction: Direction.ASC,
            token,
            enq_query_type: StdEnqQueryType.STD_ENQ_ALL,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Deleted SuccessFully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };

  const handleSubmit = () => {
    if (operation === Operation.CREATE) {
      AddEnquiryFollowUp({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
          enq_student_id: state.studentEnquiryId,
          input: {
            follow_up_remarks: removeMoreSpace(formData.follow_up_remarks),
            follow_up_person: formData.follow_up_person,
            follow_up_date: toIsoDate(serverDate),
            follow_up_status: follow_up_status?.value,
            next_follow_up_date: toIsoDate(formData.next_follow_up_date!),
          },
        },
        refetchQueries: [
          {
            query: GetStudentEnquiresByEnquiryId,
            variables: { id: state.studentEnquiryId, token },
            fetchPolicy: "network-only",
          },
          {
            query: GetStudentEnquires,
            variables: {
              name: EMPTY_STRING,
              lastFollowUpStatus: null,
              boughtApplication: null,
              admitted: null,
              deptID: null,
              branchID: null,
              classID: null,
              semesterID: null,
              categoryID: null,
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.FIRST_NAME,
              direction: Direction.ASC,
              token,
              today_date: toIsoDate(serverDate),
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              enq_query_type: StdEnqQueryType.STD_ENQ_NOT_ADMITTED,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Follow up added successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateEnquiryFollowUp({
        variables: {
          token,
          id: updateId,
          inst_id: InstId!,
          user_details,
          input: {
            follow_up_remarks: removeMoreSpace(formData.follow_up_remarks),
            follow_up_person: formData.follow_up_person,
            follow_up_date: toIsoDate(formData.follow_up_date),
            follow_up_status: follow_up_status?.value,
            next_follow_up_date: toIsoDate(formData.next_follow_up_date!),
          },
        },
        refetchQueries: [
          {
            query: GetStudentEnquiresByEnquiryId,
            variables: { id: state.studentEnquiryId, token },
            fetchPolicy: "network-only",
          },
          {
            query: GetStudentEnquires,
            variables: {
              name: EMPTY_STRING,
              lastFollowUpStatus: null,
              boughtApplication: null,
              admitted: null,
              deptID: null,
              branchID: null,
              classID: null,
              semesterID: null,
              categoryID: null,
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.FIRST_NAME,
              direction: Direction.ASC,
              token,
              today_date: toIsoDate(serverDate),
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              enq_query_type: StdEnqQueryType.STD_ENQ_NOT_ADMITTED,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Follow up updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
          setOperation(Operation.CREATE);
        }
      });
    }
  };
  const handleClear = () => {
    setFormData({
      next_follow_up_date: "",
      follow_up_date: "",
      follow_up_status: "",
      follow_up_remarks: "",
      follow_up_person: "",
    });
    set_follow_up_status(null);
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
      setStudentDetailsModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Title>Employee Details</Title>
      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={followUpValidationSchema}
        onSubmit={handleSubmit}
      >
        {(meta) => {
          return (
            <Form className="std-enquiry-followup__view">
              <div className="row g-0 std-enquiry-followup__view--details">
                <div className="col std-enquiry-followup__view--details--span-4">
                  <TextField
                    className="std-enquiry-followup__details--textfield"
                    label={"Employee Name"}
                    value={
                      empData &&
                      empData.emp_first_name +
                        " " +
                        empData.emp_middle_name +
                        empData.emp_last_name
                    }
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                  />
                  <TextField
                    className="std-enquiry-followup__details--textfield"
                    label={"Email"}
                    value={empData && empData.emp_email}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                  />
                  <TextField
                    className="std-enquiry-followup__details--textfield"
                    label={"Designation"}
                    value={
                      empData && empData.pr_designation_details.designation_desc
                    }
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                  />
                  <TextField
                    className="std-enquiry-followup__details--textfield"
                    label={"Gender"}
                    value={empData && empData.emp_sex}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                  />
                  <TextField
                    className="std-enquiry-followup__details--textfield"
                    label={"Experience"}
                    value={empData && empData.emp_experience}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                  />
                  <TextField
                    className="std-enquiry-followup__details--textfield"
                    label={"Department"}
                    value={empData && empData.pr_dept_details.dept_desc}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                  />
                  <TextField
                    className="std-enquiry-followup__details--textfield"
                    label={"Mobile"}
                    value={empData && empData.emp_mobile}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                  />
                </div>
              </div>
              <div className="std-enquiry-followup__view--tableblock">
                <Title variant="subtitle1">Follow Up History</Title>
                <TableContainer className="std-enquiry-followup__view--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No.</TableCell>
                        <TableCell>Follow Up Person</TableCell>
                        <TableCell>Follow Up Status</TableCell>
                        <TableCell>Follow Up Date</TableCell>
                        <TableCell>Next Follow Up Date</TableCell>
                        <TableCell>Remarks</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {StudentEnquiryData.data &&
                        StudentEnquiryData.data.GetStudentEnquires.edges.map(
                          (data, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  className="std-enquiry-followup__table--slno"
                                  id="td-center"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell>{data.node}</TableCell>
                                <TableCell className="std-enquiry-followup__table--status">
                                  {findEnquiry(data.follow_up_status)}
                                </TableCell>
                                <TableCell className="std-enquiry-followup__table--date">
                                  {toStandardDate(data.follow_up_date)}
                                </TableCell>
                                <TableCell className="std-enquiry-followup__table--date">
                                  {toStandardDate(data.next_follow_up_date)}
                                </TableCell>
                                <TableCell>{data.follow_up_remarks}</TableCell>
                                <TableCell id="td-center">
                                  <img
                                    src={Edit}
                                    alt="/"
                                    onClick={() => {
                                      setOperation(Operation.UPDATE);
                                      setFollowItemForUpdate(data.id);
                                    }}
                                  />
                                  <img
                                    src={Delete}
                                    alt="/"
                                    onClick={() => {
                                      setFollowUpId(data.id);
                                      setDeleteModal(!deleteModal);
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="row g-0 std-enquiry-followup__view--additional">
                <div className="col std-enquiry-followup__view--additional--frame">
                  <Input
                    LabelName="Follow Up Date"
                    values={
                      formData[follow_up_date]
                        ? formData[follow_up_date]
                        : toInputStandardDate(serverDate)
                    }
                    name={follow_up_date}
                    disabled
                    type="date"
                  />
                  <Input
                    LabelName="Next Follow Up Date"
                    values={formData[next_follow_up_date]}
                    name={next_follow_up_date}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleValueChange(e);
                      meta.handleChange(e);
                    }}
                    autoFocus
                    min={toInputStandardDate(serverDate)}
                    type="date"
                  />
                  <div className="label-grid">
                    <Label>Follow Up Person</Label>
                    {/* <RequiredAutoComplete
                      className={labelClasses.inputRoot}
                      options={employeeDropDown}
                      value={formData.follow_up_person}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              follow_up_person: newValue,
                            };
                          });
                        } else {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              follow_up_person: EMPTY_STRING,
                            };
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus
                          required
                          onChange={(e) => setSearchData(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    /> */}
                  </div>
                </div>
                <div className="col std-enquiry-followup__view--additional--frame">
                  <div className="label-grid">
                    <Label>Follow Up Status</Label>
                    <RequiredAutoComplete
                      className={formClasses.inputRoot}
                      options={ENQ_STATUS_OPTIONS}
                      value={follow_up_status}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as optionsType, follow_up_status)
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (follow_up_status) {
                            handleMUISelectEvent(e);
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          set_follow_up_status(null);
                        }
                      }}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          set_follow_up_status(newValue as optionsType);
                        } else {
                          set_follow_up_status(null);
                        }
                      }}
                      openOnFocus
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={follow_up_status ? false : true}
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                  <div className="label-grid">
                    <Label>Remarks</Label>
                    <TextArea
                      rows={3}
                      name="follow_up_remarks"
                      onChange={(e) => {
                        handleValueChange(e);
                        meta.handleChange(e);
                      }}
                      value={formData.follow_up_remarks}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER && e.shiftKey === false) {
                          e.preventDefault();
                          handleMUISelectEvent(e);
                        }
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <Button mode="save" type="submit" />
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="cancel"
                type="button"
                onClick={() => setStudentDetailsModal(false)}
              />
            </Form>
          );
        }}
      </Formik>
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        handleDelete={handleDelete}
        id={followUpId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
      />
    </>
  );
};

export default EmpDetailsModal;

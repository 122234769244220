import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { GetAcdLevel } from "../../../queries/Eduate/list";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { EMPTY_STRING } from "../../../utils/constants";
import { AppContext } from "../../../context/context";
interface GetAcdLevelData {
  GetAcdLevel: string;
}
interface GetAcdLevelVars {
  token: string;
  inst_id: string;
  acd_level_id: number | string;
}
const useAcdLevel = (id: string) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const [GetAcdLevelDetails, { data }] = useLazyQuery<
    GetAcdLevelData,
    GetAcdLevelVars
  >(GetAcdLevel);

  useEffect(() => {
    if (token && id) {
      GetAcdLevelDetails({
        variables: {
          acd_level_id: id,
          inst_id: InstId! ?? state.InstId,
          token,
        },
      });
    }
  }, [token, GetAcdLevelDetails, id, InstId, state.InstId]);
  return { data, acdLevel: data ? data.GetAcdLevel : EMPTY_STRING };
};

export default useAcdLevel;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import SalaryProcess from "./SalaryProcess";
import Home from "../../Home/Index";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { SalaryProcessTitleProps } from "../../../../Types/Titles";
import Modal from "react-modal";
import { SuccessModalCustomStyles } from "../../../../styles/ModalStyles";
import { Label } from "../../../../stories/Label/Label";
import usePayRollActiveAcademicYear from "../../hooks/usePayRollActiveAcademicYear";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetPayRollSalaryMkWork } from "../../queries/salary/list";
import useToken from "../../../../customhooks/useToken";
import dayjs from "dayjs";
import { toIsoDate, toStandardDate } from "../../../../utils/UtilFunctions";
import { DEFAULT_TIME } from "../../../../utils/constants";

import { Operation } from "../../../../utils/Enum.types";

import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import { StartPrSalaryProcessWork } from "../../queries/salary/mutation";

import Wrong from "../../../../images/Wrong.svg";
import Right from "../../../../images/Right.svg";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HR_Table } = require("../../json/table.json");
const { HRTitles } = require("../../json/title.json");

export interface PayRollSalaryMkWork {
  id: number;
  sal_wrk_for_month: string;
  sal_wrk_job_start_date: string;
  sal_wrk_job_started: boolean;
  sal_wrk_is_emp_added: boolean;
  sal_wrk_emp_added_date: string;
  sal_wrk_is_att_added: boolean;
  sal_wrk_att_added_date: string;
  sal_wrk_is_sal_str_added: boolean;
  sal_wrk_sal_str_added_date: string;
  sal_wrk_is_sal_made: boolean;
  sal_wrk_sal_made_date: string;
  sal_wrk_used_roundoff: boolean;
  sal_wrk_job_end_date: string;
  sal_wrk_job_completed: boolean;
  inst_id: number;
  pr_acd_yr_id: number;
}

interface GetPayRollSalaryMkWorkData {
  GetPayRollSalaryMkWork: PayRollSalaryMkWork[];
}
interface GetPayRollSalaryMkWorkVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
}
interface processCompletedModalType {
  id: number;
  flag: boolean;
}
export interface operationModal {
  flag: boolean;
  operation: Operation;
}
const SelectMonth = () => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  //Flags
  const [processCompletedModal, setProcessCompletedModal] = useState(false);
  const [monthId, setMonthId] = useState(0);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [processNotCompletedModal, setProcessNotCompletedModal] =
    useState<processCompletedModalType>();

  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  //useRefs

  //Queries
  const [GetPayRollMonths, { data, error }] = useLazyQuery<
    GetPayRollSalaryMkWorkData,
    GetPayRollSalaryMkWorkVars
  >(GetPayRollSalaryMkWork);

  const findMonth = () => {
    const month = data?.GetPayRollSalaryMkWork.find((d) => d.id === monthId)!;
    return month;
  };

  const monthDetails = findMonth();

  //Mutations
  const [UpdateSelectMonthStatus] = useMutation(StartPrSalaryProcessWork, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const hanldeSubmit = () => {
    if (payRollActiveAcademicYear.data) {
      UpdateSelectMonthStatus({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          pr_sal_mk_month_id: processNotCompletedModal?.id,
          month_start_date: toIsoDate(monthDetails.sal_wrk_for_month),
        },
        refetchQueries: [
          {
            query: GetPayRollSalaryMkWork,
            variables: {
              token,
              inst_id: InstId!,
              pr_acd_yr_id:
                payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          navigate(
            `/${InstId}/hr/payroll/salaryprocess/${processNotCompletedModal?.id}/selectemployee`
          );
          setProcessNotCompletedModal({
            id: 0,
            flag: false,
          });
        }
      });
    }
  };

  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  const navigateToStep = (selectedMonth: PayRollSalaryMkWork) => {
    if (selectedMonth?.sal_wrk_job_started) {
      if (!selectedMonth?.sal_wrk_is_emp_added)
        navigate(
          `/${InstId}/hr/payroll/salaryprocess/${selectedMonth?.id}/selectemployee`
        );
      else if (!selectedMonth?.sal_wrk_is_att_added)
        navigate(
          `/${InstId}/hr/payroll/salaryprocess/${selectedMonth?.id}/employeeattendance`
        );
      else if (!selectedMonth?.sal_wrk_is_sal_str_added)
        navigate(
          `/${InstId}/hr/payroll/salaryprocess/${selectedMonth?.id}/confirmemployeesalary`
        );
      else if (!selectedMonth?.sal_wrk_is_sal_made)
        navigate(
          `/${InstId}/hr/payroll/salaryprocess/${selectedMonth?.id}/provisionalsalarydetails/detailed`
        );
      else if (!selectedMonth?.sal_wrk_job_completed)
        navigate(
          `/${InstId}/hr/payroll/salaryprocess/${selectedMonth?.id}/closesalaryprocess`
        );
      else setProcessCompletedModal(!processCompletedModal);
    }
  };
  useEffect(() => {
    if (payRollActiveAcademicYear.data)
      GetPayRollMonths({
        variables: {
          token,
          inst_id: InstId!,
          pr_acd_yr_id:
            payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id,
        },
      });
  }, [payRollActiveAcademicYear.data, GetPayRollMonths, InstId, token]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryProcess.Titles.map(
          (title: SalaryProcessTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.SalaryProcess}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="salary-process">
        <div className="salary-process__data">
          <div className="salary-process__data--left-block">
            <SalaryProcess monthDetails={monthDetails} />
          </div>
          <div className="salary-process__data--right-block">
            {error ? (
              error?.message
            ) : (
              <TableContainer className="salary-process__select-month--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {HR_Table.SalaryProcess.SelectMonth.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                      <TableCell>SM</TableCell>
                      <TableCell>SEFS</TableCell>
                      <TableCell>EA</TableCell>
                      <TableCell>CES</TableCell>
                      <TableCell>PSD</TableCell>
                      <TableCell>CSP</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.GetPayRollSalaryMkWork.map((monthData, index) => {
                      return (
                        <TableRow
                          key={index}
                          className={
                            monthId === monthData.id
                              ? "salary-process__select-month--table--completed-row"
                              : ""
                          }
                          onClick={() => {
                            setMonthId(monthData.id);
                            const selectedMonth =
                              data?.GetPayRollSalaryMkWork.find(
                                ({ id }) => id === monthData.id
                              );
                            navigateToStep(selectedMonth!);
                          }}
                        >
                          <TableCell className="salary-process__select-month--table--month">
                            {dayjs(monthData.sal_wrk_for_month).format(
                              "MMMM-YYYY"
                            )}
                          </TableCell>
                          <TableCell>
                            {monthData.sal_wrk_job_started
                              ? monthData.sal_wrk_job_completed
                                ? "Completed"
                                : "In process"
                              : ""}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--number"
                            id="td-center"
                          >
                            {toStandardDate(
                              monthData.sal_wrk_job_start_date
                            ) === toStandardDate(DEFAULT_TIME)
                              ? "-"
                              : toStandardDate(
                                  monthData.sal_wrk_job_start_date
                                )}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--number"
                            id="td-center"
                          >
                            {toStandardDate(monthData.sal_wrk_job_end_date) ===
                            toStandardDate(DEFAULT_TIME)
                              ? "-"
                              : toStandardDate(
                                  monthData.sal_wrk_job_start_date
                                )}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--total"
                            id="td-center"
                          >
                            {monthData.sal_wrk_job_started ? (
                              <img src={Right} alt="Correct" />
                            ) : (
                              <img src={Wrong} alt="Wrong" />
                            )}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--total"
                            id="td-center"
                          >
                            {monthData.sal_wrk_is_emp_added ? (
                              <img src={Right} alt="Correct" />
                            ) : (
                              <img src={Wrong} alt="Wrong" />
                            )}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--total"
                            id="td-center"
                          >
                            {monthData.sal_wrk_is_att_added ? (
                              <img src={Right} alt="Correct" />
                            ) : (
                              <img src={Wrong} alt="Wrong" />
                            )}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--total"
                            id="td-center"
                          >
                            {monthData.sal_wrk_is_sal_str_added ? (
                              <img src={Right} alt="Correct" />
                            ) : (
                              <img src={Wrong} alt="Wrong" />
                            )}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--total"
                            id="td-center"
                          >
                            {monthData.sal_wrk_is_sal_made ? (
                              <img src={Right} alt="Correct" />
                            ) : (
                              <img src={Wrong} alt="Wrong" />
                            )}
                          </TableCell>
                          <TableCell
                            className="salary-process__select-month--table--month--total"
                            id="td-center"
                          >
                            {monthData.sal_wrk_job_completed ? (
                              <img src={Right} alt="Correct" />
                            ) : (
                              <img src={Wrong} alt="Wrong" />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
        {monthId > 0 && monthDetails.sal_wrk_job_started === false ? (
          <Button
            onClick={() => {
              if (monthDetails.sal_wrk_job_started) {
                navigateToStep(monthDetails!);
              } else
                setProcessNotCompletedModal({
                  id: monthDetails.id!,
                  flag: true,
                });
            }}
          >
            Start Salary Process
          </Button>
        ) : null}
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={processCompletedModal}
        style={SuccessModalCustomStyles}
        ariaHideApp={false}
      >
        <Title>Salary Process Completed</Title>
        <div>
          <Label>Salary Process For this Month Completed</Label>
        </div>
        <Button
          mode="cancel"
          onClick={() => setProcessCompletedModal(!processCompletedModal)}
        />
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={processNotCompletedModal?.flag!}
        style={SuccessModalCustomStyles}
        ariaHideApp={false}
      >
        <Title>Do You Wish to Continue</Title>
        <div>
          <Label>Wish to Start Salary Process for this Month</Label>
        </div>
        <Button mode="yes" onClick={hanldeSubmit} />
        <Button
          mode="cancel"
          onClick={() =>
            setProcessNotCompletedModal({
              flag: false,
              id: 0,
            })
          }
        />
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        handleClose={handleClose}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default SelectMonth;

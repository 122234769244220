import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummaryInLessonPlanner,
} from "../../../styles/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PreviewData = {
  titles: [
    {
      title: "Title 1",
      subtitles: ["Subtitle 1.1", "Subtitle 1.2"],
    },
    {
      title: "Title 2",
      subtitles: ["Subtitle 2.1", "Subtitle 2.2", "Subtitle 2.3"],
    },
    {
      title: "Title 3",
      subtitles: ["Subtitle 3.1", "Subtitle 3.2"],
    },
    {
      title: "Title 4",
      subtitles: ["Subtitle 4.1", "Subtitle 4.2", "Subtitle 4.3"],
    },
  ],
};

const Preview = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <>
      <div className="create-lesson-planner__topics--preview">
        {PreviewData.titles.map((data, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummaryInLessonPlanner
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{data.title}</Typography>
            </AccordionSummaryInLessonPlanner>
            <AccordionDetails>
              <div>
                {data.subtitles.map((subtitle, subtitleIndex) => (
                  <Typography
                    key={subtitleIndex}
                    className="create-lesson-planner__topics--preview--subtitle"
                  >
                    {subtitleIndex + 1}. {subtitle}
                  </Typography>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default Preview;

import { gql } from "@apollo/client";

export const AddFinyearbyInstId = gql`
  mutation AddFinyearbyInstId(
    $inst_id: ID!
    $fin_yr: String!
    $fin_st_date: Time!
    $token: String!
    $fin_end_date: Time!
    $user_details: SubscribedUser!
  ) {
    AddInstFinYr(
      token: $token
      input: {
        inst_id: $inst_id
        fin_yr: $fin_yr
        fin_st_date: $fin_st_date
        fin_end_date: $fin_end_date
      }
      user_details: $user_details
    ) {
      inst_id
      fin_yr
      fin_st_date
      fin_end_date
    }
  }
`;

import { Operation } from "../utils/Enum.types";
import Modal from "react-modal";
import { WaiveOffModalStyles } from "../styles/ModalStyles";
import { Button } from "../stories/Button/Button";
interface Props {
  modalFlag: boolean;
  value: string;
  handleClose: () => void;
  operation: Operation;
}
const TempMessageModal = ({
  modalFlag,
  value,
  handleClose,
  operation,
}: Props) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={modalFlag}
      style={WaiveOffModalStyles}
      ariaHideApp={false}
    >
      <div className="row">
        <div className="row">
          <b className={operation === Operation.NONE ? "nodata" : ""}>
            {value}
          </b>
        </div>
        <div className="row">
          <div className="col">
            <Button onClick={handleClose}>Okay</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TempMessageModal;

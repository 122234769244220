import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { toIsoDate } from "../../../utils/UtilFunctions";
import {
  GetPayRollGeneralHolidayTypeCountData,
  GetPayRollGeneralHolidayTypeCountVars,
} from "../Types/masterDataTypes";
import { GetPayRollGeneralHolidayTypeCount } from "../queries/holidays/query";

const usePayRollHolidayTypeCount = (
  acdYr: number,
  startDate: string,
  endDate: string
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [GetHolidaysinMonth, { data, loading, error }] = useLazyQuery<
    GetPayRollGeneralHolidayTypeCountData,
    GetPayRollGeneralHolidayTypeCountVars
  >(GetPayRollGeneralHolidayTypeCount);
  useEffect(() => {
    if (acdYr && startDate && endDate) {
      GetHolidaysinMonth({
        variables: {
          pr_acd_yr_id: acdYr,
          end_date: toIsoDate(endDate),
          inst_id: InstId!,
          start_date: toIsoDate(startDate),
          token,
        },
      });
    } // eslint-disable-next-line
  }, [acdYr, startDate, endDate]);
  return { HolidaysInmonth: { data, loading, error } };
};

export default usePayRollHolidayTypeCount;

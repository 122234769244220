import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { GetSwIdCardTemplates } from "../queries";
import useToken from "../../../../customhooks/useToken";
export interface GetIdCardTemplates {
  id: number;
  id_tpl_desc: string;
  bg_color: string;
  header_color: string;
  footer_color: string;
  text_color: string;
  theme: string;
}

interface GetIdCardTemplateData {
  GetSwIdCardTemplates: GetIdCardTemplates[];
}
interface GetIdCardTemplateVars {
  token: string;
}

const useSwIdCardTemplates = () => {
  const { token } = useToken();
  const [GetSwIdCardTemplateList, { data: swIdCardDetails, loading }] =
    useLazyQuery<GetIdCardTemplateData, GetIdCardTemplateVars>(
      GetSwIdCardTemplates,
      {
        variables: {
          token,
        },
      }
    );
  useEffect(() => {
    if (token) {
      GetSwIdCardTemplateList();
    }
  }, [token, GetSwIdCardTemplateList]);
  return {
    swIdCardDetails,
    loading,
  };
};

export default useSwIdCardTemplates;

import React, { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import List from "./List";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import { responseType } from "../../../../utils/Form.types";
import {  TextField } from "@mui/material";

import {
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../utils/types";
import useAcademicTableJson from "../../json/useAcademicTable";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import {
  InstitutionConfigurationTypes,
  PageFor,
  TableHeaders,
  attendanceReportType,
} from "../../../../utils/Enum.types";
import { useNavigate } from "react-router-dom";
import View from "../../../../images/EyeWhite.svg";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";

import CompleteAttendanceDetails from "../../../Master/Student/Dashboard/Academics/CompleteAttendanceDetails";

import { AppContext } from "../../../../context/context";
import { FETCH_MORE_DATA, TODAY_DATE } from "../../../../utils/constants";
import useAcdStdOverAll, {
  GetAcdStdAttOverAllEdges,
} from "../../hooks/useAcdStdOverAll";
import { payloadTypes } from "../../../../context/reducer";
import { AcdStdAttSubjQueryType } from "../../DailyActivities/Attendance/SessionAttendance";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import LoadingModal from "../../../../pages/LoadingModal";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
interface props {
  pageType: attendanceReportType;
}
const AbsentiesList = ({ pageType }: props) => {

  const { AbsentiesList } = useAcademicTableJson();
  const [absentList, setAbsentList] = useState(false);
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [dateSelected, setDateSelected] = useState(
    toInputStandardDate(TODAY_DATE)
  );
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<GetAcdStdAttOverAllEdges[]>([]);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  const { OverAllData, loading, fetchMore } = useAcdStdOverAll(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    dateSelected,
    pageType === attendanceReportType.ABSENT_LIST
      ? AcdStdAttSubjQueryType.ABSENTEES_AT_ENTRY_LEVEL
      : AcdStdAttSubjQueryType.SOA_AT_ENTRY_LEVEL
  );

  const dynamicHeaders: TableHeaderProps[] = AbsentiesList.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    ...(pageType === attendanceReportType.SHORTAGE_OF_ATT
      ? [
          {
            headerName: "Attendance Percentage",
            cellClassName: "td-name",
            field: "percentage",
            headerAlign: "center" as GridAlignment,
            flex: 1,
            align: "center" as GridAlignment,
          },
        ]
      : []),
    ...(enablePerSessionAtt && pageType === attendanceReportType.ABSENT_LIST
      ? [
          {
            headerName: "Total Sessions Held",
            cellClassName: "td-status",
            field: "total_sessions",
            headerAlign: "center" as GridAlignment,
            flex: 1,
          },
        ]
      : []),
    ...(enablePerSessionAtt && pageType === attendanceReportType.ABSENT_LIST
      ? [
          {
            headerName: "Absent Sessions",
            cellClassName: "td-status ",
            field: "absent_sessions",
            headerAlign: "center" as GridAlignment,
            flex: 1,
          },
        ]
      : []),
    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const stdId = params.row.std_id;
        return (
          <>
            <button
              className="teacher-wise-allocated-reports__view"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: stdId as number,
                  },
                });
                setAbsentList(!absentList);
              }}
            >
              View <img src={View} alt="" />
            </button>
          </>
        );
      },
    },
  ];
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStdAttOverAll.edges;
                const pageInfo = fetchMoreResult.GetAcdStdAttOverAll.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetAcdStdAttOverAll.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcdStdAttOverAll: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: OverAllData
                      ? OverAllData.GetAcdStdAttOverAll.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (OverAllData && !loading) {
      const newData = OverAllData.GetAcdStdAttOverAll?.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.student_details.std_adm_no,
            reg_no: node.student_details.std_reg_no,
            name: `${node.student_details.first_name} ${node.student_details.middle_name} ${node.student_details.last_name}`,
            percentage: node.over_all_per,
            std_id: node.student_details.id,
            total_sessions: node.total_session,
            absent_sessions: node.total_session_absent,
            dept: node.student_details.dept.dept_desc,
            branch: node.student_details.branch.branch_desc,
            class: node.student_details.class.class_desc,
            sem: node.student_details.semester.sem_desc,
            sec: node.student_details.section.section_desc,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.student_details.std_adm_no,
            reg_no: node.student_details.std_reg_no,
            name: `${node.student_details.first_name} ${node.student_details.middle_name} ${node.student_details.last_name}`,
            percentage: node.over_all_per,
            std_id: node.student_details.id,
            total_sessions: node.total_session,
            absent_sessions: node.total_session_absent,
            dept: node.student_details.dept.dept_desc,
            branch: node.student_details.branch.branch_desc,
            class: node.student_details.class.class_desc,
            sem: node.student_details.semester.sem_desc,
            sec: node.student_details.section.section_desc,
          }))
        );
      }
      setEndCursor(OverAllData.GetAcdStdAttOverAll.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [OverAllData, loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="att-report">
        <div className="att-report__block">
          <div className="att-report__block--left">
            <List />
          </div>
          <div className="att-report__block--right">
            <div className="row g-0 att-report__block--right--select">
              {USE_DEPARTMENT_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={departmentDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, departmentSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (departmentSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setDepartmentSelected(null);
                      }
                    }}
                    openOnFocus
                    value={departmentSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setDepartmentSelected(newValue as responseType);
                        setHasNextPage(true);
                      } else {
                        setDepartmentSelected(null);
                      }
                      setBranchSelected(null);
                      setClassSelected(null);
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={departmentLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        id="outlined Departments"
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_BRANCH_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={branchDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, branchSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (branchSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setBranchSelected(null);
                      }
                    }}
                    openOnFocus
                    value={branchSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setBranchSelected(newValue as responseType);
                      } else {
                        setBranchSelected(null);
                      }
                      setClassSelected(null);
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={branchLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_CLASS_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={classDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, classSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (classSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setClassSelected(null);
                      }
                    }}
                    openOnFocus
                    value={classSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setClassSelected(newValue as responseType);
                      } else {
                        setClassSelected(null);
                      }
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={classLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                        fullWidth
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_SEMESTER_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={semesterDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semesterSelected)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (semesterSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setSemesterSelected(null);
                      }
                    }}
                    openOnFocus
                    value={semesterSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSemesterSelected(newValue as responseType);
                      } else {
                        setSemesterSelected(null);
                      }
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={semesterLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_SECTION_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, sectionSelected)
                    }
                    className={labelClasses.inputRoot}
                    options={sectionDropDown}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        if (sectionSelected) {
                          handleMUISelectEvent(e);
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setSectionSelected(null);
                      }
                    }}
                    openOnFocus
                    value={sectionSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSectionSelected(newValue as responseType);
                      } else {
                        setSectionSelected(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={sectionLabel}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              <div className="col">
                <TextField
                  value={
                    dateSelected
                      ? dateSelected
                      : toInputStandardDate(TODAY_DATE)
                  }
                  className="att-report__block--right--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Date"
                  type="date"
                  onChange={(e) => {
                    setDateSelected(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className={`att-report__block--right--tableblock`}
            >
              <StyledDatagrid
                columns={columns}
                rows={rows}
                rowHeight={TABLE_ROW_HEIGHT}
                disableRowSelectionOnClick
                hideFooter
                
              />
            </div>

            <div className="row g-0">
              <div className="col">
                <Button mode="excel">Export</Button>
                <Button mode="back" onClick={() => navigate(-1)} />
              </div>
              <div className="col flex-end">
                <StudentTotalCount
                  totalCount={
                    OverAllData
                      ? OverAllData.GetAcdStdAttOverAll.totalCount!
                      : 0
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={absentList}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CompleteAttendanceDetails
              setModalFlag={setAbsentList}
              pageType={PageFor.REPORT}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAbsentList(!absentList)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading} />
    </>
  );
};

export default AbsentiesList;

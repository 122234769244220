import React, { useContext, useEffect } from "react";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useAcdReportTestDetails from "../../../Academics/Reports/CompleteReports/ReportCards/hooks";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStudentsMarksStatisticsForReportCard } from "../../../Academics/queries/reportcard";
import useAcdLevel from "../../../Accounts/hooks/useAcdLevel";
import useCheckAllocationType from "../../../Academics/hooks/useCheckAllocationType";
interface AcdReportCardDataVars {
  entry_level: string;
  entry_id: number;
  test_name_ids: number[];
}
interface GetAcdStudentsMarksStatisticsForReportCardVars {
  token: string;
  inst_id: number;
  acd_yr_id: number;
  student_ids: number[];
  input: AcdReportCardDataVars;
  per_std_subj_allocation: boolean;
}
interface GetAcdStudentsMarksStatisticsForReportCardList {
  student_id: number;
  att_percentage: number;
  cal_days_count: number;
  att_marked_days_count: number;
  std_present_count: number;
  student_details: StudentAcdDetails;
  acd_test_marks_details: AcdTestMarksDetails[];
}
interface GetAcdStudentsMarksStatisticsForReportCardData {
  GetAcdStudentsMarksStatisticsForReportCard: GetAcdStudentsMarksStatisticsForReportCardList[];
}
interface StudentAcdDetails {
  id: number;
  std_reg_no: String;
  std_adm_no: String;
  first_name: String;
  middle_name: String;
  last_name: String;
  branch_desc: String;
  branch_id: number;
  class_desc: String;
  class_id: number;
  dept_desc: String;
  dept_id: number;
  sem_desc: String;
  sem_id: number;
  section_desc: String;
  section_id: number;
  cat_desc: String;
  category_id: number;
  std_father_name: String;
  std_profile_filename: string;
}
interface AcdTestMarksDetails {
  acd_test_class_id: number;
  test_start_date: string;
  test_end_date: string;
  test_type_name: string;
  acd_test_name: string;
  test_total_max_marks: number;
  test_total_min_marks: number;
  test_top_percentage: number;
  test_avg_percentage: number;
  test_std_total_percentage: number;
  test_std_total_marks_scored: number;
  is_non_academic: boolean;
  test_remarks: string;
  alloted_level_rank: number;
  entry_level_rank: number;
  overall_grade: string;
  marks_details: subjectMarksDetails[];
}
interface subjectMarksDetails {
  subj_master_id: number;
  subj_desc: string;
  subj_code: string;
  std_marks_scored: number | any;
  marks_percentage: number;
  subj_max_marks_scored: number;
  subj_avg_marks_scored: number;
  subj_total_max_marks: number;
  subj_total_min_marks: number;
  teacher_comments: string;
  scored_grade: string;
  is_std_present: boolean;
}
export interface SelectedStudentsProps {
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const useStudentMarksStatistics = ({
  selectedStudents,
  setStudentIdSet,
}: SelectedStudentsProps) => {
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const { state } = useContext(AppContext);
  const { entry_level } = useInstitutionConfiguration();
  const { data: TestDetails } = useAcdReportTestDetails();
  const { acdLevel } = useAcdLevel(entryId!);
  const { flag } = useCheckAllocationType();
  const TestNameIds = TestDetails
    ? TestDetails.GetAcdReportTests.map((res) => res.test_name_id)
    : [];
  const [GetStats, { data }] = useLazyQuery<
    GetAcdStudentsMarksStatisticsForReportCardData,
    GetAcdStudentsMarksStatisticsForReportCardVars
  >(GetAcdStudentsMarksStatisticsForReportCard);

  useEffect(() => {
    if (token && state.ActiveAcdYr && acdLevel) {
      GetStats({
        variables: {
          token,
          inst_id: Number(InstId),
          student_ids: selectedStudents,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            entry_level: acdLevel,
            entry_id: entryId ? Number(entryId) : 0,
            test_name_ids: [...TestNameIds],
          },
          per_std_subj_allocation: flag ? flag : false,
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    entryId,
    selectedStudents,
    entry_level,
    GetStats,
    acdLevel,
    state.ActiveAcdYr,
    flag,
  ]);

  return {
    data,
  };
};

export default useStudentMarksStatistics;

import { gql } from "@apollo/client";
export const AddAcdReportCard = gql`
  mutation AddAcdReportCard(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
    $input: CreateAcdReportCardInput!
  ) {
    AddAcdReportCard(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    ) 
  }
`;
export const UpdateAcdReportCard = gql`
  mutation UpdateAcdReportCard(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateAcdReportCardInput!
  ) {
    UpdateAcdReportCard(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      id: $id
    )
  }
`;
export const DeleteAcdReportCard = gql`
  mutation DeleteAcdReportCard(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdReportCard(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      id: $id
    )
  }
`;

export const AddAcdReportTests = gql`
  mutation AddAcdReportTests(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_report_card_id: ID!
    $user_details: SubscribedUser!
    $input: [CreateAcdReportTestsInput!]!
  ) {
    AddAcdReportTests(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_report_card_id: $acd_report_card_id
      user_details: $user_details
      input: $input
    ) 
  }
`;

export const UpdateAcdReportTests = gql`
  mutation UpdateAcdReportTests(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_report_card_id: ID!
    $user_details: SubscribedUser!
    $input: [UpdateAcdReportTestsInput!]!
  ) {
    UpdateAcdReportTests(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_report_card_id: $acd_report_card_id
      user_details: $user_details
      input: $input
      id:$id
    ) {
      idx
      test_name_id
    }
  }
`;

export const UpdateAcdReportCardConfig = gql`
  mutation UpdateAcdReportCardConfig(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [UpdateAcdReportCardConfig!]!

  ) {
    UpdateAcdReportCardConfig(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdateAcdTestReportCardConfig = gql`
  mutation UpdateAcdTestReportCardConfig(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input:[UpdateAcdTestClassReportCardConfig!]!

  ) {
    UpdateAcdTestReportCardConfig(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  GetSwConfigReferenceData,
  GetSwEduateConfigVariableByConfigKey,
  GetSwEduateConfigVariables,
} from "../../../queries/institution/configuration/query/SoftwreConfig";
import useToken from "../../../customhooks/useToken";
import { Title } from "../../../stories/Title/Title";
import Home from "../../Eduate/Home/Index";
import {
  GetSwEduateConfigVariablesData,
  GetSwEduateConfigVariablesVars,
  GlobalPageConfigData,
  SoftwareConfigTypeList,
  SoftwareConfigTypeVar,
} from "../../../Types/configtypes";
import { Label } from "../../../stories/Label/Label";
import { FormControlLabel, FormGroup } from "@mui/material";
import { AntSwitch } from "../../../pages/Switch";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { UpdateSwEduateConfigVariables } from "../../../queries/institution/configuration/mutation";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import { msgType } from "../../../utils/Form.types";
import { LicenseTypes, Operation } from "../../../utils/Enum.types";
import { EduateConfigTypes } from "../../../customhooks/useEduateConfiguration";
import { Select } from "../../../stories/Select/Select";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
const Index = () => {
  const { token } = useToken();
  const [pageSwDetails, setPageSwConfigList] = useState<GlobalPageConfigData[]>(
    []
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();
  const [GetEduateConfig] = useLazyQuery<
    GetSwEduateConfigVariablesData,
    GetSwEduateConfigVariablesVars
  >(GetSwEduateConfigVariables);

  const [GetConfigData, { data: ConfigReferenceData, loading: configLoading }] =
    useLazyQuery<SoftwareConfigTypeList, SoftwareConfigTypeVar>(
      GetSwConfigReferenceData,
      { variables: { token } }
    );
  const [UpdateSwEduateConfig, { loading }] = useMutation(
    UpdateSwEduateConfigVariables,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const instTypeData = (type: string) => {
    const typeData = ConfigReferenceData?.GetSwConfigReferenceData?.find(
      (data) => data.list_item_key === type
    );
    return typeData?.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }))
      .filter(({ value }) => value !== undefined);
  };
  const handleChangeValue = (
    key: string,
    type: string,
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    if (type === "BOOL") {
      const newState =
        pageSwDetails &&
        // eslint-disable-next-line
        pageSwDetails.map((obj) =>
          obj.config_key === key
            ? { ...obj, config_boolean_value: switchValue! }
            : obj
        );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }
  };
  const handleUpdateSwConfig = () => {
    UpdateSwEduateConfig({
      variables: {
        token,
        input: pageSwDetails.map((pageSwDetail) => ({
          id: pageSwDetail.id,
          config_key: pageSwDetail.config_key,
          config_boolean_value: pageSwDetail.config_boolean_value,
          config_integer_value: pageSwDetail.config_integer_value,
          config_double_value: pageSwDetail.config_double_value,
          config_string_value: pageSwDetail.config_string_value,
          config_string_value_2: pageSwDetail.config_string_value_2,
        })),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwEduateConfigVariableByConfigKey,
          variables: {
            token,
            config_key: EduateConfigTypes.EDUATE_BANNER,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Eduate Configuration Saved Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token) {
      GetEduateConfig({
        variables: {
          token,
        },
      }).then(({ data }) => {
        if (data) {
          setPageSwConfigList(data.GetSwEduateConfigVariables);
        }
      });
    }
  }, [token, GetEduateConfig]);
  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);
  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>Eduate Configuration</Title>
      <div className="global-configuration">
        <div className="global-configuration__block">
          <div className="global-configuration__block--details">
            {pageSwDetails?.map((list: GlobalPageConfigData, index: number) => (
              <div
                className="global-configuration__data--parameters"
                key={index}
              >
                <Label>
                  {index + 1} &nbsp;
                  {list.config_form_label_caption}
                </Label>
                {list.config_key_data_storage_type === "BOOLEAN" ? (
                  <FormGroup>
                    <FormControlLabel
                      label=""
                      labelPlacement="start"
                      control={
                        <AntSwitch
                          checked={list.config_boolean_value}
                          onClick={() => {
                            const newConfigValue = !list.config_boolean_value;
                            handleChangeValue(
                              list.config_key,
                              "BOOL",
                              newConfigValue,
                              null
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                ) : null}
                {list.config_depends_on_primary_list_key === false &&
                list.config_key_data_storage_type === "VARCHAR" ? (
                  <div className="label-grid">
                    <Input
                      value={list.config_string_value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValue(
                          list.config_key,
                          "VARCHAR",
                          null,
                          e.target.value
                        )
                      }
                    />
                  </div>
                ) : null}
                {list.config_key_data_storage_type === "BOOLEAN+VARCHAR" ? (
                  <FormGroup>
                    <FormControlLabel
                      label=""
                      labelPlacement="start"
                      control={
                        <AntSwitch
                          checked={list.config_boolean_value}
                          onClick={() => {
                            const newConfigValue = !list.config_boolean_value;
                            handleChangeValue(
                              list.config_key,
                              "BOOL",
                              newConfigValue,
                              null
                            );
                          }}
                        />
                      }
                    />
                    {list.config_boolean_value && (
                      <Input
                        placeholder=""
                        value={list.config_string_value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeValue(
                            list.config_key,
                            "VARCHAR",
                            null,
                            e.target.value
                          )
                        }
                      />
                    )}
                  </FormGroup>
                ) : null}
                {list.config_key_data_storage_type === "BOOLEAN+SELECT" ? (
                  <FormGroup>
                    <FormControlLabel
                      label=""
                      labelPlacement="start"
                      control={
                        <AntSwitch
                          checked={list.config_boolean_value}
                          onClick={() => {
                            const newConfigValue = !list.config_boolean_value;
                            handleChangeValue(
                              list.config_key,
                              "BOOL",
                              newConfigValue,
                              null
                            );
                          }}
                        />
                      }
                    />
                    {list.config_depends_on_primary_list_key &&
                    !list.config_depends_on_secondary_list_key &&
                    list.config_boolean_value ? (
                      <div>
                        <Select
                          value={list.config_string_value}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeValue(
                              list.config_key,
                              "primary_list_key",
                              null,
                              e.target.value
                            );
                          }}
                        >
                          <option>Select</option>
                          {instTypeData(list.config_primary_list_key)?.map(
                            ({ label, value }) => (
                              <React.Fragment key={value}>
                                <option value={value}>{label}</option>
                              </React.Fragment>
                            )
                          )}
                        </Select>
                      </div>
                    ) : null}
                  </FormGroup>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <Button mode="save" onClick={handleUpdateSwConfig} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={loading || configLoading} />
    </>
  );
};

export default Index;

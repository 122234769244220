import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import Edit from "../../../../images/Edit.svg";
import EditProfile from "../../../../images/EditProfile.svg";
import Enter from "../../../../images/Enter.svg";

import Delete from "../../../../images/Delete.svg";
import {
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import StudentList, {
  GetStudentsEnquiryData,
  GetStudentsEnquiryVars,
} from "../../../Enquiry/StudentEnquiry";
import Modal from "react-modal";
import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  Operation,
  PageFor,
  ReceiptTypes,
  SortBy,
  StdEnqQueryType,
  VoucherBookKey,
} from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";
import {
  GetStudentEnquires,
  GetStudentEnquiresByEnquiryId,
} from "../../../../queries/students/list/newApi";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DownArrow from "../../../../images/DownArrow.svg";
import { msgType, responseType } from "../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import { Keys } from "../../../../utils/Enum.keys";

import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { Button } from "../../../../stories/Button/Button";
import { GetAcctVouchers } from "../../queries/Vouchers/query";
import { AddAcctChallanMasterAndDetails } from "../../queries/receipts/mutation/Index";
import dayjs from "dayjs";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import {
  RefsByTagName,
  formatter,
  handleMUISelectEvent,
  isOptionEqualToValue,
  sixMonthsAgo,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import useEnquiryStudentDetails from "../../../../customhooks/useEnquiryStudentDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import { Data } from "../../../Print/Accounts/FeeReceipts/Standard/Index";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../../common/Enum.types";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import TextArea from "../../../../stories/TextArea/TextArea";
import { TableHeaderProps } from "../../../../Types/Tables";
import InputNumber from "../../../../components/common/Input/InputNumber";

import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import useAcctLdgrsForCaptation from "../../hooks/useAcctLdgrsForCaptation";
import { extendedResponseType } from "../NonDemandFeePayable/Index";
import { recepitsw } from "../FeeReceipt/Index";
import ChallanPrint from "../../../Print/Accounts/Challan/Index";
import { BankDetails } from "./CreateChallan";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
  RequiredAutoComplete,
} from "../../../../styles/AutocompleteStyles";

interface Props {
  pageType: PageFor;
  setFeeReceiptModal: React.Dispatch<React.SetStateAction<boolean>>;
  studentSelected: boolean;
}

interface itemList {
  acct_ldgr_id: number;
  ledgerName: string;
  ledgerAmt: number;
  defaultAmt: number;
}
const ApplicationFeeChallan = ({
  pageType,
  setFeeReceiptModal,
  studentSelected,
}: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const { InstId } = useParams();
  const { token } = useToken();
  const { dispatch, state } = useContext(AppContext);

  const acctLedgerRef = useRef<HTMLInputElement>(null);

  const acctLedgerInputRef = RefsByTagName(acctLedgerRef);

  const navigate = useNavigate();
  const [bankDetailsForPrint, setBankDetailsForPrint] = useState<BankDetails>({
    account_no: 0,
    bank_name: "",
  });
  const [studentModal, setStudentModal] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [items, setItems] = useState<itemList[]>([]);
  const [receipts, setreceipts] = useState<recepitsw[]>([]);
  const [edit, setEdit] = useState(false);
  const [feeAmount, setFeeAmount] = useState(0);

  const amtRef = useRef<HTMLInputElement>(null);
  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();
  const { voucherNumber } = useVoucherNumber(VoucherBookKey.CHALLAN);

  //use states for receipts
  const [rcptDate, setRcptDate] = useState("");
  const [narration, setNarration] = useState("");

  const [selectedFeeLedger, setSelectedFeeLedger] =
    useState<extendedResponseType | null>(null);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const { user_details } = useLoggedInUserDetails();

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { serverDate } = useServerDateandTime();
  const [GetStudentEnquiries, { data }] = useLazyQuery<
    GetStudentsEnquiryData,
    GetStudentsEnquiryVars
  >(GetStudentEnquires, {
    variables: {
      name: searchData,
      lastFollowUpStatus: null,
      boughtApplication: null,
      admitted: null,
      // deptID: null,
      branchID: null,
      classID: null,
      today_date: toIsoDate(rcptDate),
      first: ROWS_PER_PAGE,
      after: null,

      sortBy: SortBy.FIRST_NAME,
      pr_emp_id: 0,
      enq_start_date: serverDate
        ? toIsoDate(sixMonthsAgo(serverDate))
        : EMPTY_STRING,
      enq_end_date: serverDate ? toIsoDate(serverDate) : EMPTY_STRING,
      direction: Direction.ASC,
      token,
      enq_query_type: StdEnqQueryType.STD_ENQ_ALL,
      inst_id: InstId!,
      id: state.studentEnquiryId ? state.studentEnquiryId : null,
    },
  });
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const { NonDemandCapLedgers } = useAcctLdgrsForCaptation(
    AcctLedgerQueryType.ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND
  );

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const acctLdgrsInItemsSet = useMemo(() => {
    return new Set(items.map(({ acct_ldgr_id }) => acct_ldgr_id));
  }, [items]);

  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation(AddAcctChallanMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const totalAmount = useMemo(() => {
    return items.reduce((acc, item) => {
      acc += item.ledgerAmt;
      return acc;
    }, 0);
  }, [items]);

  const {
    enquiryStudentData: { enquiryFormData },
  } = useEnquiryStudentDetails();
  const [printModal, SetPrintModal] = useState(false);

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const [transaction_no, set_transaction_no] = useState("");
  const handleDebitItems = () => {
    if (transactionBookLedgerId)
      setreceipts((items) => [
        ...items,
        {
          acct_ldgr_id: transactionBookLedgerId.value,
        },
      ]);
  };

  const handleStudentReceipts = () => {
    if (transactionBookLedgerId)
      GenerateStudentReceipts({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,
              v_type: ReceiptTypes.STUDENT_RECEIPT,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
              v_date: dayjs(rcptDate).format(),
              v_total_cr_amt: totalAmount,
              v_total_db_amt: totalAmount,
              v_reconciled: false,
              v_std_anonymous_deposit_adjusted: false,

              v_std_receipt_anonymous: false,
              v_reconciled_date: dayjs(DEFAULT_TIME).format(),
              v_std_receipt: false,
              v_std_non_demand_receipt: false,
              v_std_refund: false,
              v_std_enquiry: true,
              enquiry_student_id: state.studentEnquiryId,
              v_std_deposit_adjusted: false,
              v_book_type: VoucherBookKey.CHALLAN,
              v_std_deposit: false,
              v_std_passout_receipt: false,

              v_std_demand_receipt: false,
              v_std_scholarship_deposit: false,

              student_id: 0,
              class_id: 0,
              semester_id: 0,
              v_std_amt_receipt: totalAmount,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: totalAmount,
              v_std_amt_refunded: 0,
              v_std_refund_deposit: false,
              v_std_amt_adjusted: 0,
              v_transcation_type: "Bank Book",
              v_transcation_cash_or_bank: BankOrCash.BANK,
              v_transcation_no: EMPTY_STRING,
              v_transcation_date: dayjs(rcptDate).format(),
              v_transcation_narration: narration,
              party_bill_date: dayjs(DEFAULT_TIME).format(),
              annx_yesno: false,
              acct_ldgr_id_cr: items[0].acct_ldgr_id,
              acct_ldgr_id_db: transactionBookLedgerId.value,
            },
            acct_voucher_db: receipts.map((dbData, index) => ({
              vo_cr_db: DebitOrCredit.DEBIT,
              vo_sl_no: index + 1,
              vo_cr: 0,
              vo_db: totalAmount,
              vo_cr_total: 0,
              vo_db_total: totalAmount,
              acct_ldgr_id: dbData.acct_ldgr_id,
            })),
            acct_voucher_cr: items.map((crData, index) => ({
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_sl_no: index + 1,
              vo_cr: crData.ledgerAmt,
              vo_db: 0,
              vo_cr_total: totalAmount,
              vo_db_total: 0,
              acct_ldgr_id: crData.acct_ldgr_id,
            })),
          },
        },
        refetchQueries: [
          {
            query: GetAcctVouchers,
            variables: {
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              sortBy: SortBy.V_DATE,
              token,
              name: EMPTY_STRING,
              input: {
                inst_id: InstId!,
                voucher_query_type: VoucherQueryTypes.STUDENT_ALL_RECEIPTS,
              },
            },
          },
          {
            query: GetStudentEnquiresByEnquiryId,
            variables: {
              token,
              id: state.studentEnquiryId,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          const { v_no, v_date } = data.AddAcctChallanMasterAndDetails;
          setTableData(
            items.map(({ ledgerAmt, ledgerName }) => ({
              particular: ledgerName,
              amount: ledgerAmt,
            }))
          );
          set_v_Date(v_date);
          set_vo_no(v_no);
          set_transaction_no(transaction_no);
          SetPrintModal(true);
          setBankDetailsForPrint({
            account_no:
              data.AddAcctChallanMasterAndDetails.acct_ldgr_db_details
                .ldgr_bank_ac_no,
            bank_name:
              data.AddAcctChallanMasterAndDetails.acct_ldgr_db_details
                .ldgr_bank_name,
          });

          setMessage({
            message: "Application Fee Challan generated Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
  };

  const handleClear = () => {
    setTransactionLedgerId(null);
    setNarration("");
    dispatch({
      type: payloadTypes.SET_STD_ENQUIRY_ID,
      payload: {
        studentEnquiryId: 0,
      },
    });
    setItems([]);
    setSelectedFeeLedger(null);
    setFeeAmount(0);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (transactionBookLedgerId && transactionBookLedgerId.value > 0) {
      handleDebitItems();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);
  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);

  useEffect(() => {
    if (state.ActiveFinYr && activeAcademicYearData.data) {
      GetStudentEnquiries().then(({ data }) => {
        if (data && data.GetStudentEnquires) {
          setResponseType(
            data.GetStudentEnquires.edges.map((d) => ({
              label:
                d.node.first_name +
                " " +
                d.node.middle_name +
                " " +
                d.node.last_name,
              value: d.node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [state.ActiveFinYr, activeAcademicYearData.data, GetStudentEnquiries]);
  const findStudent = () => {
    const res = data?.GetStudentEnquires.edges.filter(
      (edge) => edge.node.id === state.studentEnquiryId
    )[0];
    if (res) {
      return {
        value: res.node.id,
        label:
          res.node.first_name +
          " " +
          res.node.middle_name +
          " " +
          res.node.last_name,
      };
    } else return null;
  };
  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.VOUCHER_EDIT_DATE,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.VOUCHER_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
    };
  };
  const { editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );

  const { branchLabel, classLabel } = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row">
        <div className="col">
          <Title> Application Challan Receipt for Enquiry Students</Title>
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "student-fee-enquiry"
            : "student-fee-enquiry--modal"
        }
      >
        <div className="student-fee-enquiry__details">
          <div className="row g-0 student-fee-enquiry__details--select-options">
            <div className="col">
              <div className="student-fee-enquiry__details--imagefield">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={responseType}
                  value={findStudent()}
                  isOptionEqualToValue={(option) =>
                    (option as responseType).value === state.studentEnquiryId
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={(option as responseType).value}>
                        {(option as responseType).label}
                      </li>
                    );
                  }}
                  openOnFocus
                  disabled={studentSelected}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      if (state.studentEnquiryId) {
                        acctLedgerInputRef?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STD_ENQUIRY_ID,
                        payload: {
                          studentEnquiryId: 0,
                        },
                      });
                      setSearchData(EMPTY_STRING);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STD_ENQUIRY_ID,
                        payload: {
                          studentEnquiryId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      dispatch({
                        type: payloadTypes.SET_STD_ENQUIRY_ID,
                        payload: {
                          studentEnquiryId: 0,
                        },
                      });
                    }
                  }}
                  autoHighlight
                  forcePopupIcon
                  popupIcon={<img src={DownArrow} alt="/" />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setSearchData(e.target.value)}
                      label="Search Student"
                      autoFocus
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
                {studentSelected === false && (
                  <img
                    className="data-fetch-icon"
                    src={Edit}
                    alt="/"
                    onClick={() => setStudentModal(!studentModal)}
                  />
                )}
              </div>

              <TextField
                label={branchLabel}
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={enquiryFormData.seekingBranch}
                disabled
              />

              <TextField
                label={classLabel}
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={enquiryFormData.seekingClass}
                disabled
              />
            </div>

            <div className="col">
              <TextField
                label="Student Name"
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={enquiryFormData.enq_student_name}
                disabled
              />

              <TextField
                label="Email"
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={enquiryFormData.std_email}
                disabled
              />
              {/* <TextField
                
                label="Ledger Name"
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={
                  OnlyApplicationLedger.data?.GetAcctLdgrs?.edges[0]?.node
                    ?.ldgr_desc ?? EMPTY_STRING
                }
                disabled
              /> */}
            </div>
            <div className="col">
              <TextField
                label="Father Name"
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={enquiryFormData.std_father_name}
                disabled
              />
              <TextField
                label="Date"
                className="student-fee-enquiry__details--textfield--date"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
            input:{
              inputProps: {
                min: state.ActiveFinYr
                  ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                  : EMPTY_STRING,
                max: toInputStandardDate(TODAY_DATE),
              },
            }
          }}
                type="date"
                value={toInputStandardDate(rcptDate)}
          
                onChange={(e) => setRcptDate(e.target.value)}
                disabled={editDate ? false : true}
              />
              {/* <TextField
                
                label="Amount"
                value={
                  configData.data?.GetSwConfigVariables[0]
                    .config_integer_value ?? 0
                }
                disabled
                className="student-fee-enquiry__details--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              /> */}
            </div>
          </div>

          <div className="row g-0 student-fee-enquiry__tableblock">
            <TableContainer className="student-fee-enquiry__table g-0">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.Receipts.EnquiryFeeReceipt.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="student-fee-enquiry__table--select-row">
                    <TableCell
                      id="td-center"
                      className="student-fee-enquiry__table--slno"
                    >
                      {items.length + 1}
                    </TableCell>
                    <TableCell className="student-fee-enquiry__table--desc">
                      {edit && selectedFeeLedger ? (
                        selectedFeeLedger.label
                      ) : (
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          isOptionEqualToValue={(option) => {
                            if (selectedFeeLedger) {
                              return (
                                (
                                  option as {
                                    label: string;
                                    value: number;
                                    defaultAmt: number;
                                  }
                                ).value === selectedFeeLedger.value
                              );
                            } else {
                              return false;
                            }
                          }}
                          options={
                            NonDemandCapLedgers.data
                              ? NonDemandCapLedgers.data.GetAcctLdgrsForNonDemand.filter(
                                  ({ id }) =>
                                    (edit === false &&
                                      acctLdgrsInItemsSet.has(id) === false) ||
                                    edit
                                ).map((node) => ({
                                  label: node.ldgr_desc,
                                  value: node.id,
                                  defaultAmt: node.ldgr_std_fee,
                                }))
                              : []
                          }
                          ref={acctLedgerRef!}
                          openOnFocus
                          autoHighlight
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER) {
                              if (selectedFeeLedger) {
                                if (amtRef.current) {
                                  amtRef.current.focus();
                                }
                              }
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setSelectedFeeLedger(null);
                            }
                          }}
                          value={selectedFeeLedger}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setSelectedFeeLedger(
                                newValue as {
                                  label: string;
                                  value: number;
                                  defaultAmt: number;
                                }
                              );
                              setFeeAmount(
                                (
                                  newValue as {
                                    label: string;
                                    value: number;
                                    defaultAmt: number;
                                  }
                                ).defaultAmt
                              );
                            } else {
                              setSelectedFeeLedger(null);
                              setFeeAmount(0);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      className="student-fee-enquiry__table--input"
                      id="td-right"
                    >
                      <InputNumber
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFeeAmount(Number(e.target.value));
                        }}
                        inputRef={amtRef}
                        value={feeAmount}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (e.key === Keys.ENTER) {
                            if (selectedFeeLedger && feeAmount) {
                              if (
                                acctLdgrsInItemsSet.has(selectedFeeLedger.value)
                              ) {
                                setMessage({
                                  message: "Ledger already exists",
                                  flag: true,
                                  operation: Operation.NONE,
                                });
                              } else {
                                if (edit) {
                                  const editedItem = items.map((data) => {
                                    if (
                                      data.acct_ldgr_id ===
                                      selectedFeeLedger.value
                                    ) {
                                      return {
                                        ...data,
                                        ledgerAmt: feeAmount,
                                      };
                                    } else {
                                      return data;
                                    }
                                  });
                                  setItems(editedItem);
                                  setEdit(!edit);
                                } else
                                  setItems((prev) => [
                                    {
                                      acct_ldgr_id: selectedFeeLedger.value,
                                      ledgerAmt: feeAmount,
                                      ledgerName: selectedFeeLedger.label,
                                      defaultAmt: selectedFeeLedger.defaultAmt,
                                    },
                                    ...prev,
                                  ]);
                                setFeeAmount(0);
                                setSelectedFeeLedger(null);
                                acctLedgerInputRef.focus();
                              }
                            }
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className="student-fee-enquiry__table--actions"
                    >
                      <button>
                        {edit ? "Save" : <img src={Enter} alt="/" />}
                      </button>
                    </TableCell>
                  </TableRow>
                  {items.map(
                    (
                      {
                        ledgerAmt,
                        ledgerName,
                        acct_ldgr_id: acctLedgerId,
                        defaultAmt,
                      },
                      index
                    ) => {
                      return (
                        <TableRow key={index}>
                          <TableCell id="td-center">{index + 1}</TableCell>
                          <TableCell>{ledgerName}</TableCell>
                          <TableCell
                            className="student-fee-enquiry__table--balance"
                            id="td-right"
                          >
                            {ledgerAmt}
                          </TableCell>
                          <TableCell id="td-center">
                            <img
                              src={EditProfile}
                              alt="/"
                              onClick={() => {
                                setFeeAmount(ledgerAmt);
                                setSelectedFeeLedger({
                                  label: ledgerName,
                                  value: acctLedgerId,
                                  defaultAmt: defaultAmt,
                                });
                                setEdit(!edit);
                              }}
                            />
                            <img
                              src={Delete}
                              id="delete-profile"
                              alt="/"
                              onClick={() => {
                                setItems(
                                  items.filter(
                                    ({ acct_ldgr_id }) =>
                                      acct_ldgr_id !== acctLedgerId
                                  )
                                );
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} className="total">
                      Total :
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totalAmount)}
                    </TableCell>
                    <TableCell id="tfoot-td"></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <form className="row g-0 student-fee-enquiry__receiptblock">
            <div className="col account-frames h-100">
              <div className="label-grid">
                <Label>Challan No.</Label>
                <Input
                  disabled
                  value={
                    voucherNumber.data?.GetVoucherNumber.vo_number ??
                    EMPTY_STRING
                  }
                />
              </div>
              <div className="label-grid">
                <Label> Mode of Transaction</Label>
                <Input disabled value="Bank Book" />
              </div>
            </div>

            <div className="col account-frames h-100">
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label>Bank Transaction Type</Label>
                <Input disabled value="Challan" />
              </div>
              <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                <Label> Bank Ledgers</Label>
                <RequiredAutoComplete
                  className={formClasses.inputRoot}
                  options={BankLedgers.responseType!}
                  openOnFocus
                  autoHighlight
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(
                      option as responseType,
                      transactionBookLedgerId
                    )
                  }
                  value={transactionBookLedgerId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setTransactionLedgerId(newValue as responseType);
                    } else {
                      setTransactionLedgerId(null);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (transactionBookLedgerId && e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setTransactionLedgerId(null);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      onChange={(e) => setSearchData(e.target.value)}
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col account-frames label-grid h-100">
              <Label>Remarks :</Label>
              <TextArea
                rows={3}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    saveButtonRef.current?.focus();
                  }
                }}
                value={narration}
                onChange={(e) => setNarration(e.target.value)}
              />
            </div>
          </form>
        </div>

        <Button
          mode="save"
          disabled={!transactionBookLedgerId}
          buttonref={saveButtonRef}
          onClick={handleStudentReceipts}
        />
        <Button mode="clear" onClick={handleClear} />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setFeeReceiptModal(false)} />
        )}
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              setModalFlag={setStudentModal}
              queryType={StdEnqQueryType.STD_ENQ_ALL}
              shouldDispatchId={true}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ChallanPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no=""
              setModal={SetPrintModal}
              bankDetailsForPrint={bankDetailsForPrint}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
                setFeeReceiptModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ApplicationFeeChallan;

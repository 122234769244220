import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../../Types/Tables";

import { formatter } from "../../../../../utils/UtilFunctions";

import { EMPTY_STRING } from "../../../../../utils/constants";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import {
  acct_ldgr_details,
  GetAcctReceiptsAndPaymentsReportVars,
} from "../R&P/Index";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  GetAcctAssetsObReport,
  GetAcctBankReport,
  GetAcctCashReport,
  GetAcctCorpusFundReport,
  GetAcctLiabilitiesObReport,
} from "../../../queries/FeeLedgers/query/Byid";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import LoadingModal from "../../../../../pages/LoadingModal";
import { acct_group_ldgr_details } from "../../../hooks/useAcctLedgerData";
import { GetAcctBankReportData, GetAcctCashReportData } from "../types";
import { AccountingLedgerModalStyles } from "../../../../../styles/ModalStyles";
import Close from "../../../../../images/Close.svg";
import Modal from "react-modal";
import { UpdateAcctLdgrById } from "../../../queries/Accountingledgers/mutations/Index";
import { Operation, PageFor } from "../../../../../utils/Enum.types";
import { msgType } from "../../../../../utils/Form.types";
import { AccLedgerByNodeId } from "../../../queries/GroupLedgers/query/ById";
import MessageModal from "../../../../../pages/MessageModal";
import AccountingLedger from "../../../AccountingLedger/Index";
import { Button } from "../../../../../stories/Button/Button";
import useAcctTableJson from "../../../json/useAcctTableJson";
import Add from "../../../../../images/Add.svg";
interface gr_ldgr_bs_summary {
  id: number;
  gr_bs_ob: number;
  gr_bs_cr: number;
  gr_bs_db: number;
  gr_bs_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}
interface acct_ldgr_bs_summary {
  ldgr_bs_ob: number;
  ldgr_bs_cr: number;
  ldgr_bs_db: number;
  ldgr_bs_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
  acct_ldgr_details: acct_ldgr_details;
}
interface GetAcctIncomeReportDetails {
  group_ldgr_bs_summary: gr_ldgr_bs_summary[];
  acct_ldgr_bs_summary: acct_ldgr_bs_summary[];
}
interface GetAcctIncomeReportData {
  GetAcctAssetsObReport: GetAcctIncomeReportDetails;
  GetAcctLiabilitiesObReport: GetAcctIncomeReportDetails;
  GetAcctBankReport: GetAcctIncomeReportDetails;
}

export interface GetAcctProfitAndLossReportDetails {
  gr_ie_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}

interface GetAcctCorpusFundReportDetails {
  gr_bs_ob: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}
export interface GetAcctProfitAndLossReportData {
  GetAcctProfitAndLossReport: GetAcctProfitAndLossReportDetails;
}

interface GetAcctCorpusFundReportData {
  GetAcctCorpusFundReport: GetAcctCorpusFundReportDetails;
}
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const OpeningBalancrBs = ({ setModal }: Props) => {
  const { dispatch, state } = useContext(AppContext);
  // eslint-disable-next-line
  const [ldgrOb, setLdgrOb] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  // eslint-disable-next-line
  const [GetAccountLedger, { data: AccountingLedgerDetails }] =
    useLazyQuery(AccLedgerByNodeId);
  // eslint-disable-next-line
  const [UpdateAccountingLedgers] = useMutation(UpdateAcctLdgrById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const { token } = useToken();
  const { InstId } = useParams();

  const [editOpeningBalance, setEditOpeningBalance] = useState(false);

  //   const handleUpdate = () => {
  //     UpdateAccountingLedgers({
  //       variables: {
  //         token,
  //         id: state.accountLedgerId,
  //         input: {
  //           inst_id: InstId,
  //           ldgr_ob: ldgrOb,
  //           ldgr_desc: AccountingLedgerDetails?.node?.ldgr_desc,
  //           cr_or_db: AccountingLedgerDetails?.node?.cr_or_db,
  //           acct_group_ldgr_id: AccountingLedgerDetails?.node?.acct_group_ldgr_id,
  //         },
  //       },
  //       refetchQueries: [
  //         {
  //           query: GetAcctAssetsReport,
  //           variables: {
  //             fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

  //             inst_id: InstId!,
  //             token,
  //           },
  //         },
  //         {
  //           query: GetAcctLiabilitiesObReport,
  //           variables: {
  //             fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

  //             inst_id: InstId!,
  //             token,
  //           },
  //         },
  //         {
  //           query: GetAcctProfitAndLossReport,
  //           variables: {
  //             fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

  //             inst_id: InstId!,
  //             token,
  //           },
  //         },
  //         {
  //           query: GetAcctBankReport,
  //           variables: {
  //             fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

  //             inst_id: InstId!,
  //             token,
  //           },
  //         },
  //         {
  //           query: GetAcctCashReport,
  //           variables: {
  //             fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

  //             inst_id: InstId!,
  //             token,
  //           },
  //         },
  //         {
  //           query: GetAcctCorpusFundReport,
  //           variables: {
  //             fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

  //             inst_id: InstId!,
  //             token,
  //           },
  //         },
  //       ],
  //     }).then(({ data }) => {
  //       if (data) {
  //         setMessage({
  //           message: "Account Ledger Updated Successfully",
  //           flag: true,
  //           operation: Operation.UPDATE,
  //         });
  //       }
  //     });
  //   };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      //enableAdd and enableEdit should stay false when you close the success modal
      setEditOpeningBalance(false);
      setLdgrOb(0);
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  let totalLiabality = 0;
  let totalAssests = 0;
  let grandTotalofCorpusAccount = 0;
  let liabilityGrandTotal = 0;
  let assestsGrandTotal = 0;
  let cashAccountGrandTotal = 0;
  let bankAccountGrandTotal = 0;
  let differenceGrandTotal = 0;
  const [GetAcctAssets, { data: Assests, loading, error }] = useLazyQuery<
    GetAcctIncomeReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctAssetsObReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const [
    GetAcctLib,
    { data: Liabilities, loading: LiabilitiesLoading, error: LiabilitiesError },
  ] = useLazyQuery<
    GetAcctIncomeReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctLiabilitiesObReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });

  const [GetAcctBankRpt, { data: BankAccount }] = useLazyQuery<
    GetAcctBankReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctBankReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });

  const [GetAcctCash, { data: CashAccount }] = useLazyQuery<
    GetAcctCashReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctCashReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const [GetAcctCorpusFund, { data: CorpusAccount }] = useLazyQuery<
    GetAcctCorpusFundReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctCorpusFundReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });

  grandTotalofCorpusAccount = CorpusAccount
    ? CorpusAccount.GetAcctCorpusFundReport.gr_bs_ob
    : 0;
  useEffect(() => {
    if (token && state.ActiveFinYr && InstId) {
      GetAcctCorpusFund();
      GetAcctCash();
      GetAcctBankRpt();
      GetAcctLib();
      GetAcctAssets();
    }
  }, [
    token,
    GetAcctCorpusFund,
    GetAcctCash,
    GetAcctBankRpt,
    GetAcctLib,
    GetAcctAssets,
    InstId,
    state.ActiveFinYr,
  ]);

  // eslint-disable-next-line
  Liabilities?.GetAcctLiabilitiesObReport.group_ldgr_bs_summary.map((edge) => {
    totalLiabality += edge.gr_bs_ob;
  });
  //  eslint-disable-next-line
  Assests?.GetAcctAssetsObReport?.group_ldgr_bs_summary?.map((edge) => {
    totalAssests += edge.gr_bs_ob;
  });
  //eslint-disable-next-line
  BankAccount?.GetAcctBankReport.group_ldgr_bank_summary.map((edge) => {
    bankAccountGrandTotal += edge.gr_bs_ob;
  });
  //eslint-disable-next-line
  CashAccount?.GetAcctCashReport.group_ldgr_cash_summary.map((edge) => {
    cashAccountGrandTotal += edge.gr_bs_ob;
  });
  liabilityGrandTotal = totalLiabality + grandTotalofCorpusAccount;
  assestsGrandTotal =
    totalAssests + cashAccountGrandTotal + bankAccountGrandTotal;
  differenceGrandTotal =
    assestsGrandTotal > liabilityGrandTotal
      ? assestsGrandTotal - liabilityGrandTotal
      : liabilityGrandTotal - assestsGrandTotal;
  return (
    <>
      <Title>Ledgers Opening Balance</Title>
      <div className="balance-sheet__modal">
        <div className="row g-0 balance-sheet__tableblock">
          <div className="col h100">
            {LiabilitiesError ? (
              <b className="nodata">{LiabilitiesError.message}</b>
            ) : (
              <TableContainer className="balance-sheet__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="balance-sheet__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Liabilities</TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    <TableRow className="balance-sheet__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      <TableRow>
                        <TableCell>
                          {
                            CorpusAccount?.GetAcctCorpusFundReport
                              .acct_group_ldgr_details.gr_ldgr_desc
                          }
                        </TableCell>
                        <TableCell id="td-right">
                          {format(grandTotalofCorpusAccount)}
                        </TableCell>
                        <TableCell id="td-right">
                          {format(grandTotalofCorpusAccount)}
                        </TableCell>
                      </TableRow>

                      {Liabilities?.GetAcctLiabilitiesObReport?.group_ldgr_bs_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="balance-sheet__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {Liabilities.GetAcctLiabilitiesObReport.acct_ldgr_bs_summary
                                ?.filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow
                                      key={index2}
                                      onClick={() => {
                                        dispatch({
                                          type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                          payload: {
                                            accountLedgerId:
                                              ldgr.acct_ldgr_details.id,
                                          },
                                        });
                                        GetAccountLedger({
                                          variables: {
                                            token,
                                            id: ldgr.acct_ldgr_details.id,
                                          },
                                        }).then(({ data }) => {
                                          if (data) {
                                            setLdgrOb(data.node.ldgr_ob);
                                          }
                                        });
                                      }}
                                    >
                                      <TableCell>
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount"
                                      >
                                        {ldgr.ldgr_bs_ob < 0
                                          ? `(${format(
                                              Math.abs(ldgr.ldgr_bs_ob)
                                            )})`
                                          : format(ldgr.ldgr_bs_ob)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount font-green "
                                      >
                                        {index2 ===
                                        Liabilities.GetAcctLiabilitiesObReport.acct_ldgr_bs_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? edge.gr_bs_ob < 0
                                            ? `(${format(
                                                Math.abs(edge.gr_bs_ob)
                                              )})`
                                            : format(edge.gr_bs_ob)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div className="col h100">
            {error ? (
              <b className="nodata">{error.message}</b>
            ) : (
              <TableContainer className="balance-sheet__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="balance-sheet__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Assests </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="balance-sheet__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {Assests?.GetAcctAssetsObReport.group_ldgr_bs_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="balance-sheet__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {Assests.GetAcctAssetsObReport.acct_ldgr_bs_summary
                                .filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow key={index2}>
                                      <TableCell
                                        onClick={() => {
                                          dispatch({
                                            type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                            payload: {
                                              accountLedgerId:
                                                ldgr.acct_ldgr_details.id,
                                            },
                                          });
                                          GetAccountLedger({
                                            variables: {
                                              token,
                                              id: ldgr.acct_ldgr_details.id,
                                            },
                                          }).then(({ data }) => {
                                            if (data) {
                                              setLdgrOb(data.node.ldgr_ob);
                                            }
                                          });
                                          setEditOpeningBalance(true);
                                        }}
                                      >
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount"
                                      >
                                        {ldgr.ldgr_bs_ob < 0
                                          ? `(${format(
                                              Math.abs(ldgr.ldgr_bs_ob)
                                            )})`
                                          : format(ldgr.ldgr_bs_ob)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount font-green"
                                      >
                                        {index2 ===
                                        Assests.GetAcctAssetsObReport.acct_ldgr_bs_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? edge.gr_bs_ob < 0
                                            ? `(${format(
                                                Math.abs(edge.gr_bs_ob)
                                              )})`
                                            : format(edge.gr_bs_ob)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                      {CashAccount?.GetAcctCashReport.group_ldgr_cash_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="balance-sheet__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {CashAccount?.GetAcctCashReport.acct_ldgr_cash_summary
                                .filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow key={index2}>
                                      <TableCell>
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount"
                                      >
                                        {format(ldgr.ldgr_bs_ob)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount font-green"
                                      >
                                        {index2 ===
                                        CashAccount?.GetAcctCashReport.acct_ldgr_cash_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? format(edge.gr_bs_ob)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                      {BankAccount?.GetAcctBankReport.group_ldgr_bank_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="balance-sheet__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {BankAccount?.GetAcctBankReport.acct_ldgr_bank_summary
                                .filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow key={index2}>
                                      <TableCell>
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount"
                                      >
                                        {format(ldgr.ldgr_bs_ob)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount font-green"
                                      >
                                        {index2 ===
                                        BankAccount?.GetAcctBankReport.acct_ldgr_bank_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? format(edge.gr_bs_ob)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        <div className="row g-0 balance-sheet__difference-amount--column">
          <div className="col">
            {assestsGrandTotal > liabilityGrandTotal && (
              <div className="balance-sheet__difference-amount">
                <span> Difference In Opening Balance :</span>
                <b> {format(differenceGrandTotal)}</b>
              </div>
            )}
          </div>
          <div className="col">
            {assestsGrandTotal < liabilityGrandTotal && (
              <div className="balance-sheet__difference-amount">
                <span>Difference In Opening Balance :</span>
                <b> {format(differenceGrandTotal)}</b>
              </div>
            )}
          </div>
        </div>
        <div className="balance-sheet__footer row g-0">
          <div className="col balance-sheet__footer--total">
            <div className="balance-sheet__footer--total--text">
              Grand Total :
            </div>
            <div className="balance-sheet__footer--total--count">
              {(assestsGrandTotal > liabilityGrandTotal
                ? liabilityGrandTotal + differenceGrandTotal
                : liabilityGrandTotal) < 0
                ? `(${format(
                    Math.abs(
                      assestsGrandTotal > liabilityGrandTotal
                        ? liabilityGrandTotal + differenceGrandTotal
                        : liabilityGrandTotal
                    )
                  )})`
                : format(
                    assestsGrandTotal > liabilityGrandTotal
                      ? liabilityGrandTotal + differenceGrandTotal
                      : liabilityGrandTotal
                  )}
            </div>
          </div>

          <div className="col balance-sheet__footer--total">
            <div className="balance-sheet__footer--total--text">
              Grand Total :
            </div>
            <div className="balance-sheet__footer--total--count">
              {(assestsGrandTotal > liabilityGrandTotal
                ? assestsGrandTotal
                : assestsGrandTotal + differenceGrandTotal) < 0
                ? `(${format(
                    Math.abs(
                      assestsGrandTotal > liabilityGrandTotal
                        ? assestsGrandTotal
                        : assestsGrandTotal + differenceGrandTotal
                    )
                  )})`
                : format(
                    assestsGrandTotal > liabilityGrandTotal
                      ? assestsGrandTotal
                      : assestsGrandTotal + differenceGrandTotal
                  )}
            </div>
          </div>
        </div>
        <Button onClick={() => setEditOpeningBalance(!editOpeningBalance)}>
          <img src={Add} alt="" /> Add / Edit Opening Balance
        </Button>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <LoadingModal flag={loading || LiabilitiesLoading} />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editOpeningBalance}
        style={AccountingLedgerModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AccountingLedger
              type={PageFor.MODAL}
              setModalFlag={setEditOpeningBalance}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditOpeningBalance(!editOpeningBalance)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default OpeningBalancrBs;

import React, { useContext, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import List from "./List";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddAcdGradeScaleFormTypes,
  GetAcdGradeScalesByInstIdList,
} from "../../types/grade";
import { useMutation } from "@apollo/client";
import {
  AddAcdGradeScale,
  ReOrderAcdGradeScale,
  UpdateAcdGradeScale,
} from "../../queries/grade/mutation";
import { msgType } from "../../../../utils/Form.types";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { GetAcdGradeScalesByInstId } from "../../queries/grade";
import { AppContext } from "../../../../context/context";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import { Form, Formik } from "formik";
import { grade_validation } from "../../../../utils/validationRules";
import Input from "../../../../components/common/Input/Index";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import InputNumber from "../../../../components/common/Input/InputNumber";
import { Label } from "../../../../stories/Label/Label";
import TextArea from "../../../../stories/TextArea/TextArea";

interface Props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditGrade = ({ pageType, setModal }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();
  const [reOrder, setReOrder] = useState(false);
  const [gradeId, setGradeId] = useState(0);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [localItems, setLocalItems] = useState<GetAcdGradeScalesByInstIdList[]>(
    []
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { state } = useContext(AppContext);
  const [formData, setFormData] = useState<AddAcdGradeScaleFormTypes>({
    grade: "",
    marks_max: 0,
    marks_min: 0,
    remarks: "",
  });

  const nameRef = document.getElementsByName("grade")[0] as HTMLInputElement;

  const [NewGrade, { loading: creationLoading }] = useMutation(
    AddAcdGradeScale,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateGrade, { loading: updationLoading }] = useMutation(
    UpdateAcdGradeScale,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const fetchEditDetails = (
    id: number,
    grade: string,
    marks_max: number,
    marks_min: number,
    remarks: string
  ) => {
    setGradeId(id);
    setFormData({ grade, marks_max, marks_min, remarks });
    setOperation(Operation.UPDATE);
  };

  const handleRegister = () => {
    if (InstId)
      NewGrade({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            grade: removeMoreSpace(formData.grade),
            marks_min: formData.marks_min,
            marks_max: formData.marks_max,
            grade_remarks: formData.remarks,
          },
        },
        refetchQueries: [
          {
            query: GetAcdGradeScalesByInstId,
            variables: {
              token,
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Grade Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    setFormData({
      grade: "",
      marks_max: 0,
      marks_min: 0,
      remarks: "",
    });
    setOperation(Operation.CREATE);
    handleClear();
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleValueTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>)  => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleClose = () => {
    setMessage({
      message: " ",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };
  const handleEdit = () => {
    if (InstId)
      updateGrade({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input: {
            grade: removeMoreSpace(formData.grade),
            marks_max: formData.marks_max,
            marks_min: formData.marks_min,
            grade_remarks: formData.remarks,
          },
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          id: gradeId,
        },
        refetchQueries: [
          {
            query: GetAcdGradeScalesByInstId,
            variables: {
              token,
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Grade Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    setFormData({
      grade: "",
      marks_max: 0,
      marks_min: 0,
      remarks: "",
    });
    setOperation(Operation.UPDATE);
    handleClear();
  };

  const [ReOrderGrade, { loading: reorderLoading }] = useMutation(
    ReOrderAcdGradeScale,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const HandleSaveDragAndDrop = () => {
    // eslint-disable-next-line
    ReOrderGrade({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.id,
          idx: index + 1,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdGradeScalesByInstId,
          variables: {
            token,
            inst_id: InstId,
            user_details,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.ReOrderAcdGradeScale) {
        setReOrder(false);

        setMessage({
          message: "Re-Order Successful",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleClear = () => {
    setFormData({
      grade: "",
      marks_max: 0,
      marks_min: 0,
      remarks: "",
    });
    nameRef.focus();
  };

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title> Grades</Title>
      <Formik
        initialValues={formData}
        validationSchema={grade_validation}
        onSubmit={operation === Operation.UPDATE ? handleEdit : handleRegister}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                pageType === PageFor.GENERAL
                  ? "grade__edit"
                  : "grade__edit--modal"
              }
            >
              <div className="grade__edit--frame row g-0">
                <div className="col grade__edit--frame--left">
                  <Title variant="subtitle1">
                    {operation === Operation.CREATE
                      ? "Add New Grade"
                      : "Update Grade"}
                  </Title>
                  <div className="grade__edit--add">
                    <Input
                      required
                      type="text"
                      LabelName="Grade"
                      values={[formData.grade]}
                      name="grade"
                      autoFocus
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        meta.handleChange(e);
                        handleValueChange(e);
                      }}
                    />

                    <div className="label-grid">
                      <Label>Higher Percentage</Label>
                      <InputNumber
                        type="number"
                        step="0.01"
                        value={[formData.marks_max]}
                        name="marks_max"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          handleValueChange(e);
                        }}
                      />
                      <Label>Lower Percentage</Label>
                      <InputNumber
                        type="number"
                        step="0.01"
                        value={[formData.marks_min]}
                        name="marks_min"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          handleValueChange(e);
                        }}
                      />
                      <Label>Grade Remarks</Label>
                      <TextArea rows={3}                         value={[formData.remarks]}   name="remarks"
                        autoFocus
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                          meta.handleChange(e);
                          handleValueTextAreaChange(e);
                        }}
/>
               
                    </div>
               
                  </div>
                </div>
                <div className="col grade__edit--frame--right">
                  <List
                    fetchEditDetails={fetchEditDetails}
                    reOrder={reOrder}
                    localItemsState={{ localItems, setLocalItems }}
                  />
                </div>
              </div>
              <Button mode="save" type="submit" />
              <Button mode="clear" onClick={handleClear} />
              {reOrder ? (
                <Button mode="save" onClick={HandleSaveDragAndDrop}>
                  &nbsp;Order
                </Button>
              ) : (
                <>
                  <Button mode="reorder" onClick={() => setReOrder(!reOrder)} />
                </>
              )}{" "}
              {pageType === PageFor.GENERAL ? (
                <Button mode="back" onClick={() => navigate(-1)} />
              ) : (
                <Button mode="cancel" onClick={() => setModal(false)} />
              )}
            </Form>
          );
        }}
      </Formik>
      <LoadingModal
        flag={creationLoading || updationLoading || reorderLoading}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default EditGrade;

import React, { useEffect, useState } from "react";
import { GetAcdSubjectGroups } from "../../../../queries/customallocation/groups/queries";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdSubjectGroupsData,
  GetAcdSubjectGroupsVars,
} from "../../../../Types/Groups/queries";
import useToken from "../../../../customhooks/useToken";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import { Direction, SortBy } from "../../../../utils/Enum.types";
import { responseType } from "../../../../utils/Form.types";
import { subjectOptions } from "../../../../utils/types";

export interface groupOptionsType extends responseType {
  subjects: subjectOptions[];
}

const useGroups = (searchData: string, rowsPerPage: number) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [groupOptions, setGroupOptions] = useState<groupOptionsType[]>([]);
  const [GetAcdSubjectGroup, { data, loading, fetchMore, error }] =
    useLazyQuery<GetAcdSubjectGroupsData, GetAcdSubjectGroupsVars>(
      GetAcdSubjectGroups,
      {
        variables: {
          token,
          after: null,
          first: rowsPerPage,
          inst_id: InstId!,
          name: searchData,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.GROUP_NAME,
          },
        },
      }
    );

  useEffect(() => {
    if (token) {
      GetAcdSubjectGroup().then(({ data }) => {
        if (data && data.GetAcdSubjectGroups) {
          setGroupOptions(
            data.GetAcdSubjectGroups.edges.map(({ node }) => ({
              label: node.group_name,
              value: node.id,
              subjects: node.subj_group_details.map((sub) => ({
                label: sub.subject_details.subj_desc,
                value: sub.subject_details.id,
                subj_code: sub.subject_details.subj_code,
              })),
            }))
          );
        }
      });
    }
  }, [token, InstId, searchData, rowsPerPage, data]);

  return {
    data,
    loading,
    error,
    groupOptions,
    fetchMore,
  };
};

export default useGroups;

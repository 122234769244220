import { NavLink, useParams } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";

import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useNotAlloactedIdFromInst from "../../../customhooks/useNotAlloactedIdFromInst";
import { InstitutionDetails } from "../../../utils/Enum.types";
import useInstitutionDetailsDataByIds from "../../../customhooks/useInstitutionDetailsDataByIds";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { ConfigurationTitlesType } from "../../../Types/Institution/json";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import Header from "../../../components/common/Header/index";
import BreadCrumb from "../../../components/common/BreadCrumb/index";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../context/context";
const { Configuration } = require("../../../json/title.json");

const InstitutionConfiguration = () => {
  const { InstId } = useParams();
  const [deptId, setDeptId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [semesterId, setSemesterId] = useState(0);
  const { state } = useContext(AppContext);
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    NotAllocatedDeptId,
    NotAllocatedBranchId,
    NotAllocatedClassId,
    NotAllocatedSemesterId,
  } = useNotAlloactedIdFromInst();
  const { departmentDropDown } = useInstMasterDataByInstId();

  const { BranchByDept: BranchByDeptId } =
    useInstitutionDetailsDataByIds(
      [NotAllocatedDeptId ?? 0]!,
      InstitutionDetails.BRANCH
    ) || {};

  const { ClassByBranch: ClassByBranchId } =
    useInstitutionDetailsDataByIds([branchId!], InstitutionDetails.CLASS) || {};

  const { SemesterByClass: SemesterByClassId } =
    useInstitutionDetailsDataByIds([classId!], InstitutionDetails.SEMESTER) ||
    {};
  useEffect(() => {
    if (departmentDropDown) {
      // if dept is not enabled not allocated dept gets created by default the array length is 1 and index is 0  by using deptby inst id we can directly fecth the same
      setDeptId(departmentDropDown[0]?.value);
    }

    if (BranchByDeptId?.data && !BranchByDeptId?.loading) {
      setBranchId(BranchByDeptId.data[0]?.id);
    }
    if (ClassByBranchId?.data && !ClassByBranchId?.loading) {
      setClassId(ClassByBranchId.data[0]?.id);
    }
    if (SemesterByClassId?.data && !SemesterByClassId?.loading) {
      setSemesterId(SemesterByClassId.data[0]?.id);
    }
    if (USE_DEPARTMENT_KEY === false) {
      setDeptId(NotAllocatedDeptId);
      if (USE_BRANCH_KEY === false) {
        setBranchId(NotAllocatedBranchId);
        if (USE_CLASS_KEY === false) {
          setClassId(NotAllocatedClassId);
          if (USE_SEMESTER_KEY === false) {
            setSemesterId(NotAllocatedSemesterId);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    departmentDropDown,
    BranchByDeptId?.data,
    BranchByDeptId?.loading,
    ClassByBranchId?.data,
    ClassByBranchId?.loading,
    SemesterByClassId?.data,
    SemesterByClassId?.loading,
  ]);
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel
  } = useInstLabels();
  return (
    <>
      {state.ActiveFinYr ? (
        <Home DashBoardRequired={false} />
      ) : (
        <>
          <div className="row g-0">
            <Header />
          </div>
          <div className="row g-0">
            <BreadCrumb />
          </div>
        </>
      )}
      <Title>
        {Configuration.Titles.map(
          (title: ConfigurationTitlesType, index: React.Key) => {
            return <React.Fragment key={index}>{title.Master}</React.Fragment>;
          }
        )}
      </Title>

      <div className="css3-tabstrip">
        <ul>
          <li>
            <NavLink to={`/${InstId}/admissions/viewactiveyears`}>
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-0"
              />

              <label htmlFor="css3-tabstrip-0-0">Active Years</label>
            </NavLink>
          </li>
          {state.ActiveFinYr ? (
            <>
              <>
                {USE_DEPARTMENT_KEY ? (
                  <li>
                    <NavLink to={`/${InstId}/admissions/department`}>
                      <input
                        type="radio"
                        name="css3-tabstrip-0"
                        id="css3-tabstrip-0-2"
                      />
                      <label htmlFor="css3-tabstrip-0-2">
                        {departmentLabel}
                      </label>
                    </NavLink>
                  </li>
                ) : USE_BRANCH_KEY ? (
                  <li>
                    <NavLink
                      to={`/${InstId}/admissions/department/${deptId}/branch`}
                    >
                      <input
                        type="radio"
                        name="css3-tabstrip-0"
                        id="css3-tabstrip-0-2"
                      />
                      <label htmlFor="css3-tabstrip-0-2">{branchLabel}</label>
                    </NavLink>
                  </li>
                ) : USE_CLASS_KEY ? (
                  <li>
                    <NavLink
                      to={`/${InstId}/admissions/department/${deptId}/branch/${branchId}/class`}
                    >
                      <input
                        type="radio"
                        name="css3-tabstrip-0"
                        id="css3-tabstrip-0-2"
                      />
                      <label htmlFor="css3-tabstrip-0-2">{classLabel}</label>
                    </NavLink>
                  </li>
                ) : USE_SEMESTER_KEY ? (
                  <li>
                    <NavLink
                      to={`/${InstId}/admissions/department/${deptId}/branch/${branchId}/class/${classId}/semester`}
                    >
                      <input
                        type="radio"
                        name="css3-tabstrip-0"
                        id="css3-tabstrip-0-2"
                      />
                      <label htmlFor="css3-tabstrip-0-2">{semesterLabel}</label>
                    </NavLink>
                  </li>
                ) : USE_SECTION_KEY ? (
                  <li>
                    <NavLink
                      to={`/${InstId}/admissions/department/${deptId}/branch/${branchId}/class/${classId}/semester/${semesterId}/section`}
                    >
                      <input
                        type="radio"
                        name="css3-tabstrip-0"
                        id="css3-tabstrip-0-2"
                      />
                      <label htmlFor="css3-tabstrip-0-2">{sectionLabel}</label>
                    </NavLink>
                  </li>
                ) : null}
              </>

              {USE_CATEGORY_KEY ? (
                <li>
                  <NavLink to={`/${InstId}/admissions/categories`}>
                    <input
                      type="radio"
                      name="css3-tabstrip-0"
                      id="css3-tabstrip-0-2"
                    />
                    <label htmlFor="css3-tabstrip-0-2">{categoryLabel}</label>
                  </NavLink>
                </li>
              ) : null}
              {USE_SEMESTER_KEY ? (
                <li>
                  <NavLink to={`/${InstId}/admissions/semacdconfig`}>
                    <input
                      type="radio"
                      name="css3-tabstrip-0"
                      id="css3-tabstrip-0-2"
                    />
                    <label htmlFor="css3-tabstrip-0-2">
                      Semester Acd Config
                    </label>
                  </NavLink>
                </li>
              ) : null}
            </>
          ) : null}
        </ul>
      </div>
    </>
  );
};

export default InstitutionConfiguration;

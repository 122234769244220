import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import HolidaysList from "../../../../../images/HolidaysList.svg";
import Close from "../../../../../images/Close.svg";
import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import { Label } from "../../../../../stories/Label/Label";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../../hooks/useAcdGeneralHolidays";
import { AcdYrType, FlagType } from "../Index";
import { MonthName, toStandardDate } from "../../../../../utils/UtilFunctions";
import { GetAcdInstGeneralHolidaysNode } from "../../../types/attendance";
interface Props {
  acdYr: AcdYrType;
  setDrawerFlag: React.Dispatch<React.SetStateAction<FlagType>>;
}

const Holidays = ({ acdYr, setDrawerFlag }: Props) => {
  const [searchText, setSearchText] = useState("");

  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    acdYr ? acdYr.value : 0,
    acdYr.end_date.toString(),
    acdYr.start_date.toString(),
    searchText,
    holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS
  );
  const [holidaysMap, setHolidaysMap] = useState<
    Map<string, GetAcdInstGeneralHolidaysNode[]>
  >(new Map());

  useEffect(() => {
    if (InstGeneralHolidays.data && !InstGeneralHolidays.loading) {
      let convertToMap = new Map();
      InstGeneralHolidays.data.GetAcdInstGeneralHolidays.edges.forEach(
        ({ node }) => {
          const alreadyExistingHolidays = convertToMap.get(
            MonthName(node.holiday_date)
          )
            ? convertToMap.get(MonthName(node.holiday_date))
            : [];
          if (alreadyExistingHolidays.length) {
            convertToMap.set(MonthName(node.holiday_date), [
              ...alreadyExistingHolidays,
              node,
            ]);
          } else {
            convertToMap.set(MonthName(node.holiday_date), [node]);
          }
        }
      );
      setHolidaysMap(convertToMap);
    }
  }, [InstGeneralHolidays.data, InstGeneralHolidays.loading]);

  return (
    <>
      <div className="calendar-holiday-list">
        <div className="calendar-holiday-list--title">
          <Title variant="subtitle1">
            <img src={HolidaysList} alt="" /> &nbsp; All Holidays
          </Title>
          <img
            src={Close}
            alt=""
            className="modal-close-icon"
            onClick={() =>
              setDrawerFlag((prev) => ({
                ...prev,
                flag: false,
              }))
            }
          />
        </div>
        <div className="row g-0 justify-content-center">
          <div className="col-5">
            <Input
              id="search"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="calendar-holiday-list--data">
          <ul className="calendar-holiday-list--data--holiday-list">
            {Array.from(holidaysMap).map(([key, value]) => (
              <>
                <li className="calendar-holiday-list--data--month">{key}</li>
                {value.map((data, index) => (
                  <li>
                    <Label>{index + 1}</Label>
                    <div className="calendar-holiday-list--data--holiday-list--events">
                      <div>
                        <span>{toStandardDate(data.holiday_date)}</span>
                        <br />
                        <b>{data.holiday_desc}</b>
                      </div>
                      <div className="calendar-holiday-list--data--holiday-list--desc">
                        {data.holiday_duration}
                      </div>
                    </div>
                  </li>
                ))}
              </>
            ))}
          </ul>
        </div>
        <Button
          mode="cancel"
          onClick={() =>
            setDrawerFlag((prev) => ({
              ...prev,
              flag: false,
            }))
          }
        />
      </div>
    </>
  );
};

export default Holidays;

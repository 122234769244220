import { LibraryMasters } from "../../enums/Enum.types";

export const getLibraryMasterDetails = (
  MasterType: LibraryMasters,
  records?: any[]
) => {
  switch (MasterType) {
    case LibraryMasters.MEDIA:
      return {
        header: [
          {
            Headers: "MediaName",
            key: "media_desc",
            width: 25,
          },
          {
            Headers: "Abbreviation",
            key: "media_abbreviation",
            width: 25,
          },
          {
            Headers: "Status",
            key: "media_status",
            width: 25,
          },
        ],
        schema: {
          MediaName: {
            name: "media_desc",
          },
          Abbreviation: {
            name: "media_abbreviation",
          },
          Status: {
            name: "media_status",
          },
        },
        sheetname: "MediaInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Department,
          })),
      };

    case LibraryMasters.DEPARTMENT:
      return {
        header: [
          {
            Headers: "DepartmentName",
            key: "dept_name",
            width: 25,
          },
        ],
        schema: {
          DepartmentName: {
            name: "dept_name",
          },
        },
        sheetname: "DepartmentInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Department,
          })),
      };
    case LibraryMasters.BOOK_CATEGORY:
      return {
        header: [
          {
            Headers: "CategoryName",
            key: "category_desc",
            width: 25,
          },
          {
            Headers: "Abbrevation",
            key: "category_abbrevation",
            width: 25,
          },
          {
            Headers: "Status",
            key: "category_is_active",
            width: 25,
          },
        ],
        schema: {
          CategoryName: {
            name: "dept_name",
          },
          Abbrevation: {
            name: "dept_name",
          },
          Status: {
            name: "dept_name",
          },
        },
        sheetname: "CategoryInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Category,
          })),
      };
    case LibraryMasters.SUBJECTS:
      return {
        header: [
          {
            Headers: "SubjectName",
            key: "subject_desc",
            width: 25,
          },
          {
            Headers: "Classification",
            key: "subject_classification",
            width: 25,
          },
          {
            Headers: "Status",
            key: "subject_is_active",
            width: 25,
          },
        ],
        schema: {
          SubjectName: {
            name: "subject_desc",
          },
          Classification: {
            name: "subject_classification",
          },
          Status: {
            name: "subject_is_active",
          },
        },
        sheetname: "SubjectsInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Subjects,
          })),
      };
    case LibraryMasters.PUBLISHER:
      return {
        header: [
          {
            Headers: "PublisherName",
            key: "publisher_name",
            width: 25,
          },
        ],
        schema: {
          PublisherName: {
            name: "publisher_name",
          },
        },
        sheetname: "PublisherInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Subjects,
          })),
      };
    case LibraryMasters.AUTHOR:
      return {
        header: [
          {
            Headers: "AuthorName",
            key: "author_name",
            width: 25,
          },
        ],
        schema: {
          AuthorName: {
            name: "author_name",
          },
        },
        sheetname: "AuthorInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Author,
          })),
      };
    case LibraryMasters.VENDOR:
      return {
        header: [
          {
            Headers: "VendorName",
            key: "vendor_name",
            width: 25,
          },
        ],
        schema: {
          VendorName: {
            name: "vendor_name",
          },
        },
        sheetname: "VendorInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Vendor,
          })),
      };
    default:
      return null;
  }
};

import { useEffect, useState } from "react";
import { GetSwConfigReferenceDataByKey } from "../queries/institution/configuration/query/SoftwreConfig";
import { SoftwareConfigList, SoftwareVars } from "../Types/configtypes";
import { useLazyQuery } from "@apollo/client";
import { optionsType } from "../utils/Form.types";
import useToken from "./useToken";

const useConfigListOptionsByListKey = (list_item_key: string) => {
  const { token } = useToken();
  const [configOptions, setConfigOptions] = useState<optionsType[]>([]);
  const [GetConfigData, { data }] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey);
  useEffect(() => {
    if (token) {
      GetConfigData({
        variables: {
          token,
          config_key: list_item_key,
        },
      }).then(({ data }) => {
        if (data && data.GetSwConfigReferenceDataByKey) {
          const optionsString =
            data.GetSwConfigReferenceDataByKey.list_item_list.split(";");

          setConfigOptions(
            optionsString.map((option) => {
              const label =
                option.includes(":") && option.split(":").length === 2
                  ? option.split(":")[0]
                  : "";
              const value =
                option.includes(":") && option.split(":").length === 2
                  ? option.split(":")[1]
                  : "";

              return {
                label,
                value,
              };
            })
          );
        }
      });
    }
  }, [token, GetConfigData, data, list_item_key]);

  return {
    data,
    configOptions,
  };
};

export default useConfigListOptionsByListKey;

import { gql } from "@apollo/client";

export const UpdateVoucherBooksByBookId = gql`
  mutation UpdateVoucherBooksByBookId(
    $token: String!
    $vo_Book_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwVoucherBookNumbersInput!
  ) {
    UpdateVoucherBooksByBookId(
      token: $token
      vo_Book_id: $vo_Book_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdateBranchWiseAdmissionNumberById = gql`
  mutation UpdateBranchWiseAdmissionNumberById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwInstBranchAdmNumberInput!
  ) {
    UpdateBranchWiseAdmissionNumberById(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateInstWiseAdmissionNumberById = gql`
  mutation UpdateInstWiseAdmissionNumberById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwInstAdmNumberInput!
  ) {
    UpdateInstWiseAdmissionNumberById(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateAccessionNumberConfig = gql`
  mutation UpdateAccessionNumberConfig(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwAccessionNumberInput!
  ) {
    UpdateAccessionNumberConfig(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdateSwConfigStudentDataFieldEnable = gql`
  mutation UpdateSwConfigStudentDataFieldEnable(
    $token: String!
    $user_details: SubscribedUser!
    $input: [UpdateSwConfigStudentDataFieldEnable!]!
  ) {
    UpdateSwConfigStudentDataFieldEnable(
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;

export const AddSwConfigPageInfo = gql`
  mutation AddSwConfigPageInfo(
    $token: String!
    $user_details: SubscribedUser!
    $input: [CreateSwConfigPageInfoInput!]!
  ) {
    AddSwConfigPageInfo(
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdateSwConfigPageInfo = gql`
  mutation UpdateSwConfigPageInfo(
    $token: String!
    $input: [UpdatePageInfo!]!
    $user_details: SubscribedUser!
  ) {
    UpdateSwConfigPageInfo(
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;

export const DeleteSwConfigPageInfo = gql`
  mutation DeleteSwConfigPageInfo(
    $token: String!
    $ids: [ID!]!
    $user_details: SubscribedUser!
  ) {
    DeleteSwConfigPageInfo(
      token: $token
      ids: $ids
      user_details: $user_details
    )
  }
`;
export const AddSwConfigVariablesPageAssoci = gql`
  mutation AddSwConfigVariablesPageAssoci(
    $token: String!
    $user_details: SubscribedUser!
    $input: [CreateSwConfigVariablesUsedInPagesInput!]!
  ) {
    AddSwConfigVariablesPageAssoci(
      token: $token
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdateSwConfigVariables = gql`
  mutation UpdateSwConfigVariables(
    $token: String!
    $input: [UpdateSwConfigVariables!]!
    $user_details: SubscribedUser!
  ) {
    UpdateSwConfigVariables(
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdateSwEmpConfigVariables = gql`
  mutation UpdateSwEmpConfigVariables(
    $token: String!
    $inst_id: ID!
    $pr_emp_id: ID!
    $input: [UpdateSwEmpConfigVariables!]!
    $user_details: SubscribedUser!
  ) {
    UpdateSwEmpConfigVariables(
      token: $token
      input: $input
      user_details: $user_details
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
    )
  }
`;
//PayRoll EmpId Config
export const UpdatePayRollEmpIdConfig = gql`
  mutation UpdatePayRollEmpIdConfig(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollEmpIdInput!
  ) {
    UpdatePayRollEmpIdConfig(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const UpdateSwEduateConfigVariables = gql`
  mutation UpdateSwEduateConfigVariables(
    $token: String!
    $user_details: SubscribedUser!
    $input: [UpdateSwEduateConfigVariables!]!
  ) {
    UpdateSwEduateConfigVariables(
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;

export const UpdateInstTimeZoneAndBdayMsgTime = gql`
  mutation UpdateInstTimeZoneAndBdayMsgTime(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: TimeZoneAndBdMsgTime!
  ) {
    UpdateInstTimeZoneAndBdayMsgTime(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

import React, { useContext, useEffect, useState } from "react";

import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { ColumnVisibilityFor } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import Allocate from "../Allocate";
import AgentDetails from "../Components/AgentDetails";
import AgentStudentList from "./AgentStudentList";
import { AppContext } from "../../../../context/context";
import { SectionAllocationDrawer } from "../../../../styles/DrawerStyles";

const StudentAssociation = () => {
  const navigate = useNavigate();

  const { state } = useContext(AppContext);
  const [allocate, setAllocate] = useState(false);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_reg_no: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: true,
      std_mobile: false,
      is_login_created: false,
      std_roll_no: false,
      std_demand_ob: false,
      std_demand_amt: false,
      std_demand_concession: false,
      std_demand_refunds: false,
      std_deposit_ob: false,
      std_deposit_amt: false,
      std_deposit_total: false,
      std_deposit_adjusted: false,
      std_deposit_refunded: false,
      std_deposit_bal: false,
    });

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_AGENTS_ASSOCIATION
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_AGENTS_ASSOCIATION,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title> Agent & Student Associations</Title>
      <div className="agent-std-assoc">
        <AgentDetails />
        <AgentStudentList />
        <Button
          mode="allocate"
          onClick={() => setAllocate(!allocate)}
          disabled={state.agentId === 0}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
        <SectionAllocationDrawer
          anchor="right"
          open={allocate}
          onClose={() => setAllocate(!allocate)}
        >
          <Allocate setModal={setAllocate} />
        </SectionAllocationDrawer>
      </div>
    </>
  );
};

export default StudentAssociation;

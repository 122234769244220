import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { TextField } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Enter from "../../../../images/Enter.svg";
import Delete from "../../../../images/Delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import {
  AutoCloseButton,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../utils/constants";
import { msgType } from "../../../../utils/Form.types";
import { subjectOptions } from "../../../../utils/types";
import { RefsByTagName } from "../../../../utils/UtilFunctions";
import { AddAcdSubjectCategoryAndDetails } from "../../../../queries/customallocation/categories/mutations";
import { useMutation } from "@apollo/client";
import {
  AddAcdSubjectCategoryAndDetailsData,
  AddAcdSubjectCategoryAndDetailsVars,
} from "../../../../Types/Category/mutations";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import MessageModal from "../../../../pages/MessageModal";
import useGroups, { groupOptionsType } from "../customHooks/useGroups";
import { Keys } from "../../../../utils/Enum.keys";
import { GetAcdSubjectCategories } from "../../../../queries/customallocation/categories/queries";
import useCategoriesByNodeId from "../customHooks/useCategoriesByNodeId";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";

const { Academics_Table } = require("../../json/table.json");

interface props {
  pageType: PageFor;
  operation: Operation;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Add = ({ pageType, setModal }: props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();

  const { state, dispatch } = useContext(AppContext);

  const [subj_cat_name, setSubjCatName] = useState<string>("");
  const [selectedSubject, setSelectedSubject] = useState<subjectOptions | null>(
    null
  );
  const [selectedGrp, setSelectedGrp] = useState<groupOptionsType | null>(null);

  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const groupRef = useRef<HTMLSelectElement>(null);
  const grpInputRef = RefsByTagName(groupRef);
  const subRef = useRef<HTMLSelectElement>(null);
  const subInputRef = RefsByTagName(subRef);
  const catNameRef = useRef<HTMLInputElement>(null);

  const autoClearBtn = subRef.current
    ? (subRef.current.getElementsByClassName(
        AutoCloseButton
      )[0] as HTMLButtonElement)
    : null;

  const [items, setItems] = useState<subjectOptions[]>([]);

  const [AddSubjCatDetails] = useMutation<
    AddAcdSubjectCategoryAndDetailsData,
    AddAcdSubjectCategoryAndDetailsVars
  >(AddAcdSubjectCategoryAndDetails);

  const { data } = useCategoriesByNodeId();

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { user_details } = useLoggedInUserDetails();
  const { groupOptions } = useGroups(EMPTY_STRING, ROWS_PER_PAGE);
  const handleItems = () => {
    const foundItem = items.find(
      (item) => selectedSubject && item.value === selectedSubject.value
    );
    if (selectedSubject)
      if (foundItem) {
        alert("Subject already exists");
      } else {
        setItems((prevItems) => {
          return [...prevItems, selectedSubject];
        });
      }
    setSelectedSubject(null);
    if (autoClearBtn) autoClearBtn.click();
  };

  const handleDeleteItem = (id: number) => {
    setItems(items.filter((item) => item.value !== id));
  };

  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_ACD_CAT_ID,
      payload: { acdCatId: 0 },
    });
    setSubjCatName("");
    setSelectedGrp(null);
    setSelectedSubject(null);
    setItems([]);
    catNameRef.current && catNameRef.current.focus();
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage(emptyMessageType);
  };

  const itemsSet = new Set(items.map((item) => item.value));
  const handleSubmit = () => {
    AddSubjCatDetails({
      variables: {
        token,
        subj_cat_name,
        inst_id: InstId!,
        user_details,
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        input: items.map((item, index) => ({
          idx: index + 1,
          subj_master_id: item.value,
        })),
      },
      refetchQueries: [
        {
          query: GetAcdSubjectCategories,
          variables: {
            token,
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: EMPTY_STRING,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.SUBJ_CAT_NAME,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.AddAcdSubjectCategoryAndDetails) {
        setMessage({
          flag: true,
          message: `${subj_cat_name} ${
            pageType === PageFor.MODAL ? "updtaed" : "created"
          } successfully`,
          operation: Operation.CREATE,
        });
      }
    });
  };
  useEffect(() => {
    if (state.acdCatId && pageType === PageFor.MODAL && data) {
      setSubjCatName(data.node.subj_cat_name);
      setItems(
        data.node.subj_cat_details.map((item) => ({
          label: item.subject_details.subj_desc,
          value: item.subject_details.id,
          subj_code: item.subject_details.subj_code,
        }))
      );
    }
  }, [state.acdCatId, pageType, data]);
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {pageType === PageFor.GENERAL
          ? "Associate Subjects to Category"
          : "Edit Associated Group with Category"}
      </Title>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "ass-catg__add"
            : "ass-catg__add--modal"
        }
      >
        <div className="ass-catg__add--row row g-0">
          <div className="col-5 ass-catg__add--block">
            <div className="label-grid">
              <Label>Enter Category Name</Label>
              {pageType === PageFor.GENERAL ? (
                <Input
                  value={subj_cat_name}
                  onChange={(e) => setSubjCatName(e.target.value)}
                  required
                  autoFocus
                  inputRef={catNameRef}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      grpInputRef.focus();
                    }
                  }}
                  error={subj_cat_name ? "" : "Category is required"}
                />
              ) : (
                <Input disabled />
              )}
              <Label>Filter Group Name</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={groupOptions}
                openOnFocus
                forcePopupIcon
                ref={groupRef}
                value={selectedGrp}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedGrp(newValue as groupOptionsType);
                  } else {
                    setSelectedGrp(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    subInputRef.focus();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="ass-catg__add--tableblock">
          <TableContainer className="ass-catg__add--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.CustomAllocation.AssociateSub.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="ass-catg__add--table--row">
                  <TableCell
                    className="ass-catg__add--table--slno"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="ass-catg__add--table--select">
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      ref={subRef}
                      value={selectedSubject}
                      options={
                        selectedGrp
                          ? selectedGrp.subjects.filter(
                              (item) => itemsSet.has(item.value) === false
                            )
                          : []
                      }
                      openOnFocus
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSelectedSubject(newValue as subjectOptions);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          handleItems();
                          subInputRef.click();
                        }
                      }}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className="ass-catg__add--table--subcode">
                    {selectedSubject ? selectedSubject.subj_code : EMPTY_STRING}
                  </TableCell>
                  <TableCell
                    id="td-center"
                    className="ass-catg__add--table--actions"
                  >
                    <img src={Enter} alt="" onClick={handleItems} />
                  </TableCell>
                </TableRow>
                {items.map((res, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="ass-catg__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{res.label}</TableCell>
                      <TableCell className="ass-catg__table--subcode">
                        {res.subj_code}
                      </TableCell>
                      <TableCell
                        className="ass-catg__table--actions"
                        id="td-center"
                      >
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => handleDeleteItem(res.value)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button mode="clear" onClick={handleClear} />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button
            mode="cancel"
            onClick={() => {
              dispatch({
                type: payloadTypes.SET_ACD_CAT_ID,
                payload: { acdCatId: 0 },
              });
              setModal(false);
            }}
          />
        )}
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Add;

import React, { useContext, useEffect, useState } from "react";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import useStudentTestMarks, {
  AcdStdMarksQueryType,
} from "../../../hooks/useStudentTestMarks";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import {
  Direction,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import useTestDetails from "../../../hooks/useTestDetails";
import {
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../../styles/ModalStyles";
import { responseType } from "../../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../../hooks/useTestStatus";
import Modal from "react-modal";
import Close from "../../../../../images/Close.svg";
import { payloadTypes } from "../../../../../context/reducer";
import { AppContext } from "../../../../../context/context";
import ParticularStudentResults from "../../Test/Modal/ParticularStudentResults";
import { Button } from "../../../../../stories/Button/Button";
import ProgressReport from "../../Progress/Print";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import { GetAcdComboSubjectOrderWithMarksDetails } from "../../../../../queries/customallocation/combination/queries";
import {
  GetAcdComboSubjectOrderWithMarksDetailsData,
  GetAcdComboSubjectOrderWithMarksDetailsVars,
} from "../../../../../Types/Combinations/queries";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import { GetStdTestMarksBySubjId } from "../../../queries/test/query";
import {
  GetStdTestMarksBySubjIdData,
  GetStdTestMarksBySubjIdEdges,
  GetStdTestMarksBySubjIdVars,
} from "../../../DailyActivities/Exam/UpdateMarks";
import useAcdLevel from "../../../../Accounts/hooks/useAcdLevel";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";

export type GridAlignment = "left" | "center" | "right";
interface Props {
  testConductId: responseType | null;
  pageType: PageFor;
  selectedCombination: responseType | null;
  forCombination?: boolean;
}

const ViewParticularTestBySubjectReportTable = ({
  testConductId,
  pageType,
  selectedCombination,
  forCombination,
}: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const [selectedStudentDetails, setSelectedStudentDetails] =
    useState<any>(null);
  const { testId, InstId, entryId, subjectId } = useParams();
  const navigate = useNavigate();
  const [printPreview, setPrintPreview] = useState(false);
  const [testConductDetailsId, setTestConductDetailsId] =
    useState<responseType | null>(null);

  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<GetStdTestMarksBySubjIdEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [selectAll, setSelectAll] = useState(false);

  const [studentIdSet, setStudentIdSet] = useState<Set<number>>(new Set());
  const { acdLevel } = useAcdLevel(entryId!);
  const { flag } = useCheckAllocationType();
  const [
    GetStdTestMarks,
    { data: studentsData, loading: studentsLoading, fetchMore },
  ] = useLazyQuery<GetStdTestMarksBySubjIdData, GetStdTestMarksBySubjIdVars>(
    GetStdTestMarksBySubjId,
    {
      variables: {
        token,
        acd_test_class_id: testId!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        after: null,
        entry_id: entryId!,
        entry_level: acdLevel,
        first: ROWS_PER_PAGE,
        inst_id: InstId!,
        subj_master_id: subjectId!,
        name: EMPTY_STRING,
        per_std_subj_allocation: flag!,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.STUDENT_ID,
        },
      },
    }
  );

  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.ALL_TESTS,
    Number(entryId)
  );
  const {
    testMarks: { loading },
  } = useStudentTestMarks(
    forCombination
      ? AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID
      : AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
    null,
    EMPTY_STRING
  );

  const { testConductDetails } = useTestDetails();

  const [GetAcdSubjects, { data: comboSubjectsList }] = useLazyQuery<
    GetAcdComboSubjectOrderWithMarksDetailsData,
    GetAcdComboSubjectOrderWithMarksDetailsVars
  >(GetAcdComboSubjectOrderWithMarksDetails, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_id: testId!,
      acd_subj_combo_id: selectedCombination ? selectedCombination.value : 0,
    },
  });

  //Headers

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "std_adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-onClick td-name",
      flex: 1,
    },
    {
      field: `std_marks`,
      headerName: `${
        studentsData && studentsData.GetStdTestMarksBySubjId.edges.length
          ? studentsData.GetStdTestMarksBySubjId.edges[0].node.subject_details
              .subj_desc
          : ""
      }`,
      headerAlign: HEADER_TEXT_ALIGN as GridAlignment,
      align: MARKS_TEXT_ALIGN as GridAlignment,
      cellClassName: "td-marks",
      renderCell: (params: any) => {
        return (
          <div
            className={params.row[`std_marks`] === "-" ? `bg-grey` : ""}
            style={{ width: "100%" }}
          >
            {params.row[`std_marks`]}
          </div>
        );
      },
    },
  ];

  // To view Particular Student Details
  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "std_name") {
      const selectedRow = rows.find((row) => row.id === params.id);

      if (selectedRow) {
        const stdId = selectedRow.std_id;

        setSelectedStudentDetails(selectedRow);
        dispatch({
          type: payloadTypes.SET_STUDENT_ID,
          payload: {
            studentId: stdId!,
          },
        });
      }
    }
  };

  //   const tableDataForExcelAndPdf = studentsData
  //     ? studentsData.GetStdTestMarksBySubjId.edges.map(
  //         (item: AcdTestMarksIndividual, index) => getMarksRowData(item, index)
  //       )
  //     : [];

  //   const downloadExcel = () => {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("Academics Test Reports");

  //     const dataRows = tableDataForExcelAndPdf;

  //     worksheet.views = FROZEN_CELLS;
  //     const headerStyle = getHeaderRowStyle();
  //     worksheet.getRow(1).height = 31;
  //     worksheet.getRow(2).height = 20;
  //     worksheet.getRow(3).height = 20;
  //     worksheet.getRow(4).height = 22;
  //     worksheet.getColumn(1).width = 6;
  //     worksheet.getColumn(2).width = 20;
  //     worksheet.getColumn(3).width = 20;
  //     worksheet.getColumn(4).width = 35;
  //     worksheet.getColumn(5).width = 25;
  //     worksheet.getColumn(6).width = 25;
  //     worksheet.getColumn(7).width = 25;
  //     worksheet.getColumn(8).width = 25;
  //     worksheet.getColumn(9).width = 25;
  //     worksheet.getColumn(10).width = 25;
  //     worksheet.getColumn(11).width = 25;
  //     worksheet.getColumn(12).width = 25;
  //     worksheet.getColumn(13).width = 25;
  //     worksheet.getColumn(14).width = 25;
  //     worksheet.getColumn(15).width = 25;

  //     const getBase64 = (file: any, cb: (a: string) => void) => {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => {
  //         cb(reader.result?.toString()!);
  //       };
  //     };
  //     fetch(Eduate)
  //       .then((response) => {
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         getBase64(blob, (result) => {
  //           const imageV = workbook.addImage({
  //             base64: result,
  //             extension: EDUATE_IMG_FORMAT,
  //           });

  //           worksheet.addImage(imageV, "H1:H3");

  //           workbook.xlsx.writeBuffer().then(() => {
  //             fetch(LogoOrSign.defaultLogo)
  //               .then((response) => {
  //                 return response.blob();
  //               })
  //               .then((blob) => {
  //                 getBase64(blob, (result) => {
  //                   const imageB = workbook.addImage({
  //                     base64: result,
  //                     extension: EDUATE_IMG_FORMAT,
  //                   });

  //                   worksheet.addImage(imageB, "B1:B3");
  //                   worksheet.mergeCells(1, 1, 1, 8);

  //                   const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
  //                   mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
  //                   mergedCell.fill = HEADER_CSS;
  //                   mergedCell.font = headerStyle[0].font;
  //                   mergedCell.alignment = HEADER_ALIGNMENT;

  //                   const mergedAddress: Cell = worksheet.getCell(A2_CELL);
  //                   mergedAddress.value =
  //                     InstDetails.data?.nodes[0]?.inst_address;
  //                   mergedAddress.fill = HEADER_CSS;
  //                   mergedAddress.font = ADDRESS_FONT;
  //                   mergedAddress.alignment = HEADER_ALIGNMENT;
  //                   worksheet.mergeCells("A2:G2");

  //                   const mergedPlace: Cell = worksheet.getCell(A3_CELL);
  //                   mergedPlace.value =
  //                     InstDetails.data?.nodes[0]?.inst_place +
  //                     "-" +
  //                     InstDetails.data?.nodes[0]?.inst_pin;

  //                   mergedPlace.fill = HEADER_CSS;
  //                   mergedPlace.font = ADDRESS_FONT;
  //                   mergedPlace.alignment = ADDRESS_ALIGNMENT;
  //                   worksheet.mergeCells("A3:H3");

  //                   const mergedHeader: Cell = worksheet.getCell(C4_CELL);
  //                   mergedHeader.value = "Academics Test/Exam Reports";
  //                   mergedHeader.fill = FILE_NAME_CSS;
  //                   mergedHeader.font = FILE_NAME_FONT;
  //                   mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
  //                   worksheet.mergeCells("C4:E4");
  //                   const mergedDate: Cell = worksheet.getCell(F4_CELL);
  //                   mergedDate.value =
  //                     ExcelPageHeader.DATE + toStandardDate(serverDate);
  //                   mergedDate.fill = FILE_NAME_CSS;
  //                   mergedDate.font = FIN_YEAR_FONT;
  //                   mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
  //                   worksheet.mergeCells("F4:H4");
  //                   const mergedYear: Cell = worksheet.getCell(A4_CELL);
  //                   mergedYear.value = state.ActiveFinYr
  //                     ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
  //                     : ExcelPageHeader.YEAR;
  //                   mergedYear.fill = FILE_NAME_CSS;
  //                   mergedYear.font = FIN_YEAR_FONT;
  //                   mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
  //                   worksheet.mergeCells("A4:B4");

  //                   let Char = FIRST_INDEX;

  //                   for (let i = 0; i < StudentHeaders.length; i++) {
  //                     Char = String.fromCharCode(Char.charCodeAt(0) + 1);

  //                     const rowData: Cell = worksheet.getCell(Char + 5);
  //                     rowData.value = StudentHeaders[i];
  //                     rowData.fill = TABLE_HEADER_CSS;
  //                     rowData.border = BORDER_DATA;
  //                     rowData.font = ACC_HEADER_FONT;
  //                     rowData.alignment = { horizontal: ExcelAlignment.CENTER };
  //                   }

  //                   dataRows!.forEach((rowData) => {
  //                     const row = worksheet.addRow(rowData);
  //                     row.eachCell({ includeEmpty: true }, (cell) => {
  //                       cell.alignment = { horizontal: ExcelAlignment.LEFT };
  //                     });
  //                   });

  //                   workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
  //                     const blob = new Blob([buffer], {
  //                       type: BLOB_TYPE,
  //                     });
  //                     const url = window.URL.createObjectURL(blob);
  //                     const link = document.createElement(ELEMENT);
  //                     link.href = url;
  //                     link.setAttribute(
  //                       DOWNLOAD,
  //                       InstDetails.data?.nodes[0]?.inst_name!
  //                     );
  //                     document.body.appendChild(link);
  //                     link.click();
  //                   });
  //                 });
  //               });
  //           });
  //         });
  //       });
  //   };
  //   const downloadPdf = () => {
  //     const getBase64 = (file: any, cb: (a: string) => void) => {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => {
  //         cb(reader.result?.toString()!);
  //       };
  //     };
  //     fetch(LogoOrSign.defaultLogo)
  //       .then((response) => {
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         getBase64(blob, (result) => {
  //           fetch(Eduate)
  //             .then((response) => {
  //               return response.blob();
  //             })
  //             .then((blob2) => {
  //               getBase64(blob2, (result2) => {
  //                 const doc = new jsPDF("landscape", "mm", "a4");
  //                 doc.setFont("Helvetica", "bold");
  //                 let i = 0;
  //                 var totalPages = doc.getNumberOfPages();
  //                 for (i = 1; i <= totalPages; i++) {
  //                   doc.setFontSize(18);
  //                   const startY = 10 + (i - 1) * 20;
  //                   doc.setFillColor(240, 240, 240);
  //                   doc.rect(
  //                     0,
  //                     0,
  //                     doc.internal.pageSize.getWidth(),
  //                     doc.internal.pageSize.getHeight(),
  //                     "F"
  //                   );

  //                   doc.setTextColor(0, 0, 0);
  //                   doc.text(
  //                     `${InstDetails?.data?.nodes[0].inst_name}`,
  //                     80,
  //                     startY
  //                   );

  //                   doc.setFont("Times New Roman", "normal");
  //                   doc.setFontSize(13);
  //                   doc.text(
  //                     `${InstDetails?.data?.nodes[0]?.inst_address}`,
  //                     120,
  //                     startY + 7
  //                   );

  //                   doc.setFont("Times New Roman", "normal");
  //                   doc.setFontSize(13);
  //                   doc.text(
  //                     `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
  //                     120,
  //                     startY + 12
  //                   );
  //                   doc.setFontSize(13);
  //                   doc.text(
  //                     `Fin-year -${
  //                       state.ActiveFinYr
  //                         ? state.ActiveFinYr.fin_yr
  //                         : EMPTY_STRING
  //                     }`,
  //                     45,
  //                     startY + 21
  //                   );
  //                   doc.setFontSize(14);
  //                   doc.setFont("Times New Roman", "normal");
  //                   doc.text(`Academics Test/Exam Reports`, 120, startY + 21);
  //                   doc.setFontSize(13);
  //                   doc.text(
  //                     `As-on-date:${toStandardDate(serverDate)}`,
  //                     230,
  //                     startY + 21
  //                   );
  //                   doc.addImage(result, "JPEG", 15, 5, 22, 22);
  //                   if (configLogo) {
  //                     doc.addImage(result2, "JPEG", 250, 5, 20, 20);
  //                   }
  //                 }

  //                 autoTable(doc, {
  //                   startY: 33,
  //                   bodyStyles: { valign: "top" },
  //                   theme: "striped",
  //                   columns: StudentHeadersForPdf.map((col) => ({
  //                     ...col,
  //                     dataKey: col.field,
  //                     styles: { fontSize: 18 },
  //                   })),

  //                   body: tableDataForExcelAndPdf,
  //                   showFoot: "lastPage",
  //                   showHead: "everyPage",
  //                   useCss: true,
  //                   didDrawPage: function (data) {
  //                     // Footer
  //                     let str =
  //                       "" +
  //                       doc.getCurrentPageInfo().pageNumber +
  //                       "of" +
  //                       doc.getNumberOfPages();
  //                     doc.setFontSize(10);

  //                     // jsPDF 1.4+ uses getWidth, <1.4 uses .width
  //                     let pageSize = doc.internal.pageSize;
  //                     let pageHeight = pageSize.height
  //                       ? pageSize.height
  //                       : pageSize.getHeight();
  //                     doc.text(str, data.settings.margin.left, pageHeight - 10);
  //                   },
  //                 });

  //                 doc.save(
  //                   `${InstDetails?.data?.nodes[0]?.inst_name} ${
  //                     state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
  //                   } `
  //                 );
  //               });
  //             });
  //         });
  //       });
  //   };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allStudentIds = rows
        .map((student) => student.std_id)
        .filter((id) => Number.isInteger(id)) as number[];
      setSelectedStudents(allStudentIds);
      setSelectAll(true);
    } else {
      setSelectedStudents([]);
      setSelectAll(false);
    }
  };
  useEffect(() => {
    if (testId && testConductDetailsId === null) {
      setTestConductDetailsId(
        statusOfSubject.classTests.find(
          ({ value }) => value === Number(testId)
        ) ?? null
      );
    }
  }, [testId, statusOfSubject.classTests]);
  useEffect(() => {
    if (token && flag !== undefined) {
      GetStdTestMarks();
    }
  }, [
    GetStdTestMarks,
    token,
    testId,
    state.ActiveAcdYr,
    entryId,
    acdLevel,
    InstId,
    subjectId,
    flag,
  ]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStdTestMarksBySubjId.edges;
                const pageInfo =
                  fetchMoreResult.GetStdTestMarksBySubjId.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetStdTestMarksBySubjId.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetStdTestMarksBySubjId: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: studentsData
                      ? studentsData.GetStdTestMarksBySubjId.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (studentsData && !studentsLoading) {
      const newData = studentsData.GetStdTestMarksBySubjId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.stdId && row.stdId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            std_marks: node.marks_scored_tot,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            std_marks: node.marks_scored_tot,
          }))
        );
      }
      setEndCursor(studentsData.GetStdTestMarksBySubjId?.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    studentsData,
    loading,
    testConductDetails.data,
    forCombination,
    comboSubjectsList,
  ]);
  useEffect(() => {
    if (token) {
      GetAcdSubjects();
    }
  }, [InstId, selectedCombination, state.ActiveAcdYr, token, GetAcdSubjects]);
  return (
    <>
      <div className={`acd-reports__per-test--tableblock`}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          onCellClick={(params: GridCellParams, studentId) => {
            handleCellClick(params);
          }}
          onColumnHeaderClick={() => handleSelectAll()}
          disableRowSelectionOnClick
        />
      </div>
      {/* <Button
        mode="print"
        disabled={selectedStudents.length === 0}
        onClick={() => {
          setPrintPreview(!printPreview);
        }}
      /> */}
      {/* <Button mode="excel" type="button" onClick={downloadExcel}>
        Export
      </Button>
      <Button mode="pdf" type="button" onClick={downloadPdf} /> */}
      {pageType === PageFor.GENERAL ? (
        <Button mode="back" onClick={() => navigate(-1)} />
      ) : // <Button mode="cancel" onClick={() => setModal?.(false)} />
      null}

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={selectedStudentDetails}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ParticularStudentResults
              setModalFlag={setSelectedStudentDetails}
              testConductId={testConductId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setSelectedStudentDetails(!selectedStudentDetails)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printPreview}
        style={PrintModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ProgressReport
              testConductId={testConductId}
              setModalFlag={setPrintPreview}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setPrintPreview(!printPreview);
                setStudentIdSet(new Set());
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewParticularTestBySubjectReportTable;

import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserDetails from "./UserDetails";

import EduateUser from "../../user/Index";
import { Operation } from "../../../../utils/Enum.types";
import { a11yProps, TabPanel } from "../../../../styles/Tabs";

const GlobalConfigFiles = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        variant="fullWidth"
        textColor="secondary"
      >
        <Tab label="Software User" {...a11yProps(0)} />
        <Tab label="User Details" {...a11yProps(1)} />
        <Tab label="Auth Eduate" {...a11yProps(2)} />

        <Tab label="Eduate List" {...a11yProps(5)} />
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          Software user details
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <UserDetails />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <EduateUser type={Operation.CREATE} />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

export default GlobalConfigFiles;

import React from "react";
import { LabelNameProps } from "../../../../Types/Labels";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
const { VMS_Form } = require("../../../json/form.json");
const BasicDetails = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="vms-staff-details-preview__data">
        <div className="vms-staff-details-preview__data--list">
          {VMS_Form.Staff_Details.Preview.BasicDetails.map(
            (label: LabelNameProps, index: React.Key) => {
              return (
                <div className="label-grid" key={index}>
                  <Label>{label.LabelName}</Label>
                  <Input
                    type={label.dataType}
                    value={label.inputName}
                    disabled
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
      <Button
        mode="edit"
        onClick={() => navigate(`/vms/staffdetails/update`)}
      />
      <Button mode="back" onClick={() => navigate(-1)} />
    </>
  );
};

export default BasicDetails;

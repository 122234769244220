import React, { useContext, useEffect } from "react";
import { TEXT_FONT_FAMILY } from "../../../../../../styles/DataGridTableStyles";
import ReactEcharts from "echarts-for-react";
import { colorsInGraph } from "../../../../../../styles/Constants";
import { responseType } from "../../../../../../utils/Form.types";
import { useParams } from "react-router-dom";
import { PercentageGraphScale } from "../../../../../../utils/UtilFunctions";
import { InstIdVars } from "../../../../../HR/Types/masterDataTypes";
import { GetAcdTestSubjectPerformance } from "../../../../queries/holidays/list";
import useToken from "../../../../../../customhooks/useToken";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../../../../context/context";

interface GetAcdTestSubjectPerformanceList {
  id: number;
  num_of_std_test_taken: number;
  num_of_std_passed: number;
  num_of_std_failed: number;
  num_of_std_mt_80_per: number;
  num_of_std_mt_70_per: number;
  num_of_std_mt_60_per: number;
  num_of_std_mt_50_per: number;
  num_of_std_mt_40_per: number;
  num_of_std_lt_40_per: number;
}

interface GetAcdTestSubjectPerformanceData {
  GetAcdTestSubjectPerformance: GetAcdTestSubjectPerformanceList;
}
interface GetAcdTestSubjectPerformanceVars extends InstIdVars {
  acd_yr_id: number;
  acd_test_class_id: number;
  subj_master_id: number;
}

interface props {
  selectedSubject: responseType | null;
}

const SubjectwisePercentageGraph = ({ selectedSubject }: props) => {
  const { testId, InstId, testClassId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [GetPerformance, { data }] = useLazyQuery<
    GetAcdTestSubjectPerformanceData,
    GetAcdTestSubjectPerformanceVars
  >(GetAcdTestSubjectPerformance, {
    variables: {
      token,
      inst_id: InstId ? InstId : state.InstId,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_id: Number(testClassId) || Number(testId),
      subj_master_id: selectedSubject ? selectedSubject.value : 0,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token && InstId && testClassId && testId) {
      GetPerformance();
    }
  }, [token, state.InstId, GetPerformance, InstId, testClassId, testId]);

  const Options = {
    tooltip: {
      trigger: "item",
      formatter: '{a} <br/>{b} : {c} ({d}%)',
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: false, readOnly: false },
        restore: { show: false },
        saveAsImage: { show: true },
      },
    },

    legend: {
      show: false,
      
    },
  
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        data: [
          {
            value: data?.GetAcdTestSubjectPerformance.num_of_std_mt_80_per,
            name:` >=80% (totalcount : ${data?.GetAcdTestSubjectPerformance.num_of_std_mt_80_per})`
          },

          {
            value: data?.GetAcdTestSubjectPerformance.num_of_std_mt_70_per,
            name: `70-79% (totalcount : ${data?.GetAcdTestSubjectPerformance.num_of_std_mt_70_per})`,
          },
          {
            value: data?.GetAcdTestSubjectPerformance.num_of_std_mt_60_per,
            name: `60-69% (totalcount : ${data?.GetAcdTestSubjectPerformance.num_of_std_mt_60_per})`,
          },
          {
            value: data?.GetAcdTestSubjectPerformance.num_of_std_mt_50_per,
            name: `50-59% (totalcount : ${data?.GetAcdTestSubjectPerformance.num_of_std_mt_50_per})`,
          },
          {
            value: data?.GetAcdTestSubjectPerformance.num_of_std_mt_40_per,
            name: `40-49% (totalcount : ${data?.GetAcdTestSubjectPerformance.num_of_std_mt_40_per})`,
          },
          {
            value: data?.GetAcdTestSubjectPerformance.num_of_std_lt_40_per,
            name:`<40% (totalcount : ${data?.GetAcdTestSubjectPerformance.num_of_std_lt_40_per})`,
          },
        ],
        type: "pie",
        name: "Nightingale Chart",
        radius: ["20%", "80%"],
        center: ["50%", "50%"],
        roseType: "area",

        itemStyle: {
          color: function (params: any) {
            return colorsInGraph[params.dataIndex];
          },
          borderRadius: 8,
        },
      },
    ],
  };

  return (
    <>
      <ReactEcharts option={Options} />
      {/* <Button mode="back" onClick={()=>navigate(-1)}/> */}
    </>
  );
};

export default SubjectwisePercentageGraph;

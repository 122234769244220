import { gql } from "@apollo/client";

export const GetLibBookCounters = gql`
  query GetLibBookCounters($token: String!, $inst_id: ID!, $acd_yr_id: ID!) {
    GetLibBookCounters(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
    ) {
      total_lib_book_count
      total_lib_books_issued_today_count
      total_lib_books_returned_today_count
      total_lib_fine_collected
      total_lib_books_overdue
      total_lib_books_damaged
      total_lib_books_lost
      total_lib_books_in_shelf
      total_lib_books_not_in_shelf
      total_categories
total_subjects
total_Languages
total_media
    }
  }
`;
export const GetLibBookMediaCounters = gql`
  query GetLibBookMediaCounters($token: String!, $inst_id: ID!) {
    GetLibBookMediaCounters(token: $token, inst_id: $inst_id) {
      media_desc
      media_abbrevation
      media_is_active
      lib_books_count
    }
  }
`;

import { useLazyQuery, useMutation } from "@apollo/client";
import Modal from "react-modal";
import { useContext, useEffect, useState } from "react";
import { GetParents, ParentByNodeId } from "../../../queries/parent/list";
import {
  Direction,
  Operation,
  PageFor,
  PageLabel,
  ParentQueryType,
  StudentListFor,
  StudentReportType,
  TableHeaders,
} from "../../../utils/Enum.types";

import { msgType, responseType } from "../../../utils/Form.types";
import { AppContext } from "../../../context/context";

import StudentList from "../Student/List";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import { studentAcademicYearFormDetails } from "../../../Types/Accounting";
import { Button } from "../../../stories/Button/Button";
import { ListParentStudentAssocByParentId } from "../../../queries/parent/list/byId";
import { Form, Formik } from "formik";
import { parentStudentSchema } from "../../../utils/validationRules";
import {
  AddParentStudentAssociation,
  UpdateParentStudentAssoci,
} from "../../../queries/parent/mutations/new";
import {
  parentStudentAssociationByParentIdData,
  parentStudentAssociationByParentIdVars,
} from "../../../Types/Student";
import {
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import LoadingModal from "../../../pages/LoadingModal";
import Close from "../../../images/Close.svg";
import Delete from "../../../images/Delete.svg";
import OpenIcon from "../../../images/OpenIcon.svg";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { GetStudentDetails } from "../../../queries/common";
import {
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../../../Types/Student";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import MessageModal from "../../../pages/MessageModal";
import Home from "../Home/Index";
import ParentDetails from "./parentPreview/parentDetails";
import { Title } from "../../../stories/Title/Title";
import DownArrow from "../../../images/DownArrow.svg";
import useStudentAdmissionNumber from "../../Accounts/hooks/UseStudentAdmissionNumber";
import { payloadTypes } from "../../../context/reducer";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import _ from "lodash";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
interface Props {
  type: Operation;
  step: number;
}
export interface StudentDetails {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  std_reg_no: string;
  std_adm_no: string;
  std_father_name: string;
  std_mother_name: string;
  class: ClassList;
  branch: BranchesList;
  dept: DepartmentList;
  semester: SemesterList;
  section: SectionList;
  category: CategoryList;
}
export interface StudentData {
  nodes: StudentDetails[];
}
export interface StudentDetailsvars {
  token: string;
  ids: number[] | number;
}
const stepHeader = () => {
  return ["Parent Details", "Parent Student Association "];
};
const ParentStudentAssociation = ({ step, type }: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId, parentId } = useParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [admNo, setAdmNo] = useState("");
  //usestate for modal
  const [studentModal, setStudentModal] = useState(false);
  const stepsHeader = stepHeader();

  // eslint-disable-next-line
  const [studentDetails, setStudentDetails] =
    useState<studentAcademicYearFormDetails>({
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    });
  const [studentList, setStudentList] = useState<
    studentAcademicYearFormDetails[]
  >([
    {
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    },
  ]);

  const [ParentStudentList, setParentStudentList] = useState<
    studentAcademicYearFormDetails[]
  >([
    {
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    },
  ]);
  const { user_details } = useLoggedInUserDetails();
  const [GetParentsByNode, { data }] = useLazyQuery(ParentByNodeId, {
    variables: { token, id: parentId },
  });

  const [
    GetAssociationData,
    {
      data: ParentStudentAssociationData,
      loading: ParentStudentAssociationLoading,
    },
  ] = useLazyQuery<
    parentStudentAssociationByParentIdData,
    parentStudentAssociationByParentIdVars
  >(ListParentStudentAssocByParentId, {
    variables: {
      token,
      parent_id: parentId!,
    },
  });

  const { studentAddmissionNumber } = useStudentAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL,
    0
  );
  const [AddParentStudentAssoci, { loading: creationLoading }] = useMutation(
    AddParentStudentAssociation,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateParentStudentAssoci, { loading: updationLoading }] = useMutation(
    UpdateParentStudentAssoci,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetStudentsAcademicDetails] = useLazyQuery<
    StudentData,
    StudentDetailsvars
  >(GetStudentDetails);
  const { studentData } = useStudentDatabyId();

  useEffect(() => {
    if (state.studentId) {
      // eslint-disable-next-line
      studentData?.data?.nodes.map((data) => {
        if (
          !studentList.find(
            (response: studentAcademicYearFormDetails) =>
              response.std_id === state.studentId
          )
        ) {
          setStudentList((prevValues) => [
            {
              std_id: state.studentId,
              std_adm_no: data.std_adm_no,
              first_name: data.first_name,
              middle_name: data.middle_name,
              last_name: data.last_name,
              std_reg_no: data.std_reg_no,
              std_father_name: data.std_father_name,
              branch_desc: data.branch.branch_desc,
              class_desc: data.class.class_desc,
              dept_desc: data.dept.dept_desc,
              cat_desc: data.category.cat_desc,
            },
            ...prevValues,
          ]);
        }
      });
      //This State should always stay since the useEffect executes more than one time when student is selected and state is change from true to false making modal misbehave
      setStudentModal(false);
    } // eslint-disable-next-line
  }, [studentData]);

  useEffect(() => {
    //UseEffect for update only to display added student list
    if (
      ParentStudentAssociationData &&
      !ParentStudentAssociationLoading &&
      type === Operation.UPDATE
    ) {
      const studentIds =
        ParentStudentAssociationData.GetParentStudentAssociByParentId?.map(
          (response: any) => response.student_id
        );
      //Listing Students from parentStudentAssociation Data
      GetStudentsAcademicDetails({
        variables: {
          token,
          ids: studentIds,
        },
      })
        .then(({ data }) => {
          data?.nodes.map(
            (
              studentData,
              index: number
              // eslint-disable-next-line
            ) => {
              setStudentList((prevValues) => [
                {
                  std_id: studentIds[index],
                  std_adm_no: studentData.std_adm_no,
                  first_name: studentData.first_name,
                  middle_name: studentData.middle_name,
                  last_name: studentData.last_name,
                  std_reg_no: studentData.std_reg_no,
                  std_father_name: studentData.std_father_name,
                  branch_desc: studentData.branch.branch_desc,
                  class_desc: studentData.class.class_desc,
                  dept_desc: studentData.dept.dept_desc,
                  cat_desc: studentData.category.cat_desc,
                },
                ...prevValues,
              ]);
              setParentStudentList((prevValues) => [
                {
                  std_id: studentIds[index],
                  std_adm_no: studentData.std_adm_no,
                  first_name: studentData.first_name,
                  middle_name: studentData.middle_name,
                  last_name: studentData.last_name,
                  std_reg_no: studentData.std_reg_no,
                  std_father_name: studentData.std_father_name,
                  branch_desc: studentData.branch.branch_desc,
                  class_desc: studentData.class.class_desc,
                  dept_desc: studentData.dept.dept_desc,
                  cat_desc: studentData.category.cat_desc,
                },
                ...prevValues,
              ]);
            }
          );
        })
        .catch(({ error }) => console.log(error));
      setStudentList([studentDetails]);
    }
    // eslint-disable-next-line
  }, [ParentStudentAssociationData, ParentStudentAssociationLoading]);
  const submitForm = () => {
    const filteredStudentIdArray = studentList.filter(
      (response: studentAcademicYearFormDetails) => response.std_id !== ""
    );
    const input = filteredStudentIdArray.map((student) => ({
      student_id: student.std_id,
      relationship: data && data?.node.parent_type,
    }));
    if (type === Operation.CREATE) {
      AddParentStudentAssoci({
        variables: {
          token,
          parent_id: parentId,
          inst_id: InstId!,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: ListParentStudentAssocByParentId,
            variables: {
              token,
              parent_id: parentId,
            },
          },
          {
            query: GetParents,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              inst_id: InstId!,
              queryType: ParentQueryType.PARENTS_BY_INST_ID,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Parent Added Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      updateParentStudentAssoci({
        variables: {
          token,
          parent_id: parentId,
          inst_id: InstId!,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: ListParentStudentAssocByParentId,
            variables: {
              token,
              parent_id: parentId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Parent Student Association Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };

  const removeStudentFromList = (index: number) => {
    studentList.splice(index, 1);
    setStudentList((prevStudents) => [...prevStudents]);
  };
  const handleClear = () => {
    setStudentList([]);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      navigate(`/${InstId}/admissions/parents`);
    }
    if (
      message.operation !== Operation.NONE &&
      message.flag &&
      type === Operation.UPDATE
    ) {
      navigate(`/${InstId}/admissions/parents/${parentId}/parentAssosiation`);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  document.onkeydown = (e) => {
    if (e.key === Keys.ENTER && document.activeElement?.tagName === "BODY") {
      setStudentModal(!studentModal);
    }
  };
  useEffect(() => {
    if (token) {
      GetAssociationData();
    }
  }, [token, GetAssociationData]);
  useEffect(() => {
    if (token && parentId) {
      GetParentsByNode();
    }
  }, [token, parentId, GetParentsByNode]);
  const { branchLabel, classLabel } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      {type === Operation.UPDATE && (
        <Title>Update Parent Student Association</Title>
      )}
      <div
        className={
          type === Operation.CREATE ? "parentReg" : "parentReg__update"
        }
      >
        {type === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        <ParentDetails />

        <Formik
          initialValues={studentDetails}
          validationSchema={parentStudentSchema}
          onSubmit={submitForm}
          validateOnChange={false}
          enableReinitialize
        >
          {(meta) => {
            return (
              <Form
                className={
                  type === Operation.UPDATE
                    ? "parent-std-association--update"
                    : "parent-std-association"
                }
              >
                <div className="row g-0 justify-content-center">
                  <div className="col-4">
                    <TextField
                      disabled
                      value={
                        data
                          ? data?.node?.first_name +
                            " " +
                            data?.node.middle_name +
                            " " +
                            data?.node.last_name
                          : EMPTY_STRING
                      }
                      label="Parent Name:"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className="parentReg__textfield"
                    />

                    <div className="parent-std-association__image-flex">
                      <LabeledAutocomplete
                        className={labelClasses.inputRoot}
                        options={studentAddmissionNumber! ?? [EMPTY_STRING]}
                        value={
                          studentAddmissionNumber?.find(({ label }) =>
                            studentData.data
                              ? label === studentData.data.nodes[0].std_adm_no
                              : label === admNo
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            dispatch({
                              type: payloadTypes.SET_STUDENT_ID,
                              payload: {
                                studentId: Number(
                                  (newValue as responseType)?.value
                                ),
                              },
                            });
                          } else {
                            dispatch({
                              type: payloadTypes.SET_STUDENT_ID,
                              payload: {
                                studentId: 0,
                              },
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            dispatch({
                              type: payloadTypes.SET_STUDENT_ID,
                              payload: {
                                studentId: 0,
                              },
                            });
                          }
                        }}
                        freeSolo
                        autoHighlight
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => setAdmNo(e.target.value)}
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                              },
                            }}
                            fullWidth
                            className={labelClasses.formControlRoot}
                            label="Add Student / Siblings AdmNo"
                          />
                        )}
                      />

                      <img
                        className="data-fetch-icon"
                        src={OpenIcon}
                        alt="Loading"
                        onClick={() => setStudentModal(!studentModal)}
                      />
                    </div>
                  </div>
                </div>

                <div className="parent-std-association__tableblock">
                  <TableContainer className="parent-std-association__table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell className="parent-std-association__table--slno">
                            {TableHeaders.SLNO}
                          </TableCell>
                          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
                          <TableCell className="parent-std-association__table--desc">
                            {branchLabel}
                          </TableCell>
                          <TableCell className="parent-std-association__table--desc">
                            {classLabel}
                          </TableCell>
                          <TableCell className="parent-std-association__table--actions">
                            {TableHeaders.ACTION}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentList
                          ?.filter(
                            (x: studentAcademicYearFormDetails) =>
                              x.first_name !== ""
                          )
                          ?.map(
                            (
                              response: studentAcademicYearFormDetails,
                              index: number
                            ) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell align="center">
                                    {index + 1}
                                  </TableCell>

                                  <TableCell>
                                    {response.first_name +
                                      " " +
                                      response.middle_name +
                                      " " +
                                      response.last_name}
                                  </TableCell>
                                  <TableCell>{response.branch_desc}</TableCell>
                                  <TableCell>{response.class_desc}</TableCell>
                                  <TableCell align="center">
                                    <img
                                      src={Delete}
                                      alt="/"
                                      onClick={() =>
                                        removeStudentFromList(index)
                                      }
                                      className="delete-icon"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {type === Operation.UPDATE ? (
                  <Button mode="save" type="submit" onClick={submitForm} />
                ) : _.isEqual(ParentStudentList, studentList) ? (
                  <>
                    <Button mode="clear" onClick={handleClear} type="button" />
                    <Button
                      mode="skip"
                      type="button"
                      onClick={() => {
                        setMessage({
                          message: "Parent Added Successfully",
                          flag: true,
                          operation: Operation.CREATE,
                        });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Button mode="finish" type="submit" />
                    <Button mode="clear" onClick={handleClear} type="button" />
                  </>
                )}

                <Button
                  mode="back"
                  type="button"
                  onClick={() =>
                    navigate(
                      `/${InstId}/admissions/parents/1/${parentId}/addparent`
                    )
                  }
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.GENERAL}
              queryType={StudentReportType.GENERAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ParentStudentAssociation;

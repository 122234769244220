import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";


import { UpdateStudentsAdmNum } from "../../../../queries/students/mutations/update";

import useToken from "../../../../customhooks/useToken";

import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../customhooks/useDropdownData";

import { msgType, responseType } from "../../../../utils/Form.types";
import { Operation, StudentReportType } from "../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import Input from "../../../../stories/Input/Input";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import StudentTotalCount from "../Components/StudentTotalCount";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useAcdStudentsForAdmission, {
  StudentAcdNode,
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const BulkEditAdmNumber = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [searchData, setSearchData] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [EditMode, setEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const { user_details } = useLoggedInUserDetails();
  const [UpdateStudentAdmNo, { loading: updationLoading }] = useMutation(
    UpdateStudentsAdmNum,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.ACD_STD_GENERAL,
    EMPTY_STRING,
    []
  );

  const [studentData, setStudentData] = useState<Map<number, string>>(
    new Map()
  );

  const valueSet = useMemo(() => {
    return new Set(Array.from(studentData.values()));
  }, [studentData]);

  const [admNumber, setAdmNumber] = useState("");
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
  };
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const onEdit = (item: StudentAcdNode) => {
    setEditMode({
      status: true,
      rowKey: item.id,
    });

    if (item.std_adm_no) {
      setAdmNumber(item.std_adm_no!);
    }
  };

  const HandleSubmit = () => {
    UpdateStudentAdmNo({
      variables: {
        token,
        std_adm_data: Array.from(studentData).map(([key, value]) => ({
          student_id: key,
          std_adm_no: value,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        // {
        //   query: GetStudents,
        //   variables: {
        //     after: null,
        //     fin_yr_id: ActiveFinancicalYear?.data?.GetFinYrActiveByInstId.id!,
        //     first: null,
        //     name: EMPTY_STRING,
        //     token,
        //     direction: Direction.ASC,
        //     sortBy: SortBy.FIRST_NAME,
        //     input: {
        //       std_query_type: studentQueryTypes.BY_INST_ID,
        //       ids: [Number(InstId)],
        //     },
        //   },
        // },
      ],
    }).then(({ data }) => {
      if (data && data.UpdateStudentsAdmNum) {
        setMessage({
          message: "Sucessfully Updated Admission Numbers Number",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const clearStudentData = () => {
    setStudentData(new Map());
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      clearStudentData();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData.loading) {
        AcademicsStudentData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };
  useEffect(
    () => {
      if (AcademicsStudentData.data && !AcademicsStudentData.loading) {
        const newData = AcademicsStudentData.data.GetAcdStudents.edges.map(
          (edge) => {
            return {
              ...edge,
              node: {
                ...edge.node,
                isChecked: true,
                std_adm_no: edge.node.std_adm_no
                  ? edge.node.std_adm_no.toString()
                  : EMPTY_STRING,
                // set default value of isChecked to true
              },
            };
          }
        );

        if (endCursor) {
          const updatedNewData = newData.map((newStudent) => {
            const filteredStudent = students.find(
              (student) => student.node.id === newStudent.node.id
            );
            if (filteredStudent) {
              return {
                ...newStudent,
                node: {
                  ...newStudent.node,
                  // isChecked: filteredStudent.node.isChecked,
                },
              };
            }
            return newStudent;
          });
          setStudents(updatedNewData);
        } else {
          setStudents(newData);
        }
        setEndCursor(
          AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor
        );
      }
    },
    // eslint-disable-next-line
    [
      AcademicsStudentData.data,
      AcademicsStudentData.loading,
      AcademicsStudentData.fetchMore,
    ]
  );

  const setRollNoAndIncrementNo = (index: number, id: number) => {
    if (valueSet.has(admNumber)) {
      setMessage({
        message: `Duplicate Admission Number Present `,
        flag: true,
        operation: Operation.NONE,
      });
    } else {
      const updatedStudentData = new Map(studentData);
      updatedStudentData.set(id, admNumber);
      setStudentData(updatedStudentData);
      onEdit(students[index + 1].node);
    }
  };

  return (
    <>
      <div className="std-edit-adm-no__bulk">
        <form className="row g-0 std-edit-adm-no__bulk--select">
          <div className="col-1 ">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option  as responseType, departmentSelected)
                }
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option  as responseType, branchSelected)
                }
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    id="outlined Branches"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option  as responseType, classSelected)
                }
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    if (!USE_SECTION_KEY && !USE_SEMESTER_KEY) {
                      setRowsPerPage(null);
                    }
                  } else {
                    setClassSelected(null);
                    if (!USE_SECTION_KEY && !USE_SEMESTER_KEY) {
                      setRowsPerPage(ROWS_PER_PAGE);
                    }
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    id="outlined Classes"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option  as responseType, semesterSelected)
                }
                options={semesterDropDown!}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    if (!USE_SECTION_KEY) {
                      setRowsPerPage(null);
                    }
                  } else {
                    setSemesterSelected(null);
                    if (!USE_SECTION_KEY) {
                      setRowsPerPage(ROWS_PER_PAGE);
                    }
                  }
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    id="outlined Semesters"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={sectionDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option  as responseType, sectionSelected)
                }
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setRowsPerPage(null);
                  } else {
                    setSectionSelected(null);
                    setRowsPerPage(ROWS_PER_PAGE);
                  }
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    id="outlined Sections"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}

          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className="allot-regno__options--button"
            />
          </div>
        </form>
        <div className="std-edit-adm-no__bulk--tableblock">
          {!students?.length ? (
            <b className="nodata">Sorry, No Students Found</b>
          ) : (
            <TableContainer
              className="std-edit-adm-no__bulk--table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Father Name</TableCell>

                    {USE_DEPARTMENT_KEY ? (
                      <TableCell>{departmentLabel}</TableCell>
                    ) : null}
                    {USE_BRANCH_KEY ? (
                      <TableCell>{branchLabel}</TableCell>
                    ) : null}
                    {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
                    <TableCell>Present AdmNumber</TableCell>

                    <TableCell>New AdmNumber</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students?.map(({ node }, index: number) => (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="std-edit-adm-no__bulk--table--slno"
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell>{`${node.first_name} ${node.middle_name} ${node.last_name}`}</TableCell>
                      <TableCell className="std-edit-adm-no__bulk--table--desc">
                        {node.std_father_name}
                      </TableCell>
                      {USE_DEPARTMENT_KEY ? (
                        <TableCell className="std-edit-adm-no__bulk--table--desc">
                          {node.acd_dept.dept_desc}
                        </TableCell>
                      ) : null}
                      {USE_BRANCH_KEY ? (
                        <TableCell className="std-edit-adm-no__bulk--table--desc">
                          {node.acd_branch.branch_desc}
                        </TableCell>
                      ) : null}
                      {USE_CLASS_KEY ? (
                        <TableCell className="std-edit-adm-no__bulk--table--desc">
                          {node.acd_class.class_desc}
                        </TableCell>
                      ) : null}
                      <TableCell className="std-edit-adm-no__bulk--table--admno">
                        {node.std_adm_no}
                      </TableCell>
                      <TableCell
                        className="std-edit-adm-no__bulk--table--input editCell"
                        onClick={() => onEdit(node)}
                      >
                        {EditMode.status && EditMode.rowKey === node.id ? (
                          <input
                            type="text"
                            value={admNumber}
                            autoFocus={
                              EditMode.status && EditMode.rowKey === node.id
                            }
                            onFocus={(e) => e.target.select()}
                            onBlur={() => {
                              setEditMode({
                                rowKey: -1,
                                status: false,
                              });
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setAdmNumber(e.target.value)}
                            onKeyDown={(e: React.KeyboardEvent) => {
                              if (e.key === Keys.ENTER) {
                                setRollNoAndIncrementNo(index, node.id);
                              }
                            }}
                          />
                        ) : (
                          <>{studentData.get(node.id)}</>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {students.length > 0 && (
              <>
                <Button mode="save" type="submit" onClick={HandleSubmit} />
                {!rowsPerPage && (
                  <Button
                    type="button"
                    onClick={() => {
                      setStudents(
                        students.map((student, index) => ({
                          ...student,
                          node: {
                            ...student!.node!,
                            std_adm_no: (index + 1).toString(),
                          },
                        }))
                      );
                    }}
                  >
                    Admission Numbers in Alphabetical Order
                  </Button>
                )}
                <Button mode="clear" type="button" onClick={clearStudentData} />
              </>
            )}

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 allot-regno__total">
            <StudentTotalCount
              totalCount={
                AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                  : 0
              }
            />
          </div>
        </div>
        <MessageModal
          modalFlag={message.flag}
          value={message.message}
          handleClose={handleClose}
          operation={message.operation}
        />
      </div>
      <LoadingModal flag={updationLoading} />
    </>
  );
};

export default BulkEditAdmNumber;

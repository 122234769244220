import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { ActiveAcademicYearData } from "../../../../Types/Accounting";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  HolidayDuration,
  HolidayType,
  Operation,
} from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  getDateRangeByMonth,
  getDatesByMonth,
  getOrdinalSuffix,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../hooks/useAcdGeneralHolidays";
import { GetAcdInstHolidayConfig } from "../../queries/holidays/list";
import { AddAcdInstGeneralHolidayConfigAndDetails } from "../../queries/holidays/mutation";
import {
  GetAcdInstHolidayConfigData,
  GetAcdInstHolidayConfigDetails,
  GetAcdInstMonthlyWorkingCalendarVars,
} from "../../types/attendance";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const weekDays = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  acd_yr: ActiveAcademicYearData;
}

interface days {
  monthName: string;
  dates: string;
  weekDay: string;
  occurrence: number;
}

interface GroupedDates {
  [key: number]: days[];
}
const New = ({ setModalFlag, acd_yr }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [filteredDates, setFilterdDates] = useState<days[]>([]);
  const [selectedWeekDay, setSelectedWeekDay] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [weekDayOptions, setWeekDayOptions] = useState<responseType[]>(
    Array.from({ length: 5 }, (_, i) => {
      const occurrence = i + 1;
      return {
        label: `${occurrence}${getOrdinalSuffix(occurrence)}`,
        value: occurrence,
        isChecked: false,
      };
    })
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [holidayConfig, setHolidayConfig] = useState<
    GetAcdInstHolidayConfigDetails[]
  >([]);
  const { endDate, startDate } = getDateRangeByMonth(
    new Date(TODAY_DATE).getFullYear(),
    new Date(TODAY_DATE).getMonth()
  );
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    endDate.toString(),
    startDate.toString(),
    EMPTY_STRING,
    holiday_query_type.WEEKEND_HOLIDAYS
  );
  const [GetHolidayConfig, { data }] = useLazyQuery<
    GetAcdInstHolidayConfigData,
    GetAcdInstMonthlyWorkingCalendarVars
  >(GetAcdInstHolidayConfig, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
    },
  });

  const [AddHolidays, { loading: creationLoading }] = useMutation(
    AddAcdInstGeneralHolidayConfigAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleHolidays = () => {
    AddHolidays({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: acd_yr.id,
        holiday_config: weekDayOptions
          .filter((data) => data.isChecked)
          .map((weekDayOption) => ({
            week_day: selectedWeekDay.label,
            day_idx: selectedWeekDay.value,
            week_day_status: weekDayOption.isChecked,
            week_idx: weekDayOption.value,
          })),

        holiday_details: Object.entries(groupByOccurrence(filteredDates))
          .map(([keys, dates]: [string, days[]]) =>
            dates.map((date) => ({
              holiday_type: HolidayType.WEEKEND,
              holiday_duration: HolidayDuration.FULLDAY,
              holiday_desc: date?.weekDay,
              day_idx: selectedWeekDay.value,
              week_idx: date.occurrence,
              holiday_date: toIsoDate(date?.dates),
            }))
          )
          .flat(),
      },
      refetchQueries: [
        {
          query: GetAcdInstHolidayConfig,
          variables: { token, acd_yr_id: acd_yr.id, inst_id: InstId! },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Created Holidays",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempClass = weekDayOptions.map((weekDayOption) => {
        return { ...weekDayOption, isChecked: checked };
      });
      setWeekDayOptions(tempClass);
    } else {
      let tempClass = weekDayOptions.map((user) =>
        user.label === name ? { ...user, isChecked: checked } : user
      );
      setWeekDayOptions(tempClass);
    }
  };
  const { acd_end_date, acd_st_date } = acd_yr;
  const getDates = () => {
    if (!acd_end_date || !acd_st_date) return;
    const res = Object.values(getDatesByMonth(acd_st_date, acd_end_date))
      .map((month) =>
        month[selectedWeekDay.label]?.filter((date) =>
          weekDayOptions.some(
            (option) => option.isChecked && option.value === date.occurrence
          )
        )
      )
      .flat();
    setFilterdDates(res);
  };

  const groupByOccurrence = (dates: days[]): GroupedDates => {
    return dates.reduce((groups: GroupedDates, date) => {
      if (!groups[date?.occurrence]) {
        groups[date?.occurrence] = [];
      }
      groups[date?.occurrence].push(date);
      return groups;
    }, {});
  };
  const handleClear = () => {
    setFilterdDates([]);
    weekDayOptions.map((weekDayOption) => weekDayOption.isChecked === false);
    setSelectedWeekDay(EMPTY_RESPONSETYPE_OBJECT);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (acd_yr.id) {
      GetHolidayConfig().then(({ data }) => {
        if (data) {
          setHolidayConfig(data.GetAcdInstHolidayConfig);
        }
      });
    }
  }, [GetHolidayConfig, acd_yr, data]);

  const updatedHoliDayConfigStructure: Record<
    string,
    GetAcdInstHolidayConfigDetails[]
  > = {};

  holidayConfig.forEach((config) => {
    if (!updatedHoliDayConfigStructure[config.week_day]) {
      updatedHoliDayConfigStructure[config.week_day] = [];
    }
    updatedHoliDayConfigStructure[config.week_day].push(config);
  });

  return (
    <>
      <Title>Add Weekends</Title>
      <div className="holiday-entry__new">
        <div className="row g-0 holiday-entry__new--details">
          <div className="col holiday-entry__new--block">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={weekDays}
              value={selectedWeekDay}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedWeekDay(newValue as responseType);
                  setFilterdDates([]);
                  setWeekDayOptions(
                    Array.from({ length: 5 }, (_, i) => {
                      const occurrence = i + 1;
                      return {
                        label: `${occurrence}${getOrdinalSuffix(occurrence)}`,
                        value: occurrence,
                        isChecked: false,
                      };
                    })
                  );
                } else {
                  setSelectedWeekDay(EMPTY_RESPONSETYPE_OBJECT);
                  setFilterdDates([]);
                  setWeekDayOptions(
                    Array.from({ length: 5 }, (_, i) => {
                      const occurrence = i + 1;
                      return {
                        label: `${occurrence}${getOrdinalSuffix(occurrence)}`,
                        value: occurrence,
                        isChecked: false,
                      };
                    })
                  );
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Weekend days"
                  fullWidth
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
            {selectedWeekDay.label && (
              <>
                <div className="select-all">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="allSelect"
                          onChange={handleOptionChange}
                          className="select-all--checkbox"
                          checked={
                            !weekDayOptions?.some(
                              (weekDayOption) =>
                                weekDayOption?.isChecked !== true
                            )
                          }
                        />
                      }
                      label="All Select"
                    />
                  </FormGroup>
                </div>
                <div className="holiday-entry__new--block--weekend-list">
                  {weekDayOptions.map((weekDayOption, index) => (
                    <FormGroup className="select-all__checkboxes" key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={weekDayOption.label}
                            checked={weekDayOption.isChecked || false}
                            onChange={handleOptionChange}
                          />
                        }
                        label={
                          <>
                            {weekDayOption.label + " " + selectedWeekDay.label}
                          </>
                        }
                      />
                    </FormGroup>
                  ))}
                </div>
                <Button mode="generate" onClick={getDates} />
              </>
            )}
          </div>
          <div className="col holiday-entry__new--block">
            <Title variant="subtitle1">
              Preview Weekend list for the Entire Month
            </Title>
            <div className="holiday-entry__new--block--list">
              <TableContainer className="holiday-entry__new--block--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl</TableCell>
                      <TableCell>Holiday Dates</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDates &&
                      Object.entries(groupByOccurrence(filteredDates)).map(
                        ([key, dates]: [string, days[]], index) => (
                          <React.Fragment key={index}>
                            <TableRow className="holiday-entry__new--block--table--day">
                              <TableCell colSpan={2} id="td-center">
                                {key + getOrdinalSuffix(Number(key))}
                                {selectedWeekDay.label}
                              </TableCell>
                            </TableRow>
                            {dates.map((date, index) => (
                              <TableRow key={date?.dates}>
                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                <TableCell>
                                  {toStandardDate(date?.dates)} {date?.weekDay}
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="col holiday-entry__new--block">
            <Title variant="subtitle1">Selected Weekends</Title>
            <div className="holiday-entry__new--block--list">
              <TableContainer className="holiday-entry__new--block--table">
                <Table stickyHeader>
                  {Object.entries(updatedHoliDayConfigStructure).map(
                    ([key, dates]: [
                      string,
                      GetAcdInstHolidayConfigDetails[]
                    ]) => {
                      return (
                        <React.Fragment>
                          <TableHead>
                            <TableRow>
                              <TableCell>{key}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dates.map((date) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    <Checkbox
                                      checked={date.week_day_status}
                                      disabled
                                    />
                                    {date.week_idx}
                                    {getOrdinalSuffix(date.week_idx)}{" "}
                                    {date.week_day}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </React.Fragment>
                      );
                    }
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="col holiday-entry__new--block">
            <Title variant="subtitle1">List of Weekends</Title>
            <div className="holiday-entry__new--block--list">
              <ul className="holiday-entry__new--block--holiday-list">
                {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
                  (edge, index) => {
                    return (
                      <li key={index}>
                        <Label>{index + 1}</Label>
                        <div className="holiday-entry__data--holiday-list--events">
                          <div>
                            <span>{edge.node.holiday_desc}</span>
                            <br />
                            <b>{toInputStandardDate(edge.node.holiday_date)}</b>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="holiday-entry__new--buttons">
          <Button
            mode="save"
            onClick={handleHolidays}
            disabled={!filteredDates.length}
          />
          <Button mode="clear" onClick={handleClear} />
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        </div>
      </div>
      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default New;

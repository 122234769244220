import React, { useEffect, useState } from "react";

import SubjectWise from "./SubjectWise";
import AttendanceGraph from "./AttendanceGraph";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes, PageFor } from "../../../../../utils/Enum.types";
import Attendance from "../../../../Master/Student/Dashboard/Attendance";
const Index = () => {
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  return (
    <>
      <div className="combinations-individual__tabpanel--block row g-0 ">
        <div className="col combinations-individual__tabpanel--frame">
          <Attendance pageType={PageFor.GENERAL}/>
        </div>
        {enablePerSessionAtt && (
          <div className="col combinations-individual__tabpanel--frame">
            <SubjectWise />
          </div>
        )}

        <div className="col combinations-individual__tabpanel--frame">
          <AttendanceGraph />
        </div>
      </div>
    </>
  );
};

export default Index;

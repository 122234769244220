import React from "react";
import Close from "../../../../images/Close.svg";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import {
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { Operation, TableHeaders } from "../../../../utils/Enum.types";
import { Title } from "../../../../stories/Title/Title";
import { AcdSubjectGroupDetails } from "../../../../Types/Groups/queries";
 
interface props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  subjects: AcdSubjectGroupDetails[];
}

const View = ({ setModal, operation, subjects }: props) => {

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "sub_name",
      headerName: TableHeaders.SUBJECT_DESCRIPTION,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-type",
      flex: 1,
    },
    {
      field: "sub_code",
      headerName: TableHeaders.SUBJECT_CODE,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-code",
    },
  ];

  const rows: GridValidRowModel[] =
    (subjects || []).map((response, index) => {
      return {
        id: index + 1,
        sub_code: response.subject_details.subj_code,
        sub_name: response.subject_details.subj_desc,
      };
    }) || [];
  return (
    <>
      <div className="view-groups">
        <div className="view-groups__title">
          <Title>
            {operation === Operation.DELETE
              ? "Delete Group of Allocated Subjects"
              : "View Group of Allocated Subjects"}
          </Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <b className="view-groups__selected-catg">Finance Minor</b>
        <div className={`view-groups__tableblock `}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            
          />
        </div>
      </div>
    </>
  );
};

export default View;

import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { StudentDetails } from "./useAcdStudentAttendance";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import { GetAcdStdAttOverAll } from "../queries/holidays/list";
import { toIsoDate } from "../../../utils/UtilFunctions";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import { EMPTY_STRING, TODAY_DATE } from "../../../utils/constants";
import {
  AcdSubjectAllocationData,
  Direction,
  SortBy,
} from "../../../utils/Enum.types";
import { StudentPageInfo } from "./useAcdStudentsData";
import { AcdStdAttSubjQueryType } from "../DailyActivities/Attendance/SessionAttendance";

interface GetAcdStdAttOverAllNode {
  id: number;
  std_roll_number: number;
  total_session: number;
  total_session_present: number;
  total_session_absent: number;
  over_all_per: number;
  student_details: StudentDetails;
}

export interface GetAcdStdAttOverAllEdges {
  node: GetAcdStdAttOverAllNode;
  cursor: string;
}

export interface GetAcdStdAttOverAllList {
  edges: GetAcdStdAttOverAllEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
interface GetAcdStdAttOverAllData {
  GetAcdStdAttOverAll: GetAcdStdAttOverAllList;
}
interface GetAcdStdAttOverAllInputs {
  att_query_type: string;
  entry_id: number;
  entry_level: string;
  cal_month: string;
  inst_id: number | string;
  acd_yr_id: number;
  subj_master_id: number;
  session_id: number;
  student_id: number;
  date_of_attendance: string;
}
interface GetAcdStdAttOverAllVars {
  token: string;
  input: GetAcdStdAttOverAllInputs;
  first: number | null;
  after: string | null;
  name: string;
  orderBy: { direction: string; field: string };
}

const useAcdStdOverAll = (
  deptId: number,
  branchId: number,
  classId: number,
  semId: number,
  sectionId: number,
  setDate?: string,
  query_type?: AcdStdAttSubjQueryType
) => {
  const { InstId, entryId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();

  const [id, setId] = useState(0);

  const [entryLevel, setEntryLevel] = useState<AcdSubjectAllocationData>(
    AcdSubjectAllocationData.NONE
  );
  const [GetOverAllAttendance, { data: OverAllData, loading, fetchMore }] =
    useLazyQuery<GetAcdStdAttOverAllData, GetAcdStdAttOverAllVars>(
      GetAcdStdAttOverAll,
      {
        variables: {
          input: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            entry_id: entryId ? Number(entryId) : id,
            entry_level: entryLevel,
            inst_id: InstId!,
            cal_month: toIsoDate(serverDate),
            subj_master_id: 0,
            session_id: 0,
            student_id: 0,
            att_query_type: query_type!,
            date_of_attendance: setDate
              ? toIsoDate(setDate)
              : toIsoDate(TODAY_DATE),
          },
          token,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.STD_ROLL_NO,
          },
          after: null,
          first: null,
          name: EMPTY_STRING,
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
      }
    );
  useEffect(() => {
    if (token && state.ActiveAcdYr) {
      GetOverAllAttendance();
    }
  }, [GetOverAllAttendance, token, state.ActiveAcdYr]);
  useEffect(() => {
    if (sectionId) {
      setId(sectionId);
      setEntryLevel(AcdSubjectAllocationData.SECTION);
    } else if (semId) {
      setId(semId);
      setEntryLevel(AcdSubjectAllocationData.SEMESTER);
    } else if (classId) {
      setId(classId);
      setEntryLevel(AcdSubjectAllocationData.CLASS);
    } else if (branchId) {
      setId(branchId);
      setEntryLevel(AcdSubjectAllocationData.BRANCH);
    } else if (deptId) {
      setId(deptId);
      setEntryLevel(AcdSubjectAllocationData.DEPT);
    } else {
      setId(0);
      setEntryLevel(AcdSubjectAllocationData.INST);
    }
  }, [sectionId, semId, classId, branchId, deptId]);
  return {
    OverAllData,
    loading,
    fetchMore,
  };
};

export default useAcdStdOverAll;

import { gql } from "@apollo/client";

export const AddOrUpdatePayRollEmpDailyDiary = gql`
  mutation AddOrUpdatePayRollEmpDailyDiary(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: PayRollEmpDailyDiaryInput!
    $pr_acd_yr_id: ID!
  ) {
    AddOrUpdatePayRollEmpDailyDiary(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      pr_acd_yr_id: $pr_acd_yr_id
    )
  }
`;
export const SubmitEmpDailyDiary = gql`
  mutation SubmitEmpDailyDiary(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: DailyDiaryStatusInput!
    $pr_acd_yr_id: ID!
  ) {
    SubmitEmpDailyDiary(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      pr_acd_yr_id: $pr_acd_yr_id
    )
  }
`;
export const AddAcdFeedbackStdRating = gql`
  mutation AddAcdFeedbackStdRating(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
    $allotted_level: String!
    $allotted_id: ID!
    $input: FeedbackRating!
    $todays_date: Time!
    $perStdSubjAllocation: Boolean!
  ) {
    AddAcdFeedbackStdRating(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      user_details: $user_details
      allotted_level: $allotted_level
      allotted_id: $allotted_id
      input: $input
      todays_date: $todays_date
      perStdSubjAllocation: $perStdSubjAllocation
    )
  }
`;
export const UpdateAcdFeedbackStdRating = gql`
  mutation UpdateAcdFeedbackStdRating(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
    $std_rating_id: ID!
    $rating: Int!
    $remarks: String!
    $todays_date: Time!
  ) {
    UpdateAcdFeedbackStdRating(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      user_details: $user_details
      std_rating_id: $std_rating_id
      rating: $rating
      remarks: $remarks
      todays_date: $todays_date
    )
  }
`;

import React, { useContext, useEffect, useState } from "react";
import { GetComboDetails } from "../../../../queries/customallocation/combination/queries";
import {
  GetComboDetailsData,
  GetComboDetailsVars,
} from "../../../../Types/Combinations/queries";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { useParams } from "react-router-dom";
import useAcdLevel from "../../../Accounts/hooks/useAcdLevel";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
import { responseType } from "../../../../utils/Form.types";

const useCombinationDetails = (query_type: AcdSubjComboQueryType) => {
  const { token } = useToken();
  const { entryId, InstId, testId } = useParams();
  const { acdLevel } = useAcdLevel(entryId!);
  const { state } = useContext(AppContext);
  const [combinations, setCombinations] = useState<responseType[]>([]);
  const [GetCombinations, { data, error }] = useLazyQuery<
    GetComboDetailsData,
    GetComboDetailsVars
  >(GetComboDetails, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: entryId!,
      entry_level: acdLevel,
      inst_id: InstId!,
      acd_test_class_id: testId!,
      query_type,
    },
  });

  useEffect(() => {
    if (token) {
      GetCombinations().then(({ data }) => {
        if (data && data.GetComboDetails) {
          setCombinations(
            data.GetComboDetails.map((combo) => ({
              label: combo.subj_combo_name,
              value: combo.id,
            }))
          );
        }
      });
    }
  }, [
    GetCombinations,
    token,
    entryId,
    InstId,
    testId,
    acdLevel,
    state.ActiveAcdYr,
    data,
  ]);
  return {
    data,
    error,
    combinations,
  };
};

export default useCombinationDetails;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { TableHeaderProps } from "../../../../Types/Tables";
import { AccountBookTypes, PageFor } from "../../../../utils/Enum.types";
import Enlarge from "../../../../images/Enlarge.svg";
import Unclear from "../../../../images/UnclearEntries.svg";
import AllTransaction from "../../../../images/AllTransaction.svg";
import ReConcilEntries from "../../../../images/ReconcilEntries.svg";
import ReConcile from "../../../../images/ReConcile.svg";
import { Button } from "../../../../stories/Button/Button";
import { AppContext } from "../../../../context/context";
import Modal from "react-modal";
import { ReConciledOnModalStyles } from "../../../../styles/ModalStyles";
import { Title } from "../../../../stories/Title/Title";
import { payloadTypes } from "../../../../context/reducer";
import { EMPTY_STRING } from "../../../../utils/constants";
import useAcctTableJson from "../../json/useAcctTableJson";

const tableData = [
  {
    date: "21-2-2022",
    particulars: "Alumini Participation",
    VoucherTypes: "payment",
    voucherno: "2344",
    debit: "25000",
    Credit: "5000",
    reconcil: "98",
    chq_number: "220",
  },
];

interface Props {
  type: AccountBookTypes;
  pageType: PageFor;
  CompleteDetailsModal: boolean;
  setCompleteDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const TransactionList = ({
  type,
  pageType,
  CompleteDetailsModal,
  setCompleteDetailsModal,
}: Props) => {
  const [enableFilterDate, setEnableFiletrDate] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const { Accounts_Table } = useAcctTableJson();
  //fee-collected
  const feeCollectedButtons = [
    { id: 1, name: "All Vouchers", className: "storybook-button--addnew" },
    {
      id: 2,
      name: "Cash & Bank Vouchers",
      className: "storybook-button--add-new-fee",
    },
    {
      id: 3,
      name: "Cash Vouchers",
      className: "storybook-button--add-new-fee",
    },
    {
      id: 4,
      name: "Bank Vouchers",
      className: "storybook-button--add-new-fee",
    },
    { id: 5, name: "Refund Fees", className: "storybook-button--add-new-fee" },
    {
      id: 6,
      name: "Advance Adjusted",
      className: "storybook-button--add-new-fee",
    },
    {
      id: 7,
      name: "Refund Advances",
      className: "storybook-button--add-new-fee",
    },
    { id: 8, name: "Refund All", className: "storybook-button--add-new-fee" },
  ];
  const handleClickFeeCollectedButtons = (id: number) => {
    // eslint-disable-next-line
    const filteredfeeCollectedButtons = feeCollectedButtons.find(
      (button) => button.id === id
    );
  };

  //bank-reconcilation

  const [reconciledOnModal, setReConciledOnModal] = useState(false);
  const BankReconcilationButtons = [
    {
      id: 1,
      name: "Reconcil Entries",
      className: "storybook-button--reconcil-entries",
      image: `${ReConcilEntries}`,
    },
    {
      id: 2,
      name: "Unclear Entries",
      className: "storybook-button--unclear-entries",
      image: `${Unclear}`,
    },
    {
      id: 3,
      name: "All Transaction",
      className: "storybook-button--all-transaction",
      image: `${AllTransaction}`,
    },
    {
      id: 4,
      name: "Auto Reconcil All Student Receipt",
      className: "storybook-button--add-new-fee",
      image: `${ReConcile}`,
    },
  ];

  const handleClickBankReconcillationButtons = (id: number) => {
    const filteredBankReconcilationButtons = BankReconcilationButtons.find(
      (button) => button.id === id
    );
    if (id === 1) {
      dispatch({
        type: payloadTypes.SET_BANK_RECONCILATION_NAME,
        payload: {
          bankReconcilationName: filteredBankReconcilationButtons?.name!,
        },
      });
    }
    if (id === 2) {
      dispatch({
        type: payloadTypes.SET_BANK_RECONCILATION_NAME,
        payload: {
          bankReconcilationName: filteredBankReconcilationButtons?.name!,
        },
      });
    }
    if (id === 3) {
      dispatch({
        type: payloadTypes.SET_BANK_RECONCILATION_NAME,
        payload: {
          bankReconcilationName: filteredBankReconcilationButtons?.name!,
        },
      });
    }
    if (id === 4) {
      dispatch({
        type: payloadTypes.SET_BANK_RECONCILATION_NAME,
        payload: {
          bankReconcilationName: filteredBankReconcilationButtons?.name!,
        },
      });
    }
  };

  return (
    <>
      <div className="bank-reconcilation__tableblock--transaction-details--title">
        <Title variant="subtitle1">
          {type === AccountBookTypes.BANK_RECONCILATION
            ? state.bankReconcilationName
            : EMPTY_STRING}
        </Title>
        <div>
          <Label variant="LabelPrimary">Filter Date :</Label>
          <Input
            type="checkbox"
            onChange={() => setEnableFiletrDate(!enableFilterDate)}
          />
          <TextField
            type="date"
            label="From"
            className="bank-reconcilation__tableblock--transaction-details--title--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            defaultValue="2022-07-02"
            disabled={!enableFilterDate}
          />
          <TextField
            type="date"
            label="To"
            className="bank-reconcilation__tableblock--transaction-details--title--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            defaultValue="2022-07-02"
            disabled={!enableFilterDate}
          />
          <img
            src={Enlarge}
            alt="/"
            onClick={() => setCompleteDetailsModal(!CompleteDetailsModal)}
          />
        </div>
      </div>
      <TableContainer className="bank-reconcilation__tableblock--transaction-details--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Accounts_Table.Reports.AccountLedgerBooks.LedgerTransactionDetails.Table_Headers.map(
                (th: TableHeaderProps, index: React.Key) => {
                  return (
                    <TableCell key={index} className={th.className}>
                      {th.labelName}
                    </TableCell>
                  );
                }
              )}

              {type === AccountBookTypes.BANK_RECONCILATION && (
                <TableCell>Reconcile Date</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((data, index) => {
              return (
                <TableRow
                  key={index}
                  onClick={() => {
                    type === AccountBookTypes.BANK_RECONCILATION &&
                      setReConciledOnModal(!reconciledOnModal);
                  }}
                >
                  <TableCell className="bank-reconcilation__tableblock--transaction-details--table--date">
                    {data.date}
                  </TableCell>
                  <TableCell>{data.particulars}</TableCell>
                  <TableCell className="bank-reconcilation__tableblock--transaction-details--table--voucher-type">
                    {data.VoucherTypes}
                  </TableCell>
                  <TableCell className="bank-reconcilation__tableblock--transaction-details--table--voucher-number">
                    {data.voucherno}
                  </TableCell>
                  <TableCell
                    className="bank-reconcilation__tableblock--transaction-details--table--amount"
                    id="td-right"
                  >
                    {data.debit}
                  </TableCell>
                  <TableCell
                    className="bank-reconcilation__tableblock--transaction-details--table--amount"
                    id="td-right"
                  >
                    {data.Credit}
                  </TableCell>
                  {type === AccountBookTypes.BANK_RECONCILATION && (
                    <TableCell className="bank-reconcilation__tableblock--transaction-details--table--date">
                      {data.reconcil}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
          {pageType === PageFor.MODAL && (
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={4}
                  className="total bank-reconcilation__tableblock--transaction-details--table--amount"
                >
                  {type === AccountBookTypes.BANK_RECONCILATION
                    ? "Balance As per Books of Accounts (Amount Not Reflected in Bank)"
                    : "Opening Balance"}
                </TableCell>
                <TableCell
                  id="td-right"
                  className={
                    type === AccountBookTypes.BANK_RECONCILATION
                      ? "balance-count bank-reconcilation__tableblock--transaction-details--table--amount"
                      : "totalcountcell bank-reconcilation__tableblock--transaction-details--table--amount"
                  }
                >
                  0.00
                </TableCell>
                <TableCell></TableCell>

                {type === AccountBookTypes.BANK_RECONCILATION && (
                  <TableCell></TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} className="total">
                  {type === AccountBookTypes.BANK_RECONCILATION
                    ? "Balance As per Bank"
                    : "Total"}
                </TableCell>
                <TableCell
                  id="td-right"
                  className={
                    type === AccountBookTypes.BANK_RECONCILATION
                      ? "balance-count"
                      : "totalcount"
                  }
                >
                  0.00
                </TableCell>
                <TableCell></TableCell>
                {type === AccountBookTypes.BANK_RECONCILATION && (
                  <TableCell></TableCell>
                )}
              </TableRow>
              {type === AccountBookTypes.FEE_COLLECTED && (
                <TableRow>
                  <TableCell colSpan={4} className="total">
                    Closing Balance
                  </TableCell>
                  <TableCell className="balance-count">0.00</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      <div className="bank-book__table-section--transaction-details--reconcil-table--button">
        {type === AccountBookTypes.BANK_RECONCILATION ? (
          <>
            {BankReconcilationButtons.map((button, index) => {
              return (
                <React.Fragment key={index}>
                  <Button
                    className={button.className}
                    onClick={() =>
                      handleClickBankReconcillationButtons(button.id)
                    }
                  >
                    <img src={button.image} alt="/" />
                    {button.name}
                  </Button>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <>
            {feeCollectedButtons.map((button, index) => {
              return (
                <React.Fragment key={index}>
                  <Button
                    className={button.className}
                    onClick={() => handleClickFeeCollectedButtons(button.id)}
                  >
                    {button.name}
                  </Button>
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>
      <Modal
        isOpen={reconciledOnModal}
        shouldCloseOnOverlayClick={true}
        style={ReConciledOnModalStyles}
        ariaHideApp={false}
      >
        <Title variant="subtitle1">Reconciled On</Title>
        <div className="label-grid">
          <Label>Cheque Released On</Label>
          <Input type="date" />
        </div>
        <Button mode="save" />
        <Button
          mode="cancel"
          onClick={() => setReConciledOnModal(!reconciledOnModal)}
        />
      </Modal>
    </>
  );
};

export default TransactionList;

import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../components/common/Input/Index";
import { Label } from "../../../stories/Label/Label";
import { Select } from "../../../stories/Select/Select";
import React, { useState } from "react";

// import { useHistory } from "react-router-dom";
import { eduateValidation } from "../../../utils/validationRules";
import { AuthEduateTypes } from "../../../utils/Form.types";
import { Form, Formik } from "formik";

import { EduateUserType, Operation } from "../../../utils/Enum.types";
import { removeMoreSpace } from "../../../utils/UtilFunctions";

const { EduateuserFormLabels } = require("../../../json/config.json");
interface Props {
  type: Operation;
}
const EduateUser = ({ type }: Props) => {
  const [formData, setFormData] = useState<AuthEduateTypes>({
    firstname: "",
    middlename: "",
    lastname: "",
    address: "",
    place: "",
    state: "",
    pincode: "",
    email: "",
    mobile: "",
    rights: "",
    user_type: "",
  });

  const handleValueChange = (e: any) => {
    setFormData((prevValues: any) => ({
      ...prevValues,
      [e.target.name]: removeMoreSpace(e.target.value),
    }));
  };

  const HandleRegister = async () => {};

  return (
    <Formik
      initialValues={formData}
      validationSchema={eduateValidation}
      onSubmit={HandleRegister}
    >
      {(meta) => {
        meta.values.first_name = formData.first_name;
        meta.values.email = formData.email;
        meta.values.mobile = formData.mobile;
        return (
          <Form>
            <div className="eduateuser">
              <Title>Eduate Version Details</Title>
              <div className="main-frame">
                <div className="row">
                  <div className="col-6">
                    <div className="details">
                      <h4>Personal Details</h4>
                    </div>
                    {EduateuserFormLabels.Labels.Personaldetails.map(
                      (label: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={label.LabelName}
                              name={label.inputName}
                              type={label.dataType}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData[label.inputName]}
                              required={label.required}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>User Type</Label>
                      <Select
                        name="user_type"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          handleValueChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        {Object.values(EduateUserType).map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </Select>
                    </div>

                    {type === Operation.CREATE ? (
                      <Button mode="addnew" type="submit" />
                    ) : (
                      <Button mode="edit" type="submit" />
                    )}
                    <Button secondary>CANCEL</Button>
                  </div>
                  <div className="col-6">
                    <div className="details">
                      <h4>Location Details</h4>
                    </div>
                    {EduateuserFormLabels.Labels.Locationdetails.map(
                      (label: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData[label.inputName]}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>State</Label>
                      <Select
                        name="state"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          handleValueChange(e);
                        }}
                      >
                        {formData.state ? (
                          <option aria-selected="true" value={formData.state}>
                            {formData.state}
                          </option>
                        ) : (
                          <option aria-selected="true" value="">
                            Select State
                          </option>
                        )}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EduateUser;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetVoucherNumber } from "../queries/Vouchers/query";

import { VoucherBookKey } from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
export interface VDetails {
  vo_number: string;
  vo_book_key: string;
  voucher_sub_type: string;
}
export interface VoucherBookData {
  GetVoucherNumber: VDetails;
}
export interface VoucherBookVars {
  token: string;
  inst_id: string;
  fin_yr_id: number;
  vo_book_key?: string;
}

const useVoucherNumber = (bookKey: VoucherBookKey | VoucherBookKey[]) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [GetVoucherData, { data, loading, error }] = useLazyQuery<
    VoucherBookData,
    VoucherBookVars
  >(GetVoucherNumber, { fetchPolicy: "network-only" });
  const [multipleVoucherNumber, setMultipleVocherNumber] = useState<VDetails[]>(
    []
  );
  useEffect(() => {
    if (state.ActiveFinYr && bookKey) {
      const fetchData = async () => {
        const promises = Array.isArray(bookKey)
          ? bookKey.map((element) => {
              return GetVoucherData({
                variables: {
                  token,
                  inst_id: InstId!,
                  vo_book_key: element,
                  fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                },
              });
            })
          : [
              GetVoucherData({
                variables: {
                  token,
                  inst_id: InstId!,
                  vo_book_key: bookKey,
                  fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                },
              }),
            ];
        const results = await Promise.all(promises);
        const str = results
          .map(({ data }) => data && data.GetVoucherNumber)
          .filter(Boolean);
        setMultipleVocherNumber(str as VDetails[]);
      };
      fetchData();
    }
  }, [state.ActiveFinYr, GetVoucherData, bookKey, token, InstId]);

  return { voucherNumber: { data, loading, error, multipleVoucherNumber } };
};

export default useVoucherNumber;

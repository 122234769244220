import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import { StudentDetails } from "../../../customhooks/useStudentDatabyId";
import useToken from "../../../customhooks/useToken";
import { GetAcctVouchers } from "../queries/Vouchers/query";

import { Direction, SortBy } from "../../../utils/Enum.types";
import {
  acct_challan_details,
  acct_voucher_details,
} from "../../../Types/Accounting";
import { EMPTY_STRING } from "../../../utils/constants";
import { pageInfo } from "../../../Types/pagination";
import { VoucherQueryTypes } from "../common/Enum.types";
import { AluminiStudentNode } from "../../../Types/Student/paginationTypes";
import useLoggedInUserDetails from "./useLoggedInUserDetails";
import { userDetails } from "../../../Types/Accounting/other";

export interface LedgerName {
  ldgr_desc: string;
}
export interface voucherMasterDetails {
  id: number;
  student_id: number;
  enquiry_student_id: number;
  v_std_passout_receipt: boolean;
  v_type: string;
  v_date: string;
  created_at: string;
  updated_at: string;
  v_transcation_narration: string;
  v_std_non_demand_receipt?: boolean;
  v_no: string;
  v_total_db_amt: number;
  v_total_cr_amt: number;
  v_std_amt_total: number;
  v_std_refund: boolean;
  v_std_enquiry: boolean;
  v_transcation_type: string;
  v_transcation_cash_or_bank: string;
  v_std_deposit_adjusted: boolean;
  mkck_voucher_id: number;
  v_std_anonymous_deposit_adjusted: boolean;
  party_bill_no: string;
  v_transcation_no: string;
  party_bill_date: string;
  v_std_deposit: boolean;
  v_std_demand_receipt: boolean;
  v_std_scholarship_deposit: boolean;
  payment_invoice_filename: string;
  v_std_deposit_amt_found: boolean;
  v_std_receipt: boolean;
  v_std_refund_deposit: boolean;
  v_std_receipt_anonymous: boolean;
  party_name: string;
  v_book_type: string;
  acct_voucher_details: acct_voucher_details[];
  acct_challan_details: acct_challan_details[];
  passout_std_details: AluminiStudentNode;
  passout_student_id: number;
  mst_student: StudentDetails;
  class_id: number;
  class_details: {
    class_desc: string;
  };
  branch_details: {
    branch_desc: string;
  };
  enq_std_details: {
    id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
    std_father_name: string;
    branch_details: {
      branch_desc: string;
    };
    class_details: {
      class_desc: string;
    };
  };
  update_remarks: string;
  update_count: number;
  db_ldgr_details: LedgerName;
}
export interface ChallanMasterDetails {
  id: number;
  created_at: string;
  updated_at: string;
  fin_yr: string;
  v_type: string;
  v_book_type: string;
  v_no: string;
  v_date: string;
  v_total_cr_amt: number;
  v_total_db_amt: number;
  v_reconciled: boolean;
  v_reconciled_date: string;
  v_std_receipt: boolean;
  v_std_refund: boolean;
  v_std_refund_deposit: boolean;
  v_std_deposit_adjusted: boolean;
  v_std_deposit: boolean;
  v_std_demand_receipt: boolean;
  v_std_non_demand_receipt: boolean;
  v_std_passout_receipt: boolean;
  v_std_enquiry: boolean;
  v_std_receipt_anonymous: boolean;
  v_std_anonymous_deposit_adjusted: boolean;
  v_std_scholarship_deposit: boolean;
  student_id: number;
  class_id: number;
  semester_id: number;
  passout_student_id: number;
  enquiry_student_id: number;
  v_std_amt_receipt: number;
  v_std_amt_deposit: number;
  v_std_amt_fine: number;
  v_std_amt_total: number;
  v_std_amt_refunded: number;
  v_std_amt_adjusted: number;
  v_transcation_type: string;
  v_transcation_cash_or_bank: string;
  v_transcation_no: string;
  v_transcation_date: string;
  v_transcation_narration: string;
  refund_authorized_by: string;
  v_online_reference_num: string;
  paid_party_id: number;
  party_bill_no: string;
  party_bill_date: string;
  party_name: string;
  payment_invoice_filename: string;
  update_remarks: string;
  update_count: number;
  annx_yesno: boolean;
  annx_id: number;
  acct_ldgr_id_cr: number;
  acct_ldgr_id_db: number;
  vo_voucher_master_id: number;
  vo_voucher_master_no: string;
  inst_id: number;
  fin_yr_id: number;

  mst_student: StudentDetails;
  passout_std_details: AluminiStudentNode;
  enquiry_student_details: {
    id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
    std_father_name: string;
    branch_details: {
      branch_desc: string;
    };
    class_details: {
      class_desc: string;
    };
  };
  acct_challan_details: acct_challan_details[];
  acct_ldgr_db_details: {
    ldgr_desc: string;
    id: number;
    ldgr_total_db: number;
  };
}
export interface GetAcctVouchersEdges {
  node: voucherMasterDetails;
}
export interface GetAcctChallanEdges {
  node: ChallanMasterDetails;
}
interface input {
  voucher_query_type: string;
  inst_id: string;
  acct_ldgr_id?: number;
  vo_start_date: string;
  vo_end_date: string;
  vo_type?: string;
  student_id?: number;
  user_details: userDetails;
}
export interface GetAcctVoucherMasterByDatesVars
  extends GetAcctVoucherAnonymousVars {
  input: input;

  deposit: boolean | null;
  vTranscationCashOrBank: string | null;
  partyName: string | null;
  vTranscationNarration: string | null;
  ldgrDesc: string | null;
  v_no: string;
  amount: number | null;
}
export interface GetAcctVoucherAnonymousVars {
  vTranscationNo: string | null;
  first: number | null;
  token: string;
  fin_yr_id: number;
  after: string | null;
  inst_id?: string | undefined;
  start_date?: string;
  end_date?: string;
  sortBy: String;
  direction: string;
  anonymous_std_type?: string;
}
export interface GetAcctVoucherAnonymousNodeDetails {
  id: number;
  voucher_master_id: number;
  v_transcation_no: string;
  anonymous_type: string;
  received_amt: number;
  adjusted_amt: number;
  bal_amt: number;
  voucher_master_details: voucherMasterDetails;
  adjustment_details: { id: number };
}
export interface UnkownStudentByMasterVoucherIdNodeData {
  nodes: GetAcctVoucherAnonymousNodeDetails[];
}
export interface GetAcctVoucherAnonymousEdges {
  node: GetAcctVoucherAnonymousNodeDetails;
}
interface GetAcctVoucherAnonymousDetails {
  totalCount: number;
  pageInfo: pageInfo;
  edges: GetAcctVoucherAnonymousEdges[];
}
export interface GetAcctVoucherAnonymousData {
  GetAcctVoucherAnonymous: GetAcctVoucherAnonymousDetails;
}
export interface GetAcctVoucherMasterByDatesData {
  GetAcctVouchers: GetAcctVoucherMasterByDatesDetails;
}
export interface GetAcctVoucherMasterByDatesDetails {
  totalCount: number;
  pageInfo: pageInfo;
  edges: GetAcctVouchersEdges[];
}
export interface GetAcctChallanMasterByDatesDetails {
  totalCount: number;
  pageInfo: pageInfo;
  edges: GetAcctChallanEdges[];
}

const useAcctVoucherDetailsByDates = (
  rowsPerPage: number | null,
  vo_end_date: string,
  vo_start_date: string,
  type: string,
  v_no: string,
  deposit?: boolean,
  vTranscationCashOrBank?: string,
  partyName?: string,
  vTranscationNo?: string,
  vTranscationNarration?: string,
  ldgrDesc?: string,
  amount?: number
) => {
  const { state } = useContext(AppContext);
  const { token } = useToken();

  const { InstId } = useParams();
  const [queryType, setQueryType] = useState("");
  const [vo_type, setVo_type] = useState("");
  const { user_details } = useLoggedInUserDetails();

  const [GetLedgerDetailsByDate, { data, loading, fetchMore, error }] =
    useLazyQuery<
      GetAcctVoucherMasterByDatesData,
      GetAcctVoucherMasterByDatesVars
    >(GetAcctVouchers, {
      variables: {
        after: null,
        direction: Direction.ASC,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        first: rowsPerPage === null ? null : rowsPerPage,
        sortBy: SortBy.V_DATE,
        token,
        v_no,
        deposit: deposit ? deposit : null,
        vTranscationCashOrBank: vTranscationCashOrBank
          ? vTranscationCashOrBank
          : null,
        partyName: partyName ? partyName : EMPTY_STRING,
        vTranscationNo: vTranscationNo ? vTranscationNo : EMPTY_STRING,
        vTranscationNarration: vTranscationNarration
          ? vTranscationNarration
          : EMPTY_STRING,
        ldgrDesc: ldgrDesc ? ldgrDesc : EMPTY_STRING,
        amount: amount ? amount : null,
        input: {
          inst_id: InstId! ?? state.InstId,
          voucher_query_type: queryType,
          vo_end_date: dayjs(vo_end_date).format(),
          acct_ldgr_id: state.accountLedgerId,
          vo_start_date: dayjs(vo_start_date).format(),
          vo_type: vo_type,
          student_id:
            queryType === VoucherQueryTypes.STD_DEMANDS_AND_DEPOSITS ||
            queryType === VoucherQueryTypes.STD_DEPOSIT_COLLECTED_DETAILS ||
            queryType === VoucherQueryTypes.STD_DEPOSIT_ADJUSTED_DETAILS
              ? state.studentId
                ? state.studentId
                : state.receiptStudentId
              : 0,
          user_details,
        },
      },
      // fetchPolicy: "network-only",
      // nextFetchPolicy: "network-only",
    });

  useEffect(() => {
    switch (type) {
      case VoucherQueryTypes.BY_VOUCHER_TYPE_AND_BY_DATES:
        if (state.registerType) {
          setQueryType(VoucherQueryTypes.BY_VOUCHER_TYPE_AND_BY_DATES);
          setVo_type(state.registerType);
        }
        break;
      default:
        setQueryType(type);
        break;
    }
  }, [type, state.registerType]);

  useEffect(() => {
    if (token && state.ActiveFinYr) {
      GetLedgerDetailsByDate();
    } // eslint-disable-next-line
  }, [state.ActiveFinYr, data, token, GetLedgerDetailsByDate, state.InstId]);

  return { VoucherDetails: { data, loading, error, fetchMore } };
};

export default useAcctVoucherDetailsByDates;

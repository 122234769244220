import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import {
  EnquiryStudentDetailsByMasterVoucherId,
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../queries/common";
import { nodevars, VouchernodeData } from "../../../Types/Accounting";
import { voucherMasterDetails } from "./useAcctVoucherDetailsByDates";

const useStudentReceiptDetails = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [studentReceiptDetails, setStudentReceiptDetails] =
    useState<voucherMasterDetails>();

  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );

  const [GetAluminiStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    PassedOutStudentDetailsByMasterVoucherId
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnquiryStudentDetailsByMasterVoucherId
  );

  useEffect(() => {
    if (state.receiptId && state.studentId && token) {
      GetStudentDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data && data.nodes) {
          setStudentReceiptDetails(data?.nodes[0]);
        }
      });
    }
  }, [GetStudentDetails, state.receiptId, state.studentId, token]);

  useEffect(() => {
    if (state.receiptId && state.aluminiStudentId && token) {
      GetAluminiStudentDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data) {
          setStudentReceiptDetails(data?.nodes[0]);
        }
      });
    }
  }, [
    GetAluminiStudentDetails,
    state.receiptId,
    token,
    state.aluminiStudentId,
  ]);

  useEffect(() => {
    if (state.receiptId && state.studentEnquiryId && token) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data) {
          setStudentReceiptDetails(data?.nodes[0]);
        }
      });
    }
  }, [GetEnqStudentDetails, state.receiptId, token, state.studentEnquiryId]);
  return { studentReceiptDetails };
};

export default useStudentReceiptDetails;

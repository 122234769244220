import Home from "../../Home/Index";
import PreviewTabs from "./PreviewTabs";
import EmployeeDetails from "../Registration/EmployeeDetails";
import React, { useEffect, useState } from "react";
import { employeeOtherDetailsType } from "../../Types/formTypes";
import { TextField } from "@mui/material";
import { LabelNameProps } from "../../../../Types/Labels";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

const { EmployeePreview } = require("../../json/form.json");
const BasicData = () => {
  const { InstId, empId } = useParams();
  const {
    empolyeeData: { data, loading },
  } = useEmpDetailsById();
  const navigate = useNavigate();
  const [EmpOtherDetailsFormData, setEmpOtherDetailsFormData] =
    useState<employeeOtherDetailsType>({
      emp_aadhar: "",
      emp_pan: "",
      emp_bank_acct_no: "",
      emp_bank_name: "",
      emp_bank_ifsc: "",
      emp_epf_no: "",
      emp_esi_no: "",
      emp_biometric_id: "",
      emp_esi_dispen: "",
      emp_uan_no: "",
      emp_passport: "",
      emp_drv_lic: "",
      emp_voter_id: "",
      emp_biometric_machine_id: "",
    });
  useEffect(() => {
    if (data && !loading) {
      setEmpOtherDetailsFormData({ ...data.node.other_details });
    }
  }, [data, loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <PreviewTabs />
      <div className="emp-preview">
        <EmployeeDetails />
        <div className="emp-preview__frame--personal">
          <div className="emp-preview__frame--data row">
            <div className="col emp-preview__span-4">
              {EmployeePreview.OtherDetails?.map(
                (label: LabelNameProps, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        id="outlined-uncontrolled"
                        className="emp-preview__textfield"
                        label={label.LabelName}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        value={EmpOtherDetailsFormData[
                          label.inputName
                        ]?.toString()}
                        disabled
                        name={label.inputName}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
          <Button
            mode="edit"
            onClick={() =>
              navigate(`/${InstId}/hr/employee/3/${empId}/update/otherdetails`)
            }
          />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default BasicData;

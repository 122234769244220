import { gql } from "@apollo/client";
export const ByNode = gql`
  query ClassDetailsByNode($class_id: ID!, $token: String!) {
    node(id: $class_id, token: $token) {
      ... on MstInstClass {
        id
        inst_id
        branch_id
        class_short_desc
        class_desc
        class_total_adm_count
        class_total_Res_adm_count
      }
    }
  }
`;
export const ClassesByBranchIds = gql`
  query GetClassesForScanByBranchIds($branch_ids: [ID!]!, $token: String!) {
    GetClassesByBranchIds(token: $token, branch_ids: $branch_ids) {
      id
      class_desc
      class_idx
      class_total_adm_count
      class_total_Res_adm_count
    }
  }
`;
export const GetClassesForScanByBranchIds = gql`
  query GetClassesForScanByBranchIds($branch_ids: [ID!]!) {
    GetClassesForScanByBranchIds(branch_ids: $branch_ids) {
      id
      class_desc
      class_idx
      class_total_adm_count
      class_total_Res_adm_count
    }
  }
`;
export const ByBranchIds = gql`
  query GetClassNamesByBranchIds($branch_id: [ID!]!, $token: String!) {
    GetClassNamesByBranchIds(token: $token, branch_id: $branch_id) {
      id
      branch_id
      class_desc
    }
  }
`;

export const ClassByInstId = gql`
  query GetClassesByInstId($token: String!, $inst_id: ID!) {
    GetClassesByInstId(token: $token, inst_id: $inst_id) {
      class_desc
      id
      class_total_adm_count
      class_total_Res_adm_count
    }
  }
`;

export const ClassCountByBranchId = gql`
  query GetClassCount($token: String!, $branch_id: ID!) {
    GetClassCountByBranchId(token: $token, branch_id: $branch_id)
  }
`;

export const GetUnMappedClassInAccountDemandDetails = gql`
  query GetAcctDemandMainUnMappedClasses(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
    $category_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctDemandMainUnMappedClasses(
      token: $token
      inst_id: $inst_id
      branch_id: $branch_id
      category_id: $category_id
      fin_yr_id: $fin_yr_id
    ) {
      class_id
      class_desc
    }
  }
`;

export const GetClassesByNodes = gql`
  query GetClassesByNodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on MstInstClass {
        class_desc
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const PayRollByNodeIds = gql`
  query nodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on PayRollAcdYr {
        id
        payroll_yr
        payroll_st_date
        payroll_end_date
        payroll_is_curr_yr
        inst_id
      }
    }
  }
`;

export const GetPayRollMasterData = gql`
  query GetPayRollMasterData($token: String!, $inst_id: ID!) {
    GetPayRollMasterData(token: $token, inst_id: $inst_id) {
      pr_dept_details {
        id
        dept_desc
        dept_idx
      }
      pr_designation_details {
        id
        designation_desc
        designation_idx
      }
      pr_category_details {
        id
        category_desc
        category_idx
      }
      pr_job_type_details {
        id
        job_type_desc
        job_type_idx
      }
      pr_grade_details {
        id
        grade_desc
        grade_idx
      }
    }
  }
`;

export const GetPayRollNotAllocatedIds = gql`
  query GetPayRollNotAllocatedIds($token: String!, $inst_id: ID!) {
    GetPayRollNotAllocatedIds(token: $token, inst_id: $inst_id) {
      job_type_id
      dept_id
      designation_id
      grade_id
      category_id
    }
  }
`;

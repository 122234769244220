import React, { useState } from "react";
import List from "./List";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/common/Input/Index";
import { Title } from "../../../stories/Title/Title";
import { GetInstDocuments } from "./queries";
import { Operation } from "../../../utils/Enum.types";
import { AddDocument, UpdateDocumentName } from "./mutation";
import { useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { DocumentFormDataTypes } from "./Types";
import { msgType } from "../../../utils/Form.types";
import { removeMoreSpace } from "../../../utils/UtilFunctions";
import { Form, Formik } from "formik";
import { document_validation } from "../../../utils/validationRules";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import Home from "../../Master/configurations/Home/Index";
import { EMPTY_STRING } from "../../../utils/constants";

const Documents = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [documentId, setDocumentId] = useState(0);
  const [documentName, setDocumentName] = useState("");

  const [operation, setOperation] = useState(Operation.CREATE);
  const [formData, setFormData] = useState<DocumentFormDataTypes>({
    document_name: "",
  });

  const nameRef = document.getElementsByName(
    "document_name"
  )[0] as HTMLInputElement;

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [createDocument, { loading: creationLoading }] = useMutation(
    AddDocument,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateName, { loading: updationLoading }] = useMutation(
    UpdateDocumentName,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const fetchEditDetails = (id: number, document_name: string) => {
    setDocumentId(id);
    setDocumentName(document_name)
    setFormData({ document_name });
    setOperation(Operation.UPDATE);
  };
  const handleRegister = () => {
    if (InstId)
      createDocument({
        variables: {
          token,
          inst_id: InstId,
          document_name: removeMoreSpace(formData.document_name),
          user_details,
        },

        refetchQueries: [
          {
            query: GetInstDocuments,
            variables: {
              token,
              inst_id: InstId,
              custom_doc_name:documentName ?documentName :EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Document Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    setFormData({
      document_name: "",
    });
    setOperation(Operation.CREATE);
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleClose = () => {
    //enableAdd and enableEdit should stay false when you close the success modal
    //   if (message.operation !== Operation.NONE && message.flag) {
    //     setDeleteModal(false);
    //   }
    setMessage({
      message: " ",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };
  const handleClear = () => {
    setFormData({
      document_name: "",
    });
    nameRef?.focus();
  };

  const handleEdit = () => {
    if (InstId)
      updateName({
        variables: {
          token,
          inst_id: InstId,
          document_id: documentId,
          document_name: removeMoreSpace(formData.document_name),
          user_details,
        },
        refetchQueries: [
          {
            query: GetInstDocuments,
            variables: {
              token,
              inst_id: InstId,
              custom_doc_name:documentName ? documentName : EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Document Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    setFormData({
      document_name: "",
    });
    setOperation(Operation.UPDATE);
    // setDocumentId(documentId);
  };
  // useEffect(() => {
  //   if (updateDocumentName) {
  //     setFormData({
  //       document_name: updateDocumentName.name,
  //     });
  //   }
  // }, [updateDocumentName]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Documents</Title>
      <Formik
        initialValues={formData}
        validationSchema={document_validation}
        onSubmit={operation === Operation.UPDATE ? handleEdit : handleRegister}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="document-create">
              <div className="document-create__frame ">
                <div className="document-create__frame--left ">
                  <Title variant="subtitle1">
                    {operation === Operation.CREATE
                      ? "Add New Document"
                      : "Update Document"}
                  </Title>
                  <Input
                    required
                    type="text"
                    LabelName="Document Descritpion"
                    values={[formData.document_name]}
                    name="document_name"
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      meta.handleChange(e);
                      handleValueChange(e);
                    }}
                  />
                </div>
                <div className="document-create__frame--right ">
                  <Title variant="subtitle1">List of Documents</Title>
                  <List fetchEditDetails={fetchEditDetails} />
                </div>
              </div>

              <Button mode="save" type="submit" />
              <Button mode="clear" onClick={handleClear} />
              <Button mode="back" onClick={() => navigate(-1)} />
            </Form>
          );
        }}
      </Formik>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Documents;

import { useEffect, useState } from "react";
import { InstitutionNode } from "../../../customhooks/useInstitutionsByCustId";
import { useLazyQuery } from "@apollo/client";
import { GetInstUserAccess } from "../queries/List";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { useParams } from "react-router-dom";
import { EmpDetails } from "../../HR/hooks/useEmployee";
export enum InstUserAccessQueryType {
  USER_ACCESS_BY_INST_ID = "USER_ACCESS_BY_INST_ID",
  USER_ACCESS_BY_EMP_ID = "USER_ACCESS_BY_EMP_ID",
}
interface GetInstUserAccessVars {
  token: string;
  id: number;
  first: number | null;
  after: string | null;
  query_type: InstUserAccessQueryType;
}
interface GetInstUserAccessDetails {
  GetInstUserAccess: {
    edges: {
      node: {
        id: number;
        pr_emp_details: EmpDetails;
        inst_details: InstitutionNode;
      };
    }[];
  };
}

const useAssignedInstbyEmpId = (
  query_type: InstUserAccessQueryType,
  empId: number
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [institutions, setInstitutions] = useState<responseType[]>([]);
  const [employees, setEmplyees] = useState<responseType[]>([]);
  const [GetDetails, { data, loading, error }] = useLazyQuery<
    GetInstUserAccessDetails,
    GetInstUserAccessVars
  >(GetInstUserAccess);

  useEffect(() => {
    if (token && query_type) {
      GetDetails({
        variables: {
          id:
            query_type === InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID
              ? empId
              : Number(InstId),
          query_type,
          token,
          after: null,
          first: null,
        },
      }).then(({ data }) => {
        if (data) {
          setInstitutions(
            data.GetInstUserAccess.edges.map((inst) => ({
              label: inst.node.inst_details.inst_name,
              value: inst.node.inst_details.id,
            }))
          );
          setEmplyees(
            data.GetInstUserAccess.edges.map((employee) => ({
              label:
                employee.node.pr_emp_details.emp_first_name +
                employee.node.pr_emp_details.emp_middle_name +
                employee.node.pr_emp_details.emp_last_name,
              value: employee.node.pr_emp_details.id,
            }))
          );
        }
      });
    }
  }, [token, query_type, empId, InstId, GetDetails]);
  return {
    MultipleInstitutions: { data, loading, error, institutions, employees },
  };
};

export default useAssignedInstbyEmpId;

import React, { useContext, useEffect, useState } from "react";
import FeeReceipt from "../../../images/FeeCollection.svg";
import ApplicationFee from "../../../images/LogApplicationFee.svg";

import RefundFee from "../../../images/LogRefundFee.svg";
import ConcessionFee from "../../../images/LogConcessionFee.svg";
import AdvanceReceipts from "../../../images/LogAdvanceReceipts.svg";
import AdvanceAdjustments from "../../../images/LogAdvanceAdjustment.svg";

import PaymentVoucher from "../../../images/LogPaymentVoucher.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import Close from "../../../images/Close.svg";
import FeeReceiptDetails from "../Modals/FeeReceiptDetails";
import { Operation, UserType } from "../../../utils/Enum.types";

import { useLazyQuery } from "@apollo/client";
import { GetAcctUserLogsCount } from "../../Accounts/queries/Vouchers/query";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import dayjs from "dayjs";

import ConcessionDetails from "../Modals/ConcessionDetails";
import VoucherDetails from "../Modals/VoucherDetails";
import DeletedVoucherDetails from "../Modals/DeletedVoucherDetails";
import DeletedVoucherDetailsForStudents from "../Modals/DeletedVoucherDetailsForstudents";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
export interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  contentComponent: React.ReactNode;
}

export enum Titles {
  Student_Receipt_entries = "Student Receipt entries",
  Contra = "Contra Voucher Entries",
  Refund_Fee = "Refund Fee",
  Concession_Fee = "Concession Fee",
  Advance_Receipts = "Receipts Entries",
  Journal = "Journal Entries",
  Payments_Voucher = "Payments Voucher",
}
interface Props {
  userid: number;
  endDate: string;
  startDate: string;
  empName: string;
}
const Accounts = ({ userid, endDate, startDate, empName }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [addedList, setAddedList] = useState(false);
  const [addedListForVouchers, setAddedListForVouchers] = useState(false);
  const [editedListForVouchers, setEditedListForVouchers] = useState(false);
  const [deletedListForVouchers, setDeletedListForVouchers] = useState(false);
  const [deletedListForStudents, setDeletedListForStudents] = useState(false);

  const [concessionList, setConcessionList] = useState(false);
  const [concessionListUpdate, setConcessionListUpdate] = useState(false);

  const [titleName, setTitleName] = useState("");
  const [editedList, setEditedList] = useState(false);

  const [deletedList, setDeletedList] = useState(false);
  const [GetAcctLogCount, { data }] = useLazyQuery(GetAcctUserLogsCount);

  const { user_details } = useLoggedInUserDetails();

  const details = [
    {
      image: `${FeeReceipt}`,
      title: Titles.Student_Receipt_entries,
      total: data
        ? data.GetAcctUserLogsCount.add_std_receipt_count +
          data.GetAcctUserLogsCount.edit_std_receipt_count +
          data.GetAcctUserLogsCount.delete_std_receipt_count
        : 0,
      added_receipts: data
        ? data.GetAcctUserLogsCount.add_std_receipt_count
        : 0,
      edited_receipts: data
        ? data.GetAcctUserLogsCount.edit_std_receipt_count
        : 0,
      deleted_receipts: data
        ? data.GetAcctUserLogsCount.delete_std_receipt_count
        : 0,
    },
    {
      image: `${ApplicationFee}`,
      title: Titles.Contra,
      total: data
        ? data.GetAcctUserLogsCount.add_contra_count +
          data.GetAcctUserLogsCount.edit_contra_count +
          data.GetAcctUserLogsCount.delete_contra_count
        : 0,
      added_receipts: data ? data.GetAcctUserLogsCount.add_contra_count : 0,
      edited_receipts: data ? data.GetAcctUserLogsCount.edit_contra_count : 0,
      deleted_receipts: data
        ? data.GetAcctUserLogsCount.delete_contra_count
        : 0,
    },

    {
      image: `${RefundFee}`,
      title: Titles.Refund_Fee,
      total: data
        ? data.GetAcctUserLogsCount.add_std_refund_count +
          data.GetAcctUserLogsCount.edit_std_refund_count +
          data.GetAcctUserLogsCount.delete_std_refund_count
        : 0,
      added_receipts: data ? data.GetAcctUserLogsCount.add_std_refund_count : 0,
      edited_receipts: data
        ? data.GetAcctUserLogsCount.edit_std_refund_count
        : 0,
      deleted_receipts: data
        ? data.GetAcctUserLogsCount.delete_std_refund_count
        : 0,
    },
    {
      image: `${ConcessionFee}`,
      title: Titles.Concession_Fee,
      total: data
        ? data.GetAcctUserLogsCount.add_std_concession_count +
          data.GetAcctUserLogsCount.edit_std_concession_count
        : 0,
      added_receipts: data
        ? data.GetAcctUserLogsCount.add_std_concession_count
        : 0,
      edited_receipts: data
        ? data.GetAcctUserLogsCount.edit_std_concession_count
        : 0,
    },
    {
      image: `${AdvanceReceipts}`,
      title: Titles.Advance_Receipts,
      total: data
        ? data.GetAcctUserLogsCount.add_receipt_count +
          data.GetAcctUserLogsCount.edit_receipt_count +
          data.GetAcctUserLogsCount.delete_receipt_count
        : 0,
      added_receipts: data ? data.GetAcctUserLogsCount.add_receipt_count : 0,
      edited_receipts: data ? data.GetAcctUserLogsCount.edit_receipt_count : 0,
      deleted_receipts: data
        ? data.GetAcctUserLogsCount.delete_receipt_count
        : 0,
    },
    {
      image: `${AdvanceAdjustments}`,
      title: Titles.Journal,
      total: data
        ? data.GetAcctUserLogsCount.add_receipt_count +
          data.GetAcctUserLogsCount.edit_receipt_count +
          data.GetAcctUserLogsCount.delete_receipt_count
        : 0,
      added_receipts: data ? data.GetAcctUserLogsCount.add_journal_count : 0,
      edited_receipts: data ? data.GetAcctUserLogsCount.edit_journal_count : 0,
      deleted_receipts: data
        ? data.GetAcctUserLogsCount.delete_journal_count
        : 0,
    },
    {
      image: `${PaymentVoucher}`,
      title: Titles.Payments_Voucher,
      total: data
        ? data.GetAcctUserLogsCount.add_payment_count +
          data.GetAcctUserLogsCount.edit_payment_count +
          data.GetAcctUserLogsCount.delete_payment_count
        : 0,
      added_receipts: data ? data.GetAcctUserLogsCount.add_payment_count : 0,
      edited_receipts: data ? data.GetAcctUserLogsCount.edit_payment_count : 0,
      deleted_receipts: data
        ? data.GetAcctUserLogsCount.delete_payment_count
        : 0,
    },
  ];
  const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    onRequestClose,
    contentComponent,
  }) => {
    return (
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        style={StudentModalStyles}
        ariaHideApp={false}
        onRequestClose={onRequestClose}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">{contentComponent}</div>
          <div className="modal-flex__image">
            <img src={Close} alt="Close" onClick={onRequestClose} />
          </div>
        </div>
      </Modal>
    );
  };
  useEffect(() => {
    if (userid && token && state.ActiveFinYr) {
      GetAcctLogCount({
        variables: {
          token,
          inst_id: InstId,
          fin_yr_id: state.ActiveFinYr.id!,
          user_details,
          input: {
            vo_start_date: dayjs(startDate).format(),
            vo_end_date: dayjs(endDate).format(),
          },
        },
      });
    }
  }, [
    state.ActiveFinYr,
    userid,
    startDate,
    endDate,
    GetAcctLogCount,
    InstId,
    token,
  ]);

  return (
    <>
      <div className="emp-logs__modules">
        {details.map((data, index) => {
          return (
            <div className="emp-logs__modules--grid" key={index}>
              <div>
                <img src={data.image} alt="/" />
                <span className="emp-logs__modules--grid--title">
                  {data.title}
                </span>
                <div>
                  <b className="emp-logs__modules--grid--total">{data.total}</b>
                </div>
              </div>

              <div
                className={
                  // data.title === Titles.Student_Interchange_Receipt
                  //   ? "emp-logs__modules--grid--list--sop"
                  //   :
                  data.title === Titles.Concession_Fee ||
                  data.title === Titles.Refund_Fee
                    ? "emp-logs__modules--grid--list--dop"
                    : "emp-logs__modules--grid--list"
                }
              >
                <div
                  className="emp-logs__modules--grid--list--add"
                  onClick={() => {
                    if (data.title === Titles.Concession_Fee) {
                      setConcessionList(!concessionList);
                    } else if (
                      data.title === Titles.Contra ||
                      data.title === Titles.Journal ||
                      data.title === Titles.Advance_Receipts ||
                      data.title === Titles.Payments_Voucher
                    ) {
                      setAddedListForVouchers(!addedListForVouchers);
                      setTitleName(data.title);
                    } else {
                      setAddedList(!addedList);
                      setTitleName(data.title);
                    }
                  }}
                >
                  <span>Add</span>
                  <b className="font-green">{data.added_receipts}</b>
                </div>

                <div
                  className="emp-logs__modules--grid--list--add"
                  onClick={() => {
                    if (data.title === Titles.Concession_Fee) {
                      setConcessionListUpdate(!concessionListUpdate);
                    } else if (
                      data.title === Titles.Contra ||
                      data.title === Titles.Journal ||
                      data.title === Titles.Advance_Receipts ||
                      data.title === Titles.Payments_Voucher
                    ) {
                      setEditedListForVouchers(!editedListForVouchers);
                      setTitleName(data.title);
                    } else {
                      setEditedList(!editedList);
                      setTitleName(data.title);
                    }
                  }}
                >
                  <span>Edit</span>
                  <b className="font-blue">{data.edited_receipts}</b>
                </div>
                {data.title === Titles.Concession_Fee ? null : (
                  <div
                    className="emp-logs__modules--grid--list--add"
                    onClick={() => {
                      if (
                        data.title === Titles.Contra ||
                        data.title === Titles.Journal ||
                        data.title === Titles.Advance_Receipts ||
                        data.title === Titles.Payments_Voucher
                      ) {
                        setDeletedListForVouchers(!editedListForVouchers);
                        setTitleName(data.title);
                      } else {
                        setDeletedListForStudents(!editedList);
                        setTitleName(data.title);
                      }
                    }}
                  >
                    <span>Delete</span>
                    <b className="font-red">{data.deleted_receipts}</b>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <CustomModal
        isOpen={addedList}
        onRequestClose={() => setAddedList(!addedList)}
        contentComponent={
          <FeeReceiptDetails
            operation={Operation.CREATE}
            setModal={setAddedList}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
            titleName={titleName}
          />
        }
      />
      <CustomModal
        isOpen={editedList}
        onRequestClose={() => setEditedList(!editedList)}
        contentComponent={
          <FeeReceiptDetails
            operation={Operation.UPDATE}
            setModal={setEditedList}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
            titleName={titleName}
          />
        }
      />
      <CustomModal
        isOpen={deletedList}
        onRequestClose={() => setDeletedList(!deletedList)}
        contentComponent={
          <FeeReceiptDetails
            operation={Operation.DELETE}
            setModal={setDeletedList}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
            titleName={titleName}
          />
        }
      />
      <CustomModal
        isOpen={addedListForVouchers}
        onRequestClose={() => setAddedListForVouchers(!addedListForVouchers)}
        contentComponent={
          <VoucherDetails
            operation={Operation.CREATE}
            setModal={setAddedListForVouchers}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
            titleName={titleName}
          />
        }
      />
      <CustomModal
        isOpen={editedListForVouchers}
        onRequestClose={() => setEditedListForVouchers(!editedListForVouchers)}
        contentComponent={
          <VoucherDetails
            operation={Operation.UPDATE}
            setModal={setEditedListForVouchers}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
            titleName={titleName}
          />
        }
      />
      <CustomModal
        isOpen={deletedListForVouchers}
        onRequestClose={() =>
          setDeletedListForVouchers(!deletedListForVouchers)
        }
        contentComponent={
          <DeletedVoucherDetails
            setModal={setDeletedListForVouchers}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
            titleName={titleName}
          />
        }
      />
      <CustomModal
        isOpen={deletedListForStudents}
        onRequestClose={() =>
          setDeletedListForStudents(!deletedListForStudents)
        }
        contentComponent={
          <DeletedVoucherDetailsForStudents
            setModal={setDeletedListForStudents}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
            titleName={titleName}
          />
        }
      />
      <CustomModal
        isOpen={concessionList}
        onRequestClose={() => setConcessionList(!concessionList)}
        contentComponent={
          <ConcessionDetails
            operation={Operation.CREATE}
            setModal={setConcessionList}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
          />
        }
      />
      <CustomModal
        isOpen={concessionListUpdate}
        onRequestClose={() => setConcessionListUpdate(!concessionListUpdate)}
        contentComponent={
          <ConcessionDetails
            operation={Operation.UPDATE}
            setModal={setConcessionListUpdate}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
          />
        }
      />

      {/* <CustomModal
        isOpen={completeList}
        onRequestClose={() => setCompleteList(!completeList)}
        contentComponent={
          <FeeReceiptDetails
            operation={Operation.VIEW}
            setModal={setCompleteList}
            endDate={endDate}
            startDate={startDate}
            userid={userid}
            empName={empName}
          />
        }
      /> */}
    </>
  );
};

export default Accounts;

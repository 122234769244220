import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/context";
import useToken from "../useToken";
import { InstIdVars } from "../../Modules/HR/Types/masterDataTypes";
import { GetAcdTeacherTree } from "../../queries/common";
enum InstTreeQueryType {
  TREE_BY_INST_ID = "TREE_BY_INST_ID",
  TREE_BY_DEPT_ID = "TREE_BY_DEPT_ID",
  TREE_BY_BRANCH_ID = "TREE_BY_BRANCH_ID",
  TREE_BY_CLASS_ID = "TREE_BY_CLASS_ID",
  TREE_BY_SEMESTER_ID = "TREE_BY_SEMESTER_ID",
  TREE_BY_SECTION_ID = "TREE_BY_SECTION_ID",
  NONE = "NONE",
}
interface AcdLevel5Tree {
  acd_level_5: string;
  acd_level_5_name: string;
  acd_level_5_desc: string;
  acd_level_5_id: number;
  teacher_assigned: boolean;
  subject_details: [
    {
      subj_code: string;
      subj_desc: string;
    }
  ];
  sub_acd_level_total_count: number;
}
interface AcdLevel4Tree {
  acd_level_4: string;
  acd_level_4_name: string;
  acd_level_4_desc: string;
  acd_level_4_id: number;
  acd_level_5_details: AcdLevel5Tree[];
  teacher_assigned: boolean;
  subject_details: [
    {
      subj_code: string;
      subj_desc: string;
    }
  ];
  sub_acd_level_total_count: number;
}
interface AcdLevel3Tree {
  acd_level_3: string;
  acd_level_3_name: string;
  acd_level_3_desc: string;
  acd_level_3_id: number;
  acd_level_4_details: AcdLevel4Tree[];
  teacher_assigned: boolean;
  subject_details: [
    {
      subj_code: string;
      subj_desc: string;
    }
  ];
  sub_acd_level_total_count: number;
}
interface AcdLevel2Tree {
  acd_level_2: string;
  acd_level_2_name: string;
  acd_level_2_desc: string;
  acd_level_2_id: number;
  acd_level_3_details: AcdLevel3Tree[];
  teacher_assigned: boolean;
  subject_details: [
    {
      subj_code: string;
      subj_desc: string;
    }
  ];
  sub_acd_level_total_count: number;
}
export interface AcdLevel1Tree {
  acd_level_1: string;
  acd_level_1_name: string;
  acd_level_1_desc: string;
  acd_level_1_id: number;

  acd_level_2_details: AcdLevel2Tree[];
  teacher_assigned: boolean;
  subject_details: [
    {
      subj_code: string;
      subj_desc: string;
    }
  ];
  sub_acd_level_total_count: number;
}
interface GetMstInstTreeData {
  GetAcdTeacherTree: AcdLevel1Tree[];
}
interface GetMstInstTreeByQueryTypeVars extends InstIdVars {
  id: number;
  query_type: InstTreeQueryType;
  pr_emp_id: number;
  acd_yr_id: number;
}

const useAcdTeacherTree = (
  deptId: number,
  branchId: number,
  classId: number,
  semId: number,
  sectionId: number
) => {
  const { InstId, empId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [id, setId] = useState(0);
  const [query_type, setQueryType] = useState<InstTreeQueryType>(
    InstTreeQueryType.NONE
  );
  useEffect(() => {
    if (sectionId) {
      setId(sectionId);
      setQueryType(InstTreeQueryType.TREE_BY_SECTION_ID);
    } else if (semId) {
      setId(semId);
      setQueryType(InstTreeQueryType.TREE_BY_SEMESTER_ID);
    } else if (classId) {
      setId(classId);
      setQueryType(InstTreeQueryType.TREE_BY_CLASS_ID);
    } else if (branchId) {
      setId(branchId);
      setQueryType(InstTreeQueryType.TREE_BY_BRANCH_ID);
    } else if (deptId) {
      setId(deptId);
      setQueryType(InstTreeQueryType.TREE_BY_DEPT_ID);
    } else {
      setId(0);
      setQueryType(InstTreeQueryType.TREE_BY_INST_ID);
    }
  }, [sectionId, semId, classId, branchId, deptId]);

  const [GetInstTree, { data, loading }] = useLazyQuery<
    GetMstInstTreeData,
    GetMstInstTreeByQueryTypeVars
  >(GetAcdTeacherTree, {
    variables: {
      inst_id: InstId ? InstId : state.InstId,
      id,
      token,
      query_type,
      pr_emp_id: empId ? Number(empId) : 0,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token && InstId && query_type !== InstTreeQueryType.NONE && empId) {
      GetInstTree();
    }
  }, [token, state.InstId, GetInstTree, InstId, id, query_type, empId]);
  return {
    data,
    loading,
  };
};

export default useAcdTeacherTree;

import { gql } from "@apollo/client";

export const ValidateMobileNumber = gql`
  query ValidateMobileNumber($mobile_number: String!) {
    ValidateMobileNumber(mobile_number: $mobile_number) {
      valid_phone_number
      user_email_id
    }
  }
`;

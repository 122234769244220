import { gql } from "@apollo/client";

export const GetLibBookClassificationsByKeywordandInstId = gql`
  query GetLibBookClassificationsByInstId(
    $keywordId: ID
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookClassificationOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookClassificationsByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            hasClassificationMasterKeywordWith: {
              or: [{ hasKeywordsWith: { or: [{ id: $keywordId }] } }]
            }
          }
        ]
      }
    ) {
      edges {
        node {
          id
          classification_no
          classification_desc
          classification_date
          classification_copies_in
          classification_copies_damaged
          classification_copies_lost
          classification_copies_avialable
          classification_copies_issued
          classification_copies_on_shelf
          book_published_year
          book_pages
          book_edition
          book_volume
          book_place
          inst_id
          lib_book_media_id
          media {
            id
            media_desc
            media_abbrevation
            media_is_active
            media_sl
          }
          category {
            id
            category_desc
            category_abbrevation
            category_is_active
            category_sl
          }
          subject {
            id
            subject_desc
            subject_classification
            subject_is_active
            subject_sl
          }
          language {
            id
            language
            language_short_desc
          }
          attribute {
            id
            book_attribute
          }
          author {
            id
            author_name
            author_address
            author_city
            author_phone
            author_email_id
          }
          publisher {
            publisher_name
            publisher_address
            publisher_city
            publisher_state
            publisher_country
            publisher_phone
            publisher_contact_person
            publisher_contact_person_tel
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

import { gql } from "@apollo/client";

export const UpdatePayRollMonthlyCalendar = gql`
  mutation UpdatePayRollMonthlyCalendar(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $cal_month: Time!
    $all_set_proceed: Boolean!
  ) {
    UpdatePayRollMonthlyCalendar(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      cal_month: $cal_month
      all_set_proceed: $all_set_proceed
    )
  }
`;

export const MarkPayRollAttendanceForEmployees = gql`
  mutation MarkPayRollAttendanceForEmployees(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $date_of_attendance: Time!
    $input: [emp_attendance!]!
  ) {
    MarkPayRollAttendanceForEmployees(
      token: $token
      inst_id: $inst_id
      user_details: $user_details

      pr_acd_yr_id: $pr_acd_yr_id
      date_of_attendance: $date_of_attendance
      input: $input
    )
  }
`;

export const UpdatePayRollAttendanceForEmployee = gql`
  mutation UpdatePayRollAttendanceForEmployee(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $date_of_attendance: Time!
    $pr_emp_id: ID!
    $from_status: String!
    $to_status: String!
  ) {
    UpdatePayRollAttendanceForEmployee(
      token: $token
      inst_id: $inst_id
      user_details: $user_details

      pr_acd_yr_id: $pr_acd_yr_id
      date_of_attendance: $date_of_attendance
      pr_emp_id: $pr_emp_id
      from_status: $from_status
      to_status: $to_status
    )
  }
`;

export const UpdateMkEmpAttFromAttTableForThisMonth = gql`
  mutation UpdateMkEmpAttFromAttTableForThisMonth(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_acd_yr_id: ID!
    $cal_month: Time!
  ) {
    UpdateMkEmpAttFromAttTableForThisMonth(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_acd_yr_id: $pr_acd_yr_id
      cal_month: $cal_month
    )
  }
`;

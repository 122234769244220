import React, { Key, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import Modal from "react-modal";
import {
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  PredefinedDataTypes,
  YesNo,
} from "../../../../utils/Enum.types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  msgType,
  optionsType,
  previewType,
  studentPreviewBasicDetails,
  studentUpdateDetails,
} from "../../../../utils/Form.types";
import PreviewTabs from "./ReservationPreviewTabs";

import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";

import { TextField } from "@mui/material";
import Home from "../../Home/Index";

import Input from "../../../../components/common/Input/Index";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../../context/context";
import {
  DEFAULT_TIME,
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  STANDARD_DATE_FORMAT,
  TODAY_DATE,
} from "../../../../utils/constants";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { ModalCustomStyles } from "../../../../styles/ModalStyles";
import {
  StudentFormLabelsType,
  StudentsTitlesType,
} from "../../../../Types/Student/json";
import { Title } from "../../../../stories/Title/Title";
import {
  handleFormEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { updateBasicDetailsSchema } from "../../../../utils/validationRules";
import { useLazyQuery, useMutation } from "@apollo/client";
import { updateStudentById } from "../../../../queries/students/mutations/update";
import { GetStudentDetails } from "../../../../queries/common";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { Label } from "../../../../stories/Label/Label";

import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
  GlobalPageConfigData,
} from "../../../../Types/configtypes";
import Close from "../../../../images/Close.svg";
import useUploadComponent from "../../../../customhooks/useUploadComponent";
import { Keys } from "../../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { GetSwConfigVariables } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
const { StudentPreviewDetails } = require("../../../../json/config.json");

const { Students } = require("../../../../json/title.json");
const { StudentUpdateLabels } = require("../../../../json/config.json");

interface Props {
  pageType: PageFor;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: Operation;
}
const BasicDataPreview = ({ pageType, setModal, type }: Props) => {
  const { InstId, studentId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const fresherRef = useRef<HTMLSelectElement>(null);
  const fresherInputRef = fresherRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const { studentData } = useStudentDatabyId();
  const [basicDetailsModal, setBasicDetailsModal] = useState(false);
  const [gender, setGender] = useState<optionsType | null>(null);
  const [studentBasicFormData, setStudentBasicFormData] =
    useState<studentPreviewBasicDetails>({
      first_name: "",
      middle_name: "",
      last_name: "",
      std_status: "",
      studying: "",
      sex: "",
      reg_number: "",
      adm_number: "",
      doa: "",
      fresher: "",
      dob: "",
      email: "",
      mobile: "",
      father_name: "",
      mother_name: "",
      class_desc: "",
      department: "",
      semester: "",
      section: "",
      fin_yr: "",
      std_sts_no: "",
      std_father_mobile: "",
      std_mother_mobile: "",
    });

  const [formData, setFormData] = useState<studentUpdateDetails>({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile: "",
    father_name: "",
    mother_name: "",
    email: "",
    std_doa: "",
    std_dob: "",
    std_fresher: "",
    std_sts_no: "",
    std_father_mobile: "",
    std_mother_mobile: "",
  });
  const { InstDetails } = useInstDetails(1);

  const {
    PredefinedData: { dropDown: Fresher },
  } = usePredefinedDataByType(PredefinedDataTypes.YESNO, EMPTY_STRING);

  const {
    PredefinedData: { dropDown: GenderOptions },
  } = usePredefinedDataByType(PredefinedDataTypes.GENDER, EMPTY_STRING);

  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME,
    InstitutionConfigurationTypes.ENABLE_LAST_NAME,
  ]);

  // eslint-disable-next-line
  const { component, file, uploadFile } = useUploadComponent(
    `${InstDetails.data?.nodes[0]?.inst_name}/students/${
      state.studentId ? state.studentId : studentId
    }/std_profile_pic/std_profile_pic`,
    Operation.CREATE,
    `${InstDetails.data?.nodes[0]?.inst_name}/students/${
      state.studentId ? state.studentId : studentId
    }/std_profile_pic/std_profile_pic`,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE
  );
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      mobile: mobileNo,
    }));
  };
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableMiddleName = true;
    let enableLastName = true;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME:
            enableMiddleName = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_LAST_NAME:
            enableLastName = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      enableMiddleName,
      enableLastName,
    };
  };
  const { enableMiddleName, enableLastName } = filterDataByConfigKey(
    configData.data?.GetSwConfigVariables!
  );
  const [updateStudent, { loading: updateLoading }] = useMutation(
    updateStudentById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues: studentUpdateDetails) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const [GetConfigdata, { data }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId ? InstId : state.InstId,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: "INSTITUTION_TYPE",
        int_value: 1,
      },
    },
  });
  const stsNoFlag = data && data.GetSwConfigVariables[0].config_string_value;

  //use state for success modal
  const updateForm = async () => {
    if (!(formData.mobile.length === 12 || formData.email)) {
      alert("Please fill any one the field, Email/Mobile No:");
      return;
    }
    if (studentId) {
      try {
        const uploadResult = await uploadFile();
        if (uploadResult) {
          updateStudent({
            variables: {
              id: studentId,
              token,
              inst_id: InstId!,
              user_details,
              input: {
                first_name: removeMoreSpace(formData.first_name),
                middle_name: removeMoreSpace(formData.middle_name),
                last_name: removeMoreSpace(formData.last_name),
                std_fresher: formData.std_fresher === YesNo.YES ? true : false,
                std_mobile:
                  formData.mobile.length === 12
                    ? `+${formData.mobile}`
                    : EMPTY_STRING,
                std_father_name: removeMoreSpace(formData.father_name),
                std_mother_name: removeMoreSpace(formData.mother_name),
                std_email: removeMoreSpace(formData.email),
                std_profile_filename:
                  file && InstDetails.data && studentData.data
                    ? `${InstDetails.data?.nodes[0]?.inst_name}/students/${studentData.data.nodes[0].id}/std_profile_pic/std_profile_pic`
                    : studentData.data?.nodes[0].std_profile_filename!,
                std_sex: gender?.value,
                std_doa: toIsoDate(formData.std_doa),
                std_dob: toIsoDate(formData.std_dob),
                std_sts_no: formData.std_sts_no,
                std_father_mobile:
                  formData.std_father_mobile.length === 12
                    ? `+${formData.std_father_mobile}`
                    : EMPTY_STRING,
                std_mother_mobile:
                  formData.std_mother_mobile.length === 12
                    ? `+${formData.std_mother_mobile}`
                    : EMPTY_STRING,
              },
            },
            refetchQueries: [
              {
                query: GetStudentDetails,
                variables: {
                  token,
                  ids: [Number(studentId)],
                },
              },
            ],
          }).then(({ data }) => {
            if (data) {
              setMessage({
                message: "Student Details Updated Successfully",
                flag: true,
                operation: Operation.UPDATE,
              });
              setBasicDetailsModal(false);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMessage({
        flag: true,
        message: "Student not found",
        operation: Operation.NONE,
      });
    }
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    const { nodes } = studentData.data || {};
    const node = nodes && nodes[0];
    if (node && !studentData.loading) {
      const {
        first_name,
        middle_name,
        last_name,
        std_status,
        std_studying,
        std_sex,
        std_reg_no,
        std_adm_no,
        std_doa,
        std_fresher,
        fin_yr,
        std_dob,
        std_email,
        std_mobile,
        std_father_name,
        std_mother_name,
        dept,
        semester,
        section,
        std_sts_no,
        std_father_mobile,
        std_mother_mobile,
      } = node;
      setStudentBasicFormData({
        first_name,
        middle_name,
        last_name,
        std_status,
        studying: std_studying ? YesNo.YES : YesNo.NO,
        sex: std_sex,
        reg_number: std_reg_no,
        adm_number: std_adm_no,
        doa:
          dayjs(std_doa).format(STANDARD_DATE_FORMAT) ===
          dayjs(DEFAULT_TIME).format(STANDARD_DATE_FORMAT)
            ? EMPTY_STRING
            : dayjs(std_doa).format(STANDARD_DATE_FORMAT),
        fresher: std_fresher ? YesNo.YES : YesNo.NO,
        fin_yr: fin_yr && fin_yr.fin_yr,
        dob:
          dayjs(std_dob).format(STANDARD_DATE_FORMAT) ===
          dayjs(DEFAULT_TIME).format(STANDARD_DATE_FORMAT)
            ? EMPTY_STRING
            : dayjs(std_dob).format(STANDARD_DATE_FORMAT),
        email: std_email,
        mobile: std_mobile,
        father_name: std_father_name,
        mother_name: std_mother_name,
        department: dept && dept.dept_desc,
        semester: semester && semester.sem_desc,
        section: section && section.section_desc,
        std_sts_no: std_sts_no ? std_sts_no : "",
        std_father_mobile,
        std_mother_mobile,
      });
    }
  }, [studentData.loading, studentData.data]);

  useEffect(() => {
    const node = studentData.data?.nodes[0];
    if (node && !studentData.loading) {
      const empMobileWithoutPlus = node.std_mobile.replaceAll("+", "");
      const {
        first_name,
        middle_name,
        last_name,
        std_father_name,
        std_mother_name,
        std_email,
        std_profile_filename,
        std_fresher,
        std_dob,
        std_doa,
        std_sex,
        std_sts_no,
      } = node;

      setFormData({
        first_name,
        middle_name,
        last_name,
        mobile: empMobileWithoutPlus,
        father_name: std_father_name,
        mother_name: std_mother_name,
        email: std_email,
        std_profile_filename,
        std_fresher: std_fresher ? YesNo.YES : YesNo.NO,
        std_dob:
          toInputStandardDate(std_dob) === toInputStandardDate(DEFAULT_TIME)
            ? EMPTY_STRING
            : toInputStandardDate(std_dob),
        std_doa:
          toInputStandardDate(std_doa) === toInputStandardDate(DEFAULT_TIME)
            ? EMPTY_STRING
            : toInputStandardDate(std_doa),
        std_sts_no: std_sts_no ? std_sts_no : "",
        std_father_mobile: node.std_father_mobile.replaceAll("+", ""),
        std_mother_mobile: node.std_mother_mobile.replaceAll("+", ""),
      });
      setGender(GenderOptions.find(({ value }) => value === std_sex) ?? null);
    }
  }, [studentData.loading, studentData.data, GenderOptions]);

  useEffect(() => {
    if (token && InstId) {
      GetConfigdata();
    }
  }, [token, GetConfigdata, InstId]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      {pageType === PageFor.GENERAL ? (
        <PreviewTabs pageType={PageFor.GENERAL} />
      ) : null}
      <div
        className={pageType === PageFor.GENERAL ? "preview" : "preview--modal"}
      >
        <StudentAcademicDetails />
        <div
          className={
            pageType === PageFor.GENERAL
              ? "preview__frame"
              : "preview__frame--modal"
          }
        >
          <div className="preview__frame--data row">
            <div className="col">
              {StudentPreviewDetails.PersonalDetails.map(
                (label: previewType, index: Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        className="preview__textfield"
                        label={label.LabelName}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        value={studentBasicFormData[
                          label.inputName
                        ]?.toString()}
                        disabled
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
            <div className="col">
              {StudentPreviewDetails.StudentInstitutionDetails.map(
                (label: previewType, index: Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        className="preview__textfield"
                        label={label.LabelName}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        value={studentBasicFormData[
                          label.inputName
                        ]?.toString()}
                        disabled
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
            <div className="col">
              {StudentPreviewDetails.FinancialYear.map(
                (label: previewType, index: Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        className="preview__textfield"
                        label={label.LabelName}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        value={studentBasicFormData[
                          label.inputName
                        ]?.toString()}
                        disabled
                      />
                    </React.Fragment>
                  );
                }
              )}
              {stsNoFlag !== undefined && stsNoFlag === "SCH"
                ? StudentPreviewDetails.STSNo.map(
                    (label: previewType, index: Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TextField
                            className="preview__textfield"
                            label={label.LabelName}
                                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                            value={studentBasicFormData[
                              label.inputName
                            ]?.toString()}
                            disabled
                          />
                        </React.Fragment>
                      );
                    }
                  )
                : null}
            </div>
          </div>
          {!state.claims!.STUDENT && (
            <>
              {pageType === PageFor.GENERAL ? (
                <>
                  <Button
                    mode="edit"
                    autoFocus
                    onClick={() => setBasicDetailsModal(!basicDetailsModal)}
                  />
                  <Button
                    mode="back"
                    onClick={() => navigate(`/${InstId}/admissions/students`)}
                  />
                </>
              ) : null}
            </>
          )}
          {pageType === PageFor.MODAL && (
            <Button
              mode="cancel"
              onClick={() => {
                setModal?.(false);
              }}
            />
          )}
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={basicDetailsModal}
        style={ModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {Students.BasicDetails.Titles.map(
                (title: StudentsTitlesType, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>{title.Update}</React.Fragment>
                  );
                }
              )}
            </Title>
            <Formik
              initialValues={formData}
              validationSchema={updateBasicDetailsSchema}
              onSubmit={updateForm}
              validateOnChange
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form className="h-30">
                    <div className="studentregistration__basic-data--update">
                      {stsNoFlag !== undefined && stsNoFlag === "SCH"
                        ? StudentUpdateLabels.Labels.filter(
                            (label: StudentFormLabelsType) => {
                              return label.inputName === "std_sts_no";
                            }
                          ).map(
                            (
                              label: StudentFormLabelsType,
                              index: React.Key
                            ) => {
                              return (
                                <>
                                  <React.Fragment key={index}>
                                    <Input
                                      onKeyDown={handleFormEvent}
                                      LabelName={label.LabelName}
                                      name={label.inputName}
                                      autoFocus={label.autoFocus}
                                      values={formData[label.inputName]}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        meta.handleChange(e);
                                        handleValueChange(e);
                                      }}
                                      type={label.dataType}
                                      maxLength={label.maxLength}
                                      className={label.className}
                                      disabled={label.disabled}
                                    />
                                  </React.Fragment>
                                </>
                              );
                            }
                          )
                        : null}

                      {StudentUpdateLabels.Labels.filter(
                        (label: StudentFormLabelsType) => {
                          return (
                            label.inputName !== "std_sts_no" &&
                            ((label.inputName === "middle_name" &&
                              enableMiddleName) ||
                              (label.inputName === "last_name" &&
                                enableLastName) ||
                              (label.inputName !== "middle_name" &&
                                label.inputName !== "last_name"))
                          );
                        }
                      ).map(
                        (label: StudentFormLabelsType, index: React.Key) => {
                          return (
                            <>
                              <React.Fragment key={index}>
                                <Input
                                  onKeyDown={handleFormEvent}
                                  LabelName={label.LabelName}
                                  name={label.inputName}
                                  autoFocus={label.autoFocus}
                                  values={formData[label.inputName]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    meta.handleChange(e);
                                    handleValueChange(e);
                                  }}
                                  required={label.required}
                                  type={label.dataType}
                                  maxLength={label.maxLength}
                                  max={toInputStandardDate(TODAY_DATE)}
                                  className={label.className}
                                  disabled={label.disabled}
                                />
                              </React.Fragment>
                            </>
                          );
                        }
                      )}
                      <div className="label-grid">
                        <Label>Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.mobile}
                          onChange={handleNumberChange}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              fresherInputRef?.focus();
                            }
                          }}
                        />
                      </div>
                      {StudentUpdateLabels.Email.map(
                        (label: StudentFormLabelsType, index: React.Key) => {
                          return (
                            <>
                              <React.Fragment key={index}>
                                <Input
                                  onKeyDown={handleFormEvent}
                                  LabelName={label.LabelName}
                                  name={label.inputName}
                                  autoFocus={label.autoFocus}
                                  values={formData[label.inputName]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    meta.handleChange(e);
                                    handleValueChange(e);
                                  }}
                                  required={label.required}
                                  type={label.dataType}
                                  maxLength={label.maxLength}
                                  max={toInputStandardDate(TODAY_DATE)}
                                  className={label.className}
                                  disabled={label.disabled}
                                />
                              </React.Fragment>
                            </>
                          );
                        }
                      )}
                      <div className="label-grid">
                        <Label>Father Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.std_father_mobile}
                          onChange={(newMobile) => {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              std_father_mobile: newMobile,
                            }));
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              fresherInputRef?.focus();
                            }
                          }}
                        />
                      </div>
                      <div className="label-grid">
                        <Label> Mother Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.std_mother_mobile}
                          onChange={(newMobile) => {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              std_mother_mobile: newMobile,
                            }));
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              fresherInputRef?.focus();
                            }
                          }}
                        />
                      </div>
                      <div className="label-grid">
                        <Label>Fresher</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={Fresher}
                          ref={fresherRef!}
                          value={
                            Fresher?.find(
                              ({ value }) => value === formData.std_fresher
                            )! ?? EMPTY_OPTIONSTYPE_OBJECT
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  std_fresher: (newValue as optionsType)
                                    ?.value!,
                                };
                              });
                            } else {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  std_fresher: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          openOnFocus
                          freeSolo
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    std_fresher: e.target.value!,
                                  };
                                });
                              }}
                              fullWidth
                              className={formClasses.formControlRoot}
                              required={formData.std_fresher ? false : true}
                            />
                          )}
                        />
                      </div>
                      <div className="label-grid">
                        <Label>Gender</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={GenderOptions}
                          value={gender}
                          isOptionEqualToValue={(option) =>
                            isOptionEqualToValue(option as optionsType, gender)
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setGender(newValue as optionsType);
                            } else {
                              setGender(null);
                            }
                          }}
                          openOnFocus
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              className={formClasses.formControlRoot}
                              required={gender ? false : true}
                            />
                          )}
                        />
                      </div>

                      {component}
                    </div>

                    <Button type="submit" mode="save" />

                    <Button
                      mode="cancel"
                      type="button"
                      onClick={() => setBasicDetailsModal(!basicDetailsModal)}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setBasicDetailsModal(!basicDetailsModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={updateLoading} />
    </>
  );
};

export default BasicDataPreview;

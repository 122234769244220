import Home from "../../Home/Index";
import PreviewTabs from "./PreviewTabs";

import React from "react";
import { TextField } from "@mui/material";
import { LabelNameProps } from "../../../../Types/Labels";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import {
  CATEGORY,
  DEPARTMENT,
  DESIGNATION,
  GRADE,
  JOB_TYPE,
} from "../../constants";
import { toStandardDate } from "../../../../utils/UtilFunctions";

const { EmployeePreview } = require("../../json/form.json");
const BasicData = () => {
  const { InstId, empId } = useParams();
  const { employeeFormData } = useEmpDetailsById();
  const navigate = useNavigate();
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();

  return (
    <>
      <Home DashBoardRequired={false} />
      <PreviewTabs />
      <div className="emp-preview">
        <div className="emp-preview__frame">
          <div className="emp-preview__frame--data row">
            <div className="col emp-preview__span-4">
              {EmployeePreview.EmployeeBasicDetails.filter(
                (label: LabelNameProps) => {
                  if (
                    (label.LabelName === DESIGNATION &&
                      USE_HR_DESIGNATION === false) ||
                    (label.LabelName === DEPARTMENT &&
                      USE_HR_DEPARTMENT === false) ||
                    (label.LabelName === CATEGORY &&
                      USE_HR_CATEGORY === false) ||
                    (label.LabelName === GRADE && USE_HR_GRADE === false) ||
                    (label.LabelName === JOB_TYPE && USE_HR_JOBTYPE === false)
                  ) {
                    return false;
                  }
                  return true;
                }
              )?.map((label: LabelNameProps, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <TextField
                      className="emp-preview__textfield"
                      label={label.LabelName}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      value={
                        label.inputName === "emp_dob" ||
                        label.inputName === "emp_doj"
                          ? toStandardDate(
                              employeeFormData[label.inputName]?.toString()
                            )
                          : employeeFormData[label.inputName]?.toString()
                      }
                      disabled
                      name={label.inputName}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <Button
            mode="edit"
            onClick={() =>
              navigate(`/${InstId}/hr/employee/1/${empId}/update/basicdata`)
            }
          />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default BasicData;

import React, { useRef, useState } from "react";
import Navbar from "../../../components/common/Navbar/Index";
import {
  Direction,
  LicenseTypes,
  Operation,
  SortBy,
  TimeComparison,
} from "../../../utils/Enum.types";

import { Title } from "../../../stories/Title/Title";
import { Form, Formik } from "formik";
import { msgType, sessionFormType } from "../../../utils/Form.types";
import { SessionsValidation } from "../../../utils/validationRules";
import { LibraryLabelNameProps } from "../../../Types/Labels";
import InputHoc from "../../../components/common/Input/Index";

import { Button } from "../../../stories/Button/Button";

import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../utils/constants";
import {
  AddAcdInstSessions,
  DeleteAcdInstSessionsById,
  ReOrderAcdInstSessions,
  UpdateAcdInstSessions,
} from "../queries/sessions/mutation";
import { useMutation } from "@apollo/client";
import { userDetails } from "../../../Types/Accounting/other";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import MessageModal from "../../../pages/MessageModal";
import SessionList from "./SessionList";
import { GetAcdInstSessions } from "../queries/sessions/list";
import { compareTimes } from "../../../utils/UtilFunctions";
import DeleteModal from "../../../pages/DeleteModal";
import { GetAcdInstSessionsList } from "../hooks/useAcdInstSessions";
import { Keys } from "../../../utils/Enum.keys";

const { SessionLabelNames } = require("../../../json/config.json");
interface AddAcdInstSessionsVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  input: {
    session_desc: string;
    start_time: string;
    end_time: string;
  };
}
interface ReOrderAcdInstSessionsData {
  ReOrderAcdInstSessions: boolean;
}
interface ReOrderAcdInstSessionsVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  input: {
    id: number;
    session_idx: number;
  }[];
}

interface UpdateAcdInstSessionsVars {
  token: string;
  inst_id: string;
  id: number;
  user_details: userDetails;
  input: {
    session_desc: string;
    start_time: string;
    end_time: string;
  };
}

interface DeleteAcdInstSessionsByIdData {
  DeleteAcdInstSessionsById: boolean;
}
interface DeleteAcdInstSessionsByIdVars {
  token: string;
  id: number;
  inst_id: string;
  user_details: userDetails;
}

interface UpdateAcdInstSessionsData {
  UpdateAcdInstSessions: boolean;
}

interface AddAcdInstSessionsData {
  AddAcdInstSessions: boolean;
}
const Index = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const emptyFormData = {
    session_desc: "",
    start_time: "",
    end_time: "",
  };
  const saveRef = useRef<HTMLButtonElement>(null);

  const session_desc_ref = document.getElementsByName(
    "session_desc"
  )[0] as HTMLInputElement;
  const [formData, setFormData] = useState<sessionFormType>(emptyFormData);
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [updateNodeId, setUpdateNodeId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const queryForGetAcdInstSession = {
    query: GetAcdInstSessions,
    variables: {
      token,
      inst_id: InstId!,
      after: null,
      first: ROWS_PER_PAGE,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.SESSION_DESC,
      },
      sessionDescContainsFold: EMPTY_STRING,
    },
  };
  const [CreateInstSessions] = useMutation<
    AddAcdInstSessionsData,
    AddAcdInstSessionsVars
  >(AddAcdInstSessions, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [UpdateInstSessions] = useMutation<
    UpdateAcdInstSessionsData,
    UpdateAcdInstSessionsVars
  >(UpdateAcdInstSessions, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteInstSessions] = useMutation<
    DeleteAcdInstSessionsByIdData,
    DeleteAcdInstSessionsByIdVars
  >(DeleteAcdInstSessionsById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [ReorderInstSessions] = useMutation<
    ReOrderAcdInstSessionsData,
    ReOrderAcdInstSessionsVars
  >(ReOrderAcdInstSessions, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [operation, setOperation] = useState(Operation.CREATE);

  const { user_details } = useLoggedInUserDetails();

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    if (
      name === "end_time" &&
      (compareTimes(formData.start_time, value) === TimeComparison.GREATER ||
        compareTimes(formData.start_time, value) === TimeComparison.EQUAL)
    ) {
      setMessage({
        flag: true,
        message: `End Time cannot be ${
          compareTimes(formData.start_time, value) === TimeComparison.GREATER
            ? "before"
            : "equal"
        } star time`,
        operation: Operation.NONE,
      });
      return;
    }
    if (name === "start_time") {
      setFormData((prevValues) => ({
        ...prevValues,
        end_time: "",
      }));
    }
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };

  const handleSubmit = () => {
    if (operation === Operation.CREATE) {
      CreateInstSessions({
        variables: {
          input: {
            ...formData,
          },
          inst_id: InstId!,
          token,
          user_details,
        },
        refetchQueries: [queryForGetAcdInstSession],
      }).then(({ data }) => {
        if (data && data.AddAcdInstSessions) {
          setMessage({
            flag: true,
            message: "Session created successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      UpdateInstSessions({
        variables: {
          input: {
            ...formData,
          },
          id: updateNodeId,
          inst_id: InstId!,
          token,
          user_details,
        },
        refetchQueries: [queryForGetAcdInstSession],
      }).then(({ data }) => {
        if (data && data.UpdateAcdInstSessions) {
          setMessage({
            flag: true,
            message: "Session updated successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setOperation(Operation.CREATE);
      handleClear();
    }
    setMessage(emptyMessageType);
  };
  const handleClear = () => {
    setFormData(emptyFormData);
    session_desc_ref.focus();
  };

  const fetchFormDataForUpdate = (
    node: GetAcdInstSessionsList,
    operationFrmTable: Operation.DELETE | Operation.UPDATE
  ) => {
    setOperation(operationFrmTable);
    setUpdateNodeId(node.id);
    if (operationFrmTable === Operation.UPDATE) {
      setFormData({
        end_time: node.end_time,
        session_desc: node.session_desc,
        start_time: node.start_time,
      });
    } else {
      setDeleteModal(!deleteModal);
    }
  };
  const handleDelete = () => {
    setDeleteModal(!deleteModal);
    DeleteInstSessions({
      variables: {
        id: updateNodeId,
        inst_id: InstId!,
        token,
        user_details,
      },
      refetchQueries: [queryForGetAcdInstSession],
    }).then(({ data }) => {
      if (data && data.DeleteAcdInstSessionsById) {
        setMessage({
          flag: true,
          message: "Deleted  the session sucessfully",
          operation: Operation.NONE,
        });
      }
    });
  };
  const hanldeSaveDragAndDrop = (localItems: GetAcdInstSessionsList[]) => {
    ReorderInstSessions({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        input: localItems.map((node, index) => ({
          id: node.id,
          session_idx: index + 1,
        })),
      },
      refetchQueries: [queryForGetAcdInstSession],
    }).then(({ data }) => {
      if (data && data.ReOrderAcdInstSessions) {
        setMessage({
          flag: true,
          message: "Reorder Successfull",
          operation: Operation.CREATE,
        });
      }
    });
  };
  return (
    <>
      <Navbar NavType={LicenseTypes.ACADEMIC} TeacherDashboard={false} />
      <Title>Sessions</Title>
      <Formik
        initialValues={formData}
        validationSchema={SessionsValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className={"sessions__frame"}>
              <div className="row g-0 sessions__frame--row">
                <div className="col h-100 booktype-left">
                  <div className="sessions__frame--title">
                    <Title variant="subtitle1">
                      {operation === Operation.CREATE ? "Create" : "Update"}
                      &nbsp;Session
                    </Title>
                  </div>
                  <div className="sessions__frame--block">
                    {SessionLabelNames.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData[label.inputName]}
                              type={label.dataType}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                              onKeyDown={(
                                e: React.KeyboardEvent<HTMLDivElement>
                              ) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  saveRef?.current?.focus();
                                }
                              }}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <SessionList
                  fetchFormDataForUpdate={fetchFormDataForUpdate}
                  hanldeSaveDragAndDrop={hanldeSaveDragAndDrop}
                />
              </div>

              <div className="sessions__buttons">
                <Button
                  mode="save"
                  type="submit"
                  buttonref={saveRef!}
                  disabled={message.flag}
                />
                <Button
                  mode="edit"
                  onClick={() =>
                    navigate(`/${InstId}/academics/activeSessions`)
                  }
                />
                <Button mode="clear" type="button" onClick={handleClear} />

                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    if (operation === Operation.CREATE) {
                      navigate(-1);
                    } else {
                      handleClear();
                      setOperation(Operation.CREATE);
                    }
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <DeleteModal
        id={updateNodeId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Index;

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DepartmentImage from "../../../../images/department.svg";
import { level_ids, tableType } from "../../../Master/Dashboard/Index";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { useLazyQuery } from "@apollo/client";
import { GetMstInstTreeData } from "../../../../customhooks/useMstInstTree";
import {
  InstTreeQueryType,
  InstTreeVars,
} from "../../../HR/Types/masterDataTypes";
import { GetMstInstTree } from "../../../../queries/common";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import usePollIntervalTime from "../../../../customhooks/usePollIntervalTime";
const TotalStudentsTable = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [table, setTable] = useState<tableType[]>([]);
  const pollIntervalTime = usePollIntervalTime();

  const [GetInstTree, { data, loading }] = useLazyQuery<
    GetMstInstTreeData,
    InstTreeVars
  >(GetMstInstTree, {
    variables: {
      inst_id: InstId!,
      token,
      id: InstId!,
      query_type: InstTreeQueryType.TREE_BY_INST_ID,
    },
    fetchPolicy: "network-only",
    pollInterval: pollIntervalTime,
  });
  const [parent, setParent] = useState(0);
  const { entry_level } = useInstitutionConfiguration();
  const [treeViewModal, setTreeViewModal] = useState(false);
  const [levelId, setLevelId] = useState(0);

  const [level_ids, setLevelIds] = useState<level_ids>({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });

  const setNextLevel = (id: number, step: number) => {
    if (data) {
      switch (step) {
        case 0:
          setTable(
            data.GetMstInstTree.map((tree_data) => ({
              level: tree_data.level_1,
              level_name: tree_data.level_1_name,
              description: tree_data.level_1_desc,
              boys_count: tree_data.boys_count,
              girls_count: tree_data.girls_count,
              id: tree_data.level_1_id,
              total_count: tree_data.total_count,
              total_seats: tree_data.total_seats,
              vacant_seats: tree_data.total_vacant_seats,
              show_seats: tree_data.show_seats_info,
            }))
          );
          setLevelIds({
            level1: 0,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 1:
          setTable(
            data.GetMstInstTree.filter(
              ({ level_1_id }) => level_1_id === id
            ).flatMap((level1_data) => {
              const { level_2_details } = level1_data;
              return level_2_details.flatMap((level_2) => {
                return {
                  level: level_2.level_2,
                  level_name: level_2.level_2_name,
                  description: level_2.level_2_desc,
                  boys_count: level_2.boys_count,
                  girls_count: level_2.girls_count,
                  total_count: level_2.total_count,
                  id: level_2.level_2_id,
                  total_seats: level_2.total_seats,
                  vacant_seats: level_2.total_vacant_seats,
                  show_seats: level_2.show_seats_info,
                };
              });
            })
          );
          setLevelIds({
            level1: id,
            level2: 0,
            level3: 0,
            level4: 0,
          });
          setParent(step);
          break;
        case 2:
          const level_2_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0].level_2_details.filter(({ level_2_id }) => level_2_id === id);
          if (level_2_tree[0].level_3_details) {
            setTable(
              level_2_tree.flatMap((level2_data) => {
                const { level_3_details } = level2_data;
                return level_3_details.flatMap((level_3) => {
                  return {
                    level: level_3.level_3,
                    level_name: level_3.level_3_name,
                    description: level_3.level_3_desc,
                    boys_count: level_3.boys_count,
                    girls_count: level_3.girls_count,
                    total_count: level_3.total_count,
                    id: level_3.level_3_id,
                    total_seats: level_3.total_seats,
                    vacant_seats: level_3.total_vacant_seats,
                    show_seats: level_3.show_seats_info,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: id,
              level3: 0,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 3:
          const level_3_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(({ level_3_id }) => level_3_id === id);

          if (level_3_tree[0].level_4_details) {
            setTable(
              level_3_tree.flatMap((level3_data) => {
                const { level_4_details } = level3_data;
                return level_4_details.flatMap((level_4) => {
                  return {
                    level: level_4.level_4,
                    level_name: level_4.level_4_name,
                    description: level_4.level_4_desc,
                    boys_count: level_4.boys_count,
                    girls_count: level_4.girls_count,
                    total_count: level_4.total_count,
                    id: level_4.level_4_id,
                    total_seats: level_4.total_seats,
                    vacant_seats: level_4.total_vacant_seats,
                    show_seats: level_4.show_seats_info,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: id,
              level4: 0,
            });
            setParent(step);
          }
          break;
        case 4:
          const level_4_tree = data.GetMstInstTree.filter(
            ({ level_1_id }) => level_1_id === level_ids.level1
          )[0]
            .level_2_details.filter(
              ({ level_2_id }) => level_2_id === level_ids.level2
            )[0]
            .level_3_details.filter(
              ({ level_3_id }) => level_3_id === level_ids.level3
            )[0]
            .level_4_details.filter(({ level_4_id }) => level_4_id === id);

          if (level_4_tree[0]?.level_5_details) {
            setTable(
              level_4_tree.flatMap((level_4_data) => {
                const { level_5_details } = level_4_data;
                return level_5_details.flatMap((level_5) => {
                  return {
                    level: level_5.level_5,
                    level_name: level_5.level_5_name,
                    description: level_5.level_5_desc,
                    boys_count: level_5.boys_count,
                    girls_count: level_5.girls_count,
                    total_count: level_5.total_count,
                    id: level_5.level_5_id,
                    total_seats: level_5.total_seats,
                    vacant_seats: level_5.total_vacant_seats,
                    show_seats: level_5.show_seats_info,
                  };
                });
              })
            );
            setLevelIds({
              level1: level_ids.level1,
              level2: level_ids.level2,
              level3: level_ids.level3,
              level4: id,
            });
            setParent(step);
          }

          break;
        default:
          break;
      }
    }
  };

  const totalCounts = table.reduce(
    (acc, item) => {
      acc.boys_count += item.boys_count;
      acc.girls_count += item.girls_count;
      acc.total_count += item.total_count;
      acc.total_seats += item.total_seats;
      acc.total_vacant_seats += item.vacant_seats;
      return acc;
    },
    {
      boys_count: 0,
      girls_count: 0,
      total_count: 0,
      total_seats: 0,
      total_vacant_seats: 0,
    }
  );
  useEffect(() => {
    if (data && !loading) {
      setTable(
        data.GetMstInstTree.map((tree_data) => ({
          level: tree_data.level_1,
          level_name: tree_data.level_1_name,
          description: tree_data.level_1_desc,
          boys_count: tree_data.boys_count,
          girls_count: tree_data.girls_count,
          id: tree_data.level_1_id,
          total_count: tree_data.total_count,
          total_seats: tree_data.total_seats,
          vacant_seats: tree_data.total_vacant_seats,
          show_seats: tree_data.show_seats_info,
        }))
      );
    }
  }, [data, loading]);
  useEffect(() => {
    if (token && InstId) {
      GetInstTree();
    }
  }, [token, GetInstTree, InstId]);
  return (
    <>
      <TableContainer className="masters-dashboard__datablock--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <img
                  src={DepartmentImage}
                  alt="/"
                  className="academics-table-logo"
                />
              </TableCell>

              <TableCell>{table.length && table[0].level_name} Name</TableCell>
              <TableCell>Boys</TableCell>
              <TableCell>Girls</TableCell>
              <TableCell>Total</TableCell>
      
              {/* {table.length && table[0].show_seats ? (
                <>
                  <TableCell>Total Seats</TableCell>
                  <TableCell>Vacant Seats</TableCell>
                </>
              ) : null} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {table?.map((data, index: number) => {
              return (
                <TableRow
                  onClick={() => {
                    if (data.level === entry_level) {
                      setTreeViewModal(!treeViewModal);
                      setLevelId(data.id);
                    } else {
                      setNextLevel(data.id, parent + 1);
                    }
                  }}
                  key={index}
                >
                  <TableCell
                    id="td-center"
                    className="masters-dashboard__datablock--table--slno"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell className="masters-dashboard__datablock--table--name">
                    {data?.description}
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className="masters-dashboard__datablock--table--count"
                  >
                    {data.boys_count}
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className="masters-dashboard__datablock--table--count"
                  >
                    {data.girls_count}
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className="masters-dashboard__datablock--table--count"
                  >
                    {data.total_count}
                  </TableCell>

              
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className="total" colSpan={2}>
                Total:
              </TableCell>
              <TableCell className="totalcount">
                {totalCounts.boys_count}
              </TableCell>
              <TableCell className="totalcount">
                {totalCounts.girls_count}
              </TableCell>
              <TableCell className="totalcount">
                {totalCounts.total_count}
              </TableCell>
              {/* {table.length && table[0].show_seats ? (
                <>
                  <TableCell className="totalcount">
                    {totalCounts.total_seats}
                  </TableCell>
                  <TableCell
                    className={`totalcount ${
                      totalCounts.total_vacant_seats < 0 ? "balance-count" : ""
                    }`}
                  >
                    {totalCounts.total_vacant_seats}
                  </TableCell>
                </>
              ) : null} */}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default TotalStudentsTable;

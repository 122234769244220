import { gql } from "@apollo/client";

export const MarkAttendanceForStudents = gql`
  mutation MarkAttendanceForStudents(
    $token: String!
    $acd_yr_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $date_of_attendance: Time!
    $emp_id: ID!
    $marked_date: Time!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [AbsentStdAttStatus!]!
  ) {
    MarkAttendanceForStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      entry_level: $entry_level
      entry_id: $entry_id
      date_of_attendance: $date_of_attendance
      emp_id: $emp_id
      marked_date: $marked_date
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const DeleteAttendanceForStudents = gql`
  mutation DeleteAttendanceForStudents(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $emp_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $date_of_attendance: Time!
  ) {
    DeleteAttendanceForStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      entry_level: $entry_level
      entry_id: $entry_id
      date_of_attendance: $date_of_attendance
      emp_id: $emp_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddAcdAttStdAbsentDetails = gql`
  mutation AddAcdAttStdAbsentDetails(
    $token: String!
    $acd_yr_id: ID!
    $student_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: AttAbsentDetails!
  ) {
    AddAcdAttStdAbsentDetails(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      student_id: $student_id
    )
  }
`;
export const UpdateAcdAttStdAbsentDetails = gql`
  mutation UpdateAcdAttStdAbsentDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $att_absent_details_id: ID!
    $input: AttAbsentDetails!
  ) {
    UpdateAcdAttStdAbsentDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      att_absent_details_id: $att_absent_details_id
    )
  }
`;
export const GetAcdStudentsAttendance = gql`
  query GetAcdStudentsAttendance($token: String!, $input: StdAttendanceQuery) {
    GetAcdStudentsAttendance(token: $token, input: $input) {
      id
      cal_month
      att_total_days_present
      att_total_days_absent_full
      att_total_days_absent_half
      att_total_days_absent
      day_2
      day_3
      day_4
      day_1
      day_5
      day_6
      day_7
      day_8
      day_9
      day_10
      day_11
      day_12
      day_13
      day_14
      day_15
      day_16
      day_17
      day_18
      day_19
      day_20
      day_21
      day_22
      day_23
      day_24
      day_25
      day_26
      day_27
      day_28
      day_29
      day_30
      day_31
      student_id
      student_details {
        first_name
        middle_name
        last_name
        std_adm_no
        std_reg_no
      }
    }
  }
`;
export const UpdateAttendanceForStudents = gql`
  mutation UpdateAttendanceForStudents(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $input: AbsentStdAttStatus!
    $from_status: String!
    $to_status: String!
    $user_details: SubscribedUser!
    $entry_level: String!
    $entry_id: ID!
  ) {
    UpdateAttendanceForStudents(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      input: $input
      from_status: $from_status
      to_status: $to_status
      user_details: $user_details
      entry_level: $entry_level
      entry_id: $entry_id
    )
  }
`;
export const AddAcdInstGeneralHolidayConfigAndDetails = gql`
  mutation AddAcdInstGeneralHolidayConfigAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $holiday_details: [CreateAcdInstGeneralHolidaysInput!]!
    $holiday_config: [CreateAcdInstHolidayConfigInput!]!
  ) {
    AddAcdInstGeneralHolidayConfigAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: {
        holiday_details: $holiday_details
        holiday_config: $holiday_config
      }
    )
  }
`;

export const AddAcdInstGeneralHolidays = gql`
  mutation AddAcdInstGeneralHolidays(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: [CreateAcdInstGeneralHolidaysInput!]!
  ) {
    AddAcdInstGeneralHolidays(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      input: $input
    )
  }
`;

export const AddAcdInstGeneralHolidayVacations = gql`
  mutation AddAcdInstGeneralHolidayVacations(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $start_date: Time!
    $end_date: Time!
    $holiday_desc: String!
  ) {
    AddAcdInstGeneralHolidayVacations(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      start_date: $start_date
      end_date: $end_date
      holiday_desc: $holiday_desc
    )
  }
`;

export const UpdateAcdInstGeneralHolidayById = gql`
  mutation UpdateAcdInstGeneralHolidayById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateAcdInstGeneralHolidaysInput!
  ) {
    UpdateAcdInstGeneralHolidayById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdateAcdInstMonthlyWorkingCalendar = gql`
  mutation UpdateAcdInstMonthlyWorkingCalendar(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $cal_month: Time!
    $all_set_proceed: Boolean!
  ) {
    UpdateAcdInstMonthlyWorkingCalendar(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      cal_month: $cal_month
      all_set_proceed: $all_set_proceed
    )
  }
`;
export const DeleteAcdInstGeneralHolidayByIds = gql`
  mutation DeleteAcdInstGeneralHolidayByIds(
    $token: String!
    $ids: [ID!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdInstGeneralHolidayByIds(
      token: $token
      ids: $ids
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteAcdInstGeneralHolidayByDate = gql`
  mutation DeleteAcdInstGeneralHolidayByDate(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $holiday_date: Time!
  ) {
    DeleteAcdInstGeneralHolidayByDate(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      holiday_date: $holiday_date
    )
  }
`;

export const DeleteAcdInstGeneralHolidayVacation = gql`
  mutation DeleteAcdInstGeneralHolidayVacation(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $start_date: Time!
    $end_date: Time!
  ) {
    DeleteAcdInstGeneralHolidayVacation(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      start_date: $start_date
      end_date: $end_date
    )
  }
`;

export const DeleteAcdInstGeneralHolidaysAndConfig = gql`
  mutation DeleteAcdInstGeneralHolidaysAndConfig(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $holiday_ids: [ID!]!
    $input: DeleteAcdInstHolidayConfig!
  ) {
    DeleteAcdInstGeneralHolidaysAndConfig(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      holiday_ids: $holiday_ids
      input: $input
    )
  }
`;

export const DeleteAcdTestNameById = gql`
  mutation DeleteAcdTestNameById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdTestNameById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteAcdTestTypeById = gql`
  mutation DeleteAcdTestTypeById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteAcdTestTypeById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const MarkAttendanceSubjMonthly = gql`
  mutation MarkAttendanceSubjMonthly(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $emp_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $date_of_attendance: Time!
    $session_id: ID!
    $subj_master_id: ID!
    $absent_student_ids: [ID!]!
    $per_std_subj_allocation: Boolean!
  ) {
    MarkAttendanceSubjMonthly(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      emp_id: $emp_id
      entry_level: $entry_level
      entry_id: $entry_id
      date_of_attendance: $date_of_attendance
      session_id: $session_id
      subj_master_id: $subj_master_id
      absent_student_ids: $absent_student_ids
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const UpdateAttendanceSubjMonthly = gql`
  mutation UpdateAttendanceSubjMonthly(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
    $student_id: ID!
    $session_id: ID!
    $subj_master_id: ID!
    $from_status: String!
    $to_status: String!
  ) {
    UpdateAttendanceSubjMonthly(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
      student_id: $student_id
      session_id: $session_id
      subj_master_id: $subj_master_id
      from_status: $from_status
      to_status: $to_status
    )
  }
`;

export const UpdateSessionAttSubjMonthly = gql`
  mutation UpdateSessionAttSubjMonthly(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $emp_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $date_of_attendance: Time!
    $marked_date: Time!
    $from_session_id: ID!
    $to_session_id: ID!
    $subj_master_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    UpdateSessionAttSubjMonthly(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
      emp_id: $emp_id
      entry_level: $entry_level
      entry_id: $entry_id
      date_of_attendance: $date_of_attendance
      marked_date: $marked_date
      from_session_id: $from_session_id
      to_session_id: $to_session_id
      subj_master_id: $subj_master_id
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

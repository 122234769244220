import { gql } from "@apollo/client";
export const ReOrderAcdGradeScale = gql`
  mutation ReOrderAcdGradeScale(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [ReOrderAcdGradeScale!]!
  ) {
    ReOrderAcdGradeScale(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const AddAcdGradeScale = gql`
  mutation AddAcdGradeScale(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: CreateAcdGradeScaleInput!
  ) {
    AddAcdGradeScale(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      acd_yr_id: $acd_yr_id
    )
  }
`;
export const UpdateAcdGradeScale = gql`
  mutation UpdateAcdGradeScale(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $input: UpdateAcdGradeScaleInput!
    $id: ID!
  ) {
    UpdateAcdGradeScale(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
      acd_yr_id: $acd_yr_id
      id: $id
    )
  }
`;
export const DeleteAcdGradeScale = gql`
  mutation DeleteAcdGradeScale(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $acd_yr_id: ID!
    $id: ID!
  ) {
    DeleteAcdGradeScale(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      acd_yr_id: $acd_yr_id
    )
  }
`;

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { PayRollSalaryMkWork } from "../Payroll/SalaryProcess/SelectMonth";
import { GetPayRollSalaryMkWorkByMonthId } from "../queries/salary/list";

import usePayRollActiveAcademicYear from "./usePayRollActiveAcademicYear";
interface GetPayRollSalaryMkWorkByMonthVars {
  token: string;
  inst_id: string;
  id: number;
}

interface GetPayRollSalaryMkWorkByMonthData {
  node: PayRollSalaryMkWork;
}

const useSalaryMkWorkByMonth = (id: string) => {
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const { InstId } = useParams();
  const { token } = useToken();
  const [GetSalaryMonth, { data, loading, error }] = useLazyQuery<
    GetPayRollSalaryMkWorkByMonthData,
    GetPayRollSalaryMkWorkByMonthVars
  >(GetPayRollSalaryMkWorkByMonthId, {
    variables: {
      token,
      inst_id: InstId!,
      id: Number(id),
    },
  });
  useEffect(() => {
    if (Number(id) > 0 && token) GetSalaryMonth();
  }, [id, GetSalaryMonth, token]);
  return {
    monthlyData: {
      data,
      loading,
      error,
    },
    pr_acd_yr: payRollActiveAcademicYear?.data?.GetPayRollAcdYrActiveByInstId,
  };
};

export default useSalaryMkWorkByMonth;

import ReactEcharts from "echarts-for-react";
interface Props {
  jsonOptions: any;
}
const SingleTestGraph = ({ jsonOptions }: Props) => {
  return (
    <div className="particular-student-results__swipable--charts">
      <ReactEcharts option={jsonOptions} />
    </div>
  );
};

export default SingleTestGraph;

import React from "react";
import { Title } from "../../../../stories/Title/Title";
import { Operation } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import Close from "../../../../images/Close.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { AcdSubjectComboDetails } from "../../../../Types/Combinations/queries";
const { Academics_Table } = require("../../json/table.json");

interface props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  data: AcdSubjectComboDetails[];
}
const View = ({ setModal, operation, data }: props) => {
  return (
    <div className={operation === Operation.DELETE ? "view-combinations__delete"  :"view-combinations"}>
      <div className="view-combinations__title">
        <Title>
          {operation === Operation.DELETE
            ? "Delete Assciated Subjects with Combinations"
            : "View Assciated Subjects with Combinations"}
        </Title>
        <img src={Close} alt="" onClick={() => setModal(false)} />
      </div>
      <b className="view-combinations__selected-catg">Combination 1</b>
      <div className={`view-combinations__tableblock`}>
        <TableContainer className="view-combinations__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Academics_Table.CustomAllocation.AssociateCombination.filter(
                  (th: TableHeaderProps) => th.labelName !== "Actions"
                ).map((th: TableHeaderProps, index: React.Key) => (
                  <TableCell key={index}>{th.labelName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((res, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className="view-combinations__table--group">
                      <TableCell colSpan={3}>
                        {index + 1}.&nbsp;{res.category_details.subj_cat_name}
                      </TableCell>
                    </TableRow>
                    {res.category_details.subj_cat_details.map((c, Cindex) => {
                      return (
                        <TableRow key={Cindex}>
                          <TableCell
                            className="view-combinations__table--slno"
                            id="td-center"
                          ></TableCell>
                          <TableCell>{c.subject_details.subj_desc}</TableCell>
                          <TableCell className="view-combinations__table--subcode">
                            {c.subject_details.subj_code}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {operation === Operation.VIEW ? (
        <Button mode="cancel" onClick={() => setModal(false)} />
      ) : null}
    </div>
  );
};

export default View;

import React, { useEffect, useState } from "react";

import { Title } from "../../../stories/Title/Title";
import Home from "../Home/Index";

import { Tab, Tabs, TextField } from "@mui/material";
import { TabPanel, a11yProps } from "../../../styles/Tabs";
import { useTheme } from "@mui/material/styles";
import Details from "./Details";
import StudentEnquiry from "../StudentEnquiry";
import { PageFor, StdEnqQueryType } from "../../../utils/Enum.types";
import StudentEnquiryFollowUp from "../FollowUp/Index";
import StudentEnquiryAllocation from "../EnquiryAllocation/Index";
import {
  sixMonthsAgo,
  toInputStandardDate,
} from "../../../utils/UtilFunctions";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import EnquiryReports from "../Reports";
export interface DashboardDetailsVars {
  token: string;
  inst_id: string;
  today_date: string;
  enq_start_date: string;
  enq_end_date: string;
  pr_emp_id?: number;
}
export interface DashboardDetailsEmpVars {
  token: string;
  inst_id: string;
  today_date: string;
  enq_start_date: string;
  enq_end_date: string;
  pr_emp_id: number;
}
export enum INCREMENT_DECREMENT {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}

const Index = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { serverDate } = useServerDateandTime();
  const [startDate, setStartDate] = useState(serverDate);
  const [endDate, setEndDate] = useState(serverDate);

  useEffect(() => {
    if (serverDate) {
      setEndDate(serverDate);
      setStartDate(sixMonthsAgo(serverDate));
    }
  }, [serverDate]);
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Enquiry</Title>
      <div className="enquiry-dashboard">
        <div className="enquiry-dashboard__menu row g-0">
          <div className="enquiry-dashboard__menu--tabs col">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Dashboard" value={0} {...a11yProps(0)} />

              <Tab
                label="Enquired Student Details"
                value={1}
                {...a11yProps(1)}
              />
              <Tab label="Follow Up's" value={2} {...a11yProps(2)} />
              <Tab label="Allocate Students" value={3} {...a11yProps(3)} />
              <Tab label="Employee Report" value={4} {...a11yProps(4)} />
            </Tabs>
          </div>

          <div className="col-4 enquiry-dashboard__menu--filters">
            <TextField
              type="date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="transport-assign-route__datablock--textfield--date"
            />
            <TextField
              type="date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              label="End Date"
              value={toInputStandardDate(endDate)}
              onChange={(e) => setEndDate(e.target.value)}
              className="transport-assign-route__datablock--textfield--date"
            />
          </div>
        </div>

        <div className="enquiry-dashboard__tabpanel">
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Details startDate={startDate} endDate={endDate} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <StudentEnquiry
              pageType={PageFor.GENERAL}
              setModalFlag={setModalFlag}
              queryType={StdEnqQueryType.STD_ENQ_ALL}
              shouldDispatchId={false}
              startDate={startDate}
              endDate={endDate}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <StudentEnquiryFollowUp startDate={startDate} endDate={endDate} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <StudentEnquiryAllocation
              queryType={StdEnqQueryType.STD_ENQ_ALL}
              startDate={startDate}
              endDate={endDate}
            />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <EnquiryReports startDate={startDate} endDate={endDate} />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default Index;

import React, { useState } from "react";
import SubjectsList from "../../LessonPlanner/SubjectsList";
import List from "./List";
import { Title } from "../../../../stories/Title/Title";
import { SubjectFormData, SubjectType } from "../../types/subject/Subject";

const Index = () => {
  const [selectedSubject, setSelectedSubject] = useState<SubjectType | null>(
    null
  );

  return (
    <>
      <div className="lesson-status">
        <Title>Lesson Status</Title>
        <div className="lesson-status__frame">
          <div className="lesson-status__frame--left">
            <SubjectsList
              selectedSubject={selectedSubject}
              setSelectedSubject={setSelectedSubject}
            />
          </div>
          <div className="lesson-status__frame--right">
            <List selectedSubject={selectedSubject} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Chip, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import {
  getFileName,
  getModifiedScrollHeight,
} from "../../../../utils/UtilFunctions";
import axios from "axios";
import Add from "../../../../images/Add.svg";
import CreateAssignment from "./Create";
import Avatar from "../../../../images/Avatar.svg";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import More from "../../../../images/DownArrow.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ChannelDataByIdData,
  ChannelDataByIdVars,
  GetChannelMessagesByData,
  GetChannelMessagesByVars,
  GetMessageData,
  GetMessageDataVariables,
  MessageEdge,
} from "../../../Channel/Types";
import {
  GetChannelMessages,
  GetMessageChannelById,
  GetMessageDataById,
} from "../../../../queries/chatapplication/list";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import {
  Direction,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import dayjs from "dayjs";
import { MessageData } from "../../../Channel/MessageList";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { msgType } from "../../../../utils/Form.types";
import { DeleteChannelMessage } from "../../../../queries/chatapplication/mutations";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import DeleteModal from "../../../../pages/DeleteModal";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import useAcdSubjectDetails from "../../hooks/useAcdSubjectDetails";
import InstDetails from "../InstDetails";
import { AttendanceDrawer } from "../../../../styles/DrawerStyles";
import { TooltipForMultipleOption } from "../../../../styles/TooltipStyles";

const ViewDetails = () => {
  const navigate = useNavigate();

  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, channelId, subjectId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const [subject_id, setSubject_Id] = useState(0);
  const [createAssignment, setCreateAssignment] = useState(false);
  const messageListRef = useRef<HTMLUListElement>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const [userList, setUserList] = useState<Map<number, string>>(new Map());
  const [operation, setOperation] = useState(Operation.CREATE);
  const [deleteId, setDeleteId] = useState(0);
  const [messageId, setMessageId] = useState(0);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const { data: subjectData } = useAcdSubjectDetails(subject_id);

  const { InstFormData } = useInstDetails(1);
  const [GetMessageUser, { data: SenderData }] = useLazyQuery<
    GetMessageData,
    GetMessageDataVariables
  >(GetMessageDataById, {
    variables: {
      token,
      ids: allMessages.map((res) => res.node.sent_user_id),
    },
  });
  const [GetMessages, { data: MessagesData, fetchMore, loading }] =
    useLazyQuery<GetChannelMessagesByData, GetChannelMessagesByVars>(
      GetChannelMessages
    );
  const [DeleteMessage, { loading: DeleteLoading }] = useMutation(
    DeleteChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        axios.get(response.data, { responseType: "blob" }).then(() => {
          setDownloadUrl((prevDownloadUrl) => {
            return prevDownloadUrl.map((messageData) => {
              if (getFileName(mediaContent) === messageData.downloadKey) {
                return { ...messageData, url: response.data };
              } else return messageData;
            });
          });
        });
      })
      .catch((err) => console.log(err));
  };
  const HandleDelete = (id: Number) => {
    DeleteMessage({
      variables: {
        token,
        msg_id: id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetChannelMessages,
          variables: {
            token,
            inst_id: InstId ? InstId : state.InstId.toString(),
            input: {
              query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
              channel_id: Number(channelId),
            },
            direction: Direction.ASC,
            last: 10,
            after: null,
            sortBy: SortBy.CREATED_AT,
            mediaContent: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Message Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
    setDeleteModal(!deleteModal);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            before: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;
            const newEdges = fetchMoreResult.GetChannelMessages.edges;
            const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );
            if (duplicateCheck.length > 0) return prevResult;
            return {
              GetChannelMessages: {
                edges: [...allMessages, ...newEdges],
                pageInfo,
                totalCount: MessagesData?.GetChannelMessages.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleFileDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }
  };
  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };
  const byDate: { [key: string]: MessageEdge[] } | undefined =
    allMessages &&
    allMessages?.reduce(
      (obj: { [key: string]: MessageEdge[] }, item: MessageEdge) => {
        const date = new Date(item?.node?.created_at);
        const dateString = dayjs(date).format("DD/MM/YY");
        if (obj[dateString]) {
          obj[dateString].push(item);
          return obj;
        }

        obj[dateString] = [{ ...item }];
        return obj;
      },
      {}
    );
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (channelId && token) {
      GetMessages({
        variables: {
          token,
          inst_id: InstId ? InstId : state.InstId.toString(),
          input: {
            query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
            channel_id: Number(channelId),
          },
          direction: Direction.ASC,
          first: ROWS_PER_PAGE,
          before: null,
          sortBy: SortBy.CREATED_AT,
          mediaContent: EMPTY_STRING,
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
          setEndCursor(data.GetChannelMessages.pageInfo.endCursor);
        }
      });
    } // eslint-disable-next-line
  }, [channelId, token, MessagesData, GetMessages]);
  useEffect(() => {
    const messageDataArray: MessageData[] = allMessages
      .filter((mess) => mess.node.msg_media_content !== "")
      .flatMap((message) => {
        const mediaContentArray = message.node.msg_media_content.split(",");
        if (mediaContentArray.length > 1) {
          return mediaContentArray.map((content, index) => {
            const mediaContent = `${
              InstFormData.inst_name
            }/channels/${channelId}/${message.node.id}_${index}.${
              content.split(".")[content.split(".").length - 1]
            }`;
            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,

              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        } else {
          return mediaContentArray.map((content) => {
            const mediaContent = `${
              InstFormData.inst_name
            }/channels/${channelId}/${message.node.id}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,

              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        }
      });
    setDownloadUrl(messageDataArray);

    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        const mediaContent = message.mediaContent;

        getDownloadUrl(message.id, mediaContent);
      });
    };

    // if (messageDataArray.some((message) => message.mediaContent !== ""))
    fetchDownloadUrls();
  }, [allMessages, InstFormData.inst_name]);
  useEffect(() => {
    if (channelId && token) {
      GetMessageUser().then(({ data }) => {
        if (data) {
          setUserList(
            new Map(
              data.nodes.map((res) => {
                if (res.first_name)
                  return [
                    res.id,
                    `${
                      res.first_name +
                      " " +
                      res.middle_name +
                      " " +
                      res.last_name
                    }`,
                  ];
                else
                  return [
                    res.id,
                    `${
                      res.emp_first_name +
                      " " +
                      res.emp_middle_name +
                      " " +
                      res.emp_last_name
                    }`,
                  ];
              })
            )
          );
        }
      });
    }
  }, [channelId, token, SenderData, GetMessageUser]);
  const [GetChannelData, { data: ChannelDetails }] = useLazyQuery<
    ChannelDataByIdData,
    ChannelDataByIdVars
  >(GetMessageChannelById, {
    variables: {
      token,
      id: Number(channelId),
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (channelId) {
      GetChannelData();
    }
  }, [channelId, GetChannelData]);

  useEffect(() => {
    if (subjectId) {
      setSubject_Id(Number(subjectId));
    }
  }, [subjectId]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        Assignments :- &nbsp;
        {subjectData && subjectData.node
          ? `${subjectData.node.subj_desc} (${subjectData.node.subj_code})`
          : ChannelDetails && ChannelDetails.node.channel_name}
      </Title>
      <div className="assignments__view-details">
        <div className="assignments__view-details--filters row g-0">
          <InstDetails />
        </div>
        <div className="assignments__view-details--data">
          <div
            className="assignments__view-details--data-block"
            onScroll={handleScroll}
          >
            <div className="assignments__view-details--body">
              <ul ref={messageListRef}>
                <div />
                <div className="assignments__view-details--messages-list--messages">
                  <ul ref={messageListRef}>
                    {Object.entries(byDate)?.map(([date, messages]) => (
                      <React.Fragment key={date}>
                        <Divider>
                          <Chip label={date} />
                        </Divider>
                        {messages.map((message, index) => {
                          const parsedContent = parseMessageContent(
                            message.node.msg_content
                          );
                          const parsedContentHeader = parseMessageContent(
                            message.node.msg_header
                          );
                          const isImage =
                            message.node.msg_media_content.includes(".png") ||
                            message.node.msg_media_content.includes(".jpeg") ||
                            message.node.msg_media_content.includes(".jpg");
                          const isPdf =
                            message.node.msg_media_content.includes(".pdf");
                          const isExcel =
                            message.node.msg_media_content.includes(".xls") ||
                            message.node.msg_media_content.includes(".xlsx");
                          const isVideo =
                            message.node.msg_media_content.includes(".mp4");
                          const id = state.claims?.EMPLOYEE
                            ? state.empLoginId
                            : state.studentId;
                          const isSentUser = id === message.node.sent_user_id;

                          const combinedById = downLoadUrl.reduce(
                            (result, item) => {
                              if (!result[item.id]) {
                                result[item.id] = [item];
                              } else {
                                result[item.id].push(item);
                              }
                              return result;
                            },
                            {} as { [id: number]: MessageData[] }
                          );

                          return (
                            <li
                              key={index}
                              className="assignments__view-details--messages-list--messages--list"
                            >
                              {!isSentUser && (
                                <img
                                  src={Avatar}
                                  alt="/"
                                  className="assignments__view-details--messages-list--image"
                                />
                              )}
                              <div
                                className={`assignments__view-details--messages-list--messages--list${
                                  isSentUser ? "--sender" : ""
                                }`}
                              >
                                <div style={{ width: "100%" }}>
                                  <div className="assignments__view-details--messages-list--flex">
                                    <b className="assignments__view-details--messages-list--name">
                                      {!isSentUser && (
                                        <>
                                          {userList.get(
                                            message.node.sent_user_id
                                          )}
                                        </>
                                      )}
                                    </b>
                                    <TooltipForMultipleOption
                                      onClick={() =>
                                        setEnableToolTipModal(
                                          !enableToolTipModal
                                        )
                                      }
                                    
                                      placement="right"
                                      title={
                                        enableToolTipModal && (
                                          <>
                                            <ul className="feeds__swipable--messages--list--tooltip--ul">
                                              <li
                                                onClick={() => {
                                                  setOperation(
                                                    Operation.UPDATE
                                                  );
                                                  setMessageId(message.node.id);
                                                  setCreateAssignment(
                                                    !createAssignment
                                                  );
                                                }}
                                                className="studentlist__table--more--fee"
                                              >
                                                <img src={Edit} alt="/" />
                                                <span> Edit Message</span>
                                              </li>
                                              <li
                                                onClick={() => {
                                                  setDeleteModal(!deleteModal);
                                                  setDeleteId(message.node.id);
                                                }}
                                                className="studentlist__table--more--fee"
                                              >
                                                <img src={Delete} alt="/" />
                                                <span> Delete Message</span>
                                              </li>
                                            </ul>
                                          </>
                                        )
                                      }
                                    >
                                      <span className="feeds__swipable--messages--list--time">
                                        {dayjs(message.node.created_at).format(
                                          "LT"
                                        )}
                                        <img src={More} alt="/" />
                                      </span>
                                    </TooltipForMultipleOption>
                                  </div>

                                  {message.node.msg_header && (
                                    <b
                                      className="assignments__view-details--messages-list--header"
                                      dangerouslySetInnerHTML={{
                                        __html: parsedContentHeader,
                                      }}
                                    />
                                  )}
                                  {message.node.msg_content && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: parsedContent,
                                      }}
                                    />
                                  )}
                                  {combinedById[message.node.id]?.map(
                                    ({ mediaContent, url }) => {
                                      if (url) {
                                        if (isImage) {
                                          return (
                                            <a key={url} href={url}>
                                              <img
                                                src={url}
                                                alt="Loading...."
                                                className="assignments__view-details--messages-list--messages--file-image"
                                              />
                                            </a>
                                          );
                                        } else if (isPdf || isExcel) {
                                          return (
                                            <a
                                              key={url}
                                              href={url}
                                              onClick={() =>
                                                handleFileDownload(url)
                                              }
                                            >
                                              {getFileName(mediaContent)}
                                            </a>
                                          );
                                        } else if (isVideo) {
                                          return (
                                            <video
                                              key={url}
                                              width="320"
                                              height="240"
                                              controls
                                              src={url}
                                            >
                                              {mediaContent}
                                              Your browser does not support the
                                              video tag.
                                            </video>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </ul>
            </div>
          </div>
          <Button onClick={() => setCreateAssignment(!createAssignment)}>
            <img src={Add} alt="" />
            Create New Assignment
          </Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <AttendanceDrawer
       
        anchor="right"
        open={createAssignment}
        onClose={() => setCreateAssignment(!createAssignment)}
      >
        <CreateAssignment
          setModalFlag={setCreateAssignment}
          operation={operation}
          messageId={messageId}
        />
      </AttendanceDrawer>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <LoadingModal flag={DeleteLoading} />
    </>
  );
};

export default ViewDetails;

import React, { useContext, useEffect } from "react";
import dayjs from "dayjs";

import { Title } from "../../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useMasterTableJson from "../../../../../json/useMasterTableJson";
import { TableHeaderProps } from "../../../../../Types/Tables";
import { useLazyQuery } from "@apollo/client";
import { ListFinancialYearVars } from "../../../../Accounts/hooks/useActiveFinancialYear";
import { FinyearByInstId } from "../../../../../queries/institution/financialyear/list/byid";
import { ListFinancialYearData } from "../../../../../Types/Accounting";
import Pointer from "../../../../../images/Pointer.svg";
import { Button } from "../../../../../stories/Button/Button";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import { useParams } from "react-router-dom";
interface Props {
  onlyTable: boolean;
  setActiveFinancialyearopenmodal?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}
const FinancialYearList = ({
  onlyTable,
  setActiveFinancialyearopenmodal,
}: Props) => {
  const { Masters_Table } = useMasterTableJson();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const [GetFinancialYears, { data: FinancialYearResponse }] = useLazyQuery<
    ListFinancialYearData,
    ListFinancialYearVars
  >(FinyearByInstId, { fetchPolicy: "network-only" });
  const financialYearList = FinancialYearResponse
    ? FinancialYearResponse.GetFinYrsByInstId
    : [];

  useEffect(() => {
    if (token && (state.InstId || InstId)) {
      GetFinancialYears({
        variables: { token, inst_id: InstId! ?? state.InstId.toString() },
      });
    }
  }, [token, state.InstId, InstId, GetFinancialYears]);
  return (
    <div className={`col ${onlyTable ? "h-100" : "booktype-right"}`}>
      <Title variant="subtitle1">
        {onlyTable ? "Financial Year List" : "Make Financial Year Active"}
      </Title>
      <div className="fin-masters-frame__tableblock">
        <TableContainer className="masterconfig__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Masters_Table.InstitutionDetails.FinancialYear.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TableCell className={th.className}>
                          {th.labelName}
                        </TableCell>
                      </React.Fragment>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {financialYearList.map((response, index: number) => (
                <React.Fragment key={index}>
                  <TableRow
                    className={
                      response.fin_is_curr_yr ? "activefinancialyear" : ""
                    }
                  >
                    <TableCell
                      id="td-center"
                      className="institution__table--slno"
                    >
                      {response.fin_is_curr_yr && (
                        <img
                          className="activefinancialyearIcon"
                          src={Pointer}
                          alt="/"
                        />
                      )}
                      {index + 1}
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="institution__table--year"
                    >
                      {response.fin_yr}
                    </TableCell>

                    <TableCell
                      id="td-center"
                      className="institution__table--date"
                    >
                      {dayjs(response.fin_st_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="institution__table--date"
                    >
                      {dayjs(response.fin_end_date).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {financialYearList.length > 0 && onlyTable === false ? (
        <Button
          mode="active"
          type="button"
          onClick={() => setActiveFinancialyearopenmodal?.(true)}
        >
          Make Financial Year Active
        </Button>
      ) : null}
    </div>
  );
};

export default FinancialYearList;

/**
 * React functional component representing a fee demand structure page.
 * Allows users to create, update, and delete fee demand structures.
 * Includes form fields and buttons for user interaction.
 * Uses custom hooks and external libraries for data fetching, state management, and UI styling.
 *
 * Example Usage:
 * <Index />
 *
 * Inputs:
 * - Branch selection
 * - Class selection
 * - Category selection
 * - Fee ledger selection
 * - Fee due date input
 * - Fee amounts input
 *
 * Flow:
 * 1. Renders a form for creating, updating, or deleting fee demand structures.
 * 2. Users can select a branch, class, and category for the fee demand structure.
 * 3. Users can add fee details by selecting a fee ledger, entering a fee due date, and specifying fee amounts for all students, new students, and existing students.
 * 4. Users can add multiple fee details and view the total fee amounts.
 * 5. Users can save the fee demand structure, triggering a mutation to create or update the fee demand structure in the database.
 * 6. Users can also delete the fee demand structure, triggering a mutation to delete the fee demand structure from the database.
 * 7. Users can view existing fee demand structures and raise student demands.
 * 8. Users can configure the fee demand page settings through a configuration modal.
 * 9. Users can extend the due date for late fee payments.
 *
 * Outputs:
 * - Created, updated, or deleted fee demand structures
 * - Viewed fee demand structures
 * - Raised student demands
 * - Configured fee demand page settings
 * - Extended due date for late fee payments
 */
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableFooter,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import Modal from "react-modal";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../stories/Button/Button";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import { Operation, PageFor, PageNumbers } from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
  FeeDetails,
  ListAccountLedgerNameData,
  ListAccountLedgerNameVars,
  ListDemandDetails,
  ListDemandDetailsData,
  ListDemandDetailsVars,
  UnMappedClassData,
  UnMappedClassVars,
} from "../../../Types/Accounting";

import { GetUnMappedClassInAccountDemandDetails } from "../../../queries/institution/classes/list/byid";
import {
  GetAccountLedgerNameById,
  GetAcctDemandDetails,
} from "../queries/FeeLedgers/query/Byid";
import {
  AddAcctDemandMainAndDetails,
  DeleteAcctDemandMain,
  updateDemandDetailsAndMainDetails,
} from "../queries/FeeLedgers/mutation/Index";
import {
  formatter,
  isOptionEqualToValue,
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import RaiseStudentDemand from "./RaiseStudentDemand";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import dayjs from "dayjs";

import Insert from "../../../images/Enter.svg";
import Settings from "../../../images/Settings.svg";
import Close from "../../../images/Close.svg";
import Delete from "../../../images/Delete.svg";
import Edit from "../../../images/EditProfile.svg";
import DownArrow from "../../../images/DownArrow.svg";

import ViewRaisedFeeDemandDetails from "./ViewRaisedFeeDemandDetails";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { FeeDemandTitleProps } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import useNotAlloactedIdFromInst from "../../../customhooks/useNotAlloactedIdFromInst";
import Home from "../Home/Index";
import FeeLedger from "../FeeLedger/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import ConfigurationSettings from "../../Master/configurations/general/Index";
import LoadingModal from "../../../pages/LoadingModal";

import useAcctLedgerData from "../hooks/useAcctLedgerData";
import { msgType, responseType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import MessageModal from "../../../pages/MessageModal";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { GetAcctDemandMainStudentDemand } from "../queries/demands/query";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { InstitutionAcctConfigurationTypes } from "../common/Enum.types";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import useFinancialYears from "../../Academics/hooks/useFinancialYears";
import DeleteModal from "../../../pages/DeleteModal";
import {
  FormAutocomplete,
  formClasses,
  RequiredAutoComplete,
} from "../../../styles/AutocompleteStyles";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";

const { AccountsTitles } = require("../json/title.json");

const Index = () => {
  const { Accounts_Table } = useAcctTableJson();
  const { state } = useContext(AppContext);
  const { format } = formatter;
  const { InstId } = useParams<{ InstId: string }>();
  const navigate = useNavigate();
  const { token } = useToken();
  const {
    financialYearResponse: { responseType },
  } = useFinancialYears();

  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.FEE_DEMAND_PAGE);
  const [selectedFinYr, setSelectedFinYr] = useState<responseType | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [viewDemandStructureModal, setViewDemandStructureModal] =
    useState(false);
  const [raiseStudentDemandModal, setRaiseStudentDemandModal] = useState(false);
  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [extendDateForLateFeeModal, setExtendDateForLateFeeModal] =
    useState(false);
  const [copyDemandModal, setCopyDemandModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [operation, setOperation] = useState(Operation.NONE);
  const [id, setId] = useState(0);
  //usestates for tablecell
  const [fee_due_date, setDueDate] = useState(TODAY_DATE);
  const [items, setItems] = useState<FeeDetails[]>([]);
  const [copyItems, setCopyItems] = useState<FeeDetails[]>([]);
  const [fee_for_all, setFeeAll] = useState<number>(0.0);
  const [fee_for_new_std, setFeeFresher] = useState<number>(0.0);
  const [fee_for_exs_std, setFeeExisting] = useState<number>(0.0);
  const [deleteModal, setDeleteModal] = useState(false);

  const [acct_ldgr_id, setAccountLedgerId] = useState<responseType | null>(
    null
  );
  const [searchData, setsearchData] = useState("");
  const [filteredFeeLedgerList, setFilteredFeeLedgerList] = useState<
    responseType[]
  >([]);

  //usestates for select tags
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);

  //usestates for select tag in copy details screen
  const [copyBranchId, setCopyBranchId] = useState(0);
  const [copyClassId, setCopyClassId] = useState(0);
  const [copyCategoryId, setcopyCategoryId] = useState(0);
  const [unmappedClasses, setUnMappedClasses] = useState<responseType[]>([]);

  //usestates for demand details
  const [demandDetails] = useState<ListDemandDetails[]>([]);
  const { user_details } = useLoggedInUserDetails();

  const { NotAllocatedCategoryId } = useNotAlloactedIdFromInst();
  const { classDropDown: classList, masterIds } = useDropdownData(
    0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    0
  );
  const { classDropDown: CopyclassList } = useDropdownData(
    0,
    copyBranchId,
    0,
    0
  );
  const { serverDate } = useServerDateandTime();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const { categoryDropDown, branchDropDown } = useInstMasterDataByInstId();
  const { configData } = useSwConfigData(
    InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS
  );

  let totalsum = 0;
  let freshersum = 0;
  let presentsum = 0;
  let copytotalsum = 0;
  let copyfreshersum = 0;
  let copypresentsum = 0;
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInput = RefsByTagName(branchRef);
  const categoyRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = RefsByTagName(categoyRef);
  const editClassRef = useRef<HTMLSelectElement>(null);
  const editClassInputRef = RefsByTagName(editClassRef);

  const classAllSelectRef = useRef<HTMLInputElement>(null);
  const classRefList = useRef<HTMLInputElement[]>([]);
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feeDescRef);
  const dueDateRef = useRef<HTMLInputElement>(null);
  const feeAllRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const fetchButtonRef = useRef<HTMLButtonElement>(null);
  const feeFresherRef = useRef<HTMLInputElement>(null);
  const feeExistingRef = useRef<HTMLInputElement>(null);
  const arrayName = items?.length ? items : copyItems;
  items.forEach(({ fee_for_all, fee_for_exs_std, fee_for_new_std }) => {
    totalsum += fee_for_all;
    freshersum += fee_for_new_std;
    presentsum += fee_for_exs_std;
  });
  copyItems.forEach(({ fee_for_all, fee_for_exs_std, fee_for_new_std }) => {
    copytotalsum += fee_for_all;
    copyfreshersum += fee_for_new_std;
    copypresentsum += fee_for_exs_std;
  });

  const [GetAcctLdgrsName, { data: AccountLedgerNameData }] = useLazyQuery<
    ListAccountLedgerNameData,
    ListAccountLedgerNameVars
  >(GetAccountLedgerNameById, {
    variables: {
      token,
      acct_ldgr_ids: arrayName?.map((item) => item.acct_ldgr_id!),
    },
  });

  //lazy queries

  const [AccountDemandDetails] = useLazyQuery<
    ListDemandDetailsData,
    ListDemandDetailsVars
  >(GetAcctDemandDetails);
  const [UnMappedClasses] = useLazyQuery<UnMappedClassData, UnMappedClassVars>(
    GetUnMappedClassInAccountDemandDetails
  );
  //mutations
  const [AddFeeDemandDetailsAndDetails, { loading: creationLoading }] =
    useMutation(AddAcctDemandMainAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const [UpdateFeeDemandDetailsAndDetails, { loading: updationLoading }] =
    useMutation(updateDemandDetailsAndMainDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const [DeleteFeeDemand, { loading: deletetionLoading }] = useMutation(
    DeleteAcctDemandMain,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleClear = () => {
    setFeeAll(0);
    setFeeFresher(0);
    setFeeExisting(0);
    setBranchId(null);
    setClassId(null);
    setCategoryId(null);
    setAccountLedgerId(null);
    setUnMappedClasses([]);
    setItems([]);
    branchInput?.focus();
  };

  const HandleItems = () => {
    const itemAlreadyExists = items.filter(
      (d) => acct_ldgr_id && d.acct_ldgr_id === acct_ldgr_id.value
    );
    if (
      state.ActiveFinYr &&
      (dayjs(fee_due_date).isBefore(state.ActiveFinYr.fin_st_date) ||
        dayjs(fee_due_date).isAfter(state.ActiveFinYr.fin_end_date))
    ) {
      alert("Due Date should be within Current Financial Year Date");
      return;
    }

    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      setAccountLedgerId(null);
      setFeeAll(0);
      setFeeExisting(0);
      setFeeFresher(0);
      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }
    const updatedData = filteredFeeLedgerList?.map((id) => {
      if (acct_ldgr_id && id.value === acct_ldgr_id.value) {
        const newData = {
          ...id,
          value: id.value,
          label: id.label,
          isChecked: !id.isChecked,
        };
        return newData;
      }
      return id;
    });
    setFilteredFeeLedgerList(updatedData);
    if (
      acct_ldgr_id === null ||
      branchId === null ||
      fee_due_date === EMPTY_STRING
    ) {
      alert("Select all fields ");
      return;
    }
    if (
      (acct_ldgr_id.value &&
        branchId.value &&
        categoryId &&
        (fee_for_all || (fee_for_exs_std && fee_for_new_std))) ||
      (fee_for_all > 0 && fee_for_new_std <= 0 && fee_for_exs_std <= 0) ||
      (fee_for_all <= 0 && (fee_for_exs_std > 0 || fee_for_new_std > 0))
    )
      setItems((items) => [
        ...items,
        {
          fee_for_exs_std,
          fee_due_date,
          fee_for_new_std,
          fee_for_all,
          acct_ldgr_id: acct_ldgr_id.value,

          sl_no: Math.random(),
        },
      ]);
    setAccountLedgerId(null);
    setDueDate(serverDate);
    setFeeExisting(0);
    setFeeFresher(0);
    setFeeAll(0);
    feeDescInputRef?.select();
    feeDescInputRef?.focus();
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    if (message.operation !== Operation.NONE && message.flag) {
      setBranchId(null);
      setUnMappedClasses([]);
      setCategoryId(null);
      setClassId(null);
      setItems([]);
      setOperation(Operation.NONE);
      setDisabled(false);
      setAccountLedgerId(null);
      classRefList.current = [];
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleItemDeleted = (ids: number) => {
    const updatedItems = items.filter((item) => item.acct_ldgr_id !== ids);
    const filteredupdatedData = filteredFeeLedgerList?.map((item) =>
      item.value === ids ? { ...item, isChecked: !item.isChecked } : item
    );
    setFilteredFeeLedgerList(filteredupdatedData);
    setItems(updatedItems);
  };

  const handleItemEdit = (
    fee_for_exs_std: number,
    dueDate: string,
    fee_for_new_std: number,
    fee_for_all: number,
    acct_ldgr_id: number
  ) => {
    const filterdData = filteredFeeLedgerList?.find(
      ({ value }) => value === acct_ldgr_id
    );
    setFeeExisting(fee_for_exs_std);
    setDueDate(dueDate);
    setFeeFresher(fee_for_new_std);
    setFeeAll(fee_for_all);
    setAccountLedgerId(filterdData!);
  };

  const HandleEditItem = (sl_number: number) => {
    if (
      state.ActiveFinYr &&
      (dayjs(fee_due_date).isBefore(state.ActiveFinYr.fin_st_date) ||
        dayjs(fee_due_date).isAfter(state.ActiveFinYr.fin_end_date))
    ) {
      alert("Due Date should be within Current Financial Year Date");
      return;
    }

    const updatedData = items.map((item) => {
      if (item.sl_no === sl_number) {
        return {
          ...item,
          fee_for_all,
          fee_for_exs_std,
          fee_due_date,
          fee_for_new_std,
          acct_ldgr_id: acct_ldgr_id ? acct_ldgr_id.value : 0,
        };
      }
      return item;
    });
    feeDescInputRef?.focus();

    setItems(updatedData);
    setFeeAll(0);
    setDueDate(serverDate);
    setFeeExisting(0);
    setFeeFresher(0);
    setEdit(!edit);
    setAccountLedgerId(null);
  };

  const HandleAll = () => {
    if (
      unmappedClasses.filter(({ isChecked }) => isChecked).length >= 1 &&
      branchId &&
      items.length >= 1
    ) {
      AddFeeDemandDetailsAndDetails({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          category_id: USE_CATEGORY_KEY
            ? categoryId && categoryId.value
            : NotAllocatedCategoryId,
          class_id: unmappedClasses
            .filter((f) => f.isChecked)
            .map(({ value }) => value),
          branch_id: branchId.value,
          acct_demand_main: {
            total_demand_new_std: freshersum,
            total_demand_exs_std: presentsum,
            total_demand_all_std: totalsum,
          },
          acct_demand_details: items.map((item, index) => ({
            acct_ldgr_id: item.acct_ldgr_id,
            fee_due_date: toIsoDate(item.fee_due_date),
            fee_for_all: item.fee_for_all ? item.fee_for_all : 0,
            fee_for_exs_std: item.fee_for_exs_std ? item.fee_for_exs_std : 0,
            fee_for_new_std: item.fee_for_new_std ? item.fee_for_new_std : 0,
            sl_no: index + 1,
          })),
        },
        refetchQueries: [
          {
            query: GetUnMappedClassInAccountDemandDetails,
            variables: {
              token,
              inst_id: InstId!,
              branch_id: branchId.value,
              category_id: USE_CATEGORY_KEY
                ? categoryId && categoryId.value
                : NotAllocatedCategoryId,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            },
          },
          {
            query: GetAcctDemandMainStudentDemand,
            variables: {
              token,
              inst_id: InstId!,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              branch_id: branchId.value,
              class_id: classId && classId.value,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Feedemand added successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const HandleUpdate = () => {
    if (
      branchId && classId
        ? USE_CATEGORY_KEY
          ? categoryId
          : true
        : items?.length >= 1
    ) {
      UpdateFeeDemandDetailsAndDetails({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          update_acct_demand_main: {
            total_demand_new_std: freshersum,
            total_demand_exs_std: presentsum,
            total_demand_all_std: totalsum,
            inst_id: InstId,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            category_id: USE_CATEGORY_KEY
              ? categoryId && categoryId.value
              : NotAllocatedCategoryId,
            class_id: classId && classId.value,
            branch_id: branchId && branchId.value,
          },
          update_acct_demand_details: items.map((item, index) => ({
            acct_ldgr_id: item.acct_ldgr_id,
            fee_due_date: toIsoDate(item.fee_due_date),
            fee_for_all: item.fee_for_all,
            fee_for_exs_std: item.fee_for_exs_std,
            fee_for_new_std: item.fee_for_new_std,
            sl_no: index + 1,
          })),
        },
        refetchQueries: [
          {
            query: GetAcctDemandDetails,
            variables: {
              token,
              branch_id: branchId && branchId.value,
              category_id: categoryId && categoryId.value,
              class_id: classId && classId.value,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "FeeDemand updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const HandleFeeDemandDelete = () => {
    setDeleteModal(!deleteModal);
    if (!items.length) {
      alert("please Select All the fields");
      branchInput?.focus();
      return;
    }
    DeleteFeeDemand({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        branch_id: masterIds.branchId,
        class_id: masterIds.classId,
        category_id: USE_CATEGORY_KEY
          ? categoryId && categoryId.value
          : NotAllocatedCategoryId,
      },
      refetchQueries: [
        {
          query: GetAcctDemandDetails,
          variables: {
            token,
            branch_id: branchId && branchId.value,
            category_id: categoryId && categoryId.value,
            class_id: classId && classId.value,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            inst_id: InstId!,
          },
        },

        {
          query: GetUnMappedClassInAccountDemandDetails,
          variables: {
            token,
            inst_id: InstId!,
            branch_id: branchId && branchId.value,
            category_id: USE_CATEGORY_KEY
              ? categoryId && categoryId.value
              : NotAllocatedCategoryId,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
        {
          query: GetAcctDemandMainStudentDemand,
          variables: {
            token,
            inst_id: InstId!,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            branch_id: branchId && branchId.value,
            class_id: classId && classId.value,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Deleted Fee Demand Structure ",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const HandleExistingItems = async () => {
    if (
      branchId &&
      classId &&
      ((USE_CATEGORY_KEY && categoryId !== null) || USE_CATEGORY_KEY === false)
    ) {
      const response = await AccountDemandDetails({
        variables: {
          token,
          branch_id: branchId.value,
          category_id: USE_CATEGORY_KEY
            ? categoryId && categoryId.value
            : NotAllocatedCategoryId,
          class_id: classId.value,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId!,
        },
      });

      if (response.data && !response.error) {
        setItems(response.data?.GetAcctDemandDetails!);
      } else {
        setItems([]);
        alert("No demand Has Been Created");
        branchInput?.focus();
        setBranchId(null);
        setClassId(null);
        setCategoryId(null);
      }
      if (response.error && !response.data) {
        setItems([]);
      }
    } else {
      if (branchId === null) {
        branchInput.focus();
      } else if (classId === null) {
        editClassInputRef.focus();
      } else if (USE_CATEGORY_KEY && categoryId === null) {
        categoryInputRef.focus();
      }
    }
  };
  const HandleCopyData = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await AccountDemandDetails({
        variables: {
          token,
          branch_id: copyBranchId,
          category_id: USE_CATEGORY_KEY
            ? copyCategoryId
            : NotAllocatedCategoryId,
          class_id: copyClassId,
          fin_yr_id: selectedFinYr ? selectedFinYr.value : 0,
          inst_id: InstId!,
        },
      });

      const acctDemandDetails = response.data?.GetAcctDemandDetails;

      if (acctDemandDetails && acctDemandDetails.length > 0) {
        setCopyItems(acctDemandDetails);
      } else {
        setMessage({
          flag: true,
          message: "No Demand Has been Created for this Class",
          operation: Operation.NONE,
        });
        setCopyItems([]);
      }
    } catch (error) {
      setMessage({
        flag: true,
        message: "An error occurred while copying data.",
        operation: Operation.NONE,
      });
      setCopyItems([]);
    }
  };

  const HandleSelectFeeType = (idx: number) => {
    const filterdData = filteredFeeLedgerList?.find(
      ({ value }) => value === idx
    );

    if (filterdData) setAccountLedgerId(filterdData!);
  };

  const handleSelectionOfUnmappedClass = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempClass = unmappedClasses.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUnMappedClasses(tempClass);
    } else {
      let tempClass = unmappedClasses.map((user) =>
        user.label === name ? { ...user, isChecked: checked } : user
      );
      setUnMappedClasses(tempClass);
    }
  };
  const AddClassRefs = (e: HTMLInputElement) => {
    if (e && !classRefList.current.includes(e)) {
      classRefList.current.push(e);
    }
  };
  useEffect(() => {
    if (
      branchId &&
      (branchId && USE_CATEGORY_KEY
        ? categoryId
        : true && operation === Operation.CREATE)
    ) {
      UnMappedClasses({
        variables: {
          token,
          inst_id: InstId!,
          branch_id: branchId.value,
          category_id: USE_CATEGORY_KEY
            ? categoryId && categoryId.value
            : NotAllocatedCategoryId,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      })
        .then(({ data }) => {
          if (data) {
            setUnMappedClasses(
              data.GetAcctDemandMainUnMappedClasses.map((data) => ({
                label: data.class_desc,
                value: data.class_id,
                isChecked: false,
              }))
            );
          } else setUnMappedClasses([]);
        })
        .catch((err) => console.log("Err", err));
    }
  }, [
    branchId,
    categoryId,
    InstId,
    UnMappedClasses,
    USE_CATEGORY_KEY,
    NotAllocatedCategoryId,
    state.ActiveFinYr,
    token,
    operation,
  ]);

  const { acctLedgers } = useAcctLedgerData(
    searchData,
    configData.data?.GetSwConfigVariables[0].config_boolean_value
      ? AcctLedgerQueryType.BILL_BOOK_ASSIGNED_STD_FEE_ACCT_LDGRS
      : AcctLedgerQueryType.STD_FEE_ACCT_LDGRS_WITHOUT_FINE,
    ROWS_PER_PAGE
  );
  useEffect(() => {
    if (acctLedgers.data && !acctLedgers.loading) {
      const filteredRes = acctLedgers.data.GetAcctLdgrs.edges.filter((el) => {
        return !items.find((element) => {
          return element.id === el.node.id;
        });
      });

      setFilteredFeeLedgerList(
        filteredRes.map((d) => ({
          label: d.node.ldgr_desc,
          value: d.node.id,
          isChecked: false,
        }))
      );
    }
  }, [acctLedgers.data, acctLedgers.loading, searchData, items]);

  useEffect(() => {
    if (disabled || operation === Operation.UPDATE) {
      if (branchRef.current && branchInput) {
        branchInput.focus();
      }
    }
  }, [disabled, branchInput, branchRef, operation]);
  useEffect(() => {
    if (arrayName.length) {
      GetAcctLdgrsName();
    }
  }, [GetAcctLdgrsName, arrayName]);
  let title =
    operation === Operation.UPDATE
      ? "- Modify"
      : Operation.CREATE === operation
      ? "- Create"
      : "- Delete";

  const { branchLabel, classLabel ,categoryLabel} = useInstLabels();
  const filteredFeeLedgerListSet: Set<number> = new Set(
    items.map(({ acct_ldgr_id }) => acct_ldgr_id)
  );

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title> Fee Demand Structure {disabled ? title : EMPTY_STRING}</Title>
        </div>
        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <div
        className={
          operation === Operation.UPDATE || operation === Operation.DELETE
            ? "row g-0 feedemand__block--edit"
            : "row g-0 feedemand__block"
        }
      >
        <div className="col-5 account-frames h-100">
          <div className="label-grid">
            <>
              <Label>{branchLabel}</Label>
              <RequiredAutoComplete
                className={formClasses.inputRoot}
                options={branchDropDown}
                disabled={!disabled || items?.length >= 1}
                ref={branchRef}
                openOnFocus
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                value={branchId}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchId)
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (operation === Operation.CREATE && branchId) {
                      USE_CATEGORY_KEY
                        ? categoryInputRef?.focus()
                        : classAllSelectRef?.current?.focus();
                    }
                    if (operation !== Operation.CREATE && branchId) {
                      editClassInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchId(null);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchId({
                      label: (newValue as responseType).label,
                      value: (newValue as responseType).value,
                      isChecked: true,
                    });
                  } else {
                    setBranchId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </>
            {operation === Operation.UPDATE ||
            operation === Operation.DELETE ? (
              <>
                <Label>{classLabel}</Label>
                <RequiredAutoComplete
                  className={formClasses.inputRoot}
                  options={classList!}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  openOnFocus
                  ref={editClassRef}
                  disabled={!disabled || items?.length >= 1}
                  value={classId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, classId)
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassId({
                        label: (newValue as responseType).label,
                        value: (newValue as responseType).value,
                        isChecked: true,
                      });
                    } else {
                      setClassId(null);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      if (classId) {
                        USE_CATEGORY_KEY
                          ? categoryInputRef?.focus()
                          : fetchButtonRef.current?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassId(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </>
            ) : null}
            {USE_CATEGORY_KEY ? (
              <>
                <Label>{categoryLabel}</Label>
                <RequiredAutoComplete
                  className={formClasses.inputRoot}
                  options={categoryDropDown}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      if (categoryId && operation === Operation.CREATE) {
                        classAllSelectRef?.current?.focus();
                      }
                      if (categoryId && operation !== Operation.CREATE) {
                        fetchButtonRef.current?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setCategoryId(null);
                    }
                  }}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, categoryId)
                  }
                  ref={categoyRef}
                  openOnFocus
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  disabled={!disabled || items?.length >= 1}
                  value={categoryId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategoryId({
                        label: (newValue as responseType).label,
                        value: (newValue as responseType).value,
                        isChecked: true,
                      });
                    } else {
                      setCategoryId(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </>
            ) : null}
          </div>

          <div className="feedemand-buttons">
            {operation !== Operation.DELETE ? (
              <Button
                mode="ledger"
                onClick={() => setFeeledgerModal(!feeLedgerModal)}
              />
            ) : null}
            {operation !== Operation.CREATE && operation !== Operation.NONE ? (
              <Button
                mode="fetch"
                onClick={HandleExistingItems}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    HandleExistingItems();
                    feeDescInputRef?.focus();
                  }
                }}
                buttonref={fetchButtonRef}
              />
            ) : null}
            {(branchId && USE_CATEGORY_KEY === false) ||
            (branchId &&
              categoryId &&
              unmappedClasses.filter((classes) => classes.isChecked).length >=
                1 &&
              operation !== Operation.DELETE) ? (
              <Button
                mode="copy-demand"
                onClick={() => {
                  setCopyDemandModal(!copyDemandModal);
                  setCopyItems([]);
                }}
              />
            ) : null}
          </div>
        </div>

        {operation !== Operation.CREATE ? null : (
          <div className="col account-frames h-100">
            <Title variant="subtitle2">
              {AccountsTitles.FeeDemand.Titles.map(
                (title: FeeDemandTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.Unmapped_Class_List}
                    </React.Fragment>
                  );
                }
              )}
            </Title>

            <div className="feedemand__categorydetails">
              <input
                type="checkbox"
                className="form-check-input"
                name="allSelect"
                disabled={items?.length >= 1}
                ref={classAllSelectRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (classRefList.current.length > 0) {
                      classRefList.current[0].focus();
                    }
                    if (classAllSelectRef.current?.checked) {
                      feeDescInputRef?.focus();
                    }
                  }
                }}
                checked={
                  !unmappedClasses?.some((user) => user?.isChecked !== true)
                }
                onChange={handleSelectionOfUnmappedClass}
              />
              <label className="all-select">All Select</label>
              <div className="feedemand__checkboxes">
                {unmappedClasses?.map((user, index) => (
                  <div className="form-check" key={index}>
                    <input
                      ref={AddClassRefs}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          const isVisited = unmappedClasses.filter(
                            ({ isChecked }) => isChecked
                          ).length;

                          if (
                            classRefList.current[index + 1] &&
                            index !==
                              unmappedClasses.filter((f) => f.isChecked)
                                .length -
                                1
                          ) {
                            classRefList.current[index + 1].focus();
                          }
                          if (
                            index ===
                              unmappedClasses.filter((f) => f.isChecked)
                                .length -
                                1 &&
                            isVisited > 0
                          ) {
                            feeDescInputRef?.focus();
                          }
                        }
                      }}
                      type="checkbox"
                      className="form-check-input"
                      name={user.label}
                      disabled={items?.length >= 1 || branchId === null}
                      checked={user?.isChecked || false}
                      onChange={handleSelectionOfUnmappedClass}
                    />
                    <label className="form-check-label ms-2">
                      {user.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          operation === Operation.UPDATE || operation === Operation.DELETE
            ? "row g-0 feedemand__tableblock--edit "
            : "row g-0 feedemand__tableblock "
        }
      >
        <TableContainer className="feedemand__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.FeeDemand.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TableCell className={th.className}>
                          {th.labelName}
                        </TableCell>
                      </React.Fragment>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {operation !== Operation.DELETE ? (
                <TableRow className="feedemand__row">
                  <TableCell id="td-center" className="feedemand__table--slno">
                    {demandDetails.length
                      ? demandDetails.length + 1
                      : items?.length + 1}
                  </TableCell>
                  <TableCell>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={
                        filteredFeeLedgerList.filter(
                          ({ value }) =>
                            (edit === false &&
                              filteredFeeLedgerListSet.has(value) === false) ||
                            edit
                        )!
                      }
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          acct_ldgr_id
                        )
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (dueDateRef.current) {
                            dueDateRef.current?.focus();
                          } else if (
                            items?.length >= 1 &&
                            acct_ldgr_id === null
                          ) {
                            saveButtonRef.current?.focus();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setAccountLedgerId(null);
                        }
                      }}
                      openOnFocus
                      autoHighlight
                      disabled={
                        operation === Operation.CREATE &&
                        unmappedClasses.filter(({ isChecked }) => isChecked)
                          .length !== 0
                          ? false
                          : operation !== Operation.UPDATE
                      }
                      value={acct_ldgr_id}
                      ref={feeDescRef}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setAccountLedgerId({
                            label: (newValue as responseType).label,
                            value: (newValue as responseType).value,
                            isChecked: true,
                          });
                        } else {
                          setAccountLedgerId(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setsearchData(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className="feedemand__table--amount">
                    <input
                      id="td-right"
                      className="feedemand__inputbase"
                      type="date"
                      ref={dueDateRef}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          feeAllRef.current?.focus();
                          feeAllRef.current?.select();
                        }
                      }}
                      min={
                        state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                          : ""
                      }
                      max={
                        state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_end_date!)
                          : ""
                      }
                      value={fee_due_date.split("T")[0]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDueDate(e.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell id="td-right" className="feedemand__table--amount">
                    <input
                      id="td-right"
                      value={fee_for_all}
                      ref={feeAllRef}
                      type="number"
                      disabled={
                        acct_ldgr_id === null ||
                        fee_for_exs_std > 0 ||
                        fee_for_new_std > 0 ||
                        fee_for_exs_std === null ||
                        fee_for_new_std === null
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (fee_for_all !== 0) {
                            buttonRef.current?.focus();
                          }
                          if (fee_for_all === 0) {
                            feeFresherRef.current?.focus();
                            feeFresherRef.current?.select();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) setFeeAll(0);
                      }}
                      className="feedemand__inputbase"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFeeAll(Number(e.target.value));
                      }}
                    />
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className=" feedemand__table--amount"
                  >
                    <input
                      type="number"
                      id="td-right"
                      className="feedemand__inputbase"
                      value={fee_for_new_std}
                      ref={feeFresherRef}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          feeExistingRef.current?.focus();
                          feeExistingRef.current?.select();
                        }
                      }}
                      disabled={
                        fee_for_all > 0 ||
                        acct_ldgr_id === null ||
                        fee_for_all === null
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFeeFresher(Number(e.target.value))
                      }
                    />
                  </TableCell>

                  <TableCell
                    id="td-right"
                    className=" feedemand__table--amount"
                  >
                    <input
                      id="td-right"
                      type="number"
                      className="feedemand__inputbase"
                      value={fee_for_exs_std}
                      disabled={
                        fee_for_all > 0 ||
                        acct_ldgr_id === null ||
                        fee_for_all === null
                      }
                      ref={feeExistingRef}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (fee_for_new_std !== 0 || fee_for_exs_std !== 0)
                            buttonRef.current?.focus();
                        }
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFeeExisting(Number(e.target.value))
                      }
                    />
                  </TableCell>
                  <TableCell
                    id="td-center"
                    className="feedemand__table--actions"
                  >
                    <button
                      ref={buttonRef}
                      onClick={(e: React.FormEvent) => {
                        edit ? HandleEditItem(id) : HandleItems();
                      }}
                    >
                      {edit ? "Save" : <img src={Insert} alt="/" />}
                    </button>
                  </TableCell>
                </TableRow>
              ) : null}

              {items?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      id="td-center"
                      className="feedemand__table--slno"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      {AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]
                        ?.id === item.acct_ldgr_id
                        ? AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]
                            ?.ldgr_desc
                        : EMPTY_STRING}
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="feedemand__table--amount"
                    >
                      {item?.fee_due_date &&
                        dayjs(item?.fee_due_date)?.format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="feedemand__table--amount"
                    >
                      {format(item.fee_for_all)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="feedemand__table--amount"
                    >
                      {format(item.fee_for_new_std)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="feedemand__table--amount"
                    >
                      {format(item.fee_for_exs_std)}
                    </TableCell>
                    <TableCell id="td-center">
                      {operation !== Operation.DELETE ? (
                        <>
                          <img
                            src={Edit}
                            alt="/"
                            onClick={() => {
                              handleItemEdit(
                                item.fee_for_exs_std,
                                item.fee_due_date,
                                item.fee_for_new_std,
                                item.fee_for_all,
                                item.acct_ldgr_id
                              );
                              setEdit(!edit);

                              HandleSelectFeeType(item.acct_ldgr_id);
                              setId(item.sl_no);
                            }}
                          />
                          <img
                            id="delete-profile"
                            src={Delete}
                            alt="/"
                            onClick={() =>
                              handleItemDeleted(items[index].acct_ldgr_id)
                            }
                          />
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={4} className="total">
                  Total :
                </TableCell>

                <TableCell className="totalcount" id="td-right">
                  {format(totalsum !== 0 ? totalsum + freshersum : freshersum)}
                </TableCell>
                <TableCell className="totalcount" id="td-right">
                  {format(totalsum !== 0 ? totalsum + presentsum : presentsum)}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <div className="feedemand__buttons g-0">
        <div>
          {operation === Operation.NONE ? (
            <>
              <Button
                autoFocus={!disabled}
                mode="addnew"
                onClick={() => {
                  setOperation(Operation.CREATE);
                  setDisabled(!disabled);
                }}
              />
              <Button
                mode="edit"
                onClick={() => {
                  setOperation(Operation.UPDATE);
                  setDisabled(!disabled);
                }}
              />
              <Button
                mode="delete"
                onClick={() => {
                  setOperation(Operation.DELETE);
                  setDisabled(!disabled);
                }}
              />
              <Button
                mode="view"
                onClick={() =>
                  setViewDemandStructureModal(!viewDemandStructureModal)
                }
              />
              <Button
                onClick={() =>
                  setExtendDateForLateFeeModal(!extendDateForLateFeeModal)
                }
                mode="extend"
              >
                Extend Date for Late fee
              </Button>
              <Button mode="back" onClick={() => navigate(-1)} />
            </>
          ) : operation === Operation.DELETE ? (
            <Button
              mode="delete"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          ) : (
            <Button
              mode="save"
              buttonref={saveButtonRef}
              onClick={() => {
                if (operation === Operation.CREATE) {
                  HandleAll();
                }
                if (operation === Operation.UPDATE) {
                  HandleUpdate();
                }
              }}
            />
          )}

          {operation !== Operation.NONE ? (
            <>
              <Button mode="clear" onClick={handleClear} />
              <Button
                mode="back"
                onClick={() => {
                  //These flags should stay false because if enableAdd flag is true the enableEdit becomes false this will cause code to misbehave
                  setOperation(Operation.NONE);
                  setDisabled(false);
                  setUnMappedClasses([]);
                  setBranchId(null);
                  setClassId(null);
                  setCategoryId(null);
                  items?.length > 0 ? setItems([]) : setItems(items);
                }}
              />
            </>
          ) : null}
        </div>
        {operation === Operation.NONE ? (
          <div>
            <Button
              className="raise-demand-btn"
              mode="demand"
              onClick={() =>
                setRaiseStudentDemandModal(!raiseStudentDemandModal)
              }
            />
          </div>
        ) : null}
      </div>

      {/* fee ledger modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setFeeledgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
            />
          </div>
        </div>
      </Modal>

      {/* Copy demand Fee Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={copyDemandModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex">
          <div className="modal-flex__data h-100">
            <Title>
              {AccountsTitles.FeeDemand.Titles.map(
                (title: FeeDemandTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.Copy_Demand}
                    </React.Fragment>
                  );
                }
              )}
            </Title>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setCopyDemandModal(!copyDemandModal)}
              className="modalclosebutton"
            />
          </div>
        </div>
        <div className="row copy-demand__options">
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              disabled={!disabled}
              ref={branchRef}
              openOnFocus
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              value={
                branchDropDown?.find(({ value }) => value === copyBranchId)! ??
                null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setCopyBranchId(Number((newValue as responseType)?.value!));
                } else {
                  setCopyBranchId(0);
                  setCopyClassId(0);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.BACKSPACE) {
                  setCopyBranchId(0);
                  setCopyClassId(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={branchLabel}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={CopyclassList}
              disabled={!disabled}
              ref={branchRef}
              openOnFocus
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              value={
                CopyclassList?.find(({ value }) => value === copyClassId)! ??
                null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setCopyClassId(Number((newValue as responseType).value!));
                } else {
                  setCopyClassId(0);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.BACKSPACE) {
                  setCopyClassId(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={classLabel}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            {USE_CATEGORY_KEY ? (
              <LabeledAutocomplete
                className={formClasses.inputRoot}
                options={categoryDropDown}
                disabled={!disabled}
                openOnFocus
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                value={
                  categoryDropDown?.find(
                    ({ value }) => value === copyCategoryId
                  )! ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setcopyCategoryId(
                      Number((newValue as responseType).value!)
                    );
                  } else {
                    setcopyCategoryId(0);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    setcopyCategoryId(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    label={categoryLabel}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            ) : null}
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={responseType}
              disabled={!disabled}
              openOnFocus
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              value={selectedFinYr}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedFinYr(newValue as responseType);
                } else {
                  setSelectedFinYr(null);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.BACKSPACE) {
                  setSelectedFinYr(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financial Year"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <Button
              mode="fetch"
              onClick={HandleCopyData}
              className="copy-demand__button"
              disabled={
                !copyCategoryId ||
                !copyClassId ||
                !copyBranchId ||
                selectedFinYr === null
              }
            />
          </div>
        </div>
        <div className="row copy-demand__tableblock">
          <TableContainer className="copy-demand__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.FeeDemand.CopyDemand.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TableCell className={th.className}>
                            {th.labelName}
                          </TableCell>
                        </React.Fragment>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {copyItems?.map((item, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell
                          id="td-center"
                          className="copy-demand__table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {AccountLedgerNameData?.GetAcctLdgrNamesByIds[index]
                            ?.id === item.acct_ldgr_id
                            ? AccountLedgerNameData?.GetAcctLdgrNamesByIds[
                                index
                              ]?.ldgr_desc
                            : ""}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="copy-demand__table--date"
                        >
                          {item?.fee_due_date &&
                            toStandardDate(item?.fee_due_date)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="copy-demand__table--fee"
                        >
                          {format(item.fee_for_all)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="copy-demand__table--fee"
                        >
                          {format(item.fee_for_new_std)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="copy-demand__table--fee"
                        >
                          {format(item.fee_for_exs_std)}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} className="total">
                    Total :
                  </TableCell>

                  <TableCell className="totalcount" id="td-right">
                    {format(
                      copytotalsum !== 0
                        ? copytotalsum + copyfreshersum
                        : copyfreshersum
                    )}
                  </TableCell>
                  <TableCell className="totalcount" id="td-right">
                    {format(
                      copytotalsum !== 0
                        ? copytotalsum + copypresentsum
                        : copypresentsum
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Button
            mode="copy-demand"
            onClick={() => {
              setCopyDemandModal(!copyDemandModal);
              setItems(copyItems);
              setCopyBranchId(0);
              setCopyClassId(0);
              setcopyCategoryId(0);
            }}
          />

          <Button
            mode="cancel"
            onClick={() => {
              setCopyDemandModal(!copyDemandModal);

              setItems([]);
            }}
          />
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.FEE_DEMAND_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* view raise student Modal */}

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewDemandStructureModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewRaisedFeeDemandDetails
              setViewDemandStructureModal={setViewDemandStructureModal}
              viewDemandStructureModal={viewDemandStructureModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setViewDemandStructureModal(!viewDemandStructureModal)
              }
            />
          </div>
        </div>
      </Modal>

      {/* raise student modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={raiseStudentDemandModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <RaiseStudentDemand
              setRaiseStudentDemandModal={setRaiseStudentDemandModal}
              raiseStudentDemandModal={raiseStudentDemandModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setRaiseStudentDemandModal(!raiseStudentDemandModal)
              }
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={creationLoading || updationLoading || deletetionLoading}
      />

      {/* Extend date for late fee Modal */}

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={extendDateForLateFeeModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Extend Date for Late Fee</Title>
            <div className="feedemand__extend-date">
              <FormControl>
                <RadioGroup
                  row
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={`${branchLabel} Wise`}
                    value={`${branchLabel} Wise`}
                  />
                  <FormControlLabel
                    value={`${classLabel} Wise`}
                    control={<Radio />}
                    label={`${classLabel} Wise`}
                  />
                </RadioGroup>
              </FormControl>
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                ref={branchRef}
                openOnFocus
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.inputRoot}
                  />
                )}
              />
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                ref={branchRef}
                openOnFocus
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.inputRoot}
                  />
                )}
              />
              <TextField
                type="date"
                label="Date"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="feedemand__extend-date--textfield"
              />
            </div>

            <Button mode="save" />
            <Button mode="clear" />
            <Button
              mode="cancel"
              onClick={() =>
                setExtendDateForLateFeeModal(!extendDateForLateFeeModal)
              }
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setExtendDateForLateFeeModal(!extendDateForLateFeeModal)
              }
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        id={0}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleFeeDemandDelete}
      />
      {/* success Message */}

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

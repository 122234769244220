import { TEXT_FONT_FAMILY } from "./DataGridTableStyles";
import { Tooltip, TooltipProps, styled } from "@mui/material";

export const enum TOOLTIP_COLORS {
  GREY = "#78716c",
  TEAL = "#4fd1c5",
  VARIABLE = "var(--level-5)",
  SKY = "#38bdf8",
  PURPLE = "#c084fc",
  PINK = "#f472b6",
  LIME = "#a3e635",
  GREEN = "#22c55e",
  FUCHSIA = "#e879f9",
  BLUE = "#3b82f6",
}
interface ToBeStyledTooltipProps extends TooltipProps {
  className?: string; // Optional className to be applied to the tooltip popover
}

const ToBeStyledTooltip = ({ className, ...props }: ToBeStyledTooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

export const CustomTooltip = styled(
  (ToBeStyledTooltip)
)(({ theme }) => ({
    fontSize: "12px  !important",
    fontFamily: "var(--text-font-family) !important", 
    fontWeight:500
  
}));
export const CustomTooltipOrganization = styled(
  (ToBeStyledTooltip)
)(({ theme }) => ({
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4) !important",
    fontSize: "14px !important",
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color) !important",
    fontWeight:400,
  
}));

export const CustomTooltipCalendar = styled(
  (ToBeStyledTooltip)
)(({ theme }) => ({
  background: "white !important",
  color: "black !important",
  fontFamily: TEXT_FONT_FAMILY,
  boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
  fontSize: 16,
  fontWeight: 500,
  width: "fit-content",
  marginTop: 0,
  
}));

export const TooltipForMultipleOption = styled(
  (ToBeStyledTooltip)
)(({ theme }) => ({
    backgroundColor: "white !important",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color) !important",
    fontWeight:400,
  
}));


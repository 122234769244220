import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import Avatar from "../../../../images/Avatar.svg";
import useAcdStudentAttendance, {
  AttendenceQueryType,
  GetAcdAttendanceMonthlyNode,
} from "../../hooks/useAcdStudentAttendance";
import {
  DateRange,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { StudentAcdNode } from "../../hooks/useAcdStudentsData";
import Close from "../../../../images/Close.svg";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStudentsAttendance } from "../../queries/holidays/mutation";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface GetAcdAttendanceMonthlyForStdsData {
  GetAcdStudentsAttendance: GetAcdAttendanceMonthlyNode[];
}
export interface GetAcdStudentsAttendanceVars {
  token: string;
  input: {
    att_query_type: string;
    entry_id: number | string;
    cal_month: string;
    inst_id: number | string;
    acd_yr_id: number | string;
    date_of_attendance: string;
    subj_master_id: number;
    per_std_subj_allocation: boolean;
  };
}

const { Academics_Table } = require("../../json/table.json");
interface Props {
  date: Date;
}
const AbsenteesList = ({ setModalFlag, date }: Props) => {
  const { token } = useToken();
  const { entryId, InstId } = useParams();
  const { firstDay } = DateRange(new Date().toString()) || {};
  const { serverDate } = useServerDateandTime();
  const { state } = useContext(AppContext);
  const [dateSelected, setDate] = useState("");

  const [GetAbsenteesData, { data, error }] = useLazyQuery<
    GetAcdAttendanceMonthlyForStdsData,
    GetAcdStudentsAttendanceVars
  >(GetAcdStudentsAttendance);
  useEffect(() => {
    if (token && dateSelected && entryId) {
      GetAbsenteesData({
        variables: {
          token,
          input: {
            att_query_type: AttendenceQueryType.ONLY_ABSENTEES,

            entry_id: entryId!,
            cal_month: toIsoDate(firstDay!),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(dateSelected),
            subj_master_id: 0,
            per_std_subj_allocation: false,
          },
        },
      });
    }
  }, [token, GetAbsenteesData, dateSelected, entryId]);

  useEffect(() => {
    if (date) {
      setDate(toInputStandardDate(toIsoDate(date.toString())));
    } else setDate(toInputStandardDate(serverDate));
  }, [serverDate, date]);

  return (
    <div className="attendance-overview__absenties-list">
      <div className="attendance-overview__absenties-list--title">
        <Title variant="subtitle1">Absenties List</Title>

        <div className="col-5 attendance-overview__absenties-list--title--close">
          <TextField
            value={dateSelected}
            className="attendance-overview__absenties-list--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            label="Date"
            type="date"
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
          <img
            src={Close}
            alt=""
            onClick={() => setModalFlag(false)}
            className="modal-close-icon"
          />
        </div>
      </div>
      <div className="attendance-overview__absenties-list--tableblock">
        <TableContainer className="attendance-overview__absenties-list--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Academics_Table.DailyActivities.AbsentiesList.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.GetAcdStudentsAttendance.length &&
                data.GetAcdStudentsAttendance.map((edge, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell id="td-center">
                        {index + 1}&nbsp;
                        <img src={Avatar} alt="/" />
                      </TableCell>
                      <TableCell>
                        {(edge.student_details as StudentAcdNode).std_adm_no}
                      </TableCell>
                      <TableCell>
                        {(edge.student_details as StudentAcdNode).std_reg_no}
                      </TableCell>
                      <TableCell>
                        {`${
                          (edge.student_details as StudentAcdNode).first_name
                        } ${
                          (edge.student_details as StudentAcdNode).middle_name
                        } ${
                          (edge.student_details as StudentAcdNode).last_name
                        }`}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AbsenteesList;

import React, { Key, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Form, Formik } from "formik";
import {
  msgType,
  responseType,
  UpdateAgentForm,
} from "../../../utils/Form.types";
import { updateAgentValidation } from "../../../utils/validationRules";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../utils/Enum.types";

import { Keys } from "../../../utils/Enum.keys";
import {
  handleFormEvent,
  handleMUISelectEvent,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import { Label } from "../../../stories/Label/Label";
import { Button } from "../../../stories/Button/Button";
import { LabelNameProps } from "../../../Types/Labels";
import Input from "../../../components/common/Input/Index";
import PhoneInput from "react-phone-input-2";
import { UpdateAgentDetails } from "../queries/Agents/mutations";
import { useMutation } from "@apollo/client";
import { userDetails } from "../../../Types/Accounting/other";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import {
  EMPTY_STRING,
  emptyMessageType,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import MessageModal from "../../../pages/MessageModal";
import { GetAgents } from "../queries/Agents/List";
import AgentOptionsAutocomplete from "./Components/AgentOptionsAutocomplete";

interface Props {
  pageType: PageFor;
}

interface UpdateAgentDetailsData {
  UpdateAgentDetails: boolean;
}

interface UpdateAgentDetailsVars {
  token: string;
  inst_id: string;
  agent_id: number;
  user_details: userDetails;
  input: {
    agent_address: string;
    agent_city: string;
    agent_state: string;
    agent_country: string;
    agent_mobile: string;
    agent_email: string;
    agent_pan_no: string;
    agent_aadhar_no: string;
    agent_gst_no: string;
    agent_bank_ac_no: string;
    agent_bank_ifsc: string;
    agent_bank_name: string;
    agent_bank_address: string;
    agent_bank_city: string;
    agent_bank_type: string;
  };
}
const { AgentFormDetails } = require("../../../json/config.json");

const UpdateAgent = ({ pageType }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
const navigate=useNavigate();
  const [agentName, setAgentName] = useState<responseType | null>(null);

  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const emptyFormData: UpdateAgentForm = {
    agent_address: "",
    agent_city: "",
    agent_state: "",
    agent_country: "",
    agent_mobile: "",
    agent_email: "",
    agent_pan_no: "",
    agent_aadhar_no: "",
    agent_gst_no: "",
    agent_bank_ac_no: "",
    agent_bank_ifsc: "",
    agent_bank_name: "",
    agent_bank_address: "",
    agent_bank_city: "",
    agent_bank_type: "",
  };
  const [formData, setFormData] = useState<UpdateAgentForm>(emptyFormData);

  //customHooks
  const [UpdateAgent] = useMutation<
    UpdateAgentDetailsData,
    UpdateAgentDetailsVars
  >(UpdateAgentDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { user_details } = useLoggedInUserDetails();

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues: UpdateAgentForm) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      agent_mobile: mobileNo,
    }));
  };

  const handleClear = () => {
    if (pageType === PageFor.GENERAL) {
      setAgentName(null);
    }
    setFormData(emptyFormData);
  };

  const handleSubmit = () => {
    if (agentName)
      UpdateAgent({
        variables: {
          agent_id: agentName?.value,
          inst_id: InstId!,
          token,
          user_details,
          input: {
            agent_address: removeMoreSpace(formData.agent_address),
            agent_bank_address: removeMoreSpace(formData.agent_bank_address),
            agent_bank_city: removeMoreSpace(formData.agent_bank_city), // Fix here
            agent_country: removeMoreSpace(formData.agent_country),
            agent_city: removeMoreSpace(formData.agent_city),
            agent_mobile:
              formData.agent_mobile.length === 12
                ? `+${formData.agent_mobile}`
                : EMPTY_STRING,
            agent_bank_name: removeMoreSpace(formData.agent_bank_name),
            agent_bank_type: removeMoreSpace(formData.agent_bank_type),
            agent_state: removeMoreSpace(formData.agent_state),
            agent_aadhar_no: formData.agent_aadhar_no,
            agent_bank_ac_no: removeMoreSpace(formData.agent_bank_ac_no),
            agent_bank_ifsc: formData.agent_bank_ifsc,
            agent_email: formData.agent_email,
            agent_gst_no: formData.agent_gst_no,
            agent_pan_no: formData.agent_pan_no,
          },
        },
        refetchQueries: [
          {
            query: GetAgents,
            variables: {
              after: null,
              first: ROWS_PER_PAGE,
              inst_id: InstId!,
              name: EMPTY_STRING,
              orderBy: {
                direction: Direction.ASC,
                field: SortBy.AGENT_NAME,
              },
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data && data.UpdateAgentDetails) {
          setMessage({
            flag: true,
            message: `Agent details for ${agentName.label} have been successfully updated.`,
            operation: Operation.CREATE,
          });
        }
      });
    else {
      setMessage({
        flag: true,
        message: "Agent Id not found",
        operation: Operation.NONE,
      });
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
    
          <Title>Update Agent Details</Title>
     

      <Formik
        initialValues={formData}
        validationSchema={updateAgentValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="update-agents">
              <div className="update-agents__form">
                <div className="update-agents__form--data">
                  {pageType === PageFor.GENERAL ? (
                    <div className="label-grid">
                      <Label variant="LabelPrimary">
                        <b>Select a Agent</b>
                      </Label>
                      <AgentOptionsAutocomplete
                        states={{
                          agentName,
                          setAgentName,
                        }}
                      />
                    </div>
                  ) : null}
                  {AgentFormDetails.Labels.map(
                    (form_elements: LabelNameProps, index: Key) => {
                      if (form_elements.dataType === "phone") {
                        return (
                          <div className="label-grid">
                            <Label>Mobile</Label>
                            <PhoneInput
                              country={"in"}
                              value={formData.agent_mobile}
                              onChange={handleNumberChange}
                              inputProps={{
                                required: false,
                              }}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  handleMUISelectEvent(e);
                                }
                              }}
                            />
                          </div>
                        );
                      }

                      return (
                        <Input
                          key={index}
                          LabelName={form_elements.LabelName}
                          values={formData[form_elements.inputName]}
                          name={form_elements.inputName}
                          onKeyDown={handleFormEvent}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                        />
                      );
                    }
                  )}
                </div>
              </div>
         
                <Button mode="save" type="submit" />
                <Button mode="clear" onClick={handleClear} />
                <Button mode="back" onClick={()=>navigate(-1)}/>
            </Form>
          );
        }}
      </Formik>

      <MessageModal
        handleClose={() => {
          handleClear();
          setMessage(emptyMessageType);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default UpdateAgent;

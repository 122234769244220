import { gql } from "@apollo/client";

export const AddPayRollCategory = gql`
  mutation AddPayRollCategory(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollCategoryInput!
  ) {
    AddPayRollCategory(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const DeletePayRollCategoryById = gql`
  mutation DeletePayRollCategoryById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollCategoryById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdatePayRollCategory = gql`
  mutation UpdatePayRollCategory(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollCategoryInput!
  ) {
    UpdatePayRollCategory(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

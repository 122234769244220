import {
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import { TableHeaderProps } from "../../../../Types/Tables";
import { DocsType } from "./EmpDocumentsUpload";
import DownloadImage from "../../../../images/DownloadImage.svg";
import DownloadPdf from "../../../../images/DownloadPdf.svg";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddEmpDocs,
  DeleteEmpDoc,
  DeleteStudentDoc,
  UpdateEmpDoc,
} from "../../../../queries/students/mutations/new";
import { Operation, PredefinedDataTypes } from "../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  emptyMessageType,
  imageFormats,
} from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import { GetEmpDocs } from "../../../../queries/students/list/byId";
import { Button } from "../../../../stories/Button/Button";
import MessageModal from "../../../../pages/MessageModal";

import {
  DeleteVars,
  GetEmpDocsData,
  GetEmpDocsVars,
} from "../../../Master/Student/Documents/Index";
import LoadingModal from "../../../../pages/LoadingModal";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { AntSwitch } from "../../../../pages/Switch";
import Input from "../../../../stories/Input/Input";
import {
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import {
  PredefinedDataByTypeData,
  PredefinedDataByTypevars,
} from "../../../../Types/Settings";
import { getPredefinedDataByType } from "../../../../queries/preDefinedData/byType";
import { handleUploadAndDownloadFile } from "../../../../utils/Upload";
interface Props {
  type: Operation;
}
const EmpDocsUploadComponent = ({ type }: Props) => {
  const { StudentDocsPreviewTable } = useMasterTableJson();
  const [documents, setDocuments] = useState<DocsType[]>([]);
  const { serverDate } = useServerDateandTime();
  const { InstDetails } = useInstDetails(1);

  const [message, setMessage] = useState(emptyMessageType);
  const { empId } = useParams();

  const { token } = useToken();
  const inputFileRefs = useRef<HTMLInputElement[]>([]);
  const [selectedIndex, setSeletedIndex] = useState(0);

  const [progress, setProgress] = useState(0);
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const { InstId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState(0);

  const [GetPredeindedData, { data, loading: PreLoading }] = useLazyQuery<
    PredefinedDataByTypeData,
    PredefinedDataByTypevars
  >(getPredefinedDataByType, {
    variables: {
      first: 50,
      token,
      type: PredefinedDataTypes.CERTIFICATE,

      after: null,
      Lname: EMPTY_STRING,
    },
  });

  useEffect(() => {
    if (token) {
      GetPredeindedData();
    }
  }, [token, GetPredeindedData]);

  const [UploadEmpDocuments, { loading }] = useMutation(AddEmpDocs, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [UpdateEmpDocuments, { loading: updateLoading }] = useMutation(
    UpdateEmpDoc,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteEmpDocs, { loading: deleteLoading }] = useMutation(
    DeleteEmpDoc,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetDocuments, { data: documentsData }] = useLazyQuery<
    GetEmpDocsData,
    GetEmpDocsVars
  >(GetEmpDocs, {
    variables: {
      pr_emp_id: Number(empId!),
      token,
    },
  });

  useEffect(() => {
    if (token && InstId && empId && !PreLoading && data) {
      const newDocuments = data.GetPredefinedDataByType.edges.map(
        (item, index) => ({
          document_name: item.node.value1,
          doc_idx: index,
          docId: 0,
          preview: null,
          fromDb: false,
          fromDbUrl: "",
          dateCollected: serverDate,
          isUpdatedFlag: false,
        })
      );

      setDocuments(newDocuments);
    }
  }, [data, PreLoading, serverDate]);

  useEffect(() => {
    if (token && InstId && empId && documents.length) {
      GetDocuments().then(({ data }) => {
        if (data && data.GetEmpDocs && data.GetEmpDocs.length) {
          setDocuments(
            documents.map((item) => {
              const foundDocType = data.GetEmpDocs.find(
                (doc) => doc.emp_doc_type === item.document_name
              );
              if (foundDocType) {
                return {
                  ...item,
                  docId: foundDocType.id,
                  preview: null,
                  fromDb: true,
                  fromDbUrl: foundDocType.emp_doc_filename,
                  collected: foundDocType.emp_doc_collected,
                  isOriginal: foundDocType.emp_doc_original,
                  isUpdatedFlag:
                    foundDocType.emp_doc_collected ||
                    foundDocType.emp_doc_original ||
                    foundDocType.emp_doc_filename
                      ? true
                      : false,
                  dateCollected: foundDocType.date_of_collection,
                };
              } else return item;
            })
          );
        }
      });
    }
  }, [
    token,
    InstId,
    GetDocuments,
    documentsData,
    state.studentId,
    documents.length,
    empId,
  ]);

  const viewPreview = (document: DocsType) => {
    const check: Map<string, string> = new Map([
      ["image", DownloadImage],
      ["pdf", DownloadPdf],
    ]);

    if (document.preview) {
      const file_ext =
        document.preview.name.split(".")[
          document.preview.name.split(".").length - 1
        ];
      return (
        <div className="preview">
          {imageFormats.includes(file_ext) ? (
            <img src={check.get("image")} alt="/" />
          ) : (
            <img src={check.get("pdf")} alt="/" />
          )}
          <label>{document.preview.name}</label>
        </div>
      );
    } else if (document.fromDb && document.fromDbUrl) {
      const file_ext =
        document.fromDbUrl.split(".")[document.fromDbUrl.split(".").length - 1];
      const file_name =
        document.fromDbUrl.split("/")[document.fromDbUrl.split("/").length - 1];

      return (
        <div className="preview">
          {imageFormats.includes(file_ext) ? (
            <img src={check.get("image")} alt="/" />
          ) : (
            <img src={check.get("pdf")} alt="/" />
          )}{" "}
          <label>{file_name}</label>
        </div>
      );
    }
  };

  const handleSubmit = () => {
    const updatedDocuments = documents.find(
      (data) => data.doc_idx === selectedDocument
    )?.isUpdatedFlag;

    if (updatedDocuments) {
      UpdateEmpDocuments({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          document_id:
            documents &&
            documents.find((res) => res.doc_idx === selectedDocument)?.docId,
          input: {
            emp_doc_filename:
              (documents &&
                documents.find((res) => res.doc_idx === selectedDocument)
                  ?.fromDbUrl) ||
              EMPTY_STRING,

            emp_doc_collected:
              documents &&
              documents.find((res) => res.doc_idx === selectedDocument)
                ?.collected
                ? true
                : false,
            emp_doc_original:
              documents &&
              documents.find((res) => res.doc_idx === selectedDocument)
                ?.isOriginal
                ? true
                : false,
            date_of_collection: documents
              ? toIsoDate(
                  documents.find((res) => res.doc_idx === selectedDocument)
                    ?.dateCollected ?? serverDate
                )
              : toIsoDate(serverDate),
          },
        },
        refetchQueries: [
          {
            query: GetEmpDocs,
            variables: {
              pr_emp_id: Number(empId!),
              token,
            },
          },
        ],
      })
        .then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Documents Updated Successfully",
              operation: Operation.UPDATE,
            });
          }
        })
        .catch((error) => {
          console.log("Error updating documents:", error);
          setMessage({
            flag: true,
            message: `Error updating documents: ${error.message}`,
            operation: Operation.UPDATE,
          });
        });
    }

    const newDocuments = documents.filter(
      (data) => !data.isUpdatedFlag && (data.collected || data.isOriginal)
    );
    if (newDocuments.length) {
      UploadEmpDocuments({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          pr_emp_id: empId!,
          input: newDocuments.map((res) => ({
            emp_doc_type: res.document_name,
            emp_doc_filename: res.fromDbUrl ? res.fromDbUrl : EMPTY_STRING,
            emp_doc_download: false,
            emp_doc_collected: res.collected ? true : false,
            emp_doc_original: res.isOriginal ? true : false,
            date_of_collection: res.dateCollected
              ? toIsoDate(res.dateCollected)
              : toIsoDate(serverDate),
          })),
        },
        refetchQueries: [
          {
            query: GetEmpDocs,
            variables: {
              pr_emp_id: Number(empId!),
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Data Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const handleFileChange = (
    doc: DocsType,
    e: React.ChangeEvent<HTMLInputElement>,
    indexNum: number
  ) => {
    const { files } = e.target;

    if (files && files.length) {
      const file_ext = files[0].name.split(".").pop();
      const filename = `${InstDetails.data?.nodes[0]?.inst_name}/employees/${empId}/documents/${doc.document_name}.${file_ext}`;

      const final = documents.map((item, index) => {
        if (indexNum === index) {
          return {
            ...item,
            preview: files[0],
          };
        } else {
          return item;
        }
      });

      handleUploadAndDownloadFile(files[0], filename, setProgress, false)
        .then(() => {
          const docToUpdate = documents.find((res) => res.doc_idx === indexNum);

          if (docToUpdate?.isUpdatedFlag) {
            UpdateEmpDocuments({
              variables: {
                token,
                inst_id: InstId!,
                user_details,
                document_id: docToUpdate?.docId,
                input: {
                  emp_doc_filename: filename,
                  emp_doc_collected: docToUpdate?.collected || false,
                  emp_doc_original: docToUpdate?.isOriginal || false,
                  date_of_collection: toIsoDate(
                    docToUpdate?.dateCollected ?? serverDate
                  ),
                },
              },
              refetchQueries: [
                {
                  query: GetEmpDocs,
                  variables: {
                    pr_emp_id: Number(empId!),
                    token,
                  },
                },
              ],
            }).then(({ data }) => {
              inputFileRefs.current[indexNum].value = "";
              if (data) {
                setMessage({
                  flag: true,
                  message: "Successfully Uploaded File",
                  operation: Operation.CREATE,
                });
              }
            });
          } else {
            UploadEmpDocuments({
              variables: {
                token,
                inst_id: InstId!,
                user_details,
                pr_emp_id: empId,
                input: [
                  {
                    emp_doc_type: doc.document_name,
                    emp_doc_filename: filename,
                    emp_doc_download: false,
                    emp_doc_collected: !!documents.find(
                      (res) => res.doc_idx === indexNum
                    )?.collected,
                    emp_doc_original: !!documents.find(
                      (res) => res.doc_idx === indexNum
                    )?.isOriginal,
                    date_of_collection: toIsoDate(
                      documents.find((res) => res.doc_idx === indexNum)
                        ?.dateCollected ?? serverDate
                    ),
                  },
                ],
              },
              refetchQueries: [
                {
                  query: GetEmpDocs,
                  variables: {
                    pr_emp_id: Number(empId!),
                    token,
                  },
                },
              ],
            }).then(({ data }) => {
              inputFileRefs.current[indexNum].value = "";
              if (data) {
                setMessage({
                  flag: true,
                  message: "Successfully Uploaded File",
                  operation: Operation.CREATE,
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setMessage({
            flag: true,
            message: `Error Uploading file: ${error}`,
            operation: Operation.CREATE,
          });
        });

      setDocuments(final);
    }
  };

  const handleUpload = (index: number) => {
    if (
      inputFileRefs.current &&
      inputFileRefs.current &&
      inputFileRefs.current[index]
    ) {
      setSeletedIndex(index);
      (inputFileRefs.current[index] as HTMLInputElement).click();
    }
  };

  const handleDelete = (doc: DocsType) => {
    DeleteEmpDocs({
      variables: {
        document_id: doc.docId,
        inst_id: InstId!,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: GetEmpDocs,
          variables: {
            pr_emp_id: Number(empId!),
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteStudentDoc) {
        inputFileRefs.current = [];
        setMessage({
          flag: true,
          message: "Deleted doc successfully",
          operation: Operation.DELETE,
        });
        const final: DocsType[] = documents.map((item) => {
          if (item.document_name === doc.document_name) {
            return {
              ...item,
              preview: null,
              fromDb: false,
            };
          } else return item;
        });
        setDocuments(final);
      }
    });
  };
  const AddRefs = (e: HTMLInputElement) => {
    if (
      e &&
      !inputFileRefs.current.includes(e) &&
      inputFileRefs.current.map((e) => e.name).includes(e.name) === false
    ) {
      inputFileRefs.current.push(e);
    }
  };

  useEffect(() => {
    inputFileRefs.current = [];
  }, [state.studentId]);
  return (
    <div className="student_docs__body">
      <TableContainer className="student_docs__body--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {StudentDocsPreviewTable.Table_Headers.filter(
                (th) =>
                  (th.labelName !== "Actions" && type === Operation.VIEW) ||
                  type === Operation.CREATE ||
                  type === Operation.UPDATE
              ).map((th: TableHeaderProps, index: React.Key) => {
                return (
                  <TableCell key={index} className={th.className}>
                    {th.labelName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((doc, index) => (
              <TableRow>
                <TableCell id="td-center">{index + 1}</TableCell>
                <TableCell>{doc.document_name}</TableCell>
                <TableCell
                  onClick={() => {
                    if (doc.fromDb && doc.fromDbUrl) {
                      getDownloadUrl(doc.fromDbUrl, true);
                    } else if (doc.preview) {
                      const imageUrl = URL.createObjectURL(doc.preview);
                      const a = document.createElement("a");
                      a.href = imageUrl;
                      a.download = doc.preview.name;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                      window.URL.revokeObjectURL(imageUrl);
                    }
                  }}
                >
                  {viewPreview(doc)}
                </TableCell>
                <TableCell id="td-center">
                  <FormControlLabel
                    label={doc.collected ? "Yes" : "No"}
                    control={
                      <AntSwitch
                        checked={doc.collected}
                        onChange={(e) => {
                          const updatedDocuments = [...documents];
                          updatedDocuments[index].collected = e.target.checked;
                          setDocuments(updatedDocuments);
                          setSelectedDocument(index);
                        }}
                      />
                    }
                  />
                </TableCell>
                <TableCell id="td-center">
                  <FormControlLabel
                    label={doc.isOriginal ? "Yes" : "No"}
                    control={
                      <AntSwitch
                        disabled={!doc.collected}
                        checked={doc.isOriginal}
                        onChange={(e) => {
                          const updatedDocuments = [...documents];
                          updatedDocuments[index].isOriginal = e.target.checked;
                          setDocuments(updatedDocuments);
                          setSelectedDocument(index);
                        }}
                      />
                    }
                    className={doc.isOriginal ? "font-green" : "font-red"}
                  />
                </TableCell>
                <TableCell className="student_docs__body--table--input">
                  <Input
                    type="date"
                    value={toInputStandardDate(doc.dateCollected!)}
                    onChange={(e) => {
                      const updatedDocuments = [...documents];
                      updatedDocuments[index].dateCollected = e.target.value;
                      setDocuments(updatedDocuments);
                      setSelectedDocument(index);
                    }}
                  />
                </TableCell>
                {type === Operation.CREATE || type === Operation.UPDATE ? (
                  <TableCell
                    id="td-center"
                    className="student_docs__body--table--actions"
                  >
                    <div>
                      <input
                        type="file"
                        ref={AddRefs}
                        name={doc.document_name}
                        onChange={(e) => {
                          handleFileChange(doc, e, index);
                        }}
                      />
                      <Button
                        mode={doc.fromDbUrl ? "docs-delete" : "docs-upload"}
                        onClick={() => {
                          if (doc.fromDbUrl) {
                            handleDelete(doc);
                          } else {
                            handleUpload(index);
                          }
                        }}
                      />
                    </div>
                  </TableCell>
                ) : null}{" "}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button mode="save" onClick={handleSubmit} />
      <LoadingModal flag={loading || deleteLoading || updateLoading} />
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </div>
  );
};

export default EmpDocsUploadComponent;

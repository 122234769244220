import { useEffect, useRef, useState } from "react";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../Modules/Academics/hooks/useAcdGeneralHolidays";
import {
  getAllDaysInMonth,
  getDateRangeByMonth,
  getMonthDateAndDay,
} from "../utils/UtilFunctions";
import { monthDaysType } from "../Modules/Academics/DailyActivities/Attendance/MonthlyOverview";
import { HolidayType } from "../utils/Enum.types";
import { DEFAULT_TIME } from "../utils/constants";
export interface datesType {
  day: number;
  status: string;
  description: string;
  week: string;
  class_name: string;
}
const useHolidayListForExam = (date: Date, acdYrId: number) => {
  const { endDate, startDate } = getDateRangeByMonth(
    new Date(date).getFullYear(),
    new Date(date).getMonth()
  );
  const { month, year } = getMonthDateAndDay(date.toString());
  const dates = getAllDaysInMonth(month, year);

  var generalHolidaysMap = useRef(new Map());
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    acdYrId,
    endDate.toString(),
    startDate.toString(),
    "",
    holiday_query_type.ALL_HOLIDAYS
  );

  const [monthDays, setMonthDays] = useState<monthDaysType[]>([]);
  const chooseTypeAndColor = (day: number) => {
    if (generalHolidaysMap.current.has(day)) {
      const holiday_details = generalHolidaysMap.current.get(day)!;

      switch (holiday_details.holiday_type) {
        case HolidayType.GENERAL || HolidayType.UNEXPECTED:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "border-left-for-holidays",
            class_name_acd: "holidays-in-calendar",
            holiday_type_list: holiday_details.holidays_list,
            events_length: 0,
          };
        case HolidayType.WEEKEND:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "weekend-dates",
            class_name_acd: "weekends-in-calendar",
            holiday_type_list: holiday_details.holidays_list,
            events_length: 0,
          };

        case HolidayType.VACATION:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "",
            class_name_acd: "weekends-in-calendar",
            holiday_type_list: holiday_details.holidays_list,
            events_length: 0,
          };
        default:
          break;
      }
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
        class_name_acd: "",
        holiday_type_list: [],
        events_length: 0,
      };
    }
  };
  const emptyDaysByWeek = (week: string) => {
    let numberOfEmpty = -1;
    let numberOfEmptyToLast = -1;
    switch (week) {
      case "Sun":
        numberOfEmpty = 6;
        numberOfEmptyToLast = 0;
        break;
      case "Mon":
        numberOfEmpty = 0;
        numberOfEmptyToLast = 6;
        break;
      case "Tue":
        numberOfEmpty = 1;
        numberOfEmptyToLast = 5;

        break;
      case "Wed":
        numberOfEmpty = 2;
        numberOfEmptyToLast = 4;
        break;
      case "Thu":
        numberOfEmpty = 3;
        numberOfEmptyToLast = 3;
        break;
      case "Fri":
        numberOfEmpty = 4;
        numberOfEmptyToLast = 2;
        break;
      case "Sat":
        numberOfEmpty = 5;
        numberOfEmptyToLast = 1;
        break;
      default:
        break;
    }
    return { numberOfEmpty, numberOfEmptyToLast };
  };
  const weekCount: () => monthDaysType[] = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          class_name: "",
          date: new Date(DEFAULT_TIME),
          holiday_types: [],
          events_length: 0,
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          class_name: "",
          date: new Date(DEFAULT_TIME),
          holiday_types: [],
          events_length: 0,
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (InstGeneralHolidays.data && !InstGeneralHolidays.loading) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            const moreHolidays =
              InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.filter(
                ({ node: { holiday_date } }) =>
                  holiday_date === node.holiday_date
              );

            if (moreHolidays && moreHolidays.length)
              return [
                new Date(node.holiday_date).getDate(),
                {
                  holiday_type: node.holiday_type,
                  holiday_description: moreHolidays.reduce((acc, d) => {
                    acc += d.node.holiday_desc + "\n";
                    return acc;
                  }, ""),
                  holidays_list: moreHolidays.reduce((acc: string[], d) => {
                    acc = [...acc, d.node.holiday_type];
                    return acc;
                  }, []),
                },
              ];
            else
              return [
                new Date(node.holiday_date).getDate(),
                {
                  holiday_type: node.holiday_type,
                  holiday_description: node.holiday_desc,
                  holidays_list: [node.holiday_type],
                  eventsLength: 0,
                },
              ];
          }
        )
      );
      setMonthDays(
        dates.map(({ day, day_num, dateFormat }) => {
          const holiday_details = chooseTypeAndColor(day_num);

          return {
            day: day_num,
            status: holiday_details?.type!,
            description: holiday_details?.description!,
            class_name: holiday_details?.class_name!,
            date: new Date(dateFormat),
            week: day,
            holiday_types: holiday_details?.holiday_type_list ?? [],
            events_length: 0,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [InstGeneralHolidays.data, InstGeneralHolidays.loading]);

  return weekCount();
};

export default useHolidayListForExam;

import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import InputHoc from "../../../../components/common/Input/Index";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { HRTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import EditProfile from "../../../../images/EditProfile.svg";
import Close from "../../../../images/Close.svg";
import HRImport from "../../Imports/MastersImport";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import {
  getModifiedScrollHeight,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { LabelNameProps } from "../../../../Types/Labels";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import { Formik, Form } from "formik";
import { CommonNodeVars } from "../../Types/masterDataTypes";
import { payroleJobTypeSchema } from "../../../../utils/payrole";
import Input from "../../../../stories/Input/Input";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import DeleteModal from "../../../../pages/DeleteModal";
import Delete from "../../../../images/Delete.svg";
import {
  GetPayRollJobTypeByNode,
  GetPayRollJobTypesByInstId,
} from "../../queries/jobtype/query";
import {
  AddPayRollJobType,
  DeletePayRollJobTypeById,
  UpdatePayRollJobType,
} from "../../queries/jobtype/mutations";
import { PayRollJobTypeEdges } from "../../Types/paginationTypes";
import { PayRollJobTypeDetailsFormType } from "../../Types/formTypes";
import { GetPayRollMasterData } from "../../queries/general/list";
import { job_type_desc } from "../../constants";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import usePayRollMasters from "../../hooks/usePayRollMasters";
import { PayRollMasters } from "../../enums/Enum.types";
import useMastersExport from "../../Imports/useMastersExport";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");
const { HRFormLabels } = require("../../json/form.json");
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  const { InstId } = useParams();
  const [importModal, setImportModal] = useState(false);

  const [formData, setFormData] = useState<PayRollJobTypeDetailsFormType>({
    job_type_desc: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [payrollJobTypeId, setPayrollJobTypeId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [jobTypes, setJobTypes] = useState<PayRollJobTypeEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchData, setSearchData] = useState("");
  const jobTypeDescRef = document.getElementsByName(job_type_desc)[0];

  const [GetJobTypeDetailsById] = useLazyQuery<
    PayRollJobTypeEdges,
    CommonNodeVars
  >(GetPayRollJobTypeByNode);
  const exportExcel = useMastersExport(PayRollMasters.JOB_TYPE);
  const [AddJobType, { loading: creationLoading }] = useMutation(
    AddPayRollJobType,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteJobType, { loading: deleteLoading }] = useMutation(
    DeletePayRollJobTypeById,
    {
      onError: (e) => {
        setDeleteModal(!deleteModal);
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
      },
    }
  );
  const [UpdateJobType, { loading: updationLoading }] = useMutation(
    UpdatePayRollJobType,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    jobTypeDetails: { data, loading, fetchMore },
  } = usePayRollMasters(PayRollMasters.JOB_TYPE, searchData, ROWS_PER_PAGE);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
      if (pageType === PageFor.MODAL) {
        setModalFlag(false);
      }
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setFormData({
      job_type_desc: "",
    });
    if (operation === Operation.UPDATE) {
      setOperation(Operation.CREATE);
    }
    jobTypeDescRef.focus();
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollJobTypesByInstId.edges;
            const pageInfo =
              fetchMoreResult.GetPayRollJobTypesByInstId.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollJobTypesByInstId.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollJobTypesByInstId: {
                edges: [...jobTypes!, ...newEdges],
                pageInfo,
                totalCount: data?.GetPayRollJobTypesByInstId.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const handleSubmitForm = () => {
    if (operation === Operation.CREATE) {
      AddJobType({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input: {
            job_type_desc: removeMoreSpace(formData.job_type_desc),
          },
        },
        refetchQueries: [
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetPayRollJobTypesByInstId,
            variables: {
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.JOB_TYPE_DESC,
              direction: Direction.ASC,
              token,
              name: searchData,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSearchData(EMPTY_STRING);
          setMessage({
            flag: true,
            message: "PayRoll JobType Created Sucessfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      UpdateJobType({
        variables: {
          token,
          id: payrollJobTypeId,
          inst_id: InstId,
          user_details,
          input: {
            job_type_desc: removeMoreSpace(formData.job_type_desc),
          },
        },
        refetchQueries: [
          {
            query: GetPayRollJobTypeByNode,
            variables: {
              token,
              id: payrollJobTypeId,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSearchData(EMPTY_STRING);
          setMessage({
            flag: true,
            message: "PayRoll Job Type Updated Sucessfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const handleDelete = (id: number) => {
    DeleteJobType({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollJobTypesByInstId,
          variables: {
            first: ROWS_PER_PAGE,
            after: null,
            sortBy: SortBy.JOB_TYPE_DESC,
            direction: Direction.ASC,
            token,
            name: searchData,
            inst_id: InstId!,
          },
          fetchPolicy: "network-only",
        },
        {
          query: GetPayRollMasterData,
          variables: {
            token,
            inst_id: InstId!,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setSearchData(EMPTY_STRING);
        setMessage({
          flag: true,
          message: "PayRoll Job Type Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  useEffect(() => {
    if (operation === Operation.UPDATE) {
      GetJobTypeDetailsById({
        variables: {
          token,
          id: payrollJobTypeId,
        },
      }).then(({ data }) => {
        if (data) {
          const { job_type_desc } = data.node;
          setFormData({
            job_type_desc,
          });
        }
      });
    }
  }, [payrollJobTypeId, operation, token, GetJobTypeDetailsById]);
  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetPayRollJobTypesByInstId.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredJobTypes = jobTypes.filter(
          (jobtype) => !jobtype.node.isChecked
        );

        const updatedNewData = newData.map((newJobType) => {
          const filteredJobtype = filteredJobTypes.find(
            (jobType) => jobType.node.id === newJobType.node.id
          );
          if (filteredJobtype) {
            return {
              ...newJobType,
              node: {
                ...newJobType.node,
                isChecked: true,
              },
            };
          }
          return newJobType;
        });
        setJobTypes(updatedNewData);
      } else {
        setJobTypes(newData);
      }
      setEndCursor(data.GetPayRollJobTypesByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {HRTitles.MasterData.JobType.Titles.map(
          (title: HRTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Title}</React.Fragment>;
          }
        )}
      </Title>

      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={payroleJobTypeSchema}
        onSubmit={handleSubmitForm}
      >
        {(meta) => {
          return (
            <Form
              className={
                pageType === PageFor.GENERAL
                  ? "payroll-masterdata"
                  : "payroll-masterdata--modal"
              }
            >
              <div className="row g-0 payroll-masterdata__frame">
                <div className="col booktype-left h-100">
                  <div className="payroll-masterdata__title">
                    <Title variant="subtitle1">
                      {HRTitles.MasterData.JobType.Titles.map(
                        (title: HRTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="payroll-masterdata__block">
                    {HRFormLabels.MasterData.JobType.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              autoFocus
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              required={label.required ? true : false}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="col booktype-right h-100">
                  <div className="row payroll-masterdata__title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">
                        {HRTitles.MasterData.JobType.Titles.map(
                          (title: HRTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {title.List}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                    </div>
                    <div className="col-3">
                      <Input
                        type="text"
                        id="search"
                        placeholder="Search..."
                        onChange={(e) => setSearchData(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="payroll-masterdata__block">
                    {!jobTypes?.length && !loading ? (
                      <b className="nodata">
                        Sorry No Payroll Categories Found
                      </b>
                    ) : (
                      <>
                        <TableContainer
                          className="payroll-masterdata__block--table"
                          onScroll={handleScroll}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                {HR_Table.MasterData.Table_Headers.JobType.map(
                                  (th: TableHeaderProps, index: React.Key) => {
                                    return (
                                      <TableCell
                                        key={index}
                                        className={th.className}
                                      >
                                        {th.labelName}
                                      </TableCell>
                                    );
                                  }
                                )}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {jobTypes.map((edge, index) => {
                                return (
                                  <TableRow key={index + 1}>
                                    <TableCell
                                      className="payroll-masterdata__block--table--slno"
                                      id="td-center"
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell>
                                      {edge.node.job_type_desc}
                                    </TableCell>
                                    <TableCell
                                      className="payroll-masterdata__block--table--actions"
                                      id="td-center"
                                    >
                                      <>
                                        <img
                                          src={EditProfile}
                                          alt="/"
                                          onClick={() => {
                                            setOperation(Operation.UPDATE);
                                            setPayrollJobTypeId(edge.node.id);
                                            jobTypeDescRef.focus();
                                          }}
                                        />
                                        <img
                                          src={Delete}
                                          alt="/"
                                          onClick={() => {
                                            setPayrollJobTypeId(edge.node.id);
                                            setDeleteModal(!deleteModal);
                                          }}
                                        />
                                      </>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <>
                <Button mode="save" type="submit" />

                <Button mode="clear" type="button" onClick={handleClose} />

                {pageType === PageFor.GENERAL ? (
                  <>
                    <Button
                      type="button"
                      onClick={() => setImportModal(!importModal)}
                      mode="excel"
                    >
                      Import JobTypes
                    </Button>
                    <Button type="button" mode="export" onClick={exportExcel} />

                    <Button
                      mode="back"
                      onClick={() => navigate(-1)}
                      type="button"
                    />
                  </>
                ) : (
                  <Button
                    mode="cancel"
                    onClick={() => setModalFlag(false)}
                    type="button"
                  />
                )}
              </>
            </Form>
          );
        }}
      </Formik>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <HRImport MasterType={PayRollMasters.JOB_TYPE} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={creationLoading || loading || deleteLoading || updationLoading}
      />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={payrollJobTypeId}
      />
    </>
  );
};

export default Index;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { GetStdVoucherBooks } from "../queries/Vouchers/query";
import { AppContext } from "../../../context/context";
export interface GetStdVoucherBooksDetails {
  id: number;
  vo_book_key: string;
  vo_book_desc: string;
  vo_book_start_no: string;
  vo_book_current_no: string;
  is_prefixed: boolean;
  prefix_str: string;
  suffix_str: string;
  separator: string;
  prefill_with_zero: boolean;
  prefill_width: number;
  is_book_system_based: boolean;
  hide_this_book: boolean;
  voucher_type: string;
  voucher_sub_type: string;
  ldgr_details?: {
    id: number;
    ldgr_desc: string;
  }[];
}

interface GetStdVoucherBooksData {
  GetStdVoucherBooks: GetStdVoucherBooksDetails[];
}
interface GetStdVoucherBooksVars {
  token: string;
  inst_id: string;
  fin_yr_id: number;
}
const useBillbooks = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [getDetails, { data, loading, error }] = useLazyQuery<
    GetStdVoucherBooksData,
    GetStdVoucherBooksVars
  >(GetStdVoucherBooks, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
  });
  useEffect(() => {
    if (state.ActiveFinYr && token) {
      getDetails().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetStdVoucherBooks.map((book) => ({
              label: book.vo_book_desc,
              value: book.id,
            }))
          );
        }
      });
    }
  }, [state.ActiveFinYr, token, getDetails]);
  return { voucherBooks: { data, loading, error, responseType } };
};

export default useBillbooks;

import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GetSubjectById } from "../queries/subjects/List.tsx/Index";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AcdSubjectMaster } from "../types/test";
import { nodevars, singleNodeVars } from "../../../Types/Accounting";

const useAcdSubjectDetails = (subId: number) => {
  const { token } = useToken();
  const { subjectId } = useParams();
  const [GetAcdSubject, { data, error }] = useLazyQuery<
    { node: AcdSubjectMaster },
    singleNodeVars
  >(GetSubjectById, {
    variables: {
      id: Number(subId ? subId : subjectId)!,
      token,
    },
  });
  useEffect(() => {
    if (token) {
      GetAcdSubject();
    }
  }, [GetAcdSubject, token, subjectId]);

  return {
    data,
    error,
  };
};

export default useAcdSubjectDetails;

import React, { useContext, useEffect, useState } from "react";
import Input from "../../../../../stories/Input/Input";
import {  TextField } from "@mui/material";
import { Title } from "../../../../../stories/Title/Title";
import useStudentOverAllPerformance from "../../../../Academics/hooks/useStudentOverAllPerformance";
import ReactEcharts from "echarts-for-react";
import Compare from "../../../../../images/Compare.svg";
import View from "../../../../../images/View.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";

import { Button } from "../../../../../stories/Button/Button";
import { PageFor } from "../../../../../utils/Enum.types";
import ParticularStudentResults from "../../../../Academics/Reports/Test/Modal/ParticularStudentResults";
import { responseType } from "../../../../../utils/Form.types";
import {
  GetAcdStudentMarksStatisticsData,
  GetAcdStudentMarksStatisticsDetails,
  GetAcdStudentMarksStatisticsVars,
} from "../../../../Academics/hooks/useAcdStdMaksStats";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import useActiveAcademicYear from "../../../../Academics/hooks/useActiveAcademicYear";
import { GetAcdStudentMarksStatistics } from "../../../../Academics/queries/test/query";
import { useLazyQuery } from "@apollo/client";
import {
  isOptionEqualToValue,
  toInputStandardDate,
} from "../../../../../utils/UtilFunctions";
import dayjs from "dayjs";
import useAcademicYears from "../../../../Academics/hooks/useAcademicYears";
import useAcdTestTypes from "../../../../Academics/hooks/useAcdTestTypes";
import { colorsInTest } from "../../../../../styles/Constants";
import useCheckAllocationType from "../../../../Academics/hooks/useCheckAllocationType";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import { labelClasses, LabeledAutocomplete } from "../../../../../styles/AutocompleteListStyles";

interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Exam = ({ pageType, setModalFlag }: Props) => {
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { state } = useContext(AppContext);
  const { graphData } = useStudentOverAllPerformance();

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { academicYearResponse } = useAcademicYears();
  const [viewDetails, setViewDetails] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedTestType, setSelectedTestType] = useState<responseType | null>(
    null
  );
  const [selectedTest, setSelectedTest] = useState<responseType | null>(null);

  const [selectedAcademicYear, setSelectedAcademicYear] =
    useState<responseType | null>(null);
  const { token } = useToken();
  const {
    acdTestTypes: { responseType: acdTestTypes },
  } = useAcdTestTypes();

  const { studentEntryId } = useStudentDatabyId();
  const { flag } = useCheckAllocationType(studentEntryId);
  const [GetStats, { data: statsData, error }] = useLazyQuery<
    GetAcdStudentMarksStatisticsData,
    GetAcdStudentMarksStatisticsVars
  >(GetAcdStudentMarksStatistics, {
    variables: {
      token,
      inst_id: state.InstId as string,
      acd_yr_id: selectedAcademicYear?.value!,
      acd_test_class_ids: [],
      subj_master_ids: [],
      student_id: state.studentId,
      per_std_subj_allocation: flag ? flag : false,
    },
    // fetchPolicy: "network-only",
  });

  const filterBasedOnSearch = (test: GetAcdStudentMarksStatisticsDetails) => {
    return (
      searchText === "" ||
      test.acd_test_name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  useEffect(() => {
    if (token && state.studentId && selectedAcademicYear && state.InstId) {
      GetStats();
    }
    //eslint-disable-next-line
  }, [token, state.studentId, GetStats, selectedAcademicYear]);
  useEffect(() => {
    if (activeAcademicYearData.data && !activeAcademicYearData.loading) {
      setSelectedAcademicYear({
        label: activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_yr,
        value: activeAcademicYearData.data.GetAcdYrActiveByInstId.id,
      });
    }
  }, [activeAcademicYearData.data, activeAcademicYearData.loading]);
  const filterBasedOnDate = (test: GetAcdStudentMarksStatisticsDetails) => {
    if (test) {
      if (fromDate === "") {
        return (
          selectedTestType === null ||
          test.test_type_name === selectedTestType.label
        );
      } else if (
        dayjs(fromDate).isBefore(test.test_start_date) &&
        endDate === ""
      ) {
        return (
          selectedTestType === null ||
          test.test_type_name === selectedTestType.label
        );
      } else if (
        dayjs(fromDate).isBefore(test.test_start_date) &&
        dayjs(endDate).isAfter(test.test_start_date)
      ) {
        return (
          selectedTestType === null ||
          test.test_type_name === selectedTestType.label
        );
      }
    }
    return false;
  };

  return (
    <>
      {pageType === PageFor.MODAL && <Title>Test Details</Title>}
      <div
        className={
          pageType === PageFor.MODAL
            ? "std-acd-dashboard__exam--modal"
            : "std-acd-dashboard__exam"
        }
      >
        {/* <div className="std-acd-dashboard__exam--left-block"> */}
          {/* <div className="std-acd-dashboard__exam--performance">
            <Title variant="subtitle1">Overall Performance</Title>
            {selectedAcademicYear?.value && (
              <div className="std-acd-dashboard__exam--graph">
                <ReactEcharts option={graphData} />
              </div>
            )}
          </div> */}
        {/* </div> */}
        <div className="std-acd-dashboard__exam--right-block">
          <div className="row g-0 std-acd-dashboard__exam--right-block--select">
            <div className="col-2">
              <Input
                id="search"
                placeholder="Search Test Name"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="col std-acd-dashboard__exam--flex">
              <TextField
                className="std-acd-dashboard__exam--textfield"
                label="From Date"
                onChange={(e) => setFromDate(e.target.value)}
                type="date"
                value={fromDate}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
              <img src={Compare} alt="" />
              <TextField
                className="std-acd-dashboard__exam--textfield"
                label="To Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              />
            </div>
            <div className="col-4 std-acd-dashboard__exam--flex-end">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={academicYearResponse.responseType}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, selectedAcademicYear)
                }
                value={selectedAcademicYear}
                onChange={(e, newValue) => {
                  setSelectedAcademicYear(newValue as responseType);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Academic Year"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={acdTestTypes}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedTestType(newValue as responseType);
                  } else {
                    setSelectedTestType(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sort By"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          </div>
          <div className="std-acd-dashboard__exam--grid-list">
            <div className="std-acd-dashboard__exam--grid-list--grids">
              {statsData
                ? statsData.GetAcdStudentMarksStatistics.filter(
                    (data) =>
                      filterBasedOnDate(data) && filterBasedOnSearch(data)
                  ).map((data, index) => {
                    return (
                      <div
                        className="std-acd-dashboard__exam--grid-list--grid "
                        style={{
                          backgroundColor: colorsInTest[index],
                        }}
                        key={index}
                      >
                        <Title variant="subtitle1">{data.acd_test_name}</Title>
                        <span className="std-acd-dashboard__exam--grid-list--grid--subtitle">
                          Subjects Conducted
                        </span>
                        <span className="std-acd-dashboard__exam--grid-list--grid--number">
                          {data.marks_details.length}
                        </span>
                        <div className="std-acd-dashboard__exam--grid-list--grid--flex">
                          <TextField
                            type="date"
                            disabled
                            className="std-acd-dashboard__exam--grid-list--grid--textfield"
                            label="Start-Date"
                            value={toInputStandardDate(data.test_start_date)}
                                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          />
                          <img src={Compare} alt="" />
                          <TextField
                            type="date"
                            disabled
                            className="std-acd-dashboard__exam--grid-list--grid--textfield"
                            label="End-Date"
                            value={toInputStandardDate(data.test_end_date)}
                                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          />
                        </div>
                        <span className="std-acd-dashboard__exam--grid-list--grid--subtitle">
                          Percentage Scored
                        </span>
                        <span
                          className={
                            data.test_std_total_percentage >= 35
                              ? "std-acd-dashboard__exam--grid-list--grid--number font-green"
                              : "std-acd-dashboard__exam--grid-list--grid--number font-red"
                          }
                        >
                          {data.test_std_total_percentage}%
                        </span>
                        <div className="std-acd-dashboard__exam--grid-list--grid--view">
                          <button
                            onClick={() => {
                              setSelectedTest({
                                label: data.acd_test_name,
                                value: data.acd_test_class_id,
                              });

                              setViewDetails(!viewDetails);
                            }}
                          >
                            <img src={View} alt="" /> View Details
                          </button>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      {pageType === PageFor.MODAL && (
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      )}
      <Modal
        isOpen={viewDetails}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <ParticularStudentResults
          setModalFlag={setViewDetails}
          testConductId={null}
        />
      </Modal>
    </>
  );
};

export default Exam;

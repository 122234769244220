import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import { Button } from "../../../../../stories/Button/Button";

import { useLazyQuery, useMutation } from "@apollo/client";
import { UpdateSessionAttSubjMonthly } from "../../../queries/holidays/mutation";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { msgType } from "../../../../../utils/Form.types";
import { Operation } from "../../../../../utils/Enum.types";
import {
  GetAcdMarkedSessionsForTheDay,
  GetAcdUnMarkedSessionsForTheDay,
} from "../../../queries/sessions/list";
import MessageModal from "../../../../../pages/MessageModal";
import { AppContext } from "../../../../../context/context";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";
export interface GetAcdUnMarkedSessionsForTheDayList {
  id: number;
  session_desc: string;
  start_time: string;
  end_time: string;
  session_idx: number;
  inst_id: number | string;
}
export interface GetAcdUnMarkedSessionsForTheDayData {
  GetAcdUnMarkedSessionsForTheDay: GetAcdUnMarkedSessionsForTheDayList[];
}
export interface GetAcdUnMarkedSessionsForTheDayVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  entry_id: string | number;
  entry_level: string;
  date_of_attendance: string;
  per_std_subj_allocation: boolean | undefined;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSession: number;
  attDate: Date;
}

const List = ({ setModalFlag, selectedSession, attDate }: Props) => {
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { state } = useContext(AppContext);

  const { InstId, entryId, subjectId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const { serverDate } = useServerDateandTime();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [toSession, setToSession] =
    useState<GetAcdUnMarkedSessionsForTheDayList | null>(null);
  const handleChange = (node: GetAcdUnMarkedSessionsForTheDayList) => {
    setToSession(node);
  };
  const { flag } = useCheckAllocationType();
  const [UpdateSession] = useMutation(UpdateSessionAttSubjMonthly);
  const handleSubmit = () => {
    UpdateSession({
      variables: {
        token,
        inst_id: InstId,
        user_details: user_details,
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        emp_id: user_details.id,
        entry_level: entry_level,
        entry_id: entryId,
        date_of_attendance: toIsoDate(attDate.toString()),
        marked_date: toIsoDate(serverDate),
        from_session_id: selectedSession ? selectedSession : 0,
        to_session_id: toSession ? toSession.id : 0,
        subj_master_id: subjectId,
        per_std_subj_allocation: flag ? flag : false,
      },
      refetchQueries: [
        {
          query: GetAcdMarkedSessionsForTheDay,
          variables: {
            token,
            inst_id: InstId!,
            per_std_subj_allocation: flag,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            entry_level: entry_level,
            entry_id: entryId ? entryId : 0,
            date_of_attendance: toIsoDate(serverDate),
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data && data.UpdateSessionAttSubjMonthly) {
          setMessage({
            flag: true,
            message: "Attendance Details Updated",
            operation: Operation.CREATE,
          });
        }
      })
      .catch((error) => {
        console.error("Error updating session:", error);
      });
  };

  const [GetSessionData, { data: SessionData }] = useLazyQuery<
    GetAcdUnMarkedSessionsForTheDayData,
    GetAcdUnMarkedSessionsForTheDayVars
  >(GetAcdUnMarkedSessionsForTheDay, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_level: entry_level,
      entry_id: entryId ? entryId : 0,
      date_of_attendance: toIsoDate(attDate.toString()),
      per_std_subj_allocation: flag ? flag : false,
    },
  });
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModalFlag?.(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token && entryId && entry_level && state.ActiveAcdYr) {
      GetSessionData();
    }
  }, [GetSessionData, token, entryId, entry_level, state.ActiveAcdYr, flag]);

  return (
    <>
      <div className="per-session-details__list">
        <Title>Update Attendance Timing</Title>

        <ul className="per-session-details__list--data">
          <FormLabel id="demo-controlled-radio-buttons-group">
            Select a Single Session for Attendance entry
          </FormLabel>

          {SessionData &&
          SessionData.GetAcdUnMarkedSessionsForTheDay.length > 0 ? (
            SessionData.GetAcdUnMarkedSessionsForTheDay.map((node, index) => {
              return (
                <li key={index}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={toSession}
                      onChange={() => handleChange(node)}
                    >
                      <FormControlLabel
                        value={node}
                        control={<Radio />}
                        label={
                          <div className="per-session-details__list--details">
                            <span> {node.session_desc}</span>
                            <div className="per-session-details__list--data--flex">
                              <TextField
                                label="Start Date"
                                slotProps={{
                                  inputLabel: {
                                    shrink: true,
                                  },
                                }}
                                defaultValue={node.start_time}
                                type="time"
                                className="per-session-details__list--data--textfield"
                              />
                              <img src={Compare} alt="" />
                              <TextField
                                label="End Date"
                                slotProps={{
                                  inputLabel: {
                                    shrink: true,
                                  },
                                }}
                                defaultValue={node.end_time}
                                type="time"
                                className="per-session-details__list--data--textfield"
                              />
                            </div>
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </li>
              );
            })
          ) : (
            <b className="nodata">No Sessions available.</b>
          )}
        </ul>
        {<Button mode="save" onClick={handleSubmit} />}

        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default List;

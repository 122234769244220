import { gql } from "@apollo/client";

export const DeleteLibBookMasterKeywordById = gql`
  mutation DeleteLibBookMasterKeywordById(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $lib_book_classification_id: ID!
  ) {
    DeleteLibBookMasterKeywordById(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      lib_book_classification_id: $lib_book_classification_id
    )
  }
`;

import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import { SalaryLedgerType } from "../../enums/Enum.types";
import { ChooseEarning } from "../../Types/dataTypes";
import { handleSelectLedger } from "../../utils/Functions";

export const ChooseLedgersTableHead = (props: ChooseEarning) => {
  const {
    ledgerSelected,
    ledgerRowCount,
    type,
    overAllSalaryLedgers,
    setOverAllSalaryLedgers,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          className="submission-details__table--actions"
        >
          <Checkbox
            indeterminate={
              ledgerSelected > 0 && ledgerSelected < ledgerRowCount
            }
            checked={ledgerRowCount > 0 && ledgerSelected === ledgerRowCount}
            onChange={() =>
              handleSelectLedger(
                type,
                overAllSalaryLedgers,
                setOverAllSalaryLedgers
              )
            }
          />
        </TableCell>
        <TableCell>
          Choose {type === SalaryLedgerType.EARNING ? "Earnings" : "Deductions"}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

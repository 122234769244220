import React, { useContext } from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Button } from "../../../../stories/Button/Button";
import useSubjectAllotedForClass from "../../hooks/useSubjectAllotedForClass";
import { AppContext } from "../../../../context/context";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../../utils/Enum.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import { EMPTY_STRING } from "../../../../utils/constants";
import { TableHeaderProps } from "../../../../Types/Tables";
import { StyledDatagrid } from "../../../../styles/DataGridTableStyles";
const { Academics_Table } = require("../../json/table.json");

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const View = ({ setModal }: Props) => {
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  const { state } = useContext(AppContext);
  const { subjectsForClass } = useSubjectAllotedForClass(state.allotedId);

  const { InstConfigDetails } = useInstConfigByEntryId(state.allotedId);
  const {
    acd_dept_desc,
    acd_branch_desc,
    acd_class_desc,
    acd_semester_desc,
    acd_section_desc,
  } = InstConfigDetails.data
    ? InstConfigDetails.data.GetAcdInstConfigNames
    : {
        acd_dept_desc: "",
        acd_branch_desc: "",
        acd_class_desc: "",
        acd_semester_desc: "",
        acd_section_desc: "",
      };
  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY } = useInstitutionConfiguration();

  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  const isNonAcademicFlag =
    subjectsForClass &&
    subjectsForClass.data &&
    subjectsForClass.data.GetAcdSubjectAllotedForClass.length &&
    subjectsForClass.data.GetAcdSubjectAllotedForClass.find(
      (res) => res.subject_details.subj_is_non_academic
    );
  return (
    <>
      <Title>List of Subjects</Title>
      <div className="sub-allocation__view">
        <div className="sub-allocation__view--details row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={departmentLabel}
                value={acd_dept_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}

          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={branchLabel}
                value={acd_branch_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}

          {displayClass ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={classLabel}
                value={acd_class_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}

          {displaySemester ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={semesterLabel}
                value={acd_semester_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}
          {displaySection ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={sectionLabel}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
                value={acd_section_desc}
              />
            </div>
          ) : null}
        </div>
        {/* <div className={`sub-allocation__view--tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            hideFooter
            
          />
        </div> */}
        <div className="test-planner__subject-allocation--tableblock">
          <TableContainer className="test-planner__subject-allocation--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.AllocateSubjects.Table_Headers.filter(
                    (data: TableHeaderProps) => data.labelName !== "Actions"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {subjectsForClass &&
                  subjectsForClass.data &&
                  subjectsForClass.data.GetAcdSubjectAllotedForClass.length &&
                  subjectsForClass.data.GetAcdSubjectAllotedForClass.filter(
                    (data) => !data.subject_details.subj_is_non_academic
                  ).map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          className="allocatesubjects__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {item.subj_is_elective
                            ? item.subject_details.subj_desc +
                              " " +
                              `(${item.elective_details.flatMap(
                                (res) => res.subject_details.subj_desc
                              )})`
                            : item.subject_details.subj_desc}
                        </TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subject_details.subj_board_code}
                        </TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subject_details.subj_is_core_subject
                            ? "Core"
                            : item.subject_details.subj_is_elective
                            ? "Elective"
                            : item.subject_details.subj_is_lang
                            ? "Language"
                            : item.subject_details.subj_is_lab
                            ? "Lab"
                            : item.subject_details.subj_is_non_academic
                            ? "Non Academic"
                            : EMPTY_STRING}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {isNonAcademicFlag && (
                  <TableRow className="assign-class-teacher__table--open-elective">
                    <TableCell colSpan={5}>Non Academic Subjects</TableCell>
                  </TableRow>
                )}

                {subjectsForClass &&
                  subjectsForClass.data &&
                  subjectsForClass.data.GetAcdSubjectAllotedForClass.length &&
                  subjectsForClass.data.GetAcdSubjectAllotedForClass.filter(
                    (data) => data.subject_details.subj_is_non_academic
                  ).map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          className="allocatesubjects__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{item.subject_details.subj_desc}</TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subject_details.subj_board_code}
                        </TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subject_details.subj_is_core_subject
                            ? "Core"
                            : item.subject_details.subj_is_elective
                            ? "Elective"
                            : item.subject_details.subj_is_lang
                            ? "Language"
                            : item.subject_details.subj_is_lab
                            ? "Lab"
                            : item.subject_details.subj_is_non_academic
                            ? "Non Academic"
                            : EMPTY_STRING}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default View;

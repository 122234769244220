import React, { useEffect } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import TotalStaff from "../../../../images/TotalStaff.svg";
import FilledDiary from "../../../../images/TotalStaffsFilledDiary.svg";
import NotFilled from "../../../../images/Total_Staffs_Not_Filled_Diary.svg";
import FilledPercentage from "../../../../images/TotalDiaryFilledPercentage.svg";
import { useLazyQuery } from "@apollo/client";
import { GetDailyDiaryDashBoardCount } from "../../../../queries/common";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import usePayRollActiveAcademicYear from "../../../HR/hooks/usePayRollActiveAcademicYear";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../../utils/constants";
import DailyDiaryGraph from "./graph/DailyDiary";
import usePollIntervalTime from "../../../../customhooks/usePollIntervalTime";
interface GetDailyDiaryDashBoardCountList {
  total_teachers: number;
  total_teachers_filled: number;
  total_teachers_not_filled: number;
  total_teachers_percentage: number;
}
interface GetDailyDiaryDashBoardCountData {
  GetDailyDiaryDashBoardCount: GetDailyDiaryDashBoardCountList;
}
export interface GetAttDashBoardTestVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
  date_of_attendance: string;
}

const DailyDairy = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const activeAcdId = payRollActiveAcademicYear.data
    ? payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id
    : 0;
  const pollIntervalTime = usePollIntervalTime();

  const [GetDailyDiaryDetails, { data: diaryDetails }] = useLazyQuery<
    GetDailyDiaryDashBoardCountData,
    GetAttDashBoardTestVars
  >(GetDailyDiaryDashBoardCount, {
    variables: {
      token,
      inst_id: InstId!,
      pr_acd_yr_id: activeAcdId!,
      date_of_attendance: toIsoDate(TODAY_DATE),
    },
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (
      token &&
      InstId &&
      payRollActiveAcademicYear.data &&
      !payRollActiveAcademicYear.loading
    ) {
      GetDailyDiaryDetails();
    } // eslint-disable-next-line
  }, [
    token,
    GetDailyDiaryDetails,
    payRollActiveAcademicYear.loading,
    payRollActiveAcademicYear.data,
    InstId,
  ]);

  return (
    <>
      <div className="acd-admin-dashboard__card--title">
        <Title variant="subtitle1">Daily Diary Status</Title>
        <img src={More} alt="" />
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={TotalStaff} alt="" />
          <span>Total Teachers </span>
        </div>
        <b className="font-blue">
          {diaryDetails &&
            diaryDetails.GetDailyDiaryDashBoardCount.total_teachers}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={FilledDiary} alt="" />
          <span>Total Teachers Filled Diary</span>
        </div>
        <b className="font-green">
          {diaryDetails &&
            diaryDetails.GetDailyDiaryDashBoardCount.total_teachers_filled}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={NotFilled} alt="" />
          <span>Total Staff Not Filled Diary</span>
        </div>
        <b className="font-red">
          {diaryDetails &&
            diaryDetails.GetDailyDiaryDashBoardCount.total_teachers_not_filled}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img
            src={FilledPercentage}
            alt=""
            className="acd-admin-dashboard__card--details--pers"
          />
          <span>Overall Percentage</span>
        </div>
        <b className="font-purple">
          {diaryDetails &&
            diaryDetails.GetDailyDiaryDashBoardCount.total_teachers_percentage}
          %
        </b>
      </div>
      <DailyDiaryGraph />
    </>
  );
};

export default DailyDairy;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { GetStudentsEnquiryNode } from "../Modules/Enquiry/StudentEnquiry";
import { GetStudentEnquiresByEnquiryId } from "../queries/students/list/newApi";
import { IObjectKeys } from "../utils/Form.types";
import { toStandardDate } from "../utils/UtilFunctions";
import useToken from "./useToken";
export interface GetStudentEnquiresByEnquiryIdData {
  node: GetStudentsEnquiryNode;
}
export interface GetStudentEnquiresByEnquiryIdVars {
  token: string;
  id: number;
}

export interface follow_up_details {
  id: number;
  follow_up_date: string;
  follow_up_status: string;
  follow_up_remarks: string;
  follow_up_person: string;
  next_follow_up_date: string;
}

interface EnquiryFormDataDetails extends IObjectKeys {
  enq_student_name: string;
  enq_date: string;
  seekingBranch: string;
  seekingClass: string;
  std_mobile: number;
  std_sex: string;
  std_email: string;
  std_father_name: string;
  std_previous_school: string;
}

const useEnquiryStudentDetails = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [enquiryFormData, setEnquiryFormData] =
    useState<EnquiryFormDataDetails>({
      enq_student_name: "",
      enq_date: "",
      seekingBranch: "",
      seekingClass: "",
      std_mobile: 0,
      std_sex: "",
      std_email: "",
      std_father_name: "",
      std_previous_school: "",
    });

  const [followItems, setFollowItems] = useState<follow_up_details[]>([]);
  const handleClear = () => {
    setEnquiryFormData({
      enq_student_name: "",
      enq_date: "",
      seekingBranch: "",
      seekingClass: "",
      std_mobile: 0,
      std_sex: "",
      std_email: "",
      std_father_name: "",
      std_previous_school: "",
    });
    setFollowItems([]);
  };

  useEffect(() => {
    if (state.studentEnquiryId === 0 || state.enqStdIdForChallan === 0) {
      handleClear();
    }
  }, [state.studentEnquiryId, state.enqStdIdForChallan]);
  const [GetEnquiryData, EnquiryQueryDetails] = useLazyQuery<
    GetStudentEnquiresByEnquiryIdData,
    GetStudentEnquiresByEnquiryIdVars
  >(GetStudentEnquiresByEnquiryId, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (state.studentEnquiryId > 0 || state.enqStdIdForChallan > 0) {
      GetEnquiryData({
        variables: {
          id: state.studentEnquiryId
            ? state.studentEnquiryId
            : state.enqStdIdForChallan,
          token,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setEnquiryFormData({
            enq_student_name: `${data.node.first_name} ${data.node.middle_name} ${data.node.last_name} `,
            enq_date: toStandardDate(data.node.enq_date),
            seekingBranch: data.node.branch_details.branch_desc,
            seekingClass: data.node.class_details.class_desc,
            std_mobile: Number(data.node.std_mobile),
            std_email: data.node.std_email,
            std_sex: data.node.std_sex,
            std_father_name: data.node.std_father_name,
            std_previous_school: data.node.std_previous_school,
          });

          setFollowItems([...data.node.follow_up_details]);
        }
      });
    }
  }, [state.studentEnquiryId, GetEnquiryData, token, state.enqStdIdForChallan]);
  return {
    enquiryStudentData: { EnquiryQueryDetails, enquiryFormData, followItems },
  };
};

export default useEnquiryStudentDetails;

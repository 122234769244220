import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { GetStudentDetails } from "../queries/common";
import useToken from "./useToken";
import { nodevars } from "../Types/Accounting";
import { StudentAcdNode } from "../Modules/Academics/hooks/useAcdStudentsForAdmission";
interface StudentNodeData {
  nodes: StudentAcdNode[];
}
const useStudentsByNodeIds = (studentIds: Set<number>) => {
  const { token } = useToken();
  const [GetStudents, { data, loading, error }] = useLazyQuery<
    StudentNodeData,
    nodevars
  >(GetStudentDetails, {
    variables: {
      token,
      ids: [...studentIds],
    },
  });
  useEffect(() => {
    if (token) {
      GetStudents();
    }
  }, [token, GetStudents, studentIds]);
  return {
    data,
    loading,
    error,
  };
};

export default useStudentsByNodeIds;

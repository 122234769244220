import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { EMPTY_RESPONSETYPE } from "../../../utils/constants";
import { responseType } from "../../../utils/Form.types";
import { GetAcdTestType } from "../queries/test/query";

import useAcademicYear from "./useActiveAcademicYear";

export interface GetAcdTestTypeDetails {
  test_type_name: string;
  id: number;
  ext_max_marks: string;
  ext_min_marks: string;
  int_max_marks: string;
  int_min_marks: string;
}

export interface GetAcdTestTypevars {
  token: string;
  inst_id: number | string;
}
export interface GetTestData {
  GetAcdTestType: GetAcdTestTypeDetails[];
}
const useAcdTestTypes = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { activeAcademicYearData } = useAcademicYear();
  const [testTypes, setTestTypes] = useState<responseType[]>([]);
  const [GetAcdTests, { data, loading, error }] = useLazyQuery<
    GetTestData,
    GetAcdTestTypevars
  >(GetAcdTestType, {
    variables: {
      inst_id: InstId!,
      token,
    },
  });
  useEffect(() => {
    if (
      InstId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetAcdTests().then(({ data, error }) => {
        if (data) {
          setTestTypes(
            data.GetAcdTestType.map((edge) => ({
              label: edge.test_type_name,
              value: edge.id,
              isChecked: false,
            }))
          );
        }
        if (error) {
          setTestTypes(EMPTY_RESPONSETYPE);
        }
      });
    }
  }, [
    InstId,
    GetAcdTests,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
  ]);

  return { acdTestTypes: { data, loading, error, responseType: testTypes } };
};

export default useAcdTestTypes;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAcctStdDemand } from "../../../queries/students/list/newApi";
import { GetAcctLdgrsByTypeDetails } from "../../../Types/Accounting";
import { Direction, StudentAcctReportType } from "../../../utils/Enum.types";

import {
  StudentNode,
  StudentPageInfo,
} from "../../../customhooks/useStudentsbyNewApi";
import useToken from "../../../customhooks/useToken";
import { AcctStudentQueryType } from "../common/QueryTypes";
import { EMPTY_STRING } from "../../../utils/constants";
import { AppContext } from "../../../context/context";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  branch_details,
  category_details,
  class_details,
  dept_details,
  section_details,
  semester_details,
} from "../../../utils/Form.types";

export interface acct_std_demand {
  id: number;
  std_demand_amt: number;
  std_demand_bal: number;
  std_demand_concession: number;
  std_demand_ob: number;
  std_demand_receivable: number;
  std_demand_received: number;
  std_demand_refunds: number;
  std_deposit_adjusted: number;
  std_deposit_amt: number;
  std_deposit_bal: number;
  std_deposit_ob: number;
  std_deposit_refunded: number;
  std_deposit_total: number;
  no_of_letters: number;
  new_due_date: string;
  remarks: string;
  std_non_demand_collected: number;
}

export interface acct_std_demand_details {
  id: number;
  fee_ob: number;
  fee_demand: number;
  fee_concession: number;
  fee_receivable: number;
  fee_received: number;
  fee_refunds: number;
  fee_bal: number;
  acct_ldgr_details: GetAcctLdgrsByTypeDetails;
  fee_due_date: number;
  acct_ldgr_id: number;
}

export interface StudentDemandNode extends acct_std_demand {
  demand_details: acct_std_demand_details[];
  mst_student: StudentNode;
  isChecked?: boolean;
  class: class_details;
  branch: branch_details;
  semester: semester_details;
  dept: dept_details;
  section: section_details;
  category: category_details;
  std_status: string;
}
export interface StudentDemandEdges {
  node: StudentDemandNode;
}
interface GetAcctStdDemandList {
  edges: StudentDemandEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
export interface GetAcctStdDemandData {
  GetAcctStdDemand: GetAcctStdDemandList;
}
interface input {
  std_demand_query_type: string;
  ids: number[];
  str_data?: string[];
  int_data?: number[];
  float_data?: number[];
}

export interface vars {
  name: string;
  after: string | null;
  sortBy?: string;
  direction: string;
  first: number | null;
  token: string;
  status: string;
  balance: number | null | undefined;
  fin_yr_id: number;
  stdFresher: boolean | null | undefined;
  input: input;
}
const useStudentsbyDemandAmount = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  name: string,
  rowsPerPage: number | null,
  queryType: StudentAcctReportType,
  acctLdgrId?: number,
  status?: string,
  balance?: number | null,
  stdFresher?: boolean | null
) => {
  const [ids, setId] = useState<number[]>([]);
  const [std_demand_query_type, setstd_query_type] = useState("");
  const { state } = useContext(AppContext);

  const [entryId, setEntryId] = useState(0);

  const { InstId } = useParams();
  const { token } = useToken();
  const [GetStudents, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcctStdDemandData,
    vars
  >(GetAcctStdDemand, {
    fetchPolicy: "network-only",
  });

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  useEffect(() => {
    if (sectionId && USE_SECTION_KEY) {
      setEntryId(sectionId);
    } else if (semesterId && USE_SEMESTER_KEY) {
      setEntryId(semesterId);
    } else if (classId && USE_CLASS_KEY) {
      setEntryId(classId);
    } else if (branchId && USE_BRANCH_KEY) {
      setEntryId(branchId);
    } else if (departmentId && USE_DEPARTMENT_KEY) {
      setEntryId(departmentId);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionId,
    USE_SECTION_KEY,
    semesterId,
    USE_SEMESTER_KEY,
    classId,
    USE_CLASS_KEY,
    branchId,
    USE_BRANCH_KEY,
    departmentId,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);

  useEffect(() => {
    switch (queryType) {
      case StudentAcctReportType.GENERAL:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_STD_DEMAND_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.ACCT_STD_DEMAND_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.CURR:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_DEPT_ID
          );
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_CURR_STD_DEMAND_BY_INST_ID
          );
        }
        break;

      case StudentAcctReportType.PARTIALLY_PAID:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_PAID_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_CATEGORY_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.PARTIALLY_PAID_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.FEE_ADVANCE:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEPOSIT_BAL_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEPOSIT_BAL_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEPOSIT_BAL_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEPOSIT_BAL_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEPOSIT_BAL_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_CATEGORY_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.DEPOSIT_BAL_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.PASSED_OUT:
        if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.PASSED_STDS_DEMAND_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.PASSED_STDS_DEMAND_BY_DEPT_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.PASSED_STDS_DEMAND_BY_INST_ID);
        }

        break;

      case StudentAcctReportType.DEMAND_RAISED_NOT_PAID:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_DEPT_ID
          );
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_INST_ID
          );
        }

        break;
      case StudentAcctReportType.REFUNDS:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.REFUND_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_CATEGORY_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.REFUND_BY_INST_ID);
        }

        break;

      case StudentAcctReportType.DEMAND_RAISED:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_CATEGORY_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.DEMAND_RAISED_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.PARTIALLY_OR_COMPLETELY_PAID:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_DEPT_ID
          );
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.PARTIALLY_OR_COMPLETELY_PAID_BY_INST_ID
          );
        }

        break;
      case StudentAcctReportType.CONCESSION:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.CONCESSION_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_CATEGORY_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.CONCESSION_BY_INST_ID);
        }
        break;
      case StudentAcctReportType.COMPLETELY_PAID:
        if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_CATEGORY_ID
          );
        } else if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.COMPLETELY_PAID_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_DEPT_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.COMPLETELY_PAID_BY_INST_ID);
        }

        break;

      case StudentAcctReportType.LEDGER_WISE:
        if (categoryId && acctLdgrId) {
          setId([categoryId, acctLdgrId]);
          setstd_query_type(AcctStudentQueryType.ACCT_LDGR_WISE_BY_CATEGORY_ID);
        } else if (sectionId && categoryId && acctLdgrId) {
          setId([sectionId, categoryId, acctLdgrId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_LDGR_WISE_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId && acctLdgrId) {
          setId([semesterId, categoryId, acctLdgrId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_LDGR_WISE_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId && acctLdgrId) {
          setId([classId, categoryId, acctLdgrId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_LDGR_WISE_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId && acctLdgrId) {
          setId([branchId, categoryId, acctLdgrId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_LDGR_WISE_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId && acctLdgrId) {
          setId([departmentId, categoryId, acctLdgrId]);
          setstd_query_type(
            AcctStudentQueryType.ACCT_LDGR_WISE_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId && acctLdgrId) {
          setId([sectionId, acctLdgrId]);
          setstd_query_type(AcctStudentQueryType.ACCT_LDGR_WISE_BY_SECTION_ID);
        } else if (semesterId && acctLdgrId) {
          setId([semesterId, acctLdgrId]);
          setstd_query_type(AcctStudentQueryType.ACCT_LDGR_WISE_BY_SEMESTER_ID);
        } else if (classId && acctLdgrId) {
          setId([classId, acctLdgrId]);
          setstd_query_type(AcctStudentQueryType.ACCT_LDGR_WISE_BY_CLASS_ID);
        } else if (branchId && acctLdgrId) {
          setId([branchId, acctLdgrId]);
          setstd_query_type(AcctStudentQueryType.ACCT_LDGR_WISE_BY_BRANCH_ID);
        } else if (departmentId && acctLdgrId) {
          setId([departmentId, acctLdgrId]);
          setstd_query_type(AcctStudentQueryType.ACCT_LDGR_WISE_BY_DEPT_ID);
        } else {
          setId([Number(InstId), acctLdgrId!]);
          setstd_query_type(AcctStudentQueryType.ACCT_LDGR_WISE_BY_INST_ID);
        }

        break;

      case StudentAcctReportType.FEE_RECEIPT:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_DEPT_ID
          );
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.STATUS_EQ_CUR_AND_NEED_PAY_FEE_BY_INST_ID
          );
        }

        break;
      case StudentAcctReportType.BY_NEW_DATE:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.NEW_DUE_DATE_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.NEW_DUE_DATE_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.NEW_DUE_DATE_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.NEW_DUE_DATE_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.NEW_DUE_DATE_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE_BY_SECTION_ID);
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE_BY_SEMESTER_ID);
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE_BY_BRANCH_ID);
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE_BY_CATEGORY_ID);
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.NEW_DUE_DATE_BY_INST_ID);
        }

        break;
      case StudentAcctReportType.UNMAPPED_ACCT_LDGR:
        if (branchId && classId && categoryId && acctLdgrId!) {
          setstd_query_type(AcctStudentQueryType.UNMAPPED_ACCT_LDGR);
          setId([branchId, classId, categoryId, acctLdgrId!]);
        }
        break;
      case StudentAcctReportType.MAPPED_ACCT_LDGR:
        if (branchId && classId && categoryId && acctLdgrId!) {
          setstd_query_type(AcctStudentQueryType.UNMAPPED_ACCT_LDGR);
          setId([branchId, classId, categoryId, acctLdgrId!]);
        }
        break;
      case StudentAcctReportType.ALL_ANONYMOUS_STUDENTS:
        setstd_query_type(AcctStudentQueryType.ALL_ANONYMOUS_STUDENTS);
        setId([Number(InstId)]);
        break;
      case StudentAcctReportType.ANONYMOUS_STUDENT:
        setstd_query_type(AcctStudentQueryType.ANONYMOUS_STUDENT);
        setId([Number(InstId)]);

        break;
      case StudentAcctReportType.SOCIAL_WELFARE_STUDENT:
        setstd_query_type(AcctStudentQueryType.SOCIAL_WELFARE_STUDENT);
        setId([Number(InstId)]);

        break;
      case StudentAcctReportType.AGENT_STUDENT:
        setstd_query_type(AcctStudentQueryType.AGENT_STUDENT);
        setId([Number(InstId)]);
        break;

      case StudentAcctReportType.DEMAND_FRESHER:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_DEPT_ID
          );
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.FRESHER_DEMAND_RAISED_BY_INST_ID
          );
        }
        break;
      case StudentAcctReportType.DEMAND_EXISTING:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_CLASS_ID
          );
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_DEPT_ID
          );
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(
            AcctStudentQueryType.EXISTING_DEMAND_RAISED_BY_INST_ID
          );
        }
        break;
      case StudentAcctReportType.DEMAND_NOT_RAISED:
        if (sectionId && categoryId) {
          setId([sectionId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setId([semesterId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setId([classId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setId([branchId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setId([departmentId, categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_DEPT_AND_CATEGORY_ID
          );
        } else if (sectionId) {
          setId([sectionId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_SECTION_ID
          );
        } else if (semesterId) {
          setId([semesterId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_SEMESTER_ID
          );
        } else if (classId) {
          setId([classId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_NOT_RAISED_BY_CLASS_ID);
        } else if (branchId) {
          setId([branchId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_BRANCH_ID
          );
        } else if (departmentId) {
          setId([departmentId]);
          setstd_query_type(AcctStudentQueryType.DEMAND_NOT_RAISED_BY_DEPT_ID);
        } else if (categoryId) {
          setId([categoryId]);
          setstd_query_type(
            AcctStudentQueryType.DEMAND_NOT_RAISED_BY_CATEGORY_ID
          );
        } else {
          setId([Number(InstId)]);
          setstd_query_type(AcctStudentQueryType.DEMAND_NOT_RAISED_BY_INST_ID);
        }
        break;
      case StudentAcctReportType.BY_AGENT:
        setId([Number(InstId), entryId, categoryId, state.agentId]);
        setstd_query_type(AcctStudentQueryType.BY_AGENT_ID);
        break;
      default:
        break;
    }
  }, [
    InstId,
    departmentId,
    branchId,
    classId,
    semesterId,
    sectionId,
    categoryId,
    queryType,
    acctLdgrId,
    entryId,
    state.agentId,
  ]);
  useEffect(() => {
    if (state.ActiveFinYr && std_demand_query_type) {
      GetStudents({
        variables: {
          after: null,
          direction: Direction.ASC,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          first: rowsPerPage === null ? null : rowsPerPage,
          input: {
            ids,
            std_demand_query_type,
          },
          name,
          token,
          status: status ? status : EMPTY_STRING,
          balance: balance && balance <= 0 ? null : balance,
          stdFresher: stdFresher != null ? stdFresher : null,
          // balance: balance !== undefined && balance < 0 ? null : balance,
          // sortBy: "STUDENT_ID",
        },
      });
    }
  }, [
    state.ActiveFinYr,
    balance,
    GetStudents,
    stdFresher,
    status,
    name,
    ids,
    rowsPerPage,
    std_demand_query_type,
    token,
  ]);

  return { students: { data, loading, error, fetchMore } };
};

export default useStudentsbyDemandAmount;

import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import * as XLSX from "xlsx";

// import Home from "../../Home/Index";
// import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../../images/TotalMale.svg";
import ReadyToImport from "../../../images/Present.svg";
import ContainsError from "../../../images/Absent.svg";
import Info from "../../../images/Information.svg";
import DownArrow from "../../../images/DownArrow.svg";
import FileAttach from "../../../images/BrowseFiles.svg";

import ExcelJS from "exceljs";
import { Cell } from "exceljs";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { ValidateStudentDataForImport } from "../../../../queries/xls";

// import { ImportStudentDataFromXls } from "../../../../queries/xls/mutation";
import {
  getHeaderRowStyle,
  handleClear,
  removeMoreSpace,
  removeUnderscore,
  toStandardCase,
  toStandardDate,
} from "../../../utils/UtilFunctions";
// import { _genderRegex, _isPhone } from "../../../../utils/validationRules";
import {
  ACC_HEADER_FONT,
  BLOB_TYPE,
  DOWNLOAD,
  ELEMENT,
  FIRST_INDEX,
  TABLE_HEADER_CSS,
  FROZEN_CELLS_1,
  FILENAME,
  EDUATE_IMG_FORMAT,
  FIRST_CELL,
  HEADER_CSS,
  HEADER_ALIGNMENT,
  A2_CELL,
  A3_CELL,
  ADDRESS_FONT,
  ADDRESS_ALIGNMENT,
  C4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  HEADER_ALIGNMENT_CENTER,
  E4_CELL,
  FIN_YEAR_FONT,
  A4_CELL,
  HEADER_ALIGNMENT_LEFT,
  FROZEN_CELLS_INST,
  BORDER_DATA,
  PATTERN,
  SOLID,
  ERROR_BORDER_DATA,
  ERROR_FONT,
} from "../../Library/Constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  Operation,
  PredefinedDataTypes,
} from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { Button } from "../../../stories/Button/Button";
import LoadingModal from "../../../pages/LoadingModal";

import { Title } from "../../../stories/Title/Title";

import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";

import Eduate from "../../../images/Eduate_Logo_image.png";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";

import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { EMPTY_STRING } from "../../../utils/constants";
import { AddPayRollSalaryLdgr } from "../queries/salary/mutation";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  DeductionOptions,
  EarningOptions,
  PayRollConfigKeys,
} from "../enums/Enum.types";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import { CustomTooltip } from "../../../styles/TooltipStyles";

const SalaryLedgerImport = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);

  const [expanded, setExpanded] = useState(false);
  const [records, setRecords] = useState<any[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });

  const { InstDetails } = useInstDetails(1);
  const {
    configData: { data: groupInsuranceConfigData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_GRP_INSURANCE_SAL_TYPE);
  const {
    configData: { data: insuranceConfigData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_INSURANCE_SAL_TYPE);
  const {
    configData: { data: loanConfigData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_LOAN_SAL_TYPE);
  const {
    PredefinedData: { dropDown: EarningDeduction },
  } = usePredefinedDataByType(
    PredefinedDataTypes.EARNING_DEDUCTION,
    EMPTY_STRING
  );
  const { config_boolean_value: enableGroupInsurance } =
    groupInsuranceConfigData?.GetSwConfigVariables[0] || {};
  const { config_boolean_value: enableInsurance } =
    insuranceConfigData?.GetSwConfigVariables[0] || {};
  const { config_boolean_value: enableLoan } =
    loanConfigData?.GetSwConfigVariables[0] || {};
  const deductionTypes = Object.entries(DeductionOptions).reduce(
    (
      acc: {
        label: string;
        value: string;
      }[],
      data
    ) => {
      if (
        (data[1] === DeductionOptions.GROUP_INSURANCE &&
          enableGroupInsurance) ||
        (data[1] === DeductionOptions.INSURANCE && enableInsurance) ||
        (data[1] === DeductionOptions.LOAN && enableLoan)
      ) {
        acc.push({
          label: toStandardCase(removeUnderscore(data[0])),
          value: data[1],
        });
      }
      return acc;
    },
    []
  );

  const [AddSalaryLedgers, { loading }] = useMutation(AddPayRollSalaryLdgr);

  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});
  const { serverDate } = useServerDateandTime();

  const InstName = InstDetails.data?.nodes[0]?.inst_name;

  const SalaryLedgerData = [
    {
      Headers: "SalaryLedgerDesc",
      key: "sal_ldgr_desc",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerShortDesc",
      key: "sal_ldgr_short_desc",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerEarningOrDeduction",
      key: "sal_ldgr_earn_deduct",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerType",
      key: "sal_ldgr_type",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerIsActive",
      key: "sal_ldgr_is_active",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerRoundToNextInt",
      key: "sal_ldgr_round_to_next_int",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerRemoveDecimal",
      key: "sal_ldgr_remove_decimal",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerIsBasic",
      key: "sal_ldgr_is_basic",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerIsLoanAc",
      key: "sal_ldgr_is_loan_ac",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "SalaryLedgerIsSystemGenerated",
      key: "sal_ldgr_is_system_generated",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
  ];
  const headers = [
    {
      Headers: "SalaryLedgerType",
      key: "SalaryLedgerType",
      width: 25,
    },
    {
      Headers: "EarningLedgersType",
      key: "EarningLedgersType",
      width: 25,
    },
    {
      Headers: "DeductionLedgersType",
      key: "DeductionLedgersType",
      width: 25,
    },
  ];

  const salaryLedger_schema: any = {
    SalaryLedgerDesc: {
      name: "sal_ldgr_desc",
      required: true,
    },
    SalaryLedgerShortDesc: {
      name: "sal_ldgr_short_desc",
      required: true,
    },
    SalaryLedgerEarningOrDeduction: {
      name: "sal_ldgr_earn_deduct",
      required: true,
    },
    SalaryLedgerType: {
      name: "sal_ldgr_type",
    },
    SalaryLedgerIsActive: {
      name: "sal_ldgr_is_active",
    },
    SalaryLedgerRoundToNextInt: {
      name: "sal_ldgr_round_to_next_int",
    },
    SalaryLedgerRemoveDecimal: {
      name: "sal_ldgr_remove_decimal",
    },
    SalaryLedgerIsBasic: {
      name: "sal_ldgr_is_basic",
      required: true,
    },
    SalaryLedgerIsLoanAc: {
      name: "sal_ldgr_is_loan_ac",
      required: true,
    },
    SalaryLedgerIsSystemGenerated: {
      name: "sal_ldgr_is_system_generated",
      required: true,
    },
  };
  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      sal_ldgr_desc: f.SalaryLedgerDesc,
      sal_ldgr_short_desc: f.SalaryLedgerShortDesc,
      sal_ldgr_earn_deduct: f.SalaryLedgerEarningOrDeduction,
      sal_ldgr_type: f.SalaryLedgerType,
      sal_ldgr_is_active: f.SalaryLedgerIsActive,
      sal_ldgr_round_to_next_int: f.SalaryLedgerRoundToNextInt,
      sal_ldgr_remove_decimal: f.SalaryLedgerRemoveDecimal,
      sal_ldgr_is_basic: f.SalaryLedgerIsBasic,
      sal_ldgr_is_loan_ac: f.SalaryLedgerIsLoanAc,
      sal_ldgr_is_system_generated: f.SalaryLedgerIsSystemGenerated,
    }));

  const downloadExcel = (forError: boolean) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("HrSalaryLedgerData");
    worksheet.views = FROZEN_CELLS_INST;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    worksheet1.views = FROZEN_CELLS_1;

    worksheet1.getColumn(1).width = 30;
    worksheet1.getColumn(2).width = 30;
    worksheet1.getColumn(3).width = 30;
    worksheet1.getColumn(4).width = 30;
    worksheet1.getColumn(5).width = 30;
    worksheet1.getColumn(6).width = 30;
    worksheet1.getColumn(7).width = 30;
    worksheet1.getColumn(8).width = 30;
    worksheet1.getColumn(9).width = 30;
    worksheet1.getColumn(10).width = 30;
    worksheet1.getColumn(11).width = 30;
    worksheet1.getColumn(12).width = 30;
    worksheet1.getColumn(13).width = 30;
    worksheet1.getColumn(14).width = 30;
    worksheet1.getColumn(15).width = 30;
    worksheet1.getColumn(16).width = 30;
    worksheet1.getColumn(17).width = 30;
    worksheet1.getColumn(18).width = 30;
    worksheet1.getColumn(19).width = 30;
    worksheet1.getColumn(20).width = 30;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
   fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  let headers2 = SalaryLedgerData.filter(
                    (sal_ledger) => sal_ledger.use_in_sheet
                  ).map((f) => f.Headers);
                  let data_char = FIRST_INDEX;
                  //to find the end of the rows displayed
                  let longest_array = 0;
                  if (
                    EarningDeduction.length >=
                      Object.values(EarningOptions).length &&
                    EarningDeduction.length >= deductionTypes.length
                  ) {
                    longest_array = EarningDeduction.length;
                  } else if (
                    Object.values(EarningOptions).length >=
                    deductionTypes.length
                  ) {
                    longest_array = Object.values(EarningOptions).length;
                  } else {
                    longest_array = deductionTypes.length;
                  }

                  worksheet.mergeCells(
                    `A${6 + longest_array}:C${6 + longest_array}`
                  );
                  const noteData: Cell = worksheet.getCell(
                    `A${6 + longest_array}`
                  );
                  noteData.value = "Note";
                  noteData.fill = {
                    type: PATTERN,
                    pattern: SOLID,
                    fgColor: { argb: "f0a8a3" },
                  };
                  noteData.border = ERROR_BORDER_DATA;
                  noteData.font = ERROR_FONT;
                  noteData.alignment = {
                    horizontal: ExcelAlignment.CENTER,
                  };
                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);
                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i].Headers;
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                    data_char = String.fromCharCode(
                      data_char.charCodeAt(0) + 1
                    );
                    let data_begins = 6;
                    if (headers[i].Headers === "SalaryLedgerType") {
                      let note_data_begins = data_begins + longest_array + 1;
                      for (let j = 0; j < EarningDeduction.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData1: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData1.value = EarningDeduction[j].value;
                        rowData1.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                      for (let j = 0; j < EarningDeduction.length; j++) {
                        const index_pos = Number(note_data_begins + j);
                        const rowData1: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData1.value = `${EarningDeduction[j].value} : ${EarningDeduction[j].label}`;
                        rowData1.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                    if (headers[i].Headers === "EarningLedgersType") {
                      let note_data_begins = data_begins + longest_array + 1;
                      for (
                        let j = 0;
                        j < Object.values(EarningOptions).length;
                        j++
                      ) {
                        const index_pos = Number(data_begins + j);
                        const rowData1: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData1.value = Object.values(EarningOptions)[j];
                        rowData1.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                      for (
                        let j = 0;
                        j < Object.values(EarningOptions).length;
                        j++
                      ) {
                        const index_pos = Number(note_data_begins + j);
                        const rowData1: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData1.value = `${
                          Object.values(EarningOptions)[j]
                        } : ${Object.keys(EarningOptions)[j]}`;
                        rowData1.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                    if (headers[i].Headers === "DeductionLedgersType") {
                      let note_data_begins = data_begins + longest_array + 1;
                      for (let j = 0; j < deductionTypes.length; j++) {
                        const index_pos = Number(data_begins + j);
                        const rowData1: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData1.value = deductionTypes[j].value;
                        rowData1.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                      for (let j = 0; j < deductionTypes.length; j++) {
                        const index_pos = Number(note_data_begins + j);
                        const rowData1: Cell = worksheet.getCell(
                          data_char + index_pos
                        );
                        rowData1.value = `${deductionTypes[j].value} : ${deductionTypes[j].label}`;
                        rowData1.alignment = {
                          horizontal: ExcelAlignment.CENTER,
                        };
                      }
                    }
                  }

                  for (let i = 0; i < headers2.length; i++) {
                    const Char = String.fromCharCode(65 + i);
                    const rowData2: Cell = worksheet1.getCell(Char + 1);
                    rowData2.value = headers2[i];
                    rowData2.fill = TABLE_HEADER_CSS;
                    rowData2.border = BORDER_DATA;
                    rowData2.font = ACC_HEADER_FONT;
                    rowData2.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }

                  if (forError) {
                    errorData.forEach((err) =>
                      worksheet1.addRow(Object.values(err))
                    );
                  }
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName!);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];
    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      salaryLedger_schema &&
        // eslint-disable-next-line
        Object.keys(salaryLedger_schema).map((key) => {
          // @ts-ignore
          let keySchema = salaryLedger_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleClick = (
    id: number,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { checked } = e.target as HTMLInputElement;
    const newData = records.map((record, index) => {
      if (id === record.id) {
        return {
          ...record,
          isChecked: checked ? true : false,
        };
      }
      return record;
    });
    setRecords(newData);
  };

  const handleSubmit = async () => {
    for (const record of records.filter(
      (record) => !record.isValidatedAndError && record.isChecked
    )) {
      try {
        await AddSalaryLedgers({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: {
              sal_ldgr_desc: record.SalaryLedgerDesc,
              sal_ldgr_short_desc: record.SalaryLedgerShortDesc,
              sal_ldgr_idx: 1,
              sal_ldgr_earn_deduct: record.SalaryLedgerEarningOrDeduction,
              sal_ldgr_type: record.SalaryLedgerType,
              sal_ldgr_is_active:
                removeMoreSpace(record.SalaryLedgerIsActive.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.SalaryLedgerIsActive.toLowerCase()) ===
                  "n"
                  ? false
                  : true,
              sal_ldgr_round_to_next_int:
                removeMoreSpace(
                  record.SalaryLedgerRoundToNextInt.toLowerCase()
                ) === "no" ||
                removeMoreSpace(
                  record.SalaryLedgerRoundToNextInt.toLowerCase()
                ) === "n"
                  ? false
                  : true,
              sal_ldgr_remove_decimal:
                removeMoreSpace(
                  record.SalaryLedgerRemoveDecimal.toLowerCase()
                ) === "no" ||
                removeMoreSpace(
                  record.SalaryLedgerRemoveDecimal.toLowerCase()
                ) === "n"
                  ? false
                  : true,
              sal_ldgr_is_basic:
                removeMoreSpace(record.SalaryLedgerIsBasic.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.SalaryLedgerIsBasic.toLowerCase()) ===
                  "n"
                  ? false
                  : true,
              sal_ldgr_is_loan_ac:
                removeMoreSpace(record.SalaryLedgerIsLoanAc.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.SalaryLedgerIsLoanAc.toLowerCase()) ===
                  "n"
                  ? false
                  : true,
              sal_ldgr_is_system_generated:
                removeMoreSpace(
                  record.SalaryLedgerIsSystemGenerated.toLowerCase()
                ) === "no" ||
                removeMoreSpace(
                  record.SalaryLedgerIsSystemGenerated.toLowerCase()
                ) === "n"
                  ? false
                  : true,
            },
          },

          onError: (err) => {
            record.errors = record.errors ? record.errors : [];
            record.errors.push(err.message);
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(
    () => {
      const input = document.getElementById("input") as HTMLInputElement;
      if (input) {
        input.addEventListener("change", () => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const res = processExcel(reader.result);
            setRecords(
              res.map((f: any, index: number) => {
                return {
                  ...f,
                  id: index + 1,
                  isChecked: false,
                };
              })
            );
          };
          reader.readAsBinaryString(input.files![0]);
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {/* <Home DashBoardRequired={false} /> */}
      <Title>Import Salary Ledgers Data</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Salary Ledgers
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="studentlist__table">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {/* <Checkbox /> */}
                          </TableCell>
                          <TableCell id="td-center">Sl</TableCell>
                          {salaryLedger_schema && // eslint-disable-next-line
                            Object.keys(salaryLedger_schema).map(
                              // eslint-disable-next-line
                              (key, index) => {
                                // @ts-ignore
                                const fieldSchema = salaryLedger_schema[key];
                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                ) {
                                  return (
                                    <TableCell key={index} id="td-center">
                                      {key}
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records.map((record, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell id="td-center">
                                <Checkbox
                                  onClick={(e) => handleClick(record.id, e)}
                                  checked={record?.isChecked}
                                />
                              </TableCell>

                              <TableCell id="td-center">{index + 1}</TableCell>

                              {salaryLedger_schema && // eslint-disable-next-line
                                Object.keys(salaryLedger_schema).map(
                                  // eslint-disable-next-line
                                  (key, index: React.Key) => {
                                    // @ts-ignore
                                    const fieldSchema =
                                      salaryLedger_schema[key];

                                    if (
                                      fieldSchema.required ||
                                      fieldSchema.required === undefined
                                    )
                                      return (
                                        <TableCell key={index}>
                                          {record[key]}
                                        </TableCell>
                                      );
                                  }
                                )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && records.filter((record) => record?.errors) && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="studentlist__table">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center"></TableCell>
                          <TableCell id="td-center">Sl</TableCell>
                          {salaryLedger_schema &&
                            // eslint-disable-next-line
                            Object.keys(salaryLedger_schema).map(
                              // eslint-disable-next-line
                              (key, index) => {
                                // @ts-ignore
                                const fieldSchema = salaryLedger_schema[key];
                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                ) {
                                  return (
                                    <TableCell key={index} id="td-center">
                                      {key}
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                          <TableCell id="td-center">Error Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => {
                            const filteredHeaders: string[] = [];
                            return (
                              <TableRow key={index}>
                                <TableCell id="td-center">
                                  {/* {!record?.errors ? (
                                    <Checkbox
                                      onClick={() => handleClick(index)}
                                      checked={record?.isChecked}
                                    />
                                  ) : null} */}
                                </TableCell>

                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                {Object.keys(salaryLedger_schema!).map(
                                  (key, index) => {
                                    // @ts-ignore
                                    const fieldSchema =
                                      salaryLedger_schema[key];
                                    if (
                                      fieldSchema.required ||
                                      fieldSchema.required === undefined
                                    ) {
                                      filteredHeaders.push(key);
                                      return (
                                        <TableCell key={index}>
                                          {record[key]}
                                        </TableCell>
                                      );
                                    }
                                    return null;
                                  }
                                )}

                                <TableCell className="import-excel-data__datablock--error">
                                  <CustomTooltip
                                    title={
                                      <ul>
                                        {record?.errors
                                          ? record?.errors?.map(
                                              (error: any, index: number) => {
                                                return (
                                                  <li key={index}>
                                                    {index + 1})&nbsp;
                                                    {typeof error === "string"
                                                      ? error
                                                      : "Error message = " +
                                                        error.error +
                                                        ", column = " +
                                                        error.column}
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    }
                                    arrow
                                    placement="bottom-start"
                              
                                  >
                                    <div className="import-excel-data__datablock--tableblock--error-message">
                                      <span>Errors</span>
                                      <img src={Info} alt="/" />
                                    </div>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button
              onClick={handleSubmit}
              mode="save"
              disabled={
                !records.filter((record) => record?.isChecked).length ||
                !records.length
                  ? true
                  : false
              }
            />
            <Button onClick={handleClear} mode="clear" />

            <Button onClick={() => downloadExcel(true)} mode="export">
              error Data
            </Button>
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={() => downloadExcel(false)}>
              Generate Template
            </Button>
          </div>
        </div>
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default SalaryLedgerImport;

import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import * as XLSX from "xlsx";

// import Home from "../../Home/Index";
// import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../../images/TotalMale.svg";
import ReadyToImport from "../../../images/Present.svg";
import ContainsError from "../../../images/Absent.svg";
import Info from "../../../images/Information.svg";
import DownArrow from "../../../images/DownArrow.svg";
import FileAttach from "../../../images/BrowseFiles.svg";

import ExcelJS from "exceljs";
import { Cell } from "exceljs";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getHeaderRowStyle,
  handleClear,
  removeMoreSpace,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import {
  ACC_HEADER_FONT,
  BLOB_TYPE,
  DOWNLOAD,
  ELEMENT,
  FIRST_INDEX,
  TABLE_HEADER_CSS,
  FROZEN_CELLS_1,
  FILENAME,
  EDUATE_IMG_FORMAT,
  FIRST_CELL,
  HEADER_CSS,
  HEADER_ALIGNMENT,
  A2_CELL,
  A3_CELL,
  ADDRESS_FONT,
  ADDRESS_ALIGNMENT,
  C4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  HEADER_ALIGNMENT_CENTER,
  E4_CELL,
  FIN_YEAR_FONT,
  A4_CELL,
  HEADER_ALIGNMENT_LEFT,
  FROZEN_CELLS_INST,
  BORDER_DATA,
  PATTERN,
  SOLID,
} from "../../Library/Constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  Operation,
  PredefinedDataTypes,
  TableHeaders,
} from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { Button } from "../../../stories/Button/Button";
import LoadingModal from "../../../pages/LoadingModal";
import { Title } from "../../../stories/Title/Title";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import Eduate from "../../../images/Eduate_Logo_image.png";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { EMPTY_STRING } from "../../../utils/constants";
import { AddPayRollLeaveLdgr } from "../queries/leaveledger/mutation";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import { CustomTooltip } from "../../../styles/TooltipStyles";

const LeaveLedgersImport = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const [records, setRecords] = useState<any[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });

  const { InstDetails } = useInstDetails(1);
  const {
    PredefinedData: { dropDown: leaveTypes },
  } = usePredefinedDataByType(PredefinedDataTypes.LEAVE_TYPE, EMPTY_STRING);

  const [AddLeaveLedgers, { loading }] = useMutation(AddPayRollLeaveLdgr);

  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});
  const { serverDate } = useServerDateandTime();

  const InstName = InstDetails.data?.nodes[0]?.inst_name;

  const LeaveLedgerData = [
    {
      Headers: "LeaveLedgerDesc",
      key: "leave_desc",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveShortCode",
      key: "leave_short_code",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveType",
      key: "leave_type",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveOnlyForWomen",
      key: "leave_only_for_women",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveIsCredited",
      key: "leave_is_credited",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveIsCarryForward",
      key: "leave_carry_forwarded",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveForSalaryNotUsed",
      key: "leave_for_salary_not_used",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveIsEarned",
      key: "leave_is_earned",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LeaveIsSystemDefined",
      key: "leave_is_system_defined",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
  ];
  const leaveLedger_schema = {
    LeaveLedgerDesc: {
      name: "leave_desc",
      required: true,
    },
    LeaveShortCode: {
      name: "leave_short_code",
      required: true,
    },
    LeaveType: {
      name: "leave_type",
      required: true,
    },
    LeaveOnlyForWomen: {
      name: "leave_only_for_women",
    },
    LeaveIsCredited: {
      name: "leave_is_credited",
    },
    LeaveIsCarryForward: {
      name: "leave_carry_forwarded",
    },
    LeaveForSalaryNotUsed: {
      name: "leave_for_salary_not_used",
    },
    LeaveIsEarned: {
      name: "leave_is_earned",
      required: true,
    },
    LeaveIsSystemDefined: {
      name: "leave_is_system_defined",
      required: true,
    },
  };
  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      leave_desc: f.LeaveLedgerDesc,
      leave_short_code: f.LeaveShortCode,
      leave_type: f.LeaveType,
      leave_only_for_women: f.LeaveOnlyForWomen,
      leave_is_credited: f.LeaveIsCredited,
      leave_carry_forwarded: f.LeaveIsCarryForward,
      leave_for_salary_not_used: f.LeaveForSalaryNotUsed,
      leave_is_earned: f.LeaveIsEarned,
      leave_is_system_defined: f.LeaveIsSystemDefined,
    }));

  const downloadExcel = (forError: boolean) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("HrLeaveLedgerData");
    worksheet.views = FROZEN_CELLS_INST;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    worksheet1.views = FROZEN_CELLS_1;

    worksheet1.getColumn(1).width = 30;
    worksheet1.getColumn(2).width = 30;
    worksheet1.getColumn(3).width = 30;
    worksheet1.getColumn(4).width = 30;
    worksheet1.getColumn(5).width = 30;
    worksheet1.getColumn(6).width = 30;
    worksheet1.getColumn(7).width = 30;
    worksheet1.getColumn(8).width = 30;
    worksheet1.getColumn(9).width = 30;
    worksheet1.getColumn(10).width = 30;
    worksheet1.getColumn(11).width = 30;
    worksheet1.getColumn(12).width = 30;
    worksheet1.getColumn(13).width = 30;
    worksheet1.getColumn(14).width = 30;
    worksheet1.getColumn(15).width = 30;
    worksheet1.getColumn(16).width = 30;
    worksheet1.getColumn(17).width = 30;
    worksheet1.getColumn(18).width = 30;
    worksheet1.getColumn(19).width = 30;
    worksheet1.getColumn(20).width = 30;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  let headers2 = LeaveLedgerData.filter(
                    (leave_ledger) => leave_ledger.use_in_sheet
                  ).map((f) => f.Headers);

                  Char = String.fromCharCode(Char.charCodeAt(0) + 1);
                  const rowData: Cell = worksheet.getCell(Char + 5);
                  rowData.value = "Leave Types";
                  rowData.fill = TABLE_HEADER_CSS;
                  rowData.border = BORDER_DATA;
                  rowData.font = ACC_HEADER_FONT;
                  rowData.alignment = { horizontal: ExcelAlignment.CENTER };

                  for (let i = 0; i < headers2.length; i++) {
                    const Char = String.fromCharCode(65 + i);
                    const rowData2: Cell = worksheet1.getCell(Char + 1);
                    rowData2.value = headers2[i];
                    rowData2.fill = TABLE_HEADER_CSS;
                    rowData2.border = BORDER_DATA;
                    rowData2.font = ACC_HEADER_FONT;
                    rowData2.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }

                  let data_begins = 6;

                  worksheet.mergeCells(
                    `A${6 + leaveTypes.length}:C${6 + leaveTypes.length}`
                  );
                  const noteData: Cell = worksheet.getCell(
                    `A${6 + leaveTypes.length}`
                  );
                  noteData.value = "Note";
                  noteData.fill = {
                    type: PATTERN,
                    pattern: SOLID,
                    fgColor: { argb: "f0a8a3" },
                  };
                  for (let i = 0; i < leaveTypes.length; i++) {
                    const index_pos = Number(data_begins + i);
                    const rowData: Cell = worksheet.getCell("A" + index_pos);

                    rowData.value = leaveTypes[i].label;
                    rowData.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }
                  let note_data_begins = data_begins + leaveTypes.length + 1;
                  for (let i = 0; i < leaveTypes.length; i++) {
                    const index_pos = Number(note_data_begins + i);
                    const rowData: Cell = worksheet.getCell("A" + index_pos);

                    rowData.value = `${leaveTypes[i].value} : ${leaveTypes[i].label}`;
                    rowData.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }

                  if (forError) {
                    errorData.forEach((err) =>
                      worksheet1.addRow(Object.values(err))
                    );
                  }
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName!);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];
    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      leaveLedger_schema &&
        // eslint-disable-next-line
        Object.keys(leaveLedger_schema).map((key) => {
          // @ts-ignore
          let keySchema = leaveLedger_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleClick = (
    id: number,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { checked } = e.target as HTMLInputElement;
    const newData = records.map((record, index) => {
      if (id === record.id) {
        return {
          ...record,
          isChecked: checked ? true : false,
        };
      }
      return record;
    });
    setRecords(newData);
  };

  const handleSubmit = async () => {
    for (const record of records.filter(
      (record) => !record.isValidatedAndError && record.isChecked
    )) {
      try {
        await AddLeaveLedgers({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: {
              leave_desc: removeMoreSpace(record.LeaveLedgerDesc),
              leave_type: removeMoreSpace(record.LeaveType),
              leave_short_code: removeMoreSpace(record.LeaveShortCode),
              leave_only_for_women:
                removeMoreSpace(record.LeaveOnlyForWomen.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.LeaveOnlyForWomen.toLowerCase()) === "n"
                  ? false
                  : true,
              leave_is_credited:
                removeMoreSpace(record.LeaveIsCredited.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.LeaveIsCredited.toLowerCase()) === "n"
                  ? false
                  : true,
              leave_carry_forwarded:
                removeMoreSpace(record.LeaveIsCarryForward.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.LeaveIsCarryForward.toLowerCase()) ===
                  "n"
                  ? false
                  : true,
              leave_for_salary_not_used:
                removeMoreSpace(record.LeaveForSalaryNotUsed.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.LeaveForSalaryNotUsed.toLowerCase()) ===
                  "n"
                  ? false
                  : true,
              leave_is_earned:
                removeMoreSpace(record.LeaveIsEarned.toLowerCase()) === "no" ||
                removeMoreSpace(record.LeaveIsEarned.toLowerCase()) === "n"
                  ? false
                  : true,
              leave_is_system_defined:
                removeMoreSpace(record.LeaveIsSystemDefined.toLowerCase()) ===
                  "no" ||
                removeMoreSpace(record.LeaveIsSystemDefined.toLowerCase()) ===
                  "n"
                  ? false
                  : true,
            },
          },

          onError: (err) => {
            record.errors = record.errors ? record.errors : [];
            record.errors.push(err.message);
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(
    () => {
      const input = document.getElementById("input") as HTMLInputElement;
      if (input) {
        input.addEventListener("change", () => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const res = processExcel(reader.result);
            setRecords(
              res.map((f: any, index: number) => {
                return {
                  ...f,
                  id: index + 1,
                  isChecked: false,
                };
              })
            );
          };
          reader.readAsBinaryString(input.files![0]);
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {/* <Home DashBoardRequired={false} /> */}
      <Title>Import Leave Ledgers Data</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Leave Ledgers
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="studentlist__table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {/* <Checkbox /> */}
                          </TableCell>
                          <TableCell id="td-center">Sl</TableCell>
                          {leaveLedger_schema &&
                            Object.keys(leaveLedger_schema).map(
                              // eslint-disable-next-line
                              (key, index) => {
                                // @ts-ignore
                                const fieldSchema = leaveLedger_schema[key];
                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                ) {
                                  return (
                                    <TableCell key={index} id="td-center">
                                      {key}
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records.map((record, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell id="td-center">
                                <Checkbox
                                  onClick={(e) => handleClick(record.id, e)}
                                  checked={record?.isChecked}
                                />
                              </TableCell>

                              <TableCell id="td-center">{index + 1}</TableCell>

                              {leaveLedger_schema && // eslint-disable-next-line
                                Object.keys(leaveLedger_schema).map(
                                  // eslint-disable-next-line
                                  (key, index: React.Key) => {
                                    // @ts-ignore
                                    const fieldSchema = leaveLedger_schema[key];

                                    if (
                                      fieldSchema.required ||
                                      fieldSchema.required === undefined
                                    )
                                      return (
                                        <TableCell key={index}>
                                          {record[key]}
                                        </TableCell>
                                      );
                                  }
                                )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && records.filter((record) => record?.errors) && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell id="td-center"></TableCell> */}
                          <TableCell id="td-center">{TableHeaders.SLNO}</TableCell>
                          {leaveLedger_schema && // eslint-disable-next-line
                            Object.keys(leaveLedger_schema).map(
                              // eslint-disable-next-line
                              (key, index) => {
                                // @ts-ignore
                                const fieldSchema = leaveLedger_schema[key];
                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                ) {
                                  return (
                                    <TableCell key={index} id="td-center">
                                      {key}
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                          <TableCell >Error Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => {
                            const filteredHeaders: string[] = [];
                            return (
                              <TableRow key={index}>
                                {/* <TableCell id="td-center">
                                  {!record?.errors ? (
                                    <Checkbox
                                      onClick={() => handleClick(index)}
                                      checked={record?.isChecked}
                                    />
                                  ) : null}
                                </TableCell> */}

                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                {Object.keys(leaveLedger_schema!).map(
                                  (key, index) => {
                                    // @ts-ignore
                                    const fieldSchema = leaveLedger_schema[key];
                                    if (
                                      fieldSchema.required ||
                                      fieldSchema.required === undefined
                                    ) {
                                      filteredHeaders.push(key);
                                      return (
                                        <TableCell
                                          key={index}
                                          
                                        >
                                          {record[key]}
                                        </TableCell>
                                      );
                                    }
                                    return null;
                                  }
                                )}

                                <TableCell className="import-excel-data__datablock--error">
                                  <CustomTooltip
                                    title={
                                      <ul>
                                        {record?.errors
                                          ? record?.errors?.map(
                                              (error: any, index: number) => {
                                                return (
                                                  <li key={index}>
                                                    {index + 1})&nbsp;
                                                    {typeof error === "string"
                                                      ? error
                                                      : "Error message = " +
                                                        error.error +
                                                        ", column = " +
                                                        error.column}
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    }
                                    arrow
                                    placement="bottom-start"
                                   
                                  >
                                    <div className="import-excel-data__datablock--tableblock--error-message">
                                      <span>Errors</span>
                                      <img src={Info} alt="/" />
                                    </div>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button
              onClick={handleSubmit}
              mode="save"
              disabled={
                !records.filter((record) => record?.isChecked).length ||
                !records.length
                  ? true
                  : false
              }
            />
            <Button onClick={handleClear} mode="clear" />

            <Button onClick={() => downloadExcel(true)} mode="export">
              error Data
            </Button>
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={() => downloadExcel(false)}>
              Generate Template
            </Button>
          </div>
        </div>
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default LeaveLedgersImport;

import React from "react";
import { TEXT_FONT_FAMILY } from "../../../../styles/DataGridTableStyles";
import ReactEcharts from "echarts-for-react";
interface Prop {
  percentage: number;
}
const ProgressGraph = ({ percentage }: Prop) => {
  const options = {
    tooltip: {
      trigger: "item",
      position: "bottom",
    },
    legend: {
      show: false,
    },
    textStyle: {
      fontSize: 14,
      fontFamily: TEXT_FONT_FAMILY,
      color: "#1e293b",
    },
    series: [
      {
        name: "Progress",
        type: "pie",
        radius: ["40%", "70%"],
        label: {
          show: true,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: percentage,
            name: "Progress",
          },
          {
            value: 100 - percentage,
            name: "Pending",
          },
        ],
      },
    ],

    color: ["#22c55e", "#ef4444"],
  };
  return (
    <>
      <ReactEcharts option={options} />
    </>
  );
};

export default ProgressGraph;

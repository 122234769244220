import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Title } from "../../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../../Types/Tables";
import { Button } from "../../../../../stories/Button/Button";
import EmployeeDetails from "./EmployeeDetails";
const { HR_Table } = require("../../../json/table.json");

const SalaryDetails = () => {
  return (
    <>
      <Title>Salary Details</Title>
      <div className="payroll-reports__payslip">
        <EmployeeDetails />
        <div className="payroll-reports__payslip--tableblock">
          <TableContainer className="payroll-reports__payslip--salary-table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {HR_Table.SalaryStructure.AssignSalaryStructure.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="assign-salary-structure__table--slno"
                  >
                    1
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    Basic
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--desc"
                    id="td-center"
                  ></TableCell>
                  <TableCell
                    className="assign-salary-structure__table--rule"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="assign-salary-structure__table--amount editCell"></TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-center"
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="assign-salary-structure__table--slno"
                  >
                    2
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    Additional Payments
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--desc"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="assign-salary-structure__table--rule">
                    0 - 5000 = 20%, 5001 - 8000 = 10%, 8001 - Above = 5%
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-right"
                  >
                    0.00
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--amount"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="assign-salary-structure__table--slno "
                  >
                    3
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    Special Allowances
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--desc"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="assign-salary-structure__table--rule">
                    FLR : 10%;
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-right"
                  >
                    0.00
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="assign-salary-structure__table--slno"
                  >
                    4
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    HRA
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--desc"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="assign-salary-structure__table--rule">
                    UDV : 300;
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-right"
                  >
                    0.00
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="assign-salary-structure__table--slno"
                  >
                    5
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    Fixed PP
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--desc"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="assign-salary-structure__table--rule">
                    UDVM
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--amount editCell"></TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-center"
                  >
                    -
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="assign-salary-structure__table--slno"
                  >
                    6
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    PF
                  </TableCell>
                  <TableCell
                    className="assign-salary-structure__table--desc"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="assign-salary-structure__table--rule">
                    UDVM
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--amount"></TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount editCell "
                    id="td-right"
                  >
                    3
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="assign-salary-structure__table--slno"
                  >
                    7
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    ESI
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--desc">
                    Basic Salary :
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--rule">
                    0 - 3000 = 15%, 3001 - 9000 = 5%, 9001 - Above = 2%.
                  </TableCell>
                  <TableCell className="assign-salary-structure__table--amount"></TableCell>
                  <TableCell
                    className="assign-salary-structure__table--amount"
                    id="td-right"
                  >
                    0.00
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} className="total ">
                    Total :
                  </TableCell>
                  <TableCell
                    className="totalcount assign-salary-structure__table--amount"
                    id="td-right"
                  >
                    0.00
                  </TableCell>
                  <TableCell
                    className="balance-count assign-salary-structure__table--amount"
                    id="td-right"
                  >
                    0.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5} className="total">
                    Net :
                  </TableCell>
                  <TableCell
                    className="totalcount assign-salary-structure__table--amount"
                    id="td-right"
                  >
                    0.00
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Button mode="print" />
    </>
  );
};

export default SalaryDetails;

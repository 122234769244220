import { gql } from "@apollo/client";
export const GetCustomers = gql`
  query GetCustomers(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: MstCustomerOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetCustomers(
      token: $token
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ custNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          cust_name
          cust_place
          cust_contact_person
          cust_mobile
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
// export const characters = gql`
//   query Characters($page: Int, $pageSize: Int, $after: String) {
//     characters(page: $page, pageSize: $pageSize, after: $after) {
//       info {
//         next
//       }
//       results {
//         id
//         name
//       }
//     }
//   }
// `;
// export const countries = gql`
//   query countries {
//     countries {
//       code
//     }
//   }
// `;
export const CheckEmpResetPwdStatus = gql`
  query CheckEmpResetPwdStatus(
    $token: String!
    $inst_id: ID!
    $pr_emp_id: ID!
  ) {
    CheckEmpResetPwdStatus(
      token: $token
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
    ) {
      status
      message
    }
  }
`;
export const CheckStdResetPwdStatus = gql`
  query CheckStdResetPwdStatus(
    $token: String!
    $inst_id: ID!
    $student_id: ID!
  ) {
    CheckStdResetPwdStatus(
      token: $token
      inst_id: $inst_id
      student_id: $student_id
    ) {
      status
      message
    }
  }
`;

import React from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";

const { VMS_Table } = require("../../json/table.json");

const Index = () => {
  const navigate = useNavigate();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Staff Details</Title>
      <div className="vms-staff-details">
        <div className="vms-staff-details__select row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={["CS", "Civil"]}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={["CS", "Civil"]}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="addnew"
              className="vms-staff-details__select--addnew"
              onClick={() => navigate(`/vms/staffdetails/new`)}
            />
          </div>
        </div>
        <div className="vms-staff-details__tableblock">
          <TableContainer className="vms-staff-details__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {VMS_Table.Staff_Details.TableHeaders.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="vms-staff-details__table--slno"
                    id="td-center"
                  ></TableCell>
                  <TableCell
                    className="vms-staff-details__table--emp-id"
                    onClick={() => navigate(`/vms/staffdetails/preview`)}
                  >
                    EMP001
                  </TableCell>

                  <TableCell></TableCell>

                  <TableCell className="vms-staff-details__table--degn"></TableCell>

                  <TableCell className="vms-staff-details__table--number"></TableCell>

                  <TableCell className="vms-staff-details__table--date"></TableCell>

                  <TableCell className="vms-staff-details__table--license-no"></TableCell>

                  <TableCell className="vms-staff-details__table--mobile-no"></TableCell>

                  <TableCell
                    className="vms-staff-details__table--actions"
                    id="td-center"
                  >
                    <img src={Edit} alt="" />
                    <img src={Delete} alt="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

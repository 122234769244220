import React, { useContext,  useState } from "react";
import More from "../../../../images/More.svg";
import {  TODAY_DATE } from "../../../../utils/constants";
import { Title } from "../../../../stories/Title/Title";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import Nodata from "../../../../images/No_data.gif";

import Message from "../../../../images/SendMessage.svg";
import BirthdayCake from "../../../../images/Birthday_Cake.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import MessageListModal from "../../../Channel/MessageList";
import { PageFor, UserType } from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";
import MoreBirthdayList from "./MoreBirthdayList";

import useStudentDataToGetBirthday from "../../../../customhooks/useStudentDataToGetBirthday";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import { Button } from "../../../../stories/Button/Button";
interface props {
  userType: UserType;
}

const BirthdayList = ({ userType }: props) => {
  const [messageModal, setMessageModal] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const { data: ForAdmin } = useStudentDataToGetBirthday(
    StudentAcdType.CUR_STD_HAS_DOB_TODAY
  );
  const { data: Teacher } = useStudentDataToGetBirthday(
    StudentAcdType.CUR_STD_HAS_DOB_TODAY
  );

  const { dispatch } = useContext(AppContext);

  return (
    <>
      <div className="birthday-list">
        <div className="birthday-list__title">
          <Title variant="subtitle1">Today's Birthday's List</Title>
          <img src={More} alt="" />
        </div>
        <div className="birthday-list__data">
          <div className="birthday-list__data--gif">
            <img src={BirthdayCake} alt="" />
          </div>
          <div className="birthday-list__data--subtitle">
            <b>{toStandardDate(TODAY_DATE)}</b>
          </div>
          <div className="birthday-list__data--total">
            Total Birthday's
            <span className="birthday-list__data--total--count">
              {userType === UserType.TEACHER ? (
                <>{Teacher ? Teacher.GetAcdStudents.totalCount : 0}</>
              ) : (
                <>{ForAdmin ? ForAdmin.GetAcdStudents.totalCount : 0}</>
              )}
            </span>
          </div>
          <ul className="birthday-list__data--list">
            {userType === UserType.TEACHER ? (
              <>
                {Teacher ? (
                  Teacher.GetAcdStudents.edges.map((res, index) => {
                    return (
                      <li key={index}>
                        <div className="birthday-list__data--list--flex">
                          <span>{index + 1}.</span>
                          {/* <img src={res.node.} alt="" /> */}
                          <div>
                            <b>
                              {res.node.first_name +
                                res.node.middle_name +
                                res.node.last_name}
                            </b>
                            <span>{toStandardDate(res.node.std_dob)}</span>
                          </div>
                        </div>
                        <img
                          src={Message}
                          alt=""
                          onClick={() => {
                            dispatch({
                              type: payloadTypes.SET_STUDENT_ID,
                              payload: { studentId: res.node.id },
                            });
                            setMessageModal(!messageModal);
                          }}
                        />
                      </li>
                    );
                  })
                ) : (
                  <>
                  <img src={Nodata} alt="" className="birthday-list__data--list--image"/>
                  </>
                )}
              </>
            ) : (
              <>
                {ForAdmin ? (
                  ForAdmin.GetAcdStudents.edges.map((res, index) => {
                    return (
                      <li key={index}>
                        <div className="birthday-list__data--list--flex">
                          <span>{index + 1}.</span>
                          {/* <img src={res.node.} alt="" /> */}
                          <div>
                            <b>
                              {res.node.first_name +
                                res.node.middle_name +
                                res.node.last_name}
                            </b>
                            <span>{toStandardDate(res.node.std_dob)}</span>
                          </div>
                        </div>
                        <img
                          src={Message}
                          alt=""
                          onClick={() => {
                            dispatch({
                              type: payloadTypes.SET_STUDENT_ID,
                              payload: { studentId: res.node.id },
                            });
                            setMessageModal(!messageModal);
                          }}
                        />
                      </li>
                    );
                  })
                ) : (
                  <>
                  <img src={Nodata} alt="" className="birthday-list__data--list--image"/>
                  </>
                )}</>
            )}
          </ul>
          <div className="birthday-list__data--button">
            <Button mode="view" onClick={() => setMoreDetails(true)}>
              &nbsp;More Details
            </Button>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={moreDetails}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <MoreBirthdayList setModal={setMoreDetails} />
      </Modal>
    </>
  );
};

export default BirthdayList;

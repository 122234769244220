import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  DebitOrCredit,
  InstitutionConfigurationTypes,
  PageFor,
  StudentAcctReportType,
  StudentListFor,
  UserType,
  VoucherBookKey,
} from "../../../../utils/Enum.types";
import {
  AccountingLedgerModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import { Button } from "../../../../stories/Button/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  studentDemandVars,
  GetStdCompleteFeeDetailsByStudentIDDetails,
  GetStdCompleteFeeDetailsByStudentIDData,
} from "../../../../Types/Accounting";
import { useLazyQuery } from "@apollo/client";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../queries/students/list/byId";
import { AppContext } from "../../../../context/context";
import { formatter, toStandardDate } from "../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import Avatar from "../../../../images/Avatar.svg";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../../utils/constants";
import { responseType } from "../../../../utils/Form.types";
import Close from "../../../../images/Close.svg";
import Edit from "../../../../images/Edit.svg";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import StudentVoucherView from "../../Reports/AccountLedgerBook/Modals/StudentVoucherView";
import useStudentDepositDetails from "../../hooks/useStudentDepositDetails";
import { Keys } from "../../../../utils/Enum.keys";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../context/reducer";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import StudentList from "../../common/StudentList";
import { DepositLedgerQueryType } from "../../common/QueryTypes";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import DepositDetails from "./DepositDetails";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import useStudentReceiptDetails from "../../hooks/useStudentReceiptDetails";
import Print from "../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import { Data } from "../../../Print/Accounts/FeeReceipts/Standard/Index";
import VoucherPrint from "../../../Print/Accounts/Vouchers/VoucherPrint";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

interface Props {
  PageType: PageFor;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ PageType, setModalFlag }: Props) => {
  const { InstId, studentId } = useParams<{
    InstId: string;
    studentId: string;
  }>();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const navigate = useNavigate();
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const [studentModal, setStudentModal] = useState(false);
  const [studentDemandModal, setStudentDemandModal] = useState(false);
  const [imageString, setImageString] = useState("");
  const [admNo, setAdmNo] = useState("");
  const [depositDetails, setDepositDetails] = useState(false);
  const { user_details } = useLoggedInUserDetails();

  const { InstDetails } = useInstDetails(1);
  const { studentData, studentFormData } = useStudentDatabyId();

  const [printModal, SetPrintModal] = useState(false);

  const [completeFeeReceiptData, setStudentCompleteFeeReceiptData] =
    useState<GetStdCompleteFeeDetailsByStudentIDDetails>();

  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.GENERAL
  );

  const { studentReceiptDetails } = useStudentReceiptDetails();

  const { StudentDepositData } = useStudentDepositDetails(
    DepositLedgerQueryType.ALL_DEPOSIT_LDGRS_BY_STD_ID
  );

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_SCHOLARSHIP
  );
  const filteredDebitTransactions = studentReceiptDetails
    ? studentReceiptDetails.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.DEBIT
      )
    : [];
  const filteredCreditTransactions = studentReceiptDetails
    ? studentReceiptDetails.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.CREDIT
      )
    : [];
  let transactionArray =
    (studentReceiptDetails && studentReceiptDetails.v_std_refund) ||
    (studentReceiptDetails && studentReceiptDetails.v_std_refund_deposit)
      ? filteredDebitTransactions
      : filteredCreditTransactions;

  const scholarshipEnabled =
    configData.data && configData.data.GetSwConfigVariables.length
      ? configData.data.GetSwConfigVariables[0].config_boolean_value
      : false;

  //lazy queries
  const [GetStdCompleteFeeReceiptsByStudentID, { data }] = useLazyQuery<
    GetStdCompleteFeeDetailsByStudentIDData,
    studentDemandVars
  >(GetStdCompleteFeeDetailsByStudentID, { fetchPolicy: "network-only" });
  useEffect(() => {
    if (state.studentId && state.ActiveFinYr) {
      GetStdCompleteFeeReceiptsByStudentID({
        variables: {
          token,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId! ?? state.InstId!,
          student_id: state.studentId,
        },
      }).then(({ data }) => {
        if (data)
          setStudentCompleteFeeReceiptData(
            data.GetStdCompleteFeeDetailsByStudentID!
          );
      });
    }
  }, [
    state.studentId,
    token,
    GetStdCompleteFeeReceiptsByStudentID,
    state.ActiveFinYr,
    InstId,
    data,
    state.InstId,
  ]);

  const demandAccumulator = completeFeeReceiptData?.std_demand_details.reduce(
    (acc, demand) => {
      acc.demandTotal += demand.fee_demand;
      acc.demandtotalconcession += demand.fee_concession;
      acc.totalbalance += demand.fee_bal;
      acc.totalPayable += demand.fee_receivable;
      acc.totalpaidAmount += demand.fee_received;
      acc.totalRefund += demand.fee_refunds;
      acc.totalOb += demand.fee_ob;
      return acc;
    },
    {
      demandTotal: 0,
      demandtotalconcession: 0,
      totalbalance: 0,
      totalPayable: 0,
      totalpaidAmount: 0,
      totalRefund: 0,
      totalOb: 0,
    }
  );

  // Total deposit data
  const depositAccumulator = StudentDepositData.data?.GetAcctStdDeposits.edges
    .filter((edge) => !edge.node.is_scholarship)
    .reduce(
      (acc, item) => {
        acc.totalDepositOb += item.node.deposit_ob;
        acc.totalDepositAmount += item.node.deposit_amt;
        acc.totalDepositTotal += item.node.deposit_total;
        acc.totalDepositAdjusted += item.node.deposit_adjusted;
        acc.dbTotalDepositRefunded += item.node.deposit_refunded;
        acc.totalDepositBalance += item.node.deposit_bal;
        return acc;
      },
      {
        totalDepositOb: 0,
        totalDepositAmount: 0,
        totalDepositTotal: 0,
        totalDepositAdjusted: 0,
        dbTotalDepositRefunded: 0,
        totalDepositBalance: 0,
      }
    );

  const ScholarshipsAccumulatr =
    StudentDepositData.data?.GetAcctStdDeposits.edges
      .filter((edge) => edge.node.is_scholarship)
      .reduce(
        (acc, item) => {
          acc.totalDepositOb += item.node.deposit_ob;
          acc.totalDepositAmount += item.node.deposit_total;
          acc.totalDepositReceived += item.node.deposit_amt;
          acc.totalDepositAdjusted += item.node.deposit_adjusted;
          acc.dbTotalDepositRefunded += item.node.deposit_refunded;
          acc.totalDepositBalance += item.node.deposit_bal;
          return acc;
        },
        {
          totalDepositOb: 0,
          totalDepositAmount: 0,
          totalDepositReceived: 0,
          totalDepositAdjusted: 0,
          dbTotalDepositRefunded: 0,
          totalDepositBalance: 0,
        }
      );
  // Total amount for receipt pages
  const receiptAccumulator = completeFeeReceiptData
    ? completeFeeReceiptData.std_demand_receipts.reduce(
        (acc, receipt) => {
          acc.receiptTotalFine += receipt.v_std_amt_fine;
          if (receipt.v_std_demand_receipt) {
            acc.receiptTotalDemandFee += receipt.v_std_amt_receipt;
          }
          if (!receipt.v_std_demand_receipt) {
            acc.receiptTotalNonDemandFee += receipt.v_std_amt_receipt;
          }
          if (receipt.v_std_deposit) {
            acc.receiptTotalDeposit += receipt.v_std_amt_deposit;
          }
          acc.receiptTotalAmount += receipt.v_std_amt_total;
          return acc;
        },
        {
          receiptTotalFine: 0,
          receiptTotalDemandFee: 0,
          receiptTotalNonDemandFee: 0,
          receiptTotalDeposit: 0,
          receiptTotalAmount: 0,
        }
      )
    : {
        receiptTotalFine: 0,
        receiptTotalDemandFee: 0,
        receiptTotalNonDemandFee: 0,
        receiptTotalDeposit: 0,
        receiptTotalAmount: 0,
      };
  //total refunded amount
  let refundBLockRefundAmount = 0;
  completeFeeReceiptData?.std_refunds_recepits.map(
    (refund) => (refundBLockRefundAmount += refund.v_std_amt_refunded)
  );
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    } else {
      setImageString("");
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  const { branchLabel, classLabel,categoryLabel } = useInstLabels();
  const loggedUserStudentOrUser =
    user_details.user_type === UserType.STUDENT ||
    user_details.user_type === UserType.PARENT;

  const showConcession = completeFeeReceiptData
    ? (completeFeeReceiptData.std_demand_details.filter(
        (demand_detail) => demand_detail.fee_concession > 0
      ).length > 0 &&
        loggedUserStudentOrUser) ||
      loggedUserStudentOrUser === false
    : false;
  const showDemandRefund = completeFeeReceiptData
    ? (completeFeeReceiptData.std_demand_details.filter(
        (demand_detail) => demand_detail.fee_refunds > 0
      ).length > 0 &&
        loggedUserStudentOrUser) ||
      loggedUserStudentOrUser === false
    : false;
  const showDepositRefund = StudentDepositData.data
    ? (StudentDepositData.data.GetAcctStdDeposits.edges.filter(
        ({ node }) => node.deposit_refunded > 0
      ).length > 0 &&
        loggedUserStudentOrUser) ||
      loggedUserStudentOrUser === false
    : false;

  const showRefunds = completeFeeReceiptData
    ? (completeFeeReceiptData.std_refunds_recepits.length > 0 &&
        loggedUserStudentOrUser) ||
      loggedUserStudentOrUser === false
    : false;

  const showConsolidatedConcession = completeFeeReceiptData
    ? (completeFeeReceiptData.std_demand.filter(
        (rcpt_data) => rcpt_data.std_demand_concession > 0
      ).length > 0 &&
        loggedUserStudentOrUser) ||
      loggedUserStudentOrUser === false
    : false;
  const showConsolidatedRefunded = completeFeeReceiptData
    ? (completeFeeReceiptData.std_demand.filter(
        (rcpt_data) => rcpt_data.std_deposit_refunded > 0
      ).length > 0 &&
        loggedUserStudentOrUser) ||
      loggedUserStudentOrUser === false
    : false;

  const showConsolidatedRefunds = completeFeeReceiptData
    ? (completeFeeReceiptData.std_demand.filter(
        (rcpt_data) => rcpt_data.std_demand_refunds > 0
      ).length > 0 &&
        loggedUserStudentOrUser) ||
      loggedUserStudentOrUser === false
    : false;

  return (
    <>
      {PageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Student Complete Fee Receipt</Title>
      <div
        className={
          PageType === PageFor.GENERAL
            ? "std-complete-fee-receipt"
            : "std-complete-fee-receipt--modal"
        }
      >
        <div className="row g-0 std-complete-fee-receipt__details">
          <div className="col">
            <div className="std-complete-fee-receipt__grid">
              {PageType === PageFor.GENERAL ? (
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={studentAddmissionNumber}
                  value={
                    state.studentId
                      ? studentAddmissionNumber?.find(
                          ({ value }) => value === state.studentId
                        )!
                      : null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      setAdmNo("");

                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setAdmNo(e.target.value)}
                      label="Admission No."
                      fullWidth
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              ) : null}
              {PageType === PageFor.GENERAL ? (
                <img
                  onClick={() => setStudentModal(!studentModal)}
                  className="data-fetch-icon"
                  src={Edit}
                  alt="/"
                />
              ) : null}
            </div>
            <TextField
              label="Register Number"
              className="refund-academic-fee__frame--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.reg_number}
              disabled
            />
            <TextField
              label={classLabel}
              className="refund-academic-fee__frame--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.class}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              className="refund-academic-fee__frame--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.std_name}
              disabled
            />
            <TextField
              label="Father Name"
              className="refund-academic-fee__frame--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.father_name}
              disabled
            />
            <TextField
              label={branchLabel}
              className="refund-academic-fee__frame--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.branch}
              disabled
            />
          </div>

          <div className="col">
            {/* <Label variant="AccountingLabel">
              Choose year to check all account Ledger Dropdown
            </Label> */}
            {/* <Select /> */}
            <TextField
               label={categoryLabel}
              className="refund-academic-fee__frame--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.category}
              disabled
            />
          </div>

          <div className="col-1 std-complete-fee-receipt__image h-100">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <div
          className={
            PageType === PageFor.GENERAL
              ? "std-complete-fee-receipt__block"
              : "std-complete-fee-receipt__block--modal"
          }
        >
          <div className="row g-0 std-complete-fee-receipt__frame--tableblock">
            <div className="col std-complete-fee-receipt__frame--block">
              <Title variant="subtitle1">Fee Structure</Title>
              <TableContainer className="std-complete-fee-receipt__frame--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.StudentCompleteFeeReceipt.DemandFeePayable.Table_Headers.filter(
                        (th) =>
                          (th.labelName === "Concession" && showConcession) ||
                          (th.labelName === "Refunds" && showDemandRefund) ||
                          (th.labelName !== "Concession" &&
                            th.labelName !== "Refunds")
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName === "Receivable" &&
                            state.claims &&
                            state.claims.STUDENT
                              ? "Payable"
                              : th.labelName === "Received" &&
                                state.claims &&
                                state.claims.STUDENT
                              ? "Paid"
                              : th.labelName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completeFeeReceiptData
                      ? completeFeeReceiptData.std_demand_details.map(
                          (demand, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  id="td-center"
                                  className="std-complete-fee-receipt__frame--table--slno"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  onClick={() =>
                                    setDepositDetails(!depositDetails)
                                  }
                                >
                                  {demand.acct_ldgr_details.ldgr_desc}
                                </TableCell>
                                <TableCell
                                  id="td-right"
                                  className="std-complete-fee-receipt__frame--table--amount"
                                >
                                  {format(demand.fee_ob)}
                                </TableCell>
                                <TableCell
                                  id="td-right"
                                  className="std-complete-fee-receipt__frame--table--amount"
                                >
                                  {format(demand.fee_demand)}
                                </TableCell>
                                {showConcession ? (
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(demand.fee_concession)}
                                  </TableCell>
                                ) : null}
                                <TableCell
                                  id="td-right"
                                  className="std-complete-fee-receipt__frame--table--amount"
                                >
                                  {format(demand.fee_receivable)}
                                </TableCell>
                                <TableCell
                                  id="td-right"
                                  className="std-complete-fee-receipt__frame--table--amount"
                                >
                                  {format(demand.fee_received)}
                                </TableCell>
                                {showDemandRefund ? (
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(demand.fee_refunds)}
                                  </TableCell>
                                ) : null}
                                <TableCell
                                  id="td-right"
                                  className="std-complete-fee-receipt__frame--table--amount"
                                >
                                  {format(demand.fee_bal)}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2} className="total">
                        Total :
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          demandAccumulator ? demandAccumulator.totalOb : 0
                        )}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          demandAccumulator ? demandAccumulator.demandTotal : 0
                        )}
                      </TableCell>
                      {showConcession ? (
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            demandAccumulator
                              ? demandAccumulator.demandtotalconcession
                              : 0
                          )}
                        </TableCell>
                      ) : null}
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          demandAccumulator ? demandAccumulator.totalPayable : 0
                        )}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          demandAccumulator
                            ? demandAccumulator.totalpaidAmount
                            : 0
                        )}
                      </TableCell>
                      {showDemandRefund ? (
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            demandAccumulator
                              ? demandAccumulator.totalRefund
                              : 0
                          )}
                        </TableCell>
                      ) : null}
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          demandAccumulator ? demandAccumulator.totalbalance : 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
            <div className="col std-complete-fee-receipt__frame--block">
              <Title variant="subtitle1">Receipts</Title>
              <TableContainer className="std-complete-fee-receipt__frame--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.StudentCompleteFeeReceipt.Receipts.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {completeFeeReceiptData
                      ? completeFeeReceiptData.std_demand_receipts.map(
                          (receipt, index) => {
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  onClick={() => {
                                    if (state.claims && state.claims.STUDENT) {
                                      dispatch({
                                        type: payloadTypes.SET_RECEPIT_ID,
                                        payload: {
                                          receiptId: receipt.id,
                                        },
                                      });
                                      SetPrintModal(true);
                                    } else {
                                      dispatch({
                                        type: payloadTypes.SET_RECEPIT_ID,
                                        payload: {
                                          receiptId: receipt.id,
                                        },
                                      });
                                      setStudentDemandModal(
                                        !studentDemandModal
                                      );
                                    }
                                  }}
                                >
                                  <TableCell
                                    id="td-center"
                                    className="std-complete-fee-receipt__frame--table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className="std-complete-fee-receipt__frame--table--amount">
                                    {receipt.v_no}
                                  </TableCell>

                                  <TableCell className="std-complete-fee-receipt__frame--table--date">
                                    {toStandardDate(receipt.v_date)}
                                  </TableCell>
                                  <TableCell className="std-complete-fee-receipt__frame--table--date">
                                    {receipt.v_transcation_type}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(receipt.v_std_amt_fine)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {!receipt.v_std_deposit_adjusted &&
                                    receipt.v_std_amt_deposit
                                      ? format(receipt.v_std_amt_deposit)
                                      : 0}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {receipt.v_std_demand_receipt
                                      ? format(receipt.v_std_amt_receipt)
                                      : 0}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {!receipt.v_std_demand_receipt &&
                                    !receipt.v_std_deposit
                                      ? format(receipt.v_std_amt_receipt)
                                      : 0}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(receipt.v_std_amt_total)}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          }
                        )
                      : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={4} className="total">
                        Total :
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(receiptAccumulator.receiptTotalFine)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(receiptAccumulator.receiptTotalDeposit)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(receiptAccumulator.receiptTotalDemandFee)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(receiptAccumulator.receiptTotalNonDemandFee)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(receiptAccumulator.receiptTotalAmount)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="row g-0 std-complete-fee-receipt__frame--tableblock">
            <div className="col std-complete-fee-receipt__frame--block">
              <Title variant="subtitle1">
                Fee Advance / Deposit and Adjustment Details
              </Title>
              <TableContainer className="std-complete-fee-receipt__frame--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.StudentCompleteFeeReceipt.FeeAdvance.Table_Headers.filter(
                        (th) =>
                          (th.labelName === "Refunds" && showDepositRefund) ||
                          th.labelName !== "Refunds"
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {StudentDepositData.data
                      ? StudentDepositData.data.GetAcctStdDeposits.edges
                          .filter((edge) => edge.node.is_scholarship === false)
                          .map((item, index) => {
                            return (
                              <React.Fragment>
                                <TableRow
                                  key={item.node.id}
                                  onClick={() => {
                                    if (state.studentId) {
                                      dispatch({
                                        type: payloadTypes.SET_DEPOSIT_ID,
                                        payload: {
                                          depositId: item.node.id,
                                        },
                                      });
                                      dispatch({
                                        type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                        payload: {
                                          accountLedgerId:
                                            item.node.acct_ldgr_details.id,
                                        },
                                      });
                                    }
                                    setDepositDetails(!depositDetails);
                                  }}
                                >
                                  <TableCell
                                    id="td-center"
                                    className="std-complete-fee-receipt__frame--table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>

                                  <TableCell>
                                    {item.node.acct_ldgr_details.ldgr_desc}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_ob)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_amt)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_total)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_adjusted)}
                                  </TableCell>
                                  {showDepositRefund ? (
                                    <TableCell
                                      id="td-right"
                                      className="std-complete-fee-receipt__frame--table--amount"
                                    >
                                      {format(item.node.deposit_refunded)}
                                    </TableCell>
                                  ) : null}
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_bal)}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })
                      : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2} className="total">
                        Total :
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          depositAccumulator
                            ? depositAccumulator.totalDepositOb
                            : 0
                        )}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          depositAccumulator
                            ? depositAccumulator.totalDepositAmount
                            : 0
                        )}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          depositAccumulator
                            ? depositAccumulator.totalDepositTotal
                            : 0
                        )}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          depositAccumulator
                            ? depositAccumulator.totalDepositAdjusted
                            : 0
                        )}
                      </TableCell>
                      {showDepositRefund ? (
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            depositAccumulator
                              ? depositAccumulator.dbTotalDepositRefunded
                              : 0
                          )}
                        </TableCell>
                      ) : null}
                      <TableCell id="td-right" className="totalcount">
                        {format(
                          depositAccumulator
                            ? depositAccumulator.totalDepositBalance
                            : 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
            {showRefunds ? (
              <div className="col std-complete-fee-receipt__frame--block">
                <Title variant="subtitle1">Refund ( If Any )</Title>
                <TableContainer className="std-complete-fee-receipt__frame--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {Accounts_Table.StudentCompleteFeeReceipt.Refund.Table_Headers.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index} className={th.className}>
                                {th.labelName}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {completeFeeReceiptData
                        ? completeFeeReceiptData.std_refunds_recepits.map(
                            (refund, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_RECEPIT_ID,
                                      payload: {
                                        receiptId: refund.id,
                                      },
                                    });

                                    setStudentDemandModal(!studentDemandModal);
                                  }}
                                >
                                  <TableCell
                                    id="td-center"
                                    className="std-complete-fee-receipt__frame--table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className="std-complete-fee-receipt__frame--table--rcptno">
                                    {refund.v_no}
                                  </TableCell>
                                  <TableCell className="std-complete-fee-receipt__frame--table--amount">
                                    {toStandardDate(refund.v_date)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(refund.v_std_amt_refunded)}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        : null}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={3} className="total">
                          Total :
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="totalcount std-complete-fee-receipt__frame--table--amount"
                        >
                          {format(refundBLockRefundAmount)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            ) : null}
          </div>
          {scholarshipEnabled ? (
            <div className="row g-0 std-complete-fee-receipt__frame--tableblock">
              <div className="col std-complete-fee-receipt__frame--block">
                <Title variant="subtitle1">Scholarships</Title>
                <TableContainer className="std-complete-fee-receipt__frame--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {Accounts_Table.StudentCompleteFeeReceipt.FeeAdvance.Table_Headers.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index} className={th.className}>
                                {th.labelName}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {StudentDepositData.data
                        ? StudentDepositData.data.GetAcctStdDeposits.edges
                            .filter((edge) => edge.node.is_scholarship)
                            .map((item, index) => {
                              return (
                                <TableRow key={item.node.id}>
                                  <TableCell
                                    id="td-center"
                                    className="std-complete-fee-receipt__frame--table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>

                                  <TableCell>
                                    {item.node.acct_ldgr_details.ldgr_desc}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_ob)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_amt)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_total)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_adjusted)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_refunded)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="std-complete-fee-receipt__frame--table--amount"
                                  >
                                    {format(item.node.deposit_bal)}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        : null}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={2} className="total">
                          Total :
                        </TableCell>
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            ScholarshipsAccumulatr
                              ? ScholarshipsAccumulatr.totalDepositOb
                              : 0
                          )}
                        </TableCell>
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            ScholarshipsAccumulatr
                              ? ScholarshipsAccumulatr.totalDepositReceived
                              : 0
                          )}
                        </TableCell>
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            ScholarshipsAccumulatr
                              ? ScholarshipsAccumulatr.totalDepositAmount
                              : 0
                          )}
                        </TableCell>
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            ScholarshipsAccumulatr
                              ? ScholarshipsAccumulatr.totalDepositAdjusted
                              : 0
                          )}
                        </TableCell>
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            ScholarshipsAccumulatr
                              ? ScholarshipsAccumulatr.dbTotalDepositRefunded
                              : 0
                          )}
                        </TableCell>
                        <TableCell id="td-right" className="totalcount">
                          {format(
                            ScholarshipsAccumulatr
                              ? ScholarshipsAccumulatr.totalDepositBalance
                              : 0
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
              <div className="col"></div>
            </div>
          ) : null}
          <div className="std-complete-fee-receipt__frame">
            <Title variant="subtitle1">Demand Details</Title>
            <TableContainer className="std-complete-fee-receipt__frame--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.StudentCompleteFeeReceipt.Demand.Table_Headers.filter(
                      (th) =>
                        (th.labelName === "Concession" &&
                          showConsolidatedConcession) ||
                        (th.labelName === "Refunded" &&
                          showConsolidatedRefunds) ||
                        (th.labelName === "Deposit Refunded" &&
                          showConsolidatedRefunded) ||
                        (th.labelName !== "Concession" &&
                          th.labelName !== "Refunded" &&
                          th.labelName !== "Deposit Refunded")
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName === "Receivable" &&
                          state.claims &&
                          state.claims.STUDENT
                            ? "Payable"
                            : th.labelName === "Received" &&
                              state.claims &&
                              state.claims.STUDENT
                            ? "Paid"
                            : th.labelName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completeFeeReceiptData
                    ? completeFeeReceiptData.std_demand.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              id="td-center"
                              className="std-complete-fee-receipt__frame--table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              className="std-complete-fee-receipt__frame--table--amount"
                              id="td-right"
                            >
                              {item.std_demand_ob}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_demand_amt)}
                            </TableCell>
                            {showConsolidatedConcession ? (
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(item.std_demand_concession)}
                              </TableCell>
                            ) : null}
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_demand_receivable)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_demand_received)}
                            </TableCell>
                            {showConsolidatedRefunds ? (
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(item.std_demand_refunds)}
                              </TableCell>
                            ) : null}
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_demand_bal)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_deposit_ob)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_deposit_amt)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_deposit_total)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_deposit_adjusted)}
                            </TableCell>
                            {showConsolidatedRefunded ? (
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(item.std_deposit_refunded)}
                              </TableCell>
                            ) : null}
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_deposit_bal)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="std-complete-fee-receipt__frame--table--amount"
                            >
                              {format(item.std_non_demand_collected)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* <div className="std-complete-fee-receipt__frame">
            <Title variant="subtitle1">Consolidated Data</Title>
            <TableContainer className="std-complete-fee-receipt__frame--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.StudentCompleteFeeReceipt.Consolidated.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName === "Receivable" &&
                            state.claims &&
                            state.claims.STUDENT
                              ? "Payable"
                              : th.labelName === "Received" &&
                                state.claims &&
                                state.claims.STUDENT
                              ? "Paid"
                              : th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completeFeeReceiptData?.std_demand_consolidate.map(
                    (consolidated, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            id="td-center"
                            className="std-complete-fee-receipt__frame--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_demand_ob)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_demand_amt)}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="std-complete-fee-receipt__frame--table--amount"
                          >
                            {format(consolidated.consol_demand_concession)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_demand_receivable)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_demand_received)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_demand_refunds)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_demand_bal)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_deposit_ob)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_deposit_amt)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_deposit_total)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_deposit_adjusted)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_deposit_refunded)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_deposit_bal)}
                          </TableCell>
                          <TableCell
                            className="std-complete-fee-receipt__frame--table--amount"
                            id="td-right"
                          >
                            {format(consolidated.consol_non_demand_collected)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div> */}
        </div>
        {PageType === PageFor.GENERAL ? (
          <div className="std-complete-fee-receipt__buttons">
            {/* <Button mode="rewrite-demand" />
            <Button mode="demand-details" />
            <Button mode="statement" />
            <Button mode="permission" />
            <Button mode="log-sheet" /> */}
            {/* <Button mode="clear" /> */}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        ) : (
          <Button
            mode="cancel"
            type="button"
            onClick={() => setModalFlag?.(false)}
          />
        )}
      </div>

      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.GENERAL}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentModal(false)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* receipt modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentDemandModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentVoucherView setModalFlag={setStudentDemandModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentDemandModal(!studentDemandModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={depositDetails}
        style={AccountingLedgerModalStyles}
      >
        <DepositDetails setModalFlag={setDepositDetails} />
      </Modal>
      {state.claims && state.claims.STUDENT ? (
        <>
          <Modal
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            isOpen={
              printModal &&
              studentReceiptDetails &&
              studentReceiptDetails.v_book_type !==
                VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK
                ? true
                : false
            }
            style={PrintModalStyles}
          >
            <div className="modal-flex h-100">
              <div className="modal-flex__data h-100">
                <Print
                  tableData={transactionArray.map((data) => ({
                    particular: data.acct_ldgr.ldgr_desc,
                    amount: studentReceiptDetails
                      ? studentReceiptDetails.v_std_refund ||
                        studentReceiptDetails.v_std_refund_deposit
                        ? data.vo_db
                        : data.vo_cr
                      : 0,
                  }))}
                  v_date={
                    studentReceiptDetails
                      ? studentReceiptDetails.v_date
                      : DEFAULT_TIME
                  }
                  v_no={
                    studentReceiptDetails
                      ? studentReceiptDetails.v_no
                      : DEFAULT_TIME
                  }
                  transaction_no={
                    studentReceiptDetails
                      ? studentReceiptDetails.v_transcation_no
                      : EMPTY_STRING
                  }
                />
              </div>
              <div className="modal-flex__image">
                <img
                  src={Close}
                  alt="/"
                  className="modal-close-icon"
                  onClick={() => SetPrintModal(false)}
                />
              </div>
            </div>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default Index;

import React from "react";
import Input from "../../../stories/Input/Input";

const InputNumber = ({ onChange, type, ...props }: any) => {
  return (
    <Input
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) >= 0) {
          onChange(e);
        }
      }}
      type="number"
      {...props}
    />
  );
};

export default InputNumber;

import React, { useState } from "react";
import AddCampus from "./New";
import { Operation } from "../../../utils/Enum.types";
import { CampusEntryDrawer } from "../../../styles/DrawerStyles";

interface Props {
  operation: Operation;
  setCampusModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCampusUpdateModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ operation, setCampusModal, setCampusUpdateModal }: Props) => {
  const [primaryInstModal, setPrimaryInstModal] = useState(false);
  return (
    <>
      <AddCampus
        operation={operation}
        setPrimaryInstModal={setPrimaryInstModal}
        setCampusModal={setCampusModal}
        setCampusUpdateModal={setCampusUpdateModal}
      />

      <CampusEntryDrawer
    
        anchor="right"
        open={primaryInstModal}
        onClose={() => setPrimaryInstModal(!primaryInstModal)}
      >
        <AddCampus
          operation={Operation.PRIMARY}
          setPrimaryInstModal={setPrimaryInstModal}
        />
      </CampusEntryDrawer>
    </>
  );
};

export default Index;

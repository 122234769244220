import { gql } from "@apollo/client";

export const GetSwGeneralHolidays = gql`
  query GetSwGeneralHolidays(
    $token: String!
    $after: Cursor
    $first: Int
    $sortBy: SwGeneralHolidaysOrderField
    $direction: OrderDirection!
    $holidayName: String
    $input: SwGenHolidayQuery!
  ) {
    GetSwGeneralHolidays(
      token: $token
      after: $after
      first: $first
      input: $input
      orderBy: { direction: $direction, field: $sortBy }
      where: { holidayDescContainsFold: $holidayName }
    ) {
      edges {
        node {
          id
          holiday_date
          holiday_desc
        }
        cursor
      }
      pageInfo {
        endCursor
      }
      totalCount
    }
  }
`;

export const GetSwSalaryLdgrs = gql`
  query GetSwSalaryLdgrs($token: String!) {
    GetSwSalaryLdgrs(token: $token) {
      id
      sal_ldgr_desc
      sal_ldgr_short_desc
      sal_ldgr_idx
      sal_ldgr_earn_deduct
      sal_ldgr_type
      sal_ldgr_round_to_next_int
      sal_ldgr_remove_decimal
      sal_ldgr_is_basic
      sal_ldgr_is_loan_ac
      sal_ldgr_is_system_generated
    }
  }
`;

export const GetSwSalaryLdgr = gql`
  query GetSwSalaryLdgr($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on SwSalaryLdgr {
        id
        sal_ldgr_desc
        sal_ldgr_short_desc
        sal_ldgr_idx
        sal_ldgr_earn_deduct
        sal_ldgr_type
        sal_ldgr_round_to_next_int
        sal_ldgr_remove_decimal
        sal_ldgr_is_basic
        sal_ldgr_is_loan_ac
      }
    }
  }
`;

export const GetSwAcctGroupLdgrs = gql`
  query GetSwAcctGroupLdgrs($token: String!) {
    GetSwAcctGroupLdgrs(token: $token)
  }
`;

export const GetSwAcctLdgrs = gql`
  query GetSwAcctLdgrs(
    $first: Int
    $after: Cursor
    $sortBy: SwAcctLdgrOrderField
    $input: SwAcctLdgrQryType!
    $direction: OrderDirection!
    $token: String!
    $acctLedgerDesc: String
  ) {
    GetSwAcctLdgrs(
      token: $token
      first: $first
      after: $after
      input: $input
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ ldgrDescContainsFold: $acctLedgerDesc }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ldgr_desc
          gr_ldgr_desc
        }
      }
    }
  }
`;

export const GetSwAcctLdgrByNode = gql`
  query GetSwAcctLdgrByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on SwAcctLdgr {
        ldgr_desc
        gr_ldgr_desc
      }
    }
  }
`;

export const GetAcdLevel = gql`
  query GetAcdLevel($token: String!, $inst_id: ID!, $acd_level_id: ID!) {
    GetAcdLevel(token: $token, inst_id: $inst_id, acd_level_id: $acd_level_id)
  }
`;

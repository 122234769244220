import { gql } from "@apollo/client";

export const GetLibBookMediaByInstId = gql`
  query GetLibBookMediaByInstId(
    $mediaName: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookMediaOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookMediaByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ mediaDescContainsFold: $mediaName }] }
    ) {
      edges {
        node {
          id
          media_desc
          media_abbrevation
          media_is_active
          media_sl
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const GetAcctMiscActivities = gql`
  query GetAcctMiscActivities(
    $activityName: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: AcctMiscActivitiesOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetAcctMiscActivities(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ activityNameContainsFold: $activityName }] }
    ) {
      edges {
        node {
          id
          activity_name
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const GetAcctStdMiscActivityLogs = gql`
  query GetAcctStdMiscActivityLogs(
    $activityName: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: AcctStdMiscActivityLogsOrderField
    $direction: OrderDirection!
    $token: String!
    $input: MiscActivityLogQuery!
    $fin_yr_id: ID!
  ) {
    GetAcctStdMiscActivityLogs(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      input: $input
      fin_yr_id: $fin_yr_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ commentsContainsFold: $activityName }] }
    ) {
      edges {
        node {
          id
          last_status
          comments
          activity_log_date
          updated_at
          student_id
          misc_activity_id
          fin_yr_id
          inst_id
          student_details {
            first_name
          }
          misc_activity_details {
            id
            activity_name
            inst_id
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
export const GetLibBookDepts = gql`
  query GetLibBookDepts(
    $deptName: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookDeptOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookDepts(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ deptNameContainsFold: $deptName }] }
    ) {
      edges {
        node {
          id
          dept_name
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

import Home from "../../Home/Index";
import { Operation } from "../../../../utils/Enum.types";
import { Step, StepLabel, Stepper } from "@mui/material";
import { Title } from "../../../../stories/Title/Title";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../../stories/Button/Button";

import { stepHeader } from "../../constants";
import EmployeeDetails from "./EmployeeDetails";
import EmpDocsUploadComponent from "./EmpDocsUploadComponent";

interface props {
  type: Operation;
  step: number;
}
export interface DocsType {
  document_name: string;
  doc_idx: number;
  preview: File | null;
  fromDb: boolean;
  fromDbUrl: string;
  docId: number;
  index?: number;
  collected?: boolean;
  isOriginal?: boolean;
  isUpdatedFlag?: boolean;
  dateCollected?: string;
}
const EmpDocumentsUpload = ({ type, step }: props) => {
  const stepsHeader = stepHeader();
  const { InstId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="employee-details">
        {type === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        <Title>Employee Documents Upload</Title>
        <EmployeeDetails />
        <div
          className={`student_docs__table${
            type === Operation.CREATE ? "--create" : "--update"
          }`}
        >
          <EmpDocsUploadComponent type={type} />
        </div>
        <Button
          mode={`${type === Operation.CREATE ? "finish" : "back"}`}
          onClick={() => {
            if (type === Operation.CREATE) {
              navigate(`/${InstId}/hr/employee/list`);
            } else {
              navigate(-1);
            }
          }}
        />
      </div>
    </>
  );
};

export default EmpDocumentsUpload;

import { gql } from "@apollo/client";

export const GetServerDateAndTime = gql`
  query GetServerDateAndTime($token: String!) {
    GetServerDateAndTime(token: $token)
  }
`;
export const GetServerDateAndTimeForScan = gql`
  query GetServerDateAndTimeForScan {
    GetServerDateAndTimeForScan
  }
`;

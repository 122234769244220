import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

import {
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";

import HRImport from "../../Imports/SalaryLedgerImport";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../components/common/Input/Index";
import { Title } from "../../../../stories/Title/Title";
import { HRTitleProps } from "../../../../Types/Titles";
import HRHome from "../../Home/Index";
import EduateHome from "../../../Eduate/Home/Index";
import DownArrow from "../../../../images/DownArrow.svg";
import { Label } from "../../../../stories/Label/Label";
import { LabelNameProps } from "../../../../Types/Labels";
import { AntSwitch } from "../../../../pages/Switch";
import { Form, Formik } from "formik";
import { addtionOfSalaryLedger } from "../../../../utils/salaryValidation";
import {
  LedgersForType,
  LicenseTypes,
  Operation,
  PageFor,
  PredefinedDataTypes,
} from "../../../../utils/Enum.types";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, optionsType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";

import {
  GetPayRollSalaryLdgrByNode,
  GetPayRollSalaryLdgrsByInstId,
} from "../../queries/salary/list";
import {
  AddPayRollSalaryLdgr,
  UpdatePayRollSalaryLdgr,
} from "../../queries/salary/mutation";
import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
} from "../../../../utils/constants";
import SalaryLedgerList from "./SalaryLedgerList";
import EduateSalaryLedgerList from "../../../Eduate/salaryledger/EduateSalaryLedgerList";
import {
  AddSwSalaryLdgr,
  UpdateSwSalaryLdgr,
} from "../../../../queries/Eduate/mutations/new";

import {
  GetSwSalaryLdgr,
  GetSwSalaryLdgrs,
} from "../../../../queries/Eduate/list";
import PredefinedSalaryLedger from "./PredefinedSalaryLedger";
import Close from "../../../../images/Close.svg";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import {
  sal_ldgr_is_active,
  sal_ldgr_is_system_generated,
  sal_ledger_name_ref,
} from "../../constants";
import {
  DeductionOptions,
  EarningOptions,
  PayRollConfigKeys,
  SalaryLedgerType,
} from "../../enums/Enum.types";
import {
  handleMUISelectEvent,
  removeMoreSpace,
  removeUnderscore,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { SalaryLedger } from "../../Types/formTypes";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { FormAutocomplete, formClasses } from "../../../../styles/AutocompleteStyles";

const { HRTitles } = require("../../json/title.json");
const { HRFormLabels } = require("../../json/form.json");
interface Props {
  pageType: LedgersForType;
}
const Index = ({ pageType }: Props) => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const [operation, setOperation] = useState<Operation>(Operation.CREATE);
  const [predefinedLedgerModal, setPredefinedLedgerModal] = useState(false);
  const [salaryLedgerId, setSalaryLedgerId] = useState(0);
  const [importModal, setImportModal] = useState(false);

  const [sal_ldgr_earn_deduct, setsal_ldgr_earn_deduct] = useState(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [sal_ldgr_type, setsal_ldgr_type] = useState(EMPTY_OPTIONSTYPE_OBJECT);

  const [formData, setFormData] = useState<SalaryLedger>({
    sal_ldgr_desc: EMPTY_STRING,
    sal_ldgr_is_active: false,
    sal_ldgr_remove_decimal: false,
    sal_ldgr_round_to_next_int: false,
    sal_ldgr_is_basic: false,
    sal_ldgr_is_loan_ac: false,
    sal_ldgr_short_desc: EMPTY_STRING,
    sal_ldgr_idx: 0,
  });
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const salLedgerDescRef = document.getElementsByName(
    sal_ledger_name_ref
  )[0] as HTMLInputElement;
  //Queries

  const [AddSalary, { loading: creationLoading }] = useMutation(
    AddPayRollSalaryLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [AddEduateSalary, { loading: eduateCreationLoading }] = useMutation(
    AddSwSalaryLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [UpdateSalary, { loading: updationLoading }] = useMutation(
    UpdatePayRollSalaryLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [UpdateEduateSalaryLedger, { loading: eduateUpdationLoading }] =
    useMutation(UpdateSwSalaryLdgr, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const [GetSalaryLedger] = useLazyQuery(GetPayRollSalaryLdgrByNode);
  const [GetEduateSalaryLedger] = useLazyQuery(GetSwSalaryLdgr);
  const {
    configData: { data: groupInsuranceConfigData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_GRP_INSURANCE_SAL_TYPE);
  const {
    configData: { data: insuranceConfigData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_INSURANCE_SAL_TYPE);
  const {
    configData: { data: loanConfigData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_LOAN_SAL_TYPE);

  const { config_boolean_value: enableGroupInsurance } =
    groupInsuranceConfigData?.GetSwConfigVariables[0] || {};
  const { config_boolean_value: enableInsurance } =
    insuranceConfigData?.GetSwConfigVariables[0] || {};
  const { config_boolean_value: enableLoan } =
    loanConfigData?.GetSwConfigVariables[0] || {};

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleSwitch = (e: React.MouseEvent) => {
    if ((e.target as HTMLInputElement).name)
      setFormData((prevValues) => ({
        ...prevValues,
        [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement)
          .checked,
      }));
  };
  const {
    PredefinedData: { dropDown: EarningDeduction },
  } = usePredefinedDataByType(
    PredefinedDataTypes.EARNING_DEDUCTION,
    EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: DeductionLedgers },
  } = usePredefinedDataByType(
    PredefinedDataTypes.DEDUCTION_LEDGER,
    EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: EarningLedgers },
  } = usePredefinedDataByType(PredefinedDataTypes.SAL_TYPE, EMPTY_STRING);

  const LedgerTypeOptions = (earn_deduc: string) => {
    switch (earn_deduc) {
      case SalaryLedgerType.DEDUCTION:
        return Object.entries(DeductionOptions).reduce(
          (
            acc: {
              label: string;
              value: string;
            }[],
            data
          ) => {
            if (
              ((data[1] === DeductionOptions.GROUP_INSURANCE &&
                enableGroupInsurance) ||
                (data[1] === DeductionOptions.INSURANCE && enableInsurance) ||
                (data[1] === DeductionOptions.LOAN && enableLoan)) &&
              pageType === LedgersForType.HR
            ) {
              acc.push({
                label: toStandardCase(removeUnderscore(data[0])),
                value: data[1],
              });
            }
            return acc;
          },
          []
        );

      case SalaryLedgerType.EARNING:
        return Object.entries(EarningOptions).map((data) => ({
          label: toStandardCase(removeUnderscore(data[0])),
          value: data[1] as string,
        }));
      default:
        return [];
    }
  };

  const setFormDataForUpdate = (id: number) => {
    setOperation(Operation.UPDATE);
    if (token) {
      if (pageType === LedgersForType.HR) {
        GetSalaryLedger({
          variables: {
            token,
            id,
          },
        }).then(({ data }) => {
          if (data) {
            setFormData({
              sal_ldgr_desc: data.node.sal_ldgr_desc,
              sal_ldgr_is_active: data.node.sal_ldgr_is_active,
              sal_ldgr_remove_decimal: data.node.sal_ldgr_remove_decimal,
              sal_ldgr_round_to_next_int: data.node.sal_ldgr_round_to_next_int,
              sal_ldgr_short_desc: data.node.sal_ldgr_short_desc,
              sal_ldgr_idx: data.node.sal_ldgr_idx,
            });

            setsal_ldgr_type(
              LedgerTypeOptions(data.node.sal_ldgr_earn_deduct)?.find(
                (row) => row.value === data.node.sal_ldgr_type
              ) ?? EMPTY_OPTIONSTYPE_OBJECT
            );
            setsal_ldgr_earn_deduct(
              EarningDeduction.find(
                (row) => row.value === data.node.sal_ldgr_earn_deduct
              ) ?? EMPTY_OPTIONSTYPE_OBJECT
            );
            setSalaryLedgerId(data.node.id);
          }
        });
      } else {
        GetEduateSalaryLedger({
          variables: {
            token,
            id,
          },
        }).then(({ data }) => {
          setOperation(Operation.UPDATE);
          if (data) {
            setFormData({
              sal_ldgr_desc: data.node.sal_ldgr_desc,
              sal_ldgr_is_active: data.node.sal_ldgr_is_active,
              sal_ldgr_remove_decimal: data.node.sal_ldgr_remove_decimal,
              sal_ldgr_round_to_next_int: data.node.sal_ldgr_round_to_next_int,
              sal_ldgr_short_desc: data.node.sal_ldgr_short_desc,
              sal_ldgr_idx: data.node.sal_ldgr_idx,
            });
            setsal_ldgr_type(
              (data.node.sal_ldgr_earn_deduct === SalaryLedgerType.DEDUCTION
                ? DeductionLedgers.find(
                    (row) => row.value === data.node.sal_ldgr_type
                  )
                : EarningLedgers.find(
                    (row) => row.value === data.node.sal_ldgr_type
                  )) ?? EMPTY_OPTIONSTYPE_OBJECT
            );
            setsal_ldgr_earn_deduct(
              EarningDeduction.find(
                (row) => row.value === data.node.sal_ldgr_earn_deduct
              ) ?? EMPTY_OPTIONSTYPE_OBJECT
            );
            setSalaryLedgerId(data.node.id);
          }
        });
      }
    }
  };
  const hanldeSubmit = () => {
    if (operation === Operation.UPDATE) {
      if (pageType === LedgersForType.HR) {
        UpdateSalary({
          variables: {
            token,
            id: salaryLedgerId,
            inst_id: InstId,
            user_details,

            input: {
              ...formData,
              sal_ldgr_desc: removeMoreSpace(formData.sal_ldgr_desc),
              sal_ldgr_earn_deduct: sal_ldgr_earn_deduct.value,
              sal_ldgr_type: sal_ldgr_type.value,
            },
          },
          refetchQueries: [
            {
              query: GetPayRollSalaryLdgrsByInstId,
              variables: {
                token,
                inst_id: InstId!,
              },
            },
            {
              query: GetPayRollSalaryLdgrByNode,
              variables: {
                token,
                id: salaryLedgerId,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Salary Ledger Updated ",
              operation: Operation.UPDATE,
            });
            setSalaryLedgerId(0);
            setOperation(Operation.CREATE);
          }
        });
      } else {
        delete formData.sal_ldgr_is_active;
        UpdateEduateSalaryLedger({
          variables: {
            token,
            id: salaryLedgerId,
            input: {
              ...formData,
              sal_ldgr_desc: removeMoreSpace(formData.sal_ldgr_desc),
              sal_ldgr_earn_deduct: sal_ldgr_earn_deduct.value,
              sal_ldgr_type: sal_ldgr_type.value,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetSwSalaryLdgrs,
              variables: {
                token,
              },
            },
            {
              query: GetSwSalaryLdgr,
              variables: {
                token,
                id: salaryLedgerId,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Eduate Salary Ledger Updated ",
              operation: Operation.UPDATE,
            });
            setSalaryLedgerId(0);
            setOperation(Operation.CREATE);
          }
        });
      }
    }
    if (operation === Operation.CREATE) {
      if (pageType === LedgersForType.HR) {
        AddSalary({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: {
              ...formData,
              sal_ldgr_desc: removeMoreSpace(formData.sal_ldgr_desc),
              sal_ldgr_earn_deduct: sal_ldgr_earn_deduct.value,
              sal_ldgr_type: sal_ldgr_type.value,
            },
          },
          refetchQueries: [
            {
              query: GetPayRollSalaryLdgrsByInstId,
              variables: {
                token,
                inst_id: InstId!,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Salary Ledger Created ",
              operation: Operation.CREATE,
            });
          }
        });
      } else {
        delete formData.sal_ldgr_is_active;
        delete formData.sal_ldgr_is_system_generated;
        AddEduateSalary({
          variables: {
            token,
            input: {
              ...formData,
              sal_ldgr_desc: removeMoreSpace(formData.sal_ldgr_desc),
              sal_ldgr_earn_deduct: sal_ldgr_earn_deduct.value,
              sal_ldgr_type: sal_ldgr_type.value,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetSwSalaryLdgrs,
              variables: {
                token,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Eduate Salary Ledger Created ",
              operation: Operation.CREATE,
            });
          }
        });
      }
    }
  };
  const handleClear = () => {
    setFormData({
      sal_ldgr_desc: EMPTY_STRING,
      sal_ldgr_is_active: false,
      sal_ldgr_remove_decimal: false,
      sal_ldgr_round_to_next_int: false,
      sal_ldgr_short_desc: EMPTY_STRING,
      sal_ldgr_idx: 0,
    });
    setsal_ldgr_earn_deduct(EMPTY_OPTIONSTYPE_OBJECT);
    setsal_ldgr_type(EMPTY_OPTIONSTYPE_OBJECT);
    if (operation === Operation.UPDATE) {
      setOperation(Operation.CREATE);
    }
    salLedgerDescRef.focus();
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      {pageType === LedgersForType.HR ? (
        <HRHome DashBoardRequired={false} />
      ) : (
        <EduateHome
          DashBoardRequired={false}
          NavType={LicenseTypes.EDUATE_CONFIGURATION}
        />
      )}
      <Title>
        {HRTitles.SalaryLedger.Titles.map(
          (title: HRTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {pageType === LedgersForType.HR
                  ? title.Title
                  : title.Eduate_Title}
              </React.Fragment>
            );
          }
        )}
      </Title>

      <Formik
        initialValues={formData}
        validationSchema={addtionOfSalaryLedger}
        enableReinitialize
        onSubmit={hanldeSubmit}
      >
        {(meta) => {
          return (
            <Form className="salary-ledger">
              <div className="row g-0 salary-ledger__block">
                <div className="col h-100 booktype-left">
                  <div className="salary-ledger__block--title">
                    <Title variant="subtitle1">
                      {HRTitles.SalaryLedger.Titles.map(
                        (title: HRTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="salary-ledger__block--data">
                    {HRFormLabels.SalaryLedger.InputType.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              required={label.required}
                              autoFocus={label.autoFocus}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>Earnings/Deduction</Label>
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        options={EarningDeduction!}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setsal_ldgr_earn_deduct(newValue as optionsType);
                          } else {
                            setsal_ldgr_earn_deduct(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          setsal_ldgr_type(EMPTY_OPTIONSTYPE_OBJECT);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setsal_ldgr_earn_deduct(EMPTY_OPTIONSTYPE_OBJECT);
                            setsal_ldgr_type(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            handleMUISelectEvent(e);
                          }
                        }}
                        value={sal_ldgr_earn_deduct}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Type</Label>
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        options={LedgerTypeOptions(sal_ldgr_earn_deduct.value)}
                        value={sal_ldgr_type}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setsal_ldgr_type(newValue as optionsType);
                          } else {
                            setsal_ldgr_type(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setsal_ldgr_type(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                    </div>
                    {HRFormLabels.SalaryLedger.SwitchType.filter(
                      (ledger: LabelNameProps) =>
                        (ledger.inputName !== sal_ldgr_is_active &&
                          ledger.inputName !== sal_ldgr_is_system_generated &&
                          pageType === LedgersForType.EDUATE) ||
                        pageType === LedgersForType.HR
                    ).map((label: LabelNameProps, index: number) => {
                      return (
                        <FormGroup key={index}>
                          <FormControlLabel
                            label={label.LabelName}
                            name={label.inputName}
                            checked={formData[label.inputName] as boolean}
                            control={
                              <AntSwitch
                                onClick={(e: React.MouseEvent) =>
                                  handleSwitch(e)
                                }
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                }}
                              />
                            }
                            labelPlacement="start"
                          />
                        </FormGroup>
                      );
                    })}
                  </div>
                </div>
                <div className="col h-100 booktype-right">
                  {pageType === LedgersForType.HR ? (
                    <SalaryLedgerList
                      pageType={PageFor.GENERAL}
                      setFormDataForUpdate={setFormDataForUpdate}
                    />
                  ) : (
                    <EduateSalaryLedgerList
                      setFormDataForUpdate={setFormDataForUpdate}
                    />
                  )}
                </div>
              </div>
              <div className="salary-ledger__buttons">
                <Button mode="save" type="submit" />
                <Button mode="clear" type="button" onClick={handleClear} />
                <Button
                  type="button"
                  mode="excel"
                  onClick={() => setImportModal(!importModal)}
                >
                  Import Salary Ledgers
                </Button>
                <Button type="button" mode="export" />

                {pageType === LedgersForType.HR ? (
                  <Button
                    type="button"
                    mode="addnew"
                    onClick={() =>
                      setPredefinedLedgerModal(!predefinedLedgerModal)
                    }
                  >
                    &nbsp;Predefined Salary Ledger
                  </Button>
                ) : null}
                <Button
                  mode="back"
                  type="button"
                  onClick={() => navigate(-1)}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <HRImport />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={
          creationLoading ||
          updationLoading ||
          eduateCreationLoading ||
          eduateUpdationLoading
        }
      />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={predefinedLedgerModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PredefinedSalaryLedger setModalFlag={setPredefinedLedgerModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPredefinedLedgerModal(!predefinedLedgerModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

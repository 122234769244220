import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { STAFFDETAILS } from "../../../../utils/Enum.types";
import BasicDetails from "./BasicDetails";
import Documents from "./Documents";

const Index = () => {
  const [activeTab, setActiveTab] = useState(STAFFDETAILS.BASIC_DETAILS);
  const handleTabClick = (tab: STAFFDETAILS) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>View Staff Details</Title>
      <div className="vms-staff-details-preview">
        <ul className="vms-staff-details-preview__menu">
          <li
            onClick={() => handleTabClick(STAFFDETAILS.BASIC_DETAILS)}
            className={
              activeTab === STAFFDETAILS.BASIC_DETAILS ? "active" : ""
            }>
            Basic Information
          </li>
          <li
            onClick={() => handleTabClick(STAFFDETAILS.STAFF_DOCUMENTS)}
            className={
              activeTab === STAFFDETAILS.STAFF_DOCUMENTS ? "active" : ""
            }>
            Staff Documents
          </li>
        </ul>
        {activeTab === STAFFDETAILS.BASIC_DETAILS && <BasicDetails />}
        {activeTab === STAFFDETAILS.STAFF_DOCUMENTS && <Documents />}
      </div>
    </>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { GetPayRollInstOrgChart } from "../queries/organizationchart/list";
import {
  GetPayRollInstOrgChartData,
  GetPayRollInstOrgChartVars,
} from "../OrganizationalCharts/TreeView";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetPayRollEmpForOrgChart } from "../queries/employee/query";
import { EmpDetails } from "../hooks/useEmployee";
import Avatar from "../../../images/Avatar.svg";
import axios from "axios";
import { getFileName } from "../../../utils/UtilFunctions";
import Department from "../../../images/OrganizationDepartment.svg";

interface TreeNode {
  nodeKey: string;
  designation_desc: string;
  designation_id: number;
  department: number;
  parent_department: number;
  parent_id: number;
  chart_id: number;
  pr_emp_id: number;
  image: string;
  sub_count: number;
  children?: TreeNode[];
}
interface empList extends EmpDetails {
  subordinate_count: 0;
}
interface GetPayRollEmpForOrgChartData {
  GetPayRollEmpForOrgChart: empList[];
}
interface GetPayRollEmpForOrgChartVars {
  token: string;
  inst_id: string;
  pr_dept_id: number;
  pr_designation_ids: number[];
  pr_emp_id: number;
}
interface TreeProps {
  data: TreeNode[];
  handleClick: (child: TreeNode) => void;
  downLoadUrl: MessageData[];
}
interface Props {
  node: TreeNode;
  handleClick: (child: TreeNode) => void;
  downLoadUrl: MessageData[];
}

const TreeComponent: React.FC<TreeProps> = ({
  data,
  handleClick,
  downLoadUrl,
}) => {
  return (
    <div className="horizontal-tree">
      {data.map((node) => {
        return (
          <TreeNodeComponent
            node={node}
            handleClick={handleClick}
            downLoadUrl={downLoadUrl}
          />
        );
      })}
    </div>
  );
};

const TreeNodeComponent: React.FC<Props> = ({
  node,
  handleClick,
  downLoadUrl,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(false);
  const {
    designation_desc,
    nodeKey,
    children,
    pr_emp_id,
    sub_count,
    department,
  } = node;

  return (
    <>
      <div className={`horizontal-tree__entry  ${active ? "active" : ""}`}>
        <div
          className="horizontal-tree__entry--span"
          onClick={() => {
            setExpanded(!expanded);
            setActive(!active);
            handleClick(node);
          }}
        >
          <div className="horizontal-tree__entry--image">
            <img
              src={
                department
                  ? Department
                  : downLoadUrl.find(({ pr_emp_id: id }) => id === pr_emp_id)
                      ?.url ?? Avatar
              }
              alt="/"
            />
            <div className="horizontal-tree__entry--text">
              {nodeKey}
              <br />
              <b>{designation_desc}</b>
            </div>
          </div>
          <div className="horizontal-tree__entry--b">
            <b>{sub_count}</b>
          </div>
        </div>
        {expanded && (
          <div className="horizontal-tree__branch">
            {children?.map((child) => {
              return (
                <TreeNodeComponent
                  node={child}
                  handleClick={handleClick}
                  downLoadUrl={downLoadUrl}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

// Usage:

interface MessageData {
  url: string;
  downloadKey: string;
  pr_emp_id: number;
  mediaContent: string;
}
const App: React.FC = () => {
  const [chartData, setChartData] = useState<TreeNode[]>([]);
  const [GetChart] = useLazyQuery<
    GetPayRollInstOrgChartData,
    GetPayRollInstOrgChartVars
  >(GetPayRollInstOrgChart);
  const [GetEmployees] = useLazyQuery<
    GetPayRollEmpForOrgChartData,
    GetPayRollEmpForOrgChartVars
  >(GetPayRollEmpForOrgChart);
  const { InstId } = useParams();
  const { token } = useToken();
  const [activeFlag, setActiveFlag] = useState(false);

  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const getDownloadUrl = (mediaContent: string) => {
    const downloadBaseUrl = `https://cncqrdifbk.execute-api.ap-south-1.amazonaws.com/dev/avdDownLoadFiles?file_name=${mediaContent}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        axios.get(response.data, { responseType: "blob" }).then(() => {
          setDownloadUrl((prevDownloadUrl) => [
            ...prevDownloadUrl.map((messageData) => {
              if (getFileName(mediaContent) === messageData.downloadKey) {
                return { ...messageData, url: response.data };
              } else return messageData;
            }),
          ]);
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (token && InstId) {
      GetChart({
        variables: {
          token,
          inst_id: InstId,
          parent_id: 0,
        },
        fetchPolicy: "network-only",
      }).then(({ data }) => {
        if (data) {
          let result = data.GetPayRollInstOrgChart.filter(
            (tree) => tree.org_chart_details.pr_dept_id !== 0
          ).map((tree) => {
            return {
              department: tree.org_chart_details.pr_dept_id,
              designation_desc: "",
              nodeKey: tree.org_chart_details.name_desc,
              children: [],
              image: Department,
              designation_id: 0,
              parent_id: 0,
              chart_id: tree.org_chart_details.id,
              pr_emp_id: 0,
              sub_count: tree.org_chart_details.child_count,
              parent_department: tree.org_chart_details.pr_dept_id,
            };
          });
          const employeeFromDesignation = data.GetPayRollInstOrgChart.filter(
            (tree) => tree.org_chart_details.pr_designation_id !== 0
          );

          if (employeeFromDesignation.length) {
            GetEmployees({
              variables: {
                token,
                inst_id: InstId!,
                pr_dept_id: 0,
                pr_designation_ids: data.GetPayRollInstOrgChart.filter(
                  (tree) => tree.org_chart_details.pr_designation_id !== 0
                ).map((tree) => tree.org_chart_details.pr_designation_id),
                pr_emp_id: 0,
              },
              fetchPolicy: "network-only",
            }).then(({ data }) => {
              if (data) {
                const listWithFileNames = data.GetPayRollEmpForOrgChart.filter(
                  (mess) => mess.emp_profile_filename !== ""
                ).flatMap((message) => {
                  const mediaContentArray =
                    message.emp_profile_filename.split(",");
                  return mediaContentArray.map((content) => ({
                    mediaContent: content,
                    url: "",

                    downloadKey: getFileName(content),
                    pr_emp_id: message.id,
                  }));
                });

                setDownloadUrl((prev) => [...prev, ...listWithFileNames]);
                const fetchDownloadUrls = () => {
                  listWithFileNames.forEach((message) => {
                    const mediaContent = message.mediaContent;
                    getDownloadUrl(mediaContent);
                  });
                };
                fetchDownloadUrls();
                setChartData([
                  ...data.GetPayRollEmpForOrgChart.map((tree) => {
                    return {
                      department: 0,
                      designation_desc:
                        tree.pr_designation_details.designation_desc,
                      nodeKey: `${tree.emp_first_name} ${tree.emp_middle_name} ${tree.emp_last_name}`,
                      children: [],
                      image: "",
                      designation_id: tree.pr_designation_id,
                      parent_id:
                        employeeFromDesignation.find(
                          (chart) =>
                            chart.org_chart_details.pr_designation_id ===
                            tree.pr_designation_id
                        )?.org_chart_details.pr_designation_id ?? 0,
                      chart_id:
                        employeeFromDesignation.find(
                          (chart) =>
                            chart.org_chart_details.pr_designation_id ===
                            tree.pr_designation_id
                        )?.org_chart_details.id ?? 0,
                      pr_emp_id: tree.id,
                      sub_count: tree.subordinate_count,
                      parent_department: 0,
                    };
                  }),
                  ...result,
                ]);
              }
            });
          }
          setChartData(result);
        }
      });
    }
  }, [InstId, token, GetChart, GetEmployees]);

  const hanldeClick = (child: TreeNode) => {
    if (token && InstId) {
      GetChart({
        variables: {
          token,
          inst_id: InstId,
          parent_id: child.chart_id,
        },
        fetchPolicy: "network-only",
      }).then(({ data }) => {
        if (data) {
          let result: TreeNode[] = data.GetPayRollInstOrgChart.filter(
            (tree) => tree.org_chart_details.pr_dept_id !== 0
          ).map((tree) => {
            return {
              department: tree.org_chart_details.pr_dept_id,
              nodeKey: tree.org_chart_details.name_desc,
              children: [],
              image: "",
              designation_desc: "",
              designation_id: 0,
              parent_id: tree.org_chart_details.parent_id,
              chart_id: tree.org_chart_details.id,
              pr_emp_id: 0,
              sub_count: child.sub_count,
              parent_department: tree.org_chart_details.pr_dept_id,
            };
          });

          const employeeFromDesignation = data.GetPayRollInstOrgChart.filter(
            (tree) => tree.org_chart_details.pr_designation_id !== 0
          );

          if (employeeFromDesignation.length) {
            GetEmployees({
              variables: {
                token,
                inst_id: InstId!,
                pr_dept_id: child.parent_department,
                pr_designation_ids: data.GetPayRollInstOrgChart.filter(
                  (tree) => tree.org_chart_details.pr_designation_id !== 0
                ).map((tree) => tree.org_chart_details.pr_designation_id),
                pr_emp_id: child.pr_emp_id,
              },
              fetchPolicy: "network-only",
            }).then(({ data }) => {
              if (data) {
                const listWithFileNames = data.GetPayRollEmpForOrgChart.filter(
                  (mess) => mess.emp_profile_filename !== ""
                ).flatMap((message) => {
                  const mediaContentArray =
                    message.emp_profile_filename.split(",");
                  return mediaContentArray.map((content) => ({
                    mediaContent: content,
                    url: "",
                    downloadKey: getFileName(content),
                    pr_emp_id: message.id,
                  }));
                });

                setDownloadUrl((prev) => [...prev, ...listWithFileNames]);
                const fetchDownloadUrls = () => {
                  listWithFileNames.forEach((message) => {
                    const mediaContent = message.mediaContent;
                    getDownloadUrl(mediaContent);
                  });
                };
                fetchDownloadUrls();
                child.children = [
                  ...data.GetPayRollEmpForOrgChart.map((tree) => {
                    return {
                      department: 0,
                      designation: tree.pr_designation_details.designation_desc,
                      nodeKey: `${tree.emp_first_name} ${tree.emp_middle_name} ${tree.emp_last_name}`,
                      children: [],
                      designation_desc:
                        tree.pr_designation_details.designation_desc,
                      designation_id: 0,
                      parent_id:
                        employeeFromDesignation.find(
                          (chart) =>
                            chart.org_chart_details.pr_designation_id ===
                            tree.pr_designation_id
                        )?.org_chart_details.pr_designation_id ?? 0,
                      image: "",
                      chart_id:
                        employeeFromDesignation.find(
                          (chart) =>
                            chart.org_chart_details.pr_designation_id ===
                            tree.pr_designation_id
                        )?.org_chart_details.id ?? 0,
                      pr_emp_id: tree.id,
                      sub_count: tree.subordinate_count,
                      parent_department: child.parent_department,
                    };
                  }),
                  ...result,
                ];
                setActiveFlag(!activeFlag);
              }
            });
          }
          child.children = result;
        }
      });
    }
  };

  return (
    <>
      <TreeComponent
        data={chartData}
        handleClick={hanldeClick}
        downLoadUrl={downLoadUrl}
      />
      <Button mode="back" />
    </>
  );
};
export default App;

import { gql } from "@apollo/client";

export const AddParentUser = gql`
  mutation AddParentUser(
    $token: String!
    $input: CreateAuthParentInput!
    $user_details: SubscribedUser!
  ) {
    AddParentUser(token: $token, input: $input, user_details: $user_details) {
      id
      first_name
      middle_name
      last_name
      parent_address
      parent_place
      parent_state
      parent_pin
      parent_email
      parent_mobile
      parent_qualification
      parent_aadhar
      parent_rights
      parent_type
    }
  }
`;

export const UpdateParentUserById = gql`
  mutation UpdateParentUserById(
    $id: ID!
    $token: String!
    $user_details: SubscribedUser!
    $input: UpdateAuthParentInput!
  ) {
    UpdateParentUserById(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    ) {
      id
      first_name
      middle_name
      last_name
      parent_address
      parent_place
      parent_state
      parent_pin
      parent_email
      parent_mobile
      parent_qualification
      parent_aadhar
      parent_rights
      parent_type
    }
  }
`;
export const AddParentStudentAssociation = gql`
  mutation AddParentStudentAssoci(
    $token: String!
    $input: [ParentStudentAssoci!]!
    $parent_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    AddParentStudentAssoci(
      token: $token
      parent_id: $parent_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdateParentStudentAssoci = gql`
  mutation UpdateParentStudentAssoci(
    $token: String!
    $input: [ParentStudentAssoci!]!
    $parent_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    UpdateParentStudentAssoci(
      token: $token
      parent_id: $parent_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateParentLoginStatus = gql`
  mutation UpdateParentLoginStatus(
    $token: String!
    $parent_id: ID!
    $login_status: Boolean!
    $user_details: SubscribedUser!
  ) {
    UpdateParentLoginStatus(
      token: $token
      parent_id: $parent_id
      login_status: $login_status
      user_details: $user_details
    )
  }
`;
export const EnableParentLoginStatusWithNewEmail = gql`
  mutation EnableParentLoginStatusWithNewEmail(
    $token: String!
    $parent_id: ID!
    $email: String!
    $user_details: SubscribedUser!
  ) {
    EnableParentLoginStatusWithNewEmail(
      token: $token
      parent_id: $parent_id
      email: $email
      user_details: $user_details
    )
  }
`;

/**
 * React functional component that handles the additional fee for a group of students.
 *
 * Example Usage:
 * <ForGroupOfStudents />
 *
 * Inputs:
 * - Branch, class, category (optional), and account ledger selected by the user.
 * - Additional fee amount entered by the user.
 * - Date selected by the user.
 *
 * Flow:
 * 1. Renders a form with dropdowns for branch, class, category (if enabled), and account ledger selection.
 * 2. User selects the desired options and enters the additional fee amount and date.
 * 3. Fetches the list of students based on the selected criteria using Apollo GraphQL queries.
 * 4. Displays the fetched student data in a table, with checkboxes to select multiple students.
 * 5. User can select multiple students and click the "Save" button to add the additional fee demand for the selected students.
 * 6. Updates the student demand details using Apollo GraphQL mutations.
 * 7. If the update is successful, a success message is displayed.
 * 8. User can view the complete fee details of a student by clicking the eye icon in the table row.
 * 9. Complete fee details are displayed in a modal.
 * 10. User can close the modal or cancel the operation.
 *
 * Outputs:
 * - Updated student demand details for the selected students.
 * - Success message if the update is successful.
 * - Complete fee details of a student in a modal.
 */
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { FeeDemandTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import DownArrow from "../../../../images/DownArrow.svg";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  FresherExisting,
  Operation,
  StudentAcctReportType,
} from "../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import { AddOrUpdateOrDeleteStudentDemandDetails } from "../../queries/demands/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import {
  formatter,
  getModifiedScrollHeight,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import { useNavigate, useParams } from "react-router-dom";
import useDropdownData from "../../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import Eye from "../../../../images/Eye.svg";
import Close from "../../../../images/Close.svg";
import {
  GetStdCompleteFeeDetailsByStudentIDData,
  GetStdCompleteFeeDetailsByStudentIDDetails,
  studentDemandVars,
} from "../../../../Types/Accounting";
import Modal from "react-modal";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../queries/students/list/byId";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../../hooks/useStudentsbyDemandAmount";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import {
  AddOrUpdateOrDeleteStudentDemandDetailsData,
  AddOrUpdateOrDeleteStudentDemandDetailsVars,
} from "../../../../Types/Accounting/mutations";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { AccountsTitles } = require("../../json/title.json");

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const ForGroupOfStudents = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const { InstId } = useParams<{ InstId: string }>();

  const { state } = useContext(AppContext);
  const deptId = 0;
  const semId = 0;
  const sectionId = 0;
  let totalRefund = 0;
  let demandtotalconcession = 0;
  let totalpaidAmount = 0;
  let totalPayable = 0;
  let totalbalance = 0;
  let demandTotal = 0;

  const [completeFeeReceiptData, setStudentCompleteFeeReceiptData] =
    useState<GetStdCompleteFeeDetailsByStudentIDDetails>();
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const { serverDate } = useServerDateandTime();
  const fresherExistingOptions: optionsType[] = [
    {
      label: toStandardCase(FresherExisting.FRESHER),
      value: StudentAcctReportType.DEMAND_FRESHER,
    },
    {
      label: toStandardCase(FresherExisting.EXISTING),
      value: StudentAcctReportType.DEMAND_EXISTING,
    },
  ];
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [studentModal, setStudentModal] = useState(false);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [searchData, setSearchData] = useState("");

  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [accountLedgerId, setAccountLedgerId] = useState<responseType | null>(
    null
  );

  const [fresherExisting, setFresherExisting] = useState<optionsType | null>(
    null
  );

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [demandAmount, setDemandAmount] = useState(0);
  const [selectedStudents, setSelected] = useState<readonly string[]>([]);
  const [date, setDate] = useState(TODAY_DATE);
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;

  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const accountLedgerRef = useRef<HTMLSelectElement>(null);
  const accountLedgerRefInputRef = accountLedgerRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;
  const demandAmountRef = useRef<HTMLInputElement>(null);
  const { user_details } = useLoggedInUserDetails();

  const [GetStdCompleteFeeReceiptsByStudentID] = useLazyQuery<
    GetStdCompleteFeeDetailsByStudentIDData,
    studentDemandVars
  >(GetStdCompleteFeeDetailsByStudentID);

  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { students: StudentsData } = UseStudentsbyDemandAmount(
    deptId,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semId,
    sectionId,
    USE_CATEGORY_KEY ? (categoryId ? categoryId.value : 0) : 0,
    EMPTY_STRING,
    ROWS_PER_PAGE,
    fresherExisting
      ? (fresherExisting.value as StudentAcctReportType)
      : StudentAcctReportType.DEMAND_RAISED,
    accountLedgerId ? accountLedgerId.value : 0
  );
  const { data, fetchMore, loading } = StudentsData;

  const { classDropDown } = useDropdownData(
    0,
    branchId ? branchId.value : 0,
    0,
    0
  );
  const { branchDropDown } = useInstMasterDataByInstId();

  const { categoryDropDown } = useInstMasterDataByInstId();
  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS_WITHOUT_FINE,
    ROWS_PER_PAGE
  );
  const [
    UpdateStudentDemandDetails,
    { loading: UpdateStudentDemandDetailsLoading },
  ] = useMutation<
    AddOrUpdateOrDeleteStudentDemandDetailsData,
    AddOrUpdateOrDeleteStudentDemandDetailsVars
  >(AddOrUpdateOrDeleteStudentDemandDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedStudents.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStudents, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStudents.slice(1));
    } else if (selectedIndex === selectedStudents.length - 1) {
      newSelected = newSelected.concat(selectedStudents.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudents.slice(0, selectedIndex),
        selectedStudents.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name: string) => selectedStudents.indexOf(name) !== -1;
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data?.GetAcctStdDemand.edges.map((label) =>
        label.node.mst_student.id.toString()
      );
      setSelected(newSelecteds!);
      return;
    }
    setSelected([]);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setBranchId(null);
      setClassId(null);
      setCategoryId(null);
      setAccountLedgerId(null);
      setDemandAmount(0);
    }
    //enableAdd and enableEdit should stay false when you close the success modal
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setBranchId(null);
    setClassId(null);
    setCategoryId(null);
    setAccountLedgerId(null);
    setDemandAmount(0);
    setStudents([]);
    setFresherExisting(null);
  };

  const branchClear = () => {
    setClassId(null);
    setCategoryId(null);
    setAccountLedgerId(null);
    setDemandAmount(0);
    setStudents([]);
  };
  const handleStudentDemandDetailsUpdation = () => {
    if (state.ActiveFinYr === null) {
      setMessage({
        flag: true,
        message: "Financial Year not found",
        operation: Operation.NONE,
      });
      return;
    }
    UpdateStudentDemandDetails({
      variables: {
        token,
        student_id: selectedStudents,
        input: {
          update_std_demand_details: [],
          add_std_demand_details: [
            {
              fee_demand: demandAmount,
              acct_ldgr_id: accountLedgerId ? accountLedgerId.value : 0,
              fee_due_date: toIsoDate(date),
            },
          ],
        },
        fin_yr_id: state.ActiveFinYr.id,
        user_details,
        inst_id: InstId!,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "added fee demand",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  // eslint-disable-next-line
  completeFeeReceiptData?.std_demand_details.map((demand) => {
    demandTotal += demand.fee_demand;
    demandtotalconcession += demand.fee_concession;
    totalbalance += demand.fee_bal;
    totalPayable += demand.fee_receivable;
    totalpaidAmount += demand.fee_received;
    totalRefund += demand.fee_refunds;
  });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({
                node: {
                  mst_student: { id },
                },
              }) =>
                newEdges.findIndex(
                  ({
                    node: {
                      mst_student: { id: newId },
                    },
                  }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: false, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) =>
              student.node.mst_student.id === newStudent.node.mst_student.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {Accounts_Table.FeeDemand.AdditionalFee.Group.Table_Headers.map(
            (th: TableHeaderProps, index: React.Key) => {
              return (
                <TableCell key={index} className={th.className}>
                  {th.labelName}
                </TableCell>
              );
            }
          )}
        </TableRow>
      </TableHead>
    );
  };
  const { branchLabel, classLabel ,categoryLabel} = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Additional Fee for Student Demand</Title>
      <div className="feedemand-additionalfee-group">
        <div className="row g-0 feedemand-additionalfee-group__details">
          <div className="col account-frames label-grid">
            <Label>{branchLabel}</Label>
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              openOnFocus
              value={branchId}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (branchId && e.key === Keys.ENTER) {
                  classInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchId(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchId(newValue as responseType);
                } else {
                  setBranchId(null);
                }
                branchClear();
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  autoFocus
                  className={labelClasses.formControlRoot}
                />
              )}
            />

            <Label>{classLabel} </Label>
            <LabeledAutocomplete
              ref={classRef}
              className={labelClasses.inputRoot}
              value={classId}
              options={classDropDown!}
              openOnFocus
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER && classId) {
                  USE_CATEGORY_KEY
                    ? categoryInputRef?.focus()
                    : accountLedgerRefInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassId(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassId({
                    label: (newValue as responseType).label,
                    value: (newValue as responseType).value,
                    isChecked: true,
                  });
                } else {
                  setClassId(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
            {USE_CATEGORY_KEY && (
              <>
                <Label>{categoryLabel}</Label>
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={categoryDropDown}
                  openOnFocus
                  value={categoryId}
                  ref={categoryRef}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER && categoryId) {
                      accountLedgerRefInputRef?.focus();
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setCategoryId(null);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategoryId({
                        label: (newValue as responseType).label,
                        value: (newValue as responseType).value,
                        isChecked: true,
                      });
                    } else {
                      setCategoryId(null);
                    }
                  }}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </>
            )}
            <Label>Fresher/Existing</Label>
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={fresherExistingOptions}
              value={fresherExisting}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as optionsType, fresherExisting)
              }
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.BACKSPACE) {
                  setBranchId(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setFresherExisting(newValue as optionsType);
                } else {
                  setFresherExisting(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col account-frames">
            <div className="label-grid">
              <Label>Additional Fee</Label>
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={acctLedgers.responseType}
                openOnFocus
                freeSolo
                disabled={
                  USE_CATEGORY_KEY ? categoryId === null : classId === null
                }
                value={accountLedgerId}
                ref={accountLedgerRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && accountLedgerId) {
                    demandAmountRef.current?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setAccountLedgerId(null);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    const data = newValue as responseType;
                    setAccountLedgerId({
                      label: data.label,
                      value: data.value,
                      isChecked: true,
                    });
                  } else {
                    setAccountLedgerId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setSearchData(e.target.value)}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <Label>Amount</Label>
              <Input
                disabled={accountLedgerId === null}
                inputRef={demandAmountRef!}
                value={demandAmount}
                id="receivedamount"
                type="number"
                onChange={(e) => setDemandAmount(Number(e.target.value))}
              />
              <TextField
                type="date"
                label="Date"
                className="feedemand-additionalfee-group__textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
            input:{
              inputProps: {
                min: state.ActiveFinYr
                  ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                  : EMPTY_STRING,
                max: toInputStandardDate(serverDate),
              },
            }
          }}
              
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="feedemand-additionalfee-group__tableblock">
          {!students?.length && demandAmount > 0 ? (
            <b className="nodata"> Sorry, no students </b>
          ) : students?.length && demandAmount === 0 ? (
            <b className="nodata">
              Please enter demand amount in order to proceed
            </b>
          ) : students.length > 0 ? (
            <TableContainer
              className="feedemand-additionalfee-group__tableblock--table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHead
                  onSelectAllClick={handleSelectAllClick}
                  numSelected={selectedStudents.length}
                  rowCount={data ? data?.GetAcctStdDemand.edges.length : 0}
                />
                <TableBody>
                  {demandAmount > 0 &&
                    students.map((edge, index) => {
                      const isItemSelected = isSelected(
                        edge.node.mst_student.id.toString()
                      );
                      return (
                        <TableRow
                          role="checkbox"
                          aria-checked={isItemSelected}
                          key={edge.node.mst_student.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            id="td-center"
                            className="feedemand-additionalfee-group__tableblock--table--checkbox"
                          >
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => {
                                handleClick(
                                  event,
                                  edge.node.mst_student.id.toString()
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="feedemand-additionalfee-group__tableblock--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="feedemand-additionalfee-group__tableblock--table--admno">
                            {edge.node.mst_student.std_adm_no}
                          </TableCell>
                          <TableCell className="feedemand-additionalfee-group__tableblock--table--regno">
                            {edge.node.mst_student.std_reg_no}
                          </TableCell>
                          <TableCell>
                            {edge.node.mst_student.first_name +
                              " " +
                              edge.node.mst_student.middle_name +
                              " " +
                              edge.node.mst_student.last_name}
                          </TableCell>
                          <TableCell id="td-right">
                            {format(edge.node.std_demand_amt)}
                          </TableCell>
                          <TableCell id="td-right">
                            {format(demandAmount)}
                          </TableCell>

                          <TableCell id="td-right">
                            {format(edge.node.std_demand_amt + demandAmount)}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="feedemand-additionalfee-group__tableblock--table--year"
                          >
                            <img
                              src={Eye}
                              alt="/"
                              onClick={() => {
                                GetStdCompleteFeeReceiptsByStudentID({
                                  variables: {
                                    token,
                                    fin_yr_id: state.ActiveFinYr
                                      ? state.ActiveFinYr.id
                                      : 0,
                                    inst_id: InstId!,
                                    student_id: edge.node.mst_student.id,
                                  },
                                }).then(({ data }) => {
                                  if (data) {
                                    setStudentModal(!studentModal);
                                    setStudentCompleteFeeReceiptData(
                                      data.GetStdCompleteFeeDetailsByStudentID
                                    );
                                  }
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <b className="nodata">Sorry, no students</b>
          )}
        </div>
      </div>
      <div className="row g-0">
        <div className="col button-left">
          <Button
            mode="save"
            onClick={handleStudentDemandDetailsUpdation}
            disabled={
              !selectedStudents.length ||
              branchId === null ||
              classId === null ||
              USE_CATEGORY_KEY
                ? categoryId === null
                : false
            }
          />

          <Button mode="clear" onClick={handleClear} />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        <div className="col feedemand-additionalfee-group__total">
          <Label>
            Total Count <b>{data ? data?.GetAcctStdDemand.totalCount : 0}</b>
            &nbsp;, &nbsp;Selected
          </Label>
          <div className="feedemand-additionalfee-group__total--count">
            {selectedStudents.length}
          </div>
        </div>
      </div>
      <LoadingModal flag={UpdateStudentDemandDetailsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {AccountsTitles.FeeDemand.AdditionalFee.Titles.map(
                (title: FeeDemandTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>{title.View}</React.Fragment>
                  );
                }
              )}
            </Title>
            <div className="feedemand-additionalfee-group__view--tableblock">
              <TableContainer className="feedemand-additionalfee-group__view--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.StudentCompleteFeeReceipt.DemandFeePayable.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completeFeeReceiptData?.std_demand_details.map(
                      (demand, index) => {
                        return (
                          <React.Fragment>
                            <TableRow>
                              <TableCell
                                id="td-center"
                                className="std-complete-fee-receipt__frame--table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell>
                                {demand.acct_ldgr_details.ldgr_desc}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(demand.fee_ob)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(demand.fee_demand)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(demand.fee_concession)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(demand.fee_receivable)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(demand.fee_received)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(demand.fee_bal)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="std-complete-fee-receipt__frame--table--amount"
                              >
                                {format(demand.fee_refunds)}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={3} className="total">
                        Total :
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(demandTotal)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(demandtotalconcession)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(totalPayable)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(totalpaidAmount)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(totalbalance)}
                      </TableCell>
                      <TableCell id="td-right" className="totalcount">
                        {format(totalRefund)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
            <Button mode="cancel" onClick={() => setStudentModal(false)} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} onClick={() => setStudentModal(false)} alt="/" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ForGroupOfStudents;

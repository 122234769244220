import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
} from "../../../utils/UtilFunctions";
import { Title } from "../../../stories/Title/Title";

import { Button } from "../../../stories/Button/Button";

import { Checkbox, TextField } from "@mui/material";

import {
  TableHeaders,
  Operation,
  Direction,
  SortBy,
  StudentReportType,
} from "../../../utils/Enum.types";
import {
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { StudentsTitlesType } from "../../../Types/Student/json";

import Home from "../Home/Index";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import useTransportRoutesData from "../../../customhooks/general/useTransportRoutesData";
import { useLazyQuery, useMutation } from "@apollo/client";
import { RouteDetailsByData, RouteDetailsByVars } from "../paginationTypes";
import { GetTransportRouteDetailsByMasterId } from "../queries/list";
import useToken from "../../../customhooks/useToken";
import { AddTransportStudent } from "../queries/mutations";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../context/context";
import { GetAcdStudentsForAdmissions } from "../../Academics/queries/students/Index";
import {
  AcdStudentsForAdmissionvars,
  GetStudentAcdForAdmissionData,
  StudentAcdNode,
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
const { Students } = require("../../../json/title.json");
const StudentList = () => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);

  const [routeId, setRouteId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [stageSelected, setStageSelected] = useState<responseType | null>(null);
  const [searchData, setSearchData] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [students, setStudents] = useState<StudentEdges[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const idSet = new Set(selectedIds);

  const [GetStudentsData, { data: StudentsData, loading, fetchMore }] =
    useLazyQuery<GetStudentAcdForAdmissionData, AcdStudentsForAdmissionvars>(
      GetAcdStudentsForAdmissions
    );

  const [GetRouteDetailsById, { data }] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id: routeId.value,
    },
  });

  const { routesData } = useTransportRoutesData(searchData, false);
  const stagesDropDown: responseType[] = data
    ? data.GetTransportRouteDetailsByMasterId.map((res) => ({
        label: res.route_stage_desc,
        value: res.id,
      }))
    : [];

  const [AssignRoute, { loading: creationLoading }] = useMutation(
    AddTransportStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const HandleSubmit = () => {
    if (stageSelected) {
      AssignRoute({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          route_detail_id: stageSelected.value,
          student_ids: selectedIds,
        },
        refetchQueries: [
          {
            query: GetAcdStudentsForAdmissions,
            variables: {
              after: null,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              status: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              orderBy: [
                {
                  direction: Direction.ASC,
                  field: SortBy.FIRST_NAME,
                },
              ],
              input: {
                ids: [Number(InstId)],
                acd_std_query_type: StudentReportType.STDS_NOT_ASSIGNED_ROUTE,
                str_data: [],
                int_data: [],
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Assigned Route to Student",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      setMessage({
        flag: true,
        message: "Please Select The Stage",
        operation: Operation.NONE,
      });
    }
  };

  const handleClear = () => {
    setRouteId(EMPTY_RESPONSETYPE_OBJECT);
    setStageSelected(null);
  };
  const Row = (props: { row: StudentAcdNode; sl: number }) => {
    const { row, sl } = props;

    return (
      <React.Fragment key={row.id}>
        <TableRow>
          <TableCell padding="checkbox" id="td-center">
            <Checkbox
              checked={idSet.has(row.id)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked = e.target.checked;
                const id = row.id;
                if (isChecked) {
                  setSelectedIds((prevIds) => [...prevIds, id]);
                } else {
                  setSelectedIds((prevIds) =>
                    prevIds.filter((prevId) => prevId !== id)
                  );
                }
              }}
            />
          </TableCell>

          <TableCell
            id="td-center"
            className="assign-route-for-multiple__table--slno"
          >
            {sl}
          </TableCell>

          <TableCell className="assign-route-for-multiple__table--admno">
            {row.std_adm_no}
          </TableCell>

          <TableCell className="assign-route-for-multiple__table--admno">
            {row.std_reg_no}
          </TableCell>
          <TableCell>
            {row.first_name + " " + row.middle_name + " " + row.last_name}
          </TableCell>

          <TableCell className="assign-route-for-multiple__table--status">
            {row.acd_branch.branch_desc}
          </TableCell>
          <TableCell className="assign-route-for-multiple__table--status">
            {row.acd_class.class_desc}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: StudentsData
                  ? StudentsData.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (routeId.value) {
      GetRouteDetailsById({
        variables: {
          token,
          inst_id: InstId!,
          route_master_id: routeId.value,
        },
      });
    }
  }, [GetRouteDetailsById, InstId, routeId.value, token]);
  useEffect(() => {
    if (StudentsData && !loading) {
      const newData = StudentsData
        ? StudentsData?.GetAcdStudents.edges.map((edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true,
            },
          }))
        : [];

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(
        StudentsData
          ? StudentsData?.GetAcdStudents.pageInfo.endCursor
          : EMPTY_STRING
      );
    } // eslint-disable-next-line
  }, [StudentsData, loading]);
  useEffect(() => {
    if (token) {
      GetStudentsData({
        variables: {
          after: null,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          first: ROWS_PER_PAGE,
          name: EMPTY_STRING,
          status: EMPTY_STRING,
          token,
          orderBy: [
            {
              direction: Direction.ASC,
              field: SortBy.FIRST_NAME,
            },
          ],
          input: {
            ids: [Number(InstId!)],
            acd_std_query_type: StudentReportType.STDS_NOT_ASSIGNED_ROUTE,
            int_data: [],
            str_data: [],
          },
        },
      });
    }
  }, [GetStudentsData, state.ActiveFinYr, InstId, token, state.ActiveAcdYr]);

  const { branchLabel, classLabel } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>
            {Students.Titles.map(
              (title: StudentsTitlesType, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.AssignedTransportList}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
      </div>
      <div className="assign-route-for-multiple">
        <div className={"row g-0 assign-route-for-multiple__options"}>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={routesData.routesDropDown}
              openOnFocus
              forcePopupIcon
              value={routeId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setRouteId(newValue as responseType);
                  setStageSelected(null);
                } else {
                  setRouteId(EMPTY_RESPONSETYPE_OBJECT);
                  setStageSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bus Route"
                  onChange={(e) => setSearchData(e.target.value)}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  autoFocus
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={stagesDropDown ?? EMPTY_RESPONSETYPE}
              openOnFocus
              forcePopupIcon
              value={stageSelected}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, stageSelected)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setStageSelected(newValue as responseType);
                } else {
                  setStageSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Student Stage At"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>

          <div className="col"></div>
        </div>

        <div className={"assign-route-for-multiple__tableblock"}>
          {!students?.length ? (
            <b className="nodata">Sorry, No Students Found</b>
          ) : stageSelected ? (
            <TableContainer
              className="assign-route-for-multiple__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{TableHeaders.SLNO}</TableCell>

                    <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>

                    <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>

                    <TableCell>{TableHeaders.NAME}</TableCell>

                    <TableCell>{branchLabel}</TableCell>

                    <TableCell>{classLabel}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students?.map((response, index: number) => (
                    <Row key={index} row={response.node} sl={index + 1} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </div>
        <div className="row g-0">
          <div className="col-2">
            <Button mode="save" onClick={HandleSubmit} />
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
      </div>

      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentList;

import React, { useEffect } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";

import RightArrow from "../../../../images/ArrowRight.svg";
import { GetAttDashBoardTestDetails } from "../../../../queries/common";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { toIsoDate, toStandardDate } from "../../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../../utils/constants";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import Timer from "../../../../images/Timeline.svg";
import InProgress from "../../../../images/ProgressStatus.svg";
import usePollIntervalTime from "../../../../customhooks/usePollIntervalTime";
interface TestDetailsForDashboard {
  test_name: string;
  test_type: string;
  start_date: string;
  end_date: string;
}
interface GetAttDashBoardTestDetailsList {
  up_coming_tests: TestDetailsForDashboard[];
  in_progress_tests: TestDetailsForDashboard[];
}
export interface GetAttDashboardTestDetailsData {
  GetAttDashBoardTestDetails: GetAttDashBoardTestDetailsList;
}
export interface GetAttDashBoardTestVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  date_of_attendance: string;
}

const UpcomingTest = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const pollIntervalTime = usePollIntervalTime();

  const [GetAcdTestDetails, { data: testDetails }] = useLazyQuery<
    GetAttDashboardTestDetailsData,
    GetAttDashBoardTestVars
  >(GetAttDashBoardTestDetails, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: activeAcdId,
      date_of_attendance: toIsoDate(TODAY_DATE),
    },
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (
      token &&
      InstId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetAcdTestDetails();
    } // eslint-disable-next-line
  }, [
    token,
    GetAcdTestDetails,
    activeAcademicYearData.loading,
    activeAcademicYearData.data,
    InstId,
  ]);

  return (
    <>
      <div className="acd-admin-dashboard__title">
        <Title variant="subtitle1">Upcoming/InProg. Test/Exam</Title>
        <div className="acd-admin-dashboard__total">
          {/* <div className="student-total-count">
            Total :&nbsp;
            <b>
              {testDetails &&
                testDetails.GetAttDashBoardTestDetails.up_coming_tests.length}
            </b>
          </div> */}
          <img src={More} alt="" />
        </div>
      </div>
      <div className="acd-admin-dashboard__upcoming-test">
        <ul>
          {testDetails &&
            testDetails.GetAttDashBoardTestDetails.in_progress_tests.map(
              (data, index) => {
                return (
                  <li key={index}>
                    <div className="acd-admin-dashboard__upcoming-test--names">
                      <div className="acd-admin-dashboard__upcoming-test--names--title">
                        <b className="acd-admin-dashboard__upcoming-test--names--test font-grey">
                          {index + 1}. {data.test_name}
                        </b>
                        <span className=" acd-admin-dashboard__upcoming-test--names--test-type">
                          ({data.test_type})
                        </span>
                        <span className="acd-admin-dashboard__upcoming-test--total--inprogress">
                          <img src={InProgress} alt="" />
                          InProgress
                        </span>
                      </div>
                      <span className="acd-admin-dashboard__upcoming-test--names--s-date font-grey">
                        Start & End Date
                      </span>
                      <b className="acd-admin-dashboard__upcoming-test--names--date">
                        {toStandardDate(data.start_date)}/
                        {toStandardDate(data.end_date)}
                      </b>
                    </div>
                    <div className="acd-admin-dashboard__upcoming-test--total">
                      <img src={RightArrow} alt="" />
                    </div>
                  </li>
                );
              }
            )}
          {testDetails &&
            testDetails.GetAttDashBoardTestDetails.up_coming_tests.map(
              (data, index) => {
                return (
                  <li key={index}>
                    <div className="acd-admin-dashboard__upcoming-test--names">
                      <div className="acd-admin-dashboard__upcoming-test--names--title">
                        <b className="acd-admin-dashboard__upcoming-test--names--test font-grey">
                          {index + 1}. {data.test_name}
                        </b>
                        <span className=" acd-admin-dashboard__upcoming-test--names--test-type">
                          ({data.test_type})
                        </span>
                        <span className="acd-admin-dashboard__upcoming-test--total--upcoming">
                          <img src={Timer} alt="" /> Upcoming
                        </span>
                      </div>
                      <span className="acd-admin-dashboard__upcoming-test--names--s-date font-grey">
                        Start & End Date
                      </span>
                      <b className="acd-admin-dashboard__upcoming-test--names--date">
                        {toStandardDate(data.start_date)}/
                        {toStandardDate(data.end_date)}
                      </b>
                    </div>
                    <div className="acd-admin-dashboard__upcoming-test--total">
                      <img src={RightArrow} alt="" />
                    </div>
                  </li>
                );
              }
            )}
        </ul>
      </div>
    </>
  );
};

export default UpcomingTest;

import React, { useContext, useEffect } from "react";
import { Button } from "../../../../stories/Button/Button";

import { Title } from "../../../../stories/Title/Title";

import Home from "../../Home/Index";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatter,
  getHeaderRowStyle,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctLdgrYearlyDataSummation,
  GetAcctStudentDemandFeeSummary,
} from "../../queries/FeeLedgers/query/Byid";
import {
  GetAcctLdgrYearlyDataSummationData,
  GetAcctLdgrYearlyDataSummationVars,
  GetAcctStudentDemandFeeSummaryDetailsData,
} from "../../../../Types/Accounting";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  I4_CELL,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import {
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  FileUploadParams,
  InstitutionConfigurationTypes,
} from "../../../../utils/Enum.types";
import { SummaryHeader, SummaryHeaderPdf } from "../../common/HeaderConsts";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useAcctTableJson from "../../json/useAcctTableJson";
import { EMPTY_STRING } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridPinnedRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT
} from "../../../../styles/DataGridTableStyles";
 

const Index = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { format } = formatter;
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { Accounts_Table } = useAcctTableJson();
  const [GetAcctStdDemand, { data: Ledgers, loading: ledgerLoading }] =
    useLazyQuery<
      GetAcctStudentDemandFeeSummaryDetailsData,
      GetAcctLdgrYearlyDataSummationVars
    >(GetAcctStudentDemandFeeSummary, {
      variables: {
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        inst_id: InstId!,
        token,
      },
      fetchPolicy: "network-only",
    });

  const [GetAcctLdgrYr, { data, loading, error }] = useLazyQuery<
    GetAcctLdgrYearlyDataSummationData,
    GetAcctLdgrYearlyDataSummationVars
  >(GetAcctLdgrYearlyDataSummation, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const summaryData = Ledgers?.GetAcctStudentDemandFeeSummary.map(
    (edge, index) => ({
      SlNo: index + 1,
      LdgrDesc: edge.acct_ldgr_details.ldgr_desc,
      OB: edge.fee_ldgr_ob,
      DemandAmt: edge.fee_ldgr_demand,
      Concession: edge.fee_ldgr_concession,
      Receivable: edge.fee_ldgr_receivable,
      Received: edge.fee_ldgr_received,
      Balance: edge.fee_ldgr_bal,
      Refunds: edge.fee_ldgr_refunds,
      FeeBalance: edge.fee_ldgr_dropout_demand,
    })
  );

  useEffect(() => {
    if (token) {
      GetAcctStdDemand();
    }
  }, [token, GetAcctStdDemand, state.ActiveFinYr, InstId]);

  useEffect(() => {
    if (token) {
      GetAcctLdgrYr();
    }
  }, [token, GetAcctLdgrYr, state.ActiveFinYr, InstId]);
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    40,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text("Ledger Summary", 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: SummaryHeaderPdf.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: summaryData,
                  foot: [
                    [
                      "",
                      "Closing Balance",
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_ob
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_demand
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_concession
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_receivable
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_received
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_bal
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_refunds
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_dropout_demand
                      )}`,
                    ],
                  ],
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                  columnStyles: {
                    3: { halign: "right" },
                    4: { halign: "right" },
                    5: { halign: "right" },
                    6: { halign: "right" },
                    7: { halign: "right" },
                    8: { halign: "right" },
                    9: { halign: "right" },
                    10: { halign: "right" },
                  },
                  footStyles: {
                    fillColor: [144, 238, 144],
                    textColor: [0, 0, 0],
                    fontSize: 10,
                    halign: "right",
                  },
                });
                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${state.ActiveFinYr?.fin_yr} `
                );
              });
            });
        });
      });
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.FEE_BALANCE);

    const dataRows = summaryData
      ? summaryData?.map((item) => [
          item.SlNo,
          item.LdgrDesc,
          item.OB,
          item.DemandAmt,
          item.Concession,
          item.Receivable,
          item.Received,
          item.Balance,
          item.Refunds,
          item.FeeBalance,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 25;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          if (configLogo) worksheet.addImage(imageV, "J1:J3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");

                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:J2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:J3");

                  const mergedHeader: Cell = worksheet.getCell(D4_CELL);
                  mergedHeader.value = ExcelPageHeader.FEE_BALANCE;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("D4:H4");
                  const mergedDate: Cell = worksheet.getCell(I4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("I4:J4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:C4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < SummaryHeader.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = SummaryHeader[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.RIGHT };
                      cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
                      row.getCell(2).font = {
                        name: ExcelFont.CENTURY_GOTHIC,
                        size: 9,
                      };
                      row.getCell(2).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    });
                  });

                  const footerRow = worksheet.addRow([]);
                  footerRow.getCell(2).value =
                    ExcelFooterHeader.CLOSING_BALANCE;
                  footerRow.getCell(2).font = {
                    name: ExcelFont.ARIAL,
                    size: 9,
                  };
                  footerRow.getCell(3).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_ob
                  );
                  footerRow.getCell(4).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_demand
                  );
                  footerRow.getCell(5).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_concession
                  );
                  footerRow.getCell(6).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_receivable
                  );
                  footerRow.getCell(7).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_received
                  );
                  footerRow.getCell(8).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_bal
                  );
                  footerRow.getCell(9).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_refunds
                  );
                  footerRow.getCell(10).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0]
                      .fee_ldgr_dropout_demand
                  );

                  for (let i = 2; i <= 11; i++) {
                    footerRow.getCell(i).alignment = {
                      horizontal: ExcelAlignment.RIGHT,
                    };
                  }
                  worksheet.addConditionalFormatting({
                    ref: `C${footerRow.number}:J${footerRow.number}`,
                    rules: FOOTER_CSS,
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      ExcelSheetsNames.DEMAND_SUMMARY
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  if (error) return <>{error.message}</>;

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.LedgerSummary.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  const rows: GridValidRowModel[] =
    (Ledgers ? Ledgers.GetAcctStudentDemandFeeSummary : []).map(
      (res, index) => {
        return {
          id: index + 1,
          ledger_desc: res.acct_ldgr_details.ldgr_desc,
          ob: format(res.fee_ldgr_ob),
          demand_amount: format(res.fee_ldgr_demand),
          concession: format(res.fee_ldgr_concession),
          receivable: format(res.fee_ldgr_receivable),
          received: format(res.fee_ldgr_received),
          refunds: format(res.fee_ldgr_refunds),
          balance: format(res.fee_ldgr_bal),
        };
      }
    ) || [];

  const getRow = () => {
    if (data && data.GetAcctLdgrYearlyDataSummation.length && !loading) {
      return data.GetAcctLdgrYearlyDataSummation.map((res, index) => {
        return {
          id: [],
          ledger_desc: "Closing Balance :",
          ob: format(data.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_ob),
          demand_amount: format(
            data.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_demand
          ),
          concession: format(
            data.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_concession
          ),
          receivable: format(
            data.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_receivable
          ),
          received: format(
            data.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_received
          ),
          refunds: format(
            data.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_refunds
          ),
          balance: format(data.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_bal),
        };
      });
    } else {
      return [];
    }
  };
  const pinnedRows: GridPinnedRowsProp = {
    bottom:
      data && data.GetAcctLdgrYearlyDataSummation.length && !loading
        ? getRow()
        : [],
  };
  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];
    const isPinnedRow = pinnedRowIds.includes(params.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "ledger_desc":
          return "total";
        case "id":
          return "total";
        case "balance":
          return "balance-count";
        default:
          return "totalcount";
      }
    }

    return "";
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Ledger Summary</Title>
      <div className="ledger-summary">
        <div className={`ledger-summary__tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
            pinnedRows={pinnedRows}
            getCellClassName={getCellClassName}
            
          />
        </div>
        <div className="row g-0">
          <div className="col">
            <Button onClick={downloadPdf} mode="pdf" />

            <Button onClick={downloadExcel} mode="export" />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 ledger-summary__total">
            <div className="student-total-count">
              Total Ledgers :&nbsp;
              <b>{Ledgers?.GetAcctStudentDemandFeeSummary.length!}</b>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal flag={loading || ledgerLoading} />
    </>
  );
};

export default Index;

import React, { useContext, useEffect, useState } from "react";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { Button } from "../../../../../stories/Button/Button";
import { AppContext } from "../../../../../context/context";
import { TEXT_FONT_FAMILY } from "../../../../../styles/DataGridTableStyles";
import ReactEcharts from "echarts-for-react";
import { useLazyQuery } from "@apollo/client";
import { GetAcdStdAttOverAllData } from "../../../../Academics/hooks/useAcdStudentAttendance";
import { GetAcdStdAttOverAllVars } from "../../../../Academics/types/holidays";
import { GetAcdStdAttOverAll } from "../../../../Academics/queries/holidays/list";
import { EMPTY_STRING } from "../../../../../utils/constants";
import useToken from "../../../../../customhooks/useToken";
import {
  Direction,
  InstitutionConfigurationTypes,
  SortBy,
} from "../../../../../utils/Enum.types";
import AbsentDays from "./AbsentDays";
import LoadingModal from "../../../../../pages/LoadingModal";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import GraphData from "../../../../Academics/DailyActivities/Attendance/SessionDetails/GraphData";
import { AttendanceDrawer } from "../../../../../styles/DrawerStyles";

const OverAllAttendance = () => {
  const { serverDate } = useServerDateandTime();
  const { state } = useContext(AppContext);
  const [absentiesList, setAbsentiesList] = useState(false);
  const { token } = useToken();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  const [GetOverAllAttendance, { data: OverAllData, loading }] = useLazyQuery<
    GetAcdStdAttOverAllData,
    GetAcdStdAttOverAllVars
  >(GetAcdStdAttOverAll, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: 0,
        entry_level: EMPTY_STRING,
        inst_id: state.InstId!,
        cal_month: toIsoDate(serverDate),
        subj_master_id: 0,
        session_id: 0,
        student_id: state.studentId,
        att_query_type: "OVER_ALL_ATT_PER_STD",
        date_of_attendance: toIsoDate(serverDate),
      },
      token,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      after: null,
      first: null,
      name: EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (state.studentId && token && state.ActiveAcdYr) {
      GetOverAllAttendance();
      //   GetOverAllSubjectAttendance();
    }
  }, [
    GetOverAllAttendance,
    // GetOverAllSubjectAttendance,
    state.studentId,
    token,
    state.ActiveAcdYr,
  ]);

  const options = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "horizontal",
      bottom: "bottom",
    },
    textStyle: {
      fontSize: 14,
      fontFamily: TEXT_FONT_FAMILY,
      color: "#1e293b",
    },
    series: [
      {
        name: "Attendance Report",
        type: "pie",
        radius: "50%",
        data: [
          {
            value: OverAllData
              ? OverAllData.GetAcdStdAttOverAll.edges[0]?.node
                  .total_session_present
              : 0,
            name: "Present",
          },
          {
            value: OverAllData
              ? OverAllData.GetAcdStdAttOverAll.edges[0]?.node
                  .total_session_absent
              : 0,
            name: "Absent",
          },
        ],
      },
    ],

    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: "rgba(0, 0, 0, 0.5)",
      },
    },
    color: ["#22c55e", "#ef4444"],
  };

  return (
    <>
      <div className="student-dashboard__attendance--full-view--datablock--frame--cards row g-0">
        <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
            Total Attendance
          </span>
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--attendance">
            {OverAllData &&
              OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node?.total_session}
          </span>
        </div>
        <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
            Total Present
          </span>
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--present">
            {OverAllData &&
              OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                .total_session_present}
          </span>
        </div>
        <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
            Total Absent
          </span>
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--absent">
            {OverAllData &&
              OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                .total_session_absent}
          </span>
        </div>
        <div className="student-dashboard__attendance--full-view--datablock--frame--blocks col">
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--header">
            Total Percentage
          </span>
          <span className="student-dashboard__attendance--full-view--datablock--frame--blocks--percentage">
            {OverAllData &&
              OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node.over_all_per}
            %
          </span>
        </div>
      </div>
      {OverAllData &&
      OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node.over_all_per ===
        100 ? null : (
        <div className="student-dashboard__attendance--full-view--datablock--frame--button">
          <Button
            mode="absent"
            onClick={() => setAbsentiesList(!absentiesList)}
          >
            View Absent Days
          </Button>
        </div>
      )}

      <div className="student-dashboard__attendance--full-view--datablock--frame--graph">
        {enablePerSessionAtt ? (
          <>
            <GraphData />
          </>
        ) : (
          <>
            <ReactEcharts option={options} />
          </>
        )}
      </div>

      <AttendanceDrawer
        anchor="right"
        open={absentiesList}
        onClose={() => setAbsentiesList(!absentiesList)}
      >
        <AbsentDays setModalFlag={setAbsentiesList} />
      </AttendanceDrawer>
      <LoadingModal flag={loading} />
    </>
  );
};

export default OverAllAttendance;

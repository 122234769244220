import React, { useEffect } from "react";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import { toStandardDate } from "../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../utils/constants";
import { useLazyQuery } from "@apollo/client";
import { GetInstDocumentContent } from "./queries";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useDocumentConfig from "./hooks/useDocumentConfig";
import {
  DocumentAcknowledgement,
  FileUploadParams,
} from "../../../utils/Enum.types";

interface Props {
  selectedId: number;
  selectedStudents: number[];
  setStudentIdSet: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const Document = ({ selectedId, selectedStudents, setStudentIdSet }: Props) => {
  const { token } = useToken();
  const { InstId, documentId } = useParams();
  const { InstFormData } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });

  const {
    titleName,
    academicYear,
    bottomMargin,
    custNameColor,
    custName,
    footerHeight,
    footerText,
    headerBorderColor,
    headerHeight,
    instAddress,
    instLogo,
    instName,
    instNamecolor,
    leftMargin,
    rightMargin,
    sign1,
    sign2,
    sign3,
    sign4,
    sign5,
    topMargin,
    docDate,
    footerBorderColor,
    instAddressColor,
    minBodyHeight,
  } = useDocumentConfig({
    selectedId: Number(documentId),
    selectedStudents,
    setStudentIdSet,
    pageType: DocumentAcknowledgement.GENERAL,
  });

  const [GetInstDocumentContentData, { data: contentData }] = useLazyQuery(
    GetInstDocumentContent,
    {
      variables: {
        token,
        inst_id: InstId!,
        document_id: selectedId ? selectedId : Number(documentId),
      },
    }
  );
  useEffect(() => {
    if (token && InstId! && documentId) {
      GetInstDocumentContentData();
    } else {
      if (token && InstId! && selectedId) {
        GetInstDocumentContentData();
      }
    }
  }, [token, InstId, documentId, selectedId, GetInstDocumentContentData]);

  return (
    <>
      <div
        className="document-layout__frame--left--frame h-100"
        style={{
          padding: `${topMargin?.config_integer_value}px ${rightMargin?.config_integer_value}px ${bottomMargin?.config_integer_value}px ${leftMargin?.config_integer_value}px`,
        }}
      >
        <div
          className="document-layout__frame--left--header"
          style={{
            height: `${headerHeight?.config_integer_value}px`,
            borderBottom: `1px solid ${headerBorderColor?.config_string_value}`,
          }}
        >
          {instLogo?.config_boolean_value && (
            <div className="document-layout__frame--left--header--logo">
              {LogoOrSign.defaultLogo ? (
                <img src={LogoOrSign.defaultLogo} alt="/" />
              ) : null}
            </div>
          )}

          <div className="document-layout__frame--left--header--inst-details">
            {custName?.config_boolean_value && (
              <div
                className="document-layout__frame--left--header--cust-name"
                style={{ color: `${custNameColor?.config_string_value}` }}
              >
                {InstFormData.cust_name}
              </div>
            )}
            {instName?.config_boolean_value && (
              <div
                className="document-layout__frame--left--header--inst-name"
                style={{ color: `${instNamecolor?.config_string_value}` }}
              >
                {InstFormData.inst_name}
              </div>
            )}
            {instAddress?.config_boolean_value && (
              <div
                className="document-layout__frame--left--header--inst-address"
                style={{ color: `${instAddressColor?.config_string_value}` }}
              >
                {InstFormData.inst_address}
              </div>
            )}
          </div>
        </div>
        <div
          className="document-layout__frame--left--body"
          style={{ height: minBodyHeight }}
        >
          <div className="document-layout__frame--left--body--container">
            <div className="document-layout__frame--left--body--number">
              {academicYear?.config_boolean_value && <span>SVN/2023</span>}
              {docDate?.config_boolean_value && (
                <span>Date:{toStandardDate(TODAY_DATE)}</span>
              )}
            </div>
            {titleName?.config_boolean_value && (
              <div className="document-layout__frame--left--body--sub-title">
                {titleName?.config_string_value}
              </div>
            )}

            <div className="document-layout__frame--left--body--content">
              <>{contentData ? contentData.GetInstDocumentContent : ""}</>
            </div>
          </div>

          <div className="document-layout__frame--left--body--footer">
            {sign1?.config_boolean_value && <b>{sign1.config_string_value}</b>}
            {sign2?.config_boolean_value && <b>{sign2.config_string_value}</b>}
            {sign3?.config_boolean_value && <b>{sign3.config_string_value}</b>}
            {sign4?.config_boolean_value && <b>{sign4.config_string_value}</b>}
            {sign5?.config_boolean_value && <b>{sign5.config_string_value}</b>}
          </div>
        </div>
        {footerText?.config_boolean_value && (
          <div
            className="document-layout__frame--left--footer"
            style={{
              height: `${footerHeight?.config_integer_value}px`,
              borderTop: `1px solid ${footerBorderColor?.config_string_value}`,
            }}
          >
            {footerText?.config_string_value}
          </div>
        )}
      </div>
    </>
  );
};

export default Document;

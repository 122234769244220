import React, { useEffect } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import TotalClasses from "../../../../images/TotalClasses.svg";
import ClassAttMarked from "../../../../images/ClassAttMarked.svg";
import ClassAttNotMarked from "../../../../images/ClassAttNotMarked.svg";
import ClassAttPercentage from "../../../../images/TotalPercentageMarked.svg";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../../utils/constants";
import { useLazyQuery } from "@apollo/client";
import { GetAttDashBoardCount } from "../../../../queries/common";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import MarkedAtt from "./graph/MarkedAtt";
import usePollIntervalTime from "../../../../customhooks/usePollIntervalTime";
const StudentAttStatus = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const pollIntervalTime = usePollIntervalTime();

  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const [GetAttendanceDashBoardData, { data: DashBoardData }] = useLazyQuery(
    GetAttDashBoardCount,
    {
      pollInterval: pollIntervalTime,
    }
  );
  useEffect(() => {
    if (
      token &&
      InstId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetAttendanceDashBoardData({
        variables: {
          token,
          inst_id: InstId,
          acd_yr_id: activeAcdId,
          date_of_attendance: toIsoDate(TODAY_DATE),
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    GetAttendanceDashBoardData,
    activeAcademicYearData.loading,
    activeAcademicYearData.data,

    InstId,
  ]);

  return (
    <>
      <div className="acd-admin-dashboard__card--title">
        <Title variant="subtitle1">Today's Class Att. Overview</Title>
        <img src={More} alt="" />
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={TotalClasses} alt="" />
          <span>Total Classes</span>
        </div>
        <b className="font-blue">
          {DashBoardData && DashBoardData.GetAttDashBoardCount.total_classes}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={ClassAttMarked} alt="" />
          <span>Attendance Marked</span>
        </div>
        <b className="font-green">
          {DashBoardData && DashBoardData.GetAttDashBoardCount.total_att_marked}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={ClassAttNotMarked} alt="" />
          <span>Attendance Not Marked</span>
        </div>
        <b className="font-red">
          {DashBoardData &&
            DashBoardData.GetAttDashBoardCount.total_att_not_marked}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={ClassAttPercentage} alt="" />
          <span>Total Marked Percentage</span>
        </div>
        <b className="font-purple">
          {DashBoardData &&
            DashBoardData.GetAttDashBoardCount.marked_percentage}
          %
        </b>
      </div>
      <MarkedAtt />
    </>
  );
};

export default StudentAttStatus;

import { useState } from "react";

import Modal from "react-modal";

import CompleteTransactionModal from "./Modals/CompleteTransactionModal";
import { PageFor } from "../../../../utils/Enum.types";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import { LedgersOrRegisters } from "../../../../utils/types";

interface Props {
  type: LedgersOrRegisters;
}
const LedgerTransactionDetails = ({ type }: Props) => {
  const [CompleteDetailsModal, setCompleteDetailsModal] = useState(false);

  return (
    <>
      <CompleteTransactionModal
        setCompleteDetailsModal={setCompleteDetailsModal}
        CompleteDetailsModal={CompleteDetailsModal}
        pageType={PageFor.GENERAL}
        type={type}
      />

      {/* Complete Ledger Transaction Details */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={CompleteDetailsModal}
        style={StudentModalStyles}
      >
        <div className="bank-book__table-section--transaction-details--tableblock">
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <CompleteTransactionModal
                setCompleteDetailsModal={setCompleteDetailsModal}
                CompleteDetailsModal={CompleteDetailsModal}
                pageType={PageFor.MODAL}
                type={type}
              />
            </div>
            <div className="modal-flex__image">
              <img
                onClick={() => setCompleteDetailsModal(!CompleteDetailsModal)}
                src={Close}
                alt="/"
                className="modal-close-icon"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LedgerTransactionDetails;

import  { useContext, useEffect, useState } from "react";
import useToken from "./useToken";
import useInstitutionConfiguration from "./useInstitutionConfiguration";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/context";
import { useLazyQuery } from "@apollo/client";
import {
  AcdStudentsvars,
  GetStudentAcdData,
} from "../Modules/Academics/hooks/useAcdStudentsData";
import { GetAcdStudents } from "../Modules/Academics/queries/students/Index";
import { ROWS_PER_PAGE } from "../utils/constants";
import { Direction, SortBy,  } from "../utils/Enum.types";
import { StudentAcdType } from "../utils/studentAcdEnum.types";


const useStudentDataToGetBirthday = (queryType:string) => {
  const { token } = useToken();
  const { entry_level } = useInstitutionConfiguration();
  const { entryId, InstId } = useParams();
  const { state } = useContext(AppContext);

  const [searchData, setSearchData] = useState("");

  const [GetStudentsData, { data }] = useLazyQuery<
    GetStudentAcdData,
    AcdStudentsvars
  >(GetAcdStudents);

  useEffect(() => {
    if (token && entry_level) {
      let acd_std_query_type;

      switch (queryType) {
        case StudentAcdType.CUR_STD_HAS_DOB_IN_WEEK:
          acd_std_query_type = StudentAcdType.CUR_STD_HAS_DOB_IN_WEEK;
          break;
        case StudentAcdType.CUR_STD_HAS_DOB_TODAY_AT_ENTRY_LEVEL:
          acd_std_query_type =
            StudentAcdType.CUR_STD_HAS_DOB_TODAY_AT_ENTRY_LEVEL;
          break;
        case StudentAcdType.CUR_STD_HAS_DOB_IN_WEEK_AT_ENTRY_LEVEL:
          acd_std_query_type =
            StudentAcdType.CUR_STD_HAS_DOB_IN_WEEK_AT_ENTRY_LEVEL;
          break;
        case StudentAcdType.CUR_STD_HAS_DOB_TODAY:
        default:
          acd_std_query_type = StudentAcdType.CUR_STD_HAS_DOB_TODAY;
          break;
      }

      GetStudentsData({
        variables: {
          after:null,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          first: ROWS_PER_PAGE,
          name: searchData,
          token,
          orderBy: [
            {
              direction: Direction.ASC,
              field: SortBy.STD_DOB,
            },
          ],
          input: {
            ids: [Number(InstId)],
            acd_std_query_type,
            str_data: [entry_level],
          },
        },
      });
    }
  }, [
    token,
    GetStudentsData,
    InstId,
    entryId,
    state.ActiveAcdYr,
    searchData,
    entry_level,
    queryType, // Add queryType to dependency array
  ]);

  return {
    data,
  };
};

export default useStudentDataToGetBirthday;

import React from "react";
import useChannelDetails from "../../../../../customhooks/useChannelDetails";
import { ChannelQueryType } from "../../../../Accounts/common/Enum.types";
import { Title } from "../../../../../stories/Title/Title";
import View from "../../../../../images/EyeWhite.svg";

interface Props {
  setSubjectId: React.Dispatch<React.SetStateAction<number>>;
}
const AssignmentsList = ({ setSubjectId }: Props) => {
  const { channelDetails } = useChannelDetails([ChannelQueryType.ASSIGNMENTS]);
  return (
    <div className="stu-assignments__list">
      <Title variant="subtitle1">Subjects List</Title>
      <ul className="stu-assignments__list--ul">
        {channelDetails.data?.GetChannelSubscribed.map((data, index) => {
          return (
            <li className="assignments__list--data--card">
              <b className="assignments__list--data--b">{data.channel_name}</b>
              <span className="assignments__list--data--code">
                {/* {data.subcode} */}
              </span>

              <button
                className="assignments__list--data--view"
                onClick={() => {
                  setSubjectId(data.id);
                }}>
                View Assignment <img src={View} alt="" />
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AssignmentsList;

import React, { useContext, useEffect, useRef, useState } from "react";
import FileAttach from "../../../images/BrowseFiles.svg";
import { Label } from "../../../stories/Label/Label";
import { Button } from "../../../stories/Button/Button";
import {
  Direction,
  InstitutionConfigurationTypes,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddChannelmessage,
  UpdateChannelMessage,
} from "../../../queries/chatapplication/mutations";
import { msgType } from "../../../utils/Form.types";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useToken from "../../../customhooks/useToken";
import {
  GetChannelMessages,
  GetMessageById,
  GetMessageChannel,
} from "../../../queries/chatapplication/list";
import { handleUploadAndDownloadFile } from "../../../utils/Upload";
import {
  EMPTY_STRING,
  formatOptions,
  formattingToolbarOptions,
} from "../../../utils/constants";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import { ChannelQueryTypeByInstId } from "../../Accounts/common/Enum.types";
import {
  ChannelDataByIdVars,
  ChannelListData,
  ChannelListVars,
  MessageDataByIdData,
} from "../Types";
import MessageModal from "../../../pages/MessageModal";
import Input from "../../../stories/Input/Input";
import {
  AddChannelmessageData,
  AddChannelmessageVars,
} from "../../Accounts/common/types";
import LoadingModal from "../../../pages/LoadingModal";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  messageId: number;
  setSent?: React.Dispatch<React.SetStateAction<boolean>>;
  sent?: boolean;
}
const CreateFeed = ({
  setModalFlag,
  operation,
  messageId,
  setSent,
  sent,
}: Props) => {
  const { token } = useToken();
  const imageRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [channelMessage, setChannelMessage] = useState("");
  const { user_details } = useLoggedInUserDetails();
  const { InstId } = useParams();
  const [msgHeader, setMsgHeader] = useState("");
  const titleRef = useRef<HTMLInputElement>(null);
  const descRef = useRef<ReactQuill>(null);
  const { state } = useContext(AppContext);
  const [progress, setProgress] = useState(0);
  const { InstFormData } = useInstDetails(1);
  const [GetChannelListData, { data: ChannelList }] = useLazyQuery<
    ChannelListData,
    ChannelListVars
  >(GetMessageChannel, {
    variables: {
      token,
      inst_id: InstId!,
      input: {
        query_type: ChannelQueryTypeByInstId.MYCAMPUSFEED,
        channel_id: 0,
      },
    },
  });
  const channelId = ChannelList?.GetChannelsByInstId[0].id;
  const [GetChannelMessage] = useLazyQuery<
    MessageDataByIdData,
    ChannelDataByIdVars
  >(GetMessageById);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { configData: sizeLimits } = useSwConfigData([
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE,
  ]);
  // const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length) {
  //     if (
  //       (e.target.files[0].size > maxFileSizeInBits.mediaSize &&
  //         type === InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE) ||
  //       (e.target.files[0].size > maxFileSizeInBits.fileSizeInBits &&
  //         type === InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE)
  //     ) {
  //       alert("huge file");
  //       setFile(null);
  //       return;
  //     }
  //     let image_as_files = e.target.files[0];
  //     setFile(image_as_files);
  //   }
  // };
  const removeFile = (indexToRemove: number) => {
    const fileteredFiles = file.filter((_, index) => index !== indexToRemove);
    //@ts-ignore
    setFile(fileteredFiles);
  };

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      //@ts-ignore
      setFile(files);
    }
  };

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let imageSize = 0;
    let videoSize = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE:
            imageSize = item.config_integer_value;
            break;
          case InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE:
            videoSize = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      imageSize,
      videoSize,
    };
  };
  const { imageSize, videoSize } = filterDataByConfigKey(
    sizeLimits.data?.GetSwConfigVariables!
  );
  const [AddChannelMsg, { loading: AddLoading }] = useMutation<
    AddChannelmessageData,
    AddChannelmessageVars
  >(AddChannelmessage, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateChannelMsg, { loading: UpdateLoading }] = useMutation(
    UpdateChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  let filename =
    file.length > 1
      ? file
          .map(
            (f) => `${InstFormData.inst_name}/channels/${channelId}/${f.name}`
          )
          .join(",")
      : `${InstFormData.inst_name}/channels/${channelId}/${
          file.length ? file[0].name : EMPTY_STRING
        }`;
  const handleSubmit = () => {
    if (msgHeader.length === 0) {
      return titleRef?.current?.focus();
    }
    if (channelMessage.length === 0) {
      return descRef?.current?.focus();
    }
    let flag = true;

    // Handle multiple files
    for (const f of file) {
      if (f.type.includes("image/") && f.size > imageSize! * 1024 * 1024) {
        alert("Image file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (f.type.includes("video/") && f.size > videoSize! * 1024 * 1024) {
        alert("Video file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (f.type === "application/pdf") {
        alert("PDF file is not allowed");
        flag = false;
        break;
      }

      if (
        f.type === "application/vnd.ms-excel" ||
        f.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        alert("Excel file is not allowed ");
        flag = false;
        break;
      }
    }

    if (flag && operation === Operation.CREATE) {
      const sendMsgPromise = AddChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: file ? filename : "",
            msg_channel_id: channelId?.toString()!,
            inst_id: InstId!,
            msg_header: msgHeader,
          },
          user_details,
        },

        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              token,
              inst_id: InstId ? InstId : state.InstId.toString(),
              input: {
                query_type: MsgChannelType.MY_CAMPUS_FEEDS,
                channel_id: channelId,
              },
              direction: Direction.ASC,
              last: 10,
              after: null,
              sortBy: SortBy.CREATED_AT,
              mediaContent: EMPTY_STRING,
            },
          },
        ],
      });
      sendMsgPromise.then(({ data }) => {
        if (data) {
          const uploadFilePromise = file
            ? Promise.all(
                file.map((f, index) =>
                  handleUploadAndDownloadFile(
                    f,
                    file.length > 1
                      ? `${InstFormData.inst_name}/channels/${channelId}/${
                          data.AddChannelMessage.id
                        }_${index}.${
                          f.name.split(".")[f.name.split(".").length - 1]
                        }`
                      : `${InstFormData.inst_name}/channels/${channelId}/${
                          data.AddChannelMessage.id
                        }.${f.name.split(".")[f.name.split(".").length - 1]}`,
                    setProgress,
                    false
                  )
                )
              )
            : false;

          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              if (sendMsgData) {
                setSent?.(!sent);
                setChannelMessage(EMPTY_STRING);
                setFile([]);
                setMessage({
                  flag: true,
                  message: "Feed Created Successfully",
                  operation: Operation.CREATE,
                });
                setIsDisabled(true);
              }
            })

            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });
    } else if (flag && operation === Operation.UPDATE) {
      const sendMsgPromise = UpdateChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: file ? filename : "",
            msg_header: msgHeader,
          },
          user_details,
          msg_id: messageId,
        },

        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              token,
              inst_id: InstId ? InstId : state.InstId.toString(),
              input: {
                query_type: MsgChannelType.MY_CAMPUS_FEEDS,
                channel_id: channelId,
              },
              direction: Direction.ASC,
              last: 10,
              after: null,
              sortBy: SortBy.CREATED_AT,
              mediaContent: EMPTY_STRING,
            },
          },
        ],
      });
      sendMsgPromise.then(({ data }) => {
        if (data) {
          const uploadFilePromise = file
            ? Promise.all(
                file.map((f, index) =>
                  handleUploadAndDownloadFile(
                    f,
                    `${InstFormData.inst_name}/channels/${channelId}/${
                      data.UpdateChannelMessage.id
                    }_${index}.${
                      f.name.split(".")[f.name.split(".").length - 1]
                    }`,
                    setProgress,
                    false
                  )
                )
              )
            : false;

          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              if (sendMsgData) {
                setSent?.(!sent);
                setChannelMessage(EMPTY_STRING);
                setFile([]);
                setMessage({
                  flag: true,
                  message: "Feed Updated Successfully",
                  operation: Operation.CREATE,
                });
                setIsDisabled(true);
              }
            })

            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });
    }
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setModalFlag(false);
      setIsDisabled(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token && InstId) {
      GetChannelListData();
    }
  }, [token, InstId, GetChannelListData]);
  useEffect(() => {
    if (messageId && operation === Operation.UPDATE) {
      GetChannelMessage({
        variables: {
          token,
          id: messageId,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setMsgHeader(data.node.msg_header);
          setChannelMessage(data.node.msg_content);
        }
      });
    }
  }, [messageId, operation, GetChannelMessage, token]);

  return (
    <>
      <div className="assignments__creation">
        <div className="assignments__creation--title">
          <Title>
            {operation === Operation.UPDATE ? "Update Feed" : "Create Feed"}
          </Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="assignments__creation--data">
          <div className="assignments__creation--selected"></div>
          <div className="label-grid">
            <Label>Feed Title</Label>
            <Input
              value={msgHeader}
              inputRef={titleRef!}
              onChange={(e) => setMsgHeader(e.target.value)}
            />
            <Label>Description</Label>
            <ReactQuill
              value={channelMessage}
              ref={descRef!}
              onChange={setChannelMessage}
              modules={formattingToolbarOptions}
              formats={formatOptions}
            />
          </div>
          <div className="assignments__creation--logo-grid">
            <Label>Attachments</Label>
            {file && (
              <ul className="assignments__creation--attachments">
                {file.map((f, index) => (
                  <li key={index}>
                    {f.name}
                    <img
                      src={Close}
                      alt="Close"
                      onClick={() => removeFile(index)}
                      className="close-icon"
                    />
                  </li>
                ))}
              </ul>
            )}
            <label className="assignments__creation--browse">
              <img src={FileAttach} alt="/" className="browse-icon" />
              Browse
              <input
                id="assignments__creation--browse--inputTag"
                type="file"
                className="assignments__creation--browse--input"
                ref={imageRef!}
                multiple
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleImagePreview(e)
                }
                accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/*"
              />
            </label>
          </div>
        </div>
        <Button mode="save" onClick={handleSubmit} disabled={isDisabled} />
        <Button mode="clear" />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={AddLoading || UpdateLoading} />
    </>
  );
};

export default CreateFeed;

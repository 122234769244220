import { gql } from "@apollo/client";

export const GetPayRollGeneralHolidays = gql`
  query GetPayRollGeneralHolidays(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $input: PrGenHolidayQuery!
    $holidayDesc: String
  ) {
    GetPayRollGeneralHolidays(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      input: $input
      where: { or: [{ holidayDescContainsFold: $holidayDesc }] }
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          holiday_date
          holiday_desc
          holiday_duration
          holiday_type
          pr_acd_yr_id
        }
      }
    }
  }
`;
export const GetPayRollGeneralHolidayTypeCount = gql`
  query GetPayRollGeneralHolidayTypeCount(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $start_date: Time!
    $end_date: Time!
  ) {
    GetPayRollGeneralHolidayTypeCount(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      start_date: $start_date
      end_date: $end_date
    ) {
      general_holiday_count
      unexpected_holiday_count
      weekend_holiday_count
    }
  }
`;
export const GetPayRollGeneralHolidaysInBetween = gql`
  query GetPayRollGeneralHolidaysInBetween(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $start_date: Time!
    $end_date: Time!
  ) {
    GetPayRollGeneralHolidaysInBetween(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      start_date: $start_date
      end_date: $end_date
    ) {
      id
      holiday_date
      holiday_desc
      holiday_duration
      holiday_type
      inst_id
      pr_acd_yr_id
    }
  }
`;
export const GetPayRollCurrentMonthCalendar = gql`
  query GetPayRollCurrentMonthCalendar(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $cal_month: Time!
  ) {
    GetPayRollCurrentMonthCalendar(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      cal_month: $cal_month
    ) {
      id
      cal_month
      month_start_date
      month_end_date
      no_of_days
      no_of_weekend_days
      no_of_general
      no_of_unexpected
      total_no_of_holidays
      total_no_of_workdays
    }
  }
`;

export const GetPayRollHolidayConfig = gql`
  query GetPayRollHolidayConfig(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
  ) {
    GetPayRollHolidayConfig(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
    ) {
      id
      week_day
      day_idx
      week_idx
      week_day_status
      inst_id
      pr_acd_yr_id
    }
  }
`;

export const GetPayRollGeneralHolidayConfiguredWeekends = gql`
  query GetPayRollGeneralHolidayConfiguredWeekends(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $day_idx: Int!
    $week_idx: Int!
  ) {
    GetPayRollGeneralHolidayConfiguredWeekends(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      day_idx: $day_idx
      week_idx: $week_idx
    ) {
      id
      holiday_date
      holiday_desc
      holiday_duration
      holiday_type
      day_idx
      week_idx
      inst_id
      pr_acd_yr_id
    }
  }
`;

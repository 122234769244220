import React, { useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import InstDetails from "../InstDetails";
import { Tab, Tabs } from "@mui/material";
import StdDetails from "./StdDetails";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import Home from "../../Home/Index";
import RightArrow from "../../../../images/ArrowRight.svg";
import { useTheme } from "@mui/material/styles";
import Attendance from "./Attendance/Index";
import TestWise from "./TestWise/Index";
import SubjectDetails from "./SubjectDetails/Index";
import {
  AcdSubjectComboDetails,
  AcdSubjectComboEdge,
} from "../../../../Types/Combinations/queries";
import useCombinations from "../../CustomAllocation/customHooks/useCombinations";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import Close from "../../../../images/Close.svg";
import View from "../../CustomAllocation/Combinations/View";
interface props{
  pageType:PageFor;
  setModalFlag:React.Dispatch<React.SetStateAction<boolean>>
}

const Individual = ({pageType ,setModalFlag}:props) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [endCursor, setEndCursor] = useState("");
  const [combinations, setCombinations] = useState<AcdSubjectComboEdge[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [combo_details, setComboDetails] = useState<AcdSubjectComboDetails[]>(
    []
  );
  const [searchCombo, setSearchCombo] = useState("");

  const { data, error, loading, fetchMore } = useCombinations(
    ROWS_PER_PAGE,
    searchCombo,
    AcdSubjComboQueryType.COMBO_STDS_ALLOCATED
  );
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdSubjectCombos.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newCategory) => {
          const filteredStudent = combinations.find(
            (category) => category.node.id === newCategory.node.id
          );
          if (filteredStudent) {
            return {
              ...newCategory,
              node: {
                ...newCategory.node,
              },
            };
          }
          return newCategory;
        });
        setCombinations(updatedNewData);
      } else {
        setCombinations(newData);
      }
      setHasNextPage(data.GetAcdSubjectCombos.pageInfo.hasNextPage);
      setEndCursor(data.GetAcdSubjectCombos.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
    {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
    <div className="combinations-individual__title">
    <Title>Student Information</Title>
    <img src={Close} alt="" onClick={()=>setModalFlag(false)}/>
    </div>
      
      

      <div className={pageType === PageFor.GENERAL ? "combinations-individual" :"combinations-individual__modal"}>
        
        {pageType ===PageFor.GENERAL && <>
          <InstDetails />
          <div className="row g-0">
          <div className="col-2 combinations-individual__back">
            <img src={RightArrow} alt="" /> Go Back to Student List
          </div>
        </div></> }
       

        <StdDetails />
        <div className="combinations-individual__tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Attendance Report" value={0} {...a11yProps(0)} />
            <Tab label="Test / Exam Report" value={1} {...a11yProps(1)} />
            {/* <Tab label="Subject Details" value={2} {...a11yProps(2)} /> */}
          </Tabs>
        </div>
        <div className={pageType === PageFor.GENERAL ?"combinations-individual__tabpanel" : "combinations-individual__tabpanel--modal"}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Attendance />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <TestWise />
          </TabPanel>
          {/* <TabPanel value={value} index={2} dir={theme.direction}>
            <SubjectDetails data={combo_details} />
          </TabPanel> */}
        </div>
        {pageType ===PageFor.MODAL && <Button mode="cancel" onClick={()=>setModalFlag(false)}/> }
        
      </div>
    </>
  );
};

export default Individual;

import React from "react";
import { useParams } from "react-router-dom";
import SelectMonth from "../../../../images/SelectMonth.svg";
import ProvisionalSalaryDetails from "../../../../images/ProvisionalSalaryDetails.svg";
import ConfirmSalary from "../../../../images/ConfirmSalary.svg";
import EmployeeAttendance from "../../../../images/EmployeeAttendance.svg";
import SelectEmpForSalary from "../../../../images/SelectEmpForSalary.svg";
import CloseSalaryProcessImg from "../../../../images/CloseSalaryProcess.svg";
import { NavLink } from "react-router-dom";
import { PayRollSalaryMkWork } from "./SelectMonth";

interface ArrayDataType {
  label: string;
  link: string;
  id: number;
  image: string;
  flag: boolean;
}
interface Props {
  monthDetails: PayRollSalaryMkWork | null;
}
const SalaryProcess = ({ monthDetails }: Props) => {
  const { InstId } = useParams();

  const salaryProcess: ArrayDataType[] = [
    {
      id: 1,
      label: "Select Month",
      link: `/${InstId}/hr/payroll/salaryprocess/selectmonth`,
      image: `${SelectMonth}`,
      flag: monthDetails?.sal_wrk_job_started!,
    },
    {
      id: 2,
      label: "Select Employee for Salary",
      link: `/${InstId}/hr/payroll/salaryprocess/${monthDetails?.id}/selectemployee`,
      image: `${SelectEmpForSalary}`,
      flag: monthDetails?.sal_wrk_job_started!,
    },
    {
      id: 3,
      label: "Employee Attendance",
      link: `/${InstId}/hr/payroll/salaryprocess/${monthDetails?.id}/employeeattendance`,
      image: `${EmployeeAttendance}`,
      flag: monthDetails?.sal_wrk_is_emp_added!,
    },
    {
      id: 4,
      label: "Confirm Employee Salary",
      link: `/${InstId}/hr/payroll/salaryprocess/${monthDetails?.id}/confirmemployeesalary`,
      image: `${ConfirmSalary}`,
      flag: monthDetails?.sal_wrk_is_att_added!,
    },
    {
      id: 5,
      label: "Provisional Salary Details",
      link: `/${InstId}/hr/payroll/salaryprocess/${monthDetails?.id}/provisionalsalarydetails/detailed`,
      image: `${ProvisionalSalaryDetails}`,
      flag: monthDetails?.sal_wrk_is_sal_str_added!,
    },
    {
      id: 6,
      label: "Close Salary Process",
      link: `/${InstId}/hr/payroll/salaryprocess/${monthDetails?.id}/closesalaryprocess`,
      image: `${CloseSalaryProcessImg}`,
      flag: monthDetails?.sal_wrk_is_sal_made!,
    },
  ];

  return (
    <>
      <ul>
        {salaryProcess.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <NavLink
                to={data.link}
                className={data.flag ? "" : "events-none"}
              >
                <li>
                  <img src={data.image} alt="/" />
                  {data.label}
                </li>
              </NavLink>
            </React.Fragment>
          );
        })}
      </ul>
    </>
  );
};

export default SalaryProcess;

import { useLazyQuery } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { GetSwLeaveLdgrs } from "../queries/leaveledger/list";
import {
  GetSwLeaveLdgrsData,
  GetSwLeaveLdgrsVars,
} from "../../Eduate/leaveledger/EduateLeaveLedgerList";
import { useEffect } from "react";

const usePredefindedLeaveLedgers = () => {
  const { token } = useToken();
  const [GetSwLeaveLedgers, { data, loading, error }] = useLazyQuery<
    GetSwLeaveLdgrsData,
    GetSwLeaveLdgrsVars
  >(GetSwLeaveLdgrs, {
    variables: {
      token,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (token) {
      GetSwLeaveLedgers();
    }
  }, [token, GetSwLeaveLedgers]);
  return {
    predefinedSalaryledger: {
      data,
      loading,
      error,
    },
  };
};

export default usePredefindedLeaveLedgers;

// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Modules/Eduate/Home/Index";
import { LicenseTypes } from "../utils/Enum.types";
const EduateConfigurations = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            DashBoardRequired={false}
            NavType={LicenseTypes.EDUATE_CONFIGURATION}
          />
        }
      />
    </Routes>
  );
};

export default EduateConfigurations;

import  { useEffect } from "react";
import { GetPaymentDetails } from "../Modules/Accounts/queries/Payments";
import { useLazyQuery } from "@apollo/client";
import {  singleNodeVars } from "../Types/Accounting";
import useToken from "./useToken";
import { useParams } from "react-router-dom";
interface GetPaymentDetailsData {
  node: {
    response_code:string,
        voucher_master_id:string,
        response_code_str:string,
        transaction_amount:string,
        reference_no:string,
        transaction_date:string,
  };
}
const useTransactionDetailsById = () => {
  const { token } = useToken();
  const { transactionId } = useParams();
  const [GetTransactionDetails, { data, loading, error }] = useLazyQuery<
    GetPaymentDetailsData,
    singleNodeVars
  >(GetPaymentDetails, {
    variables: {
      id: Number(transactionId),
      token,
    },
  });
   console.log(token, transactionId, "Transaction");
   
  useEffect(() => {
    if (token) {
      GetTransactionDetails();
    }
  }, [token, transactionId, GetTransactionDetails]);
  return { data, loading, error };
};

export default useTransactionDetailsById;

import { useLazyQuery } from "@apollo/client";
import { PageInfo } from "jspdf";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { Direction } from "../../../../utils/Enum.types";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import { GetAcctPurchases } from "../../queries/receipts/list";
import { AppContext } from "../../../../context/context";
export enum purchaseEntryQueryType {
  ALL_PURCHASES = "ALL_PURCHASES",
}
interface GetAcctPurchasesNode {
  id: number;
  pur_inv_no: string;
  pur_inv_date: string;
  pur_total_taxable: number;
  pur_tax_amt: number;
  pur_sec_discount: number;
  pur_freight: number;
  pur_others: number;
  pur_round_off: number;
  pur_amt: number;
  pur_item_count: number;
  pur_item_qty: number;
  pur_for_department: string;
  pur_remarks: string;
  pur_reconciled: boolean;
  pur_payment_done: boolean;
  pur_paid_amt: number;
  pur_balance_amt: number;
  pur_full_payment_done: boolean;
  pur_type: string;
  pur_order_no: string;
  pur_order_date: string;
}
interface GetAcctPurchasesEdges {
  node: GetAcctPurchasesNode;
}
interface GetAcctPurchasesDetails {
  edges: GetAcctPurchasesEdges[];
  totalCount: number;
  pageInfo: PageInfo;
}
interface GetAcctPurchasesData {
  GetAcctPurchases: GetAcctPurchasesDetails;
}

interface input {
  purchase_query_type: purchaseEntryQueryType;
  inst_id: string;
  acct_ldgr_id: number;
  pur_start_date: string;
  pur_end_date: string;
}
interface Vars {
  token: string;
  fin_yr_id: number;
  first: number;
  after: string | null;
  //   itemName: string;
  orderBy: { direction: Direction; field?: string };
  input: input;
}
const usePurchaseEntry = (
  rowsPerPage: number,
  end_date: string,
  start_date: string,
  purchase_query_type: purchaseEntryQueryType
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [GetItems, { data, loading, error }] = useLazyQuery<
    GetAcctPurchasesData,
    Vars
  >(GetAcctPurchases, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      state.ActiveFinYr &&
      start_date &&
      end_date &&
      token &&
      purchase_query_type
    ) {
      GetItems({
        variables: {
          after: null,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          first: rowsPerPage,
          //   itemName: "",
          orderBy: { direction: Direction.ASC },
          token,
          input: {
            acct_ldgr_id: 0,
            inst_id: InstId!,
            pur_end_date: toIsoDate(end_date),
            pur_start_date: toIsoDate(start_date),
            purchase_query_type,
          },
        },
      });
    }
  }, [
    GetItems,
    state.ActiveFinYr,
    token,
    start_date,
    end_date,
    purchase_query_type,
    InstId,
    rowsPerPage,
  ]);
  return {
    PurchaseEntries: { data, loading, error },
  };
};

export default usePurchaseEntry;

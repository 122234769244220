import { gql } from "@apollo/client";

export const GetAuthDetailsByToken = gql`
  query GetAuthDetailsByToken($token: String!, $device_token: String!) {
    GetAuthDetailsByToken(token: $token, device_token: $device_token) {
      id
      auth_user_id
      auth_group_type
      firebase_id
      inst_id
    }
  }
`;
export const LogoutUser = gql`
  mutation LogoutUser(
    $token: String!
    $device_token: String!
    $input: SubscribedUser!
  ) {
    LogoutUser(token: $token, device_token: $device_token, input: $input)
  }
`;
export const ValidateAuthUser = gql`
  query Query($token: String!, $inst_id: ID!, $input: SubscribedUser!) {
    ValidateAuthUser(token: $token, inst_id: $inst_id, input: $input)
  }
`;

import useInstLabels from "../../../customhooks/general/useInstLabels";

const useEnquiryFormJson = () => {
  const { departmentLabel, branchLabel, classLabel, semesterLabel } =
    useInstLabels();
  const formJson = {
    EnquiryFormLabels: {
      FollowUp: {
        Details: [
          {
            LabelName: "Email ID",
            inputName: "std_email",
            Parent: "",
            class: "",
          },
          {
            LabelName: "Mobile No.",
            inputName: "std_mobile",
            Parent: "",
            class: "",
          },
          { LabelName: "Gender", inputName: "std_sex", Parent: "", class: "" },
          {
            LabelName: "Student Name",
            inputName: "enq_student_name",
            Parent: "",
            class: "",
          },
          {
            LabelName: "Father Name",
            inputName: "std_father_name",
            Parent: "",
            class: "",
          },
          {
            LabelName: "Previous Studied Institutions",
            inputName: "std_previous_school",
            Parent: "",
            class: "",
          },
          {
            LabelName: "Enquired Date",
            inputName: "enq_date",
            Parent: "",
            class: "",
          },
          {
            LabelName: `Seeking ${departmentLabel}`,
            inputName: "seekingDept",
            Parent: "",
            class: "",
          },
          {
            LabelName: `Seeking ${branchLabel}`,
            inputName: "seekingBranch",
            Parent: "",
            class: "",
          },
          {
            LabelName: `Seeking ${classLabel}`,
            inputName: "seekingClass",
            Parent: "",
            class: "",
          },
          {
            LabelName: `Seeking ${semesterLabel}`,
            inputName: "seekingSemester",
            Parent: "",
            class: "",
          },
        ],
        AdditionalDetails: [
          {
            LabelName: "Follow Up Person",
            inputName: "follow_up_person",
            Parent: "",
            class: "",
          },
        ],
      },
    },
  };
  return formJson;
};

export default useEnquiryFormJson;

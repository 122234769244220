import { gql } from "@apollo/client";

export const GetAcdBranchesByAcdDeptIds = gql`
  query GetAcdBranchesByAcdDeptIds($token: String!, $acd_dept_ids: [ID!]!) {
    GetAcdBranchesByAcdDeptIds(token: $token, acd_dept_ids: $acd_dept_ids) {
      branch_desc
      branch_idx
      id
      acd_dept_id
      branch_id
    }
  }
`;

export const GetAcdBranchesByInstId = gql`
  query GetAcdBranchesByInstId($token: String!, $inst_id: ID!) {
    GetAcdBranchesByInstId(token: $token, inst_id: $inst_id) {
      branch_desc
      branch_idx
      id
      acd_dept_id
      branch_id
    }
  }
`;

import React from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import Total from "../../../../../images/TotalStudentsinAdmin.svg";
import FormFilled from "../../../../../images/MarksEntryCompleted.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import InstDetails from "../../../DailyActivities/InstDetails";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import useAcdLevel from "../../../../Accounts/hooks/useAcdLevel";
import { useNavigate, useParams } from "react-router-dom";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { Button } from "../../../../../stories/Button/Button";
import useAcademicTableJson from "../../../json/useAcademicTable";
import Eye from "../../../../../images/EyeWhite.svg";
import Avatar from "../../../../../images/Avatar.svg";
import List from "../List";
import { ReportType } from "./FormsReport";
interface props {
  pageType: ReportType;
}
const ViewDetailedReports = ({ pageType }: props) => {
  const { entryId } = useParams();
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { FeedbackSubwise } = useAcademicTableJson();
  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    entry_level,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const { acdLevel } = useAcdLevel(entryId!);
  //   const {
  //     InstConfigDetails: { data: configdata },
  //   } = useInstConfigByEntryId(entryId!, acdLevel);
  return (<>
    <Home DashBoardRequired={false} />
    <Title>Forms Report</Title>
    <div className="fb-form-report">
      <div className="fb-form-report__left">
        <List />
      </div>
      <div className="fb-form-report__right">
        <div className="fb-form-view">
          <div className="row g-0 fb-form-view__detailed--select">
            {USE_DEPARTMENT_KEY && (
              <div className="col-2">
                <TextField
                  label={departmentLabel}
                  // value={
                  //   configdata
                  //     ? configdata.GetAcdInstConfigNames.acd_dept_desc
                  //     : EMPTY_STRING
                  // }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                  className="daily-activities__textfield"
                />
              </div>
            )}
            {USE_BRANCH_KEY && (
              <div className="col-2">
                <TextField
                  label={branchLabel}
                  // value={
                  //   configdata
                  //     ? configdata.GetAcdInstConfigNames.acd_branch_desc
                  //     : EMPTY_STRING
                  // }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                  className="daily-activities__textfield"
                />
              </div>
            )}
            {
              USE_CLASS_KEY && (
                //   configdata &&
                //   configdata.GetAcdInstConfigNames.acd_class_desc ?
                (<div className="col-2">
                  <TextField
                    label={classLabel}
                    // value={
                    //   configdata
                    //     ? configdata.GetAcdInstConfigNames.acd_class_desc
                    //     : EMPTY_STRING
                    // }
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    className="daily-activities__textfield"
                  />
                </div>)
              )
              //   : null
            }
            {
              USE_SEMESTER_KEY && (
                //   configdata &&
                //   configdata.GetAcdInstConfigNames.acd_semester_desc ?
                (<div className="col-2">
                  <TextField
                    label={semesterLabel}
                    // value={
                    //   configdata
                    //     ? configdata.GetAcdInstConfigNames.acd_semester_desc
                    //     : EMPTY_STRING
                    // }
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    className="daily-activities__textfield"
                  />
                </div>)
              )
              //   : null
            }
            {
              USE_SECTION_KEY && (
                //   configdata &&
                //   configdata.GetAcdInstConfigNames.acd_section_desc ?
                (<div className="col-2">
                  <TextField
                    label={sectionLabel}
                    // value={
                    //   configdata
                    //     ? configdata.GetAcdInstConfigNames.acd_section_desc
                    //     : EMPTY_STRING
                    // }
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    className="daily-activities__textfield"
                  />
                </div>)
              )
              //   : null
            }
          </div>
          <div className="fb-form-view__title row g-0">
            <div className="col-4 fb-form-view__title--left">
              {/* use for nonacademic "fb-form-view__title--non-acd" */}
              <b>Feedback Form </b>{" "}
              <span className="fb-form-view__title--acd">Academic</span>
            </div>
            {pageType === ReportType.Formwise ? (
              <div className="col"></div>
            ) : (
              <div className="col fb-form-view__questionwise--emp">
                <div className="fb-form-view__questionwise--emp--flex">
                  <img src={Avatar} alt="" />
                  <div className="fb-form-view__questionwise--emp--block">
                    <b>karan</b>
                    <span>Emp001</span>
                  </div>
                </div>
              </div>
            )}{" "}
            <div className="col-4">
              <div className="fb-form-view__title--flex">
                <TextField
                  className="fb-form-report__datablock--grid--textfield"
                  label="Start Date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
                <img src={Compare} alt="" />
                <TextField
                  className="fb-form-report__datablock--grid--textfield"
                  label="End Date"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              </div>
            </div>
          </div>
          <div className="fb-form-view__subtitle">
            <b>Subjectwise Detailed List</b>
          </div>
          <div className="fb-form-view__cards">
            <div className="fb-form-view__card">
              <img src={Total} alt="" />
              <span>Total Students</span>
              <b className="font-blue">100</b>
            </div>
            <div className="fb-form-view__card">
              <img src={FormFilled} alt="" />
              <span>Students Form Filled</span>
              <b className="font-green">20</b>
            </div>
          </div>
          <div className="fb-form-view__detailed--tableblock">
            <TableContainer className="fb-form-view__detailed--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {FeedbackSubwise.TableHeader.map((th, index) => {
                      return (
                        <TableCell key={index}>{th.labelName}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="fb-form-view__detailed--table--slno">
                      1
                    </TableCell>
                    <TableCell className="fb-form-view__detailed--table--code">
                      Eng001
                    </TableCell>
                    <TableCell>English</TableCell>
                    <TableCell className="fb-form-view__detailed--table--emp">
                      <div className="fb-form-view__detailed--table--emp--flex">
                        <img src={Avatar} alt="" />
                        <div className="fb-form-view__detailed--table--emp--block">
                          <b>karan</b>
                          <span>Emp001</span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="fb-form-view__detailed--table--number"
                      id="td-center"
                    >
                      <b className="font-blue">40</b>
                    </TableCell>
                    <TableCell
                      className="fb-form-view__detailed--table--number"
                      id="td-center"
                    >
                      <b className="font-green">40</b>
                    </TableCell>
                    <TableCell
                      className="fb-form-view__detailed--table--button"
                      id="td-center"
                    >
                      <button
                        className="fb-form-view__detailed--table--view"
                        onClick={() =>
                          navigate(
                            `/${InstId}/academics/reports/feedback/questionwise`
                          )
                        }
                      >
                        View Details <img src={Eye} alt="" />
                      </button>
                    </TableCell>
                  </TableRow>
                  <TableRow className="fb-form-view__detailed--table--elective">
                    <TableCell colSpan={7}>Open Elective</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="fb-form-view__detailed--table--slno">
                      1
                    </TableCell>
                    <TableCell className="fb-form-view__detailed--table--code">
                      Eng001
                    </TableCell>
                    <TableCell>English</TableCell>
                    <TableCell className="fb-form-view__detailed--table--emp">
                      <div className="fb-form-view__detailed--table--emp--flex">
                        <img src={Avatar} alt="" />
                        <div className="fb-form-view__detailed--table--emp--block">
                          <b>karan</b>
                          <span>Emp001</span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="fb-form-view__detailed--table--number"
                      id="td-center"
                    >
                      <b className="font-blue">40</b>
                    </TableCell>
                    <TableCell
                      className="fb-form-view__detailed--table--number"
                      id="td-center"
                    >
                      <b className="font-green">40</b>
                    </TableCell>
                    <TableCell
                      className="fb-form-view__detailed--table--button"
                      id="td-center"
                    >
                      <button className="fb-form-view__detailed--table--view">
                        View Details <img src={Eye} alt="" />
                      </button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="excel">Export</Button>
          <Button mode="pdf" />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </div>
  </>);
};

export default ViewDetailedReports;

import React, { useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { PageFor, TransportMasters } from "../../../utils/Enum.types";
import Input from "../../../stories/Input/Input";
import VehicleBodyType from "./VehicleBodyType";
import VehicleType from "./VehicleType";
import FuelUsed from "./FuelUsed";
import TyresUsed from "./TyresUsed";
import SeatingCapacity from "./SeatingCapacity";
import Manufacturer from "./Manufacturer";
import ManufacturerModel from "./ManufacturerModel";
import NumberPlate from "./NumberPlate";
import Designation from "./Designation";
import LicenseType from "./LicenseType";
import FuelBrand from "./FuelBrand";
import TyreSize from "./TyreSize";
import ItemGroup from "./ItemGroup";
import ItemCategory from "./ItemCategory";
import UnitOfMeasurement from "./UnitOfMeasurement";

const listItems = [
  {
    id: 1,
    label: "Vehicle Type",
    value: TransportMasters.VEHICLE_TYPE,
  },
  {
    id: 2,
    label: "Fuel Used",
    value: TransportMasters.FUEL_USED,
  },
  {
    id: 3,
    label: "Tyres Used",
    value: TransportMasters.TYRES_USED,
  },
  {
    id: 4,
    label: "Seating Capacity",
    value: TransportMasters.SEATING_CAPACITY,
  },
  {
    id: 5,
    label: "Manufacturer",
    value: TransportMasters.MANUFACTURER,
  },
  {
    id: 6,
    label: "Manufacturer Model",
    value: TransportMasters.MANUFACTURER_MODEL,
  },
  {
    id: 7,
    label: "Number Plate",
    value: TransportMasters.NUMBER_PLATE,
  },
  {
    id: 8,
    label: "Vehicle Body Type",
    value: TransportMasters.VEHICLE_BODY_TYPE,
  },
  {
    id: 9,
    label: "Tyre Size",
    value: TransportMasters.TYRE_SIZE,
  },
  {
    id: 10,
    label: "Designation",
    value: TransportMasters.DESIGNATION,
  },
  {
    id: 11,
    label: "License Type",
    value: TransportMasters.LICENSE_TYPE,
  },
  {
    id: 12,
    label: "Fuel Brand",
    value: TransportMasters.FUEL_BRAND,
  },
  {
    id: 13,
    label: "Item Group",
    value: TransportMasters.ITEM_GROUP,
  },
  {
    id: 14,
    label: "Item Category",
    value: TransportMasters.ITEM_CATEGORY,
  },
  {
    id: 14,
    label: "Unit Of Measurement",
    value: TransportMasters.UNIT_OF_MEASUREMENT,
  },
];

const Index = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(
    TransportMasters.VEHICLE_TYPE
  );

  const handleItemClick = (value: any) => {
    setSelectedItem(value);
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Master Data</Title>
      <div className="transport-masters">
        <div className="transport-masters__datablock">
          <div className="transport-masters__left">
            <Title variant="subtitle1">Select Master Data</Title>
            <Input id="search" placeholder="Search..." />
            <div className="transport-masters__left--ul">
              {listItems.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => handleItemClick(item.value)}
                    className={
                      item.value === selectedItem
                        ? "transport-masters__left--ul--active"
                        : ""
                    }
                  >
                    {item.label}
                  </li>
                );
              })}
            </div>
          </div>
          <div className="transport-masters__right">
            {selectedItem === TransportMasters.VEHICLE_BODY_TYPE ? (
              <VehicleBodyType pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.FUEL_USED ? (
              <FuelUsed pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.TYRES_USED ? (
              <TyresUsed pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.SEATING_CAPACITY ? (
              <SeatingCapacity pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.MANUFACTURER ? (
              <Manufacturer pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.MANUFACTURER_MODEL ? (
              <ManufacturerModel pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.NUMBER_PLATE ? (
              <NumberPlate pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.TYRE_SIZE ? (
              <TyreSize pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.DESIGNATION ? (
              <Designation pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.LICENSE_TYPE ? (
              <LicenseType pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.FUEL_BRAND ? (
              <FuelBrand pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.ITEM_GROUP ? (
              <ItemGroup pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.ITEM_CATEGORY ? (
              <ItemCategory pageType={PageFor.GENERAL} />
            ) : selectedItem === TransportMasters.UNIT_OF_MEASUREMENT ? (
              <UnitOfMeasurement pageType={PageFor.GENERAL} />
            ) : (
              <VehicleType pageType={PageFor.GENERAL} />
            )}
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

import React from "react";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../../styles/DataGridTableStyles";
import useTestDetails from "../../../../hooks/useTestDetails";
import {
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaders } from "../../../../../../utils/Enum.types";
import { toStandardDate } from "../../../../../../utils/UtilFunctions";
import Eye from "../../../../../../images/EyeWhite.svg";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../../../hooks/useTestStatus";
import { useNavigate, useParams } from "react-router-dom";
const Subjectwise = () => {
  

  const { testConductDetails } = useTestDetails();
  const { entryId, InstId, testId, testTypeId } = useParams();

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "sub_code",
      headerName: TableHeaders.SUBJECT_CODE,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-code",
    },
    {
      field: "sub_name",
      headerName: TableHeaders.SUBJECT_DESCRIPTION,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-type",
      flex: 1,
    },
    {
      field: "test_date",
      headerName: TableHeaders.DATE,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-date",
    },
    {
      field: "action",
      headerName: TableHeaders.ACTION,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-actions",
      renderCell: (params) => {
        return (
          <button
            className="combination-report-view__tableblock--view"
            onClick={() =>
              navigate(
                `/${InstId}/academics/reports/completeReports/${entryId}/view/${testId}/${testTypeId}/pertestdetails/${params.row.sub_id}/forsubjects`
              )
            }
          >
            View <img src={Eye} alt="" />
          </button>
        );
      },
    },
  ];

  const rows: GridValidRowModel[] =
    (testConductDetails.data
      ? testConductDetails.data.node.class_subject_details
      : []
    ).map((response, index) => {
      return {
        id: index + 1,
        sub_id: response.subject_master_details.id,
        sub_code: response.subject_master_details.subj_code,
        sub_name: response.subject_master_details.subj_desc,
        test_date: toStandardDate(response.test_date),
      };
    }) || [];
  return (
    <>
      <div className="combination-report-view__sub-wise">
        <div
          className={`combination-report-view__sub-wise--tableblock`}
        >
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            
          />
        </div>
      </div>
    </>
  );
};

export default Subjectwise;

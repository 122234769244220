import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import { Direction, PrMkSalQueryType, SortBy } from "../../../utils/Enum.types";
import {
  GetPayRollSalaryMkEmpMasterData,
  GetPayRollSalaryMkEmpVars,
} from "../Types/paginationTypes";
import { GetPayRollSalaryMkEmpMaster } from "../queries/salary/list";

const useSalaryMkEmpMaster = (
  pr_acd_yr: number,
  queryType: PrMkSalQueryType,
  department: number,
  designation: number,
  jobtype: number,
  grade: number,
  category: number,
  employee: string
) => {
  const { token } = useToken();
  const { monthId, InstId } = useParams();

  const [GetEmployees, { data, error, loading, fetchMore }] = useLazyQuery<
    GetPayRollSalaryMkEmpMasterData,
    GetPayRollSalaryMkEmpVars
  >(GetPayRollSalaryMkEmpMaster, {
    variables: {
      token,
      inst_id: InstId!,
      pr_acd_yr_id: pr_acd_yr,
      input: {
        pr_mk_sal_query: queryType,
        ids: [Number(monthId)],
      },
      first: ROWS_PER_PAGE,
      after: null,
      direction: Direction.ASC,
      sortBy: SortBy.EMP_SAL_MK_MONTH,
      department: department ? department : null,
      designation: designation ? designation : null,
      jobtype: jobtype ? jobtype : null,
      grade: grade ? grade : null,
      category: category ? category : null,
      name: employee,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (pr_acd_yr && monthId && token) {
      GetEmployees();
    }
  }, [pr_acd_yr, monthId, token, GetEmployees]);
  return {
    salaryMkEmployeeData: {
      data,
      error,
      loading,
      fetchMore,
    },
  };
};

export default useSalaryMkEmpMaster;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  muiCheckboxClassName,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import Home from "../Home/Index";
import Avatar from "../../../images/Avatar.svg";
import MarkAttendance from "../../../images/MarkAttendance.svg";
import Modal from "react-modal";

import { Label } from "../../../stories/Label/Label";

import {
  AttendanceStatus,
  Direction,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import { Keys } from "../../../utils/Enum.keys";
import { msgType, responseType } from "../../../utils/Form.types";
import {
  getModifiedScrollHeight,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import MessageModal from "../../../pages/MessageModal";

import { useNavigate, useParams } from "react-router-dom";

import usePayRollActiveAcademicYear from "../hooks/usePayRollActiveAcademicYear";
import usePayRollGeneralHolidays, {
  HolidayQueryType,
} from "../hooks/usePayRollGeneralHolidays";
import { MarkPayRollAttendanceForEmployees } from "../queries/attendance/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { EmpAttendanceTitlesType } from "../Types/jsonTypes";
import Input from "../../../stories/Input/Input";

import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import { AntSwitch } from "../../../pages/Switch";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import LoadingModal from "../../../pages/LoadingModal";
import useEmployeeAttsByMonth, {
  PayRollEmpDetails,
  PayRollEmpEdges,
} from "../hooks/useEmployeeAttsByMonth";
import { PrEmpAttQueryType, SubmitType } from "../enums/Enum.types";
import ViewPerEmployeeAttendance from "./ViewPerEmployeeAttendance";
import MonthlyOverview from "./MonthlyOverview";
import AbsenteesList from "./AbsenteesList";
import {
  GetPayRollEmpAttTodaysStatus,
  GetPayRollEmpAttendance,
} from "../queries/attendance/query";
import {
  GetPayRollEmpAttTodaysStatusData,
  GetPayRollEmpAttTodaysStatusVars,
} from "../Types/masterDataTypes";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { AttendanceDrawer } from "../../../styles/DrawerStyles";
const { HRTitles } = require("../json/title.json");
const { HR_Table } = require("../json/table.json");
export const AddLeaveLedgerModalStyle = {
  content: {
    borderRadius: "15px",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginLeft: "76.5%",
    width: "20%",
    height: "fit-content",
    marginTop: "10px",
    padding: "10px",
  },
  overlay: {
    background: "none",
    backdropFilter: "blur(0px)",
    zIndex: 1000,
  },
};
interface idWithFlagType {
  id: number;
  flag: boolean;
}

const Index = () => {
  const { dispatch } = useContext(AppContext);
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const { user_details } = useLoggedInUserDetails();

  const empStatusRefs = useRef<HTMLButtonElement[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [enableMarkAttendance, setEnableMarkAttendance] = useState(false);
  const [absentiesList, setAbsentiesList] = useState(false);
  const [monthlyOverView, setMonthlyOverView] = useState(false);
  const [perEmpAttendance, setPerEmpAttendance] = useState(false);
  const [selectLeaveLedgerModal, setSelectLeaveLedgerModal] =
    useState<idWithFlagType>({
      id: 0,
      flag: false,
    });

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const classRefList = useRef<HTMLButtonElement[]>([]);
  const [leaveOptions, setLeaveOptions] = useState<responseType[]>([]);
  const [searchEmployee, setSearchEmployee] = useState("");

  const [leaveLedgerSelected, setLeaveLedgerSelected] =
    useState<responseType | null>(null);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);

  const { token } = useToken();

  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();

  const [GetTodaysAttStatus, { data: attStatus }] = useLazyQuery<
    GetPayRollEmpAttTodaysStatusData,
    GetPayRollEmpAttTodaysStatusVars
  >(GetPayRollEmpAttTodaysStatus, {
    variables: {
      token,
      date_of_attendance: toIsoDate(serverDate),
      inst_id: InstId!,
      pr_acd_yr_id:
        payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!,
    },
  });

  const { PayRollGeneralHolidays } = usePayRollGeneralHolidays(
    payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!,
    TODAY_DATE,
    TODAY_DATE,
    EMPTY_STRING,
    HolidayQueryType.HOLIDAY_BY_DATE,
    false
  );
  const { totalCount } =
    PayRollGeneralHolidays.data?.GetPayRollGeneralHolidays! ?? 0;

  const { empDetails } = useEmployeeAttsByMonth(
    ROWS_PER_PAGE,
    PrEmpAttQueryType.ALL_EMPS_ATT,
    serverDate,
    searchEmployee,
    payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!
  );

  const { data, loading, error, fetchMore } = empDetails;

  const [MarkAttendence] = useMutation(MarkPayRollAttendanceForEmployees, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const AddRefs = (e: HTMLButtonElement) => {
    if (e && !empStatusRefs.current.includes(e)) {
      empStatusRefs.current.push(e);
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollEmpAttendance.edges;
            const pageInfo = fetchMoreResult.GetPayRollEmpAttendance.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollEmpAttendance.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollEmpAttendance: {
                edges: [...employees, ...newEdges],
                pageInfo,
                totalCount: data?.GetPayRollEmpAttendance.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setEmployees([]);
      classRefList.current = [];
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleEditItem = (id: number, e: React.MouseEvent) => {
    const index = employees.findIndex((d) => d.node.id === id);

    if (index !== -1) {
      const newData: PayRollEmpDetails = {
        ...employees[index].node,
        isChecked: !employees[index].node.isChecked, // toggle isChecked property
        leave_ledger_id: (e.target as HTMLInputElement).checked
          ? 0
          : employees[index].node.leave_ledger_id,
      };
      const newemployees = [...employees];
      newemployees[index] = { node: newData };
      setEmployees(newemployees);
    }
    if (!(e.target as HTMLInputElement).checked) {
      setLeaveOptions(
        employees[
          index
        ].node.emp_details.leave_master_details?.leave_details.map((leave) => ({
          label: leave.leave_ldgr_details.leave_desc,
          value: leave.leave_ldgr_details.id,
        })) ?? []
      );
      setSelectLeaveLedgerModal({
        id,
        flag: true,
      });
    }
  };

  const handleEditLeaveLedger = (submitType: SubmitType) => {
    const index = employees.findIndex(
      (d) => d.node.id === selectLeaveLedgerModal.id
    );
    if (index !== -1) {
      const newData: PayRollEmpDetails = {
        ...employees[index].node,
        leave_ledger_id: leaveLedgerSelected?.value!,
        isChecked: submitType === SubmitType.CANCEL ? true : false,
      };
      const newemployees = [...employees];
      newemployees[index] = { node: newData };
      setEmployees(newemployees);
      setSelectLeaveLedgerModal({
        id: 0,
        flag: false,
      });
    }
  };
  const handleMarkingAttendence = () => {
    MarkAttendence({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_acd_yr_id:
          payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id,
        date_of_attendance: toIsoDate(serverDate),
        input: employees
          .filter((emp) => !emp.node.isChecked)
          .map((emp) => ({
            pr_emp_id: emp.node.pr_emp_id,
            pr_leave_ldgr_id: emp.node.leave_ledger_id,
          })),
      },
      refetchQueries: [
        {
          query: GetPayRollEmpAttendance,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            pr_acd_yr_id:
              payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id,
            input: {
              inst_id: InstId!,
              att_query_type: PrEmpAttQueryType.ALL_EMPS_ATT,
              cal_month: toIsoDate(serverDate),
              date_of_attendance: toIsoDate(serverDate),
            },
            name: EMPTY_STRING,
            sortBy: SortBy.TOTAL_DAYS,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: `Sucessfully Updated Attendence for the day ${TODAY_DATE}`,
          operation: Operation.CREATE,
        });
      }
    });
  };

  useEffect(() => {
    if (data && !loading && token) {
      GetTodaysAttStatus().then(({ data: attStatus }) => {
        if (!attStatus?.GetPayRollEmpAttTodaysStatus) {
          const newData = data.GetPayRollEmpAttendance.edges.map((edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked:
                edge.node[`day_20`] === AttendanceStatus.P ||
                edge.node[`day_20`] === EMPTY_STRING
                  ? true
                  : false,
            },
          }));

          if (endCursor) {
            // If we have already fetched some data, we need to check if there
            // are any duplicates in the new data, and update their isChecked
            // property based on the existing data.
            const filteredEmployee = employees.filter(
              (employee) => !employee.node.isChecked
            );

            const updatedNewData = newData.map((newEmployee) => {
              const filteredStudent = filteredEmployee.find(
                (employee) => employee.node.id === newEmployee.node.id
              );
              if (filteredStudent) {
                return {
                  ...newEmployee,
                  node: {
                    ...newEmployee.node,
                    isChecked: filteredStudent.node.isChecked,
                  },
                };
              }
              return newEmployee;
            });
            setEmployees(updatedNewData);
          } else {
            setEmployees(newData);
          }
          setEndCursor(data.GetPayRollEmpAttendance.pageInfo.endCursor);
        }
      });
    } // eslint-disable-next-line
  }, [data, loading, GetTodaysAttStatus, token]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.Attendance.Titles.map(
          (title: EmpAttendanceTitlesType, index: React.Key) => {
            return <React.Fragment key={index}>{title.List}</React.Fragment>;
          }
        )}
      </Title>

      <div className="emp-attendance-overview">
        <div className="row g-0 emp-attendance-overview__data">
          <div className="col h-100">
            {/* <div className="row g-0 emp-attendance-overview__blocks">
              <div className="emp-attendance-overview__blocks--cards">
                <Title variant="subtitle1">
                  <img src={TotalStudents} alt="/" />
                  Total Employees
                </Title>
                <b className="emp-attendance-overview__blocks--cards--students">
                  4
                </b>
              </div>
              <div className="emp-attendance-overview__blocks--cards">
                <Title variant="subtitle1">
                  <img src={Present} alt="/" />
                  Total Present
                </Title>
                <b className="emp-attendance-overview__blocks--cards--present">
                  4
                </b>
              </div>
              <div className="emp-attendance-overview__blocks--cards">
                <Title variant="subtitle1">
                  <img src={Absent} alt="/" />
                  Total Absent
                </Title>
                <b className="emp-attendance-overview__blocks--cards--absent">
                  4
                </b>
              </div>
              <div className="emp-attendance-overview__blocks--cards">
                <Title variant="subtitle1">
                  <img src={AttendancePercentage} alt="/" />
                  Attendance Percentage
                </Title>
                <b className="emp-attendance-overview__blocks--cards--percentage">
                  4
                </b>
              </div>
            </div> */}
            <div className="row g-0">
              <div className="col-3">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => setSearchEmployee(e.target.value)}
                />
              </div>
              <div className="col-3">
                {totalCount && totalCount > 0 ? (
                  <i className="nodata">
                    Cannot Add Attendence on this day, as it is declared
                    Holiday.
                  </i>
                ) : null}
              </div>
            </div>
            <div className="row g-0 emp-attendance-overview__tableblock">
              {error ? (
                <b className="nodata">{error.message}</b>
              ) : !attStatus?.GetPayRollEmpAttTodaysStatus ? (
                <TableContainer
                  className="emp-attendance-overview__table"
                  onScroll={handleScroll}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {HR_Table.Attendance.TableHeaders.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employees.map(({ node }, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className="emp-attendance-overview__table--slno">
                              {index + 1} &nbsp; <img src={Avatar} alt="/" />
                            </TableCell>
                            <TableCell className="emp-attendance-overview__table--admno">
                              {node?.emp_details.emp_id}
                            </TableCell>
                            <TableCell
                              className="emp-attendance-overview__table--name"
                              onClick={() => {
                                dispatch({
                                  type: payloadTypes.SET_EMPLOYEE_ID,
                                  payload: { employeeId: node?.pr_emp_id },
                                });
                                setPerEmpAttendance(!perEmpAttendance);
                              }}
                            >
                              {`${node.emp_details.emp_first_name} ${node.emp_details.emp_middle_name} ${node.emp_details.emp_last_name}`}
                            </TableCell>
                            <TableCell
                              className="emp-attendance-overview__table--status"
                              id="td-center"
                            >
                              {enableMarkAttendance ? (
                                <AntSwitch
                                  checked={node?.isChecked}
                                  onClick={(e) => handleEditItem(node?.id, e)}
                                  ref={AddRefs}
                                  onKeyDown={(e: React.KeyboardEvent) => {
                                    if (e.key === Keys.ENTER) {
                                      if (
                                        empStatusRefs.current[index + 1] &&
                                        index !== employees.length - 1
                                      ) {
                                        (
                                          empStatusRefs.current[
                                            index + 1
                                          ].getElementsByClassName(
                                            muiCheckboxClassName
                                          )[0] as HTMLInputElement
                                        ).focus();
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <b className="nodata">Attendance for this date completed</b>
              )}
            </div>
          </div>
          <div className="col-2 emp-attendance-overview__rightblock">
            <div className="emp-attendance-overview__rightblock--date">
              <TextField
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                label="Date"
                type="date"
                disabled
                value={toInputStandardDate(serverDate)}
                className="emp-attendance-overview__rightblock--textfield--date"
              />
            </div>
            <div className="emp-attendance-overview__rightblock--buttons">
              <Button
                mode="absent"
                onClick={() => setAbsentiesList(!absentiesList)}
              >
                View Absentees List
              </Button>
            </div>
            <div className="emp-attendance-overview__rightblock--buttons">
              <Button
                mode="view-graph"
                onClick={() => setMonthlyOverView(!monthlyOverView)}
              >
                Monthly Overview
              </Button>
            </div>
          </div>
        </div>
        {enableMarkAttendance ? (
          <Button mode="save" onClick={handleMarkingAttendence}>
            &nbsp;Attendance
          </Button>
        ) : (
          <Button
            onClick={() => setEnableMarkAttendance(!enableMarkAttendance)}
            autoFocus
          >
            <img src={MarkAttendance} alt="/" />
            Mark Attendance
          </Button>
        )}

        <Button mode="back" onClick={() => navigate(-1)} />

        <AttendanceDrawer
          anchor="right"
          open={monthlyOverView}
          onClose={() => setMonthlyOverView(!monthlyOverView)}
        >
          <MonthlyOverview />
        </AttendanceDrawer>
        <AttendanceDrawer
          anchor="right"
          open={absentiesList}
          onClose={() => setAbsentiesList(!absentiesList)}
        >
          <AbsenteesList
            pr_acd_yr_id={
              payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!
            }
          />
        </AttendanceDrawer>
        <AttendanceDrawer
          anchor="right"
          open={perEmpAttendance}
          onClose={() => setPerEmpAttendance(!perEmpAttendance)}
        >
          <ViewPerEmployeeAttendance
            pr_acd_yr_id={
              payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!
            }
          />
        </AttendanceDrawer>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={selectLeaveLedgerModal.flag}
        ariaHideApp={false}
        style={AddLeaveLedgerModalStyle}
        onRequestClose={() => {
          handleEditLeaveLedger(SubmitType.CANCEL);
          setSelectLeaveLedgerModal({
            id: 0,
            flag: false,
          });
        }}
      >
        <div className="logout-modal">
          <Label variant="LabelPrimary">Select LeaveLedger</Label>
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={leaveOptions}
            openOnFocus
            onChange={(e, newValue) => {
              if (newValue) {
                setLeaveLedgerSelected(newValue as responseType);
              } else {
                setLeaveLedgerSelected(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="LeaveLedgers"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className={labelClasses.formControlRoot}
              />
            )}
          />
          <Button
            onClick={() => handleEditLeaveLedger(SubmitType.SAVE)}
            mode="save"
          />
          <Button
            onClick={() => handleEditLeaveLedger(SubmitType.CANCEL)}
            mode="cancel"
          />
        </div>
      </Modal>

      <LoadingModal flag={loading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  CommonPaginationVars,
  PayRollCategoryData,
  PayRollDeptData,
  PayRollDesignationData,
  PayRollGradeData,
  PayRollJobTypeData,
} from "../Types/paginationTypes";
import { GetPayRollDeptsByInstId } from "../queries/department/query";
import { Direction, SortBy } from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { PayRollMasters } from "../enums/Enum.types";
import { useParams } from "react-router-dom";
import { GetPayRollCategoriesByInstId } from "../queries/category/query";
import { GetPayRollDesignationsByInstId } from "../queries/designation/query";
import { GetPayRollGradesByInstId } from "../queries/grade/query";
import { GetPayRollJobTypesByInstId } from "../queries/jobtype/query";
import { responseType } from "../../../utils/Form.types";

const usePayRollMasters = (
  master_config: PayRollMasters,
  searchData: string,
  rowsPerPage: number | null
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [dropDown, setDropDown] = useState<responseType[]>([]);
  const [
    GetDept,
    {
      data: departmentData,
      loading: departmentLoading,
      fetchMore: departmentFetchMore,
    },
  ] = useLazyQuery<PayRollDeptData, CommonPaginationVars>(
    GetPayRollDeptsByInstId,
    {
      variables: {
        first: rowsPerPage,
        after: null,
        sortBy: SortBy.DEPT_DESC,
        direction: Direction.ASC,
        token,
        name: searchData,
        inst_id: InstId!,
      },
      fetchPolicy: "network-only",
    }
  );
  const [
    GetCategory,
    {
      data: categoryData,
      loading: categoryLoading,
      fetchMore: categoryFetchmore,
    },
  ] = useLazyQuery<PayRollCategoryData, CommonPaginationVars>(
    GetPayRollCategoriesByInstId,
    {
      variables: {
        first: rowsPerPage,
        after: null,
        sortBy: SortBy.CATEGORY_DESC,
        direction: Direction.ASC,
        token,
        name: searchData,
        inst_id: InstId!,
      },
      fetchPolicy: "network-only",
    }
  );
  const [
    GetDesignation,
    {
      data: designationData,
      loading: designationLoading,
      fetchMore: designationFetchmore,
    },
  ] = useLazyQuery<PayRollDesignationData, CommonPaginationVars>(
    GetPayRollDesignationsByInstId,
    {
      variables: {
        first: rowsPerPage,
        after: null,
        sortBy: SortBy.DESIGNATION_DESC,
        direction: Direction.ASC,
        token,
        name: searchData,
        inst_id: InstId!,
      },
      fetchPolicy: "network-only",
    }
  );
  const [
    GetGrade,
    { data: gradeData, loading: gradeLoading, fetchMore: gradeFetchmore },
  ] = useLazyQuery<PayRollGradeData, CommonPaginationVars>(
    GetPayRollGradesByInstId,
    {
      variables: {
        first: rowsPerPage,
        after: null,
        sortBy: SortBy.GRADE_DESC,
        direction: Direction.ASC,
        token,
        name: searchData,
        inst_id: InstId!,
      },
      fetchPolicy: "network-only",
    }
  );
  const [
    GetJobType,
    { data: jobTypeData, loading: jobTypeLoading, fetchMore: jobTypeFetchmore },
  ] = useLazyQuery<PayRollJobTypeData, CommonPaginationVars>(
    GetPayRollJobTypesByInstId,
    {
      variables: {
        first: rowsPerPage,
        after: null,
        sortBy: SortBy.JOB_TYPE_DESC,
        direction: Direction.ASC,
        token,
        name: searchData,
        inst_id: InstId!,
      },
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (token && InstId) {
      switch (master_config) {
        case PayRollMasters.DEPARTMENT:
          GetDept().then(({ data }) => {
            if (data) {
              setDropDown(
                data.GetPayRollDeptsByInstId.edges.map(({ node }) => ({
                  label: node.dept_desc,
                  value: node.id,
                }))
              );
            }
          });
          break;
        case PayRollMasters.CATEGORY:
          GetCategory().then(({ data }) => {
            if (data) {
              setDropDown(
                data.GetPayRollCategoriesByInstId.edges.map(({ node }) => ({
                  label: node.category_desc,
                  value: node.id,
                }))
              );
            }
          });
          break;
        case PayRollMasters.DESIGNATION:
          GetDesignation().then(({ data }) => {
            if (data) {
              setDropDown(
                data.GetPayRollDesignationsByInstId.edges.map(({ node }) => ({
                  label: node.designation_desc,
                  value: node.id,
                }))
              );
            }
          });
          break;
        case PayRollMasters.GRADE:
          GetGrade().then(({ data }) => {
            if (data) {
              setDropDown(
                data.GetPayRollGradesByInstId.edges.map(({ node }) => ({
                  label: node.grade_desc,
                  value: node.id,
                }))
              );
            }
          });
          break;
        case PayRollMasters.JOB_TYPE:
          GetJobType().then(({ data }) => {
            if (data) {
              setDropDown(
                data.GetPayRollJobTypesByInstId.edges.map(({ node }) => ({
                  label: node.job_type_desc,
                  value: node.id,
                }))
              );
            }
          });
          break;
        default:
          break;
      }
    }
  }, [
    master_config,
    token,
    GetDept,
    GetCategory,
    GetDesignation,
    GetGrade,
    GetJobType,
    InstId,
    searchData,
  ]);
  return {
    departmentDetails: {
      data: departmentData,
      loading: departmentLoading,
      fetchMore: departmentFetchMore,
    },
    categoryDetails: {
      data: categoryData,
      loading: categoryLoading,
      fetchMore: categoryFetchmore,
    },
    designationDetails: {
      data: designationData,
      loading: designationLoading,
      fetchMore: designationFetchmore,
    },
    gradeDetails: {
      data: gradeData,
      loading: gradeLoading,
      fetchMore: gradeFetchmore,
    },
    jobTypeDetails: {
      data: jobTypeData,
      loading: jobTypeLoading,
      fetchMore: jobTypeFetchmore,
    },
    dropDown,
  };
};

export default usePayRollMasters;

import React, { useContext, useEffect } from "react";
import Home from "../../../Home/Index";
import InstDetails from "../../InstDetails";
import Input from "../../../../../stories/Input/Input";
import { Title } from "../../../../../stories/Title/Title";
import View from "../../../../../images/EyeWhite.svg";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { GetAcdTeacherClassAssociations } from "../../../queries/subjects/List.tsx/Index";
import useToken from "../../../../../customhooks/useToken";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsData,
  GetAcdTeacherClassAssociationsVars,
} from "../../../types/subject/Subject";
import { useLazyQuery } from "@apollo/client";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../../context/context";
import { AttendanceOverViewPageFor } from "../../../../../utils/Enum.types";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";

interface Props {
  pageType: AttendanceOverViewPageFor;
}
const Index = ({ pageType }: Props) => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { token } = useToken();
  const { entryId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();

  const {
    user_details: { id },
  } = useLoggedInUserDetails();
  const { flag, acdLevel } = useCheckAllocationType();

  const [GetSubjectsTeachersAssociated, { data }] = useLazyQuery<
    GetAcdTeacherClassAssociationsData,
    GetAcdTeacherClassAssociationsVars
  >(GetAcdTeacherClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
      per_std_subj_allocation: flag,
      input: {
        query_type:
          AcdTeacherClassQueryType.ALL_SUBJECTS_BY_TEACHER_ID_AT_ENTRY_LEVEL,
        entry_id: Number(entryId)!,
        entry_level: acdLevel,
        pr_emp_id: id,
        subj_master_id: 0,
      },
    },
  });

  useEffect(() => {
    if (
      token &&
      InstId &&
      entry_level &&
      entryId &&
      id &&
      activeAcademicYearData.data &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetSubjectsTeachersAssociated();
    }
  }, [
    token,
    InstId,
    entry_level,
    entryId,
    id,
    activeAcademicYearData.data,
    GetSubjectsTeachersAssociated,
    state.ActiveAcdYr,
    acdLevel,
    flag,
  ]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Daily Activities Subjects List</Title>
      <div className="per-session-details">
        <InstDetails />
        <div className="row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col">
            <Title variant="subtitle1">Allocated Subjects</Title>
          </div>
          <div className="col-2"></div>
        </div>
        <div className="per-session-details__data">
          <div className="per-session-details__data--grids">
            {data &&
              data.GetAcdTeacherClassAssociations.map((data, index) => {
                return (
                  <div className="per-session-details__data--grid" key={index}>
                    <b className="per-session-details__data--sub">
                      {data.subject_details.subj_desc}
                    </b>
                    <span className="per-session-details__data--subcode">
                      ({data.subject_details.subj_code})
                    </span>
                    {/* <span className="per-session-details__data--desn">
                      Subject Teacher
                    </span> */}
                    {/* <div className="per-session-details__data--multiple-images">
                      {data.teacher_profile
                        .slice(-2)
                        .map((teacherdetails, index) => (
                          <React.Fragment key={index}>
                            <img src={teacherdetails.image} alt="" />
                          </React.Fragment>
                        ))}
                      {data.teacher_profile.length > 2 && (
                        <b>+{data.teacher_profile.length - 2}</b>
                      )}
                    </div> */}
                    {/* <div className="per-session-details__data--multiple-codes">
                      {data.teacher_profile
                        .slice(-2)
                        .map((teacherdetails, index) => (
                          <React.Fragment key={index}>
                            <span className="per-session-details__data--subcode">
                              {teacherdetails.emp_id}
                              {index !==
                                data.teacher_profile.slice(-2).length - 1 &&
                                ", "}
                            </span>
                          </React.Fragment>
                        ))}
                    </div> */}
                    {/* {data.teacher_profile.length > 1 ? (
                      <>
                        <Tooltip
                          title={
                            <ul className="per-session-details__data--list">
                              {data.teacher_profile.map((data, index) => {
                                return (
                                  <li key={index}>
                                    <img src={data.image} alt="" />
                                    <span>{data.name}</span>
                                    <span className="per-session-details__data--subcode">
                                      {data.emp_id}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          }
                          placement="right"
                          classes={{
                            tooltip: tooltipClasses.studentOptions,
                          }}
                        >
                          <>
                            {data.teacher_profile.length > 2 && (
                              <span className="per-session-details__data--desn--multiple">
                                View
                                {data.teacher_profile.length - 2} Teachers
                              </span>
                            )}
                          </>
                        </Tooltip>
                      </>
                    ) : (
                      <span className="per-session-details__data--sub">
                        {data.teacher_profile.map((data, index) => {
                          return (
                            <React.Fragment key={index}>
                              {data.name}
                            </React.Fragment>
                          );
                        })}
                      </span>
                    )} */}

                    <div className="per-session-details__data--view">
                      <button
                        onClick={() => {
                          if (
                            pageType ===
                            AttendanceOverViewPageFor.DAILY_ACTIVITIES
                          ) {
                            navigate(
                              `/${InstId}/academics/dailyactivities/${entryId}/${data.subject_details.id}/classdetails`
                            );
                          } else {
                            navigate(
                              `/${InstId}/academics/attendance/foradmin/${entryId}/${data.subject_details.id}/persession/attendance`
                            );
                          }
                        }}
                      >
                        View&nbsp; <img src={View} alt="" />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

import React, { useContext, useEffect, useMemo, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import List from "./List";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { GetAttDashBoardCount } from "../../../../queries/common";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import TotalClasses from "../../../../images/TotalClasses.svg";
import TotalAttendanceMarked from "../../../../images/TotalAttendanceMarked.svg";
import TotalAttendanceNotMarked from "../../../../images/TotalAttendanceNotMarked.svg";
import TotalStudents from "../../../../images/TotalStudentsinAdmin.svg";
import TotalPresent from "../../../../images/Attendance_Marked.svg";
import TotalAbsent from "../../../../images/Attendance_Not_Marked.svg";
import OverallPercentage from "../../../../images/AttendanceOverallPercentage.svg";
import View from "../../../../images/EyeWhite.svg";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

import { responseType } from "../../../../utils/Form.types";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import { Keys } from "../../../../utils/Enum.keys";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  InstitutionConfigurationTypes,
  TableHeaders,
  attendanceReportType,
} from "../../../../utils/Enum.types";
import { TreeType } from "../../Attendance/ForAdmin";
import useMstInstTreeByQueryType from "../../../../customhooks/general/useMstInstTreeByQueryType";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { Button } from "../../../../stories/Button/Button";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

interface AssignComponentProp {
  id: number;
  marked: boolean;
}
interface props {
  pageType: attendanceReportType;
}
const DailyAttendance = ({ pageType }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const navigate = useNavigate();
  const [GetAttendanceDashBoardData, { data: DashBoardData }] =
    useLazyQuery(GetAttDashBoardCount);
  console.log(DashBoardData, "DashBoardData");

  const { activeAcademicYearData } = useActiveAcademicYear();
  const [date, setDate] = useState(toInputStandardDate(TODAY_DATE));

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  let idsSet = new Set();

  useEffect(() => {
    if (
      token &&
      InstId &&
      state.ActiveAcdYr &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetAttendanceDashBoardData({
        variables: {
          token,
          inst_id: InstId,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(date),
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    GetAttendanceDashBoardData,
    date,
    state.ActiveAcdYr,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
  ]);

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }
    if (USE_SECTION_KEY) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);

  const { data } = useMstInstTreeByQueryType(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    toIsoDate(date),
    0
  );

  const modifiedArrayForTable: TreeType[] = data
    ? data.GetAcdInstTree.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.map((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          level1_std_level_alloc: false,
                          level2_std_level_alloc: false,
                          level3_std_level_alloc: false,
                          level4_std_level_alloc: false,
                          level5_std_level_alloc: false,
                          filterThis: false,
                          total_count: level5.total_count,
                          total_att_present: level5.total_att_present,
                          total_att_absent: level5.total_att_absent,
                          att_percentage: level5.att_percentage,
                          att_marked: level5.att_marked,
                          cal_days_count: level5.cal_days_count,
                          att_marked_days_count: level5.att_marked_days_count,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_id: level1.acd_level_1_id,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level2_id: level2.acd_level_2_id,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level3_id: level3.acd_level_3_id,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count
                          ? level4.sub_acd_level_total_count
                          : 1,
                        level4_id: level4.acd_level_4_id,
                        level5_name: EMPTY_STRING,
                        level5_id: 0,
                        level1_std_level_alloc: false,
                        level2_std_level_alloc: false,
                        level3_std_level_alloc: false,
                        level4_std_level_alloc: false,
                        level5_std_level_alloc: false,
                        filterThis: !(last_level <= 5),
                        total_count: level4.total_count,
                        total_att_present: level4.total_att_present,
                        total_att_absent: level4.total_att_absent,
                        att_percentage: level4.att_percentage,
                        att_marked: level4.att_marked,
                        cal_days_count: level4.cal_days_count,
                        att_marked_days_count: level4.att_marked_days_count,
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_id: level1.acd_level_1_id,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level2_id: level2.acd_level_2_id,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level3_id: level3.acd_level_3_id,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level4_id: 0,
                    level5_name: EMPTY_STRING,
                    level5_id: 0,
                    level1_std_level_alloc: false,
                    level2_std_level_alloc: false,
                    level3_std_level_alloc: false,
                    level4_std_level_alloc: false,
                    level5_std_level_alloc: false,
                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                    total_count: level3.total_count,
                    total_att_present: level3.total_att_present,
                    total_att_absent: level3.total_att_absent,
                    att_percentage: level3.att_percentage,
                    att_marked: level3.att_marked,
                    cal_days_count: level3.cal_days_count,
                    att_marked_days_count: level3.att_marked_days_count,
                  };

                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_id: level1.acd_level_1_id,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level2_id: level2.acd_level_2_id,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level3_id: 0,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level4_id: 0,
                level5_name: EMPTY_STRING,
                level5_id: 0,
                level1_std_level_alloc: false,
                level2_std_level_alloc: false,
                level3_std_level_alloc: false,
                level4_std_level_alloc: false,
                level5_std_level_alloc: false,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                total_count: level2.total_count,
                total_att_present: level2.total_att_present,
                total_att_absent: level2.total_att_absent,
                att_percentage: level2.att_percentage,
                att_marked: level2.att_marked,
                cal_days_count: level2.cal_days_count,
                att_marked_days_count: level2.att_marked_days_count,
              };

              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_id: level1.acd_level_1_id,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level2_id: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level3_id: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level4_id: 0,
            level5_name: EMPTY_STRING,
            level5_id: 0,
            level1_std_level_alloc: false,
            level2_std_level_alloc: false,
            level3_std_level_alloc: false,
            level4_std_level_alloc: false,
            level5_std_level_alloc: false,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),

            total_count: level1.total_count,
            total_att_present: level1.total_att_present,
            total_att_absent: level1.total_att_absent,
            att_percentage: level1.att_percentage,
            att_marked: level1.att_marked,
            cal_days_count: level1.cal_days_count,
            att_marked_days_count: level1.att_marked_days_count,
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];

  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const AssignComponent = ({ id, marked }: AssignComponentProp) => (
    <button
      className="view-button"
      onClick={() => {
        if (enablePerSessionAtt !== undefined) {
          if (enablePerSessionAtt) {
            if (pageType === attendanceReportType.DAILY_ATT) {
              navigate(
                `/${InstId}/academics/reports/attendance/dailyattendance/${id}/view`
              );
            } else if (pageType === attendanceReportType.OVERALL) {
              navigate(
                `/${InstId}/academics/reports/attendance/overall/${id}/view`
              );
            } else if (pageType === attendanceReportType.MARKED) {
              navigate(
                `/${InstId}/academics/reports/attendance/marked/${id}/view`
              );
            } else {
              navigate(
                `/${InstId}/academics/reports/attendance/monthly/${id}/subjectView`
              );
            }
          } else {
            if (pageType === attendanceReportType.DAILY_ATT) {
              navigate(
                `/${InstId}/academics/reports/attendance/dailyattendance/${id}/view`
              );
            } else if (pageType === attendanceReportType.OVERALL) {
              navigate(
                `/${InstId}/academics/reports/attendance/overall/${id}/view`
              );
            } else if (pageType === attendanceReportType.MARKED) {
              navigate(
                `/${InstId}/academics/reports/attendance/marked/${id}/view`
              );
            } else {
              navigate(
                `/${InstId}/academics/reports/attendance/monthly/${id}/view`
              );
            }
          }
        }
      }}
    >
      View <img src={View} alt="" />
    </button>
  );
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="daily-attendance">
        <div className="daily-attendance__left">
          <List />
        </div>
        <div className="daily-attendance__right">
          {pageType === attendanceReportType.DAILY_ATT && (
            <>
              <div className="row g-0 daily-attendance__right--cards">
                <div className="col daily-attendance__right--cards--list">
                  <div className="daily-attendance__right--cards--list--grid">
                    <img src={TotalClasses} alt="" />
                    <span>Total Classes</span>
                    <b className="font-blue">
                      {DashBoardData
                        ? DashBoardData?.GetAttDashBoardCount.total_classes
                        : 0}
                    </b>
                  </div>
                  <div className="daily-attendance__right--cards--list--grid">
                    <img src={TotalAttendanceMarked} alt="" />
                    <span>Total Attendance Marked</span>
                    <b className="font-green">
                      {DashBoardData
                        ? DashBoardData?.GetAttDashBoardCount.total_att_marked
                        : 0}
                    </b>
                  </div>
                  <div className="daily-attendance__right--cards--list--grid">
                    <img src={TotalAttendanceNotMarked} alt="" />
                    <span>Total Attendance Not Marked</span>
                    <b className="font-red">
                      {DashBoardData
                        ? DashBoardData?.GetAttDashBoardCount
                            .total_att_not_marked
                        : 0}
                    </b>
                  </div>
                </div>
                <div className="col-2 daily-attendance__right--cards--filter">
                  <TextField
                    className="daily-attendance__right--cards--textfield"
                    label="Date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                </div>
              </div>

              <div className="daily-attendance__right--cards">
                <div className="daily-attendance__right--cards--list">
                  <div className="daily-attendance__right--cards--list--grid">
                    <img src={TotalStudents} alt="" />
                    <span>Total Students</span>
                    <b className="font-blue">
                      {DashBoardData &&
                        DashBoardData.GetAttDashBoardCount.total_students}
                    </b>
                  </div>
                  <div className="daily-attendance__right--cards--list--grid">
                    <img src={TotalPresent} alt="" />
                    <span>Total Students Present</span>
                    <b className="font-green">
                      {DashBoardData &&
                        DashBoardData.GetAttDashBoardCount
                          .total_students_present}
                    </b>
                  </div>
                  <div className="daily-attendance__right--cards--list--grid">
                    <img src={TotalAbsent} alt="" />
                    <span>Total Students Absent</span>
                    <b className="font-red">
                      {DashBoardData &&
                        DashBoardData.GetAttDashBoardCount
                          .total_students_absent}
                    </b>
                  </div>
                  <div className="daily-attendance__right--cards--list--grid">
                    <img src={OverallPercentage} alt="" />
                    <span>Overall Attendance Percentage</span>
                    <b className="font-purple">
                      {DashBoardData?.GetAttDashBoardCount.att_percentage !==
                      undefined
                        ? DashBoardData?.GetAttDashBoardCount.att_percentage +
                          "%"
                        : ""}
                    </b>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="daily-attendance__right--filters row g-0">
            {USE_DEPARTMENT_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={departmentDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(
                      option as responseType,
                      departmentSelected
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (departmentSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(null);
                    }
                  }}
                  openOnFocus
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue as responseType);
                    } else {
                      setDepartmentSelected(null);
                    }
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      id="outlined Departments"
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={branchDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, branchSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (branchSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(null);
                    }
                  }}
                  openOnFocus
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue as responseType);
                    } else {
                      setBranchSelected(null);
                    }
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={classDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, classSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (classSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(null);
                    }
                  }}
                  openOnFocus
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue as responseType);
                    } else {
                      setClassSelected(null);
                    }
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={classLabel}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                      fullWidth
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={semesterDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(
                      option as responseType,
                      semesterSelected
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (semesterSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterSelected(null);
                    }
                  }}
                  openOnFocus
                  value={semesterSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterSelected(newValue as responseType);
                    } else {
                      setSemesterSelected(null);
                    }
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(
                      option as responseType,
                      sectionSelected
                    )
                  }
                  className={labelClasses.inputRoot}
                  options={sectionDropDown}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (sectionSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSectionSelected(null);
                    }
                  }}
                  openOnFocus
                  value={sectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionSelected(newValue as responseType);
                    } else {
                      setSectionSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <div
            className={
              pageType === attendanceReportType.DAILY_ATT
                ? "daily-attendance__right--tableblock"
                : "daily-attendance__right--overall"
            }
          >
            <TableContainer className="daily-attendance__right--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {USE_DEPARTMENT_KEY ? (
                      <TableCell>{departmentLabel}</TableCell>
                    ) : null}
                    {USE_BRANCH_KEY ? (
                      <TableCell>{branchLabel}</TableCell>
                    ) : null}
                    {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
                    {USE_SEMESTER_KEY ? (
                      <TableCell>{semesterLabel}</TableCell>
                    ) : null}
                    {USE_SECTION_KEY ? (
                      <TableCell>{sectionLabel}</TableCell>
                    ) : null}
                    {pageType === attendanceReportType.DAILY_ATT &&
                    !enablePerSessionAtt ? (
                      <>
                        <TableCell>Total Students</TableCell>
                        <TableCell>Total Present</TableCell>
                        <TableCell>Total Absent</TableCell>
                        <TableCell>Percentage</TableCell>
                      </>
                    ) : enablePerSessionAtt ? (
                      <>
                        <TableCell>Total Working Days</TableCell>
                        <TableCell>Marked Days</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Total Working Days</TableCell>
                        <TableCell>Attendance Marked</TableCell>
                      </>
                    )}

                    <TableCell>{TableHeaders.ACTION}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                    if (data) {
                      return (
                        <TableRow key={i}>
                          {data.level1_name !== EMPTY_STRING ? (
                            <TableCell rowSpan={data.level1_rowspan}>
                              {data.level1_name}
                            </TableCell>
                          ) : null}
                          {data &&
                          (data.level2_name ||
                            data.level2_name !== EMPTY_STRING) ? (
                            <TableCell rowSpan={data.level2_rowspan}>
                              {data.level2_name}
                            </TableCell>
                          ) : null}
                          {data && data.level3_name !== EMPTY_STRING ? (
                            <TableCell rowSpan={data.level3_span}>
                              {data.level3_name}
                            </TableCell>
                          ) : null}
                          {data && data.level4_name !== EMPTY_STRING ? (
                            <TableCell rowSpan={data.level4_span}>
                              {data.level4_name}
                            </TableCell>
                          ) : null}
                          {data.level5_name !== EMPTY_STRING ? (
                            <TableCell>{data.level5_name}</TableCell>
                          ) : null}

                          {pageType === attendanceReportType.DAILY_ATT &&
                          !enablePerSessionAtt ? (
                            <>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-blue"
                              >
                                {data.total_count}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-green"
                              >
                                {data.total_att_present}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-red"
                              >
                                {data.total_att_absent}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-amber"
                              >
                                {data.att_percentage}%
                              </TableCell>
                            </>
                          ) : enablePerSessionAtt ? (
                            <>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-blue"
                              >
                                {data.cal_days_count}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-green"
                              >
                                {data.att_marked_days_count}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-blue"
                              >
                                {data.cal_days_count}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="att-for-admin__table--number font-green"
                              >
                                {data.att_marked_days_count}
                              </TableCell>
                            </>
                          )}

                          <TableCell
                            id="td-center"
                            className="att-for-admin__table--actions"
                          >
                            {last_level - 1 === 1 && data.level1_name && (
                              <AssignComponent
                                id={data.level1_id}
                                marked={data.att_marked!}
                              />
                            )}
                            {last_level - 1 === 2 && data.level2_name && (
                              <AssignComponent
                                id={data.level2_id}
                                marked={data.att_marked!}
                              />
                            )}
                            {last_level - 1 === 3 && data.level3_name && (
                              <AssignComponent
                                id={data.level3_id}
                                marked={data.att_marked!}
                              />
                            )}
                            {last_level - 1 === 4 && data.level4_name && (
                              <AssignComponent
                                id={data.level4_id}
                                marked={data.att_marked!}
                              />
                            )}
                            {last_level - 1 === 5 && data.level5_name && (
                              <AssignComponent
                                id={data.level5_id}
                                marked={data.att_marked!}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="excel">Export</Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default DailyAttendance;

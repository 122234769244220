import { NavLink, useParams } from "react-router-dom";
import { Title } from "../../../../stories/Title/Title";

const ParentPreviewTabs = () => {
  const { InstId, parentId } = useParams();

  return (
    <>
      <Title>Parent Details Preview</Title>
      <div className="css3-tabstrip">
        <ul>
          <li>
            <NavLink
              to={`/${InstId}/admissions/parents/${parentId}/ParentData`}
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-0"
              />

              <label htmlFor="css3-tabstrip-0-0">Basic Details</label>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${InstId}/admissions/parents/${parentId}/parentAssosiation`}
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-1"
              />
              <label htmlFor="css3-tabstrip-0-1">Parent Association</label>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ParentPreviewTabs;

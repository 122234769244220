import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabPanel, a11yProps } from "../../../styles/Tabs";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import TreeView from "./TreeView";
import Home from "../Home/Index";
import UserRightsHome from "../../UserRights/Home/Index";
import { Title } from "../../../stories/Title/Title";
import Department from "../Payroll/Department/Index";
import Designation from "../Payroll/Designation/Index";
import { LicenseTypes, PageFor } from "../../../utils/Enum.types";
import { ComponentFor } from "../enums/Enum.types";
interface Props {
  LicenseType: LicenseTypes;
}
const Index = ({ LicenseType }: Props) => {
  const theme = useTheme();
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => setValue(index);
  return (
    <>
      {LicenseType === LicenseTypes.PAYROLL ? (
        <Home DashBoardRequired={false} />
      ) : (
        <UserRightsHome DashBoardRequired={false} />
      )}
      <Title>Organizational Chart</Title>
      <div className="organizational-chart">
        <div className="row g-0 organizational-chart__tabs">
          <div className="col-6">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Oganizational Chart" value={0} {...a11yProps(0)} />
              <Tab label="Department" value={1} {...a11yProps(1)} />
              <Tab label="Designation" value={2} {...a11yProps(2)} />
            </Tabs>
          </div>
        </div>
        <div className="row g-0 organizational-chart__swipable">
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            className="h-100"
          >
            {/* values for Organizational Chart */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <TreeView />
            </TabPanel>
            {/* values for Department */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Department
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
                type={ComponentFor.OAGANIZATIONAL}
                LicenseType={LicenseTypes.PAYROLL}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Designation
                pageType={PageFor.GENERAL}
                setModalFlag={setModalFlag}
                type={ComponentFor.OAGANIZATIONAL}
                LicenseType={LicenseTypes.PAYROLL}
              />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const AddOwner = gql`
  mutation AddOwner(
    $token: String!
    $input: CreateMstCustOwnerInput!
    $user_details: SubscribedUser!
  ) {
    AddOwner(token: $token, input: $input, user_details: $user_details)
  }
`;
export const UpdateOwner = gql`
  mutation UpdateOwner(
    $token: String!
    $id: ID!
    $input: UpdateMstCustOwnerInput!
    $user_details: SubscribedUser!
  ) {
    UpdateOwner(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;
export const ResetOwnerPassword = gql`
  mutation ResetOwnerPassword(
    $token: String!
    $owner_id: ID!
    $password: String!
    $user_details: SubscribedUser!
  ) {
    ResetOwnerPassword(
      token: $token
      owner_id: $owner_id
      password: $password
      user_details: $user_details
    )
  }
`;

import React, { useContext, useEffect, useState } from "react";
import { GetAcdSubjectCategories } from "../../../../queries/customallocation/categories/queries";
import {
  AcdSubjectCategory,
  AcdSubjectCategoryDetails,
  GetAcdSubjectCategoriesData,
  GetAcdSubjectCategoriesVars,
} from "../../../../Types/Category/queries";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import { Direction, SortBy } from "../../../../utils/Enum.types";
import { responseType } from "../../../../utils/Form.types";
import { GetAcdSubjectCombos } from "../../../../queries/customallocation/combination/queries";
import {
  GetAcdSubjectCombosData,
  GetAcdSubjectCombosVars,
} from "../../../../Types/Combinations/queries";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
export interface comboType extends responseType {
  category_details: AcdSubjectCategory[];
}
const useCombinations = (
  rowsPerPage: number | null,
  searchData: string,
  query_type: AcdSubjComboQueryType
) => {
  const { token } = useToken();
  const { InstId, entryId, testId } = useParams();
  const { state } = useContext(AppContext);

  const [combinations, setCombinations] = useState<comboType[]>([]);

  const { entry_level } = useInstitutionConfiguration();

  const [GetAcdSubject, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcdSubjectCombosData,
    GetAcdSubjectCombosVars
  >(GetAcdSubjectCombos, {
    variables: {
      token,
      after: null,
      first: rowsPerPage,
      inst_id: InstId!,
      name: searchData,
      query_type,
      entry_id: entryId ? Number(entryId) : 0,
      entry_level,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.SUBJ_COMBO_NAME,
      },
    },
  });

  useEffect(() => {
    if (token && state.ActiveAcdYr) {
      GetAcdSubject().then(({ data }) => {
        if (data && data.GetAcdSubjectCombos) {
          setCombinations(
            data.GetAcdSubjectCombos.edges.map(({ node }) => ({
              label: node.subj_combo_name,
              value: node.id,
              category_details: node.subj_combo_details.map(
                ({ category_details }) => category_details
              ),
            }))
          );
        }
      });
    }
  }, [token, state, GetAcdSubject, data]);

  return { data, loading, error, fetchMore, combinations };
};

export default useCombinations;

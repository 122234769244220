import React, { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import { Button } from "../../../stories/Button/Button";
import Assign from "../../../images/Assign.svg";
import More from "../../../images/More.svg";
import Avatar from "../../../images/Avatar.svg";
import AssignClassTeacher from "../../../images/AssignClassTeacher.svg";

import Modal from "react-modal";
import View from "./View";

import RemoveIcon from "../../../images/RemoveIcon.svg";
import Replace from "../../../images/Replace.svg";
import { useNavigate, useParams } from "react-router-dom";
import TeachersList from "./TeachersList";
import Close from "../../../images/Close.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AcdTeacherClassQueryType,
  GetAcdSubjectForEntryLevelVars,
  GetAcdTeacherSubjectClassAssociationsData,
} from "../types/subject/Subject";
import {
  GetAcdTeacherClassAssociations,
  GetAcdTeacherSubjectClassAssociations,
} from "../queries/subjects/List.tsx/Index";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useToken from "../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { ViewDetails } from "../../../styles/ModalStyles";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import ClassTeachersList from "./ClassTeacherList";
import { Operation } from "../../../utils/Enum.types";
import { UpdateAcdTeacherClassAssoci } from "../queries/teacher_association/mutations";
import { msgType } from "../../../utils/Form.types";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import MessageModal from "../../../pages/MessageModal";
import DeleteModal from "../../../pages/DeleteModal";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { EMPTY_STRING } from "../../../utils/constants";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
import { AllocateTeachersDrawer } from "../../../styles/DrawerStyles";
import { TooltipForMultipleOption } from "../../../styles/TooltipStyles";
const { Academics_Table } = require("../json/table.json");
export interface TeacherDrawerDetails {
  subj_code: string;
  subj_name: string;
  subj_id: number;
}
const Index = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { entryId, InstId } = useParams();

  const [subjectId, setSubjectId] = useState(0);
  const { dispatch, state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();

  const [classTeacherModal, setClassTeacherModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [perSubjectTeacherList, setPerSubjectTeacherList] = useState(false);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [view, setView] = useState(false);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    entry_level,
  } = useInstitutionConfiguration();

  const { flag } = useCheckAllocationType();

  const [AssociateClassTeachers] = useMutation(UpdateAcdTeacherClassAssoci, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;

  const [subjectDetails, setSubjectDetails] = useState<TeacherDrawerDetails>({
    subj_code: "",
    subj_id: 0,
    subj_name: "",
  });
  const [GetSubjectsTeachersAssociated, { data: Tdata }] = useLazyQuery<
    GetAcdTeacherSubjectClassAssociationsData,
    GetAcdSubjectForEntryLevelVars
  >(GetAcdTeacherSubjectClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: Number(entryId)!,
      entry_level: entry_level,
      inst_id: InstId!,
      per_std_subj_allocation: flag,
      token,
    },
  });

  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(entryId!);
  const classTeacherLabel = Tdata?.GetAcdTeacherSubjectClassAssociations.map(
    (data) => data.Teachers.find((res) => res.is_class_teacher === true)
  ).filter((teacher) => teacher !== undefined);

  const HandleDelete = () => {
    AssociateClassTeachers({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        entry_level: entry_level,
        entry_id: entryId,
        pr_emp_id: (classTeacherLabel && classTeacherLabel[0]?.pr_emp_id) ?? 0,
        is_class_teacher: false,
        per_std_subj_allocation: flag,
      },
      refetchQueries: [
        {
          query: GetAcdTeacherSubjectClassAssociations,
          variables: {
            acd_yr_id: activeAcdId,
            entry_id: Number(entryId)!,
            entry_level: entry_level,
            inst_id: InstId!,
            per_std_subj_allocation: flag,
            token,
          },
        },
        {
          query: GetAcdTeacherClassAssociations,
          variables: {
            acd_yr_id: activeAcdId,
            inst_id: InstId!,
            token,
            per_std_subj_allocation: flag,
            input: {
              query_type: AcdTeacherClassQueryType.ALL_TEACHERS_BY_SUBJECT,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              pr_emp_id: 0,
              subj_master_id: subjectId,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Class Teacher Removed Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDeleteModal(!deleteModal);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      entryId &&
      entry_level &&
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetSubjectsTeachersAssociated();
    }
  }, [
    entryId,
    GetSubjectsTeachersAssociated,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    entry_level,
    state.ActiveAcdYr,
    flag,
  ]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Assign Subject Teachers to Class</Title>
      <div className="assign-class-teacher">
        <div className="row g-0 assign-class-teacher__select">
          {USE_DEPARTMENT_KEY && (
            <div className="col-2">
              <TextField
                label={departmentLabel}
                value={configdata?.GetAcdInstConfigNames.acd_dept_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
                className="daily-activities__textfield"
              />
            </div>
          )}
          {USE_BRANCH_KEY && (
            <div className="col-2">
              <TextField
                label={branchLabel}
                value={configdata?.GetAcdInstConfigNames.acd_branch_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
                className="daily-activities__textfield"
              />
            </div>
          )}
          {USE_CLASS_KEY && (
            <div className="col-2">
              <TextField
                label={classLabel}
                value={configdata?.GetAcdInstConfigNames.acd_class_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
                className="daily-activities__textfield"
              />
            </div>
          )}
          {USE_SEMESTER_KEY && (
            <div className="col-2">
              <TextField
                label={semesterLabel}
                value={configdata?.GetAcdInstConfigNames.acd_semester_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
                className="daily-activities__textfield"
              />
            </div>
          )}
          {USE_SECTION_KEY && (
            <div className="col-2">
              <TextField
                label={sectionLabel}
                value={configdata?.GetAcdInstConfigNames.acd_section_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
                className="daily-activities__textfield"
              />
            </div>
          )}
        </div>
        {/* if not assigned display this block */}
        {classTeacherLabel?.length ? (
          <div className="assign-class-teacher__block">
            <div className="assign-class-teacher__block--image">
              <img src={Avatar} alt="" />
              <div className="assign-class-teacher__block--emp-details">
                <b>
                  {classTeacherLabel[0]?.emp_first_name +
                    " " +
                    classTeacherLabel[0]?.emp_middle_name +
                    " " +
                    classTeacherLabel[0]?.emp_last_name}
                </b>
                <span className="assign-class-teacher__block--emp-details--emp-id">
                  {classTeacherLabel[0]?.emp_id}
                </span>
              </div>
              <div className="assign-class-teacher__block--button">
                <button
                  className="assign-class-teacher__remove"
                  onClick={() => setDeleteModal(!deleteModal)}
                >
                  Remove
                  <img src={RemoveIcon} alt="" />
                </button>

                <button
                  className="assign-class-teacher__replace"
                  onClick={() => setClassTeacherModal(!classTeacherModal)}
                >
                  Replace
                  <img src={Replace} alt="" />
                </button>
              </div>
            </div>
            <span className="assign-class-teacher__block--image--text">
              The Classroom activities are being overseen by the class teacher
              on a daily basis.
            </span>
          </div>
        ) : (
          <div className="assign-class-teacher__block">
            <div className="assign-class-teacher__block--image">
              <img src={AssignClassTeacher} alt="" />
              <span className="assign-class-teacher__block--image--assign">
                Assign Class Teacher
              </span>

              <button
                className="assign-class-teacher__assign"
                onClick={() => setClassTeacherModal(!classTeacherModal)}
              >
                Assign
                <img src={Assign} alt="" />
              </button>
            </div>
            <span className="assign-class-teacher__block--image--text">
              The Classroom activities are being overseen by the class teacher
              on a daily basis.
            </span>
          </div>
        )}
        <div className="assign-class-teacher__tableblock">
          <TableContainer className="assign-class-teacher__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.AllocateClassTeacher.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Tdata
                  ? Tdata.GetAcdTeacherSubjectClassAssociations.filter(
                      (data) => data.elective_subj_master_id === 0
                    ).map((subj, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            className="assign-class-teacher__table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--code">
                            {subj.subj_code}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--code">
                            {subj.subj_board_code}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--subject">
                            {subj.subj_desc}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--type">
                            {subj.subj_is_core_subject
                              ? "Core"
                              : subj.subj_is_elective
                              ? "Elective"
                              : subj.subj_is_lab
                              ? "Lab"
                              : subj.subj_is_lang
                              ? "Language"
                              : subj.subj_is_non_academic
                              ? "Non-Academics"
                              : EMPTY_STRING}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--teacher">
                            {subj.Teachers.length ? (
                              subj.Teachers.map((teacher, teachIndex) => (
                                <div
                                  className="assign-class-teacher__table--teacher--details"
                                  key={teachIndex}
                                >
                                  <img src={Avatar} alt="" />
                                  <div>
                                    <b>
                                      {teacher.emp_first_name +
                                        " " +
                                        teacher.emp_middle_name +
                                        " " +
                                        teacher.emp_last_name}
                                    </b>
                                    <span>{teacher.emp_id}</span>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <button
                                className="assign-class-teacher__assign"
                                onClick={() => {
                                  setPerSubjectTeacherList(
                                    !perSubjectTeacherList
                                  );
                                  setSubjectId(subj.subj_master_id);
                                  dispatch({
                                    type: payloadTypes.SET_SUBJECT_ID,
                                    payload: {
                                      subjectId: subj.subj_master_id,
                                    },
                                  });
                                }}
                              >
                                Assign
                                <img src={Assign} alt="" />
                              </button>
                            )}
                          </TableCell>
                          <TableCell
                            className="assign-class-teacher__table--actions"
                            id="td-center"
                          >
                            {subj.Teachers.length ? (
                              <TooltipForMultipleOption
                                
                                placement="left-start"
                                title={
                                  <>
                                    <ul>
                                      <li
                                        className="studentlist__table--more--interchange"
                                        onClick={() => {
                                          setSubjectDetails({
                                            subj_code: subj.subj_code,
                                            subj_id: subj.subj_master_id!,
                                            subj_name: subj.subj_desc,
                                          });
                                          setView(!view);
                                        }}
                                      >
                                        <img src={Replace} alt="/" />
                                        &nbsp;
                                        <span>Update</span>
                                      </li>
                                    </ul>
                                  </>
                                }
                              >
                                <img src={More} alt="/" />
                              </TooltipForMultipleOption>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : []}

                <TableRow className="assign-class-teacher__table--open-elective">
                  <TableCell colSpan={7}>Open Elective</TableCell>
                </TableRow>
                {Tdata
                  ? Tdata.GetAcdTeacherSubjectClassAssociations.filter(
                      (data) =>
                        data.elective_subj_desc &&
                        data.elective_subj_desc.length > 0
                    ).map((subj, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            className="assign-class-teacher__table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--code">
                            {subj.subj_code}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--code">
                            {subj.subj_board_code}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--subject">
                            {`${subj.subj_desc}   (${subj.elective_subj_desc})`}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--type">
                            {subj.subj_is_core_subject ? "Core" : "Elective"}
                          </TableCell>
                          <TableCell className="assign-class-teacher__table--teacher">
                            {subj.Teachers.length ? (
                              subj.Teachers.map((teacher, teacherIndex) => (
                                <div key={teacherIndex}>
                                  <img src={Avatar} alt="" />
                                  <div>
                                    <b>
                                      {teacher.emp_first_name +
                                        " " +
                                        teacher.emp_middle_name +
                                        " " +
                                        teacher.emp_last_name}
                                    </b>
                                    <span>{teacher.emp_id}</span>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <button
                                className="assign-class-teacher__assign"
                                onClick={() => {
                                  setPerSubjectTeacherList(
                                    !perSubjectTeacherList
                                  );
                                  setSubjectId(subj.subj_master_id);
                                  dispatch({
                                    type: payloadTypes.SET_SUBJECT_ID,
                                    payload: {
                                      subjectId: subj.subj_master_id,
                                    },
                                  });
                                }}
                              >
                                Assign
                                <img src={Assign} alt="" />
                              </button>
                            )}
                          </TableCell>
                          <TableCell
                            className="assign-class-teacher__table--actions"
                            id="td-center"
                          >
                            {subj.Teachers.length ? (
                              <TooltipForMultipleOption
                                
                                placement="left-start"
                                title={
                                  <>
                                    <ul>
                                      <li
                                        className="studentlist__table--more--interchange"
                                        onClick={() => {
                                          setSubjectDetails({
                                            subj_code: subj.subj_code,
                                            subj_id: subj.subj_master_id!,
                                            subj_name: subj.subj_desc,
                                          });
                                          setView(!view);
                                        }}
                                      >
                                        <img src={Replace} alt="/" />
                                        &nbsp;
                                        <span>Update</span>
                                      </li>
                                    </ul>
                                  </>
                                }
                              >
                                <img src={More} alt="/" />
                              </TooltipForMultipleOption>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : []}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <AllocateTeachersDrawer
    
        anchor="right"
        open={perSubjectTeacherList}
        onClose={() => setPerSubjectTeacherList(!perSubjectTeacherList)}
      >
        <TeachersList
          subjectId={subjectId}
          updateOperation={Operation.CREATE}
          setPerSubjectTeacherList={setPerSubjectTeacherList}
        />
      </AllocateTeachersDrawer>
      <AllocateTeachersDrawer
   
        anchor="right"
        open={classTeacherModal}
        onClose={() => setClassTeacherModal(!classTeacherModal)}
      >
        <ClassTeachersList setClassTeacherModal={setClassTeacherModal} />
      </AllocateTeachersDrawer>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={view}
        style={ViewDetails}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View setModal={setView} subjectDetails={subjectDetails} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setView(!view)} />
          </div>
        </div>
      </Modal>
      <DeleteModal
        id={(classTeacherLabel && classTeacherLabel[0]?.pr_emp_id) ?? 0}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;

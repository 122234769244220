import { Borders } from "exceljs";
import ExcelJS from "exceljs";

export const AUTHOR_NAME = "author_name";
export const DONOR_NAME = "donor_name";

export const MEDIA_DESC = "media_desc";
// const For Excel Sheets

export const EDUATE_IMG_FORMAT = "png";

export const FIRST_INDEX = "@";
export const TWO = "2";
export const NEW_SHEET = "Sheet1";

//CONSTS for Excel Sheets
export const FILENAME = "filename";
export const PATTERN = "pattern";
export const SOLID = "solid";
export const FIRST_CELL = "A1";
export const A2_CELL = "A2";
export const A3_CELL = "A3";
export const A4_CELL = "A4";
export const A5_CELL = "A5";
export const A6_CELL = "A6";
export const F4_CELL = "F4";
export const K4_CELL = "K4";
export const M4_CELL = "M4";
export const C4_CELL = "C4";
export const E4_CELL = "E4";
export const D4_CELL = "D4";
export const G4_CELL = "G4";
export const B4_CELL = "B4";
export const H4_CELL = "H4";
export const I4_CELL = "I4";
export const E5_CELL = "E5";
export const E_CELL = "E";
export const A_CELL = "A";
export const D_CELL = "D";
export const TOTAL = "Total :";
export const ELEMENT = "a";
export const DOWNLOAD = "download";
export const ACC_HEADER_FONT = {
  name: "Century Gothic",
  size: 10,
  bold: true,
  color: { argb: "FFFFFFFF" },
};
export const ERROR_FONT = {
  name: "Century Gothic",
  size: 15,
  bold: true,
  color: { argb: "d61406" },
};
export const LESS_INCOME = "LESS INCOME OVER EXPENDITURE";
export const EXCESS_INCOME = "EXCESS OF INCOME OVER EXPENDITURE";
export const BLOB_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const BORDER_DATA: Partial<Borders> = {
  top: { style: "thin", color: { argb: "FF000000" } },
  left: { style: "thin", color: { argb: "FF000000" } },
  bottom: { style: "thin", color: { argb: "FF000000" } },
  right: { style: "thin", color: { argb: "FF000000" } },
};
export const ERROR_BORDER_DATA: Partial<Borders> = {
  top: { style: "thin", color: { argb: "d61406" } },
  left: { style: "thin", color: { argb: "d61406" } },
  bottom: { style: "thin", color: { argb: "d61406" } },
  right: { style: "thin", color: { argb: "d61406" } },
};

export const FOOTER_CSS: ExcelJS.ConditionalFormattingRule[] = [
  {
    type: "expression",
    priority: 1,
    formulae: ["TRUE"],
    style: {
      font: { bold: true, color: { argb: "FFFFFFFF" } },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "059669" },
      },
    },
  },
];
export const FOOTER_CSS_RED: ExcelJS.ConditionalFormattingRule[] = [
  {
    type: "expression",
    priority: 1,
    formulae: ["TRUE"],
    style: {
      font: { bold: true, color: { argb: "FFFFFFFF" } },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFCC" }, // Light red color
        bgColor: { argb: "FFFF4040" },
      },
    },
  },
];

export const FROZEN_CELLS: Partial<ExcelJS.WorksheetView>[] = [
  {
    state: "frozen",
    xSplit: 0,
    ySplit: 5,
    activeCell: "A6",
  },
];
export const FROZEN_CELLS_INST: Partial<ExcelJS.WorksheetView>[] = [
  {
    state: "frozen",
    xSplit: 0,
    ySplit: 4,
    activeCell: "A5",
  },
];
export const FROZEN_CELLS_1: Partial<ExcelJS.WorksheetView>[] = [
  {
    state: "frozen",
    xSplit: 0,
    ySplit: 1,
    activeCell: "A1",
  },
];
export const FROZEN_CELLS_EMP_ATTENDANCE: Partial<ExcelJS.WorksheetView>[] = [
  {
    state: "frozen",
    xSplit: 2,
    ySplit: 5,
    activeCell: "C6",
  },
];
export const HEADER_CSS: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "e5e7eb" },
};
export const HEADER_ALIGNMENT: Partial<ExcelJS.Alignment> = {
  horizontal: "center",
  vertical: "bottom",
};
export const ADDRESS_ALIGNMENT: Partial<ExcelJS.Alignment> = {
  horizontal: "center",
  vertical: "top",
};
export const TABLE_HEADER_CSS: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "334155" },
};
export const TABLE_HEADER_CSS2: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "B2DFEE" },
};
export const TABLE_HEADER_CSS3: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "D4E4F7" },
};
export const TABLE_CSS: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "D9D9D9" },
};
export const FILE_NAME_CSS: ExcelJS.Fill = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "d6e9b7" },
};
export const FILE_NAME_FONT = {
  name: "Arial Rounded MT Bold",
  size: 14,
};

export const TABLE_HEADER_CSS4: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "F2F2F2" },
};
export const ADDRESS_FONT = {
  name: "Arial Rounded MT Bold",
  size: 11,
};
export const FIN_YEAR_FONT = {
  name: "Arial Rounded MT Bold",
  size: 11,
};
export const HEADER_ALIGNMENT_LEFT: Partial<ExcelJS.Alignment> = {
  horizontal: "left",
  vertical: "middle",
};
export const HEADER_ALIGNMENT_RIGHT: Partial<ExcelJS.Alignment> = {
  horizontal: "right",
  vertical: "middle",
};
export const HEADER_ALIGNMENT_CENTER: Partial<ExcelJS.Alignment> = {
  horizontal: "center",
  vertical: "middle",
};

export const RP_TABLE_HEADER = {
  name: "Century Gothic",
  size: 13,
  bold: true,
  color: { argb: "000000" },
};
export const GROUP_LDGR_FONT = {
  name: "Century Gothic",
  size: 10,
  bold: true,
  color: { argb: "000000" },
};
export const INCOME_FONT = {
  name: "Century Gothic",
  size: 13,
  bold: true,
  color: { argb: "000000" },
};
export const LEDGER_WISE_FONT = {
  name: "Bookman Old Style",
  size: 9,
  bold: true,
};
export const BALANCE_FONT = {
  name: "Courier New",
  size: 9,
  bold: true,
};
export const BALANCE_FONT_BOLD = {
  name: "Courier New",
  size: 9,
};
export const RIGHT_ALIGNMENT: Partial<ExcelJS.Alignment> = {
  horizontal: "right",
};

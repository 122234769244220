import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import New from "./New";
const { VMS_Table } = require("../../../json/table.json");

const Index = () => {
  const navigate = useNavigate();
  const [addModal, setAddModal] = useState(false);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Fuel Purchase</Title>
      <div className="vms-fuel-purchase">
        <div className="vms-fuel-purchase__select row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="addnew"
              className="vms-fuel-purchase__select--addnew"
              onClick={() => setAddModal(!addModal)}
            />
          </div>
        </div>
        <div className="vms-fuel-purchase__tableblock">
          <TableContainer className="vms-fuel-purchase__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {VMS_Table.Supplier_Details.FuelPurchase.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="vms-fuel-purchase__table--slno"
                    id="td-center">
                    1
                  </TableCell>
                  <TableCell className="vms-fuel-purchase__table--number"></TableCell>
                  <TableCell className="vms-fuel-purchase__table--number"></TableCell>
                  <TableCell></TableCell>
                  <TableCell className="vms-fuel-purchase__table--number"></TableCell>
                  <TableCell className="vms-fuel-purchase__table--actions"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal isOpen={addModal} ariaHideApp={false} style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <New setModalFlag={setAddModal} />
          </div>
          <div className="modal-flex__image h-100"></div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

import React, { useContext, useEffect, useState } from "react";
import { ChannelQueryType } from "../../../Accounts/common/Enum.types";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { Checkbox } from "@mui/material";
import SendMessage from "./SendMessage";
import Modal from "react-modal";
import { msgType } from "../../../../utils/Form.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  emptyMessageType,
} from "../../../../utils/constants";
import {
  AccountingLedgerModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import Avatar from "../../../../images/Avatar.svg";
import Send from "../../../../images/Send.svg";
import ForIndividual from "./ForIndividual";

import {
  Direction,
  Operation,
  SortBy,
  StudentReportType,
} from "../../../../utils/Enum.types";
import useChannelDetails from "../../../../customhooks/useChannelDetails";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useInstDetails from "../../../../customhooks/general/useInstDetails";

import axios from "axios";
import { useLazyQuery } from "@apollo/client";

import useToken from "../../../../customhooks/useToken";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import { GetAcdStudentsNameAndId } from "../../queries/students/Index";
import {
  AcdStudentsForAdmissionvars,
  GetStudentAcdForAdmissionData,
  StudentEdges,
} from "../../hooks/useAcdStudentsForAdmission";
import { useParams } from "react-router-dom";

const StudentList = () => {
  const [selectedStudents, setSelectedStudents] = useState<
    { id: number; name: string }[]
  >([]);

  const { InstId } = useParams();
  const [enableCheckbox, setEnableCheckbox] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [studentPics, setStudentPics] = useState<Map<number, string>>(
    new Map()
  );

  const { token } = useToken();
  const { state, dispatch } = useContext(AppContext);

  const { InstDetails } = useInstDetails(1);
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const { channelDetails } = useChannelDetails([
    ChannelQueryType.ANNOUNCEMENTS,
    ChannelQueryType.ENTRYLEVEL,
    ChannelQueryType.INDIVIDUAL,
  ]);
  const channelid =
    channelDetails.data && channelDetails.data.GetChannelSubscribed.length
      ? channelDetails.data.GetChannelSubscribed[0].id
      : 0;
  const [individual, setIndividual] = useState(false);
  const [search, setSearch] = useState("");

  const [students, setStudents] = useState<StudentEdges[]>([]);

  const [GetStudents, { data, loading, fetchMore }] = useLazyQuery<
    GetStudentAcdForAdmissionData,
    AcdStudentsForAdmissionvars
  >(GetAcdStudentsNameAndId);

  const getStudentPics = (id: number, pic_name: string) => {
    const temp = studentPics;
    const filename =
      InstDetails.data && InstDetails.data.nodes.length
        ? `${InstDetails.data.nodes[0].inst_name}/students/${id}/std_profile_pic/std_profile_pic`
        : "";

    if (pic_name) {
      const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${filename}&access_type=${process.env.React_App_access_type}`;

      axios
        .post(downloadBaseUrl, null, {
          headers: {
            "Content-Type":
              "application/x-www-form-urlencoded; charset=UTF-8;application/json",
          },
        })
        .then((response) => {
          axios
            .get(response.data.split("?")[0], { responseType: "blob" })
            .then((fileData) => {
              temp.set(id, response.data);
            });
        })
        .catch((err) => console.log(err));
    }
  };
  const ProfilePic = ({ id, pic_name }: { id: number; pic_name: string }) => {
    if (pic_name) {
      const ers = studentPics.get(id);
      return <img src={ers ? ers : Avatar} alt="/" />;
    } else return <img src={Avatar} alt="/" />;
  };

  const handleCheckboxChange = (studentId: number, studentName: string) => {
    setSelectedStudents((prevSelectedStudents) => {
      const isSelected = prevSelectedStudents.some(
        (student) => student.id === studentId
      );
      if (isSelected) {
        // Unselect the student if already selected
        return prevSelectedStudents.filter(
          (student) => student.id !== studentId
        );
      } else {
        // Select the student if not already selected
        return [...prevSelectedStudents, { id: studentId, name: studentName }];
      }
    });
  };

  useEffect(() => {
    if (token && state.ActiveAcdYr) {
      GetStudents({
        variables: {
          after: null,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          first: 15,
          input: {
            ids: [Number(InstId), Number(channelid)],
            acd_std_query_type: StudentReportType.STDS_BY_CHANNEL_ID,
            str_data: [],
            int_data: [],
          },
          name: search,
          orderBy: [
            {
              direction: Direction.ASC,
              field: SortBy.FIRST_NAME,
            },
          ],
          status: EMPTY_STRING,
          token,
        },
      });
    }
  }, [
    token,
    GetStudents,
    channelid,
    state.ActiveFinYr,
    search,
    InstId,
    search,
  ]);
  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcdStudents.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [loading, data]);

  return (
    <>
      <div className="academics__channel--datablock--right--list">
        <div className="academics__channel--datablock--right--list--header">
          <span> Student List</span>
          <b>{data ? data.GetAcdStudents.totalCount : 0}</b>
        </div>
        <Input
          id="search"
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
        />
        {enableCheckbox ? (
          <div className="academics__channel--datablock--right--list--subheader">
            <b>Selected Students</b>
            <span>{selectedStudents.length}</span>
          </div>
        ) : null}
        <ul
          className={
            enableCheckbox
              ? "academics__channel--datablock--right--list--ul"
              : "academics__channel--datablock--right--list--sel"
          }
          onScroll={handleScroll}
        >
          {data
            ? students.map((res, index) => {
                const isChecked = selectedStudents.some(
                  (student) => student.id === res.node.id
                );
                const studentName = `${res.node.first_name} ${res.node.middle_name} ${res.node.last_name}`;

                return (
                  <li key={index}>
                    <div className="academics__channel--datablock--right--list--flex">
                      {enableCheckbox ? (
                        <Checkbox
                          checked={isChecked}
                          onChange={() =>
                            handleCheckboxChange(res.node.id, studentName)
                          }
                        />
                      ) : null}
                      <ProfilePic
                        id={res.node.id}
                        pic_name={res.node.std_profile_filename}
                      />
                      {index + 1}.<span>{studentName}</span>
                    </div>
                    {!enableCheckbox && (
                      <img
                        src={Send}
                        onClick={() => {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: {
                              studentId: res.node.id,
                            },
                          });
                          setIndividual(!individual);
                        }}
                        alt="/"
                        className="academics__channel--datablock--right--list--send"
                      />
                    )}
                  </li>
                );
              })
            : null}
        </ul>

        {data ? (
          !enableCheckbox ? (
            <Button
              mode="selected"
              onClick={() => setEnableCheckbox(!enableCheckbox)}
            />
          ) : (
            <>
              <Button
                mode="message"
                onClick={() => setSendMessage(!sendMessage)}
              />
              <Button
                mode="cancel"
                onClick={() => {
                  setSelectedStudents([]);
                  setEnableCheckbox(!enableCheckbox);
                }}
              />
            </>
          )
        ) : null}
      </div>
      <Modal
        isOpen={sendMessage}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <SendMessage
          setModalFlag={setSendMessage}
          Channel={ChannelQueryType.MULTIPLE}
          setMessage={setMessage}
          selectedStudents={selectedStudents!}
          setSelectedStudents={setSelectedStudents}
          operation={Operation.CREATE}
        />
      </Modal>
      <Modal
        isOpen={individual}
        ariaHideApp={false}
        style={AccountingLedgerModalStyles}
      >
        <ForIndividual setModal={setIndividual} />
      </Modal>
    </>
  );
};

export default StudentList;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { GetPayRollAcdYrsByInstId } from "../queries/academicyear/query";
import { AppContext } from "../../../context/context";
export interface PayRollAcademicYearList {
  id: number;
  payroll_yr: string;
  payroll_st_date: string;
  payroll_end_date: string;
  payroll_is_curr_yr: boolean;
  inst_id: number;
}
export interface PayRollAcademicYearListData {
  GetPayRollAcdYrsByInstId: PayRollAcademicYearList[];
}

export interface PayRollAcademicYearListVars {
  inst_id: string | number;
  token: string;
}
const usePayRollAcademicYear = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [GetPayRollAcdYear, { data, loading, error }] = useLazyQuery<
    PayRollAcademicYearListData,
    PayRollAcademicYearListVars
  >(GetPayRollAcdYrsByInstId, {
    variables: { inst_id: InstId! || state.InstId!, token },
  });

  useEffect(() => {
    if (token) {
      GetPayRollAcdYear().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetPayRollAcdYrsByInstId.map((acdYear) => ({
              label: acdYear.payroll_yr,
              value: acdYear.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [token, InstId, data, GetPayRollAcdYear]);
  return {
    payRollAcademicYearResponse: { data, loading, error, responseType },
  };
};

export default usePayRollAcademicYear;

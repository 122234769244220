import { gql } from "@apollo/client";

export const GetOwners = gql`
  query GetOwners($token: String!, $cust_id: ID!) {
    GetOwners(token: $token, cust_id: $cust_id) {
      id
      owner_name
      owner_mobile
      owner_email
    }
  }
`;
export const CustOwnerDetialsByNode = gql`
  query CustOwnerDetialsByNode($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MstCustOwner {
        id
        owner_name
        owner_name
        owner_mobile
        owner_email
      }
    }
  }
`;
export const GetOwnerDetailsByAuthId = gql`query GetOwnerDetailsByAuthId($token: String!, $auth_id: ID!) {
  GetOwnerDetailsByAuthId(token: $token, auth_id: $auth_id) {
        id
    owner_name
    owner_mobile
    owner_email
    mst_customer_id
    auth_id
  }
}`;

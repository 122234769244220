import { useContext, useEffect } from "react";
import { GetComboByNodeId } from "../../../../queries/customallocation/combination/queries";
import { singleNodeVars } from "../../../../Types/Accounting";
import { GetAcdSubjectCombosByNodeIdData } from "../../../../Types/Combinations/queries";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
const useCombinationByNodeId = () => {
  const { token } = useToken();
  const { combinationId } = useParams();
  const { state } = useContext(AppContext);
  const [GetAcdCombo, { data, loading, error }] = useLazyQuery<
    GetAcdSubjectCombosByNodeIdData,
    singleNodeVars
  >(GetComboByNodeId, {
    variables: {
      token,
      id: combinationId ? Number(combinationId) : state.acdCombinationId,
    },
  });

  useEffect(() => {
    if (token && state.acdCombinationId) {
      GetAcdCombo();
    }
  }, [GetAcdCombo, token, state.acdCombinationId]);
  return { data, loading, error };
};

export default useCombinationByNodeId;

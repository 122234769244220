import React, { forwardRef, useEffect, useRef, useState } from "react";
import QRCode from "qrcode.react";
import { Title } from "../stories/Title/Title";
import ReactToPrint from "react-to-print";
import { Button } from "../stories/Button/Button";
import Modal from "react-modal";
import { QRCodeGeneratorConfig } from "../styles/ModalStyles";
import Close from "../images/Close.svg";
import { useLazyQuery } from "@apollo/client";
import { InstDetails } from "../Modules/Enquiry/EnquiryByQrCode";
import { GetInstByID } from "../queries/institution/list/byId";
import { useParams } from "react-router";
import { getDownloadUrl } from "./DownloadFile";
import EduateImage from "../images/EduateLogo.svg";
import EnquiryConfigurations from "../Modules/Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../Modules/HR/enums/Enum.types";
import { ModuleName, PageFor } from "./Enum.types";
import { StudentEnquiryFormConfig } from "../Modules/Print/Enum.types";
import useSwConfigData from "../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../Types/configtypes";

interface Props {
  url: string;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
interface PrintProps {
  headerValue: string;
  footer1Value: string;
  footer2Value: string;
  data: InstDetails;
  imageString: string;
}
const ComponentToPrint = forwardRef<HTMLDivElement, Props & PrintProps>(
  (
    { url, headerValue, footer1Value, footer2Value, imageString, data },
    ref
  ) => {
    return (
      <>
        <style type="text/css" media="print" scoped>
          {`
            @page a4 {
          width:100%;
          height:100%;
            }
            body {
              padding: 20px 50px;
            }
  .std-enquiry-print__url{
width:535px !important;
height:535px !important;
  }

          `}
        </style>
        <div ref={ref} className="std-enquiry-print">
          <div className="std-enquiry-print__inst-details">
            <div className="std-enquiry-print__inst-details--logo">
              {imageString && <img src={imageString} alt="" />}
            </div>
            <div className="std-enquiry-print__inst-details--address">
              <span className="std-enquiry-print__inst-details--c-name">
                {data?.GetInstByID.customer_details.cust_name}
              </span>
              <b>{data?.GetInstByID.inst_name}</b>
              <span>{data?.GetInstByID.inst_address}</span>
            </div>
          </div>
          <Title>{headerValue}</Title>
          <div className="std-enquiry-print__qrcode">
            {url && <QRCode value={url} className="std-enquiry-print__url" />}
            <span className="std-enquiry-print__url--footer1">
              {footer1Value}
            </span>
            <span className="std-enquiry-print__url--footer2">
              {footer2Value}
            </span>
          </div>

          <div className="std-enquiry-print__footer">
            <img src={EduateImage} alt="" />
            Simplified With My-Eduate
          </div>
        </div>
      </>
    );
  }
);

const QRCodeGenerator = ({ url, setModalFlag }: Props) => {
  const { InstId } = useParams();
  const componentRef = useRef<HTMLDivElement>(null);
  const [modal, setModal] = useState(false);
  const [imageString, setImageString] = useState("");

  const [GetInstDetails, { data, loading }] = useLazyQuery<InstDetails>(
    GetInstByID,
    {
      variables: {
        id: InstId,
      },
    }
  );
  const { configData: configKeys } = useSwConfigData([
    StudentEnquiryFormConfig.ENQ_PRINT_QR_HEADER,
    StudentEnquiryFormConfig.ENQ_PRINT_QR_FOOTER_1,
    StudentEnquiryFormConfig.ENQ_PRINT_QR_FOOTER_2,
    StudentEnquiryFormConfig.ENQ_PRINT_QR_THANK_YOU_MSG,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let headerValue = "";
    let footer1Value = "";
    let footer2Value = "";

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case StudentEnquiryFormConfig.ENQ_PRINT_QR_HEADER:
            headerValue = item.config_string_value;
            break;
          case StudentEnquiryFormConfig.ENQ_PRINT_QR_FOOTER_1:
            footer1Value = item.config_string_value;
            break;
          case StudentEnquiryFormConfig.ENQ_PRINT_QR_FOOTER_2:
            footer2Value = item.config_string_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      headerValue,
      footer1Value,
      footer2Value,
    };
  };
  const { headerValue, footer1Value, footer2Value } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );

  useEffect(() => {
    if (data && !loading) {
      const filename = data.GetInstByID;
      if (filename) {
        // eslint-disable-next-line
        const ers = getDownloadUrl(
          data?.GetInstByID.inst_logo_filename,
          false,
          setImageString
        );
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (InstId) {
      GetInstDetails();
    }
  }, [InstId, GetInstDetails]);
  return (
    <>
      <ComponentToPrint
        url={url}
        ref={componentRef}
        headerValue={headerValue}
        footer1Value={footer1Value}
        data={data!}
        footer2Value={footer2Value}
        imageString={imageString}
        setModalFlag={setModalFlag}
      />

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
      <Button mode="configure" onClick={() => setModal(!modal)} />
      <Button mode="cancel" onClick={() => setModalFlag(false)} />

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={modal}
        ariaHideApp={false}
        style={QRCodeGeneratorConfig}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1">Update Your Details</Title>
            <div className="std-enquiry-print__config">
              <EnquiryConfigurations
                config_query_type={SwConfigQueryType.INST_BY_MODULE}
                str_value={ModuleName.ENQUIRY}
                int_value={0}
                setModalFlag={setModal}
                pageType={PageFor.MODAL}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setModal(!modal)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QRCodeGenerator;

import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { VouchernodeData, nodevars } from "../../../../../Types/Accounting";
import { AccountVoucherDetailsByMasterVoucherId } from "../../../../../queries/common";
import {
  BankOrCash,
  DebitOrCredit,
  FileUploadParams,
  ReceiptTypes,
  VoucherBookKey,
  transactionTypeEnum,
} from "../../../../../utils/Enum.types";
import { AppContext } from "../../../../../context/context";
import useToken from "../../../../../customhooks/useToken";
import {
  formatter,
  toStandardCase,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import EduateLogo from "../../../../../images/EduateLogo.svg";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import USeVoucherReceiptConfig from "../hooks/UseReceiptConfig";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import { voucherMasterDetails } from "../../../../Accounts/hooks/useAcctVoucherDetailsByDates";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { VoucherTypes } from "../../../Enum.types";
import { ToWords } from "to-words";

const Template1 = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { format } = formatter;
  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});
  const { InstFormData } = useInstDetails(1);
  const toWords = new ToWords();
  const [paymentVoucherDetails, setPaymentVoucherDetails] =
    useState<voucherMasterDetails>();
  const [GetPaymentVoucherDetails] = useLazyQuery<VouchernodeData, nodevars>(
    AccountVoucherDetailsByMasterVoucherId
  );
  let totalDebitAmount = 0;
  let totalCreditAmount = 0;
  // eslint-disable-next-line
  totalCreditAmount = paymentVoucherDetails?.acct_voucher_details.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.CREDIT
  )[0]?.vo_cr_total!;
  // eslint-disable-next-line
  totalDebitAmount = paymentVoucherDetails?.acct_voucher_details.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.DEBIT
  )[0]?.vo_db_total!;
  useEffect(() => {
    if (state.receiptId) {
      GetPaymentVoucherDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data) {
          setPaymentVoucherDetails(data?.nodes[0]);
        }
      });
    } // eslint-disable-next-line
  }, [GetPaymentVoucherDetails, state.receiptId, token]);

  const {
    USE_LOGO,
    USE_INST_NAME,
    USE_INST_ADDRESS,
    USE_VOUCHER_TYPE,
    USE_VOUCHERS_APPROVED_BY,
    USE_VOUCHERS_SIGNED_BY,
    USE_CREATED_TIME,
  } = USeVoucherReceiptConfig();

  return (
    <>
      <div className="voucher-print">
        <div className="voucher-print__block">
          <div className="voucher-print__block--inst-data">
            <div className={"voucher-print__block--inst-data--details"}>
              {USE_LOGO && (
                <div className="voucher-print__block--inst-data--logo">
                  {LogoOrSign.defaultLogo ? (
                    <img src={LogoOrSign.defaultLogo} alt="/" />
                  ) : (
                    <img src={EduateLogo} alt="/" />
                  )}
                </div>
              )}

              <div className="voucher-print__block--inst-data--details-block">
                {USE_INST_NAME && (
                  <b className="voucher-print__block--inst-data--inst-name">
                    {InstFormData.inst_name}
                  </b>
                )}

                {USE_INST_ADDRESS && (
                  <span className="voucher-print__block--inst-data--inst-address">
                    {[
                      InstFormData.inst_address,
                      InstFormData.inst_place,
                      InstFormData.inst_pin,
                    ]
                      .filter(Boolean)
                      .join(", ")
                      .trim()}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="voucher-print__block--v-details">
            <div className="voucher-print__block--v-details--rcptno">
              <span>No.: </span>
              <b>{paymentVoucherDetails?.v_no}</b>
            </div>
            <div className="voucher-print__block--v-details--vtype">
              {USE_VOUCHER_TYPE && (
                <span className="voucher-print__block--inst-data--voucher-type">
                  {paymentVoucherDetails?.v_type === ReceiptTypes.RCPT
                    ? VoucherTypes.R
                    : paymentVoucherDetails?.v_type === ReceiptTypes.CONTRA
                    ? VoucherTypes.C
                    : paymentVoucherDetails?.v_type === ReceiptTypes.JOURNAL
                    ? VoucherTypes.J
                    : VoucherTypes.P}
                </span>
              )}
            </div>

            <div className="voucher-print__block--v-details--date">
              <span>Date: </span>
              <b>{toStandardDate(paymentVoucherDetails?.v_date!)}</b>
            </div>
          </div>
          <div className="voucher-print__block--payment-info">
            {paymentVoucherDetails?.v_type === ReceiptTypes.PYMT ||
            (paymentVoucherDetails?.v_type === ReceiptTypes.STUDENT_RECEIPT &&
              paymentVoucherDetails.v_book_type ===
                VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK) ? (
              <p>
                Pay to
                <span className="voucher-print__block--payment-info--uppercase">
                  &nbsp;
                  {paymentVoucherDetails?.party_name}
                </span>
                &nbsp;a sum of Rs.&nbsp;
                <span className="voucher-print__amount">
                  {format(paymentVoucherDetails?.v_total_cr_amt!)}
                </span>
                &nbsp;
                <span className="voucher-print__block--payment-info--capitalize">
                  (
                  {toWords.convert(paymentVoucherDetails?.v_total_cr_amt!, {
                    currency: true,
                  })}
                  ) &nbsp; towards&nbsp;
                </span>
                <span className="voucher-print__block--payment-info--uppercase">
                  being&nbsp;
                  {
                    paymentVoucherDetails?.acct_voucher_details.filter(
                      ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.DEBIT
                    )[0].acct_ldgr.ldgr_desc
                  }
                </span>
                &nbsp;vide Bill No.
                <span className="voucher-print__amount">
                  {paymentVoucherDetails?.party_bill_no}
                </span>
              </p>
            ) : null}
          </div>
          <div className="voucher-print__tableblock">
            <TableContainer className="voucher-print__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="voucher-print__table--th-slno"></TableCell>
                    <TableCell
                      className="voucher-print__table--th-desc"
                      align="center"
                    >
                      Particulars
                    </TableCell>
                    <TableCell
                      className="voucher-print__table--th-amount"
                      align="right"
                    >
                      Debit
                    </TableCell>
                    <TableCell
                      className="voucher-print__table--th-amount"
                      align="right"
                    >
                      Credit
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paymentVoucherDetails?.acct_voucher_details &&
                    [...paymentVoucherDetails.acct_voucher_details]
                      .sort((a, b) => a.vo_sl_no - b.vo_sl_no)
                      .map((data, index) => (
                        <TableRow key={index}>
                          <TableCell className="voucher-print__table--slno">
                            {data.vo_cr_db === DebitOrCredit.DEBIT
                              ? toStandardCase(transactionTypeEnum.BY)
                              : toStandardCase(transactionTypeEnum.TO)}
                          </TableCell>
                          <TableCell>{data.acct_ldgr.ldgr_desc}</TableCell>
                          <TableCell
                            className="voucher-print__table--amount"
                            id="td-right"
                          >
                            {data.vo_db === 0 ? "" : format(data.vo_db)}
                          </TableCell>
                          <TableCell
                            className="voucher-print__table--amount"
                            id="td-right"
                          >
                            {data.vo_cr === 0 ? "" : format(data.vo_cr)}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} className="total">
                      Total:
                    </TableCell>
                    <TableCell id="td-right">
                      {format(totalDebitAmount)}
                    </TableCell>
                    <TableCell id="td-right">
                      {format(totalCreditAmount)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <div className="voucher-print__block--sender-info">
            <p>
              Received with thanks from
              <span className="voucher-print__block--payment-info--uppercase">
                &nbsp;{InstFormData.inst_name}
              </span>
              &nbsp;{InstFormData.inst_address} {InstFormData.inst_place} -
              {InstFormData.inst_pin} a sum of Rs.
              <span className="voucher-print__amount">
                {format(paymentVoucherDetails?.v_total_db_amt!)}
              </span>
              &nbsp;
              {paymentVoucherDetails?.v_transcation_cash_or_bank ===
              BankOrCash.CASH
                ? `through Cash`
                : `through Cheque vide bearing Cheque No.:${paymentVoucherDetails?.v_transcation_no} dated : 28-03-2023`}
            </p>
          </div>

          <div className="voucher-print__signature">
            <div className="voucher-print__signature--block">
              <b>Approved By</b>
              <span>{USE_VOUCHERS_APPROVED_BY}</span>
            </div>
            <div className="voucher-print__signature--block">
              <b>{USE_VOUCHERS_SIGNED_BY}</b>
              <span></span>
            </div>
            <div className="voucher-print__signature--block">
              <b>Signature of Receiver</b>
              <span></span>
            </div>
          </div>
          <div className="voucher-print__created-date">
            {USE_CREATED_TIME ? (
              <span className="voucher-receipts__block--time">
                {toIsoDate(paymentVoucherDetails?.created_at!)}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Template1;

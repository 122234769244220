import React, { useContext, useEffect, useState } from "react";

import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import {
  GetAcdStdMonthlyAttAbsentDetailsData,
  GetAcdStdMonthlyAttAbsentDetailsVars,
} from "../../../../Academics/types/holidays";
import { GetAcdStdMonthlyAttAbsentDetails } from "../../../../Academics/queries/holidays/list";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../../../context/context";
import useToken from "../../../../../customhooks/useToken";
import useActiveAcademicYear from "../../../../Academics/hooks/useActiveAcademicYear";
import { useParams } from "react-router-dom";
import {
  toStandardDate,
  toStandardMonth,
} from "../../../../../utils/UtilFunctions";
import Close from "../../../../../images/Close.svg";
import Calendar from "../../../../../images/Calender_white.svg";
import DownArrow from "../../../../../images/DownArrow.svg";
import RightArrow from "../../../../../images/ArrowRight.svg";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const AbsentDays = ({ setModalFlag }: Props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { InstId } = useParams();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const [GetAcdStdAbseentDetails, { data: AbsentData }] = useLazyQuery<
    GetAcdStdMonthlyAttAbsentDetailsData,
    GetAcdStdMonthlyAttAbsentDetailsVars
  >(GetAcdStdMonthlyAttAbsentDetails);

  useEffect(() => {
    if (
      token &&
      state.studentId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr &&
      (InstId || state.InstId)
    ) {
      GetAcdStdAbseentDetails({
        variables: {
          token,
          inst_id: state.InstId ? state.InstId : InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          student_id: state.studentId,
          subj_master_id: 0,
        },
      });
    }
  }, [
    token,
    state.studentId,
    GetAcdStdAbseentDetails,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
    InstId,
    state.InstId,
  ]);
  const totalAbsentCount =
    AbsentData &&
    AbsentData?.GetAcdStdMonthlyAttAbsentDetails?.reduce((total, res) => {
      return total + (res.absent_details?.length || 0);
    }, 0);

  return (
    <div className="student-dashboard__attendance--absenties">
      <div className="student-dashboard__attendance--absenties--title">
        <Title>Absent Days</Title>
        <img src={Close} alt="" onClick={() => setModalFlag(false)} />
      </div>
      <div className="student-dashboard__attendance--absenties--total">
        <div className="student-dashboard__attendance--absenties--flex">
          <img src={Calendar} alt="" />
          <span>Total Absent Days</span>
        </div>
        <b className="font-red">{totalAbsentCount}</b>
      </div>

      <div className="student-dashboard__attendance--absenties--data">
        {AbsentData &&
          AbsentData.GetAcdStdMonthlyAttAbsentDetails.map((res, index) => {
            const isExpanded = expandedIndex === index;

            return (
              <React.Fragment key={index}>
                <div
                  className="student-dashboard__attendance--absenties--data--t"
                  onClick={() => handleToggle(index)}
                >
                  <b>{toStandardMonth(res.cal_month)}</b>
                  <div className="student-dashboard__attendance--absenties--flex">
                    <b className="font-red">{res.absent_details.length}</b>
                    {isExpanded ? (
                      <img src={DownArrow} alt="" />
                    ) : (
                      <img src={RightArrow} alt="" />
                    )}
                  </div>
                </div>

                <ul>
                  {isExpanded ? (
                    <>
                      {res.absent_details.map((s, index) => {
                        return (
                          <>
                            <li key={index}>
                              <span>
                                {toStandardDate(s.date_of_attendance)}
                              </span>
                              <span>{s.comments}</span>
                            </li>
                            <hr className="dashed" />
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </ul>
              </React.Fragment>
            );
          })}
      </div>
      <Button mode="cancel" onClick={() => setModalFlag(false)} />
    </div>
  );
};

export default AbsentDays;

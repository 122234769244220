import NavBar from "../../../components/common/Navbar/Index";
import { LicenseTypes } from "../../../utils/Enum.types";
interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  return (
    <>
      <NavBar NavType={LicenseTypes.USER_RIGHTS} />
      {DashBoardRequired && "DashBoard"}
    </>
  );
};

export default Index;

import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import {
  GetDocumentConfigData,
  GetDocumentConfigVars,
  GetInstDocumentVars,
  GetInstDocumentsData,
} from "../Types";
import { GetDocumentConfig, GetInstDocuments } from "../queries";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { documentconfigTypes } from "../../Enum.types";
import { DocumentAcknowledgement } from "../../../../utils/Enum.types";
interface Props {
  selectedId: number;
  selectedStudents: number[];
  pageType: DocumentAcknowledgement;
  setStudentIdSet?: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const useDocumentConfig = ({ selectedId, pageType }: Props) => {
  const { token } = useToken();
  const { InstId, documentId } = useParams();

  const [GetInstDocumentData, { data: DocumentsList }] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments, {
    variables: {
      token,
      inst_id: InstId!,
      custom_doc_name: DocumentAcknowledgement.STD_DOC_ACK,
    },
  });
  useEffect(() => {
    if (token && InstId!) {
      GetInstDocumentData();
    }
    // eslint-disable-next-line
  }, [token, GetInstDocumentData]);

  const customDocID = DocumentsList && DocumentsList.GetInstDocuments[0].id;

  const [GetDocumentConfigDetails, { data }] = useLazyQuery<
    GetDocumentConfigData,
    GetDocumentConfigVars
  >(GetDocumentConfig, {
    variables: {
      token,
      inst_id: InstId!,
      document_main_id:
        pageType === DocumentAcknowledgement.STD_DOC_ACK
          ? Number(customDocID)
          : selectedId
          ? Number(selectedId)
          : Number(documentId),
    },
  });

  useEffect(() => {
    if (token && InstId && documentId) {
      GetDocumentConfigDetails();
    } else if (token && InstId && selectedId) {
      GetDocumentConfigDetails();
    } else {
      if (token && InstId && customDocID) {
        GetDocumentConfigDetails();
      }
    }
  }, [token, InstId, documentId, selectedId, GetDocumentConfigDetails]);

  const titleName =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_SUBJECT
    );
  const academicYear =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_ACADEMIC_YEAR
    );
  const bottomMargin =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_BOTTOM_MARGIN
    );
  const custNameColor =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_CUST_NAME_COLOR
    );
  const custName =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_CUSTOMER_NAME
    );
  const footerHeight =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_FOOTER_HEIGHT
    );
  const footerText =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_FOOTER_TEXT
    );
  const headerBorderColor =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_HEADER_BORDER_COLOR
    );
  const headerHeight =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_HEADER_HEIGHT
    );
  const addrColor =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_INST_ADDR_COLOR
    );
  const instAddress =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_INST_ADDRESS
    );
  const instLogo =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_INST_LOGO
    );
  const instName =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_INST_NAME
    );
  const instNamecolor =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_INST_NAME_COLOR
    );
  const leftMargin =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_LEFT_MARGIN
    );
  const rightMargin =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_RIGHT_MARGIN
    );
  const sign1 =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_SIGNATORY_1
    );
  const sign2 =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_SIGNATORY_2
    );
  const sign3 =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_SIGNATORY_3
    );
  const sign4 =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_SIGNATORY_4
    );
  const sign5 =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_SIGNATORY_5
    );
  const topMargin =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_TOP_MARGIN
    );
  const docDate =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_DATE
    );
  const footerBorderColor =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_FOOTER_BORDER_COLOR
    );
  const instAddressColor =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_INST_ADDR_COLOR
    );

  const studentPhoto =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_PRINT_STD_PHOTO
    );
  const feeCategory =
    data &&
    data.GetDocumentConfig.find(
      (res) => res.config_key === documentconfigTypes.DOC_FEE_CATEGORY
    );
  const minBodyHeight = `calc(100% - ${headerHeight?.config_integer_value}px - ${footerHeight?.config_integer_value}px)`;
  return {
    titleName,
    academicYear,
    bottomMargin,
    custNameColor,
    custName,
    footerHeight,
    footerText,
    headerBorderColor,
    headerHeight,
    addrColor,
    instAddress,
    instLogo,
    instName,
    instNamecolor,
    leftMargin,
    rightMargin,
    sign1,
    sign2,
    sign3,
    sign4,
    sign5,
    topMargin,
    docDate,
    footerBorderColor,
    instAddressColor,
    minBodyHeight,
    studentPhoto,
    feeCategory,
  };
};
export default useDocumentConfig;

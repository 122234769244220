import React, { useState } from "react";
import {  responseType } from "../../../../utils/Form.types";
import {  TextField } from "@mui/material";
import useAcademicYears from "../../hooks/useAcademicYears";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
import { FormAutocomplete, formClasses } from "../../../../styles/AutocompleteStyles";

interface Props {
  valueId: number;
  onChange: (newValue: responseType | null) => void;
  label: string;
}
const AcademicsDropDown = ({ valueId, label, onChange }: Props) => {

  const {
    academicYearResponse: { responseType },
  } = useAcademicYears();

  const foundAcdYrFromValueId =
    responseType.find(({ value }) => value === valueId) ?? null;

    const Autocomplete = label ? LabeledAutocomplete : FormAutocomplete;

  return (
    <Autocomplete
      className={label ? labelClasses.inputRoot : formClasses.inputRoot}
      options={responseType}
      openOnFocus={true}
      value={foundAcdYrFromValueId}
      onChange={(
        e: React.SyntheticEvent<Element, Event>,
        newValue
      ) => onChange(newValue as responseType)}
      isOptionEqualToValue={(option) =>
        isOptionEqualToValue(option as responseType, foundAcdYrFromValueId)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ""}
          slotProps={{
            inputLabel: {
              shrink: label.length > 0
            },
          }}
          className={label? labelClasses.formControlRoot : formClasses.formControlRoot}
        />
      )}
    />
  );
};

export default AcademicsDropDown;

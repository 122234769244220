import { gql } from "@apollo/client";

export const UpdatePredefinedDataById = gql`
  mutation UpdatePredefinedDataById(
    $token: String!
    $id: ID!
    $type: String!
    $index: Int!
    $value1: String
    $value2: String
    $value3: String
    $value4: String
    $user_details: SubscribedUser!
  ) {
    UpdatePredefinedDataById(
      token: $token
      id: $id
      input: {
        type: $type
        index: $index
        value1: $value1
        value2: $value2
        value3: $value3
        value4: $value4
      }
      user_details: $user_details
    ) {
      id
      type
      index
      value1
      value2
      value3
      value4
    }
  }
`;

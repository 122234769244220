import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import useToken from "../../../../../customhooks/useToken";

import { AccountVoucherDetailsByMasterVoucherId } from "../../../../../queries/common";
import { Button } from "../../../../../stories/Button/Button";
import Input from "../../../../../stories/Input/Input";
import { Label } from "../../../../../stories/Label/Label";
import { Title } from "../../../../../stories/Title/Title";
import { VouchernodeData } from "../../../../../Types/Accounting";
import PaymentVoucher from "../../../../../images/PaymentVoucher.svg";
import ReceiptVoucher from "../../../../../images/ReceiptVoucher.svg";
import BanktoBank from "../../../../../images/BanktoBank.svg";
import { TableHeaderProps } from "../../../../../Types/Tables";
import { VoucherTitleProps } from "../../../../../Types/Titles";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../../utils/constants";
import {
  DebitOrCredit,
  Direction,
  ModuleName,
  Operation,
  PageFor,
  SortBy,
  transactionTypeEnum,
  VoucherPageType,
} from "../../../../../utils/Enum.types";
import {
  DateRange,
  formatter,
  getFileIcon,
  NameOfTheDay,
  toInputStandardDate,
  toStandardCase,
} from "../../../../../utils/UtilFunctions";
import { AppContext } from "../../../../../context/context";
import DeleteModal from "../../../../../pages/DeleteModal";
import { GetAcctVouchers } from "../../../queries/Vouchers/query";
import { msgType } from "../../../../../utils/Form.types";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DeleteAcctVoucherMasterAndDetails } from "../../../queries/receipts/mutation/Index";
import LoadingModal from "../../../../../pages/LoadingModal";
// import Voucher from "../../../Voucher/Index";
import MessageModal from "../../../../../pages/MessageModal";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import {
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../../styles/ModalStyles";
import NewVoucher from "../../../Voucher/NewVoucher";
import Close from "../../../../../images/Close.svg";
import { voucherMasterDetails } from "../../../hooks/useAcctVoucherDetailsByDates";
// import View from "../../../../../images/Eye.svg";
import Download from "../../../../../images/DocumentsDownload.svg";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import { TooltipForMultipleOption } from "../../../../../styles/TooltipStyles";
import TextArea from "../../../../../stories/TextArea/TextArea";
import VoucherReceiptPrint from "../../../../Print/Accounts/Vouchers/VoucherPrint";
import Settings from "../../../../../images/Settings.svg";
import PerModuleConfiguration from "../../../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../../../HR/enums/Enum.types";
import useAcctTableJson from "../../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../../hooks/useLoggedInUserDetails";
const { AccountsTitles } = require("../../../json/title.json");

interface nodevars {
  ids: number[];
  token: string;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const PaymentVoucherModal = ({ setModalFlag }: Props) => {
  const { format } = formatter;
  const { state } = useContext(AppContext);
  const { Accounts_Table } = useAcctTableJson();
  const DefaultDate = new Date();
  const { firstDay, lastDay } = DateRange(DefaultDate.toString()) || {};
  const { InstId } = useParams();

  const { token } = useToken();
  const [deleteModal, setDeleteModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [printConfig, setPrintConfig] = useState(false);
  const [imageString, setImageString] = useState("");

  // eslint-disable-next-line
  const [enableEdit, setEnableEdit] = useState(false);
  const [paymentVoucherDetails, setPaymentVoucherDetails] =
    useState<voucherMasterDetails>();
  const { user_details } = useLoggedInUserDetails();

  //TO be impelemented in Future
  // const { acctLedgers: AcctledgersNotBankAndCash } = useAcctLedgerData(
  //   EMPTY_STRING,
  //   AcctLedgerQueryType.EXCEPT_CASH_AND_BANK
  // );
  // const { acctLedgers: paymentLedgers } = useAcctLedgerData(
  //   EMPTY_STRING,
  //   AcctLedgerQueryType.ACCT_LDGRS_BY_INST_ID
  // );

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [GetPaymentVoucherDetails] = useLazyQuery<VouchernodeData, nodevars>(
    AccountVoucherDetailsByMasterVoucherId
  );

  const [DeleteVoucher, { loading: deleteLoading }] = useMutation(
    DeleteAcctVoucherMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    setModalFlag(false);
  };
  const HandleDelete = (id: number) => {
    DeleteVoucher({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            v_no: "",
            deposit: null,
            vTranscationCashOrBank: null,
            partyName: EMPTY_STRING,
            vTranscationNo: EMPTY_STRING,
            vTranscationNarration: EMPTY_STRING,
            ldgrDesc: EMPTY_STRING,
            amount: null,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.BY_DATES,
              vo_end_date: dayjs(lastDay).format(),
              acct_ldgr_id: state.accountLedgerId,
              vo_start_date: dayjs(firstDay).format(),
              vo_type: "",
              student_id: 0,
              user_details: user_details,
            },
          },
        },
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            amount: null,
            deposit: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              acct_ldgr_id: 0,
              inst_id: InstId!,
              student_id: 0,
              user_details: user_details,
              vo_end_date: dayjs(lastDay).format(),
              vo_start_date: dayjs(firstDay).format(),
              vo_type: "",
              voucher_query_type: VoucherQueryTypes.PAYMENT_VOUCHERS,
            },
            ldgrDesc: EMPTY_STRING,
            partyName: EMPTY_STRING,
            sortBy: SortBy.V_DATE,
            token,
            vTranscationCashOrBank: null,
            vTranscationNarration: EMPTY_STRING,
            vTranscationNo: EMPTY_STRING,
            v_no: "",
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  let totalDebitAmount = 0;
  let totalCreditAmount = 0;
  // eslint-disable-next-line
  totalCreditAmount = paymentVoucherDetails?.acct_voucher_details.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.CREDIT
  )[0]?.vo_cr_total!;
  totalDebitAmount = paymentVoucherDetails?.acct_voucher_details.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.DEBIT
  )[0]?.vo_db_total!;

  useEffect(() => {
    if (state.receiptId) {
      GetPaymentVoucherDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data) {
          setPaymentVoucherDetails(data?.nodes[0]);
        }
      });
    } // eslint-disable-next-line
  }, [GetPaymentVoucherDetails, state.receiptId, enableEdit, token]);
  const fileNameArray = paymentVoucherDetails
    ? paymentVoucherDetails.payment_invoice_filename.split("/")
    : [];
  const fileName = fileNameArray[fileNameArray.length - 1];
  useEffect(() => {
    if (
      paymentVoucherDetails &&
      paymentVoucherDetails.payment_invoice_filename !== EMPTY_STRING
    ) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        paymentVoucherDetails?.payment_invoice_filename!,
        false,
        setImageString
      );
    }
  }, [paymentVoucherDetails?.payment_invoice_filename, paymentVoucherDetails]);
  
  return (
    <>
      <Title>
        {paymentVoucherDetails?.v_type === VoucherPageType.PAYMENT_VOUCHER ? (
          <img
            src={PaymentVoucher}
            alt="/"
            className="payment-voucher__image"
          />
        ) : paymentVoucherDetails?.v_type ===
          VoucherPageType.RECEIPT_VOUCHER ? (
          <img
            src={ReceiptVoucher}
            alt="/"
            className="payment-voucher__image"
          />
        ) : (
          <img src={BanktoBank} alt="/" className="payment-voucher__image" />
        )}
        {AccountsTitles.Voucher.Titles.map(
          (title: VoucherTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {paymentVoucherDetails?.v_type ===
                VoucherPageType.PAYMENT_VOUCHER
                  ? title.Payment_Voucher
                  : paymentVoucherDetails?.v_type ===
                    VoucherPageType.RECEIPT_VOUCHER
                  ? title.Receipt_Voucher
                  : paymentVoucherDetails?.v_type ===
                    VoucherPageType.CONTRA_VOUCHER
                  ? title.Contra_Voucher
                  : title.Journal_Voucher}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="payment-voucher-modal">
        <div className="row g-0 payment-voucher-modal__block">
          <div className="col-3 payment-voucher-modal__block--payment-number">
            <Label>Receipt No.</Label>
            <Input
              disabled
              type="text"
              value={paymentVoucherDetails?.v_no ?? EMPTY_STRING}
            />
          </div>
          <div className="col"></div>
          <div className="col-4 payment-voucher-modal__block--date">
            <div className="label-grid">
              <Label>Date</Label>
              <Input
                type="date"
                value={
                  toInputStandardDate(paymentVoucherDetails?.v_date!) ??
                  EMPTY_STRING
                }
                disabled
              />
            </div>
            <div className="label-grid">
              <Label>Day</Label>
              <Input
                value={
                  NameOfTheDay(paymentVoucherDetails?.v_date!) ?? EMPTY_STRING
                }
                disabled
              />
            </div>
          </div>
        </div>
        <div className="payment-voucher-modal__tableblock">
          <TableContainer className="payment-voucher__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.PaymentVoucher.Table_Headers.filter(
                    (data: TableHeaderProps) => data.labelName !== "Actions"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell className={th.className} key={index}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {paymentVoucherDetails?.acct_voucher_details &&
                  [...paymentVoucherDetails.acct_voucher_details]
                    .sort((a, b) => a.vo_sl_no - b.vo_sl_no)
                    .map((data, index) => (
                      <TableRow key={index}>
                        <TableCell
                          className="payment-voucher__table--select"
                          id="td-center"
                        >
                          {data.vo_cr_db === DebitOrCredit.DEBIT
                            ? toStandardCase(transactionTypeEnum.BY)
                            : toStandardCase(transactionTypeEnum.TO)}
                        </TableCell>
                        <TableCell>{data.acct_ldgr.ldgr_desc}</TableCell>
                        <TableCell
                          id="td-right"
                          className="payment-voucher__table--amount"
                        >
                          {format(data.vo_db)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="payment-voucher__table--amount"
                        >
                          {format(data.vo_cr)}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total:
                  </TableCell>
                  <TableCell className="totalcount" align="right">
                    {format(totalDebitAmount)}
                  </TableCell>
                  <TableCell className="totalcount" align="right">
                    {format(totalCreditAmount)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div className="payment-voucher-modal__lableblock">
          <div className="payment-voucher__lableblock--details h-100">
            {paymentVoucherDetails?.v_book_type === "CONTRA" ||
            paymentVoucherDetails?.v_book_type === "JOURNAL" ? null : (
              <div className="payment-voucher__lableblock--frames h-100">
                <div className="row g-0">
                  <div className="col">
                    <div className="label-grid">
                      <Label>Pay to</Label>
                      <Input
                        disabled
                        value={
                          paymentVoucherDetails?.party_name ?? EMPTY_STRING
                        }
                      />
                      <Label>Bill No.</Label>
                      <Input
                        disabled
                        value={paymentVoucherDetails?.party_bill_no || ""}
                      />
                      {paymentVoucherDetails?.v_transcation_cash_or_bank ===
                      "C" ? null : (
                        <>
                          <Label>Chq / DD No.</Label>
                          <Input
                            disabled
                            value={
                              paymentVoucherDetails?.v_transcation_no ??
                              EMPTY_STRING
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="label-grid payment-voucher__lableblock--top">
                      <Label>Bill Date</Label>
                      <Input
                        type="date"
                        value={
                          toInputStandardDate(
                            paymentVoucherDetails?.party_bill_date!
                          ) || ""
                        }
                        disabled
                      />
                      {paymentVoucherDetails?.v_transcation_cash_or_bank ===
                      "C" ? null : (
                        <>
                          <Label>Chq / DD Date</Label>
                          <Input
                            type="date"
                            value={
                              toInputStandardDate(
                                paymentVoucherDetails?.party_bill_date!
                              ) || ""
                            }
                            disabled
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="payment-voucher__lableblock--frames h-100">
              <div className="payment-voucher__label-grid2">
                <Label>Narration :</Label>
                <TextArea
                  disabled
                  value={
                    paymentVoucherDetails?.v_transcation_narration ??
                    EMPTY_STRING
                  }
                  rows={3}
                />
              </div>
              {paymentVoucherDetails?.payment_invoice_filename ? (
                <>
                  <div className="documents-upload--filename">
                    <>
                      <img
                        src={getFileIcon(
                          fileNameArray[fileNameArray.length - 1]
                        )}
                        alt=""
                      />

                      <span>{fileNameArray[fileNameArray.length - 1]}</span>
                      <TooltipForMultipleOption
                        title={
                          <>
                            {imageString ? (
                              <>
                                <div className="registration__data--logo-grid--image">
                                  {fileName.split(".")[
                                    fileName.split(".").length - 1
                                  ] === "pdf" ? (
                                    <span>
                                      <img src={getFileIcon(fileName)} alt="" />
                                      <a download href={imageString}>
                                        {fileName}
                                      </a>
                                    </span>
                                  ) : (
                                    <img
                                      src={imageString}
                                      alt="/"
                                      className="image-preview"
                                    />
                                  )}
                                </div>

                                {/* <div className="label-grid">
                <Label>Uploading In Progress</Label>
                <progress value={progress}></progress>
              </div> */}
                              </>
                            ) : null}
                          </>
                        }
                       
                        placement="top"
                      >
                        <img
                          src={Download}
                          alt="/"
                          onClick={() => {
                            getDownloadUrl(
                              paymentVoucherDetails.payment_invoice_filename,
                              true,
                              setImageString
                            );
                          }}
                        />
                      </TooltipForMultipleOption>
                    </>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="payment-voucher__buttons-left">
            <Button mode="edit" onClick={() => setEnableEdit(!enableEdit)} />
            <Button
              mode="delete"
              onClick={() => setDeleteModal(!deleteModal)}
            />
            {paymentVoucherDetails?.v_type ===
            VoucherPageType.CONTRA_VOUCHER ? null : (
              <Button mode="print" onClick={() => setPrintModal(!printModal)}>
                &nbsp;Preview
              </Button>
            )}

            <Button mode="cancel" onClick={() => setModalFlag(false)} />
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherReceiptPrint setModalFlag={setPrintModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintModal(!printModal)}
              className="modal-close-icon"
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfig(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={enableEdit}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NewVoucher
              type={paymentVoucherDetails?.v_type! as VoucherPageType}
              operation={Operation.UPDATE}
              pageType={PageFor.MODAL}
              setModalFlag={setEnableEdit}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEnableEdit(!enableEdit)}
            />
          </div>
        </div>
      </Modal>
      {/* <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={previewModal}
        ariaHideApp={false}
        style={WaiveOffModalStyles}
        onRequestClose={() => setPreviewModal(!previewModal)}
      >
        {imageString ? (
          <>
            <div className="registration__data--logo-grid--image">
              {fileName.split(".")[fileName.split(".").length - 1] === "pdf" ? (
                <span>
                  <img src={getFileIcon(fileName)} alt="" />
                  <a download href={imageString}>
                    {fileName}
                  </a>
                </span>
              ) : (
                <img src={imageString} alt="/" className="image-preview" />
              )}

              <img
                src={Close}
                alt="/"
                onClick={() => setPreviewModal(!previewModal)}
                className="registration__data--logo-grid--image--close-icon"
              />
            </div>

            <div className="label-grid">
                <Label>Uploading In Progress</Label>
                <progress value={progress}></progress>
              </div>
          </>
        ) : null}
      </Modal> */}
      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PerModuleConfiguration
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.VOUCHER_PRINT}
              int_value={0}
              setModalFlag={setPrintConfig}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfig(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={state.receiptId}
      />
      <LoadingModal flag={deleteLoading} />{" "}
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default PaymentVoucherModal;

import {
  DEFAULT_NUMBER_VALUE,
  EMPTY_STRING,
} from "../../../../utils/constants";
import { responseType } from "../../../../utils/Form.types";
import { HYPHEN, MAX } from "../../constants";
import {
  applyHeadsOn,
  calculationType,
  EarnedSalaryMethod,
  PER_VALUE,
  SalaryCalculationConfig,
  SalaryLedgerType,
} from "../../enums/Enum.types";
import {
  LedgersListType,
  PayRollEmpSalaryStructDetails,
  Rules,
  salaryLedgerDetails,
} from "../../Payroll/SalaryStructure/AssignSalaryStructure";
import {
  applyHeadsOnOptions,
  IncomeCalculatorType,
} from "../../Types/dataTypes";

export const incomeArrayToRule = (
  incomeCalculatorItems: IncomeCalculatorType[]
) => {
  if (!incomeCalculatorItems || incomeCalculatorItems.length === 0) {
    return "";
  }

  const rules = incomeCalculatorItems.map(
    ({ from_amt, upto_amt, calculation_type, income_value_basis }) => {
      const incomeBasis = `${income_value_basis},`;
      const calcType =
        calculation_type === calculationType.Percentage
          ? PER_VALUE.PER
          : PER_VALUE.VALUE;
      const uptoAmount =
        upto_amt === DEFAULT_NUMBER_VALUE || upto_amt === 0 ? "MAX" : upto_amt;

      return `${from_amt}-${uptoAmount}-${calcType}-${incomeBasis}`;
    }
  );

  return rules.join("");
};

//getting the ruleOnBasicSalary string to array
export const getExpressionStringtoArray = (expressionString: string) => {
  return expressionString
    ?.split(",")
    ?.filter((data) => data !== EMPTY_STRING)
    ?.map((data) => data?.split(HYPHEN))
    ?.map((data, index) => ({
      id: index,
      from_amt: Number(data[0]),
      upto_amt: data[1] === MAX ? DEFAULT_NUMBER_VALUE : Number(data[1]),
      calculation_type:
        data[2] === PER_VALUE.VALUE
          ? calculationType.Value
          : calculationType.Percentage,
      income_value_basis: Number(data[3]),
    }));
};

export const findEarningsForExpressionString = (
  expressionString: string,
  amount: number,
  roundOff: boolean
) => {
  const resArray = getExpressionStringtoArray(expressionString);
  const theResultRow = resArray.find(
    (item) =>
      amount >= item.from_amt &&
      (amount <= item.upto_amt || item.upto_amt === DEFAULT_NUMBER_VALUE)
  );

  const { calculation_type, income_value_basis } = theResultRow || {};

  return calculation_type === calculationType.Percentage
    ? roundOff
      ? Math.round(((income_value_basis || 0) / 100) * amount)
      : ((income_value_basis || 0) / 100) * amount
    : calculation_type === calculationType.Value
    ? roundOff
      ? Math.round(income_value_basis!) || 0
      : income_value_basis || 0
    : 0;
};

export const getGroupIds = (data: string) => {
  return data
    .split(";")
    .filter((d) => d !== EMPTY_STRING)
    .map((group_id) => Number(group_id));
};
export const convertGroupIdsToString = (data: number[]) => {
  return data.reduce((acc, id) => {
    acc += id + ";";
    return acc;
  }, EMPTY_STRING);
};
export const applyOnHeadsOptions: applyHeadsOnOptions[] = [
  { label: "On Basic salary", value: applyHeadsOn.BASIC_SALARY },
  { label: "On Gross salary", value: applyHeadsOn.GROSS_SALARY },
  {
    label: "On Group of ledgers",
    value: applyHeadsOn.ON_GROUP_OF_LEDGERS,
  },
];
export const applyOnHeadsLookUp: responseType[] = [
  {
    label: applyHeadsOn.BASIC_SALARY,
    value: 1,
  },
  {
    label: applyHeadsOn.GROSS_SALARY,
    value: 3,
  },
];

export const calculateEarning = (
  emp_basic: number,
  salRoundFlag: boolean,
  ledger: PayRollEmpSalaryStructDetails
) => {
  if (ledger.sal_ldgr_details.sal_ldgr_is_basic) {
    return checkForFlags(ledger.sal_ldgr_details, salRoundFlag, emp_basic);
  }
  if (
    ledger.emp_sal_earn_flat_rate > 0 &&
    ledger.emp_sal_earn_type === Rules.FLR
  ) {
    let newEarning = 0;
    let newBasicSalary = 0;
    newBasicSalary = emp_basic;
    if (
      ledger.emp_sal_earn_to_min_basic > 0 &&
      emp_basic > ledger.emp_sal_earn_to_min_basic
    )
      newBasicSalary = ledger.emp_sal_earn_to_min_basic;

    if (ledger.emp_sal_earn_flat_rate >= 0) {
      newEarning = (ledger.emp_sal_earn_flat_rate / 100) * newBasicSalary;
      if (
        ledger.emp_sal_earn_restrict_to > 0 &&
        newEarning > ledger.emp_sal_earn_restrict_to
      )
        newEarning = ledger.emp_sal_earn_restrict_to;

      return checkForFlags(ledger.sal_ldgr_details, salRoundFlag, newEarning);
    }
  }

  if (
    ledger.emp_sal_earn_ded === SalaryLedgerType.EARNING &&
    ledger.emp_sal_earn_type === Rules.RULE_ON_SALARY
  ) {
    return findEarningsForExpressionString(
      ledger.emp_sal_earn_rule,
      emp_basic,
      salRoundFlag
    );
  }
  if (ledger.emp_sal_earn_is_UDM) {
    return ledger.emp_sal_earn_UDM_val;
  }

  return 0;
};
export const calculateSalaryStructEarning = (
  amount: number,
  ledger: LedgersListType,
  roundOff: boolean
) => {
  // if (ledger.sal_ldgr_is_basic) {
  //   return emp_basic;
  // }

  if (ledger.flat_rate > 0) {
    let newEarning = 0;
    let newBasicSalary = 0;
    newBasicSalary = amount;
    if (
      ledger.earnedValueFixedBelowBasic > 0 &&
      amount > ledger.earnedValueFixedBelowBasic
    )
      newBasicSalary = ledger.earnedValueFixedBelowBasic;

    if (ledger.flat_rate >= 0) {
      newEarning = (ledger.flat_rate / 100) * newBasicSalary;
      if (
        ledger.earnedValueRestrictedTo > 0 &&
        newEarning > ledger.earnedValueRestrictedTo
      )
        newEarning = ledger.earnedValueRestrictedTo;

      return newEarning;
    }
  }

  if (ledger.ledger_type === SalaryLedgerType.EARNING && ledger.rule_applied) {
    return findEarningsForExpressionString(
      ledger.ledger_rule,
      amount,
      roundOff
    );
  }
  if (ledger.ledger_udvMonthly) {
    return ledger.udv;
  }
  return 0;
};
export const findApplyHeadsBasedOnGroupIds = (groupIds: number[]) => {
  switch (groupIds[0]) {
    case 1:
      return applyHeadsOn.BASIC_SALARY;
    case 2:
      return applyHeadsOn.GROSS_SALARY;
    default:
      return applyHeadsOn.ON_GROUP_OF_LEDGERS;
  }
};
export const calculateDeduction = (
  groupIds: number[],
  ledger_rule: string,
  basicAmt: number,
  grossAmt: number,
  groupEarningsAmt: number,
  roundOff: boolean,
  EsiCondition: boolean
) => {
  switch (groupIds[0]) {
    case 1:
      return EsiCondition
        ? 0
        : findEarningsForExpressionString(ledger_rule, basicAmt, roundOff);
    case 2:
      return EsiCondition
        ? 0
        : findEarningsForExpressionString(ledger_rule, grossAmt, roundOff);
    default:
      return EsiCondition
        ? 0
        : findEarningsForExpressionString(
            ledger_rule,
            groupEarningsAmt,
            roundOff
          );
  }
};
export const calculateMonthlyBasicGrossGroupLedgers = (
  emp_basic: number,
  gross_sal: number,
  earned_days: number,
  sal_ledgers: PayRollEmpSalaryStructDetails[],
  no_of_days_in_month: number,
  salRoundFlag: boolean,
  remaining_balance_ledger: number,
  groupIds?: Set<number>
) => {
  const basicPerDay = emp_basic / no_of_days_in_month;
  const grossPerDay = gross_sal / no_of_days_in_month;

  const monthlyBasic = checkForRoundOff(
    basicPerDay * earned_days,
    salRoundFlag
  );

  const monthlyGross = checkForRoundOff(
    grossPerDay * earned_days,
    salRoundFlag
  );

  const monthlyGroupSalary = groupIds
    ? checkForRoundOff(
        sal_ledgers
          .filter(({ sal_ldgr_details }) => groupIds.has(sal_ldgr_details.id))
          .reduce((result: number, ledger: PayRollEmpSalaryStructDetails) => {
            if (ledger.sal_ldgr_details.sal_ldgr_is_basic) {
              result += monthlyBasic;
            } else if (ledger.emp_sal_remaining_amt_rule) {
              const perDayEarningLedger =
                remaining_balance_ledger / no_of_days_in_month;

              result += checkForFlags(
                ledger.sal_ldgr_details,
                salRoundFlag,
                perDayEarningLedger * earned_days
              );
            } else {
              result += calculateEarning(monthlyBasic, salRoundFlag, ledger);
            }

            return result;
          }, 0),
        salRoundFlag
      )
    : 0;

  return {
    monthlyBasic,
    monthlyGross,
    monthlyGroupSalary,
  };
};

//Chat Gpt
export const grossChange = (
  overAllSalaryLedger: LedgersListType[],
  setOverAllSalaryLedger: React.Dispatch<
    React.SetStateAction<LedgersListType[]>
  >,
  roundOff: boolean
) => {
  if (!overAllSalaryLedger) return;

  let totalEar = 0;
  const res = [];

  for (const { ...data } of overAllSalaryLedger) {
    totalEar += data.earnings;

    if (
      data.ledger_type === SalaryLedgerType.DEDUCTION &&
      data.ledger_applied_to === applyHeadsOn.GROSS_SALARY &&
      data.deductions > 0
    ) {
      const newDeductions = findEarningsForExpressionString(
        data.ledger_rule,
        totalEar,
        roundOff
      );
      if (newDeductions) {
        res.push({
          ...data,
          deductions: newDeductions,
        });
      } else {
        res.push(data);
      }
    } else {
      res.push(data);
    }
  }

  setOverAllSalaryLedger(res);
};

export const handleSelectLedger = (
  type: SalaryLedgerType,
  overAllSalaryLedger: LedgersListType[],
  setOverAllSalaryLedger: React.Dispatch<
    React.SetStateAction<LedgersListType[]>
  >
) => {
  const newSelected = overAllSalaryLedger?.map((data) => {
    if (data.ledger_type === type)
      return {
        ...data,
        isChecked: !data.isChecked || data.sal_ldgr_is_basic,
      };
    else return data;
  });

  setOverAllSalaryLedger(newSelected);
};
//Getting index from overAllSalaryLedger through id
export const getIndex = (
  id: number,
  overAllSalaryLedger: LedgersListType[]
) => {
  return overAllSalaryLedger?.indexOf(
    overAllSalaryLedger?.find(({ id: value }) => value === id)!
  );
};

export const getAppliedHeads = (
  data: LedgersListType,
  overAllSalaryLedger: LedgersListType[]
) => {
  let heads = EMPTY_STRING;
  let headIds = EMPTY_STRING;
  if (data.ledger_applied_to === applyHeadsOn.ON_GROUP_OF_LEDGERS) {
    data.groupLedgers?.forEach((data) => {
      heads =
        heads +
        overAllSalaryLedger[getIndex(data, overAllSalaryLedger)].ledger_desc +
        ";";
    });
    data.groupLedgers?.forEach((data) => {
      headIds =
        headIds +
        overAllSalaryLedger[getIndex(data, overAllSalaryLedger)].id +
        ";";
    });
  } else {
    heads =
      applyOnHeadsOptions
        ?.find(({ value }) => value === data.ledger_applied_to)
        ?.label?.replaceAll("On ", EMPTY_STRING) + ";";
    if (data.ledger_applied_to === applyHeadsOn.BASIC_SALARY) headIds = "1";
    if (data.ledger_applied_to === applyHeadsOn.GROSS_SALARY) headIds = "2";
  }
  return {
    heads,
    headIds,
  };
};

export const handleEarning = (
  ledger: LedgersListType,
  salRoundFlag: boolean,
  amountByConfig: number
) => {
  if (ledger.flat_rate > 0) {
    let newEarning = 0;
    let newBasicSalary = 0;
    newBasicSalary = amountByConfig;
    if (
      ledger.earnedValueFixedBelowBasic > 0 &&
      amountByConfig > ledger.earnedValueFixedBelowBasic
    )
      newBasicSalary = ledger.earnedValueFixedBelowBasic;

    if (ledger.flat_rate >= 0) {
      newEarning = (ledger.flat_rate / 100) * newBasicSalary;

      if (
        ledger.earnedValueRestrictedTo > 0 &&
        newEarning > ledger.earnedValueRestrictedTo
      )
        newEarning = ledger.earnedValueRestrictedTo;

      return checkForFlags(ledger, salRoundFlag, newEarning);
    }
  }
  if (
    ledger.earn_type === Rules.RULE_ON_SALARY &&
    ledger.ledger_type === SalaryLedgerType.EARNING
  ) {
    return findEarningsForExpressionString(
      ledger.ledger_rule,
      amountByConfig,
      salRoundFlag
    );
  }
  if (ledger.udv && ledger.ledger_udvMonthly === false) {
    return checkForFlags(ledger, salRoundFlag, ledger.udv);
  }

  return 0;
};

export const checkForFlags = (
  ledger: LedgersListType | salaryLedgerDetails,
  salRoundFlag: boolean,
  sal_amt: number
) => {
  if (ledger.sal_ldgr_round_to_next_int || salRoundFlag) {
    return Math.round(sal_amt);
  } else if (ledger.sal_ldgr_remove_decimal) {
    return Math.trunc(sal_amt);
  } else {
    return Math.round(sal_amt * 100) / 100;
  }
};

export const checkForRoundOff = (amount: number, salRoundFlag: boolean) => {
  if (salRoundFlag) {
    return Math.round(amount);
  } else {
    return Math.round(amount * 100) / 100;
  }
};

export const chooseAmtBasedOnType = (
  isBasic: boolean,
  ledgers: LedgersListType[],
  salaryConfigKey: string,
  salRoundFlag: boolean,
  amount: number
) => {
  const basicLedger = ledgers.find(
    ({ sal_ldgr_is_basic }) => sal_ldgr_is_basic
  );
  let basicAmount = 0;

  if (basicLedger) {
    basicAmount = handleEarning(basicLedger, salRoundFlag, amount);
  }

  return salaryConfigKey === SalaryCalculationConfig.BASIC ||
    (salaryConfigKey === SalaryCalculationConfig.BASIC && isBasic)
    ? amount
    : basicAmount;
};

export const getSalCalDaysMethod = (
  sal_cal_method: EarnedSalaryMethod,
  days: number,
  weekends: number
) => {
  switch (sal_cal_method) {
    case EarnedSalaryMethod.MONTH_DAYS:
      return days;
    case EarnedSalaryMethod.D30:
      return 30;
    case EarnedSalaryMethod.NOWD:
      return days - weekends;
    default:
      break;
  }
};

import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { useTheme } from "@mui/material/styles";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Button } from "../../../../stories/Button/Button";
import useDepositDetailsById from "../../hooks/useDepositDetailsById";
import useAcctVoucherDetailsByDates from "../../hooks/useAcctVoucherDetailsByDates";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { VoucherQueryTypes } from "../../common/Enum.types";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import { DebitOrCredit } from "../../../../utils/Enum.types";

interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ListType {
  title: string;
  amount: number;
  className: string;
}

interface adjustedLedgersType {
  particulars: string;
  date: string;
  amount: number;
}
const DepositDetails = ({ setModalFlag }: props) => {
  const { data } = useDepositDetailsById();
  const { state } = useContext(AppContext);
  const [list, setList] = useState<ListType[]>([]);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { Masters_Table } = useMasterTableJson();

  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE,
    state.ActiveFinYr ? state.ActiveFinYr.fin_end_date : EMPTY_STRING,
    state.ActiveFinYr ? state.ActiveFinYr.fin_st_date : EMPTY_STRING,
    value === 0
      ? VoucherQueryTypes.STD_DEPOSIT_COLLECTED_DETAILS
      : VoucherQueryTypes.STD_DEPOSIT_ADJUSTED_DETAILS,
    EMPTY_STRING
  );

  useEffect(() => {
    if (data && data.node) {
      const {
        deposit_ob,
        deposit_amt,
        deposit_total,
        deposit_adjusted,
        deposit_refunded,
        deposit_bal,
      } = data.node;
      setList([
        {
          title: "OB",
          amount: deposit_ob,
          className: "font-grey",
        },
        {
          title: "Deposit",
          amount: deposit_amt,
          className: "font-amber",
        },
        {
          title: "Total",
          amount: deposit_total,
          className: "font-blue",
        },
        {
          title: "Adjusted",
          amount: deposit_adjusted,
          className: "font-green",
        },
        {
          title: "Refunded",
          amount: deposit_refunded,
          className: `var(--text-color)`,
        },
        {
          title: "Balance",
          amount: deposit_bal,
          className: "font-red",
        },
      ]);
    }
  }, [data]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const adjustedLedgers: adjustedLedgersType[] =
    value === 1 && VoucherDetails.data
      ? VoucherDetails.data.GetAcctVouchers.edges
          .map((v_master) => {
            return v_master.node.acct_voucher_details
              .filter((v_det) => v_det.vo_cr_db === DebitOrCredit.CREDIT)
              .map((acct_voucher) => {
                return {
                  amount: acct_voucher.vo_cr,
                  date: toStandardDate(acct_voucher.vo_date),
                  particulars: acct_voucher.acct_ldgr.ldgr_desc,
                };
              });
          })
          .flat()
      : [];

  return (
    <>
      <div className="deposit-details">
        <div className="deposit-details__title">
          <Title>Deposit Break - up Details</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="deposit-details__block">
          <div className="deposit-details__block--title">
            <b>{data ? data.node.acct_ldgr_details.ldgr_desc : EMPTY_STRING}</b>
          </div>
          <ul className="deposit-details__block--list">
            {list.map((res, index) => {
              return (
                <li key={index + 1}>
                  <span>{res.title}</span>
                  <b className={res.className}>{res.amount}</b>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="deposit-details__tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Deposit Collected" value={0} {...a11yProps(0)} />

            <Tab label="Deposit Adjusted" value={1} {...a11yProps(1)} />
          </Tabs>
        </div>
        <div className="deposit-details__tabpanel">
          <TabPanel value={value} index={0} dir={theme.direction}>
            <TableContainer className="deposit-details__tabpanel--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Masters_Table.Students.Dashboard.DepositCollectedDetails.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {VoucherDetails.data
                    ? VoucherDetails.data.GetAcctVouchers.edges.map(
                        (voucher, index) => {
                          const ledger_details =
                            voucher.node.acct_voucher_details.find(
                              (v) =>
                                data &&
                                v.acct_ldgr_id ===
                                  data.node.acct_ldgr_details.id
                            );

                          return (
                            <TableRow>
                              <TableCell
                                className="deposit-details__tabpanel--table--slno"
                                id="td-center"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell>{voucher.node.v_no}</TableCell>
                              <TableCell className="deposit-details__tabpanel--table--date">
                                {toStandardDate(voucher.node.v_date)}
                              </TableCell>

                              <TableCell
                                className="deposit-details__tabpanel--table--amount"
                                id="td-right"
                              >
                                {ledger_details
                                  ? ledger_details.vo_cr
                                  : EMPTY_STRING}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    : null}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3} className="total">
                      Deposit Collected
                    </TableCell>
                    <TableCell
                      className="deposit-details__tabpanel--table--amount totalcount"
                      id="td-right"
                    >
                      {data ? data.node.deposit_amt : 0}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <TableContainer className="deposit-details__tabpanel--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Masters_Table.Students.Dashboard.DepositAdjustedDetails.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adjustedLedgers.map((voucher, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          className="deposit-details__tabpanel--table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{voucher.particulars}</TableCell>
                        <TableCell className="deposit-details__tabpanel--table--date">
                          {voucher.date}
                        </TableCell>

                        <TableCell
                          className="deposit-details__tabpanel--table--amount"
                          id="td-right"
                        >
                          {voucher.amount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3} className="total">
                      Deposit Adjusted
                    </TableCell>
                    <TableCell
                      className="deposit-details__tabpanel--table--amount totalcount"
                      id="td-right"
                    >
                      {data ? data.node.deposit_adjusted : 0}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default DepositDetails;

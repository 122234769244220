import React from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DataFetch from "../../../../images/Edit.svg";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import Input from "../../../../stories/Input/Input";
import Save from "../../../../images/Save.svg";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import { Label } from "../../../../stories/Label/Label";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { VMS_Table } = require("../../../json/table.json");
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const New = ({ setModalFlag }: Props) => {
  return (
    <>
      <div className="vms-fuel-purchase__add">
        <Title>Add Fuel Purchase</Title>
        <div className="vms-fuel-purchase__add--details row g-0">
          <div className="col">
            <div className="vms-fuel-purchase__add--details--flex">
              <LabeledAutocomplete
                options={["1", "2"]}
                className={labelClasses.inputRoot}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={labelClasses.formControlRoot}
                    label="Supplier Name"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
              <img src={DataFetch} alt="" className="data-fetch-icon" />
            </div>
            <div className="row g-0 vms-fuel-purchase__add--details--row">
              <div className="col">
                <TextField
                  className="vms-fuel-purchase__add--details--textfield"
                  label="GST No."
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
              </div>
              <div className="col">
                <TextField
                  className="vms-fuel-purchase__add--details--textfield"
                  label="Mobile No."
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
              </div>
            </div>
            <div className="row g-0 vms-fuel-purchase__add--details--row">
              <div className="col ">
                <TextField
                  className="vms-fuel-purchase__add--details--textfield"
                  label="Address"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
              </div>
              <div className="col ">
                <TextField
                  className="vms-fuel-purchase__add--details--textfield font-red"
                  label="Balance Amount"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col">
            <LabeledAutocomplete
              options={["1", "2"]}
              className={labelClasses.inputRoot}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={labelClasses.formControlRoot}
                  label="Invoice No."
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              )}
            />
            <LabeledAutocomplete
              options={["1", "2"]}
              className={labelClasses.inputRoot}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={labelClasses.formControlRoot}
                  label="Purchase Order No."
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              )}
            />{" "}
            <LabeledAutocomplete
              options={["1", "2"]}
              className={labelClasses.inputRoot}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={labelClasses.formControlRoot}
                  label="Eway Bill"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <TextField
              className="vms-fuel-purchase__add--details--textfield--date"
              label="Invoice Date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
              type="date"
            />
            <TextField
              className="vms-fuel-purchase__add--details--textfield--date"
              label="Purchase Order Date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
              type="date"
            />
            <LabeledAutocomplete
              options={["1", "2"]}
              className={labelClasses.inputRoot}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={labelClasses.formControlRoot}
                  label="Type of Bill"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Button mode="addnew"> New Item</Button>
          </div>
        </div>
        <div className="vms-fuel-purchase__add--datablock">
          <div className="vms-fuel-purchase__add--tableblock">
            <TableContainer className="vms-fuel-purchase__add--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {VMS_Table.Supplier_Details.AddFuelPurchase.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="vms-fuel-purchase__add--table--slno"
                      id="td-center"
                    >
                      1
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--code">
                      abc
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--code">
                      g65
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--number">
                      10
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--number">
                      152
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--rate"
                      id="td-right"
                    >
                      85
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--rate"
                      id="td-right"
                    >
                      <Input />
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--rate"
                      id="td-right"
                    >
                      <Input />
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--amount font-red"
                      id="td-right"
                    ></TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--amount font-green"
                      id="td-right"
                    ></TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--actions"
                      id="td-center"
                    >
                      <img src={Save} alt="" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="vms-fuel-purchase__add--table--slno"
                      id="td-center"
                    >
                      1
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--code">
                      abc
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--code">
                      g65
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--number">
                      10
                    </TableCell>
                    <TableCell className="vms-fuel-purchase__add--table--number">
                      152
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--rate"
                      id="td-right"
                    >
                      85
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--rate"
                      id="td-right"
                    >
                      000
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--rate"
                      id="td-right"
                    >
                      000
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--amount font-red"
                      id="td-right"
                    >
                      000
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--amount font-green"
                      id="td-right"
                    >
                      000
                    </TableCell>
                    <TableCell
                      className="vms-fuel-purchase__add--table--actions"
                      id="td-center"
                    >
                      <img src={Edit} alt="" />
                      <img src={Delete} alt="" />
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={6} className="total">
                      Total :
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="totalcount vms-fuel-purchase__add--table--rate"
                    >
                      0
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="taxableamount vms-fuel-purchase__add--table--rate"
                    >
                      0
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="balance-count vms-fuel-purchase__add--table--amount"
                    >
                      0
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="totalcount  vms-fuel-purchase__add--table--amount"
                    >
                      0
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <div className="vms-fuel-purchase__add--footer row g-0">
            <div className="col vms-fuel-purchase__add--footer--frame">
              <div className="label-grid">
                <Label>Purchased By</Label>
                <Input disabled />
              </div>
              <div className="label-grid">
                <Label>Narration</Label>
                <Input disabled />
              </div>
            </div>
            <div className="col vms-fuel-purchase__add--footer--right-block">
              <div className="label-grid">
                <Label>Secondary Discount Amount :</Label>
                <Input />
                <Label>Frieght/Extra Charges</Label>
                <Input />
                <Label>Net Amount :</Label>
                <Input />
              </div>
            </div>
          </div>
        </div>
        <Button mode="save" />
        <Button mode="clear" />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default New;

import React from "react";
import Header from "../../../../../components/common/Header/index";
import BreadCrumb from "../../../../../components/common/BreadCrumb/index";

const Index = () => {
  return (
    <>
      <div className="row g-0">
        <Header />
      </div>
      <div className="row g-0">
        <BreadCrumb />
      </div>
      <div className="student-dashboard__nav"></div>
    </>
  );
};

export default Index;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetSwConfigThankYouMsg } from "../../queries/institution/configuration/query/SoftwreConfig";
import { useParams } from "react-router-dom";
import submit from "../../images/SubmitForm.gif";
import EduateImage from "../../images/EduateLogo.svg";
import { GetInstByID } from "../../queries/institution/list/byId";
import { InstDetails } from "./EnquiryByQrCode";
import { getDownloadUrl } from "../../utils/DownloadFile";

const FinalPage = () => {
  const { InstId } = useParams();
  const [imageString, setImageString] = useState("");

  const [GetConfigData, { data: configData }] = useLazyQuery(
    GetSwConfigThankYouMsg,
    {
      variables: {
        inst_id: InstId!,
      },
    }
  );

  const [GetInstDetails, { data, loading }] = useLazyQuery<InstDetails>(
    GetInstByID,
    {
      variables: {
        id: InstId,
      },
    }
  );
  useEffect(() => {
    if (InstId) GetConfigData();
    GetInstDetails();
  }, [InstId, GetConfigData, GetInstDetails]);

  useEffect(() => {
    if (data && !loading) {
      const filename = data.GetInstByID;
      if (filename) {
        // eslint-disable-next-line
        const ers = getDownloadUrl(
          data?.GetInstByID.inst_logo_filename,
          false,
          setImageString
        );
      }
    }
  }, [data, loading]);

  return (
    <>
      <div className="std-enquiry-for-all__final-page">
        <div className="std-enquiry-for-all__inst-details">
          <div className="std-enquiry-for-all__inst-details--logo">
            <img src={imageString} alt="/" />
          </div>
          <div className="std-enquiry-for-all__inst-details--address">
            <span className="std-enquiry-for-all__inst-details--c-name">
              {data?.GetInstByID.customer_details.cust_name}
            </span>
            <b>{data?.GetInstByID.inst_name}</b>
            <span>{data?.GetInstByID.inst_address}</span>
          </div>
        </div>
        <div className="std-enquiry-for-all__final-page--data">
          <div className="std-enquiry-for-all__final-page--text">
            <h2>{configData?.GetSwConfigThankYouMsg.thank_you_msg}</h2>
          </div>
          <div className="std-enquiry-for-all__final-page--image">
            <img src={submit} alt="loading" />
          </div>
          <div className="std-enquiry-for-all__footer">
            <img src={EduateImage} alt="" />
            Simplified With My-Eduate
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalPage;

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { GetInstsByCustId } from "../queries/institution/list/byId";
import { Direction, SortBy } from "../utils/Enum.types";
import useToken from "./useToken";
import { CutomerListNode } from "../Types/Customers/paginationTypes";
import { ActiveFinancialYearData } from "../Modules/Accounts/hooks/useActiveFinancialYear";
import { AcdActiveDetails } from "../Modules/Academics/hooks/useActiveAcademicYear";
import { InstModules } from "../Modules/UserRights/hooks/useInstModules";
import { GetMstInstLogoAndSignsDetails } from "../Types/Student";
export interface InstitutionNode {
  id: number;
  inst_code: string;
  inst_name: string;
  inst_short_name: string;
  inst_address: string;
  inst_name_to_print: string;
  inst_place: string;
  inst_state: string;
  inst_pin: string;
  inst_contact_person: string;
  inst_phone: string;
  inst_email: string;
  inst_mobile: string;
  inst_url: string;
  inst_is_active: string;
  inst_status: string;
  inst_logo_filename: string;
  inst_latitude: number;
  inst_longitude: number;
  inst_time_zone: string;
  customer_id: number;
  customer_details: CutomerListNode;
  fin_yr_details: ActiveFinancialYearData[];
  acd_yr_details: AcdActiveDetails[];
  module_master_details: InstModules;
  inst_logos: GetMstInstLogoAndSignsDetails[];
  isChecked: boolean;
}
export interface InstitutionEdges {
  node: InstitutionNode;
  cursor: string;
}
interface InstitutionListPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}
interface GetInstitutions {
  totalCount: number;
  pageInfo: InstitutionListPageInfo;
  edges: InstitutionEdges[];
}

export interface GetInstitutionsByCustIdData {
  GetInstsByCustId: GetInstitutions;
}

export interface GetInstitutionsVars {
  customer_id?: number;
  name: string;
  first: number | null;
  after: string | null;
  orderBy: { field: string; direction: string };
  token: string;
}
const useInstitutionsByCustId = (
  custId: number,
  searchData: string,
  first: number | null
) => {
  const { token } = useToken();
  const [GetInsts, { data, loading, error, fetchMore }] = useLazyQuery<
    GetInstitutionsByCustIdData,
    GetInstitutionsVars
  >(GetInstsByCustId, {
    variables: {
      customer_id: Number(custId),
      name: searchData,
      first,
      after: null,
      orderBy: { field: SortBy.INST_NAME, direction: Direction.ASC },
      token,
    },
  });
  useEffect(() => {
    if (custId && token) {
      GetInsts();
    }
  }, [token, custId, GetInsts]);
  return { Institutions: { data, loading, error, fetchMore } };
};

export default useInstitutionsByCustId;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import {
  AcdSubjectType,
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../utils/Enum.types";

import { useNavigate, useParams } from "react-router-dom";
import { TableHeaderProps } from "../../../../Types/Tables";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import Delete from "../../../../images/Delete.svg";
import Enter from "../../../../images/Enter.svg";
import useAcademicSubjects, {
  SubjectDetailsNode,
} from "../../hooks/useAcademicSubjects";
import { useMutation } from "@apollo/client";

import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AddAcdSubjectClassAllotedElectives } from "../../queries/subjects/mutation/Index";

import { Keys } from "../../../../utils/Enum.keys";

import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import useSubjectAllotedForClass from "../../hooks/useSubjectAllotedForClass";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import { GetAcdSubjectAllotedForClass } from "../../queries/subjects/List.tsx/Index";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Title } from "../../../../stories/Title/Title";
import { ElectiveSubjectData } from "./ViewElectiveSubjects";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
const { Academics_Table } = require("../../json/table.json");

interface Props {
  operation: Operation;
  electiveId: ElectiveSubjectData;
  setElectiveId: React.Dispatch<React.SetStateAction<ElectiveSubjectData>>;
  setAllocateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAllocateUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ElectiveSubjectAllocate = ({
  operation,
  electiveId,
  setAllocateModal,
  setAllocateUpdateModal,
  setElectiveId,
}: Props) => {
  const navigate = useNavigate();
  const { InstId, allotedID } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { subjectsForClass } = useSubjectAllotedForClass(Number(allotedID));

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState<SubjectDetailsNode[]>([]);
  const [deletedItems, setDeletedItems] = useState<SubjectDetailsNode[]>([]);
  const [filteredSubjects, setFilteredSubjects] = useState<responseType[]>([]);

  const [subjectId, setSubjectId] = useState<responseType | null>(null);

  const [electiveModal, setElectiveModal] = useState(false);
  //refs
  const subjectRef = useRef<HTMLSelectElement>(null);
  const subjectInputRef = subjectRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { flag } = useCheckAllocationType();
  const [AllocateSubjects, { loading }] = useMutation(
    AddAcdSubjectClassAllotedElectives,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const { subjects } = useAcademicSubjects(
    0,
    0,
    0,
    searchText,
    ROWS_PER_PAGE,
    AcdSubjectType.GENERAL
  );
  const existingSubjects =
    subjectsForClass &&
    subjectsForClass.data?.GetAcdSubjectAllotedForClass.map((res) => ({
      label: res.subject_details.subj_desc,
      value: res.subject_details.id,
    }));
  const filteredDropDownOptions = filteredSubjects.filter(
    (subject) =>
      !existingSubjects?.some(
        (existingSubject) => existingSubject.value === subject.value
      )
  );

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { token } = useToken();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { InstConfigDetails } = useInstConfigByEntryId(allotedID!);
  const {
    acd_dept_desc,
    acd_branch_desc,
    acd_class_desc,
    acd_semester_desc,
    acd_section_desc,
  } = InstConfigDetails.data
    ? InstConfigDetails.data.GetAcdInstConfigNames
    : {
        acd_dept_desc: "",
        acd_branch_desc: "",
        acd_class_desc: "",
        acd_semester_desc: "",
        acd_section_desc: "",
      };
  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY } = useInstitutionConfiguration();

  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();

  const handleAllocateSubjects = (operation: Operation) => {
    if (items.length === 0) {
      alert("Please Select Subjects");
      subjectInputRef?.select();
      subjectInputRef?.focus();
      return;
    }
    AllocateSubjects({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
        input: {
          allotted_details: {
            allotted_level:
              configData.data?.GetSwConfigVariables[0].config_string_value,
            allotted_id: allotedID,
            allotted_details_id: electiveId.id,
          },
          add_elective_subj_details: items.map((subject, index) => ({
            elective_sl: index + 1,
            elective_subj_master_id: subject.id,
          })),
        },
      },
      refetchQueries: [
        {
          query: GetAcdSubjectAllotedForClass,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            alloted_id: allotedID,
            alloted_level:
              configData.data?.GetSwConfigVariables[0].config_string_value!,
            inst_id: InstId!,
            per_std_subj_allocation: flag,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && isElectiveSubject.length === 0) {
        setMessage({
          flag: true,
          message: "Successfully allocated subjects ",
          operation: Operation.CREATE,
        });
        // navigate(`/${InstId}/academics/subjectallocationdetails`);
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setItems([]);
      setAllocateModal(false);
      setElectiveId({
        id: 0,
        name: "",
      });
      setAllocateUpdateModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handleItemDeleted = (ids: number) => {
    const updatedItems = items.filter(({ id }) => id !== ids);
    const filteredupdatedData = filteredSubjects.map((subject) => {
      if (subject.value === ids) {
        const newData = {
          ...subject,
          isChecked: false,
        };
        return newData;
      }
      return subject;
    });
    setFilteredSubjects(filteredupdatedData);
    setItems(updatedItems);
    if (operation === Operation.UPDATE) {
      const deletedItem = items.find((data) => data.id === ids);
      setDeletedItems((prevDeletedItems) =>
        deletedItem ? [...prevDeletedItems, deletedItem] : prevDeletedItems
      );
    }
  };

  const handleItems = () => {
    const filteredSubject = subjects.data?.GetAcdSubject.edges.find(
      (subject) => subject.node.id === subjectId?.value
    );
    const itemAlreadyExists = items.filter((d) => d.id === subjectId?.value);
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate Subject entry");
      setSubjectId(null);

      subjectInputRef?.select();
      subjectInputRef?.focus();
      return;
    }

    const updatedData = filteredSubjects?.map((id) => {
      if (id.value === subjectId?.value) {
        const newData = {
          ...id,
          value: id.value,
          label: id.label,
          isChecked: !id.isChecked,
        };
        return newData;
      }
      return id;
    });
    setFilteredSubjects(updatedData);
    if (subjectId?.value && filteredSubject) {
      setItems((items) => [
        ...items,
        {
          id: subjectId?.value,
          subj_apptitude_avialed: filteredSubject?.node.subj_apptitude_avialed,
          subj_board_code: filteredSubject?.node.subj_board_code,
          subj_code: filteredSubject?.node.subj_code,
          subj_desc: filteredSubject?.node.subj_desc,
          subj_idx: filteredSubject?.node.subj_idx,
          subj_internals_avialed: filteredSubject?.node.subj_internals_avialed,
          subj_is_core_subject: filteredSubject?.node.subj_is_core_subject,
          subj_is_elective: filteredSubject?.node.subj_is_elective,
          subj_is_lang: filteredSubject?.node.subj_is_lang,
          subj_is_non_academic: filteredSubject?.node.subj_is_non_academic,
          subj_marks_in_grades: filteredSubject?.node.subj_marks_in_grades,
          isChecked: true,
          subj_is_lab: filteredSubject.node.subj_is_lab,
          teacher_count: filteredSubject.node.teacher_count,
          newAdded: true,
          updated: false,
        },
      ]);
    }
    setSubjectId(null);
    setSearchText("");
    subjectInputRef?.select();
    subjectInputRef?.focus();
  };

  const handleClear = () => {
    setItems([]);
  };
  const isElectiveSubject = items
    .filter((data) => data.subj_is_elective)
    .map((res) => res.subj_desc);

  useEffect(() => {
    if (subjects.data && !subjects.loading) {
      const filteredResult = subjects.data.GetAcdSubject.edges.filter((el) => {
        return !items.find((element) => {
          return element.id === el.node.id;
        });
      });
      setFilteredSubjects(
        filteredResult.map((edge) => ({
          value: edge.node.id,
          // eslint-disable-next-line
          label: edge.node.subj_desc + " " + `(${edge.node.subj_code})`,
          isChecked: false,
        }))
      );
    }
  }, [subjects.data, subjects.loading, searchText, items]);

  useEffect(() => {
    if (
      subjectsForClass.data &&
      !subjectsForClass.loading &&
      operation !== Operation.CREATE &&
      allotedID &&
      electiveId
    ) {
      const { data } = subjectsForClass;
      setItems(
        data.GetAcdSubjectAllotedForClass.flatMap((item) =>
          item.elective_details
            .filter(
              (detail) => detail.subj_alloted_details_id === electiveId.id
            )
            .map((subject) => ({
              id: subject.subject_details.id || 0,

              subj_apptitude_avialed:
                subject.subject_details.subj_apptitude_avialed,
              subj_board_code: subject.subject_details.subj_board_code,
              subj_code: subject.subject_details.subj_code,
              subj_desc: subject.subject_details.subj_desc,
              subj_idx: subject.subject_details.subj_idx,
              subj_internals_avialed:
                subject.subject_details.subj_internals_avialed,
              subj_is_core_subject:
                subject.subject_details.subj_is_core_subject,
              subj_is_elective: subject.subject_details.subj_is_elective,
              subj_is_lang: subject.subject_details.subj_is_lang,
              subj_is_non_academic:
                subject.subject_details.subj_is_non_academic,
              subj_marks_in_grades:
                subject.subject_details.subj_marks_in_grades,
              isChecked: true,
              subj_is_lab: subject.subject_details.subj_is_lab,
              teacher_count: subject.subject_details.teacher_count,
              newAdded: false,
              updated: true,
            }))
        )
      );
    } // eslint-disable-next-line
  }, [
    subjectsForClass.data,
    subjectsForClass.loading,
    operation,
    allotedID,
    electiveId,
  ]);

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  return (
    <>
      <Title>
        {operation === Operation.CREATE
          ? `Subject Allocation for ${electiveId.name}`
          : `Update Subject Allocation for ${electiveId.name}`}
      </Title>
      <div className="test-planner__subject-allocation--elective">
        <div className="row g-0 test-planner__subject-allocation--select">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={departmentLabel}
                value={acd_dept_desc}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={branchLabel}
                value={acd_branch_desc}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
              />
            </div>
          ) : null}
          {displayClass ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={classLabel}
                value={acd_class_desc}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
              />
            </div>
          ) : null}
          {displaySemester ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={semesterLabel}
                value={acd_semester_desc}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
              />
            </div>
          ) : null}
          {displaySection ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={sectionLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={acd_section_desc}
              />
            </div>
          ) : null}
        </div>
        <div className="test-planner__subject-allocation--elective--tableblock">
          <TableContainer className="test-planner__subject-allocation--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.AllocateSubjects.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="test-planner__subject-allocation--table--row">
                  <TableCell id="td-center"> {items.length + 1}</TableCell>
                  <TableCell>
                    <FormAutocomplete
                      ref={subjectRef}
                      className={formClasses.inputRoot}
                      // disabled={!state}
                      value={subjectId}
                      options={filteredDropDownOptions.filter(
                        ({ isChecked }, data) => !isChecked
                      )}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as responseType, subjectId)
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (subjectId?.value) {
                            buttonRef.current?.focus();
                          }
                        } else if (e.key === Keys.BACKSPACE) {
                          setSubjectId(null);
                        }
                      }}
                      // disabled={
                      //   !classSelected.value ||
                      //   !semesterSelected.value ||
                      //   !sectionSelected.value
                      // }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          const data = newValue as responseType;
                          setSubjectId({
                            label: data.label,
                            value: data.value,
                            isChecked: true,
                          });
                        } else {
                          setSubjectId(null);
                        }
                      }}
                      openOnFocus
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setSearchText(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {
                      subjects.data?.GetAcdSubject.edges.find(
                        (d) => d.node.id === subjectId?.value
                      )?.node.subj_board_code!
                    }
                  </TableCell>

                  <TableCell>
                    {subjects.data?.GetAcdSubject.edges.find(
                      (d) => d.node.id === subjectId?.value
                    )?.node.subj_is_core_subject
                      ? "Core"
                      : subjectId?.value &&
                        subjects.data?.GetAcdSubject.edges.find(
                          (d) => d.node.id === subjectId?.value
                        )?.node.subj_is_lang
                      ? "Language"
                      : subjectId?.value &&
                        subjects.data?.GetAcdSubject.edges.find(
                          (d) => d.node.id === subjectId?.value
                        )?.node.subj_is_elective
                      ? "Elective"
                      : subjectId?.value &&
                        subjects.data?.GetAcdSubject.edges.find(
                          (d) => d.node.id === subjectId?.value
                        )?.node.subj_is_lab
                      ? "Lab"
                      : EMPTY_STRING}
                  </TableCell>
                  <TableCell id="td-center">
                    <button
                      disabled={!subjectId?.value}
                      ref={buttonRef}
                      onClick={(e: React.FormEvent) => handleItems()}
                    >
                      <img alt="/" src={Enter} />
                    </button>
                    {/* <img src={Enter} alt="/" /> */}
                  </TableCell>
                </TableRow>

                {items.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        className="allocatesubjects__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.subj_desc}</TableCell>
                      <TableCell className="allocatesubjects__table--code">
                        {item.subj_board_code}
                      </TableCell>
                      <TableCell className="allocatesubjects__table--code">
                        {item.subj_is_core_subject
                          ? "Core"
                          : item.subj_is_elective
                          ? "Elective"
                          : item.subj_is_lang
                          ? "Language"
                          : item.subj_is_lab
                          ? "Lab"
                          : EMPTY_STRING}
                      </TableCell>
                      <TableCell
                        className="allocatesubjects__table--actions"
                        id="td-center"
                      >
                        {operation !== Operation.DELETE ? (
                          <img
                            src={Delete}
                            alt="/"
                            onClick={() => handleItemDeleted(item.id)}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode="save"
          disabled={!items.length}
          onClick={() => {
            if (isElectiveSubject && isElectiveSubject.length > 0) {
              setElectiveModal(!electiveModal);
              handleAllocateSubjects(operation);
            } else handleAllocateSubjects(operation);
          }}
        />
        <Button mode="clear" onClick={handleClear} />
        <Button mode="cancel" onClick={() => navigate(-1)} />
      </div>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default ElectiveSubjectAllocate;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetAcdYrActiveByInstId } from "../../../queries/academicyear/list/byid";
import { AppContext } from "../../../context/context";
import { getDatesBetween } from "../../../utils/UtilFunctions";
import { DatesOptionType } from "../../../utils/Form.types";

export interface AcdActiveVars {
  token: string;
  inst_id: string | number;
}
export interface AcdActiveDetails {
  id: number;
  acd_yr: string;
  acd_st_date: string;
  acd_end_date: string;
  acd_is_curr_yr: boolean;
}
export interface AcdActiveData {
  GetAcdYrActiveByInstId: AcdActiveDetails;
}
const useActiveAcademicYear = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [dateOptions, setDateOptions] = useState<DatesOptionType[]>([]);
  const [GetAcdYear, { data, loading, error }] = useLazyQuery<
    AcdActiveData,
    AcdActiveVars
  >(GetAcdYrActiveByInstId, {
    variables: {
      token,
      inst_id: state.instIdForCampus
        ? state.instIdForCampus
        : state.InstId
        ? state.InstId
        : InstId!,
    },
  });

  useEffect(() => {
    if (token && (InstId || state.InstId || state.instIdForCampus)) {
      GetAcdYear().then(({ data }) => {
        if (data) {
          setDateOptions(
            getDatesBetween(
              data.GetAcdYrActiveByInstId.acd_st_date,
              data.GetAcdYrActiveByInstId.acd_end_date
            )
          );
        }
      });
    }
  }, [token, GetAcdYear, InstId, state.InstId, state.instIdForCampus, data]);
  return { activeAcademicYearData: { data, loading, error, dateOptions } };
};

export default useActiveAcademicYear;

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { TextField } from "@mui/material";
import {
  TableHeaders,
  StudentReportType,
  PageFor,
  AcdSubjectAllocationData,
} from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import Modal from "react-modal";

import { responseType } from "../../../../utils/Form.types";
import { EmpData } from "../../../HR/hooks/useEmployee";
import { useLazyQuery } from "@apollo/client";
import { CommonNodeVars } from "../../../HR/Types/masterDataTypes";
import { PayRollEmpMasterNode } from "../../../HR/queries/employee/query";
import useToken from "../../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  GetAcdInstDetailsByNodeIdData,
  GetAcdInstDetailsByNodeIdVars,
} from "../../Dashboard/Teacher/Index";
import View from "../../../../images/EyeWhite.svg";
import Close from "../../../../images/Close.svg";

import { GetAcdInstDetailsByIdForteacherDashBoard } from "../../queries/instdetails/section/byId";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsData,
  GetAcdTeacherClassAssociationsVars,
} from "../../types/subject/Subject";
import { GetAcdTeacherClassAssociations } from "../../queries/subjects/List.tsx/Index";
import { AppContext } from "../../../../context/context";
import { ViewDetails } from "../../../../styles/ModalStyles";
import ViewSubjectsForTeacher from "./ViewSubjects";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";

interface TeacherDrawerDetails {
  subj_code: string;
  subj_name: string;
  subj_id: number;
}
interface Props {
  pageType: PageFor;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  queryType: StudentReportType;
  instSelected?: responseType | null;
}
const PerTeacherClassDetails = ({ pageType,  }: Props) => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { InstId, teacherId } = useParams();
  const { state } = useContext(AppContext);
  const { entry_level } = useInstitutionConfiguration();
  const [view, setView] = useState(false);
const {categoryLabel}=useInstLabels();
  const [subjectDetails, setSubjectDetails] = useState<TeacherDrawerDetails>({
    subj_code: "",
    subj_id: 0,
    subj_name: "",
  });
  const [GetEmpDetails, { data: TeacherData }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNode, {
    variables: {
      id: Number(teacherId),
      token,
    },
    fetchPolicy: "network-only",
  });
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const { flag } = useCheckAllocationType();
  const [GetSubjectsTeachersAssociated, { data }] = useLazyQuery<
    GetAcdTeacherClassAssociationsData,
    GetAcdTeacherClassAssociationsVars
  >(GetAcdTeacherClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
      per_std_subj_allocation: flag,
      input: {
        query_type: AcdTeacherClassQueryType.ALL_ENTRIES_BY_TEACHER_ID,
        entry_id: 0,
        entry_level: entry_level,
        pr_emp_id: Number(teacherId),
        subj_master_id: 0,
      },
    },
  });
  let entryIdToUse;
  switch (entry_level) {
    case AcdSubjectAllocationData.CLASS:
      entryIdToUse = data?.GetAcdTeacherClassAssociations.map(
        (res) => res.acd_class_id!
      );
      break;
    case AcdSubjectAllocationData.SEMESTER:
      entryIdToUse = data?.GetAcdTeacherClassAssociations.map(
        (res) => res.acd_semester_id!
      );
      break;
    case AcdSubjectAllocationData.SECTION:
      entryIdToUse = data?.GetAcdTeacherClassAssociations.map(
        (res) => res.acd_section_id!
      );
      break;
    default:
      entryIdToUse = null;
  }

  const uniqueEntryIds = new Set(entryIdToUse);

  const [GetInstMasterDetails, { data: nodeData, loading }] = useLazyQuery<
    GetAcdInstDetailsByNodeIdData,
    GetAcdInstDetailsByNodeIdVars
  >(GetAcdInstDetailsByIdForteacherDashBoard, {
    variables: {
      token,
      ids: entryIdToUse ? Array.from(uniqueEntryIds) : [],
    },
  });
  useEffect(() => {
    if (token && teacherId) {
      GetEmpDetails();
    }
  }, [token, teacherId, GetEmpDetails]);
  useEffect(() => {
    if (token && state.ActiveAcdYr && flag !== undefined) {
      GetSubjectsTeachersAssociated();
    }
  }, [GetSubjectsTeachersAssociated, token, state.ActiveAcdYr, flag]);
  useEffect(() => {
    if (token) {
      GetInstMasterDetails();
    }
  }, [token, GetInstMasterDetails]);

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Teacher Wise Allocated Reports</Title>

      <div className="teacher-wise-allocated-reports">
        <div className="teacher-wise-allocated-reports__details row g-0">
          <div className="col">
            <TextField
              className="preview__details--textfield"
              label="Employee Id"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={TeacherData && TeacherData.node.emp_id}
              disabled
            />
            <TextField
              className="preview__details--textfield"
              label="Name"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                TeacherData &&
                TeacherData.node.emp_first_name +
                  " " +
                  TeacherData.node.emp_middle_name +
                  " " +
                  TeacherData.node.emp_last_name
              }
              disabled
            />
            <TextField
              className="preview__details--textfield"
              label="Mobile No."
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={TeacherData && TeacherData.node.emp_mobile}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="preview__details--textfield"
              label="Designation"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                TeacherData &&
                TeacherData.node.pr_designation_details.designation_desc
              }
              disabled
            />
            <TextField
              className="preview__details--textfield"
               label={categoryLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                TeacherData &&
                TeacherData.node.pr_category_details.category_desc
              }
              disabled
            />
            <TextField
              className="preview__details--textfield"
              label="Job Type"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                TeacherData &&
                TeacherData.node.pr_job_type_details.job_type_desc
              }
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="preview__details--textfield--date"
              label="Work Experience"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={TeacherData && TeacherData.node.emp_experience}
              disabled
            />
            <TextField
              className="preview__details--textfield"
              label="Gender"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={TeacherData && TeacherData.node.emp_sex}
              disabled
            />
          </div>
        </div>
        <div className={`teacher-wise-allocated-reports__tableblock`}>
          <TableContainer className="teacher-dashboard__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{TableHeaders.SLNO}</TableCell>
                  {USE_DEPARTMENT_KEY && (
                    <TableCell>{departmentLabel}</TableCell>
                  )}
                  {USE_BRANCH_KEY && <TableCell>{branchLabel}</TableCell>}
                  {USE_CLASS_KEY && <TableCell>{classLabel}</TableCell>}
                  {USE_SEMESTER_KEY && <TableCell>{semesterLabel}</TableCell>}
                  {USE_SECTION_KEY && <TableCell>{sectionLabel}</TableCell>}
                  <TableCell>{TableHeaders.STATUS}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nodeData &&
                  nodeData.nodes.map((data: any, index: number) => {
                    let deptDesc,
                      branchDesc,
                      classDesc,
                      semDesc,
                      sectionDesc,
                      entryId: string | number;
                    switch (entry_level) {
                      case AcdSubjectAllocationData.CLASS:
                        deptDesc =
                          data.acd_branch_details.acd_dept_details.dept_desc;
                        branchDesc = data.acd_branch_details.branch_desc;
                        classDesc = data.class_desc;
                        entryId = data.id;
                        break;
                      case AcdSubjectAllocationData.SEMESTER:
                        deptDesc =
                          data.acd_class_details.acd_branch_details
                            .acd_dept_details.dept_desc;
                        branchDesc =
                          data.acd_class_details.acd_branch_details.branch_desc;
                        classDesc = data.acd_class_details.class_desc;
                        semDesc = data.sem_desc;
                        entryId = data.id;

                        break;
                      case AcdSubjectAllocationData.SECTION:
                        deptDesc =
                          data.acd_semester_details.acd_class_details
                            .acd_branch_details.acd_dept_details.dept_desc;
                        branchDesc =
                          data.acd_semester_details.acd_class_details
                            .acd_branch_details.branch_desc;
                        classDesc =
                          data.acd_semester_details.acd_class_details
                            .class_desc;
                        semDesc = data.acd_semester_details.sem_desc;
                        sectionDesc = data.section_desc;
                        entryId = data.id;

                        break;
                      default:
                        break;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="teacher-dashboard__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        {deptDesc !== "---" && (
                          <TableCell>{deptDesc}</TableCell>
                        )}
                        <TableCell>{branchDesc}</TableCell>
                        <TableCell>{classDesc}</TableCell>
                        {semDesc !== "---" && <TableCell>{semDesc}</TableCell>}
                        {sectionDesc !== "---" && (
                          <TableCell>{sectionDesc}</TableCell>
                        )}
                        <TableCell
                          className="teacher-dashboard__table--actions"
                          id="td-center"
                        >
                          <button className="teacher-dashboard__table--view">
                            View
                            <img
                              src={View}
                              alt=""
                              onClick={() => {
                                navigate(
                                  `/${InstId}/academics/dailyactivities/${entryId}/persessiondetails`
                                );
                              }}
                            />
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={view}
        style={ViewDetails}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewSubjectsForTeacher
              setModal={setView}
              subjectDetails={subjectDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setView(!view)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PerTeacherClassDetails;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import AdmissionHome from "../../Home/Index";
import Accountsome from "../../../Accounts/Home/Index";

import Modal from "react-modal";
import {
  AboutChannelModalStyles,
  StudentModalStyles,
  WaiveOffModalStyles,
} from "../../../../styles/ModalStyles";
import AccountStudentList from "../../../Accounts/common/StudentList";
import {
  Direction,
  LicenseTypes,
  Operation,
  PageFor,
  PredefinedDataTypes,
  SortBy,
  StudentAcctReportType,
  StudentListFor,
  YesNo,
} from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";
import Edit from "../../../../images/Edit.svg";
import Avatar from "../../../../images/Avatar.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { useNavigate, useParams } from "react-router-dom";
import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { msgType, optionsType, responseType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import { AppContext } from "../../../../context/context";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../customhooks/useDropdownData";
import {
  FeeDetails,
  ListDemandDetailsData,
  ListDemandDetailsVars,
} from "../../../../Types/Accounting";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import {
  RefsByTagName,
  formatter,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import { GetAcctDemandDetails } from "../../../Accounts/queries/FeeLedgers/query/Byid";
import { ChangeStudentBranch } from "../../../../queries/students/mutations/update";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";

import ViewChangedStudent from "../../../../Modules/Master/Student/ChangeOfDepartment/ViewChangedStudent";
import { payloadTypes } from "../../../../context/reducer";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import useNotAlloactedIdFromInst from "../../../../customhooks/useNotAlloactedIdFromInst";
import {
  GetStudentBranchChange,
  GetStudentBranchChangeByStdID,
} from "../../../../queries/students/list/newApi";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { Label } from "../../../../stories/Label/Label";
import Right from "../../../../images/Correct.svg";

import Print from "../../../../images/PrintPreviousReceipt.svg";
import ChangeOfBranchVoucherInterchange, {
  GetStudentBranchChangeByStdIDData,
  GetStudentBranchChangeByStdIDDataVars,
} from "./ChangeOfBranchVoucherInterchange";
import useAcctStdAdmissionNumber from "../../../Accounts/hooks/useAcctStdAdmissionNumber";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { InstitutionAcctConfigurationTypes } from "../../../Accounts/common/Enum.types";
import { CheckVouchersTransferStatus } from "../../../Accounts/queries/Vouchers/query";
import {
  CheckVouchersTransferStatusData,
  CheckVouchersTransferStatusDataDetails,
  CheckVouchersTransferStatusVars,
} from "../../../Accounts/common/types";
import { acct_std_demand_details } from "../../../Accounts/hooks/useStudentsbyDemandAmount";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
interface Props {
  pageFor: LicenseTypes.ACCOUNTS | LicenseTypes.ADMISSION;
}
const Index = ({ pageFor }: Props) => {
  const navigate = useNavigate();
  const { format } = formatter;
  const { InstId } = useParams();
  const { token } = useToken();
  const { Masters_Table } = useMasterTableJson();

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();

  const deptAutocompleteRef = useRef<HTMLInputElement>(null);
  const deptInputRef = RefsByTagName(deptAutocompleteRef!);
  const branchAutocompleteRef = useRef<HTMLInputElement>(null);
  const branchInputRef = RefsByTagName(branchAutocompleteRef!);
  const classAutocompleteRef = useRef<HTMLInputElement>(null);
  const classInputRef = RefsByTagName(classAutocompleteRef!);
  const semAutocompleteRef = useRef<HTMLInputElement>(null);
  const semInputRef = RefsByTagName(semAutocompleteRef!);
  const sectionAutocompleteRef = useRef<HTMLInputElement>(null);
  const sectionInputRef = RefsByTagName(semAutocompleteRef!);
  const categoryAutocompleteRef = useRef<HTMLInputElement>(null);
  const categoryInputRef = RefsByTagName(categoryAutocompleteRef!);
  const studentAutoCompleteRef = useRef<HTMLInputElement>(null);
  const studentRef = RefsByTagName(studentAutoCompleteRef!);

  let receiptTotalAmount = 0;
  let totalReceivedAmount = 0;
  let totalbalance = 0;
  // let existingStudentData: FeeDetails[] = [];
  // let NewStudentData: FeeDetails[] = [];

  const { dispatch, state } = useContext(AppContext);
  const { InstDetails } = useInstDetails(1);
  const [studentModal, setStudentModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [initiateReceiptModal, setInitiateReceiptModal] = useState(false);
  const [interChangeReceipt, setInterChangeReceipt] = useState(false);
  const [tableViewModal, setTableViewModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [oldDemandDetails, setOldDemandDetails] = useState<
    acct_std_demand_details[]
  >([]);
  const [newstudentData, setNewStudentData] = useState<FeeDetails[]>([]);
  const [existingStudentData, setExistingData] = useState<FeeDetails[]>([]);
  const [admNo, setAdmNo] = useState("");

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );

  const [selectedStudent, setSelectedStudent] = useState<responseType | null>(
    null
  );
  const [fresher, setFresher] = useState("");
  // eslint-disable-next-line
  const [imageString, setImageString] = useState("");
  const [vouchers, setVouchers] = useState<
    CheckVouchersTransferStatusDataDetails[]
  >([]);

  const [newDemandDetails, setNewDemandDetails] = useState<FeeDetails[]>([]);

  const { user_details } = useLoggedInUserDetails();
  const {
    PredefinedData: { dropDown: Fresher },
  } = usePredefinedDataByType(PredefinedDataTypes.YESNO, EMPTY_STRING);

  // //lazy queries
  const [GetVoucherStatus] = useLazyQuery<
    CheckVouchersTransferStatusData,
    CheckVouchersTransferStatusVars
  >(CheckVouchersTransferStatus);
  const [AccountDemandDetails] = useLazyQuery<
    ListDemandDetailsData,
    ListDemandDetailsVars
  >(GetAcctDemandDetails);
  const [
    GetStudentDetails,
    { data: changeOfBranchDetails, error, loading: stdChangeBranchLoading },
  ] = useLazyQuery<
    GetStudentBranchChangeByStdIDData,
    GetStudentBranchChangeByStdIDDataVars
  >(GetStudentBranchChangeByStdID);
  const {
    configData: { data: AllowChangeOfBranch },
  } = useSwConfigData(InstitutionAcctConfigurationTypes.ALLOW_CHANGE_OF_BRANCH);
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    pageFor === LicenseTypes.ADMISSION
      ? StudentAcctReportType.DEMAND_NOT_RAISED
      : StudentAcctReportType.DEMAND_RAISED
  );

  const [UpdateStudentBranch, { loading }] = useMutation(ChangeStudentBranch, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { studentData } = useStudentDatabyId();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();
  const { NotAllocatedDeptId, NotAllocatedCategoryId } =
    useNotAlloactedIdFromInst();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
    masterIds,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0
  );
  const fresherData = fresher === YesNo.YES ? true : false;

  // const { acct_std_demand } = studentData.data?.nodes[0] || {};
  const { categoryDropDown } = useInstMasterDataByInstId();
  // eslint-disable-next-line
  let totalReceivableAmt = 0;
  oldDemandDetails.forEach((demand) => {
    totalbalance += demand.fee_bal;
    totalReceivedAmount += demand.fee_received;
    totalReceivableAmt += demand.fee_receivable;
  });

  // eslint-disable-next-line
  vouchers.map((receipt) => {
    receiptTotalAmount += receipt.voucher_master_details.v_std_amt_total;
  });
  let newStudentSum = 0;
  let existingStudentSum = 0;

  existingStudentData.forEach(({ fee_for_all, fee_for_exs_std }) => {
    existingStudentSum += fee_for_all + fee_for_exs_std;
  });
  newstudentData.forEach(({ fee_for_all, fee_for_new_std }) => {
    newStudentSum += fee_for_all + fee_for_new_std;
  });
  let conditionsMet = false;
  switch (true) {
    case USE_DEPARTMENT_KEY &&
      studentData.data?.nodes[0]?.dept.id ===
        (departmentSelected?.value || null):
    case studentData.data?.nodes[0]?.branch.id === masterIds.branchId:
    case studentData.data?.nodes[0]?.class.id === masterIds.classId:
    case USE_SEMESTER_KEY &&
      studentData.data?.nodes[0]?.semester.id === masterIds.semesterId:
    case USE_SECTION_KEY &&
      studentData.data?.nodes[0]?.section.id === masterIds.sectionId:
    case USE_CATEGORY_KEY &&
      studentData.data?.nodes[0]?.category.id ===
        (categorySelected?.value || null):
    case studentData.data?.nodes[0]?.std_fresher === fresherData:
      conditionsMet = true;
      break;
  }
  const handleChangeBranch = () => {
    UpdateStudentBranch({
      variables: {
        token,
        module_name:
          pageFor === LicenseTypes.ACCOUNTS ? "ACCOUNTS" : "ADMISSION",
        student_id: state.studentId,
        inst_id: InstId!,
        user_details,
        input: {
          dept_id: USE_DEPARTMENT_KEY
            ? departmentSelected && departmentSelected.value
            : NotAllocatedDeptId,
          branch_id: masterIds.branchId,
          class_id: masterIds.classId,
          sem_id: masterIds.semesterId,
          section_id: masterIds.sectionId,
          category_id: USE_CATEGORY_KEY
            ? categorySelected && categorySelected.value
            : NotAllocatedCategoryId,
          std_fresher: fresher === YesNo.YES ? true : false,
        },
      },
      refetchQueries: [
        {
          query: GetStudentBranchChange,
          variables: {
            name: EMPTY_STRING,
            first: ROWS_PER_PAGE,
            after: null,
            sortBy: SortBy.CREATED_AT,
            direction: Direction.ASC,
            token,
            inst_id: InstId!,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
        {
          query: CheckVouchersTransferStatus,
          variables: {
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            inst_id: InstId!,
            student_id: state.studentId,
            token,
            change_of_branch_id:
              changeOfBranchDetails?.GetStudentBranchChangeByStdID.id ?? 0,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        if (pageFor === LicenseTypes.ACCOUNTS) {
          setInitiateReceiptModal(true);
        } else {
          setMessage({
            flag: true,
            message: "Sucessfully Updated  New Master Details",
            operation: Operation.UPDATE,
          });
        }
      }
    });
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });
    setNewDemandDetails([]);
    setExistingData([]);
    setOldDemandDetails([]);
    setVouchers([]);
    setNewStudentData([]);
    setAdmNo("");
    setSelectedStudent(null);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state.ActiveFinYr && state.studentId) {
          const { id: fin_yr_id } = state.ActiveFinYr;

          const { data } = await AccountDemandDetails({
            variables: {
              token,
              branch_id: branchSelected ? Number(branchSelected.value) : 0,
              category_id: categorySelected
                ? Number(categorySelected.value)
                : 0,
              class_id: classSelected ? Number(classSelected.value) : 0,
              fin_yr_id,
              inst_id: InstId!,
            },
          });
          setExistingData([]);
          setNewStudentData([]);

          if (data) {
            const { GetAcctDemandDetails } = data;

            GetAcctDemandDetails?.map(
              ({
                fee_for_all,
                fee_for_exs_std,
                fee_for_new_std,
                fee_due_date,
                sl_no,
                acct_ldgr_id,
                acct_ldgr_details,
                // eslint-disable-next-line
              }) => {
                if (fee_for_all !== 0) {
                  setExistingData((prevData) => [
                    ...prevData,
                    {
                      fee_for_all,
                      fee_for_exs_std,
                      fee_for_new_std,
                      fee_due_date,
                      sl_no,
                      acct_ldgr_id,
                      acct_ldgr_details,
                    },
                  ]);
                  setNewStudentData((prevData) => [
                    ...prevData,
                    {
                      fee_for_all,
                      fee_for_exs_std,
                      fee_for_new_std,
                      fee_due_date,
                      sl_no,
                      acct_ldgr_id,
                      acct_ldgr_details,
                    },
                  ]);
                }
                if (fee_for_exs_std !== 0) {
                  setExistingData((prevData) => [
                    ...prevData,
                    {
                      fee_for_all,
                      fee_for_exs_std,
                      fee_for_new_std,
                      fee_due_date,
                      sl_no,
                      acct_ldgr_id,
                      acct_ldgr_details,
                    },
                  ]);
                }
                if (fee_for_new_std !== 0) {
                  setNewStudentData((prevData) => [
                    ...prevData,
                    {
                      fee_for_all,
                      fee_for_exs_std,
                      fee_for_new_std,
                      fee_due_date,
                      sl_no,
                      acct_ldgr_id,
                      acct_ldgr_details,
                    },
                  ]);
                }
              }
            );
          }
        }
      } catch (error) {
        alert("Demand Structure for the chosen option was not found.");
      }
    };

    fetchData();
  }, [
    token,
    AccountDemandDetails,
    branchSelected,
    classSelected,
    InstId,
    categorySelected,
    state.ActiveFinYr,
    state.studentId,
  ]);

  useEffect(() => {
    if (
      studentData.data &&
      !studentData.loading &&
      state.studentId > 0 &&
      InstDetails.data
    ) {
      const { acct_std_demand, std_profile_filename } =
        studentData.data.nodes[0];
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      setOldDemandDetails(acct_std_demand.demand_details);
      std_profile_filename !== EMPTY_STRING &&
        getDownloadUrl(studentProfiePicUrl, false, setImageString);
      // setNewDemandDetails(fresherData ? newstudentData : existingStudentData);
    }
  }, [
    newstudentData,
    existingStudentData,
    studentData.data,
    studentData.loading,
    state.studentId,
    fresherData,
    InstDetails.data,
  ]);

  useEffect(() => {
    if (fresher !== EMPTY_STRING) {
      setNewDemandDetails(fresherData ? newstudentData : existingStudentData);
    } else {
      setNewDemandDetails([]);
    }
  }, [fresher, fresherData]);
  useEffect(() => {
    if (token && state.studentId && InstId && state.ActiveFinYr) {
      GetStudentDetails({
        variables: {
          inst_id: InstId!,
          student_id: state.studentId,
          token,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      });
    }
  }, [state.studentId, GetStudentDetails, token, InstId, state.ActiveFinYr]);
  useEffect(() => {
    if (
      (state.ActiveFinYr && changeOfBranchDetails && !stdChangeBranchLoading) ||
      (error && !stdChangeBranchLoading)
    ) {
      GetVoucherStatus({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          inst_id: InstId!,
          student_id: state.studentId,
          token,
          change_of_branch_id:
            changeOfBranchDetails?.GetStudentBranchChangeByStdID.id ?? 0,
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
      }).then(({ data, variables }) => {
        if (data) {
          setVouchers(data.CheckVouchersTransferStatus);
        }
      });
    }
  }, [
    state.ActiveFinYr,
    GetVoucherStatus,
    state.studentId,
    token,
    InstId,
    changeOfBranchDetails,
    error,
    stdChangeBranchLoading,
    interChangeReceipt,
  ]);
  const handleConfirmationModal = () => {
    if (state.studentId === 0) {
      studentRef.focus();
    } else if (!departmentSelected && deptInputRef && USE_DEPARTMENT_KEY) {
      deptInputRef.focus();
      return;
    } else if (!branchSelected && branchInputRef && USE_BRANCH_KEY) {
      branchInputRef.focus();
      return;
    } else if (!classSelected && classInputRef && USE_CLASS_KEY) {
      classInputRef.focus();
      return;
    } else if (!semesterSelected && semInputRef && USE_SEMESTER_KEY) {
      semInputRef.focus();
      return;
    } else if (!sectionSelected && sectionInputRef && USE_SECTION_KEY) {
      sectionInputRef.focus();
      return;
    } else if (!categorySelected && categoryInputRef && USE_CATEGORY_KEY) {
      categoryInputRef.focus();
      return;
    } else setConfirmationModal(!confirmationModal);
  };
  return (
    <>
      {pageFor === LicenseTypes.ACCOUNTS ? (
        <Accountsome DashBoardRequired={false} />
      ) : (
        <AdmissionHome DashBoardRequired={false} />
      )}
      <Title>
        Change of
        {USE_DEPARTMENT_KEY && <> {departmentLabel} /</>}
        {USE_BRANCH_KEY && <> {branchLabel} /</>}
        {USE_CLASS_KEY && <> {classLabel} /</>}
        {USE_SEMESTER_KEY && <> {semesterLabel} /</>}
        {USE_SECTION_KEY && <> {sectionLabel} /</>}
        {USE_CATEGORY_KEY && <> {categoryLabel}</>}
      </Title>
      <div className="change-of-dept">
        <div className="row g-0 change-of-dept__details">
          <div className="col">
            <div className="row change-of-dept__details--row">
              <div className="col-2">
                <div className="change-of-dept__details--imagefield">
                  {/* <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={studentAddmissionNumber}
                    value={
                      studentAddmissionNumber?.find(
                        ({ label }) =>
                          label === studentData.data?.nodes[0]?.std_adm_no ??
                          admNo
                      )! ?? null
                    }
                    openOnFocus
                    autoHighlight
                    onChange={(e, newValue) => {
                      if (newValue) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: (newValue as responseType)?.value,
                          },
                        });
                      } else {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                      }
                    }}
                    // popupIcon={
                    //   <img
                    //     src={DownArrow}
                    //     alt="/"
                    //     className="down-arrow-autocomplete"
                    //   />
                    // }
                    forcePopupIcon
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                        setAdmNo("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Admission Number"
                        autoFocus
                        onChange={(e) => setAdmNo(e.target.value)}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                  */}
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={studentAddmissionNumber}
                    value={selectedStudent}
                    ref={studentAutoCompleteRef}
                    openOnFocus
                    onChange={(e, newValue) => {
                      if (newValue) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: (newValue as responseType)?.value,
                          },
                        });
                        setSelectedStudent(newValue as responseType);
                      } else {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                        setSelectedStudent(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                        setSelectedStudent(null);
                        setAdmNo("");
                      }
                    }}
                    // freeSolo
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => setAdmNo(e.target.value)}
                        label="Admission Number"
                        autoFocus
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                  <img
                    className="data-fetch-icon"
                    src={Edit}
                    alt="/"
                    onClick={() => setStudentModal(!studentModal)}
                  />
                </div>
              </div>
              <div className="col-2">
                <TextField
                  label="Reg No."
                  disabled
                  value={
                    state.studentId
                      ? studentData.data?.nodes[0]?.std_reg_no
                      : EMPTY_STRING
                  }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className="change-of-dept__details--textfield"
                />
              </div>
              <div className="col-2">
                <TextField
                  label="Name"
                  disabled
                  value={
                    state.studentId
                      ? studentData.data?.nodes[0]?.first_name +
                        " " +
                        studentData.data?.nodes[0]?.middle_name +
                        " " +
                        studentData.data?.nodes[0]?.last_name
                      : EMPTY_STRING
                  }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className="change-of-dept__details--textfield"
                />
              </div>
              <div className="col-2">
                <TextField
                  label="Gender"
                  disabled
                  value={
                    state.studentId
                      ? studentData.data?.nodes[0]?.std_sex
                      : EMPTY_STRING
                  }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className="change-of-dept__details--textfield"
                />
              </div>
              <div className="col-2">
                <TextField
                  label="Mobile No."
                  disabled
                  value={
                    state.studentId
                      ? studentData.data?.nodes[0]?.std_mobile
                      : EMPTY_STRING
                  }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className="change-of-dept__details--textfield"
                />
              </div>
              <div className="col-2">
                <TextField
                  label="DOA"
                  disabled
                  value={
                    state.studentId
                      ? toStandardDate(studentData.data?.nodes[0]?.std_doa!)
                      : EMPTY_STRING
                  }
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className="change-of-dept__details--textfield"
                />
              </div>
            </div>

            <div className="row  change-of-dept__details--row">
              {USE_DEPARTMENT_KEY ? (
                <div className="col-2">
                  <TextField
                    label={departmentLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    value={
                      state.studentId
                        ? studentData.data?.nodes[0]?.dept?.dept_desc
                        : EMPTY_STRING
                    }
                    className="change-of-dept__details--textfield"
                  />
                </div>
              ) : null}
              {USE_BRANCH_KEY ? (
                <div className="col-2">
                  <TextField
                    label={branchLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    value={
                      state.studentId
                        ? studentData.data?.nodes[0]?.branch?.branch_desc
                        : EMPTY_STRING
                    }
                    className="change-of-dept__details--textfield"
                  />
                </div>
              ) : null}
              {USE_CLASS_KEY ? (
                <div className="col-2">
                  <TextField
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    value={
                      state.studentId
                        ? studentData.data?.nodes[0]?.class?.class_desc
                        : EMPTY_STRING
                    }
                    className="change-of-dept__details--textfield"
                  />
                </div>
              ) : null}
              {USE_SEMESTER_KEY ? (
                <div className="col-2">
                  <TextField
                    label={semesterLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    value={
                      state.studentId
                        ? studentData.data?.nodes[0]?.semester?.sem_desc
                        : EMPTY_STRING
                    }
                    className="change-of-dept__details--textfield"
                  />
                </div>
              ) : null}
              {USE_SECTION_KEY ? (
                <div className="col-2">
                  <TextField
                    label={sectionLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    value={
                      state.studentId
                        ? studentData.data?.nodes[0]?.section?.section_desc
                        : EMPTY_STRING
                    }
                    className="change-of-dept__details--textfield"
                  />
                </div>
              ) : null}
              {USE_CATEGORY_KEY ? (
                <div className="col-1">
                  <TextField
                     label={categoryLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    disabled
                    value={
                      state.studentId
                        ? studentData.data?.nodes[0]?.category?.cat_desc
                        : EMPTY_STRING
                    }
                    className="change-of-dept__details--textfield"
                  />
                </div>
              ) : null}
              <div className="col-1">
                <TextField
                  label="Fresher"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  disabled
                  value={
                    state.studentId
                      ? studentData.data?.nodes[0]?.std_fresher
                        ? "YES"
                        : "NO"
                      : EMPTY_STRING
                  }
                  className="change-of-dept__details--textfield"
                />
              </div>
            </div>
            <form className="row  change-of-dept__details--row">
              {USE_DEPARTMENT_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={departmentDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, departmentSelected)
                    }
                    ref={deptAutocompleteRef!}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setDepartmentSelected(null);
                        setBranchSelected(null);
                        setClassSelected(null);
                        setSemesterSelected(null);
                        setSectionSelected(null);
                      }
                    }}
                    openOnFocus
                    value={departmentSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setDepartmentSelected(newValue as responseType);
                      } else {
                        setDepartmentSelected(null);
                      }
                      setBranchSelected(null);
                      setClassSelected(null);
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`New ${departmentLabel}`}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                        required
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_BRANCH_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={branchDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, branchSelected)
                    }
                    ref={branchAutocompleteRef!}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setBranchSelected(null);
                        setClassSelected(null);
                        setSemesterSelected(null);
                        setSectionSelected(null);
                      }
                    }}
                    openOnFocus
                    value={branchSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setBranchSelected(newValue as responseType);
                      } else {
                        setBranchSelected(null);
                      }
                      setClassSelected(null);
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`New ${branchLabel}`}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                        required
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_CLASS_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={classDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, classSelected)
                    }
                    ref={classAutocompleteRef!}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setClassSelected(null);
                        setSemesterSelected(null);
                        setSectionSelected(null);
                      }
                    }}
                    openOnFocus
                    value={classSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setClassSelected(newValue as responseType);
                      } else {
                        setClassSelected(null);
                      }
                      setSemesterSelected(null);
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`New ${classLabel}`}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                        fullWidth
                        required
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_SEMESTER_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={semesterDropDown}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semesterSelected)
                    }
                    ref={semAutocompleteRef!}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setSemesterSelected(null);
                        setSectionSelected(null);
                      }
                    }}
                    openOnFocus
                    value={semesterSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSemesterSelected(newValue as responseType);
                      } else {
                        setSemesterSelected(null);
                      }
                      setSectionSelected(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`New ${semesterLabel}`}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                        required
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_SECTION_KEY ? (
                <div className="col-2">
                  <LabeledAutocomplete
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, sectionSelected)
                    }
                    className={labelClasses.inputRoot}
                    options={sectionDropDown}
                    ref={sectionAutocompleteRef}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setSectionSelected(null);
                      }
                    }}
                    openOnFocus
                    value={sectionSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSectionSelected(newValue as responseType);
                      } else {
                        setSectionSelected(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`New ${sectionLabel}`}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                        required
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_CATEGORY_KEY ? (
                <div className="col-1">
                  <LabeledAutocomplete
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, categorySelected)
                    }
                    className={labelClasses.inputRoot}
                    options={categoryDropDown}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setCategorySelected(null);
                      }
                    }}
                    openOnFocus
                    value={categorySelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setCategorySelected(newValue as responseType);
                      } else {
                        setCategorySelected(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`New ${categoryLabel}`}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        className={labelClasses.formControlRoot}
                        required
                      />
                    )}
                  />
                </div>
              ) : null}
              <div className="col-1">
                <LabeledAutocomplete
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, categorySelected)
                  }
                  className={labelClasses.inputRoot}
                  options={Fresher!}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setFresher(EMPTY_STRING);
                    }
                  }}
                  openOnFocus
                  value={
                    Fresher?.find(({ value }) => value === fresher)! ??
                    EMPTY_STRING
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setFresher((newValue as optionsType).value);
                    } else {
                      setFresher(EMPTY_STRING);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fresher"
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      className={labelClasses.formControlRoot}
                      required
                    />
                  )}
                />
              </div>
            </form>
          </div>

          <div className="col-1 h-100 change-of-dept__details--studentprofile">
            <img src={imageString ? imageString : Avatar} alt="/" />
          </div>
        </div>

        <div className="row g-0 change-of-dept__tableblock">
          <div className="col-5 h-100">
            <TableContainer className="change-of-dept__tableblock--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="change-of-dept__tableblock--table--groupheader">
                    <TableCell colSpan={5}>Demand</TableCell>
                  </TableRow>
                  <TableRow className="change-of-dept__tableblock--table--subheader">
                    {Masters_Table.Students.ChangeOfDepartment.PreviousDemand.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oldDemandDetails.map((demand, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="change-of-dept__tableblock--table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {demand.acct_ldgr_details.ldgr_desc}
                        </TableCell>
                        <TableCell
                          className="change-of-dept__tableblock--table--amount"
                          id="td-right"
                        >
                          {format(demand.fee_receivable)}
                        </TableCell>
                        <TableCell
                          className="change-of-dept__tableblock--table--amount"
                          id="td-right"
                        >
                          {format(demand.fee_received)}
                        </TableCell>
                        <TableCell
                          className="change-of-dept__tableblock--table--amount"
                          id="td-right"
                        >
                          {format(demand.fee_bal)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className="total" colSpan={2}>
                      Total:
                    </TableCell>
                    <TableCell className="totalcount">
                      {format(totalReceivableAmt)}
                    </TableCell>
                    <TableCell className="totalcount">
                      {format(totalReceivedAmount)}
                    </TableCell>
                    <TableCell className="totalcount">
                      {format(totalbalance)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <div className="col h-100">
            <TableContainer className="change-of-dept__tableblock--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="change-of-dept__tableblock--table--groupheader">
                    <TableCell colSpan={2}>Receipts</TableCell>
                  </TableRow>
                  <TableRow className="change-of-dept__tableblock--table--subheader">
                    {Masters_Table.Students.ChangeOfDepartment.Receipts.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vouchers.map((receipt, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {receipt.voucher_master_details.v_no}
                        </TableCell>
                        <TableCell
                          className="change-of-dept__tableblock--table--amount"
                          id="td-right"
                        >
                          {format(
                            receipt.voucher_master_details.v_std_amt_total
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className="total">Total:</TableCell>
                    <TableCell className="totalcount">
                      {format(receiptTotalAmount)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <div className="col h-100">
            <TableContainer className="change-of-dept__tableblock--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="change-of-dept__tableblock--table--groupheader">
                    <TableCell colSpan={2}>New Demand</TableCell>
                  </TableRow>
                  <TableRow className="change-of-dept__tableblock--table--subheader">
                    {Masters_Table.Students.ChangeOfDepartment.NewDemand.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newDemandDetails.map((data, index) => {
                    return (
                      <TableRow key={index + 1}>
                        <TableCell>
                          {data.acct_ldgr_details?.ldgr_desc}
                        </TableCell>
                        <TableCell
                          className="change-of-dept__tableblock--table--amount"
                          id="td-right"
                        >
                          {data.fee_for_all !== 0
                            ? format(data.fee_for_all)
                            : format(
                                fresherData
                                  ? data.fee_for_new_std
                                  : data.fee_for_exs_std
                              )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className="total">Total:</TableCell>
                    <TableCell className="totalcount">
                      {fresher !== EMPTY_STRING
                        ? fresherData
                          ? format(newStudentSum)
                          : format(existingStudentSum)
                        : 0}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button
          mode="save"
          onClick={() => {
            // if (
            //   conditionsMet
            // ) {
            //    setMessage({
            //     flag: true,
            //     message: "Cannot save student details with same details",
            //     operation: Operation.NONE,
            //   });
            // } else {
            handleConfirmationModal();
            // }
          }}
          disabled={
            (AllowChangeOfBranch?.GetSwConfigVariables[0]
              .config_boolean_value &&
              pageFor === LicenseTypes.ACCOUNTS) ||
            pageFor === LicenseTypes.ADMISSION
              ? false
              : true
          }
        />
        {LicenseTypes.ACCOUNTS === pageFor && (
          <Button
            onClick={() => setInitiateReceiptModal(!initiateReceiptModal)}
          >
            <img
              src={Print}
              alt=""
              className="initiate-receipt-transfer-image"
            />
            Initiate Receipt Transfer
          </Button>
        )}
        <Button
          mode="view"
          onClick={() => setTableViewModal(!tableViewModal)}
        />
        <Button mode="clear" onClick={() => handleClear()} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      {/* Confirmation Modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={confirmationModal}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex">
          <div className="modal-flex__data">
            <Title>Demand Gets Delete</Title>
            <Label>
              Old Demand Gets Deleted & New Demand Will Be Created. Do You Wish
              to Continue
            </Label>

            <Button
              mode="okay"
              onClick={() => {
                handleChangeBranch();
                setConfirmationModal(!confirmationModal);
              }}
            />
            <Button
              mode="cancel"
              onClick={() => setConfirmationModal(!confirmationModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setConfirmationModal(!confirmationModal)}
            />
          </div>
        </div>
      </Modal>

      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {/* {pageFor === LicenseTypes.ACCOUNTS ? ( */}
            <AccountStudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.GENERAL}
              queryType={
                pageFor === LicenseTypes.ADMISSION
                  ? StudentAcctReportType.DEMAND_NOT_RAISED
                  : StudentAcctReportType.DEMAND_RAISED
              }
              setStudentModal={setStudentModal}
            />
            {/* // ) : (
            //   <AdmissionStudentList
            //     pageType={PageFor.MODAL}
            //     studentListFor={StudentListFor.GENERAL}
            //     queryType={StudentReportType.GENERAL}
            //     setStudentModal={setStudentModal}
            //     studentModal={studentModal}
            //   />
            // )} */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      {/* viewmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={tableViewModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewChangedStudent setTableViewModal={setTableViewModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setTableViewModal(!tableViewModal);
                dispatch({
                  type: payloadTypes.SET_CHANGE_OF_BRANCH_STUDENT_ID,
                  payload: {
                    changeOfBranchStudentId: 0,
                  },
                });
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={initiateReceiptModal}
        style={AboutChannelModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="change-of-dept__receipt-details">
              <Title>Receipt Details</Title>

              <div className="change-of-dept__receipt-details--data">
                <ul>
                  {vouchers.map((voucher) => {
                    return (
                      <li key={voucher.voucher_master_details.id}>
                        <div>
                          <span className="change-of-dept__receipt-details--data--receipts">
                            {voucher.voucher_master_details.v_no}
                          </span>
                          <span className="change-of-dept__receipt-details--data--amount">
                            {format(
                              voucher.voucher_master_details.v_std_amt_total
                            )}
                          </span>
                        </div>

                        {voucher.status ? (
                          <img src={Right} alt="" />
                        ) : (
                          <Button
                            mode="transfer-receipt"
                            onClick={() => {
                              dispatch({
                                type: payloadTypes.SET_RECEPIT_ID,
                                payload: {
                                  receiptId: voucher.voucher_master_details.id,
                                },
                              });
                              setInterChangeReceipt(!interChangeReceipt);
                            }}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
                <div className="change-of-dept__receipt-details--data--balance">
                  <span className="change-of-dept__receipt-details--data--new-demand">
                    New Demand Balance
                  </span>
                  <span className="change-of-dept__receipt-details--data--demand-balance">
                    {format(
                      studentData.data?.nodes[0].acct_std_demand
                        .std_demand_bal! ?? 0
                    )}
                  </span>
                </div>
              </div>
              <Button
                mode="cancel"
                onClick={() => {
                  handleClear();
                  setInitiateReceiptModal(!initiateReceiptModal);
                }}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setInitiateReceiptModal(!initiateReceiptModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={interChangeReceipt}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ChangeOfBranchVoucherInterchange
              setModalFlag={setInterChangeReceipt}
              handleClear={handleClear}
            />
          </div>
          <div className="modal-flex__image h-100">
            <img
              src={Close}
              alt=""
              onClick={() => setInterChangeReceipt(!interChangeReceipt)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

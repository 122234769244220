import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/context";

import Pointer from "../../../../images/Pointer.svg";

import { BankOrCash, ReceiptTypes, SortBy } from "../../../../utils/Enum.types";

import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";

import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";

import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import useGroupLedgerData, {
  GetAcctGroupLdgrsDetailsNode,
} from "../../hooks/useGroupLedgerData";
import { responseType } from "../../../../utils/Form.types";
import { payloadTypes } from "../../../../context/reducer";
import {
  AcctGroupLdgrQueryType,
  AcctLedgerQueryType,
} from "../../common/QueryTypes";
import {
  LedgersOrRegisters,
  OtherAccountLedgerBooks,
} from "../../../../utils/types";

const registers = [
  { value: ReceiptTypes.STUDENT_RECEIPT, label: "Student Receipt" },
  { value: ReceiptTypes.PYMT, label: "Payment Register" },
  { value: ReceiptTypes.RCPT, label: "General Receipt Register" },
  // { value: ReceiptTypes.CONTRA, label: "Contra Register" },
  { value: ReceiptTypes.JOURNAL, label: "Journal Register" },
];
interface props {
  type: LedgersOrRegisters;
}

const AccountLedger = ({ type }: props) => {
  const { dispatch, state } = useContext(AppContext);
  const [searchData, setSearchData] = useState("");
  const [selectedRegister, setSelectedRegister] = useState("");

  //bank list
  const [groupLedgerId, setGroupLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [groupLedgerDetails, setGroupLedgerDetails] =
    useState<GetAcctGroupLdgrsDetailsNode>();

  const GroupLedgerReports = [
    { label: "OB", value: groupLedgerDetails?.gr_ldgr_ob },
    { label: "Credit", value: groupLedgerDetails?.gr_ldgr_total_cr },
    { label: "Debit", value: groupLedgerDetails?.gr_ldgr_total_db },
    { label: "Closing Balance", value: groupLedgerDetails?.gr_ldgr_cb },
  ];

  // const [groupLedgers, setGroupLedgers] = useState<GroupLedgerEdge[]>([]);
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");

  const {
    groupLedgers: { data },
  } = useGroupLedgerData(
    searchData,
    AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID
  );
  const [queryType, setQueryType] = useState("");

  const { acctLedgers } = useAcctLedgerData(
    searchData,
    queryType,
    ROWS_PER_PAGE
  );

  useEffect(() => {
    switch (type) {
      case BankOrCash.CASH:
        setQueryType(AcctLedgerQueryType.ACCT_LDGRS_CASH);
        break;
      case BankOrCash.BANK:
        setQueryType(AcctLedgerQueryType.ACCT_LDGRS_BANK);
        break;
      case BankOrCash.OTHERS:
        setQueryType(AcctLedgerQueryType.EXCEPT_CASH_AND_BANK);
        break;
      case BankOrCash.STD:
        setQueryType(AcctLedgerQueryType.STD_FEE_ACCT_LDGRS_FOR_REPORT);
        break;
      case BankOrCash.DEPOSIT:
        setQueryType(AcctLedgerQueryType.DEPOSIT_FEE_LDGRS);
        break;
      default:
        setQueryType("");
    }
  }, [type]);

  // const { acctLedgers: byGroupLedgerId } = useAcctLedgerData(
  //   searchData,
  //   AcctLedgerQueryType.ACCT_LDGR_BY_GROUP_LDGR_ID,
  //   ROWS_PER_PAGE,
  //   SortBy.LDGR_DESC,
  //   groupLedgerId.value
  // );

  // const groupLedger = groupLedgers?.map((option) => ({
  //   value: option.node.id,
  //   label: option.node.gr_ldgr_desc,
  // }));
  // useEffect(() => {
  //   if (type === AccountBookTypes.GROUP_LEDGER && data) {
  //     setGroupLedgers(data?.GetAcctGroupLdgrs.edges!);
  //   }
  // }, [type, InstId, token, data]);
  useEffect(() => {
    if (groupLedgerId && data) {
      const filteredData = data.GetAcctGroupLdgrs.edges.find(
        (edge) => edge.node.id === groupLedgerId.value
      );
      setGroupLedgerDetails(filteredData?.node!);
    }
  }, [groupLedgerId, data]);

  // const [enableAccountingLedger, SetEnableAccountingLedger] = useState(false);

  return (
    <>
      {type !== OtherAccountLedgerBooks.REGISTER &&
      type !== OtherAccountLedgerBooks.FEE_COLLECTED &&
      type !== OtherAccountLedgerBooks.GROUP_LEDGER ? (
        <TableContainer
          className="bank-book__table"
          onScroll={acctLedgers.handleScroll}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell> Ledger List</TableCell>
              </TableRow>
            </TableHead>

            <TableRow>
              <TableCell className="bank-book__group-ledger--table--input">
                <Input
                  placeholder="Search Ledgers"
                  id="search"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchData(e.target.value)
                  }
                  size="small"
                />
              </TableCell>
            </TableRow>
            <TableBody>
              {!acctLedgers.scrolledResponseType.length && (
                <TableRow>
                  <TableCell>no options</TableCell>
                </TableRow>
              )}
              {acctLedgers.scrolledResponseType.map(({ node }, index) => {
                return (
                  <TableRow
                    key={index}
                    className={
                      node.id === state.ledger?.value
                        ? "selected-row"
                        : EMPTY_STRING
                    }
                  >
                    <TableCell
                      onClick={() => {
                        dispatch({
                          type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                          payload: {
                            accountLedgerId: node.id,
                          },
                        });
                        dispatch({
                          type: payloadTypes.SET_LEDGER,
                          payload: {
                            ledger: {
                              label: node.ldgr_desc,
                              value: node.id,
                            },
                          },
                        });
                      }}
                      className="bank-book__table--ledger"
                    >
                      {node.id === state.ledger?.value ? (
                        <img src={Pointer} alt="/" className="pointer-icon" />
                      ) : (
                        EMPTY_STRING
                      )}
                      {node.ldgr_desc}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : type === OtherAccountLedgerBooks.REGISTER ? (
        <TableContainer className="bank-book__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Ledger List</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="bank-book__group-ledger--table--input">
                  <Input
                    size="small"
                    id="search"
                    placeholder="Search..."
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              {registers?.map((node, index) => {
                return (
                  <TableRow
                    key={index}
                    className={
                      selectedRegister === node.value
                        ? "selected-row"
                        : EMPTY_STRING
                    }
                  >
                    <TableCell
                      onClick={() => {
                        dispatch({
                          type: payloadTypes.SET_REGISTER,
                          payload: {
                            register: {
                              label: node.label,
                              value: node.value as string,
                            },
                          },
                        });
                        dispatch({
                          type: payloadTypes.SET_REGISTER_TYPE,
                          payload: {
                            registerType: node.value,
                          },
                        });
                        setSelectedRegister(node.value!);
                      }}
                    >
                      {selectedRegister === node.value ? (
                        <img src={Pointer} alt="/" className="pointer-icon" />
                      ) : (
                        EMPTY_STRING
                      )}
                      {node.label}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="bank-book__table--groupledger">
          <TableContainer className="bank-book__group-ledger--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Ledger List</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          {type === OtherAccountLedgerBooks.GROUP_LEDGER && (
            <>
              <div className="bank-book__group-ledger--group-ledger-table--yearly--details">
                {GroupLedgerReports.map((label, index) => {
                  return (
                    <div
                      className="bank-book__group-ledger--group-ledger-table--yearly--details--label-grid"
                      key={index}
                    >
                      <Label>{label.label}</Label>
                      <Label> - </Label>
                      <span className="bank-book__group-ledger--group-ledger-table--yearly--details--value">
                        {label.value}
                      </span>
                    </div>
                  );
                })}
              </div>

              {/* <TableContainer
                className="bank-book__group-ledger--account-ledger-table"
                onScroll={byGroupLedgerId?.handleScroll}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="bank-book__group-ledger--table--groupheader">
                      <TableCell>Accounting Ledger</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!byGroupLedgerId.scrolledResponseType?.length && (
                      <TableRow>
                        <TableCell>no options</TableCell>
                      </TableRow>
                    )}
                    {byGroupLedgerId?.scrolledResponseType.map(
                      (ledger, index) => {
                        return (
                          <TableRow
                            key={index}
                            className={
                              ledger.node.id === state.ledger?.value
                                ? "selected-row"
                                : EMPTY_STRING
                            }
                          >
                            <TableCell
                              onClick={() =>
                                dispatch({
                                  type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                  payload: {
                                    accountLedgerId: ledger.node.id,
                                  },
                                })
                              }
                            >
                              {ledger.node.id === state.ledger?.value ? (
                                <img
                                  src={Pointer}
                                  alt="/"
                                  className="pointer-icon"
                                />
                              ) : (
                                EMPTY_STRING
                              )}
                              {ledger.node.ldgr_desc}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AccountLedger;

import gql from "graphql-tag";

export const AddPayRollInstOrgChart = gql`
  mutation AddPayRollInstOrgChart(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $parent_id: ID!
    $input: CreatePayRollInstOrgChartInput!
  ) {
    AddPayRollInstOrgChart(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      parent_id: $parent_id
      input: $input
    ) {
      org_chart_details {
        id
        pr_dept_id
        pr_designation_id
        parent_id
        name_desc
        child_count
      }
      option_dept
      option_designation
    }
  }
`;

export const DeletePayRollInstOrgChartById = gql`
  mutation DeletePayRollInstOrgChartById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollInstOrgChartById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

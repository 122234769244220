import React, { useState } from "react";
import Avatar from "../../../../images/Avatar.svg";
import { LabelNameProps } from "../../../../Types/Labels";
import { TextField } from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import { getFileIcon } from "../../../../utils/UtilFunctions";
import Delete from "../../../../images/Delete.svg";
import Download from "../../../../images/DocumentsDownload.svg";
import Upload from "../../../../images/BrowseFiles.svg";
import { ViewPickedSubjectModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import UploadDocuments from "../New/Documents";
import { Operation } from "../../../../utils/Enum.types";
const { VMS_Form } = require("../../../json/form.json");
interface files {
  filename: string;
  url: string;
  type: string;
}
const documents: files[] = [
  {
    filename: "sample.jpg",
    url: "https://images.freeimages.com/images/previews/884/buddha-1310506.jpg",
    type: "Driving License",
  },
  {
    filename: "sample.pdf",
    url: "https://example.com/documents/sample.pdf",
    type: "Aadhaar",
  },
];
const Documents = () => {
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  return (
    <>
      <div className="vms-staff-details-preview__documents">
        <div className="vms-staff-details-preview__documents--common row g-0">
          <div className="col vms-staff-details-preview__documents--common--list">
            {VMS_Form.Staff_Details.Preview.CommonDetails.map(
              (label: LabelNameProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    <TextField
                      className="vms-staff-details-preview__documents--common--textfield"
                      label={label.LabelName}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      disabled
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
          <div className="col-1 vms-staff-details-preview__documents--common--profile">
            <img src={Avatar} alt="" />
          </div>
        </div>
        <div className="vms-staff-details-preview__documents--list">
          <div className="vms-staff-details-preview__documents--list--title">
            Uploaded Files
          </div>
          <div className="vms-staff-details-preview__documents--uploaded-list">
            {documents.map((file, index) => {
              return (
                <div
                  className="vms-staff-details-preview__documents--file"
                  key={index}
                >
                  <div className="vms-staff-details-preview__documents--file--name">
                    <img src={getFileIcon(file.filename)} alt="" />
                    <b>{file.type}</b>
                    <span> {file.filename}</span>
                    <div className="vms-staff-details-preview__documents--file--icons">
                      <img src={Delete} alt="" />
                      <img src={Download} alt="" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <label
          className="vms-staff-details-preview__documents--upload"
          onClick={() => setEditModal(!editModal)}
        >
          <img src={Upload} alt="" /> Upload New File
        </label>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={editModal}
        ariaHideApp={false}
        style={ViewPickedSubjectModalStyles}
        onRequestClose={() => setEditModal(!editModal)}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <UploadDocuments
              operation={Operation.UPDATE}
              step={2}
              setModalFlag={setEditModal}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setEditModal(!editModal)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Documents;

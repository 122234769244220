//Table header for Ledger Summary Page
export const SummaryHeader = [
  "Sl No",
  "Ledger Description",
  "OB",
  "Demand Amount",
  "Concession",
  "Receivable",
  "Received",
  "Balance",
  "Refunds",
  "Balance From Dropouts",
];
//Table header for Payment Voucher Register
export const voucherHeader = [
  "Date",
  "Voucher No",
  "Particulars",
  "Paid to",
  "Bill No",
  "Narration",
  "Amount",
  "Cash / Bank",
  "Cheque No",
];

//Table header for Fee Receipt Book
export const receiptBookHeader = [
  "Sl No",
  "Date",
  "Receipt No.",
  "Adm No",
  "Reg No",
  "Name",
  "Paid Through",
  "Receipt Amt.",
];

//Table header for Monthly wise collection
export const header = ["Sl No", "Month", "Debit", "Credit", "Balance"];

//Table header for Book Reports
export const libHeaderData = [
  "Sl No",
  "Accession No",
  "Title",
  "Issued to",
  "Issued Date",
  "Due Date",
  "Recieved Date",
  "Delayed Days",
  "Transaction Status",
];

//Table header for Accounts balance sheets
export const accHeaderData = [
  "SlNo",
  "AdmNo",
  "RegNo",
  "Name",
  "Mobile",
  "Status",
  "Category",
  "OB",
  "DemandAmount",
  "Concession",
  "Refunds",
  "Receivable",
  "Received",
  "Balance",
];
//Table header for Ledger Wise Student Report
export const ldgrHeaderData = [
  "SlNo",
  "RegNo",
  "AdmNo",
  "Name",
  "Ledger Name",
  "OB",
  "Demand",
  "Concession",
  "Refunds",
  "Receivable",
  "Received",
  "Balance",
];

//Table header for receipt and payment page
export const RandPheaderData = ["Description", "Amount", "Total"];

//Table header for Student Reservation List
export const studentReservationHeaders = [
  "SlNo",
  "Admission No",
  "Name",
  "E-Mail",
  "Mobile",
  "Father Name",
  "Status",
];
//Table header for newAdmitted Student List
export const studentNewAdmHeaders = [
  "SlNo",
  "Admission No",
  "Name",
  "E-Mail",
  "Mobile",
  "Date of Admission",
  "Status",
];

//Table header for Parents List
export const ParentsListHeaders = [
  "SlNo",
  "Name",
  "Parent Type",
  "E-Mail",
  "Mobile",
];
//Table header for Follow Up List
export const FollowUpListHeaders = [
  "SlNo",
  "Name",
  "E-Mail",
  "Mobile",
  "Previous Studied Institution",
  "Last updated Status",
  "Follow Up Date",
  "Next Follow Up Date",
  "Follow Up Count",
];

//Table header for std reservation PDF
export const reservationStudentCol = [
  { title: "Sl", field: "SlNo" },
  { title: "Adm No", field: "AdmNo" },
  { title: "Name", field: "Name" },
  { title: "Email", field: "Email" },
  { title: "Mobile", field: "Mobile" },
  { title: "Father Name", field: "Father" },
  { title: "Status", field: "Status" },
];

//Table header for Ledger Summary PDF
export const SummaryHeaderPdf = [
  { title: "Sl", field: "SlNo" },
  { title: "Ledger Description", field: "LdgrDesc" },
  { title: "OB", field: "OB" },
  { title: "Demand Amount", field: "DemandAmt" },
  { title: "Concession", field: "Concession" },
  { title: "Receivable", field: "Receivable" },
  { title: "Received", field: "Received" },
  { title: "Balance", field: "Balance" },
  { title: "Refunds", field: "Refunds" },
  { title: "Balance From Dropouts", field: "FeeBalance" },
];

//Table Header for Fee Receipt Book
export const FeeReceiptBookPdf = [
  { title: "Sl", field: "SlNo" },
  { title: "Date", field: "Date" },
  { title: "Receipt No.", field: "ReceiptNo" },
  { title: "Admission No.", field: "AdmNo" },
  { title: "Register No.", field: "RegNo" },
  { title: "Name", field: "Name" },
  { title: "Paid Through", field: "PaidThrough" },
  { title: "Receipt Amt.", field: "ReceiptAmt" },
];
//Table Header for Library Media
export const MediaHeader = ["SlNo", "Media Name", "Abbreviation	", "Status"];
export const DeptHeader = ["SlNo", "Department Name"];

export const CategoryHeader = [
  "SlNo",
  "Category Name",
  "Abbreviation	",
  "Status",
];
export const SubjectHeader = [
  "SlNo",
  "Subject Name",
  "Classification No.	",
  "Status",
];
export const PublisherHeader = [
  "SlNo",
  "Publisher Name",
  "Phone No.	",
  "Contact Person",
];
export const LanguageHeader = ["SlNo", "Language", "Short Desc	"];
export const AttributeHeader = ["SlNo", "Attribute"];
export const AuthorHeader = ["SlNo", "Author Name", "Phone", "E-Mail"];
export const ShelfHeader = ["SlNo", "Shelf Description", "Shelf No."];
export const VendorHeader = [
  "SlNo",
  "Vendor Name",
  "Phone No.",
  "PAN No.",
  "GST No.",
];
export const DonarHeader = ["SlNo", "Donor Name", "Contact No.", "Donor Type"];
export const KeyWordHeader = ["SlNo", "KeyWord"];

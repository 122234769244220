import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetLibBookRacksByInstId } from "../../../queries/Library/MasterData/Shelf/list/byInstId";
import {
  GetLibBookRacksByInstIdData,
  GetLibBookRacksByInstIdVars,
} from "../../../Types/Library/MasterData/BookRack/paginationTypes";
import { LIBRARY_ROWS_PER_PAGE } from "../../../utils/constants";
import { Direction, SortBy } from "../../../utils/Enum.types";
export interface shelfType {
  id: number;
  lib_rack_no: string;
  lib_rack_desc: string;
}
const useShelfOptions = (searchShelf: string) => {
  const { InstId } = useParams();

  const { token } = useToken();
  const [shelves, setShelves] = useState<shelfType[]>([]);
  const [GetLibBooks, { data, loading, error }] = useLazyQuery<
    GetLibBookRacksByInstIdData,
    GetLibBookRacksByInstIdVars
  >(GetLibBookRacksByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: LIBRARY_ROWS_PER_PAGE,
      after: null,
      name: searchShelf,
      sortBy: SortBy.LIB_BOOK_RACK_DESC,
      direction: Direction.ASC,
    },
  });

  useEffect(() => {
    if (token) {
      GetLibBooks().then(({ data }) => {
        if (data && data.GetLibBookRacksByInstId) {
          setShelves(
            data.GetLibBookRacksByInstId?.edges?.map(({ node }) => ({
              id: node.id,
              lib_rack_no: node.lib_rack_no,
              lib_rack_desc: node.lib_rack_desc,
            }))
          );
        } else {
          setShelves([]);
        }
      });
    }
  }, [data, loading, InstId, searchShelf, GetLibBooks, token]);
  return {
    shelves,
  };
};

export default useShelfOptions;

import React from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Delete from "../../../../images/Delete.svg";
import Edit from "../../../../images/EditProfile.svg";
import { PageFor } from "../../../../utils/Enum.types";
import useAcctTableJson from "../../json/useAcctTableJson";
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Type = ({ pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();

  // const [desc, setDesc] = useState("");
  // const { token } = useToken();
  // const { InstId } = useParams();
  // const [operation, setOperation] = useState(Operation.CREATE);
  // const [deleteModal, setDeleteModal] = useState(false);
  // const [message, setMessage] = useState<msgType>({
  //   message: "",
  //   flag: false,
  //   operation: Operation.NONE,
  // });

  // const [AddGroup] = useMutation(AddAcctStockItemUom, {
  //   onError: (e) =>
  //     setMessage({
  //       flag: true,
  //       message: e.message,
  //       operation: Operation.NONE,
  //     }),
  // });

  // const [DeleteCategory] = useMutation(DeleteAcctStockItemUomById, {
  //   onError: (e) =>
  //     setMessage({
  //       flag: true,
  //       message: e.message,
  //       operation: Operation.NONE,
  //     }),
  // });
  // const handleClose = () => {
  //   if (message.operation !== Operation.NONE && message.flag) {
  //     setOperation(Operation.CREATE);
  //     setModalFlag(false);
  //     setDesc("");
  //   }
  //   setMessage({
  //     flag: false,
  //     message: "",
  //     operation: Operation.NONE,
  //   });
  // };
  // const handleCreation = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   AddGroup({
  //     variables: {
  //       token,
  //       inst_id: InstId,
  //       input: {
  //         item_uom_desc: removeMoreSpace(desc),
  //         item_uom_in_use: true,
  //       },
  //     },
  //     // refetchQueries: [
  //     //   {
  //     //     query: GetAcctStockItemUoms,
  //     //     variables: {
  //     //       after: null,
  //     //       first: ROWS_PER_PAGE,
  //     //       inst_id: InstId!,
  //     //       orderBy: { direction: Direction.ASC },
  //     //       query_type: "ALL_ITEM_UOMS",
  //     //       token,
  //     //     },
  //     //   },
  //     // ],
  //   }).then(({ data }) => {
  //     if (data) {
  //       setMessage({
  //         flag: true,
  //         message: "Category Created Sucessfully",
  //         operation: Operation.CREATE,
  //       });
  //     }
  //   });
  // };

  // const handleDelete = (id: number) => {
  //   DeleteCategory({
  //     variables: {
  //       token,
  //       id,
  //     },
  //     // refetchQueries: [
  //     //   {
  //     //     query: GetAcctStockItemUoms,
  //     //     variables: {
  //     //       after: null,
  //     //       first: ROWS_PER_PAGE,
  //     //       inst_id: InstId!,
  //     //       orderBy: { direction: Direction.ASC },
  //     //       query_type: "ALL_ITEM_UOMS",
  //     //       token,
  //     //     },
  //     //   },
  //     // ],
  //   }).then(({ data }) => {
  //     if (data) {
  //       setMessage({
  //         flag: true,
  //         message: "Category Deleted Sucessfully",
  //         operation: Operation.DELETE,
  //       });
  //       setDeleteModal(!deleteModal);
  //     }
  //   });
  // };

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>Item Type</Title>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "purchase-entry-masters"
            : "purchase-entry-masters__modal"
        }
      >
        <div className="row g-0 purchase-entry-masters__datablock">
          <div className="col purchase-entry-masters__datablock--frame-left">
            <Title variant="subtitle1">Add New Type</Title>
            <div className="purchase-entry-masters__tableblock">
              <div className="label-grid">
                <Label>Item Type Description</Label>
                <Input required />
              </div>
            </div>
          </div>
          <div className="col purchase-entry-masters__datablock--frame-right">
            <div className="row g-0 purchase-entry-masters__datablock--title">
              <div className="col-3"></div>
              <div className="col">
                <Title variant="subtitle1">List of Types</Title>
              </div>
              <div className="col-3">
                <Input />
              </div>
            </div>
            <div className="purchase-entry-masters__tableblock">
              <TableContainer className="purchase-entry-masters__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.PurchaseEntry.Masters.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index}>{th.labelName}</TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className="purchase-entry-masters__table--slno"
                        id="td-center"
                      >
                        1
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        className="purchase-entry-masters__table--actions"
                        id="td-center"
                      >
                        <img src={Edit} alt="" />
                        <img src={Delete} alt="" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Button mode="save" />
        <Button mode="clear" />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </form>
    </>
  );
};

export default Type;

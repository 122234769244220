import {
  
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";

import { TableHeaderProps } from "../../../../Types/Tables";
import { SalaryProcessTitleProps } from "../../../../Types/Titles";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NOTALLOCATED,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
  MonthName,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import Home from "../../Home/Index";
import SalaryProcess from "./SalaryProcess";
import Modal from "react-modal";
import {
  StudentModalStyles,
  SuccessModalCustomStyles,
} from "../../../../styles/ModalStyles";
import { Label } from "../../../../stories/Label/Label";
import { PrEmpQueryType } from "../../hooks/useEmployee";
import useEmpMasterData from "../../hooks/useEmpMasterData";

import { Keys } from "../../../../utils/Enum.keys";
import { msgType, responseType } from "../../../../utils/Form.types";
import useSalaryMkWorkByMonth from "../../hooks/useSalaryMkWorkByMonth";
import { useMutation } from "@apollo/client";
import {
  AddPrEmpForSalMkMonth,
  DeletePrSalWorkEmpFromMkMonth,
  UpdatePrEmpForSalMkMonth,
} from "../../queries/salary/mutation";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import { GetPayRollSalaryMkWorkByMonthId } from "../../queries/salary/list";
import CloseImg from "../../../../images/Close.svg";
import { GetPayRollEmp } from "../../queries/employee/query";
import { DEPARTMENT, DESIGNATION, HYPHEN } from "../../constants";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useEmployeeAttsByMonth, {
  PayRollEmpEdges,
} from "../../hooks/useEmployeeAttsByMonth";
import { PrEmpAttQueryType } from "../../enums/Enum.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useCheckAllocationType from "../../../Academics/hooks/useCheckAllocationType";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");

interface TableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface Props {
  currentRepo: PayRollEmpEdges[];
  selected: readonly number[];
  handleScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

const SelectEmpForSalary = () => {

  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  const { InstId, monthId } = useParams();
  const [saveModal, setSaveModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [searchNotAddedEmployee, setSearchNotAddedEmployee] = useState("");
  const [searchAddedEmployee, setSearchAddedEmployee] = useState("");
  const [addedEmpListModal, setAddedEmpListModal] = useState(false);
  const [notAddedEmpHasNextPage, setNotAddedEmpHasNextPage] = useState(true);
  const [notAddedEmpEndCursor, setNotAddedEmpEndCursor] = useState("");
  const [addedEmpHasNextPage, setAddedEmpHasNextPage] = useState(true);
  const [addedEmpEndCursor, setAddedEmpEndCursor] = useState("");
  const [addedEmployees, setAddedEmployees] = useState<PayRollEmpEdges[]>([]);
  const [notAddedEmployees, setNotAddedEmployees] = useState<PayRollEmpEdges[]>(
    []
  );
  //states for notAddedEmployees

  //Pagination for Not Added Employees

  // eslint-disable-next-line
  const [notAddedEmpCategory, setNotAddedEmpCategory] =
    useState<responseType | null>(null);
  const [notAddedEmpDepartment, setNotAddedEmpDepartment] =
    useState<responseType | null>(null);
  const [notAddedEmpDesignation, setNotAddedEmpDesignation] =
    useState<responseType | null>(null);
  const [notAddedEmpGrade, setNotAddedEmpGrade] = useState<responseType | null>(
    null
  );
  const [notAddedEmpJobtype, setNotAddedEmpJobType] =
    useState<responseType | null>(null);

  //states for AddedEmployees

  const [addedEmpCategory, setAddedEmpCategory] = useState<responseType | null>(
    null
  );
  const [addedEmpDepartment, setAddedEmpDepartment] =
    useState<responseType | null>(null);
  const [addedEmpDesignation, setAddedEmpDesignation] =
    useState<responseType | null>(null);
  const [addedEmpGrade, setAddedEmpGrade] = useState<responseType | null>(null);
  const [addedEmpJobtype, setAddedEmpJobType] = useState<responseType | null>(
    null
  );

  //employees who are not selected for the salary process
  const [notSelectedEmpForSal, setNotSelectedEmpForSal] = React.useState<
    readonly number[]
  >([]);
  //employees who are removed from exisiting salary process
  const [removeSelectedEmpForSal, setRemoveSelectedEmpForSal] = React.useState<
    readonly number[]
  >([]);
  //employees who are getting selected from notselected employees list
  const [unselectedEmployees, setUnSelectedEmployees] = useState<
    PayRollEmpEdges[]
  >([]);

  //Shiva:Time complexity for Set.has is lesser than that of Array.indexOf and Array.includes
  const notSelectedEmpArrayset = new Set(notSelectedEmpForSal);
  const removeSelectedEmpArrayset = new Set(removeSelectedEmpForSal);

  const { flag } = useCheckAllocationType();
  const { serverDate } = useServerDateandTime();

  const { monthlyData, pr_acd_yr } = useSalaryMkWorkByMonth(monthId!);

  const { sal_wrk_is_emp_added, sal_wrk_for_month } =
    monthlyData.data?.node || {};

  const { empDetails: employeesNotAddedForMonth } = useEmployeeAttsByMonth(
    ROWS_PER_PAGE,
    PrEmpAttQueryType.EMPS_ATT_NOT_ADDED_FOR_SAL_MK_MONTH,
    sal_wrk_for_month!,
    searchNotAddedEmployee,
    pr_acd_yr?.id!
  );
  const employeesNotAddedTotalCount =
    employeesNotAddedForMonth.data?.GetPayRollEmpAttendance.totalCount;
  const { empDetails: employeesAddedForMonth } = useEmployeeAttsByMonth(
    ROWS_PER_PAGE,
    monthlyData.data?.node.sal_wrk_is_emp_added
      ? PrEmpAttQueryType.EMPS_ATT_ADDED_FOR_SAL_MK_MONTH
      : PrEmpAttQueryType.ALL_EMPS_ATT,
    sal_wrk_for_month!,
    searchAddedEmployee,
    pr_acd_yr?.id!
  );

  const handleNotAddedEmployeesScroll = (
    event: React.UIEvent<HTMLDivElement>
  ) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (notAddedEmpHasNextPage && !employeesNotAddedForMonth.loading) {
        employeesNotAddedForMonth.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: notAddedEmpEndCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollEmpAttendance.edges;
            const pageInfo = fetchMoreResult.GetPayRollEmpAttendance.pageInfo;
            setNotAddedEmpEndCursor(pageInfo.endCursor);
            setNotAddedEmpHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollEmpAttendance.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollEmpAttendance: {
                edges: [...notAddedEmployees!, ...newEdges],
                pageInfo,
                totalCount:
                  employeesNotAddedForMonth?.data?.GetPayRollEmpAttendance
                    .totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const handleAddedEmployeesScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (addedEmpHasNextPage && !employeesAddedForMonth.loading) {
        employeesNotAddedForMonth.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: addedEmpEndCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollEmpAttendance.edges;
            const pageInfo = fetchMoreResult.GetPayRollEmpAttendance.pageInfo;
            setAddedEmpEndCursor(pageInfo.endCursor);
            setAddedEmpHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollEmpAttendance.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollEmpAttendance: {
                edges: [...addedEmployees, ...newEdges],
                pageInfo,
                totalCount:
                  employeesAddedForMonth?.data?.GetPayRollEmpAttendance
                    .totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();

  const EnhancedTableHead = (props: TableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          {!(
            addedEmpListModal && monthlyData.data?.node.sal_wrk_is_sal_str_added
          ) && (
            <TableCell
              padding="checkbox"
              className="salary-process__select-emp--table--th-slno"
            >
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}

          {HR_Table.SalaryProcess.SelectEmpForSalary.Table_Headers.filter(
            (th: TableHeaderProps) => {
              if (
                (th.labelName === DEPARTMENT && USE_HR_DEPARTMENT === false) ||
                (th.labelName === DESIGNATION && USE_HR_DESIGNATION === false)
              ) {
                return false;
              } else {
                return true;
              }
            }
          ).map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={th.className}>
                {th.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  const [AddEmp] = useMutation(AddPrEmpForSalMkMonth, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });
  const [UpdateEmp] = useMutation(UpdatePrEmpForSalMkMonth, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });
  const [DeleteEmpAdded] = useMutation(DeletePrSalWorkEmpFromMkMonth, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });

  const {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  } = useEmpMasterData();

  const EmployeesFilter = () => {
    return (
      <>
        {USE_HR_DEPARTMENT ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown!}
              value={
                addedEmpListModal ? addedEmpDepartment : notAddedEmpDepartment
              }
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(
                  option as responseType ,
                  addedEmpListModal ? addedEmpDepartment : notAddedEmpDepartment
                )
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  if (addedEmpListModal) {
                    setAddedEmpDepartment(newValue as responseType);
                  } else {
                    setNotAddedEmpDepartment(newValue as responseType);
                  }
                } else {
                  if (addedEmpListModal) {
                    setAddedEmpDepartment(null);
                  } else {
                    setNotAddedEmpDepartment(null);
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  if (addedEmpListModal) {
                    setAddedEmpDepartment(null);
                  } else {
                    setNotAddedEmpDepartment(null);
                  }
                }
              }}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_HR_DESIGNATION ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={designationDropDown!}
              value={
                addedEmpListModal ? addedEmpDesignation : notAddedEmpDesignation
              }
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(
                  option as responseType,
                  addedEmpListModal
                    ? addedEmpDesignation
                    : notAddedEmpDesignation
                )
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  if (addedEmpListModal) {
                    setAddedEmpDesignation(newValue as responseType);
                  } else {
                    setNotAddedEmpDesignation(newValue as responseType);
                  }
                } else {
                  if (addedEmpListModal) {
                    setAddedEmpDesignation(null);
                  } else {
                    setNotAddedEmpDesignation(null);
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  if (addedEmpListModal) {
                    setAddedEmpDesignation(null);
                  } else {
                    setNotAddedEmpDesignation(null);
                  }
                }
              }}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_HR_JOBTYPE ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={jobTypeDropDown!}
              value={addedEmpListModal ? addedEmpJobtype : notAddedEmpJobtype}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(
                  option as responseType,
                  addedEmpListModal ? addedEmpJobtype : notAddedEmpJobtype
                )
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  if (addedEmpListModal) {
                    setAddedEmpJobType(newValue as responseType);
                  } else {
                    setNotAddedEmpJobType(newValue as responseType);
                  }
                } else {
                  if (addedEmpListModal) {
                    setAddedEmpJobType(null);
                  } else {
                    setNotAddedEmpJobType(null);
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  if (addedEmpListModal) {
                    setAddedEmpJobType(null);
                  } else {
                    setNotAddedEmpJobType(null);
                  }
                }
              }}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="JobType"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_HR_GRADE ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={gradeDropDown!}
              value={addedEmpListModal ? addedEmpGrade : notAddedEmpGrade}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(
                  option as responseType,
                  addedEmpListModal ? addedEmpGrade : notAddedEmpGrade
                )
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  if (addedEmpListModal) {
                    setAddedEmpGrade(newValue as responseType);
                  } else {
                    setNotAddedEmpGrade(newValue as responseType);
                  }
                } else {
                  if (addedEmpListModal) {
                    setAddedEmpGrade(null);
                  } else {
                    setNotAddedEmpGrade(null);
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  if (addedEmpListModal) {
                    setAddedEmpGrade(null);
                  } else {
                    setNotAddedEmpGrade(null);
                  }
                }
              }}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grade"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_HR_CATEGORY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={categoryDropDown!}
              value={addedEmpListModal ? addedEmpCategory : notAddedEmpJobtype}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(
                  option as responseType,
                  addedEmpListModal ? addedEmpCategory : notAddedEmpJobtype
                )
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  if (addedEmpListModal) {
                    setAddedEmpCategory(newValue as responseType);
                  } else {
                    setNotAddedEmpCategory(newValue as responseType);
                  }
                } else {
                  if (addedEmpListModal) {
                    setAddedEmpCategory(null);
                  } else {
                    setNotAddedEmpCategory(null);
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  if (addedEmpListModal) {
                    setAddedEmpCategory(null);
                  } else {
                    setNotAddedEmpCategory(null);
                  }
                }
              }}
              openOnFocus
              freeSolo
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        <div className="col-2 salary-process__select-emp--details--textfield">
          <TextField
            className="salary-process__select-emp--details--date"
            label="Salary Process Month"
            disabled
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={MonthName(
              monthlyData?.data?.node?.sal_wrk_for_month!
            ).toString()}
          />
        </div>
      </>
    );
  };
  const EmployeesTable = ({ currentRepo, selected, handleScroll }: Props) => {
    if (currentRepo)
      return (
        <TableContainer
          className="salary-process__select-emp--table"
          onScroll={handleScroll}
        >
          <Table stickyHeader>
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={currentRepo?.length ?? 0}
            />
            <TableBody>
              {currentRepo.map((data, index) => {
                const isItemSelected = isSelected(data.node.emp_details.id);

                return (
                  <TableRow key={index}>
                    {!(
                      addedEmpListModal &&
                      monthlyData.data?.node.sal_wrk_is_sal_str_added
                    ) && (
                      <TableCell
                        padding="checkbox"
                        id="td-center"
                        className="salary-process__select-emp--table--slno"
                      >
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleClick(event, data.node.emp_details.id)
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell
                      id="td-center"
                      className="salary-process__select-emp--table--slno"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell className="salary-process__select-emp--table--slno">
                      {data.node.emp_details.emp_id}
                    </TableCell>
                    <TableCell className="salary-process__select-emp--table--title">
                      {data.node.emp_details.emp_title}
                    </TableCell>
                    <TableCell>
                      {data.node.emp_details.emp_first_name +
                        " " +
                        data.node.emp_details.emp_middle_name +
                        " " +
                        data.node.emp_details.emp_last_name}
                    </TableCell>
                    <TableCell
                      className="salary-process__select-emp--table--gender"
                      id="td-center"
                    >
                      {data.node.emp_details.emp_sex}
                    </TableCell>
                    <TableCell
                      className="salary-process__select-emp--table--date"
                      id="td-center"
                    >
                      {toStandardDate(data.node.emp_details.emp_doj) ===
                      DEFAULT_TIME
                        ? "-"
                        : toStandardDate(data.node.emp_details.emp_doj)}
                    </TableCell>
                    <TableCell
                      className="salary-process__select-emp--table--date"
                      id="td-center"
                    >
                      {toInputStandardDate(data.node.emp_details.emp_dor) ===
                      DEFAULT_TIME
                        ? "-"
                        : toStandardDate(data.node.emp_details.emp_dor)}
                    </TableCell>
                    {USE_HR_DEPARTMENT ? (
                      <TableCell
                        className="salary-process__select-emp--table--desc"
                        id="td-center"
                      >
                        {data.node.emp_details.pr_dept_details.dept_desc ===
                        NOTALLOCATED
                          ? HYPHEN
                          : data.node.emp_details.pr_dept_details.dept_desc}
                      </TableCell>
                    ) : null}
                    {USE_HR_DESIGNATION ? (
                      <TableCell
                        className="salary-process__select-emp--table--desc"
                        id="td-center"
                      >
                        {data.node.emp_details.pr_designation_details
                          .designation_desc === NOTALLOCATED
                          ? HYPHEN
                          : data.node.emp_details.pr_designation_details
                              .designation_desc}
                      </TableCell>
                    ) : null}
                    <TableCell
                      className="salary-process__select-emp--table--status"
                      id="td-center"
                    >
                      {data.node.emp_details.emp_status}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    else return <b className="nodata">Employee not found</b>;
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (addedEmpListModal) {
      if (event.target.checked) {
        const newSelecteds =
          employeesAddedForMonth.data?.GetPayRollEmpAttendance.edges?.map(
            (n) => n.node.emp_details.id
          )!;
        setRemoveSelectedEmpForSal(newSelecteds);
        return;
      }
      setRemoveSelectedEmpForSal([]);
    } else {
      if (event.target.checked) {
        const newSelecteds =
          employeesNotAddedForMonth.data?.GetPayRollEmpAttendance.edges?.map(
            (n) => n.node.emp_details.id
          )!;
        setNotSelectedEmpForSal(newSelecteds);
        return;
      }
      setNotSelectedEmpForSal([]);
    }
  };

  const isSelected = (name: number) =>
    addedEmpListModal
      ? removeSelectedEmpArrayset.has(name)
      : sal_wrk_is_emp_added
      ? notSelectedEmpArrayset.has(name)
      : !notSelectedEmpArrayset.has(name);

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    const { checked } = event.target as HTMLInputElement;
    const addToArray = () => {
      setUnSelectedEmployees((prev) => [
        ...prev,
        ...employeesNotAddedForMonth.data?.GetPayRollEmpAttendance.edges?.filter(
          ({ node }) => node.emp_details.id === name
        )!,
      ]);
      setNotSelectedEmpForSal((prev) => [...prev, name]);
    };
    const removeFromArray = () => {
      setUnSelectedEmployees(
        unselectedEmployees?.filter(({ node }) => node.emp_details.id !== name)
      );
      setNotSelectedEmpForSal(
        notSelectedEmpForSal.filter((data) => data !== name)
      );
    };
    if (addedEmpListModal) {
      if (checked) {
        setRemoveSelectedEmpForSal((prev) => [...prev, name]);
      } else {
        setRemoveSelectedEmpForSal(
          removeSelectedEmpForSal.filter((data) => data !== name)
        );
      }
    } else {
      if (checked) {
        if (sal_wrk_is_emp_added) {
          addToArray();
        } else {
          removeFromArray();
        }
      } else {
        if (sal_wrk_is_emp_added) {
          removeFromArray();
        } else {
          addToArray();
        }
      }
    }
  };

  const handleSubmit = () => {
    if (sal_wrk_is_emp_added) {
      if (unselectedEmployees.length) {
        UpdateEmp({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            pr_sal_mk_month_id: monthId,
            pr_emp_ids: unselectedEmployees?.map(
              ({ node }) => node.emp_details.id
            ),
            month_start_date: toIsoDate(serverDate),
          },
          refetchQueries: [
            {
              query: GetPayRollSalaryMkWorkByMonthId,
              variables: {
                token,
                inst_id: InstId!,
                id: Number(monthId),
              },
            },
            {
              query: GetPayRollEmp,
              variables: {
                after: null,
                direction: Direction.ASC,
                first: ROWS_PER_PAGE,
                input: {
                  ids: [Number(InstId), Number(monthId)],
                  pr_emp_query_type: PrEmpQueryType.EMPS_ADDED_FOR_SAL_MK_MONTH,
                },
                per_std_subj_allocation: flag,
                name: EMPTY_STRING,
                departmentId: null,
                designationId: null,
                categoryId: null,
                gradeId: null,
                jobTypeId: null,
                sortBy: SortBy.EMP_FIRST_NAME,
                token,
              },
            },
            {
              query: GetPayRollEmp,
              variables: {
                after: null,
                direction: Direction.ASC,
                first: ROWS_PER_PAGE,
                input: {
                  ids: [Number(InstId), Number(monthId)],
                  pr_emp_query_type:
                    PrEmpQueryType.EMPS_NOT_ADDED_FOR_SAL_MK_MONTH,
                },
                per_std_subj_allocation: flag,
                name: EMPTY_STRING,
                departmentId: null,
                designationId: null,
                categoryId: null,
                gradeId: null,
                jobTypeId: null,
                sortBy: SortBy.EMP_FIRST_NAME,
                token,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setUnSelectedEmployees([]);
            setNotSelectedEmpForSal([]);
            setSaveModal(!saveModal);
          }
        });
      } else {
        setSaveModal(!saveModal);
      }
    } else {
      AddEmp({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          pr_sal_mk_month_id: monthId,
          pr_emp_ids: unselectedEmployees?.map(
            ({ node }) => node.emp_details.id
          ),
          month_start_date: toIsoDate(serverDate),
        },
        refetchQueries: [
          {
            query: GetPayRollSalaryMkWorkByMonthId,
            variables: {
              token,
              inst_id: InstId!,
              id: Number(monthId),
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId), Number(monthId)],
                pr_emp_query_type: PrEmpQueryType.EMPS_ADDED_FOR_SAL_MK_MONTH,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              per_std_subj_allocation: flag,
              input: {
                ids: [Number(InstId), Number(monthId)],
                pr_emp_query_type:
                  PrEmpQueryType.EMPS_NOT_ADDED_FOR_SAL_MK_MONTH,
              },
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setUnSelectedEmployees([]);
          setNotSelectedEmpForSal([]);
          setSaveModal(!saveModal);
        }
      });
    }
  };
  const removeAddedEmpFromSalProcess = () => {
    DeleteEmpAdded({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_sal_mk_month_id: monthId,
        pr_emp_ids: removeSelectedEmpForSal,
      },
      refetchQueries: [
        {
          query: GetPayRollEmp,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            per_std_subj_allocation: flag,
            input: {
              ids: [Number(InstId), Number(monthId)],
              pr_emp_query_type: PrEmpQueryType.EMPS_ADDED_FOR_SAL_MK_MONTH,
            },
            name: EMPTY_STRING,
            departmentId: null,
            designationId: null,
            categoryId: null,
            gradeId: null,
            jobTypeId: null,
            sortBy: SortBy.EMP_FIRST_NAME,
            token,
          },
        },
        {
          query: GetPayRollEmp,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            input: {
              ids: [Number(InstId), Number(monthId)],
              pr_emp_query_type: PrEmpQueryType.EMPS_NOT_ADDED_FOR_SAL_MK_MONTH,
            },
            per_std_subj_allocation: flag,
            name: EMPTY_STRING,
            departmentId: null,
            designationId: null,
            categoryId: null,
            gradeId: null,
            jobTypeId: null,
            sortBy: SortBy.EMP_FIRST_NAME,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setRemoveSelectedEmpForSal([]);
        setAddedEmpListModal(!addedEmpListModal);
        setMessage({
          operation: Operation.DELETE,
          message: "",
          flag: true,
        });
      }
    });
  };
  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  useEffect(() => {
    if (employeesAddedForMonth.data && !employeesAddedForMonth.loading) {
      const newData =
        employeesAddedForMonth.data.GetPayRollEmpAttendance.edges.map(
          (edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true, // set default value of isChecked to true
            },
          })
        );

      if (addedEmpEndCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newEmployee) => {
          const filteredEmployee = addedEmployees.find(
            (employee) => employee.node.id === newEmployee.node.id
          );
          if (filteredEmployee) {
            return {
              ...newEmployee,
              node: {
                ...newEmployee.node,
                // isChecked: filteredEmployee.node.isChecked,
              },
            };
          }
          return newEmployee;
        });
        setAddedEmployees(updatedNewData);
      } else {
        setAddedEmployees(newData);
      }
      setAddedEmpEndCursor(
        employeesAddedForMonth.data.GetPayRollEmpAttendance.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [employeesAddedForMonth.data, employeesAddedForMonth.loading]);
  useEffect(() => {
    if (employeesNotAddedForMonth.data && !employeesNotAddedForMonth.loading) {
      const newData =
        employeesNotAddedForMonth.data.GetPayRollEmpAttendance.edges.map(
          (edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true, // set default value of isChecked to true
            },
          })
        );

      if (addedEmpEndCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newEmployee) => {
          const filteredEmployee = notAddedEmployees.find(
            (employee) => employee.node.id === newEmployee.node.id
          );
          if (filteredEmployee) {
            return {
              ...newEmployee,
              node: {
                ...newEmployee.node,
                // isChecked: filteredEmployee.node.isChecked,
              },
            };
          }
          return newEmployee;
        });
        setNotAddedEmployees(updatedNewData);
      } else {
        setNotAddedEmployees(newData);
      }
      setNotAddedEmpEndCursor(
        employeesNotAddedForMonth.data.GetPayRollEmpAttendance.pageInfo
          .endCursor
      );
    } // eslint-disable-next-line
  }, [employeesNotAddedForMonth.data, employeesNotAddedForMonth.loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryProcess.Titles.map(
          (title: SalaryProcessTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.SalaryProcess}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="salary-process">
        <div className="salary-process__data">
          <div className="salary-process__data--left-block">
            <SalaryProcess monthDetails={monthlyData.data?.node!} />
          </div>
          <div className="salary-process__data--right-block">
            <div className="row g-0 salary-process__select-emp--details">
              <div className="col-2">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search Name..."
                  value={searchNotAddedEmployee}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchNotAddedEmployee(e.target.value);
                  }}
                />
              </div>
              <EmployeesFilter />
            </div>
            <div className="salary-process__select-emp--legends">
              <div className="salary-process__select-emp--legends--selected">
                <Checkbox checked={true} />
                <Label variant="LabelBold">
                  Selected Employees will be added for Salary process
                </Label>
              </div>
              <div className="salary-process__select-emp--legends--unselected">
                <Checkbox checked={false} />
                <Label variant="LabelBold">
                  Unselected Employees will be removed from Salary process
                </Label>
              </div>
            </div>
            <div className="salary-process__select-emp--tableblock">
              <EmployeesTable
                currentRepo={
                  employeesAddedForMonth.data?.GetPayRollEmpAttendance.edges!
                }
                selected={notSelectedEmpForSal}
                handleScroll={handleNotAddedEmployeesScroll}
              />
              {/* {unselectedEmployees.length ? (
                <>
                  <b>Selected Employees </b>
                  <EmployeesTable
                    currentRepo={unselectedEmployees!}
                    selected={[]}
                  />
                </>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col">
            {!monthlyData.data?.node.sal_wrk_job_completed && (
              <Button
                mode={
                  monthlyData.data?.node.sal_wrk_is_emp_added
                    ? "update"
                    : "save"
                }
                type="submit"
                onClick={handleSubmit}
                disabled={
                  employeesNotAddedForMonth.data?.GetPayRollEmpAttendance.edges
                    ?.length! === 0
                }
              />
            )}
            {monthlyData.data?.node.sal_wrk_is_emp_added && (
              <Button onClick={() => setAddedEmpListModal(!addedEmpListModal)}>
                Employees Added for Salary Process
              </Button>
            )}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col student-fee-receipt__total">
            <Label> Selected Employees for Salary process:</Label>
            <div className="student-fee-receipt__totalcount">
              {employeesNotAddedTotalCount &&
                employeesNotAddedTotalCount - unselectedEmployees?.length}
            </div>
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={saveModal}
        style={SuccessModalCustomStyles}
        ariaHideApp={false}
      >
        <Title>Salary En-Rolled</Title>
        <div>
          <Label>
            {unselectedEmployees.length > 0
              ? `Selected ${
                  unselectedEmployees.length
                } Employees will be not added
            for salary Process and other ${
              employeesNotAddedTotalCount! - unselectedEmployees.length
            } will added`
              : `${employeesNotAddedTotalCount} Employees are added for salary process `}
          </Label>
        </div>
        <Button
          mode="okay"
          onClick={() => {
            setSaveModal(!saveModal);
            navigate(
              `/${InstId}/hr/payroll/salaryprocess/${monthId}/employeeattendance`
            );
          }}
        />
        <Button mode="cancel" onClick={() => setSaveModal(!saveModal)} />
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        handleClose={handleClose}
        operation={message.operation}
        value={message.message}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addedEmpListModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>List of Employees Added for Salary Process</Title>
            <div className="salary-process__select-emp--added">
              <div className="row g-0 salary-process__select-emp--added--details">
                <div className="col-2">
                  <Input
                    id="search"
                    type="text"
                    placeholder="Search Name..."
                    value={searchAddedEmployee}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchAddedEmployee(e.target.value);
                    }}
                  />
                </div>
                <EmployeesFilter />
              </div>
              <EmployeesTable
                currentRepo={
                  employeesAddedForMonth.data?.GetPayRollEmpAttendance.edges!
                }
                selected={removeSelectedEmpForSal}
                handleScroll={handleAddedEmployeesScroll}
              />
            </div>

            {!(
              addedEmpListModal &&
              monthlyData.data?.node.sal_wrk_is_sal_str_added
            ) && (
              <Button
                mode="remove"
                disabled={removeSelectedEmpForSal.length === 0}
                onClick={removeAddedEmpFromSalProcess}
              />
            )}
            <Button
              mode="cancel"
              onClick={() => setAddedEmpListModal(!addedEmpListModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setAddedEmpListModal(!addedEmpListModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectEmpForSalary;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// this is for production build, NEVER USE THIS
const firebaseConfig = {
  apiKey: "AIzaSyAy7KImc6LQ_tX-kQutm84NviFViH3_mJA",
  authDomain: "myeduate-7da08.firebaseapp.com",
  projectId: "myeduate-7da08",
  storageBucket: "myeduate-7da08.appspot.com",
  messagingSenderId: "224975454414",
  appId: "1:224975454414:web:2ef0ef82c3ae5e3b68d09f",
  measurementId: "G-GSE9VVHBGB",
};

// dev Branch firebase config
// export const firebaseConfig = {
//   apiKey: "AIzaSyCPpOMlza_ZclZAPiMt7hEC1H8_xeU3eRg",
//   authDomain: "myeduate-48fee.firebaseapp.com",
//   projectId: "myeduate-48fee",
//   storageBucket: "myeduate-48fee.appspot.com",
//   messagingSenderId: "512000334631",
//   appId: "1:512000334631:web:7b4b3eb5e6e6467603a702",
//   measurementId: "G-XD4WRX9H8E",
// };
// my personal
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBXPLL49Bih6pK-z5BqBtW_pvAcnh7slpU",
//   authDomain: "eduate2.firebaseapp.com",
//   projectId: "eduate2",
//   storageBucket: "eduate2.appspot.com",
//   messagingSenderId: "735882090232",
//   appId: "1:735882090232:web:01b4872ff4e1757c7e0d0d",
//   measurementId: "G-1FPBB30H9C",
// };
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db };
export { storage as default };

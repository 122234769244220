import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import useInstLabels from "../customhooks/general/useInstLabels";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../styles/DataGridTableStyles";
import useSwConfigData from "../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../utils/Enum.types";

export interface TableColumn {
  headerName: string;
  cellClassName: string;
  field: string;
  headerAlign: string;
  align?: string;
  flex?: number;
  renderCell?: (params: GridRenderCellParams) => React.ReactNode;
  renderHeader?: any;
  hide?: boolean;
}
const useMasterTableJson = () => {
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_STUDENT_DOCUMENT_COLLECTION
  );
  const isDocCollectionEnabled =
    configData &&
    configData.data &&
    configData.data.GetSwConfigVariables.length &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const tableJson = {
    StudentsBulkPic: {
      Table_Headers: [
        {
          field: "std_adm_no",
          headerName: "Adm No.",
          headerAlign: HEADER_TEXT_ALIGN,
          cellClassName: "td-adm-no",
        },
        {
          field: "std_name",
          headerName: "Student Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          field: "dept_desc",
          headerName: departmentLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "branch_desc",
          headerName: branchLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "class_desc",
          headerName: classLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "sem_desc",
          headerName: semesterLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "section_desc",
          headerName: sectionLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
    StudentDocsPreviewTable: {
      Table_Headers: [
        {
          field: "sl_no",
          labelName: "Sl No",
          headerAlign: HEADER_TEXT_ALIGN,
          cellClassName: "sl-no",
        },
        {
          field: "doc_name",
          labelName: "Document Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          field: "doc_preview",
          labelName: "Document Preview",
          cellClassName: "td-status",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },

        ...(isDocCollectionEnabled
          ? [
              {
                field: "is_doc_collected",
                labelName: "Collected Document Status",
                cellClassName: "td-status",
                headerAlign: HEADER_TEXT_ALIGN,
              },
              {
                field: "is_original",
                labelName: "Is Original",
                cellClassName: "td-status",
                headerAlign: HEADER_TEXT_ALIGN,
              },
              {
                field: "date_of_collection",
                labelName: "Date of Collection",
                cellClassName: "td-status",
                headerAlign: HEADER_TEXT_ALIGN,
              },
              {
                field: "std_doc_remarks",
                labelName: "Remarks",
                cellClassName: "td-status",
                headerAlign: HEADER_TEXT_ALIGN,
              },
            ]
          : []),
        {
          field: "actions",
          labelName: "Actions",
          cellClassName: "td-actions",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },

    StudentsDefaultDocs: {
      Table_Headers: [
        {
          field: "sl_no",
          labelName: "Sl No",
          headerAlign: HEADER_TEXT_ALIGN,
          cellClassName: "sl-no",
        },
        {
          field: "doc_name",
          labelName: "Document Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          field: "actions",
          labelName: "Actions",
          cellClassName: "td-actions",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
    Customers: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name td-onClick",
          field: "cust_name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Place",
          cellClassName: "td-place",
          field: "cust_addr",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Contact Person",
          cellClassName: "td-name",
          field: "cust_c_person",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "cust_mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        // {
        //   headerName: "Actions",
        //   cellClassName: "td-actions",
        //   field: "cust_actions",
        //   headerAlign: HEADER_TEXT_ALIGN,
        //   align: "",
        // },
      ],
    },
    Institutions: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-onClick td-name",
          field: "inst_name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Place",
          cellClassName: "td-place",
          field: "inst_addr",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Contact Person",
          cellClassName: "td-name",
          field: "inst_c_person",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "inst_mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        // {
        //   headerName: "Actions",
        //   className: "td-actions",
        //   field: "inst_actions",
        //   headerAlign: HEADER_TEXT_ALIGN,
        //   align: "",
        // },
      ],
      Academics: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "Year",
          cellClassName: "td-year",
          field: "acd_year",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Start Date",
          cellClassName: "td-date",
          field: "acd_s_date",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
          flex: 1,
        },
        {
          headerName: "End Date",
          cellClassName: "td-date",
          field: "acd_e_date",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
          flex: 1,
        },
      ],
      InstDetails: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Description",
          cellClassName: "td-onClick",
          field: "dept_desc",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Actions",
          cellClassName: "td-onClick",
          field: "Action",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
      ],
      Categories: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Description",
          cellClassName: "",
          field: "cat_desc",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
      ],
    },

    Masters_Table: {
      Institutions: {
        Table_Headers: [
          { labelName: "Sl", className: "container__table--th-slno" },
          { labelName: "Name", className: "" },
          { labelName: "Place", className: "container__table--place" },
          {
            labelName: "Contact Person",
            className: "container__table--cperson",
          },
          { labelName: "Mobile", className: "container__table--th-mobile" },
        ],
      },
      CustomerUnderInstitutions: {
        Table_Headers: [
          { labelName: "Sl", className: "container__table--th-slno" },
          { labelName: "Name" },
          { labelName: "Place", className: "container__table--place" },
          {
            labelName: "Contact Person",
            className: "container__table--cperson",
          },
          { labelName: "Mobile", className: "container__table--th-mobile" },
          { labelName: "Action" },
        ],
      },
      InstitutionsUnderCampus: {
        Table_Headers: [
          { labelName: "Sl", className: "container__table--th-slno" },
          { labelName: "Campus Name" },
          { labelName: "Primary Institution" },
          { labelName: "Institutions" },

          { labelName: "Action" },
        ],
      },
      InstitutionDetails: {
        FinancialYear: {
          Table_Headers: [
            { labelName: "Sl", className: "institution__table--th-slno" },
            { labelName: "Year", className: "institution__table--th-year" },
            {
              labelName: "Start Date",
              className: "institution__table--th-date",
            },
            {
              labelName: "End Date",
              className: "institution__table--th-date",
            },
          ],
        },
        AcademicYear: {
          Table_Headers: [
            { labelName: "Sl", className: "institution__table--th-slno" },
            { labelName: "Year", className: "institution__table--th-year" },
            {
              labelName: "Start Date",
              className: "institution__table--th-date",
            },
            {
              labelName: "End Date",
              className: "institution__table--th-date",
            },
            { labelName: "Actions", className: "institution__table--action" },
          ],
        },
        Departments: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "institution__table--th-slno",
            },
            {
              labelName: "Description",
            },
            {
              labelName: "Actions",
              className: "institution__table--action",
            },
          ],
        },
        Branches: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "institution__table--th-slno",
            },
            {
              labelName: "Description",
            },
            {
              labelName: "Actions",
              className: "institution__table--action",
            },
          ],
        },
        Classes: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "institution__table--th-slno",
            },
            {
              labelName: "Description",
            },
            {
              labelName: "Actions",
              className: "institution__table--action",
            },
          ],
        },
        Semesters: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "institution__table--th-slno",
            },
            {
              labelName: "Description",
            },
            {
              labelName: "Actions",
              className: "institution__table--action",
            },
          ],
        },
        Sections: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "institution__table--th-slno",
            },
            {
              labelName: "Description",
            },
            {
              labelName: "Actions",
              className: "institution__table--action",
            },
          ],
        },
        ChannelTable: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "channeldescription__frame--userlist--table--th-slno",
            },
            {
              labelName: "Student Name",
            },
            {
              labelName: `${departmentLabel}`,
              className: "channeldescription__frame--userlist--table--desc",
            },
            {
              labelName: `${branchLabel}`,
              className: "channeldescription__frame--userlist--table--desc",
            },
            {
              labelName: `${classLabel}`,
              className: "channeldescription__frame--userlist--table--desc",
            },
          ],
        },
        Categories: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "institution__table--th-slno",
            },
            {
              labelName: "Description",
            },
            {
              labelName: "Actions",
              className: "institution__table--action",
            },
          ],
        },
      },
      Parents: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            cellClassName: "td-name td-onClick",
            field: "parent_name",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
            flex: 1,
          },
          {
            headerName: "Parent type",
            cellClassName: "td-email",
            field: "parent_type",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
          },

          {
            headerName: "Mobile",
            cellClassName: "parent-mobile",
            field: "parent_mobile",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
          },
          {
            headerName: "Email",
            cellClassName: "td-email",
            field: "parent_email",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
          },
        ],
        Login: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            cellClassName: "td-name td-onClick",
            field: "parent_name",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
            flex: 1,
          },
          {
            headerName: "Parent type",
            cellClassName: "td-email",
            field: "parent_type",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
          },

          {
            headerName: "Mobile",
            cellClassName: "parent-mobile",
            field: "parent_mobile",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
          },
          {
            headerName: "Email",
            cellClassName: "td-email",
            field: "parent_email",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
          },
        ],
      },
      Staffs: {
        Table_Headers: [
          { labelName: "Sl", className: "container__table--th-slno" },
          { labelName: "Name" },
          { labelName: "Staff Type", className: "container__table--place" },

          { labelName: "Place", className: "container__table--place" },
          { labelName: "Mobile", className: "container__table--th-mobile" },
          { labelName: "Email", className: "container__table--email" },
          { labelName: "Action", className: "container__table--action" },
        ],

        Association: {
          Table_Headers: [
            {
              labelName: "Sl",
              className:
                "eduate-user-association__data--tableblock--table--th-slno",
            },
            { labelName: "Name" },
            {
              labelName: "Place",
              className:
                "eduate-user-association__data--tableblock--table--th-place",
            },
            {
              labelName: "Contact Person",
              className:
                "eduate-user-association__data--tableblock--table--th-name",
            },
            {
              labelName: "Mobile",
              className:
                "eduate-user-association__data--tableblock--table--th-mobile",
            },
          ],
        },
      },
      Channels: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "institution__table--th-slno",
          },
          {
            labelName: "Channel Name",
          },
          {
            labelName: "Created By",
          },
          {
            labelName: "Actions",
            className: "institution__table--action",
          },
        ],
      },
      EnableLogin: {
        Table_Headers: [{ LabelName: "", inputName: "login_status" }],
      },
      StudentsBranchChange: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "change-of-dept__view--table--th-slno",
          },
          {
            labelName: "Date",
            className: "change-of-dept__view--table--th-slno",
          },
          {
            labelName: "Adm No.",
            className: "change-of-dept__view--table--th-admno",
          },
          {
            labelName: "Reg No.",
            className: "change-of-dept__view--table--th-regno",
          },
          { labelName: "Name" },
          {
            labelName: `Previous ${branchLabel}`,
            className: "change-of-dept__view--table--desc",
          },
          {
            labelName: `Previous ${classLabel}`,
            className: "change-of-dept__view--table--desc",
          },

          {
            labelName: `Previous ${categoryLabel}`,
            className: "change-of-dept__view--table--desc",
          },
          {
            labelName: `Changed ${branchLabel}`,
            className: "change-of-dept__view--table--desc",
          },
          {
            labelName: `${classLabel}`,
            className: "change-of-dept__view--table--desc",
          },

          {
            labelName: `Changed ${categoryLabel}`,
            className: "change-of-dept__view--table--desc",
          },
        ],
      },
      StudentsEnquiry: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "student-enquiry__frame--table--th-slno",
          },
          { labelName: "Date of Enquiry" },
          { labelName: "Name" },
          {
            labelName: "Previous Studied Institution",
            className: "student-enquiry__frame--table--inst",
          },
          {
            labelName: `Seeking ${branchLabel}`,
            className: "student-enquiry__frame--table--desc",
          },
          {
            labelName: `Seeking ${classLabel}`,
            className: "student-enquiry__frame--table--desc",
          },
          {
            labelName: "Mobile No",
            className: "student-enquiry__frame--table--th-mobile",
          },
          {
            labelName: "Email Id",
            className: "student-enquiry__frame--table--email",
          },
          {
            labelName: "Admitted",
            className: "student-enquiry__frame--table--status",
          },
          {
            labelName: "Actions",
            className: "student-enquiry__frame--table--action",
          },
        ],
        TeacherDashboard: [
          {
            field: "id",
            headerName: "Sl",
            headerAlign: "center",
            align: "center",
            className: "td-sl-no",
          },
          {
            field: "date",
            headerName: "Date of Enquiry",
            headerAlign: "center",
            align: "center",
            className: "td-date",
          },
          {
            field: "name",
            headerName: "Name",
            headerAlign: "center",
            className: "td-name",
            flex: 1,
          },
          {
            field: "previous_studied",
            headerName: "Previous Institution Studied",
            headerAlign: "center",
            className: "td-desc",
          },
          {
            field: "email",
            headerName: "Email ID",
            headerAlign: "center",
            className: "td-email",
          },
          {
            field: "mobile",
            headerName: "Mobile No.",
            headerAlign: "center",
            align: "center",
            className: "td-mobile",
          },
          {
            field: "last_updated",
            headerName: "Last Updated Status",
            headerAlign: "center",
            className: "td-desc",
          },
          {
            field: "follow_up_date",
            headerName: "FollowUp Date",
            headerAlign: "center",
            align: "center",
            className: "td-desc",
          },
          {
            field: "next_follow_up_date",
            headerName: "Next FollowUp Date",
            headerAlign: "center",
            align: "center",
            className: "td-desc",
          },
          {
            field: "follow_up_count",
            headerName: "Follow Up Count",
            headerAlign: "center",
            className: "td-desc",
          },
        ],
      },
      Students: {
        Table_Headers: [
          {
            labelName: "Sl",
            type: "ALLOT_REGISTER_NUMBER",
            value: "",
          },
          {
            labelName: "Adm Number",
            value: "std_adm_no",
            type: "ALLOT_REGISTER_NUMBER",
          },
          {
            labelName: "Name",
            value: "std_name",
            type: "ALLOT_REGISTER_NUMBER",
          },
          {
            labelName: `${departmentLabel}`,
            value: "std_dept",
            type: "ALLOT_REGISTER_NUMBER",
          },
          {
            labelName: `${branchLabel}`,
            value: "std_branch",
            type: "ALLOT_REGISTER_NUMBER",
          },
          {
            labelName: `${classLabel}`,
            value: "std_class",
            type: "ALLOT_REGISTER_NUMBER",
          },
          { labelName: "Father Name", value: "std_father_name", type: "" },
          {
            labelName: "Register Number",
            value: "std_reg_no",
            type: "ALLOT_REGISTER_NUMBER",
          },
          {
            labelName: "Roll Number",
            value: "std_roll_no",
            type: "ALLOT_REGISTER_NUMBER",
          },
          { labelName: `${categoryLabel}`, value: "category_id", type: "" },
          { labelName: `${departmentLabel}`, value: "dept_id", type: "" },
          { labelName: `${branchLabel}`, value: "branch_id", type: "" },
          { labelName: `${classLabel}`, value: "class_id", type: "" },
          { labelName: `${semesterLabel}`, value: "semester_id", type: "" },
          { labelName: `${sectionLabel}`, value: "section_id", type: "" },
          { labelName: "Mobile ", value: "std_mobile", type: "" },
          { labelName: "Email", value: "std_email", type: "" },
          { labelName: "Status ", value: "std_status", type: "" },
          { labelName: "Dmd Open Bal ", value: "std_demand_ob", type: "" },
          { labelName: "Dmd Amt ", value: "std_demand_amt", type: "" },
          {
            labelName: "Dmd Concession ",
            value: "std_demand_concession",
            type: "",
          },
          { labelName: "Dmd Total ", value: "std_demand_receivable", type: "" },
          { labelName: "Dmd Paid ", value: "std_demand_received", type: "" },
          { labelName: "Dmd Refunds ", value: "std_demand_refunds", type: "" },
          { labelName: "Dmd Bal ", value: "std_demand_bal", type: "" },
          { labelName: "Advance Open Amt", value: "std_adv_ob", type: "" },
          { labelName: "Advance  Total", value: "std_adv_total", type: "" },
          {
            labelName: "Advance Adjusted",
            value: "std_adv_adjusted",
            type: "",
          },
          {
            labelName: "Advance Refunded",
            value: "std_adv_refunded",
            type: "",
          },
          { labelName: "Advance Balance", value: "std_adv_bal", type: "" },
        ],

        AluminiStudentList: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "alumini-student-list__table--th-slno",
            },
            { labelName: "Student Name" },
            {
              labelName: "Mobile ",
              className: "alumini-student-list__table--th-mobile",
            },
            {
              labelName: "Email",
              className: "alumini-student-list__table--email",
            },
            {
              labelName: `${branchLabel}`,
              className: "alumini-student-list__table--email",
            },
            {
              labelName: `${classLabel}`,
              className: "alumini-student-list__table--email",
            },
            {
              labelName: "Pass Out Year",
              className: "alumini-student-list__table--th-year",
            },
            {
              labelName: "Actions",
              className: "alumini-student-list__table--th-slno",
            },
          ],
        },

        Academics: {
          Departments: {
            Table_Headers: [
              { labelName: `${departmentLabel}` },
              {
                labelName: "Boys",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Girls",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Total",
                className: "academic-dept-list__table--data--th-total",
              },
              {
                labelName: "Drop Out",
                className: "academic-dept-list__table--data--th-dropout",
              },
            ],
          },
          Branches: {
            Table_Headers: [
              {
                labelName: `${branchLabel}`,
              },
              {
                labelName: "Boys",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Girls",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Total",
                className: "academic-dept-list__table--data--th-total",
              },
              {
                labelName: "Drop Out",
                className: "academic-dept-list__table--data--th-dropout",
              },
            ],
          },
          Classes: {
            Table_Headers: [
              { labelName: `${classLabel}` },
              {
                labelName: "Boys",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Girls",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Total",
                className: "academic-dept-list__table--data--th-total",
              },
              {
                labelName: "Drop Out",
                className: "academic-dept-list__table--data--th-dropout",
              },
            ],
          },
          Semesters: {
            Table_Headers: [
              { labelName: `${semesterLabel} Name` },
              {
                labelName: "Boys",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Girls",
                className: "academic-dept-list__table--data--th-list",
              },
              {
                labelName: "Total",
                className: "academic-dept-list__table--data--th-total",
              },
              {
                labelName: "Drop Out",
                className: "academic-dept-list__table--data--th-dropout",
              },
            ],
          },
          Sections: {
            Table_Headers: [
              { labelName: `${sectionLabel}` },
              { labelName: "Boys" },
              { labelName: "Girls" },
              {
                labelName: "Total",
                className: "academic-dept-list__table--data--th-total",
              },
              {
                labelName: "Drop Out",
                className: "academic-dept-list__table--data--th-dropout",
              },
            ],
          },
          CompleteStudentList: {
            Table_Headers: [
              {
                labelName: "Admission No.",
                className: "academics-all-studentlist__table--th-admno",
              },
              {
                labelName: "Name",
              },
              {
                labelName: "Register No.",
                className: "academics-all-studentlist__table--th-regno",
              },
              {
                labelName: "Joined Year",
                className: "academics-all-studentlist__table--th-date",
              },
              {
                labelName: "Drop Out ",
                className: "academics-all-studentlist__table--th-dropout",
              },
            ],
          },
        },
        DetainedStudentList: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "detained-student-list__view--table--th-slno",
            },
            {
              labelName: "Adm No.",
              className: "detained-student-list__view--table--th-admno",
            },
            {
              labelName: "Name",
              className: "detained-student-list__view--table--name",
            },
            {
              labelName: "Reg No.",
              className: "detained-student-list__view--table--th-regno",
            },
            {
              labelName: "Previous Year",
              className: "detained-student-list__view--table--th-year",
            },
            {
              labelName: "Current Year",
              className: "detained-student-list__view--table--th-regno",
            },
            { labelName: "Reason" },
          ],
        },
        DeleteStudentWhoLeftInstitution: {
          Table_Headers: [
            { labelName: "Sl", className: "delete-student__table--th-slno" },
            {
              labelName: "Adm No.",
              className: "delete-student__table--th-admno",
            },
            {
              labelName: "Register No.",
              className: "delete-student__table--th-admno",
            },
            { labelName: "Name" },

            {
              labelName: "Status",
              className: "delete-student__table--desc ",
            },
            {
              labelName: "O.B",
              className: "delete-student__table--th-amount",
            },
            {
              labelName: "Concession",
              className: "delete-student__table--th-amount",
            },
            {
              labelName: "Demand",
              className: "delete-student__table--th-amount",
            },
            {
              labelName: "Total",
              className: "delete-student__table--th-amount",
            },
            {
              labelName: "Paid",
              className: "delete-student__table--th-amount",
            },
            {
              labelName: "Balance",
              className: "delete-student__table--th-amount",
            },
            {
              labelName: "Refunds",
              className: "delete-student__table--th-amount",
            },
          ],
        },
        ViewMadeElligibleStudents: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "student-status__view--table--th-slno",
            },
            {
              labelName: "Admission No.",
              className: "student-status__view--table--th-admno",
            },
            {
              labelName: "Reg No.",
              className: "student-status__view--table--th-admno",
            },
            { labelName: "Name" },
            {
              labelName: `${departmentLabel}`,
              className: "student-status__view--table--dept",
            },
            {
              labelName: "NE Date",
              className: "student-status__view--table--th-date",
            },
            {
              labelName: "NE Year",
              className: "student-status__view--table--th-year",
            },
            {
              labelName: "NE Remarks",
              className: "student-status__view--table--remarks",
            },
            {
              labelName: "Eligible Date",
              className: "student-status__view--table--th-date",
            },
            {
              labelName: "NE Cancel Remarks",
              className: "student-status__view--table--remarks",
            },
          ],
        },
        ViewMadeNotEligibleStudents: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "student-status__view--table--th-slno",
            },
            {
              labelName: "Admission No.",
              className: "student-status__view--table--th-admno",
            },
            {
              labelName: "Reg No.",
              className: "student-status__view--table--th-admno",
            },
            { labelName: "Name" },
            {
              labelName: `${departmentLabel}`,
              className: "student-status__view--table--dept",
            },
            {
              labelName: `${branchLabel}`,
              className: "student-status__view--table--dept",
            },
            {
              labelName: `${classLabel}`,
              className: "student-status__view--table--dept",
            },
            {
              labelName: "NE Date",
              className: "student-status__view--table--th-date",
            },
            {
              labelName: "NE Reason",
              className: "student-status__view--table--th-year",
            },
            {
              labelName: "NE Remarks",
              className: "student-status__view--table--remarks",
            },
            {
              labelName: "Eligible Date",
              className: "student-status__view--table--th-date",
            },
            {
              labelName: "NE Cancel Remarks",
              className: "student-status__view--table--remarks",
            },
          ],
        },
        Documents: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "student-documents__table--th-slno",
            },
            { labelName: "Document type" },
            {
              labelName: "Preview",
              className: "student-documents__table--preview",
            },
            {
              labelName: "Actions",
              className: "student-documents__table--actions",
            },
          ],
        },
        FeeDemandDisplay: {
          DemandStructure: {
            Table_Headers: [
              {
                labelName: "Sl",
                className:
                  "fee-payable-student__demand-structure--table--th-slno",
              },
              { labelName: "Fee Description" },
              {
                labelName: "Total Amount",
                className:
                  "fee-payable-student__demand-structure--table--th-amt",
              },
            ],
          },
          RaiseDemand: {
            Table_Headers: [
              {
                labelName: "Sl",
                className: "fee-payable-student__details--table--th-slno",
              },
              {
                labelName: "Adm. No.",
                className: "fee-payable-student__details--table--th-amt",
              },
              { labelName: "Student Name" },
              {
                labelName: "Fresher",
                className: "fee-payable-student__details--table--status",
              },
              {
                labelName: "Demand Year",
                className: "fee-payable-student__details--table--th-amt",
              },
              {
                labelName: "Demand Amount",
                className: "fee-payable-student__details--table--th-amt",
              },
              {
                labelName: "Raise Demand",
                className: "fee-payable-student__details--table--th-amt",
              },
            ],
          },
        },
        ChangeOfDepartment: {
          PreviousDemand: [
            {
              labelName: "Sl",
              className: "change-of-dept__tableblock--table--th-slno",
            },
            { labelName: "Fee Description" },
            {
              labelName: "Receviable",
              className: "change-of-dept__tableblock--table--th-amount",
            },
            {
              labelName: "Received",
              className: "change-of-dept__tableblock--table--th-amount",
            },
            {
              labelName: "Balance",
              className: "change-of-dept__tableblock--table--th-amount",
            },
          ],
          NewDemand: [
            { labelName: "Fee Description" },
            {
              labelName: "Payable",
              className: "change-of-dept__tableblock--table--th-amount",
            },
          ],
          Receipts: [
            { labelName: "Voucher No." },
            {
              labelName: "Amount",
              className: "change-of-dept__tableblock--table--th-amount",
            },
          ],
        },
        Dashboard: {
          FeeDetails: [
            { labelName: "Sl" },
            { labelName: "Paid Dates" },
            { labelName: "Receipt No." },
            { labelName: "Transaction Type" },
            { labelName: "Transaction No" },
            { labelName: "Amount Paid" },
          ],
          PendingFee: [
            { labelName: "Sl" },
            { labelName: "Fee Description" },
            { labelName: "Fee Opening Bal." },
            { labelName: "Demand" },
            { labelName: "Concession" },
            { labelName: "Paid" },
            { labelName: "Balance" },
          ],
          BookDetails: [
            { labelName: "Sl" },
            { labelName: "Accession No." },
            { labelName: "Title" },
            { labelName: "Publication" },
            { labelName: "Issue Date" },
            { labelName: "Due Date" },
            { labelName: "No. of Days Delayed" },
          ],
          DepositCollectedDetails: [
            { labelName: "Sl" },
            { labelName: "Receipt No." },
            { labelName: "Date" },
            { labelName: "Amount" },
          ],
          DepositAdjustedDetails: [
            { labelName: "Sl" },
            { labelName: "Particular's" },
            { labelName: "Date" },
            { labelName: "Amount" },
          ],
          TransportDetails: [
            { labelName: "Sl" },
            { labelName: "Receipt No." },
            { labelName: "Remarks" },
            { labelName: "Paid Dates" },
            { labelName: "Amount Paid" },
          ],
          DemandDetails: [
            { labelName: "Sl" },
            { labelName: "Fee Description" },
            { labelName: "Opening Balance" },
            { labelName: "Demand" },
            { labelName: "Concession" },
            { labelName: "Paid" },
            { labelName: "Balance" },
          ],
          ExamDetails: [
            {
              labelName: "Sl",
            },
            { labelName: "Subject Name (Subject Code)" },
          ],
        },

        ClassWiseCount: [
          { labelName: "Sl" },
          { labelName: `${classLabel}` },
          { labelName: "Total Admission Count" },
          { labelName: "Total Reserved Count" },
        ],
        AnonymousStudent: [
          { labelName: "Sl" },
          { labelName: "Name" },
          { labelName: "Ledger" },
          { labelName: "Actions" },
        ],
      },
      Eduate: {
        Association: {
          Table_Headers: [
            {
              labelName: "Sl",
              className:
                "eduate-user-association__data--tableblock--table--th-slno",
            },
            { labelName: "Name" },
            {
              labelName: "Place",
              className:
                "eduate-user-association__data--tableblock--table--th-place",
            },
            {
              labelName: "Contact Person",
              className:
                "eduate-user-association__data--tableblock--table--th-name",
            },
            {
              labelName: "Mobile",
              className:
                "eduate-user-association__data--tableblock--table--th-mobile",
            },
            {
              labelName: "No. of Institutions",
              className:
                "eduate-user-association__data--tableblock--table--th-total",
            },
          ],
        },
        EduateAcctLedgers: {
          Table_Headers: [
            {
              labelName: "Sl",
            },
            { labelName: "Description" },
            {
              labelName: "Group Ledger",
            },
            {
              labelName: "Actions",
            },
          ],
        },
      },
      ProprietorDashboard: {
        Table_Headers: [
          { labelName: "Sl" },
          { labelName: "Institution Name" },
          { labelName: "Boys" },
          { labelName: "girls" },
          { labelName: "No. of Students" },
        ],
      },
      DemoList: {
        Table_Headers: [
          {
            field: "id",
            headerName: "Sl",
            headerAlign: "center",
            align: "center",
            className: "td-sl-no",
          },
          {
            headerName: "Created At",
            field: "created_at",
            headerAlign: "center",
            align: "center",
            className: "td-date",
          },
          {
            headerName: "Name",
            field: "demo_enq_name",
            headerAlign: "center",
            className: "td-name",
          },
          {
            headerName: "Designation",
            field: "demo_enq_designation",
            headerAlign: "center",
            className: "td-name",
          },
          {
            headerName: "Mobile No.",
            field: "demo_enq_mobile_no",
            headerAlign: "center",
            className: "td-mobile",
          },
          {
            headerName: "Email",
            field: "demo_enq_email",
            headerAlign: "center",
            className: "td-mail",
          },
          {
            headerName: "Institution Name",
            field: "demo_enq_inst_name",
            headerAlign: "center",
            className: "td-name",
          },
          {
            headerName: "Comments",
            flex: 1,
            field: "demo_enq_comments",
            headerAlign: "center",
            className: "td-name",
          },
        ],
      },

      GlobalSearch: {
        Table_Headers: [
          {
            labelName: "Sl",
          },
          {
            labelName: "Admission No.",
          },
          {
            labelName: "Reg No.",
          },
          {
            labelName: "Name",
          },
          {
            labelName: "Father Name",
          },
          {
            labelName: "Class",
          },
          {
            labelName: "Mobile No.",
          },
          {
            labelName: "Email Id",
          },
          {
            labelName: "Status",
          },
          {
            labelName: "User Type",
          },
          {
            labelName: "Institution Name",
          },
        ],
      },
    },
  };
  return tableJson;
};

export default useMasterTableJson;

import React, { useContext, useState } from "react";
import DownArrow from "../../../../images/DownArrow.svg";

import {  TextField } from "@mui/material";
import { responseType } from "../../../../utils/Form.types";
import useAgents from "../../../../customhooks/useAgents";
import { Keys } from "../../../../utils/Enum.keys";
import { handleMUISelectEvent } from "../../../../utils/UtilFunctions";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";

interface Props {
  states?: {
    agentName: responseType | null;
    setAgentName: React.Dispatch<React.SetStateAction<responseType | null>>;
  };

  labelRequired?: boolean;
}
const AgentOptionsAutocomplete = ({ states, labelRequired }: Props) => {
  const { agentName, setAgentName } = states ?? {};

  const { dispatch } = useContext(AppContext);
  const [searchAgent, setSearchAgent] = useState("");

  const { agentOptions, handleScroll } = useAgents(searchAgent);
  const Autocomplete = labelRequired ? LabeledAutocomplete : FormAutocomplete;
  return (
    <Autocomplete
      className={labelRequired ? labelClasses.inputRoot : formClasses.inputRoot}
      options={agentOptions}
      openOnFocus
      popupIcon={<img src={DownArrow} alt="/" />}
      forcePopupIcon
      value={agentName}
      onChange={(e, newValue) => {
        if (newValue) {
          setAgentName?.(newValue as responseType);
          dispatch({
            payload: {
              agentId: (newValue as responseType).value,
            },
            type: payloadTypes.SET_AGENT_ID,
          });
        } else {
          setAgentName?.(null);
          dispatch({
            payload: {
              agentId: 0,
            },
            type: payloadTypes.SET_AGENT_ID,
          });
        }
      }}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      onKeyDown={(e) => {
        if (e.key === Keys.BACKSPACE) {
          setAgentName?.(null);
          dispatch({
            payload: {
              agentId: 0,
            },
            type: payloadTypes.SET_AGENT_ID,
          });
        } else if (e.key === Keys.ENTER) {
          handleMUISelectEvent(e);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelRequired ? "Agent" : ""}
          autoFocus
                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          onChange={(e) => setSearchAgent(e.target.value)}
          fullWidth
          className={
            labelRequired
              ? labelClasses.formControlRoot
              : formClasses.formControlRoot
          }
        />
      )}
    />
  );
};

export default AgentOptionsAutocomplete;

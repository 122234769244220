import { gql } from "@apollo/client";

export const UpdateIdCardTemplate = gql`
  mutation UpdateIdCardTemplate(
    $token: String!
    $inst_id: ID!
    $id_card_tpl_id: ID!
    $user_details: SubscribedUser!
    $input: IdCardDetails!
  ) {
    UpdateIdCardTemplate(
      token: $token
      inst_id: $inst_id
      id_card_tpl_id: $id_card_tpl_id
      user_details: $user_details
      input: $input
    )
  }
`;

import React, { useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { BookTransactionTitleProps } from "../../../../Types/Titles";
import Avatar from "../../../../images/Avatar.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  // BookIssueStudentDetailsType,
  responseType,
} from "../../../../utils/Form.types";
import { LibraryLabelNameProps } from "../../../../Types/Labels";
import { GetBookIssueDetailsData } from "../../customHooks/useBookEntryOptions";
import {
  DateRange,
  getDueDate,
  GetNoOfDaysDelayed,
  toInputStandardDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  BooksReportType,
  LibraryConfigKey,
  ReturnType,
} from "../../../../utils/Enum.types";
import { ROWS_PER_PAGE, TODAY_DATE } from "../../../../utils/constants";
import useIssuedBook from "../../customHooks/useIssuedBook";
import useFineSlabOptions from "../../customHooks/useFineSlabOptions";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
const { Library } = require("../../../../json/title.json");
interface Props {
  issued_books?: GetBookIssueDetailsData;
}
const TransactionHistory = ({ issued_books }: Props) => {
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  // eslint-disable-next-line
  const [fromDate, setFormDate] = useState(
    toInputStandardDate(DateRange(TODAY_DATE)?.firstDay!)
  );
  // eslint-disable-next-line
  const [fine, setFine] = useState<responseType>();
  // eslint-disable-next-line
  const [toDate, setToDate] = useState(TODAY_DATE);
  const { Library_Table } = useLibraryTableJson();
  const { LibraryFormLabels } = useLibraryformJson();
  const { configData } = useSwConfigData(
    LibraryConfigKey.LIB_BOOK_NO_OF_BORROWING_DAYS
  );
  const { config_integer_value: DELAY_DAYS_LIMIT } =
    configData.data?.GetSwConfigVariables[0] || {};
  const {
    IssuedBooks: { data: IssuedBooksData },
  } = useIssuedBook(
    0,
    0,
    0,
    0,
    0,
    rowsPerPage,
    BooksReportType.BOOKS_RETURNED,
    fromDate,
    toDate
  );

  const { studentFormData } = useStudentDatabyId();
  const {
    fines: {
      queryData: { data: finesData },
    },
  } = useFineSlabOptions(ReturnType.BOTH);
  const getFine = (noOfDaysDelayed: number) => {
    const fineDetails = finesData?.GetLibFineByInstId?.find(
      ({ id }) => id === fine?.value
    )!;
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab1_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab1_to
    ) {
      return fineDetails.lib_slab1_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab2_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab2_to
    ) {
      return fineDetails.lib_slab2_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab3_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab3_to
    ) {
      return fineDetails.lib_slab3_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab4_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab4_to
    ) {
      return fineDetails.lib_slab4_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab5_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab5_to
    ) {
      return fineDetails.lib_slab5_fine;
    }
    return 0;
  };

  return (
    <>
      <Title>
        {Library.BookTransaction.Titles.map(
          (title: BookTransactionTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.Previous}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="issued-book__history">
        <div className="row g-0 issued-book__history--details">
          <div className="col studentregistration__span-3">
            {LibraryFormLabels?.PreviousTransactionStudentDetails?.map(
              (label: LibraryLabelNameProps, index: React.Key) => {
                return (
                  <TextField
                    className="book-issue__details--textfield"
                    label={label.LabelName}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    value={studentFormData && studentFormData[label.inputName]}
                    disabled
                  />
                );
              }
            )}
          </div>
          <div className="col-1">
            <div className="issued-book__history--image">
              <img src={Avatar} alt="/" />
            </div>
          </div>
        </div>

        <Title variant="subtitle1">
          {Library.BookTransaction.Titles.map(
            (title: BookTransactionTitleProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>
                  {title.IssuedBookHistory}
                </React.Fragment>
              );
            }
          )}
        </Title>
        <div className="issued-book__history--tableblock">
          <TableContainer className="issued-book__history--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Library_Table.BookTransaction.IssuedBooksHistory.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {issued_books &&
                  issued_books.nodes.map((label, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="book-issue__tableblock--books-table--accno">
                          {label.book_access_no}
                        </TableCell>
                        <TableCell>
                          {label.classification.classification_desc}
                        </TableCell>
                        <TableCell className="book-issue__tableblock--books-table--publication">
                          {label.classification.publisher.publisher_name}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--date"
                        >
                          {toStandardDate(label.book_last_issued_date)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Title variant="subtitle1">
          {Library.BookTransaction.Titles.map(
            (title: BookTransactionTitleProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>
                  {title.ReturnedBookHistory}
                </React.Fragment>
              );
            }
          )}
        </Title>

        <div className="issued-book__history--tableblock">
          <TableContainer className="issued-book__history--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Library_Table.BookTransaction.ReturnedBooksHistory.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {IssuedBooksData?.GetLibBookIssued.edges.map(
                  ({ node }, index: number) => {
                    return (
                      <TableRow>
                        <TableCell
                          id="td-center"
                          className="issued-book__history--table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--accno"
                        >
                          {node.book_master.book_access_no}
                        </TableCell>
                        <TableCell>{node.book_master.book_title}</TableCell>
                        <TableCell
                          id="td-center"
                          className="issued-book__history--table--publication"
                        >
                          {
                            node.book_master.classification.publisher
                              .publisher_name
                          }
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="issued-book__history--table--date"
                        >
                          {toStandardDate(node.issue_date)}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="issued-book__history--table--date"
                        >
                          {toStandardDate(node.issue_return_date)}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell
                          id="td-center"
                          className="book-return__tableblock--issued-books-table--date"
                        >
                          {GetNoOfDaysDelayed(
                            getDueDate(
                              node.issue_return_date,
                              DELAY_DAYS_LIMIT!
                            )
                          )}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className={
                            node.issue_return_fine === "0.00"
                              ? "font-green book-return__tableblock--issued-books-table--amount"
                              : "font-red book-return__tableblock--issued-books-table--amount"
                          }
                        >
                          {getFine(
                            GetNoOfDaysDelayed(
                              getDueDate(
                                node.issue_return_date,
                                DELAY_DAYS_LIMIT!
                              )
                            )
                          ) *
                            GetNoOfDaysDelayed(
                              getDueDate(
                                node.issue_return_date,
                                DELAY_DAYS_LIMIT!
                              )
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;

import React, { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import Avatar from "../../../../images/Avatar.svg";
import { TextField } from "@mui/material";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import {
  BankOrCash,
  PageFor,
  StudentAcctReportType,
  StudentListFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import RightArrow from "../../../../images/ArrowRight.svg";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { TableHeaderProps } from "../../../../utils/types";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import {
  formatter,
  getModifiedScrollHeight,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { useLazyQuery } from "@apollo/client";
import { GetStdCompleteFeeSummary } from "../../queries/demands/query";
import {
  GetStdCompleteFeeSummaryData,
  GetStdCompleteFeeSummaryVars,
} from "../../../../Types/Accounting";
import useToken from "../../../../customhooks/useToken";
import { responseType } from "../../../../utils/Form.types";

import DataFetch from "../../../../images/Edit.svg";
import { Keys } from "../../../../utils/Enum.keys";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import StudentList from "../../common/StudentList";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import useInstLabels from "../../../../customhooks/general/useInstLabels";

const AllYearFeeDetails = () => {
  const [studentModal, setStudentModal] = useState(false);
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [imageString, setImageString] = useState("");
  const { Accounts_Table } = useAcctTableJson();

  const [hasNextPage, setHasNextPage] = useState(true);

  const [endCursor, setEndCursor] = useState("");

  const { InstId, studentId } = useParams();

  const { InstDetails } = useInstDetails(1);

  const { token } = useToken();

  const { dispatch, state } = useContext(AppContext);

  const { format } = formatter;

  const [searchAdmNo, setSearchAdmNo] = useState("");

  const { studentFormData, studentData } = useStudentDatabyId();

  const [selectedStudent, setSelectedStduent] = useState<responseType | null>(
    null
  );

  const [students, setStudents] = useState<StudentEdges[]>([]);

  const [studentOptions, setStudentOptions] = useState<responseType[]>([]);

  const {
    AcademicsStudentData: { loading, data: studentsData, fetchMore },
  } = useAcdStudentsForAdmission(
    0,
    0,
    0,
    0,
    0,
    0,
    ROWS_PER_PAGE,
    0,
    searchAdmNo,
    StudentReportType.ACD_STD_GENERAL,
    EMPTY_STRING,
    []
  );
  const [GetCompleteFeeDetails, { data }] = useLazyQuery<
    GetStdCompleteFeeSummaryData,
    GetStdCompleteFeeSummaryVars
  >(GetStdCompleteFeeSummary, {
    variables: {
      inst_id: InstId!,
      student_id: state.studentId,
      token,
    },
  });
  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.FeeSummary.FeeStructure.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const feeStructureColumns: GridColDef[] = [...dynamicHeaders];

  const receiptHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.FeeSummary.Receipts.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const receiptColumns: GridColDef[] = [...receiptHeaders];

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  useEffect(() => {
    if (token && state.studentId) {
      GetCompleteFeeDetails();
    }
  }, [token, state.studentId, InstId, GetCompleteFeeDetails]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.length
              ? prevResult.GetAcdStudents.edges.filter(({ node }) => {
                  return (
                    newEdges.findIndex(
                      ({ node: newNode }) => newNode.id === node.id
                    ) !== -1
                  );
                })
              : [];

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: studentsData
                  ? studentsData.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (studentsData && !loading) {
      const newData = studentsData.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudentOptions(
          updatedNewData.map((std) => ({
            label: `${std.node.first_name} ${std.node.middle_name} ${std.node.last_name} (${std.node.std_adm_no})`,
            value: std.node.id,
          }))
        );
        setStudents(updatedNewData);
      } else {
        setStudentOptions(
          newData.map((std) => ({
            label: `${std.node.first_name} ${std.node.middle_name} ${std.node.last_name} (${std.node.std_adm_no})`,
            value: std.node.id,
          }))
        );
        setStudents(newData);
      }
      setEndCursor(studentsData.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [loading, studentsData]);

  const handleClear = () => {
    dispatch({
      payload: { studentId: 0 },
      type: payloadTypes.SET_STUDENT_ID,
    });
    setSearchAdmNo("");
    setSelectedStduent(null);
  };
  const {categoryLabel}=useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Student All Year Fee Summary</Title>
      <div className="all-yr-fee">
        <div className="all-yr-fee__details row g-0">
          <div className="col">
            <div className="student-autocomplete">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentOptions}
                openOnFocus
                forcePopupIcon
                value={
                  studentOptions.find(
                    ({ value }) => value === state.studentId
                  ) ?? null
                }
                ListboxProps={{
                  onScroll: handleScroll,
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, selectedStudent)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedStduent(newValue as responseType);
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType).value,
                      },
                    });
                  } else {
                    setSelectedStduent(null);
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setSearchAdmNo("");
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSelectedStduent(null);
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setSearchAdmNo("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchAdmNo(e.target.value)
                    }
                    fullWidth
                    className={labelClasses.formControlRoot}
                    label="Admission No."
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
              <img
                src={DataFetch}
                alt=""
                className="data-fetch-icon"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              className="all-yr-fee__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.reg_number}
              label="Reg No."
              disabled
            />
            <TextField
              className="all-yr-fee__details--date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                studentFormData.std_dob
                  ? toStandardDate(studentFormData.std_dob)
                  : EMPTY_STRING
              }
              label="Date of Birth"
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="all-yr-fee__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.std_name}
              label="First Name"
              disabled
            />
            <TextField
              className="all-yr-fee__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.father_name}
              label="Father Name"
              disabled
            />
            <TextField
              className="all-yr-fee__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.branch}
              label="Branch"
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="all-yr-fee__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.class}
              label="Class"
              disabled
            />
            <TextField
              className="all-yr-fee__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.category}
               label={categoryLabel}
              disabled
            />
            <TextField
              className="all-yr-fee__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.acd_yr}
              label="Academic Year"
              disabled
            />
          </div>
          <div className="col-1 h-100">
            <img
              src={imageString ? imageString : Avatar}
              alt=""
              className="all-yr-fee__details--profile"
            />
          </div>
        </div>

        <div className="all-yr-fee__data">
          {state.studentId && data
            ? data.GetStdCompleteFeeSummary.map((res, index) => {
                const isExpanded = expandedIndex === index;

                const ReceiptRows: GridValidRowModel[] = (
                  res.std_yearly_fee_details.std_demand_receipts || []
                ).map((item, index) => ({
                  id: index + 1,
                  receipt_no: item.v_no,
                  date: toStandardDate(item.v_date),
                  v_type: item.v_transcation_type,
                  fine: item.v_std_amt_fine,
                  deposits:
                    !item.v_std_deposit_adjusted && item.v_std_amt_deposit
                      ? format(item.v_std_amt_deposit)
                      : 0,
                  demand: item.v_std_demand_receipt
                    ? format(item.v_std_amt_receipt)
                    : 0,
                  nondemand:
                    !item.v_std_demand_receipt && !item.v_std_deposit
                      ? format(item.v_std_amt_receipt)
                      : 0,
                  payment:
                    item.v_transcation_cash_or_bank === BankOrCash.CASH
                      ? "Cash"
                      : "Bank",
                  amount: item.v_std_amt_receipt,
                  remarks: item.update_remarks,
                }));

                const feeStructureRows: GridValidRowModel[] =
                  res.std_yearly_fee_details.std_demand_details.map(
                    (res, index) => ({
                      ...res,
                      id: index + 1,
                      particulars: res.acct_ldgr_details.ldgr_desc,
                      ob: res.fee_ob,
                      receivable: res.fee_receivable,
                      demand: res.fee_demand,
                      received: res.fee_received,
                      balance: res.fee_bal,
                    })
                  ) || [];

                const getFeeStructureRow = (): Record<
                  string,
                  string | number
                >[] => {
                  const row: Record<string, string | number> = {
                    id: "",
                    particulars: "Total",
                    demand:
                      res.std_yearly_fee_details.std_demand_by_fin_yr
                        .std_demand_amt,
                    ob: res.std_yearly_fee_details.std_demand_by_fin_yr
                      .std_demand_ob,
                    receivable:
                      res.std_yearly_fee_details.std_demand_by_fin_yr
                        .std_demand_received,
                    received:
                      res.std_yearly_fee_details.std_demand_by_fin_yr
                        .std_demand_received,
                    balance:
                      res.std_yearly_fee_details.std_demand_by_fin_yr
                        .std_demand_bal,
                  };

                  return [row];
                };

                const pinnedRows = {
                  bottom: getFeeStructureRow(),
                };

                const receiptAccumulator =
                  res.std_yearly_fee_details.std_demand_receipts.reduce(
                    (acc, receipt) => {
                      acc.receiptTotalFine += receipt.v_std_amt_fine;
                      if (receipt.v_std_demand_receipt) {
                        acc.receiptTotalDemandFee += receipt.v_std_amt_receipt;
                      }
                      if (!receipt.v_std_demand_receipt) {
                        acc.receiptTotalNonDemandFee +=
                          receipt.v_std_amt_receipt;
                      }
                      if (receipt.v_std_deposit) {
                        acc.receiptTotalDeposit += receipt.v_std_amt_deposit;
                      }
                      return acc;
                    },
                    {
                      receiptTotalFine: 0,
                      receiptTotalDemandFee: 0,
                      receiptTotalNonDemandFee: 0,
                      receiptTotalDeposit: 0,
                    }
                  );
                const getFeeStructureClassName = (params: GridCellParams) => {
                  const pinnedRowIds = pinnedRows.bottom
                    ? pinnedRows.bottom.map((row) => row.id)
                    : [];

                  const isPinnedRow = pinnedRowIds.includes(params.row.id);

                  if (isPinnedRow) {
                    switch (params.field) {
                      case "particulars":
                        return "total";
                      case "ob":
                        return "totalcount";
                      case "demand":
                        return "taxableamount";
                      case "receivable":
                        return "totalcount";
                      case "received":
                        return "totalcount";
                      case "balance":
                        return "balance-count";
                      default:
                        return "";
                    }
                  }

                  return "";
                };

                const getReceiptRow = (): Record<string, string | number>[] => {
                  const row: Record<string, string | number> = {
                    id: "",
                    receipt_no: "Total",
                    date: "",
                    v_type: "",
                    fine: receiptAccumulator.receiptTotalFine,
                    deposits: receiptAccumulator.receiptTotalDeposit,
                    demand: receiptAccumulator.receiptTotalDemandFee,
                    nondemand: receiptAccumulator.receiptTotalNonDemandFee,
                    payment: "",
                    amount:
                      res.std_yearly_fee_details.std_demand_by_fin_yr
                        .std_demand_received,
                    remarks: "",
                  };

                  return [row];
                };

                const pinnedReceiptRows = {
                  bottom: getReceiptRow(),
                };

                const getReceiptClassName = (params: GridCellParams) => {
                  const pinnedRowIds = pinnedReceiptRows.bottom
                    ? pinnedRows.bottom.map((row) => row.id)
                    : [];

                  const isPinnedRow = pinnedRowIds.includes(params.row.id);

                  if (isPinnedRow) {
                    switch (params.field) {
                      case "receipt_no":
                        return "total";
                      case "fine":
                        return "totalcount";
                      case "deposits":
                        return "totalcount";
                      case "demand":
                        return "totalcount";
                      case "nondemand":
                        return "totalcount";
                      case "amount":
                        return "totalcount";
                      default:
                        return "";
                    }
                  }

                  return "";
                };

                return (
                  <React.Fragment key={index}>
                    <div className="all-yr-fee__data--block">
                      <div
                        className="all-yr-fee__data--header "
                        onClick={() => handleToggle(index)}
                      >
                        <b className="all-yr-fee__data--header--b">
                          {res.fin_yr}
                        </b>
                        <div className="all-yr-fee__data--header--flex">
                          <div className="all-yr-fee__data--header--grids">
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Total Demand
                              </span>
                              <b className="all-yr-fee__data--header--amount font-blue">
                                {res.std_yearly_fee_details.std_demand_by_fin_yr
                                  ? res.std_yearly_fee_details
                                      .std_demand_by_fin_yr.std_demand_amt
                                  : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Total Received
                              </span>
                              <b className="all-yr-fee__data--header--amount font-green">
                                {res.std_yearly_fee_details.std_demand_by_fin_yr
                                  ? res.std_yearly_fee_details
                                      .std_demand_by_fin_yr.std_demand_received
                                  : 0}
                              </b>
                            </div>

                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Total Balance
                              </span>
                              <b className="all-yr-fee__data--header--amount font-red">
                                {res.std_yearly_fee_details.std_demand_by_fin_yr
                                  ? res.std_yearly_fee_details
                                      .std_demand_by_fin_yr.std_demand_bal
                                  : 0}
                              </b>
                            </div>

                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Total Receipts
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {
                                  res.std_yearly_fee_details.std_demand_receipts
                                    .length
                                }
                              </b>
                            </div>
                          </div>
                          <div className="all-yr-fee__data--header--image">
                            {isExpanded ? (
                              <img src={DownArrow} alt="" />
                            ) : (
                              <img src={RightArrow} alt="" />
                            )}
                          </div>
                        </div>
                      </div>

                      {isExpanded ? (
                        <div className="all-yr-fee__data--content">
                          <div className="all-yr-fee__data--content--block">
                            <b>Fee Structure</b>
                            <div
                              className={` all-yr-fee__data--content--tableblock`}
                            >
                              <StyledDatagrid
                                columns={feeStructureColumns}
                                rows={feeStructureRows}
                                disableRowSelectionOnClick
                                disableChildrenSorting
                                rowHeight={TABLE_ROW_HEIGHT}
                                pinnedRows={pinnedRows}
                                getCellClassName={getFeeStructureClassName}
                                hideFooter
 
                              />
                            </div>
                          </div>
                          <div className="all-yr-fee__data--content--block">
                            <b>Receipts</b>
                            <div
                              className={` all-yr-fee__data--content--tableblock`}
                            >
                              <StyledDatagrid
                                columns={receiptColumns}
                                rows={ReceiptRows}
                                disableRowSelectionOnClick
                                disableChildrenSorting
                                rowHeight={TABLE_ROW_HEIGHT}
                                pinnedRows={pinnedReceiptRows}
                                getCellClassName={getReceiptClassName}
                                hideFooter
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                );
              })
            : null}
        </div>
        <div className="row g-0">
          <div className="col">
            {" "}
            <Button mode="clear" onClick={handleClear} />
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col flex-end">
            <div className="student-total-count">
              Total :
              <b>
                {state.studentId && data
                  ? data.GetStdCompleteFeeSummary.length
                  : 0}
              </b>
            </div>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.GENERAL}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AllYearFeeDetails;

import React, { useEffect, useState } from "react";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import useAcademicTableJson from "../../json/useAcademicTable";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import List from "./List";
import Input from "../../../../stories/Input/Input";
import {  TextField } from "@mui/material";

import { Button } from "../../../../stories/Button/Button";
import useEmployee, {
  empQueryTypes,
  PayRollEmpEdges,
} from "../../../HR/hooks/useEmployee";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import { AccountentType } from "../../../Accounts/common/Enum.types";
import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import useEmpMasterData from "../../../HR/hooks/useEmpMasterData";
import { responseType } from "../../../../utils/Form.types";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const AllTeachers = () => {
  const navigate = useNavigate();

  const { TeachersAllotedList } = useAcademicTableJson();
  const [searchData, setSearchData] = useState("");
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { designationDropDown } = useEmpMasterData();
  const [pr_designation, setpr_designation] = useState<responseType | null>(
    null
  );
  const dynamicHeaders: TableHeaderProps[] =
    TeachersAllotedList.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];
  const {
    empDetails: { data, loading: EmpLoading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    pr_designation && pr_designation.value
      ? empQueryTypes.EMP_BY_DESIGINATION_ID
      : empQueryTypes.EMP_BY_TYPE,
    searchData,
    0,
    0,
    pr_designation ? pr_designation.value : 0,
    0,
    0,
    0,
    pr_designation && pr_designation.value ? [] : [AccountentType.TEACHER]
  );

  useEffect(() => {
    if (data && !EmpLoading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
      } else {
        setEmployees(newData);
      }
      setHasNextPage(data.GetPayRollEmp.pageInfo.hasNextPage);
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, EmpLoading]);
  useEffect(() => {
    if (data && !EmpLoading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (employee) => employee.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            emp_id: node.emp_id,
            name:
              node.emp_first_name +
              " " +
              node.emp_middle_name +
              " " +
              node.emp_last_name,
            designation: node.pr_designation_details.designation_desc,
            assoc_class: 2,
            total_forms: 2,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            emp_id: node.emp_id,
            name:
              node.emp_first_name +
              " " +
              node.emp_middle_name +
              " " +
              node.emp_last_name,
            designation: node.pr_designation_details.designation_desc,
            assoc_class: 2,
            total_forms: 2,
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, EmpLoading]);
  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !EmpLoading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetPayRollEmp.edges;
              const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetPayRollEmp: {
                  edges: [...employees, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Teacher's Report</Title>
      <div className="alloted-teacher-report">
        <div className="alloted-teacher-report__left">
          <List />
        </div>
        <div className="alloted-teacher-report__right">
          <div className="alloted-teacher-report__right--select row g-0">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchData(e.target.value)
                }
                value={searchData}
              />
            </div>
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={designationDropDown}
                value={pr_designation}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, pr_designation)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setpr_designation(newValue as responseType);
                  } else setpr_designation(null);
                }}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                    label="Designation"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
            </div>
          </div>
          <div
            className={`alloted-teacher-report__right--tableblock `}
          >
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              hideFooter
 
            />
          </div>

          <div className="row g-0">
            <div className="col">
              {/* <Button mode="export" /> */}
              <Button mode="back" onClick={() => navigate(-1)} />
            </div>
            <div className="col flex-end">
              <div className="student-total-count">
                Total Teachers -
                <b>{data ? data.GetPayRollEmp.totalCount : 0}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTeachers;

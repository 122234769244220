import { gql } from "@apollo/client";

export const getPredefinedDataByType = gql`
  query GetPredefinedDataByType(
    $Lname: String
    $first: Int
    $type: String!
    $after: Cursor
    $token: String!
  ) {
    GetPredefinedDataByType(
      token: $token
      first: $first
      after: $after
      type: $type
      where: { or: [{ value1ContainsFold: $Lname }] }
    ) {
      edges {
        node {
          id
          type
          index
          value1
          value2
          value3
          value4
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
      }
    }
  }
`;

export const getPredefinedDataByTypes = gql`
  query GetPredefinedDataTypes($token: String!) {
    GetPredefinedDataTypes(token: $token)
  }
`;

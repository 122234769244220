import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../../stories/Button/Button";

import { Title } from "../../../../../stories/Title/Title";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import { TableHeaderProps } from "../../../../../Types/Tables";
import { BankBookTitleProps } from "../../../../../Types/Titles";
import {
  BankOrCash,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  PageFor,
} from "../../../../../utils/Enum.types";
import Home from "../../../Home/Index";
import Modal from "react-modal";
import Close from "../../../../../images/Close.svg";
import { Cell } from "exceljs";
import Eduate from "../../../../../images/Eduate_Logo_image.png";
import ExcelJS from "exceljs";

import {
  formatter,
  getHeaderRowStyle,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import ReceiptModal from "../../AccountLedgerBook/Index";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctPaymentsReport,
  GetAcctReceiptsReport,
} from "../../../queries/FeeLedgers/query/Byid";
import useToken from "../../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import LoadingModal from "../../../../../pages/LoadingModal";
import { acct_group_ldgr_details } from "../../../hooks/useAcctLedgerData";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  A5_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  A_CELL,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  D_CELL,
  E5_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  E_CELL,
  F4_CELL,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  GROUP_LDGR_FONT,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  RP_TABLE_HEADER,
  TABLE_CSS,
  TABLE_HEADER_CSS,
  TABLE_HEADER_CSS2,
  TABLE_HEADER_CSS4,
} from "../../../../Library/Constants";
import { RandPheaderData } from "../../../common/HeaderConsts";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import useAcctTableJson from "../../../json/useAcctTableJson";
const { AccountsTitles } = require("../../../json/title.json");

interface gr_ldgr_summary {
  id: number;
  gr_rp_ob: number;
  gr_rp_cr: number;
  gr_rp_db: number;
  gr_rp_cb: number;
  gr_rp_rcpt_show: number;
  gr_rp_pymt_show: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}

interface acct_ldgr_rp_summary {
  ldgr_rp_ob: number;
  ldgr_rp_cr: number;
  ldgr_rp_db: number;
  ldgr_rp_cb: number;
  ldgr_rp_rcpt_show: number;
  ldgr_rp_pymt_show: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
  acct_ldgr_details: acct_ldgr_details;
}
export interface acct_ldgr_details {
  ldgr_desc: string;
  id: number;
}

interface GetAcctReceiptsAndPaymentsReportDetails {
  group_ldgr_rp_summary: gr_ldgr_summary[];
  acct_ldgr_rp_summary: acct_ldgr_rp_summary[];
}
interface GetAcctReceiptsAndPaymentsReportData {
  GetAcctReceiptsReport: GetAcctReceiptsAndPaymentsReportDetails;
  GetAcctPaymentsReport: GetAcctReceiptsAndPaymentsReportDetails;
}
export interface GetAcctReceiptsAndPaymentsReportVars {
  token: string;

  inst_id: string;
  fin_yr_id: number;
}
interface Props {
  pageType: PageFor;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageType, setModalFlag }: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const [receiptModal, setReceiptModal] = useState(false);
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const { InstDetails } = useInstDetails(1);

  const [GetReceipts, { data, loading, error }] = useLazyQuery<
    GetAcctReceiptsAndPaymentsReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctReceiptsReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });

  const [
    GetPayments,
    { data: Payments, loading: PaymentsLoading, error: PaymentsError },
  ] = useLazyQuery<
    GetAcctReceiptsAndPaymentsReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctPaymentsReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  let rcptGrandTotal = 0;
  let paymentGrandTotal = 0;
  // eslint-disable-next-line
  data?.GetAcctReceiptsReport.group_ldgr_rp_summary.map((f) => {
    rcptGrandTotal += f.gr_rp_rcpt_show;
  });
  // eslint-disable-next-line
  Payments?.GetAcctPaymentsReport.group_ldgr_rp_summary.map((f) => {
    paymentGrandTotal += f.gr_rp_pymt_show;
  });

  const groupedData =
    Payments?.GetAcctPaymentsReport.group_ldgr_rp_summary?.map((edge) => {
      const accountLedgers =
        Payments.GetAcctPaymentsReport.acct_ldgr_rp_summary?.filter(
          (f) =>
            f.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
        );
      return {
        groupLedgerDescription: edge.acct_group_ldgr_details.gr_ldgr_desc,
        accountLedgers: accountLedgers?.map((ldgr) => ({
          accountLedgerDescription: ldgr.acct_ldgr_details.ldgr_desc,
          ledgerRpPymtShow: ldgr.ldgr_rp_pymt_show,
        })),
        groupRpPymtShow: edge.gr_rp_pymt_show,
      };
    });

  const groupedReceiptData =
    data?.GetAcctReceiptsReport?.group_ldgr_rp_summary?.map((edge) => {
      const accLdgr = data?.GetAcctReceiptsReport?.acct_ldgr_rp_summary?.filter(
        (data) =>
          data.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
      );
      return {
        groupLdgr: edge.acct_group_ldgr_details.gr_ldgr_desc,
        accLedgers: accLdgr?.map((ldgr) => ({
          accDesc: ldgr.acct_ldgr_details.ldgr_desc,
          amount: ldgr.ldgr_rp_rcpt_show,
        })),
        groupRpPymtShow: edge.gr_rp_rcpt_show,
      };
    });
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.RECEIPT_PAYMENT);
    const dataRows = groupedReceiptData
      ? groupedReceiptData.map((group) => ({
          group: group.groupLdgr,
          list: group.accLedgers.map((ledger) => ({
            desc: ledger.accDesc,
            amount: format(ledger.amount),
            groupRpPymtShow: format(group.groupRpPymtShow),
          })),
        }))
      : [];

    const dataRows2 = groupedData
      ? groupedData.map((group) => ({
          group: group.groupLedgerDescription,
          list: group.accountLedgers.map((ledger) => ({
            desc: ledger.accountLedgerDescription,
            amount: format(ledger.ledgerRpPymtShow),
            groupRpPymtShow: format(group.groupRpPymtShow),
          })),
        }))
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 28;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 20;
    worksheet.getRow(5).height = 23;
    worksheet.getColumn(1).width = 35;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 26;
    worksheet.getColumn(4).width = 8;
    worksheet.getColumn(5).width = 35;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "M1:M1");
          worksheet.mergeCells(
            1,
            1,
            1,
            RandPheaderData.length + RandPheaderData.length + 1
          );
          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;

          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:G2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:G3");

          const mergedHeader: Cell = worksheet.getCell(C4_CELL);
          mergedHeader.value = ExcelPageHeader.RECEIPT_PAYMENT;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("C4:E4");
          const mergedDate: Cell = worksheet.getCell(F4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("F4:G4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:B4");

          worksheet.mergeCells("A5:C5");
          const mergedCell2: Cell = worksheet.getCell(A5_CELL);
          mergedCell2.value = ExcelPageHeader.RECIEPT_ACCOUNT;
          mergedCell2.fill = TABLE_HEADER_CSS2;
          mergedCell2.font = RP_TABLE_HEADER;
          mergedCell2.alignment = {
            horizontal: ExcelAlignment.CENTER,
            vertical: ExcelAlignment.MIDDLE,
          };
          worksheet.mergeCells("E5:G5");
          const mergedCell3: Cell = worksheet.getCell(E5_CELL);
          mergedCell3.value = ExcelPageHeader.PAYMENT_ACCOUNT;
          mergedCell3.fill = TABLE_HEADER_CSS2;
          mergedCell3.font = RP_TABLE_HEADER;
          mergedCell3.alignment = {
            horizontal: ExcelAlignment.CENTER,
            vertical: ExcelAlignment.MIDDLE,
          };
          let Char = FIRST_INDEX;

          for (let i = 0; i < RandPheaderData.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 6);
            rowData.value = RandPheaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }
          let Char2 = D_CELL;

          for (let i = 0; i < RandPheaderData.length; i++) {
            Char2 = String.fromCharCode(Char2.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char2 + 6);
            rowData.value = RandPheaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }
          let DataStart = A_CELL;
          let DataNmbr = 7;
          // eslint-disable-next-line
          let Increment = 0;
          // eslint-disable-next-line
          let num = 0;

          dataRows!.forEach((rowData, index) => {
            let cell4 = worksheet.getCell(DataStart + DataNmbr);
            cell4.value = rowData.group;
            worksheet.mergeCells(`A${DataNmbr}:C${DataNmbr}`);
            cell4.font = GROUP_LDGR_FONT;
            cell4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cell4.fill = TABLE_CSS;

            rowData.list.forEach((listItem, i) => {
              let cellA = worksheet.getCell(`A${DataNmbr + i + 1}`);
              let cellB = worksheet.getCell(`B${DataNmbr + i + 1}`);
              let styleCellC = worksheet.getCell(`C${DataNmbr + i + 1}`);
              let cellC = worksheet.getCell(
                `C${DataNmbr + rowData.list.length}`
              );
              cellA.value = listItem.desc;
              cellB.value = listItem.amount;
              cellC.value = listItem.groupRpPymtShow;
              cellA.fill = TABLE_HEADER_CSS4;
              cellB.fill = TABLE_HEADER_CSS4;
              styleCellC.fill = TABLE_HEADER_CSS4;
              cellB.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellA.alignment = {
                horizontal: ExcelAlignment.CENTER,
              };
              cellB.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellC.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellC.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            DataNmbr += rowData.list ? rowData.list.length + 1 : 1;
            Increment += rowData.list ? rowData.list.length + 1 : 1;
            num = 7 + rowData.list.length + index + 1;
          });
          let DataStartNmbr = E_CELL;
          let Dnumber = 7;
          // eslint-disable-next-line
          let Increment2 = 0;
          // eslint-disable-next-line
          let num2 = 0;
          dataRows2.forEach((row, index) => {
            let cellE4 = worksheet.getCell(DataStartNmbr + Dnumber);
            cellE4.value = row.group;
            worksheet.mergeCells(`E${Dnumber}:G${Dnumber}`);
            cellE4.font = GROUP_LDGR_FONT;
            cellE4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cellE4.fill = TABLE_CSS;

            row.list.forEach((listItem, i) => {
              let cellE = worksheet.getCell(`E${Dnumber + i + 1}`);
              let cellF = worksheet.getCell(`F${Dnumber + i + 1}`);
              let styleCellG = worksheet.getCell(`G${Dnumber + i + 1}`);
              let cellG = worksheet.getCell(
                `G${Dnumber + i + row.list.length}`
              );
              cellE.value = listItem.desc;
              cellF.value = listItem.amount;
              cellG.value = listItem.groupRpPymtShow;
              cellE.fill = TABLE_HEADER_CSS4;
              cellF.fill = TABLE_HEADER_CSS4;
              styleCellG.fill = TABLE_HEADER_CSS4;
              cellF.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellE.alignment = {
                horizontal: ExcelAlignment.CENTER,
              };
              cellF.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            Dnumber += row.list ? row.list.length + 1 : 1;
            Increment2 += row.list ? row.list.length + 1 : 1;
            num2 = 7 + row.list.length + index + 1;
          });
          worksheet.addRow([]);
          const footerRow = worksheet.addRow([]);
          footerRow.getCell(2).value = ExcelFooterHeader.GRAND_TOTAL;
          footerRow.getCell(2).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(2).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          footerRow.getCell(6).value = ExcelFooterHeader.GRAND_TOTAL;
          footerRow.getCell(6).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(6).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };

          footerRow.getCell(3).value = format(rcptGrandTotal);
          footerRow.getCell(3).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          footerRow.getCell(7).value = format(paymentGrandTotal);
          footerRow.getCell(7).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          worksheet.addConditionalFormatting({
            ref: `C${footerRow.number}`,
            rules: FOOTER_CSS,
          });
          worksheet.addConditionalFormatting({
            ref: `G${footerRow.number}`,
            rules: FOOTER_CSS,
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.RP_ACCOUNT);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
  };
  useEffect(() => {
    if (state.ActiveFinYr) {
      GetPayments();
      GetReceipts();
    }
  }, [GetPayments, state.ActiveFinYr, GetReceipts]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <Title>
        {AccountsTitles.BankBook.ReceiptAndPaymentAccount.Titles.map(
          (title: BankBookTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.ReceiptAndPaymentAccount}
              </React.Fragment>
            );
          }
        )}
      </Title>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "receipt-payment-account"
            : "receipt-payment-account__modal"
        }
      >
        {pageType === PageFor.MODAL && (
          <div className="row g-0">
            <div className="col"></div>
            <div className="col-2 receipt-payment-account__modal--year">
              <TextField
                label="Financial Year"
                disabled
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="receipt-payment-account__modal--textfield"
              />
            </div>
          </div>
        )}
        <div
          className={
            pageType === PageFor.GENERAL
              ? "row g-0 receipt-payment-account__tableblock"
              : "row g-0 receipt-payment-account__modal--tableblock"
          }
        >
          <div className="col h100">
            {error ? (
              <b className="nodata">{error.message}</b>
            ) : (
              <TableContainer className="receipt-payment-account__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="receipt-payment-account__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Receipt</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="receipt-payment-account__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {data?.GetAcctReceiptsReport?.group_ldgr_rp_summary.map(
                        (edge, index) => (
                          <>
                            <TableRow
                              className="receipt-payment-account__tableblock--table--rowheader"
                              key={index}
                            >
                              <TableCell colSpan={3}>
                                {edge.acct_group_ldgr_details.gr_ldgr_desc}
                              </TableCell>
                            </TableRow>
                            {data?.GetAcctReceiptsReport?.acct_ldgr_rp_summary
                              .filter(
                                (f) =>
                                  f.acct_group_ldgr_details.id ===
                                  edge.acct_group_ldgr_details.id
                              )
                              .map((ldgr, index2) => (
                                <TableRow
                                  key={index2}
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                      payload: {
                                        accountLedgerId:
                                          ldgr.acct_ldgr_details.id,
                                      },
                                    });
                                    dispatch({
                                      type: payloadTypes.SET_LEDGER,
                                      payload: {
                                        ledger: {
                                          label:
                                            ldgr.acct_ldgr_details.ldgr_desc,
                                          value: ldgr.acct_ldgr_details.id,
                                        },
                                      },
                                    });
                                    setReceiptModal(!receiptModal);
                                  }}
                                >
                                  <TableCell className="receipt-payment-account__tableblock--table--desc">
                                    {ldgr.acct_ldgr_details.ldgr_desc}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="receipt-payment-account__tableblock--table--amount"
                                  >
                                    {ldgr.ldgr_rp_rcpt_show < 0
                                      ? `(${format(
                                          Math.abs(ldgr.ldgr_rp_rcpt_show)
                                        )})`
                                      : format(ldgr.ldgr_rp_rcpt_show)}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="receipt-payment-account__tableblock--table--amount font-green"
                                  >
                                    {index2 ===
                                    data.GetAcctReceiptsReport.acct_ldgr_rp_summary.filter(
                                      (f) =>
                                        f.acct_group_ldgr_details.id ===
                                        edge.acct_group_ldgr_details.id
                                    ).length -
                                      1
                                      ? edge.gr_rp_rcpt_show < 0
                                        ? `(${format(
                                            Math.abs(edge.gr_rp_rcpt_show)
                                          )})`
                                        : format(edge.gr_rp_rcpt_show)
                                      : EMPTY_STRING}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </>
                        )
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div className="col h100">
            {PaymentsError ? (
              <b className="nodata">{PaymentsError.message}</b>
            ) : (
              <TableContainer className="receipt-payment-account__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="receipt-payment-account__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Payment</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="receipt-payment-account__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {Payments?.GetAcctPaymentsReport.group_ldgr_rp_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="receipt-payment-account__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {/* // filtering this because the acct ledger should
                            always match with it's group ledger eg: bank acct
                            ledger should always come under bank group ledger */}
                              {Payments.GetAcctPaymentsReport.acct_ldgr_rp_summary
                                ?.filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow
                                      key={index2}
                                      onClick={() => {
                                        setReceiptModal(!receiptModal);

                                        dispatch({
                                          type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                          payload: {
                                            accountLedgerId:
                                              ldgr.acct_ldgr_details.id,
                                          },
                                        });
                                        dispatch({
                                          type: payloadTypes.SET_LEDGER,
                                          payload: {
                                            ledger: {
                                              label:
                                                ldgr.acct_ldgr_details
                                                  .ldgr_desc,
                                              value: ldgr.acct_ldgr_details.id,
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <TableCell className="receipt-payment-account__tableblock--table--desc">
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="receipt-payment-account__tableblock--table--amount"
                                      >
                                        {ldgr.ldgr_rp_pymt_show < 0
                                          ? `(${format(
                                              Math.abs(ldgr.ldgr_rp_pymt_show)
                                            )})`
                                          : format(ldgr.ldgr_rp_pymt_show)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="receipt-payment-account__tableblock--table--amount font-green"
                                      >
                                        {/* // filtering this because the acct ledger
                                      should always match with it's group ledger
                                      eg: bank acct ledger should always come
                                      under bank group ledger */}
                                        {index2 ===
                                        Payments.GetAcctPaymentsReport.acct_ldgr_rp_summary?.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? edge.gr_rp_pymt_show < 0
                                            ? `(${format(
                                                Math.abs(edge.gr_rp_pymt_show)
                                              )})`
                                            : format(edge.gr_rp_pymt_show)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        <div className="receipt-payment-account__footer row g-0">
          <div className="col receipt-payment-account__footer--total">
            <div className="receipt-payment-account__footer--total--text">
              Grand Total :
            </div>
            <div className="receipt-payment-account__footer--total--count">
              {rcptGrandTotal > 0
                ? format(rcptGrandTotal)
                : `(${format(Math.abs(rcptGrandTotal))})`}
            </div>
          </div>
          <div className="col receipt-payment-account__footer--total">
            <div className="receipt-payment-account__footer--total--text">
              Grand Total :
            </div>
            <div className="receipt-payment-account__footer--total--count">
              {paymentGrandTotal > 0
                ? format(paymentGrandTotal)
                : `(${format(Math.abs(paymentGrandTotal))})`}
            </div>
          </div>
        </div>
      </div>
      {pageType === PageFor.GENERAL ? (
        <div className="button-left">
          <Button mode="export" onClick={downloadExcel} />

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      ) : (
        <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
      )}

      {/* receiptmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={receiptModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ReceiptModal
              type={BankOrCash.BANK}
              pageType={PageFor.MODAL}
              setModalFlag={setReceiptModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setReceiptModal(!receiptModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || PaymentsLoading} />
    </>
  );
};

export default Index;

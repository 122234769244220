import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
//Images
import Pointer from "../../../../images/Pointer.svg";

import Input from "../../../../stories/Input/Input";
import { TableHeaderProps } from "../../../../Types/Tables";
import UseSelect from "../../../../customhooks/UseSelect";

import { BooksReportType } from "../../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";
import useLibraryTableJson from "../../json/useLibraryTableJson";
const List = () => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { Library_Table } = useLibraryTableJson();

  const List = [
    {
      value: 1,
      label: "List of Currently Issued Books",
      queryType: BooksReportType.BOOKS_ISSUED,
      url: `/${InstId}/library/reports/currentlyissuedbooks`,
    },
    {
      value: 2,
      label: "List of Currently Received Books",
      queryType: BooksReportType.BOOKS_RETURNED,
      url: `/${InstId}/library/reports/currentlyrecievedbooks`,
    },
    {
      value: 3,
      label: "List of Issued & Received Books",
      queryType: BooksReportType.BOOKS_ISSUED_AND_RETURNED,
      url: `/${InstId}/library/reports/bothissuedandreturned`,
    },
    {
      value: 4,
      label: "Books OverDue",
      url: `/${InstId}/library/reports/booksoverdue`,
    },
    {
      value: 5,
      label: "Fine Collection",
      url: `/${InstId}/library/reports/finecollection`,
    },
    {
      value: 6,
      label: "Books Clearance List",
      url: `/${InstId}/library/reports/booksclearancelist`,
    },
    {
      value: 7,
      label: "Reference Books List",
      url: `/${InstId}/library/reports/booksclearancelist`,
    },
  ];
  const TransactionList = UseSelect(List!, searchText);
  return (
    <TableContainer className="library-report__transaction-list--table">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {Library_Table.Reports.List.Table_Headers.map(
              (th: TableHeaderProps, index: React.Key) => {
                return <TableCell key={index}>{th.labelName}</TableCell>;
              }
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell className="library-report__transaction-list--table--input">
              <Input
                id="search"
                type="text"
                placeholder="Search ..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchText(e.target.value)
                }
              />
            </TableCell>
          </TableRow>
          {TransactionList?.map((label) => {
            return (
              <TableRow key={label.value} selected={selected === label.value}>
                <TableCell
                  onClick={() => {
                    navigate(label.url!);
                    setSelected(label.value);
                  }}
                  className={
                    selected === label.value ? "selected-row" : EMPTY_STRING
                  }
                >
                  {selected === label.value ? (
                    <img src={Pointer} alt="/" className="pointer-icon" />
                  ) : (
                    EMPTY_STRING
                  )}
                  {label.label}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;

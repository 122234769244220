import * as Yup from "yup";
import { Number_Regex, OnlyAlphabets } from "../../../utils/validationRules";
export const loginUserValidation = Yup.object({
  emp_first_name: Yup.string()
    .required("Firstname is required")
    .matches(OnlyAlphabets),
  emp_mobile: Yup.string()
    .min(10, "Invalid mobile")
    .required("Mobile is required")
    .matches(Number_Regex),
  emp_email: Yup.string().email().required("Email is required"),
});

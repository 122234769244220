import { gql } from "@apollo/client";

export const GetLibPurchaseMasterByVendorId = gql`
  query GetLibPurchaseMasterByVendorId(
    $name: String
    $first: Int
    $after: Cursor
    $vendor_id: ID!
    $sortBy: LibPurchaseMasterOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibPurchaseMasterByVendorId(
      token: $token
      first: $first
      after: $after
      vendor_id: $vendor_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ purBillNoContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          pur_bill_no
          pur_total_books
          pur_date
          pur_value
          pur_actual_value
          pur_closed
          inst_id
          lib_book_vendor_id
          purchase_details {
            id
            pur_sl_no
            pur_rate
            pur_qty
            pur_value
            pur_actual_value
            inst_id
            lib_purchase_master_id
            lib_book_classification_id
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GetLibPurchaseDetailsByPurchaseMaster = gql`
  query GetLibPurchaseDetailsByPurchaseMaster(
    $token: String!
    $inst_id: ID!
    $lib_purchase_master_id: ID!
    $lib_book_classification_id: ID!
  ) {
    GetLibPurchaseDetailsByPurchaseMaster(
      token: $token
      inst_id: $inst_id
      lib_purchase_master_id: $lib_purchase_master_id
      lib_book_classification_id: $lib_book_classification_id
    ) {
      id
      pur_sl_no
      pur_rate
      pur_qty
      pur_value
      pur_actual_value
      inst_id
      lib_purchase_master_id
      lib_book_classification_id
    }
  }
`;

import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { Step, StepLabel, Stepper, TextField,  } from "@mui/material";
import { LabelNameProps } from "../../../../Types/Labels";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { useNavigate } from "react-router-dom";
import Add from "../../../../images/Add.svg";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";
import {
  Operation,
  PageFor,
  TransportMasters,
} from "../../../../utils/Enum.types";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import VehicleType from "../../Masters/VehicleType";
import VehicleBodyType from "../../Masters/VehicleBodyType";
import TyreSize from "../../Masters/TyreSize";
import Close from "../../../../images/Close.svg";
import UnitOfMeasurement from "../../Masters/UnitOfMeasurement";
import TyresUSed from "../../Masters/TyresUsed";
import SeatingCapacity from "../../Masters/SeatingCapacity";
import NumberPlate from "../../Masters/NumberPlate";
import ManufacturerModel from "../../Masters/ManufacturerModel";
import Manufacturer from "../../Masters/Manufacturer";
import LicenseType from "../../Masters/LicenseType";
import ItemGroup from "../../Masters/ItemGroup";
import ItemCategory from "../../Masters/ItemCategory";
import FuelUsed from "../../Masters/FuelUsed";
import FuelBrand from "../../Masters/FuelBrand";
import Designation from "../../Masters/Designation";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";

const { VMS_Form } = require("../../../json/form.json");

interface Props {
  operation: Operation;
  step: number;
}

const stepHeader = () => {
  return [
    "Vehicle Information",
    "Vehicle Purchase and Other Details",
    "Vehicle Current Details",
  ];
};
const New = ({ step, operation }: Props) => {
  const navigate = useNavigate();
  const stepsHeader = stepHeader();
  const [permitNeeded, setPermitNeeded] = useState(false);
  const [vehicleType, setVehicleType] = useState(false);
  const [vehicleBodyType, setVehicleBodyType] = useState(false);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState(false);
  const [tyresUsed, setTyresUsed] = useState(false);
  const [tyreSize, setTyreSize] = useState(false);
  const [seatingCapacity, setSeatingCapacity] = useState(false);
  const [numberPlate, setNumberPlate] = useState(false);
  const [manufacturerModel, setManufacturerModel] = useState(false);
  const [manufacturer, setManufacturer] = useState(false);
  const [licenseType, setLicenseType] = useState(false);
  const [itemGroup, setItemGroup] = useState(false);
  const [itemCategory, setItemCategory] = useState(false);
  const [fuelUsed, setFuelUsed] = useState(false);
  const [fuelBrand, setFuelBrand] = useState(false);
  const [designation, setDesignation] = useState(false);

  const handleToggleVehicleType = () => setVehicleType(!vehicleType);
  const handleToggleVehicleBodyType = () =>
    setVehicleBodyType(!vehicleBodyType);
  const handleToggleUnitOfMeasurement = () =>
    setUnitOfMeasurement(!unitOfMeasurement);
  const handleToggleTyresUsed = () => setTyresUsed(!tyresUsed);
  const handleToggleTyreSize = () => setTyreSize(!tyreSize);
  const handleToggleSeatingCapacity = () =>
    setSeatingCapacity(!seatingCapacity);
  const handleToggleNumberPlate = () => setNumberPlate(!numberPlate);
  const handleToggleManufacturerModel = () =>
    setManufacturerModel(!manufacturerModel);
  const handleToggleManufacturer = () => setManufacturer(!manufacturer);
  const handleToggleLicenseType = () => setLicenseType(!licenseType);
  const handleToggleItemGroup = () => setItemGroup(!itemGroup);
  const handleToggleItemCategory = () => setItemCategory(!itemCategory);
  const handleToggleFuelUsed = () => setFuelUsed(!fuelUsed);
  const handleToggleFuelBrand = () => setFuelBrand(!fuelBrand);
  const handleToggleDesignation = () => setDesignation(!designation);
  const renderModal = (
    pageType: TransportMasters,
    isOpen: boolean,
    handleClose: () => void
  ) => (
    <Modal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={StudentModalStyles}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          {pageType === TransportMasters.TYRE_SIZE ? (
            <>
              <TyreSize pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.VEHICLE_BODY_TYPE ? (
            <>
              <VehicleBodyType pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.UNIT_OF_MEASUREMENT ? (
            <>
              <UnitOfMeasurement pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.TYRES_USED ? (
            <>
              <TyresUSed pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.SEATING_CAPACITY ? (
            <>
              <SeatingCapacity pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.NUMBER_PLATE ? (
            <>
              <NumberPlate pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.MANUFACTURER_MODEL ? (
            <>
              <ManufacturerModel pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.MANUFACTURER ? (
            <>
              <Manufacturer pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.LICENSE_TYPE ? (
            <>
              <LicenseType pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.ITEM_GROUP ? (
            <>
              <ItemGroup pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.ITEM_CATEGORY ? (
            <>
              <ItemCategory pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.FUEL_USED ? (
            <>
              <FuelUsed pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.FUEL_BRAND ? (
            <>
              <FuelBrand pageType={PageFor.MODAL} />
            </>
          ) : pageType === TransportMasters.DESIGNATION ? (
            <>
              <Designation pageType={PageFor.MODAL} />
            </>
          ) : (
            <>
              <VehicleType pageType={PageFor.MODAL} />
            </>
          )}

          <Button mode="save" />
          <Button mode="clear" />
          <Button mode="cancel" onClick={() => handleClose()} />
        </div>
        <div className="modal-flex__image">
          <img src={Close} alt="/" onClick={handleClose} />
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      <Home DashBoardRequired={false} />

      <div className="vms-v-details-new">
        <div className="vms-v-details-new__stepper">
          {operation === Operation.CREATE && (
            <Stepper alternativeLabel activeStep={step - 1}>
              {stepsHeader.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </div>
        <Title>
          {operation === Operation.UPDATE
            ? "Update Vehicle Information"
            : "Vehicle Information"}
        </Title>
        <div
          className={
            operation === Operation.UPDATE
              ? "vms-v-details-new__data--update row g-0"
              : "vms-v-details-new__data row g-0"
          }
        >
          <div className="col">
            {VMS_Form.Vehicle_Details.BasicDetails.map(
              (label: LabelNameProps, index: React.Key) => {
                return (
                  <>
                    <div className="label-grid" key={index}>
                      <Label>{label.LabelName}</Label>
                      <Input type={label.dataType} />
                    </div>
                  </>
                );
              }
            )}
            <div className="label-grid">
              <Label>Permit Needed</Label>

              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Yes", "No"]}
                forcePopupIcon
                onChange={(event, newValue) =>
                  setPermitNeeded(newValue === "Yes")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            {permitNeeded && (
              <div className="label-grid">
                <Label>Permit Needed Details</Label>
                <Input />
              </div>
            )}

            <div className="vms-v-details-new__data--grid">
              <Label>Vehicle Type</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Bus"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setVehicleType(!vehicleType)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Vehicle Body Type</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Mini Bus"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setVehicleBodyType(!vehicleBodyType)}
                />
              </CustomTooltip>
            </div>
          </div>
          <div className="col">
            <div className="vms-v-details-new__data--grid">
              <Label>Tyres Size</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["14mm"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setTyreSize(!tyreSize)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Tyres Used</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["14 Tyres"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setTyresUsed(!tyresUsed)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Seating Capacity</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["32 Seats"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setSeatingCapacity(!seatingCapacity)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Fuel Used</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Petro"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setFuelBrand(!fuelBrand)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Manufacturer</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Tata"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setManufacturer(!manufacturer)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Manufacturer Model</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Nano"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setManufacturerModel(!manufacturerModel)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Number Plate Type</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Yellow Board"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setNumberPlate(!numberPlate)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Item Group</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Item1"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setItemGroup(!itemGroup)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Item Category</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["c1"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setItemCategory(!itemCategory)}
                />
              </CustomTooltip>
            </div>
            <div className="vms-v-details-new__data--grid">
              <Label>Unit Of Measurement</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Ltr"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-v-details-new__data--image"
                  alt="/"
                  onClick={() => setUnitOfMeasurement(!unitOfMeasurement)}
                />
              </CustomTooltip>
            </div>
          </div>
        </div>
        {operation === Operation.UPDATE ? (
          <Button mode="save" />
        ) : (
          <Button
            mode="save-continue"
            onClick={() => navigate(`/vms/vehicledetails/new/vehiclepurchase`)}
          />
        )}

        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      {renderModal(
        TransportMasters.VEHICLE_TYPE,
        vehicleType,
        handleToggleVehicleType
      )}
      {renderModal(
        TransportMasters.VEHICLE_BODY_TYPE,
        vehicleBodyType,
        handleToggleVehicleBodyType
      )}
      {renderModal(
        TransportMasters.UNIT_OF_MEASUREMENT,
        unitOfMeasurement,
        handleToggleUnitOfMeasurement
      )}
      {renderModal(
        TransportMasters.TYRES_USED,
        tyresUsed,
        handleToggleTyresUsed
      )}
      {renderModal(TransportMasters.TYRE_SIZE, tyreSize, handleToggleTyreSize)}
      {renderModal(
        TransportMasters.SEATING_CAPACITY,
        seatingCapacity,
        handleToggleSeatingCapacity
      )}
      {renderModal(
        TransportMasters.NUMBER_PLATE,
        numberPlate,
        handleToggleNumberPlate
      )}
      {renderModal(
        TransportMasters.MANUFACTURER_MODEL,
        manufacturerModel,
        handleToggleManufacturerModel
      )}
      {renderModal(
        TransportMasters.MANUFACTURER,
        manufacturer,
        handleToggleManufacturer
      )}
      {renderModal(
        TransportMasters.LICENSE_TYPE,
        licenseType,
        handleToggleLicenseType
      )}
      {renderModal(
        TransportMasters.ITEM_GROUP,
        itemGroup,
        handleToggleItemGroup
      )}
      {renderModal(
        TransportMasters.ITEM_CATEGORY,
        itemCategory,
        handleToggleItemCategory
      )}
      {renderModal(TransportMasters.FUEL_USED, fuelUsed, handleToggleFuelUsed)}
      {renderModal(
        TransportMasters.FUEL_BRAND,
        fuelBrand,
        handleToggleFuelBrand
      )}
      {renderModal(
        TransportMasters.DESIGNATION,
        designation,
        handleToggleDesignation
      )}
    </>
  );
};

export default New;

import { gql } from "@apollo/client";

export const AcctFinYrPosting = gql`
  mutation AcctFinYrPosting(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $prev_fin_yr_id: ID!
    $input: NewFinYrData!
    $post_prev_fin: Boolean!
  ) {
    AcctFinYrPosting(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      prev_fin_yr_id: $prev_fin_yr_id
      input: $input
      post_prev_fin: $post_prev_fin
    )
  }
`;

export const AcctFinYrPostingForStudent = gql`
  mutation AcctFinYrPostingForStudent(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $from_fin_yr_id: ID!
    $to_fin_yr_id: ID!
    $student_id: ID!
    $post_prev_fin: Boolean!
  ) {
    AcctFinYrPostingForStudent(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      from_fin_yr_id: $from_fin_yr_id
      to_fin_yr_id: $to_fin_yr_id
      student_id: $student_id
      post_prev_fin: $post_prev_fin
    )
  }
`;
export const AcdYrPostingForStudent = gql`
  mutation AcdYrPostingForStudent(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: AcdStdPostingData!
  ) {
    AcdYrPostingForStudent(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

import { gql } from "@apollo/client";

export const GetPayRollLeaveLdgrsByInstId = gql`
  query GetPayRollLeaveLdgrsByInstId(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $sortBy: PayRollLeaveLdgrOrderField
    $direction: OrderDirection!
    $name: String!
  ) {
    GetPayRollLeaveLdgrsByInstId(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ leaveDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          leave_desc
          leave_idx
          leave_short_code
          leave_type
          leave_is_system_defined
          leave_is_ldgr_active
          leave_only_for_women
          leave_is_credited
          leave_carry_forwarded
          is_lwp_ldgr
          leave_is_earned
          leave_for_salary_not_used
        }
        cursor
      }
    }
  }
`;

export const GetLeaveById = gql`
  query nodequery($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on PayRollLeaveLdgr {
        leave_desc
        leave_idx
        leave_type
        leave_short_code
        leave_is_system_defined
        leave_is_ldgr_active
        leave_only_for_women
        leave_is_credited
        leave_carry_forwarded
        leave_for_salary_not_used
      }
    }
  }
`;

export const GetEduateLeaveLedgerById = gql`
  query nodequery($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on SwLeaveLdgr {
        leave_desc
        leave_idx
        leave_type
        leave_short_code
        leave_is_system_defined
        leave_is_ldgr_active
        leave_only_for_women
        leave_is_credited
        leave_carry_forwarded
        leave_for_salary_not_used
      }
    }
  }
`;

export const GetPayRollEmpLeaveMasterDetails = gql`
  query GetPayRollEmpLeaveMasterDetails(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $pr_emp_id: ID!
  ) {
    GetPayRollEmpLeaveMasterDetails(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      pr_emp_id: $pr_emp_id
    ) {
      pr_emp_id
      total_leaves_availed
      leave_details {
        id
        leaves_availed
        leaves_used
        leaves_balance
        pr_leave_ldgr_id
        leave_ldgr_details {
          id
          leave_desc
          leave_idx
          leave_type
          leave_short_code
          leave_is_system_defined
          leave_is_ldgr_active
          leave_is_earned
          leave_only_for_women
          leave_is_credited
          leave_carry_forwarded
          is_lwp_ldgr
          leave_for_salary_not_used
        }
      }
    }
  }
`;

export const PayRollLeaveLdgr = gql`
  query PayRollLeaveLdgr($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollLeaveLdgr {
        id
        leave_desc
        leave_idx
        leave_type
        leave_short_code
        leave_is_system_defined
        leave_is_ldgr_active
        leave_is_earned
        leave_only_for_women
        leave_is_credited
        leave_carry_forwarded
        leave_for_salary_not_used
        is_lwp_ldgr
      }
    }
  }
`;

export const GetPayRollEmpDefaultLeaveLdgr = gql`
  query GetPayRollEmpDefaultLeaveLdgr($token: String!, $inst_id: ID!) {
    GetPayRollEmpDefaultLeaveLdgr(token: $token, inst_id: $inst_id) {
      id
      leaves_availed
      pr_leave_ldgr_id

      leave_ldgr_details {
        id
        leave_desc
        leave_idx
        leave_type
        leave_short_code
        leave_is_system_defined
        leave_is_ldgr_active
        leave_is_earned
        leave_only_for_women
        leave_is_credited
        leave_carry_forwarded
        leave_for_salary_not_used
        is_lwp_ldgr
      }
    }
  }
`;

export const GetSwLeaveLdgrs = gql`
  query GetSwLeaveLdgrs($token: String!) {
    GetSwLeaveLdgrs(token: $token) {
      id
      leave_desc
      leave_idx
      leave_type
      leave_short_code
      leave_is_system_defined
      leave_is_ldgr_active
      leave_is_earned
      leave_only_for_women
      leave_is_credited
      leave_carry_forwarded
      leave_for_salary_not_used
      is_lwp_ldgr
    }
  }
`;

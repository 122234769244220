import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import usePredefindedSalaryLedgers, {
  SWSalaryLedgerListType,
} from "../../hooks/usePredefindedSalaryLedgers";
import useToken from "../../../../customhooks/useToken";
import ArrowGreen from "../../../../images/ArrowGreen.svg";
import ArrowRed from "../../../../images/ArrowRed.svg";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { AntSwitch } from "../../../../pages/Switch";
import { GetSwConfigVariables } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
  GlobalPageConfigData,
} from "../../../../Types/configtypes";
import { TableHeaderProps } from "../../../../Types/Tables";
import { EMPTY_STRING } from "../../../../utils/constants";
import {
  ModuleName,
  MoveDirection,
  Operation,
} from "../../../../utils/Enum.types";
import { msgType } from "../../../../utils/Form.types";
import { Actions, BASIC, SalaryledgerTypeOptions } from "../../constants";
import {
  PayRollConfigKeys,
  SalaryLedgerType,
  SwConfigQueryType,
} from "../../enums/Enum.types";
import useEmpSalaryLedger from "../../hooks/useEmpSalaryLedger";
import { GetPayRollSalaryLdgrsByInstId } from "../../queries/salary/list";
import { AddOrDeletePayRollPredefinedSalaryLdgr } from "../../queries/salary/mutation";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HR_Table } = require("../../json/table.json");
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  type: MoveDirection;
}

interface SalaryLedgers extends SWSalaryLedgerListType {
  isChecked: boolean;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const PredefinedSalaryLedger = ({ setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [eduateLedgers, setEduateLedgers] = useState<SalaryLedgers[]>([]);
  const [hrLedgers, setHrLedgers] = useState<SalaryLedgers[]>([]);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [pageSwDetails, setPageSwConfigList] = useState<GlobalPageConfigData[]>(
    []
  );
  const [GetSwConfig, { data: configData, loading: configLoading }] =
    useLazyQuery<GetSwConfigVariablesData, GetSwConfigVariablesVars>(
      GetSwConfigVariables,
      {
        variables: {
          token,
          inst_id: InstId!,
          input: {
            config_query_type: SwConfigQueryType.INST_BY_MODULE,
            str_value: ModuleName.PAYROLL,
            int_value: 0,
          },
        },
      }
    );

  const {
    salaryLedger: { data: salaryLedgerList, loading: salaryLedgerListLoading },
  } = useEmpSalaryLedger();
  const instSalaryLedgerSet = new Set(
    salaryLedgerList?.GetPayRollSalaryLdgrsByInstId?.filter(
      ({ sal_ldgr_is_system_generated }) => sal_ldgr_is_system_generated
    ).map(({ sal_ldgr_desc }) => sal_ldgr_desc)
  );

  const handleDeductionConfigClick = (e: React.MouseEvent) => {
    const { name, checked } = e.target as HTMLInputElement;

    setPageSwConfigList(
      pageSwDetails.map((swdetail) => {
        if (name === swdetail.config_key) {
          return {
            ...swdetail,
            config_boolean_value: checked ? checked : false,
          };
        } else {
          return swdetail;
        }
      })
    );
  };
  useEffect(() => {
    if (configData && !configLoading) {
      setPageSwConfigList(
        configData.GetSwConfigVariables.filter(
          ({ config_key }) =>
            config_key === PayRollConfigKeys.PR_ENABLE_GRP_INSURANCE_SAL_TYPE ||
            config_key === PayRollConfigKeys.PR_ENABLE_INSURANCE_SAL_TYPE ||
            config_key === PayRollConfigKeys.PR_ENABLE_LOAN_SAL_TYPE
        )
      );
    }
  }, [configData, configLoading]);

  const [AddSalary, { loading: creationLoading }] = useMutation(
    AddOrDeletePayRollPredefinedSalaryLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    predefinedSalaryledger: { data, loading, error },
  } = usePredefindedSalaryLedgers();
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount, type } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            className="salary-ledger__block--data--table--actions"
          >
            {type === MoveDirection.RIGHT ? (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  eduateLedgers?.filter((data) => data.isChecked).length > 0
                }
              />
            ) : (
              <Checkbox
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  hrLedgers?.filter((data) => data.isChecked).length > 0
                }
              />
            )}
          </TableCell>

          {HR_Table.SalaryLedger.Table_Headers.filter(
            (head: TableHeaderProps) => head.labelName !== Actions
          ).map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={th.className}>
                {th.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  const handleClick = (
    event: React.MouseEvent<unknown>,
    data: SalaryLedgers,
    direction: MoveDirection
  ) => {
    const { checked } = event.target as HTMLInputElement;
    switch (direction) {
      case MoveDirection.LEFT:
        const mappedDataLeft = eduateLedgers?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setEduateLedgers(mappedDataLeft);
        break;
      case MoveDirection.RIGHT:
        const mappedDataRight = hrLedgers?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setHrLedgers(mappedDataRight);
        break;
      default:
        break;
    }
  };
  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    direction: MoveDirection
  ) => {
    switch (direction) {
      case MoveDirection.LEFT:
        setEduateLedgers(
          eduateLedgers.map((ledger) => ({
            ...ledger,
            isChecked: event.target.checked ? true : false,
          }))
        );
        break;
      case MoveDirection.RIGHT:
        setHrLedgers(
          hrLedgers.map((ledger) => ({
            ...ledger,
            isChecked: event.target.checked ? true : false,
          }))
        );
        break;
      default:
        break;
    }
  };

  //Implemented using chatGPT
  const moveBooks = (direction: MoveDirection): void => {
    let sourceLedgers: SalaryLedgers[], destinationLedgers: SalaryLedgers[];

    switch (direction) {
      case MoveDirection.RIGHT:
        sourceLedgers = eduateLedgers;
        destinationLedgers = eduateLedgers.filter(
          (data: SalaryLedgers) => !data.isChecked
        );
        setEduateLedgers(destinationLedgers);
        const selectedLedgers = sourceLedgers.filter(
          (data: SalaryLedgers) => data.isChecked
        );
        selectedLedgers.forEach((ledger: SalaryLedgers) => {
          ledger.isChecked = false;
        });
        setHrLedgers([...hrLedgers, ...selectedLedgers]);

        break;
      case MoveDirection.LEFT:
        sourceLedgers = hrLedgers;
        destinationLedgers = hrLedgers.filter(
          (data: SalaryLedgers) => !data.isChecked
        );
        setHrLedgers(destinationLedgers);
        const selectedLedgers2 = sourceLedgers.filter(
          (data: SalaryLedgers) => data.isChecked
        );
        selectedLedgers2.forEach((ledger: SalaryLedgers) => {
          ledger.isChecked = false;
        });
        setEduateLedgers([...eduateLedgers, ...selectedLedgers2]);
        break;
      default:
        return;
    }
  };

  const handleSubmit = () => {
    if (eduateLedgers.find(({ sal_ldgr_type }) => sal_ldgr_type === BASIC)) {
      setMessage({
        message: "Basic cannot be deleted",
        flag: true,
        operation: Operation.NONE,
      });
    } else {
      AddSalary({
        variables: {
          token,
          inst_id: InstId,
          user_details,

          input: {
            add_sal_ldgrs: hrLedgers
              .filter(
                ({ sal_ldgr_desc }) =>
                  instSalaryLedgerSet.has(sal_ldgr_desc) === false
              )
              .map((ledger) => ({
                sal_ldgr_desc: ledger.sal_ldgr_desc,
                sal_ldgr_short_desc: ledger.sal_ldgr_short_desc,
                sal_ldgr_idx: ledger.sal_ldgr_idx,
                sal_ldgr_earn_deduct: ledger.sal_ldgr_earn_deduct,
                sal_ldgr_type: ledger.sal_ldgr_type,
                sal_ldgr_is_active: true,
                sal_ldgr_round_to_next_int: ledger.sal_ldgr_round_to_next_int,
                sal_ldgr_remove_decimal: ledger.sal_ldgr_remove_decimal,
                sal_ldgr_is_basic: ledger.sal_ldgr_is_basic,
                sal_ldgr_is_loan_ac: ledger.sal_ldgr_is_loan_ac,
                sal_ldgr_is_system_generated:
                  ledger.sal_ldgr_is_system_generated,
              })),
            pr_config: pageSwDetails.map((swdetail) => ({
              id: swdetail.id,
              config_key: swdetail.config_key,
              config_boolean_value: swdetail.config_boolean_value,
            })),
            delete_sal_ldgr_ids: eduateLedgers
              .filter(({ sal_ldgr_desc }) =>
                instSalaryLedgerSet.has(sal_ldgr_desc)
              )
              .map(({ id }) => id),
          },
        },
        refetchQueries: [
          {
            query: GetSwConfigVariables,
            variables: {
              token,
              inst_id: InstId!,
              input: {
                config_query_type: SwConfigQueryType.INST_BY_MODULE,
                str_value: ModuleName.PAYROLL,
                int_value: 0,
              },
            },
          },
          {
            query: GetPayRollSalaryLdgrsByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Predefined Salary Ledgers added",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModalFlag(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(
    () => {
      if (data && !loading) {
        setEduateLedgers(
          data.GetSwSalaryLdgrs.filter(
            ({ sal_ldgr_desc }) =>
              instSalaryLedgerSet.has(sal_ldgr_desc) === false
          ).map((ledger) => ({
            id: ledger.id,
            sal_ldgr_desc: ledger.sal_ldgr_desc,
            sal_ldgr_short_desc: ledger.sal_ldgr_short_desc,
            sal_ldgr_idx: ledger.sal_ldgr_idx,
            sal_ldgr_earn_deduct: ledger.sal_ldgr_earn_deduct,
            sal_ldgr_type: ledger.sal_ldgr_type,
            sal_ldgr_round_to_next_int: ledger.sal_ldgr_round_to_next_int,
            sal_ldgr_remove_decimal: ledger.sal_ldgr_remove_decimal,
            sal_ldgr_is_basic: ledger.sal_ldgr_is_basic,
            sal_ldgr_is_loan_ac: ledger.sal_ldgr_is_loan_ac,
            sal_ldgr_is_system_generated: ledger.sal_ldgr_is_system_generated,
            isChecked: false,
          })) ?? []
        );
      }
    },
    // eslint-disable-next-line
    [data, loading]
  );

  useEffect(() => {
    if (salaryLedgerList && !salaryLedgerListLoading) {
      setHrLedgers(
        salaryLedgerList.GetPayRollSalaryLdgrsByInstId.filter(
          ({ sal_ldgr_is_system_generated }) => sal_ldgr_is_system_generated
        )?.map((ledger) => ({
          id: ledger.id,
          sal_ldgr_desc: ledger.sal_ldgr_desc,
          sal_ldgr_short_desc: ledger.sal_ldgr_short_desc,
          sal_ldgr_idx: ledger.sal_ldgr_idx,
          sal_ldgr_earn_deduct: ledger.sal_ldgr_earn_deduct,
          sal_ldgr_type: ledger.sal_ldgr_type,
          sal_ldgr_round_to_next_int: ledger.sal_ldgr_round_to_next_int,
          sal_ldgr_remove_decimal: ledger.sal_ldgr_remove_decimal,
          sal_ldgr_is_basic: ledger.sal_ldgr_is_basic,
          sal_ldgr_is_loan_ac: false,
          sal_ldgr_is_system_generated: ledger.sal_ldgr_is_system_generated,
          isChecked: false,
        })) ?? []
      );
    }
  }, [salaryLedgerList, salaryLedgerListLoading]);
  useEffect(() => {
    if (token && InstId) {
      GetSwConfig();
    }
  }, [token, GetSwConfig, InstId]);
  return (
    <>
      <Title>Add Predefined Salary Ledger</Title>
      <div className="add-predefined-salary-ledger">
        <div className="row g-0 add-predefined-salary-ledger__data">
          <div className="col booktype-left h-100">
            <Title variant="subtitle1">Eduate ledgers</Title>

            <div className="add-predefined-salary-ledger__tableblock">
              {error ? (
                <b className="nodata">{error.message}</b>
              ) : data?.GetSwSalaryLdgrs.length! > 0 ? (
                <TableContainer className="add-predefined-salary-ledger__table">
                  <Table stickyHeader>
                    <EnhancedTableHead
                      numSelected={
                        eduateLedgers.filter(({ isChecked }) => isChecked)
                          .length
                      }
                      onSelectAllClick={(e) => {
                        handleSelectAllClick(e, MoveDirection.LEFT);
                      }}
                      rowCount={eduateLedgers.length}
                      type={MoveDirection.LEFT}
                    />
                    <TableBody>
                      {eduateLedgers.map((response, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            key={response.id}
                            hover
                            role="checkbox"
                            aria-checked={response.isChecked}
                            tabIndex={-1}
                            selected={response?.isChecked}
                          >
                            <TableCell
                              padding="checkbox"
                              id="td-center"
                              className="add-predefined-salary-ledger__table--slno"
                            >
                              <Checkbox
                                checked={response.isChecked}
                                onClick={(event) =>
                                  handleClick(
                                    event,
                                    response,
                                    MoveDirection.LEFT
                                  )
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                disabled={
                                  hrLedgers?.filter((data) => data.isChecked)
                                    .length > 0
                                }
                              />
                            </TableCell>
                            <TableCell
                              id="td-center"
                              className="add-predefined-salary-ledger__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell>{response.sal_ldgr_desc}</TableCell>
                            <TableCell className="add-predefined-salary-ledger__table--desc">
                              {response.sal_ldgr_short_desc}
                            </TableCell>
                            <TableCell className="add-predefined-salary-ledger__table--desc">
                              {
                                SalaryledgerTypeOptions.find(
                                  ({ value }) =>
                                    value === response.sal_ldgr_earn_deduct
                                )?.label
                              }
                            </TableCell>
                            <TableCell className="add-predefined-salary-ledger__table--desc">
                              {response.sal_ldgr_type}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <b className="nodata">No data Please try adding...</b>
              )}
            </div>
          </div>
          <div className="add-predefined-salary-ledger__tableblock--swap-image">
            <div>
              <img
                src={ArrowGreen}
                alt="/"
                onClick={() => {
                  if (
                    eduateLedgers?.filter((data) => data.isChecked).length > 0
                  ) {
                    moveBooks(MoveDirection.RIGHT);
                  }
                }}
                className={
                  eduateLedgers?.filter((data) => data.isChecked).length > 0
                    ? EMPTY_STRING
                    : "opacity"
                }
              />
            </div>
            <br />
            <div>
              <img
                src={ArrowRed}
                alt="/"
                onClick={() => moveBooks(MoveDirection.LEFT)}
                className={
                  hrLedgers?.filter((data) => data.isChecked).length > 0
                    ? EMPTY_STRING
                    : "opacity"
                }
              />
            </div>
          </div>

          <div className="col booktype-right h-100">
            <Title variant="subtitle1">HR ledgers</Title>

            <div className="add-predefined-salary-ledger__tableblock">
              {error ? (
                <b className="nodata">{error.message}</b>
              ) : hrLedgers.length! > 0 ? (
                <>
                  <TableContainer className="add-predefined-salary-ledger__table">
                    <Table stickyHeader>
                      <EnhancedTableHead
                        numSelected={
                          hrLedgers.filter(
                            ({ sal_ldgr_earn_deduct, isChecked }) =>
                              sal_ldgr_earn_deduct ===
                                SalaryLedgerType.EARNING && isChecked
                          ).length
                        }
                        onSelectAllClick={(e) =>
                          handleSelectAllClick(e, MoveDirection.RIGHT)
                        }
                        rowCount={
                          hrLedgers.filter(
                            ({ sal_ldgr_earn_deduct }) =>
                              sal_ldgr_earn_deduct === SalaryLedgerType.EARNING
                          ).length
                        }
                        type={MoveDirection.RIGHT}
                      />
                      <TableBody>
                        {hrLedgers.map((response, index: number) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              key={response.id}
                              hover
                              role="checkbox"
                              aria-checked={response.isChecked}
                              tabIndex={-1}
                              selected={response?.isChecked}
                            >
                              <TableCell
                                padding="checkbox"
                                id="td-center"
                                className="add-predefined-salary-ledger__table--slno"
                              >
                                {!response.sal_ldgr_is_basic && (
                                  <Checkbox
                                    checked={response.isChecked}
                                    onClick={(event) =>
                                      handleClick(
                                        event,
                                        response,
                                        MoveDirection.RIGHT
                                      )
                                    }
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                    disabled={
                                      eduateLedgers?.filter(
                                        (data) => data.isChecked
                                      ).length > 0
                                    }
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="add-predefined-salary-ledger__table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell>{response.sal_ldgr_desc}</TableCell>
                              <TableCell className="add-predefined-salary-ledger__table--desc">
                                {response.sal_ldgr_short_desc}
                              </TableCell>
                              <TableCell className="add-predefined-salary-ledger__table--desc">
                                {
                                  SalaryledgerTypeOptions.find(
                                    ({ value }) =>
                                      value === response.sal_ldgr_earn_deduct
                                  )?.label
                                }
                              </TableCell>
                              <TableCell className="add-predefined-salary-ledger__table--type">
                                {response.sal_ldgr_type}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <b className="nodata">No data Please try adding...</b>
              )}
            </div>
          </div>
        </div>
        <div className="row g-0 add-predefined-salary-ledger__salary-types">
          <div className="col"></div>
          <div className="col">
            {pageSwDetails.map((data) => {
              return (
                <FormGroup key={data.id}>
                  <FormControlLabel
                    label={data.config_form_label_caption}
                    labelPlacement="start"
                    control={
                      <AntSwitch
                        checked={data.config_boolean_value}
                        name={data.config_key}
                        onClick={(e) => {
                          handleDeductionConfigClick(e);
                        }}
                      />
                    }
                  />
                </FormGroup>
              );
            })}
          </div>
        </div>

        <Button
          mode="save"
          onClick={handleSubmit}
          disabled={
            eduateLedgers.filter(({ isChecked }) => isChecked).length > 0 ||
            hrLedgers.filter(({ isChecked }) => isChecked).length > 0
          }
        />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>

      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag}
        handleClose={handleClose}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default PredefinedSalaryLedger;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { Cell } from "exceljs";
import Eduate from "../../../images/Eduate_Logo_image.png";
import ExcelJS from "exceljs";
import { TextField } from "@mui/material";
import Close from "../../../images/Close.svg";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Input from ".././../../stories/Input/Input";
import Modal from "react-modal";
import {
  StudentListFields,
  StudentStatus,
  StudentReportType,
  StudentListFor,
  Operation,
  ExcelFont,
  ExcelAlignment,
  ExcelSheetsNames,
  ExcelPageHeader,
  InstitutionConfigurationTypes,
  PageLabel,
  FileUploadParams,
  ColumnVisibilityFor,
  DeviceType,
} from "../../../utils/Enum.types";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import Home from "../Home/Index";

import { StudentModalStyles } from "../../../styles/ModalStyles";

import useDropdownData from "../../../customhooks/useDropdownData";
import { Keys } from "../../../utils/Enum.keys";
import { msgType, responseType } from "../../../utils/Form.types";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import MessageModal from "../../../pages/MessageModal";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  NEW_SHEET,
  HEADER_ALIGNMENT_RIGHT,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import StudentTotalCount from "./Components/StudentTotalCount";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import {
  
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT} from "../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../utils/types";
import useStudentTableJson from "../../../json/useStudentTableJson";
import StudentFieldsForExport, { Fields } from "./StudentFieldsForExport";
import MobileDeviceModal from "./MobileDeviceModal";
import MobileDevice from "../../../images/Installed_Devices.svg";
import { AllocateTeachersDrawer } from "../../../styles/DrawerStyles";
import useAcdDropDownData from "../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";
import { labelClasses, LabeledAutocomplete } from "../../../styles/AutocompleteListStyles";
interface Props {
  studentListFor: StudentListFor;
  queryType: StudentReportType;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  label?: PageLabel;
}
export interface columnsType {
  label: string;
  value: StudentListFields;
  visible: boolean;
}
export const FetchStudentStatusClassName = (row: StudentStatus | string) => {
  switch (row) {
    case StudentStatus.CUR:
      return "std_status--cur";
    case StudentStatus.TC:
      return "std_status";
    case StudentStatus.DET:
      return "std_status--det";
    case StudentStatus.SOA:
      return "std_status--soa";
    case StudentStatus.NXT:
      return "std_status--nxt";
    case StudentStatus.NE:
      return "std_status--ne";

    default:
      break;
  }
};
const StudentsDeviceList = ({ studentListFor, queryType, label }: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [statusSelected, setStatusSelected] = useState<string>("");

  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_ADMISSIONS,
  });
  const [deviceNo, setDeviceNo] = useState(0);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [studentPersonalSwConfigData, setStudentPersonalSwConfigData] =
    useState<Fields[]>([]);

  var [studentListOptions] = useState<readonly string[]>([
    StudentListFields.STD_ADMISSION_NO,
    StudentListFields.STD_REG_NO,
    StudentListFields.STD_NAME,
    StudentListFields.STD_FATHER_NAME,
    StudentListFields.BRANCH_ID,
    StudentListFields.CATEGORY_ID,
    StudentListFields.STD_STATUS,
  ]);

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel
  } = useInstLabels();

  const [messageModal, setMessageModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    rowsPerPage,
    0,
    searchData,
    statusSelected === DeviceType.Installed
      ? StudentReportType.MOBILE_APP_INSTALLED
      : statusSelected === DeviceType.Not_Installed
      ? StudentReportType.MOBILE_APP_NOT_INSTALLED
      : StudentReportType.ACD_STD_GENERAL,
    EMPTY_STRING,
    []
  );

  const { data, fetchMore, loading } = AcademicsStudentData;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { categoryDropDown } = useInstMasterDataByInstId();

  //Categories

  if (USE_CLASS_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CLASS_ID
    );
  }
  if (USE_CATEGORY_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CATEGORY_ID
    );
  }

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
    setStatusSelected(EMPTY_STRING);
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const headerRow = [
    "Sl No",
    ...studentPersonalSwConfigData
      .filter((ele) => ele.enabled)
      .map((ele) => ele.fieldLabel),
  ];

  const studentCol = [
    { title: "Sl", field: "SlNo" },
    ...studentListOptions.map((option) => {
      switch (option) {
        case StudentListFields.STD_ADMISSION_NO:
          return { title: "Adm No", field: "AdmNo" };
        case StudentListFields.STD_REG_NO:
          return { title: "Reg. No.", field: "RegNo" };
        case StudentListFields.STD_NAME:
          return { title: "Name", field: "Name" };
        case StudentListFields.STD_FATHER_NAME:
          return { title: "Father Name", field: "Father" };
        case StudentListFields.STD_EMAIL:
          return { title: "E-Mail", field: "Email" };
        case StudentListFields.STD_MOBILE:
          return { title: "Mobile", field: "Mobile" };
        case StudentListFields.DEPT_ID:
          return { title: departmentLabel, field: "Dept" };
        case StudentListFields.BRANCH_ID:
          return { title: branchLabel, field: "Branch" };
        case StudentListFields.CLASS_ID:
          return { title: classLabel, field: "Class" };
        case StudentListFields.SEMESTER_ID:
          return { title: semesterLabel, field: "Sem" };
        case StudentListFields.SECTION_ID:
          return { title: sectionLabel, field: "Section" };
        case StudentListFields.CATEGORY_ID:
          return { title: categoryLabel, field: "Category" };
        case StudentListFields.STD_STATUS:
          return { title: "No of Devices", field: "Status" };
        default:
          return { title: "", field: "" };
      }
    }),
  ];

  const downloadPdf = () => {
    if (data) {
      const getBase64 = (file: any, cb: (a: string) => void) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          cb(reader.result?.toString()!);
        };
      };
      fetch(LogoOrSign.defaultLogo)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          getBase64(blob, (result) => {
            fetch(Eduate)
              .then((response) => {
                return response.blob();
              })
              .then((blob2) => {
                getBase64(blob2, (result2) => {
                  const doc = new jsPDF("landscape", "mm", "a4");
                  doc.setFont("Helvetica", "bold");
                  let i = 0;
                  var totalPages = doc.getNumberOfPages();
                  for (i = 1; i <= totalPages; i++) {
                    doc.setFontSize(18);
                    const startY = 10 + (i - 1) * 20;
                    doc.setFillColor(240, 240, 240);
                    doc.rect(
                      0,
                      0,
                      doc.internal.pageSize.getWidth(),
                      doc.internal.pageSize.getHeight(),
                      "F"
                    );
                    doc.setTextColor(0, 0, 0);
                    doc.text(
                      `${InstDetails?.data?.nodes[0].inst_name}`,
                      80,
                      startY
                    );
                    doc.setFont("Times New Roman", "normal");
                    doc.setFontSize(13);
                    doc.text(
                      `${InstDetails?.data?.nodes[0]?.inst_address}`,
                      120,
                      startY + 7
                    );

                    doc.setFont("Times New Roman", "normal");
                    doc.setFontSize(13);
                    doc.text(
                      `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                      120,
                      startY + 12
                    );
                    doc.setFontSize(13);
                    doc.text(
                      `Fin-year -${
                        state.ActiveFinYr
                          ? state.ActiveFinYr.fin_yr
                          : EMPTY_STRING
                      }`,
                      45,
                      startY + 21
                    );
                    doc.setFontSize(14);
                    doc.setFont("Times New Roman", "normal");
                    doc.text(`Students List`, 120, startY + 21);
                    doc.setFontSize(13);
                    doc.text(
                      `As-on-date:${toStandardDate(serverDate)}`,
                      230,
                      startY + 21
                    );
                    doc.addImage(result, "JPEG", 15, 5, 22, 22);
                    if (configLogo) {
                      doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                    }
                  }

                  autoTable(doc, {
                    startY: 33,
                    bodyStyles: { valign: "top" },
                    theme: "striped",
                    columns: studentCol.map((col) => ({
                      ...col,
                      dataKey: col.field,
                      styles: { fontSize: 18 },
                    })),

                    body: data
                      ? data.GetAcdStudents.edges.map((edge, index) => ({
                          SlNo: index + 1,
                          AdmNo: edge.node.std_adm_no,
                          RegNo: edge.node.std_reg_no,
                          Name:
                            edge.node.first_name +
                            " " +
                            edge.node.middle_name +
                            " " +
                            edge.node.last_name,
                          Father: edge.node.std_father_name,
                          Email: edge.node.std_email,
                          Mobile: edge.node.std_mobile,
                          Dept: edge.node.acd_dept.dept_desc,
                          Branch: edge.node.acd_branch.branch_desc,
                          Class: edge.node.acd_class.class_desc,
                          Sem: edge.node.acd_semester.sem_desc,
                          Section: edge.node.acd_section.section_desc,
                          Category: edge.node.category.cat_desc,
                          Status: edge.node.misc_data.mobile_app_count,
                        }))
                      : [],
                    showFoot: "lastPage",
                    showHead: "everyPage",
                    useCss: true,
                    didDrawPage: function (data) {
                      // Footer
                      let str =
                        "" +
                        doc.getCurrentPageInfo().pageNumber +
                        "of" +
                        doc.getNumberOfPages();
                      doc.setFontSize(10);

                      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                      let pageSize = doc.internal.pageSize;
                      let pageHeight = pageSize.height
                        ? pageSize.height
                        : pageSize.getHeight();
                      doc.text(str, data.settings.margin.left, pageHeight - 10);
                    },
                  });

                  doc.save(
                    `${InstDetails?.data?.nodes[0]?.inst_name} ${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    } `
                  );
                });
              });
          });
        });
      setPdfFlag(false);
      setRowsPerPage(ROWS_PER_PAGE);
    }
  };

  // const downloadExcel = () => {
  //   if (data) {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet(NEW_SHEET);

  //     const studentsData: studentsType[] = data
  //       ? data.GetAcdStudents.edges.map((edge, index) => {
  //           let student = {};
  //           studentPersonalSwConfigData
  //             .filter((ele) => ele.enabled)
  //             .forEach((element) => {
  //               const node = edge.node as unknown as Record<
  //                 string,
  //                 string | boolean | number
  //               >;
  //               const nodeStdDetails = edge.node
  //                 .std_details as unknown as Record<
  //                 string,
  //                 string | boolean | number
  //               >;
  //               const nodeOtherDetails = edge.node
  //                 .std_other_details as unknown as Record<
  //                 string,
  //                 string | boolean | number
  //               >;
  //               student = {
  //                 ...student,
  //                 std_name: `${edge.node.first_name} ${edge.node.middle_name} ${edge.node.last_name}`,
  //               };

  //               if (element.fieldName === "dept") {
  //                 student = {
  //                   ...student,
  //                   dept: edge.node.dept.dept_desc,
  //                 };
  //               } else if (element.fieldName === "branch") {
  //                 student = {
  //                   ...student,
  //                   branch: edge.node.branch.branch_desc,
  //                 };
  //               } else if (element.fieldName === "class") {
  //                 student = {
  //                   ...student,
  //                   class: edge.node.class.class_desc,
  //                 };
  //               } else if (element.fieldName === "sem") {
  //                 student = {
  //                   ...student,
  //                   sem: edge.node.semester.sem_desc,
  //                 };
  //               } else if (element.fieldName === "section") {
  //                 student = {
  //                   ...student,
  //                   section: edge.node.section.section_desc,
  //                 };
  //               } else if (element.fieldName === "category") {
  //                 student = {
  //                   ...student,
  //                   category: edge.node.category.cat_desc,
  //                 };
  //               } else if (
  //                 node[`${element.fieldName}`] ||
  //                 node[`${element.fieldName}`] === "" ||
  //                 node[`${element.fieldName}`] === false ||
  //                 node[`${element.fieldName}`] === 0
  //               ) {
  //                 student = {
  //                   ...student,
  //                   [`${element.fieldName}`]: node[`${element.fieldName}`],
  //                 };
  //               } else if (
  //                 nodeStdDetails[`${element.fieldName}`] ||
  //                 nodeStdDetails[`${element.fieldName}`] === "" ||
  //                 nodeStdDetails[`${element.fieldName}`] === false ||
  //                 nodeStdDetails[`${element.fieldName}`] === 0
  //               ) {
  //                 student = {
  //                   ...student,
  //                   [`${element.fieldName}`]:
  //                     nodeStdDetails[`${element.fieldName}`],
  //                 };
  //               } else if (
  //                 nodeOtherDetails[`${element.fieldName}`] ||
  //                 nodeOtherDetails[`${element.fieldName}`] === "" ||
  //                 nodeOtherDetails[`${element.fieldName}`] === false ||
  //                 nodeOtherDetails[`${element.fieldName}`] === 0
  //               ) {
  //                 student = {
  //                   ...student,
  //                   [`${element.fieldName}`]:
  //                     nodeOtherDetails[`${element.fieldName}`],
  //                 };
  //               }
  //             });
  //           return student as studentsType;
  //         })
  //       : [];

  //     const dataRows = studentsData
  //       ? studentsData.map((item, index) => {
  //           const row: (string | undefined)[] = [
  //             `${index + 1}`,
  //             `${item.std_adm_no}`,
  //             `${item.std_reg_no}`,
  //             `${item.std_name}`,
  //             `${item.std_father_name}`,
  //             `${item.std_father_mobile}`,
  //             `${item.std_mother_name}`,
  //             `${item.std_mother_mobile}`,
  //             `${item.std_mobile}`,
  //             `${item.std_email}`,
  //             `${item.std_dob ? toStandardDate(item.std_dob) : undefined}`,
  //             `${item.std_sex}`,
  //             `${
  //               item.std_fresher !== undefined
  //                 ? item.std_fresher
  //                   ? "Fresher"
  //                   : "Existing"
  //                 : undefined
  //             }`,
  //             `${item.dept}`,
  //             `${item.branch}`,
  //             `${item.class}`,
  //             `${item.sem}`,
  //             `${item.section}`,
  //             `${item.category}`,
  //             `${item.std_doa ? toStandardDate(item.std_doa) : undefined}`,
  //             `${item.std_aadhar}`,
  //             `${item.std_sts_no}`,
  //             `${item.std_blood_group}`,
  //             `${item.std_address}`,
  //             `${item.std_place}`,
  //             `${item.std_district}`,
  //             `${item.std_state}`,
  //             `${item.std_pincode}`,
  //             `${item.std_phone}`,
  //             `${item.std_corr_address}`,
  //             `${item.std_corr_place}`,
  //             `${item.std_corr_district}`,
  //             `${item.std_corr_state}`,
  //             `${item.std_corr_pincode}`,
  //             `${item.std_corr_phone}`,
  //             `${item.std_religion}`,
  //             `${item.std_caste}`,
  //             `${item.std_sub_caste}`,
  //             `${item.std_caste_group}`,
  //             `${item.std_sc_st}`,
  //             `${
  //               item.std_caste_minority !== undefined
  //                 ? item.std_caste_minority
  //                   ? "Minority"
  //                   : "Non Minority"
  //                 : undefined
  //             }`,
  //             `${item.std_rural_urban}`,
  //             `${item.std_mother_tongue}`,
  //             `${item.std_annual_income}`,
  //             `${item.std_identity_marks}`,
  //             `${item.std_place_of_birth}`,
  //             `${item.std_nationality}`,
  //             `${item.std_is_phy_challenged}`,
  //             `${item.std_prev_tc_details}`,
  //             `${item.std_prev_inst}`,
  //             `${item.std_prev_quali_exam}`,
  //             `${item.std_prev_studied_board}`,
  //             `${item.std_prev_studied_state}`,
  //             `${item.std_prev_subject_studied}`,

  //             `${item.std_prev_total_marks}`,
  //             `${item.std_prev_total_per}`,
  //           ].filter((ele) => ele !== undefined && ele !== "undefined");

  //           return row;
  //         })
  //       : [];

  //     worksheet.views = FROZEN_CELLS;
  //     const headerStyle = getHeaderRowStyle();
  //     worksheet.getRow(1).height = 35;
  //     worksheet.getRow(2).height = 20;
  //     worksheet.getRow(3).height = 20;
  //     worksheet.getRow(4).height = 23;
  //     worksheet.getColumn(1).width = 8;
  //     worksheet.getColumn(2).width = 16;
  //     worksheet.getColumn(3).width = 16;
  //     worksheet.getColumn(4).width = 30;
  //     worksheet.getColumn(5).width = 35;
  //     worksheet.getColumn(6).width = 25;
  //     worksheet.getColumn(7).width = 25;
  //     worksheet.getColumn(8).width = 25;
  //     worksheet.getColumn(9).width = 25;
  //     worksheet.getColumn(10).width = 25;
  //     worksheet.getColumn(11).width = 25;
  //     worksheet.getColumn(12).width = 25;
  //     worksheet.getColumn(13).width = 25;
  //     worksheet.getColumn(14).width = 25;
  //     worksheet.getColumn(15).width = 25;
  //     worksheet.getColumn(16).width = 25;
  //     worksheet.getColumn(17).width = 25;
  //     worksheet.getColumn(18).width = 25;
  //     worksheet.getColumn(19).width = 25;
  //     worksheet.getColumn(20).width = 25;
  //     worksheet.getColumn(21).width = 25;
  //     worksheet.getColumn(22).width = 25;
  //     worksheet.getColumn(23).width = 25;
  //     worksheet.getColumn(24).width = 25;
  //     worksheet.getColumn(25).width = 25;
  //     worksheet.getColumn(26).width = 25;
  //     worksheet.getColumn(27).width = 25;
  //     worksheet.getColumn(28).width = 25;
  //     worksheet.getColumn(29).width = 25;
  //     worksheet.getColumn(30).width = 25;

  //     worksheet.getColumn(31).width = 25;
  //     worksheet.getColumn(32).width = 25;
  //     worksheet.getColumn(33).width = 25;
  //     worksheet.getColumn(34).width = 25;
  //     worksheet.getColumn(35).width = 25;
  //     worksheet.getColumn(36).width = 25;
  //     worksheet.getColumn(37).width = 25;
  //     worksheet.getColumn(38).width = 25;
  //     worksheet.getColumn(39).width = 25;
  //     worksheet.getColumn(40).width = 25;

  //     worksheet.getColumn(41).width = 25;
  //     worksheet.getColumn(42).width = 25;
  //     worksheet.getColumn(43).width = 25;
  //     worksheet.getColumn(44).width = 25;
  //     worksheet.getColumn(45).width = 25;
  //     worksheet.getColumn(46).width = 25;
  //     worksheet.getColumn(47).width = 25;
  //     worksheet.getColumn(48).width = 25;
  //     worksheet.getColumn(49).width = 25;
  //     worksheet.getColumn(50).width = 25;

  //     worksheet.getColumn(51).width = 25;
  //     worksheet.getColumn(52).width = 25;
  //     worksheet.getColumn(53).width = 25;
  //     worksheet.getColumn(54).width = 25;
  //     worksheet.getColumn(55).width = 25;
  //     worksheet.getColumn(56).width = 25;

  //     const getBase64 = (blob: Blob, cb: (a: string) => void) => {
  //       const file = new File([blob], FILENAME, {
  //         lastModified: Date.now(),
  //         type: blob.type,
  //       });
  //       let reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => {
  //         cb(reader.result?.toString()!);
  //       };
  //     };
  //     fetch(Eduate)
  //       .then((response) => {
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         getBase64(blob, (result) => {
  //           const imageV = workbook.addImage({
  //             base64: result,
  //             extension: EDUATE_IMG_FORMAT,
  //           });

  //           worksheet.addImage(imageV, "K1:K3");

  //           worksheet.mergeCells(1, 1, 1, 11);

  //           const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
  //           mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
  //           mergedCell.fill = HEADER_CSS;
  //           mergedCell.font = headerStyle[0].font;
  //           mergedCell.alignment = HEADER_ALIGNMENT;

  //           const mergedAddress: Cell = worksheet.getCell(A2_CELL);
  //           mergedAddress.value = InstDetails?.data?.nodes[0]?.inst_address;
  //           mergedAddress.fill = HEADER_CSS;
  //           mergedAddress.font = ADDRESS_FONT;
  //           mergedAddress.alignment = HEADER_ALIGNMENT;
  //           worksheet.mergeCells("A2:K2");

  //           const mergedPlace: Cell = worksheet.getCell(A3_CELL);
  //           mergedPlace.value =
  //             InstDetails?.data?.nodes[0]?.inst_place +
  //             "-" +
  //             InstDetails?.data?.nodes[0]?.inst_pin;

  //           mergedPlace.fill = HEADER_CSS;
  //           mergedPlace.font = ADDRESS_FONT;
  //           mergedPlace.alignment = ADDRESS_ALIGNMENT;
  //           worksheet.mergeCells("A3:K3");

  //           const mergedHeader: Cell = worksheet.getCell(D4_CELL);
  //           mergedHeader.value = ExcelPageHeader.STUDENT_LIST;
  //           mergedHeader.fill = FILE_NAME_CSS;
  //           mergedHeader.font = FILE_NAME_FONT;
  //           mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
  //           worksheet.mergeCells("D4:F4");
  //           const mergedDate: Cell = worksheet.getCell("G4");
  //           mergedDate.value =
  //             ExcelPageHeader.DATE + toStandardDate(serverDate);
  //           mergedDate.fill = FILE_NAME_CSS;
  //           mergedDate.font = FIN_YEAR_FONT;
  //           mergedDate.alignment = HEADER_ALIGNMENT_RIGHT;
  //           worksheet.mergeCells("G4:K4");
  //           const mergedYear: Cell = worksheet.getCell(A4_CELL);
  //           mergedYear.value = state.ActiveFinYr
  //             ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
  //             : ExcelPageHeader.YEAR;
  //           mergedYear.fill = FILE_NAME_CSS;
  //           mergedYear.font = FIN_YEAR_FONT;
  //           mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
  //           worksheet.mergeCells("A4:C4");

  //           let chars: string[] = [];

  //           // Add "A" to "Z"
  //           for (let i = 0; i < 26; i++) {
  //             chars.push(String.fromCharCode(65 + i));
  //           }

  //           // Add "AA" to "AZ"
  //           for (let i = 0; i < 26; i++) {
  //             for (let j = 0; j < 26; j++) {
  //               chars.push(
  //                 String.fromCharCode(65 + i) + String.fromCharCode(65 + j)
  //               );
  //             }
  //           }

  //           // Add "BA" to "BZ"
  //           for (let i = 0; i < 26; i++) {
  //             for (let j = 0; j < 26; j++) {
  //               chars.push(
  //                 String.fromCharCode(66 + i) + String.fromCharCode(65 + j)
  //               );
  //             }
  //           }

  //           for (let i = 0; i < headerRow.length; i++) {
  //             const rowData: Cell = worksheet.getCell(chars[i] + 5);
  //             rowData.value = headerRow[i];
  //             rowData.fill = TABLE_HEADER_CSS;
  //             rowData.border = BORDER_DATA;
  //             rowData.font = ACC_HEADER_FONT;
  //             rowData.alignment = { horizontal: ExcelAlignment.CENTER };
  //           }

  //           dataRows!.forEach((rowData) => {
  //             const row = worksheet.addRow(rowData);
  //             row.eachCell({ includeEmpty: true }, (cell) => {
  //               cell.alignment = { horizontal: ExcelAlignment.CENTER };
  //               cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
  //               row.getCell(4).font = {
  //                 name: ExcelFont.CENTURY_GOTHIC,
  //                 size: 9,
  //               };
  //               row.getCell(5).font = {
  //                 name: ExcelFont.CENTURY_GOTHIC,
  //                 size: 9,
  //               };
  //             });
  //           });
  //           workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
  //             const blob = new Blob([buffer], {
  //               type: BLOB_TYPE,
  //             });
  //             const url = window.URL.createObjectURL(blob);
  //             const link = document.createElement(ELEMENT);
  //             link.href = url;
  //             link.setAttribute(DOWNLOAD, ExcelSheetsNames.STUDENT_LIST);
  //             document.body.appendChild(link);
  //             link.click();
  //           });
  //           // setExcelFlag(false);
  //           setRowsPerPage(ROWS_PER_PAGE);
  //         });
  //       });
  //   }
  // };

  useEffect(() => {
    if (pdfFlag && rowsPerPage === null && !loading) downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading]);

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: "No Of Devices Installed",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`std-device-details__tableblock--cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status >= 1 ? (
              <>
                {params.row.status} <img src={MobileDevice} alt="/" />
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,

      is_login_created: false,
      std_roll_no: false,
      dept: false,
      branch: false,
      class: false,
      sem: false,
      sec: false,
      category: false,
    });
  useEffect(() => {
    if (data && !loading && excelFlag === false && pdfFlag === false) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.misc_data.mobile_app_count,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_roll_no: node.std_roll_no,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(DEFAULT_TIME),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,

            std_type: node.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.misc_data.mobile_app_count,
            std_id: node.id,
            std_roll_no: node.std_roll_no,

            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(DEFAULT_TIME),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,

            std_type: node.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading, data, statusSelected]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        // eslint-disable-next-line
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.DEVICE_DETAILS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_ADMISSIONS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <Home DashBoardRequired={false} />
      {label !== PageLabel.FALSE && (
        <Title>
          {queryType === StudentReportType.NON_ELIGIBLE ? (
            "Not Eligible Students"
          ) : (
            <>Students Device Details</>
          )}
        </Title>
      )}
      <div className="std-device-details">
        <form className="std-device-details__select row g-0">
          <div className="col-1">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType) ;
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && branchSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType) ;
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }

                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType) ;
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType) ;
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType) ;
                  } else {
                    setSectionSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType) ;
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={Object.keys(DeviceType)}
              openOnFocus
              value={statusSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatusSelected(newValue as DeviceType) ;
                } else {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
          <div className="col"></div>

          <div className="col-2 flex-end">
            <>
              <Button
                mode="clear"
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  clear();
                }}
                className="student-clear-button"
              />
            </>
          </div>
        </form>

        <div className={`std-device-details__tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            onCellClick={(params) => {
              if (params.field === "std_name") {
                if (studentListFor === StudentListFor.GENERAL) {
                  setDeviceNo(params.row.status);
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: {
                      studentId: params.row.std_id,
                    },
                  });
                  setMessageModal(!messageModal);
                }
              }
            }}
            disableRowSelectionOnClick
            hideFooter
            
          />
        </div>

        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setPdfFlag(true);
              }}
              disabled={classSelected && classSelected.value ? false : true}
              mode="pdf"
            />
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setExcelFlag(true);
              }}
              disabled={classSelected && classSelected.value ? false : true}
              mode="export"
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-3 flex-end">
            <StudentTotalCount
              totalCount={data ? data.GetAcdStudents.totalCount! : 0}
            />
          </div>
        </div>

        {/* MessageModal */}

        <AllocateTeachersDrawer
 
          anchor="right"
          open={messageModal}
          onClose={() => setMessageModal(!messageModal)}
        >
          <MobileDeviceModal
            setMessageModal={setMessageModal}
            deviceNo={deviceNo}
          />
        </AllocateTeachersDrawer>
        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />

        <Modal
          ariaHideApp={false}
          style={StudentModalStyles}
          isOpen={excelFlag}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <StudentFieldsForExport
                setStudentPersonalSwConfigData={setStudentPersonalSwConfigData}
                studentPersonalSwConfigData={studentPersonalSwConfigData}
                downloadExcel={() => {}}
              />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={() => setExcelFlag?.(!excelFlag)}
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default StudentsDeviceList;

import React, { useContext, useEffect, useRef, useState } from "react";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../images/CalenderNextButton.svg";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import { Tooltip } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdAttendanceMonthlyByStdIdData,
  GetAcdAttendanceMonthlyByStdIdVars,
} from "../../../Academics/types/holidays";
import {
  GetAcdStdAttSubjMonthlyForCal,
  GetAcdStudentAttendanceByStdId,
} from "../../../Academics/queries/holidays/list";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import View from "../../../../images/EyeWhite.svg";
import {
  DateRange,
  GetnumberOfDaysInMonth,
  MonthName,
  handleDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { DEFAULT_TIME, EMPTY_STRING, days } from "../../../../utils/constants";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../../Academics/hooks/useAcdGeneralHolidays";
import {
  AttendanceStatus,
  HolidayType,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
} from "../../../../utils/Enum.types";
import { monthDaysType } from "../../../Academics/DailyActivities/Attendance/MonthlyOverview";
import { INCREMENT_DECREMENT } from "../../../Enquiry/Dashboard/Index";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";

import CompleteAttendanceDetails from "./Academics/CompleteAttendanceDetails";
import dayjs from "dayjs";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useCheckAllocationType from "../../../Academics/hooks/useCheckAllocationType";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
interface props {
  pageType: PageFor;
}
const Attendance = ({ pageType }: props) => {
  const [fullView, setFullView] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [monthDays, setMonthDays] = useState<monthDaysType[]>();
  const [monthDaysForSession, setMonthDaysForSession] =
    useState<monthDaysType[]>();

  var generalHolidaysMap = useRef(new Map());

  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [date, setDate] = useState(new Date());
  const [errorAppearedFromApi, setErrorAppearedFromApi] = useState("");
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const { firstDay, lastDay } = DateRange(date.toString()) || {};

  const [GetStudentHolidayDetails, { data }] = useLazyQuery<
    GetAcdAttendanceMonthlyByStdIdData,
    GetAcdAttendanceMonthlyByStdIdVars
  >(GetAcdStudentAttendanceByStdId, {
    onError: (e) => {
      setErrorAppearedFromApi(e.message);
    },
  });
  const [GetData, { data: attData }] = useLazyQuery(
    GetAcdStdAttSubjMonthlyForCal
  );

  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();

  const emptyDaysByWeek = (week: string) => {
    let numberOfEmpty = -1;
    let numberOfEmptyToLast = -1;
    switch (week) {
      case "Sun":
        numberOfEmpty = 6;
        numberOfEmptyToLast = 0;
        break;
      case "Mon":
        numberOfEmpty = 0;
        numberOfEmptyToLast = 6;
        break;
      case "Tue":
        numberOfEmpty = 1;
        numberOfEmptyToLast = 5;

        break;
      case "Wed":
        numberOfEmpty = 2;
        numberOfEmptyToLast = 4;
        break;
      case "Thu":
        numberOfEmpty = 3;
        numberOfEmptyToLast = 3;
        break;
      case "Fri":
        numberOfEmpty = 4;
        numberOfEmptyToLast = 2;
        break;
      case "Sat":
        numberOfEmpty = 5;
        numberOfEmptyToLast = 1;
        break;
      default:
        break;
    }
    return { numberOfEmpty, numberOfEmptyToLast };
  };

  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    holiday_query_type.ALL_HOLIDAYS
  );

  const { studentEntryId } = useStudentDatabyId();

  const { flag } = useCheckAllocationType(studentEntryId);

  const chooseTypeAndColor = (status: string, day: number) => {
    if (status === AttendanceStatus.P) {
      return {
        type: AttendanceStatus.P,
        description: "",
        class_name: "cal-bg-green",
      };
    } else if (status === AttendanceStatus.A) {
      return {
        type: AttendanceStatus.A,
        description: "",
        class_name: "cal-bg-red",
      };
    }

    if (status === AttendanceStatus.P) {
      return {
        type: AttendanceStatus.P,
        description: "",
        class_name: "cal-bg-green",
      };
    } else if (status === AttendanceStatus.A) {
      return {
        type: AttendanceStatus.A,
        description: "",
        class_name: "cal-bg-red",
      };
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
      };
    }
  };
  const chooseTypeAndColorForSession = (statusArray: string[], day: number) => {
    const areAllPresent =
      statusArray.length > 0 &&
      statusArray.every((value) => value === AttendanceStatus.P);

    const areAllAbsent =
      statusArray.length > 0 &&
      statusArray.every((value) => value === AttendanceStatus.A);
    const containsBothAP =
      statusArray.includes(AttendanceStatus.A) &&
      statusArray.includes(AttendanceStatus.P);

    if (areAllPresent) {
      return {
        type: AttendanceStatus.P,
        description: "",
        class_name: "cal-bg-green",
      };
    } else if (areAllAbsent) {
      return {
        type: AttendanceStatus.A,
        description: "",
        class_name: "cal-bg-red",
      };
    } else if (containsBothAP) {
      return {
        type: "",
        description: "",
        class_name: "cal-bg-amber",
      };
    } else {
      return {
        type: "",
        description: "",
        class_name: "",
      };
    }
  };
  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };
  const weekCountForSession = () => {
    if (monthDaysForSession && monthDaysForSession.length > 0) {
      const firstWeek = monthDaysForSession[0].week;

      const lastWeek =
        monthDaysForSession[monthDaysForSession.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
          attendance: [],
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
          attendance: [],
        }));

      return [...startArray, ...monthDaysForSession, ...endArray];
    } else {
      return [];
    }
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      state.studentId &&
      firstDay &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      enablePerSessionAtt === false
    ) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetStudentHolidayDetails({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          cal_month: toIsoDate(firstDay!),
          inst_id: String(state.InstId)!,
          student_id: state.studentId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          const arrLength = GetnumberOfDaysInMonth(firstDay);
          const res = Array.from({ length: arrLength }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);
            const holiday_details = chooseTypeAndColor(
              (data.GetAcdStudentAttendanceByStdId as Record<string, string>)[
                dayKey
              ],
              dayNum
            );

            return {
              day: dayNum,
              status: holiday_details?.type,
              description: holiday_details?.description,
              week: date.toLocaleString("en-US", { weekday: "short" }),
              class_name: holiday_details?.class_name!,
              date,
              holiday_types: [],
              events_length: 0,
            };
          });
          setMonthDays(res);
          setErrorAppearedFromApi("");
        }
      });
    }
    // eslint-disable-next-line
  }, [
    state.studentId,
    firstDay,
    state.InstId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStudentHolidayDetails,
    InstGeneralHolidays.data,
    data,
    date,
    enablePerSessionAtt,
    state.ActiveAcdYr,
  ]);
  useEffect(() => {
    if (serverDate) {
      setDate(new Date(serverDate));
    }
  }, [serverDate]);
  useEffect(() => {
    if (
      state.studentId &&
      firstDay &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      enablePerSessionAtt
    ) {
      GetData({
        variables: {
          token,
          inst_id: state.InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(firstDay),
          student_id: state.studentId,
          per_std_subj_allocation: flag ? flag : false,
        },
      }).then(({ data }) => {
        if (data) {
          const arrLength = GetnumberOfDaysInMonth(firstDay);
          const res = Array.from({ length: arrLength }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);

            const statusValue = (
              data.GetAcdStdAttSubjMonthlyForCal as Record<
                string,
                string | string[]
              >
            )[dayKey];

            const statusArray = Array.isArray(statusValue)
              ? statusValue
              : statusValue?.split(";") || [];

            const filteredStatusArray = statusArray.filter(
              (value) => value !== ""
            );

            const holiday_details = chooseTypeAndColorForSession(
              filteredStatusArray,
              dayNum
            );
            return {
              day: dayNum,
              status: holiday_details?.type,
              description: holiday_details?.description,
              week: date.toLocaleString("en-US", { weekday: "short" }),
              class_name: holiday_details?.class_name!,
              date,
              holiday_types: [],
              events_length: 0,
              attendance: statusArray,
            };
          });
          setMonthDaysForSession(res);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.studentId,
    firstDay,
    state.InstId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetData,
    InstGeneralHolidays.data,
    attData,
    date,
    state.ActiveAcdYr,
    enablePerSessionAtt,
    flag,
  ]);
  return (
    <>
      <div className="student-dashboard__attendance">
        <div className="student-dashboard__attendance--month">
          <img
            src={Previous}
            alt="/"
            onClick={() =>
              handleDate(
                date,
                setDate,
                activeAcademicYearData.data?.GetAcdYrActiveByInstId
                  .acd_st_date!,
                activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                  .acd_end_date!,
                setMessage,
                INCREMENT_DECREMENT.DECREMENT
              )
            }
          />
          <Title variant="subtitle1">{MonthName(date.toString())}</Title>
          <img
            src={Next}
            alt="/"
            onClick={() => {
              const nextDate = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate()
              );
              const serverDateDayjs = dayjs(serverDate);
              const selectedDateDayjs = dayjs(nextDate);
              if (
                serverDateDayjs.isAfter(selectedDateDayjs) &&
                !serverDateDayjs.isSame(selectedDateDayjs)
              ) {
                handleDate(
                  date,
                  setDate,
                  activeAcademicYearData.data?.GetAcdYrActiveByInstId
                    .acd_st_date!,
                  activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                    .acd_end_date!,
                  setMessage,
                  INCREMENT_DECREMENT.INCREMENT
                );
              } else {
                setMessage({
                  flag: true,
                  operation: Operation.NONE,
                  message: "Cannot Access Future Dates",
                });
              }
            }}
          />
        </div>
        {errorAppearedFromApi ? (
          <div className="flex_error">
            <b className="nodata">{errorAppearedFromApi}</b>
          </div>
        ) : (
          <>
            <ul className="student-dashboard__attendance--grid-container">
              {days.map((day, index: React.Key) => {
                return (
                  <li
                    key={index}
                    className="enquiry-dashboard__data--calendar--grid-container--day"
                  >
                    {day}
                  </li>
                );
              })}
              {enablePerSessionAtt
                ? weekCountForSession().map(
                    (
                      {
                        day,
                        class_name,
                        description,
                        date: monthDate,
                        status,
                        attendance,
                      },
                      index
                    ) => {
                      return day > 0 ? (
                        status === HolidayType.GENERAL ||
                        status === HolidayType.UNEXPECTED ||
                        status === HolidayType.VACATION ? (
                          <Tooltip
                            title={<>{description}</>}
                            arrow
                            placement="right"
                            key={index}
                          >
                            <li
                              className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                              key={index}
                            >
                              <span>{day > 0 ? day : ""}</span>
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                            onClick={() => {
                              dayjs(monthDate!).isAfter(serverDate)
                                ? setMessage({
                                    flag: true,
                                    message: "Cannot Access Future Dates",
                                    operation: Operation.NONE,
                                  })
                                : setFullView(!fullView);
                            }}
                            key={index}
                          >
                            <span>{day > 0 ? day : ""}</span>
                            <div className="student-dashboard__attendance--full-view--sessions--status">
                              {attendance &&
                                attendance
                                  .filter((data) => data !== EMPTY_STRING)
                                  .map((status: string, index: number) => (
                                    <span
                                      key={index}
                                      className={`student-dashboard__attendance--full-view--dot ${
                                        status === "P"
                                          ? "student-dashboard__attendance--full-view--present"
                                          : "student-dashboard__attendance--full-view--absent"
                                      }`}
                                    ></span>
                                  ))}
                            </div>
                          </li>
                        )
                      ) : (
                        <li key={index}></li>
                      );
                    }
                  )
                : weekCount().map(
                    (
                      { day, class_name, description, date: monthDate, status },
                      index
                    ) => {
                      return day > 0 ? (
                        status === HolidayType.GENERAL ||
                        status === HolidayType.UNEXPECTED ||
                        status === HolidayType.VACATION ? (
                          <Tooltip
                            title={<>{description}</>}
                            arrow
                            placement="right"
                            key={index}
                          >
                            <li
                              className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                              key={index}
                            >
                              <span>{day > 0 ? day : ""}</span>
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                            onClick={() => {
                              if (dayjs(monthDate!).isAfter(serverDate)) {
                                setMessage({
                                  flag: true,
                                  message: "Cannot Access Future Dates",
                                  operation: Operation.NONE,
                                });
                              } else {
                                if (pageType === PageFor.GENERAL) {
                                  setFullView(!fullView);
                                }
                              }
                            }}
                            key={index}
                          >
                            <span>{day > 0 ? day : ""}</span>
                          </li>
                        )
                      ) : (
                        <li key={index}></li>
                      );
                    }
                  )}
            </ul>
          </>
        )}
        <button
          className="student-dashboard__attendance--view"
          onClick={() => setFullView(!fullView)}
        >
          <img src={View} alt="" />
          Detailed View
        </button>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={fullView}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CompleteAttendanceDetails
              setModalFlag={setFullView}
              pageType={PageFor.GENERAL}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setFullView(!fullView)} />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default Attendance;

import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { GetDepositDetailsByNodeId } from "../queries/FeeLedgers/query/Byid";
import { DepositDetailNode } from "./useStudentDepositDetails";
import { singleNodeVars } from "../../../Types/Accounting";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";

const useDepositDetailsById = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [GetDepositDetail, { data }] = useLazyQuery<
    { node: DepositDetailNode },
    singleNodeVars
  >(GetDepositDetailsByNodeId, {
    variables: {
      id: state.depositId,
      token,
    },
  });

  useEffect(() => {
    if (token && state.depositId) {
      GetDepositDetail();
    }
  }, [token, GetDepositDetail, state.depositId]);
  return { data };
};

export default useDepositDetailsById;

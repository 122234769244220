import React, { useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../styles/Tabs";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import TreeView from "./TreeView";
import List, { ComponentForEmployee } from "../Employee/List/Index";
import { PageFor } from "../../../utils/Enum.types";
const Index = () => {
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index: number) => setValue(index);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Employee Tree</Title>
      <div className="employee-tree">
        <div className="employee-tree__tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Employee Tree" value={0} {...a11yProps(0)} />
            <Tab label="Employees" value={1} {...a11yProps(1)} />
          </Tabs>
        </div>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          className="bulk-data-alter__swipable"
        >
          {/* values for Attendance */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <TreeView />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <List
              pageType={PageFor.GENERAL}
              type={ComponentForEmployee.ORGANIZATIONAL}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
    </>
  );
};

export default Index;

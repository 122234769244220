import axios from "axios";
import fileDownload from "js-file-download";

export const handleUploadAndDownloadFile = (
  image: File,
  filename: string,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
  downloadFile: boolean
) => {
  const image_name = filename.split("/").pop();
  return new Promise((resolve, reject) => {
    const headers = {
      "Content-Type":
        "application/x-www-form-urlencoded; charset=UTF-8;application/json",
    };
    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        setProgress(percentage);

        if (percentage < 100) {
          setProgress(percentage);
        }
      },
    };

    const apiBaseUrl = `https://ix2f1y2hpk.execute-api.ap-south-1.amazonaws.com/dev/UploadFilesToS3?file_name=${filename}&access_type=${process.env.React_App_access_type}`;
    let formData = new FormData();
    if (image && image_name) formData.append(image_name, image);
    let completed = false;

    axios
      .post(apiBaseUrl, null, {
        headers,
      })
      .then((res) => {
        const formData = new FormData();

        Object.keys(res.data.fields).forEach((key) => {
          formData.append(key, res.data.fields[key]);
        });

        formData.append("file", image!);
        axios
          .post(res.data.url, formData, options)
          .then((d) => {
            if (d.status === 204) {
              const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${filename}&access_type=${process.env.React_App_access_type}`;
              axios
                .post(downloadBaseUrl, null, {
                  headers,
                })
                .then((response) => {
                  axios
                    .get(response.data, { responseType: "blob" })
                    .then(() => {
                      if (downloadFile) {
                        fileDownload(response.data!, filename!);
                        completed = true;
                        resolve(completed);
                      } else {
                        completed = true;
                        resolve(completed);
                      }
                    })
                    .catch((err) => reject(err));
                })
                .catch((err) => reject(err));
              resolve(true);
            }
          })
          .catch((err) => {
            console.log(err, "Error");
            reject(err);
          });
      })
      .catch((err) => reject(err));
  });
};

//   const getPresignedPostData = (selectedFile: File) => {
//     return new Promise((resolve) => {
//       const xhr = new XMLHttpRequest();

//       // Set the proper URL here.
//       const url = apiBaseUrl;

//       xhr.open("POST", url, true);
//       xhr.setRequestHeader("Content-Type", "application/json");
//       xhr.send(
//         JSON.stringify({
//           name: selectedFile.name,
//           type: selectedFile.type,
//         })
//       );
//       xhr.onload = function () {
//         resolve(JSON.parse(this.responseText));
//       };
//     });
//   };

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import {
  BranchesListDataByDeptId,
  BranchListVarsByDeptId,
  ClassListDataByBranchId,
  ClassListVarsBranchId,
  SectionListDataBySemesterId,
  SectionListVarsBySemesterId,
  SemesterListDataByClassId,
  SemesterListVarsByClassId,
} from "../Types/Student";
import { ByDeptIDs as BranchesByDeptIds } from "../queries/institution/branch/list/byid";
import { ClassesByBranchIds } from "../queries/institution/classes/list/byid";

import { InstitutionDetails } from "../utils/Enum.types";
import { SemestersByClassIds } from "../queries/institution/sem/list/byid";
import { SectionsBySemIds } from "../queries/sections/list/byId";
import { isEqual } from "@react-spring/shared";
import useToken from "./useToken";
import { EMPTY_STRING } from "../utils/constants";

const useInstitutionDetailsDataByIds = (
  id: number[],
  type: InstitutionDetails
) => {
  const { token } = useToken();
  const emptyNumberArray = [0];
  const [
    GetBranchesByDept,
    {
      data: BranchesByDeptData,
      error: BranchesByDeptError,
      loading: BranchesByDeptLoading,
    },
  ] = useLazyQuery<BranchesListDataByDeptId, BranchListVarsByDeptId>(
    BranchesByDeptIds,
    {
      variables: {
        dept_ids: id,
        token,
      },
    }
  );
  const [
    GetClassesByBranch,
    {
      data: ClassesByDeptData,
      error: ClassesByDeptError,
      loading: ClassesByDeptLoading,
    },
  ] = useLazyQuery<ClassListDataByBranchId, ClassListVarsBranchId>(
    ClassesByBranchIds,
    {
      variables: {
        branch_ids: [Number(id)],
        token,
      },
    }
  );
  const [
    GetSemesterByClass,
    {
      data: SemesterByClassData,
      error: SemesterByClassError,
      loading: SemesterByClassLoading,
    },
  ] = useLazyQuery<SemesterListDataByClassId, SemesterListVarsByClassId>(
    SemestersByClassIds,
    {
      variables: {
        class_ids: [Number(id)],
        token,
      },
    }
  );
  const [
    GetSectionBySemester,
    {
      data: SectionBySemesterData,
      error: SectionBySemesterError,
      loading: SectionBySemesterLoading,
    },
  ] = useLazyQuery<SectionListDataBySemesterId, SectionListVarsBySemesterId>(
    SectionsBySemIds,
    {
      variables: {
        sem_ids: id,
        token,
      },
    }
  );
  useEffect(() => {
    switch (type) {
      case InstitutionDetails.BRANCH:
        if (!isEqual(id, emptyNumberArray)) {
          GetBranchesByDept();
        }
        break;
      case InstitutionDetails.CLASS:
        if (!isEqual(id, emptyNumberArray)) {
          GetClassesByBranch();
        }
        break;
      case InstitutionDetails.SEMESTER:
        if (!isEqual(id, emptyNumberArray)) {
          GetSemesterByClass();
        }
        break;
      case InstitutionDetails.SECTION:
        if (!isEqual(id, emptyNumberArray)) {
          GetSectionBySemester();
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [type, isEqual(id, emptyNumberArray)]);

  switch (type) {
    case InstitutionDetails.BRANCH:
      return {
        BranchByDept: {
          data:
            BranchesByDeptData &&
            BranchesByDeptData.GetBranchesByDeptIds?.filter(
              (branchResponse) => branchResponse?.id !== undefined
            ),
          error: BranchesByDeptError,
          loading: BranchesByDeptLoading,
          queryResult: BranchesByDeptData,
        },
      };
    case InstitutionDetails.CLASS:
      return {
        ClassByBranch: {
          data:
            ClassesByDeptData &&
            ClassesByDeptData.GetClassesByBranchIds?.filter(
              (classResponse) => classResponse?.id !== undefined
            ),
          error: ClassesByDeptError,
          loading: ClassesByDeptLoading,
          queryResult: ClassesByDeptData,
        },
      };
    case InstitutionDetails.SEMESTER:
      return {
        SemesterByClass: {
          data:
            SemesterByClassData &&
            SemesterByClassData.GetSemestersByClassIds?.filter(
              (semesterResponse) => semesterResponse?.id !== undefined
            ),
          error: SemesterByClassError,
          loading: SemesterByClassLoading,
          queryResult: SemesterByClassData,
        },
      };
    case InstitutionDetails.SECTION:
      return {
        SectionBySemester: {
          data:
            SectionBySemesterData &&
            SectionBySemesterData.GetSectionsBySemesterIds?.filter(
              (sectionResponse) => sectionResponse?.id !== undefined
            ),
          error: SectionBySemesterError,
          loading: SectionBySemesterLoading,
          queryResult: SectionBySemesterData,
        },
      };

    default:
      break;
  }
  return EMPTY_STRING;
};

export default useInstitutionDetailsDataByIds;

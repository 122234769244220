import { gql } from "@apollo/client";
export const AddDocument = gql`
  mutation AddDocument(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $document_name: String!
  ) {
    AddDocument(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      document_name: $document_name
    ) {
      document_name
    }
  }
`;
export const DeleteDocument = gql`
  mutation DeleteDocument(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $document_id: ID!
  ) {
    DeleteDocument(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      document_id: $document_id
    )
  }
`;

export const UpdateDocumentName = gql`
  mutation UpdateDocumentName(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $document_id: ID!
    $document_name: String!
  ) {
    UpdateDocumentName(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      document_id: $document_id
      document_name: $document_name
    ) {
      id
    }
  }
`;
export const UpdateDocumentContent = gql`
  mutation UpdateDocumentContent(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $document_id: ID!
    $document_content: String!
  ) {
    UpdateDocumentContent(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      document_id: $document_id
      document_content: $document_content
    ) {
      id
      document_name
      document_content
    }
  }
`;

export const UpdateDocumentConfig = gql`
  mutation UpdateDocumentConfig(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: [UpdateDocumentConfigData!]!
  ) {
    UpdateDocumentConfig(
      token: $token
      user_details: $user_details
      inst_id: $inst_id
      input: $input
    )
  }
`;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../../Types/Tables";
import EmployeeDetails from "./EmployeeDetails";
const { HR_Table } = require("../../../../../json/table.json");

const LeaveDetails = () => {
  return (
    <>
      <Title>Employee Leave Details</Title>
      <EmployeeDetails />
      <TableContainer className="salary-process__emp-attendance--leave-ledger--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {HR_Table.SalaryProcess.EmpAttendance.LeaveLedger.map(
                (th: TableHeaderProps, index: React.Key) => {
                  return (
                    <TableCell key={index} className={th.className}>
                      {th.labelName}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                1
              </TableCell>
              <TableCell>Earned Leave</TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno">
                5
              </TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno"></TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno"></TableCell>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                5
              </TableCell>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                5
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                1
              </TableCell>
              <TableCell>Casual Leave</TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno">
                5
              </TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno"></TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno"></TableCell>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                5
              </TableCell>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                5
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                1
              </TableCell>
              <TableCell> Leave without pay</TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno">
                5
              </TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno"></TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno"></TableCell>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                5
              </TableCell>
              <TableCell
                className="salary-process__emp-attendance--leave-ledger--table--slno"
                id="td-center"
              >
                5
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} className="total">
                Total Leaves :
              </TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno balance-count">
                0
              </TableCell>
              <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno balance-count">
                0
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Button mode="print" />
    </>
  );
};

export default LeaveDetails;

import { gql } from "@apollo/client";

export const ListParentStudentAssocByParentId = gql`
  query ListParentStudentAssocByParentId($token: String!, $parent_id: ID!) {
    GetParentStudentAssociByParentId(token: $token, parent_id: $parent_id) {
      id
      student_id
      parent_id
      student {
        id
        first_name
        middle_name
        last_name
        std_status
        std_adm_no
        std_profile_filename
        dept {
          dept_desc
        }
        branch {
          branch_desc
        }
        class {
          class_desc
        }
        semester {
          sem_desc
        }
        section {
          section_desc
        }
      }
    }
  }
`;

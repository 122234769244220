import React from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import TotalStaff from "../../../../images/TotalStaff.svg";
import TotalPresent from "../../../../images/Present.svg";
import TotalAbsent from "../../../../images/Absent.svg";
import Percentage from "../../../../images/AttendanceOverallPercentage.svg";
import ReactEcharts from "echarts-for-react";
const StaffAttStatus = () => {
  const numbers = Array.from({ length: 30 }, (_, index) => index + 1);
  const option = {
    xAxis: {
      type: "category",
      data: [...numbers],
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    series: [
      {
        data: [
          100, 50, 310, 45, 48, 69, 485, 258, 148, 147, 256, 14, 25, 45, 68,
          258, 459, 65, 254, 57, 56, 563, 854, 56, 96, 54, 25, 65, 45, 68,
        ],
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: "#38bdf8",
          width: 1,
        },
        areaStyle: {
          color: "#e0f2fe",
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
      },
    ],
  };
  return (
    <>
      <div className="acd-admin-dashboard__card--title">
        <Title variant="subtitle1">Today's Staff Att. Status</Title>
        <img src={More} alt="" />
      </div>
      <div className="acd-admin-dashboard__staff">
        <div className="acd-admin-dashboard__card--details">
          <div>
            <img src={TotalStaff} alt="" />
            <span>Total Staff</span>
          </div>
          <b className="font-blue">0</b>
        </div>
        <div className="acd-admin-dashboard__card--details">
          <div>
            <img src={TotalPresent} alt="" />
            <span>Total Staff Present</span>
          </div>
          <b className="font-green">0</b>
        </div>
        <div className="acd-admin-dashboard__card--details">
          <div>
            <img src={TotalAbsent} alt="" />
            <span>Total Staff Absent</span>
          </div>
          <b className="font-red">0</b>
        </div>
        <div className="acd-admin-dashboard__card--details">
          <div>
            <img
              src={Percentage}
              alt=""
              className="acd-admin-dashboard__card--details--pers"
            />
            <span>Overall Percentage</span>
          </div>
          <b className="font-purple">0%</b>
        </div>
      </div>
      <div className="acd-admin-dashboard__staff--graph">
        <ReactEcharts option={option} />
      </div>
    </>
  );
};

export default StaffAttStatus;

import React from "react";
import Home from "../Home/Index";
import { LicenseTypes } from "../../../utils/Enum.types";
const Configuration = () => {
  return (
    <>
      <Home
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
        DashBoardRequired={false}
      />
      akshay
    </>
  );
};

export default Configuration;

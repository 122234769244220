import { AddPayRollDept } from "../../queries/department/mutation";
import { useMutation } from "@apollo/client";
import { PayRollMasters } from "../../enums/Enum.types";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AddPayRollCategory } from "../../queries/category/mutation";
import { AddPayRollJobType } from "../../queries/jobtype/mutations";
import { AddPayRollGrade } from "../../queries/grade/mutation";
import { AddPayRollDesignation } from "../../queries/designation/mutation";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const useMasterMutations = (MasterType: PayRollMasters, records: any[]) => {
  const { user_details } = useLoggedInUserDetails();
  const [AddDepts, { loading: deptCreationLoading }] =
    useMutation(AddPayRollDept);
  const [AddCategories, { loading: catCreationLoading }] =
    useMutation(AddPayRollCategory);
  const [AddDesignations, { loading: designationCreationLoading }] =
    useMutation(AddPayRollDesignation);
  const [AddJobTypes, { loading: jobTypeCreationLoading }] =
    useMutation(AddPayRollJobType);
  const [AddGrades, { loading: gradeCreationLoading }] =
    useMutation(AddPayRollGrade);

  const { token } = useToken();
  const { InstId } = useParams();

  const handleSubmit = async () => {
    switch (MasterType) {
      case PayRollMasters.DEPARTMENT:
        for (const record of records.filter((record) => record.isChecked)) {
          try {
            await AddDepts({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  dept_desc: record.Department,
                  dept_idx: 1,
                },
              },
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;

      case PayRollMasters.CATEGORY:
        for (const record of records.filter((record) => record.isChecked)) {
          try {
            await AddCategories({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  category_desc: record.Category,
                  category_idx: 1,
                },
              },
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case PayRollMasters.DESIGNATION:
        for (const record of records.filter((record) => record.isChecked)) {
          try {
            await AddDesignations({
              variables: {
                token,
                inst_id: InstId,
                user_details,

                input: {
                  designation_desc: record.Designation,
                  designation_idx: 1,
                },
              },
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case PayRollMasters.JOB_TYPE:
        for (const record of records.filter((record) => record.isChecked)) {
          try {
            await AddJobTypes({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  job_type_desc: record.JobType,
                  job_type_idx: 1,
                },
              },
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case PayRollMasters.GRADE:
        for (const record of records.filter((record) => record.isChecked)) {
          try {
            await AddGrades({
              variables: {
                token,
                inst_id: InstId,
                user_details,

                input: {
                  grade_desc: record.Grade,
                  grade_idx: 1,
                },
              },
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      default:
        break;
    }
  };

  return {
    handleSubmit,
    loading:
      MasterType === PayRollMasters.DEPARTMENT
        ? deptCreationLoading
        : MasterType === PayRollMasters.CATEGORY
        ? catCreationLoading
        : MasterType === PayRollMasters.DESIGNATION
        ? designationCreationLoading
        : MasterType === PayRollMasters.GRADE
        ? gradeCreationLoading
        : jobTypeCreationLoading,
  };
};

export default useMasterMutations;

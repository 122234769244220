import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { GetChannelSubscribed } from "../queries/chatapplication/list";
import { UserType } from "../utils/Enum.types";
import useToken from "./useToken";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "./useInstitutionConfiguration";
import useStudentDatabyId from "./useStudentDatabyId";
import { EMPTY_STRING } from "../utils/constants";
import { ChannelQueryType } from "../Modules/Accounts/common/Enum.types";
import useCheckAllocationType from "../Modules/Academics/hooks/useCheckAllocationType";

interface GetChannelSubscribedDetails {
  id: number;
  channel_name: string;
  channel_desc: string;
  channel_topic: string;
  channel_is_student: boolean;
  channel_chat_enabled: boolean;
  channel_type: string;
  channel_active: boolean;
  channel_icon_id: number;
}
interface GetChannelSubscribedData {
  GetChannelSubscribed: GetChannelSubscribedDetails[];
}
interface GetChannelSubscribedVars {
  token: string;
  input: { user_type: string; id: number };
  device_token: string;
  inst_id: number | string;
  channel_type: string[];
  entry_level: string;
  entry_id: number;
  per_std_subj_allocation: boolean;
}

const useChannelDetails = (channel_type: string[]) => {
  const { state } = useContext(AppContext);
  const [user_type, setUserType] = useState("");
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const { entry_level } = useInstitutionConfiguration();
  const { studentEntryId } = useStudentDatabyId();
  const [id, setId] = useState(0);

  const { flag } = useCheckAllocationType(
    entryId ? Number(entryId) : studentEntryId
  );
  const [ChannelListids, { data, loading, error }] = useLazyQuery<
    GetChannelSubscribedData,
    GetChannelSubscribedVars
  >(GetChannelSubscribed, {
    variables: {
      token,
      input: {
        user_type,
        id,
      },
      device_token: EMPTY_STRING,
      inst_id: InstId!,
      channel_type,
      entry_level,
      entry_id: entryId ? Number(entryId)! : studentEntryId,
      per_std_subj_allocation: flag ? flag :false,
    },
  });

  useEffect(() => {
    if (state.claims!.STUDENT) {
      setUserType(UserType.STUDENT);
      setId(state.studentId);
    } else if (state.claims?.EMPLOYEE) {
      setUserType(UserType.EMPLOYEE);
      setId(state.empLoginId);
    }
  }, [state]);

  useEffect(() => {
    if (
      user_type &&
      id &&
      ((channel_type.includes(ChannelQueryType.ENTRYLEVEL) && entry_level) ||
        channel_type.includes(ChannelQueryType.ENTRYLEVEL) === false) &&
      flag !== undefined
    ) {
      ChannelListids();
    }
  }, [user_type, ChannelListids, entry_level, id, token, flag]);
  return {
    channelDetails: {
      data,
      loading,
      error,
    },
  };
};

export default useChannelDetails;

import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import useAcctTableJson from "../../json/useAcctTableJson";
import { PayerType } from "../FeeReceipt/Index";
import { TableHeaderProps } from "../../../../Types/Tables";
import Print from "../../../../images/Print.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import {
  ChallanMasterDetails,
  GetAcctChallanEdges,
  GetAcctChallanMasterByDatesDetails,
} from "../../hooks/useAcctVoucherDetailsByDates";
import { ChallanQueryTypes } from "../../common/Enum.types";
import { userDetails } from "../../../../Types/Accounting/other";
import { useLazyQuery } from "@apollo/client";
import { GetAcctChallans } from "../../queries/Vouchers/query";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  Direction,
  PageFor,
  ReceiptTypes,
  SortBy,
  studentData,
} from "../../../../utils/Enum.types";
import {
  DateRange,
  filterVouchersByTypeForChallan,
  getModifiedScrollHeight,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import DownArrow from "../../../../images/DownArrow.svg";
import { payloadTypes } from "../../../../context/reducer";
import ChallanReconcileModal from "./ChallanReconcileModal";
import {
  EnquiryStudentDetailsByMasterChallanId,
  PassedOutStudentDetailsByMasterChallanId,
  StudentDetailsByChallanVoucherId,
} from "../../../../queries/common";
import { ChallannodeData, nodevars } from "../../../../Types/Accounting";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import { responseType } from "../../../../utils/Form.types";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
export interface ChallanQueryInput {
  challan_query_type: string;
  inst_id: string | number;
  start_date: string;
  end_date: string;
  student_id: number;
  user_details: userDetails;
  acct_ldgr_id: number;
}
export interface GetAcctChallansData {
  GetAcctChallans: GetAcctChallanMasterByDatesDetails;
}
export interface GetAcctChallansVars {
  token: string;
  fin_yr_id: number;
  inst_id: string | number;
  input: ChallanQueryInput;
  first: number | null;
  after: string | null;
  sortBy: string;
  direction: string;
  v_no: string;
  vTranscationNo: string;
  amount: number | null;
  ldgrDesc: string;
}
interface Props {
  pageType: PageFor;
}
const Challan = ({ pageType }: Props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();

  const { dispatch, state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [startDate, setStartDate] = useState(toInputStandardDate(TODAY_DATE));
  const [endDate, setEndDate] = useState(toInputStandardDate(TODAY_DATE));
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<GetAcctChallanEdges[]>([]);
  const [isDemand, setIsDemand] = useState(false);
  const [challanId, setChallanId] = useState(0);
  const [challanNodeId, setChallanNodeId] = useState(0);
  const [statusSelected, setStatusSelected] = useState("");

  const [enquiryStudentDetails, setEnquiryStudentDetails] = useState<
    ChallanMasterDetails[]
  >([]);
  const [studentDetails, setStudentDetails] = useState<ChallanMasterDetails[]>(
    []
  );

  const [searchData, setsearchData] = useState("");
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const [selectedStudentType, setSlectedStudentType] = useState("");
  const [passedOutStudentDetails, setPassedStudentDetails] = useState<
    ChallanMasterDetails[]
  >([]);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const { Accounts_Table } = useAcctTableJson();
  const [reconcileReceipt, setReconcileReceipt] = useState(false);
  const [GetChallans, { data, loading, fetchMore }] = useLazyQuery<
    GetAcctChallansData,
    GetAcctChallansVars
  >(GetAcctChallans);

  let queryType: string = ChallanQueryTypes.ALL_CHALLAN;

  switch (true) {
    case transactionBookLedgerId?.label === "All Banks":
      queryType = ChallanQueryTypes.CHALLAN_ALL_BANK_LDGR;
      break;
    case statusSelected === "Pending":
      queryType =
        selectedStudentType === "Current Students"
          ? ChallanQueryTypes.CHALLAN_STD_PENDING
          : selectedStudentType === "Enquiry Students"
          ? ChallanQueryTypes.CHALLAN_ENQ_PENDING
          : selectedStudentType === "Alumni Students"
          ? ChallanQueryTypes.CHALLAN_ALUMINI_PENDING
          : ChallanQueryTypes.CHALLAN_PENDING;
      break;
    case statusSelected === "Reconciled":
      queryType =
        selectedStudentType === "Current Students"
          ? ChallanQueryTypes.CHALLAN_STD_RECONCILED
          : selectedStudentType === "Enquiry Students"
          ? ChallanQueryTypes.CHALLAN_ENQ_RECONCILED
          : selectedStudentType === "Alumni Students"
          ? ChallanQueryTypes.CHALLAN_ALUMINI_RECONCILED
          : ChallanQueryTypes.CHALLAN_RECONCILED;
      break;
    case statusSelected === "Discarded":
      queryType =
        selectedStudentType === "Current Students"
          ? ChallanQueryTypes.CHALLAN_STD_DISCORDED
          : selectedStudentType === "Enquiry Students"
          ? ChallanQueryTypes.CHALLAN_ENQ_DISCORDED
          : selectedStudentType === "Alumni Students"
          ? ChallanQueryTypes.CHALLAN_ALUMINI_DISCORDED
          : ChallanQueryTypes.CHALLAN_DISCORDED;
      break;
    default:
      queryType =
        selectedStudentType === "Current Students"
          ? ChallanQueryTypes.CHALLAN_ALL_STD
          : selectedStudentType === "Enquiry Students"
          ? ChallanQueryTypes.CHALLAN_ALL_ENQ
          : selectedStudentType === "Alumni Students"
          ? ChallanQueryTypes.CHALLAN_ALL_ALUMINI
          : ChallanQueryTypes.ALL_CHALLAN;
  }

  const filteredStudentsReceipts = filterVouchersByTypeForChallan(
    ReceiptTypes.STUDENT_RECEIPT,
    data!
  );
  const filteredPassedStudentReceipts = data
    ? data.GetAcctChallans.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          node.v_std_passout_receipt
      )
    : [];
  const filteredEnquiryStudentReceipts = data
    ? data.GetAcctChallans.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT && node.v_std_enquiry
      )
    : [];
  const [GetStudentDetails] = useLazyQuery<ChallannodeData, nodevars>(
    StudentDetailsByChallanVoucherId
  );

  const [GetPassedStudentDetails] = useLazyQuery<ChallannodeData, nodevars>(
    PassedOutStudentDetailsByMasterChallanId
  );

  const [GetEnqStudentDetails] = useLazyQuery<ChallannodeData, nodevars>(
    EnquiryStudentDetailsByMasterChallanId
  );
  const handleClear = () => {
    setSlectedStudentType(EMPTY_STRING);
    setStatusSelected(EMPTY_STRING);
    setTransactionLedgerId(null);
  };
  const FetchStudentDetails = (
    voucher: ChallanMasterDetails,
    type: studentData
  ) => {
    const student = studentDetails?.find(
      (studentDetail) => studentDetail?.id === voucher.id
    );
    const enqStudent = enquiryStudentDetails.find(
      (enqStudentDetails) => enqStudentDetails?.id === voucher.id
    );
    const passedOutStudent = passedOutStudentDetails.find(
      (passedOutStudentDetails) => passedOutStudentDetails?.id === voucher.id
    );

    if (
      student &&
      voucher.student_id &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            student?.mst_student.first_name +
            " " +
            student?.mst_student.middle_name +
            " " +
            student?.mst_student.last_name
          );
        case studentData.admNo:
          return student?.mst_student.std_adm_no!;
        case studentData.parent:
          return student?.mst_student.std_father_name!;
        case studentData.reg:
          return student?.mst_student.std_reg_no!;
        case studentData.id:
          return student?.mst_student.id!;
        default:
          break;
      }
    }
    if (
      enqStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            enqStudent?.enquiry_student_details.first_name +
            " " +
            enqStudent?.enquiry_student_details.middle_name +
            " " +
            enqStudent?.enquiry_student_details.last_name
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return enqStudent?.enquiry_student_details.std_father_name!;
        case studentData.reg:
          return "-";
        case studentData.id:
          return enqStudent?.enquiry_student_details.id!;
        default:
          break;
      }
    }
    if (
      passedOutStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt
    ) {
      switch (type) {
        case studentData.name:
          return passedOutStudent?.passout_std_details.std_name;
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return "-";
        case studentData.reg:
          return "-";
        case studentData.id:
          return passedOutStudent?.passout_std_details.id!;
        default:
          break;
      }
    }
  };
  const handleButtonClick = (voucher: ChallanMasterDetails) => {
    const student = studentDetails?.find(
      (studentDetail) => studentDetail?.id === voucher.id
    );
    const enqStudent = enquiryStudentDetails.find(
      (enqStudentDetails) => enqStudentDetails?.id === voucher.id
    );
    const passedOutStudent = passedOutStudentDetails.find(
      (passedOutStudentDetails) => passedOutStudentDetails?.id === voucher.id
    );

    if (
      student &&
      voucher.student_id &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt === false
    ) {
      dispatch({
        type: payloadTypes.SET_STD_ID_FOR_CHALLAN,
        payload: {
          stdIdForChallan: student.mst_student.id,
        },
      });
    }
    if (
      enqStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry &&
      voucher.v_std_passout_receipt === false
    ) {
      dispatch({
        type: payloadTypes.SET_ENQ_STD_ID_FOR_CHALLAN,
        payload: {
          enqStdIdForChallan: enqStudent.enquiry_student_details.id,
        },
      });
    }
    if (
      passedOutStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt
    ) {
      dispatch({
        type: payloadTypes.SET_PASSOUT_STD_ID_FOR_CHALLAN,
        payload: {
          passOutStdIdForChallan: passedOutStudent.passout_std_details.id,
        },
      });
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctChallans.edges;
            const pageInfo = fetchMoreResult.GetAcctChallans.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctChallans.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctChallans: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctChallans.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (token && state.ActiveFinYr && queryType) {
      GetChallans({
        variables: {
          token,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          after: null,
          first: ROWS_PER_PAGE,
          sortBy: SortBy.V_DATE,
          inst_id: InstId!,
          direction: Direction.ASC,
          input: {
            challan_query_type: queryType,
            inst_id: InstId!,
            start_date: toIsoDate(startDate),
            end_date: toIsoDate(endDate),
            student_id: 0,
            acct_ldgr_id: transactionBookLedgerId?.value
              ? transactionBookLedgerId?.value
              : 0,
            user_details,
          },
          ldgrDesc: EMPTY_STRING,
          amount: null,
          v_no: searchData,
          vTranscationNo: EMPTY_STRING,
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    GetChallans,
    state.ActiveFinYr,
    searchData,
    startDate,
    endDate,
    queryType,
  ]);

  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetAcctChallans.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = students.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
              },
            };
          }
          return newVoucher;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctChallans.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);
  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, filteredStudentsReceipts?.length, token]);

  useEffect(() => {
    if (filteredPassedStudentReceipts?.length) {
      GetPassedStudentDetails({
        variables: {
          token,
          ids: filteredPassedStudentReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setPassedStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetPassedStudentDetails, filteredPassedStudentReceipts?.length, token]);
  useEffect(() => {
    if (filteredEnquiryStudentReceipts.length) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: filteredEnquiryStudentReceipts.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setEnquiryStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetEnqStudentDetails, filteredEnquiryStudentReceipts.length, token]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Challan Receipts</Title>
      <div className="challan-receipt">
        <div className="challan-receipt__select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search Challan No."
              onChange={(e) => {
                setsearchData(e.target.value);
              }}
            />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={[
                "Current Students",
                "Enquiry Students",
                "Alumni Students",
              ]}
              value={selectedStudentType}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSlectedStudentType(newValue as string);
                } else {
                  setSlectedStudentType(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={["Pending", "Reconciled", "Discarded", "All"]}
              value={statusSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatusSelected(newValue as string);
                } else {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={[
                { label: "All Banks", value: 0 },
                ...BankLedgers.responseType!,
              ]}
              freeSolo
              value={transactionBookLedgerId}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(
                  option as responseType,
                  transactionBookLedgerId
                )
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setTransactionLedgerId(newValue as responseType);
                } else {
                  setTransactionLedgerId(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sort By Banks"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  fullWidth
                  onChange={(e) => setsearchData(e.target.value)}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>

          <div className="col-3 challan-receipt__select--flex">
            <TextField
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              label="Start Date"
              className="challan-receipt__select--textfield"
              type="date"
              value={toInputStandardDate(startDate)}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <TextField
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              label="End Date"
              className="challan-receipt__select--textfield"
              type="date"
              value={toInputStandardDate(endDate)}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="challan-receipt__tableblock">
          <TableContainer
            className="challan-receipt__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.ChallanReceipts.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="challan-receipt__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="challan-receipt__table--date">
                        {toStandardDate(data.node.v_date)}
                      </TableCell>
                      <TableCell className="challan-receipt__table--admno">
                        {data.node.v_no}
                      </TableCell>
                      <TableCell className="challan-receipt__table--admno">
                        {FetchStudentDetails(data.node, studentData.admNo)}
                      </TableCell>
                      <TableCell>
                        {FetchStudentDetails(data.node, studentData.name)}
                      </TableCell>
                      <TableCell className="challan-receipt__table--bank">
                        {data.node.acct_ldgr_db_details.ldgr_desc}
                      </TableCell>
                      <TableCell className="challan-receipt__table--type">
                        {data.node.v_transcation_type}
                      </TableCell>
                      <TableCell
                        className="challan-receipt__table--amount"
                        id="td-right"
                      >
                        {data.node.v_std_amt_total}
                      </TableCell>
                      <TableCell
                        className="challan-receipt__table--amount"
                        id="td-right"
                      >
                        {toStandardDate(data.node.v_reconciled_date) !==
                        toStandardDate(DEFAULT_TIME)
                          ? toStandardDate(data.node.v_reconciled_date)
                          : "-"}
                      </TableCell>
                      <TableCell className="challan-receipt__table--actions">
                        {data.node.vo_voucher_master_id === 0 && (
                          <button
                            className="challan-receipt__table--generate"
                            onClick={() => {
                              setReconcileReceipt(!reconcileReceipt);
                              handleButtonClick(data.node);
                              setIsDemand(data.node.v_std_demand_receipt);
                              setChallanId(
                                data.node.acct_challan_details[0]
                                  .challan_master_id
                              );
                              setChallanNodeId(data.node.id);
                            }}
                          >
                            Reconcile <img src={Print} alt="" />
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {/* <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} className="total">
                    Total
                  </TableCell>
                  <TableCell className="totalcount">0</TableCell>
                </TableRow>
              </TableFooter> */}
            </Table>
          </TableContainer>
        </div>

        <Button mode="clear" onClick={handleClear} />

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        isOpen={reconcileReceipt}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ChallanReconcileModal
              pageType={PageFor.MODAL}
              setModalFlag={setReconcileReceipt}
              payer={PayerType.ACCOUNTING}
              isDemand={isDemand}
              challanId={challanId}
              challanNodeId={challanNodeId}
            />
          </div>
          <div className="modal-flex__image h-100">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setReconcileReceipt(!reconcileReceipt);
                dispatch({
                  type: payloadTypes.SET_STD_ID_FOR_CHALLAN,
                  payload: {
                    stdIdForChallan: 0,
                  },
                });
                dispatch({
                  type: payloadTypes.SET_ENQ_STD_ID_FOR_CHALLAN,
                  payload: {
                    enqStdIdForChallan: 0,
                  },
                });
                dispatch({
                  type: payloadTypes.SET_PASSOUT_STD_ID_FOR_CHALLAN,
                  payload: {
                    passOutStdIdForChallan: 0,
                  },
                });
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Challan;

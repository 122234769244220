import { useContext } from "react";
import NavBar from "../../../components/common/Navbar/Index";
import { AppContext } from "../../../context/context";
import { DashBoardFor, LicenseTypes } from "../../../utils/Enum.types";
import AccountsDashboard from "../Dashboard/Index";
import { OtherAccountLedgerBooks } from "../../../utils/types";
import useUserRightsByEmpId from "../../UserRights/hooks/useUserRightsByEmpId";
interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  const { state } = useContext(AppContext);
  const path = window.location.pathname;

  const { USE_ACCOUNTS } = useUserRightsByEmpId();
  return (
    <>
      <NavBar
        NavType={LicenseTypes.ACCOUNTS}
        TeacherDashboard={
          path.includes("/cashierdashboard") &&
          ((USE_ACCOUNTS.details &&
            USE_ACCOUNTS.details.module_rights_type !== "1") ||
            USE_ACCOUNTS.details === null)
            ? true
            : false
        }
      />
      {DashBoardRequired && (
        <AccountsDashboard
          type={OtherAccountLedgerBooks.FEE_COLLECTED}
          pageType={
            state.claims?.OWNER && path.includes("/proprietor/accounts")
              ? DashBoardFor.PROPRIETOR
              : DashBoardFor.GENERAL
          }
        />
      )}
    </>
  );
};

export default Index;

import React, { useContext } from "react";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import View from "../../../../images/EyeWhite.svg";

import { useNavigate, useParams } from "react-router-dom";
import useChannelDetails from "../../../../customhooks/useChannelDetails";
import { ChannelQueryType } from "../../../Accounts/common/Enum.types";
import { AppContext } from "../../../../context/context";

const Index = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const { InstId, entryId, subjectId } = useParams();

  const { channelDetails } = useChannelDetails([ChannelQueryType.ASSIGNMENTS]);

  return (
    <>
      <div className="assignments">
        <div className="assignments__title">
          <Title>Assignment Overview</Title>
        </div>

        <div className="assignments__list">
          <div className="assignments__list--data">
            {channelDetails.data?.GetChannelSubscribed.map((data, index) => {
              return (
                <div className={"assignments__list--data--card"} key={index}>
                  <b className="assignments__list--data--b">
                    {data.channel_name}
                  </b>
                  <span className="assignments__list--data--code">
                    {/* {data.subcode} */}
                  </span>

                  <button
                    className="assignments__list--data--view"
                    onClick={() => {
                      if (state.claims && state.claims.STUDENT) {
                        navigate(`/${InstId}/assignments/${data.id}`);
                      } else {
                        navigate(
                          `/${InstId}/academics/dailyactivities/${entryId}/${subjectId}/channel/${data.id}/classdetails/assignments/view`
                        );
                      }
                    }}
                  >
                    View Assignment <img src={View} alt="" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        {state.claims && state.claims.STUDENT ? null : (
          <Button mode="back" onClick={() => navigate(-1)} />
        )}
      </div>
    </>
  );
};

export default Index;

import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import {
  GetAcdReportTestsData,
  GetAcdReportTestsVars,
} from "../../../../types/Progresscard";
import { GetAcdReportTests } from "../../../../queries/reportcard";
import useToken from "../../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../../context/context";

const useAcdReportTestDetails = () => {
  const { token } = useToken();
  const { InstId, reportId } = useParams();
  const { state } = useContext(AppContext);
  const [GetAcdReportTestDetails, { data: TestDetails, loading }] =
    useLazyQuery<GetAcdReportTestsData, GetAcdReportTestsVars>(
      GetAcdReportTests,
      {
        variables: {
          token,
          inst_id: Number(InstId),
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          acd_report_card_id: Number(reportId),
        },
      }
    );
  useEffect(() => {
    if (token && InstId!) {
      GetAcdReportTestDetails();
    }
  }, [token, GetAcdReportTestDetails]);
  return { data: TestDetails, loading };
};

export default useAcdReportTestDetails;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toggleFullSceen,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { useParams } from "react-router";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import Eduate from "../../../images/Eduate_Logo_image.png";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import Settings from "../../../images/Settings.svg";
import Close from "../../../images/Close.svg";
import More from "../../../images/More.svg";
import Message from "../../../images/SendMessage.svg";
import MessageListModal from "../../Channel/MessageList";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Input from ".././../../stories/Input/Input";
import Modal from "react-modal";
import {
  PageNumbers,
  StudentListFields,
  PageFor,
  StudentStatus,
  TableHeaders,
  StudentReportType,
  StudentListFor,
  Operation,
  InstitutionConfigurationTypes,
  PageLabel,
  FileUploadParams,
  ColumnVisibilityFor,
} from "../../../utils/Enum.types";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { StudentsTitlesType } from "../../../Types/Student/json";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import ConfigurationSettings from "../configurations/general/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Home from "../Home/Index";

import {
  ConfigurationsModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import { useLazyQuery } from "@apollo/client";
import { CheckIfInstConfigComplete } from "../../../queries/institution/configuration/query";
import Enlarge from "../../../images/Enlarge.svg";

import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import MessageModal from "../../../pages/MessageModal";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";

import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import StudentTotalCount from "./Components/StudentTotalCount";
import LegendsInStudentList from "../Student/Components/LegendsInStudentList";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import {
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../utils/types";
import useStudentTableJson from "../../../json/useStudentTableJson";
import { Fields } from "./StudentFieldsForExport";
import useAcdDropDownData from "../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";
import StatusAutocomplete from "../../../components/common/Autocompletes/StatusAutocomplete";

import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { TooltipForMultipleOption } from "../../../styles/TooltipStyles";
const { Students } = require("../../../json/title.json");

interface Props {
  pageType: PageFor;
  studentListFor: StudentListFor;
  queryType: StudentReportType;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  label?: PageLabel;
}
export interface columnsType {
  label: string;
  value: StudentListFields;
  visible: boolean;
}
export const FetchStudentStatusClassName = (row: StudentStatus | string) => {
  switch (row) {
    case StudentStatus.CUR:
      return "std_status--cur";
    case StudentStatus.TC:
      return "std_status";
    case StudentStatus.DET:
      return "std_status--det";
    case StudentStatus.SOA:
      return "std_status--soa";
    case StudentStatus.NXT:
      return "std_status--nxt";
    case StudentStatus.NE:
      return "std_status--ne";

    default:
      break;
  }
};
const StudentList = ({
  pageType,
  studentListFor,
  queryType,
  setStudentModal,
  studentModal,
  label,
}: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [previewModal, setPreviewModal] = useState(false);
  const [showAllStudent, setShowAllStudent] = useState(false);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );

  const [pdfFlag, setPdfFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_ADMISSIONS,
  });
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  var [studentListOptions] = useState<readonly string[]>([
    StudentListFields.STD_ADMISSION_NO,
    StudentListFields.STD_REG_NO,
    StudentListFields.STD_NAME,
    StudentListFields.STD_FATHER_NAME,
    StudentListFields.BRANCH_ID,
    StudentListFields.CATEGORY_ID,
    StudentListFields.STD_STATUS,
  ]);

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  //states for modal  screens
  const [instConfigErrorModal, setInstConfigErrorModal] = useState(false);
  //configuration Modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const { configData: EnableChannel } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_INDIVIDUAL_CHANNELS
  );
  const channelFlag =
    EnableChannel &&
    EnableChannel.data &&
    EnableChannel.data.GetSwConfigVariables[0].config_boolean_value;
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.STUDENT_LIST_PAGE
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //Queries
  const [CheckConfigForStudentAddition] = useLazyQuery(
    CheckIfInstConfigComplete,
    {
      variables: {
        token,
        inst_id: InstId,
      },
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.ACD_STD_GENERAL,
    statusSelected ? statusSelected.value : EMPTY_STRING,
    []
  );

  const { data, fetchMore, loading } = AcademicsStudentData;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { categoryDropDown } = useInstMasterDataByInstId();

  //Categories
  if (USE_CLASS_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CLASS_ID
    );
  }
  if (USE_CATEGORY_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CATEGORY_ID
    );
  }

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
    setStatusSelected(null);
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const studentCol = [
    { title: "Sl", field: "SlNo" },
    ...studentListOptions.map((option) => {
      switch (option) {
        case StudentListFields.STD_ADMISSION_NO:
          return { title: "Adm No", field: "AdmNo" };
        case StudentListFields.STD_REG_NO:
          return { title: "Reg. No.", field: "RegNo" };
        case StudentListFields.STD_NAME:
          return { title: "Name", field: "Name" };
        case StudentListFields.STD_FATHER_NAME:
          return { title: "Father Name", field: "Father" };
        case StudentListFields.STD_EMAIL:
          return { title: "E-Mail", field: "Email" };
        case StudentListFields.STD_MOBILE:
          return { title: "Mobile", field: "Mobile" };
        case StudentListFields.DEPT_ID:
          return { title: departmentLabel, field: "Dept" };
        case StudentListFields.BRANCH_ID:
          return { title: branchLabel, field: "Branch" };
        case StudentListFields.CLASS_ID:
          return { title: classLabel, field: "Class" };
        case StudentListFields.SEMESTER_ID:
          return { title: semesterLabel, field: "Sem" };
        case StudentListFields.SECTION_ID:
          return { title: sectionLabel, field: "Section" };
        case StudentListFields.CATEGORY_ID:
          return { title: "Category", field: "Category" };
        case StudentListFields.STD_STATUS:
          return { title: "Status", field: "Status" };
        default:
          return { title: "", field: "" };
      }
    }),
  ];

  const downloadPdf = () => {
    if (data) {
      const getBase64 = (file: any, cb: (a: string) => void) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          cb(reader.result?.toString()!);
        };
      };
      fetch(LogoOrSign.defaultLogo)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          getBase64(blob, (result) => {
            fetch(Eduate)
              .then((response) => {
                return response.blob();
              })
              .then((blob2) => {
                getBase64(blob2, (result2) => {
                  const doc = new jsPDF("landscape", "mm", "a4");
                  doc.setFont("Helvetica", "bold");
                  let i = 0;
                  var totalPages = doc.getNumberOfPages();
                  for (i = 1; i <= totalPages; i++) {
                    doc.setFontSize(18);
                    const startY = 10 + (i - 1) * 20;
                    doc.setFillColor(240, 240, 240);
                    doc.rect(
                      0,
                      0,
                      doc.internal.pageSize.getWidth(),
                      doc.internal.pageSize.getHeight(),
                      "F"
                    );
                    doc.setTextColor(0, 0, 0);
                    doc.text(
                      `${InstDetails?.data?.nodes[0].inst_name}`,
                      80,
                      startY
                    );
                    doc.setFont("Times New Roman", "normal");
                    doc.setFontSize(13);
                    doc.text(
                      `${InstDetails?.data?.nodes[0]?.inst_address}`,
                      120,
                      startY + 7
                    );

                    doc.setFont("Times New Roman", "normal");
                    doc.setFontSize(13);
                    doc.text(
                      `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                      120,
                      startY + 12
                    );
                    doc.setFontSize(13);
                    doc.text(
                      `Fin-year -${
                        state.ActiveFinYr
                          ? state.ActiveFinYr.fin_yr
                          : EMPTY_STRING
                      }`,
                      45,
                      startY + 21
                    );
                    doc.setFontSize(14);
                    doc.setFont("Times New Roman", "normal");
                    doc.text(`Students List`, 120, startY + 21);
                    doc.setFontSize(13);
                    doc.text(
                      `As-on-date:${toStandardDate(serverDate)}`,
                      230,
                      startY + 21
                    );
                    doc.addImage(result, "JPEG", 15, 5, 22, 22);
                    if (configLogo) {
                      doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                    }
                  }

                  autoTable(doc, {
                    startY: 33,
                    bodyStyles: { valign: "top" },
                    theme: "striped",
                    columns: studentCol.map((col) => ({
                      ...col,
                      dataKey: col.field,
                      styles: { fontSize: 18 },
                    })),

                    body: data
                      ? data.GetAcdStudents.edges.map((edge, index) => ({
                          SlNo: index + 1,
                          AdmNo: edge.node.std_adm_no,
                          RegNo: edge.node.std_reg_no,
                          Name:
                            edge.node.first_name +
                            " " +
                            edge.node.middle_name +
                            " " +
                            edge.node.last_name,
                          Father: edge.node.std_father_name,
                          Email: edge.node.std_email,
                          Mobile: edge.node.std_mobile,
                          Dept: edge.node.acd_dept.dept_desc,
                          Branch: edge.node.acd_branch.branch_desc,
                          Class: edge.node.acd_class.class_desc,
                          Sem: edge.node.acd_semester.sem_desc,
                          Section: edge.node.acd_section.section_desc,
                          Category: edge.node.category.cat_desc,
                          Status: edge.node.std_status,
                        }))
                      : [],
                    showFoot: "lastPage",
                    showHead: "everyPage",
                    useCss: true,
                    didDrawPage: function (data) {
                      // Footer
                      let str =
                        "" +
                        doc.getCurrentPageInfo().pageNumber +
                        "of" +
                        doc.getNumberOfPages();
                      doc.setFontSize(10);

                      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                      let pageSize = doc.internal.pageSize;
                      let pageHeight = pageSize.height
                        ? pageSize.height
                        : pageSize.getHeight();
                      doc.text(str, data.settings.margin.left, pageHeight - 10);
                    },
                  });

                  doc.save(
                    `${InstDetails?.data?.nodes[0]?.inst_name} ${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    } `
                  );
                });
              });
          });
        });
      setPdfFlag(false);
      setRowsPerPage(ROWS_PER_PAGE);
    }
  };

  useEffect(() => {
    if (pdfFlag && rowsPerPage === null && !loading) downloadPdf();
    // eslint-disable-next-line
  }, [pdfFlag, rowsPerPage, loading]);

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },
  ];

  // Conditionally add the "actions" column based on the channelFlag
  if (channelFlag) {
    columns.push({
      field: "actions",
      cellClassName: "td-actions-print",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const studentId = params.row.std_id || "";

        return (
          <TooltipForMultipleOption
            onClick={() => setEnableToolTipModal(!enableToolTipModal)}
            placement="left-start"
            title={
              enableToolTipModal && (
                <ul>
                  <li
                    onClick={() => {
                      setMessageModal(!messageModal);
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: { studentId: studentId },
                      });
                    }}
                    className="studentlist__table--more--fee"
                  >
                    <img src={Message} alt="/" className="td-status__more" />
                    <span>Send Message</span>
                  </li>
                </ul>
              )
            }
          >
            <img src={More} alt="/" />
          </TooltipForMultipleOption>
        );
      },
    });
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: false,
      std_mobile: false,
      is_login_created: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
    });
  useEffect(() => {
    if (data && !loading && pdfFlag === false) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });

        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_roll_no: node.std_roll_no ? node.std_roll_no : EMPTY_STRING,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_doa),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,

            std_type: node.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_roll_no: node.std_roll_no ? node.std_roll_no : EMPTY_STRING,

            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_doa),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,

            std_type: node.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [pdfFlag, rowsPerPage, loading, data]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents
                  ? prevResult.GetAcdStudents.edges.filter(
                      ({ node: { id } }) =>
                        newEdges.findIndex(
                          ({ node: { id: newId } }) => newId === id
                        ) !== -1
                    )
                  : [];

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        // eslint-disable-next-line
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_ADMISSIONS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_ADMISSIONS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <div
      className={
        pageType === PageFor.GENERAL ? "studentlist" : "studentlist--modal"
      }
    >
      {pageType === PageFor.GENERAL && label !== PageLabel.FALSE ? (
        <Home DashBoardRequired={false} />
      ) : null}

      {label !== PageLabel.FALSE && (
        <div className="row g-0">
          <div className="col">
            <Title>
              {queryType === StudentReportType.NON_ELIGIBLE ? (
                "Not Eligible Students"
              ) : (
                <>
                  {Students.Titles.map(
                    (title: StudentsTitlesType, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          {title.List}
                        </React.Fragment>
                      );
                    }
                  )}
                </>
              )}
            </Title>
          </div>
          <div className="configuration-settings">
            {pageType === PageFor.MODAL ? (
              <>
                <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
              </>
            ) : (
              <>
                {USE_CONFIG_KEY && (
                  <img
                    src={Settings}
                    alt="/"
                    id="settings-icon"
                    onClick={() => setConfigurationModal(!configurationModal)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
      <form>
        <div
          className={
            pageType === PageFor.GENERAL
              ? "row g-0 studentlist__options"
              : "row g-0 studentlist__options--modal"
          }
        >
          <div className="col-1 studentlist__select-flex studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && branchSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }

                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                  } else {
                    setSectionSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <StatusAutocomplete
              label="Status"
              onChange={(newValue) => {
                setStatusSelected(newValue as optionsType);
              }}
              statusValue={EMPTY_STRING}
            />
          </div>
          <div className="col"></div>
          {pageType === PageFor.MODAL ? null : (
            <div className="col-2 buttons-flex">
              <>
                <Button
                  mode="clear"
                  onClick={(e: React.FormEvent) => {
                    e.preventDefault();
                    clear();
                  }}
                  className="student-clear-button"
                />

                {studentListFor === StudentListFor.GENERAL ? (
                  <Button
                    mode="addnew"
                    onClick={(e: React.MouseEvent) => {
                      CheckConfigForStudentAddition({
                        variables: {
                          token,
                          inst_id: InstId,
                        },
                      }).then(({ data }) => {
                        if (data && data.CheckIfInstConfigComplete) {
                          window.sessionStorage.removeItem("student-id");
                          dispatch({
                            type: payloadTypes.SET_STD_ENQUIRY_ID,
                            payload: { studentEnquiryId: 0 },
                          });
                          navigate(
                            `/${InstId}/admissions/students/1/0/basicDetails`
                          );
                        } else {
                          setInstConfigErrorModal(!instConfigErrorModal);
                        }
                      });
                      e.preventDefault();
                    }}
                    autoFocus
                    className="student-add-button"
                  />
                ) : null}
              </>
            </div>
          )}
        </div>

        <div className="row g-0">
          <div className="col-5 studentlist__filters">
            {pageType === PageFor.MODAL && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAllStudent}
                      onClick={() => setShowAllStudent(!showAllStudent)}
                    />
                  }
                  label="All Students"
                />
              </FormGroup>
            )}
          </div>

          <div className="col"></div>
        </div>
      </form>

      <div
        id={
          pageType === PageFor.MODAL
            ? "studentlist__modal--frame"
            : pageType === PageFor.GENERAL && label === "FALSE"
            ? "studentlist__frame--report"
            : "studentlist__frame"
        }
      >
        <div className={`h100`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            onCellClick={(params) => {
              if (params.field === "std_name") {
                if (
                  pageType === PageFor.GENERAL &&
                  studentListFor === StudentListFor.GENERAL
                ) {
                  navigate(
                    `/${InstId}/admissions/students/${params.row.std_id}/basicdata`
                  );
                }
                if (pageType === PageFor.MODAL) {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: { studentId: params.row.std_id },
                  });

                  if (studentListFor === StudentListFor.DASHBOARD) {
                    // setPreviewModal?.(!previewModal);
                  } else {
                    setStudentModal(!studentModal);
                  }
                }
              }
            }}
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
      </div>
      <div className="studentlist__buttons">
        <div
          className={
            !(pageType === PageFor.GENERAL && label === "FALSE")
              ? "button-left"
              : ""
          }
        >
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setPdfFlag(true);
            }}
            disabled={classSelected && classSelected.value ? false : true}
            mode="pdf"
          />
          <Button
            onClick={() => {
              navigate(
                `/${InstId}/admissions/reports/instlevel/student/adm_list`
              );
            }}
            mode="export"
          />

          {pageType === PageFor.MODAL && (
            <Button mode="cancel" onClick={() => setStudentModal(false)} />
          )}

          {pageType === PageFor.GENERAL &&
          label === "FALSE" ? null : pageType === PageFor.GENERAL ? (
            <Button mode="back" onClick={() => navigate(-1)} />
          ) : null}
        </div>
        {pageType === PageFor.GENERAL && label === "FALSE" ? null : (
          <LegendsInStudentList />
        )}
        <StudentTotalCount
          totalCount={data ? data.GetAcdStudents.totalCount! : 0}
        />
      </div>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.STUDENT_LIST_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* MessageModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      {/* preview-modal */}
      <Modal
        ariaHideApp={false}
        style={StudentModalStyles}
        isOpen={previewModal}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">student Preview</div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPreviewModal?.(!previewModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StudentList;

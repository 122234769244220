import React, { Fragment, Key } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Title } from "../../../../stories/Title/Title";

import { EmpPreviewTabsType, EmployeeTitlesType } from "../../Types/jsonTypes";
const { HRTitles } = require("../../json/title.json");

const PreviewTabs = () => {
  const { InstId, empId } = useParams();

  return (
    <>
      <Title>
        {HRTitles.Employee.Titles.map(
          (title: EmployeeTitlesType, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.EmployeeDetails}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="css3-tabstrip">
        <ul>
          <li>
            <NavLink
              to={`/${InstId}/hr/employee/${empId}/preview/basicdetails`}
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-0"
              />

              <label htmlFor="css3-tabstrip-0-0">
                {HRTitles.Employee.PreviewTabs.Titles.map(
                  (label: EmpPreviewTabsType, index: Key) => {
                    return (
                      <Fragment key={index}>{label.BasicDetails}</Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${InstId}/hr/employee/${empId}/preview/personaldetails`}
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-1"
              />
              <label htmlFor="css3-tabstrip-0-1">
                {HRTitles.Employee.PreviewTabs.Titles.map(
                  (label: EmpPreviewTabsType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {label.PersonalDetails}
                      </React.Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${InstId}/hr/employee/${empId}/preview/academicContribution`}
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-1"
              />
              <label htmlFor="css3-tabstrip-0-1">
                {HRTitles.Employee.PreviewTabs.Titles.map(
                  (label: EmpPreviewTabsType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {label.AcademicContribution}
                      </React.Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={`/${InstId}/hr/employee/${empId}/preview/otherdetails`}
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-2"
              />
              <label htmlFor="css3-tabstrip-0-2">
                {HRTitles.Employee.PreviewTabs.Titles.map(
                  (label: EmpPreviewTabsType, index: Key) => {
                    return (
                      <Fragment key={index}>{label.OtherDetails}</Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${InstId}/hr/employee/${empId}/preview/empDocuments`}
            >
              <input
                type="radio"
                name="css3-tabstrip-0"
                id="css3-tabstrip-0-2"
              />
              <label htmlFor="css3-tabstrip-0-2">
                {HRTitles.Employee.PreviewTabs.Titles.map(
                  (label: EmpPreviewTabsType, index: Key) => {
                    return (
                      <Fragment key={index}>{label.UploadDocuments}</Fragment>
                    );
                  }
                )}
              </label>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PreviewTabs;

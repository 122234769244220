import { useLazyQuery } from "@apollo/client";
import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  EnquiryStudentDetailsByMasterVoucherId,
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../queries/common";
import { Title } from "../../../../stories/Title/Title";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
import {
  filterVouchersByType,
  formatter,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  BankOrCash,
  DebitOrCredit,
  ExcelAlignment,
  ExcelFont,
  ExcelPageHeader,
  ExcelSheetsNames,
  ReceiptTypes,
  studentData,
} from "../../../../utils/Enum.types";
import List from "./List";
import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import Input from "../../../../stories/Input/Input";
import useToken from "../../../../customhooks/useToken";
import { filterOptions } from "../FeeReceiptBook/List/Index";

import { nodevars, VouchernodeData } from "../../../../Types/Accounting";
import { payloadTypes } from "../../../../context/reducer";
import StudentDemandList from "../AccountLedgerBook/Modals/StudentVoucherView";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import Modal from "react-modal";
import { AppContext } from "../../../../context/context";
import { GetAcctLdgrForDailyFeeCollection } from "../../queries/FeeLedgers/query/Byid";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../hooks/useAcctVoucherDetailsByDates";
import { optionsType } from "../../../../utils/Form.types";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  NEW_SHEET,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { VoucherQueryTypes } from "../../common/Enum.types";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import useAcctTableJson from "../../json/useAcctTableJson";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

interface GetAcctLdgrForDailyFeeCollectionDetails {
  id: number;
  ldgr_desc: string;
}
interface GetAcctLdgrForDailyFeeCollectionData {
  GetAcctLdgrForDailyFeeCollection: GetAcctLdgrForDailyFeeCollectionDetails[];
}
interface GetAcctLdgrForDailyFeeCollectionVars {
  token: string;
  inst_id: string;
  vo_start_date: string;
  vo_end_date: string;
  fin_yr_id: number;
}

const LedgerWiseReport = () => {
  const { format } = formatter;
  const { token } = useToken();
  const { InstId } = useParams();
  const { dispatch, state } = useContext(AppContext);
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const { InstDetails } = useInstDetails(1);
  const [adjustmentFlag, setAdjustmentFlag] = useState(false);
  const [page, setPage] = useState(0);
  const [v_no, setV_no] = useState("");
  const { serverDate } = useServerDateandTime();
  const [startDate, setStartDate] = useState(toInputStandardDate(TODAY_DATE));
  const [endDate, setEndDate] = useState(toInputStandardDate(TODAY_DATE));
  const [studentReceiptModal, setStudentReceiptModal] = useState(false);
  const { Accounts_Table } = useAcctTableJson();
  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );

  const [passedOutStudentDetails, setPassedStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [enquiryStudentDetails, setEnquiryStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );

  const [GetPassedStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    PassedOutStudentDetailsByMasterVoucherId
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnquiryStudentDetailsByMasterVoucherId
  );

  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    rowsPerPage,
    endDate,
    startDate,
    VoucherQueryTypes.STD_DAILY_RECEIPTS,
    v_no,
    adjustmentFlag,
    filter
  );

  const { data, fetchMore, loading } = VoucherDetails;

  const [GetLedgers, { data: acctLedgers }] = useLazyQuery<
    GetAcctLdgrForDailyFeeCollectionData,
    GetAcctLdgrForDailyFeeCollectionVars
  >(GetAcctLdgrForDailyFeeCollection, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
      vo_end_date: toIsoDate(endDate),
      vo_start_date: toIsoDate(startDate),
    },
    nextFetchPolicy: "network-only",
  });

  const filteredPassedStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          node.v_std_passout_receipt
      )
    : [];

  const filteredEnquiryStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT && node.v_std_enquiry
      )
    : [];
  const FetchStudentDetails = (
    voucher: voucherMasterDetails,
    type: studentData
  ) => {
    const student = studentDetails?.find(
      (studentDetail) => studentDetail?.id === voucher.id
    );
    const enqStudent = enquiryStudentDetails.find(
      (enqStudentDetails) => enqStudentDetails?.id === voucher.id
    );
    const passedOutStudent = passedOutStudentDetails.find(
      (passedOutStudentDetails) => passedOutStudentDetails?.id === voucher.id
    );

    if (
      student &&
      voucher.student_id &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            student?.mst_student.first_name +
            " " +
            student?.mst_student.middle_name +
            " " +
            student?.mst_student.last_name +
            " " +
            `(${student?.mst_student.branch.branch_desc} / ${student?.mst_student.class.class_desc} / ${student?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return student?.mst_student.std_adm_no!;
        case studentData.parent:
          return student?.mst_student.std_father_name!;
        case studentData.reg:
          return student?.mst_student.std_reg_no!;
        case studentData.id:
          return student?.mst_student.id!;
        default:
          break;
      }
    }
    if (
      enqStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            enqStudent?.enq_std_details.first_name +
            " " +
            enqStudent?.enq_std_details.middle_name +
            " " +
            enqStudent?.enq_std_details.last_name +
            " " +
            `(${enqStudent?.enq_std_details.branch_details.branch_desc} / ${enqStudent?.enq_std_details.class_details.class_desc} / ${enqStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return enqStudent?.enq_std_details.std_father_name!;
        case studentData.reg:
          return "-";
        case studentData.id:
          return enqStudent?.enq_std_details.id!;
        default:
          break;
      }
    }
    if (
      passedOutStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt
    ) {
      switch (type) {
        case studentData.name:
          return (
            passedOutStudent?.passout_std_details.std_name +
            " " +
            `(${passedOutStudent?.passout_std_details.branch_details.branch_desc} / ${passedOutStudent?.passout_std_details.class_details.class_desc} / ${passedOutStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return "-";
        case studentData.reg:
          return "-";
        case studentData.id:
          return passedOutStudent?.passout_std_details.id!;
        default:
          break;
      }
    }
  };

  type LedgerTotal = Record<number, number>;

  const LedgerTotals: LedgerTotal =
    acctLedgers?.GetAcctLdgrForDailyFeeCollection?.reduce((acc, ledgers) => {
      const debitData = vouchers
        ?.flatMap((d) => d.node.acct_voucher_details)
        .filter(
          (d) =>
            d.vo_cr_db === DebitOrCredit.CREDIT && d.acct_ldgr.id === ledgers.id
        );

      if (!debitData || debitData.length === 0) return acc;

      const ledgrId = ledgers.id;
      acc[ledgrId] = debitData.reduce((sum, ban) => sum + ban?.vo_cr, 0);

      return acc;
    }, {} as LedgerTotal) || {};

  const filteredStudentsReceipts = filterVouchersByType(
    ReceiptTypes.STUDENT_RECEIPT,
    VoucherDetails.data!
  );

  const tableData = [
    "Sl.no",
    "V.no",
    "Date",
    "Admission Number",
    "Register Number",
    "Name",
    "Paid Through",
    ...(acctLedgers?.GetAcctLdgrForDailyFeeCollection?.map(
      (acctledgrs) => acctledgrs.ldgr_desc
    ) || []),
  ];

  const stdData = data
    ? data.GetAcctVouchers.edges.map((edge, index) => {
        return {
          index: page * ROWS_PER_PAGE + index + 1,
          v_no: edge.node.v_no,
          v_date: toStandardDate(edge.node.v_date),
          regNo: FetchStudentDetails(edge.node, studentData.admNo),
          register: FetchStudentDetails(edge.node, studentData.reg),
          name: FetchStudentDetails(edge.node, studentData.name),
          paidThrough:
            edge.node.v_transcation_cash_or_bank === BankOrCash.BANK
              ? edge.node.v_transcation_type +
                " - " +
                edge.node.v_transcation_no
              : edge.node.v_transcation_type,
          ldgrDetails: acctLedgers?.GetAcctLdgrForDailyFeeCollection?.map(
            (banks) => {
              const res = edge.node.acct_voucher_details.filter(
                (d) =>
                  d.vo_cr_db === DebitOrCredit.CREDIT &&
                  d.acct_ldgr.id === banks.id
              );
              if (res[0] && res[0].acct_ldgr.id === banks.id) {
                return format(res[0].vo_cr);
              } else {
                return "-";
              }
            }
          ),
        };
      })
    : [];

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(NEW_SHEET);

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.index,
          item.v_no,
          item.v_date,
          item.regNo,
          item.register,
          item.name,
          item.paidThrough,
          item.ldgrDetails,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 13;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 65;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "J1:J3");

          worksheet.mergeCells(1, 1, 1, 10);

          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;

          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails?.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:J2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails?.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails?.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:J3");

          const mergedHeader: Cell = worksheet.getCell(F4_CELL);
          mergedHeader.value = ExcelPageHeader.DAY_WISE_COLLECTION;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("F4:F4");
          const mergedDate: Cell = worksheet.getCell("G4");
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("G4:J4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:E4");

          let Char = FIRST_INDEX;

          for (let i = 0; i < tableData.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 5);
            rowData.value = tableData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }
          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.LEFT };
              cell.font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };
              row.getCell(8).font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(8).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              row.getCell(9).font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(9).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              row.getCell(10).font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(10).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
            });
          });

          // const footerRow = worksheet.addRow([]);
          // footerRow.getCell(7).value = ExcelFooterHeader.TOTAL;
          // footerRow.getCell(7).font = {
          //   name: ExcelFont.ARIAL,
          //   size: 10,
          // };
          // footerRow.getCell(8).value =
          //   filter === EMPTY_STRING
          //     ? totalAdjustment
          //     : filter === "C"
          //     ? totalCash
          //     : filter === "B"
          //     ? (
          //         acctLedgers.data?.GetAcctLdgrs.edges.map((banks) =>
          //           format(bankTotals[banks.node.id] || 0)
          //         ) || []
          //       ).join(", ")
          //     : totalAdjustment;
          // if (filter === EMPTY_STRING) {
          //   footerRow.getCell(9).value = totalCash;
          // }
          // if (filter === EMPTY_STRING) {
          //   footerRow.getCell(10).value = (
          //     acctLedgers.data?.GetAcctLdgrs.edges.map((banks) =>
          //       format(bankTotals[banks.node.id] || 0)
          //     ) || []
          //   ).join(", ");
          // }
          // for (let i = 7; i <= 14; i++) {
          //   footerRow.getCell(i).alignment = {
          //     horizontal: ExcelAlignment.RIGHT,
          //   };
          // }
          // worksheet.addConditionalFormatting({
          //   ref: `H${footerRow.number}`,
          //   rules: FOOTER_CSS,
          // });
          // if (filter === EMPTY_STRING) {
          //   worksheet.addConditionalFormatting({
          //     ref: `I${footerRow.number}`,
          //     rules: FOOTER_CSS,
          //   });
          // }
          // if (filter === EMPTY_STRING) {
          //   worksheet.addConditionalFormatting({
          //     ref: `J${footerRow.number}`,
          //     rules: FOOTER_CSS,
          //   });
          // }

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.DAY_WISE);
            document.body.appendChild(link);
            link.click();
          });
          setExcelFlag(false);
          setRowsPerPage(ROWS_PER_PAGE);
        });
      });
  };

  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data) {
          setStudentDetails(data?.nodes!);
        }
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, filteredStudentsReceipts?.length, token]);
  useEffect(() => {
    if (state.ActiveFinYr && token) {
      GetLedgers();
    }
  }, [state.ActiveFinYr, GetLedgers, startDate, endDate, token]);
  useEffect(() => {
    if (filteredPassedStudentReceipts?.length) {
      GetPassedStudentDetails({
        variables: {
          token,
          ids: filteredPassedStudentReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setPassedStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetPassedStudentDetails, filteredPassedStudentReceipts?.length, token]);
  useEffect(() => {
    if (filteredEnquiryStudentReceipts.length) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: filteredEnquiryStudentReceipts.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setEnquiryStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetEnqStudentDetails, filteredEnquiryStudentReceipts.length, token]);
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.DayWiseCollection.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    ...(acctLedgers?.GetAcctLdgrForDailyFeeCollection.map((acctledgrs) => ({
      headerName: acctledgrs.ldgr_desc,
      cellClassName: "td-desc",
      headerAlign: "center" as GridAlignment,
      align: "right" as GridAlignment,
      field: acctledgrs.id.toString(),
    })) || []),
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = vouchers.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setVouchers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            concession_id: node.id,
            std_id: FetchStudentDetails(node, studentData.id),
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            adm_no: FetchStudentDetails(node, studentData.admNo),
            reg_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            v_std_enquiry: node.v_std_enquiry,
            v_std_passout_receipt: node.v_std_passout_receipt,
            v_std_receipt: node.v_std_receipt,
            received_from:
              node.v_transcation_cash_or_bank === BankOrCash.BANK
                ? `${node.v_transcation_type} - ${node.v_transcation_no}`
                : node.v_transcation_type,
            ...acctLedgers?.GetAcctLdgrForDailyFeeCollection?.reduce(
              (acc, banks) => {
                const voucherDetail = node.acct_voucher_details.find(
                  (d) =>
                    d.vo_cr_db === DebitOrCredit.CREDIT &&
                    d.acct_ldgr.id === banks.id
                );
                return {
                  ...acc,
                  [banks.id]: voucherDetail ? format(voucherDetail.vo_cr) : "-",
                };
              },
              {}
            ),
          }))
        );
      } else {
        setVouchers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            concession_id: node.id,
            std_id: FetchStudentDetails(node, studentData.id),
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            adm_no: FetchStudentDetails(node, studentData.admNo),
            reg_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            v_std_enquiry: node.v_std_enquiry,
            v_std_passout_receipt: node.v_std_passout_receipt,
            v_std_receipt: node.v_std_receipt,
            received_from:
              node.v_transcation_cash_or_bank === BankOrCash.BANK
                ? `${node.v_transcation_type} - ${node.v_transcation_no}`
                : node.v_transcation_type,
            ...acctLedgers?.GetAcctLdgrForDailyFeeCollection?.reduce(
              (acc, banks) => {
                const voucherDetail = node.acct_voucher_details.find(
                  (d) =>
                    d.vo_cr_db === DebitOrCredit.CREDIT &&
                    d.acct_ldgr.id === banks.id
                );
                return {
                  ...acc,
                  [banks.id]: voucherDetail ? format(voucherDetail.vo_cr) : "-",
                };
              },
              {}
            ),
          }))
        );
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    data,
    loading,
    studentDetails,
    enquiryStudentDetails,
    passedOutStudentDetails,
  ]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVouchers.edges;
                const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctVouchers: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctVouchers.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  const getRow = (): Record<string, string>[] => {
    const row: Record<string, string> = {
      id: "",
      date: "",
      receipt_no: "",
      adm_no: "",
      reg_no: "",
      name: "",
      received_from: "Total",
    };

    if (acctLedgers?.GetAcctLdgrForDailyFeeCollection) {
      acctLedgers.GetAcctLdgrForDailyFeeCollection.forEach((banks) => {
        row[String(banks.id)] = format(LedgerTotals[banks.id] || 0);
      });
    }

    return [row];
  };

  const pinnedRows = {
    bottom: getRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "received_from":
          return "total";

        case "id":
        case "date":
        case "receipt_no":
        case "adm_no":
        case "reg_no":
        case "name":
          return "";
        default:
          return "totalcount";
      }
    }

    return "";
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Day Wise Collection Report</Title>
      <div className="feecollected">
        <div className="feecollected__tableblock">
          <List />
        </div>

        <div className="feecollected__reportblock">
          <div className="row g-0 feecollected__select-options">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                className="feecollected__reportblock--filter-options--search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setV_no(e.target.value);
                  setPage(0);
                }}
                onKeyDown={handleFormEvent}
              />
            </div>

            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={filterOptions}
                openOnFocus
                autoHighlight
                onChange={(e, newValue) => {
                  if (newValue) {
                    const data = newValue as optionsType;
                    if (data.value !== BankOrCash.ADJ) {
                      setFilter(data.value);
                      setAdjustmentFlag(false);
                    } else {
                      setFilter(EMPTY_STRING);
                      setAdjustmentFlag(true);
                    }
                  } else {
                    setFilter(EMPTY_STRING);
                    setAdjustmentFlag(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-uncontrolled"
                    label="Receipt Types"
                    autoFocus
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="col"></div>

            <div className="col-3 feecollected__reportblock--date">
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className="feecollected__reportblock--textfield"
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className="feecollected__reportblock--textfield"
              />
            </div>
          </div>

          <div className={`feecollected__reportblock--details `}>
            {vouchers?.length ? (
              <>
                <StyledDatagrid
                  columns={columns}
                  rows={rows}
                  onCellClick={(params) => {
                    if (params.field === "name") {
                      dispatch({
                        type: payloadTypes.SET_RECEPIT_ID,
                        payload: {
                          receiptId: params.row.concession_id,
                        },
                      });

                      if (params.row.v_std_receipt)
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: params.row.std_id,
                          },
                        });
                      if (params.row.v_std_enquiry)
                        dispatch({
                          type: payloadTypes.SET_STD_ENQUIRY_ID,
                          payload: {
                            studentEnquiryId: params.row.std_id,
                          },
                        });
                      setStudentReceiptModal(!studentReceiptModal);
                    }
                  }}
                  disableRowSelectionOnClick
                  disableChildrenSorting
                  rowHeight={TABLE_ROW_HEIGHT}
                  pinnedRows={pinnedRows}
                  getCellClassName={getCellClassName}
                  hideFooter
                />
              </>
            ) : (
              <b className="nodata">Sorry, no results.</b>
            )}
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          <div className="feecollected__buttons">
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setExcelFlag(true);
              }}
              mode="export"
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
        <div className="col-2 feecollected__total">
          <StudentTotalCount totalCount={vouchers.length!} />
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentReceiptModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDemandList setModalFlag={setStudentReceiptModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentReceiptModal(!studentReceiptModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LedgerWiseReport;

// import React, { useEffect, useState } from "react";
// import useLoggedInUserDetails from "../../Modules/Accounts/hooks/useLoggedInUserDetails";
import { TeacherType, UserType } from "../../utils/Enum.types";
import useUserRightsByEmpId from "../../Modules/UserRights/hooks/useUserRightsByEmpId";
import { EMPTY_STRING } from "../../utils/constants";

const useTeacherType = () => {
  //   const [teacherType, setTeacherType] = useState(TeacherType.ONLY_TEACHER);
  const { userRights } = useUserRightsByEmpId();

  return userRights.data &&
    userRights.data.GetUserRightsByEmpId.pr_emp_details.emp_type ===
      UserType.TEACHER
    ? userRights.data.GetUserRightsByEmpId.is_inst_sysadmin
      ? TeacherType.GLOBAL_TEACHER
      : TeacherType.ONLY_TEACHER
    : EMPTY_STRING;
};

export default useTeacherType;

import {  TextField } from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { StudentTitles } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import Avatar from "../../../../images/Avatar.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import Edit from "../../../../images/Edit.svg";
import Close from "../../../../images/Close.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import StudentList from ".././List";
import {
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import ViewDetainedStudentList from "./ViewDetainedStudentList";
import { useNavigate } from "react-router-dom";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const { Students } = require("../../../../json/title.json");

const options = ["1", "2"];

const DetainedStudent = () => {
  const [studentModal, setStudentModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const navigate = useNavigate();
  const { branchLabel, classLabel, semesterLabel,categoryLabel } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Students.Titles.map((title: StudentTitles, index: React.Key) => {
          return (
            <React.Fragment key={index}>
              {title.Detained_Student}
            </React.Fragment>
          );
        })}
      </Title>
      <div className="detained-student-list">
        <div className="row g-0 detained-student-list__details">
          <div className="col">
            <div className="detained-student-list__details--image-field">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={options}
                openOnFocus
                onChange={(e) => {}}
                forcePopupIcon
                popupIcon={<img src={DownArrow} alt="/" />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Admission No."
                    fullWidth
                    id="outlined Admission No."
                    className={labelClasses.formControlRoot}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              label="Reg No."
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield"
              disabled
            />
            <TextField
              label={classLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield--year"
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield"
              disabled
            />

            <TextField
              label="Father Name"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield"
              disabled
            />
            <TextField
              label={branchLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield"
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label={semesterLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield"
              disabled
            />
            <TextField
               label={categoryLabel}
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield"
              disabled
            />
            <TextField
              label="Academic Year"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="detained-student-list__details--textfield"
              disabled
            />
          </div>
          <div className="col-1 detained-student-list__details--profile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="detained-student-list__block">
          <div className="row">
            <div className="col-4">
              <TextField
                label={classLabel}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="detained-student-list__details--textfield"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextField
                label="Reason"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="detained-student-list__details--textfield"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="button-left">
        <Button mode="save" />
        <Button mode="view" onClick={() => setViewModal(!viewModal)} />
        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      {/* student-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentReportType.GENERAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentModal(!studentModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* View-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewDetainedStudentList />
            <Button mode="cancel" onClick={() => setViewModal(!viewModal)} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setViewModal(!viewModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetainedStudent;

import Home from "../../Home/Index";
import AccountsHome from "../../../Accounts/Home/Index";
import { useTheme } from "@mui/material/styles";

import { Title } from "../../../../stories/Title/Title";
import ReportsList from "./Students/ReportType";

import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import GenerateReport from "./Students/GenerateReport";
import OtherOptions from "./Students/OtherOptions";
import { ExportProps } from "./Index";
import {
  ExcelReportPageType,
  ExportModuleType,
} from "../../../../utils/Enum.types";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { useEffect, useState } from "react";
import { ExcelAdmQueryType } from "./useExportMutation";

const MainPage = ({ reportType}: ExportProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { InstId } = useParams();
  const isAccounts = window.location.pathname.includes(
    ExportModuleType.ACCOUNTS
  );
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue) {
      navigate(`/${InstId}/accounts/reports/fee/voucher/0`);
    } else {
      navigate(`/${InstId}/accounts/reports/fee/balance`);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (window.location.pathname.includes("voucher")) {
      setValue(1);
    }
  }, [window.location.pathname.includes("voucher")]);

  return (
    <>
      {isAccounts ? (
        <AccountsHome DashBoardRequired={false} />
      ) : (
        <Home DashBoardRequired={false} />
      )}
      <Title>Institution Level Report</Title>
      <div className="inst-level-report">
        {isAccounts ? (
          <div className="inst-level-report__tabs">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Students Report" value={0} {...a11yProps(0)} />
              <Tab label="Vouchers" value={1} {...a11yProps(1)} />
            </Tabs>
          </div>
        ) : null}
        <div className= {reportType === ExcelAdmQueryType.ADM_STUDENT_LIST ? "inst-level-report__tabpanel--wp" :"inst-level-report__tabpanel"} >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div className="inst-level-report__datablock">
              <div className="inst-level-report__lt">
                <ReportsList
                  reportType={reportType}
                  pageType={ExcelReportPageType.STUDENT}
                />
              </div>
              <GenerateReport
                reportType={reportType}
                pageType={ExcelReportPageType.STUDENT}
              />
              <OtherOptions
                reportType={reportType}
                pageType={ExcelReportPageType.STUDENT}
              />
            </div>
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <div className="inst-level-report__datablock">
              <div className="inst-level-report__lt">
                <ReportsList
                  reportType={reportType}
                  pageType={ExcelReportPageType.VOUCHER}
                />
              </div>
              <GenerateReport
                reportType={reportType}
                pageType={ExcelReportPageType.VOUCHER}
              />
              <OtherOptions
                reportType={reportType}
                pageType={ExcelReportPageType.VOUCHER}
              />
            </div>
          </TabPanel>
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default MainPage;

import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AccountGroupLdgrsTypeByInstId } from "../queries/GroupLedgers/query/ById";

import { TreeView } from "@mui/x-tree-view/TreeView";

import {
  CollapseIcon,
  CustomTreeItem,
  EndIcon,
  ExpandIcon,
 
} from "../../../pages/TreeView";
import useToken from "../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../utils/constants";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
export interface GetAcctGroupLdgrsByInstIdByType {
  current_asset: [];
  expenses: [];
  liabilities: [];
  fixed_asset: [];
}

export interface GetAcctGroupLdgrsByInstIdByTypeData {
  GetAcctGroupLdgrsByInstIdByType: GetAcctGroupLdgrsByInstIdByType[];
}

export interface GetAcctGroupLdgrsByInstIdByTypeVars {
  token: string;
  inst_id: string;
}

const Tree = () => {
  const { InstId } = useParams();

  const { token } = useToken();
  const [GetGroupLedgerTree, { data, loading, error }] = useLazyQuery(
    AccountGroupLdgrsTypeByInstId,
    {
      variables: { token, inst_id: InstId! },
    }
  );
  const { fixed_asset, current_asset, income, expenses, liabilities } =
    (!loading && data?.GetAcctGroupLdgrsByInstIdByType) ?? [];
  useEffect(() => {
    if (token && InstId) {
      GetGroupLedgerTree();
    }
  }, [token, InstId, GetGroupLedgerTree]);
  if (error) return <>{error.message}</>;
  return (
    <div className="tree-view">
      <SimpleTreeView
        aria-label="customized"
        defaultExpandedItems={["1"]}
        slots={{
          expandIcon: ExpandIcon,
          collapseIcon: CollapseIcon,
          endIcon: EndIcon,
        }}        className="departmenttreeview"
      >
        <CustomTreeItem itemId="1" label="Fixed Assets">
          {fixed_asset &&
            fixed_asset.map(({ gr_ldgr_desc, id, acct_ldgr }: any) => {
              return (
                <React.Fragment key={id}>
                  <CustomTreeItem itemId={id.toString()} label={gr_ldgr_desc}>
                    {acct_ldgr.map((data: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <CustomTreeItem
                            itemId={data.id.toString()}
                            label={
                              data.ldgr_desc === "null" || EMPTY_STRING
                                ? data.ldgr_std_fee_short_desc
                                : data.ldgr_desc
                            }
                          />
                        </React.Fragment>
                      );
                    })}
                  </CustomTreeItem>
                </React.Fragment>
              );
            })}
        </CustomTreeItem>
        <CustomTreeItem itemId="2" label="Current Assets">
          {current_asset &&
            current_asset.map(({ gr_ldgr_desc, id, acct_ldgr }: any) => {
              return (
                <React.Fragment key={id}>
                  <CustomTreeItem itemId={id.toString()} label={gr_ldgr_desc}>
                    {acct_ldgr.map((data: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <CustomTreeItem
                            itemId={data.id.toString()}
                            label={
                              data.ldgr_desc === "null" || EMPTY_STRING
                                ? data.ldgr_std_fee_short_desc
                                : data.ldgr_desc
                            }
                          />
                        </React.Fragment>
                      );
                    })}
                  </CustomTreeItem>
                </React.Fragment>
              );
            })}
        </CustomTreeItem>

        <CustomTreeItem itemId="3" label="Income">
          {income &&
            income.map(({ gr_ldgr_desc, id, acct_ldgr }: any) => {
              return (
                <React.Fragment key={id}>
                  <CustomTreeItem itemId={id.toString()} label={gr_ldgr_desc}>
                    {acct_ldgr.map((data: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <CustomTreeItem
                            itemId={data.id.toString()}
                            label={
                              data.ldgr_desc === "null" || EMPTY_STRING
                                ? data.ldgr_std_fee_short_desc
                                : data.ldgr_desc
                            }
                          />
                        </React.Fragment>
                      );
                    })}
                  </CustomTreeItem>
                </React.Fragment>
              );
            })}
        </CustomTreeItem>

        <CustomTreeItem itemId="4" label="Expenses">
          {expenses &&
            expenses.map(({ gr_ldgr_desc, id, acct_ldgr }: any) => {
              return (
                <React.Fragment key={id}>
                  <CustomTreeItem itemId={id.toString()} label={gr_ldgr_desc}>
                    {acct_ldgr.map((data: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <CustomTreeItem
                            itemId={data.id.toString()}
                            label={
                              data.ldgr_desc === "null" || EMPTY_STRING
                                ? data.ldgr_std_fee_short_desc
                                : data.ldgr_desc
                            }
                          />
                        </React.Fragment>
                      );
                    })}
                  </CustomTreeItem>
                </React.Fragment>
              );
            })}
        </CustomTreeItem>
        <CustomTreeItem itemId="5" label="Liabilities">
          {liabilities &&
            liabilities.map(({ gr_ldgr_desc, id, acct_ldgr }: any) => {
              return (
                <React.Fragment key={id}>
                  <CustomTreeItem itemId={id.toString()} label={gr_ldgr_desc}>
                    {acct_ldgr.map((data: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <CustomTreeItem
                            itemId={data.id.toString()}
                            label={
                              data.ldgr_desc === "null" || EMPTY_STRING
                                ? data.ldgr_std_fee_short_desc
                                : data.ldgr_desc
                            }
                          />
                        </React.Fragment>
                      );
                    })}
                  </CustomTreeItem>
                </React.Fragment>
              );
            })}
        </CustomTreeItem>
      </SimpleTreeView>
    </div>
  );
};

export default Tree;

import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DataFetch from "../../../../images/Edit.svg";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import Enter from "../../../../images/Enter.svg";
import { useNavigate, useParams } from "react-router";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import ItemDetails from "../Details/Index";
import Close from "../../../../images/Close.svg";
import { Direction, Operation, PageFor } from "../../../../utils/Enum.types";
import View from "./View";
import LedgerList from "./LedgerList";
import usePurchaseItems, {
  PuchanseItemQueryType,
} from "../hooks/usePurchaseItems";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  DateRange,
  formatter,
  handleFormEvent,
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { useMutation } from "@apollo/client";
import { AddAcctPurchaseMasterAndDetails } from "../../queries/receipts/mutation/Index";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { GetAcctPurchases } from "../../queries/receipts/list";
import { purchaseEntryQueryType } from "../hooks/usePurchaseEntry";
import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";

interface IItem {
  name: string;
  id: number;
  uom: string;
  quantity: number;
  rate: number;
  gst: number;
  taxableAmount: number;
  tax: number;
  net: number;
}
const supplierType = [
  { label: "Sundry Debitor", value: "SUNDRY_DEBITORS" },
  { label: "Sundry Creditor", value: "SUNDRY_SUPPLIERS" },
];
const Index = () => {
  const navigate = useNavigate();

  const { Accounts_Table } = useAcctTableJson();
  const { format } = formatter;

  const { dispatch, state } = useContext(AppContext);

  const { token } = useToken();
  const { InstId } = useParams();
  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [ledgerList, setLedgerList] = useState(false);
  const [edit, setedit] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [queryType, setQueryType] = useState(supplierType[0]);

  const [items, setItems] = useState<IItem[]>([]);
  const [id, setId] = useState(0);
  const [itemId, setItemId] = useState<responseType>(EMPTY_RESPONSETYPE_OBJECT);
  const [quantity, setQuantity] = useState(0);
  const [rate, setRate] = useState(0);
  const [gst, setGst] = useState(0);
  const [tax, setTax] = useState(0);
  const [netamt, setNetAmt] = useState(0);
  const [taxableAmt, setTaxableAmt] = useState(0);
  const [discountAmt, setDiscountamt] = useState(0);
  const [extraCharges, setExtraCharges] = useState(0);

  const [supplierName, setSupplierName] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [inVoiceDate, setInVoiceDate] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [orderDate, setOrderDate] = useState("");

  const [purchasedBy, setPurchasedBy] = useState("");

  const [remarks, setRemarks] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();

  const { Items } = usePurchaseItems(
    PuchanseItemQueryType.ALL_ITEMS,
    searchItem
  );

  const { acctLedgers } = useAcctLedgerData(
    supplierName,
    queryType.value,
    ROWS_PER_PAGE
  );

  const filterdData = acctLedgers.data?.GetAcctLdgrs.edges.find(
    (e) => e.node.id === state.accountLedgerId
  );

  const { data: serverDate, loading: serverDateLoading } =
    useServerDateandTime();

  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feeDescRef);
  const dueDateRef = useRef<HTMLInputElement>(null);
  const feeAllRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const feeFresherRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const [CreatepurchaseEntry] = useMutation(AddAcctPurchaseMasterAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const CalculateTax = () => {
    setTax((quantity * rate * gst) / 100);
    return (quantity * rate * gst) / 100;
  };
  const CaluculateNetAmt = () => {
    const res = quantity * rate + (quantity * rate * gst) / 100;
    setNetAmt(res);

    return res;
  };

  const CalculateTaxbleAmt = () => {
    const res = quantity * rate;
    setTaxableAmt(res);

    return res;
  };

  const { totalnet, totalquantity, totaltax, totaltaxableAmount } =
    items.reduce(
      (acc, item) => {
        acc.totalquantity += item.quantity;
        acc.totaltaxableAmount += item.taxableAmount;
        acc.totaltax += item.tax;
        acc.totalnet += item.net;

        return acc;
      },
      {
        totalquantity: 0,
        totaltaxableAmount: 0,
        totaltax: 0,
        totalnet: 0,
      }
    );

  const handleItems = () => {
    const itemAlreadyExists = items.filter((d) => d.id === itemId.value);
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      setItemId(EMPTY_RESPONSETYPE_OBJECT);
      setGst(0);
      setQuantity(0);
      setRate(0);
      setTax(0);
      setTaxableAmt(0);
      setNetAmt(0);
      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }

    const res = Items.data?.GetAcctStockItems.edges.find(
      ({ node: { id } }) => id === itemId.value
    );
    if (itemId.value && rate && quantity && gst && res)
      setItems((items) => [
        ...items,
        {
          gst,
          id: res.node.id,
          name: res.node.item_desc,
          quantity,
          rate,
          uom: res.node.item_uom_details.item_uom_desc,
          taxableAmount: CalculateTaxbleAmt(),
          tax: CalculateTax(),
          net: CaluculateNetAmt(),
        },
      ]);
    setItemId(EMPTY_RESPONSETYPE_OBJECT);
    setGst(0);
    setQuantity(0);
    setRate(0);
    setTax(0);
    setTaxableAmt(0);
    setNetAmt(0);
    feeDescInputRef?.select();
    feeDescInputRef?.focus();
  };
  const handleItemDeleted = (ids: number) => {
    const updatedItems = items.filter((item) => item.id !== ids);

    setItems(updatedItems);
  };

  const handleItemEdit = (ids: number) => {
    const filterdData = items?.find(({ id }) => id === ids);
    const res = Items.data?.GetAcctStockItems.edges.find(
      ({ node: { id } }) => id === ids
    );
    if (filterdData && res) {
      setItemId({ label: res.node.item_desc, value: res.node.id });
      setGst(filterdData.gst);
      setQuantity(filterdData.quantity);
      setRate(filterdData.rate);
      setTax(filterdData.tax);
      setTaxableAmt(filterdData.taxableAmount);
      setNetAmt(filterdData.net);
    }
  };
  const HandleEditItem = (id: number) => {
    const res = Items.data?.GetAcctStockItems.edges.find(
      ({ node: { id } }) => id === itemId.value
    );
    if (res) {
      const updatedData = items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            gst,
            id: res.node.id,
            name: res.node.item_desc,
            quantity,
            rate,
            uom: res.node.item_uom_details.item_uom_desc,
            taxableAmount: CalculateTaxbleAmt(),
            tax: CalculateTax(),
            net: CaluculateNetAmt(),
          };
        }
        return item;
      });
      feeDescInputRef?.focus();

      setItems(updatedData);
      setItemId(EMPTY_RESPONSETYPE_OBJECT);
      setGst(0);
      setQuantity(0);
      setRate(0);
      setTax(0);
      setTaxableAmt(0);
      setNetAmt(0);
      setedit(false);
      feeDescInputRef?.select();
      feeDescInputRef?.focus();
    }
  };
  const handleSave = () => {
    CreatepurchaseEntry({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: {
          purchase_master: {
            pur_inv_no: invoiceNo,
            pur_inv_date: toIsoDate(inVoiceDate),
            pur_total_taxable: totaltaxableAmount,
            pur_tax_amt: totaltax,
            pur_sec_discount: discountAmt,
            pur_freight: extraCharges,
            pur_others: 0,
            pur_round_off: 0,
            pur_amt: totalnet - discountAmt + extraCharges,
            pur_item_count: items.length,
            pur_item_qty: totalquantity,
            pur_for_department: purchasedBy,
            pur_remarks: remarks,
            pur_reconciled: false,
            pur_payment_done: false,
            pur_paid_amt: 0,
            pur_balance_amt: 0,
            pur_full_payment_done: false,
            pur_type: "pur_type",
            pur_order_no: orderNo,
            pur_order_date: toIsoDate(orderDate),
            acct_ldgr_id: state.accountLedgerId,
          },
          purchase_details: items.map((item, index) => ({
            sl_no: index + 1,
            pur_qty: item.quantity,
            pur_rate: item.rate,
            pur_amt: 0,
            pur_tax: item.tax,
            pur_total_amt: item.net,
            pur_item_serial_no: "000",
            pur_item_remarks: remarks,
            acct_stock_item_id: item.id,
          })),
        },
      },
      refetchQueries: [
        {
          query: GetAcctPurchases,
          variables: {
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            //   itemName: "",
            orderBy: { direction: Direction.ASC },
            token,
            input: {
              acct_ldgr_id: 0,
              inst_id: InstId!,
              pur_end_date: toIsoDate(lastDay!),
              pur_start_date: toIsoDate(firstDay!),
              purchase_query_type: purchaseEntryQueryType.ALL_PURCHASES,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Purchase Entry Done",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClear = () => {
    setSupplierName("");
    setInvoiceNo("");
    setOrderNo("");
    setItems([]);
    setDiscountamt(0);
    setExtraCharges(0);
    setPurchasedBy("");
    setRemarks("");
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (serverDate && !serverDateLoading) {
      setInVoiceDate(toInputStandardDate(serverDate.GetServerDateAndTime));
      setOrderDate(toInputStandardDate(serverDate.GetServerDateAndTime));
    }
  }, [serverDate, serverDateLoading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Purchase Entry</Title>
      <div className="purchase-entry">
        <div className="purchase-entry__filters row g-0">
          <div className="col">
            <div className="purchase-entry__filters--flex">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={acctLedgers.responseType}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                      payload: {
                        accountLedgerId: (newValue as responseType).value,
                      },
                    });
                  }
                }}
                // onKeyDown={(e) => {
                //   if (e.key === Keys.ENTER) {
                //     handleMUISelectEvent(e);
                //   }
                // }}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    {...params}
                    label="Supplier Name"
                    fullWidth
                    onChange={(e) => setSupplierName(e.target.value)}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={supplierType}
                value={queryType}
                onChange={(e, newValue) => {
                  if (newValue) setQueryType(newValue as optionsType);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Supplier Type"
                    fullWidth
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <img
                src={DataFetch}
                alt=""
                className="data-fetch-icon"
                onClick={() => setLedgerList(!ledgerList)}
              />
            </div>

            <div className="purchase-entry__filters--flex">
              <TextField
                label="GST No."
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="purchase-entry__filters--textfield"
                disabled
                value={filterdData?.node.ldgr_sundry_gst_no ?? EMPTY_STRING}
              />
              <TextField
                label="Mobile No."
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="purchase-entry__filters--textfield"
                disabled
                value={
                  filterdData?.node.ldgr_sundry_contact_person_mobile ??
                  EMPTY_STRING
                }
              />
            </div>
            <TextField
              label="Address"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className="purchase-entry__filters--textfield"
              disabled
              value={filterdData?.node.ldgr_sundry_address ?? EMPTY_STRING}
            />
          </div>
          <form className="col">
            <div className="purchase-entry__filters--flex">
              <TextField
                label="Invoice No."
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="purchase-entry__filters--textfields"
                onChange={(e) => setInvoiceNo(e.target.value)}
                value={invoiceNo}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && invoiceNo) {
                    handleFormEvent(e);
                  }
                }}
              />
              <TextField
                label="Invoice Date"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="purchase-entry__filters--date"
                type="date"
                value={inVoiceDate}
                onChange={(e) => setInVoiceDate(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && inVoiceDate) {
                    handleFormEvent(e);
                  }
                }}
              />
            </div>
            <div className="purchase-entry__filters--flex">
              <TextField
                label="Purchase Order No."
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="purchase-entry__filters--textfields"
                onChange={(e) => setOrderNo(e.target.value)}
                value={orderNo}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && orderNo) {
                    handleFormEvent(e);
                  }
                }}
              />
              <TextField
                label="Purchase Order Date"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className="purchase-entry__filters--date"
                type="date"
                value={orderDate}
                onChange={(e) => setOrderDate(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && orderDate) {
                    feeDescInputRef?.focus();
                  }
                }}
              />
            </div>
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={["a"]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Procured for"
                  fullWidth
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </form>
          <div className="col-2">
            <Button mode="addnew" onClick={() => setAddModal(!addModal)}>
              &nbsp;New Item
            </Button>
          </div>
        </div>
        <div className="purchase-entry__tableblock">
          <TableContainer className="purchase-entry__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.PurchaseEntry.Invoice.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        className="purchase-entry__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>PCS</TableCell>
                      <TableCell id="td-center">
                        {format(item.quantity)}
                      </TableCell>
                      <TableCell id="td-right">{format(item.rate)}</TableCell>
                      <TableCell id="td-right">
                        {format(item.taxableAmount)}
                      </TableCell>
                      <TableCell id="td-center">{format(item.gst)} %</TableCell>
                      <TableCell id="td-right">{format(item.tax)}</TableCell>
                      <TableCell id="td-right" className="font-green">
                        {format(item.net)}
                      </TableCell>
                      <TableCell id="td-center">
                        <img
                          src={Edit}
                          alt=""
                          onClick={() => {
                            handleItemEdit(item.id);
                            setedit(!edit);

                            setId(item.id);
                          }}
                        />
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => handleItemDeleted(item.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow className="purchase-entry__table--row">
                  <TableCell
                    className="purchase-entry__table--slno"
                    id="td-center"
                  >
                    {items.length + 1}
                  </TableCell>
                  <TableCell>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      ref={feeDescRef}
                      value={itemId}
                      options={Items.purchaseItemResponseType}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setItemId(newValue as responseType);
                        } else {
                          setItemId(EMPTY_RESPONSETYPE_OBJECT);
                        }
                      }}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (dueDateRef.current && itemId.value) {
                            dueDateRef.current?.focus();
                            dueDateRef.current?.select();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setItemId(EMPTY_RESPONSETYPE_OBJECT);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          onChange={(e) => setSearchItem(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className="purchase-entry__table--desc">
                    <Input disabled />
                  </TableCell>
                  <TableCell
                    className="purchase-entry__table--number"
                    id="td-right"
                  >
                    <Input
                      inputRef={dueDateRef}
                      value={quantity}
                      id="td-right"
                      type="number"
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER && quantity) {
                          feeAllRef.current?.focus();
                          feeAllRef.current?.select();
                        }
                      }}
                      onChange={(e) => setQuantity(Number(e.target.value))}
                    />
                  </TableCell>
                  <TableCell
                    className="purchase-entry__table--number"
                    id="td-right"
                  >
                    <Input
                      inputRef={feeAllRef}
                      value={rate}
                      type="number"
                      onChange={(e) => setRate(Number(e.target.value))}
                      id="td-right"
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (rate) {
                            CalculateTaxbleAmt();
                            feeFresherRef.current?.focus();
                            feeFresherRef.current?.select();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) setRate(0);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className="purchase-entry__table--number"
                    id="td-right"
                  >
                    <Input disabled value={taxableAmt} id="td-right" />
                  </TableCell>
                  <TableCell
                    className="purchase-entry__table--number"
                    id="td-right"
                  >
                    <Input
                      placeholder="%"
                      inputRef={feeFresherRef}
                      id="td-right"
                      value={gst}
                      onChange={(e) => setGst(Number(e.target.value))}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (gst) {
                            buttonRef.current?.focus();
                            CalculateTax();
                            CaluculateNetAmt();
                          }
                        } else if (e.key === Keys.BACKSPACE) setGst(0);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className="purchase-entry__table--number"
                    id="td-right"
                  >
                    <Input disabled value={tax} id="td-right" />
                  </TableCell>
                  <TableCell
                    className="purchase-entry__table--number"
                    id="td-right"
                  >
                    <Input disabled value={netamt} id="td-right" />
                  </TableCell>
                  <TableCell
                    id="td-center"
                    className="purchase-entry__table--actions"
                  >
                    <button
                      ref={buttonRef}
                      onClick={(e: React.FormEvent) => {
                        edit ? HandleEditItem(id) : handleItems();
                      }}
                    >
                      {edit ? "Save" : <img src={Enter} alt="" />}
                    </button>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={3} className="total">
                    Total :
                  </TableCell>
                  <TableCell className="totalcount">{totalquantity}</TableCell>
                  <TableCell></TableCell>
                  <TableCell className="totalcount" id="td-right">
                    {format(totaltaxableAmount)}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell className="totalcount" id="td-right">
                    {format(totaltax)}
                  </TableCell>
                  <TableCell className="totalcount" id="td-right">
                    {format(totalnet)}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <form className="row g-0 purchase-entry__datablock">
          <div className="col purchase-entry__datablock--frame">
            <div className="label-grid">
              <Label>Purchased By</Label>
              <Input
                value={purchasedBy}
                onChange={(e) => setPurchasedBy(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleFormEvent(e);
                  }
                }}
              />
            </div>
            <div className="label-grid">
              <Label>Narration</Label>
              <TextArea
                rows={2}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleFormEvent(e);
                  }
                }}
              />
            </div>
          </div>
          <div className="col"></div>
          <div className="col-4 purchase-entry__datablock--details">
            <div className="purchase-entry__datablock--details--label-grid">
              <Label>Secondary Discount Amount : </Label>
              <Input
                value={discountAmt}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleFormEvent(e);
                  }
                }}
                onChange={(e) => setDiscountamt(Number(e.target.value))}
              />
              <Label>Frieght / Extra Charges : </Label>
              <Input
                type="number"
                onChange={(e) => setExtraCharges(Number(e.target.value))}
                value={extraCharges}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    saveButtonRef.current?.focus();
                  }
                }}
              />
              <Label>Net Total : </Label>
              <Input
                id="bg-green"
                disabled
                value={format(totalnet - discountAmt + extraCharges)}
              />
            </div>
          </div>
        </form>
        <Button mode="save" buttonref={saveButtonRef} onClick={handleSave} />
        <Button mode="edit" />
        <Button mode="view" onClick={() => setViewModal(!viewModal)} />
        <Button mode="clear" onClick={handleClear} />
        <Button mode="delete" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={ledgerList}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LedgerList setModalFlag={setLedgerList} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setLedgerList(!ledgerList)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ItemDetails pageType={PageFor.MODAL} setModalFlag={setAddModal} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setAddModal(!addModal)} />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View setModalFlag={setViewModal} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;

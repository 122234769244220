import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { GetAcdTestClassSubjects } from "../queries/test/query";
import { GetAcdTestClassDetails } from "./useTestClassData";
import { responseType } from "../../../utils/Form.types";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

export interface GetAcdTestSubjectsByTestConductIdData {
  node: GetAcdTestClassDetails;
}
export enum TestClassSubjectQueryType {
  TO_ADD_MARKS = "TO_ADD_MARKS",
  TO_UPDATE_MARKS = "TO_UPDATE_MARKS",
}
export interface GetAcdTestClassSubjectsVars {
  token: string;
  acd_test_classes_id: number;
  inst_id: number | string;
  acd_yr_id: number;
  pr_emp_id: number;
  entry_level: string;
  entry_id: number;
  query_type: TestClassSubjectQueryType;
  per_std_subj_allocation: boolean;
}

export interface GetAcdTestClassSubjectsData {
  GetAcdTestClassSubjects: {
    subj_total_max_marks: number;
    subj_total_min_marks: number;
    subj_master_id: number;
    subj_is_elective: boolean;
    test_class_details: {
      test_start_date: string;
      test_end_date: string;
      is_non_academic: boolean;
      enable_teachers_comments: boolean;
      test_name_details: {
        test_name: string;
        show_marks_in_grade: boolean;
        add_test_remarks: boolean;
        test_type_details: {
          test_type_name: string;
        };
      };
    };
    subject_master_details: {
      id: number;
      subj_code: string;
      subj_desc: string;
    };
  }[];
}

const useTestClassSubjects = (
  query_type: TestClassSubjectQueryType,
  per_std_subj_allocation: boolean
) => {
  const { testId, InstId, entryId } = useParams();

  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { entry_level } = useInstitutionConfiguration();

  const [subjectsOptions, setSubjectOptions] = useState<responseType[]>([]);
  const [GetAcdSubjects, { data, loading, error }] = useLazyQuery<
    GetAcdTestClassSubjectsData,
    GetAcdTestClassSubjectsVars
  >(GetAcdTestClassSubjects, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_classes_id: state.testConductId || Number(testId),
      pr_emp_id: state.empLoginId,
      entry_level,
      entry_id: entryId ? Number(entryId) : 0,
      query_type,
      per_std_subj_allocation,
    },
  });

  useEffect(() => {
    if (
      (state.testConductId || testId) &&
      token &&
      entry_level &&
      entryId &&
      InstId &&
      state.ActiveAcdYr
    ) {
      GetAcdSubjects().then(({ data }) => {
        if (data) {
          setSubjectOptions(
            data.GetAcdTestClassSubjects.map((subject) => ({
              label: `${subject.subject_master_details.subj_desc}(${subject.subject_master_details.subj_code})`,
              value: subject.subject_master_details.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [
    state.testConductId,
    GetAcdSubjects,
    testId,
    token,
    entry_level,
    entryId,
    InstId,
    state.ActiveAcdYr,
  ]);
  return { testConductDetails: { data, loading, error, subjectsOptions } };
};

export default useTestClassSubjects;

import React, { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import {
  ConfigListKey,
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  SortBy,
  TestMarksDerivedMethod,
} from "../../../utils/Enum.types";
import AllotedLevelConfigs from "../Components/AllotedLevelConfigs";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetAcdTestNameByIdData } from "../Test/TestCreation/Index";
import { singleNodeVars } from "../../../Types/Accounting";
import {
  GetAcdTestClassesForDerived,
  GetAcdTestNameById,
} from "../queries/test/query";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import {
  EMPTY_STRING,
  MULTI_SELECT_LIMIT_TAGS,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../utils/constants";
import { Button } from "../../../stories/Button/Button";
import DerivativeLogo from "../../../images/DerivativeLogo.svg";
import { Label } from "../../../stories/Label/Label";
import {  Checkbox, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { AddNewTestDerived, msgType, optionsType, responseType } from "../../../utils/Form.types";
import useConfigListOptionsByListKey from "../../../customhooks/useConfigListOptionsByListKey";
import { Keys } from "../../../utils/Enum.keys";
import InputNumber from "../../../components/common/Input/InputNumber";
import {
  AddAcdDerivedTestForClass,
  UpdateAcdDerivedTestForClass,
} from "../queries/test/mutation";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { TestDerivationValidation } from "../../../utils/academicValidation";
import MessageModal from "../../../pages/MessageModal";
import useTestClassData, {
  AcdTestConductQueryType,
  TestConductData,
  TestConductVars,
} from "../hooks/useTestClassData";
import { AppContext } from "../../../context/context";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { userDetails } from "../../../Types/Accounting/other";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
import { labelClasses } from "../../../styles/AutocompleteListStyles";
import { LabeledAutocomplete } from "../../../styles/AutocompleteListStyles";

interface Props {
  operation: Operation;
}

interface AcdDerivedTestData {
  test_name_id: string;
  allotted_level: string;
  allotted_id: string;
  out_of: string;
  derived_method: string;
  avg_best_of_num: string;
  derive_test_ids: number[];
}

interface UpdateAcdDerivedTestForClassVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  acd_yr_id: number;
  test_class_id: number;
  input: AcdDerivedTestData;
}

interface UpdateAcdDerivedTestForClassResult {
  UpdateAcdDerivedTestForClass: boolean;
}
const Derivative = ({ operation }: Props) => {
  const { token } = useToken();

  const { testId, InstId, allotedID } = useParams();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const { state } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [test_class_id, set_test_class_id] = useState(0);

  const {
    InstConfigDetails: { alloted_level },
  } = useInstConfigByEntryId(0);

  const {
    testConductDetails: { responseType: acdTestClassOptions },
  } = useTestClassData(
    0,
    Number(allotedID),
    AcdTestConductQueryType.ALL_TESTS_BY_ACD_ALLOTTED_LEVEL
  );

  const [GetAcdTestDerivedClass] = useLazyQuery<
    TestConductData,
    TestConductVars
  >(GetAcdTestClassesForDerived, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      after: null,
      first: ROWS_PER_PAGE,
      input: {
        acd_test_class_query: AcdTestConductQueryType.BY_ACD_TEST_NAME,
        alloted_level: configData.data
          ? configData.data.GetSwConfigVariables[0].config_string_value
          : EMPTY_STRING,
        ids: [Number(InstId), Number(testId), Number(allotedID)],
      },
      inst_id: InstId!,
      orderBy: {
        direction: Direction.DESC,
        field: SortBy.TEST_START_DATE,
      },
    },
  });
  const { flag } = useCheckAllocationType();

  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [AddTestDeravative] = useMutation(AddAcdDerivedTestForClass, {
    onError: (e) => {
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      });
    },
  });
  const [UpdateTestDeravative] = useMutation<
    UpdateAcdDerivedTestForClassResult,
    UpdateAcdDerivedTestForClassVars
  >(UpdateAcdDerivedTestForClass, {
    onError: (e) => {
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      });
    },
  });
  const AllTests = {
    label: "All Test",
    value: -52,
    isCheked: false,
  };

  const { configOptions } = useConfigListOptionsByListKey(
    ConfigListKey.ACD_TEST_MARKS_DERIVED_METHOD
  );

  const [formData, setFormData] = useState<AddNewTestDerived>({
    avg_best_of_num: "0",
    derive_test_ids: [],
    derived_method: "",
    out_of: "0",
  });
  const [GetTestTypeDetails, { data: TestNameData }] = useLazyQuery<
    GetAcdTestNameByIdData,
    singleNodeVars
  >(GetAcdTestNameById);
  useEffect(() => {
    if (
      operation === Operation.UPDATE &&
      state.ActiveAcdYr &&
      configData.data &&
      testId &&
      InstId
    ) {
      GetAcdTestDerivedClass().then(({ data }) => {
        if (
          data &&
          data.GetAcdTestClass &&
          data.GetAcdTestClass.edges.length === 1
        ) {
          const testClassDetails = data.GetAcdTestClass.edges[0];
          setFormData({
            avg_best_of_num: testClassDetails.node.avg_best_of_num.toString(),
            derive_test_ids: testClassDetails.node.derive_tests.map(
              (test) => test.derive_test_id
            ),
            derived_method: testClassDetails.node.derived_method,
            out_of: testClassDetails.node.out_of.toString(),
          });
          set_test_class_id(testClassDetails.node.id);
        }
      });
    }
  }, [
    state.ActiveAcdYr,
    GetAcdTestDerivedClass,
    configData.data,
    testId,
    operation,
    InstId,
  ]);

  useEffect(() => {
    if (testId && token) {
      GetTestTypeDetails({
        variables: { id: testId ? Number(testId) : 0, token },
      });
    }
  }, [testId, GetTestTypeDetails, token]);

  const hanldeSubmit = () => {
    if (formData.derive_test_ids.length === 0) {
      alert("Test not Selected");
      return;
    }
    if (formData.derived_method === EMPTY_STRING) {
      alert("Derivative method not Selected");
      return;
    }

    if (operation === Operation.CREATE) {
      AddTestDeravative({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          acd_yr_id: activeAcademicYearData.data
            ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
            : 0,
          input: {
            test_name_id: testId,
            allotted_level: alloted_level,
            allotted_id: allotedID,
            out_of: formData.out_of.length ? formData.out_of : 0,
            derived_method: formData.derived_method,
            derive_test_ids: formData.derive_test_ids,
            avg_best_of_num: formData.avg_best_of_num.length
              ? formData.avg_best_of_num
              : 0,
          },
          per_std_subj_allocation: flag ? flag : false,
        },
      }).then(({ data }) => {
        if (data && data.AddAcdDerivedTestForClass) {
          setMessage({
            flag: true,
            message: "Deravative Successfully added",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateTestDeravative({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          acd_yr_id: activeAcademicYearData.data
            ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
            : 0,
          test_class_id: test_class_id,
          input: {
            test_name_id: testId!,
            allotted_level: alloted_level,
            allotted_id: allotedID!,
            out_of: formData.out_of.length ? formData.out_of : "0",
            derived_method: formData.derived_method,
            derive_test_ids: formData.derive_test_ids,
            avg_best_of_num: formData.avg_best_of_num.length
              ? formData.avg_best_of_num
              : "0",
          },
        },
      }).then(({ data }) => {
        if (data && data.UpdateAcdDerivedTestForClass) {
          setMessage({
            flag: true,
            message: "Deravative Successfully added",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const hanldeClear = () => {
    setFormData({
      avg_best_of_num: "0",
      derive_test_ids: [],
      derived_method: "",
      out_of: "0",
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      if (message.operation === Operation.CREATE) hanldeClear();
    }
    setMessage(emptyMessageType);
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Test Derivative Planning</Title>
      <div className="derivative-plan">
        <AllotedLevelConfigs />
        <div className="derivative-plan__title">
          <Title>
            {TestNameData ? TestNameData.node.test_name : EMPTY_STRING}
          </Title>
          <span className="derivative-plan__title--derivativespan">
            Derivative
          </span>
        </div>
        <div className="derivative-plan__details">
          <div className="derivative-plan__details--image">
            <img src={DerivativeLogo} alt="/" />
          </div>
          <div className="derivative-plan__details--add">
            <Title>
              {operation === Operation.CREATE
                ? "Add derivative"
                : "Update derivative"}
            </Title>
            <Formik
              initialValues={formData}
              validationSchema={TestDerivationValidation}
              enableReinitialize
              validateOnChange
              onSubmit={hanldeSubmit}
            >
              {(meta) => {
                return (
                  <Form className="derivative-plan__details--add--form">
                    <div className="label-grid multi-select">
                      <Label>Tests</Label>
                      <LabeledAutocomplete
                        className={labelClasses.inputRoot}
                        limitTags={MULTI_SELECT_LIMIT_TAGS}
                        multiple
                        options={[...acdTestClassOptions]}
                        getOptionLabel={(option) => {
                          return (option as responseType).label;
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={
                                  formData.derive_test_ids.includes(
                                    (option as responseType).value
                                  ) ||
                                  ((option as responseType).value === -52 &&
                                    acdTestClassOptions.length ===
                                      formData.derive_test_ids.length &&
                                    acdTestClassOptions.length !== 0)
                                }
                              />
                              {(option as responseType).label ? (option as responseType).label : ""}
                            </li>
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          (option as responseType).value === (value as responseType).value
                        }
                        openOnFocus
                        value={acdTestClassOptions.filter(({ value }) =>
                          formData.derive_test_ids.includes(value)
                        )}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            if (
                              (newValue as responseType[]).map(({ value }) => value).includes(-52)
                            ) {
                              setFormData((prev) => ({
                                ...prev,
                                derive_test_ids: [
                                  ...formData.derive_test_ids,
                                  ...(newValue as responseType[]).map((value) => value.value),
                                ],
                              }));
                            } else {
                              setFormData((prev) => ({
                                ...prev,
                                derive_test_ids: [
                                  ...(newValue as responseType[]).map((value) => value.value),
                                ],
                              }));
                            }
                          } else {
                            setFormData((prev) => ({
                              ...prev,
                              derive_test_ids: [],
                            }));
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              // value={selectedTests}
                              fullWidth
                                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                              className={labelClasses.formControlRoot}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="label-grid ">
                      <Label>Calculation Type</Label>
                      <LabeledAutocomplete
                        className={labelClasses.inputRoot}
                        options={configOptions}
                        openOnFocus
                        value={
                          configOptions.find(
                            ({ value }) => value === formData.derived_method
                          ) ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              derived_method: (newValue as optionsType).value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              derived_method: EMPTY_STRING,
                            }));
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              derived_method: EMPTY_STRING,
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                            className={labelClasses.formControlRoot}
                          />
                        )}
                      />
                    </div>
                    {formData.derived_method ===
                    TestMarksDerivedMethod.AVERAGE_OF_BEST_OF ? (
                      <div className="label-grid number">
                        <Label>
                          Best of ( <span>Number</span> ){" "}
                        </Label>
                        <div className="field">
                          <InputNumber
                            value={formData.avg_best_of_num}
                            required={
                              formData.derived_method ===
                              TestMarksDerivedMethod.AVERAGE_OF_BEST_OF
                            }
                            name="avg_best_of_num"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              setFormData((prev) => ({
                                ...prev,
                                avg_best_of_num: e.target.value,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="label-grid number">
                      <Label>Scaling Marks to</Label>
                      <InputNumber
                        value={formData.out_of}
                        required
                        name="out_of"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          setFormData((prev) => ({
                            ...prev,
                            out_of: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <Button mode="save" type="submit" />
                    <Button mode="clear" type="button" onClick={hanldeClear} />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Derivative;

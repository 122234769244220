export const colorsInGraph = [
  "#86efac",
  "#38bdf8",
  "#f472b6",
  "#fdba74",
  "#9ca3af",
  "#e879f9",
  "#34d399",
  "#60a5fa",
  "#bef264",
];
export const colorsInTest = [
  "#fffbeb",
  "#eff6ff",
  "#f8fafc",
  "#ecfeff",
  "#ecfdf5",
  "#f0fdf4",
  "#f7fee7",
  "#eef2ff",
];

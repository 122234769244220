import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditProfile from "../../../images/EditProfile.svg";
import DeleteIcon from "../../../images/Delete.svg";

import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";

import Navbar from "../../../components/common/Navbar/Index";
import {
  Direction,
  LicenseTypes,
  Operation,
  PageFor,
  SwAcctLdgrOrderField,
  SwAcctLdgrQueryType,
} from "../../../utils/Enum.types";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { GetSwAcctLdgrs } from "../../../queries/Eduate/list";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import { Button } from "../../../stories/Button/Button";
import AddSwLedger from "./AddSwLedger";
import useSwGroupLedgers, { GetSwAcctGroupLdgrsData } from "../../../customhooks/useSwGroupLedgers";
import { DeleteSwAcctLdgr } from "../../../queries/Eduate/mutations/new";
import DeleteModal from "../../../pages/DeleteModal";
import { msgType, responseType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import { AddAcctLdgrFromList } from "../../Accounts/queries/Accountingledgers/mutations/Index";
import { useNavigate, useParams } from "react-router-dom";
import { AccountGroupLdgrsTypeByInstId } from "../../Accounts/queries/GroupLedgers/query/ById";
import { PageInfo } from "../../Channel/Types";
import Input from "../../../stories/Input/Input";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../json/useMasterTableJson";

interface Props {
  pageFor: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

interface GetSwAcctLdgrsNode {
  id: number;
  ldgr_desc: string;
  gr_ldgr_desc: string;
  isChecked: boolean;
}

interface GetSwAcctLdgrsEdges {
  node: GetSwAcctLdgrsNode;
  cursor: string;
}

interface GetSwAcctLdgrsList {
  totalCount: number;
  pageInfo: PageInfo;
  edges: GetSwAcctLdgrsEdges[];
}
interface GetSwAcctLdgrsData {
  GetSwAcctLdgrs: GetSwAcctLdgrsList;
}
interface GetSwAcctLdgrsInput {
  query_type: SwAcctLdgrQueryType;
  grp_ldgr_desc: String;
}
interface GetSwAcctLdgrVars {
  input: GetSwAcctLdgrsInput;
  token: string;
  after: string | null;
  first: number;
  sortBy: string;
  acctLedgerDesc: string;
  direction: Direction;
}
export interface modalFlag {
  flag: boolean;
  id: number;
}

const EduateAccountLedgerList = ({ pageFor, setModalFlag }: Props) => {

  const { InstId } = useParams();
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();

  const { user_details } = useLoggedInUserDetails();

  const [acctLedgers, setAcctLedgers] = useState<GetSwAcctLdgrsEdges[]>([]);
  const [checkedAcctLdgr, setCheckedAcctLdgr] = useState<
    Map<number, GetSwAcctLdgrsNode>
  >(new Map());
  const [groupLedgerType, setGroupLedgerType] = useState("");
  const [acctLedgerDesc, setAcctLedgerDesc] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });
  const [acctLedgerEndCursor, setAcctLedgerEndCursor] = useState<string | null>(
    null
  );
  const [addLdgrModal, setAddLdgrModal] = useState<modalFlag>({
    flag: false,
    id: 0,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const { token } = useToken();
  const acctLedgerRef = useRef<HTMLButtonElement[]>([]);
  const { groupLedgerOptions } = useSwGroupLedgers();

  const [DeleteAcctLdgr] = useMutation(DeleteSwAcctLdgr, {
    onError: (e) =>
      setMessage({
        message: e.message,
        flag: true,
        operation: Operation.NONE,
      }),
  });

  const [AddAcctLdgrToInst] = useMutation(AddAcctLdgrFromList, {
    onError: (e) =>
      setMessage({
        message: e.message,
        flag: true,
        operation: Operation.NONE,
      }),
  });
  const [
    GetAcctLedgers,
    {
      data: acctLedgersData,
      error: acctLedgersError,
      loading: acctLedgerLoaing,
      fetchMore,
    },
  ] = useLazyQuery<GetSwAcctLdgrsData, GetSwAcctLdgrVars>(GetSwAcctLdgrs, {
    variables: {
      token,
      after: null,
      first: ROWS_PER_PAGE,
      direction: Direction.ASC,
      acctLedgerDesc,
      input: {
        grp_ldgr_desc: groupLedgerType,
        query_type: groupLedgerType
          ? SwAcctLdgrQueryType.SW_ACCT_LDGRS_BY_GRP_DESC
          : SwAcctLdgrQueryType.ALL_SW_ACCT_LDGRS,
      },
      sortBy: SwAcctLdgrOrderField.LDGR_DESC,
    },
  });
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !acctLedgerLoaing) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: acctLedgerEndCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetSwAcctLdgrs.edges;
            const pageInfo = fetchMoreResult.GetSwAcctLdgrs.pageInfo;
            setAcctLedgerEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetSwAcctLdgrs.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetSwAcctLdgrs: {
                edges: [...acctLedgers!, ...newEdges],
                pageInfo,
                totalCount: acctLedgersData?.GetSwAcctLdgrs.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteAcctLdgr({
      variables: {
        token,
        id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwAcctLdgrs,
          variables: {
            token,
            after: null,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            input: {
              grp_ldgr_desc: groupLedgerType,
              query_type: groupLedgerType
                ? SwAcctLdgrQueryType.SW_ACCT_LDGRS_BY_GRP_DESC
                : SwAcctLdgrQueryType.ALL_SW_ACCT_LDGRS,
            },
            sortBy: SwAcctLdgrOrderField.LDGR_DESC,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Deleted Account Ledger",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setModalFlag(false);
      setCheckedAcctLdgr(new Map());
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const AddClassRefs = (e: HTMLButtonElement) => {
    if (e && !acctLedgerRef.current.includes(e)) {
      acctLedgerRef.current.push(e);
    }
  };
  const handleEditItem = (id: number) => {
    const result = acctLedgers.find((ledger) => ledger.node.id === id);
    if (checkedAcctLdgr.has(id)) {
      const newMap = new Map(checkedAcctLdgr);
      newMap.delete(id);
      setCheckedAcctLdgr(newMap);
    } else {
      if (result) {
        const newMap = new Map(checkedAcctLdgr);
        newMap.set(id, {
          ...result.node,
        });
        setCheckedAcctLdgr(newMap);
      } else {
        setMessage({
          message: "Ledger Not Found",
          flag: true,
          operation: Operation.NONE,
        });
      }
    }
  };
  useEffect(() => {
    if (token) {
      GetAcctLedgers();
    }
  }, [token, GetAcctLedgers, acctLedgersData, acctLedgerDesc]);
  useEffect(
    () => {
      if (acctLedgersData && !acctLedgerLoaing) {
        if (acctLedgersData) {
          const newData = acctLedgersData.GetSwAcctLdgrs.edges.map((edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true, // set default value of isChecked to true
            },
          }));

          if (acctLedgerEndCursor) {
            // If we have already fetched some data, we need to check if there
            // are any duplicates in the new data, and update their isChecked
            // property based on the existing data.
            const filteredStudents = acctLedgers.filter(
              (student) => !student.node.isChecked
            );

            const updatedNewData = newData.map((newStudent) => {
              const filteredStudent = filteredStudents.find(
                (student) => student.node.id === newStudent.node.id
              );
              if (filteredStudent) {
                return {
                  ...newStudent,
                  node: {
                    ...newStudent.node,
                    isChecked: filteredStudent.node.isChecked,
                  },
                };
              }
              return newStudent;
            });
            setAcctLedgers(updatedNewData);
          } else {
            setAcctLedgers(newData);
          }
          setAcctLedgerEndCursor(
            acctLedgersData.GetSwAcctLdgrs.pageInfo.endCursor
          );
          setAcctLedgers(acctLedgersData.GetSwAcctLdgrs.edges);
        }
      }
    },
    // eslint-disable-next-line
    [acctLedgersData, acctLedgerLoaing]
  );
  const addAccountLedgersToInst = () => {
    AddAcctLdgrToInst({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: Array.from(checkedAcctLdgr).map(([key, value]) => {
          // Perform a transformation or return a new value
          return {
            ldgr_desc: value.ldgr_desc,
            gr_ldgr_desc: value.gr_ldgr_desc,
          };
        }),
      },
      refetchQueries: [
        {
          query: AccountGroupLdgrsTypeByInstId,
          variables: { token, inst_id: InstId! },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Account Ledgers added to institution",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  return (
    <>
      {pageFor === PageFor.GENERAL ? (
        <Navbar NavType={LicenseTypes.EDUATE_CONFIGURATION} />
      ) : null}
      <Title>Eduate Account Ledgers</Title>
      <div
        className={
          pageFor === PageFor.GENERAL
            ? "eduate-account-ledger"
            : "eduate-account-ledger__modal"
        }
      >
        <div className="row g-0 eduate-account-ledger__select">
          <div className="col-3">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={groupLedgerOptions}
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setGroupLedgerType(newValue as string );
                } else {
                  setGroupLedgerType("");
                }
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Group Ledgers"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-3">
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAcctLedgerDesc(e.target.value)
              }
              placeholder="Search Account Ledger"
              value={acctLedgerDesc}
            />
          </div>
          <div className="col"></div>
          {pageFor === PageFor.GENERAL ? (
            <div className="col-2 ">
              <Button
                mode="addnew"
                onClick={() => {
                  setOperation(Operation.CREATE);
                  setAddLdgrModal({
                    flag: true,
                    id: 0,
                  });
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="eduate-account-ledger__tableblock">
          {acctLedgersError ? (
            <b className="no-data">{acctLedgersError.message}</b>
          ) : (
            <>
              <TableContainer
                className="eduate-account-ledger__table"
                onScroll={handleScroll}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {pageFor === PageFor.MODAL && <TableCell></TableCell>}
                      {Masters_Table.Eduate.EduateAcctLedgers.Table_Headers.filter(
                        (tb_head: TableHeaderProps) =>
                          (tb_head.labelName !== "Actions" &&
                            pageFor === PageFor.MODAL) ||
                          pageFor === PageFor.GENERAL
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {acctLedgersData && acctLedgers.length > 0 ? (
                      acctLedgers.map(({ node }, index: number) => {
                        return (
                          <TableRow key={index}>
                            {pageFor === PageFor.MODAL ? (
                              <TableCell
                                className="eduate-account-ledger__table--actions"
                                id="td-center"
                              >
                                <Checkbox
                                  ref={AddClassRefs}
                                  checked={checkedAcctLdgr.has(node.id)}
                                  onClick={() => handleEditItem(node.id)}
                                />
                              </TableCell>
                            ) : null}
                            <TableCell
                              id="td-center"
                              className="eduate-account-ledger__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell>{node.ldgr_desc}</TableCell>
                            <TableCell className="eduate-account-ledger__table--desc">
                              {node.gr_ldgr_desc}
                            </TableCell>
                            {pageFor === PageFor.GENERAL ? (
                              <TableCell className="eduate-account-ledger__table--actions">
                                <img
                                  src={DeleteIcon}
                                  onClick={() => {
                                    setAddLdgrModal({
                                      flag: false,
                                      id: node.id,
                                    });
                                    setDeleteModal(!deleteModal);
                                  }}
                                  alt="/"
                                />
                                <img
                                  src={EditProfile}
                                  onClick={() => {
                                    setOperation(Operation.UPDATE);
                                    setAddLdgrModal({
                                      flag: true,
                                      id: node.id,
                                    });
                                  }}
                                  alt="/"
                                />
                              </TableCell>
                            ) : null}
                          </TableRow>
                        );
                      })
                    ) : (
                      <b className="nodata">Earning Ledgers are empty</b>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {pageFor === PageFor.GENERAL ? (
              <Button mode="back" onClick={() => navigate(-1)} />
            ) : (
              <>
                <Button mode="save" onClick={addAccountLedgersToInst}></Button>
                <Button mode="cancel" onClick={() => setModalFlag(false)} />
              </>
            )}
          </div>
          <div className="col-4 eduate-account-ledger__total">
            <div className="student-total-count">
              Total Ledgers :&nbsp;
              <b>{acctLedgersData?.GetSwAcctLdgrs.totalCount!}</b>
            </div>
          </div>
        </div>

        <AddSwLedger
          openModal={addLdgrModal}
          setOpenModal={setAddLdgrModal}
          operation={operation}
        />
        <DeleteModal
          modalFlag={deleteModal}
          setModalFlag={setDeleteModal}
          handleDelete={handleDelete}
          id={addLdgrModal.id}
        />
        <MessageModal
          handleClose={handleClose}
          value={message.message}
          modalFlag={message.flag}
          operation={message.operation}
        />
      </div>
    </>
  );
};

export default EduateAccountLedgerList;

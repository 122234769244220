import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { GetMstInstTree } from "../queries/common";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/context";
import useToken from "./useToken";
import {
  InstTreeQueryType,
  InstTreeVars,
} from "../Modules/HR/Types/masterDataTypes";

interface Level5Tree {
  level_5: string;
  level_5_name: string;
  level_5_desc: string;
  level_5_id: number;
  boys_count: number;
  girls_count: number;
  total_count: number;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_level_total_count: number;
  show_seats_info: boolean;
}
interface Level4Tree {
  level_4: string;
  level_4_name: string;
  level_4_desc: string;
  level_4_id: number;
  level_5_details: Level5Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_level_total_count: number;
  show_seats_info: boolean;
}
interface Level3Tree {
  level_3: string;
  level_3_name: string;
  level_3_desc: string;
  level_3_id: number;
  level_4_details: Level4Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_level_total_count: number;
  show_seats_info: boolean;
}
interface Level2Tree {
  level_2: string;
  level_2_name: string;
  level_2_desc: string;
  level_2_id: number;
  level_3_details: Level3Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_level_total_count: number;
  show_seats_info: boolean;
}
export interface Level1Tree {
  level_1: string;
  level_1_name: string;
  level_1_desc: string;
  level_1_id: number;
  level_2_details: Level2Tree[];
  boys_count: number;
  girls_count: number;
  total_count: number;
  fresher_count: number;
  existing_count: number;
  total_vacant_seats: number;
  total_seats: number;
  sub_level_total_count: number;
  show_seats_info: boolean;
}
export interface GetMstInstTreeData {
  GetMstInstTree: Level1Tree[];
}
const useMstInstTree = () => {
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [GetInstTree, { data, loading }] = useLazyQuery<
    GetMstInstTreeData,
    InstTreeVars
  >(GetMstInstTree, {
    variables: {
      inst_id: InstId ? InstId : state.InstId,
      token,
      id: InstId ? InstId : state.InstId,
      query_type: InstTreeQueryType.TREE_BY_INST_ID,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token && InstId) {
      GetInstTree();
    }
  }, [token, state.InstId, GetInstTree, InstId]);
  return {
    data,
    loading,
  };
};

export default useMstInstTree;

import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import useToken from "../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../utils/constants";
import { PayRollMasterConfigKey, SwConfigQueryType } from "../enums/Enum.types";
import { GetSwConfigVariables } from "../../../queries/institution/configuration/query/SoftwreConfig";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
} from "../../../Types/configtypes";

const usePayRollMastersConfig = () => {
  const { token } = useToken();
  const { InstId } = useParams();

  const [categoryStatus, setCategoryStatus] = useState<boolean>(false);
  const [jobTypeStatus, setJobTypeStatus] = useState<boolean>(false);
  const [departmentStatus, setDepartmentStatus] = useState<boolean>(false);
  const [gradeStatus, setGradeStatus] = useState<boolean>(false);
  const [designationStatus, setDesignationStatus] = useState<boolean>(false);

  const [GetConfig] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId!,
      input: {
        config_query_type: SwConfigQueryType.PAYROLL_CONFIG_FLAGS,
        str_value: EMPTY_STRING,
        int_value: 0,
      },
    },
  });

  useEffect(() => {
    if (InstId && token) {
      GetConfig().then(({ data }) => {
        if (data) {
          const categoryConfig = data.GetSwConfigVariables.find(
            (config) =>
              config.config_key === PayRollMasterConfigKey.PR_USE_EMP_CATEGORY
          );
          const jobTypeConfig = data.GetSwConfigVariables.find(
            (config) =>
              config.config_key === PayRollMasterConfigKey.PR_USE_EMP_JOB_TYPE
          );
          const departmentConfig = data.GetSwConfigVariables.find(
            (config) =>
              config.config_key === PayRollMasterConfigKey.PR_USE_EMP_DEPT
          );
          const designationConfig = data.GetSwConfigVariables.find(
            (config) =>
              config.config_key ===
              PayRollMasterConfigKey.PR_USE_EMP_DESIGNATION
          );
          const gradeConfig = data.GetSwConfigVariables.find(
            (config) =>
              config.config_key === PayRollMasterConfigKey.PR_USE_EMP_GRADE
          );

          if (categoryConfig) {
            setCategoryStatus(categoryConfig.config_boolean_value);
          }
          if (jobTypeConfig) {
            setJobTypeStatus(jobTypeConfig.config_boolean_value);
          }
          if (departmentConfig) {
            setDepartmentStatus(departmentConfig.config_boolean_value);
          }
          if (designationConfig) {
            setDesignationStatus(designationConfig.config_boolean_value);
          }
          if (gradeConfig) {
            setGradeStatus(gradeConfig.config_boolean_value);
          }
        }
      });
      // eslint-disable-next-line
    }
  }, [InstId, token, GetConfig]);

  return {
    USE_HR_CATEGORY: categoryStatus,
    USE_HR_JOBTYPE: jobTypeStatus,
    USE_HR_DEPARTMENT: departmentStatus,
    USE_HR_DESIGNATION: designationStatus,
    USE_HR_GRADE: gradeStatus,
  };
};

export default usePayRollMastersConfig;

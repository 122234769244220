import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DashBoard from "./layouts/DashBoard";
import EmployeeHome from "./pages/EmployeeHome";
import Login from "./components/Auth/Login";
import {
  getIdTokenResult,
  onAuthStateChanged,
  ParsedToken,
} from "firebase/auth";
import { auth } from "./firebase";
import InstitutionNeeds from "./Modules/Master/configurations/institutionconfiguration/InstitutionNeeds";
import {
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  PageLabel,
  ParentQueryType,
  StudentDashBoardFor,
  StudentListFor,
  StudentPreviewFor,
  StudentReportType,
  TransactionStatusType,
} from "./utils/Enum.types";
import Token from "./pages/Token";
import ChannelUserList from "./Modules/Channel/ChannelUserList";
import NewChannel from "./Modules/Channel/NewChannel";
import Chat from "./Modules/Channel/Index";
import StudentsConfiguration from "./Modules/Eduate/configurations/StudentConfiguration/Index";
import ParentsLogin from "./Modules/Master/parent/EnableLogin/Index";
import BasicData from "./Modules/Master/Student/StudentPreview/BasicData";
import StudentPersonalData from "./Modules/Master/Student/StudentPreview/StudentPersonalData";
import PreviousInstDetails from "./Modules/Master/Student/StudentPreview/PreviousInstDetails";
import StudentParentAssoc from "./Modules/Master/Student/StudentPreview/StudentParentAssoc";
import EnquiryByQrCode from "./Modules/Enquiry/EnquiryByQrCode";
import PageConfiguration from "./Modules/Master/configurations/institutionconfiguration/PageConfiguration";
import InstitutionConfigHome from "./Modules/Master/configurations/Home/Index";
import Page404 from "./pages/Page404";
import StudentDashboard from "./Modules/Master/Student/Dashboard/Index";
import ParentDashboard from "./Modules/Master/parent/Dashboard/Index";
import InstitutionCompleteConfiguration from "./Modules/Master/configurations/institutionconfiguration/InstitutionCompleteConfiguration";
import FeedBackForm from "./Modules/Master/Student/Dashboard/FormTypes/Form";
import { AppContext } from "./context/context";
import { payloadTypes } from "./context/reducer";
import CustomerPredefinedData from "./Modules/Eduate/configurations/CustomerPredefinedData/Index";
import {
  WINDOW_MAX_ZOOM,
  WINDOW_MIN_HEIGHT,
  WINDOW_MIN_WIDTH,
} from "./utils/constants";
import ResetCalculation from "./Modules/Eduate/configurations/ResetAndRecalculate/Index";
import InstLogoUpload from "./Modules/Master/configurations/institutionconfiguration/InstLogoUpload";
import AcademicRoutes from "./routes/AcademicRoutes";
import AccountingRoutes from "./routes/AccountingRoutes";
import UserRightsRoutes from "./routes/UserRightsRoutes";
import LibraryRoutes from "./routes/LibraryRoutes";
import PayrollRoutes from "./routes/PayrollRoutes";
import TransportRoutes from "./routes/TransportRoutes";
import AcademicsInStudentLogin from "./Modules/Master/Student/Dashboard/Academics/Index";
import FeeDetailsStudentLogin from "./Modules/Master/Student/Dashboard/StudentFeeDetails/Index";
import LibraryStudentLogin from "./Modules/Master/Student/Dashboard/Library/Index";
import TransportStudentLogin from "./Modules/Master/Student/Dashboard/Transport/Index";
import AdmissonRoutes from "./routes/admissions/AdmissonRoutes";
import ChatRoutes from "./routes/ChatRoutes";
import IdleTimeOutHandler from "./utils/IdleTimeOut";
import FinalPage from "./Modules/Enquiry/FinalPage";
import useSwConfigData from "./customhooks/useSwConfigData";
import VehicleDetails from "./VMS/Components/VehicleDetails/Index";
import VMSHome from "./VMS/Components/Home/Index";
import AddVehicleDetails from "./VMS/Components/VehicleDetails/New/Index";
import AddVehiclePurchase from "./VMS/Components/VehicleDetails/New/VehiclePurchase";
import CurrentDetails from "./VMS/Components/VehicleDetails/New/CurrentDetails";
import PreviewVehicleDetails from "./VMS/Components/VehicleDetails/Preview/Index";
import Masters from "./VMS/Components/Masters/Index";
import StaffDetails from "./VMS/Components/StaffDetails/Index";
import AddStaffDetails from "./VMS/Components/StaffDetails/New/Index";
import StaffDocuments from "./VMS/Components/StaffDetails/New/Documents";
import PreviewStaffDetails from "./VMS/Components/StaffDetails/Preview/Index";
import SupplierDetails from "./VMS/Components/Supplier/Details/Index";
import FuelIndent from "./VMS/Components/Supplier/FuelIndent/Index";
import FuelPurchase from "./VMS/Components/Supplier/FuelPurchase/Index";
import EmployeeLogs from "./Modules/Logs/EmployeeLogs";
import CompleteLogs from "./Modules/Logs/CompleteLogs";
import CampusActivity from "./Modules/Master/Student/Dashboard/CampusActivity/Index";
import Website from "./Demo/Index";
import Demo from "./Demo/Index";
import DemoList from "./Demo/List";
import AcdYrPosting from "./Modules/Academics/Posting/Index";
import FinYrPost from "./Modules/Accounts/FinYrPost/Index";
import Documents from "./Modules/Print/Documents/Index";
import PendingStudentDataPosting from "./Modules/Master/configurations/institutionconfiguration/posting/PendingStudentDataPosting";
import EnquiryDashboard from "./Modules/Enquiry/Dashboard/Index";
import PendingAcdPosting from "./Modules/Master/configurations/institutionconfiguration/AcdStdPosting/PendingAcdPosting";
import EduateRoutes from "./routes/EduateRoutes";
import EnquiryTeacherDashboard from "./Modules/Enquiry/Dashboard/Teacher/Index";
import ModuleConfiguration from "./Modules/Configurations/ModuleConfiguration";
import StudentListExcelWithFeeDetails from "./Modules/Master/Student/ImportData/StudentImportWithFeeDetails";
import FillForm from "./Modules/Master/Student/Dashboard/FormTypes/FillForm";
import PreviewForm from "./Modules/Master/Student/Dashboard/FormTypes/Preview";
import Template from "./Modules/Print/ProgressReport/BGS/Tenth/Template";
import Template1 from "./Modules/Print/ProgressReport/BGS/Tenth/Index";
import StdImportNonDemand from "./Modules/Master/Student/ImportData/StdImportNonDemand";
import IdCards from "./Modules/Print/IdCards/Index";
import StudentlistForIDCards from "./Modules/Print/IdCards/StudentList";
import GlobalSearch from "./GlobalSearch/Index";
import GenerateDocuments from "./Modules/Print/Documents/Generate";
import Failure from "./Modules/Accounts/Status/Failure";
const Eduate = () => {
  const { state, dispatch } = useContext(AppContext);
  const [claims, setClaims] = useState<ParsedToken>({});

  //To restrict zoom in browsers
  const [showWarning, setShowWarning] = useState(false);
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);

  // eslint-disable-next-line
  const [isActive, setIsActive] = useState(true);
  // eslint-disable-next-line
  const [isLogout, setLogout] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({ type: payloadTypes.SET_USER, payload: { user } });

        getIdTokenResult(user).then((IdTokenResult) => {
          const { claims } = IdTokenResult;
          dispatch({ type: payloadTypes.SET_CLAIMS, payload: { claims } });
          setClaims(claims);
        });
      }
    });
  }, [dispatch]);

  // To restrict window size
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let tolerance = 1;
    if (window.devicePixelRatio > 1) {
      tolerance = 1 / window.devicePixelRatio;
    }

    if (
      window.innerWidth < WINDOW_MIN_WIDTH * tolerance ||
      window.innerHeight < WINDOW_MIN_HEIGHT * tolerance
    ) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [windowSize]);

  useEffect(() => {
    if (window.devicePixelRatio > WINDOW_MAX_ZOOM) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [windowSize]);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.INACTIVITY_TIMEOUT
  );

  return (
    <div className="h100">
      <Router>
        <Routes>
          <Route path="/contact-us" element={<Demo />} />
        </Routes>
      </Router>

      {showError ? (
        <>
          <Router>
            <Routes>
              <Route
                path="/:InstId/studentenquiry/basicdetails"
                element={<EnquiryByQrCode />}
              />

              <Route
                path="/:InstId/studentenquiry/basicdetails/exit"
                element={<FinalPage />}
              />
            </Routes>
          </Router>
          {window.location.pathname.includes("studentenquiry/basicdetails") ||
          window.location.pathname.includes("contact-us") ||
          window.location.pathname.includes("mobileapp/login") ||
          window.location.pathname.includes("mobileapp/payfee") ||
          window.location.pathname.includes("mobileapp/ledgerwise") ||
          window.location.pathname.includes("mobileapp/consolidate") ? (
            showError ? null : (
              <div className="desktop-error">
                This application is only available for desktop. Please increase
                the window size Or enable Desktop Site.
              </div>
            )
          ) : null}
        </>
      ) : showWarning ? (
        <>
          <Router>
            <Routes>
              <Route
                path="/:InstId/studentenquiry/basicdetails"
                element={<EnquiryByQrCode />}
              />
              <Route
                path="/:InstId/studentenquiry/basicdetails/exit"
                element={<FinalPage />}
              />
            </Routes>
          </Router>
          {window.location.pathname.includes("studentenquiry/basicdetails") ||
          window.location.pathname.includes("contact-us") ||
          window.location.pathname.includes("mobileapp/login") ||
          window.location.pathname.includes("mobileapp/payfee") ||
          window.location.pathname.includes("mobileapp/ledgerwise") ||
          window.location.pathname.includes("mobileapp/consolidated") ? (
            showWarning ? null : (
              <div className="desktop-error">You cant zoom more than 200%</div>
            )
          ) : null}
        </>
      ) : (
        <>
          {state.user &&
          (claims.EDUATE || claims.EMPLOYEE || claims.OWNER) &&
          (window.location.pathname.includes("contact-us") === false ||
            window.location.pathname.includes("mobileapp/login") === false ||
            window.location.pathname.includes("mobileapp/payfee") === false ||
            window.location.pathname.includes("mobileapp/ledgerwise") ||
            window.location.pathname.includes("mobileapp/consolidated")) ? (
            <>
              <IdleTimeOutHandler
                onActive={() => setIsActive(true)}
                onIdle={() => setIsActive(false)}
                onLogout={() => setLogout(true)}
                timeOutInterval={
                  configData.data
                    ? configData.data.GetSwConfigVariables[0]
                        .config_integer_value
                    : 10
                }
              />
              <Router>
                <DashBoard>
                  <Routes>
                    <Route path="/failure" element={<Failure pageType={TransactionStatusType.FAILURE} setModalFlag={setModalFlag} />} />
                    <Route path="/demolist" element={<DemoList />} />

                    <Route
                      path="/globalsearch"
                      element={<GlobalSearch pageType={PageFor.GENERAL} />}
                    />

                    <Route path="/:InstId/logs" element={<EmployeeLogs />} />
                    <Route
                      path="/:InstId/completelogs"
                      element={<CompleteLogs />}
                    />
                    <Route path="/eduateWebsite" element={<Website />} />
                    <Route
                      path="/vms"
                      element={<VMSHome DashBoardRequired={true} />}
                    />
                    <Route path="/bgs/tenth" element={<Template1 />} />
                    <Route path="/vms/masters" element={<Masters />} />
                    <Route
                      path="/vms/vehicledetails"
                      element={<VehicleDetails />}
                    />
                    <Route
                      path="/vms/vehicledetails/new"
                      element={
                        <AddVehicleDetails
                          step={1}
                          operation={Operation.CREATE}
                        />
                      }
                    />
                    <Route
                      path="/vms/vehicledetails/update"
                      element={
                        <AddVehicleDetails
                          step={1}
                          operation={Operation.UPDATE}
                        />
                      }
                    />
                    <Route
                      path="/vms/vehicledetails/new/vehiclepurchase"
                      element={
                        <AddVehiclePurchase
                          step={2}
                          operation={Operation.CREATE}
                        />
                      }
                    />
                    <Route
                      path="/vms/vehicledetails/update/purchasedetails"
                      element={
                        <AddVehiclePurchase
                          step={2}
                          operation={Operation.UPDATE}
                        />
                      }
                    />
                    <Route
                      path="/vms/vehicledetails/new/currentdetails"
                      element={
                        <CurrentDetails step={3} operation={Operation.CREATE} />
                      }
                    />
                    <Route
                      path="/vms/vehicledetails/update/currentdetails"
                      element={
                        <CurrentDetails step={3} operation={Operation.UPDATE} />
                      }
                    />
                    <Route
                      path="/vms/vehicledetails/preview"
                      element={<PreviewVehicleDetails />}
                    />
                    <Route
                      path="/vms/staffdetails"
                      element={<StaffDetails />}
                    />
                    <Route
                      path="/vms/staffdetails/new"
                      element={
                        <AddStaffDetails
                          step={1}
                          operation={Operation.CREATE}
                        />
                      }
                    />
                    <Route
                      path="/vms/staffdetails/update"
                      element={
                        <AddStaffDetails
                          step={1}
                          operation={Operation.UPDATE}
                        />
                      }
                    />
                    <Route
                      path="/vms/staffdetails/new/staffdocuments"
                      element={
                        <StaffDocuments
                          step={2}
                          operation={Operation.CREATE}
                          setModalFlag={setModalFlag}
                        />
                      }
                    />
                    <Route
                      path="/vms/staffdetails/preview"
                      element={<PreviewStaffDetails />}
                    />
                    <Route
                      path="/vms/supplierdetails"
                      element={<SupplierDetails />}
                    />
                    <Route
                      path="/vms/fuelindent"
                      element={<FuelIndent operation={Operation.CREATE} />}
                    />
                    <Route
                      path="/vms/fuelindent/edit"
                      element={<FuelIndent operation={Operation.UPDATE} />}
                    />
                    <Route
                      path="/vms/fuelpurchase"
                      element={<FuelPurchase />}
                    />
                    <Route path="/token" element={<Token />} />
                    <Route path="/home" element={<EmployeeHome />} />
                    {/* channel */}
                    <Route
                      path="/:InstId/masters/chatconfiguration"
                      element={
                        <NewChannel
                          setAddChannel={setModalFlag}
                          operation={Operation.CREATE}
                        />
                      }
                    />
                    <Route
                      path="/:InstId/masters/chatconfiguration/:channelId/channeldescription"
                      element={<ChannelUserList />}
                    />
                    {/* parents */}
                    <Route
                      path="/:InstId/masters/parentsLogin"
                      element={
                        <ParentsLogin
                          queryType={ParentQueryType.PARENTS_BY_INST_ID}
                        />
                      }
                    />
                    {/* payroll  */}
                    <Route path="/:InstId/hr/*" element={<PayrollRoutes />} />
                    <Route path="/eduate/*" element={<EduateRoutes />} />
                    <Route
                      path="/:InstId/institutionconfiguration"
                      element={<InstitutionConfigHome DashBoardRequired />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/configureuserneeds"
                      element={<InstitutionNeeds />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/instmodules"
                      element={<ModuleConfiguration />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/posting/academicyear"
                      element={<AcdYrPosting />}
                    />

                    <Route
                      path="/:InstId/institutionconfiguration/posting/pendingstudentdata"
                      element={<PendingStudentDataPosting />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/posting/pendingAcdstudentdata"
                      element={<PendingAcdPosting />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/posting/financialyear"
                      element={<FinYrPost />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/posting/pendingAcdstudentdata"
                      element={<PendingAcdPosting />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/documents/create"
                      element={<Documents />}
                    />

                    <Route
                      path="/:InstId/institutionconfiguration/:documentId/documents/generate"
                      element={<GenerateDocuments pageType={PageFor.GENERAL} />}
                    />
                    <Route
                      path="/:InstId/admissions/logoupload"
                      element={<InstLogoUpload />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/pageconfiguration"
                      element={<PageConfiguration />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/globalconfiguration"
                      element={
                        <InstitutionCompleteConfiguration config_Type="INST" />
                      }
                    />

                    <Route
                      path="/:InstId/institutionconfiguration/eduate"
                      element={
                        <InstitutionCompleteConfiguration config_Type="EDUATE" />
                      }
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/resetandrecalculate"
                      element={<ResetCalculation />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/studentsconfiguration"
                      element={
                        <StudentsConfiguration pageType={PageFor.GENERAL} />
                      }
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/importStdData"
                      element={<StudentListExcelWithFeeDetails />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/importNonDemandData"
                      element={<StdImportNonDemand />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/idcards"
                      element={<IdCards setModal={setModalFlag} />}
                    />
                    <Route
                      path="/:InstId/institutionconfiguration/idcards/print"
                      element={
                        <StudentlistForIDCards
                          pageType={PageFor.GENERAL}
                          studentListFor={StudentListFor.GENERAL}
                          queryType={StudentReportType.ACD_STD_GENERAL}
                          setStudentModal={setModalFlag}
                          studentModal={modalFlag}
                          label={PageLabel.TRUE}
                        />
                      }
                    />
                    {/* eduate-login */}
                    <Route
                      path="/:InstId/instdetails/configurations/predefineddata"
                      element={<CustomerPredefinedData />}
                    />
                    {/* Accounts */}
                    <Route
                      path="/:InstId/admissions/*"
                      element={<AdmissonRoutes />}
                    />
                    <Route
                      path="/:InstId/accounts/*"
                      element={<AccountingRoutes />}
                    />
                    <Route
                      path="/:InstId/rights/*"
                      element={<UserRightsRoutes />}
                    />
                    <Route
                      path="/:InstId/rights/globalsearch"
                      element={<GlobalSearch pageType={PageFor.ADMIN} />}
                    />

                    <Route path="/:InstId/chat/*" element={<ChatRoutes />} />
                    {/* Academics */}
                    <Route
                      path="/:InstId/academics/*"
                      element={<AcademicRoutes />}
                    />
                    {/* Library */}
                    <Route
                      path="/:InstId/library/*"
                      element={<LibraryRoutes />}
                    />
                    {/* transport */}
                    <Route
                      path="/:InstId/transport/*"
                      element={<TransportRoutes />}
                    />

                    <Route
                      path="/:InstId/enquiry"
                      element={<EnquiryDashboard />}
                    />

                    <Route
                      path="/:InstId/enquiry/teacher"
                      element={<EnquiryTeacherDashboard />}
                    />
                  </Routes>
                </DashBoard>
              </Router>
            </>
          ) : state.user &&
            claims.STUDENT &&
            (window.location.pathname.includes("contact-us") === false ||
              window.location.pathname.includes("mobileapp/login") === false ||
              window.location.pathname.includes("mobileapp/payfee") === false ||
              window.location.pathname.includes("mobileapp/ledgerwise") ===
                false ||
              window.location.pathname.includes("mobileapp/consolidated") ===
                false) ? (
            <Router>
              <DashBoard>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.STUDENT_LOGIN}
                        previewType={StudentPreviewFor.BASIC_DETAILS}
                      />
                    }
                  />

                  <Route
                    path="/:InstId/basicdetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.STUDENT_LOGIN}
                        previewType={StudentPreviewFor.BASIC_DETAILS}
                      />
                    }
                  />

                  <Route
                    path="/:InstId/personaldetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.STUDENT_LOGIN}
                        previewType={StudentPreviewFor.PERSONAL_DETAILS}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/prevInstDetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.STUDENT_LOGIN}
                        previewType={StudentPreviewFor.PREV_INST_DETAILS}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/parentdetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.STUDENT_LOGIN}
                        previewType={StudentPreviewFor.PARENT_DETAILS}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/docs_preview"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.STUDENT_LOGIN}
                        previewType={StudentPreviewFor.STUDENT_DOCS}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/studentbasicdata"
                    element={<BasicData pageType={PageFor.MODAL} />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/previewpersonaldata"
                    element={<StudentPersonalData pageType={PageFor.MODAL} />}
                  />
                  <Route
                    path="/:InstId/personaldetails"
                    element={<StudentPersonalData pageType={PageFor.MODAL} />}
                  />

                  <Route
                    path="/:InstId/masters/studentlist/:studentId/previewpreviousschooldata"
                    element={<PreviousInstDetails pageType={PageFor.MODAL} />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/previewstuparentassoc"
                    element={<StudentParentAssoc pageType={PageFor.MODAL} />}
                  />
                  <Route path="*" element={<Page404 />} />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/chat"
                    element={<Chat />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/academics"
                    element={<AcademicsInStudentLogin />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/:formName/:formId/academics/feedback/form"
                    element={<FeedBackForm />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/:formName/:formId/:teacherId/:subjectId/academics/feedback/fillform"
                    element={<FillForm />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/:formName/:formId/:teacherId/:subjectId/academics/feedback/preview"
                    element={<PreviewForm />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/feedetails/:transactionId/success"
                    element={
                      <FeeDetailsStudentLogin
                        transactionStatus={TransactionStatusType.SUCCESS}
                        pageType={PageFor.GENERAL} 
                      />
                    }
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/feedetails/:transactionId/failed"
                    element={
                      <FeeDetailsStudentLogin
                        transactionStatus={TransactionStatusType.FAILURE}
                        pageType={PageFor.GENERAL}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/library"
                    element={
                      <LibraryStudentLogin
                        pageType={PageFor.GENERAL}
                        setModalFlag={setModalFlag}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/feeds"
                    element={<CampusActivity />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/transport"
                    element={
                      <TransportStudentLogin pageType={PageFor.GENERAL} />
                    }
                  />
                  {/* <Route
                    path="/:InstId/masters/studentlist/:studentId/chat/:channelId/channel"
                    element={<Chat />}
                  /> */}
                </Routes>
              </DashBoard>
            </Router>
          ) : state.user &&
            claims.PARENT &&
            (window.location.pathname.includes("contact-us") === false ||
              window.location.pathname.includes("mobileapp/login") === false ||
              window.location.pathname.includes("mobileapp/fee") === false ||
              window.location.pathname.includes("mobileapp/ledgerwise") ===
                false ||
              window.location.pathname.includes("mobileapp/consolidated") ===
                false) ? (
            <Router>
              <DashBoard>
                <Routes>
                  <Route
                    path="/parentdashboard"
                    element={<ParentDashboard />}
                  />
                  <Route
                    path="/:studentId/parentdashboard/student"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.PARENT_LOGIN}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/feeds"
                    element={<CampusActivity />}
                  />
                  <Route
                    path="/:InstId/basicdetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.PARENT_LOGIN}
                        previewType={StudentPreviewFor.BASIC_DETAILS}
                      />
                    }
                  />

                  <Route
                    path="/:InstId/personaldetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.PARENT_LOGIN}
                        previewType={StudentPreviewFor.PERSONAL_DETAILS}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/prevInstDetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.PARENT_LOGIN}
                        previewType={StudentPreviewFor.PREV_INST_DETAILS}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/parentdetails"
                    element={
                      <StudentDashboard
                        type={StudentDashBoardFor.PARENT_LOGIN}
                        previewType={StudentPreviewFor.PARENT_DETAILS}
                      />
                    }
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/studentbasicdata"
                    element={<BasicData pageType={PageFor.MODAL} />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/previewpersonaldata"
                    element={<StudentPersonalData pageType={PageFor.MODAL} />}
                  />
                  <Route
                    path="/:InstId/personaldetails"
                    element={<StudentPersonalData pageType={PageFor.MODAL} />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/previewpreviousschooldata"
                    element={<PreviousInstDetails pageType={PageFor.MODAL} />}
                  />
                  <Route
                    path="/:InstId/masters/studentlist/:studentId/previewstuparentassoc"
                    element={<StudentParentAssoc pageType={PageFor.MODAL} />}
                  />
                </Routes>
              </DashBoard>
            </Router>
          ) : (
            <Router>
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>
            </Router>
          )}
        </>
      )}
    </div>
  );
};
export default Eduate;

import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import { HRTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import EditProfile from "../../../../images/EditProfile.svg";
import Close from "../../../../images/Close.svg";
import Add from "../../../../images/Add.svg";

import HRImport from "../../Imports/LeaveLedgersImport";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import {
  AddLedgerModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import NewLeaveLedger from "./NewLeaveLedger";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import {
  Direction,
  LedgersForType,
  Operation,
  PageFor,
  SortBy,
  TableHeaders,
  YesNo,
} from "../../../../utils/Enum.types";
import Delete from "../../../../images/Delete.svg";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import DeleteModal from "../../../../pages/DeleteModal";
import { GetPayRollLeaveLdgrsByInstId } from "../../queries/leaveledger/list";
import { DeletePayRollLeaveLdgrById } from "../../queries/leaveledger/mutation";
import {
  CommonPaginationVars,
  GetPayRollLeaveLdgrsByInstIdData,
  GetPayRollLeaveLdgrsByInstIdEdges,
} from "../../Types/paginationTypes";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import PredefinedLeaveLedger from "./PredefinedLeaveLedger";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useHRtable from "../../json/useHRtable";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";

const { HRTitles } = require("../../json/title.json");
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { HR_Table } = useHRtable();
  const { user_details } = useLoggedInUserDetails();
  const { dispatch } = useContext(AppContext);
  const [leaveLedgers, setLeaveLedgers] = useState<
    GetPayRollLeaveLdgrsByInstIdEdges[]
  >([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [importModal, setImportModal] = useState(false);
  const [predefinedLeaveModal, setPredefinedLeaveModal] = useState(false);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [id, setId] = useState(0);
  //useStates
  const [addLeaveLedger, setAddLeaveLedger] = useState(false);

  // eslint-disable-next-line
  const [searchLeave, setSearchLeave] = useState("");

  const { USE_PAYROLL } = useUserRightsByEmpId();

  const [GetLeaves, { data, error, loading, fetchMore }] = useLazyQuery<
    GetPayRollLeaveLdgrsByInstIdData,
    CommonPaginationVars
  >(GetPayRollLeaveLdgrsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: ROWS_PER_PAGE,
      after: null,
      direction: Direction.ASC,
      sortBy: SortBy.LEAVE_DESC,
      name: searchLeave,
    },
  });

  const [DeleteLeave] = useMutation(DeletePayRollLeaveLdgrById, {
    onError: (e) => {
      setDeleteModal(!deleteModal);
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      });
    },
  });

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token) {
      GetLeaves();
    }
  }, [searchLeave, token, GetLeaves]);
  const handleDelete = (id: number) => {
    DeleteLeave({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollLeaveLdgrsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.LEAVE_DESC,
            name: "",
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDeleteModal(!deleteModal);
        setMessage({
          flag: true,
          message: "Successfully deleted leave",
          operation: Operation.DELETE,
        });
      }
    });
  };

  const dynamicHeaders: TableHeaderProps[] = HR_Table.LeaveLedger.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    ...(pageType === PageFor.GENERAL
      ? [
          {
            field: "actions",
            headerName: TableHeaders.ACTION,
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
            sortable: false,

            renderCell: (params: GridRenderCellParams) => {
              return (
                <>
                  {params.row.is_lwp_ldgr ? (
                    []
                  ) : (
                    <>
                      {USE_PAYROLL.details?.edit ? (
                        <img
                          src={EditProfile}
                          alt="/"
                          onClick={() => {
                            setId(params.row.id);
                            setOperation(Operation.UPDATE);
                            setAddLeaveLedger(!addLeaveLedger);
                          }}
                        />
                      ) : null}
                      {USE_PAYROLL.details?.delete ? (
                        <img
                          src={Delete}
                          alt="/"
                          onClick={() => {
                            setId(params.row.id);
                            setDeleteModal(!deleteModal);
                          }}
                        />
                      ) : null}
                    </>
                  )}
                </>
              );
            },
          },
        ]
      : []),
  ];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges =
                  fetchMoreResult.GetPayRollLeaveLdgrsByInstId.edges;
                const pageInfo =
                  fetchMoreResult.GetPayRollLeaveLdgrsByInstId.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetPayRollLeaveLdgrsByInstId.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetPayRollLeaveLdgrsByInstId: {
                    edges: [...leaveLedgers, ...newEdges],
                    pageInfo,
                    totalCount: data
                      ? data.GetPayRollLeaveLdgrsByInstId.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetPayRollLeaveLdgrsByInstId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.leaveLedgerId && row.leaveLedgerId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setLeaveLedgers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            leaveLedgerId: node.id,
            description: node.leave_desc,
            short_code: node.leave_short_code,
            leave_type: node.leave_type,
            only_for_women: node.leave_only_for_women ? YesNo.YES : YesNo.NO,
            is_credited: node.leave_is_credited ? YesNo.YES : YesNo.NO,
            is_carry_forwarded: node.leave_carry_forwarded
              ? YesNo.YES
              : YesNo.NO,
            leave_is_earned: node.leave_is_earned ? YesNo.YES : YesNo.NO,
            is_lwp_ldgr: node.is_lwp_ldgr,
          }))
        );
      } else {
        setLeaveLedgers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            leaveLedgerId: node.id,
            description: node.leave_desc,
            short_code: node.leave_short_code,
            leave_type: node.leave_type,
            only_for_women: node.leave_only_for_women ? YesNo.YES : YesNo.NO,
            is_credited: node.leave_is_credited ? YesNo.YES : YesNo.NO,
            is_carry_forwarded: node.leave_carry_forwarded
              ? YesNo.YES
              : YesNo.NO,
            leave_is_earned: node.leave_is_earned ? YesNo.YES : YesNo.NO,
            is_lwp_ldgr: node.is_lwp_ldgr,
          }))
        );
      }
      setEndCursor(data.GetPayRollLeaveLdgrsByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {HRTitles.MasterData.LeaveLedger.Titles.map(
          (title: HRTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.List}</React.Fragment>;
          }
        )}
      </Title>
      <div className="payroll-masterdata__leave-ledger">
        <div className="row g-0">
          <div className="col"></div>
          <div className="col-2">
            <Input
              type="text"
              id="search"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchLeave(e.target.value);
              }}
            />
          </div>
          {pageType === PageFor.GENERAL && (
            <div className="col-1">
              <Button
                mode="addnew"
                className="payroll-masterdata__leave-ledger--button"
                autoFocus
                onClick={() => {
                  setOperation(Operation.CREATE);
                  setAddLeaveLedger(!addLeaveLedger);
                }}
              />
            </div>
          )}
        </div>
        <div className={`payroll-masterdata__leave-ledger--tableblock`}>
          {error ? (
            <b className="nodata">{error?.message}</b>
          ) : leaveLedgers.length ? (
            <StyledDatagrid
              columns={columns}
              rows={rows}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
              onRowClick={(params) => {
                if (pageType === PageFor.MODAL) {
                  dispatch({
                    type: payloadTypes.SET_LEAVE_LEDGER_ID,
                    payload: { leaveLedgerId: params.row.leaveLedgerId },
                  });
                  setModalFlag(false);
                }
              }}
            />
          ) : (
            <b className="nodata">No data please try adding...</b>
          )}
        </div>
        {pageType === PageFor.GENERAL ? (
          <>
            <Button
              type="button"
              onClick={() => setImportModal(!importModal)}
              mode="excel"
            >
              Import Leave Ledgers
            </Button>
            <Button type="button" mode="export" />

            <Button
              type="button"
              onClick={() => {
                setPredefinedLeaveModal(!predefinedLeaveModal);
              }}
            >
              <img src={Add} alt="/" />
              Add Predefined Leave Ledgers
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} />
          </>
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <HRImport />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addLeaveLedger}
        style={AddLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NewLeaveLedger
              operation={operation}
              setModalFlag={setAddLeaveLedger}
              modalFlag={addLeaveLedger}
              eduateOrHr={LedgersForType.HR}
              id={id}
              setId={setId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddLeaveLedger(!addLeaveLedger)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={predefinedLeaveModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PredefinedLeaveLedger setModalFlag={setPredefinedLeaveModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPredefinedLeaveModal(!predefinedLeaveModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        id={id}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Index;

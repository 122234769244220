import React, { useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import More from "../../../../images/More.svg";
import Add from "../../../../images/Add.svg";
import Update from "../../../../images/Update.svg";

import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Button } from "../../../../stories/Button/Button";
import useSubjectAllotedForClass from "../../hooks/useSubjectAllotedForClass";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../utils/Enum.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";

import { StudentModalStyles } from "../../../../styles/ModalStyles";
import ElectiveSubjectAllocate from "./ElectiveSubjectsAllocate";
import { EMPTY_STRING } from "../../../../utils/constants";
import { TooltipForMultipleOption } from "../../../../styles/TooltipStyles";
const { Academics_Table } = require("../../json/table.json");
export interface ElectiveSubjectData {
  id: number;
  name: string;
}
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ViewElectiveSubjects = ({ setModal }: Props) => {
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const { allotedID } = useParams();
  const { subjectsForClass } = useSubjectAllotedForClass(Number(allotedID)!);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const navigate = useNavigate();

  const [electiveId, setElectiveId] = useState<ElectiveSubjectData>({
    id: 0,
    name: "",
  });
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const [allocateModal, setAllocateModal] = useState(false);
  const [allocateUpdateModal, setAllocateUpdateModal] = useState(false);

  const { InstConfigDetails } = useInstConfigByEntryId(Number(allotedID)!);
  const {
    acd_dept_desc,
    acd_branch_desc,
    acd_class_desc,
    acd_semester_desc,
    acd_section_desc,
  } = InstConfigDetails.data
    ? InstConfigDetails.data.GetAcdInstConfigNames
    : {
        acd_dept_desc: "",
        acd_branch_desc: "",
        acd_class_desc: "",
        acd_semester_desc: "",
        acd_section_desc: "",
      };
  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY } = useInstitutionConfiguration();

  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  return (
    <>
      <Title>List Of Subjects</Title>
      <div className="sub-allocation__view">
        <div className="sub-allocation__view--details row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={departmentLabel}
                value={acd_dept_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}

          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={branchLabel}
                value={acd_branch_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}

          {displayClass ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={classLabel}
                value={acd_class_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}

          {displaySemester ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={semesterLabel}
                value={acd_semester_desc}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
              />
            </div>
          ) : null}
          {displaySection ? (
            <div className="col-2">
              <TextField
                className="sub-allocation__view--details--textfield"
                label={sectionLabel}
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                disabled
                value={acd_section_desc}
              />
            </div>
          ) : null}
        </div>
        <div className="sub-allocation__view--tableblock">
          <TableContainer className="sub-allocation__view--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.SubjectList.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {subjectsForClass.data &&
                  subjectsForClass.data.GetAcdSubjectAllotedForClass.map(
                    (data, index) => {
                      return (
                        <TableRow
                          className={
                            data.subj_is_elective
                              ? "test-planner__subject-allocation--table--elective-row"
                              : ""
                          }
                        >
                          <TableCell
                            className="sub-allocation__view--table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="sub-allocation__view--table--code">
                            {data.subject_details.subj_code}
                          </TableCell>
                          <TableCell className="sub-allocation__view--table--code">
                            {data.subject_details.subj_board_code}
                          </TableCell>
                          <TableCell>
                            {data.subject_details.subj_is_elective
                              ? `${
                                  data.subject_details.subj_desc
                                } (${data.elective_details.map(
                                  (res) => res.subject_details.subj_desc
                                )})`
                              : data.subject_details.subj_desc}
                          </TableCell>
                          <TableCell className="sub-allocation__view--table--code">
                            {data.subject_details.subj_is_core_subject
                              ? "Core"
                              : data.subject_details.subj_is_elective
                              ? "Elective"
                              : data.subject_details.subj_is_lang
                              ? "Language"
                              : data.subject_details.subj_is_lab
                              ? "Lab"
                              : EMPTY_STRING}
                          </TableCell>
                          <TableCell className="sub-allocation__view--table--code">
                            {data.subj_is_elective ? (
                              <TooltipForMultipleOption
                                onClick={() =>
                                  setEnableToolTipModal(!enableToolTipModal)
                                }
                             
                                placement="left-start"
                                title={
                                  enableToolTipModal && (
                                    <>
                                      <ul>
                                        {data.elective_details.length === 0 ? (
                                          <li
                                            onClick={() => {
                                              setAllocateModal(!allocateModal);
                                              setElectiveId({
                                                id: data.id,
                                                name: data.subject_details
                                                  .subj_desc,
                                              });
                                            }}
                                            className="studentlist__table--more--fee"
                                          >
                                            <img src={Add} alt="/" />
                                            <span> Add Elective Subjects</span>
                                          </li>
                                        ) : null}
                                        {data.elective_details.length > 0 ? (
                                          <li
                                            onClick={() => {
                                              setAllocateUpdateModal(
                                                !allocateUpdateModal
                                              );
                                              setElectiveId({
                                                id: data.id,
                                                name: data.subject_details
                                                  .subj_desc,
                                              });
                                            }}
                                            className="studentlist__table--more--fee"
                                          >
                                            <img src={Update} alt="/" />
                                            <span>
                                              Update Elective Subjects
                                            </span>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </>
                                  )
                                }
                              >
                                <img src={More} alt="/" />
                              </TooltipForMultipleOption>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={allocateModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ElectiveSubjectAllocate
              operation={Operation.CREATE}
              electiveId={electiveId}
              setAllocateModal={setAllocateModal}
              setAllocateUpdateModal={setAllocateUpdateModal}
              setElectiveId={setElectiveId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="Close"
              onClick={() => setAllocateModal(!allocateModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={allocateUpdateModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ElectiveSubjectAllocate
              operation={Operation.UPDATE}
              electiveId={electiveId}
              setAllocateModal={setAllocateModal}
              setAllocateUpdateModal={setAllocateUpdateModal}
              setElectiveId={setElectiveId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="Close"
              onClick={() => setAllocateUpdateModal(!allocateUpdateModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewElectiveSubjects;

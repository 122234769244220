import React, { useContext, useEffect, useState } from "react";
import { Operation } from "../../../../../utils/Enum.types";
import Input from "../../../../../stories/Input/Input";
import { emptyMessageType } from "../../../../../utils/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { reOrderProcess } from "../../../../../utils/UtilFunctions";
import { Button } from "../../../../../stories/Button/Button";
import { UpdateAcdSubjectComboSubjOrder } from "../../../../../queries/customallocation/combination/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { msgType } from "../../../../../utils/Form.types";
import MessageModal from "../../../../../pages/MessageModal";
import {
  UpdateAcdSubjectComboSubjOrderData,
  UpdateAcdSubjectComboSubjOrderVars,
} from "../../../../../Types/Combinations/mutations";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import { GetAcdSubjectComboSubjOrder } from "../../../../../queries/customallocation/combination/queries";
import LoadingModal from "../../../../../pages/LoadingModal";
import {
  AcdSubjectComboSubjOrder,
  GetAcdSubjectComboSubjOrderData,
  GetAcdSubjectComboSubjOrderVars,
} from "../../../../../Types/Combinations/queries";
import { AppContext } from "../../../../../context/context";

const SubjectList = () => {
  
  const { InstId, combinationId } = useParams();

  const { user_details } = useLoggedInUserDetails();

  const [reOrder, setReOrder] = useState(false);

  const [localItems, setLocalItems] = useState<AcdSubjectComboSubjOrder[]>([]);

  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const { token } = useToken();

  // eslint-disable-next-line
  const [item, setitems] = useState<AcdSubjectComboSubjOrder[]>([]);

  const { state } = useContext(AppContext);

  const { activeAcademicYearData } = useActiveAcademicYear();

  const [GetAcdSubjects, { data, loading }] = useLazyQuery<
    GetAcdSubjectComboSubjOrderData,
    GetAcdSubjectComboSubjOrderVars
  >(GetAcdSubjectComboSubjOrder, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: activeAcademicYearData.data
        ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
        : 0,
      acd_subj_combo_id: state.acdCombinationId
        ? state.acdCombinationId
        : Number(combinationId),
    },
  });

  const [ReOrderbranch, { loading: reorderLoading }] = useMutation<
    UpdateAcdSubjectComboSubjOrderData,
    UpdateAcdSubjectComboSubjOrderVars
  >(UpdateAcdSubjectComboSubjOrder, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  useEffect(() => {
    if (token && (state.acdCombinationId || combinationId)) {
      GetAcdSubjects();
    }
  }, [
    token,
    GetAcdSubjects,
    InstId,
    state.acdCombinationId,
    activeAcademicYearData.data,
    combinationId,
  ]);

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };

  const handleSaveOrder = () => {
    // eslint-disable-next-line
    ReOrderbranch({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.id,
          subj_master_id: res.subject_details.id,
          subj_sl: index + 1,
        })),
        inst_id: InstId!,
        user_details,
        acd_subj_combo_id: Number(combinationId)!,
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
      },
      refetchQueries: [
        {
          query: GetAcdSubjectComboSubjOrder,
          variables: {
            token,
            inst_id: InstId,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            acd_subj_combo_id: combinationId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re-Order Successful",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setReOrder(!reOrder);
  };
  useEffect(() => {
    if (data && !loading)
      setLocalItems(
        data.GetAcdSubjectComboSubjOrder
          ? data.GetAcdSubjectComboSubjOrder.filter((f) => f !== null)
          : []
      );
  }, [data, loading]);
  return (
    <>
      <div className="sub-allocation-comb__sub-list">
        <div className="sub-allocation-comb__sub-list--search row g-0 justify-content-center">
          <div className="col-5">
            <Input id="search" placeholder="Search..." />
          </div>
        </div>

        <div
          className={`sub-allocation-comb__sub-list--tableblock`}
        >
       
          <TableContainer className="sub-allocation-comb__sub-list--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sl</TableCell>
                  <TableCell>Subject Name</TableCell>
                  <TableCell>Subject Code</TableCell>
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={handleDragAndDrop}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {localItems?.map((response, index: number) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={!reOrder}
                        >
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  {reOrder ? (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      id="td-center"
                                      className="institution__table--slno"
                                    >
                                      =
                                    </TableCell>
                                  ) : null}
                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                    className="institution__table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>

                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    className="institution__table--name"
                                  >
                                    {response.subject_details.subj_desc}
                                  </TableCell>
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    className="institution__table--name"
                                  >
                                    {response.subject_details.subj_code}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
          {localItems?.length > 1 ? (
            reOrder ? (
              <>
                <Button
                  mode="save-order"
                  type="button"
                  onClick={handleSaveOrder}
                />

                <Button mode="clear" onClick={() => setReOrder(!reOrder)} />
              </>
            ) : (
              <Button
                mode="reorder"
                type="button"
                onClick={() => setReOrder(!reOrder)}
              />
            )
          ) : null}
        </div>
      </div>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={reorderLoading} />
    </>
  );
};

export default SubjectList;

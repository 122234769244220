import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext, useState } from "react";
import Input from "../../../../stories/Input/Input";
import Pointer from "../../../../images/Pointer.svg";

import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { AppContext } from "../../../../context/context";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { payloadTypes } from "../../../../context/reducer";
import { AccountBookTypes } from "../../../../utils/Enum.types";
import { AcctLedgerQueryType } from "../../common/QueryTypes";

interface props {
  type: AccountBookTypes;
}

const List = ({ type }: props) => {
  const [searchText, setSearchText] = useState("");
  const { state, dispatch } = useContext(AppContext);
  const { acctLedgers } = useAcctLedgerData(
    searchText,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );

  return (
    <>
      <TableContainer className="bank-book__group-ledger--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow className="bank-book__group-ledger--table--groupheader">
              <TableCell>
                {type === AccountBookTypes.BANK_RECONCILATION
                  ? "Bank Reconcilation"
                  : "Fee Collected"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="bank-book__group-ledger--table--input">
                <Input
                  size="small"
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </TableCell>
            </TableRow>
            {acctLedgers.responseType?.map((data, index) => {
              return (
                <TableRow
                  key={index}
                  className={
                    state.accountLedgerId === data.value
                      ? "selected-row"
                      : EMPTY_STRING
                  }
                >
                  <TableCell
                    onClick={() =>
                      dispatch({
                        type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                        payload: {
                          accountLedgerId: data.value,
                        },
                      })
                    }
                  >
                    {state.accountLedgerId === data.value ? (
                      <img src={Pointer} alt="/" className="pointer-icon" />
                    ) : null}
                    {data.label}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;

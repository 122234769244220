import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  InstitutionPrintConfig,
  InstitutionVoucherPrintConfig,
} from "../../../Enum.types";

const USeVoucherReceiptConfig = () => {
  const {
    configData: { data: Logo },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_PRINT_INST_LOGO);
  const {
    configData: { data: InstName },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_PRINT_INST_NAME);
  const {
    configData: { data: InstAdress },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_PRINT_INST_ADDRESS);
  const {
    configData: { data: VoucherType },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_PRINT_VOUCHER_TYPE);
  const {
    configData: { data: VouchersApprovedBy },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_APPROVED_BY);
  const {
    configData: { data: VouchersSignedBy },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_SIGNED_BY);
  const {
    configData: { data: VouchersCreatedTime },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_PRINT_CREATED_TIME);
  const {
    configData: { data: voucherTemplates },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_TEMPLATE);

  const {
    configData: { data: NoOfCopies },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_NUMBER_COPIES);
  const {
    configData: { data: VouchersMarginLeft },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_LEFT_MARGIN);
  const {
    configData: { data: VouchersMarginRight },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_RIGHT_MARGIN);
  const {
    configData: { data: VouchersMarginTop },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_TOP_MARGIN);
  const {
    configData: { data: VouchersMarginBottom },
  } = useSwConfigData(InstitutionVoucherPrintConfig.VO_BOTTOM_MARGIN);
  return {
    USE_LOGO: Logo?.GetSwConfigVariables[0].config_boolean_value,
    USE_INST_NAME: InstName?.GetSwConfigVariables[0].config_boolean_value,
    USE_INST_ADDRESS: InstAdress?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_VOUCHER_TYPE:
      VoucherType?.GetSwConfigVariables[0]?.config_boolean_value,
    USE_VOUCHERS_APPROVED_BY:
      VouchersApprovedBy?.GetSwConfigVariables[0]?.config_string_value,
    USE_VOUCHERS_SIGNED_BY:
      VouchersSignedBy?.GetSwConfigVariables[0]?.config_string_value,
    USE_NO_COPIES: NoOfCopies?.GetSwConfigVariables[0].config_integer_value,
    USE_CREATED_TIME:
      VouchersCreatedTime?.GetSwConfigVariables[0].config_integer_value,
    USE_VOUCHER_TEMPLATES:
      voucherTemplates?.GetSwConfigVariables[0]?.config_string_value,
    USE_VOUCHER_MARGIN_LEFT:
      VouchersMarginLeft?.GetSwConfigVariables[0].config_integer_value,
    USE_VOUCHER_MARGIN_RIGHT:
      VouchersMarginRight?.GetSwConfigVariables[0].config_integer_value,
    USE_VOUCHER_MARGIN_TOP:
      VouchersMarginTop?.GetSwConfigVariables[0].config_integer_value,
    USE_VOUCHER_MARGIN_BOTTOM:
      VouchersMarginBottom?.GetSwConfigVariables[0].config_integer_value,
  };
};
export default USeVoucherReceiptConfig;

import React, { useContext, useEffect, useState } from "react";

import PercentageGraph from "./PercentageGraph";
import ToppersList from "./ToppersList";
import {
  CustomTooltip,
  TOOLTIP_COLORS,
} from "../../../../../../styles/TooltipStyles";
import { TextField } from "@mui/material";
import TableViewImage from "../../../../../../images/TableView.svg";
import DataView from "../../../../../../images/DataView.svg";
import TableView from "./TableView";
import {
  InstitutionConfigurationTypes,
  TestWiseReportPageType,
} from "../../../../../../utils/Enum.types";
import { responseType } from "../../../../../../utils/Form.types";
import { Keys } from "../../../../../../utils/Enum.keys";
import { useLazyQuery } from "@apollo/client";
import { GetAcdTestClassSubjects } from "../../../../queries/test/query";
import { AppContext } from "../../../../../../context/context";
import useToken from "../../../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import useSwConfigData from "../../../../../../customhooks/useSwConfigData";
import { EMPTY_STRING } from "../../../../../../utils/constants";
import {
  GetAcdTestClassSubjectsData,
  GetAcdTestClassSubjectsVars,
  TestClassSubjectQueryType,
} from "../../../../hooks/useTestClassSubjects";
import SubjectwiseList from "../SubjectWise/SubjectWiseList";
import SubjectwisePercentageGraph from "../SubjectWise/PercentageGraph";
import { Button } from "../../../../../../stories/Button/Button";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../../../styles/AutocompleteListStyles";
interface props {
  pageType: TestWiseReportPageType;
  departmentSelected?: responseType | null;
  branchSelected?: responseType | null;
  classSelected?: responseType | null;
  semesterSelected?: responseType | null;
  sectionSelected?: responseType | null;
}
const ClassWise = ({
  pageType,
  departmentSelected,
  branchSelected,
  classSelected,
  semesterSelected,
  sectionSelected,
}: props) => {
  const [tableViewActive, setTableViewActive] = useState(false);

  const { state } = useContext(AppContext);
  const { token } = useToken();
  const navigate = useNavigate();
  const { allotedID, testId, InstId, testClassId } = useParams();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const alloted_level = configData.data
    ? configData.data?.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;

  const [selectedSubject, setSelectedSubject] = useState<responseType | null>(
    null
  );
  const [subjectsOptions, setSubjectOptions] = useState<responseType[]>([]);

  // eslint-disable-next-line
  const [GetAcdSubjects, { data: subjectsData, loading }] = useLazyQuery<
    GetAcdTestClassSubjectsData,
    GetAcdTestClassSubjectsVars
  >(GetAcdTestClassSubjects, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_classes_id: Number(testClassId) || Number(testId),
      pr_emp_id: 0,
      entry_level: alloted_level ? alloted_level : EMPTY_STRING,
      entry_id: allotedID ? Number(allotedID) : 0,
      query_type: TestClassSubjectQueryType.TO_UPDATE_MARKS,
      per_std_subj_allocation: false,
    },
  });

  useEffect(() => {
    if (
      (testClassId || testId) &&
      token &&
      alloted_level &&
      allotedID &&
      InstId &&
      state.ActiveAcdYr
    ) {
      GetAcdSubjects().then(({ data }) => {
        if (data) {
          setSubjectOptions(
            data.GetAcdTestClassSubjects.map((subject) => ({
              label: `${subject.subject_master_details.subj_desc}(${subject.subject_master_details.subj_code})`,
              value: subject.subject_master_details.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [
    testClassId,
    GetAcdSubjects,
    testId,
    token,
    alloted_level,
    allotedID,
    InstId,
    state.ActiveAcdYr,
  ]);

  return (
    <>
      <div className="class-wise-rank row g-0">
        <div className="class-wise-rank__overview">
          <b>Overview</b>
          {pageType === TestWiseReportPageType.SUB_WISE && (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={subjectsOptions}
                openOnFocus
                forcePopupIcon
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedSubject(newValue as responseType);
                  } else {
                    setSelectedSubject(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSelectedSubject(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    // onChange={(e) => setSearchGrade(e.target.value)}
                    className={labelClasses.formControlRoot}
                    label="Filter by Subject"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                )}
              />
            </div>
          )}
          {pageType === TestWiseReportPageType.CLASS_WISE ? (
            <div className="class-wise-rank__overview--images">
              <CustomTooltip
                title="Graph View"
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <span className={!tableViewActive ? "active" : ""}>
                  <img
                    src={DataView}
                    alt=""
                    onClick={() => setTableViewActive(!tableViewActive)}
                  />
                </span>
              </CustomTooltip>

              <CustomTooltip
                title="Table View"
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.GREY,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.GREY,
                      },
                    },
                  },
                }}
                arrow
              >
                <span className={tableViewActive ? "active" : ""}>
                  <img
                    src={TableViewImage}
                    alt=""
                    onClick={() => setTableViewActive(!tableViewActive)}
                  />
                </span>
              </CustomTooltip>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {pageType === TestWiseReportPageType.CLASS_WISE ? (
          <>
            <div className="class-wise-rank__datablock">
              {tableViewActive ? (
                <>
                  <TableView />
                </>
              ) : (
                <>
                  <div className="class-wise-rank__graph">
                    <h6>Student Count Based on Marks</h6>

                    <PercentageGraph
                      departmentSelected={departmentSelected!}
                      branchSelected={branchSelected!}
                      classSelected={classSelected!}
                      semesterSelected={semesterSelected!}
                      sectionSelected={sectionSelected!}
                    />
                  </div>

                  <div className="class-wise-rank__toppers">
                    <ToppersList
                      departmentSelected={departmentSelected!}
                      branchSelected={branchSelected!}
                      classSelected={classSelected!}
                      semesterSelected={semesterSelected!}
                      sectionSelected={sectionSelected!}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {selectedSubject ? (
              <>
                <div className="class-wise-rank__datablock">
                  <div className="class-wise-rank__graph">
                    <h6>Student Count Based on Marks</h6>

                    <SubjectwisePercentageGraph
                      selectedSubject={selectedSubject}
                    />
                  </div>

                  <div className="class-wise-rank__graph">
                    <SubjectwiseList selectedSubject={selectedSubject} />
                  </div>
                </div>
              </>
            ) : (
              <b className="nodata">Please Select any Subject</b>
            )}
          </>
        )}
      </div>
      {tableViewActive ? null : (
        <Button mode="back" onClick={() => navigate(-1)} />
      )}
    </>
  );
};

export default ClassWise;

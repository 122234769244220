import React, { useState } from "react";
import Common from "../New/Common";
import { Title } from "../../../../stories/Title/Title";
import { PreviewVehiclePreviousDetails } from "../../../../utils/Enum.types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import { useNavigate } from "react-router-dom";
const { VMS_Table } = require("../../../json/table.json");
const PreviousDetails = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    PreviewVehiclePreviousDetails.INSURANCE
  );

  const handleTabClick = (tab: PreviewVehiclePreviousDetails) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Common />
      <Title>Previous Details</Title>
      <div className="view-vehicle-details__previous-details">
        <ul className="view-vehicle-details__menu">
          <li
            onClick={() =>
              handleTabClick(PreviewVehiclePreviousDetails.INSURANCE)
            }
            className={
              activeTab === PreviewVehiclePreviousDetails.INSURANCE
                ? "active"
                : ""
            }
          >
            Insurance
          </li>
          <li
            onClick={() =>
              handleTabClick(PreviewVehiclePreviousDetails.FITNESS)
            }
            className={
              activeTab === PreviewVehiclePreviousDetails.FITNESS
                ? "active"
                : ""
            }
          >
            Fitness
          </li>
          <li
            onClick={() =>
              handleTabClick(PreviewVehiclePreviousDetails.ROADTAX)
            }
            className={
              activeTab === PreviewVehiclePreviousDetails.ROADTAX
                ? "active"
                : ""
            }
          >
            Road Tax
          </li>
          <li
            onClick={() =>
              handleTabClick(PreviewVehiclePreviousDetails.EMISSIONTEST)
            }
            className={
              activeTab === PreviewVehiclePreviousDetails.EMISSIONTEST
                ? "active"
                : ""
            }
          >
            Emission Test
          </li>
        </ul>
        <div className="view-vehicle-details__previous-details--tableblock row g-0 justify-content-center">
          <div className="col-7">
            {activeTab === PreviewVehiclePreviousDetails.INSURANCE && (
              <>
                <TableContainer className="view-vehicle-details__previous-details--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {VMS_Table.Vehicle_Details.Preview.Insurance.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--slno"
                          id="td-center"
                        >
                          1
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-blue"
                          id="td-center"
                        >
                          10-05-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-red"
                          id="td-center"
                        >
                          14-12-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-green"
                          id="td-center"
                        >
                          11-05-2023
                        </TableCell>

                        <TableCell>LIC</TableCell>
                        <TableCell className="view-vehicle-details__previous-details--table--number">
                          569832145
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {activeTab === PreviewVehiclePreviousDetails.FITNESS && (
              <>
                <TableContainer className="view-vehicle-details__previous-details--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {VMS_Table.Vehicle_Details.Preview.Fitness.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--slno"
                          id="td-center"
                        >
                          1
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-blue"
                          id="td-center"
                        >
                          10-05-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-red"
                          id="td-center"
                        >
                          14-12-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-green"
                          id="td-center"
                        >
                          11-05-2023
                        </TableCell>

                        <TableCell className="view-vehicle-details__previous-details--table--number">
                          569832145
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {activeTab === PreviewVehiclePreviousDetails.ROADTAX && (
              <>
                <TableContainer className="view-vehicle-details__previous-details--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {VMS_Table.Vehicle_Details.Preview.RoadTax.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--slno"
                          id="td-center"
                        >
                          1
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-blue"
                          id="td-center"
                        >
                          10-05-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-red"
                          id="td-center"
                        >
                          14-12-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-green"
                          id="td-center"
                        >
                          11-05-2023
                        </TableCell>

                        <TableCell className="view-vehicle-details__previous-details--table--number">
                          569832145
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {activeTab === PreviewVehiclePreviousDetails.EMISSIONTEST && (
              <>
                <TableContainer className="view-vehicle-details__previous-details--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {VMS_Table.Vehicle_Details.Preview.EmissionTest.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--slno"
                          id="td-center"
                        >
                          1
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-blue"
                          id="td-center"
                        >
                          10-05-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-red"
                          id="td-center"
                        >
                          14-12-2023
                        </TableCell>
                        <TableCell
                          className="view-vehicle-details__previous-details--table--date font-green"
                          id="td-center"
                        >
                          11-05-2023
                        </TableCell>

                        <TableCell className="view-vehicle-details__previous-details--table--number">
                          569832145
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </div>
      </div>
      <Button mode="back" onClick={() => navigate(-1)} />
    </>
  );
};

export default PreviousDetails;

import { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import {
  SortBy,
  Direction,
  MsgChannelType,
  PageFor,
} from "../../../../utils/Enum.types";
import { useNavigate } from "react-router";
import { AppContext } from "../../../../context/context";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import {
  ChannelListData,
  ChannelListVars,
  GetChannelMessagesByData,
  GetChannelMessagesByVars,
} from "../../../Channel/Types";
import {
  GetChannelMessages,
  GetMessageChannel,
} from "../../../../queries/chatapplication/list";
import useToken from "../../../../customhooks/useToken";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { ChannelQueryTypeByInstId } from "../../../Accounts/common/Enum.types";
import { getFileName, toStandardDate } from "../../../../utils/UtilFunctions";
import { MessageData } from "../../../Channel/MessageList";
import axios from "axios";
import useInstDetails from "../../../../customhooks/general/useInstDetails";

interface Props {
  pageType: PageFor;
}
type Activity = {
  title: string;
  date: string;
  subtitle: string;
  image: string | string[];
  id: number;
  imageString: string;
};

const CampusActivity = ({ pageType }: Props) => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  // eslint-disable-next-line
  const [viewMore, setViewMore] = useState(false);
  const [messages, setMessages] = useState<Activity[]>([]);

  const { InstFormData } = useInstDetails(1);
  const [GetMessages, { data: MessagesData }] = useLazyQuery<
    GetChannelMessagesByData,
    GetChannelMessagesByVars
  >(GetChannelMessages);

  const [GetChannelListData, { data: ChannelList }] = useLazyQuery<
    ChannelListData,
    ChannelListVars
  >(GetMessageChannel, {
    variables: {
      token,
      inst_id: InstId ? InstId : state.InstId.toString(),
      input: {
        query_type: ChannelQueryTypeByInstId.MYCAMPUSFEED,
        channel_id: 0,
      },
    },
  });

  const channelId =
    ChannelList && ChannelList.GetChannelsByInstId.length
      ? ChannelList?.GetChannelsByInstId[0].id
      : 0;

  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        axios.get(response.data, { responseType: "blob" }).then(() => {
          setDownloadUrl((prevDownloadUrl) =>
            prevDownloadUrl.map((messageData) => {
              if (getFileName(mediaContent) === messageData.downloadKey) {
                return { ...messageData, url: response.data };
              } else return messageData;
            })
          );
        });
      })
      .catch((err) => console.log(err));
  };

  // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
  //   const target = event.target as HTMLDivElement;
  //   const scrollTop = target.scrollTop;
  //   if (scrollTop === 0 && !loading && hasPreviousPage) {
  //     fetchMore({
  //       variables: {
  //         last: 5,
  //         before: startCursor,
  //       },
  //       updateQuery: (prevResult, { fetchMoreResult }) => {
  //         if (!fetchMoreResult) return prevResult;

  //         const newEdges = fetchMoreResult.GetChannelMessages.edges;
  //         const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
  //         setStartCursor(pageInfo.startCursor);
  //         setHasPreviousPage(pageInfo.hasPreviousPage);

  //         const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
  //           ({ node: { id } }) =>
  //             newEdges.findIndex(({ node: { id: newId } }) => newId === id) !==
  //             -1
  //         );

  //         if (duplicateCheck.length > 0) return prevResult;

  //         return {
  //           GetChannelMessages: {
  //             edges: [...newEdges, ...messages],
  //             pageInfo,
  //             totalCount: MessagesData?.GetChannelMessages.totalCount!,
  //           },
  //         };
  //       },
  //     });
  //   }
  // };
  useEffect(() => {
    if (channelId && token) {
      GetMessages({
        variables: {
          token,
          inst_id: InstId ? InstId : state.InstId.toString(),
          input: {
            query_type: MsgChannelType.MY_CAMPUS_FEEDS,
            channel_id: channelId,
          },
          direction: Direction.DESC,
          // last: viewMore && pageType === PageFor.DASHBOARD ? 100 : 5,
          last: ROWS_PER_PAGE,
          after: null,
          sortBy: SortBy.CREATED_AT,
          mediaContent: EMPTY_STRING,
        },
      }).then(({ data }) => {
        if (data) {
          setMessages(
            data.GetChannelMessages.edges.map(({ node }) => ({
              title: node.msg_header,
              date: toStandardDate(node.msg_date),
              subtitle: node.msg_content,
              image: node.msg_media_content.includes(",")
                ? node.msg_media_content.split(",")
                : node.msg_media_content,
              id: node.id,
              imageString: node.msg_media_content,
            }))
          );
        }
      });
    } // eslint-disable-next-line
  }, [channelId, token, MessagesData, GetMessages]);
  useEffect(() => {
    const messageDataArray: MessageData[] = messages
      .filter((mess) => mess.image !== "")
      .flatMap((message) => {
        const mediaContentArray = Array.isArray(message.imageString)
          ? message.imageString
          : message.imageString.split(",");
        if (mediaContentArray.length > 1) {
          return mediaContentArray.map((content, index) => {
            const mediaContent = `${
              InstFormData.inst_name
            }/channels/${channelId}/${message.id}_${index}.${
              content.split(".")[content.split(".").length - 1]
            }`;
            return {
              id: message.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.date,
              sent_user_id: 0,
              downloadKey: getFileName(mediaContent),
            };
          });
        } else {
          return mediaContentArray.map((content) => {
            const mediaContent = `${
              InstFormData.inst_name
            }/channels/${channelId}/${message.id}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.date,
              sent_user_id: 0,
              downloadKey: getFileName(mediaContent),
            };
          });
        }
      });

    setDownloadUrl(messageDataArray);

    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        const mediaContent = message.mediaContent;
        getDownloadUrl(message.id, mediaContent);
      });
    };

    fetchDownloadUrls();
  }, [messages, InstFormData.inst_name, channelId]);
  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };
  useEffect(() => {
    if (token && state.InstId) {
      GetChannelListData();
    }
  }, [token, state.InstId, GetChannelListData]);

  return (
    <>
      {pageType === PageFor.DASHBOARD && (
        <div className="student-dashboard__frame--grids--title">
          <Title variant="subtitle1">My Campus Activity</Title>
          <img src={More} alt="/" />
        </div>
      )}

      <div
        className={
          pageType === PageFor.DASHBOARD
            ? "student-dashboard__feeds"
            : "student-dashboard__feeds--general"
        }
      >
        {messages.map((data, index) => {
          const parsedContent = parseMessageContent(data.subtitle);
          const isImage =
            data.imageString.includes(".png") ||
            data.imageString.includes(".jpeg");

          const isVideo = data.imageString.includes(".mp4");
          const combinedById = downLoadUrl.reduce((result, item) => {
            if (!result[item.id]) {
              result[item.id] = [item];
            } else {
              result[item.id].push(item);
            }
            return result;
          }, {} as { [id: number]: MessageData[] });

          return (
            <div className="student-dashboard__feeds--details" key={index}>
              <div className="student-dashboard__feeds--details--flex">
                <Title variant="subtitle1">{data.title}</Title>
                <span>{data.date}</span>
              </div>
              <span className="student-dashboard__feeds--details--subtitle">
                {data.subtitle && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: parsedContent,
                    }}
                  />
                )}
              </span>

              <div
                className={
                  Array.isArray(data.image)
                    ? "student-dashboard__feeds--details--multiple-image"
                    : "student-dashboard__feeds--details--single-image"
                }
              >
                {data.image !== EMPTY_STRING
                  ? combinedById[data.id]?.map(({ mediaContent, url }) => {
                      if (url)
                        if (isImage) {
                          return (
                            <a key={url} href={url}>
                              <img
                                src={url}
                                alt="Loading...."
                                className="channel__message-block--messages-list--messages--file-image"
                              />
                            </a>
                          );
                        } else if (isVideo) {
                          return (
                            <video
                              key={url}
                              width="320"
                              height="240"
                              controls
                              src={url}
                            >
                              {mediaContent}
                              Your browser does not support the video tag.
                            </video>
                          );
                        } else {
                          return null;
                        }
                    })
                  : null}
              </div>
            </div>
          );
        })}

        {pageType === PageFor.DASHBOARD && (
          <div className="student-dashboard__feeds--view-more">
            <button
              onClick={() => {
                navigate(
                  `/${state.InstId}/masters/studentlist/${state.studentId}/feeds`
                );
                setViewMore(true);
              }}
            >
              View More
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CampusActivity;

import React, { useContext, useEffect, useState } from "react";
import { MkCkQueryType } from "../../common/Enum.types";
import useMkckVoucherData from "../../hooks/useMkckVoucherData";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import {

  TextField,
} from "@mui/material";
import { formatter, toStandardDate } from "../../../../utils/UtilFunctions";
import Status, { returnStatus } from "./Status";
import Modal from "react-modal";
import {
  ApprovedStatusModal,
  CheckerRequestsStyles,
} from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";

import { EMPTY_OPTIONSTYPE_OBJECT } from "../../../../utils/constants";
import { optionsType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { GetAcctVouchersMkCkSummation } from "../../queries/Vouchers/query";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import useAcctTableJson from "../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import { GridAlignment, GridCellParams, GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import { StyledDatagrid, TABLE_ROW_HEIGHT,  } from "../../../../styles/DataGridTableStyles";
import { labelClasses,LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
const VoucherStatue = [
  { label: "Pending", value: "P" },
  { label: "Rejected", value: "R" },
  { label: "Approved", value: "A" },
  { label: "Forwarded", value: "F" },
];
const MkckVoucherList = () => {
  const [statusModal, setStatusModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const { dispatch, state } = useContext(AppContext);
  const { format } = formatter;
  const { token } = useToken();
  const { InstId } = useParams();
  const { Accounts_Table } = useAcctTableJson();
  const navigate = useNavigate();

  const [status, setStatus] = useState<optionsType>(EMPTY_OPTIONSTYPE_OBJECT);
  const { mkckVouchers } = useMkckVoucherData(
    MkCkQueryType.MK_CK_ALL,
    status.value
  );

  const { user_details } = useLoggedInUserDetails();
  const [GetVouchersMkCkSummation, { data: ConcessionSummationData }] =
    useLazyQuery(GetAcctVouchersMkCkSummation, {
      variables: {
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        inst_id: InstId!,
        token,
        user_details,
        input: {
          query_type: MkCkQueryType.MK_CK_ALL,
          pr_emp_id: state.empLoginId,
        },
      },
    });
  useEffect(() => {
    if (token && state.ActiveFinYr) {
      GetVouchersMkCkSummation();
    }
  }, [token, state.ActiveFinYr, GetVouchersMkCkSummation]);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.PurchaseEntry.MakerCheckerList.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
   
  ];

  const rows: GridValidRowModel[] = (
    (mkckVouchers.data &&
      mkckVouchers.data.GetAcctVouchersMkCk.edges) ||
    []
  ).map((edge, index) => {
    return {
      id: index + 1,
      date:toStandardDate(edge.node.created_at),
      voucher_no:edge.node.mkck_no,
      amount:format(edge.node.mkck_total_db_amt),
      status:returnStatus(edge.node.mkck_status),
      transaction_type:edge.node.transcation_type,
      remarks:edge.node.remarks,
      mkckId:edge.node.id
    };
  });


  const getRow = (): Record<string, string>[] => {
    const row: Record<string, string> = {
      id: "",
      date: "",
      voucher_no: "Total",
      amount:format(
        ConcessionSummationData?.GetAcctVouchersMkCkSummation[0]
          .mkck_total_db_amt
      ) ?? 0,
      status:"",
      transaction_type:"",
      remarks:""
    };



    return [row];
  };

  const pinnedRows = {
    bottom: getRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "voucher_no":
          return "total";

        case "amount":
     
          return "totalcount";
        default:
          return "";
      }
    }

    return "";
  };



  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Maker Checker Vouchers</Title>
      <div className="maker-checker-list">
        <div className="row g-0 maker-checker-list__select">
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={VoucherStatue}
              value={status}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatus(EMPTY_OPTIONSTYPE_OBJECT);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatus(newValue as optionsType);
                } else {
                  setStatus(EMPTY_OPTIONSTYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Approved Status"
                  fullWidth
                  autoFocus
                  className={labelClasses.formControlRoot}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              )}
            />
          </div>
          <div className="col maker-checker-list__textfields">
            <TextField
              className="maker-checker-list__textfields--date"
              label="From Date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              type="date"
            />
            <TextField
              className="maker-checker-list__textfields--date"
              label="To Date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              type="date"
            />
          </div>
        </div>
        <div className={`maker-checker-list__tableblock`}>
     

<StyledDatagrid
                  columns={columns}
                  rows={rows}
                  onCellClick={(params) => {
                    if (params.field === "status") {
                      dispatch({
                        type: payloadTypes.SET_MK_CK_ID,
                        payload: { mkckId: params.row.mkckId },
                      });
                      setStatusModal(!statusModal);
                    }
                  }}
                  disableRowSelectionOnClick
                  disableChildrenSorting
                  rowHeight={TABLE_ROW_HEIGHT}
                  pinnedRows={pinnedRows}
                  getCellClassName={getCellClassName}
                  hideFooter
                  
                />

        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col view-concession-report__total">
            <div className="student-total-count">
              Total Vouchers :{" "}
              <b>{mkckVouchers.data?.GetAcctVouchersMkCk.totalCount}</b>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={statusModal}
        style={ApprovedStatusModal}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Status />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStatusModal(!statusModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={CheckerRequestsStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {/* <View setModalFlag={setViewModal} pageType={pageType} type={type} /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setViewModal(!viewModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MkckVoucherList;

import * as Yup from "yup";
import { noSpace_Regex } from "./validationRules";

export const addtionOfSubjectValidation = Yup.object({
  subj_code: Yup.string().required("Subject Code is  required"),
  subj_desc: Yup.string()
    .required("Subject Description is  required")
    .matches(noSpace_Regex),
});
export const TestCreationValidation = Yup.object({
  test_name: Yup.string().required("Test Name Description is  required"),
});

export const TestTypeCreationValidation = Yup.object({
  test_type_name: Yup.string().required("Test Type Description is  required"),
  ext_max_marks: Yup.string().required("Test Max Marks is  required"),
  ext_min_marks: Yup.string().required("Test Min Marks  is  required"),
});

export const AcdHolidaysValidation = Yup.object({
  att_holiday_date: Yup.date().required("Holiday Date is Required"),
  att_holiday_desc: Yup.string().required("Holiday Description is Required"),
  att_holiday_full_half_day: Yup.string().required(
    "Holiday Duration Is Required"
  ),
  att_holiday_type: Yup.string().required(" holiday Type is Required"),
});

export const TestDerivationValidation = Yup.object({
  out_of: Yup.string().required("Out Of is required"),
});

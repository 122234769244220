import { gql } from "@apollo/client";

export const GetAcctPurchases = gql`
  query GetAcctPurchases(
    $token: String!
    $fin_yr_id: ID!
    $first: Int
    $after: Cursor
    $orderBy: AcctPurchaseMasterOrder
    $input: PurchaseQueryData! # $query_type: AcctStockItemQueryType!
  ) # $itemName: String
  {
    GetAcctPurchases(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      input: $input #   query_type: $query_type
    ) #   where: { or: [{ itemDescContainsFold: $itemName }] }
    {
      edges {
        node {
          id
          pur_inv_no
          pur_inv_date
          pur_total_taxable
          pur_tax_amt
          pur_sec_discount
          pur_freight
          pur_others
          pur_round_off
          pur_amt
          pur_item_count
          pur_item_qty
          pur_for_department
          pur_remarks
          pur_reconciled
          pur_payment_done
          pur_paid_amt
          pur_balance_amt
          pur_full_payment_done
          pur_type
          pur_order_no
          pur_order_date
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
// token: String!;
// fin_yr_id: ID!;
// input: PurchaseQueryData!;
// after: Cursor;
// first: Int;
// before: Cursor;
// last: Int;
// orderBy: AcctPurchaseMasterOrder;
// where: AcctPurchaseMasterWhereInput;

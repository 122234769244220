import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import {
  AddLibBookDept,
  AddLibBookMedia,
} from "../../../../queries/Library/MasterData/Media/mutations/new";
import { LibraryMasters } from "../../enums/Enum.types";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { GetLibBookMediaByInstId } from "../../../../queries/Library/MasterData/Media/list/byInstId";
import {
  EMPTY_STRING,
  LIBRARY_ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import { msgType } from "../../../../utils/Form.types";
import { AddLibBookCategory } from "../../../../queries/Library/MasterData/BookCategory/mutations/new";
import { GetLibBookCategoriesByInstId } from "../../../../queries/Library/MasterData/BookCategory/list/byInstId";
import { AddLibBookSubject } from "../../../../queries/Library/MasterData/Subjects/mutations/new";
import { GetLibBookSubjectsByInstId } from "../../../../queries/Library/MasterData/Subjects/list/byInstid";
import { AddLibBookPublisher } from "../../../../queries/Library/MasterData/Publisher/mutations/new";
import { GetLibBookPublishersByInstId } from "../../../../queries/Library/MasterData/Publisher/list/byInstId";
import { AddLibBookAuthor } from "../../../../queries/Library/MasterData/Author/mutations/new";
import { GetLibBookAuthorsByInstId } from "../../../../queries/Library/MasterData/Author/list/byInstId";
import { AddLibBookVendor } from "../../../../queries/Library/MasterData/Vendor/mutations/new";
import { GetLibBookVendorsByInstId } from "../../../../queries/Library/MasterData/Vendor/list/byInstId";

const useLibMasterMutations = (
  MasterType: LibraryMasters,
  records: any[],
  setMessage: React.Dispatch<React.SetStateAction<msgType>>
) => {
  const { user_details } = useLoggedInUserDetails();
  const [AddMedia, { loading: mediaCreationLoading }] =
    useMutation(AddLibBookMedia);
  const [AddDepartment, { loading: creationLoading }] =
    useMutation(AddLibBookDept);
  const [AddBookCategory, { loading: categoryLoading }] =
    useMutation(AddLibBookCategory);
  const [AddSubject, { loading: subjectsLoading }] =
    useMutation(AddLibBookSubject);
  const [AddPublisher, { loading: publisherLoading }] =
    useMutation(AddLibBookPublisher);
  const [AddAuthor, { loading: authorLoading }] = useMutation(AddLibBookAuthor);
  const [AddVendor, { loading: vendorLoading }] = useMutation(AddLibBookVendor);
  const { token } = useToken();
  const { InstId } = useParams();

  const handleSubmit = async () => {
    switch (MasterType) {
      case LibraryMasters.MEDIA:
        for (const record of records) {
          try {
            await AddMedia({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  media_desc: removeMoreSpace(record.MediaName),
                  media_abbrevation: removeMoreSpace(record.Abbreviation),
                  media_is_active: record.Status,
                  media_sl: 0,
                },
              },
              refetchQueries: [
                {
                  query: GetLibBookMediaByInstId,
                  variables: {
                    token,
                    inst_id: InstId!,
                    first: LIBRARY_ROWS_PER_PAGE,
                    sortBy: SortBy.MEDIA_DESC,
                    direction: Direction.ASC,
                    mediaName: EMPTY_STRING,
                    after: null,
                  },
                },
              ],
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            }).then(({ data }) => {
              if (data) {
                setMessage({
                  flag: true,
                  message: "Media Added Successfully",
                  operation: Operation.CREATE,
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case LibraryMasters.BOOK_CATEGORY:
        for (const record of records) {
          try {
            await AddBookCategory({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  category_desc: removeMoreSpace(record.CategoryName),
                  category_abbrevation: removeMoreSpace(record.Abbrevation),
                  category_is_active: record.Status,
                  category_sl: 0,
                },
              },
              refetchQueries: [
                {
                  query: GetLibBookCategoriesByInstId,
                  variables: {
                    token,
                    inst_id: InstId!,
                    first: LIBRARY_ROWS_PER_PAGE,
                    sortBy: SortBy.CATEGORY_DESC,
                    direction: Direction.ASC,
                    categoryName: "",
                    after: null,
                  },
                },
              ],
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            }).then(({ data }) => {
              if (data) {
                setMessage({
                  flag: true,
                  message: "Category Added Successfully",
                  operation: Operation.CREATE,
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case LibraryMasters.SUBJECTS:
        for (const record of records) {
          try {
            await AddSubject({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  subject_desc: removeMoreSpace(record.SubjectName),
                  subject_classification: removeMoreSpace(
                    record.Classification
                  ),
                  subject_is_active: record.Status,
                  subject_sl: record.subject_sl,
                },
              },
              refetchQueries: [
                {
                  query: GetLibBookSubjectsByInstId,
                  variables: {
                    token,
                    inst_id: InstId!,
                    first: LIBRARY_ROWS_PER_PAGE,
                    sortBy: SortBy.SUBJECT_DESC,
                    direction: Direction.ASC,
                    name: "",
                    after: null,
                  },
                },
              ],
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            }).then(({ data }) => {
              if (data) {
                setMessage({
                  flag: true,
                  message: "Subjects Added Successfully",
                  operation: Operation.CREATE,
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case LibraryMasters.PUBLISHER:
        for (const record of records) {
          try {
            await AddPublisher({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  publisher_name: removeMoreSpace(record.PublisherName),
                },
              },
              refetchQueries: [
                {
                  query: GetLibBookPublishersByInstId,
                  variables: {
                    token,
                    inst_id: InstId!,
                    first: LIBRARY_ROWS_PER_PAGE,
                    sortBy: SortBy.PUBLISHER_NAME,
                    direction: Direction.ASC,
                    name: "",
                    after: null,
                  },
                },
              ],
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            }).then(({ data }) => {
              if (data) {
                setMessage({
                  flag: true,
                  message: "Publisher Added Successfully",
                  operation: Operation.CREATE,
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case LibraryMasters.AUTHOR:
        for (const record of records) {
          try {
            await AddAuthor({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  author_name: removeMoreSpace(record.AuthorName),
                },
              },
              refetchQueries: [
                {
                  query: GetLibBookAuthorsByInstId,
                  variables: {
                    token,
                    inst_id: InstId!,
                    first: LIBRARY_ROWS_PER_PAGE,
                    sortBy: SortBy.AUTHOR_NAME,
                    direction: Direction.ASC,
                    name: "",
                    after: null,
                  },
                },
              ],
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            }).then(({ data }) => {
              if (data) {
                setMessage({
                  flag: true,
                  message: "Author Added Successfully",
                  operation: Operation.CREATE,
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case LibraryMasters.VENDOR:
        for (const record of records) {
          try {
            await AddVendor({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  vendor_name: removeMoreSpace(record.VendorName),
                },
              },
              refetchQueries: [
                {
                  query: GetLibBookVendorsByInstId,
                  variables: {
                    token,
                    inst_id: InstId!,
                    first: LIBRARY_ROWS_PER_PAGE,
                    sortBy: SortBy.VENDOR_NAME,
                    direction: Direction.ASC,
                    name: "",
                    after: null,
                  },
                },
              ],
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            }).then(({ data }) => {
              if (data) {
                setMessage({
                  flag: true,
                  message: "Vendor Added Successfully",
                  operation: Operation.CREATE,
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case LibraryMasters.DEPARTMENT:
        for (const record of records) {
          try {
            await AddDepartment({
              variables: {
                token,
                inst_id: InstId,
                user_details,
                input: {
                  dept_name: removeMoreSpace(record.DepartmentName),
                },
              },
              onError: (err) => {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(err.message);
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        break;

      default:
        break;
    }
  };

  return {
    handleSubmit,
    loading:
      mediaCreationLoading ||
      creationLoading ||
      categoryLoading ||
      subjectsLoading ||
      publisherLoading ||
      authorLoading ||
      vendorLoading,
  };
};

export default useLibMasterMutations;

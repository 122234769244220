import React, { useState, Key, useRef, useEffect } from "react";
import Modal from "react-modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { Button } from "../../../stories/Button/Button";

import { Title } from "../../../stories/Title/Title";

import {
  Direction,
  Operation,
  SwAcctLdgrOrderField,
  SwAcctLdgrQueryType,
} from "../../../utils/Enum.types";
import InputHoc from "../../../components/common/Input/Index";
import { SwAcctLdgrFormType, msgType } from "../../../utils/Form.types";
import { SwAcctLdgrValidation } from "../../../utils/validationRules";

import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import { InstitutionCustomStyles } from "../../../styles/ModalStyles";
import {
  getApiErrorMessage,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import Close from "../../../images/Close.svg";
import {  TextField } from "@mui/material";
import useToken from "../../../customhooks/useToken";

import LoadingModal from "../../../pages/LoadingModal";
import ErrorModal from "../../../pages/ErrorModal";

import {
  AddSwAcctLdgr,
  UpdateSwAcctLdgr,
} from "../../../queries/Eduate/mutations/new";
import {
  GetSwAcctGroupLdgrs,
  GetSwAcctLdgrByNode,
  GetSwAcctLdgrs,
} from "../../../queries/Eduate/list";
import { CommonNodeVars } from "../../HR/Types/masterDataTypes";
import { TitlesType } from "../../../Types/Eduate/TitlesType";
import useSwGroupLedgers from "../../../customhooks/useSwGroupLedgers";
import { modalFlag } from "./EduateAccountLedgerList";
import MessageModal from "../../../pages/MessageModal";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { FormAutocomplete, formClasses } from "../../../styles/AutocompleteStyles";

const { SwAccountLedger } = require("../../../json/title.json");
interface Props {
  openModal: modalFlag;
  setOpenModal: React.Dispatch<React.SetStateAction<modalFlag>>;
  operation: Operation;
}
interface GetSwAcctLdgrByNodeData {
  node: {
    id: number;
    ldgr_desc: string;
    gr_ldgr_desc: string;
  };
}

const AddSwLedger = ({ openModal, setOpenModal, operation }: Props) => {
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  const descRef = document.getElementsByName(
    "ldgr_desc"
  )[0] as HTMLInputElement;
  const saveRef = useRef<HTMLButtonElement | null>(null);
  const [formData, setFormData] = useState<SwAcctLdgrFormType>({
    ldgr_desc: "",
    gr_ldgr_desc: "",
  });
  const { groupLedgerOptions } = useSwGroupLedgers();
  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });
  const [AddAcctLdgr, { loading, error }] = useMutation(AddSwAcctLdgr, {
    onError: (e) =>
      setMessage({
        message: e.message,
        flag: true,
        operation: Operation.NONE,
      }),
  });
  const [UpdateAcctLdgr, { loading: UpdateLoading }] = useMutation(
    UpdateSwAcctLdgr,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetSwAcctLdgr] = useLazyQuery<GetSwAcctLdgrByNodeData, CommonNodeVars>(
    GetSwAcctLdgrByNode,
    {
      variables: {
        id: openModal.id,
        token,
      },
    }
  );

  const handleSubmit = () => {
    if (operation === Operation.CREATE) {
      AddAcctLdgr({
        variables: {
          token,
          input: {
            ldgr_desc: removeMoreSpace(formData.ldgr_desc),
            gr_ldgr_desc: formData.gr_ldgr_desc,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetSwAcctLdgrs,
            variables: {
              token,
              after: null,
              first: ROWS_PER_PAGE,
              direction: Direction.ASC,
              input: {
                grp_ldgr_desc: EMPTY_STRING,
                query_type: SwAcctLdgrQueryType.ALL_SW_ACCT_LDGRS,
              },
              sortBy: SwAcctLdgrOrderField.LDGR_DESC,
            },
          },
        ],
      });
      setOpenModal({
        flag: false,
        id: 0,
      });
      handleClear();
    } else {
      UpdateAcctLdgr({
        variables: {
          token,
          id: openModal.id,
          user_details,
          input: {
            ldgr_desc: formData.ldgr_desc,
            gr_ldgr_desc: formData.gr_ldgr_desc,
          },
        },
        refetchQueries: [
          {
            query: GetSwAcctLdgrs,
            variables: {
              token,
              after: null,
              first: ROWS_PER_PAGE,
              direction: Direction.ASC,
              input: {
                grp_ldgr_desc: EMPTY_STRING,
                query_type: SwAcctLdgrQueryType.ALL_SW_ACCT_LDGRS,
              },
              sortBy: SwAcctLdgrOrderField.LDGR_DESC,
            },
          },
          {
            query: GetSwAcctGroupLdgrs,
            variables: {
              token,
            },
          },
        ],
      });
      setOpenModal({
        flag: false,
        id: 0,
      });

      handleClear();
    }
  };

  const handleClear = () => {
    setFormData({
      gr_ldgr_desc: "",
      ldgr_desc: "",
    });

    descRef?.focus();
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (openModal.id && operation === Operation.UPDATE && token) {
      GetSwAcctLdgr().then(({ data }) => {
        if (data && data.node) {
          setFormData({
            ldgr_desc: data.node.ldgr_desc,
            gr_ldgr_desc: data.node.gr_ldgr_desc,
          });
        }
      });
    }
  }, [GetSwAcctLdgr, token, openModal.id, operation]);

  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={openModal.flag}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {SwAccountLedger.Titles.map((title: TitlesType, index: Key) => {
                return (
                  <React.Fragment key={index}>
                    {operation === Operation.CREATE ? title.Add : title.Update}
                  </React.Fragment>
                );
              })}
            </Title>
            <Formik
              initialValues={formData}
              validationSchema={SwAcctLdgrValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    <div className="row">
                      <InputHoc
                        name="ldgr_desc"
                        LabelName="Ledger Description"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          setFormData((prev) => ({
                            ...prev,
                            ldgr_desc: e.target.value,
                          }));
                        }}
                        autoFocus
                        required
                        values={formData.ldgr_desc}
                      />
                      {operation === Operation.CREATE ? (
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={groupLedgerOptions}
                          openOnFocus
                          value={formData.gr_ldgr_desc}
                          onChange={(e, newValue) => {
                            meta.handleChange(e);
                            if (newValue) {
                              setFormData((prevValues) => ({
                                ...prevValues,
                                gr_ldgr_desc: newValue as string,
                              }));
                            } else {
                              setFormData((prevValues) => ({
                                ...prevValues,
                                gr_ldgr_desc: "",
                              }));
                            }
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault();
                            saveRef.current?.focus();
                          }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              placeholder="Select Group Ledgers"
                                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          required
                          value={formData.gr_ldgr_desc}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              gr_ldgr_desc: e.target.value,
                            }));
                          }}
                                 slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    </div>
                    <Button mode="save" type="submit" buttonref={saveRef!} />
                    <Button mode="clear" type="button" onClick={handleClear} />
                    <Button
                      mode="cancel"
                      type="button"
                      onClick={() => {
                        handleClear();
                        setOpenModal({
                          flag: false,
                          id: 0,
                        });
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setOpenModal({
                  flag: false,
                  id: 0,
                });
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || UpdateLoading} />
      <ErrorModal value={getApiErrorMessage(error!)} />
      <MessageModal
        handleClose={handleClose}
        value={message.message}
        modalFlag={message.flag}
        operation={message.operation}
      />
    </>
  );
};

export default AddSwLedger;

import { gql } from "@apollo/client";

export const UpdateLibBookSubject = gql`
  mutation UpdateLibBookSubject(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateLibBookSubjectInput!
  ) {
    UpdateLibBookSubject(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

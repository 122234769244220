import { gql } from "@apollo/client";

export const GetAcdClassesByAcdBranchIds = gql`
  query GetAcdClassesByAcdBranchIds($token: String!, $acd_branch_ids: [ID!]!) {
    GetAcdClassesByAcdBranchIds(
      token: $token
      acd_branch_ids: $acd_branch_ids
    ) {
      class_desc
      class_idx
      id
      acd_branch_id
      class_id
    }
  }
`;
export const GetAcdClassesByInstId = gql`
  query GetAcdClassesByInstId($token: String!, $inst_id: ID!) {
    GetAcdClassesByInstId(token: $token, inst_id: $inst_id) {
      class_desc
      class_idx
      id
      acd_branch_id
      class_id
    }
  }
`;

export const GetAcdSubjectMainClassUnmapped = gql`
  query GetAcdSubjectMainClassUnmapped(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_branch_id: ID!
  ) {
    GetAcdSubjectMainClassUnmapped(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_branch_id: $acd_branch_id
    ) {
      acd_class_id
      class_desc
    }
  }
`;
export const GetAcdSubjectMainClassMapped = gql`
  query GetAcdSubjectMainClassMapped(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_branch_id: ID!
  ) {
    GetAcdSubjectMainClassMapped(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_branch_id: $acd_branch_id
    ) {
      acd_class_id
      class_desc
    }
  }
`;

import { gql } from "@apollo/client";
export const GetAcctDemandMainMappedTreeByInstId = gql`
  query GetAcctDemandMainMappedTreeByInstId(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctDemandMainMappedTreeByInstId(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      branch_id
      branch_desc
      class_map {
        class_id
        class_desc
        category_map {
          category_id
          cat_desc
          acct_demand_main_id
        }
      }
    }
  }
`;

export const GetAccountLedgersByNode = gql`
  query GetAccountLedgersByNode($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on AcctLdgr {
        ldgr_std_fee_is_deposit_ldgr
        ldgr_std_fee_is_fine_ldgr
        id
      }
    }
  }
`;

export const GetAcctLdgrMonthlyDataByInstId = gql`
  query GetAcctLdgrMonthlyDataByInstId(
    $token: String!
    $inst_id: ID!
    $acct_ldgr_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctLdgrMonthlyDataByInstId(
      token: $token
      inst_id: $inst_id
      acct_ldgr_id: $acct_ldgr_id
      fin_yr_id: $fin_yr_id
    ) {
      id
      acct_ldgr_month_date
      acct_ldgr_month_idx_no
      acct_ldgr_month_ob
      acct_ldgr_month_cr
      acct_ldgr_month_db
      acct_ldgr_month_cb
      acct_group_ldgr_details {
        id
        gr_ldgr_desc
        gr_ldgr_rp
      }
    }
  }
`;
export const GetAcctRegisterMonthlyDataByInstId = gql`
  query GetAcctRegisterMonthlyDataByInstId(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $register_type: String!
  ) {
    GetAcctRegisterMonthlyDataByInstId(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      register_type: $register_type
    ) {
      id
      register_month_date
      register_month_idx_no
      register_type
      register_month_voucher_count
      register_month_cr
      register_month_db
      inst_id
      fin_yr_id
    }
  }
`;
export const GetAcctDashBoardFinYrGraph = gql`
  query GetAcctDashBoardFinYrGraph(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $register_type: String!
  ) {
    GetAcctDashBoardFinYrGraph(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      register_type: $register_type
    ) {
      fin_is_curr_yr
      monthly_register_data {
        register_month_date
        register_month_idx_no
        register_type
        register_month_voucher_count
        register_month_cr
        register_month_db
      }
    }
  }
`;
export const GetAcctLdgrYearlyDataByInstId = gql`
  query GetAcctLdgrYearlyDataByInstId(
    $token: String!
    $inst_id: ID!
    $acct_ldgr_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctLdgrYearlyDataByInstId(
      token: $token
      inst_id: $inst_id
      acct_ldgr_id: $acct_ldgr_id
      fin_yr_id: $fin_yr_id
    ) {
      id
      acct_ldgr_year_ob
      acct_ldgr_year_cr
      acct_ldgr_year_db
      acct_ldgr_year_cb
      acct_group_ldgr_details {
        id
        gr_ldgr_desc
        gr_ldgr_rp
      }
    }
  }
`;
export const GetAcctRegisterYearlyDataByInstId = gql`
  query GetAcctRegisterYearlyDataByInstId(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $register_type: String!
  ) {
    GetAcctRegisterYearlyDataByInstId(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      register_type: $register_type
    ) {
      id
      register_type
      register_year_voucher_count
      register_year_cr
      register_year_db
    }
  }
`;
export const GetStdDemandSummaryTree = gql`
  query GetStdDemandSummaryTree(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $std_status: String!
  ) {
    GetStdDemandSummaryTree(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      std_status: $std_status
    ) {
      level_1
      level_1_name
      level_1_desc
      level_1_id
      level_2_details {
        level_2
        level_2_name
        level_2_desc
        level_2_id
        level_3_details {
          level_3
          level_3_name
          level_3_desc
          level_3_id
          level_4_details {
            level_4
            level_4_name
            level_4_desc
            level_4_id
            level_5_details {
              level_5
              level_5_name
              level_5_desc
              level_5_id
              cur_std_no
              tc_std_no
              ne_std_no
              std_demand_ob
              std_demand_amt
              std_demand_concession
              tc_std_demand_amt
              ne_std_demand_amt
              total_demand
            }
            cur_std_no
            tc_std_no
            ne_std_no
            std_demand_ob
            std_demand_amt
            std_demand_concession
            tc_std_demand_amt
            ne_std_demand_amt
            total_demand
          }
          cur_std_no
          tc_std_no
          ne_std_no
          std_demand_ob
          std_demand_amt
          std_demand_concession
          tc_std_demand_amt
          ne_std_demand_amt
          total_demand
        }
        cur_std_no
        tc_std_no
        ne_std_no
        std_demand_ob
        std_demand_amt
        std_demand_concession
        tc_std_demand_amt
        ne_std_demand_amt
        total_demand
      }
      cur_std_no
      tc_std_no
      ne_std_no
      std_demand_ob
      std_demand_amt
      std_demand_concession
      tc_std_demand_amt
      ne_std_demand_amt
      total_demand
    }
  }
`;

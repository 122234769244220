import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Modal from "react-modal";
import SubjectWiseAttendance from "./SubjectWiseAttendance";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import Close from "../../../../../images/Close.svg";
import { Button } from "../../../../../stories/Button/Button";
import RightArrow from "../../../../../images/ArrowRight.svg";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdStdAttOverAllData,
  GetAcdStdSubjAttOverAllData,
  subjectData,
} from "../../../../Academics/hooks/useAcdStudentAttendance";
import {
  GetAcdStdAttOverAllVars,
  GetAcdStdSubjAttOverAllVars,
} from "../../../../Academics/types/holidays";
import {
  GetAcdStdAttOverAll,
  GetAcdStdSubjAttOverAll,
} from "../../../../Academics/queries/holidays/list";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { Direction, PageFor, SortBy } from "../../../../../utils/Enum.types";
import StudentAcademicDetails from "../../StudentPreview/StudentAcademicDetails";

export interface SubjectData {
  subj_desc: string;
  id: number;
  total_sessions: number;
  total_present: number;
  total_absent: number;
  total_per: number;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: PageFor;
}

const ViewAllSubjects = ({ setModalFlag, pageType }: Props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);

  const { serverDate } = useServerDateandTime();
  const [subjectDetails, setSubjectDetails] = useState<SubjectData>({
    subj_desc: "",
    id: 0,
    total_sessions: 0,
    total_present: 0,
    total_absent: 0,
    total_per: 0,
  });

  const [viewCompleteSubWiseAtt, setViewCompleteSubWiseAtt] = useState(false);
  const [
    GetOverAllSubjectAttendance,
    { data: OverSubjectData, loading: SubjectLoading },
  ] = useLazyQuery<GetAcdStdSubjAttOverAllData, GetAcdStdSubjAttOverAllVars>(
    GetAcdStdSubjAttOverAll,
    {
      variables: {
        input: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          entry_id: 0,
          entry_level: EMPTY_STRING,
          inst_id: state.InstId!,
          cal_month: toIsoDate(serverDate),
          subj_master_id: 0,
          session_id: 0,
          student_id: state.studentId,
          att_query_type: "SUBJ_YEARLY_ATT_PER_STD",
          date_of_attendance: toIsoDate(serverDate),
        },
        token,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.STD_ROLL_NO,
        },
        after: null,
        first: null,
        name: EMPTY_STRING,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );
  const [GetOverAllAttendance, { data: OverAllData, loading, error }] =
    useLazyQuery<GetAcdStdAttOverAllData, GetAcdStdAttOverAllVars>(
      GetAcdStdAttOverAll,
      {
        variables: {
          input: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            entry_id: 0,
            entry_level: EMPTY_STRING,
            inst_id: state.InstId!,
            cal_month: toIsoDate(serverDate),
            subj_master_id: 0,
            session_id: 0,
            student_id: state.studentId,
            att_query_type: "OVER_ALL_ATT_PER_STD",
            date_of_attendance: toIsoDate(serverDate),
          },
          token,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.STD_ROLL_NO,
          },
          after: null,
          first: null,
          name: EMPTY_STRING,
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
      }
    );
  useEffect(() => {
    if (state.studentId && token && state.ActiveAcdYr) {
      GetOverAllSubjectAttendance();
      GetOverAllAttendance();
    }
  }, [
    GetOverAllSubjectAttendance,
    GetOverAllAttendance,
    state.studentId,
    token,
    state.ActiveAcdYr,
  ]);
  return (
    <>
      <Title>All Subjects Attendance Details </Title>
      <div
        className={
          pageType === PageFor.REPORT ? "sub-wise-att--report" : "sub-wise-att"
        }
      >
        {pageType === PageFor.REPORT && <StudentAcademicDetails />}
        <div className="sub-wise-att__cards">
          <div className="sub-wise-att__cards--grid">
            <span>Total No. of Sessions</span>
            <b className=" font-grey">
              {OverAllData?.GetAcdStdAttOverAll.edges[0].node.total_session}
            </b>
          </div>
          <div className="sub-wise-att__cards--grid">
            <span>Total Present</span>
            <b className=" font-green">
              {
                OverAllData?.GetAcdStdAttOverAll.edges[0].node
                  .total_session_present
              }
            </b>
          </div>
          <div className="sub-wise-att__cards--grid">
            <span>Total Absent</span>
            <b className=" font-red">
              {
                OverAllData?.GetAcdStdAttOverAll.edges[0].node
                  .total_session_absent
              }
            </b>
          </div>
          <div className="sub-wise-att__cards--grid">
            <span>Overall Attendance Percentage</span>
            <b className=" font-blue">
              {OverAllData?.GetAcdStdAttOverAll.edges[0].node.over_all_per}%
            </b>
          </div>
        </div>
        <ul className="sub-wise-att__subject-list">
          {OverSubjectData &&
            OverSubjectData.GetAcdStdSubjAttOverAll.edges.map((sub, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setViewCompleteSubWiseAtt(!viewCompleteSubWiseAtt);
                    setSubjectDetails({
                      subj_desc: (sub.node.subject_details as subjectData)
                        .subj_desc,
                      id: (sub.node.subject_details as subjectData).id,
                      total_sessions: Number(sub.node.total_session),
                      total_present: Number(sub.node.total_session_present),
                      total_absent: Number(sub.node.total_session_absent),
                      total_per: Number(sub.node.over_all_per),
                    });
                  }}
                >
                  <div className="sub-wise-att__subject-list--block">
                    <b>{(sub.node.subject_details as subjectData).subj_desc}</b>
                    <span>
                      ({(sub.node.subject_details as subjectData).subj_code})
                    </span>
                  </div>
                  <div className="sub-wise-att__cards">
                    <div className="sub-wise-att__cards--grid">
                      <span>Total No. of Sessions</span>
                      <b className=" font-grey">{sub.node.total_session}</b>
                    </div>
                    <div className="sub-wise-att__cards--grid">
                      <span>Total Present</span>
                      <b className=" font-green">
                        {sub.node.total_session_present}
                      </b>
                    </div>
                    <div className="sub-wise-att__cards--grid">
                      <span>Total Absent</span>
                      <b className=" font-red">
                        {sub.node.total_session_absent}
                      </b>
                    </div>
                    <div className="sub-wise-att__cards--grid">
                      <span>Overall Attendance Percentage</span>
                      <b className=" font-blue">{sub.node.over_all_per} %</b>
                    </div>
                  </div>
                  <img src={RightArrow} alt="" />
                </li>
              );
            })}
        </ul>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewCompleteSubWiseAtt}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <SubjectWiseAttendance
              setModalFlag={setViewCompleteSubWiseAtt}
              subjectDetails={subjectDetails}
              pageType={pageType}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setViewCompleteSubWiseAtt(!viewCompleteSubWiseAtt)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewAllSubjects;

import React, { useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Exam from "./Exam";
import { TabPanel, a11yProps } from "../../../../../styles/Tabs";
import {
  InstitutionConfigurationTypes,
  PageFor,
} from "../../../../../utils/Enum.types";
import LessonPlanner from "../../../../Academics/DailyActivities/LessonStatus/Index";
import StudentAssignments from "./StudentAssignments";
import FormType from "../FormTypes/Index";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
const Index = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { configData: FeedBackFormFlag } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_FEED_BACK
  );
  return (
    <>
      <Home />
      <Title>Academics</Title>
      <div className="std-acd-dashboard">
        <div className="std-acd-dashboard__data">
          <div className="row g-0 std-acd-dashboard__data--tabs justify-content-center">
            <div className="col-5 ">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Test/Exam" value={0} {...a11yProps(0)} />

                <Tab label="Assignments" value={1} {...a11yProps(1)} />
                <Tab label="Lesson Planner" value={2} {...a11yProps(2)} />
                {FeedBackFormFlag &&
                FeedBackFormFlag.data?.GetSwConfigVariables[0]
                  .config_boolean_value ? (
                  <Tab label="Feed Back" value={3} {...a11yProps(3)} />
                ) : null}
              </Tabs>
            </div>
          </div>
          <div className="row g-0 std-acd-dashboard__data--swipable">
            <div className="col h-100">
              {/* values for Attendance */}
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Exam pageType={PageFor.GENERAL} setModalFlag={setModalFlag} />
              </TabPanel>
              {/* values for Assignments */}
              <TabPanel value={value} index={1} dir={theme.direction}>
                <StudentAssignments />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <LessonPlanner />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <FormType />
              </TabPanel>
            </div>
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetInstMasterData } from "../queries/institution/masterdata";
import {
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../Types/Student";
import { EMPTY_STRING } from "../utils/constants";
import { responseType } from "../utils/Form.types";
import useToken from "./useToken";
export interface GetInstMasterDataList {
  dept_details: DepartmentList[];
  branch_details: BranchesList[];
  class_details: ClassList[];
  semester_details: SemesterList[];
  section_details: SectionList[];
  category_details: CategoryList[];
}
export interface GetInstMasterDataDetails {
  GetInstMasterData: GetInstMasterDataList;
}
export interface GetInstMasterDataVars {
  token: string;
  inst_id: string;
}
const useInstMasterDataByInstId = () => {
  //useParams and Token
  const { token } = useToken();
  const { InstId } = useParams();
  const [departmentDropDown, setDepartmentDropDown] = useState<responseType[]>(
    []
  );
  const [branchDropDown, setBranchDropDown] = useState<responseType[]>([]);
  const [classDropDown, setClassDropDown] = useState<responseType[]>([]);
  const [semesterDropDown, setSemesterDropDown] = useState<responseType[]>([]);
  const [sectionDropDown, setsectionDropDown] = useState<responseType[]>([]);
  const [categoryDropDown, setCategoryDropdown] = useState<responseType[]>([]);

  const [GetInstData, { data, loading }] = useLazyQuery<
    GetInstMasterDataDetails,
    GetInstMasterDataVars
  >(GetInstMasterData, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  useEffect(() => {
    if (token) {
      GetInstData();
    }
  }, [token, GetInstData]);
  useEffect(() => {
    if (data && !loading && InstId && token !== EMPTY_STRING) {
      setDepartmentDropDown(
        data.GetInstMasterData.dept_details.map((dept) => ({
          label: dept.dept_desc,
          value: dept.id,
        }))
      );
      setBranchDropDown(
        data.GetInstMasterData.branch_details.map((dept) => ({
          label: dept.branch_desc,
          value: dept.id,
        }))
      );
      setClassDropDown(
        data.GetInstMasterData.class_details.map((dept) => ({
          label: dept.class_desc,
          value: dept.id,
        }))
      );
      setSemesterDropDown(
        data.GetInstMasterData.semester_details.map((dept) => ({
          label: dept.sem_desc,
          value: dept.id,
        }))
      );
      setsectionDropDown(
        data.GetInstMasterData.section_details.map((dept) => ({
          label: dept.section_desc,
          value: dept.id,
        }))
      );
      setCategoryDropdown(
        data.GetInstMasterData.category_details.map((dept) => ({
          label: dept.cat_desc,
          value: dept.id,
        }))
      );
    }
  }, [data, loading, InstId, token]);
  return {
    departmentDropDown,
    branchDropDown,
    classDropDown,
    semesterDropDown,
    sectionDropDown,
    categoryDropDown,
  };
};

export default useInstMasterDataByInstId;

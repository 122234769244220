import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import {
  GetAcctLdgrYearlyDataByInstId,
  GetAcctRegisterYearlyDataByInstId,
} from "../../queries/Accountingledgers/List/ById";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  GetAcctLdgrMonthlyDataByInstIdVars,
  GetAcctLdgrYearlyDataByInstIdData,
  GetAcctLdgrYearlyDataByInstIdDetails,
  GetAcctRegisterYearlyDataByInstIdData,
  GetAcctRegisterYearlyDataByInstIdDetails,
  GetAcctRegisterYearlyDataByInstIdVars,
} from "../../../../Types/Accounting";
import { BankBookTitleProps } from "../../../../Types/Titles";
import { AccountBookTypes, BankOrCash } from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import MonthlyTotal from "../AccountLedgerBook/MonthlyTotal";
import List from "./List";
import CompleteTransactionModal from "./Modal/CompleteTransactionModal";

const { AccountsTitles } = require("../../json/title.json");
interface props {
  type: AccountBookTypes;
}

const Index = ({ type }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [completeDetailsModal, setCompleteDetailsModal] = useState(false);

  const { state } = useContext(AppContext);
  const [acctldgrYearlyData, setAcctLdgrYearlyData] = useState<
    GetAcctLdgrYearlyDataByInstIdDetails[]
  >([]);

  const [registeryearlyData, setRegisterYearlyData] = useState<
    GetAcctRegisterYearlyDataByInstIdDetails[]
  >([]);

  const [GetYearlyDataOfParticularAccountingLedger] = useLazyQuery<
    GetAcctLdgrYearlyDataByInstIdData,
    GetAcctLdgrMonthlyDataByInstIdVars
  >(GetAcctLdgrYearlyDataByInstId);
  const [GetAcctRegisterYearlyData] = useLazyQuery<
    GetAcctRegisterYearlyDataByInstIdData,
    GetAcctRegisterYearlyDataByInstIdVars
  >(GetAcctRegisterYearlyDataByInstId, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (state.accountLedgerId && state.ActiveFinYr) {
      GetYearlyDataOfParticularAccountingLedger({
        variables: {
          acct_ldgr_id: state.accountLedgerId!,
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      }).then(({ data }) =>
        setAcctLdgrYearlyData(data?.GetAcctLdgrYearlyDataByInstId!)
      );
    }
  }, [
    state.ActiveFinYr,
    state.accountLedgerId,

    GetYearlyDataOfParticularAccountingLedger,
    InstId,
    token,
    setAcctLdgrYearlyData,
    type,
  ]);
  useEffect(() => {
    if (
      state.registerType &&
      state.ActiveFinYr &&
      type === AccountBookTypes.REGISTER_BOOK
    ) {
      GetAcctRegisterYearlyData({
        variables: {
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          register_type: state.registerType,
        },
      }).then(({ data }) => {
        setRegisterYearlyData(data?.GetAcctRegisterYearlyDataByInstId!);
      });
    }
  }, [
    state.registerType,
    state.ActiveFinYr,
    InstId,
    token,
    type,
    GetAcctRegisterYearlyData,
    setRegisterYearlyData,
  ]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.BankBook.BankReconcilation.Titles.map(
          (title: BankBookTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {type === AccountBookTypes.BANK_RECONCILATION
                  ? title.BankReconcilation
                  : title.FeeCollected}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="bank-reconcilation">
        <div className="bank-reconcilation__listblock">
          <List type={type} />
        </div>
        <div className="bank-reconcilation__tableblock">
          <div className="bank-reconcilation__tableblock--monthly-total">
            <MonthlyTotal
              type={BankOrCash.ADJ}
              registerYearlyData={registeryearlyData}
              acctldgrYearlyData={acctldgrYearlyData}
              completeDetailsModal={completeDetailsModal}
              setCompleteDetailsModal={setCompleteDetailsModal}
            />
          </div>
          <div className="bank-reconcilation__tableblock--transaction-details">
            <CompleteTransactionModal type={type} />
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

import React, { useContext, useEffect, useState } from "react";
import Compare from "../../../../images/Compare.svg";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { useLazyQuery } from "@apollo/client";
import { GetAcdGradeScalesByInstId } from "../../queries/grade";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import Percentage from "../../../../images/Percentage.svg";
import {
  GetAcdGradeScalesByInstIdData,
  GetAcdGradeScalesByInstIdVars,
} from "../../types/grade";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import LoadingModal from "../../../../pages/LoadingModal";
import { PageFor } from "../../../../utils/Enum.types";
import EditGrade from "./Add";
import Close from "../../../../images/Close.svg";
interface props{
  setModal:React.Dispatch<React.SetStateAction<boolean>>
}
const GradingScale = ({setModal}:props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [GetAcdGradeScaleDetails, { data: gradeDetails, loading }] =
    useLazyQuery<GetAcdGradeScalesByInstIdData, GetAcdGradeScalesByInstIdVars>(
      GetAcdGradeScalesByInstId,
      {
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        },
      }
    );
  useEffect(() => {
    if (token && InstId) {
      GetAcdGradeScaleDetails();
    }
  }, [token, InstId, GetAcdGradeScaleDetails]);
  const [editModal, setEditModal] = useState(false);
  return (
    <>
      <Title>Convert Marks to Grade</Title>

      <div className="grade">
        <div className="grade__scale">Grading Scale</div>
        <div className="grade__subtitle">
          <b>Grade</b>
          <b>Lower Percentage</b>
          <b>Higher Percentage</b>
        </div>
        <ul className="grade__block">
          {gradeDetails
            ? gradeDetails.GetAcdGradeScalesByInstId.map((grade, index) => {
                return (
                  <li key={index}>
                                        <span>{index + 1}</span>

                    <div>
                    <div className="grade__block--grade">{grade.grade}</div>
                    </div>
                    <span>=</span>
                    <div className="grade__block--perc">
                      {grade.marks_min} <img src={Percentage} alt="" />
                    </div>
                    <img src={Compare} alt="/" />
                    <div className="grade__block--perc">
                      {grade.marks_max} <img src={Percentage} alt="" />
                    </div>
                  </li>
                );
              })
            : null}
        </ul>
        <Button mode="edit" onClick={() => setEditModal(!editModal)} />
        <Button mode="cancel" onClick={()=>setModal(false)}/>
      </div>
      <LoadingModal flag={loading} />
      <Modal isOpen={editModal} style={StudentModalStyles} ariaHideApp={true}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EditGrade pageType={PageFor.MODAL} setModal={setEditModal} />
          </div>
          <div className="modal-flex__image h-100">
            <img src={Close} alt="" onClick={() => setEditModal(false)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GradingScale;

import Input from "../../../../stories/Input/Input";

import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import { SectionAllocationModalStyles } from "../../../../styles/ModalStyles";

import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";

import { Button } from "../../../../stories/Button/Button";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Modal from "react-modal";
import {
  Direction,
  InstitutionDetails,
  Operation,
  SortBy,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";

import { Label } from "../../../../stories/Label/Label";
import { UpdateStdSectionByIds } from "../../../../queries/students/mutations/update";

import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

import { GetStudents } from "../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import Close from "../../../../images/Close.svg";
import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { SectionAllocationDrawer } from "../../../../styles/DrawerStyles";
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const Allocate = () => {
  const navigate = useNavigate();

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          {USE_SECTION_KEY &&
            sectionDropDown.length > 0 &&
            branchSelected?.value! && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              </TableCell>
            )}
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
          {USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--status">
              {branchLabel}
            </TableCell>
          ) : null}
          {USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--status">
              {classLabel}
            </TableCell>
          ) : null}
          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };
  const EnhancedTableHeadForDrawer = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
          {USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--status">
              {branchLabel}
            </TableCell>
          ) : null}
          {USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--status">
              {classLabel}
            </TableCell>
          ) : null}
          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };
  //useStates
  const { InstId } = useParams();
  const { token } = useToken();
  const [allocateSection, setAllocateSection] = useState(false);
  const { state } = useContext(AppContext);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [rightSectionSelected, setRightSectionSelected] =
    useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [allocatedHasNextPage, setAllocatedHasNextPage] =
    useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [allocateEndCursor, setAllocateEndCursor] = useState<string | null>(
    null
  );
  const [searchData, setSearchData] = useState("");
  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [allocatedStudentsList, setAllocatedStudentsList] = useState<
    StudentEdges[]
  >([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //useRefs
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;

  const { user_details } = useLoggedInUserDetails();
  //useMutations
  const [UpdateStdSection, { loading: updationLoading }] = useMutation(
    UpdateStdSectionByIds,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
    masterIds,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  //left table
  const { AcademicsStudentData: notAllocatedStudents } =
    useAcdStudentsForAdmission(
      departmentSelected ? departmentSelected.value : 0,
      masterIds.branchId,
      masterIds.classId,
      masterIds.semesterId,
      0,
      0,
      ROWS_PER_PAGE,
      0,
      searchData,
      StudentReportType.STDS_WITHOUT_SECTION_ALLOCATED,
      EMPTY_STRING,
      []
    );

  // Right table
  const { AcademicsStudentData: allocatedStudents } =
    useAcdStudentsForAdmission(
      0,
      0,
      0,
      0,
      rightSectionSelected ? rightSectionSelected.value : 0,
      0,
      ROWS_PER_PAGE,
      0,
      EMPTY_STRING,
      StudentReportType.CURR_STUDENTS,
      EMPTY_STRING,
      []
    );

  //pagination for modalview
  const righttotalCount = allocatedStudents.data
    ? allocatedStudents.data.GetAcdStudents?.totalCount
    : 0;

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAllocateSection(!allocateSection);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    departmentInputRef?.focus();
  };

  const HandleClear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setRightSectionSelected(null);
    setSearchData("");
    setStudents([]);
    departmentInputRef?.focus();
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = students?.map((edge) => ({
      ...edge,
      node: {
        ...edge.node,
        isChecked: event.target.checked,
      },
    }));
    setStudents(newSelecteds);
  };

  const handleClick = (
    data: StudentEdges,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const mappedDataLeft = students?.map((row) => {
      if (row.node.id === data.node.id) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: (e.target as HTMLInputElement).checked ? true : false,
          },
        };
      } else {
        return row;
      }
    });

    setStudents(mappedDataLeft);
  };

  const handleAllocationOfStudentsToSection = () => {
    UpdateStdSection({
      variables: {
        token,
        user_details,
        student_ids: students
          .filter((student) => student.node.isChecked)
          ?.map(({ node }) => node.id),
        section_id: rightSectionSelected?.value,
        inst_id: InstId,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [semesterSelected ? semesterSelected.value : 0],
              std_query_type:
                studentQueryTypes.SECTION_NOT_ALLOC_BY_SEMESTER_ID,
            },
          },
        },
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [InstId],
              std_query_type: studentQueryTypes.SECTION_NOT_ALLOC_BY_INST_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Students Moved to Section Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        HandleClear();
      }
    });
  };

  const clearRestInstDetails = (type: InstitutionDetails) => {
    switch (type) {
      case InstitutionDetails.DEPARTMENT:
        setBranchSelected(null);
        setClassSelected(null);
        setSemesterSelected(null);
        break;
      case InstitutionDetails.BRANCH:
        setClassSelected(null);
        setSemesterSelected(null);
        break;
      case InstitutionDetails.CLASS:
        setSemesterSelected(null);
        break;
    }
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !notAllocatedStudents.loading) {
        notAllocatedStudents.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students!, ...newEdges],
                pageInfo,
                totalCount:
                  notAllocatedStudents.data?.GetAcdStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const allocateHandleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight + 2 >= getModifiedScrollHeight(scrollHeight)) {
      if (allocatedHasNextPage && !allocatedStudents.loading) {
        allocatedStudents.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: allocateEndCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setAllocateEndCursor(pageInfo.endCursor);
            setAllocatedHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...allocatedStudentsList!, ...newEdges],
                pageInfo,
                totalCount: allocatedStudents.data
                  ? allocatedStudents.data.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (notAllocatedStudents.data && !notAllocatedStudents.loading && token) {
      const newData = notAllocatedStudents.data.GetAcdStudents.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: false, // set default value of isChecked to true
          },
        })
      );

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(notAllocatedStudents.data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [notAllocatedStudents.data, notAllocatedStudents.loading, token]);

  useEffect(() => {
    if (allocatedStudents.data && !allocatedStudents.loading && token) {
      const newData = allocatedStudents.data.GetAcdStudents.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: edge.node.isChecked ? true : false, // set default value of isChecked to true
          },
        })
      );

      if (allocateEndCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = allocatedStudentsList.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: true,
              },
            };
          }
          return newStudent;
        });
        setAllocatedStudentsList(updatedNewData);
      } else {
        setAllocatedStudentsList(newData);
      }
      setAllocateEndCursor(
        allocatedStudents.data.GetAcdStudents.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [allocatedStudents.data, allocatedStudents.loading, token]);

  return (
    <>
      <form className="row g-0 section-allocation__options">
        <div className="col-1 studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
            value={searchData}
            onKeyDown={handleFormEvent}
          />
        </div>

        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, departmentSelected)
              }
              ref={departmentRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && departmentSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.DEPARTMENT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={departmentLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}

        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && branchSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.BRANCH);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={branchLabel}
                  id="outlined Branches"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                } else {
                  setClassSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.CLASS);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={classLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={semesterDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && semesterSelected) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue as responseType);
                } else {
                  setSemesterSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.SEMESTER);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label={semesterLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
      </form>
      <div className="section-allocation__datablock frame-space">
        {!students?.length ? (
          <b className="nodata">Sorry, No Students Found</b>
        ) : (
          <>
            <div className="section-allocation__tableblock">
              <TableContainer
                className="section-allocation__table"
                onScroll={handleScroll}
              >
                <Table stickyHeader>
                  <EnhancedTableHead
                    numSelected={
                      students?.filter((data) => data.node.isChecked).length
                    }
                    onSelectAllClick={(event) => handleSelectAllClick(event)}
                    rowCount={students?.length}
                  />
                  <TableBody>
                    {students?.map((response, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={students[index]?.node.isChecked}
                          tabIndex={-1}
                          key={response.node.id}
                          selected={students[index]?.node.isChecked}
                        >
                          {USE_SECTION_KEY &&
                            sectionDropDown.length > 0 &&
                            branchSelected?.value! && (
                              <TableCell padding="checkbox" align="center">
                                <Checkbox
                                  checked={
                                    students[index]?.node.isChecked ?? false
                                  }
                                  onClick={(event) =>
                                    handleClick(response, event)
                                  }
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                            )}
                          <TableCell
                            id="td-center"
                            className="section-allocation__table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_adm_no}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_reg_no}
                          </TableCell>

                          <TableCell id={labelId}>
                            {response.node.first_name +
                              " " +
                              response.node.middle_name +
                              " " +
                              response.node.last_name}
                          </TableCell>
                          {USE_BRANCH_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.acd_branch.branch_desc}
                            </TableCell>
                          ) : null}
                          {USE_CLASS_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.acd_class.class_desc}
                            </TableCell>
                          ) : null}
                          {USE_SECTION_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.acd_section.section_desc}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          {" "}
          <Button
            mode="move-to"
            onClick={() => setAllocateSection(!allocateSection)}
            disabled={
              !(students?.filter((data) => data.node.isChecked).length > 0)
            }
          />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        <div className="col-2 section-allocation__total">
          <StudentTotalCount
            totalCount={
              notAllocatedStudents.data
                ? notAllocatedStudents.data.GetAcdStudents?.totalCount!
                : 0
            }
          />
        </div>
      </div>

      <SectionAllocationDrawer
        anchor="right"
        open={allocateSection}
        onClose={() => setAllocateSection(!allocateSection)}
      >
        <div className="section-allocation__move-to">
          <div className="row g-0 section-allocation__move-to--select">
            {USE_SECTION_KEY ? (
              <div className="col-4 section-allocation__select ">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={sectionDropDown!}
                  value={rightSectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setRightSectionSelected(newValue as responseType);
                    } else {
                      setRightSectionSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      label={`Move To ${sectionLabel}`}
                      required={rightSectionSelected?.value ? false : true}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <div
            className="section-allocation__move-to--count"
            onClick={() =>
              rightSectionSelected && setViewStudentModal(!viewStudentModal)
            }
          >
            <b>{rightSectionSelected && righttotalCount}</b>
            <Label>Students :</Label>
            <Label>{sectionLabel}</Label>
          </div>
          <div className="section-allocation__move-to--tableblock">
            <TableContainer
              className="section-allocation__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHeadForDrawer />

                <TableBody>
                  {students
                    .filter((student) => student.node.isChecked)
                    .map((response, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={students[index]?.node.isChecked}
                          tabIndex={-1}
                          key={response.node.id}
                          selected={students[index]?.node.isChecked}
                        >
                          <TableCell
                            id="td-center"
                            className="section-allocation__table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_adm_no}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_reg_no}
                          </TableCell>

                          <TableCell>
                            {response.node.first_name +
                              " " +
                              response.node.middle_name +
                              " " +
                              response.node.last_name}
                          </TableCell>
                          {USE_BRANCH_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.acd_branch.branch_desc}
                            </TableCell>
                          ) : null}
                          {USE_CLASS_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.acd_class.class_desc}
                            </TableCell>
                          ) : null}
                          {USE_SECTION_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {
                                sectionDropDown?.find(
                                  ({ value }) =>
                                    Number(value) ===
                                    rightSectionSelected?.value
                                )?.label
                              }
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            mode="save"
            onClick={handleAllocationOfStudentsToSection}
            disabled={rightSectionSelected?.value ? false : true}
          />
          <Button
            mode="cancel"
            onClick={() => {
              HandleClear();
              setAllocateSection(!allocateSection);
            }}
          />
          <MessageModal
            modalFlag={message.flag!}
            value={message.message!}
            handleClose={handleClose}
            operation={message.operation!}
          />
          <LoadingModal flag={updationLoading} />
        </div>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={viewStudentModal}
          ariaHideApp={false}
          style={SectionAllocationModalStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <Title> List of Students In {sectionLabel}</Title>
              <div className="section-allocation__allocated">
                <TableContainer
                  className="section-allocation__table"
                  onScroll={allocateHandleScroll}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className="section-allocation__table--th-slno">
                          {TableHeaders.SLNO}
                        </TableCell>
                        <TableCell className="section-allocation__table--th-admno">
                          {TableHeaders.ADMISSION_NUMBER}
                        </TableCell>
                        <TableCell className="section-allocation__table--th-admno">
                          {TableHeaders.REGISTER_NUMBER}
                        </TableCell>
                        <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
                        {USE_DEPARTMENT_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {departmentLabel}
                          </TableCell>
                        ) : null}
                        {USE_BRANCH_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {branchLabel}
                          </TableCell>
                        ) : null}

                        {USE_CLASS_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {classLabel}
                          </TableCell>
                        ) : null}

                        {USE_SEMESTER_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {semesterLabel}
                          </TableCell>
                        ) : null}
                        {USE_SECTION_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {sectionLabel}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rightSectionSelected &&
                        allocatedStudentsList?.length > 0 &&
                        allocatedStudentsList?.map(
                          (response, index: number) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow>
                                <TableCell
                                  id="td-center"
                                  className="section-allocation__table--slno"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell className="section-allocation__table--admno">
                                  {response.node.std_adm_no}
                                </TableCell>
                                <TableCell className="section-allocation__table--admno">
                                  {response.node.std_reg_no}
                                </TableCell>

                                <TableCell id={labelId}>
                                  {response.node.first_name +
                                    " " +
                                    response.node.middle_name +
                                    " " +
                                    response.node.last_name}
                                </TableCell>
                                {USE_DEPARTMENT_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.acd_dept.dept_desc}
                                  </TableCell>
                                ) : null}
                                {USE_BRANCH_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.acd_branch.branch_desc}
                                  </TableCell>
                                ) : null}

                                {USE_CLASS_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.acd_class.class_desc}
                                  </TableCell>
                                ) : null}

                                {USE_SEMESTER_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.acd_semester.sem_desc}
                                  </TableCell>
                                ) : null}
                                {USE_SECTION_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.acd_section.section_desc}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                    {/* <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={PAGINATION_ARRAY}
                          count={righttotalCount ? righttotalCount : 0}
                          rowsPerPage={modalRowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeModalRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          labelDisplayedRows={defaultLabelDisplayedRows}
                          labelRowsPerPage={TABLE_DATA_PER_PAGE}
                        />
                      </TableRow>
                    </TableFooter> */}
                  </Table>
                </TableContainer>
              </div>
              <div className="row g-0">
                <div className="col">
                  <Button
                    mode="cancel"
                    onClick={() => setViewStudentModal(!viewStudentModal)}
                  />
                </div>
                <div className="col section-allocation--total-count">
                  <StudentTotalCount
                    totalCount={
                      allocatedStudents.data
                        ? allocatedStudents.data.GetAcdStudents.totalCount!
                        : 0
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={() => setViewStudentModal(!viewStudentModal)}
              />
            </div>
          </div>
        </Modal>
      </SectionAllocationDrawer>
    </>
  );
};

export default Allocate;

/**
 * React functional component that renders a table for deleting fee demands for particular students.
 * The component uses Material-UI components and custom hooks to fetch and display the data.
 * It includes form fields for filtering the students based on branch, year, and category.
 * The selected students can be deleted by clicking a button.
 *
 * Example Usage:
 * <DeleteFeeDemandForParticularStudent />
 *
 * Inputs:
 * - branchDropDown: An array of branch options for the branch filter.
 * - classDropDown: An array of class options for the year filter.
 * - categoryDropDown: An array of category options for the category filter.
 * - students: The data of students with fee demands.
 *
 * Outputs:
 * - Renders a table displaying the students with fee demands.
 * - Allows the user to select and delete fee demands for particular students.
 */
import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";

import { Label } from "../../../stories/Label/Label";

import { Title } from "../../../stories/Title/Title";

import { useMutation } from "@apollo/client";

import { TextField } from "@mui/material";
import { DeleteAcctStdDemand } from "../queries/demands/mutation/index";

import { Button } from "../../../stories/Button/Button";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  formatter,
  getModifiedScrollHeight,
  isOptionEqualToValue,
} from "../../../utils/UtilFunctions";
import { FeeDemandTitleProps } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import Home from "../Home/Index";
import DownArrow from "../../../images/DownArrow.svg";
import useToken from "../../../customhooks/useToken";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { Keys } from "../../../utils/Enum.keys";
import MessageModal from "../../../pages/MessageModal";
import { msgType, responseType } from "../../../utils/Form.types";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import LoadingModal from "../../../pages/LoadingModal";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
  StudentDemandNode,
} from "../hooks/useStudentsbyDemandAmount";
import {
  Direction,
  Operation,
  StudentAcctReportType,
} from "../../../utils/Enum.types";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import {
  DeleteAcctStdDemandData,
  DeleteAcctStdDemandVars,
} from "../../../Types/Accounting/mutations";
import { AppContext } from "../../../context/context";

import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import { GetAcctStdDemand } from "../../../queries/students/list/newApi";
import { AcctStudentQueryType } from "../common/QueryTypes";
import DeleteModal from "../../../pages/DeleteModal";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
const { AccountsTitles } = require("../json/title.json");

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}
interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Title variant="subtitle1">
      Selected Students: <b>{numSelected}</b>
    </Title>
  );
};

const DeleteFeeDemandForParticularStudent = () => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();

  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const setarray = new Set(selected);
  const { state } = useContext(AppContext);
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;

  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const { user_details } = useLoggedInUserDetails();
  const { branchDropDown, categoryDropDown } = useInstMasterDataByInstId();
  const { classDropDown } = useDropdownData(
    0,
    branchId ? branchId.value : 0,
    0,
    0
  );
  const { USE_BRANCH_KEY, USE_CATEGORY_KEY, USE_CLASS_KEY } =
    useInstitutionConfiguration();
  //custom hooks

  const { students: studentsData } = UseStudentsbyDemandAmount(
    0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    0,
    0,
    categoryId ? categoryId.value : 0,
    EMPTY_STRING,
    rowsPerPage,
    StudentAcctReportType.DEMAND_RAISED_NOT_PAID
  );
  const totalCount = studentsData.data
    ? studentsData.data.GetAcctStdDemand.totalCount
    : 0;

  //mutations
  const [DeleteRaisedFeeDemand, { loading }] = useMutation<
    DeleteAcctStdDemandData,
    DeleteAcctStdDemandVars
  >(DeleteAcctStdDemand, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleDelete = () => {
    setDeleteModal(!deleteModal);
    if (state.ActiveFinYr === null) {
      setMessage({
        flag: true,
        message: "Active Financial year not found",
        operation: Operation.NONE,
      });
      return;
    }
    DeleteRaisedFeeDemand({
      variables: {
        token,
        student_ids: selected,
        inst_id: InstId!,
        fin_yr_id: state.ActiveFinYr?.id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctStdDemand,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: rowsPerPage === null ? null : rowsPerPage,
            input: {
              ids: [Number(InstId)],
              std_demand_query_type:
                AcctStudentQueryType.DEMAND_RAISED_NOT_PAID_BY_INST_ID,
            },
            name: EMPTY_STRING,
            token,
            status: EMPTY_STRING,
            balance: null,
            stdFresher: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Sucessfully Updated data",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (studentsData.data && studentsData.data.GetAcctStdDemand) {
        const newSelecteds = studentsData.data?.GetAcctStdDemand.edges.map(
          (n) => n.node.mst_student.id.toString()
        );
        setSelected(newSelecteds!);
        return;
      }
    } else {
      setSelected([]);
    }
  };
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleClear = () => {
    setBranchId(null);
    setClassId(null);
    setCategoryId(null);
    setSelected([]);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        studentsData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students!, ...newEdges],
                pageInfo,
                totalCount: studentsData?.data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const isSelected = (name: string) => setarray.has(name);
  useEffect(() => {
    if (studentsData.data && !loading && token) {
      const newData = studentsData.data.GetAcctStdDemand.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) =>
              student.node.id === (newStudent.node as StudentDemandNode).id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: true,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData as StudentDemandEdges[]);
      } else {
        setStudents(newData as StudentDemandEdges[]);
      }
      setEndCursor(studentsData.data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [studentsData.data, loading, token]);
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {Accounts_Table.FeeDemand.DeleteFeeDemand.Table_Headers.map(
            (th: TableHeaderProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>
                  <TableCell className={th.className}>{th.labelName}</TableCell>
                </React.Fragment>
              );
            }
          )}
        </TableRow>
      </TableHead>
    );
  };
  const { branchLabel ,classLabel,categoryLabel} = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.FeeDemand.Titles.map(
          (title: FeeDemandTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.Delete_Fee_Demand}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="feedemand__delete">
        <div className="row g-0 feedemand__delete--block">
          <div className="col-5 account-frames h-100">
            <div className="label-grid">
              {USE_BRANCH_KEY ? (
                <>
                  <Label>{branchLabel}</Label>

                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={branchDropDown}
                    openOnFocus
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, branchId)
                    }
                    value={branchId}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (branchId && e.key === Keys.ENTER) {
                        classInputRef?.focus();
                      } else if (e.key === Keys.BACKSPACE) {
                        setBranchId(null);
                      }
                    }}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setBranchId(newValue as responseType);
                      } else {
                        setBranchId(null);
                      }
                      setClassId(null);
                      setCategoryId(null);
                      setSelected([]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        autoFocus
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : null}

              {USE_CLASS_KEY ? (
                <>
                  <Label>{classLabel}</Label>

                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={classDropDown!}
                    ref={classRef}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, classId)
                    }
                    openOnFocus
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER && classId) {
                        categoryInputRef?.focus();
                      } else if (e.key === Keys.BACKSPACE) {
                        setClassId(null);
                      }
                    }}
                    value={classId}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setClassId(newValue as responseType);
                      } else {
                        setClassId(null);
                      }
                      setCategoryId(null);
                      setSelected([]);
                    }}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : null}
              {USE_CATEGORY_KEY ? (
                <>
                  <Label>{categoryLabel}</Label>

                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={categoryDropDown}
                    openOnFocus
                    value={categoryId}
                    ref={categoryRef}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, categoryId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setCategoryId(newValue as responseType);
                      } else {
                        setCategoryId(null);
                      }
                      setSelected([]);
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.BACKSPACE) {
                        setCategoryId(null);
                      }
                    }}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="feedemand__delete--tableblock">
          {branchId && classId && categoryId ? (
            <>
              <div className="feedemand__delete--subtitle">
                <EnhancedTableToolbar numSelected={selected.length} />
              </div>
              {!studentsData.loading && !students?.length ? (
                <b className="nodata">Sorry, no students</b>
              ) : (
                <>
                  <TableContainer
                    className="feedemand__delete--table"
                    onScroll={handleScroll}
                  >
                    <Table stickyHeader>
                      <EnhancedTableHead
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={totalCount ? totalCount : 0}
                      />
                      <TableBody>
                        {students.map((edge, index) => {
                          const isItemSelected = isSelected(
                            edge.node.mst_student.id.toString()
                          );
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(
                                  event,
                                  edge.node.mst_student.id.toString()
                                )
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              key={edge.node.mst_student.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox" align="center">
                                <Checkbox checked={isItemSelected} />
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="feedemand__delete--table--slno"
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell className="feedemand__delete--table--regno">
                                {edge.node.mst_student.std_adm_no}
                              </TableCell>
                              <TableCell className="feedemand__delete--table--regno">
                                {edge.node.mst_student.std_reg_no}
                              </TableCell>
                              <TableCell id={labelId}>
                                {edge.node.mst_student.first_name +
                                  " " +
                                  edge.node.mst_student.middle_name +
                                  " " +
                                  edge.node.mst_student.last_name}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="feedemand__delete--table--amt"
                              >
                                {format(edge.node.std_demand_amt)}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className="feedemand__delete--table--amt"
                              >
                                {format(edge.node.std_demand_bal)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          ) : (
            <b className="nodata">Select all the fields</b>
          )}
        </div>

        <div className="row g-0">
          <div className="col">
            <Button
              mode="delete"
              onClick={() => setDeleteModal(!deleteModal)}
              disabled={
                !selected.length || !branchId || !classId || USE_CATEGORY_KEY
                  ? !categoryId
                  : false
              }
            />
            <Button mode="clear" onClick={handleClear} />
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 feedemand__delete--total">
            <StudentTotalCount totalCount={totalCount} />
          </div>
        </div>
      </div>
      <DeleteModal
        id={0}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default DeleteFeeDemandForParticularStudent;

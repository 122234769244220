import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import useToken from "../../../customhooks/useToken";
import { GetServerDateAndTime } from "../../../queries/Library/ServerDateTime/byTime";
interface ServerDateTimeData {
  GetServerDateAndTime: string;
}
interface ServerDateTimeVars {
  token: string;
}

const useServerDateandTime = () => {
  const { token } = useToken();
  const [serverDate, setServerDate] = useState("");
  const [GetDate, { data, loading }] = useLazyQuery<
    ServerDateTimeData,
    ServerDateTimeVars
  >(GetServerDateAndTime, {
    variables: {
      token,
    },
  });
  useEffect(() => {
    if (token) GetDate();
  }, [token, GetDate]);
  useEffect(() => {
    if (data && !loading) {
      setServerDate(data.GetServerDateAndTime);
    }
  }, [data, loading, token]);
  return {
    serverDate,
    data,
    loading,
  };
};
export default useServerDateandTime;

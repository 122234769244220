import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import useToken from "../../../customhooks/useToken";
import {
  AcademicYear,
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../../../Types/Student";
import { GetAcdStudentDetails } from "../queries/student";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useInstDetails from "../../../customhooks/general/useInstDetails";
interface StudentDetails {
  std_corr_address: string;
}
export interface AcdStudentDetails {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  std_reg_no: string;
  std_adm_no: string;
  acd_yr: AcademicYear;
  acd_yr_id: number;
  fin_yr_id: number;
  std_father_name: string;
  std_studying: boolean;
  std_sex: string;
  std_doa: string;
  std_fresher: boolean;
  std_mobile: string;
  std_status: string;
  std_email: string;
  std_mother_name: string;
  std_dob: string;
  std_profile_filename: string;
  std_details: StudentDetails;
  acd_class: ClassList;
  acd_branch: BranchesList;
  acd_dept: DepartmentList;
  acd_semester: SemesterList;
  acd_section: SectionList;
  category: CategoryList;
}

export interface StudentAcademicData {
  nodes: AcdStudentDetails[];
}
export interface StudentAcademicVars {
  token: string;
  ids: number[];
}
interface form {
  std_name: string;
  reg_number: string;
  branch: string;
  father_name: string;
  mother_name: string;
  class: string;
  dept: string;
  semester: string;
  section: string;
  adm_no: string;
  acd_yr: string;
  category: string;
  std_profile_pic: string;
}
const useAcdStudentDetails = () => {
  const { state } = useContext(AppContext);

  const { InstDetails } = useInstDetails(1);

  const [studentFormData, setStudentFormData] = useState<form>({
    acd_yr: "",
    branch: "",
    class: "",
    dept: "",
    father_name: "",
    mother_name: "",
    reg_number: "",
    section: "",
    semester: "",
    std_name: "",
    adm_no: "",
    category: "",
    std_profile_pic: "",
  });
  const { token } = useToken();
  const [stdId, setStdId] = useState(0);
  const { studentId: id } = useParams();
  const { entry_level } = useInstitutionConfiguration();
  const [entryIdFromStudent, setEntryIdFromStudent] = useState(0);
  const handleClear = () => {
    setStudentFormData({
      acd_yr: "",
      branch: "",
      class: "",
      dept: "",
      father_name: "",
      mother_name: "",
      reg_number: "",
      section: "",
      semester: "",
      std_name: "",
      adm_no: "",
      category: "",
      std_profile_pic: "",
    });
  };
  const [GetStudentDetailsByIds, GetStudentAcademicDetailsByIdsQuery] =
    useLazyQuery<StudentAcademicData, StudentAcademicVars>(
      GetAcdStudentDetails,
      {
        variables: { token, ids: [stdId] },
        fetchPolicy: "network-only",
      }
    );
  useEffect(() => {
    if (state.studentId) {
      setStdId(state.studentId);
    }
    if (id) {
      setStdId(Number(id));
    }
    if (state.studentId === 0) {
      handleClear();
    }
  }, [state.studentId, id]);
  useEffect(() => {
    if (stdId && token) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${stdId}/std_profile_pic/std_profile_pic`;
      GetStudentDetailsByIds().then(({ data }) => {
        if (data) {
          const {
            acd_branch,
            acd_class,
            acd_dept,
            acd_section,
            acd_semester,
            first_name,
            last_name,
            middle_name,
            std_adm_no,
            std_reg_no,
            std_father_name,
            std_mother_name,
            acd_yr,
            category,
          } = data.nodes[0];

          setStudentFormData({
            branch: acd_branch.branch_desc,
            class: acd_class.class_desc,
            dept: acd_dept.dept_desc,
            father_name: std_father_name,
            mother_name: std_mother_name,
            reg_number: std_reg_no,
            section: acd_section.section_desc,
            semester: acd_semester.sem_desc,
            std_name: first_name + " " + middle_name + " " + last_name,
            adm_no: std_adm_no,
            acd_yr: acd_yr.acd_yr,
            category: category.cat_desc,
            std_profile_pic: studentProfiePicUrl,
          });
          if (entry_level === "SEC") {
            setEntryIdFromStudent(acd_section.id);
          } else if (entry_level === "SEM") {
            setEntryIdFromStudent(acd_semester.id);
          } else {
            setEntryIdFromStudent(acd_class.id);
          }
        }
      });
    }
  }, [stdId, GetStudentDetailsByIds, token, entry_level, InstDetails.data]);
  return {
    studentAcademicData: GetStudentAcademicDetailsByIdsQuery,
    studentFormData,
    entryIdFromStudent,
  };
};

export default useAcdStudentDetails;

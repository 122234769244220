import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import HRImport from "../../Imports/MastersImport";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { HRTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import UserRightsHome from "../../../UserRights/Home/Index";

import EditProfile from "../../../../images/EditProfile.svg";
import Close from "../../../../images/Close.svg";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import {
  getModifiedScrollHeight,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { LabelNameProps } from "../../../../Types/Labels";
import {
  Direction,
  LicenseTypes,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import InputHoc from "../../../../components/common/Input/Index";
import {
  CommonNodeVars,
  PayRollNodeDesignationData,
} from "../../Types/masterDataTypes";
import { Form, Formik } from "formik";
import { payroleDesignationSchema } from "../../../../utils/payrole";

import useToken from "../../../../customhooks/useToken";
import { useLazyQuery, useMutation } from "@apollo/client";
import { msgType } from "../../../../utils/Form.types";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import {
  GetPayRollDesignationNode,
  GetPayRollDesignationsByInstId,
} from "../../queries/designation/query";
import {
  AddPayRollDesignation,
  DeletePayRollDesignationById,
  UpdatePayRollDesignation,
} from "../../queries/designation/mutation";

import { PayRollDesignationDetailsFormType } from "../../Types/formTypes";
import DeleteModal from "../../../../pages/DeleteModal";
import Delete from "../../../../images/Delete.svg";
import { GetPayRollMasterData } from "../../queries/general/list";
import {
  ComponentFor,
  PayRollMasters,
  PayRollSortBy,
} from "../../enums/Enum.types";
import { designation_desc } from "../../constants";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import usePayRollMasters from "../../hooks/usePayRollMasters";
import { PayRollDesignationEdges } from "../../Types/paginationTypes";
import useMastersExport from "../../Imports/useMastersExport";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");
const { HRFormLabels } = require("../../json/form.json");

interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  type: ComponentFor;
  LicenseType: LicenseTypes;
}

const Index = ({ pageType, setModalFlag, type, LicenseType }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();

  const [deleteModal, setDeleteModal] = useState(false);
  const [payrollDesignationId, setPayrollDesignationId] = useState(0);
  const [operation, setOperation] = useState(Operation.CREATE);

  const [formData, setFormData] = useState<PayRollDesignationDetailsFormType>({
    designation_desc: "",
  });
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [searchData, setSearchData] = useState("");
  const [importModal, setImportModal] = useState(false);
  const [designations, setDesignations] = useState<PayRollDesignationEdges[]>(
    []
  );
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const designationDescRef = document.getElementsByName(designation_desc)[0];
  const [GetDesignationDetailsById] = useLazyQuery<
    PayRollNodeDesignationData,
    CommonNodeVars
  >(GetPayRollDesignationNode);
  const exportExcel = useMastersExport(PayRollMasters.DESIGNATION);
  const [AddDesignation, { loading: creationLoading }] = useMutation(
    AddPayRollDesignation,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteDesignation, { loading: deleteLoading }] = useMutation(
    DeletePayRollDesignationById,
    {
      onError: (e) => {
        setDeleteModal(!deleteModal);
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
      },
    }
  );
  const [UpdateDesignation, { loading: updationLoading }] = useMutation(
    UpdatePayRollDesignation,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    designationDetails: { data, loading, fetchMore },
  } = usePayRollMasters(PayRollMasters.DESIGNATION, searchData, ROWS_PER_PAGE);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
      if (pageType === PageFor.MODAL) {
        setModalFlag(false);
      }
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handleClear = () => {
    setFormData({
      designation_desc: "",
    });
    if (operation === Operation.UPDATE) {
      setOperation(Operation.CREATE);
    }
    designationDescRef.focus();
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges =
              fetchMoreResult.GetPayRollDesignationsByInstId.edges;
            const pageInfo =
              fetchMoreResult.GetPayRollDesignationsByInstId.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollDesignationsByInstId.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollDesignationsByInstId: {
                edges: [...designations!, ...newEdges],
                pageInfo,
                totalCount: data?.GetPayRollDesignationsByInstId.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const handleSubmitForm = () => {
    if (operation === Operation.CREATE) {
      AddDesignation({
        variables: {
          token,
          inst_id: InstId,
          user_details,

          input: {
            designation_desc: removeMoreSpace(formData.designation_desc),
          },
        },
        refetchQueries: [
          {
            query: GetPayRollDesignationsByInstId,
            variables: {
              first: ROWS_PER_PAGE,
              after: null,
              sortBy: SortBy.DESIGNATION_DESC,
              direction: Direction.ASC,
              token,
              name: searchData,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSearchData(EMPTY_STRING);
          setPage(0);
          setMessage({
            flag: true,
            message: "Designation Created Sucessfully",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateDesignation({
        variables: {
          token,
          id: payrollDesignationId,
          inst_id: InstId,
          user_details,

          input: {
            designation_desc: removeMoreSpace(formData.designation_desc),
          },
        },
        refetchQueries: [
          {
            query: GetPayRollDesignationNode,
            variables: {
              token,
              id: payrollDesignationId,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetPayRollMasterData,
            variables: {
              token,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSearchData(EMPTY_STRING);
          setMessage({
            flag: true,
            message: "PayRoll Designation Updated Sucessfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteDesignation({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollDesignationsByInstId,
          variables: {
            first: ROWS_PER_PAGE,
            after: null,
            sortBy: PayRollSortBy.DESIGNATION_DESC,
            direction: Direction.ASC,
            token,
            name: searchData,
            inst_id: InstId!,
          },
          fetchPolicy: "network-only",
        },
        {
          query: GetPayRollMasterData,
          variables: {
            token,
            inst_id: InstId!,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setSearchData(EMPTY_STRING);
        setPage(0);
        setMessage({
          flag: true,
          message: "PayRoll Category Deleted Sucessfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  useEffect(() => {
    if (operation === Operation.UPDATE) {
      GetDesignationDetailsById({
        variables: {
          token,
          id: payrollDesignationId,
        },
      }).then(({ data }) => {
        if (data) {
          const { designation_desc } = data.node;
          setFormData({
            designation_desc,
          });
        }
      });
    }
  }, [payrollDesignationId, operation, token, GetDesignationDetailsById]);
  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetPayRollDesignationsByInstId.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredDeignations = designations.filter(
          (designation) => !designation.node.isChecked
        );

        const updatedNewData = newData.map((newDesignation) => {
          const filteredDesignation = filteredDeignations.find(
            (designation) => designation.node.id === newDesignation.node.id
          );
          if (filteredDesignation) {
            return {
              ...newDesignation,
              node: {
                ...newDesignation.node,
                isChecked: true,
              },
            };
          }
          return newDesignation;
        });
        setDesignations(updatedNewData);
      } else {
        setDesignations(newData);
      }
      setEndCursor(data.GetPayRollDesignationsByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);
  return (<>
    {pageType === PageFor.GENERAL &&
      type === ComponentFor.MASTERS &&
      (LicenseType === LicenseTypes.PAYROLL ? (
        <Home DashBoardRequired={false} />
      ) : (
        (<UserRightsHome DashBoardRequired={false} />)
        // Render something else for different LicenseType
      ))}
    <Title>
      {HRTitles.MasterData.Designation.Titles.map(
        (title: HRTitleProps, index: React.Key) => {
          return <React.Fragment key={index}>{title.Title}</React.Fragment>;
        }
      )}
    </Title>
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={payroleDesignationSchema}
      onSubmit={handleSubmitForm}
    >
      {(meta) => {
        return (
          <Form
            className={
              pageType === PageFor.GENERAL && type === ComponentFor.MASTERS
                ? "payroll-masterdata"
                : pageType === PageFor.MODAL
                ? "payroll-masterdata--modal"
                : "payroll-masterdata--organization"
            }
          >
            <div className="row g-0 payroll-masterdata__frame">
              <div className="col booktype-left h-100">
                <div className="payroll-masterdata__title">
                  <Title variant="subtitle1">
                    {HRTitles.MasterData.Designation.Titles.map(
                      (title: HRTitleProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            {operation === Operation.UPDATE
                              ? title.Update
                              : title.Add}
                          </React.Fragment>
                        );
                      }
                    )}
                  </Title>
                </div>
                <div className="payroll-masterdata__block">
                  {HRFormLabels.MasterData.Designation.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <React.Fragment key={index}>
                            <InputHoc
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              autoFocus
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              required={label.required ? true : false}
                            />
                          </React.Fragment>
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="col booktype-right h-100">
                <div className="row g-0 payroll-masterdata__title">
                  <div className="col-3"></div>
                  <div className="col">
                    <Title variant="subtitle1">
                      {HRTitles.MasterData.Designation.Titles.map(
                        (title: HRTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.List}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="col-3">
                    <Input
                      type="text"
                      id="search"
                      placeholder="Search..."
                      onChange={(e) => setSearchData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="payroll-masterdata__block">
                  {!designations?.length && !loading ? (
                    <b className="nodata">Sorry, No designations Found</b>
                  ) : (
                    <TableContainer
                      className="payroll-masterdata__block--table"
                      onScroll={handleScroll}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {HR_Table.MasterData.Table_Headers.Designation.map(
                              (th: TableHeaderProps, index: React.Key) => {
                                return (
                                  <TableCell
                                    key={index}
                                    className={th.className}
                                  >
                                    {th.labelName}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {designations.map((edge, index) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell
                                  className="payroll-masterdata__block--table--slno"
                                  id="td-center"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell>
                                  {edge.node.designation_desc}
                                </TableCell>
                                <TableCell
                                  className="payroll-masterdata__block--table--actions"
                                  id="td-center"
                                >
                                  <>
                                    <img
                                      src={EditProfile}
                                      alt="/"
                                      onClick={() => {
                                        setOperation(Operation.UPDATE);
                                        setPayrollDesignationId(edge.node.id);
                                        designationDescRef.focus();
                                      }}
                                    />
                                    <img
                                      src={Delete}
                                      alt="/"
                                      onClick={() => {
                                        setPayrollDesignationId(edge.node.id);
                                        setDeleteModal(!deleteModal);
                                      }}
                                    />
                                  </>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              </div>
            </div>
            <>
              <Button mode="save" type="submit" />
              <Button mode="clear" onClick={handleClear} type="button" />

              {pageType === PageFor.GENERAL ? (
                <>
                  <Button
                    mode="excel"
                    type="button"
                    onClick={() => setImportModal(!importModal)}
                  >
                    Import Designations
                  </Button>
                  <Button type="button" mode="export" onClick={exportExcel} />

                  <Button
                    mode="back"
                    onClick={() => navigate(-1)}
                    type="button"
                  />
                </>
              ) : (
                <Button
                  mode="cancel"
                  onClick={() => setModalFlag(false)}
                  type="button"
                />
              )}
            </>
          </Form>
        );
      }}
    </Formik>
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={importModal}
      style={StudentModalStyles}
      ariaHideApp={false}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <HRImport MasterType={PayRollMasters.DESIGNATION} />
        </div>
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </div>
    </Modal>
    <LoadingModal
      flag={creationLoading || loading || deleteLoading || updationLoading}
    />
    <MessageModal
      modalFlag={message.flag!}
      value={message.message!}
      handleClose={handleClose}
      operation={message.operation!}
    />
    <DeleteModal
      modalFlag={deleteModal}
      setModalFlag={setDeleteModal}
      handleDelete={handleDelete}
      id={payrollDesignationId}
    />
  </>);
};

export default Index;

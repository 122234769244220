import { gql } from "@apollo/client";

export const GetLibFineByInstId = gql`
  query GetLibFineByInstId($inst_id: ID!, $token: String!) {
    GetLibFineByInstId(token: $token, inst_id: $inst_id) {
      id
      lib_fine_desc
      lib_fine_for_student
      lib_fine_for_staff
      lib_slab1_from
      lib_slab1_to
      lib_slab1_fine
      lib_slab2_from
      lib_slab2_to
      lib_slab2_fine
      lib_slab3_from
      lib_slab3_to
      lib_slab3_fine
      lib_slab4_from
      lib_slab4_to
      lib_slab4_fine
      lib_slab5_from
      lib_slab5_to
      lib_slab5_fine
      inst_id
    }
  }
`;

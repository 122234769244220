import { gql } from "@apollo/client";

export const GetBranchAndClassDetailsForSchoolPosting = gql`
  query GetBranchAndClassDetailsForSchoolPosting(
    $token: String!
    $inst_id: ID!
  ) {
    GetBranchAndClassDetailsForSchoolPosting(token: $token, inst_id: $inst_id) {
      branch_desc
      branch_idx
      id
      class_details {
        class_desc
        class_idx
        id
        semester_details {
          semester_desc
          semester_idx
          id
          student_count
        }
      }
    }
  }
`;

import { useState, useEffect, ChangeEvent, useContext } from "react";
import { IDCardConfigTypes } from "../Templates/Portrait/FullyCustomizable/Template2";
import { useMutation } from "@apollo/client";
import useIdcardConfig from "./useIdcardConfig";
import { UpdateIdCardTemplate } from "../mutation";
import { GetIdCardTemplate } from "../queries";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { Operation } from "../../../../utils/Enum.types";
import { msgType } from "../../../../utils/Form.types";
import { AppContext } from "../../../../context/context";

const useUpdateIdCardTemplate = (TemplateName: string) => {
  const [formData, setFormData] = useState<IDCardConfigTypes>({
    id_tpl_desc: "",
    bg_color: "",
    header_color: "",
    footer_color: "",
    text_color: "",
    theme: "",
  });

  const [updateIdCard] = useMutation(UpdateIdCardTemplate);
  const { data, loading } = useIdcardConfig();
  const { token } = useToken();
  const { state } = useContext(AppContext);

  const { user_details } = useLoggedInUserDetails();
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name.includes("color") ? value : value,
    }));
  };

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { InstId } = useParams();
  const handleUpdate = () => {
    if (InstId)
      updateIdCard({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          id_card_tpl_id: state.templateId,
          input: {
            id_tpl_desc: TemplateName,
            bg_color: formData.bg_color,
            header_color: formData.header_color,
            footer_color: formData.footer_color,
            text_color: formData.text_color,
            theme: formData.theme,
          },
        },
        refetchQueries: [
          {
            query: GetIdCardTemplate,
            variables: {
              token,
              inst_id: InstId,
              id_card_tpl_id: formData.id,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Template Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setFormData({
      id_tpl_desc: "",
      bg_color: "",
      header_color: "",
      footer_color: "",
      text_color: "",
      theme: "",
    });
  };
  useEffect(() => {
    if (data && !loading) {
      setFormData({
        ...data.GetIdCardTemplate,
      });
    }
  }, [data, loading]);

  return {
    formData,
    handleInputChange,
    handleUpdate,
    message,
    handleClose,
    handleClear,
  };
};

export default useUpdateIdCardTemplate;

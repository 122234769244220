import React, { useState, Key, useRef, useContext, useEffect } from "react";
import Modal from "react-modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { Button } from "../../../stories/Button/Button";
import { Label } from "../../../stories/Label/Label";

import { Title } from "../../../stories/Title/Title";
import { LabelNameProps } from "../../../Types/Labels";
import { NonDemandFeePayableTitleProps } from "../../../Types/Titles";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import InputHoc from "../../../components/common/Input/Index";
import {
  StudentAluminiInput,
  optionsType,
  responseType,
} from "../../../utils/Form.types";
import { studentAluminiValidation } from "../../../utils/validationRules";
import { AddStudentPassout } from "../../../queries/students/mutations/new";

import { useParams } from "react-router-dom";
import useNotAlloactedIdFromInst from "../../../customhooks/useNotAlloactedIdFromInst";
import {
  GetStudentsPassoutByInstId,
  GetStudentsPassoutByStudentId,
} from "../../../queries/students/list/byId";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import {
  getApiErrorMessage,
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import DownArrow from "../../../images/DownArrow.svg";
import Close from "../../../images/Close.svg";
import { TextField } from "@mui/material";
import useToken from "../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../utils/Enum.keys";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import LoadingModal from "../../../pages/LoadingModal";
import ErrorModal from "../../../pages/ErrorModal";
import { GetInstDashBoardCounters } from "../../../queries/institution/masterDashboard/new";
import { UpdateStudentPassout } from "../../../queries/students/mutations/update";
import { AppContext } from "../../../context/context";
import {
  StudentsPassoutByStudentIdData,
  StudentsPassoutByStudentIdVars,
} from "../../../Types/Student/paginationTypes";
import { payloadTypes } from "../../../context/reducer";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import TextArea from "../../../stories/TextArea/TextArea";
import PhoneInput from "react-phone-input-2";
import {
  formClasses,
  RequiredAutoComplete,
} from "../../../styles/AutocompleteStyles";

const { AccountsTitles } = require("../../Accounts/json/title.json");
const { StudentAluminiLabels } = require("../../../json/config.json");
interface Props {
  openModal: boolean;
  setOpenModal: (flag: boolean) => void;
  operation: Operation;
}

const AddPassoutStudentModal = ({
  openModal,
  setOpenModal,
  operation,
}: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { dispatch, state } = useContext(AppContext);
  const saveRef = useRef<HTMLButtonElement>(null);
  const nameRef = document.getElementsByName("std_name")[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classRefInput = classRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLSelectElement;
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchRefInput = branchRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLSelectElement;
  const [formData, setFormData] = useState<StudentAluminiInput>({
    std_name: "",
    std_passout_yr: "",
    std_mobile: "",
    std_email: "",
    father_name: "",
    organisation_address: "",
    placement_by: "",
    present_organisation: "",
    residential_address: "",
    std_dob: "",
    yrs_experience: 0,
    employed: "",
  });
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [comments, setComments] = useState("");
  const [gender, setGender] = useState<optionsType | null>(null);
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [employedSelected, setEmployedSelected] = useState("");
  const { user_details } = useLoggedInUserDetails();
  const [GetAluminiStudentData, { data }] = useLazyQuery<
    StudentsPassoutByStudentIdData,
    StudentsPassoutByStudentIdVars
  >(GetStudentsPassoutByStudentId, {
    variables: {
      token,
      id: state.aluminiStudentId,
    },
  });
  const Gender = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];
  const [AddAluminiStudentDetails, { loading, error }] =
    useMutation(AddStudentPassout);
  const [UpdateAluminiStudentDetails, { loading: UpdateLoading }] =
    useMutation(UpdateStudentPassout);
  const { NotAllocatedBranchId, NotAllocatedClassId } =
    useNotAlloactedIdFromInst();
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const { USE_BRANCH_KEY, USE_CLASS_KEY } = useInstitutionConfiguration();
  const { branchDropDown } = useInstMasterDataByInstId();

  const { classDropDown } = useDropdownData(
    0,
    branchSelected ? branchSelected.value : 0,
    0,
    0
  );
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      std_mobile: mobileNo,
    }));
  };
  const emplyedDropDown = ["Yes", "No"];
  const HandlePassoutStudentDetails = () => {
    if (operation === Operation.CREATE) {
      AddAluminiStudentDetails({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          input: {
            std_name: removeMoreSpace(formData.std_name),
            std_passout_yr: formData.std_passout_yr,
            std_email: removeMoreSpace(formData.std_email),
            std_mobile: formData.std_mobile
              ? `+${formData.std_mobile}`
              : EMPTY_STRING,
            inst_id: InstId,
            class_id: classSelected ? classSelected.value : NotAllocatedClassId,
            branch_id: branchSelected
              ? branchSelected.value
              : NotAllocatedBranchId,
            residential_address: formData.residential_address,
            employed: formData.employed,
            present_organisation: formData.present_organisation,
            organisation_address: formData.organisation_address,
            yrs_experience: formData.yrs_experience,
            placement_by: formData.placement_by,
            comments: comments,
            father_name: formData.father_name,
            std_dob: toIsoDate(formData.std_dob),
            std_gender: employedSelected,
          },
        },
        refetchQueries: [
          {
            query: GetStudentsPassoutByInstId,
            variables: {
              token,
              inst_id: InstId!,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              after: null,
              branch: null,
              class: null,
              orderBy: SortBy.STD_NAME,
              direction: Direction.ASC,
            },
          },
          {
            query: GetInstDashBoardCounters,
            variables: {
              inst_id: InstId!,
              token,
            },
          },
        ],
      });
      handleClear();
      setOpenModal(!openModal);
    } else {
      UpdateAluminiStudentDetails({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          id: state.aluminiStudentId,
          input: {
            std_name: removeMoreSpace(formData.std_name),
            std_passout_yr: formData.std_passout_yr,
            std_email: removeMoreSpace(formData.std_email),
            std_mobile: formData.std_mobile
              ? `+${formData.std_mobile}`
              : EMPTY_STRING,
            class_id: classSelected ? classSelected.value : NotAllocatedClassId,
            branch_id: branchSelected
              ? branchSelected.value
              : NotAllocatedBranchId,
            residential_address: formData.residential_address,
            employed: formData.employed,
            present_organisation: formData.present_organisation,
            organisation_address: formData.organisation_address,
            yrs_experience: formData.yrs_experience,
            placement_by: formData.placement_by,
            comments: comments,
            father_name: formData.father_name,
            std_dob: toIsoDate(formData.std_dob),
            std_gender: employedSelected,
          },
        },
        refetchQueries: [
          {
            query: GetStudentsPassoutByInstId,
            variables: {
              token,
              inst_id: InstId!,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              after: null,
              branch: null,
              class: null,
              orderBy: SortBy.STD_NAME,
              direction: Direction.ASC,
            },
          },
          {
            query: GetInstDashBoardCounters,
            variables: {
              inst_id: InstId!,
              token,
            },
          },
        ],
      });
      handleClear();
      setOpenModal(!openModal);
    }
  };
  const handleClear = () => {
    setFormData({
      std_name: "",
      std_passout_yr: EMPTY_STRING,
      std_mobile: EMPTY_STRING,
      std_email: EMPTY_STRING,
      father_name: EMPTY_STRING,
      organisation_address: "",
      placement_by: "",
      present_organisation: "",
      residential_address: "",
      std_dob: "",
      yrs_experience: 0,
      employed: "",
      email: EMPTY_STRING,
      mobile: EMPTY_STRING,
    });
    dispatch({
      type: payloadTypes.SET_ALUMINI_STUDENT_ID,
      payload: {
        aluminiStudentId: 0,
      },
    });
    setBranchSelected(null);
    setClassSelected(null);
    nameRef?.focus();
  };

  useEffect(() => {
    if (state.aluminiStudentId && operation === Operation.UPDATE) {
      GetAluminiStudentData().then(({ data }) => {
        if (data && data.node) {
          setFormData({
            std_name: data.node.std_name,
            std_passout_yr: data.node.std_passout_yr,
            std_mobile: data.node.std_mobile,
            std_email: data.node.std_email,
            father_name: data.node.father_name,
            organisation_address: data.node.organisation_address,
            placement_by: data.node.placement_by,
            present_organisation: data.node.present_organisation,
            residential_address: data.node.residential_address,
            std_dob: toInputStandardDate(data.node.std_dob),
            yrs_experience: 0,
            employed: data.node.employed,
            email: data?.node.std_email ?? EMPTY_STRING,
            mobile: data?.node.std_mobile,
          });
          setBranchSelected({
            label: data?.node.branch_details.branch_desc,
            value: data?.node.branch_details.id,
          });
          setClassSelected({
            label: data?.node.class_details.class_desc,
            value: data?.node.class_details.id,
          });
        }
      });
    }
  }, [state.aluminiStudentId, data, operation, GetAluminiStudentData]);
  const { branchLabel } = useInstLabels();
  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={openModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="alumni-students">
          <div className="alumni-students__title">
            <Title>
              {AccountsTitles.NonDemandFeePayable.Titles.map(
                (title: NonDemandFeePayableTitleProps, index: Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.Student_Details}
                    </React.Fragment>
                  );
                }
              )}
            </Title>
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setOpenModal(!openModal);
                handleClear();
              }}
            />
          </div>

          <Formik
            initialValues={formData}
            validationSchema={studentAluminiValidation}
            onSubmit={HandlePassoutStudentDetails}
            enableReinitialize
          >
            {(meta) => {
              return (
                <Form className="alumni-students__data">
                  <div className="alumni-students__data--details">
                    <div className="row g-0 alumni-students__data--details--row">
                      <div className="col">
                        <div className="details">
                          <h4>Personal Details</h4>
                        </div>
                        {StudentAluminiLabels.PersonalDetails.map(
                          (label: LabelNameProps, index: Key) => {
                            return (
                              <React.Fragment key={index}>
                                <InputHoc
                                  name={label.inputName}
                                  LabelName={label.LabelName}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleValueChange(e);
                                    meta.handleChange(e);
                                  }}
                                  autoFocus={label.autoFocus}
                                  values={formData[label.inputName]}
                                  type={label.dataType}
                                  required={label.required}
                                  maxLength={label.maxLength}
                                />
                              </React.Fragment>
                            );
                          }
                        )}

                        <div className="label-grid">
                          <Label>Gender</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            options={Gender!}
                            value={gender}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (gender?.value) {
                                  branchRefInput?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setBranchSelected(null);
                                setClassSelected(null);
                              }
                            }}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(
                                option as optionsType,
                                gender
                              )
                            }
                            openOnFocus
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setGender(newValue as optionsType);
                              } else {
                                setGender(null);
                              }
                            }}
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                required
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                          {USE_BRANCH_KEY ? (
                            <>
                              <Label>{branchLabel}</Label>
                              <RequiredAutoComplete
                                className={formClasses.inputRoot}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    if (branchSelected) {
                                      classRefInput?.focus();
                                    }
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setBranchSelected(null);
                                    setClassSelected(null);
                                  }
                                }}
                                isOptionEqualToValue={(option) =>
                                  isOptionEqualToValue(
                                    option as responseType,
                                    branchSelected
                                  )
                                }
                                options={branchDropDown}
                                value={branchSelected}
                                renderOption={(props, option) => {
                                  return (
                                    <li
                                      {...props}
                                      key={(option as responseType).value}
                                    >
                                      {(option as responseType).label}
                                    </li>
                                  );
                                }}
                                openOnFocus
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setBranchSelected(newValue as responseType);
                                  } else {
                                    setBranchSelected(null);
                                  }
                                  setClassSelected(null);
                                }}
                                ref={branchRef!}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    required
                                    className={formClasses.formControlRoot}
                                  />
                                )}
                              />
                            </>
                          ) : null}
                          {USE_CLASS_KEY ? (
                            <>
                              <Label>Class</Label>
                              <RequiredAutoComplete
                                className={formClasses.inputRoot}
                                ref={classRef!}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    if (classSelected) {
                                      handleMUISelectEvent(e);
                                    }
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setClassSelected(null);
                                  }
                                }}
                                isOptionEqualToValue={(option) =>
                                  isOptionEqualToValue(
                                    option as responseType,
                                    classSelected
                                  )
                                }
                                options={classDropDown}
                                value={classSelected}
                                openOnFocus
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setClassSelected(newValue as responseType);
                                  } else {
                                    setClassSelected(null);
                                  }
                                }}
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    required
                                    className={formClasses.formControlRoot}
                                  />
                                )}
                              />
                            </>
                          ) : null}
                        </div>

                        <React.Fragment>
                          <InputHoc
                            name="std_passout_yr"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            values={formData.std_passout_yr}
                            required={true}
                            // autoFocus={label.autoFocus}
                            LabelName="Pass Out Year"
                          />
                        </React.Fragment>
                      </div>
                      <div className="col">
                        <div className="details">
                          <h4>Contact Details</h4>
                        </div>
                        <div className="label-grid">
                          <Label>Mobile</Label>
                          <PhoneInput
                            country={"in"}
                            value={formData.std_mobile}
                            onChange={handleNumberChange}
                            inputProps={{
                              required: true,
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                            }}
                          />
                        </div>
                        {StudentAluminiLabels.ContactDetails.map(
                          (label: LabelNameProps, index: Key) => {
                            return (
                              <React.Fragment key={index}>
                                <InputHoc
                                  name={label.inputName}
                                  LabelName={label.LabelName}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleValueChange(e);
                                    meta.handleChange(e);
                                  }}
                                  autoFocus={label.autoFocus}
                                  values={formData[label.inputName]}
                                  type={label.dataType}
                                  required={label.required}
                                  maxLength={label.maxLength}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="row g-0 frame-space alumni-students__data--details--row">
                      <div className="details">
                        <h4>Other Details</h4>
                      </div>
                      <div className="col-6">
                        <div className="label-grid">
                          <Label>Employed</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (employedSelected) {
                                  handleMUISelectEvent(e);
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setEmployedSelected(EMPTY_STRING);
                              }
                            }}
                            options={emplyedDropDown!}
                            value={employedSelected}
                            openOnFocus
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setEmployedSelected(newValue as string);
                              } else {
                                setEmployedSelected(EMPTY_STRING);
                              }
                            }}
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                required
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                        </div>
                        {StudentAluminiLabels.OtherDetails.map(
                          (label: LabelNameProps, index: Key) => {
                            return (
                              <React.Fragment key={index}>
                                <InputHoc
                                  name={label.inputName}
                                  LabelName={label.LabelName}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleValueChange(e);
                                    meta.handleChange(e);
                                  }}
                                  autoFocus={label.autoFocus}
                                  values={formData[label.inputName]}
                                  type={label.dataType}
                                  required={label.required}
                                  maxLength={label.maxLength}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                        <div className="label-grid">
                          <Label>Company Location / Address</Label>
                          <TextArea
                            rows={3}
                            value={formData.organisation_address}
                            onChange={(e) =>
                              setFormData((prevValues) => ({
                                ...prevValues,
                                organisation_address: e.target.value,
                              }))
                            }
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                            }}
                          />
                          <Label>Comments if Any</Label>
                          <TextArea
                            rows={3}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Button mode="save" buttonref={saveRef} type="submit" />
                  <Button mode="clear" type="button" onClick={handleClear} />
                  <Button
                    mode="cancel"
                    type="button"
                    onClick={() => {
                      handleClear();
                      setOpenModal(!openModal);
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      <LoadingModal flag={loading || UpdateLoading} />
      <ErrorModal value={getApiErrorMessage(error!)} />
    </>
  );
};

export default AddPassoutStudentModal;

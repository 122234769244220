import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import useToken from "../../../customhooks/useToken";
import {
  CommonNodeVars,
  PayRollLeaveLedgerNode,
} from "../Types/masterDataTypes";
import { AppContext } from "../../../context/context";
import { PayRollLeaveLdgr } from "../queries/leaveledger/list";

interface LeaveDetailsForm {
  leaveLedgerDesc: string;
  leaveType: string;
  leaveShortCode: string;
}
interface GetPayRollLeaveLedgerNodeData {
  node: PayRollLeaveLedgerNode;
}
const useLeaveLedgerDetails = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [leaveFormData, setLeaveFormData] = useState<LeaveDetailsForm>({
    leaveLedgerDesc: "",
    leaveType: "",
    leaveShortCode: "",
  });
  const [GetLeaveDetails, queryData] = useLazyQuery<
    GetPayRollLeaveLedgerNodeData,
    CommonNodeVars
  >(PayRollLeaveLdgr, {
    variables: {
      id: state.leaveLedgerId,
      token,
    },
    fetchPolicy: "network-only",
  });

  const handleClear = () => {
    setLeaveFormData({
      leaveLedgerDesc: "",
      leaveType: "",
      leaveShortCode: "",
    });
  };

  useEffect(() => {
    if (state.leaveLedgerId) {
      GetLeaveDetails().then(({ data }) => {
        if (data) {
          const { leave_desc, leave_type, leave_short_code } = data.node;

          setLeaveFormData({
            leaveLedgerDesc: leave_desc,
            leaveType: leave_type,
            leaveShortCode: leave_short_code,
          });
        }
      });
    } else {
      handleClear();
    }
  }, [state.leaveLedgerId, GetLeaveDetails]);
  return { LeaveData: { queryData, leaveFormData } };
};

export default useLeaveLedgerDetails;

import { gql } from "@apollo/client";

export const BranchDetailsByNode = gql`
  query BranchDetailsByNode($branch_id: ID!, $token: String!) {
    node(id: $branch_id, token: $token) {
      ... on MstInstBranch {
        id
        branch_desc
        branch_idx
        branch_short_desc
        inst_id
      }
    }
  }
`;
export const ByDeptIDs = gql`
  query GetBranchesByDeptIds($dept_ids: [ID!]!, $token: String!) {
    GetBranchesByDeptIds(token: $token, dept_ids: $dept_ids) {
      id
      branch_desc
      branch_idx
    }
  }
`;
export const GetBranchesForScanByDeptIds = gql`
  query GetBranchesForScanByDeptIds($dept_ids: [ID!]!) {
    GetBranchesForScanByDeptIds(dept_ids: $dept_ids) {
      id
      branch_desc
      branch_idx
    }
  }
`;
export const ByDeptIds = gql`
  query GetBranchNamesByDeptIds($dept_id: [ID!]!, $token: String!) {
    GetBranchNamesByDeptIds(token: $token, dept_id: $dept_id) {
      id
      branch_desc
      dept_id
    }
  }
`;

export const BranchByInstId = gql`
  query GetBranchesByInstId($token: String!, $inst_id: ID!) {
    GetBranchesByInstId(token: $token, inst_id: $inst_id) {
      branch_desc
      id
    }
  }
`;
export const ByInstId = gql`
  query GetBranchesByInstId($token: String!, $inst_id: ID!) {
    GetBranchesByInstId(token: $token, inst_id: $inst_id) {
      branch_desc
      id
    }
  }
`;

export const BranchCountByDeptId = gql`
  query GetBranchCountByDeptId($token: String!, $dept_id: ID!) {
    GetBranchCountByDeptId(token: $token, dept_id: $dept_id)
  }
`;
export const GetBranchesForScanByInstId = gql`
  query GetBranchesForScanByInstId($inst_id: ID!) {
    GetBranchesForScanByInstId(inst_id: $inst_id) {
      id
      branch_desc
      branch_idx
    }
  }
`;

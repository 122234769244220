import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { VehicleDetailsPreviewTabs } from "../../../../utils/Enum.types";
import BasicInformation from "./BasicInformation";
import PurchaseDetails from "./PurchaseDetails";
import CurrentDetails from "./CurrentDetails";
import PreviousDetails from "./PreviousDetails";
const Index = () => {
  const [activeTab, setActiveTab] = useState(
    VehicleDetailsPreviewTabs.BASIC_DETAILS
  );

  const handleTabClick = (tab: VehicleDetailsPreviewTabs) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>View Vehicle Details</Title>
      <div className="view-vehicle-details">
        <ul className="view-vehicle-details__menu">
          <li
            onClick={() =>
              handleTabClick(VehicleDetailsPreviewTabs.BASIC_DETAILS)
            }
            className={
              activeTab === VehicleDetailsPreviewTabs.BASIC_DETAILS
                ? "active"
                : ""
            }
          >
            Vehical Information
          </li>
          <li
            onClick={() =>
              handleTabClick(VehicleDetailsPreviewTabs.PURCHASE_DETAILS)
            }
            className={
              activeTab === VehicleDetailsPreviewTabs.PURCHASE_DETAILS
                ? "active"
                : ""
            }
          >
            Vehicle Purchase & Other Details
          </li>
          <li
            onClick={() =>
              handleTabClick(VehicleDetailsPreviewTabs.CURRENT_DETAILS)
            }
            className={
              activeTab === VehicleDetailsPreviewTabs.CURRENT_DETAILS
                ? "active"
                : ""
            }
          >
            Vehicle Current Details
          </li>
          <li
            onClick={() =>
              handleTabClick(VehicleDetailsPreviewTabs.PREVIOUS_DETAILS)
            }
            className={
              activeTab === VehicleDetailsPreviewTabs.PREVIOUS_DETAILS
                ? "active"
                : ""
            }
          >
            Vehicle Previous Details
          </li>
        </ul>
        {activeTab === VehicleDetailsPreviewTabs.BASIC_DETAILS && (
          <BasicInformation />
        )}
        {activeTab === VehicleDetailsPreviewTabs.PURCHASE_DETAILS && (
          <PurchaseDetails />
        )}
        {activeTab === VehicleDetailsPreviewTabs.CURRENT_DETAILS && (
          <CurrentDetails />
        )}
        {activeTab === VehicleDetailsPreviewTabs.PREVIOUS_DETAILS && (
          <PreviousDetails />
        )}
      </div>
    </>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { GetAgentsNameAndId } from "../Modules/Accounts/queries/Agents/List";
import { GetAgentsVars } from "../Modules/Accounts/Agents/List";
import { useLazyQuery } from "@apollo/client";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../utils/constants";
import { useParams } from "react-router-dom";
import { Direction, SortBy } from "../utils/Enum.types";
import useToken from "./useToken";
import { getModifiedScrollHeight } from "../utils/UtilFunctions";
import { responseType } from "../utils/Form.types";
import { StudentPageInfo } from "../Modules/Academics/hooks/useAcdStudentsData";
export interface AgentsEdges {
  node: {
    agent_name: string;
    id: number;
  };
}

export interface GetAgentsList {
  edges: AgentsEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}

export interface GetAgentsNameAndIdData {
  GetAgents: GetAgentsList;
}
const useAgents = (searchAgent: string) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [agents, setAgents] = useState<AgentsEdges[]>([]);

  const [agentOptions, setAgentOptions] = useState<responseType[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [GetAcctAgents, { data, loading, fetchMore }] = useLazyQuery<
    GetAgentsNameAndIdData,
    GetAgentsVars
  >(GetAgentsNameAndId, {
    variables: {
      after: null,
      first: ROWS_PER_PAGE,
      inst_id: InstId!,
      name: searchAgent,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.AGENT_NAME,
      },
      token,
    },
  });

  useEffect(() => {
    if (token) {
      GetAcctAgents();
    }
  }, [token, GetAcctAgents, InstId, searchAgent]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAgents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = agents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setAgents(updatedNewData);
        setAgentOptions(
          updatedNewData.map(({ node }) => ({
            label: node.agent_name,
            value: node.id,
          }))
        );
      } else {
        setAgents(newData);
        setAgentOptions(
          newData.map(({ node }) => ({
            label: node.agent_name,
            value: node.id,
          }))
        );
      }
      setEndCursor(data.GetAgents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [loading, data]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAgents.edges;
            const pageInfo = fetchMoreResult.GetAgents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAgents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAgents: {
                edges: [...agents, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAgents.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };

  return {
    handleScroll,
    agentOptions,
  };
};

export default useAgents;

import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import Home from "../Home/Index";
import Modal from "react-modal";
import { ViewBookDetailsModalStyles } from "../../../styles/ModalStyles";
import { useNavigate, useParams } from "react-router-dom";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import {
  Direction,
  Operation,
  SortBy,
  TableHeaders,
  YesNo,
} from "../../../utils/Enum.types";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import NewSubject from "./NewSubject";
import {
  GetSubjectData,
  GetSubjectvars,
  SubjectDetailsNode,
  SubjectEdges,
} from "../hooks/useAcademicSubjects";
import Input from "../../../stories/Input/Input";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetAcdSubject } from "../queries/subjects/List.tsx/Index";
import { SubjectsByAcdYear } from "../../../utils/studentAcdEnum.types";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useToken from "../../../customhooks/useToken";
import LoadingModal from "../../../pages/LoadingModal";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import { DeleteAcdSubjectMasterById } from "../queries/subjects/mutation/Index";
import { msgType } from "../../../utils/Form.types";
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useAcademicTableJson from "../json/useAcademicTable";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
 

const Index = () => {
  const { dispatch, state } = useContext(AppContext);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { InstId } = useParams();
  const [addSubjectDetails, setAddSubjectDetails] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const navigate = useNavigate();

  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const { SubjectDetails } = useAcademicTableJson();
  const [GetSubjects, { data, loading, error, fetchMore }] = useLazyQuery<
    GetSubjectData,
    GetSubjectvars
  >(GetAcdSubject);

  const [subjects, setSubjects] = useState<SubjectEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [DeleteSubject, { loading: deleteLoading }] = useMutation(
    DeleteAcdSubjectMasterById,
    {
      onError: (e) => {
        setDeleteModal(!deleteModal);
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
      },
    }
  );

  const handleDelete = (id: number) => {
    DeleteSubject({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdSubject,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,

            input: {
              acd_subj_query_type: SubjectsByAcdYear.ACD_SUBJ_BY_INST_ID,
              ids: [Number(InstId)],
            },
            name: searchData,
            token,
            sortBy: SortBy.SUBJ_CODE,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setSearchData("");
        setMessage({
          flag: true,
          message: "Sucessfully Deleted Subject",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      operation: Operation.NONE,
      message: "",
    });
  };

  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetSubjects({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          after: null,
          direction: Direction.ASC,
          first: ROWS_PER_PAGE,

          input: {
            acd_subj_query_type: SubjectsByAcdYear.ACD_SUBJ_BY_INST_ID,
            ids: [Number(InstId)],
          },
          name: searchData,
          token,
          sortBy: SortBy.SUBJ_CODE,
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
      });
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetSubjects,
    token,
    addSubjectDetails,
    InstId,
    searchData,
    state.ActiveAcdYr,
  ]);

  const dynamicHeaders: TableHeaderProps[] = SubjectDetails.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: "center",
      align: "center",
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      headerName: "Is Language",
      cellClassName: "td-sub-code",
      field: "is_language",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <div className={params.row.is_language ? "font-green" : "font-red"}>
            {params.row.is_language ? YesNo.YES : YesNo.NO}
          </div>
        );
      },
    },
    {
      headerName: "Is Core Subject",
      cellClassName: "td-sub-code",
      field: "is_core",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <div className={params.row.is_core ? "font-green" : "font-red"}>
            {params.row.is_core ? YesNo.YES : YesNo.NO}
          </div>
        );
      },
    },
    {
      headerName: "Is Non-Academic Subject",
      cellClassName: "td-sub-code",
      field: "is_nonacademic",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <div
            className={params.row.is_nonacademic ? "font-green" : "font-red"}
          >
            {params.row.is_nonacademic ? YesNo.YES : YesNo.NO}
          </div>
        );
      },
    },
    {
      headerName: "Internal Avail",
      cellClassName: "td-sub-code",
      field: "internal_avail",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <div
            className={params.row.internal_avail ? "font-green" : "font-red"}
          >
            {params.row.internal_avail ? YesNo.YES : YesNo.NO}
          </div>
        );
      },
    },
    {
      headerName: "Aptitude Avail",
      cellClassName: "td-sub-code",
      field: "aptitude_avail",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <div
            className={params.row.aptitude_avail ? "font-green" : "font-red"}
          >
            {params.row.aptitude_avail ? YesNo.YES : YesNo.NO}
          </div>
        );
      },
    },
    {
      headerName: "Show Marks In Grade",
      cellClassName: "td-sub-code",
      field: "show_marks_in_grade",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <div
            className={
              params.row.show_marks_in_grade ? "font-green" : "font-red"
            }
          >
            {params.row.show_marks_in_grade ? YesNo.YES : YesNo.NO}
          </div>
        );
      },
    },
    {
      headerName: "Is Subject Elective",
      cellClassName: "td-sub-code",
      field: "is_subject_elective",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <div
            className={
              params.row.is_subject_elective ? "font-green" : "font-red"
            }
          >
            {params.row.is_subject_elective ? YesNo.YES : YesNo.NO}
          </div>
        );
      },
    },
    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const subId = params.row.sub_id;

        return (
          <>
            <img
              src={Edit}
              alt="/"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_SUBJECT_ID,
                  payload: { subjectId: subId },
                });
                setAddSubjectDetails(!addSubjectDetails);
                setOperation(Operation.UPDATE);
              }}
            />
            &nbsp;
            <img
              src={Delete}
              alt="/"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_SUBJECT_ID,
                  payload: { subjectId: subId },
                });
                setDeleteModal(!deleteModal);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetAcdSubject.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newSubject) => {
          const filteredStudent = subjects.find(
            (subject) => subject.node.id === newSubject.node.id
          );
          if (filteredStudent) {
            return {
              ...newSubject,
              node: {
                ...newSubject.node,
              },
            };
          }
          return newSubject;
        });
        setSubjects(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            sub_code: node.subj_code,
            sub_desc: node.subj_desc,
            board_code: node.subj_board_code,
            is_language: node.subj_is_lang,
            is_core: node.subj_is_core_subject,
            is_nonacademic: node.subj_is_non_academic,
            internal_avail: node.subj_internals_avialed,
            aptitude_avail: node.subj_apptitude_avialed,
            show_marks_in_grade: node.subj_marks_in_grades,
            is_subject_elective: node.subj_is_elective,
            sub_id: node.id,
          }))
        );
      } else {
        setSubjects(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            sub_code: node.subj_code,
            sub_desc: node.subj_desc,
            board_code: node.subj_board_code,
            is_language: node.subj_is_lang,
            is_core: node.subj_is_core_subject,
            is_nonacademic: node.subj_is_non_academic,
            internal_avail: node.subj_internals_avialed,
            aptitude_avail: node.subj_apptitude_avialed,
            show_marks_in_grade: node.subj_marks_in_grades,
            is_subject_elective: node.subj_is_elective,
            sub_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetAcdSubject.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdSubject.edges;
              const pageInfo = fetchMoreResult.GetAcdSubject.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdSubject.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdSubject: {
                  edges: [...subjects, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcdSubject.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  if (error) return <>{error.message}</>;
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Subject Details</Title>
      <div className="row g-0">
        <div className="col-2 button-left">
          <Input
            id="search"
            type="text"
            placeholder="Search ..."
            onChange={(e) => setSearchData(e.target.value)}
          />
        </div>
        <div className="col"></div>
        <div className="col-1">
          <Button
            mode="addnew"
            autoFocus
            className="container__list--addnew"
            onClick={() => {
              setAddSubjectDetails(!addSubjectDetails);
              setOperation(Operation.CREATE);
            }}
          />
        </div>
      </div>
      <div className={`academic-subject-details`}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          disableRowSelectionOnClick
          hideFooter
        
        />
      </div>
      <div className="button-left">
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addSubjectDetails}
        style={ViewBookDetailsModalStyles}
        ariaHideApp={false}
      >
        <NewSubject
          setAddSubjectDetails={setAddSubjectDetails}
          operation={operation}
        />
      </Modal>
      <LoadingModal flag={loading || deleteLoading} />

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={state.subjectId}
      />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const GetLibBookPublishersByInstId = gql`
  query GetLibBookPublishersByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $sortBy: LibBookPublisherOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetLibBookPublishersByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ publisherNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          publisher_name
          publisher_address
          publisher_city
          publisher_state
          publisher_country
          publisher_phone
          publisher_contact_person
          publisher_contact_person_tel
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

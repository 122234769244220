import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { TextField } from "@mui/material";
import Edit from "../../../../images/Edit.svg";
import { LabelNameProps } from "../../../../Types/Labels";
import { useNavigate } from "react-router-dom";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
import TextArea from "../../../../stories/TextArea/TextArea";
import { Operation } from "../../../../utils/Enum.types";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import View from "./View";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { VMS_Form } = require("../../../json/form.json");
interface Props {
  operation: Operation;
}
const Index = ({ operation }: Props) => {
  const [detailsFlag, setDetailsFlag] = useState(false);
  const navigate = useNavigate();
  const [viewModal, setViewModal] = useState(false);

  return (
    <>
      <Home DashBoardRequired={false} />
      {operation === Operation.UPDATE ? (
        <Title>Edit Fuel Indent</Title>
      ) : (
        <Title>Fuel Indent</Title>
      )}

      <div className="vms-fuel-indent">
        <div className="row g-0">
          <div className="col-3">
            <div className="label-grid">
              <Label variant="LabelPrimary">Indent No: </Label>
              {operation === Operation.UPDATE ? (
                <FormAutocomplete
                  options={["1222", "568"]}
                  className={formClasses.inputRoot}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              ) : (
                <Input />
              )}
            </div>
          </div>
          <div className="col"></div>
          <div className="col-2">
            <div className="label-grid">
              <Label variant="LabelPrimary">Date: </Label>
              <Input type="date" />
            </div>
          </div>
        </div>
        <div className="vms-fuel-indent__basic-details row g-0">
          <div className="col-4">
            <div className="vms-fuel-indent__basic-details--flex">
              <LabeledAutocomplete
                options={["Bangalore", "Mysore"]}
                className={labelClasses.inputRoot}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vehicle Number"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                    onClick={() => setDetailsFlag(!detailsFlag)}
                  />
                )}
              />
              <img src={Edit} alt="" className="data-fetch-icon" />
            </div>
            <TextField
              className="vms-fuel-indent__basic-details--textfield"
              label="Engine Number"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
            />
            <TextField
              className="vms-fuel-indent__basic-details--textfield"
              label="Chasis Number"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
            />
          </div>
          <div className="col">
            <div className="vms-fuel-indent__basic-details--column-span">
              {VMS_Form.Supplier_Details.FuelIndent.map(
                (label: LabelNameProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        className="vms-fuel-indent__basic-details--textfield"
                        label={label.LabelName}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        disabled
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
        </div>

        <div className="vms-fuel-indent__data">
          {!detailsFlag ? (
            <>
              <div className="row g-0 vms-fuel-indent__data--details">
                <div className="col">
                  <div className="label-grid">
                    <Label>Previous KM</Label>
                    <Input disabled />
                    <Label>Previous Fuel Loaded Ltrs</Label>
                    <Input disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="label-grid">
                    <Label>Current KM</Label>
                    <Input />
                    <Label>Approximate Fuel in Tank</Label>
                    <Input />
                  </div>
                </div>
                <div className="col">
                  <div className="label-grid">
                    <Label>KM Covered</Label>
                    <Input disabled />
                    <Label>Prev KM reading Date</Label>
                    <Input disabled type="date" />
                  </div>
                </div>
              </div>
              <div className="row g-0 vms-fuel-indent__data--supplier-details">
                <div className="col vms-fuel-indent__data--supplier-details--frame">
                  <div className="label-grid">
                    <Label>Indent For Supplier</Label>
                    <div className="vms-fuel-indent__data--supplier-details--flex">
                      <FormAutocomplete
                        options={["Ganagadhar Private Ltd", "Mysore"]}
                        className={formClasses.inputRoot}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <img src={Edit} alt="" className="data-fetch-icon" />
                    </div>
                  </div>
                  <div className="label-grid ">
                    <Label>Fuel Brand</Label>
                    <Input disabled />
                    <Label>Address</Label>
                    <Input disabled />
                    <Label>Fuel Loaded in Ltrs</Label>
                    <Input id="receivedamount" />
                  </div>
                </div>
                <div className="col vms-fuel-indent__data--supplier-details--frame">
                  <div className="label-grid">
                    <Label>Indent By</Label>
                    <Input />
                    <Label>Remarks</Label>
                    <TextArea rows={3} />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <Button mode="save" />
        <Button mode="edit" onClick={() => navigate(`/vms/fuelindent/edit`)} />
        <Button mode="view" onClick={() => setViewModal(!viewModal)} />
        <Button mode="delete" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal isOpen={viewModal} ariaHideApp={false} style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View setModalFlag={setViewModal} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;

import { gql } from "@apollo/client";

export const GetPayRollGradesByInstId = gql`
  query GetPayRollGradesByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: PayRollGradeOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
  ) {
    GetPayRollGradesByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ gradeDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          grade_desc
          grade_idx
        }
      }
    }
  }
`;
export const GetPayRollGradeIdsByNames = gql`
  query GetPayRollGradeIdsByNames(
    $token: String!
    $inst_id: ID!
    $grade_desc: [String!]
  ) {
    GetPayRollGradeIdsByNames(
      token: $token
      inst_id: $inst_id
      grade_desc: $grade_desc
    ) {
      id
      grade_desc
    }
  }
`;

export const GetPayRollGradeNode = gql`
  query GetPayRollGradeNode($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      id
      ... on PayRollGrade {
        grade_desc
      }
    }
  }
`;

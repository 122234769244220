import { gql } from "@apollo/client";

export const GetPayRollAcdYrsByInstId = gql`
  query GetPayRollAcdYrsByInstId($inst_id: ID!, $token: String!) {
    GetPayRollAcdYrsByInstId(token: $token, inst_id: $inst_id) {
      id
      payroll_yr
      payroll_st_date
      payroll_end_date
      payroll_is_curr_yr
      inst_id
    }
  }
`;

export const GetPayRollAcdYrActiveByInstId = gql`
  query GetPayRollAcdYrActiveByInstId($token: String!, $inst_id: ID!) {
    GetPayRollAcdYrActiveByInstId(token: $token, inst_id: $inst_id) {
      id
      payroll_yr
      payroll_st_date
      payroll_end_date
      payroll_is_curr_yr
      inst_id
    }
  }
`;

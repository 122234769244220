import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { RoutesDetailsTitleProps } from "../../../../Types/Titles";
import Avatar from "../../../../images/Avatar.svg";
import DataFetch from "../../../../images/Edit.svg";
import ActiveRoute from "../../../../images/ActiveRoute.svg";
import ChoosedRoute from "../../../../images/ChoosedRoute.svg";
import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import StudentList from "../../Student/List";
import {
  PageFor,
  StageDetails,
  StudentReportType,
} from "../../../../utils/Enum.types";
import useAcdStudentsAdmNumber from "../../../Academics/hooks/useAcdStudentsAdmNumber";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import { responseType } from "../../../../utils/Form.types";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import {
  GetTransportFeeDetails,
  GetTransportStudent,
} from "../../queries/list";
import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
} from "../../paginationTypes";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { toInputStandardDate } from "../../../../utils/UtilFunctions";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const { Transport_Title } = require("../../json/title.json");

interface Props {
  pageType: PageFor;
  modalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const View = ({ pageType, modalFlag }: Props) => {
  const { token } = useToken();
  const { state, dispatch } = useContext(AppContext);

  const [studentModal, setStudentModal] = useState(false);
  const [searchAdmNo, setSearchAdmNo] = useState("");

  const { studentFormData } = useStudentDatabyId();
  const { serverDate } = useServerDateandTime();

  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    searchAdmNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );

  const [GetTransportStudentData, { data }] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: state.studentId,
    },
  });
  const [GetRouteFeeData, { data: FeeData }] = useLazyQuery(
    GetTransportFeeDetails
  );

  useEffect(() => {
    if (data && data?.GetTransportStudent) {
      GetRouteFeeData({
        variables: {
          token,
          inst_id: state.InstId,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          acct_ldgr_id: data?.GetTransportStudent.acct_ldgr_details.id,
        },
      });
    }
  }, [data, GetRouteFeeData, state.ActiveFinYr, token, state.InstId]);
  const ChoosedRouteData = [
    {
      name: StageDetails.ROUTE,
      route:
        data?.GetTransportStudent.route_details.route_master_details.route_desc,
    },
    {
      name: StageDetails.STAGE,
      route: data?.GetTransportStudent.route_details.route_stage_desc,
    },
    {
      name: StageDetails.LEDGER,
      route: data?.GetTransportStudent.acct_ldgr_details.ldgr_desc,
    },
    {
      name: StageDetails.FEE,
      route: FeeData?.GetTransportFeeDetails,
    },
  ];
  useEffect(() => {
    if (state.studentId) {
      GetTransportStudentData({
        variables: {
          token,
          student_id: state.studentId,
        },
      });
    }
  }, [GetTransportStudentData, state.studentId, token]);
  const { branchLabel ,categoryLabel} = useInstLabels();
  return (
    <>
      <Title>
        {Transport_Title.MasterData.RoutesDetails.map(
          (title: RoutesDetailsTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.View}</React.Fragment>;
          }
        )}
      </Title>
      <div className="transport-assign-route__view">
        <div className="transport-assign-route__details row g-0">
          <div className="col">
            <div className="transport-assign-route__details--image-flex">
              {pageType === PageFor.GENERAL ? (
                <>
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={
                      studentAddmissionNumber ?? EMPTY_RESPONSETYPE_OBJECT
                    }
                    isOptionEqualToValue={(option) =>
                      (option as responseType).value === state.studentId
                    }
                    openOnFocus
                    freeSolo
                    autoHighlight
                    forcePopupIcon
                    onChange={(e, newValue) => {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Admission Number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSearchAdmNo(e.target.value)
                        }
                        autoFocus
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                  <img
                    className="data-fetch-icon"
                    src={DataFetch}
                    alt="/"
                    onClick={() => {
                      setStudentModal(!studentModal);
                    }}
                  />
                </>
              ) : (
                <TextField
                  label="Admission No"
                  className="transport-assign-route__details--textfield"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  value={studentFormData.adm_no}
                  disabled
                />
              )}
            </div>
            <TextField
              label="Reg No."
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.reg_number}
              disabled
            />
            <TextField
              label="Academic Year"
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.acd_yr}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                studentFormData.first_name +
                " " +
                studentFormData.middle_name +
                " " +
                studentFormData.last_name
              }
              disabled
            />
            <TextField
              label="Father Name"
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.father_name}
              disabled
            />
            <TextField
              label={branchLabel}
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.branch}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Date"
              className="transport-assign-route__details--date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={toInputStandardDate(serverDate)}
              disabled
              type="date"
            />
            <TextField
               label={categoryLabel}
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.category}
              disabled
            />
            <TextField
              label="Mobile No."
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData.std_mobile}
              disabled
            />
          </div>
          <div className="col-1 transport-assign-route__details--studentprofile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="transport-assign-route__select--view row g-0">
          <div className="col-3">
            <TextField
              label="Bus Route"
              className="transport-assign-route__details--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={
                data?.GetTransportStudent?.route_details?.route_master_details
                  .route_desc ?? EMPTY_STRING
              }
              disabled
            />
          </div>
          <div className="col-3">
            <TextField
              label="Student Stage At"
              className="transport-assign-route__details--textfield"
              value={
                data?.GetTransportStudent.route_details.route_stage_desc ??
                EMPTY_STRING
              }
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
            />
          </div>
          <div className="col">
            <Title variant="subtitle2">
              <img src={ActiveRoute} alt="/" />
              Active Route
            </Title>
          </div>
        </div>
        <div className="transport-assign-route__datablock">
          <div className="transport-assign-route__tableblock col-3">
            {!data ? (
              <b className="nodata">
                Sorry No Routes has been assigned for this particular Student
              </b>
            ) : null}
          </div>
          <div className="transport-assign-route__choosed-route col-3">
            <Title variant="subtitle2">
              <img src={ChoosedRoute} alt="/" />
              Choosed Route
            </Title>
            <ul className="transport-assign-route__choosed-route--list">
              {ChoosedRouteData.map((data, index) => {
                return (
                  <li key={index + 1}>
                    <TextField
                      className="transport-assign-route__choosed-route--list--textfield"
                      label={data.name}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                      value={data.route}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="transport-assign-route__choosed-route--label-grid">
              <TextField
                label="Start Date"
                className="transport-assign-route__details--date"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                type="date"
              />

              <TextField
                label="End Date"
                className="transport-assign-route__details--date"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                type="date"
              />
            </div>
          </div>
        </div>
        <Button mode="cancel" onClick={() => modalFlag(false)} />
      </div>
      {/* student-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              setStudentModal={setStudentModal}
              queryType={StudentReportType.GENERAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default View;

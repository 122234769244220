import { gql } from "@apollo/client";
export const ListCategoryByInstId = gql`
  query GetCategoriesByInstId($inst_id: ID!, $token: String!) {
    GetCategoriesByInstId(token: $token, inst_id: $inst_id) {
      id
      cat_desc
    }
  }
`;

export const ListInstCategoriesById = gql`
  query GetCategoryById($id: ID!, $token: String!) {
    GetCategoryById(token: $token, id: $id) {
      inst_id
      cat_idx
      cat_desc
    }
  }
`;

export const CategoryDetailsByNode = gql`
  query CategoryDetailsByNode($category_id: ID!, $token: String!) {
    node(id: $category_id, token: $token) {
      ... on MstStdCategory {
        inst_id
        cat_idx
        cat_desc
      }
    }
  }
`;

export const GetCategoryDetailsByNodes = gql`
  query GetCategoryDetailsByNodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on MstStdCategory {
        cat_desc
      }
    }
  }
`;

import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetSwConfigStudentDataEntryByInstId } from "../../../../queries/institution/configuration/query";
import { Button } from "../../../../stories/Button/Button";

import {
  GetSwConfigStudentDataEntryByInstIdData,
  GetSwConfigStudentDataEntryByInstIdVars,
} from "../../../../Types/Student/Configuration";
import { STANDARD_DATE_FORMAT } from "../../../../utils/constants";
import {
  PageFor,
  StudentRegitrationForms,
  YesNo,
} from "../../../../utils/Enum.types";
import { studentPreviousSchoolData } from "../../../../utils/Form.types";
import { AppContext } from "../../../../context/context";
import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";
import { TextField } from "@mui/material";
import Home from "../../Home/Index";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import { useLazyQuery } from "@apollo/client";
import ReservationPreviewTabs from "./ReservationPreviewTabs";
interface Props {
  pageType: PageFor;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const NxtPreviousInstDetails = ({ pageType, setModal }: Props) => {
  const { InstId, studentId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const navigate = useNavigate();
  const [GetConfigData, { data: GetStudentSwConfig }] = useLazyQuery<
    GetSwConfigStudentDataEntryByInstIdData,
    GetSwConfigStudentDataEntryByInstIdVars
  >(GetSwConfigStudentDataEntryByInstId, {
    variables: { token, inst_id: InstId! },
  });

  const PreviousInstitutionDetails =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PREVIOUS_INST_DATA &&
        data_field_enable
    );
  const { studentData } = useStudentDatabyId();
  const [studentPreviousInstFormData, setStudentPreviousInstFormData] =
    useState<studentPreviousSchoolData>({
      std_prev_tc_details: "",
      std_prev_inst: "",
      std_prev_quali_exam: "",
      std_prev_studied_board: "",
      std_prev_studied_state: "",
      std_prev_subject_studied: "",
      std_prev_total_marks: "",
      std_prev_tech_subject_studied: "",
      std_prev_10_std_marks: "",
      std_prev_10_std_per: "",
      std_prev_10_lang_medium: "",
      std_prev_12_std_marks: "",
      std_prev_12_std_per: "",
      std_bank_name: "",
      std_bank_branch: "",
      std_bank_acct: "",
      std_bank_ifsc: "",
      std_adm_appl_no: "",
      std_adm_free_pymnt_seat: "",
      std_adm_state_or_outside: "",
      std_adm_seat_got_through: "",
      std_adm_category: "",
      std_adm_cet_no: "",
      std_adm_cet_rank: "",
      std_adm_eli_no: "",
      std_adm_eli_date: "",
      std_adm_cet_paid_rcpt_no: "",
      std_adm_cet_paid_rcpt_date: "",
      std_adm_cet_paid_amt: 0,
      std_adm_cet_alloted_category: "",
      std_10_board_name: "",
      std_10_grade_system: "",
      std_10_inst_name: "",
      std_10_lang_medium: "",
      std_10_reg_no: "",
      std_10_scored_marks: 0,
      std_10_scored_percentage: 0,
      std_10_state: "",
      std_10_total_marks: 0,
      std_10_year: "",
      std_12_course_name: "",
      std_12_grade_system: "",
      std_12_inst_name: "",
      std_12_lang_medium: "",
      std_12_reg_no: "",
      std_12_scored_marks: 0,
      std_12_scored_percentage: 0,
      std_12_state: "",
      std_12_total_marks: 0,
      std_12_year: "",
      std_challan_no: "",
      std_discipline_1: "",
      std_discipline_2: "",
      std_fee_amount: 0,
      std_fee_payment_date: "",
      std_kk_reservation: "",
      std_language_1: "",
      std_language_2: "",
      std_open_elective: "",
      std_prev_scored_marks: 0,
      std_prev_tech_total_mark: 0,
      std_prev_tech_total_per: 0,
      std_prev_total_per: 0,
      std_program_name: "",
      std_ug_course_name: "",
      std_ug_doa: "",
      std_ug_doc: "",
      std_ug_grade_system: "",
      std_ug_inst_name: "",
      std_ug_lang_medium: "",
      std_ug_reg_no: "",
      std_ug_scored_marks: 0,
      std_ug_scored_percentage: 0,
      std_ug_state: "",
      std_ug_total_marks: 0,
      std_ug_univ_name: "",
      std_kannada_medium: YesNo.NO,
    });
  useEffect(() => {
    if (!studentData.loading && studentData.data) {
      setStudentPreviousInstFormData({
        std_kannada_medium: studentData.data.nodes[0].std_other_details
          .std_kannada_medium
          ? YesNo.YES
          : YesNo.NO,
        std_prev_tc_details:
          studentData.data.nodes[0].std_other_details.std_prev_tc_details,
        std_prev_inst:
          studentData.data.nodes[0].std_other_details.std_prev_inst,
        std_prev_quali_exam:
          studentData.data.nodes[0].std_other_details.std_prev_quali_exam,
        std_prev_studied_board:
          studentData.data.nodes[0].std_other_details.std_prev_studied_board,
        std_prev_studied_state:
          studentData.data.nodes[0].std_other_details.std_prev_studied_state,
        std_prev_subject_studied:
          studentData.data.nodes[0].std_other_details.std_prev_subject_studied,
        std_prev_scored_marks:
          studentData.data.nodes[0].std_other_details.std_prev_scored_marks !==
          0
            ? studentData.data.nodes[0].std_other_details.std_prev_scored_marks
            : 0,
        std_prev_total_per:
          studentData.data.nodes[0].std_other_details.std_prev_total_per !== 0
            ? studentData.data.nodes[0].std_other_details.std_prev_total_per
            : 0,
        std_adm_free_pymnt_seat:
          studentData.data.nodes[0].std_other_details.std_adm_free_pymnt_seat,
        std_prev_tech_subject_studied:
          studentData.data.nodes[0].std_other_details
            .std_prev_tech_subject_studied,
        std_prev_tech_total_mark:
          studentData.data.nodes[0].std_other_details
            .std_prev_tech_total_mark !== 0
            ? studentData.data.nodes[0].std_other_details
                .std_prev_tech_total_mark
            : 0,
        std_prev_tech_total_per:
          studentData.data.nodes[0].std_other_details
            .std_prev_tech_total_per !== 0
            ? studentData.data.nodes[0].std_other_details
                .std_prev_tech_total_per
            : 0,
        std_10_scored_marks:
          studentData.data.nodes[0].std_other_details.std_10_scored_marks !== 0
            ? studentData.data.nodes[0].std_other_details.std_10_scored_marks
            : 0,
        std_10_scored_percentage:
          studentData.data.nodes[0].std_other_details
            .std_10_scored_percentage !== 0
            ? studentData.data.nodes[0].std_other_details
                .std_10_scored_percentage
            : 0,
        std_10_lang_medium:
          studentData.data.nodes[0].std_other_details.std_10_lang_medium,
        std_12_scored_marks:
          studentData.data.nodes[0].std_other_details.std_12_scored_marks !== 0
            ? studentData.data.nodes[0].std_other_details.std_12_scored_marks
            : 0,
        std_12_scored_percentage:
          studentData.data.nodes[0].std_other_details
            .std_12_scored_percentage !== 0
            ? studentData.data.nodes[0].std_other_details
                .std_12_scored_percentage
            : 0,
        std_bank_acct:
          studentData.data.nodes[0].std_other_details.std_bank_acct,
        std_bank_name:
          studentData.data.nodes[0].std_other_details.std_bank_name,
        std_bank_ifsc:
          studentData.data.nodes[0].std_other_details.std_bank_ifsc,
        std_bank_branch:
          studentData.data.nodes[0].std_other_details.std_bank_branch,
        std_adm_appl_no:
          studentData.data.nodes[0].std_other_details.std_adm_appl_no,

        std_adm_state_or_outside:
          studentData.data.nodes[0].std_other_details.std_adm_state_or_outside,
        std_adm_seat_got_through:
          studentData.data.nodes[0].std_other_details.std_adm_seat_got_through,
        std_adm_category:
          studentData.data.nodes[0].std_other_details.std_adm_category,
        std_adm_cet_no:
          studentData.data.nodes[0].std_other_details.std_adm_cet_no,
        std_adm_cet_rank:
          studentData.data.nodes[0].std_other_details.std_adm_cet_rank,
        std_adm_eli_no:
          studentData.data.nodes[0].std_other_details.std_adm_eli_no,
        std_adm_eli_date: dayjs(
          studentData.data.nodes[0].std_other_details.std_adm_eli_date
        ).format(STANDARD_DATE_FORMAT),
        std_adm_cet_paid_rcpt_no:
          studentData.data.nodes[0].std_other_details.std_adm_cet_paid_rcpt_no,
        std_adm_cet_paid_rcpt_date: dayjs(
          studentData.data.nodes[0].std_other_details.std_adm_cet_paid_rcpt_date
        ).format(STANDARD_DATE_FORMAT),
        std_adm_cet_paid_amt:
          studentData.data.nodes[0].std_other_details.std_adm_cet_paid_amt !== 0
            ? studentData.data.nodes[0].std_other_details.std_adm_cet_paid_amt
            : 0,
        std_adm_cet_alloted_category:
          studentData.data.nodes[0].std_other_details
            .std_adm_cet_alloted_category,
        std_10_board_name:
          studentData.data.nodes[0].std_other_details.std_10_board_name,
        std_10_grade_system:
          studentData.data.nodes[0].std_other_details.std_10_grade_system,
        std_10_inst_name:
          studentData.data.nodes[0].std_other_details.std_10_inst_name,

        std_10_reg_no:
          studentData.data.nodes[0].std_other_details.std_10_reg_no,

        std_10_state: studentData.data.nodes[0].std_other_details.std_10_state,

        std_10_total_marks:
          studentData.data.nodes[0].std_other_details.std_10_total_marks,

        std_10_year: studentData.data.nodes[0].std_other_details.std_10_year,
        std_12_course_name:
          studentData.data.nodes[0].std_other_details.std_12_course_name,

        std_12_grade_system:
          studentData.data.nodes[0].std_other_details.std_12_grade_system,

        std_12_inst_name:
          studentData.data.nodes[0].std_other_details.std_12_inst_name,

        std_12_lang_medium:
          studentData.data.nodes[0].std_other_details.std_12_lang_medium,

        std_12_reg_no:
          studentData.data.nodes[0].std_other_details.std_12_reg_no,

        std_12_state: studentData.data.nodes[0].std_other_details.std_12_state,

        std_12_total_marks:
          studentData.data.nodes[0].std_other_details.std_12_total_marks,

        std_12_year: studentData.data.nodes[0].std_other_details.std_12_year,

        std_challan_no:
          studentData.data.nodes[0].std_other_details.std_challan_no,

        std_discipline_1:
          studentData.data.nodes[0].std_other_details.std_discipline_1,

        std_discipline_2:
          studentData.data.nodes[0].std_other_details.std_discipline_2,

        std_fee_amount:
          studentData.data.nodes[0].std_other_details.std_fee_amount,

        std_fee_payment_date:
          studentData.data.nodes[0].std_other_details.std_fee_payment_date,

        std_kk_reservation: studentData.data.nodes[0].std_other_details
          .std_kk_reservation
          ? YesNo.YES
          : YesNo.NO,

        std_language_1:
          studentData.data.nodes[0].std_other_details.std_language_1,

        std_language_2:
          studentData.data.nodes[0].std_other_details.std_language_2,
        std_open_elective:
          studentData.data.nodes[0].std_other_details.std_open_elective,
        std_program_name:
          studentData.data.nodes[0].std_other_details.std_program_name,
        std_ug_course_name:
          studentData.data.nodes[0].std_other_details.std_ug_course_name,
        std_ug_doa: studentData.data.nodes[0].std_other_details.std_ug_doa,
        std_ug_doc: studentData.data.nodes[0].std_other_details.std_ug_doc,
        std_ug_grade_system:
          studentData.data.nodes[0].std_other_details.std_ug_grade_system,
        std_ug_inst_name:
          studentData.data.nodes[0].std_other_details.std_ug_inst_name,
        std_ug_lang_medium:
          studentData.data.nodes[0].std_other_details.std_ug_lang_medium,
        std_ug_reg_no:
          studentData.data.nodes[0].std_other_details.std_ug_reg_no,
        std_ug_scored_marks:
          studentData.data.nodes[0].std_other_details.std_ug_scored_marks,
        std_ug_scored_percentage:
          studentData.data.nodes[0].std_other_details.std_ug_scored_percentage,
        std_ug_state: studentData.data.nodes[0].std_other_details.std_ug_state,
        std_ug_total_marks:
          studentData.data.nodes[0].std_other_details.std_ug_total_marks,
        std_ug_univ_name:
          studentData.data.nodes[0].std_other_details.std_ug_univ_name,
      });
    }
  }, [studentData.loading, studentData.data]);
  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}

      {pageType === PageFor.GENERAL ? (
        <ReservationPreviewTabs pageType={PageFor.GENERAL} />
      ) : null}
      <div
        className={pageType === PageFor.GENERAL ? "preview" : "preview--modal"}
      >
        <StudentAcademicDetails />

        <div
          className={
            pageType === PageFor.GENERAL
              ? "preview__frame"
              : "preview__frame--modal"
          }
        >
          <div className="preview__frame--data row">
            <div className="col preview__span-4">
              {PreviousInstitutionDetails?.map((label, index) => {
                return (
                  <React.Fragment key={index}>
                    <TextField
                      id="outlined-uncontrolled"
                      className="preview__textfield"
                      label={label.data_field_label}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      value={studentPreviousInstFormData[
                        label.data_field_name
                      ]?.toString()}
                      disabled
                      name={label.data_field_name}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {!state.claims!.STUDENT && (
            <>
              {pageType === PageFor.GENERAL ? (
                <Button
                  mode="edit"
                  autoFocus
                  onClick={() =>
                    navigate(
                      `/${InstId}/admissions/students/${studentId}/NxtPrevInstDetailsUpdate`
                    )
                  }
                />
              ) : null}

              {pageType === PageFor.GENERAL ? (
                <Button
                  mode="back"
                  onClick={() => navigate(`/${InstId}/admissions/students`)}
                />
              ) : null}
            </>
          )}
          {pageType === PageFor.MODAL && (
            <Button mode="cancel" onClick={() => setModal?.(false)} />
          )}
        </div>
      </div>
    </>
  );
};

export default NxtPreviousInstDetails;

import { gql } from "@apollo/client";

export const GetPayRollSalaryLdgrsByInstId = gql`
  query GetPayRollSalaryLdgrsByInstId($token: String!, $inst_id: ID!) {
    GetPayRollSalaryLdgrsByInstId(token: $token, inst_id: $inst_id) {
      id
      sal_ldgr_desc
      sal_ldgr_short_desc
      sal_ldgr_idx
      sal_ldgr_earn_deduct
      sal_ldgr_type
      sal_ldgr_is_system_generated
      sal_ldgr_is_active
      sal_ldgr_round_to_next_int
      sal_ldgr_remove_decimal
      sal_ldgr_is_loan_ac
      sal_ldgr_is_basic
      inst_id
    }
  }
`;

export const GetPayRollSalaryStructMastersAndDetails = gql`
  query GetPayRollSalaryStructMastersAndDetails(
    $token: String!
    $inst_id: ID!
    $pr_sal_str_master_id: ID!
  ) {
    GetPayRollSalaryStructMastersAndDetails(
      token: $token
      inst_id: $inst_id
      pr_sal_str_master_id: $pr_sal_str_master_id
    ) {
      master_data {
        id
        sal_str_desc
        sal_str_assigned_to_all
        sal_str_in_use
        inst_id
        pr_acd_yr_id
        pr_category_id
        pr_dept_id
        pr_designation_id
      }
      details_data {
        id
        sal_str_sl
        sal_str_earn_ded
        sal_str_earn_type
        sal_str_earn_flat_rate
        sal_str_earn_is_UDM
        sal_str_earn_UDM_val
        sal_str_earn_restrict_to
        sal_str_earn_basic_max_to
        sal_str_earn_rule_apply
        sal_str_earn_rule
        sal_str_ded_is_UDM
        sal_str_ded_UDM_val
        sal_str_ded_is_applied_on_heads
        sal_str_ded_heads
        sal_str_ded_rule
        sal_str_ded_to_max_value
        sal_remaining_amt_rule
        pr_sal_str_master_id
        pr_sal_ldgr_id
        sal_str_group_id_heads
        sal_ldgr_details {
          id
          sal_ldgr_desc
          sal_ldgr_short_desc
          sal_ldgr_idx
          sal_ldgr_earn_deduct
          sal_ldgr_type
          sal_ldgr_is_system_generated
          sal_ldgr_is_active
          sal_ldgr_is_basic
          sal_ldgr_round_to_next_int
          sal_ldgr_remove_decimal
        }
      }
    }
  }
`;
export const GetPayRollSalaryStructMastersByInstId = gql`
  query GetPayRollSalaryStructMastersByInstId(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $direction: OrderDirection!
    $sortBy: PayRollSalaryStructMasterOrderField
    $name: String
  ) {
    GetPayRollSalaryStructMastersByInstId(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ salStrDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          sal_str_desc
          sal_str_assigned_to_all
          sal_str_in_use
          inst_id
          pr_acd_yr_id
          pr_category_id
          pr_dept_id
          pr_designation_id
        }
        cursor
      }
    }
  }
`;

//For Salary process
export const GetPayRollSalaryMkWork = gql`
  query GetPayRollSalaryMkWork(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
  ) {
    GetPayRollSalaryMkWork(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
    ) {
      id
      sal_wrk_for_month
      sal_wrk_job_start_date
      sal_wrk_job_started
      sal_wrk_is_emp_added
      sal_wrk_emp_added_date
      sal_wrk_is_att_added
      sal_wrk_att_added_date
      sal_wrk_is_sal_str_added
      sal_wrk_sal_str_added_date
      sal_wrk_is_sal_made
      sal_wrk_sal_made_date
      sal_wrk_used_roundoff
      sal_wrk_job_end_date
      sal_wrk_job_completed
    }
  }
`;

export const GetPayRollSalaryMkWorkByMonthId = gql`
  query node($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      id
      ... on PayRollSalaryMkWork {
        sal_wrk_for_month
        sal_wrk_job_start_date
        sal_wrk_job_started
        sal_wrk_is_emp_added
        sal_wrk_emp_added_date
        sal_wrk_is_att_added
        sal_wrk_att_added_date
        sal_wrk_is_sal_str_added
        sal_wrk_sal_str_added_date
        sal_wrk_is_sal_made
        sal_wrk_sal_made_date
        sal_wrk_used_roundoff
        sal_wrk_job_end_date
        sal_wrk_job_completed
      }
    }
  }
`;

export const GetPayRollSalaryMkEmpMaster = gql`
  query GetPayRollSalaryMkEmpMaster(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $input: PrMkSalQuery!
    $after: Cursor
    $first: Int
    $direction: OrderDirection!
    $sortBy: PayRollSalaryMkEmpMasterOrderField
    $name: String!
    $department: ID
    $designation: ID
    $jobtype: ID
    $grade: ID
    $category: ID
  ) {
    GetPayRollSalaryMkEmpMaster(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      input: $input
      after: $after
      first: $first
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            hasPrEmpWith: {
              or: [
                {
                  empFirstNameContainsFold: $name
                  prDeptID: $department
                  prCategoryID: $category
                  prDesignationID: $designation
                  prJobTypeID: $jobtype
                  prGradeID: $grade
                }
              ]
            }
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          # emp_sal_mk_month
          emp_sal_mk_earned_days
          emp_sal_mk_basic
          emp_sal_mk_earned_basic
          emp_sal_mk_other_earn
          emp_sal_mk_gross
          emp_sal_mk_others_ded
          emp_sal_mk_lwp
          emp_sal_mk_net
          emp_sal_mk_remaining_ldgr_bal
          emp_sal_mk_fixed_gross
          # emp_sal_mk_last_edited
          # emp_sal_mk_last_verified
          emp_sal_mk_completed
          emp_sal_mk_any_error
          emp_sal_mk_trans_bank_ac_no
          emp_sal_mk_trans_bank_ifsc_code
          # emp_sal_mk_trans_date
          emp_sal_mk_trans_sms_sent
          emp_details {
            id
            emp_id
            emp_title
            emp_first_name
            emp_middle_name
            emp_last_name
            emp_qualification
            emp_experience
            emp_mobile
            emp_email
            other_details {
              emp_bank_acct_no
              emp_bank_ifsc
              emp_bank_name
              emp_epf_no
            }
            pr_designation_details {
              designation_desc
            }
            pr_job_type_details {
              job_type_desc
            }
            pr_category_details {
              category_desc
            }
            pr_dept_details {
              dept_desc
            }
            leave_master_details {
              total_leaves_availed
              total_leaves_used
              leave_details {
                leaves_availed
                leaves_used
              }
            }
          }
          fixed_salary_details {
            emp_sal_str_total_earnings
            salary_str_details {
              emp_sal_ldgr_value
              sal_ldgr_details {
                id
                sal_ldgr_desc
              }
            }
          }
          confirmed_salary_details {
            id
            emp_sal_sl
            emp_sal_ldgr_value
            emp_sal_ldgr_sl
            emp_sal_earn_ded
            emp_sal_earn_type
            emp_sal_earn_flat_rate
            emp_sal_earn_is_UDM
            emp_sal_earn_UDM_val
            emp_sal_earn_restrict_to
            emp_sal_earn_to_min_basic
            emp_sal_earn_rule_apply
            emp_sal_earn_rule
            emp_sal_group_id_heads
            emp_sal_remaining_amt_rule
            emp_sal_ded_is_UDM
            emp_sal_ded_UDM_value
            emp_sal_ded_is_applied_on_heads
            emp_sal_ded_on_heads
            emp_sal_ded_to_max_value
            emp_sal_ded_rule
            pr_sal_mk_emp_master_id
            pr_sal_ldgr_id
            sal_ldgr_details {
              id
              sal_ldgr_desc
              sal_ldgr_short_desc
              sal_ldgr_idx
              sal_ldgr_earn_deduct
              sal_ldgr_type
              sal_ldgr_is_system_generated
              sal_ldgr_is_active
              sal_ldgr_round_to_next_int
              sal_ldgr_remove_decimal
              sal_ldgr_is_basic
              sal_ldgr_is_loan_ac
            }
          }
        }
        cursor
      }
    }
  }
`;
export const GetPayRollSalaryMkEmpAtt = gql`
  query GetPayRollSalaryMkEmpAtt(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $pr_sal_mk_month_id: ID!
    $after: Cursor
    $first: Int
    $direction: OrderDirection!
    $sortBy: PayRollSalaryMkEmpAttOrderField
    $name: String!
    $department: ID
    $designation: ID
    $jobtype: ID
    $grade: ID
    $category: ID
  ) {
    GetPayRollSalaryMkEmpAtt(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      pr_sal_mk_month_id: $pr_sal_mk_month_id
      after: $after
      first: $first
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            hasPrEmpWith: {
              or: [
                {
                  empFirstNameContainsFold: $name
                  prDeptID: $department
                  prCategoryID: $category
                  prDesignationID: $designation
                  prJobTypeID: $jobtype
                  prGradeID: $grade
                }
              ]
            }
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          att_sal_month
          att_entry_completed
          no_month_days
          no_weekend_days
          no_gen_holidays
          no_unexpt_holidays
          no_vacation_holidays
          no_working_days
          att_emp_leaves
          att_emp_paid_leave
          att_lwp_days
          att_earned_days
          att_imported_from_biometric
          att_manual_entry
          att_emp_sal_idx
          att_emp_sal_dept_idx
          inst_id
          pr_acd_yr_id
          pr_emp_id
          emp_details {
            id
            emp_id
            emp_title
            emp_first_name
            emp_middle_name
            emp_last_name
            emp_qualification
            pr_designation_details {
              designation_desc
            }
            pr_job_type_details {
              job_type_desc
            }
            pr_category_details {
              category_desc
            }
            pr_dept_details {
              dept_desc
            }
          }
        }
        cursor
      }
    }
  }
`;

export const GetPayRollSalaryLdgrByNode = gql`
  query GetPayRollSalaryLdgrByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on PayRollSalaryLdgr {
        id
        sal_ldgr_desc
        sal_ldgr_short_desc
        sal_ldgr_idx
        sal_ldgr_earn_deduct
        sal_ldgr_type
        sal_ldgr_is_system_generated
        sal_ldgr_is_active
        sal_ldgr_round_to_next_int
        sal_ldgr_remove_decimal
        inst_id
      }
    }
  }
`;

export const GetPayRollSalaryProcessCount = gql`
  query GetPayRollSalaryProcessCount(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $pr_sal_mk_month_id: ID!
  ) {
    GetPayRollSalaryProcessCount(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      pr_sal_mk_month_id: $pr_sal_mk_month_id
    ) {
      total_emp_count
      total_emp_male_count
      total_emp_female_count
      total_emp_sal_count
      total_emp_sal_mk_basic
      total_emp_sal_mk_earned_basic
      total_emp_sal_mk_other_earn
      total_emp_sal_mk_gross
      total_emp_sal_mk_others_ded
      total_emp_sal_mk_net
    }
  }
`;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Home from "../Home/Index";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../components/common/Input/Index";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { FineSlabTitleProps } from "../../../Types/Titles";

import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { Operation, ReturnType } from "../../../utils/Enum.types";
import { Form, Formik } from "formik";
import { LibraryFineSlab } from "../../../utils/validationRules";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddLibFine } from "../../../queries/Library/FineSlab/mutation/new";
import LoadingModal from "../../../pages/LoadingModal";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import { GetLibFineByInstId } from "../../../queries/Library/FineSlab/list/byInstId";
import { DeleteLibFineById } from "../../../queries/Library/FineSlab/mutation/delete";
import DeleteModal from "../../../pages/DeleteModal";
import { ByNode as FineSlabById } from "../../../queries/Library/Node";
import { UpdateLibFine } from "../../../queries/Library/FineSlab/mutation/update";
import useFineSlabOptions from "../customHooks/useFineSlabOptions";
import { fineSlabType } from "../../../Types/Library/Types";
import { EMPTY_STRING } from "../../../utils/constants";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../json/useLibraryTableJson";

const { Library } = require("../../../json/title.json");
const Index = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [fineTitle, setFineTitle] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [fineId, setFineId] = useState(0);
  const { Library_Table } = useLibraryTableJson();

  const [fineSlabArray, setFineSlabArray] = useState<fineSlabType[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //Modals flag
  const [deleteModal, setDeleteModal] = useState(false);
  const FineNameRef = useRef<HTMLInputElement>(null);

  //Queries
  const {
    fines: {
      queryData: { data, error },
    },
  } = useFineSlabOptions(ReturnType.QUERY_TYPE);
  const [GetFineSlabById, { data: FineSlabData, loading: FineSlabLoading }] =
    useLazyQuery(FineSlabById);

  //Mutations
  const [AddFineSlab, { loading: creationLoading }] = useMutation(AddLibFine, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateFineSlab, { loading: updationLoading }] = useMutation(
    UpdateLibFine,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteFine, { loading: deletionLoading }] = useMutation(
    DeleteLibFineById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const formLabels = () => {
    return [...new Array(5).keys()].map((i) => {
      return {
        label: "From Day",
        label2: "To Day",
        label3: "Amount / Day",
        id: (Math.random() + 1).toString(),
      };
    });
  };
  const fineSlabData = () => {
    return [...new Array(5).keys()].map((i, index) => {
      if (index === 0) {
        return {
          fromDay: 1,
          toDay: 0,
          amtPerDay: 0,
        };
      }
      return {
        fromDay: 0,
        toDay: 0,
        amtPerDay: 0,
      };
    });
  };
  const arr = [...formLabels()];
  const handleClear = () => {
    setFineTitle(EMPTY_STRING);
    setFineSlabArray([...fineSlabData()]);
    setOperation(Operation.CREATE);
    FineNameRef?.current?.focus();
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      //enableAdd and enableEdit should stay false when you close the success modal
      setOperation(Operation.CREATE);
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const HandleSubmit = () => {
    const input = {
      lib_fine_desc: fineTitle,
      lib_fine_slabs: fineSlabArray?.map(({ fromDay, toDay, amtPerDay }) => ({
        lib_slab_from: Number(fromDay),
        lib_slab_to: Number(toDay),
        lib_slab_fine: Number(amtPerDay),
      })),
    };

    if (operation === Operation.CREATE) {
      AddFineSlab({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibFineByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Fineslab Added successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      UpdateFineSlab({
        variables: {
          token,
          id: fineId,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibFineByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
          {
            query: FineSlabById,
            variables: {
              token,
              ids: fineId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "FineSlab Updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };

  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteFine({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibFineByInstId,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Fineslab Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };
  useEffect(
    () => {
      if (fineSlabData().length > 0 && operation === Operation.CREATE) {
        setFineSlabArray([...fineSlabData()]);
      }
    },
    // eslint-disable-next-line
    [fineSlabData().length, operation]
  );
  useEffect(
    () => {
      if (FineSlabData && !FineSlabLoading && operation === Operation.UPDATE) {
        const {
          lib_fine_desc,
          lib_slab1_from,
          lib_slab1_to,
          lib_slab1_fine,
          lib_slab2_from,
          lib_slab2_to,
          lib_slab2_fine,
          lib_slab3_from,
          lib_slab3_to,
          lib_slab3_fine,
          lib_slab4_from,
          lib_slab4_to,
          lib_slab4_fine,
          lib_slab5_from,
          lib_slab5_to,
          lib_slab5_fine,
        } = FineSlabData.nodes[0] || EMPTY_STRING;
        setFineTitle(lib_fine_desc);
        const item1 = {
          fromDay: lib_slab1_from,
          toDay: lib_slab1_to,
          amtPerDay: lib_slab1_fine,
        };
        fineSlabArray[0] = item1;
        const item2 = {
          fromDay: lib_slab2_from,
          toDay: lib_slab2_to,
          amtPerDay: lib_slab2_fine,
        };
        fineSlabArray[1] = item2;
        const item3 = {
          fromDay: lib_slab3_from,
          toDay: lib_slab3_to,
          amtPerDay: lib_slab3_fine,
        };
        fineSlabArray[2] = item3;
        const item4 = {
          fromDay: lib_slab4_from,
          toDay: lib_slab4_to,
          amtPerDay: lib_slab4_fine,
        };
        fineSlabArray[3] = item4;
        const item5 = {
          fromDay: lib_slab5_from,
          toDay: lib_slab5_to,
          amtPerDay: lib_slab5_fine,
        };
        fineSlabArray[4] = item5;
        setFineSlabArray([...fineSlabArray]);
      }
    },
    // eslint-disable-next-line
    [FineSlabData, FineSlabLoading, operation]
  );
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Library.FineSlab.Titles.map(
          (title: FineSlabTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.General}</React.Fragment>;
          }
        )}
      </Title>

      <Formik
        initialValues={{ fineTitle }}
        onSubmit={HandleSubmit}
        validationSchema={LibraryFineSlab}
        validateOnChange
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="fine-slab">
              <div className="row g-0 fine-slab__details">
                <div className="col h-100 booktype-left">
                  <div className="fine-slab__details--title">
                    <Title variant="subtitle1">
                      {Library.FineSlab.Titles.map(
                        (title: FineSlabTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>

                  <div className="fine-slab__frame">
                    <Input
                      name="fineTitle"
                      LabelName="Fine Name"
                      values={fineTitle}
                      inputRef={FineNameRef!}
                      autoFocus
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        meta.handleChange(e);
                        setFineTitle(e.target.value);
                      }}
                      required
                    />
                    <div className="label-grid">
                      <div></div>
                      <div>
                        {arr.map((row, index) => (
                          <div className="fine-slab__formlabels" key={index}>
                            <div className="fine-slab__formlabels--columns">
                              <Label>{index + 1})</Label>
                            </div>
                            <div className="fine-slab__formlabels--columns">
                              <Label>{row.label}</Label>
                              <Input
                                type="number"
                                name={"formDay" + index}
                                disabled={index > 0 ? true : false}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  fineSlabArray[index].fromDay = Number(
                                    e.target.value
                                  );
                                  setFineSlabArray([...fineSlabArray]);
                                }}
                                onFocus={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => e.target.select()}
                                values={
                                  fineSlabArray[index]?.fromDay ?? EMPTY_STRING
                                }
                              />
                            </div>
                            <div className="fine-slab__formlabels--columns">
                              <Label>{row.label2}</Label>
                              <Input
                                name={"toDay" + index}
                                type="number"
                                disabled={operation ? false : true}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  fineSlabArray[index].toDay = Number(
                                    e.target.value
                                  );
                                  if (fineSlabArray[index + 1]) {
                                    fineSlabArray[index + 1].fromDay =
                                      fineSlabArray[index].toDay + 1;
                                  }
                                  setFineSlabArray([...fineSlabArray]);
                                }}
                                onFocus={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => e.target.select()}
                                values={
                                  fineSlabArray[index]?.toDay ?? EMPTY_STRING
                                }
                              />
                            </div>
                            <div className="fine-slab__formlabels--columns--amountperday">
                              <Label>{row.label3}</Label>
                              <Input
                                name={"amtPerDay" + index}
                                type="number"
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  fineSlabArray[index].amtPerDay = Number(
                                    e.target.value
                                  );
                                  setFineSlabArray([...fineSlabArray]);
                                }}
                                onFocus={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => e.target.select()}
                                values={
                                  fineSlabArray[index]?.amtPerDay ??
                                  EMPTY_STRING
                                }
                                disabled={operation ? false : true}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col h-100 booktype-right">
                  <div className="fine-slab__details--title">
                    <Title variant="subtitle1">
                      {Library.FineSlab.Titles.map(
                        (title: FineSlabTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.List}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="fine-slab__frame--tableblock">
                    {error ? (
                      error.message
                    ) : data && data?.GetLibFineByInstId?.length > 0 ? (
                      <TableContainer className="fine-slab__frame--table">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {Library_Table.FineSlab.Table_Headers.map(
                                (th: TableHeaderProps, index: React.Key) => {
                                  return (
                                    <TableCell
                                      key={index}
                                      className={th.className}
                                    >
                                      {th.labelName}
                                    </TableCell>
                                  );
                                }
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data?.GetLibFineByInstId?.map(
                              (row, index: number) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      id="td-center"
                                      className="fine-slab__frame--table--slno"
                                    >
                                      1
                                    </TableCell>
                                    <TableCell>{row.lib_fine_desc}</TableCell>
                                    <TableCell
                                      id="td-center"
                                      className="fine-slab__frame--table--actions"
                                    >
                                      <img
                                        src={EditProfile}
                                        alt="/"
                                        onClick={() => {
                                          setOperation(Operation.UPDATE);
                                          setFineId(row.id);
                                          GetFineSlabById({
                                            variables: {
                                              token,
                                              ids: [row.id],
                                            },
                                          });
                                        }}
                                      />
                                      &nbsp;
                                      <img
                                        src={Delete}
                                        alt="/"
                                        onClick={() => {
                                          setFineId(row.id);
                                          setDeleteModal(!deleteModal);
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <b className="nodata"> Sorry, no results</b>
                    )}
                  </div>
                </div>
              </div>
              <div className="button-left">
                <Button mode="save" type="submit" />
                <Button
                  mode="clear"
                  type="button"
                  onClick={() => {
                    handleClear();
                  }}
                />

                <Button mode="back" onClick={() => navigate(-1)} />
              </div>
            </Form>
          );
        }}
      </Formik>
      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deletionLoading}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={fineId}
      />
    </>
  );
};

export default Index;

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetAccountLedgerNameById,
  GetAcctDemandDetails,
} from "../../../Accounts/queries/FeeLedgers/query/Byid";
import { GetAcctDemandMainStudentDemandByStudentId } from "../../../../queries/students/list/byId";
import { AddAcctStudentDemandDetailsByDemandMainId } from "../../../../queries/students/mutations/new";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAccountLedgerNameData,
  ListAccountLedgerNameVars,
  ListDemandDetailsData,
  ListDemandDetailsVars,
} from "../../../../Types/Accounting";
import {
  studentDemandDataByStudentId,
  studentDemandDataByStudentIdVars,
} from "../../../../Types/Student";
import { StudentsTitlesType } from "../../../../Types/Student/json";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Operation, YesNo } from "../../../../utils/Enum.types";
import { msgType, studentFeeDemand } from "../../../../utils/Form.types";

import LoadingModal from "../../../../pages/LoadingModal";
import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";
import useToken from "../../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../../utils/constants";
import Home from "../../Home/Index";
import MessageModal from "../../../../pages/MessageModal";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { formatter } from "../../../../utils/UtilFunctions";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import {
  AddAcctStudentDemandDetailsByDemandMainIdData,
  AddAcctStudentDemandDetailsByDemandMainIdVars,
} from "../../../../Types/Accounting/mutations";
import { studentRegStepHeader } from "./BasicDetails";

const { Students } = require("../../../../json/title.json");

interface Props {
  type?: Operation;
  step: number;
}

const FeeDemandDisplay = ({ type, step }: Props) => {
  const { InstId, studentId } = useParams();
  const { token } = useToken();
  const { format } = formatter;
  const { Masters_Table } = useMasterTableJson();
  const { state } = useContext(AppContext);
  const stepsHeader = studentRegStepHeader();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [formData, setFormData] = useState<studentFeeDemand>({
    admn_no: "",
    std_name: "",
    fresher: false,
    demand_yr: "",
    demand_amt: 0,
    raise_demand: YesNo.NO,
  });
  const navigate = useNavigate();

  const [GetStudentDemand, { data: StudentData, loading: StudentLoading }] =
    useLazyQuery<
      studentDemandDataByStudentId,
      studentDemandDataByStudentIdVars
    >(GetAcctDemandMainStudentDemandByStudentId, {
      variables: {
        token,
        student_id: studentId!,
      },
    });
  const { user_details } = useLoggedInUserDetails();
  const { studentData: studentAcdDetails } = useStudentDatabyId();
  const [GetDemandDetails, { data: AcctDemandDetailsData }] = useLazyQuery<
    ListDemandDetailsData,
    ListDemandDetailsVars
  >(GetAcctDemandDetails, {
    variables: {
      token,
      branch_id: studentAcdDetails.data?.nodes[0].branch.id!,
      category_id: studentAcdDetails.data?.nodes[0].category.id!,
      class_id: studentAcdDetails.data?.nodes[0].class.id!,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
    },
  });

  const [GetAcctLedgers, { data: AcctLedgerNames }] = useLazyQuery<
    ListAccountLedgerNameData,
    ListAccountLedgerNameVars
  >(GetAccountLedgerNameById, {
    variables: {
      token,
      acct_ldgr_ids: AcctDemandDetailsData?.GetAcctDemandDetails.map(
        ({ acct_ldgr_id }) => acct_ldgr_id
      )! ?? [""],
    },
  });
  const [AddStudentDemandDetails, { loading: creationLoading }] = useMutation<
    AddAcctStudentDemandDetailsByDemandMainIdData,
    AddAcctStudentDemandDetailsByDemandMainIdVars
  >(AddAcctStudentDemandDetailsByDemandMainId, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  useEffect(() => {
    if (StudentData && !StudentLoading) {
      if (state.ActiveFinYr) {
        const {
          first_name,
          middle_name,
          last_name,
          std_adm_no,
          std_fresher,
          total_demand_new_std,
          total_demand_exs_std,
          total_demand_all_std,
        } = StudentData.GetAcctDemandMainStudentDemandByStudentId[0];

        const { fin_yr } = state.ActiveFinYr;

        setFormData({
          admn_no: std_adm_no,
          std_name: first_name + " " + middle_name + " " + last_name,
          fresher: std_fresher,
          demand_yr: fin_yr,
          demand_amt: std_fresher
            ? total_demand_new_std + total_demand_all_std
            : total_demand_exs_std + total_demand_all_std,
          raise_demand: YesNo.NO,
        });
      }
    }
  }, [StudentData, StudentLoading, state.ActiveFinYr]);
  const demandStructure =
    AcctDemandDetailsData &&
    AcctDemandDetailsData.GetAcctDemandDetails.map(
      ({ fee_for_all, fee_for_exs_std, fee_for_new_std, acct_ldgr_id }) => ({
        fee_for_all: fee_for_all,
        fee_for_exs_std: fee_for_exs_std,
        fee_for_new_std: fee_for_new_std,
        acct_ldgr_id: acct_ldgr_id,
      })
    );
  let fresherAmt = 0;
  let oldStudentAmt = 0;
  demandStructure?.forEach(
    ({ fee_for_all, fee_for_exs_std, fee_for_new_std }) => {
      fresherAmt += fee_for_all + fee_for_new_std;
      oldStudentAmt += fee_for_all + fee_for_exs_std;
    }
  );

  const handleSubmit = async () => {
    if (state.ActiveFinYr === null) {
      setMessage({
        flag: true,
        message: "Active Financial Year not found",
        operation: Operation.NONE,
      });
      return;
    }
    await AddStudentDemandDetails({
      variables: {
        token,
        student_id: studentId!,
        acct_demand_main_id: StudentData
          ? StudentData.GetAcctDemandMainStudentDemandByStudentId[0]
              .acct_demand_main_id
          : 0,
        inst_id: InstId!,
        fin_yr_id: state.ActiveFinYr.id,
        user_details,
      },
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Student Demand added successfully",
          flag: true,
          operation: Operation.CREATE,
        });
    });
    navigate(`/${InstId}/admissions/students`);
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token) {
      GetStudentDemand();
    }
  }, [token, GetStudentDemand]);
  useEffect(() => {
    if (token) {
      GetDemandDetails();
    }
  }, [token, GetDemandDetails]);
  useEffect(() => {
    if (token) {
      GetAcctLedgers();
    }
  }, [token, GetAcctLedgers]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="previous-inst">
        <Stepper alternativeLabel activeStep={step - 1}>
          {stepsHeader.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <StudentAcademicDetails />
        <div className="fee-payable-student">
          <Title variant="subtitle1">
            {Students.Titles.map(
              (title: StudentsTitlesType, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.Fee_Demand}
                  </React.Fragment>
                );
              }
            )}
          </Title>

          <div className="fee-payable-student__details">
            <div className="row g-0 fee-payable-student__tableblock">
              <TableContainer className="fee-payable-student__details--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Masters_Table.Students.FeeDemandDisplay.RaiseDemand.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        id="td-center"
                        className="fee-payable-student__details--table--slno"
                      >
                        1
                      </TableCell>
                      <TableCell className="fee-payable-student__details--table--amt">
                        {formData.admn_no}
                      </TableCell>
                      <TableCell>{formData.std_name}</TableCell>
                      <TableCell className="fee-payable-student__details--table--status">
                        {formData.fresher ? YesNo.YES : YesNo.NO}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="fee-payable-student__details--table--amt"
                      >
                        {formData.demand_yr}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="fee-payable-student__details--table--amt"
                      >
                        {format(formData.demand_amt)}
                      </TableCell>
                      <TableCell className="fee-payable-student__details--table--amt">
                        {formData.raise_demand}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Title variant="subtitle1">
              {Students.Titles.map(
                (title: StudentsTitlesType, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.Demand_Structure}
                    </React.Fragment>
                  );
                }
              )}
            </Title>
            <div className="row g-0 fee-payable-student__demand-structure">
              <TableContainer className="fee-payable-student__demand-structure--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Masters_Table.Students.FeeDemandDisplay.DemandStructure.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demandStructure?.map((item, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            id="td-center"
                            className="fee-payable-student__demand-structure--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            {AcctLedgerNames?.GetAcctLdgrNamesByIds[index]
                              ?.id === item.acct_ldgr_id
                              ? AcctLedgerNames?.GetAcctLdgrNamesByIds[index]
                                  ?.ldgr_desc
                              : EMPTY_STRING}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="fee-payable-student__demand-structure--table--amt"
                          >
                            {formData.fresher
                              ? format(item.fee_for_all + item.fee_for_new_std)
                              : format(item.fee_for_all + item.fee_for_exs_std)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2} id="td-right">
                        Total
                      </TableCell>
                      <TableCell className="totalcount" id="td-right">
                        {formData.fresher
                          ? format(fresherAmt)
                          : format(oldStudentAmt)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Button mode="finish" autoFocus onClick={handleSubmit} />
        <Button
          mode="back"
          onClick={() =>
            navigate(
              `/${InstId}/admissions/students/4/${studentId}/studentParentAssociation`
            )
          }
        />
      </div>

      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default FeeDemandDisplay;

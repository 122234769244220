import { gql } from "@apollo/client";

export const AddLibBookVendor = gql`
  mutation AddLibBookVendor(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateLibBookVendorInput!
  ) {
    AddLibBookVendor(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

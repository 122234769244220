import { gql } from "@apollo/client";

export const GetAcdYrsByInstId = gql`
  query GetAcdYrsByInstId($inst_id: ID!, $token: String!) {
    GetAcdYrsByInstId(token: $token, inst_id: $inst_id) {
      id
      acd_yr
      acd_st_date
      acd_end_date
      acd_is_curr_yr
      re_opening_date
    }
  }
`;
export const GetAcdYrsFuture = gql`
  query GetAcdYrsFuture($inst_id: ID!, $token: String!, $acd_yr_id: ID!) {
    GetAcdYrsFuture(token: $token, inst_id: $inst_id, acd_yr_id: $acd_yr_id) {
      id
      acd_yr
      acd_st_date
      acd_end_date
      acd_is_curr_yr
      inst_id
    }
  }
`;
export const AcdYrDetailsByNode = gql`
  query AcdYrDetailsByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstInstAcdYr {
        acd_yr
        acd_st_date
        acd_end_date
        inst_id
        id
      }
    }
  }
`;
export const GetAcdYrActiveByInstId = gql`
  query GetAcdYrActiveByInstId($token: String!, $inst_id: ID!) {
    GetAcdYrActiveByInstId(token: $token, inst_id: $inst_id) {
      id
      acd_yr
      acd_st_date
      acd_end_date
      acd_is_curr_yr
    }
  }
`;

import React, { useState } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import InstDetails from "../../../DailyActivities/InstDetails";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../../styles/Tabs";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Test from "./Test";
import Attendance from "../Attendance/Attendance";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import ReportCards from "../ReportCards/Index";
const View = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index: number) => setValue(index);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>View Academic Reports</Title>
      <div className="acd-reports__view">
        <InstDetails />
        <div className="acd-reports__view--tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Test / Exam Report" value={0} {...a11yProps(0)} />
            <Tab label="Attendance Report" value={1} {...a11yProps(1)} />
            <Tab label="Report Cards" value={2} {...a11yProps(2)} />
          </Tabs>
        </div>
        <div className="acd-reports__view--swipable">
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            className="h-100"
          >
            {/* values for Test */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Test />
            </TabPanel>
            {/* values for Attendance */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Attendance />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <ReportCards />
            </TabPanel>
          </SwipeableViews>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default View;

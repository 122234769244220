import { gql } from "@apollo/client";

export const UpdateLibBookMedia = gql`
  mutation UpdateLibBookMedia(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateLibBookMediaInput!
  ) {
    UpdateLibBookMedia(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateAcctMiscActivities = gql`
  mutation UpdateAcctMiscActivities(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateAcctMiscActivitiesInput!
  ) {
    UpdateAcctMiscActivities(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateLibBookDept = gql`
  mutation UpdateLibBookDept(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateLibBookDeptInput!
  ) {
    UpdateLibBookDept(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

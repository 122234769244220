import { gql } from "@apollo/client";

export const AddSwGeneralHolidays = gql`
  mutation AddSwGeneralHolidays(
    $token: String!
    $user_details: SubscribedUser!
    $input: CreateSwGeneralHolidaysInput!
  ) {
    AddSwGeneralHolidays(
      token: $token
      user_details: $user_details
      input: $input
    )
  }
`;

export const UpdateSwGeneralHolidays = gql`
  mutation UpdateSwGeneralHolidays(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwGeneralHolidaysInput!
  ) {
    UpdateSwGeneralHolidays(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;

export const DeleteSwGeneralHolidaysById = gql`
  mutation DeleteSwGeneralHolidaysById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteSwGeneralHolidaysById(
      token: $token
      id: $id
      user_details: $user_details
    )
  }
`;

export const AddSwSalaryLdgr = gql`
  mutation AddSwSalaryLdgr(
    $token: String!
    $input: CreateSwSalaryLdgrInput!
    $user_details: SubscribedUser!
  ) {
    AddSwSalaryLdgr(token: $token, input: $input, user_details: $user_details)
  }
`;

export const UpdateSwSalaryLdgr = gql`
  mutation UpdateSwSalaryLdgr(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwSalaryLdgrInput!
  ) {
    UpdateSwSalaryLdgr(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;

export const AddSwAcctLdgr = gql`
  mutation AddSwAcctLdgr(
    $token: String!
    $input: CreateSwAcctLdgrInput!
    $user_details: SubscribedUser!
  ) {
    AddSwAcctLdgr(token: $token, input: $input, user_details: $user_details)
  }
`;

export const UpdateSwAcctLdgr = gql`
  mutation UpdateSwAcctLdgr(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwAcctLdgrInput!
  ) {
    UpdateSwAcctLdgr(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;

export const DeleteSwAcctLdgr = gql`
  mutation DeleteSwAcctLdgr(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteSwAcctLdgr(token: $token, id: $id, user_details: $user_details)
  }
`;

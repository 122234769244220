
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import {  TextField } from "@mui/material";
import Input from ".././../../../stories/Input/Input";
import {
  TableHeaders,
  StudentReportType,
  PageFor,
} from "../../../../utils/Enum.types";
import MessageListModal from "../../../Channel/MessageList";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import Home from "../../Home/Index";

import { Keys } from "../../../../utils/Enum.keys";

import { responseType } from "../../../../utils/Form.types";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import useEmpMasterData from "../../../HR/hooks/useEmpMasterData";
import useEmployee, {
  PayRollEmpEdges,
  empQueryTypes,
} from "../../../HR/hooks/useEmployee";
import { AccountentType } from "../../../Accounts/common/Enum.types";
import View from "../../../../images/EyeWhite.svg";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import useAcademicTableJson from "../../json/useAcademicTable";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";
interface Props {
  pageType: PageFor;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  queryType: StudentReportType;
  instSelected?: responseType | null;
}
const TeacherWiseAllocatedReports = ({ pageType, setStudentModal }: Props) => {

  const navigate = useNavigate();
  const { InstId } = useParams();
  const [messageModal, setMessageModal] = useState(false);
  const { TeacherWise } = useAcademicTableJson();
  const [designationSelected, setDesignationSelected] =
    useState<responseType | null>(null);

  const [searchData, setSearchData] = useState("");
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.EMP_BY_TYPE,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0,
    [AccountentType.TEACHER]
  );
  const { designationDropDown } = useEmpMasterData();
  const clear = () => {
    setDesignationSelected(null);
    setSearchData("");
  };
  
  const dynamicHeaders: TableHeaderProps[] = TeacherWise.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const employeeId = params.row.emp_id
        return (
          <>
            <button
              className="teacher-wise-allocated-reports__view"
              onClick={() =>
                navigate(
                  `/${InstId}/academics/reports/teacherwise/${employeeId}/perTeacherDetails`
                )
              }
            >
              View <img src={View} alt="" />
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (employee) => employee.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            emp_id: node.id,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            designation: node.pr_designation_details.designation_desc,
            allocated_class: 0,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            emp_id: node.id,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            designation: node.pr_designation_details.designation_desc,
            allocated_class: 0,
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetPayRollEmp.edges;
              const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetPayRollEmp: {
                  edges: [...employees, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <div
      className={
        pageType === PageFor.GENERAL ? "studentlist" : "studentlist--modal"
      }
    >
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>Teacher Wise Allocated Reports</Title>
        </div>
      </div>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 studentlist__options"
            : "row g-0 studentlist__options--modal"
        }
      >
        <div className="col-1 studentlist__select-flex studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchData(e.target.value)
            }
            value={searchData}
            onKeyDown={handleFormEvent}
          />
        </div>

        <div className="col-1">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={designationDropDown}
            isOptionEqualToValue={(option) =>
              isOptionEqualToValue(option as responseType, designationSelected)
            }
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                e.preventDefault();
                if (designationSelected) {
                  handleMUISelectEvent(e);
                }
              }
              if (e.key === Keys.BACKSPACE) {
                setDesignationSelected(null);
              }
            }}
            openOnFocus
            value={designationSelected}
            onChange={(e, newValue) => {
              if (newValue) {
                setDesignationSelected(newValue as responseType);
              } else {
                setDesignationSelected(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Designation"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>

        <div className="col"></div>
        <div className="col-1">
          <Button
            mode="clear"
            onClick={(e: React.FormEvent) => {
              e.preventDefault();
              clear();
            }}
            className={
              pageType === PageFor.GENERAL
                ? "student-clear-button--acd"
                : "student-clear-button--modal"
            }
          />
        </div>
      </form>
      <div
        id={
          pageType === PageFor.MODAL
            ? "studentlist__transport--modal"
            : "studentlist__transport"
        }
      >
        {!employees?.length ? (
          <b className="nodata">Sorry, No Students Found</b>
        ) : (
          <>
         

            <div className={`h100 `}>
              <StyledDatagrid
                columns={columns}
                rows={rows}
                rowHeight={TABLE_ROW_HEIGHT}
          
              
                
                disableRowSelectionOnClick
                hideFooter
                
              />
            </div>
          </>
        )}
      </div>
      <div className="studentlist__buttons row g-0">
        <div className="col-2">
          {pageType === PageFor.GENERAL ? (
            <div className="button-left">
              <Button mode="back" onClick={() => navigate(-1)} />
            </div>
          ) : (
            <Button mode="cancel" onClick={() => setStudentModal?.(false)} />
          )}
        </div>
        <div className="col"></div>
        <div className="col-3 flex-end">
          {" "}
          <StudentTotalCount totalCount={data?.GetPayRollEmp.totalCount!} />
        </div>
      </div>
      {/* MessageModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TeacherWiseAllocatedReports;

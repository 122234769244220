import { Alert, Snackbar } from "@mui/material";
import useSwConfigData from "../customhooks/useSwConfigData";
import { LibraryConfigKey, Operation } from "../utils/Enum.types";

interface Props {
  modalFlag: boolean;
  value: string;
  handleClose: () => void;
  operation: Operation;
}
const MessageModal = ({ modalFlag, value, handleClose, operation }: Props) => {
  const { configData } = useSwConfigData(LibraryConfigKey.TOAST_MSG_TIMEOUT);
  const DEFAULT_HIDE_DURATION = 3;
  const SECONDS_TO_MILLISEC_UNIT = 1000;
  const autoHideDuration = configData.data
    ? configData.data?.GetSwConfigVariables[0].config_integer_value
    : DEFAULT_HIDE_DURATION;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{ zIndex: "2000 !important" }}
      open={modalFlag}
      autoHideDuration={autoHideDuration * SECONDS_TO_MILLISEC_UNIT}
      onClose={handleClose}
      className="eduate-success-message"
    >
      <Alert
        onClose={handleClose}
        severity={operation === Operation.NONE ? "error" : "success"}
        sx={{
          width: "100%",
          color: "white",
          background: operation === Operation.NONE ? "red" : "green",
        }}
      >
        {value}
      </Alert>
    </Snackbar>
  );
};

export default MessageModal;

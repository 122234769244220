import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import { TextField } from "@mui/material";
import { toInputStandardDate, toIsoDate } from "../../../utils/UtilFunctions";
import { EMPTY_STRING, TODAY_DATE } from "../../../utils/constants";
import { Button } from "../../../stories/Button/Button";
import TextArea from "../../../stories/TextArea/TextArea";
import Copy from "../../../images/CopyDemand.svg";

import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import Review, { PageFor } from "./Review";
import useQuestionsConfig from "./hooks";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddOrUpdatePayRollEmpDailyDiary } from "./queries/mutations";
import { msgType } from "../../../utils/Form.types";
import { Operation, PrEmpDailyDiaryQueryType } from "../../../utils/Enum.types";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import usePayRollActiveAcademicYear from "../../HR/hooks/usePayRollActiveAcademicYear";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import { GetPayRollEmpDailyDiary } from "./queries";
import { labelClasses, LabeledAutocomplete } from "../../../styles/AutocompleteListStyles";

export interface GetPayRollEmpDailyDiaryList {
  id: number;
  date_of_diary: string;
  question_idx: number;
  question: string;
  answer: string;
  inst_id: number;
  pr_acd_yr_id: number;
  pr_emp_id: number;
}
export interface GetPayRollEmpDailyDiaryData {
  GetPayRollEmpDailyDiary: GetPayRollEmpDailyDiaryList[];
}
export interface GetPayRollEmpDailyDiaryVars {
  token: string;
  inst_id: string | number;
  pr_acd_yr_id: number;
  pr_emp_id: number;
  input: {
    query_type: PrEmpDailyDiaryQueryType | string;
    date_of_diary: string;
    question_idx: number;
  };
}
interface Props {
  setFillDiary: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ setFillDiary }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const pr_acd_yr_id = payRollActiveAcademicYear.data
    ? payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id
    : 0;
  const remarksRef = useRef<HTMLTextAreaElement>(null);

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [review, setReview] = useState(false);

  const [answer, setAnswer] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const {
    question1Boolean,
    question1String,
    question2Boolean,
    question2String,
    question3Boolean,
    question3String,
    question4Boolean,
    question4String,
    question5Boolean,
    question5String,
    question6Boolean,
    question6String,
    question7Boolean,
    question7String,
    question8Boolean,
    question8String,
    question9Boolean,
    question9String,
    question10Boolean,
    question10String,
  } = useQuestionsConfig();
  let queryType: string = PrEmpDailyDiaryQueryType.PREV_DAY_DIARY_NOTES;

  switch (statusSelected) {
    case "Monday":
      queryType = PrEmpDailyDiaryQueryType.LAST_MON_DIARY_NOTES;
      break;
    case "Tuesday":
      queryType = PrEmpDailyDiaryQueryType.LAST_TUE_DIARY_NOTES;
      break;
    case "Wednesday":
      queryType = PrEmpDailyDiaryQueryType.LAST_WED_DIARY_NOTES;
      break;
    case "Thursday":
      queryType = PrEmpDailyDiaryQueryType.LAST_THUR_DIARY_NOTES;
      break;
    case "Friday":
      queryType = PrEmpDailyDiaryQueryType.LAST_FRI_DIARY_NOTES;
      break;
    case "Saturday":
      queryType = PrEmpDailyDiaryQueryType.LAST_SAT_DIARY_NOTES;
      break;
    default:
      break;
  }
  const handleNumberClick = (index: number) => {
    if (index >= 0 && index < questions.length) {
      setActiveQuestionIndex(index);
    }
  };

  const questions = [
    {
      id: 1,
      question: question1String,
      flag: question1Boolean,
    },
    {
      id: 2,
      question: question2String,
      flag: question2Boolean,
    },
    {
      id: 3,
      question: question3String,
      flag: question3Boolean,
    },
    {
      id: 4,
      question: question4String,
      flag: question4Boolean,
    },
    {
      id: 5,
      question: question5String,
      flag: question5Boolean,
    },
    {
      id: 6,
      question: question6String,
      flag: question6Boolean,
    },
    {
      id: 7,
      question: question7String,
      flag: question7Boolean,
    },
    {
      id: 8,
      question: question8String,
      flag: question8Boolean,
    },
    {
      id: 9,
      question: question9String,
      flag: question9Boolean,
    },
    {
      id: 10,
      question: question10String,
      flag: question10Boolean,
    },
  ].filter((data) => data.flag === true);
  const questionIndex = questions[activeQuestionIndex]
    ? questions[activeQuestionIndex].id
    : 0;
  const questionName = questions[activeQuestionIndex]
    ? questions[activeQuestionIndex].question
    : EMPTY_STRING;
  const [AddDailyDairy, { loading }] = useMutation(
    AddOrUpdatePayRollEmpDailyDiary,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetDairyData, { data: DairyData }] = useLazyQuery<
    GetPayRollEmpDailyDiaryData,
    GetPayRollEmpDailyDiaryVars
  >(GetPayRollEmpDailyDiary);
  const [GetPreviousDairyData, { data: PreviousDairyData }] = useLazyQuery<
    GetPayRollEmpDailyDiaryData,
    GetPayRollEmpDailyDiaryVars
  >(GetPayRollEmpDailyDiary);
  useEffect(() => {
    if (token && pr_acd_yr_id) {
      GetDairyData({
        variables: {
          token,
          inst_id: Number(InstId)!,
          pr_acd_yr_id,
          pr_emp_id: state.empLoginId,
          input: {
            query_type: PrEmpDailyDiaryQueryType.DIARY_NOTES,
            date_of_diary: toIsoDate(TODAY_DATE),
            question_idx: questionIndex,
          },
        },
      }).then(({ data }) => {
        if (data && data.GetPayRollEmpDailyDiary.length > 0) {
          const answer = data.GetPayRollEmpDailyDiary[0].answer;
          setAnswer(answer !== undefined ? answer : EMPTY_STRING);
        } else {
          setAnswer(EMPTY_STRING);
        }
      });
    } // eslint-disable-next-line
  }, [token, GetDairyData, pr_acd_yr_id, questionIndex]);

  useEffect(() => {
    if (token && pr_acd_yr_id && queryType) {
      GetPreviousDairyData({
        variables: {
          token,
          inst_id: InstId!,
          pr_acd_yr_id,
          pr_emp_id: state.empLoginId,
          input: {
            query_type: queryType,
            date_of_diary: toIsoDate(TODAY_DATE),
            question_idx: questionIndex,
          },
        },
      });
    } // eslint-disable-next-line
  }, [token, pr_acd_yr_id, GetPreviousDairyData, queryType, questionIndex]);

  const HandlePreviousDairyData = () => {
    setActiveQuestionIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      return nextIndex >= 0 ? nextIndex : prevIndex;
    });
  };
  const HandleAddDairyData = () => {
    if (answer.length === 0) {
      return remarksRef?.current?.focus();
    }
    setActiveQuestionIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      if (nextIndex === questions.length) {
        setReview(!review);
      }
      return nextIndex < questions.length ? nextIndex : prevIndex;
    });

    AddDailyDairy({
      variables: {
        token,
        inst_id: InstId!,
        pr_acd_yr_id,
        user_details,
        input: {
          pr_emp_id: state.empLoginId,
          date_of_diary: toIsoDate(TODAY_DATE),
          question_idx: questionIndex,
          question: questionName,
          answer,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollEmpDailyDiary,
          variables: {
            token,
            inst_id: Number(InstId)!,
            pr_acd_yr_id,
            pr_emp_id: state.empLoginId,
            input: {
              query_type: PrEmpDailyDiaryQueryType.DIARY_NOTES,
              date_of_diary: toIsoDate(TODAY_DATE),
              question_idx: questionIndex,
            },
          },
        },
        {
          query: GetPayRollEmpDailyDiary,
          variables: {
            token,
            inst_id: Number(InstId)!,
            pr_acd_yr_id,
            pr_emp_id: state.empLoginId,
            input: {
              query_type: PrEmpDailyDiaryQueryType.DIARY_NOTES,
              date_of_diary: toIsoDate(TODAY_DATE),
              question_idx: 0,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        if (
          DairyData &&
          DairyData.GetPayRollEmpDailyDiary?.[0]?.answer &&
          DairyData.GetPayRollEmpDailyDiary[0].answer.length > 0
        ) {
          return;
        }
        setAnswer(EMPTY_STRING);
        remarksRef?.current?.focus();
      }
    });
  };

  const handleCopyMessage = () => {
    const messageElement = document.querySelector(
      ".daily-diary__datablock--message--text"
    );
    if (messageElement) {
      const range = document.createRange();
      range.selectNode(messageElement);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
    }
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  let today = new Date();
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let formattedDate = `${yesterday.getDate()}-${
    yesterday.getMonth() + 1
  }-${yesterday.getFullYear()}`;
  return (
    <>
      <div className="daily-diary">
        <div className="daily-diary__title">
          <Title>Daily Diary</Title>
          <img src={Close} alt="" onClick={() => setFillDiary(false)} />
        </div>
        <div className="row g-0">
          <div className="col-2">
            <TextField
              className="daily-diary__textfield"
              disabled
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              label="Date"
              value={toInputStandardDate(TODAY_DATE)}
            />
          </div>
        </div>
        <div className="daily-diary__numbers">
          <ul>
            {questions.map((question, index) => (
              <li
                key={question.id}
                onClick={() => handleNumberClick(index)}
                className={activeQuestionIndex === index ? "active" : ""}
              >
                {question.id}
              </li>
            ))}
          </ul>
        </div>
        <div className="daily-diary__datablock">
          <div className="daily-diary__datablock--qa">
            <p>
              {questionIndex}. {questionName}
            </p>
            <TextArea
              rows={6}
              value={answer}
              autoFocus
              textAreaRef={remarksRef}
              id={`answer-${questionIndex}`}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </div>

          <div className="daily-diary__datablock--message">
            <div className="daily-diary__datablock--message--header">
              {statusSelected ? null : (
                <>
                  <span className="daily-diary__datablock--message--header--date">
                    {formattedDate}
                  </span>
                  <button className="daily-diary__datablock--message--header--previous-response">
                    Yesterday Status
                  </button>
                </>
              )}
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ]}
                value={statusSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStatusSelected(newValue as string);
                  } else {
                    setStatusSelected(EMPTY_STRING);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Last"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="daily-diary__datablock--message--text">
              {PreviousDairyData &&
                (PreviousDairyData.GetPayRollEmpDailyDiary[0]?.answer || "")}
            </div>
            <div className="daily-diary__datablock--message--footer">
              <button
                onClick={handleCopyMessage}
                className="daily-diary__datablock--message--footer--copy"
              >
                <img src={Copy} alt="" />
                Copy Text
              </button>
            </div>
          </div>
        </div>
        <div className="daily-diary__btns">
          <div>
            <Button mode="cancel" onClick={() => setFillDiary(false)} />
          </div>
          <div>
            {questionIndex === 1 ? null : (
              <Button mode="previous" onClick={HandlePreviousDairyData} />
            )}
            <Button mode="next" onClick={HandleAddDairyData} />
          </div>
        </div>
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      {/* <Button mode="next" onClick={() => setReview(!review)} /> */}

      <Modal isOpen={review} style={StudentModalStyles} ariaHideApp={false}>
        <Review
          setModal={setReview}
          setFillDiary={setFillDiary}
          pageType={PageFor.SUBMIT}
        />
      </Modal>
    </>
  );
};

export default Index;

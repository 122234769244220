/**
 * React functional component for configuring a bill book.
 *
 * Inputs:
 * - voucherBooks: An array of voucher books data.
 * - captationFee: A boolean value indicating whether to hide the book or not.
 * - ActiveFinancicalYear: The active financial year data.
 * - InstId: The institution ID.
 * - token: The authentication token.
 *
 * Flow:
 * 1. Initializes the state variables using the useState hook.
 * 2. Fetches the voucher books data using the useBillbooks hook.
 * 3. Renders a form with input fields and dropdowns for configuring the bill book settings.
 * 4. When a user selects a bill book from the dropdown, the HandleVoucherBook function is called to update the state with the selected voucher book details.
 * 5. User can update the configuration settings in the form fields.
 * 6. When the user clicks the "Save" button, the handleVoucherUpdate function is called to perform a mutation and update the voucher book configuration.
 * 7. If the mutation is successful, a success message is displayed.
 * 8. If there is an error, an error message is displayed.
 * 9. User can close the message modal by clicking the close button.
 * 10. Renders a loading modal while the mutation is in progress.
 *
 * Outputs:
 * - Renders a form for configuring the bill book settings.
 * - Updates the voucher book configuration when the user clicks the "Save" button.
 * - Displays success/error messages based on the mutation result.
 */
import React, { useContext, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import { AntSwitch } from "../../../pages/Switch";
import useBillbooks, { GetStdVoucherBooksDetails } from "../hooks/useBillbooks";
import { width } from "../../../utils/constants";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import {
  handleZeros,
  isOptionEqualToValue,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import { UpdateVoucherBooksByBookId } from "../../../queries/institution/configuration/mutation";
import { useMutation } from "@apollo/client";
import { Operation, SeparatorTypes } from "../../../utils/Enum.types";
import { Keys } from "../../../utils/Enum.keys";
import LoadingModal from "../../../pages/LoadingModal";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GetStdVoucherBooks } from "../queries/Vouchers/query";
import { InstitutionAcctConfigurationTypes } from "../common/Enum.types";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";

const Index = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [voucherBookType, setVoucherBookType] = useState<responseType | null>(
    null
  );
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [voucherBookData, setVoucherBookData] =
    useState<GetStdVoucherBooksDetails>({
      id: 0,
      is_book_system_based: false,
      is_prefixed: false,
      prefill_width: 0,
      prefill_with_zero: false,
      separator: " ",
      suffix_str: "",
      vo_book_current_no: "",
      vo_book_desc: "",
      vo_book_key: "",
      vo_book_start_no: "",
      prefix_str: "",
      voucher_sub_type: "",
      voucher_type: "",
      hide_this_book: false,
    });
  const separatorType = Object.values(SeparatorTypes).map((value) => {
    return value;
  });
  const { configData: captationFee } = useSwConfigData(
    InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE
  );

  const { voucherBooks } = useBillbooks();

  const [updateVoucherConfiguration, { loading: updationloading }] =
    useMutation(UpdateVoucherBooksByBookId, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const HandleVoucherBook = (voucherBookId: number) => {
    const filteredVoucherDetails = voucherBooks?.data?.GetStdVoucherBooks.find(
      ({ id }) => id === voucherBookId
    );
    if (filteredVoucherDetails) {
      setVoucherBookData(filteredVoucherDetails);
      handleZeros(filteredVoucherDetails.prefill_width.toString());
    }
  };

  const handleVoucherUpdate = () => {
    updateVoucherConfiguration({
      variables: {
        token,
        vo_Book_id: voucherBookData.id,
        input: {
          vo_book_current_no: voucherBookData.vo_book_current_no,
          vo_book_start_no: voucherBookData.vo_book_start_no,
          is_prefixed: voucherBookData.is_prefixed,
          prefix_str: voucherBookData.prefix_str,
          suffix_str: voucherBookData.suffix_str,
          separator: voucherBookData.separator,
          prefill_with_zero: voucherBookData.prefill_with_zero,
          prefill_width: Number(voucherBookData.prefill_width),
          // hide_this_book: voucherBookData.hide_this_book,
          hide_this_book: false,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetStdVoucherBooks,
          variables: {
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr : 0,
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Voucher Configuration Made Successfully",
          flag: true,

          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherBookData((prevValues) => ({
      ...prevValues,
      [e.target.name]: removeMoreSpace(e.target.value),
    }));
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setVoucherBookData({
        id: 0,
        is_book_system_based: false,
        is_prefixed: false,
        prefill_width: 0,
        prefill_with_zero: false,
        separator: " ",
        suffix_str: "",
        vo_book_current_no: "",
        vo_book_desc: "",
        vo_book_key: "",
        vo_book_start_no: "",
        prefix_str: "",
        voucher_sub_type: "",
        voucher_type: "",
        hide_this_book: false,
      });
      setVoucherBookType(null);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Bill Book Configuration</Title>
      <div className="bill-book-config">
        <div className="bill-book-config__datablock row g-0">
          <div className="col bill-book-config__datablock--frame">
            <div className="label-grid">
              <Label>Bill Book Name</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={voucherBooks.responseType}
                openOnFocus
                freeSolo
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, voucherBookType)
                }
                autoHighlight
                value={voucherBookType}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setVoucherBookType(newValue as responseType);
                    HandleVoucherBook((newValue as responseType).value);
                  } else {
                    setVoucherBookType(null);
                  }
                }}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    {...params}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="label-grid">
              <Label>Description</Label>
              <Input value={voucherBookData.vo_book_desc} />
            </div>
            <div className="bill-book-config__datablock--frame--grids">
              <Label>Voucher Start Number</Label>
              <Input value={voucherBookData.vo_book_start_no} disabled />
              <Label> Current Voucher Number</Label>
              <Input
                value={voucherBookData.vo_book_current_no}
                name="vo_book_current_no"
                onChange={handleValueChange}
              />
            </div>
            <div className="bill-book-config__datablock--form-labels--grids">
              <div>
                <FormGroup className="bill-book-config__datablock--form-labels">
                  <FormControlLabel
                    label="Is Prefixed"
                    control={
                      <AntSwitch
                        checked={voucherBookData?.is_prefixed ? true : false}
                        onClick={() => {
                          const newConfigValue = voucherBookData?.is_prefixed
                            ? false
                            : true;
                          voucherBookType &&
                            setVoucherBookData({
                              ...voucherBookData,
                              prefix_str: "",
                              separator: "",
                              suffix_str: "",
                              is_prefixed: newConfigValue,
                            });
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
              </div>
              {voucherBookData?.is_prefixed && (
                <>
                  <div className="bill-book-config__datablock--form-labels--grids--block">
                    <Label>Prefix</Label>
                    <Input
                      name="prefix_str"
                      value={voucherBookData.prefix_str}
                      onChange={(e) => handleValueChange(e)}
                    />
                  </div>
                  <div className="bill-book-config__datablock--form-labels--grids--block">
                    <Label>Suffix</Label>
                    <Input
                      name="suffix_str"
                      onChange={(e) => handleValueChange(e)}
                    />
                  </div>
                  <div className="bill-book-config__datablock--form-labels--grids--block">
                    <Label>Separator</Label>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={separatorType}
                      openOnFocus
                      forcePopupIcon
                      value={voucherBookData.separator}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setVoucherBookData((prev) => ({
                            ...prev,
                            separator: newValue as SeparatorTypes,
                          }));
                        } else {
                          setVoucherBookData((prev) => ({
                            ...prev,
                            separator: "",
                          }));
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.BACKSPACE) {
                          setVoucherBookData((prev) => ({
                            ...prev,
                            separator: "",
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="bill-book-config__datablock--form-labels--grids">
              <div>
                <FormGroup className="bill-book-config__datablock--form-labels">
                  <FormControlLabel
                    label="Prefill With Zero"
                    control={
                      <AntSwitch
                        checked={
                          voucherBookData.prefill_with_zero ? true : false
                        }
                        onClick={() => {
                          const newConfigValue =
                            voucherBookData?.prefill_with_zero ? false : true;
                          voucherBookType &&
                            setVoucherBookData({
                              ...voucherBookData,
                              prefill_width: 0,
                              prefill_with_zero: newConfigValue,
                            });
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
              </div>
              {voucherBookData.prefill_with_zero && (
                <div>
                  <Label>Prefill Width</Label>
                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={width}
                    openOnFocus
                    forcePopupIcon
                    value={voucherBookData.prefill_width}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setVoucherBookData((prev) => ({
                          ...prev,
                          prefill_width: newValue as number,
                        }));
                      } else {
                        setVoucherBookData((prev) => ({
                          ...prev,
                          prefill_width: 0,
                        }));
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setVoucherBookData((prev) => ({
                          ...prev,
                          prefill_width: 0,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            {/* <div className="bill-book-config__datablock--form-labels--grids">
              {captationFee.data?.GetSwConfigVariables[0]
                .config_boolean_value ? (
                <FormGroup className="bill-book-config__datablock--form-labels">
                  <FormControlLabel
                    label="Hide This Book"
                    control={
                      <AntSwitch
                        checked={voucherBookData.hide_this_book ? true : false}
                        onClick={() => {
                          const newConfigValue = voucherBookData?.hide_this_book
                            ? false
                            : true;
                          voucherBookType &&
                            setVoucherBookData({
                              ...voucherBookData,
                              hide_this_book: newConfigValue,
                            });
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
              ) : null}
            </div> */}
            <div className="label-grid">
              <div></div>
              <Input
                disabled
                value={
                  voucherBookData.prefix_str +
                  voucherBookData.separator +
                  handleZeros(voucherBookData.prefill_width.toString()) +
                  voucherBookData.vo_book_start_no +
                  voucherBookData.separator +
                  voucherBookData.suffix_str
                }
                placeholder="Preview"
              />
            </div>
            <div className="bill-book-config__datablock--frame--grids">
              <Label>Voucher Type</Label>

              <Input
                disabled={voucherBookData.is_book_system_based}
                name="voucher_type"
                value={voucherBookData.voucher_type}
                onChange={handleValueChange}
              />
              <Label>Voucher Sub Type</Label>
              <Input
                value={voucherBookData.voucher_sub_type}
                disabled={voucherBookData.is_book_system_based}
                name="voucher_sub_type"
                onChange={handleValueChange}
              />
            </div>
          </div>
          <div className="col bill-book-config__datablock--frame"></div>
        </div>
        <Button mode="save" onClick={handleVoucherUpdate} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={updationloading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

import {

  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";

import {
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import Close from "../../../../images/Close.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import LeaveDetails from "./Modals/LeaveDetails";
import { labelClasses, LabeledAutocomplete } from "../../../../styles/AutocompleteListStyles";

const { HR_Table } = require("../../json/table.json");

const enum stickyTableHeaders {
  SL = "Sl",
  ID = "Employee ID",
  WORKING_DAYS = "Worked Days",
}
const Leaves = () => {
  const [leaveDetails, setLeaveDetails] = useState(false);

  // const [{ payRollReportName }] = useContext(AppContext);
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <div className="row g-0 payroll-reports__rightblock--details">
        <div className="col-2">
          <Input id="search" placeholder="Search..." type="text" />
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Departments"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
        <div className="col-2">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Type"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
      </div>
      <div className="payroll-reports__rightblock--data">
        <div className="payroll-reports__rightblock--data--title">
          <Title variant="subtitle1"> Staff Management report name</Title>
          <div>
            <TextField
              type="month"
              id="outlined-uncontrolled"
              label="Test End Date"
              size="small"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value="2022-11"
            />
          </div>
        </div>
        <div className="payroll-reports__rightblock--data--table">
          {/* <TableContainer className={stickyClasses.table}>
            <Table>
              <TableHead>
                <TableRow onClick={() => setLeaveDetails(!leaveDetails)}>
                  {HR_Table.SalaryProcess.EmpAttendance.StickyHeaders.filter(
                    (th: TableHeaderProps) =>
                      th.labelName !== stickyTableHeaders.WORKING_DAYS
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell
                        className={
                          th.labelName === stickyTableHeaders.SL
                            ? stickyClasses.stickyHeaderSl
                            : th.labelName === stickyTableHeaders.ID
                            ? stickyClasses.stickyHeaderEmpId
                            : stickyClasses.stickyHeaderName
                        }
                        key={index}
                      >
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                  {HR_Table.Reports.Leaves.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell
                          key={index}
                          className={stickyClasses.stickyHeader}
                        >
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}

                  {HR_Table.SalaryProcess.EmpAttendance.StickyHeaders.filter(
                    (th: TableHeaderProps) =>
                      th.labelName === stickyTableHeaders.WORKING_DAYS
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell
                        className={stickyClasses.stickyHeaderTotal}
                        key={index}
                      >
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className={stickyClasses.stickyColumnSl}
                  >
                    1
                  </TableCell>
                  <TableCell className={stickyClasses.stickyColumnEmpId}>
                    Emp01
                  </TableCell>

                  <TableCell className={stickyClasses.stickyColumnName}>
                    Emp Name
                  </TableCell>

                  <TableCell className="salary-process__emp-attendance--table--desc">
                    staff
                  </TableCell>
                  <TableCell
                    className="salary-process__emp-attendance--table--status"
                    id="td-center"
                  >
                    CUR
                  </TableCell>
                  <TableCell className="salary-process__emp-attendance--table--input">
                    2
                  </TableCell>
                  <TableCell className="salary-process__emp-attendance--table--input">
                    0
                  </TableCell>
                  <TableCell className="salary-process__emp-attendance--table--input">
                    0
                  </TableCell>
                  <TableCell className="salary-process__emp-attendance--table--input">
                    1
                  </TableCell>
                  <TableCell className="salary-process__emp-attendance--table--input">
                    1
                  </TableCell>
                  <TableCell className="salary-process__emp-attendance--table--input">
                    1
                  </TableCell>

                  <TableCell
                    className={stickyClasses.stickyColumnTotal}
                    id="td-right"
                  >
                    25
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={PAGINATION_ARRAY}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    labelRowsPerPage={TABLE_DATA_PER_PAGE}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer> */}
        </div>
      </div>

      {/* leave-entry */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={leaveDetails}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LeaveDetails />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setLeaveDetails(!leaveDetails)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Leaves;

/**
 * React functional component that renders a table of account ledgers and provides the functionality to delete a ledger.
 *
 * @component
 * @example
 * <DeleteStudentLedgers setModal={setModal} />
 *
 * @param {function} setModal - A function to control the visibility of the parent modal component.
 *
 * @returns {JSX.Element} The component renders a UI that allows users to search for account ledgers, view them in a table, and delete individual ledgers.
 * Success or error messages are displayed when a ledger is deleted.
 * The `setModal` function is called to control the visibility of the parent modal component.
 */
import { useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";

import { TableHeaderProps } from "../../../Types/Tables";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import { DeleteAcctLdgrById } from "../queries/Accountingledgers/mutations/Index";
import { GetAcctLdgrs } from "../queries/FeeLedgers/query/Byid";

import DeleteIcon from "../../../images/Delete.svg";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import { Button } from "../../../stories/Button/Button";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeleteStudentLedgers = ({ setModal }: Props) => {
  const { InstId } = useParams();
  const { Accounts_Table } = useAcctTableJson();
  const [searchData, setSearchData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [acctId, setAcctId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();

  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.STD_LDGR_USER_DEFINED,
    null
  );
  const { token } = useToken();

  const [DeleteLedger, { loading }] = useMutation(DeleteAcctLdgrById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteLedger({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctLdgrs,
          variables: {
            token,
            after: null,
            input: {
              acct_ldgr_query_type: AcctLedgerQueryType.ACCT_LDGRS_BY_INST_ID,
              ids: [Number(InstId)],
            },
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            name: searchData,
            sortBy: SortBy.LDGR_DESC,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "FeeLedger Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAcctId(0);
      setModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Title>Delete Fee Ledger</Title>
      <div className="delete-account-ledger">
        <div className="row g-0">
          <div className="col-3">
            <Input
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search Acct Ledgers..."
              id="search"
            />
          </div>
        </div>
        <div className="delete-account-ledger__tableblock">
          {!acctLedgers.data?.GetAcctLdgrs.edges?.length &&
          !acctLedgers.loading ? (
            <b className="nodata">Sorry No Results</b>
          ) : (
            <TableContainer className="delete-account-ledger__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.AccountLedger.Delete.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acctLedgers.data?.GetAcctLdgrs.edges?.map(
                    (response, index: number) => (
                      <TableRow key={response.node.id}>
                        <TableCell
                          id="td-center"
                          className="delete-account-ledger__table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{response.node.ldgr_desc}</TableCell>

                        <TableCell
                          id="td-center"
                          className="delete-account-ledger__table--actions"
                        >
                          <img
                            src={DeleteIcon}
                            onClick={() => {
                              setAcctId(response.node.id);
                              setDeleteModal(!deleteModal);
                            }}
                            alt="/"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="cancel" onClick={() => setModal(false)} />
          </div>
          <div className="col-4 eduate-account-ledger__total">
            <div className="student-total-count">
              Total Ledgers :&nbsp;
              <b>{acctLedgers.data?.GetAcctLdgrs.totalCount!}</b>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={acctId}
      />
    </>
  );
};

export default DeleteStudentLedgers;

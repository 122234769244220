import { gql } from "@apollo/client";

export const AddPayRollGrade = gql`
  mutation AddPayRollGrade(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreatePayRollGradeInput!
  ) {
    AddPayRollGrade(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const DeletePayRollGradeById = gql`
  mutation DeletePayRollGradeById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeletePayRollGradeById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdatePayRollGrade = gql`
  mutation UpdatePayRollGrade(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollGradeInput!
  ) {
    UpdatePayRollGrade(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

import { gql } from "@apollo/client";

export const AddLibBookMasterAndPurchaseDetails = gql`
  mutation AddLibBookMasterAndPurchaseDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: AddBookMasterAndPurchaseDetails!
  ) {
    AddLibBookMasterAndPurchaseDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

import { Drawer, styled } from "@mui/material";
export const StudentDetailsDrawer = styled(Drawer)(({ theme }) => ({
  width: "25%",

  "& .MuiDrawer-paper": {
    top: "auto !important",
    bottom: " 0px !important",
    height: "fit-content !important",
    width: "25%",
    borderRadius: "6px 0px",
  },
}));
export const AttendanceDrawer = styled(Drawer)(({ theme }) => ({
  width: "25%",
  "& .MuiDrawer-paper": {
    bottom: "0px !important",
    width: "40%",
    borderRadius: "6px 0px",
    padding: 10,
    height: "100% !important",
  },
}));
export const PerStudentAttDrawer = styled(Drawer)(({ theme }) => ({
  width: "25%",
  "& .MuiDrawer-paper": {
    bottom: "0px !important",
    width: "60%",
    borderRadius: "6px 0px",
    padding: 10,
    height: "100% !important",
  },
}));
export const CampusEntryDrawer = styled(Drawer)(({ theme }) => ({
  width: "25%",

  "& .MuiDrawer-paper": {
    top: "110px !important",
    bottom: "0px !important",
    width: "40%",
    borderRadius: "6px 0px",
    padding: "10px !important",
    height: "calc(100% - 110px) !important",
  },
}));
export const AdjustFeeDrawer = styled(Drawer)(({theme}) => ({
    width: "25%",
    "& .MuiDrawer-paper":{
    top: "110px !important",
    bottom: "0px !important",
    width: "60%",
    borderRadius: "6px 0px",
    padding: "20px",
    height: "calc(100% - 110px) !important",
  },
}));
export const TemplateViewDrawer = styled(Drawer)(({theme}) => ({
    width: "25%",
  
    "& .MuiDrawer-paper": {
    top: "110px !important",
    bottom: "0px !important",
    width: "35%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    padding: 10,
  },
}));
export const HolidaysListDrawer = styled(Drawer)(({theme}) => ({
    width: "25%",
  
    "& .MuiDrawer-paper":  {
    top: "110px !important",
    bottom: "0px !important",
    width: "35%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    padding: 15,
    zIndex: "900 !important",
    backgroundColor: "none !important",
  },
}));
export const SectionAllocationDrawer = styled(Drawer)(({theme}) => ({
    width: "25%",
    "& .MuiDrawer-paper": {
    top: "110px !important",
    bottom: "0px !important",
    width: "60%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    zIndex: "100 !important",
  },
}));
export const EmpAttendanceDrawer = styled(Drawer)(({theme}) => ({
    width: "25%",
    height: "fit-content",
    "& .MuiDrawer-paper": {
    top: "auto !important",
    bottom: "0px !important",
    width: "30% !important",
    height: "fit-content !important",
    borderRadius: "6px 0px !important",
    padding: 10,
  },
}));

export const AddOrganizationalDrawer = styled(Drawer)(({theme}) => ({
    width: "30%",
    "& .MuiDrawer-paper": {
    top: "auto !important",
    bottom: " 0px !important",
    height: "calc(100% - 110px) !important",
    width: "35%",
    borderRadius: "6px 0px",
  },
}));
export const AllocateTeachersDrawer = styled(Drawer)(({theme}) => ({
    width: "50%",
  "& .MuiDrawer-paper": {
    top: "auto !important",
    bottom: " 0px !important",
    height: "calc(100% - 110px) !important",
    width: "50%",
    borderRadius: "6px 0px",
    padding: 10,
  },
}));

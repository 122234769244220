import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import Template from "./Template";

const Template1 = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>Print Report Cards</Title>
      </div>
      <div className="bgs-tenth-pr">
        <div className="bgs-tenth-pr__block" ref={componentRef}>
          <style type="text/css" media="print" scoped>
            {`
                  
                  body {
  
                    height: 100% tial !important;
                         overflow: initial !important;
                         -webkit-print-color-adjust: exact;
                     
                     }
              @page {
          size: A4 landscape; 
          width:100%;
          height:100%;
         
              }
          .pr-temp1-fs{
            padding:20px;
  
            height:100% !important;
  
          }  
          .pr-temp1-fs__left{
  
            height:100% !important;
  
          }  
          .pr-temp1-fs__right{
  
            height:100% !important;
  
          }  
  
          .pr-temp1-bs{
            padding:20px;
  
            height:100% !important;
  
          }  
   
  
            `}
          </style>
          <div className="h-100">
            <Template />
          </div>
        </div>
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
    </>
  );
};

export default Template1;

import React from "react";
import { Step, StepLabel, Stepper, TextField } from "@mui/material";
import { Operation } from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import { LabelNameProps } from "../../../../Types/Labels";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";
import Add from "../../../../images/Add.svg";
import { AntSwitch } from "../../../../pages/Switch";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
const { VMS_Form } = require("../../../json/form.json");

interface Props {
  operation: Operation;
  step: number;
}

const stepHeader = () => {
  return ["Staff Details", "Staff Documents"];
};
const Index = ({ step, operation }: Props) => {
  const stepsHeader = stepHeader();
  const navigate = useNavigate();

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="vms-staff-details-new">
        <div className="vms-staff-details-new__stepper">
          {operation === Operation.CREATE && (
            <Stepper alternativeLabel activeStep={step - 1}>
              {stepsHeader.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </div>
        <Title>Staff Details</Title>
        <div
          className={
            operation === Operation.UPDATE
              ? "vms-staff-details-new__form--update row g-0"
              : "vms-staff-details-new__form row g-0"
          }
        >
          <div className="col">
            {VMS_Form.Staff_Details.BasicDetails.map(
              (label: LabelNameProps, index: React.Key) => {
                return (
                  <>
                    <div className="label-grid" key={index}>
                      <Label>{label.LabelName}</Label>
                      <Input />
                    </div>
                  </>
                );
              }
            )}
            <div className="vms-staff-details-new__form--grid">
              <Label>Designation</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Bus"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-staff-details-new__form--image"
                  alt="/"
                />
              </CustomTooltip>
            </div>
            <div className="label-grid">
              <Label>Work Experience</Label>
              <Input />
            </div>
            <div className="vms-staff-details-new__form--date-grid">
              <Label>Joined Date</Label>
              <Input type="date" />
              <Label>End Date</Label>
              <Input type="date" />
            </div>
          </div>
          <div className="col">
            <div className="label-grid">
              <Label>Aadhar No.</Label>
              <Input />
              <Label>PAN No.</Label>
              <Input />
              <Label>Driving License No.</Label>
              <Input />
            </div>
            <div className="vms-staff-details-new__form--date-grid">
              <Label>License Start Date</Label>
              <Input type="date" />
              <Label>License End Date</Label>
              <Input type="date" />
            </div>
            <div className="vms-staff-details-new__form--grid">
              <Label>License Type</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Bus"]}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <CustomTooltip
                title="Add New"
                arrow
                placement="left"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: TOOLTIP_COLORS.VARIABLE,
                      "& .MuiTooltip-arrow": {
                        color: TOOLTIP_COLORS.VARIABLE,
                      },
                    },
                  },
                }}
              >
                <img
                  src={Add}
                  className="vms-staff-details-new__form--image"
                  alt="/"
                />
              </CustomTooltip>
            </div>
            <div className="label-grid">
              <Label>Employee is Driver</Label>
              <AntSwitch />
            </div>
            <div className="label-grid">
              <Label>Employee Can Drive</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={["Bus", "Lorry"]}
                forcePopupIcon
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <Label>Employee Photo</Label>
          </div>
        </div>
        {operation === Operation.CREATE ? (
          <Button
            mode="save-continue"
            onClick={() => navigate(`/vms/staffdetails/new/staffdocuments`)}
          />
        ) : (
          <Button mode="save" />
        )}
        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;

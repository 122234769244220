import { PayRollMasters } from "../../enums/Enum.types";

export const getMasterDetails = (
  MasterType: PayRollMasters,
  records?: any[]
) => {
  switch (MasterType) {
    case PayRollMasters.DEPARTMENT:
      return {
        header: {
          Headers: "Department",
          key: "Department",
          width: 25,
        },
        schema: {
          Department: {
            name: "desc",
          },
        },
        sheetname: "HrDepartmentInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Department,
          })),
      };
    case PayRollMasters.CATEGORY:
      return {
        header: {
          Headers: "Category",
          key: "Category",
          width: 25,
        },
        schema: {
          Category: {
            name: "desc",
          },
        },
        sheetname: "HrCategoryInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Category,
          })),
      };
    case PayRollMasters.DESIGNATION:
      return {
        header: {
          Headers: "Designation",
          key: "Designation",
          width: 25,
        },
        schema: {
          Designation: {
            name: "desc",
          },
        },
        sheetname: "HrDesignationInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Designation,
          })),
      };
    case PayRollMasters.GRADE:
      return {
        header: {
          Headers: "Grade",
          key: "Grade",
          width: 25,
        },
        schema: {
          Grade: {
            name: "desc",
          },
        },
        sheetname: "HrGradeInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.Grade,
          })),
      };
    case PayRollMasters.JOB_TYPE:
      return {
        header: {
          Headers: "JobType",
          key: "JobType",
          width: 25,
        },
        schema: {
          JobType: {
            name: "desc",
          },
        },
        sheetname: "HrJobTypeInfo",
        errorData: records
          ?.filter((record) => record?.errors)
          .map((f) => ({
            desc: f.JobType,
          })),
      };
    default:
      break;
  }
};

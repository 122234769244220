import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Home from "../Home/Index";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../components/common/Input/Index";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { FineSlabTitleProps } from "../../../Types/Titles";

import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { Operation } from "../../../utils/Enum.types";
import { Form, Formik } from "formik";
import { LibraryFineSlab } from "../../../utils/validationRules";
import { useLazyQuery, useMutation } from "@apollo/client";

import LoadingModal from "../../../pages/LoadingModal";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { msgType, optionsType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";

import DeleteModal from "../../../pages/DeleteModal";

import { EMPTY_STRING } from "../../../utils/constants";
import {
  AddAcctFineSlab,
  DeleteAcctFineSlabById,
  UpdateAcctFineSlab,
} from "../queries/Fines/mutation";
import {
  GetAcctFineSlabById,
  GetAcctFineSlabByInstId,
} from "../queries/Fines/query";
import {
  GetAcctFineSlabByInstIdData,
  GetAcctFineSlabByInstIdVars,
  GetFineSlabByIdData,
  GetFineSlabByIdVars,
} from "../hooks/useFineSlab";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import Fine from "../../../images/CashReceipts.svg";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";

const { AccountsTitles } = require("../json/title.json");
interface fineSlabType {
  fromDay: number;
  toDay: number;
  amtPerDay: number;
}

export enum FineType {
  PERCENTAGE = "PERCENTAGE",
  PER_DAY = "PER_DAY",
  FLAT = "FLAT",
}

const fineTypeOptions = [
  {
    label: "Percentage wise",
    value: FineType.PERCENTAGE,
  },
  {
    label: "Perday wise",
    value: FineType.PER_DAY,
  },
  {
    label: "Flat",
    value: FineType.FLAT,
  },
];
const Index = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const [fineTitle, setFineTitle] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [fineId, setFineId] = useState(0);
  const [fineType, setFineType] = useState<optionsType>({
    label: "Perday wise",
    value: FineType.PER_DAY,
  });

  const [fineSlabArray, setFineSlabArray] = useState<fineSlabType[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //Modals flag
  const [deleteModal, setDeleteModal] = useState(false);

  //Queries
  const [GetFineSlabData, { data, error }] = useLazyQuery<
    GetAcctFineSlabByInstIdData,
    GetAcctFineSlabByInstIdVars
  >(GetAcctFineSlabByInstId, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  const { user_details } = useLoggedInUserDetails();

  const [GetFineSlabById, { data: FineSlabData, loading: FineSlabLoading }] =
    useLazyQuery<GetFineSlabByIdData, GetFineSlabByIdVars>(GetAcctFineSlabById);

  //Mutations
  const [AddAcctFine, { loading: creationLoading }] = useMutation(
    AddAcctFineSlab,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateAcctFine, { loading: updationLoading }] = useMutation(
    UpdateAcctFineSlab,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteAcctFine, { loading: deletionLoading }] = useMutation(
    DeleteAcctFineSlabById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const formLabels = () => {
    return [...new Array(5).keys()].map((i) => {
      return {
        label: "From Day",
        label2: "To Day",
        label3: `Amount ${fineType.value === FineType.PER_DAY ? "/ Day" : ""}`,
        id: (Math.random() + 1).toString(),
      };
    });
  };
  const fineSlabData = () => {
    return [...new Array(5).keys()].map((i, index) => {
      if (index === 0) {
        return {
          fromDay: 1,
          toDay: 0,
          amtPerDay: 0,
        };
      }
      return {
        fromDay: 0,
        toDay: 0,
        amtPerDay: 0,
      };
    });
  };
  const arr = [...formLabels()];
  const handleClear = () => {
    setFineTitle("");
    setFineSlabArray([...fineSlabData()]);
    setOperation(Operation.CREATE);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      //enableAdd and enableEdit should stay false when you close the success modal
      setOperation(Operation.CREATE);
      handleClear();
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const HandleSubmit = () => {
    const input = {
      acct_fine_desc: fineType.label,
      acct_fine_type: fineType.value,
      acct_fine_slabs: fineSlabArray?.map(({ fromDay, toDay, amtPerDay }) => ({
        acct_slab_from: Number(fromDay),
        acct_slab_to: Number(toDay),
        acct_slab_fine: Number(amtPerDay),
      })),
    };

    if (operation === Operation.CREATE) {
      AddAcctFine({
        variables: {
          token,
          inst_id: InstId!,
          input,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcctFineSlabByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Fineslab Added successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      UpdateAcctFine({
        variables: {
          token,
          id: fineId,
          input,
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcctFineSlabByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Fineslab Updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };

  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteAcctFine({
      variables: {
        token,
        id,
        user_details,
        inst_id: InstId,
      },
      refetchQueries: [
        {
          query: GetAcctFineSlabByInstId,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Fineslab Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };
  useEffect(
    () => {
      if (fineSlabData().length > 0 && operation === Operation.CREATE) {
        setFineSlabArray([...fineSlabData()]);
      }
    },
    // eslint-disable-next-line
    [fineSlabData().length, operation]
  );
  useEffect(
    () => {
      if (FineSlabData && !FineSlabLoading && operation === Operation.UPDATE) {
        const {
          acct_fine_desc,
          acct_slab1_from,
          acct_slab1_to,
          acct_slab1_fine,
          acct_slab2_from,
          acct_slab2_to,
          acct_slab2_fine,
          acct_slab3_from,
          acct_slab3_to,
          acct_slab3_fine,
          acct_slab4_from,
          acct_slab4_to,
          acct_slab4_fine,
          acct_slab5_from,
          acct_slab5_to,
          acct_slab5_fine,
        } = FineSlabData.node || EMPTY_STRING;
        setFineTitle(acct_fine_desc);
        const item1 = {
          fromDay: acct_slab1_from,
          toDay: acct_slab1_to,
          amtPerDay: acct_slab1_fine,
        };
        fineSlabArray[0] = item1;
        const item2 = {
          fromDay: acct_slab2_from,
          toDay: acct_slab2_to,
          amtPerDay: acct_slab2_fine,
        };
        fineSlabArray[1] = item2;
        const item3 = {
          fromDay: acct_slab3_from,
          toDay: acct_slab3_to,
          amtPerDay: acct_slab3_fine,
        };
        fineSlabArray[2] = item3;
        const item4 = {
          fromDay: acct_slab4_from,
          toDay: acct_slab4_to,
          amtPerDay: acct_slab4_fine,
        };
        fineSlabArray[3] = item4;
        const item5 = {
          fromDay: acct_slab5_from,
          toDay: acct_slab5_to,
          amtPerDay: acct_slab5_fine,
        };
        fineSlabArray[4] = item5;
        setFineSlabArray([...fineSlabArray]);
      }
    },
    // eslint-disable-next-line
    [FineSlabData, FineSlabLoading, operation]
  );
  useEffect(() => {
    if (token && !deletionLoading) {
      GetFineSlabData();
    }
  }, [token, GetFineSlabData, InstId, deletionLoading, message.flag]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.Fines.Titles.map(
          (title: FineSlabTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.AccountsFineSlab}
              </React.Fragment>
            );
          }
        )}
      </Title>

      <Formik
        initialValues={{ fineTitle }}
        onSubmit={HandleSubmit}
        validationSchema={LibraryFineSlab}
        validateOnChange
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="fine-slab">
              <div className="row g-0 fine-slab__details">
                <div className="col h-100 booktype-left">
                  <div className="fine-slab__details--title">
                    <Title variant="subtitle1">
                      {AccountsTitles.Fines.Titles.map(
                        (title: FineSlabTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>

                  <div className="fine-slab__frame">
                    <div className="row g-0 fine-slab__frame--autocomplete">
                      {data &&
                      data.GetAcctFineSlabByInstId &&
                      operation !== Operation.UPDATE ? (
                        <div className="fine-slab__frame--fine col-4">
                          <img src={Fine} alt="" />{" "}
                          <b>{data.GetAcctFineSlabByInstId.acct_fine_desc}</b>
                        </div>
                      ) : (
                        <div className="col-4">
                          <LabeledAutocomplete
                            className={labelClasses.inputRoot}
                            options={fineTypeOptions}
                            openOnFocus
                            onChange={(e, newValue) => {
                              setFineType(newValue as optionsType);
                            }}
                            value={fineType}
                            forcePopupIcon
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                className={labelClasses.formControlRoot}
                                label="Fine type"
                                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>

                    <div className="fine-slab__frame--block">
                      {arr.map((row, index) => (
                        <div className="fine-slab__formlabels" key={index}>
                          <div className="fine-slab__formlabels--columns">
                            <Label>{index + 1})</Label>
                          </div>
                          <div className="fine-slab__formlabels--columns">
                            <Label>{row.label}</Label>
                            <Input
                              type="number"
                              name={"formDay" + index}
                              disabled={index > 0 ? true : false}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                fineSlabArray[index].fromDay = Number(
                                  e.target.value
                                );
                                setFineSlabArray([...fineSlabArray]);
                              }}
                              onFocus={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => e.target.select()}
                              values={
                                fineSlabArray[index]?.fromDay ?? EMPTY_STRING
                              }
                            />
                          </div>
                          <div className="fine-slab__formlabels--columns">
                            <Label>{row.label2}</Label>
                            <Input
                              name={"toDay" + index}
                              type="number"
                              disabled={operation ? false : true}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                fineSlabArray[index].toDay = Number(
                                  e.target.value
                                );
                                if (fineSlabArray[index + 1]) {
                                  fineSlabArray[index + 1].fromDay =
                                    fineSlabArray[index].toDay + 1;
                                }
                                setFineSlabArray([...fineSlabArray]);
                              }}
                              onFocus={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => e.target.select()}
                              values={
                                fineSlabArray[index]?.toDay ?? EMPTY_STRING
                              }
                            />
                          </div>
                          <div className="fine-slab__formlabels--columns--amountperday">
                            <Label>{row.label3}</Label>
                            <Input
                              name={"amtPerDay" + index}
                              type="number"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                fineSlabArray[index].amtPerDay = Number(
                                  e.target.value
                                );
                                setFineSlabArray([...fineSlabArray]);
                              }}
                              onFocus={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => e.target.select()}
                              values={
                                fineSlabArray[index]?.amtPerDay ?? EMPTY_STRING
                              }
                              disabled={operation ? false : true}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col h-100 booktype-right">
                  <div className="fine-slab__details--title">
                    <Title variant="subtitle1">
                      {AccountsTitles.Fines.Titles.map(
                        (title: FineSlabTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.List}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="fine-slab__frame--tableblock">
                    {error ? (
                      <b className="nodata">{error.message}</b>
                    ) : data && data?.GetAcctFineSlabByInstId ? (
                      <TableContainer className="fine-slab__frame--table">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {Accounts_Table.Fines.Table_Headers.map(
                                (th: TableHeaderProps, index: React.Key) => {
                                  return (
                                    <TableCell
                                      key={index}
                                      className={th.className}
                                    >
                                      {th.labelName}
                                    </TableCell>
                                  );
                                }
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                id="td-center"
                                className="fine-slab__frame--table--slno"
                              >
                                1
                              </TableCell>
                              <TableCell>
                                {data?.GetAcctFineSlabByInstId.acct_fine_desc}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="fine-slab__frame--table--actions"
                              >
                                <img
                                  src={EditProfile}
                                  alt="/"
                                  onClick={() => {
                                    setOperation(Operation.UPDATE);
                                    setFineId(data?.GetAcctFineSlabByInstId.id);
                                    GetFineSlabById({
                                      variables: {
                                        token,
                                        id: data?.GetAcctFineSlabByInstId.id,
                                      },
                                    });
                                  }}
                                />
                                &nbsp;
                                <img
                                  src={Delete}
                                  alt="/"
                                  onClick={() => {
                                    setFineId(data?.GetAcctFineSlabByInstId.id);
                                    setDeleteModal(!deleteModal);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <b className="nodata"> Sorry, no results</b>
                    )}
                  </div>
                </div>
              </div>
              <div className="button-left">
                <Button mode="save" type="submit" />
                <Button
                  mode="clear"
                  type="button"
                  onClick={() => {
                    handleClear();
                  }}
                />

                <Button mode="back" onClick={() => navigate(-1)} />
              </div>
            </Form>
          );
        }}
      </Formik>
      <LoadingModal
        flag={creationLoading || updationLoading || deletionLoading}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={fineId}
      />
    </>
  );
};

export default Index;

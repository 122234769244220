import React, { useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";

import EditProfile from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";

import Input from "../../../../stories/Input/Input";
import InputHoc from "../../../../components/common/Input/Index";

import { LibraryLabelNameProps } from "../../../../Types/Labels";
import { Button } from "../../../../stories/Button/Button";
import { LibraryTitleProps } from "../../../../Types/Titles";
import { KeywordValidation } from "../../../../utils/validationRules";
import { Form, Formik } from "formik";

import { useNavigate, useParams } from "react-router-dom";
import {
  CURRENT_PAGE,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  Direction,
  Operation,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";
import { KeywordType, msgType } from "../../../../utils/Form.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ByNode as KeywordById } from "../../../../queries/Library/Node";
import {
  getModifiedScrollHeight,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import LoadingModal from "../../../../pages/LoadingModal";
import DeleteModal from "../../../../pages/DeleteModal";
import MessageModal from "../../../../pages/MessageModal";
import {
  GetLibKeywordsByInstIdData,
  GetLibKeywordsByInstIdVars,
  KeywordsEdge,
  KeywordsNode,
} from "../../../../Types/Library/MasterData/Keyword/paginationTypes";
import { GetLibKeywordsByInstId } from "../../../../queries/Library/MasterData/Keyword/list/byInstId";
import { AddLibKeywords } from "../../../../queries/Library/MasterData/Keyword/mutation/new";
import { UpdateLibKeywords } from "../../../../queries/Library/MasterData/Keyword/mutation/update";
import { DeleteLibKeywordsById } from "../../../../queries/Library/MasterData/Keyword/mutation/delete";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT} from "../../../../styles/DataGridTableStyles";
import {
  
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";

const { Library } = require("../../../../json/title.json");
export interface KeywordByIdData {
  nodes: KeywordsNode[];
}
export interface KeywordByIVars {
  token: string;
  ids: number[] | number;
}
const Index = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const { LibraryFormLabels } = useLibraryformJson();
  const { Library_Table } = useLibraryTableJson();
  const { user_details } = useLoggedInUserDetails();
  const [operation, setOperation] = useState(Operation.CREATE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy] = useState(SortBy.KEYWORD);
  const [direction] = useState(Direction.ASC);
  const [searchData, setSearchData] = useState("");
  const [keywordId, setKeywordId] = useState(0);
  const nameRef = document.getElementsByName("keyword")[0] as HTMLInputElement;
  const [keywordList, setKeywordList] = useState<KeywordsEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [formData, setFormData] = useState<KeywordType>({
    keyword: "",
  });
  //Queries
  const [GetKeywordData, { data, error, fetchMore }] = useLazyQuery<
    GetLibKeywordsByInstIdData,
    GetLibKeywordsByInstIdVars
  >(GetLibKeywordsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: ROWS_PER_PAGE,
      sortBy,
      direction,
      name: searchData,
      after: null,
    },
  });

  const [GetKeyword, { data: KeywordByIdData, loading: KeywordByIdLoading }] =
    useLazyQuery<KeywordByIdData, KeywordByIVars>(KeywordById);

  const totalCount = data?.GetLibKeywordsByInstId?.totalCount;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = data
    ? data.GetLibKeywordsByInstId.edges.slice(indexOfFirstrepo, indexOfLastrepo)
    : [];

  //Mutations
  const [AddKeyword, { loading: creationLoading }] = useMutation(
    AddLibKeywords,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateKeyword, { loading: updationLoading }] = useMutation(
    UpdateLibKeywords,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteKeyword, { loading: deleteLoading }] = useMutation(
    DeleteLibKeywordsById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //Methods
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };

  const HandleSubmit = () => {
    const input = {
      keyword: removeMoreSpace(formData.keyword),
    };
    if (operation === Operation.CREATE) {
      AddKeyword({
        variables: {
          token,
          inst_id: InstId,
          user_details,

          input,
        },
        refetchQueries: [
          {
            query: GetLibKeywordsByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              name: "",
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Vendor Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateKeyword({
        variables: {
          token,
          id: keywordId,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibKeywordsByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              name: "",
              after: null,
            },
          },
          {
            query: KeywordById,
            variables: {
              token,
              ids: keywordId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data)
          setMessage({
            flag: !message.flag,
            message: "Vendor Updated Successfully",
            operation: Operation.UPDATE,
          });
      });
    }
  };

  const HandleDelete = (id: number) => {
    DeleteKeyword({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibKeywordsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            sortBy,
            direction,
            name: "",
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Vendor Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClear = () => {
    setFormData({
      keyword: "",
    });
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
      //incase if user clicks back the modal will open which should not happen and if he clicks the back in message it should close
    }
    setMessage({
      flag: !message.flag,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      KeywordByIdData &&
      !KeywordByIdLoading &&
      operation === Operation.UPDATE
    ) {
      const { keyword } = KeywordByIdData.nodes[0];
      setFormData({
        keyword,
      });
    }
  }, [KeywordByIdData, KeywordByIdLoading, operation]);
  useEffect(() => {
    if (token) {
      GetKeywordData();
    }
  }, [token, GetKeywordData]);

  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Keyword.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      headerName: TableHeaders.ACTION,
      renderCell: (params: GridRenderCellParams) => {
        const keywordId = params.row.keyword_id;

        return (
          <>
            <img
              src={EditProfile}
              alt="/"
              onClick={() => {
                GetKeyword({
                  variables: {
                    token,
                    ids: keywordId,
                  },
                }).then(({ data }) => {
                  //enableSave should always stay true if edit is clicked
                  if (data) {
                    setKeywordId(keywordId);
                    setOperation(Operation.UPDATE);
                  }
                });
              }}
            />
            &nbsp;
            <img
              src={Delete}
              alt="/"
              onClick={() => {
                setKeywordId(keywordId);
                setOperation(Operation.DELETE);
                setDeleteModal(!deleteModal);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !KeywordByIdLoading) {
      const newData = data.GetLibKeywordsByInstId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newMedia) => {
          const filteredStudent = currentRepo.find(
            (media) => media.node.id === newMedia.node.id
          );
          if (filteredStudent) {
            return {
              ...newMedia,
              node: {
                ...newMedia.node,
              },
            };
          }
          return newMedia;
        });
        setKeywordList(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            keyword: node.keyword,

            keyword_id: node.id,
          }))
        );
      } else {
        setKeywordList(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            keyword: node.keyword,

            keyword_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetLibKeywordsByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, KeywordByIdLoading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !KeywordByIdLoading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetLibKeywordsByInstId.edges;
              const pageInfo = fetchMoreResult.GetLibKeywordsByInstId.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck =
                prevResult.GetLibKeywordsByInstId.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetLibKeywordsByInstId: {
                  edges: [...keywordList, ...newEdges],
                  pageInfo,
                  totalCount: data
                    ? data.GetLibKeywordsByInstId.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [rows]);
  if (error) return <>{error}</>;
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Library.Titles.Keyword.map(
          (title: LibraryTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Keyword}</React.Fragment>;
          }
        )}
      </Title>
      <Formik
        initialValues={formData}
        validationSchema={KeywordValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="library__frame">
              <div className="library__frame--row">
                <div className="h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">
                      {Library.Titles.Keyword.map(
                        (title: LibraryTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="library__frame--block">
                    {LibraryFormLabels.Keyword.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              inputref={nameRef!}
                              values={formData[label.inputName]}
                              type={label.dataType}
                              required={label.required}
                              autoFocus
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="h-100 booktype-right">
                  <div className="row g-0 library__frame--title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">
                        {Library.Titles.Keyword.map(
                          (title: LibraryTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {title.List}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                    </div>
                    <div className="col-3">
                      <Input
                        id="search"
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchData(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`library__frame--block`}>
                    {!currentRepo?.length ? (
                      <b className="nodata">Sorry, no results.</b>
                    ) : (
                      <>
                        <StyledDatagrid
                          columns={columns}
                          rows={rows}
                          rowHeight={TABLE_ROW_HEIGHT}
                          disableRowSelectionOnClick
                          hideFooter
                      
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                <Button mode="save" type="submit" />
                <Button mode="clear" type="button" onClick={handleClear} />

                <Button
                  mode="back"
                  type="button"
                  onClick={() => navigate(-1)}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deleteLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={keywordId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

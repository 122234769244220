import { gql } from "@apollo/client";

export const GetAcdSubjectCombos = gql`
  query GetAcdSubjectCombos(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: AcdSubjectComboOrder
    $query_type: AcdSubjComboQueryType!
    $entry_id: ID!
    $entry_level: String!
    $name: String!
  ) {
    GetAcdSubjectCombos(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      after: $after
      first: $first
      orderBy: $orderBy
      query_type: $query_type
      entry_id: $entry_id
      entry_level: $entry_level
      where: { or: [{ subjComboNameContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          subj_combo_name
          subj_combo_details {
            id
            acd_subj_combo_id
            acd_subj_cat_id
            category_details {
              id
              subj_cat_name
              subj_cat_details {
                subject_details {
                  id
                  subj_code
                  subj_desc
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GetComboByNodeId = gql`
  query GetComboByNodeId($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcdSubjectCombo {
        id
        subj_combo_name
        subj_combo_details {
          category_details {
            id
            subj_cat_name
            subj_cat_details {
              id
              subject_details {
                id
                subj_code
                subj_desc
              }
            }
          }
        }
      }
    }
  }
`;

export const GetAcdSubjectComboSubjOrder = gql`
  query GetAcdSubjectComboSubjOrder(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_subj_combo_id: ID!
  ) {
    GetAcdSubjectComboSubjOrder(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_subj_combo_id: $acd_subj_combo_id
    ) {
      id
      subject_details {
        id
        subj_code
        subj_desc
      }
    }
  }
`;

export const GetAcdSubjectComboStdAssociAtAllotedLevel = gql`
  query GetAcdSubjectComboStdAssociAtAllotedLevel(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $alloted_level: String!
    $allotted_id: ID!
  ) {
    GetAcdSubjectComboStdAssociAtAllotedLevel(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      alloted_level: $alloted_level
      allotted_id: $allotted_id
    ) {
      acd_subj_combo_id
      subj_combo_name
      student_count
      subject_count
    }
  }
`;

export const CheckPerStdSubjAllocAtEntryLevel = gql`
  query CheckPerStdSubjAllocAtEntryLevel(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $entry_level: String!
    $entry_id: ID!
  ) {
    CheckPerStdSubjAllocAtEntryLevel(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      entry_level: $entry_level
      entry_id: $entry_id
    )
  }
`;

export const GetAcdSubjectAllotedToStudent = gql`
  query GetAcdSubjectAllotedToStudent(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $student_id: ID!
    $allotted_level: String!
    $allotted_id: ID!
    $per_std_subj_allocation: Boolean!
    $form_name_id: ID!
  ) {
    GetAcdSubjectAllotedToStudent(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      student_id: $student_id
      allotted_level: $allotted_level
      allotted_id: $allotted_id
      per_std_subj_allocation: $per_std_subj_allocation
      form_name_id: $form_name_id
    ) {
      subj_master_id
      subj_code
      subj_desc
      subj_board_code
      elective_subj_master_id
      elective_subj_code
      elective_subj_desc
      subj_is_elective
      subj_is_core_subject
      subj_is_lang
      subj_is_lab
      subj_is_non_academic
      is_lessons_planned
      form_filled_by_std
      Teachers {
        pr_emp_id
        emp_id
        emp_title
        emp_first_name
        emp_middle_name
        emp_last_name
        emp_profile_filename
        is_class_teacher
      }
    }
  }
`;

export const GetComboDetails = gql`
  query GetComboDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $query_type: AcdSubjComboQueryType!
    $entry_id: ID!
    $acd_test_class_id: ID!
    $entry_level: String!
  ) {
    GetComboDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      query_type: $query_type
      entry_id: $entry_id
      acd_test_class_id: $acd_test_class_id
      entry_level: $entry_level
    ) {
      id
      subj_combo_name
      subject_count
      std_count
    }
  }
`;

export const GetAcdComboSubjectOrderWithMarksDetails = gql`
  query GetAcdComboSubjectOrderWithMarksDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_test_class_id: ID!
    $acd_subj_combo_id: ID!
  ) {
    GetAcdComboSubjectOrderWithMarksDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_test_class_id: $acd_test_class_id
      acd_subj_combo_id: $acd_subj_combo_id
    ) {
      subj_code
      subj_desc
      subj_sl
      subj_master_id
      subj_total_max_marks
      subj_total_min_marks
    }
  }
`;

import { useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import useAcctLedgerData, { FeeLedgerEdge } from "../hooks/useAcctLedgerData";
import useToken from "../../../customhooks/useToken";

import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { UpdateAcctLdgrIndex } from "../queries/Accountingledgers/mutations/Index";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { LedgerTitles } from "../../../Types/Titles";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";

import { reOrderProcess } from "../../../utils/UtilFunctions";
import { useParams } from "react-router-dom";
import { GetAcctLdgrs } from "../queries/FeeLedgers/query/Byid";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";

const { AccountsTitles } = require("../json/title.json");

interface Props {
  setReOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ReorderFeeLedger = ({ setReOrderModal }: Props) => {
  const { token } = useToken();
  const { Accounts_Table } = useAcctTableJson();
  const [searchData, setSearchData] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const [localItems, setLocalItems] = useState<FeeLedgerEdge[]>([]);
  const [item, setitems] = useState<FeeLedgerEdge[]>([]);
  const [order, setorder] = useState(SortBy.INDEX);
  const { user_details } = useLoggedInUserDetails();

  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
    ROWS_PER_PAGE,
    false,
    order
  );

  const [updateacctOrder, { loading: updationLoading }] = useMutation(
    UpdateAcctLdgrIndex,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { InstId } = useParams();

  useEffect(() => {
    setLocalItems(acctLedgers.data?.GetAcctLdgrs.edges!);

    if (order === SortBy.LDGR_DESC) {
      setLocalItems(() => {
        const temp = acctLedgers.data?.GetAcctLdgrs.edges!;
        setitems(temp);
        return temp;
      });
    }
  }, [acctLedgers.data, order]);
  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: FeeLedgerEdge[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };

  const HandleSaveDragAndDrop = () => {
    // eslint-disable-next-line
    item?.map((element, index: number) => {
      if (localItems[index].node.ldgr_desc === element.node.ldgr_desc) {
        updateacctOrder({
          variables: {
            token,
            input: {
              id: item[index].node.id,
              ldgr_index_position: index + 1,
            },
            inst_id: InstId,
            user_details,
          },
          refetchQueries: [
            {
              query: GetAcctLdgrs,
              variables: {
                token,
                after: null,
                input: {
                  acct_ldgr_query_type: AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
                  ids: [InstId!],
                },
                direction: Direction.ASC,
                sortBy: SortBy.LDGR_DESC,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Fee Ledgers Re-Ordered Successfully",
              flag: true,
              operation: Operation.UPDATE,
            });
          }
        });
      }
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    setReOrderModal?.(false);
  };

  return (
    <>
      <Title>
        {AccountsTitles.FeeLedger.map(
          (title: LedgerTitles, index: React.Key) => {
            return <React.Fragment key={index}>{title.REORDER}</React.Fragment>;
          }
        )}
      </Title>
      <div className="row g-0 ">
        <div className="col-3">
          <Input
            id="search"
            type="text"
            placeholder="Search Fee Ledgers ..."
            value={searchData}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="reorder-feeledger__tableblock">
        <TableContainer className="reorder-feeledger__tableblock--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {localItems?.length > 1 && <TableCell></TableCell>}
                {Accounts_Table.AccountLedger.Reorder.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}> {th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {localItems?.map((response, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => {
                          return (
                            <TableRow
                              key={index}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                            >
                              {localItems?.length > 1 && (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  id="td-center"
                                  className="reorder-feeledger__tableblock--table--slno"
                                >
                                  =
                                </TableCell>
                              )}

                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                id="td-center"
                                className="reorder-feeledger__tableblock--table--slno"
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                key={index}
                              >
                                {response.node.ldgr_desc}
                              </TableCell>
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>
      <div className="row g-0">
        <div className="col">
          {localItems?.length > 1 ? (
            <>
              <Button
                mode="save-order"
                onClick={() => {
                  HandleSaveDragAndDrop();
                }}
              />
              <Button
                mode="reorder"
                onClick={() => {
                  setorder(SortBy.LDGR_DESC);
                  setLocalItems((prev: FeeLedgerEdge[]) => {
                    const temp = [...prev];
                    setitems(temp);
                    return temp;
                  });
                }}
              >
                by Alphabetical order
              </Button>
            </>
          ) : null}
          <Button mode="cancel" onClick={() => setReOrderModal(false)} />
        </div>
        <div className="col-3 eduate-account-ledger__total">
          <div className="student-total-count">
            Total Ledgers:&nbsp;
            <b>{acctLedgers.data?.GetAcctLdgrs.totalCount!}</b>
          </div>
        </div>
      </div>

      <LoadingModal flag={updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default ReorderFeeLedger;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
const Data = {
  Tests: [
    "1st Monthly Test",
    "2nd Monthly Test",
    "3rd Monthly Test",
    "1st Preparatory",
    "2nd Preparatory",
    "3rd Preparatory",
  ],
  subjects: [
    "Max Marks",
    "ENglish1",
    "English2",
    "2nd Language (Kannada/Hindi)",
    "Mathematics",
    "Physics",
    "Chemistry",
    "Biology",
    "His & Civ",
    "Geography",
    "CA/PET/Art",
    "Total",
    "Percentage",
    "Rank",
    "height & weight",
    "No. of day Present",
    "No. of days Absent",
    "Total Working Days",
  ],
};
const Template = () => {
  return (
    <>
      <div className="bgs-tenth-pr__bs">
        <div className="bgs-tenth-pr__bs--title">
          <b>PROJECT / ASSIGNMENT</b>
        </div>
        <div className="bgs-tenth-pr__bs--flex">
          <div className="bgs-tenth-pr__bs--flex--name">
            <span>Name</span>
            <input />
          </div>
          <div className="bgs-tenth-pr__bs--flex--class">
            <span>Class</span>
            <input />
          </div>
          <div className="bgs-tenth-pr__bs--flex--class">
            <span>Section</span>
            <input />
          </div>
          <div className="bgs-tenth-pr__bs--flex--class">
            <span>Year</span>
            <input />
          </div>
        </div>
        <div className="bgs-tenth-pr__bs--tableblock">
          <TableContainer className="bgs-tenth-pr__bs--table">
            <Table>
              <TableHead>
                <TableRow className="bgs-tenth-pr__bs--table--first-row">
                  <TableCell rowSpan={2}></TableCell>
                  {Data.Tests.map((res) => {
                    return <TableCell>{}</TableCell>;
                  })}
                </TableRow>
                <TableRow>
                  {Data.Tests.map((res) => {
                    return <TableCell>{res}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Data.subjects.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell className="bgs-tenth-pr__bs--table--sub">
                      {row}
                    </TableCell>
                    {Array.from({ length: 6 }).map(() => {
                      return <TableCell></TableCell>;
                    })}
                  </TableRow>
                ))}
                <TableRow className="bgs-tenth-pr__bs--table--remarks">
                  <TableCell rowSpan={3}>Remarks</TableCell>
                  <TableCell rowSpan={3}></TableCell>
                  <TableCell rowSpan={3}></TableCell>
                  <TableCell rowSpan={3}></TableCell>
                  <TableCell rowSpan={3}></TableCell>
                  <TableCell rowSpan={3}></TableCell>
                </TableRow>
                <TableRow className="bgs-tenth-pr__bs--table--remarks">
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className="bgs-tenth-pr__bs--table--remarks">
                  <TableCell></TableCell>
                </TableRow>

                <TableRow className="bgs-tenth-pr__bs--table--sign">
                  <TableCell>Class Teacher Sign</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className="bgs-tenth-pr__bs--table--sign">
                  <TableCell>Principal Sign</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className="bgs-tenth-pr__bs--table--sign">
                  <TableCell>Parent Sign</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Template;

import React from "react";
import { useRef } from "react";

import StandardReceiptTemplate from "./StandardTemplate/Template1";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../stories/Button/Button";
import Template2 from "../Vouchers/StandardTemplate/Index";
import USeVoucherReceiptConfig from "./hooks/UseReceiptConfig";
import { VoucherTemplates } from "../../Enum.types";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  handleClear?: () => void;
}

const VoucherPrint = ({ setModalFlag, handleClear }: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const {
    USE_VOUCHER_TEMPLATES,
    USE_VOUCHER_MARGIN_LEFT,
    USE_VOUCHER_MARGIN_RIGHT,
    USE_VOUCHER_MARGIN_TOP,
    USE_VOUCHER_MARGIN_BOTTOM,
  } = USeVoucherReceiptConfig();

  const marginLeft = `${Math.min(USE_VOUCHER_MARGIN_LEFT || 0, 200)}px`;
  const marginRight = `${Math.min(USE_VOUCHER_MARGIN_RIGHT || 0, 200)}px`;
  const marginTop = `${Math.min(USE_VOUCHER_MARGIN_TOP || 0, 200)}px`;
  const marginBottom = `${Math.min(USE_VOUCHER_MARGIN_BOTTOM || 0, 200)}px`;

  return (
    <>
      <div ref={componentRef} className="voucher-receipts__print">
        <>
          <style type="text/css" media="print" scoped>
            {`
            
            @page {
        size: auto; 
              margin-left: ${marginLeft};
              margin-right: ${marginRight};
              margin-top: ${marginTop};
              margin-bottom: ${marginBottom};
            }
            body {
              padding:0px;
              margin:0px;
            }
  

          `}
          </style>
          <div className="h-100">
            {USE_VOUCHER_TEMPLATES === VoucherTemplates.TEMPLATE_1 && (
              <StandardReceiptTemplate />
            )}
            {USE_VOUCHER_TEMPLATES === VoucherTemplates.TEMPLATE_2 && (
              <Template2 />
            )}
          </div>
        </>
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
      <Button
        mode="cancel"
        onClick={() => {
          handleClear?.();
          setModalFlag(false);
        }}
      />
    </>
  );
};

export default VoucherPrint;

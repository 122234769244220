import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../stories/Input/Input";
import { NavLink } from "react-router-dom";

const List = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();

  const List = [
    {
      value: 1,
      label: "Class Teacher List",
      url: `/${InstId}/academics/reports/teacher`,
    },
    {
      value: 2,
      label: "Teachers Allocated List",
      url: `/${InstId}/academics/reports/teacher/alloted`,
    },
    {
      value: 3,
      label: "All Teachers List",
      url: `/${InstId}/academics/reports/teacher/all`,
    },
  ];
  return (
    <>
      <TableContainer className="fb-form-report__left--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Reports </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <Input id="search" placeholder="Search..." />
            </TableRow>
            {List.map((label) => {
              return (
                <TableRow key={label.value}>
                  <TableCell
                    onClick={() => {
                      navigate(label.url);
                    }}
                  >
                    <NavLink to={label.url}>{label.label}</NavLink>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;

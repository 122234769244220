import React, { useContext, useEffect, useState } from "react";
import Home from "../../../../Master/Student/Dashboard/Home/Index";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../../../../images/Avatar.svg";
import TextArea from "../../../../../stories/TextArea/TextArea";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcdFeedbackFromDetailsData,
  GetAcdFeedbackFromDetailsVars,
  GetAcdFeedbackStdRatingData,
  GetAcdFeedbackStdRatingVars,
} from "../../../../Academics/types/subject/Subject";
import {
  GetAcdFeedbackFormDetails,
  GetAcdFeedbackStdRating,
  GetFormDataByFormId,
} from "../../../../Academics/queries/general";
import { EMPTY_STRING, TODAY_DATE } from "../../../../../utils/constants";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../../utils/Enum.types";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import {
  AddAcdFeedbackStdRating,
  UpdateAcdFeedbackStdRating,
} from "../../../../Academics/DailyDiary/queries/mutations";
import { msgType } from "../../../../../utils/Form.types";
import LoadingModal from "../../../../../pages/LoadingModal";
import MessageModal from "../../../../../pages/MessageModal";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import useAcdSubjectDetails from "../../../../Academics/hooks/useAcdSubjectDetails";
import { PayRollEmpMasterNodeForLogin } from "../../../../HR/queries/employee/query";
import { EmpData } from "../../../../HR/hooks/useEmployeeAttsByMonth";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import {
  toInputStandardDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import { GetAcdSubjectAllotedToStudent } from "../../../../../queries/customallocation/combination/queries";
import useCheckAllocationType from "../../../../Academics/hooks/useCheckAllocationType";

const FillForm: React.FC = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { formName, subjectId, InstId, formId, teacherId } = useParams();

  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const { data: subjectData } = useAcdSubjectDetails(Number(subjectId));

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const [GetEmpDetailsForLogin, { data: teacherData }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNodeForLogin, {
    variables: {
      id: Number(teacherId),
      token,
    },
    fetchPolicy: "network-only",
  });

  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { allocationId, studentEntryId } = useStudentDatabyId();
  const { flag } = useCheckAllocationType(studentEntryId);

  const subjectAllocationLevel =
    configData && configData.data?.GetSwConfigVariables[0].config_string_value;

  const [GetAcdFeedbackFrom, { data }] = useLazyQuery<
    GetAcdFeedbackFromDetailsData,
    GetAcdFeedbackFromDetailsVars
  >(GetAcdFeedbackFormDetails, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
      form_name: formName ? formName : EMPTY_STRING,
    },
  });

  const [ratings, setRatings] = useState<{ [key: number]: number }>({});
  const [formDetailId, setFormDetailId] = useState(0);

  const [remarks, setRemarks] = useState<string>("");

  const [currentIdx, setCurrentIdx] = useState<number | null>(1);
  const [AddFeedBack, { loading }] = useMutation(AddAcdFeedbackStdRating, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateFeedBack, { loading: updateLoading }] = useMutation(
    UpdateAcdFeedbackStdRating,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetAcdFeedbackAnswer, { data: answerData }] = useLazyQuery<
    GetAcdFeedbackStdRatingData,
    GetAcdFeedbackStdRatingVars
  >(GetAcdFeedbackStdRating);

  useEffect(() => {
    if (
      currentIdx &&
      teacherId &&
      formId &&
      subjectAllocationLevel &&
      allocationId &&
      state.studentId &&
      token
    ) {
      GetAcdFeedbackAnswer({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          inst_id: InstId!,
          token,
          allotted_level: subjectAllocationLevel!,
          allotted_id: allocationId!,
          query_type: {
            pr_emp_id: Number(teacherId),
            student_id: state.studentId,
            subj_master_id: Number(subjectId),
            idx: currentIdx,
            form_name_id: Number(formId),
          },
          perStdSubjAllocation: flag!,
        },
      });
    }
  }, [currentIdx, flag]);

  useEffect(() => {
    if (answerData && answerData?.GetAcdFeedbackStdRating.length > 0) {
      setFormDetailId(answerData.GetAcdFeedbackStdRating[0].fb_form_detail_id);
      setRatings({
        ...ratings,
        [answerData.GetAcdFeedbackStdRating[0].idx]:
          answerData.GetAcdFeedbackStdRating[0].rating,
      });
      setRemarks(answerData.GetAcdFeedbackStdRating[0].remarks);
    }
  }, [answerData]);

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (allocationId && formName && subjectAllocationLevel) {
      GetAcdFeedbackFrom();
    }
  }, [allocationId, formName, subjectAllocationLevel, GetAcdFeedbackFrom]);
  useEffect(() => {
    if (teacherId && token) {
      GetEmpDetailsForLogin();
    }
  }, [teacherId, GetEmpDetailsForLogin, token]);
  useEffect(() => {
    if (data && data.GetAcdFeedbackFormDetails.length > 0) {
      setCurrentIdx(data.GetAcdFeedbackFormDetails[0].idx);
    }
  }, [data]);

  const handleRatingChange = (idx: number, value: number) => {
    setRatings({ ...ratings, [idx]: value });
  };

  const handleRemarksChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRemarks(event.target.value);
  };

  const HandleClear = () => {
    setRemarks(EMPTY_STRING);
    setFormDetailId(0);
    setRatings({});
  };

  const handleSaveAndNext = async () => {
    if (currentIdx !== null) {
      const currentRating = ratings[currentIdx];
      try {
        const response = await AddFeedBack({
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            user_details,
            allotted_level: subjectAllocationLevel!,
            allotted_id: allocationId!,
            input: {
              idx: currentIdx,
              rating: currentRating,
              remarks,
              student_id: state.studentId,
              form_name_id: formId,
              fb_form_detail_id: formDetailId,
              pr_emp_id: formData.node.is_academic ? teacherId : 0,
              subj_master_id: formData.node.is_academic ? Number(subjectId) : 0,
            },
            todays_date: toIsoDate(TODAY_DATE),
            perStdSubjAllocation: flag!,
          },
          refetchQueries: [
            {
              query: GetAcdFeedbackStdRating,
              variables: {
                acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
                inst_id: InstId!,
                token,
                allotted_level: subjectAllocationLevel!,
                allotted_id: allocationId!,
                query_type: {
                  pr_emp_id: teacherId,
                  student_id: state.studentId,
                  subj_master_id: Number(subjectId),
                  idx: currentIdx,
                  form_name_id: formId,
                },
              },
            },
            {
              query: GetAcdFeedbackFormDetails,
              variables: {
                acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
                inst_id: InstId!,
                token,
                form_name: formName ? formName : EMPTY_STRING,
              },
            },
            {
              query: GetAcdSubjectAllotedToStudent,
              variables: {
                acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
                inst_id: Number(InstId)!,
                student_id: state.studentId,
                token,
                allotted_id: allocationId,
                allotted_level: subjectAllocationLevel!,
                per_std_subj_allocation: flag ? flag : false,
                form_name_id: Number(formId),
              },
            },
          ],
        });

        if (response.data.AddAcdFeedbackStdRating) {
          const nextQuestion = data?.GetAcdFeedbackFormDetails.find(
            (item) => item.idx === currentIdx + 1
          );
          if (nextQuestion) {
            setCurrentIdx(nextQuestion.idx);
          } else {
            // All questions answered, navigate to preview or summary page
            navigate(
              `/${InstId}/masters/studentlist/${state.studentId}/${formName}/${formId}/${teacherId}/${subjectId}/academics/feedback/preview`
            );
          }
        }
      } catch (error) {
        console.error("Error saving feedback:", error);
      }
      HandleClear();
    }
  };

  const handleUpdateAndNext = async () => {
    if (currentIdx !== null) {
      const currentRating = ratings[currentIdx];
      try {
        const response = await UpdateFeedBack({
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            user_details,
            rating: currentRating,
            remarks,
            std_rating_id:
              answerData && answerData.GetAcdFeedbackStdRating[0].id,
            todays_date: toIsoDate(TODAY_DATE),
          },
          refetchQueries: [
            {
              query: GetAcdFeedbackStdRating,
              variables: {
                acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
                inst_id: InstId!,
                token,
                allotted_level: subjectAllocationLevel!,
                allotted_id: allocationId!,
                query_type: {
                  pr_emp_id: teacherId,
                  student_id: state.studentId,
                  subj_master_id: Number(subjectId),
                  idx: currentIdx,
                  form_name_id: formId,
                },
              },
            },
            {
              query: GetAcdFeedbackFormDetails,
              variables: {
                acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
                inst_id: InstId!,
                token,
                form_name: formName ? formName : EMPTY_STRING,
              },
            },
          ],
        });

        if (response.data.UpdateAcdFeedbackStdRating) {
          const nextQuestion = data?.GetAcdFeedbackFormDetails.find(
            (item) => item.idx === currentIdx + 1
          );
          if (nextQuestion) {
            setCurrentIdx(nextQuestion.idx);
          } else {
            // All questions answered, navigate to preview or summary page
            navigate(
              `/${InstId}/masters/studentlist/${state.studentId}/${formName}/${formId}/${teacherId}/${subjectId}/academics/feedback/preview`
            );
          }
        }
      } catch (error) {
        console.error("Error saving feedback:", error);
      }
      HandleClear();
    }
  };

  const ratingValues: { [key: string]: number } = {
    E: 5,
    G: 4,
    A: 3,
    F: 2,
    P: 1,
  };

  return (
    <>
      <Home />
      <Title>Fill Feedback Form</Title>
      <div className="fill-form">
        <div className="fill-form__flex">
          <div className="fill-form__flex--header">
            <b>{formData && formData.node.form_name}</b> &nbsp;
            <span className="formtype-form__flex--header--acd">
              {formData && formData.node.is_academic
                ? "Academic"
                : "Non-Academic"}
            </span>
          </div>
          <div className="fill-form__flex--sub">
            <b>{subjectData && subjectData.node.subj_desc}</b>
            <span>{subjectData && subjectData.node.subj_code}</span>
          </div>
          <div className="fill-form__flex--dates">
            <TextField
              className="fill-form__flex--textfield"
              label="Start Date"
              type="date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
              value={
                formData && toInputStandardDate(formData.node.form_st_date)
              }
            />
            <img src={Compare} alt="" />
            <TextField
              className="fill-form__flex--textfield"
              label="End Date"
              type="date"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              disabled
              value={
                formData && toInputStandardDate(formData.node.form_end_date)
              }
            />
          </div>
        </div>
        <div className="fill-form__datablock--preview">
          <div className="fill-form__datablock--emp">
            <img src={Avatar} alt="" />
            <div className="fill-form__datablock--emp--block">
              <b className="fill-form__datablock--emp--name">
                {teacherData &&
                  teacherData.node.emp_first_name +
                    " " +
                    teacherData.node.emp_middle_name +
                    " " +
                    teacherData.node.emp_last_name}
              </b>
              <span className="fill-form__datablock--emp--id">
                {teacherData && teacherData.node.emp_id}
              </span>
            </div>
          </div>
          <div className="fill-form__datablock--numbers">
            <ul>
              {data?.GetAcdFeedbackFormDetails.map((item, index) => (
                <li
                  key={index}
                  className={currentIdx === item.idx ? "active" : ""}
                  // onClick={() => handleQuestionClick(item.idx)}
                >
                  {item.idx}
                </li>
              ))}
            </ul>
          </div>
          {currentIdx !== null && (
            <>
              {data?.GetAcdFeedbackFormDetails.filter(
                (item) => item.idx === currentIdx
              ).map((item) => (
                <div key={item.idx}>
                  <div className="fill-form__datablock--question">
                    <b>{item.rating_factor}</b>
                  </div>
                  <div className="fill-form__datablock--radio">
                    <FormControl>
                      <RadioGroup
                        row
                        value={
                          Object.keys(ratingValues).find(
                            (key) => ratingValues[key] === ratings[item.idx]
                          ) || ""
                        }
                        onChange={(e) => {
                          handleRatingChange(
                            item.idx,
                            ratingValues[e.target.value]
                          );
                          setFormDetailId(item.id);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Excellent"
                          value="E"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Good"
                          value="G"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Average"
                          value="A"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Fair"
                          value="F"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Poor"
                          value="P"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              ))}

              <div className="fill-form__datablock--remarks">
                <b>Remarks</b> <span>(if any)</span>
              </div>
              <TextArea
                rows={6}
                value={remarks}
                onChange={handleRemarksChange}
              />

              <div className="row g-0">
                <div className="col">
                  <Button
                    mode="back"
                    className="fill-form__datablock--remarks--button"
                    onClick={() => navigate(-1)}
                  />
                </div>
                <div className="col-4 flex-end">
                  {/* <Button
                    mode="previous"
                    onClick={() => setCurrentIdx(currentIdx - 1)}
                  /> */}
                  {answerData &&
                  answerData.GetAcdFeedbackStdRating.length > 0 ? (
                    <Button
                      mode="update-continue"
                      onClick={handleUpdateAndNext}
                    />
                  ) : (
                    <Button mode="next" onClick={handleSaveAndNext} />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <LoadingModal flag={loading || updateLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default FillForm;

import React from "react";
import { Label } from "../../../../stories/Label/Label";

const LegendsInStudentList = () => {
  return (
    <div className="col std_status--legends">
      <span className="std_status--legends--cur">CUR</span>
      <Label> &rarr; Current</Label>
      <span className="std_status--legends--nxt">NXT</span>
      <Label> &rarr; Reservation</Label>
      <span className="std_status--legends--ne">NE</span>

      <Label> &rarr; Not Eligible</Label>
      <span className="std_status--legends--soa">SOA</span>

      <Label> &rarr; Shortage of Attendance</Label>
      <span className="std_status--legends--tc">TC</span>

      <Label> &rarr; Transferred</Label>
      <span className="std_status--legends--det">DET</span>

      <Label> &rarr; Detained</Label>
    </div>
  );
};

export default LegendsInStudentList;

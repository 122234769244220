import { SalaryLedgerType } from "../enums/Enum.types";

//Table Headers
export const EarningsDeduction = "Earnings/Deduction";
export const Actions = "Actions";

//Form Field Names
export const sal_ledger_name_ref = "sal_ldgr_desc";
export const sal_ldgr_is_active = "sal_ldgr_is_active";
export const sal_ldgr_is_system_generated = "sal_ldgr_is_system_generated";
export const category_desc = "category_desc";
export const dept_desc = "dept_desc";
export const designation_desc = "designation_desc";
export const grade_desc = "grade_desc";
export const job_type_desc = "job_type_desc";
export const leave_desc = "leave_desc";

export const SalaryledgerTypeOptions = [
  {
    label: "Earning",
    value: SalaryLedgerType.EARNING,
  },
  {
    label: "Deduction",
    value: SalaryLedgerType.DEDUCTION,
  },
];
export const stepHeader = () => {
  return [
    "Basic Details",
    "Personal Details",
    "Academic and Professional Contributions",
    "Other Details",
    "Documents Upload",
  ];
};

export const MAX = "MAX";

export const BASIC = "BASIC";

export const HYPHEN = "-";

//Form input names
export const EMP_EXPERIENCE = "emp_experience";

//Master Data Configs
export const DEPARTMENT = "Department";
export const DESIGNATION = "Designation";
export const CATEGORY = "Category";
export const EMPLOYEE = "EMPLOYEE";
export const GRADE = "Grade";

//TableHeaders
export const ACTIONS = "Actions";
export const ACTION = "Action";
export const EMPLOYEEGRADE = "Employee Grade";
export const JOB_TYPE = "Job Type";

//Salary Ledgers
export const ESI = "ESI";

export const ESI_LIMIT = 21000;

//importModalTypes
export const LEAVE_LEDGER = "LEAVE_LEDGER";
export const SALARY_LEDGER = "SALARY_LEDGER";

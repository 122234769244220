import { TextField } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Cell } from "exceljs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../../stories/Title/Title";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE_30,
} from "../../../../utils/constants";
import {
  formatter,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardCase,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  ColumnVisibilityFor,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  FileUploadParams,
  InstitutionConfigurationTypes,
  StudentAcctReportType,
} from "../../../../utils/Enum.types";
import List from "./List";
import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import ExcelJS from "exceljs";
import Input from "../../../../stories/Input/Input";
import useDropdownData from "../../../../customhooks/useDropdownData";
import { optionsType, responseType } from "../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../../utils/Enum.keys";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../../hooks/useStudentsbyDemandAmount";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_CSS,
  M4_CELL,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import { accHeaderData } from "../../common/HeaderConsts";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import useSummationDatabyNewApi from "../../hooks/useSummationDatabyNewApi";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useAcctTableJson from "../../json/useAcctTableJson";
import { AppContext } from "../../../../context/context";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import AgentOptionsAutocomplete from "../../Agents/Components/AgentOptionsAutocomplete";
import { InstitutionNode } from "../../../../customhooks/useInstitutionsByCustId";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

export const studentStatusDropDown = [
  { label: "Current", value: "CUR" },
  { label: "Transfer", value: "TC" },
  { label: "Detained", value: "DET" },
  { label: "Not Eligible", value: "NE" },
  { label: "Reserved", value: "NXT" },
  { label: "Shortage Of Attendance", value: "SOA" },
  { label: "Cancelled", value: "CXLD" },
  { label: "Passed Out", value: "PASS" },
];
const demandCol = [
  { title: "Sl", field: "SlNo" },
  { title: "AdmNo", field: "AdmNo" },
  { title: "RegNo", field: "RegNo" },
  { title: "Name", field: "Name" },
  { title: "Mobile", field: "field" },
  { title: "Status", field: "Status" },
  { title: "Category", field: "Category" },
  { title: "OB", field: "OB" },
  { title: "DemandAmount", field: "DemandAmount" },
  { title: "Concession", field: "Concession" },
  { title: "Receivable", field: "Receivable" },
  { title: "Received", field: "Received" },
  { title: "Balance", field: "Balance" },
];

interface Props {
  reportType: StudentAcctReportType;
}

const Reports = ({ reportType }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const { serverDate } = useServerDateandTime();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [searchData, setSearchData] = useState("");

  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [departmentId, setDepartmentId] = useState<responseType | null>(null);
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [semesterId, setSemesterId] = useState<responseType | null>(null);
  const [sectionId, setSectionId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);

  const [excelFlag, setExcelFlag] = useState(false);
  const [pdfFlag, setPdfFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(
    ROWS_PER_PAGE_30
  );
  const [status, setStatus] = useState<optionsType | null>(null);
  // this should be always -1 for filter to work, if i set 0 as inital value, by default it's null, filter doesnt work
  const [balanceAmount, setBalanceAmount] = useState(-1);
  //useRefs
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const { InstDetails } = useInstDetails(1);

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentId ? departmentId.value : 0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semesterId ? semesterId.value : 0
  );

  const { categoryDropDown } = useInstMasterDataByInstId();

  const { students: studentsDemandDetails } = UseStudentsbyDemandAmount(
    departmentId ? departmentId.value : 0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semesterId ? semesterId.value : 0,
    sectionId ? sectionId.value : 0,
    categoryId ? categoryId.value : 0,
    searchData,
    rowsPerPage,
    reportType as StudentAcctReportType,
    0,
    status ? status.value : "",
    balanceAmount
  );
  const { StudentsSummationData } = useSummationDatabyNewApi(
    departmentId ? departmentId.value : 0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semesterId ? semesterId.value : 0,
    sectionId ? sectionId.value : 0,
    categoryId ? categoryId.value : 0,
    reportType as StudentAcctReportType,
    status ? status.value : ""
  );
  const { data, fetchMore, loading } = studentsDemandDetails;

  let sumStdDemand = 0;
  let sumStdDemandAmt = 0;
  let sumStdDemandConcession = 0;
  let sumStdDemandRefunds = 0;
  let sumStdDemandReceivable = 0;
  let sumStdDemandReceived = 0;
  let sumStdDemandBal = 0;

  for (const edge of data ? data.GetAcctStdDemand.edges : []) {
    sumStdDemand += edge.node.std_demand_ob || 0;
    sumStdDemandAmt += edge.node.std_demand_amt || 0;
    sumStdDemandConcession += edge.node.std_demand_concession || 0;
    sumStdDemandRefunds += edge.node.std_demand_refunds || 0;
    sumStdDemandReceivable += edge.node.std_demand_receivable || 0;
    sumStdDemandReceived += edge.node.std_demand_received || 0;
    sumStdDemandBal += edge.node.std_demand_bal || 0;
  }

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const feeBalanceData = data
    ? data.GetAcctStdDemand.edges.map((edge, index) => ({
        SlNo: index + 1,
        AdmNo: edge.node.mst_student.std_adm_no,
        RegNo: edge.node.mst_student.std_reg_no,
        Name: toStandardCase(
          edge.node.mst_student.first_name +
            " " +
            edge.node.mst_student.middle_name +
            " " +
            edge.node.mst_student.last_name
        ),
        Mobile: edge.node.mst_student.std_mobile,
        Status: edge.node.std_status,
        Category: edge.node.mst_student.category.cat_desc,
        OB: edge.node.std_demand_ob,
        DemandAmount: edge.node.std_demand_amt,
        Concession: edge.node.std_demand_concession,
        Refunds: edge.node.std_demand_refunds,
        Receivable: edge.node.std_demand_receivable,
        Received: edge.node.std_demand_received,
        Balance: edge.node.std_demand_bal,
      }))
    : [];

  const InstData =
    InstDetails.data && InstDetails.data.nodes.length
      ? InstDetails.data.nodes[0]
      : {
          inst_name: "",
          inst_address: "",
          inst_place: "",
          inst_pin: "",
        };

  const StudentsSummationDataFinal =
    StudentsSummationData.data &&
    StudentsSummationData.data.GetAcctStdDemandSummation.length
      ? StudentsSummationData.data?.GetAcctStdDemandSummation[0]
      : {
          std_demand_ob: 0,
          std_demand_amt: 0,
          std_demand_concession: 0,
          std_demand_receivable: 0,
          std_demand_received: 0,
          std_demand_bal: 0,
          std_demand_refunds: 0,
        };

  const finYr = state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING;
  const ReturnTitle = () => {
    switch (reportType) {
      case StudentAcctReportType.GENERAL:
        return "Fee Balance Students Report";
      case StudentAcctReportType.COMPLETELY_PAID:
        return "Completely Paid  Students Report";
      case StudentAcctReportType.PARTIALLY_PAID:
        return "Partially Paid Students Report";
      case StudentAcctReportType.DEMAND_RAISED_NOT_PAID:
        return "Not Paid Students Report";

      case StudentAcctReportType.REFUNDS:
        return "Refund Fee Report";

      case StudentAcctReportType.BY_AGENT:
        return "Agent Student Report";
      default:
        return "Concession Fee Report";
    }
  };

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result ? reader.result.toString()! : EMPTY_STRING);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(`${InstData.inst_name}`, 80, startY);

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(`${InstData.inst_address}`, 120, startY + 7);

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstData.inst_place},${InstData.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    40,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(ReturnTitle(), 110, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: demandCol.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: feeBalanceData,
                  foot: [
                    [
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "Total",
                      `${format(StudentsSummationDataFinal.std_demand_ob)}`,
                      `${format(StudentsSummationDataFinal.std_demand_amt)}`,
                      `${format(
                        StudentsSummationDataFinal.std_demand_concession
                      )}`,
                      `${format(
                        StudentsSummationDataFinal.std_demand_receivable
                      )}`,
                      `${format(
                        StudentsSummationDataFinal.std_demand_received
                      )}`,
                      `${format(StudentsSummationDataFinal.std_demand_bal)}`,
                    ],
                  ],
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: (data) => {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                  columnStyles: {
                    7: { halign: "right" },
                    8: { halign: "right" },
                    9: { halign: "right" },
                    10: { halign: "right" },
                    11: { halign: "right" },
                    12: { halign: "right" },
                  },
                  footStyles: {
                    fillColor: [144, 238, 144],
                    textColor: [0, 0, 0],
                    fontSize: 10,
                    halign: "right",
                  },
                });
                doc.save(`${InstData.inst_name} ${finYr} `);
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE_30);
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.FEE_BALANCE);

    const dataRows = feeBalanceData
      ? feeBalanceData.map((item) => [
          item.SlNo,
          item.AdmNo,
          item.RegNo,
          item.Name,
          item.Mobile,
          item.Status,
          item.Category,
          item.OB,
          item.DemandAmount,
          item.Concession,
          item.Refunds,
          item.Receivable,
          item.Received,
          item.Balance,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 5;
    worksheet.getColumn(2).width = 13;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 10;
    worksheet.getColumn(7).width = 13;
    worksheet.getColumn(8).width = 13;
    worksheet.getColumn(9).width = 18;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 13;
    worksheet.getColumn(12).width = 13;
    worksheet.getColumn(13).width = 13;
    worksheet.getColumn(14).width = 13;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          if (configLogo) {
            worksheet.addImage(imageV, "M1:M3");
          }
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, 14);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstData.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value = InstData.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:N2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstData.inst_place + "-" + InstData.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:N3");

                  const mergedHeader: Cell = worksheet.getCell(F4_CELL);
                  mergedHeader.value = ExcelPageHeader.FEE_BALANCE;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("F4:L4");
                  const mergedDate: Cell = worksheet.getCell(M4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("M4:N4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;

                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:E4");

                  let Char = FIRST_INDEX;
                  for (let i = 0; i < accHeaderData.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = accHeaderData[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.RIGHT };
                      cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
                      row.getCell(4).font = {
                        name: ExcelFont.CENTURY_GOTHIC,
                        size: 9,
                      };
                      row.getCell(4).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                      row.getCell(5).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                      row.getCell(1).alignment = {
                        horizontal: ExcelAlignment.CENTER,
                      };
                      row.getCell(6).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                      row.getCell(7).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                      row.getCell(3).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    });
                  });

                  const footerRow = worksheet.addRow([]);
                  footerRow.getCell(7).value = ExcelFooterHeader.SUMMATION;
                  footerRow.getCell(7).font = {
                    name: ExcelFont.ARIAL,
                    size: 10,
                  };
                  footerRow.getCell(8).value = sumStdDemand;
                  footerRow.getCell(9).value = sumStdDemandAmt;
                  footerRow.getCell(10).value = sumStdDemandConcession;
                  footerRow.getCell(11).value = sumStdDemandRefunds;
                  footerRow.getCell(12).value = sumStdDemandReceivable;
                  footerRow.getCell(13).value = sumStdDemandReceived;
                  footerRow.getCell(14).value = sumStdDemandBal;

                  for (let i = 7; i <= 14; i++) {
                    footerRow.getCell(i).alignment = {
                      horizontal: ExcelAlignment.RIGHT,
                    };
                  }
                  worksheet.addConditionalFormatting({
                    ref: `H${footerRow.number}:N${footerRow.number}`,
                    rules: FOOTER_CSS,
                  });
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, ExcelSheetsNames.FEE_BALANCE);
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE_30);
                });
              });
          });
        });
      });
  };
  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.FeeBalanceReports.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(() => {
    if (
      excelFlag &&
      rowsPerPage === null &&
      !loading &&
      data &&
      data.GetAcctStdDemand.edges.length === data.GetAcctStdDemand.totalCount
    ) {
      downloadExcel();
    }
    if (
      pdfFlag &&
      rowsPerPage === null &&
      !loading &&
      data &&
      data.GetAcctStdDemand.edges.length === data.GetAcctStdDemand.totalCount
    )
      downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading]);

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id && row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.mst_student.std_adm_no,
            reg_no: node.mst_student.std_reg_no,

            name: toStandardCase(
              node.mst_student.first_name +
                node.mst_student.middle_name +
                node.mst_student.last_name
            ),
            mobile: node.mst_student.std_mobile,
            status: node.std_status,
            cat: node.mst_student.category.cat_desc,
            ob: format(node.std_demand_ob),
            demand: format(node.std_demand_amt),
            concession: format(node.std_demand_concession),
            receivable: format(node.std_demand_receivable),
            received: format(node.std_demand_received),
            refunds: format(node.std_demand_refunds),
            balance: format(node.std_demand_bal),
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.mst_student.std_adm_no,
            reg_no: node.mst_student.std_reg_no,

            name: toStandardCase(
              node.mst_student.first_name +
                node.mst_student.middle_name +
                node.mst_student.last_name
            ),
            mobile: node.mst_student.std_mobile,
            status: node.std_status,
            cat: node.mst_student.category.cat_desc,
            ob: format(node.std_demand_ob),
            demand: format(node.std_demand_amt),
            concession: format(node.std_demand_concession),
            receivable: format(node.std_demand_receivable),
            received: format(node.std_demand_received),
            refunds: format(node.std_demand_refunds),
            balance: format(node.std_demand_bal),
          }))
        );
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
                const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctStdDemand: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctStdDemand.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  const getRow = () => {
    if (students.length) {
      return [
        {
          id: [],
          cat: "Total",
          adm_no: [],
          reg_no: [],
          name: [],
          mobile: [],
          status: [],
          ob: format(StudentsSummationDataFinal.std_demand_ob),
          demand: format(StudentsSummationDataFinal.std_demand_amt),
          concession: format(StudentsSummationDataFinal.std_demand_concession),
          receivable: format(StudentsSummationDataFinal.std_demand_receivable),
          received: format(StudentsSummationDataFinal.std_demand_received),
          refunds: format(StudentsSummationDataFinal.std_demand_refunds),
          balance: format(StudentsSummationDataFinal.std_demand_bal),
        },
      ];
    }
    return [];
  };

  const pinnedRows = {
    bottom: getRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "cat":
          return "total";
        case "balance":
          return "balance-count";
        case "id":
        case "adm_no":
        case "reg_no":
        case "name":
        case "mobile":
        case "status":
          return "";
        default:
          return "totalcount";
      }
    }

    return "";
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      reg_no: false,
      mobile: false,
      cat: false,
    });

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FEE_BALANCE_REPORT
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FEE_BALANCE_REPORT,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>{ReturnTitle()}</Title>
      <div className="fee-balance-reports">
        <div className="fee-balance-reports__tableblock">
          <List />
        </div>

        <div className="fee-balance-reports__reportblock">
          <form className="row g-0 fee-balance-reports__select-options">
            {reportType === StudentAcctReportType.BY_AGENT ? (
              <div className="col-2">
                <AgentOptionsAutocomplete labelRequired />
              </div>
            ) : null}
            {USE_DEPARTMENT_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={departmentDropDown}
                  openOnFocus
                  value={departmentId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, departmentId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (departmentId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentId(newValue as responseType);
                    } else {
                      setDepartmentId(null);
                    }
                    setBranchId(null);
                    setClassId(null);
                    setSemesterId(null);
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      fullWidth
                      autoFocus
                      className={labelClasses.formControlRoot}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_BRANCH_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={branchDropDown}
                  openOnFocus
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, branchId)
                  }
                  value={branchId}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (branchId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchId(newValue as responseType);
                    } else {
                      setBranchId(null);
                    }
                    setClassId(null);
                    setSemesterId(null);
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      className={labelClasses.formControlRoot}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_CLASS_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={classDropDown}
                  openOnFocus
                  value={classId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, classId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (classId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassId(newValue as responseType);
                    } else {
                      setClassId(null);
                    }
                    setSemesterId(null);
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${classLabel}`}
                      className={labelClasses.formControlRoot}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_SEMESTER_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={semesterDropDown}
                  openOnFocus
                  value={semesterId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, semesterId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (semesterId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterId(newValue as responseType);
                    } else {
                      setSemesterId(null);
                    }
                    setSectionId(null);
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      className={labelClasses.formControlRoot}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_SECTION_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={sectionDropDown}
                  openOnFocus
                  value={sectionId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, sectionId)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (sectionId) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSectionId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionId(newValue as responseType);
                    } else {
                      setSectionId(null);
                    }
                    setCategoryId(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      className={labelClasses.formControlRoot}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_CATEGORY_KEY ? (
              <div className="col-2">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={categoryDropDown}
                  openOnFocus
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, categoryId)
                  }
                  value={categoryId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategoryId(newValue as responseType);
                    } else {
                      setCategoryId(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setCategoryId(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={categoryLabel}
                      className={labelClasses.formControlRoot}
                             slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    />
                  )}
                />
              </div>
            ) : null}
          </form>
          <div className="row g-0 fee-balance-reports__select-options2">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                className="fee-balance-reports__reportblock--filter-options--search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchData(e.target.value);
                }}
                value={searchData}
                onKeyDown={handleFormEvent}
              />
            </div>

            {reportType === StudentAcctReportType.GENERAL && (
              <div className="col-2">
                <TextField
                  label="Balance Amount"
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  onChange={(e) => setBalanceAmount(Number(e.target.value))}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setBalanceAmount(-1);
                    }
                  }}
                  className="fee-balance-reports__select-options2--textfield"
                />
              </div>
            )}

            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentStatusDropDown}
                openOnFocus
                value={status}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStatus(newValue as optionsType);
                  } else {
                    setStatus(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setStatus(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    className={labelClasses.formControlRoot}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
            </div>
          </div>

          <div
            className={`fee-balance-reports__reportblock--details `}
          >
            {students.length ? (
              <>
                <StyledDatagrid
                  columns={columns}
                  rows={rows}
                  disableRowSelectionOnClick
                  disableChildrenSorting
                  rowHeight={TABLE_ROW_HEIGHT}
                  pinnedRows={pinnedRows}
                  getCellClassName={getCellClassName}
                  hideFooter
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                  }
                
                />
              </>
            ) : (
              <b className="nodata">Sorry, no results.</b>
            )}
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          <div className="fee-balance-reports__buttons">
            {students.length ? (
              <>
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setPdfFlag(true);
                  }}
                  disabled={classId && classId.value ? false : true}
                  mode="pdf"
                ></Button>
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setExcelFlag(true);
                  }}
                  disabled={classId && classId.value ? false : true}
                  mode="export"
                />
              </>
            ) : null}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
        <div className="col-2 fee-balance-reports__total">
          <StudentTotalCount
            totalCount={data ? data.GetAcctStdDemand.totalCount! : 0}
          />
        </div>
      </div>
    </>
  );
};

export default Reports;

import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import Close from "../../../../images/Close.svg";
import Input from "../../../../stories/Input/Input";
import ReactQuill from "react-quill";
import { ChannelQueryType } from "../../../Accounts/common/Enum.types";
import {
  Direction,
  InstitutionConfigurationTypes,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import { Label } from "../../../../stories/Label/Label";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  formatOptions,
  formattingToolbarOptions,
} from "../../../../utils/constants";

import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  AddChannelmessage,
  AddIndividualStdMessageWithTags,
  UpdateChannelMessage,
} from "../../../../queries/chatapplication/mutations";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import { GetChannelMessages } from "../../../../queries/chatapplication/list";
import { handleUploadAndDownloadFile } from "../../../../utils/Upload";
import { msgType } from "../../../../utils/Form.types";
import useChannelDetails from "../../../../customhooks/useChannelDetails";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import Avatar from "../../../../images/Avatar.svg";
import {
  GetInstMsgTemplatesVars,
  GetMsgInstTemplatesData,
  templateEdges,
} from "../../../Templates/utils/types";
import useIndividualStudentId from "../../hooks/useIndividualStudentId";
import { GetMsgInstTemplates } from "../../../Templates/queries/list";
import Template from "../../../../images/TemplateMessages.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LoadingModal from "../../../../pages/LoadingModal";
import { UpdateMessageType } from "./Index";
import { TemplateViewDrawer } from "../../../../styles/DrawerStyles";

interface ModalProps {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  Channel: ChannelQueryType;
  setMessage: React.Dispatch<React.SetStateAction<msgType>>;
  selectedStudents?: { id: number; name: string }[];
  setSelectedStudents?: React.Dispatch<
    React.SetStateAction<{ id: number; name: string }[]>
  >;
  operation:Operation;
  updateMessage?:UpdateMessageType;

}
const SendMessage = ({
  setModalFlag,
  Channel,
  setMessage,
  selectedStudents,
  setSelectedStudents,
  operation,
  updateMessage
 
}: ModalProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  const [channelMessage, setChannelMessage] = useState("");

  const { token } = useToken();

  const { state } = useContext(AppContext);

  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);
  const [templates, setTemplates] = useState<templateEdges[]>([]);
  const [templateId, setTemplateId] = useState(-898);

  const { user_details } = useLoggedInUserDetails();

  const { channelId: individualChannelId } = useIndividualStudentId(
    state.studentId
  );
  const { InstId } = useParams();
  const { channelDetails } = useChannelDetails([
    ChannelQueryType.ANNOUNCEMENTS,
    ChannelQueryType.ENTRYLEVEL,
    ChannelQueryType.INDIVIDUAL,
  ]);
  const [GetTemplates] = useLazyQuery<
    GetMsgInstTemplatesData,
    GetInstMsgTemplatesVars
  >(GetMsgInstTemplates, {
    variables: {
      token,
      inst_id: InstId!,
      after: null,
      first: ROWS_PER_PAGE,
      name: EMPTY_STRING,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.MSG_TPL_DESC,
      },
    },
  });

  const { InstFormData } = useInstDetails(1);
  // eslint-disable-next-line
  const [mediaContent, setMediaContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleQuillChange = (value: string) => {
    setChannelMessage(value);
  };
  const studentIds = selectedStudents?.map((student) => student.id);

  const { configData: sizeLimits } = useSwConfigData([
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE,
  ]);

  const channelId =
    Channel === ChannelQueryType.INDIVIDUAL
      ? individualChannelId
      : channelDetails.data && channelDetails.data.GetChannelSubscribed.length
      ? channelDetails.data.GetChannelSubscribed[0].id
      : 0;

  let filename =
    selectedFiles.length > 1
      ? selectedFiles
          .map(
            (f) => `${InstFormData.inst_name}/channels/${channelId}/${f.name}`
          )
          .join(",")
      : selectedFiles.length === 1
      ? `${InstFormData.inst_name}/channels/${channelId}/${selectedFiles[0].name}`
      : "";

  const [AddIndividualStudentMessage, { loading }] = useMutation(
    AddIndividualStdMessageWithTags,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [AddChannelMsg, { loading: channelLoading }] = useMutation(
    AddChannelmessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let imageSize = 0;
    let videoSize = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE:
            imageSize = item.config_integer_value;
            break;
          case InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE:
            videoSize = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      imageSize,
      videoSize,
    };
  };
  const { imageSize, videoSize } = filterDataByConfigKey(
    sizeLimits.data?.GetSwConfigVariables!
  );
  const [UpdateChannelMsg, { loading: UpdateLoading }] = useMutation(
    UpdateChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
useEffect(()=>{
if(updateMessage){
  setChannelMessage(updateMessage.message)
}},[updateMessage])
 
    const handleSubmit = async () => {
      if (channelMessage === EMPTY_STRING) {
        alert("Message content cannot be empty");
        return;
      }
    
      // Validate file sizes
      const validFileTypes = [
        { type: "image/", maxSize: imageSize! * 1024 * 1024, alertMsg: "Image file size exceeds the maximum limit." },
        { type: "video/", maxSize: videoSize! * 1024 * 1024, alertMsg: "Video file size exceeds the maximum limit." },
        { type: "application/pdf", maxSize: imageSize! * 1024 * 1024, alertMsg: "PDF file size exceeds the maximum limit." },
        {
          type: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
          maxSize: imageSize! * 1024 * 1024,
          alertMsg: "Excel file size exceeds the maximum limit."
        }
      ];
    
      for (const f of selectedFiles) {
        for (const fileType of validFileTypes) {
          if (
            (Array.isArray(fileType.type) ? fileType.type.includes(f.type) : f.type.includes(fileType.type)) &&
            f.size > fileType.maxSize
          ) {
            alert(fileType.alertMsg);
            return; // Exit early if file size is invalid
          }
        }
      }
    
      try {
        let sendMsgPromise: Promise<any>;
    
        if (operation === Operation.CREATE) {
          if (Channel === ChannelQueryType.INDIVIDUAL || Channel === ChannelQueryType.MULTIPLE) {
            sendMsgPromise = AddIndividualStudentMessage({
              variables: {
                token,
                inst_id: InstId!,
                std_ids: selectedStudents ? studentIds : [state.studentId],
                input: {
                  msg_content: channelMessage,
                  msg_header: EMPTY_STRING,
                },
                user_details,
              },
              refetchQueries: [
                {
                  query: GetChannelMessages,
                  variables: {
                    token,
                    inst_id: InstId,
                    input: {
                      query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
                      channel_id: channelId,
                    },
                    direction: Direction.ASC,
                    last: 5,
                    after: null,
                    sortBy: SortBy.CREATED_AT,
                    mediaContent: EMPTY_STRING,
                  },
                },
              ],
            });
          } else {
            sendMsgPromise = AddChannelMsg({
              variables: {
                token,
                input: {
                  msg_content: channelMessage,
                  msg_media_content: selectedFiles.length ? filename : "",
                  msg_channel_id: channelId,
                  inst_id: InstId!,
                  msg_header: EMPTY_STRING,
                },
                user_details,
              },
              refetchQueries: [
                {
                  query: GetChannelMessages,
                  variables: {
                    inst_id: InstId,
                    input: {
                      channel_id: channelId,
                      query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
                    },
                    direction: Direction.ASC,
                    last: 5,
                    after: null,
                    mediaContent: EMPTY_STRING,
                    sortBy: SortBy.CREATED_AT,
                    token,
                  },
                },
              ],
            });
          }
    
          const { data } = await sendMsgPromise;
    
          if (data) {
            setChannelMessage(EMPTY_STRING);
    
            // Handle file uploads if necessary
            const uploadFilePromise = selectedFiles.length
              ? selectedFiles.length > 1
                ? Promise.all(
                    selectedFiles.map((f, index) =>
                      handleUploadAndDownloadFile(
                        f,
                        `${InstFormData.inst_name}/channels/${channelId}/${data.AddChannelMessage.id}_${index}.${f.name.split(".").pop()}`,
                        setProgress,
                        false
                      )
                    )
                  )
                : handleUploadAndDownloadFile(
                    selectedFiles[0],
                    `${InstFormData.inst_name}/channels/${channelId}/${data.AddChannelMessage.id}.${selectedFiles[0].name.split(".").pop()}`,
                    setProgress,
                    false
                  )
              : false;
    
            await Promise.all([sendMsgPromise, uploadFilePromise]);
    
            setChannelMessage(EMPTY_STRING);
            setSelectedFiles([]);
            setModalFlag(false);
            setMessage({
              flag: true,
              message: "Message Sent",
              operation: Operation.CREATE,
            });
            setSelectedStudents?.([]);
          }
        } else {
          // Update message in edit mode
          const { data } = await UpdateChannelMsg({
            variables: {
              token,
              input: {
                msg_content: channelMessage,
                msg_media_content:EMPTY_STRING,
                msg_header: EMPTY_STRING,
              },
              user_details,
              msg_id: updateMessage?.id,
            },
            refetchQueries: [
              {
                query: GetChannelMessages,
                variables: {
                  token,
                  inst_id: InstId ? InstId : state.InstId.toString(),
                  input: {
                    query_type: MsgChannelType.ANNOUNCEMENTS,
                    channel_id: channelId,
                  },
                  direction: Direction.ASC,
                  last: 5,
                  after: null,
                  sortBy: SortBy.CREATED_AT,
                  mediaContent: EMPTY_STRING,
                },
              },
            ],
          });
    
          if (data) {
            setMessage({
              flag: true,
              message: "Message Updated Successfully",
              operation: Operation.CREATE,
            });
            // setIsDisabled(true);
          }
        }
      } catch (error) {
        console.log("Error occurred:", error);
      }
      setModalFlag(false)
    };


  useEffect(() => {
    if (token && InstId) {
      GetTemplates().then(({ data }) => {
        if (data) {
          setTemplates(data.GetMsgInstTemplates.edges);
        }
      });
    }
  }, [GetTemplates, token, InstId]);

useEffect(() => {
    if (templateId > 0) {
      const res = templates.find(({ node: { id } }) => id === templateId);
      if (res) {
        setChannelMessage(res.node.msg_tpl_desc);
      }
    } // eslint-disable-next-line
  }, [templateId, templates]);
  
  const handleClear = () => {
    setChannelMessage("");
    setMediaContent("");
    setSelectedFiles([]);
  };

  return (
    <>
      <div className="academics__channel--send-message">
        <div className="academics__channel--send-message--title">
          <Title>Send Message</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="academics__channel--send-message--block">
          <div
            className={
              Channel === ChannelQueryType.MULTIPLE
                ? "academics__channel--send-message--block--left"
                : "academics__channel--send-message--block--fw"
            }
          >
            <div className="send-message__form">
              <div className="send-message__form--label-grid1">
                <Label>Message</Label>
                <ReactQuill
                  value={channelMessage}
                  onChange={handleQuillChange}
                  modules={formattingToolbarOptions}
                  formats={formatOptions}
                  placeholder={`Message...`}
                />
              </div>
              {Channel === ChannelQueryType.INDIVIDUAL ||
              Channel === ChannelQueryType.MULTIPLE ? null : (
                <div className="send-message__form--label-grid2">
                  <Label>Attachments</Label>
                  <div className="send-message__form--attachments">
                    <ul>
                      {selectedFiles.length
                        ? selectedFiles.map((file, index) => (
                            <li key={index}>{file.name}</li>
                          ))
                        : null}
                    </ul>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileInputChange}
                    />
                    <div className="send-message__form--attachments--footer">
                      Please Upload File (Max File Size: 5MB. Only Jpeg, jpg and
                      png allowed)
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {Channel === ChannelQueryType.MULTIPLE && (
            <div className="academics__channel--send-message--block--right">
              <div className="academics__channel--datablock--right--list--header">
                <span>Selected Students List</span>
                <b>{selectedStudents?.length}</b>
              </div>
              <Input id="search" placeholder="Search..." />
              <ul className="academics__channel--send-message--block--right--ul">
                {selectedStudents
                  ? selectedStudents.map((res) => {
                      return (
                        <li>
                          <img src={Avatar} alt="" />
                          <span>{res.name}</span>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          )}
        </div>
        <Button
          mode="send"
          onClick={handleSubmit}
          disabled={channelMessage === EMPTY_STRING || loading}
        />
      
          <Button onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
            <img src={Template} alt="/" /> Add Template
          </Button>
        
        <Button mode="clear" onClick={handleClear} />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <TemplateViewDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
   
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Choose Message Template</Title>
            <div className="choose-message-template">
              <div className="choose-message-template__tableblock">
                <TableContainer className="choose-message-template__table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl</TableCell>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {templates.map((template, index) => {
                        return (
                          <TableRow
                            key={template.node.id}
                            onClick={() => {
                              setTemplateId(template.node.id);
                              setIsDrawerOpen(!isDrawerOpen);
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell> {template.node.msg_tpl_desc}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Button
                mode="cancel"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
            </div>
          </div>
        </div>
      </TemplateViewDrawer>
      <LoadingModal flag={loading || channelLoading ||UpdateLoading } />
    </>
  );
};

export default SendMessage;

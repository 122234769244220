import { useLazyQuery } from "@apollo/client";
import { GetSwSalaryLdgrs } from "../../../queries/Eduate/list";
import useToken from "../../../customhooks/useToken";
import { useEffect } from "react";
export interface SWSalaryLedgerListType {
  id: number;
  sal_ldgr_desc: string;
  sal_ldgr_short_desc: string;
  sal_ldgr_idx: number;
  sal_ldgr_earn_deduct: string;
  sal_ldgr_type: string;
  sal_ldgr_round_to_next_int: boolean;
  sal_ldgr_remove_decimal: boolean;
  sal_ldgr_is_system_generated: boolean;
  sal_ldgr_is_basic: boolean;
  sal_ldgr_is_loan_ac: boolean;
}

interface GetSwSalaryLdgrsData {
  GetSwSalaryLdgrs: SWSalaryLedgerListType[];
}
interface GetSwSalaryLdgrsVars {
  token: string;
}
const usePredefindedSalaryLedgers = () => {
  const { token } = useToken();
  const [GetLedgers, { data, loading, error }] = useLazyQuery<
    GetSwSalaryLdgrsData,
    GetSwSalaryLdgrsVars
  >(GetSwSalaryLdgrs, {
    variables: {
      token,
    },
  });
  useEffect(() => {
    if (token) {
      GetLedgers();
    }
  }, [token, GetLedgers]);
  return {
    predefinedSalaryledger: {
      data,
      loading,
      error,
    },
  };
};

export default usePredefindedSalaryLedgers;

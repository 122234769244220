import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GetIdCardTemplate } from "../queries";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
export interface GetIdCardTemplates {
  id: number;
  id_tpl_desc: string;
  bg_color: string;
  header_color: string;
  footer_color: string;
  text_color: string;
  theme: string;
  inst_id: number;
}
interface GetIdCardTemplateData {
  GetIdCardTemplate: GetIdCardTemplates;
}
interface GetIdCardTemplateVars {
  token: string;
  inst_id: string | number;
}

const useIdcardConfig = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [GetIdCardTemplateList, { data, loading }] = useLazyQuery<
    GetIdCardTemplateData,
    GetIdCardTemplateVars
  >(GetIdCardTemplate, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  useEffect(() => {
    if (token) {
      GetIdCardTemplateList();
    }
  }, [token, GetIdCardTemplateList]);
  return {
    data,
    loading,
  };
};

export default useIdcardConfig;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import useToken from "../../../customhooks/useToken";
import { GetLibBookMaster } from "../../../queries/Library/BookEntry/list/byInstId";
import {
  BookMasterNode,
  GetLibBookMasterData,
  GetLibBookMasterVars,
} from "../../../Types/Library/BookEntry/paginationTypes";
import {
  BooksBy,
  BooksReportType,
  Direction,
  LibBookMasterQueryType,
  SortBy,
  UserType,
} from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import { ByNode as BookIssueDetails } from "../../../queries/Library/Node/index";

export interface GetBookIssueDetailsData {
  nodes: BookMasterNode[];
}
interface GetBookIssueDetailsVars {
  ids: number[];
  token: string;
}
const useBookEntryOptions = (
  searchBook: string,
  rowsPerPage: number | null,
  queryType: BooksReportType,
  userType: UserType | BooksBy,
  id?: number
) => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();

  const [book_query_type, setbook_query_type] = useState(
    LibBookMasterQueryType.NOT_SELECTED
  );
  const [bookEntries, setBookEntries] = useState<responseType[]>([]);

  const [ids, setIds] = useState([0]);
  const [instid, setInstid] = useState(0);

  const [GetBooks, { data, loading, error, variables, fetchMore }] =
    useLazyQuery<GetLibBookMasterData, GetLibBookMasterVars>(GetLibBookMaster, {
      variables: {
        token,
        input: {
          book_query_type,
          ids,
        },
        first: rowsPerPage,
        sortBy: SortBy.ACCESS_NO,
        direction: Direction.ASC,
        access_no: searchBook,
        after: null,
      },
    });

  const [
    GetIssueBookDetails,
    {
      data: IssuedBookDetails,
      loading: IssuedBookDetailsLoading,
      error: IssuedBookDetailsError,
    },
  ] = useLazyQuery<GetBookIssueDetailsData, GetBookIssueDetailsVars>(
    BookIssueDetails
  );

  useEffect(() => {
    if (state.InstId) {
      setInstid(Number(state.InstId));
    }
    if (InstId) {
      setInstid(Number(InstId));
    }
  }, [state.InstId, InstId]);

  useEffect(() => {
    if (instid) {
      switch (queryType) {
        case BooksReportType.GENERAL:
          setbook_query_type(LibBookMasterQueryType.BOOK_BY_INST_ID);
          setIds([Number(instid)]);
          break;
        case BooksReportType.BOOKS_IN_NOT_ALLOCATED_DEPT:
          setbook_query_type(
            LibBookMasterQueryType.BOOKS_IN_NOT_ALLOCATED_DEPT
          );
          setIds([Number(instid)]);
          break;
        case BooksReportType.BOOKS_ISSUED:
          if (userType === UserType.EMPLOYEE && state.staffId) {
            setbook_query_type(LibBookMasterQueryType.BOOKS_ISSUED_TO_STAFF);
            setIds([Number(instid), state.staffId]);
          }
          if (userType === UserType.STUDENT && state.studentId) {
            setbook_query_type(LibBookMasterQueryType.BOOKS_ISSUED_TO_STUDENT);
            setIds([Number(instid), state.studentId]);
          }
          if (userType === BooksBy.ACCESS_NO) {
            setbook_query_type(LibBookMasterQueryType.BOOKS_TO_RETURN);
            setIds([Number(instid)]);
          }
          break;
        case BooksReportType.BOOKS_RETURNED:
          if (userType === UserType.EMPLOYEE && state.staffId) {
            setbook_query_type(LibBookMasterQueryType.BOOKS_RETURNED_BY_STAFF);
            setIds([Number(instid), state.staffId]);
          }
          if (userType === UserType.STUDENT && state.studentId) {
            setbook_query_type(
              LibBookMasterQueryType.BOOKS_RETURNED_BY_STUDENT
            );
            setIds([Number(instid), state.studentId]);
          }
          break;
        case BooksReportType.BOOKS_TO_BE_ISSUED:
          setbook_query_type(LibBookMasterQueryType.BOOKS_TO_ISSUE);
          setIds([Number(instid)]);
          break;
        case BooksReportType.BOOKS_BY_RACK_ID:
          if (id) {
            setbook_query_type(LibBookMasterQueryType.BOOKS_BY_RACK_ID);
            setIds([Number(instid), id]);
          }
          break;
        case BooksReportType.BOOKS_BY_DEPT_ID:
          if (id) {
            setbook_query_type(LibBookMasterQueryType.BOOKS_BY_DEPT_ID);
            setIds([Number(instid), id]);
          }
          break;
        case BooksReportType.BOOKS_THAT_ARE_DAMAGED:
          setbook_query_type(LibBookMasterQueryType.BOOKS_THAT_ARE_DAMAGED);
          setIds([Number(instid)]);
          break;
        case BooksReportType.BOOKS_ON_SHELF:
          setbook_query_type(
            LibBookMasterQueryType.BOOKS_ON_SHELF_BY_CLASSIFICATION_ID
          );
          setIds([Number(instid), state.classificationId]);
          break;
        case BooksReportType.BOOKS_BY_CLASSIFICATION:
          setbook_query_type(LibBookMasterQueryType.BOOKS_BY_CLASSIFICATION_ID);
          setIds([Number(instid), state.classificationId]);
          break;
        case BooksReportType.BOOKS_ISSUED_BY_CLASSIFICATION:
          setbook_query_type(
            LibBookMasterQueryType.BOOKS_ISSUED_BY_CLASSIFICATION_ID
          );
          setIds([Number(instid), state.classificationId]);
          break;
        default:
          break;
      }
    }
  }, [
    userType,
    queryType,
    state.staffId,
    state.studentId,
    state.classificationId,
    id,
    instid,
  ]);

  useEffect(() => {
    if (
      book_query_type !== LibBookMasterQueryType.NOT_SELECTED &&
      token &&
      InstId
    ) {
      GetBooks().then(({ data }) => {
        if (data) {
          setBookEntries(
            data.GetLibBookMaster?.edges?.map(({ node }) => ({
              label: node.book_access_no,
              value: node.id,
            }))
          );
        }
      });
    }
  }, [
    GetBooks,
    book_query_type,
    token,
    InstId,
    state.staffId,
    state.studentId,
  ]);

  useEffect(() => {
    if (data && queryType === BooksReportType.BOOKS_ISSUED) {
      GetIssueBookDetails({
        variables: {
          token,
          ids: data.GetLibBookMaster?.edges?.map(({ node }) => node.id),
        },
      });
    }
  }, [GetIssueBookDetails, data, token, queryType]);

  return {
    bookEntries: {
      bookEntriesDropDown: bookEntries,
      issuedBooksData: {
        data: IssuedBookDetails,
        error: IssuedBookDetailsError,
        loading: IssuedBookDetailsLoading,
      },
      queryData: {
        data,
        error,
        loading,
        fetchMore,
      },
    },
  };
};

export default useBookEntryOptions;

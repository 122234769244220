import { gql } from "@apollo/client";

export const SectionsBySemIds = gql`
  query GetSectionsBySemesterIds($sem_ids: [ID!]!, $token: String!) {
    GetSectionsBySemesterIds(token: $token, semester_ids: $sem_ids) {
      id
      section_desc
      section_idx
      section_short_desc
    }
  }
`;

export const GetSectionsForScanBySemesterIds = gql`
  query GetSectionsForScanBySemesterIds($sem_ids: [ID!]!) {
    GetSectionsForScanBySemesterIds(semester_ids: $sem_ids) {
      id
      section_desc
      section_idx
      section_short_desc
    }
  }
`;
export const BySemIds = gql`
  query GetSectionNamesBySemesterIds($sem_id: [ID!]!, $token: String!) {
    GetSectionNamesBySemesterIds(token: $token, semester_id: $sem_id) {
      id
      semester_id
      section_desc
    }
  }
`;

export const SectionDetailsByNode = gql`
  query SectionDetailsByNode($token: String!, $section_id: ID!) {
    node(id: $section_id, token: $token) {
      ... on MstInstSection {
        id
        inst_id
        semester_id
        section_desc
        section_short_desc
        section_idx
      }
    }
  }
`;
export const SectionByInstId = gql`
  query GetSectionsByInstId($token: String!, $inst_id: ID!) {
    GetSectionsByInstId(token: $token, inst_id: $inst_id) {
      section_desc
      id
    }
  }
`;

export const SectionCountBySectionId = gql`
  query GetSectionCountBySemesterId($token: String!, $semester_id: ID!) {
    GetSectionCountBySemesterId(token: $token, semester_id: $semester_id)
  }
`;

import { useContext, useEffect } from "react";
import { CheckPerStdSubjAllocAtEntryLevel } from "../../../queries/customallocation/combination/queries";
import {
  CheckPerStdSubjAllocAtEntryLevelData,
  CheckPerStdSubjAllocAtEntryLevelVars,
} from "../../../Types/Combinations/queries";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../utils/constants";
import useAcdLevel from "../../Accounts/hooks/useAcdLevel";

const useCheckAllocationType = (entry_id?: number) => {
  const { token } = useToken();
  const { InstId, entryId, allotedID } = useParams();
  const { state } = useContext(AppContext);

  const { data: acdLevelData } = useAcdLevel(
    entry_id ? entry_id.toString() : entryId ? entryId! : allotedID!
  );
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const subjectAllocLevel =
    configData.data && configData.data.GetSwConfigVariables.length
      ? configData.data.GetSwConfigVariables[0].config_string_value
      : EMPTY_STRING;

  const [CheckPerStdConfig, { data }] = useLazyQuery<
    CheckPerStdSubjAllocAtEntryLevelData,
    CheckPerStdSubjAllocAtEntryLevelVars
  >(CheckPerStdSubjAllocAtEntryLevel, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: entry_id
        ? entry_id
        : entryId
        ? Number(entryId)!
        : Number(allotedID),
      entry_level: acdLevelData ? acdLevelData.GetAcdLevel : EMPTY_STRING,
      inst_id: InstId! ?? state.InstId,
      token,
    },
  });

  useEffect(() => {
    if (token && (entry_id || entryId || allotedID) && acdLevelData) {
      CheckPerStdConfig();
    }
  }, [
    CheckPerStdConfig,
    InstId,
    state.ActiveAcdYr,
    entryId,
    token,
    allotedID,
    subjectAllocLevel,
    acdLevelData,
    entry_id,
    state.InstId,
  ]);
  return {
    data,
    flag: data && data.CheckPerStdSubjAllocAtEntryLevel,
    acdLevel: acdLevelData ? acdLevelData.GetAcdLevel : EMPTY_STRING,
  };
};

export default useCheckAllocationType;

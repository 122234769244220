import React from "react";
export const enum CalendarFor {
  GENERAL = "GENERAL",
  ATTENDANCE = "ATTENDANCE",
  PER_STUDENT_ATTENDANCE = "PER_STUDENT_ATTENDANCE",
  DAILY_DIARY = "DAILY_DIARY",
}
interface Props {
  useCalendarIn: CalendarFor;
}

const CalendarLegends = ({ useCalendarIn }: Props) => {
  return (
    <>
      <div className="customized-calendar--legends">
        {useCalendarIn === CalendarFor.ATTENDANCE ? (
          <>
            <div className="customized-calendar--legends--outline">
              <div className="customized-calendar--legends--marked">
                <span className="customized-calendar--legends--box--date">
                  1
                </span>
              </div>
              &rarr;
              <span className="customized-calendar--legends--box--text">
                Marked
              </span>
            </div>
            <div className="customized-calendar--legends--outline">
              <div className="customized-calendar--legends--un-marked">
                <span className="customized-calendar--legends--box--date">
                  1
                </span>
              </div>
              &rarr;
              <span className="customized-calendar--legends--box--text">
                UnMarked
              </span>
            </div>
          </>
        ) : useCalendarIn === CalendarFor.PER_STUDENT_ATTENDANCE ? (
          <>
            <div className="customized-calendar--legends--outline">
              <div className="customized-calendar--legends--marked">
                <span className="customized-calendar--legends--box--date">
                  1
                </span>
              </div>
              &rarr;
              <span className="customized-calendar--legends--box--text">
                Present
              </span>
            </div>
            <div className="customized-calendar--legends--outline">
              <div className="customized-calendar--legends--un-marked">
                <span className="customized-calendar--legends--box--date">
                  1
                </span>
              </div>
              &rarr;
              <span className="customized-calendar--legends--box--text">
                Absent
              </span>
            </div>
          </>
        ) : useCalendarIn === CalendarFor.DAILY_DIARY ? (
          <>
            <div className="customized-calendar--legends--outline">
              <div className="customized-calendar--legends--marked">
                <span className="customized-calendar--legends--box--date">
                  1
                </span>
              </div>
              &rarr;
              <span className="customized-calendar--legends--box--text">
                Filled
              </span>
            </div>
            <div className="customized-calendar--legends--outline">
              <div className="customized-calendar--legends--un-marked">
                <span className="customized-calendar--legends--box--date">
                  1
                </span>
              </div>
              &rarr;
              <span className="customized-calendar--legends--box--text">
                Not Filled
              </span>
            </div>
          </>
        ) : null}
   
      </div>
    </>
  );
};

export default CalendarLegends;

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../utils/UtilFunctions";
import { Title } from "../../../stories/Title/Title";

import { Button } from "../../../stories/Button/Button";
import { AppContext } from "../../../context/context";
import View from "../MasterData/AssignRoute/View";

import { TextField } from "@mui/material";

import Input from ".././../../stories/Input/Input";
import {
  StudentStatus,
  TableHeaders,
  StudentSearchField,
  StudentReportType,
  PageFor,
  Operation,
} from "../../../utils/Enum.types";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { StudentsTitlesType } from "../../../Types/Student/json";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import AssignRouteToStudent from "../MasterData/AssignRoute/ToStudent";
import Home from "../Home/Index";
import Modal from "react-modal";
import { Keys } from "../../../utils/Enum.keys";

import { responseType } from "../../../utils/Form.types";
import { payloadTypes } from "../../../context/reducer";
import Close from "../../../images/Close.svg";
import { StudentModalStyles } from "../../../styles/ModalStyles";

import useInstLabels from "../../../customhooks/general/useInstLabels";
import useAcdDropDownData from "../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentAcdNode,
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
const { Students } = require("../../../json/title.json");
interface Props {
  pageType: PageFor;
  setStudentModal?: React.Dispatch<React.SetStateAction<boolean>>;
  queryType: StudentReportType;
}
const StudentList = ({ pageType, setStudentModal, queryType }: Props) => {
  const [assignRouteDetails, setAssignRouteDetails] = useState(false);

  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [searchData, setSearchData] = useState("");
  const [searchAdm, setSearchAdm] = useState("");
  const [searchType, setSearchType] = useState("");

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [viewModal, setViewModal] = useState(false);
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const sectionRef = useRef<HTMLSelectElement>(null);
  const sectionInputRef = sectionRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    queryType,
    EMPTY_STRING,
    []
  );

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const clear = () => {
    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSearchAdm("");
    setSearchData("");
    setSearchType("");
  };

  const SearchFields = [
    StudentSearchField.ADMISSION_NUMBER,
    StudentSearchField.NAME,
  ];

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData?.loading) {
        AcademicsStudentData?.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount:
                  AcademicsStudentData?.data?.GetAcdStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const Row = (props: { row: StudentAcdNode; sl: number }) => {
    const FetchStudentStatusClassName = (row: StudentStatus | string) => {
      switch (row) {
        case StudentStatus.CUR:
          return "std_status--cur";
        case StudentStatus.TC:
          return "std_status";
        case StudentStatus.DET:
          return "std_status--det";
        case StudentStatus.SOA:
          return "std_status--soa";
        case StudentStatus.NXT:
          return "std_status--nxt";
        case StudentStatus.NE:
          return "std_status--ne";
        default:
          break;
      }
    };
    const { row, sl } = props;

    return (
      <React.Fragment key={row.id}>
        <TableRow>
          <TableCell id="td-center" className="studentlist__table--slno">
            {sl}
          </TableCell>

          <TableCell className="studentlist__table--admno">
            {row.std_adm_no}
          </TableCell>

          <TableCell className="studentlist__table--admno">
            {row.std_reg_no}
          </TableCell>
          <TableCell
            onClick={() => {
              dispatch({
                type: payloadTypes.SET_STUDENT_ID,
                payload: {
                  studentId: row.id,
                },
              });
              pageType === PageFor.GENERAL
                ? setViewModal(!viewModal)
                : setStudentModal?.(false);
            }}
          >
            {row.first_name + " " + row.middle_name + " " + row.last_name}
          </TableCell>

          <TableCell className="studentlist__table--status">
            {row.acd_branch.branch_desc}
          </TableCell>
          <TableCell className="studentlist__table--status">
            {row.acd_class.class_desc}
          </TableCell>

          <TableCell
            className={FetchStudentStatusClassName(row.std_status)}
            id="td-center"
          >
            {row.std_status}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  useEffect(() => {
    if (AcademicsStudentData?.data && !AcademicsStudentData?.loading) {
      const newData = AcademicsStudentData?.data.GetAcdStudents.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: true,
          },
        })
      );

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(
        AcademicsStudentData?.data.GetAcdStudents.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [AcademicsStudentData?.data, AcademicsStudentData?.loading]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  return (
    <div
      className={
        pageType === PageFor.GENERAL ? "studentlist" : "studentlist--modal"
      }
    >
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {Students.Titles.map(
              (title: StudentsTitlesType, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.TransportList}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 studentlist__options"
            : "row g-0 studentlist__options--modal"
        }
      >
        <div className="col-1 studentlist__select-flex studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (searchType === StudentSearchField.ADMISSION_NUMBER) {
                setSearchAdm(e.target.value);
                setSearchData("");
              } else {
                setSearchData(e.target.value);
                setSearchAdm("");
              }
            }}
            value={searchData !== "" ? searchData : searchAdm}
            onKeyDown={handleFormEvent}
          />
        </div>
        <div className="col-1">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={SearchFields}
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                e.preventDefault();
                departmentInputRef?.focus();
              }
              if (e.key === Keys.BACKSPACE) {
                setSearchType("");
              }
            }}
            openOnFocus
            value={searchType}
            onChange={(e, newValue) =>
              setSearchType(newValue as StudentSearchField)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search By"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                id="outlined Departments"
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              ref={departmentRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    branchInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              ref={branchRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    classInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              ref={classRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected) {
                    semInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                } else {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={semesterDropDown}
              ref={semRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (semesterSelected) {
                    sectionInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue as responseType);
                } else {
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={semesterLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SECTION_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={sectionDropDown}
              ref={sectionRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    categoryInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue as responseType);
                } else {
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}

        <div className="col"></div>
        <div className="col-1">
          <Button
            mode="clear"
            onClick={(e: React.FormEvent) => {
              e.preventDefault();
              clear();
            }}
            className={
              pageType === PageFor.GENERAL
                ? "student-clear-button--acd"
                : "student-clear-button--modal"
            }
          />
        </div>
      </div>

      <div
        id={
          pageType === PageFor.MODAL
            ? "studentlist__transport--modal"
            : "studentlist__transport"
        }
      >
        {!students?.length ? (
          <b className="nodata">Sorry, No Students Found</b>
        ) : (
          <TableContainer
            className="studentlist__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="studentlist__table--slno">
                    {TableHeaders.SLNO}
                  </TableCell>

                  <TableCell className="studentlist__table--admno">
                    {TableHeaders.ADMISSION_NUMBER}
                  </TableCell>

                  <TableCell className="studentlist__table--admno">
                    {TableHeaders.REGISTER_NUMBER}
                  </TableCell>

                  <TableCell className="studentlist__table--name">
                    {TableHeaders.NAME}
                  </TableCell>

                  <TableCell className="studentlist__table--status">
                    {branchLabel}
                  </TableCell>

                  <TableCell className="studentlist__table--status">
                    {classLabel}
                  </TableCell>

                  <TableCell className="studentlist__table--status">
                    {TableHeaders.STATUS}
                  </TableCell>
                  {/* <TableCell className="studentlist__table--status">
                    {TableHeaders.ACTION}
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((response, index: number) => (
                  <Row key={index} row={response.node} sl={index + 1} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className="studentlist__buttons">
        {pageType === PageFor.GENERAL ? (
          <div className="button-left">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        ) : (
          <Button mode="cancel" onClick={() => setStudentModal?.(false)} />
        )}
      </div>

      {/* student-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={assignRouteDetails}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AssignRouteToStudent
              pageType={PageFor.MODAL}
              operation={Operation.CREATE}
              setModalFlag={setAssignRouteDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setAssignRouteDetails(!assignRouteDetails)}
            />
          </div>
        </div>
      </Modal>
      {/* view-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View modalFlag={setViewModal} pageType={PageFor.MODAL} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StudentList;

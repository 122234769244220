import { gql } from "@apollo/client";

export const GetSwConfigStudentDataEntryByInstId = gql`
  query GetSwConfigStudentDataEntryByInstId($token: String!, $inst_id: ID!) {
    GetSwConfigStudentDataEntryByInstId(token: $token, inst_id: $inst_id) {
      id
      data_field_name
      data_field_type
      data_used_in_table
      data_field_label
      data_field_enable
      data_field_index_order
      data_field_is_list_data
      data_field_list_data_key
      data_field_default_used
      data_field_block_number
      inst_id
    }
  }
`;

export const CheckIfInstConfigComplete = gql`
  query CheckIfInstConfigComplete($token: String!, $inst_id: ID!) {
    CheckIfInstConfigComplete(token: $token, inst_id: $inst_id)
  }
`;
export const GetMobileDevicesDetails = gql`
  query GetMobileDevicesDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    GetMobileDevicesDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
    ) {
      device_id
      brand
      model
      last_login_date
    }
  }
`;
export const GetBranchAdmNumberByBranchId = gql`
  query GetBranchAdmNumberByBranchId(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
  ) {
    GetBranchAdmNumberByBranchId(
      token: $token
      inst_id: $inst_id
      branch_id: $branch_id
    ) {
      id
      adm_start_no
      adm_current_no
      is_prefixed
      prefix_str
      suffix_str
      separator
      prefill_with_zero
      prefill_width
      inst_id
      branch_id
    }
  }
`;
export const GetAccessionNumberConfig = gql`
  query GetAccessionNumberConfig(
    $token: String!
    $inst_id: ID!
    $lib_classfication_id: ID!
  ) {
    GetAccessionNumberConfig(
      token: $token
      inst_id: $inst_id
      lib_classfication_id: $lib_classfication_id
    ) {
      id
      acc_start_no
      acc_current_no
      is_prefixed
      prefix_str
      suffix_str
      separator
      prefill_with_zero
      prefill_width
      inst_id
      lib_classfication_id
    }
  }
`;
export const GetInstAdmNumberByInstId = gql`
  query GetInstAdmNumberByInstId($token: String!, $inst_id: ID!) {
    GetInstAdmNumberByInstId(token: $token, inst_id: $inst_id) {
      id
      adm_start_no
      adm_current_no
      is_prefixed
      prefix_str
      suffix_str
      separator
      prefill_with_zero
      prefill_width
      inst_id
    }
  }
`;
export const GetBranchWiseAdmissionNumber = gql`
  query GetBranchWiseAdmissionNumber(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
  ) {
    GetBranchWiseAdmissionNumber(
      token: $token
      inst_id: $inst_id
      branch_id: $branch_id
    )
  }
`;
export const GetInstWiseAdmissionNumber = gql`
  query GetInstWiseAdmissionNumber($token: String!, $inst_id: ID!) {
    GetInstWiseAdmissionNumber(token: $token, inst_id: $inst_id)
  }
`;

//PayRoll
export const GetPayRollEmpIdConfig = gql`
  query GetPayRollEmpIdConfig($token: String!, $inst_id: ID!) {
    GetPayRollEmpIdConfig(token: $token, inst_id: $inst_id) {
      id
      emp_id_start_no
      emp_id_current_no
      is_prefixed
      prefix_str
      suffix_str
      separator
      prefill_with_zero
      prefill_width
    }
  }
`;

export const GetSwConfigStdDataFields = gql`
  query GetSwConfigStdDataFields($token: String!, $inst_id: ID!) {
    GetSwConfigStdDataFields(token: $token, inst_id: $inst_id) {
      data_field_block_name
      field_details {
        data_field_name
        data_field_type
        data_used_in_table
        data_field_label
        data_field_enable
        data_field_index_order
        data_field_is_list_data
        data_field_list_data_key
        data_field_default_used
        data_field_block_number
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const LateFeePermission = gql`
  mutation LateFeePermission(
    $token: String!
    $fin_yr_id: ID!
    $student_id: ID!
    $input: LateFeePermissionData!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    LateFeePermission(
      token: $token
      fin_yr_id: $fin_yr_id
      student_id: $student_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

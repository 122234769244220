import React, { useState } from "react";
import Modal from "react-modal";
import AttendanceModal from "./Modal/Attendance";
import More from "../../../../images/More.svg";
import TotalClasses from "../../../../images/TotalClasses.svg";
import AttendanceMarked from "../../../../images/ClassAttMarked.svg";
import AttendanceNotMarked from "../../../../images/ClassAttNotMarked.svg";
import { Title } from "../../../../stories/Title/Title";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
const Attendance = () => {
  const [attendanceModal, setAttendanceModal] = useState(false);
  return (
    <>
      <div className="teacher-dashboard__flex">
        <b className="teacher-dashboard__flex--title">Attendance Status </b>
        <div className="teacher-dashboard__flex--image">
          <button
            className="teacher-dashboard__flex--mark"
            onClick={() => setAttendanceModal(!attendanceModal)}
          >
            Mark
          </button>
          <img src={More} alt="" />
        </div>
      </div>
      <div className="teacher-dashboard__details">
        <div className="teacher-dashboard__details--flex">
          <div className="teacher-dashboard__details--image-flex">
            <img src={TotalClasses} alt="" />
            <Title variant="subtitle2">Total Classes</Title>
          </div>
          <b className="font-blue">0</b>
        </div>
        <div className="teacher-dashboard__details--flex">
          <div className="teacher-dashboard__details--image-flex">
            <img src={AttendanceMarked} alt="" />
            <Title variant="subtitle2">Attendance Marked</Title>
          </div>
          <b className="font-green">0</b>
        </div>
        <div className="teacher-dashboard__details--flex">
          <div className="teacher-dashboard__details--image-flex">
            <img src={AttendanceNotMarked} alt="" />
            <Title variant="subtitle2">Attendance Not Marked</Title>
          </div>
          <b className="font-blue">0</b>
        </div>
      </div>
      <Modal
        isOpen={attendanceModal}
        onRequestClose={() => setAttendanceModal(!attendanceModal)}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <AttendanceModal setModalFlag={setAttendanceModal} />
      </Modal>
    </>
  );
};

export default Attendance;

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import "react-phone-input-2/lib/style.css";

import PreviewTabs from "./ReservationPreviewTabs";

import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";

import Home from "../../Home/Index";

import DocsUploadComponent from "../Registration/DocsUploadComponent";

interface Props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const NxtDocsPreview = ({ pageType, setModal }: Props) => {
  const { InstId, studentId } = useParams();

  const navigate = useNavigate();

  const className = `docs_preview${
    pageType === PageFor.GENERAL ? "" : "__modal"
  }`;

  return (
    <>
      {pageType === PageFor.GENERAL ? (
        <>
          <Home DashBoardRequired={false} />
          <PreviewTabs pageType={PageFor.GENERAL} />
        </>
      ) : null}

      <div className={className}>
        <StudentAcademicDetails />
        <div className={`${className}__tableblock`}>
          <DocsUploadComponent type={Operation.VIEW} />
        </div>
        {pageType === PageFor.GENERAL ? (
          <Button
            mode="edit"
            onClick={() =>
              navigate(
                `/${InstId}/admissions/students/5/${studentId}/defaultDocsUpdate`
              )
            }
          />
        ) : (
          <Button mode="cancel" onClick={() => setModal(false)} />
        )}
      </div>
    </>
  );
};

export default NxtDocsPreview;

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import DownArrow from "../../../../images/DownArrow.svg";
import NotDone from "../../../../images/MarksEntryNotCompleted.svg";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../../styles/TooltipStyles";

const Subjects = [
  { sub: "Mathematics" },
  { sub: "Science" },
  { sub: "Social" },
  { sub: "Hindi" },
  { sub: "English" },
];

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const tableData = {
  Electives: [
    {
      name: "Open Elective1",

      Subjects: [
        {
          name: "Mathematics",
        },
        {
          name: "Social Science",
        },
      ],
    },
    {
      name: "Open Elective2",

      Subjects: [
        {
          name: "Kannada",
        },
        {
          name: "English",
        },
      ],
    },
  ],
};
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const ChooseSubjects = ({ setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { InstId } = useParams();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = Subjects.map((n) => n.sub);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {
      onSelectAllClick,

      numSelected,
      rowCount,
    } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          <TableCell>Select Subjects</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const [enableI, setEnableI] = useState(false);
  const [collapseFlag, setCollapseFlag] = useState<number[]>([]);
  const [disableElective, setDisableElective] = useState(false);
  return (
    <div className="academics__exam--choose-sub">
      <Title>Choose Subjects for Marks Entry</Title>
      <div className="row g-0 academics__exam--choose-sub--tableblock">
        <div className="col h-100">
          <b className="nodata"></b>
          <TableContainer className="academics__exam--choose-sub--table">
            <Table stickyHeader>
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={Subjects.length}
              />
              <TableBody>
                {Subjects.map((data) => {
                  const isItemSelected = isSelected(data.sub);

                  return (
                    <TableRow
                      key={data.sub}
                      onClick={(event) => handleClick(event, data.sub)}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell id="td-center">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => {
                            handleClick(event, data.sub);
                            setDisableElective(!disableElective);
                          }}
                        />
                      </TableCell>
                      <TableCell className="academics__exam--choose-sub--table--subjects">
                        {data.sub}
                        {/* if Marks entry is already completed enable this icon 
                        <Tooltip
                          title="Marks Entry Completed"
                          arrow
                          placement="right"
                          classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow,
                          }}
                        >
                          <img src={Done} alt="/" />
                        </Tooltip> */}
                        {/* if not done */}
                        <CustomTooltip
                          title="Marks Entry Not Completed"
                          arrow
                          placement="right"
                          slotProps={{
                            tooltip: {
                              sx: {
                                bgcolor: TOOLTIP_COLORS.VARIABLE,
                                "& .MuiTooltip-arrow": {
                                  color: TOOLTIP_COLORS.VARIABLE,
                                },
                              },
                            },
                          }}
                        >
                          <img src={NotDone} alt="/" />
                        </CustomTooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="col h-100">
          <b className="nodata">
            {!disableElective ? (
              <>You Can Select Only One Elective Subject</>
            ) : (
              <>
                You Can't Select Elective Subject along with Common Subjects it
                should Select Individually
              </>
            )}
          </b>
          <TableContainer className="academics__exam--choose-sub--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Elective Subjects</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.Electives &&
                  tableData.Electives.map((parentdata, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell className="academics__exam--choose-sub--table--parent-row">
                            <span>{parentdata.name}</span>
                            <div className="academics__exam--choose-sub--table--parent-row--image">
                              {collapseFlag.includes(index) && (
                                <>
                                  <b>{parentdata.Subjects.length} Subjects</b>
                                  {/* if Marks entry is already completed enable this icon  */}
                                  {/* 
                                  <Tooltip
                                    title="Marks Entry Completed"
                                    arrow
                                    placement="right"
                                    classes={{
                                      tooltip: classes.customTooltip,
                                      arrow: classes.customArrow,
                                    }}
                                  >
                                    <img src={Done} alt="/" />
                                  </Tooltip> */}
                                  {/* if not done */}
                                  <CustomTooltip
                                    title="Marks Entry Completed"
                                    arrow
                                    placement="right"
                                    slotProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: TOOLTIP_COLORS.VARIABLE,
                                          "& .MuiTooltip-arrow": {
                                            color: TOOLTIP_COLORS.VARIABLE,
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    <img src={NotDone} alt="/" />
                                  </CustomTooltip>
                                </>
                              )}
                              <img
                                src={DownArrow}
                                alt="/"
                                onClick={() => {
                                  setEnableI(!enableI);

                                  if (!collapseFlag.includes(index)) {
                                    setCollapseFlag((p) => [...p, index]);
                                  } else {
                                    collapseFlag.splice(
                                      collapseFlag.indexOf(index),
                                      1
                                    );
                                  }
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {!collapseFlag.includes(index) && (
                          <>
                            {parentdata.Subjects?.map((sub, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  className={
                                    disableElective
                                      ? "academics__exam--choose-sub--table--disabled-row"
                                      : "academics__exam--choose-sub--table--link"
                                  }
                                  onClick={(e) => {
                                    !disableElective
                                      ? navigate(
                                          `/${InstId}/academics/dailyactivities/exams/entermarks`
                                        )
                                      : e.stopPropagation();
                                  }}
                                >
                                  <TableCell className="academics__exam--choose-sub--table--subjects">
                                    {sub.name}
                                    {/* if Marks entry is already completed enable this icon 
                                    <Tooltip
                                      title="Marks Entry Completed"
                                      arrow
                                      placement="right"
                                      classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow,
                                      }}
                                    >
                                 
                                      <img src={Done} alt="/" />
                                    </Tooltip> */}

                                    {/* if not done */}
                                    <CustomTooltip
                                      title="Marks Entry Not Completed"
                                      arrow
                                      placement="right"
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                                            "& .MuiTooltip-arrow": {
                                              color: TOOLTIP_COLORS.VARIABLE,
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      <img src={NotDone} alt="/" />
                                    </CustomTooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Button mode="select" />
      <Button mode="clear" />
      <Button mode="cancel" onClick={() => setModalFlag(false)} />
    </div>
  );
};

export default ChooseSubjects;

import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { GetSwConfigVariablesPageAssociByPageNumber } from "../queries/institution/configuration/query/SoftwreConfig";
import {
  GetSwConfigVariablesPageAssociByPageNumberData,
  SoftwareVars,
} from "../Types/configtypes";
import useToken from "./useToken";

const usePageDetailsByPageNumber = (pagenumber: number) => {
  const { InstId } = useParams();

  const { token } = useToken();

  const [GetSwConfigVariablesPageNumber, { data, loading, error }] =
    useLazyQuery<GetSwConfigVariablesPageAssociByPageNumberData, SoftwareVars>(
      GetSwConfigVariablesPageAssociByPageNumber,
      {
        variables: { token, page_number: pagenumber, inst_id: InstId },
      }
    );
  useEffect(() => {
    //Manjunath: changed to lazy query should execute only if there is page number
    if (pagenumber > 0) GetSwConfigVariablesPageNumber();
  }, [GetSwConfigVariablesPageNumber, pagenumber]);

  return {
    data: data?.GetSwConfigVariablesPageAssociByPageNumber,
    GetSwConfigVariablesPageAssociByPageNumberLoading: loading,
    GetSwConfigVariablesPageAssociByPageNumberError: error,
  };
};

export default usePageDetailsByPageNumber;

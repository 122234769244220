import { useContext, useEffect, useState } from "react";

import * as XLSX from "xlsx";

// import Home from "../../Home/Index";
// import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../../images/TotalMale.svg";
import ReadyToImport from "../../../images/Present.svg";
import ContainsError from "../../../images/Absent.svg";
import Info from "../../../images/Information.svg";
import DownArrow from "../../../images/DownArrow.svg";
import FileAttach from "../../../images/BrowseFiles.svg";

import ExcelJS from "exceljs";
import { Cell } from "exceljs";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { ValidateStudentDataForImport } from "../../../../queries/xls";

// import { ImportStudentDataFromXls } from "../../../../queries/xls/mutation";
import {
  getHeaderRowStyle,
  handleClear,
  toStandardDate,
} from "../../../utils/UtilFunctions";
// import { _genderRegex, _isPhone } from "../../../../utils/validationRules";
import {
  ACC_HEADER_FONT,
  BLOB_TYPE,
  DOWNLOAD,
  ELEMENT,
  FIRST_INDEX,
  TABLE_HEADER_CSS,
  FROZEN_CELLS_1,
  FILENAME,
  EDUATE_IMG_FORMAT,
  FIRST_CELL,
  HEADER_CSS,
  HEADER_ALIGNMENT,
  A2_CELL,
  A3_CELL,
  ADDRESS_FONT,
  ADDRESS_ALIGNMENT,
  C4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  HEADER_ALIGNMENT_CENTER,
  E4_CELL,
  FIN_YEAR_FONT,
  A4_CELL,
  HEADER_ALIGNMENT_LEFT,
  FROZEN_CELLS_INST,
} from "../../Library/Constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  Operation,
  TableHeaders,
} from "../../../utils/Enum.types";
import { Button } from "../../../stories/Button/Button";
import LoadingModal from "../../../pages/LoadingModal";
import { Title } from "../../../stories/Title/Title";
import { PayRollMasters } from "../enums/Enum.types";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import useMasterMutations from "./utils/useMasterMutations";
import { getMasterDetails } from "./utils/GetMasterDetails";
import Eduate from "../../../images/Eduate_Logo_image.png";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import { AppContext } from "../../../context/context";
import { CustomTooltip } from "../../../styles/TooltipStyles";

interface Props {
  MasterType: PayRollMasters;
}
const MastersImport = ({ MasterType }: Props) => {
  const { state } = useContext(AppContext);

  const [expanded, setExpanded] = useState(false);
  const [records, setRecords] = useState<any[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    operation: Operation.NONE,
    flag: false,
  });
  const { InstDetails } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { serverDate } = useServerDateandTime();
  const { handleSubmit, loading } = useMasterMutations(MasterType, records);

  const InstName = InstDetails.data?.nodes[0]?.inst_name!;

  const { header, schema, errorData, sheetname } =
    getMasterDetails(MasterType, records) || {};
  const downloadExcel = (forError: boolean) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet(sheetname);
    worksheet.views = FROZEN_CELLS_INST;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    worksheet1.views = FROZEN_CELLS_1;
    worksheet1.getColumn(1).width = 30;
    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                  const rowData: Cell = worksheet1.getCell(Char + 1);
                  rowData.value = header?.Headers;
                  rowData.fill = TABLE_HEADER_CSS;
                  rowData.font = ACC_HEADER_FONT;
                  rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  let startCell = 2;
                  let excel_name = InstName;

                  if (forError) {
                    errorData?.forEach((errData, index) => {
                      const cell_number = startCell + index;
                      const rowErrorData: Cell = worksheet1.getCell(
                        `A${cell_number}`
                      );
                      rowErrorData.value = errData?.desc;
                    });
                    excel_name = `${InstName}errors`;
                  }
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, excel_name);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];
    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      schema &&
        // eslint-disable-next-line
        Object.keys(schema).map((key) => {
          // @ts-ignore
          let keySchema = schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleButtonClick = async () => {
    try {
      await Promise.all(records.map((record) => handleClick(record.id)));
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (id: number) => {
    const filteredData = records.find((record) => record.id === id);
    if (filteredData) {
      const newData = records.map((record, index) => {
        if (id === record.id) {
          return {
            ...record,
            isChecked: !record?.isChecked,
          };
        }
        return record;
      });
      setRecords(newData);
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };

  useEffect(
    () => {
      const input = document.getElementById("input") as HTMLInputElement;
      if (input) {
        input.addEventListener("change", () => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const res = processExcel(reader.result);
            setRecords(
              res.map((f: any, index: number) => {
                return {
                  ...f,
                  id: index + 1,
                  isChecked: false,
                };
              })
            );
          };
          reader.readAsBinaryString(input.files![0]);
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {/* <Home DashBoardRequired={false} /> */}
      <Title>Import {header?.Headers} Data</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total {header?.Headers}
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          <TableCell id="td-center">Department</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records.map((record, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell id="td-center">
                                {index + 1}
                              </TableCell>

                              {schema && // eslint-disable-next-line
                                Object.keys(schema).map(
                                  // eslint-disable-next-line
                                  (key, index: React.Key) => {
                                    // @ts-ignore
                                    const fieldSchema = schema[key];

                                    if (
                                      fieldSchema.required ||
                                      fieldSchema.required === undefined
                                    )
                                      return (
                                        <TableCell key={index}>
                                          {record[key]}
                                        </TableCell>
                                      );
                                  }
                                )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && records.filter((record) => record?.errors) && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          <TableCell id="td-center">Category</TableCell>
                          <TableCell id="td-center">
                            Error Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => {
                            const filteredHeaders: string[] = [];
                            return (
                              <TableRow key={index}>
                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                {Object.keys(schema!).map((key, index) => {
                                  // @ts-ignore
                                  const fieldSchema = schema[key];
                                  if (
                                    fieldSchema.required ||
                                    fieldSchema.required === undefined
                                  ) {
                                    filteredHeaders.push(key);
                                    return (
                                      <TableCell key={index}>
                                        {record[key]}
                                      </TableCell>
                                    );
                                  }
                                  return null;
                                })}

                                <TableCell className="import-excel-data__datablock--error">
                                  <CustomTooltip
                                    title={
                                      <ul>
                                        {record?.errors
                                          ? record?.errors?.map(
                                              (error: any, index: number) => {
                                                return (
                                                  <li key={index}>
                                                    {index + 1})&nbsp;
                                                    {typeof error === "string"
                                                      ? error
                                                      : "Error message = " +
                                                        error.error +
                                                        ", column = " +
                                                        error.column}
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <div className="import-excel-data__datablock--tableblock--error-message">
                                      <span>Errors</span>
                                      <img src={Info} alt="/" />
                                    </div>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button
              onClick={handleSubmit}
              mode="save"
              disabled={
                !records.filter((record) => record?.isChecked).length ||
                !records.length
                  ? true
                  : false
              }
            />
            <Button onClick={handleButtonClick}>Validate</Button>

            <Button onClick={handleClear} mode="clear" />

            <Button onClick={() => downloadExcel(true)} mode="export">
              Export error Data
            </Button>
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={() => downloadExcel(false)}>
              Generate Template
            </Button>
          </div>
        </div>
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default MastersImport;

export enum InstitutionPrintConfig {
  PRINT_ADMISSION_NUMBER = "PRINT_ADMISSION_NUMBER",
  PRINT_CASH_BANK_DETAILS = "PRINT_CASH_BANK_DETAILS",
  PRINT_CLASS_NAME = "PRINT_CLASS_NAME",
  PRINT_CUSTOMER_NAME = "PRINT_CUSTOMER_NAME",
  PRINT_FATHER_NAME = "PRINT_FATHER_NAME",
  PRINT_FEE_BALANCE = "PRINT_FEE_BALANCE",
  PRINT_INST_LOGO = "PRINT_INST_LOGO",
  PRINT_INST_NAME = "PRINT_INST_NAME",
  PRINT_INST_ADDRESS = "PRINT_INST_ADDRESS",
  PRINT_NARRATIONS = "PRINT_NARRATIONS",
  PRINT_REGISTER_NUMBER = "PRINT_REGISTER_NUMBER",
  PRINT_SECTION_NAME = "PRINT_SECTION_NAME",
  PRINT_COMMON_TITLE = "PRINT_COMMON_TITLE",
  PRINT_RECEIPT_FOOTER = "PRINT_RECEIPT_FOOTER",
  PRINT_RECEIPT_HEADER = "PRINT_RECEIPT_HEADER",
  PRINT_NUMBER_COPIES = "PRINT_NUMBER_COPIES",
  PRINT_RECEIPT_FOOTER_2 = "PRINT_RECEIPT_FOOTER_2",
  PRINT_INST_STD_LABEL = "PRINT_INST_STD_LABEL",
  PRINT_TRANSACTION_NUMBER = "PRINT_TRANSACTION_NUMBER",
  PRINT_RECEIPT_BY_DEFAULT = "PRINT_RECEIPT_BY_DEFAULT",
}

export enum InstitutionVoucherPrintConfig {
  VO_PRINT_INST_LOGO = "VO_PRINT_INST_LOGO",
  VO_PRINT_INST_NAME = "VO_PRINT_INST_NAME",
  VO_PRINT_INST_ADDRESS = "VO_PRINT_INST_ADDRESS",
  VO_PRINT_VOUCHER_TYPE = "VO_PRINT_VOUCHER_TYPE",
  VO_APPROVED_BY = "VO_APPROVED_BY",
  VO_SIGNED_BY = "VO_SIGNED_BY",
  VO_PRINT_CREATED_TIME = "VO_PRINT_CREATED_TIME",
  VO_TEMPLATE = "VO_TEMPLATE",
  VO_LEFT_MARGIN = "VO_LEFT_MARGIN",
  VO_RIGHT_MARGIN = "VO_RIGHT_MARGIN",
  VO_TOP_MARGIN = "VO_TOP_MARGIN",
  VO_BOTTOM_MARGIN = "VO_BOTTOM_MARGIN",
}
export enum StudentEnquiryFormConfig {
  ENQ_PRINT_QR_HEADER = "ENQ_PRINT_QR_HEADER",
  ENQ_PRINT_QR_FOOTER_1 = "ENQ_PRINT_QR_FOOTER_1",
  ENQ_PRINT_QR_FOOTER_2 = "ENQ_PRINT_QR_FOOTER_2",
  ENQ_PRINT_QR_THANK_YOU_MSG = "ENQ_PRINT_QR_THANK_YOU_MSG",
}

export const enum VoucherTypes {
  P = "Payment Voucher",
  R = "Receipt Voucher",
  C = "Contra Voucher",
  J = "Journal Voucher",
}
export const enum VoucherTemplates {
  TEMPLATE_1 = "template-1",
  TEMPLATE_2 = "template-2",
}
export const enum LedgerTypes {
  CASH = "CASH A/C",
  BANK = "BANK A/C",
  OTHERS = "OTHERS",
  STD = "STUDENT",
  ADJ = "ADJUSTMENT",
  ALL = "ALL",
  DEPOSIT = "DEPOSIT",
}

export const enum ChallanPrintTypes {
  CHALLAN_PRINT_CUSTOMER_NAME = "CHALLAN_PRINT_CUSTOMER_NAME",
  CHALLAN_PRINT_INST_NAME = "CHALLAN_PRINT_INST_NAME",
  CHALLAN_PRINT_INST_LOGO = "CHALLAN_PRINT_INST_LOGO",
  CHALLAN_PRINT_FATHER_NAME = "CHALLAN_PRINT_FATHER_NAME",
  CHALLAN_PRINT_ADMISSION_NUMBER = "CHALLAN_PRINT_ADMISSION_NUMBER",
  CHALLAN_PRINT_CLASS_NAME = "CHALLAN_PRINT_CLASS_NAME",
  CHALLAN_PRINT_SECTION_NAME = "CHALLAN_PRINT_SECTION_NAME",
  CHALLAN_PRINT_INST_ADDRESS = "CHALLAN_PRINT_INST_ADDRESS",
  CHALLAN_BANK_ACCOUNT_NUMBER = "CHALLAN_BANK_ACCOUNT_NUMBER",
  CHALLAN_BANK_NAME = "CHALLAN_BANK_NAME",
  CHALLAN_BANK_BRANCH_NAME = "CHALLAN_BANK_BRANCH_NAME",
  CHALLAN_PRINT_CONSOLIDATE_TITLE = "CHALLAN_PRINT_CONSOLIDATE_TITLE",
  CHALLAN_PRINT_RECEIPT_HEADER = "CHALLAN_PRINT_RECEIPT_HEADER",
  CHALLAN_PRINT_INST_STD_BANK_LABEL = "CHALLAN_PRINT_INST_STD_BANK_LABEL",
  CHALLAN_PRINT_NUMBER_COPIES = "CHALLAN_PRINT_NUMBER_COPIES",
  CHALLAN_PRINT_NOTE_1 = "CHALLAN_PRINT_NOTE_1",
  CHALLAN_PRINT_NOTE_2 = "CHALLAN_PRINT_NOTE_2",
  CHALLAN_PRINT_NOTE_3 = "CHALLAN_PRINT_NOTE_3",
}
export const enum IdCardPrintTypes {
  ID_PRINT_CUSTOMER_NAME = "ID_PRINT_CUSTOMER_NAME",
  ID_PRINT_INST_NAME = "ID_PRINT_INST_NAME",
  ID_PRINT_INST_LOGO = "ID_PRINT_INST_LOGO",
  ID_PRINT_FATHER_NAME = "ID_PRINT_FATHER_NAME",
  ID_PRINT_ADMISSION_NUMBER = "ID_PRINT_ADMISSION_NUMBER",
  ID_PRINT_CLASS_NAME = "ID_PRINT_CLASS_NAME",
  ID_PRINT_SECTION_NAME = "ID_PRINT_SECTION_NAME",
  ID_PRINT_REGISTER_NUMBER = "ID_PRINT_REGISTER_NUMBER",
  ID_PRINT_INST_ADDRESS = "ID_PRINT_INST_ADDRESS",
  ID_PRINT_STD_ADDRESS = "ID_PRINT_STD_ADDRESS",
  ID_TEMPLATE = "ID_TEMPLATE",
  ID_PRINT_STD_MOBILE_NO = "ID_PRINT_STD_MOBILE_NO",
  ID_BOTTOM_MARGIN = "ID_BOTTOM_MARGIN",
  ID_RIGHT_MARGIN = "ID_RIGHT_MARGIN",
  ID_TOP_MARGIN = "ID_TOP_MARGIN",
  ID_LEFT_MARGIN = "ID_LEFT_MARGIN",
  ID_FOOTER_TEXT = "ID_FOOTER_TEXT",
}
export const enum IdConfigFor {
  TEMPLATE1 = "TEMPLATE1",
  TEMPLATE2 = "TEMPLATE2",
  TEMPLATE3 = "TEMPLATE3",
  TEMPLATE4 = "TEMPLATE4",
}
export const enum documentconfigTypes {
  DOC_CUSTOMER_NAME = "DOC_CUSTOMER_NAME",
  DOC_INST_LOGO = "DOC_INST_LOGO",
  DOC_INST_NAME = "DOC_INST_NAME",
  DOC_INST_ADDRESS = "DOC_INST_ADDRESS",
  DOC_DATE = "DOC_DATE",
  DOC_ACADEMIC_YEAR = "DOC_ACADEMIC_YEAR",
  DOC_SUBJECT = "DOC_SUBJECT",
  DOC_FOOTER_TEXT = "DOC_FOOTER_TEXT",
  DOC_SIGNATORY_1 = "DOC_SIGNATORY_1",
  DOC_SIGNATORY_2 = "DOC_SIGNATORY_2",
  DOC_SIGNATORY_3 = "DOC_SIGNATORY_3",
  DOC_SIGNATORY_4 = "DOC_SIGNATORY_4",
  DOC_SIGNATORY_5 = "DOC_SIGNATORY_5",
  DOC_HEADER_BORDER_COLOR = "DOC_HEADER_BORDER_COLOR",
  DOC_FOOTER_BORDER_COLOR = "DOC_FOOTER_BORDER_COLOR",
  DOC_INST_NAME_COLOR = "DOC_INST_NAME_COLOR",
  DOC_CUST_NAME_COLOR = "DOC_CUST_NAME_COLOR",
  DOC_INST_ADDR_COLOR = "DOC_INST_ADDR_COLOR",
  DOC_LEFT_MARGIN = "DOC_LEFT_MARGIN",
  DOC_RIGHT_MARGIN = "DOC_RIGHT_MARGIN",
  DOC_TOP_MARGIN = "DOC_TOP_MARGIN",
  DOC_BOTTOM_MARGIN = "DOC_BOTTOM_MARGIN",
  DOC_FOOTER_HEIGHT = "DOC_FOOTER_HEIGHT",
  DOC_HEADER_HEIGHT = "DOC_HEADER_HEIGHT",
  DOC_PRINT_STD_PHOTO = "DOC_PRINT_STD_PHOTO",
  DOC_FEE_CATEGORY = "DOC_FEE_CATEGORY",
}

export const enum reportCardConfigTypes {
  RP_CUSTOMER_NAME = "RP_CUSTOMER_NAME",
  RP_INST_LOGO = "RP_INST_LOGO",
  RP_INST_NAME = "RP_INST_NAME",
  RP_INST_ADDRESS = "RP_INST_ADDRESS",
  RP_INST_EMAIL = "RP_INST_EMAIL",
  RP_INST_WEBSITE = "RP_INST_WEBSITE",
  RP_GRADE_SCALE = "RP_GRADE_SCALE",
  RP_STD_NAME = "RP_STD_NAME",
  RP_STD_CLASS_SECTION = "RP_STD_CLASS_SECTION",
  RP_STD_ROLL_NUMBER = "RP_STD_ROLL_NUMBER",
  RP_STD_DOB = "RP_STD_DOB",
  RP_STD_MOTHER_NAME = "RP_STD_MOTHER_NAME",
  RP_STD_FATHER_NAME = "RP_STD_FATHER_NAME",
  RP_STD_ADDRESS = "RP_STD_ADDRESS",
  RP_STD_MOBILE = "RP_STD_MOBILE",
  RP_STD_EMAIL = "RP_STD_EMAIL",
  RP_PARENT_SIGN = "RP_PARENT_SIGN",
  RP_CLASS_TEACHER_SIGN = "RP_CLASS_TEACHER_SIGN",
  RP_STD_ATTENDANCE_STATS = "RP_STD_ATTENDANCE_STATS",
  RP_REMARKS_PER_TEST = "RP_REMARKS_PER_TEST",
  RP_GRADE_FOR_EACH_SUBJECT = "RP_GRADE_FOR_EACH_SUBJECT",
  RP_STD_HEIGHT_WEIGHT = "RP_STD_HEIGHT_WEIGHT",
  RP_OVERALL_PERCENTAGE = "RP_OVERALL_PERCENTAGE",
  RP_ANNUAL_RESULTS = "RP_ANNUAL_RESULTS",
  RP_STD_OVERALL_GRADE = "RP_STD_OVERALL_GRADE",
  RP_STD_OVERALL_RANK = "RP_STD_OVERALL_RANK",
  RP_HEADER = "RP_HEADER",
  RP_PHOTO_1 = "RP_PHOTO_1",
  RP_PHOTO_2 = "RP_PHOTO_2",
  RP_TITLE = "RP_TITLE",
  RP_AFFILIATED_CAPTION = "RP_AFFILIATED_CAPTION",
  RP_STUDENT_PHOTO = "RP_STUDENT_PHOTO",
}

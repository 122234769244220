import { useContext, useEffect } from "react";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { singleNodeVars } from "../../../../Types/Accounting";
import { GetAcdSubjectCategoriesByNodeIdData } from "../../../../Types/Category/queries";
import { useLazyQuery } from "@apollo/client";
import { GetSubjectGroupByNodeId } from "../../../../queries/customallocation/groups/queries";

const useCategoriesByNodeId = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [GetAcdCategory, { data, loading, error }] = useLazyQuery<
    GetAcdSubjectCategoriesByNodeIdData,
    singleNodeVars
  >(GetSubjectGroupByNodeId, {
    variables: {
      token,
      id: state.acdCatId ? state.acdCatId : 0,
    },
  });

  useEffect(() => {
    if (token && state.acdCatId) {
      GetAcdCategory();
    }
  }, [GetAcdCategory, token, state.acdCatId]);
  return { data, loading, error };
};

export default useCategoriesByNodeId;

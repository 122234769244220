import { useContext, useEffect } from "react";
import {
  GetAcdStdSubjAttOverAllData,
  subjectData,
} from "../../../hooks/useAcdStudentAttendance";
import { GetAcdStdSubjAttOverAllVars } from "../../../types/holidays";
import { GetAcdStdSubjAttOverAll } from "../../../queries/holidays/list";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { Direction, SortBy } from "../../../../../utils/Enum.types";
import { TEXT_FONT_FAMILY } from "../../../../../styles/DataGridTableStyles";
import { colorsInGraph } from "../../../../../styles/Constants";
import ReactEcharts from "echarts-for-react";
const GraphData = () => {
  const { token } = useToken();
  const { InstId, subjectId, entryId } = useParams();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();

  const [
    GetOverAllSubjectAttendance,
    { data: OverSubjectData, loading: SubjectLoading },
  ] = useLazyQuery<GetAcdStdSubjAttOverAllData, GetAcdStdSubjAttOverAllVars>(
    GetAcdStdSubjAttOverAll,
    {
      variables: {
        input: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          entry_id: 0,
          entry_level: EMPTY_STRING,
          inst_id: state.InstId!,
          cal_month: toIsoDate(serverDate),
          subj_master_id: 0,
          session_id: 0,
          student_id: state.studentId,
          att_query_type: "SUBJ_YEARLY_ATT_PER_STD",
          date_of_attendance: toIsoDate(serverDate),
        },
        token,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.STD_ROLL_NO,
        },
        after: null,
        first: null,
        name: EMPTY_STRING,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (state.studentId && token && state.ActiveAcdYr) {
      GetOverAllSubjectAttendance();
    }
  }, [GetOverAllSubjectAttendance, state.studentId, token, state.ActiveAcdYr]);

  const graphData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        crossStyle: {
          color: "#999",
        },
      },
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },
    toolbox: {
      feature: {
        dataView: {
          show: false,
          readOnly: false,
        },
        magicType: { show: false, type: ["line", "bar", "piechart"] },
        saveAsImage: { show: true },
      },
    },

    xAxis: {
      type: "category",
      boundaryGap: [0, 0.01],
      data: OverSubjectData
        ? OverSubjectData.GetAcdStdSubjAttOverAll.edges.map((value, index) => ({
            value: (value.node.subject_details as subjectData).subj_desc,
            itemStyle: { color: colorsInGraph[index] },
          }))
        : null,

      axisLabel: {
        rotate: -25,
        fontSize: 8,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#808080",
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: 'bar',
        data: OverSubjectData
          ? OverSubjectData.GetAcdStdSubjAttOverAll.edges.map(
              (value, index) => ({
                name: (value.node.subject_details as subjectData).subj_desc,
                value: value.node.over_all_per,
                itemStyle: { color: colorsInGraph[index] },
              })
            )
          : null,
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
      },
    ]
    
  };
  

  return (
    <>
      <ReactEcharts option={graphData} />
    </>
  );
};

export default GraphData;

import { gql } from "@apollo/client";

export const AddMsgInstTemplate = gql`
  mutation AddMsgInstTemplate(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [CreateMsgInstTemplatesInput!]!
  ) {
    AddMsgInstTemplate(
      token: $token
      inst_id: $inst_id
      user_details: $user_details

      input: $input
    )
  }
`;
export const UpdateMsgInstTemplate = gql`
  mutation UpdateMsgInstTemplate(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMsgInstTemplatesInput!
  ) {
    UpdateMsgInstTemplate(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      id: $id
      input: $input
    )
  }
`;
export const AddSwMsgTag = gql`
  mutation AddSwMsgTag(
    $token: String!
    $input: CreateSwMsgTagsInput!
    $user_details: SubscribedUser!
  ) {
    AddSwMsgTag(token: $token, input: $input, user_details: $user_details)
  }
`;
export const UpdateSwMsgTag = gql`
  mutation UpdateSwMsgTag(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwMsgTagsInput!
  ) {
    UpdateSwMsgTag(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;

export const DeleteMsgInstTemplate = gql`
  mutation DeleteMsgInstTemplate(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteMsgInstTemplate(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteSwMsgTag = gql`
  mutation DeleteSwMsgTag(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteSwMsgTag(token: $token, id: $id, user_details: $user_details)
  }
`;
export const DeleteSwMsgTemplate = gql`
  mutation DeleteSwMsgTemplate(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteSwMsgTemplate(token: $token, id: $id, user_details: $user_details)
  }
`;

export const AddSwMsgTemplate = gql`
  mutation AddSwMsgTemplate(
    $token: String!
    $user_details: SubscribedUser!
    $input: CreateSwMsgTemplatesInput!
  ) {
    AddSwMsgTemplate(token: $token, input: $input, user_details: $user_details)
  }
`;
export const UpdateSwMsgTemplate = gql`
  mutation UpdateSwMsgTemplate(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateSwMsgTemplatesInput!
  ) {
    UpdateSwMsgTemplate(
      token: $token
      input: $input
      id: $id
      user_details: $user_details
    )
  }
`;
export const SendFcmMsg = gql`
  mutation SendFcmMsg($token: String!, $msg: String!) {
    SendFcmMsg(token: $token, msg: $msg)
  }
`;

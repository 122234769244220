import { GridAlignment } from "@mui/x-data-grid-pro";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../../../styles/DataGridTableStyles";

const useLibraryTableJson = () => {
  const { departmentLabel, branchLabel } = useInstLabels();
  const tableJson = {
    Library_Table: {
      Media: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
            flex: 1,
          },
          {
            headerName: "Abbreviation",
            cellClassName: "td-desc",
            field: "abbr",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Status",
            cellClassName: "td-status",
            field: "status",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
        ],
      },
      Department: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
        ],
      },
      Donar: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Contact No",
            cellClassName: "td-mobile",
            field: "mobile",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Donor Type",
            cellClassName: "td-donor-type",
            field: "donor_type",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      Book_Category: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "",
            flex: 1,
          },
          {
            headerName: "Abbreviation",
            cellClassName: "td-desc",
            field: "abbr",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Status",
            cellClassName: "td-status",
            field: "status",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
        ],
      },
      Subjects: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Subject Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Classification No.",
            cellClassName: "td-desc",
            field: "class_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Status",
            cellClassName: "td-desc",
            field: "status",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      Publisher: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Publisher Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Phone No.",
            cellClassName: "td-mobile",
            field: "mobile",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Contact Person",
            cellClassName: "td-desc",
            field: "contact_person",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      Languages: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Languages",
            cellClassName: "td-name",
            field: "languages",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Short Description",
            cellClassName: "td-desc",
            field: "short_desc",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      Attribute: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Attribute",
            cellClassName: "td-name",
            field: "attribute",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
        ],
      },
      Author: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Phone",
            cellClassName: "td-mobile",
            field: "mobile",
            headerAlign: HEADER_TEXT_ALIGN,
          },

          {
            headerName: "Email",
            cellClassName: "td-email",
            field: "email",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      Shelf: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Description",
            cellClassName: "td-name",
            field: "description",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Number",
            cellClassName: "td-number",
            field: "number",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      Vendor: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Vendor Name",
            cellClassName: "td-name",
            field: "name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Phone No.",
            cellClassName: "td-mobile",
            field: "mobile",
            headerAlign: HEADER_TEXT_ALIGN,
          },

          {
            headerName: "PAN No.",
            cellClassName: "td-number",
            field: "pan_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "GST No",
            cellClassName: "td-number",
            field: "gst_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
      },
      Classification: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Classification No.",
            cellClassName: "td-adm-no",
            field: "classification_no",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Classification Desc.",
            cellClassName: "td-desc",
            field: "classification_desc",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: "Classification Copies",
            cellClassName: "td-desc",
            field: "classification_copies",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "center" as GridAlignment,
          },
          {
            headerName: "Classification Copies Available",
            cellClassName: "td-desc",
            field: "classification_copies_available",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "center" as GridAlignment,
          },
          {
            headerName: "No. of Books Available",
            cellClassName: "td-number",
            field: "no_of_books_available",
            headerAlign: HEADER_TEXT_ALIGN,
            align: "center" as GridAlignment,
          },
        ],
      },
      Keyword: {
        Table_Headers: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Keyword",
            cellClassName: "td-name",
            field: "keyword",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
        ],
      },
      BookMasterKeyword: {
        Table_Headers: [
          { labelName: "Sl", className: "library__table--th-slno" },
          {
            labelName: "Classification",
          },
          { labelName: "Keyword ", className: "library__table--th-phoneno" },
          { labelName: "Actions", className: "library__table--actions" },
        ],
      },
      Books_Entry: {
        Table_Headers: [
          {
            field: "id",
            headerName: "Sl",
            headerAlign: HEADER_TEXT_ALIGN,
            cellClassName: "td-sl-no",
            align: SLNO_TEXT_ALIGN,
          },

          {
            headerName: "Accession No.",
            cellClassName: "td-adm-no",
            field: "accession_no",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Title",
            cellClassName: "td-name td-onClick",
            field: "title",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },

          {
            headerName: "Book Status",
            cellClassName: "td-name",
            field: "book_status",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Publication",
            cellClassName: "td-desc",
            field: "publication",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Authors",
            cellClassName: "td-desc",
            field: "authors",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },

          {
            headerName: "Subjects",
            cellClassName: "td-desc",
            field: "subjects",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Reference",
            cellClassName: "td-desc",
            field: "reference",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
        ],
      },
      Books_Entry_Modal: {
        Table_Headers: [
          { labelName: "Sl", className: "book-entry__table--th-slno" },
          {
            labelName: "Accession No.",
            className: "book-entry__table--th-accessno",
          },
          { labelName: "Title" },
          {
            labelName: "Publication",
            className: "book-entry__table--publication",
          },
          { labelName: "Authors", className: "book-entry__table--authors" },
        ],
      },
      AccesionNumberEdit: {
        Table_Headers: [
          { labelName: "Sl", className: "edit-accno__table--th-slno" },
          { labelName: "Title" },
          { labelName: "Publication", className: "edit-accno__table--desc" },
          { labelName: "Authors", className: "edit-accno__table--desc" },
          { labelName: "Subjects", className: "edit-accno__table--desc" },
          {
            labelName: "Accession No.",
            className: "edit-accno__table--th-input",
          },
        ],
      },
      FineSlab: {
        Table_Headers: [
          { labelName: "Sl", className: "fine-slab__frame--table--th-slno" },
          { labelName: "Fine Name" },
          {
            labelName: "Actions",
            className: "fine-slab__frame--table--actions",
          },
        ],
        Association: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "fine-slab__frame--table--th-slno",
            },
            { labelName: "Fine Name" },
            {
              labelName: "Fine Applied for",
              className: "fine-slab__frame--table--desc",
            },
            {
              labelName: "Actions",
              className: "fine-slab__frame--table--actions",
            },
          ],
        },
      },
      BookTransaction: {
        BookReturn: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "book-return__tableblock--issued-books-table--th-slno",
            },
            {
              labelName: "Accession No.",
              className:
                "book-return__tableblock--issued-books-table--th-accno",
            },
            { labelName: "Title" },
            {
              labelName: "Author",
              className: "book-return__tableblock--issued-books-table--Author",
            },
            {
              labelName: "Issue Date",
              className: "book-return__tableblock--issued-books-table--th-date",
            },
            {
              labelName: "Due Date",
              className: "book-return__tableblock--issued-books-table--th-date",
            },
            {
              labelName: "No. of Days Delayed",
              className: "book-return__tableblock--issued-books-table--th-date",
            },
            {
              labelName: "Fine",
              className:
                "book-return__tableblock--issued-books-table--th-amount",
            },
          ],
          DamagedBooks: {
            Table_Headers: [
              { labelName: "Sl" },
              { labelName: "Accession No." },
              { labelName: "Remarks" },
              { labelName: "Fine" },
            ],
          },
          LostBooks: {
            Table_Headers: [
              { labelName: "Sl" },
              { labelName: "Accession No." },
              { labelName: "Remarks" },
              { labelName: "Fine" },
            ],
          },
        },
        IssuedBooks: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "book-issue__tableblock--books-table--th-slno",
            },
            {
              labelName: "Accession No.",
              className: "book-issue__tableblock--books-table--accno",
            },
            { labelName: "Title" },
            {
              labelName: "Author",
              className: "book-issue__tableblock--books-table--publication",
            },
            {
              labelName: "Issue Date",
              className: "book-issue__tableblock--books-table--date",
            },
            {
              labelName: "Due Date",
              className: "book-issue__tableblock--books-table--date",
            },
            {
              labelName: "No. of Days Delayed",
              className: "book-issue__tableblock--books-table--date",
            },
            {
              labelName: "Fine",
              className: "book-issue__tableblock--books-table--amount",
            },
          ],
        },
        BooksToBeIssued: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "book-issue__tableblock--books-table--th-slno",
            },
            {
              labelName: "Accession No.",
              className: "book-issue__tableblock--books-table--accno",
            },
            { labelName: "Title" },
            {
              labelName: "Author",
              className: "book-issue__tableblock--books-table--publication",
            },
            {
              labelName: "Edition",
              className: "book-issue__tableblock--books-table--publication",
            },
            {
              labelName: "Volume",
              className: "book-issue__tableblock--books-table--publication",
            },
            {
              labelName: "Actions",
              className: "book-issue__tableblock--books-table--actions",
            },
          ],
        },
        ReturnedBooksHistory: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "issued-book__history--table--th-slno",
            },
            {
              labelName: "Accession No.",
              className: "issued-book__history--table--th-accno",
            },
            { labelName: "Title" },
            {
              labelName: "Publication",
              className: "issued-book__history--table--publication",
            },
            {
              labelName: "Issued Date",
              className: "issued-book__history--table--th-date",
            },
            {
              labelName: "Due Date",
              className: "issued-book__history--table--th-date",
            },
            {
              labelName: "Submitted Date",
              className: "issued-book__history--table--th-date",
            },
            { labelName: "No. of Days" },
            { labelName: "Fine" },
          ],
        },
        IssuedBooksHistory: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "book-issue__tableblock--books-table--th-slno",
            },
            {
              labelName: "Accession No.",
              className: "book-issue__tableblock--books-table--th-accno",
            },
            { labelName: "Title" },
            {
              labelName: "Publication",
              className: "book-issue__tableblock--books-table--publication",
            },
            {
              labelName: "Issued Date",
              className: "book-issue__tableblock--books-table--th-date",
            },
          ],
        },
        BookReservation: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "book-reservation__table--th-slno",
            },
            {
              labelName: "Classification No.",
              className: "book-reservation__table--th-clsno",
            },
            { labelName: "Title" },
            {
              labelName: "Reserved ID",
              className: "book-reservation__table--th-rsvid",
            },
            {
              labelName: "Reservation No.",
              className: "book-reservation__table--th-number",
            },
            {
              labelName: "Actions",
              className: "book-reservation__table--actions",
            },
          ],
        },
        Classification: {
          Table_Headers: [
            {
              labelName: "Sl",
              className: "classification-list__table--th-slno",
            },
            {
              labelName: "Classification No.",
              className: "classification-list__table--th-clsno",
            },
            { labelName: "Title" },
            {
              labelName: "Publications",
              className: "classification-list__table--publication",
            },
            {
              labelName: "Book Category",
              className: "classification-list__table--category",
            },
            {
              labelName: "Total No. of Books",
              className: "classification-list__table--th-total",
            },
            {
              labelName: "Total Circulation Books",
              className: "classification-list__table--th-total",
            },
            {
              labelName: "Damaged",
              className: "classification-list__table--th-total",
            },
          ],
        },
      },
      Reports: {
        List: {
          Table_Headers: [{ labelName: "List of Transactions" }],
        },

        Books_Clearance_List: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Student Name",
            cellClassName: "td-name",
            field: "std_name",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
            flex: 1,
          },
          {
            headerName: `${departmentLabel}`,
            cellClassName: "td-desc",
            field: "dept",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: `${branchLabel}`,
            cellClassName: "td-desc",
            field: "branch",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "No. of Books Not Returned",
            cellClassName: "td-number",
            field: "books_returned",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
        ],
      },
      BooksShelfAllocation: {
        Table_Headers: [
          {
            labelName: "Sl",
            className: "books-allocation__tableblock--table--th-slno",
          },
          {
            labelName: "Accession No.",
            className: "books-allocation__tableblock--table--th-accessno",
          },
          { labelName: "Title" },
          { labelName: "Authors" },
        ],
      },

      AllocateRepairedBooks: {
        Table_Headers: [
          { labelName: "Sl", className: "repaired-books__table--th-slno" },
          {
            labelName: "Classification No.",
            className: "repaired-books__table--th-clsno",
          },
          {
            labelName: "Accession No.",
            className: "repaired-books__table--th-clsno",
          },
          { labelName: "Title" },
          { labelName: "Publication" },
          { labelName: "Authors" },
        ],
      },
    },
  };
  return tableJson;
};

export default useLibraryTableJson;

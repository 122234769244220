import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import {
  ClassList,
  ClassListDataByBranchId,
  ClassListVarsBranchId,
} from "../../../Types/Student";
import { reOrderProcess } from "../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { ClassesByBranchIds } from "../../../queries/institution/classes/list/byid";
import { Operation } from "../../../utils/Enum.types";
import { ReOrderInstClass } from "../../../queries/institution/mutations/update";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import LoadingModal from "../../../pages/LoadingModal";
import { GetBranchAndClassDetailsForSchoolPosting } from "../queries/posting";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  branchId: number;
}
const ReorderTable = ({ setModal, branchId }: Props) => {
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { token } = useToken();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  // eslint-disable-next-line
  const [item, setitems] = useState<ClassList[]>([]);
  const [localItems, setLocalItems] = useState<ClassList[]>([]);
  const [reorderClass, { loading: reorderLoading }] = useMutation(
    ReOrderInstClass,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetClassData, { data: classData, loading: classLoading }] =
    useLazyQuery<ClassListDataByBranchId, ClassListVarsBranchId>(
      ClassesByBranchIds,
      {
        variables: { branch_ids: [branchId], token },
      }
    );
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModal(false);
    }
    setMessage({
      message: " ",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };
  const handleSaveOrder = () => {
    // eslint-disable-next-line
    reorderClass({
      variables: {
        token,

        input: localItems?.map((res, index) => ({
          id: res.id,
          class_idx: index + 1,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetBranchAndClassDetailsForSchoolPosting,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re-Order Successful ",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  useEffect(() => {
    if (classData && !classLoading)
      setLocalItems(
        classData.GetClassesByBranchIds?.filter(
          (classResponse) => classResponse !== null
        )
      );
    // eslint-disable-next-line
  }, [classData, classLoading]);
  useEffect(() => {
    if (token) {
      GetClassData();
    }
  }, [token, GetClassData]);
  return (
    <>
      <Title>Reorder Classes</Title>
      <div className="acd-posting__reorder">
        <div className="acd-posting__reorder--tableblock">
          <TableContainer className="acd-posting__reorder--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Sl</TableCell>
                  <TableCell>Classes</TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {localItems?.map((response, index: number) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="acd-posting__reorder--table--slno"
                                  >
                                    =
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                    className="acd-posting__reorder--table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>

                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    {response.class_desc}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </div>
        <Button mode="save" onClick={handleSaveOrder} />
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={reorderLoading} />
    </>
  );
};

export default ReorderTable;

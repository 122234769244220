import { gql } from "@apollo/client";
export const UpdateCustomer = gql`
  mutation UpdateCustomer(
    $id: ID!
    $input: UpdateMstCustomerInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    UpdateCustomer(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;

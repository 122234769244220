import { gql } from "@apollo/client";

export const DeleteLibBookMediaById = gql`
  mutation DeleteLibBookMediaById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteLibBookMediaById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteAcctMiscActivities = gql`
  mutation DeleteAcctMiscActivities(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteAcctMiscActivities(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteLibBookDept = gql`
  mutation DeleteLibBookDept(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteLibBookDept(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

/**
 * React functional component that handles the logic for generating student receipts for fee concessions.
 *
 * Props:
 * - pageType: Determines the type of page the component is rendered on (either "GENERAL" or "MODAL").
 *
 * Renders:
 * - Title component
 * - Student information
 * - Fee table
 * - Receipt form
 * - Modal components for viewing refund details, selecting students, and confirming deletion
 *
 * Uses:
 * - React hooks: useContext, useEffect, useRef, useState
 * - External dependencies: Input, Label, Title, Modal, Autocomplete, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextArea, TextField, Button, useNavigate, useParams, useMutation
 * - Custom hooks: useVoucherNumber, useStudentDatabyId, useToken, useStudentDemandDetails, useInstReferenceDataByType, useAcctStdAdmissionNumber, useServerDateandTime, useInstitutionConfiguration, useActiveFinancialYear, useInstDetails
 * - GraphQL queries: GetAcctVouchers, GetVoucherNumber
 * - GraphQL mutation: AddAcctStdConcessionAndDetails
 * - Other utility functions and constants
 *
 * Example usage:
 * <Index pageType={PageFor.GENERAL} />
 */
import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import {
  PageFor,
  VoucherBookKey,
  StudentListFor,
  StudentAcctReportType,
  Direction,
  SortBy,
  YesNo,
  Operation,
} from "../../../utils/Enum.types";
import {
  DeleteLedgerModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import Modal from "react-modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useVoucherNumber from "../hooks/useVoucherNumber";
import { useMutation } from "@apollo/client";
import { RefundAmount } from "../../../Types/Accounting";
import {
  DateRange,
  formatter,
  NameOfTheDay,
  RefsByTagName,
  toggleFullSceen,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  GetAcctStdConcessionVouchers,
  GetAcctVouchers,
  GetVoucherNumber,
} from "../queries/Vouchers/query";
import { AddAcctStdConcessionAndDetails } from "../queries/receipts/mutation/Index";
import { ConcessionFeeTitleProps } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import Home from "../Home/Index";
import Avatar from "../../../images/Avatar.svg";
import Edit from "../../../images/Edit.svg";
import CloseImg from "../../../images/Close.svg";

import LoadingModal from "../../../pages/LoadingModal";

import Enlarge from "../../../images/Enlarge.svg";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";

import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import useStudentDemandDetails from "../hooks/useStudentDemandDetails";
import MessageModal from "../../../pages/MessageModal";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import ConcessionView from "./ConcessionView";
import Close from "../../../images/Close.svg";
import FileAttach from "../../../images/BrowseFiles.svg";

import dayjs from "dayjs";
import { payloadTypes } from "../../../context/reducer";
import useInstReferenceDataByType, {
  InstReferenceDataByType,
} from "../../../customhooks/useInstReferenceDataByType";

import DownArrow from "../../../images/DownArrow.svg";
import Delete from "./Delete";
import { handleUploadAndDownloadFile } from "../../../utils/Upload";
import StudentList from "../common/StudentList";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { DemandDetailsQueryType } from "../common/QueryTypes";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../common/Enum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import TextArea from "../../../stories/TextArea/TextArea";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";

const { AccountsTitles } = require("../json/title.json");

interface Props {
  pageType: PageFor;
}
const Index = ({ pageType }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};

  const [startDate] = useState(firstDay);
  const [endDate] = useState(lastDay);

  const { state, dispatch } = useContext(AppContext);
  const { studentId } = useParams();

  const { token } = useToken();
  const navigate = useNavigate();
  const [imageString, setImageString] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [remarks, setRemarks] = useState("");
  const { InstId } = useParams<{ InstId: string }>();
  const [items, setItems] = useState<RefundAmount[]>([]);
  const [concessionAmount, setConcessionAmount] = useState(0);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });

  const [authorizedBy, setAuthorizedBy] = useState<optionsType | null>(null);
  const [rcptDate, setRcptDate] = useState("");
  const [studentModal, setStudentModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [viewRefundDetailsModal, setViewRefundAmountDetailsModal] =
    useState(false);
  const [admNo, setAdmNo] = useState("");

  const feedesc = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feedesc);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const remarkRef = useRef<HTMLTextAreaElement>(null);
  // use queries
  const { user_details } = useLoggedInUserDetails();
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.GENERAL
  );
  const { studentData, studentFormData } = useStudentDatabyId();
  const {
    data: serverDateData,
    loading: serverDateLoading,
    serverDate,
  } = useServerDateandTime();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { voucherNumber } = useVoucherNumber(VoucherBookKey.CONCESSION);

  const { InstreferenceData } = useInstReferenceDataByType(
    InstReferenceDataByType.AUTHORIZER
  );
  //lazy queries

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.ALL_DEMAND_DETAILS,
    false,
    EMPTY_STRING
  );
  //mutations
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation(AddAcctStdConcessionAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  let totalOb = 0;
  let totalConcessionAmount = 0;
  let totaldemandAmount = 0;
  let totalFeeReceviable = 0;
  let totalFeeCollected = 0;
  let totalBalanceAmount = 0;
  let totalRefundedAmount = 0;
  let totalWaiveOffAmount = 0;

  const { InstDetails } = useInstDetails(1);

  // eslint-disable-next-line
  items.map((item) => {
    totalOb += item.fee_ob;
    totalConcessionAmount += item.fee_concession;
    totaldemandAmount += item.fee_demand;
    totalFeeReceviable += item.fee_receivable;
    totalFeeCollected += item.fee_received;
    totalRefundedAmount += item.fee_refunded;
    totalBalanceAmount += item.fee_bal;
    totalWaiveOffAmount += item.waive_off_amt!;
  });

  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setConcessionAmount(updatedRcptAmount);
  };
  const HandleEditItem = (sl_number: number) => {
    const updatedData = items.map((item) => {
      if (item.id === sl_number) {
        if (concessionAmount > item.fee_bal) {
          alert("Please check you're concession amount");
          return item;
        } else {
          const newData = {
            ...item,
            fee_ob: item.fee_ob,
            fee_demand: item.fee_demand,
            fee_bal: item.fee_bal,
            fee_concession: item.fee_concession,
            waive_off_amt: concessionAmount,
            fee_received: item.fee_received,
            fee_refunds: item.fee_refunds,
            acct_ldgr_id: item.acct_ldgr_id,
            id: item.id,
          };

          return newData;
        }
      }
      return item;
    });

    setItems(updatedData);
  };
  const handleClear = () => {
    setItems([]);
    setAdmNo("");
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setRemarks("");
    setAuthorizedBy(null);
    setRemarks("");
    setImageString("");
    setFile(null);
  };

  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
    };
  };
  const { editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );
  const handleStudentReceipts = (e: React.FormEvent) => {
    e.preventDefault();
    if (totalWaiveOffAmount === 0) {
      alert("Please check your concession amount");
      return;
    }

    GenerateStudentReceipts({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        acct_std_concession: {
          concession_vouch_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
          concession_vouch_date: toIsoDate(rcptDate),
          concession_total: totalWaiveOffAmount,
          concession_for_staff: false,
          concession_auth_by: authorizedBy ? authorizedBy.label : EMPTY_STRING,
          concession_letter_enclosed: file ? true : false,
          concession_naration: remarks,
          student_id: state.studentId,
        },
        concession_details: items
          .filter((d) => d.waive_off_amt !== 0)
          .map((item, index) => ({
            vouch_sl_no: index + 1,
            concession_amt: item.waive_off_amt!,
            acct_ldgr_id: item.acct_ldgr_details.id,
          })),
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0!,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.BY_DATES,

              vo_end_date: dayjs(lastDay!).format(),
              acct_ldgr_id: 0,
              vo_start_date: dayjs(firstDay).format(),
              vo_type: EMPTY_STRING,
            },
          },
        },
        {
          query: GetVoucherNumber,
          variables: {
            token,
            inst_id: InstId!,
            vo_book_key: VoucherBookKey.CONCESSION,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0!,
          },
        },
        {
          query: GetAcctStdConcessionVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: EMPTY_STRING,
            sortBy: "CONCESSION_VOUCH_DATE",
            token,
            startDate: startDate ? toIsoDate(startDate) : DEFAULT_TIME,
            endDate: endDate ? toIsoDate(endDate) : DEFAULT_TIME,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        handleClear();

        setMessage({
          message: `Sucessfully Reduced ${totalWaiveOffAmount} to student ${studentFormData?.std_name}`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let image_as_files = e.target.files[0];
      setFile(image_as_files);
    }
  };
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== ""
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId, studentId]);
  useEffect(() => {
    if (StudentDemandDetails.data) {
      // setStudentModal(!studentModal);

      const result = StudentDemandDetails.data?.GetAcctStdDemandDetails.filter(
        (item) => item !== null
      ).map((item) => ({
        id: item.id!,
        fee_ob: item.fee_ob,
        fee_demand: item.fee_demand,
        fee_concession: item.fee_concession,
        fee_received: item.fee_received,
        fee_refunds: item.fee_refunds,
        acct_ldgr_id: item.acct_ldgr_id!,
        fee_bal: item.fee_bal,
        fee_refunded: item.fee_refunds,
        fee_receivable: item.fee_receivable,
        waive_off_amt: 0,
        acct_ldgr_details: item.acct_ldgr_details,
      }));

      setItems(result!);
    }
  }, [state.studentId, token, StudentDemandDetails.data]);
  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.Concessions.Titles.map(
              (title: ConcessionFeeTitleProps) => {
                return (
                  <React.Fragment key={title.Fee_Concession}>
                    {title.Fee_Concession}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        <div className="configuration-settings">
          {pageType === PageFor.MODAL && (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          )}
        </div>
      </div>
      <div className="row g-0 refund-academic-fee">
        <div className="col refund-academic-fee__frame ">
          <div className="refund-academic-fee__frame--imagefield">
            {pageType === PageFor.GENERAL ? (
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )
                    : null
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    if (items.length)
                      onEdit(items[0].id!, items[0].waive_off_amt!);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setAdmNo("");
                  }
                }}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    handleClear();
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission Number"
                    autoFocus
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            ) : (
              <TextField
                id="Admission No."
                label="Register Number"
                className="refund-academic-fee__frame--textfield"
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                value={studentFormData?.adm_no}
                disabled
              />
            )}

            {pageType === PageFor.GENERAL ? (
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            ) : null}
          </div>

          <TextField
            label="Register Number"
            className="refund-academic-fee__frame--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.reg_number}
            disabled
          />
          <TextField
            label={classLabel}
            className="refund-academic-fee__frame--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.class}
            disabled
          />
        </div>

        <div className="col refund-academic-fee__frame g-0">
          <TextField
            label="Name"
            className="refund-academic-fee__frame--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.std_name}
            disabled
          />
          <TextField
            label="Father Name"
            className="refund-academic-fee__frame--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.father_name}
            disabled
          />
          <TextField
            label={branchLabel}
            className="refund-academic-fee__frame--textfield"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
            value={studentFormData?.branch}
            disabled
          />
        </div>
        <div className="col refund-academic-fee__frame g-0">
          <TextField
            type="date"
            label="Date"
            className="refund-academic-fee__frame--textfield--date"
                   slotProps={{
            inputLabel: {
              shrink: true,
            },
            input:{
              inputProps: {
                min: state.ActiveFinYr
                  ? toInputStandardDate(state.ActiveFinYr?.fin_st_date)
                  : "",
                max: toInputStandardDate(serverDate),
              },
            }
          }}
            value={toInputStandardDate(rcptDate)}
            onChange={(e) => setRcptDate(e.target.value)}
            disabled={editDate ? false : true}
        
          />
          <Label className="present-day">{NameOfTheDay(rcptDate)}</Label>
          {USE_CATEGORY_KEY && (
            <TextField
               label={categoryLabel}
              className="refund-academic-fee__frame--textfield"
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              value={studentFormData?.category}
              disabled
            />
          )}
        </div>
        <div className="col-1 refund-academic-fee__frame--image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 refund-academic-fee__tableblock"
            : "row g-0 refund-academic-fee__tableblock--modal"
        }
      >
        <TableContainer className="refund-academic-fee__table g-0">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.FeeConcession.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.studentId
                ? items.map((item, index) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell
                          className="refund-academic-fee__table--slno"
                          align="center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {item.acct_ldgr_details.ldgr_desc}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="refund-academic-fee__table--fee"
                        >
                          {format(item.fee_ob)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="refund-academic-fee__table--fee"
                        >
                          {format(item.fee_concession)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="refund-academic-fee__table--fee"
                        >
                          {format(item.fee_demand)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="refund-academic-fee__table--fee"
                        >
                          {format(item.fee_receivable)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="refund-academic-fee__table--fee"
                        >
                          {format(item.fee_received)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="refund-academic-fee__table--fee"
                        >
                          {format(item.fee_refunded)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="refund-academic-fee__table--fee"
                        >
                          {format(item.fee_bal)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          onClick={() => onEdit(item.id!, item.waive_off_amt!)}
                          className="refund-academic-fee__table--fee editCell"
                        >
                          {inEditMode.status &&
                          inEditMode.rowKey === item.id ? (
                            <input
                              autoFocus
                              value={concessionAmount}
                              type="number"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (Number(e.target.value) >= 0) {
                                  setConcessionAmount(Number(e.target.value));
                                }
                              }}
                              onKeyDown={(event: React.KeyboardEvent) => {
                                if (event.key === Keys.ENTER) {
                                  if (items[index + 1] && items[index + 1].id) {
                                    onEdit(
                                      items[index + 1].id!,
                                      items[index + 1].waive_off_amt!
                                    );

                                    HandleEditItem(item.id!);
                                  } else {
                                    onEdit(
                                      items[index].id!,
                                      items[index].waive_off_amt!
                                    );
                                    HandleEditItem(item.id!);

                                    setInEditMode({
                                      rowKey: -805,
                                      status: false,
                                    });
                                    feeDescInputRef?.focus();
                                  }
                                }
                              }}
                            />
                          ) : (
                            format(item.waive_off_amt!)
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} className="total">
                  Total :
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalOb)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalConcessionAmount)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totaldemandAmount)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalFeeReceviable)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalFeeCollected)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalRefundedAmount)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalBalanceAmount)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalWaiveOffAmount)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <form onSubmit={handleStudentReceipts}>
        <div className="row g-0 refund-academic-fee__receiptblock">
          <div className="col account-frames h-100">
            <div className="label-grid">
              <Label>Receipt No</Label>
              <Input
                disabled
                value={
                  voucherNumber.data?.GetVoucherNumber.vo_number ?? EMPTY_STRING
                }
              />
            </div>
            <div className="student-documents__upload--buttons">
              <label className="student-documents__browse">
                <img src={FileAttach} alt="/" className="browse-icon" />
                Browse
                <input
                  id="student-documents__browse--inputTag"
                  type="file"
                  className="assignment-creation__fileattach--input"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleImagePreview(e)
                  }
                  accept="image/*,.pdf"
                />
              </label>
              <Button
                mode="upload"
                disabled={!file}
                onClick={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  if (file) {
                    handleUploadAndDownloadFile(
                      file,
                      `${InstDetails.data?.nodes[0]?.inst_name}/students/${studentFormData.adm_no}/concessions/${voucherNumber.data?.GetVoucherNumber.vo_number}`,
                      setProgress,
                      false
                    );
                  }
                  if (!file) {
                    alert("Please Select A file ");
                  }
                }}
              />
              <div className="student-documents__upload--label-grid">
                <Label>Uploading File Progress</Label>
                <progress value={progress} />
              </div>
            </div>
          </div>

          <div className="col account-frames label-grid">
            <Label>Letter Enclosed</Label>
            <Input disabled value={file ? YesNo.YES : YesNo.NO} />
            <Label>Authorized By</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={InstreferenceData.optionsType}
              openOnFocus
              autoHighlight
              ref={feedesc}
              value={authorizedBy}
              onChange={(e, newValue) => {
                if (newValue) {
                  setAuthorizedBy(newValue as optionsType);
                } else {
                  setAuthorizedBy(null);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  remarkRef.current?.focus();
                } else if (e.key === Keys.BACKSPACE) {
                  setAuthorizedBy(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col account-frames label-grid">
            <Label>Remarks :</Label>
            <TextArea
              rows={3}
              draggable={false}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  saveButtonRef.current?.focus();
                }
              }}
              textAreaRef={remarkRef}
              required
              resize="none"
            />
          </div>
        </div>

        <div className="refund-academic-fee__btns">
          <Button
            mode="save"
            type="submit"
            buttonref={saveButtonRef}
            disabled={
              totalWaiveOffAmount === 0 ||
              (file && progress === 0 ? true : false)
            }
          />
          <Button
            mode="delete"
            type="button"
            onClick={() => setDeleteModal(!deleteModal)}
          />
          <Button
            mode="view"
            type="button"
            onClick={() =>
              setViewRefundAmountDetailsModal(!viewRefundDetailsModal)
            }
          />
          <Button
            mode="clear"
            type="button"
            disabled={!state.studentId}
            onClick={handleClear}
          />
          {pageType === PageFor.GENERAL && (
            <Button mode="back" type="button" onClick={() => navigate(-1)} />
          )}
        </div>
      </form>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewRefundDetailsModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConcessionView
              pageType={PageFor.MODAL}
              setModal={setViewRefundAmountDetailsModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              alt="/"
              src={Close}
              className="modal-close-icon"
              onClick={() =>
                setViewRefundAmountDetailsModal(!viewRefundDetailsModal)
              }
            />
          </div>
        </div>
      </Modal>
      {/* student-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.GENERAL}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentModal(!studentModal)}
              src={CloseImg}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete />
            <Button
              mode="cancel"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;

import React from "react";
import More from "../../../../images/More.svg";
const UpcomingTest = () => {
  return (
    <>
      <div className="teacher-dashboard__flex">
        <b className="teacher-dashboard__flex--title">
          List Of Upcoming Test/Exam
        </b>
        <div className="teacher-dashboard__flex--image">
          <div className="student-count">
            Total<b>o</b>
          </div>
          <img src={More} alt="" />
        </div>
      </div>
    </>
  );
};

export default UpcomingTest;

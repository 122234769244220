import React from "react";
import { Title } from "../../../../stories/Title/Title";
import { TextField } from "@mui/material";
import useAcctLedgerData, {
  FeeLedgerEdge,
} from "../../hooks/useAcctLedgerData";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDepositLedger: React.Dispatch<
    React.SetStateAction<FeeLedgerEdge | null>
  >;
  selectedDepositLedger: FeeLedgerEdge | null;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
}
const AddDepositLedgersModal = ({
  setModalFlag,
  setSelectedDepositLedger,
  selectedDepositLedger,
  setAmount,
}: Props) => {
  const {
    acctLedgers: { scrolledResponseType },
  } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  return (
    <>
      <Title>Add Deposit</Title>
      <div className="add-deposit-ledgers">
        <LabeledAutocomplete
          className={labelClasses.inputRoot}
          options={scrolledResponseType}
          isOptionEqualToValue={(option) =>
            selectedDepositLedger != null &&
            (option as FeeLedgerEdge).node.id === selectedDepositLedger.node.id
          }
          getOptionLabel={(option) => (option as FeeLedgerEdge).node.ldgr_desc}
          onKeyDown={(e) => {
            if (e.key === Keys.ENTER) {
              e.preventDefault();
              if (selectedDepositLedger) {
                setModalFlag(false);
              }
            }
            if (e.key === Keys.BACKSPACE) {
              setSelectedDepositLedger(null);
            }
          }}
          openOnFocus
          value={selectedDepositLedger}
          onChange={(e, newValue) => {
            if (newValue) {
              setSelectedDepositLedger(newValue as FeeLedgerEdge);
            } else {
              setSelectedDepositLedger(null);
              setAmount(0);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Deposit Ledger"
              autoFocus
                     slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
              className={labelClasses.formControlRoot}
            />
          )}
        />

        <div className="add-deposit-ledgers--btns">
          <Button onClick={() => setModalFlag(false)}>Okay</Button>
        </div>
      </div>
    </>
  );
};

export default AddDepositLedgersModal;

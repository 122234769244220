import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { EmpData } from "./useEmployee";
import {
  PayRollEmpMasterNode,
  PayRollEmpMasterNodeForLogin,
} from "../queries/employee/query";
import { CommonNodeVars } from "../Types/masterDataTypes";
import { AppContext } from "../../../context/context";
import { DEFAULT_TIME, NOTALLOCATED } from "../../../utils/constants";
import { HYPHEN } from "../constants";
import { IObjectKeys } from "../../../utils/Form.types";

interface EmpDetailsForm extends IObjectKeys {
  emp_name: string;
  category: string;
  department: string;
  designation: string;
  grade: string;
  emp_experience: number;
  job_type: string;
  emp_qualification: string;
  emp_doj: string;
  emp_sex: string;
  emp_id: string;
  emp_dob: string;
  emp_mobile: string;
  emp_email: string;
  emp_profile_filename: string;
}

const useEmpDetailsById = (login?: boolean, loggedinUser?: boolean) => {
  const { empId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [employeeId, setEmpId] = useState(0);
  const [employeeFormData, setEmployeeFormData] = useState<EmpDetailsForm>({
    emp_name: "",
    category: "",
    department: "",
    designation: "",
    emp_experience: 0,
    job_type: "",
    emp_sex: "",
    emp_qualification: "",
    emp_doj: DEFAULT_TIME,
    emp_id: "",
    emp_dob: "",
    emp_email: "",
    emp_mobile: "",
    grade: "",
    emp_profile_filename: "",
  });
  const [employeeLoginFormData, setEmployeeLoginFormData] = useState({
    emp_name: "",
  });
  const [GetEmpDetails, EmpData] = useLazyQuery<EmpData, CommonNodeVars>(
    PayRollEmpMasterNode,
    {
      variables: {
        id: employeeId,
        token,
      },
      fetchPolicy: "network-only",
    }
  );

  const [GetEmpDetailsForLogin] = useLazyQuery<EmpData, CommonNodeVars>(
    PayRollEmpMasterNodeForLogin,
    {
      variables: {
        id: employeeId,
        token,
      },
      fetchPolicy: "network-only",
    }
  );
  const handleClear = () => {
    setEmployeeFormData({
      emp_name: "",
      emp_experience: 0,
      emp_sex: "",
      emp_qualification: "",
      emp_doj: DEFAULT_TIME,
      emp_id: "",
      emp_dob: "",
      emp_email: "",
      emp_mobile: "",
      grade: "",
      category: "",
      department: "",
      designation: "",
      job_type: "",
      emp_profile_filename: "",
    });
  };

  useEffect(() => {
    if (
      state.employeeId &&
      (loggedinUser === undefined || loggedinUser === false)
    ) {
      setEmpId(state.employeeId);
    } else if (state.empLoginId && loggedinUser) {
      setEmpId(state.empLoginId);
    } else if (empId) {
      setEmpId(Number(empId));
    } else {
      handleClear();
    }
  }, [empId, state, loggedinUser]);

  useEffect(() => {
    if (employeeId && token) {
      if (login) {
        GetEmpDetailsForLogin().then(({ data, error }) => {
          if (data) {
            const { emp_first_name, emp_middle_name, emp_last_name } =
              data.node;

            setEmployeeLoginFormData({
              emp_name: `${emp_first_name} ${emp_middle_name} ${emp_last_name}`,
            });
          }
        });
      } else {
        GetEmpDetails().then(({ data }) => {
          if (data) {
            const {
              emp_first_name,
              emp_middle_name,
              emp_last_name,
              emp_doj,
              pr_dept_details,
              pr_designation_details,
              pr_job_type_details,
              emp_qualification,
              pr_category_details,
              pr_grade_details,
              emp_experience,
              emp_sex,
              emp_id,
              emp_dob,
              emp_email,
              emp_mobile,
              emp_profile_filename,
            } = data.node;

            setEmployeeFormData({
              emp_name: `${emp_first_name} ${emp_middle_name} ${emp_last_name}`,
              category:
                pr_category_details.category_desc === NOTALLOCATED
                  ? HYPHEN
                  : pr_category_details.category_desc,
              department:
                pr_dept_details.dept_desc === NOTALLOCATED
                  ? HYPHEN
                  : pr_dept_details.dept_desc,
              designation:
                pr_designation_details.designation_desc === NOTALLOCATED
                  ? HYPHEN
                  : pr_designation_details.designation_desc,
              emp_experience: emp_experience,
              job_type:
                pr_job_type_details.job_type_desc === NOTALLOCATED
                  ? HYPHEN
                  : pr_job_type_details.job_type_desc,
              emp_qualification,
              emp_doj,
              emp_sex,
              emp_id,
              grade: pr_grade_details.grade_desc,
              emp_dob,
              emp_email,
              emp_mobile,
              emp_profile_filename: emp_profile_filename,
            });
          }
        });
      }
    } // eslint-disable-next-line
  }, [employeeId, GetEmpDetails, GetEmpDetailsForLogin, token, login]);

  return { empolyeeData: EmpData, employeeFormData, employeeLoginFormData };
};

export default useEmpDetailsById;

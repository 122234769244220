import React, { useContext, useEffect, useState } from "react";
import List from "./List";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import InstDetails from "../../DailyActivities/InstDetails";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import useAcademicTableJson from "../../json/useAcademicTable";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT
} from "../../../../styles/DataGridTableStyles";
import {
  InstitutionConfigurationTypes,
  PageFor,
  TableHeaders,
} from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";

import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import {
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import Modal from "react-modal";

import useAcdStdOverAll, {
  GetAcdStdAttOverAllEdges,
} from "../../hooks/useAcdStdOverAll";
import View from "../../../../images/EyeWhite.svg";
import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";
import ViewPerStdAttendance from "../../DailyActivities/Attendance/ViewPerStdAttendance";
import { AcdStdAttSubjQueryType } from "../../DailyActivities/Attendance/SessionAttendance";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import CompleteAttendanceDetails from "../../../Master/Student/Dashboard/Academics/CompleteAttendanceDetails";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { PerStudentAttDrawer } from "../../../../styles/DrawerStyles";

const ViewOverallAtt = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [date, setDate] = useState(new Date());
  const { entryId } = useParams();
  const { OverAttReport } = useAcademicTableJson();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [perStudentAttendance, setPerStudentAttendance] = useState(false);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const [absentList, setAbsentList] = useState(false);

  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const [students, setStudents] = useState<GetAcdStdAttOverAllEdges[]>([]);
  const dynamicHeaders: TableHeaderProps[] = OverAttReport.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      cellClassName: header.className,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const stdId = params.row.std_id;
        return (
          <>
            <button
              className="teacher-wise-allocated-reports__view"
              onClick={() => {
                // if (attStatus?.GetAcdAttStdTodaysStatus) {
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: stdId as number,
                  },
                });

                enablePerSessionAtt
                  ? setAbsentList(!absentList)
                  : setPerStudentAttendance(!perStudentAttendance);

                // }
              }}
            >
              View <img src={View} alt="" />
            </button>
          </>
        );
      },
    },
  ];

  const { OverAllData, loading, fetchMore } = useAcdStdOverAll(
    entryId ? Number(entryId) : 0,
    entryId ? Number(entryId) : 0,
    entryId ? Number(entryId) : 0,
    entryId ? Number(entryId) : 0,
    entryId ? Number(entryId) : 0,
    TODAY_DATE,
    AcdStdAttSubjQueryType.OVER_ALL_ATT_AT_ENTRY_LEVEL
  );

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStdAttOverAll.edges;
                const pageInfo = fetchMoreResult.GetAcdStdAttOverAll.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetAcdStdAttOverAll.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcdStdAttOverAll: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: OverAllData
                      ? OverAllData.GetAcdStdAttOverAll.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (OverAllData && !loading) {
      const newData = OverAllData.GetAcdStdAttOverAll?.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.student_details.std_adm_no,
            reg_no: node.student_details.std_reg_no,
            name: `${node.student_details.first_name} ${node.student_details.middle_name} ${node.student_details.last_name}`,
            percentage: node.over_all_per,
            std_id: node.student_details.id,
            att_held: node.total_session,
            total_present: node.total_session_present,
            total_absent: node.total_session_absent,
            total_perc: `${node.over_all_per} %`,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: index + 1,
            adm_no: node.student_details.std_adm_no,
            reg_no: node.student_details.std_reg_no,
            name: `${node.student_details.first_name} ${node.student_details.middle_name} ${node.student_details.last_name}`,
            percentage: node.over_all_per,
            std_id: node.student_details.id,
            att_held: node.total_session,
            total_present: node.total_session_present,
            total_absent: node.total_session_absent,
            total_perc: `${node.over_all_per} %`,
          }))
        );
      }
      setEndCursor(OverAllData.GetAcdStdAttOverAll.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [OverAllData, loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="view-overall-att">
        <div className="view-overall-att__left">
          <List />
        </div>
        <div className="view-overall-att__right">
          <InstDetails />
          <div className={`view-overall-att__tableblock`}>
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              hideFooter
 
            />
          </div>
          <Button mode="excel">Export</Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <PerStudentAttDrawer
    
        anchor="right"
        open={perStudentAttendance}
        onClose={() => setPerStudentAttendance(!perStudentAttendance)}
      >
        <ViewPerStdAttendance
          selectedDate={date}
          setSelectedDate={setDate}
          setPerStudentAttendance={setPerStudentAttendance}
          perStudentAttendance={perStudentAttendance}
          pageType={PageFor.REPORT}
        />
      </PerStudentAttDrawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={absentList}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CompleteAttendanceDetails setModalFlag={setAbsentList} pageType={PageFor.GENERAL} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAbsentList(!absentList)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewOverallAtt;

import { gql } from "@apollo/client";

export const GetAcctDashBoardDetails = gql`
  query GetAcctDashBoardDetails(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $today_date: Time!
    $query_type: AcctDashboardQueryType!
  ) {
    GetAcctDashBoardDetails(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      today_date: $today_date
      query_type: $query_type
    ) {
      total_fee_collection
      total_receipts_count
      fee_collection_bank
      fee_collection_cash
      cash_receipts_count
      bank_receipts_count
      total_payments
      total_payments_count
      payments_bank
      payments_cash
      cash_payments_count
      bank_payments_count
      total_balance
      bank_balance
      cash_balance
      total_demand
      total_received
      total_demand_balance
      upi_receipts_count
      cheque_receipts_count
      dd_receipts_count
      neft_receipts_count
      imps_receipts_count
      cardswipe_receipts_count
      total_std_demand
total_std_ob
total_std_concession
total_std_refund
    }
  }
`;
export const GetOwnersAcctDashBoardDetails = gql`
  query GetOwnersAcctDashBoardDetails(
    $token: String!
    $cust_id: ID!
    $today_date: Time!
    $query_type: AcctDashboardQueryType!
  ) {
    GetOwnersAcctDashBoardDetails(
      token: $token
      cust_id: $cust_id
      today_date: $today_date
      query_type: $query_type
    ) {
      cust_total_stats {
        total_fee_collection
        total_receipts_count
        fee_collection_bank
        fee_collection_cash
        cash_receipts_count
        bank_receipts_count
        total_payments
        total_payments_count
        payments_bank
        payments_cash
        cash_payments_count
        bank_payments_count
        total_balance
        bank_balance
        cash_balance
        total_demand
        total_received
        total_demand_balance
        upi_receipts_count
        cheque_receipts_count
        dd_receipts_count
        neft_receipts_count
        imps_receipts_count
        cardswipe_receipts_count
        total_std_demand
total_std_ob
total_std_concession
total_std_refund
      }
      inst_total_stats {
        total_fee_collection
        total_receipts_count
        fee_collection_bank
        fee_collection_cash
        cash_receipts_count
        bank_receipts_count
        total_payments
        total_payments_count
        payments_bank
        payments_cash
        cash_payments_count
        bank_payments_count
        total_balance
        bank_balance
        cash_balance
        total_demand
        total_received
        total_demand_balance
        upi_receipts_count
        cheque_receipts_count
        dd_receipts_count
        neft_receipts_count
        imps_receipts_count
        cardswipe_receipts_count
        inst_name
        inst_id
      }
    }
  }
`;

export const GetFinYrsByInstId = gql`
  query GetFinYrsByInstId($token: String!, $inst_id: ID!) {
    GetFinYrsByInstId(token: $token, inst_id: $inst_id) {
      id
      fin_yr
      fin_st_date
      fin_end_date
      fin_is_curr_yr
      inst_id
    }
  }
`;

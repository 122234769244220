import React, { useEffect } from "react";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";
import TotalStudents from "../../../../images/StudentListIcon.svg";
import TotalPresent from "../../../../images/TotalStudentsPresent.svg";
import TotalAbsent from "../../../../images/TotalStudentsAbsent.svg";
import Percentage from "../../../../images/AttendanceOverallPercentage.svg";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { useLazyQuery } from "@apollo/client";
import { GetAttDashBoardCount } from "../../../../queries/common";
import { TODAY_DATE } from "../../../../utils/constants";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import Attendance from "./graph/Attendance";
import usePollIntervalTime from "../../../../customhooks/usePollIntervalTime";

const StudentAttStatus = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const pollIntervalTime = usePollIntervalTime();

  const [GetAttendanceDashBoardData, { data: DashBoardData }] = useLazyQuery(
    GetAttDashBoardCount,
    { pollInterval: pollIntervalTime }
  );
  useEffect(() => {
    if (
      token &&
      InstId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading
    ) {
      GetAttendanceDashBoardData({
        variables: {
          token,
          inst_id: InstId,
          acd_yr_id: activeAcdId,
          date_of_attendance: toIsoDate(TODAY_DATE),
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    GetAttendanceDashBoardData,
    activeAcademicYearData.loading,
    activeAcademicYearData.data,
    InstId,
  ]);

  return (
    <>
      <div className="acd-admin-dashboard__card--title">
        <Title variant="subtitle1">Today's Student Att. Status</Title>
        <img src={More} alt="" />
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={TotalStudents} alt="" />
          <span>Total Students</span>
        </div>
        <b className="font-blue">
          {DashBoardData && DashBoardData.GetAttDashBoardCount.total_students}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={TotalPresent} alt="" />
          <span>Total Students Present</span>
        </div>
        <b className="font-green">
          {DashBoardData &&
            DashBoardData.GetAttDashBoardCount.total_students_present}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img src={TotalAbsent} alt="" />
          <span>Total Students Absent</span>
        </div>
        <b className="font-red">
          {DashBoardData &&
            DashBoardData.GetAttDashBoardCount.total_students_absent}
        </b>
      </div>
      <div className="acd-admin-dashboard__card--details">
        <div>
          <img
            src={Percentage}
            alt=""
            className="acd-admin-dashboard__card--details--pers"
          />
          <span>Overall Percentage</span>
        </div>
        <b className="font-purple">
          {DashBoardData && DashBoardData.GetAttDashBoardCount.att_percentage}%
        </b>
      </div>
      <Attendance />
    </>
  );
};

export default StudentAttStatus;

import { gql } from "@apollo/client";

export const AddTransportRouteMaster = gql`
  mutation AddTransportRouteMaster(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateTransportRouteMasterInput!
  ) {
    AddTransportRouteMaster(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdateTransportRouteMaster = gql`
  mutation UpdateTransportRouteMaster(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateTransportRouteMasterInput!
  ) {
    UpdateTransportRouteMaster(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteTransportRouteMasterById = gql`
  mutation DeleteTransportRouteMasterById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteTransportRouteMasterById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddTransportRouteDetails = gql`
  mutation AddTransportRouteDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [CreateTransportRouteDetailsInput!]!
  ) {
    AddTransportRouteDetails(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const DeleteTransportRouteDetailsById = gql`
  mutation DeleteTransportRouteDetailsById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteTransportRouteDetailsById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddTransportStudent = gql`
  mutation AddTransportStudent(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $route_detail_id: ID!
    $student_ids: [ID!]!
  ) {
    AddTransportStudent(
      token: $token
      inst_id: $inst_id
      user_details: $user_details

      route_detail_id: $route_detail_id
      student_ids: $student_ids
    )
  }
`;
export const DeleteTransportStudent = gql`
  mutation DeleteTransportStudent(
    $token: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteTransportStudent(
      token: $token
      student_id: $student_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdateTransportStudent = gql`
  mutation UpdateTransportStudent(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateTransportStudent!
  ) {
    UpdateTransportStudent(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateTransportRouteDetails = gql`
  mutation UpdateTransportRouteDetails(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateTransportRouteDetailsInput!
  ) {
    UpdateTransportRouteDetails(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const AddOrUpdateOrDeleteTransportRouteDetails = gql`
  mutation AddOrUpdateOrDeleteTransportRouteDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $update_route_details: [UpdateRouteDetails]
    $add_route_details: [CreateTransportRouteDetailsInput]
    $delete_route_details: [DeleteRouteDetails]
  ) {
    AddOrUpdateOrDeleteTransportRouteDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: {
        update_route_details: $update_route_details
        add_route_details: $add_route_details
        delete_route_details: $delete_route_details
      }
    )
  }
`;
export const AddTransportRouteFee = gql`
  mutation AddTransportRouteFee(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateTransportRouteFeeInput!
  ) {
    AddTransportRouteFee(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdateTransportRouteFee = gql`
  mutation UpdateTransportRouteFee(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateTransportRouteFeeInput!
  ) {
    UpdateTransportRouteFee(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const DeleteTransportRouteFee = gql`
  mutation DeleteTransportRouteFee(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteTransportRouteFee(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

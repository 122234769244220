import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import usePredefindedSalaryLedgers from "../../HR/hooks/usePredefindedSalaryLedgers";
import EditProfile from "../../../images/EditProfile.svg";

import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { SalaryledgerTypeOptions } from "../../HR/constants";

const { HR_Table } = require("../../HR/json/table.json");

interface Props {
  setFormDataForUpdate: (id: number) => void;
}

const EduateSalaryLedgerList = ({ setFormDataForUpdate }: Props) => {
  const {
    predefinedSalaryledger: { data, error },
  } = usePredefindedSalaryLedgers();

  return (
    <>
      <div className="row g-0 salary-ledger__block--title">
        <Title variant="subtitle1">Earning Ledgers</Title>
      </div>
      <div className="salary-ledger__block--data">
        {error ? (
          <b className="nodata">{error.message}</b>
        ) : data?.GetSwSalaryLdgrs.length! > 0 ? (
          <>
            <TableContainer className="salary-ledger__block--data--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {HR_Table.SalaryLedger.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.GetSwSalaryLdgrs.length! > 0 ? (
                    data?.GetSwSalaryLdgrs.map((response, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            id="td-center"
                            className="salary-ledger__block--data--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell>{response.sal_ldgr_desc}</TableCell>
                          <TableCell className="salary-ledger__block--data--table--shortdesc">
                            {response.sal_ldgr_short_desc}
                          </TableCell>
                          <TableCell className="salary-ledger__block--data--table--shortdesc">
                            {
                              SalaryledgerTypeOptions.find(
                                ({ value }) =>
                                  value === response.sal_ldgr_earn_deduct
                              )?.label
                            }
                          </TableCell>
                          <TableCell className="salary-ledger__block--data--table--type">
                            {response.sal_ldgr_type}
                          </TableCell>
                          <TableCell
                            className="salary-ledger__block--data--table--actions"
                            id="td-center"
                          >
                            <img
                              src={EditProfile}
                              alt="/"
                              onClick={() => {
                                setFormDataForUpdate(response.id);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <b className="nodata">Earning Ledgers are empty</b>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <b className="nodata">No data Please try adding...</b>
        )}
      </div>
    </>
  );
};

export default EduateSalaryLedgerList;

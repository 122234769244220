import { gql } from "@apollo/client";

export const ChannelDetails = gql`
  query ChannelDetails($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on MsgChannelMaster {
        channel_name
        channel_desc
      }
    }
  }
`;

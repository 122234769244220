/**
 * Renders a block of details for a dashboard balance.
 *
 * @component
 * @example
 * ```typescriptreact
 * <DashboardBalance
 *   title="Balance"
 *   mainImageSrc="../../../images/balance.svg"
 *   totalBalance={1000}
 *   subtitle1="Income"
 *   subtitle2="Expenses"
 *   subtitle1ImgSrc="../../../images/income.svg"
 *   subtitle2ImgSrc="../../../images/expenses.svg"
 *   subtitle1Balance={500}
 *   subtitle2balance={-300}
 * />
 * ```
 */
import React from "react";
import { Title } from "../../../stories/Title/Title";
import More from "../../../images/More.svg";
import { formatter } from "../../../utils/UtilFunctions";

interface DashboardBalanceProps {
  title: string;
  mainImageSrc: string;
  totalBalance: number;
  subtitle1: string;
  subtitle1ImgSrc: string;
  subtitle1Balance: number;
  subtitle2: string;
  subtitle2ImgSrc: string;
  subtitle2balance: number;
}

const DashboardBalance: React.FC<DashboardBalanceProps> = ({
  title,
  mainImageSrc,
  totalBalance,
  subtitle1,
  subtitle2,
  subtitle1ImgSrc,
  subtitle2ImgSrc,
  subtitle1Balance,
  subtitle2balance,
}) => {
  const { format } = formatter;

  /**
   * Formats the balance amount based on its value.
   *
   * @param {number} amount - The balance amount to format.
   * @returns {string} The formatted balance amount.
   */
  const formatBalance = (amount: number): string => {
    const formattedBalance =
      amount < 0 ? `(${format(Math.abs(amount))})` : format(Math.abs(amount));

    return formattedBalance;
  };

  /**
   * Returns a CSS class name based on the balance amount.
   *
   * @param {number} amount - The balance amount.
   * @returns {string} The CSS class name.
   */
  const GetClassName = (amount: number): string => {
    return amount > 99999 ? "font-14" : "";
  };

  return (
    <div className="accounts-dashboard__details--block">
      <div className="accounts-dashboard__details--block--title">
        <Title variant="subtitle1">{title}</Title>
        <img src={More} alt="/" />
      </div>
      <div className="accounts-dashboard__details--block--details">
        <img src={mainImageSrc} alt="/" />
        <div className="accounts-dashboard__details--block--g-count--block">
          <span
            className={`accounts-dashboard__details--block--details--total ${GetClassName(
              totalBalance
            )}`}
          >
            {formatBalance(totalBalance)}
          </span>
        </div>
      </div>

      <div className="accounts-dashboard__details--block--g-count">
        <div className="accounts-dashboard__details--block--g-count--flex separator">
          <img src={subtitle1ImgSrc} alt="/" />
          <div className="accounts-dashboard__details--block--g-count--block">
            <span className="accounts-dashboard__details--block--g-count--block--text">
              {subtitle1}
            </span>
            <span
              className={`accounts-dashboard__details--block--g-count--block--total ${GetClassName(
                subtitle1Balance
              )}`}
            >
              {formatBalance(subtitle1Balance)}
            </span>
          </div>
        </div>
        <div className="accounts-dashboard__details--block--g-count--flex">
          <img src={subtitle2ImgSrc} alt="/" />
          <div className="accounts-dashboard__details--block--g-count--block">
            <span className="accounts-dashboard__details--block--g-count--block--text">
              {subtitle2}
            </span>
            <span
              className={`accounts-dashboard__details--block--g-count--block--total ${GetClassName(
                subtitle2balance
              )}`}
            >
              {formatBalance(subtitle2balance)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBalance;

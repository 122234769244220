import {
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../components/common/Input/Index";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import DownArrow from "../../../../images/DownArrow.svg";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
} from "../../../../utils/constants";
import { Operation } from "../../../../utils/Enum.types";
import {
  handleMUISelectEvent,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { LabelNameProps } from "../../../../Types/Labels";
import { AntSwitch } from "../../../../pages/Switch";
import { TestCreationDetails } from "../../types/test/index";
import { Form, Formik } from "formik";
import { TestCreationValidation } from "../../../../utils/academicValidation";
import { useLazyQuery, useMutation } from "@apollo/client";
import LoadingModal from "../../../../pages/LoadingModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import useToken from "../../../../customhooks/useToken";
import useAcademicYear from "../../hooks/useActiveAcademicYear";
import MessageModal from "../../../../pages/MessageModal";

import { AddAcdTestName, UpdateAcdTestName } from "../../queries/test/mutation";
import { Keys } from "../../../../utils/Enum.keys";
import { GetAcdTestNameDetails, TestTypeDetails } from "../../hooks/useTests";
import { GetAcdTestName, GetAcdTestNameById } from "../../queries/test/query";
import useAcdTestTypes from "../../hooks/useAcdTestTypes";
import { singleNodeVars } from "../../../../Types/Accounting";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { FormAutocomplete, formClasses } from "../../../../styles/AutocompleteStyles";

const { AcademicsDetails } = require("../../json/formLabels.json");
export interface GetAcdTestNameByIdData {
  node: GetAcdTestNameDetails;
}
export interface GetAcdTestTypeByIdData {
  node: TestTypeDetails;
}
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  setOperation: React.Dispatch<React.SetStateAction<Operation>>;
  testId?: number;
}
const TestCreation = ({ setModal, operation, setOperation, testId }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { activeAcademicYearData } = useAcademicYear();
  const { user_details } = useLoggedInUserDetails();

  const [formData, setFormData] = useState<TestCreationDetails>({
    show_marks_in_grade: false,
    test_name: "",
    is_marks_derived: false,
    is_non_academic: false,
    show_marks_in_std_login: true,
    add_test_remarks: false,
  });

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [testType, setTestType] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [GetTestTypeDetails] = useLazyQuery<
    GetAcdTestNameByIdData,
    singleNodeVars
  >(GetAcdTestNameById, { variables: { id: testId ? testId : 0, token } });

  const { acdTestTypes } = useAcdTestTypes();
  const [AddNewTest, { loading: creationTestLoading }] = useMutation(
    AddAcdTestName,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [updateTest, { loading: updationTestLoading }] = useMutation(
    UpdateAcdTestName,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSwitch = (e: React.MouseEvent) => {
    if ((e.target as HTMLInputElement).name) {
      setFormData((prevValues) => ({
        ...prevValues,
        [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement)
          .checked,
      }));
    }
  };

  const handleCreationOfTests = () => {
    const {
      show_marks_in_grade,
      show_marks_in_std_login,
      is_non_academic,
      add_test_remarks,
    } = formData;
    if (operation === Operation.CREATE) {
      AddNewTest({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
          input: {
            show_marks_in_grade,
            test_name: removeMoreSpace(formData.test_name),
            test_type_id: testType.value,
            show_marks_in_std_login,
            is_non_academic,
            is_marks_derived: formData.is_marks_derived,
            add_test_remarks,
          },
        },
        refetchQueries: [
          {
            query: GetAcdTestName,
            variables: {
              inst_id: InstId!,
              for_planning: false,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              alloted_level: "",
              allotted_id: 0,
              test_type_id: 0,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Test Name Created Sucessfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else
      updateTest({
        variables: {
          token,
          id: testId,
          inst_id: InstId,
          user_details,
          input: {
            show_marks_in_grade,
            test_name: removeMoreSpace(formData.test_name),
            test_type_id: testType.value,
            show_marks_in_std_login,
            is_non_academic,
            is_marks_derived: formData.is_marks_derived,
            add_test_remarks,
          },
        },
        refetchQueries: [
          {
            query: GetAcdTestName,
            variables: {
              inst_id: InstId!,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              alloted_level: EMPTY_STRING,
              allotted_id: 0,
              for_planning: false,
              test_type_id: testType ? testType.value : 0,
              token,
            },
          },
          {
            query: GetAcdTestNameById,
            variables: { id: testId, token },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Test Name Updated Sucessfully",
            operation: Operation.UPDATE,
          });
        }
      });
  };

  const handleClear = () => {
    setFormData({
      lab_max_mark: 0,
      lab_min_mark: 0,
      show_marks_in_grade: false,
      test_max_mark: 0,
      test_min_mark: 0,
      test_name: "",
      is_marks_derived: false,
      is_non_academic: false,
      show_marks_in_std_login: false,
      add_test_remarks: false,
    });
    setTestType(EMPTY_RESPONSETYPE_OBJECT);
    setOperation(Operation.CREATE);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (testId) {
        const { data } = await GetTestTypeDetails();
        if (data && data.node) {
          const {
            show_marks_in_grade,
            test_name,
            is_marks_derived,
            is_non_academic,
            show_marks_in_std_login,
            add_test_remarks,
          } = data.node;
          setFormData({
            show_marks_in_grade,
            test_name,
            is_marks_derived,
            is_non_academic,
            show_marks_in_std_login,
            add_test_remarks,
          });
          const res = acdTestTypes.responseType.find(
            (d) => d.value === data.node.test_type_id
          );
          res && setTestType(res);
        }
      }
    };
    fetchData();
  }, [testId, GetTestTypeDetails, acdTestTypes.responseType]);

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={TestCreationValidation}
        enableReinitialize
        onSubmit={handleCreationOfTests}
      >
        {(meta) => {
          return (
            <Form className={"academic-test-creation--modal"}>
              <div className="row g-0 academic-test-creation__data">
                <div>
                  <Title variant={"h6"}>
                    {operation === Operation.UPDATE
                      ? "Update Test "
                      : "Create Test"}
                  </Title>
                  <div>
                    {AcademicsDetails.TestCreation.InputType.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              autoFocus={true}
                              type={label.dataType}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              required={label.required ? true : false}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>Test Type</Label>
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        options={acdTestTypes.responseType}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setTestType(newValue as responseType);
                          } else {
                            setTestType(EMPTY_RESPONSETYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setTestType(EMPTY_RESPONSETYPE_OBJECT);
                          } else if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        value={testType}
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                    </div>
                    {AcademicsDetails.TestCreation.SwitchType.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <FormGroup key={index}>
                            <FormControlLabel
                              label={label.LabelName}
                              name={label.inputName}
                              checked={formData[label.inputName] as boolean}
                              control={
                                <AntSwitch
                                  onClick={(e: React.MouseEvent) =>
                                    handleSwitch(e)
                                  }
                                  onKeyDown={(e: React.KeyboardEvent) => {
                                    if (e.key === Keys.ENTER) {
                                      handleMUISelectEvent(e);
                                    }
                                  }}
                                />
                              }
                              labelPlacement="start"
                            />
                          </FormGroup>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>

              <div>
                <Button mode="save" type="submit" />

                <Button
                  mode="cancel"
                  type="button"
                  onClick={() => setModal(false)}
                />
              </div>
            </Form>
          );
        }}
      </Formik>

      <LoadingModal flag={creationTestLoading || updationTestLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default TestCreation;

import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import * as Excel from "exceljs";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  GetInstMasterDataDetails,
  GetInstMasterDataVars,
} from "../../../../customhooks/useInstMasterDataByInstId";
import useToken from "../../../../customhooks/useToken";
import { Button } from "../../../../stories/Button/Button";
import Home from "../../Home/Index";
import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../../../images/TotalMale.svg";
import ReadyToImport from "../../../../images/Present.svg";
import ContainsError from "../../../../images/Absent.svg";
import Info from "../../../../images/Information.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import FileAttach from "../../../../images/BrowseFiles.svg";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import { ValidateStudentDataForImport } from "../../../../queries/xls";
import { Title } from "../../../../stories/Title/Title";
import { ImportStudentDataFromXls } from "../../../../queries/xls/mutation";
import {
  toIsoDate,
  convertToDateObject_MMDDYYYYFormat,
  getHeaderRowStyle,
  toStandardDate,
  handleClear,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { _genderRegex } from "../../../../utils/validationRules";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  TableHeaders,
} from "../../../../utils/Enum.types";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { HEADER_ALIGNMENT_CENTER } from "../../../Library/Constants";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useMstInstTree from "../../../../customhooks/useMstInstTree";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  LoadingStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import StudentsConfiguration, {
  StudentSwConfigType,
} from "../../../Eduate/configurations/StudentConfiguration/Index";
import Validate from "../../../../images/Generate.svg";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
} from "../../../../Types/configtypes";
import { GetSwConfigVariables } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";
import ExcelLoading from "../../../../pages/ExcelLoading";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import { CustomTooltip } from "../../../../styles/TooltipStyles";
interface ValidateStudentDataForImportDataInput {
  first_name: string;
  middle_name?: string;
  last_name?: string;
  std_father_name: string;
  std_dob: string;
  std_email: string;
  std_mobile: string;
  dept_name: string;
  branch_name: string;
  class_name: string;
  semester_name: string;
  category_name: string;
  section_name: string;
}
interface ValidateStudentDataForImportVars {
  token: string;
  inst_id: string;
  input: ValidateStudentDataForImportDataInput;
}
interface ValidateStudentDataForImportData {
  ValidateStudentDataForImport: boolean;
}
interface dr {
  Class: string;
  Department: string;
  Branch: string;
  Semester: string;
  Section: string;
  Category: string;
}

const StudentBasicDataImport = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();

  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const [stdConfig, setStdConfig] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [schema, setSchema] = useState<any>();

  const { configData: middleName } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME
  );
  const { configData: lastName } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_LAST_NAME
  );

  const middleNameFlag =
    middleName?.data?.GetSwConfigVariables[0].config_boolean_value;
  const lastNameFlag =
    lastName?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [records, setRecords] = useState<any[]>([]);

  const [GetMasterDetails, { data }] = useLazyQuery<
    GetInstMasterDataDetails,
    GetInstMasterDataVars
  >(GetInstMasterData, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  const PrimaryContactType = [
    { label: "Father", value: "FATHER" },
    { label: "Mother", value: "MOTHER" },
    { label: "Guardian", value: "GUARDIAN" },
    { label: "Student", value: "STUDENT" },
  ];
  const [importModal, setImportModal] = useState(false);
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const { data: treeData } = useMstInstTree();
  const [ValidateEachRow] = useLazyQuery<
    ValidateStudentDataForImportData,
    ValidateStudentDataForImportVars
  >(ValidateStudentDataForImport);
  const [AddStudent, { loading }] = useMutation(ImportStudentDataFromXls);
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_STUDENT_LOGIN
  );
  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;

  const {
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel,
  } = useInstLabels();
  const classDetails = data?.GetInstMasterData.class_details || [];
  const deptDetails = data?.GetInstMasterData.dept_details || [];
  const branchDetails = data?.GetInstMasterData.branch_details || [];
  const sectionDetails = data?.GetInstMasterData.section_details || [];
  const semesterDetails = data?.GetInstMasterData.semester_details || [];
  const categoryDetails = data?.GetInstMasterData.category_details || [];
  const maxLength = Math.max(
    classDetails.length,
    deptDetails.length,
    branchDetails.length,
    sectionDetails.length,
    semesterDetails.length,
    categoryDetails.length
  );
  let arrayTreeMapedData: dr[] = [];
  for (let index = 0; index < maxLength; index++) {
    const classe = classDetails[index]?.class_desc;
    const dept = deptDetails[index]?.dept_desc;
    const branche = branchDetails[index]?.branch_desc;
    const sectione = sectionDetails[index]?.section_desc;
    const semestere = semesterDetails[index]?.sem_desc;
    const categorye = categoryDetails[index]?.cat_desc;

    arrayTreeMapedData.push({
      Class: classe || "",
      Department: dept || "",
      Branch: branche || "",
      Semester: semestere || "",
      Section: sectione || "",
      Category: categorye || "",
    });
  }
  const [GetConfigdata, { data: stsData }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId ? InstId : state.InstId,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: "INSTITUTION_TYPE",
        int_value: 1,
      },
    },
  });
  const [message, setMessage] = useState<msgType>({
    message: EMPTY_STRING,
    flag: false,
    operation: Operation.NONE,
  });
  const stsNoFlag =
    stsData && stsData.GetSwConfigVariables[0].config_string_value;
  const [studentSwDataForModal, setStudentSwDataForModal] = useState<
    StudentSwConfigType[]
  >([]);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const getHeaderUseInSheetStatus = (headerKey: string) => {
    const configEntry = studentSwDataForModal.find(
      (entry) => entry.data_field_name === headerKey
    );
    if (configEntry) {
      return configEntry.data_field_enable;
    }
    return false;
  };
  const instConfig = [
    {
      Headers: departmentLabel,
      bool: USE_DEPARTMENT_KEY,
      key: "Department",
      width: 25,
    },
    {
      Headers: branchLabel,
      bool: USE_BRANCH_KEY,
      key: "Branch",
      width: 25,
    },

    {
      Headers: classLabel,
      bool: USE_CLASS_KEY,
      key: "Class",
      width: 25,
    },
    {
      Headers: semesterLabel,
      bool: USE_SEMESTER_KEY,
      key: "Semester",
      width: 25,
    },
    {
      Headers: sectionLabel,
      bool: USE_SECTION_KEY,
      key: "Section",
      width: 25,
    },
    {
      Headers: categoryLabel,
      bool: USE_CATEGORY_KEY,
      key: "Category",
      width: 25,
    },
    {
      Headers: "Primary Contact",
      bool: true,
      key: "Primary",
      width: 25,
    },
  ];
  const studentData = [
    {
      Headers: "AdmissionNumber",
      key: "adm_no",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "RegisterNo",
      key: "std_reg_no",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    stsNoFlag === "SCH"
      ? {
          Headers: "STSno",
          key: "std_sts_no",
          width: 50,
          required: false,
          use_in_sheet: true,
        }
      : {},
    {
      Headers: "FirstName",
      key: "firstname",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "MiddleName",
      key: "middlename",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: middleNameFlag,
    },
    {
      Headers: "LastName",
      key: "lastname",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: lastNameFlag,
    },
    {
      Headers: "Email",
      key: "email",
      width: 50,
      required: configData.data?.GetSwConfigVariables[0].config_boolean_value,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "ContactNumber",
      key: "contactnumber",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "FatherName",
      key: "fathername",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "FatherMobile",
      key: "fatherMobile",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "FatherEmail",
      key: "fatherEmail",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "MotherName",
      key: "mothername",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "MotherMobile",
      key: "motherMobile",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "MotherEmail",
      key: "motherEmail",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "GuardianName",
      key: "guardianName",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "GuardianMobile",
      key: "guardianMobile",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "GuardianEmail",
      key: "guardianEmail",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "PrimaryContact",
      key: "primaryContact",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "DateOfBirth",
      key: "dob",
      width: 50,
      required: true,
      type: String,
      message: "requires",
      use_in_sheet: true,
    },

    {
      Headers: "Gender",
      key: "sex",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Fresher",
      key: "fresher",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: departmentLabel,
      width: 50,
      required: USE_DEPARTMENT_KEY,
      type: String,
      key: "Department",
      use_in_sheet: USE_DEPARTMENT_KEY,
    },
    {
      Headers: branchLabel,
      width: 50,
      required: USE_BRANCH_KEY,
      type: String,
      key: "Branch",
      use_in_sheet: USE_BRANCH_KEY,
    },
    {
      Headers: classLabel,
      width: 50,
      required: USE_CLASS_KEY,
      type: String,
      key: "class",
      use_in_sheet: USE_CLASS_KEY,
    },
    {
      Headers: semesterLabel,
      width: 50,
      required: USE_SEMESTER_KEY,
      type: String,
      key: "Sem",
      use_in_sheet: USE_SEMESTER_KEY,
    },
    {
      Headers: sectionLabel,
      width: 50,
      required: USE_SECTION_KEY,
      type: String,
      key: "section",
      use_in_sheet: USE_SECTION_KEY,
    },
    {
      Headers: categoryLabel,
      width: 50,
      required: USE_CATEGORY_KEY,
      type: String,
      key: "category",
      use_in_sheet: USE_CATEGORY_KEY,
    },
    {
      Headers: "DateOfAdmission",
      width: 50,
      required: false,
      type: String,
      key: "doa",
      use_in_sheet: true,
    },
    {
      Headers: "Aadhar",
      width: 50,
      required: false,
      type: String,
      key: "std_aadhar",
      use_in_sheet: getHeaderUseInSheetStatus("std_aadhar"),
    },

    {
      Headers: "BloodGroup",
      width: 50,
      required: false,
      type: String,
      key: "std_blood_group",
      use_in_sheet: getHeaderUseInSheetStatus("std_blood_group"),
    },
    {
      Headers: "Address",
      width: 50,
      required: false,
      type: String,
      key: "std_address",
      use_in_sheet: getHeaderUseInSheetStatus("std_address"),
    },
    {
      Headers: "City",
      width: 50,
      required: false,
      type: String,
      key: "std_place",
      use_in_sheet: getHeaderUseInSheetStatus("std_place"),
    },
    {
      Headers: "District",
      width: 50,
      required: false,
      type: String,
      key: "std_district",
      use_in_sheet: getHeaderUseInSheetStatus("std_district"),
    },
    {
      Headers: "State",
      width: 50,
      required: false,
      type: String,
      key: "std_state",
      use_in_sheet: getHeaderUseInSheetStatus("std_state"),
    },
    {
      Headers: "PinCode",
      width: 50,
      required: false,
      type: String,
      key: "std_pincode",
      use_in_sheet: getHeaderUseInSheetStatus("std_pincode"),
    },
    {
      Headers: "Telno",
      width: 50,
      required: false,
      type: String,
      key: "std_phone",
      use_in_sheet: getHeaderUseInSheetStatus("std_phone"),
    },

    {
      Headers: "CorrespondingAddress",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_address",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_address"),
    },
    {
      Headers: "CorrespondingCity",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_place",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_place"),
    },
    {
      Headers: "CorrDistrict",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_district",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_district"),
    },
    {
      Headers: "CorrState",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_state",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_state"),
    },
    {
      Headers: "CorrPinCode",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_pincode",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_pincode"),
    },
    {
      Headers: "CorrTelephone",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_phone",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_phone"),
    },
    {
      Headers: "Religion",
      width: 50,
      required: false,
      type: String,
      key: "std_religion",
      use_in_sheet: getHeaderUseInSheetStatus("std_religion"),
    },
    {
      Headers: "Caste",
      width: 50,
      required: false,
      type: String,
      key: "std_caste",
      use_in_sheet: getHeaderUseInSheetStatus("std_caste"),
    },
    {
      Headers: "SubCaste",
      width: 50,
      required: false,
      type: String,
      key: "std_sub_caste",
      use_in_sheet: getHeaderUseInSheetStatus("std_sub_caste"),
    },
    {
      Headers: "CasteGroup",
      width: 50,
      required: false,
      type: String,
      key: "std_caste_group",
      use_in_sheet: getHeaderUseInSheetStatus("std_caste_group"),
    },
    {
      Headers: "SCST",
      width: 50,
      required: false,
      type: String,
      key: "std_sc_st",
      use_in_sheet: getHeaderUseInSheetStatus("std_sc_st"),
    },
    {
      Headers: "Minority",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_caste_minority",
      use_in_sheet: getHeaderUseInSheetStatus("std_caste_minority"),
    },
    {
      Headers: "Area",
      width: 50,
      required: false,
      type: String,
      key: "std_rural_urban",
      use_in_sheet: getHeaderUseInSheetStatus("std_rural_urban"),
    },
    {
      Headers: "MotherTongue",
      width: 50,
      required: false,
      type: String,
      key: "std_mother_tongue",
      use_in_sheet: getHeaderUseInSheetStatus("std_mother_tongue"),
    },
    {
      Headers: "AnnualIncome",
      width: 50,
      required: false,
      type: Number,
      key: "std_annual_income",
      use_in_sheet: getHeaderUseInSheetStatus("std_annual_income"),
    },
    {
      Headers: "Married",
      width: 50,
      required: false,
      type: String,
      key: "std_martial_status",
      use_in_sheet: getHeaderUseInSheetStatus("std_martial_status"),
    },

    {
      Headers: "IdentityMarks",
      width: 50,
      required: false,
      type: String,
      key: "std_identity_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_identity_marks"),
    },
    {
      Headers: "PlaceofBirth",
      width: 50,
      required: false,
      type: String,
      key: "std_place_of_birth",
      use_in_sheet: getHeaderUseInSheetStatus("std_place_of_birth"),
    },
    {
      Headers: "Nationality",
      width: 50,
      required: false,
      type: String,
      key: "std_nationality",
      use_in_sheet: getHeaderUseInSheetStatus("std_nationality"),
    },
    {
      Headers: "PhysicalChallenged",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_is_phy_challenged",
      use_in_sheet: getHeaderUseInSheetStatus("std_is_phy_challenged"),
    },
    {
      Headers: "NearestPoliceStation",
      width: 50,
      required: false,
      type: String,
      key: "std_nearest_police_station",
      use_in_sheet: getHeaderUseInSheetStatus("std_nearest_police_station"),
    },
    {
      Headers: "NearestRailwayStation",
      width: 50,
      required: false,
      type: String,
      key: "std_nearest_railway_station",
      use_in_sheet: getHeaderUseInSheetStatus("std_nearest_railway_station"),
    },
    {
      Headers: "Kmsfromresidence",
      width: 50,
      required: false,
      type: String,
      key: "std_km_from_residence",
      use_in_sheet: getHeaderUseInSheetStatus("std_km_from_residence"),
    },
    {
      Headers: "BelongstoKashmir",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_belongs_to_jk",
      use_in_sheet: getHeaderUseInSheetStatus("std_belongs_to_jk"),
    },
    {
      Headers: "IsKashmirBrahmin",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_belongs_to_jk_brahmin",
      use_in_sheet: getHeaderUseInSheetStatus("std_belongs_to_jk_brahmin"),
    },
    {
      Headers: "PrevTCDetails",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_tc_details",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tc_details"),
    },
    {
      Headers: "PrevInstitution",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_inst",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_inst"),
    },
    {
      Headers: "PrevQualification",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_quali_exam",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_quali_exam"),
    },
    {
      Headers: "PervStudiedBoard",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_studied_board",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_studied_board"),
    },
    {
      Headers: "PrevStudiedState",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_studied_state",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_studied_state"),
    },
    {
      Headers: "PrevStudySubjects",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_subject_studied",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_subject_studied"),
    },
    {
      Headers: "PrevTotalMarks",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_total_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_total_marks"),
    },
    {
      Headers: "PrevMarksPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_total_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_total_per"),
    },
    {
      Headers: "PrevTechnicalSubjects",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_tech_subject_studied",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tech_subject_studied"),
    },
    {
      Headers: "PrevTechnicalMarks",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_tech_total_mark",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tech_total_mark"),
    },
    {
      Headers: "PrevTechnicalPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_tech_total_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tech_total_per"),
    },
    {
      Headers: "tenthMarks",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_10_std_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_10_std_marks"),
    },
    {
      Headers: "tenthPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_10_std_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_10_std_per"),
    },
    {
      Headers: "tenthLanguage",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_10_lang_medium",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_10_lang_medium"),
    },
    {
      Headers: "twelthMarks",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_12_std_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_12_std_marks"),
    },
    {
      Headers: "twelthPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_12_std_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_12_std_per"),
    },
    {
      Headers: "BankAccountNo",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_acct",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_acct"),
    },
    {
      Headers: "BankName",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_name",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_name"),
    },
    {
      Headers: "IFSC",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_ifsc",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_ifsc"),
    },
    {
      Headers: "BankBranch",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_branch",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_branch"),
    },
    {
      Headers: "CETapplicationNo",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_appl_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_appl_no"),
    },
    {
      Headers: "FREEorPaymentSeat",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_free_pymnt_seat",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_free_pymnt_seat"),
    },
    {
      Headers: "STATEorINTERSTATE",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_state_or_outside",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_state_or_outside"),
    },
    {
      Headers: "SeatGotThrough",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_seat_got_through",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_seat_got_through"),
    },
    {
      Headers: "CategoryThrough",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_category",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_category"),
    },
    {
      Headers: "CETno",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_no"),
    },
    {
      Headers: "CETrank",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_rank",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_rank"),
    },
    {
      Headers: "AdmEligibiltyNo",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_eli_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_eli_no"),
    },
    {
      Headers: "AdmEligibiltyDate",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_eli_date",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_eli_date"),
    },
    {
      Headers: "CETPaidReceiptNo",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_paid_rcpt_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_paid_rcpt_no"),
    },
    {
      Headers: "CETPaidReceiptDate",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_paid_rcpt_date",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_paid_rcpt_date"),
    },
    {
      Headers: "CETPaidAmount",
      width: 50,
      required: false,
      type: Number,
      key: "std_adm_cet_paid_amt",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_paid_amt"),
    },
    {
      Headers: "CETAllotedCategory",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_alloted_category",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_alloted_category"),
    },
  ];
  const student_schema = {
    AdmissionNumber: {
      name: "adm_no",
    },
    RegisterNo: {
      name: "std_reg_no",
    },
    ...(stsNoFlag === "SCH"
      ? {
          STSno: {
            name: "std_sts_no",
          },
        }
      : {}),
    FirstName: {
      name: "firstname",
      required: true,
    },
    MiddleName: {
      name: "middlename",
    },
    LastName: {
      name: "lastname",
    },
    Email: {
      name: "email",
    },
    ContactNumber: {
      name: "contactnumber",
    },
    FatherName: {
      name: "fathername",
      required: true,
    },
    FatherMobile: {
      name: "fatherMobile",
    },
    FatherEmail: {
      name: "fatherEmail",
    },
    MotherName: {
      name: "mothername",
    },
    MotherMobile: {
      name: "motherMobile",
    },
    MotherEmail: {
      name: "motherEmail",
    },
    GuardianName: {
      name: "guardianName",
    },
    GuardianMobile: {
      name: "guardianMobile",
    },
    GuardianEmail: {
      name: "guardianEmail",
    },
    PrimaryContact: {
      name: "primaryContact",
    },
    DateOfBirth: {
      name: "dob",
      required: true,
    },
    Gender: {
      name: "sex",
      required: true,
    },
    Fresher: {
      name: "fresher",
      required: true,
    },
    Department: {
      name: "Department",
      required: USE_DEPARTMENT_KEY,
    },
    Branch: {
      name: "Branch",
      required: USE_BRANCH_KEY,
    },

    Class: {
      name: "class",
      required: USE_CLASS_KEY,
    },
    Sem: {
      name: "Sem",
      required: USE_SEMESTER_KEY,
    },
    Section: { name: "Sec", required: USE_SECTION_KEY },
    Category: {
      name: "category",
      required: USE_CATEGORY_KEY,
    },
    DateOfAdmission: {
      name: "doa",
      required: true,
    },
    Aadhar: {
      name: "std_aadhar",
    },

    BloodGroup: {
      name: "std_blood_group",
    },
    Address: {
      name: "std_address",
    },
    City: {
      name: "std_place",
    },
    District: {
      name: "std_district",
    },
    State: {
      name: "std_state",
    },
    PinCode: {
      name: "std_pincode",
    },
    Telno: {
      name: "std_phone",
    },
    CorrespondingAddress: {
      name: "std_corr_address",
    },
    CorrespondingCity: {
      name: "std_corr_place",
    },
    CorrDistrict: {
      name: "std_corr_district",
    },
    CorrState: {
      name: "std_corr_state",
    },
    CorrPinCode: {
      name: "std_corr_pincode",
    },
    CorrTelephone: {
      name: "std_corr_phone",
    },
    Religion: {
      name: "std_religion",
    },
    Caste: {
      name: "std_caste",
    },
    SubCaste: {
      name: "std_sub_caste",
    },
    CasteGroup: {
      name: "std_caste_group",
    },
    SCST: {
      name: "std_sc_st",
    },
    Minority: {
      name: "std_caste_minority",
    },
    Area: {
      name: "std_rural_urban",
    },
    MotherTongue: {
      name: "std_mother_tongue",
    },
    AnnualIncome: {
      name: "std_annual_income",
    },
    Married: {
      name: "std_martial_status",
    },
    IdentityMarks: {
      name: "std_identity_marks",
    },
    PlaceofBirth: {
      name: "std_place_of_birth",
    },
    Nationality: {
      name: "std_nationality",
    },
    PhysicalChallenged: {
      name: "std_is_phy_challenged",
    },
    NearestPoliceStation: {
      name: "std_nearest_police_station",
    },
    NearestRailwayStation: {
      name: "std_nearest_railway_station",
    },
    Kmsfromresidence: {
      name: "std_km_from_residence",
    },
    BelongstoKashmir: {
      name: "std_belongs_to_jk",
    },
    IsKashmirBrahmin: {
      name: "std_belongs_to_jk_brahmin",
    },
    PrevTCDetails: {
      name: "std_prev_tc_details",
    },
    PrevInstitution: {
      name: "std_prev_inst",
    },
    PrevQualification: {
      name: "std_prev_quali_exam",
    },
    PervStudiedBoard: {
      name: "std_prev_studied_board",
    },
    PrevStudiedState: {
      name: "std_prev_studied_state",
    },
    PrevStudySubjects: {
      name: "std_prev_subject_studied",
    },
    PrevTotalMarks: {
      name: "std_prev_total_marks",
    },
    PrevMarksPercentage: {
      name: "std_prev_total_per",
    },
    PrevTechnicalSubjects: {
      name: "std_prev_tech_subject_studied",
    },
    PrevTechnicalMarks: {
      name: "std_prev_tech_total_mark",
    },
    PrevTechnicalPercentage: {
      name: "std_prev_tech_total_per",
    },
    tenthMarks: {
      name: "std_prev_10_std_marks",
    },
    tenthPercentage: {
      name: "std_prev_10_std_per",
    },
    tenthLanguage: {
      name: "std_prev_10_lang_medium",
    },
    twelthMarks: {
      name: "std_prev_12_std_marks",
    },
    twelthPercentage: {
      name: "std_prev_12_std_per",
    },
    BankAccountNo: {
      name: "std_bank_acct",
    },
    BankName: {
      name: "std_bank_name",
    },
    IFSC: {
      name: "std_bank_ifsc",
    },
    BankBranch: {
      name: "std_bank_branch",
    },
    CETapplicationNo: {
      name: "std_adm_appl_no",
    },
    FREEorPaymentSeat: {
      name: "std_adm_free_pymnt_seat",
    },
    STATEorINTERSTATE: {
      name: "std_adm_state_or_outside",
    },
    SeatGotThrough: {
      name: "std_adm_seat_got_through",
    },
    CategoryThrough: {
      name: "std_adm_category",
    },
    CETno: {
      name: "std_adm_cet_no",
    },
    CETrank: {
      name: "std_adm_cet_rank",
    },
    AdmEligibiltyNo: {
      name: "std_adm_eli_no",
    },
    AdmEligibiltyDate: {
      name: "std_adm_eli_date",
    },
    CETPaidReceiptNo: {
      name: "std_adm_cet_paid_rcpt_no",
    },
    CETPaidReceiptDate: {
      name: "std_adm_cet_paid_rcpt_date",
    },
    CETPaidAmount: {
      name: "std_adm_cet_paid_amt",
    },
    CETAllotedCategory: {
      name: "std_adm_cet_alloted_category",
    },
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setRecords([]);
    }
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };
  const schemaKeys = Object.keys(student_schema);

  const filteredKeys = schemaKeys.filter((key) => {
    // @ts-ignore
    const fieldSchema = student_schema[key];
    if (fieldSchema.required || fieldSchema.required === undefined) {
      const hasData = records.some(
        (record) => record[key] !== undefined && record[key] !== ""
      );
      return hasData;
    }
    return false;
  });

  const InstName = InstDetails.data?.nodes[0]?.inst_name!;
  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      adm_no: f.AdmissionNumber,
      std_reg_no: f.RegisterNo,
      firstname: f.FirstName,
      middlename: f.MiddleName,
      lastname: f.LastName,
      email: f.Email,
      contactnumber: f.ContactNumber,
      fathername: f.FatherName,
      dob: f.DateOfBirth,
      sex: f.Gender,
      fresher: f.Fresher,
      ...(USE_DEPARTMENT_KEY ? { Department: f.Department } : {}),
      ...(USE_BRANCH_KEY ? { Branch: f.Branch } : {}),
      ...(USE_CLASS_KEY ? { class: f.Class } : {}),
      ...(USE_SEMESTER_KEY ? { Sem: f.Sem } : {}),
      ...(USE_CATEGORY_KEY ? { category: f.category } : {}),
      ...(USE_SECTION_KEY ? { Section: f.Section } : {}),
    }));
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");

    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = instConfig
                    .filter((d) => d.bool)
                    .map((f) => f.Headers);

                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  const filteredStudentExcelData = studentData.filter(
                    (column) => column.use_in_sheet
                  );

                  let studentInfoHeader = filteredStudentExcelData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  let dataRow = 6;
                  treeData?.GetMstInstTree.forEach((level1_data) => {
                    let level1_nxt_index = 0;
                    if (
                      level1_data.level_2_details &&
                      level1_data.level_2_details.length > 0
                    ) {
                      let level2_dataRow = dataRow;
                      level1_data.level_2_details.forEach((level2_data) => {
                        let level2_nxt_index = 0;
                        if (
                          level2_data.level_3_details &&
                          level2_data.level_3_details.length > 0
                        ) {
                          let level3_dataRow = level2_dataRow;
                          level2_data.level_3_details.forEach((level3_data) => {
                            let level3_nxt_index = 0;
                            if (
                              level3_data.level_4_details &&
                              level3_data.level_4_details.length > 0
                            ) {
                              let level4_dataRow = level3_dataRow;
                              level3_data.level_4_details.forEach(
                                (level4_data) => {
                                  let level4_nxt_index = 0;
                                  if (
                                    level4_data.level_5_details &&
                                    level4_data.level_5_details.length > 0
                                  ) {
                                    level4_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level3_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level1_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level2_nxt_index +=
                                      level4_data.level_5_details.length;
                                    let level5_dataRow = level4_dataRow;
                                    level4_data.level_5_details.forEach(
                                      (level5_data, index) => {
                                        const level5_cell = worksheet.getCell(
                                          `E${level5_dataRow}`
                                        );
                                        level5_cell.value =
                                          level5_data.level_5_desc;

                                        level5_cell.style = {
                                          alignment: HEADER_ALIGNMENT_CENTER,
                                        };
                                        level5_dataRow += index + 1;
                                      }
                                    );
                                  } else {
                                    level4_nxt_index = 1;
                                    level3_nxt_index += 1;
                                    level2_nxt_index += 1;
                                    level1_nxt_index += 1;
                                  }
                                  const level4_cell = worksheet.getCell(
                                    `D${level4_dataRow}`
                                  );
                                  level4_cell.value = level4_data.level_4_desc;
                                  worksheet.mergeCells(
                                    `D${level4_dataRow}:D${
                                      level4_dataRow + level4_nxt_index - 1
                                    }`
                                  );
                                  level4_cell.style = {
                                    alignment: HEADER_ALIGNMENT_CENTER,
                                  };
                                  level4_dataRow += level4_nxt_index;
                                }
                              );
                            } else {
                              level3_nxt_index = 1;
                              level2_nxt_index += 1;
                              level1_nxt_index += 1;
                            }
                            const level3_cell = worksheet.getCell(
                              `C${level3_dataRow}`
                            );
                            level3_cell.value = level3_data.level_3_desc;
                            worksheet.mergeCells(
                              `C${level3_dataRow}:C${
                                level3_dataRow + level3_nxt_index - 1
                              }`
                            );
                            level3_cell.style = {
                              alignment: HEADER_ALIGNMENT_CENTER,
                            };
                            level3_dataRow += level3_nxt_index;
                          });
                        } else {
                          level2_nxt_index = 1;
                          level1_nxt_index += 1;
                        }

                        const level2_cell = worksheet.getCell(
                          `B${level2_dataRow}`
                        );
                        level2_cell.value = level2_data.level_2_desc;
                        worksheet.mergeCells(
                          `B${level2_dataRow}:B${
                            level2_dataRow + level2_nxt_index - 1
                          }`
                        );
                        level2_cell.style = {
                          alignment: HEADER_ALIGNMENT_CENTER,
                        };
                        level2_dataRow += level2_nxt_index;
                      });
                    } else {
                      level1_nxt_index = 1;
                    }

                    const level1_cell = worksheet.getCell(`A${dataRow}`);
                    level1_cell.value = level1_data.level_1_desc;
                    worksheet.mergeCells(
                      `A${dataRow}:A${dataRow + level1_nxt_index - 1}`
                    );
                    level1_cell.style = {
                      alignment: HEADER_ALIGNMENT_CENTER,
                    };
                    dataRow += level1_nxt_index;
                  });
                  let count = 1;
                  if (USE_DEPARTMENT_KEY) {
                    count++;
                  }
                  if (USE_BRANCH_KEY) {
                    count++;
                  }
                  if (USE_CLASS_KEY) {
                    count++;
                  }
                  if (USE_SEMESTER_KEY) {
                    count++;
                  }
                  if (USE_SECTION_KEY) {
                    count++;
                  }
                  const cat_cell = String.fromCharCode(
                    FIRST_INDEX.charCodeAt(0) + count
                  );
                  const primary_cell = String.fromCharCode(
                    FIRST_INDEX.charCodeAt(0) + count + 1
                  );

                  categoryDetails?.forEach((cat, index) => {
                    const categoryCell = worksheet.getCell(
                      `${cat_cell + Number(index + 6)}`
                    );
                    categoryCell.value = cat.cat_desc;
                  });
                  PrimaryContactType.forEach((cat, index) => {
                    const primaryCell = worksheet.getCell(
                      `${primary_cell + Number(index + 6)}`
                    );
                    primaryCell.value = cat.value;
                  });
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const downloadExcelContainsError = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });
    worksheet1.getColumn(1).width = 15;
    worksheet1.getColumn(2).width = 15;
    worksheet1.getColumn(3).width = 15;
    worksheet1.getColumn(4).width = 15;
    worksheet1.getColumn(5).width = 15;
    worksheet1.getColumn(6).width = 15;
    worksheet1.getColumn(7).width = 15;
    worksheet1.getColumn(8).width = 15;
    worksheet1.getColumn(9).width = 15;
    worksheet1.getColumn(10).width = 15;
    worksheet1.getColumn(11).width = 15;
    worksheet1.getColumn(12).width = 15;
    worksheet1.getColumn(13).width = 15;
    worksheet1.getColumn(14).width = 15;
    worksheet1.getColumn(15).width = 15;
    worksheet1.getColumn(16).width = 15;
    worksheet1.getColumn(17).width = 15;
    worksheet1.getColumn(18).width = 15;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;

                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = instConfig
                    .filter((d) => d.bool)
                    .map((f) => f.Headers);
                  let headers2 = studentData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  for (let i = 0; i < headers2.length; i++) {
                    const Char = String.fromCharCode(65 + i);

                    const rowData2: Cell = worksheet1.getCell(Char + 1);
                    rowData2.value = headers2[i];
                    rowData2.fill = TABLE_HEADER_CSS;
                    rowData2.border = BORDER_DATA;
                    rowData2.font = ACC_HEADER_FONT;
                    rowData2.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  let dataRow = 6;
                  treeData?.GetMstInstTree.forEach((level1_data) => {
                    let level1_nxt_index = 0;
                    if (
                      level1_data.level_2_details &&
                      level1_data.level_2_details.length > 0
                    ) {
                      let level2_dataRow = dataRow;
                      level1_data.level_2_details.forEach((level2_data) => {
                        let level2_nxt_index = 0;
                        if (
                          level2_data.level_3_details &&
                          level2_data.level_3_details.length > 0
                        ) {
                          let level3_dataRow = level2_dataRow;
                          level2_data.level_3_details.forEach((level3_data) => {
                            let level3_nxt_index = 0;
                            if (
                              level3_data.level_4_details &&
                              level3_data.level_4_details.length > 0
                            ) {
                              let level4_dataRow = level3_dataRow;
                              level3_data.level_4_details.forEach(
                                (level4_data) => {
                                  let level4_nxt_index = 0;
                                  if (
                                    level4_data.level_5_details &&
                                    level4_data.level_5_details.length > 0
                                  ) {
                                    level4_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level3_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level1_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level2_nxt_index +=
                                      level4_data.level_5_details.length;
                                    let level5_dataRow = level4_dataRow;
                                    level4_data.level_5_details.forEach(
                                      (level5_data, index) => {
                                        const level5_cell = worksheet.getCell(
                                          `E${level5_dataRow}`
                                        );
                                        level5_cell.value =
                                          level5_data.level_5_desc;

                                        level5_cell.style = {
                                          alignment: HEADER_ALIGNMENT_CENTER,
                                        };
                                        level5_dataRow += index + 1;
                                      }
                                    );
                                  } else {
                                    level4_nxt_index = 1;
                                    level3_nxt_index += 1;
                                    level2_nxt_index += 1;
                                    level1_nxt_index += 1;
                                  }
                                  const level4_cell = worksheet.getCell(
                                    `D${level4_dataRow}`
                                  );
                                  level4_cell.value = level4_data.level_4_desc;
                                  worksheet.mergeCells(
                                    `D${level4_dataRow}:D${
                                      level4_dataRow + level4_nxt_index - 1
                                    }`
                                  );
                                  level4_cell.style = {
                                    alignment: HEADER_ALIGNMENT_CENTER,
                                  };
                                  level4_dataRow += level4_nxt_index;
                                }
                              );
                            } else {
                              level3_nxt_index = 1;
                              level2_nxt_index += 1;
                              level1_nxt_index += 1;
                            }
                            const level3_cell = worksheet.getCell(
                              `C${level3_dataRow}`
                            );
                            level3_cell.value = level3_data.level_3_desc;
                            worksheet.mergeCells(
                              `C${level3_dataRow}:C${
                                level3_dataRow + level3_nxt_index - 1
                              }`
                            );
                            level3_cell.style = {
                              alignment: HEADER_ALIGNMENT_CENTER,
                            };
                            level3_dataRow += level3_nxt_index;
                          });
                        } else {
                          level2_nxt_index = 1;
                          level1_nxt_index += 1;
                        }

                        const level2_cell = worksheet.getCell(
                          `B${level2_dataRow}`
                        );
                        level2_cell.value = level2_data.level_2_desc;
                        worksheet.mergeCells(
                          `B${level2_dataRow}:B${
                            level2_dataRow + level2_nxt_index - 1
                          }`
                        );
                        level2_cell.style = {
                          alignment: HEADER_ALIGNMENT_CENTER,
                        };
                        level2_dataRow += level2_nxt_index;
                      });
                    } else {
                      level1_nxt_index = 1;
                    }

                    const level1_cell = worksheet.getCell(`A${dataRow}`);
                    level1_cell.value = level1_data.level_1_desc;
                    worksheet.mergeCells(
                      `A${dataRow}:A${dataRow + level1_nxt_index - 1}`
                    );
                    level1_cell.style = {
                      alignment: HEADER_ALIGNMENT_CENTER,
                    };
                    dataRow += level1_nxt_index;
                  });
                  categoryDetails?.forEach((cat, index) => {
                    const categoryCell = worksheet.getCell(`F${index + 6}`);
                    categoryCell.value = cat.cat_desc;
                  });
                  worksheet1.columns = studentData.filter(
                    (d) => d.use_in_sheet
                  );
                  errorData.forEach((f) => worksheet1.addRow(f));
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName + "error");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      schema &&
        // eslint-disable-next-line
        Object.keys(schema).map((key) => {
          // @ts-ignore
          let keySchema = student_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }

          if (key === "ContactNumber") {
            if (keyValue && keyValue.length < 10) {
              let errorObj = {
                column: key,
                error: "Invalid Phone",
              };
              errors.push(errorObj);
            }
          }

          if (
            key === "DateOfBirth" &&
            !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          ) {
            let errorObj = {
              column: key,
              error:
                "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
            };
            errors.push(errorObj);
          }
          if (
            key === "DateOfAdmission" &&
            !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          ) {
            let errorObj = {
              column: key,
              error:
                "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
            };
            errors.push(errorObj);
          }

          if (key === "Gender" && !_genderRegex.mask.test(keyValue)) {
            let errorObj = {
              column: key,
              error: "Invalid Gender, the format should be male or female",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };
  const handleButtonClick = async () => {
    try {
      await Promise.all(records.map((record) => handleClick(record.id)));
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (id: number) => {
    return new Promise((resolve, reject) => {
      const filteredData = records.find((record) => record.id === id);
      if (filteredData) {
        ValidateEachRow({
          variables: {
            input: {
              first_name: filteredData.FirstName,
              std_father_name: filteredData.FatherName,
              middle_name: filteredData.MiddleName ?? "",
              last_name: filteredData.LastName ?? "",
              std_dob: toIsoDate(
                convertToDateObject_MMDDYYYYFormat(filteredData.DateOfBirth)
              ),
              std_email: filteredData.Email ?? "",
              std_mobile: filteredData.ContactNumber,
              dept_name: filteredData.Department ?? "",
              branch_name: filteredData.Branch ?? "",
              class_name: filteredData.Class ?? "",
              semester_name: filteredData.Sem ?? "",
              category_name: filteredData.Category ?? "",
              section_name: filteredData.Section ?? "",
            },
            inst_id: InstId!,
            token,
          },
        }).then(({ data, error }) => {
          if (data) {
            const newData = records.map((record) => {
              if (id === record.id) {
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: !record.isValidated,
                  isValidatedAndError: false,
                };
              }
              return record;
            });
            setRecords(newData);
            resolve(data);
          }
          if (error) {
            const newData = records.map((record) => {
              if (record.id === id) {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(error?.message);
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: true,
                  isValidatedAndError: true,
                };
              }
              return record;
            });
            setRecords(newData);
            reject(error);
          }
        });
      }
    });
  };
  const handleAddStudent = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);
    for (const record of records.filter((record) => !record.errors)) {
      try {
        await AddStudent({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            flags: {
              is_adm_num_edited: false,
              use_adm_num_as_is: record.AdmissionNumber ? true : false,
            },
            input: {
              std_basic_details: {
                first_name: removeMoreSpace(record.FirstName),
                middle_name: removeMoreSpace(record.MiddleName) ?? "",
                last_name: removeMoreSpace(record.LastName) ?? "",
                std_sex: removeMoreSpace(record.Gender.toUpperCase()),
                std_reg_no: record.RegisterNo ? record.RegisterNo : "",
                std_adm_no: record.AdmissionNumber ?? "",
                std_doa: toIsoDate(
                  convertToDateObject_MMDDYYYYFormat(record.DateOfAdmission)
                ),
                std_fresher:
                  removeMoreSpace(record.Fresher.toLowerCase()) === "no" ||
                  removeMoreSpace(record.Fresher.toLowerCase()) === "n"
                    ? false
                    : true,
                std_dob: toIsoDate(
                  convertToDateObject_MMDDYYYYFormat(record.DateOfBirth)
                ),
                std_email: removeMoreSpace(record.Email),
                std_mobile: record.ContactNumber
                  ? `+91${record.ContactNumber}`
                  : EMPTY_STRING,
                std_father_name: removeMoreSpace(record.FatherName),
                std_father_mobile: record.FatherMobile
                  ? `+91${record.FatherMobile}`
                  : EMPTY_STRING,
                std_father_email: record.FatherEmail
                  ? removeMoreSpace(record.FatherEmail)
                  : EMPTY_STRING,
                std_mother_name: record.MotherName
                  ? removeMoreSpace(record.MotherName)
                  : " ",
                std_mother_mobile: record.MotherMobile
                  ? `+91${record.MotherMobile}`
                  : EMPTY_STRING,
                std_mother_email: record.MotherEmail
                  ? removeMoreSpace(record.MotherEmail)
                  : EMPTY_STRING,
                std_guardian_name: record.GuardianName
                  ? removeMoreSpace(record.GuardianName)
                  : " ",
                std_guardian_mobile: record.GuardianMobile
                  ? `+91${record.GuardianMobile}`
                  : EMPTY_STRING,
                std_guardian_email: record.GuardianEmail
                  ? removeMoreSpace(record.GuardianEmail)
                  : EMPTY_STRING,
                std_primary_login: record.PrimaryContact
                  ? record.PrimaryContact
                  : EMPTY_STRING,
                dept_name: record.Department ?? "",
                branch_name: record.Branch ?? "",
                class_name: record.Class ?? "",
                semester_name: record.Sem ?? "",
                category_name: record.Category ?? "",
                section_name: record.Section ?? "",
                std_sts_no: record.STSno,
              },
              std_details: {
                std_blood_group: record.BloodGroup,
                std_aadhar: record.Aadhar,
                std_address: record.Address,
                std_place: record.City,
                std_district: record.District,
                std_state: record.State,
                std_pincode: record.PinCode,
                std_phone: record.Telno,
                std_corr_address: record.CorrespondingAddress,
                std_corr_place: record.CorrespondingCity,
                std_corr_district: record.CorrDistrict,
                std_corr_state: record.CorrState,
                std_corr_pincode: record.CorrPinCode,
                std_corr_phone: record.CorrTelephone,
                std_religion: record.Religion,
                std_sub_caste: record.SubCaste,
                std_caste_group: record.CasteGroup,
                std_sc_st: record.SCST,
                std_caste_minority:
                  record.Minority === "Yes"
                    ? true
                    : record.IsKashmirBrahmin === "No"
                    ? false
                    : false,
                std_rural_urban: record.Area,
                std_mother_tongue: record.MotherTongue,
                std_annual_income: record.AnnualIncome,
                std_martial_status: record.IdentityMarks,
                std_identity_marks: record.IdentityMarks,
                std_place_of_birth: record.PlaceofBirth,
                std_nationality: record.Nationality,
                std_is_phy_challenged:
                  record.PhysicalChallenged === "Yes"
                    ? true
                    : record.PhysicalChallenged === "No"
                    ? false
                    : false,
                std_nearest_police_station: record.NearestPoliceStation,
                std_nearest_railway_station: record.NearestRailwayStation,
                std_km_from_residence: record.Kmsfromresidence,
                std_belongs_to_jk:
                  record.BelongstoKashmir === "Yes"
                    ? true
                    : record.IsKashmirBrahmin === "No"
                    ? false
                    : false,
                std_belongs_to_jk_brahmin:
                  record.IsKashmirBrahmin === "Yes"
                    ? true
                    : record.IsKashmirBrahmin === "No"
                    ? false
                    : false,
              },
              std_other_details: {
                std_prev_tc_details: record.PrevTCDetails,
                std_prev_inst: record.PrevInstitution,
                std_prev_quali_exam: record.PervStudiedBoard,
                std_prev_studied_state: record.PrevStudiedState,
                std_prev_subject_studied: record.PrevStudySubjects,
                std_prev_total_marks: record.PrevTotalMarks,
                std_prev_total_per: record.PrevMarksPercentage,
                std_prev_tech_subject_studied: record.PrevTechnicalSubjects,
                std_prev_tech_total_mark: record.PrevTechnicalMarks,
                std_prev_tech_total_per: record.PrevTechnicalPercentage,
                std_prev_10_std_marks: record.tenthMarks,
                std_prev_10_std_per: record.tenthPercentage,
                std_prev_10_lang_medium: record.tenthLanguage,
                std_prev_12_std_marks: record.twelthMarks,
                std_prev_12_std_per: record.twelthPercentage,
                std_bank_acct: record.BankAccountNo,
                std_bank_name: record.BankName,
                std_bank_ifsc: record.IFSC,
                std_bank_branch: record.BankBranch,
                std_adm_appl_no: record.CETapplicationNo,
                std_adm_free_pymnt_seat: record.FREEorPaymentSeat,
                std_adm_state_or_outside: record.STATEorINTERSTATE,
                std_adm_seat_got_through: record.SeatGotThrough,
                std_adm_category: record.CategoryThrough,
                std_adm_cet_no: record.CETno,
                std_adm_cet_rank: record.CETrank,
                std_adm_eli_no: record.AdmEligibiltyNo,
                std_adm_eli_date: record.AdmEligibiltyDate,
                std_adm_cet_paid_rcpt_no: record.CETPaidReceiptNo,
                std_adm_cet_paid_rcpt_date: record.CETPaidReceiptDate,
                std_adm_cet_paid_amt: record.CETPaidAmount,
                std_adm_cet_alloted_category: record.CETAllotedCategory,
              },
              std_additional_details: {},
            },
          },
          onError: (err) => {
            record.errors = record.errors ? record.errors : [];
            record.errors.push(err.message);
            setMessage({
              flag: true,
              message: err.message,
              operation: Operation.NONE,
            });
            setErrorCount((prev) => prev + 1);
          },
        }).then(({ data }) => {
          if (data) {
            setSuccessCount((prev) => prev + 1);
          }
        });
      } catch (error) {
        console.error(error);
        setErrorCount((prev) => prev + 1);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);
          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
                isValidated: false,
                isValidatedAndError: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });
  useEffect(() => {
    if (token && InstId) {
      GetConfigdata();
    }
  }, [token, GetConfigdata, InstId]);
  useEffect(() => {
    if (
      USE_CLASS_KEY ||
      USE_DEPARTMENT_KEY ||
      USE_SEMESTER_KEY ||
      USE_CATEGORY_KEY ||
      USE_BRANCH_KEY
    ) {
      setSchema(student_schema);
    } // eslint-disable-next-line
  }, [
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_BRANCH_KEY,
  ]);

  useEffect(() => {
    if (token) {
      GetMasterDetails();
    }
  }, [token, GetMasterDetails]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Import Student Data</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Students
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.errors).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record?.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 && schema ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {records.filter((record) => !record.errors).length}
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>

              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>

                          {filteredKeys?.map((key, index) => {
                            // @ts-ignore

                            return <TableCell key={index}>{key}</TableCell>;
                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {records
                          .filter((record) => !record?.errors)
                          .map((record, index) => (
                            <TableRow key={index}>
                              <TableCell id="td-center">{index + 1}</TableCell>
                              {/* @ts-ignore */}
                              {/* eslint-disable-next-line */}
                              {filteredKeys.map((key, index) => {
                                //@ts-ignore
                                const fieldSchema = student_schema[key];

                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                )
                                  return (
                                    <TableCell key={index}>
                                      {record[key]}
                                    </TableCell>
                                  );
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contain Error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>

              {expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className="import-excel-data__datablock--table">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell id="td-center">
                            {TableHeaders.SLNO}
                          </TableCell>
                          {/* eslint-disable-next-line */}
                          {filteredKeys.map((key, index) => {
                            //@ts-ignore
                            const fieldSchema = student_schema[key];
                            if (
                              fieldSchema.required ||
                              fieldSchema.required === undefined
                            )
                              return <TableCell key={index}>{key}</TableCell>;
                          })}
                          <TableCell id="td-center">Error Details</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => (
                            <TableRow key={index}>
                              <TableCell id="td-center">{index + 1}</TableCell>
                              {filteredKeys.map((key, index) => {
                                // @ts-ignore
                                const fieldSchema = student_schema[key];

                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                )
                                  return (
                                    <TableCell key={index}>
                                      {record[key]}
                                    </TableCell>
                                  );
                              })}

                              <TableCell className="import-excel-data__datablock--error">
                                <CustomTooltip
                                  title={
                                    <ul>
                                      {record?.errors
                                        ? record?.errors?.map(
                                            (error: any, index: number) => {
                                              return (
                                                <li key={index}>
                                                  {index + 1})&nbsp;
                                                  {typeof error === "string"
                                                    ? error
                                                    : "Error message = " +
                                                      error.error +
                                                      ", column = " +
                                                      error.column}
                                                </li>
                                              );
                                            }
                                          )
                                        : null}
                                    </ul>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <div className="import-excel-data__datablock--tableblock--error-message">
                                    <span>Errors</span>
                                    <img src={Info} alt="/" />
                                  </div>
                                </CustomTooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>

        <div className="row ">
          <div className="col">
            <Button
              onClick={() => {
                setImportModal(true);
                handleAddStudent();
              }}
              disabled={
                !records.filter((record) => record?.isChecked).length ||
                !records.length
                  ? true
                  : false
              }
              mode="excel"
            >
              Import Students
            </Button>
            <Button onClick={handleButtonClick}>
              <img src={Validate} alt="" />
              Validate
            </Button>
            <Button onClick={handleClear} mode="clear" />

            <Button onClick={downloadExcelContainsError} mode="excel">
              Export error Data
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={() => setStdConfig(!stdConfig)}>
              Generate Template XLS File
            </Button>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={stdConfig}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentsConfiguration
              pageType={PageFor.MODAL}
              setStudentSwDataForModal={setStudentSwDataForModal}
              studentSwDataForModal={studentSwDataForModal}
            />
            <Button mode="excel" onClick={downloadExcel}>
              Download to Excel
            </Button>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStdConfig(!stdConfig)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentBasicDataImport;

import { gql } from "@apollo/client";

export const UpdateLibBooksAccessionNumber = gql`
  mutation UpdateSLibBooksAccessionNumber(
    $token: String!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $accession_data: [LibBookAccessionData!]!
  ) {
    UpdateLibBooksAccessionNumber(
      token: $token
      accession_data: $accession_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

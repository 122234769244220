import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ROWS_PER_PAGE } from "../../../utils/constants";
import { Direction, ReturnType, SortBy } from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import useToken from "../../../customhooks/useToken";
import { GetPayRollSalaryStructMastersByInstId } from "../queries/salary/list";

interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}
export interface PayRollSalaryStructMasterNode {
  id: number;
  sal_str_desc: string;
  sal_str_assigned_to_all: boolean;
  sal_str_in_use: boolean;
  inst_id: number;
  pr_acd_yr_id: number;
  pr_category_id: number;
  pr_dept_id: number;
  pr_designation_id: number;
}

interface PayRollSalaryStructMasterEdge {
  node: PayRollSalaryStructMasterNode;
  cursor: string;
}

export interface GetPayRollSalaryStructMastersByInstIdList {
  totalCount: number;
  pageInfo: PageInfo;
  edges: PayRollSalaryStructMasterEdge[];
}

interface GetPayRollSalaryStructMastersByInstIdData {
  GetPayRollSalaryStructMastersByInstId: GetPayRollSalaryStructMastersByInstIdList;
}
export interface GetPayRollSalaryStructMastersByInstIdVars {
  token: string;
  inst_id: string | number;
  after: string | null;
  first: number;
  direction: string;
  sortBy: string;
  name: string;
}
const usePayRollSalaryStructure = (type: ReturnType, searchData: string) => {
  //Token and others
  const { token } = useToken();
  const { InstId } = useParams();
  //Queries
  const [
    GetPayRollSalaryStruct,
    {
      data: salaryStructData,
      error: salaryStructMastersListError,
      loading: salaryStructMastersListLoading,
    },
  ] = useLazyQuery<
    GetPayRollSalaryStructMastersByInstIdData,
    GetPayRollSalaryStructMastersByInstIdVars
  >(GetPayRollSalaryStructMastersByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      after: null,
      first: ROWS_PER_PAGE,
      direction: Direction.ASC,
      sortBy: SortBy.SAL_STR_DESC,
      name: searchData,
    },
  });
  useEffect(() => {
    if (token && InstId) {
      GetPayRollSalaryStruct();
    }
  }, [token, GetPayRollSalaryStruct, InstId, searchData]);
  //useStates
  const [salaryStructMasters, setsalaryStructMasters] = useState<
    responseType[]
  >([]);

  useEffect(() => {
    if (
      type === ReturnType.RESPONSE_TYPE &&
      salaryStructData &&
      !salaryStructMastersListLoading
    ) {
      setsalaryStructMasters(
        salaryStructData?.GetPayRollSalaryStructMastersByInstId?.edges?.map(
          ({ node }) => ({
            label: node.sal_str_desc,
            value: node.id,
          })
        )
      );
    }
  }, [type, salaryStructData, salaryStructMastersListLoading]);

  return {
    salaryStructMasters: {
      queryData: {
        data: salaryStructData,
        error: salaryStructMastersListError?.message,
        loading: salaryStructMastersListLoading,
      },
      dropDown: salaryStructMasters,
    },
  };
};

export default usePayRollSalaryStructure;

import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import {
  GetAcdSubjectForEntryLevelVars,
  GetAcdSubjectForEntryLevelsData,
} from "../types/subject/Subject";
import { GetAcdSubjectForEntryLevel } from "../queries/subjects/List.tsx/Index";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "./useActiveAcademicYear";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../context/context";
import useCheckAllocationType from "./useCheckAllocationType";

const useAcdSubjectForEntryLevel = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();

  const { studentEntryId } = useStudentDatabyId();

  const { activeAcademicYearData } = useActiveAcademicYear();

  const { entry_level } = useInstitutionConfiguration();
  const { flag } = useCheckAllocationType(studentEntryId);
  const [GetAcdSubjects, { data, loading, error }] = useLazyQuery<
    GetAcdSubjectForEntryLevelsData,
    GetAcdSubjectForEntryLevelVars
  >(GetAcdSubjectForEntryLevel, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: Number(entryId) ? Number(entryId) : studentEntryId,
      entry_level: entry_level,
      per_std_subj_allocation: flag,
    },
  });

  useEffect(() => {
    if (
      token &&
      entry_level &&
      activeAcademicYearData.data &&
      (entryId || studentEntryId) &&
      state.ActiveAcdYr &&
      flag !== undefined
    )
      GetAcdSubjects();
  }, [
    GetAcdSubjects,
    InstId,
    token,
    activeAcademicYearData.data,
    entryId,
    studentEntryId,
    entry_level,
    flag,
    state.ActiveAcdYr,
  ]);
  return { data, loading };
};

export default useAcdSubjectForEntryLevel;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import Modal from "react-modal";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import { FilterColumnCustomStyles } from "../../../../styles/ModalStyles";
import { BookTransactionTitleProps } from "../../../../Types/Titles";

import Filter from "../../../../images/Filter.svg";
import DownArrow from "../../../../images/DownArrow.svg";

import { Label } from "../../../../stories/Label/Label";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../../utils/constants";
import { TablePaginationActions } from "../../../../pages/CustomTablePagination";
import { defaultLabelDisplayedRows } from "../../../../utils/UtilFunctions";
import useLibraryTableJson from "../../json/useLibraryTableJson";

const { Library } = require("../../../../json/title.json");

const ClassificationList = () => {
  const [columnsModal, setColumnsModal] = useState(false);
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const { Library_Table } = useLibraryTableJson();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Title>
        {Library.BookTransaction.Titles.map(
          (title: BookTransactionTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.Classification}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="row g-0 classification-list__data">
        <div className="col-2">
          <Input
            id="search"
            type="text"
            placeholder="Search By Classification No."
          />
        </div>
        <div className="col-2">
          <Input id="search" type="text" placeholder="Search By Title" />
        </div>
        <div className="col-2">
          <Input id="search" type="text" placeholder="Search By Publications" />
        </div>
        <div className="col"></div>
        <div className="col-2">
          <Label
            className="book-entry__filter-columns"
            onClick={() => setColumnsModal(!columnsModal)}
          >
            <img src={Filter} alt="/" />
            Filter Columns &nbsp;
            <img src={DownArrow} alt="/" />
          </Label>
        </div>
      </div>
      <div className="classification-list__tableblock">
        <TableContainer className="classification-list__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Library_Table.BookTransaction.Classification.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  id="td-center"
                  className="classification-list__table--slno"
                >
                  1
                </TableCell>
                <TableCell className="classification-list__table--clsno">
                  2012hg323
                </TableCell>
                <TableCell>computer science</TableCell>
                <TableCell className="classification-list__table--publication">
                  2012hg323
                </TableCell>
                <TableCell className="classification-list__table--category">
                  Book Category
                </TableCell>
                <TableCell
                  id="td-right"
                  className="classification-list__table--total"
                >
                  5
                </TableCell>
                <TableCell
                  id="td-right"
                  className="classification-list__table--total"
                >
                  5
                </TableCell>
                <TableCell
                  id="td-right"
                  className="classification-list__table--total"
                >
                  5
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={PAGINATION_ARRAY}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={defaultLabelDisplayedRows}
                  labelRowsPerPage={TABLE_DATA_PER_PAGE}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {/* columns filter */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={columnsModal}
        style={FilterColumnCustomStyles}
        onRequestClose={() => setColumnsModal(!columnsModal)}
      >
        <Input
          autoFocus
          placeholder="Type to search..."
          id="search"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="studentlist__column-list">
          <ul role="checkbox" aria-checked>
            <li>
              <Input type="checkbox" /> &nbsp; Select
            </li>
            <li>
              <Input type="checkbox" /> &nbsp; Select
            </li>
            <li>
              <Input type="checkbox" /> &nbsp; Select
            </li>
            <li>
              <Input type="checkbox" /> &nbsp; Select
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ClassificationList;

import { gql } from "@apollo/client";

export const GetPayRollDesignationsByInstId = gql`
  query GetPayRollDesignationsByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: PayRollDesignationOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
  ) {
    GetPayRollDesignationsByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ designationDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          designation_desc
          designation_idx
        }
      }
    }
  }
`;

export const GetPayRollDesignationIdsByNames = gql`
  query GetPayRollDesignationIdsByNames(
    $token: String!
    $inst_id: ID!
    $designation_desc: [String!]
  ) {
    GetPayRollDesignationIdsByNames(
      token: $token
      inst_id: $inst_id
      designation_desc: $designation_desc
    ) {
      id
      designation_desc
      designation_idx
    }
  }
`;
export const GetPayRollDesignationNode = gql`
  query GetPayRollDesignationNode($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollDesignation {
        designation_desc
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const DeleteLibBookSubjectById = gql`
  mutation DeleteLibBookSubjectById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    DeleteLibBookSubjectById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

import React, { useState } from "react";
import { PageFor } from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import Collapse from "../../../../images/Collapse.svg";
import UnCollapse from "../../../../images/UnCollapse.svg";
import DepartmentImage from "../../../../images/department.svg";
import Branch from "../../../../images/branch.svg";
import Class from "../../../../images/class.svg";
import Semester from "../../../../images/Semester.svg";
import Section from "../../../../images/Section.svg";
import TableView from "../../../../images/TableView.svg";
import TreeView from "../../../../images/TreeView.svg";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { formatter } from "../../../../utils/UtilFunctions";

import useDemandTree from "./useDemandTree";
import { EMPTY_STRING } from "../../../../utils/constants";

interface Props {
  type: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Tree = ({ type, setModalFlag }: Props) => {
  const { InstId } = useParams();
  const navigate = useNavigate();

  const { format } = formatter;
  const {
    demandTreeData: { data },
  } = useDemandTree(EMPTY_STRING);
  const [collapseFlag1, setCollapseFlag1] = useState<number[]>([]);
  const [collapseFlag2, setCollapseFlag2] = useState<number[]>([]);
  const [collapseFlag3, setCollapseFlag3] = useState<number[]>([]);
  const [collapseFlag4, setCollapseFlag4] = useState<number[]>([]);

  const handleCollapse = (level: number, index: number) => {
    if (level === 1) {
      if (collapseFlag1.includes(index)) {
        setCollapseFlag1((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag1((prevState) => [...prevState, index]);
      }
    } else if (level === 2) {
      if (collapseFlag2.includes(index)) {
        setCollapseFlag2((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag2((prevState) => [...prevState, index]);
      }
    } else if (level === 3) {
      if (collapseFlag3.includes(index)) {
        setCollapseFlag3((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag3((prevState) => [...prevState, index]);
      }
    } else if (level === 4) {
      if (collapseFlag4.includes(index)) {
        setCollapseFlag4((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag4((prevState) => [...prevState, index]);
      }
    }
  };

  return (
    <>
      {type === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <Title>Demand Summary</Title>
      <div
        className={
          type === PageFor.GENERAL
            ? "demand-summary-treeview"
            : "demand-summary-treeview__modal"
        }
      >
        {type === PageFor.GENERAL && (
          <div className="demand-summary-treeview__icons">
            <NavLink to={`/${InstId}/accounts/demandsummary`}>
              <img src={TreeView} alt="" />
            </NavLink>
            <NavLink to={`/${InstId}/accounts/demandsummary/table`}>
              <img src={TableView} alt="" />
            </NavLink>
          </div>
        )}
        <div
          className={
            type === PageFor.GENERAL
              ? "demand-summary-treeview__datablock"
              : "demand-summary-treeview__datablock--modal"
          }
        >
          <div className="demand-summary-treeview__tree-view">
            <ol className="demand-summary-treeview__tree-view--header">
              <li className="demand-summary-treeview__tree-view--header--li">
                <div className="demand-summary-treeview__tree-view--header--title"></div>

                <div className="demand-summary-treeview__tree-view--header--c-title">
                  <span>Students</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--c-title">
                  <span>NE</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--c-title">
                  <span>TC</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--a-title">
                  <span>OB</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--a-title">
                  <span>Demand</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--a-title">
                  <span>Concession</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--a-title">
                  <span>NE Std's Collection</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--a-title">
                  <span>TC Std's Collection</span>
                </div>
                <div className="demand-summary-treeview__tree-view--header--a-title">
                  <span>Total</span>
                </div>
              </li>
            </ol>
            <ol className="demand-summary-treeview__tree-view__level-1">
              {data?.GetStdDemandSummaryTree.map((deptdata, index) => (
                <React.Fragment key={index}>
                  <li
                    key={deptdata.level_1_id}
                    className="demand-summary-treeview__tree-view__level-1--li "
                  >
                    <div
                      onClick={() => {
                        handleCollapse(1, index);
                      }}
                      className="demand-summary-treeview__tree-view__level-1--title"
                    >
                      {collapseFlag1.includes(index) ? (
                        <img src={Collapse} alt="Collapse" />
                      ) : (
                        <img src={UnCollapse} alt="UnCollapse" />
                      )}
                      <img src={DepartmentImage} alt="Collapse" />
                      {deptdata.level_1_desc}
                    </div>
                    <div className="demand-summary-treeview__tree-view--count">
                      {0}
                    </div>
                    <div className="demand-summary-treeview__tree-view--count">
                      {deptdata.ne_std_no}
                    </div>
                    <div className="demand-summary-treeview__tree-view--count">
                      {deptdata.tc_std_no}
                    </div>
                    <div className="demand-summary-treeview__tree-view--amount">
                      {format(deptdata.std_demand_ob)}
                    </div>
                    <div className="demand-summary-treeview__tree-view--amount">
                      {format(deptdata.std_demand_amt)}
                    </div>
                    <div className="demand-summary-treeview__tree-view--amount">
                      {format(deptdata.std_demand_concession)}
                    </div>
                    <div className="demand-summary-treeview__tree-view--amount">
                      {format(deptdata.ne_std_demand_amt)}
                    </div>
                    <div className="demand-summary-treeview__tree-view--amount">
                      {format(deptdata.tc_std_demand_amt)}
                    </div>
                    <div className="demand-summary-treeview__tree-view--amount">
                      {format(deptdata.total_demand)}
                    </div>
                  </li>

                  {collapseFlag1.includes(index) && (
                    <ol className="demand-summary-treeview__tree-view__level-2">
                      {deptdata.level_2_details.map((bdata, bIndex) => (
                        <React.Fragment key={bIndex}>
                          <li
                            key={bdata.level_2_id}
                            className="demand-summary-treeview__tree-view__level-2--li"
                          >
                            <div
                              onClick={() => handleCollapse(2, bIndex)}
                              className="demand-summary-treeview__tree-view__level-2--title"
                            >
                              {collapseFlag2.includes(bIndex) ? (
                                <img src={Collapse} alt="Collapse" />
                              ) : (
                                <img src={UnCollapse} alt="UnCollapse" />
                              )}
                              <img src={Branch} alt="Collapse" />

                              {bdata.level_2_desc}
                            </div>
                            <div className="demand-summary-treeview__tree-view--count">
                              {0}
                            </div>
                            <div className="demand-summary-treeview__tree-view--count">
                              {bdata.ne_std_no}
                            </div>
                            <div className="demand-summary-treeview__tree-view--count">
                              {bdata.tc_std_no}
                            </div>
                            <div className="demand-summary-treeview__tree-view--amount">
                              {format(bdata.std_demand_ob)}
                            </div>
                            <div className="demand-summary-treeview__tree-view--amount">
                              {format(bdata.std_demand_amt)}
                            </div>
                            <div className="demand-summary-treeview__tree-view--amount">
                              {format(bdata.std_demand_concession)}
                            </div>
                            <div className="demand-summary-treeview__tree-view--amount">
                              {format(bdata.ne_std_demand_amt)}
                            </div>
                            <div className="demand-summary-treeview__tree-view--amount">
                              {format(bdata.tc_std_demand_amt)}
                            </div>
                            <div className="demand-summary-treeview__tree-view--amount">
                              {format(bdata.total_demand)}
                            </div>
                          </li>

                          {collapseFlag2.includes(bIndex) && (
                            <ol className="demand-summary-treeview__tree-view__level-2">
                              {bdata.level_3_details.map((cdata, cIndex) => (
                                <React.Fragment key={cIndex}>
                                  <li className="demand-summary-treeview__tree-view__level-2--li">
                                    <div
                                      onClick={() => handleCollapse(3, cIndex)}
                                      className="demand-summary-treeview__tree-view__level-3--title"
                                    >
                                      {collapseFlag3.includes(cIndex) ? (
                                        <img src={Collapse} alt="Collapse" />
                                      ) : (
                                        <img
                                          src={UnCollapse}
                                          alt="UnCollapse"
                                        />
                                      )}
                                      <img src={Class} alt="Collapse" />
                                      {cdata.level_3_desc}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--count">
                                      {0}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--count">
                                      {cdata.ne_std_no}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--count">
                                      {cdata.tc_std_no}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--amount">
                                      {format(cdata.std_demand_ob)}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--amount">
                                      {format(cdata.std_demand_amt)}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--amount">
                                      {format(cdata.std_demand_concession)}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--amount">
                                      {format(cdata.ne_std_demand_amt)}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--amount">
                                      {format(cdata.tc_std_demand_amt)}
                                    </div>
                                    <div className="demand-summary-treeview__tree-view--amount">
                                      {format(cdata.total_demand)}
                                    </div>
                                  </li>

                                  {collapseFlag3.includes(cIndex) && (
                                    <ol className="demand-summary-treeview__tree-view__level-2">
                                      {cdata.level_4_details?.map(
                                        (semdata, semIndex) => (
                                          <React.Fragment key={semIndex}>
                                            <li className="demand-summary-treeview__tree-view__level-2--li">
                                              <div
                                                onClick={() =>
                                                  handleCollapse(4, semIndex)
                                                }
                                                className="demand-summary-treeview__tree-view__level-4--title"
                                              >
                                                {collapseFlag4.includes(
                                                  semIndex
                                                ) ? (
                                                  <img
                                                    src={Collapse}
                                                    alt="Collapse"
                                                  />
                                                ) : (
                                                  <img
                                                    src={UnCollapse}
                                                    alt="UnCollapse"
                                                  />
                                                )}
                                                <img
                                                  src={Semester}
                                                  alt="Collapse"
                                                />
                                                {semdata.level_4_desc}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--count">
                                                {0}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--count">
                                                {semdata.ne_std_no}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--count">
                                                {semdata.tc_std_no}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--amount">
                                                {format(semdata.std_demand_ob)}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--amount">
                                                {format(semdata.std_demand_amt)}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--amount">
                                                {format(
                                                  semdata.std_demand_concession
                                                )}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--amount">
                                                {format(
                                                  semdata.ne_std_demand_amt
                                                )}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--amount">
                                                {format(
                                                  semdata.tc_std_demand_amt
                                                )}
                                              </div>
                                              <div className="demand-summary-treeview__tree-view--amount">
                                                {format(semdata.total_demand)}
                                              </div>
                                            </li>

                                            {collapseFlag4.includes(
                                              semIndex
                                            ) && (
                                              <ol className="demand-summary-treeview__tree-view__level-2">
                                                {semdata.level_5_details?.map(
                                                  (secdata, secIndex) => (
                                                    <React.Fragment
                                                      key={secIndex}
                                                    >
                                                      <li className="demand-summary-treeview__tree-view__level-2--li">
                                                        <div className="demand-summary-treeview__tree-view__level-5--title">
                                                          <img
                                                            src={Section}
                                                            alt="Collapse"
                                                          />
                                                          {secdata.level_5_desc}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--count">
                                                          {0}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--count">
                                                          {secdata.ne_std_no}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--count">
                                                          {secdata.tc_std_no}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--amount">
                                                          {format(
                                                            secdata.std_demand_ob
                                                          )}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--amount">
                                                          {format(
                                                            secdata.std_demand_amt
                                                          )}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--amount">
                                                          {format(
                                                            secdata.std_demand_concession
                                                          )}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--amount">
                                                          {format(
                                                            secdata.ne_std_demand_amt
                                                          )}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--amount">
                                                          {format(
                                                            secdata.tc_std_demand_amt
                                                          )}
                                                        </div>
                                                        <div className="demand-summary-treeview__tree-view--amount">
                                                          {format(
                                                            secdata.total_demand
                                                          )}
                                                        </div>
                                                      </li>
                                                    </React.Fragment>
                                                  )
                                                )}
                                              </ol>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </ol>
                                  )}
                                </React.Fragment>
                              ))}
                            </ol>
                          )}
                        </React.Fragment>
                      ))}
                    </ol>
                  )}
                </React.Fragment>
              ))}
            </ol>
          </div>
        </div>
        {type === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </div>
    </>
  );
};

export default Tree;

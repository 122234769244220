import { gql } from "@apollo/client";

export const AddLibBookMedia = gql`
  mutation AddLibBookMedia(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateLibBookMediaInput!
  ) {
    AddLibBookMedia(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const AddAcctMiscActivities = gql`
  mutation AddAcctMiscActivities(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateAcctMiscActivitiesInput!
  ) {
    AddAcctMiscActivities(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const AddAcctStdMiscActivityLogs = gql`
  mutation AddAcctStdMiscActivityLogs(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $fin_yr_id: ID!
    $student_id: ID!
    $log_date: Time!
    $input: [StdMiscLogData!]!
  ) {
    AddAcctStdMiscActivityLogs(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      fin_yr_id: $fin_yr_id
      student_id: $student_id
      log_date: $log_date
      input: $input
    )
  }
`;

export const AddLibBookDept = gql`
  mutation AddLibBookDept(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateLibBookDeptInput!
  ) {
    AddLibBookDept(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;

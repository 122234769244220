import { gql } from "@apollo/client";
export const GetMstInstLogoAndSigns = gql`
  query GetMstInstLogoAndSigns(
    $token: String!
    $inst_id: ID!
    $file_type: InstFileType!
  ) {
    GetMstInstLogoAndSigns(
      token: $token
      inst_id: $inst_id
      file_type: $file_type
    )
  }
`;

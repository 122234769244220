import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";

import { Title } from "../../../../stories/Title/Title";

import { TableHeaderProps } from "../../../../Types/Tables";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";

import { Operation } from "../../../../utils/Enum.types";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import Home from "../../Home/Index";
import { useLazyQuery, useMutation } from "@apollo/client";
import LoadingModal from "../../../../pages/LoadingModal";
import { msgType } from "../../../../utils/Form.types";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";

import { AddAcdTestType, UpdateAcdTestType } from "../../queries/test/mutation";
import useAcdTestTypes, {
  GetAcdTestTypeDetails,
} from "../../hooks/useAcdTestTypes";

import { GetAcdTestType, GetAcdTestTypeById } from "../../queries/test/query";
import { singleNodeVars } from "../../../../Types/Accounting";
import { Keys } from "../../../../utils/Enum.keys";
import DeleteModal from "../../../../pages/DeleteModal";
import { DeleteAcdTestTypeById } from "../../queries/holidays/mutation";
import { TestTypeCreationValidation } from "../../../../utils/academicValidation";
import Input from "../../../../components/common/Input/Index";
import { Form, Formik } from "formik";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

interface typeTypeData {
  test_type_name: string;
  ext_max_marks: string;
  ext_min_marks: string;
}
interface TestTypeNodeData {
  node: GetAcdTestTypeDetails;
}
const { Academics_Table } = require("../../json/table.json");

const TestCreation = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  const [formData, setFormData] = useState<typeTypeData>({
    test_type_name: "",
    ext_max_marks: "",
    ext_min_marks: "",
  });

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [testTypeId, setTestTypeId] = useState(0);
  const { acdTestTypes } = useAcdTestTypes();
  const testTypeNameRef = document.getElementsByName("test_type_name")[0];
  const [GetTestTypeDetails] = useLazyQuery<TestTypeNodeData, singleNodeVars>(
    GetAcdTestTypeById,
    { variables: { id: testTypeId, token } }
  );

  const [AddNewTest, { loading: creationTestLoading }] = useMutation(
    AddAcdTestType,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [updateTest, { loading: updationTestLoading }] = useMutation(
    UpdateAcdTestType,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteTestType] = useMutation(DeleteAcdTestTypeById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleNewSubjects = () => {
    if (Number(formData.ext_max_marks) < Number(formData.ext_min_marks)) {
      setMessage({
        flag: true,
        message: "Min Marks cannot be greator then Max Marks",
        operation: Operation.NONE,
      });
      return;
    }

    if (operation === Operation.CREATE) {
      AddNewTest({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input: {
            test_type_name: removeMoreSpace(formData.test_type_name),
            test_type_idx: 0,
            ext_max_marks: formData.ext_max_marks,
            ext_min_marks: formData.ext_min_marks,
            int_max_marks: 0,
            int_min_marks: 0,
          },
        },
        refetchQueries: [
          {
            query: GetAcdTestType,
            variables: {
              inst_id: InstId!,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Test Type Created Sucessfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      updateTest({
        variables: {
          token,
          id: testTypeId,
          inst_id: InstId,
          user_details,
          input: {
            test_type_name: removeMoreSpace(formData.test_type_name),
            test_type_idx: 0,
            ext_max_marks: formData.ext_max_marks,
            ext_min_marks: formData.ext_min_marks,
            int_max_marks: 0,
            int_min_marks: 0,
          },
        },
        refetchQueries: [
          {
            query: GetAcdTestType,
            variables: {
              inst_id: InstId!,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Test Type Updated Sucessfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: Number) => {
    DeleteTestType({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdTestType,
          variables: {
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Test Type Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleClear = () => {
    setFormData({
      test_type_name: "",
      ext_max_marks: "",
      ext_min_marks: "",
    });
    setOperation(Operation.CREATE);
    setTestTypeId(0);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
      setTestTypeId(0);
    }
    testTypeNameRef.focus();
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (testTypeId) {
        const { data } = await GetTestTypeDetails();
        if (data) {
          const { node } = data;
          setFormData({
            test_type_name: node.test_type_name,
            ext_max_marks: node.ext_max_marks,
            ext_min_marks: node.ext_min_marks,
          });
        }
      }
    };
    fetchData();
  }, [testTypeId, GetTestTypeDetails]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Test Type</Title>
      <Formik
        initialValues={formData}
        validationSchema={TestTypeCreationValidation}
        enableReinitialize
        onSubmit={handleNewSubjects}
      >
        {(meta) => {
          return (
            <Form className="academic-test-creation">
              <div className="row g-0 academic-test-creation__data">
                <div className="col booktype-left h-100">
                  <Title variant="subtitle1">
                    {operation === Operation.UPDATE
                      ? "Update Test Type"
                      : "Add Test Type"}
                  </Title>
                  <div className="academic-test-creation__data--details">
                    <Input
                      LabelName="Test Type"
                      autoFocus={message.operation === Operation.NONE || true}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === Keys.ENTER) {
                          buttonRef.current?.focus();
                        }
                      }}
                      name="test_type_name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormData((prevValues) => ({
                          ...prevValues,
                          test_type_name: e.target.value,
                        }))
                      }
                      values={formData.test_type_name}
                    />
                    <Input
                      LabelName="Max Marks"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormData((prevValues) => ({
                          ...prevValues,
                          ext_max_marks: e.target.value,
                        }))
                      }
                      name="ext_max_marks"
                      values={formData.ext_max_marks}
                    />
                    <Input
                      LabelName="Min Marks"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData((prevValues) => ({
                          ...prevValues,
                          ext_min_marks: e.target.value,
                        }));
                      }}
                      name="ext_min_marks"
                      className={
                        Number(formData.ext_min_marks) >
                        Number(formData.ext_max_marks)
                          ? "max_error"
                          : ""
                      }
                      values={formData.ext_min_marks}
                    />
                  </div>
                </div>
                <div className="col booktype-right h-100">
                  <Title variant="subtitle1">List of Test Type(s)</Title>
                  {acdTestTypes.error ? (
                    <>{acdTestTypes.error.message}</>
                  ) : !acdTestTypes.data?.GetAcdTestType?.length &&
                    !acdTestTypes.loading ? (
                    <b className="nodata">Sorry, No data</b>
                  ) : (
                    <TableContainer className="academic-test-creation__table ">
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {Academics_Table.TestType.Table_Headers.map(
                              (th: TableHeaderProps, index: React.Key) => {
                                return (
                                  <React.Fragment key={index}>
                                    <TableCell
                                      className={th.className}
                                      colSpan={th.colSpan}
                                      rowSpan={th.rowSpan}
                                    >
                                      {th.labelName}
                                    </TableCell>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>Max</TableCell>
                            <TableCell>Min</TableCell>
                            <TableCell>Max</TableCell>
                            <TableCell>Min</TableCell>
                            <TableCell>Max</TableCell>
                            <TableCell>Min</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {acdTestTypes.data?.GetAcdTestType?.map(
                            (edge, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    align="center"
                                    className="academic-test-creation__table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell>{edge.test_type_name}</TableCell>
                                  <TableCell id="td-center">
                                    {edge.ext_max_marks}
                                  </TableCell>
                                  <TableCell id="td-center">
                                    {edge.ext_min_marks}
                                  </TableCell>
                                  <TableCell id="td-center">
                                    {edge.int_max_marks
                                      ? edge.int_max_marks
                                      : "-"}
                                  </TableCell>
                                  <TableCell id="td-center">
                                    {edge.int_max_marks
                                      ? edge.int_min_marks
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    className="font-green"
                                    id="td-center"
                                  >
                                    {edge.ext_max_marks + edge.int_max_marks}
                                  </TableCell>
                                  <TableCell
                                    className="font-amber"
                                    id="td-center"
                                  >
                                    {edge.ext_min_marks + edge.int_min_marks}
                                  </TableCell>

                                  <TableCell
                                    className="academic-test-creation__table--marks"
                                    id="td-center"
                                  >
                                    <img
                                      src={Edit}
                                      alt="/"
                                      onClick={() => {
                                        setTestTypeId(edge.id);
                                        setOperation(Operation.UPDATE);
                                      }}
                                    />
                                    <img
                                      src={Delete}
                                      alt="/"
                                      onClick={() => {
                                        setDeleteId(edge.id);
                                        setDeleteModal(!deleteModal);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              </div>

              <div className="academic-test-creation__buttons">
                <Button
                  mode="save"
                  type="submit"
                  buttonref={buttonRef}
                  disabled={!formData.test_type_name}
                />

                <Button mode="clear" type="button" onClick={handleClear} />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => navigate(-1)}
                />
              </div>
            </Form>
          );
        }}
      </Formik>

      <LoadingModal
        flag={
          creationTestLoading || acdTestTypes.loading || updationTestLoading
        }
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={deleteId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default TestCreation;

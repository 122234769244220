import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { EMPTY_STRING } from "../../../utils/constants";

import Header from "./Header";
import BreadCrumbs from "./BreadCrumbs";

interface MenuProps {
  title: string;
  url: string;
}

interface DropDownProp {
  submenus: MenuProps[];
  dropdown: boolean;
  depthLevel: number;
  url: string;
}
interface MenuItemsProps {
  menu?: string | number;
  url?: string;
  title: string;
  submenu: string[];
}

const Dropdown = ({ submenus, dropdown, depthLevel, url }: DropDownProp) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : EMPTY_STRING;
  return (
    <ul
      className={`dropdown ${dropdownClass} ${
        dropdown ? "show" : EMPTY_STRING
      }`}
    >
      {submenus.map((submenu: MenuProps, index: React.Key) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          url={url}
        />
      ))}
    </ul>
  );
};

const MenuItems = ({ items, depthLevel }: any) => {
  const [dropdown, setDropdown] = useState(false);
  const nullRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (
        dropdown &&
        nullRef &&
        nullRef.current &&
        !nullRef.current.contains(event.target as Element)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    // Cleanup the event listener
    return () => document.removeEventListener("mousedown", handler);
  }, [dropdown]);

  return (
    <li ref={nullRef} className="menu-items">
      {items?.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
            className={
              depthLevel > 0 ? "menu-items__submenu" : "menu-items__mainmenu"
            }
          >
            {items.title}

            {depthLevel > 0 ? (
              <span className="menu-items__rightarrow">
                <KeyboardArrowRight />
              </span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            url={items.url}
          />
        </>
      ) : (
        items && <NavLink to={items?.url}>{items?.title}</NavLink>
      )}
    </li>
  );
};

const Navbar = () => {
  const VMSItems = [
    {
      title: "Vehicle Details",
      submenu: [{ title: "Vehicle Details", url: `/vms/vehicledetails` }],
    },
    {
      title: "Staff Details",
      submenu: [{ title: "Staff Details", url: `/vms/staffdetails` }],
    },
    {
      title: "Masters",
      submenu: [{ title: "Masters", url: `/vms/masters` }],
    },
    {
      title: "Supplier",
      submenu: [
        { title: "Supplier", url: `/vms/supplierdetails` },
        { title: "Fuel Indent", url: `/vms/fuelindent` },
        { title: "Fuel Purchase", url: `/vms/fuelpurchase` },
      ],
    },
  ];
  const [items, setItems] = useState<any>([]);
  useEffect(() => {
    setItems(VMSItems);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row g-0">
        <Header />
      </div>
      <div className="row g-0">
        <BreadCrumbs />
      </div>

      <nav>
        <ul className="menus">
          {items
            .filter((menu: MenuItemsProps) => menu)
            .map((menu: MenuItemsProps, index: React.Key) => {
              const depthLevel = 0;

              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  url={menu.url}
                />
              );
            })}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;

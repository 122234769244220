import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { EnqStudentDetailsByMasterVoucherId } from "../../../queries/common";
import { nodevars, VouchernodeData } from "../../../Types/Accounting";
import { voucherMasterDetails } from "./useAcctVoucherDetailsByDates";

const usePassedOutStudentReceiptDetails = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [passedOutStudentReceiptDetails, setPassedOutStudentReceiptDetails] =
    useState<voucherMasterDetails>();
  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnqStudentDetailsByMasterVoucherId
  );
  useEffect(() => {
    if (state.receiptId) {
      if (state.aluminiStudentId) {
        GetStudentDetails({
          variables: {
            token,
            ids: [state.receiptId],
          },
        }).then(({ data }) => {
          if (data) {
            setPassedOutStudentReceiptDetails(data?.nodes[0]);
          }
        });
      }
    }
  }, [GetStudentDetails, state.receiptId, state.aluminiStudentId, token]);
  return { passedOutStudentReceiptDetails };
};

export default usePassedOutStudentReceiptDetails;

import React from "react";
import Navbar from "../../../components/common/Navbar/Index";
import { LicenseTypes } from "../../../utils/Enum.types";
interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  return (
    <>
      <Navbar NavType={LicenseTypes.TRANSPORT} />
      {DashBoardRequired && <p>Transport DashBoard</p>}
    </>
  );
};

export default Index;

import React, { useState } from "react";
import EduateLogo from "../../../../../images/EduateLogo.svg";
import Avatar from "../../../../../images/Avatar.svg";
import { Title } from "../../../../../stories/Title/Title";
import { Label } from "../../../../../stories/Label/Label";
import { IdTemplateProps, IdTemplates } from "../Portrait/ThemeBased/Template1";

const colorOption = [
  {
    label: "amber",
    value: "#fbbf24",
  },
  { label: "blue", value: "#3b82f6" },
  { label: "emarald", value: "#10b981" },
  { label: "fuchsia", value: "#d946ef" },
  { label: "pink", value: "#ec4899" },
];
const textColorOption = [
  {
    label: "amber",
    value: "#fbbf24",
  },
  { label: "blue", value: "#60a5fa" },
  { label: "emarald", value: "#34d399" },
  { label: "fuchsia", value: "#e879f9" },
  { label: "pink", value: "#f472b6" },
  { label: "black", value: "black" },
  { label: "white", value: "white" },
];
const Template1 = ({ templateFor }: IdTemplateProps) => {
  const [backgroundColor, setBackgroundColor] = useState("#fffff");
  const [footerColor, setFooterColor] = useState("#a3a3a3");
  const [headerColor, setHeaderColor] = useState("#a3a3a3");
  const [textColor, setTextColor] = useState("black");
  return (
    <>
      <div
        className={
          templateFor === IdTemplates.DEMO ? "id-card-template__md" : ""
        }
      >
        <div className="id-vertical-temp1">
          <div
            className={`id-vertical-temp1__card${
              templateFor === IdTemplates.DEMO ? "" : "--li"
            }`}
          >
            <div
              className={`id-vertical-temp1__card--fs${
                templateFor === IdTemplates.DEMO ? "" : "--li"
              }`}
            >
              <Title variant="subtitle1">
                {templateFor === IdTemplates.LIST ? "Landscape" : "Page 1"}
              </Title>
              <div
                className="id-vertical-temp1__card--fs--id"
                style={{ backgroundColor: backgroundColor }}
              >
                <div className="id-vertical-temp1__card--fs--id--profile">
                  <div
                    className="id-vertical-temp1__card--fs--id--profile--header"
                    style={{ background: headerColor }}
                  >
                    <div className="id-vertical-temp1__card--fs--id--inst-logo">
                      <img src={EduateLogo} alt="" />
                    </div>
                    <div>
                      <span className="id-vertical-temp1__card--fs--id--c-name">
                        Demo
                      </span>
                      <b className="id-vertical-temp1__card--fs--id--inst-name">
                        Demo Institution of Technology
                      </b>
                    </div>
                  </div>
                  <div className="id-vertical-temp1__card--fs--id--std-details">
                    <div className="id-vertical-temp1__card--fs--id--std-image">
                      <img src={Avatar} alt="" />
                    </div>
                    <div className="id-vertical-temp1__card--fs--id--grid">
                      <label>Admission No.</label>
                      <span>:</span>
                      <b style={{ color: textColor }}>1245785</b>
                      <label>Name</label>
                      <span>:</span>
                      <b style={{ color: textColor }}>Suraj Deeru</b>
                      <label>Class</label>
                      <span>:</span>
                      <b style={{ color: textColor }}>1st Standard</b>
                      <label>Section</label>
                      <span>:</span>
                      <b style={{ color: textColor }}>1st Standard</b>
                      <label>Mobile No</label>
                      <span>:</span>
                      <b style={{ color: textColor }}>9863254785</b>
                    </div>
                  </div>
                </div>
                <div
                  className="id-vertical-temp1__card--fs--id--footer"
                  style={{ backgroundColor: footerColor }}
                >
                  <span>www.demogroups.com</span>
                </div>
              </div>
            </div>
            {templateFor === IdTemplates.DEMO && (
              <div className="id-vertical-temp1__card--bs">
                <Title variant="subtitle1">Page 2</Title>
                <div
                  className="id-vertical-temp1__card--bs--id"
                  style={{ backgroundColor: backgroundColor }}
                >
                  <div className="id-vertical-temp1__card--fs--id--profile">
                    <div className="id-vertical-temp1__card--fs--id--address">
                      <div
                        className="id-vertical-temp1__card--fs--id--profile--header"
                        style={{ background: headerColor }}
                      >
                        <div className="id-vertical-temp1__card--fs--id--inst-logo">
                          <img src={EduateLogo} alt="" />
                        </div>
                        <div>
                          <span className="id-vertical-temp1__card--fs--id--c-name">
                            Demo
                          </span>
                          <b className="id-vertical-temp1__card--fs--id--inst-name">
                            Demo Institution of Technology
                          </b>
                        </div>
                      </div>
                      <div className="id-vertical-temp1__card--bs--grid">
                        <label>Address</label>
                        <span>:</span>
                        <b>
                          New Horizon knowledge Park, Bellandur Main Rd, Near
                          Marathahalli, Bengaluru-560103.
                        </b>
                        <label>Father Name</label>
                        <span>:</span>
                        <b>Hemanth</b>
                      </div>
                    </div>
                    <div className="id-vertical-temp1__card--fs--id--inst-address">
                      <div className="id-vertical-temp1__card--fs--id--signature">
                        <span>PRINCIPAL</span>
                      </div>
                      <div className="id-vertical-temp1__card--fs--id--addr">
                        New Horizon knowledge Park, Bellandur Main Rd, Near
                        Marathahalli, Bengaluru-560103.
                      </div>
                    </div>
                  </div>
                  <div
                    className="id-vertical-temp1__card--fs--id--footer"
                    style={{ backgroundColor: footerColor }}
                  >
                    <span>www.demogroups.com</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {templateFor === IdTemplates.DEMO && (
            <div className="id-vertical-temp1__button">
              <button>Use This Template</button>
            </div>
          )}
        </div>
      </div>
      {templateFor === IdTemplates.DEMO && (
        <div className="id-card-template__rs">
          <Title>Configurations</Title>
          <div className="id-card-template__rs--grid">
            <Label>Background Color</Label>
            <select
              value={backgroundColor}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setBackgroundColor(event.target.value);
              }}
            >
              {colorOption.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Label>Footer Color</Label>
            <select
              value={footerColor}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setFooterColor(event.target.value);
              }}
            >
              {colorOption.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Label>Header Color</Label>
            <select
              value={headerColor}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setHeaderColor(event.target.value);
              }}
            >
              {colorOption.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Label>Text Color</Label>
            <select
              value={textColor}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setTextColor(event.target.value);
              }}
            >
              {textColorOption.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default Template1;

import { gql } from "@apollo/client";

export const AddPayRollEmpMaster = gql`
  mutation AddPayRollEmpMaster(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $flags: EmpFlags!
    $input: CreatePayRollEmpMasterInput!
  ) {
    AddPayRollEmpMaster(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      flags: $flags
      input: $input
    ) {
      id
    }
  }
`;
export const UpdatePayRollEmpMaster = gql`
  mutation UpdatePayRollEmpMaster(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollEmpMasterInput!
  ) {
    UpdatePayRollEmpMaster(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    ) {
      id
    }
  }
`;
export const UpdatePayRollEmpPersonalDetails = gql`
  mutation UpdatePayRollEmpPersonalDetails(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollEmpPersonalDetailsInput!
  ) {
    UpdatePayRollEmpPersonalDetails(
      token: $token
      pr_emp_id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const UpdatePayRollEmpAcdProfDetails = gql`
  mutation UpdatePayRollEmpAcdProfDetails(
    $token: String!
    $pr_emp_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollEmpAcdProfDetailsInput!
  ) {
    UpdatePayRollEmpAcdProfDetails(
      token: $token
      pr_emp_id: $pr_emp_id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const UpdatePayRollEmpOtherDetails = gql`
  mutation UpdatePayRollEmpOtherDetails(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdatePayRollEmpOtherDetailsInput!
  ) {
    UpdatePayRollEmpOtherDetails(
      token: $token
      pr_emp_id: $id
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const DeletePayRollEmpSalaryStructMasterAndDetails = gql`
  mutation DeletePayRollEmpSalaryStructMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
  ) {
    DeletePayRollEmpSalaryStructMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
    )
  }
`;
export const UpdatePayRollEmpSalaryStructMasterAndDetails = gql`
  mutation UpdatePayRollEmpSalaryStructMasterAndDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
    $input: EmpSalaryDetails!
  ) {
    UpdatePayRollEmpSalaryStructMasterAndDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
      input: $input
    )
  }
`;

export const EnablePayRollEmpMasterLoginStatusWithNewEmail = gql`
  mutation EnablePayRollEmpMasterLoginStatusWithNewEmail(
    $token: String!
    $pr_emp_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $email: String!
  ) {
    EnablePayRollEmpMasterLoginStatusWithNewEmail(
      token: $token
      pr_emp_id: $pr_emp_id
      inst_id: $inst_id
      user_details: $user_details
      email: $email
    )
  }
`;

export const ImportEmpDataFromXls = gql`
  mutation ImportEmpDataFromXls(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $flags: EmpFlags!
    $input: ImportEmpData!
  ) {
    ImportEmpDataFromXls(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      flags: $flags
      input: $input
    )
  }
`;
export const ImportPayRollJustLoginAcct = gql`
  mutation ImportPayRollJustLoginAcct(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: ImportJustLoginAcctInput!
  ) {
    ImportPayRollJustLoginAcct(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
export const ResetEmpPassword = gql`
  mutation ResetEmpPassword(
    $token: String!
    $pr_emp_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $password: String!
  ) {
    ResetEmpPassword(
      token: $token
      pr_emp_id: $pr_emp_id
      inst_id: $inst_id
      user_details: $user_details
      password: $password
    )
  }
`;
export const UpdateEmpResetPwdDate = gql`
  mutation UpdateEmpResetPwdDate(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
  ) {
    UpdateEmpResetPwdDate(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
    )
  }
`;
export const UpdateStdResetPwdDate = gql`
  mutation UpdateStdResetPwdDate(
    $token: String!
    $inst_id: ID!
    $student_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateStdResetPwdDate(
      token: $token
      inst_id: $inst_id
      student_id: $student_id
      user_details: $user_details
    )
  }
`;

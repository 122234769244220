import { gql } from "@apollo/client";

export const ImportLibBooks = gql`
  mutation ImportLibBooks(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $lib_flags: LibFlags!
    $input: ImportLibBookData!
  ) {
    ImportLibBooks(
      token: $token
      inst_id: $inst_id
      lib_flags: $lib_flags
      user_details: $user_details
      input: $input
    )
  }
`;

import { gql } from "@apollo/client";
export const AddCustomer = gql`
  mutation AddCustomer(
    $input: CreateMstCustomerInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddCustomer(token: $token, input: $input, user_details: $user_details)
  }
`;

import { useContext } from "react";
import NavBar from "../../../components/common/Navbar/Index";
import { AppContext } from "../../../context/context";
import { DashBoardFor, LicenseTypes } from "../../../utils/Enum.types";
import MastersDashboard from "../Dashboard/Index";
interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  const { state } = useContext(AppContext);
  const path = window.location.pathname;

  return (
    <>
      <NavBar NavType={LicenseTypes.MASTERS} />
      {DashBoardRequired && (
        <MastersDashboard
          pageType={
            state.claims?.OWNER && path.includes("/proprietor/admissions")
              ? DashBoardFor.PROPRIETOR
              : DashBoardFor.GENERAL
          }
        />
      )}
    </>
  );
};

export default Index;

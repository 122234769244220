import { gql } from "@apollo/client";

export const GetUserRightsByEmpId = gql`
  query GetUserRightsByEmpId($token: String!, $inst_id: ID!, $pr_emp_id: ID!) {
    GetUserRightsByEmpId(
      token: $token
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
    ) {
      is_inst_sysadmin
      inst_id
      default_theme
      pr_emp_details {
        emp_type
      }
      pr_emp_id
      user_rights_details {
        id
        module_rights_type
        view
        add
        edit
        delete
        inst_module_details {
          eduate_module_details {
            id
            Name
          }
        }
      }
    }
  }
`;

export const GetUserRights = gql`
  query GetUserRights(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: UserRightsMasterOrder
  ) {
    GetUserRights(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          id
          pr_emp_details {
            emp_first_name
            emp_middle_name
            emp_last_name
            emp_id
            emp_email
            emp_mobile
            emp_type
            id
            pr_designation_details {
              designation_desc
            }
          }
          user_rights_details {
            inst_module_details {
              eduate_module_details {
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const GetInstUserAccess = gql`
  query GetInstUserAccess(
    $token: String!
    $id: ID!
    $first: Int
    $after: Cursor
    $query_type: InstUserAccessQueryType!
  ) {
    GetInstUserAccess(
      token: $token
      id: $id
      after: $after
      first: $first
      query_type: $query_type
    ) {
      edges {
        node {
          id
          pr_emp_details {
            id
            emp_id
            emp_title
            emp_first_name
            emp_middle_name
            emp_last_name
            emp_type
          }
          inst_details {
            id
            inst_name
          }
        }
      }
    }
  }
`;
export const GetUserDashBoardDetails = gql`
  query GetUserDashBoardDetails(
    $token: String!
    $inst_id: ID!
    $pr_emp_id: ID!
  ) {
    GetUserDashBoardDetails(
      token: $token
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
    ) {
      total_inst_count
      total_std_count
      boys_count
      girls_count
    }
  }
`;

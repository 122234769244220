import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { toIsoDate, toStandardDate } from "../../../utils/UtilFunctions";
import { GetAcdInstGeneralHolidays } from "../queries/holidays/list";
import {
  GetAcdInstGeneralHolidaysData,
  GetAcdInstGeneralHolidaysVars,
} from "../types/attendance";
import { AppContext } from "../../../context/context";
import { Direction, SortBy } from "../../../utils/Enum.types";
export enum holiday_query_type {
  WEEKEND_HOLIDAYS = "WEEKEND_HOLIDAYS",
  GENERAL_AND_UNEXPECTED_HOLIDAYS = "GENERAL_AND_UNEXPECTED_HOLIDAYS",
  GENERAL_HOLIDAYS = "GENERAL_HOLIDAYS",
  UNEXPECTED_HOLIDAYS = "UNEXPECTED_HOLIDAYS",
  VACATION_HOLIDAYS = "VACATION_HOLIDAYS",
  ALL_HOLIDAYS = "ALL_HOLIDAYS",
  HOLIDAY_BY_DATE = "HOLIDAY_BY_DATE",
  EVENTS = "EVENTS",
}
const useAcdGeneralHolidays = (
  acdYr: number,
  end_date: string,
  start_date: string,
  searchText: string,
  queryType: holiday_query_type | string
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [GetAllotedHolidays, { data, loading, error }] = useLazyQuery<
    GetAcdInstGeneralHolidaysData,
    GetAcdInstGeneralHolidaysVars
  >(GetAcdInstGeneralHolidays, {
    variables: {
      acd_yr_id: acdYr,
      input: {
        end_date: toIsoDate(end_date),
        in_between_flag:
          queryType === holiday_query_type.HOLIDAY_BY_DATE ? false : true,
        start_date: toIsoDate(start_date),
        holiday_query_type: queryType,
      },
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.HOLIDAY_DATE,
      },
      inst_id: InstId ?? (state.InstId as string),
      token,
      holidayDesc: searchText,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (acdYr && queryType && token) {
      GetAllotedHolidays().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetAcdInstGeneralHolidays.edges.map((edge) => ({
              label: `${edge.node.holiday_desc} ${toStandardDate(
                edge.node.holiday_date
              )}`,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [
    acdYr,
    GetAllotedHolidays,
    searchText,
    queryType,
    state.InstId,
    token,
    end_date,
    start_date,
  ]);

  return { InstGeneralHolidays: { data, loading, error, responseType } };
};

export default useAcdGeneralHolidays;

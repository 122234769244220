import React, { useContext, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import DataFetch from "../../../../images/Add.svg";
import Close from "../../../../images/Close.svg";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Group from "../Masters/Group";
import { Direction, Operation, PageFor } from "../../../../utils/Enum.types";
import Category from "../Masters/Category";
import Type from "../Masters/Type";
import UnitOfMeasurement from "../Masters/UnitOfMeasurement";
import usePurchasesMasterData from "../hooks/usePurchasesMasterData";
import { msgType, responseType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { AddAcctStockItem } from "../../queries/purchages/mutation";
import { useMutation } from "@apollo/client";
import MessageModal from "../../../../pages/MessageModal";
import useToken from "../../../../customhooks/useToken";
import { GetAcctStockItems } from "../../queries/purchages/queries";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import usePurchaseItems, {
  PuchanseItemQueryType,
} from "../hooks/usePurchaseItems";
import LoadingModal from "../../../../pages/LoadingModal";
import { AppContext } from "../../../../context/context";
import useAcctTableJson from "../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();

  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [group, setGroup] = useState(false);
  const [category, setCategory] = useState(false);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState(false);
  const [type, setType] = useState(false);
  const { Categories, Groups, UOM } = usePurchasesMasterData();
  const [operation, setOperation] = useState(Operation.CREATE);
  const [itemdesc, setItemDesc] = useState("");
  const [groupId, setGroupId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [umId, setUmId] = useState<responseType | null>(null);
  const [itemType, setItemType] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { Items } = usePurchaseItems(
    PuchanseItemQueryType.ALL_ITEMS,
    EMPTY_STRING
  );
  const { user_details } = useLoggedInUserDetails();

  const [AddItem, { loading: creationLoading }] = useMutation(
    AddAcctStockItem,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleCreation = (e: React.FormEvent) => {
    e.preventDefault();
    AddItem({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: {
          item_desc: removeMoreSpace(itemdesc),
          item_type: itemType,
          item_in_use: true,
          item_grp_id: groupId?.value,
          item_category_id: categoryId?.value,
          item_uom_id: umId?.value,
        },
      },
      refetchQueries: [
        {
          query: GetAcctStockItems,
          variables: {
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            orderBy: { direction: Direction.ASC },
            query_type: "ALL_ITEMS",
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Category Created Sucessfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setOperation(Operation.CREATE);
      setModalFlag(false);
      setItemDesc("");
      setGroupId(null);
      setCategoryId(null);
      setUmId(null);
      setItemType("");
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>Item Details</Title>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "purchase-entry-masters"
            : "purchase-entry-masters__modal"
        }
        onSubmit={handleCreation}
      >
        <div className="row g-0 purchase-entry-masters__datablock">
          <div className="col purchase-entry-masters__datablock--frame-left">
            <Title variant="subtitle1">Add Item Details</Title>
            <div className="purchase-entry-masters__tableblock">
              <div className="label-grid">
                <Label>Item Description</Label>
                <Input
                  required
                  autoFocus
                  value={itemdesc}
                  onChange={(e) => setItemDesc(e.target.value)}
                />
              </div>
              <div className="purchase-entry-masters__grid-trio">
                <Label>Item Group</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  options={Groups.groupsResponseType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setGroupId(newValue as responseType);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    } else if (e.key === Keys.BACKSPACE) {
                      setGroupId(null);
                    }
                  }}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, groupId)
                  }
                  value={groupId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
                <img
                  className="data-fetch-icon"
                  src={DataFetch}
                  alt=""
                  onClick={() => setGroup(!group)}
                />
              </div>
              <div className="purchase-entry-masters__grid-trio">
                <Label>Item Category</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  options={Categories.categoriesResponseType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategoryId(newValue as responseType);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    } else if (e.key === Keys.BACKSPACE) {
                      setCategoryId(null);
                    }
                  }}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, categoryId)
                  }
                  value={categoryId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
                <img
                  className="data-fetch-icon"
                  src={DataFetch}
                  alt=""
                  onClick={() => setCategory(!category)}
                />
              </div>
              <div className="purchase-entry-masters__grid-trio">
                <Label>Unit of Measurement</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  options={UOM.umResponseType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setUmId(newValue as responseType);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    } else if (e.key === Keys.BACKSPACE) {
                      setUmId(null);
                    }
                  }}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option as responseType, umId)
                  }
                  value={umId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
                <img
                  className="data-fetch-icon"
                  src={DataFetch}
                  alt=""
                  onClick={() => setUnitOfMeasurement(!unitOfMeasurement)}
                />
              </div>
              <div className="purchase-entry-masters__grid-trio">
                <Label>Item Type</Label>
                <Input
                  required
                  onChange={(e) => setItemType(e.target.value)}
                  value={itemType}
                />
                {/* <Autocomplete
                  className={formClasses.inputRoot}
                  options={["a"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      className={}
                    />
                  )}
                /> */}
                {/* <img
                  className="data-fetch-icon"
                  src={DataFetch}
                  alt=""
                  onClick={() => setType(!type)}
                /> */}
              </div>
            </div>
          </div>
          <div className="col purchase-entry-masters__datablock--frame-right">
            <div className="row g-0 purchase-entry-masters__datablock--title">
              <div className="col-3"></div>
              <div className="col">
                <Title variant="subtitle1">List of Item Details</Title>
              </div>
              <div className="col-3">
                <Input id="search" placeholder="Search..." />
              </div>
            </div>
            <div className="purchase-entry-masters__tableblock">
              <TableContainer className="purchase-entry-masters__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.PurchaseEntry.Details.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index}>{th.labelName}</TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Items.data?.GetAcctStockItems.edges.map((edge, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            id="td-center"
                            className="purchase-entry-masters__table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell>{edge.node.item_desc}</TableCell>
                          <TableCell>
                            {edge.node.item_group_details.item_grp_desc}
                          </TableCell>
                          <TableCell>
                            {edge.node.item_category_details.item_category_desc}
                          </TableCell>
                          <TableCell>
                            {edge.node.item_uom_details.item_uom_desc}
                          </TableCell>
                          <TableCell>{edge.node.item_type}</TableCell>
                          <TableCell
                            className="purchase-entry-masters__table--actions"
                            id="td-center"
                          >
                            <img src={Edit} alt="" />
                            <img src={Delete} alt="" />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Button mode="save" />
        <Button mode="clear" />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </form>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={group}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Group pageType={PageFor.MODAL} setModalFlag={setGroup} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setGroup(!group)} />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={category}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Category pageType={PageFor.MODAL} setModalFlag={setCategory} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setCategory(!category)} />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={type}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Type pageType={PageFor.MODAL} setModalFlag={setType} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setType(!type)} />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={unitOfMeasurement}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <UnitOfMeasurement
              pageType={PageFor.MODAL}
              setModalFlag={setUnitOfMeasurement}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setUnitOfMeasurement(!unitOfMeasurement)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <LoadingModal flag={creationLoading} />
    </>
  );
};

export default Index;

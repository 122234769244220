import { gql } from "@apollo/client";

export const GetPayRollCategoriesByInstId = gql`
  query GetPayRollCategoriesByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: PayRollCategoryOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
  ) {
    GetPayRollCategoriesByInstId(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ categoryDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          category_desc
          category_idx
        }
        cursor
      }
    }
  }
`;

export const GetPayRollCategoryIdsByNames = gql`
  query GetPayRollCategoryIdsByNames(
    $token: String!
    $inst_id: ID!
    $category_desc: [String!]
  ) {
    GetPayRollCategoryIdsByNames(
      token: $token
      inst_id: $inst_id
      category_desc: $category_desc
    ) {
      id
      category_desc
      category_idx
    }
  }
`;

export const PayRollCategoryNode = gql`
  query ($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on PayRollCategory {
        category_desc
      }
    }
  }
`;

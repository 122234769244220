import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../components/common/Input/Index";
import { LabelNameProps } from "../../../../Types/Labels";
import { HRTitleProps } from "../../../../Types/Titles";
import { Operation } from "../../../../utils/Enum.types";
import EmployeeDetails from "./EmployeeDetails";
import Home from "../../Home/Index";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { EmpOtherDetailsSchema } from "../../../../utils/payrole";
import { useMutation } from "@apollo/client";
import LoadingModal from "../../../../pages/LoadingModal";
import useToken from "../../../../customhooks/useToken";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import { UpdatePayRollEmpOtherDetails } from "../../queries/employee/mutation";
import { EmpOtherDetailsFormType } from "../../Types/formTypes";
import { stepHeader } from "../../constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRTitles } = require("../../json/title.json");
const { HRFormLabels } = require("../../json/form.json");
interface props {
  operation: Operation;
  step: number;
}

const OtherDetails = ({ operation, step }: props) => {
  const stepsHeader = stepHeader();
  const navigate = useNavigate();
  const { InstId, empId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { empolyeeData } = useEmpDetailsById();
  const [formData, setFormData] = useState<EmpOtherDetailsFormType>({
    emp_aadhar: "",
    emp_bank_acct_no: "",
    emp_bank_ifsc: "",
    emp_bank_name: "",
    emp_biometric_id: "",
    emp_biometric_machine_id: "",
    emp_drv_lic: "",
    emp_epf_no: "",
    emp_esi_dispen: "",
    emp_esi_no: "",
    emp_pan: "",
    emp_passport: "",
    emp_uan_no: "",
    emp_voter_id: "",
  });
  const [UpdateEmpOtherData, { loading }] = useMutation(
    UpdatePayRollEmpOtherDetails
  );

  const handleSubmit = () => {
    UpdateEmpOtherData({
      variables: {
        token,
        id: empId,
        inst_id: InstId,
        user_details,
        input: formData,
      },
    }).then(({ data }) => {
      if (data) {
        if (operation === Operation.CREATE) {
          navigate(`/${InstId}/hr/employee/5/${empId}/empUploadDocument`);
        } else {
          navigate(`/${InstId}/hr/employee/${empId}/preview/otherdetails`);
        }
      }
    });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  useEffect(() => {
    if (empolyeeData.data && !empolyeeData.loading) {
      const { other_details } = empolyeeData.data.node;
      setFormData({
        emp_aadhar: other_details.emp_aadhar,
        emp_bank_acct_no: other_details.emp_bank_acct_no,
        emp_bank_ifsc: other_details.emp_bank_ifsc,
        emp_bank_name: other_details.emp_bank_name,
        emp_biometric_id: other_details.emp_biometric_id,
        emp_biometric_machine_id: other_details.emp_biometric_machine_id,
        emp_drv_lic: other_details.emp_drv_lic,
        emp_epf_no: other_details.emp_epf_no,
        emp_esi_dispen: other_details.emp_esi_dispen,
        emp_esi_no: other_details.emp_esi_no,
        emp_pan: other_details.emp_pan,
        emp_passport: other_details.emp_passport,
        emp_uan_no: other_details.emp_uan_no,
        emp_voter_id: other_details.emp_voter_id,
      });
    }
  }, [empolyeeData.data, empolyeeData.loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="employee-details">
        <Stepper alternativeLabel activeStep={step - 1}>
          {stepsHeader.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <EmployeeDetails />

        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={EmpOtherDetailsSchema}
          onSubmit={handleSubmit}
        >
          {(meta) => {
            return (
              <Form className="employee-details__basic-details--frame">
                <div className="employee-details__basic-details--block">
                  <div className="details">
                    <h4>
                      {HRTitles.Employee_Registration.Other_Details.map(
                        (title: HRTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </h4>
                  </div>

                  <div className="employee-details__basic-details--block--frame">
                    {HRFormLabels.Employee.Other_Details.InputType.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              type={label.dataType}
                              required={label.required ? true : false}
                              maxLength={label.maxLength}
                              autoFocus={label.autoFocus}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <Button mode="save-continue" type="submit" />
                <Button mode="clear" type="button" />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    if (operation === Operation.CREATE) {
                      navigate(
                        `/${InstId}/hr/employee/2/${empId}/personaldetails`
                      );
                    } else {
                      navigate(
                        `/${InstId}/hr/employee/${empId}/preview/otherdetails`
                      );
                    }
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>

      <LoadingModal flag={loading} />
    </>
  );
};

export default OtherDetails;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UseSelect from "../../../../customhooks/UseSelect";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pointer from "../../../../images/Pointer.svg";

import Input from "../../../../stories/Input/Input";
import { EMPTY_STRING } from "../../../../utils/constants";
const List = () => {
  const [selectedReportType, setSelectedReportType] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { InstId } = useParams();
  const navigate = useNavigate();

  const reportTypes = [
    {
      value: 1,
      label: "Payment Register",
      url: `/${InstId}/accounts/reports/paymentvoucherregister`,
    },
    {
      value: 2,
      label: "General Receipt Voucher Register",

      url: `/${InstId}/accounts/reports/generalreceiptvoucherregister`,
    },
  ];

  const filteredReportTypes = UseSelect(reportTypes, searchText);
  return (
    <>
      <TableContainer className="payment-general-register__tableblock--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Report Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="payment-general-register__tableblock--table--input">
                <Input
                  size="small"
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </TableCell>
            </TableRow>
            {filteredReportTypes?.map((data, index) => {
              return (
                <TableRow
                  key={data.value}
                  className={
                    selectedReportType === data.value
                      ? "selected-row"
                      : EMPTY_STRING
                  }
                >
                  <TableCell
                    onClick={() => {
                      navigate(data.url!);
                      setSelectedReportType(data.value!);
                    }}
                  >
                    {selectedReportType === data.value ? (
                      <img src={Pointer} alt="/" className="pointer-icon" />
                    ) : (
                      EMPTY_STRING
                    )}
                    {data.label}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;

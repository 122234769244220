import { useMutation } from "@apollo/client";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useToken from "../../../../customhooks/useToken";
import ArrowGreen from "../../../../images/ArrowGreen.svg";
import ArrowRed from "../../../../images/ArrowRed.svg";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";

import { TableHeaderProps } from "../../../../Types/Tables";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import {
  Direction,
  MoveDirection,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import { msgType } from "../../../../utils/Form.types";
import { Actions } from "../../constants";

import useLeaveLedgersByInstId from "../../hooks/useLeaveLedgersByInstId";
import { PayRollLeaveLedgerNode } from "../../Types/masterDataTypes";
import usePredefindedLeaveLedgers from "../../hooks/usePredefinedLeaveLedgers";
import { AddOrDeletePayRollPredefinedLeaveLdgr } from "../../queries/leaveledger/mutation";
import { GetPayRollLeaveLdgrsByInstId } from "../../queries/leaveledger/list";
import { HRLeaveLedgerTitleProps } from "../../../../Types/Titles";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  type: MoveDirection;
}

interface LeaveLedgers extends PayRollLeaveLedgerNode {
  isChecked: boolean;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const PredefinedLeaveLedger = ({ setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [eduateLedgers, setEduateLedgers] = useState<LeaveLedgers[]>([]);
  const [hrLedgers, setHrLedgers] = useState<LeaveLedgers[]>([]);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const {
    leaveLedgers: { data: hrLeaveLedgerData, loading: hrLeaveLedgerLoading },
  } = useLeaveLedgersByInstId(EMPTY_STRING, 15);

  const instSalaryLedgerSet = new Set(
    hrLeaveLedgerData?.GetPayRollLeaveLdgrsByInstId.edges
      ?.filter(({ node }) => node.leave_is_system_defined)
      .map(({ node }) => node.leave_desc)
  );

  const [AddHRLeave, { loading: creationLoading }] = useMutation(
    AddOrDeletePayRollPredefinedLeaveLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    predefinedSalaryledger: { data, loading, error },
  } = usePredefindedLeaveLedgers();
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount, type } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            className="add-predefined-leave-ledger__table--checkbox"
          >
            {type === MoveDirection.RIGHT ? (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  eduateLedgers?.filter((data) => data.isChecked).length > 0
                }
              />
            ) : (
              <Checkbox
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  hrLedgers?.filter((data) => data.isChecked).length > 0
                }
              />
            )}
          </TableCell>

          {HR_Table.MasterData.Table_Headers.PredefinedLeaveLedgerList.filter(
            (head: TableHeaderProps) => head.labelName !== Actions
          ).map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={th.className}>
                {th.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  const handleClick = (
    event: React.MouseEvent<unknown>,
    data: LeaveLedgers,
    direction: MoveDirection
  ) => {
    const { checked } = event.target as HTMLInputElement;
    switch (direction) {
      case MoveDirection.LEFT:
        const mappedDataLeft = eduateLedgers?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setEduateLedgers(mappedDataLeft);
        break;
      case MoveDirection.RIGHT:
        const mappedDataRight = hrLedgers?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setHrLedgers(mappedDataRight);
        break;
      default:
        break;
    }
  };
  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    direction: MoveDirection
  ) => {
    switch (direction) {
      case MoveDirection.LEFT:
        setEduateLedgers(
          eduateLedgers.map((ledger) => ({
            ...ledger,
            isChecked: event.target.checked ? true : false,
          }))
        );
        break;
      case MoveDirection.RIGHT:
        setHrLedgers(
          hrLedgers.map((ledger) => ({
            ...ledger,
            isChecked: event.target.checked ? true : false,
          }))
        );
        break;
      default:
        break;
    }
  };

  //Implemented using chatGPT
  const moveBooks = (direction: MoveDirection): void => {
    let sourceLedgers: LeaveLedgers[], destinationLedgers: LeaveLedgers[];

    switch (direction) {
      case MoveDirection.RIGHT:
        sourceLedgers = eduateLedgers;
        destinationLedgers = eduateLedgers.filter(
          (data: LeaveLedgers) => !data.isChecked
        );
        setEduateLedgers(destinationLedgers);
        const selectedLedgers = sourceLedgers.filter(
          (data: LeaveLedgers) => data.isChecked
        );
        selectedLedgers.forEach((ledger: LeaveLedgers) => {
          ledger.isChecked = false;
        });
        setHrLedgers([...hrLedgers, ...selectedLedgers]);

        break;
      case MoveDirection.LEFT:
        sourceLedgers = hrLedgers;
        destinationLedgers = hrLedgers.filter(
          (data: LeaveLedgers) => !data.isChecked
        );
        setHrLedgers(destinationLedgers);
        const selectedLedgers2 = sourceLedgers.filter(
          (data: LeaveLedgers) => data.isChecked
        );
        selectedLedgers2.forEach((ledger: LeaveLedgers) => {
          ledger.isChecked = false;
        });
        setEduateLedgers([...eduateLedgers, ...selectedLedgers2]);
        break;
      default:
        return;
    }
  };

  const handleSubmit = () => {
    AddHRLeave({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: {
          add_leave_ldgrs: hrLedgers
            .filter(
              ({ leave_desc }) => instSalaryLedgerSet.has(leave_desc) === false
            )
            .map((ledger) => ({
              leave_desc: ledger.leave_desc,
              leave_type: ledger.leave_type,
              leave_short_code: ledger.leave_short_code,
              leave_only_for_women: ledger.leave_only_for_women,
              leave_is_credited: ledger.leave_is_credited,
              leave_carry_forwarded: ledger.leave_carry_forwarded,
              leave_for_salary_not_used: ledger.leave_for_salary_not_used,
              leave_is_earned: ledger.leave_is_earned,
              leave_is_system_defined: ledger.leave_is_system_defined,
            })),
          delete_leave_ldgr_ids: eduateLedgers
            .filter(({ leave_desc }) => instSalaryLedgerSet.has(leave_desc))
            .map(({ id }) => id),
        },
      },
      refetchQueries: [
        {
          query: GetPayRollLeaveLdgrsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.LEAVE_DESC,
            name: EMPTY_STRING,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          flag: true,
          message: "Leave Ledger added successfully",
          operation: Operation.CREATE,
        });
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModalFlag(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(
    () => {
      if (data && !loading) {
        setEduateLedgers(
          data.GetSwLeaveLdgrs.filter(
            ({ leave_desc, is_lwp_ldgr }) =>
              instSalaryLedgerSet.has(leave_desc) === false && !is_lwp_ldgr
          ).map((ledger) => ({
            ...ledger,
            isChecked: false,
          })) ?? []
        );
      }
    },
    // eslint-disable-next-line
    [data, loading]
  );

  useEffect(() => {
    if (hrLeaveLedgerData && !hrLeaveLedgerLoading) {
      setHrLedgers(
        hrLeaveLedgerData.GetPayRollLeaveLdgrsByInstId.edges
          .filter(
            ({ node }) =>
              node.leave_is_system_defined && node.is_lwp_ldgr === false
          )
          ?.map(({ node }) => ({
            ...node,
            isChecked: false,
          })) ?? []
      );
    }
  }, [hrLeaveLedgerData, hrLeaveLedgerLoading]);

  return (
    <>
      <Title>
        {HRTitles.MasterData.LeaveLedger.Titles.map(
          (title: HRLeaveLedgerTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.Predefined}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="add-predefined-leave-ledger">
        <div className="row g-0 add-predefined-leave-ledger__data">
          <div className="col booktype-left h-100">
            <Title variant="subtitle1">
              {HRTitles.MasterData.LeaveLedger.Titles.map(
                (title: HRLeaveLedgerTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.EduateLedgers}
                    </React.Fragment>
                  );
                }
              )}
            </Title>

            <div className="add-predefined-leave-ledger__tableblock">
              {error ? (
                <b className="nodata">{error.message}</b>
              ) : data?.GetSwLeaveLdgrs.length! > 0 ? (
                <TableContainer className="add-predefined-leave-ledger__table">
                  <Table stickyHeader>
                    <EnhancedTableHead
                      numSelected={
                        eduateLedgers.filter(({ isChecked }) => isChecked)
                          .length
                      }
                      onSelectAllClick={(e) => {
                        handleSelectAllClick(e, MoveDirection.LEFT);
                      }}
                      rowCount={eduateLedgers.length}
                      type={MoveDirection.LEFT}
                    />
                    <TableBody>
                      {eduateLedgers.map((response, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            key={response.id}
                            hover
                            role="checkbox"
                            aria-checked={response.isChecked}
                            tabIndex={-1}
                            selected={response?.isChecked}
                          >
                            <TableCell
                              padding="checkbox"
                              id="td-center"
                              className="add-predefined-leave-ledger__table--slno"
                            >
                              <Checkbox
                                checked={response.isChecked}
                                onClick={(event) =>
                                  handleClick(
                                    event,
                                    response,
                                    MoveDirection.LEFT
                                  )
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                disabled={
                                  hrLedgers?.filter((data) => data.isChecked)
                                    .length > 0
                                }
                              />
                            </TableCell>
                            <TableCell
                              id="td-center"
                              className="add-predefined-leave-ledger__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell>{response.leave_desc}</TableCell>
                            <TableCell className="add-predefined-leave-ledger__table--desc">
                              {response.leave_short_code}
                            </TableCell>
                            <TableCell className="add-predefined-leave-ledger__table--desc"></TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <b className="nodata">No data Please try adding...</b>
              )}
            </div>
          </div>
          <div className="add-predefined-leave-ledger__tableblock--swap-image">
            <div>
              <img
                src={ArrowGreen}
                alt="/"
                onClick={() => {
                  if (
                    eduateLedgers?.filter((data) => data.isChecked).length > 0
                  ) {
                    moveBooks(MoveDirection.RIGHT);
                  }
                }}
                className={
                  eduateLedgers?.filter((data) => data.isChecked).length > 0
                    ? EMPTY_STRING
                    : "opacity"
                }
              />
            </div>
            <br />
            <div>
              <img
                src={ArrowRed}
                alt="/"
                onClick={() => moveBooks(MoveDirection.LEFT)}
                className={
                  hrLedgers?.filter((data) => data.isChecked).length > 0
                    ? EMPTY_STRING
                    : "opacity"
                }
              />
            </div>
          </div>

          <div className="col booktype-right h-100">
            <Title variant="subtitle1">
              {HRTitles.MasterData.LeaveLedger.Titles.map(
                (title: HRLeaveLedgerTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {title.HRLedgers}
                    </React.Fragment>
                  );
                }
              )}
            </Title>

            <div className="add-predefined-leave-ledger__tableblock">
              {error ? (
                <b className="nodata">{error.message}</b>
              ) : hrLedgers.length! > 0 ? (
                <>
                  <TableContainer className="add-predefined-leave-ledger__table">
                    <Table stickyHeader>
                      <EnhancedTableHead
                        numSelected={
                          hrLedgers.filter(({ isChecked }) => isChecked).length
                        }
                        onSelectAllClick={(e) =>
                          handleSelectAllClick(e, MoveDirection.RIGHT)
                        }
                        rowCount={hrLedgers.length}
                        type={MoveDirection.RIGHT}
                      />
                      <TableBody>
                        {hrLedgers.map((response, index: number) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              key={response.id}
                              hover
                              role="checkbox"
                              aria-checked={response.isChecked}
                              tabIndex={-1}
                              selected={response?.isChecked}
                            >
                              <TableCell
                                padding="checkbox"
                                id="td-center"
                                className="add-predefined-leave-ledger__table--slno"
                              >
                                <Checkbox
                                  checked={response.isChecked}
                                  onClick={(event) =>
                                    handleClick(
                                      event,
                                      response,
                                      MoveDirection.RIGHT
                                    )
                                  }
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  disabled={
                                    eduateLedgers?.filter(
                                      (data) => data.isChecked
                                    ).length > 0
                                  }
                                />
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="add-predefined-leave-ledger__table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell>{response.leave_desc}</TableCell>
                              <TableCell className="add-predefined-leave-ledger__table--desc">
                                {response.leave_short_code}
                              </TableCell>
                              <TableCell className="add-predefined-leave-ledger__table--desc">
                                {response.leave_type}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <b className="nodata">No data Please try adding...</b>
              )}
            </div>
          </div>
        </div>

        <Button
          mode="save"
          onClick={handleSubmit}
          disabled={
            hrLedgers.filter(({ isChecked }) => isChecked).length > 0 ||
            eduateLedgers.filter(({ isChecked }) => isChecked).length > 0
          }
        />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>

      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag}
        handleClose={handleClose}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default PredefinedLeaveLedger;

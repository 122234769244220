import { forwardRef, useContext, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../stories/Button/Button";
import useStudentDatabyId, {
  StudentDetails,
} from "../../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../../context/context";
import Template1 from "./Template1";
import useChallanConfiguration from "./hooks/useChallanConfiguration";
import { BankDetails } from "../../../Accounts/StudentDetails/Challan/CreateChallan";
import usePassedOutStudent from "../../../../customhooks/usePassedOutStudent";
import { NonDemand } from "../../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";

interface Data {
  particular: string;
  amount: number;
}
export interface PrintReceiptProps {
  tableData: Data[];
  v_date: string;
  v_no: string;
  transaction_no:string;
  studentDetails?: StudentDetails | null;
  printBoth?: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  bankDetailsForPrint: BankDetails;
  handleClear?: () => void;
  demand?: NonDemand;
}
const ComponentToPrint = forwardRef<HTMLDivElement, PrintReceiptProps>(
  ({ tableData, v_date, v_no,transaction_no, studentDetails, bankDetailsForPrint }, ref) => {
    return (
      <>
        <style type="text/css" media="print" scoped>
          {`
            
            @page {
              size:A4 landscape,
              margin:0,
          width:100%;
          height:100%;
            }
            body {
              padding: 10px 10px 0px 20px;
            }
  

          `}
        </style>
        <div className="h-30" ref={ref}>
          <Template1
            tableData={tableData}
            v_date={v_date}
            v_no={v_no}
            studentDetails={studentDetails}
            bankDetailsForPrint={bankDetailsForPrint}
            transaction_no={transaction_no}
          />
        </div>
      </>
    );
  }
);
const ChallanPrint = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
  setModal,
  bankDetailsForPrint,
  handleClear,
  demand,
}: PrintReceiptProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { studentFormData } = useStudentDatabyId();
  const { passedOutStudentFormData } = usePassedOutStudent();

  const { state } = useContext(AppContext);
  // State to store the number of copies to print
  const { USE_NO_COPIES, USE_LABEL } = useChallanConfiguration();

  return (
    <div className="challan-receipts">
      <div ref={componentRef} className="challan-receipts__ss">
        {state.claims && state.claims.STUDENT ? (
          <div className="challan-receipts__dd">
            <ComponentToPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              studentDetails={studentDetails}
              setModal={setModal}
              bankDetailsForPrint={bankDetailsForPrint}
              handleClear={() => {}}
              demand={demand}
              transaction_no={transaction_no}
            />
            {USE_LABEL && (
              <>
                <b className="print-receipts__standard--footer--copy">
                  Student Copy
                </b>
              </>
            )}
          </div>
        ) : (
          [...Array(USE_NO_COPIES)].map((_, index) => (
            <div className="challan-receipts__dd" key={index}>
              {USE_LABEL && (
                <>
                  {index === 0 ? (
                    <b className="print-receipts__standard--footer--copy">
                      Bank Copy
                    </b>
                  ) : index === 1 ? (
                    <b className="print-receipts__standard--footer--copy">
                      Student Copy
                    </b>
                  ) : (
                    <b className="print-receipts__standard--footer--copy">
                      Institution Copy
                    </b>
                  )}
                </>
              )}
              <ComponentToPrint
                tableData={tableData}
                v_date={v_date}
                v_no={v_no}
                studentDetails={studentDetails}
                setModal={setModal}
                bankDetailsForPrint={bankDetailsForPrint}
                handleClear={() => {}}
                demand={demand}
                transaction_no={transaction_no}
              />
            </div>
          ))
        )}
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
        documentTitle={
          demand === NonDemand.ROLE
            ? studentFormData.std_name
            : demand === NonDemand.NON_ROLE
            ? passedOutStudentFormData.std_name
            : EMPTY_STRING
        }
      />
      <Button
        mode="cancel"
        onClick={() => {
          handleClear?.();
          setModal(false);
        }}
      />
    </div>
  );
};

export default ChallanPrint;

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { responseType } from "../../../utils/Form.types";
import { toIsoDate, toStandardDate } from "../../../utils/UtilFunctions";
import { GetPayRollGeneralHolidays } from "../queries/holidays/query";
import {
  GetPayRollGeneralHolidaysData,
  GetPayRollGeneralHolidaysVars,
} from "../Types/paginationTypes";

export enum HolidayQueryType {
  WEEKEND_HOLIDAYS = "WEEKEND_HOLIDAYS",
  GENERAL_AND_UNEXPECTED_HOLIDAYS = "GENERAL_AND_UNEXPECTED_HOLIDAYS",
  GENERAL_HOLIDAYS = "GENERAL_HOLIDAYS",
  UNEXPECTED_HOLIDAYS = "UNEXPECTED_HOLIDAYS",
  VACATION_HOLIDAYS = "VACATION_HOLIDAYS",
  ALL_HOLIDAYS = "ALL_HOLIDAYS",
  HOLIDAY_BY_DATE = "HOLIDAY_BY_DATE",
  ALL_HOLIDAYS_IN_MONTH = "ALL_HOLIDAYS_IN_MONTH",
  MONTHLY = "MONTHLY",
}
const usePayRollGeneralHolidays = (
  acdYr: number,
  end_date: string,
  start_date: string,
  searchText: string,
  queryType: HolidayQueryType | string,
  in_between_flag: boolean
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [GetPayRollHolidays, { data, loading, error }] = useLazyQuery<
    GetPayRollGeneralHolidaysData,
    GetPayRollGeneralHolidaysVars
  >(GetPayRollGeneralHolidays, {
    variables: {
      pr_acd_yr_id: acdYr,
      input: {
        end_date: toIsoDate(end_date),
        in_between_flag,
        start_date: toIsoDate(start_date),
        holiday_query_type: queryType,
      },
      inst_id: InstId!,
      token,
      holidayDesc: searchText,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (acdYr && token) {
      GetPayRollHolidays().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetPayRollGeneralHolidays.edges.map((edge) => ({
              label: `${edge.node.holiday_desc} ${toStandardDate(
                edge.node.holiday_date
              )}`,
              value: edge.node.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [acdYr, GetPayRollHolidays, token, searchText]);
  return { PayRollGeneralHolidays: { data, loading, error, responseType } };
};

export default usePayRollGeneralHolidays;

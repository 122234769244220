import { gql } from "@apollo/client";

export const ListInstBranchIdByNames = gql`
  query GetBranchIdsByNames(
    $branch_names: [String!]
    $token: String!
    $inst_id: ID!
  ) {
    GetBranchIdsByNames(
      token: $token
      branch_names: $branch_names
      inst_id: $inst_id
    ) {
      branch_desc
      id
    }
  }
`;

export const BranchIdByName = gql`
  query GetBranchIdByName(
    $inst_id: ID!
    $dept_id: ID!
    $branch_name: String!
    $token: String!
  ) {
    GetBranchIdByName(
      token: $token
      inst_id: $inst_id
      dept_id: $dept_id
      branch_name: $branch_name
    )
  }
`;

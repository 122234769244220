import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import Avatar from "../../../../images/Avatar.svg";
import Messenger from "../../../../images/SendMessage.svg";
import BirthdayCake from "../../../../images/Birthday_Cake.svg";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../../utils/constants";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../images/CalenderNextButton.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import RightArrow from "../../../../images/ArrowRight.svg";
import Input from "../../../../stories/Input/Input";
import Close from "../../../../images/Close.svg";

import { AppContext } from "../../../../context/context";
import { PageFor } from "../../../../utils/Enum.types";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import useStudentDataToGetBirthday from "../../../../customhooks/useStudentDataToGetBirthday";
import Modal from "react-modal";
import { payloadTypes } from "../../../../context/reducer";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import MessageListModal from "../../../Channel/MessageList";
import { StudentEdges } from "../../hooks/useAcdStudentsData";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const MoreBirthdayList = ({ setModal }: Props) => {
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const [messageModal, setMessageModal] = useState(false);

  const { state, dispatch } = useContext(AppContext);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const { data: dataToday } = useStudentDataToGetBirthday(
    StudentAcdType.CUR_STD_HAS_DOB_TODAY
  );
  const { data: dataWeek } = useStudentDataToGetBirthday(
    StudentAcdType.CUR_STD_HAS_DOB_IN_WEEK
  );
  const [groupedStudents, setGroupedStudents] = useState<{
    [date: string]: StudentEdges[];
  }>({});

  const groupStudentsByBirthdate = (students: StudentEdges[]) => {
    const grouped: { [date: string]: StudentEdges[] } = {};

    students.forEach((student) => {
      const birthdate = new Date(student.node.std_dob);
      const month = birthdate.getMonth();
      const date = birthdate.getDate();
      const currentYear = new Date().getFullYear();

      const formattedDate = new Date(
        currentYear,
        month,
        date
      ).toLocaleDateString("en-GB"); // Format as DD/MM/YYYY

      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(student);
    });

    return grouped;
  };

  useEffect(() => {
    if (dataWeek && dataWeek.GetAcdStudents) {
      const grouped = groupStudentsByBirthdate(dataWeek.GetAcdStudents.edges);
      setGroupedStudents(grouped);
    }
  }, [dataWeek]);

  return (
    <>
      <div className="more-birthday-list">
        <div className="more-birthday-list__title">
          <Title>Birthday Details</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="more-birthday-list__data row g-0">
          <div className="more-birthday-list__data--left col">
            <Title variant="subtitle1">Today's Birthday's</Title>
            <div className="more-birthday-list__data--left--gif">
              <img src={BirthdayCake} alt="" />
            </div>
            <div className="birthday-list__data--subtitle">
              <img src={Previous} alt="" />
              <b>{toStandardDate(TODAY_DATE)}</b>
              <img src={Next} alt="" />
            </div>
            <div className="birthday-list__data--total">
              Total Birthday's
              <span className="birthday-list__data--total--count">
                {dataToday ? dataToday.GetAcdStudents.totalCount : 0}
              </span>
            </div>
            <div className="more-birthday-list__data--left--list">
              <ul>
                {dataToday ? (
                  dataToday.GetAcdStudents.edges.map((res, index) => {
                    return (
                      <li key={index}>
                        <div className="birthday-list__data--list--flex">
                          <span>{index + 1}.</span>
                          {/* <img src={res.image} alt="" /> */}
                          <div>
                            <b>
                              {res.node.first_name +
                                res.node.middle_name +
                                res.node.last_name}
                            </b>
                            <span>{toStandardDate(res.node.std_dob)}</span>
                          </div>
                        </div>
                        <img
                          src={Messenger}
                          alt=""
                          onClick={() => {
                            dispatch({
                              type: payloadTypes.SET_STUDENT_ID,
                              payload: { studentId: res.node.id },
                            });
                            setMessageModal(!messageModal);
                          }}
                        />
                      </li>
                    );
                  })
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
          <div className="more-birthday-list__data--right col">
            <Title variant="subtitle1">Upcoming Birthday's</Title>
            <div className="more-birthday-list__data--right--search row g-0 justify-content-center">
              <div className="col-4">
                <Input id="search" placeholder="Search" />
              </div>
            </div>
            <ul className="more-birthday-list__data--right--list">
              {Object.entries(groupedStudents).map(
                ([date, students], index) => {
                  const isExpanded = expandedIndex === index;

                  return (
                    <React.Fragment key={index}>
                      <li className="birthday-list__data--total">
                        {date}
                        <div className="birthday-list__data--total--image">
                          <span className="birthday-list__data--total--count">
                            {students.length}
                          </span>

                          {isExpanded ? (
                            <img
                              src={DownArrow}
                              alt=""
                              onClick={() => handleToggle(index)}
                            />
                          ) : (
                            <img
                              src={RightArrow}
                              alt=""
                              onClick={() => handleToggle(index)}
                            />
                          )}
                        </div>
                      </li>
                      {!isExpanded ? (
                        <>
                          {students.map((li, index) => {
                            return (
                              <li
                                key={index}
                                className="more-birthday-list__data--right--li"
                              >
                                <div className="birthday-list__data--list--flex">
                                  <span>{index + 1}.</span>
                                  {/* <img src={li.image} alt="" /> */}
                                  <div>
                                    <b>
                                      {li.node.first_name +
                                        li.node.middle_name +
                                        li.node.last_name}
                                    </b>
                                    <span>
                                      DOB :{" "}
                                      <b className="font-blue">
                                        {toStandardDate(li.node.std_dob)}
                                      </b>
                                    </span>
                                    <div className="more-birthday-list__data--right--flex">
                                     {USE_DEPARTMENT_KEY && (
                                        <>
                                          <span>
                                            {li.node.acd_dept.dept_desc}/
                                          </span>
                                        </>
                                      )}
                                      {USE_BRANCH_KEY && (
                                        <>
                                          <span>
                                            {li.node.acd_branch.branch_desc}/
                                          </span>
                                        </>
                                      )}

                                      {USE_CLASS_KEY && (
                                        <>
                                          <span>
                                            {li.node.acd_class.class_short_desc}
                                            /
                                          </span>
                                        </>
                                      )}
                                      {USE_SEMESTER_KEY && (
                                        <>
                                          <span>
                                            {li.node.acd_semester.sem_desc}/
                                          </span>
                                        </>
                                      )}

                                      {USE_SECTION_KEY && (
                                        <>
                                          <span>
                                            {
                                              li.node.acd_section
                                                .section_short_desc
                                            }
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Messenger}
                                  alt=""
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_STUDENT_ID,
                                      payload: { studentId: li.node.id },
                                    });
                                    setMessageModal(!messageModal);
                                  }}
                                />
                              </li>
                            );
                          })}
                        </>
                      ) : null}
                    </React.Fragment>
                  );
                }
              )}
            </ul>
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MoreBirthdayList;

import React, { useContext, useEffect, useState } from "react";
import { GetAcdSubjectCategories } from "../../../../queries/customallocation/categories/queries";
import {
  AcdSubjectCategoryDetails,
  GetAcdSubjectCategoriesData,
  GetAcdSubjectCategoriesVars,
} from "../../../../Types/Category/queries";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import { Direction, SortBy } from "../../../../utils/Enum.types";
import { responseType } from "../../../../utils/Form.types";
export interface categoryType extends responseType {
  subj_cat_details: AcdSubjectCategoryDetails[];
}
const useCategories = (rowsPerPage: number, searchData: string) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [categories, setCategories] = useState<categoryType[]>([]);

  const [GetAcdSubject, { data, loading, error, fetchMore }] = useLazyQuery<
    GetAcdSubjectCategoriesData,
    GetAcdSubjectCategoriesVars
  >(GetAcdSubjectCategories, {
    variables: {
      token,
      after: null,
      first: rowsPerPage,
      inst_id: InstId!,
      name: searchData,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.SUBJ_CAT_NAME,
      },
    },
  });

  useEffect(() => {
    if (token && state.ActiveAcdYr) {
      GetAcdSubject().then(({ data }) => {
        if (data && data.GetAcdSubjectCategories) {
          setCategories(
            data.GetAcdSubjectCategories.edges.map(({ node }) => ({
              label: node.subj_cat_name,
              value: node.id,
              subj_cat_details: node.subj_cat_details,
            }))
          );
        }
      });
    }
  }, [token, state, GetAcdSubject, data]);

  return { data, loading, error, fetchMore, categories };
};

export default useCategories;

import React, { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import Modal from "react-modal";
import View from "./View";
import EditAssociated from "./Add";
import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import {
  AcdSubjectCategoryDetails,
  AcdSubjectCategoryEdge,
} from "../../../../Types/Category/queries";
import { GetAcdSubjectCategories } from "../../../../queries/customallocation/categories/queries";
import {
  DELETE,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../utils/constants";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  AccountingLedgerModalStyles,
  StudentModalStyles,
  ViewPickedSubjectModalStyles,
} from "../../../../styles/ModalStyles";
import { DeleteAcdSubjectCategory } from "../../../../queries/customallocation/categories/mutations";
import {
  DeleteAcdSubjectCategoryData,
  DeleteAcdSubjectCategoryVars,
} from "../../../../Types/Category/mutations";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import MessageModal from "../../../../pages/MessageModal";
import useCategories from "../customHooks/useCategories";

const { Academics_Table } = require("../../json/table.json");

const Index = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [searchData, setSearchData] = useState("");
  const [endCursor, setEndCursor] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [categories, setCategories] = useState<AcdSubjectCategoryEdge[]>([]);
  const [subjects, setSubjects] = useState<AcdSubjectCategoryDetails[]>([]);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteId, setDeleteId] = useState(0);

  const [message, setMessage] = useState(emptyMessageType);

  const [inputValue, setInputValue] = useState("");

  const { user_details } = useLoggedInUserDetails();
  const { data, loading, fetchMore, error } = useCategories(
    ROWS_PER_PAGE,
    searchData
  );

  const [DeleteCategory] = useMutation<
    DeleteAcdSubjectCategoryData,
    DeleteAcdSubjectCategoryVars
  >(DeleteAcdSubjectCategory, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdSubjectCategories.edges;
            const pageInfo = fetchMoreResult.GetAcdSubjectCategories.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetAcdSubjectCategories.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdSubjectCategories: {
                edges: [...categories, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcdSubjectCategories.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdSubjectCategories.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newCategory) => {
          const filteredStudent = categories.find(
            (category) => category.node.id === newCategory.node.id
          );
          if (filteredStudent) {
            return {
              ...newCategory,
              node: {
                ...newCategory.node,
              },
            };
          }
          return newCategory;
        });
        setCategories(updatedNewData);
      } else {
        setCategories(newData);
      }
      setHasNextPage(data.GetAcdSubjectCategories.pageInfo.hasNextPage);
      setEndCursor(data.GetAcdSubjectCategories.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const handleDelete = (id: number) => {
    DeleteCategory({
      variables: {
        id,
        inst_id: InstId!,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdSubjectCategories,
          variables: {
            token,
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: EMPTY_STRING,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.SUBJ_CAT_NAME,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteAcdSubjectCategory) {
        setMessage({
          flag: true,
          message: `Group deleted successfully`,
          operation: Operation.NONE,
        });
      }
    });
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Associate Subjects to Category</Title>
      <div className="ass-catg">
        <div className="ass-catg__filters row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search.."
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
            />
          </div>
          <div className="col"></div>
          <div className="col-2 ass-catg__filters--button">
            <Button
              mode="addnew"
              autoFocus
              onClick={() =>
                navigate(
                  `/${InstId}/academics/customallocation/category/associatesub`
                )
              }
            />
          </div>
        </div>
        <div className="ass-catg__tableblock">
          {error ? (
            <b className="nodata">{error.message}</b>
          ) : categories.length > 0 ? (
            <TableContainer className="ass-catg__table" onScroll={handleScroll}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Academics_Table.CustomAllocation.Categories.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((res, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="ass-catg__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="ass-catg__table--name"
                          onClick={() => {
                            setSubjects(res.node.subj_cat_details);
                            setView(!view);
                          }}
                        >
                          {res.node.subj_cat_name}
                        </TableCell>
                        <TableCell>
                          <ul className="ass-catg__table--ul">
                            {res.node.subj_cat_details.map((sub, index) => {
                              return (
                                <li key={index}>
                                  {index + 1}.{" "}
                                  {`${sub.subject_details.subj_desc}`}
                                  <span className="font-grey">({sub.subject_details.subj_code})</span>
                                </li>
                              );
                            })}
                          </ul>
                        </TableCell>
                        <TableCell
                          className="ass-catg__table--actions"
                          id="td-center"
                        >
                          <img
                            src={Edit}
                            alt=""
                            onClick={() => setEdit(!edit)}
                          />
                          <img
                            src={Delete}
                            alt=""
                            onClick={() => {
                              setSubjects(res.node.subj_cat_details);
                              setDeleteId(res.node.id);
                              setDeleteModal(!deleteModal);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <b className="nodata">Categories not found</b>
          )}
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        isOpen={view}
        ariaHideApp={false}
        style={ViewPickedSubjectModalStyles}
      >
        <View setModal={setView} operation={Operation.VIEW} data={subjects} />
      </Modal>
      <Modal isOpen={edit} ariaHideApp={false} style={StudentModalStyles}>
        <EditAssociated
          pageType={PageFor.MODAL}
          operation={Operation.UPDATE}
          setModal={setEdit}
        />
      </Modal>
      <Modal
        isOpen={deleteModal}
        ariaHideApp={false}
        style={AccountingLedgerModalStyles}
      >
  <div className="delete-groups">
  <View
          setModal={setDeleteModal}
          operation={Operation.DELETE}
          data={subjects}
        />
        <span>Do you really want to delete these records?</span>
        {/*Shiva:  br tag to be removed after css */}
        <br />
        <span>
          Please enter <b className="nodata">'DELETE'</b> to confirm the
          deletion.
        </span>
        <Input
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
          }}
        />
        <Button
          autoFocus
          disabled={inputValue !== DELETE}
          onClick={() => {
            handleDelete(deleteId);
            setInputValue("");
          }}
          mode="delete"
        />
        <Button
          onClick={() => {
            setInputValue("");
            setDeleteModal(!deleteModal);
          }}
          mode="cancel"
        />
  </div>

      </Modal>
      <MessageModal
        handleClose={() => {
          setMessage(emptyMessageType);
          setSubjects([]);
          setDeleteId(0);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import TransactionFailed from "../../../images/TransactionFailed.svg";
import AllError from "../../../images/AllTypesofErrors.svg";

import { Button } from "../../../stories/Button/Button";
import { TransactionStatusType } from "../../../utils/Enum.types";
import useTransactionDetailsById from "../../../customhooks/useTransactionDetailsById";
import TransactionSuccess from "../../../images/TransactionSuccess.svg";
import { optionsType, responseType } from "../../../utils/Form.types";
import {
  toInputStandardDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import Modal from "react-modal";
import { PrintModalStyles } from "../../../styles/ModalStyles";
import Print from "../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import { useLazyQuery } from "@apollo/client";
import { AccountVoucherDetailsByMasterVoucherId } from "../../../queries/common";
import {
  nodevars,
  singleNodeVars,
  VouchernodeData,
} from "../../../Types/Accounting";
import useToken from "../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../utils/constants";
interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: TransactionStatusType;
}
const Failure = ({ setModalFlag, pageType }: props) => {
  const { data, loading } = useTransactionDetailsById();
  const [dataOptions, setDataOptions] = useState<optionsType[]>([]);
  const [printModal, setPrintModal] = useState(false);
  const { token } = useToken();

  const [GetAcctVoucher, { data: v_data }] = useLazyQuery<
    VouchernodeData,
    nodevars
  >(AccountVoucherDetailsByMasterVoucherId);
  const isSuccess = window.location.pathname.includes("success");
  const isFailure = window.location.pathname.includes("failed");

  const transactionDetails = data
    ? {
        response_code: data.node.response_code,
        str: data.node.response_code_str,
      }
    : {
        response_code: "",
        str: "",
      };

  useEffect(() => {
    
    if (data && data.node && !loading && (isSuccess || isFailure)) {
      setDataOptions([
        {
          label: "Amount",
          value: data.node.transaction_amount,
        },
        {
          label: "Date",
          value: toStandardDate(data.node.transaction_date),
        },
        {
          label: "Transaction No.",
          value: v_data && v_data.nodes.length ? v_data.nodes[0].v_transcation_no : EMPTY_STRING,
        },
        {
          label: "Transaction Type",
          value: v_data && v_data.nodes.length ? v_data.nodes[0].v_transcation_type : EMPTY_STRING,
        },
        {
          label: "Online Reference No.",
          value: data.node.reference_no,
        },
      ]);
    }
  }, [data, loading, isFailure, isSuccess, v_data]);

  useEffect(() => {
    if (token && data && data.node.voucher_master_id) {
      GetAcctVoucher({
        variables: {
          ids: [Number(data.node.voucher_master_id)],
          token,
        },
      });
    }
  }, [token, data, GetAcctVoucher]);
  return (
    <>
      {transactionDetails.response_code === "E000" ? (
        <div className="transaction-success">
          <div className="transaction-success__title">
            <Title>Transaction Success</Title>
            <img src={Close} alt="/" onClick={() => setModalFlag(false)} />
          </div>
          <div className="transaction-success__datablock">
            <div className="transaction-success__datablock--left">
              <img src={TransactionSuccess} alt="" />
            </div>
            <div className="transaction-success__datablock--right">
              <b className="transaction-success__datablock--text">0</b>
              <span className="transaction-success__datablock--subtext">
                Transaction Success
              </span>
              <ul>
                {dataOptions.map((res, index) => {
                  return (
                    <li key={index}>
                      <label>{res.label}</label>
                      <span>:</span>
                      <span>{res.value}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="transaction-success__footer">
            <div className="transaction-success__footer--flex">
              <div>
                <label>Status : </label>
                <span>{transactionDetails.response_code}</span>
              </div>
              <b>{transactionDetails.str}</b>
            </div>
          </div>
          <Button mode="print" onClick={() => setPrintModal(!printModal)} />

          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        </div>
      ) : (
        <div className="transaction-failed">
          <div className="transaction-failed__title">
            <Title>Transaction Failed</Title>
            <img src={Close} alt="/" onClick={() => setModalFlag(false)} />
          </div>
          <div className="transaction-failed__datablock">
            <div className="transaction-failed__datablock--left">
              {transactionDetails.response_code === "E007" ? (
                <img src={TransactionFailed} alt="" />
              ) : (
                <>
                  <img src={AllError} alt="" />
                </>
              )}
            </div>
          </div>
          <div className="transaction-failed__footer">
            <div className="transaction-failed__footer--flex">
              <div>
                <label>Status : </label>
                <span>{transactionDetails.response_code}</span>
              </div>
              <b>{transactionDetails.str}</b>
            </div>
          </div>
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        </div>
      )}

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={v_data && v_data.nodes.length ? v_data.nodes[0].
                acct_voucher_details.map((item) => ({
                  particular: item.acct_ldgr.ldgr_desc,
                  amount: item.vo_cr,
                })):[]}
              v_date={v_data && v_data.nodes.length ? v_data.nodes[0].v_date : EMPTY_STRING}
              v_no={v_data && v_data.nodes.length ? v_data.nodes[0].v_no : EMPTY_STRING}
              transaction_no={v_data && v_data.nodes.length ? v_data.nodes[0].v_transcation_no : EMPTY_STRING }
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPrintModal(!printModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Failure;

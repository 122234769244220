import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../../../Types/Student";
import {
  AcdSubjectAllocationData,
  Direction,
  InstitutionConfigurationTypes,
  SortBy,
  StudentReportType,
} from "../../../utils/Enum.types";
import { StudentAcdType } from "../../../utils/studentAcdEnum.types";
import useToken from "../../../customhooks/useToken";
import useAcademicYear from "./useActiveAcademicYear";
import { GetAcdStudents } from "../queries/students/Index";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { MstStudentMiscData } from "../../../customhooks/useStudentsbyNewApi";
import { AppContext } from "../../../context/context";
export interface StudentAcdNode {
  first_name: string;
  id: number;
  last_name: string;
  middle_name: string;
  std_adm_no: string;
  std_email: string;
  std_father_name: string;
  std_fresher: boolean;
  std_mobile: string;
  std_mother_name: string;
  std_reg_no: string;
  std_status: string;
  std_doa: string;
  std_dob: string;
  is_login_created: boolean;
  category: CategoryList;
  acd_dept: DepartmentList;
  acd_branch: BranchesList;
  acd_class: ClassList;
  acd_semester: SemesterList;
  acd_section: SectionList;
  misc_data: MstStudentMiscData;
  // acd_indivual_marks: GetAcdStdMarksByIdDetails[];
  isChecked: boolean;
  ext_marks?: number;
  int_marks?: number;
  scored_grade?: string;
  teacher_comments?: string;
  is_present?: boolean;
  att_status?: string;
  comments?: string;
  approved_by?: string;
  std_roll_no: string;
  std_sex?: string;
  std_remarks?: string;
}
export interface StudentPageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}
export interface StudentEdges {
  node: StudentAcdNode;
}
export interface StudentListEdges {
  first_name: string;
  id: number;
  last_name: string;
  middle_name: string;
  std_adm_no: string;
  std_email: string;
  std_father_name: string;
  std_fresher: boolean;
  std_mobile: string;
  std_mother_name: string;
  std_reg_no: string;
  std_status: string;
  std_doa: string;
  isChecked: boolean;
  is_present?: boolean;
  ext_marks?: number;
  int_marks?: number;
  scored_grade?: string;
  teacher_comments?: string;
  att_status?: string;
  comments?: string;
  approved_by?: string;
}
export interface GetAcdStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
interface input {
  acd_std_query_type: string;
  ids: number[] | string[] | any;
  str_data: string[];
}
export interface GetStudentAcdData {
  GetAcdStudents: GetAcdStudentsList;
}
export interface AcdStudentsvars {
  name: string;
  after: string | null;
  orderBy?: {
    direction: string;
    field: string;
  }[];
  first: number | null;
  token: string;
  acd_yr_id: number;
  input: input;
}

export interface AcdStudentsvarsForCount {
  after: string | null;
  orderBy?: {
    direction: string;
    field: string;
  }[];
  first: number | null;
  token: string;
  acd_yr_id: number;
  input: input;
}
const useAcdStudentsData = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  rowsPerPage: number | null,
  allotedId: number,
  name: string,
  queryType: StudentReportType | string,
  subjectId?: number,
  testConductId?: number,
  instIdForCampus?: number
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [ids, setIds] = useState<number[]>([]);
  const [str_data, setstr_data] = useState<string[]>([]);
  const [acd_std_query_type, setacd_std_query_type] = useState("");
  const { activeAcademicYearData } = useAcademicYear();
  const acdYearID =
    activeAcademicYearData.data &&
    activeAcademicYearData.data.GetAcdYrActiveByInstId.id;

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetStudentAcdData,
    AcdStudentsvars
  >(GetAcdStudents, {
    variables: {
      after: null,
      acd_yr_id:
        state.instIdForCampus && acdYearID
          ? acdYearID
          : state.ActiveAcdYr
          ? state.ActiveAcdYr.id
          : 0,
      first: rowsPerPage,
      name,
      token,
      orderBy: [
        {
          direction: Direction.ASC,
          field: SortBy.STD_ROLL_NO,
        },
      ],
      input: {
        ids,
        acd_std_query_type,
        str_data,
      },
    },
  });

  useEffect(() => {
    switch (queryType) {
      case StudentReportType.GENERAL:
        if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_SECTION_ID);
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_DEPT_ID);
        } else {
          setIds(instIdForCampus ? [instIdForCampus] : [Number(InstId)]);
          setacd_std_query_type(StudentAcdType.BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.LOGIN_ACTIVE:
        if (sectionId) {
          setIds([sectionId]);
          setstr_data([AcdSubjectAllocationData.SECTION]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setstr_data([AcdSubjectAllocationData.SEMESTER]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (classId) {
          setIds([classId]);
          setstr_data([AcdSubjectAllocationData.CLASS]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (branchId) {
          setIds([branchId]);
          setstr_data([AcdSubjectAllocationData.BRANCH]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (departmentId) {
          setIds([departmentId]);
          setstr_data([AcdSubjectAllocationData.DEPT]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else {
          setIds([Number(InstId)]);
          setstr_data([AcdSubjectAllocationData.INST]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        }
        break;
      case StudentReportType.LOGIN_INACTIVE:
        if (sectionId) {
          setIds([sectionId]);

          setstr_data([AcdSubjectAllocationData.SECTION]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setstr_data([AcdSubjectAllocationData.SEMESTER]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (classId) {
          setIds([classId]);
          setstr_data([AcdSubjectAllocationData.CLASS]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (branchId) {
          setIds([branchId]);
          setstr_data([AcdSubjectAllocationData.BRANCH]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (departmentId) {
          setIds([departmentId]);
          setstr_data([AcdSubjectAllocationData.DEPT]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else {
          setIds([Number(InstId)]);
          setstr_data([AcdSubjectAllocationData.INST]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        }
        break;
      case StudentReportType.PASSED_OUT:
        if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(
            StudentAcdType.ACD_PASSED_STDS_BY_ACD_BRANCH_ID
          );
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.ACD_PASSED_STDS_BY_ACD_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.ACD_PASSED_STDS_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.BY_ALLOTTED_LEVEL:
        if (allotedId && configData.data && !configData.loading) {
          setIds([allotedId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(StudentAcdType.BY_ALLOTTED_LEVEL);
        }
        break;
      case StudentReportType.BY_MARKS:
        if (
          allotedId &&
          configData.data &&
          !configData.loading &&
          testConductId! &&
          subjectId!
        ) {
          setIds([allotedId, testConductId, subjectId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(StudentAcdType.ACD_STD_MARKS_BY_SUBJECT);
        }
        break;

      case StudentReportType.LIB_BOOK_CLEARANCE:
        if (Number(InstId) || instIdForCampus) {
          setIds(instIdForCampus ? [instIdForCampus] : [Number(InstId)]);
          setacd_std_query_type(StudentAcdType.LIB_BOOK_CLEARANCE_BY_INST_ID);
        }
        break;

      case StudentReportType.TRANSPORT_NOT_ASSIGNED:
        if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_NOT_ASSIGNED_BY_SECTION_ID
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID
          );
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_INST_ID);
        }
        break;
      case StudentReportType.TRANSPORT_ASSIGNED:
        if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_ASSIGNED_BY_ACD_SECTION_ID
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_ASSIGNED_BY_ACD_SEMESTER_ID
          );
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.NXT_STUDENTS:
        if (semesterId && categoryId) {
          setIds([semesterId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_SEMESTER_ID_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setIds([classId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_CLASS_ID_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setIds([branchId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_BRANCH_ID_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setIds([departmentId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_DEPT_ID_AND_CATEGORY_ID
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_DEPT_ID);
        } else if (categoryId) {
          setIds([categoryId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_CATEGORY_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.CURR_STUDENTS:
        if (sectionId && categoryId) {
          setIds([sectionId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setIds([semesterId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setIds([classId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setIds([branchId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setIds([departmentId, categoryId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_DEPT_AND_CATEGORY_ID);
        } else if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_SECTION_ID);
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_DEPT_ID);
        } else if (categoryId) {
          setIds([categoryId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_CATEGORY_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.BY_COMBO_ID_AT_ALLOTED_LEVEL:
        if (
          allotedId &&
          configData.data &&
          !configData.loading &&
          state.acdCombinationId
        ) {
          setIds([Number(InstId), allotedId, state.acdCombinationId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(StudentAcdType.BY_COMBO_ID_AT_ALLOTED_LEVEL);
        }
        break;
      case StudentReportType.COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL:
        if (allotedId && configData.data && !configData.loading) {
          setIds([Number(InstId), allotedId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(
            StudentAcdType.COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL
          );
        }
        break;
      default:
        // setIds([Number(InstId)]);

        // setacd_std_query_type(StudentAcdType.BY_ACD_INST_ID);
        break;
    }
  }, [
    sectionId,
    semesterId,
    classId,
    branchId,
    departmentId,
    categoryId,
    InstId,
    queryType,
    allotedId,
    configData.data,
    configData.loading,
    testConductId,
    subjectId,
    instIdForCampus,
    state.acdCombinationId,
  ]);

  useEffect(() => {
    if (
      !activeAcademicYearData.loading &&
      activeAcademicYearData.data &&
      state.ActiveAcdYr &&
      acd_std_query_type &&
      ids.length
    ) {
      GetStudentsData();
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    queryType,
    GetStudentsData,
    state.ActiveAcdYr,
    str_data,
    acd_std_query_type,
    ids.length,
  ]);
  return {
    AcademicsStudentData: { data, loading, error, fetchMore },
  };
};

export default useAcdStudentsData;

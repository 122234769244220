import React, { useEffect, useRef, useState } from "react";
import { Label } from "../stories/Label/Label";
import useFileSizeDetails from "./useFileUploadConstraints";
import FileAttach from "../images/BrowseFiles.svg";
import Close from "../images/Close.svg";

import { handleUploadAndDownloadFile } from "../utils/Upload";
import { InstitutionConfigurationTypes, Operation } from "../utils/Enum.types";
import { EMPTY_STRING } from "../utils/constants";
import { getDownloadUrl } from "../utils/DownloadFile";
import { imageFileType } from "../Modules/Master/Student/Documents/Index";
import Modal from "react-modal";
import { WaiveOffModalStyles } from "../styles/ModalStyles";
import { Button } from "../stories/Button/Button";
import { getFileIcon } from "../utils/UtilFunctions";

const useUploadComponent = (
  first_name: string,
  operation: Operation,
  std_profile_filename: string,
  type:
    | InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE
    | InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE
) => {
  const [file, setFile] = useState<File | null>(null);
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);
  const [previewModal, setPreviewModal] = useState(false);
  const filetype = file?.name.split(".").pop();

  const filename = `${first_name}.${filetype}`;

  const { ComponentWarning, maxFileSizeInBits } = useFileSizeDetails();
  const imageRef = useRef<HTMLInputElement>(null);
  const [imageString, setImageString] = useState("");

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      if (
        (e.target.files[0].size > maxFileSizeInBits.mediaSize &&
          type === InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE) ||
        (e.target.files[0].size > maxFileSizeInBits.fileSizeInBits &&
          type === InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE)
      ) {
        alert("huge file");
        setFile(null);
        return;
      }
      let image_as_files = e.target.files[0];
      setFile(image_as_files);
    }
  };

  useEffect(() => {
    if (file) {
      // eslint-disable-next-line
      Object.values(imageFileType).map((d) => {
        if (d === filetype) {
          setImageString(URL.createObjectURL(file));
        }
      });
    } // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (Operation.UPDATE === operation && std_profile_filename) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(std_profile_filename, false, setImageString);
    }
  }, [operation, std_profile_filename]);

  const uploadFile = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          handleUploadAndDownloadFile(file, filename, setProgress, false)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        } else {
          resolve(true);
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  return {
    file,
    filename,
    setFile,
    uploadFile,

    component: (
      <>
        <div className="documents-upload">
          <Label>
            Upload{" "}
            {`${
              type === InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE
                ? "Document"
                : "Image"
            }`}
          </Label>
          <div className="documents-upload--filename">
            {file ? (
              <>
                <img src={getFileIcon(file.name)} alt="/" />
                <span>{file ? file.name : EMPTY_STRING}</span>
                <img src={Close} alt="/" onClick={() => setFile(null)} />
              </>
            ) : null}
          </div>

          <label className="student-documents__browse">
            <img src={FileAttach} alt="/" className="browse-icon" />
            Browse
            <input
              id="student-documents__browse--inputTag"
              type="file"
              className="assignment-creation__fileattach--input"
              ref={imageRef!}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleImagePreview(e)
              }
              accept={`${
                type === InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE
                  ? ".jpg, .png, .jpeg, .pdf"
                  : ".jpg, .png, .jpeg"
              }`}
            />
          </label>

          {/* <Button
            type="button"
            disabled={file === null}
            onClick={() => {
              if (file) {
                setPreviewModal(!previewModal);
              }
            }}
          >
            <img src={View} alt="/" />
          </Button> */}
        </div>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={previewModal}
          ariaHideApp={false}
          style={WaiveOffModalStyles}
          onRequestClose={() => setPreviewModal(!previewModal)}
        >
          {file ? (
            <>
              <div className="registration__data--logo-grid--image">
                <img
                  src={
                    Operation.UPDATE === operation &&
                    std_profile_filename !== EMPTY_STRING
                      ? getDownloadUrl(
                          std_profile_filename,
                          false,
                          setImageString
                        )
                      : imageString
                  }
                  alt="/"
                  className="image-preview"
                />
                <Button
                  mode="cancel"
                  onClick={() => {
                    setFile(null);
                    setPreviewModal(!previewModal);
                  }}
                />
                <img
                  src={Close}
                  alt="/"
                  onClick={() => setPreviewModal(!previewModal)}
                  className="registration__data--logo-grid--image--close-icon"
                />
              </div>
            </>
          ) : null}
        </Modal>

        <div className="registration__data--logo-grid--span">
          {type === InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE
            ? ComponentWarning.mediaSizeComponent
            : ComponentWarning.fileSizeComponent}
        </div>
      </>
    ),
  };
};

export default useUploadComponent;

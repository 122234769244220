import React, { useState } from "react";
import Modal from "react-modal";
import Warning from "../../../images/Warning.svg";
import { WaiveOffModalStyles } from "../../../styles/ModalStyles";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { Label } from "../../../stories/Label/Label";
import Close from "../../../images/Close.svg";
import Input from "../../../stories/Input/Input";
import { DELETE } from "../../../utils/constants";
interface Props {
  isOpen: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFinYearPost: () => void;
}
const DeleteConfirmationModal = ({
  handleFinYearPost,
  isOpen,
  setOpenModal,
}: Props) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={WaiveOffModalStyles}
      ariaHideApp={false}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <Title variant="subtitle1">
            <img src={Warning} alt="/" /> Are You Sure For Deleting Channels
          </Title>
          <Label>
            Please Note All the Channel related data including Media content and
            Message Content will be erased permanently
          </Label>
          <div className="delete-modal__body">
            <span>Do you really want to delete these records?</span>
            {/*Shiva:  br tag to be removed after css */}
            <br />
            <span>
              Please enter <b className="nodata">'DELETE'</b> to confirm the
              deletion.
            </span>
            <Input
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
              }}
            />
          </div>
          <Button
            autoFocus
            disabled={inputValue !== DELETE}
            onClick={() => {
              handleFinYearPost();
              setInputValue("");
            }}
            mode="delete"
          />
          <Button mode="cancel" onClick={() => setOpenModal(false)} />
        </div>
        <div className="modal-flex__image">
          <img src={Close} alt="/" onClick={() => setOpenModal(false)} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;

import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import {  TextField } from "@mui/material";
import { responseType } from "../../../utils/Form.types";
import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import useEmployee, { empQueryTypes } from "../../HR/hooks/useEmployee";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import useStudentEnquiries from "../hooks/useStudentEnquires";
import {
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
} from "../../../utils/Enum.types";
import { GetStudentsEnquiryEdges } from "../StudentEnquiry";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { Button } from "../../../stories/Button/Button";
import Close from "../../../images/Close.svg";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import {
  
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import useEnquiryTable from "../json/useEnquiryTable";
import { TableHeaderProps } from "../../../utils/types";
import { labelClasses, LabeledAutocomplete } from "../../../styles/AutocompleteListStyles";
interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  startDate: string;
  endDate: string;
}

const ViewAllocated = ({ setModalFlag, startDate, endDate }: props) => {

  const [empSelected, setEmpSelected] = useState<responseType | null>(null);
  const [students, setStudents] = useState<GetStudentsEnquiryEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { FollowUp } = useEnquiryTable();
  const {
    empDetails: { data },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.JUST_LOGIN_ACCTS,
    EMPTY_STRING,
    0,
    0,
    0,
    0,
    0,
    0
  );
  const {
    EnquiryData: { StudentEnquiryData },
  } = useStudentEnquiries(
    ROWS_PER_PAGE,
    empSelected?.value
      ? StdEnqQueryType.STD_ENQ_BY_EMP_ID
      : StdEnqQueryType.STD_ENQ_ALL,
    EMPTY_STRING,
    EMPTY_STRING,
    null,
    null,
    SortBy.ENQ_DATE,
    endDate,
    startDate,
    0,
    0,
    empSelected?.value ? empSelected?.value : 0
  );
  const employeeDropDown: responseType[] = data
    ? data.GetPayRollEmp.edges.map((res) => ({
        label:
          res.node.emp_first_name +
          " " +
          res.node.emp_middle_name +
          " " +
          res.node.emp_last_name,
        value: res.node.id,
      }))
    : [];
  const {
    PredefinedData: { dropDown: ENQ_STATUS_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_FOLLOW_UP_STATUS,
    EMPTY_STRING
  );
  const findEnquiry = (data: string) => {
    return (
      ENQ_STATUS_OPTIONS.find(({ value }) => value === data)?.label ??
      EMPTY_STRING
    );
  };
  const handleClear = () => {
    setEmpSelected(null);
  };

  const dynamicHeaders: TableHeaderProps[] = FollowUp.map((header) => ({
    headerName: header.headerName,
    className: header.cellClassName,
    field: header.field,
    headerAlign: header.headerAlign as GridAlignment,
    align: header.align as GridAlignment,
    flex: header.flex,
  }));
  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !StudentEnquiryData.loading) {
            StudentEnquiryData.fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStudentEnquires.edges;
                const pageInfo = fetchMoreResult.GetStudentEnquires.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetStudentEnquires.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetStudentEnquires: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: StudentEnquiryData.data
                      ? StudentEnquiryData.data.GetStudentEnquires.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (StudentEnquiryData.data && !StudentEnquiryData.loading) {
      const newData = StudentEnquiryData.data.GetStudentEnquires.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.std_id && row.std_id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_id: node.id,
            date_of_enquiry: toStandardDate(node.enq_date),
            name: node.first_name + node.middle_name + node.last_name,
            email: node.std_email,
            mobile: node.std_mobile,
            status: findEnquiry(node.last_follow_up_status),
            previous_studied_institution: node.std_previous_school,
            follow_up_date:
              toStandardDate(node.last_follow_up_made_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.last_follow_up_made_date),
            next_follow_up_date:
              toStandardDate(node.next_follow_up_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.next_follow_up_date),
            follow_up_count: node.follow_up_count,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_id: node.id,
            date_of_enquiry: toStandardDate(node.enq_date),
            name: node.first_name + node.middle_name + node.last_name,
            email: node.std_email,
            mobile: node.std_mobile,
            status: findEnquiry(node.last_follow_up_status),
            previous_studied_institution: node.std_previous_school,
            follow_up_date:
              toStandardDate(node.last_follow_up_made_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.last_follow_up_made_date),
            next_follow_up_date:
              toStandardDate(node.next_follow_up_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.next_follow_up_date),
            follow_up_count: node.follow_up_count,
          }))
        );
      }
      setEndCursor(
        StudentEnquiryData.data.GetStudentEnquires.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [StudentEnquiryData.data, StudentEnquiryData.loading]);

  return (
    <>
      <div className="std-enquiry-view">
        <div className="std-enquiry-view__title">
          <Title>View Allocated Enquiry Students </Title>

          <img src={Close} alt="/" onClick={() => setModalFlag(false)} />
        </div>
        <div className="row g-0">
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={employeeDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, empSelected)
              }
              openOnFocus
              value={empSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setEmpSelected(newValue as responseType);
                } else {
                  setEmpSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  label="Select Employee"
                  autoFocus
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className={`std-enquiry-view__tableblock`}>
          {students?.length ? (
            <StyledDatagrid
              columns={columns}
              rows={rows}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
 
            />
          ) : (
            <b className="nodata">No Students found</b>
          )}
        </div>
        <Button mode="clear" onClick={handleClear} />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default ViewAllocated;

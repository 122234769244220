import React, { useContext, useEffect, useState } from "react";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import {
  GetAcdGradeScalesByInstIdData,
  GetAcdGradeScalesByInstIdList,
  GetAcdGradeScalesByInstIdVars,
} from "../../types/grade";
import { GetAcdGradeScalesByInstId } from "../../queries/grade";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { reOrderProcess } from "../../../../utils/UtilFunctions";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Operation } from "../../../../utils/Enum.types";
import { DeleteAcdGradeScale } from "../../queries/grade/mutation";
import { msgType } from "../../../../utils/Form.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { Title } from "../../../../stories/Title/Title";
import LoadingModal from "../../../../pages/LoadingModal";
import DeleteModal from "../../../../pages/DeleteModal";
const { Academics_Table } = require("../../json/table.json");
interface GradeListProps {
  fetchEditDetails: (
    id: number,
    grade: string,
    marks_max: number,
    marks_min: number,
    remarks: string
  ) => void;
  reOrder: boolean;
  localItemsState: {
    localItems: GetAcdGradeScalesByInstIdList[];
    setLocalItems: React.Dispatch<
      React.SetStateAction<GetAcdGradeScalesByInstIdList[]>
    >;
  };
}
const List = ({
  fetchEditDetails,
  localItemsState,
  reOrder,
}: GradeListProps) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { localItems, setLocalItems } = localItemsState;
  const [deleteModal, setDeleteModal] = useState(false);
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [items, setItems] = useState<GetAcdGradeScalesByInstIdList[]>([]);
  const [gradeId, setGradeId] = useState(0);
  const [GetAcdGradeScaleDetails, { data: gradeDetails, loading }] =
    useLazyQuery<GetAcdGradeScalesByInstIdData, GetAcdGradeScalesByInstIdVars>(
      GetAcdGradeScalesByInstId,
      {
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        },
      }
    );
  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: GetAcdGradeScalesByInstIdList[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setItems(temp);
      return temp;
    });
  };
  useEffect(() => {
    if (token && InstId) {
      GetAcdGradeScaleDetails();
    }
  }, [token, InstId, GetAcdGradeScaleDetails]);

  useEffect(() => {
    if (gradeDetails && !loading)
      setLocalItems(gradeDetails.GetAcdGradeScalesByInstId);
    // eslint-disable-next-line
  }, [gradeDetails, loading]);
  const [DeleteGradeDetails, { loading: deleteLoading }] = useMutation(
    DeleteAcdGradeScale,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const HandleDelete = (id: number) => {
    if (InstId)
      DeleteGradeDetails({
        variables: {
          token,
          id: gradeId,
          inst_id: InstId,
          user_details,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        },
        refetchQueries: [
          {
            query: GetAcdGradeScalesByInstId,
            variables: {
              token,
              inst_id: InstId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Document Deleted Successfully",
            operation: Operation.DELETE,
          });
          setDeleteModal(!deleteModal);
        }
      });
  };

  return (
    <>
      <div className="grade__edit--list">
        <Title variant="subtitle1">List Of Grades</Title>
        <div className="grade__edit--list--tableblock">
          {" "}
          <TableContainer className="grade__edit--list--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {reOrder ? <TableCell></TableCell> : null}
                  {Academics_Table.ExamPlanner.Grade.filter(
                    ({ labelName }: TableHeaderProps) =>
                      !(labelName === "Actions" && reOrder)
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TableCell className={th.className}>
                          {th.labelName}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={handleDragAndDrop}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {localItems?.map((response, index: number) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={!reOrder}
                        >
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  {reOrder ? (
                                    <TableCell
                                      {...draggableProvided.dragHandleProps}
                                      id="td-center"
                                      className="institution__table--slno"
                                    >
                                      =
                                    </TableCell>
                                  ) : null}
                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                    className="institution__table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>

                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    {response?.grade}
                                  </TableCell>

                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    {response.marks_min}
                                  </TableCell>

                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    {response.marks_max}
                                  </TableCell>
                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    {response?.grade_remarks}
                                  </TableCell>

                                  {reOrder === false ? (
                                    <TableCell
                                      id="td-center"
                                      {...draggableProvided.dragHandleProps}
                                      className="institution__table--action"
                                    >
                                      <>
                                        <img
                                          src={Edit}
                                          alt="/"
                                          onClick={() => {
                                            fetchEditDetails(
                                              response.id,
                                              response.grade,
                                              response.marks_max,
                                              response.marks_min,
                                              response.grade_remarks
                                            );
                                          }}
                                        />

                                        <img
                                          src={Delete}
                                          alt="/"
                                          onClick={() => {
                                            setGradeId(response.id);
                                            setDeleteModal(!deleteModal);
                                          }}
                                        />
                                      </>
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              </React.Fragment>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </div>
      </div>
      <LoadingModal flag={deleteLoading} />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={gradeId}
      />
    </>
  );
};

export default List;
